import React from 'react';
import {
  connect
} from 'react-redux';
import { bindActionCreators } from 'redux';
import { dataCreators } from '../../actions/dataAction';
import { Grid, Image, PaginationControl, Section, Table, TableAction, Text } from '../../components';
import { ROUTE_PATH } from '../../helpers/constants/route';
import { getSortAscending } from '../../helpers/functions/data';
import { getLanguage } from '../../helpers/functions/language';
import { redirect } from '../../helpers/functions/route';
import { getClearProjectDocumentParams, getProjectDocumentList } from '../../helpers/services/projectDocument';
import {
  ICONS,
  VARIABLES
} from '../../themes';

class ProjectDocumentListContainer extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      activePage: 1,
      dataPerPage: 10,
    }
  }


  componentDidMount() {
    const {
      activePage,
      dataPerPage,
    } = this.state;
    const params = {
      ...getClearProjectDocumentParams(),
      page: activePage,
      limit: dataPerPage,
    };
    getProjectDocumentList(params);
  }

  projectDocumentList = {
    onClickSort: (target) => {
      getProjectDocumentList({
        isNoSort: false,
        sort: target
      });
    },
    onClickEdit: async (item) => {
      redirect(`${ROUTE_PATH.PROJECT_DOCUMENT_MANAGE.LINK}/${item.preSalesTransId}`);
    },
  }

  input = {
    onChangeSearchOption: (selectedOption) => {
      this.setState({
        selectedOption
      })
    },
    onChangeInput: (ev) => {
      const name = ev.target.name;
      const value = ev.target.value;
      this.setState({
        [name]: value,
      });
    },
  }

  modal = {
    onCloseLogModal: () => {
      this.setState({
        isOpenLogModal: false,
      })
    }
  }

  pagination = {
    onPaginationChange: (pageNumber) => {
      getProjectDocumentList({
        isNoSort: true,
        page: pageNumber,
      });
    }
  }

  render() {
    const {
      dataList,
      dataParams,
      totalData,
      activePage,
      onClickViewSaleTrans,
    } = this.props;
    const {
      isOpenSelectCustomerModal,
      customerData,
      searchValue,
      startDate,
      endDate,
      selectedOption,
      selectOptionList,
      isOpenSaleTransactionModal,
      dataPerPage,
    } = this.state;
    return (
      <React.Fragment>
        <Section spacingBottom='45'>
          <Table striped>
            <Table.Section>
              <Table.Row>
                <Table.HeadColumn
                  maxWidth={150}
                  minWidth={150}
                  sortAscending={getSortAscending(dataParams, 'pre_sales_trans_code')}
                  onClickSort={() => {
                    this.projectDocumentList.onClickSort('pre_sales_trans_code desc');
                  }}
                >
                  {getLanguage('projectDocument.table.header.projectDocumentNo', '')}
                </Table.HeadColumn>
                <Table.HeadColumn
                  minWidth={245}
                  maxWidth={245}
                  sortAscending={getSortAscending(dataParams, 'pre_sales_trans_project_name')}
                  onClickSort={() => {
                    this.projectDocumentList.onClickSort('pre_sales_trans_project_name desc');
                  }}
                >
                  {getLanguage('projectDocument.table.header.projectName', '')}
                </Table.HeadColumn>
                <Table.HeadColumn
                  minWidth={245}
                  sortAscending={getSortAscending(dataParams, 'customer_name')}
                  onClickSort={() => {
                    this.projectDocumentList.onClickSort('customer_name desc');
                  }}
                >
                  {getLanguage('projectDocument.table.header.customerName', '')}
                </Table.HeadColumn>
                <Table.HeadColumn
                  minWidth={140}
                  noSort
                >
                  {getLanguage('projectDocument.table.header.saleTransactionId', '')}
                </Table.HeadColumn>
                <Table.HeadColumn
                  maxWidth={115}
                  minWidth={115}
                  sortAscending={getSortAscending(dataParams, 'created_date')}
                  onClickSort={() => {
                    this.projectDocumentList.onClickSort('created_date desc');
                  }}
                >
                  {getLanguage('projectDocument.table.header.createdDate', '')}
                </Table.HeadColumn>
                <Table.HeadColumn
                  maxWidth={115}
                  minWidth={115}
                  sortAscending={getSortAscending(dataParams, 'updated_date')}
                  onClickSort={() => {
                    this.projectDocumentList.onClickSort('updated_date desc');
                  }}
                >
                  {getLanguage('projectDocument.table.header.editedDate', '')}
                </Table.HeadColumn>
                <Table.HeadColumn
                  alignCenter
                  action
                  maxWidth={50}
                  minWidth={50}
                  noSort
                >
                  {getLanguage('projectDocument.table.header.action', '')}
                </Table.HeadColumn>
              </Table.Row>
            </Table.Section>
            <Table.Section>
              {
                dataList.map((e, i) => {
                  return (
                    <Table.Row
                      key={i}
                    >
                      <Table.BodyColumn
                        info
                        maxWidth={150}
                        minWidth={150}
                        title={e.preSalesTransCode}
                      >
                        {e.preSalesTransCode}
                      </Table.BodyColumn>
                      <Table.BodyColumn
                        info
                        minWidth={245}
                        title={e.preSalesTransProjectName}
                      >
                        {e.preSalesTransProjectName}
                      </Table.BodyColumn>
                      <Table.BodyColumn
                        info
                        // maxWidth={245}
                        minWidth={245}
                        title={e.customerName}
                      >
                        {e.customerName}
                      </Table.BodyColumn>
                      {e.salesTransList.length > 1 ?
                        <Table.BodyColumn
                          link
                          minWidth={140}
                          title={e.saleTransactionId}
                        >
                          <Section onClick={() => {
                            onClickViewSaleTrans(e);
                          }}
                          >
                            View
                          </Section>
                        </Table.BodyColumn>
                        :
                        <React.Fragment>
                          <Table.BodyColumn
                            info
                            // maxWidth={140}
                            minWidth={140}
                            title={e.salesTransList.length === 0 ? '-' : e.salesTransList[0].salesTransId}
                          >
                            {e.salesTransList.length === 0 ? '-' : e.salesTransList[0].salesTransId}
                          </Table.BodyColumn>
                        </React.Fragment>
                      }
                      <Table.BodyColumn
                        info
                        maxWidth={115}
                        minWidth={115}
                      >
                        {e.displayCreatedDate}
                      </Table.BodyColumn>
                      <Table.BodyColumn
                        info
                        maxWidth={115}
                        minWidth={115}
                      >
                        {e.displayUpdatedDate}
                      </Table.BodyColumn>
                      <Table.BodyColumn
                        info
                        alignCenter
                        maxWidth={50}
                        minWidth={50}
                      >
                        <Image
                          src={ICONS['ic-action-edit.svg']}
                          widthRatio="14"
                          widthRatioUnit="px"
                          heightRatio="14"
                          heightRatioUnit="px"
                          onClick={() => {
                            this.projectDocumentList.onClickEdit(e);
                          }}
                        />
                      </Table.BodyColumn>
                    </Table.Row>
                  )
                })
              }
            </Table.Section>
          </Table>
        </Section>
        <TableAction>
          <TableAction.Left></TableAction.Left>
          <TableAction.Right>
            <Grid gutter='20'>
              <Grid.Column>
                <Section paddingTop='6'>
                  <Text
                    regular12
                    color={VARIABLES.COLORS.GRAY_16}
                  >
                    {getLanguage('pagination.page', '')} {activePage}/{Math.ceil(totalData / dataPerPage)}
                  </Text>
                </Section>
              </Grid.Column>
              <Grid.Column>
                <PaginationControl
                  activePage={activePage}
                  itemsCountPerPage={dataPerPage}
                  totalItemsCount={totalData}
                  pageRangeDisplayed={3}
                  onChange={this.pagination.onPaginationChange}
                />
              </Grid.Column>
            </Grid>
          </TableAction.Right>
        </TableAction>
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => {
  const dataRedux = state.dataRedux;
  return {
    dataList: dataRedux.projectDocumentList,
    dataParams: dataRedux.projectDocumentParams,
    activePage: dataRedux.projectDocumentParams.activePage,
    totalData: dataRedux.projectDocumentParams.totalData || 0,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    dataActions: bindActionCreators(dataCreators, dispatch),
  }
}

export const ProjectDocumentListContainerConnected = connect(
  mapStateToProps,
  mapDispatchToProps
)(ProjectDocumentListContainer)