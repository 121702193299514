import React from 'react';
import {
  ShapeContainer,
  Section,
  Grid,
  Field,
  Button,
  TitleSection,
  SelectControl,
  Text,
  SegmentCard
} from './../../components'
import {
  VARIABLES,
  ICONS,
  CONTENTS,
} from './../../themes'
import { ModalSegmentInformationContainer, } from './../../containers/ModalContainer'
import _ from 'lodash';
import { getLanguage } from '../../helpers/functions/language';
import { getUserAuthRedux } from '../../helpers/functions/auth';
import { getSegmentList, getSubSegmentList } from '../../helpers/services/segment';
import { data } from 'autoprefixer';
import { getLocalStorage } from '../../helpers/functions/localStorage';
import { LOCAL_STORAGE } from '../../helpers/constants/localStorage';
import { DATA } from '../../helpers/constants/data';

export class WarrantyManageSegment extends React.Component {

  constructor(props) {
    super(props);
    const language = getLocalStorage(LOCAL_STORAGE.LANGUAGE_RBPTRUEBLUE);
    const isLanguageTh = language === DATA.LANGUAGE.THAI;

    this.state = {
      userAuth: getUserAuthRedux(),
      oldDataList: [],
      dataList: [],
      rowMax: 5,
      isLanguageTh,
      isOpenSubSegmentList: [],
      subSegmentList: [],
      isSegmentResidential: props.data.wrtCat === DATA.WARRANTY_CATEGORY_TYPE.RESIDENTIAL.TYPE,

      isOpenSegmentInformationModal: false,
      segmentInformationList: [],

      searchValue: '',
      dataAutoCompleteList: [],
    }
  }

  componentDidMount() {
    this.warrantyManageSegment.getWarrantySegment();
  }

  warrantyManageSegment = {
    onClickSearchSegment: () => {
      const {
        searchValue,
        oldDataList,
      } = this.state;
      const dataList = _.cloneDeep(oldDataList);
      const newDataList = dataList.filter(e => {
        e.subSegmentList = e.subSegmentList.filter(es => {
          return (es.subSegmentNameEN || '').toLowerCase().indexOf(searchValue.toLowerCase()) >= 0 ||
            (es.subSegmentNameTH || '').toLowerCase().indexOf(searchValue.toLowerCase()) >= 0 ||
            (es.subSegmentDescEN || '').toLowerCase().indexOf(searchValue.toLowerCase()) >= 0 ||
            (es.subSegmentDescTH || '').toLowerCase().indexOf(searchValue.toLowerCase()) >= 0;
        });
        return e.subSegmentList.length;
      }).map(e => {
        e.isOpenSubSegment = false;
        return e;
      });;
      console.log('searchValue', searchValue);
      console.log('newDataList', newDataList);
      this.setState({
        isOpenSubSegmentList: [],
        subSegmentList: [],
      }, () => {
        this.warrantyManageSegment.handleSegmentNonRes(newDataList);
      });
    },
    getIsSelected: (value, type) => {
      const {
        data,
      } = this.props;
      const {
        isSegmentResidential,
      } = this.state;
      switch (type) {
        case 'segment':
          if (isSegmentResidential) {
            return data.segmentSelected &&
              data.segmentSelected.segmentId === value.segmentId &&
              data.segmentSelected.subSegmentId === value.subSegmentId;
          }
          else {
            return data.segmentSelected &&
              data.segmentSelected.segmentId === value.segmentId;
          }
        case 'subSegment':
          return data.segmentSelected &&
            data.segmentSelected.segmentId === value.segmentId &&
            data.segmentSelected.subSegmentId === value.subSegmentId;
        default: return false;
      }
    },
    handleSegmentNonRes: (dataList, callback = () => { }) => {
      const {
        rowMax,
      } = this.state;
      const dataGroupList = [];
      let cnt = 0;
      let row = 0;

      dataList.forEach(e => {
        if (cnt < rowMax) {
          if (dataGroupList[row]) {
            dataGroupList[row].push(e);
          } else {
            dataGroupList[row] = [e];
          }
          cnt++;
        }
        if (cnt === rowMax) {
          cnt = 0;
          row++;
        }
      });

      this.setState({
        dataList: dataGroupList,
      }, () => {
        callback();
      });
    },
    handleSegmentRes: (dataList) => {
      const {
        data,
      } = this.props;
      const {
        rowMax,
      } = this.state;

      const subSegmentList = [];
      dataList.forEach(e => {
        e.subSegmentList.forEach(es => {
          subSegmentList.push(es);
        })
      });

      const dataGroupList = [];
      let cnt = 0;
      let row = 0;

      subSegmentList.forEach(e => {
        if (cnt < rowMax) {
          if (dataGroupList[row]) {
            dataGroupList[row].push(e);
          } else {
            dataGroupList[row] = [e];
          }
          cnt++;
        }
        if (cnt === rowMax) {
          cnt = 0;
          row++;
        }
      });

      this.setState({
        dataList: dataGroupList,
      });
    },
    getWarrantySegment: async () => {
      const {
        data,
      } = this.props;
      const {
        isSegmentResidential,
      } = this.state;
      const dataList = await this.warrantyManageSegment.getSegmentDataList();
      if (dataList) {
        if (isSegmentResidential) {
          this.warrantyManageSegment.handleSegmentRes(dataList);
        }
        else {
          this.warrantyManageSegment.handleSegmentNonRes(dataList);

          this.setState({
            oldDataList: _.cloneDeep(dataList),
          });
        }
      }
    },
    getSegmentDataList: async () => {
      const {
        data,
      } = this.props;
      const {
        isLanguageTh,
        isSegmentResidential,
      } = this.state;
      const params = {
        sort: 'segment_id asc',
        isSearchRes: isSegmentResidential,
      }
      let dataList = await getSegmentList(params);
      if (dataList) {
        dataList = dataList.map(e => {
          e.name = isLanguageTh ? e.segmentNameTH : e.segmentNameEN;
          e.description = isLanguageTh ? e.segmentDescTH : e.segmentDescEN;
          e.image = e.segmentImg;
          e.subSegmentList = e.subSegmentList.map(es => {
            es.name = isLanguageTh ? es.subSegmentNameTH : es.subSegmentNameEN;
            es.description = isLanguageTh ? es.subSegmentDescTH : es.subSegmentDescEN;
            es.image = es.subSegmentImg;
            return es;
          });
          return e;
        })
        return dataList;
      }
    },
    onClickSegment: (value, callback = () => { }, option = {}) => {
      const {
        data,
        onChangeSegment,
      } = this.props;
      const {
        dataList,
        isSegmentResidential,
      } = this.state;
      if (isSegmentResidential) {
        dataList.forEach(e => {
          e.forEach(item => {
            if (item.subSegmentId === value.subSegmentId) {
              onChangeSegment(item);
            }
          });
        });
      }
      else {
        let subSegmentList = [];
        const isOpenSubSegmentList = [];
        const newDataList = dataList.map((e, i) => {
          let isOpenSubSegment = false;
          e = e.map(item => {
            if (item.segmentId === value.segmentId) {
              item.isActive = option.isActive ? option.isActive : !item.isActive;
              item.isOpenSubSegment = option.isOpenSubSegment ? option.isOpenSubSegment : !item.isOpenSubSegment;
            }
            else {
              item.isActive = false;
              item.isOpenSubSegment = false;
            }
            if (item.isOpenSubSegment) {
              subSegmentList = _.cloneDeep(item.subSegmentList);
              isOpenSubSegment = true;
            }
            return item;
          });
          isOpenSubSegmentList.push(isOpenSubSegment);
          return e;
        });
        this.setState({
          dataList: newDataList,
          isOpenSubSegmentList,
          subSegmentList,
        }, () => {
          callback();
        })
      }
    },
    onClickSubSegment: (value) => {
      const {
        onChangeSegment,
      } = this.props;
      const {
        subSegmentList,
      } = this.state;
      subSegmentList.forEach(e => {
        if (e.segmentId === value.segmentId && e.subSegmentId === value.subSegmentId) {
          onChangeSegment(e);
        }
      });
    },
    onClickOpenSegmentInformationModal: () => {
      this.warrantyManageSegment.getSegmentInformationList(() => {
        this.setState({
          isOpenSegmentInformationModal: true,
        });
      })
    },
    getSegmentInformationList: async (callback = () => { }) => {
      const dataList = await this.warrantyManageSegment.getSegmentDataList();
      if (dataList) {
        const segmentInformationList = [];
        dataList.forEach(e => {
          e.subSegmentList.forEach(es => {
            segmentInformationList.push({
              ...e,
              subSegmentName: es.name,
              subSegmentDesc: es.description,
            });
          });
        })
        this.setState({
          segmentInformationList,
        }, () => {
          callback();
        });
      }
    },
    onCloseSegmentInformationModal: () => {
      this.setState({
        isOpenSegmentInformationModal: false,
      });
    },
    getSearchAutoCompleteList: (value) => {
      const {
        timeoutSearchIndex,
        isSegmentResidential,
      } = this.state;
      clearTimeout(timeoutSearchIndex);
      this.setState({
        timeoutSearchIndex: setTimeout(async () => {
          const params = {
            text: value
          }
          let dataList = await getSubSegmentList(params);
          const dataAutoCompleteList = [];
          dataList.forEach(e => {
            dataAutoCompleteList.push({
              label: e.subSegmentNameEN,
              value: e.subSegmentNameEN,
              data: e
            });
            dataAutoCompleteList.push({
              label: e.subSegmentNameTH,
              value: e.subSegmentNameTH,
              data: e
            });
            dataAutoCompleteList.push({
              label: e.subSegmentDescEN,
              value: e.subSegmentDescEN,
              data: e
            });
            dataAutoCompleteList.push({
              label: e.subSegmentDescTH,
              value: e.subSegmentDescTH,
              data: e
            });
          });

          this.setState({
            dataAutoCompleteList,
          });
        }, 500)
      })
    },
  }

  input = {
    onChangeSearch: (value, option) => {
      if (option.action === 'input-change') {
        this.setState({
          searchValue: value
        });
        this.warrantyManageSegment.getSearchAutoCompleteList(value);
      }
    },
    onChangeSearchSelected: (selected) => {
      const {
        oldDataList,
      } = this.state;
      this.warrantyManageSegment.handleSegmentNonRes(oldDataList, () => {
        this.warrantyManageSegment.onClickSegment(selected.data, () => {
          this.warrantyManageSegment.onClickSubSegment(selected.data);
        }, { isActive: true, isOpenSubSegment: true });
      });
      this.setState({
        searchValue: '',
      });
    }
  }

  render() {
    const {
      data,
      onClickBack,
      onClickSaveDraft,
      onClickSave,
    } = this.props;
    const {
      userAuth,
      dataList,
      isOpenSubSegmentList,
      subSegmentList,
      isOpenSegmentInformationModal,
      segmentInformationList,
      dataAutoCompleteList,
      searchValue,
      isSegmentResidential,
    } = this.state;
    console.log('dataList', dataList)
    console.log('dataAutoCompleteList', dataAutoCompleteList)
    return (
      <React.Fragment>
        <ShapeContainer
          ui='contentStep'
          justify='space-between'
        >
          <Section spacingBottom='45'>
            <Section spacingBottom='45'>
              <TitleSection name={getLanguage('warrantyManage.form.segment.title', '')} />
            </Section>
            <ShapeContainer fluid padding='30' bgColor={VARIABLES.COLORS.BLUE_16} borderRadius='5'>
              <ShapeContainer fluid padding='30' bgColor={VARIABLES.COLORS.WHITE} borderRadius='5'>
                {!isSegmentResidential &&
                  <Section paddingBottom='15'>
                    <Grid gutter='30' justify='space-between'>
                      <Grid.Column gridLaptop='7' gridLargeDesktop='8'>
                        <Grid gutter='15'>
                          <Grid.Column flex='1'>
                            <SelectControl
                              filterBorder
                              isFilterSearch
                              isSearchable
                              placeholder={getLanguage('field.segmentPlaceholder', '')}
                              value={null}
                              inputValue={searchValue}
                              onInputChange={this.input.onChangeSearch}
                              onChange={this.input.onChangeSearchSelected}
                              options={dataAutoCompleteList}
                            />
                          </Grid.Column>
                          <Grid.Column flex='none'>
                            <Button
                              lightBlue
                              width='100'
                              name={getLanguage('warrantyManage.form.segment.button.search', '')}
                              onClick={this.warrantyManageSegment.onClickSearchSegment}
                            />
                          </Grid.Column>
                        </Grid>
                      </Grid.Column>

                      <Grid.Column gridLaptop='5' gridLargeDesktop='4'>
                        <Section justify='flex-end'>
                          <Button ui='frontIcon'
                            lightestBlue
                            outlineLightestBlue
                            textSmall
                            width='100'
                            iconWidth='22'
                            iconHeight='16'
                            iconSrc={ICONS['ic-table-blue.svg']}
                            name={getLanguage('warrantyManage.form.segment.button.segmentAndSubSegmentInformation', '')}
                            onClick={this.warrantyManageSegment.onClickOpenSegmentInformationModal}
                          />
                        </Section>
                      </Grid.Column>
                    </Grid>
                  </Section>
                }
                {/* Element for Loop > 5 Segments per row */}
                {/* SegmentCard Conditions:
                    1. Select sub segment in segment (but not see sub segment lists)
                      > isNotSelected={false}
                      > isActive={false}
                      > isSelected={true}
                      > isOpenSubSegmentLists={false}
                    2. Not select sub segment in segment
                      > isNotSelected={true}
                      > isActive={false}
                      > isSelected={false}
                      > isOpenSubSegmentLists={false}
                    3. See sub segment lists
                      > isNotSelected={false}
                      > isActive={true}
                      > isSelected={false}
                      > isOpenSubSegmentLists={true}
                    4. See sub segment lists (Segment selected)
                      > isNotSelected={false}
                      > isActive={false}
                      > isSelected={true}
                      > isOpenSubSegmentLists={true}
                */}
                {dataList.map((e, i) => {
                  return (
                    <React.Fragment key={i}>
                      <Section paddingTop='15'>
                        <Grid gutter='15'>
                          {e.map((item, iItem) => {
                            return (
                              <Grid.Column key={`${i}_${iItem}`} gridSystem='10' grid='2'>
                                <SegmentCard
                                  isNotSelected={!this.warrantyManageSegment.getIsSelected(item, 'segment')}
                                  isActive={item.isActive}
                                  isSelected={this.warrantyManageSegment.getIsSelected(item, 'segment')}
                                  isOpenSubSegmentLists={item.isOpenSubSegment}
                                  name={item.name}
                                  description={item.description}
                                  imageSrc={_.get(item, 'image.path', '')}
                                  onClick={() => { this.warrantyManageSegment.onClickSegment(item) }}
                                />
                              </Grid.Column>
                            )
                          })}
                        </Grid>
                      </Section>
                      {isOpenSubSegmentList[i] &&
                        <Section paddingTop='15'>
                          <ShapeContainer fluid padding='15' bgColor={VARIABLES.COLORS.GRAY_1} borderStyle='solid' borderWidth='1' borderColor={VARIABLES.COLORS.GRAY_8} borderRadius='5'>
                            <Grid gutter='15' gutterVertical='15'>
                              {subSegmentList.map((item, iItem) => {
                                return (
                                  <Grid.Column gridSystem='10' grid='2' key={`${i}_${iItem}`}>
                                    <SegmentCard
                                      isSelected={this.warrantyManageSegment.getIsSelected(item, 'subSegment')}
                                      name={item.name}
                                      description={item.description}
                                      imageSrc={_.get(item, 'image.path', '')}
                                      onClick={() => { this.warrantyManageSegment.onClickSubSegment(item) }}
                                    />
                                  </Grid.Column>
                                )
                              })}
                            </Grid>
                          </ShapeContainer>
                        </Section>
                      }
                    </React.Fragment>
                  )
                })}
              </ShapeContainer>
            </ShapeContainer>
          </Section>
          <Section>
            <Grid
              gutter='15'
              gutterVertical='15'
              justify='flex-end'
            >
              <React.Fragment>
                <Grid.Column>
                  <Button
                    gray
                    ui='back'
                    name={getLanguage('field.buttonBack', '')}
                    onClick={onClickBack}
                  />
                </Grid.Column>
                {data.isShowSaveDraft &&
                  <Grid.Column>
                    <Button
                      lightBlue
                      name={getLanguage('field.buttonSaveAsDraft', '')}
                      onClick={onClickSaveDraft}
                    />
                  </Grid.Column>
                }
                <Grid.Column>
                  <Button
                    blue
                    name={getLanguage('field.buttonSaveAndContinue', '')}
                    width={VARIABLES.BUTTON.WIDTHS.W_255}
                    onClick={onClickSave}
                  />
                </Grid.Column>
              </React.Fragment>
            </Grid>
          </Section>
        </ShapeContainer>

        {isOpenSegmentInformationModal &&
          <ModalSegmentInformationContainer
            isOpenModal
            dataList={segmentInformationList}
            primaryTitle={getLanguage('warrantyManage.form.segment.modal.segmentAndSubSegmentInformation', '')}
            secondaryTitle={''}
            onClickClose={this.warrantyManageSegment.onCloseSegmentInformationModal}
          />
        }
      </React.Fragment>
    )
  }
}
