import React from 'react';
import {
  connect,
} from 'react-redux';
import {
  ShapeContainer,
  Section,
  TableAction,
  Grid,
  Text,
  Button,
  PaginationControl,
  Table,
  Image,
} from './../../components';
import {
  VARIABLES,
  ICONS,
} from './../../themes';
import { getRedemptionList, getClearRedemptionParams } from '../../helpers/services/redemption';
import { redirect } from '../../helpers/functions/route';
import { ROUTE_PATH } from '../../helpers/constants/route';
import { getSortAscending } from '../../helpers/functions/data';
import { getLanguage } from '../../helpers/functions/language';
import _ from 'lodash';
import { ENUM } from '../../helpers/constants/enum';
import { getUserAuthRedux } from '../../helpers/functions/auth';

class RedemptionHistoryListContainer extends React.Component {

  constructor(props) {
    super(props);
    const userAuth = getUserAuthRedux();
    this.state = {
      activePage: 1,
      dataPerPage: 10,
      customerId: props.customerId,
      userAuth
    }
  }

  componentDidMount() {
    const {
      activePage,
      dataPerPage,
      customerId,
    } = this.state;
    const params = {
      ...getClearRedemptionParams(),
      page: activePage,
      limit: dataPerPage,
      customer_id: customerId,
    };
    getRedemptionList(params);
  }

  RedemptionhistoryList = {
    onClickSort: (target) => {
      getRedemptionList({
        isNoSort: false,
        sort: target
      });
    }
  }

  pagination = {
    onPaginationChange: (pageNumber) => {
      getRedemptionList({
        isNoSort: true,
        page: pageNumber,
      });
    }
  }

  render() {
    const {
      dataList,
      dataParams,
      totalData,
      activePage,
      onClickEdit,
      isMalay,
    } = this.props;
    const {
      dataPerPage,
      userAuth,
    } = this.state;
    return (
      <ShapeContainer
        ui='contentFilter'
        justify='space-between'
      >
        <Section spacingBottom='45'>
          <Table
            striped
            action
          >
            <Table.Section>
              <Table.Row>
                <Table.HeadColumn
                  maxWidth={VARIABLES.TABLE.INFO_WIDTHS.REDEMPTION_ID}
                  minWidth={VARIABLES.TABLE.INFO_WIDTHS.REDEMPTION_ID}
                  sortAscending={getSortAscending(dataParams, 'id')}
                  onClickSort={() => {
                    this.RedemptionhistoryList.onClickSort('id desc');
                  }}
                  title={getLanguage('container.redemptionRewardHistory.table.header.redemptionID', '')}
                >
                  {getLanguage('container.redemptionRewardHistory.table.header.redID', '')}
                </Table.HeadColumn>
                <Table.HeadColumn
                  minWidth={VARIABLES.TABLE.INFO_WIDTHS.REWARDS}
                  sortAscending={getSortAscending(dataParams, 'reward_name')}
                  onClickSort={() => {
                    this.RedemptionhistoryList.onClickSort('reward_name desc');
                  }}
                >
                  {getLanguage('container.redemptionRewardHistory.table.header.rewards', '')}
                </Table.HeadColumn>
                <Table.HeadColumn
                  minWidth={VARIABLES.TABLE.INFO_WIDTHS.CUS_ID}
                  sortAscending={getSortAscending(dataParams, 'customer_id')}
                  onClickSort={() => {
                    this.RedemptionhistoryList.onClickSort('customer_id desc');
                  }}
                  title={getLanguage('container.redemptionRewardHistory.table.header.customerID', '')}
                >
                  {getLanguage('container.redemptionRewardHistory.table.header.cusID', '')}
                </Table.HeadColumn>
                <Table.HeadColumn
                  minWidth={VARIABLES.TABLE.INFO_WIDTHS.CUS_NAME}
                  sortAscending={getSortAscending(dataParams, 'customer_name')}
                  onClickSort={() => {
                    this.RedemptionhistoryList.onClickSort('customer_name desc');
                  }}
                  title={getLanguage('container.redemptionRewardHistory.table.header.customerName', '')}
                >
                  {getLanguage('container.redemptionRewardHistory.table.header.cusName', '')}
                </Table.HeadColumn>
                <Table.HeadColumn
                  minWidth={VARIABLES.TABLE.INFO_WIDTHS.PHONE}
                  sortAscending={getSortAscending(dataParams, 'phone_no')}
                  onClickSort={() => {
                    this.RedemptionhistoryList.onClickSort('phone_no desc');
                  }}
                >
                  {getLanguage('container.redemptionRewardHistory.table.header.phoneNo', '')}
                </Table.HeadColumn>
                <Table.HeadColumn
                  minWidth={VARIABLES.TABLE.INFO_WIDTHS.SALESPERSON}
                  sortAscending={getSortAscending(dataParams, 'sales_person_name')}
                  onClickSort={() => {
                    this.RedemptionhistoryList.onClickSort('sales_person_name desc');
                  }}
                >
                  {getLanguage('container.redemptionRewardHistory.table.header.salesperson', '')}
                </Table.HeadColumn>
                <Table.HeadColumn
                  minWidth={VARIABLES.TABLE.INFO_WIDTHS.DATE_CREATE_REDEMPTION}
                  sortAscending={getSortAscending(dataParams, 'create_at')}
                  onClickSort={() => {
                    this.RedemptionhistoryList.onClickSort('create_at desc');
                  }}
                >
                  {getLanguage('container.redemptionRewardHistory.table.header.createDate', '')}
                </Table.HeadColumn>
                {
                  isMalay ?
                    <Table.HeadColumn
                      minWidth={VARIABLES.TABLE.INFO_WIDTHS.DATE_GIFT_PICK_UP_ABBREVIATION}
                      sortAscending={getSortAscending(dataParams, 'pickup_at')}
                      onClickSort={() => {
                        this.RedemptionhistoryList.onClickSort('pickup_at desc');
                      }}
                      title={getLanguage('container.redemptionRewardHistory.table.header.deliveryDate', '')}
                    >
                      {getLanguage('container.redemptionRewardHistory.table.header.deliveryDate', '')}
                    </Table.HeadColumn>
                    :
                    <Table.HeadColumn
                      minWidth={VARIABLES.TABLE.INFO_WIDTHS.DATE_GIFT_PICK_UP_ABBREVIATION}
                      sortAscending={getSortAscending(dataParams, 'pickup_at')}
                      onClickSort={() => {
                        this.RedemptionhistoryList.onClickSort('pickup_at desc');
                      }}
                      title={getLanguage('container.redemptionRewardHistory.table.header.giftDate', '')}
                    >
                      {getLanguage('container.redemptionRewardHistory.table.header.gpuDate', '')}
                    </Table.HeadColumn>
                }
                <Table.HeadColumn
                  alignRight
                  minWidth={VARIABLES.TABLE.INFO_WIDTHS.TB_POINTS_ABBREVIATION}
                  sortAscending={getSortAscending(dataParams, 'points')}
                  onClickSort={() => {
                    this.RedemptionhistoryList.onClickSort('points desc');
                  }}
                  title={getLanguage('container.redemptionRewardHistory.table.header.tbPoints', '')}
                >
                  {getLanguage('container.redemptionRewardHistory.table.header.tbPointsAbbreviation', '')}
                </Table.HeadColumn>
                {
                  userAuth.addressType === ENUM.COUNTRY_TYPE.VIETNAM &&
                  <Table.HeadColumn
                    alignRight
                    minWidth={VARIABLES.TABLE.INFO_WIDTHS.NUMBER_OF_REWARDS}
                    sortAscending={getSortAscending(dataParams, 'unit')}
                    onClickSort={() => {
                      this.RedemptionhistoryList.onClickSort('unit desc');
                    }}
                  >
                    {getLanguage('container.redemptionRewardHistory.table.header.numberOfRewards', '')}
                  </Table.HeadColumn>
                }
                <Table.HeadColumn
                  minWidth={VARIABLES.TABLE.INFO_WIDTHS.STOCK_TYPE}
                  sortAscending={() => { }}
                  onClickSort={() => { }}
                >
                  {getLanguage('container.redemptionRewardHistory.table.header.stockType', '')}
                </Table.HeadColumn>
                <Table.HeadColumn
                  noSort
                  alignCenter
                  maxWidth={VARIABLES.TABLE.INFO_WIDTHS.ACTION_EDIT_ICON}
                  minWidth={VARIABLES.TABLE.INFO_WIDTHS.ACTION_EDIT_ICON}
                >
                  {getLanguage('container.redemptionRewardHistory.table.header.action', '')}
                </Table.HeadColumn>
              </Table.Row>
            </Table.Section>
            <Table.Section>
              {
                dataList.map((e, i) => {
                  return (
                    <Table.Row
                      key={i}
                    >
                      <Table.BodyColumn
                        info
                        maxWidth={VARIABLES.TABLE.INFO_WIDTHS.REDEMPTION_ID}
                        minWidth={VARIABLES.TABLE.INFO_WIDTHS.REDEMPTION_ID}
                        title={e.idWithPrefix}
                      >
                        {e.idWithPrefix}
                      </Table.BodyColumn>
                      <Table.BodyColumn
                        info
                        minWidth={VARIABLES.TABLE.INFO_WIDTHS.REWARDS}
                        title={e.rewardName}
                      >
                        {e.rewardName}
                      </Table.BodyColumn>
                      <Table.BodyColumn
                        info
                        minWidth={VARIABLES.TABLE.INFO_WIDTHS.CUS_ID}
                        title={e.customerIdWithPrefix}
                      >
                        {e.customerIdWithPrefix}
                      </Table.BodyColumn>
                      <Table.BodyColumn
                        info
                        minWidth={VARIABLES.TABLE.INFO_WIDTHS.CUS_NAME}
                        title={e.customerName}
                      >
                        {e.customerName}
                      </Table.BodyColumn>
                      <Table.BodyColumn
                        phone
                        minWidth={VARIABLES.TABLE.INFO_WIDTHS.PHONE}
                        idPhone={e.phoneNoCountry.value}
                        srcFlag={e.phoneNoCountry.flag}
                      >
                        {e.phoneNo}
                      </Table.BodyColumn>
                      <Table.BodyColumn
                        info
                        minWidth={VARIABLES.TABLE.INFO_WIDTHS.SALESPERSON}
                        title={e.salesPersonName}
                      >
                        {e.salesPersonName}
                      </Table.BodyColumn>
                      <Table.BodyColumn
                        info
                        minWidth={VARIABLES.TABLE.INFO_WIDTHS.DATE_CREATE_REDEMPTION}
                      >
                        {e.displayCreateAt}
                      </Table.BodyColumn>
                      <Table.BodyColumn
                        info
                        minWidth={VARIABLES.TABLE.INFO_WIDTHS.DATE_GIFT_PICK_UP_ABBREVIATION}
                      >
                        {e.displayPickupAt}
                      </Table.BodyColumn>
                      <Table.BodyColumn
                        info
                        alignRight
                        minWidth={VARIABLES.TABLE.INFO_WIDTHS.TB_POINTS_ABBREVIATION}
                      >
                        {e.totalPointsWithComma}
                      </Table.BodyColumn>
                      {
                        userAuth.addressType === ENUM.COUNTRY_TYPE.VIETNAM &&
                        <Table.BodyColumn
                          info
                          alignRight
                          minWidth={VARIABLES.TABLE.INFO_WIDTHS.NUMBER_OF_REWARDS}
                          title={e.unitWithComma}
                        >
                          {e.unitWithComma}
                        </Table.BodyColumn>
                      }
                      <Table.BodyColumn
                        info
                        minWidth={VARIABLES.TABLE.INFO_WIDTHS.STOCK_TYPE}
                        title='STOCK_TYPE'
                      >
                        {e.stockType}
                      </Table.BodyColumn>
                      <Table.BodyColumn
                        action
                        alignCenter
                        maxWidth={VARIABLES.TABLE.INFO_WIDTHS.ACTION_EDIT_ICON}
                        minWidth={VARIABLES.TABLE.INFO_WIDTHS.ACTION_EDIT_ICON}
                      >
                        <Grid
                          gutter='5'
                          justify='center'
                        >
                          <Grid.Column>
                            {
                              e.isCanEdit &&
                              <Image
                                widthRatio='14'
                                widthRatioUnit='px'
                                heightRatio='14'
                                heightRatioUnit='px'
                                src={ICONS['ic-action-edit.svg']}
                                onClick={() => {
                                  onClickEdit(e);
                                }}
                              />
                            }
                            {/* <Button
                              lightBlue
                              ui='frontIconSmall'
                              name={getLanguage('container.redemptionRewardHistory.table.action.buttonEdit', '')}
                              iconWidth='10'
                              iconHeight='10'
                              iconSrc={ICONS['ic-edit.svg']}
                              onClick={() => {
                                onClickEdit(e);
                              }}
                            /> */}
                          </Grid.Column>
                          {/* <Grid.Column>
                            <Button
                              red
                              ui='frontIconSmall'
                              name='Delete'
                              iconWidth='8'
                              iconHeight='10'
                              iconSrc={ICONS['ic-delete.svg']}
                              onClick={() => { }}
                            />
                          </Grid.Column> */}
                        </Grid>
                      </Table.BodyColumn>
                    </Table.Row>
                  )
                })
              }
            </Table.Section>
          </Table>
        </Section>
        <TableAction>
          <TableAction.Left>
            {/* <Grid gutter='15'>
              <Grid.Column>
                <Button
                  blue
                  ui='frontIcon'
                  name='Print'
                  width={VARIABLES.BUTTON.WIDTHS.W_125}
                  iconWidth='18'
                  iconHeight='18'
                  iconSrc={ICONS['ic-printer.svg']}
                  onClick={() => { }}
                />
              </Grid.Column>
              <Grid.Column>
                <Button
                  green
                  ui='frontIcon'
                  name='Export'
                  width={VARIABLES.BUTTON.WIDTHS.W_125}
                  iconWidth='16'
                  iconHeight='16'
                  iconSrc={ICONS['ic-export.svg']}
                  onClick={() => { }}
                />
              </Grid.Column>
            </Grid> */}
          </TableAction.Left>
          <TableAction.Right>
            <Grid gutter='20'>
              <Grid.Column>
                <Section paddingTop='6'>
                  <Text
                    regular12
                    color={VARIABLES.COLORS.GRAY_16}
                  >
                    {getLanguage('pagination.page', '')} {activePage}/{Math.ceil(totalData / dataPerPage)}
                  </Text>
                </Section>
              </Grid.Column>
              <Grid.Column>
                <PaginationControl
                  activePage={activePage}
                  itemsCountPerPage={dataPerPage}
                  totalItemsCount={totalData}
                  pageRangeDisplayed={3}
                  onChange={this.pagination.onPaginationChange}
                />
              </Grid.Column>
            </Grid>
          </TableAction.Right>
        </TableAction>
      </ShapeContainer>
    )
  }
}

const mapStateToProps = (state) => {
  const dataRedux = state.dataRedux;
  return {
    dataList: dataRedux.redemptionList,
    dataParams: dataRedux.redemptionParams,
    activePage: dataRedux.redemptionParams.activePage,
    totalData: dataRedux.redemptionParams.totalData || 0,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
  }
}

export const RedemptionHistoryListContainerConnected = connect(
  mapStateToProps,
  mapDispatchToProps
)(RedemptionHistoryListContainer)