import React from 'react'
import ClassNames from 'classnames'
import PropTypes from 'prop-types'
import {
  osName,
} from 'react-device-detect'
import {
  WarrantyReplacementWrapper
} from './styled'
import {
  Section,
  Text,
  Image,
  Grid,
  Table,
  ShapeContainer,
} from './../../../../components'
import {
  VARIABLES,
} from './../../../../themes'

/**
 * Warranty description:
 * - Warranty
 */

export class WarrantyBSWMIndonesiaReplacement extends React.PureComponent {
  render() {
    const {
      registeredTrademarkName,
    } = this.props

    // props for css classes
    const classes = ClassNames(
      'warranty-bswm-note',
    )

    return (
      <WarrantyReplacementWrapper
        className={classes}
      >
        <Section spacingBottom='5'>
          <p className='warranty-bswm-text is-xs'>Should perforation by natural weathering occur in less than the warranted period, NSBI liability shall be limited exclusively to the prorata share of the <span className='warranty-bswm-text is-xs is-bold'>{registeredTrademarkName}</span>, for the replacement of the affected portion of the product as follows.</p>
        </Section>
        <Section spacingBottom='5'>
          <p className='warranty-bswm-text is-xs'>Replacement <span className='warranty-bswm-text is-xs is-bold'>{registeredTrademarkName}</span>, will be supplied at a discount, which nears the same ratio to the then current price as that part of the warranty period, not achieved bears to the full warranty period.</p>
        </Section>
        <Section>
          <p className='warranty-bswm-text is-xs'>NSBI SHALL ONLY BE LIABLE FOR:</p>
        </Section>
        <Section paddingLeft='17' spacingBottom='5'>
          <Grid>
            <Grid.Column flex='none'><Section width='17'><p className='warranty-bswm-text is-xs'>a.</p></Section></Grid.Column>
            <Grid.Column flex='1'><p className='warranty-bswm-text is-xs'>The cost of replacing the product;</p></Grid.Column>
          </Grid>
          <Grid>
            <Grid.Column flex='none'><Section width='17'><p className='warranty-bswm-text is-xs'>b.</p></Section></Grid.Column>
            <Grid.Column flex='1'><p className='warranty-bswm-text is-xs'>The cost of obtaining equivalent product; or</p></Grid.Column>
          </Grid>
          <Grid>
            <Grid.Column flex='none'><Section width='17'><p className='warranty-bswm-text is-xs'>c.</p></Section></Grid.Column>
            <Grid.Column flex='1'><p className='warranty-bswm-text is-xs'>The cost of having the project repaired</p></Grid.Column>
          </Grid>
          <Section spacingTop='5'>
            <p className='warranty-bswm-text is-xs'>Whichever is the lowest</p>
          </Section>
        </Section>
        <Section>
          <p className='warranty-bswm-text is-xs'>NSBI SHALL NOT BE LIABLE FOR:</p>
        </Section>
        <Section paddingLeft='17' spacingBottom='5'>
          <Grid>
            <Grid.Column flex='none'><Section width='17'><p className='warranty-bswm-text is-xs'>a.</p></Section></Grid.Column>
            <Grid.Column flex='1'><p className='warranty-bswm-text is-xs'>Labour cost associated with the installation or removal of the affected product or the fixing of replacement sheets; or</p></Grid.Column>
          </Grid>
          <Grid>
            <Grid.Column flex='none'><Section width='17'><p className='warranty-bswm-text is-xs'>b.</p></Section></Grid.Column>
            <Grid.Column flex='1'><p className='warranty-bswm-text is-xs'>Injury to persons, or damage to property or consequential losses arising from the use of the product.</p></Grid.Column>
          </Grid>
        </Section>
        <Section spacingBottom='5'>
          <p className='warranty-bswm-text is-xs'>NSBI extends this warranty solely to the manufacturer.</p>
        </Section>
        <Section>
          <p className='warranty-bswm-text is-xs'>This warranty is given lieu of all other considerations or warranties expressed or implied except to the extent that any statute applicable to this contract prevents the exclusion restrictions or modification of such conditions or warranties.</p>
        </Section>
      </WarrantyReplacementWrapper>
    )
  }

  static defaultProps = {
    // If you set new default props, you should create propsType for correct styleguide document.
  }

  static propTypes = {
    // TYPE > node, string, number, bool, array, object, symbol, func
  }
}
