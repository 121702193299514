import { displayWithPrefix, displayDateTime } from "../helpers/functions/display";
import { ModelImage } from "./ModelImage";
import { ENUM } from "../helpers/constants/enum";
import _ from 'lodash';
import { getLanguage } from "../helpers/functions/language";

export class ModelSegment {
  constructor(props = {}) {
    this.segmentBuildingList = props.segment_building || [];

    this.segmentBuildingList = this.segmentBuildingList.map(e => {
      if (e === ENUM.SEGMENT_BUILDING.FACTORY.VALUE) {
        return getLanguage('warrantyManage.form.segment.building.factory');
      }
      if (e === ENUM.SEGMENT_BUILDING.OFFICE.VALUE) {
        return getLanguage('warrantyManage.form.segment.building.office');
      }
      if (e === ENUM.SEGMENT_BUILDING.CAR_PARK.VALUE) {
        return getLanguage('warrantyManage.form.segment.building.carPark');
      }
      if (e === ENUM.SEGMENT_BUILDING.WAREHOUSE.VALUE) {
        return getLanguage('warrantyManage.form.segment.building.warehouse');
      }
    });

    this.segmentDescEN = props.segment_desc_en || '';
    this.segmentDescTH = props.segment_desc_th || '';
    this.segmentId = props.segment_id || 0;
    this.segmentImg = new ModelImage({ path: props.segment_img || '' });
    this.segmentNameEN = props.segment_name_en || '';
    this.segmentNameTH = props.segment_name_th || '';
    this.subSegmentList = (props.sub_segment || []).map(e => {
      return {
        ...new ModelSubSegment(e),
        segmentNameEN: this.segmentNameEN,
        segmentNameTH: this.segmentNameTH,
        segmentBuildingList: this.segmentBuildingList.map(e => {
          return {
            label: e,
            value: e,
          }
        }),
        isSegmentBuildingSelected: this.segmentBuildingList.length > 0,
      }
    });
    this.createdDate = props.created_date || '';
    this.updatedDate = props.updated_date || '';
    this.isSegmentCateRes = props.segment_cate_res || false;
    this.isSegmentCateNonRes = props.segment_cate_non_res || false;

    this.segmentIdWithPrefix = displayWithPrefix(this.segmentId);
    this.isSegmentBuildingSelected = this.segmentBuildingList.length > 0;
    this.displayCreatedDate = this.createdDate ? displayDateTime(this.createdDate) : '-';
    this.displayUpdatedDate = this.updatedDate ? displayDateTime(this.updatedDate) : '-';
    this.segmentOldImg = _.cloneDeep(this.segmentImg);
  }
}

export class ModelSubSegment {
  constructor(props = {}) {
    this.segmentId = props.segment_id || 0;
    this.subSegmentDescEN = props.sub_segment_desc_en || '';
    this.subSegmentDescTH = props.sub_segment_desc_th || '';
    this.subSegmentId = props.sub_segment_id || 0;
    this.subSegmentImg = new ModelImage({ path: props.sub_segment_img || '' });
    this.subSegmentNameEN = props.sub_segment_name_en || '';
    this.subSegmentNameTH = props.sub_segment_name_th || '';
    this.subSegmentSeq = props.sub_segment_seq || 0;

    this.subSegmentIdWithPrefix = displayWithPrefix(this.subSegmentId);
    this.subSegmentOldImg = _.cloneDeep(this.subSegmentImg);
  }
}