// //////////////////////////////////////////////////////////////////////////////////////////////////
// ==================================================================================================
//
// Variables:
//
// * Factors
// * Website width
// * Colors
// * Font
// * Z indexs
// * Breakpoints
// * Transitions
// * Animation timings
// * Components
//   - Scrollbar
//
// ==================================================================================================
// //////////////////////////////////////////////////////////////////////////////////////////////////

// Factors (using in variables)
// ============================================================
const SPACING_FACTOR = 5;
const GRID_SYSTEM = 12;

export default {
  // Factors
  // ============================================================
  FACTORS: {
    SPACING: SPACING_FACTOR,
    GRID: GRID_SYSTEM,
  },

  // Website width
  // ============================================================
  WEBSITE_WIDTH: 1440,

  // Colors
  // ============================================================
  COLORS: {
    // Base
    // ------------------------------
    // Transparent
    TRANSPARENT: "transparent",
    // Black
    BLACK: "#000000",
    // White
    WHITE: "#FFFFFF",
    // Gray
    GRAY_1: "#F8F8F8",
    GRAY_2: "#F1F1F1",
    GRAY_3: "#EEEEEE",
    GRAY_4: "#EDEDEC",
    GRAY_5: "#E9E9F0",
    GRAY_6: "#E8E9EC",
    GRAY_7: "#E1E1E1",
    GRAY_8: "#DDDDDD",
    GRAY_9: "#D9D9D9",
    GRAY_10: "#CECECE",
    GRAY_11: "#CFCFCF",
    GRAY_12: "#CBCBCB",
    GRAY_13: "#8E95A1",
    GRAY_14: "#A3A6B4",
    GRAY_15: "#808495",
    GRAY_16: "#777777",
    GRAY_17: "#707070",
    GRAY_18: "#7A7A7A",
    GRAY_19: "#898989",
    GRAY_20: "#43425D",
    GRAY_21: "#4D4F5C",
    GRAY_22: "#4B4B4B",
    GRAY_23: "#373A3C",
    GRAY_24: "#D1D1D1",
    GRAY_25: "#92929F",
    GRAY_26: "#BFBEC7",
    GRAY_27: "#696969",
    GRAY_28: "#F7F7F7",
    GRAY_29: "#AAAAAA",
    GRAY_30: "#F0F0F0",
    GRAY_31: "#6A6A6A",
    GRAY_32: "#111111",
    // Red
    RED_1: "#FFE8E8",
    RED_2: "#FF6565",
    RED_3: "#FF4141",
    RED_4: "#FF2929",
    RED_5: "#CC001E",
    RED_6: "#C1A193",
    RED_7: "#C87165",
    RED_8: "#C64040",
    // Green
    GREEN_1: "#5EE2A0",
    GREEN_2: "#20D959",
    GREEN_3: "#3CC480",
    GREEN_4: "#01B79E",
    GREEN_5: "#09842F",
    GREEN_6: "#449C2E",
    GREEN_7: "#ABF0CE",
    GREEN_8: "#1C842F",
    // Blue
    BLUE_1: "#F1F9FD",
    BLUE_2: "#EAF0F4",
    BLUE_3: "#EFF6FA",
    BLUE_4: "#F5FAFE",
    BLUE_5: "#F6F9FA",
    BLUE_6: "#EEF7FD",
    BLUE_7: "#E0F1FB",
    BLUE_8: "#E7F4FC",
    BLUE_9: "#E4F2FC",
    BLUE_10: "#EBF5FD",
    BLUE_11: "#D8EDFA",
    BLUE_12: "#DDEFFB",
    BLUE_13: "#CDE8F9",
    BLUE_14: "#C7E5F8",
    BLUE_15: "#CFDFE5",
    BLUE_16: "#C1D9EA",
    BLUE_17: "#D2EAF9",
    BLUE_18: "#54D8FF",
    BLUE_19: "#6CCCE2",
    BLUE_20: "#5FABDE",
    BLUE_21: "#328BC8",
    BLUE_22: "#196FAA",
    BLUE_23: "#006CB6",
    BLUE_24: "#004698",
    BLUE_25: "#A8EBFF",
    BLUE_26: "#8399C1",
    BLUE_27: "#4F81BD",
    BLUE_28: "#C6D9F0",
    BLUE_29: "#C2D3E8",
    BLUE_30: "#DBE5F1",
    BLUE_31: "#EEF8FF",
    // Brown
    BROWN_1: "#E1C5A0",
    BROWN_2: "#DEA85F",
    BROWN_3: "#C89440",
    BROWN_4: "#DF9E5B",
    // Purple
    PURPLE_1: "#A3A1FB",
    PURPLE_2: "#D0CFFD",
    // Orange
    ORANGE_1: "#EFC553",
    ORANGE_2: "#FF9429",
    ORANGE_3: "#E36C09",
    ORANGE_4: "#FDE9D9",
    ORANGE_5: "#FACFAC",
    ORANGE_6: "#F79646",
    ORANGE_7: "#FBD5B5",
    // Gradient
    GRADIENT_BLACK_1_START: "rgba(0, 0, 0, 0)",
    GRADIENT_BLACK_1_END: "rgba(0, 0, 0, 0.65)",
    GRADIENT_GREEN_1_START: "rgba(94, 226, 160, 1)",
    GRADIENT_GREEN_1_END: "rgba(94, 226, 160, 0.5)",
    GRADIENT_PURPLE_1_START: "rgba(163, 160, 251, 1)",
    GRADIENT_PURPLE_1_END: "rgba(163, 160, 251, 0.5)",
    GRADIENT_BLUE_1_START: "rgba(84, 261, 255, 1)",
    GRADIENT_BLUE_1_END: "rgba(84, 261, 255, 0.5)",
    GRADIENT_BLUE_2_START: "#3E90C9",
    GRADIENT_BLUE_2_END: "#1A5C89",
    GRADIENT_BLUE_3_START: "#273B78",
    GRADIENT_BLUE_3_END: "#477DCF",
    // Overlay
    OVERLAY_BLACK_1: "rgba(0, 0, 0, 0.7)",
    OVERLAY_BLACK_2: "rgba(0, 0, 0, 0.35)",
    OVERLAY_WHITE_1: "rgba(255, 255, 255, 0.3)",
    OVERLAY_WHITE_2: "rgba(255, 255, 255, 0.65)",
    OVERLAY_GREEN_1: "rgba(32, 217, 89, 0.24)",
    OVERLAY_BLUE_1: "rgba(25, 111, 170, 0.54)",
    OVERLAY_BLUE_2: "rgba(95, 171, 222, 0.2)",
    OVERLAY_BLUE_3: "rgba(212, 230, 242, 0.8)",
    OVERLAY_RED_1: "rgba(255, 232, 232, 0.75)",
    // Shadow
    SHADOW_1: "3px 1px 6px rgba(0, 0, 0, 0.16)",
    SHADOW_2: "0 3px 6px rgba(0, 0, 0, 0.16)",
    SHADOW_3: "0 3px 10px rgba(0, 0, 0, 0.16)",
    SHADOW_4: "0 0 30px rgba(0, 0, 0, 0.15)",
    SHADOW_5: "0 1px 3px rgba(0, 0, 0, 0.05)",
    SHADOW_6: "0 0 10px rgba(0,0,0,0.1)",

    // Apply - bring 'Base' color create a new name for easy using
    // ------------------------------
    // Primary
    PRIMARY_1: "#5FABDE",
    PRIMARY_2: "#196FAA",
    // Status
    DISABLED: "#CBCBCB",
    ERROR: "#FF2929",
    SUCCESS: "#01B79E",
    // Scrollbar
    SCROLLBAR_DEFAULT: "#B5B5B5",
    SCROLLBAR_HOVER: "#858585",
    SCROLLBAR_ACTIVE: "#858585",
  },

  // Font
  // ============================================================
  FONT: {
    FAMILIES: {
      FIRST: {
        WEIGHTS: {
          REGULAR: "Sarabun-Regular",
          MEDIUM: "Sarabun-Medium",
          BOLD: "Sarabun-Bold",
        },
        SIZES: {
          FS_36: 36, // Before > 48
          FS_28: 28, // Before > 36
          FS_24: 24, // Before > 30
          FS_22: 22, // Before > 28
          FS_20: 20, // Before > 25
          FS_18: 18, // Before > 22
          FS_16: 16, // Before > 20
          FS_14: 14, // Before > 18
          FS_12: 12, // Before > 16
          FS_11: 11, // Before > 15
          FS_10: 10, // Before > 14
          FS_9: 9, // Before > 12
          FS_8: 8, // Before > 11
          FS_7: 7, // Before > 10
        },
        LINE_HEIGHT_FACTOR: 1.3,
      },
    },
  },

  // Zindexs
  // ============================================================
  Z_INDEXS: {
    LV_1: 1,
    LV_2: 9,
    LV_3: 10,
    LV_4: 11,
    LV_5: 99,
    LV_6: 100,
    LV_7: 101,
    LV_8: 999,
    LV_9: 1000,
    LV_10: 1001,
  },

  // Breakpoints
  // ============================================================
  BREAKPOINTS: {
    // Mobile
    BP_320: "320px",
    BP_321: "321px",
    BP_359: "359px",
    BP_360: "360px",
    BP_361: "361px",
    BP_374: "374px",
    BP_375: "375px",
    BP_376: "376px",
    BP_413: "413px",
    BP_414: "414px",
    BP_415: "415px",

    // Phablet
    BP_479: "479px",
    BP_480: "480px",
    BP_481: "481px",
    BP_639: "639px",
    BP_640: "640px",
    BP_641: "641px",
    BP_666: "666px",
    BP_667: "667px",
    BP_668: "668px",
    BP_735: "735px",
    BP_736: "736px",
    BP_737: "737px",

    // Tablet
    BP_767: "767px",
    BP_768: "768px",
    BP_769: "769px",
    BP_811: "811px",
    BP_812: "812px",
    BP_813: "813px",
    BP_1023: "1023px",
    BP_1024: "1024px",
    BP_1025: "1025px",

    // Laptop
    BP_1279: "1279px",
    BP_1280: "1280px",
    BP_1281: "1281px",
    BP_1365: "1365px",
    BP_1366: "1366px",
    BP_1367: "1367px",
    BP_1439: "1439px",
    BP_1440: "1440px",
    BP_1441: "1441px",
    BP_1599: "1599px",
    BP_1600: "1600px",
    BP_1601: "1601px",

    // Desktop
    BP_1919: "1919px",
    BP_1920: "1920px",
    BP_1921: "1921px",
    BP_2559: "2559px",
    BP_2560: "2560px",
  },

  // Transitions
  // ============================================================
  TRANSITIONS: {
    DEFAULT: "all 0.3s ease",
    TRANSITION_1_MS: 'all 0.1s ease'
  },

  // Animation timings
  // ============================================================
  ANIMATION_TIMINGS: {
    ELASTIC: "cubic-bezier(.835, -.005, .06, 1)",
  },

  // Components
  // ============================================================

  // Button
  // ------------------------------
  BUTTON: {
    HEIGHTS: {
      H_20: 20,
      H_32: 32,
    },
    BORDER_RADIUSES: {
      BR_3: 3,
    },
    PADDINGS: {
      P_10: 10,
      P_15: 15,
      P_20: 20,
      P_25: 25,
      P_35: 35,
    },
    WIDTHS: {
      W_83: 83,
      W_100: 100,
      W_105: 105,
      W_120: 120,
      W_125: 125,
      W_126: 126,
      W_128: 128,
      W_132: 132,
      W_135: 135,
      W_140: 140,
      W_145: 145,
      W_147: 147,
      W_155: 155,
      W_160: 160,
      W_162: 162,
      W_165: 165,
      W_170: 170,
      W_172: 172,
      W_180: 180,
      W_183: 183,
      W_187: 187,
      W_200: 200,
      W_228: 228,
      W_255: 255,
      W_310: 310,
      W_360: 360,
    },
  },

  // Scrollbar
  // ------------------------------
  SCROLLBAR: {
    WIDTH: 8,
    HEIGHT: 8,
    BORDER_RADIUS: 8,
  },

  // Field
  // ------------------------------
  FIELD: {
    HEIGHT: 32,
    PADDINGS: {
      TOP: 12,
      BOTTOM: 6,
      TOP_BLOCK: 12,
      BOTTOM_BLOCK: 7,
      HORIZONTAL: 15,
    },
    BORDER_RADIUS: 3,
  },

  // Modal
  // ------------------------------
  MODAL: {
    WIDTHS: {
      W_1165: 1165,
      W_1145: 1145,
      W_1032: 1032,
      W_1024: 1024,
      W_960: 960,
      W_770: 770,
      W_768: 768,
      W_660: 660,
      W_640: 640,
      W_560: 560,
      W_530: 530,
      W_526: 526,
      W_480: 480,
      W_327: 327,
    },

    CONTAINER: {
      C_1: {
        TRANSFORM_START: "translateY(-30px)",
        TRANSFORM_END: "translateY(0)",
        SPACING: 30,
      },
    },

    HEADER: {
      H_1: {
        PADDING_TOP: 45,
        PADDING_BOTTOM: 20,
        PADDING_HORIZONTAL: 55,
        BORDER_BOTTOM_WIDTH: 1,
        BORDER_BOTTOM_STYLE: "solid",
        BORDER_TOP_RIGHT_RADIUS: 10,
        BORDER_TOP_LEFT_RADIUS: 10,
      },
    },

    BODY: {
      B_1: {
        PADDING_BOTTOM: 55,
        PADDING_HORIZONTAL: 55,
        PADDING_VERTICAL: 55,
      },
    },

    FOOTER: {
      F_1: {
        PADDING_TOP: 35,
        PADDING_BOTTOM: 45,
        PADDING_HORIZONTAL: 55,
        BORDER_TOP_WIDTH: 1,
        BORDER_TOP_STYLE: "solid",
        BORDER_BOTTOM_RIGHT_RADIUS: 10,
        BORDER_BOTTOM_LEFT_RADIUS: 10,
      },
    },
  },

  // CMS Layout
  // ------------------------------
  CMS_LAYOUT: {
    TOPBAR: {
      HEIGHT: 65,
    },

    SIDEBAR: {
      WIDTH: 245, // 205
    },

    CONTENT: {},
  },

  // Notification
  // ------------------------------
  NOTIFICATION: {
    WIDTHS: {
      DEFAULT: 535, // Not use 'W_' becuase this variable is global
    },
    BUTTON_CLOSE: {
      WIDTH: 75,
    },
  },

  // Table
  // ------------------------------
  TABLE: {
    BODY_SCROLL_CONTAINER_HEIGHT: "355px",
    INFO_WIDTHS: {
      DATE: 75,
      DATE_CREATE: 100,
      DATE_ORDER: 100,
      DATE_ORDER_NO_SORT: 80,
      DATE_TIME: 135,
      DATE_CREATE_REDEMPTION: 115,
      DATE_GIFT_PICK_UP: 120,
      DATE_GIFT_PICK_UP_ABBREVIATION: 95,
      DATE_PURCHASING: 100,
      DATE_PURCHASING_WITH_TIME: 110,
      DATE_UPLOADED_FILE: 125,
      //
      TRAINED: 75,
      LEVEL: 75,
      COLOR: 105,
      COLOR_SHORT: 60,
      AGING: 85, // 65
      SELECTED_ITEM: 90,
      QUANTITY: 60,
      QUANTITY_SHEET: 85,
      QUANTITY_UNIT: 125,
      TYPE: 100,
      STOCK_TYPE: 95,
      PROVINCE: 100,
      //
      ID: 60,
      ST_ID: 95,
      COIL_NUMBER: 105,
      COIL_ID: 105,
      COIL_ID_INFO: 105, // 90
      COIL_ID_NO_SORT: 60,
      COIL_ID_VIEW_MORE: 105, // 85
      COIL_ID_FULL: 105,
      SALES_TRANSACTION_ID: 145,
      SALES_TRANSACTION_ID_NO_SORT: 125,
      SALES_TRANSACTION_ID_NO_SORT_WARRANTY: 70,
      REDEMPTION_ID: 75,
      CUSTOMER_ID: 100,
      CUS_ID: 70,
      T1_SALES_TRANSACTION: 145,
      //
      COIL_NAME: 90,
      COIL_NAME_NO_SORT: 70,
      COIL_NAME_FULL: 350,
      COIL_DETAIL: 90,
      PROJECT_NAME: 120,
      PROJECT_NAME_NO_SORT: 85,
      PROJECT_NAME_FULL: 150,
      PROJECT_NAME_WARRANTY: 110,
      PROJECT_NAME_WARRANTY_FULL: 250,
      CONTACT_NAME: 120,
      CONTACT_NAME_WARRANTY: 120,
      CONTACT_NAME_WARRANTY_FULL: 250,
      CUSTOMER_NAME: 120,
      CUSTOMER_NAME_ABBREVIATION: 75,
      CUSTOMER_NAME_FULL: 250,
      CUS_NAME: 100,
      COMPANY_NAME: 120,
      NAME: 120,
      NICKNAME: 85,
      SALESPERSON: 100,
      SALESPERSON_ABBREVIATION: 80,
      SALESPERSON_NO_SORT: 75,
      SALESPERSON_FULL_NAME: 150,
      SUBJECT: 100,
      PROFILE: 80,
      PROFILE_FULL_NAME: 125,
      PRODUCT_GROUP: 135,
      PRODUCT_GROUP_NO_SORT: 115,
      PRODUCT_GROUP_NO_SORT_WARRANTY: 95, // 95
      REWARDS: 85,
      REWARDS_CONFIRM: 400,
      SALES_REPRESENTATIVE: 155,
      ERROR_DESCRIPTION: 300,
      HOMEOWNER: 80,
      HOMEOWNER_FULL: 150,
      //
      STATUS_ACKNOWLEDGED_PENDING: 110,
      STATUS_WARRANTY: 85,
      STATUS_TRANSACTION_FILE: 50,
      //
      EMAIL: 130,
      EMAIL_NO_SORT: 75,
      EMAIL_FULL: 250,
      PHONE: 140, // 130
      PHONE_GLOBAL: 140,
      PHONE_GLOBAL_LONG: 150,
      PHONE_NO_SORT: 85,
      ORDER_M: 90,
      ORDER_M_NO_SORT: 70,
      TRUEBLUE_POINTS: 120,
      TRUEBLUE_POINTS_NO_SORT: 100,
      NUMBER_OF_REWARDS: 140,
      NUMBER_OF_REWARDS_ABBREVIATION: 75,
      TB_POINTS: 95,
      TB_POINTS_ABBREVIATION: 85, // 80
      EXPIRING_TRUEBLUE_POINTS_IN_THIS_QUARTER: 245,
      THICKNESS: 90, // 95
      THICKNESS_BMT: 120, // 95
      THICKNESS_APT_TCT: 165,
      LENGTH_M: 100,
      LENGTH_UNIT: 125,
      LENGTH_BALANCE_M: 145,
      LENGTH_BALANCE_M_ABBREVIATION: 125,
      LENGTH_SOLD: 75,
      TRANSFER_AMOUNT_M: 130,
      TRANSFER_AMOUNT_M_SHORT: 100,
      TRANSFER_AMOUNT_M_INFO: 150,
      REMAINED_WEIGHT_KG: 155,
      REMAINED_WEIGHT_KG_ABBREVIATION: 135,
      WARRANTY_NUMBER: 140, // 150
      INVOICE_NUMBER: 75,
      INVOICE_NUMBER_FULL: 100,
      INVOICE_LINE: 95,
      INVOICE_LINE_ITEM: 130,
      INVOICE_LINE_ITEM_SHORT: 110,
      REVENUE: 65,
      ORDER_NO: 85,
      ORDER_NUMBER: 115,
      ORDER_NUMBER_NO_SORT: 95,
      PURCHASE_LENGTH_M: 145,
      PURCHASE_VALUE: 95,
      PURCHASE_VALUE_ABBREVIATION: 75,
      LIFETIME: 80,
      WEIGHT_TON: 105,
      WEIGHT_KG: 100,
      IN_STOCK: 75,
      REWARD_POINT: 115,
      USED_THE_TRUEBLUE_POINTS: 175,
      PROFILE_QUANTITY_UNIT: 115,
      PROFILE_LENGTH_UNIT: 95,
      TOTAL_LENGTH_M: 120,
      UNIT: 100,
      REMAINING_LENGTH: 130,
      //
      ACTION_EDIT: 70,
      ACTION_EDIT_ICON: 50,
      ACTION_DELETE: 80,
      ACTION_DELETE_ICON: 50,
      ACTION_DETAILS: 80,
      ACTION_CUSTOMER: 135,
      ACTION_VIEW_MORE: 50, // 75 > 90
      ACTION_VIEW_MORE_DETAIL: 115,
      ACTION_EDIT_LENGTH: 100,
      ACTION_ERROR: 200, // 40
      ACTION_DETAIL: 65,
      ACTION_PICK_QUANTITY: 125,
      //
      CHECKBOX_CIRCLE: 30,
      INPUT_ORDER: 75,
      INPUT_UNIT: 135,
      CHECKBOX_SQUARE: 45,
      CHECKBOX_ALL_REMAINING_LENGTH: 130,
      FIELD_ALL_REMAINING_LENGTH: 150,
    },

    COLUMN_WIDTHS: {
      PHONE: 150,
      EMAIL: 200,
      POINT: 140,
      REWARD_POINT: 160,
      TRAIN: 100,
      SYNC: 100,
      THICKNESS: 120,
      WIDTH: 100,
      WIDTH_SHORT: 75,
      COLOR: 100,
      AGING: 100,
      UNIT: 100,
      UNIT_SHORT: 75,
      USED_THE_POINTS: 175,
      T1_SALES_TRANSACTION: 200,
      CHECKBOX: 50,
      LIFETIME: 110,
      STATUS: 150,
      STATUS_WARRANTY: 175,
      SELECTED_ITEM: 100,
      SELECTED_ITEM_ICON: 130,
      TYPE: 80,
      DATES: {
        CREATE: 175,
        CREATE_NOTIME: 140,
        CREATE_NOTIME_SHORT: 100,
        EXPIRE: 275,
        DEFAULT: 110,
        GIFT_PICKUP: 185,
        SALES: 140,
        LAST_CHANGE: 200,
        ORDER_NOTIME: 100,
        PURCHASE: 135,
      },
      IDS: {
        INDEX: 75,
        PERSON: 150,
        COIL: 105,
        REDEMPTION: 175,
        CUSTOMER: 150,
        SALES_TRANSACTION: 150,
      },
      NAMES: {
        FULL: 175,
        NICK: 125,
        COIL: 350,
        COIL_SHORT: 175,
        COIL_INFO: 225,
        COIL_INNER: 640,
        COIL_DETAIL: 350,
        REWARD: 300,
        SALES_REPRESENTATIVE: 215,
        PRODUCT: 250,
        SUBJECT: 250,
        PROJECT: 230,
        PRODUCT_GROUP: 175,
        PRODUCT_GROUP_SHORT: 145,
        PRODUCT_GROUP_ABBREVIATION: 90,
        COIL_DESCRIPTION: 250,
        TYPE: 150,
        PROVINCE: 150,
        PROFILE_TYPE: 150,
        PROFILE_TYPE_SHORT: 100,
        PROFILE_TYPE_ABBREVIATION: 75,
      },
      ACTIONS: {
        ACT_1: 215,
        ACT_2: 490,
        ACT_3: 110,
        ACT_4: 170,
        ACT_5: 150,
        ACT_6: 135,
        ACT_7: 125,
        ACT_8: 109,
        ACT_9: 138,
        ACT_10: 115,
      },
      NUMBERS: {
        ORDER: 100,
        EARN_POINT: 150,
        REWARD: 145,
        COIL: 150,
        WARRANTY: 205,
        QUANTITY: 90,
      },
      MEASUREMENTS: {
        ORDER: 120,
        LENGTH_BALANCE: 150,
        WEIGHT_BALANCE: 165,
        REMAINED_WEIGHT: 165,
        PURCHASE_LENGTH: 145,
        LENGTH: 200,
        LENGTH_NUMBER: 100,
        LENGTH_NUMBER_ABBREVIATION: 75,
        WEIGHT: 200,
        WEIGHT_TON: 125,
        TRANSFER_AMOUNT: 165,
        QUANTITY_UNIT: 130,
        LENGTH_UNIT: 130,
        LENGTH_UNIT_SHORT: 100,
      },
      ICONS: {
        SELECTED: 45,
      },
      FIELDS: {
        ORDER: 135,
        ORDER_QUALITY: 135,
        UNIT: 145,
        TRANSFER_AMOUNT: 145,
        ALL_REMAINING_LENGTH: 145,
      },

      // Table name
      PROFILE_MAPPING_LIST: {
        AD: {
          COIL_NAME: 190,
          COIL_ID: 105,
          THICKNESS: 120,
          WIDTH: 75,
          CREATE_DATE: 125,
          QUANTITY: 100,
          UNIT: 75,
          LENGTH: 100,
          ACTION: 120,
        },

        TOKO: {
          PROFILE_TYPE: 150,
          PRODUCT_GROUP: 200,
          QUANTITY: 100,
          LENGTH: 135,
          DESCRIPTION: 150,
          ACTION: 130,
        },
      },
    },

    SALES_PERSON: {
      NUMBER: 75,
      NAME: 100,
      NICK_NAME: 100,
      JOB_POSITION: 100,
      MOBILE: 100,
      POINTS: 100,
      ACTIONS: 75,
    },

    WARRANTY_TEMPLATE: {
      COLUMN_WIDTHS: {
        TEMPLATE_NAME: 125,
        M3_PRODUCT_CODE: 125,
        M3_PRODUCT_GROUP: 125,
        M3_PRODUCT_COLOR: 125,
        ACTION: 75,
      },
    },

    SEGMENT_INFORMATION: {
      COLUMN_WIDTHS: {
        SEGMENT_NAME: 125,
        SUB_SEGMENT_NAME: 200,
        SEGMENT_DESCRIPTION: 145,
        SUB_SEGMENT_DESCRIPTION: 200,
      },
    },

    DOCUMENTARY: {
      COLUMN_WIDTHS: {
        SELECT: 50,
        CREATE_DATE: 100,
        WARRANTY_NO: 125,
        SALES_TRANSACTION_ID: 105,
        PROJECT_NAME: 135,
        CUSTOMER_NAME: 125,
        PRODUCT_GROUP: 125,
        MOBILE_NO: 135,
        STATUS: 65,
        ACTION: 60,
      },
    },

    DOCUMENTARY_MANAGE: {
      COLUMN_WIDTHS: {
        DOCUMENT: 200,
        SPECIFY_COMPANY_NAME: 200,
        CREATE_DATE: 100,
        ACTION: 60,
      },
    },
  },

  // Banner
  // ------------------------------
  BANNER: {
    NEWS_AND_PROMOTIONS_HEIGHT_RATIO: 57, // 23
  },
};
