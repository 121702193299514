import React from 'react'
import ClassNames from 'classnames'
// import PropTypes from 'prop-types'
import {
  FieldBase
} from './../../base'
import {
  Image,
  Grid,
  Text,
  Section,
  ShapeContainer,
  Button,
  SelectControl
} from './../../../components'
import {
  default as VARIABLES
} from './../../../themes/styles/bases/variables'
import {
  default as TYPOGRAPHYS
} from './../../../themes/styles/bases/typographys'
// import {
//   default as UTILITIES
// } from './../../../themes/styles/helpers/utilities'
import {
  ICONS
} from './../../../themes'
import { DATA } from '../../../helpers/constants/data'
import { getLanguage } from '../../../helpers/functions/language'
import _ from 'lodash';

/**
 * Field description:
 * - Field
 */

export class Field extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      selectedIdPhoneOption: null
    }
  }

  handleIdPhoneChange = selectedIdPhoneOption => {
    this.setState({
      selectedIdPhoneOption
    })
  }

  render() {
    let {
      className,
      ui,
      focus,
      required,
      srcUpload,
      onClickBrowse,
      onClickDelete,
      acceptFileType,
      changePassword,
      unit,
      onChange,
      border,
      onClickUnitMinus,
      onClickUnitPlus,
      isRemoveAddress,
      labelAddress,
      placeholderAddress,
      requiredAddress,
      nameAddress,
      valueAddress,
      onChangeAddress,
      errorInstallationAddress,
      isShowIndrustialEstate,
      requiredIndrustialEstateName,
      disabledSelectIndrustialEstateName,
      disabledIndrustialEstateName,
      isSearchableIndrustialEstateName,
      nameIndrustialEstateName,
      valueIndrustialEstateName,
      onChangeIndrustialEstateName,
      optionIndrustialEstateName,
      requiredWarrantyDistance,
      automaticEnvironmentData,
      isShowWarrantyDistance,
      disabledSelectWarrantyDistance,
      disabledWarrantyDistance,
      isSearchableWarrantyDistance,
      nameWarrantyDistance,
      valueWarrantyDistance,
      onChangeWarrantyDistance,
      optionWarrantyDistance,
      requiredProvince,
      isSearchableProvince,
      nameProvince,
      valueProvince,
      onChangeProvince,
      optionProvince,
      requiredCity,
      disabledSelectCity,
      disabledCity,
      isSearchableCity,
      nameCity,
      valueCity,
      onChangeCity,
      optionCity,
      requiredSubdistrict,
      isSearchableSubdistrict,
      nameSubdistrict,
      valueSubdistrict,
      onChangeSubdistrict,
      optionSubdistrict,
      requiredPostalCode,
      disabledSelectPostalCode,
      disabledPostalCode,
      isSearchablePostalCode,
      namePostalCode,
      valuePostalCode,
      onChangePostalCode,
      optionPostalCode,
      requiredDistrict,
      isSearchableDistrict,
      nameDistrict,
      valueDistrict,
      onChangeDistrict,
      optionDistrict,
      requiredAlley,
      nameAlley,
      valueAlley,
      onChangeAlley,
      requiredGroup,
      nameGroup,
      valueGroup,
      onChangeGroup,
      requiredStreet,
      nameStreet,
      valueStreet,
      onChangeStreet,
      requiredWard,
      nameWard,
      valueWard,
      onChangeWard,
      enableFieldIconAction,
      selectedPhoneCountry = DATA.PHONE_PREFIX_LIST[0],
      onChangePhoneCountry,
      disabledAddress,
      disabledProvince,
      disabledSubdistrict,
      disabledDistrict,
      disabledAlley,
      disabledGroup,
      disabledStreet,
      disabledWard,
      labelNoWrap,
      labelRequired,
      unitName,
      iconRight,
      disabledColor,
    } = this.props

    // props for css classes
    const classes = ClassNames(
      className,
      { 'is-focus': focus },
      { 'is-required': required },
      { 'is-change-password': changePassword },
      { 'is-unit': unit },
      { 'is-enable-field-icon-action': enableFieldIconAction },
      { 'is-label-nowrap': labelNoWrap },
      { 'is-icon-right': iconRight },
    )

    labelAddress = labelAddress ? labelAddress : getLanguage('field.address', '');
    placeholderAddress = placeholderAddress ? placeholderAddress : getLanguage('field.addressPlaceholder', '');

    return (
      <React.Fragment>
        {(() => {
          switch (ui) {
            case 'lineLabelVertical':
              return (
                <FieldBase
                  {...this.props}
                  className={ClassNames(
                    className,
                    'is-label-vertical',
                    { 'is-focus': focus },
                    { 'is-required': required },
                    { 'is-label-required': labelRequired },
                    { 'is-enable-field-icon-action': enableFieldIconAction },
                    { 'is-icon-right': iconRight },
                  )}
                  placeholderColor={VARIABLES.COLORS.GRAY_12}
                  labelFontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_14}
                  labelFontColor={VARIABLES.COLORS.GRAY_19}
                  fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_14}
                  fontColor={VARIABLES.COLORS.BLACK}
                  paddingTop={VARIABLES.FIELD.PADDINGS.TOP}
                  paddingBottom={VARIABLES.FIELD.PADDINGS.BOTTOM}
                  borderBottomWidth='1'
                  borderBottomStyle='solid'
                  borderBottomColor={VARIABLES.COLORS.GRAY_2}
                />
              )
            case 'lineLabelVerticalPhone':
              return (
                <FieldBase
                  {...this.props}
                  className={ClassNames(
                    className,
                    'is-label-vertical',
                    'is-enable-field-icon-action',
                    { 'is-focus': focus },
                    { 'is-required': required },
                    { 'is-label-required': labelRequired },
                  )}
                  placeholderColor={VARIABLES.COLORS.GRAY_12}
                  labelFontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_14}
                  labelFontColor={VARIABLES.COLORS.GRAY_19}
                  fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_14}
                  fontColor={VARIABLES.COLORS.BLACK}
                  paddingTop={VARIABLES.FIELD.PADDINGS.TOP}
                  paddingBottom={VARIABLES.FIELD.PADDINGS.BOTTOM}
                  paddingLeft='108'
                  borderBottomWidth='1'
                  borderBottomStyle='solid'
                  borderBottomColor={VARIABLES.COLORS.GRAY_2}
                  fieldIcon={
                    <Grid gutter='15'>
                      <Grid.Column>
                        <Image
                          ui='iconFlagField'
                          src={selectedPhoneCountry.flag} // ic-flag-th, ic-flag-my, ic-flag-vn, ic-flag-id
                        // src={ICONS['ic-flag-th.jpg']} // ic-flag-th.jpg, ic-flag-my.jpg, ic-flag-vn.jpg, ic-flag-id.jpg
                        />
                      </Grid.Column>
                      <Grid.Column>
                        <SelectControl
                          verticalLabel
                          idPhoneGlobal
                          isSearchable={false}
                          placeholder=' '
                          // name=''
                          value={selectedPhoneCountry}
                          // value={{ value: '66', label: '+66' }}
                          options={DATA.PHONE_PREFIX_LIST}
                          // options={[
                          //   { value: '66', label: '+66' },
                          //   { value: '999', label: '+999' },
                          // ]}
                          onChange={onChangePhoneCountry}
                        />
                      </Grid.Column>
                    </Grid>
                  }
                />
              )
            case 'blockLabelVertical':
              return (
                <FieldBase
                  {...this.props}
                  className={ClassNames(
                    className,
                    'is-label-vertical-block',
                    { 'is-required': required },
                    { 'is-change-password': changePassword },
                    { 'is-label-nowrap': labelNoWrap },
                  )}
                  placeholderColor={VARIABLES.COLORS.GRAY_12}
                  height={VARIABLES.FIELD.HEIGHT}
                  labelFontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_14}
                  labelFontColor={VARIABLES.COLORS.GRAY_19}
                  paddingRight={VARIABLES.FIELD.PADDINGS.HORIZONTAL}
                  paddingLeft={VARIABLES.FIELD.PADDINGS.HORIZONTAL}
                  fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_14}
                  fontColor={VARIABLES.COLORS.BLACK}
                  fontLineHeight={VARIABLES.FIELD.HEIGHT}
                  borderWidth='1'
                  borderStyle='solid'
                  borderColor={VARIABLES.COLORS.GRAY_8}
                  borderRadius='3'
                />
              )
            case 'blockLabelVerticalSmall':
              return (
                <FieldBase
                  {...this.props}
                  className={ClassNames(
                    className,
                    'is-label-vertical-block',
                    { 'is-required': required },
                    { 'is-change-password': changePassword },
                    { 'is-label-nowrap': labelNoWrap },
                  )}
                  placeholderColor={VARIABLES.COLORS.GRAY_12}
                  labelFontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_12}
                  labelFontColor={VARIABLES.COLORS.BLACK}
                  paddingTop='4'
                  paddingRight={VARIABLES.FIELD.PADDINGS.HORIZONTAL}
                  paddingBottom='3'
                  paddingLeft={VARIABLES.FIELD.PADDINGS.HORIZONTAL}
                  fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_12}
                  fontColor={VARIABLES.COLORS.BLACK}
                  borderWidth='1'
                  borderStyle='solid'
                  borderColor={VARIABLES.COLORS.GRAY_8}
                  borderRadius='3'
                />
              )
            case 'blockLabelVerticalNumberLarge':
              return (
                <FieldBase
                  {...this.props}
                  className={ClassNames(
                    className,
                    'is-label-vertical-block',
                    { 'is-required': required },
                    { 'is-change-password': changePassword },
                    { 'is-label-nowrap': labelNoWrap },
                  )}
                  placeholderColor={VARIABLES.COLORS.GRAY_12}
                  height='42'
                  labelFontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_14}
                  labelFontColor={VARIABLES.COLORS.GRAY_19}
                  paddingTop='5'
                  paddingRight='5'
                  paddingLeft='5'
                  fontStyle={TYPOGRAPHYS.FIRST.BOLD.FS_28}
                  fontColor={VARIABLES.COLORS.PRIMARY_2}
                  fontLineHeight='42'
                  borderWidth='1'
                  borderStyle='solid'
                  borderColor={VARIABLES.COLORS.GRAY_8}
                  borderRadius='7'
                />
              )
            case 'blockLabelVerticalTextarea':
              return (
                <FieldBase
                  {...this.props}
                  className={ClassNames(
                    className,
                    'is-label-vertical-block',
                    { 'is-required': required },
                    { 'is-change-password': changePassword },
                    { 'is-label-nowrap': labelNoWrap },
                  )}
                  type='textarea'
                  placeholderColor={VARIABLES.COLORS.GRAY_12}
                  labelFontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_14}
                  labelFontColor={VARIABLES.COLORS.GRAY_19}
                  height='130'
                  paddingTop={VARIABLES.FIELD.PADDINGS.TOP_BLOCK}
                  paddingRight={VARIABLES.FIELD.PADDINGS.HORIZONTAL}
                  paddingBottom={VARIABLES.FIELD.PADDINGS.BOTTOM_BLOCK}
                  paddingLeft={VARIABLES.FIELD.PADDINGS.HORIZONTAL}
                  fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_14}
                  fontColor={VARIABLES.COLORS.BLACK}
                  borderWidth='1'
                  borderStyle='solid'
                  borderColor={VARIABLES.COLORS.GRAY_8}
                  borderRadius='3'
                />
              )
            case 'blockLabelVerticalTextareaSmall':
              return (
                <FieldBase
                  {...this.props}
                  className={ClassNames(
                    className,
                    'is-label-vertical-block',
                    { 'is-required': required },
                    { 'is-change-password': changePassword },
                    { 'is-label-nowrap': labelNoWrap },
                  )}
                  type='textarea'
                  placeholderColor={VARIABLES.COLORS.GRAY_12}
                  labelFontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_12}
                  labelFontColor={VARIABLES.COLORS.BLACK}
                  height='100'
                  paddingTop={VARIABLES.FIELD.PADDINGS.TOP_BLOCK}
                  paddingRight={VARIABLES.FIELD.PADDINGS.HORIZONTAL}
                  paddingBottom={VARIABLES.FIELD.PADDINGS.BOTTOM_BLOCK}
                  paddingLeft={VARIABLES.FIELD.PADDINGS.HORIZONTAL}
                  fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_12}
                  fontColor={VARIABLES.COLORS.BLACK}
                  borderWidth='1'
                  borderStyle='solid'
                  borderColor={VARIABLES.COLORS.GRAY_8}
                  borderRadius='3'
                />
              )
            case 'blockLabelVerticalSearch':
              return (
                <FieldBase
                  {...this.props}
                  className={ClassNames(
                    className,
                    'is-label-vertical-block'
                  )}
                  placeholderColor={VARIABLES.COLORS.GRAY_12}
                  height={VARIABLES.FIELD.HEIGHT}
                  paddingRight={VARIABLES.FIELD.PADDINGS.HORIZONTAL}
                  paddingLeft='58'
                  fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_12}
                  fontColor={VARIABLES.COLORS.BLACK}
                  fontLineHeight={VARIABLES.FIELD.HEIGHT}
                  borderWidth='1'
                  borderStyle='solid'
                  borderColor={border ? VARIABLES.COLORS.GRAY_2 : VARIABLES.COLORS.WHITE}
                  borderRadius='3'
                  fieldIcon={
                    <Image ui='iconSearch' />
                  }
                  fieldIconSpacingLeft='25'
                />
              )
            case 'blockLabelVerticalPhone':
              return (
                <FieldBase
                  {...this.props}
                  className={ClassNames(
                    className,
                    'is-label-vertical-block',
                    'is-enable-field-icon-action',
                    { 'is-required': required }
                  )}
                  placeholderColor={VARIABLES.COLORS.GRAY_12}
                  labelFontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_14}
                  labelFontColor={VARIABLES.COLORS.GRAY_19}
                  paddingTop='7'
                  paddingRight={VARIABLES.FIELD.PADDINGS.HORIZONTAL}
                  paddingBottom='5'
                  paddingLeft='80' // 107
                  fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_14}
                  fontColor={VARIABLES.COLORS.BLACK}
                  borderWidth='1'
                  borderStyle='solid'
                  borderColor={VARIABLES.COLORS.GRAY_8}
                  borderRadius='3'
                  fieldIcon={
                    <Grid gutter='15'>
                      <Grid.Column>
                        <Image
                          ui='iconFlagField'
                          src={selectedPhoneCountry.flag} // ic-flag-th, ic-flag-my, ic-flag-vn, ic-flag-id
                        />
                      </Grid.Column>
                      <Grid.Column>
                        {/* <SelectControl
                          verticalLabel
                          idPhone
                          isSearchable={false}
                          placeholder=' '
                          // name=''
                          value={selectedPhoneCountry}
                          options={DATA.PHONE_PREFIX_LIST}
                          onChange={onChangePhoneCountry}
                        /> */}
                        <Text
                          fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_14}
                          color={VARIABLES.COLORS.BLACK}
                          top='6'
                        >
                          {/* +{idPhone} */}
                          {(selectedPhoneCountry || {}).label || ''}
                        </Text>
                      </Grid.Column>
                    </Grid>
                  }
                  fieldIconSpacingLeft='15'
                />
              )
            case 'radio':
              return (
                <FieldBase
                  {...this.props}
                  className={classes}
                  type='radio'
                  radioSize='16'
                  radioBgColor={VARIABLES.COLORS.WHITE}
                  radioCheckedBgColor={VARIABLES.COLORS.WHITE}
                  radioBorderWidth='2'
                  radioBorderStyle='solid'
                  radioBorderColor={VARIABLES.COLORS.GRAY_8}
                  radioBorderCheckedColor={VARIABLES.COLORS.GRAY_8}
                  radioBorderRadius='8'
                  radioLabelFontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_14}
                  radioLabelSpacingLeft='10'
                  radioLabelFontColor={VARIABLES.COLORS.GRAY_19}
                  radioLabelCheckedFontColor={VARIABLES.COLORS.BLACK}
                  radioCheckedIcon={
                    <Image
                      widthRatio='8'
                      widthRatioUnit='px'
                      heightRatio='8'
                      heightRatioUnit='px'
                      src={ICONS['ic-radio-checked.svg']}
                    />
                  }
                />
              )
            case 'checkbox':
              return (
                <FieldBase
                  {...this.props}
                  className={classes}
                  type='checkbox'
                  checkboxSize='16'
                  checkboxBgColor={VARIABLES.COLORS.WHITE}
                  checkboxCheckedBgColor={VARIABLES.COLORS.WHITE}
                  checkboxBorderWidth='2'
                  checkboxBorderStyle='solid'
                  checkboxBorderColor={VARIABLES.COLORS.GRAY_8}
                  checkboxBorderCheckedColor={VARIABLES.COLORS.GRAY_8}
                  checkboxBorderRadius='10'
                  checkboxLabelFontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_14}
                  checkboxLabelSpacingLeft='10'
                  checkboxLabelFontColor={VARIABLES.COLORS.GRAY_19}
                  checkboxLabelCheckedFontColor={VARIABLES.COLORS.BLACK}
                  checkboxCheckedIcon={
                    <Image
                      widthRatio='10'
                      widthRatioUnit='px'
                      heightRatio='10'
                      heightRatioUnit='px'
                      src={ICONS['ic-radio-checked.svg']}
                    />
                  }
                />
              )
            case 'checkboxTick':
              return (
                <FieldBase
                  {...this.props}
                  className={classes}
                  type='checkbox'
                  checkboxSize='16'
                  checkboxBgColor={VARIABLES.COLORS.WHITE}
                  checkboxCheckedBgColor={VARIABLES.COLORS.WHITE}
                  checkboxBorderWidth='1'
                  checkboxBorderStyle='solid'
                  checkboxBorderColor={VARIABLES.COLORS.PRIMARY_1}
                  checkboxBorderCheckedColor={VARIABLES.COLORS.PRIMARY_1}
                  checkboxBorderRadius='3'
                  checkboxLabelFontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_14}
                  checkboxLabelSpacingLeft='10'
                  checkboxLabelFontColor={VARIABLES.COLORS.GRAY_19}
                  checkboxLabelCheckedFontColor={VARIABLES.COLORS.BLACK}
                  checkboxCheckedIcon={
                    <Image
                      widthRatio='12'
                      widthRatioUnit='px'
                      heightRatio='9'
                      heightRatioUnit='px'
                      src={ICONS['ic-checkbox.svg']}
                    />
                  }
                />
              )
            case 'checkboxTickLabelBlack':
              return (
                <FieldBase
                  {...this.props}
                  className={classes}
                  type='checkbox'
                  checkboxSize='16'
                  checkboxBgColor={VARIABLES.COLORS.WHITE}
                  checkboxCheckedBgColor={VARIABLES.COLORS.WHITE}
                  checkboxBorderWidth='1'
                  checkboxBorderStyle='solid'
                  checkboxBorderColor={VARIABLES.COLORS.GRAY_8}
                  checkboxBorderCheckedColor={VARIABLES.COLORS.GRAY_8}
                  checkboxBorderRadius='3'
                  checkboxLabelFontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_14}
                  checkboxLabelSpacingLeft='10'
                  checkboxLabelFontColor={VARIABLES.COLORS.BLACK}
                  checkboxLabelCheckedFontColor={VARIABLES.COLORS.BLACK}
                  checkboxCheckedIcon={
                    <Image
                      widthRatio='12'
                      widthRatioUnit='px'
                      heightRatio='9'
                      heightRatioUnit='px'
                      src={ICONS['ic-checkbox.svg']}
                    />
                  }
                />
              )
            case 'checkboxTickCondition':
              return (
                <FieldBase
                  {...this.props}
                  className={classes}
                  type='checkbox'
                  checkboxSize='16'
                  checkboxBgColor={VARIABLES.COLORS.WHITE}
                  checkboxCheckedBgColor={VARIABLES.COLORS.WHITE}
                  checkboxBorderWidth='1'
                  checkboxBorderStyle='solid'
                  checkboxBorderColor={VARIABLES.COLORS.PRIMARY_1}
                  checkboxBorderCheckedColor={VARIABLES.COLORS.PRIMARY_1}
                  checkboxBorderRadius='3'
                  checkboxLabelFontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_16}
                  checkboxLabelSpacingLeft='10'
                  checkboxLabelFontColor={VARIABLES.COLORS.GRAY_19}
                  checkboxLabelCheckedFontColor={VARIABLES.COLORS.BLACK}
                  checkboxCheckedIcon={
                    <Image
                      widthRatio='12'
                      widthRatioUnit='px'
                      heightRatio='9'
                      heightRatioUnit='px'
                      src={ICONS['ic-checkbox.svg']}
                    />
                  }
                />
              )
            case 'checkboxTickFill':
              return (
                <FieldBase
                  {...this.props}
                  className={classes}
                  type='checkbox'
                  checkboxSize='16'
                  checkboxBgColor={VARIABLES.COLORS.WHITE}
                  checkboxCheckedBgColor={VARIABLES.COLORS.PRIMARY_1}
                  checkboxBorderWidth='1'
                  checkboxBorderStyle='solid'
                  checkboxBorderColor={VARIABLES.COLORS.PRIMARY_1}
                  checkboxBorderCheckedColor={VARIABLES.COLORS.PRIMARY_1}
                  checkboxBorderRadius='3'
                  checkboxLabelFontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_14}
                  checkboxLabelSpacingLeft='10'
                  checkboxLabelFontColor={VARIABLES.COLORS.GRAY_19}
                  checkboxLabelCheckedFontColor={VARIABLES.COLORS.BLACK}
                  checkboxCheckedIcon={
                    <Image
                      widthRatio='12'
                      widthRatioUnit='px'
                      heightRatio='9'
                      heightRatioUnit='px'
                      src={ICONS['ic-checkbox-white.svg']}
                    />
                  }
                />
              )
            case 'checkboxTickFillBlank':
              return (
                <FieldBase
                  {...this.props}
                  className={classes}
                  type='checkbox'
                  checkboxSize='16'
                  checkboxBgColor={VARIABLES.COLORS.TRANSPARENT}
                  checkboxCheckedBgColor={VARIABLES.COLORS.PRIMARY_1}
                  checkboxBorderWidth='1'
                  checkboxBorderStyle='solid'
                  checkboxBorderColor={VARIABLES.COLORS.TRANSPARENT}
                  checkboxBorderCheckedColor={VARIABLES.COLORS.PRIMARY_1}
                  checkboxBorderRadius='3'
                  checkboxLabelFontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_14}
                  checkboxLabelSpacingLeft='10'
                  checkboxLabelFontColor={VARIABLES.COLORS.GRAY_19}
                  checkboxLabelCheckedFontColor={VARIABLES.COLORS.BLACK}
                  checkboxCheckedIcon={
                    <Image
                      widthRatio='12'
                      widthRatioUnit='px'
                      heightRatio='9'
                      heightRatioUnit='px'
                      src={ICONS['ic-checkbox-white.svg']}
                    />
                  }
                />
              )
            case 'checkboxTickFillSmall':
              return (
                <FieldBase
                  {...this.props}
                  className={classes}
                  type='checkbox'
                  checkboxSize='18'
                  checkboxBgColor={VARIABLES.COLORS.WHITE}
                  checkboxCheckedBgColor={VARIABLES.COLORS.PRIMARY_2}
                  checkboxBorderWidth='1'
                  checkboxBorderStyle='solid'
                  checkboxBorderColor={VARIABLES.COLORS.PRIMARY_2}
                  checkboxBorderCheckedColor={VARIABLES.COLORS.PRIMARY_2}
                  checkboxBorderRadius='3'
                  checkboxLabelFontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_12}
                  checkboxLabelSpacingLeft='18'
                  checkboxLabelFontColor={VARIABLES.COLORS.GRAY_19}
                  checkboxLabelCheckedFontColor={VARIABLES.COLORS.BLACK}
                  checkboxCheckedIcon={
                    <Image
                      widthRatio='10'
                      widthRatioUnit='px'
                      heightRatio='7'
                      heightRatioUnit='px'
                      src={ICONS['ic-checkbox-white.svg']}
                    />
                  }
                />
              )
            case 'checkboxTickFillSmallest':
              return (
                <FieldBase
                  {...this.props}
                  className={classes}
                  type='checkbox'
                  checkboxSize='14'
                  checkboxBgColor={VARIABLES.COLORS.WHITE}
                  checkboxCheckedBgColor={VARIABLES.COLORS.PRIMARY_2}
                  checkboxBorderWidth='1'
                  checkboxBorderStyle='solid'
                  checkboxBorderColor={VARIABLES.COLORS.PRIMARY_2}
                  checkboxBorderCheckedColor={VARIABLES.COLORS.PRIMARY_2}
                  checkboxBorderRadius='2'
                  checkboxLabelFontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_12}
                  checkboxLabelSpacingLeft='14'
                  checkboxLabelFontColor={VARIABLES.COLORS.GRAY_19}
                  checkboxLabelCheckedFontColor={VARIABLES.COLORS.BLACK}
                  checkboxCheckedIcon={
                    <Image
                      widthRatio='8'
                      widthRatioUnit='px'
                      heightRatio='6'
                      heightRatioUnit='px'
                      src={ICONS['ic-checkbox-white.svg']}
                    />
                  }
                />
              )
            case 'checkboxTickCircle':
              return (
                <FieldBase
                  {...this.props}
                  className={classes}
                  type='checkbox'
                  checkboxSize='16'
                  checkboxBgColor={VARIABLES.COLORS.WHITE}
                  checkboxCheckedBgColor={VARIABLES.COLORS.WHITE}
                  checkboxBorderWidth='1'
                  checkboxBorderStyle='solid'
                  checkboxBorderColor={VARIABLES.COLORS.GRAY_8}
                  checkboxBorderCheckedColor={VARIABLES.COLORS.PRIMARY_2}
                  checkboxBorderRadius='10'
                  checkboxLabelFontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_14}
                  checkboxLabelSpacingLeft='10'
                  checkboxLabelFontColor={VARIABLES.COLORS.GRAY_19}
                  checkboxLabelCheckedFontColor={VARIABLES.COLORS.BLACK}
                  checkboxCheckedIcon={
                    <Image
                      widthRatio='10'
                      widthRatioUnit='px'
                      heightRatio='7'
                      heightRatioUnit='px'
                      src={ICONS['ic-checkbox-blue.svg']}
                    />
                  }
                />
              )
            case 'uploadAvatar':
              return (
                <FieldBase
                  type='fileAvatar'
                  fileImage={
                    <Section spacingBottom='10'>
                      <ShapeContainer
                        ui='userUpload'
                        srcUserUploadImage={srcUpload ?
                          srcUpload
                          :
                          ICONS['ic-placeholder-avatar.svg']
                        }
                      />
                    </Section>
                  }
                  accept={acceptFileType}
                  buttonBrowse={
                    <Button
                      lightBlue
                      ui='frontIcon'
                      name={getLanguage('field.imageUpload', '')}
                      iconWidth='15'
                      iconHeight='15'
                      iconSrc={ICONS['ic-image.svg']}
                      onClick={onClickBrowse}
                    />
                  }
                  buttonDeleteUpload={
                    <Button
                      red
                      ui='icon'
                      iconWidth='13'
                      iconHeight='17'
                      iconSrc={ICONS['ic-delete.svg']}
                      onClick={onClickDelete}
                    />
                  }
                  buttonDeleteUploadSpacingLeft='10'
                  onChange={onChange}
                />
              )
            case 'order':
              return (
                <FieldBase
                  {...this.props}
                  className={classes}
                  height='20'
                  placeholderColor={VARIABLES.COLORS.GRAY_12}
                  fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_12}
                  fontLineHeight='20'
                  fontColor={VARIABLES.COLORS.PRIMARY_1}
                  width='65' // 100
                  paddingHorizontal='15'
                  borderWidth='1'
                  borderStyle='solid'
                  borderColor={VARIABLES.COLORS.PRIMARY_1}
                  borderRadius='10'
                  textAlign='right'
                />
              )
            case 'unit':
              return (
                <div className='field-unit'>
                  <div className='field-unit-button-minus'>
                    <Image
                      ui='iconUnitMinus'
                      onClick={onClickUnitMinus}
                    />
                  </div>
                  <FieldBase
                    {...this.props}
                    className={classes}
                    placeholderColor={VARIABLES.COLORS.GRAY_12}
                    fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_12}
                    fontColor={VARIABLES.COLORS.PRIMARY_1}
                    width='100'
                    paddingTop='1'
                    paddingBottom='1'
                    paddingHorizontal='37'
                    borderWidth='1'
                    borderStyle='solid'
                    borderColor={VARIABLES.COLORS.PRIMARY_1}
                    borderRadius='12'
                    textAlign='center'
                  />
                  <div className='field-unit-button-plus'>
                    <Image
                      ui='iconUnitPlus'
                      onClick={onClickUnitPlus}
                    />
                  </div>
                </div>
              )
            case 'addressThai':
              return (
                <React.Fragment>
                  {!isRemoveAddress &&
                    <Section spacingBottom='30'>
                      <Grid>
                        <Grid.Column
                          gridTablet='12'
                          gridLaptop='7'
                        >
                          <FieldBase
                            fluid
                            className={ClassNames(
                              className,
                              'is-label-vertical-block',
                              { 'is-required': requiredAddress }
                            )}
                            type='textarea'
                            placeholderColor={VARIABLES.COLORS.GRAY_12}
                            labelFontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_14}
                            labelFontColor={VARIABLES.COLORS.GRAY_19}
                            height='130'
                            paddingTop={VARIABLES.FIELD.PADDINGS.TOP_BLOCK}
                            paddingRight={VARIABLES.FIELD.PADDINGS.HORIZONTAL}
                            paddingBottom={VARIABLES.FIELD.PADDINGS.BOTTOM_BLOCK}
                            paddingLeft={VARIABLES.FIELD.PADDINGS.HORIZONTAL}
                            fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_14}
                            fontColor={VARIABLES.COLORS.BLACK}
                            borderWidth='1'
                            borderStyle='solid'
                            borderColor={VARIABLES.COLORS.GRAY_8}
                            borderRadius='3'
                            label={labelAddress}
                            placeholder={placeholderAddress}
                            name={nameAddress}
                            value={valueAddress}
                            onChange={onChangeAddress}
                            disabled={disabledAddress}
                            disabledColor={disabledColor}
                            message={
                              <Section
                                ui='hintBottomField'
                                className={errorInstallationAddress && 'is-message-error'}
                                hint={getLanguage('field.installationAddressHint', '')}
                              />
                            }
                            error={errorInstallationAddress}
                          />
                        </Grid.Column>
                      </Grid>
                    </Section>}
                  <Section spacingBottom='30'>
                    <Grid
                      gutter='35'
                      gutterVertical='30'
                    >
                      <Grid.ResponsiveColumn ui='fieldSelect'>
                        <SelectControl
                          // isCreatable
                          verticalLabel
                          required={requiredProvince}
                          isSearchable={isSearchableProvince}
                          label={getLanguage('field.provinceTH', '')}
                          placeholder={getLanguage('field.provinceTHPlaceholder', '')}
                          name={nameProvince}
                          value={valueProvince}
                          onChange={onChangeProvince}
                          options={optionProvince}
                          disabled={disabledProvince}
                          disabledColor={disabledColor}
                        />
                      </Grid.ResponsiveColumn>
                      <Grid.ResponsiveColumn ui='fieldSelect'>
                        <SelectControl
                          // isCreatable
                          verticalLabel
                          required={requiredCity}
                          isSearchable={isSearchableCity}
                          label={getLanguage('field.cityTH', '')}
                          placeholder={getLanguage('field.cityTHPlaceholder', '')}
                          name={nameCity}
                          value={valueCity}
                          onChange={onChangeCity}
                          options={optionCity}
                          disabled={disabledCity}
                          disabledColor={disabledColor}
                        />
                      </Grid.ResponsiveColumn>
                    </Grid>
                  </Section >
                  <Section spacingBottom={(isShowIndrustialEstate || isShowWarrantyDistance) ? "30" : undefined}>
                    <Grid
                      gutter='35'
                      gutterVertical='30'
                    >
                      <Grid.ResponsiveColumn ui='fieldSelect'>
                        {/* <SelectControl
                          verticalLabel
                          required={requiredSubdistrict}
                          isSearchable={isSearchableSubdistrict}
                          label='Subdistrict (ตำบล)'
                          placeholder='Insert Subdistrict (ตำบล)'
                          name={nameSubdistrict}
                          value={valueSubdistrict}
                          onChange={onChangeSubdistrict}
                          options={optionSubdistrict}
                        /> */}

                        <SelectControl
                          // isCreatable
                          verticalLabel
                          required={requiredSubdistrict}
                          isSearchable={isSearchableSubdistrict}
                          label={getLanguage('field.subdistrictTH', '')}
                          placeholder={getLanguage('field.subdistrictTHPlaceholder', '')}
                          name={nameSubdistrict}
                          value={valueSubdistrict}
                          onChange={onChangeSubdistrict}
                          options={optionSubdistrict}
                          disabled={disabledSubdistrict}
                          disabledColor={disabledColor}
                        />
                        {/* <FieldBase
                          fluid
                          className={ClassNames(
                            className,
                            'is-label-vertical-block',
                            { 'is-required': requiredSubdistrict }
                          )}
                          placeholderColor={VARIABLES.COLORS.GRAY_12}
                          height={VARIABLES.FIELD.HEIGHT}
                          labelFontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_14}
                          labelFontColor={VARIABLES.COLORS.GRAY_19}
                          paddingRight={VARIABLES.FIELD.PADDINGS.HORIZONTAL}
                          paddingLeft={VARIABLES.FIELD.PADDINGS.HORIZONTAL}
                          fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_14}
                          fontColor={VARIABLES.COLORS.BLACK}
                          fontLineHeight={VARIABLES.FIELD.HEIGHT}
                          borderWidth='1'
                          borderStyle='solid'
                          borderColor={VARIABLES.COLORS.GRAY_8}
                          borderRadius='3'
                          label={getLanguage('field.subdistrictTH', '')}
                          placeholder={getLanguage('field.subdistrictTHPlaceholder', '')}
                          name={nameSubdistrict}
                          value={valueSubdistrict}
                          onChange={onChangeSubdistrict}
                          disabled={disabledSubdistrict}
                        /> */}
                      </Grid.ResponsiveColumn>
                      <Grid.ResponsiveColumn ui='fieldSelect'>
                        <SelectControl
                          // isCreatable
                          verticalLabel
                          required={requiredPostalCode}
                          isSearchable={isSearchablePostalCode}
                          label={getLanguage('field.postalCode', '')}
                          placeholder={getLanguage('field.postalCodePlaceholder', '')}
                          name={namePostalCode}
                          value={valuePostalCode}
                          onChange={onChangePostalCode}
                          options={optionPostalCode}
                          disabled={disabledPostalCode}
                          disabledColor={disabledColor}
                        />
                        {/* <FieldBase
                          fluid
                          className={ClassNames(
                            className,
                            'is-label-vertical-block',
                            { 'is-required': requiredPostalCode }
                          )}
                          placeholderColor={VARIABLES.COLORS.GRAY_12}
                          height={VARIABLES.FIELD.HEIGHT}
                          labelFontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_14}
                          labelFontColor={VARIABLES.COLORS.GRAY_19}
                          paddingRight={VARIABLES.FIELD.PADDINGS.HORIZONTAL}
                          paddingLeft={VARIABLES.FIELD.PADDINGS.HORIZONTAL}
                          fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_14}
                          fontColor={VARIABLES.COLORS.BLACK}
                          fontLineHeight={VARIABLES.FIELD.HEIGHT}
                          borderWidth='1'
                          borderStyle='solid'
                          borderColor={VARIABLES.COLORS.GRAY_8}
                          borderRadius='3'
                          label={getLanguage('field.postalCode', '')}
                          placeholder={getLanguage('field.postalCodePlaceholder', '')}
                          name={namePostalCode}
                          value={valuePostalCode}
                          onChange={onChangePostalCode}
                          disabled={disabledPostalCode}
                        /> */}
                      </Grid.ResponsiveColumn>
                    </Grid>
                  </Section>
                  {isShowIndrustialEstate &&
                    <Section spacingBottom={isShowWarrantyDistance ? "30" : undefined} >
                      <Grid
                        gutter='35'
                        gutterVertical='30'
                      >
                        <Grid.ResponsiveColumn ui="fieldSelect">
                          <SelectControl
                            // isCreatable
                            verticalLabel
                            required={requiredIndrustialEstateName}
                            isSearchable={isSearchableIndrustialEstateName}
                            label={getLanguage('field.industrialEstateName', '')}
                            placeholder={getLanguage('field.industrialEstateNamePlaceholder', '')}
                            name={nameIndrustialEstateName}
                            value={valueIndrustialEstateName}
                            onChange={onChangeIndrustialEstateName}
                            options={optionIndrustialEstateName}
                            disabled={disabledIndrustialEstateName}
                            disabledColor={disabledColor}
                          />
                        </Grid.ResponsiveColumn>
                      </Grid>
                    </Section>
                  }
                  {isShowWarrantyDistance &&
                    <Section >
                      <Grid
                        gutter='35'
                        gutterVertical='30'
                      >
                        <Grid.ResponsiveColumn ui="fieldSelect">
                          <SelectControl
                            // isCreatable
                            verticalLabel
                            required={requiredWarrantyDistance}
                            isSearchable={isSearchableWarrantyDistance}
                            label={automaticEnvironmentData ?
                              (
                                automaticEnvironmentData.isGroupIndustrial ?
                                  getLanguage('field.warrantyEnvDistanceIndustrial', '')
                                  :
                                  getLanguage('field.warrantyEnvDistanceMarine', '')
                              ) : ''
                            }
                            placeholder={automaticEnvironmentData ?
                              (
                                automaticEnvironmentData.isGroupIndustrial ?
                                  getLanguage('field.warrantyEnvDistanceIndustrialPlaceholder', '')
                                  :
                                  getLanguage('field.warrantyEnvDistanceMarinePlaceholder', '')
                              ) : ''
                            }
                            name={nameWarrantyDistance}
                            value={valueWarrantyDistance}
                            onChange={onChangeWarrantyDistance}
                            options={optionWarrantyDistance}
                            disabled={disabledWarrantyDistance}
                            disabledColor={disabledColor}
                          />
                        </Grid.ResponsiveColumn>
                      </Grid>
                    </Section>
                  }
                </React.Fragment>
              )
            case 'addressLao':
              return (
                <React.Fragment>
                  <Section spacingBottom='30'>
                    <Grid>
                      <Grid.Column
                        gridTablet='12'
                        gridLaptop='7'
                      >
                        <FieldBase
                          fluid
                          className={ClassNames(
                            className,
                            'is-label-vertical-block',
                            { 'is-required': requiredAddress }
                          )}
                          type='textarea'
                          placeholderColor={VARIABLES.COLORS.GRAY_12}
                          labelFontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_14}
                          labelFontColor={VARIABLES.COLORS.GRAY_19}
                          height='130'
                          paddingTop={VARIABLES.FIELD.PADDINGS.TOP_BLOCK}
                          paddingRight={VARIABLES.FIELD.PADDINGS.HORIZONTAL}
                          paddingBottom={VARIABLES.FIELD.PADDINGS.BOTTOM_BLOCK}
                          paddingLeft={VARIABLES.FIELD.PADDINGS.HORIZONTAL}
                          fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_14}
                          fontColor={VARIABLES.COLORS.BLACK}
                          borderWidth='1'
                          borderStyle='solid'
                          borderColor={VARIABLES.COLORS.GRAY_8}
                          borderRadius='3'
                          label={labelAddress}
                          placeholder={placeholderAddress}
                          name={nameAddress}
                          value={valueAddress}
                          onChange={onChangeAddress}
                          disabled={disabledAddress}
                          disabledColor={disabledColor}
                          message={
                            <Section
                              ui='hintBottomField'
                              className={errorInstallationAddress && 'is-message-error'}
                              hint={getLanguage('field.installationAddressHint', '')}
                            />
                          }
                          error={errorInstallationAddress}
                        />
                      </Grid.Column>
                    </Grid>
                  </Section>
                  <Section spacingBottom='30'>
                    <Grid
                      gutter='35'
                      gutterVertical='30'
                    >
                      <Grid.ResponsiveColumn ui='fieldSelect'>
                        <FieldBase
                          fluid
                          className={ClassNames(
                            className,
                            'is-label-vertical-block',
                            { 'is-required': requiredProvince }
                          )}
                          placeholderColor={VARIABLES.COLORS.GRAY_12}
                          height={VARIABLES.FIELD.HEIGHT}
                          labelFontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_14}
                          labelFontColor={VARIABLES.COLORS.GRAY_19}
                          paddingRight={VARIABLES.FIELD.PADDINGS.HORIZONTAL}
                          paddingLeft={VARIABLES.FIELD.PADDINGS.HORIZONTAL}
                          fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_14}
                          fontColor={VARIABLES.COLORS.BLACK}
                          fontLineHeight={VARIABLES.FIELD.HEIGHT}
                          borderWidth='1'
                          borderStyle='solid'
                          borderColor={VARIABLES.COLORS.GRAY_8}
                          borderRadius='3'
                          label={getLanguage('field.provinceLA', '')}
                          placeholder={getLanguage('field.provinceLAPlaceholder', '')}
                          name={nameProvince}
                          value={valueProvince}
                          onChange={onChangeProvince}
                          disabled={disabledProvince}
                          disabledColor={disabledColor}
                        />
                      </Grid.ResponsiveColumn>
                      <Grid.ResponsiveColumn ui='fieldSelect'>
                        <FieldBase
                          fluid
                          className={ClassNames(
                            className,
                            'is-label-vertical-block',
                            { 'is-required': requiredCity }
                          )}
                          placeholderColor={VARIABLES.COLORS.GRAY_12}
                          height={VARIABLES.FIELD.HEIGHT}
                          labelFontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_14}
                          labelFontColor={VARIABLES.COLORS.GRAY_19}
                          paddingRight={VARIABLES.FIELD.PADDINGS.HORIZONTAL}
                          paddingLeft={VARIABLES.FIELD.PADDINGS.HORIZONTAL}
                          fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_14}
                          fontColor={VARIABLES.COLORS.BLACK}
                          fontLineHeight={VARIABLES.FIELD.HEIGHT}
                          borderWidth='1'
                          borderStyle='solid'
                          borderColor={VARIABLES.COLORS.GRAY_8}
                          borderRadius='3'
                          label={getLanguage('field.cityLA', '')}
                          placeholder={getLanguage('field.cityLAPlaceholder', '')}
                          name={nameCity}
                          value={valueCity}
                          onChange={onChangeCity}
                          disabled={disabledCity}
                          disabledColor={disabledColor}
                        />
                      </Grid.ResponsiveColumn>
                    </Grid>
                  </Section>
                  <Section>
                    <Grid
                      gutter='35'
                      gutterVertical='30'
                    >
                      <Grid.ResponsiveColumn ui='fieldSelect'>
                        {/* <SelectControl
                          verticalLabel
                          required={requiredSubdistrict}
                          isSearchable={isSearchableSubdistrict}
                          label='Subdistrict (ตำบล)'
                          placeholder='Insert Subdistrict (ตำบล)'
                          name={nameSubdistrict}
                          value={valueSubdistrict}
                          onChange={onChangeSubdistrict}
                          options={optionSubdistrict}
                        /> */}
                        <FieldBase
                          fluid
                          className={ClassNames(
                            className,
                            'is-label-vertical-block',
                            { 'is-required': requiredSubdistrict }
                          )}
                          placeholderColor={VARIABLES.COLORS.GRAY_12}
                          height={VARIABLES.FIELD.HEIGHT}
                          labelFontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_14}
                          labelFontColor={VARIABLES.COLORS.GRAY_19}
                          paddingRight={VARIABLES.FIELD.PADDINGS.HORIZONTAL}
                          paddingLeft={VARIABLES.FIELD.PADDINGS.HORIZONTAL}
                          fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_14}
                          fontColor={VARIABLES.COLORS.BLACK}
                          fontLineHeight={VARIABLES.FIELD.HEIGHT}
                          borderWidth='1'
                          borderStyle='solid'
                          borderColor={VARIABLES.COLORS.GRAY_8}
                          borderRadius='3'
                          label={getLanguage('field.subdistrictLA', '')}
                          placeholder={getLanguage('field.subdistrictLAPlaceholder', '')}
                          name={nameSubdistrict}
                          value={valueSubdistrict}
                          onChange={onChangeSubdistrict}
                          disabled={disabledSubdistrict}
                          disabledColor={disabledColor}
                        />
                      </Grid.ResponsiveColumn>
                      <Grid.ResponsiveColumn ui='fieldSelect'>
                        <FieldBase
                          fluid
                          className={ClassNames(
                            className,
                            'is-label-vertical-block',
                            { 'is-required': requiredPostalCode }
                          )}
                          placeholderColor={VARIABLES.COLORS.GRAY_12}
                          height={VARIABLES.FIELD.HEIGHT}
                          labelFontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_14}
                          labelFontColor={VARIABLES.COLORS.GRAY_19}
                          paddingRight={VARIABLES.FIELD.PADDINGS.HORIZONTAL}
                          paddingLeft={VARIABLES.FIELD.PADDINGS.HORIZONTAL}
                          fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_14}
                          fontColor={VARIABLES.COLORS.BLACK}
                          fontLineHeight={VARIABLES.FIELD.HEIGHT}
                          borderWidth='1'
                          borderStyle='solid'
                          borderColor={VARIABLES.COLORS.GRAY_8}
                          borderRadius='3'
                          label={getLanguage('field.postalCode', '')}
                          placeholder={getLanguage('field.postalCodePlaceholder', '')}
                          name={namePostalCode}
                          value={valuePostalCode}
                          onChange={onChangePostalCode}
                          disabled={disabledPostalCode}
                          disabledColor={disabledColor}
                        />
                      </Grid.ResponsiveColumn>
                    </Grid>
                  </Section>
                </React.Fragment>
              )
            case 'addressMalaysia':
              return (
                <React.Fragment>
                  <Section spacingBottom='30'>
                    <Grid>
                      <Grid.Column
                        gridTablet='12'
                        gridLaptop='7'
                      >
                        <FieldBase
                          fluid
                          className={ClassNames(
                            className,
                            'is-label-vertical-block',
                            { 'is-required': requiredAddress }
                          )}
                          type='textarea'
                          placeholderColor={VARIABLES.COLORS.GRAY_12}
                          labelFontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_14}
                          labelFontColor={VARIABLES.COLORS.GRAY_19}
                          height='130'
                          paddingTop={VARIABLES.FIELD.PADDINGS.TOP_BLOCK}
                          paddingRight={VARIABLES.FIELD.PADDINGS.HORIZONTAL}
                          paddingBottom={VARIABLES.FIELD.PADDINGS.BOTTOM_BLOCK}
                          paddingLeft={VARIABLES.FIELD.PADDINGS.HORIZONTAL}
                          fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_14}
                          fontColor={VARIABLES.COLORS.BLACK}
                          borderWidth='1'
                          borderStyle='solid'
                          borderColor={VARIABLES.COLORS.GRAY_8}
                          borderRadius='3'
                          label={labelAddress}
                          placeholder={placeholderAddress}
                          name={nameAddress}
                          value={valueAddress}
                          onChange={onChangeAddress}
                          disabled={disabledAddress}
                          disabledColor={disabledColor}
                          message={
                            <Section
                              ui='hintBottomField'
                              className={errorInstallationAddress && 'is-message-error'}
                              hint={getLanguage('field.installationAddressHint', '')}
                            />
                          }
                          error={errorInstallationAddress}
                        />
                      </Grid.Column>
                    </Grid>
                  </Section>
                  <Section spacingBottom='30'>
                    <Grid
                      gutter='35'
                      gutterVertical='30'
                    >
                      <Grid.ResponsiveColumn ui='fieldSelect'>
                        <SelectControl
                          isCreatable
                          verticalLabel
                          required={requiredProvince}
                          isSearchable={isSearchableProvince}
                          label={getLanguage('field.province', '')}
                          placeholder={getLanguage('field.provincePlaceholder', '')}
                          name={nameProvince}
                          value={valueProvince}
                          onChange={onChangeProvince}
                          options={optionProvince}
                          disabled={disabledProvince}
                          disabledColor={disabledColor}
                        />
                      </Grid.ResponsiveColumn>
                      <Grid.ResponsiveColumn ui='fieldSelect'>
                        <SelectControl
                          isCreatable
                          verticalLabel
                          required={requiredCity}
                          isSearchable={isSearchableCity}
                          label={getLanguage('field.city', '')}
                          placeholder={getLanguage('field.cityPlaceholder', '')}
                          name={nameCity}
                          value={valueCity}
                          onChange={onChangeCity}
                          options={optionCity}
                          disabled={disabledCity}
                          disabledColor={disabledColor}
                        />
                      </Grid.ResponsiveColumn>
                      <Grid.ResponsiveColumn ui='fieldSelect'>
                        <FieldBase
                          fluid
                          className={ClassNames(
                            className,
                            'is-label-vertical-block',
                            { 'is-required': requiredPostalCode }
                          )}
                          placeholderColor={VARIABLES.COLORS.GRAY_12}
                          height={VARIABLES.FIELD.HEIGHT}
                          labelFontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_14}
                          labelFontColor={VARIABLES.COLORS.GRAY_19}
                          paddingRight={VARIABLES.FIELD.PADDINGS.HORIZONTAL}
                          paddingLeft={VARIABLES.FIELD.PADDINGS.HORIZONTAL}
                          fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_14}
                          fontColor={VARIABLES.COLORS.BLACK}
                          fontLineHeight={VARIABLES.FIELD.HEIGHT}
                          borderWidth='1'
                          borderStyle='solid'
                          borderColor={VARIABLES.COLORS.GRAY_8}
                          borderRadius='3'
                          label={getLanguage('field.postalCode', '')}
                          placeholder={getLanguage('field.postalCodePlaceholder', '')}
                          name={namePostalCode}
                          value={valuePostalCode}
                          onChange={onChangePostalCode}
                          disabled={disabledPostalCode}
                          disabledColor={disabledColor}
                        />
                      </Grid.ResponsiveColumn>
                    </Grid>
                  </Section>
                  {/* <Section spacingBottom='30'>
                    <Grid
                      gutter='35'
                      gutterVertical='30'
                    >
                      <Grid.ResponsiveColumn ui='fieldSelect'>
                        <SelectControl
                          verticalLabel
                          required={requiredCity}
                          disabled={disabledSelectCity}
                          isSearchable={isSearchableCity}
                          label='City'
                          placeholder='Select City'
                          name={nameCity}
                          value={valueCity}
                          onChange={onChangeCity}
                          options={optionCity}
                        />
                      </Grid.ResponsiveColumn>
                      <Grid.ResponsiveColumn>
                        <Section paddingTop='28'>
                          <Text
                            regular12
                            color={VARIABLES.COLORS.GRAY_19}
                          >
                            or
                          </Text>
                        </Section>
                      </Grid.ResponsiveColumn>
                      <Grid.ResponsiveColumn ui='fieldSelect'>
                        <FieldBase
                          fluid
                          disabled={disabledCity}
                          className={ClassNames(
                            className,
                            'is-label-vertical-block',
                            {'is-required': requiredCity}
                          )}
                          placeholderColor={VARIABLES.COLORS.GRAY_12}
                          height={VARIABLES.FIELD.HEIGHT}
                          labelFontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_14}
                          labelFontColor={VARIABLES.COLORS.GRAY_19}
                          paddingRight={VARIABLES.FIELD.PADDINGS.HORIZONTAL}
                          paddingLeft={VARIABLES.FIELD.PADDINGS.HORIZONTAL}
                          fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_14}
                          fontColor={VARIABLES.COLORS.BLACK}
                          fontLineHeight={VARIABLES.FIELD.HEIGHT}
                          borderWidth='1'
                          borderStyle='solid'
                          borderColor={VARIABLES.COLORS.GRAY_8}
                          borderRadius='3'
                          label='City'
                          placeholder='Insert City'
                          name={nameCity}
                          value={valueCity}
                          onChange={onChangeCity}
                        />
                      </Grid.ResponsiveColumn>
                    </Grid>
                  </Section>
                  <Section spacingBottom='30'>
                    <Grid
                      gutter='35'
                      gutterVertical='30'
                    >
                      <Grid.ResponsiveColumn ui='fieldSelect'>
                        <SelectControl
                          verticalLabel
                          required={requiredPostalCode}
                          disabled={disabledSelectPostalCode}
                          isSearchable={isSearchablePostalCode}
                          label='Postal Code'
                          placeholder='Select Postal Code'
                          name={namePostalCode}
                          value={valuePostalCode}
                          onChange={onChangePostalCode}
                          options={optionPostalCode}
                        />
                      </Grid.ResponsiveColumn>
                      <Grid.ResponsiveColumn>
                        <Section paddingTop='28'>
                          <Text
                            regular12
                            color={VARIABLES.COLORS.GRAY_19}
                          >
                            or
                          </Text>
                        </Section>
                      </Grid.ResponsiveColumn>
                      <Grid.ResponsiveColumn ui='fieldSelect'>
                        <FieldBase
                          fluid
                          disabled={disabledPostalCode}
                          className={ClassNames(
                            className,
                            'is-label-vertical-block',
                            {'is-required': requiredPostalCode}
                          )}
                          placeholderColor={VARIABLES.COLORS.GRAY_12}
                          height={VARIABLES.FIELD.HEIGHT}
                          labelFontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_14}
                          labelFontColor={VARIABLES.COLORS.GRAY_19}
                          paddingRight={VARIABLES.FIELD.PADDINGS.HORIZONTAL}
                          paddingLeft={VARIABLES.FIELD.PADDINGS.HORIZONTAL}
                          fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_14}
                          fontColor={VARIABLES.COLORS.BLACK}
                          fontLineHeight={VARIABLES.FIELD.HEIGHT}
                          borderWidth='1'
                          borderStyle='solid'
                          borderColor={VARIABLES.COLORS.GRAY_8}
                          borderRadius='3'
                          label='Postal Code'
                          placeholder='Insert Postal Code'
                          name={namePostalCode}
                          value={valuePostalCode}
                          onChange={onChangePostalCode}
                        />
                      </Grid.ResponsiveColumn>
                    </Grid>
                  </Section> */}
                </React.Fragment>
              )
            case 'addressVietnam':
              return (
                <React.Fragment>
                  <Section spacingBottom='30'>
                    <Grid>
                      <Grid.Column
                        gridTablet='12'
                        gridLaptop='7'
                      >
                        <FieldBase
                          fluid
                          className={ClassNames(
                            className,
                            'is-label-vertical-block',
                            { 'is-required': requiredAddress }
                          )}
                          type='textarea'
                          placeholderColor={VARIABLES.COLORS.GRAY_12}
                          labelFontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_14}
                          labelFontColor={VARIABLES.COLORS.GRAY_19}
                          height='130'
                          paddingTop={VARIABLES.FIELD.PADDINGS.TOP_BLOCK}
                          paddingRight={VARIABLES.FIELD.PADDINGS.HORIZONTAL}
                          paddingBottom={VARIABLES.FIELD.PADDINGS.BOTTOM_BLOCK}
                          paddingLeft={VARIABLES.FIELD.PADDINGS.HORIZONTAL}
                          fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_14}
                          fontColor={VARIABLES.COLORS.BLACK}
                          borderWidth='1'
                          borderStyle='solid'
                          borderColor={VARIABLES.COLORS.GRAY_8}
                          borderRadius='3'
                          label={labelAddress}
                          placeholder={placeholderAddress}
                          name={nameAddress}
                          value={valueAddress}
                          onChange={onChangeAddress}
                          disabled={disabledAddress}
                          disabledColor={disabledColor}
                          message={
                            <Section
                              ui='hintBottomField'
                              className={errorInstallationAddress && 'is-message-error'}
                              hint={getLanguage('field.installationAddressHint', '')}
                            />
                          }
                          error={errorInstallationAddress}
                        />
                      </Grid.Column>
                    </Grid>
                  </Section>
                  <Section spacingBottom='30'>
                    <Grid
                      gutter='35'
                      gutterVertical='30'
                    >
                      <Grid.ResponsiveColumn ui='fieldSelect'>
                        <SelectControl
                          verticalLabel
                          required={requiredProvince}
                          isSearchable={isSearchableProvince}
                          label={getLanguage('field.province', '')}
                          placeholder={getLanguage('field.provincePlaceholder', '')}
                          name={nameProvince}
                          value={valueProvince}
                          onChange={onChangeProvince}
                          options={optionProvince}
                          disabled={disabledProvince}
                          disabledColor={disabledColor}
                        />
                      </Grid.ResponsiveColumn>
                      <Grid.ResponsiveColumn ui='fieldSelect'>
                        <SelectControl
                          verticalLabel
                          required={requiredCity}
                          isSearchable={isSearchableCity}
                          label={getLanguage('field.city', '')}
                          placeholder={getLanguage('field.cityPlaceholder', '')}
                          name={nameCity}
                          value={valueCity}
                          onChange={onChangeCity}
                          options={optionCity}
                          disabled={disabledCity}
                          disabledColor={disabledColor}
                        />
                      </Grid.ResponsiveColumn>
                    </Grid>
                  </Section>
                  <Section spacingBottom='30'>
                    <Grid
                      gutter='35'
                      gutterVertical='30'
                    >
                      <Grid.ResponsiveColumn ui='fieldSelect'>
                        {/* <SelectControl
                          verticalLabel
                          required={requiredDistrict}
                          isSearchable={isSearchableDistrict}
                          label='District'
                          placeholder='Insert District'
                          name={nameDistrict}
                          value={valueDistrict}
                          onChange={onChangeDistrict}
                          options={optionDistrict}
                        /> */}
                        <FieldBase
                          fluid
                          className={ClassNames(
                            className,
                            'is-label-vertical-block',
                            { 'is-required': requiredDistrict }
                          )}
                          placeholderColor={VARIABLES.COLORS.GRAY_12}
                          height={VARIABLES.FIELD.HEIGHT}
                          labelFontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_14}
                          labelFontColor={VARIABLES.COLORS.GRAY_19}
                          paddingRight={VARIABLES.FIELD.PADDINGS.HORIZONTAL}
                          paddingLeft={VARIABLES.FIELD.PADDINGS.HORIZONTAL}
                          fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_14}
                          fontColor={VARIABLES.COLORS.BLACK}
                          fontLineHeight={VARIABLES.FIELD.HEIGHT}
                          borderWidth='1'
                          borderStyle='solid'
                          borderColor={VARIABLES.COLORS.GRAY_8}
                          borderRadius='3'
                          label={getLanguage('field.district', '')}
                          placeholder={getLanguage('field.districtPlaceholder', '')}
                          name={nameDistrict}
                          value={valueDistrict}
                          onChange={onChangeDistrict}
                          disabled={disabledDistrict}
                          disabledColor={disabledColor}
                        />
                      </Grid.ResponsiveColumn>
                      <Grid.ResponsiveColumn ui='fieldSelect'>
                        <FieldBase
                          fluid
                          className={ClassNames(
                            className,
                            'is-label-vertical-block',
                            { 'is-required': requiredAlley }
                          )}
                          placeholderColor={VARIABLES.COLORS.GRAY_12}
                          height={VARIABLES.FIELD.HEIGHT}
                          labelFontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_14}
                          labelFontColor={VARIABLES.COLORS.GRAY_19}
                          paddingRight={VARIABLES.FIELD.PADDINGS.HORIZONTAL}
                          paddingLeft={VARIABLES.FIELD.PADDINGS.HORIZONTAL}
                          fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_14}
                          fontColor={VARIABLES.COLORS.BLACK}
                          fontLineHeight={VARIABLES.FIELD.HEIGHT}
                          borderWidth='1'
                          borderStyle='solid'
                          borderColor={VARIABLES.COLORS.GRAY_8}
                          borderRadius='3'
                          label={getLanguage('field.alley', '')}
                          placeholder={getLanguage('field.alleyPlaceholder', '')}
                          name={nameAlley}
                          value={valueAlley}
                          onChange={onChangeAlley}
                          disabled={disabledAlley}
                        />
                      </Grid.ResponsiveColumn>
                      <Grid.ResponsiveColumn ui='fieldSelect'>
                        <FieldBase
                          fluid
                          className={ClassNames(
                            className,
                            'is-label-vertical-block',
                            { 'is-required': requiredGroup }
                          )}
                          placeholderColor={VARIABLES.COLORS.GRAY_12}
                          height={VARIABLES.FIELD.HEIGHT}
                          labelFontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_14}
                          labelFontColor={VARIABLES.COLORS.GRAY_19}
                          paddingRight={VARIABLES.FIELD.PADDINGS.HORIZONTAL}
                          paddingLeft={VARIABLES.FIELD.PADDINGS.HORIZONTAL}
                          fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_14}
                          fontColor={VARIABLES.COLORS.BLACK}
                          fontLineHeight={VARIABLES.FIELD.HEIGHT}
                          borderWidth='1'
                          borderStyle='solid'
                          borderColor={VARIABLES.COLORS.GRAY_8}
                          borderRadius='3'
                          label={getLanguage('field.group', '')}
                          placeholder={getLanguage('field.groupPlaceholder', '')}
                          name={nameGroup}
                          value={valueGroup}
                          onChange={onChangeGroup}
                          disabled={disabledGroup}
                        />
                      </Grid.ResponsiveColumn>
                    </Grid>
                  </Section>
                  <Section>
                    <Grid
                      gutter='35'
                      gutterVertical='30'
                    >
                      <Grid.ResponsiveColumn ui='fieldSelect'>
                        <FieldBase
                          fluid
                          className={ClassNames(
                            className,
                            'is-label-vertical-block',
                            { 'is-required': requiredStreet }
                          )}
                          placeholderColor={VARIABLES.COLORS.GRAY_12}
                          height={VARIABLES.FIELD.HEIGHT}
                          labelFontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_14}
                          labelFontColor={VARIABLES.COLORS.GRAY_19}
                          paddingRight={VARIABLES.FIELD.PADDINGS.HORIZONTAL}
                          paddingLeft={VARIABLES.FIELD.PADDINGS.HORIZONTAL}
                          fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_14}
                          fontColor={VARIABLES.COLORS.BLACK}
                          fontLineHeight={VARIABLES.FIELD.HEIGHT}
                          borderWidth='1'
                          borderStyle='solid'
                          borderColor={VARIABLES.COLORS.GRAY_8}
                          borderRadius='3'
                          label={getLanguage('field.street', '')}
                          placeholder={getLanguage('field.streetPlaceholder', '')}
                          name={nameStreet}
                          value={valueStreet}
                          onChange={onChangeStreet}
                          disabled={disabledStreet}
                        />
                      </Grid.ResponsiveColumn>
                      <Grid.ResponsiveColumn ui='fieldSelect'>
                        <FieldBase
                          fluid
                          className={ClassNames(
                            className,
                            'is-label-vertical-block',
                            { 'is-required': requiredWard }
                          )}
                          placeholderColor={VARIABLES.COLORS.GRAY_12}
                          height={VARIABLES.FIELD.HEIGHT}
                          labelFontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_14}
                          labelFontColor={VARIABLES.COLORS.GRAY_19}
                          paddingRight={VARIABLES.FIELD.PADDINGS.HORIZONTAL}
                          paddingLeft={VARIABLES.FIELD.PADDINGS.HORIZONTAL}
                          fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_14}
                          fontColor={VARIABLES.COLORS.BLACK}
                          fontLineHeight={VARIABLES.FIELD.HEIGHT}
                          borderWidth='1'
                          borderStyle='solid'
                          borderColor={VARIABLES.COLORS.GRAY_8}
                          borderRadius='3'
                          label={getLanguage('field.ward', '')}
                          placeholder={getLanguage('field.wardPlaceholder', '')}
                          name={nameWard}
                          value={valueWard}
                          onChange={onChangeWard}
                          disabled={disabledWard}
                        />
                      </Grid.ResponsiveColumn>
                      <Grid.ResponsiveColumn ui='fieldSelect'>
                        <FieldBase
                          fluid
                          className={ClassNames(
                            className,
                            'is-label-vertical-block',
                            { 'is-required': requiredPostalCode }
                          )}
                          placeholderColor={VARIABLES.COLORS.GRAY_12}
                          height={VARIABLES.FIELD.HEIGHT}
                          labelFontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_14}
                          labelFontColor={VARIABLES.COLORS.GRAY_19}
                          paddingRight={VARIABLES.FIELD.PADDINGS.HORIZONTAL}
                          paddingLeft={VARIABLES.FIELD.PADDINGS.HORIZONTAL}
                          fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_14}
                          fontColor={VARIABLES.COLORS.BLACK}
                          fontLineHeight={VARIABLES.FIELD.HEIGHT}
                          borderWidth='1'
                          borderStyle='solid'
                          borderColor={VARIABLES.COLORS.GRAY_8}
                          borderRadius='3'
                          label={getLanguage('field.postalCode', '')}
                          placeholder={getLanguage('field.postalCodePlaceholder', '')}
                          name={namePostalCode}
                          value={valuePostalCode}
                          onChange={onChangePostalCode}
                          disabled={disabledPostalCode}
                          disabledColor={disabledColor}
                        />
                      </Grid.ResponsiveColumn>
                    </Grid>
                  </Section>
                </React.Fragment>
              )
            case 'addressIndonesia':
              return (
                <React.Fragment>
                  <Section spacingBottom='30'>
                    <Grid>
                      <Grid.Column
                        gridTablet='12'
                        gridLaptop='7'
                      >
                        <FieldBase
                          fluid
                          className={ClassNames(
                            className,
                            'is-label-vertical-block',
                            { 'is-required': requiredAddress }
                          )}
                          type='textarea'
                          placeholderColor={VARIABLES.COLORS.GRAY_12}
                          labelFontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_14}
                          labelFontColor={VARIABLES.COLORS.GRAY_19}
                          height='130'
                          paddingTop={VARIABLES.FIELD.PADDINGS.TOP_BLOCK}
                          paddingRight={VARIABLES.FIELD.PADDINGS.HORIZONTAL}
                          paddingBottom={VARIABLES.FIELD.PADDINGS.BOTTOM_BLOCK}
                          paddingLeft={VARIABLES.FIELD.PADDINGS.HORIZONTAL}
                          fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_14}
                          fontColor={VARIABLES.COLORS.BLACK}
                          borderWidth='1'
                          borderStyle='solid'
                          borderColor={VARIABLES.COLORS.GRAY_8}
                          borderRadius='3'
                          label={labelAddress}
                          placeholder={placeholderAddress}
                          name={nameAddress}
                          value={valueAddress}
                          onChange={onChangeAddress}
                          disabled={disabledAddress}
                          disabledColor={disabledColor}
                          message={
                            <Section
                              ui='hintBottomField'
                              className={errorInstallationAddress && 'is-message-error'}
                              hint={getLanguage('field.installationAddressHint', '')}
                            />
                          }
                          error={errorInstallationAddress}
                        />
                      </Grid.Column>
                    </Grid>
                  </Section>
                  <Section spacingBottom='30'>
                    <Grid
                      gutter='35'
                      gutterVertical='30'
                    >
                      <Grid.ResponsiveColumn ui='fieldSelect'>
                        <SelectControl
                          verticalLabel
                          required={requiredProvince}
                          isSearchable={isSearchableProvince}
                          label={getLanguage('field.province', '')}
                          placeholder={getLanguage('field.provincePlaceholder', '')}
                          name={nameProvince}
                          value={valueProvince}
                          onChange={onChangeProvince}
                          options={optionProvince}
                          disabled={disabledProvince}
                          disabledColor={disabledColor}
                        />
                      </Grid.ResponsiveColumn>
                      <Grid.ResponsiveColumn ui='fieldSelect'>
                        <SelectControl
                          verticalLabel
                          required={requiredCity}
                          isSearchable={isSearchableCity}
                          label={getLanguage('field.city', '')}
                          placeholder={getLanguage('field.cityPlaceholder', '')}
                          name={nameCity}
                          value={valueCity}
                          onChange={onChangeCity}
                          options={optionCity}
                          disabled={disabledCity}
                          disabledColor={disabledColor}
                        />
                      </Grid.ResponsiveColumn>
                    </Grid>
                  </Section>
                  <Section>
                    <Grid
                      gutter='35'
                      gutterVertical='30'
                    >
                      <Grid.ResponsiveColumn ui='fieldSelect'>
                        {/* <SelectControl
                          verticalLabel
                          required={requiredDistrict}
                          isSearchable={isSearchableDistrict}
                          label='District (Kecamatan)'
                          placeholder='Insert District (Kecamatan)'
                          name={nameDistrict}
                          value={valueDistrict}
                          onChange={onChangeDistrict}
                          options={optionDistrict}
                        /> */}
                        <FieldBase
                          fluid
                          className={ClassNames(
                            className,
                            'is-label-vertical-block',
                            { 'is-required': requiredDistrict }
                          )}
                          placeholderColor={VARIABLES.COLORS.GRAY_12}
                          height={VARIABLES.FIELD.HEIGHT}
                          labelFontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_14}
                          labelFontColor={VARIABLES.COLORS.GRAY_19}
                          paddingRight={VARIABLES.FIELD.PADDINGS.HORIZONTAL}
                          paddingLeft={VARIABLES.FIELD.PADDINGS.HORIZONTAL}
                          fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_14}
                          fontColor={VARIABLES.COLORS.BLACK}
                          fontLineHeight={VARIABLES.FIELD.HEIGHT}
                          borderWidth='1'
                          borderStyle='solid'
                          borderColor={VARIABLES.COLORS.GRAY_8}
                          borderRadius='3'
                          label={getLanguage('field.districtIN', '')}
                          placeholder={getLanguage('field.districtINPlaceholder', '')}
                          name={nameDistrict}
                          value={valueDistrict}
                          onChange={onChangeDistrict}
                          disabled={disabledDistrict}
                          disabledColor={disabledColor}
                        />
                      </Grid.ResponsiveColumn>
                      <Grid.ResponsiveColumn ui='fieldSelect'>
                        {/* <SelectControl
                          verticalLabel
                          required={requiredSubdistrict}
                          isSearchable={isSearchableSubdistrict}
                          label='Subdistrict (Kelurahan Bahasa)'
                          placeholder='Insert Subdistrict (Kelurahan Bahasa)'
                          name={nameSubdistrict}
                          value={valueSubdistrict}
                          onChange={onChangeSubdistrict}
                          options={optionSubdistrict}
                        /> */}
                        <FieldBase
                          fluid
                          className={ClassNames(
                            className,
                            'is-label-vertical-block',
                            { 'is-required': requiredSubdistrict }
                          )}
                          placeholderColor={VARIABLES.COLORS.GRAY_12}
                          height={VARIABLES.FIELD.HEIGHT}
                          labelFontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_14}
                          labelFontColor={VARIABLES.COLORS.GRAY_19}
                          paddingRight={VARIABLES.FIELD.PADDINGS.HORIZONTAL}
                          paddingLeft={VARIABLES.FIELD.PADDINGS.HORIZONTAL}
                          fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_14}
                          fontColor={VARIABLES.COLORS.BLACK}
                          fontLineHeight={VARIABLES.FIELD.HEIGHT}
                          borderWidth='1'
                          borderStyle='solid'
                          borderColor={VARIABLES.COLORS.GRAY_8}
                          borderRadius='3'
                          label={getLanguage('field.subdistrictIN', '')}
                          placeholder={getLanguage('field.subdistrictINPlaceholder', '')}
                          name={nameSubdistrict}
                          value={valueSubdistrict}
                          onChange={onChangeSubdistrict}
                          disabled={disabledSubdistrict}
                          disabledColor={disabledColor}
                        />
                      </Grid.ResponsiveColumn>
                      <Grid.ResponsiveColumn ui='fieldSelect'>
                        <FieldBase
                          fluid
                          className={ClassNames(
                            className,
                            'is-label-vertical-block',
                            { 'is-required': requiredPostalCode }
                          )}
                          placeholderColor={VARIABLES.COLORS.GRAY_12}
                          height={VARIABLES.FIELD.HEIGHT}
                          labelFontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_14}
                          labelFontColor={VARIABLES.COLORS.GRAY_19}
                          paddingRight={VARIABLES.FIELD.PADDINGS.HORIZONTAL}
                          paddingLeft={VARIABLES.FIELD.PADDINGS.HORIZONTAL}
                          fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_14}
                          fontColor={VARIABLES.COLORS.BLACK}
                          fontLineHeight={VARIABLES.FIELD.HEIGHT}
                          borderWidth='1'
                          borderStyle='solid'
                          borderColor={VARIABLES.COLORS.GRAY_8}
                          borderRadius='3'
                          label={getLanguage('field.postalCode', '')}
                          placeholder={getLanguage('field.postalCodePlaceholder', '')}
                          name={namePostalCode}
                          value={valuePostalCode}
                          onChange={onChangePostalCode}
                          disabled={disabledPostalCode}
                          disabledColor={disabledColor}
                        />
                      </Grid.ResponsiveColumn>
                    </Grid>
                  </Section>
                </React.Fragment>
              )
            case 'conditionUnit':
              return (
                <div className='field-unit'>
                  <FieldBase
                    {...this.props}
                    className={ClassNames(
                      className,
                      { 'is-required': required },
                    )}
                    placeholderColor={VARIABLES.COLORS.GRAY_12}
                    height={VARIABLES.FIELD.HEIGHT}
                    labelFontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_14}
                    labelFontColor={VARIABLES.COLORS.GRAY_19}
                    paddingTop='0'
                    paddingRight='50'
                    paddingLeft={VARIABLES.FIELD.PADDINGS.HORIZONTAL}
                    fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_14}
                    fontColor={VARIABLES.COLORS.BLACK}
                    fontLineHeight={VARIABLES.FIELD.HEIGHT}
                    borderWidth='1'
                    borderStyle='solid'
                    borderColor={VARIABLES.COLORS.GRAY_8}
                    borderRadius='3'
                    textAlign='center'
                  />
                  <div className="field-unit-name">
                    {unitName}
                  </div>
                </div>
              )
            case 'pin':
              return (
                <FieldBase
                  {...this.props}
                  className={ClassNames(
                    className,
                    'is-pin',
                  )}
                  placeholderColor={VARIABLES.COLORS.GRAY_12}
                  width='40'
                  paddingTop='0'
                  paddingRight='0'
                  paddingBottom='4'
                  paddingLeft='0'
                  fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_36}
                  fontColor={VARIABLES.COLORS.BLACK}
                  fontLineHeight='36'
                  borderWidth='1'
                  borderStyle='solid'
                  borderColor={VARIABLES.COLORS.PRIMARY_2}
                  borderRadius='4'
                  textAlign='center'
                />
              )
            default:
              return (
                <FieldBase
                  {...this.props}
                  className={classes}
                  placeholderColor={VARIABLES.COLORS.GRAY_12}
                  labelFontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_14}
                  labelFontColor={VARIABLES.COLORS.GRAY_19}
                  fontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_14}
                  fontColor={VARIABLES.COLORS.BLACK}
                  paddingTop={VARIABLES.FIELD.PADDINGS.TOP}
                  paddingBottom={VARIABLES.FIELD.PADDINGS.BOTTOM}
                  borderBottomWidth='1'
                  borderBottomStyle='solid'
                  borderBottomColor={VARIABLES.COLORS.GRAY_2}
                />
              )
          }
        })()}
      </React.Fragment>
    )
  }

  static defaultProps = {
    // * If you set new default props, you should create propsType for correct styleguide document.
    disabledColor: VARIABLES.COLORS.GRAY_1,
    isSearchableProvince: false,
    isSearchableCity: false,
    isSearchableDistrict: false,
    isSearchableSubdistrict: false
  }

  static propTypes = {
    // TYPE > node, string, number, bool, array, object, symbol, func
    /**
    * props_name_oneType
    */
    // props_name_onetype: PropTypes.string,

    /**
    * props_name_oneMoreType
    */
    // props_name_oneMoreType: PropTypes.oneOfType([
    //   PropTypes.node,
    //   PropTypes.string
    // ])
  }
}
