import React from 'react';
// import {
//   connect,
// } from 'react-redux';
import {
  Section,
  Grid,
  Modal,
  Text,
  Button,
  Table,
  Image,
} from '../../../components'
import {
  VARIABLES,
  ICONS
} from '../../../themes'
import { getLanguage } from '../../../helpers/functions/language';
import { displayUrlPath } from '../../../helpers/functions/display';
// import { MODAL_TYPE } from '../../../helpers/constants/main'
// import { MAIN_REDUX_STATE_KEY } from '../../../actions/mainAction'

export class ModalCoilApprovalContainer extends React.Component {

  constructor(props) {
    super(props);
    this.state = {}
  }

  render() {
    const {
      isOpenModal,
      isSecondModal,
      onClickClose,
      onClickCancel,
      title,
      dataList,
      onClickConfirm,
      isApprove
    } = this.props
    return (
      <Modal
        open={isOpenModal}
        scroll
        second={isSecondModal}
        // enableOverlayClose={false}
        width={VARIABLES.MODAL.WIDTHS.W_1165}
        overlayColor={VARIABLES.COLORS.OVERLAY_BLACK_1}
        spacing={VARIABLES.MODAL.CONTAINER.C_1.SPACING}
        transition={VARIABLES.TRANSITIONS.DEFAULT}
        transformStart={VARIABLES.MODAL.CONTAINER.C_1.TRANSFORM_START}
        transformEnd={VARIABLES.MODAL.CONTAINER.C_1.TRANSFORM_END}
        onClickClose={onClickClose}
      >
        <Modal.Section
          paddingVertical='25'
          paddingHorizontal='35'
          bgColor={VARIABLES.COLORS.WHITE}
          borderBottomWidth='1'
          borderBottomStyle='solid'
          borderBottomColor={VARIABLES.COLORS.GRAY_8}
          borderTopRightRadius={VARIABLES.MODAL.HEADER.H_1.BORDER_TOP_RIGHT_RADIUS}
          borderTopLeftRadius={VARIABLES.MODAL.HEADER.H_1.BORDER_TOP_LEFT_RADIUS}
        >
          <Text
            regular16
            singleLine
            color={VARIABLES.COLORS.BLACK}
            top='2'
          >
            {title}
          </Text>
        </Modal.Section>
        <Modal.Section
          paddingVertical='45'
          paddingHorizontal='45'
          bgColor={VARIABLES.COLORS.WHITE}
        >
          <Section>
            <Table strip>
              <Table.Section>
                <Table.Row>
                  <Table.HeadColumn
                    action
                    noSort
                    alignCenter
                    maxWidth={30}
                    minWidth={30}
                  >
                    {getLanguage('coilApproval.modal.modalCoilApproval.table.header.no', '')}
                  </Table.HeadColumn>
                  <Table.HeadColumn
                    noSort
                    maxWidth={100}
                    minWidth={100}
                  >
                    {getLanguage('coilApproval.modal.modalCoilApproval.table.header.coilId', '')}
                  </Table.HeadColumn>
                  <Table.HeadColumn
                    noSort
                    maxWidth={193}
                    minWidth={193}
                  >
                    {getLanguage('coilApproval.modal.modalCoilApproval.table.header.coilName', '')}
                  </Table.HeadColumn>
                  <Table.HeadColumn
                    noSort
                    maxWidth={110}
                    minWidth={110}
                  >
                    {getLanguage('coilApproval.modal.modalCoilApproval.table.header.thickness', '')}
                  </Table.HeadColumn>
                  <Table.HeadColumn
                    noSort
                    maxWidth={140}
                    minWidth={140}
                  >
                    {getLanguage('coilApproval.modal.modalCoilApproval.table.header.color', '')}
                  </Table.HeadColumn>
                  <Table.HeadColumn
                    noSort
                    maxWidth={80}
                    minWidth={80}
                  >
                    {getLanguage('coilApproval.modal.modalCoilApproval.table.header.aging', '')}
                  </Table.HeadColumn>
                  <Table.HeadColumn
                    noSort
                    alignRight
                    maxWidth={120}
                    minWidth={120}
                  >
                    {getLanguage('coilApproval.modal.modalCoilApproval.table.header.reWeight', '')}
                  </Table.HeadColumn>
                  <Table.HeadColumn
                    noSort
                    alignRight
                    maxWidth={120}
                    minWidth={120}
                  >
                    {getLanguage('coilApproval.modal.modalCoilApproval.table.header.lengthBalance', '')}
                  </Table.HeadColumn>
                  <Table.HeadColumn
                    noSort
                    alignRight
                    maxWidth={142}
                    minWidth={142}
                  >
                    {getLanguage('coilApproval.modal.modalCoilApproval.table.header.tranferAmount', '')}
                  </Table.HeadColumn>
                </Table.Row>
              </Table.Section>
            </Table>
            <Section scrollOverlay maxHeight='189'>
              <Table striped>
                <Table.Section>
                  {
                    dataList.map((e, i) => {
                      return (
                        <Table.Row key={i}>
                          <Table.BodyColumn
                            info
                            alignRight
                            maxWidth={30}
                            minWidth={30}
                          >
                            {i + 1}
                          </Table.BodyColumn>
                          <Table.BodyColumn
                            info
                            maxWidth={100}
                            minWidth={100}
                            title={e.coilId}
                          >
                            {e.coilId}
                          </Table.BodyColumn>
                          <Table.BodyColumn
                            info
                            maxWidth={193}
                            minWidth={193}
                            title={e.coilName}
                          >
                            {e.coilName}
                          </Table.BodyColumn>
                          <Table.BodyColumn
                            info
                            maxWidth={110}
                            minWidth={110}
                            title={e.thickness}
                          >
                            {e.thickness}
                          </Table.BodyColumn>
                          <Table.BodyColumn
                            info
                            maxWidth={140}
                            minWidth={140}
                            title={e.color}
                          >
                            {e.color}
                          </Table.BodyColumn>
                          <Table.BodyColumn
                            info
                            alignRight
                            maxWidth={80}
                            minWidth={80}
                            title={e.aging}
                          >
                            {e.aging}
                          </Table.BodyColumn>
                          <Table.BodyColumn
                            info
                            alignRight
                            maxWidth={120}
                            minWidth={120}
                            title={e.weight}
                          >
                            {e.weight}
                          </Table.BodyColumn>
                          <Table.BodyColumn
                            info
                            alignRight
                            maxWidth={120}
                            minWidth={120}
                            title={e.lengthBalance}
                          >
                            {e.lengthBalance}
                          </Table.BodyColumn>
                          <Table.BodyColumn
                            info
                            alignRight
                            maxWidth={142}
                            minWidth={142}
                            title={e.length}
                          >
                            {e.length}
                          </Table.BodyColumn>
                        </Table.Row>
                      )
                    })
                  }
                </Table.Section>
              </Table>
            </Section>
          </Section>
        </Modal.Section>
        <Modal.Section
          paddingHorizontal='45'
          paddingVertical='30'
          borderTopWidth={VARIABLES.MODAL.FOOTER.F_1.BORDER_TOP_WIDTH}
          borderTopStyle={VARIABLES.MODAL.FOOTER.F_1.BORDER_TOP_STYLE}
          borderTopColor={VARIABLES.COLORS.GRAY_8}
          borderBottomRightRadius={VARIABLES.MODAL.FOOTER.F_1.BORDER_BOTTOM_RIGHT_RADIUS}
          borderBottomLeftRadius={VARIABLES.MODAL.FOOTER.F_1.BORDER_BOTTOM_LEFT_RADIUS}
        >
          <Grid
            gutter='15'
            justify='flex-end'
          >
            <Grid.Column>
              <Button
                gray
                width={VARIABLES.BUTTON.WIDTHS.W_100}
                name={getLanguage('field.buttonCancel')}
                onClick={onClickClose}
              />
            </Grid.Column>
            <Grid.Column>
              {
                isApprove ? (
                  <React.Fragment>
                    <Button
                      blue
                      width={VARIABLES.BUTTON.WIDTHS.W_160}
                      name={getLanguage('coilApproval.button.buttonConformAndApprove', '')}
                      onClick={onClickConfirm}
                    />
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <Button
                      red
                      width={VARIABLES.BUTTON.WIDTHS.W_160}
                      name={getLanguage('coilApproval.button.buttonConfirmAndClear', '')}
                      onClick={onClickConfirm}
                    />
                  </React.Fragment>
                )
              }
            </Grid.Column>
          </Grid>
        </Modal.Section>
      </Modal>
    )
  }
}
