import { displayDateTime, displayWithPrefix, displayWithComma } from "../helpers/functions/display";

export class ModelUploadTransactionHistoryDetail {
  constructor(props = {}) {
    this.id = props.id;
    this.logId = props.log_id;
    this.status = props.status;
    this.isError = this.status !== 'success';
    this.invoiceNo = props.invoice_no;
    this.invoiceLine = props.invoice_line;
    this.errorMsg = props.error_msg;
    this.salesPerson = props.sales_person;
    this.phone = props.phone;
    this.email = props.email;
    this.coilNumber = props.coil_number;
    this.lengthSold = props.length_sold;
    this.lengthSoldWithComma = displayWithComma(props.length_sold);
    this.projectName = props.project_name;
    this.homeOwner = props.home_owner;
    this.createdAt = props.created_at;
    this.displayCreatedAt = displayDateTime(this.createdAt, '/');
  }
}