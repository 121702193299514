import ClassNames from 'classnames';
import _ from 'lodash';
import React from 'react';
import {
  AccordionItem,
  AccordionItemBody,
  AccordionItemTitle
} from 'react-accessible-accordion';
import { DATA } from '../../helpers/constants/data';
import { ENUM } from '../../helpers/constants/enum';
import { LOCAL_STORAGE } from '../../helpers/constants/localStorage';
import { getLanguage } from '../../helpers/functions/language';
import { getLocalStorage } from '../../helpers/functions/localStorage';
import { isShowLoading, toast } from '../../helpers/functions/main';
import { isValidFloat } from '../../helpers/functions/validation';
import { getWarrantyConditionTemplateSearchDetail } from '../../helpers/services/warranty';
import {
  AccordionControl,
  Field,
  Grid,
  Section,
  SelectControl,
  ShapeContainer,
  Text
} from './../../components';
import {
  VARIABLES
} from './../../themes';

export class WarrantyEnvConditionContainer extends React.Component {

  constructor(props) {
    super(props);
    // props.handleUpdateEnvDetail();
    const warrantyAppDetailNameList = DATA.WARRANTY_APP_DETAIL_NAME_SELECT_LIST;
    const conditionTypeList = ['radio2', 'input', 'textarea', 'radioWithOptionInputAndRadio'];

    const language = getLocalStorage(LOCAL_STORAGE.LANGUAGE_RBPTRUEBLUE);
    const isLanguageTh = language === DATA.LANGUAGE.THAI;

    this.state = {
      warrantyAppDetailNameList,
      conditionTypeList,
      conditionTextAreaMaxLength: 500,
      conditionGroup: {
        //isRoofSelected
        [warrantyAppDetailNameList[0]]: [{
          type: conditionTypeList[0],
          title: getLanguage('warrantyManage.form.condition.roofConditions.condition1', ''),
          fieldName: 'wrtTermCon1',
        }, {
          type: conditionTypeList[0],
          title: getLanguage('warrantyManage.form.condition.roofConditions.condition2', ''),
          fieldName: 'wrtTermCon2',
        }, {
          type: conditionTypeList[0],
          title: getLanguage('warrantyManage.form.condition.roofConditions.condition3', ''),
          fieldName: 'wrtTermCon3',
        }, {
          type: conditionTypeList[0],
          title: getLanguage('warrantyManage.form.condition.roofConditions.condition4', ''),
          fieldName: 'wrtTermCon4',
        }, {
          type: conditionTypeList[0],
          title: getLanguage('warrantyManage.form.condition.roofConditions.condition5', ''),
          fieldName: 'wrtTermCon5',
        }, {
          type: conditionTypeList[0],
          title: getLanguage('warrantyManage.form.condition.roofConditions.condition6', ''),
          fieldName: 'wrtTermCon6',
        }, {
          type: conditionTypeList[0],
          title: getLanguage('warrantyManage.form.condition.roofConditions.condition7', ''),
          fieldName: 'wrtTermCon7',
        }, {
          type: conditionTypeList[0],
          title: getLanguage('warrantyManage.form.condition.roofConditions.condition8', ''),
          fieldName: 'wrtTermCon8',
        }, {
          type: conditionTypeList[0],
          title: getLanguage('warrantyManage.form.condition.roofConditions.condition9', ''),
          fieldName: 'wrtTermCon9',
        }, {
          type: conditionTypeList[0],
          title: getLanguage('warrantyManage.form.condition.roofConditions.condition10', ''),
          fieldName: 'wrtTermCon10',
        }, {
          type: conditionTypeList[1],
          title: getLanguage('warrantyManage.form.condition.roofConditions.condition11_1', ''),
          title2: getLanguage('warrantyManage.form.condition.roofConditions.condition11_2', ''),
          fieldName: 'wrtTermCon11',
        }, {
          type: conditionTypeList[1],
          title: getLanguage('warrantyManage.form.condition.roofConditions.condition12_1', ''),
          title2: getLanguage('warrantyManage.form.condition.roofConditions.condition12_2', ''),
          fieldName: 'wrtTermCon12',
        }, {
          type: conditionTypeList[1],
          title: getLanguage('warrantyManage.form.condition.roofConditions.condition13_1', ''),
          title2: getLanguage('warrantyManage.form.condition.roofConditions.condition13_2', ''),
          fieldName: 'wrtTermCon13',
        }, {
          type: conditionTypeList[1],
          title: getLanguage('warrantyManage.form.condition.roofConditions.condition14_1', ''),
          title2: getLanguage('warrantyManage.form.condition.roofConditions.condition14_2', ''),
          fieldName: 'wrtTermCon14',
        }, {
          type: conditionTypeList[2],
          title: getLanguage('warrantyManage.form.condition.roofConditions.condition15', ''),
          title2: getLanguage('warrantyManage.form.condition.roofConditions.condition15', ''),
          fieldName: 'wrtTermCon15',
        }, {
          type: conditionTypeList[2],
          title: getLanguage('warrantyManage.form.condition.roofConditions.condition16', ''),
          title2: getLanguage('warrantyManage.form.condition.roofConditions.condition16', ''),
          fieldName: 'wrtTermCon16',
        }],
        //isWallSelected
        [warrantyAppDetailNameList[1]]: [{
          type: conditionTypeList[0],
          title: getLanguage('warrantyManage.form.condition.wallConditions.condition1', ''),
          fieldName: 'wrtTermCon1',
        }, {
          type: conditionTypeList[0],
          title: getLanguage('warrantyManage.form.condition.wallConditions.condition2', ''),
          fieldName: 'wrtTermCon2',
        }, {
          type: conditionTypeList[0],
          title: getLanguage('warrantyManage.form.condition.wallConditions.condition3', ''),
          fieldName: 'wrtTermCon3',
        }, {
          type: conditionTypeList[0],
          title: getLanguage('warrantyManage.form.condition.wallConditions.condition4', ''),
          fieldName: 'wrtTermCon4',
        }, {
          type: conditionTypeList[0],
          title: getLanguage('warrantyManage.form.condition.wallConditions.condition5', ''),
          fieldName: 'wrtTermCon5',
        }, {
          type: conditionTypeList[0],
          title: getLanguage('warrantyManage.form.condition.wallConditions.condition6', ''),
          fieldName: 'wrtTermCon6',
        }, {
          type: conditionTypeList[0],
          title: getLanguage('warrantyManage.form.condition.wallConditions.condition7', ''),
          fieldName: 'wrtTermCon7',
        }, {
          type: conditionTypeList[0],
          title: getLanguage('warrantyManage.form.condition.wallConditions.condition8', ''),
          fieldName: 'wrtTermCon8',
        }, {
          type: conditionTypeList[0],
          title: getLanguage('warrantyManage.form.condition.wallConditions.condition9', ''),
          fieldName: 'wrtTermCon9',
        }, {
          type: conditionTypeList[0],
          title: getLanguage('warrantyManage.form.condition.wallConditions.condition10', ''),
          fieldName: 'wrtTermCon10',
        }, {
          type: conditionTypeList[1],
          title: getLanguage('warrantyManage.form.condition.wallConditions.condition11_1', ''),
          title2: getLanguage('warrantyManage.form.condition.wallConditions.condition11_2', ''),
          fieldName: 'wrtTermCon11',
        }, {
          type: conditionTypeList[1],
          title: getLanguage('warrantyManage.form.condition.wallConditions.condition12_1', ''),
          title2: getLanguage('warrantyManage.form.condition.wallConditions.condition12_2', ''),
          fieldName: 'wrtTermCon12',
        }, {
          type: conditionTypeList[1],
          title: getLanguage('warrantyManage.form.condition.wallConditions.condition13_1', ''),
          title2: getLanguage('warrantyManage.form.condition.wallConditions.condition13_2', ''),
          fieldName: 'wrtTermCon13',
        }, {
          type: conditionTypeList[1],
          title: getLanguage('warrantyManage.form.condition.wallConditions.condition14_1', ''),
          title2: getLanguage('warrantyManage.form.condition.wallConditions.condition14_2', ''),
          fieldName: 'wrtTermCon14',
        }, {
          type: conditionTypeList[2],
          title: getLanguage('warrantyManage.form.condition.wallConditions.condition15', ''),
          fieldName: 'wrtTermCon15',
        }, {
          type: conditionTypeList[2],
          title: getLanguage('warrantyManage.form.condition.wallConditions.condition16', ''),
          fieldName: 'wrtTermCon16',
        }],
        //isPurlinGirtSelected
        [warrantyAppDetailNameList[2]]: [{
          type: conditionTypeList[0],
          title: getLanguage('warrantyManage.form.condition.purlinAndGirtConditions.condition1', ''),
          fieldName: 'wrtTermCon1',
        }, {
          type: conditionTypeList[0],
          title: getLanguage('warrantyManage.form.condition.purlinAndGirtConditions.condition2', ''),
          fieldName: 'wrtTermCon2',
        }, {
          type: conditionTypeList[0],
          title: getLanguage('warrantyManage.form.condition.purlinAndGirtConditions.condition3', ''),
          fieldName: 'wrtTermCon3',
        }, {
          type: conditionTypeList[0],
          title: getLanguage('warrantyManage.form.condition.purlinAndGirtConditions.condition4', ''),
          fieldName: 'wrtTermCon4',
        }, {
          type: conditionTypeList[0],
          title: getLanguage('warrantyManage.form.condition.purlinAndGirtConditions.condition5', ''),
          fieldName: 'wrtTermCon5',
        }, {
          type: conditionTypeList[0],
          title: getLanguage('warrantyManage.form.condition.purlinAndGirtConditions.condition6', ''),
          fieldName: 'wrtTermCon6',
        }, {
          type: conditionTypeList[0],
          title: getLanguage('warrantyManage.form.condition.purlinAndGirtConditions.condition7', ''),
          fieldName: 'wrtTermCon7',
        }, {
          type: conditionTypeList[0],
          title: getLanguage('warrantyManage.form.condition.purlinAndGirtConditions.condition8', ''),
          fieldName: 'wrtTermCon8',
        }, {
          type: conditionTypeList[0],
          title: getLanguage('warrantyManage.form.condition.purlinAndGirtConditions.condition9', ''),
          fieldName: 'wrtTermCon9',
        }, {
          type: conditionTypeList[0],
          title: getLanguage('warrantyManage.form.condition.purlinAndGirtConditions.condition10', ''),
          fieldName: 'wrtTermCon10',
        }, {
          type: conditionTypeList[0],
          title: getLanguage('warrantyManage.form.condition.purlinAndGirtConditions.condition11', ''),
          fieldName: 'wrtTermCon11',
        }, {
          type: conditionTypeList[1],
          title: getLanguage('warrantyManage.form.condition.purlinAndGirtConditions.condition12_1', ''),
          title2: getLanguage('warrantyManage.form.condition.purlinAndGirtConditions.condition12_2', ''),
          fieldName: 'wrtTermCon12',
        }, {
          type: conditionTypeList[1],
          title: getLanguage('warrantyManage.form.condition.purlinAndGirtConditions.condition13_1', ''),
          title2: getLanguage('warrantyManage.form.condition.purlinAndGirtConditions.condition13_2', ''),
          fieldName: 'wrtTermCon13',
        }, {
          type: conditionTypeList[1],
          title: getLanguage('warrantyManage.form.condition.purlinAndGirtConditions.condition14_1', ''),
          title2: getLanguage('warrantyManage.form.condition.purlinAndGirtConditions.condition14_2', ''),
          fieldName: 'wrtTermCon14',
        }, {
          type: conditionTypeList[1],
          title: getLanguage('warrantyManage.form.condition.purlinAndGirtConditions.condition15_1', ''),
          title2: getLanguage('warrantyManage.form.condition.purlinAndGirtConditions.condition15_2', ''),
          fieldName: 'wrtTermCon15',
        }, {
          type: conditionTypeList[2],
          title: getLanguage('warrantyManage.form.condition.purlinAndGirtConditions.condition16', ''),
          fieldName: 'wrtTermCon16',
        }, {
          type: conditionTypeList[2],
          title: getLanguage('warrantyManage.form.condition.purlinAndGirtConditions.condition17', ''),
          fieldName: 'wrtTermCon17',
        }],
        //isTruFrameSelected
        [warrantyAppDetailNameList[3]]: [{
          type: conditionTypeList[0],
          title: getLanguage('warrantyManage.form.condition.trussAndFrameConditions.condition1', ''),
          fieldName: 'wrtTermCon1',
        }, {
          type: conditionTypeList[0],
          title: getLanguage('warrantyManage.form.condition.trussAndFrameConditions.condition2', ''),
          fieldName: 'wrtTermCon2',
        }, {
          type: conditionTypeList[0],
          title: getLanguage('warrantyManage.form.condition.trussAndFrameConditions.condition3', ''),
          fieldName: 'wrtTermCon3',
        }, {
          type: conditionTypeList[0],
          title: getLanguage('warrantyManage.form.condition.trussAndFrameConditions.condition4', ''),
          fieldName: 'wrtTermCon4',
        }, {
          type: conditionTypeList[0],
          title: getLanguage('warrantyManage.form.condition.trussAndFrameConditions.condition5', ''),
          fieldName: 'wrtTermCon5',
        }, {
          type: conditionTypeList[0],
          title: getLanguage('warrantyManage.form.condition.trussAndFrameConditions.condition6', ''),
          fieldName: 'wrtTermCon6',
        }, {
          type: conditionTypeList[0],
          title: getLanguage('warrantyManage.form.condition.trussAndFrameConditions.condition7', ''),
          fieldName: 'wrtTermCon7',
        }, {
          type: conditionTypeList[0],
          title: getLanguage('warrantyManage.form.condition.trussAndFrameConditions.condition8', ''),
          fieldName: 'wrtTermCon8',
        }, {
          type: conditionTypeList[0],
          title: getLanguage('warrantyManage.form.condition.trussAndFrameConditions.condition9', ''),
          fieldName: 'wrtTermCon9',
        }, {
          type: conditionTypeList[0],
          title: getLanguage('warrantyManage.form.condition.trussAndFrameConditions.condition10', ''),
          fieldName: 'wrtTermCon10',
        }, {
          type: conditionTypeList[0],
          title: getLanguage('warrantyManage.form.condition.trussAndFrameConditions.condition11', ''),
          fieldName: 'wrtTermCon11',
        }, {
          type: conditionTypeList[1],
          title: getLanguage('warrantyManage.form.condition.trussAndFrameConditions.condition12_1', ''),
          title2: getLanguage('warrantyManage.form.condition.trussAndFrameConditions.condition12_2', ''),
          fieldName: 'wrtTermCon12',
        }, {
          type: conditionTypeList[1],
          title: getLanguage('warrantyManage.form.condition.trussAndFrameConditions.condition13_1', ''),
          title2: getLanguage('warrantyManage.form.condition.trussAndFrameConditions.condition13_2', ''),
          fieldName: 'wrtTermCon13',
        }, {
          type: conditionTypeList[1],
          title: getLanguage('warrantyManage.form.condition.trussAndFrameConditions.condition14_1', ''),
          title2: getLanguage('warrantyManage.form.condition.trussAndFrameConditions.condition14_2', ''),
          fieldName: 'wrtTermCon14',
        }, {
          type: conditionTypeList[1],
          title: getLanguage('warrantyManage.form.condition.trussAndFrameConditions.condition15_1', ''),
          title2: getLanguage('warrantyManage.form.condition.trussAndFrameConditions.condition15_2', ''),
          fieldName: 'wrtTermCon15',
        }, {
          type: conditionTypeList[2],
          title: getLanguage('warrantyManage.form.condition.trussAndFrameConditions.condition16', ''),
          fieldName: 'wrtTermCon16',
        }, {
          type: conditionTypeList[2],
          title: getLanguage('warrantyManage.form.condition.trussAndFrameConditions.condition17', ''),
          fieldName: 'wrtTermCon17',
        }],
        //isCoolRoomSelected
        [warrantyAppDetailNameList[4]]: [{
          type: conditionTypeList[0],
          title: getLanguage('warrantyManage.form.condition.coolRoomPanelConditions.condition1', ''),
          fieldName: 'wrtTermCon1',
        }, {
          type: conditionTypeList[0],
          title: getLanguage('warrantyManage.form.condition.coolRoomPanelConditions.condition2', ''),
          fieldName: 'wrtTermCon2',
        }, {
          type: conditionTypeList[0],
          title: getLanguage('warrantyManage.form.condition.coolRoomPanelConditions.condition3', ''),
          fieldName: 'wrtTermCon3',
        }, {
          type: conditionTypeList[0],
          title: getLanguage('warrantyManage.form.condition.coolRoomPanelConditions.condition4', ''),
          fieldName: 'wrtTermCon4',
        }, {
          type: conditionTypeList[0],
          title: getLanguage('warrantyManage.form.condition.coolRoomPanelConditions.condition5', ''),
          fieldName: 'wrtTermCon5',
        }, {
          type: conditionTypeList[0],
          title: getLanguage('warrantyManage.form.condition.coolRoomPanelConditions.condition6', ''),
          fieldName: 'wrtTermCon6',
        }, {
          type: conditionTypeList[0],
          title: getLanguage('warrantyManage.form.condition.coolRoomPanelConditions.condition7', ''),
          fieldName: 'wrtTermCon7',
        }, {
          type: conditionTypeList[0],
          title: getLanguage('warrantyManage.form.condition.coolRoomPanelConditions.condition8', ''),
          fieldName: 'wrtTermCon8',
        }, {
          type: conditionTypeList[0],
          title: getLanguage('warrantyManage.form.condition.coolRoomPanelConditions.condition9', ''),
          fieldName: 'wrtTermCon9',
        }, {
          type: conditionTypeList[0],
          title: getLanguage('warrantyManage.form.condition.coolRoomPanelConditions.condition10', ''),
          fieldName: 'wrtTermCon10',
        }, {
          type: conditionTypeList[1],
          title: getLanguage('warrantyManage.form.condition.coolRoomPanelConditions.condition11_1', ''),
          title2: getLanguage('warrantyManage.form.condition.coolRoomPanelConditions.condition11_2', ''),
          fieldName: 'wrtTermCon11',
        }, {
          type: conditionTypeList[1],
          title: getLanguage('warrantyManage.form.condition.coolRoomPanelConditions.condition12_1', ''),
          title2: getLanguage('warrantyManage.form.condition.coolRoomPanelConditions.condition12_2', ''),
          fieldName: 'wrtTermCon12',
        }, {
          type: conditionTypeList[1],
          title: getLanguage('warrantyManage.form.condition.coolRoomPanelConditions.condition13_1', ''),
          title2: getLanguage('warrantyManage.form.condition.coolRoomPanelConditions.condition13_2', ''),
          fieldName: 'wrtTermCon13',
        }, {
          type: conditionTypeList[1],
          title: getLanguage('warrantyManage.form.condition.coolRoomPanelConditions.condition14_1', ''),
          title2: getLanguage('warrantyManage.form.condition.coolRoomPanelConditions.condition14_2', ''),
          fieldName: 'wrtTermCon14',
        }, {
          type: conditionTypeList[2],
          title: getLanguage('warrantyManage.form.condition.coolRoomPanelConditions.condition15', ''),
          title2: getLanguage('warrantyManage.form.condition.coolRoomPanelConditions.condition15', ''),
          fieldName: 'wrtTermCon15',
        }, {
          type: conditionTypeList[2],
          title: getLanguage('warrantyManage.form.condition.coolRoomPanelConditions.condition16', ''),
          title2: getLanguage('warrantyManage.form.condition.coolRoomPanelConditions.condition16', ''),
          fieldName: 'wrtTermCon16',
        }, {
          type: conditionTypeList[3],
          title: getLanguage('warrantyManage.form.condition.coolRoomPanelConditions.condition17_1', ''),
          fieldName: 'wrtTermCon17',
          fieldName2: 'wrtTermCon17_1',
          isShowOption: true,
          radioList: [{
            title: getLanguage('warrantyManage.form.condition.coolRoomPanelConditions.condition17_2', ''),
            value: getLanguage('warrantyManage.form.condition.coolRoomPanelConditions.condition17_2', ''),
            optionTitle: getLanguage('warrantyManage.form.condition.coolRoomPanelConditions.condition17_3', ''),
            optionList: [{
              title: getLanguage('warrantyManage.form.condition.coolRoomPanelConditions.condition17_4', ''),
              value: getLanguage('warrantyManage.form.condition.coolRoomPanelConditions.condition17_4', ''),
            }, {
              title: getLanguage('warrantyManage.form.condition.coolRoomPanelConditions.condition17_5', ''),
              value: getLanguage('warrantyManage.form.condition.coolRoomPanelConditions.condition17_5', ''),
            }, {
              title: getLanguage('warrantyManage.form.condition.coolRoomPanelConditions.condition17_6', ''),
              value: getLanguage('warrantyManage.form.condition.coolRoomPanelConditions.condition17_6', ''),
            }, {
              title: getLanguage('warrantyManage.form.condition.coolRoomPanelConditions.condition17_7', ''),
              value: getLanguage('warrantyManage.form.condition.coolRoomPanelConditions.condition17_7', ''),
            }, {
              title: getLanguage('warrantyManage.form.condition.coolRoomPanelConditions.condition17_8', ''),
              value: getLanguage('warrantyManage.form.condition.coolRoomPanelConditions.condition17_8', ''),
            }]
          }, {
            title: getLanguage('warrantyManage.form.condition.coolRoomPanelConditions.condition17_9', ''),
            value: getLanguage('warrantyManage.form.condition.coolRoomPanelConditions.condition17_9', ''),
          }, {
            title: getLanguage('warrantyManage.form.condition.coolRoomPanelConditions.condition17_10', ''),
            value: getLanguage('warrantyManage.form.condition.coolRoomPanelConditions.condition17_10', ''),
          }, {
            title: getLanguage('warrantyManage.form.condition.coolRoomPanelConditions.condition17_11', ''),
            value: getLanguage('warrantyManage.form.condition.coolRoomPanelConditions.condition17_11', ''),
          }, {
            title: getLanguage('warrantyManage.form.condition.coolRoomPanelConditions.condition17_12', ''),
            value: getLanguage('warrantyManage.form.condition.coolRoomPanelConditions.condition17_12', ''),
            isInput: true,
          }]
        }],
        //isDeckingSelected
        [warrantyAppDetailNameList[5]]: [{
          type: conditionTypeList[0],
          title: getLanguage('warrantyManage.form.condition.trussAndFrameConditions.condition1', ''),
          fieldName: 'wrtTermCon1',
        }, {
          type: conditionTypeList[0],
          title: getLanguage('warrantyManage.form.condition.trussAndFrameConditions.condition2', ''),
          fieldName: 'wrtTermCon2',
        }, {
          type: conditionTypeList[0],
          title: getLanguage('warrantyManage.form.condition.trussAndFrameConditions.condition3', ''),
          fieldName: 'wrtTermCon3',
        }, {
          type: conditionTypeList[0],
          title: getLanguage('warrantyManage.form.condition.trussAndFrameConditions.condition4', ''),
          fieldName: 'wrtTermCon4',
        }, {
          type: conditionTypeList[0],
          title: getLanguage('warrantyManage.form.condition.trussAndFrameConditions.condition5', ''),
          fieldName: 'wrtTermCon5',
        }, {
          type: conditionTypeList[0],
          title: getLanguage('warrantyManage.form.condition.trussAndFrameConditions.condition6', ''),
          fieldName: 'wrtTermCon6',
        }, {
          type: conditionTypeList[0],
          title: getLanguage('warrantyManage.form.condition.trussAndFrameConditions.condition7', ''),
          fieldName: 'wrtTermCon7',
        }, {
          type: conditionTypeList[0],
          title: getLanguage('warrantyManage.form.condition.trussAndFrameConditions.condition8', ''),
          fieldName: 'wrtTermCon8',
        }, {
          type: conditionTypeList[0],
          title: getLanguage('warrantyManage.form.condition.trussAndFrameConditions.condition9', ''),
          fieldName: 'wrtTermCon9',
        }, {
          type: conditionTypeList[0],
          title: getLanguage('warrantyManage.form.condition.trussAndFrameConditions.condition10', ''),
          fieldName: 'wrtTermCon10',
        }, {
          type: conditionTypeList[0],
          title: getLanguage('warrantyManage.form.condition.trussAndFrameConditions.condition11', ''),
          fieldName: 'wrtTermCon11',
        }, {
          type: conditionTypeList[1],
          title: getLanguage('warrantyManage.form.condition.trussAndFrameConditions.condition12_1', ''),
          title2: getLanguage('warrantyManage.form.condition.trussAndFrameConditions.condition12_2', ''),
          fieldName: 'wrtTermCon12',
        }, {
          type: conditionTypeList[1],
          title: getLanguage('warrantyManage.form.condition.trussAndFrameConditions.condition13_1', ''),
          title2: getLanguage('warrantyManage.form.condition.trussAndFrameConditions.condition13_2', ''),
          fieldName: 'wrtTermCon13',
        }, {
          type: conditionTypeList[1],
          title: getLanguage('warrantyManage.form.condition.trussAndFrameConditions.condition14_1', ''),
          title2: getLanguage('warrantyManage.form.condition.trussAndFrameConditions.condition14_2', ''),
          fieldName: 'wrtTermCon14',
        }, {
          type: conditionTypeList[1],
          title: getLanguage('warrantyManage.form.condition.trussAndFrameConditions.condition15_1', ''),
          title2: getLanguage('warrantyManage.form.condition.trussAndFrameConditions.condition15_2', ''),
          fieldName: 'wrtTermCon15',
        }, {
          type: conditionTypeList[2],
          title: getLanguage('warrantyManage.form.condition.trussAndFrameConditions.condition16', ''),
          fieldName: 'wrtTermCon16',
        }, {
          type: conditionTypeList[2],
          title: getLanguage('warrantyManage.form.condition.trussAndFrameConditions.condition17', ''),
          fieldName: 'wrtTermCon17',
        }],
        //isAirDuctingSelected
        [warrantyAppDetailNameList[6]]: [{
          type: conditionTypeList[0],
          title: getLanguage('warrantyManage.form.condition.airDuctingConditions.condition1', ''),
          fieldName: 'wrtTermCon1',
        }, {
          type: conditionTypeList[0],
          title: getLanguage('warrantyManage.form.condition.airDuctingConditions.condition2', ''),
          fieldName: 'wrtTermCon2',
        }, {
          type: conditionTypeList[0],
          title: getLanguage('warrantyManage.form.condition.airDuctingConditions.condition3', ''),
          fieldName: 'wrtTermCon3',
        }, {
          type: conditionTypeList[0],
          title: getLanguage('warrantyManage.form.condition.airDuctingConditions.condition4', ''),
          fieldName: 'wrtTermCon4',
        }, {
          type: conditionTypeList[0],
          title: getLanguage('warrantyManage.form.condition.airDuctingConditions.condition5', ''),
          fieldName: 'wrtTermCon5',
        }, {
          type: conditionTypeList[0],
          title: getLanguage('warrantyManage.form.condition.airDuctingConditions.condition6', ''),
          fieldName: 'wrtTermCon6',
        }, {
          type: conditionTypeList[0],
          title: getLanguage('warrantyManage.form.condition.airDuctingConditions.condition7', ''),
          fieldName: 'wrtTermCon7',
        }, {
          type: conditionTypeList[0],
          title: getLanguage('warrantyManage.form.condition.airDuctingConditions.condition8', ''),
          fieldName: 'wrtTermCon8',
        }, {
          type: conditionTypeList[0],
          title: getLanguage('warrantyManage.form.condition.airDuctingConditions.condition9', ''),
          fieldName: 'wrtTermCon9',
        }, {
          type: conditionTypeList[0],
          title: getLanguage('warrantyManage.form.condition.airDuctingConditions.condition10', ''),
          fieldName: 'wrtTermCon10',
        }, {
          type: conditionTypeList[0],
          title: getLanguage('warrantyManage.form.condition.airDuctingConditions.condition11', ''),
          fieldName: 'wrtTermCon11',
        }, {
          type: conditionTypeList[1],
          title: getLanguage('warrantyManage.form.condition.airDuctingConditions.condition12_1', ''),
          title2: getLanguage('warrantyManage.form.condition.airDuctingConditions.condition12_2', ''),
          fieldName: 'wrtTermCon12',
        }, {
          type: conditionTypeList[1],
          title: getLanguage('warrantyManage.form.condition.airDuctingConditions.condition13_1', ''),
          title2: getLanguage('warrantyManage.form.condition.airDuctingConditions.condition13_2', ''),
          fieldName: 'wrtTermCon13',
        }, {
          type: conditionTypeList[1],
          title: getLanguage('warrantyManage.form.condition.airDuctingConditions.condition14_1', ''),
          title2: getLanguage('warrantyManage.form.condition.airDuctingConditions.condition14_2', ''),
          fieldName: 'wrtTermCon14',
        }, {
          type: conditionTypeList[1],
          title: getLanguage('warrantyManage.form.condition.airDuctingConditions.condition15_1', ''),
          title2: getLanguage('warrantyManage.form.condition.airDuctingConditions.condition15_2', ''),
          fieldName: 'wrtTermCon15',
        }, {
          type: conditionTypeList[2],
          title: getLanguage('warrantyManage.form.condition.airDuctingConditions.condition16', ''),
          fieldName: 'wrtTermCon16',
        }, {
          type: conditionTypeList[2],
          title: getLanguage('warrantyManage.form.condition.airDuctingConditions.condition17', ''),
          fieldName: 'wrtTermCon17',
        }],
      },
      isLanguageTh
    }
  }

  componentDidMount() {
    const {
      data,
      isTemplate
    } = this.props;
    // CR: warranty condition
    if (data.isWarrantyConditionTemplate && !isTemplate) {
      this.warrantyManageCondition.getData();
    }
  }

  warrantyManageCondition = {
    getData: async () => {
      const {
        data,
        handleUpdateProductGroupData,
      } = this.props;
      isShowLoading(true);

      const params = {
        data: []
      };
      data.productGroupList.forEach(e => {
        e.templateList.forEach(et => {
          if (e[et.applicationForSelect]) {
            const envDetailSelect = et.envList[et.detailEnvSelect] || {}
            const environmentName = envDetailSelect.environment;
            const applicationName = et.application;
            params.data.push({
              // product_group: e.productGroupName,
              // CR: 1498
              product_group: e.oldProductGroupName,
              environment_id: (data.environmentNameList.filter(ev => ev.environmentName === environmentName)[0] || {}).id,
              application_id: (data.applicationNameList.filter(ev => ev.applicationName === applicationName)[0] || {}).id,

              productGroupName: e.productGroupName,
              environmentName,
              applicationName
            })
          }
        })
      })
      const configService = {
        isShowLoading: false
      }
      const templateList = await getWarrantyConditionTemplateSearchDetail(params, configService)

      const resultList = params.data.map(e => {
        templateList.forEach(er => {
          if (
            // e.productGroupName === er.productGroup &&
            // CR: 1498
            e.product_group === er.productGroup &&
            e.environment_id === er.environmentId &&
            e.application_id === er.applicationId
          ) {
            e.warrantyConditionTemplate = er.warrantyConditionTemplate
          }
        })
        return e;
      })

      console.log('getWarrantyConditionTemplateSearchDetail resultList', resultList)
      resultList.forEach(e => {
        if (!e.warrantyConditionTemplate) {
          toast.error(`${getLanguage('message.warrantySelfDeclaredConditionNotFound', '')}, ${e.productGroupName}, ${e.environmentName}, ${e.applicationName}`);
        }
      });

      data.productGroupList = data.productGroupList.map(e => {
        e.templateList = e.templateList.map(et => {
          if (e[et.applicationForSelect]) {
            const conditionData = _.cloneDeep(et.condition);
            et.condition = {};
            et.conditionError = {};
            et.warrantyConditionTemplate = undefined;
            et.isGetWarrantyConditionTemplateFinish = true;
            et.questionList = [];
            const envDetailSelect = et.envList[et.detailEnvSelect] || {}
            const environmentName = envDetailSelect.environment;
            resultList.forEach(er => {
              if (
                er.warrantyConditionTemplate &&
                e.productGroupName === er.productGroupName &&
                environmentName === er.environmentName &&
                et.application === er.applicationName
              ) {
                et.warrantyConditionTemplate = er.warrantyConditionTemplate
                const questionList = er.warrantyConditionTemplate.questionList
                et.questionList = questionList;
                questionList.forEach(e => {
                  et.condition[e.id] = conditionData[e.id] === undefined ? '' : conditionData[e.id];
                  if (e.isTypeRadio && et.condition[e.id] === '') {
                    et.condition[e.id] = true
                  }
                })
              }
            });
          }
          return et;
        })
        return e;
      })
      // CR: 1498
      await handleUpdateProductGroupData(data.productGroupList);
      console.log('warrantyManageCondition getData', data.productGroupList)
      isShowLoading(false);
    },
    getProductGroupConditionError: (dataGroup) => {
      const {
        data,
      } = this.props;
      let isProductGroupError = dataGroup.templateList.filter(e => {
        let isError = false;
        if (dataGroup[e.applicationForSelect]) {
          // CR: warranty condition
          if (
            data.isWarrantyConditionTemplate &&
            e.isGetWarrantyConditionTemplateFinish
          ) {
            Object.keys(e.conditionError).forEach(keyc => {
              if (isError === false) {
                isError = e.conditionError[keyc] === true;
              }
            });
            if (isError === false) {
              isError = !e.warrantyConditionTemplate;
            }
          }
          else {
            Object.keys(e.condition).forEach(keyc => {
              if (isError === false) {
                isError = e.condition[`${keyc}Error`] === true;
              }
            });
          }
        }
        return isError;
      }).length > 0;
      return isProductGroupError;
    },
    // CR: warranty condition
    renderConditionTemplate: (template, templateIndex, productGroupIndex) => {
      const {
        isLanguageTh,
      } = this.state;
      return template.questionList.map((e, i) => {
        return (
          <Section spacingBottom='24' key={i}>
            <Section spacingBottom='8'>
              <Grid gutter='5'>
                <Grid.Column flex='none'>
                  {i + 1}.
                </Grid.Column>
                <Grid.Column flex='1'>
                  <Section
                    relative
                    top='-2'
                    breakAll
                  >
                    <div
                      className={`text-editor-content ${e.isRequire && 'is-madatory'}`}
                      dangerouslySetInnerHTML={{ __html: isLanguageTh ? e.titleTH : e.titleEN }}
                    />
                  </Section>
                </Grid.Column>
              </Grid>
            </Section>
            {
              e.isTypeRadio && (
                <React.Fragment>
                  <Grid gutter='32'>
                    <Grid.Column>
                      <Field
                        checked={template.condition[e.id] === true}
                        name=''
                        ui='radio'
                        radioLabel={getLanguage('warrantyConditions.label.yes')}
                        onChange={(ev) => {
                          this.input.onChangeDataConditionTemplateInput(e, true, templateIndex, productGroupIndex);
                        }}
                      />
                    </Grid.Column>
                    <Grid.Column>
                      <Field
                        checked={template.condition[e.id] === false}
                        name=''
                        ui='radio'
                        radioLabel={getLanguage('warrantyConditions.label.no')}
                        onChange={(ev) => {
                          this.input.onChangeDataConditionTemplateInput(e, false, templateIndex, productGroupIndex);
                        }}
                      />
                    </Grid.Column>
                  </Grid>
                </React.Fragment>
              )
            }
            {
              e.isTypeInput && (
                <Field
                  error={template.conditionError[e.id] === true}
                  name=""
                  width='392'
                  ui="blockLabelVertical"
                  placeholder={getLanguage('warrantyConditions.field.placeholderInsertDistanceNumber')}
                  value={template.condition[e.id]}
                  onChange={(ev) => {
                    this.input.onChangeDataConditionTemplateInput(e, ev.target.value, templateIndex, productGroupIndex);
                  }}
                />
              )
            }
            {
              e.isTypeSelect && (
                <SelectControl
                  error={template.conditionError[e.id] === true}
                  verticalLabel
                  width='392'
                  placeholder={getLanguage('warrantyConditions.field.placeholderSelectThePreferredOption')}
                  isSearchable={false}
                  value={DATA.WARRANTY_QUESTION_SELECT_TYPE_LIST.filter(ews => ews.value === template.condition[e.id])[0]}
                  onChange={(selected) => {
                    this.input.onChangeDataConditionTemplateInput(e, selected.value, templateIndex, productGroupIndex);
                  }}
                  options={DATA.WARRANTY_QUESTION_SELECT_TYPE_LIST}
                />
              )
            }
            {
              e.isTypeTextarea && (
                <Field
                  fluid
                  error={template.conditionError[e.id] === true}
                  ui='blockLabelVerticalTextarea'
                  placeholder={getLanguage('warrantyConditions.field.placeholderInsertDescription')}
                  value={template.condition[e.id]}
                  onChange={(ev) => {
                    this.input.onChangeDataConditionTemplateInput(e, ev.target.value, templateIndex, productGroupIndex);
                  }}
                />
              )
            }
          </Section>
        )
      })
    },
    renderConditionList: (template, templateIndex, productGroupIndex) => {
      const {
        data,
      } = this.props;
      const {
        conditionGroup,
      } = this.state;
      // CR: warranty condition
      if (data.isWarrantyConditionTemplate) {
        return this.warrantyManageCondition.renderConditionTemplate(template, templateIndex, productGroupIndex)
      }
      else {
        return conditionGroup[template.applicationForSelect].map((e, i) => {
          return this.warrantyManageCondition.renderCondition(e, i, template, templateIndex, productGroupIndex);
        })
      }
    },
    renderCondition: (data, index, template, templateIndex, productGroupIndex) => {
      const {
        isTemplate
      } = this.props;
      const {
        conditionTypeList,
        conditionGroup,
        conditionTextAreaMaxLength,
      } = this.state;
      const propsComponent = index < conditionGroup[template.applicationForSelect].length - 1 ?
        {
          paddingBottom: '20',
          spacingBottom: '15',
          borderBottomWidth: '1',
          borderBottomStyle: 'solid',
          borderBottomColor: VARIABLES.COLORS.GRAY_8
        }
        :
        {};
      switch (data.type) {
        case conditionTypeList[0]:
          return (
            <Section key={index} {...propsComponent}>
              <Grid>
                <Grid.Column flex='none'>
                  <Section width='30'><Text regular16 color={VARIABLES.COLORS.GRAY_19}>{index + 1}.</Text></Section>
                </Grid.Column>
                <Grid.Column flex='1'>
                  <Section spacingBottom='15'>
                    <Text regular16 color={VARIABLES.COLORS.GRAY_19}>
                      {data.title}
                    </Text>
                  </Section>
                  <Grid gutter='25'>
                    <Grid.Column>
                      <Field name={isTemplate ? undefined : `${productGroupIndex}_${templateIndex}_${data.fieldName}`}
                        checked={template.condition[data.fieldName] === true}
                        ui='radio'
                        radioLabel={getLanguage('warrantyManage.form.condition.conditionYes', '')}
                        onChange={(ev) => {
                          this.input.onChangeDataApplicationInput({
                            target: {
                              name: data.fieldName,
                            }
                          }, true, templateIndex, productGroupIndex);
                        }}
                      />
                    </Grid.Column>
                    <Grid.Column>
                      <Field name={isTemplate ? undefined : `${productGroupIndex}_${templateIndex}_${data.fieldName}`}
                        checked={template.condition[data.fieldName] === false}
                        ui='radio'
                        radioLabel={getLanguage('warrantyManage.form.condition.conditionNo', '')}
                        onChange={(ev) => {
                          this.input.onChangeDataApplicationInput({
                            target: {
                              name: data.fieldName,
                            }
                          }, false, templateIndex, productGroupIndex);
                        }}
                      />
                    </Grid.Column>
                  </Grid>
                </Grid.Column>
              </Grid>
            </Section>
          );
        case conditionTypeList[1]:
          const isConditionError = template.condition[`${data.fieldName}Error`] === true;
          return (
            <Section key={index} {...propsComponent}>
              <Grid>
                <Grid.Column flex='none'>
                  <Section width='30' paddingTop='6'><Text regular16 color={VARIABLES.COLORS.GRAY_19}>{index + 1}.</Text></Section>
                </Grid.Column>
                <Grid.Column flex='1'>
                  <Grid gutter='25' gutterVertical='5'>
                    <Grid.Column>
                      <Section paddingTop='6'>
                        <Text regular16 color={VARIABLES.COLORS.GRAY_19}>
                          {data.title}
                        </Text>
                      </Section>
                    </Grid.Column>
                    <Grid.Column>
                      <Field name={`${productGroupIndex}_${templateIndex}_${data.fieldName}`}
                        required
                        error={isConditionError}
                        ui='conditionUnit'
                        width='165'
                        placeholder={getLanguage('warrantyManage.form.condition.conditionUnitPlaceholder', '')}
                        unitName={<Text regular14 color={VARIABLES.COLORS.BLACK}>m.</Text>}
                        value={template.condition[data.fieldName]}
                        onChange={ev => {
                          this.input.onChangeDataApplicationInput({
                            target: {
                              name: data.fieldName,
                            }
                          }, ev.target.value, templateIndex, productGroupIndex);
                        }}
                      />
                    </Grid.Column>
                    <Grid.Column>
                      <Section paddingTop='6'>
                        <Text noWrap regular12 color={isConditionError ? VARIABLES.COLORS.RED_4 : VARIABLES.COLORS.BLACK}>{this.warrantyManageCondition.displayConditionRange(template.envDetailCondition.CONDITION, data.fieldName)}</Text>
                      </Section>
                    </Grid.Column>
                    <Grid.Column>
                      <Section paddingTop='6'>
                        <Text regular16 color={VARIABLES.COLORS.GRAY_19}>
                          {data.title2}
                        </Text>
                      </Section>
                    </Grid.Column>
                  </Grid>
                </Grid.Column>
              </Grid>
            </Section>
          );
        case conditionTypeList[2]:
          return (
            <Section key={index} {...propsComponent}>
              <Grid>
                <Grid.Column flex='none'>
                  <Section width='30'><Text regular16 color={VARIABLES.COLORS.GRAY_19}>{index + 1}.</Text></Section>
                </Grid.Column>
                <Grid.Column flex='1'>
                  <Section spacingBottom='15'>
                    <Text regular16 color={VARIABLES.COLORS.GRAY_19}>
                      {data.title}
                    </Text>
                  </Section>
                  <Grid>
                    <Grid.Column
                      gridTablet='12'
                      gridLaptop='7'
                    >
                      <Field name={`${productGroupIndex}_${templateIndex}_${data.fieldName}`}
                        fluid
                        ui='blockLabelVerticalTextarea'
                        type='textarea'
                        height='130'
                        maxLength={conditionTextAreaMaxLength}
                        placeholder={getLanguage('warrantyManage.form.condition.conditionDescriptionPlaceholder', '')}
                        message={
                          <Section
                            ui='hintBottomTextarea'
                            hint={getLanguage('warrantyManage.form.condition.conditionDescriptionHint', '')}
                          />
                        }
                        value={template.condition[data.fieldName]}
                        onChange={ev => {
                          this.input.onChangeDataApplicationInput({
                            target: {
                              name: data.fieldName,
                            }
                          }, ev.target.value, templateIndex, productGroupIndex);
                        }}
                      />
                    </Grid.Column>
                  </Grid>
                </Grid.Column>
              </Grid>
            </Section>
          );
        case conditionTypeList[3]:
          return (
            <Section key={index}>
              <Grid>
                <Grid.Column flex='none'>
                  <Section width='30'><Text regular16 color={VARIABLES.COLORS.GRAY_19}>{index + 1}.</Text></Section>
                </Grid.Column>
                <Grid.Column flex='1'>
                  <Section spacingBottom='5'>
                    <Text regular16 color={VARIABLES.COLORS.GRAY_19}>
                      {data.title}
                    </Text>
                  </Section>
                </Grid.Column>
              </Grid>
              <Section paddingLeft='30' {...propsComponent}>
                <Grid gutter='25' align='center'>
                  {data.radioList.map((e, i) => {
                    return (
                      <Grid.Column key={i}>
                        {e.isInput ?
                          <Grid gutter='25' align='center'>
                            <Grid.Column>
                              <Field name={`${productGroupIndex}_${templateIndex}_${data.fieldName}`}
                                checked={template.condition[data.fieldName] === e.value}
                                ui='radio'
                                radioLabel={e.title}
                                onChange={ev => {
                                  this.input.onChangeDataApplicationInput({
                                    target: {
                                      name: data.fieldName,
                                    }
                                  }, e.value, templateIndex, productGroupIndex);
                                }}
                              />
                            </Grid.Column>
                            <Grid.Column>
                              <Field name={`${productGroupIndex}_${templateIndex}_${data.fieldName2}`}
                                required={false}
                                disabled={template.condition[data.fieldName] !== e.value}
                                error={false}
                                ui='blockLabelVertical'
                                width='165'
                                placeholder={''}
                                value={template.condition[data.fieldName] === e.value ? template.condition[data.fieldName2] : ''}
                                onChange={ev => {
                                  this.input.onChangeDataApplicationInput({
                                    target: {
                                      name: data.fieldName2,
                                    }
                                  }, ev.target.value, templateIndex, productGroupIndex);
                                }}
                              />
                            </Grid.Column>
                          </Grid>
                          :
                          <Field name={`${productGroupIndex}_${templateIndex}_${data.fieldName}`}
                            checked={template.condition[data.fieldName] === e.value}
                            ui='radio'
                            radioLabel={e.title}
                            onChange={ev => {
                              this.input.onChangeDataApplicationInput({
                                target: {
                                  name: data.fieldName,
                                }
                              }, e.value, templateIndex, productGroupIndex);
                            }}
                          />
                        }
                      </Grid.Column>
                    )
                  })}
                </Grid>
                {data.isShowOption &&
                  <Section paddingTop='10'>
                    <ShapeContainer
                      fluid
                      padding='20'
                      bgColor={VARIABLES.COLORS.GRAY_1}
                      borderRadius='3'
                    >
                      <Section spacingBottom='15'>
                        <Text
                          regular16
                          color={VARIABLES.COLORS.GRAY_19}
                        >
                          {template.condition[data.fieldName] === data.radioList[0].value && data.radioList[0].optionTitle}
                        </Text>
                      </Section>
                      <Grid
                        gutter='25'
                        gutterVertical='15'
                      >
                        {template.condition[data.fieldName] === data.radioList[0].value && data.radioList[0].optionList.map((e, i) => {
                          return (
                            <Grid.Column key={i}>
                              <Field name={`${productGroupIndex}_${templateIndex}_${data.fieldName2}`}
                                checked={template.condition[data.fieldName2] === e.value}
                                ui='radio'
                                radioLabel={e.title}
                                onChange={ev => {
                                  this.input.onChangeDataApplicationInput({
                                    target: {
                                      name: data.fieldName2,
                                    }
                                  }, e.value, templateIndex, productGroupIndex);
                                }}
                              />
                            </Grid.Column>
                          )
                        })}
                      </Grid>
                    </ShapeContainer>
                  </Section>
                }
              </Section>
            </Section>
          );
        default: return null;
      }
    },
    displayConditionRange: (condition, field) => {
      if (condition) {
        const conditionData = condition[field];
        if (conditionData) {
          if (conditionData.MIN && conditionData.MAX) {
            return `(${conditionData.MIN} - ${conditionData.MAX})`;
          }
          else if (conditionData.MIN) {
            return `(>= ${conditionData.MIN})`;
          }
          else if (conditionData.MAX) {
            return `(<= ${conditionData.MAX})`
          }
        }
      }
      return '';
    },
  }

  input = {
    onChangeDataApplicationInput: (ev, value, templateIndex, productGroupIndex) => {
      const {
        onChangeDataApplicationInput,
        data,
      } = this.props;
      const {
        conditionGroup,
      } = this.state;
      let template = data.productGroupList[productGroupIndex].templateList[templateIndex];
      const conditionList = conditionGroup[template.applicationForSelect];
      const name = ev.target.name;

      switch (template.applicationForSelect) {
        case DATA.WARRANTY_APP_DETAIL_NAME_SELECT_LIST[4]:
          switch (name) {
            case 'wrtTermCon17':
              let condition = {};
              let conditionIndex = 0;
              conditionList.forEach((e, i) => {
                if (e.fieldName === 'wrtTermCon17') {
                  condition = e;
                  conditionIndex = i;
                }
              });

              if (value === condition.radioList[0].value) {
                condition.isShowOption = true;
                onChangeDataApplicationInput({
                  target: {
                    name: 'wrtTermCon17_1',
                  }
                }, getLanguage('warrantyManage.form.condition.coolRoomPanelConditions.condition17_4', ''), templateIndex, productGroupIndex);
              }
              else {
                condition.isShowOption = false;
                onChangeDataApplicationInput({
                  target: {
                    name: 'wrtTermCon17_1',
                  }
                }, '', templateIndex, productGroupIndex);
              }
              conditionList[conditionIndex] = condition;
              conditionGroup[template.applicationForSelect] = conditionList;

              this.setState({
                conditionGroup,
              })
              break;
            default: break;
          }
          break;
        default: break;
      }

      onChangeDataApplicationInput(ev, value, templateIndex, productGroupIndex);
    },
    onChangeDataConditionTemplateInput: (item, value, templateIndex, productGroupIndex) => {
      const {
        data,
        handleUpdateProductGroupData,
      } = this.props;
      if (item.isTypeInput) {
        if (!isValidFloat(value)) {
          return;
        };
      }

      data.productGroupList[productGroupIndex].templateList[templateIndex].condition[item.id] = value;
      data.productGroupList[productGroupIndex].templateList[templateIndex].conditionError[item.id] = false;
      handleUpdateProductGroupData(data.productGroupList);
    }
  }

  render() {
    const {
      data,
      isTemplate,
    } = this.props;
    console.log('productGroupList', data.productGroupList)
    return (
      <React.Fragment>
        {data.productGroupList.map((e, i) => {
          const isShowRoofAndWall = e.isRoofAndWall && data.projectCountry === ENUM.COUNTRY_TYPE.THAI;
          return (
            <AccordionItem
              key={i}
              uuid={i}
              className={ClassNames(
                'accordion__item',
                { 'is-error': this.warrantyManageCondition.getProductGroupConditionError(e) },
              )}
            >
              <AccordionItemTitle>
                <AccordionControl.ButtonOutline
                  name={e.productGroupName}
                />
              </AccordionItemTitle>
              <AccordionItemBody style={isTemplate ? { display: 'block' } : undefined}>
                <ShapeContainer
                  fluid
                  paddingTop='30'
                  paddingHorizontal='30'
                  bgColor={VARIABLES.COLORS.GRAY_1}
                  borderRadius='5'
                >
                  {e.templateList.map((et, it) => {
                    if (e[et.applicationForSelect]) {
                      return (
                        <div ref={isTemplate ? `envConditionRef_${e.productGroupName}_${et.application}` : undefined} key={it} className='is-warranty-env-condition-fluid'>
                          {/* Application condition : Roof */}
                          {et.applicationForSelect === DATA.WARRANTY_APP_DETAIL_NAME_SELECT_LIST[0] &&
                            <React.Fragment>
                              <Section spacingBottom='20'>
                                <Section spacingBottom='10'>
                                  <Text regular16 color={VARIABLES.COLORS.PRIMARY_2}>{
                                    isShowRoofAndWall ?
                                      getLanguage('warrantyManage.form.condition.application.roofAndWall', '') :
                                      getLanguage('warrantyManage.form.condition.application.roofing', '')
                                  }</Text>
                                </Section>
                                <Section width='55' borderBottomWidth='3' borderBottomStyle='solid' borderBottomColor={VARIABLES.COLORS.PRIMARY_2} />
                              </Section>
                              <Section spacingBottom='30'>
                                <ShapeContainer
                                  fluid
                                  paddingHorizontal='30'
                                  paddingVertical='35'
                                  bgColor={VARIABLES.COLORS.WHITE}
                                  borderWidth='1'
                                  borderStyle='solid'
                                  borderColor={VARIABLES.COLORS.GRAY_8}
                                  borderRadius='5'
                                >
                                  {this.warrantyManageCondition.renderConditionList(et, it, i)}
                                </ShapeContainer>
                              </Section>
                            </React.Fragment>
                          }
                          {/* Application condition : Wall */}
                          {et.applicationForSelect === DATA.WARRANTY_APP_DETAIL_NAME_SELECT_LIST[1] &&
                            isShowRoofAndWall === false &&
                            <React.Fragment>
                              <Section spacingBottom='20'>
                                <Section spacingBottom='10'>
                                  <Text regular16 color={VARIABLES.COLORS.PRIMARY_2}>{getLanguage('warrantyManage.form.condition.application.walling', '')}</Text>
                                </Section>
                                <Section width='55' borderBottomWidth='3' borderBottomStyle='solid' borderBottomColor={VARIABLES.COLORS.PRIMARY_2} />
                              </Section>
                              <Section spacingBottom='30'>
                                <ShapeContainer
                                  fluid
                                  paddingHorizontal='30'
                                  paddingVertical='35'
                                  bgColor={VARIABLES.COLORS.WHITE}
                                  borderWidth='1'
                                  borderStyle='solid'
                                  borderColor={VARIABLES.COLORS.GRAY_8}
                                  borderRadius='5'
                                >
                                  {this.warrantyManageCondition.renderConditionList(et, it, i)}
                                </ShapeContainer>
                              </Section>
                            </React.Fragment>
                          }
                          {/* Application condition : Purlin & Girt */}
                          {et.applicationForSelect === DATA.WARRANTY_APP_DETAIL_NAME_SELECT_LIST[2] &&
                            <React.Fragment>
                              <Section spacingBottom='20'>
                                <Section spacingBottom='10'>
                                  <Text regular16 color={VARIABLES.COLORS.PRIMARY_2}>{getLanguage('warrantyManage.form.condition.application.purlinAndGirt', '')}</Text>
                                </Section>
                                <Section width='55' borderBottomWidth='3' borderBottomStyle='solid' borderBottomColor={VARIABLES.COLORS.PRIMARY_2} />
                              </Section>
                              <Section spacingBottom='30'>
                                <ShapeContainer
                                  fluid
                                  paddingHorizontal='30'
                                  paddingVertical='35'
                                  bgColor={VARIABLES.COLORS.WHITE}
                                  borderWidth='1'
                                  borderStyle='solid'
                                  borderColor={VARIABLES.COLORS.GRAY_8}
                                  borderRadius='5'
                                >
                                  {this.warrantyManageCondition.renderConditionList(et, it, i)}
                                </ShapeContainer>
                              </Section>
                            </React.Fragment>
                          }
                          {/* Application condition : Truss & Frame */}
                          {et.applicationForSelect === DATA.WARRANTY_APP_DETAIL_NAME_SELECT_LIST[3] &&
                            <React.Fragment>
                              <Section spacingBottom='20'>
                                <Section spacingBottom='10'>
                                  <Text regular16 color={VARIABLES.COLORS.PRIMARY_2}>{getLanguage('warrantyManage.form.condition.application.trussAndFrame', '')}</Text>
                                </Section>
                                <Section width='55' borderBottomWidth='3' borderBottomStyle='solid' borderBottomColor={VARIABLES.COLORS.PRIMARY_2} />
                              </Section>
                              <Section spacingBottom='30'>
                                <ShapeContainer
                                  fluid
                                  paddingHorizontal='30'
                                  paddingVertical='35'
                                  bgColor={VARIABLES.COLORS.WHITE}
                                  borderWidth='1'
                                  borderStyle='solid'
                                  borderColor={VARIABLES.COLORS.GRAY_8}
                                  borderRadius='5'
                                >
                                  {this.warrantyManageCondition.renderConditionList(et, it, i)}
                                </ShapeContainer>
                              </Section>
                            </React.Fragment>
                          }
                          {/* Application condition : Cool room panel */}
                          {et.applicationForSelect === DATA.WARRANTY_APP_DETAIL_NAME_SELECT_LIST[4] &&
                            <React.Fragment>
                              <Section spacingBottom='20'>
                                <Section spacingBottom='10'>
                                  <Text regular16 color={VARIABLES.COLORS.PRIMARY_2}>{getLanguage('warrantyManage.form.condition.application.coolRoomPanel', '')}</Text>
                                </Section>
                                <Section width='55' borderBottomWidth='3' borderBottomStyle='solid' borderBottomColor={VARIABLES.COLORS.PRIMARY_2} />
                              </Section>
                              <Section spacingBottom='30'>
                                <ShapeContainer
                                  fluid
                                  paddingHorizontal='30'
                                  paddingVertical='35'
                                  bgColor={VARIABLES.COLORS.WHITE}
                                  borderWidth='1'
                                  borderStyle='solid'
                                  borderColor={VARIABLES.COLORS.GRAY_8}
                                  borderRadius='5'
                                >
                                  {this.warrantyManageCondition.renderConditionList(et, it, i)}
                                </ShapeContainer>
                              </Section>
                            </React.Fragment>
                          }
                          {/* Application condition : Decking */}
                          {et.applicationForSelect === DATA.WARRANTY_APP_DETAIL_NAME_SELECT_LIST[5] &&
                            <React.Fragment>
                              <Section spacingBottom='20'>
                                <Section spacingBottom='10'>
                                  <Text regular16 color={VARIABLES.COLORS.PRIMARY_2}>{getLanguage('warrantyManage.form.condition.application.decking', '')}</Text>
                                </Section>
                                <Section width='55' borderBottomWidth='3' borderBottomStyle='solid' borderBottomColor={VARIABLES.COLORS.PRIMARY_2} />
                              </Section>
                              <Section spacingBottom='30'>
                                <ShapeContainer
                                  fluid
                                  paddingHorizontal='30'
                                  paddingVertical='35'
                                  bgColor={VARIABLES.COLORS.WHITE}
                                  borderWidth='1'
                                  borderStyle='solid'
                                  borderColor={VARIABLES.COLORS.GRAY_8}
                                  borderRadius='5'
                                >
                                  {this.warrantyManageCondition.renderConditionList(et, it, i)}
                                </ShapeContainer>
                              </Section>
                            </React.Fragment>
                          }
                          {/* Application condition : Air Ducting */}
                          {et.applicationForSelect === DATA.WARRANTY_APP_DETAIL_NAME_SELECT_LIST[6] &&
                            <React.Fragment>
                              <Section spacingBottom='20'>
                                <Section spacingBottom='10'>
                                  <Text regular16 color={VARIABLES.COLORS.PRIMARY_2}>{getLanguage('warrantyManage.form.condition.application.airDucting', '')}</Text>
                                </Section>
                                <Section width='55' borderBottomWidth='3' borderBottomStyle='solid' borderBottomColor={VARIABLES.COLORS.PRIMARY_2} />
                              </Section>
                              <Section spacingBottom='30'>
                                <ShapeContainer
                                  fluid
                                  paddingHorizontal='30'
                                  paddingVertical='35'
                                  bgColor={VARIABLES.COLORS.WHITE}
                                  borderWidth='1'
                                  borderStyle='solid'
                                  borderColor={VARIABLES.COLORS.GRAY_8}
                                  borderRadius='5'
                                >
                                  {this.warrantyManageCondition.renderConditionList(et, it, i)}
                                </ShapeContainer>
                              </Section>
                            </React.Fragment>
                          }
                        </div>
                      )
                    }
                    return undefined
                  })}
                  {/* Criteria and Surrounding Environment */}
                  {/* <React.Fragment>
                            <Section spacingBottom='20'>
                              <Section spacingBottom='10'>
                                <Text regular16 color={VARIABLES.COLORS.PRIMARY_2}>{getLanguage('warrantyManage.form.condition.criteriaAndSurroundingEnvironment', '')}</Text>
                              </Section>
                              <Section width='55' borderBottomWidth='3' borderBottomStyle='solid' borderBottomColor={VARIABLES.COLORS.PRIMARY_2} />
                            </Section>
                            <Section spacingBottom='30'>
                              <Grid
                                gutter='60'
                                gutterVertical='60'
                              >
                                <Grid.Column grid='6'>
                                  <ShapeContainer
                                    fluid
                                    calcHeight='100%'
                                    paddingHorizontal='30'
                                    paddingVertical='35'
                                    bgColor={VARIABLES.COLORS.WHITE}
                                    borderWidth='1'
                                    borderStyle='solid'
                                    borderColor={VARIABLES.COLORS.GRAY_8}
                                    borderRadius='5'
                                  >
                                    <Section spacingBottom='15'>
                                      <Text
                                        regular16
                                        color={VARIABLES.COLORS.GRAY_19}
                                      >
                                        {getLanguage('warrantyManage.form.condition.condition1', '')}
                                      </Text>
                                    </Section>
                                    <Section
                                      paddingBottom='20'
                                      spacingBottom='15'
                                      borderBottomWidth='1'
                                      borderBottomStyle='solid'
                                      borderBottomColor={VARIABLES.COLORS.GRAY_8}
                                    >
                                      <Grid gutter='25'>
                                        <Grid.Column>
                                          <Field name='wrtCriteria1'
                                            checked={condition.wrtCriteria1 === true}
                                            ui='radio'
                                            radioLabel={getLanguage('warrantyManage.form.condition.conditionYes', '')}
                                            onChange={(ev) => {
                                              onChangeDataInput(ev, true);
                                            }}
                                          />
                                        </Grid.Column>
                                        <Grid.Column>
                                          <Field name='wrtCriteria1'
                                            checked={condition.wrtCriteria1 === false}
                                            ui='radio'
                                            radioLabel={getLanguage('warrantyManage.form.condition.conditionNo', '')}
                                            onChange={(ev) => {
                                              onChangeDataInput(ev, false);
                                            }}
                                          />
                                        </Grid.Column>
                                      </Grid>
                                    </Section>
                                    <Section spacingBottom='15'>
                                      <Text
                                        regular16
                                        color={VARIABLES.COLORS.GRAY_19}
                                      >
                                        {getLanguage('warrantyManage.form.condition.condition2', '')}
                                      </Text>
                                    </Section>
                                    <Section
                                      paddingBottom='20'
                                      spacingBottom='15'
                                      borderBottomWidth='1'
                                      borderBottomStyle='solid'
                                      borderBottomColor={VARIABLES.COLORS.GRAY_8}
                                    >
                                      <Grid gutter='25'>
                                        <Grid.Column>
                                          <Field name='wrtCriteria2'
                                            checked={condition.wrtCriteria2 === true}
                                            ui='radio'
                                            radioLabel={getLanguage('warrantyManage.form.condition.conditionYes', '')}
                                            onChange={(ev) => {
                                              onChangeDataInput(ev, true);
                                            }}
                                          />
                                        </Grid.Column>
                                        <Grid.Column>
                                          <Field name='wrtCriteria2'
                                            checked={condition.wrtCriteria2 === false}
                                            ui='radio'
                                            radioLabel={getLanguage('warrantyManage.form.condition.conditionNo', '')}
                                            onChange={(ev) => {
                                              onChangeDataInput(ev, false);
                                            }}
                                          />
                                        </Grid.Column>
                                      </Grid>
                                    </Section>
                                    <Section spacingBottom='15'>
                                      <Text
                                        regular16
                                        color={VARIABLES.COLORS.GRAY_19}
                                      >
                                        {getLanguage('warrantyManage.form.condition.condition3', '')}
                                      </Text>
                                    </Section>
                                    <Section
                                      paddingBottom='20'
                                      spacingBottom='15'
                                      borderBottomWidth='1'
                                      borderBottomStyle='solid'
                                      borderBottomColor={VARIABLES.COLORS.GRAY_8}
                                    >
                                      <Grid gutter='25'>
                                        <Grid.Column>
                                          <Field name='wrtCriteria3'
                                            checked={condition.wrtCriteria3 === true}
                                            ui='radio'
                                            radioLabel={getLanguage('warrantyManage.form.condition.conditionYes', '')}
                                            onChange={(ev) => {
                                              onChangeDataInput(ev, true);
                                            }}
                                          />
                                        </Grid.Column>
                                        <Grid.Column>
                                          <Field name='wrtCriteria3'
                                            checked={condition.wrtCriteria3 === false}
                                            ui='radio'
                                            radioLabel={getLanguage('warrantyManage.form.condition.conditionNo', '')}
                                            onChange={(ev) => {
                                              onChangeDataInput(ev, false);
                                            }}
                                          />
                                        </Grid.Column>
                                      </Grid>
                                    </Section>
                                    <Section spacingBottom='15'>
                                      <Text
                                        regular16
                                        color={VARIABLES.COLORS.GRAY_19}
                                      >
                                        {getLanguage('warrantyManage.form.condition.condition4', '')}
                                      </Text>
                                    </Section>
                                    <Section
                                      paddingBottom='20'
                                      spacingBottom='15'
                                      borderBottomWidth='1'
                                      borderBottomStyle='solid'
                                      borderBottomColor={VARIABLES.COLORS.GRAY_8}
                                    >
                                      <Grid gutter='25'>
                                        <Grid.Column>
                                          <Field name='wrtCriteria4'
                                            checked={condition.wrtCriteria4 === true}
                                            ui='radio'
                                            radioLabel={getLanguage('warrantyManage.form.condition.conditionYes', '')}
                                            onChange={(ev) => {
                                              onChangeDataInput(ev, true);
                                            }}
                                          />
                                        </Grid.Column>
                                        <Grid.Column>
                                          <Field name='wrtCriteria4'
                                            checked={condition.wrtCriteria4 === false}
                                            ui='radio'
                                            radioLabel={getLanguage('warrantyManage.form.condition.conditionNo', '')}
                                            onChange={(ev) => {
                                              onChangeDataInput(ev, false);
                                            }}
                                          />
                                        </Grid.Column>
                                      </Grid>
                                    </Section>
                                    <Section spacingBottom='15'>
                                      <Text
                                        regular16
                                        color={VARIABLES.COLORS.GRAY_19}
                                      >
                                        {getLanguage('warrantyManage.form.condition.condition5', '')}
                                      </Text>
                                    </Section>
                                    <Section>
                                      <Grid
                                        gutter='25'
                                        gutterVertical='15'
                                      >
                                        <Grid.Column>
                                          <Field name='wrtCriteria5'
                                            checked={condition.wrtCriteria5 === '1'}
                                            ui='radio'
                                            radioLabel={getLanguage('warrantyManage.form.condition.condition5_1', '')}
                                            value='1'
                                            onChange={(ev) => {
                                              onChangeDataInput(ev, '1');
                                            }}
                                          />
                                        </Grid.Column>
                                        <Grid.Column>
                                          <Field name='wrtCriteria5'
                                            checked={condition.wrtCriteria5 === '2'}
                                            ui='radio'
                                            radioLabel={getLanguage('warrantyManage.form.condition.condition5_2', '')}
                                            value='2'
                                            onChange={(ev) => {
                                              onChangeDataInput(ev, '2');
                                            }}
                                          />
                                        </Grid.Column>
                                        <Grid.Column>
                                          <Field name='wrtCriteria5'
                                            checked={condition.wrtCriteria5 === '3'}
                                            ui='radio'
                                            radioLabel={getLanguage('warrantyManage.form.condition.condition5_3', '')}
                                            value='3'
                                            onChange={(ev) => {
                                              onChangeDataInput(ev, '3');
                                            }}
                                          />
                                        </Grid.Column>
                                        <Grid.Column>
                                          <Field name='wrtCriteria5'
                                            checked={condition.wrtCriteria5 === '4'}
                                            ui='radio'
                                            radioLabel={getLanguage('warrantyManage.form.condition.condition5_4', '')}
                                            value='4'
                                            onChange={(ev) => {
                                              onChangeDataInput(ev, '4');
                                            }}
                                          />
                                        </Grid.Column>
                                      </Grid>
                                    </Section>
                                  </ShapeContainer>
                                </Grid.Column>
                                <Grid.Column grid='6'>
                                  <ShapeContainer
                                    fluid
                                    calcHeight='100%'
                                    paddingHorizontal='30'
                                    paddingVertical='35'
                                    bgColor={VARIABLES.COLORS.WHITE}
                                    borderWidth='1'
                                    borderStyle='solid'
                                    borderColor={VARIABLES.COLORS.GRAY_8}
                                    borderRadius='5'
                                  >
                                    <Section spacingBottom='15'>
                                      <Text
                                        regular16
                                        color={VARIABLES.COLORS.GRAY_19}
                                      >
                                        {getLanguage('warrantyManage.form.condition.condition6', '')}
                                      </Text>
                                    </Section>
                                    <Section
                                      paddingBottom='20'
                                      spacingBottom='15'
                                      borderBottomWidth='1'
                                      borderBottomStyle='solid'
                                      borderBottomColor={VARIABLES.COLORS.GRAY_8}
                                    >
                                      <Grid gutter='25'>
                                        <Grid.Column>
                                          <Field name='wrtSurEnv1'
                                            checked={condition.wrtSurEnv1 === DATA.WARRANTY_CONDITION.RURAL}
                                            ui='radio'
                                            radioLabel={getLanguage('warrantyManage.form.condition.condition6_1', '')}
                                            onChange={(ev) => {
                                              onChangeDataInput(ev, DATA.WARRANTY_CONDITION.RURAL);
                                            }}
                                          />
                                        </Grid.Column>
                                        <Grid.Column>
                                          <Field name='wrtSurEnv1'
                                            checked={condition.wrtSurEnv1 === DATA.WARRANTY_CONDITION.URBAN}
                                            ui='radio'
                                            radioLabel={getLanguage('warrantyManage.form.condition.condition6_2', '')}
                                            onChange={(ev) => {
                                              onChangeDataInput(ev, DATA.WARRANTY_CONDITION.URBAN);
                                            }}
                                          />
                                        </Grid.Column>
                                      </Grid>
                                    </Section>
                                    <Section spacingBottom='15'>
                                      <Text
                                        regular16
                                        color={VARIABLES.COLORS.GRAY_19}
                                      >
                                        {getLanguage('warrantyManage.form.condition.condition7', '')}
                                      </Text>
                                    </Section>
                                    <Section
                                      paddingBottom='20'
                                      spacingBottom='15'
                                      borderBottomWidth='1'
                                      borderBottomStyle='solid'
                                      borderBottomColor={VARIABLES.COLORS.GRAY_8}
                                    >
                                      <Grid gutter='25'>
                                        <Grid.Column>
                                          <Field name='wrtSurEnv2'
                                            checked={condition.wrtSurEnv2 === true}
                                            ui='radio'
                                            radioLabel={getLanguage('warrantyManage.form.condition.conditionYes', '')}
                                            onChange={(ev) => {
                                              onChangeDataInput(ev, true);
                                            }}
                                          />
                                        </Grid.Column>
                                        <Grid.Column>
                                          <Field name='wrtSurEnv2'
                                            checked={condition.wrtSurEnv2 === false}
                                            ui='radio'
                                            radioLabel={getLanguage('warrantyManage.form.condition.conditionNo', '')}
                                            onChange={(ev) => {
                                              onChangeDataInput(ev, false);
                                            }}
                                          />
                                        </Grid.Column>
                                      </Grid>
                                      {
                                        !condition.wrtSurEnv2 &&
                                        <Section paddingTop='20'>
                                          <ShapeContainer
                                            fluid
                                            padding='20'
                                            bgColor={VARIABLES.COLORS.GRAY_1}
                                            borderRadius='3'
                                          >
                                            <Section spacingBottom='15'>
                                              <Text
                                                regular16
                                                color={VARIABLES.COLORS.GRAY_19}
                                              >
                                                {getLanguage('warrantyManage.form.condition.condition7cTitle', '')}
                                              </Text>
                                            </Section>
                                            <Grid
                                              gutter='25'
                                              gutterVertical='15'
                                            >
                                              <Grid.Column>
                                                <Field name='wrtSurEnv22'
                                                  checked={condition.wrtSurEnv22 === '1'}
                                                  ui='radio'
                                                  radioLabel={getLanguage('warrantyManage.form.condition.condition7_1', '')}
                                                  onChange={(ev) => {
                                                    onChangeDataInput(ev, '1');
                                                  }}
                                                />
                                              </Grid.Column>
                                              <Grid.Column>
                                                <Field name='wrtSurEnv22'
                                                  checked={condition.wrtSurEnv22 === '2'}
                                                  ui='radio'
                                                  radioLabel={getLanguage('warrantyManage.form.condition.condition7_2', '')}
                                                  onChange={(ev) => {
                                                    onChangeDataInput(ev, '2');
                                                  }}
                                                />
                                              </Grid.Column>
                                              <Grid.Column>
                                                <Field name='wrtSurEnv22'
                                                  checked={condition.wrtSurEnv22 === '3'}
                                                  ui='radio'
                                                  radioLabel={getLanguage('warrantyManage.form.condition.condition7_3', '')}
                                                  onChange={(ev) => {
                                                    onChangeDataInput(ev, '3');
                                                  }}
                                                />
                                              </Grid.Column>
                                            </Grid>
                                          </ShapeContainer>
                                        </Section>
                                      }
                                    </Section>
                                    <Section spacingBottom='15'>
                                      <Text
                                        regular16
                                        color={VARIABLES.COLORS.GRAY_19}
                                      >
                                        {getLanguage('warrantyManage.form.condition.condition8', '')}
                                      </Text>
                                    </Section>
                                    <Section
                                      paddingBottom='20'
                                      spacingBottom='15'
                                      borderBottomWidth='1'
                                      borderBottomStyle='solid'
                                      borderBottomColor={VARIABLES.COLORS.GRAY_8}
                                    >
                                      <Grid gutter='25'>
                                        <Grid.Column>
                                          <Field name='wrtSurEnv3'
                                            checked={condition.wrtSurEnv3 === true}
                                            ui='radio'
                                            radioLabel={getLanguage('warrantyManage.form.condition.conditionYes', '')}
                                            onChange={(ev) => {
                                              onChangeDataInput(ev, true);
                                            }}
                                          />
                                        </Grid.Column>
                                        <Grid.Column>
                                          <Field name='wrtSurEnv3'
                                            checked={condition.wrtSurEnv3 === false}
                                            ui='radio'
                                            radioLabel={getLanguage('warrantyManage.form.condition.conditionNo', '')}
                                            onChange={(ev) => {
                                              onChangeDataInput(ev, false);
                                            }}
                                          />
                                        </Grid.Column>
                                      </Grid>
                                      {
                                        !condition.wrtSurEnv3 &&
                                        <Section paddingTop='20'>
                                          <ShapeContainer
                                            fluid
                                            padding='20'
                                            bgColor={VARIABLES.COLORS.GRAY_1}
                                            borderRadius='3'
                                          >
                                            <Section spacingBottom='15'>
                                              <Text
                                                regular16
                                                color={VARIABLES.COLORS.GRAY_19}
                                              >
                                                {getLanguage('warrantyManage.form.condition.condition8cTitle', '')}
                                              </Text>
                                            </Section>
                                            <Grid
                                              gutter='25'
                                              gutterVertical='15'
                                            >
                                              <Grid.Column>
                                                <Field name='wrtSurEnv32'
                                                  checked={condition.wrtSurEnv32 === '1'}
                                                  ui='radio'
                                                  radioLabel={getLanguage('warrantyManage.form.condition.condition8_1', '')}
                                                  onChange={(ev) => {
                                                    onChangeDataInput(ev, '1');
                                                  }}
                                                />
                                              </Grid.Column>
                                              <Grid.Column>
                                                <Field name='wrtSurEnv32'
                                                  checked={condition.wrtSurEnv32 === '2'}
                                                  ui='radio'
                                                  radioLabel={getLanguage('warrantyManage.form.condition.condition8_2', '')}
                                                  onChange={(ev) => {
                                                    onChangeDataInput(ev, '2');
                                                  }}
                                                />
                                              </Grid.Column>
                                              <Grid.Column>
                                                <Field name='wrtSurEnv32'
                                                  checked={condition.wrtSurEnv32 === '3'}
                                                  ui='radio'
                                                  radioLabel={getLanguage('warrantyManage.form.condition.condition8_3', '')}
                                                  onChange={(ev) => {
                                                    onChangeDataInput(ev, '3');
                                                  }}
                                                />
                                              </Grid.Column>
                                            </Grid>
                                          </ShapeContainer>
                                        </Section>
                                      }
                                    </Section>
                                    <Section spacingBottom='15'>
                                      <Text
                                        regular16
                                        color={VARIABLES.COLORS.GRAY_19}
                                      >
                                        {getLanguage('warrantyManage.form.condition.condition9', '')}
                                      </Text>
                                    </Section>
                                    <Section
                                      paddingBottom='20'
                                      spacingBottom='15'
                                      borderBottomWidth='1'
                                      borderBottomStyle='solid'
                                      borderBottomColor={VARIABLES.COLORS.GRAY_8}
                                    >
                                      <Grid gutter='25'>
                                        <Grid.Column>
                                          <Field name='wrtSurEnv4'
                                            checked={condition.wrtSurEnv4 === true}
                                            ui='radio'
                                            radioLabel={getLanguage('warrantyManage.form.condition.conditionYes', '')}
                                            onChange={(ev) => {
                                              onChangeDataInput(ev, true);
                                            }}
                                          />
                                        </Grid.Column>
                                        <Grid.Column>
                                          <Field name='wrtSurEnv4'
                                            checked={condition.wrtSurEnv4 === false}
                                            ui='radio'
                                            radioLabel={getLanguage('warrantyManage.form.condition.conditionNo', '')}
                                            onChange={(ev) => {
                                              onChangeDataInput(ev, false);
                                            }}
                                          />
                                        </Grid.Column>
                                      </Grid>
                                    </Section>
                                    <Section spacingBottom='15'>
                                      <Text
                                        regular16
                                        color={VARIABLES.COLORS.GRAY_19}
                                      >
                                        {getLanguage('warrantyManage.form.condition.condition10', '')}
                                      </Text>
                                    </Section>
                                    <Section>
                                      <Grid gutter='25'>
                                        <Grid.Column>
                                          <Field name='wrtSurEnv5'
                                            checked={condition.wrtSurEnv5 === true}
                                            ui='radio'
                                            radioLabel={getLanguage('warrantyManage.form.condition.conditionYes', '')}
                                            onChange={(ev) => {
                                              onChangeDataInput(ev, true);
                                            }}
                                          />
                                        </Grid.Column>
                                        <Grid.Column>
                                          <Field name='wrtSurEnv5'
                                            checked={condition.wrtSurEnv5 === false}
                                            ui='radio'
                                            radioLabel={getLanguage('warrantyManage.form.condition.conditionNo', '')}
                                            onChange={(ev) => {
                                              onChangeDataInput(ev, false);
                                            }}
                                          />
                                        </Grid.Column>
                                      </Grid>
                                    </Section>
                                  </ShapeContainer>
                                </Grid.Column>
                              </Grid>
                            </Section>
                          </React.Fragment>
                          */}
                </ShapeContainer>
              </AccordionItemBody>
            </AccordionItem>
          )
        })}
      </React.Fragment>
    )
  }
}