import React from 'react'
import ClassNames from 'classnames'
// import PropTypes from 'prop-types'
import {
  ImageBase
} from './../../base'
import {
  Grid,
  Text
} from './../../../components'
import {
  default as VARIABLES
} from './../../../themes/styles/bases/variables'
// import {
//   default as TYPOGRAPHYS
// } from './../../../themes/styles/bases/typographys'
// import {
//   default as UTILITIES
// } from './../../../themes/styles/helpers/utilities'
import {
  LOGOS,
  ICONS,
  CONTENTS,
} from './../../../themes'

/**
 * Image description:
 * - Image
 */

export class Image extends React.PureComponent {
  render() {
    const {
      className,
      ui,
      phoneId,
      phoneNumber
    } = this.props

    // props for css classes
    const classes = ClassNames(
      className,
      // { 'is-modifier-name': modifierName }
    )

    return (
      <React.Fragment>
        {(() => {
          switch (ui) {
            case 'logoLogin':
              return (
                <ImageBase
                  {...this.props}
                  className={classes}
                  widthRatio='309'
                  widthRatioUnit='px'
                  heightRatio='45'
                  heightRatioUnit='px'
                  src={LOGOS['logo.png']}
                />
              )
            case 'logoCms':
              return (
                <ImageBase
                  {...this.props}
                  className={classes}
                  widthRatio='160'
                  widthRatioUnit='px'
                  heightRatio='23'
                  heightRatioUnit='px'
                  src={LOGOS['logo.png']}
                />
              )
            case 'user':
              return (
                <ImageBase
                  {...this.props}
                  className={classes}
                  widthRatio='42'
                  widthRatioUnit='px'
                  heightRatio='42'
                  heightRatioUnit='px'
                  borderRadius='21'
                />
              )
            case 'userUpload':
              return (
                <ImageBase
                  {...this.props}
                  className={classes}
                  widthRatio='130'
                  widthRatioUnit='px'
                  heightRatio='130'
                  heightRatioUnit='px'
                  borderRadius='65'
                />
              )
            case 'iconArrowDropdownUser':
              return (
                <ImageBase
                  {...this.props}
                  className={classes}
                  widthRatio='17'
                  widthRatioUnit='px'
                  heightRatio='10'
                  heightRatioUnit='px'
                  src={ICONS['ic-arrow-dropdown-user.svg']}
                />
              )
            case 'iconNotification':
              return (
                <ImageBase
                  {...this.props}
                  className={classes}
                  widthRatio='16'
                  widthRatioUnit='px'
                  heightRatio='21'
                  heightRatioUnit='px'
                  src={ICONS['ic-notification.svg']}
                />
              )
            case 'iconNotificationActive':
              return (
                <ImageBase
                  {...this.props}
                  className={classes}
                  widthRatio='18'
                  widthRatioUnit='px'
                  heightRatio='21'
                  heightRatioUnit='px'
                  src={ICONS['ic-notification-active.svg']}
                />
              )
            case 'iconLogout':
              return (
                <ImageBase
                  {...this.props}
                  className={classes}
                  widthRatio='21'
                  widthRatioUnit='px'
                  heightRatio='17'
                  heightRatioUnit='px'
                  src={ICONS['ic-logout.svg']}
                />
              )
            case 'iconHome':
              return (
                <ImageBase
                  {...this.props}
                  className={classes}
                  widthRatio='18' // 16
                  widthRatioUnit='px'
                  heightRatio='16' // 14
                  heightRatioUnit='px'
                  src={ICONS['ic-home.svg']}
                  top='1' // 2
                />
              )
            case 'iconYearWarranty':
              return (
                <ImageBase
                  {...this.props}
                  className={classes}
                  widthRatio='19'
                  widthRatioUnit='px'
                  heightRatio='20'
                  heightRatioUnit='px'
                  src={ICONS['ic-isometric-square-white.svg']}
                  top='1'
                />
              )
            case 'iconSalesTransaction':
              return (
                <ImageBase
                  {...this.props}
                  className={classes}
                  widthRatio='18' // 16
                  widthRatioUnit='px'
                  heightRatio='18' // 16
                  heightRatioUnit='px'
                  src={ICONS['ic-sales-transaction.svg']}
                  top='0' // 1
                  left='2' // 2
                />
              )
            case 'iconUploadTransaction':
              return (
                <ImageBase
                  {...this.props}
                  className={classes}
                  widthRatio='15' // 13
                  widthRatioUnit='px'
                  heightRatio='18' // 16
                  heightRatioUnit='px'
                  src={ICONS['ic-upload.svg']}
                  top='0' // 1
                />
              )
            case 'iconWarranty':
              return (
                <ImageBase
                  {...this.props}
                  className={classes}
                  widthRatio='16' // 14
                  widthRatioUnit='px'
                  heightRatio='20' // 17
                  heightRatioUnit='px'
                  src={ICONS['ic-warranty.svg']}
                  top='-1' // 0
                />
              )
            case 'iconInventory':
              return (
                <ImageBase
                  {...this.props}
                  className={classes}
                  widthRatio='22' // 18
                  widthRatioUnit='px'
                  heightRatio='21' // 17
                  heightRatioUnit='px'
                  src={ICONS['ic-inventory.svg']}
                  top='-1' // 0
                />
              )
            case 'iconCustomers':
              return (
                <ImageBase
                  {...this.props}
                  className={classes}
                  widthRatio='23' // 17
                  widthRatioUnit='px'
                  heightRatio='16' // 12
                  heightRatioUnit='px'
                  src={ICONS['ic-customers.svg']}
                  top='1' // 3
                  left='-2'
                />
              )
            case 'iconRewards':
              return (
                <ImageBase
                  {...this.props}
                  className={classes}
                  widthRatio='18' // 16
                  widthRatioUnit='px'
                  heightRatio='16' // 14
                  heightRatioUnit='px'
                  src={ICONS['ic-rewards.svg']}
                  top='1' // 2
                />
              )
            case 'iconReport':
              return (
                <ImageBase
                  {...this.props}
                  className={classes}
                  widthRatio='19' // 16
                  widthRatioUnit='px'
                  heightRatio='18' // 15
                  heightRatioUnit='px'
                  src={ICONS['ic-report.svg']}
                  top='-1' // 1
                />
              )
            case 'iconSettings':
              return (
                <ImageBase
                  {...this.props}
                  className={classes}
                  widthRatio='18' // 15
                  widthRatioUnit='px'
                  heightRatio='18' // 15
                  heightRatioUnit='px'
                  src={ICONS['ic-settings.svg']}
                  top='2' // 2
                />
              )
            case 'iconArrowSideMenu':
              return (
                <ImageBase
                  {...this.props}
                  className={classes}
                  widthRatio='7'
                  widthRatioUnit='px'
                  heightRatio='12'
                  heightRatioUnit='px'
                  src={ICONS['ic-arrow-sidemenu.svg']}
                />
              )
            case 'iconSearch':
              return (
                <ImageBase
                  {...this.props}
                  className={classes}
                  widthRatio='14'
                  widthRatioUnit='px'
                  heightRatio='14'
                  heightRatioUnit='px'
                  src={ICONS['ic-search.svg']}
                />
              )
            case 'iconArrowAscending':
              return (
                <ImageBase
                  {...this.props}
                  className={classes}
                  widthRatio='9'
                  widthRatioUnit='px'
                  heightRatio='5'
                  heightRatioUnit='px'
                  src={ICONS['ic-arrow-sort-ascending.svg']}
                  top='-1'
                />
              )
            case 'iconArrowDescending':
              return (
                <ImageBase
                  {...this.props}
                  className={classes}
                  widthRatio='9'
                  widthRatioUnit='px'
                  heightRatio='5'
                  heightRatioUnit='px'
                  src={ICONS['ic-arrow-sort-descending.svg']}
                  top='-1'
                />
              )
            case 'iconFlagField':
              return (
                <ImageBase
                  {...this.props}
                  className={classes}
                  widthRatio='18'
                  widthRatioUnit='px'
                  heightRatio='12'
                  heightRatioUnit='px'
                  top='5'
                />
              )
            case 'iconFlag':
              return (
                <ImageBase
                  {...this.props}
                  className={classes}
                  widthRatio='18'
                  widthRatioUnit='px'
                  heightRatio='12'
                  heightRatioUnit='px'
                />
              )
            case 'iconContact':
              return (
                <ImageBase
                  {...this.props}
                  className={classes}
                  widthRatio='33'
                  widthRatioUnit='px'
                  heightRatio='33'
                  heightRatioUnit='px'
                  top='-5'
                  src={ICONS['ic-contact.svg']}
                />
              )
            case 'iconWebsite':
              return (
                <ImageBase
                  {...this.props}
                  className={classes}
                  widthRatio='32'
                  widthRatioUnit='px'
                  heightRatio='32'
                  heightRatioUnit='px'
                  top='-5'
                  src={ICONS['ic-website.svg']}
                />
              )
            case 'iconArrowGoto':
              return (
                <ImageBase
                  {...this.props}
                  className={classes}
                  widthRatio='8'
                  widthRatioUnit='px'
                  heightRatio='14'
                  heightRatioUnit='px'
                  src={ICONS['ic-arrow-goto.svg']}
                />
              )
            case 'iconInfoError':
              return (
                <ImageBase
                  {...this.props}
                  className={classes}
                  widthRatio='17'
                  widthRatioUnit='px'
                  heightRatio='17'
                  heightRatioUnit='px'
                  src={ICONS['ic-info-error.svg']}
                />
              )
            case 'iconInfo':
              return (
                <ImageBase
                  {...this.props}
                  className={classes}
                  widthRatio='17'
                  widthRatioUnit='px'
                  heightRatio='17'
                  heightRatioUnit='px'
                  src={ICONS['ic-notification-info.svg']}
                />
              )
            case 'iconInfoLabel':
              return (
                <ImageBase
                  {...this.props}
                  className={classes}
                  widthRatio='17'
                  widthRatioUnit='px'
                  heightRatio='17'
                  heightRatioUnit='px'
                  src={ICONS['ic-notification-info.svg']}
                  top='-2'
                />
              )
            case 'iconBlankCustomer':
              return (
                <ImageBase
                  {...this.props}
                  className={classes}
                  widthRatio='66'
                  widthRatioUnit='px'
                  heightRatio='66'
                  heightRatioUnit='px'
                  src={ICONS['ic-blank-customer.svg']}
                />
              )
            case 'iconBlankProduct':
              return (
                <ImageBase
                  {...this.props}
                  className={classes}
                  widthRatio='74'
                  widthRatioUnit='px'
                  heightRatio='68'
                  heightRatioUnit='px'
                  src={ICONS['ic-blank-product.svg']}
                />
              )
            case 'iconSymbolTrained':
              return (
                <ImageBase
                  {...this.props}
                  className={classes}
                  widthRatio='84'
                  widthRatioUnit='px'
                  heightRatio='84'
                  heightRatioUnit='px'
                  src={ICONS['ic-symbol-trained.svg']}
                />
              )
            case 'flagWithPhoneIdAndNumber':
              return (
                <Grid gutter='10'>
                  <Grid.Column>
                    <Grid gutter='5'>
                      <Grid.Column>
                        <ImageBase
                          {...this.props}
                          className={classes}
                          widthRatio='18'
                          widthRatioUnit='px'
                          heightRatio='12'
                          heightRatioUnit='px'
                        />
                      </Grid.Column>
                      <Grid.Column>
                        <Text
                          regular14
                          color={VARIABLES.COLORS.BLACK}
                        >
                          +{phoneId}
                        </Text>
                      </Grid.Column>
                    </Grid>
                  </Grid.Column>
                  <Grid.Column>
                    <Text
                      regular14
                      color={VARIABLES.COLORS.BLACK}
                    >
                      {phoneNumber}
                    </Text>
                  </Grid.Column>
                </Grid>
              )
            case 'iconSelectedActive':
              return (
                <ImageBase
                  {...this.props}
                  className={classes}
                  widthRatio='16'
                  widthRatioUnit='px'
                  heightRatio='16'
                  heightRatioUnit='px'
                  src={ICONS['ic-selected-active.svg']}
                  top='-1'
                />
              )
            case 'iconSelectedInactive':
              return (
                <ImageBase
                  {...this.props}
                  className={classes}
                  widthRatio='16'
                  widthRatioUnit='px'
                  heightRatio='16'
                  heightRatioUnit='px'
                  src={ICONS['ic-selected-inactive.svg']}
                  top='-1'
                />
              )
            case 'iconUnitMinus':
              return (
                <ImageBase
                  {...this.props}
                  className={classes}
                  widthRatio='14'
                  widthRatioUnit='px'
                  heightRatio='14'
                  heightRatioUnit='px'
                  src={ICONS['ic-unit-minus.svg']}
                  top='-3'
                />
              )
            case 'iconUnitPlus':
              return (
                <ImageBase
                  {...this.props}
                  className={classes}
                  widthRatio='14'
                  widthRatioUnit='px'
                  heightRatio='14'
                  heightRatioUnit='px'
                  src={ICONS['ic-unit-plus.svg']}
                  top='-3'
                />
              )
            case 'iconOrderInquiry':
              return (
                <ImageBase
                  {...this.props}
                  className={classes}
                  widthRatio='26' // 20
                  widthRatioUnit='px'
                  heightRatio='22' // 18
                  heightRatioUnit='px'
                  src={ICONS['ic-order-inquiry.svg']}
                  top='-2'
                  left='3'
                />
              )
            case 'iconNotificationOrderInquiry':
              return (
                <ImageBase
                  {...this.props}
                  className={classes}
                  widthRatio='42'
                  widthRatioUnit='px'
                  heightRatio='42'
                  heightRatioUnit='px'
                  src={ICONS['ic-notification-order-inquiry.svg']}
                />
              )
            case 'iconArrowNotification':
              return (
                <ImageBase
                  {...this.props}
                  className={classes}
                  widthRatio='8'
                  widthRatioUnit='px'
                  heightRatio='14'
                  heightRatioUnit='px'
                  src={ICONS['ic-arrow-notification.svg']}
                />
              )
            case 'iconArrowViewMoreOpen':
              return (
                <ImageBase
                  {...this.props}
                  className={classes}
                  widthRatio='8'
                  widthRatioUnit='px'
                  heightRatio='5'
                  heightRatioUnit='px'
                  src={ICONS['ic-arrow-viewmore-open.svg']}
                />
              )
            case 'iconArrowViewMoreClose':
              return (
                <ImageBase
                  {...this.props}
                  className={classes}
                  widthRatio='8'
                  widthRatioUnit='px'
                  heightRatio='5'
                  heightRatioUnit='px'
                  src={ICONS['ic-arrow-viewmore-close.svg']}
                />
              )
            case 'iconSelectedItem':
              return (
                <ImageBase
                  {...this.props}
                  className={classes}
                  widthRatio='10'
                  widthRatioUnit='px'
                  heightRatio='8'
                  heightRatioUnit='px'
                  src={ICONS['ic-checkbox.svg']}
                />
              )
            case 'iconActionSalesHistory':
              return (
                <ImageBase
                  {...this.props}
                  className={classes}
                  widthRatio='16'
                  widthRatioUnit='px'
                  heightRatio='11'
                  heightRatioUnit='px'
                  src={ICONS['ic-action-sales-history.svg']}
                />
              )
            case 'iconActionPointsDetail':
              return (
                <ImageBase
                  {...this.props}
                  className={classes}
                  widthRatio='12'
                  widthRatioUnit='px'
                  heightRatio='14'
                  heightRatioUnit='px'
                  src={ICONS['ic-action-points-detail.svg']}
                />
              )
            case 'iconActionRewardRedemptionHistory':
              return (
                <ImageBase
                  {...this.props}
                  className={classes}
                  widthRatio='14'
                  widthRatioUnit='px'
                  heightRatio='12'
                  heightRatioUnit='px'
                  src={ICONS['ic-action-reward-redemption-history.svg']}
                />
              )
            case 'iconActionEdit':
              return (
                <ImageBase
                  {...this.props}
                  className={classes}
                  widthRatio='11'
                  widthRatioUnit='px'
                  heightRatio='11'
                  heightRatioUnit='px'
                  src={ICONS['ic-action-edit.svg']}
                />
              )
            case 'iconChecked':
              return (
                <ImageBase
                  {...this.props}
                  className={classes}
                  widthRatio='10'
                  widthRatioUnit='px'
                  heightRatio='8'
                  heightRatioUnit='px'
                  src={ICONS['ic-checkbox.svg']}
                />
              )
            case 'iconBarChart':
              return (
                <ImageBase
                  {...this.props}
                  className={classes}
                  widthRatio='60'
                  widthRatioUnit='px'
                  heightRatio='21'
                  heightRatioUnit='px'
                />
              )
            case 'iconArrowStatistic':
              return (
                <ImageBase
                  {...this.props}
                  className={classes}
                  widthRatio='8'
                  widthRatioUnit='px'
                  heightRatio='10'
                  heightRatioUnit='px'
                />
              )
            case 'iconShare':
              return (
                <ImageBase
                  {...this.props}
                  className={classes}
                  widthRatio='17'
                  widthRatioUnit='px'
                  heightRatio='17'
                  heightRatioUnit='px'
                  src={ICONS['ic-share-blue-circle.svg']}
                />
              )
            case 'logoBlueScope':
              return (
                <ImageBase
                  {...this.props}
                  className={classes}
                  widthRatio='82' // 45
                  widthRatioUnit='px'
                  heightRatio='50' // 25
                  heightRatioUnit='px'
                  src={LOGOS['logo-blue.png']}
                />
              )
            case 'logoBlueScopeZacs':
              return (
                <ImageBase
                  {...this.props}
                  className={classes}
                  widthRatio='72' // 36
                  widthRatioUnit='px'
                  heightRatio='50' // 25
                  heightRatioUnit='px'
                  src={LOGOS['logo-bluescope-zacs.jpg']}
                />
              )
            case 'logoColorbond':
              return (
                <ImageBase
                  {...this.props}
                  className={classes}
                  widthRatio='140' // 73
                  widthRatioUnit='px'
                  heightRatio='25' // 13
                  heightRatioUnit='px'
                  src={LOGOS['logo-colorbond.jpg']}
                />
              )
            case 'logoCrp':
              return (
                <ImageBase
                  {...this.props}
                  className={classes}
                  widthRatio='218' // 145
                  widthRatioUnit='px'
                  heightRatio='18' // 12
                  heightRatioUnit='px'
                  src={LOGOS['logo-crp.jpg']}
                />
              )
            case 'logoJingjoeLek':
              return (
                <ImageBase
                  {...this.props}
                  className={classes}
                  widthRatio='45' // 23
                  widthRatioUnit='px'
                  heightRatio='50' // 25
                  heightRatioUnit='px'
                  src={LOGOS['logo-jingjoe-lek.jpg']}
                />
              )
            case 'logoPrima':
              return (
                <ImageBase
                  {...this.props}
                  className={classes}
                  widthRatio='77' // 40
                  widthRatioUnit='px'
                  heightRatio='25' // 13
                  heightRatioUnit='px'
                  src={LOGOS['logo-prima.jpg']}
                />
              )
            case 'logoSuperDyma':
              return (
                <ImageBase
                  {...this.props}
                  className={classes}
                  widthRatio='121' // 63
                  widthRatioUnit='px'
                  heightRatio='25' // 13
                  heightRatioUnit='px'
                  src={LOGOS['logo-superdyma.jpg']}
                />
              )
            case 'logoTrueCore':
              return (
                <ImageBase
                  {...this.props}
                  className={classes}
                  widthRatio='125' // 65
                  widthRatioUnit='px'
                  heightRatio='25' // 13
                  heightRatioUnit='px'
                  src={LOGOS['logo-truecore.jpg']}
                />
              )
            case 'logoZincalume':
              return (
                <ImageBase
                  {...this.props}
                  className={classes}
                  widthRatio='131' // 63
                  widthRatioUnit='px'
                  heightRatio='25' // 12
                  heightRatioUnit='px'
                  src={LOGOS['logo-zincalume.jpg']}
                />
              )
            case 'iconViewWarranty':
              return (
                <ImageBase
                  {...this.props}
                  className={classes}
                  widthRatio='13'
                  widthRatioUnit='px'
                  heightRatio='16'
                  heightRatioUnit='px'
                  src={ICONS['ic-document-blue.svg']}
                />
              )
            case 'iconEditWarranty':
              return (
                <ImageBase
                  {...this.props}
                  className={classes}
                  widthRatio='11'
                  widthRatioUnit='px'
                  heightRatio='11'
                  heightRatioUnit='px'
                  src={ICONS['ic-action-edit.svg']}
                />
              )
            case 'iconStatusDefault':
              return (
                <ImageBase
                  {...this.props}
                  className={classes}
                  widthRatio='14'
                  widthRatioUnit='px'
                  heightRatio='14'
                  heightRatioUnit='px'
                  src={ICONS['ic-status-default.svg']}
                />
              )
            case 'iconStatusSuccess':
              return (
                <ImageBase
                  {...this.props}
                  className={classes}
                  widthRatio='14'
                  widthRatioUnit='px'
                  heightRatio='14'
                  heightRatioUnit='px'
                  src={ICONS['ic-status-success.svg']}
                />
              )
            case 'iconStatusFail':
              return (
                <ImageBase
                  {...this.props}
                  className={classes}
                  widthRatio='14'
                  widthRatioUnit='px'
                  heightRatio='14'
                  heightRatioUnit='px'
                  src={ICONS['ic-status-fail.svg']}
                />
              )
            case 'iconErrorNote':
              return (
                <ImageBase
                  {...this.props}
                  className={classes}
                  widthRatio='12'
                  widthRatioUnit='px'
                  heightRatio='16'
                  heightRatioUnit='px'
                  src={ICONS['ic-error-note.svg']}
                />
              )
            case 'iconMedalGold':
              return (
                <ImageBase
                  {...this.props}
                  className={classes}
                  widthRatioUnit='px'
                  heightRatioUnit='px'
                  src={ICONS['ic-medal-coin-gold.png']}
                />
              )
            case 'iconMedalSilver':
              return (
                <ImageBase
                  {...this.props}
                  className={classes}
                  widthRatioUnit='px'
                  heightRatioUnit='px'
                  src={ICONS['ic-medal-coin-silver.png']}
                />
              )
            case 'iconMedalBronze':
              return (
                <ImageBase
                  {...this.props}
                  className={classes}
                  widthRatioUnit='px'
                  heightRatioUnit='px'
                  src={ICONS['ic-medal-coin-bronze.png']}
                />
              )
            case 'iconDocuments':
              return (
                <ImageBase
                  {...this.props}
                  className={classes}
                  widthRatio='18'
                  widthRatioUnit='px'
                  heightRatio='18'
                  heightRatioUnit='px'
                  src={ICONS['ic-documents-blue.svg']}
                />
              )
            case 'iconDocument':
              return (
                <ImageBase
                  {...this.props}
                  className={classes}
                  widthRatio='11'
                  widthRatioUnit='px'
                  heightRatio='14'
                  heightRatioUnit='px'
                  src={ICONS['ic-document-blue.svg']}
                />
              )
            case 'warrantyMalaysiaTermsAndConditionsLabel':
              return (
                <ImageBase
                  {...this.props}
                  className={classes}
                  widthRatio='300'
                  widthRatioUnit='px'
                  heightRatio='12'
                  heightRatioUnit='px'
                  src={CONTENTS['warranty-malaysia-terms-and-conditions-label.jpg']}
                />
              )
            case 'iconPreSalesTransaction':
              return (
                <ImageBase
                  {...this.props}
                  className={classes}
                  widthRatio='18' // 15
                  widthRatioUnit='px'
                  heightRatio='18' // 15
                  heightRatioUnit='px'
                  src={ICONS['ic-pre-sales-transaction.svg']}
                  top='2' // 2

                />
              )
            case 'iconProjectReference':
              return (
                <ImageBase
                  {...this.props}
                  className={classes}
                  widthRatio='18' // 18
                  widthRatioUnit='px'
                  heightRatio='18' // 17
                  heightRatioUnit='px'
                  src={ICONS['ic-reference-white.svg']}
                  top='-1' // 0
                />
              )
            case 'iconDownload':
              return (
                <ImageBase
                  {...this.props}
                  className={classes}
                  widthRatio='18' // 18
                  widthRatioUnit='px'
                  heightRatio='18' // 17
                  heightRatioUnit='px'
                  src={ICONS['ic-dowload-circle-outline-blue.svg']}
                  top='-1' // 0
                />
              )
            case 'iconWarrantyGenerate':
              return (
                <ImageBase
                  {...this.props}
                  className={classes}
                  widthRatio='14' // 18
                  widthRatioUnit='px'
                  heightRatio='17' // 17
                  heightRatioUnit='px'
                  src={ICONS['ic-warranty-generate.svg']}
                  top='-1' // 0
                />
              )
            case 'iconViewImage':
              return (
                <ImageBase
                  {...this.props}
                  className={classes}
                  widthRatio='14' // 18
                  widthRatioUnit='px'
                  heightRatio='14' // 17
                  heightRatioUnit='px'
                  src={ICONS['ic-photo.svg']}
                  top='-1' // 0
                />
              )
            case 'iconViewDocument':
              return (
                <ImageBase
                  {...this.props}
                  className={classes}
                  widthRatio='12' // 18
                  widthRatioUnit='px'
                  heightRatio='15' // 17
                  heightRatioUnit='px'
                  src={ICONS['ic-document-fill-blue.svg']}
                  top='-1' // 0
                />
              )
              case 'iconNotificationManagement':
              return (
                <ImageBase
                  {...this.props}
                  className={classes}
                  widthRatio='18' // 15
                  widthRatioUnit='px'
                  heightRatio='18' // 15
                  heightRatioUnit='px'
                  src={ICONS['ic-notification-menu-white.svg']}
                  top='2' // 2
                />
              )
            default:
              return (
                <ImageBase
                  {...this.props}
                  className={classes}
                />
              )
          }
        })()}
      </React.Fragment>
    )
  }

  static defaultProps = {
    // * If you set new default props, you should create propsType for correct styleguide document.
  }

  static propTypes = {
    // TYPE > node, string, number, bool, array, object, symbol, func
    /**
    * props_name_oneType
    */
    // props_name_onetype: PropTypes.string,

    /**
    * props_name_oneMoreType
    */
    // props_name_oneMoreType: PropTypes.oneOfType([
    //   PropTypes.node,
    //   PropTypes.string
    // ])
  }
}
