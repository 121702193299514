import React from 'react'
import {
  ShapeContainer,
  Section,
  Breadcrumb,
  TitlePage
} from './../../components'
import {
  VARIABLES,
  ICONS
} from './../../themes'
import { setSideMenuActive } from '../../helpers/functions/route';
import { ROUTE_PATH } from '../../helpers/constants/route';
import { redirect } from '../../helpers/functions/route';

export class SpecialWarrantyScreen extends React.Component {

  constructor(props) {
    super(props);
    setSideMenuActive(props);
  }

  render() {
    return (
      <React.Fragment>
        <Breadcrumb>
          <Breadcrumb.List
            link
            name='Home'
            onClick={() => {
              redirect(ROUTE_PATH.HOME.LINK)
            }}
          />
          <Breadcrumb.List name='/' />
          <Breadcrumb.List
            link
            name='Warranty'
          />
          <Breadcrumb.List name='/' />
          <Breadcrumb.List name='Special Warranty' />
        </Breadcrumb>
        <Section ui='content'>
          <Section spacingBottom='35'>
            <TitlePage
              src={ICONS['ic-page-warranty.svg']}
              name='Warranty > Special Warranty'
            />
          </Section>
          <ShapeContainer ui='content'>
            Special Warranty
          </ShapeContainer>
        </Section>
      </React.Fragment>
    )
  }
}
