import { getUserAuth } from '../functions/auth';
import { handleQueryFirebase, handleUpdateFirebase, handleAddFirebase, handleQueryDocFirebase, handleSetFirebase } from './firebaseManageData';
import { ModelOTP } from '../../models/ModelOTP';

let qSnapOTPAuthUnsub = () => { };

export const firebaseUnsubscribeOTPAuth = () => {
  console.log('firebaseUnsubscribeOTPAuth')
  qSnapOTPAuthUnsub();
}

export const handleUpdateOTPAuth = (data, callback = () => { }) => {
  return new Promise(async (resolve, reject) => {
    try {
      let where = ['data', '==', data.data];
      const qSnapOTP = await handleQueryFirebase('otp_authen', {
        where
      });
      console.log('handleUpdateOTPAuth', where, qSnapOTP.size);
      if (qSnapOTP.size) {
        const qSnap = qSnapOTP.docs[0];
        let dataOTP = qSnap.data();
        dataOTP.firebaseKey = qSnap.id;
        dataOTP.firebasePath = qSnap.ref.parent.path;
        dataOTP = new ModelOTP(dataOTP);
        console.log('dataOTP', dataOTP);
        await handleUpdateFirebase('otp_authen', qSnap.id, {
          is_otp_success: true
        })
        callback(dataOTP)
      }
      else {
        callback();
      }
      resolve();
    }
    catch (err) {
      reject(err);
      console.log('err', { err });
    }
  });
}

export const handleWatchOTPAuth = (data, callback = () => { }) => {
  return new Promise(async (resolve, reject) => {
    try {
      let userAuth = getUserAuth();
      if (userAuth) {
        qSnapOTPAuthUnsub();
        await handleSetFirebase('otp_authen', userAuth.email, {
          id: userAuth.id || 0,
          email: userAuth.email || '',
          phone: `${userAuth.mobileNoCountry.value}${userAuth.mobileNo}` || '',
          reference_code: data.referenceCode || '',
          secret: data.secret || '',
          data: data.data || '',
          is_otp_success: false
        })

        qSnapOTPAuthUnsub = await handleQueryDocFirebase('otp_authen', userAuth.email, {
          callType: 'onSnapshot',
          callbackSuccess: async (doc) => {
            let dataOTP = doc.data();
            console.log('qSnapOTPAuthUnsub dataOTP', dataOTP)
            if (dataOTP.reference_code === data.referenceCode && dataOTP.is_otp_success) {
              dataOTP = new ModelOTP(dataOTP);
              console.log('handleWatchOTPAuth dataOTP', dataOTP)
              callback(dataOTP);
            }
            resolve();
          }
        });
      }
      else {
        resolve()
      }
    }
    catch (err) {
      reject(err);
      console.log('err', { err });
    }
  });
}