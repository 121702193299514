import React from 'react'
import ClassNames from 'classnames'
import PropTypes from 'prop-types'
import {
  osName,
} from 'react-device-detect'
import {
  WarrantyNoteWrapper
} from './styled'
import {
  Section,
  Text,
  Image,
  Grid,
  Table,
  ShapeContainer,
} from './../../../../components'
import {
  VARIABLES,
} from './../../../../themes'

/**
 * Warranty description:
 * - Warranty
 */

export class WarrantyBSWMThailandNote extends React.PureComponent {
  render() {
    const {
      registeredTrademarkName,
      customerName,
      thLanguage,
      isCustomer,
    } = this.props

    // props for css classes
    const classes = ClassNames(
      'warranty-bswm-note',
    )

    return (
      <WarrantyNoteWrapper
        className={classes}
      >
        {thLanguage ?
          <React.Fragment>
            {isCustomer ?
              <p className='warranty-bswm-text is-xs'><span className='warranty-bswm-text is-xs is-bold'>หมายเหตุ:</span> การรับประกันนี้ให้ไว้แก่ และสามารถใช้ได้เฉพาะ <span className='warranty-bswm-text is-xs is-bold'>{customerName}</span> เท่านั้น ภายใต้ข้อกำหนดและ<br />เงื่อนไขโดยชัดแจ้งที่แนบท้ายนี้ แม้ว่าจะใช้ความพยายามอย่างดีที่สุด การไม่ระบุชี้ปัญหากับสิ่งแวดล้อม และ/ หรือการติดตั้งระหว่างการตรวจสอบโครงการจะไม่กระทบ<br />หรือจำกัดการดำเนินการตามข้อกำหนด และเงื่อนไขตามที่แนบท้ายนี้</p>
              :
              <p className='warranty-bswm-text is-xs'><span className='warranty-bswm-text is-xs is-bold'>หมายเหตุ:</span> ภายใต้บังคับของเงื่อนไขที่ปรากฏในด้านหลังนี้ บริษัท เอ็นเอส บลูสโคป (ประเทศไทย) จำกัด ขอให้การรับประกันว่า โครงสร้างเหล็กของอาคารทั้งหมดภายในเอาคาร<br />ที่พักอาศัยและอาคารพาณิชย์ในประเทศไทย ผลิตขึ้นเป็นพิเศษจากเหล็กเคลือบ <span className='warranty-bswm-text is-xs is-bold'>{registeredTrademarkName}</span> ("ส่วนประกอบโครงสร้าง") จะได้รับการประกันความเสียหายต่อโครงสร้าง<br />เฉพาะสาเหตุจากการผุกร่อนตามที่ระบุข้างต้น (“ระยะเวลารรับประกัน”) นับจากวันที่ติดตั้ง</p>
            }
          </React.Fragment>
          :
          <p className='warranty-bswm-text is-xs'><span className='warranty-bswm-text is-xs is-bold'>NOTE:</span> This warranty is provided to, and can only be actioned by, <span className='warranty-bswm-text is-xs is-bold'>{customerName}</span> subject to the express terms and conditions attached. Failure, despite best endeavours, to identify problems with environment and/or installation during project inspection shall not preclude or limit the operation of the terms and conditions attached.</p>
        }

      </WarrantyNoteWrapper>
    )
  }

  static defaultProps = {
    // If you set new default props, you should create propsType for correct styleguide document.
  }

  static propTypes = {
    // TYPE > node, string, number, bool, array, object, symbol, func
  }
}
