import React from 'react'
import ClassNames from 'classnames'
// import PropTypes from 'prop-types'
import {
  SectionBase
} from './../../base'
import {
  Grid,
  ShapeContainer,
  Text,
  Image
} from './../../../components'
import {
  default as VARIABLES
} from './../../../themes/styles/bases/variables'
import {
  SHAREDS
} from './../../../themes'
// import {
//   default as TYPOGRAPHYS
// } from './../../../themes/styles/bases/typographys'
// import {
//   default as UTILITIES
// } from './../../../themes/styles/helpers/utilities'
import { getLanguage } from '../../../helpers/functions/language'
import _ from 'lodash';
/**
 * Section description:
 * - Section
 */

export class Section extends React.PureComponent {
  render() {
    const {
      children,
      className,
      ui,
      relative,
      zIndexLv1,
      zIndexLv2,
      zIndexLv3,
      zIndexLv4,
      zIndexLv5,
      overflowHidden,
      uploadAvatar,
      helpNumber,
      helpDetail,
      modalHeaderButton,
      onClick,
      hint,
      notification,
      open,
      coilRoof,
      coilWall,
      coilPurlinGirt,
      coilTrussFrame,
      coilCoolRoomPanel,
      coilDecking,
      coilMmx,
      coilMmsdf,
      coilMmdf,
      url,
      versionRight,
      legendBadgeColor,
      legendName,
      legendNameWidth,
      warrantyDetailName,
      warrantyDetailNameCustom,
      warrantyDetailNote,
      warrantyDetailYear,
      warrantyLicenseAuthorizedDate,
      warrantyLicenseAuthorizedSrc,
      warrantyLicenseAuthorizedName,
      warrantyLicenseAcknowledgedDate,
      warrantyLicenseAcknowledgedSrc,
      warrantyLicenseAcknowledgedName,
      isViewerPopup,
      isViewerUploading,
      isViewerLoading,
      isError,
      breakpointDisplayNone,
      absolute,
      breakAll,
    } = this.props

    // props for css classes
    const classes = ClassNames(
      className,
      { 'is-relative': relative },
      { 'is-zindex-lv1': zIndexLv1 },
      { 'is-zindex-lv2': zIndexLv2 },
      { 'is-zindex-lv3': zIndexLv3 },
      { 'is-zindex-lv4': zIndexLv4 },
      { 'is-zindex-lv5': zIndexLv5 },
      { 'is-overflow-hidden': overflowHidden },
      { 'is-upload-avatar': uploadAvatar },
      { 'is-modal-header-button': modalHeaderButton },
      { 'is-cursor': onClick },
      { 'is-notification': notification },
      { 'is-breakpoint-display-none ': breakpointDisplayNone },
      { 'is-absolute': absolute },
      { 'is-break-all': breakAll },

    )

    return (
      <React.Fragment>
        {(() => {
          switch (ui) {
            case 'content':
              return (
                <SectionBase
                  {...this.props}
                  className={classes}
                  padding='40'
                />
              )
            case 'hintPassword':
              return (
                <SectionBase
                  {...this.props}
                  className={ClassNames(
                    className,
                    'is-hint-password'
                  )}
                  height='100'
                  heightUnit='%'
                  paddingLeft='85'
                  borderLeftWidth='1'
                  borderLeftStyle='solid'
                  borderLeftColor={VARIABLES.COLORS.BLUE_15}
                />
              )
            case 'helpList':
              return (
                <SectionBase
                  {...this.props}
                  className={classes}
                  spacingBottom='15'
                >
                  <Grid gutter='15'>
                    <Grid.Column flex='none'>
                      <ShapeContainer ui='numberHelp'>
                        {helpNumber}
                      </ShapeContainer>
                    </Grid.Column>
                    <Grid.Column flex='1'>
                      <SectionBase paddingTop='5'>
                        <Text
                          regular14
                          color={VARIABLES.COLORS.BLACK}
                        >
                          {helpDetail}
                        </Text>
                      </SectionBase>
                    </Grid.Column>
                  </Grid>
                </SectionBase>
              )
            case 'hintBottomField':
              return (
                <SectionBase
                  {...this.props}
                  className={classes}
                >
                  <Text
                    regular10
                    singleLine
                    color={VARIABLES.COLORS.GRAY_19}
                  >
                    {hint}
                  </Text>
                </SectionBase>
              )
            case 'hintBottomTextarea':
              return (
                <SectionBase
                  {...this.props}
                  className={classes}
                  spacingTop='-4'
                >
                  <Text
                    regular10
                    singleLine
                    color={VARIABLES.COLORS.GRAY_19}
                  >
                    {hint}
                  </Text>
                </SectionBase>
              )
            case 'viewMore':
              return (
                <SectionBase
                  {...this.props}
                  className={classes}
                >
                  <Grid
                    gutter='10'
                    justify='center'
                  >
                    <Grid.Column>
                      <Text
                        regular9
                        color={VARIABLES.COLORS.PRIMARY_2}
                      >
                        {children}
                      </Text>
                    </Grid.Column>
                    <Grid.Column>
                      <Image ui={open ? 'iconArrowViewMoreOpen' : 'iconArrowViewMoreClose'} />
                    </Grid.Column>
                  </Grid>
                </SectionBase>
              )
            case 'coilDetail':
              return (
                <SectionBase
                  paddingVertical='5'
                  paddingHorizontal='5'
                >
                  <Grid gutter='60'>
                    <Grid.Column>
                      <SectionBase spacingBottom='5'>
                        <Text
                          bold12
                          color={VARIABLES.COLORS.PRIMARY_2}
                        >
                          {getLanguage('component.section.coilDetail.title', '')}
                        </Text>
                      </SectionBase>
                      <SectionBase spacingBottom='5'>
                        <Grid gutter='15'>
                          <Grid.Column>
                            <Text
                              regular12
                              color={VARIABLES.COLORS.GRAY_19}
                            >
                              {getLanguage('component.section.coilDetail.roofAndWall', '')} {coilRoof ? coilRoof : '-'}
                            </Text>
                          </Grid.Column>
                          {/*<Grid.Column>
                            <Text
                              regular12
                              color={VARIABLES.COLORS.GRAY_19}
                            >
                              /
                            </Text>
                          </Grid.Column>
                           <Grid.Column>
                            <Text
                              regular12
                              color={VARIABLES.COLORS.GRAY_19}
                            >
                              Wall - Sq.m : {coilWall ? coilWall : '-'}
                            </Text>
                          </Grid.Column> */}
                          <Grid.Column>
                            <Text
                              regular12
                              color={VARIABLES.COLORS.GRAY_19}
                            >
                              /
                            </Text>
                          </Grid.Column>
                          <Grid.Column>
                            <Text
                              regular12
                              color={VARIABLES.COLORS.GRAY_19}
                            >
                              {getLanguage('component.section.coilDetail.purlin', '')} {coilPurlinGirt ? coilPurlinGirt : '-'}
                            </Text>
                          </Grid.Column>
                        </Grid>
                      </SectionBase>
                      <SectionBase>
                        <Grid gutter='15'>
                          <Grid.Column>
                            <Text
                              regular12
                              color={VARIABLES.COLORS.GRAY_19}
                            >
                              {getLanguage('component.section.coilDetail.truss', '')} {coilTrussFrame ? coilTrussFrame : '-'}
                            </Text>
                          </Grid.Column>
                          <Grid.Column>
                            <Text
                              regular12
                              color={VARIABLES.COLORS.GRAY_19}
                            >
                              /
                            </Text>
                          </Grid.Column>
                          <Grid.Column>
                            <Text
                              regular12
                              color={VARIABLES.COLORS.GRAY_19}
                            >
                              {getLanguage('component.section.coilDetail.coolRoom', '')} {coilCoolRoomPanel ? coilCoolRoomPanel : '-'}
                            </Text>
                          </Grid.Column>
                          <Grid.Column>
                            <Text
                              regular12
                              color={VARIABLES.COLORS.GRAY_19}
                            >
                              /
                            </Text>
                          </Grid.Column>
                          <Grid.Column>
                            <Text
                              regular12
                              color={VARIABLES.COLORS.GRAY_19}
                            >
                              {getLanguage('component.section.coilDetail.decking', '')} {coilDecking ? coilDecking : '-'}
                            </Text>
                          </Grid.Column>
                        </Grid>
                      </SectionBase>
                    </Grid.Column>
                    <Grid.Column>
                      <SectionBase spacingBottom='5'>
                        <Text
                          bold12
                          color={VARIABLES.COLORS.PRIMARY_2}
                        >
                          {getLanguage('component.section.coilDetail.mmx', '')} &nbsp;
                        </Text>
                        <Text
                          regular12
                          color={VARIABLES.COLORS.GRAY_19}
                        >
                          {coilMmx ? coilMmx : '-'}
                        </Text>
                      </SectionBase>
                      <SectionBase spacingBottom='5'>
                        <Text
                          bold12
                          color={VARIABLES.COLORS.PRIMARY_2}
                        >
                          {getLanguage('component.section.coilDetail.mmsdf', '')} &nbsp;
                        </Text>
                        <Text
                          regular12
                          color={VARIABLES.COLORS.GRAY_19}
                        >
                          {coilMmsdf ? coilMmsdf : '-'}
                        </Text>
                      </SectionBase>
                      <SectionBase>
                        <Text
                          bold12
                          color={VARIABLES.COLORS.PRIMARY_2}
                        >
                          {getLanguage('component.section.coilDetail.mmdf', '')} &nbsp;
                        </Text>
                        <Text
                          regular12
                          color={VARIABLES.COLORS.GRAY_19}
                        >
                          {coilMmdf ? coilMmdf : '-'}
                        </Text>
                      </SectionBase>
                    </Grid.Column>
                  </Grid>
                </SectionBase>
              )
            case 'googleViewer':
              return (
                <SectionBase
                  {...this.props}
                  className={ClassNames(
                    className,
                    'is-google-viewer',
                    { 'is-disabled-google-viewer-popup': !isViewerPopup },
                    { 'is-loading-google-viewer-popup': isViewerLoading },
                  )}
                  height='1132' // A4 height = 1132
                  // calcHeight='100vh - 645px'
                  bgColor={VARIABLES.COLORS.GRAY_24}
                >
                  {
                    isViewerUploading ?
                      <SectionBase
                        className='is-loading-message-google-viewer-popup'
                        paddingTop='150'
                      >
                        <SectionBase
                          justify='center'
                          spacingBottom='10'
                        >
                          <Image
                            widthRatio='50'
                            widthRatioUnit='px'
                            heightRatio='50'
                            heightRatioUnit='px'
                            src={SHAREDS['loading.svg']}
                          />
                        </SectionBase>
                        <SectionBase justify='center'>
                          <Text
                            regular14
                            color={VARIABLES.COLORS.WHITE}
                          >
                            {getLanguage('warrantyManage.alertGenerateWarranty', '')}
                          </Text>
                        </SectionBase>
                      </SectionBase>
                      :
                      <React.Fragment>
                        {isViewerLoading &&
                          <SectionBase
                            className='is-loading-message-google-viewer-popup'
                            paddingTop='150'
                          >
                            <SectionBase
                              justify='center'
                              spacingBottom='10'
                            >
                              <Image
                                widthRatio='50'
                                widthRatioUnit='px'
                                heightRatio='50'
                                heightRatioUnit='px'
                                src={SHAREDS['loading.svg']}
                              />
                            </SectionBase>
                            <SectionBase justify='center'>
                              <Text
                                regular14
                                color={VARIABLES.COLORS.WHITE}
                              >
                                {getLanguage('warrantyManage.alertGenerateWarranty', '')}
                              </Text>
                            </SectionBase>
                          </SectionBase>
                        }
                        <iframe
                          title='Google Viewer'
                          // src={`https://docs.google.com/viewer?url=` + url + `&embedded=true`}
                          src={url + `#toolbar=0`}
                          frameBorder='0'
                        />
                      </React.Fragment>
                  }
                </SectionBase>
              )
            case 'versionNumber':
              return (
                <SectionBase
                  {...this.props}
                  nonFluid
                  className={ClassNames(
                    'is-version-number',
                    { 'is-version-number-right': versionRight }
                  )}
                >
                  <Text
                    regular12
                    singleLine
                    color={VARIABLES.COLORS.WHITE}
                  >
                    Ver {children}
                  </Text>
                </SectionBase>
              )
            case 'legend':
              return (
                <SectionBase
                  {...this.props}
                  className={classes}
                >
                  <Grid gutter='10'>
                    <Grid.Column>
                      <ShapeContainer
                        width='18'
                        height='8'
                        bgColor={legendBadgeColor}
                        borderRadius='4'
                      />
                    </Grid.Column>
                    <Grid.Column>
                      <Section width={legendNameWidth}>
                        <Text
                          ellipsis
                          regular14
                          color={VARIABLES.COLORS.GRAY_26}
                          title={legendNameWidth && legendName}
                        >
                          {legendName}
                        </Text>
                      </Section>
                    </Grid.Column>
                  </Grid>
                </SectionBase>
              )
            case 'idWarranty':
              return (
                <SectionBase
                  {...this.props}
                  nonFluid
                  className='is-id-warranty'
                >
                  <Grid>
                    <Grid.Column>
                      <SectionBase paddingRight='15'>
                        <Text
                          bold18
                          color={VARIABLES.COLORS.BLACK}
                        >
                          Warranty&nbsp;No.
                        </Text>
                      </SectionBase>
                    </Grid.Column>
                    <Grid.Column>
                      <Text
                        regular18
                        color={VARIABLES.COLORS.BLACK}
                      >
                        {children}
                      </Text>

                    </Grid.Column>
                  </Grid>


                </SectionBase>
              )
            case 'warrantyDetailList':
              return (
                <SectionBase
                  {...this.props}
                  className={classes}
                  spacingTop='10'
                >
                  <Grid
                    gutter='30'
                    gutterVertical='20'
                  >
                    <Grid.Column flex='1'>
                      {warrantyDetailNameCustom ?
                        warrantyDetailNameCustom
                        :
                        <Text
                          regular18
                          lineheightTh
                          noWrap
                          color={VARIABLES.COLORS.BLACK}
                        >
                          {warrantyDetailName}
                        </Text>
                      }
                    </Grid.Column>
                    <Grid.Column flex='none'>
                      {warrantyDetailNote &&
                        <Text
                          regular18
                          lineheightTh
                          noWrap
                          color={VARIABLES.COLORS.BLACK}
                        >
                          {warrantyDetailNote}
                        </Text>
                      }
                      {warrantyDetailYear &&
                        <Text
                          regular18
                          lineheightTh
                          noWrap
                          color={VARIABLES.COLORS.BLACK}
                        >
                          {warrantyDetailYear} Year
                        </Text>
                      }
                    </Grid.Column>
                  </Grid>
                </SectionBase>
              )
            case 'warrantyLicense':
              return (
                <SectionBase
                  {...this.props}
                  className='is-license-warranty'
                >
                  <Grid
                    gutter='60'
                    gutterVertical='60'
                  >
                    <Grid.Column grid='6'>
                      <SectionBase spacingBottom='10'>
                        <Grid gutter='30'>
                          <Grid.Column flex='1'>
                            <Text
                              regular18
                              lineheightTh
                              noWrap
                              color={VARIABLES.COLORS.BLACK}
                            >
                              Authorized By
                            </Text>
                          </Grid.Column>
                          <Grid.Column flex='none'>
                            <Grid>
                              <Grid.Column>
                                <Text
                                  regular18
                                  lineheightTh
                                  noWrap
                                  color={VARIABLES.COLORS.BLACK}
                                >
                                  Date&nbsp;
                                </Text>
                              </Grid.Column>
                              <Grid.Column>
                                <SectionBase
                                  paddingLeft='5'
                                  paddingRight='15'
                                >
                                  <Text
                                    regular18
                                    lineheightTh
                                    noWrap
                                    color={VARIABLES.COLORS.BLACK}
                                  >
                                    {warrantyLicenseAuthorizedDate}
                                  </Text>
                                </SectionBase>
                              </Grid.Column>
                            </Grid>
                          </Grid.Column>
                        </Grid>
                      </SectionBase>
                      <SectionBase>
                        <ShapeContainer
                          fluid
                          paddingTop='30'
                          paddingBottom='45'
                          paddingHorizontal='45'
                          borderWidth='1'
                          borderStyle='solid'
                          borderColor={VARIABLES.COLORS.BLACK}
                          borderRadius='8'
                        >
                          <SectionBase
                            height='88'
                            justify='center'
                          >
                            {warrantyLicenseAuthorizedSrc &&
                              <Image
                                widthRatio='240'
                                widthRatioUnit='px'
                                heightRatio='88'
                                heightRatioUnit='px'
                                src={warrantyLicenseAuthorizedSrc}
                              />
                            }
                          </SectionBase>
                        </ShapeContainer>
                        <SectionBase
                          spacingTop='-12'
                          justify='center'
                        >
                          <SectionBase
                            nonFluid
                            zIndexLv1
                            paddingHorizontal='40'
                            bgColor={VARIABLES.COLORS.WHITE}
                          >
                            <Text
                              bold18
                              noWrap
                              color={VARIABLES.COLORS.BLACK}
                            >
                              {warrantyLicenseAuthorizedName}
                            </Text>
                          </SectionBase>
                        </SectionBase>
                      </SectionBase>
                    </Grid.Column>
                    <Grid.Column grid='6'>
                      <SectionBase
                        spacingBottom='10'
                        justify='space-between'
                      >
                        <Grid gutter='30'>
                          <Grid.Column flex='1'>
                            <Text
                              regular18
                              lineheightTh
                              noWrap
                              color={VARIABLES.COLORS.BLACK}
                            >
                              Acknowledged By
                            </Text>
                          </Grid.Column>
                          <Grid.Column flex='none'>
                            <Grid>
                              <Grid.Column>
                                <Text
                                  regular18
                                  lineheightTh
                                  noWrap
                                  color={VARIABLES.COLORS.BLACK}
                                >
                                  Date&nbsp;
                                </Text>
                              </Grid.Column>
                              <Grid.Column>
                                <SectionBase
                                  paddingLeft='5'
                                  paddingRight='15'
                                >
                                  <Text
                                    regular18
                                    lineheightTh
                                    noWrap
                                    color={VARIABLES.COLORS.BLACK}
                                  >
                                    {warrantyLicenseAcknowledgedDate}
                                  </Text>
                                </SectionBase>
                              </Grid.Column>
                            </Grid>
                          </Grid.Column>
                        </Grid>
                      </SectionBase>
                      <SectionBase>
                        <ShapeContainer
                          fluid
                          paddingTop='30'
                          paddingBottom='45'
                          paddingHorizontal='45'
                          borderWidth='1'
                          borderStyle='solid'
                          borderColor={VARIABLES.COLORS.BLACK}
                          borderRadius='8'
                        >
                          <SectionBase
                            height='88'
                            justify='center'
                          >
                            {warrantyLicenseAcknowledgedSrc &&
                              <Image
                                widthRatio='240'
                                widthRatioUnit='px'
                                heightRatio='88'
                                heightRatioUnit='px'
                                src={warrantyLicenseAcknowledgedSrc}
                              />
                            }
                          </SectionBase>
                        </ShapeContainer>
                        <SectionBase
                          spacingTop='-12'
                          justify='center'
                        >
                          <SectionBase
                            nonFluid
                            zIndexLv1
                            paddingHorizontal='40'
                            bgColor={VARIABLES.COLORS.WHITE}
                          >
                            <Text
                              bold18
                              noWrap
                              color={VARIABLES.COLORS.BLACK}
                            >
                              {warrantyLicenseAcknowledgedName}
                            </Text>
                          </SectionBase>
                        </SectionBase>
                      </SectionBase>
                    </Grid.Column>
                  </Grid>
                </SectionBase>
              )
            default:
              return (
                <SectionBase
                  {...this.props}
                  className={classes}
                />
              )
          }
        })()}
      </React.Fragment>
    )
  }

  static defaultProps = {
    // * If you set new default props, you should create propsType for correct styleguide document.
  }

  static propTypes = {
    // TYPE > node, string, number, bool, array, object, symbol, func
    /**
    * props_name_oneType
    */
    // props_name_onetype: PropTypes.string,

    /**
    * props_name_oneMoreType
    */
    // props_name_oneMoreType: PropTypes.oneOfType([
    //   PropTypes.node,
    //   PropTypes.string
    // ])
  }
}
