import React from 'react'
import {
  ShapeContainer,
  Section,
  Breadcrumb,
  TitlePage,
  TitleSection,
  Grid,
  Button,
  Image,
  Text,
} from './../../components'
import {
  ModalHardwareAndTokoShopProfileContainer,
  ModalAuthorisedDealerAndRollFormerProfileContainerConnected,
} from './../../containers/ModalContainer'
import {
  VARIABLES,
  ICONS,
} from './../../themes'
import { setSideMenuActive, getRouteData } from '../../helpers/functions/route';
import { ROUTE_PATH } from '../../helpers/constants/route';
import { redirect } from '../../helpers/functions/route';
import _ from 'lodash';
import { getLanguage } from '../../helpers/functions/language';
import { getUserAuthRedux } from '../../helpers/functions/auth';
import { ENUM } from '../../helpers/constants/enum';

export class CoilTransferScreen extends React.Component {

  constructor(props) {
    super(props);
    setSideMenuActive(props);
    let data = getRouteData(props, 'data');
    this.state = {
      isOpenModalAuthorisedDealerAndRollFormerProfile: false,
      isDisabledButtonSave: false,
      AdAndToko: false,
      adRfData: data,
      userAuth: getUserAuthRedux(),
    }
  }

  modal = {
    onOpenModalAuthorisedDealerAndRollFormerProfile: () => {
      this.setState({
        isOpenModalAuthorisedDealerAndRollFormerProfile: true,
      });
    },

    onCloseModalAuthorisedDealerAndRollFormerProfile: () => {
      this.setState({
        isOpenModalAuthorisedDealerAndRollFormerProfile: false,
      });
    },

    onOpenModalHardwareAndTokoShopProfile: () => {
      this.setState({
        isOpenModalHardwareAndTokoShopProfile: true,
      });
    },

    onCloseModalHardwareAndTokoShopProfile: () => {
      this.setState({
        isOpenModalHardwareAndTokoShopProfile: false,
      });
    },
    // onClickSubmit: (data) => {
    //   this.setState({
    //     adRfData: data,
    //   })
    //   redirect(ROUTE_PATH.COIL_TRANSACTION.LINK, { data })
    // },
  }

  render() {
    const {
      isOpenModalAuthorisedDealerAndRollFormerProfile,
      isOpenModalHardwareAndTokoShopProfile,
      isDisabledButtonSave,
      AdAndToko,
      adRfData,
      userAuth
    } = this.state

    return (
      <React.Fragment>
        <Breadcrumb>
          <Breadcrumb.List
            link
            name={getLanguage('coilTransfer.breadcrumb1', '')}
            onClick={() => {
              redirect(ROUTE_PATH.HOME.LINK)
            }}
          />
          <Breadcrumb.List name='/' />
          <Breadcrumb.List
            link
            name={getLanguage('coilTransfer.breadcrumb2', '')}
          // onClick={() => {
          //   redirect(ROUTE_PATH.INVENTORY_INDEXES.LINK)
          // }}
          />
          <Breadcrumb.List name='/' />
          <Breadcrumb.List name={getLanguage('coilTransfer.breadcrumb3', '')} />
        </Breadcrumb>
        <Section ui='content'>
          <Section spacingBottom='35'>
            <TitlePage
              src={ICONS['ic-page-inventory.svg']}
              name={getLanguage('coilTransfer.title', '')}
            />
          </Section>
          <ShapeContainer ui='content'>
            <Section spacingBottom='45'>
              <TitleSection name={AdAndToko ? getLanguage('coilTransfer.form.adAndToko.title', '') : getLanguage('coilTransfer.form.customer.title', '')} />
              <Section spacingTop='70'>
                <Section
                  spacingBottom='20'
                  justify='center'
                >
                  <Image ui='iconBlankCustomer' />
                </Section>
                <Section
                  spacingBottom='20'
                  justify='center'
                >
                  <Text
                    regular14
                    color={VARIABLES.COLORS.BLACK}
                  >
                    {AdAndToko ? getLanguage('coilTransfer.form.adAndToko.blank', '') : getLanguage('coilTransfer.form.customer.blank', '')}
                  </Text>
                </Section>
                <Section>
                  <Grid
                    gutter='20'
                    gutterVertical='15'
                    justify='center'
                  >
                    <Grid.Column>
                      <Button
                        lightBlue
                        ui='frontIcon'
                        name={AdAndToko ? getLanguage('coilTransfer.form.adAndToko.buttonCreate', '') : getLanguage('coilTransfer.form.customer.buttonCreate', '')}
                        iconWidth='14'
                        iconHeight='14'
                        iconSrc={ICONS['ic-create.svg']}
                        width={AdAndToko ? VARIABLES.BUTTON.WIDTHS.W_310 : VARIABLES.BUTTON.WIDTHS.W_360}
                        onClick={() => {
                          redirect(ROUTE_PATH.AUTHORISED_DEALER_AND_ROLL_FORMER_MANAGE.LINK);
                        }}
                      />
                    </Grid.Column>
                    <Grid.Column>
                      <Button
                        blue
                        ui='frontIcon'
                        name={AdAndToko ? getLanguage('coilTransfer.form.adAndToko.buttonSelect', '') : getLanguage('coilTransfer.form.customer.buttonSelect', '')}
                        iconWidth='20'
                        iconHeight='16'
                        iconSrc={ICONS['ic-select-customer.svg']}
                        width={AdAndToko ? VARIABLES.BUTTON.WIDTHS.W_310 : VARIABLES.BUTTON.WIDTHS.W_360}
                        onClick={AdAndToko ? this.modal.onOpenModalHardwareAndTokoShopProfile : this.modal.onOpenModalAuthorisedDealerAndRollFormerProfile}
                      />
                    </Grid.Column>
                  </Grid>
                </Section>
              </Section>
            </Section>
          </ShapeContainer>
        </Section>
        {
          isOpenModalAuthorisedDealerAndRollFormerProfile &&
          <ModalAuthorisedDealerAndRollFormerProfileContainerConnected
            adRfData={adRfData}
            isOpenModal={isOpenModalAuthorisedDealerAndRollFormerProfile}
            disabledButtonSave={isDisabledButtonSave}
            onClickClose={this.modal.onCloseModalAuthorisedDealerAndRollFormerProfile}
          />
        }
      </React.Fragment>
    )
  }
}

// const mapStateToProps = (state) => {
//   const dataRedux = state.dataRedux;
//   return {
//     adID: dataRedux.adID
//   }
// }

// const mapDispatchToProps = (dispatch) => {
//   return {
//     dataActions: bindActionCreators(dataCreators, dispatch),
//   }
// }

// export const CoilTransferScreenConnected = connect(
//   mapStateToProps,
//   mapDispatchToProps
// )(CoilTransferScreen)