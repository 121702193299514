import apiService from '../apiService'

const apiPath = '/auth-dealer';

export const adController = (configService = {}) => {
  const service = apiService(configService);
  return {
    updateADInfo: (params) => {
      return service.put(`${apiPath}/${params.id}`, params)
    },
    updateTermsAndCondition: (params) => {
      return service.put(`${apiPath}/terms/update`, params)
    },
    // forgetPassword: (params) => {
    //   return service.post(`${apiPath}/forgetpw`, params)
    // },
  }
}
