import moment from 'moment';
import { DATE_FORMAT } from "../helpers/constants/data";
import { ENUM } from "../helpers/constants/enum";
import { getUserAuthRedux } from "../helpers/functions/auth";
import { getCityByLabel, getCityThaiByLabel, getCountryPhone, getPhoneRemovePrefix, getProvinceByLabel, getProvinceThaiByLabel, getSubDistrictThaiByLabel } from "../helpers/functions/data";
import { displayDate, displayUrlPath, displayWithPrefix } from "../helpers/functions/display";
import { ModelImage } from "./ModelImage";

export class ModelWarrantyDocumentary {
  constructor(props = {}) {
    this.id = props.doc_ms_id;
    this.idWithPrefix = displayWithPrefix(this.id);
    this.docMsId = props.doc_ms_id;
    this.docMsCate = props.doc_ms_cate || '';
    this.docMsCode = props.doc_ms_code || '';
    this.docMsName = props.doc_ms_name || '';
    this.docMsPathFile = props.doc_ms_path_file || '';
    this.docMsPathLogo = props.doc_ms_path_logo || '';
    this.certificateSettingType = props.certificate_setting_type;
    this.folderType = props.folder_type;
    this.docSubFolderIsActive = props.doc_sf_is_active;
    this.docSFId = props.doc_sf_id;
    this.wtmTempPreId = props.wtm_temp_pre_id;
    this.wtmTempPostId = props.wtm_temp_post_id;
    this.productionStartDate = props.production_start_date;
    this.productionEndDate = props.production_end_date;

    this.displayDocMsName = `${this.docMsCate} ${this.docMsCode} ${this.docMsName}`;
    this.docMsPathFileObject = new ModelImage({ path: this.docMsPathFile });
    this.docMsPathLogoFile = new ModelImage({ path: this.docMsPathLogo });
    this.isSelected = !!props.isSelected;
    this.isShowPreCertificate = this.certificateSettingType === ENUM.PROJECT_DOCUMENT.CERTIFICATE_SETTING_TYPE.PRE ||
      this.certificateSettingType === ENUM.PROJECT_DOCUMENT.CERTIFICATE_SETTING_TYPE.PRE_POST
    this.isShowPostCertificate = this.certificateSettingType === ENUM.PROJECT_DOCUMENT.CERTIFICATE_SETTING_TYPE.POST ||
      this.certificateSettingType === ENUM.PROJECT_DOCUMENT.CERTIFICATE_SETTING_TYPE.PRE_POST
    this.isHideWatermark = this.folderType === ENUM.DOCUMENTARY.FOLDER_TYPE.DOCUMENTARY
  }
}

export class ModelDocumentary {
  constructor(props = {}) {
    const userAuth = getUserAuthRedux() || {};

    this.id = props.wrt_dt_id;
    this.idWithPrefix = displayWithPrefix(this.id);
    this.wrtDtId = props.wrt_dt_id;
    this.wrtId = props.wrt_id;
    this.createBy = props.created_by;
    this.createdDate = props.created_date;
    this.wrtNo = props.wrt_no;
    this.salesTran = props.sales_tran;
    this.projectName = props.project_name;
    this.customerName = props.customer_name;
    this.productGroupName = props.product_group_name;
    // CR: 1498
    this.oldProductGroupName = props.product_group_name;
    this.mobileNo = props.mobile_no;
    this.docStatus = props.doc_status;
    this.salesTranId = props.sales_tran_id;
    this.m3ProdGrpCode = props.m3_prod_grp_code;
    this.projectAddr = props.project_addr || '';
    this.projectGroup = props.project_group || '';
    this.projectAlley = props.project_alley || '';
    this.projectStreet = props.project_street || '';
    this.projectWard = props.project_ward || '';
    this.projectSubDist = props.project_sub_dist || '';
    this.projectDist = props.project_dist || '';
    this.projectProv = props.project_prov || '';
    this.projectCountry = props.project_county || '';
    this.projectCountry = this.projectCountry ? this.projectCountry : userAuth.addressType;
    this.selectedProvince = this.projectCountry === ENUM.COUNTRY_TYPE.LAOS ?
      props.project_prov
      :
      getProvinceByLabel(props.project_prov);
    this.selectedCity = this.projectCountry === ENUM.COUNTRY_TYPE.LAOS ?
      props.project_city
      :
      getCityByLabel(props.project_city);
    this.projectPostCode = props.project_post_code === 'null' ? '' : (props.project_post_code || '');
    if (this.projectCountry === ENUM.COUNTRY_TYPE.THAI) {
      // this.projectSubDist = getSubDistrictOption(this.selectedCity, this.projectSubDist);
      this.selectedProvince = getProvinceThaiByLabel(props.project_prov || '');
      this.selectedCity = getCityThaiByLabel(props.project_city || '', this.selectedProvince);
      this.projectSubDist = getSubDistrictThaiByLabel(this.projectSubDist, this.selectedCity);

      this.projectPostCode = {
        label: this.projectPostCode,
        value: this.projectPostCode,
      }
    }
    this.coilId = props.coil_id;
    this.coilName = props.coil_name;
    this.productionDateTime = props.production_date;

    this.m3ProductGroup = this.m3ProdGrpCode;
    this.productionDate = this.productionDateTime ? moment(this.productionDateTime).format('YYYY-MM-DD') : null
    this.mobileNoSuffix = getPhoneRemovePrefix(this.mobileNo);
    this.mobileNoCountry = getCountryPhone(this.mobileNo);
    this.mobileNoPrefix = this.mobileNoCountry.value;
    this.createdDateTime = moment(this.createdDate)._d;
    this.displayCreatedDate = displayDate(this.createdDate, '/', DATE_FORMAT);
    this.isCanSelect = props.doc_status !== ENUM.DOCUMENTARY.STATUS.READY;
    this.isReady = props.doc_status === ENUM.DOCUMENTARY.STATUS.READY;
    this.isSelected = false;
    // this.isCanSelect = true;
  }
}

export class ModelDocumentaryDetail {
  constructor(props = {}) {
    const userAuth = getUserAuthRedux() || {};

    this.id = props.doc_ms_id;
    this.idWithPrefix = displayWithPrefix(this.id);
    this.docMsId = props.doc_ms_id;
    this.docId = props.doc_id;
    this.createdDate = props.created_date;
    this.docMsCate = props.doc_ms_cate;
    this.docMsCode = props.doc_ms_code;
    this.docMsName = props.doc_ms_name;
    this.docSpCompName = props.doc_sp_comp_name;
    this.docPath = props.doc_path;

    this.createdDateTime = moment(this.createdDate)._d;
    this.displayCreatedDate = displayDate(this.createdDate, '/', DATE_FORMAT);
    this.fileUrlRef = this.docPath;
    this.fileUrl = displayUrlPath(this.fileUrlRef);
  }
}