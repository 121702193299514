import ClassNames from 'classnames'
import _ from 'lodash'
import React from 'react'
import {
  Accordion,
  AccordionItem,
  AccordionItemBody,
  AccordionItemTitle
} from 'react-accessible-accordion'
import { getLanguage } from '../../helpers/functions/language'
import { isShowLoading } from '../../helpers/functions/main'
import { isValidTemplateExtendYear, isValidTemplateSegmentSpecial } from '../../helpers/functions/warranty'
import { getWarrantyDocumentaryDetailList } from '../../helpers/services/warranty'
import {
  AccordionControl,
  Button,
  DocumentCard,
  Field,
  Grid,
  Section,
  ShapeContainer,
  Text,
  TitleSection,
} from './../../components'
import {
  ModalConfirmContainer,
  ModalPreviewPdfContainer,
} from './../../containers/ModalContainer'
import {
  ICONS,
  VARIABLES
} from './../../themes'

export class GenerateDocumentaryContainer extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isShowPreviewDocumentary: false,
      previewDataModal: {},
      isShowConfirmCompanyName: false,

      isPreviewWarranty: false,
      dataDetail: {},
    }
  }

  componentDidMount() {
    const {
      isSelectDocumentary,
    } = this.props;
    if (isSelectDocumentary) {
      this.generateDocumentary.getM3DocumentaryList();
    }
  }

  generateDocumentary = {
    getM3DocumentaryList: async () => {
      const {
        data,
        handleUpdateProductGroupData
      } = this.props;
      isShowLoading(true);
      const newProductGroupList = [];
      let cnt = 0;
      const configService = {
        isShowLoading: false,
      }
      data.productGroupList.forEach(async (e) => {

        const m3ProductGroupList = data.selectedDataList.filter(ed => ed.productGroupName === e.productGroupName);
        const params = {
          data: m3ProductGroupList.map(em => {
            return {
              m3_prod_grp_code: em.m3ProductGroup,
              production_date: em.productionDate
            }
          })
        }
        let dataList = await getWarrantyDocumentaryDetailList(params, configService)
        // let dataList = await getM3DocumentaryList({
        //   m3ProdGrpCode: e.m3ProductGroup,
        //   folderType: ENUM.DOCUMENTARY.FOLDER_TYPE.WARRANRY_DOCUMENTARY
        // });
        cnt++;

        const selectedIdList = (e.documentaryList || []).filter(ed => ed.isSelected).map(ed => ed.id);
        dataList = dataList.map(ed => {
          if (selectedIdList.indexOf(ed.id) >= 0) {
            ed.isSelected = true;
          }
          return ed;
        })
        newProductGroupList.push({
          ...e,
          documentaryList: dataList
        });
        if (cnt === data.productGroupList.length) {
          isShowLoading(false);
          // CR: 1498
          handleUpdateProductGroupData(_.orderBy(newProductGroupList, ['productGroupName'], ['asc']));
        }
      });
    },
    onChangeSelected: (ev, groupData, indexGroup, item, index) => {
      const {
        data,
        handleUpdateProductGroupData
      } = this.props;
      const isChecked = ev.target.checked;
      data.productGroupList[indexGroup].documentaryList[index].isSelected = isChecked;
      handleUpdateProductGroupData(data.productGroupList);
    },
    onClickPreviewDocumentary: (item) => {
      this.setState({
        isShowPreviewDocumentary: true,
        previewDataModal: item
      })
    },
    onClickClosePreview: () => {
      this.setState({
        isShowPreviewDocumentary: false,
      })
    },
    onChangeSelectSpecifyCompanyName: (ev, groupData, indexGroup) => {
      const {
        data,
        handleUpdateProductGroupData,
      } = this.props;
      const isChecked = ev.target.checked;
      data.productGroupList[indexGroup].isSelectedSpecifyCompanyName = isChecked;
      handleUpdateProductGroupData(data.productGroupList);
    },
    onChangeSpecifyCompanyName: (ev, groupData, indexGroup) => {
      const {
        data,
        handleUpdateProductGroupData
      } = this.props;
      const value = ev.target.value;
      data.productGroupList[indexGroup].specifyCompanyName = value;
      handleUpdateProductGroupData(data.productGroupList);
    },
    getIsCanClickSaveButton: () => {
      const {
        data,
      } = this.props;
      let isPass = true;
      data.productGroupList.forEach(e => {
        if ((e.documentaryList || []).filter(ed => ed.isSelected).length === 0) {
          isPass = false
        }
      })
      return isPass
    },
    onClickSubmit: () => {
      this.setState({
        isShowConfirmCompanyName: true,
      })
    },
    onClickCloseConfirmCompanyName: () => {
      this.setState({
        isShowConfirmCompanyName: false,
      })
    },
    handleSubmit: () => {
      const {
        onClickSave,
      } = this.props;
      this.generateDocumentary.onClickCloseConfirmCompanyName();
      onClickSave();
    },
    onClickViewWarranty: (index) => {
      const {
        data,
      } = this.props;
      const dataDetail = data.templateList[index];
      this.setState({
        dataDetail,
        isPreviewWarranty: true,
      });
    },
    onClickEdit: () => {
      const {
        onClickEdit,
      } = this.props;
      this.generateDocumentary.onClickCloseViewWarranty();
      onClickEdit();
    },
    onClickCloseViewWarranty: () => {
      this.setState({
        isPreviewWarranty: false,
      })
    }
  }

  render() {
    const {
      data,
      isSelectDocumentary,
      onClickBack,
      onClickSave,
      getTemplateFormName,
      isShowSaveDraft,
      onClickSaveDraft,
    } = this.props;
    const {
      isShowPreviewDocumentary,
      previewDataModal,
      isShowConfirmCompanyName,
      isPreviewWarranty,
      dataDetail,
    } = this.state;
    console.log('data', data)
    return (
      <React.Fragment>
        {/* RL-703 */}
        <ShapeContainer
          ui='contentStep'
          justify='space-between'
        >
          {isSelectDocumentary ?
            // Step: Documentary Information
            <React.Fragment>
              <Section spacingBottom='45'>
                {data.productGroupList.map((e, i) => {
                  return (
                    <Section spacingBottom='15' key={i}>
                      <AccordionControl outline>
                        <Accordion accordion>
                          <AccordionItem
                            expanded={false}
                            className={ClassNames(
                              'accordion__item',
                              { 'is-error': false },
                              { 'is-success': (e.documentaryList || []).filter(ed => ed.isSelected).length > 0 },
                            )}
                          >
                            <AccordionItemTitle>
                              <AccordionControl.ButtonOutline
                                name={e.productGroupName}
                              />
                            </AccordionItemTitle>
                            <AccordionItemBody>
                              <ShapeContainer
                                fluid
                                padding='15'
                                bgColor={VARIABLES.COLORS.BLUE_5}
                                borderRadius='5'
                              >
                                <Section spacingBottom='30'>
                                  <TitleSection name={getLanguage('warrantyManage.form.documentary.title.selectDocumentary', '')} />
                                </Section>
                                <Section justify='center' spacingBottom='30'>
                                  <Section maxWidth='980'>
                                    <Grid gutter='60' gutterVertical='30'>
                                      {(e.documentaryList || []).map((ed, id) => {
                                        return (
                                          <Grid.Column grid='3' key={id}>
                                            <DocumentCard
                                              isSelected={ed.isSelected}
                                              isIconMoreLines={false}
                                              documentLogoSrc={ed.docMsPathLogoFile.path}
                                              documentCategory={ed.docMsCate}
                                              documentCode={ed.docMsCode}
                                              documentName={ed.docMsName}
                                              onChangeCheckbox={(ev) => {
                                                this.generateDocumentary.onChangeSelected(ev, e, i, ed, id)
                                              }}
                                              onClickPreview={() => { this.generateDocumentary.onClickPreviewDocumentary(ed) }}
                                            />
                                          </Grid.Column>
                                        )
                                      })}
                                    </Grid>
                                  </Section>
                                </Section>
                                <Section spacingBottom='30'>
                                  <TitleSection name={getLanguage('warrantyManage.form.documentary.title.specifyCompanyName', '')} />
                                </Section>
                                <Section>
                                  <Grid gutter='15' align='center'>
                                    <Grid.Column>
                                      <Grid gutter='10' align='center'>
                                        <Grid.Column>
                                          <Field
                                            ui='checkboxTickLabelBlack'
                                            checked={e.isSelectedSpecifyCompanyName || false}
                                            onChange={(ev) => {
                                              this.generateDocumentary.onChangeSelectSpecifyCompanyName(ev, e, i)
                                            }}
                                          />
                                        </Grid.Column>
                                        <Grid.Column>
                                          <Text regular14 color={e.isSelectedSpecifyCompanyName ? VARIABLES.COLORS.BLACK : VARIABLES.COLORS.GRAY_19}>
                                            {getLanguage('warrantyManage.form.documentary.label.specifyCompanyNameInDocument', '')}
                                          </Text>
                                        </Grid.Column>
                                      </Grid>
                                    </Grid.Column>
                                    <Grid.Column>
                                      <Field
                                        ui='blockLabelVertical'
                                        error={false}
                                        disabled={!e.isSelectedSpecifyCompanyName}
                                        width='450'
                                        placeholder={getLanguage('warrantyManage.form.documentary.placeholder.typeCompanyName', '')}
                                        value={e.specifyCompanyName || ''}
                                        onChange={(ev) => {
                                          this.generateDocumentary.onChangeSpecifyCompanyName(ev, e, i)
                                        }}
                                      />
                                    </Grid.Column>
                                  </Grid>
                                </Section>
                              </ShapeContainer>
                            </AccordionItemBody>
                          </AccordionItem>
                        </Accordion>
                      </AccordionControl>
                    </Section>
                  )
                })}
              </Section>
              <Section>
                <Grid
                  gutter='15'
                  gutterVertical='15'
                  justify='flex-end'
                  align='center'
                >
                  <Grid.Column>
                    <Button
                      gray
                      ui='back'
                      name={getLanguage('field.buttonBack', '')}
                      width={VARIABLES.BUTTON.WIDTHS.W_135}
                      onClick={onClickBack}
                    />
                  </Grid.Column>
                  {isShowSaveDraft &&
                    <Grid.Column>
                      <Button
                        lightBlue
                        name={getLanguage('field.buttonSaveAsDraft', '')}
                        onClick={onClickSaveDraft}
                      />
                    </Grid.Column>
                  }
                  <Grid.Column>
                    <Button
                      blue
                      disabled={!this.generateDocumentary.getIsCanClickSaveButton()}
                      name={getLanguage('field.buttonSaveAndContinue', '')}
                      width={170}
                      onClick={this.generateDocumentary.onClickSubmit}
                    />
                  </Grid.Column>
                </Grid>
              </Section>
            </React.Fragment>
            :
            // Step: Documentary
            isPreviewWarranty ?
              <React.Fragment>
                <Section spacingBottom='45'>
                  <Section spacingBottom='45'>
                    <TitleSection name={getLanguage('warrantyManage.form.documentary.title.productWarrantyAndDocumentary', '')} />
                  </Section>
                  <Section
                    ui='googleViewer'
                    spacingBottom='30'
                    isViewerUploading={dataDetail.isViewerUploading}
                    isViewerLoading={dataDetail.isViewerLoading}
                    url={dataDetail.fileUrl}
                  />
                </Section>
                <Section>
                  <Grid
                    gutter='15'
                    gutterVertical='15'
                    justify='flex-end'
                    align='center'
                  >
                    <Grid.Column>
                      <Button
                        gray
                        ui='back'
                        name={getLanguage('field.buttonBack', '')}
                        width={VARIABLES.BUTTON.WIDTHS.W_135}
                        onClick={this.generateDocumentary.onClickCloseViewWarranty}
                      />
                    </Grid.Column>
                    <Grid.Column>
                      <Button
                        lightBlue
                        ui='frontIcon'
                        name={getLanguage('field.buttonEdit', '')}
                        width={VARIABLES.BUTTON.WIDTHS.W_135}
                        iconWidth='13'
                        iconHeight='13'
                        iconSrc={ICONS['ic-edit.svg']}
                        onClick={this.generateDocumentary.onClickEdit}
                      />
                    </Grid.Column>
                  </Grid>
                </Section>
              </React.Fragment>
              :
              <React.Fragment>
                <Section spacingBottom='45'>
                  <Section spacingBottom='15'>
                    {data.templateList.map((e, i) => {
                      // CR: 1497, 1498
                      if (!isValidTemplateSegmentSpecial(data, e) || !isValidTemplateExtendYear(data, e)) {
                        return null
                      }
                      return (
                        <Section spacingBottom='15' key={i}>
                          <ShapeContainer
                            ui='warrantyList'
                            warrantyName={getTemplateFormName(e)}
                            onClickWarrantyPreview={() => {
                              this.generateDocumentary.onClickViewWarranty(i)
                            }}
                          />
                        </Section>
                      )
                    })}
                  </Section>
                </Section>
                <Section>
                  <Grid
                    gutter='15'
                    gutterVertical='15'
                    justify='flex-end'
                    align='center'
                  >
                    <Grid.Column>
                      <Button
                        gray
                        ui='back'
                        name={getLanguage('field.buttonBack', '')}
                        width={VARIABLES.BUTTON.WIDTHS.W_135}
                        onClick={onClickBack}
                      />
                    </Grid.Column>
                    {isShowSaveDraft &&
                      <Grid.Column>
                        <Button
                          lightBlue
                          name={getLanguage('field.buttonSaveAsDraft', '')}
                          onClick={onClickSaveDraft}
                        />
                      </Grid.Column>
                    }
                    <Grid.Column>
                      <Button
                        blue
                        name={getLanguage('field.buttonConfirmAndSAVE', '')}
                        width={170}
                        onClick={onClickSave}
                      />
                    </Grid.Column>
                  </Grid>
                </Section>
              </React.Fragment>
          }
        </ShapeContainer>

        {/* Modal : Confirm Proceed to The Previous Step */}
        {false &&
          <ModalConfirmContainer
            isOpenModal
            title={getLanguage('warrantyManage.form.documentary.title.confirmPeviousStep', '')}
            message={getLanguage('warrantyManage.form.documentary.message.confirmPeviousStep', '')}
            messageWarning={getLanguage('warrantyManage.form.documentary.message.warningPeviousStep', '')}
            messageAlign='center'
            buttonSubmitName={getLanguage('field.buttonConfirm', '')}
            onClickCancel={() => { }}
            onClickConfirm={() => { }}
          />
        }

        {/* Modal : Confirm Company Name */}
        {isShowConfirmCompanyName &&
          <ModalConfirmContainer
            isOpenModal
            title={getLanguage('warrantyManage.form.documentary.title.confirmCompanyName', '')}
            message={getLanguage('warrantyManage.form.documentary.message.confirmCompanyName', '')}
            messageWarning={getLanguage('warrantyManage.form.documentary.message.warningCompanyName1', '')}
            messageWarningSecondary={getLanguage('warrantyManage.form.documentary.message.warningCompanyName2', '')}
            messageAlign='center'
            orderList={data.productGroupList}
            buttonSubmitName={getLanguage('field.buttonConfirm', '')}
            onClickCancel={this.generateDocumentary.onClickCloseConfirmCompanyName}
            onClickConfirm={this.generateDocumentary.handleSubmit}
          />
        }

        {/* Modal : Preview Pdf */}
        {isShowPreviewDocumentary &&
          <ModalPreviewPdfContainer
            isOpenModal
            title={getLanguage('warrantyManage.form.documentary.title.previewDocument', '')}
            pdfSrc={previewDataModal.docMsPathFileObject.path}
            onClickCancel={this.generateDocumentary.onClickClosePreview}
            onClickConfirm={this.generateDocumentary.onClickClosePreview}
          />
        }
      </React.Fragment>
    )
  }
}
