
import axios from 'axios'
import { BASE_API, BASE_PATH_API, BASE_PORT_API } from '../config/config'
import { isShowLoading, toast } from '../helpers/functions/main';
import _ from 'lodash';
import { handleCheckMaintenance } from '../helpers/functions/auth';
import { storeGetState } from '../stores';
import { handleValidateAuth } from '../helpers/functions/auth';

const getConfig = (configService, params) => {
  const config = {
    baseURL: configService.baseApiPath ? configService.baseApiPath : BASE_API + BASE_PORT_API + BASE_PATH_API,
    onUploadProgress: (progressEvent) => {
      if (configService.onUploadProgress) {
        configService.onUploadProgress(Math.round(progressEvent.loaded * 100 / progressEvent.total));
      }
    },
    onDownloadProgress: (progressEvent) => {
      if (configService.onDownloadProgress) {
        configService.onDownloadProgress(Math.round(progressEvent.loaded * 100 / progressEvent.total));
      }
    },
    headers: {
    },
    params: configService.params,
    data: configService.data,
  }
  return config
}

const axiosSuccess = (res, configService) => {
  console.log('axiosSuccess', res);
  const _isShowLoading = configService.isShowLoading !== undefined ? configService.isShowLoading : true;
  if (_isShowLoading) {
    isShowLoading(false);
  }
  return res.data
}

const axiosError = (error, configService) => {
  const _isShowLoading = configService.isShowLoading !== undefined ? configService.isShowLoading : true;
  if (_isShowLoading) {
    isShowLoading(false);
  }
  console.log('error', { error });
  const message = _.get(error, 'response.data.message', error.message);
  const _isShowError = configService.isShowError !== undefined ? configService.isShowError : true;
  if (_isShowError) {
    toast.error(message);
  }
  return false
}

const handleConfigService = (configService) => {
  const _isShowLoading = configService.isShowLoading !== undefined ? configService.isShowLoading : true;
  if (_isShowLoading) {
    isShowLoading(true);
  }
}

const axiosService = (type, url, params, configService) => {
  const config = getConfig(configService, params);
  handleConfigService(configService);
  if (!handleValidateAuth()) {
    return false;
  }
  if (configService.urlParams) {
    Object.keys(configService.urlParams).forEach((key, i) => {
      if (i === 0) {
        url = `${url}?`
      }
      else {
        url = `${url}&`
      }
      url = `${url}${key}=${configService.urlParams[key]}`
    })
  }
  console.log('params', params, url);
  switch (type) {
    case 'get': return axios.get(url, config).then(res => axiosSuccess(res, configService)).catch(err => axiosError(err, configService))
    case 'post': return axios.post(url, params, config).then(res => axiosSuccess(res, configService)).catch(err => axiosError(err, configService))
    case 'put': return axios.put(url, params, config).then(res => axiosSuccess(res, configService)).catch(err => axiosError(err, configService))
    case 'patch': return axios.patch(url, params, config).then(res => axiosSuccess(res, configService)).catch(err => axiosError(err, configService))
    case 'delete': return axios.delete(url, config).then(res => axiosSuccess(res, configService)).catch(err => axiosError(err, configService))
    default: return false
  }
}


export default (configService = {}) => {
  const maintenanceData = storeGetState().mainRedux.maintenance;
  const isCanUseApp = handleCheckMaintenance();
  const createAxiosService = (type, url, params) => {
    if (isCanUseApp) {
      return axiosService(type, url, params, configService)
    } else {
      // check call login
      if (maintenanceData.apiBeforeLoginWhiteList.indexOf(url) >= 0) {
        return axiosService(type, url, params, configService)
      }
    }

    return false
  }

  return {
    get: (url, params) => createAxiosService('get', url, params),
    post: (url, params) => createAxiosService('post', url, params),
    put: (url, params) => createAxiosService('put', url, params),
    patch: (url, params) => createAxiosService('patch', url, params),
    delete: (url, params) => createAxiosService('delete', url, params),
  }
}