import React from 'react'
import ClassNames from 'classnames'
import PropTypes from 'prop-types'
import {
  WarrantyGeneratePdfPageWrapper
} from './styled'
import {
  Grid,
  Image,
  Section,
  Text,
} from './../../../components'
import {
  default as VARIABLES
} from './../../../themes/styles/bases/variables'
// import {
//   default as TYPOGRAPHYS
// } from './../../../themes/styles/bases/typographys'
// import {
//   default as UTILITIES
// } from './../../../themes/styles/helpers/utilities'

/**
 * TemplateBase description:
 * - WarrantyGeneratePdfPage
 */

class WarrantyGeneratePdfPageList extends React.PureComponent {
  render () {
    const {
      className,
      children,
      isDocument,
    } = this.props

    // props for css classes
    const classes = ClassNames(
      'wgpp-list',
      {'is-document': isDocument},
      className
    )

    return (
      <div
        className={classes}
      >
        <div className='jodit-wysiwyg'>
          {children}
        </div>
      </div>
    )
  }

  static defaultProps = {
    // * If you set new default props, you should create propsType for correct styleguide document.
  }

  static propTypes = { // TYPE > node, string, number, bool, array, object, symbol, func
    /**
    * Additional classes
    */
    className: PropTypes.string
  }
}

export class WarrantyGeneratePdfPage extends React.PureComponent {
  render () {
    const {
      className,
      children,
      isHidden,
      isPreview,
    } = this.props

    // props for css classes
    const classes = ClassNames(
      'warranty-generate-pdf-page',
      {'is-hide': isHidden},
      {'is-preview': isPreview},
      className
    )

    return (
      <WarrantyGeneratePdfPageWrapper
        className={classes}
      >
        {children}
      </WarrantyGeneratePdfPageWrapper>
    )
  }

  static List = WarrantyGeneratePdfPageList

  static defaultProps = {
    // * If you set new default props, you should create propsType for correct styleguide document.
  }

  static propTypes = { // TYPE > node, string, number, bool, array, object, symbol, func
    /**
    * Additional classes
    */
    className: PropTypes.string
  }
}
