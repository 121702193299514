import React from 'react';
import {
  connect,
} from 'react-redux';
import {
  ShapeContainer,
  Section,
  TableAction,
  Grid,
  Text,
  Button,
  PaginationControl,
  Table,
} from './../../components';
import {
  VARIABLES,
  ICONS
} from './../../themes';
import { getSaleTransList, getClearSaleTransParams } from '../../helpers/services/saleTrans';
import { onOpenModal, toast } from '../../helpers/functions/main';
import { saleTransController } from '../../apiService/apiController/saleTransService';
import { isValidResponse } from '../../helpers/functions/validation';
import { MODAL_TYPE } from '../../helpers/constants/main';
import { getSortAscending } from '../../helpers/functions/data';
import _ from 'lodash';
import { getLanguage } from '../../helpers/functions/language';

class SaleTransactionHistoryIndoListContainer extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
    }
  }

  historySaleTransList = this.props.historySaleTransList;

  render() {
    const {
      dataList,
      dataParams,
    } = this.props;
    return (
      <Table
        striped
        action
      >
        <Table.Section>
          <Table.Row>
            <Table.HeadColumn
              maxWidth={VARIABLES.TABLE.INFO_WIDTHS.DATE}
              minWidth={VARIABLES.TABLE.INFO_WIDTHS.DATE}
              sortAscending={getSortAscending(dataParams, 'create_at')}
              onClickSort={() => {
                this.historySaleTransList.onClickSort('create_at desc');
              }}
            >
              {getLanguage('container.saleTransactionHistory.table.header.date', '')}
            </Table.HeadColumn>
            <Table.HeadColumn
              maxWidth={VARIABLES.TABLE.INFO_WIDTHS.ST_ID}
              minWidth={VARIABLES.TABLE.INFO_WIDTHS.ST_ID}
              sortAscending={getSortAscending(dataParams, 'name')}
              onClickSort={() => {
                this.historySaleTransList.onClickSort('name desc');
              }}
              title={getLanguage('container.saleTransactionHistory.table.header.stID', '')}
            >
              {getLanguage('container.saleTransactionHistory.table.header.stIDAbbreviation', '')}
            </Table.HeadColumn>
            <Table.HeadColumn
              minWidth={VARIABLES.TABLE.COLUMN_WIDTHS.NAMES.PROFILE_TYPE_ABBREVIATION}
              sortAscending={getSortAscending(dataParams, 'profile_type__c')}
              onClickSort={() => {
                this.historySaleTransList.onClickSort('profile_type__c desc');
              }}
              title={getLanguage('container.saleTransactionHistory.table.header.profileType', '')}
            >
              {getLanguage('container.saleTransactionHistory.table.header.profileTypeAbbreviation', '')}
            </Table.HeadColumn>
            <Table.HeadColumn
              maxWidth={VARIABLES.TABLE.COLUMN_WIDTHS.NAMES.PRODUCT_GROUP_ABBREVIATION}
              minWidth={VARIABLES.TABLE.COLUMN_WIDTHS.NAMES.PRODUCT_GROUP_ABBREVIATION}
              sortAscending={getSortAscending(dataParams, 'f_bsproductgroupname__c')}
              onClickSort={() => {
                this.historySaleTransList.onClickSort('f_bsproductgroupname__c desc');
              }}
              title={getLanguage('container.saleTransactionHistory.table.header.productGroup', '')}
            >
              {getLanguage('container.saleTransactionHistory.table.header.productGroupAbbreviation', '')}
            </Table.HeadColumn>
            <Table.HeadColumn
              maxWidth={VARIABLES.TABLE.INFO_WIDTHS.CUSTOMER_NAME_ABBREVIATION}
              minWidth={VARIABLES.TABLE.INFO_WIDTHS.CUSTOMER_NAME_ABBREVIATION}
              sortAscending={getSortAscending(dataParams, 'customer_name')}
              onClickSort={() => {
                this.historySaleTransList.onClickSort('customer_name desc');
              }}
              title={getLanguage('container.saleTransactionHistory.table.header.customerName', '')}
            >
              {getLanguage('container.saleTransactionHistory.table.header.customerNameAbbreviation', '')}
            </Table.HeadColumn>
            <Table.HeadColumn
              maxWidth={VARIABLES.TABLE.INFO_WIDTHS.PHONE}
              minWidth={VARIABLES.TABLE.INFO_WIDTHS.PHONE}
              sortAscending={getSortAscending(dataParams, 'phone_no')}
              onClickSort={() => {
                this.historySaleTransList.onClickSort('phone_no desc');
              }}
            >
              {getLanguage('container.saleTransactionHistory.table.header.phoneNo', '')}
            </Table.HeadColumn>
            <Table.HeadColumn
              alignRight
              maxWidth={VARIABLES.TABLE.COLUMN_WIDTHS.MEASUREMENTS.LENGTH_NUMBER_ABBREVIATION}
              minWidth={VARIABLES.TABLE.COLUMN_WIDTHS.MEASUREMENTS.LENGTH_NUMBER_ABBREVIATION}
              sortAscending={getSortAscending(dataParams, 'profile_length__c')}
              onClickSort={() => {
                this.historySaleTransList.onClickSort('profile_length__c desc');
              }}
            >
              {getLanguage('container.saleTransactionHistory.table.header.length', '')}
            </Table.HeadColumn>
            <Table.HeadColumn
              alignRight
              maxWidth={VARIABLES.TABLE.COLUMN_WIDTHS.MEASUREMENTS.QUANTITY_UNIT}
              minWidth={VARIABLES.TABLE.COLUMN_WIDTHS.MEASUREMENTS.QUANTITY_UNIT}
              sortAscending={getSortAscending(dataParams, 'profile_quantity__c')}
              onClickSort={() => {
                this.historySaleTransList.onClickSort('profile_quantity__c desc');
              }}
            >
              {getLanguage('container.saleTransactionHistory.table.header.orderQuantity', '')}
            </Table.HeadColumn>
            <Table.HeadColumn
              alignRight
              maxWidth={VARIABLES.TABLE.INFO_WIDTHS.TB_POINTS}
              minWidth={VARIABLES.TABLE.INFO_WIDTHS.TB_POINTS}
              sortAscending={getSortAscending(dataParams, 'points')}
              onClickSort={() => {
                this.historySaleTransList.onClickSort('points desc');
              }}
              title={getLanguage('container.saleTransactionHistory.table.header.tbPoints', '')}
            >
              {getLanguage('container.saleTransactionHistory.table.header.tbPointsAbbreviation', '')}
            </Table.HeadColumn>
            <Table.HeadColumn
              maxWidth={VARIABLES.TABLE.INFO_WIDTHS.SALESPERSON_ABBREVIATION}
              minWidth={VARIABLES.TABLE.INFO_WIDTHS.SALESPERSON_ABBREVIATION}
              sortAscending={getSortAscending(dataParams, 'sales_person_name')}
              onClickSort={() => {
                this.historySaleTransList.onClickSort('sales_person_name desc');
              }}
            >
              {getLanguage('container.saleTransactionHistory.table.header.salesperson', '')}
            </Table.HeadColumn>
            <Table.HeadColumn
              noSort
              alignCenter
              maxWidth={VARIABLES.TABLE.INFO_WIDTHS.ACTION_DELETE}
              minWidth={VARIABLES.TABLE.INFO_WIDTHS.ACTION_DELETE}
            >
              {getLanguage('container.saleTransactionHistory.table.header.action', '')}
            </Table.HeadColumn>
          </Table.Row>
        </Table.Section>
        <Table.Section>
          {dataList.map((e, i) => {
            return (
              <Table.Row
                key={i}
              >
                <Table.BodyColumn
                  info
                  maxWidth={VARIABLES.TABLE.INFO_WIDTHS.DATE}
                  minWidth={VARIABLES.TABLE.INFO_WIDTHS.DATE}
                >
                  {e.displayCreateAt}
                </Table.BodyColumn>
                <Table.BodyColumn
                  info
                  maxWidth={VARIABLES.TABLE.INFO_WIDTHS.ST_ID}
                  minWidth={VARIABLES.TABLE.INFO_WIDTHS.ST_ID}
                  title={e.name}
                >
                  {e.name}
                </Table.BodyColumn>
                <Table.BodyColumn
                  info
                  minWidth={VARIABLES.TABLE.COLUMN_WIDTHS.NAMES.PROFILE_TYPE_ABBREVIATION}
                  title={e.profileType}
                >
                  {e.profileType}
                </Table.BodyColumn>
                <Table.BodyColumn
                  info
                  maxWidth={VARIABLES.TABLE.COLUMN_WIDTHS.NAMES.PRODUCT_GROUP_ABBREVIATION}
                  minWidth={VARIABLES.TABLE.COLUMN_WIDTHS.NAMES.PRODUCT_GROUP_ABBREVIATION}
                  title={e.fBsproductgroupname}
                >
                  {e.fBsproductgroupname}
                </Table.BodyColumn>
                <Table.BodyColumn
                  info
                  maxWidth={VARIABLES.TABLE.INFO_WIDTHS.CUSTOMER_NAME_ABBREVIATION}
                  minWidth={VARIABLES.TABLE.INFO_WIDTHS.CUSTOMER_NAME_ABBREVIATION}
                  title={e.customerName}
                >
                  {e.customerName}
                </Table.BodyColumn>
                <Table.BodyColumn
                  phone
                  maxWidth={VARIABLES.TABLE.INFO_WIDTHS.PHONE}
                  minWidth={VARIABLES.TABLE.INFO_WIDTHS.PHONE}
                  idPhone={e.phoneNoCountry.value}
                  srcFlag={e.phoneNoCountry.flag}
                >
                  {e.phoneNo}
                </Table.BodyColumn>
                <Table.BodyColumn
                  info
                  alignRight
                  maxWidth={VARIABLES.TABLE.COLUMN_WIDTHS.MEASUREMENTS.LENGTH_NUMBER_ABBREVIATION}
                  minWidth={VARIABLES.TABLE.COLUMN_WIDTHS.MEASUREMENTS.LENGTH_NUMBER_ABBREVIATION}
                >
                  {e.profileLengthWithComma}
                </Table.BodyColumn>
                <Table.BodyColumn
                  info
                  alignRight
                  maxWidth={VARIABLES.TABLE.COLUMN_WIDTHS.MEASUREMENTS.QUANTITY_UNIT}
                  minWidth={VARIABLES.TABLE.COLUMN_WIDTHS.MEASUREMENTS.QUANTITY_UNIT}
                >
                  {`${e.profileQuantityWithComma} ${e.profileTypeUnit}`}
                </Table.BodyColumn>
                <Table.BodyColumn
                  info
                  alignRight
                  maxWidth={VARIABLES.TABLE.INFO_WIDTHS.TB_POINTS}
                  minWidth={VARIABLES.TABLE.INFO_WIDTHS.TB_POINTS}
                  title={e.pointsWithComma}
                >
                  {e.pointsWithComma}
                </Table.BodyColumn>
                <Table.BodyColumn
                  info
                  maxWidth={VARIABLES.TABLE.INFO_WIDTHS.SALESPERSON_ABBREVIATION}
                  minWidth={VARIABLES.TABLE.INFO_WIDTHS.SALESPERSON_ABBREVIATION}
                  title={e.salesPersonName}
                >
                  {e.salesPersonName}
                </Table.BodyColumn>
                <Table.BodyColumn
                  action
                  alignCenter
                  maxWidth={VARIABLES.TABLE.INFO_WIDTHS.ACTION_DELETE}
                  minWidth={VARIABLES.TABLE.INFO_WIDTHS.ACTION_DELETE}
                >
                  {
                    e.isShowDelete &&
                    <Button
                      red
                      ui='frontIconSmall'
                      name={getLanguage('container.saleTransactionHistory.table.action.buttonDelete', '')}
                      iconWidth='8'
                      iconHeight='10'
                      iconSrc={ICONS['ic-delete.svg']}
                      onClick={() => {
                        this.historySaleTransList.onClickDelete(e);
                      }}
                    />
                  }
                </Table.BodyColumn>
              </Table.Row>
            )
          })
          }
        </Table.Section>
      </Table>
    )
  }
}

const mapStateToProps = (state) => {
  const dataRedux = state.dataRedux;
  return {
    dataList: dataRedux.saleTransList,
    dataParams: dataRedux.saleTransParams,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
  }
}

export const SaleTransactionHistoryIndoListContainerConnected = connect(
  mapStateToProps,
  mapDispatchToProps
)(SaleTransactionHistoryIndoListContainer)