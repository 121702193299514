import moment from "moment";
import React from "react";
import {
  Breadcrumb,
  Button,
  DatePickerControl,
  Field,
  Grid,
  Section,
  Text,
  TitlePage
} from "../../components";
import { ProjectDocumentDocumentaryListContainerConnected } from "../../containers/NotificationManagementListContainer";
import { DATE_PICKER_FORMAT } from "../../helpers/constants/data";
import { ROUTE_PATH } from "../../helpers/constants/route";
import { getUserAuthRedux } from "../../helpers/functions/auth";
import { getLanguage } from "../../helpers/functions/language";
import { redirect, setSideMenuActive } from "../../helpers/functions/route";
import { ICONS, VARIABLES } from "../../themes";
import { getClearNotificationManagementAcceptSearchParams, getNotificationManagementAcceptSearchList } from "../../helpers/services/notificationManagement";
import { getDateApiRequet } from "../../helpers/functions/data";

export class NotificationManagementScreen extends React.Component {
  constructor(props) {
    super(props);
    setSideMenuActive(props);
    const userAuth = getUserAuthRedux();

    this.state = {
      userAuth,

      startDate: moment().startOf('months').toDate(),
      endDate: moment().endOf('months').toDate(),
      searchValue: '',
    };
  }

  notificationManagement = {
    onClickSearch: async () => {
      const {
        searchValue,
        startDate,
        endDate,
      } = this.state;
      const params = {
        ...getClearNotificationManagementAcceptSearchParams(),
        page: 1,
        text_search: searchValue,
        start_date: moment(startDate).startOf('days').toDate(),
        end_date: moment(endDate).endOf('days').toDate(),
      };
      getNotificationManagementAcceptSearchList(params);
    }
  }

  input = {
    onChangeInput: (ev) => {
      const name = ev.target.name;
      const value = ev.target.value;
      this.setState({
        [name]: value,
      });
    },
    onChangeDate: (date, name) => {
      const {
        endDate,
        startDate,
      } = this.state;
      if (name === 'startDate') {
        if (endDate) {
          if (endDate.getTime() < date.getTime()) {
            this.setState({
              endDate: date,
            })
          }
        }
        else {
          this.setState({
            endDate: date,
          })
        }
      }
      if (name === 'endDate' && !startDate) {
        this.setState({
          startDate: date,
        })
      }
      this.setState({
        [name]: date
      })
    }
  }

  render() {
    const {
      userAuth,
      startDate,
      endDate,
      searchValue
    } = this.state;
    return (
      <React.Fragment>
        <Breadcrumb>
          <Breadcrumb.List
            link
            name={getLanguage("notificationManagement.breadcrumb1", "")}
            onClick={() => {
              redirect(ROUTE_PATH.HOME.LINK);
            }}
          />

          <Breadcrumb.List name="/" />
          <Breadcrumb.List
            name={getLanguage("notificationManagement.breadcrumb2", "")}
          />
        </Breadcrumb>
        <Section ui="content">
          <Section spacingBottom="35">
            <Grid gutterVertical="15" justify="space-between">
              <Grid.Column>
                <TitlePage
                  src={ICONS["ic-page-notification-management.svg"]}
                  name={getLanguage("notificationManagement.title", "")}
                />
              </Grid.Column>
              <Grid.Column>
                <Grid gutter="10" gutterVertical='15'>
                  <Grid.Column>
                    <Grid gutter="20">
                      <Grid.Column>
                        <Section paddingTop="6">
                          <Text regular12 color={VARIABLES.COLORS.GRAY_19}>
                            {getLanguage(
                              "notificationManagement.label.from",
                              ""
                            )}
                          </Text>
                        </Section>
                      </Grid.Column>
                      <Grid.Column>
                        <Grid gutter="15">
                          <Grid.Column>
                            <DatePickerControl
                              filter
                              dateFormat={DATE_PICKER_FORMAT}
                              placeholderText={getLanguage(
                                "notificationManagement.label.selectStartDate",
                                ""
                              )}
                              selected={startDate}
                              onChange={(date) => {
                                this.input.onChangeDate(date, 'startDate');
                              }}
                            />
                          </Grid.Column>
                          <Grid.Column>
                            <Section paddingTop="6">
                              <Text regular12 color={VARIABLES.COLORS.GRAY_19}>
                                {getLanguage(
                                  "notificationManagement.label.to",
                                  ""
                                )}
                              </Text>
                            </Section>
                          </Grid.Column>
                          <Grid.Column>
                            <DatePickerControl
                              filter
                              dateFormat={DATE_PICKER_FORMAT}
                              placeholderText={getLanguage(
                                "notificationManagement.label.selectEndDate",
                                ""
                              )}
                              minDate={startDate}
                              selected={endDate}
                              onChange={(date) => {
                                this.input.onChangeDate(date, 'endDate');
                              }}
                            />
                          </Grid.Column>
                        </Grid>
                      </Grid.Column>
                    </Grid>
                  </Grid.Column>
                  <Grid.Column>
                    <Field
                      name="searchValue"
                      fluid
                      ui="blockLabelVerticalSearch"
                      placeholder={getLanguage(
                        "notificationManagement.field.placeholderSearch",
                        ""
                      )}
                      value={searchValue}
                      onChange={this.input.onChangeInput}
                    />
                  </Grid.Column>
                  <Grid.Column>
                    <Button
                      lightBlue
                      name={getLanguage(
                        "notificationManagement.button.buttonSearch",
                        ""
                      )}
                      width={VARIABLES.BUTTON.WIDTHS.W_105}
                      onClick={this.notificationManagement.onClickSearch}
                    />
                  </Grid.Column>
                </Grid>
              </Grid.Column>
            </Grid>
          </Section>
          <ProjectDocumentDocumentaryListContainerConnected />
        </Section>
      </React.Fragment>
    );
  }
}
