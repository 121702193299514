import React from 'react';
import {
  connect,
} from 'react-redux';
import {
  SelectControl
} from './../../components'
import { getSalePersonList, getClearSalePersonParams } from '../../helpers/services/salePerson';
import { getLanguage } from '../../helpers/functions/language';
import _ from 'lodash';
import { MAIN_REDUX_STATE_KEY } from '../../actions/mainAction';

class SelectSalePersonContainer extends React.Component {

  static defaultProp = {
    handleSetSelectSalePerson: () => { }
  }

  constructor(props) {
    super(props);
    this.state = {
      activePage: 1,
      dataPerPage: 0
    }
  }

  componentDidMount() {
    const {
      handleSetSelectSalePerson,
      userAuth,
    } = this.props;
    const {
      activePage,
      dataPerPage,
    } = this.state;
    const params = {
      ...getClearSalePersonParams(),
      page: activePage,
      limit: dataPerPage,
      authorize_dealer_id: userAuth.id,
    };
    const configService = {
      isShowLoading: false,
    }
    getSalePersonList(params, configService).then(dataList => {
      const {
        selectedOption,
      } = this.props;
      if (!selectedOption) {
        if (handleSetSelectSalePerson) {
          const selectOptionList = dataList.map(e => {
            return {
              value: e.id,
              label: e.fullname,
              data: e,
            }
          });
          handleSetSelectSalePerson(selectOptionList);
        }
      }
    });
  }

  render() {
    const {
      selectOptionList,
      selectedOption,
      onChange,
      disableSelectSalePerson,
      disableSelectSalePersonColor,
    } = this.props;
    return (
      <SelectControl
        verticalLabel
        placeholder={getLanguage('field.salespersonPlaceholder', '')}
        value={selectedOption}
        onChange={onChange}
        options={selectOptionList}
        disabled={disableSelectSalePerson}
        disabledColor={disableSelectSalePersonColor}
      />
    )
  }
}

const mapStateToProps = (state) => {
  const mainRedux = state.mainRedux;
  const dataRedux = state.dataRedux;
  const dataList = dataRedux.salePersonList;
  const selectOptionList = dataList.map(e => {
    return {
      value: e.id,
      label: e.fullname,
      data: e,
    }
  });
  return {
    dataList,
    selectOptionList,
    activePage: dataRedux.salePersonParams.activePage,
    totalData: dataRedux.salePersonParams.totalData || 0,
    userAuth: mainRedux[MAIN_REDUX_STATE_KEY.USER_AUTH],
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
  }
}

export const SelectSalePersonContainerConnected = connect(
  mapStateToProps,
  mapDispatchToProps
)(SelectSalePersonContainer)