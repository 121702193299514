import { displayWithPrefix, displayDate } from "../helpers/functions/display";
import { ENUM } from "../helpers/constants/enum";

export class ModelCMSUserManual {
  constructor(props = {}) {
    this.id = props.id;
    this.idWithPrefix = displayWithPrefix(props.id);
    this.name = props.name;
    this.description = props.description;
    this.createdAt = props.created_at;
    this.displayCreatedAt = displayDate(props.created_at);
    this.updatedDate = props.updated_at;
    this.displayUpdatedDate = displayDate(props.updated_at);
    this.displayUpdatedDate = this.displayUpdatedDate === 'Invalid date' ? '-' : this.displayUpdatedDate;
    this.status = props.status;
    this.isActive = props.status === ENUM.STATUS_ACTIVE.ACTIVE;
    this.app = props.app;
  }
}
