import React from 'react';
import {
  connect
} from 'react-redux';
import { bindActionCreators } from 'redux';
import { dataCreators } from '../../actions/dataAction';
import { ROUTE_PATH } from '../../helpers/constants/route';
import { getSortAscending } from '../../helpers/functions/data';
import { getLanguage } from '../../helpers/functions/language';
import { redirectData } from '../../helpers/functions/route';
import { getClearProjectDocumentCertificateSearchParams, getProjectDocumentCertificateSearchList } from '../../helpers/services/projectDocument';
import { ModalPreviewPdfContainer } from '../ModalContainer';
import {
  Button, Grid, Image, PaginationControl, Section, ShapeContainer, Table, TableAction, Text
} from './../../components';
import {
  ICONS,
  VARIABLES
} from './../../themes';
import { getLocalStorage } from '../../helpers/functions/localStorage';
import { LOCAL_STORAGE } from '../../helpers/constants/localStorage';
import { DATA } from '../../helpers/constants/data';

class ProjectDocumentCertificateListContainer extends React.Component {

  constructor(props) {
    super(props);
    const language = getLocalStorage(LOCAL_STORAGE.LANGUAGE_RBPTRUEBLUE);
    this.state = {
      activePage: 1,
      dataPerPage: 10,

      isShowPDFModal: false,
      dataPDF: undefined,
      certificateTypeList: ['pre', 'post'],

      isLanguageTH: language === DATA.LANGUAGE.THAI,
    }
  }

  componentDidMount() {
    const {
      projectDocumentId,
    } = this.props;
    const {
      activePage,
      dataPerPage,
    } = this.state;
    const params = {
      ...getClearProjectDocumentCertificateSearchParams(),
      page: activePage,
      limit: dataPerPage,
      pre_sales_trans_id: projectDocumentId,
      start_date: '',
      end_date: '',
    };
    getProjectDocumentCertificateSearchList(params);
  }

  projectDocumentCertificateList = {
    onClickSort: (target) => {
      getProjectDocumentCertificateSearchList({
        sort: target
      });
    },
    onClickView: (item, target) => {
      const {
        certificateTypeList,
      } = this.state;
      this.setState({
        isShowPDFModal: true,
        dataPDF: {
          title: getLanguage('projectDocument.modal.previewCertificate.title', '').replace('[productGroup]', item.preSalesTransM3ProdGrpName),
          fileUrl: target === certificateTypeList[0] ? item.fileUrl : item.filePostUrl,
        },
      })
    },
    onClickRegenerate: (item) => {
      const {
        projectDocumentData,
      } = this.props;
      redirectData(ROUTE_PATH.CERTIFICATE_MANAGE.LINK, {
        data: {
          ...projectDocumentData,
          certificateData: item,
          isRegenerate: true,
        }
      });
    }
  }

  modal = {
    onClickClosePreviewPDFModal: () => {
      this.setState({
        isShowPDFModal: false,
      })
    },
    onClickDownload: () => {
      const {
        dataPDF,
      } = this.state;
      const link = document.createElement('a');
      link.download = `${new Date().getTime()}.pdf`;
      link.target = '_blank';
      link.href = dataPDF.fileUrl;
      link.click();
    }
  }

  pagination = {
    onPaginationChange: (pageNumber) => {
      getProjectDocumentCertificateSearchList({
        isNoSort: true,
        page: pageNumber,
      });
    }
  }

  render() {
    const {
      dataList,
      dataParams,
      totalData,
      activePage,
      onClickBack,
    } = this.props;
    const {
      dataPerPage,
      isShowPDFModal,
      dataPDF,
      certificateTypeList,
      isLanguageTH,
    } = this.state;
    return (
      <React.Fragment>
        <ShapeContainer justify='space-between' fluid >
          <Section spacingBottom="40" >
            <Table striped overflowVisible>
              <Table.Section>
                <Table.Row>
                  <Table.HeadColumn
                    sortAscending={getSortAscending(dataParams, 'pre_sales_trans_cert_id')}
                    onClickSort={() => {
                      this.projectDocumentCertificateList.onClickSort('pre_sales_trans_cert_id desc');
                    }}
                    minWidth={175}
                    maxWidth={175}
                  >
                    {getLanguage('projectDocument.table.header.ID', '')}
                  </Table.HeadColumn>
                  <Table.HeadColumn
                    noSort
                    minWidth={175}
                  >
                    {getLanguage('projectDocument.table.header.nameDocument', '')}
                  </Table.HeadColumn>
                  <Table.HeadColumn
                    sortAscending={getSortAscending(dataParams, 'pre_sales_trans_m3_prod_grp_name')}
                    onClickSort={() => {
                      this.projectDocumentCertificateList.onClickSort('pre_sales_trans_m3_prod_grp_name desc');
                    }}
                    minWidth={175}
                    maxWidth={175}
                  >
                    {getLanguage('projectDocument.table.header.productGroup', '')}
                  </Table.HeadColumn>
                  <Table.HeadColumn
                    sortAscending={getSortAscending(dataParams, 'created_date')}
                    onClickSort={() => {
                      this.projectDocumentCertificateList.onClickSort('created_date desc');
                    }}
                    minWidth={225}
                    maxWidth={225}
                  >
                    {getLanguage('projectDocument.table.header.createdDate', '')}
                  </Table.HeadColumn>
                  <Table.HeadColumn
                    noSort
                    alignCenter
                    minWidth={75}
                    maxWidth={75}
                  >
                    {getLanguage('projectDocument.table.header.action', '')}
                  </Table.HeadColumn>
                </Table.Row>
              </Table.Section>
              {
                dataList.length > 0 ? (
                  <Section >
                    <Table.Section scroll>
                      {
                        dataList.map((e, i) => {
                          return (
                            <Table.Row key={i}>
                              <Table.BodyColumn
                                info
                                minWidth={175}
                                maxWidth={175}
                                title={e.displayCode}
                              >
                                {e.displayCode}
                              </Table.BodyColumn>
                              <Table.BodyColumn
                                info
                                minWidth={175}
                                title={e.displayDocMsName}
                              >
                                {e.displayDocMsName}
                              </Table.BodyColumn>
                              <Table.BodyColumn
                                info
                                minWidth={175}
                                maxWidth={175}
                                title={e.preSalesTransM3ProdGrpName}
                              >
                                {e.preSalesTransM3ProdGrpName}
                              </Table.BodyColumn>
                              <Table.BodyColumn
                                info
                                minWidth={225}
                                maxWidth={225}
                              >
                                {e.displayCreatedDate}
                              </Table.BodyColumn>
                              <Table.BodyColumn
                                action
                                alignCenter
                                minWidth={100}
                                maxWidth={100}
                              >
                                <Grid justify="center" gutter="15" align="center">
                                  {
                                    e.isCanRegenerate && (
                                      <Grid.Column>
                                        <Section relative top='-2'>
                                          <Image
                                            src={ICONS['ic-regenerate-file.svg']}
                                            widthRatio="12"
                                            widthRatioUnit="px"
                                            heightRatio="15"
                                            heightRatioUnit="px"
                                            onClick={() => {
                                              this.projectDocumentCertificateList.onClickRegenerate(e)
                                            }}
                                          />
                                        </Section>
                                      </Grid.Column>
                                    )
                                  }
                                  <Grid.Column>
                                    {
                                      e.isShowPreCertificate ?
                                        <Image
                                          src={ICONS['ic-certificate.svg']}
                                          widthRatio="12"
                                          widthRatioUnit="px"
                                          heightRatio="18"
                                          heightRatioUnit="px"
                                          onClick={() => {
                                            this.projectDocumentCertificateList.onClickView(e, certificateTypeList[0]);
                                          }}
                                        />
                                        :
                                        <Section width='12'>
                                          <Text
                                            regular12
                                            top='-5'
                                            color={VARIABLES.COLORS.GRAY_19}
                                          >
                                            -
                                          </Text>
                                        </Section>
                                    }
                                  </Grid.Column>
                                  {e.isShowPostCertificate ?
                                    (e.isPostCertificate ?
                                      <Grid.Column>
                                        <Image
                                          src={ICONS['ic-certificate-checked.svg']}
                                          widthRatio="15"
                                          widthRatioUnit="px"
                                          heightRatio="21"
                                          heightRatioUnit="px"
                                          onClick={() => {
                                            this.projectDocumentCertificateList.onClickView(e, certificateTypeList[1]);
                                          }}
                                        />
                                      </Grid.Column>
                                      :
                                      <Grid.Column>
                                        <ShapeContainer
                                          tooltips
                                        >
                                          <div className='tooltips-section'>
                                            <span>
                                              {isLanguageTH ?
                                                <Text
                                                  regular14
                                                  color={VARIABLES.COLORS.BLACK}
                                                >
                                                  {getLanguage('projectDocument.label.aSaleOrderForTheSameProductGroupIsPresent', '')}
                                                </Text>
                                                :
                                                <React.Fragment>
                                                  <Text
                                                    bold14
                                                    noWrap
                                                    color={VARIABLES.COLORS.GREEN_3}
                                                  >
                                                    {getLanguage('projectDocument.label.enabled', '')}
                                                    <Text
                                                      regular14
                                                      left='5'
                                                      color={VARIABLES.COLORS.BLACK}
                                                    >
                                                      {getLanguage('projectDocument.label.whenever', '')}
                                                    </Text>
                                                  </Text>
                                                  <Text
                                                    regular14
                                                    color={VARIABLES.COLORS.BLACK}
                                                  >
                                                    {getLanguage('projectDocument.label.aSaleOrderForTheSameProductGroupIsPresent', '')}
                                                  </Text>
                                                </React.Fragment>
                                              }
                                            </span>
                                          </div>
                                          <Image
                                            src={ICONS['ic-certificate-gray.svg']}
                                            widthRatio="15"
                                            widthRatioUnit="px"
                                            heightRatio="21"
                                            heightRatioUnit="px"
                                          />
                                        </ShapeContainer>
                                      </Grid.Column>
                                    ) :
                                    <Grid.Column>
                                      <Section width='12'>
                                        <Text
                                          regular12
                                          top='-5'
                                          color={VARIABLES.COLORS.GRAY_19}
                                        >
                                          -
                                        </Text>
                                      </Section>
                                    </Grid.Column>
                                  }
                                </Grid>
                              </Table.BodyColumn>
                            </Table.Row>
                          )
                        })
                      }
                    </Table.Section>
                  </Section>

                ) : (
                  <Section justify='center' paddingVertical='80'>
                    <Section justify='center' spacingBottom='8' >
                      <Image
                        src={ICONS['ic-lists-gray.svg']}
                        widthRatio="24"
                        widthRatioUnit="px"
                        heightRatio="24"
                        heightRatioUnit="px"

                      />
                    </Section>
                    <Text regular14 color={VARIABLES.COLORS.GRAY_12}>
                      {getLanguage('projectDocument.label.noCertificate', '')}
                    </Text>
                  </Section>
                )
              }
            </Table>
          </Section>
          <Section spacingBottom='40' >
            <Grid justify='flex-end'>
              <Grid.Column>
                <Section spacingBottom='10'>
                  <Grid gutter='30' justify='flex-end'>
                    <Grid.Column>
                      <Image
                        src={ICONS['ic-certificate.svg']}
                        widthRatio="12"
                        widthRatioUnit="px"
                        heightRatio="18"
                        heightRatioUnit="px"
                        onClick={() => { }}
                      />
                      <Text
                        bold12
                        color={VARIABLES.COLORS.PRIMARY_2}
                        left='5'
                      >
                        {getLanguage('projectDocument.label.preCertificate', '')}
                      </Text>
                    </Grid.Column>
                  </Grid>
                </Section>
                <Section spacingBottom='10'>
                  <Grid gutter='30' justify='flex-end'>
                    <Grid.Column>
                      <Image
                        src={ICONS['ic-certificate-checked.svg']}
                        widthRatio="15"
                        widthRatioUnit="px"
                        heightRatio="21"
                        heightRatioUnit="px"
                      />
                      <Text
                        bold12
                        color={VARIABLES.COLORS.PRIMARY_2}
                        left='5'
                      >
                        {getLanguage('projectDocument.label.postCertificate', '')}
                      </Text>
                    </Grid.Column>
                  </Grid>
                </Section>
                <Section spacingBottom='10'>
                  <Grid gutter='30' justify='flex-end'>
                    <Grid.Column>
                      <Text
                        bold12
                        color={VARIABLES.COLORS.PRIMARY_2}
                        left='5'
                      >
                        {getLanguage('projectDocument.label.postCertificate2', '')}
                      </Text>
                    </Grid.Column>
                  </Grid>
                </Section>
                <Section>
                  <Grid gutter='30' justify='flex-end'>
                    <Grid.Column>
                      <Text
                        bold12
                        color={VARIABLES.COLORS.PRIMARY_2}
                        left='5'
                      >
                        {getLanguage('projectDocument.label.postCertificate3', '')}
                      </Text>
                    </Grid.Column>
                  </Grid>
                </Section>
              </Grid.Column>
            </Grid>

          </Section>
          <TableAction>
            <TableAction.Left>
              <Button
                gray
                ui="back"
                name={getLanguage('field.buttonBack', '')}
                onClick={onClickBack}
              />
            </TableAction.Left>
            <TableAction.Right>
              <Grid gutter='20'>
                <Grid.Column>
                  <Section paddingTop='6'>
                    <Text
                      regular12
                      color={VARIABLES.COLORS.GRAY_16}
                    >
                      {getLanguage('pagination.page', '')} {activePage}/{Math.ceil(totalData / dataPerPage)}
                    </Text>
                  </Section>
                </Grid.Column>
                <Grid.Column>
                  <PaginationControl
                    activePage={activePage}
                    itemsCountPerPage={dataPerPage}
                    totalItemsCount={totalData}
                    pageRangeDisplayed={3}
                    onChange={this.pagination.onPaginationChange}
                  />
                </Grid.Column>
              </Grid>
            </TableAction.Right>
          </TableAction>
        </ShapeContainer>


        {isShowPDFModal &&
          <ModalPreviewPdfContainer
            isOpenModal
            ui='previewDownload'
            title={dataPDF?.title}
            pdfSrc={dataPDF?.fileUrl}
            onClickCancel={this.modal.onClickClosePreviewPDFModal}
            onClickDownload={this.modal.onClickDownload}
          />
        }
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => {
  const dataRedux = state.dataRedux;
  return {
    dataList: dataRedux.projectDocumentCertificateList,
    dataParams: dataRedux.projectDocumentCertificateParams,
    activePage: dataRedux.projectDocumentCertificateParams.activePage,
    totalData: dataRedux.projectDocumentCertificateParams.totalData || 0,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    dataActions: bindActionCreators(dataCreators, dispatch),
  }
}

export const ProjectDocumentCertificateListContainerConnected = connect(
  mapStateToProps,
  mapDispatchToProps
)(ProjectDocumentCertificateListContainer)