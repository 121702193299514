import React from 'react';
import {
  Breadcrumb,
  Button, DatePickerControl, Field,
  Grid,
  Section, SelectControl, ShapeContainer, Text, TitlePage
} from '../../components';

import {
  ModalCustomerProfileContainerConnected,
  ModalSaleTransactionDetailContainer
} from '../../containers/ModalContainer';
import { ProjectDocumentListContainerConnected } from '../../containers/ProjectDocumentListContainer/ProjectDocumentListContainer';
import { DATE_PICKER_FORMAT } from '../../helpers/constants/data';
import { LOCAL_STORAGE } from '../../helpers/constants/localStorage';
import { ROUTE_PATH } from '../../helpers/constants/route';
import { getDateApiRequet, getMomentEndOf, getMomentStartOf } from '../../helpers/functions/data';
import { getLanguage } from '../../helpers/functions/language';
import { getLocalStorage } from '../../helpers/functions/localStorage';
import { redirect, redirectData, setSideMenuActive } from '../../helpers/functions/route';
import { getClearProjectDocumentParams, getProjectDocumentList } from '../../helpers/services/projectDocument';
import { ICONS, VARIABLES } from '../../themes';


export class ProjectDocumentScreen extends React.Component {
  constructor(props) {
    super(props);
    setSideMenuActive(props);
    const language = getLocalStorage(LOCAL_STORAGE.LANGUAGE_RBPTRUEBLUE);
    const selectOptionList = [{
      value: 'pre_sales_trans_code',
      label: 'Pre-Transaction ID'
    }, {
      value: 'pre_sales_trans_project_name',
      label: 'Project name'
    }, {
      value: 'customer_name',
      label: 'Customer name'
    }, {
      value: 'product_group',
      label: 'Product group'
    }]

    this.state = {
      selectOptionList,
      selectedOption: selectOptionList[0],
      isOpenSelectCustomerModal: false,
      language,
      searchValue: '',
      startDate: '',
      endDate: '',

      isOpenSaleTransactionModal: false,
      saleTransactionDataModal: undefined,
    };
  }

  projectDocument = {
    onClickSearch: () => {
      const {
        searchValue,
        selectedOption,
        startDate,
        endDate,
      } = this.state;
      let params = {
        ...getClearProjectDocumentParams(),
        page: 1,
        [selectedOption.value]: searchValue,
      };
      if (startDate && endDate) {
        params.start_date = getDateApiRequet(startDate);
        params.end_date = getDateApiRequet(endDate);
      }
      getProjectDocumentList(params);
    },
    onClickEdit: async (item) => {
      redirect(`${ROUTE_PATH.PROJECT_DOCUMENT_MANAGE.LINK}/${item.preSalesTransId}`);
    },
    onClickViewSaleTrans: (item) => {
      this.setState({
        isOpenSaleTransactionModal: true,
        saleTransactionDataModal: item,
      })
    }
  };

  modal = {
    onOpenModalSelectCustomer: () => {
      this.setState({
        customerData: undefined,
        isOpenSelectCustomerModal: true,
      });
    },
    onClickCloseModalSelectCustomer: () => {
      this.setState({
        isOpenSelectCustomerModal: false,
      });
    },
    onClickSubmitCustomer: (data) => {
      redirectData(`${ROUTE_PATH.PROJECT_DOCUMENT_INFORMATION.LINK}/0`, {
        data: {
          customerData: data,
        }
      })
    },
    onClickCloseViewSaleTrans: () => {
      this.setState({
        isOpenSaleTransactionModal: false,
      })
    }
  };

  input = {
    onChangeSearchOption: (selectedOption) => {
      this.setState({
        selectedOption
      })
    },
    onChangeInput: (ev) => {
      const name = ev.target.name;
      const value = ev.target.value;
      this.setState({
        [name]: value,
      });
    },
    onChangeDate: (date, name) => {
      this.setState({
        [name]: date
      })
    }
  }

  render() {
    const {
      isOpenSelectCustomerModal,
      customerData,
      searchValue,
      startDate,
      endDate,
      selectedOption,
      selectOptionList,
      isOpenSaleTransactionModal,
      saleTransactionDataModal
    } = this.state;

    return (
      <React.Fragment>
        <Breadcrumb>
          <Breadcrumb.List
            link
            name={getLanguage('projectDocument.breadcrumb1', '')}
            onClick={() => {
              redirect(ROUTE_PATH.HOME.LINK);
            }}
          />
          <Breadcrumb.List name="/" />
          <Breadcrumb.List
            name={getLanguage('projectDocument.breadcrumb2', '')}
          />
        </Breadcrumb>
        <Section ui="content">
          <Section spacingBottom="35">
            <Grid gutterVertical="15" justify="space-between">
              <Grid.Column flex='1' >
                <TitlePage
                  src={ICONS['ic-pre-transaction.svg']}
                  name={getLanguage('projectDocument.title', '')}
                />
              </Grid.Column>
              <Grid.Column flex='none'>
                <Grid justify='flex-end' gutter='15'>
                  <Grid.Column>
                    <Button
                      ui='frontIcon'
                      blue
                      name={getLanguage('projectDocument.label.createProjectDocument', '')}
                      iconSrc={ICONS['ic-create.svg']}
                      iconWidth={16}
                      iconHeight={16}
                      onClick={this.modal.onOpenModalSelectCustomer}
                    />
                  </Grid.Column>
                </Grid>
              </Grid.Column>
            </Grid>
          </Section>
          <ShapeContainer
            ui="content"
            justify="space-between"
          >
            <Section spacingBottom="14">
              <ShapeContainer
                fluid
                padding='15'
                bgColor={VARIABLES.COLORS.GRAY_1}
                borderRadius='5'
              >
                <Grid gutterVertical="30" gutter="30" >
                  <Grid.Column flex="1" >
                    <Section >
                      <Grid gutter='20'>
                        <Grid.Column flex='none'>
                          <Section paddingTop='6'>
                            <Text
                              regular12
                              color={VARIABLES.COLORS.BLACK}
                            >
                              {getLanguage('projectDocument.label.date', '')}
                            </Text>
                          </Section>
                        </Grid.Column>
                        <Grid.Column flex='1'>
                          <Grid gutter='10'>
                            <Grid.Column flex='1'>
                              <Grid gutter='15'>
                                <Grid.Column flex='1'>
                                  <DatePickerControl
                                    filter
                                    bottom
                                    dateFormat={DATE_PICKER_FORMAT}
                                    placeholderText={getLanguage('projectDocument.label.selectStartDate', '')}
                                    selected={startDate}
                                    onChange={(date) => {
                                      this.input.onChangeDate(date, 'startDate');
                                    }}
                                  />
                                </Grid.Column>
                                <Grid.Column flex='none'>
                                  <Section paddingTop='6'>
                                    <Text
                                      regular12
                                      color={VARIABLES.COLORS.GRAY_19}
                                    >
                                      {getLanguage('projectDocument.label.dateTo', '')}
                                    </Text>
                                  </Section>
                                </Grid.Column>
                                <Grid.Column flex='1'>
                                  <DatePickerControl
                                    filter
                                    bottom
                                    dateFormat={DATE_PICKER_FORMAT}
                                    placeholderText={getLanguage('projectDocument.label.selectEndDate', '')}
                                    selected={endDate}
                                    onChange={(date) => {
                                      this.input.onChangeDate(date, 'endDate');
                                    }}
                                  />
                                </Grid.Column>
                              </Grid>
                            </Grid.Column>
                          </Grid>
                        </Grid.Column>
                      </Grid>
                    </Section>
                  </Grid.Column>
                  <Grid.Column flex="1">
                    <Section >
                      <Grid gutter='15'>
                        <Grid.Column flex='1'>
                          <Section >
                            <Field name='searchValue'
                              fluid
                              ui='blockLabelVerticalSearch'
                              placeholder={getLanguage('projectDocument.placeholder.search', '')}
                              value={searchValue}
                              onChange={this.input.onChangeInput}
                            />
                          </Section>
                        </Grid.Column>
                        <Grid.Column flex='1'>
                          <Section width="220">
                            <SelectControl
                              filterFluid
                              width='220'
                              isSearchable={false}
                              value={selectedOption}
                              onChange={this.input.onChangeSearchOption}
                              options={selectOptionList}
                            />
                          </Section>
                        </Grid.Column>
                      </Grid>
                    </Section>
                  </Grid.Column>
                  <Grid.Column flex='none'>
                    <Button
                      lightBlue
                      name={getLanguage('field.buttonSearch', '')}
                      width={VARIABLES.BUTTON.WIDTHS.W_105}
                      onClick={this.projectDocument.onClickSearch}
                    />
                  </Grid.Column>
                </Grid>
              </ShapeContainer>
              <ProjectDocumentListContainerConnected
                onClickViewSaleTrans={this.projectDocument.onClickViewSaleTrans}
              />
            </Section>
          </ShapeContainer>
        </Section>

        {isOpenSelectCustomerModal && (
          <ModalCustomerProfileContainerConnected
            isOpenModal
            isCheckboxTick
            customerData={customerData}
            onClickClose={this.modal.onClickCloseModalSelectCustomer}
            onClickSave={this.modal.onClickSubmitCustomer}
          />
        )}

        {isOpenSaleTransactionModal && (
          <ModalSaleTransactionDetailContainer
            isOpenModal
            title={getLanguage('projectDocument.modal.modalSaleTransaction.title', '')}
            data={saleTransactionDataModal}
            onClickClose={this.modal.onClickCloseViewSaleTrans}
          />
        )}
      </React.Fragment>
    );
  }
}
