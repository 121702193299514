import styled, {
  // css
} from 'styled-components'
import {
  default as VARIABLES
} from './../../../themes/styles/bases/variables'
import {
  default as TYPOGRAPHYS
} from './../../../themes/styles/bases/typographys'
// import {
//   default as MIXINS
// } from './../../../themes/styles/helpers/mixins'

// Sample using image in style
// url(${require('./../../../themes/images/icons/icon-sample.svg')});

// Wrapper
// ============================================================
export const ChooseLanguageWrapper = styled.div`
  /* Parent
  ------------------------------- */
  /* Childrens
  ------------------------------- */
  .choose-language-input-container {
    position: relative;
    display: flex;
    align-items: center;
  }

  .choose-language-flag {
    position: relative;
    margin-right: 15px;

    &:after {
      content: ' ';
      position: absolute;
      z-index: ${VARIABLES.Z_INDEXS.LV_1};
      top: 0;
      left: 0;
      display: block;
      width: 100%;
      height: 100%;
      box-shadow: ${VARIABLES.COLORS.SHADOW_2};
    }
  }

  .choose-language-flag-image {
    display: block;
    width: 18px;
    height: 12px;
  }

  .choose-language-input {
    cursor: pointer;
    opacity: 0;
    position: absolute;
    z-index: ${VARIABLES.Z_INDEXS.LV_1};
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    &:checked {
      + .choose-language-label {
        color: ${VARIABLES.COLORS.BLACK};

        &:after {
          opacity: 1;
        }
      }
    }
  }

  .choose-language-label {
    ${TYPOGRAPHYS.FIRST.REGULAR.FS_12};
    transition: ${VARIABLES.TRANSITIONS.DEFAULT};
    padding-right: 33px;
    line-height: 1;
    color: ${VARIABLES.COLORS.GRAY_19};

    &:after {
      content: ' ';
      transition: ${VARIABLES.TRANSITIONS.DEFAULT};
      opacity: 0;
      position: absolute;
      z-index: ${VARIABLES.Z_INDEXS.LV_1};
      top: 0;
      right: 0;
      display: block;
      width: 14px;
      height: 9px;
      background-image: url(${require('./../../../themes/images/icons/ic-check-language.svg')});
      background-repeat: no-repeat;
      background-size: contain;
    }
  }


  /* Modifiers
  ------------------------------- */
  /* Media queries
  ------------------------------- */
`
