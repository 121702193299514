import { displayWithPrefix, displayDateTime, displayWithComma } from "../helpers/functions/display";
import { getPhoneRemovePrefix, getCountryPhone } from "../helpers/functions/data";
import _ from 'lodash';

export class ModelSalePerson {
  constructor(props = {}) {
    this.email = props.email;
    this.firstname = props.first_name;
    this.id = props.id;
    this.idWithPrefix = displayWithPrefix(props.id);
    this.lastname = props.last_name;
    this.fullname = `${this.firstname} ${this.lastname}`;
    this.mobileNo = getPhoneRemovePrefix(props.mobile_no);
    this.mobileNoCountry = getCountryPhone(props.mobile_no);
    this.nickname = props.nick_name;
    this.phoneNo = getPhoneRemovePrefix(props.phone_no);
    this.phoneNoCountry = getCountryPhone(props.phone_no);
    this.createdAt = props.created_at;
    this.displayCreatedAt = displayDateTime(props.created_at, '/');
    this.sfid = props.sfid;

    //setting sale person
    this.acceptTermsConditions = props.accept_terms_conditions__c;
    this.alley = props.alley__c;
    this.birthDate = props.birth_date__c;
    this.city = props.city__c;
    this.company = props.company__c;
    this.contact = props.contact__c;
    this.contactChannel = props.contact_channel__c;
    this.contractorType = props.contractor_type__c;
    this.country = props.country__c;
    this.createdDate = props.createddate;
    this.dealerAccount = props.dealer_account__c;
    this.district = props.district__c;
    this.email__c = props.email__c;
    this.firstname__c = props.first_name__c;
    this.hamlet = props.hamlet__c;
    this.houseNumber = props.house_number__c;
    this.id = props.id;
    this.identificationNumber = props.identification_number__c || '';
    this.isDeleted = props.isdeleted;
    this.lastname__c = props.last_name__c;
    this.locationAccount = props.location_account__c;
    this.memberType = props.member_type__c;
    this.name = props.name;
    this.nickname__c = props.nick_name__c;
    this.oldPoints = props.old_points__c;
    this.password = props.password__c;
    this.phone__c = props.phone__c;
    this.points = props.points || 0;
    this.postCode = props.post_code__c;
    this.province = props.province__c;
    this.remainPoint = props.remain_point;
    this.road = props.road__c;
    this.role = props.role__c;
    this.sfid = props.sfid;
    this.shippingCity = props.shipping_city__c;
    this.shippingCountry = props.shipping_country__c;
    this.shippingDistrict = props.shipping_district__c;
    this.shippingHouseNumber = props.shipping_house_number__c;
    this.shippingPostCode = props.shipping_post_code__c;
    this.shippingProvince = props.shipping_province__c;
    this.shippingRoad = props.shipping_road__c;
    this.syncCustomer = props.sync_customer__c;
    this.systemmodStamp = props.systemmodstamp;
    this.trained = props.trained;
    this.trained__c = props.trained__c;
    this.uid = props.uid__c;
    this.ward = props.ward__c;
    this.hcErr = props._hc_err;
    this.startDateStartWorkingDay__c = props.start_date_start_working_day__c;
    this.position__c = props.position__c;

    // this.courseList = [];
    // for (let i = 0; i < 5; i++) {
    //   this[`courseName${i + 1}__c`] = props[`course_name_${i + 1}__c`];
    //   this[`courseDate${i + 1}__c`] = props[`course_date_${i + 1}__c`];
    //   if (this[`courseName${i + 1}__c`] !== '' || this[`courseDate${i + 1}__c`] !== null) {
    //     this.courseList.push({
    //       name: this[`courseName${i + 1}__c`],
    //       date: this[`courseDate${i + 1}__c`]
    //     });
    //   }
    // }

    this.idCardNo = this.identificationNumber.replace(/\$/g, '');
    this.fullname__c = `${this.firstname__c} ${this.lastname__c}`;
    this.phoneNoPrefix__c = getPhoneRemovePrefix(this.phone__c);
    this.pointsWithComma = displayWithComma(this.points);

    this.salesSkillSpecificCourseList = [];
    this.salesSkillIndependentCourseList = [];
    this.productSkillSpecificCourseList = [];
    this.productSkillIndependentCourseList = [];

    (props.training_courses || []).forEach(e => {
      const trainingData = {
        courseName__c: e.course_name__c || '',
        courseType__c: e.course_type__c,
        id: e.id,
        name: e.name,
        rbpMember__c: e.rbp_member__c,
        sfid: e.sfid,
        status__c: e.status__c,
        trainingCourse__c: e.training_course__c,
        trainingDate__c: e.training_date__c,
        uid__c: e.uid__c,

        isActive: e.status__c === 'Active',
      }
      if (trainingData.isActive === false) return;

      if (trainingData.courseType__c === 'Specific Course' && trainingData.trainingCourse__c === 'Sales Skills Training Course') {
        this.salesSkillSpecificCourseList.push(trainingData);
      }
      if (trainingData.courseType__c === 'Independent Course' && trainingData.trainingCourse__c === 'Sales Skills Training Course') {
        this.salesSkillIndependentCourseList.push(trainingData);
      }
      if (trainingData.courseType__c === 'Specific Course' && trainingData.trainingCourse__c === 'Product Knowledge Skills Training Course') {
        this.productSkillSpecificCourseList.push(trainingData);
      }
      if (trainingData.courseType__c === 'Independent Course' && trainingData.trainingCourse__c === 'Product Knowledge Skills Training Course') {
        this.productSkillIndependentCourseList.push(trainingData);
      }
    });

    this.oldSalesSkillSpecificCourseList = _.cloneDeep(this.salesSkillSpecificCourseList);
    this.oldSalesSkillIndependentCourseList = _.cloneDeep(this.salesSkillIndependentCourseList);
    this.oldProductSkillSpecificCourseList = _.cloneDeep(this.productSkillSpecificCourseList);
    this.oldProductSkillIndependentCourseList = _.cloneDeep(this.productSkillIndependentCourseList);
  }
}