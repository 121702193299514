import React from 'react';
import {
  connect,
} from 'react-redux';
import {
  Accordion,
  AccordionItem,
  AccordionItemTitle,
  AccordionItemBody
} from 'react-accessible-accordion'
import {
  ShapeContainer,
  Section,
  Text,
  TitleSection,
  Grid,
  AccordionControl,
  Video
} from './../../components'
import {
  VARIABLES,
} from './../../themes'
import { getClearCMSUserManualParams, getCMSUserManualList } from '../../helpers/services/cmsUserManual';
import { ENUM } from '../../helpers/constants/enum';
import _ from 'lodash';
import { getLanguage } from '../../helpers/functions/language';

class CMSUserManualListContainer extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      activePage: 1,
      dataPerPage: 10
    }
  }

  componentDidMount() {
    const {
      activePage,
      dataPerPage,
    } = this.state;
    const params = {
      ...getClearCMSUserManualParams(),
      page: activePage,
      limit: dataPerPage,
      status: ENUM.STATUS_ACTIVE.ACTIVE
    };
    getCMSUserManualList(params);
  }


  render() {
    const {
      dataList,
      totalData,
      activePage,
    } = this.props;
    const {
      dataPerPage
    } = this.state;
    return (
      <ShapeContainer ui='content'>
        <Section spacingBottom='45'>
          <TitleSection name={getLanguage('container.cmsUserManual.title', '')} />
        </Section>
        <AccordionControl help>
          <Accordion accordion>
            {dataList.map((e, i) => {
              return (
                <AccordionItem
                  key={i}
                  uuid={`howto_${i}`}
                >
                  <AccordionItemTitle>
                    <AccordionControl.ButtonHelp
                      name={e.name}
                    />
                  </AccordionItemTitle>
                  <AccordionItemBody>
                    <Grid justify='center'>
                      <Grid.Column grid='8'>
                        <div
                          className='text-editor-content'
                          dangerouslySetInnerHTML={{
                            __html: e.description
                          }}
                        />
                      </Grid.Column>
                    </Grid>
                  </AccordionItemBody>
                </AccordionItem>
              )
            })}
            {/* <AccordionItem uuid='howto_3'>
              <AccordionItemTitle>
                <AccordionControl.ButtonHelp name='How to create new customer' />
              </AccordionItemTitle>
              <AccordionItemBody>
                <Grid justify='center'>
                  <Grid.Column grid='8'>
                    <Section justify='center'>
                      <Text
                        regular14
                        color={VARIABLES.COLORS.GRAY_19}
                      >
                        Coming Soon
                  </Text>
                    </Section>
                  </Grid.Column>
                </Grid>
              </AccordionItemBody>
            </AccordionItem>
           */}
          </Accordion>
        </AccordionControl>
      </ShapeContainer>
    )
  }
}

const mapStateToProps = (state) => {
  const dataRedux = state.dataRedux;
  return {
    dataList: dataRedux.cmsUserManualList,
    activePage: dataRedux.cmsUserManualParams.activePage,
    totalData: dataRedux.cmsUserManualParams.totalData || 0,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
  }
}

export const CMSUserManualListContainerConnected = connect(
  mapStateToProps,
  mapDispatchToProps
)(CMSUserManualListContainer)