import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import {
  connect
} from 'react-redux';
import { bindActionCreators } from 'redux';
import { DATA_REDUX_STATE_KEY, dataCreators } from '../../actions/dataAction';
import { DATA, DATE_MOMENT_FORMAT } from '../../helpers/constants/data';
import { ENUM } from '../../helpers/constants/enum';
import { ROUTE_PATH } from '../../helpers/constants/route';
import { getSortAscending, getTodayDateDiff } from '../../helpers/functions/data';
import { getLanguage } from '../../helpers/functions/language';
import { isShowAppLoading, isShowLoading, toast } from '../../helpers/functions/main';
import { redirectData } from '../../helpers/functions/route';
import { checkIsCanSelectResidentialCategory, getCloseChooseCategoryWarrantyModalData, getDefaultChooseCategoryWarrantyModalData, getManageWarrantyData, getOpenChooseCategoryWarrantyModalData, handleProjectDocumentWarrantyData, handleSubmitCategoryWarrantyModal, onChangeProjectCategoryWarrantyModalData } from '../../helpers/functions/warranty';
import { getClearWarrantyParams, getWarrantyDetail, getWarrantyDetailList, getWarrantyList, getWarrantySelGroupList } from '../../helpers/services/warranty';
import { WarrantyManageWarranty } from '../../screens/WarrantyManageScreen/WarrantyManageWarranty';
import { storeDispatch, storeGetState } from '../../stores';
import { ModalChooseProjectCategoryContainer } from '../ModalContainer';
import {
  Button, Field, Grid, Image, PaginationControl, Section, ShapeContainer, Table, TableAction, Text
} from './../../components';
import {
  VARIABLES
} from './../../themes';
import {
  ModalConfirmContainer
} from './../../containers/ModalContainer';

class WarrantyListContainer extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      activePage: 1,
      dataPerPage: 10,
      dataDetail: {
        templateList: [],
      },
      modalData: getDefaultChooseCategoryWarrantyModalData(),
      data: {},
      modalTypeList: ['waitingAdminApprove'],
      modalType: null,
    }
    const dataDefaultOptionParams = storeGetState().dataRedux.warrantyDefaultOptionParams;
    storeDispatch(dataCreators.setDataRedux(DATA_REDUX_STATE_KEY.WARRANTY_OPTION_PARAMS, _.cloneDeep(dataDefaultOptionParams)));
  }

  componentDidMount() {
    const {
      projectDocumentId,
    } = this.props;
    const {
      activePage,
      dataPerPage,
    } = this.state;
    const params = {
      ...getClearWarrantyParams(),
      page: activePage,
      limit: dataPerPage,
      pre_sales_trans_id: projectDocumentId,
    };
    isShowAppLoading(true);
    getWarrantyList(params).then(() => {
      isShowAppLoading(false);
    });
  }

  warrantyList = {
    isDisableGenerate: (data) => {
      const {
        isMalay
      } = this.props;
      //bswm v
      return data.isDisableGenerate;
      //bmsw ^
      // return isMalay && DATA.WARRANTY_CAN_GENERATE.MALAY_GROUP_LIST.indexOf(data.productGroupName) === -1
    },
    // isDisableResidential: () => {
    //   const {
    //     dataOptionParams,
    //   } = this.props;
    //   let isCanGenerate = true;
    //   dataOptionParams.selectedDataList.forEach(e => {
    //     if(isCanGenerate){
    //       isCanGenerate = this.warrantyList.isCanGenerate(e);
    //     }
    //   });
    //   return isCanGenerate === false;
    // },
    isCanGenerate: (e) => {
      const {
        isMalay
      } = this.props;
      let isPass = e.wrtStatusText === DATA.WARRANTY_TYPE.DRAFT.TYPE ||
        e.isCanRegenerate
      // || e.isSaveDraft;
      if (isMalay) {

      }
      else {
        isPass = isPass && getTodayDateDiff(moment(moment(e.createdDate).add(1, 'day').format(DATE_MOMENT_FORMAT))._d, 'month') < 6;
      }
      //bswm v
      return isPass
      //bswm ^
    },
    updateData: (dataList) => {
      const {
        dataActions,
        dataOptionParams,
      } = this.props;
      //handle new or edit data
      dataList.forEach(e => {
        const findIndex = dataOptionParams.selectedDataList.map(es => es.id).indexOf(e.id);
        if (findIndex >= 0) {
          dataOptionParams.selectedDataList[findIndex] = e;
        }
        else {
          if (e.isSelected) {
            dataOptionParams.selectedDataList.push(e);
          }
        }
      })
      dataOptionParams.selectedDataList = dataOptionParams.selectedDataList.filter(e => e.isSelected);
      // console.log('dataOptionParams', dataList, dataOptionParams)
      dataActions.setDataRedux(DATA_REDUX_STATE_KEY.WARRANTY_LIST, dataList);
      dataActions.setDataRedux(DATA_REDUX_STATE_KEY.WARRANTY_OPTION_PARAMS, dataOptionParams);
    },
    onClickSort: (target) => {
      getWarrantyList({
        sort: target
      });
    },
    checkSelectDataIsCanRegenerate: () => {
      const {
        dataOptionParams,
      } = this.props;
      let isCanRegenerate = true;
      let selectedDataList = dataOptionParams.selectedDataList;
      selectedDataList.forEach(e => {
        if (isCanRegenerate) {
          isCanRegenerate = e.isCanRegenerate;
        }
      });
      return isCanRegenerate
    },
    isDisableGenerateWarrantyButton: () => {
      const {
        dataOptionParams,
      } = this.props;
      return dataOptionParams.selectedDataList.length === 0 ||
        dataOptionParams.selectedDataList.filter(e => e.isSaveDraft).length
    },
    onClickGenerate: async (option = {}) => {
      const {
        projectDocumentData,
        dataOptionParams,
      } = this.props;
      const {
        modalData,
      } = this.state;

      let customerName = dataOptionParams.selectedDataList[0].customerName;
      let isSameCustomer = true;
      let selectedDataList = dataOptionParams.selectedDataList;
      selectedDataList.forEach(e => {
        if (e.customerName !== customerName) {
          isSameCustomer = false;
        }
      });

      if (isSameCustomer) {
        isShowLoading(true)
        selectedDataList = await getWarrantyDetailList(selectedDataList)
        isShowLoading(false)

        const data = {
          ...selectedDataList[0],
        }

        let isCanSelectResidentialCategory = data.isCanSelectResidentialCategory;
        if (data.projectCountry === ENUM.COUNTRY_TYPE.THAI) {
          isCanSelectResidentialCategory = checkIsCanSelectResidentialCategory(selectedDataList);
        }

        const isCanRegenerate = this.warrantyList.checkSelectDataIsCanRegenerate();
        if (isCanRegenerate || option.isEditDraft) {
          let data = await getManageWarrantyData(selectedDataList);
          if (data) {
            data.isCanSelectResidentialCategory = isCanSelectResidentialCategory;
            if (projectDocumentData) {
              data = handleProjectDocumentWarrantyData(data, projectDocumentData, option);
            }
            redirectData(`${ROUTE_PATH.WARRANTY.LINK}/${data.id}`, {
              data,
            });
          }
        }
        else {
          data.isCanSelectResidentialCategory = isCanSelectResidentialCategory;
          this.setState({
            data,
          }, () => {
            // CR: 1490
            // if (data.projectCountry === ENUM.COUNTRY_TYPE.THAI) {
            //   modalData.wrtCat = DATA.WARRANTY_CATEGORY_TYPE.NON_RESIDENTIAL.TYPE;
            //   this.setState({
            //     modalData,
            //   }, () => {
            //     this.modal.onClickSubmitCategory()
            //   })
            // }
            // else {
            this.modal.onClickOpenChooseCategoryModal();
            // }
          })
        }
      }
      else {
        toast.error(getLanguage('message.contactNamePleaseSelectOnlyOne', ''));
      }
    },
    onClickViewWarrantyDetail: async (item) => {
      const {
        onClickViewWarrantyDetail,
        projectDocumentData,
      } = this.props;
      const data = await getWarrantyDetail(item)
      if (projectDocumentData) {
        onClickViewWarrantyDetail(data);
      }
      else {
        this.setState({
          isViewWarrantyDetail: true,
          dataDetail: {
            ...data,
            templateList: data.detailList,
          }
        })
        onClickViewWarrantyDetail(true)
      }
    },
    goBackDetailToList: () => {
      const {
        onClickViewWarrantyDetail,
      } = this.props;
      this.setState({
        isViewWarrantyDetail: false,
      })
      onClickViewWarrantyDetail(false)
    },
    onClickEditDraft: async (data) => {
      const {
        dataOptionParams,
        dataActions,
      } = this.props;
      const { modalTypeList } = this.state;

      if (data.wrtSelGrp) {
        const warrantyDraftGroupList = await getWarrantySelGroupList({
          wrt_sel_grp: data.wrtSelGrp
        })

        const APPROVE_STATUS_TEXT = DATA.WARRANTY_TYPE.APPROVE.TEXT;
        // show modal wating admin to be approve all if warrantyDraftGroupList array some wrtStatusText == Approve
        if (warrantyDraftGroupList.some(e => e.wrtStatusText === APPROVE_STATUS_TEXT)
          && warrantyDraftGroupList.some(e => e.wrtStatusText !== APPROVE_STATUS_TEXT)) {
          this.modal.openModalType(modalTypeList[0]);
          return;
        }

        dataOptionParams.selectedDataList = warrantyDraftGroupList.map(e => {
          e.isSelected = true;
          return e;
        });;
        dataActions.setDataRedux(DATA_REDUX_STATE_KEY.WARRANTY_OPTION_PARAMS, dataOptionParams);
      }
      else {
        let draftData = _.cloneDeep(data);
        draftData.isSelected = true;
        dataOptionParams.selectedDataList = [draftData];
        dataActions.setDataRedux(DATA_REDUX_STATE_KEY.WARRANTY_OPTION_PARAMS, dataOptionParams);
      }

      this.warrantyList.onClickGenerate({
        isEditDraft: true
      });
    }
  }

  input = {
    onChangeSelectCheckbox: (data) => {
      const {
        dataList,
      } = this.props;
      if (this.warrantyList.isDisableGenerate(data)) {

      }
      else {
        const tempDataList = dataList.map(e => {
          if (e.id === data.id) {
            e.isSelected = !e.isSelected;
          }
          // else {
          //   e.isSelected = false;
          // }
          return e
        })
        this.warrantyList.updateData(tempDataList);
      }
    },
    toggleViewMoreWarranty: (data) => {
      const {
        dataList,
      } = this.props;
      const tempDataList = dataList.map(e => {
        if (e.id === data.id) {
          e.isViewMore = !e.isViewMore;
        }
        return e
      })
      this.warrantyList.updateData(tempDataList);
    },
    toggleViewMoreWarrantyDetail: (data, detailData) => {
      const {
        dataList,
      } = this.props;
      const tempDataList = dataList.map(e => {
        if (e.id === data.id) {
          e.detailList.map(ed => {
            if (ed.coilId === detailData.coilId) {
              ed.isViewMore = !ed.isViewMore;
            }
            return ed;
          });
        }
        return e;
      })
      this.warrantyList.updateData(tempDataList);
    },
  }


  modal = {
    onClickOpenChooseCategoryModal: () => {
      const {
        modalData,
      } = this.state;
      this.setState({
        modalData: getOpenChooseCategoryWarrantyModalData(modalData),
      });
    },
    onCloseModal: () => {
      const {
        modalData,
      } = this.state;
      this.setState({
        modalData: getCloseChooseCategoryWarrantyModalData(modalData),
      })
    },
    onClickSubmitCategory: async () => {
      const {
        dataOptionParams,
        projectDocumentData,
      } = this.props;
      const {
        modalData,
      } = this.state;
      let selectedDataList = _.cloneDeep(dataOptionParams.selectedDataList);
      handleSubmitCategoryWarrantyModal(selectedDataList, modalData, (data) => {
        if (projectDocumentData) {
          data = handleProjectDocumentWarrantyData(data, projectDocumentData);
        }
        redirectData(`${ROUTE_PATH.WARRANTY.LINK}/${data.id}`, {
          data,
        });
        this.modal.onCloseModal();
      });
    },
    onChangeProjectCategory: (type) => {
      const {
        modalData,
      } = this.state;
      this.setState({
        modalData: onChangeProjectCategoryWarrantyModalData(modalData, type),
      })
    },
    openModalType: (type = null) => {
      this.setState({ modalType: type });
    },
    closeModalType: () => {
      this.setState({ modalType: null });
    }
  }

  pagination = {
    onPaginationChange: (pageNumber) => {
      getWarrantyList({
        page: pageNumber,
      });
    }
  }

  render() {
    const {
      dataList,
      dataParams,
      dataOptionParams,
      totalData,
      activePage,
      isMalay,
      projectDocumentData,
      onClickBack,
    } = this.props;
    const {
      dataPerPage,
      isViewWarrantyDetail,
      dataDetail,
      //bswm v
      modalData,
      data,
      //bswm ^
      modalType,
      modalTypeList
    } = this.state;

    console.log('projectDocumentData', projectDocumentData);
    return isViewWarrantyDetail ?
      <WarrantyManageWarranty isPreview isViewWarrantyDetail isViewerPopup
        data={dataDetail}
        dataDetailFromView={dataDetail.templateList[0]}
        onClickCloseView={this.warrantyList.goBackDetailToList}
      />
      :
      <React.Fragment>
        <ShapeContainer
          ui={projectDocumentData ? '' : 'contentFilter'}
          fluid={projectDocumentData}
          paddingVertical={projectDocumentData ? 0 : 40}
          paddingHorizontal={projectDocumentData ? 0 : 40}
          justify='space-between'
        >
          <Section spacingBottom='45'>
            <Table
              striped
              actionCheckboxWarranty
            >
              <Table.Section>
                <Table.Row>
                  <Table.HeadColumn
                    alignCenter
                    maxWidth={VARIABLES.TABLE.INFO_WIDTHS.CHECKBOX_SQUARE}
                    minWidth={VARIABLES.TABLE.INFO_WIDTHS.CHECKBOX_SQUARE}
                    noSort
                  >
                    {getLanguage('container.warrantyList.tableWarranty.header.select', '')}
                  </Table.HeadColumn>
                  <Table.HeadColumn
                    minWidth={VARIABLES.TABLE.INFO_WIDTHS.DATE_CREATE}
                    sortAscending={getSortAscending(dataParams, 'created_date')}
                    onClickSort={() => {
                      this.warrantyList.onClickSort('created_date desc');
                    }}
                  >
                    {getLanguage('container.warrantyList.tableWarranty.header.createDate', '')}
                  </Table.HeadColumn>
                  <Table.HeadColumn
                    noSort
                    minWidth={isMalay ? VARIABLES.TABLE.INFO_WIDTHS.SALES_TRANSACTION_ID : VARIABLES.TABLE.INFO_WIDTHS.SALES_TRANSACTION_ID_NO_SORT_WARRANTY}
                    title={getLanguage('container.warrantyList.tableWarranty.header.salesTransactionID', '')}
                  >
                    {isMalay ? getLanguage('container.warrantyList.tableWarranty.header.salesTransactionID', '') : getLanguage('container.warrantyList.tableWarranty.header.salesTransactionIDAbbreviation', '')}
                  </Table.HeadColumn>
                  <Table.HeadColumn
                    minWidth={isMalay ? VARIABLES.TABLE.INFO_WIDTHS.CONTACT_NAME_WARRANTY : VARIABLES.TABLE.INFO_WIDTHS.CONTACT_NAME_WARRANTY}
                    sortAscending={getSortAscending(dataParams, 'customer_name')}
                    onClickSort={() => {
                      this.warrantyList.onClickSort('customer_name desc');
                    }}
                  >
                    {getLanguage('container.warrantyList.tableWarranty.header.customerName', '')}
                  </Table.HeadColumn>
                  <Table.HeadColumn
                    minWidth={VARIABLES.TABLE.INFO_WIDTHS.WARRANTY_NUMBER}
                    sortAscending={getSortAscending(dataParams, 'wrt_no')}
                    onClickSort={() => {
                      this.warrantyList.onClickSort('wrt_no desc');
                    }}
                  >
                    {getLanguage('container.warrantyList.tableWarranty.header.warrantyNo', '')}
                  </Table.HeadColumn>
                  <Table.HeadColumn
                    minWidth={isMalay ? VARIABLES.TABLE.INFO_WIDTHS.PROJECT_NAME_WARRANTY : VARIABLES.TABLE.INFO_WIDTHS.PROJECT_NAME_WARRANTY}
                    sortAscending={getSortAscending(dataParams, 'project_name')}
                    onClickSort={() => {
                      this.warrantyList.onClickSort('project_name desc');
                    }}
                  >
                    {getLanguage('container.warrantyList.tableWarranty.header.projectName', '')}
                  </Table.HeadColumn>
                  <Table.HeadColumn
                    noSort
                    minWidth={isMalay ? VARIABLES.TABLE.INFO_WIDTHS.PRODUCT_GROUP : VARIABLES.TABLE.INFO_WIDTHS.PRODUCT_GROUP_NO_SORT_WARRANTY}
                  // sortAscending={() => {}}
                  // onClickSort={() => {}}
                  >
                    {getLanguage('container.warrantyList.tableWarrantyDetail.header.productGroup', '')}
                  </Table.HeadColumn>
                  {
                    isMalay &&
                    <React.Fragment>
                      <Table.HeadColumn
                        noSort
                        minWidth={VARIABLES.TABLE.INFO_WIDTHS.DATE_PURCHASING}
                      // sortAscending={() => {}}
                      // onClickSort={() => {}}
                      >
                        {getLanguage('container.warrantyList.tableWarranty.header.purchaseDate', '')}
                      </Table.HeadColumn>
                      <Table.HeadColumn
                        noSort
                        minWidth={VARIABLES.TABLE.INFO_WIDTHS.INVOICE_NUMBER_FULL}
                      // sortAscending={() => {}}
                      // onClickSort={() => {}}
                      >
                        {getLanguage('container.warrantyList.tableWarranty.header.invoiceNumber', '')}
                      </Table.HeadColumn>
                      <Table.HeadColumn
                        noSort
                        alignRight
                        minWidth={VARIABLES.TABLE.INFO_WIDTHS.INVOICE_LINE_ITEM_SHORT}
                      // sortAscending={() => {}}
                      // onClickSort={() => {}}
                      >
                        {getLanguage('container.warrantyList.tableWarranty.header.invoiceLineItem', '')}
                      </Table.HeadColumn>
                    </React.Fragment>
                  }
                  <Table.HeadColumn
                    noSort
                    alignRight
                    minWidth={VARIABLES.TABLE.INFO_WIDTHS.ORDER_M_NO_SORT}
                  // sortAscending={() => {}}
                  // onClickSort={() => {}}
                  >
                    {getLanguage('container.warrantyList.tableWarrantyDetail.header.order', '')}
                  </Table.HeadColumn>
                  {
                    isMalay &&
                    <Table.HeadColumn
                      noSort
                      alignRight
                      minWidth={VARIABLES.TABLE.INFO_WIDTHS.REVENUE}
                    // sortAscending={() => {}}
                    // onClickSort={() => {}}
                    >
                      {getLanguage('container.warrantyList.tableWarranty.header.revenue', '')}
                    </Table.HeadColumn>
                  }
                  <Table.HeadColumn
                    minWidth={VARIABLES.TABLE.INFO_WIDTHS.PHONE_GLOBAL}
                    sortAscending={getSortAscending(dataParams, 'project_phone')}
                    onClickSort={() => {
                      this.warrantyList.onClickSort('project_phone desc');
                    }}
                  >
                    {getLanguage('container.warrantyList.tableWarranty.header.mobileNo', '')}
                  </Table.HeadColumn>
                  <Table.HeadColumn
                    minWidth={VARIABLES.TABLE.INFO_WIDTHS.STATUS_WARRANTY}
                    sortAscending={getSortAscending(dataParams, 'wrt_status')}
                    onClickSort={() => {
                      this.warrantyList.onClickSort('wrt_status desc');
                    }}
                  >
                    {getLanguage('container.warrantyList.tableWarranty.header.status', '')}
                  </Table.HeadColumn>
                  <Table.HeadColumn
                    noSort
                    alignCenter
                    maxWidth={VARIABLES.TABLE.INFO_WIDTHS.ACTION_VIEW_MORE}
                    minWidth={VARIABLES.TABLE.INFO_WIDTHS.ACTION_VIEW_MORE}
                  >
                    {getLanguage('container.warrantyList.tableWarranty.header.action', '')}
                  </Table.HeadColumn>
                </Table.Row>
              </Table.Section>
              <Table.Section>
                {
                  dataList.map((e, i) => {
                    return (
                      <Table.RowCollapsible
                        key={i}
                      >
                        <Table.Row>
                          <Table.BodyColumn
                            action
                            alignCenter
                            maxWidth={VARIABLES.TABLE.INFO_WIDTHS.CHECKBOX_SQUARE}
                            minWidth={VARIABLES.TABLE.INFO_WIDTHS.CHECKBOX_SQUARE}
                          >
                            {this.warrantyList.isCanGenerate(e) ?
                              <Section spacingTop='-2'>
                                <Field
                                  disabled={this.warrantyList.isDisableGenerate(e)}
                                  checked={e.isSelected || false}
                                  ui='checkboxTick'
                                  onChange={() => {
                                    this.input.onChangeSelectCheckbox(e);
                                  }}
                                />
                              </Section>
                              :
                              <Text
                                regular12
                                ellipsis
                                color={VARIABLES.COLORS.GRAY_19}
                              >
                                -
                              </Text>
                            }
                          </Table.BodyColumn>
                          <Table.BodyColumn
                            info
                            minWidth={VARIABLES.TABLE.INFO_WIDTHS.DATE_CREATE}
                          >
                            {e.displayCreatedDate}
                          </Table.BodyColumn>
                          <Table.BodyColumn
                            info
                            minWidth={isMalay ? VARIABLES.TABLE.INFO_WIDTHS.SALES_TRANSACTION_ID : VARIABLES.TABLE.INFO_WIDTHS.SALES_TRANSACTION_ID_NO_SORT_WARRANTY}
                            title={e.orderNoText}
                          >
                            {e.orderNoText}
                          </Table.BodyColumn>
                          <Table.BodyColumn
                            info
                            minWidth={isMalay ? VARIABLES.TABLE.INFO_WIDTHS.CONTACT_NAME_WARRANTY : VARIABLES.TABLE.INFO_WIDTHS.CONTACT_NAME_WARRANTY}
                            title={e.customerName}
                          >
                            {e.customerName}
                          </Table.BodyColumn>
                          <Table.BodyColumn
                            info
                            minWidth={VARIABLES.TABLE.INFO_WIDTHS.WARRANTY_NUMBER}
                            title={e.isShowWrtNo ? (
                              e.isCanRegenerate ? e.oldWrtNo : e.wrtNo
                            ) : ''}
                          >
                            {e.isShowWrtNo ? (
                              e.isCanRegenerate ? e.oldWrtNo : e.wrtNo
                            ) : ''}
                          </Table.BodyColumn>
                          <Table.BodyColumn
                            info
                            minWidth={isMalay ? VARIABLES.TABLE.INFO_WIDTHS.PROJECT_NAME_WARRANTY : VARIABLES.TABLE.INFO_WIDTHS.PROJECT_NAME_WARRANTY}
                            title={e.projectName}
                          >
                            {e.projectName}
                          </Table.BodyColumn>
                          <Table.BodyColumn
                            info
                            minWidth={isMalay ? VARIABLES.TABLE.INFO_WIDTHS.PRODUCT_GROUP : VARIABLES.TABLE.INFO_WIDTHS.PRODUCT_GROUP_NO_SORT_WARRANTY}
                            title={e.productGroupName}
                          >
                            {e.productGroupName}
                          </Table.BodyColumn>
                          {
                            isMalay &&
                            <React.Fragment>
                              <Table.BodyColumn
                                info
                                minWidth={VARIABLES.TABLE.INFO_WIDTHS.DATE_PURCHASING}
                                title={e.displayOrderDate}
                              >
                                {e.displayOrderDate}
                              </Table.BodyColumn>
                              <Table.BodyColumn
                                info
                                minWidth={VARIABLES.TABLE.INFO_WIDTHS.INVOICE_NUMBER_FULL}
                                title={e.inoivceNo}
                              >
                                {e.inoivceNo}
                              </Table.BodyColumn>
                              <Table.BodyColumn
                                info
                                alignRight
                                minWidth={VARIABLES.TABLE.INFO_WIDTHS.INVOICE_LINE_ITEM_SHORT}
                                title={e.invoiceLineItem}
                              >
                                {e.invoiceLineItem}
                              </Table.BodyColumn>
                            </React.Fragment>
                          }
                          <Table.BodyColumn
                            info
                            alignRight
                            minWidth={VARIABLES.TABLE.INFO_WIDTHS.ORDER_M_NO_SORT}
                            title={e.coilOrderMWithComma}
                          >
                            {e.coilOrderMWithComma}
                          </Table.BodyColumn>
                          {
                            isMalay &&
                            <Table.BodyColumn
                              info
                              alignRight
                              minWidth={VARIABLES.TABLE.INFO_WIDTHS.REVENUE}
                              title={e.revenue}
                            >
                              {e.revenue}
                            </Table.BodyColumn>
                          }
                          <Table.BodyColumn
                            phone
                            minWidth={VARIABLES.TABLE.INFO_WIDTHS.PHONE_GLOBAL}
                            srcFlag={e.projectPhoneCountry.flag}
                            idPhone={e.projectPhoneCountry.value}
                          >
                            {e.projectPhone}
                          </Table.BodyColumn>
                          <Table.BodyColumn
                            infoStatus
                            // Status color
                            // No status(-) = GRAY_19, Ready = GREEN_5, Waiting for approval = ORANGE_2, Change needed = RED_4
                            infoStatusColor={e.wrtStatusColor}
                            minWidth={VARIABLES.TABLE.INFO_WIDTHS.STATUS_WARRANTY}
                            title={e.wrtStatusText}
                          >
                            {e.wrtStatusText}
                          </Table.BodyColumn>
                          <Table.BodyColumn
                            info={false}
                            action={true}
                            alignCenter
                            maxWidth={VARIABLES.TABLE.INFO_WIDTHS.ACTION_VIEW_MORE}
                            minWidth={VARIABLES.TABLE.INFO_WIDTHS.ACTION_VIEW_MORE}
                          >
                            {
                              e.wrtStatusText === DATA.WARRANTY_TYPE.READY.TEXT &&
                              <Section>
                                <Image
                                  title={getLanguage('container.warrantyList.tableWarrantyDetail.action.buttonViewWarranty', '')}
                                  ui='iconViewWarranty'
                                  onClick={() => {
                                    this.warrantyList.onClickViewWarrantyDetail(e)
                                  }}
                                />
                              </Section>
                            }
                            {
                              e.isSaveDraft &&
                              <Section>
                                <Image
                                  title={getLanguage('container.warrantyList.tableWarrantyDetail.action.buttonEditWarranty', '')}
                                  ui='iconEditWarranty'
                                  onClick={() => {
                                    this.warrantyList.onClickEditDraft(e);
                                  }}
                                />
                              </Section>
                            }
                          </Table.BodyColumn>
                        </Table.Row>
                      </Table.RowCollapsible>
                    )
                  })
                }
              </Table.Section>
            </Table>
          </Section>
          <TableAction>
            <TableAction.Left>
              {
                projectDocumentData ? (
                  <Button
                    gray
                    ui="back"
                    name={getLanguage('field.buttonBack', '')}
                    onClick={onClickBack}
                  />
                ) : (
                  <Grid gutter='15'>
                    <Grid.Column>
                      <ShapeContainer
                        ui='badgeCount'
                        badgeCountTitle={getLanguage('warranty.selectedTransactions', '')}
                        badgeCountNumber={dataOptionParams.selectedDataList.length}
                      />
                    </Grid.Column>
                    <Grid.Column>
                      <Button
                        disabled={this.warrantyList.isDisableGenerateWarrantyButton()}
                        blue
                        name={getLanguage('warranty.buttonGenerateWarranty', '')}
                        width={VARIABLES.BUTTON.WIDTHS.W_200}
                        onClick={this.warrantyList.onClickGenerate}
                      />
                    </Grid.Column>
                  </Grid>
                )
              }
            </TableAction.Left>
            <TableAction.Right>
              <Grid gutter='20'>
                <Grid.Column>
                  <Section paddingTop='12'>
                    <Text
                      regular14
                      color={VARIABLES.COLORS.GRAY_16}
                    >
                      {getLanguage('pagination.page', '')} {activePage}/{Math.ceil(totalData / dataPerPage)}
                    </Text>
                  </Section>
                </Grid.Column>
                <Grid.Column>
                  <Section paddingTop='6'>
                    <PaginationControl
                      activePage={activePage}
                      itemsCountPerPage={dataPerPage}
                      totalItemsCount={totalData}
                      pageRangeDisplayed={3}
                      onChange={this.pagination.onPaginationChange}
                    />
                  </Section>
                </Grid.Column>
                {
                  projectDocumentData && (
                    <React.Fragment>
                      <Grid.Column>
                        <Section paddingTop='6'>
                          <ShapeContainer
                            ui='badgeCount'
                            badgeCountTitle={getLanguage('warranty.selectedTransactions', '')}
                            badgeCountNumber={dataOptionParams.selectedDataList.length}
                          />
                        </Section>
                      </Grid.Column>
                      <Grid.Column>
                        <Section paddingTop='6'>
                          <Button
                            disabled={this.warrantyList.isDisableGenerateWarrantyButton()}
                            blue
                            name={getLanguage('warranty.buttonGenerateWarranty', '')}
                            width={VARIABLES.BUTTON.WIDTHS.W_200}
                            onClick={this.warrantyList.onClickGenerate}
                          />
                        </Section>
                      </Grid.Column>
                    </React.Fragment>
                  )
                }
              </Grid>
            </TableAction.Right>
          </TableAction>
        </ShapeContainer >
        {modalData.isOpenChooseProjectCategoryModal &&
          <ModalChooseProjectCategoryContainer
            isOpenModal
            onClickClose={this.modal.onCloseModal}
            onClickConfirm={this.modal.onClickSubmitCategory}
            onChangeCategory={this.modal.onChangeProjectCategory}
            category={modalData.wrtCat}
            data={data}
          />
        }
        {modalType === modalTypeList[0] &&
          <ModalConfirmContainer isOpenModal
            title={getLanguage('modal.info.notice', '')}
            message={getLanguage('message.warrantySpecialWaitingToBeApproveAll', '')}
            onClickCancel={this.modal.closeModalType}
            isHideConfirmButton={true}
          />
        }
      </React.Fragment >
  }
}

const mapStateToProps = (state) => {
  const dataRedux = state.dataRedux;
  return {
    dataList: dataRedux.warrantyList,
    dataParams: dataRedux.warrantyParams,
    dataOptionParams: dataRedux.warrantyOptionParams,
    activePage: dataRedux.warrantyParams.activePage,
    totalData: dataRedux.warrantyParams.totalData || 0,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    dataActions: bindActionCreators(dataCreators, dispatch),
  }
}

export const WarrantyListContainerConnected = connect(
  mapStateToProps,
  mapDispatchToProps
)(WarrantyListContainer)