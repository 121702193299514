import React from 'react';
import {
  connect,
} from 'react-redux';
import {
  Table,
} from './../../components'
import { VARIABLES } from '../../themes';
import { getSaleTransList, getClearSaleTransParams } from '../../helpers/services/saleTrans';
import { getLanguage } from '../../helpers/functions/language';
import _ from 'lodash';

class SaleTransactionListContainer extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      activePage: 1,
      dataPerPage: 3,
    }
  }

  componentDidMount() {
    const {
      customerData,
    } = this.props
    const {
      activePage,
      dataPerPage,
    } = this.state;
    const params = {
      ...getClearSaleTransParams(),
      page: activePage,
      limit: dataPerPage,
      customer_id: customerData.id,
    };
    getSaleTransList(params);
  }

  saleTransaction = {
    renderTableHeader: () => {
      return (
        <Table.Row>
          <Table.HeadColumn
            noSort
            maxWidth={VARIABLES.TABLE.INFO_WIDTHS.DATE}
            minWidth={VARIABLES.TABLE.INFO_WIDTHS.DATE}
          >
            {getLanguage('container.latestSaleTransaction.table.header.date', '')}
          </Table.HeadColumn>
          <Table.HeadColumn
            noSort
            maxWidth={VARIABLES.TABLE.INFO_WIDTHS.SALES_TRANSACTION_ID_NO_SORT}
            minWidth={VARIABLES.TABLE.INFO_WIDTHS.SALES_TRANSACTION_ID_NO_SORT}
          >
            {getLanguage('container.latestSaleTransaction.table.header.saleTransactionID', '')}
          </Table.HeadColumn>
          <Table.HeadColumn
            noSort
            minWidth={VARIABLES.TABLE.INFO_WIDTHS.COIL_NAME}
          >
            {getLanguage('container.latestSaleTransaction.table.header.coilName', '')}
          </Table.HeadColumn>
          <Table.HeadColumn
            noSort
            maxWidth={VARIABLES.TABLE.INFO_WIDTHS.COIL_ID_INFO}
            minWidth={VARIABLES.TABLE.INFO_WIDTHS.COIL_ID_INFO}
          >
            {getLanguage('container.latestSaleTransaction.table.header.coilID', '')}
          </Table.HeadColumn>
          <Table.HeadColumn
            noSort
            alignRight
            maxWidth={VARIABLES.TABLE.INFO_WIDTHS.ORDER_M}
            minWidth={VARIABLES.TABLE.INFO_WIDTHS.ORDER_M}
          >
            {getLanguage('container.latestSaleTransaction.table.header.order', '')}
          </Table.HeadColumn>
          <Table.HeadColumn
            noSort
            alignRight
            maxWidth={VARIABLES.TABLE.INFO_WIDTHS.TRUEBLUE_POINTS_NO_SORT}
            minWidth={VARIABLES.TABLE.INFO_WIDTHS.TRUEBLUE_POINTS_NO_SORT}
          >
            {getLanguage('container.latestSaleTransaction.table.header.trueBluePoints', '')}
          </Table.HeadColumn>
          <Table.HeadColumn
            noSort
            maxWidth={VARIABLES.TABLE.INFO_WIDTHS.SALESPERSON}
            minWidth={VARIABLES.TABLE.INFO_WIDTHS.SALESPERSON}
          >
            {getLanguage('container.latestSaleTransaction.table.header.salesperson', '')}
          </Table.HeadColumn>
        </Table.Row>
      )
    },
    renderTableHeaderIndoToko: () => {
      return (
        <Table.Row>
          <Table.HeadColumn
            noSort
            maxWidth={VARIABLES.TABLE.INFO_WIDTHS.DATE}
            minWidth={VARIABLES.TABLE.INFO_WIDTHS.DATE}
          >
            {getLanguage('container.latestSaleTransaction.table.header.date', '')}
          </Table.HeadColumn>
          <Table.HeadColumn
            noSort
            maxWidth={VARIABLES.TABLE.INFO_WIDTHS.SALES_TRANSACTION_ID_NO_SORT}
            minWidth={VARIABLES.TABLE.INFO_WIDTHS.SALES_TRANSACTION_ID_NO_SORT}
          >
            {getLanguage('container.latestSaleTransaction.table.header.saleTransactionID', '')}
          </Table.HeadColumn>
          <Table.HeadColumn
            noSort
            minWidth={VARIABLES.TABLE.COLUMN_WIDTHS.NAMES.PROFILE_TYPE}
          >
            {getLanguage('container.latestSaleTransaction.table.header.profileType', '')}
          </Table.HeadColumn>
          <Table.HeadColumn
            noSort
            minWidth={VARIABLES.TABLE.COLUMN_WIDTHS.NAMES.PRODUCT_GROUP}
          >
            {getLanguage('container.latestSaleTransaction.table.header.productGroup', '')}
          </Table.HeadColumn>
          <Table.HeadColumn
            noSort
            alignRight
            maxWidth={VARIABLES.TABLE.COLUMN_WIDTHS.MEASUREMENTS.LENGTH_NUMBER}
            minWidth={VARIABLES.TABLE.COLUMN_WIDTHS.MEASUREMENTS.LENGTH_NUMBER}
          >
            {getLanguage('container.latestSaleTransaction.table.header.length', '')}
          </Table.HeadColumn>
          <Table.HeadColumn
            noSort
            alignRight
            maxWidth={VARIABLES.TABLE.COLUMN_WIDTHS.MEASUREMENTS.QUANTITY_UNIT}
            minWidth={VARIABLES.TABLE.COLUMN_WIDTHS.MEASUREMENTS.QUANTITY_UNIT}
          >
            {getLanguage('container.latestSaleTransaction.table.header.orderQuantity', '')}
          </Table.HeadColumn>
          <Table.HeadColumn
            noSort
            alignRight
            maxWidth={VARIABLES.TABLE.INFO_WIDTHS.TRUEBLUE_POINTS_NO_SORT}
            minWidth={VARIABLES.TABLE.INFO_WIDTHS.TRUEBLUE_POINTS_NO_SORT}
          >
            {getLanguage('container.latestSaleTransaction.table.header.trueBluePoints', '')}
          </Table.HeadColumn>
          <Table.HeadColumn
            noSort
            maxWidth={VARIABLES.TABLE.INFO_WIDTHS.SALESPERSON}
            minWidth={VARIABLES.TABLE.INFO_WIDTHS.SALESPERSON}
          >
            {getLanguage('container.latestSaleTransaction.table.header.salesperson', '')}
          </Table.HeadColumn>
        </Table.Row>
      )
    },
    renderTableBody: () => {
      const {
        dataList,
      } = this.props;
      return dataList.map((e, i) => {
        return (
          <Table.Row
            key={i}
          >
            <Table.BodyColumn
              info
              maxWidth={VARIABLES.TABLE.INFO_WIDTHS.DATE}
              minWidth={VARIABLES.TABLE.INFO_WIDTHS.DATE}
            >
              {e.displayCreateAt}
            </Table.BodyColumn>
            <Table.BodyColumn
              info
              maxWidth={VARIABLES.TABLE.INFO_WIDTHS.SALES_TRANSACTION_ID_NO_SORT}
              minWidth={VARIABLES.TABLE.INFO_WIDTHS.SALES_TRANSACTION_ID_NO_SORT}
              title={e.name}
            >
              {e.name}
            </Table.BodyColumn>
            <Table.BodyColumn
              info
              minWidth={VARIABLES.TABLE.INFO_WIDTHS.COIL_NAME}
              title={e.coilName}
            >
              {e.coilName}
            </Table.BodyColumn>
            <Table.BodyColumn
              info
              maxWidth={VARIABLES.TABLE.INFO_WIDTHS.COIL_ID_INFO}
              minWidth={VARIABLES.TABLE.INFO_WIDTHS.COIL_ID_INFO}
              title={e.coilId}
            >
              {e.coilId}
            </Table.BodyColumn>
            <Table.BodyColumn
              info
              alignRight
              maxWidth={VARIABLES.TABLE.INFO_WIDTHS.ORDER_M}
              minWidth={VARIABLES.TABLE.INFO_WIDTHS.ORDER_M}
              title={e.orderMWithCommas}
            >
              {e.orderMWithCommas}
            </Table.BodyColumn>
            <Table.BodyColumn
              info
              alignRight
              maxWidth={VARIABLES.TABLE.INFO_WIDTHS.TRUEBLUE_POINTS_NO_SORT}
              minWidth={VARIABLES.TABLE.INFO_WIDTHS.TRUEBLUE_POINTS_NO_SORT}
              title={e.pointsWithComma}
            >
              {e.pointsWithComma}
            </Table.BodyColumn>
            <Table.BodyColumn
              info
              maxWidth={VARIABLES.TABLE.INFO_WIDTHS.SALESPERSON}
              minWidth={VARIABLES.TABLE.INFO_WIDTHS.SALESPERSON}
              title={e.salesPersonName}
            >
              {e.salesPersonName}
            </Table.BodyColumn>
          </Table.Row>
        )
      })
    },
    renderTableBodyIndoToko: () => {
      const {
        dataList,
      } = this.props;
      return dataList.map((e, i) => {
        return (
          <Table.Row
            key={i}
          >
            <Table.BodyColumn
              info
              maxWidth={VARIABLES.TABLE.INFO_WIDTHS.DATE}
              minWidth={VARIABLES.TABLE.INFO_WIDTHS.DATE}
            >
              {e.displayCreateAt}
            </Table.BodyColumn>
            <Table.BodyColumn
              info
              maxWidth={VARIABLES.TABLE.INFO_WIDTHS.SALES_TRANSACTION_ID_NO_SORT}
              minWidth={VARIABLES.TABLE.INFO_WIDTHS.SALES_TRANSACTION_ID_NO_SORT}
              title={e.name}
            >
              {e.name}
            </Table.BodyColumn>
            <Table.BodyColumn
              info
              minWidth={VARIABLES.TABLE.COLUMN_WIDTHS.NAMES.PROFILE_TYPE}
              title={e.profileType}
            >
              {e.profileType}
            </Table.BodyColumn>
            <Table.BodyColumn
              info
              minWidth={VARIABLES.TABLE.COLUMN_WIDTHS.NAMES.PRODUCT_GROUP}
              title={e.fBsproductgroupname}
            >
              {e.fBsproductgroupname}
            </Table.BodyColumn>
            <Table.BodyColumn
              info
              alignRight
              maxWidth={VARIABLES.TABLE.COLUMN_WIDTHS.MEASUREMENTS.LENGTH_NUMBER}
              minWidth={VARIABLES.TABLE.COLUMN_WIDTHS.MEASUREMENTS.LENGTH_NUMBER}
            >
              {e.profileLengthWithComma}
            </Table.BodyColumn>
            <Table.BodyColumn
              info
              alignRight
              maxWidth={VARIABLES.TABLE.COLUMN_WIDTHS.MEASUREMENTS.QUANTITY_UNIT}
              minWidth={VARIABLES.TABLE.COLUMN_WIDTHS.MEASUREMENTS.QUANTITY_UNIT}
            >
              {`${e.profileQuantityWithComma} ${e.profileTypeUnit}`}
            </Table.BodyColumn>
            <Table.BodyColumn
              info
              alignRight
              maxWidth={VARIABLES.TABLE.INFO_WIDTHS.TRUEBLUE_POINTS_NO_SORT}
              minWidth={VARIABLES.TABLE.INFO_WIDTHS.TRUEBLUE_POINTS_NO_SORT}
              title={e.pointsWithComma}
            >
              {e.pointsWithComma}
            </Table.BodyColumn>
            <Table.BodyColumn
              info
              maxWidth={VARIABLES.TABLE.INFO_WIDTHS.SALESPERSON}
              minWidth={VARIABLES.TABLE.INFO_WIDTHS.SALESPERSON}
              title={e.salesPersonName}
            >
              {e.salesPersonName}
            </Table.BodyColumn>
          </Table.Row>
        )
      })
    }
  }

  render() {
    const {
      isIndoToko,
    } = this.props;
    return (
      <Table striped>
        <Table.Section>
          {
            isIndoToko ?
              this.saleTransaction.renderTableHeaderIndoToko()
              :
              this.saleTransaction.renderTableHeader()
          }
        </Table.Section>
        <Table.Section>
          {
            isIndoToko ?
              this.saleTransaction.renderTableBodyIndoToko()
              :
              this.saleTransaction.renderTableBody()
          }
        </Table.Section>
      </Table>
    )
  }
}

const mapStateToProps = (state) => {
  const dataRedux = state.dataRedux;
  return {
    dataList: dataRedux.saleTransList,
    activePage: dataRedux.saleTransParams.activePage,
    totalData: dataRedux.saleTransParams.totalData || 0,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
  }
}

export const SaleTransactionListContainerConnected = connect(
  mapStateToProps,
  mapDispatchToProps
)(SaleTransactionListContainer)