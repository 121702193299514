import React from 'react';
import { storeDispatch } from "../../stores"
import { mainCreators, MAIN_REDUX_STATE_KEY } from "../../actions/mainAction"
import {
  toast as reactToast,
} from 'react-toastify';
import { Notification } from '../../components';
import { MODAL_TYPE } from '../constants/main';

export const isShowLoading = (isShowLoading) => {
  storeDispatch(mainCreators.setMainRedux(MAIN_REDUX_STATE_KEY.IS_SHOW_LOADING, isShowLoading));
}

export const isShowAppLoading = (isShowAppLoading) => {
  storeDispatch(mainCreators.setMainRedux(MAIN_REDUX_STATE_KEY.IS_SHOW_APP_LOADING, isShowAppLoading));
}

export const toast = {
  success: (message) => {
    reactToast(
      (({ closeToast }) =>
        <Notification
          ui='success'
          message={message}
        />
      ),
      {
        // autoClose: false
      }
    )
  },
  error: (message) => {
    reactToast(
      (({ closeToast }) =>
        <Notification
          ui='error'
          message={message}
        />
      ),
      {
        // autoClose: false
      }
    )
  },
  info: (message) => {
    reactToast(
      (({ closeToast }) =>
        <Notification
          ui='info'
          message={message}
        />
      ),
      {
        // autoClose: false
      }
    )
  },
}

export const onOpenModal = (params = {}) => {
  params.onClickCancel = params.onClickCancel ? params.onClickCancel : () => { };
  params.onClickConfirm = params.onClickConfirm ? params.onClickConfirm : () => { };
  const onClickCancel = () => {
    params.onClickCancel();
    onCloseModal();
  }
  const modalData = {
    openModalType: params.type,
    message: params.message,
    data: params.data || {},
    title: params.title,
    buttonCancelName: params.buttonCancelName,
    buttonSubmitName: params.buttonSubmitName,
    isDisableOverlayClick: params.isDisableOverlayClick,
    rewardLists: params.rewardLists,
    onClickCancel,
    onClickConfirm: () => {
      params.onClickConfirm();
      onCloseModal();
    }
  }
  storeDispatch(mainCreators.setMainRedux(MAIN_REDUX_STATE_KEY.MODAL_DATA, modalData));
}

export const onCloseModal = () => {
  const modalData = {
    openModalType: undefined
  };
  storeDispatch(mainCreators.setMainRedux(MAIN_REDUX_STATE_KEY.MODAL_DATA, modalData));
}

export const downloadPathUrl = async (pathUrl = '', fileName = '') => {
  const link = document.createElement('a');
  link.download = fileName;
  link.href = pathUrl;
  link.click();
}

export const downloadData = (exportObj, exportName, type = 'json') => {
  let data = exportObj;
  if (type === 'json') {
    data = JSON.stringify(exportObj, null, 2);
  }
  const dataStr = 'data:text/json;charset=utf-8,' + encodeURIComponent(data);
  const downloadAnchorNode = document.createElement('a');
  downloadAnchorNode.setAttribute('href', dataStr);
  downloadAnchorNode.setAttribute('download', exportName + `.${type}`);
  document.body.appendChild(downloadAnchorNode); // required for firefox
  downloadAnchorNode.click();
  downloadAnchorNode.remove();
}