import styled, {
  css
} from 'styled-components'
import {
  default as VARIABLES
} from './../../../themes/styles/bases/variables'
// import {
//   default as TYPOGRAPHYS
// } from './../../../themes/styles/bases/typographys'
// import {
//   default as MIXINS
// } from './../../../themes/styles/helpers/mixins'
// import {
//   // Example using:
//   // background: url(${CONTENTS['image-RewardRedemptionCard.svg']});
//   CONTENTS,
//   ICONS,
//   LOGOS,
//   SHAREDS,
//   DOCUMENTS
// } from './../../../themes'

// Wrapper
// ============================================================
export const LightboxWrapper = styled.div`
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  /* Parent
  ------------------------------- */
  animation-name: fadeIn;
  animation-duration: 0.3s;
  position: fixed;
  z-index: ${VARIABLES.Z_INDEXS.LV_4};
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  /* Childrens
  ------------------------------- */
  .lightbox-overlay {
    position: absolute;
    z-index: ${VARIABLES.Z_INDEXS.LV_1};
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
  }

  .lightbox-scroll-container {
    overflow: auto;
    position: relative;
    z-index: ${VARIABLES.Z_INDEXS.LV_1};
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
  }

  .lightbox-container {
    position: relative;
    z-index: ${VARIABLES.Z_INDEXS.LV_2};
    padding: 30px 15px;
  }

  .lightbox-content {
    margin: 0 auto 30px;
    background-color: ${VARIABLES.COLORS.WHITE};
  }

  .lightbox-button {
    cursor: pointer;
    transition: ${VARIABLES.TRANSITIONS.DEFAULT};

    &:active {
      opacity: 0.75;
    }

    &.is-close {}
  }

  /* Modifiers
  ------------------------------- */
  &.is-image {
    .lightbox-content {
      max-width: 480px;
      margin: 0 auto 30px;
    }
  }

  /* Media queries
  ------------------------------- */
`
