import _ from 'lodash';
import moment from 'moment';
import { DATA_REDUX_STATE_KEY, dataCreators } from "../../actions/dataAction";
import { storeDispatch, storeGetState } from "../../stores";
import { VARIABLES } from "../../themes";
import { COUNTRY } from "../constants/country";
import { DATA, DATE_MOMENT_FORMAT } from "../constants/data";
import { ENUM } from "../constants/enum";
import { LOCAL_STORAGE } from "../constants/localStorage";
import { getUserAuthRedux } from "./auth";
import { getLanguage } from "./language";
import { getLocalStorage } from "./localStorage";

export const calcFloat = (number1 = 0, opt = '+', number2 = 0) => {
  let lengthDigit = 0;
  const number1List = number1.toString().split('.');
  const number2List = number2.toString().split('.');
  if (number1List[1] && number2List[1]) {
    lengthDigit = number1List[1].length > number2List[1].length ? number1List[1].length : number2List[1].length;
  }
  else if (number1List[1]) {
    lengthDigit = number1List[1].length;
  }
  else if (number2List[1]) {
    lengthDigit = number2List[1].length;
  }
  const factor = Math.pow(10, lengthDigit);
  number1 = number1 * factor;
  number2 = number2 * factor;
  switch (opt) {
    case '+':
      return (number1 + number2) / factor;
    case '-':
      return (number1 - number2) / factor;
  }
}

export const handleSortData = (list, params = {}) => {
  const isDesc = params.orderType === 'desc';
  return list.sort((a, b) => {
    let data1 = a[params.sortTarget];
    let data2 = b[params.sortTarget];
    if (params.sortTargetType === 'date') {
      data1 = new Date(data1).getTime();
      data2 = new Date(data2).getTime();
    }
    if (data2 < data1) {
      return isDesc ? -1 : 1;
    }
    if (data2 > data1) {
      return isDesc ? 1 : -1;
    }
    return 0;
  })
}

export const getCustomerTypeText = (type) => {
  let typeText;
  switch (type) {
    case ENUM.CUSTOMER_TYPE.FIXERS.TYPE:
      typeText = getLanguage('customersManage.form.regis.fixer', '');
      break;
    case ENUM.CUSTOMER_TYPE.HARDWARE.TYPE:
      typeText = getLanguage('customersManage.form.regis.hardware', '');
      break;
    case ENUM.CUSTOMER_TYPE.HOMEOWNER.TYPE:
      typeText = getLanguage('customersManage.form.regis.homeowner', '');
      break;
    case ENUM.CUSTOMER_TYPE.ROLL_FORMER.TYPE:
      typeText = getLanguage('customersManage.form.regis.rollFormer', '');
      break;
    case ENUM.CUSTOMER_TYPE.OTHERS.TYPE:
      typeText = getLanguage('customersManage.form.regis.others', '');
      break;
    case ENUM.CUSTOMER_TYPE.AUTHORISED_DEALER.TYPE:
      typeText = getLanguage('customersManage.form.regis.authorisedDealer', '');
      break;
    case ENUM.CUSTOMER_TYPE.ARCHITECT.TYPE:
      typeText = getLanguage('customersManage.form.regis.architect', '');
      break;
    case ENUM.CUSTOMER_TYPE.ENGINEER.TYPE:
      typeText = getLanguage('customersManage.form.regis.engineer', '');
      break;
    default:
      typeText = ''
      break;
  }
  // Object.values(ENUM.CUSTOMER_TYPE).forEach(e => {
  //   if (e.TYPE === type) {
  //     typeText = e.TEXT;
  //   }
  // })
  return typeText;
}

export const getWarrantyTypeText = (type, statusCode) => {
  let typeText = DATA.WARRANTY_TYPE.DRAFT.TEXT;
  let color = DATA.WARRANTY_TYPE.DRAFT.COLOR;
  if (type === DATA.WARRANTY_TYPE.RE_GENERATE.TEXT) {
    typeText = DATA.WARRANTY_TYPE.RE_GENERATE.TEXT;
    color = DATA.WARRANTY_TYPE.RE_GENERATE.COLOR;
  }
  else {
    Object.values(DATA.WARRANTY_TYPE).forEach(e => {
      if (e.TEXT.toLowerCase() === type.toLowerCase() && e.TYPE === statusCode) {
        typeText = e.TEXT;
        color = e.COLOR;
      }
    });
  }
  if (typeText === DATA.WARRANTY_TYPE.DRAFT.TEXT && statusCode === DATA.WARRANTY_TYPE.DRAFT.TYPE) {
    typeText = '-';
  }
  return {
    typeText,
    color
  };
}

Array.prototype.sum = function () {
  let sum = 0;
  this.forEach(e => {
    sum += e;
  })
  return sum;
};

export const getCountryPhone = (phone = '', countryType = ENUM.COUNTRY_TYPE.THAI) => {
  let country;
  // if (phone) {
  //   DATA.PHONE_PREFIX_LIST.map(e => {
  //     if (phone.toString().indexOf(e.value) === 0) {
  //       country = e;
  //     }
  //   })
  // }
  if (!country) {
    const userAuth = getUserAuthRedux();
    if (userAuth) {
      DATA.PHONE_PREFIX_LIST.map(e => {
        if (userAuth.addressType === e.countryType) {
          country = e;
        }
      })
    }
    else {
      DATA.PHONE_PREFIX_LIST.map(e => {
        if (countryType === e.countryType) {
          country = e;
        }
      })
    }
  }
  return country;
}

export const getPhoneRemovePrefix = (phone = '', countryType) => {
  if (phone) {
    let isSubStr = false;
    let subStrLength = 2;
    const userAuth = getUserAuthRedux();
    phone = phone.replace(/-/g, '');
    phone = phone.replace(/ /g, '');
    let phoneList = phone.split(',');
    phoneList = phoneList.map(ephone => {
      DATA.PHONE_PREFIX_LIST.forEach(e => {
        ephone = ephone.replace(e.label, '');
        if (
          ((userAuth || {}).addressType === e.countryType ||
            countryType === e.countryType) &&
          ephone.toString().indexOf(e.value) === 0
        ) {
          isSubStr = true;
          subStrLength = e.subStrLength;
        }
      });
      if (isSubStr) {
        ephone = ephone.toString().substring(subStrLength, ephone.length);
      }
      if (ephone[0] === '0') {
        ephone = ephone.toString().substring(1, ephone.length);
      }
      return ephone;
    });
    return phoneList.join(', ');
  }
  return ''
}

export const getProvince = (value) => {
  if (value) {
    let province = {
      label: value,
      value
    };
    const countryList = storeGetState().dataRedux.countryList;
    const findProvince = countryList.filter(e => e.provinceCode.toString() === value.toString())[0];
    if (findProvince) {
      province = findProvince;
    }
    return province;
  }
  return undefined;
}

export const getCity = (value) => {
  if (value) {
    const countryList = storeGetState().dataRedux.countryList;
    let city = {
      label: value,
      value
    };
    countryList.forEach(e => {
      e.cityList.forEach(ec => {
        if (ec.cityCode.toString() === value.toString()) {
          city = ec;
        }
      });
    });
    return city;
  }
  return undefined;
}

export const getSubDistrictOption = (city, subDistrict) => {
  let result = undefined;
  if (city && city.subDistrict) {
    city.subDistrict.forEach(e => {
      if (e.name === subDistrict) {
        result = {
          ...e,
          label: e.name,
          value: e.name,
        }
      }
    });
    if (!result) {
      let findSubDistrictId;

      [...COUNTRY.THAI.CITY_LIST, ...COUNTRY.THAI_EN.CITY_LIST].forEach(e => {
        if (e.cityCode.toString() === city.value.toString()) {
          e.subDistrict.forEach(es => {
            if (es.name === subDistrict) {
              findSubDistrictId = es.id;
            }
          });
        }
      });

      [...COUNTRY.THAI.CITY_LIST, ...COUNTRY.THAI_EN.CITY_LIST].forEach(e => {
        if (e.cityCode.toString() === city.value.toString()) {
          e.subDistrict.forEach(es => {
            if (es.id === findSubDistrictId && es.name !== subDistrict) {
              result = {
                ...es,
                label: es.name,
                value: es.name,
              }
            }
          });
        }
      });
    }
  }
  return result;
}

export const getSubDistrictThaiByLabel = (subDistrictText, selectedCity) => {
  let result;
  if (selectedCity) {
    let findSubDistrictId;

    [...COUNTRY.THAI.CITY_LIST, ...COUNTRY.THAI_EN.CITY_LIST].forEach(e => {
      if (selectedCity.cityCode === e.cityCode) {
        e.subDistrict.forEach(es => {
          if ((es.name || '').toLowerCase() === (subDistrictText || '').toLowerCase()) {
            findSubDistrictId = es.id;
          }
        });
      }
    });

    selectedCity.subDistrict.forEach(e => {
      if (e.id === findSubDistrictId) {
        result = {
          ...e,
          label: e.name,
          value: e.name,
        }
      }
    })
  }

  return result;
}

export const getProvinceByLabel = (value) => {
  if (value) {
    const countryList = storeGetState().dataRedux.countryList;
    const province = countryList.filter(e => e.province.toString() === value.toString())[0];
    if (!province) {
      return getProvince(value);
    }
    return province;
  }
  return undefined;
}

export const getProvinceThaiByLabel = (value) => {
  if (value) {
    const province = [...getCountryDataList('THAI'), ...getCountryDataList('THAI_EN')].filter(e => e.province.toString() === value.toString())[0];
    if (!province) {
      return getProvince(value);
    }
    return province;
  }
  return undefined;
}

export const getCityThaiByLabel = (valueCity, selectedProvince) => {
  if (valueCity) {
    let city;
    if (selectedProvince) {
      [...COUNTRY.THAI.PROVINCE_LIST, ...COUNTRY.THAI_EN.PROVINCE_LIST].forEach(e => {
        if (e.provinceCode === selectedProvince.provinceCode) {
          e.cityList.forEach(ec => {
            if (ec.city.toString() === valueCity.toString()) {
              city = ec;
            }
          });
        }
      });
    }
    // if (!city) {
    //   return getCity(valueCity);
    // }
    return city;
  }
  return undefined;
}

export const getCityByLabel = (value) => {
  if (value) {
    const countryList = storeGetState().dataRedux.countryList;
    let city;
    countryList.forEach(e => {
      e.cityList.forEach(ec => {
        if (ec.city.toString() === value.toString()) {
          city = ec;
        }
      });
    });
    if (!city) {
      return getCity(value);
    }
    return city;
  }
  return undefined;
}

export const getCountryType = (type) => {
  switch (type) {
    case ENUM.COUNTRY.THAI:
      return ENUM.COUNTRY_TYPE.THAI;
    case ENUM.COUNTRY.MALAYSIA:
      return ENUM.COUNTRY_TYPE.MALAYSIA;
    case ENUM.COUNTRY.VIETNAM:
      return ENUM.COUNTRY_TYPE.VIETNAM;
    case ENUM.COUNTRY.INDONESIA:
      return ENUM.COUNTRY_TYPE.INDONESIA;
    case ENUM.COUNTRY.LAOS:
      return ENUM.COUNTRY_TYPE.LAOS;
    default:
      return ENUM.COUNTRY_TYPE.THAI;
  }
}

export const getCountryText = (type) => {
  switch (type) {
    case ENUM.COUNTRY_TYPE.THAI:
      return ENUM.COUNTRY.THAI;
    case ENUM.COUNTRY_TYPE.MALAYSIA:
      return ENUM.COUNTRY.MALAYSIA;
    case ENUM.COUNTRY_TYPE.VIETNAM:
      return ENUM.COUNTRY.VIETNAM;
    case ENUM.COUNTRY_TYPE.INDONESIA:
      return ENUM.COUNTRY.INDONESIA;
    case ENUM.COUNTRY_TYPE.LAOS:
      return ENUM.COUNTRY.LAOS;
    default:
      return ENUM.COUNTRY.THAI;
  }
}

const getCountryDataList = (target) => {
  return _.sortBy(COUNTRY[target].PROVINCE_LIST, 'province').map(e => {
    e.cityList = COUNTRY[target].CITY_LIST.filter(ec => ec.provinceCode === e.provinceCode).map(ec => {
      ec.value = ec.cityCode;
      ec.label = ec.city;
      ec.subDistrict = (ec.subDistrict || []).map(ect => {
        ect.postCodeList = ect.postCode.map(ectp => {
          return {
            label: ectp,
            value: ectp,
          };
        });
        return {
          ...ect,
          label: ect.name,
          value: ect.name,
        }
      });
      return ec
    });
    e.cityList = _.sortBy(e.cityList, 'city');
    e.value = e.provinceCode;
    e.label = e.province;
    return e;
  })
}

export const setCountryDataList = (type = ENUM.COUNTRY_TYPE.THAI) => {
  let target = 'THAI';
  const language = getLocalStorage(LOCAL_STORAGE.LANGUAGE_RBPTRUEBLUE);
  switch (type) {
    case ENUM.COUNTRY_TYPE.THAI:
      switch (language) {
        case DATA.LANGUAGE.THAI:
          target = 'THAI';
          break;
        default:
          target = 'THAI_EN';
          break;
      }
      break;
    case ENUM.COUNTRY_TYPE.MALAYSIA:
      target = 'MALAYSIA';
      break;
    case ENUM.COUNTRY_TYPE.VIETNAM:
      target = 'VIETNAM';
      break;
    case ENUM.COUNTRY_TYPE.INDONESIA:
      target = 'INDONESIA';
      break;
    default:
      target = 'THAI';
      break;
  }
  const countryList = getCountryDataList(target);
  console.log('countryList', countryList, type, target)
  storeDispatch(dataCreators.setDataRedux(DATA_REDUX_STATE_KEY.COUNTRY_LIST, countryList));
}

export const getMomentStartOf = (date, type = 'day') => {
  return moment(date).startOf(type)._d
}

export const getMomentEndOf = (date, type = 'day') => {
  return moment(date).endOf(type)._d
}

export const getDateApiRequet = (date) => {
  return moment(date).format(DATE_MOMENT_FORMAT);
}

export const getTodayDateDiff = (date, type = 'day') => {
  return moment(moment().format(DATE_MOMENT_FORMAT)).diff(date, type)
}

export const getSortAscending = (dataParams, target = 'id') => {
  return dataParams.sort.indexOf(target) === -1 ||
    dataParams.sort.indexOf('desc') > 0
}

export const handleSortParams = (currentParams, newParams) => {
  if (currentParams.page === newParams.page) {
    if (currentParams.sort === newParams.sort) {
      const sortList = newParams.sort.split(',');
      let sortResultList = [];
      sortList.forEach(e => {
        const sort = e.trim();
        const sortParamsList = sort.split(' ');
        if (sortParamsList.length > 1) {
          sortResultList.push(sortParamsList[0]);
        }
        else {
          sortResultList.push(`${sort} desc`);
        }
      });
      newParams.sort = sortResultList.join(', ');
    }
  }
  return newParams;
}

export const getCurrentTimeStamp = () => {
  return moment().unix() * 1000;
}

export const handleTrimParams = (data = {}) => {
  Object.keys(data).forEach(key => {
    if (typeof data[key] === typeof '') {
      data[key] = data[key].trim();
    }
  })
  return data;
}

export const getAdminRoleByCountryType = () => {
  const userAuth = getUserAuthRedux();
  switch (userAuth.addressType) {
    case ENUM.COUNTRY_TYPE.THAI:
      return ENUM.ADMIN_ROLE.TH;
    case ENUM.COUNTRY_TYPE.MALAYSIA:
      return ENUM.ADMIN_ROLE.ML;
    case ENUM.COUNTRY_TYPE.VIETNAM:
      return ENUM.ADMIN_ROLE.IN;
    case ENUM.COUNTRY_TYPE.INDONESIA:
      return ENUM.ADMIN_ROLE.VI;
    case ENUM.COUNTRY_TYPE.LAOS:
      return ENUM.ADMIN_ROLE.TH;
    default:
      return ENUM.ADMIN_ROLE.TH;
  }
}

export const convertInt = (data = '') => {
  if (data) {
    let result = parseInt(data);
    return result ? result : 0;
  }
  return 0;
}

export const convertFloat = (data = '', decimal = 0) => {
  if (data) {
    let result = parseFloat(data);
    if (result) {
      if (data.toString().indexOf('.') >= 0) {
        result = decimal === 0 ? result : parseFloat(result.toFixed(decimal));
      }
    }
    return result ? result : 0;
  }
  return 0;
}

export const canvasMergeImageFile = (list = []) => {
  return new Promise(resolve => {

    let cnt = 0;
    let canvas = document.createElement("canvas");
    let ctx = canvas.getContext("2d");
    let oldHeight = 0;
    let cntTotalImageHeight = 0;
    let totalImageHeight = 0;
    let maxWidth = 0;
    list.forEach(e => {
      const img = new Image();
      img.onload = async () => {
        maxWidth = maxWidth > img.width ? maxWidth : img.width;
        totalImageHeight += img.height;
        cntTotalImageHeight += 1;
        if (cntTotalImageHeight === list.length) {
          canvas.width = maxWidth;
          canvas.height = totalImageHeight;

          const renderImage = (e) => {
            return new Promise(resolve => {
              const img = new Image();
              img.onload = () => {
                ctx.drawImage(img, 0, oldHeight);
                oldHeight += img.height;
                resolve();
              };
              img.src = URL.createObjectURL(e);
            })
          }

          for (let i = 0; i < list.length; i++) {
            await renderImage(list[i]);
            cnt += 1;
            if (cnt === list.length) {
              canvas.toBlob(blob => {
                resolve(blob);
              }, 'image/png');
            }
          }
        }
      };
      img.src = URL.createObjectURL(e);
    })

  })
}

export const getCustomerTrainedTier = (data) => {
  let ui = '';
  let textColor = '';
  let text = '';
  switch (data) {
    case ENUM.CUSTOMER_TRAINED_TIER.GOLD.TEXT:
      ui = 'iconMedalGold';
      textColor = VARIABLES.COLORS.BROWN_3;
      text = getLanguage('customers.table.body.gold', '');
      break;
    case ENUM.CUSTOMER_TRAINED_TIER.SILVER.TEXT:
      ui = 'iconMedalSilver';
      textColor = VARIABLES.COLORS.GRAY_16;
      text = getLanguage('customers.table.body.silver', '');
      break;
    case ENUM.CUSTOMER_TRAINED_TIER.BRONZE.TEXT:
      ui = 'iconMedalBronze';
      textColor = VARIABLES.COLORS.RED_7;
      text = getLanguage('customers.table.body.bronze', '');
      break;
    default:
      return undefined;
  }
  return {
    ui,
    textColor,
    text,
  }
}

export const convertString = (data) => {
  return data === 'undefined' || data === 'null' ? '' : data || '';
};


export const imageUrltoBase64 = (url, callback) => {
  return new Promise(resolve => {
    var xhr = new XMLHttpRequest();
    xhr.onload = function () {
      var reader = new FileReader();
      reader.onloadend = function () {
        resolve(reader.result);
      }
      reader.readAsDataURL(xhr.response);
    };
    xhr.open('GET', url);
    xhr.responseType = 'blob';
    xhr.send();
  });
}

export const getStyleSelectControl = () => {
  return {
    input: (provided) => ({
      ...provided,
      input: {
        opacity: "1 !important",
      },
    }),
  }
}

export const getAllNumberInString = (text) => {
  const r = /\d+/g;
  return (text.match(r) || []).map(e => parseFloat(e));
}