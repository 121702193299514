import {
  createActions
} from 'reduxsauce'
import { IS_MAINTENANCE, MAINTENANCE_TIME, MAINTENANCE_USER_WHITE_LIST } from '../config/maintenance'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  setMainRedux: ['key', 'data'],
})

export const MAIN_REDUX_TYPES = Types
export const mainCreators = Creators

/* ------------- Initial State ------------- */
export const MAIN_REDUX_STATE_KEY = {
  USER_AUTH: 'userAuth',
  IS_SHOW_LOADING: 'isShowLoading',
  IS_SHOW_APP_LOADING: 'isShowAppLoading',
  MODAL_DATA: 'modalData',
  LANGUAGE: 'language',
  TNC: 'tnc',
  MAINTENANCE: 'maintenance'
}

export const MAIN_REDUX_INITIAL_STATE = {
  userAuth: {},
  isShowLoading: false,
  isShowAppLoading: false,
  modalData: {},
  language: '',
  tnc: {},
  maintenance: {
    isMaintenance: IS_MAINTENANCE,
    apiBeforeLoginWhiteList: [
      '/login',
      '/otp/verify'
    ],
    userWhiteList: MAINTENANCE_USER_WHITE_LIST,
    textTime: MAINTENANCE_TIME,
  }
}