import { isValidResponse } from "../functions/validation";
import { storeDispatch, storeGetState } from "../../stores";
import { dataCreators, DATA_REDUX_STATE_KEY } from "../../actions/dataAction";
import { cmsTncController } from "../../apiService/apiController/cmsTncService";
import { ModelCMSTnc } from "../../models/ModelCMSTnc";
import { ENUM } from "../constants/enum";
import { handleSortParams, handleTrimParams, getAdminRoleByCountryType } from "../functions/data";
import { getUserAuthRedux } from "../functions/auth";

export const getCMSTncList = async (params = {}) => {
  const userAuth = getUserAuthRedux();
  let currentParams = storeGetState().dataRedux.cmsTncParams;
  let newParams = handleTrimParams({
    ...currentParams,
    ...params,
    app: ENUM.CMS_TYPE.TRUE_BLUE,
    available_country: userAuth.addressType,
    // created_by_admin_role: getAdminRoleByCountryType(),
  });
  if (!newParams.sort) {
    newParams.sort = 'id desc';
  }
  else {
    newParams = handleSortParams(currentParams, newParams);
  }
  const cmsTncService = cmsTncController();
  const res = await cmsTncService.searchTermsCondition(newParams);
  if (isValidResponse(res)) {
    const dataList = res.data.map(e => new ModelCMSTnc(e));
    newParams.activePage = newParams.page;
    newParams.totalData = res.total_items;
    console.log('dataList', res.data, dataList, newParams);
    storeDispatch(dataCreators.setDataRedux(DATA_REDUX_STATE_KEY.CMS_TNC_LIST, dataList));
    storeDispatch(dataCreators.setDataRedux(DATA_REDUX_STATE_KEY.CMS_TNC_PARAMS, newParams));
    return dataList;
  }
}