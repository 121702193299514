import { DATE_TIME_FORMAT } from "../helpers/constants/data";
import { displayDate, displayWithPrefix } from "../helpers/functions/display";
import { ModelImage } from "./ModelImage";

export class ModelNotificationManagement {
  constructor(props = {}) {
    this.id = props.id;
    this.subject = props.subject;
    this.content = props.content;
    this.publicDate = props.public_date;
    this.imgPath = props.img_path;
    this.isActive = props.is_active;

    this.idWithPrefix = displayWithPrefix(props.id);
    this.displayPublicDate = this.publicDate ? displayDate(this.publicDate, '/', DATE_TIME_FORMAT) : '-';
    this.notiImage = new ModelImage({ path: this.imgPath || '' });
  }
}

export class ModelNotificationManagementSearch {
  constructor(props = {}) {
    this.id = props.id;
    this.notificationId = props.notification_id;
    this.rbpMemberSfid = props.rbp_member_sfid;
    this.locationAccount__c = props.location_account__c;
    this.createdBy = props.created_by;
    this.createdDate = props.created_date;
    this.updatedBy = props.updated_by;
    this.updatedDate = props.updated_date;
    this.subject = props.subject;
    this.publicDate = props.public_date;
    this.content = props.content;
    this.imgPath = props.img_path;

    this.idWithPrefix = displayWithPrefix(this.notificationId);
    this.displayPublicDate = this.publicDate ? displayDate(this.publicDate, '/', DATE_TIME_FORMAT) : '-';
    this.displayAcceptedDate = this.createdDate ? displayDate(this.createdDate, '/', DATE_TIME_FORMAT) : '-';
    this.notiImage = new ModelImage({ path: this.imgPath || '' });
  }
}