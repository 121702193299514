import { DATE_FORMAT, DATE_TIME_FORMAT } from "../helpers/constants/data";
import { getCountryPhone, getExtensionFileIcon, getPhoneRemovePrefix, isImageFile } from "../helpers/functions/data";
import { displayDate, displayUrlPath } from "../helpers/functions/display";
import { VARIABLES } from "../themes";

export class ModelProjectReference {
  constructor(props = {}) {
    this.referenceId = props.reference_id;
    this.referenceName = props.reference_name;
    this.createdDate = props.created_date;
    this.updatedDate = props.updated_date;
    this.isAdmin = props.is_admin;

    this.id = this.referenceId;
    this.displayCreatedDate = displayDate(this.createdDate, '/', DATE_FORMAT);
    this.displayUpdatedDate = displayDate(this.updatedDate, '/', DATE_FORMAT);
  }
}

export class ModelProjectReferenceDetailFile {
  constructor(props = {}, id = '') {
    this.id = id;
    this.referenceDtFileName = (props.reference_dt_file_name || '');
    this.referenceDtPath = (props.reference_dt_path || '');
    this.createdDate = (props.created_date || '');
    this.isAdminCreate = !!props.is_admin;
    const getExtensionFileIcon = (fileType) => {
      if (fileType) {
        return `ic-file-${fileType.toLowerCase()}.svg`;
      }
      return `ic-file-doc.svg`;
    }

    this.fileExtension = this.referenceDtFileName.split('.').pop();
    this.displayCreatedDate = displayDate(this.createdDate, '/', DATE_TIME_FORMAT);
    this.filePath = this.referenceDtPath;
    this.fileUrl = displayUrlPath(this.referenceDtPath);
    this.fileIcon = getExtensionFileIcon(this.fileExtension);
    this.isImage = this.fileExtension === 'jpg' ||
      this.fileExtension === 'jpeg' ||
      this.fileExtension === 'png' ||
      this.fileExtension === 'gif';
    this.isPdf = this.fileExtension === 'pdf';
    this.isOtherFile = !this.isImage && !this.isPdf;
    this.isSelected = false;
  }
}

export class ModelProjectReferenceDetail {
  constructor(props = {}) {
    this.id = props.reference_id;
    this.referenceId = props.reference_id;
    this.preSalesTransId = props.pre_sales_trans_id;
    this.wrtId = props.wrt_id;
    this.referenceName = (props.reference_name || '');
    this.referenceDesc = (props.reference_desc || '');
    this.referenceProjectName = (props.reference_project_name || '');

    this.referenceDetailList = (props.reference_detail || []).map((e,i) => new ModelProjectReferenceDetailFile(e, i));
  }
}


export class ModelProjectReferenceProject {
  constructor(props = {}) {
    this.wrtId = props.wrt_id;
    this.wrtNo = props.wrt_no;
    this.projectName = props.project_name;
    this.salesTransactionId = props.sale_transaction_id;
    this.preSalesTransId = props.pre_sales_trans_id;
    this.preSalesTransProjectName = props.pre_sales_trans_project_name;
  }
}
