import React from 'react'
import ClassNames from 'classnames'
import PropTypes from 'prop-types'
import {
  NewsPromotionsListWrapper
} from './styled'
import {
  Section,
  Image,
  Text
} from './../../../components'
import {
  default as VARIABLES
} from './../../../themes/styles/bases/variables'
// import {
//   default as TYPOGRAPHYS
// } from './../../../themes/styles/bases/typographys'
// import {
//   default as UTILITIES
// } from './../../../themes/styles/helpers/utilities'
import {
  ICONS
} from './../../../themes'

/**
 * NewsPromotionsList description:
 * - NewsPromotionsList
 */

export class NewsPromotionsList extends React.PureComponent {
  render () {
    const {
      className,
      src,
      title,
      labelNews,
      labelPromotions,
      onClick
    } = this.props

    // props for css classes
    const classes = ClassNames(
      'news-promotions-list',
      {'is-cursor': onClick},
      className
    )

    return (
      <NewsPromotionsListWrapper
        className={classes}
        onClick={onClick}
      >
        <Section
          relative
          spacingBottom='15'
        >
          <Image src={src} />
          {(labelNews || labelPromotions) &&
            <div className={ClassNames(
              'news-promotions-list-label',
              {'is-news': labelNews},
              {'is-promotions': labelPromotions}
            )}>
              {labelNews &&
                <Image
                  widthRatio='79'
                  widthRatioUnit='px'
                  heightRatio='48'
                  heightRatioUnit='px'
                  src={ICONS['ic-label-news.svg']}
                />
              }

              {labelPromotions &&
                <Image
                  widthRatio='143'
                  widthRatioUnit='px'
                  heightRatio='62'
                  heightRatioUnit='px'
                  src={ICONS['ic-label-promotions.svg']}
                />
              }
            </div>
          }
        </Section>
        <Section>
          <Text
            bold14
            className='news-promotions-list-title'
            color={VARIABLES.COLORS.BLACK}
          >
            <span className='news-promotions-list-title-ellipsis'>
              {title}
            </span>
          </Text>
        </Section>
      </NewsPromotionsListWrapper>
    )
  }

  static defaultProps = {
    // * If you set new default props, you should create propsType for correct styleguide document.
  }

  static propTypes = { // TYPE > node, string, number, bool, array, object, symbol, func
    /**
    * Additional classes
    */
    className: PropTypes.string
  }
}
