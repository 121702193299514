import _ from 'lodash';
import React from 'react';
import {
  connect
} from 'react-redux';
import { MAIN_REDUX_STATE_KEY } from '../../actions/mainAction';
import { productController } from '../../apiService/apiController/productService';
import { profileController } from '../../apiService/apiController/profileService';
import { projectDocumentController } from '../../apiService/apiController/projectDocumentService';
import { saleTransController } from '../../apiService/apiController/saleTransService';
import { warrantyController } from '../../apiService/apiController/warrantyService';
import { DATA } from '../../helpers/constants/data';
import { ENUM } from '../../helpers/constants/enum';
import { ROUTE_PATH } from '../../helpers/constants/route';
import { getUserAuthRedux } from '../../helpers/functions/auth';
import { displayFloatDigit } from '../../helpers/functions/display';
import { getLanguage } from '../../helpers/functions/language';
import { isShowLoading, toast } from '../../helpers/functions/main';
import { redirectData } from '../../helpers/functions/route';
import { isValidResponse } from '../../helpers/functions/validation';
import { checkIsCanSelectResidentialCategory, getCloseChooseCategoryWarrantyModalData, getDefaultChooseCategoryWarrantyModalData, getOpenChooseCategoryWarrantyModalData, handleSubmitCategoryWarrantyModal, onChangeProjectCategoryWarrantyModalData } from '../../helpers/functions/warranty';
import { getClearSaleTransParams, getSaleTransList } from '../../helpers/services/saleTrans';
import { getClearWarrantyParams, getWarrantyDetailList, getWarrantyList, getWarrantyTransManageParams } from '../../helpers/services/warranty';
import { ModalChooseProjectCategoryContainer, ModalLinkProjectDocumentContainer } from '../ModalContainer';
import { OrderSummaryListContainerConnected } from '../OrderSummaryListContainer/OrderSummaryListContainer';
import { SaleTransactionListContainerConnected } from '../SaleTransactionListContainer';
import {
  Button, Grid, Image, Section, ShapeContainer, Text, TitleSection
} from './../../components';
import {
  ICONS, VARIABLES
} from './../../themes';

class SalesOrdersCustomerContainer extends React.Component {

  constructor(props) {
    super(props);
    const userAuth = getUserAuthRedux();
    this.state = {
      isShowHintDeleteTransaction: false,
      userAuth,
      m3ProductGroupFilter: '',

      modalData: getDefaultChooseCategoryWarrantyModalData(),
      warrantyDraftIdList: [],
      warrantyData: undefined,
      warrantyDataList: [],

      isOpenLinkProjectDocumentModal: false,
    }
  }

  saleOrderCustomer = {
    onClickGenerateWarranty: async () => {
      const {
        warrantyDraftIdList,
        modalData,
      } = this.state;
      const params = {
        ...getClearWarrantyParams(),
        page: 1,
        limit: warrantyDraftIdList.length,
        wrt_id: warrantyDraftIdList,
      };
      const configService = {
        isShowLoading: false,
      }
      isShowLoading(true)
      let selectedDataList = await getWarrantyList(params, configService);
      if (selectedDataList.length) {
        selectedDataList = await getWarrantyDetailList(selectedDataList)
        isShowLoading(false)

        const warrantyData = {
          ...selectedDataList[0],
        }
        warrantyData.isCanSelectResidentialCategory = checkIsCanSelectResidentialCategory(selectedDataList);
        this.setState({
          warrantyData,
          warrantyDataList: selectedDataList,
        }, () => {
          // CR: 1490
          // if (warrantyData.projectCountry === ENUM.COUNTRY_TYPE.THAI) {
          //   modalData.wrtCat = DATA.WARRANTY_CATEGORY_TYPE.NON_RESIDENTIAL.TYPE;
          //   this.setState({
          //     modalData,
          //   }, () => {
          //     this.modal.onClickSubmitCategory()
          //   })
          // }
          // else {
          this.modal.onClickOpenChooseCategoryModal();
          // }
        })
      }
      else {
        isShowLoading(false)
        toast.error(`Can't found warranty`);
      }
    },
    onClickSaveOrder: async () => {
      const {
        data,
        productList,
        selectSalePerson,
        userAuth,
        isMalay,
        onClickSaveOrder,
        preTransData,
      } = this.props;
      // const isMalay = userAuth.addressType === ENUM.COUNTRY_TYPE.MALAYSIA;
      if (!selectSalePerson) {
        toast.error(getLanguage('message.pleaseInsertAllRequiredField', ''));
      }
      // else if (isMalay && parseInt(data.pointsRevenue || 0) <= 0) {
      //   toast.error(getLanguage('message.pleaseInsertAllRequiredField', ''));
      // }
      else if (selectSalePerson.data.memberType === 'Contractor') {
        toast.error(getLanguage('message.errorSalesPersonMemberTypeIsContractor', ''));
      }
      else {
        isShowLoading(true);

        console.log('productList', productList);

        const params = {
          products: productList.map(e => {
            // const pointsRevenue = displayFloatDigit(parseInt(data.pointsRevenue || 0) / productList.length)
            const pointsRevenue = displayFloatDigit(e.revenueValue || 0);
            return {
              coil_name: e.itemDescription,
              coil_id: e.lotNumber,
              order_m: e.orderValue,
              // points: e.points,
              points: 0,
              // points_revenue: data.phoneCountry.country === ENUM.COUNTRY.MALAYSIA ? pointsRevenue : 0.0
              points_revenue: isMalay ? pointsRevenue : 0.0
            }
          }),
          sales_person_id: selectSalePerson.data.id,
          sales_person_name: selectSalePerson.data.fullname,
          customer_id: data.id,
          customer_name: data.fullname,
          phone_no: data.phoneCountry.value + data.phone,
          ad_ref_id: userAuth.id,
        }
        const configService = {
          isShowLoading: false,
        }
        const saleTransService = saleTransController(configService);
        const res = await saleTransService.createSalesTransaction(params);
        if (isValidResponse(res)) {
          const saleTransList = _.get(res, 'data', []).map((e, i) => {
            return {
              ...e,
              productData: productList[i],
            }
          });
          console.log('saleTransList', res.data, saleTransList);

          this.saleOrderCustomer.handleManageProduct(saleTransList, () => {
            this.saleOrderCustomer.handleManageWarrantyTrans(saleTransList, async () => {
              // link project document if navigate from menu project document
              if (preTransData) {
                this.saleOrderCustomer.handleLinkProjectDocument(saleTransList);
              }

              getSaleTransList({
                ...getClearSaleTransParams(),
                customer_id: data.id
              });

              await onClickSaveOrder();

              isShowLoading(false);
              // console.log(MESSAGE.CREATE_PRODUCT_COMPLETED);
              toast.success(getLanguage('message.createSalesTransactionCompleted', ''));

              this.setState({
                isShowHintDeleteTransaction: true,
              }, () => {
                setTimeout(() => {
                  this.setState({
                    isShowHintDeleteTransaction: false,
                  });
                }, 10000);
              });
            });
          });
        }
        else {
          toast.error(res.message);
          isShowLoading(false);
        }
      }
    },
    handleLinkProjectDocument: async (saleTransList) => {
      const {
        preTransData,
      } = this.props;
      const {
        userAuth,
      } = this.state;
      const params = saleTransList.map(e => {
        return {
          pre_sales_trans_id: preTransData.data.id,
          sales_trans_id: e.id,
          created_by: userAuth.name
        }
      });
      const configService = {
        isShowLoading: false,
      }
      const projectDocumentService = projectDocumentController(configService);
      await projectDocumentService.projectDocumentLinkSaleTrans(params);
    },
    handleManageWarrantyTrans: async (saleTransList, callback = () => { }) => {
      return new Promise((resolve) => {
        const {
          data,
          preTransData,
        } = this.props;
        const {
          userAuth,
        } = this.state;
        this.setState({
          warrantyDraftIdList: [],
        }, async () => {
          // for generate warranty after create sale trans
          const warrantyDraftIdList = [];
          let cnt = 0;
          saleTransList.forEach(async (e) => {
            let params = {
              customerData: data,
              userRefId: data.userRefId,
              customerId: data.id,
              productList: [{
                sales_tran_id: e.id,
                wrt_path: ''
              }],
              wrtLength: e.productData.orderValue,
              preTransData,
            }
            const configService = {
              isShowLoading: false,
            }
            params = getWarrantyTransManageParams(params);
            const warrantyService = warrantyController(configService);
            const res = await warrantyService.createWarrantyTran(params);
            if (isValidResponse(res)) {
              // can generate warranty for thai 
              if (userAuth.addressType === ENUM.COUNTRY_TYPE.THAI) {
                warrantyDraftIdList.push(res.wrt_id);
              }
              cnt += 1;
              console.log('Warranty is successfully created.');
              if (cnt === saleTransList.length) {
                this.setState({
                  warrantyDraftIdList
                }, () => {
                  resolve(true);
                  callback();
                });
              }
            }
            else {
              isShowLoading(false);
              toast.error(res.message);
              resolve(false);
            }
          });
        });
      });
    },
    handleManageProduct: async (saleTransList, callback = () => { }) => {
      const {
        data,
        productList,
        userAuth,
        isIndoToko,
        isMalay,
      } = this.props;
      const params = await (new Promise((resolve) => {
        let cnt = 0;
        let paramsProductList = []
        productList.forEach(async (e, i) => {
          // const pointsRevenue = displayFloatDigit(parseInt(data.pointsRevenue || 0) / productList.length)
          const pointsRevenue = displayFloatDigit(e.revenueValue || 0);
          const product = {
            member__c: data.id,
            external_id__c: saleTransList[i].id,
            dealer__c: userAuth.adRefId,
            coil_length__c: e.orderValue,
            sales_transaction__c: e.sfid,
            // myr__c: data.phoneCountry.country === ENUM.COUNTRY.MALAYSIA ? pointsRevenue : 0.0,
            myr__c: isMalay ? pointsRevenue : 0.0,
          }
          if (isIndoToko) {
            product.profile_quantity__c = e.orderValue;
            product.profile_type__c = e.profileType;
            product.profile_length__c = e.profileLength;
            const paramsCal = {
              product_type: e.profileType,
              width: e.coilWidthMM,
              quantity: e.orderValue,
              length: e.profileLength
            }
            const configService = {
              isShowLoading: false,
            }
            const profileService = profileController(configService);
            const resCal = await profileService.calculate(paramsCal);
            console.log('resCal', resCal)
            if (isValidResponse(resCal)) {
              if (resCal.total_length === null) {
                toast.error('Total length null');
              }
              else {
                product.coil_length__c = resCal.total_length;
                paramsProductList.push(product);
                cnt++;
              }
            }
          }
          else {
            paramsProductList.push(product);
            cnt++;
          }
          if (cnt === productList.length) {
            resolve({
              products: paramsProductList,
            });
          }
        });
      }));
      const configService = {
        isShowLoading: false,
      }
      const productService = productController(configService);
      const res = await productService.addProductT1(params);
      if (isValidResponse(res)) {
        callback();
      }
      else {
        toast.error(res.message);
        isShowLoading(false);
      }
    },
    onClickOpenLinkProjectDocumentModal: () => {
      this.setState({
        isOpenLinkProjectDocumentModal: true,
      })
    }
  }

  modal = {
    onCloseOpenLinkProjectDocumentModal: () => {
      this.setState({
        isOpenLinkProjectDocumentModal: false,
      })
    },
    onClickOpenChooseCategoryModal: () => {
      const {
        modalData,
      } = this.state;
      this.setState({
        modalData: getOpenChooseCategoryWarrantyModalData(modalData),
      });
    },
    onCloseModal: () => {
      const {
        modalData,
      } = this.state;
      this.setState({
        modalData: getCloseChooseCategoryWarrantyModalData(modalData),
      })
    },
    onClickSubmitCategory: async () => {
      const {
        modalData,
        warrantyDataList,
      } = this.state;
      let selectedDataList = _.cloneDeep(warrantyDataList);
      handleSubmitCategoryWarrantyModal(selectedDataList, modalData, (data) => {
        redirectData(`${ROUTE_PATH.WARRANTY.LINK}/${data.id}`, {
          data: {
            ...data,
            handleGoBackPath: window.location.href.replace(window.location.origin, ''),
          },
        });
        this.modal.onCloseModal();
      });
    },
    onChangeProjectCategory: (type) => {
      const {
        modalData,
      } = this.state;
      this.setState({
        modalData: onChangeProjectCategoryWarrantyModalData(modalData, type),
      })
    },
  }

  render() {
    const {
      data,
      productList,
      handleClearCustomer,
      summaryPoint,
      onChangeCustomerDataInput,
      isIndoToko,
      isMalay,
      onClickOpenProductList,
      preTransData,
      onClickBackProjectDocumentManage,
    } = this.props;
    const {
      isShowHintDeleteTransaction,
      userAuth,
      warrantyDraftIdList,
      modalData,
      warrantyData,
      isOpenLinkProjectDocumentModal,
    } = this.state;
    console.log('productList', productList)
    console.log('preTransData', preTransData)
    return (
      <React.Fragment>
        <Section spacingBottom='25'>
          <TitleSection name={getLanguage('container.latestSaleTransaction.titleSaleTransaction', '')} />
        </Section>
        <Section spacingBottom='40'>
          {isShowHintDeleteTransaction && <ShapeContainer fluid paddingVertical='10' paddingHorizontal='15' spacingBottom='20' bgColor={VARIABLES.COLORS.PRIMARY_1} borderRadius='5'>
            <Grid gutter='15' justify='center' align='center'>
              <Grid.Column><img src={ICONS['ic-notification-info-white.svg']} alt='Icon' /></Grid.Column>
              <Grid.Column><Text regular14 color={VARIABLES.COLORS.WHITE}>{getLanguage('container.latestSaleTransaction.hintDeleteTransaction', '')}</Text></Grid.Column>
            </Grid>
          </ShapeContainer>}
          <SaleTransactionListContainerConnected
            customerData={data}
            isIndoToko={isIndoToko}
          />
        </Section>
        <Section spacingBottom={productList.length === 0 ? '50' : '25'}>
          <TitleSection name={getLanguage('container.latestSaleTransaction.titleOrder', '')} />
        </Section>
        <Section spacingBottom={productList.length === 0 ? '110' : '30'}>
          {
            productList.length === 0 ?
              <React.Fragment>
                <Section
                  spacingBottom='20'
                  justify='center'
                >
                  <Image ui='iconBlankProduct' />
                </Section>
                <Section
                  spacingBottom='20'
                  justify='center'
                >
                  <Text
                    regular14
                    color={VARIABLES.COLORS.BLACK}
                  >
                    {getLanguage('container.latestSaleTransaction.contentBlank', '')}
                  </Text>
                </Section>
                <Section justify='center'>
                  <Button
                    brown
                    ui='frontIcon'
                    name={getLanguage('container.latestSaleTransaction.buttonSelectProduct', '')}
                    iconWidth='14'
                    iconHeight='14'
                    iconSrc={ICONS['ic-create.svg']}
                    width={VARIABLES.BUTTON.WIDTHS.W_200}
                    onClick={onClickOpenProductList}
                  />
                </Section>
              </React.Fragment>
              :
              <OrderSummaryListContainerConnected
                customerData={data}
                onChangeCustomerDataInput={onChangeCustomerDataInput}
                dataList={productList}
                summaryPoint={summaryPoint}
                totalPoint={summaryPoint + data.remainPoints}
                isIndoToko={isIndoToko}
                isMalay={isMalay}
              />
          }
        </Section>
        <Section>
          <Grid
            gutter='15'
            justify='flex-end'
          >
            <Grid.Column>
              <Button
                gray
                ui='back'
                name={preTransData ? getLanguage('field.buttonProjectDocument', '') : getLanguage('field.buttonBack', '')}
                onClick={() => {
                  if (productList.length > 0) {
                    onClickOpenProductList();
                  }
                  else {
                    if (preTransData) {
                      onClickBackProjectDocumentManage();
                    }
                    else {
                      handleClearCustomer();
                    }
                  }
                }}
              />
            </Grid.Column>
            {!preTransData && userAuth.addressType === ENUM.COUNTRY_TYPE.THAI &&
              <Grid.Column>
                <ShapeContainer
                  tooltips
                >
                  <Button
                    lightBlue
                    name={getLanguage('field.buttonLinkPreTransactionId', '')}
                    width={VARIABLES.BUTTON.WIDTHS.W_180}
                    onClick={this.saleOrderCustomer.onClickOpenLinkProjectDocumentModal}
                  />
                </ShapeContainer>
              </Grid.Column>
            }
            {warrantyDraftIdList.length > 0 &&
              <Grid.Column>
                <ShapeContainer
                  tooltips
                >
                  <div className='tooltips-section'>
                    <Section
                      paddingHorizontal='28'
                      paddingBottom='10'
                      paddingTop='5'
                    >
                      <Text
                        regular12
                        noWrap
                        color={VARIABLES.COLORS.BLACK}
                      >
                        {getLanguage('field.onceTheSaleOrderIsComplete', '')}
                      </Text>
                      <Text
                        regular12
                        noWrap
                        color={VARIABLES.COLORS.BLACK}
                      >
                        {getLanguage('field.youCanGoTothe', '')}
                        <Text
                          bold12
                          left='2'
                          color={VARIABLES.COLORS.BLACK}
                        >
                          {getLanguage('field.projectDocument', '')}
                        </Text>
                        <Text
                          regular12
                          left='2'
                          color={VARIABLES.COLORS.BLACK}
                        >
                          &nbsp;{getLanguage('field.or', '')}
                        </Text>
                        <Text
                          bold12
                          left='2'
                          color={VARIABLES.COLORS.BLACK}
                        >
                          &nbsp;{getLanguage('field.warranty', '')}
                        </Text>
                        <Text
                          regular12
                          left='2'
                          color={VARIABLES.COLORS.BLACK}
                        >
                          &nbsp;{getLanguage('field.menu', '')}
                        </Text>
                      </Text>
                      <Text
                        regular12
                        noWrap
                        color={VARIABLES.COLORS.BLACK}
                      >
                        {getLanguage('field.toGenerateWarranty', '')}
                        <Text
                          bold12
                          left='2'
                          color={VARIABLES.COLORS.BLACK}
                        >
                          {getLanguage('field.ifYouLeaveThisPage', '')}
                        </Text>
                      </Text>

                    </Section>
                  </div>
                  <Button
                    lightGreen
                    name={getLanguage('field.buttonGenerateWarrantyFromCurrentSaleTransaction', '')}
                    onClick={this.saleOrderCustomer.onClickGenerateWarranty}
                  />
                </ShapeContainer>
              </Grid.Column>
            }
            {
              productList.length > 0 &&
              <Grid.Column>
                <Button
                  blue
                  name={getLanguage('field.buttonSaveAndSendOrders', '')}
                  onClick={this.saleOrderCustomer.onClickSaveOrder}
                />
              </Grid.Column>
            }
          </Grid>
        </Section>
        {
          isOpenLinkProjectDocumentModal &&
          <ModalLinkProjectDocumentContainer
            isOpenModal
            customerData={data}
            title={getLanguage('field.linkPreTransactionIdAndToSaleTransactionId', '')}
            onClickClose={this.modal.onCloseOpenLinkProjectDocumentModal}
          />
        }

        {
          modalData.isOpenChooseProjectCategoryModal &&
          <ModalChooseProjectCategoryContainer
            isOpenModal
            onClickClose={this.modal.onCloseModal}
            onClickConfirm={this.modal.onClickSubmitCategory}
            onChangeCategory={this.modal.onChangeProjectCategory}
            category={modalData.wrtCat}
            data={warrantyData}
          />
        }
      </React.Fragment >
    )
  }
}

const mapStateToProps = (state) => {
  const dataRedux = state.dataRedux;
  const mainRedux = state.mainRedux;
  return {
    dataList: dataRedux.customerList,
    activePage: dataRedux.customerParams.activePage,
    totalData: dataRedux.customerParams.totalData || 0,
    userAuth: mainRedux[MAIN_REDUX_STATE_KEY.USER_AUTH],
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
  }
}

export const SalesOrdersCustomerContainerConnected = connect(
  mapStateToProps,
  mapDispatchToProps
)(SalesOrdersCustomerContainer)