import React from 'react';
import {
  connect,
} from 'react-redux';
import {
  ShapeContainer,
  Section,
  Grid,
  Field,
  Button,
  TitleSection,
  Text,
  DatePickerControl
} from './../../components'
import {
  VARIABLES,
} from './../../themes'
import { FieldUploadContainer } from '../FieldUploadContainer';
import _ from 'lodash';
import { getLanguage } from '../../helpers/functions/language';
import { DATE_PICKER_FORMAT } from '../../helpers/constants/data';

class ManageCustomerTypeContainer extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
    }
  }

  render() {
    const {
      data,
      customerRadioTypeList,
      onChangeDataInput,
      onChangeCustomerTypeRadio,
      goBack,
      onChangeDatePicker,
      tabEdit,
      isEdit,
      nextStep,
      onClickSave,
      onChangeUpload,
      onClickDeleteImage,
      onChangePhoneCountry,
    } = this.props;
    return (
      <ShapeContainer ui='contentStep'>
        {tabEdit}
        <Section spacingBottom='40'>
          <TitleSection name={getLanguage('customersManage.tab.registration')} />
        </Section>
        <Section relative>
          <Section uploadAvatar>
            <FieldUploadContainer ui='uploadAvatar'
              src={data.imageUrl}
              onChange={onChangeUpload}
              onClickDeleteImage={onClickDeleteImage}
            />
          </Section>
          <Grid>
            <Grid.ResponsiveColumn ui='fieldText'>
              <Section spacingBottom='30'>
                <Field name='phone'
                  fluid
                  required
                  // error
                  ui='blockLabelVerticalPhone'
                  label={getLanguage('field.phoneNo', '')}
                  placeholder={getLanguage('field.phoneNoPlaceholder', '')}
                  message={
                    <Section
                      ui='hintBottomField'
                      hint={getLanguage('field.phoneNoHint', '')}
                    />
                  }
                  value={data.phone}
                  onChange={onChangeDataInput}
                  selectedPhoneCountry={data.phoneCountry}
                  onChangePhoneCountry={selected => {
                    onChangePhoneCountry(selected, 'phoneCountry')
                  }}
                />
              </Section>
              <Section spacingBottom='30'>
                <Section spacingBottom='15'>
                  <Grid gutter='5'>
                    <Grid.Column>
                      <Text
                        regular14
                        color={VARIABLES.COLORS.GRAY_19}
                      >
                        {getLanguage('customersManage.form.regis.customerType', '')}
                      </Text>
                    </Grid.Column>
                    <Grid.Column>
                      <ShapeContainer ui='required' />
                    </Grid.Column>
                  </Grid>
                </Section>
                {
                  customerRadioTypeList.map((e, i) => {
                    return (
                      /* e.text === customerRadioTypeList[4].text ?
                        <Section
                          key={i}
                        >
                          <Section spacingBottom={e.isChecked ? '15' : '0'}>
                            <Field name={e.text}
                              checked={e.isChecked}
                              ui='radio'
                              radioLabel={e.text}
                              onChange={onChangeCustomerTypeRadio}
                            />
                          </Section>
                          {
                            e.isChecked &&
                            <Section paddingLeft='30'>
                              <Field name='type'
                                fluid
                                required
                                // error
                                ui='blockLabelVertical'
                                placeholder='Insert Others Customer Type'
                                value={data.type}
                                onChange={onChangeDataInput}
                              />
                            </Section>
                          } 
                        </Section>
                        :*/
                      < Section spacingBottom='15'
                        key={i}
                      >
                        <Field name={e.text}
                          checked={e.isChecked}
                          ui='radio'
                          radioLabel={e.text}
                          onChange={onChangeCustomerTypeRadio}
                        />
                      </Section>
                    )
                  })
                }
              </Section>
            </Grid.ResponsiveColumn>
          </Grid>
          {/* {
            customerRadioTypeList[0].isChecked && */}
          <Section spacingBottom='30'>
            <Grid>
              <Grid.Column
                gridLaptop='12'
                gridDesktop='8'
              >
                <Grid
                  gutter='35'
                  gutterVertical='30'
                >
                  <Grid.Column
                    gridTablet='12'
                    gridLaptop='6'
                  >
                    <Field name='firstname'
                      fluid
                      required
                      // error
                      ui='blockLabelVertical'
                      label={getLanguage('field.firstname', '')}
                      placeholder={getLanguage('field.firstnamePlaceholder', '')}
                      value={data.firstname}
                      onChange={onChangeDataInput}
                    />
                  </Grid.Column>
                  <Grid.Column
                    gridTablet='12'
                    gridLaptop='6'
                  >
                    <Field name='lastname'
                      fluid
                      required
                      // error
                      ui='blockLabelVertical'
                      label={getLanguage('field.lastname', '')}
                      placeholder={getLanguage('field.lastnamePlaceholder', '')}
                      value={data.lastname}
                      onChange={onChangeDataInput}
                    />
                  </Grid.Column>
                </Grid>
              </Grid.Column>
            </Grid>
          </Section>
          {/* } */}
          {/* {
            customerRadioTypeList[1].isChecked &&
            <Section spacingBottom='30'>
              <Grid>
                <Grid.ResponsiveColumn ui='fieldText'>
                  <Field name='customerName'
                    fluid
                    required
                    // error
                    ui='blockLabelVertical'
                    label='Customer Name'
                    placeholder='Insert Customer Name'
                    value={data.customerName}
                    onChange={onChangeDataInput}
                  />
                </Grid.ResponsiveColumn>
              </Grid>
            </Section>
          } */}
          <Section spacingBottom='45'>
            <Grid>
              <Grid.ResponsiveColumn ui='fieldText'>
                <Section spacingBottom='30'>
                  <Grid
                    gutter='35'
                    gutterVertical='30'
                  >
                    <Grid.Column
                      gridTablet='12'
                      gridLaptop='6'
                    >
                      <Field name='nickname'
                        fluid
                        ui='blockLabelVertical'
                        label={getLanguage('field.nickname', '')}
                        placeholder={getLanguage('field.nicknamePlaceholder', '')}
                        value={data.nickname}
                        onChange={onChangeDataInput}
                      />
                    </Grid.Column>
                    <Grid.Column
                      gridTablet='12'
                      gridLaptop='6'
                    >
                      <DatePickerControl
                        dateFormat={DATE_PICKER_FORMAT}
                        maxDate={new Date()}
                        label={getLanguage('field.dateOfBirth', '')}
                        placeholderText={getLanguage('field.dateOfBirthPlaceholder', '')}
                        selected={data.dateOfBirth}
                        onChange={onChangeDatePicker}
                      />
                    </Grid.Column>
                  </Grid>
                </Section>
                <Section spacingBottom='30'>
                  <Field name='email'
                    fluid
                    ui='blockLabelVertical'
                    label={getLanguage('field.email', '')}
                    placeholder={getLanguage('field.emailPlaceholder', '')}
                    value={data.email}
                    onChange={onChangeDataInput}
                  />
                </Section>
                <Section>
                  <Field name='company'
                    fluid
                    ui='blockLabelVertical'
                    label={getLanguage('field.company', '')}
                    placeholder={getLanguage('field.companyPlaceholder', '')}
                    value={data.company}
                    onChange={onChangeDataInput}
                  />
                </Section>
              </Grid.ResponsiveColumn>
            </Grid>
          </Section>
          <Grid
            gutter='15'
            justify='flex-end'
          >
            <Grid.Column>
              <Button
                gray
                ui='back'
                name={getLanguage('field.buttonBack', '')}
                onClick={goBack}
              />
            </Grid.Column>
            <Grid.Column>
              <Button
                blue
                name={isEdit ? getLanguage('field.buttonSave', '') : getLanguage('field.buttonContinue', '')}
                width={VARIABLES.BUTTON.WIDTHS.W_200}
                onClick={isEdit ? onClickSave : nextStep}
              />
            </Grid.Column>
          </Grid>
        </Section>
      </ShapeContainer >
    )
  }
}

const mapStateToProps = (state) => {
  return {
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
  }
}

export const ManageCustomerTypeContainerConnected = connect(
  mapStateToProps,
  mapDispatchToProps
)(ManageCustomerTypeContainer)