import React from 'react';
import {
  connect,
} from 'react-redux';
import { MAIN_REDUX_STATE_KEY } from '../../actions/mainAction';
import {
  Loading
} from '../../components';

class LoadingContainer extends React.Component {
  render() {
    const {
      isShowLoading,
      isShowAppLoading,
    } = this.props;
    return (
      <Loading
        active={isShowLoading || isShowAppLoading}
      />
    )
  }
}

const mapStateToProps = (state) => {
  const mainRedux = state.mainRedux;
  return {
    isShowLoading: mainRedux[MAIN_REDUX_STATE_KEY.IS_SHOW_LOADING],
    isShowAppLoading: mainRedux[MAIN_REDUX_STATE_KEY.IS_SHOW_APP_LOADING],
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
  }
}

export const LoadingContainerConnected = connect(
  mapStateToProps,
  mapDispatchToProps
)(LoadingContainer)