import _ from 'lodash';

export class ModelAdNoti {
  constructor(props = {}) {
    this.props = props;
  }

  data = () => {
    const props = this.props;
    return {
      firebaseKey: props.firebaseKey,
      firebasePath: props.firebasePath,
      adId: props.ad_id,
      orderInquiry: {
        isNew: _.get(props, 'order_inquiry.is_new', false),
      }
    }
  }

  firebase = () => {
    const props = this.props;
    return {
      ad_id: props.adId,
      order_inquiry: {
        is_new: _.get(props, 'orderInquiry.isNew', false),
      }
    }
  }
}