import { injectGlobal } from "styled-components";
import { default as VARIABLES } from "./../../../themes/styles/bases/variables";
// import {
//   default as TYPOGRAPHYS
// } from './../../../themes/styles/bases/typographys'
// import {
//   default as MIXINS
// } from './../../../themes/styles/helpers/mixins'

// Sample using image in style
// url(${require('./../../../themes/images/icons/icon-sample.svg')});

injectGlobal`
  .modal-base {
    /* Parent
    ------------------------------- */
    padding-left: 30px;
    padding-right: 30px;

    /* Childrens
    ------------------------------- */
    /* Modifiers
    ------------------------------- */
    &.is-scroll {
      overflow: auto;
      height: 100vh;
      flex-wrap: wrap;
    }

    .modal-section-base {
      &.is-news-promotions-header {
        margin-right: -55px;
        margin-left: -55px;
      }
    }

    &.is-second-modal {
      z-index: ${VARIABLES.Z_INDEXS.LV_7};

      + .modal-overlay-base {
        z-index: ${VARIABLES.Z_INDEXS.LV_6};
      }
    }

    &.is-fluid {
      padding: 0;
    }

    /* Media queries
    ------------------------------- */

    &.print-news-promotion-box {
      @media print {
      display: block;
      overflow: visible !important;

        .swiper-carousel-item {
          width: 600px !important;
          margin: 0 !important;
          padding: 0 !important;
        }

        .print-text-section {
          overflow: visible ;
          height: auto;
        }

        .print-section {
          display: none;
        }
      }
    }

    &.product-update {
      @media print {
        margin: 0;
        padding: 0;

        .print-section{
          display: none;
        }
      .product-image-print {
        display: block !important;
        position: absolute !important;
        transform: translateX(-50%);
        left: 50%;
        top: -100% !important;
        width: 720px;
        height: 1320px;
        
    }
      }
    }
  }
`;
