import React from 'react';
// import {
//   connect,
// } from 'react-redux';
import {
  Section,
  Grid,
  Modal,
  Text,
  Button,
  Image
} from './../../../components'
import {
  VARIABLES,
} from './../../../themes'
// import { MODAL_TYPE } from '../../../helpers/constants/main'
// import { MAIN_REDUX_STATE_KEY } from '../../../actions/mainAction'
import _ from 'lodash';
import { getLanguage } from '../../../helpers/functions/language';

export class ModalInfoContainer extends React.Component {
  render() {
    const {
      isOpenModal,
      onClickClose,
      onClickConfirm,
      title,
      message,
      buttonSubmitName,
    } = this.props
    return (
      <Modal
        open={isOpenModal}
        scroll
        // enableOverlayClose={false}
        width={VARIABLES.MODAL.WIDTHS.W_530}
        overlayColor={VARIABLES.COLORS.OVERLAY_BLACK_1}
        spacing={VARIABLES.MODAL.CONTAINER.C_1.SPACING}
        transition={VARIABLES.TRANSITIONS.DEFAULT}
        transformStart={VARIABLES.MODAL.CONTAINER.C_1.TRANSFORM_START}
        transformEnd={VARIABLES.MODAL.CONTAINER.C_1.TRANSFORM_END}
        onClickClose={onClickClose}
      >
        <Modal.Section
          paddingVertical='25'
          paddingHorizontal='35'
          bgColor={VARIABLES.COLORS.WHITE}
          borderBottomWidth='1'
          borderBottomStyle='solid'
          borderBottomColor={VARIABLES.COLORS.GRAY_8}
          borderTopRightRadius={VARIABLES.MODAL.HEADER.H_1.BORDER_TOP_RIGHT_RADIUS}
          borderTopLeftRadius={VARIABLES.MODAL.HEADER.H_1.BORDER_TOP_LEFT_RADIUS}
        >
          <Grid gutter='10'>
            <Grid.Column>
              <Image ui='iconInfo' />
            </Grid.Column>
            <Grid.Column>
              <Text
                regular16
                singleLine
                color={VARIABLES.COLORS.BLACK}
                top='2'
              >
                {title}
              </Text>
            </Grid.Column>
          </Grid>
        </Modal.Section>
        <Modal.Section
          paddingVertical='40'
          paddingHorizontal='35'
          bgColor={VARIABLES.COLORS.WHITE}
        >
          <Section
            calcMinHeight='70px'
          >
            <Text
              regular14
              color={VARIABLES.COLORS.GRAY_19}
            >
              {message}
            </Text>
          </Section>
        </Modal.Section>
        <Modal.Section
          paddingTop='15'
          paddingBottom='20'
          paddingHorizontal='35'
          borderTopWidth={VARIABLES.MODAL.FOOTER.F_1.BORDER_TOP_WIDTH}
          borderTopStyle={VARIABLES.MODAL.FOOTER.F_1.BORDER_TOP_STYLE}
          borderTopColor={VARIABLES.COLORS.GRAY_8}
          borderBottomRightRadius={VARIABLES.MODAL.FOOTER.F_1.BORDER_BOTTOM_RIGHT_RADIUS}
          borderBottomLeftRadius={VARIABLES.MODAL.FOOTER.F_1.BORDER_BOTTOM_LEFT_RADIUS}
        >
          <Grid
            gutter='15'
            justify='flex-end'
          >
            <Grid.Column>
              <Button
                blue
                width={VARIABLES.BUTTON.WIDTHS.W_135}
                name={buttonSubmitName}
                onClick={onClickConfirm}
              />
            </Grid.Column>
          </Grid>
        </Modal.Section>
      </Modal>
    )
  }

  static defaultProps = {
    buttonSubmitName: getLanguage('field.buttonConfirm', ''),
  }
}

// const mapStateToProps = (state) => {
//   const mainRedux = state.mainRedux;
//   const modalData = mainRedux[MAIN_REDUX_STATE_KEY.MODAL_DATA];
//   return {
//     isOpenModal: modalData.openModalType === MODAL_TYPE.CONFIRM_DELETE,
//     message: modalData.message,
//     onClickCancel: modalData.onClickCancel,
//     onClickConfirm: modalData.onClickConfirm,
//   }
// }

// const mapDispatchToProps = (dispatch) => {
//   return {
//   }
// }

// export const ModalInfoContainerConnected = connect(
//   mapStateToProps,
//   mapDispatchToProps
// )(ModalInfoContainer)