import styled, {
  css
} from 'styled-components'
import {
  default as VARIABLES
} from './../../../themes/styles/bases/variables'
// import {
//   default as TYPOGRAPHYS
// } from './../../../themes/styles/bases/typographys'
// import {
//   default as MIXINS
// } from './../../../themes/styles/helpers/mixins'

// Sample using image in style
// url(${require('./images/icons/icon-sample.svg')});
// url(${require('./../../../themes/images/icons/icon-sample.svg')});

// Wrapper
// ============================================================
export const ImageBaseWrapper = styled.div`
  /* Parent
  ------------------------------- */
  overflow: hidden;
  position: relative;
  display: inline-block;
  vertical-align: middle;

  ${props => {
    const theme = props.theme
    return css`
      ${theme.top && css`
          top: ${theme.top + `px`};
        `
      }

      ${theme.left && css`
          left: ${theme.left + `px`};
        `
      }

      ${theme.widthRatio && css`
          width: ${theme.widthRatio + theme.widthRatioUnit};
        `
      }

      ${theme.heightRatio && css`
          padding-bottom: ${theme.heightRatio + theme.heightRatioUnit};
        `
      }

      ${theme.borderRadius && css`
          border-radius: ${theme.borderRadius + `px`};
        `
      }
    `
  }}

  /* Childrens
  ------------------------------- */
  .image-ratio-base {
    position: absolute;
    z-index: ${VARIABLES.Z_INDEXS.LV_1};
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  /* Modifiers
  ------------------------------- */
  &.is-cursor-pointer {
    cursor: pointer;
  }

  /* Media queries
  ------------------------------- */
`
