import React from 'react';
import {
  connect,
} from 'react-redux';
import {
  ShapeContainer,
  Section,
  TitleSection,
  Grid,
  Image,
  Text,
} from './../../components'
import {
  VARIABLES,
  ICONS,
} from './../../themes'
import moment from 'moment';
import { displayWithComma } from '../../helpers/functions/display';
import _ from 'lodash';
import { getLanguage } from '../../helpers/functions/language';
import { getCustomerTrainedTier } from '../../helpers/functions/data';

export class CustomerPointsDetailContainer extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      // currentYear: new Date().getFullYear(),
    }
  }

  customerPointDetail = {
    // getPointPeriod: () => {
    //   const {
    //     data,
    //   } = this.props;
    //   const {
    //     currentYear,
    //   } = this.state;
    //   const peroidList = [
    //     `${currentYear}-1-1`, `${currentYear}-3-31`,
    //     `${currentYear}-4-1`, `${currentYear}-6-30`,
    //     `${currentYear}-7-1`, `${currentYear}-9-30`,
    //     `${currentYear}-10-1`, `${currentYear}-12-31`,
    //   ]
    //   const pointGroup = {
    //     [peroidList[1]]: 0,
    //     [peroidList[3]]: 0,
    //     [peroidList[5]]: 0,
    //     [peroidList[7]]: 0,
    //   }
    //   data.pointList.forEach((e, i) => {
    //     if (moment(moment(e.createAt).format('YYYY-MM-D')).isBetween(peroidList[0], peroidList[1])) {
    //       pointGroup[peroidList[1]] += e.points;
    //     }
    //     if (moment(moment(e.createAt).format('YYYY-MM-D')).isBetween(peroidList[2], peroidList[3])) {
    //       pointGroup[peroidList[3]] += e.points;
    //     }
    //     if (moment(moment(e.createAt).format('YYYY-MM-D')).isBetween(peroidList[4], peroidList[5])) {
    //       pointGroup[peroidList[5]] += e.points;
    //     }
    //     if (moment(moment(e.createAt).format('YYYY-MM-D')).isBetween(peroidList[6], peroidList[7])) {
    //       pointGroup[peroidList[7]] += e.points;
    //     }
    //   })
    //   console.log('pointGroup', pointGroup)
    //   return Object.values(pointGroup).map((e, i) => {
    //     return (
    //       <Section
    //         spacingBottom={i < (Object.values(pointGroup).length - 1) ? '21' : undefined}
    //         key={i}
    //         justify='flex-end'
    //       >
    //         <Text
    //           bold14
    //           color={VARIABLES.COLORS.PRIMARY_1}
    //         >
    //           {displayWithComma(e)}
    //         </Text>
    //       </Section>
    //     )
    //   })
    // }
  }

  render() {
    const {
      data,
      expirePointList,
    } = this.props;
    const {
      currentYear,
    } = this.state;
    const customerTrainedTier = getCustomerTrainedTier(data.trainedTier__c);
    return (
      <React.Fragment>
        <Section spacingBottom='35'>
          <TitleSection name={getLanguage('container.customerPointDetail.title', '')} />
        </Section>
        <Section spacingBottom='30'>
          <Grid
            gutterTablet='35'
            gutterLargeDesktop='55'
            gutterVertical='35'
            justify='space-between'
          >
            <Grid.Column flex='1'>
              <Grid
                gutterTablet='35'
                gutterLargeDesktop='55'
                gutterVertical='35'
              >
                <Grid.Column>
                  <ShapeContainer
                    ui='userUpload'
                    srcUserUploadImage={data.oldImageRef ? data.imageUrl : ICONS['ic-placeholder-avatar.svg']}
                  />
                </Grid.Column>
                <Grid.Column>
                  <Section
                    spacingTop='15'
                    spacingBottom='10'
                  >
                    <Text
                      regular14
                      color={VARIABLES.COLORS.GRAY_19}
                    >
                      {getLanguage('container.customerPointDetail.name', '')}
                    </Text>
                  </Section>
                  <Section
                    className='is-info-name-short'
                    spacingBottom='15'
                  >
                    <Text
                      ellipsis
                      regular22
                      color={VARIABLES.COLORS.BLACK}
                      title={data.fullname}
                    >
                      {data.fullname}
                    </Text>
                  </Section>
                  <Section
                    spacingTop='15'
                    spacingBottom='15'
                  >
                    <Text
                      regular14
                      color={VARIABLES.COLORS.GRAY_19}
                    >
                      {getLanguage('container.customerPointDetail.nickname', '')}
                    </Text>
                  </Section>
                  <Section spacingBottom='15'>
                    <Text
                      regular14
                      color={VARIABLES.COLORS.BLACK}
                    >
                      {data.nickname ? data.nickname : <span>&nbsp;</span>}
                    </Text>
                  </Section>
                </Grid.Column>
                <Grid.Column>
                  <Section
                    spacingTop='15'
                    spacingBottom='15'
                  >
                    <Text
                      regular14
                      color={VARIABLES.COLORS.GRAY_19}
                    >
                      {getLanguage('container.customerPointDetail.phoneNo', '')}
                    </Text>
                  </Section>
                  <Section spacingBottom='24'>
                    <Text
                      regular14
                      color={VARIABLES.COLORS.BLACK}
                    >
                      <Image
                        ui='flagWithPhoneIdAndNumber'
                        phoneId={data.phoneCountry.value}
                        phoneNumber={data.phone}
                        src={data.phoneCountry.flag}
                      />
                    </Text>
                  </Section>
                  <Section spacingBottom='15'>
                    <Text
                      regular14
                      color={VARIABLES.COLORS.GRAY_19}
                    >
                      {getLanguage('container.customerPointDetail.customerType', '')}
                    </Text>
                  </Section>
                  <Section>
                    <Text
                      regular14
                      color={VARIABLES.COLORS.BLACK}
                    >
                      {data.typeText}
                    </Text>
                  </Section>
                </Grid.Column>
                <Grid.Column>
                  <Section
                    spacingTop='15'
                    spacingBottom='15'
                  >
                    <Text
                      regular14
                      color={VARIABLES.COLORS.GRAY_19}
                    >
                      {getLanguage('container.customerPointDetail.trained', '')}
                    </Text>
                  </Section>
                  <Section>
                    {true ?
                      <img src={ICONS['ic-status-checked.svg']} width='18' height='18' alt='Icon' />
                      :
                      <Text regular14 color={VARIABLES.COLORS.BLACK}>-</Text>
                    }
                  </Section>
                </Grid.Column>
              </Grid>
            </Grid.Column>
            <Grid.Column flex='none'>
              <Section spacingTop='15'>
                <Grid
                  gutterTablet='35'
                  gutterLargeDesktop='80'
                  gutterVertical='35'
                >
                  <Grid.Column>
                    <Section spacingBottom='8'>
                      <Text
                        regular14
                        color={VARIABLES.COLORS.GRAY_19}
                      >
                        {getLanguage('container.customerPointDetail.currentTrueBluePoints', '')}
                      </Text>
                    </Section>
                    <Section spacingBottom='15'>
                      <Text
                        bold22
                        color={VARIABLES.COLORS.PRIMARY_1}
                      >
                        {data.remainPointsWithComma} {getLanguage('container.customerPointDetail.point', '')}
                      </Text>
                    </Section>
                    {customerTrainedTier &&
                      <React.Fragment>
                        <Section spacingBottom='15'>
                          <Text
                            regular14
                            color={VARIABLES.COLORS.GRAY_19}
                          >
                            {getLanguage('container.customerPointDetail.level', '')}
                          </Text>
                        </Section>
                        <Section>
                          <Image ui={customerTrainedTier.ui} widthRatio='42' heightRatio='42' top='-5' />
                          <Text bold16 left='10' color={customerTrainedTier.textColor}>
                            {customerTrainedTier.text}
                          </Text>
                        </Section>
                      </React.Fragment>
                    }
                  </Grid.Column>
                  <Grid.Column>
                    <Grid gutter='35'>
                      <Grid.Column>
                        <Section spacingBottom='15'>
                          <Text
                            regular14
                            color={VARIABLES.COLORS.GRAY_19}
                          >
                            {getLanguage('container.customerPointDetail.expiringDate', '')}
                          </Text>
                        </Section>
                        {
                          expirePointList.map((e, i) => {
                            return (
                              <Section
                                spacingBottom={i === (expirePointList.length - 1) ? undefined : '20'}
                                key={i}
                              >
                                <Text
                                  regular14
                                  color={VARIABLES.COLORS.BLACK}
                                >
                                  {e.displayExpireDate}
                                </Text>
                              </Section>
                            )
                          })
                        }
                      </Grid.Column>
                      <Grid.Column>
                        <Section spacingBottom='16'>
                          <Text
                            regular14
                            color={VARIABLES.COLORS.GRAY_19}
                          >
                            {getLanguage('container.customerPointDetail.tbPoints', '')}
                          </Text>
                        </Section>
                        {
                          expirePointList.map((e, i) => {
                            return (
                              <Section
                                spacingBottom={i === (expirePointList.length - 1) ? undefined : '21'}
                                key={i}
                                justify='flex-end'
                              >
                                <Text
                                  bold14
                                  color={VARIABLES.COLORS.PRIMARY_1}
                                >
                                  {e.pointsWithComma}
                                </Text>
                              </Section>
                            )
                          })
                        }
                      </Grid.Column>
                    </Grid>
                  </Grid.Column>
                </Grid>
              </Section>
            </Grid.Column>
          </Grid>
        </Section>
      </React.Fragment >
    )
  }
}