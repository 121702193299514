import React from 'react';
import {
  Section,
  Breadcrumb,
  TitlePage,
  Step,
} from './../../components'
import {
  ModalInfoContainer
} from './../../containers/ModalContainer'
import {
  ICONS
} from './../../themes'
import { setSideMenuActive, redirect, getRouteParams, getRouteData } from '../../helpers/functions/route';
import { ROUTE_PATH } from '../../helpers/constants/route';
import { ENUM } from '../../helpers/constants/enum';
import { ManageCustomerTypeContainerConnected } from '../../containers/ManageCustomerTypeContainer';
import { ManageContactAddressContainerConnected } from '../../containers/ManageContactAddressContainer/ManageContactAddressContainer';
import { ManageIdAddressContainerConnected } from '../../containers/ManageIdAddressContainer/ManageIdAddressContainer';
import { isValidNumber, isValidEmail, isValidResponse, isValidPhoneNumber } from '../../helpers/functions/validation';
import { toast } from '../../helpers/functions/main';
import { customerController } from '../../apiService/apiController/customerService';
import { firebaseUpdateFile, firebaseDeleteFile } from '../../helpers/firebases/firebaseStorage';
import { getCustomerList, getClearCustomerParams, getCustomerManageParams } from '../../helpers/services/customer';
import { getUserList, getClearUserParams } from '../../helpers/services/user';
import { DATA } from '../../helpers/constants/data';
import _ from 'lodash';
import { getCountryPhone } from '../../helpers/functions/data';
import { getLanguage } from '../../helpers/functions/language';
import { getUserAuthRedux } from '../../helpers/functions/auth';
import { FIREBASE_PUBLIC_STORAGE_PATH } from '../../config/config';

export class CustomersManageScreen extends React.Component {

  constructor(props) {
    super(props);
    setSideMenuActive(props);
    const dataId = parseInt(getRouteParams(props, 'id'));
    const routeData = getRouteData(props, 'data');
    const userAuth = getUserAuthRedux();
    const defaultData = {
      firstname: '',
      lastname: '',
      nickname: '',
      idCardNo: '',
      email: '',
      phone: '',
      address: '',
      postCode: '',
      imageUrl: '',
      dateOfBirth: '',
      company: '',
      type: ENUM.CUSTOMER_TYPE.FIXERS.TYPE,
      contactChannelList: [ENUM.CUSTOMER_CONTACT_CHANNEL.SMS],
      contactPhone: '',
      customerName: '',
      userRefId: '',
      customerRefId: '',
      idAddress: '',
      idDistrict: '',
      idSubDistrict: '',
      idAlley: '',
      idGroup: '',
      idStreet: '',
      idWard: '',
      idPostCode: '',
      idAddressType: userAuth.addressType,
      contactAddress: '',
      contactDistrict: '',
      contactSubDistrict: '',
      contactAlley: '',
      contactGroup: '',
      contactStreet: '',
      contactWard: '',
      contactPostCode: '',
      contactAddressType: userAuth.addressType,
      phoneCountry: getCountryPhone(),
    };
    const isEdit = dataId > 0;
    let data = {
      ...defaultData,
      ...routeData,
    };
    if (isEdit) {
      data.contactChannelList = data.contactChannel ? data.contactChannel.split(',') : [];
    }
    data.userRefId = data.userRefId === 'undefined' ? defaultData.userRefId : data.userRefId;
    data.customerRefId = data.customerRefId === 'undefined' ? defaultData.customerRefId : data.customerRefId;
    data.phone = data.phone ? data.phone : '';
    data.firstname = data.firstname ? data.firstname : '';
    data.lastname = data.lastname ? data.lastname : '';
    data.email = data.email ? data.email : '';
    data.nickname = data.nickname ? data.nickname : '';
    data.company = data.company ? data.company : '';
    data.dateOfBirth = isEdit ? new Date(data.dateOfBirth) : new Date();
    data.oldPhone = data.phone;
    data.oldEmail = data.email;
    const stepContentList = [{
      stepPercent: '0',
      contentList: [{
        isActive: true,
        isSuccess: false,
        stepName: getLanguage('customersManage.step.register')
      }, {
        isActive: false,
        isSuccess: false,
        stepName: getLanguage('customersManage.step.idAddress')
      }, {
        isActive: false,
        isSuccess: false,
        stepName: getLanguage('customersManage.step.contactAddress')
      }]
    }, {
      stepPercent: '50',
      contentList: [{
        isActive: false,
        isSuccess: true,
        stepName: getLanguage('customersManage.step.register')
      }, {
        isActive: true,
        isSuccess: false,
        stepName: getLanguage('customersManage.step.idAddress')
      }, {
        isActive: false,
        isSuccess: false,
        stepName: getLanguage('customersManage.step.contactAddress')
      }]
    }, {
      stepPercent: '100',
      contentList: [{
        isActive: false,
        isSuccess: true,
        stepName: getLanguage('customersManage.step.register')
      }, {
        isActive: false,
        isSuccess: true,
        stepName: getLanguage('customersManage.step.idAddress')
      }, {
        isActive: true,
        isSuccess: false,
        stepName: getLanguage('customersManage.step.contactAddress')
      }]
    }]
    const customerRadioTypeList = []
    customerRadioTypeList.push({
      text: getLanguage('customersManage.form.regis.fixer', ''),
      isChecked: data.type === ENUM.CUSTOMER_TYPE.FIXERS.TYPE,
      value: ENUM.CUSTOMER_TYPE.FIXERS.TYPE,
    });
    customerRadioTypeList.push({
      text: getLanguage('customersManage.form.regis.hardware', ''),
      isChecked: data.type === ENUM.CUSTOMER_TYPE.HARDWARE.TYPE || data.type === 'Hardware',
      value: ENUM.CUSTOMER_TYPE.HARDWARE.TYPE
    });
    customerRadioTypeList.push({
      text: getLanguage('customersManage.form.regis.homeowner', ''),
      isChecked: data.type === ENUM.CUSTOMER_TYPE.HOMEOWNER.TYPE,
      value: ENUM.CUSTOMER_TYPE.HOMEOWNER.TYPE,
    });
    if (userAuth.addressType !== ENUM.COUNTRY_TYPE.MALAYSIA) {
      customerRadioTypeList.push({
        text: getLanguage('customersManage.form.regis.rollFormer', ''),
        isChecked: data.type === ENUM.CUSTOMER_TYPE.ROLL_FORMER.TYPE,
        value: ENUM.CUSTOMER_TYPE.ROLL_FORMER.TYPE,
      });
    }

    // CR: 1483
    if (userAuth.addressType === ENUM.COUNTRY_TYPE.THAI) {
      customerRadioTypeList.push({
        text: getLanguage('customersManage.form.regis.architect', ''),
        isChecked: data.type === ENUM.CUSTOMER_TYPE.ARCHITECT.TYPE,
        value: ENUM.CUSTOMER_TYPE.ARCHITECT.TYPE,
      });
      customerRadioTypeList.push({
        text: getLanguage('customersManage.form.regis.engineer', ''),
        isChecked: data.type === ENUM.CUSTOMER_TYPE.ENGINEER.TYPE,
        value: ENUM.CUSTOMER_TYPE.ENGINEER.TYPE,
      });
    }

    customerRadioTypeList.push({
      text: getLanguage('customersManage.form.regis.others', ''),
      isChecked: data.type === ENUM.CUSTOMER_TYPE.OTHERS.TYPE,
      value: ENUM.CUSTOMER_TYPE.OTHERS.TYPE
    });

    this.state = {
      dataId,
      isEdit,
      data,
      selectedOption: null,
      customerRadioTypeList,
      currentStepIndex: 0,
      currentStepContent: stepContentList[0],
      stepContentList,
      userAuth,
      isContactSameAddress: isEdit === false,
    }
    console.log('dataId', dataId)
    console.log('data', data)
  }

  componentDidMount() {
    // this.image.getImageUrlFromRef();
  }

  // image = {
  //   getImageUrlFromRef: async () => {
  //     const {
  //       data,
  //     } = this.state;
  //     if (data.oldImageRef) {
  //       const imageUrl = await firebaseGetDownloadPath(data.oldImageRef);
  //       data.imageUrl = imageUrl;
  //       data.oldImageUrl = imageUrl;
  //       this.setState({
  //         data,
  //       });
  //     }
  //   }
  // }

  customerManage = {
    isValidateCustomerTypePass: () => {
      const {
        customerRadioTypeList,
        data,
      } = this.state;
      let isPass = true;
      console.log('data', data);
      // if (customerRadioTypeList[0].isChecked) {
      if (
        !data.phone.length ||
        !data.firstname.length ||
        !data.lastname.length
      ) {
        toast.error(getLanguage('message.pleaseInsertAllRequiredField', ''));
        isPass = false;
      }
      // }
      // else if (customerRadioTypeList[1].isChecked) {
      //   if (
      //     !data.phone.length ||
      //     !data.customerName.length
      //   ) {
      //     toast.error(getLanguage('message.pleaseInsertAllRequiredField',''));
      //     isPass = false;
      //   }
      // }
      // else if (customerRadioTypeList[4].isChecked) {
      //   if (
      //     !data.phone.length ||
      //     !data.type.length
      //   ) {
      //     toast.error(getLanguage('message.pleaseInsertAllRequiredField',''));
      //     isPass = false;
      //   }
      // }
      // else {
      //   if (!data.phone.length) {
      //     toast.error(getLanguage('message.pleaseInsertAllRequiredField',''));
      //     isPass = false;
      //   }
      // }
      DATA.PHONE_PREFIX_LIST.forEach(e => {
        if (data.phoneCountry.value === e.value) {
          if (e.lengthList.indexOf(data.phone.length) === -1) {
            toast.error(getLanguage('message.incorrectPhoneNumberLength', ''));
            isPass = false;
          }
        }
      })
      if (data.email.length && !isValidEmail(data.email)) {
        toast.error(getLanguage('message.wrongEmailFormat', ''));
        isPass = false;
      }
      return isPass;
    },
    isValidateContactAddressPass: () => {
      const {
        data,
      } = this.state;
      let isPass = true;
      // if (
      //   !data.contactSelectedProvince ||
      //   !data.contactSelectedCity
      // ) {
      //   toast.error(getLanguage('message.pleaseInsertAllRequiredField',''));
      //   isPass = false;
      // }
      return isPass;
    },
    isValidatePass: () => {
      return this.customerManage.isValidateCustomerTypePass() &&
        this.customerManage.isValidateContactAddressPass()
    },
    isValidatePhonePass: () => {
      return new Promise((resolve, reject) => {
        const {
          data,
          dataId,
          isEdit
        } = this.state;
        if (isEdit && data.oldPhone === data.phone && data.oldEmail === data.email) {
          return resolve(true);
        }
        else {
          const paramsValidateCustomer = {
            ...getClearCustomerParams(),
            page: 1,
            limit: 1,
            phone: data.phoneCountry.value + data.phone,
            email: isEdit ? data.email : '',
            isAll: true,
            callbackSuccess: async (dataList) => {
              if (dataList.length && dataList[0].id !== dataId) {
                toast.error(getLanguage('message.customerPhoneIsAlreadyExist', ''));
                resolve(false);
              }
              else {
                const paramsValidateUser = {
                  ...getClearUserParams(),
                  page: 1,
                  limit: 1,
                  phone: data.phoneCountry.value + data.phone,
                  email: isEdit ? data.email : '',
                  callbackSuccess: (dataList) => {
                    if (dataList.length) {
                      toast.error(getLanguage('message.customerPhoneIsAlreadyExist', ''));
                      resolve(false);
                    }
                    else {
                      resolve(true);
                    }
                  }
                };
                getUserList(paramsValidateUser);
              }
            }
          };
          getCustomerList(paramsValidateCustomer);
        }
      });
    },
    handleUpdateCustomer: async (params, isUserImage) => {
      const {
        data,
        isEdit,
      } = this.state;
      if (!isUserImage) {
        let newImageRef = data.oldImageRef;
        if (data.oldImageUrl !== data.imageUrl) {
          if (data.imageUrl.length) {
            const storagePath = `${ENUM.UPLOAD_PATH.IMAGE_CUSTOMER}/${params.id}`;
            newImageRef = await firebaseUpdateFile(storagePath, data.imageFile, `${new Date().getTime()}.png`, data.oldImageRef);
            newImageRef = newImageRef.ref.fullPath;
          }
          else {
            firebaseDeleteFile(data.oldImageRef);
            newImageRef = '';
          }
        }
        if (newImageRef.indexOf('http') === 0) {
          params.image_url = newImageRef
        }
        else {
          params.image_url = newImageRef ? `${FIREBASE_PUBLIC_STORAGE_PATH}/${newImageRef}` : '';
        }
      }
      const customerService = customerController();
      params.no_sms = false;
      const res = await customerService.updateCustomerInfo(params);
      if (isValidResponse(res)) {
        setTimeout(() => {
          redirect(ROUTE_PATH.CUSTOMERS.LINK);
        }, 1000);
        if (isEdit) {
          toast.success(getLanguage('message.updateCustomerCompleted', ''));
        }
        else {
          toast.success(getLanguage('message.createCustomerCompleted', ''));
        }
      }
      else {
        toast.error(res.message);
      }
    },
    handleManageCustomer: async () => {
      if (this.customerManage.isValidatePass()) {
        if (await this.customerManage.isValidatePhonePass()) {
          const {
            data,
            isEdit,
            dataId
          } = this.state;
          data.contactChannel = data.contactChannelList.filter(e => e).join(',');
          let params = getCustomerManageParams(data, dataId);
          let res = {};
          if (isEdit) {
            params.check_duplicate_email = true;
            this.customerManage.handleUpdateCustomer(params);
          }
          else {
            const paramsSyncUser = {
              ...getClearUserParams(),
              page: 1,
              limit: 1,
              // phone: data.phone,
              email: data.email,
              callbackSuccess: async (dataList) => {
                const customerService = customerController();
                params.check_duplicate_email = true;
                res = await customerService.createCustomer(params);
                if (isValidResponse(res)) {
                  if (res.id) {
                    params.id = res.id;
                    let isUserImage = false;
                    if (dataList.length) {
                      params.user_ref_id = dataList[0].id;
                      params.image_url = dataList[0].oldImageRef;
                      isUserImage = true;
                    }
                    // params.check_duplicate_email = false;
                    this.customerManage.handleUpdateCustomer(params, isUserImage);
                  }
                  else {
                    toast.error(getLanguage('message.phoneOrContractorInvalid', ''));
                  }
                }
                else {
                  toast.error(res.message);
                }
              }
            };
            if (data.email.length) {
              console.log('getUserList')
              getUserList(paramsSyncUser);
            }
            else {
              console.log('createCustomer')
              paramsSyncUser.callbackSuccess([]);
            }
          }
        }
      }
    },
    nextStep: () => {
      const {
        currentStepIndex,
        stepContentList,
      } = this.state;
      let validateFunc = () => { return true };
      switch (currentStepIndex) {
        case 0:
          validateFunc = this.customerManage.isValidateCustomerTypePass;
          break;
        case 1:
          break;
        case 2:
          validateFunc = this.customerManage.isValidateContactAddressPass;
          break;
      }
      if (validateFunc()) {
        const nextStepIndex = currentStepIndex + 1;
        if (nextStepIndex === stepContentList.length) {
          this.customerManage.handleManageCustomer();
        }
        else {
          this.setState({
            currentStepIndex: nextStepIndex,
            currentStepContent: stepContentList[nextStepIndex],
          });
        }
      }
    },
    goBack: () => {
      const {
        currentStepIndex,
        stepContentList,
        isEdit,
      } = this.state;
      const prevStepIndex = currentStepIndex - 1;
      if (isEdit || prevStepIndex === -1) {
        redirect(ROUTE_PATH.CUSTOMERS.LINK);
      }
      else {
        this.setState({
          currentStepIndex: prevStepIndex,
          currentStepContent: stepContentList[prevStepIndex]
        })
      }
    },
    renderContent: () => {
      const {
        currentStepIndex,
        data,
        customerRadioTypeList,
        isEdit,
        isContactSameAddress,
      } = this.state;
      switch (currentStepIndex) {
        case 0:
          return (
            <ManageCustomerTypeContainerConnected
              isEdit={isEdit}
              tabEdit={isEdit ? this.customerManage.renderTabEdit() : undefined}
              data={{ ...data }}
              customerRadioTypeList={customerRadioTypeList}
              onChangeDataInput={this.input.onChangeDataInput}
              onChangePhoneCountry={this.input.onChangePhoneCountry}
              onChangeCustomerTypeRadio={this.input.onChangeCustomerTypeRadio}
              onChangeDatePicker={this.input.onChangeDatePicker}
              goBack={this.customerManage.goBack}
              nextStep={this.customerManage.nextStep}
              onClickSave={this.customerManage.handleManageCustomer}
              onChangeUpload={this.input.onChangeUpload}
              onClickDeleteImage={this.input.onClickDeleteImage}
            />
          )
        case 1:
          return (
            <ManageIdAddressContainerConnected
              isEdit={isEdit}
              tabEdit={isEdit ? this.customerManage.renderTabEdit() : undefined}
              data={{ ...data }}
              onChangeDataInput={this.input.onChangeDataInput}
              onChangeAddress={this.input.onChangeAddress}
              goBack={this.customerManage.goBack}
              nextStep={this.customerManage.nextStep}
              onClickSave={this.customerManage.handleManageCustomer}
            />
          )
        case 2:
          return (
            <ManageContactAddressContainerConnected
              isEdit={isEdit}
              isContactSameAddress={isContactSameAddress}
              tabEdit={isEdit ? this.customerManage.renderTabEdit() : undefined}
              data={{ ...data }}
              onChangeDataInput={this.input.onChangeDataInput}
              goBack={this.customerManage.goBack}
              nextStep={this.customerManage.nextStep}
              onClickSave={this.customerManage.handleManageCustomer}
              onChangeContactChannel={this.input.onChangeContactChannel}
              onChangeAddress={this.input.onChangeAddress}
              onChangeAddressContactSameId={this.customerManage.onChangeAddressContactSameId}
            />
          )
      }
    },
    onClickTabEdit: (currentStepIndex) => {
      this.setState({
        currentStepIndex,
      });
    },
    renderTabEdit: () => {
      const {
        currentStepIndex,
      } = this.state
      return (
        <Section spacingBottom='40'>
          <Step tabs>
            <Step.Tab
              active={currentStepIndex === 0}
              name={getLanguage('customersManage.step.customerInfo')}
              onClick={() => {
                this.customerManage.onClickTabEdit(0)
              }}
            />
            <Step.Tab
              active={currentStepIndex === 1}
              name={getLanguage('customersManage.step.idAddress')}
              onClick={() => {
                this.customerManage.onClickTabEdit(1)
              }}
            />
            <Step.Tab
              active={currentStepIndex === 2}
              name={getLanguage('customersManage.step.contactAddress')}
              onClick={() => {
                this.customerManage.onClickTabEdit(2)
              }}
            />
          </Step>
        </Section>
      )
    },
    onChangeAddressContactSameId: () => {
      let {
        isContactSameAddress,
        data,
      } = this.state;
      if (!isContactSameAddress) {
        data = this.customerManage.getDupAddress();
      }
      this.setState({
        data: _.cloneDeep(data),
        isContactSameAddress: !isContactSameAddress
      })
    },
    getDupAddress: () => {
      const {
        data,
      } = this.state;
      data.contactAddress = data.idAddress ? data.idAddress : '';
      data.contactSelectedProvince = data.idSelectedProvince ? data.idSelectedProvince : null;
      data.contactSelectedCity = data.idSelectedCity ? data.idSelectedCity : null;
      data.contactDistrict = data.idDistrict ? data.idDistrict : '';
      data.contactAlley = data.idAlley ? data.idAlley : '';
      data.contactGroup = data.idGroup ? data.idGroup : '';
      data.contactStreet = data.idStreet ? data.idStreet : '';
      data.contactWard = data.idWard ? data.idWard : '';
      //TODO: loas
      if (data.idAddressType === ENUM.COUNTRY_TYPE.THAI) {
        data.contactSubDistrict = data.idSubDistrict ? data.idSubDistrict : null;
        data.contactPostCode = data.idPostCode ? data.idPostCode : null;
      }
      else {
        data.contactSubDistrict = data.idSubDistrict ? data.idSubDistrict : '';
        data.contactPostCode = data.idPostCode ? data.idPostCode : '';
      }
      return data;
    }
  }

  input = {
    onChangeUpload: (file, result) => {
      const {
        data,
      } = this.state;
      data.imageUrl = result;
      data.imageFile = file;
      this.setState({
        data,
      });
    },
    onClickDeleteImage: () => {
      const {
        data,
      } = this.state;
      data.imageUrl = '';
      data.imageFile = undefined;
      this.setState({
        data,
      });
    },
    onChangeCustomerTypeRadio: (ev) => {
      const {
        customerRadioTypeList,
        data,
        userAuth,
      } = this.state
      const name = ev.target.name;
      // if (userAuth.addressType === ENUM.COUNTRY_TYPE.MALAYSIA) {
      //   switch (name) {
      //     case customerRadioTypeList[0].text:
      //       data.type = ENUM.CUSTOMER_TYPE.FIXERS.TYPE;
      //       break;
      //     case customerRadioTypeList[1].text:
      //       data.type = ENUM.CUSTOMER_TYPE.HARDWARE.TYPE;
      //       break;
      //     case customerRadioTypeList[2].text:
      //       data.type = ENUM.CUSTOMER_TYPE.HOMEOWNER.TYPE;
      //       break;
      //     case customerRadioTypeList[3].text:
      //       data.type = ENUM.CUSTOMER_TYPE.OTHERS.TYPE;
      //       break;
      //   }
      // }
      // else {
      //   switch (name) {
      //     case customerRadioTypeList[0].text:
      //       data.type = ENUM.CUSTOMER_TYPE.FIXERS.TYPE;
      //       break;
      //     case customerRadioTypeList[1].text:
      //       data.type = ENUM.CUSTOMER_TYPE.HARDWARE.TYPE;
      //       break;
      //     case customerRadioTypeList[2].text:
      //       data.type = ENUM.CUSTOMER_TYPE.HOMEOWNER.TYPE;
      //       break;
      //     case customerRadioTypeList[3].text:
      //       data.type = ENUM.CUSTOMER_TYPE.ROLL_FORMER.TYPE;
      //       break;
      //     case customerRadioTypeList[4].text:
      //       data.type = ENUM.CUSTOMER_TYPE.OTHERS.TYPE;
      //       break;
      //   }
      // }
      data.type = customerRadioTypeList.filter(e => e.text === name)[0]?.value
      this.setState({
        data,
        customerRadioTypeList: customerRadioTypeList.map(e => {
          e.isChecked = e.text === name;
          return e;
        })
      });
    },
    onChangeDataInput: (ev) => {
      let {
        data,
        isContactSameAddress,
      } = this.state;
      const name = ev.target.name;
      const value = ev.target.value;
      switch (name) {
        case 'phone':
          if (value.length && !isValidPhoneNumber(value)) {
            return;
          }
        case 'idCardNo':
        case 'idPostCode':
        case 'contactPostCode':
          if (value.length && !isValidNumber(value)) {
            return;
          }
        default:
          data[name] = value;
          if (isContactSameAddress) {
            data = this.customerManage.getDupAddress();
          }
          this.setState({
            data,
          });
      }
    },
    onChangePhoneCountry: (selected, target) => {
      const {
        data,
      } = this.state;
      data[target] = selected;
      this.setState({
        data,
      })
    },
    onChangeAddress: (selected, target) => {
      let {
        data,
        isContactSameAddress,
      } = this.state;
      data[target] = selected;
      switch (target) {
        case 'idSelectedProvince':
          data.idSelectedCity = null;
        case 'idSelectedCity':
          //TODO: loas
          if (data.idAddressType === ENUM.COUNTRY_TYPE.THAI) {
            data.idSubDistrict = null;
            data.idPostCode = null;
          }
          break;
        case 'idSubDistrict':
          //TODO: loas
          if (data.idAddressType === ENUM.COUNTRY_TYPE.THAI) {
            data.idPostCode = null;
          }
          break;
        case 'contactSelectedProvince':
          data.contactSelectedCity = null;
        case 'contactSelectedCity':
          //TODO: loas
          if (data.idAddressType === ENUM.COUNTRY_TYPE.THAI) {
            data.contactSubDistrict = null;
            data.contactPostCode = null;
          }
          break;
        case 'contactSubDistrict':
          //TODO: loas
          if (data.idAddressType === ENUM.COUNTRY_TYPE.THAI) {
            data.contactPostCode = null;
          }
          break;
        default: break;
      }

      if (isContactSameAddress) {
        data = this.customerManage.getDupAddress();
      }
      this.setState({
        data: _.cloneDeep(data),
      })
    },
    onChangeDatePicker: (date) => {
      const {
        data,
      } = this.state;
      data.dateOfBirth = date;
      console.log('date', date)
      this.setState({
        data,
      });
    },
    onChangeContactChannel: (type) => {
      const {
        data,
      } = this.state;
      if (data.contactChannelList.indexOf(type) >= 0) {
        data.contactChannelList.splice(data.contactChannelList.indexOf(type), 1);
      }
      else {
        data.contactChannelList.push(type);
      }
      this.setState({
        data,
      });
    }
  }

  render() {
    const {
      currentStepContent,
      isEdit,
    } = this.state
    return (
      <React.Fragment>
        <Breadcrumb>
          <Breadcrumb.List
            link
            name={getLanguage('customersManage.breadcrumb1')}
            onClick={() => {
              redirect(ROUTE_PATH.HOME.LINK)
            }}
          />
          <Breadcrumb.List name='/' />
          <Breadcrumb.List
            link
            name={getLanguage('customersManage.breadcrumb2')}
            onClick={() => {
              redirect(ROUTE_PATH.CUSTOMERS.LINK)
            }}
          />
          <Breadcrumb.List name='/' />
          <Breadcrumb.List name={isEdit ? getLanguage('customersManage.breadcrumb3_2') : getLanguage('customersManage.breadcrumb3_1')} />
        </Breadcrumb>
        <Section ui='content'>
          <TitlePage
            src={ICONS['ic-page-customer.svg']}
            name={isEdit ? getLanguage('customersManage.breadcrumb3_2') : getLanguage('customersManage.breadcrumb3_1')}
          />
          {
            !isEdit &&
            <Step
              label
              progressPercent={currentStepContent.stepPercent}
            >
              {
                currentStepContent.contentList.map((e, i) => {
                  return (
                    <Step.Number
                      key={i}
                      active={e.isActive}
                      success={e.isSuccess}
                      number={i + 1}
                      label={e.stepName}
                    />
                  )
                })
              }
            </Step>
          }
          {
            this.customerManage.renderContent()
          }
        </Section>
        <ModalInfoContainer
          // isOpenModal
          title={getLanguage('modal.info.notice', '')}
          message={getLanguage('message.nationalIdCard', '')}
          onClickClose={() => { }}
          onClickConfirm={() => { }}
        />
      </React.Fragment>
    )
  }
}
