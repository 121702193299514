import React from 'react'
import ClassNames from 'classnames'
import PropTypes from 'prop-types'
import {
  osName,
} from 'react-device-detect'
import {
  WarrantyProductInformationWrapper
} from './styled'
import {
  Section,
  Text,
  Image,
  Grid,
  Table,
  ShapeContainer,
} from './../../../../components'
import {
  VARIABLES,
} from './../../../../themes'

/**
 * Warranty description:
 * - Warranty
 */

export class WarrantyBSWMMalaysiaProductInformation extends React.PureComponent {
  render() {
    const {
      registeredTrademarkName,
      customerName,
      projectName,
      projectAddress,
      perforateByCorrosion,
      perforateByCorrosionYear,
      peelOrFlakeExcessively,
      peelOrFlakeExcessivelyYear,
      colourFadeExcessively,
      colourFadeExcessivelyYear,
      discolourByDirtStaining,
      discolourByDirtStainingYear,
    } = this.props

    // props for css classes
    const classes = ClassNames(
      'warranty-bswm-note',
    )

    return (
      <WarrantyProductInformationWrapper
        className={classes}
      >
        <ShapeContainer width='100' widthUnit='%' paddingVertical='10' paddingHorizontal='15' borderWidth='1' borderStyle='solid' borderColor={VARIABLES.COLORS.BLACK} borderRadius='5'>
          <Section>
            <Section spacingBottom='5'>
              <p className='warranty-bswm-text'>
                <span className='warranty-bswm-text is-bold'>NS BlueScope Malaysia Sdn Bhd</span> (manufacturer of <span className='warranty-bswm-text is-bold'>{registeredTrademarkName} </span> steel), warrants to <span className='warranty-bswm-text is-bold'>{customerName}</span> (supplier of profiled sheets) that the <span className='warranty-bswm-text is-bold'>{registeredTrademarkName}</span> steel if correctly installed and maintained on the <span className='warranty-bswm-text is-bold'>{projectName}</span> project at <span className='warranty-bswm-text is-bold'>{projectAddress}</span> :
              </p>
            </Section>
            {perforateByCorrosionYear &&
              <Grid>
                <Grid.Column>
                  <Section width='250'><p className='warranty-bswm-text'>- {perforateByCorrosion}:</p></Section>
                </Grid.Column>
                <Grid.Column>
                  <p className='warranty-bswm-text is-year'><span className='warranty-bswm-text is-bold'>{perforateByCorrosionYear}</span> Years</p>
                </Grid.Column>
              </Grid>
            }
            {peelOrFlakeExcessivelyYear &&
              <Grid>
                <Grid.Column>
                  <Section width='250'><p className='warranty-bswm-text'>- {peelOrFlakeExcessively}:</p></Section>
                </Grid.Column>
                <Grid.Column>
                  <p className='warranty-bswm-text is-year'><span className='warranty-bswm-text is-bold'>{peelOrFlakeExcessivelyYear}</span> Years</p>
                </Grid.Column>
              </Grid>
            }
            {colourFadeExcessivelyYear &&
              <Grid>
                <Grid.Column>
                  <Section width='250'><p className='warranty-bswm-text'>- {colourFadeExcessively}:</p></Section>
                </Grid.Column>
                <Grid.Column>
                  <p className='warranty-bswm-text is-year'><span className='warranty-bswm-text is-bold'>{colourFadeExcessivelyYear}</span> Years</p>
                </Grid.Column>
              </Grid>
            }
            {discolourByDirtStainingYear &&
              <Grid>
                <Grid.Column>
                  <Section width='250'><p className='warranty-bswm-text'>- {discolourByDirtStaining}:</p></Section>
                </Grid.Column>
                <Grid.Column>
                  <p className='warranty-bswm-text is-year'><span className='warranty-bswm-text is-bold'>{discolourByDirtStainingYear}</span> Years</p>
                </Grid.Column>
              </Grid>
            }
          </Section>
        </ShapeContainer>
      </WarrantyProductInformationWrapper>
    )
  }

  static defaultProps = {
    // If you set new default props, you should create propsType for correct styleguide document.
    registeredTrademarkName: '< Registered Trademark Name >',
    customerName: '< Customer Name >',
    projectName: '< Full Project Name >',
    projectAddress: '< Full Project Address >',
    perforateByCorrosion: 'Shall not perforate by corrosion for',
    perforateByCorrosionYear: 'XX',
    peelOrFlakeExcessively: 'Shall not peel or flake excessively for',
    peelOrFlakeExcessivelyYear: 'XX',
    colourFadeExcessively: 'Shall not colour fade excessively for',
    colourFadeExcessivelyYear: 'XX',
    discolourByDirtStaining: 'Shall not discolour by dirt staining for',
    discolourByDirtStainingYear: 'XX',
  }

  static propTypes = {
    // TYPE > node, string, number, bool, array, object, symbol, func
  }
}
