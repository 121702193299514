import React from 'react'
import ClassNames from 'classnames'
import PropTypes from 'prop-types'
import { osName, } from 'react-device-detect'
import { WarrantyContentWrapper } from './styled'
import { WarrantyBSWMThailandSignature } from './WarrantyBSWMThailandSignature'
import { WarrantyBSWMThailandNote } from './WarrantyBSWMThailandNote'
import { WarrantyBSWMThailandCladdingIssuesLists } from './WarrantyBSWMThailandCladdingIssuesLists'
import { WarrantyBSWMThailandNotCoveredLists } from './WarrantyBSWMThailandNotCoveredLists'
import { WarrantyBSWMThailandProductInformation } from './WarrantyBSWMThailandProductInformation'
import { WarrantyBSWMThailandProductConditions } from './WarrantyBSWMThailandProductConditions'
import { WarrantyBSWMThailandTableCoilId } from './WarrantyBSWMThailandTableCoilId'
import { WarrantyBSWMThailandTableBuilding } from './WarrantyBSWMThailandTableBuilding'
import { WarrantyBSWMThailandAdditionalInformation } from './WarrantyBSWMThailandAdditionalInformation'
import { WarrantyBSWMThailandApplicationDetail } from './WarrantyBSWMThailandApplicationDetail'
import { WarrantyBSWMThailandTermsAndConditions } from './WarrantyBSWMThailandTermsAndConditions'
import {
  Section,
  Text,
  Image,
  Grid,
  Table,
  ShapeContainer,
} from './../../../../components'
import { VARIABLES, } from './../../../../themes'
import { displayDate } from '../../../../helpers/functions/display'

/**
 * Warranty description:
 * - Warranty
 */

export class WarrantyBSWMThailandSuperdymaSDuckK1dsdTh extends React.PureComponent {
  render() {
    const {
      registeredTrademarkName,
      customerName,
      projectName,
      projectAddress,
      perforateByCorrosionYear,
      peelOrFlakeExcessivelyYear,
      colourFadeExcessivelyYear,
      discolourByDirtStainingYear,
      coilNumber,
      dataGroupList,
      currentDate,
      additionalProjectInformation,
      roof,
      wall,
      purlinAndGirt,
      trussAndFrame,
      coolRoomPanel,
      decking,
      airDucting,
      thLanguage,
      isHideApplicationDetail,
    } = this.props
    let cntDisplayCoil = 0;
    let cntDisplayCustomerRemark = 0;
    let cntPageProduct = 0;
    let cntPageApplication = 0;
    let cntPageLimit = 10;
    let totalRowProductRow = 0;
    let totalApplicationRow = 0;
    dataGroupList.forEach(e => {
      totalRowProductRow += e.displayCoil.length
      totalApplicationRow += e.applicationInfoList.length;
    });
    const totalRow = totalRowProductRow + totalApplicationRow;
    const totalPageProduct = Math.ceil(totalRowProductRow / 10);
    const totalPageApplication = Math.ceil(totalApplicationRow / 10);

    // props for css classes
    const classes = ClassNames(
      'warranty-bswm-content',
    )

    return (
      <React.Fragment>
        {/* Page 1 */}
        <WarrantyContentWrapper
          className={classes}
        >
          <div className='warranty-bswm-container is-spacebetween'>
            <div className='warranty-bswm-section'>
              <Section spacingBottom='15'><p className='warranty-bswm-text is-huge is-bold is-center'>{false ? 'การรับประกัน' : 'WARRANTY'}</p></Section>
              <Section spacingBottom='15'>
                <WarrantyBSWMThailandProductInformation
                  registeredTrademarkName={registeredTrademarkName}
                  customerName={customerName}
                  projectName={projectName}
                  projectAddress={projectAddress}
                  // perforateByCorrosion={''}
                  perforateByCorrosionYear={perforateByCorrosionYear}
                  // peelOrFlakeExcessively={''}
                  peelOrFlakeExcessivelyYear={peelOrFlakeExcessivelyYear}
                  // colourFadeExcessively={''}
                  colourFadeExcessivelyYear={colourFadeExcessivelyYear}
                  // discolourByDirtStaining={''}
                  discolourByDirtStainingYear={discolourByDirtStainingYear}
                  thLanguage={false}
                  prefixRegisteredTrademarkName={'เคลือบ'}
                />
              </Section>
              {/* {!thLanguage && */}
                <Section paddingHorizontal='15' spacingBottom='15'><p className='warranty-bswm-text is-xs'>* Terms and conditions apply. Please consult NS BlueScope (Thailand) Company Limited representative.</p></Section>
              {/* } */}
              <Section paddingHorizontal='15'>
                <WarrantyBSWMThailandProductConditions
                  registeredTrademarkName={registeredTrademarkName}
                  customerName={customerName}
                  objectName={'air ducting'}
                  isSteel
                  isSheets
                  isCladding={false}
                  thLanguage={false}
                  prefixRegisteredTrademarkName={'เคลือบ'}
                  objectThName={'โครงสร้างเหล็กของอาคาร'}
                  isComments={false}
                  isMaterialLabel={false}
                />
                {additionalProjectInformation &&
                  <WarrantyBSWMThailandAdditionalInformation
                    additionalProjectInformation={additionalProjectInformation}
                    thLanguage={false}
                  />
                }
              </Section>
            </div>
            <div className='warranty-bswm-section'>
              {/* <Section spacingBottom='20'>
                <WarrantyBSWMThailandSignature
                  customerName={customerName}
                  date={currentDate}
                  thLanguage={false}
                  isSignatureNote
                  registeredTrademarkName={registeredTrademarkName}
                />
              </Section> */}
              <WarrantyBSWMThailandNote
                customerName={customerName}
                thLanguage={false}
                isCustomer={false}
                registeredTrademarkName={registeredTrademarkName}
              />
            </div>
          </div>
        </WarrantyContentWrapper>
        {/* Page 2 */}
        {Array.from({ length: totalRow > cntPageLimit ? totalPageProduct + totalPageApplication : 1 }).map((page, pageIndex) => {
          cntPageProduct = pageIndex * cntPageLimit;
          cntPageApplication = totalRow > cntPageLimit ? (pageIndex - totalPageProduct) * cntPageLimit : cntPageProduct;

          return (
            <WarrantyContentWrapper key={pageIndex}
              className={classes}
            >
              <div className='warranty-bswm-container is-spacebetween'>
                <div className='warranty-bswm-section'>
                  <Section spacingTop='1' spacingBottom='15'><p className='warranty-bswm-text is-huge is-bold is-center'>{false ? 'การรับประกัน' : 'WARRANTY'}</p></Section>
                  <Section spacingBottom='15'>
                    <WarrantyBSWMThailandProductInformation
                      registeredTrademarkName={registeredTrademarkName}
                      customerName={customerName}
                      projectName={projectName}
                      projectAddress={projectAddress}
                      // perforateByCorrosion={''}
                      perforateByCorrosionYear={''}
                      // peelOrFlakeExcessively={''}
                      peelOrFlakeExcessivelyYear={''}
                      // colourFadeExcessively={''}
                      colourFadeExcessivelyYear={''}
                      // discolourByDirtStaining={''}
                      discolourByDirtStainingYear={''}
                      thLanguage={false}
                      prefixRegisteredTrademarkName={'เคลือบ'}
                    />
                  </Section>
                  <Section paddingHorizontal='15'>
                    {cntDisplayCoil < totalRowProductRow &&
                      <React.Fragment>
                        <Section spacingBottom='10'>
                          <p className='warranty-bswm-text'>{false ? 'เอกสารแนบ' : 'Attachment'} {pageIndex + 1}</p>
                        </Section>
                        <Section width='690' spacingBottom='15'>
                          <WarrantyBSWMThailandTableCoilId thLanguage={false}>
                            {dataGroupList.map((e, i) => {
                              return (
                                <Table.Section key={i}>
                                  {e.displayCoil.map((ec, ic) => {
                                    if (ic >= cntPageProduct && ic < cntPageProduct + cntPageLimit) {
                                      cntDisplayCoil += 1;
                                      return (
                                        <Table.Row key={ic}>
                                          <Table.Column className='is-body-column' alignCenter maxWidth='50' minWidth='50' paddingVertical='4' paddingHorizontal='4'>
                                            <p className='warranty-bswm-text is-sm is-bold'>{cntDisplayCoil}</p>
                                          </Table.Column>
                                          <Table.Column className='is-body-column' maxWidth='400' minWidth='400' paddingVertical='4' paddingHorizontal='4'>
                                            <p className='warranty-bswm-text is-sm is-bold'>{e.coilNameList[ic]}</p>
                                          </Table.Column>
                                          <Table.Column className='is-body-column' maxWidth='240' minWidth='240' paddingVertical='4' paddingHorizontal='4'>
                                            <p className='warranty-bswm-text is-sm is-bold'>{e.coilIdList[ic]}</p>
                                          </Table.Column>
                                        </Table.Row>
                                      )
                                    }
                                  })}
                                </Table.Section>
                              )
                            })}
                          </WarrantyBSWMThailandTableCoilId>
                        </Section>
                      </React.Fragment>
                    }
                    {isHideApplicationDetail || cntPageApplication < 0 ?
                      undefined
                      :
                      <React.Fragment>
                        <Section spacingBottom='15'>
                          <WarrantyBSWMThailandApplicationDetail
                            roof={roof}
                            wall={wall}
                            purlinAndGirt={purlinAndGirt}
                            trussAndFrame={trussAndFrame}
                            coolRoomPanel={coolRoomPanel}
                            decking={decking}
                            airDucting={airDucting}
                            thLanguage={false}
                          />
                        </Section>
                        <Section width='690' spacingBottom='15'>
                          <WarrantyBSWMThailandTableBuilding thLanguage={false}>
                            {dataGroupList.map((e, i) => {
                              return (
                                <Table.Section key={i}>
                                  {e.applicationInfoList.map((ec, ic) => {
                                    if (ic >= cntPageApplication && ic < cntPageApplication + cntPageLimit) {
                                      cntDisplayCustomerRemark += 1;
                                      return (
                                        <Table.Row key={ic}>
                                          <Table.Column className='is-body-column' alignCenter maxWidth='50' minWidth='50' paddingVertical='4' paddingHorizontal='4'>
                                            <p className='warranty-bswm-text is-sm is-bold'>{cntDisplayCustomerRemark}</p>
                                          </Table.Column>
                                          <Table.Column className='is-body-column' maxWidth='200' minWidth='200' paddingVertical='4' paddingHorizontal='4'>
                                            <p className='warranty-bswm-text is-sm is-bold'>{ec.building}</p>
                                          </Table.Column>
                                          <Table.Column className='is-body-column' alignCenter maxWidth='200' minWidth='200' paddingVertical='4' paddingHorizontal='4'>
                                            <p className='warranty-bswm-text is-sm is-bold'>{displayDate(ec.projectInsDateTime)}</p>
                                          </Table.Column>
                                          <Table.Column className='is-body-column' maxWidth='240' minWidth='240' paddingVertical='4' paddingHorizontal='4'>
                                            <Section>
                                              {/* <p className='warranty-bswm-text is-sm is-bold'>Roof: {roofProfileNameInM2}</p>
                                            <p className='warranty-bswm-text is-sm is-bold'>Wall: {wallProfileNameInM2}</p> */}
                                              <p className='warranty-bswm-text is-sm is-bold'>{ec.customerRemark}</p>
                                            </Section>
                                          </Table.Column>
                                        </Table.Row>
                                      )
                                    }
                                  })}
                                </Table.Section>
                              )
                            })}
                          </WarrantyBSWMThailandTableBuilding>
                        </Section>
                      </React.Fragment>
                    }
                  </Section>
                </div>
                <div className='warranty-bswm-section'>
                  <Section spacingBottom='20'>
                    <WarrantyBSWMThailandSignature
                      customerName={customerName}
                      date={currentDate}
                      thLanguage={false}
                    />
                  </Section>
                  <WarrantyBSWMThailandNote
                    customerName={customerName}
                    thLanguage={false}
                    isCustomer
                    registeredTrademarkName={registeredTrademarkName}
                  />
                </div>
              </div>
            </WarrantyContentWrapper>
          )
        })}
        {/* Page 3 */}
        <WarrantyContentWrapper
          className={classes}
        >
          {false ?
            <React.Fragment>
              <Section spacingBottom='10'><p className='warranty-bswm-text is-bold is-underline'>ข้อกำหนดและเงื่อนไข</p></Section>
              <WarrantyBSWMThailandTermsAndConditions intro3 registeredTrademarkName={registeredTrademarkName} />
              <WarrantyBSWMThailandTermsAndConditions type11 number='1' />
              <WarrantyBSWMThailandTermsAndConditions type12 number='2' />
              <WarrantyBSWMThailandTermsAndConditions type13 number='3' />
              <WarrantyBSWMThailandTermsAndConditions type14 number='4' />
              <WarrantyBSWMThailandTermsAndConditions type15 number='5' />
              <WarrantyBSWMThailandTermsAndConditions type16 number='6' spacingBottom='10' />
              <WarrantyBSWMThailandTermsAndConditions intro4 registeredTrademarkName={registeredTrademarkName} />
              <WarrantyBSWMThailandTermsAndConditions intro5 registeredTrademarkName={registeredTrademarkName} spacingBottom='10' />
              <WarrantyBSWMThailandTermsAndConditions heading1 />
              <WarrantyBSWMThailandTermsAndConditions type17 number='1' />
              <WarrantyBSWMThailandTermsAndConditions type18 number='2' />
              <WarrantyBSWMThailandTermsAndConditions type19 number='3' registeredTrademarkName={registeredTrademarkName} />
              <WarrantyBSWMThailandTermsAndConditions type20 number='4' registeredTrademarkName={registeredTrademarkName} spacingBottom='10' />
              <WarrantyBSWMThailandTermsAndConditions heading2 />
              <WarrantyBSWMThailandTermsAndConditions type21 number='1' registeredTrademarkName={registeredTrademarkName} />
              <WarrantyBSWMThailandTermsAndConditions type22 number='2' registeredTrademarkName={registeredTrademarkName} spacingBottom='10' />
              <WarrantyBSWMThailandTermsAndConditions heading3 />
              <WarrantyBSWMThailandTermsAndConditions intro6 />
              <WarrantyBSWMThailandTermsAndConditions type23 number='1' registeredTrademarkName={registeredTrademarkName} />
              <WarrantyBSWMThailandTermsAndConditions type24 number='2' registeredTrademarkName={registeredTrademarkName} spacingBottom='10' />
              <WarrantyBSWMThailandTermsAndConditions intro />
              <WarrantyBSWMThailandTermsAndConditions intro8 spacingBottom='0' />
            </React.Fragment>
            :
            <React.Fragment>
              <Section spacingBottom='10'><p className='warranty-bswm-text is-bold is-underline'>TERMS AND CONDITIONS</p></Section>
              <Section spacingBottom='5'><p className='warranty-bswm-text is-xs'>This warranty is your guarantee that <span className='warranty-bswm-text is-xs is-bold'>SuperDyma® SmartDuct™ Steel</span>, if properly installed and maintained, will meet minimum expected performance levels for at least the warranted period (commencing from the date of installation).</p></Section>
              <Section spacingBottom='5'><p className='warranty-bswm-text is-xs'>However, certain specific problems and situations (listed below) must be addressed and rectified early in order to get the best possible performance and, if not rectified, may result in the warranty being void.</p></Section>
              <Section spacingBottom='5'>
                <Grid>
                  <Grid.Column flex='none'><Section width='17'><p className='warranty-bswm-text is-xs'>1.</p></Section></Grid.Column>
                  <Grid.Column flex='1'><p className='warranty-bswm-text is-xs'>The warranty applies only to air ducting made from prime quality <span className='warranty-bswm-text is-xs is-bold'>{registeredTrademarkName}</span> steel installed inside building only. The Owner/Contractor is advised to check for the <span className='warranty-bswm-text is-xs is-bold'>{registeredTrademarkName}</span> steel brand name printed on the reverse of the sheets prior to installation.</p></Grid.Column>
                </Grid>
              </Section>
              <Section>
                <Grid>
                  <Grid.Column flex='none'><Section width='17'><p className='warranty-bswm-text is-xs'>2.</p></Section></Grid.Column>
                  <Grid.Column flex='1'><p className='warranty-bswm-text is-xs'>Air ducting must be handled and installed in accordance with published BlueScope guidelines current at the time of installation, with particular attention to the issues listed below:-</p></Grid.Column>
                </Grid>
              </Section>
              <Section paddingLeft='17' spacingBottom='5'>
                <WarrantyBSWMThailandCladdingIssuesLists />
              </Section>
              <Section spacingBottom='5'>
                <Grid>
                  <Grid.Column flex='none'><Section width='17'><p className='warranty-bswm-text is-xs'>3.</p></Section></Grid.Column>
                  <Grid.Column flex='1'><p className='warranty-bswm-text is-xs'>Fasteners must comply with AS3566 Class 3 or Class 4, and be certified as such by the supplier of fasteners.</p></Grid.Column>
                </Grid>
              </Section>
              <Section spacingBottom='5'>
                <Grid>
                  <Grid.Column flex='none'><Section width='17'><p className='warranty-bswm-text is-xs'>4.</p></Section></Grid.Column>
                  <Grid.Column flex='1'><p className='warranty-bswm-text is-xs'>All fixings, flashings and associated components must be manufactured from compatible metals (COLORBOND® steel, ZINCALUME® steel, SuperDyma® steel, zinc, or aluminium) or be coated or plated in accordance with current published BlueScope guidelines. For long lasting consistency in appearance, flashings should be made from the same material as the air ducting.</p></Grid.Column>
                </Grid>
              </Section>
              <Section spacingBottom='5'>
                <Grid>
                  <Grid.Column flex='none'><Section width='17'><p className='warranty-bswm-text is-xs'>5.</p></Section></Grid.Column>
                  <Grid.Column flex='1'><p className='warranty-bswm-text is-xs'>Only neutral cure sealants are to be applied to the joints. Slip joints and channels must be inspected annually and sealant replaced where necessary.</p></Grid.Column>
                </Grid>
              </Section>
              <Section spacingBottom='5'>
                <Grid>
                  <Grid.Column flex='none'><Section width='17'><p className='warranty-bswm-text is-xs'>6.</p></Section></Grid.Column>
                  <Grid.Column flex='1'><p className='warranty-bswm-text is-xs'>The coating finish as supplied must not be scratched, abraded damaged, or coated with any foreign matter or incompatible material (including stack and flue deposits).</p></Grid.Column>
                </Grid>
              </Section>
              <Section spacingBottom='5'>
                <Grid>
                  <Grid.Column flex='none'><Section width='17'><p className='warranty-bswm-text is-xs'>7.</p></Section></Grid.Column>
                  <Grid.Column flex='1'><p className='warranty-bswm-text is-xs'>The air ducting must be installed greater than 2km from marine or industrial influence.</p></Grid.Column>
                </Grid>
              </Section>
              <Section spacingBottom='5'>
                <Grid>
                  <Grid.Column flex='none'><Section width='17'><p className='warranty-bswm-text is-xs'>8.</p></Section></Grid.Column>
                  <Grid.Column flex='1'><p className='warranty-bswm-text is-xs'>For SuperDyma® SmartDuct™ Steel which has a base metal thickness greater than 0.70 (or greater), warranty applied only to SuperDyma® SmartDuct™ K18.</p></Grid.Column>
                </Grid>
              </Section>
              <Section>
                <Grid>
                  <Grid.Column flex='none'><Section width='17'><p className='warranty-bswm-text is-xs'>9.</p></Section></Grid.Column>
                  <Grid.Column flex='1'><p className='warranty-bswm-text is-xs'>Product failures wholly or in part due to the following causes are not covered by this warranty.</p></Grid.Column>
                </Grid>
              </Section>
              <Section paddingLeft='17' spacingBottom='5'>
                <Grid>
                  <Grid.Column flex='none'><Section width='17'><p className='warranty-bswm-text is-xs'>a.</p></Section></Grid.Column>
                  <Grid.Column flex='1'><p className='warranty-bswm-text is-xs'>Defects attributed to faulty design, method of manufacture or installation of the product.</p></Grid.Column>
                </Grid>
                <Grid>
                  <Grid.Column flex='none'><Section width='17'><p className='warranty-bswm-text is-xs'>b.</p></Section></Grid.Column>
                  <Grid.Column flex='1'><p className='warranty-bswm-text is-xs'>Mechanical, chemicals or other damage sustained during transport, handling, storage, erection or subsequent to erection.</p></Grid.Column>
                </Grid>
                <Grid>
                  <Grid.Column flex='none'><Section width='17'><p className='warranty-bswm-text is-xs'>c.</p></Section></Grid.Column>
                  <Grid.Column flex='1'><p className='warranty-bswm-text is-xs'>Attack from chemical agents, like acid, alkali, sulfurous acid gas, or other corrosive substances including animal feces, fumes, liquids or solids other than direct rainfall or run-off falling onto the end product.</p></Grid.Column>
                </Grid>
                <Grid>
                  <Grid.Column flex='none'><Section width='17'><p className='warranty-bswm-text is-xs'>d.</p></Section></Grid.Column>
                  <Grid.Column flex='1'><p className='warranty-bswm-text is-xs'>Unwashed areas sheltered from normal rainfall and not regularly cleaned.</p></Grid.Column>
                </Grid>
                <Grid>
                  <Grid.Column flex='none'><Section width='17'><p className='warranty-bswm-text is-xs'>e.</p></Section></Grid.Column>
                  <Grid.Column flex='1'><p className='warranty-bswm-text is-xs'>Extended contact with soil, dust, ashes, fertilizers, wet cement or moisture retaining substances of any adjoining building materials.</p></Grid.Column>
                </Grid>
                <Grid>
                  <Grid.Column flex='none'><Section width='17'><p className='warranty-bswm-text is-xs'>f.</p></Section></Grid.Column>
                  <Grid.Column flex='1'><p className='warranty-bswm-text is-xs'>Contact with incompatible materials (eg. lead, copper, carbon/graphite, or stainless steel) or subject to water run-off from such incompatible materials.</p></Grid.Column>
                </Grid>
                <Grid>
                  <Grid.Column flex='none'><Section width='17'><p className='warranty-bswm-text is-xs'>g.</p></Section></Grid.Column>
                  <Grid.Column flex='1'><p className='warranty-bswm-text is-xs'>Failure to remove debris (dust, metal powder, fallen leaves) and/or failure to provide free drainage of water including internal condensation all surfaces of the end product.</p></Grid.Column>
                </Grid>
                <Grid>
                  <Grid.Column flex='none'><Section width='17'><p className='warranty-bswm-text is-xs'>h.</p></Section></Grid.Column>
                  <Grid.Column flex='1'><p className='warranty-bswm-text is-xs'>Abnormally long periods/cycles of wet due to splashing, ponding, washing or from any other similar activities (e.g. use of the Goods product in bathrooms).</p></Grid.Column>
                </Grid>
                <Grid>
                  <Grid.Column flex='none'><Section width='17'><p className='warranty-bswm-text is-xs'>i.</p></Section></Grid.Column>
                  <Grid.Column flex='1'><p className='warranty-bswm-text is-xs'>Improper design or detailing as such that moisture is retained via capillary action or frequent wet and dry cycling in the product.</p></Grid.Column>
                </Grid>
                <Grid>
                  <Grid.Column flex='none'><Section width='17'><p className='warranty-bswm-text is-xs'>j.</p></Section></Grid.Column>
                  <Grid.Column flex='1'><p className='warranty-bswm-text is-xs'>Corrosion arising within the lapped areas of end-lapped sheet including, but not limited to, brackets, end-caps, astragals and leaf guards etc.</p></Grid.Column>
                </Grid>
                <Grid>
                  <Grid.Column flex='none'><Section width='17'><p className='warranty-bswm-text is-xs'>k.</p></Section></Grid.Column>
                  <Grid.Column flex='1'><p className='warranty-bswm-text is-xs'>Failure to replace corroded fasteners/bolts/rivets, brackets or accessories.</p></Grid.Column>
                </Grid>
                <Grid>
                  <Grid.Column flex='none'><Section width='17'><p className='warranty-bswm-text is-xs'>l.</p></Section></Grid.Column>
                  <Grid.Column flex='1'><p className='warranty-bswm-text is-xs'>Deterioration of the air ducts caused by contact with wet, green or treated timber due to acidic substances in the timber which have a corrosive effect on the metallic coating.</p></Grid.Column>
                </Grid>
                <Grid>
                  <Grid.Column flex='none'><Section width='17'><p className='warranty-bswm-text is-xs'>m.</p></Section></Grid.Column>
                  <Grid.Column flex='1'><p className='warranty-bswm-text is-xs'>Severe marine, industrial or corrosive environments unless specifically agreed and noted.</p></Grid.Column>
                </Grid>
                <Grid>
                  <Grid.Column flex='none'><Section width='17'><p className='warranty-bswm-text is-xs'>n.</p></Section></Grid.Column>
                  <Grid.Column flex='1'><p className='warranty-bswm-text is-xs'>Acts of God and falling objects, external force, explosions, fire, riots, civil commotions, acts of war, radiation, harmful gases or fumes, chemicals or foreign substances in the air or atmosphere, acts of terrorism, global warming, flooding and political disturbances including but not limited to uprising and protests.</p></Grid.Column>
                </Grid>
              </Section>
              <Section spacingBottom='5'>
                <Grid>
                  <Grid.Column flex='none'><Section width='17'><p className='warranty-bswm-text is-xs'>10.</p></Section></Grid.Column>
                  <Grid.Column flex='1'><p className='warranty-bswm-text is-xs'>Failure of the product from corrosion is defined as when the air ducts is perforated. Surface rust, or minor corrosion at tension bends or cuts edges due to natural weathering does not constitute failure of the product.</p></Grid.Column>
                </Grid>
              </Section>
              <Section spacingBottom='5'>
                <Grid>
                  <Grid.Column flex='none'><Section width='17'><p className='warranty-bswm-text is-xs'>11.</p></Section></Grid.Column>
                  <Grid.Column flex='1'><p className='warranty-bswm-text is-xs'>When designing the ducts should avoid sharp turn or elbow and use larger diameter duct to reduce the velocity of the fluid in the duct but still able to maintain the same flow rate.</p></Grid.Column>
                </Grid>
              </Section>
              <Section>
                <Grid>
                  <Grid.Column flex='none'><Section width='17'><p className='warranty-bswm-text is-xs'>12.</p></Section></Grid.Column>
                  <Grid.Column flex='1'><p className='warranty-bswm-text is-xs'>Any defects must be informed to NSBST in writing within 30 days of the date NSBST in its sole discretion believes such defect should reasonably have been found given each individual circumstance.</p></Grid.Column>
                </Grid>
              </Section>
            </React.Fragment>
          }
        </WarrantyContentWrapper>
      </React.Fragment>
    )
  }

  static defaultProps = {
    // If you set new default props, you should create propsType for correct styleguide document.
    registeredTrademarkName: 'SuperDyma®',
    customerName: '< Customer Name >',
    projectName: '< Full Project Name >',
    projectAddress: '< Full Project Address >',
    perforateByCorrosionYear: 'XX',
    peelOrFlakeExcessivelyYear: 'XX',
    colourFadeExcessivelyYear: 'XX',
    discolourByDirtStainingYear: 'XX',
    coilNumber: '< COIL NO >',
    additionalProjectInformation: '< Additional Project Information >',
    roof: 'XX',
    wall: 'XX',
    purlinAndGirt: 'XX',
    trussAndFrame: 'XX',
    coolRoomPanel: 'XX',
    decking: 'XX',
    airDucting: 'XX',
  }

  static propTypes = {
    // TYPE > node, string, number, bool, array, object, symbol, func
  }
}
