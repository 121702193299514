import styled, {
  css
} from 'styled-components'
import {
  default as VARIABLES
} from './../../../themes/styles/bases/variables'
import {
  default as TYPOGRAPHYS
} from './../../../themes/styles/bases/typographys'
// import {
//   default as MIXINS
// } from './../../../themes/styles/helpers/mixins'
// import {
//   // Example using:
//   // background: url(${CONTENTS['image-SelectControl.svg']});
//   // CONTENTS,
//   ICONS,
//   // LOGOS,
//   // SHAREDS,
//   // DOCUMENTS
// } from './../../../themes'

// Wrapper
// ============================================================
export const SelectControlWrapper = styled.div`
  /* Parent
  ------------------------------- */
  display: flex;

  /* Childrens
  ------------------------------- */
  .select-control-label {
    flex: none;
    margin-bottom: 5px;

    ${props => {
    const theme = props.theme
    return css`
        ${theme.labelFontStyle}

        ${theme.labelWidth && css`
            width: ${theme.labelWidth + `px`};
          `
      }

        ${theme.labelSpacingTop && css`
            padding-top: ${theme.labelSpacingTop + `px`};
          `
      }

        ${theme.labelSpacingRight && css`
            margin-right: ${theme.labelSpacingRight + `px`};
          `
      }

        color: ${theme.labelFontColor};
        text-align: ${theme.labelAlign};
      `
  }}
  }

  .select-control-select {
    flex: 1;

    ${props => {
    const theme = props.theme
    return css`
        ${theme.width && css`
            width: ${theme.width + `px`};
          `
      }
      `
  }}
  }

  .select-control-message {
    ${props => {
    const theme = props.theme
    return css`
        ${theme.messageFontStyle}

        ${theme.messageSpacingTop && css`
            padding-top: ${theme.messageSpacingTop + `px`};
          `
      }

        ${theme.messageSpacingLeft && css`
            padding-left: ${theme.messageSpacingLeft + `px`};
          `
      }

        color: ${theme.messageFontColor};
        text-align: ${theme.messageAlign};
      `
  }}
  }

  .react-select {}

  .react-select__control {
    min-height: ${(VARIABLES.FIELD.HEIGHT - 2) + `px`};
    background-color: ${VARIABLES.COLORS.WHITE};
    border-top-width: 1px;
    border-right-width: 1px;
    border-left-width: 1px;
    border-bottom-width: 1px;
    border-color: ${VARIABLES.COLORS.GRAY_8};
    border-radius: ${VARIABLES.FIELD.BORDER_RADIUS + `px`};

    &:hover {
      border-color: ${VARIABLES.COLORS.GRAY_8};
    }

    &--is-focused {
      min-height: ${(VARIABLES.FIELD.HEIGHT - 2) + `px`};
      background-color: ${VARIABLES.COLORS.WHITE};
      border-top-width: 1px;
      border-right-width: 1px;
      border-left-width: 1px;
      border-bottom-width: 1px;
      border-radius: ${VARIABLES.FIELD.BORDER_RADIUS + `px`};
      box-shadow: 0 0 0 0 transparent;
    }
  }

  .react-select__value-container {
    padding: 0 ${VARIABLES.FIELD.PADDINGS.HORIZONTAL + `px`};
    height: ${(VARIABLES.FIELD.HEIGHT - 2) + `px`};
  }

  .react-select__placeholder {
    ${TYPOGRAPHYS.FIRST.REGULAR.FS_14};
    margin-right: 0;
    margin-left: 0;
    color: ${VARIABLES.COLORS.GRAY_12};

    + [class*='-Input'] {
      margin: 0;
    }
  }

  .react-select__input {
    input {
      ${TYPOGRAPHYS.FIRST.REGULAR.FS_14};
      color: ${VARIABLES.COLORS.BLACK};
      line-height: 26px;
    }
  }

  .react-select__single-value {
    ${TYPOGRAPHYS.FIRST.REGULAR.FS_14};
    margin-right: 0;
    margin-left: 0;
    color: ${VARIABLES.COLORS.BLACK};
    line-height: 26px;
  }

  .react-select__indicators {
    width: 56px;
    height: ${(VARIABLES.FIELD.HEIGHT - 2) + `px`};
    background: url(${require('./images/ic-arrow-select.svg')}) no-repeat center center;
    background-size: 13px 8px;
  }

  .react-select__indicator-separator,
  .react-select__dropdown-indicator {
    display: none;
  }

  .react-select__menu {
    ${TYPOGRAPHYS.FIRST.REGULAR.FS_14}; /* For no options */
    z-index: ${VARIABLES.Z_INDEXS.LV_2};
    margin-top: 8px;
    margin-bottom: 8px;
    background-color: ${VARIABLES.COLORS.WHITE};
    /* border: 1px solid ${VARIABLES.COLORS.GRAY_8}; */
    border-radius: ${VARIABLES.FIELD.BORDER_RADIUS + `px`};
    color: ${VARIABLES.COLORS.BLACK};
    box-shadow: ${VARIABLES.COLORS.SHADOW_3};

    > div {
      ${props => {
    const theme = props.theme
    return css`
            ${theme.optionHeight && css`
                max-height: ${theme.optionHeight + `px`};
              `
      }
          `
  }}
    } 
  }

  .react-select__menu-list {
    padding: 5px 0;
    background-color: transparent;
  }

  .react-select__option {
    ${TYPOGRAPHYS.FIRST.REGULAR.FS_14};
    cursor: pointer;
    padding: 9px ${VARIABLES.FIELD.PADDINGS.HORIZONTAL + `px`};
    line-height: 1;
    color: ${VARIABLES.COLORS.BLACK};

    &:hover {
      /* background-color: ${VARIABLES.COLORS.PRIMARY_1}; */
      color: ${VARIABLES.COLORS.PRIMARY_2};
    }

    &--is-selected {
      color: ${VARIABLES.COLORS.PRIMARY_2};
    }
  }

  /* Modifiers
  ------------------------------- */
  &.is-error {
    .select-control-message {
      ${props => {
    const theme = props.theme
    return css`
          color: ${theme.errorColor};
        `
  }}
    }

    .react-select__control {
      ${props => {
    const theme = props.theme
    return css`
          border-color: ${theme.errorColor};
        `
  }}
    }
  }

  &.is-success {
    .select-control-message {
      ${props => {
    const theme = props.theme
    return css`
          color: ${theme.successColor};
        `
  }}
    }

    .react-select__control {
      ${props => {
    const theme = props.theme
    return css`
          border-color: ${theme.successColor};
        `
  }}
    }
  }

  &.is-disabled {
    .select-control-select {
      pointer-events: none;
      cursor: none;
    }

    .select-control-message {
      ${props => {
    const theme = props.theme
    return css`
          color: ${theme.disabledColor};
        `
  }}
    }

    .react-select__control {
      ${props => {
    const theme = props.theme
    return css`
          background-color: ${theme.disabledColor};
        `
  }}
    }
  }

  &.is-no-arrow {
    .react-select__indicators {
      display: none;
    }
  }

  &.is-stack-label {
    @media (max-width: ${VARIABLES.BREAKPOINTS.LAPTOP_XS_MAX}) {
      display: block;

      .select-control-label {
        display: block;
        width: auto;
        padding-top: 0;
        margin-bottom: 10px;
        margin-right: 0;
        text-align: left;
      }
    }
  }

  &.is-vertical-label {
    display: block;
  }

  &.is-required {
    .select-control-select {
      position: relative;

      &:after {
        content: ' ';
        position: absolute;
        z-index: ${VARIABLES.Z_INDEXS.LV_1};
        top: 0;
        right: -10px;
        display: block;
        width: 6px;
        height: 6px;
        background-color: ${VARIABLES.COLORS.ERROR};
        border-radius: 50%;
      }
    }
  }

  &.is-filter,
  &.is-filter-large,
  &.is-filter-fluid,
  &.is-filter-border,
  &.is-filter-search {
    .react-select__control {
      min-height: 30px;

      &--is-focused {
        min-height: 30px;
      }
    }

    .react-select__indicators {
      height: 30px;
    }

    .react-select__placeholder,
    .react-select__input input,
    .react-select__single-value,
    .react-select__menu,
    .react-select__option {
      ${TYPOGRAPHYS.FIRST.REGULAR.FS_12};
    }
  }

  &.is-filter,
  &.is-filter-large,
  &.is-filter-fluid,
  &.is-filter-search {
    .react-select__control {
      border-color: ${VARIABLES.COLORS.WHITE};

      &:hover {
        border-color: ${VARIABLES.COLORS.WHITE};
      }
    }
  }

  &.is-filter-border {
    .react-select__control {
      border-color: ${VARIABLES.COLORS.GRAY_2};

      &:hover {
        border-color: ${VARIABLES.COLORS.GRAY_2};
      }
    }
  }

  &.is-filter {
    width: 170px;
  }

  &.is-filter-large {
    width: 165px;
  }

  &.is-filter-large-placeholder-short {
    width: 145px;
  }

  &.is-filter-large-placeholder-long {
    width: 185px;
  }

  &.is-filter-fluid,
  &.is-filter-search {
    width: 100%;
  }

  &.is-order-inquiry-submitted,
  &.is-order-inquiry-acknownledged {
    .react-select__option {
      color: ${VARIABLES.COLORS.GRAY_19};

      &:hover {
        color: ${VARIABLES.COLORS.BLACK};
      }

      &--is-selected {
        color: ${VARIABLES.COLORS.BLACK};
      }
    }
  }

  &.is-order-inquiry-submitted {
    .react-select__single-value {
      color: ${VARIABLES.COLORS.RED_4};
    }
  }

  &.is-order-inquiry-acknownledged {
    .react-select__single-value {
      color: ${VARIABLES.COLORS.GREEN_5};
    }
  }

  &.is-id-phone,
  &.is-id-phone-global {
    width: 50px;

    .react-select__control {
      cursor: pointer;
      border: none;
    }

    .react-select__value-container {
      padding: 0;
    }

    .react-select__single-value {
      top: calc(50% - 1px); // + 1px
    }

    .react-select__indicators {
      width: 16px;
      background-size: 8px 5px;
      background-position: center 55%;
    }

    .react-select__menu {
      width: 80px;
      left: -20px;
    }
  }

  &.is-id-phone-global {
    width: 60px;

    .react-select__single-value {
      width: 100%;
      text-align: center;
    }
  }

  &.is-filter-search {
    .react-select__control {
      flex-direction: row-reverse;
    }

    .react-select__indicators {
      background: url(${require('./images/ic-search.svg')}) no-repeat center center;
      background-size: 15px 15px;
    }

    .react-select__value-container {
      padding-left: 2px;
    }

    .react-select__input {
      ${TYPOGRAPHYS.FIRST.REGULAR.FS_12};
    }

    .react-select__menu {
      z-index: ${VARIABLES.Z_INDEXS.LV_3};
    }
  }

  &.is-view-type {
    .react-select__indicators {
      width: 40px;
    }
  }
  /* Media queries
  ------------------------------- */
`
