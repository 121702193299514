import React from 'react';
// import {
//   connect,
// } from 'react-redux';
import {
  Section,
  Grid,
  Modal,
  Text,
  Button,
  Image,
  Field
} from '../../../components'
import {
  VARIABLES,
  ICONS,
} from '../../../themes'
// import { MODAL_TYPE } from '../../../helpers/constants/main'
// import { MAIN_REDUX_STATE_KEY } from '../../../actions/mainAction'
import _ from 'lodash';
import { getLanguage } from '../../../helpers/functions/language';
import { isValidNumber } from '../../../helpers/functions/validation';

export class ModalCoilTransferBarcodeScanInformationContainer extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      data: {
        ...props.data,
        isCheckAll: true,
      }
    }
  }

  componentDidMount() {
    this.input.onChangeCheckboxAll({
      target: {
        checked: true,
      }
    })
  }


  modal = {
    isDisableConfirmButton: () => {
      const {
        data,
      } = this.state;
      let isDisable = false;
      if (data.length === 0 || data.length > data.lenBalance) {
        isDisable = true;
      }
      return isDisable;
    },
    onClickConfirm: () => {
      const {
        data,
      } = this.state;
      const {
        onClickConfirm = () => { },
      } = this.props;
      onClickConfirm(data);
    }
  }

  input = {
    onChangeCheckboxAll: (ev) => {
      const {
        data,
      } = this.state;
      const isChecked = ev.target.checked;
      data.isCheckAll = isChecked;
      data.length = isChecked ? data.lenBalance : 0;
      this.setState({
        data,
      })
    },
    onChangeInputData: (ev) => {
      const {
        data,
      } = this.state;
      const name = ev.target.name;
      let value = ev.target.value;
      switch (name) {
        case 'length':
          if (value.length && !isValidNumber(value)) {
            return;
          }
          value = parseInt(value || 0);
          data.isCheckAll = false;
          break;
        default:
          break;
      }
      data[name] = value;
      this.setState({
        data
      })
    },
  }

  render() {
    const {
      isOpenModal,
      onClickClose,
      title,
    } = this.props;
    const {
      data,
    } = this.state;
    return (
      <Modal
        open={isOpenModal}
        scroll
        width={VARIABLES.MODAL.WIDTHS.W_327}
        overlayColor={VARIABLES.COLORS.OVERLAY_BLACK_1}
        transition={VARIABLES.TRANSITIONS.DEFAULT}
        transformStart={VARIABLES.MODAL.CONTAINER.C_1.TRANSFORM_START}
        transformEnd={VARIABLES.MODAL.CONTAINER.C_1.TRANSFORM_END}
        onClickClose={onClickClose}
      >
        <Modal.Section
          paddingVertical='21'
          paddingHorizontal='24'
          bgColor={VARIABLES.COLORS.WHITE}
          borderBottomWidth='1'
          borderBottomStyle='solid'
          borderBottomColor={VARIABLES.COLORS.GRAY_8}
          borderTopRightRadius={VARIABLES.MODAL.HEADER.H_1.BORDER_TOP_RIGHT_RADIUS}
          borderTopLeftRadius={VARIABLES.MODAL.HEADER.H_1.BORDER_TOP_LEFT_RADIUS}
        >
          <Grid gutter='10'>
            <Grid.Column>
              <Text
                regular16
                singleLine
                color={VARIABLES.COLORS.BLACK}
                top='2'
              >
                {title}
              </Text>
            </Grid.Column>
          </Grid>
        </Modal.Section>
        <Modal.Section
          paddingVertical={24}
          paddingHorizontal={24}
          bgColor={VARIABLES.COLORS.WHITE}
        >
          <React.Fragment>
            <Section
              spacingBottom='28'
              justify='center'
              align='center'
              textAlign='center'
            >
              <Section spacingBottom='4'>
                <Text
                  bold14
                  color={VARIABLES.COLORS.GRAY_19}
                >
                  {getLanguage('barcode.modal.modalCoilTranferInformation.label.coilId', '')}
                </Text>
              </Section>
              <Text
                regular14
                color={VARIABLES.COLORS.BLACK}
              >
                {data.coilId}
              </Text>
            </Section>

            <Section
              spacingBottom='28'
              justify='center'
              align='center'
              textAlign='center'
            >
              <Section spacingBottom='4'>
                <Text
                  bold14
                  color={VARIABLES.COLORS.GRAY_19}
                >
                  {getLanguage('barcode.modal.modalCoilTranferInformation.label.coilName', '')}
                </Text>
              </Section>
              <Text
                regular16
                color={VARIABLES.COLORS.BLACK}
              >
                {data.coilName}
              </Text>
            </Section>

            <Section
              justify='center'
              align='center'
              textAlign='center'
              spacingBottom='8'
            >
              <Section spacingBottom='4' textAlign='center' >
                <Text
                  bold14
                  color={VARIABLES.COLORS.GRAY_19}
                >
                  {getLanguage('barcode.modal.modalCoilTranferInformation.label.tranferAmount', '')}
                </Text>
              </Section>
              <Field
                name='length'
                required={false}
                error={data.length > data.lenBalance}
                ui='blockLabelVertical'
                textAlign='center'
                width={137}
                placeholder={'0'}
                value={data.length}
                onChange={this.input.onChangeInputData}
              />
            </Section>
            <Section
              justify='center'
              align='center'
              textAlign='center'
            >
              <Field
                ui='checkboxTick'
                name='coilAll'
                checkboxLabel={getLanguage('barcode.modal.modalCoilTranferInformation.label.allAmount', '')}
                checked={data.isCheckAll}
                onChange={this.input.onChangeCheckboxAll}
              />
            </Section>
          </React.Fragment>
        </Modal.Section>
        <Modal.Section
          paddingVertical='14'
          paddingHorizontal='24'
          borderTopWidth={VARIABLES.MODAL.FOOTER.F_1.BORDER_TOP_WIDTH}
          borderTopStyle={VARIABLES.MODAL.FOOTER.F_1.BORDER_TOP_STYLE}
          borderTopColor={VARIABLES.COLORS.GRAY_8}
          borderBottomRightRadius={VARIABLES.MODAL.FOOTER.F_1.BORDER_BOTTOM_RIGHT_RADIUS}
          borderBottomLeftRadius={VARIABLES.MODAL.FOOTER.F_1.BORDER_BOTTOM_LEFT_RADIUS}
        >
          <Grid
            gutter='15'
            gutterVertical='24'
            justify='flex-end'
          >
            <Grid.Column>
              <Button
                gray
                width={VARIABLES.BUTTON.WIDTHS.W_132}
                name={getLanguage('field.buttonCancel', '')}
                onClick={onClickClose}
              />
            </Grid.Column>
            <Grid.Column>
              <Button
                blue
                disabled={this.modal.isDisableConfirmButton()}
                width={VARIABLES.BUTTON.WIDTHS.W_128}
                name={getLanguage('field.buttonConfirm', '')}
                onClick={this.modal.onClickConfirm}
              />
            </Grid.Column>
          </Grid>
        </Modal.Section>
      </Modal>
    )
  }
}
