import React from "react";
// import {
//   connect,
// } from 'react-redux';
import _ from "lodash";
import {
  Button, Field, Grid, Image, Modal, Section, Table, Text
} from "../../../components";
import { getLanguage } from "../../../helpers/functions/language";
import { ICONS, VARIABLES } from "../../../themes";

export class ModalM3ProductGroupContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filterValue: '',
      sortGroup: {
        number: {
          iconASC: true,
          sortName: 'number',
        },
        code: {
          iconASC: true,
          sortName: 'code',
        },
        name: {
          iconASC: true,
          sortName: 'name',
        },
      },
      currentSort: 'number',
      dataList: _.orderBy(props.dataList.map((e, i) => {
        return {
          code: e.m3ProdGrpCode || '',
          name: e.m3ProdGrpName || '',
        }
      }), ['code'], ['asc']).map((e, i) => {
        e.number = i + 1;
        return e;
      }),
    };
  }

  m3ProductGroupModal = {
    getDataList: () => {
      const {
        filterValue,
        dataList,
      } = this.state;
      return dataList.filter((e, i) => {
        return (
          e.code.toLocaleLowerCase().indexOf(filterValue.toLocaleLowerCase()) >= 0 ||
          e.name.toLocaleLowerCase().indexOf(filterValue.toLocaleLowerCase()) >= 0
        )
      })
    },
    onClickSort: (target) => {
      const {
        sortGroup,
        dataList,
        currentSort,
      } = this.state;

      Object.keys(sortGroup).forEach(keys => {
        const data = sortGroup[keys];
        if (keys === target) {
          data.iconASC = currentSort === target ? !data.iconASC : true;
        }
        else {
          data.iconASC = true;
        }
        sortGroup[keys] = data;
      });

      this.setState({
        currentSort: target,
        sortGroup,
        dataList: _.orderBy(dataList, [target], sortGroup[target].iconASC ? 'asc' : 'desc')
      })
    },
  }

  input = {
    onChangeInput: (ev) => {
      const name = ev.target.name;
      const value = ev.target.value;
      this.setState({
        [name]: value,
      })
    },
  }

  render() {
    const {
      isOpenModal,
      onClickClose,
      primaryTitle,
      secondalyTitle,
    } = this.props;
    const {
      filterValue,
      sortGroup,
    } = this.state;

    return (
      <Modal
        open={isOpenModal}
        scroll
        // enableOverlayClose={false}
        width={VARIABLES.MODAL.WIDTHS.W_560}
        overlayColor={VARIABLES.COLORS.OVERLAY_BLACK_1}
        spacing={VARIABLES.MODAL.CONTAINER.C_1.SPACING}
        transition={VARIABLES.TRANSITIONS.DEFAULT}
        transformStart={VARIABLES.MODAL.CONTAINER.C_1.TRANSFORM_START}
        transformEnd={VARIABLES.MODAL.CONTAINER.C_1.TRANSFORM_END}
        onClickClose={onClickClose}
      >
        <Modal.Section
          paddingVertical="25"
          paddingHorizontal="35"
          bgColor={VARIABLES.COLORS.WHITE}
          borderBottomWidth="1"
          borderBottomStyle="solid"
          borderBottomColor={VARIABLES.COLORS.GRAY_8}
          borderTopRightRadius={
            VARIABLES.MODAL.HEADER.H_1.BORDER_TOP_RIGHT_RADIUS
          }
          borderTopLeftRadius={
            VARIABLES.MODAL.HEADER.H_1.BORDER_TOP_LEFT_RADIUS
          }
        >
          <Text regular16 singleLine color={VARIABLES.COLORS.BLACK}>
            {primaryTitle}
          </Text>
          <Text regular16 singleLine color={VARIABLES.COLORS.BLUE_22}>
            {secondalyTitle}
          </Text>
        </Modal.Section>
        <Modal.Section
          paddingVertical="40"
          bgColor={VARIABLES.COLORS.WHITE}
        >
          <Section spacingBottom="29">
            <Grid justify="center" align="center">
              <Grid.Column>
                <Field
                  border
                  name="filterValue"
                  ui="blockLabelVerticalSearch"
                  width="470"
                  placeholder={getLanguage("documentTemplate.modal.placeholderSearchM3", "")}
                  value={filterValue}
                  onChange={this.input.onChangeInput}
                />
              </Grid.Column>
            </Grid>
          </Section>
          <Grid justify="center" align="center">
            <Grid.Column>
              <Table striped fluid>
                <Table.Section>
                  <Table.Row>
                    <Table.HeadColumn
                      maxWidth={50}
                      minWidth={50}
                      sortAscending={sortGroup.number.iconASC}
                      onClickSort={() => {
                        this.m3ProductGroupModal.onClickSort(sortGroup.number.sortName)
                      }}
                    >
                      {getLanguage("documentTemplate.modal.table.header.no", "")}
                    </Table.HeadColumn>
                    <Table.HeadColumn
                      minWidth={165}
                      sortAscending={sortGroup.code.iconASC}
                      onClickSort={() => {
                        this.m3ProductGroupModal.onClickSort(sortGroup.code.sortName)
                      }}
                    >
                      {getLanguage(
                        "documentTemplate.modal.table.header.m3ProductGroupCode",
                        ""
                      )}
                    </Table.HeadColumn>
                    <Table.HeadColumn
                      minWidth={245}
                      sortAscending={sortGroup.name.iconASC}
                      onClickSort={() => {
                        this.m3ProductGroupModal.onClickSort(sortGroup.name.sortName)
                      }}
                    >
                      {getLanguage("documentTemplate.modal.table.header.m3ProductGroupName", "")}
                    </Table.HeadColumn>
                  </Table.Row>
                </Table.Section>
              </Table>
              {this.m3ProductGroupModal.getDataList() ? (
                <Section scrollOverlay height="179">
                  <Table striped>
                    <Table.Section>
                      {this.m3ProductGroupModal.getDataList().map((e, i) => {
                        return (
                          <Table.Row key={i}>
                            <Table.BodyColumn
                              info
                              minWidth={50}
                            >
                              {e.number}
                            </Table.BodyColumn>
                            <Table.BodyColumn
                              info
                              minWidth={165}
                              title={e.code}
                            >
                              {e.code}
                            </Table.BodyColumn>
                            <Table.BodyColumn
                              info
                              minWidth={245}
                              title={e.name}
                            >
                              {e.name}
                            </Table.BodyColumn>
                          </Table.Row>
                        );
                      })}
                    </Table.Section>
                  </Table>
                </Section>
              ) : (
                <Section scrollOverlay height="179">
                  <Table striped>
                    <Table.Section>
                      <Table.Row>
                        <Table.BodyColumn
                          info
                          alignCenter
                          minWidth={470}
                        >
                          <Section
                            nonFluid
                            spacingBottom="10"
                            spacingTop="35"
                            align="center"
                            justify="center"
                          >
                            <Image
                              src={ICONS["ic-coil-gray.svg"]}
                              widthRatio="30"
                              widthRatioUnit="px"
                              heightRatio="30"
                              heightRatioUnit="px"
                            />
                          </Section>
                          <Section
                            nonFluid
                            spacingBottom="20"
                            align="left"
                            textAlign="left"
                          >
                            <Text
                              regular12
                              color={VARIABLES.COLORS.GRAY_12}
                              noWrap
                            >
                              {getLanguage("documentTemplate.modal.table.label.noM3ProductGroup", "")}
                            </Text>
                          </Section>
                        </Table.BodyColumn>
                      </Table.Row>
                    </Table.Section>
                  </Table>
                </Section>
              )}
            </Grid.Column>
          </Grid>
        </Modal.Section>
        <Modal.Section
          paddingTop="15"
          paddingBottom="20"
          paddingHorizontal="35"
          borderTopWidth={VARIABLES.MODAL.FOOTER.F_1.BORDER_TOP_WIDTH}
          borderTopStyle={VARIABLES.MODAL.FOOTER.F_1.BORDER_TOP_STYLE}
          borderTopColor={VARIABLES.COLORS.GRAY_8}
          borderBottomRightRadius={
            VARIABLES.MODAL.FOOTER.F_1.BORDER_BOTTOM_RIGHT_RADIUS
          }
          borderBottomLeftRadius={
            VARIABLES.MODAL.FOOTER.F_1.BORDER_BOTTOM_LEFT_RADIUS
          }
        >
          <Grid gutter="15" justify="flex-end">
            <Grid.Column>
              <Button
                gray
                width={VARIABLES.BUTTON.WIDTHS.W_155}
                name={getLanguage("field.buttonClose", "")}
                onClick={onClickClose}
              />
            </Grid.Column>
          </Grid>
        </Modal.Section>
      </Modal>
    );
  }
}
