import React from 'react'
import ClassNames from 'classnames'
import PropTypes from 'prop-types'
import {
  DocumentCardWrapper
} from './styled'
import {
  Section,
  Text,
} from './../../../components'
import {
  LOGOS,
  ICONS,
  VARIABLES,
} from './../../../themes'
import { getLanguage } from '../../../helpers/functions/language';

/**
 * DocumentCard description:
 * - DocumentCard
 */

export class DocumentCard extends React.PureComponent {
  render() {
    const {
      className,
      isSelected,
      isIconMoreLines,
      documentCategory,
      documentCode,
      documentName,
      documentLogoSrc,
      checkboxId,
      checkboxName,
      checkboxValue,
      onChangeCheckbox,
      onClickPreview,
      isViewMode
    } = this.props

    // props for css classes
    const classes = ClassNames(
      'document-card',
      { 'is-selected': isSelected },
      className
    )

    return (
      <DocumentCardWrapper
        className={classes}
      >
        <div className='document-card-container'>
          <Section>
            <Section spacingBottom='9' textAlign='center'>
              <img
                className='document-card-image is-logo'
                src={documentLogoSrc}
              />
            </Section>
            <Section spacingBottom='9' textAlign='center'>
              <Text bold18 color={VARIABLES.COLORS.BLACK}>
                {documentCategory}
              </Text>
            </Section>
            {!isIconMoreLines &&
              <React.Fragment>
                <Section spacingBottom='3' textAlign='center'>
                  <Text regular12 color={VARIABLES.COLORS.BLACK}>
                    {documentCode}
                  </Text>
                </Section>
                <Section spacingBottom='10' textAlign='center'>
                  <Text regular12 color={VARIABLES.COLORS.BLACK}>
                    {documentName}
                  </Text>
                </Section>
              </React.Fragment>
            }
            <Section>
              {isIconMoreLines ?
                <img
                  className='document-card-image is-lines'
                  src={ICONS['ic-more-lines.svg']}
                />
                :
                <img
                  className='document-card-image is-lines'
                  src={ICONS['ic-lines.svg']}
                />
              }
            </Section>
          </Section>
          {!isViewMode &&
            <Section justify='flex-end'>
              <div className='document-card-checkbox'>
                <input className='document-card-checkbox-input'
                  type='checkbox'
                  id={checkboxId}
                  name={checkboxName}
                  value={checkboxValue}
                  checked={isSelected}
                  onChange={onChangeCheckbox}
                />
                <label className='document-card-checkbox-content'>
                  <span className='document-card-checkbox-icon'>
                    <img
                      className='document-card-checkbox-icon-checked'
                      src={ICONS['ic-checkbox-white.svg']}
                    />
                  </span>
                </label>
              </div>
            </Section>
          }
        </div>
        {!isViewMode &&
          <Section justify='center'>
            <button
              className='document-card-button is-small is-border-blue is-white'
              onClick={onClickPreview}
            >
              <img
                className='document-card-image is-icon-button is-icon-preview'
                src={ICONS['ic-document-fill-blue.svg']}
              />
              <Text top='-2' medium10 color={VARIABLES.COLORS.PRIMARY_2}>
                {getLanguage('field.buttonPreview', '')}
              </Text>
            </button>
          </Section>
        }
      </DocumentCardWrapper>
    )
  }

  static defaultProps = {
    // * If you set new default props, you should create propsType for correct styleguide document.
  }

  static propTypes = { // TYPE > node, string, number, bool, array, object, symbol, func
    /**
    * Additional classes
    */
    className: PropTypes.string
  }
}
