import { isValidResponse } from "../functions/validation";
import { reportController } from "../../apiService/apiController/reportService";
import { storeGetState } from "../../stores";
import { ModelReport } from "../../models/ModelReport";
import { handleTrimParams } from "../functions/data";

export const getReport = async (params = {}) => {
  let userAuth = storeGetState().mainRedux.userAuth;
  let newParams = handleTrimParams({
    ...params,
    ad_ref_id: userAuth.adRefId
  });
  const reportService = reportController();
  const res = await reportService.getReport(newParams);
  if (isValidResponse(res)) {
    const data = new ModelReport(res);
    console.log('data', res, data, newParams);
    if (params.callbackSuccess) {
      params.callbackSuccess(data);
    }
  }
}

export const getClearReportParams = () => {
  return {
    start_date: '',
    end_date: '',
    ad_ref_id: '',
  }
}