import _ from 'lodash';
import React from 'react';
import { CustomerInfoContainerConnected } from '../../containers/CustomerInfoContainer';
import { OrderProductListContainerConnected } from '../../containers/OrderProductListContainer';
import { SalesOrdersCustomerContainerConnected } from '../../containers/SalesOrdersCustomerContainer';
import { ENUM } from '../../helpers/constants/enum';
import { ROUTE_PATH } from '../../helpers/constants/route';
import { getUserAuthRedux } from '../../helpers/functions/auth';
import { displayWithComma } from '../../helpers/functions/display';
import { getLanguage } from '../../helpers/functions/language';
import { isShowLoading } from '../../helpers/functions/main';
import { redirect, setSideMenuActive } from '../../helpers/functions/route';
import { isValidPhoneNumber } from '../../helpers/functions/validation';
import { getClearCustomerParams, getCustomerList } from '../../helpers/services/customer';
import { getProjectDocumentInformationData } from '../../helpers/services/projectDocument';
import {
  Breadcrumb, Button, Grid, Image, Section, ShapeContainer, Text, TitlePage,
  TitleSection
} from './../../components';
import {
  ModalCustomerProfileContainerConnected
} from './../../containers/ModalContainer';
import {
  ICONS, VARIABLES
} from './../../themes';

export class SalesOrdersScreen extends React.Component {

  constructor(props) {
    super(props);
    setSideMenuActive(props);

    const userAuth = getUserAuthRedux();

    this.state = {
      isOpenModalSelectCustomer: false,
      isOpenModalSyncCustomer: false,
      productList: [],
      isIndoToko: userAuth.addressType === ENUM.COUNTRY_TYPE.INDONESIA && (
        userAuth.customerRoleType === ENUM.CUSTOMER_ROLE_TYPE.INDONESIA.TOKO ||
        userAuth.customerRoleType === ENUM.CUSTOMER_ROLE_TYPE.INDONESIA.DISTRIBUTOR
      ),
      isMalay: userAuth.addressType === ENUM.COUNTRY_TYPE.MALAYSIA,
      isThai: userAuth.addressType === ENUM.COUNTRY_TYPE.THAI,
      isVietnam: userAuth.addressType === ENUM.COUNTRY_TYPE.VIETNAM,
      customerData: undefined,
      isViewCertificate: false,
      previewData: undefined,
    }
  }

  componentDidMount() {
    this.projectDocument.handleCheckProjectDocumentDetail();
  }

  projectDocument = {
    handleCheckProjectDocumentDetail: async () => {
      const params = new URLSearchParams(this.props.location.search);
      const preSalesTransId = parseInt(params.get('preSalesTransId'));
      console.log('preSalesTransId', preSalesTransId)
      if (preSalesTransId) {
        isShowLoading(true);
        getProjectDocumentInformationData(preSalesTransId).then(result => {
          if (result) {
            const preTransData = {
              data: result.data,
              segmentSelected: result.segmentSelected,
            };
            this.setState({
              preTransData,
              customerData: result.customerData
            });
          }
          isShowLoading(false);
        });
      }
    },
    onClickBackProjectDocumentManage: () => {
      const {
        preTransData,
      } = this.state;
      redirect(`${ROUTE_PATH.PROJECT_DOCUMENT_MANAGE.LINK}/${preTransData.data.preSalesTransId}`);
    }
  }

  saleOrder = {
    onClickOpenProductList: () => {
      this.setState({
        isOpenProductList: true,
      })
    },
    onClickCloseProductList: () => {
      this.setState({
        isOpenProductList: false
      })
    },
    onClickSaveProduct: (productList) => {
      let summaryPoint = 0;
      productList.forEach(e => {
        summaryPoint += e.points
      });
      this.setState({
        productList,
        summaryPoint,
        isOpenProductList: false,
      })
    },
    handleClearCustomer: () => {
      this.setState({
        customerData: undefined,
        selectSalePerson: undefined,
      })
    },
    onChangeSelectSalePerson: (selectSalePerson) => {
      this.setState({
        selectSalePerson
      })
    },
    handleSetSelectSalePerson: (dataList) => {
      if (dataList.length) {
        const userAuth = getUserAuthRedux();
        const saleUser = dataList.filter(e => {
          return e.data.sfid === userAuth.sfid
        })[0];
        if (saleUser) {
          this.setState({
            selectSalePerson: saleUser
          });
        }
        else {
          this.setState({
            selectSalePerson: dataList[0]
          });
        }
      }
    },
    onClickSaveOrder: async () => {
      const {
        customerData,
        summaryPoint
      } = this.state;
      isShowLoading(true);
      const params = {
        ...getClearCustomerParams(),
        page: 1,
        id: customerData.id,
        callbackSuccess: (dataList) => {
          const data = dataList[0];
          // customerData.remainPoints += summaryPoint;
          customerData.remainPoints = data.remainPoints;
          customerData.remainPointsWithComma = displayWithComma(customerData.remainPoints);
          customerData.pointsRevenue = '';
          this.setState({
            productList: [],
            customerData: { ...customerData }
          }, () => {
            isShowLoading(false);
          })
        }
      }
      await getCustomerList(params);
    },
    onClickBackViewCertificate: () => {
      this.setState({
        isViewCertificate: false,
      })
    },
    onClickDownloadCertificate: () => {
      const {
        previewData,
      } = this.state;
      window.open(previewData.pdfSrc, '_blank');
    }
  }

  modal = {
    onOpenModalSelectCustomer: () => {
      this.setState({
        isOpenModalSelectCustomer: true
      })
    },
    onClickCloseModalSelectCustomer: () => {
      this.setState({
        isOpenModalSelectCustomer: false
      })
    },
    onClickSubmitCustomer: (data) => {
      this.setState({
        customerData: undefined,
      }, () => {
        this.setState({
          isOpenModalSelectCustomer: false,
          customerData: data,
          productList: [],
        })
      })
    },
  }

  input = {
    onChangeCustomerDataInput: (ev) => {
      const {
        customerData,
      } = this.state;
      if (customerData) {
        const name = ev.target.name;
        const value = ev.target.value;
        switch (name) {
          case 'pointsRevenue':
            if (value.length && !isValidPhoneNumber(value)) {
              return;
            }
          default:
            customerData[name] = value;
            this.setState({
              customerData: _.cloneDeep(customerData),
            })
        }
      }
    },
  }

  render() {
    const {
      isOpenModalSelectCustomer,
      customerData,
      productList,
      isOpenProductList,
      selectSalePerson,
      summaryPoint,
      isIndoToko,
      isMalay,
      isThai,
      isVietnam,
      isViewCertificate,
      previewData,
      preTransData,
    } = this.state;

    return (
      <React.Fragment>
        <Breadcrumb>
          <Breadcrumb.List
            link
            name={getLanguage('salesOrders.breadcrumb1', '')}
            onClick={() => {
              redirect(ROUTE_PATH.HOME.LINK)
            }}
          />
          <Breadcrumb.List name='/' />
          <Breadcrumb.List
            link
            name={getLanguage('salesOrders.breadcrumb2', '')}
          // onClick={() => {
          //   redirect(ROUTE_PATH.SALES_TRANSACTION.LINK)
          // }}
          />
          <Breadcrumb.List name='/' />
          <Breadcrumb.List name={getLanguage('salesOrders.breadcrumb3', '')} />
        </Breadcrumb>
        {isViewCertificate ?
          <Section>
            <ShapeContainer ui="content" justify="space-between">
              <Section spacingBottom="30">
                <Section spacingBottom="45">
                  <TitleSection
                    name={`${getLanguage("certificate.title", "")} ${previewData.preSalesTransProjectName}`}
                  />
                </Section>
                <Section
                  className="is-browser-viewer"
                  height="1132"
                >
                  <iframe
                    title="Browser Viewer"
                    src={`${previewData.pdfSrc}#toolbar=0`}
                    frameBorder="0"
                  />
                </Section>
              </Section>
              <Section>
                <Grid
                  gutter="15"
                  gutterVertical="15"
                  justify="flex-end"
                  align="center"
                >
                  <Grid.Column>
                    <Button
                      gray
                      ui="back"
                      name={getLanguage("field.buttonBack", "")}
                      width={VARIABLES.BUTTON.WIDTHS.W_135}
                      onClick={this.saleOrder.onClickBackViewCertificate}
                    />
                  </Grid.Column>
                  <Grid.Column>
                    <Button
                      green
                      ui="frontIcon"
                      name={getLanguage("field.buttonDownload", "")}
                      width={VARIABLES.BUTTON.WIDTHS.W_155}
                      iconWidth="18"
                      iconHeight="18"
                      iconSrc={ICONS["ic-download-circle.svg"]}
                      onClick={this.saleOrder.onClickDownloadCertificate}
                    />
                  </Grid.Column>
                </Grid>
              </Section>
            </ShapeContainer>
          </Section>
          :
          isOpenProductList ?
            <OrderProductListContainerConnected
              onClickBack={this.saleOrder.onClickCloseProductList}
              onClickSave={this.saleOrder.onClickSaveProduct}
              productList={productList}
              isIndoToko={isIndoToko}
              isMalay={isMalay}
              isThai={isThai}
              isVietnam={isVietnam}
            />
            :
            <Section ui='content'>
              <Section spacingBottom='35'>
                <TitlePage
                  src={ICONS['ic-page-sales-transaction.svg']}
                  name={getLanguage('salesOrders.title', '')}
                />
              </Section>
              <ShapeContainer ui='content'>
                {
                  !!customerData ?
                    <React.Fragment>
                      <CustomerInfoContainerConnected
                        data={customerData}
                        selectSalePerson={selectSalePerson}
                        onChangeSelectSalePerson={this.saleOrder.onChangeSelectSalePerson}
                        onClickSelectCustomer={this.modal.onOpenModalSelectCustomer}
                        handleSetSelectSalePerson={this.saleOrder.handleSetSelectSalePerson}
                        displayNoneButtonSelectSalePerson={preTransData}
                      />
                      <SalesOrdersCustomerContainerConnected
                        data={customerData}
                        selectSalePerson={selectSalePerson}
                        onChangeCustomerDataInput={this.input.onChangeCustomerDataInput}
                        productList={productList}
                        summaryPoint={summaryPoint}
                        onClickOpenProductList={this.saleOrder.onClickOpenProductList}
                        handleClearCustomer={this.saleOrder.handleClearCustomer}
                        onClickSaveOrder={this.saleOrder.onClickSaveOrder}
                        isIndoToko={isIndoToko}
                        isMalay={isMalay}
                        preTransData={preTransData}
                        onClickBackProjectDocumentManage={this.projectDocument.onClickBackProjectDocumentManage}
                      />
                    </React.Fragment>
                    :
                    <React.Fragment>
                      <Section spacingBottom='45'>
                        <TitleSection name={getLanguage('salesOrders.form.customerInfo', '')} />
                        <Section spacingTop='70'>
                          <Section
                            spacingBottom='20'
                            justify='center'
                          >
                            <Image ui='iconBlankCustomer' />
                          </Section>
                          <Section
                            spacingBottom='20'
                            justify='center'
                          >
                            <Text
                              regular14
                              color={VARIABLES.COLORS.BLACK}
                            >
                              {getLanguage('salesOrders.form.selectCustomer', '')}
                            </Text>
                          </Section>
                          <Section>
                            <Grid
                              gutter='20'
                              justify='center'
                            >
                              <Grid.Column>
                                <Button
                                  lightBlue
                                  ui='frontIcon'
                                  name={getLanguage('salesOrders.form.buttonCreate', '')}
                                  iconWidth='14'
                                  iconHeight='14'
                                  iconSrc={ICONS['ic-create.svg']}
                                  width={VARIABLES.BUTTON.WIDTHS.W_200}
                                  onClick={() => {
                                    redirect(`${ROUTE_PATH.CUSTOMERS_MANAGE.LINK}/0`);
                                  }}
                                />
                              </Grid.Column>
                              <Grid.Column>
                                <Button
                                  blue
                                  ui='frontIcon'
                                  name={getLanguage('salesOrders.form.buttonSelectCustomer', '')}
                                  iconWidth='20'
                                  iconHeight='16'
                                  iconSrc={ICONS['ic-select-customer.svg']}
                                  width={VARIABLES.BUTTON.WIDTHS.W_200}
                                  onClick={this.modal.onOpenModalSelectCustomer}
                                />
                              </Grid.Column>
                            </Grid>
                          </Section>
                        </Section>
                      </Section>
                    </React.Fragment>
                }
              </ShapeContainer>
            </Section>
        }
        {
          isOpenModalSelectCustomer &&
          <ModalCustomerProfileContainerConnected
            isOpenModal
            customerData={customerData}
            onClickClose={this.modal.onClickCloseModalSelectCustomer}
            onClickSave={this.modal.onClickSubmitCustomer}
          />
        }
      </React.Fragment>
    )
  }
}
