import React from 'react'
import ClassNames from 'classnames'
import PropTypes from 'prop-types'
import {
  osName,
} from 'react-device-detect'
import {
  WarrantyTableBuildingWrapper
} from './styled'
import {
  Section,
  Text,
  Image,
  Grid,
  Table,
  ShapeContainer,
} from './../../../../components'
import {
  VARIABLES,
} from './../../../../themes'

/**
 * Warranty description:
 * - Warranty
 */

export class WarrantyBSWMThailandTableBuilding extends React.PureComponent {
  render() {
    const {
      children,
      thLanguage,
    } = this.props

    // props for css classes
    const classes = ClassNames(
      'warranty-bswm-table-building',
    )

    return (
      <WarrantyTableBuildingWrapper
        className={classes}
      >
        <Table fluid warranty>
          <Table.Section>
            <Table.Row>
              <Table.Column className='is-head-column' alignCenter maxWidth='50' minWidth='50' paddingVertical='4' paddingHorizontal='4'>
                <p className='warranty-bswm-text is-sm'>{thLanguage ? 'เลขที่' : 'No.'}</p>
              </Table.Column>
              <Table.Column className='is-head-column' alignCenter maxWidth='200' minWidth='200' paddingVertical='4' paddingHorizontal='4'>
                <p className='warranty-bswm-text is-sm'>{thLanguage ? 'การก่อสร้าง' : 'Building'}</p>
              </Table.Column>
              <Table.Column className='is-head-column' alignCenter maxWidth='200' minWidth='200' paddingVertical='4' paddingHorizontal='4'>
                <p className='warranty-bswm-text is-sm'>{thLanguage ? 'วันที่ติดตั้ง (วว/ดด/ปป)' : 'Installation Date (dd/mm/yy)'}</p>
              </Table.Column>
              <Table.Column className='is-head-column' alignCenter maxWidth='240' minWidth='240' paddingVertical='4' paddingHorizontal='4'>
                <p className='warranty-bswm-text is-sm'>{thLanguage ? 'ข้อคิดเห็น' : 'Remark'}</p>
              </Table.Column>
            </Table.Row>
          </Table.Section>
          {children}
        </Table>
      </WarrantyTableBuildingWrapper>
    )
  }

  static defaultProps = {
    // If you set new default props, you should create propsType for correct styleguide document.
  }

  static propTypes = {
    // TYPE > node, string, number, bool, array, object, symbol, func
  }
}
