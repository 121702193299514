import React from 'react';
// import {
//   connect,
// } from 'react-redux';
import {
  FacebookShareButton,
  WhatsappShareButton,
  LineShareButton,
  EmailShareButton,
  FacebookIcon,
  WhatsappIcon,
  LineIcon,
  EmailIcon
} from 'react-share'
import {
  Section,
  Grid,
  Modal,
  Text,
  Button,
  Image
} from './../../../components'
import {
  VARIABLES,
} from './../../../themes'
// import { MODAL_TYPE } from '../../../helpers/constants/main'
// import { MAIN_REDUX_STATE_KEY } from '../../../actions/mainAction'

export class ModalSocialShareContainer extends React.Component {
  render() {
    const {
      isOpenModal,
      onClickClose,
      title,
      url,
      quote,
      second,
      emailBody,
      emailSubject
    } = this.props
    return (
      <Modal
        open={isOpenModal}
        scroll
        second={second}
        // enableOverlayClose={false}
        width={VARIABLES.MODAL.WIDTHS.W_530}
        overlayColor={VARIABLES.COLORS.OVERLAY_BLACK_1}
        spacing={VARIABLES.MODAL.CONTAINER.C_1.SPACING}
        transition={VARIABLES.TRANSITIONS.DEFAULT}
        transformStart={VARIABLES.MODAL.CONTAINER.C_1.TRANSFORM_START}
        transformEnd={VARIABLES.MODAL.CONTAINER.C_1.TRANSFORM_END}
        onClickClose={onClickClose}
      >
        <Modal.Section
          paddingVertical='25'
          paddingHorizontal='35'
          bgColor={VARIABLES.COLORS.WHITE}
          borderBottomWidth='1'
          borderBottomStyle='solid'
          borderBottomColor={VARIABLES.COLORS.GRAY_8}
          borderTopRightRadius={VARIABLES.MODAL.HEADER.H_1.BORDER_TOP_RIGHT_RADIUS}
          borderTopLeftRadius={VARIABLES.MODAL.HEADER.H_1.BORDER_TOP_LEFT_RADIUS}
        >
          <Grid gutter='10'>
            <Grid.Column>
              <Image ui='iconShare' />
            </Grid.Column>
            <Grid.Column>
              <Text
                regular16
                singleLine
                color={VARIABLES.COLORS.BLACK}
                top='1'
              >
                {title}
              </Text>
            </Grid.Column>
          </Grid>
        </Modal.Section>
        <Modal.Section
          paddingVertical='40'
          paddingHorizontal='35'
          bgColor={VARIABLES.COLORS.WHITE}
        >
          <Section
            height='70'
            align='center'
          >
            <Grid
              gutter='30'
              justify='center'
            >
              <Grid.Column>
                <FacebookShareButton
                  url={url}
                  quote={quote}
                >
                  <FacebookIcon
                    size={32}
                    round
                  />
                </FacebookShareButton>
              </Grid.Column>
              <Grid.Column>
                <WhatsappShareButton
                  url={url}
                  quote={quote}
                >
                  <WhatsappIcon
                    size={32}
                    round
                  />
                </WhatsappShareButton>
              </Grid.Column>
              <Grid.Column>
                <LineShareButton
                  url={url}
                  quote={quote}
                >
                  <LineIcon
                    size={32}
                    round
                  />
                </LineShareButton>
              </Grid.Column>
              <Grid.Column>
                <EmailShareButton openWindow
                  subject={emailSubject}
                  body={emailBody}
                  quote={quote}
                  url=''
                >
                  <EmailIcon
                    size={32}
                    round
                  />
                </EmailShareButton>
              </Grid.Column>
            </Grid>
          </Section>
        </Modal.Section>
        <Modal.Section
          paddingTop='15'
          paddingBottom='20'
          paddingHorizontal='35'
          borderTopWidth={VARIABLES.MODAL.FOOTER.F_1.BORDER_TOP_WIDTH}
          borderTopStyle={VARIABLES.MODAL.FOOTER.F_1.BORDER_TOP_STYLE}
          borderTopColor={VARIABLES.COLORS.GRAY_8}
          borderBottomRightRadius={VARIABLES.MODAL.FOOTER.F_1.BORDER_BOTTOM_RIGHT_RADIUS}
          borderBottomLeftRadius={VARIABLES.MODAL.FOOTER.F_1.BORDER_BOTTOM_LEFT_RADIUS}
        >
          <Grid
            gutter='15'
            justify='flex-end'
          >
            <Grid.Column>
              <Button
                gray
                width={VARIABLES.BUTTON.WIDTHS.W_135}
                name='Close'
                onClick={onClickClose}
              />
            </Grid.Column>
          </Grid>
        </Modal.Section>
      </Modal>
    )
  }
}
