import React from 'react';
// import {
//   connect,
// } from 'react-redux';
import {
  Section,
  Grid,
  Modal,
  Text,
  Button,
  Table,
  Image,
  SelectControl,
} from '../../../components'
import {
  VARIABLES,
  ICONS
} from '../../../themes'
import { getLanguage } from '../../../helpers/functions/language';
import { displayUrlPath } from '../../../helpers/functions/display';
import { getSaleTransProjectDocumentLinkList } from '../../../helpers/services/saleTrans';
import { isShowLoading, toast } from '../../../helpers/functions/main';
import { getProjectDocumentGetAllList } from '../../../helpers/services/projectDocument';
// import { MODAL_TYPE } from '../../../helpers/constants/main'
// import { MAIN_REDUX_STATE_KEY } from '../../../actions/mainAction'
import _ from 'lodash';
import { projectDocumentController } from '../../../apiService/apiController/projectDocumentService';
import { getUserAuthRedux } from '../../../helpers/functions/auth';
import { isValidResponse } from '../../../helpers/functions/validation';

export class ModalLinkProjectDocumentContainer extends React.Component {

  constructor(props) {
    super(props);

    const userAuth = getUserAuthRedux();
    const defaultSortIcon = {
      name: true,
      itemDescription__c: true,
      documentaryName: true,
      lotNumber__c: true,
      coilLength__c: true,
    };
    this.state = {
      userAuth,
      dataList: [],
      selectOptionList: [],

      currentSort: 'name',
      defaultSortIcon,
      sortIcon: _.cloneDeep(defaultSortIcon),
    }
  }

  componentDidMount() {
    this.modalLinkProjectDocument.getData();
  }

  modalLinkProjectDocument = {
    getData: async () => {
      isShowLoading(true);
      await this.modalLinkProjectDocument.getSaleTransProjectDocumentLinkList();
      await this.modalLinkProjectDocument.getProjectDocumentList();
      isShowLoading(false);
    },
    getSaleTransProjectDocumentLinkList: async () => {
      const {
        customerData,
      } = this.props;
      const {
        defaultSortIcon,
      } = this.state;
      // get all sale trans without link project document
      const params = {
        customer_id: customerData.id,
      }
      const configService = {
        isShowLoading: false,
      }
      const dataList = await getSaleTransProjectDocumentLinkList(params, configService);
      this.setState({
        dataList,
        currentSort: 'name',
        sortIcon: _.cloneDeep(defaultSortIcon),
      });
    },
    getProjectDocumentList: async () => {
      const {
        customerData,
      } = this.props;
      const {
        userAuth,
      } = this.state;
      // get select option
      const configService = {
        urlParams: {
          customer_id: customerData.id,
          auth_deal_id: userAuth.id,
        },
        isShowLoading: false,
      }
      const selectOptionList = await getProjectDocumentGetAllList(configService);
      this.setState({
        selectOptionList: selectOptionList.map(e => {
          e.value = e.id;
          e.label = e.preSalesTransProjectName;
          return e;
        }),
      });
    },
    onClickSort: (target) => {
      const {
        sortIcon,
        dataList,
        currentSort,
      } = this.state;

      Object.keys(sortIcon).forEach(key => {
        if (key === target) {
          sortIcon[key] = currentSort === target ? !sortIcon[key] : true;
        }
        else {
          sortIcon[key] = true;
        }
      })

      const sortType = sortIcon[target] ? 'desc' : 'asc';
      this.setState({
        sortIcon,
        currentSort: target,
        dataList: _.orderBy(dataList, [target], [sortType])
      })
    },
    onClickSubmit: async () => {
      const {
        dataList,
        userAuth,
      } = this.state;
      isShowLoading(true);
      const params = dataList.filter(e => e.projectDocumentSelected).map(e => {
        return {
          pre_sales_trans_id: e.projectDocumentSelected.id,
          sales_trans_id: e.id,
          created_by: userAuth.name
        }
      });
      const configService = {
        isShowLoading: false,
      }
      const projectDocumentService = projectDocumentController(configService);
      const res = await projectDocumentService.projectDocumentLinkSaleTrans(params);
      if (isValidResponse(res)) {
        toast.success(getLanguage('message.linkProjectSuccess'));
        this.modalLinkProjectDocument.getSaleTransProjectDocumentLinkList();
      }
      else {
        toast.error(res.message)
      }
      isShowLoading(false);
    }
  }

  input = {
    onChangeSelect: (item, index, selected) => {
      const {
        dataList,
      } = this.state
      dataList[index].projectDocumentSelected = selected;
      this.setState({
        dataList,
      })
    }
  }
  render() {
    const {
      isOpenModal,
      isSecondModal,
      onClickClose,
      title,
    } = this.props;
    const {
      dataList,
      selectOptionList,
      sortIcon,
    } = this.state;
    return (
      <Modal
        open={isOpenModal}
        scroll
        second={isSecondModal}
        // enableOverlayClose={false}
        width={VARIABLES.MODAL.WIDTHS.W_1024}
        overlayColor={VARIABLES.COLORS.OVERLAY_BLACK_1}
        spacing={VARIABLES.MODAL.CONTAINER.C_1.SPACING}
        transition={VARIABLES.TRANSITIONS.DEFAULT}
        transformStart={VARIABLES.MODAL.CONTAINER.C_1.TRANSFORM_START}
        transformEnd={VARIABLES.MODAL.CONTAINER.C_1.TRANSFORM_END}
        onClickClose={onClickClose}
      >
        <Modal.Section
          paddingVertical='25.5'
          paddingHorizontal='45'
          bgColor={VARIABLES.COLORS.WHITE}
          borderBottomWidth='1'
          borderBottomStyle='solid'
          borderBottomColor={VARIABLES.COLORS.GRAY_8}
          borderTopRightRadius={VARIABLES.MODAL.HEADER.H_1.BORDER_TOP_RIGHT_RADIUS}
          borderTopLeftRadius={VARIABLES.MODAL.HEADER.H_1.BORDER_TOP_LEFT_RADIUS}
        >
          <Text
            regular16
            singleLine
            color={VARIABLES.COLORS.BLACK}
            top='2'
          >
            {title}
          </Text>
        </Modal.Section>
        <Modal.Section
          paddingTop='45'
          paddingRight='45'
          paddingLeft='45'
          paddingBottom='24'
          bgColor={VARIABLES.COLORS.WHITE}
          scroll
        >
          <Section height='490'>
            <Table striped className={`${dataList.length > 10 ? 'is-link-pre-transaction' : ''}`} >
              <Table.Section>
                <Table.Row>
                  <Table.HeadColumn
                    minWidth={150}
                    maxWidth={150}
                    sortAscending={sortIcon.name}
                    onClickSort={() => {
                      this.modalLinkProjectDocument.onClickSort('name')
                    }}
                  >
                    {getLanguage('modal.saleOrder.table.header.saleTransactionId', '')}
                  </Table.HeadColumn>
                  <Table.HeadColumn
                    minWidth={256}
                    sortAscending={sortIcon.itemDescription__c}
                    onClickSort={() => {
                      this.modalLinkProjectDocument.onClickSort('itemDescription__c')
                    }}
                  >
                    {getLanguage('modal.saleOrder.table.header.coilName', '')}
                  </Table.HeadColumn>
                  <Table.HeadColumn
                    minWidth={100}
                    maxWidth={100}
                    sortAscending={sortIcon.lotNumber__c}
                    onClickSort={() => {
                      this.modalLinkProjectDocument.onClickSort('lotNumber__c')
                    }}
                  >
                    {getLanguage('modal.saleOrder.table.header.coilId', '')}
                  </Table.HeadColumn>
                  <Table.HeadColumn
                    alignRight
                    minWidth={103}
                    maxWidth={103}
                    sortAscending={sortIcon.coilLength__c}
                    onClickSort={() => {
                      this.modalLinkProjectDocument.onClickSort('coilLength__c')
                    }}
                  >
                    {getLanguage('modal.saleOrder.table.header.order', '')}
                  </Table.HeadColumn>
                  <Table.HeadColumn
                    minWidth={240}
                    maxWidth={240}
                    noSort
                    alignRight
                  >
                    {getLanguage('modal.saleOrder.table.header.linkProject', '')}
                  </Table.HeadColumn>
                </Table.Row>
              </Table.Section>
              <Section scrollOverlay height='470'>
                {
                  dataList.length ? (
                    <Table.Section scroll>
                      {dataList.map((e, i) => {
                        return (
                          <Table.Row
                            key={e.id}
                          >
                            <Table.BodyColumn
                              className='is-body-column'
                              info
                              maxWidth={150}
                              minWidth={150}
                              title={e.name}
                            >
                              {e.name}
                            </Table.BodyColumn>
                            <Table.BodyColumn
                              className='is-body-column'
                              info
                              // maxWidth={140}
                              minWidth={256}
                              title={e.itemDescription__c}
                            >
                              {e.itemDescription__c}
                            </Table.BodyColumn>
                            <Table.BodyColumn
                              className='is-body-column'
                              info
                              maxWidth={100}
                              minWidth={100}
                              title={e.lotNumber__c}
                            >
                              {e.lotNumber__c}
                            </Table.BodyColumn>
                            <Table.BodyColumn
                              className='is-body-column'
                              info
                              maxWidth={103}
                              minWidth={103}
                              alignRight
                              title={e.displayCoilLengthWithComma}
                            >
                              {e.displayCoilLengthWithComma}
                            </Table.BodyColumn>
                            <Table.BodyColumn
                              className='is-body-column'
                              action
                              maxWidth={240}
                              minWidth={240}
                            >
                              <Section width="220" >
                                <SelectControl
                                  // filterFluid
                                  className='is-filter-year-warranty'
                                  width='220'
                                  isSearchable={false}
                                  value={e.projectDocumentSelected}
                                  placeholder={getLanguage('modal.saleOrder.label.selectProject', '')}
                                  onChange={(selected) => { this.input.onChangeSelect(e, i, selected) }}
                                  options={selectOptionList}
                                  optionHeight={150}
                                />
                              </Section>
                            </Table.BodyColumn>
                          </Table.Row>
                        )
                      })}
                    </Table.Section>
                  ) : (
                    <Section justify='center' paddingVertical='80'>
                      <Section justify='center' spacingBottom='8' >
                        <Image
                          src={ICONS['ic-lists-gray.svg']}
                          widthRatio="24"
                          widthRatioUnit="px"
                          heightRatio="24"
                          heightRatioUnit="px"
                        />
                      </Section>
                      <Text regular14 color={VARIABLES.COLORS.GRAY_12}>
                        {getLanguage('modal.saleOrder.label.noSaleTransactionIdForLinkToProject', '')}
                      </Text>
                    </Section>
                  )
                }
              </Section>
            </Table>
          </Section>
        </Modal.Section>
        <Modal.Section
          paddingHorizontal='45'
          paddingVertical='30'
          borderTopWidth={VARIABLES.MODAL.FOOTER.F_1.BORDER_TOP_WIDTH}
          borderTopStyle={VARIABLES.MODAL.FOOTER.F_1.BORDER_TOP_STYLE}
          borderTopColor={VARIABLES.COLORS.GRAY_8}
          borderBottomRightRadius={VARIABLES.MODAL.FOOTER.F_1.BORDER_BOTTOM_RIGHT_RADIUS}
          borderBottomLeftRadius={VARIABLES.MODAL.FOOTER.F_1.BORDER_BOTTOM_LEFT_RADIUS}
        >
          <Grid
            gutter='15'
            justify='flex-end'
          >
            <Grid.Column>
              <Button
                gray
                width={VARIABLES.BUTTON.WIDTHS.W_105}
                name={getLanguage('field.buttonClose')}
                onClick={onClickClose}
              />
            </Grid.Column>
            <Grid.Column>
              <Button
                blue
                disabled={dataList.filter(e => e.projectDocumentSelected).length === 0}
                width={VARIABLES.BUTTON.WIDTHS.W_105}
                name={getLanguage('field.buttonSave')}
                onClick={this.modalLinkProjectDocument.onClickSubmit}
              />
            </Grid.Column>
          </Grid>
        </Modal.Section>
      </Modal >
    )
  }
}
