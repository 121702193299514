import ClassNames from 'classnames'
import _ from 'lodash'
import moment from 'moment'
import React from 'react'
import {
  Accordion,
  AccordionItem,
  AccordionItemBody,
  AccordionItemTitle
} from 'react-accessible-accordion'
import { WarrantyEnvDetailContainer } from '../../containers/WarrantyTemplateContainer/WarrantyEnvDetailContainer'
import { DATA, DATE_PICKER_FORMAT } from '../../helpers/constants/data'
import { ENUM } from '../../helpers/constants/enum'
import { LOCAL_STORAGE } from '../../helpers/constants/localStorage'
import { calcFloat } from '../../helpers/functions/data'
import { displayWithComma } from '../../helpers/functions/display'
import { getLanguage } from '../../helpers/functions/language'
import { getLocalStorage } from '../../helpers/functions/localStorage'
import { toast } from '../../helpers/functions/main'
import { isNumber } from '../../helpers/functions/validation'
import {
  AccordionControl,
  ApplicationCard,
  Button,
  DatePickerControl,
  Field,
  Grid,
  Image,
  Section,
  SelectControl,
  ShapeContainer,
  Table,
  Text,
  TitleSection
} from './../../components'
import {
  ICONS,
  TYPOGRAPHYS,
  VARIABLES,
} from './../../themes'

export class WarrantyManageDetail extends React.Component {

  constructor(props) {
    super(props);
    const detailTypeList = ['productInfo', 'applicationDetail', 'environmentDetail']
    const language = getLocalStorage(LOCAL_STORAGE.LANGUAGE_RBPTRUEBLUE);
    const isLanguageTh = language === DATA.LANGUAGE.THAI;
    let productGroupSelectIndex = undefined;
    props.data.productGroupList.forEach((e, i) => {
      if (e.isSelectedProductGroup) {
        productGroupSelectIndex = i;
      }
    });
    this.state = {
      detailTypeList,
      detailType: detailTypeList[1],
      isLanguageTh,
      productGroupSelectIndex,
    }
  }

  componentDidMount() {
    // this.warrantyManageDetail.handleSetErrorSelectApplicationDetail();
  }

  warrantyManageDetail = {
    // isDisableSaveDetail: () => {
    //   const {
    //     data,
    //   } = this.props;
    //   let isDisable = false;
    //   data.productGroupList.map((e, i) => {
    //     if (isDisable === false) {
    //       e.templateList.map((et, it) => {
    //         if (e[et.applicationForSelect] && isDisable === false) {
    //           let envDetail = et.envList[et.detailEnvSelect];
    //           if (envDetail) {
    //             isDisable = envDetail.isWarrantyNoOffer;
    //             if (envDetail.isWarrantyNoOffer) {
    //               console.log('isWarrantyNoOffer', envDetail);
    //             }
    //           }
    //         }
    //       });
    //     }
    //   });
    //   return isDisable;
    // },
    isSelectApplicationDetail: (data) => {
      return data.isRoofSelected ||
        data.isWallSelected ||
        data.isPurlinGirtSelected ||
        data.isTruFrameSelected ||
        data.isCoolRoomSelected ||
        data.isDeckingSelected ||
        data.isAirDuctingSelected
    },
    handleSetErrorSelectApplicationDetail: () => {
      const {
        data,
        onChangeDataInput,
      } = this.props;
      data.productGroupList.forEach((e, i) => {
        onChangeDataInput({
          target: {
            name: 'isErrorSelectApplicationDetail',
            value: !this.warrantyManageDetail.isSelectApplicationDetail(e)
          }
        }, i);
        onChangeDataInput({
          target: {
            name: 'isErrorApplicationList',
            value: e.applicationInfoList.filter(ea => ea.building.length === 0 || ea.customerRemark.length === 0).length > 0
          }
        }, i);
        onChangeDataInput({
          target: {
            name: 'isShowErrorNoQuantity',
            value: (e.isRoofSelected && e.roof.length === 0) ||
              (e.isWallSelected && e.wall.length === 0) ||
              (e.isPurlinGirtSelected && e.purlinGirt.length === 0) ||
              (e.isTruFrameSelected && e.truFrame.length === 0) ||
              (e.isCoolRoomSelected && e.coolRoom.length === 0) ||
              (e.isDeckingSelected && e.decking.length === 0) ||
              (e.isAirDuctingSelected && e.airDucting.length === 0)
          }
        }, i);
      })
    },
    handleSetErrorSelectEnvDetail: () => {
      const {
        data,
        onChangeDataInput,
      } = this.props;
      data.productGroupList.forEach((e, i) => {
        let isSelected = true;
        e.templateList.forEach(et => {
          if (isSelected && e[et.applicationForSelect]) {
            isSelected = et.detailEnvSelect !== undefined;
            onChangeDataInput({
              target: {
                name: 'isErrorSelectEnvDetail',
                value: isSelected === false
              }
            }, i);
          }
        });
      })
    },
    isValidateApplicationDetailPass: () => {
      const {
        data,
      } = this.props;
      let isPass = true;

      this.warrantyManageDetail.handleSetErrorSelectApplicationDetail();

      data.productGroupList.forEach(e => {
        //if isPass = false then return false
        if (isPass) {
          if (e.isErrorQuantity) {
            toast.error(getLanguage('message.warrantyDetailExceedQuantity', ''));
            isPass = false;
          }
          if (e.isErrorSelectApplicationDetail) {
            toast.error(getLanguage('message.warrantyPleaseApplicationDetail', ''));
            isPass = false;
          }
          if (e.isErrorApplicationList) {
            toast.error(getLanguage('message.pleaseInsertAllRequiredField', ''));
            isPass = false;
          }
          if (e.isShowErrorNoQuantity) {
            toast.error(getLanguage('message.pleaseInsertAllRequiredField', ''));
            isPass = false;
          }
        }
      });

      return isPass;
    },
    isValidateEnvironmentDetailPass: () => {
      const {
        data,
        handleCheckAlertWarrantyEnvDetail,
      } = this.props;
      let isPass = true;

      this.warrantyManageDetail.handleSetErrorSelectEnvDetail();

      data.productGroupList.forEach(e => {
        if (isPass) {
          if (e.isErrorSelectEnvDetail) {
            toast.error(getLanguage('message.warrantyPleaseSelectEnvDetail', ''));
            isPass = false;
          }
        }
      });

      if (isPass) {
        isPass = handleCheckAlertWarrantyEnvDetail()
      }

      return isPass
    },
    onClickChangeDetailType: (detailType) => {
      const {
        data,
        isAutomaticEnv,
      } = this.props;
      const {
        detailTypeList,
      } = this.state;
      switch (detailType) {
        case detailTypeList[2]:
          if (this.warrantyManageDetail.isValidateApplicationDetailPass()) {
            if (isAutomaticEnv) {
              // pass step select environment
              this.warrantyManageDetail.onClickSaveDetail();
            }
          }
          else {
            return;
          }
          break;
        default: break;
      }
      this.setState({
        detailType,
      })
    },
    onClickSaveDetail: () => {
      const {
        onClickSave,
        handleUpdateEnvDetail,
        handleUpdateDetailList
      } = this.props;
      handleUpdateDetailList(() => {
        if (this.warrantyManageDetail.isValidateEnvironmentDetailPass()) {
          handleUpdateEnvDetail(() => {
            onClickSave();
          });
        }
      });
    },
    getApplicationMinDate: () => {
      const {
        data
      } = this.props;
      switch (data.projectCountry) {
        case ENUM.COUNTRY_TYPE.INDONESIA:
          return moment().add(-12, 'month')._d;
        case ENUM.COUNTRY_TYPE.THAI:
        case ENUM.COUNTRY_TYPE.MALAYSIA:
        case ENUM.COUNTRY_TYPE.VIETNAM:
        default:
          return moment().add(-24, 'month')._d;
      }
    },
    getApplicationMaxDate: () => {
      const {
        data
      } = this.props;
      switch (data.projectCountry) {
        case ENUM.COUNTRY_TYPE.INDONESIA:
        case ENUM.COUNTRY_TYPE.THAI:
        case ENUM.COUNTRY_TYPE.MALAYSIA:
        case ENUM.COUNTRY_TYPE.VIETNAM:
        default:
          return moment()._d;
      }
    },
    getSegmentName: () => {
      const {
        data,
      } = this.props;
      const {
        isLanguageTh,
      } = this.state;
      console.log('data', data)
      if (isLanguageTh) {
        return `${_.get(data, 'segmentSelected.segmentNameTH', '')} (${_.get(data, 'segmentSelected.subSegmentNameTH', '')})`
      } else {
        return `${_.get(data, 'segmentSelected.segmentNameEN', '')} (${_.get(data, 'segmentSelected.subSegmentNameEN', '')})`
      }
    },
    onClickProductGroup: (index) => {
      const {
        data,
        handleUpdateProductGroupData,
      } = this.props;
      data.productGroupList = data.productGroupList.map((e, i) => {
        if (i === index) {
          e.isSelectedProductGroup = true;
        }
        else {
          e.isSelectedProductGroup = false;
        }
        return e;
      })
      handleUpdateProductGroupData(data.productGroupList);
      this.setState({
        productGroupSelectIndex: index
      })
    },
    getColorProductGroup: () => {
      const {
        data,
      } = this.props;
      const {
        productGroupSelectIndex,
      } = this.state;
      const dataProductGroup = data.productGroupList[productGroupSelectIndex];
      // Default = PRIMARY_2, Error = RED_4, Success = GREEN_3
      if (
        dataProductGroup.isErrorQuantity ||
        dataProductGroup.isShowErrorNoQuantity ||
        dataProductGroup.isErrorSelectApplicationDetail ||
        dataProductGroup.isErrorApplicationList
      ) {
        return VARIABLES.COLORS.RED_4;
      }
      if (dataProductGroup.isSuccessApplicationDetail) {
        return VARIABLES.COLORS.GREEN_3;
      }
      return VARIABLES.COLORS.PRIMARY_2;
    }
  }

  input = {
    onBlurApplication: (ev) => {
      // TODO: fix/warranty-application
      // let value = ev.target.value;
      // if (value === '0' || parseInt(value) === 0) {
      //   value = '';
      // } else if (isNumber(value)) {
      //   value = parseInt(value).toString();
      // }
      // return value;
      let value = ev.target.value;
      if (value === '0' || parseFloat(value) === 0) {
        value = '';
      } else if (isNumber(value)) {
        value = parseFloat(value).toString();
      }
      return value;
    }
  }

  render() {
    const {
      onClickBack,
      onChangeCheckbox,
      onChangeDataInput,
      onChangeAppInfoDataInput,
      onChangeEnvDataInput,
      onClickAddApplicationInfo,
      onClickDeleteApplicationInfo,
      onClickSaveDraft,
      data,
      isShowSegment,
      isAutomaticEnv,
    } = this.props;
    const {
      detailType,
      detailTypeList,
      isLanguageTh,
      productGroupSelectIndex,
    } = this.state
    const dataProductGroup = data.productGroupList[productGroupSelectIndex] || {};
    return (
      <ShapeContainer
        ui='contentStep'
        justify='space-between'
      >
        <Section spacingBottom='45'>
          <Section spacingBottom='45'>
            {(
              detailType === detailTypeList[0] ||
              detailType === detailTypeList[1] ||
              (
                isAutomaticEnv &&
                detailType === detailTypeList[2]
              )
            ) &&
              <TitleSection name={getLanguage('warrantyManage.form.detail.productInformation', '')} />
            }
            {/* {detailType === detailTypeList[1] &&
              <TitleSection name={getLanguage('warrantyManage.form.detail.applicationDetail', '')} />
            } */}
            {
              !isAutomaticEnv &&
              detailType === detailTypeList[2] &&
              <TitleSection name={getLanguage('warrantyManage.form.environment.title', '')} />
            }
          </Section>
          {(
            detailType === detailTypeList[0] ||
            detailType === detailTypeList[1] ||
            (
              isAutomaticEnv &&
              detailType === detailTypeList[2]
            )
          ) &&
            <Section justify='center'>
              <ShapeContainer
                productInformation
                padding='30'
                borderWidth='1'
                borderStyle='solid'
                borderColor={VARIABLES.COLORS.PRIMARY_1}
                borderRadius='5'
              >
                <Table
                  striped
                  fluid
                >
                  <Table.Section>
                    <Table.Row>
                      <Table.HeadColumn
                        noSort
                        action
                        alignCenter
                        maxWidth={VARIABLES.TABLE.INFO_WIDTHS.SELECTED_ITEM}
                        minWidth={VARIABLES.TABLE.INFO_WIDTHS.SELECTED_ITEM}
                      >
                        {getLanguage('warrantyManage.form.detail.table.header.selectedItem', '')}
                      </Table.HeadColumn>
                      <Table.HeadColumn
                        maxWidth={VARIABLES.TABLE.INFO_WIDTHS.DATE_PURCHASING}
                        minWidth={VARIABLES.TABLE.INFO_WIDTHS.DATE_PURCHASING}
                        noSort
                      >
                        {getLanguage('warrantyManage.form.detail.table.header.purchasingDate', '')}
                      </Table.HeadColumn>
                      <Table.HeadColumn
                        maxWidth={VARIABLES.TABLE.INFO_WIDTHS.SALES_TRANSACTION_ID_NO_SORT}
                        minWidth={VARIABLES.TABLE.INFO_WIDTHS.SALES_TRANSACTION_ID_NO_SORT}
                        noSort
                      >
                        {getLanguage('warrantyManage.form.detail.table.header.salesTransactionID', '')}
                      </Table.HeadColumn>
                      <Table.HeadColumn
                        minWidth={VARIABLES.TABLE.INFO_WIDTHS.COIL_NAME_NO_SORT}
                        noSort
                      >
                        {getLanguage('warrantyManage.form.detail.table.header.coilDescription', '')}
                      </Table.HeadColumn>
                      <Table.HeadColumn
                        maxWidth={VARIABLES.TABLE.INFO_WIDTHS.COIL_NUMBER}
                        minWidth={VARIABLES.TABLE.INFO_WIDTHS.COIL_NUMBER}
                        noSort
                      >
                        {getLanguage('warrantyManage.form.detail.table.header.coilNo', '')}
                      </Table.HeadColumn>
                      <Table.HeadColumn
                        info
                        noSort
                        alignRight
                        maxWidth={VARIABLES.TABLE.INFO_WIDTHS.QUANTITY}
                        minWidth={VARIABLES.TABLE.INFO_WIDTHS.QUANTITY}
                      >
                        {getLanguage('warrantyManage.form.detail.table.header.quantity', '')}
                      </Table.HeadColumn>
                    </Table.Row>
                  </Table.Section>
                  <Table.Section>
                    {
                      data.detailList.map((e, i) => {
                        return (
                          <Table.Row
                            key={i}
                          >
                            <Table.BodyColumn
                              action
                              alignCenter
                              maxWidth={VARIABLES.TABLE.INFO_WIDTHS.SELECTED_ITEM}
                              minWidth={VARIABLES.TABLE.INFO_WIDTHS.SELECTED_ITEM}
                            >
                              <Section>
                                <Image ui='iconSelectedItem' />
                              </Section>
                            </Table.BodyColumn>
                            <Table.BodyColumn
                              info
                              maxWidth={VARIABLES.TABLE.INFO_WIDTHS.DATE_PURCHASING}
                              minWidth={VARIABLES.TABLE.INFO_WIDTHS.DATE_PURCHASING}
                            >
                              {e.displayOrderDate}
                            </Table.BodyColumn>
                            <Table.BodyColumn
                              info
                              maxWidth={VARIABLES.TABLE.INFO_WIDTHS.SALES_TRANSACTION_ID_NO_SORT}
                              minWidth={VARIABLES.TABLE.INFO_WIDTHS.SALES_TRANSACTION_ID_NO_SORT}
                              title={e.name}
                            >
                              {e.name}
                            </Table.BodyColumn>
                            <Table.BodyColumn
                              info
                              minWidth={VARIABLES.TABLE.INFO_WIDTHS.COIL_NAME_NO_SORT}
                              title={e.coilName}
                            >
                              {e.coilName}
                            </Table.BodyColumn>
                            <Table.BodyColumn
                              info
                              maxWidth={VARIABLES.TABLE.INFO_WIDTHS.COIL_NUMBER}
                              minWidth={VARIABLES.TABLE.INFO_WIDTHS.COIL_NUMBER}
                              title={e.coilId}
                            >
                              {e.coilId}
                            </Table.BodyColumn>
                            <Table.BodyColumn
                              info
                              alignRight
                              maxWidth={VARIABLES.TABLE.INFO_WIDTHS.QUANTITY}
                              minWidth={VARIABLES.TABLE.INFO_WIDTHS.QUANTITY}
                              title={e.coilOrderMWithComma}
                            >
                              {e.coilOrderMWithComma}
                            </Table.BodyColumn>
                          </Table.Row>
                        )
                      })
                    }
                  </Table.Section>
                </Table>
              </ShapeContainer>
            </Section>
          }
          {
            !isAutomaticEnv &&
            detailType === detailTypeList[2] &&
            <React.Fragment>
              <ShapeContainer fluid paddingVertical='10' paddingHorizontal='15' spacingTop='-20' spacingBottom='20' bgColor={VARIABLES.COLORS.PRIMARY_1} borderRadius='5'>
                <Grid gutter='15' justify='center' align='center'>
                  <Grid.Column><img src={ICONS['ic-notification-info-white.svg']} alt='Icon' /></Grid.Column>
                  <Grid.Column><Text regular14 color={VARIABLES.COLORS.WHITE}>{getLanguage('warrantyManage.form.environment.hint', '')}</Text></Grid.Column>
                </Grid>
              </ShapeContainer>
              <WarrantyEnvDetailContainer
                onChangeEnvDataInput={onChangeEnvDataInput}
                data={data}
              />
            </React.Fragment>
          }
        </Section>
        {(
          detailType === detailTypeList[1] ||
          (
            isAutomaticEnv &&
            detailType === detailTypeList[2]
          )
        ) &&
          <Section spacingBottom='45'>
            <Section spacingBottom='45'>
              <TitleSection name={getLanguage('warrantyManage.form.detail.applicationDetail', '')} />
            </Section>
            <React.Fragment>
              {isShowSegment ?
                <React.Fragment>
                  <Section spacingBottom='30'>
                    <Grid gutter='30' gutterVertical='30'>
                      {data.productGroupList.map((e, i) => {
                        return (
                          <Grid.Column grid='4' key={i}>
                            <ApplicationCard
                              isFail={
                                e.isErrorQuantity ||
                                e.isShowErrorNoQuantity ||
                                e.isErrorSelectApplicationDetail ||
                                e.isErrorApplicationList
                              }
                              isErrorQuantity={e.isErrorQuantity}
                              isSuccess={e.isSuccessApplicationDetail}
                              isNotSelected={!e.isSelectedProductGroup}
                              applicationName={e.productGroupName}
                              applicationNumber={displayWithComma(calcFloat(e.coilOrderMGroupTotal, '-', e.coilOrderMSelectTotal))}
                              applicationTotalNumber={displayWithComma(e.coilOrderMGroupTotal)}
                              segmentName={this.warrantyManageDetail.getSegmentName()}
                              roofNumber={e.roof ? displayWithComma(e.roof) : undefined}
                              wallNumber={e.wall ? displayWithComma(e.wall) : undefined}
                              purlinNumber={e.purlinGirt ? displayWithComma(e.purlinGirt) : undefined}
                              trussNumber={e.truFrame ? displayWithComma(e.truFrame) : undefined}
                              coolRoomNumber={e.coolRoom ? displayWithComma(e.coolRoom) : undefined}
                              deckingNumber={e.decking ? displayWithComma(e.decking) : undefined}
                              airDuctingNumber={e.airDucting ? displayWithComma(e.airDucting) : undefined}
                              onClick={() => this.warrantyManageDetail.onClickProductGroup(i)}
                            />
                          </Grid.Column>
                        )
                      })}
                    </Grid>
                  </Section>
                  {productGroupSelectIndex !== undefined &&
                    <ShapeContainer
                      fluid padding='30'
                      bgColor={VARIABLES.COLORS.BLUE_5}
                      borderStyle='solid' borderWidth='1'
                      borderColor={this.warrantyManageDetail.getColorProductGroup()}
                      borderRadius='5'
                    >
                      <Section spacingBottom='15'>
                        <Grid gutter='5'>
                          <Grid.Column><Text bold14 color={VARIABLES.COLORS.PRIMARY_2}>{getLanguage('warrantyManage.form.detail.segmentInformation', '')}</Text></Grid.Column>
                          <Grid.Column>
                            <Section height='18' align='flex-start'>
                              <ShapeContainer width='6' height='6' bgColor={VARIABLES.COLORS.RED_4} borderRadius='3' />
                            </Section>
                          </Grid.Column>
                        </Grid>
                      </Section>
                      <Section spacingBottom='30'>
                        <ShapeContainer fluid padding='15' bgColor={VARIABLES.COLORS.WHITE} borderStyle='solid' borderWidth='1' borderColor={VARIABLES.COLORS.GRAY_8} borderRadius='5'>
                          <Grid gutter='30' gutterVertical='15'>
                            {dataProductGroup.templateList.map((e, i) => {
                              return (
                                <React.Fragment key={i}>
                                  {e.applicationForSelect === DATA.WARRANTY_APP_DETAIL_NAME_SELECT_LIST[0] &&
                                    <Grid.Column>
                                      <Grid gutter='15' align='center'>
                                        <Grid.Column>
                                          <Section width='180'>
                                            <Field ui='checkboxTickLabelBlack'
                                              name={DATA.WARRANTY_APP_DETAIL_NAME_SELECT_LIST[0]}
                                              checked={dataProductGroup[DATA.WARRANTY_APP_DETAIL_NAME_SELECT_LIST[0]] || false}
                                              checkboxLabel={getLanguage('warrantyManage.form.detail.roof', '')}
                                              onChange={(ev) => {
                                                onChangeCheckbox(ev, productGroupSelectIndex, 'roof');
                                              }}
                                            />
                                          </Section>
                                        </Grid.Column>
                                        <Grid.Column>
                                          <Field ui='blockLabelVertical'
                                            name='roof'
                                            error={
                                              dataProductGroup[DATA.WARRANTY_APP_DETAIL_NAME_SELECT_LIST[0]] &&
                                              (
                                                dataProductGroup.isErrorQuantity ||
                                                (dataProductGroup.isShowErrorNoQuantity && dataProductGroup.roof.length === 0)
                                              )
                                            }
                                            disabled={!dataProductGroup[DATA.WARRANTY_APP_DETAIL_NAME_SELECT_LIST[0]]}
                                            width='200'
                                            // TODO: fix/warranty-application
                                            inputTextType='number'
                                            placeholder={getLanguage('field.applicationDetailPlaceholder', '')}
                                            value={dataProductGroup.roof}
                                            onChange={(ev) => {
                                              onChangeDataInput(ev, productGroupSelectIndex);
                                            }}
                                            onBlur={(ev) => {
                                              ev.target.value = this.input.onBlurApplication(ev);
                                              onChangeDataInput(ev, productGroupSelectIndex);
                                            }}
                                          />
                                        </Grid.Column>
                                      </Grid>
                                    </Grid.Column>
                                  }
                                  {e.applicationForSelect === DATA.WARRANTY_APP_DETAIL_NAME_SELECT_LIST[1] &&
                                    <Grid.Column>
                                      <Grid gutter='15' align='center'>
                                        <Grid.Column>
                                          <Section width='180'>
                                            <Field ui='checkboxTickLabelBlack'
                                              name={DATA.WARRANTY_APP_DETAIL_NAME_SELECT_LIST[1]}
                                              checked={dataProductGroup[DATA.WARRANTY_APP_DETAIL_NAME_SELECT_LIST[1]] || false}
                                              checkboxLabel={getLanguage('warrantyManage.form.detail.wall', '')}
                                              onChange={(ev) => {
                                                onChangeCheckbox(ev, productGroupSelectIndex, 'wall');
                                              }}
                                            />
                                          </Section>
                                        </Grid.Column>
                                        <Grid.Column>
                                          <Field ui='blockLabelVertical'
                                            name='wall'
                                            error={
                                              dataProductGroup[DATA.WARRANTY_APP_DETAIL_NAME_SELECT_LIST[1]] &&
                                              (
                                                dataProductGroup.isErrorQuantity ||
                                                (dataProductGroup.isShowErrorNoQuantity && dataProductGroup.wall.length === 0)
                                              )
                                            }
                                            disabled={!dataProductGroup[DATA.WARRANTY_APP_DETAIL_NAME_SELECT_LIST[1]]}
                                            width='200'
                                            // TODO: fix/warranty-application
                                            inputTextType='number'
                                            placeholder={getLanguage('field.applicationDetailPlaceholder', '')}
                                            value={dataProductGroup.wall}
                                            onChange={(ev) => {
                                              onChangeDataInput(ev, productGroupSelectIndex);
                                            }}
                                            onBlur={(ev) => {
                                              ev.target.value = this.input.onBlurApplication(ev);
                                              onChangeDataInput(ev, productGroupSelectIndex);
                                            }}
                                          />
                                        </Grid.Column>
                                      </Grid>
                                    </Grid.Column>
                                  }
                                  {e.applicationForSelect === DATA.WARRANTY_APP_DETAIL_NAME_SELECT_LIST[2] &&
                                    <Grid.Column>
                                      <Grid gutter='15' align='center'>
                                        <Grid.Column>
                                          <Section width='180'>
                                            <Field ui='checkboxTickLabelBlack'
                                              name={DATA.WARRANTY_APP_DETAIL_NAME_SELECT_LIST[2]}
                                              checked={dataProductGroup[DATA.WARRANTY_APP_DETAIL_NAME_SELECT_LIST[2]] || false}
                                              checkboxLabel={getLanguage('warrantyManage.form.detail.purlin', '')}
                                              onChange={(ev) => {
                                                onChangeCheckbox(ev, productGroupSelectIndex, 'purlinGirt');
                                              }}
                                            />
                                          </Section>
                                        </Grid.Column>
                                        <Grid.Column>
                                          <Field ui='blockLabelVertical'
                                            name='purlinGirt'
                                            error={
                                              dataProductGroup[DATA.WARRANTY_APP_DETAIL_NAME_SELECT_LIST[2]] &&
                                              (
                                                dataProductGroup.isErrorQuantity ||
                                                (dataProductGroup.isShowErrorNoQuantity && dataProductGroup.purlinGirt.length === 0)
                                              )
                                            }
                                            disabled={!dataProductGroup[DATA.WARRANTY_APP_DETAIL_NAME_SELECT_LIST[2]]}
                                            width='200'
                                            // TODO: fix/warranty-application
                                            inputTextType='number'
                                            placeholder={getLanguage('field.applicationDetailPlaceholder', '')}
                                            value={dataProductGroup.purlinGirt}
                                            onChange={(ev) => {
                                              onChangeDataInput(ev, productGroupSelectIndex);
                                            }}
                                            onBlur={(ev) => {
                                              ev.target.value = this.input.onBlurApplication(ev);
                                              onChangeDataInput(ev, productGroupSelectIndex);
                                            }}
                                          />
                                        </Grid.Column>

                                      </Grid>
                                    </Grid.Column>
                                  }
                                  {e.applicationForSelect === DATA.WARRANTY_APP_DETAIL_NAME_SELECT_LIST[3] &&
                                    <Grid.Column>
                                      <Grid gutter='15' align='center'>
                                        <Grid.Column>
                                          <Section width='180'>
                                            <Field ui='checkboxTickLabelBlack'
                                              name={DATA.WARRANTY_APP_DETAIL_NAME_SELECT_LIST[3]}
                                              checked={dataProductGroup[DATA.WARRANTY_APP_DETAIL_NAME_SELECT_LIST[3]] || false}
                                              checkboxLabel={getLanguage('warrantyManage.form.detail.truss', '')}
                                              onChange={(ev) => {
                                                onChangeCheckbox(ev, productGroupSelectIndex, 'truFrame');
                                              }}
                                            />
                                          </Section>
                                        </Grid.Column>
                                        <Grid.Column>
                                          <Field ui='blockLabelVertical'
                                            name='truFrame'
                                            error={
                                              dataProductGroup[DATA.WARRANTY_APP_DETAIL_NAME_SELECT_LIST[3]] &&
                                              (
                                                dataProductGroup.isErrorQuantity ||
                                                (dataProductGroup.isShowErrorNoQuantity && dataProductGroup.truFrame.length === 0)
                                              )
                                            }
                                            disabled={!dataProductGroup[DATA.WARRANTY_APP_DETAIL_NAME_SELECT_LIST[3]]}
                                            width='200'
                                            // TODO: fix/warranty-application
                                            inputTextType='number'
                                            placeholder={getLanguage('field.applicationDetailPlaceholder', '')}
                                            value={dataProductGroup.truFrame}
                                            onChange={(ev) => {
                                              onChangeDataInput(ev, productGroupSelectIndex);
                                            }}
                                            onBlur={(ev) => {
                                              ev.target.value = this.input.onBlurApplication(ev);
                                              onChangeDataInput(ev, productGroupSelectIndex);
                                            }}
                                          />
                                        </Grid.Column>
                                      </Grid>
                                    </Grid.Column>
                                  }
                                  {e.applicationForSelect === DATA.WARRANTY_APP_DETAIL_NAME_SELECT_LIST[4] &&
                                    <Grid.Column>
                                      <Grid gutter='15' align='center'>
                                        <Grid.Column>
                                          <Section width='180'>
                                            <Field ui='checkboxTickLabelBlack'
                                              name={DATA.WARRANTY_APP_DETAIL_NAME_SELECT_LIST[4]}
                                              checked={dataProductGroup[DATA.WARRANTY_APP_DETAIL_NAME_SELECT_LIST[4]] || false}
                                              checkboxLabel={getLanguage('warrantyManage.form.detail.coolRoom', '')}
                                              onChange={(ev) => {
                                                onChangeCheckbox(ev, productGroupSelectIndex, 'coolRoom');
                                              }}
                                            />
                                          </Section>
                                        </Grid.Column>
                                        <Grid.Column>
                                          <Field ui='blockLabelVertical'
                                            name='coolRoom'
                                            error={
                                              dataProductGroup[DATA.WARRANTY_APP_DETAIL_NAME_SELECT_LIST[4]] &&
                                              (
                                                dataProductGroup.isErrorQuantity ||
                                                (dataProductGroup.isShowErrorNoQuantity && dataProductGroup.coolRoom.length === 0)
                                              )
                                            }
                                            disabled={!dataProductGroup[DATA.WARRANTY_APP_DETAIL_NAME_SELECT_LIST[4]]}
                                            width='200'
                                            // TODO: fix/warranty-application
                                            inputTextType='number'
                                            placeholder={getLanguage('field.applicationDetailPlaceholder', '')}
                                            value={dataProductGroup.coolRoom}
                                            onChange={(ev) => {
                                              onChangeDataInput(ev, productGroupSelectIndex);
                                            }}
                                            onBlur={(ev) => {
                                              ev.target.value = this.input.onBlurApplication(ev);
                                              onChangeDataInput(ev, productGroupSelectIndex);
                                            }}
                                          />
                                        </Grid.Column>
                                      </Grid>
                                    </Grid.Column>
                                  }
                                  {e.applicationForSelect === DATA.WARRANTY_APP_DETAIL_NAME_SELECT_LIST[5] &&
                                    <Grid.Column>
                                      <Grid gutter='15' align='center'>
                                        <Grid.Column>
                                          <Section width='180'>
                                            <Field ui='checkboxTickLabelBlack'
                                              name={DATA.WARRANTY_APP_DETAIL_NAME_SELECT_LIST[5]}
                                              checked={dataProductGroup[DATA.WARRANTY_APP_DETAIL_NAME_SELECT_LIST[5]] || false}
                                              checkboxLabel={getLanguage('warrantyManage.form.detail.decking', '')}
                                              onChange={(ev) => {
                                                onChangeCheckbox(ev, productGroupSelectIndex, 'decking');
                                              }}
                                            />
                                          </Section>
                                        </Grid.Column>
                                        <Grid.Column>
                                          <Field ui='blockLabelVertical'
                                            name='decking'
                                            error={
                                              dataProductGroup[DATA.WARRANTY_APP_DETAIL_NAME_SELECT_LIST[5]] &&
                                              (
                                                dataProductGroup.isErrorQuantity ||
                                                (dataProductGroup.isShowErrorNoQuantity && dataProductGroup.decking.length === 0)
                                              )
                                            }
                                            disabled={!dataProductGroup[DATA.WARRANTY_APP_DETAIL_NAME_SELECT_LIST[5]]}
                                            width='200'
                                            // TODO: fix/warranty-application
                                            inputTextType='number'
                                            placeholder={getLanguage('field.applicationDetailPlaceholder', '')}
                                            value={dataProductGroup.decking}
                                            onChange={(ev) => {
                                              onChangeDataInput(ev, productGroupSelectIndex);
                                            }}
                                            onBlur={(ev) => {
                                              ev.target.value = this.input.onBlurApplication(ev);
                                              onChangeDataInput(ev, productGroupSelectIndex);
                                            }}
                                          />
                                        </Grid.Column>
                                      </Grid>
                                    </Grid.Column>
                                  }
                                  {e.applicationForSelect === DATA.WARRANTY_APP_DETAIL_NAME_SELECT_LIST[6] &&
                                    <Grid.Column>
                                      <Grid gutter='15' align='center'>
                                        <Grid.Column>
                                          <Section width='180'>
                                            <Field ui='checkboxTickLabelBlack'
                                              name={DATA.WARRANTY_APP_DETAIL_NAME_SELECT_LIST[6]}
                                              checked={dataProductGroup[DATA.WARRANTY_APP_DETAIL_NAME_SELECT_LIST[6]] || false}
                                              checkboxLabel={getLanguage('warrantyManage.form.detail.airDucting', '')}
                                              onChange={(ev) => {
                                                onChangeCheckbox(ev, productGroupSelectIndex, 'airDucting');
                                              }}
                                            />
                                          </Section>
                                        </Grid.Column>
                                        <Grid.Column>
                                          <Field ui='blockLabelVertical'
                                            name='airDucting'
                                            error={
                                              dataProductGroup[DATA.WARRANTY_APP_DETAIL_NAME_SELECT_LIST[6]] &&
                                              (
                                                dataProductGroup.isErrorQuantity ||
                                                (dataProductGroup.isShowErrorNoQuantity && dataProductGroup.airDucting.length === 0)
                                              )
                                            }
                                            disabled={!dataProductGroup[DATA.WARRANTY_APP_DETAIL_NAME_SELECT_LIST[6]]}
                                            width='200'
                                            // TODO: fix/warranty-application
                                            inputTextType='number'
                                            placeholder={getLanguage('field.applicationDetailPlaceholder', '')}
                                            value={dataProductGroup.airDucting}
                                            onChange={(ev) => {
                                              onChangeDataInput(ev, productGroupSelectIndex);
                                            }}
                                            onBlur={(ev) => {
                                              ev.target.value = this.input.onBlurApplication(ev);
                                              onChangeDataInput(ev, productGroupSelectIndex);
                                            }}
                                          />
                                        </Grid.Column>
                                      </Grid>
                                    </Grid.Column>
                                  }
                                </React.Fragment>
                              )
                            })}
                          </Grid>
                        </ShapeContainer>
                      </Section>
                      <Section>
                        <Text bold14 color={VARIABLES.COLORS.PRIMARY_2}>{getLanguage('warrantyManage.form.detail.buildingInformation', '')}</Text>
                      </Section>
                      {dataProductGroup.applicationInfoList.map((e, i) => {
                        return (
                          <Section spacingTop='15' key={i}>
                            <ShapeContainer fluid padding='15' bgColor={VARIABLES.COLORS.WHITE} borderStyle='solid' borderWidth='1' borderColor={VARIABLES.COLORS.GRAY_8} borderRadius='5'>
                              <Grid gutter='30' gutterVertical='15'>
                                <Grid.Column flex='1'>
                                  <Section spacingBottom='15'>
                                    <Grid gutter='30'>
                                      <Grid.Column>
                                        <Grid gutter='15' align='center'>
                                          <Grid.Column>
                                            <Text regular14 color={VARIABLES.COLORS.BLACK}>{getLanguage('field.building', '')}</Text>
                                          </Grid.Column>
                                          <Grid.Column>
                                            {_.get(data, 'segmentSelected.isSegmentBuildingSelected', false) ?
                                              <SelectControl
                                                name='buildingSelected'
                                                required
                                                isSearchable={false}
                                                error={dataProductGroup.isErrorApplicationList}
                                                width='250'
                                                placeholder={getLanguage('field.buildingSelectPlaceholder', '')}
                                                value={e.building ? { label: e.building, value: e.building } : null}
                                                options={data.segmentSelected.segmentBuildingList}
                                                onChange={(selected) => {
                                                  onChangeAppInfoDataInput({
                                                    target: {
                                                      name: 'building',
                                                      value: selected.value,
                                                    }
                                                  }, productGroupSelectIndex, i);
                                                }}
                                              />
                                              :
                                              <Field ui='blockLabelVertical'
                                                name='building'
                                                required
                                                error={dataProductGroup.isErrorApplicationList}
                                                width='250'
                                                placeholder={getLanguage('field.buildingPlaceholder', '')}
                                                value={e.building}
                                                onChange={(ev) => {
                                                  onChangeAppInfoDataInput(ev, productGroupSelectIndex, i);
                                                }}
                                              />
                                            }
                                          </Grid.Column>
                                        </Grid>
                                      </Grid.Column>
                                      <Grid.Column>
                                        <Grid gutter='15' align='center'>
                                          <Grid.Column>
                                            <Text regular14 color={VARIABLES.COLORS.BLACK}>{getLanguage('field.installationDate', '')}</Text>
                                          </Grid.Column>
                                          <Grid.Column>
                                            <DatePickerControl
                                              top
                                              placeholderText={getLanguage('field.installationDatePlaceholder', '')}
                                              dateFormat={DATE_PICKER_FORMAT}
                                              selected={e.projectInsDateTime}
                                              minDate={this.warrantyManageDetail.getApplicationMinDate()}
                                              maxDate={this.warrantyManageDetail.getApplicationMaxDate()}
                                              onChange={(selected) => {
                                                onChangeAppInfoDataInput({
                                                  target: {
                                                    value: selected,
                                                    name: 'projectInsDateTime'
                                                  }
                                                }, productGroupSelectIndex, i)
                                              }}
                                            />
                                          </Grid.Column>
                                        </Grid>
                                      </Grid.Column>
                                    </Grid>
                                  </Section>
                                  <Section>
                                    <Section spacingBottom='5'>
                                      <Text regular14 color={VARIABLES.COLORS.BLACK}>{getLanguage('field.customerRemark', '')}</Text>
                                      &nbsp;
                                      <Text regular14 color={VARIABLES.COLORS.BLACK}>{getLanguage('field.customerRemarkExample', '')}</Text>
                                    </Section>
                                    <Field ui='blockLabelVertical'
                                      name='customerRemark'
                                      required
                                      error={dataProductGroup.isErrorApplicationList}
                                      width='676'
                                      placeholder={getLanguage('field.customerRemarkPlaceholder', '')}
                                      message={getLanguage('field.customerRemarkHint', '')}
                                      messageFontStyle={TYPOGRAPHYS.FIRST.REGULAR.FS_10}
                                      messageFontColor={VARIABLES.COLORS.GRAY_19}
                                      messageSpacingTop='5'
                                      maxLength={500}
                                      value={e.customerRemark}
                                      onChange={(ev) => {
                                        onChangeAppInfoDataInput(ev, productGroupSelectIndex, i);
                                      }}
                                    />
                                  </Section>
                                </Grid.Column>
                                <Grid.Column flex='none'>
                                  <Section width='42'>
                                    <Grid gutter='10' justify='flex-end'>
                                      {i === (dataProductGroup.applicationInfoList.length - 1) ?
                                        <Grid.Column>
                                          <img className='is-pointer' onClick={() => { onClickAddApplicationInfo(productGroupSelectIndex) }} src={ICONS['ic-plus-circle-slim-blue.svg']} alt='Icon' />
                                        </Grid.Column>
                                        :
                                        <Grid.Column>
                                          <img className='is-pointer' onClick={() => { onClickDeleteApplicationInfo(productGroupSelectIndex, i) }} src={ICONS['ic-minus-circle-slim-red.svg']} alt='Icon' />
                                        </Grid.Column>
                                      }
                                    </Grid>
                                  </Section>
                                </Grid.Column>
                              </Grid>
                            </ShapeContainer>
                          </Section>
                        )
                      })}
                    </ShapeContainer>
                  }
                </React.Fragment>
                :
                <AccordionControl outline>
                  <Accordion accordion>
                    {data.productGroupList.map((e, i) => {
                      return (
                        <AccordionItem
                          // expanded
                          key={i}
                          uuid={i}
                          className={ClassNames(
                            'accordion__item',
                            {
                              'is-error':
                                e.isErrorQuantity ||
                                e.isShowErrorNoQuantity ||
                                e.isErrorSelectApplicationDetail ||
                                e.isErrorApplicationList
                            },
                          )}
                        >
                          <AccordionItemTitle>
                            <AccordionControl.ButtonOutline
                              name={e.productGroupName}
                            />
                          </AccordionItemTitle>
                          <AccordionItemBody>
                            <ShapeContainer
                              fluid
                              padding='30'
                              bgColor={VARIABLES.COLORS.GRAY_1}
                              borderRadius='5'
                            >
                              <Section spacingBottom='15'>
                                <Grid gutter='10'>
                                  <Grid.Column><Text bold12 color={VARIABLES.COLORS.BLACK}>Quantity remaining</Text></Grid.Column>
                                  <Grid.Column>
                                    <div className={ClassNames(
                                      'accordion__badge',
                                      {
                                        'is-error': e.isErrorQuantity
                                      },
                                    )}>{displayWithComma(calcFloat(e.coilOrderMGroupTotal, '-', e.coilOrderMSelectTotal))}</div>
                                    <Text regular12 color={VARIABLES.COLORS.BLACK}>&nbsp;/ {displayWithComma(e.coilOrderMGroupTotal)}</Text>
                                  </Grid.Column>
                                  {e.isErrorQuantity &&
                                    <Grid.Column><Text regular12 color={VARIABLES.COLORS.ERROR}>(Exceed quantity, please change quantity or remove application.)</Text></Grid.Column>
                                  }
                                </Grid>
                              </Section>
                              <Grid
                                gutter='100'
                                gutterVertical='15'
                              >
                                {e.templateList.map((env, inv) => {
                                  return (
                                    <React.Fragment key={inv}>
                                      {env.applicationForSelect === DATA.WARRANTY_APP_DETAIL_NAME_SELECT_LIST[0] &&
                                        <Grid.Column>
                                          <Grid>
                                            <Grid.Column>
                                              <Section
                                                width='230'
                                                paddingTop='3'
                                              >
                                                <Field
                                                  name={DATA.WARRANTY_APP_DETAIL_NAME_SELECT_LIST[0]}
                                                  checked={e[DATA.WARRANTY_APP_DETAIL_NAME_SELECT_LIST[0]] || false}
                                                  ui='checkboxTickFillSmall'
                                                  // checkboxLabel={getLanguage('warrantyManage.form.detail.roofAndWall', '')}
                                                  checkboxLabel={getLanguage('warrantyManage.form.detail.roof', '')}
                                                  onChange={(ev) => {
                                                    onChangeCheckbox(ev, i, 'roof');
                                                  }}
                                                />
                                              </Section>
                                            </Grid.Column>
                                            <Grid.Column>
                                              <Field name='roof'
                                                disabled={!e[DATA.WARRANTY_APP_DETAIL_NAME_SELECT_LIST[0]]}
                                                ui='blockLabelVerticalSmall'
                                                placeholder={getLanguage('field.inputNumberPlaceholder', '')}
                                                width='150'
                                                textAlign='center'
                                                value={e.roof}
                                                onChange={(ev) => {
                                                  onChangeDataInput(ev, i);
                                                }}
                                                onBlur={(ev) => {
                                                  ev.target.value = this.input.onBlurApplication(ev);
                                                  onChangeDataInput(ev, i)
                                                }}
                                              />
                                            </Grid.Column>
                                          </Grid>
                                        </Grid.Column>
                                      }
                                      {env.applicationForSelect === DATA.WARRANTY_APP_DETAIL_NAME_SELECT_LIST[1] &&
                                        <Grid.Column>
                                          <Grid>
                                            <Grid.Column>
                                              <Section
                                                width='230'
                                                paddingTop='3'
                                              >
                                                <Field name={DATA.WARRANTY_APP_DETAIL_NAME_SELECT_LIST[1]}
                                                  checked={e[DATA.WARRANTY_APP_DETAIL_NAME_SELECT_LIST[1]] || false}
                                                  ui='checkboxTickFillSmall'
                                                  checkboxLabel={getLanguage('warrantyManage.form.detail.wall', '')}
                                                  onChange={(ev) => {
                                                    onChangeCheckbox(ev, i, 'wall');
                                                  }}
                                                />
                                              </Section>
                                            </Grid.Column>
                                            <Grid.Column>
                                              <Field name='wall'
                                                disabled={!e[DATA.WARRANTY_APP_DETAIL_NAME_SELECT_LIST[1]]}
                                                ui='blockLabelVerticalSmall'
                                                placeholder={getLanguage('field.inputNumberPlaceholder', '')}
                                                width='150'
                                                textAlign='center'
                                                value={e.wall}
                                                onChange={(ev) => {
                                                  onChangeDataInput(ev, i);
                                                }}
                                                onBlur={(ev) => {
                                                  ev.target.value = this.input.onBlurApplication(ev);
                                                  onChangeDataInput(ev, i)
                                                }}
                                              />
                                            </Grid.Column>
                                          </Grid>
                                        </Grid.Column>
                                      }
                                      {env.applicationForSelect === DATA.WARRANTY_APP_DETAIL_NAME_SELECT_LIST[2] &&
                                        <Grid.Column>
                                          <Grid>
                                            <Grid.Column>
                                              <Section
                                                width='230'
                                                paddingTop='3'
                                              >
                                                <Field name={DATA.WARRANTY_APP_DETAIL_NAME_SELECT_LIST[2]}
                                                  checked={e[DATA.WARRANTY_APP_DETAIL_NAME_SELECT_LIST[2]] || false}
                                                  ui='checkboxTickFillSmall'
                                                  checkboxLabel={getLanguage('warrantyManage.form.detail.purlin', '')}
                                                  onChange={(ev) => {
                                                    onChangeCheckbox(ev, i, 'purlinGirt');
                                                  }}
                                                />
                                              </Section>
                                            </Grid.Column>
                                            <Grid.Column>
                                              <Field name='purlinGirt'
                                                disabled={!e[DATA.WARRANTY_APP_DETAIL_NAME_SELECT_LIST[2]]}
                                                ui='blockLabelVerticalSmall'
                                                placeholder={getLanguage('field.inputNumberPlaceholder', '')}
                                                width='150'
                                                textAlign='center'
                                                value={e.purlinGirt}
                                                onChange={(ev) => {
                                                  onChangeDataInput(ev, i);
                                                }}
                                                onBlur={(ev) => {
                                                  ev.target.value = this.input.onBlurApplication(ev);
                                                  onChangeDataInput(ev, i)
                                                }}
                                              />
                                            </Grid.Column>
                                          </Grid>
                                        </Grid.Column>
                                      }
                                      {env.applicationForSelect === DATA.WARRANTY_APP_DETAIL_NAME_SELECT_LIST[3] &&
                                        <Grid.Column>
                                          <Grid>
                                            <Grid.Column>
                                              <Section
                                                width='230'
                                                paddingTop='3'
                                              >
                                                <Field name={DATA.WARRANTY_APP_DETAIL_NAME_SELECT_LIST[3]}
                                                  checked={e[DATA.WARRANTY_APP_DETAIL_NAME_SELECT_LIST[3]] || false}
                                                  ui='checkboxTickFillSmall'
                                                  checkboxLabel={getLanguage('warrantyManage.form.detail.truss', '')}
                                                  onChange={(ev) => {
                                                    onChangeCheckbox(ev, i, 'truFrame');
                                                  }}
                                                />
                                              </Section>
                                            </Grid.Column>
                                            <Grid.Column>
                                              <Field name='truFrame'
                                                disabled={!e[DATA.WARRANTY_APP_DETAIL_NAME_SELECT_LIST[3]]}
                                                ui='blockLabelVerticalSmall'
                                                placeholder={getLanguage('field.inputNumberPlaceholder', '')}
                                                width='150'
                                                textAlign='center'
                                                value={e.truFrame}
                                                onChange={(ev) => {
                                                  onChangeDataInput(ev, i);
                                                }}
                                                onBlur={(ev) => {
                                                  ev.target.value = this.input.onBlurApplication(ev);
                                                  onChangeDataInput(ev, i)
                                                }}
                                              />
                                            </Grid.Column>
                                          </Grid>
                                        </Grid.Column>
                                      }
                                      {env.applicationForSelect === DATA.WARRANTY_APP_DETAIL_NAME_SELECT_LIST[4] &&
                                        <Grid.Column>
                                          <Grid>
                                            <Grid.Column>
                                              <Section
                                                width='230'
                                                paddingTop='3'
                                              >
                                                <Field name={DATA.WARRANTY_APP_DETAIL_NAME_SELECT_LIST[4]}
                                                  checked={e[DATA.WARRANTY_APP_DETAIL_NAME_SELECT_LIST[4]] || false}
                                                  ui='checkboxTickFillSmall'
                                                  checkboxLabel={getLanguage('warrantyManage.form.detail.coolRoom', '')}
                                                  onChange={(ev) => {
                                                    onChangeCheckbox(ev, i, 'coolRoom');
                                                  }}
                                                />
                                              </Section>
                                            </Grid.Column>
                                            <Grid.Column>
                                              <Field name='coolRoom'
                                                disabled={!e[DATA.WARRANTY_APP_DETAIL_NAME_SELECT_LIST[4]]}
                                                ui='blockLabelVerticalSmall'
                                                placeholder={getLanguage('field.inputNumberPlaceholder', '')}
                                                width='150'
                                                textAlign='center'
                                                value={e.coolRoom}
                                                onChange={(ev) => {
                                                  onChangeDataInput(ev, i);
                                                }}
                                                onBlur={(ev) => {
                                                  ev.target.value = this.input.onBlurApplication(ev);
                                                  onChangeDataInput(ev, i)
                                                }}
                                              />
                                            </Grid.Column>
                                          </Grid>
                                        </Grid.Column>
                                      }
                                      {env.applicationForSelect === DATA.WARRANTY_APP_DETAIL_NAME_SELECT_LIST[5] &&
                                        <Grid.Column>
                                          <Grid>
                                            <Grid.Column>
                                              <Section
                                                width='230'
                                                paddingTop='3'
                                              >
                                                <Field name={DATA.WARRANTY_APP_DETAIL_NAME_SELECT_LIST[5]}
                                                  checked={e[DATA.WARRANTY_APP_DETAIL_NAME_SELECT_LIST[5]] || false}
                                                  ui='checkboxTickFillSmall'
                                                  checkboxLabel={getLanguage('warrantyManage.form.detail.decking', '')}
                                                  onChange={(ev) => {
                                                    onChangeCheckbox(ev, i, 'decking');
                                                  }}
                                                />
                                              </Section>
                                            </Grid.Column>
                                            <Grid.Column>
                                              <Field name='decking'
                                                disabled={!e[DATA.WARRANTY_APP_DETAIL_NAME_SELECT_LIST[5]]}
                                                ui='blockLabelVerticalSmall'
                                                placeholder={getLanguage('field.inputNumberPlaceholder', '')}
                                                width='150'
                                                textAlign='center'
                                                value={e.decking}
                                                onChange={(ev) => {
                                                  onChangeDataInput(ev, i);
                                                }}
                                                onBlur={(ev) => {
                                                  ev.target.value = this.input.onBlurApplication(ev);
                                                  onChangeDataInput(ev, i)
                                                }}
                                              />
                                            </Grid.Column>
                                          </Grid>
                                        </Grid.Column>
                                      }
                                      {env.applicationForSelect === DATA.WARRANTY_APP_DETAIL_NAME_SELECT_LIST[6] &&
                                        <Grid.Column>
                                          <Grid>
                                            <Grid.Column>
                                              <Section
                                                width='230'
                                                paddingTop='3'
                                              >
                                                <Field name={DATA.WARRANTY_APP_DETAIL_NAME_SELECT_LIST[6]}
                                                  checked={e[DATA.WARRANTY_APP_DETAIL_NAME_SELECT_LIST[6]] || false}
                                                  ui='checkboxTickFillSmall'
                                                  checkboxLabel={getLanguage('warrantyManage.form.detail.airDucting', '')}
                                                  onChange={(ev) => {
                                                    onChangeCheckbox(ev, i, 'airDucting');
                                                  }}
                                                />
                                              </Section>
                                            </Grid.Column>
                                            <Grid.Column>
                                              <Field name='airDucting'
                                                disabled={!e[DATA.WARRANTY_APP_DETAIL_NAME_SELECT_LIST[6]]}
                                                ui='blockLabelVerticalSmall'
                                                placeholder={getLanguage('field.inputNumberPlaceholder', '')}
                                                width='150'
                                                textAlign='center'
                                                value={e.airDucting}
                                                onChange={(ev) => {
                                                  onChangeDataInput(ev, i);
                                                }}
                                                onBlur={(ev) => {
                                                  ev.target.value = this.input.onBlurApplication(ev);
                                                  onChangeDataInput(ev, i)
                                                }}
                                              />
                                            </Grid.Column>
                                          </Grid>
                                        </Grid.Column>
                                      }
                                    </React.Fragment>
                                  )
                                })}
                              </Grid>
                              {e.applicationInfoList.map((eai, iai) => {
                                return (
                                  <Section
                                    key={iai}
                                    paddingTop='20'
                                    spacingTop='20'
                                    borderTopWidth='1'
                                    borderTopStyle='solid'
                                    borderTopColor={VARIABLES.COLORS.GRAY_8}
                                  >
                                    <Grid gutter='30'>
                                      <Grid.Column flex='1'>
                                        <Field name='building'
                                          fluid
                                          required
                                          ui='blockLabelVerticalSmall'
                                          label={getLanguage('field.building', '')}
                                          placeholder={getLanguage('field.buildingPlaceholder', '')}
                                          value={eai.building}
                                          onChange={(ev) => {
                                            onChangeAppInfoDataInput(ev, i, iai);
                                          }}
                                        />
                                      </Grid.Column>
                                      <Grid.Column flex='1'>
                                        <DatePickerControl
                                          fluid
                                          small
                                          required
                                          label={getLanguage('field.installationDate', '')}
                                          placeholderText={getLanguage('field.installationDatePlaceholder', '')}
                                          dateFormat={DATE_PICKER_FORMAT}
                                          selected={eai.projectInsDateTime}
                                          minDate={this.warrantyManageDetail.getApplicationMinDate()}
                                          maxDate={this.warrantyManageDetail.getApplicationMaxDate()}
                                          // minDate={e.createdDateTime}
                                          // maxDate={data.wrtCat === DATA.WARRANTY_CATEGORY_TYPE.RESIDENTIAL.TYPE ? moment(e.createdDateTime).add(60, 'day')._d : undefined}
                                          onChange={(selected) => {
                                            onChangeAppInfoDataInput({
                                              target: {
                                                value: selected,
                                                name: 'projectInsDateTime'
                                              }
                                            }, i, iai)
                                          }}
                                        />
                                        {/* <Section spacingTop='5'>
                                <Text regular10 color={VARIABLES.COLORS.GRAY_19}>
                                  {getLanguage('field.installationDateHint', '')}
                                </Text>
                              </Section> */}
                                      </Grid.Column>
                                      <Grid.Column flex='2'>
                                        <Field name='customerRemark'
                                          fluid
                                          required
                                          labelNoWrap
                                          // error
                                          ui='blockLabelVerticalSmall'
                                          // ui='blockLabelVerticalTextareaSmall'
                                          // type='textarea'
                                          // height='130'
                                          label={
                                            <React.Fragment>
                                              {getLanguage('field.customerRemark', '')} <Text regular10>{getLanguage('field.customerRemarkExample', '')}</Text>
                                            </React.Fragment>
                                          }
                                          placeholder={getLanguage('field.customerRemarkPlaceholder', '')}
                                          message={
                                            <Section
                                              ui='hintBottomField'
                                              hint={getLanguage('field.customerRemarkHint', '')}
                                            />
                                          }
                                          maxLength={500}
                                          value={eai.customerRemark}
                                          onChange={(ev) => {
                                            onChangeAppInfoDataInput(ev, i, iai);
                                          }}
                                        />
                                      </Grid.Column>
                                      <Grid.Column flex='none'>
                                        <Section width='55'>
                                          <Grid gutter='15' justify='flex-end'>
                                            {iai === (e.applicationInfoList.length - 1) ?
                                              <Grid.Column>
                                                <Section paddingTop='23'>
                                                  <div onClick={() => { onClickAddApplicationInfo(i) }} className='is-pointer'>
                                                    <img src={ICONS['ic-add-outline.svg']} alt='Icon' />
                                                  </div>
                                                </Section>
                                              </Grid.Column>
                                              :
                                              <Grid.Column>
                                                <Section paddingTop='23'>
                                                  <div onClick={() => { onClickDeleteApplicationInfo(i, iai) }} className='is-pointer'>
                                                    <img src={ICONS['ic-delete-outline.svg']} alt='Icon' />
                                                  </div>
                                                </Section>
                                              </Grid.Column>
                                            }
                                          </Grid>
                                        </Section>
                                      </Grid.Column>
                                    </Grid>
                                  </Section>
                                )
                              })}
                            </ShapeContainer>
                          </AccordionItemBody>
                        </AccordionItem>
                      )
                    })}
                  </Accordion>
                </AccordionControl>
              }
            </React.Fragment>
          </Section>
        }
        <Section>
          <Grid
            gutter='15'
            gutterVertical='15'
            justify='flex-end'
          >
            {detailType === detailTypeList[0] &&
              <React.Fragment>
                <Grid.Column>
                  <Button
                    gray
                    ui='back'
                    name={getLanguage('field.buttonBack', '')}
                    onClick={onClickBack}
                  />
                </Grid.Column>
                {data.isShowSaveDraft &&
                  <Grid.Column>
                    <Button
                      lightBlue
                      name={getLanguage('field.buttonSaveAsDraft', '')}
                      onClick={onClickSaveDraft}
                    />
                  </Grid.Column>
                }
                <Grid.Column>
                  <Button
                    blue
                    name={getLanguage('field.buttonContinue', '')}
                    width={VARIABLES.BUTTON.WIDTHS.W_255}
                    onClick={() => {
                      this.warrantyManageDetail.onClickChangeDetailType(detailTypeList[1]);
                    }}
                  />
                </Grid.Column>
              </React.Fragment>
            }
            {(
              detailType === detailTypeList[1] ||
              (
                isAutomaticEnv &&
                detailType === detailTypeList[2]
              )
            ) &&
              <React.Fragment>
                <Grid.Column>
                  {/* <Button
                    gray
                    ui='back'
                    name={getLanguage('field.buttonBack', '')}
                    onClick={() => {
                      this.warrantyManageDetail.onClickChangeDetailType(detailTypeList[0]);
                    }}
                  /> */}
                  <Button
                    gray
                    ui='back'
                    name={getLanguage('field.buttonBack', '')}
                    onClick={onClickBack}
                  />
                </Grid.Column>
                {data.isShowSaveDraft &&
                  <Grid.Column>
                    <Button
                      lightBlue
                      name={getLanguage('field.buttonSaveAsDraft', '')}
                      onClick={onClickSaveDraft}
                    />
                  </Grid.Column>
                }
                <Grid.Column>
                  <Button
                    blue
                    name={getLanguage('field.buttonContinue', '')}
                    width={VARIABLES.BUTTON.WIDTHS.W_255}
                    onClick={() => {
                      this.warrantyManageDetail.onClickChangeDetailType(detailTypeList[2]);
                    }}
                  />
                </Grid.Column>
              </React.Fragment>
            }
            {
              !isAutomaticEnv &&
              detailType === detailTypeList[2] &&
              <React.Fragment>
                <Grid.Column>
                  <Button
                    gray
                    ui='back'
                    name={getLanguage('field.buttonBack', '')}
                    onClick={() => {
                      this.warrantyManageDetail.onClickChangeDetailType(detailTypeList[1]);
                    }}
                  />
                </Grid.Column>
                {data.isShowSaveDraft &&
                  <Grid.Column>
                    <Button
                      lightBlue
                      name={getLanguage('field.buttonSaveAsDraft', '')}
                      onClick={onClickSaveDraft}
                    />
                  </Grid.Column>
                }
                <Grid.Column>
                  <Button
                    blue
                    // disabled={this.warrantyManageDetail.isDisableSaveDetail()}
                    name={getLanguage('field.buttonSaveAndContinue', '')}
                    width={VARIABLES.BUTTON.WIDTHS.W_255}
                    onClick={this.warrantyManageDetail.onClickSaveDetail}
                  />
                </Grid.Column>
              </React.Fragment>
            }
          </Grid>
        </Section>
      </ShapeContainer >
    )
  }
}
