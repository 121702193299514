import React from 'react'
import ClassNames from 'classnames'
import PropTypes from 'prop-types'
import {
  osName,
} from 'react-device-detect'
import {
  WarrantySignatureWrapper
} from './styled'
import {
  Section,
  Text,
  Image,
  Grid,
  Table,
  ShapeContainer,
} from './../../../../components'
import {
  VARIABLES,
} from './../../../../themes'

/**
 * Warranty description:
 * - Warranty
 */

export class WarrantyBSWMThailandSignature extends React.PureComponent {
  render() {
    const {
      customerName,
      date,
      thLanguage,
      registeredTrademarkName,
      isSignatureNote,
    } = this.props

    // props for css classes
    const classes = ClassNames(
      'warranty-bswm-signature',
    )

    return (
      <WarrantySignatureWrapper
        className={classes}
      >
        {/* {thLanguage ?
          <React.Fragment>
            {isSignatureNote &&
              <Section spacingBottom='20'>
                <p className='warranty-bswm-text is-sm'>การรับประกันนี้นำมาใช้บังคับเฉพาะโครงสร้างเหล็กของอาคารที่พักอาศัยและอาคารในประเทศไทย และผลิตจากเหล็กเคลือบ <span className='warranty-bswm-text is-sm is-bold'>{registeredTrademarkName}</span> เท่านั้น</p>
              </Section>
            }
            <Grid justify='space-between'>
              <Grid.Column>
                <Section width='300'>
                  <p className='warranty-bswm-text'>ยืนยันโดย:</p>
                  <Section height='50' paddingBottom='10' spacingBottom='10' borderBottomWidth='1' borderBottomStyle='solid' borderBottomColor={VARIABLES.COLORS.BLACK} />
                  <p className='warranty-bswm-text is-bold'>บริษัท เอ็นเอส บลูสโคป (ประเทศไทย) จำกัด</p>
                  <Section spacingBottom='10'>
                    <p className='warranty-bswm-text is-sm'>(ผู้จัดการฝ่ายการตลาดเทคนิค)</p>
                  </Section>
                  <p className='warranty-bswm-text'>วันที่: <span className='warranty-bswm-text is-bold'>{date}</span></p>
                </Section>
              </Grid.Column>
              <Grid.Column>
                <Section width='300'>
                  <p className='warranty-bswm-text'>รับทราบโดย:</p>
                  <Section height='50' paddingBottom='10' spacingBottom='10' borderBottomWidth='1' borderBottomStyle='solid' borderBottomColor={VARIABLES.COLORS.BLACK} />
                  <p className='warranty-bswm-text is-bold'>{customerName}</p>
                  <Section spacingBottom='10'>
                    <p className='warranty-bswm-text is-sm'>&nbsp;</p>
                  </Section>
                  <p className='warranty-bswm-text'>วันที่: <span className='warranty-bswm-text is-bold'>{date}</span></p>
                </Section>
              </Grid.Column>
            </Grid>
          </React.Fragment>
          :
          <React.Fragment>
            {isSignatureNote &&
              <Section spacingBottom='20'>
                <p className='warranty-bswm-text is-sm'>This warranty applies only to the steel building frames enclosed within the building envelope, in domestic dwellings and commercial buildings in Thailand and manufactured from <span className='warranty-bswm-text is-sm is-bold'>{registeredTrademarkName}</span> steel.</p>
              </Section>
            }
            <Grid justify='space-between'>
              <Grid.Column>
                <Section width='300'>
                  <p className='warranty-bswm-text'>Authorized by:</p>
                  <Section height='50' paddingBottom='10' spacingBottom='10' borderBottomWidth='1' borderBottomStyle='solid' borderBottomColor={VARIABLES.COLORS.BLACK} />
                  <p className='warranty-bswm-text is-bold'>NS BlueScope (Thailand) Limited</p>
                  <Section spacingBottom='10'>
                    <p className='warranty-bswm-text is-sm'>(Technical Marketing Manager)</p>
                  </Section>
                  <p className='warranty-bswm-text'>Date: <span className='warranty-bswm-text is-bold'>{date}</span></p>
                </Section>
              </Grid.Column>
              <Grid.Column>
                <Section width='300'>
                  <p className='warranty-bswm-text'>Acknowledged by:</p>
                  <Section height='50' paddingBottom='10' spacingBottom='10' borderBottomWidth='1' borderBottomStyle='solid' borderBottomColor={VARIABLES.COLORS.BLACK} />
                  <p className='warranty-bswm-text is-bold'>{customerName}</p>
                  <Section spacingBottom='10'>
                    <p className='warranty-bswm-text is-sm'>&nbsp;</p>
                  </Section>
                  <p className='warranty-bswm-text'>Date: <span className='warranty-bswm-text is-bold'>{date}</span></p>
                </Section>
              </Grid.Column>
            </Grid>
          </React.Fragment>
        } */}
      </WarrantySignatureWrapper>
    )
  }

  static defaultProps = {
    // If you set new default props, you should create propsType for correct styleguide document.
  }

  static propTypes = {
    // TYPE > node, string, number, bool, array, object, symbol, func
  }
}
