import { isValidResponse } from "../functions/validation";
import { segmentController } from "../../apiService/apiController/segmentService";
import { ModelSegment, ModelSubSegment } from "../../models/ModelSegment";

export const getSegmentList = async (params = {}, configService = {}) => {
  let newParams = {
    ...params,
  };
  if (!newParams.isNoSort) {
    if (!newParams.sort) {
      newParams.sort = 'segment_id desc';
    }
  }
  const apiParams = {
    ...newParams,
  }
  const segmentService = segmentController(configService);
  const res = await segmentService.searchSegment(apiParams);
  if (isValidResponse(res)) {
    const dataList = res.data.filter(e => {
      if (newParams.isSearchRes !== undefined) {
        return newParams.isSearchRes ? e.segment_cate_res : e.segment_cate_non_res;
      }
      return true;
    }).map(e => new ModelSegment(e));
    console.log('dataList', res.data, dataList, newParams);
    return dataList;
  }
}

export const getSubSegmentList = async (params = {}) => {
  let newParams = {
    ...params,
  };
  const apiParams = {
    ...newParams,
  }
  const configService = {
    isShowLoading: false,
  }
  const segmentService = segmentController(configService);
  const res = await segmentService.searchSubSegment(apiParams);
  if (isValidResponse(res)) {
    const dataList = res.data.map((e) => new ModelSubSegment(e));
    console.log('dataList', res.data, dataList, newParams);
    return dataList;
  }
}