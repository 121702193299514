import React from "react";
import { connect } from "react-redux";
import {
  Section,
  Grid,
  Modal,
  Text,
  Button,
  Field,
  Image,
  Table,
} from "./../../../components";
import { ICONS, VARIABLES } from "./../../../themes";
import { MODAL_TYPE } from "../../../helpers/constants/main";
import { MAIN_REDUX_STATE_KEY } from "../../../actions/mainAction";
import { getLanguage } from "../../../helpers/functions/language";
import _ from "lodash";
import { displayWithComma } from "../../../helpers/functions/display";
import { getUserAuthRedux } from "../../../helpers/functions/auth";

export class ModalConfirmPreTransactionContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      projectName: (props.data || {}).projectName || '',
      dataList: _.cloneDeep(props.dataList || []).map(e => {
        e.specifyCompanyName = e.isSelectedSpecifyCompanyName ? e.specifyCompanyName : '-';
        e.documentaryName = e.documentaryList.map(e => `${e.docMsCode} ${e.docMsName}`).join(', ');
        return e;
      }),

      currentSort: 'm3ProductGroupName',
      sortIcon: {
        m3ProductGroupName: true,
        specifyCompanyName: true,
        documentaryName: true,
      },
    }
  }

  confirmPreTransactionModal = {
    onClickSort: (target) => {
      const {
        sortIcon,
        dataList,
        currentSort,
      } = this.state;

      Object.keys(sortIcon).forEach(key => {
        if (key === target) {
          sortIcon[key] = currentSort === target ? !sortIcon[key] : true;
        }
        else {
          sortIcon[key] = true;
        }
      })

      const sortType = sortIcon[target] ? 'asc' : 'desc';
      this.setState({
        sortIcon,
        currentSort: target,
        dataList: _.orderBy(dataList, [target], [sortType])
      })
    },
    onClickConfirm: () => {
      const {
        projectName,
        dataList,
      } = this.state;
      const {
        onClickConfirm = () => { },
      } = this.props;
      onClickConfirm({
        projectName,
        dataList: dataList.filter(e => e.isSelected),
      })
    }
  }

  input = {
    onChangeInput: (ev) => {
      const name = ev.target.name;
      const value = ev.target.value;
      this.setState({
        [name]: value,
      })
    },
    onChangeSelect: (item) => {
      const {
        dataList,
      } = this.state;
      this.setState({
        dataList: dataList.map(e => {
          if (e.id === item.id) {
            e.isSelected = !e.isSelected;
          }
          return e;
        })
      })
    }
  }

  render() {
    const {
      isOpenModal,
      onClickCancel,
      onClickConfirm,
      title,
      buttonCancelName,
      buttonSubmitName,
      isDisableOverlayClick,
    } = this.props;
    const {
      projectName,
      dataList,
      sortIcon,
    } = this.state;

    return (
      <Modal
        open={isOpenModal}
        scroll
        enableOverlayClose={!isDisableOverlayClick}
        width={VARIABLES.MODAL.WIDTHS.W_1165}
        overlayColor={VARIABLES.COLORS.OVERLAY_BLACK_1}
        spacing={VARIABLES.MODAL.CONTAINER.C_1.SPACING}
        transition={VARIABLES.TRANSITIONS.DEFAULT}
        transformStart={VARIABLES.MODAL.CONTAINER.C_1.TRANSFORM_START}
        transformEnd={VARIABLES.MODAL.CONTAINER.C_1.TRANSFORM_END}
        onClickClose={onClickCancel}
      >
        <Modal.Section
          paddingVertical="25"
          paddingHorizontal="35"
          bgColor={VARIABLES.COLORS.WHITE}
          borderBottomWidth="1"
          borderBottomStyle="solid"
          borderBottomColor={VARIABLES.COLORS.GRAY_8}
          borderTopRightRadius={
            VARIABLES.MODAL.HEADER.H_1.BORDER_TOP_RIGHT_RADIUS
          }
          borderTopLeftRadius={
            VARIABLES.MODAL.HEADER.H_1.BORDER_TOP_LEFT_RADIUS
          }
        >
          <Grid gutter="10">
            <Grid.Column>
              <Text
                regular16
                singleLine
                color={VARIABLES.COLORS.BLACK}
                top="1"
              >
                {title ? title : getLanguage("modal.confirm.title")}
              </Text>
            </Grid.Column>
          </Grid>
        </Modal.Section>
        <Modal.Section
          paddingVertical="40"
          paddingHorizontal="35"
          bgColor={VARIABLES.COLORS.WHITE}
        >
          <Section align="center">
            <Grid justify="center">
              <Grid.Column>
                <Section
                  nonFluid
                  spacingBottom="5"
                  align="center"
                  textAlign="center"
                >
                  <Text
                    regular14
                    color={VARIABLES.COLORS.BLACK}
                    noWrap
                  >
                    {getLanguage("certificate.modal.modalConfirmPreTransaction.pleaseConfirmProjectNameAndSpecifyCompany", "")}
                  </Text>
                </Section>
                <Section
                  nonFluid
                  spacingBottom="30"
                  align="center"
                  textAlign="center"
                >
                  <Text
                    regular14
                    color={VARIABLES.COLORS.RED_4}
                    noWrap
                  >
                    {getLanguage("certificate.modal.modalConfirmPreTransaction.afterCompleteSave", "")}
                  </Text>
                </Section>
                <Section
                  nonFluid
                  spacingBottom="45"
                  align="center"
                >
                  <Field
                    className="is-icon-right"
                    fieldIcon={
                      <Section paddingRight="15">
                        <Image
                          widthRatio="16"
                          widthRatioUnit="px"
                          heightRatio="16"
                          heightRatioUnit="px"
                          src={ICONS["ic-action-edit-line.svg"]}
                        />
                      </Section>
                    }
                    name="projectName"
                    fluid
                    ui="blockLabelVertical"
                    label={getLanguage("certificate.label.projectName", "")}
                    placeholder={getLanguage("certificate.placeholder.placeholderProjectName", "")}
                    value={projectName}
                    onChange={this.input.onChangeInput}
                  />
                </Section>
              </Grid.Column>
            </Grid>
            <Table striped>
              <Table.Section>
                <Table.Row>
                  {/* <Table.HeadColumn
                    noSort
                    maxWidth={50}
                    minWidth={50}
                  >
                    {getLanguage("certificate.table.header.select", "")}
                  </Table.HeadColumn> */}
                  <Table.HeadColumn
                    minWidth={337}
                    sortAscending={sortIcon.m3ProductGroupName}
                    onClickSort={() => {
                      this.confirmPreTransactionModal.onClickSort('m3ProductGroupName');
                    }}
                  >
                    {getLanguage("certificate.table.header.productGroup", "")}
                  </Table.HeadColumn>
                  <Table.HeadColumn
                    minWidth={337}
                    sortAscending={sortIcon.specifyCompanyName}
                    onClickSort={() => {
                      this.confirmPreTransactionModal.onClickSort('specifyCompanyName');
                    }}
                  >
                    {getLanguage("certificate.table.header.company", "")}
                  </Table.HeadColumn>
                  <Table.HeadColumn
                    minWidth={337}
                    sortAscending={sortIcon.documentaryName}
                    onClickSort={() => {
                      this.confirmPreTransactionModal.onClickSort('documentaryName');
                    }}
                  >
                    {getLanguage("certificate.table.header.document", "")}
                  </Table.HeadColumn>
                </Table.Row>
              </Table.Section>
              <Table.Section>
                {dataList.map((e, i) => {
                  return (
                    <Table.Row key={i}>
                      {/* <Table.BodyColumn
                        action
                        alignCenter
                        maxWidth={VARIABLES.TABLE.INFO_WIDTHS.CHECKBOX_SQUARE}
                        minWidth={VARIABLES.TABLE.INFO_WIDTHS.CHECKBOX_SQUARE}
                      >
                        <Section spacingTop="-2">
                          <Field
                            checked={e.isSelected}
                            ui="checkboxTick"
                            onChange={() => { this.input.onChangeSelect(e) }}
                          />
                        </Section>
                      </Table.BodyColumn> */}
                      <Table.BodyColumn
                        info
                        minWidth={337}
                        title={e.m3ProductGroupName}
                      >
                        {e.m3ProductGroupName}
                      </Table.BodyColumn>
                      <Table.BodyColumn
                        info
                        minWidth={337}
                        title={e.specifyCompanyName}
                      >
                        {e.specifyCompanyName}
                      </Table.BodyColumn>
                      <Table.BodyColumn
                        info
                        minWidth={337}
                        title={e.documentaryName}
                      >
                        {e.documentaryName}
                      </Table.BodyColumn>
                    </Table.Row>
                  );
                })}
              </Table.Section>
            </Table>
          </Section>
        </Modal.Section>
        <Modal.Section
          paddingTop="15"
          paddingBottom="20"
          paddingHorizontal="35"
          borderTopWidth={VARIABLES.MODAL.FOOTER.F_1.BORDER_TOP_WIDTH}
          borderTopStyle={VARIABLES.MODAL.FOOTER.F_1.BORDER_TOP_STYLE}
          borderTopColor={VARIABLES.COLORS.GRAY_8}
          borderBottomRightRadius={VARIABLES.MODAL.FOOTER.F_1.BORDER_BOTTOM_RIGHT_RADIUS}
          borderBottomLeftRadius={VARIABLES.MODAL.FOOTER.F_1.BORDER_BOTTOM_LEFT_RADIUS}
        >
          <Grid gutter="15" justify="flex-end">
            <Grid.Column>
              <Button
                gray
                width={VARIABLES.BUTTON.WIDTHS.W_100}
                name={
                  buttonCancelName
                    ? buttonCancelName
                    : getLanguage("field.buttonCancel")
                }
                onClick={onClickCancel}
              />
            </Grid.Column>
            <Grid.Column>
              <Button
                blue
                width={VARIABLES.BUTTON.WIDTHS.W_100}
                name={
                  buttonSubmitName
                    ? buttonSubmitName
                    : getLanguage("field.buttonConfirm")
                }
                onClick={this.confirmPreTransactionModal.onClickConfirm}
              />
            </Grid.Column>
          </Grid>
        </Modal.Section>
      </Modal>
    );
  }

  static defaultProps = {
    imageUi: "iconInfo",
  };
}
