export const lang_th = {
  topMenu: {
    myProfile: 'ข้อมูลของฉัน',
    chooseLanguage: {
      title: 'เลือกภาษา',
      thai: 'ไทย',
      english: 'อังกฤษ',
      indonesia: 'อินโดนีเซีย',
      vietnam: 'เวียดนาม',
      malaysia: 'มาเลเซีย',
      laos: 'ลาว',
    }
  },
  sideMenu: {
    home: 'หน้าหลัก',
    yearWarranty: 'การประเมินปีรับประกัน',
    customers: 'ลูกค้า',
    orderInquiry: 'ความต้องการจากลูกค้า',
    salesTransaction: 'การขาย',
    salesOrders: 'สร้างการขาย',
    salesHistory: 'ดูการขายที่ผ่านมา',
    warrantyManagement: 'การจัดการใบรับประกัน',
    warrantyTemplate: 'ตัวอย่างใบรับประกัน',
    warranty: 'ใบรับประกัน',
    documentary: 'เอกสาร',
    documentTemplate: 'Document Template',
    rewards: 'แลกรางวัล',
    redemption: 'การแลกรางวัล',
    rewardRedemptionHistory: 'รายการแลกรางวัล',
    rewardStock: 'รายการของรางวัล',
    trueBluePointsDetail: 'รายละเอียดคะแนน ',
    inventory: 'คลังสินค้า และการขายคอยล์',
    inventoryManagement: 'คลังสินค้า',
    profileMapping: 'Profile Mapping',
    profileMappingHistory: 'Profile Mapping History',
    profileTransfer: 'Profile Transfer',
    profileTransferHistory: 'Profile Transfer History',
    coilTransfer: 'การขายคอยล์ออก',
    coilTransferHistory: 'ประวัติการโอนคอยล์',
    report: 'รายงาน',
    settings: 'การตั้งค่า',
    adInformation: 'ข้อมูลดีลเลอร์',
    termAndCondition: 'ข้อกำหนดและเงื่อนไข',
    contactUs: 'ติดต่อเรา',
    help: 'ช่วยเหลือ',
    salesPerson: 'พนักงานขาย',
    uploadTransaction: 'อัพโหลดการขาย',
    uploadTransactionManagement: 'จัดการอัพโหลดการขาย',
    uploadTransactionHistory: 'ประวัติอัพโหลดการขาย',
    preTransaction: 'Pre Transaction',
    projectDocument: 'เอกสารโครงการ',
    preTransactionHistory: 'Pre-Transaction History',
    projectReference: 'Project Reference',
    barcodeScan: 'การขอโอนคอยล์เข้า',
    coilApproval: 'อนุมัติโอนคอยล์ออกไป',
    notificationManagement: 'การแจ้งเตือน',
  },
  login: {
    message_1: 'ยินดีต้อนรับสู่',
    message_2: 'TrueBlue',
    message_3: 'ลืม User ID หรือรหัสผ่าน',
    message_4: 'กรุณาระบุ อีเมลล์ เพื่อ reset รหัสผ่าน',
    message_5: 'ตรวจสอบ',
    message_6: 'สิทธิ์',
    message_7: 'ลิงก์การตรวจสอบสิทธิ์ถูกส่งให้เบอร์โทรศัพท์ของคุณทาง SMS',
    message_8: 'โปรดกดลิงก์เข้าสู่ระบบ ภายในเวลา 3 นาที',
    message_9: 'รหัสอ้างอิง คือ',
    message_10: 'สำเร็จ!',
    message_11: 'ล้มเหลว!',
    message_12: 'หมดอายุ',
    message_13: 'ยืนยัน',
    message_14: 'วิธีการ',
    message_15: 'ยืนยันด้วย',
    message_16: 'เบอร์โทรศัพท์',
    message_17: 'คำถาม',
    message_18: 'OTP',
    message_19: 'ส่งถึง',
    message_20: 'ส่งซ้ำ',
    message_21: 'ร้านคุณอยู่จังหวัดอะไร? (ภาษาไทย)',
    message_22: "ร้านคุณมีกี่สาขา?",
    message_23: 'ร้านคุณก่อตั้งในปี พ.ศ. อะไร?',
    message_24: '8 - 24 ตัวอักษร หรือตัวเลข',
    message_25: 'เปลี่ยนรหัสผ่าน',
    message_26: 'โปรดลองรหัสผ่านใหม่ของคุณในการเข้าสู่ระบบครั้งถัดไป',
    message_27: 'เปลี่ยน',
    message_28: 'รหัสผ่าน',
    message_29: 'Setup',
    maintenance: "ระบบได้ปิดทำการปรับปรุงตั้งแต่",
    maintenance_2: "กรุณาเข้าระบบใหม่หลังจากระบบเปิดทำการตามเวลาที่แจ้ง",
    message_30: 'เพื่อความปลอดภัยของ Account โปรดเข้าสู่ระบบใหม่',
  },
  home: {
    breadcrumb1: 'หน้าหลัก',
    title: 'หน้าหลัก',
    label: {
      barcodeScan: 'Scan Barcode เพื่อขอโอนคอยล์',
    }
  },
  yearWarranty: {
    breadcrumb1: 'หน้าหลัก',
    breadcrumb2: 'การประเมินปีรับประกัน',
    title: 'การประเมินปีรับประกัน',
    label: {
      product: 'สินค้า',
      environment: 'สภาพแวดล้อม',
      wall: 'ผนัง',
      roof: 'หลังคา',
      purlinGirt: 'แป',
      trussFrame: 'โครง',
      coolRoomPanel: 'Sandwich panel',
      decking: 'พื้น',
      airDucting: 'ท่อแอร์',
      shutterEx: 'ประตู',
      warrantiesAvailable: 'การรับประกัน',
      year: 'ปี',
      std: 'มาตรฐาน',
      standard: 'มาตรฐาน',
      industrial: 'อุตสาหกรรม',
      marine: 'ทะเล',
      distance: 'ระยะห่าง',
      m: 'เมตร',
      metre: 'เมตร',
      min: 'ต่ำสุด',
      max: 'สูงสุด',
      from: 'ระยะห่างจาก',
      pleaseRegister: 'ลงทะเบียน',
    },
    placeholder: {
      selectProduct: 'เลือกสินค้า',
      selectFrom: 'เลือกระยะห่างจาก',
      selectDistance: 'เลือกระยะห่าง(เมตร)',
    },
    hint: {
      short: {
        br: 'B/R',
        lu: 'L/U',
        ind: 'Ind',
        hind: 'HInd',
        sind: 'SInd',
        mm: 'MM',
        m: 'M',
        sm: 'SM',
        vsm: 'VSM',
      },
      long: {
        br: 'ไม่มีการกัดกร่อน / ชนบท',
        lu: 'อุตสาหกรรมขนาดเล็ก / เมือง',
        ind: 'อุตสาหกรรม',
        hind: 'อุตสาหกรรมที่มีการกัดกร่อน',
        sind: 'อุตสาหกรรมที่มีการกัดกร่อนรุนแรง',
        mm: 'ทะเลที่มีการกัดกร่อนปานกลาง',
        m: 'ทะเล',
        sm: 'ทะเลที่มีการกัดกร่อนรุนแรง',
        vsm: 'ทะเลที่มีการกัดกร่อนรุนแรงมาก',
      },
      message: {
        steelProductionDate: "เป็นเหล็กที่ผลิตก่อน 18 สิงหาคม 2024 เท่านั้น",
      },
    },
    warranty: {
      perforateByCorrosion1: 'การไม่ผุเป็นรูอันเนื่องมาจากการกัดกร่อน',
      perforateByCorrosion2: 'โครงสร้างไม่พังอันเนื่องมาจากการกัดกร่อน', // isSteelBuildingFrames
      perforateByCorrosion3: 'โครงสร้างไม่พังอันเนื่องมาจากการกัดกร่อน', // SuperDyma / TrueCore
      peelOrFlakeExcessively1: 'การไม่หลุดร่อนหรือหลุดเป็นเกล็ดของสี',
      colourFadeExcessively1: 'สีไม่ซีดจางหรือไม่หลุดเป็นผงอย่างรุนแรง',
      discolourByDirtStaining1: 'การไม่เกิดรอยด่างจากคราบสะสมของฝุ่น',
      noWarrantyInformation: 'ไม่มีข้อมูลการรับประกัน',
      perforateDueToCorrosionRoof: "การไม่ผุเป็นรูอันเนื่องมาจากการกัดกร่อนสำหรับหลังคา",
      perforateDueToCorrosionWall: "การไม่ผุเป็นรูอันเนื่องมาจากการกัดกร่อนสำหรับผนัง",
      year: 'ปี'
    },
  },
  customers: {
    breadcrumb1: 'หน้าหลัก',
    breadcrumb2: 'ลูกค้า',
    title: 'จัดการลูกค้า',
    placeholder: 'ค้นหา',
    selectOption: {
      all: 'ทั้งหมด',
      name: 'ชื่อ',
      nickname: 'ชื่อเล่น',
      phoneNo: 'เบอร์โทร',
    },
    buttonSearch: 'ค้นหา',
    buttonCreate: 'สร้างลูกค้า',
    table: {
      header: {
        no: 'อันดับ',
        id: 'เลขที่',
        name: 'ชื่อ',
        nickname: 'ชื่อเล่น',
        phoneNo: 'เบอร์โทร',
        points: 'คะแนนทรูบลู',
        expiringPoints: 'คะแนนที่จะหมดอายุภายใน',
        trained: 'ผ่านการอบรม',
        level: 'เลเวล',
        action: 'ตัวเลือก',
        memberType: 'ประเภทสมาชิก',
      },
      body: {
        platinum: 'แพลตตินั่ม',
        gold: 'ทอง',
        silver: 'เงิน',
        bronze: 'ทองแดง',
      },
    },
    checkboxs: {
      all: 'ทั้งหมด',
      onlyMemberPointsExpire: 'เฉพาะสมาชิกที่คะแนนกำลังจะหมดอายุ',
      pdf: 'PDF',
      excel: 'Excel',
    },
  },
  customersManage: {
    breadcrumb1: 'หน้าหลัก',
    breadcrumb2: 'ลูกค้า',
    breadcrumb3_1: 'สร้างลูกค้า',
    breadcrumb3_2: 'แก้ไขข้อมูลลูกค้า',
    step: {
      register: 'ลงทะเบียน',
      customerInfo: 'รายละเอียดข้อมูลลูกค้า',
      idAddress: 'ที่อยู่ตามบัตรประชาชน',
      contactAddress: 'ที่อยู่ สำหรับติดต่อ และส่งของ',
    },
    tab: {
      registration: 'การลงทะเบียน',
      customerInfo: 'รายละเอียดข้อมูลลูกค้า',
      idAddress: 'ที่อยู่ตามบัตรประชาชน',
      contactAddress: 'ที่อยู่ สำหรับติดต่อ และส่งของ',
    },
    form: {
      regis: {
        customerType: 'ประเภทลูกค้า',
        fixer: 'ช่างติดตั้ง/ผู้รับเหมา',
        hardware: 'ร้านค้า/ร้านขายวัสดุก่อสร้าง',
        homeowner: 'เจ้าของบ้าน/เจ้าของโครงการ',
        rollFormer: 'โรงรีดซื้อแผ่นไปขายต่อ พนักงานของร้าน ช่างติดตั้งของร้าน',
        others: 'อื่นๆ เช่น หน่วยงานราชการ ทหาร',
        authorisedDealer: 'ดีลเลอร์',
        architect: "สถาปนิค / ผู้ออกแบบ / ที่ปรึกษา",
        engineer: "วิศวกร"
      },
      idAddress: {
        idCardNo: 'เลขบัตรประชาชน',
      },
      contactAddress: {
        sameAddress: 'ที่อยู่ตามบัตรประชาชน',
        contactChannel: 'ช่องทางการติดต่อ',
      }
    }
  },
  orderInquiry: {
    breadcrumb1: 'หน้าหลัก',
    breadcrumb2: 'คำถามจากลูกค้า',
    title: 'รายการคำถามจากลูกค้า',
    placeholder: 'ลูกค้า, เบอร์โทร, อีเมล์, สถานะ',
    buttonSearch: 'ค้นหา',
    table: {
      header: {
        id: 'เลขที่',
        subject: 'เรื่อง',
        customer: 'ลูกค้า',
        phoneNo: 'เบอร์โทร',
        email: 'อีเมล์',
        pendingOn: 'อยู่ระหว่างดำเนินการ',
        responseDate: 'ตอบเมื่อวันที่',
        status: 'สถานะ',
        action: 'ตัวเลือก',
      },
      action: {
        details: 'รายละเอียด'
      }
    }
  },
  orderInquiryManage: {
    breadcrumb1: 'หน้าหลัก',
    breadcrumb2: 'คำถามจากลูกค้า',
    breadcrumb3: 'คำถามจากลูกค้า',
    title: 'รายการคำถามจากลูกค้า',
    form: {
      information: {
        acknowledged: 'ตอบกลับแล้ว',
        pending: 'รอการตอบกลับ',
        subject: 'เรื่อง',
        description: 'รายละเอียด',
        customer: 'ลูกค้า',
        phoneNo: 'เบอร์โทร',
        email: 'อีเมล์',
        submittedOn: 'ส่งเมื่อวันที่',
        responseDate: 'ตอบเมือวันที่',
        status: 'สถานะ',
      },
    }
  },
  salesTransaction: {
    breadcrumb1: 'หน้าหลัก',
    breadcrumb2: 'การขาย',
    title: 'การขาย',
    subtitle: 'สารบัญการขาย',
    page: {
      goToPage: 'ไปที่หน้า',
      salesOrders: 'สร้างการขาย',
      salesHistory: 'รายการขายที่ผ่านมา',
    },
  },
  salesOrders: {
    breadcrumb1: 'หน้าหลัก',
    breadcrumb2: 'การขาย',
    breadcrumb3: 'สร้างการขาย',
    title: 'การขาย > สร้างการขาย',
    form: {
      customerInfo: 'รายละเอียดข้อมูลลูกค้า',
      selectCustomer: 'เลือก หรือ สร้างลูกค้าใหม่',
      buttonCreate: 'สร้างลูกค้า',
      buttonSelectCustomer: 'เลือกลูกค้า',
    }
  },
  salesHistory: {
    breadcrumb1: 'หน้าหลัก',
    breadcrumb2: 'การขาย',
    breadcrumb3: 'รายการขายที่ผ่านมา',
    title: 'การขาย > รายการขายที่ผ่านมา',
    selectOption: {
      all: 'ทั้งหมด',
      salesTransactionID: 'เลขที่การขาย',
      customerName: 'ชื่อลูกค้า',
      phoneNumber: 'เบอร์โทรศัพท์',
    },
    placeholder: 'ค้นหา',
    date: 'วันที่',
    dateTo: 'ถึง',
    selectStartDate: 'เลือกวันที่เริ่มต้น',
    selectEndDate: 'เลือกวันที่สิ้นสุด',
    buttonSearch: 'ค้นหา',
  },
  warranty: {
    breadcrumb1: 'หน้าหลัก',
    breadcrumb2: 'ใบรับประกัน',
    breadcrumb3: 'รายการใบรับประกัน',
    title: 'ใบรับประกัน',
    selectOption: {
      salesTransactionID: 'เลขที่การขาย',
      contactName: 'ลูกค้า',
      warrantyNo: 'เลขใบรับประกัน',
      projectName: 'ชื่อโครงการ',
      phoneNumber: 'เบอร์โทร',
    },
    placeholder: 'ค้นหา',
    date: 'วันที่',
    dateTo: 'ถึง',
    selectStartDate: 'เลือกวันที่เริ่มต้น',
    selectEndDate: 'เลือกวันที่สิ้นสุด',
    buttonSearch: 'ค้นหา',
    selectedTransactions: 'เลือกการขาย',
    buttonGenerateWarranty: 'สร้างใบรับประกัน',
    footer: {
      createDate: 'วันที่ออกเอกสาร',
      checkViaQr: 'ตรวจสอบข้อมูลผ่าน QR Code',
    }
  },
  warrantyManage: {
    breadcrumb1: 'หน้าหลัก',
    breadcrumb2: 'ใบรับประกัน',
    breadcrumb3: 'รายการใบรับประกัน',
    breadcrumb4: 'สร้างใบรับประกัน',
    title: 'ใบรับประกัน > สร้างใบรับประกัน',
    alertGenerateWarranty: 'ใบรับประกันอยู่ระหว่างการสร้าง',
    step: {
      information: 'ข้อมูล',
      segment: 'กลุ่ม',
      detail: 'รายละเอียด',
      conditions: 'เงื่อนไข',
      warranty: 'ใบรับประกัน',
      documentaryInformation: 'ข้อมูลเอกสาร',
      documentary: 'เอกสาร',
    },
    form: {
      info: {
        title: 'ข้อมูลโครงการ',
        title_env: 'Environment Informaion',
      },
      segment: {
        title: 'กลุ่ม',
        button: {
          search: 'ค้นหา',
          segmentAndSubSegmentInformation: 'ข้อมูลกลุ่ม',
        },
        table: {
          header: {
            segment: 'กลุ่ม',
            description: 'รายละเอียดกลุ่ม',
            subSegment: 'กลุ่มย่อย',
            subSegmentDescription: 'รายละเอียดกลุ่มย่อย',
          },
        },
        modal: {
          segmentAndSubSegmentInformation: 'ข้อมูลกลุ่ม',
        },
        building: {
          factory: 'โรงงาน',
          office: 'สำนักงาน, ออฟฟิศ',
          carPark: 'ที่จอดรถ',
          warehouse: 'คลังสินค้า,โกดังเก็บสินค้า'
        }
      },
      detail: {
        applicationDetail: 'ประเภทการใช้งาน',
        productInformation: 'ข้อมูลสินค้า',
        roofAndWall: 'หลังคา หรือ ผนัง (เมตร)',
        roof: 'หลังคา (เมตร)',
        wall: 'ผนัง (เมตร)',
        purlin: 'แปหลังคา และ แป ผนัง',
        truss: 'โครงหลังคา หรือโครงผนัง (เมตร)',
        coolRoom: 'ผนังห้องเย็น',
        decking: 'แผ่นพื้นกึ่งสำเร็จ',
        airDucting: 'งานท่อ (เมตร)',
        quantityRemaining: 'จำนวนที่เหลือ',
        noInformation: 'ไม่มีข้อมูล',
        exceedQuantity: 'เกินจำนวน โปรดเปลี่ยนจำนวนหรือเปลี่ยนกลุ่ม',
        segmentInformation: 'ข้อมูลกลุ่ม',
        buildingInformation: 'ข้อมูลก่อสร้าง',
        table: {
          header: {
            selectedItem: 'เลือกรายการ',
            purchasingDate: 'วันที่ซื้อ',
            salesTransactionID: 'เลขที่การขาย',
            coilDescription: 'รายละเอียดคอยล์',
            coilNo: 'เบอร์คอยล์',
            quantity: 'จำนวน',
          }
        }
      },
      condition: {
        title: 'เงื่อนไข',
        conditionYes: 'ใช่',
        conditionNo: 'ไม่ใช่',
        condition1: 'องศาหลังคา มากกว่า 5 องศา หรือ รายการคำนาณการรับน้ำฝน',
        condition2: 'สกรู คลาส 3 หรือ 4 ที่ไม่ใช้ สแตนเลส',
        condition3: 'ไม่ทำการวางทับกัน',
        condition4: 'ชายคาเปิด',
        condition5: 'ใต้หลังคาถูกปิดด้วย',
        condition5_1: 'ฉนวน',
        condition5_2: 'เพดาน',
        condition5_3: 'แผ่นปิดกันรั่วซึม',
        condition5_4: 'แผ่นเปล่า (ไม่มีสิ่งปิดทับ)',
        condition6: 'ชนบท หรือ ในเมือง',
        condition6_1: 'ชนบท',
        condition6_2: 'ในเมือง',
        condition7: `สถานที่ตั้งโครงการต้องอยู่ห่างจากทะเลมากกว่า 1 กม.`,
        condition7cTitle: 'กรุณาเลือก',
        condition7_1: '< 300 ม.',
        condition7_2: '> 300, < 500 ม.',
        condition7_3: '> 500, < 1,000 ม.',
        condition8: `สถานที่ตั้งโครงการต้องอยู่ห่างจากเขตอุสหกรรม หรือ สภาพแวดล้อมที่มีการกัดกร่อนสูง มากกว่า 1 กม.`,
        condition8cTitle: 'กรุณาเลือก',
        condition8_1: '< 500 ม.',
        condition8_2: '> 500, < 1 กม.',
        condition8_3: '> 1 , < 2 กม.',
        condition9: 'ผลิตภัณฑ์ต้องไม่ใช้ร่วมกับวัสดุที่ไม่สามารถเข้ากันได้ เช่น สแตนเลส ทองแดง กรด หรือ ด่าง',
        condition10: 'กิจกรรมในอาคารต้องไม่ส่งผลต่ออายุการใช้งานของผลิตภัณฑ์(ความร้อน,สารเคมี,ไอจากกระบวนการผลิต)',
        conditionUnitPlaceholder: 'เพิ่มตัวเลข',
        conditionSelectPlaceholder: 'ตัวเลือก',
        conditionDescriptionPlaceholder: 'เพิ่มคำอธิบาย',
        conditionDescriptionHint: 'สูงสุด 500 ตัวอักษร',
        application: {
          roofAndWall: 'หลังคา และ ผนัง',
          walling: 'ผนัง',
          roofing: 'หลังคา',
          purlinAndGirt: 'โครงสร้าง และ แป',
          trussAndFrame: 'โครงผนังเบา และ แป',
          coolRoomPanel: 'ผนังติดฉนวน',
          decking: 'พื้นสำเร็จ',
          airDucting: 'งานท่อ',
        },
        criteriaAndSurroundingEnvironment: 'เกณฑ์และสภาพแวดล้อมโดยรอบ',
        warrantyPeriods: 'ระยะเวลารับประกัน',
        warrantyPeriodsCondition: 'ขอลดระยะเวลาการรับประกัน, กรุณาเลือกสินค้า:',
        warrantyPeriodsSelectLabel: 'เลือกสินค้าทุกรายการ',
        warrantyPeriodsSelectPlaceholder: 'เลือกสินค้า',
        warrantyPeriodsConditionAttribute: {
          PleaseSelectApplication: 'Please select application',
          roof: 'Roof',
          wall: 'Wall',
          corrosion: 'Corrosion',
          peelFlake: 'Peel & Flake',
          colorFade: 'Color Fade',
          dirtStain: 'Dirt Stain',
        },
        roofConditions: {
          condition1: 'ผู้ผลิตหรือตัวแทนของผู้ผลิตแผ่นหลังคา/ผนังได้ทำการตรวจสอบวัสดุและสถานที่ติดตั้งแล้วใช่หรือไม่',
          condition2: 'สกรูที่ใช้มีคุณภาพตามมาตรฐาน AS3566: คลาส 3 (>400เมตร จากพื้นที่ชายฝั่ง/อุตสาหกรรม) หรือ คลาส 4 (≤400m จากพื้นที่ชายฝั่ง/อุตสาหกรรม) และสกรูได้ถูกยึดไปจนถึงปลายหัวสกรู',
          condition3: 'ไม่มีปล่องควันที่ตัวอาคารหรือบริเวณใกล้เคียง',
          condition4: "การติดตั้ง หลังคา/ผนัง ต้องเป็นไปตามคำแนะนำของทางบริษัทฯ ที่ได้มีการจัดพิมพ์และใช้งานอยู่เป็นฉบับปัจุบัน ณ ช่วงเวลาที่มีการติดตั้ง",
          condition5: 'หลังคา/ผนัง จะต้องไม่มีรอยขีดข่วน ครูด เสียหายหรือเคลือบด้วยสิ่งแปลกปลอม หรือวัสดุที่ไม่เหมาะสมกัน',
          condition6: 'อุปกรณ์ประกอบ ครอบมุมและชิ้นส่วนประกอบทั้งหมดจะต้องผลิตขึ้นจากโลหะที่มีความเหมาะสมกัน (COLORBOND?, ZINCALUME?,เหล็ก สังกะสี หรืออะลูมิเนียม) หรือได้รับการเคลือบตามคำแนะนำของทางบริษัทฯ ที่ได้มีการจัดพิมพ์และใช้งานอยู่เป็นปัจุบัน',
          condition7: "วัสดุที่ใช้ทำสายล่อฟ้า ต้องเข้ากันได้กับวัสดุของทางบริษัทฯ (เช่น อลูมิเนียม)",
          condition8: 'ไม่มีการใช้สีซ่อมหลังคาและผนัง',
          condition9: 'ซิลิโคนยาแนวที่ใช้ในการติดตั้งต้องมีคุณสมบัติเป็นกลางและเป็นไปตามคำแนะนำของทางบริษัทฯ ที่ได้มีการจัดพิมพ์และใช้งานอยู่เป็นปัจุบัน',
          condition10: 'สภาพแวดล้อมโดยรอบและสภาพการติดตั้งเป็นไปตามเงื่อนไขปกติที่ระบุไว้ในเงื่อนไขการรับประกัน',
          condition11_1: 'ที่ตั้งโครงการ',
          condition11_2: 'จากบริเวณพื้นที่ชายฝั่งทะเลคลื่นลมสงบ',
          condition12_1: 'ที่ตั้งโครงการ',
          condition12_2: 'จากบริเวณพื้นที่ชายฝั่งทะเลคลื่นลมปานกลาง.',
          condition13_1: 'ที่ตั้งโครงการ',
          condition13_2: 'จากบริเวณพื้นที่นิคมอุตสาหกรรมที่มีผลต่อการกัดกร่อนรุนแรง',
          condition14_1: 'ที่ตั้งโครงการ',
          condition14_2: 'จากบริเวณพื้นที่นิคมอุตสาหกรรมที่มผลต่อการกัดกร่อนไม่รุนแรง',
          condition15: 'รายละเอียดเพิ่มเติมของปัจจัยที่ผิดปกติอย่างรุนแรงอื่น ๆ ซึ่งมีผลต่อการรับประกันโดยระบุระยะทางที่อยู่ห่างออกไปเป็นกิโลเมตร',
          condition16: 'รายละเอียดเพิ่มเติมของประสิทธิภาพของผลิตภัณฑ์และการใช้งานที่คล้ายคลึงกัน ซึ่งมีกิจกรรมภายในอาคารที่ใกล้เคียงกัน',
        },
        wallConditions: {
          condition1: 'ผู้ผลิตหรือตัวแทนของผู้ผลิตแผ่นหลังคา/ผนังได้ทำการตรวจสอบวัสดุและสถานที่ติดตั้งแล้วใช่หรือไม่',
          condition2: 'สกรูที่ใช้มีคุณภาพตามมาตรฐาน AS3566: คลาส 3 (>400เมตร จากพื้นที่ชายฝั่ง/อุตสาหกรรม) หรือ คลาส 4 (≤400m จากพื้นที่ชายฝั่ง/อุตสาหกรรม) และสกรูได้ถูกยึดไปจนถึงปลายหัวสกรู',
          condition3: 'ไม่มีปล่องควันที่ตัวอาคารหรือบริเวณใกล้เคียง',
          condition4: "การติดตั้ง หลังคา/ผนัง ต้องเป็นไปตามคำแนะนำของทางบริษัทฯ ที่ได้มีการจัดพิมพ์และใช้งานอยู่เป็นฉบับปัจุบัน ณ ช่วงเวลาที่มีการติดตั้ง",
          condition5: 'หลังคา/ผนัง จะต้องไม่มีรอยขีดข่วน ครูด เสียหายหรือเคลือบด้วยสิ่งแปลกปลอม หรือวัสดุที่ไม่เหมาะสมกัน',
          condition6: 'อุปกรณ์ประกอบ ครอบมุมและชิ้นส่วนประกอบทั้งหมดจะต้องผลิตขึ้นจากโลหะที่มีความเหมาะสมกัน (COLORBOND?, ZINCALUME?,เหล็ก สังกะสี หรืออะลูมิเนียม) หรือได้รับการเคลือบตามคำแนะนำของทางบริษัทฯ ที่ได้มีการจัดพิมพ์และใช้งานอยู่เป็นปัจุบัน',
          condition7: "วัสดุที่ใช้ทำสายล่อฟ้า ต้องเข้ากันได้กับวัสดุของทางบริษัทฯ (เช่น อลูมิเนียม)",
          condition8: 'ไม่มีการใช้สีซ่อมหลังคาและผนัง',
          condition9: 'ซิลิโคนยาแนวที่ใช้ในการติดตั้งต้องมีคุณสมบัติเป็นกลางและเป็นไปตามคำแนะนำของทางบริษัทฯ ที่ได้มีการจัดพิมพ์และใช้งานอยู่เป็นปัจุบัน',
          condition10: 'สภาพแวดล้อมโดยรอบและสภาพการติดตั้งเป็นไปตามเงื่อนไขปกติที่ระบุไว้ในเงื่อนไขการรับประกัน',
          condition11_1: 'ที่ตั้งโครงการ',
          condition11_2: 'จากบริเวณพื้นที่ชายฝั่งทะเลคลื่นลมสงบ',
          condition12_1: 'ที่ตั้งโครงการ',
          condition12_2: 'จากบริเวณพื้นที่ชายฝั่งทะเลคลื่นลมปานกลาง.',
          condition13_1: 'ที่ตั้งโครงการ',
          condition13_2: 'จากบริเวณพื้นที่นิคมอุตสาหกรรมที่มีผลต่อการกัดกร่อนรุนแรง',
          condition14_1: 'ที่ตั้งโครงการ',
          condition14_2: 'จากบริเวณพื้นที่นิคมอุตสาหกรรมที่มีผลต่อการกัดกร่อนไม่รุนแรง',
          condition15: 'รายละเอียดเพิ่มเติมของปัจจัยที่ผิดปกติอย่างรุนแรงอื่น ๆ ซึ่งมีผลต่อการรับประกันโดยระบุระยะทางที่อยู่ห่างออกไปเป็นกิโลเมตร',
          condition16: 'รายละเอียดเพิ่มเติมของประสิทธิภาพของผลิตภัณฑ์และการใช้งานที่คล้ายคลึงกัน ซึ่งมีกิจกรรมภายในอาคารที่ใกล้เคียงกัน:',
        },
        trussAndFrameConditions: {
          condition1: 'ผู้ผลิตหรือตัวแทนของผู้ผลิตทรัส/เฟรมมิ่ง ได้ทำการตรวจสอบวัสดุและสถานที่ติดตั้งแล้วใช่หรือไม่',
          condition2: 'โครงหลังคาเหล็ก ทรัส / เฟรมมิ่ง อยู่ "ภายใต้อาคารปิด".',
          condition3: 'ที่พักอาศัยที่ตั้งอยู่ห่างจากชายทะเลมากกว่า 300 เมตรหรือห่างจากแหล่งน้ำมากกว่า 100 เมตร',
          condition4: "โครงหลังคาเหล็ก ทรัส / เฟรมมิ่ง ได้รับการติดตั้งตามตามคำแนะนำที่เผยแพร่ของผู้ผลิต ณ เวลาที่ติดตั้ง",
          condition5: 'ทำการติดตั้งแผ่นกันชื้นระหว่างแผ่นรองฐานกับแผ่นพื้นคอนกรีต',
          condition6: 'ทำการติดตั้งครอบและการซ่อมแซมส่วนที่น้ำรั่วซึมอย่างเหมาะสมเพื่อป้องไม่ให้น้ำรั่วซึม',
          condition7: "ทำการติดตั้งตัวเกี่ยวยึด สกรู ต้องเป็นไปตามข้อกำหนด ที่ระบุตามมาตรฐาน AS 3566 และมาตรฐานอื่นๆที่บังคับใช้",
          condition8: 'การติดตั้ง ไม่มีการสัมผัสกันกับวัสดุที่ไม่เข้ากัน (เช่น ท่อทองแดง หรือไม้ที่อาบน้ำยา) ติดกับส่วนประกอบโครงสร้าง โครงหลังคาเหล็ก ทรัส / เฟรมมิ่ง',
          condition9: 'โครงหลังคาเหล็ก ทรัส / เหรมมิ่ง ปราศจากเศษผงเหล็กและเศษวัดสุอื่น เช่นปูน ทั้งก่อนและหลังการติดตั้ง ',
          condition10: 'โครงหลังคาเหล็ก ทรัส / เฟรมมิ่ง ต้องอยู่ในสภาพสมบูรณ์ ปราศจากความเสียหายทั้งทางกลและทางเคมี ทั้งก่อนและหลังการติดตั้ง ',
          condition11: 'สภาพแวดล้อมโดยรอบและสภาพการติดตั้งเป็นไปตามเงื่อนไขปกติที่ระบุไว้ในเงื่อนไขการรับประกัน ',
          condition12_1: 'ที่ตั้งโครงการ',
          condition12_2: 'จากบริเวณพื้นที่ชายฝั่งทะเลคลื่นลมสงบ',
          condition13_1: 'ที่ตั้งโครงการ',
          condition13_2: 'จากบริเวณพื้นที่ชายฝั่งทะเลคลื่นลมปานกลาง',
          condition14_1: 'ที่ตั้งโครงการ',
          condition14_2: 'จากบริเวณพื้นที่นิคมอุตสาหกรรมที่มีผลต่อการกัดกร่อนรุนแรง',
          condition15_1: 'ที่ตั้งโครงการ',
          condition15_2: 'จากบริเวณพื้นที่นิคมอุตสาหกรรมที่มีผลต่อการกัดกร่อนไม่รุนแรง',
          condition16: 'รายละเอียดเพิ่มเติมของปัจจัยที่ผิดปกติอย่างรุนแรงอื่น ๆ ซึ่งมีผลต่อการรับประกันโดยระบุระยะทางที่อยู่ห่างออกไปเป็นกิโลเมตร',
          condition17: 'รายละเอียดเพิ่มเติมของประสิทธิภาพของผลิตภัณฑ์และการใช้งานที่คล้ายคลึงกัน ซึ่งมีกิจกรรมภายในอาคารที่ใกล้เคียงกัน:',
        },
        purlinAndGirtConditions: {
          condition1: 'ผู้ผลิตหรือตัวแทนของผู้ผลิตทรัส/เฟรมมิ่ง ได้ทำการตรวจสอบวัสดุและสถานที่ติดตั้งแล้วใช่หรือไม่',
          condition2: 'โครงหลังคาเหล็ก ทรัส / เฟรมมิ่ง อยู่ "ภายใต้อาคารปิด".',
          condition3: 'ที่พักอาศัยที่ตั้งอยู่ห่างจากชายทะเลมากกว่า 300 เมตรหรือห่างจากแหล่งน้ำมากกว่า 100 เมตร',
          condition4: "โครงหลังคาเหล็ก ทรัส / เฟรมมิ่ง ได้รับการติดตั้งตามตามคำแนะนำที่เผยแพร่ของผู้ผลิต ณ เวลาที่ติดตั้ง",
          condition5: 'ทำการติดตั้งแผ่นกันชื้นระหว่างแผ่นรองฐานกับแผ่นพื้นคอนกรีต',
          condition6: 'ทำการติดตั้งครอบและการซ่อมแซมส่วนที่น้ำรั่วซึมอย่างเหมาะสมเพื่อป้องไม่ให้น้ำรั่วซึม',
          condition7: "ทำการติดตั้งตัวเกี่ยวยึด สกรู ต้องเป็นไปตามข้อกำหนด ที่ระบุตามมาตรฐาน AS 3566 และมาตรฐานอื่นๆที่บังคับใช้",
          condition8: 'การติดตั้ง ไม่มีการสัมผัสกันกับวัสดุที่ไม่เข้ากัน (เช่น ท่อทองแดง หรือไม้ที่อาบน้ำยา) ติดกับส่วนประกอบโครงสร้าง โครงหลังคาเหล็ก ทรัส / เฟรมมิ่ง',
          condition9: 'โครงหลังคาเหล็ก ทรัส / เหรมมิ่ง ปราศจากเศษผงเหล็กและเศษวัดสุอื่น เช่นปูน ทั้งก่อนและหลังการติดตั้ง ',
          condition10: 'โครงหลังคาเหล็ก ทรัส / เฟรมมิ่ง ต้องอยู่ในสภาพสมบูรณ์ ปราศจากความเสียหายทั้งทางกลและทางเคมี ทั้งก่อนและหลังการติดตั้ง ',
          condition11: 'สภาพแวดล้อมโดยรอบและสภาพการติดตั้งเป็นไปตามเงื่อนไขปกติที่ระบุไว้ในเงื่อนไขการรับประกัน ',
          condition12_1: 'ที่ตั้งโครงการ',
          condition12_2: 'จากบริเวณพื้นที่ชายฝั่งทะเลคลื่นลมสงบ',
          condition13_1: 'ที่ตั้งโครงการ',
          condition13_2: 'จากบริเวณพื้นที่ชายฝั่งทะเลคลื่นลมปานกลาง',
          condition14_1: 'ที่ตั้งโครงการ',
          condition14_2: 'จากบริเวณพื้นที่นิคมอุตสาหกรรมที่มีผลต่อการกัดกร่อนรุนแรง',
          condition15_1: 'ที่ตั้งโครงการ',
          condition15_2: 'จากบริเวณพื้นที่นิคมอุตสาหกรรมที่มีผลต่อการกัดกร่อนไม่รุนแรง',
          condition16: 'รายละเอียดเพิ่มเติมของปัจจัยที่ผิดปกติอย่างรุนแรงอื่น ๆ ซึ่งมีผลต่อการรับประกันโดยระบุระยะทางที่อยู่ห่างออกไปเป็นกิโลเมตร',
          condition17: 'รายละเอียดเพิ่มเติมของประสิทธิภาพของผลิตภัณฑ์และการใช้งานที่คล้ายคลึงกัน ซึ่งมีกิจกรรมภายในอาคารที่ใกล้เคียงกัน:',
        },
        coolRoomPanelConditions: {
          condition1: 'ผู้ผลิตหรือตัวแทนของผู้ผลิตผนัง/หลังคา ได้ทำการตรวจสอบวัสดุและสถานที่ติดตั้งแล้วใช่หรือไม่',
          condition2: 'การติดตั้ง ผนัง/หลังคา ต้องเป็นไปตามคำแนะนำของทางบริษัทฯ ที่ได้มีการจัดพิมพ์และใช้งานอยู่เป็นฉบับปัจุบัน ณ ช่วงเวลาที่มีการติดตั้ง',
          condition3: 'ความหนาเหล็กก่อนเคลือบ (BMT) 0.40mm (หรือมากกว่า)',
          condition4: 'ใช้สำหรับผลิตภัณฑ์ผนังและพาเนว',
          condition5: 'ซิลิโคนยาแนวรอยต่อผนัง เฉพาะรุ่นที่มีคุณสมบัติเป็นกลางเท่านั้น',
          condition6: 'สกรุและหมุดยึดจะต้องมีคุณสมบัติตาม AS3566 Class3 หรือ Class4 และต้องได้รับการรับรองคุณสมบัติดังกว่าวจากผู้จัดหาวัสดุยึด',
          condition7: 'อุปกรณ์ประกอบ ครอบมุมและชิ้นส่วนประกอบทั้งหมดจะต้องผลิตขึ้นจากโลหะที่มีความเหมาะสมกัน (COLORBOND? เหล็ก สังกะสี หรือ อะลูมิเนียม) ต้องเป็นไปตามคำแนะนำของทางบริษัทฯ ที่ได้มีการจัดพิมพ์และใช้งานอยู่เป็นฉบับปัจุบัน ณ ช่วงเวลาที่มีการติดตั้ง',
          condition8: 'การติดตั้ง ไม่มีการสัมผัสกันกับวัสดุที่ไม่เข้ากัน (เช่น ท่อทองแดง หรือไม้ที่อาบน้ำยา)',
          condition9: 'ผลิตภัณฑ์ต้องอยู่ในสภาพสมบูรณ์ ปราศจากความเสียหายทั้งทางกลและทางเคมี ทั้งก่อนและหลังการติดตั้ง',
          condition10: 'พื้นที่ทั้งหมดต้องถูกล้างและทำความสะอาด',
          condition11_1: 'ที่ตั้งโครงการ',
          condition11_2: 'จากบริเวณพื้นที่ชายฝั่งทะเลคลื่นลมสงบ',
          condition12_1: 'ที่ตั้งโครงการ',
          condition12_2: 'จากบริเวณพื้นที่ชายฝั่งทะเลคลื่นลมปานกลาง',
          condition13_1: 'ที่ตั้งโครงการ',
          condition13_2: 'จากบริเวณพื้นที่นิคมอุตสาหกรรมที่มีผลต่อการกัดกร่อนรุนแรง',
          condition14_1: 'ที่ตั้งโครงการ',
          condition14_2: 'จากบริเวณพื้นที่นิคมอุตสาหกรรมที่มีผลต่อการกัดกร่อนไม่รุนแรง',
          condition15: 'รายละเอียดเพิ่มเติมของปัจจัยที่ผิดปกติอย่างรุนแรงอื่น ๆ ซึ่งมีผลต่อการรับประกันโดยระบุระยะทางที่อยู่ห่างออกไปเป็นกิโลเมตร:',
          condition16: 'รายละเอียดเพิ่มเติมของประสิทธิภาพของผลิตภัณฑ์และการใช้งานที่คล้ายคลึงกัน ซึ่งมีกิจกรรมภายในอาคารที่ใกล้เคียงกัน:',
          condition17_1: 'หลังคาติดตั้งร่วมกับ',
          condition17_2: 'ฉนวน',
          condition17_3: 'กรุณาเลือกรายละเอียดเพิ่มเติม',
          condition17_4: 'PU',
          condition17_5: 'PIR',
          condition17_6: 'PE',
          condition17_7: 'PS',
          condition17_8: 'EPS',
          condition17_9: 'ฝ้า',
          condition17_10: 'ปล่องควันหรือไอ',
          condition17_11: 'ไม่มี',
          condition17_12: 'อื่นๆ',
        },
        airDuctingConditions: {
          condition1: 'ผู้ผลิตหรือตัวแทนของผู้ผลิตทรัส/เฟรมมิ่ง ได้ทำการตรวจสอบวัสดุและสถานที่ติดตั้งแล้วใช่หรือไม่',
          condition2: 'โครงหลังคาเหล็ก ทรัส / เฟรมมิ่ง อยู่ "ภายใต้อาคารปิด".',
          condition3: 'ที่พักอาศัยที่ตั้งอยู่ห่างจากชายทะเลมากกว่า 300 เมตรหรือห่างจากแหล่งน้ำมากกว่า 100 เมตร',
          condition4: "โครงหลังคาเหล็ก ทรัส / เฟรมมิ่ง ได้รับการติดตั้งตามตามคำแนะนำที่เผยแพร่ของผู้ผลิต ณ เวลาที่ติดตั้ง",
          condition5: 'ทำการติดตั้งแผ่นกันชื้นระหว่างแผ่นรองฐานกับแผ่นพื้นคอนกรีต',
          condition6: 'ทำการติดตั้งครอบและการซ่อมแซมส่วนที่น้ำรั่วซึมอย่างเหมาะสมเพื่อป้องไม่ให้น้ำรั่วซึม',
          condition7: "ทำการติดตั้งตัวเกี่ยวยึด สกรู ต้องเป็นไปตามข้อกำหนด ที่ระบุตามมาตรฐาน AS 3566 และมาตรฐานอื่นๆที่บังคับใช้",
          condition8: 'การติดตั้ง ไม่มีการสัมผัสกันกับวัสดุที่ไม่เข้ากัน (เช่น ท่อทองแดง หรือไม้ที่อาบน้ำยา) ติดกับส่วนประกอบโครงสร้าง โครงหลังคาเหล็ก ทรัส / เฟรมมิ่ง',
          condition9: 'โครงหลังคาเหล็ก ทรัส / เหรมมิ่ง ปราศจากเศษผงเหล็กและเศษวัดสุอื่น เช่นปูน ทั้งก่อนและหลังการติดตั้ง ',
          condition10: 'โครงหลังคาเหล็ก ทรัส / เฟรมมิ่ง ต้องอยู่ในสภาพสมบูรณ์ ปราศจากความเสียหายทั้งทางกลและทางเคมี ทั้งก่อนและหลังการติดตั้ง ',
          condition11: 'สภาพแวดล้อมโดยรอบและสภาพการติดตั้งเป็นไปตามเงื่อนไขปกติที่ระบุไว้ในเงื่อนไขการรับประกัน ',
          condition12_1: 'ที่ตั้งโครงการ',
          condition12_2: 'จากบริเวณพื้นที่ชายฝั่งทะเลคลื่นลมสงบ',
          condition13_1: 'ที่ตั้งโครงการ',
          condition13_2: 'จากบริเวณพื้นที่ชายฝั่งทะเลคลื่นลมปานกลาง',
          condition14_1: 'ที่ตั้งโครงการ',
          condition14_2: 'จากบริเวณพื้นที่นิคมอุตสาหกรรมที่มีผลต่อการกัดกร่อนรุนแรง',
          condition15_1: 'ที่ตั้งโครงการ',
          condition15_2: 'จากบริเวณพื้นที่นิคมอุตสาหกรรมที่มีผลต่อการกัดกร่อนไม่รุนแรง',
          condition16: 'รายละเอียดเพิ่มเติมของปัจจัยที่ผิดปกติอย่างรุนแรงอื่น ๆ ซึ่งมีผลต่อการรับประกันโดยระบุระยะทางที่อยู่ห่างออกไปเป็นกิโลเมตร',
          condition17: 'รายละเอียดเพิ่มเติมของประสิทธิภาพของผลิตภัณฑ์และการใช้งานที่คล้ายคลึงกัน ซึ่งมีกิจกรรมภายในอาคารที่ใกล้เคียงกัน:',
        },
      },
      manage: {
        title: 'รับประกันสินค้า',
        or: 'หรือ',
      },
      environment: {
        title: 'รายละเอียดสิ่งแวดล้อม',
        hint: 'โปรดเลือกรายละเอียดสิ่งแวดล้อมของแต่ละผลิตภัณฑ์',
      },
      documentary: {
        title: {
          selectDocumentary: 'เลือกเอกสาร',
          specifyCompanyName: 'ระบุชื่อบริษัท',
          confirmPeviousStep: 'ยืนยันการย้อนกลับไปขั้นตอนก่อนหน้า',
          confirmCompanyName: 'ยืนยันชื่อบริษัท',
          productWarrantyAndDocumentary: 'ใบรับประกัน & เอกสาร',
          previewDocument: 'ตัวอย่างเอกสาร',
        },
        label: {
          specifyCompanyNameInDocument: 'ระบุชื่อบริษัทในเอกสาร',
        },
        placeholder: {
          typeCompanyName: 'ใส่ชื่อบริษัท',
        },
        message: {
          confirmPeviousStep: 'คุณการย้อนกลับไปขั้นตอนก่อนหน้า ?',
          warningPeviousStep: 'ข้อมูลเอกสารของคุณจะไม่ได้รับการบันทึก',
          confirmCompanyName: 'ยืนยันการใช้ชื่อบริษัทในเอกสาร',
          warningCompanyName1: 'หลังจากเสร็จสิ้นการสร้างเอกสาร',
          warningCompanyName2: "คุณจะไม่สามารถแก้ไขหรือสร้างเอกสารที่คุณเลือกในครั้งนี้ได้อีก",
        },
      },
    }
  },
  warrantyStatus: {
    warrantyCertificateChecker: 'การตรวจสอบใบรับประกัน',
    warrantyNo: 'เลขที่ใบรับประกัน',
    projectName: 'ชื่อโครงการ',
    projectAddress: 'ที่อยู่โครงการ (ที่อยู่ติดตั้ง)',
    warrantyStatusChecker: "WARRANTY STATUS CHECKER",
    certificateStatusChecker: "CERTIFICATE STATUS CHECKER",
    projectAddressShort: "ที่อยู่โครงการ",
    certificateNo: "เลขที่เอกสาร",
    locationAccount: "ชื่อร้าน/โรงรีด",
    phoneNo: "เบอร์ติดต่อร้าน/โรงรีด",
    coilNo: " Coil no.",
    coilDescription: "Coil description",
    quantity: "Quantity",
    productGroup: "ผลิตภัณฑ์",
    usability: 'การใช้งาน',
    metre: 'เมตร',
    roof: 'หลังคา',
    wall: 'ผนัง',
    purlinGirt: 'แปหลังคา และ แปผนัง',
    truFrame: 'โครงหลังคา หรือ โครงผนัง',
    coolRoom: 'ผนังห้องเย็น',
    decking: 'แผ่นพื้นกึ่งสำเร็จ',
    airDucting: 'งานท่อ',
    length: 'ความยาว',
  },
  documentary: {
    breadcrumb1: 'หน้าหลัก',
    breadcrumb2: 'เอกสาร',
    title: 'เอกสาร',
    placeholder: {
      search: 'ค้นหา',
      selectStartDate: 'เลือกวันที่เริ่มต้น',
      selectEndDate: 'เลือกวันที่สิ้นสุด',
    },
    label: {
      date: 'วันที่',
      to: 'ถึง',
      selectedTransaction: 'การขายที่เลือก',
      ready: 'พร้อม',
    },
    selectOption: {
      all: 'All',
      salesTransactionID: 'Sales Transaction ID',
      documentID: 'Document ID',
      contractName: 'Contract Name',
      warrantyNo: 'Warranty No.',
      projectName: 'Project Name',
      phoneNumber: 'Phone Number',
    },
    table: {
      header: {
        select: 'เลือก',
        createDate: 'วันที่สร้าง',
        warrantyNo: 'เลขใบรับประกัน',
        salesTId: 'เลขที่การขาย',
        salesTransactionId: 'เลขที่การขาย',
        projectName: 'ชื่อโครงการ',
        customerName: 'ชื่อลูกค้า',
        productGroup: 'กลุ่มสินค้า',
        mobileNo: 'เบอร์มือถือ',
        status: 'สถานะ',
        action: 'ตัวเลือก',
      },
    },
    message: {
      noResult: 'ไม่มีผลการค้นหา',
    },
  },
  documentTemplate: {
    breadcrumb1: 'Home',
    breadcrumb2: 'Warranty Management ',
    breadcrumb3: 'Document Template ',
    title: 'Document template',
    placeholderSearch: 'Search document category code or name',
    button: {
      buttonSearch: 'Search',
      buttonClose: 'Close'
    },
    table: {
      header: {
        category: 'Categoty',
        code: 'Code',
        name: 'Name',
        m3ProductGroup: 'M3 Product Group',
        view: 'View'
      }
    },
    modal: {
      header: {
        title: 'M3 Product Group Mapping : ',
        productCertificate: 'Certificate : [documentName]',
        productDocumentary: 'Documentary : [documentName]',
        documentary: 'Documentary',
      },
      placeholderSearchM3: 'Search M3 Product Group Code or Name',
      table: {
        header: {
          no: 'No.',
          m3ProductGroupCode: 'M3 Product Group Code',
          m3ProductGroupName: 'M3 Product Group Name'

        },
        label: {
          noM3ProductGroup: 'No M3 Product Group.'
        }
      }
    }
  },
  documentaryManage: {
    breadcrumb1: 'Home',
    breadcrumb2: 'Documentary',
    breadcrumb3: 'View Document',
    breadcrumb4: 'Generate Documentary',
    title: 'Documentary',
    titleGenerate: 'Documentary > Generate Documentary',
    sectionTitle: {
      productWarrantyDocumentary: 'Product Warranty & Documentary',
      documentary: 'Documentary',
    },
    placeholder: {
      search: 'Search document id or name',
    },
    label: {
      information: 'Information',
      documentary: 'Documentary',
    },
    table: {
      header: {
        document: 'Document',
        specifyCompanyName: 'Specify Company Name',
        createDate: 'Create Date',
        action: 'Action',
      },
    },
    message: {
      noDocument: 'No Document',
    },
  },
  rewards: {
    breadcrumb1: 'หน้าหลัก',
    breadcrumb2: 'ของรางวัล',
    title: 'ของรางวัล',
    subtitle: 'สารบัญของรางวัล',
    page: {
      goToPage: 'ไปที่หน้า',
      redemption: 'การแลกรางวัล',
      rewardRedemptionHistory: 'ประวัติการแลกของรางวัล',
      rewardStock: 'รายการของรางวัล',
      trueBluePointsDetail: 'รายละเอียดคะแนน',
    },
  },
  redemption: {
    breadcrumb1: 'หน้าหลัก',
    breadcrumb2: 'ของรางวัล',
    breadcrumb3: 'การแลกรางวัล',
    title: 'แลกรางวัล > การแลกรางวัล',
    form: {
      customerInfo: {
        title: 'รายละเอียดข้อมูลลูกค้า',
        customerBlank: 'เลือกลูกค้าที่จะแลกของ',
        buttonSelectCustomer: 'เลือกลูกค้า',
      }
    }
  },
  redemptionReward: {
    breadcrumb1: 'หน้าหลัก',
    breadcrumb2: 'ของรางวัล',
    breadcrumb3: 'การแลกรางวัล',
    breadcrumb4: 'รายการรางวัล',
    title: 'รางวัล > การรางวัล > รายการแลกของรางวัล',
    placeholder: 'ค้นหา เลขที่, ชื่อของรางวัล',
    buttonSearch: 'ค้นหา',
    table: {
      header: {
        id: 'เลขที่',
        rewards: 'ของรางวัล',
        inStock: 'จำนวนของที่มี',
        rewardPoint: 'คะแนนที่ใช้',
        unit: 'จำนวน',
        trueBluePoints: 'คะแนนทรูบลู',
        usedPoints: 'คะแนนที่ใช้ไป',
      },
      footer: {
        usedPoints: 'คะแนนที่ใช้ไป',
        point: 'คะแนน',
        yourTotalPoints: 'คะแนนที่มีอยู่',
      }
    },
    informations: {
      yourTotalTrueBluePoints: 'คะแนนที่มีอยู่',
      usedtheTrueBluePoints: 'คะแนนที่ใช้ไป',
      points: 'คะแนน',
      orderBy: 'การเรียงลำดับ',
      selectOrderBy: 'เลือกการเรียงลำดับ',
    },
  },
  redemptionRewardHistory: {
    breadcrumb1: 'หน้าหลัก',
    breadcrumb2: 'ของรางวัล',
    breadcrumb3: 'รายการแลกรางวัล',
    title: 'ของรางวัล > ประวัติการแลกของรางวัล',
    selectOption: {
      mostExchangeReward: 'ของรางวัลที่มีการถูกแลกคะแนนมากที่สุด',
      mostExchangeCustomer: 'ลูกค้าที่ทำการแลกคะแนนมากที่สุด',
      redemptionCode: 'รหัสการแลกของรางวัล',
      customerName: 'ชื่อลูกค้า',
      phoneNo: 'เบอร์โทร',
    },
    placeholder: 'ค้นหา',
    date: 'วันที่',
    dateTo: 'ถึง',
    selectStartDate: 'เลือกวันที่เริ่มต้น',
    selectEndDate: 'เลือกวันที่สิ้นสุด',
    buttonSearch: 'ค้นหา',
  },
  rewardStock: {
    breadcrumb1: 'หน้าหลัก',
    breadcrumb2: 'ของรางวัล',
    breadcrumb3: 'การแลกรางวัล',
    breadcrumb4: 'รายการของรางวัล',
    title: 'ของรางวัล > รายการของรางวัล',
    placeholder: 'ค้นหา เลขที่, ชื่อของรางวัล',
    buttonSearch: 'ค้นหา',
  },
  pointDetail: {
    breadcrumb1: 'หน้าหลัก',
    breadcrumb2: 'ของรางวัล',
    breadcrumb3: 'รายละเอียดคะแนน',
    title: 'ของรางวัล > รายละเอียดคะแนน',
    placeholder: 'ค้นหา เลขที่, ชื่อ, เบอร์โทร, อีเมลล์',
    buttonSearch: 'ค้นหา',
  },
  pointDetailManage: {
    breadcrumb1: 'หน้าหลัก',
    breadcrumb2: 'ของรางวัล',
    breadcrumb3: 'รายละเอียดคะแนน',
    title: 'ของรางวัล > รายละเอียดคะแนน',
    purchaseHistoryTitle: 'ประวัติการสั่งซื้อ',
  },
  inventoryIndexes: {
    breadcrumb1: 'หน้าหลัก',
    breadcrumb2: 'คลังสินค้า และการขายคอยล์',
    title: 'คลังสินค้า',
    subtitle: 'สารบัญสินค้าคงคลัง',
    page: {
      goToPage: 'ไปหน้าที่',
      inventoryManagement: 'จัดการสินค้าคงคลัง',
      coilTransfer: 'การขายคอยล์ออก',
      coilTransferHistory: 'ประวัติการโอนคอยล์',
      profileTransfer: 'Profile Transfer',
      profileTransferHistory: 'Profile Transfer History',
      profileMapping: 'Profile Mapping',
      profileMappingHistory: 'Profile Mapping History',
    },
  },
  inventory: {
    breadcrumb1: 'หน้าหลัก',
    breadcrumb2: 'คลังสินค้า และการขายคอยล์',
    breadcrumb3: 'จำนวนสินค้า',
    title: 'คลังสินค้า > จำนวนสินค้า',
    placeholder: 'ค้นหาประเภทสินค้า',
    buttonSearch: 'ค้นหา',
  },
  productDetail: {
    breadcrumb1: 'หน้าหลัก',
    breadcrumb2: 'คลังสินค้า และการขายคอยล์',
    breadcrumb3: 'จำนวนสินค้า',
    breadcrumb4: 'รายละเอียดสินค้า',
    title: 'คลังสินค้า > จำนวนสินค้า > รายละเอียดสินค้า',
    placeholder: 'ค้นหา ชื่อคอยล์, เลขที่คอยล์, สี',
    placeholderForToko: 'Search Profile Type/Product Group',
    buttonSearch: 'ค้นหา',
    table: {
      header: {
        coilName: 'ชื่อคอยล์',
        coilID: 'เบอร์คอยล์',
        thickness: 'ความหนา',
        thicknessBMT: 'ความหนา (BMT)',
        createDate: 'สร้างเมื่อวันที่',
        lifetime: 'อายุ',
        color: 'สี',
        weightBalance: 'น้ำหนักคงเหลือ (กก)',
        weightBalanceAbbreviation: 'น้ำหนักคงเหลือ (กก)',
        lengthBalance: 'ความยาวคงเหลือ (ม)',
        lengthBalanceAbbreviation: 'ความยาวคงเหลือ (ม)',
        action: 'ตัวเลือก',
        profileType: 'Profile Type',
        brandProductGroup: 'Product Group',
        quantity: 'ปริมาณ',
        length: 'ความยาว (เมตร)',
        description: 'รายละเอียด',
      },
      action: {
        buttonEditLength: 'แก้ไขความยาว',
      }
    }
  },
  productDetailManage: {
    breadcrumb1: 'หน้าหลัก',
    breadcrumb2: 'คลังสินค้า และการขายคอยล์',
    breadcrumb3: 'จำนวนสินค้า',
    breadcrumb4: 'รายละเอียดสินค้า',
    breadcrumb5: 'คลังสินค้า และการปรับแก้',
    title: 'คลังสินค้า > จำนวนสินค้า > รายละเอียดสินค้า > คลังสินค้า และการปรับแก้',
    form: {
      coilInformation: {
        title: 'รายละเอียดสินค้า',
        coilName: 'ชื่อคอยล์',
        coilID: 'เบอร์คอยล์',
        lengthBalance: 'ความยาวคงเหลือ (ม)',
        weightBalance: 'น้ำหนักคงเหลือ (กก)',
        titleProduct: `ปรับแต่งสินค้า`,
        reasonChange: {
          title: 'เหตุผล',
          cover: 'ทำครอบ',
          cutOff: 'ตัดทิ้ง',
          salesFlat: 'ขายแผ่นเรียบ',
          other: 'อิ่นๆ',
        },
        profileType: 'Profile Type',
        productGroup: 'Product Group',
        quantity: 'ปริมาณ',
      }
    }
  },
  profileMapping: {
    breadcrumb1: 'หน้าหลัก',
    breadcrumb2: 'คลังสินค้า และการขายคอยล์',
    breadcrumb3: 'Profile Mapping',
    breadcrumb4: 'Profile Detail',
    title_1: 'Inventory > Profile Mapping > Select Hardware shop or Toko',
    title_2: 'Inventory > Profile Mapping > Product Detail',
    placeholder: 'Search by Profile Type/Group',
    buttonSearch: 'ค้นหา',
    form: {
      shopInfo: {
        title: 'Hardware shop / Toko info.',
        shopInfoBlank: 'Select Hardware / Toko Shop for profile mapping.',
        buttonSelect: 'Select Hardware / Toko Shop',
        message: 'Coil cannot be used in profile mapping, please select new coil.',
        coilValue: 'Plese Check coil width values.',
        table: {
          header: {
            profileType: 'Profile Type',
            group: 'Brand / Product Group',
            quantity: 'จำนวน',
            length: 'Length (m.)',
            action: 'ตัวเลือก',
          }
        }
      }
    }
  },
  coilTransfer: {
    breadcrumb1: 'หน้าหลัก',
    breadcrumb2: 'คลังสินค้า และการขายคอยล์',
    breadcrumb3: 'การขายคอยล์ออก',
    title: 'คลังสินค้า และการขายคอยล์ > การขายคอยล์ออก',
    form: {
      adAndToko: {
        title: 'Hardware / Toko Shop Info',
        blank: 'Select or create Hardware / Toko Shop for create coil transfer.',
        buttonCreate: 'Request Create Hardware / Toko Shop',
        buttonSelect: 'Select Hardware / Toko Shop',
      },
      customer: {
        title: 'รายละเอียดข้อมูลลูกค้า',
        blank: 'ร้องขอการสร้างหรือเลือกร้านออร์โธไรซ์ ดีลเลอร์ หรือ โรงรีดอื่นๆสำหรับการโอนคอยล์',
        buttonCreate: 'ขอเพิ่มชื่อโรงรีด',
        buttonSelect: 'เลือกโรงรีดที่ขายคอยล์ไป',
      },
    },
  },
  profileTransfer: {
    breadcrumb1: 'หน้าหลัก',
    breadcrumb2: 'คลังสินค้า และการขายคอยล์',
    breadcrumb3: 'Profile Transfer',
    title: 'Inventory > Profile Transfer',
    form: {
      adAndToko: {
        title: 'Hardware / Toko Shop Info',
        blank: 'Select or create Hardware / Toko Shop for create coil transfer.',
        buttonCreate: 'Request Create Hardware / Toko Shop',
        buttonSelect: 'Select Hardware / Toko Shop',
      },
      customer: {
        title: 'รายละเอียดข้อมูลลูกค้า',
        blank: 'ร้องขอการสร้างหรือเลือกร้านออร์โธไรซ์ ดีลเลอร์ หรือ โรงรีดอื่นๆสำหรับการโอนคอยล์',
        buttonCreate: 'ร้องขอการสร้างออร์โธไรซ์ ดีลเลอร์ หรือ โรงรีดอื่นๆ',
        buttonSelect: 'เลือกร้านออร์โธไรซ์ ดีลเลอร์ หรือ โรงรีดอื่นๆ',
      },
    }
  },
  coilTransaction: {
    breadcrumb1: 'หน้าหลัก',
    breadcrumb2: 'คลังสินค้า และการขายคอยล์',
    breadcrumb3: 'การขายคอยล์ออก',
    breadcrumb4: 'โอนคอยล์',
    title: 'คลังสินค้า และการขายคอยล์ > การขายคอยล์ออก > โอนคอยล์',
    title_2: 'Inventory > Profile Transfer > Profile Transaction',
    form: {
      ad: {
        title: 'ข้อมูลดีลเลอร์',
        buttonSelect: 'เลือกดีลเลอร์',
      },
      toko: {
        title: 'Toko Info',
      },
      latestCoilTransfer: {
        title: 'รายการโอนคอยล์ล่าสุด',
        table: {
          header: {
            date: 'วันที่',
            orderNo: 'เลขที่คำสั่งซื้อ',
            coilName: 'ชื่อคอยล์',
            coilID: 'เบอร์คอยล์',
            transferAmount: 'ความยาวที่โอน (ม)',
            weight: 'น้ำหนัก (ตัน)',
            salesperson: 'พนักงานขาย',
            profileType: 'Profile Type',
            productGroup: 'Product Group',
            quantity: 'ปริมาณ',
            length: 'ความยาว (เมตร)',
            lengthPerUnit: 'เมตร (เมตร)',
            tranferFrom: 'Transfer from',
            tranferTo: 'Tranfer to',
            status: 'Status',
          }
        },
      },
      coilTransferSummary: {
        title: 'สรุปการโอนคอยล์',
        blank: 'เลือกคอลย์ที่ต้องการโอน',
        buttonSelectProduct: 'เลือกสินค้า',
        table: {
          header: {
            coilID: 'เบอร์คอยล์',
            coilName: 'ชื่อคอยล์',
            length: 'ความยาวที่โอน (ม)',
            weight: 'น้ำหนัก (ตัน)',
          }
        },
      },
    }
  },
  profileTransaction: {
    breadcrumb1: 'หน้าหลัก',
    breadcrumb2: 'คลังสินค้า และการขายคอยล์',
    breadcrumb3: 'Profile Transfer',
    breadcrumb4: 'Profile Transaction',
    title: 'Inventory > Profile Transfer > Profile Transaction',
    form: {
      ad: {
        title: 'ข้อมูลดีลเลอร์',
        buttonSelect: 'เลือกดีลเลอร์',
      },
      toko: {
        title: 'Toko Info',
      },
      latestProfileTransfer: {
        title: 'รายการโอนคอยล์ล่าสุด',
        table: {
          header: {
            date: 'วันที่',
            orderNo: 'เลขที่คำสั่งซื้อ',
            coilName: 'ชื่อคอยล์',
            coilID: 'เบอร์คอยล์',
            transferAmount: 'ความยาวที่โอน (ม)',
            weight: 'น้ำหนัก (ตัน)',
            salesperson: 'พนักงานขาย',
            profileType: 'Profile Type',
            productGroup: 'Product Group',
            quantity: 'ปริมาณ',
            length: 'ความยาว (เมตร)',
            lengthPerUnit: 'ความยาว (เมตร)',
          }
        },
      },
      profileTransferSummary: {
        title: 'สรุปการโอนคอยล์',
        blank: 'เลือกคอลย์ที่ต้องการโอน',
        buttonSelectProduct: 'เลือกสินค้า',
        table: {
          header: {
            coilID: 'เบอร์คอยล์',
            coilName: 'ชื่อคอยล์',
            quantity: 'ปริมาณ',
            length: 'ความยาวที่โอน (ม)',
            weight: 'น้ำหนัก (ตัน)',
          }
        },
      },
    }
  },
  coilTransactionManage: {
    breadcrumb1: 'หน้าหลัก',
    breadcrumb2: 'คลังสินค้า และการขายคอยล์',
    breadcrumb3: 'การโอนคอลย์',
    breadcrumb4: 'โอนคอยล์',
    title: 'คลังสินค้า และการขายคอยล์ > การขายคอยล์ออก > โอนคอยล์',
    placeholder: 'เลือกสินค้า',
    placeholderThickness: 'เลือกความหนา',
    placeholderCoilID: 'เลือกเบอร์คอยล์',
    placeholderColor: 'เลือกสี',
    table: {
      header: {
        coilID: 'เบอร์คอยล์',
        coilName: 'ชื่อคอยล์',
        thickness: 'ความหนา',
        color: 'สี',
        aging: 'อายุ',
        lengthBalance: 'ความยาวคงเหลือ (ม)',
        remainedWeight: 'น้ำหนักที่เหลือ (กก.)',
        remainedWeightAbbreviation: 'น้ำหนักที่เหลือ (กก.)',
        transferAmount: 'ความยาวที่โอน (ม)',
        allRemainingLength: 'ความยาวที่เหลือทั้งหมด',
      }
    }
  },
  profileTransactionManage: {
    breadcrumb1: 'หน้าหลัก',
    breadcrumb2: 'คลังสินค้า และการขายคอยล์',
    breadcrumb3: 'Profile Transfer',
    breadcrumb4: 'Profile Transaction',
    title: 'คลังสินค้า และการขายคอยล์ > Profile Transfer > Profile Transaction',
    placeholder: 'เลือกสินค้า',
    placeholderThickness: 'เลือกความหนา',
    placeholderCoilID: 'เลือกเบอร์คอยล์',
    placeholderColor: 'เลือกสี',
    table: {
      header: {
        coilID: 'เบอร์คอยล์',
        coilName: 'ชื่อคอยล์',
        profileType: 'ประเภท',
        thickness: 'ความหนา (BMT)',
        color: 'สี',
        quantity: 'Quantity',
        aging: 'อายุ',
        lengthBalance: 'ความยาวคงเหลือ (ม)',
        remainedWeight: 'น้ำหนักที่เหลือ (กก.)',
        remainedWeightAbbreviation: 'น้ำหนักที่เหลือ (กก.)',
        transferAmount: 'ความยาวที่โอน (ม)',
        allRemainingLength: 'ความยาวที่เหลือทั้งหมด',
      }
    }
  },
  coilTransferHistory: {
    breadcrumb1: 'หน้าหลัก',
    breadcrumb2: 'คลังสินค้า และการขายคอยล์',
    breadcrumb3_1: 'Profile Transfer History',
    breadcrumb3_2: 'Profile Mapping History',
    breadcrumb3_3: 'Profile Sold History',
    breadcrumb3_4: 'ประวัติการโอนคอยล์',
    title_1: 'คลังสินค้า และการขายคอยล์ > Profile Transfer History',
    title_2: 'คลังสินค้า และการขายคอยล์ > Profile Mapping History',
    title_3: 'คลังสินค้า และการขายคอยล์ > Profile Sold History',
    title_4: 'คลังสินค้า และการขายคอยล์ > ประวัติการโอนคอยล์',
    selectOption: {
      all: 'ทั้งหมด',
      orderNo: 'เลขที่คำสั่งซื้อ',
      transferFrom: 'Transfer from',
      transferTo: 'Transfer to',
      coilID: 'เบอร์คอยล์',
      pending: 'Pending',
      transferred: 'Transferred',
      received: 'Received',
    },
    placeholder: 'ค้นหา',
    date: 'วันที่',
    dateTo: 'ถึง',
    selectStartDate: 'เลือกวันที่เริ่มต้น',
    selectEndDate: 'เลือกวันที่สิ้นสุด',
    buttonSearch: 'ค้นหา',
    label: {
      category: 'Category',
      status: 'Status'
    },
  },
  profileTransferHistory: {
    breadcrumb1: 'หน้าหลัก',
    breadcrumb2: 'คลังสินค้า และการขายคอยล์',
    breadcrumb3_1: 'Profile Transfer History',
    breadcrumb3_2: 'Profile Mapping History',
    breadcrumb3_3: 'Profile Sold History',
    breadcrumb3_4: 'Profile Transfer History',
    title_1: 'สินค้าคงคลัง > Profile Transfer History',
    title_2: 'สินค้าคงคลัง > Profile Mapping History',
    title_3: 'สินค้าคงคลัง > Profile Sold History',
    title_4: 'สินค้าคงคลัง > Profile Transfer History',
    selectOption: {
      all: 'ทั้งหมด',
      orderNo: 'เลขที่คำสั่งซื้อ',
      companyName: 'ชื่อบริษัท',
      phoneNumber: 'เบอร์โทร',
      coilID: 'เบอร์คอยล์',
    },
    placeholder: 'ค้นหา',
    date: 'วันที่',
    dateTo: 'ถึง',
    selectStartDate: 'เลือกวันที่เริ่มต้น',
    selectEndDate: 'เลือกวันที่สิ้นสุด',
    buttonSearch: 'ค้นหา',
  },
  profileMappingHistory: {
    breadcrumb1: 'Home',
    breadcrumb2: 'Inventory',
    breadcrumb3: 'Profile Mapping History',
    title: 'Inventory > Profile Mapping History',
    selectOption: {
      all: 'All',
      orderNo: 'Order No.',
      companyName: 'Company Name',
      phoneNumber: 'Phone Number',
      profileID: 'Profile ID',
    },
    placeholder: 'Search',
    date: 'วันที่',
    dateTo: 'ถึง',
    selectStartDate: 'เลือกวันที่เริ่มต้น',
    selectEndDate: 'เลือกวันที่สิ้นสุด',
    buttonSearch: 'ค้นหา',
  },
  adAndRfManage: {
    breadcrumb1: 'หน้าหลัก',
    breadcrumb2: 'คลังสินค้า และการขายคอยล์',
    breadcrumb3: 'การโอนคอลย์',
    breadcrumb4_1: 'Request Create Hardware/Toko Shop',
    breadcrumb4_2: 'Request Create Authorised Dealer/Roll Former',
    title_1: 'Request Create Hardware/Toko Shop',
    title_2: 'Request Create Authorised Dealer/Roll Former',
    step: {
      regis: 'ลงทะเบียน',
    },
    form: {
      regis: {
        title: 'การลงทะเบียน',
        customerType: 'ประเภทลูกค้า',
        checkbox: {
          rollFormer: 'โรงรีด',
          authorisedDealer: 'ดีลเลอร์',
        }
      }
    }
  },
  report: {
    breadcrumb1: 'หน้าหลัก',
    breadcrumb2: 'รายงาน',
    breadcrumb3: 'รายงานการขาย',
    title: 'รายงาน > รายงานการขาย',
    date: 'วันที่',
    dateTo: 'ถึง',
    selectStartDate: 'เลือกวันที่เริ่มต้น',
    selectEndDate: 'เลือกวันที่สิ้นสุด',
    buttonSearch: 'ค้นหา',
    totalSalesReportInformation: 'รายงานการซื้อ-ขาย : ข้อมูล ',
    totalSalesReportTotalLength: 'รายงานการซื้อ-ขาย : จำนวนความยาว',
    totalSalesReportCustomer: 'รายงานการซื้อ-ขาย : จำนวนลูกค้า',
    buyIn: 'รายการซื้อเข้า',
    sellOut: 'รายการขายออก',
    mappingIn: 'Mapping in',
    mappingOut: 'Mapping Out',
    transferIn: 'Transfer in',
    transferOut: 'Transfer Out',
    averageSales: 'การขายเฉลี่ย',
    noOfCustomers: 'จำนวนลูกค้า',
    rewardPoint: 'Rewards points',
    warranty: 'Warranty',
    revenue: 'Revenue',
    point: 'Points',
    ready: 'Ready',
    notReady: 'Not Ready',
    volume: 'จำนวน(ตัน)',
    length: 'จำนวนความยาว(ม.)',
    quantity: 'Quantity',
    monthly: 'รายเดือน',
    daily: 'รายวัน',
    total: 'รวมทั้งหมด',
    active: 'สถานะยังเคลื่อนไหว',
    form: {
      inOutStock: {
        title: 'รายงานปริมาณซื้อเข้า-ขายออก :',
        sellIn: 'ปริมาณซื้อเข้า',
        salesOut: 'ปริมาณขายออก',
        stock: 'สินค้าคงคลัง',
      },
      inOutTransfer: {
        title: 'In-Out Transfer Report :',
        transferIn: 'Transfer in',
        transferOut: 'Transfer out',
      },
      product: {
        title: 'รายการสินค้า',
        salesIn: 'สินค้าซื้อเข้า',
        salesOut: 'สินค้าขายออก',
        report: 'รายงาน',
      },
      topMember: {
        title: 'top 10 สมาชิกที่มีแต้มและยอดซื้อ(ม.) สะสมสูงสุด',
        subTitle: 'รายชื่อสมาชิก',
        volume: 'จำนวน(ตัน)',
        length: 'จำนวนความยาว(ม.)',
      },
      topSalesPersons: {
        title: 'Top 10 พนักงานขายที่มีแต้มและยอดขาย(ม.) สะสมสูงสุด',
        subTitle: 'รายชื่อพนักงานขาย',
        volume: 'จำนวน(ตัน',
        length: 'จำนวนความยาว(ม.)',
      },
      rewardReport: {
        title: 'รานงานการแลกรางวัล',
        subTitle_1: 'ของรางวัลยอดนิยม',
        subTitle_2: 'ลูกค้าที่แลกของรางวัลมากที่สุด',
      },
    }
  },
  settings: {
    breadcrumb1: 'หน้าหลัก',
    breadcrumb2: 'ปรับแต่ง',
    title: 'ปรับแต่ง',
    subtitle: 'สารบัญปรับแต่ง',
    page: {
      goToPage: 'ไปที่หน้า',
      adInfo: 'ข้อมูลดีลเลอร์',
      tnc: 'เงื่อนไข',
      contactUs: 'ติดต่อเรา',
      help: 'ช่วยเหลือ',
    },
  },
  adInfo: {
    breadcrumb1: 'หน้าหลัก',
    breadcrumb2: 'ปรับแต่ง',
    breadcrumb3: 'ข้อมูลดีลเลอร์',
    breadcrumb3_2: 'Sales Person',
    title: 'ปรับแต่ง > ข้อมูลดีลเลอร์',
    title_2: 'ปรับแต่ง > Sales Person',
    form: {
      info: {
        title: 'ข้อมูลดีลเลอร์',
      },
      changePassword: {
        title: 'เปลี่ยนรหัสผ่าน',
        changeYourPassword: 'เปลี่ยนรหัสผ่านของคุณ',
        passwordPolicies: 'ข้อกำหนดการตั้งรหัสผ่าน',
        passwordMinimum_1: 'จำนวนรหัสผ่านขั้นต่ำ 8 ตัว',
        passwordMinimum_2: 'อย่างน้อย 1 ตัวอักษร',
        passwordMinimum_3: 'อย่างน้อย 1 ตัวเลข',
      }
    }
  },
  tnc: {
    breadcrumb1: 'หน้าหลัก',
    breadcrumb2: 'ปรับแต่ง',
    breadcrumb3: 'เงื่อนไข',
    title: 'ปรับแต่ง > เงื่อนไข',
    form: {
      tnc: {
        title: 'เงื่อนไข',
      },
    }
  },
  contactUs: {
    breadcrumb1: 'หน้าหลัก',
    breadcrumb2: 'ปรับแต่ง',
    breadcrumb3: 'ติดต่อเรา',
    title: 'ปรับแต่ง > ติดต่อเรา',
    form: {
      contactUs: {
        title: 'ติดต่อเรา',
        hotline: 'เบอร์ติดต่อ',
        phoneNo: 'เบอร์โทร',
        fax: 'แฟกซ์',
        website: 'เว็บไซต์',
      },
    }
  },
  help: {
    breadcrumb1: 'หน้าหลัก',
    breadcrumb2: 'ปรับแต่ง',
    breadcrumb3: 'ช่วยเหลือ',
    title: 'ปรับแต่ง > ช่วยเหลือ',
    form: {
      contactUs: {
        title: 'ติดต่อเรา',
        hotline: 'เบอร์ติดต่อ',
        phoneNo: 'เบอร์โทร',
        fax: 'แฟกซ์',
        website: 'เว็บไซต์',
      },
    },
    label: {
      coilTranfer: 'Coil Transfer',
      stepGuildline: 'Steps and Guideline'
    },
    button: {
      buttonSubmit: 'Submit',
    },
    tab: {
      title: 'Please enter information to report coil transfer issue',
      label: {
        coilId: 'Coil ID',
        reportProblem: 'Report Problem',
      },
      field: {
        placeholderInsertCoilId: 'Insert Coil ID',
        placeholderInsertProblem: 'Insert Problem',
      }
    }
  },
  salesPerson: {
    breadcrumb1: 'หน้าหลัก',
    breadcrumb2: 'ปรับแต่ง',
    breadcrumb3: 'พนักงานขาย',
    breadcrumb4: 'เพิ่มพนักงานขาย',
    breadcrumb5: 'แก้ไขพนักงานขาย',
    titles: {
      salesPerson: 'ปรับแต่ง > พนักงานขาย',
      addNewSales: 'ปรับแต่ง > พนักงานขาย > เพิ่มพนักงานขาย',
      editSales: 'ปรับแต่ง > พนักงานขาย > แก้ไขพนักงานขาย',
    },
    buttons: {
      addNewSales: 'เพิ่มพนักงานขาย',
      export: 'นำออกไฟล์',
    },
    tables: {
      salesPerson: {
        number: 'ลำดับ',
        name: 'ชื่อ',
        nickName: 'ชื่อเล่น',
        jobPosition: 'ตำแหน่ง',
        mobile: 'เบอร์โทร',
        points: 'คะแนน',
        actions: 'ตัวเลือก',
      }
    },
    tooltips: {
      viewInformation: 'ดูข้อมูล',
      edit: 'แก้ไข',
    },
    options: {
      incentiveSales: 'ขายแบบจูงใจขายอะไรก็สำเร็จ',
      disputeClosing: 'การปิดข้อโต้แย้งและการปิดการขายแบบมืออาชีพ',
      professionalNegotiating: 'การเจรจาต่อรองแบบมืออาชีพ',
      basicKnowledge: 'ความรู้พื้นฐานเกี่ยวกับผลิตภัณฑ์',
      courseForExperts: 'หลักสูตรสำหรับผู้เชี่ยวชาญในการใช้ผลิตภัณฑ์',
    },
    headings: {
      salesInformation: 'ข้อมูลพนักงานขาย',
      blueScopeTraining: 'การอบรมโดยบลูสโคป',
      salesSkill: 'หลักสูตรอบรมทักษะในการขาย',
      productKnowledge: 'หลักสูตรอบรมทักษะความรู้เกี่ยวกับผลิตภัณฑ์',
      specificCourse: 'หลักสูตรเฉพาะ',
      independentCourse: 'หลักสูตรอิสระ',
      maximum5: '(ไม่เกิน 5)',
    },
  },
  uploadTransactionIndexes: {
    breadcrumb1: 'หน้าหลัก',
    breadcrumb2: 'อัพโหลดการขาย',
    title: 'อัพโหลดการขาย',
    subtitle: 'สารบัญอัพโหลดการขาย',
    page: {
      goToPage: 'ไปที่หน้า',
      uploadTransactionManagement: 'จัดการอัพโหลดการขาย',
      uploadTransactionHistory: 'ประวัติอัพโหลดการขาย',
    },
  },
  uploadTransaction: {
    breadcrumb1: 'หน้าหลัก',
    breadcrumb2: 'อัพโหลดการขาย',
    breadcrumb3: 'จัดการอัพโหลดการขาย',
    title: 'อัพโหลดการขาย',
    step: {
      validation: {
        title: 'ตรวจสอบการขาย',
      },
      information: {
        title: 'ข้อมูลการขาย',
      },
    },
    form: {
      validation: {
        title: 'ตรวจสอบการขาย',
        placeholderUploadTransaction: 'ลากและวางไฟล์หรือเลือกไฟล์',
        buttonRemoveFile: 'ยกเลิกไฟล์',
        buttonValidateTransaction: 'ตราจสอบการขาย',
        buttonDownloadTemplate: 'ดาวน์โหลดต้นแบบ',
        checkingInProcess: 'อยู่ระหว่างการตรวจสอบ',
        formatFileIncorrect: 'ประเภทไฟล์ไม่ถูกต้อง โปรดอัพโหลดไฟล์นามสกุล csv',
      },
      information: {
        title: 'ข้อมูลการขาย',
        table: {
          header: {
            status: 'สถานะ',
            saleperson: 'เซล',
            phone: 'เบอร์โทร',
            email: 'อีเมล์',
            coilId: 'เบอร์คอยล์',
            lengthSold: 'ความยาว',
            projectName: 'โครงการ',
            homeowner: 'เจ้าของ',
            purchaseDate: 'วันที่ซื้อ',
            purchaseValue: 'ค่าที่ซื้อ',
            invoiceNumber: 'เลขที่ใบสั่ง',
            invoiceLine: 'ไลน์ใบสั่ง',
            error: 'ข้อผิด',
          },
        },
        buttonUploadTransaction: 'อัพโหลดการขาย',
        uploadingInProcess: 'อยู่ระหว่างการอัพโหลด',
        modal: {
          title: 'รายละเอียดความผิด',
        },
        uploadTransactionSuccess: 'อัพโหลดการขายเรียบร้อย',
      },
    },
  },
  uploadTransactionHistory: {
    breadcrumb1: 'หน้าหลัก',
    breadcrumb2: 'อัพโหลดการขาย',
    breadcrumb3: 'ประวัติอัพโหลดการขาย',
    title: 'ประวัติอัพโหลดการขาย',
    form: {
      placeholderSearchFileName: 'ค้นหาชื่อไฟล์',
      placeholderSearchSalesTransaction: 'ค้นหาเลขที่ใบสั่ง, ชื่อโครงการ',
      buttonSearch: 'ค้นหา',
    },
    table: {
      header: {
        id: 'ไอดี',
        fileName: 'ชื่อไฟล์',
        uploadedDate: 'วันที่อัพโหลดไฟล์',
        actions: 'ตัวเลือก',
        buttonDetail: 'ข้อมูล',
        status: 'สถานะ',
        saleperson: 'เซล',
        phone: 'เบอร์โทร',
        email: 'อีเมล์',
        coilId: 'เบอร์คอยล์',
        lengthSold: 'ความยาว',
        projectName: 'โครงการ',
        homeowner: 'เจ้าของ',
        purchaseDate: 'วันที่ซื้อ',
        purchaseValue: 'ค่าที่ซื้อ',
        invoiceNumber: 'เลขที่ใบสั่ง',
        invoiceLine: 'ไลน์ใบสั่ง',
        error: 'ข้อผิด',
      },
    },
  },
  container: {
    customerContentInfo: {
      title: 'รายละเอียดข้อมูลลูกค้า',
      name: 'ชื่อ',
      trueBluePoints: 'คะแนนทรูบลู',
      point: 'คะแนนใช้ไปทั้งสิ้น',
      nickname: 'ชื่อเล่น',
      phoneNo: 'เบอร์โทร',
      customerType: 'ประเภทลูกค้า',
      buttonSelectCustomer: 'เลือกลูกค้า',
    },
    customerContactInfo: {
      title: 'ข้อมูลการติดต่อ',
    },
    customerPointTable: {
      table: {
        header: {
          id: 'เลขที่',
          customerName: 'ชื่อลูกค้า',
          phoneNo: 'เบอร์โทร',
          email: 'อีเมล์',
          tbPoints: 'คะแนนทรูบลู',
          action: 'ตัวเลือก',
        },
        action: {
          buttonViewDetail: 'ดูรายละเอียด',
        }
      }
    },
    customerPointDetail: {
      title: 'รายละเอียดข้อมูลลูกค้า',
      name: 'ชื่อ',
      nickname: 'ชื่อเล่น',
      phoneNo: 'เบอร์โทร',
      customerType: 'ประเภทลูกค้า',
      currentTrueBluePoints: 'คะแนนคงเหลือปัจจุบัน',
      level: 'เลเวล',
      point: 'คะแนน',
      expiringDate: 'วันที่คะแนนหมดอายุ',
      tbPoints: 'คะแนนทรูบลู',
      trained: 'การอบรม',
    },
    latestSaleTransaction: {
      titleSaleTransaction: 'รายการขายที่ผ่านมา',
      titleOrder: 'สรุปรายการขาย',
      contentBlank: 'เลือกสินค้าที่ต้องการขาย',
      buttonSelectProduct: 'เลือกสินค้า',
      hintDeleteTransaction: 'รายการขายสามารถลบได้ภายใน 7 วันหลังจากถูกสร้าง และการดำเนินการใด ๆ หลังจากช่วงเวลาดังกล่าวโปรดติดต่อ Bluescope',
      table: {
        header: {
          date: 'วันที่',
          saleTransactionID: 'เลขที่การขาย',
          coilName: 'ชื่อคอยล์',
          coilID: 'เบอร์คอยล์',
          order: 'ความยาว (ม.)',
          trueBluePoints: 'คะแนนทรูบลู',
          salesperson: 'พนักงานขาย',
          profileType: 'Profile Type',
          productGroup: 'Product group',
          length: 'Length',
          orderQuantity: 'Order (Quantity)',
        },
      }
    },
    preTransactionList: {
      titlePreTransactionList: 'Pre-Transaction List (To manage Pre-Transaction to Sales Order please select Product group item.)',
      table: {
        header: {
          date: 'Date',
          preTransactionID: 'Pre-Transaction ID',
          productGroup: 'Product group',
          projectName: 'Project Name',
          companyName: 'Company Name',
          salesOrder: 'Sales Orders',
          action: 'Action'
        }
      },
    },
    orderSummary: {
      table: {
        header: {
          coilID: 'เบอร์คอยล์',
          coilName: 'ชื่อคอยล์',
          order: 'ความยาว (ม.)',
          earnPoints: 'คะแนนที่ได้รับ',
          profileType: 'Profile Type',
          productGroup: 'Product group',
          length: 'Length',
          orderQuantity: 'Order (Quantity)',
          revenue: 'Revenue',
        },
        footer: {
          revenue: 'Revenue',
          summaryGetPoints: 'Summary Get the TrueBlue Points',
          point: 'คะแนนใช้ไปทั้งสิ้น',
          yourTotalPoints: 'คะแนนใช้ไปทั้งสิ้น',
        }
      }
    },
    orderProduct: {
      title: 'การขาย > สร้างการขาย',
      selectProductGroup: 'เลือกสินค้า',
      selectThickness: 'เลือกความหนา',
      selectCoilID: 'เลือกเลขที่คอยล์',
      selectColor: 'เลือกสี',
      selectOption: {
        all: 'ทั้งหมด',
      },
      selectProfileType: 'Select Profile Type',
      selectLength: 'Select Length',
      table: {
        header: {
          coilID: 'เบอร์คอยล์',
          coilName: 'ชื่อคอยล์',
          thickness: 'ความหนา',
          thicknessBMT: 'ความหนา (BMT)',
          thicknessVt: 'Thickness',
          color: 'สี',
          aging: 'อายุ',
          lengthBalance: 'ความยาวคงเหลือ (ม.)',
          remainedWeight: 'ความหนักที่เหลือ (กก.)',
          length: 'ความยาว (ม.)',
          weight: 'ความหนัก (กก.)',
          orders: 'ความยาว (ม.)',
          profileType: 'Profile Type',
          productGroup: 'Product group',
          quantity: 'ปริมาณ',
          orderQuantity: 'Order (Quantity)',
          revenue: 'Revenue',
        }
      }
    },
    saleTransactionHistory: {
      hintDeleteTransaction: 'รายการขายสามารถลบได้ภายใน 7 วันหลังจากถูกสร้าง และการดำเนินการใด ๆ หลังจากช่วงเวลาดังกล่าวโปรดติดต่อ Bluescope',
      table: {
        header: {
          date: 'วันที่',
          stID: 'เลขที่การขาย',
          stIDAbbreviation: 'เลขที่การขาย',
          coilName: 'ชื่อคอยล์',
          coilID: 'เบอร์คอยล์',
          customerName: 'ชื่อลูกค้า',
          customerNameAbbreviation: 'C. Name',
          phoneNo: 'เบอร์โทร',
          order: 'ความยาว (ม.)',
          purchaseValue: 'Purchase value',
          purchaseValueAbbreviation: 'P. value',
          tbPoints: 'คะแนนทรูบลู',
          tbPointsAbbreviation: 'คะแนนทรูบลู',
          numberOfRewards: 'จำนวนของรางวัล',
          numberOfRewardsAbbreviation: 'จำนวนของรางวัล',
          salesperson: 'พนักงานขาย',
          action: 'ตัวเลือก',
          profileTypeAbbreviation: 'PF Type',
          productGroupAbbreviation: 'PD Group',
          profileType: 'Profile Type',
          productGroup: 'Product Group',
          length: 'Length',
          orderQuantity: 'Order (Quantity)',
          invoiceNumber: 'Invoice No.',
          invoiceLineItem: 'Invoice Line Item',
          revenue: 'Revenue',
          remainingLength: 'Remaining Length',
          pickQuantity: 'Pick Quantity',
        },
        action: {
          buttonDelete: 'ลบ',
        }
      }
    },
    warrantyList: {
      tableWarranty: {
        header: {
          select: 'เลือก',
          createDate: 'สร้างเมื่อวันที่',
          salesTransactionID: 'เลขที่การขาย',
          salesTransactionIDAbbreviation: 'เลขที่การขาย',
          contactName: 'ลูกค้า',
          customerName: 'ลูกค้า',
          warrantyNo: 'เลขใบรับประกัน',
          projectName: 'ชื่อโครงการ',
          mobileNo: 'เบอร์มือถือ',
          status: 'สถานะ',
          action: 'ตัวเลือก',
          invoiceNumber: 'Invoice No.',
          invoiceLineItem: 'Invoice Line Item',
          revenue: 'Revenue',
          purchaseDate: 'Purchase Date',
        },
        action: {
          viewMore: 'เพิ่มเติม',
        }
      },
      tableWarrantyDetail: {
        header: {
          coilID: 'เบอร์คอยล์',
          orderDate: 'วันที่สั่งซื้อ',
          orderNo: 'เลขที่คำสั่งซื้อ',
          coilName: 'ชื่อคอยล์',
          productGroup: 'กลุ่มสินค้า',
          order: 'ความยาว (ม.)',
          action: 'ตัวเลือก',
        },
        action: {
          viewProjectDetail: 'ดูรายละเอียดของโครงการ',
          buttonViewWarranty: 'ดูรายละเอียดของการรับประกัน',
          buttonEditWarranty: 'แก้ไขการรับประกัน',
        }
      },
    },
    warrantyTemplate: {
      breadcrumb1: 'หน้าหลัก',
      breadcrumb2: 'ตัวอย่างใบรับประกัน',
      title: 'ตัวอย่างใบรับประกัน',
      noWarrantyTemplate: 'ไม่มีตัวอย่างใบรับประกัน',
      noM3ProductCode: 'ไม่มี M3 Product Code',
      noM3ProductGroup: 'ไม่มี M3 Product Group',
      noM3ProductColor: 'ไม่มี M3 Product Color',
      filter: {
        label: {
          m3ProductCode: 'M3 Product Code',
          m3ProductGroup: 'M3 Product Group',
          m3ProductColor: 'M3 Product Color',
          searchTemplateName: 'ค้นหาชื่อตัวอย่างใบรับประกัน',
        },
      },
      table: {
        header: {
          no: 'No.',
          templateName: 'ชื่อตัวอย่างใบรับประกัน',
          m3ProductCode: 'M3 Product Code',
          m3ProductGroup: 'M3 Product Group',
          m3ProductColor: 'M3 Product Color',
          action: 'ตัวเลือก',
        },
        body: {
          codes: 'Code(s)',
          groups: 'Group(s)',
          colors: 'Color(s)',
        },
      },
      placeholder: {
        selectM3ProductCode: 'เลือก Code',
        selectM3ProductGroup: 'เลือก Group',
        selectM3ProductColor: 'เลือก Color',
        searchM3ProductCode: 'ค้นหา M3 Product Code',
        searchM3ProductGroup: 'ค้นหา M3 Product Group',
        searchM3ProductColor: 'ค้นหา M3 Product Color',
        searchTemplateName: 'ค้นหา Template Name',
      },
      modal: {
        title: {
          m3ProductCode: 'M3 Product Code',
          m3ProductGroup: 'M3 Product Group',
          m3ProductColor: 'M3 Product Color',
        },
        tabs: {
          project: 'โครงการ',
          residential: 'ที่พักอาศัย',
          templateEn: 'ตัวอย่างใบรับประกันอังกฤษ',
          templateTh: 'ตัวอย่างใบรับประกันไทย',
        },
      },
    },
    redemptionRewardHistory: {
      table: {
        header: {
          redID: 'เลขที่การแลกของรางวัล',
          redemptionID: 'เลขที่การแลกของรางวัล',
          rewards: 'ของรางวัล',
          customerID: 'หมายเลขลูกค้า',
          cusID: 'หมายเลขลูกค้า',
          customerName: 'ชื่อลูกค้า',
          cusName: 'ชื่อลูกค้า',
          phoneNo: 'เบอร์โทร',
          salesperson: 'พนักงานขาย',
          createDate: 'สร้างเมื่อวันที่',
          giftDate: 'วันที่แลกของรางวัล',
          gpuDate: 'G. Pu. date',
          deliveryDate: 'Delivery Date',
          tbPoints: 'คะแนนทรูบลู',
          tbPointsAbbreviation: 'U. Points',
          numberOfRewards: 'Number of Rewards',
          numberOfRewardsAbbreviation: 'N.O.R.',
          stockType: 'Stock Type',
          action: 'ตัวเลือก',
        },
        action: {
          buttonEdit: 'แก้ไข'
        }
      }
    },
    rewardStock: {
      table: {
        header: {
          id: 'เลขที่',
          rewards: 'ของรางวัล',
          inStock: 'จำนวนของที่มี',
          rewardPoint: 'คะแนนที่ใช้แลกรางวัล',
        },
      }
    },
    purchaseHistoryList: {
      table: {
        header: {
          salesDate: 'วันที่ทำรายการขาย',
          salesTransaction: 'รายละเอียดการขาย',
          coilDetail: 'รายละเอียดคอยล์',
          coilNumber: 'เลขที่คอยล์',
          purchaseLength: 'จำนวนความยาวที่ซื้อ(ม)',
          tbPoints: 'คะแนนทรูบลู',
          salesRepresentative: 'พนักงานขาย',
        },
      }
    },
    productQuantityList: {
      table: {
        header: {
          productGroup: 'กลุ่มสินค้า',
          noCoils: 'จำนวนคอยล์',
          weight: 'นน.โดยรวม (กก.)',
          length: 'ความยาวรวม (ม.)',
        },
        footer: {
          total: 'รวมทั้งหมด',
          buttonViewDetail: 'ดูรายละเอียด',
        }
      }
    },
    coilTransferTable: {
      table: {
        header: {
          id: 'เลขที่',
          adRF: 'รายชื่อ AD และโรงรีดอื่น',
          phoneNo: 'เบอร์โทร',
          email: 'อีเมล์',
          type: 'ประเภทสมาชิก',
          province: 'จังหวัด',
          select: 'เลือก',
        },
      }
    },
    profileTransferTable: {
      table: {
        header: {
          id: 'เลขที่',
          adRF: 'รายชื่อ AD และโรงรีดอื่น',
          phoneNo: 'เบอร์โทร',
          email: 'อีเมล์',
          type: 'ประเภทสมาชิก',
          province: 'จังหวัด',
          select: 'เลือก',
        },
      }
    },
    coilTransferAdInfo: {
      companyName: 'ชื่อบริษัท',
      customerType: 'ประเภทลูกค้า',
      phoneNo: 'เบอร์โทร',
    },
    profileTransferAdInfo: {
      companyName: 'ชื่อบริษัท',
      customerType: 'ประเภทลูกค้า',
      phoneNo: 'เบอร์โทร',
    },
    coilTransferHistoryTable: {
      table: {
        header: {
          date: 'วันที่',
          coilID: 'เบอร์คอยล์',
          coilName: 'ชื่อคอยล์',
          companyName: 'ชื่อบริษัท',
          profile: 'รูปลอน',
          profileID: 'Profile Transfer ID',
          quantity: 'จำนวน (no. of sheets or no. of sticks) – แผ่น',
          quantityAbbreviation: 'จำนวน ... แผ่น',
          length: 'ความยาว per sheet or per stick – ม.',
          lengthAbbreviation: 'ความยาว ... ม.',
          totalLength: 'ความยาวทั้งหมด ... ม',
          orderNo: 'เลขที่คำสั่งซื้อ',
          phoneNo: 'เบอร์โทร',
          transferAmount: 'ความยาวที่โอนทั้งหมด ... (ม)',
          lengthFullText: 'ความยาว (ม.)',
          weight: 'ความหนัก (กก.)',
          contactName: 'Contact Name',
          quantityUnit: 'Quantity / Unit',
          lengthUnit: 'Length / Unit',
          tranferFrom: 'Transfer from',
          tranferTo: 'Tranfer to',
          status: 'Status',
        }
      }
    },
    profileTransferHistoryTable: {
      table: {
        header: {
          date: 'วันที่',
          coilID: 'เบอร์คอยล์',
          coilName: 'ชื่อคอยล์',
          companyName: 'ชื่อบริษัท',
          profile: 'รูปลอน',
          quantity: 'จำนวน (no. of sheets or no. of sticks) – แผ่น',
          quantityAbbreviation: 'จำนวน ... แผ่น',
          length: 'ความยาว per sheet or per stick – ม.',
          lengthAbbreviation: 'ความยาว ... ม.',
          totalLength: 'ความยาวทั้งหมด ... ม',
          orderNo: 'เลขที่คำสั่งซื้อ',
          phoneNo: 'เบอร์โทร',
          transferAmount: 'ความยาวที่โอนทั้งหมด ... (ม)',
          lengthFullText: 'ความยาว (ม.)',
          weight: 'ความหนัก (กก.)',
          contactName: 'Contact Name',
          quantityUnit: 'Quantity / Unit',
          lengthUnit: 'Length / Unit',
          quantityProfile: 'Quantity',
          lengthProfile: 'Length',
        }
      }
    },
    cmsUserManual: {
      title: 'คู่มือการใช้งาน',
    }
  },
  component: {
    section: {
      coilDetail: {
        title: 'Product Detail',
        roofAndWall: 'Roof and Wall - Sq.m :',
        purlin: 'Purlin & Girt - m. :',
        truss: 'Truss & Frame - m. :',
        coolRoom: 'Cool room panel - Sq.m :',
        decking: 'Decking - Sq.m :',
        mmx: 'MMx :',
        mmsdf: 'MMsdf :',
        mmdf: 'MMdf :',
      }
    },
  },
  modal: {
    newsAndPromotion: {
      buttonBack: 'ย้อนกลับ'
    },
    info: {
      notice: 'ข้อสังเกต',
    },
    customerProfile: {
      title: 'ประวัติลูกค้า',
      placeholder: 'ค้นหา เลขที่, ชื่อ, เบอร์โทร, อีเมลล์',
      buttonSearch: 'ค้นหา',
      buttonCancel: 'ยกเลิก',
      buttonSave: 'บันทึก',
      buttonSelect: 'เลือก',
      table: {
        header: {
          id: 'เลขที่',
          name: 'ชื่อ',
          phoneNo: 'เบอร์โทร',
          email: 'อีเมล์',
          points: 'คะแนนทรูบลู',
          select: 'เลือก',
        },
        action: {
          details: 'รายละเอียด'
        }
      }
    },
    confirm: {
      title: 'ยืนยัน',
    },
    confirmDelete: {
      title: 'ยืนยันการลบข้อมูล',
    },
    warrantyChooseCategory: {
      title: 'เลือกประเภท',
      residential: 'ที่พักอาศัย',
      nonResidential: 'อาคาร/ตึก/โครงการ',
      residentialExample: 'เช่น บ้านพักอาศัย, บ้านเดี่ยว, คอนโด',
      nonResidentialExample1: 'เช่น โรงงาน, คลังสินค้า, ห้างสรรพสินค้า',
      nonResidentialExample2: 'รวมไปถึงโครงการที่เป็นหมู่บ้านจัดสรร',
    },
    share: {
      title: 'แชร์',
    },
    selectDateTime: {
      title: 'แก้ไขวันรับของรางวัล',
      placeholder: 'เลือกวันรับของรางวัล',
    },
    hardwareAndTokoShop: {
      title: 'Hardware / Toko Shop List',
      placeholder: 'Search by ID, Hardware/Toko Shop Name, Phone no., Email',
      buttonSearch: 'ค้นหา',
      table: {
        header: {
          id: 'เลขที่',
          name: 'Hardware / Toko Shop Name',
          phoneNo: 'เบอร์โทร',
          email: 'อีเมล์',
          select: 'เลือก',
        },
      }
    },
    adAndRf: {
      title: 'รายชื่อ AD และโรงรีดอื่นๆ',
      placeholder: 'ค้นหาโดย ID,รายชื่อ AD และโรงรีดอื่นๆ, เบอร์โทร, Email',
      buttonSearch: 'ค้นหา',
    },
    update: {
      title: 'การแจ้งเตือน',
      message: 'Trueblue เวอร์ชั่นใหม่ ',
      buttonCancel: 'ยกเลิก',
      buttonSubmit: 'Update',
    },
    termsAndConditions: {
      title: 'เงื่อนไขการรับประกันสำหรับการขอการรับประกัน',
      subTitle: 'เงื่อนไข',
      condition: 'ยอมรับเงื่อนไขการรับประกัน',
    },
    createMultipleWarranty: {
      title: 'Create Multiple Warranty:',
      placeholder: 'Search by Contractor Name',
      step1: {
        title: 'Contractor',
        description: 'Input coil length to contractor for generate warranty.',
      },
      step2: {
        title: 'Confirm',
        description: 'Confirm to generate warranty.',
        remainingCoilLength: 'Remaining Coil Length',
      },
      label: {
        invoiceId: 'Invoice no.',
        salesOrder: 'Sales Order',
        coilId: 'Coil ID',
        totalLength: 'Total Length',
        enterLength: 'Enter Length',
        errorLengthMessage: 'Coli length can’t more than total length.',
        coilLength: 'Coil Length',
        waitingForGenerating: 'Your warranty are waiting for generating process.',
        success: 'Success Generating Warranty',
      },
      table: {
        header: {
          id: 'No.',
          contractorName: 'Contractor Name',
          coliLength: 'Coli Length',
        },
      },
      button: {
        search: 'Search',
        close: 'Close',
        back: 'Back',
        continue: 'Continue',
        generateWarranty: 'Generate Warranty',
        goToWarranty: 'Go to Warranty',
      },
    },
    saleOrder: {
      table: {
        header: {
          saleTransactionId: 'Sales Transaction ID',
          coilName: 'Coil Name',
          coilId: 'Coil ID',
          order: 'Order (m.)',
          linkPreTransactionId: 'Link Pre-Transaction ID',
          action: 'Action',
          linkProject: 'Link Project',
        }
      },
      label: {
        noSaleTransactionIdForLinkToProject: ' No Sales Transaction ID for Link to Project',
        selectProject: 'Select Project',
      },
    },
  },
  pagination: {
    page: 'หน้า',
    previous: 'ย้อนกลับ',
    next: 'ต่อไป',
  },
  field: {
    buttonAccept: 'Accept',
    buttonPrintProductUpdateImage: 'Print product update image',
    buttonRequestToEdit: 'แก้ไขข้อมูล',
    buttonProjectDocument: 'เอกสารโครงการ',
    buttonDelete: 'ลบ',
    buttonLinkPreTransactionId: 'Link Pre-Transaction ID',
    buttonGenerateWarrantyFromCurrentSaleTransaction: 'ออกใบรับประกันจากการขายล่าสุด',
    buttonNewScan: 'New Scan',
    buttonExport: 'ส่งออก',
    buttonPreview: 'ตัวอย่าง',
    buttonBack: 'ย้อนกลับ',
    buttonNext: 'ต่อไป',
    buttonNextStep: 'ถัดไป',
    buttonEdit: 'แก้ไข',
    buttonEditProfile: 'แก้ไขข้อมูล',
    buttonShare: 'แชร์',
    buttonDownload: 'ดาวน์โหลด',
    buttonCancel: 'ยกเลิก',
    buttonSave: 'บันทึก',
    buttonSaveCapitalize: 'บันทึก',
    buttonSelect: 'เลือก',
    buttonSubmit: 'ส่งมอบ',
    buttonConfirm: 'ยืนยัน',
    buttonContinue: 'ดำเนินการต่อ',
    buttonSaveAndContinue: 'บันทึกและดำเนินการต่อ',
    buttonSaveAndSendOrders: 'บันทึกและส่งรายการ',
    buttonSaveAndTransfer: 'บันทึกและโอน',
    buttonConfirmAndSAVE: 'ยืนยันและบันทึก',
    buttonSaveAsDraft: 'บันทึกแบบร่าง',
    buttonChangePassword: 'เปลี่ยนรหัส',
    buttonUpdate: 'Update',
    buttonClose: 'ปิด',
    buttonInputQuantity: 'ระบุปริมาณ',
    buttonSend: 'ส่ง',
    buttonLogin: 'เข้าสู่ระบบ',
    buttonAcceptTermsAndConditions: 'ยอมรับเงื่อนไขการรับประกัน',
    buttonToLogin: 'ย้อนกลับหน้าเข้าสู่ระบบ',
    buttonApprove: 'ยอมรับ',
    buttonReSendAuthenticationLink: 'ส่งลิงก์การตรวจสอบสิทธิ์อีกครั้ง',
    buttonRequireDocumentary: 'ต้องการเอกสาร',
    buttonSearch: 'ค้นหา',
    buttonGenerateDocumentary: 'สร้างเอกสาร',
    buttonViewDocument: 'ดูเอกสาร',
    imageUpload: 'อับโหลดรูปภาพ',
    phoneNo: 'เบอร์โทร',
    phoneNoPlaceholder: 'ใส่เบอร์โทร',
    mobileNo: 'เบอร์โทรศัพท์มือถือ',
    mobileNoPlaceholder: 'ใส่เบอร์โทรศัพท์มือถือ',
    phoneNoHint: 'กรุณากรอกเบอร์โทร โดยไม่ต้องใส่ (0) นำหน้า',
    fullname: 'ชื่อ ผู้ใช้งาน',
    fullnamePlaceholder: 'ใส่ชื่อ ผู้ใช้งาน',
    companyname: 'บริษัท หรือโครงการ',
    companynamePlaceholder: 'ใส่บริษัท หรือโครงการ',
    typeProject: "เลือกชนิดโครงการ",
    typeProjectPlaceholder: "เลือกชนิดโครงการ",
    firstname: 'ชื่อ',
    firstnamePlaceholder: 'ใส่ชื่อ',
    lastname: 'นามสกุล',
    lastnamePlaceholder: 'ใส่นามสกุล',
    nickname: 'ชื่อเล่น',
    nicknamePlaceholder: 'ใส่ชื่อเล่น',
    dateOfBirth: 'วันเกิด',
    dateOfBirthPlaceholder: 'เลือกวันเดือนปีเกิด',
    email: 'อีเมล์',
    emailPlaceholder: 'ใส่อีเมลล์',
    sms: 'ข้อความ',
    company: 'บริษัท',
    companyPlaceholder: 'ใส่ชื่อบริษัท',
    idCardNo: 'เลขบัตรประชาชน',
    idCardNoPlaceholder: 'ใส่เลขบัตรประชาชน',
    address: 'ที่อยู่',
    addressPlaceholder: 'ใส่ที่อยู่',
    installationAddress: 'ที่อยู่โครงการ',
    installationAddressPlaceholder: 'กรุณาระบุรายละเอียดที่อยู่ที่โครงการ',
    installationAddressHint: 'สูงสุด 500 ตัวอักษร',
    industrialEstateName: 'ชื่อนิคมอุตสาหกรรม',
    industrialEstateNamePlaceholder: 'ชื่อนิคมอุตสาหกรรม',
    warrantyEnvDistanceMarine: "ระยะห่างจากทะเล (ม.)",
    warrantyEnvDistanceMarinePlaceholder: "ระยะห่างจากทะเล (ม.)",
    warrantyEnvDistanceIndustrial: "ระยะห่างจากนิคมอุตสาหกรรม (ม.)",
    warrantyEnvDistanceIndustrialPlaceholder: "ระยะห่างจากนิคมอุตสาหกรรม (ม.)",
    province: 'จังหวัด',
    provincePlaceholder: 'ใส่ชื่อ จังหวัด',
    provinceTH: 'จังหวัด',
    provinceTHPlaceholder: 'ใส่ชื่อ จังหวัด',
    provinceLA: 'จังหวัด',
    provinceLAPlaceholder: 'ใส่ชื่อ จังหวัด',
    city: 'อำเภอ',
    cityPlaceholder: 'ใส่ชื่อ อำเภอ',
    cityTH: 'อำเภอ',
    cityTHPlaceholder: 'ใส่ชื่อ อำเภอ',
    cityLA: 'อำเภอ',
    cityLAPlaceholder: 'ใส่ชื่อ อำเภอ',
    subdistrict: 'ตำบล',
    subdistrictPlaceholder: 'ใส่ชื่อ ตำบล',
    subdistrictTH: 'ตำบล',
    subdistrictTHPlaceholder: 'ใส่ชื่อ ตำบล',
    subdistrictLA: 'ตำบล',
    subdistrictLAPlaceholder: 'ใส่ชื่อ ตำบล',
    subdistrictIN: 'Subdistrict (Kelurahan Bahasa)',
    subdistrictINPlaceholder: 'Insert Subdistrict (Kelurahan Bahasa)',
    postalCode: 'รหัสไปรษณีย์',
    postalCodePlaceholder: 'ใส่ รหัสไปรษณีย์',
    district: 'District',
    districtPlaceholder: 'Insert District',
    districtIN: 'District (Kecamatan)',
    districtINPlaceholder: 'Insert District (Kecamatan)',
    alley: 'Alley/Lane',
    alleyPlaceholder: 'Insert Alley/Lane',
    group: 'Group/Hamlet',
    groupPlaceholder: 'Insert Group/Hamlet',
    street: 'Street',
    streetPlaceholder: 'Insert Street',
    ward: 'Ward',
    wardPlaceholder: 'Insert Ward',
    commentRemark: 'ข้อคิดเห็น/ข้อสังเกต',
    commentRemarkPlaceholder: 'ใส่ ข้อคิดเห็น/ข้อสังเกต',
    salesperson: 'พนักงานขาย',
    salespersonPlaceholder: 'เลือกพนักงานขาย',
    rmb: 'RMB',
    rmbPlaceholder: 'Insert Revenue',
    customerProjectName: 'ชื่อลูกค้า / ชื่อโครงการ',
    customerProjectNamePlaceholder: 'ใส่ ชื่อลูกค้า / ชื่อโครงการ',
    customerProjectNameHint: 'สูงสุด 100 ตัวอักษร',
    customerProjectNameHint500: 'สูงสุด 500 ตัวอักษร',
    additionalProjectInformation: 'ข้อมูลโครงการเพิ่มเติม (ถ้ามี)',
    additionalProjectInformationPlaceholder: 'กรุณากรอกข้อมูลโครงการเพิ่มเติม',
    additionalProjectInformationHint: 'สูงสุด 500 ตัวอักษร',
    customerRemark: 'ข้อความลูกค้า',
    customerRemarkExample: '(ตัวอย่าง: “หลังคา ลอน 760 A - xxx เมตร”)',
    customerRemarkPlaceholder: 'กรอกข้อความลูกค้า',
    customerRemarkHint: 'สูงสุด 500 ตัวอักษร',
    mobilePhoneNo: 'เบอร์มือถือ',
    mobilePhoneNoPlaceholder: 'กรอกเบอร์มือถือ',
    mobilePhoneNoHint: 'กรุณากรอกเบอร์โทร โดยไม่ต้องใส่ (0) นำหน้า',
    purchaseDate: 'วันที่สั่งซื้อ',
    installationDate: 'วันที่ติดตั้ง',
    installationDatePlaceholder: 'เลือกวันที่ติดตั้ง',
    installationDateHint: 'วันที่ติดตั้งต้องอยู่ภายใน 6 เดือนจากวันที่ชำระเงิน',
    inputNumberPlaceholder: 'ใส่ตัวเลข',
    editLength: 'แก้ไขความยาว (ม) ลดลงเท่านั้น',
    editLengthPlaceholder: 'ใส่ความยาว (ม) ลดลงเท่านั้น',
    editLengthPerUnit: 'Edit Length (m. per unit) reduce only',
    editLengthPerUnitPlaceholder: 'Insert edit length (m. per unit) reduce only',
    editQuantity: 'Edit Quantity (Sheet / Stick)',
    editQuantityPlaceholder: 'Insert edit quantity (Sheet / Stick)',
    otherReasonsChange: 'เหตุผลอื่นๆ',
    otherReasonsChangePlaceholder: 'กรุณาใส่เหตุผล',
    adName: 'ชื่อดีลเลอร์',
    adNamePlaceholder: 'ใส่ชื่อดีลเลอร์',
    businessRegisNo: 'เลขจดทะเบียนธุรกิจ',
    businessRegisNoPlaceholder: 'ใส่ เลขจดทะเบียนธุรกิจ',
    oldPassword: 'รหัสเก่า',
    oldPasswordPlaceholder: 'ใส่รหัสเก่า',
    newPassword: 'รหัสใหม่',
    newPasswordPlaceholder: 'ใส่รหัสใหม่',
    confirmPassword: 'ยืนยันรหัส',
    confirmPasswordPlaceholder: 'ยืนยันรหัส',
    homeOwnerName: 'ชื่อเจ้าของบ้าน /โครงการ',
    homeOwnerNamePlaceholder: 'ระบุชื่อเจ้าของบ้าน / โครงการ',
    homeOwnerAddress: 'ที่อยู่เจ้าของบ้าน / โครงการ',
    homeOwnerAddressPlaceholder: 'ระบุที่อยู่เจ้าของบ้าน / โครงการ',
    permitLetterNumber: 'Permit letter number',
    permitLetterNumberPlaceholder: 'Insert Permit letter number',
    buildingType: 'อาคาร',
    buildingTypePlaceholder: 'ระบุอาคาร',
    AreaM2OfBuilding: 'พื้นที่ (ตารางเมตร) ของอาคาร',
    AreaM2OfBuildingPlaceholder: 'ระบุ พื้นที่ (ตารางเมตร) ของอาคาร',
    tokoName: 'Toko Name',
    tokoNamePlaceholder: 'Insert Toko Name',
    builderName: 'Builder Name',
    builderNamePlaceholder: 'Insert Builder Name',
    productWeight: 'Product Weight',
    productWeightPlaceholder: 'Insert Product Weight',
    tctThickness: 'TCT Thickness',
    tctThicknessPlaceholder: 'Insert TCT Thickness',
    colorName: 'Color Name',
    colorNamePlaceholder: 'Insert Color Name',
    productUpdate: 'Product Update',
    application: {
      title: 'ผลิตภัณฑ์',
      walling: 'ผนัง',
      roofing: 'หลังคา',
      roofFrame: 'โครงหลังคา',
      wallFrame: 'โครงผนัง',
      awning: 'กันสาด',
    },
    brand: 'แบรนด์',
    brandPlaceholder: 'ระบุแบรนด์',
    installationFinishDate: 'วันที่ติดตั้งแล้วเสร็จ',
    installationFinishDatePlaceholder: 'ระบุวันที่ติดตั้งแล้วเสร็จ',
    authorizedDealerRubberStamp: 'Authorized Dealer rubber stamp',
    authorizedDealerRubberStampPlaceholder: 'Insert Authorized Dealer rubber stamp',
    originalReceiptInvoice: 'Original Receipt / Invoice',
    originalReceiptInvoicePlaceholder: 'Insert Original Receipt / Invoice',
    residentialCommercial: 'Residential / Commercial',
    residentialCommercialPlaceholder: 'Insert Residential / Commercial',
    name: 'Name',
    namePlaceholder: 'Insert Name',
    postcode: 'Postcode',
    postcodePlaceholder: 'Insert Postcode',
    typesOfProductPurchased: 'Types of product purchased',
    typesOfProductPurchasedPlaceholder: 'Insert Types of product purchased',
    purposeOfPurchasedZacs: 'Purpose of purchased Zacs',
    purposeOfPurchasedZacsPlaceholder: 'Insert Purpose of purchased Zacs',
    dateOfPurchase: 'วันที่ซื้อสินค้า',
    dateOfPurchasePlaceholder: 'ระบุวันที่ซื้อสินค้า',
    inoivceNo: 'Inoivce No.',
    inoivceNoPlaceholder: 'Insert Inoivce No.',
    password: 'Password',
    passwordPlaceholder: 'Insert Password',
    rememberMe: 'Remember Me',
    building: 'อาคาร',
    buildingPlaceholder: 'ระบุอาคาร',
    environment: {
      verySevereMarme: 'ชายฝั่งที่มีการกัดกร่อนรุนแรงมาก',
      verySevereMarmeDetail: 'พื้นที่จากชายฝั่ง 100 เมตร และ รวมไปถึงพื้นที่นอกชายฝั่งที่มีคลื่นลมแรง',
      setereMarine: 'ชายฝั่งที่มีการกัดกร่อนรุนแรง',
      setereMarineDetail: 'โดยทั่วไปอยู่ห่างจากชายฝั่งระหว่าง 101 เมตร ถึงประมาณ 200 เมตร อย่างไรก็ตามลมที่พัดแรงสามารถขยายระยะทางนี้ได้ (หรือ 100 เมตร จากอ่าวที่คลื่นลมสงบ) สภาพแวดล้อมจะมีไอเกลือที่รุนแรง มีกลิ่นไอเกลือและมีคราบเกลือเกาะบนโครงสร้างที่อยู่ในบริเวณที่ไม่ถูกชะล้างด้วยน้ำฝน โดยทั่วไปแล้วการเสื่อมสภาพของวัสดุก่อสร้างส่วนใหญ่จะสังเกตได้ชัด',
      marine: 'ชายฝั่ง',
      marineDetail: 'โดยทั่วไปห่างจากชายฝั่งระหว่าง 201 เมตร ถึงประมาณ 400 เมตร อย่างไรก็ตามลมที่พัดแรงสามารถขยายระยะทางนี้ได้ (หรือ 101 เมตร ถึง 200 เมตร จากทะเลที่คลื่นลมสงบ) สภาพแวดล้อมมีกลิ่นเกลือเล็กน้อยที่สังเกตเห็นได้เป็นครั้งคราว และมีคราบเกลือเกาะบนโครงสร้างที่อยู่ในบริเวณที่ไม่ถูกชะล้างด้วยน้ำฝน',
      moderateMarine: 'ชายฝั่งที่มีการกัดกร่อนปานกลาง',
      moderateMarineDetail: 'โดยทั่วไปห่างจากชายฝั่งระหว่าง 401 เมตร ถึงประมาณ 1000 เมตร อย่างไรก็ตามลมที่พัดแรงสามารถขยายระยะทางนี้ได้(หรือ 201 เมตร ถึง 1000 เมตร จากทะเล)',
      beigaRural: 'ชนบท / สภาพแวดล้อมปกติ',
      beigaRuralDetail: 'โดยปกติระยะเกิน 1000 เมตร จากอิทธิพลของทะเล (ทั้งที่มีคลื่นลมแรงและคลื่นลมสงบ)',
      severeIndustrial: 'นิคมอุสาหกรรมที่มีการกัดกร่อนรุนแรง',
      severeIndustrialDetail: 'สภาพแวดล้อมมีการปล่อยมลพิษจากปล่องควัน กลิ่นกำมะถัน และ กลิ่นกรดหรือด่าง รวมถึงอาคารในโรงงาน และ อาคารใดๆที่อยู่ภายใต้ปล่องควันนั้น และรวมไปถึงอาคารที่มีความชื้นภายในอาคาร และ/หรือ การกัดกร่อนที่เกิดจากกระบวนการผลิต/กิจกรรมภายในอาคาร โดยทั่วไปอัตราการกัดกร่อนที่สูงมากในโครงสร้างอาคารอาคารส่วนใหญ่จะเห็นได้ชัด',
      heavyIndustrial: 'อุตสาหกรรมที่มีการกัดกร่อนรุนแรงมาก',
      heavyIndustrialDetail: 'สภาพแวดล้อมทั่วไปจะอยู่ห่างจากพื้นที่เขตอุตสาหกรรมที่ปล่อยมลพิษอย่างรุนแรงน้อยกว่า 500 เมตร หรือ อุตสาหกรรมขนาดเล็กที่ก่อให้เกิดมลพิษอย่างมีนัยสำคัญ รวมไปถึงอาคารซ่อมบำรุงที่อยู่ใกล้กับอุตสาหกรรมขนาดใหญ่ รวมไปถึงด้านนอกของอาคารที่อยู่ในโรงงานนั้นด้วย',
      industrial: 'อุตสาหกรรม',
      industrialDetail: 'สภาพแวดล้อมที่กระจายตัวในเขตอุตสาหกรรม โดยทั่วไปจะอยู่ห่างจากอุตสาหกรรมที่ปล่อยมลพิษ มากกว่า 500 เมตร ถึง 1000 เมตร ห่างจากพื้นที่อุตสาหกรรมที่ปล่อยมลพิษ หรืออยู่ห่าง น้อยกว่า 500 เมตร จาก อุตสาหกรรมที่นำไปสู่การปล่อยมลพิษในระดับปานกลางจากปล่องขนาดเล็ก ',
      lightIndustrial: 'อุตสาหกรรมที่มีการกัดกร่อนเล็กน้อย',
      lightIndustrialDetail: 'สภาพแวดล้อมที่กระจายตัวในเขตอุตสาหกรรม โดยทั่วไปจะอยู่ห่างจากอุตสาหกรรมที่ปล่อยมลพิษ มากกว่า 1000 เมตร ถึง 2000 เมตร ห่างจากพื้นที่อุตสาหกรรมที่ปล่อยมลพิษ หรืออยู่ห่าง มากกว่า 500 เมตร ถึง 1000 จาก อุตสาหกรรมที่นำไปสู่การปล่อยมลพิษในระดับปานกลางจากปล่องขนาดเล็ก',
      trueCoreApplication: 'TrueCore Application',
      trueCoreApplicationDetail: 'การใช้งานของการรับประกันนี้กับการก่อสร้าง เป็นเงื่อนไขสำหรับที่อยู่อาศัย และอาคารพาณิชย์ ในประเทศไทย (ที่พักอาศัย) การผสมผสานกันของส่วนประกอบ TRUECORE และเฟรม รวมไปถึงการก่อสร้าง ได้รับการดำเนินการตามข้อควรปฎิบัติของการก่อสร้างเป็นอย่างดี ที่พักอาศัยตั้งอยู่ห่างจากทะเลที่มีคลื่นลมแรงมากว่า 300 เมตร หรือ ห่างจากแหล่งน้ำมากว่า 100 เมตร (โครงสร้างเหล็กและเฟรมเหล็กทั้งหมดต้องอยู่ภายในอาคารปิด) ',
      standard: {
        type: 'มาตรฐาน',
        benignRural: 'ชนบท / ไม่มีการกัดกร่อน',
        benignRuralDescription1: 'โดยทั่วไป มีระยะห่างมากกว่า',
        benignRuralDescription2: '1000 เมตร จากอิทธิพลของทะเล',
        benignRuralDescription3: '(ที่คลื่นลมแรง และ คลื่นลมสงบ)',
        benignRuralDescription4: 'และ / หรือ',
        benignRuralDescription5: 'อย่างน้อย',
        benignRuralDescription6: '2000 เมตรจากพื้นที่อุตสาหกรรมที่มีการปล่อยมลพิษรุนแรง',
        benignRuralDescription7: 'หรือ',
        benignRuralDescription8: '1000 เมตร ห่างจากพื้นที่อุตสาหกรรมขนาดเล็กที่ปล่อยมลพิษ ',
      },
      industrialEnvironment: {
        type: 'สภาพแวดล้อม นิคมอุตสาหกรรม',
        lightIndUrban: 'อุตสาหกรรมขนาดเล็ก / ชนบท',
        lightIndUrbanDescription1: 'สภาพแวดล้อมนี้อยุ่ภายนอกเขตนิคมอุตสาหกรรม / พื้นที่ชนบท โดยปกติแล้ว ห่างมากกว่า',
        lightIndUrbanDescription2: '1000 เมตร ถึง 2000 เมตร จาก นิคมอุตสาหกรรมที่เป็นมลพิษอย่างมาก หรือ ระหว่าง 500 เมตร ถึง 1000 เมตร จากอุตสาหกรรมขนาดเล็ก ',
        lightIndUrbanDescription3: 'ซึ่งนำไปสู่การได้รับมลพิษระดับปานกลางจากปล่องขนาดเล็ก ',
        industrial: 'อุตสาหกรรม',
        industrialDescription1: 'สภาพแวดล้อมนี้อยุ่ภายนอกเขตนิคมอุตสาหกรรม / พื้นที่ชนบท โดยปกติแล้ว ห่างมากกว่า',
        industrialDescription2: '500 เมตร ถึง 1000 เมตร จาก นิคมอุตสาหกรรมที่เป็นมลพิษอย่างมาก, หรือ น้อยกว่า 500 เมตร จากอุตสาหกรรมขนาดเล็ก ',
        industrialDescription3: 'ซึ่งนำไปสู่การได้รับมลพิษระดับปานกลางจากปล่องขนาดเล็ก',
        heavyIndustrial: 'อุตสาหกรรมที่มีการกัดกร่อน',
        heavyIndustrialDescription1: 'สภาพแวดล้อมนี้',
        heavyIndustrialDescription2: 'อยู่ห่างน้อยกว่า 500 เมตร',
        heavyIndustrialDescription3: 'จากเขตอุตสาหกรรมที่มีมลพิษรุนแรง หรือ อุตสาหกรรมขนาดเล็กที่นำไปสู่การปล่อยมลพิษอย่างเป็นนัยสำคัญ โดยทั่วไปจะรวมไปถึงอาคารต่างๆที่อยู่ใกล้กับเขตนิคมอุสาหกรรมที่มีการกัดกร่อนรุนแรง และรวมไปถึงภายนอกอาคารของโรงงานนั้นๆด้วย',
        heavyIndustrialDescription4: 'สินค้า COLORBOND ULTRA เป็นสินค้าที่ได้รับการแนะนำเป็นอย่างยิ่ง',
        severeIndustrial: 'อุตสาหกรรมที่มีการกัดกร่อนรุนแรง',
        severeIndustrialDescription1: 'สภาพแวดล้อมที่มีการปล่อยมลพิษจาก',
        severeIndustrialDescription2: 'ปล่องควัน ได้กลิ่นกำมะถัน กรดหรือด่าง ',
        severeIndustrialDescription3: 'รวมถึงอาคารของโรงงานนั้นและโรงงานที่อยู่ภายใต้ปล่องควันโดยตรง และรวมไปถึงอาคารที่',
        severeIndustrialDescription4: 'ภายในมีความชื้นสูง และ/หรือ เกิดการกัดกร่อนจากกิจกรรมภายในอาคาร',
        severeIndustrialDescription5: 'โดยทั่วไปจะมีอัตราการกัดกร่อนที่ค่อนข้างรุนแรงเกิดขึ้นที่โครงสร้างอาคาร (กรุณาอ้างอิงจากเอกสารบลูสโคป)',
      },
      marineEnvironment: {
        type: 'สภาพแวดล้อมที่เป็นทะเล',
        moderateMarine: 'ทะเลที่มีการกัดกร่อนปานกลาง',
        moderateMarineDescription1: 'โดยปกติจะอยู่ระหว่าง',
        moderateMarineDescription2: '401 เมตร และ 1000 เมตร จากทะเล',
        moderateMarineDescription3: 'ความแรงของลมอาจจะทำให้ระยะนี้เพิ่มขึ้น',
        moderateMarineDescription4: '(หรือ 201เมตร ถึง 1000เมตร จากทะเลทีคลื่นลมสงบ).',
        marine: 'ทะเล',
        marineDescription1: 'โดยปกติจะอยู่ระหว่าง',
        marineDescription2: '201เมตร ถึง 400เมตร จากชายหาด',
        marineDescription3: 'ในพื้นที่ลมแรง, อาจจะเพิ่มระยะทางในส่วนนี้ได้ขึ้นอยู่กับสภาพภูมิประเทศด้วย (หรือ',
        marineDescription4: '101 เมตร – 200 เมตร จากทะเลที่คลื่นลมสงบ)',
        marineDescription5: 'ได้กลิ่นไอเกลือเล็กน้อยและมีคราบเกลือเกาะบนโครงสร้างเหล็กที่อยู่ในบริเวณที่ไม่ได้ชะล้างด้วยน้ำฝน',
        marineDescription6: 'หากมีสามารถมองเห็นละอองเกลือในพื้นที่นี้ บริษัทฯ แนะนำสินค้า COLORBOND ULTRA และหมุดยึด หรือ สกรู Class- 4 เท่านั้น',
        severeMarine: 'ทะเลที่มีการกัดกร่อนรุนแรง',
        severeMarineDescription1: 'โดยปกติอยู่ระหว่าง',
        severeMarineDescription2: '101เมตร ถึง 200 เมตรจากชายฝั่ง',
        severeMarineDescription3: 'ความแรงของลมอาจจะทำให้ระยะนี้เพิ่มขึ้น ขึ้นอยู่กับลักษณะภูมิประเทศด้วย (หรือ ',
        severeMarineDescription4: '<100เมตร จากทะเลที่สงบ',
        severeMarineDescription5: 'เช่นท่าเรือ ลักษณะทั่วไปจะพบละอองเกลือค่อนข้างชัดเจน ได้กลิ่นเกลือและมีคราบเกลือเกาะอยู่ที่โครงสร้างเหล็กบริเวณที่ไม่ได้ถูกชะล้างด้วยน้ำฝน โดยทั่วไปวัสดุก่อสร้างจะถูกกัดกร่อนอย่างเห็นได้ชัด ',
        severeMarineDescription6: 'ในสภาพแวดล้อมนี้ ทางบริษัทฯ แนะนำ COLORBOND? ULTRA และ หมุดยึด/สกรู Class- 4 เป็นอย่างยิ่ง',
        verySevereMarine: 'ทะเลที่มีการกัดกร่อนรุนแรงมาก',
        verySevereMarineDescription1: 'รวมถึงพื้นที่นอกชายฝั่งและ',
        verySevereMarineDescription2: 'น้อยกว่า 100 เมตร จากชายหาดที่มีคลื่นลมแรง',
        verySevereMarineDescription3: '. (กรุณาอ้างอิงเอกสารจากบลูสโคปฯ)',
      },
      vn: {
        standard: {
          benignRural: 'สภาพอ่อนโยน / ชุมชนชนบท',
          benignRuralDescription1: 'โดยทั่วไปจะอยู่ห่าง',
          benignRuralDescription2: '5 กิโลเมตรจากอิทธิพลของทะเล',
          benignRuralDescription3: '(ทั้งที่มีคลื่นรุนแรงและคลื่นสงบ) และห่าง',
          benignRuralDescription4: '2 กิโลเมตร จากแหล่งนิคมอุตสาหกรรมข้างต้นทั้งหมด',
          benignRuralDescription5: 'รวมไปถึงการกัดกร่อนจากมลพิษ',
          benignRuralDescription6: 'C?ch m?i tr??ng bi?n h?n 5km v? m?i tr??ng c?ng nghi?p v? khu v?c kh?i b?i c? t?nh ?n m?n t? 2km tr? l?n.',
        },
        industrialEnvironment: {
          lightIndUrban: 'อุตสาหกรรมขนาดเล็ก / ชนบท',
          lightIndUrbanDescription1: 'สภาพแวดล้อมอยู่ภายนอกเขตนิคมอุตสาหกรรม เป็นพื้นที่ชนบท อยู่ห่างจากพื้นที่ที่ได้กล่าวมาทั้งหมดเป็นระยะทางมากกว่า',
          lightIndUrbanDescription2: '1001เมตร ถึง 2000เมตร จากอุตสาหกรรมที่มีมลพิษรุนแรง',
          lightIndUrbanDescription3: 'M?i tr??ng n?y ph? bi?n trong c?c khu c?ng nghi?p/ khu ?? th?, n?m c?ch xa c?c m?i tr??ng li?t k? ? tr?n, ?i?n h?nh c?ch xa h?n 1001m – 2000m ??i v?i khu v?c c? b?i c?ng nghi?p n?ng.',
          lightIndUrbanDescription4: 'ระยะเวลาการรับประกันอุตสาหกรรมที่แสดงไว้ที่นี่มีไว้เพื่อวัตถุประสงค์ในการบ่งชี้เท่านั้นการรับประกันในสภาพแวดล้อมทางอุตสาหกรรมจะพิจารณาเป็นกรณีไป โดยพิจารณาจากความรุนแรงของสารมลพิษและระยะห่างจากอิทธิพลทางทะเลและอุตสาหกรรม',
          industrial: 'พื้นที่อุตสาหกรรม',
          industrialDescription1: 'สภาพแวดล้อมนี้อยู่ภายนอกนิคมอุตสาหกรรม เป็นพื้นที่ชนบท อยู่ห่างจากสภาพแวดล้อมที่ระบุไว้ทั้งหมด เป็นระยะทางมากกว่า',
          industrialDescription2: '501 เมตร ถึง 1000 เมตร จากอุตสาหกรรมที่มีมลพิษรุนแรง',
          industrialDescription3: '??c ?i?m c?a m?i tr??ng n?y l? khu v?c c? b?i do n?m g?n k? khu v?c mang t?nh ch?t c?ng nghi?p n?ng hay c?c khu v?c c?ng nghi?p nh? s?n sinh ra kh?i b?i c?ng nghi?p ??ng k?, c?ch c?c khu v?c n?y t? 501 t?i 1000m.',
          industrialDescription4: 'ระยะเวลาการรับประกันอุตสาหกรรมที่แสดงไว้ที่นี่มีไว้เพื่อวัตถุประสงค์ในการบ่งชี้เท่านั้นการรับประกันในสภาพแวดล้อมทางอุตสาหกรรมจะพิจารณาเป็นกรณีไป โดยพิจารณาจากความรุนแรงของสารมลพิษและระยะห่างจากอิทธิพลทางทะเลและอุตสาหกรรม',
          heavyIndustrial: 'เขตนิคมอุตสาหกรรมที่มีการกัดกร่อนรุนแรง',
          heavyIndustrialDescription1: 'ลักษณะทั่วไป จะอยู่ห่าง',
          heavyIndustrialDescription2: '500 เมตร จากเขตพื้นที่ติดกับอุตสาหกรรมที่มีการปล่อยมลพิษอย่างรุนแรง',
          heavyIndustrialDescription3: 'หรือ อุตสาหกรรมขนาดเล็กที่นำไปสู่การปล่อยมลพิษอย่างเป็นนัยสำคัญ โดยทั่วไปจะรวมไปถึงอาคารต่างๆที่อยู่ใกล้กับเขตนิคมอุสาหกรรมที่มีการกัดกร่อนรุนแรง และรวมไปถึงภายนอกอาคารของโรงงานนั้นๆด้วย',
          heavyIndustrialDescription4: '??c ?i?m c?a m?i tr??ng n?y l? khu v?c c? b?i do n?m g?n k? trong v?ng 500m khu v?c mang t?nh ch?t c?ng nghi?p n?ng hay c?c khu v?c c?ng nghi?p nh? s?n sinh ra kh?i b?i c?ng nghi?p ??ng k?.Th?ng th??ng bao g?m c?c t?a nh? d?ch v? n?m ri?ng bi?t v? g?n nh?ng nh? m?y c?ng nghi?p n?ng v? bao g?m c? khu nh? ph?.',
          severeIndustrial: 'SEVERE INDUSTRIAL (M?I TR??NG C?NG NGHI?P N?NG)',
          severeIndustrialDescription1: 'โดยทั่วไปจะมีการปล่อยมลพิษจาก',
          severeIndustrialDescription2: 'ปล่องควัน, มีกลิ่นกำมะถัน กลิ่นกรดหรือด่าง',
          severeIndustrialDescription3: 'รวมไปถึงอาคารในโรงงานนั้น และ อาคารที่อยู่ภายใต้ปล่องโดยตรง และรวมไปถึงอาคารที่ ',
          severeIndustrialDescription4: 'มีความชื้นสูง และ/หรือ เกิดการกัดกร่อนจากกิจกรรมภายในอาคาร',
          severeIndustrialDescription5: '??c ?i?m c?a m?i tr??ng n?y l? khu v?c c? b?i v? kh?i th?i t? ?ng kh?i, c? ch?a l?u hu?nh v? axit hay ch?t ki?m.Bao g?m c?c nh? x??ng c? ?ng kh?i v? b?t k? t?a nh? n?o ngay b?n d??i ?ng kh?i.V? c?ng bao g?m c?c t?a nh? c? ?? ?m b?n trong cao hay/ ho?c ?n m?n x?y ra do c?c ho?t ??ng b?n trong.',
          severeIndustrialDescription6: 'โดยทั่วไปจะเห็นได้ชัดว่ามีอัตราการกัดกร่อนที่สูงมากกับโครงสร้างในเป็นอาคารส่วนใหญ่',
        },
        marineEnvironment: {
          moderateMarine: 'พื้นที่ชายฝั่งทะเล',
          moderateMarineDescription1: 'โดยปกติจะอยู่ในพื้นที่',
          moderateMarineDescription2: '1001เมตร ถึง 5000เมตร จากชายฝั่ง',
          moderateMarineDescription3: 'อย่างไรก็ตามลมที่พัดแรงสามารถขยายระยะทางนี้ได้',
          moderateMarineDescription4: 'โดยปกติจะอยู่ในพื้นที่ตั้งแต่ 1001 ม. ถึง 5,000 ม. จากชายฝั่ง อย่างไรก็ตามลมที่พัดแรงสามารถขยายระยะทางนี้ได้',
          marine: 'พื้นที่ติดชายฝั่งทะเล',
          marineDescription1: 'โดยปกติจะอยู่ในพื้นที่',
          marineDescription2: 'จากชายฝั่ง 401 เมตร ถึง 1000 เมตร',
          marineDescription3: 'อย่างไรก็ตามลมที่พัดแรงสามารถขยายระยะทางนี้ได้',
          marineDescription4: '(หรือจาก 201 เมตร ถึง 1000 เมตร จากบริเวณคลื่นลมสงบ)',
          marineDescription5: 'โดยปกติจะอยู่ในพื้นที่ห่างจากชายฝั่ง 401 เมตร ถึง 1000 เมตร อย่างไรก็ตามลมที่พัดแรงสามารถขยายระยะทางนี้ได้ (หรือจาก 201 เมตร ถึง 1000 เมตร จากบริเวณคลื่นลมสงบ)',
          severeMarine: 'พื้นที่ติดชายฝั่งทะเลคลื่นลมแรง',
          severeMarineDescription1: 'โดยปรกติแล้ว จะอยู่ในพื้นที่',
          severeMarineDescription2: 'จากชายฝั่ง 101 เมตร ถึง 400 จาก',
          severeMarineDescription3: 'พื้นที่ที่มีคลื่นลมแรง, อย่างไรก็ตามลมที่พัดแรงสามารถขยายระยะทางนี้ได้',
          severeMarineDescription4: '(หรือ 101 เมตร ถึง 200 เมตร จากทะเลที่ไม่มีคลื่นแรง)',
          severeMarineDescription5: 'ประเมินได้จากกลิ่นไอเกลือเล็กน้อยและมีคราบเกลือเกาะอยู่บริเวณโครงสร้างที่ไม่สามารถชะล้างด้วยน้ำฝน',
          severeMarineDescription6: 'โดยปกติแล้วจะอยู่ในพื้นที่ 101 เมตร ถึง 400 เมตร จากชายฝั่ง อย่างไรก็ตามลมที่พัดแรงสามารถขยายระยะทางนี้ได้ (หรือจาก 101เมตร ถึง 200 เมตร จากพื้นที่ไม่มีคลื่นแรง) มีกลิ่นเกลือเล็กน้อยและมีคราบเกลือเกาะอยู่บริเวณโครงสร้างที่ไม่สามารถชะล้างด้วยน้ำฝน',
          severeMarineDescription7: 'ในพื้นที่นี้ ต้องใช้ COLORBOND? ULTRA Steel และใช้สกรู Class- 4 เท่านั้น',
          verySevereMarine: 'พื้นที่ติดชายฝั่งทะเลคลื่นลมรุนแรงมาก',
          verySevereMarineDescription1: 'งานหลังคาสามารถใช้ได้โดยขึ้นอยู่กับการประเมินหน้างานโครงการนั้นๆ (พิจารณาเป็นกรณีๆ ไป) ',
          verySevereMarineDescription2: 'งานหลังคาสามารถใช้ได้โดยขึ้นอยู่กับการประเมินหน้างานโครงการนั้นๆ (พิจารณาเป็นกรณีๆ ไป) ',
          verySevereMarineDescription3: 'ในพื้นที่นี้ ต้องใช้ COLORBOND? ULTRA Steel และใช้สกรู Class- 4 เท่านั้น',
          verySevereMarineDescription4: 'พื้นที่นอกชายฝั่ง',
          verySevereMarineDescription5: 'น้อยกว่า 100 เมตร จากชายฝั่งที่คลื่นลมแรง ',
        },
      },
      in: {
        standard: {
          benignRural: 'Benign / Rural',
          benignRuralDescription1: 'Generally',
          benignRuralDescription2: 'greater than 1000m',
          benignRuralDescription3: 'from marine influence (breaking surf or calm marine) ​AND/OR at​',
          benignRuralDescription4: 'least 2km',
          benignRuralDescription5: 'away from heavy Industrial fall out OR',
          benignRuralDescription6: '1km',
          benignRuralDescription7: 'away from small industrial fall out.​',
        },
        industrialEnvironment: {
          lightIndUrban: 'Light Industrial',
          lightIndUrbanDescription1: 'This environment is widespread in industrial / urban areas, typically more than',
          lightIndUrbanDescription2: '1000m up to 2000m from heavy industrial fall-out, or between 500m to 1000m where small industries',
          lightIndUrbanDescription3: 'lead to a moderate level of fall-out from small stacks, etc.',
          lightIndAndUrban: 'Light Industrial / Urban',
          lightIndAndUrbanDescription1: 'This environment is widespread in industrial / urban areas, typically more than',
          lightIndAndUrbanDescription2: '1000m up to 2000m from heavy industrial fall-out, or between 500m to 1000m where small industries',
          lightIndAndUrbanDescription3: 'lead to a moderate level of fall-out from small stacks, etc.',
          industrial: 'Industrial',
          industrialDescription1: 'This environment is widespread in industrial/urban areas, typically more than',
          industrialDescription2: '500m up to 1000m from heavy industrial fall-out, or < 500m where small industries',
          industrialDescription3: 'lead to a moderate level of fall-out from small stacks, etc. ',
          heavyIndustrial: 'Heavy Industrial ',
          heavyIndustrialDescription1: 'Typically characterized by',
          heavyIndustrialDescription2: 'less than 500m',
          heavyIndustrialDescription3: 'fall-out from adjoining severe industrial environments or where small industries lead to significant industrial fall-out. Generally includes other service building located near heavy industrial plants, including out-building of the plant itself.',
          heavyIndustrialDescription4: 'In this environment we strongly recommend Clean COLORBOND®ULTRA Steel.',
          severeIndustrial: 'Severe Industrial',
          severeIndustrialDescription1: 'Characterized by fall-out and emissions from',
          severeIndustrialDescription2: 'stacks, sulfur and acid or alkali smells.',
          severeIndustrialDescription3: 'Includes only plant building themselves and any building immediately under stacks. Also includes building with',
          severeIndustrialDescription4: 'high internal humidity and/ or corrosion from operations within.',
          severeIndustrialDescription5: 'Generally a very high rate of corrosion in most building structures is evident.',
          severeIndustrialDescription6: '(Please refer to BlueScope representatives).',
        },
        marineEnvironment: {
          moderateMarine: 'Moderate Marine',
          moderateMarineDescription1: 'Generally between',
          moderateMarineDescription2: '401m and 1000m from marine surf,',
          moderateMarineDescription3: 'although strong prevailing winds may extend this distance.',
          moderateMarineDescription4: '(Or 201m – 1000m from calm marine).',
          marine: 'Marine',
          marineDescription1: 'Generally between',
          marineDescription2: '201m from the beachfront to approximately 400m inland.',
          marineDescription3: 'In high wind areas, may extend further inland depending on prevailing winds and geography of the area. (Or',
          marineDescription4: '101 - 200m from calm marine',
          marineDescription5: '). Characterized by occasionally noticeable slight salt smell and salt building-up in unwashed areas of structures.',
          marineDescription6: 'If there is visible salt haze in this zone we would recommend you only use of Clean COLORBOND® ULTRA Steel and Class-4 fasteners​.',
          severeMarine: 'Severe Marine',
          severeMarineDescription1: 'Generally between',
          severeMarineDescription2: '101m from the beachfront to approximately 200m inland.',
          severeMarineDescription3: 'In high wind areas, may extend further inland depending on prevailing winds and geography of the area. (Or',
          severeMarineDescription4: '< 100m from calm marine',
          severeMarineDescription5: 'e.g.: harbour). Characterized by strong salt and haze, salt smearing and salt building-up in unwashed areas of structures.',
          severeMarineDescription6: 'Generally a very noticeable deterioration of most building materials is evident.​ In this environment we strongly recommend Clean COLORBOND® ULTRA Steel and Class-4 fasteners.',
          verySevereMarine: 'Very Severe Marine',
          verySevereMarineDescription1: 'Includes off-shores areas and',
          verySevereMarineDescription2: 'up to 100m from high waterline of areas with breaking surf. ',
          verySevereMarineDescription3: '(Please refer to BlueScope representatives)',
        },
      },
    },
    startWorkingDate: 'วันเริ่มงาน',
    startWorkingDatePlaceholder: 'ใส่วันเริ่มงาน',
    jobPosition: 'ตำแหน่ง',
    jobPositionPlaceholder: 'เลือกตำแหน่ง',
    courseName: 'ชื่อคอร์ส',
    courseNamePlaceholder: 'ใส่ชื่อคอร์ส',
    trainingDate: 'วันที่อบรม',
    trainingDatePlaceholder: 'เลือกวันที่อบรม',
    segmentPlaceholder: 'ค้นหากลุ่มย่อยหรือคำอธิบายกลุ่มย่อย',
    applicationDetailPlaceholder: 'ใส่จำนวน',
    buildingSelectPlaceholder: 'เลือกการก่อสร้าง',
    segmentSelectPlaceholder: 'เลือกกลุ่ม',
    filter: 'ตัวกรอง',
    questionPlaceholder: 'ใส่คำตอบ',
    birthDayPlaceholder: 'เลือกวันเกิด',
    trainingYear: 'ปีที่อบรม',
    trainingYearPlaceholder: 'เลือกปีที่อบรม',
    onceTheSaleOrderIsComplete: 'Once the sales order is complete, this button will appear.',
    youCanGoTothe: 'You can go to the',
    projectDocument: 'project document',
    or: 'or',
    warranty: 'warranty',
    menu: 'menu',
    toGenerateWarranty: 'to generate a warranty',
    ifYouLeaveThisPage: 'if you leave this page.',
    thisButtonWillDisappear: 'this button will disappear.',
    linkPreTransactionIdAndToSaleTransactionId: 'Link Pre-Transaction ID to Sales Transaction ID',
  },
  preTransaction: {
    breadcrumb1: 'Home',
    breadcrumb2: 'Project Document',
    breadcrumb3: 'Pre-Transaction',
    breadcrumb4: 'Project information',
    title: 'Pre-Transaction',
    label: {
      customerSelection: 'Customer Selection',
      selectCustomerCreatePreTransaction: 'Select customer for create Pre-Transaction.',
      information: 'Information',
      segment: 'Segment',
      segmentAndSubSegment: 'Segment and Sub segment',
      segmentDescription: 'Segment description',
      subSegment: 'Sub Segment',
      subSegmentDescription: 'Segment description',
      pleaseChooseAnAction: 'โปรดเลือกการดำเนินการ',
      warrantyTempalte: 'ตัวอย่างใบรับประกัน',
      warrantyTempalteDesc: 'ตัวอย่างใบรับประกันของทุกผลิตภัณฑ์',
      documentTemplate: 'เอกสารอื่นๆ',
      documentTemplateDesc: 'จดหมายรับรอง / โบรชัวร์ / Mill certificate',
      certificate: 'เอกสารรับรอง',
      certificateDesc: 'มอก. / MiT / HACCP',
      salesOrder: 'ใบสั่งขาย',
      salesOrderDesc: 'เปลี่ยนกลุ่มผลิตภัณฑ์จากธุรกรรมล่วงหน้าเป็นใบสั่งขาย',
      reference: 'เอกสารที่เกี่ยวข้อง',
      referenceDesc: 'สำหรับจัดเก็บเอกสารที่เกี่ยวข้องกับโครงการ (เอกสาร, รูปภาพ)',
      remarkOnlyProjectDocument: 'สำหรับก่อนการขาย (Project Document) เท่านั้น',

    },
    field: {
      projectName: 'Project Name',
      projectAddress: 'Project address',
      maxProjectName: 'Maximum 100 characters.',
      maxprojectAddress: 'Maximum 100 characters.',
      placeholder: 'Placeholder',
      placeholderProjectName: 'Project Name',
      placeholderSearchSegmentSubSegmentAndDescription: 'Search segment/sub segment name and description',
      placeholderSearchProjectOrCustomerName: 'Search project or customer name'
    },
    button: {
      buttonSelectCustomer: 'Select Customer',
      segmentAndSubSegmentSelection: 'Segment & Sub Segment Selection',
      buttonCreatePreTransaction: 'Create Pre-Transaction'
    },
    projectInformation: {
      title: 'Project information',
    },
    preTransactionHistory: {
      breadcrumb1: 'Pre-Transaction History',
      breadcrumb2: 'Duplicate certificate',
      breadcrumb3: 'View Certificate',
      title: 'Project Document > Pre-Transaction History',
      field: {
        placeholderSearch: 'Search',
        placeholderSelectStartDate: 'Select Start Date',
        placeholderSelectEndDate: 'Select End Date',
      },
      selectOption: {
        productName: 'Product Name',
        customerName: 'Customer Name',
        phoneNo: 'Phone No.',
        status: 'Status',
      },
      label: {
        date: 'Date',
        to: 'to',
        preTransactionCanBeDelete: 'Pre-Transaction can be delete within a year after created. Any action will be done after the period, please contact Bluescope.',
        selectedPreTransaction: 'Selected Pre-Transaction',
        noPreTransaction: 'No Pre-Transaction',
        duplicatePreTransaction: 'Duplicate Pre-Transaction',
        preTransactionCertificate: 'Pre-Transaction certificate'

      },
      table: {
        header: {
          select: 'Select',
          date: 'Date',
          preTransactionID: 'Pre-Transaction ID',
          projectName: 'Project Name',
          productGroup: 'Product group',
          customerName: 'Customer Name',
          phoneNo: 'Phone No.',
          references: 'References',
          status: 'Status',
          action: 'Action',
        }
      },
      modal: {
        modalDelete: {
          title: 'Confirm Delete',
          message: 'Are you sure ? you want to delete this Pre-Transaction History',
        },
      }
    },
    duplicatePreTransaction: {
      breadcrumb1: 'Duplicate Pre-Transaction',
      title: 'Duplicate Pre-Transaction ',
      label: {
        productGroup: 'Product Group',
        certificate: 'Certificate',
        companyName: 'Company Name',
        projectName: 'Project Name',
        productAndDocumentary: 'Product & Documentary'
      },
      field: {
        labelCustomerNameDocument: 'Customer name in document',
      },
      button: {
        buttonSelectCustomer: 'Select customer'
      },
    },
    referancePreTransaction: {
      breadcrumb1: 'Reference',
      breadcrumb2: 'Create Reference',
      breadcrumb3: 'Edit Reference',
      title: 'Reference',
      field: {
        placeholderReferenceName: 'Reference name',
        placeholderInputReferenceName: 'Input reference name',
        placeholderEnterDescription: 'Enter description (optional)',

      },
      label: {
        noReference: 'No Reference',
        noPreTransaction: 'No Pre-Transaction',
        referenceInformation: 'Reference information',
        referenceName: 'Reference name',
        projectName: 'Project name',
        description: 'Description',
        referenceFile: 'Reference file (Upload file type: .jpg, .jpeg, .png, .gif, .doc, .docx, .xls, .xlsx, .ptt, .pttx and .pdf)',

      },
      button: {
        buttonSearch: 'Search',
        buttonCreateReference: 'Create Reference',
        buttonUploadFile: 'Upload File',
      },
      table: {
        header: {
          referenceName: 'Reference Name',
          referenceNameLower: 'Reference name',
          dateCreated: 'Date created',
          dateModify: 'Date Modified',
          action: 'Action'
        }
      },
      modal: {
        modalDelete: {
          title: 'Confirm Delete',
          message: 'Are you sure ? you want to delete this Reference.'
        },
        modalLeave: {
          title: 'Confirm leave',
          message: 'Confirm Leave? Are you sure you want to leave this page with out save. The data will not save.'
        },
      }
    },
    modal: {
      modalHistoryLog: {
        title: 'History log',
        label: {
          projectName: 'Project name :',
          customerName: 'Customer name :',
        },
        table: {
          header: {
            documentCategory: 'Document category',
            productGroup: 'Product group',
            logDescription: 'Log description',
            dateTime: 'Datetime'
          }
        }
      }
    },
    table: {
      header: {
        projectName: 'Project name',
        customerName: 'Customer name',
        createdDate: 'Created date',
        action: 'Action'
      }
    },
    document: {
      breadcrumb1: 'Documentary',
      breadcrumb2: 'View Documentary',
      title: 'Documentary',
      table: {
        header: {
          productGroup: 'Product group',
          certificate: 'Certificate',
          createdDate: 'Created date',
          action: 'Action',
        }
      },
    }
  },
  message: {
    pleaseInsertAllRequiredField: 'กรุณากรอกข้อมูลให้ครบ',
    wrongEmailFormat: 'อีเมลล์ไม่ถูกต้อง',
    saveCompleted: 'บันทึกเรียบร้อย',
    incorrectEmailOrPassword: 'อีเมลล์หรือรหัสผ่านไม่ถูกต้อง',
    pleaseSelectRewardCatalog: 'กรุณาเลือกแค็ตตาล็อคสินค้า',
    customerPhoneIsAlreadyExist: 'เบอร์โทรหรืออีเมลล์ได้ถูกลงทะเบียนในระบบไปแล้ว กรุณาเช็คกับทางดีลเลอร์อีกครั้ง',
    newPasswordAndConfirmPasswordNotMatch: 'รหัสผ่านใหม่ และการยืนยันรหัสผ่านไม่ตรงกัน',
    passwordIncorrect: 'รหัสผ่านไม่ถูกต้อง.',
    passwordIncorrectLength: 'password incorrect length.',
    usedPointsMoreThanTotalPoints: 'ใช้คะแนนมากกว่าคะแนนสะสมที่มีอยู่',
    incorrectPhoneNumberLength: 'เบอร์โทรศัพท์ ไม่ถูกต้อง',
    incorrectMobileNumberLength: 'เบอร์มือถือ ไม่ถูกต้อง',
    contactNamePleaseSelectOnlyOne: 'รายชื่อไม่มีในระบบ โปรดตรวจสอบ',
    updateAdCompleted: 'updated สถานะของออร์โธไรซ์ดีลเลอร์สำเร็จ',
    createCustomerCompleted: 'สร้างข้อมูลลูกค้าสำเร็จ',
    updateCustomerCompleted: ' updated ข้อมูลลูกค้าสำเร็จ',
    deleteCustomerCompleted: 'ลบข้อมูลลูกค้าสำเร็จ',
    createRedemptionCompleted: 'สร้างรายการแลกของรางวัลสำเร็จ',
    updateRedemptionCompleted: 'updated รายการแลกของรางวัลสำเร็จ',
    createSalesPersonCompleted: 'สร้างรายชื่อพนักงานขายสำเร็จ',
    updateSalesPersonCompleted: ' updated รายชื่อพนักงานขายสำเร็จ.',
    deleteSalesPersonCompleted: 'ลบรายชื่อพนักงานขายสำเร็จ',
    createSalesTransactionCompleted: 'สร้างรายการขายสำเร็จ',
    updateWarrantyCompleted: 'updated การรับประกันสำเร็จ',
    updateProductLengthCompleted: 'updateข้อมูลความยาวของสินค้า สำเร็จ',
    confirmDeleteSalesPerson: 'คุณแน่ใจ ที่จะลบรายชื่อพนักงานขายรายนี้?',
    confirmDeleteCustomer: 'คุณแน่ใจ ที่จะลบรายชื่อลูกค้ารายนี้',
    confirmDeleteHistorySaleTrans: 'คุณแน่ใจ ที่จะลบประวัติการขายนี้?',
    warrantyWaitingApproval: 'การรับประกันอยู่ในขั้นตอนการพิจารณา',
    nationalIdCard: 'เครื่องอ่านข้อมูลบัตรประชาชน',
    pleaseConfirmPointsAndUnits: 'กรุณายืนยันคะแนน Trueblue และปริมาณ',
    inventoryIsNotEnoughToPlace: 'ปริมาณที่เหลืออยู่ไม่เพียงพอ กรุณาติดต่อฝ่ายขาย Bluescope ที่ดูแลท่าน.',
    rewardStockItemIsNotEnoughToPlace: 'ปริมาณที่เหลืออยู่ไม่เพียงพอ กรุณาติดต่อฝ่ายขาย Bluescope ที่ดูแลท่าน',
    sendPasswordToPhone: 'ส่งรหัสชั่วคราวไปที่หมายเลขโทรศัพท์',
    phoneOrContractorInvalid: 'หมายเลขโทรศัพท์ หรือ รายชื่อ ไม่ถูกต้อง',
    formatFileIncorrect: 'รูปแบบ CSV file ไม่ถูกต้อง.',
    incorrectProjectNameLength: 'สูงสุด 100 ตัวอักษร',
    incorrectProjectName500Length: 'สูงสุด 500 ตัวอักษร',
    incorrectProjectAddrLength: 'สูงสุด 500 ตัวอักษร',
    incorrectAdditionalProjectLength: 'สูงสุด 500 ตัวอักษร',
    warrantyDetailExceedQuantity: 'ระบุปริมาณเกิน, กรุณาตรวจสอบปริมาณ',
    warrantyPleaseSelectEnvDetail: 'กรุณากรอกข้อมูลให้ครบ',
    warrantyPleaseApplicationDetail: 'กรุณากรอกข้อมูลให้ครบ',
    warrantyConditionOutOfLength: 'พื้นที่ตั้งโครงการไม่อยู่ในการพิจารณารายละเอียดของสภาพแวดล้อม',
    warrantySelectEnvDetailSpecial: 'Special product, กรุณาติดต่อฝ่ายขาย Bluescope ที่ดูแลท่าน',
    numberEqualOrGreater: 'ระบุตัวเลขเท่ากับหรือมากกว่า',
    numberMustBe: 'ระบุตัวเลขเป็น',
    numberMustBetween: 'ระบุตัวเลขระหว่าง',
    warrantyNoWarrantyOffered: 'ไม่สามารถออกใบรับประกันได้, กรุณาติดต่อฝ่ายขาย Bluescope ที่ดูแลท่าน',
    warrantyOfferedNA: 'ใบรับประกันจะถูกส่งไปถึงผู้ดูแลระบบรับประกันของ Bluescope เพื่อดำเนินการต่อไป',
    warrantyEnvSpecialProduct: 'Special product จะถูกส่งไปถึงผู้ดูแลระบบรับประกันของ Bluescope เพื่อดำเนินการต่อไป',
    warrantyPleaseSelectResidential: 'ไม่สามารถออกใบรับประกันได้ กรุณาเลือกประเภท งานที่พักอาศัย',
    warrantyPleaseSelectNonResidential: 'ไม่สามารถออกใบรับประกันได้ กรุณาเลือกประเภท งานโครงการ',
    warrantyPleaseSelectResidentialMY: 'Your warranty application cannot be processed. Please contact BlueScope for further assistance',
    warrantyPleaseSelectNonResidentialMY: 'Your warranty application cannot be processed. Please contact BlueScope for further assistance',
    warrantyNoTemplateForm: 'Error-01 ไม่มีแบบฟอร์มใบรับประกัน กรุณาติดต่อฝ่ายขาย Bluescope ที่ดูแลท่าน',
    warrantySelectEnvDetailNA: 'ไม่สามาถออกใบรับประกันได้, กรุณาติดต่อฝ่ายขาย Bluescope ที่ดูแลท่าน',
    warrantyConditionNotPass: 'ใบรับประกันจะถูกส่งไป Bluescope เพื่อพิจารณาต่อไป',
    warrantyMaximumYearExceed: 'The maximum year cannot exceed corrosion warranty.',
    warrantyPleaseSelectCategory: 'โปรดเลือกประเภท',
    pleaseSelectSegment: 'โปรดเลือกกลุ่ม',
    otpSentSuccess: 'OTP has been sent, Please check your message.',
    otpResendSuccess: 'OTP has been resend, Please check your message.',
    incorrectOTP: 'Incorrect OTP.',
    wrongAnswer: 'Wrong Answer',
    wrtNoPleaseSelectOnlyOne: 'Warranty is invalid. Please select only one warranty.',
    updateDocumentaryCompleted: 'Documentary is successfully created.',
    blueScopeContactBack: 'ต้องการให้ BlueScope ติดต่อกลับ?',
    privacyPolicyRegisterYearWarranty1: 'นโยบายความเป็นส่วนตัว : เมื่อคลิก "ยอมรับ" แสดงว่าท่านยินยอมที่จะ ส่งข้อมูลของท่านให้กับบริษัทฯ ซึ่งตกลงจะใช้ข้อมูลตามนโยบายความ เป็นส่วนตัว และวัตถุประสงค์ทางการตลาด กด ',
    privacyPolicyRegisterYearWarranty2: 'ลิงค์นี้ ',
    privacyPolicyRegisterYearWarranty3: 'เพื่อดูนโยบายความเป็นส่วนตัว ของบริษัท เอ็นเอส บลูสโคป (ประเทศไทย) จํากัด',
    errorSalesPersonMemberTypeIsContractor: 'Sales person member_type__c is Contractor, Please contact Bluescope',
    preTransactionHistorySuccessDeleted: 'Pre-Transaction History  is successfully deleted.',
    duplicatePreTransactionSuccess: 'Duplicate Pre-Transaction success.',
    referenceSuccessfullyCreated: 'Reference is successfully created',
    referenceSuccessfullyEdited: 'Reference is successfully edited.',
    referenceSuccessfullyDeleted: 'Reference is successfully deleted.',
    deleteReferenceFileSuccess: 'Delete reference file success.',
    coilTransactionSuccessfully: 'You have been coil-transaction successfully.',
    coilTransferWaitingApproval: 'You have completed coil transfer, waiting for approval.',
    youApprovedCoil: 'You have been approved coil.',
    youClearedCoil: 'You have been cleared coil.',
    receivedYourIssueCheckGetBackYouContact: `Bluescope has received your issue. We'll check and get back to you by contact.`,
    pleaseLoginWithSmartPhone: 'Please login with smart phone',
    projectDocumentSuccessCreate: 'Project Document is successfully created.',
    projectDocumentSuccessUpdate: 'Project Document is successfully updated.',
    linkProjectSuccess: 'Link project success.',
    certificateSuccessCreate: 'Certificate is successfully created.',
    certificateSuccessUpdate: 'Certificate is successfully updated.',
    cantFoundDocumentary: `Can't found documentary data.`,
    requestEditProjectDocumentSuccess: 'Request edit project document success.',
    maximumFileSize1MB: `File size is too big, maximum file's size is 1 MB`,
    maximumFileSize20MB: `File size is too big, maximum file's size is 20 MB`,
    automaticEnvironmentNotFound: `Automatic environment not found`,
    environmentFromProjectAddressNotFound: `environment_from_project_address__c not found`,
    errorCoilNoLength: 'คอยล์ [coilName] ได้ขายหมด ม้วนแล้ว ความยาว เหลือ 0 เมตร',
    warrantySelfDeclaredConditionNotFound: `Warranty self-declared conditions not found`,
    warrantySpecialRejectEnvironment: `เนื่องจากเงื่อนไข และข้อกำหนดไม่เป็นไปตามการรับประกันตามมาตรฐาน โปรดติดต่อฝ่ายขายที่ดูแลท่านเพื่อความช่วยเหลือเพิ่มเติม`,
    warrantySpecialReject: `เนื่องจากเงื่อนไข และข้อกำหนดไม่เป็นไปตามการรับประกันตามมาตรฐาน โปรดติดต่อฝ่ายขายที่ดูแลท่านเพื่อความช่วยเหลือเพิ่มเติม`,
    warrantySpecialWaitingToBeApprove: `เนื่องจากเงื่อนไข และข้อกำหนดไม่เป็นไปตามการรับประกันตามมาตรฐาน กรุณารอการอนุมัติจากผู้ดูแลระบบของบลูสโคป`,
    warrantySpecialWaitingToBeApproveAll: `Warranty is special, Waiting admin to be approve all.`, // TODO: re wording
  },
  certificate: {
    breadcrumb1: 'Home',
    breadcrumb2: 'Project Document',
    breadcrumb3: 'Pre-Transaction',
    breadcrumb4: 'Create Certificate',
    breadcrumb5: 'Regenerate Certificate',
    title: 'Pre-Transaction certificate : ',
    label: {
      preTransactionSuccessCreate: 'Pre-Transaction is successfully created, It is visible in history and sales orders',
      for: 'for',
      history: 'history',
      or: 'or',
      project: 'project',
      referenceMenuMaintain: ' reference menu, you can maintain references (text, images, and files).',
      inSalesOrder: 'In sales orders, you can switch the product group from Pre-Transaction to Sales Transaction.',
      preTransactionAndCertificate: 'Pre-Transaction & Certificate',
      selectProduct: 'Select products',
      information: 'Information',
      certificate: 'Certificate',
      confirmPreTransaction: 'Confirm Pre-transaction',
      projectName: 'Project name',
    },
    placeholder: {
      placeholderSearchProductGroup: 'Search product group',
      placeholderProjectName: 'Project name'
    },
    table: {
      header: {
        select: 'Select',
        productGroup: 'Product Group',
        company: 'Company',
        document: 'Document',
      }
    },
    modal: {
      modalConfirmPreTransaction: {
        pleaseConfirmProjectNameAndSpecifyCompany: 'Please confirm project name and specify company to use in document.',
        afterCompleteSave: `After a complete save, you won't be able to change or re-generate it.`
      }
    }
  },
  preSaleDocumentary: {
    breadcrumb1: 'Home',
    breadcrumb2: 'Project Document',
    breadcrumb3: 'Pre-Transaction',
    breadcrumb4: 'Create Documentary',
    title: 'Pre-Transaction documentary : ',
    label: {
      preTransactionSuccessCreate: 'Pre-Transaction is successfully created, It is visible in history and sales orders',
      for: 'for',
      history: 'history',
      or: 'or',
      project: 'project',
      referenceMenuMaintain: ' reference menu, you can maintain references (text, images, and files).',
      inSalesOrder: 'In sales orders, you can switch the product group from Pre-Transaction to Sales Transaction.',
      preTransactionAndDocumentary: 'Pre-Transaction & Documentary',
      selectProduct: 'Select products',
      information: 'Information',
      documentary: 'Documentary',
      confirmPreTransaction: 'Confirm Pre-transaction',
      projectName: 'Project name',
    },
    placeholder: {
      placeholderSearchProductGroup: 'Search product group',
      placeholderProjectName: 'Project name'
    },
    table: {
      header: {
        select: 'Select',
        productGroup: 'Product Group',
        company: 'Company',
        document: 'Document',
      }
    },
    modal: {
      modalConfirmPreTransaction: {
        pleaseConfirmProjectNameAndSpecifyCompany: 'Please confirm project name and specify company to use in document.',
        afterCompleteSave: `After a complete save, you won't be able to change or re-generate it.`
      }
    }
  },
  projectReference: {
    breadcrumb1: 'Home',
    breadcrumb2: 'Project reference',
    breadcrumb3: 'Reference',
    breadcrumb4: 'Create reference',
    breadcrumb5: 'Edit reference',
    title: 'Project Reference',
    label: {
      customerSelection: 'Customer Selection',
      selectCustomerCreatePreTransaction: 'Select customer for create Pre-Transaction.',
      reference: 'Reference',
      referenceList: 'Reference List',
      noReference: 'No Reference',
      createReference: 'Create Reference',
      editReference: 'Edit Reference',
      referenceInformation: 'รายละเอียดฐานข้อมูลโครงการ',
      referenceName: 'ชื่อฐานข้อมูล',
      saleTransactionID: 'Sales transaction ID',
      projectName: 'Project name',
      description: 'ข้อมูลเพิ่มเติม',
      referenceFile: 'ไฟล์ข้อมูล (ประเภทไฟล์:  .jpg, .jpeg, .png, .gif, .doc, .docx, .xls, .xlsx, .ptt, .pttx and .pdf)',
      warrantyNo: 'Warranty No.',
      maximumFileSize: `*ขนาดไฟล์ใหญ่สุดไม่เกิน 20 MB`,
    },
    button: {
      buttonSelectCustomer: 'Select Customer',
      buttonSearch: 'Search',
      buttonCreateReference: 'Create Reference',
      buttonUploadFile: 'อัพโหลดไฟล์',
    },
    field: {
      placeholderSearchNameReferenceProjectSaleTransaction: 'Name of reference, project or sales transaction ID',
      placeholderInputReferenceName: 'Input reference name',
      placeholderSelectSalesTransactionID: 'Select sales transaction ID',
      placeholderProjectName: 'Select project name',
      placeholderInputDescription: 'Input description(optional)',
      placeholderSelectWarrantyNo: 'Select warranty No.',
    },
    table: {
      header: {
        referenceName: 'Reference Name',
        referenceNameLower: 'ชื่อเอกสาร',
        warrantyNo: 'Warranty No.',
        projectName: 'Project Name',
        dateCreated: 'Date created',
        dateModify: 'Date Modified',
        action: 'ตัวอย่าง',
        updatedDate: 'วันที่อัพโหลด',
        select: 'เลือก',
      }
    },
    modal: {
      modalSelectReferenceType: {
        label: {
          pleaseSelectReferenceType: 'Plese select reference type',
        },
        button: {
          buttonWarranty: 'Warranty',
          buttonProjectDocument: 'Project Document',
        }
      },
      modalConfirmDelete: {
        title: 'Confirm Delete',
        message: 'Are you sure ? you want to delete this Reference.'
      }
    }
  },
  barcode: {
    breadcrumb1: 'หน้าหลัก',
    breadcrumb2: 'การขอโอนคอยล์เข้า',
    title: 'Scan Barcode เพื่อขอโอนคอยล์',
    label: {
      coilId: 'ค้นหาเลขคอยล์',
      back: 'Back',
    },
    field: {
      placeholderInsertCoilId: 'ตัวอย่าง MMxPxxxxx',
    },
    modal: {
      modalBarcodeDuplicate: {
        title: 'Coil Duplicate',
        message: 'You already have coil {coilName} in stock.'
      },
      modalCoilTranferInformation: {
        title: 'Coil Transfer Information',
        label: {
          coilId: 'Coil ID',
          coilName: 'Coil Name',
          allAmount: 'All amount (m.)',
          tranferAmount: 'Transfer Amount (m.)',
        }
      }
    }
  },
  coilApproval: {
    breadcrumb1: 'หน้าหลัก  ',
    breadcrumb2: 'คลังสินค้า และการขายคอยล์',
    breadcrumb3: 'อนุมัติโอนคอยล์ออกไป',
    title: 'คลังสินค้า และการขายคอยล์ > อนุมัติโอนคอยล์ออกไป',
    selectOption: {
      all: 'All',
      coilID: 'Coil ID',
      coilName: 'Coil Name',
      adRFName: 'AD/RF Name',
      pending: 'Pending',
      approved: 'Approved',
      cleared: 'Cleared',
    },
    label: {
      category: 'Category',
      status: 'Status',
      date: 'Date',
      dateTo: 'to',
      noDataCoil: 'No Data Coil',
    },
    field: {
      placeholderSearch: 'Search',
      selectStartDate: 'Select Start Date',
      selectEndDate: 'Select End Date',
      selectCoilApproval: 'Selected Coil Approval'
    },
    button: {
      buttonSearch: 'Search',
      buttonClear: 'Clear',
      buttonApprove: 'Approve',
      buttonConformAndApprove: 'Confirm and Approve',
      buttonConfirmAndClear: 'Confirm and Clear',
    },
    table: {
      header: {
        coilId: 'Coil ID',
        coilName: 'Coil Name',
        adRfName: 'AD/RF name',
        date: 'Date',
        tranferAmount: 'Transfer Amount (m.)',
        status: 'Status',
        action: 'Action',
      }
    },
    modal: {
      modalCoilApproval: {
        title_1: 'Confirm Approve Coil',
        title_2: 'Confirm Clear Coil',
        table: {
          header: {
            no: 'No',
            coilId: 'Coil ID',
            coilName: 'Coil Name',
            thickness: 'Thickness',
            color: 'Color',
            aging: 'Aging',
            reWeight: 'Re. Weight (kg)',
            lengthBalance: 'Length Balance (m.)',
            tranferAmount: 'Transfer Amount (m.)'
          }
        }
      },
      modalCoilApprovalDetail: {
        title: 'Detail Coil',
        label: {
          coilName: 'Coil Name',
          coilId: ' Coil ID',
          tranferAmount: 'Transfer Amount (m.)',
          reWeight: 'Re. Weight (kg)',
          lengthBalance: 'Length Balance (m.)',
          thickness: 'Thickness',
          color: 'Color',
          aging: 'Aging',
        }
      }
    }
  },
  projectDocument: {
    breadcrumb1: 'Home',
    breadcrumb2: 'Project Document',
    breadcrumb3: 'View warranty',
    breadcrumb4: 'Warranty Template',
    breadcrumb5: 'Create documentary',
    breadcrumb6: 'Create reference',
    breadcrumb7: 'Create certificate',
    breadcrumb8: 'Edit reference',
    breadcrumb9: 'Regenerate Certificate',
    title: 'เอกสารโครงการ',
    label: {
      createProjectDocument: 'สร้างเอกสารโครงการ',
      date: 'สร้างเมื่อวันที่',
      dateTo: 'ถึง',
      selectStartDate: 'Select Start Date',
      selectEndDate: 'Select End Date',
      enabled: 'Enabled',
      when: 'when',
      aSalesOrderIsPresent: 'a sales order is present',
      noWarrantyTemplate: 'No Warranty Template',
      noDocumentary: 'No Documentary',
      noCertificate: 'No Certificate',
      noReference: 'No Reference',
      warranty: 'Warranty',
      warrantyTemplate: 'Warranty Template',
      allReferenceFile: 'ไฟล์ทั้งหมด',
      noReferenceFile: 'No Reference File',
      documentary: 'Documentary',
      certificate: 'Certificate',
      selectProduct: 'Select product',
      whenever: 'whenever',
      aSaleOrderForTheSameProductGroupIsPresent: 'เอกสารเสร็จสมบูรณ์เมื่อมีการสร้างการขายจากกลุ่มสินค้าเดียวกัน',
      preCertificate: 'เอกสารก่อนการขาย',
      postCertificate: 'เอกสารหลังการขาย (เสร็จสมบูรณ์เมื่อมีการสร้างการขาย)',
      postCertificate2: `*มอก.2228-2559 สำหรับคอยล์ที่ผลิตก่อนวันที่ 8/11/66`,
      postCertificate3: `*มอก.2228-2565 สำหรับคอยล์ที่ผลิตตั้งแต่วันที่ 8/11/66`,
      specifyCompanyNameInCertificate: 'Specify the company name in certificate',
      viewList: 'มุมมองลิสต์',
      viewIcon: 'มุมมองไอคอน',
      andUntilTheProductionDate: "and until the production date",
    },
    placeholder: {
      search: 'Search',
      searchWarranty: 'Search Sales Trans. ID',
      searchProductGroup: 'Search product group'
    },
    button: {
      buttonProjectInformation: 'ข้อมูลโครงการ',
      buttonSaleOrder: 'สร้างการขาย',
      buttonViewAllTemplate: 'ดูตัวอย่างใบรับประกันทั้งหมด',
      buttonCreateDocumentary: 'สร้างเอกสาร',
      buttonCreateCertificate: 'สร้างเอกสาร',
      buttonCreateReference: 'สร้างฐานข้อมูล',
      buttonSelectReferenceFile: 'Select Reference File',
      buttonPreviewPostCertificate: 'Preview Post Certificate',
      buttonPreviewPreCertificate: 'Preview Pre Certificate',

    },
    table: {
      header: {
        preTransactionId: 'Pre-Transaction ID',
        projectName: 'ชื่อโครงการ',
        customerName: 'ลูกค้า',
        saleTransactionId: 'เลขที่การขาย',
        createdDate: 'วันที่สร้าง',
        editedDate: 'วันที่แก้ไข',
        action: 'ตัวเลือก',
        name: 'ตัวอย่างใบรับประกัน',
        nameDocument: 'ชื่อเอกสาร',
        downloadDate: 'วันที่ดาวน์โหลด',
        ID: 'เลขที่เอกสาร',
        productGroup: 'กลุ่มสินค้า',
        referenceName: 'ชื่อฐานข้อมูล',
        modifiedName: 'วันที่สร้าง',
        uploadedDate: 'Uploaded date',
        select: 'Select',
        code: 'Code',
        category: 'Category',
        no: 'No',
        view: 'View',
        preview: 'Preview',
        projectDocumentNo: 'เลขที่โครงการ',
      }
    },
    modal: {
      label: {
        confirmToDelete: 'Confirm to Delete',
        doYouWantToDeleteTheReferenceFile: 'Do you want to delete the {count} reference files ?  ',
        doYouWantToDelete: 'Do you want to delete ',
        projectReference: '“Project reference”',
        reference: 'reference ?',
        confirmCompanyName: 'Confirm Company Name',
        pleaseConfirmSpecifyCompanyNameToUseInDocumenary: 'Please confirm specify company name to use in documentary 1.BlueScope Zacs use',
        afterACompleteSave: 'After a complete save,',
        youWontBeAbleToChangeOrReGenerateIt: `you won't be able to change or re-generate it.`,
        pleaseConfirmSpecifyCompanyNameToUseInCertificate: 'Please confirm specify company name to use in certificate 1.BlueScope Zacs use',
      },
      modalSaleTransaction: {
        title: 'Sales Transaction',
        label: {
          preTransactionID: 'Pre-Transaction ID :',
          projectName: 'Project name :',
          customerName: 'Customer name :',
        },
        table: {
          header: {
            saleTransactionID: 'Sales Transaction ID',
            createdDate: 'Created date',
          }
        },
      },
      previewWarranty: {
        title: 'Warranty No. [warrantyNo] : [productGroup]'
      },
      previewDocumentary: {
        title: 'Documentary : [productGroup]'
      },
      previewCertificate: {
        title: 'Certificate : [productGroup]'
      },
    },
    tab: {
      titleWarrantyGuideline: 'ประเมินการรับประกัน',
      titleWarrantyTemplate: 'ตัวอย่างใบรับประกัน',
      titleWarranty: 'ใบรับประกัน',
      titleDocumentary: 'เอกสารอ้างอิง',
      titleCertificateAndLetter: 'หนังสือรับรองและจดหมาย',
      titleProjectDatabase: 'ฐานข้อมูลโครงการ',
      descriptionWarrantyGuideline: 'เพื่อประเมินปีรับประกันในสภาพแวดล้อมที่ใกล้ทะเล',
      descriptionWarrantyTemplate: 'เพื่อดูตัวอย่างใบรับประกันของแต่ละผลิตภัณฑ์',
      descriptionWarranty: 'เพื่อสร้างใบรับประกัน',
      descriptionDocumentary: 'เพื่อสร้างเอกสารอื่นๆที่เกี่ยวข้อง เช่น โบรชัวร์, เอกสารข้อมูลผลิตภัณฑ์',
      descriptionCertificateAndLetter: 'เพื่อสร้างเอกสารอื่นๆที่เกี่ยวข้อง เช่น เอกสารมอก., MiT, HACCP  และเอกสารอื่นๆที่เกี่ยวข้อง',
      descriptionProjectDatabase: ' เพื่ออัพโหลดเอกสารต่างๆที่เกี่ยวข้องกับโครงการ',
    }
  },
  notificationManagement: {
    breadcrumb1: "Home",
    breadcrumb2: "Notification Management",
    title: "การแจ้งเตือน",
    label: {
      from: "ตั้งแต่วันที่",
      to: "ถึง",
      selectStartDate: "Select Start Date",
      selectEndDate: "Select End Date",
      noData: "No data"
    },
    field: {
      placeholderSearch: "Search ID, Subject",
    },
    button: {
      buttonSearch: "ค้นหา",
    },
    table: {
      header: {
        id: 'เลขที่การแจ้งเตือน',
        subject: 'เรื่อง',
        publicationDate: 'วันที่เผยแพร่',
        acceptedDate: 'วันที่รับทราบ',
        action: 'ตัวเลือก'
      }
    }
  },
  warrantyConditions: {
    field: {
      placeholderInsertDistanceNumber: 'Insert distance number',
      placeholderSelectThePreferredOption: 'Select the preferred option',
      placeholderInsertDescription: 'Insert description',
    },
    label: {
      yes: 'Yes',
      no: 'No',

    },
  },
}