import React from 'react'
import ClassNames from 'classnames'
import PropTypes from 'prop-types'
import {
  ButtonBaseWrapper,
  ButtonIconBaseWrapper
} from './styled'
// import {
//   default as VARIABLES
// } from './../../../themes/styles/bases/variables'
// import {
//   default as TYPOGRAPHYS
// } from './../../../themes/styles/bases/typographys'

class ButtonIconBase extends React.PureComponent {
  render() {
    const {
      // Option
      children,
      className,
      // Default
      spacingRight,
      spacingLeft
    } = this.props

    return (
      <ButtonIconBaseWrapper
        className={ClassNames(
          'button-icon-base',
          // Option
          className
        )}
        theme={{
          // Default
          spacingRight,
          spacingLeft
        }}
      >
        {children}
      </ButtonIconBaseWrapper>
    )
  }

  static defaultProps = {
    // Option
    // children: undefined,
    // className: undefined,
    // Default
  }

  static propTypes = { // TYPE > node, string, number, bool, array, object, symbol, func
    /**
    * Additional classes
    */
    className: PropTypes.string,

    /**
    * Additional Text, HTML, Component
    */
    children: PropTypes.oneOfType([
      PropTypes.node,
      PropTypes.string
    ])
  }
}

/**
 * ButtonBase description:
 * - button
 */

export class ButtonBase extends React.PureComponent {
  render () {
    const {
      // Option
      children,
      className,
      // Default
      width,
      height,
      padding,
      paddingHorizontal,
      paddingVertical,
      paddingTop,
      paddingRight,
      paddingBottom,
      paddingLeft,
      bgColor,
      bgColorDisabled,
      bgHoverColor,
      borderWidth,
      borderStyle,
      borderColor,
      borderHoverColor,
      borderRadius,
      fluid,
      disabled,
      onClick,
      textHoverColor
    } = this.props

    return (
      <ButtonBaseWrapper
        className={ClassNames(
          'button-base',
          {'is-fluid': fluid},
          {'is-disabled': disabled},
          // Option
          className
        )}
        theme={{
          // Default
          width,
          height,
          padding,
          paddingHorizontal,
          paddingVertical,
          paddingTop,
          paddingRight,
          paddingBottom,
          paddingLeft,
          bgColor,
          bgHoverColor,
          bgColorDisabled,
          borderWidth,
          borderStyle,
          borderColor,
          borderHoverColor,
          borderRadius,
          textHoverColor
        }}
        onClick={onClick}
      >
        <div className='button-body-base'>
          {children}
        </div>
      </ButtonBaseWrapper>
    )
  }

  static Icon = ButtonIconBase

  static defaultProps = {
    // Option
    // children: undefined,
    // className: undefined,
    // Default
    bgColor: '#333333',
    bgColorDisabled: '#EEEEEE'
  }

  static propTypes = { // TYPE > node, string, number, bool, array, object, symbol, func
    /**
    * Additional classes
    */
    className: PropTypes.string,

    /**
    * Additional Text, HTML, Component
    */
    children: PropTypes.oneOfType([
      PropTypes.node,
      PropTypes.string
    ])
  }
}
