import React from 'react';
import { ROUTE_PATH } from '../../helpers/constants/route';
import { handleSortData } from '../../helpers/functions/data';
import { getLanguage } from '../../helpers/functions/language';
import { toast } from '../../helpers/functions/main';
import { getRouteData, redirect, redirectData, setSideMenuActive } from '../../helpers/functions/route';
import {
  Breadcrumb,
  Button,
  Field,
  Grid,
  PaginationControl,
  Section,
  ShapeContainer,
  Table,
  TableAction,
  Text,
  TitlePage,
} from './../../components';
import {
  ICONS,
  VARIABLES,
} from './../../themes';
// import { toast } from '../../helpers/functions/main';

export class ProfileMappingProductListScreen extends React.Component {

  constructor(props) {
    super(props);
    setSideMenuActive(props);
    const routeDataList = getRouteData(props, 'dataList');
    const routeDataNoLengthList = getRouteData(props, 'dataNoLengthList');
    const profile = getRouteData(props, 'profile');
    const dataList = handleSortData(routeDataList, {
      sortTarget: 'itemDescription',
    })

    this.state = {
      activePage: 1,
      dataPerPage: 10,
      dataList,
      oldDataList: dataList,
      dataNoLengthList: routeDataNoLengthList || [],
      searchValue: '',
      filterValue: '',
      sortIcon: {
        itemDescription: true,
        lotNumber: true,
        thicknessMM: true,
        bmtMM: true,
        createdDate: true,
        fAging: true,
        topPaintColor: true,
        fRemainingweight: true,
        fTotalRemaininglength: true,
      },
      profile
    }
  }

  productDetail = {
    handleClearIcon: () => {
      const {
        sortIcon,
      } = this.state;
      let newSortIcon = {};
      Object.keys(sortIcon).forEach(e => {
        newSortIcon[e] = true;
      });
      this.setState({
        sortIcon: newSortIcon
      })
    },
    handleSortIcon: (type) => {
      const {
        sortIcon,
      } = this.state;
      let newSortIcon = {};
      let oldType = type;
      Object.keys(sortIcon).forEach(e => {
        if (sortIcon[e] === false) {
          oldType = e;
        }
        newSortIcon[e] = true;
      });
      Object.keys(sortIcon).forEach(e => {
        if (e === type) {
          if (type === oldType) {
            newSortIcon[e] = !sortIcon[e];
          }
        }
      })
      this.setState({
        sortIcon: newSortIcon
      })
      return newSortIcon
    },
    onClickSort: (type) => {
      const {
        dataList,
      } = this.state;
      const newSortIcon = this.productDetail.handleSortIcon(type);
      this.setState({
        dataList: handleSortData(dataList, {
          sortTarget: type,
          sortTargetType: type === 'createdDate' ? 'date' : undefined,
          orderType: newSortIcon[type] ? 'desc' : 'asc'
        })
      })
    },
    getFilterDataList: () => {
      const {
        dataList,
        activePage,
        dataPerPage,
        filterValue,
      } = this.state;
      const firstIndex = (activePage - 1) * dataPerPage;
      const lastIndex = activePage * dataPerPage;
      const filterList = dataList.filter((e, i) => {
        if (filterValue.length) {
          if (
            e.itemDescription.toLowerCase().indexOf(filterValue.toLowerCase()) === -1 &&
            e.lotNumber.toLowerCase().indexOf(filterValue.toLowerCase()) === -1 &&
            e.topPaintColor.toLowerCase().indexOf(filterValue.toLowerCase()) === -1
          ) {
            return false;
          }
        }
        return true;
      })
      return filterList.filter((e, i) => i >= firstIndex && i < lastIndex);
    }
  }

  input = {
    onChangeInput: (ev) => {
      const name = ev.target.name;
      const value = ev.target.value;
      this.setState({
        [name]: value,
      })
    },
    onClickSearch: () => {
      const {
        searchValue,
        oldDataList,
        dataNoLengthList,
      } = this.state;
      const filterValue = searchValue.trim();
      this.setState({
        filterValue,
        activePage: 1,
        dataList: oldDataList,
      }, () => {
        const dataList = this.productDetail.getFilterDataList();
        const findCoilNoLength = (dataNoLengthList || []).filter(e => (e.lotNumber).toLowerCase() === filterValue.toLowerCase())[0]
        if (
          dataList.length === 0 &&
          findCoilNoLength
        ) {
          toast.error(getLanguage('message.errorCoilNoLength').replace('[coilName]', findCoilNoLength.lotNumber))
        }
      })
    },
    onAssign: (e) => {
      const {
        oldDataList,
        profile,
        dataNoLengthList,
      } = this.state

      redirectData(`${ROUTE_PATH.PROFILE_MAPPING_PRODUCT_MANAGE.LINK}/${e.id}`, {
        data: e,
        productList: oldDataList,
        dataNoLengthList,
        profile: profile
      })

      // if(e.coilWidthMM < 1219){
      //   toast.error(getLanguage('profileMapping.form.shopInfo.message', ''));
      // } else {
      //   redirectData(`${ROUTE_PATH.PROFILE_MAPPING_PRODUCT_MANAGE.LINK}/${e.id}`,{
      //     data: e,
      //     productList: oldDataList,
      //     profile: profile
      //   })
      // }
    }
  }

  pagination = {
    onPaginationChange: (pageNumber) => {
      this.setState({
        activePage: pageNumber,
      })
    }
  }

  render() {
    const {
      activePage,
      dataPerPage,
      dataList,
      searchValue,
      sortIcon,
    } = this.state
    const totalData = dataList.length;
    return (
      <React.Fragment>
        <Breadcrumb>
          <Breadcrumb.List
            link
            name='Home'
            onClick={() => {
              redirect(ROUTE_PATH.HOME.LINK)
            }}
          />
          <Breadcrumb.List name='/' />
          <Breadcrumb.List
            link
            name='Inventory'
          // onClick={() => {
          //   redirect(ROUTE_PATH.INVENTORY_INDEXES.LINK)
          // }}
          />
          {
            <React.Fragment>
              <Breadcrumb.List name='/' />
              <Breadcrumb.List
                link
                name='Profile Mapping'
                onClick={() => {
                  redirect(ROUTE_PATH.PROFILE_MAPPING.LINK)
                }}
              />
              <Breadcrumb.List name='/' />
              <Breadcrumb.List
                link
                name='Conxecute'
                onClick={() => {
                  redirect(ROUTE_PATH.PROFILE_MAPPING_HARDWARE_AND_TOKO_SHOP_INFO.LINK)
                }}
              />
            </React.Fragment>
          }
          <Breadcrumb.List name='/' />
          <Breadcrumb.List
            link
            name='Products Quantity'
            onClick={() => { redirect(ROUTE_PATH.PROFILE_MAPPING_PRODUCT_GROUP.LINK) }}
          />
          <Breadcrumb.List name='/' />
          <Breadcrumb.List name='Products Detail' />
        </Breadcrumb>
        <Section ui='content'>
          <Section spacingBottom='35'>
            <Grid
              gutterVertical='15'
              justify='space-between'
            >
              <Grid.Column>
                <TitlePage
                  src={ICONS['ic-page-inventory.svg']}
                  name={'Conxecute > Products Quantity > Product Detail'}
                />
              </Grid.Column>
              <Grid.Column>
                <Grid gutter='35'>
                  <Grid.Column>
                    <Grid gutter='10'>
                      <Grid.Column>
                        <Field name='searchValue'
                          ui='blockLabelVerticalSearch'
                          width='285'
                          placeholder={getLanguage('productDetail.placeholder', '')}
                          value={searchValue}
                          onChange={this.input.onChangeInput}
                        />
                      </Grid.Column>
                      <Grid.Column>
                        <Button
                          lightBlue
                          name={getLanguage('productDetail.buttonSearch', '')}
                          width={VARIABLES.BUTTON.WIDTHS.W_105}
                          onClick={this.input.onClickSearch}
                        />
                      </Grid.Column>
                    </Grid>
                  </Grid.Column>
                </Grid>
              </Grid.Column>
            </Grid>
          </Section>
          <ShapeContainer
            ui='content'
            justify='space-between'
          >
            <Section spacingBottom='45'>
              <Table
                striped
                action
              >
                <Table.Section>
                  <Table.Row>
                    <Table.HeadColumn
                      minWidth={VARIABLES.TABLE.COLUMN_WIDTHS.PROFILE_MAPPING_LIST.AD.COIL_NAME}
                      sortAscending={sortIcon.itemDescription}
                      onClickSort={() => {
                        this.productDetail.onClickSort('itemDescription');
                      }}
                    >
                      Coil Name
                    </Table.HeadColumn>
                    <Table.HeadColumn
                      minWidth={VARIABLES.TABLE.COLUMN_WIDTHS.PROFILE_MAPPING_LIST.AD.COIL_ID}
                      sortAscending={sortIcon.lotNumber}
                      onClickSort={() => {
                        this.productDetail.onClickSort('lotNumber')
                      }}
                    >
                      Coil ID
                    </Table.HeadColumn>
                    <Table.HeadColumn
                      minWidth={VARIABLES.TABLE.COLUMN_WIDTHS.PROFILE_MAPPING_LIST.AD.THICKNESS}
                      // sortAscending={sortIcon.thicknessMM}
                      // onClickSort={() => {
                      //   this.productDetail.onClickSort('thicknessMM');
                      // }}
                      sortAscending={sortIcon.bmtMM}
                      onClickSort={() => {
                        this.productDetail.onClickSort('bmtMM');
                      }}
                    >
                      {/* Thickness */}
                      Thickness (BMT)
                    </Table.HeadColumn>
                    <Table.HeadColumn
                      minWidth={VARIABLES.TABLE.COLUMN_WIDTHS.PROFILE_MAPPING_LIST.AD.WIDTH}
                      sortAscending={true}
                    // onClickSort={}
                    >
                      Width
                    </Table.HeadColumn>
                    <Table.HeadColumn
                      minWidth={VARIABLES.TABLE.COLUMN_WIDTHS.PROFILE_MAPPING_LIST.AD.CREATE_DATE}
                      sortAscending={sortIcon.createdDate}
                      onClickSort={() => {
                        this.productDetail.onClickSort('createdDate');
                      }}
                    >
                      Create Date
                    </Table.HeadColumn>
                    <Table.HeadColumn
                      alignRight
                      minWidth={VARIABLES.TABLE.INFO_WIDTHS.COLOR}
                      sortAscending={sortIcon.topPaintColor}
                      onClickSort={() => {
                        this.productDetail.onClickSort('topPaintColor');
                      }}
                    >
                      {getLanguage('productDetail.table.header.color', '')}
                    </Table.HeadColumn>
                    <Table.HeadColumn
                      alignRight
                      minWidth={VARIABLES.TABLE.COLUMN_WIDTHS.PROFILE_MAPPING_LIST.AD.QUANTITY}
                      sortAscending={true}
                    // onClickSort={}
                    >
                      Quantity
                    </Table.HeadColumn>
                    <Table.HeadColumn
                      alignCenter
                      minWidth={VARIABLES.TABLE.COLUMN_WIDTHS.PROFILE_MAPPING_LIST.AD.UNIT}
                      sortAscending={true}
                    // onClickSort={}
                    >
                      Unit
                    </Table.HeadColumn>
                    <Table.HeadColumn
                      alignRight
                      minWidth={VARIABLES.TABLE.COLUMN_WIDTHS.PROFILE_MAPPING_LIST.AD.LENGTH}
                      sortAscending={true}
                    // onClickSort={}
                    >
                      Length (m.)
                    </Table.HeadColumn>
                    <Table.HeadColumn
                      noSort
                      alignCenter
                      maxWidth={VARIABLES.TABLE.COLUMN_WIDTHS.PROFILE_MAPPING_LIST.AD.ACTION}
                      minWidth={VARIABLES.TABLE.COLUMN_WIDTHS.PROFILE_MAPPING_LIST.AD.ACTION}
                    >
                      Action
                    </Table.HeadColumn>
                  </Table.Row>
                </Table.Section>
                <Table.Section>
                  {
                    this.productDetail.getFilterDataList().map((e, i) => {
                      return (
                        <Table.Row key={i}>
                          <Table.BodyColumn
                            info
                            minWidth={VARIABLES.TABLE.COLUMN_WIDTHS.PROFILE_MAPPING_LIST.AD.COIL_NAME}
                            title={e.itemDescription}
                          >
                            {e.itemDescription}
                          </Table.BodyColumn>
                          <Table.BodyColumn
                            info
                            minWidth={VARIABLES.TABLE.COLUMN_WIDTHS.PROFILE_MAPPING_LIST.AD.COIL_ID}
                            title={e.lotNumber}
                          >
                            {e.lotNumber}
                          </Table.BodyColumn>
                          <Table.BodyColumn
                            info
                            minWidth={VARIABLES.TABLE.COLUMN_WIDTHS.PROFILE_MAPPING_LIST.AD.THICKNESS}
                            title={e.thicknessMM}
                          >
                            {e.thicknessMM}
                          </Table.BodyColumn>
                          <Table.BodyColumn
                            info
                            minWidth={VARIABLES.TABLE.COLUMN_WIDTHS.PROFILE_MAPPING_LIST.AD.WIDTH}
                            title={e.coilWidthMM}
                          >
                            {e.coilWidthMM}
                          </Table.BodyColumn>
                          <Table.BodyColumn
                            info
                            minWidth={VARIABLES.TABLE.COLUMN_WIDTHS.PROFILE_MAPPING_LIST.AD.CREATE_DATE}
                          >
                            {e.displayCreatedDate}
                          </Table.BodyColumn>
                          <Table.BodyColumn
                            info
                            alignRight
                            minWidth={VARIABLES.TABLE.INFO_WIDTHS.COLOR}
                            title={e.topPaintColor}
                          >
                            {e.topPaintColor}
                          </Table.BodyColumn>
                          <Table.BodyColumn
                            info
                            alignRight
                            minWidth={VARIABLES.TABLE.COLUMN_WIDTHS.PROFILE_MAPPING_LIST.AD.QUANTITY}
                            title='358'
                          >
                            {e.fRemainingweightWithComma}
                          </Table.BodyColumn>
                          <Table.BodyColumn
                            info
                            alignCenter
                            minWidth={VARIABLES.TABLE.COLUMN_WIDTHS.PROFILE_MAPPING_LIST.AD.UNIT}
                          >
                            Kg.
                          </Table.BodyColumn>
                          <Table.BodyColumn
                            info
                            alignRight
                            minWidth={VARIABLES.TABLE.COLUMN_WIDTHS.PROFILE_MAPPING_LIST.AD.LENGTH}
                            title={e.fTotalRemaininglengthWithComma}
                          >
                            {e.fTotalRemaininglengthWithComma}
                          </Table.BodyColumn>
                          <Table.BodyColumn
                            action
                            alignCenter
                            maxWidth={VARIABLES.TABLE.COLUMN_WIDTHS.PROFILE_MAPPING_LIST.AD.ACTION}
                            minWidth={VARIABLES.TABLE.COLUMN_WIDTHS.PROFILE_MAPPING_LIST.AD.ACTION}
                          >
                            <Button
                              blue
                              ui='frontIconSmall'
                              name='Assign Profile'
                              iconWidth='10'
                              iconHeight='10'
                              iconSrc={ICONS['ic-edit.svg']}
                              onClick={() => {
                                this.input.onAssign(e)
                              }}
                            />
                          </Table.BodyColumn>
                        </Table.Row>
                      )
                    })
                  }
                </Table.Section>
              </Table>
            </Section>
            <TableAction>
              <TableAction.Left>
                <Grid gutter='20'>
                  <Grid.Column>
                    <Section paddingTop='6'>
                      <Text
                        regular12
                        color={VARIABLES.COLORS.GRAY_16}
                      >
                        {getLanguage('pagination.page', '')} {activePage}/{Math.ceil(totalData / dataPerPage)}
                      </Text>
                    </Section>
                  </Grid.Column>
                  <Grid.Column>
                    <PaginationControl
                      activePage={activePage}
                      itemsCountPerPage={dataPerPage}
                      totalItemsCount={totalData}
                      pageRangeDisplayed={3}
                      onChange={this.pagination.onPaginationChange}
                    />
                  </Grid.Column>
                </Grid>
              </TableAction.Left>
              <TableAction.Right>
                <Button
                  gray
                  ui='back'
                  name={getLanguage('field.buttonBack', '')}
                  onClick={() => {
                    redirect(ROUTE_PATH.PROFILE_MAPPING_PRODUCT_GROUP.LINK)
                  }}
                />
              </TableAction.Right>
            </TableAction>
          </ShapeContainer>
        </Section>
      </React.Fragment>
    )
  }
}
