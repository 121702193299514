import { salePersonController } from "../../apiService/apiController/salePersonService"
import { isValidResponse } from "../functions/validation";
import { storeDispatch, storeGetState } from "../../stores";
import { dataCreators, DATA_REDUX_STATE_KEY } from "../../actions/dataAction";
import { ModelSalePerson } from "../../models/ModelSalePerson";
import { handleSortParams, handleTrimParams } from "../functions/data";

export const getSalePersonList = async (params = {}, configService = {}) => {
  // let userAuth = storeGetState().mainRedux.userAuth;
  let currentParams = storeGetState().dataRedux.salePersonParams;
  let newParams = handleTrimParams({
    ...currentParams,
    ...params,
  });
  if (!newParams.isNoSort) {
    if (!newParams.sort) {
      newParams.sort = 'id desc';
    }
    else {
      newParams = handleSortParams(currentParams, newParams);
    }
  }
  const salePersonService = salePersonController(configService);
  const res = await salePersonService.searchSalePersonWithPagination(newParams);
  if (isValidResponse(res)) {
    const dataList = res.data.map(e => new ModelSalePerson(e));
    newParams.activePage = newParams.page;
    newParams.totalData = res.total_items;
    console.log('dataList', res.data, dataList, newParams);
    storeDispatch(dataCreators.setDataRedux(DATA_REDUX_STATE_KEY.SALE_PERSON_LIST, dataList));
    storeDispatch(dataCreators.setDataRedux(DATA_REDUX_STATE_KEY.SALE_PERSON_PARAMS, newParams));
    return dataList;
  }
}

export const getSalePersonExportList = async () => {
  let userAuth = storeGetState().mainRedux.userAuth;
  const newParams = handleTrimParams({
    ad_ref_id: userAuth.adRefId,
    sort: 'id desc'
  });
  const salePersonService = salePersonController();
  const res = await salePersonService.searchSalePersonWithPagination(newParams);
  if (isValidResponse(res)) {
    const dataList = res.data.map(e => new ModelSalePerson(e));
    console.log('dataList', res.data, dataList, newParams);
    return dataList;
  }
}

export const getClearSalePersonParams = () => {
  return {
    authorize_dealer_id: '',
    first_name: '',
    last_name: '',
    nick_name: '',
    email: '',
    phone_no: '',
    all_field: '',
    sort: '',
    isNoSort: false,
    ad_ref_id: '',
  }
}