import _ from 'lodash';
import React from 'react';
import {
  connect
} from 'react-redux';
import { bindActionCreators } from 'redux';
import { dataCreators } from '../../actions/dataAction';
import { DATA } from '../../helpers/constants/data';
import { getLanguage } from '../../helpers/functions/language';
import { isShowLoading } from '../../helpers/functions/main';
import { getWarrantyEnvironmentList } from '../../helpers/services/warranty';
import {
  Section, ShapeContainer, TitlePage, YearWarranty
} from './../../components';
import {
  ICONS
} from './../../themes';

class YearWarrantyContainer extends React.Component {

  constructor(props) {
    super(props);

    const markData = {};
    const markDataList = [];
    let markStepList = [0, 100, 200, 300, 400, 500, 600, 700, 800, 900, 1000, 1500, 2000, 2500, 3000, 3500, 4000, 4500, 5000, 5500, 6000];
    markStepList.forEach((e, i) => {
      const label = e;
      const value = i / (markStepList.length - 1) * 100
      markData[value] = label;
      markDataList.push({
        label,
        value
      })
    });

    const selectOptionFromAllProductList = [{
      value: 0,
      // 'Marine'
      label: DATA.WARRANTY_ENV_DETAIL_NAME_LIST[2]
    }]

    const filterProductGroupNameList = [
      'Colorbond',
      'Colorbond Ultra',
      'Prima',
      'Zincalume',
      'BlueScope Zacs Cool',
      'BlueScope Zacs Natural',
      'BlueScope Zacs Dazzle',
      'BlueScope Zacs',
      'JingJoe Paint',
      'JingJoe Lek',
      'SuperDyma CRP',
      'PP Panel SD',
      'TrueCore',
      'SuperDyma',
      'SuperDyma SmartDuct',
      'COLORBOND XPD',
      'BlueScope Zacs Paint',
    ].map(e => e.toLowerCase());

    this.state = {
      selectOptionProductList: [],
      selectOptionFromAllProductList,
      selectOptionFromList: _.cloneDeep(selectOptionFromAllProductList),
      selectedFrom: undefined,
      selectedProduct: undefined,
      dataList: [],
      envList: [],
      markValue: 0,
      markData,
      markDataList,
      applicationList: [],
      filterProductGroupNameList,

      productGroupNameSpecificDisplayNameList: [{
        // SuperDyma CRP
        value: filterProductGroupNameList[10],
        label: 'SuperDyma CRP / SuperDyma CRP Antibacterial / SuperDyma CRP Antistatic'
      }],

      productGroupNameSpecificDisplayCodeList: [{
        //'SuperDyma'
        productGroupName: filterProductGroupNameList[13],
        codeList: [{
          value: 'K1DPL',
          label: 'SuperDyma Smart Purlin',
        }, {
          value: 'K1DTF',
          label: 'SuperDyma SmartFrame',
        }],
      }, {
        //'SuperDyma SmartDuct'
        productGroupName: filterProductGroupNameList[14],
        codeList: [{
          value: 'K1DSD',
          label: 'SuperDyma SmartDuct K18',
        }, {
          value: 'K2DSD',
          label: 'SuperDyma SmartDuct K14',
        }],
      }],

      productGroupNameSpecificYearList: [{
        //'Colorbond'
        productGroupName: filterProductGroupNameList[0],
        code: 'P13PP',
      }, {
        //'Colorbond Ultra'
        productGroupName: filterProductGroupNameList[1],
        code: 'P13UL',
      }, {
        //'Prima'
        productGroupName: filterProductGroupNameList[2],
        code: 'P1PMA',
      }, {
        //'Zincalume'
        productGroupName: filterProductGroupNameList[3],
        code: 'Z1ZAL',
      }, {
        //'BlueScope Zacs Cool'
        productGroupName: filterProductGroupNameList[4],
        code: 'P3BPC',
      }, {
        //'JingJoe Lek'
        productGroupName: filterProductGroupNameList[9],
        code: 'Z3JJL',
      }, {
        //'PP Panel SD'
        productGroupName: filterProductGroupNameList[11],
        code: 'P2DPP',
      }, {
        //'TrueCore'
        productGroupName: filterProductGroupNameList[12],
        code: 'Z1TRC',
      }, {
        //'BlueScope Zacs Paint'
        productGroupName: filterProductGroupNameList[16],
        code: 'P3PZC',
      }],

      productGroupNameSpecificApplicationList: [{
        // 'Zincalume'
        productGroupName: filterProductGroupNameList[3],
        applicationList: [
          DATA.WARRANTY_APP_DETAIL_NAME_LIST[0],
          DATA.WARRANTY_APP_DETAIL_NAME_LIST[1]
        ]
      }, {
        //'SuperDyma CRP'
        productGroupName: filterProductGroupNameList[10],
        applicationList: [
          DATA.WARRANTY_APP_DETAIL_NAME_LIST[4],
        ]
      }, {
        //'PP Panel SD'
        productGroupName: filterProductGroupNameList[11],
        applicationList: [
          DATA.WARRANTY_APP_DETAIL_NAME_LIST[4],
        ]
      }, {
        //'TrueCore'
        productGroupName: filterProductGroupNameList[12],
        applicationList: [
          DATA.WARRANTY_APP_DETAIL_NAME_LIST[3],
        ]
      }, {
        //'SuperDyma'
        productGroupName: filterProductGroupNameList[13],
        applicationList: [
          DATA.WARRANTY_APP_DETAIL_NAME_LIST[3],
        ]
      }, {
        //'SuperDyma SmartDuct'
        productGroupName: filterProductGroupNameList[14],
        applicationList: [
          DATA.WARRANTY_APP_DETAIL_NAME_LIST[6],
        ]
      }],

      productGroupNameSpecificRoofAndWallList: [
        // 'Colorbond',
        filterProductGroupNameList[0],
        // 'Colorbond Ultra',
        filterProductGroupNameList[1],
        // // 'Zincalume',
        // filterProductGroupNameList[3],
        // // 'BlueScope Zacs',
        // filterProductGroupNameList[7],
        // // 'JingJoe Paint',
        // filterProductGroupNameList[8],
        // // 'JingJoe Lek',
        // filterProductGroupNameList[9],
        // // 'COLORBOND XPD',
        // filterProductGroupNameList[15],
        // // 'BlueScope Zacs Paint',
        // filterProductGroupNameList[16],
      ],

      environmentNameList: [],
    }
  }

  componentDidMount() {
    this.yearWarranty.getData()
  }

  yearWarranty = {
    getData: async () => {
      await Promise.all([
        this.yearWarranty.getWarrantyEnvironmentList(),
        this.yearWarranty.getSelectOptionList(),
      ])
    },
    getWarrantyEnvironmentList: () => {
      return new Promise(async (resolve) => {
        const environmentNameList = await getWarrantyEnvironmentList({
          isShowLoading: false,
        });
        this.setState({
          environmentNameList,
        }, () => {
          resolve();
        })
      });
    },
    getSelectOptionList: async () => {
      const {
        getWarrantyYearList,
      } = this.props;
      const {
        filterProductGroupNameList,
        productGroupNameSpecificDisplayCodeList,
        productGroupNameSpecificYearList,
      } = this.state;
      let dataList = await getWarrantyYearList();

      const filterList = [];
      filterProductGroupNameList.forEach(e => {
        dataList.forEach(ed => {
          if (e.toLowerCase() === (ed.productGroupName || '').toLowerCase()) {
            filterList.push(ed);
          }
        });
      })
      console.log('filterList', filterList)
      const dataGroup = {};
      filterList.forEach(e => {
        if (dataGroup[e.productGroupName]) {
          dataGroup[e.productGroupName].codeList.push(e.m3ProductGroupCode);
        }
        else {
          dataGroup[e.productGroupName] = {
            label: e.productGroupName,
            value: e.m3ProductGroupCode,
            codeList: [e.m3ProductGroupCode]
          }
        }
      })

      const selectOptionProductList = Object.keys(dataGroup).map(key => {
        const data = dataGroup[key];

        data.codeList = [data.codeList[0]] || [];

        productGroupNameSpecificDisplayCodeList.forEach(e => {
          if (data.label.toLowerCase() === e.productGroupName.toLowerCase()) {
            data.codeList = e.codeList.map(ed => ed.value);
          }
        });

        productGroupNameSpecificYearList.forEach(e => {
          if (data.label.toLowerCase() === e.productGroupName.toLowerCase()) {
            data.codeList = [e.code];
          }
        })

        return data
      })

      this.setState({
        selectOptionProductList: _.orderBy(selectOptionProductList, [item => (item.label || '').toLowerCase()])
      })
    },
    onChangeSelectFrom: (item) => {
      console.log('onChangeSelectFrom', item)
      this.setState({
        selectedFrom: item,
        markValue: 0,
      }, () => {
        this.yearWarranty.getEnvList();
      })
    },
    getEnvList: () => {
      const {
        markData,
        markValue,
        selectedFrom,
        selectedProduct,
        productGroupNameSpecificRoofAndWallList,
        environmentNameList,
      } = this.state;

      const envList = [];
      const distance = markData[markValue];
      const getEnvironmentName = (code) => {
        return environmentNameList.filter(e => e.code === code)[0].environmentName || ''
      }
      // 'Marine'
      if (selectedFrom.value === 0) {
        // if (productGroupNameSpecificRoofAndWallList.indexOf((selectedProduct.label || '').toLowerCase()) >= 0) {
        if (distance > 5000) {
          envList.push(getEnvironmentName(DATA.WARRANTY_ENV_DETAIL_CODE_LIST[4]));
        } else if (distance >= 1001 && distance <= 5000) {
          envList.push(getEnvironmentName(DATA.WARRANTY_ENV_DETAIL_CODE_LIST[3]));
        } else if (distance >= 401 && distance <= 1000) {
          envList.push(getEnvironmentName(DATA.WARRANTY_ENV_DETAIL_CODE_LIST[2]));
        } else if (distance >= 201 && distance <= 400) {
          envList.push(getEnvironmentName(DATA.WARRANTY_ENV_DETAIL_CODE_LIST[1]));
        } else {
          envList.push(getEnvironmentName(DATA.WARRANTY_ENV_DETAIL_CODE_LIST[0]));
        }
        // }
        // else {
        //   if (distance > 1000) {
        //     envList.push(getEnvironmentName(DATA.WARRANTY_ENV_DETAIL_CODE_LIST[4]));
        //   } else if (distance >= 401 && distance <= 1000) {
        //     envList.push(getEnvironmentName(DATA.WARRANTY_ENV_DETAIL_CODE_LIST[3]));
        //   } else if (distance >= 201 && distance <= 400) {
        //     envList.push(getEnvironmentName(DATA.WARRANTY_ENV_DETAIL_CODE_LIST[2]));
        //   } else if (distance >= 101 && distance <= 200) {
        //     envList.push(getEnvironmentName(DATA.WARRANTY_ENV_DETAIL_CODE_LIST[1]));
        //   } else {
        //     envList.push(getEnvironmentName(DATA.WARRANTY_ENV_DETAIL_CODE_LIST[0]));
        //   }
        // }
      }
      // 'Industrial'
      if (selectedFrom.value === 1) {
        if (distance > 2000) {
          envList.push(getEnvironmentName(DATA.WARRANTY_ENV_DETAIL_CODE_LIST[4]));
        } else if (distance >= 501 && distance <= 2000) {
          envList.push(getEnvironmentName(DATA.WARRANTY_ENV_DETAIL_CODE_LIST[7]));
        } else if (distance >= 1 && distance <= 500) {
          envList.push(getEnvironmentName(DATA.WARRANTY_ENV_DETAIL_CODE_LIST[6]));
        } else {
          envList.push(getEnvironmentName(DATA.WARRANTY_ENV_DETAIL_CODE_LIST[5]));
        }
      }

      this.setState({
        envList
      })
    },
    getDisplayProductGroupName: (item, m3ProductGroupCode) => {
      const {
        productGroupNameSpecificDisplayNameList,
        productGroupNameSpecificDisplayCodeList,
      } = this.state;

      let productGroupName = item.label;
      productGroupNameSpecificDisplayCodeList.forEach(e => {
        if (item.label.toLowerCase() === e.productGroupName.toLowerCase()) {
          e.codeList.forEach(ed => {
            if (ed.value === m3ProductGroupCode) {
              productGroupName = ed.label;
            }
          })
        }
      });

      productGroupNameSpecificDisplayNameList.forEach(e => {
        if (item.label.toLowerCase() === e.value.toLowerCase()) {
          productGroupName = e.label;
        }
      })

      return productGroupName;
    },
    onChangeSelectProduct: (item) => {
      const {
        getWarrantyYearDetail,
      } = this.props;
      const {
        selectOptionFromList,
        productGroupNameSpecificApplicationList,
        productGroupNameSpecificRoofAndWallList
      } = this.state;
      console.log('onChangeSelectProduct', item);
      let dataList = [];
      let applicationList = [];
      let cnt = 0;
      isShowLoading(true)
      item.codeList.forEach(async (e) => {
        const params = {
          m3ProductGroupCode: e
        }
        const configService = {
          isShowLoading: false,
        }
        let resultList = await getWarrantyYearDetail(params, configService);
        cnt++;
        const isShowRoofAndWall = productGroupNameSpecificRoofAndWallList.indexOf((item.label || '').toLowerCase()) >= 0;
        dataList.push({
          productGroupName: this.yearWarranty.getDisplayProductGroupName(item, e),
          m3ProductGroupCode: e,
          warrantyYearDetailList: resultList,
          dataList: resultList.length ? [resultList[0]] : [],
          isShowRoofAndWall
        })
        applicationList = [...applicationList, ...resultList.map(e => e.application)];

        if (cnt === item.codeList.length) {

          productGroupNameSpecificApplicationList.forEach(ea => {
            if (item.label.toLowerCase() === ea.productGroupName.toLowerCase()) {
              applicationList = ea.applicationList;
            }
          });

          this.setState({
            dataList: _.orderBy(dataList, ['m3ProductGroupCode'], ['asc']),
            applicationList: _.uniq(applicationList),
            markValue: 0,
            selectedFrom: selectOptionFromList[0],
            selectedProduct: item,
          }, () => {
            this.yearWarranty.getEnvList();
            isShowLoading(false)
          })
        }
      });
    },
    onChangeSlider: (data) => {
      this.setState({
        markValue: typeof data === typeof {} ? data.value : data,
      }, () => {
        this.yearWarranty.getEnvList();
      })
    },
  }

  render() {
    const {
      isShowRegister,
      handleSubmitRegisterForm,
      isHideTitle,
    } = this.props;
    const {
      selectOptionProductList,
      selectOptionFromList,
      markData,
      markDataList,
      markValue,
      selectedFrom,
      selectedProduct,
      applicationList,
      envList,
      dataList,
      filterProductGroupNameList,

    } = this.state;
    console.log('applicationList', applicationList)
    return (
      <Section ui='content' className='is-year-warranty'>
        {
          !isHideTitle && (
            <Section spacingBottom='35'>
              <TitlePage
                className='is-year-warranty'
                src={ICONS['ic-page-year-warranty.svg']}
                name={getLanguage('yearWarranty.title', '')}
              />
            </Section>
          )
        }
        <ShapeContainer
          ui='contentYearWarranty'
          className='is-year-warranty'
          justify='space-between'
        >
          <YearWarranty
            filterProductGroupNameList={filterProductGroupNameList}
            dataList={dataList}
            envList={envList}
            selectOptionProductList={selectOptionProductList}
            selectOptionFromList={selectOptionFromList}
            selectedProduct={selectedProduct}
            selectedFrom={selectedFrom}
            onChangeSelectProduct={this.yearWarranty.onChangeSelectProduct}
            onChangeSelectFrom={this.yearWarranty.onChangeSelectFrom}
            onChangeSlider={this.yearWarranty.onChangeSlider}
            markData={markData}
            markDataList={markDataList}
            markValue={markValue}
            isProductSelected={!!dataList.length}
            isShowTooltipRoof={applicationList.indexOf(DATA.WARRANTY_APP_DETAIL_NAME_LIST[0]) >= 0}
            isShowTooltipWall={applicationList.indexOf(DATA.WARRANTY_APP_DETAIL_NAME_LIST[1]) >= 0}
            isShowTooltipPurlinGirt={applicationList.indexOf(DATA.WARRANTY_APP_DETAIL_NAME_LIST[2]) >= 0}
            isShowTooltipTrussFrame={applicationList.indexOf(DATA.WARRANTY_APP_DETAIL_NAME_LIST[3]) >= 0}
            isShowTooltipCoolRoomPanel={applicationList.indexOf(DATA.WARRANTY_APP_DETAIL_NAME_LIST[4]) >= 0}
            isShowTooltipDecking={applicationList.indexOf(DATA.WARRANTY_APP_DETAIL_NAME_LIST[5]) >= 0}
            isShowTooltipAirDucting={applicationList.indexOf(DATA.WARRANTY_APP_DETAIL_NAME_LIST[6]) >= 0}
            isShowTooltipShutterEx={applicationList.indexOf(DATA.WARRANTY_APP_DETAIL_NAME_LIST[7]) >= 0}
            isShowRegister={isShowRegister}
            handleSubmitRegisterForm={handleSubmitRegisterForm}
          />
        </ShapeContainer>
      </Section>
    )
  }
}

const mapStateToProps = (state) => {
  const dataRedux = state.dataRedux;
  return {
    dataList: dataRedux.warrantyList,
    dataParams: dataRedux.warrantyParams,
    dataOptionParams: dataRedux.warrantyOptionParams,
    activePage: dataRedux.warrantyParams.activePage,
    totalData: dataRedux.warrantyParams.totalData || 0,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    dataActions: bindActionCreators(dataCreators, dispatch),
  }
}

export const YearWarrantyContainerConnected = connect(
  mapStateToProps,
  mapDispatchToProps
)(YearWarrantyContainer)