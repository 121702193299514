import React from 'react'
import ClassNames from 'classnames'
// import PropTypes from 'prop-types'
import {
  GridBase
} from './../../base'
// import {
// } from './../../../components'
// import {
//   default as VARIABLES
// } from './../../../themes/styles/bases/variables'
// import {
//   default as TYPOGRAPHYS
// } from './../../../themes/styles/bases/typographys'
// import {
//   default as UTILITIES
// } from './../../../themes/styles/helpers/utilities'

/**
 * Grid description:
 * - 12 Columns system
 * - Breakpoints:
 *   - Mobile        (from screen to 413px)
 *   - Phablet       (min 414px)
 *   - Tablet        (min 768px)
 *   - Laptop        (min 1024px)
 *   - Desktop       (min 1366px)
 *   - Large Desktop (min 1600px)
 */

class GridResponsiveColumn extends React.PureComponent {
  render() {
    const {
      className,
      children,
      ui
    } = this.props

    // props for css classes
    const classes = ClassNames(
      className,
      // { 'is-modifier-name': modifierName }
    )

    return (
      <React.Fragment>
        {(() => {
          switch (ui) {
            case 'fieldText':
              return (
                <GridBase.Column
                  {...this.props}
                  className={classes}
                  gridLaptop='12'
                  gridDesktop='5'
                  gridLargeDesktop='4'
                >
                  {children}
                </GridBase.Column>
              )
            case 'fieldPhone':
              return (
                <GridBase.Column
                  {...this.props}
                  className={classes}
                  gridLaptop='6'
                  gridDesktop='5'
                  gridLargeDesktop='4'
                >
                  {children}
                </GridBase.Column>
              )
            case 'fieldSelect':
              return (
                <GridBase.Column
                  {...this.props}
                  className={classes}
                  gridTablet='12'
                  gridLaptop='4'
                  gridLargeDesktop='3'
                >
                  {children}
                </GridBase.Column>
              )
            default:
              return (
                <GridBase.Column
                  {...this.props}
                  className={classes}
                >
                  {children}
                </GridBase.Column>
              )
          }
        })()}
      </React.Fragment>
    )
  }

  static Column = GridBase.Column

  static defaultProps = {
    // * If you set new default props, you should create propsType for correct styleguide document.
  }

  static propTypes = {
    // TYPE > node, string, number, bool, array, object, symbol, func
    /**
    * props_name_oneType
    */
    // props_name_onetype: PropTypes.string,

    /**
    * props_name_oneMoreType
    */
    // props_name_oneMoreType: PropTypes.oneOfType([
    //   PropTypes.node,
    //   PropTypes.string
    // ])
  }
}

export class Grid extends React.PureComponent {
  render () {
    const {
      className
    } = this.props

    // props for css classes
    const classes = ClassNames(
      className,
      // { 'is-modifier-name': modifierName }
    )

    return (
      <GridBase
        {...this.props}
        className={classes}
      />
    )
  }

  static Column = GridBase.Column
  static ResponsiveColumn = GridResponsiveColumn

  static defaultProps = {
    // * If you set new default props, you should create propsType for correct styleguide document.
  }

  static propTypes = {
    // TYPE > node, string, number, bool, array, object, symbol, func
    /**
    * props_name_oneType
    */
    // props_name_onetype: PropTypes.string,

    /**
    * props_name_oneMoreType
    */
    // props_name_oneMoreType: PropTypes.oneOfType([
    //   PropTypes.node,
    //   PropTypes.string
    // ])
  }
}
