import React from 'react'
import {
  ShapeContainer,
  Section,
  Breadcrumb,
  TitlePage,
  TableAction,
  Grid,
  Text,
  Button,
  PaginationControl,
  Table,
  Image,
  TitleSection
} from './../../components'
import {
  VARIABLES,
  ICONS,
  CONTENTS
} from './../../themes'
import { setSideMenuActive, getRouteData } from '../../helpers/functions/route';
import { ROUTE_PATH } from '../../helpers/constants/route';
import { redirect } from '../../helpers/functions/route';
import { CustomerInfoContainerConnected } from '../../containers/CustomerInfoContainer';
import { PurchaseHistoryListContainerConnected } from '../../containers/PurchaseHistoryListContainer';
import { getLanguage } from '../../helpers/functions/language';
import _ from 'lodash';

export class PointsDetailManagementScreen extends React.Component {

  constructor(props) {
    super(props);
    setSideMenuActive(props);
    const data = getRouteData(props, 'data');
    if (!data) {
      redirect(ROUTE_PATH.POINTS_DETAIL.LINK);
    }
    this.state = {
      data,
      activePage: 1,
      dataPerPage: 10,
      totalData: 10
    }
  }

  pointsDetailManagement = {
    onLoadFinish: (dataList) => {
      const {
        data,
      } = this.state;
      data.pointList = dataList
      this.setState({
        totalData: dataList.length,
        data: { ...data },
      })
    }
  }

  pagination = {
    onPaginationChange: (pageNumber) => {
      this.setState({
        activePage: pageNumber,
      })
    }
  }

  render() {
    const {
      data,
      activePage,
      dataPerPage,
      totalData,
    } = this.state
    return (
      <React.Fragment>
        <Breadcrumb>
          <Breadcrumb.List
            link
            name={getLanguage('pointDetailManage.breadcrumb1', '')}
            onClick={() => {
              redirect(ROUTE_PATH.HOME.LINK)
            }}
          />
          <Breadcrumb.List name='/' />
          <Breadcrumb.List
            link
            name={getLanguage('pointDetailManage.breadcrumb2', '')}
          // onClick={() => {
          //   redirect(ROUTE_PATH.REWARDS.LINK)
          // }}
          />
          <Breadcrumb.List name='/' />
          <Breadcrumb.List name={`${getLanguage('pointDetailManage.breadcrumb3', '')} : ${data.fullname}`} />
        </Breadcrumb>
        <Section ui='content'>
          <Section spacingBottom='35'>
            <TitlePage
              src={ICONS['ic-page-rewards.svg']}
              name={`${getLanguage('pointDetailManage.title', '')} : ${data.fullname}`}
            />
          </Section>
          <ShapeContainer
            ui='content'
            justify='space-between'
          >
            <Section spacingBottom='45'>
              <CustomerInfoContainerConnected type='pointsDetail'
                data={data}
              />
              <Section spacingBottom='35'>
                <TitleSection name={getLanguage('pointDetailManage.purchaseHistoryTitle', '')} />
              </Section>
              <Section>
                <PurchaseHistoryListContainerConnected
                  data={data}
                  activePage={activePage}
                  dataPerPage={dataPerPage}
                  onLoadFinish={this.pointsDetailManagement.onLoadFinish}
                />
              </Section>
            </Section>
            <Section>
              <TableAction>
                <TableAction.Left>
                  {/* <Grid gutter='15'>
                    <Grid.Column>
                      <Button
                        blue
                        ui='frontIcon'
                        name='Print'
                        width={VARIABLES.BUTTON.WIDTHS.W_125}
                        iconWidth='18'
                        iconHeight='18'
                        iconSrc={ICONS['ic-printer.svg']}
                        onClick={() => { }}
                      />
                    </Grid.Column>
                    <Grid.Column>
                      <Button
                        green
                        ui='frontIcon'
                        name='Export'
                        width={VARIABLES.BUTTON.WIDTHS.W_125}
                        iconWidth='16'
                        iconHeight='16'
                        iconSrc={ICONS['ic-export.svg']}
                        onClick={() => { }}
                      />
                    </Grid.Column>
                  </Grid> */}
                  <Grid gutter='20'>
                    <Grid.Column>
                      <Section paddingTop='6'>
                        <Text
                          regular12
                          color={VARIABLES.COLORS.GRAY_16}
                        >
                          {getLanguage('pagination.page', '')} {activePage}/{Math.ceil(totalData / dataPerPage)}
                        </Text>
                      </Section>
                    </Grid.Column>
                    <Grid.Column>
                      <PaginationControl
                        activePage={activePage}
                        itemsCountPerPage={dataPerPage}
                        totalItemsCount={totalData}
                        pageRangeDisplayed={3}
                        onChange={this.pagination.onPaginationChange}
                      />
                    </Grid.Column>
                  </Grid>
                </TableAction.Left>
                <TableAction.Right>
                  <Button
                    gray
                    ui='back'
                    name={getLanguage('field.buttonBack', '')}
                    onClick={() => {
                      redirect(ROUTE_PATH.POINTS_DETAIL.LINK)
                    }}
                  />
                </TableAction.Right>
              </TableAction>
            </Section>
          </ShapeContainer>
        </Section>
      </React.Fragment>
    )
  }
}
