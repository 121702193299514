import React from 'react'
import ClassNames from 'classnames'
import PropTypes from 'prop-types'
import {
  osName,
} from 'react-device-detect'
import {
  WarrantyApplicationDetailWrapper
} from './styled'
import {
  Section,
  Text,
  Image,
  Grid,
  Table,
  ShapeContainer,
} from './../../../../components'
import {
  VARIABLES,
} from './../../../../themes'

/**
 * Warranty description:
 * - Warranty
 */

export class WarrantyBSWMThailandApplicationDetail extends React.PureComponent {
  render() {
    const {
      roof,
      wall,
      purlinAndGirt,
      trussAndFrame,
      coolRoomPanel,
      decking,
      airDucting,
      unit,
      thLanguage,
      isHideApplicationDetail,
    } = this.props

    // props for css classes
    const classes = ClassNames(
      'warranty-bswm-application-detail',
    )

    return (
      <WarrantyApplicationDetailWrapper
        className={classes}
      >
        {isHideApplicationDetail ?
          undefined
          :
          <React.Fragment>
            <Section spacingBottom='10'>
              <p className='warranty-bswm-text'>{thLanguage ? 'รายละเอียดแอปพลิเคชัน' : 'Application Detail'}</p>
            </Section>
            {roof &&
              <Section spacingBottom='5'>
                <Grid gutter='10'>
                  <Grid.Column><Section width={thLanguage ? '200' : '100'}><p className='warranty-bswm-text is-sm is-bold'>{thLanguage ? 'หลังคา' : 'Roof'}</p></Section></Grid.Column>
                  <Grid.Column><Section width='50'><p className='warranty-bswm-text is-sm is-bold is-right'>{roof}</p></Section></Grid.Column>
                  <Grid.Column><p className='warranty-bswm-text is-sm is-bold'>{unit}</p></Grid.Column>
                </Grid>
              </Section>
            }
            {wall &&
              <Section spacingBottom='5'>
                <Grid gutter='10'>
                  <Grid.Column><Section width={thLanguage ? '200' : '100'}><p className='warranty-bswm-text is-sm is-bold'>{thLanguage ? 'ผนัง' : 'Wall'}</p></Section></Grid.Column>
                  <Grid.Column><Section width='50'><p className='warranty-bswm-text is-sm is-bold is-right'>{wall}</p></Section></Grid.Column>
                  <Grid.Column><p className='warranty-bswm-text is-sm is-bold'>{unit}</p></Grid.Column>
                </Grid>
              </Section>
            }
            {purlinAndGirt &&
              <Section spacingBottom='5'>
                <Grid gutter='10'>
                  <Grid.Column><Section width={thLanguage ? '200' : '100'}><p className='warranty-bswm-text is-sm is-bold'>{thLanguage ? 'แปหลังคา และ แปผนัง' : 'Purlin And Girt'}</p></Section></Grid.Column>
                  <Grid.Column><Section width='50'><p className='warranty-bswm-text is-sm is-bold is-right'>{purlinAndGirt}</p></Section></Grid.Column>
                  <Grid.Column><p className='warranty-bswm-text is-sm is-bold'>{unit}</p></Grid.Column>
                </Grid>
              </Section>
            }
            {trussAndFrame &&
              <Section spacingBottom='5'>
                <Grid gutter='10'>
                  <Grid.Column><Section width={thLanguage ? '200' : '100'}><p className='warranty-bswm-text is-sm is-bold'>{thLanguage ? 'โครงหลังคา หรือ โครงผนัง' : 'Truss and Frame'}</p></Section></Grid.Column>
                  <Grid.Column><Section width='50'><p className='warranty-bswm-text is-sm is-bold is-right'>{trussAndFrame}</p></Section></Grid.Column>
                  <Grid.Column><p className='warranty-bswm-text is-sm is-bold'>{unit}</p></Grid.Column>
                </Grid>
              </Section>
            }
            {coolRoomPanel &&
              <Section spacingBottom='5'>
                <Grid gutter='10'>
                  <Grid.Column><Section width={thLanguage ? '200' : '100'}><p className='warranty-bswm-text is-sm is-bold'>{thLanguage ? 'ผนังห้องเย็น' : 'Cool Room Panel'}</p></Section></Grid.Column>
                  <Grid.Column><Section width='50'><p className='warranty-bswm-text is-sm is-bold is-right'>{coolRoomPanel}</p></Section></Grid.Column>
                  <Grid.Column><p className='warranty-bswm-text is-sm is-bold'>{unit}</p></Grid.Column>
                </Grid>
              </Section>
            }
            {decking &&
              <Section spacingBottom='5'>
                <Grid gutter='10'>
                  <Grid.Column><Section width={thLanguage ? '200' : '100'}><p className='warranty-bswm-text is-sm is-bold'>{thLanguage ? 'แผ่นพื้นกึ่งสำเร็จ' : 'Decking'}</p></Section></Grid.Column>
                  <Grid.Column><Section width='50'><p className='warranty-bswm-text is-sm is-bold is-right'>{decking}</p></Section></Grid.Column>
                  <Grid.Column><p className='warranty-bswm-text is-sm is-bold'>{unit}</p></Grid.Column>
                </Grid>
              </Section>
            }
            {airDucting &&
              <Section spacingBottom='5'>
                <Grid gutter='10'>
                  <Grid.Column><Section width={thLanguage ? '200' : '100'}><p className='warranty-bswm-text is-sm is-bold'>{thLanguage ? 'งานท่อ' : 'Air Ducting'}</p></Section></Grid.Column>
                  <Grid.Column><Section width='50'><p className='warranty-bswm-text is-sm is-bold is-right'>{airDucting}</p></Section></Grid.Column>
                  <Grid.Column><p className='warranty-bswm-text is-sm is-bold'>{unit}</p></Grid.Column>
                </Grid>
              </Section>
            }
          </React.Fragment>
        }
      </WarrantyApplicationDetailWrapper>
    )
  }

  static defaultProps = {
    // If you set new default props, you should create propsType for correct styleguide document.
    roof: 'XX',
    wall: 'XX',
    purlinAndGirt: 'XX',
    trussAndFrame: 'XX',
    coolRoomPanel: 'XX',
    decking: 'XX',
    airDucting: 'XX',
    unit: 'm.',
    isHideApplicationDetail: false,
  }

  static propTypes = {
    // TYPE > node, string, number, bool, array, object, symbol, func
  }
}
