import React from 'react'
import {
  connect,
} from 'react-redux';
import {
  ShapeContainer,
  Section,
  Grid,
  Field,
  Button,
  SelectControl,
  TitleSection,
} from './../../components';
import { FieldAddressContainerConnected } from './../../containers/FieldAddressContainer';
import {
  VARIABLES,
} from './../../themes';
import { ENUM } from '../../helpers/constants/enum';
import { MAIN_REDUX_STATE_KEY } from '../../actions/mainAction';
import _ from 'lodash';
import { getLanguage } from '../../helpers/functions/language';

class ManageIdAddressContainer extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      selectedOption: null
    }
  }

  handleChange = selectedOption => {
    this.setState({
      selectedOption
    })
  }

  render() {
    const {
      data,
      selectedOption,
      onChangeDataInput,
      onChangeAddress,
      goBack,
      nextStep,
      tabEdit,
      isEdit,
      onClickSave,
      userAuth,
    } = this.props;
    return (
      <ShapeContainer
        ui='contentStep'
        justify='space-between'
      >
        {tabEdit}
        <Section>
          <Section spacingBottom='40'>
            <TitleSection name={getLanguage('customersManage.step.idAddress')} />
          </Section>
          <Section spacingBottom='30'>
            <Grid>
              <Grid.ResponsiveColumn ui='fieldText'>
                <Field name='idCardNo'
                  fluid
                  ui='blockLabelVertical'
                  label={getLanguage('field.idCardNo', '')}
                  placeholder={getLanguage('field.idCardNoPlaceholder', '')}
                  value={data.idCardNo}
                  onChange={onChangeDataInput}
                />
              </Grid.ResponsiveColumn>
            </Grid>
          </Section>
          <Section spacingBottom='60'>
            <FieldAddressContainerConnected
              thai={data.idAddressType === ENUM.COUNTRY_TYPE.THAI}
              malaysia={data.idAddressType === ENUM.COUNTRY_TYPE.MALAYSIA}
              vietnam={data.idAddressType === ENUM.COUNTRY_TYPE.VIETNAM}
              indonesia={data.idAddressType === ENUM.COUNTRY_TYPE.INDONESIA}
              laos={data.idAddressType === ENUM.COUNTRY_TYPE.LAOS}
              // Address
              requiredAddress={false}
              nameAddress='idAddress'
              valueAddress={data.idAddress}
              onChangeAddress={onChangeDataInput}
              // Province
              requiredProvince={false}
              nameProvince='idSelectedProvince'
              valueProvince={data.idSelectedProvince}
              onChangeProvince={(selected) => {
                if (data.idAddressType === ENUM.COUNTRY_TYPE.LAOS) {
                  onChangeDataInput(selected);
                }
                else {
                  onChangeAddress(selected, 'idSelectedProvince');
                }
              }}
              // City
              requiredCity={false}
              nameCity='idSelectedCity'
              valueCity={data.idSelectedCity}
              onChangeCity={(selected) => {
                if (data.idAddressType === ENUM.COUNTRY_TYPE.LAOS) {
                  onChangeDataInput(selected);
                }
                else {
                  onChangeAddress(selected, 'idSelectedCity');
                }
              }}
              // Subdistrict
              requiredSubdistrict={false}
              nameSubdistrict='idSubDistrict'
              valueSubdistrict={data.idSubDistrict}
              onChangeSubdistrict={(selected) => {
                if (data.idAddressType === ENUM.COUNTRY_TYPE.THAI) {
                  onChangeAddress(selected, 'idSubDistrict');
                }
                else {
                  onChangeDataInput(selected);
                }
              }}
              // District
              requiredDistrict={false}
              isSearchableDistrict={false}
              nameDistrict='idDistrict'
              valueDistrict={data.idDistrict}
              onChangeDistrict={onChangeDataInput}
              // Alley
              requiredAlley={false}
              nameAlley='idAlley'
              valueAlley={data.idAlley}
              onChangeAlley={onChangeDataInput}
              // Group
              requiredGroup={false}
              nameGroup='idGroup'
              valueGroup={data.idGroup}
              onChangeGroup={onChangeDataInput}
              // Street
              requiredStreet={false}
              nameStreet='idStreet'
              valueStreet={data.idStreet}
              onChangeStreet={onChangeDataInput}
              // Ward
              requiredWard={false}
              nameWard='idWard'
              valueWard={data.idWard}
              onChangeWard={onChangeDataInput}
              // PostalCode
              requiredPostalCode={false}
              namePostalCode='idPostCode'
              valuePostalCode={data.idPostCode}
              onChangePostalCode={(selected) => {
                if (data.idAddressType === ENUM.COUNTRY_TYPE.THAI) {
                  onChangeAddress(selected, 'idPostCode');
                }
                else {
                  onChangeDataInput(selected);
                }
              }}
            />
          </Section>
        </Section>
        <Grid
          gutter='15'
          justify='flex-end'
        >
          <Grid.Column>
            <Button
              gray
              ui='back'
              name={getLanguage('field.buttonBack', '')}
              onClick={goBack}
            />
          </Grid.Column>
          <Grid.Column>
            <Button
              blue
              name={isEdit ? getLanguage('field.buttonSave', '') : getLanguage('field.buttonContinue', '')}
              width={VARIABLES.BUTTON.WIDTHS.W_200}
              onClick={isEdit ? onClickSave : nextStep}
            />
          </Grid.Column>
        </Grid>
      </ShapeContainer>
    )
  }
}

const mapStateToProps = (state) => {
  const mainRedux = state.mainRedux;
  return {
    userAuth: mainRedux[MAIN_REDUX_STATE_KEY.USER_AUTH]
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
  }
}

export const ManageIdAddressContainerConnected = connect(
  mapStateToProps,
  mapDispatchToProps
)(ManageIdAddressContainer)