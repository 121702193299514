import { getPhoneRemovePrefix, getCountryPhone, getCountryType } from "../helpers/functions/data";
import { ENUM } from "../helpers/constants/enum";
import { ModelAddress } from "./ModelAddress";
import { displayUrlPath } from "../helpers/functions/display";
import { FIREBASE_PUBLIC_STORAGE_PATH } from "../config/config";

export class ModelUserAuth {
  constructor(props = {}) {
    this.id = props.id;
    this.name = props.name;
    this.firstname = props.first_name__c;
    this.businessRegNo = props.business_reg_no;
    let addressGroup = {};
    try {
      addressGroup = new ModelAddress(props.address);
    } catch { }
    finally {
      this.address = addressGroup.address || '';
      this.selectedProvince = addressGroup.selectedProvince;
      this.selectedCity = addressGroup.selectedCity;
      this.district = addressGroup.district || '';
      this.subDistrict = addressGroup.subDistrict || '';
      this.alley = addressGroup.alley || '';
      this.group = addressGroup.group || '';
      this.street = addressGroup.street || '';
      this.ward = addressGroup.ward || '';
      this.addressType = addressGroup.addressType;
    }
    this.postCode = props.post_code === 'null' ? '' : (props.post_code || '');
    this.oldImageRef = (props.image_url || '').replace(`${FIREBASE_PUBLIC_STORAGE_PATH}/`, '');
    this.imageUrl = displayUrlPath(this.oldImageRef);
    this.oldImageUrl = this.imageUrl;
    this.company = props.company;
    this.email = props.email;
    this.password = props.password;
    this.createdAt = props.created_at;
    this.adRefId = props.ad_ref_id;
    this.country = props.country__c || ENUM.COUNTRY.THAI;

    // this.country = ENUM.COUNTRY.INDONESIA;

    this.addressType = getCountryType(this.country);
    this.mobileNo = getPhoneRemovePrefix(props.mobile_no, this.addressType);
    this.mobileNoCountry = getCountryPhone('', this.addressType);
    this.phoneNo = getPhoneRemovePrefix(props.phone_no, this.addressType);
    this.phoneNoCountry = getCountryPhone('', this.addressType);
    this.sfid = props.sfid;
    this.acceptTermsConditions = props.accept_terms_conditions__c;
    this.role = props.role__c;
    // this.role = ENUM.USER_ROLE.SALES_PERSON;
    this.customerRoleType = props.customer_type__c || '';
    // this.customerRoleType = ENUM.CUSTOMER_ROLE_TYPE.INDONESIA.AD;

    this.dealerAccount = props.dealer_account__c;
    this.position = props.position__c;

    this.isMalayUploadTransaction = (
      //staging
      this.dealerAccount === "0019D0000085Q3pQAE" ||
      //prod
      this.dealerAccount === "0012u00000BplFtAAJ" ||
      this.dealerAccount === "0012u00000BplacAAB" ||
      this.dealerAccount === "0012u00000BplZJAAZ" ||
      this.dealerAccount === "0012u00000BplRcAAJ" ||
      this.dealerAccount === "0012u00000Bpl8SAAR" ||
      this.dealerAccount === "0012u00000Bpl8RAAR"
    ) && this.addressType === ENUM.COUNTRY_TYPE.MALAYSIA;

    this.isIndoAdSpecial = (
      //staging
      this.dealerAccount === "0019D0000085Q4dQAE" ||
      //prod
      this.dealerAccount === "0019000000L3r81AAB"
    ) && this.customerRoleType === ENUM.CUSTOMER_ROLE_TYPE.INDONESIA.AD;

    this.segmentCategory__c = props.segment_category__c;
    this.segmentation__c = props.segmentation__c;

    // this.isMultiWarranty =
    //   //staging
    //   this.sfid === "a2I9D0000008PaZUAU";
    // // this.dealerAccount === "0019D0000085Q4TQAU";

    //TODO: loas
    if (this.addressType === ENUM.COUNTRY_TYPE.THAI) {
      this.postCode = {
        label: this.postCode,
        value: this.postCode,
      }
    }

    this.securityAnswer1__c = props.security_answer_1__c || '';
    this.securityAnswer2__c = props.security_answer_2__c || '';
    this.securityAnswer3__c = props.security_answer_3__c || '';

    this.accountRoleType = props.account_type__c || '';
  }
}