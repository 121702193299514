import React from 'react'
import { connect } from 'react-redux';
import {
  ShapeContainer,
  Section,
  Breadcrumb,
  TitlePage,
  Grid,
  Button,
  Image,
  SelectControl,
  Table,
  TableAction,
  Field,
  Text,
  PaginationControl,
} from './../../components'
import {
  VARIABLES,
  ICONS,
} from './../../themes'
import { setSideMenuActive } from '../../helpers/functions/route';
import { ROUTE_PATH } from '../../helpers/constants/route';
import { redirect } from '../../helpers/functions/route';
// import { getTransferInvenrotyList, getTransferInvenrotyParams } from '../../helpers/services/transfer';
import { getProductQuantityList } from '../../helpers/services/product';
import { dataCreators, DATA_REDUX_STATE_KEY } from '../../actions/dataAction';
import { MAIN_REDUX_STATE_KEY } from '../../actions/mainAction';
import { bindActionCreators } from 'redux';
import { handleSortData } from '../../helpers/functions/data';
import { getLanguage } from '../../helpers/functions/language';
import { calProfile } from '../../helpers/services/profile';
import _ from 'lodash';
import { getUserAuthRedux } from '../../helpers/functions/auth';

class ProfileTransactionManageScreen extends React.Component {

  constructor(props) {
    super(props);
    setSideMenuActive(props);
    this.state = {
      activePage: 1,
      dataPerPage: 10,
      Ad: false,
      Toko: false,
      optionProductGroupList: [],
      optionThicknessList: [],
      optionThicknessBMTList: [],
      optionCoilIdList: [],
      optionColorList: [],
      filterProductGroupValue: '',
      filterThicknessValue: '',
      filterThicknessBMTValue: '',
      filterCoilIdValue: '',
      filterColorValue: '',
      sortIcon: {
        lotNumber: true,
        itemDescription: true,
        profileType: true,
        thicknessMM: true,
        bmtMM: true,
        topPaintColor: true,
        fTotalRemainingprofile: true,
        fAging: true,
        fTotalRemaininglength: true,
        fRemainingweight: true,
      },
      userAuth: getUserAuthRedux(),
      // [TODO : RL-170]
      isIndonesia: {
        toko: false,
        distributor: false,
      },
    }
  }

  getData() {
    const {
      dataDetail
    } = this.props;
    if (dataDetail) {
      const {
        activePage,
        dataPerPage,
      } = this.state;

      // const params = {
      //   ...getTransferInvenrotyParams(),
      //   page: activePage,
      //   limit: dataPerPage,
      // };

      // getTransferInvenrotyList(params);
      const {
        userAuth,
        productList = [],
      } = this.props;
      const params = {
        page: activePage,
        limit: dataPerPage,
        adRefId: userAuth.adRefId,
        productList
      };
      // getProductList(params);
      getProductQuantityList(params);
    } else {
      redirect(ROUTE_PATH.PROFILE_TRANSFER.LINK)
    }
  }

  componentDidMount() {
    this.getData();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.dataList !== this.props.dataList) {
      this.invList.handleSelectOptionList(this.props.dataList)
    }
  }

  pagination = {
    onPaginationChange: (pageNumber) => {
      this.setState({
        activePage: pageNumber,
      })
    }
  }

  invList = {
    updateData: (dataList) => {
      const {
        dataActions,
      } = this.props;
      dataActions.setDataRedux(DATA_REDUX_STATE_KEY.PRODUCT_LIST, dataList);
    },
    onSave: async () => {
      const {
        dataList,
        dataActions
      } = this.props;
      let orderList = [];
      if (this.invList.isValidateTranAmt()) {
        for (const e of dataList) {
          if (e.orderValue && e.orderValue !== 0) {
            e.quantity = e.orderValue
            e.length = e.profileLength

            const params = {
              product_type: e.profileType,
              width: e.coilWidthMM,
              quantity: e.orderValue,
              length: e.profileLength,
              total_length: 0
            }
            const resp = await calProfile(params)
            e.orderValue = resp.total_length
            orderList.push(e);
          }
        }

        console.log('orderList', orderList);
        dataActions.setDataRedux(DATA_REDUX_STATE_KEY.TRNSFER_ORDER_LIST, orderList);
        redirect(ROUTE_PATH.PROFILE_TRANSACTION.LINK)
      } else {
        console.log('chkfalse');
      }
    },
    isValidateTranAmt: () => {
      const {
        dataList,
      } = this.props;
      let isChk = true;
      const chkList = dataList.map(e => {
        if (e.orderValue > e.fTotalRemainingprofile) {
          e.isError = true;
          isChk = false;
        } else {
          e.isError = false;
        }
        return e;
      })
      this.invList.updateData(chkList);
      return isChk;
    },
    handleSortIcon: (type) => {
      const {
        sortIcon,
      } = this.state;
      let newSortIcon = {};
      let oldType = type;
      Object.keys(sortIcon).forEach(e => {
        if (sortIcon[e] === false) {
          oldType = e;
        }
        newSortIcon[e] = true;
      });

      Object.keys(sortIcon).forEach(e => {
        if (e === type) {
          if (type === oldType) {
            newSortIcon[e] = !sortIcon[e];
          }
        }
      })

      this.setState({
        sortIcon: newSortIcon
      })
      return newSortIcon
    },
    onClickSort: (type) => {
      const {
        dataList,
      } = this.props;
      const newSortIcon = this.invList.handleSortIcon(type);
      const tempDataList = handleSortData(dataList, {
        sortTarget: type,
        orderType: newSortIcon[type] ? 'desc' : 'asc'
      });
      this.invList.updateData(tempDataList);
    },
    getFilterDataPaginationList: (fList) => {
      const {
        activePage,
        dataPerPage,
      } = this.state;
      const firstIndex = (activePage - 1) * dataPerPage;
      const lastIndex = activePage * dataPerPage;
      const filterList = fList;
      return filterList.filter((e, i) => i >= firstIndex && i < lastIndex);
    },
    getFilterDataList: () => {
      const {
        dataList,
      } = this.props;
      const {
        filterProductGroupValue,
        // filterThicknessValue,
        filterThicknessBMTValue,
        filterCoilIdValue,
        filterColorValue,
      } = this.state;

      return dataList.filter((e, i) => {
        if (filterProductGroupValue.length === 0 &&
          // filterThicknessValue.length === 0 &&
          filterThicknessBMTValue.length === 0 &&
          filterCoilIdValue.length === 0 &&
          filterColorValue.length === 0
        ) {
          return true;
        }
        else {
          if (
            (filterProductGroupValue.length === 0 || e.fBsproductgroupname.toString() === filterProductGroupValue.toString()) &&
            // (filterThicknessValue.length === 0 || e.thicknessMM.toString() === filterThicknessValue.toString()) &&
            (filterThicknessBMTValue.length === 0 || e.bmtMM.toString() === filterThicknessBMTValue.toString()) &&
            (filterCoilIdValue.length === 0 || e.lotNumber.toString() === filterCoilIdValue.toString()) &&
            (filterColorValue.length === 0 || e.topPaintColor.toString() === filterColorValue.toString())
          ) {
            return true;
          }
        }
        return false;
      })
    },
    handleSelectOptionList: (dataList = []) => {
      let newOptionProductGroupList = [];
      let newOptionThicknessList = [];
      let newOptionThicknessBMTList = [];
      let newOptionCoilIdList = [];
      let newOptionColorList = [];

      dataList.forEach(e => {
        newOptionProductGroupList.push(e.fBsproductgroupname);
        newOptionThicknessList.push(e.thicknessMM);
        newOptionThicknessBMTList.push(e.bmtMM);
        newOptionCoilIdList.push(e.lotNumber);
        newOptionColorList.push(e.topPaintColor);
      })

      newOptionProductGroupList = [...new Set(newOptionProductGroupList)].filter(e => e).sort();
      newOptionThicknessList = [...new Set(newOptionThicknessList)].filter(e => e).sort();
      newOptionThicknessBMTList = [...new Set(newOptionThicknessBMTList)].filter(e => e).sort();
      newOptionCoilIdList = [...new Set(newOptionCoilIdList)].filter(e => e).sort();
      newOptionColorList = [...new Set(newOptionColorList)].filter(e => e).sort();

      const selectOptionAll = {
        value: '',
        label: getLanguage('container.orderProduct.selectOption.all'),
      }

      this.setState({
        optionProductGroupList: [selectOptionAll, ...newOptionProductGroupList.map(e => ({ value: e.toString(), label: e }))],
        optionThicknessList: [selectOptionAll, ...newOptionThicknessList.map(e => ({ value: e.toString(), label: e }))],
        optionThicknessBMTList: [selectOptionAll, ...newOptionThicknessBMTList.map(e => ({ value: e.toString(), label: e }))],
        optionCoilIdList: [selectOptionAll, ...newOptionCoilIdList.map(e => ({ value: e.toString(), label: e }))],
        optionColorList: [selectOptionAll, ...newOptionColorList.map(e => ({ value: e.toString(), label: e }))],
      });
    },
  }

  input = {
    onChangeFilter: (name, data) => {
      this.setState({
        [name]: data.value,
      }, () => {
        const dataList = this.invList.getFilterDataList();
        this.invList.handleSelectOptionList(dataList);
      })
    },
    onChangeDataInput: (ev, data) => {
      const {
        dataList,
      } = this.props;
      const name = ev.target.name;
      const value = parseInt(ev.target.value);
      const tempDataList = dataList.map(e => {
        if (e.id === data.id && e.subSalesSfid === data.subSalesSfid) {
          e[name] = value
          if (value > data.fTotalRemainingprofile) {
            e.chkAll = true;
          } else {
            e.chkAll = false;
          }
        }
        return e;
      })
      this.invList.updateData(tempDataList);
    },
    onClickCheck: (ev, data) => {
      const {
        dataList,
      } = this.props;
      const name = ev.target.name;
      const value = ev.target.checked;
      const tempDataList = dataList.map(e => {
        if (e.id === data.id && e.subSalesSfid === data.subSalesSfid) {
          e[name] = value;
          if (value) {
            e.orderValue = data.fTotalRemainingprofile;
          } else {
            e.orderValue = 0;
          }
        }
        return e;
      })
      this.invList.updateData(tempDataList);
    },
  }

  render() {
    // const {
    //   // dataList,
    //   // totalData,
    //   activePage,
    // } = this.props;
    const {
      userAuth,
      activePage,
      dataPerPage,
      sortIcon,
      optionProductGroupList,
      optionThicknessList,
      optionThicknessBMTList,
      optionCoilIdList,
      optionColorList,
      // [TODO : RL-170]
      isIndonesia,
    } = this.state;
    const filterList = this.invList.getFilterDataList();
    const dataList = this.invList.getFilterDataPaginationList(filterList);

    console.log('dataList', this.props.dataList)
    return (
      <React.Fragment>
        <Breadcrumb>
          <Breadcrumb.List
            link
            name={getLanguage('profileTransactionManage.breadcrumb1', '')}
            onClick={() => {
              redirect(ROUTE_PATH.HOME.LINK)
            }}
          />
          <Breadcrumb.List name='/' />
          <Breadcrumb.List
            link
            name={getLanguage('profileTransactionManage.breadcrumb2', '')}
          // onClick={() => {
          //   redirect(ROUTE_PATH.INVENTORY_INDEXES.LINK)
          // }}
          />
          <Breadcrumb.List name='/' />
          <Breadcrumb.List
            link
            name={getLanguage('profileTransactionManage.breadcrumb3', '')}
            onClick={() => {
              redirect(ROUTE_PATH.PROFILE_TRANSFER.LINK)
            }}
          />
          <Breadcrumb.List name='/' />
          <Breadcrumb.List name={getLanguage('profileTransactionManage.breadcrumb4', '')} />
        </Breadcrumb>
        <Section ui='content'>
          <Section spacingBottom='35'>
            <Grid
              gutterVertical='15'
              justify='space-between'
            >
              <Grid.Column>
                <TitlePage
                  src={ICONS['ic-page-inventory.svg']}
                  name={getLanguage('profileTransactionManage.title', '')}
                />
              </Grid.Column>
              <Grid.Column>
                <Grid
                  gutter='10'
                  gutterVertical='10'
                >
                  {/* [TODO : RL-170] */}
                  {(isIndonesia.toko || isIndonesia.distributor) ?
                    <React.Fragment>
                      <Grid.Column>
                        <SelectControl
                          filterLarge
                          verticalLabel
                          placeholder={getLanguage('profileTransactionManage.placeholderThickness', '')}
                          onChange={(data) => {
                            // this.input.onChangeFilter('filterThicknessValue', data)
                            this.input.onChangeFilter('filterThicknessBMTValue', data)
                          }}
                          // options={optionThicknessList}
                          options={optionThicknessBMTList}
                        />
                      </Grid.Column>
                    </React.Fragment>
                    :
                    <React.Fragment>
                      <Grid.Column>
                        <SelectControl
                          filterLarge
                          filterLargePlaceholderLong
                          verticalLabel
                          placeholder={getLanguage('profileTransactionManage.placeholder', '')}
                          onChange={(data) => {
                            this.input.onChangeFilter('filterProductGroupValue', data);
                          }}
                          options={optionProductGroupList}
                        />
                      </Grid.Column>
                      <Grid.Column>
                        <SelectControl
                          filterLarge
                          verticalLabel
                          placeholder={getLanguage('profileTransactionManage.placeholderThickness', '')}
                          onChange={(data) => {
                            this.input.onChangeFilter('filterThicknessBMTValue', data)
                          }}
                          // options={optionThicknessList}
                          options={optionThicknessBMTList}
                        />
                      </Grid.Column>
                      <Grid.Column>
                        <SelectControl
                          filterLarge
                          filterLargePlaceholderShort
                          verticalLabel
                          placeholder={getLanguage('profileTransactionManage.placeholderCoilID', '')}
                          onChange={(data) => {
                            this.input.onChangeFilter('filterCoilIdValue', data)
                          }}
                          options={optionCoilIdList}
                        />
                      </Grid.Column>
                      <Grid.Column>
                        <SelectControl
                          filterLarge
                          filterLargePlaceholderShort
                          verticalLabel
                          placeholder={getLanguage('profileTransactionManage.placeholderColor', '')}
                          onChange={(data) => {
                            this.input.onChangeFilter('filterColorValue', data)
                          }}
                          options={optionColorList}
                        />
                      </Grid.Column>
                    </React.Fragment>
                  }

                </Grid>
              </Grid.Column>
            </Grid>
          </Section>
          <ShapeContainer
            ui='content'
            justify='space-between'
          >
            <Section spacingBottom='45'>
              {/* {(!Ad || !Toko) && */}
              <Table
                striped
                coilTransaction
              >
                <Table.Section>
                  <Table.Row>
                    <Table.HeadColumn
                      noSort
                      maxWidth={VARIABLES.TABLE.INFO_WIDTHS.CHECKBOX_CIRCLE}
                      minWidth={VARIABLES.TABLE.INFO_WIDTHS.CHECKBOX_CIRCLE}
                    >
                      &nbsp;
                    </Table.HeadColumn>
                    <Table.HeadColumn
                      minWidth={VARIABLES.TABLE.INFO_WIDTHS.COIL_ID}
                      sortAscending={sortIcon.lotNumber}
                      onClickSort={() => {
                        this.invList.onClickSort('lotNumber');
                      }}
                    >
                      {getLanguage('profileTransactionManage.table.header.coilID', '')}
                    </Table.HeadColumn>
                    <Table.HeadColumn
                      minWidth={VARIABLES.TABLE.INFO_WIDTHS.COIL_NAME}
                      sortAscending={sortIcon.itemDescription}
                      onClickSort={() => {
                        this.invList.onClickSort('itemDescription');
                      }}
                    >
                      {getLanguage('profileTransactionManage.table.header.coilName', '')}
                    </Table.HeadColumn>
                    <Table.HeadColumn
                      minWidth={VARIABLES.TABLE.INFO_WIDTHS.PROFILE_FULL_NAME}
                      sortAscending={sortIcon.profileType}
                      onClickSort={() => {
                        this.invList.onClickSort('profileType');
                      }}
                    >
                      {getLanguage('profileTransactionManage.table.header.profileType', '')}
                    </Table.HeadColumn>
                    <Table.HeadColumn
                      minWidth={VARIABLES.TABLE.INFO_WIDTHS.THICKNESS_BMT}
                      sortAscending={sortIcon.bmtMM}
                      onClickSort={() => {
                        this.invList.onClickSort('bmtMM');
                      }}
                    >
                      {getLanguage('profileTransactionManage.table.header.thickness', '')}
                    </Table.HeadColumn>
                    <Table.HeadColumn
                      minWidth={VARIABLES.TABLE.INFO_WIDTHS.COLOR}
                      sortAscending={sortIcon.topPaintColor}
                      onClickSort={() => {
                        this.invList.onClickSort('topPaintColor');
                      }}
                    >
                      {getLanguage('profileTransactionManage.table.header.color', '')}
                    </Table.HeadColumn>
                    <Table.HeadColumn
                      minWidth={VARIABLES.TABLE.INFO_WIDTHS.QUANTITY_SHEET}
                      sortAscending={sortIcon.fTotalRemainingprofile}
                      onClickSort={() => {
                        this.invList.onClickSort('fTotalRemainingprofile');
                      }}
                    >
                      {getLanguage('profileTransactionManage.table.header.quantity', '')}
                    </Table.HeadColumn>
                    <Table.HeadColumn
                      alignRight
                      minWidth={VARIABLES.TABLE.INFO_WIDTHS.AGING}
                      sortAscending={sortIcon.fAging}
                      onClickSort={() => {
                        this.invList.onClickSort('fAging');
                      }}
                    >
                      {getLanguage('profileTransactionManage.table.header.aging', '')}
                    </Table.HeadColumn>
                    <Table.HeadColumn
                      alignRight
                      minWidth={VARIABLES.TABLE.INFO_WIDTHS.REMAINED_WEIGHT_KG_ABBREVIATION}
                      sortAscending={sortIcon.fRemainingweight}
                      onClickSort={() => {
                        this.invList.onClickSort('fRemainingweight');
                      }}
                      title={getLanguage('profileTransactionManage.table.header.remainedWeight', '')}
                    >
                      {getLanguage('profileTransactionManage.table.header.remainedWeightAbbreviation', '')}
                    </Table.HeadColumn>
                    <Table.HeadColumn
                      alignRight
                      minWidth={VARIABLES.TABLE.INFO_WIDTHS.LENGTH_BALANCE_M}
                      sortAscending={sortIcon.fTotalRemaininglength}
                      onClickSort={() => {
                        this.invList.onClickSort('fTotalRemaininglength');
                      }}
                    >
                      {getLanguage('profileTransactionManage.table.header.lengthBalance', '')}
                    </Table.HeadColumn>
                    <Table.HeadColumn
                      alignRight
                      maxWidth={VARIABLES.TABLE.INFO_WIDTHS.TRANSFER_AMOUNT_M}
                      minWidth={VARIABLES.TABLE.INFO_WIDTHS.TRANSFER_AMOUNT_M}
                      noSort
                    >
                      {getLanguage('profileTransactionManage.table.header.transferAmount', '')}
                    </Table.HeadColumn>
                    <Table.HeadColumn
                      alignCenter
                      maxWidth={VARIABLES.TABLE.INFO_WIDTHS.FIELD_ALL_REMAINING_LENGTH}
                      minWidth={VARIABLES.TABLE.INFO_WIDTHS.FIELD_ALL_REMAINING_LENGTH}
                      noSort
                    >
                      {getLanguage('profileTransactionManage.table.header.allRemainingLength', '')}
                    </Table.HeadColumn>
                  </Table.Row>
                </Table.Section>
                <Table.Section>
                  {
                    dataList.map((e, i) => {
                      return (
                        <Table.Row
                          key={i}
                        >
                          <Table.BodyColumn
                            info
                            maxWidth={VARIABLES.TABLE.INFO_WIDTHS.CHECKBOX_CIRCLE}
                            minWidth={VARIABLES.TABLE.INFO_WIDTHS.CHECKBOX_CIRCLE}
                          >
                            <Image ui={e.orderValue > 0 ? 'iconSelectedActive' : 'iconSelectedInactive'} />
                          </Table.BodyColumn>
                          <Table.BodyColumn
                            info
                            minWidth={VARIABLES.TABLE.INFO_WIDTHS.COIL_ID}
                            title={e.lotNumber}
                          >
                            {e.lotNumber}
                          </Table.BodyColumn>
                          <Table.BodyColumn
                            info
                            minWidth={VARIABLES.TABLE.INFO_WIDTHS.COIL_NAME}
                            title={e.itemDescription}
                          >
                            {e.itemDescription}
                          </Table.BodyColumn>
                          <Table.BodyColumn
                            info
                            minWidth={VARIABLES.TABLE.INFO_WIDTHS.PROFILE_FULL_NAME}
                            title={e.profileType}
                          >
                            {e.profileType}
                          </Table.BodyColumn>
                          <Table.BodyColumn
                            info
                            minWidth={VARIABLES.TABLE.INFO_WIDTHS.THICKNESS_BMT}
                            title={e.bmtMM}
                          >
                            {e.bmtMM}
                          </Table.BodyColumn>
                          <Table.BodyColumn
                            info
                            minWidth={VARIABLES.TABLE.INFO_WIDTHS.COLOR}
                            title={e.topPaintColor}
                          >
                            {e.topPaintColor}
                          </Table.BodyColumn>
                          <Table.BodyColumn
                            info
                            minWidth={VARIABLES.TABLE.INFO_WIDTHS.QUANTITY_SHEET}
                            title={e.fTotalRemainingprofile + ' ' + e.unit}
                          >
                            {e.fTotalRemainingprofile + ' ' + e.unit}
                          </Table.BodyColumn>
                          <Table.BodyColumn
                            info
                            alignRight
                            minWidth={VARIABLES.TABLE.INFO_WIDTHS.AGING}
                            title={e.fAging}
                          >
                            {e.fAging}
                          </Table.BodyColumn>
                          <Table.BodyColumn
                            info
                            alignRight
                            minWidth={VARIABLES.TABLE.INFO_WIDTHS.REMAINED_WEIGHT_KG_ABBREVIATION}
                            title={e.fRemainingweightWithComma}
                          >
                            {e.fRemainingweightWithComma}
                          </Table.BodyColumn>
                          <Table.BodyColumn
                            info
                            alignRight
                            minWidth={VARIABLES.TABLE.INFO_WIDTHS.LENGTH_BALANCE_M}
                            title={e.fTotalRemaininglength}
                          >
                            {e.fTotalRemaininglength}
                          </Table.BodyColumn>
                          <Table.BodyColumn
                            action
                            alignRight
                            maxWidth={VARIABLES.TABLE.INFO_WIDTHS.TRANSFER_AMOUNT_M}
                            minWidth={VARIABLES.TABLE.INFO_WIDTHS.TRANSFER_AMOUNT_M}
                          >
                            <Field name='orderValue'
                              error={e.isError ? e.isError : false}
                              ui='order'
                              value={e.orderValue ? e.orderValue : 0}
                              onChange={(ev) => {
                                this.input.onChangeDataInput(ev, e)
                              }}
                            />
                          </Table.BodyColumn>
                          <Table.BodyColumn
                            action
                            alignCenter
                            maxWidth={VARIABLES.TABLE.INFO_WIDTHS.FIELD_ALL_REMAINING_LENGTH}
                            minWidth={VARIABLES.TABLE.INFO_WIDTHS.FIELD_ALL_REMAINING_LENGTH}
                          >
                            <Field
                              name='chkAll'
                              checked={e.chkAll ? e.chkAll : false}
                              ui='checkboxTickFill'
                              onChange={(ev) => {
                                this.input.onClickCheck(ev, e)
                              }}
                            />
                          </Table.BodyColumn>
                        </Table.Row>
                      )
                    })
                  }
                  {/* <Table.Row>
                      <Table.BodyColumn
                        info
                        maxWidth={VARIABLES.TABLE.INFO_WIDTHS.CHECKBOX_CIRCLE}
                        minWidth={VARIABLES.TABLE.INFO_WIDTHS.CHECKBOX_CIRCLE}
                      >
                        <Image ui={true > 0 ? 'iconSelectedActive' : 'iconSelectedInactive'} />
                      </Table.BodyColumn>
                      <Table.BodyColumn
                        info
                        minWidth={VARIABLES.TABLE.INFO_WIDTHS.COIL_ID}
                        title='MM1Z224312'
                      >
                        MM1Z224312
                      </Table.BodyColumn>
                      <Table.BodyColumn
                        info
                        minWidth={VARIABLES.TABLE.INFO_WIDTHS.COIL_NAME}
                        title='0.35TCT x 914mm BLUESCOPE ZAC0 G550 st HG RumRua'
                      >
                        0.35TCT x 914mm BLUESCOPE ZAC0 G550 st HG RumRua...
                      </Table.BodyColumn>
                      <Table.BodyColumn
                        info
                        minWidth={VARIABLES.TABLE.INFO_WIDTHS.THICKNESS}
                        title='0.230'
                      >
                        0.230
                      </Table.BodyColumn>
                      <Table.BodyColumn
                        info
                        minWidth={VARIABLES.TABLE.INFO_WIDTHS.COLOR_SHORT}
                        title='Blue'
                      >
                        Blue
                      </Table.BodyColumn>
                      <Table.BodyColumn
                        info
                        minWidth={VARIABLES.TABLE.INFO_WIDTHS.AGING}
                        title='-'
                      >
                        -
                      </Table.BodyColumn>
                      <Table.BodyColumn
                        info
                        minWidth={VARIABLES.TABLE.INFO_WIDTHS.LENGTH_BALANCE_M}
                        title='1,670'
                      >
                        1,670
                      </Table.BodyColumn>
                      <Table.BodyColumn
                        info
                        minWidth={VARIABLES.TABLE.INFO_WIDTHS.REMAINED_WEIGHT_KG}
                        title='500'
                      >
                        500
                      </Table.BodyColumn>
                      <Table.BodyColumn
                        action
                        maxWidth={VARIABLES.TABLE.INFO_WIDTHS.TRANSFER_AMOUNT_M}
                        minWidth={VARIABLES.TABLE.INFO_WIDTHS.TRANSFER_AMOUNT_M}
                      >
                        <Field name='orderValue'
                          error
                          ui='order'
                          value={2000}
                          onChange={() => {}}
                        />
                      </Table.BodyColumn>
                      <Table.BodyColumn
                        action
                        alignCenter
                        maxWidth={VARIABLES.TABLE.INFO_WIDTHS.CHECKBOX_ALL_REMAINING_LENGTH}
                        minWidth={VARIABLES.TABLE.INFO_WIDTHS.CHECKBOX_ALL_REMAINING_LENGTH}
                      >
                        <Field
                          checked={false}
                          ui='checkboxTickFill'
                          onChange={() => {}}
                        />
                      </Table.BodyColumn>
                    </Table.Row>
                    <Table.Row>
                      <Table.BodyColumn
                        info
                        maxWidth={VARIABLES.TABLE.INFO_WIDTHS.CHECKBOX_CIRCLE}
                        minWidth={VARIABLES.TABLE.INFO_WIDTHS.CHECKBOX_CIRCLE}
                      >
                        <Image ui={false > 0 ? 'iconSelectedActive' : 'iconSelectedInactive'} />
                      </Table.BodyColumn>
                      <Table.BodyColumn
                        info
                        minWidth={VARIABLES.TABLE.INFO_WIDTHS.COIL_ID}
                        title='MM2Z255760'
                      >
                        MM2Z255760
                      </Table.BodyColumn>
                      <Table.BodyColumn
                        info
                        minWidth={VARIABLES.TABLE.INFO_WIDTHS.COIL_NAME}
                        title='0.35TCT x 914mm BLUESCOPE ZAC0 G550 st HG RumRua'
                      >
                        0.35TCT x 914mm BLUESCOPE ZAC0 G550 st HG RumRua...
                      </Table.BodyColumn>
                      <Table.BodyColumn
                        info
                        minWidth={VARIABLES.TABLE.INFO_WIDTHS.THICKNESS}
                        title='0.330'
                      >
                        0.330
                      </Table.BodyColumn>
                      <Table.BodyColumn
                        info
                        minWidth={VARIABLES.TABLE.INFO_WIDTHS.COLOR_SHORT}
                        title='Blue'
                      >
                        Blue
                      </Table.BodyColumn>
                      <Table.BodyColumn
                        info
                        minWidth={VARIABLES.TABLE.INFO_WIDTHS.AGING}
                        title='-'
                      >
                        -
                      </Table.BodyColumn>
                      <Table.BodyColumn
                        info
                        minWidth={VARIABLES.TABLE.INFO_WIDTHS.LENGTH_BALANCE_M}
                        title='1,655'
                      >
                        1,655
                      </Table.BodyColumn>
                      <Table.BodyColumn
                        info
                        minWidth={VARIABLES.TABLE.INFO_WIDTHS.REMAINED_WEIGHT_KG}
                        title='350'
                      >
                        350
                      </Table.BodyColumn>
                      <Table.BodyColumn
                        action
                        maxWidth={VARIABLES.TABLE.INFO_WIDTHS.TRANSFER_AMOUNT_M}
                        minWidth={VARIABLES.TABLE.INFO_WIDTHS.TRANSFER_AMOUNT_M}
                      >
                        <Field name='orderValue'
                          // error
                          ui='order'
                          // value={}
                          onChange={() => {}}
                        />
                      </Table.BodyColumn>
                      <Table.BodyColumn
                        action
                        alignCenter
                        maxWidth={VARIABLES.TABLE.INFO_WIDTHS.CHECKBOX_ALL_REMAINING_LENGTH}
                        minWidth={VARIABLES.TABLE.INFO_WIDTHS.CHECKBOX_ALL_REMAINING_LENGTH}
                      >
                        <Field
                          checked={false}
                          ui='checkboxTickFill'
                          onChange={() => {}}
                        />
                      </Table.BodyColumn>
                    </Table.Row> */}
                </Table.Section>
              </Table>
              {/* {Ad &&
                <Table
                  striped
                  coilTransaction
                >
                  <Table.Section>
                    <Table.Row>
                      <Table.HeadColumn
                        noSort
                        maxWidth={VARIABLES.TABLE.INFO_WIDTHS.CHECKBOX_CIRCLE}
                        minWidth={VARIABLES.TABLE.INFO_WIDTHS.CHECKBOX_CIRCLE}
                      >
                        &nbsp;
                      </Table.HeadColumn>
                      <Table.HeadColumn
                        minWidth={VARIABLES.TABLE.INFO_WIDTHS.COIL_ID}
                        sortAscending={true}
                        // onClickSort={}
                      >
                        Coil ID
                      </Table.HeadColumn>
                      <Table.HeadColumn
                        minWidth={VARIABLES.TABLE.INFO_WIDTHS.COIL_NAME}
                        sortAscending={true}
                        // onClickSort={}
                      >
                        Coil Name
                      </Table.HeadColumn>
                      <Table.HeadColumn
                        minWidth={VARIABLES.TABLE.COLUMN_WIDTHS.NAMES.PROFILE_TYPE}
                        sortAscending={true}
                        // onClickSort={}
                      >
                        Profile Type
                      </Table.HeadColumn>
                      <Table.HeadColumn
                        minWidth={VARIABLES.TABLE.INFO_WIDTHS.THICKNESS}
                        sortAscending={true}
                        // onClickSort={}
                      >
                        Thickness
                      </Table.HeadColumn>
                      <Table.HeadColumn
                        minWidth={VARIABLES.TABLE.COLUMN_WIDTHS.WIDTH}
                        sortAscending={true}
                        // onClickSort={}
                      >
                        Width
                      </Table.HeadColumn>
                      <Table.HeadColumn
                        minWidth={VARIABLES.TABLE.COLUMN_WIDTHS.DATES.CREATE_NOTIME}
                        sortAscending={true}
                        // onClickSort={}
                      >
                        Create Date
                      </Table.HeadColumn>
                      <Table.HeadColumn
                        minWidth={VARIABLES.TABLE.COLUMN_WIDTHS.NUMBERS.QUANTITY}
                        sortAscending={true}
                        // onClickSort={}
                      >
                        Quantity
                      </Table.HeadColumn>
                      <Table.HeadColumn
                        minWidth={VARIABLES.TABLE.COLUMN_WIDTHS.UNIT}
                        sortAscending={true}
                        // onClickSort={}
                      >
                        Unit
                      </Table.HeadColumn>
                      <Table.HeadColumn
                        minWidth={VARIABLES.TABLE.COLUMN_WIDTHS.MEASUREMENTS.LENGTH_UNIT}
                        sortAscending={true}
                        // onClickSort={}
                      >
                        Length (m.)
                      </Table.HeadColumn>
                      <Table.HeadColumn
                        alignCenter
                        maxWidth={VARIABLES.TABLE.INFO_WIDTHS.TRANSFER_AMOUNT_M}
                        minWidth={VARIABLES.TABLE.INFO_WIDTHS.TRANSFER_AMOUNT_M}
                        noSort
                      >
                        Quantity / Unit
                      </Table.HeadColumn>
                      <Table.HeadColumn
                        alignCenter
                        maxWidth={VARIABLES.TABLE.INFO_WIDTHS.TRANSFER_AMOUNT_M}
                        minWidth={VARIABLES.TABLE.INFO_WIDTHS.TRANSFER_AMOUNT_M}
                        noSort
                      >
                        Length / Unit
                      </Table.HeadColumn>
                    </Table.Row>
                  </Table.Section>
                  <Table.Section>
                    <Table.Row>
                      <Table.BodyColumn
                        info
                        maxWidth={VARIABLES.TABLE.INFO_WIDTHS.CHECKBOX_CIRCLE}
                        minWidth={VARIABLES.TABLE.INFO_WIDTHS.CHECKBOX_CIRCLE}
                      >
                        <Image ui={true > 0 ? 'iconSelectedActive' : 'iconSelectedInactive'} />
                      </Table.BodyColumn>
                      <Table.BodyColumn
                        info
                        minWidth={VARIABLES.TABLE.INFO_WIDTHS.COIL_ID}
                        title='MM2Z255756'
                      >
                        MM2Z255756
                      </Table.BodyColumn>
                      <Table.BodyColumn
                        info
                        minWidth={VARIABLES.TABLE.INFO_WIDTHS.COIL_NAME}
                        title='0.35TCT x 914mm BLUESCOPE ZAC0 G550 st HG RumRua'
                      >
                        0.35TCT x 914mm BLUESCOPE ZAC0 G550 st HG RumRua...
                      </Table.BodyColumn>
                      <Table.BodyColumn
                        info
                        minWidth={VARIABLES.TABLE.COLUMN_WIDTHS.NAMES.PROFILE_TYPE}
                        title='Truss C75-75'
                      >
                        Truss C75-75
                      </Table.BodyColumn>
                      <Table.BodyColumn
                        info
                        minWidth={VARIABLES.TABLE.INFO_WIDTHS.THICKNESS}
                        title='0.230'
                      >
                        0.230
                      </Table.BodyColumn>
                      <Table.BodyColumn
                        info
                        minWidth={VARIABLES.TABLE.COLUMN_WIDTHS.WIDTH}
                        title='500'
                      >
                        500
                      </Table.BodyColumn>
                      <Table.BodyColumn
                        info
                        minWidth={VARIABLES.TABLE.COLUMN_WIDTHS.DATES.CREATE_NOTIME}
                      >
                        27/08/2019
                      </Table.BodyColumn>
                      <Table.BodyColumn
                        info
                        minWidth={VARIABLES.TABLE.COLUMN_WIDTHS.NUMBERS.QUANTITY}
                        title='358'
                      >
                        358
                      </Table.BodyColumn>
                      <Table.BodyColumn
                        info
                        minWidth={VARIABLES.TABLE.COLUMN_WIDTHS.UNIT}
                      >
                        kg
                      </Table.BodyColumn>
                      <Table.BodyColumn
                        info
                        minWidth={VARIABLES.TABLE.COLUMN_WIDTHS.MEASUREMENTS.LENGTH_UNIT}
                        title='100'
                      >
                        100
                      </Table.BodyColumn>
                      <Table.BodyColumn
                        action
                        alignCenter
                        maxWidth={VARIABLES.TABLE.INFO_WIDTHS.TRANSFER_AMOUNT_M}
                        minWidth={VARIABLES.TABLE.INFO_WIDTHS.TRANSFER_AMOUNT_M}
                      >
                        <Field name='orderValue'
                          // error
                          ui='order'
                          value={1646}
                          onChange={() => {}}
                        />
                      </Table.BodyColumn>
                      <Table.BodyColumn
                        action
                        alignCenter
                        maxWidth={VARIABLES.TABLE.INFO_WIDTHS.TRANSFER_AMOUNT_M}
                        minWidth={VARIABLES.TABLE.INFO_WIDTHS.TRANSFER_AMOUNT_M}
                      >
                        <Field name='orderValue'
                          // error
                          ui='order'
                          value={1646}
                          onChange={() => {}}
                        />
                      </Table.BodyColumn>
                    </Table.Row>
                  </Table.Section>
                </Table>
              }  */}
              {/* [TODO : RL-170] */}
              {(isIndonesia.toko || isIndonesia.distributor) &&
                <Table
                  striped
                  coilTransaction
                >
                  <Table.Section>
                    <Table.Row>
                      <Table.HeadColumn
                        noSort
                        maxWidth={VARIABLES.TABLE.INFO_WIDTHS.CHECKBOX_CIRCLE}
                        minWidth={VARIABLES.TABLE.INFO_WIDTHS.CHECKBOX_CIRCLE}
                      >
                        &nbsp;
                      </Table.HeadColumn>
                      <Table.HeadColumn
                        minWidth={VARIABLES.TABLE.COLUMN_WIDTHS.NAMES.PROFILE_TYPE}
                        sortAscending={true}
                      // onClickSort={}
                      >
                        Profile Type
                      </Table.HeadColumn>
                      <Table.HeadColumn
                        minWidth={VARIABLES.TABLE.INFO_WIDTHS.THICKNESS}
                        sortAscending={true}
                      // onClickSort={}
                      >
                        Thickness
                      </Table.HeadColumn>
                      <Table.HeadColumn
                        minWidth={VARIABLES.TABLE.COLUMN_WIDTHS.WIDTH_SHORT}
                        sortAscending={true}
                      // onClickSort={}
                      >
                        Width
                      </Table.HeadColumn>
                      <Table.HeadColumn
                        minWidth={VARIABLES.TABLE.COLUMN_WIDTHS.DATES.CREATE_NOTIME_SHORT}
                        sortAscending={true}
                      // onClickSort={}
                      >
                        Create Date
                      </Table.HeadColumn>
                      <Table.HeadColumn
                        minWidth={VARIABLES.TABLE.COLUMN_WIDTHS.NUMBERS.QUANTITY}
                        sortAscending={true}
                      // onClickSort={}
                      >
                        Quantity
                      </Table.HeadColumn>
                      <Table.HeadColumn
                        minWidth={VARIABLES.TABLE.COLUMN_WIDTHS.UNIT_SHORT}
                        sortAscending={true}
                      // onClickSort={}
                      >
                        Unit
                      </Table.HeadColumn>
                      <Table.HeadColumn
                        minWidth={VARIABLES.TABLE.COLUMN_WIDTHS.MEASUREMENTS.LENGTH_UNIT_SHORT}
                        sortAscending={true}
                      // onClickSort={}
                      >
                        Length (m.)
                      </Table.HeadColumn>
                      <Table.HeadColumn
                        alignCenter
                        maxWidth={VARIABLES.TABLE.INFO_WIDTHS.TRANSFER_AMOUNT_M_SHORT}
                        minWidth={VARIABLES.TABLE.INFO_WIDTHS.TRANSFER_AMOUNT_M_SHORT}
                        noSort
                      >
                        Quantity / Unit
                      </Table.HeadColumn>
                      <Table.HeadColumn
                        alignCenter
                        maxWidth={VARIABLES.TABLE.INFO_WIDTHS.TRANSFER_AMOUNT_M_SHORT}
                        minWidth={VARIABLES.TABLE.INFO_WIDTHS.TRANSFER_AMOUNT_M_SHORT}
                        noSort
                      >
                        Length / Unit
                      </Table.HeadColumn>
                    </Table.Row>
                  </Table.Section>
                  <Table.Section>
                    <Table.Row>
                      <Table.BodyColumn
                        info
                        maxWidth={VARIABLES.TABLE.INFO_WIDTHS.CHECKBOX_CIRCLE}
                        minWidth={VARIABLES.TABLE.INFO_WIDTHS.CHECKBOX_CIRCLE}
                      >
                        <Image ui={true > 0 ? 'iconSelectedActive' : 'iconSelectedInactive'} />
                      </Table.BodyColumn>
                      <Table.BodyColumn
                        info
                        minWidth={VARIABLES.TABLE.COLUMN_WIDTHS.NAMES.PROFILE_TYPE}
                        title='PROFILE_TYPE'
                      >
                        PROFILE_TYPE
                      </Table.BodyColumn>
                      <Table.BodyColumn
                        info
                        minWidth={VARIABLES.TABLE.INFO_WIDTHS.THICKNESS}
                      >
                        THICKNESS
                      </Table.BodyColumn>
                      <Table.BodyColumn
                        info
                        minWidth={VARIABLES.TABLE.COLUMN_WIDTHS.WIDTH_SHORT}
                      >
                        WIDTH
                      </Table.BodyColumn>
                      <Table.BodyColumn
                        info
                        minWidth={VARIABLES.TABLE.COLUMN_WIDTHS.DATES.CREATE_NOTIME_SHORT}
                      >
                        DATE
                      </Table.BodyColumn>
                      <Table.BodyColumn
                        info
                        minWidth={VARIABLES.TABLE.COLUMN_WIDTHS.NUMBERS.QUANTITY}
                        title='358'
                      >
                        QUANTITY
                      </Table.BodyColumn>
                      <Table.BodyColumn
                        info
                        minWidth={VARIABLES.TABLE.COLUMN_WIDTHS.UNIT_SHORT}
                      >
                        UNIT
                      </Table.BodyColumn>
                      <Table.BodyColumn
                        info
                        minWidth={VARIABLES.TABLE.COLUMN_WIDTHS.MEASUREMENTS.LENGTH_UNIT_SHORT}
                        title='100'
                      >
                        LENGTH
                      </Table.BodyColumn>
                      <Table.BodyColumn
                        action
                        alignCenter
                        maxWidth={VARIABLES.TABLE.INFO_WIDTHS.TRANSFER_AMOUNT_M_SHORT}
                        minWidth={VARIABLES.TABLE.INFO_WIDTHS.TRANSFER_AMOUNT_M_SHORT}
                      >
                        <Field name='orderValue'
                          // error
                          ui='order'
                          value={0}
                          onChange={() => { }}
                        />
                      </Table.BodyColumn>
                      <Table.BodyColumn
                        action
                        alignCenter
                        maxWidth={VARIABLES.TABLE.INFO_WIDTHS.TRANSFER_AMOUNT_M_SHORT}
                        minWidth={VARIABLES.TABLE.INFO_WIDTHS.TRANSFER_AMOUNT_M_SHORT}
                      >
                        <Field name='orderValue'
                          // error
                          ui='order'
                          value={0}
                          onChange={() => { }}
                        />
                      </Table.BodyColumn>
                    </Table.Row>
                  </Table.Section>
                </Table>
              }
            </Section>
            <TableAction>
              <TableAction.Left>
                <Grid gutter='20'>
                  <Grid.Column>
                    <Section paddingTop='6'>
                      <Text
                        regular12
                        color={VARIABLES.COLORS.GRAY_16}
                      >
                        {getLanguage('pagination.page', '')} {activePage}/{Math.ceil(filterList.length / dataPerPage)}
                      </Text>
                    </Section>
                  </Grid.Column>
                  <Grid.Column>
                    <PaginationControl
                      activePage={activePage}
                      itemsCountPerPage={dataPerPage}
                      totalItemsCount={filterList.length}
                      pageRangeDisplayed={3}
                      onChange={this.pagination.onPaginationChange}
                    />
                  </Grid.Column>
                </Grid>
              </TableAction.Left>
              <TableAction.Right>
                <Grid gutter='15'>
                  <Grid.Column>
                    <Button
                      gray
                      ui='back'
                      name={getLanguage('field.buttonBack', '')}
                      onClick={() => {
                        redirect(ROUTE_PATH.PROFILE_TRANSACTION.LINK)
                      }}
                    />
                  </Grid.Column>
                  <Grid.Column>
                    <Button
                      blue
                      name={getLanguage('field.buttonSave', '')}
                      width={VARIABLES.BUTTON.WIDTHS.W_155}
                      onClick={() => {
                        this.invList.onSave()
                      }}
                    />
                  </Grid.Column>
                </Grid>
              </TableAction.Right>
            </TableAction>
          </ShapeContainer>
        </Section>
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => {
  const dataRedux = state.dataRedux;
  const mainRedux = state.mainRedux;
  return {
    dataDetail: dataRedux.selectAD,
    // dataList: dataRedux.transferInvList,
    dataList: dataRedux.productList,
    userAuth: mainRedux[MAIN_REDUX_STATE_KEY.USER_AUTH],
    // dataParams: dataRedux.transferInvParams,
    // activePage: dataRedux.transferInvParams.activePage,
    // totalData: dataRedux.transferInvParams.totalData,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    dataActions: bindActionCreators(dataCreators, dispatch),
  }
}

export const ProfileTransactionManageScreenConnected = connect(
  mapStateToProps,
  mapDispatchToProps
)(ProfileTransactionManageScreen)