import React from 'react';
import { connect, } from 'react-redux';
import {
  Section,
  Grid,
  Modal,
  Text,
  Button,
  Field,
  PaginationControl,
} from './../../../components';
import {
  VARIABLES,
} from './../../../themes';

import { storeDispatch, storeGetState } from '../../../stores';
import { dataCreators, DATA_REDUX_STATE_KEY } from '../../../actions/dataAction';
import { ROUTE_PATH } from '../../../helpers/constants/route';
import { redirect } from '../../../helpers/functions/route';
import { CoilTransferContainerConnected } from '../../CoilTransferContainer/CoilTransferContainer';
import { bindActionCreators } from 'redux';
import { getLanguage } from '../../../helpers/functions/language';
import _ from 'lodash';

export class ModalAuthorisedDealerAndRollFormerProfileContainer extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isDisableSaveButton: false,
      dataPerPage: 10,
      filter: '',
      filterValue: '',
    };
  }

  componentDidMount() {
    const {
      adRfData,
    } = this.props;
    if (adRfData) {
      this.setState({
        adRfData,
      });
    }
  }

  modal = {
    onClickCustomer: (data) => {
      this.setState({
        adRfData: data,
        isDisableSaveButton: true
      });
    },

    onClickSaveSelectedCustomer: () => {
      const {
        isProfile,
        dataActions,
      } = this.props;
      const {
        adRfData,
      } = this.state;
      storeDispatch(dataCreators.setDataRedux('selectAD', adRfData));
      if (isProfile) {
        redirect(ROUTE_PATH.PROFILE_TRANSACTION.LINK, { adRfData })
      }
      else {
        dataActions.setDataRedux(DATA_REDUX_STATE_KEY.TRNSFER_ORDER_LIST, []);
        redirect(ROUTE_PATH.COIL_TRANSACTION.LINK, { adRfData })
      }
    }
  }

  input = {
    onChangeInput: (ev) => {
      this.setState({
        [ev.target.name]: ev.target.value
      })
    },
    onClickFilter: () => {
      const {
        dataActions,
        adRfParams
      } = this.props;
      const {
        filter,
      } = this.state;

      this.setState({
        filterValue: filter,
      }, () => {
        adRfParams.activePage = 1;
        dataActions.setDataRedux(DATA_REDUX_STATE_KEY.AD_RF_PARAMS, adRfParams)
      });
      // const params = {
      //   ...getClearCustomerParams(),
      //   page: 1,
      //   all_field: filter,
      //   callbackSuccess: (dataList) => {
      //     if (dataList.length === 0) {
      //       this.modal.getAllUserList();
      //     }
      //   }
      // };
      // getCustomerList(params);
      // this.refCustomerListContainer.customerList.handleClearIcon();
    },
  }

  ad_rf_list = {
    handleClearIcon: () => {
      const {
        sortIcon,
      } = this.state;
      let newSortIcon = {};
      Object.keys(sortIcon).forEach(e => {
        newSortIcon[e] = true;
      });
      this.setState({
        sortIcon: newSortIcon
      })
    },
    handleSortIcon: (type) => {
      const {
        sortIcon,
      } = this.state;
      let newSortIcon = {};
      let oldType = type;
      Object.keys(sortIcon).forEach(e => {
        if (sortIcon[e] === false) {
          oldType = e;
        }
        newSortIcon[e] = true;
      });
      Object.keys(sortIcon).forEach(e => {
        if (e === type) {
          if (type === oldType) {
            newSortIcon[e] = !sortIcon[e];
          }
        }
      })
      this.setState({
        sortIcon: newSortIcon
      })
      return newSortIcon
    },
    // onClickSort: (type) => {
    //   const {
    //     dataList,
    //   } = this.props;
    //   const newSortIcon = this.customerList.handleSortIcon(type);
    //   const tempDataList = handleSortData(dataList, {
    //     sortTarget: type,
    //     orderType: newSortIcon[type] ? 'desc' : 'asc'
    //   });
    //   this.customerList.updateData(tempDataList);
    // },
    onClickSelect: (data) => {
      const {
        dataList,
      } = this.props;
      dataList.forEach(e => {
        if (e.id === data.id) {
          this.modal.onClickCustomer(e);
        }
      })
    }
  }

  pagination = {
    onPaginationChange: (pageNumber) => {
      const {
        dataActions,
        adRfParams
      } = this.props;
      adRfParams.activePage = pageNumber;
      dataActions.setDataRedux(DATA_REDUX_STATE_KEY.AD_RF_PARAMS, adRfParams)
    },
    getFilterDataList: () => {
      const {
        dataList,
      } = this.props;
      const {
        filterValue,
      } = this.state;
      if (filterValue.length) {
        return this.refCoilTransfer.adRfList.getFilterDataList();
      }
      else {
        return dataList;
      }
    },
  }

  render() {
    const {
      isOpenModal,
      onClickClose,
      isDisableSaveButton,
      adRfParams,
    } = this.props;

    const {
      adRfData,
      filterValue,
      filter,
      dataPerPage,
    } = this.state;

    return (
      <React.Fragment>
        <Modal
          open={isOpenModal}
          scroll
          // enableOverlayClose={false}
          width={VARIABLES.MODAL.WIDTHS.W_1145}
          overlayColor={VARIABLES.COLORS.OVERLAY_BLACK_1}
          spacing={VARIABLES.MODAL.CONTAINER.C_1.SPACING}
          transition={VARIABLES.TRANSITIONS.DEFAULT}
          transformStart={VARIABLES.MODAL.CONTAINER.C_1.TRANSFORM_START}
          transformEnd={VARIABLES.MODAL.CONTAINER.C_1.TRANSFORM_END}
          onClickClose={onClickClose}
        >
          <Modal.Section
            paddingVertical='30'
            paddingHorizontal='40'
            bgColor={VARIABLES.COLORS.WHITE}
            borderBottomWidth='1'
            borderBottomStyle='solid'
            borderBottomColor={VARIABLES.COLORS.GRAY_8}
            borderTopRightRadius={VARIABLES.MODAL.HEADER.H_1.BORDER_TOP_RIGHT_RADIUS}
            borderTopLeftRadius={VARIABLES.MODAL.HEADER.H_1.BORDER_TOP_LEFT_RADIUS}
          >
            <Text
              regular16
              singleLine
              color={VARIABLES.COLORS.BLACK}
            >
              {getLanguage('modal.adAndRf.title', '')}
            </Text>
          </Modal.Section>
          <Modal.Section
            paddingTop='20'
            paddingBottom='30'
            paddingHorizontal='45'
            bgColor={VARIABLES.COLORS.WHITE}
          >
            <Section spacingBottom='25'>
              <Grid
                gutter='10'
                justify='flex-end'
              >
                <Grid.Column>
                  <Field
                    border
                    name='filter'
                    ui='blockLabelVerticalSearch'
                    width='350'
                    placeholder={getLanguage('modal.adAndRf.placeholder', '')}
                    value={filter}
                    onChange={this.input.onChangeInput}
                  />
                </Grid.Column>
                <Grid.Column>
                  <Button
                    lightBlue
                    name={getLanguage('modal.adAndRf.buttonSearch', '')}
                    width={VARIABLES.BUTTON.WIDTHS.W_105}
                    onClick={this.input.onClickFilter}
                  />
                </Grid.Column>
              </Grid>
            </Section>
            <Section spacingBottom='30'>
              <CoilTransferContainerConnected
                // type='selectCustomer'
                innerRef={r => this.refCoilTransfer = r}
                adRfData={adRfData}
                filterValue={filterValue}
                onClickCustomer={this.modal.onClickCustomer}
              />
            </Section>
            <Section justify='flex-end'>
              <PaginationControl
                activePage={adRfParams.activePage}
                itemsCountPerPage={dataPerPage}
                totalItemsCount={this.pagination.getFilterDataList().length}
                pageRangeDisplayed={3}
                onChange={this.pagination.onPaginationChange}
              />
            </Section>
          </Modal.Section>
          <Modal.Section
            paddingVertical='30'
            paddingHorizontal='45'
            borderTopWidth={VARIABLES.MODAL.FOOTER.F_1.BORDER_TOP_WIDTH}
            borderTopStyle={VARIABLES.MODAL.FOOTER.F_1.BORDER_TOP_STYLE}
            borderTopColor={VARIABLES.COLORS.GRAY_8}
            borderBottomRightRadius={VARIABLES.MODAL.FOOTER.F_1.BORDER_BOTTOM_RIGHT_RADIUS}
            borderBottomLeftRadius={VARIABLES.MODAL.FOOTER.F_1.BORDER_BOTTOM_LEFT_RADIUS}
          >
            <Grid
              gutter='15'
              justify='flex-end'
            >
              <Grid.Column>
                <Button
                  gray
                  width={VARIABLES.BUTTON.WIDTHS.W_155}
                  name={getLanguage('field.buttonCancel', '')}
                  onClick={onClickClose}
                />
              </Grid.Column>
              <Grid.Column>
                <Button
                  blue
                  disabled={isDisableSaveButton}
                  width={VARIABLES.BUTTON.WIDTHS.W_155}
                  name={getLanguage('field.buttonSelect', '')}
                  onClick={this.modal.onClickSaveSelectedCustomer}
                />
              </Grid.Column>
            </Grid>
          </Modal.Section>
        </Modal>
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => {
  const dataRedux = state.dataRedux;
  return {
    dataList: dataRedux.adRfList,
    adRfParams: dataRedux.adRfParams,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    dataActions: bindActionCreators(dataCreators, dispatch),
  }
}

export const ModalAuthorisedDealerAndRollFormerProfileContainerConnected = connect(
  mapStateToProps,
  mapDispatchToProps
)(ModalAuthorisedDealerAndRollFormerProfileContainer)