import {
  css
} from 'styled-components'
import {
  default as VARIABLES
} from './../../../themes/styles/bases/variables' // Use relative path for React Styleguidist

// //////////////////////////////////////////////////////////////////////////////////////////////////
// ==================================================================================================
//
// Typographys:
//
// First (Sarabun)
// ------------------------------
// * Regular
//   - TYPOGRAPHYS.FIRST.REGULAR.FS_36
//   - TYPOGRAPHYS.FIRST.REGULAR.FS_28
//   - TYPOGRAPHYS.FIRST.REGULAR.FS_24
//   - TYPOGRAPHYS.FIRST.REGULAR.FS_22
//   - TYPOGRAPHYS.FIRST.REGULAR.FS_20
//   - TYPOGRAPHYS.FIRST.REGULAR.FS_18
//   - TYPOGRAPHYS.FIRST.REGULAR.FS_16
//   - TYPOGRAPHYS.FIRST.REGULAR.FS_14
//   - TYPOGRAPHYS.FIRST.REGULAR.FS_12
//   - TYPOGRAPHYS.FIRST.REGULAR.FS_11
//   - TYPOGRAPHYS.FIRST.REGULAR.FS_10
//   - TYPOGRAPHYS.FIRST.REGULAR.FS_9
//   - TYPOGRAPHYS.FIRST.REGULAR.FS_8
//   - TYPOGRAPHYS.FIRST.REGULAR.FS_7
//
// * Medium
//   - TYPOGRAPHYS.FIRST.MEDIUM.FS_36
//   - TYPOGRAPHYS.FIRST.MEDIUM.FS_28
//   - TYPOGRAPHYS.FIRST.MEDIUM.FS_24
//   - TYPOGRAPHYS.FIRST.MEDIUM.FS_22
//   - TYPOGRAPHYS.FIRST.MEDIUM.FS_20
//   - TYPOGRAPHYS.FIRST.MEDIUM.FS_18
//   - TYPOGRAPHYS.FIRST.MEDIUM.FS_16
//   - TYPOGRAPHYS.FIRST.MEDIUM.FS_14
//   - TYPOGRAPHYS.FIRST.MEDIUM.FS_12
//   - TYPOGRAPHYS.FIRST.MEDIUM.FS_11
//   - TYPOGRAPHYS.FIRST.MEDIUM.FS_10
//   - TYPOGRAPHYS.FIRST.MEDIUM.FS_9
//   - TYPOGRAPHYS.FIRST.MEDIUM.FS_8
//   - TYPOGRAPHYS.FIRST.MEDIUM.FS_7
//
// * Bold
//   - TYPOGRAPHYS.FIRST.BOLD.FS_36
//   - TYPOGRAPHYS.FIRST.BOLD.FS_28
//   - TYPOGRAPHYS.FIRST.BOLD.FS_24
//   - TYPOGRAPHYS.FIRST.BOLD.FS_22
//   - TYPOGRAPHYS.FIRST.BOLD.FS_20
//   - TYPOGRAPHYS.FIRST.BOLD.FS_18
//   - TYPOGRAPHYS.FIRST.BOLD.FS_16
//   - TYPOGRAPHYS.FIRST.BOLD.FS_14
//   - TYPOGRAPHYS.FIRST.BOLD.FS_12
//   - TYPOGRAPHYS.FIRST.BOLD.FS_11
//   - TYPOGRAPHYS.FIRST.BOLD.FS_10
//   - TYPOGRAPHYS.FIRST.BOLD.FS_9
//   - TYPOGRAPHYS.FIRST.BOLD.FS_8
//   - TYPOGRAPHYS.FIRST.BOLD.FS_7
//
// Notice: Color eg. plain text, link etc. can set at variables.js or 'color' props of Text/TextBase component
//
// ==================================================================================================
// //////////////////////////////////////////////////////////////////////////////////////////////////

// Text default style
// ============================================================
const FONT_STYLES = {
  DEFAULT: css`
    font-weight: normal;
    text-transform: none;
  `,
  FAMILIES: {
    FIRST: {
      REGULAR: css`
        font-family: ${VARIABLES.FONT.FAMILIES.FIRST.WEIGHTS.REGULAR};
      `,

      MEDIUM: css`
        font-family: ${VARIABLES.FONT.FAMILIES.FIRST.WEIGHTS.MEDIUM};
      `,

      BOLD: css`
        font-family: ${VARIABLES.FONT.FAMILIES.FIRST.WEIGHTS.BOLD};
      `
    }
  },
  SIZES: {
    FIRST: {
      FS_36: css`
        font-size: ${VARIABLES.FONT.FAMILIES.FIRST.SIZES.FS_36 + `px`};
        line-height: ${Math.round(VARIABLES.FONT.FAMILIES.FIRST.SIZES.FS_36 * VARIABLES.FONT.FAMILIES.FIRST.LINE_HEIGHT_FACTOR) + `px`};
      `,

      FS_28: css`
        font-size: ${VARIABLES.FONT.FAMILIES.FIRST.SIZES.FS_28 + `px`};
        line-height: ${Math.round(VARIABLES.FONT.FAMILIES.FIRST.SIZES.FS_28 * VARIABLES.FONT.FAMILIES.FIRST.LINE_HEIGHT_FACTOR) + `px`};
      `,

      FS_24: css`
        font-size: ${VARIABLES.FONT.FAMILIES.FIRST.SIZES.FS_24 + `px`};
        line-height: ${Math.round(VARIABLES.FONT.FAMILIES.FIRST.SIZES.FS_24 * VARIABLES.FONT.FAMILIES.FIRST.LINE_HEIGHT_FACTOR) + `px`};
      `,

      FS_22: css`
        font-size: ${VARIABLES.FONT.FAMILIES.FIRST.SIZES.FS_22 + `px`};
        line-height: ${Math.round(VARIABLES.FONT.FAMILIES.FIRST.SIZES.FS_22 * VARIABLES.FONT.FAMILIES.FIRST.LINE_HEIGHT_FACTOR) + `px`};
      `,

      FS_20: css`
        font-size: ${VARIABLES.FONT.FAMILIES.FIRST.SIZES.FS_20 + `px`};
        line-height: ${Math.round(VARIABLES.FONT.FAMILIES.FIRST.SIZES.FS_20 * VARIABLES.FONT.FAMILIES.FIRST.LINE_HEIGHT_FACTOR) + `px`};
      `,

      FS_18: css`
        font-size: ${VARIABLES.FONT.FAMILIES.FIRST.SIZES.FS_18 + `px`};
        line-height: ${Math.round(VARIABLES.FONT.FAMILIES.FIRST.SIZES.FS_18 * VARIABLES.FONT.FAMILIES.FIRST.LINE_HEIGHT_FACTOR) + `px`};
      `,

      FS_16: css`
        font-size: ${VARIABLES.FONT.FAMILIES.FIRST.SIZES.FS_16 + `px`};
        line-height: ${Math.round(VARIABLES.FONT.FAMILIES.FIRST.SIZES.FS_16 * VARIABLES.FONT.FAMILIES.FIRST.LINE_HEIGHT_FACTOR) + `px`};
      `,

      FS_14: css`
        font-size: ${VARIABLES.FONT.FAMILIES.FIRST.SIZES.FS_14 + `px`};
        line-height: ${Math.round(VARIABLES.FONT.FAMILIES.FIRST.SIZES.FS_14 * VARIABLES.FONT.FAMILIES.FIRST.LINE_HEIGHT_FACTOR) + `px`};
      `,

      FS_12: css`
        font-size: ${VARIABLES.FONT.FAMILIES.FIRST.SIZES.FS_12 + `px`};
        line-height: ${Math.round(VARIABLES.FONT.FAMILIES.FIRST.SIZES.FS_12 * VARIABLES.FONT.FAMILIES.FIRST.LINE_HEIGHT_FACTOR) + `px`};
      `,

      FS_11: css`
        font-size: ${VARIABLES.FONT.FAMILIES.FIRST.SIZES.FS_11 + `px`};
        line-height: ${Math.round(VARIABLES.FONT.FAMILIES.FIRST.SIZES.FS_11 * VARIABLES.FONT.FAMILIES.FIRST.LINE_HEIGHT_FACTOR) + `px`};
      `,

      FS_10: css`
        font-size: ${VARIABLES.FONT.FAMILIES.FIRST.SIZES.FS_10 + `px`};
        line-height: ${Math.round(VARIABLES.FONT.FAMILIES.FIRST.SIZES.FS_10 * VARIABLES.FONT.FAMILIES.FIRST.LINE_HEIGHT_FACTOR) + `px`};
      `,

      FS_9: css`
        font-size: ${VARIABLES.FONT.FAMILIES.FIRST.SIZES.FS_9 + `px`};
        line-height: ${Math.round(VARIABLES.FONT.FAMILIES.FIRST.SIZES.FS_9 * VARIABLES.FONT.FAMILIES.FIRST.LINE_HEIGHT_FACTOR) + `px`};
      `,

      FS_8: css`
        font-size: ${VARIABLES.FONT.FAMILIES.FIRST.SIZES.FS_8 + `px`};
        line-height: ${Math.round(VARIABLES.FONT.FAMILIES.FIRST.SIZES.FS_8 * VARIABLES.FONT.FAMILIES.FIRST.LINE_HEIGHT_FACTOR) + `px`};
      `,

      FS_7: css`
        font-size: ${VARIABLES.FONT.FAMILIES.FIRST.SIZES.FS_7 + `px`};
        line-height: ${Math.round(VARIABLES.FONT.FAMILIES.FIRST.SIZES.FS_7 * VARIABLES.FONT.FAMILIES.FIRST.LINE_HEIGHT_FACTOR) + `px`};
      `
    }
  }
}

export default {
  // First (Sarabun)
  // ============================================================
  FIRST: {
    // Regular
    // ------------------------------
    REGULAR: {
      // Font size > 48px
      FS_36: css`
        ${FONT_STYLES.DEFAULT}
        ${FONT_STYLES.FAMILIES.FIRST.REGULAR}
        ${FONT_STYLES.SIZES.FIRST.FS_36}
      `,

      // Font size > 36px
      FS_28: css`
        ${FONT_STYLES.DEFAULT}
        ${FONT_STYLES.FAMILIES.FIRST.REGULAR}
        ${FONT_STYLES.SIZES.FIRST.FS_28}
      `,

      // Font size > 30px
      FS_24: css`
        ${FONT_STYLES.DEFAULT}
        ${FONT_STYLES.FAMILIES.FIRST.REGULAR}
        ${FONT_STYLES.SIZES.FIRST.FS_24}
      `,

      // Font size > 28px
      FS_22: css`
        ${FONT_STYLES.DEFAULT}
        ${FONT_STYLES.FAMILIES.FIRST.REGULAR}
        ${FONT_STYLES.SIZES.FIRST.FS_22}
      `,

      // Font size > 25px
      FS_20: css`
        ${FONT_STYLES.DEFAULT}
        ${FONT_STYLES.FAMILIES.FIRST.REGULAR}
        ${FONT_STYLES.SIZES.FIRST.FS_20}
      `,

      // Font size > 22px
      FS_18: css`
        ${FONT_STYLES.DEFAULT}
        ${FONT_STYLES.FAMILIES.FIRST.REGULAR}
        ${FONT_STYLES.SIZES.FIRST.FS_18}
      `,

      // Font size > 20px
      FS_16: css`
        ${FONT_STYLES.DEFAULT}
        ${FONT_STYLES.FAMILIES.FIRST.REGULAR}
        ${FONT_STYLES.SIZES.FIRST.FS_16}
      `,

      // Font size > 18px
      FS_14: css`
        ${FONT_STYLES.DEFAULT}
        ${FONT_STYLES.FAMILIES.FIRST.REGULAR}
        ${FONT_STYLES.SIZES.FIRST.FS_14}
      `,

      // Font size > 16px
      FS_12: css`
        ${FONT_STYLES.DEFAULT}
        ${FONT_STYLES.FAMILIES.FIRST.REGULAR}
        ${FONT_STYLES.SIZES.FIRST.FS_12}
      `,

      // Font size > 15px
      FS_11: css`
        ${FONT_STYLES.DEFAULT}
        ${FONT_STYLES.FAMILIES.FIRST.REGULAR}
        ${FONT_STYLES.SIZES.FIRST.FS_11}
      `,

      // Font size > 14px
      FS_10: css`
        ${FONT_STYLES.DEFAULT}
        ${FONT_STYLES.FAMILIES.FIRST.REGULAR}
        ${FONT_STYLES.SIZES.FIRST.FS_10}
      `,

      // Font size > 12px
      FS_9: css`
        ${FONT_STYLES.DEFAULT}
        ${FONT_STYLES.FAMILIES.FIRST.REGULAR}
        ${FONT_STYLES.SIZES.FIRST.FS_9}
      `,

      // Font size > 11px
      FS_8: css`
        ${FONT_STYLES.DEFAULT}
        ${FONT_STYLES.FAMILIES.FIRST.REGULAR}
        ${FONT_STYLES.SIZES.FIRST.FS_8}
      `,

      // Font size > 10px
      FS_7: css`
        ${FONT_STYLES.DEFAULT}
        ${FONT_STYLES.FAMILIES.FIRST.REGULAR}
        ${FONT_STYLES.SIZES.FIRST.FS_7}
      `
    },

    // Medium
    // ------------------------------
    MEDIUM: {
      // Font size > 48px
      FS_36: css`
        ${FONT_STYLES.DEFAULT}
        ${FONT_STYLES.FAMILIES.FIRST.MEDIUM}
        ${FONT_STYLES.SIZES.FIRST.FS_36}
      `,

      // Font size > 36px
      FS_28: css`
        ${FONT_STYLES.DEFAULT}
        ${FONT_STYLES.FAMILIES.FIRST.MEDIUM}
        ${FONT_STYLES.SIZES.FIRST.FS_28}
      `,

      // Font size > 30px
      FS_24: css`
        ${FONT_STYLES.DEFAULT}
        ${FONT_STYLES.FAMILIES.FIRST.MEDIUM}
        ${FONT_STYLES.SIZES.FIRST.FS_24}
      `,

      // Font size > 28px
      FS_22: css`
        ${FONT_STYLES.DEFAULT}
        ${FONT_STYLES.FAMILIES.FIRST.MEDIUM}
        ${FONT_STYLES.SIZES.FIRST.FS_22}
      `,

      // Font size > 25px
      FS_20: css`
        ${FONT_STYLES.DEFAULT}
        ${FONT_STYLES.FAMILIES.FIRST.MEDIUM}
        ${FONT_STYLES.SIZES.FIRST.FS_20}
      `,

      // Font size > 22px
      FS_18: css`
        ${FONT_STYLES.DEFAULT}
        ${FONT_STYLES.FAMILIES.FIRST.MEDIUM}
        ${FONT_STYLES.SIZES.FIRST.FS_18}
      `,

      // Font size > 20px
      FS_16: css`
        ${FONT_STYLES.DEFAULT}
        ${FONT_STYLES.FAMILIES.FIRST.MEDIUM}
        ${FONT_STYLES.SIZES.FIRST.FS_16}
      `,

      // Font size > 18px
      FS_14: css`
        ${FONT_STYLES.DEFAULT}
        ${FONT_STYLES.FAMILIES.FIRST.MEDIUM}
        ${FONT_STYLES.SIZES.FIRST.FS_14}
      `,

      // Font size > 16px
      FS_12: css`
        ${FONT_STYLES.DEFAULT}
        ${FONT_STYLES.FAMILIES.FIRST.MEDIUM}
        ${FONT_STYLES.SIZES.FIRST.FS_12}
      `,

      // Font size > 15px
      FS_11: css`
        ${FONT_STYLES.DEFAULT}
        ${FONT_STYLES.FAMILIES.FIRST.MEDIUM}
        ${FONT_STYLES.SIZES.FIRST.FS_11}
      `,

      // Font size > 14px
      FS_10: css`
        ${FONT_STYLES.DEFAULT}
        ${FONT_STYLES.FAMILIES.FIRST.MEDIUM}
        ${FONT_STYLES.SIZES.FIRST.FS_10}
      `,

      // Font size > 12px
      FS_9: css`
        ${FONT_STYLES.DEFAULT}
        ${FONT_STYLES.FAMILIES.FIRST.MEDIUM}
        ${FONT_STYLES.SIZES.FIRST.FS_9}
      `,

      // Font size > 11px
      FS_8: css`
        ${FONT_STYLES.DEFAULT}
        ${FONT_STYLES.FAMILIES.FIRST.MEDIUM}
        ${FONT_STYLES.SIZES.FIRST.FS_8}
      `,

      // Font size > 10px
      FS_7: css`
        ${FONT_STYLES.DEFAULT}
        ${FONT_STYLES.FAMILIES.FIRST.MEDIUM}
        ${FONT_STYLES.SIZES.FIRST.FS_7}
      `
    },

    // Bold
    // ------------------------------
    BOLD: {
      // Font size > 48px
      FS_36: css`
        ${FONT_STYLES.DEFAULT}
        ${FONT_STYLES.FAMILIES.FIRST.BOLD}
        ${FONT_STYLES.SIZES.FIRST.FS_36}
      `,

      // Font size > 36px
      FS_28: css`
        ${FONT_STYLES.DEFAULT}
        ${FONT_STYLES.FAMILIES.FIRST.BOLD}
        ${FONT_STYLES.SIZES.FIRST.FS_28}
      `,

      // Font size > 30px
      FS_24: css`
        ${FONT_STYLES.DEFAULT}
        ${FONT_STYLES.FAMILIES.FIRST.BOLD}
        ${FONT_STYLES.SIZES.FIRST.FS_24}
      `,

      // Font size > 28px
      FS_22: css`
        ${FONT_STYLES.DEFAULT}
        ${FONT_STYLES.FAMILIES.FIRST.BOLD}
        ${FONT_STYLES.SIZES.FIRST.FS_22}
      `,

      // Font size > 25px
      FS_20: css`
        ${FONT_STYLES.DEFAULT}
        ${FONT_STYLES.FAMILIES.FIRST.BOLD}
        ${FONT_STYLES.SIZES.FIRST.FS_20}
      `,

      // Font size > 22px
      FS_18: css`
        ${FONT_STYLES.DEFAULT}
        ${FONT_STYLES.FAMILIES.FIRST.BOLD}
        ${FONT_STYLES.SIZES.FIRST.FS_18}
      `,

      // Font size > 20px
      FS_16: css`
        ${FONT_STYLES.DEFAULT}
        ${FONT_STYLES.FAMILIES.FIRST.BOLD}
        ${FONT_STYLES.SIZES.FIRST.FS_16}
      `,

      // Font size > 18px
      FS_14: css`
        ${FONT_STYLES.DEFAULT}
        ${FONT_STYLES.FAMILIES.FIRST.BOLD}
        ${FONT_STYLES.SIZES.FIRST.FS_14}
      `,

      // Font size > 16px
      FS_12: css`
        ${FONT_STYLES.DEFAULT}
        ${FONT_STYLES.FAMILIES.FIRST.BOLD}
        ${FONT_STYLES.SIZES.FIRST.FS_12}
      `,

      // Font size > 15px
      FS_11: css`
        ${FONT_STYLES.DEFAULT}
        ${FONT_STYLES.FAMILIES.FIRST.BOLD}
        ${FONT_STYLES.SIZES.FIRST.FS_11}
      `,

      // Font size > 14px
      FS_10: css`
        ${FONT_STYLES.DEFAULT}
        ${FONT_STYLES.FAMILIES.FIRST.BOLD}
        ${FONT_STYLES.SIZES.FIRST.FS_10}
      `,

      // Font size > 12px
      FS_9: css`
        ${FONT_STYLES.DEFAULT}
        ${FONT_STYLES.FAMILIES.FIRST.BOLD}
        ${FONT_STYLES.SIZES.FIRST.FS_9}
      `,

      // Font size > 11px
      FS_8: css`
        ${FONT_STYLES.DEFAULT}
        ${FONT_STYLES.FAMILIES.FIRST.BOLD}
        ${FONT_STYLES.SIZES.FIRST.FS_8}
      `,

      // Font size > 10px
      FS_7: css`
        ${FONT_STYLES.DEFAULT}
        ${FONT_STYLES.FAMILIES.FIRST.BOLD}
        ${FONT_STYLES.SIZES.FIRST.FS_7}
      `
    }
  }
}
