import React from 'react'
import {
  ShapeContainer,
  Section,
  TitlePage,
  TitleSection,
  Grid,
  Field,
  Text,
  Button
} from './../../components'
import {
  VARIABLES,
  ICONS
} from './../../themes'
import { toast } from '../../helpers/functions/main';
import _ from 'lodash';
import { getLanguage } from '../../helpers/functions/language';

export class AdInformationChangePassword extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      password: '',
      newPassword: '',
      confirmPassword: '',
    }
  }

  adInfoChangePassword = {
    onClickSubmit: () => {
      const {
        onClickSubmit,
      } = this.props;
      const {
        password,
        newPassword,
        confirmPassword,
      } = this.state;
      if (newPassword !== confirmPassword) {
        toast.error(getLanguage('message.newPasswordAndConfirmPasswordNotMatch', ''));
      }
      else {
        this.setState({
          password: '',
          newPassword: '',
          confirmPassword: '',
        }, () => {
          onClickSubmit(password, newPassword);
        })
      }
    }
  }

  input = {
    onChangeInputUserAuth: (ev) => {
      const name = ev.target.name;
      const value = ev.target.value;
      this.setState({
        [name]: value,
      });
    },
  }

  render() {
    const {
      onClickBack,
      isSalePerson,
    } = this.props;
    const {
      password,
      newPassword,
      confirmPassword,
    } = this.state;
    return (
      <Section ui='content'>
        <Section spacingBottom='30'>
          <TitlePage
            src={ICONS['ic-page-settings.svg']}
            name={getLanguage('adInfo.form.changePassword.title', '')}
          />
        </Section>
        <ShapeContainer
          ui='content'
          justify='space-between'
        >
          <Section spacingBottom='45'>
            <Section spacingBottom='50'>
              <TitleSection name={getLanguage('adInfo.form.changePassword.changeYourPassword', '')} />
            </Section>
            <ShapeContainer ui='contentInner'>
              <Grid>
                <Grid.Column gridTablet='7' gridLargeDesktop='6'>
                  <Section spacingBottom='30'>
                    <Field name='password' inputTextType='password'
                      fluid
                      required
                      changePassword
                      // error
                      ui='blockLabelVertical'
                      label={getLanguage('field.oldPassword', '')}
                      placeholder={getLanguage('field.oldPasswordPlaceholder', '')}
                      value={password}
                      onChange={this.input.onChangeInputUserAuth}
                    />
                  </Section>
                  <Section spacingBottom='30'>
                    <Field name='newPassword' inputTextType='password'
                      fluid
                      required
                      changePassword
                      // error
                      ui='blockLabelVertical'
                      label={getLanguage('field.newPassword', '')}
                      placeholder={getLanguage('field.newPasswordPlaceholder', '')}
                      value={newPassword}
                      onChange={this.input.onChangeInputUserAuth}
                    />
                  </Section>
                  <Section>
                    <Field name='confirmPassword' inputTextType='password'
                      fluid
                      required
                      changePassword
                      // error
                      ui='blockLabelVertical'
                      label={getLanguage('field.confirmPassword', '')}
                      placeholder={getLanguage('field.confirmPasswordPlaceholder', '')}
                      value={confirmPassword}
                      onChange={this.input.onChangeInputUserAuth}
                    />
                  </Section>
                </Grid.Column>
                <Grid.Column gridTablet='5' gridLargeDesktop='6'>
                  <Section ui='hintPassword'>
                    <Section spacingBottom='25'>
                      <Text
                        bold14
                        color={VARIABLES.COLORS.PRIMARY_2}
                      >
                        {getLanguage('adInfo.form.changePassword.passwordPolicies', '')}
                      </Text>
                    </Section>
                    <Section spacingBottom='25'>
                      <Grid gutter='10'>
                        <Grid.Column>
                          <ShapeContainer
                            width='18'
                            height='8'
                            bgColor={VARIABLES.COLORS.PRIMARY_2}
                            borderRadius='4'
                          />
                        </Grid.Column>
                        <Grid.Column>
                          <Text
                            regular14
                            color={VARIABLES.COLORS.GRAY_19}
                          >
                            {getLanguage('adInfo.form.changePassword.passwordMinimum_1', '')}
                          </Text>
                        </Grid.Column>
                      </Grid>
                    </Section>
                    <Section spacingBottom='25'>
                      <Grid gutter='10'>
                        <Grid.Column>
                          <ShapeContainer
                            width='18'
                            height='8'
                            bgColor={VARIABLES.COLORS.PRIMARY_2}
                            borderRadius='4'
                          />
                        </Grid.Column>
                        <Grid.Column>
                          <Text
                            regular14
                            color={VARIABLES.COLORS.GRAY_19}
                          >
                            {getLanguage('adInfo.form.changePassword.passwordMinimum_2', '')}
                          </Text>
                        </Grid.Column>
                      </Grid>
                    </Section>
                    <Section>
                      <Grid gutter='10'>
                        <Grid.Column>
                          <ShapeContainer
                            width='18'
                            height='8'
                            bgColor={VARIABLES.COLORS.PRIMARY_2}
                            borderRadius='4'
                          />
                        </Grid.Column>
                        <Grid.Column>
                          <Text
                            regular14
                            color={VARIABLES.COLORS.GRAY_19}
                          >
                            {getLanguage('adInfo.form.changePassword.passwordMinimum_3', '')}
                          </Text>
                        </Grid.Column>
                      </Grid>
                    </Section>
                  </Section>
                </Grid.Column>
              </Grid>
            </ShapeContainer>
          </Section>
          <Grid
            gutter='15'
            justify='flex-end'
          >
            {!isSalePerson &&
              <Grid.Column>
                <Button
                  gray
                  ui='frontIcon'
                  name={getLanguage('field.buttonBack', '')}
                  width={VARIABLES.BUTTON.WIDTHS.W_145}
                  iconWidth='13'
                  iconHeight='13'
                  iconSrc={ICONS['ic-arrow-back.svg']}
                  onClick={onClickBack}
                />
              </Grid.Column>
            }
            <Grid.Column>
              <Button
                blue
                name={getLanguage('field.buttonSave', '')}
                width={VARIABLES.BUTTON.WIDTHS.W_155}
                onClick={this.adInfoChangePassword.onClickSubmit}
              />
            </Grid.Column>
          </Grid>
        </ShapeContainer>
      </Section>
    )
  }
}
