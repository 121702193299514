import React from "react";
import ReactToPrint from "react-to-print";
import {
  Section,
  Image,
  Grid,
  SwiperCarousel,
  Modal,
  ShapeContainer,
  Text,
  Button,
} from "./../../../components";
import { ModalSocialShareContainer } from "../ModalSocialShareContainer";
import { VARIABLES, CONTENTS, ICONS } from "./../../../themes";
import { BASE_API } from "../../../config/config";
import { ROUTE_PATH } from "../../../helpers/constants/route";
import _ from "lodash";
import { getLanguage } from "../../../helpers/functions/language";

class NewsAndPromotionsPrintContent extends React.Component {
  render() {
    const { data } = this.props;
    return (
      <Section padding="60">
        <Section
          paddingBottom="30"
          spacingBottom="40"
          borderBottomWidth="1"
          borderBottomStyle="solid"
          borderBottomColor={VARIABLES.COLORS.BLACK}
        >
          <Text bold24 color={VARIABLES.COLORS.BLACK}>
            {data.subject}
          </Text>
        </Section>
        <Section spacingBottom="30">
          <Image
            heightRatio={VARIABLES.BANNER.NEWS_AND_PROMOTIONS_HEIGHT_RATIO}
            src={data.cover.path}
          />
        </Section>
        <Section className="text-editor-content">
          <div
            dangerouslySetInnerHTML={{
              __html: data.content,
            }}
          />
        </Section>
      </Section>
    );
  }
}

export class ModalNewsAndPromotionsContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpenSocialShareModal: false,
      dataPrint: {
        cover: {},
      },
      shareUrlPath: "",
    };
  }

  modal = {
    onClickOpenSocialShareModal: () => {
      this.setState({
        isOpenSocialShareModal: true,
      });
    },

    onClickCloseSocialShareModal: () => {
      this.setState({
        isOpenSocialShareModal: false,
      });
    },
    onClickPrint: (index) => {
      const { dataList } = this.props;
      return new Promise((resolve) => {
        this.setState(
          {
            dataPrint: dataList[index],
          },
          () => {
            resolve();
          }
        );
      });
    },
    onClickShare: (data) => {
      const shareUrlPath = `${window.location.origin}${ROUTE_PATH.PUBLIC.NEWS_AND_PROMOTION.LINK}/${data.id}`;
      console.log("shareUrlPath", shareUrlPath);
      this.setState(
        {
          shareUrlPath,
        },
        () => {
          this.modal.onClickOpenSocialShareModal();
        }
      );
    },
  };

  render() {
    const { initSlideIndex, dataList, open, onClickClose } = this.props;
    const { isOpenSocialShareModal, dataPrint, shareUrlPath } = this.state;
    console.log("shareUrlPath", shareUrlPath);
    return (
      <React.Fragment>
        <Modal
          open={open}
          scroll
          className="print-news-promotion-box"
          // enableOverlayClose={false}
          width={VARIABLES.MODAL.WIDTHS.W_1145}
          overlayColor={VARIABLES.COLORS.OVERLAY_BLACK_1}
          spacing={VARIABLES.MODAL.CONTAINER.C_1.SPACING}
          transition={VARIABLES.TRANSITIONS.DEFAULT}
          transformStart={VARIABLES.MODAL.CONTAINER.C_1.TRANSFORM_START}
          transformEnd={VARIABLES.MODAL.CONTAINER.C_1.TRANSFORM_END}
          onClickClose={onClickClose}
        >
          <Modal.Section
            className="print-section"
            paddingTop={VARIABLES.MODAL.HEADER.H_1.PADDING_TOP}
            bgColor={VARIABLES.COLORS.WHITE}
            borderTopRightRadius={
              VARIABLES.MODAL.HEADER.H_1.BORDER_TOP_RIGHT_RADIUS
            }
            borderTopLeftRadius={
              VARIABLES.MODAL.HEADER.H_1.BORDER_TOP_LEFT_RADIUS
            }
          />
          <Modal.Section
            paddingBottom={VARIABLES.MODAL.BODY.B_1.PADDING_BOTTOM}
            bgColor={VARIABLES.COLORS.WHITE}
          >
            {dataList.length > 0 && (
              <SwiperCarousel
                newsPromotions
                options={{
                  initialSlide: initSlideIndex,
                  spaceBetween: 55,
                  navigation: {
                    nextEl: ".swiper-button-next",
                    prevEl: ".swiper-button-prev",
                  },
                }}
              >
                {dataList.map((e, i) => {
                  return (
                    <SwiperCarousel.Item key={i}>
                      <Modal.Section
                        className="is-news-promotions-header"
                        paddingBottom={
                          VARIABLES.MODAL.HEADER.H_1.PADDING_BOTTOM
                        }
                        paddingHorizontal={
                          VARIABLES.MODAL.HEADER.H_1.PADDING_HORIZONTAL
                        }
                        bgColor={VARIABLES.COLORS.WHITE}
                        borderBottomWidth={
                          VARIABLES.MODAL.HEADER.H_1.BORDER_BOTTOM_WIDTH
                        }
                        borderBottomStyle={
                          VARIABLES.MODAL.HEADER.H_1.BORDER_BOTTOM_STYLE
                        }
                        borderBottomColor={VARIABLES.COLORS.GRAY_8}
                        borderTopRightRadius={
                          VARIABLES.MODAL.HEADER.H_1.BORDER_TOP_RIGHT_RADIUS
                        }
                        borderTopLeftRadius={
                          VARIABLES.MODAL.HEADER.H_1.BORDER_TOP_LEFT_RADIUS
                        }
                      >
                        <Grid gutter="55">
                          <Grid.Column flex="1">
                            <Section overflowHidden>
                              <Text
                                bold24
                                ellipsisTwoLine
                                color={VARIABLES.COLORS.BLACK}
                              >
                                {e.subject}
                              </Text>
                            </Section>
                          </Grid.Column>
                          <Grid.Column flex="none" className="print-section">
                            <Section spacingBottom="10">
                              <ReactToPrint
                                pageStyle
                                onBeforeGetContent={async () => {
                                  await this.modal.onClickPrint(i);
                                }}
                                trigger={() => (
                                  <ShapeContainer
                                    ui="buttonIconCircle"
                                    bgColor={VARIABLES.COLORS.PRIMARY_2}
                                    iconWidth="21"
                                    iconHeight="21"
                                    iconSrc={ICONS["ic-printer.svg"]}
                                    onClick={() => {}}
                                  />
                                )}
                                content={() => this.componentRef}
                              />
                            </Section>
                            <Section>
                              <ShapeContainer
                                ui="buttonIconCircle"
                                bgColor={VARIABLES.COLORS.PRIMARY_1}
                                iconWidth="20"
                                iconHeight="19"
                                iconSrc={ICONS["ic-share.svg"]}
                                onClick={() => {
                                  this.modal.onClickShare(e);
                                }}
                              />
                            </Section>
                          </Grid.Column>
                        </Grid>
                      </Modal.Section>
                      <Section spacingTop="35" spacingBottom="40">
                        <Image
                          heightRatio={
                            VARIABLES.BANNER.NEWS_AND_PROMOTIONS_HEIGHT_RATIO
                          }
                          src={e.cover.path}
                        />
                      </Section>
                      <Section
                        scroll
                        height="215"
                        className="print-text-section"
                      >
                        <div
                          className="text-editor-content"
                          dangerouslySetInnerHTML={{
                            __html: e.content,
                          }}
                        />
                      </Section>
                    </SwiperCarousel.Item>
                  );
                })}
              </SwiperCarousel>
            )}
          </Modal.Section>
          <Modal.Section
            className="print-section"
            paddingTop={VARIABLES.MODAL.FOOTER.F_1.PADDING_TOP}
            paddingBottom={VARIABLES.MODAL.FOOTER.F_1.PADDING_BOTTOM}
            paddingHorizontal={VARIABLES.MODAL.FOOTER.F_1.PADDING_HORIZONTAL}
            borderTopWidth={VARIABLES.MODAL.FOOTER.F_1.BORDER_TOP_WIDTH}
            borderTopStyle={VARIABLES.MODAL.FOOTER.F_1.BORDER_TOP_STYLE}
            borderTopColor={VARIABLES.COLORS.GRAY_8}
            borderBottomRightRadius={
              VARIABLES.MODAL.FOOTER.F_1.BORDER_BOTTOM_RIGHT_RADIUS
            }
            borderBottomLeftRadius={
              VARIABLES.MODAL.FOOTER.F_1.BORDER_BOTTOM_LEFT_RADIUS
            }
          >
            <Section justify="flex-end">
              <Button
                gray
                width={VARIABLES.BUTTON.WIDTHS.W_135}
                name={getLanguage("modal.newsAndPromotion.buttonBack", "")}
                onClick={onClickClose}
              />
            </Section>
          </Modal.Section>
        </Modal>
        <NewsAndPromotionsPrintContent
          ref={(el) => (this.componentRef = el)}
          data={dataPrint}
        />
        <ModalSocialShareContainer
          second
          isOpenModal={isOpenSocialShareModal}
          title="Social share"
          url={shareUrlPath}
          quote="Share news and promotions"
          onClickClose={this.modal.onClickCloseSocialShareModal}
        />
      </React.Fragment>
    );
  }
}
