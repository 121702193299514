import styled, {
  // css
} from 'styled-components'
import {
  default as VARIABLES
} from './../../../themes/styles/bases/variables'
import {
  default as TYPOGRAPHYS
} from './../../../themes/styles/bases/typographys'
// import {
//   default as MIXINS
// } from './../../../themes/styles/helpers/mixins'

// Sample using image in style
// url(${require('./../../../themes/images/icons/icon-sample.svg')});

// Wrapper
// ============================================================
export const YearWarrantyWrapper = styled.div`
  /* Parent
  ------------------------------- */
  position: relative;
  width: 100%;
  min-height: calc(100vh - 351px);

  /* Childrens
  ------------------------------- */
  .year-warranty-section {
    &.is-tooltip-name {
      padding: 10px 0 0;
    }

    &.is-heading-warranty-available {
      padding: 0 5px;
      margin: 0 0 5px;
    }

    &.is-heading-product-name {
      margin: 0 0 10px;
    }

    &.is-warranty-available {
      position: relative;

      @media (min-width: 768px) and (max-width: 1279px) {
        margin: 0 0 20px;
      }
    }

    &.is-environment-warranty-available {
      margin: 0 0 7px;
    }

    &.is-label-warranty-available {
      padding: 0 5px 5px;
    }
  }

  .year-warranty-register {
    transition: ${VARIABLES.TRANSITIONS.DEFAULT};
    pointer-events: auto;
    visibility: visible;
    opacity: 1;
    position: absolute;
    z-index: ${VARIABLES.Z_INDEXS.LV_4};
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${VARIABLES.COLORS.WHITE};
  }

  .year-warranty-register-container {
    max-width: 290px;
    padding: 15px 0;
  }

  .year-warranty-select-product {
    transition: ${VARIABLES.TRANSITIONS.DEFAULT};
    position: absolute;
    z-index: ${VARIABLES.Z_INDEXS.LV_3};
    top: 15px;
    left: 0;

    @media (max-width: 639px) {
      position: static;
      margin: 0 0 30px;
    }

    @media (max-width: 1279px) {
      right: 0;
    }
  }

  .year-warranty-product-information {
    pointer-events: none;
    visibility: hidden;
    opacity: 0;
    transition: ${VARIABLES.TRANSITIONS.DEFAULT};
    position: absolute;
    z-index: ${VARIABLES.Z_INDEXS.LV_2};
    top: 15px;
    left: 0;

    @media (max-width: 639px) {
      position: static;
      margin: 0 0 30px;
    }

    @media (max-width: 1279px) {
      right: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

  .year-warranty-building {
    position: relative;
    display: flex;
    justify-content: center;
    padding: 114px 0 0;
    margin: 0 0 85px;

    @media (max-width: 639px) {
      padding: 0;
      margin: -95px 0 -60px;
    }
  }

  .year-warranty-building-tooltip {
    visibility: hidden;
    transition: ${VARIABLES.TRANSITIONS.DEFAULT};
    opacity: 0;
    position: absolute;
    z-index: ${VARIABLES.Z_INDEXS.LV_1};
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 96px;
    height: 96px;
    background-color: rgba(255, 255, 255, 0.9);
    border-radius: 10px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.20);

    &.is-show-tooltip {
      visibility: visible;
      opacity: 1;
    }

    &.is-roof {
      top: -7px;
      left: 616px;

      &.is-show-tooltip-roof {
        left: 626px;
      }
    }

    &.is-wall {
      top: 142px;
      left: 0;

      &.is-show-tooltip-wall {
        left: -25px;
      }
    }

    &.is-purlin-girt {
      top: -7px;
      left: 373px;

      &.is-show-tooltip-purlin-girt {
        left: 383px;
      }
    }

    &.is-truss-frame {
      top: 126px;
      left: 341px;

      &.is-show-tooltip-truss-frame {
        left: 351px;
      }
    }

    &.is-coolroom-panel {
      top: 59px;
      right: 34px;

      &.is-show-tooltip-coolroom-panel {
        right: 44px;
      }
    }

    &.is-decking {
      top: 243px;
      left: 509px;

      &.is-show-tooltip-decking {
        left: 519px;
      }
    }

    &.is-air-ducting {
      top: 40px;
      left: 117px;

      &.is-show-tooltip-air-ducting {
        left: 127px;
      }
    }

    &.is-shutter-ex {
      top: 188px;
      left: 168px;

      &.is-show-tooltip-shutter-ex {
        left: 178px;
      }
    }

    &.is-arrow {
      &:after {
        content: "";
        pointer-events: none;
        position: absolute;
        width: 0;
        height: 0;
        border: solid transparent;
        border-color: transparent;
      }
    }

    &.is-arrow-left {
      &:after {
        top: 50%;
        right: 100%;
        margin-top: -3px;
        border-width: 5px 7px 5px 7px;
        border-right-color: rgba(255, 255, 255, 0.9);
      }
    }

    &.is-arrow-right {
      &:after {
        top: 50%;
        left: 100%;
        margin-top: -3px;
        border-width: 5px 7px 5px 7px;
        border-left-color: rgba(255, 255, 255, 0.9);
      }
    }

    &.is-arrow-bottom {
      &:after {
        top: 100%;
        left: 50%;
        margin-left: -4px;
        border-width: 7px 5px 7px 5px;
        border-top-color: rgba(255, 255, 255, 0.9);
      }
    }
  }

  .year-warranty-available {
    visibility: hidden;
    opacity: 0;
    transition: ${VARIABLES.TRANSITIONS.DEFAULT};
    position: absolute;
    z-index: ${VARIABLES.Z_INDEXS.LV_1};
    bottom: 100%;
    right: 0;
    width: 380px;
    padding: 15px;
    background-color: rgba(255, 255, 255, 0.9);
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
    min-width: 332px;

    @media (max-width: 1279px) {
      position: static;
      width: auto;
    }
  }

  .year-warranty-image {
    &.is-model {
      transition: ${VARIABLES.TRANSITIONS.DEFAULT};
      position: relative;

      @media (max-width: 639px) {
        transform: scale(0.3);
      }

      @media (min-width: 640px) and (max-width: 959px) {
        transform: scale(0.5);
      }

      @media (min-width: 960px) and (max-width: 1120px) {
        transform: scale(0.6);
      }

      @media (min-width: 1121px) and (max-width: 1365px) {
        transform: scale(0.7);
      }
    }

    &.is-home-model,
    &.is-home-model-disabled {
      @media (max-width: 1365px) {
        max-width: none;
      }
    }

    &.is-home-model {
      transition: ${VARIABLES.TRANSITIONS.DEFAULT};
      transform: translateX(-50%);
      opacity: 0;
      position: absolute;
      z-index: ${VARIABLES.Z_INDEXS.LV_1};
      top: 0;
      left: 50%;
    }

    &.is-home-model-disabled {}
  }

  .year-warranty-text {
    &.is-tooltip {
      ${TYPOGRAPHYS.FIRST.BOLD.FS_12};
      color: ${VARIABLES.COLORS.PRIMARY_2};
    }

    &.is-heading-warranty-available {
      ${TYPOGRAPHYS.FIRST.BOLD.FS_14};
      color: ${VARIABLES.COLORS.BLACK};
    }

    &.is-label-warranty-available {
      ${TYPOGRAPHYS.FIRST.REGULAR.FS_12};
      color: ${VARIABLES.COLORS.BLACK};
    }

    &.is-label-environment {
      ${TYPOGRAPHYS.FIRST.BOLD.FS_18};
      color: ${VARIABLES.COLORS.BLACK};

      @media (max-width: 639px) {
        ${TYPOGRAPHYS.FIRST.BOLD.FS_16};
      }
    }

    &.is-label-environment-selected {
      ${TYPOGRAPHYS.FIRST.REGULAR.FS_12};
      position: relative;
      top: -1px;
      color: ${VARIABLES.COLORS.WHITE};
    }

    &.is-label-slider-range {
      transform: translateX(-50%);
      position: absolute;
      z-index: ${VARIABLES.Z_INDEXS.LV_1};
      left: 50%;
      color: ${VARIABLES.COLORS.WHITE};
      white-space: nowrap;

      &.is-label-slider-range-min,
      &.is-label-slider-range-max {
        ${TYPOGRAPHYS.FIRST.REGULAR.FS_14};
      }

      &.is-label-slider-range-distance {
        ${TYPOGRAPHYS.FIRST.BOLD.FS_14};
      }
    }

    &.is-product,
    &.is-number-warranty-available {
      ${TYPOGRAPHYS.FIRST.BOLD.FS_12};
      color: ${VARIABLES.COLORS.PRIMARY_2};
    }

    &.is-application {
      ${TYPOGRAPHYS.FIRST.BOLD.FS_12};
      color: ${VARIABLES.COLORS.BLACK};
    }

    &.is-name-product {
      ${TYPOGRAPHYS.FIRST.BOLD.FS_36};
      color: ${VARIABLES.COLORS.BLACK};

      @media (max-width: 639px) {
        ${TYPOGRAPHYS.FIRST.BOLD.FS_28};
      }
    }

    &.is-name-category,
    &.is-distance {
      ${TYPOGRAPHYS.FIRST.BOLD.FS_18};
      color: ${VARIABLES.COLORS.PRIMARY_2};

      @media (max-width: 639px) {
        ${TYPOGRAPHYS.FIRST.BOLD.FS_16};
      }
    }

    &.is-hint-short {
      ${TYPOGRAPHYS.FIRST.BOLD.FS_10};
      color: ${VARIABLES.COLORS.BLACK};
    }

    &.is-hint-long {
      ${TYPOGRAPHYS.FIRST.REGULAR.FS_10};
      color: ${VARIABLES.COLORS.BLACK};
    }

    &.is-message-error {
      ${TYPOGRAPHYS.FIRST.REGULAR.FS_12};
      color: ${VARIABLES.COLORS.RED_2};
    }

    &.is-link {
      ${TYPOGRAPHYS.FIRST.BOLD.FS_12};
      color: ${VARIABLES.COLORS.PRIMARY_2};
      text-decoration: underline;
    }
  }

  .year-warranty-slider-label-position {
    position: absolute;
    z-index: ${VARIABLES.Z_INDEXS.LV_1};
    bottom: calc(100% + 30px);

    &.is-min {
      left: 0;
    }

    &.is-distance {
      left: 50%;
    }

    &.is-max {
      right: 0;
    }
  }

  .year-warranty-lists {
    &.is-product {
      overflow: overlay;
      max-height: 112px;
      padding: 0 10px 0 0;
      margin: 0 -10px 0 0;

      @media (max-width: 767px) {
        max-height: none;
      }

      @media (min-width: 768px) and (max-width: 1279px) {
        max-height: 200px;
      }

      &::-webkit-scrollbar {
        width: 5px;
      }

      &::-webkit-scrollbar-thumb {
        background: ${VARIABLES.COLORS.GRAY_29};
        border-radius: 3px;
      }

      &::-webkit-scrollbar-thumb:hover {
        background: ${VARIABLES.COLORS.GRAY_25};
      }

      &::-webkit-scrollbar-track {
        background: transparent;
        border-radius: 3px;
      }

      > .year-warranty-list {
        padding: 3px 5px 5px;
        margin: 0 0 5px;
        border: 1px solid ${VARIABLES.COLORS.BLUE_16};
        border-radius: 5px;

        &:last-child {
          margin: 0;
        }
      }
    }

    &.is-application-group {
      > .year-warranty-list {
        margin: 0 0 5px;

        &:last-child {
          margin: 0;
        }
      }
    }

    &.is-application {
      padding: 3px 5px;
      background-color: ${VARIABLES.COLORS.BLUE_31};
      border: 1px solid ${VARIABLES.COLORS.BLUE_15};
      border-radius: 5px;

      > .year-warranty-list {}
    }

    &.is-register {
      > .year-warranty-list {
        margin: 0 0 15px;

        &:first-child {
          text-align: center;
        }

        &:last-child {
          padding: 15px 0 0;
          margin: 0;
          text-align: center;
        }
      }
    }
  }

  .year-warranty-environment-control {
    position: relative;
    display: flex;
    align-items: center;
    height: 94px;
    padding: 0 40px;
    background: rgb(61,140,196);
    background: -moz-linear-gradient(90deg, rgba(61,140,196,1) 0%, rgba(26,92,137,1) 100%);
    background: -webkit-linear-gradient(90deg, rgba(61,140,196,1) 0%, rgba(26,92,137,1) 100%);
    background: linear-gradient(90deg, rgba(61,140,196,1) 0%, rgba(26,92,137,1) 100%);
    border-radius: 10px;

    @media (max-width: 767px) {
      pointer-events: none;
      visibility: hidden;
      opacity: 0;
      position: absolute;
      z-index: ${VARIABLES.Z_INDEXS.LV_1};
      height: 0;
      padding: 0;
    }
  }

  .year-warranty-environment-control-container {
    position: relative;
    width: 100%;

    /* &:before,
    &:after {
      content: " ";
      position: absolute;
      z-index: ${VARIABLES.Z_INDEXS.LV_1};
      top: -42px;
      display: block;
      height: 94px;
      border-left: 1px solid rgba(255, 255, 255, 0.25);
    }

    &:before {
      left: 6.5%;
    }

    &:after {
      left: 56.5%;
    } */
  }

  .year-warranty-environment-hint {}

  .year-warranty-partition {
    &.is-environment {
      margin: 0 0 5px;
      border-bottom: 1px solid ${VARIABLES.COLORS.BLUE_16};
    }
  }

  /* Other Components
  ------------------------------- */
  .rc-slider {
    z-index: ${VARIABLES.Z_INDEXS.LV_2};
    height: 10px;
    padding: 0;
    border-radius: 0;

    .rc-slider-mark {
      top: 23px;

      .rc-slider-mark-text {
        ${TYPOGRAPHYS.FIRST.REGULAR.FS_14};
        color: ${VARIABLES.COLORS.WHITE};
      }
    }

    .rc-slider-rail {
      height: 10px;
      background-color: ${VARIABLES.COLORS.WHITE};
      border-radius: 5px;
    }

    .rc-slider-track {
      z-index: ${VARIABLES.Z_INDEXS.LV_1};
      height: 10px;
      background: rgb(60,196,128);
      background: -moz-linear-gradient(0deg, rgba(60,196,128,1) 0%, rgba(87,235,161,1) 100%);
      background: -webkit-linear-gradient(0deg, rgba(60,196,128,1) 0%, rgba(87,235,161,1) 100%);
      background: linear-gradient(0deg, rgba(60,196,128,1) 0%, rgba(87,235,161,1) 100%);
    }

    .rc-slider-step {
      height: 10px;

      .rc-slider-dot {
        /* opacity: 0; */
        transform: translate(-50%, -50%) !important; // Overwrite inline style from library
        bottom: auto;
        top: 50%;
        width: 6px;
        height: 6px;
        background-color: ${VARIABLES.COLORS.BLUE_16};
        border: none;

        &:first-child {
          opacity: 0;
        }

        &:last-child {
          margin-left: -5px;
        }
      }
    }

    .rc-slider-handle {
      transition: box-shadow 0.3s ease;
      opacity: 1;
      z-index: ${VARIABLES.Z_INDEXS.LV_2};
      width: 24px;
      height: 24px;
      margin-top: -7px;
      background: rgb(199,199,199);
      background: -moz-linear-gradient(0deg, rgba(199,199,199,1) 0%, rgba(238,238,238,1) 40%, rgba(255,255,255,1) 100%);
      background: -webkit-linear-gradient(0deg, rgba(199,199,199,1) 0%, rgba(238,238,238,1) 40%, rgba(255,255,255,1) 100%);
      background: linear-gradient(0deg, rgba(199,199,199,1) 0%, rgba(238,238,238,1) 40%, rgba(255,255,255,1) 100%);
      border: 2px solid ${VARIABLES.COLORS.WHITE};

      &.rc-slider-handle-dragging {
        &.rc-slider-handle-dragging {
          border-color: ${VARIABLES.COLORS.WHITE};
          box-shadow: 0 0 0 5px rgba(255, 255, 255, 0.5);
        }
      }
    }
  }

  .shape-container-base {
    &.is-badge-environment {
      .shape-container-content-base {
        background: rgb(26,92,137);
        background: -moz-linear-gradient(0deg, rgba(26,92,137,1) 0%, rgba(61,140,196,1) 100%);
        background: -webkit-linear-gradient(0deg, rgba(26,92,137,1) 0%, rgba(61,140,196,1) 100%);
        background: linear-gradient(0deg, rgba(26,92,137,1) 0%, rgba(61,140,196,1) 100%);
      }
    }
  }

  .grid-base {
    &.is-select-product,
    &.is-disctance-info {
      .grid-container-base {
        @media (max-width: 1279px) {
          justify-content: center;
        }
      }
    }

    &.is-select-product {
      .grid-container-base {
        .grid-column-base {
          @media (max-width: 639px) {
            width: 100%;
          }

          @media (min-width: 640px) and (max-width: 767px) {
            width: 33.33%;
          }
        }
      }
    }

    .grid-column-base {
      &.is-select-distance {
        pointer-events: none;
        visibility: hidden;
        opacity: 0;
        position: absolute;
        z-index: ${VARIABLES.Z_INDEXS.LV_1};

        @media (max-width: 767px) {
          pointer-events: auto;
          visibility: visible;
          opacity: 1;
          position: static;
          display: block;
        }
      }
    }
  }

  .select-control {
    &.is-filter-year-warranty {
      .select-control-select {
        @media (max-width: 767px) {
          width: 100%;
        }
      }
    }
  }

  /* Modifiers
  ------------------------------- */
  &.is-product-selected {
    .year-warranty-select-product {
      top: 444px;
    }

    .year-warranty-product-information {
      visibility: visible;
      opacity: 1;
    }

    .year-warranty-image {
      &.is-home-model {
        opacity: 1;
      }
    }

    .year-warranty-available {
      visibility: visible;
      opacity: 1;
      bottom: calc(100% + 20px);
    }
  }

  &.is-register-success {
    .year-warranty-register {
      pointer-events: none;
      visibility: hidden;
      opacity: 0;
    }
  }

  .year-warranty-env-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 4px;
    width: 100%;

    .year-warranty-production-date-hint {
      font-size: 12px;
      color: ${VARIABLES.COLORS.RED_2};
      /* color: ${VARIABLES.COLORS.PRIMARY_2}; */
    }
  } 
`
