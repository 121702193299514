import _ from 'lodash';

export class ModelWarrantyYear {
  constructor(props = {}) {
    this.m3ProductGroupCode = props.m3_product_group_code;
    // this.m3ProductGroupName = props.m3_product_group_name;
    this.productGroupName = props.product_group_name;
  }
}

export class ModelWarrantyYearDetail {
  constructor(props = {}) {
    this.category = props.category;
    this.m3ProductGroup = props.m3_product_group;
    this.country = props.country;
    this.productGroupName = props.product_group_name;
    this.application = props.application;
    this.template = props.template;
    this.envList = (props.env || []).map(e => {
      return {
        environment: e.environment,
        corrosion: e.corrosion || e.corrosion_1,
        corrosion2: e.corrosion_2,
        peelFlake: e.peel_flake,
        colorFade: e.color_fade,
        dirtStain: e.dirt_stain
      }
    })
  };
}