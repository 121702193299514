import html2pdf from 'html2pdf.js';
import React from "react";
import { DATA } from '../../../helpers/constants/data';
import { isShowLoading } from "../../../helpers/functions/main";
import { handleDocumentaryData } from '../../../helpers/functions/warranty';
import { DocumentaryTemplate } from "../../GenerateDocumentaryContainer/DocumentaryTemplate";
import {
  Button, Grid,
  Modal, Section,
  ShapeContainer,
  TitleSection, WarrantyGeneratePdfPage
} from "./../../../components";
import { getLanguage } from "./../../../helpers/functions/language";
import { ICONS, VARIABLES } from "./../../../themes";

export class ModalPreviewDocumentTemplateContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isShowPreview: true,
    };
  }

  modalPreviewDocument = {
    onClickDownload: () => {
      isShowLoading(true);
      this.setState({
        isShowPreview: false,
      }, async () => {
        const dom = this.documentaryTemplateRef;
        const pdfFile = await html2pdf().set({
          margin: [0, 0],
          html2canvas: { scale: 2.2, },
        }).from(dom).outputPdf('blob');

        const link = document.createElement('a');
        link.download = `${new Date().getTime()}.pdf`;
        link.href = URL.createObjectURL(pdfFile);
        link.click();
        this.setState({
          isShowPreview: true
        }, () => {
          isShowLoading(false);
        });
      });
    }
  }

  render() {
    const {
      isOpenModal,
      isSecondModal,
      onClickClose,
      onClickCancel,
      title,
      isShowDownload,
      setComponentTemplateRef = () => { },
      pdfSrc,
      isShowPreviewMark,
      isShowEdit,
      buttonWidth,
      onClickEdit,
      isButtontonDownloadColorLightGreen,
      data,
      dataDetail,
      customerAddress,
      isHideWaterMark,
      isWaterMarkPreCertificate,
    } = this.props;
    const {
      isShowPreview,
    } = this.state;
    return (
      <Modal
        open={isOpenModal}
        scroll
        second={isSecondModal}
        // enableOverlayClose={false}
        width={VARIABLES.MODAL.WIDTHS.W_1165}
        overlayColor={VARIABLES.COLORS.OVERLAY_BLACK_1}
        spacing={VARIABLES.MODAL.CONTAINER.C_1.SPACING}
        transition={VARIABLES.TRANSITIONS.DEFAULT}
        transformStart={VARIABLES.MODAL.CONTAINER.C_1.TRANSFORM_START}
        transformEnd={VARIABLES.MODAL.CONTAINER.C_1.TRANSFORM_END}
        onClickClose={onClickClose}
      >
        <Modal.Section
          paddingVertical="25"
          paddingHorizontal="35"
          bgColor={VARIABLES.COLORS.WHITE}
          borderTopRightRadius={VARIABLES.MODAL.HEADER.H_1.BORDER_TOP_RIGHT_RADIUS}
          borderTopLeftRadius={VARIABLES.MODAL.HEADER.H_1.BORDER_TOP_LEFT_RADIUS}
        >
          <TitleSection name={title} />
        </Modal.Section>
        <Modal.Section
          paddingVertical="40"
          paddingHorizontal="35"
          bgColor={VARIABLES.COLORS.WHITE}
        >
          <Section className="is-browser-viewer" height="1132">
            <ShapeContainer
              bgColor={VARIABLES.COLORS.WHITE}
              justify='center'
              align='center'
              fluid
              boxShadow='0px 0px 10px rgba(0, 0, 0, 0.15);'
              borderRadius='5'
              paddingHorizontal='30'
              paddingVertical='30'
              height='1132'
            >
              {pdfSrc ?
                <iframe
                  title="Browser Viewer"
                  src={pdfSrc + `#toolbar=0`}
                  frameBorder="0"
                />
                :
                <div ref={r => this.documentaryTemplateRef = r}>
                  <WarrantyGeneratePdfPage
                    isHidden={false}
                    isPreview={isShowPreview}
                  >
                    <DocumentaryTemplate
                      isHideWaterMark={isHideWaterMark}
                      isShowPreviewMark={isShowPreviewMark}
                      isWaterMarkPreCertificate={isWaterMarkPreCertificate}
                      ref={r => setComponentTemplateRef(r)}
                      data={data}
                      dataDetail={handleDocumentaryData(
                        dataDetail,
                        [dataDetail],
                        isWaterMarkPreCertificate ?
                          DATA.WATERMARK_TEMPLATE_LIST[2].value :
                          DATA.WATERMARK_TEMPLATE_LIST[1].value
                      )}
                      customerAddress={customerAddress}
                      isModalPreviewDocument
                      watermarkTemplateType={isWaterMarkPreCertificate ? DATA.WATERMARK_TEMPLATE_LIST[2].value : DATA.WATERMARK_TEMPLATE_LIST[1].value}
                      productGroupName={dataDetail.m3ProductGroupName}
                    />
                  </WarrantyGeneratePdfPage>
                </div>
              }
            </ShapeContainer>
          </Section>
        </Modal.Section>
        <Modal.Section
          paddingTop="15"
          paddingBottom="20"
          paddingHorizontal="35"
          borderTopWidth={VARIABLES.MODAL.FOOTER.F_1.BORDER_TOP_WIDTH}
          borderTopStyle={VARIABLES.MODAL.FOOTER.F_1.BORDER_TOP_STYLE}
          borderTopColor={VARIABLES.COLORS.GRAY_8}
          borderBottomRightRadius={
            VARIABLES.MODAL.FOOTER.F_1.BORDER_BOTTOM_RIGHT_RADIUS
          }
          borderBottomLeftRadius={
            VARIABLES.MODAL.FOOTER.F_1.BORDER_BOTTOM_LEFT_RADIUS
          }
        >
          <Grid gutter="15" justify="flex-end">
            <Grid.Column>
              <Button
                gray
                width={buttonWidth ? buttonWidth : VARIABLES.BUTTON.WIDTHS.W_155}
                name={getLanguage("field.buttonClose")}
                onClick={onClickCancel}
              />
            </Grid.Column>
            {isShowDownload && (
              <Grid.Column>
                <Button
                  green={!isButtontonDownloadColorLightGreen}
                  lightGreen={isButtontonDownloadColorLightGreen}
                  ui="frontIcon"
                  name={getLanguage(
                    "field.buttonDownload",
                    ""
                  )}
                  width={buttonWidth ? buttonWidth : VARIABLES.BUTTON.WIDTHS.W_155}
                  iconWidth="18"
                  iconHeight="18"
                  iconSrc={ICONS["ic-download-circle.svg"]}
                  onClick={this.modalPreviewDocument.onClickDownload}
                />
              </Grid.Column>
            )}
            {isShowEdit && (
              <Grid.Column>
                <Button
                  lightBlue
                  ui="edit"
                  name={getLanguage(
                    "field.buttonEdit",
                    ""
                  )}
                  width={buttonWidth ? buttonWidth : VARIABLES.BUTTON.WIDTHS.W_155}
                  onClick={onClickEdit}
                />
              </Grid.Column>
            )}
          </Grid>
        </Modal.Section>
      </Modal>
    );
  }
}
