import React from 'react'
import ClassNames from 'classnames'
import PropTypes from 'prop-types'
import {
  TitlePageWrapper
} from './styled'
import {
  Grid,
  Image,
  Section,
  Text
} from './../../../components'
import {
  default as VARIABLES
} from './../../../themes/styles/bases/variables'
// import {
//   default as TYPOGRAPHYS
// } from './../../../themes/styles/bases/typographys'
// import {
//   default as UTILITIES
// } from './../../../themes/styles/helpers/utilities'

/**
 * TitlePage description:
 * - TitlePage
 */

export class TitlePage extends React.PureComponent {
  render () {
    const {
      className,
      src,
      name
    } = this.props

    // props for css classes
    const classes = ClassNames(
      'title-page',
      // {'is-modifier-name': modifierName},
      className
    )

    return (
      <TitlePageWrapper
        className={classes}
      >
        <Grid gutter='15'>
          <Grid.Column>
            <Image
              widthRatio='36'
              widthRatioUnit='px'
              heightRatio='36'
              heightRatioUnit='px'
              src={src}
            />
          </Grid.Column>
          <Grid.Column>
            <Section
              height='36'
              align='center'
            >
              <Text
                regular18
                singleLine
                color={VARIABLES.COLORS.BLACK}
                top='1'
              >
                {name}
              </Text>
            </Section>
          </Grid.Column>
        </Grid>
      </TitlePageWrapper>
    )
  }

  static defaultProps = {
    // * If you set new default props, you should create propsType for correct styleguide document.
  }

  static propTypes = { // TYPE > node, string, number, bool, array, object, symbol, func
    /**
    * Additional classes
    */
    className: PropTypes.string
  }
}
