import {
  injectGlobal
} from 'styled-components'
// import {
//   default as VARIABLES
// } from './../../../themes/styles/bases/variables'
// import {
//   default as TYPOGRAPHYS
// } from './../../../themes/styles/bases/typographys'
// import {
//   default as MIXINS
// } from './../../../themes/styles/helpers/mixins'

// Sample using image in style
// url(${require('./../../../themes/images/icons/icon-sample.svg')});

injectGlobal`
  .notification-base {
    /* Parent
    ------------------------------- */
    padding-right: 13px;
    padding-left: 13px;

    /* Childrens
    ------------------------------- */
    /* Modifiers
    ------------------------------- */
    /* Media queries
    ------------------------------- */
  }
`
