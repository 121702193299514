import apiService from "../apiService";

export const projectDocumentController = (configService = {}) => {
  const service = apiService(configService);
  return {
    projectDocumentM3Product: () => {
      return service.get(`/pre-sale-transaction/m3-product`);
    },
    projectDocumentDocumentary: () => {
      return service.get(`/pre-sale-transaction/documentary`);
    },
    projectDocumentCertificate: (params) => {
      return service.post(`/pre-sale-transaction/certificate`, params);
    },
    updateProjectDocumentCertificateDetail: (params) => {
      return service.put(`/pre-sale-transaction/certificate-detail`, params);
    },
    getProjectDocumentCertificate: () => {
      return service.get(`/pre-sale-transaction/certificate`);
    },
    projectDocumentProject: () => {
      return service.get(`/pre-sale-transaction/project`);
    },
    projectDocumentProjectInformation: () => {
      return service.get(`/pre-sale-transaction`);
    },
    createProjectDocument: (params = {}) => {
      return service.post(`/pre-sale-transaction`, params);
    },
    updateProjectDocument: (params = {}) => {
      return service.put(`/pre-sale-transaction`, params);
    },
    projectDocumentSearch: (params = {}) => {
      return service.post(`/pre-sale-transaction/search`, params);
    },
    createProjectDocumentDocumentary: (params = {}) => {
      return service.post(`/pre-sale-transaction/documentary`, params);
    },
    updateProjectDocumentDocumentary: (params = {}) => {
      return service.put(`/pre-sale-transaction/documentary-detail`, params);
    },
    projectDocumentDocumentarySearch: (params = {}) => {
      return service.post(`/pre-sale-transaction/documentary/search`, params);
    },
    projectDocumentLog: (params = {}) => {
      return service.get(`/pre-sale-transaction/log`, params);
    },
    createProjectDocumentLog: (params = {}) => {
      return service.post(`/pre-sale-transaction/log`, params);
    },
    getProjectDocumentDocumentaryDetail: () => {
      return service.get(`/pre-sale-transaction/documentary-detail`);
    },
    projectDocumentSearchLog: (params = {}) => {
      return service.post(`/pre-sale-transaction/search/log`, params);
    },
    // projectDocumentSearchWarranty: (params = {}) => {
    //   return service.post(`/pre-sale-transaction/search/warranty`, params);
    // },
    projectDocumentGetAll: (params = {}) => {
      return service.get(`/pre-sale-transaction/all`, params);
    },
    projectDocumentLinkSaleTrans: (params = {}) => {
      return service.post(`/pre-sale-transaction/link/sale-trans`, params);
    },
    projectDocumentCertificateSearch: (params = {}) => {
      return service.post(`/pre-sale-transaction/certificate/search`, params);
    },
    projectDocumentRequestEditProjectInformation: (params = {}) => {
      return service.post(`/pre-sale-transaction/request-edit`, params);
    },
  };
};
