import _ from 'lodash';
import React from 'react';
import {
  Section,
  Text,
  WarrantyBSWMIndonesiaP13xlColorbondRXal,
  WarrantyBSWMIndonesiaP13xpColorbondRXpd,
  WarrantyBSWMIndonesiaP17ulColorbondRUltra,
  WarrantyBSWMIndonesiaP17xrColorbondRXrw,
  WarrantyBSWMIndonesiaP1ccpColorbondRCoolroom,
  WarrantyBSWMIndonesiaP1ccpCrpAntibacterial,
  WarrantyBSWMIndonesiaP2bpcBluescopeZacsCool,
  WarrantyBSWMIndonesiaP2bpnBluescopeZacsNatural,
  WarrantyBSWMIndonesiaP2cctCrpFoodgrade,
  WarrantyBSWMIndonesiaP2hrpHrpAntibacterial,
  WarrantyBSWMIndonesiaP2kirKirana,
  WarrantyBSWMIndonesiaZ1trcTruecore,
  WarrantyBSWMIndonesiaZ1zalZincalume,
  WarrantyBSWMIndonesiaZ1zauZincalumeUltra,
  WarrantyBSWMIndonesiaZ2abdAbadi,
  WarrantyBSWMIndonesiaZ2bzcBluescopeZacsBare,
  WarrantyBSWMIndonesiaZ2bzuBluescopeZacsTruss,
  WarrantyBSWMMalaysiaCaperoMyP1cwr,
  WarrantyBSWMMalaysiaCcbMattMyP15rm,
  WarrantyBSWMMalaysiaCcbSpectrumMyP15pp,
  WarrantyBSWMMalaysiaCcbSpectrumMyP1cpp,
  WarrantyBSWMMalaysiaCcbUltraMattMyP15um,
  WarrantyBSWMMalaysiaCcbUltraMyP15ul,
  WarrantyBSWMMalaysiaCcbXpdMyP15xp,
  WarrantyBSWMMalaysiaCcbXpdPscMyP15xs,
  WarrantyBSWMMalaysiaCcbXrwMyP15xr,
  WarrantyBSWMMalaysiaPmMyP2prm,
  WarrantyBSWMMalaysiaPmRMyP2prj,
  WarrantyBSWMMalaysiaTruecoreMyZ1trc,
  WarrantyBSWMMalaysiaVermoeMyP1vrm,
  WarrantyBSWMMalaysiaZlMyZ1zal,
  WarrantyBSWMMalaysiaZlMyZ1zau,
  WarrantyBSWMThailandBluescopeZacsCoolTh,
  WarrantyBSWMThailandBluescopeZacsDazzleTh,
  WarrantyBSWMThailandBluescopeZacsNaturalTh,
  WarrantyBSWMThailandBluescopeZacsTh,
  WarrantyBSWMThailandColorbondP15xmTh,
  WarrantyBSWMThailandColorbondP16xmTh,
  WarrantyBSWMThailandColorbondP17xmTh,
  WarrantyBSWMThailandColorbondTh,
  WarrantyBSWMThailandColorbondXmaTh,
  WarrantyBSWMThailandCrpAntibacterialSdTh,
  WarrantyBSWMThailandEndurocoreTh,
  WarrantyBSWMThailandJingjoeLekTh,
  WarrantyBSWMThailandPpPanelSdTh,
  WarrantyBSWMThailandPrimaP1pmaTh,
  WarrantyBSWMThailandPrimaTh,
  WarrantyBSWMThailandSuperdymaPlTh,
  WarrantyBSWMThailandSuperdymaSDuckK1dsdTh,
  WarrantyBSWMThailandSuperdymaSDuckK2dsdTh,
  WarrantyBSWMThailandSuperdymaSDuckTh,
  WarrantyBSWMThailandSuperdymaTfTh,
  WarrantyBSWMThailandSuperdymaTh,
  WarrantyBSWMThailandTruecoreTh,
  WarrantyBSWMThailandZincalumeTh,
  WarrantyBSWMVietnamCcbCustomXpdPearlescentVnP17xr,
  WarrantyBSWMVietnamCcbCustomXpdVnP17xr,
  WarrantyBSWMVietnamCcbForPanelVnP17xr,
  WarrantyBSWMVietnamCcbForPanelVnP1cpp,
  WarrantyBSWMVietnamCcbUltraActivateVnP17xr,
  WarrantyBSWMVietnamCcbUltraActivateVnV13ul,
  WarrantyBSWMVietnamCcbUltraVnP14ul,
  WarrantyBSWMVietnamCcbXpdPscVnP13xs,
  WarrantyBSWMVietnamCcbXpdVnX13xp,
  WarrantyBSWMVietnamCcbXrwVnP13xr,
  WarrantyBSWMVietnamHinataBareVnZ1hb,
  WarrantyBSWMVietnamHinataPaintedVnP1h1z,
  WarrantyBSWMVietnamSumoAntifadingVnP17xr,
  WarrantyBSWMVietnamSumoAntifadingVnP2sua,
  WarrantyBSWMVietnamSumoForPanelVnP3sup,
  WarrantyBSWMVietnamZlUltraVnB1max,
  WarrantyBSWMVietnamZlUltraVnZ1zal,
  WarrantyBSWMVietnamZlVnZ1zal,
} from '../../components';
import { ModelWarrantyDetail } from '../../models/ModelWarranty';
import { VARIABLES } from '../../themes';
import { DATA } from '../constants/data';
import { ENUM } from '../constants/enum';
import { LOCAL_STORAGE } from '../constants/localStorage';
import { ROUTE_PATH } from '../constants/route';
import { firebaseGetDownloadPath } from '../firebases/firebaseStorage';
import { getWarrantyTemplateDetail } from '../services/warrantyTemplate';
import { getUserAuthRedux } from './auth';
import { imageUrltoBase64 } from './data';
import { getLanguage } from './language';
import { getLocalStorage } from './localStorage';
import { isShowLoading, toast } from './main';

let _warrantyTemplateRef;

export const setWarrantyTemplateRef = (ref) => {
  if (ref) {
    _warrantyTemplateRef = ref;
  }
}

export const setWarrantyData = (data) => {
  return _warrantyTemplateRef.warranty.setWarrantyData(data);
}

export const generateAllWarranty = () => {
  return _warrantyTemplateRef.warranty.generateAllWarranty();
}

export const deleteAllWarranty = (exceptDeletePathList = []) => {
  return _warrantyTemplateRef.warranty.handleDeletePdf(exceptDeletePathList);
}

export const getWarrantyTemplateForm = (type = '') => {
  let form = DATA.WARRANTY_TEMPLATE_LIST[0];
  let logo = 'logoBlueScopeZacs';
  if (type) {
    if (type.toLowerCase().indexOf(DATA.WARRANTY_DETAIL_GROUP_TYPE.BLUE_SCOPE_ZACS.TYPE.toLowerCase()) >= 0) {
      switch (type.toLowerCase()) {
        // case DATA.WARRANTY_DETAIL_GROUP_TYPE.BLUESCOPE_ZACS_NATURAL.TYPE.toLowerCase():
        //   form = DATA.WARRANTY_TEMPLATE_LIST[11];
        //   break;
        // case DATA.WARRANTY_DETAIL_GROUP_TYPE.BLUESCOPE_ZACS_DAZZLE.TYPE.toLowerCase():
        //   form = DATA.WARRANTY_TEMPLATE_LIST[12];
        //   break;
        default:
          form = DATA.WARRANTY_TEMPLATE_LIST[0];
          break;
      }
    } else if (type.toLowerCase().indexOf(DATA.WARRANTY_DETAIL_GROUP_TYPE.COLOR_BOND.TYPE.toLowerCase()) >= 0) {
      form = DATA.WARRANTY_TEMPLATE_LIST[1];
      //bswm v
      if (type === DATA.WARRANTY_TEMPLATE_LIST[9]) {
        form = DATA.WARRANTY_TEMPLATE_LIST[9];
      }
      if (type === DATA.WARRANTY_TEMPLATE_LIST[10]) {
        form = DATA.WARRANTY_TEMPLATE_LIST[10];
      }
      //bswm ^
    } else if (type.toLowerCase().indexOf(DATA.WARRANTY_DETAIL_GROUP_TYPE.CRP.TYPE.toLowerCase()) >= 0) {
      form = DATA.WARRANTY_TEMPLATE_LIST[2];
    } else if (type.toLowerCase().indexOf(DATA.WARRANTY_DETAIL_GROUP_TYPE.JINGJOE_LEK.TYPE.toLowerCase()) >= 0) {
      form = DATA.WARRANTY_TEMPLATE_LIST[3];
    } else if (type.toLowerCase().indexOf(DATA.WARRANTY_DETAIL_GROUP_TYPE.PRIMA.TYPE.toLowerCase()) >= 0) {
      form = DATA.WARRANTY_TEMPLATE_LIST[4];
    } else if (type.toLowerCase().indexOf(DATA.WARRANTY_DETAIL_GROUP_TYPE.SUPER_DYMA_DK.TYPE.toLowerCase()) >= 0) {
      form = DATA.WARRANTY_TEMPLATE_LIST[5];
    } else if (type.toLowerCase().indexOf(DATA.WARRANTY_DETAIL_GROUP_TYPE.TRUE_CORE.TYPE.toLowerCase()) >= 0) {
      form = DATA.WARRANTY_TEMPLATE_LIST[7];
    } else if (type.toLowerCase().indexOf(DATA.WARRANTY_DETAIL_GROUP_TYPE.ZINCALUME.TYPE.toLowerCase()) >= 0) {
      form = DATA.WARRANTY_TEMPLATE_LIST[8];
    }
  }
  switch (form) {
    case DATA.WARRANTY_TEMPLATE_LIST[0]:
      logo = 'logoBlueScopeZacs';
      break;
    case DATA.WARRANTY_TEMPLATE_LIST[1]:
    case DATA.WARRANTY_TEMPLATE_LIST[9]:
    case DATA.WARRANTY_TEMPLATE_LIST[10]:
      logo = 'logoColorbond';
      break;
    case DATA.WARRANTY_TEMPLATE_LIST[2]:
      logo = 'logoCrp';
      break;
    case DATA.WARRANTY_TEMPLATE_LIST[3]:
      logo = 'logoJingjoeLek';
      break;
    case DATA.WARRANTY_TEMPLATE_LIST[4]:
      logo = 'logoPrima';
      break;
    case DATA.WARRANTY_TEMPLATE_LIST[5]:
      logo = 'logoSuperDyma';
      break;
    case DATA.WARRANTY_TEMPLATE_LIST[7]:
      logo = 'logoTrueCore';
      break;
    case DATA.WARRANTY_TEMPLATE_LIST[8]:
      logo = 'logoZincalume';
      break;
  }
  return {
    form,
    logo,
  }
}


export const getWarrantyTemplateFormVi = (type = '') => {
  let form = type;
  switch (type) {
    case DATA.WARRANTY_TEMPLATE_VI_LIST[0]:
      form = DATA.WARRANTY_TEMPLATE_VI_LIST[0];
      break;
    case DATA.WARRANTY_TEMPLATE_VI_LIST[1]:
      form = DATA.WARRANTY_TEMPLATE_VI_LIST[1];
      break;
    case DATA.WARRANTY_TEMPLATE_VI_LIST[2]:
      form = DATA.WARRANTY_TEMPLATE_VI_LIST[2];
      break;
    case DATA.WARRANTY_TEMPLATE_VI_LIST[3]:
      form = DATA.WARRANTY_TEMPLATE_VI_LIST[3];
      break;
    default:
      form = DATA.WARRANTY_TEMPLATE_VI_LIST[0];
      break;
  }
  return {
    form,
    logo: ''
  }
}

export const warrantyCloneDetail = (source = {}, target = {}) => {
  target.roof = source.roof;
  target.isRoofSelected = source.isRoofSelected;
  target.wall = source.wall;
  target.isWallSelected = source.isWallSelected;
  target.purlinGirt = source.purlinGirt;
  target.isPurlinGirtSelected = source.isPurlinGirtSelected;
  target.truFrame = source.truFrame;
  target.isTruFrameSelected = source.isTruFrameSelected;
  target.coolRoom = source.coolRoom;
  target.isCoolRoomSelected = source.isCoolRoomSelected;
  target.decking = source.decking;
  target.isDeckingSelected = source.isDeckingSelected;
  target.airDucting = source.airDucting;
  target.isAirDuctingSelected = source.isAirDuctingSelected;
  target.applicationInfoList = _.cloneDeep(source.applicationInfoList);
  target.templateList = target.templateList.map(e => {
    if (target[e.applicationForSelect]) {
      source.templateList.forEach(es => {
        if (e.applicationForSelect === es.applicationForSelect) {
          e.detailEnvSelect = es.detailEnvSelect || 0;
          e.envDetailSelect = e.envList[e.detailEnvSelect];

          const templatePeriodMax = setWarrantyMaxPeriod(e.envDetailSelect);
          e.envDetailSelectMax = {
            corrosion: templatePeriodMax.warrantyCorPeriodMax,
            peelFlake: templatePeriodMax.warrantyPeelFlakePeriodMax,
            colorFade: templatePeriodMax.warrantyColFadePeriodMax,
            dirtStain: templatePeriodMax.warrantyDirStainPeriodMax,
          }

          if (source.isWarrantyPeriodSelected) {
            e.envDetail = es.envDetail;
          }
          e.condition = es.condition;
          e.envDetailCondition = es.envDetailCondition;
          e.isWarrantyPeriodAppSelect = es.isWarrantyPeriodAppSelect;
          e.imageConditionSelectFile = es.imageConditionSelectFile;
          e.imageEnvSelectFile = es.imageEnvSelectFile;
          e.imageSelectPath = es.imageSelectPath;
        }
      });
    };
    return e
  });
  target.isWarrantyPeriodSelected = source.isWarrantyPeriodSelected;
  target.isRoofAndWall = source.isRoofAndWall;

  // if (target.m3ProductGroup === source.m3ProductGroup) {
  target.documentaryList = (source.documentaryList || []).filter(e => e.isSelected);
  target.isSelectedSpecifyCompanyName = source.isSelectedSpecifyCompanyName;
  target.specifyCompanyName = source.specifyCompanyName;
  // }
  return target;
}

export const warrantyCloneTemplate = (source = {}, target = {}) => {
  target.isAcceptTncTemplate = source.isAcceptTncTemplate;
  target.wrtSelTemplateGrp = source.wrtSelTemplateGrp;
  return target;
}

export const setWarrantyMaxPeriod = (data) => {
  return {
    warrantyCorPeriodMax: isNaN(parseInt(data.corrosion)) ? 0 : parseInt(data.corrosion),
    warrantyPeelFlakePeriodMax: isNaN(parseInt(data.peelFlake)) ? 0 : parseInt(data.peelFlake),
    warrantyColFadePeriodMax: isNaN(parseInt(data.colorFade)) ? 0 : parseInt(data.colorFade),
    warrantyDirStainPeriodMax: isNaN(parseInt(data.dirtStain)) ? 0 : parseInt(data.dirtStain),
  }
}

export const getWarrantyProjectTemplateData = (data = {}, dataDetail = {}) => {
  const warrantyTemplateNameList = DATA.WARRANTY_PRODUCT_TEMPLATE_GROUP[data.projectCountry];
  let WarrantyProjectTemplateComponent;
  let imageHeader = '';
  let imageFooter = '';
  let dataFooter = '';

  const language = getLocalStorage(LOCAL_STORAGE.LANGUAGE_RBPTRUEBLUE);

  switch (data.projectCountry) {
    case ENUM.COUNTRY_TYPE.THAI:
      imageFooter = 'warranty-footer-thailand.png';
      if (language === DATA.LANGUAGE.THAI &&
        (
          dataDetail.templateForm !== warrantyTemplateNameList[2] &&
          dataDetail.templateForm !== warrantyTemplateNameList[14] &&
          dataDetail.templateForm !== warrantyTemplateNameList[15] &&
          dataDetail.templateForm !== warrantyTemplateNameList[18]
        )
      ) {
        imageFooter = 'warranty-footer-thailand-th.png';
      }
      switch (dataDetail.templateForm) {
        case warrantyTemplateNameList[0]:
          imageHeader = 'warranty-header-thailand-BLUESCOPE_ZACS_TH.png';
          WarrantyProjectTemplateComponent = WarrantyBSWMThailandBluescopeZacsTh;
          break;
        case warrantyTemplateNameList[9]:
          imageHeader = 'warranty-header-thailand-BLUESCOPE_ZACS_TH.png';
          WarrantyProjectTemplateComponent = WarrantyBSWMThailandBluescopeZacsCoolTh;
          break;
        case warrantyTemplateNameList[10]:
          imageHeader = 'warranty-header-thailand-BLUESCOPE_ZACS_TH.png';
          WarrantyProjectTemplateComponent = WarrantyBSWMThailandBluescopeZacsNaturalTh;
          break;
        case warrantyTemplateNameList[11]:
          imageHeader = 'warranty-header-thailand-BLUESCOPE_ZACS_TH.png';
          WarrantyProjectTemplateComponent = WarrantyBSWMThailandBluescopeZacsDazzleTh;
          break;
        case warrantyTemplateNameList[1]:
          imageHeader = 'warranty-header-thailand-COLORBOND_TH.png';
          WarrantyProjectTemplateComponent = WarrantyBSWMThailandColorbondTh;
          break;
        case warrantyTemplateNameList[16]:
          imageHeader = 'warranty-header-thailand-COLORBOND_TH.png';
          WarrantyProjectTemplateComponent = WarrantyBSWMThailandColorbondXmaTh;
          break;
        case warrantyTemplateNameList[12]:
          imageHeader = 'warranty-header-thailand-COLORBOND_TH.png';
          if (dataDetail.m3ProductGroup === 'P15XM') {
            WarrantyProjectTemplateComponent = WarrantyBSWMThailandColorbondP15xmTh;
          }
          else if (dataDetail.m3ProductGroup === 'P16XM') {
            WarrantyProjectTemplateComponent = WarrantyBSWMThailandColorbondP16xmTh;
          }
          else {
            WarrantyProjectTemplateComponent = WarrantyBSWMThailandColorbondP17xmTh;
          }
          break;
        case warrantyTemplateNameList[2]:
          imageHeader = 'warranty-header-thailand-CRP_ANTIBACTERIAL_SD_TH.png';
          WarrantyProjectTemplateComponent = WarrantyBSWMThailandCrpAntibacterialSdTh;
          break;
        case warrantyTemplateNameList[3]:
          imageHeader = 'warranty-header-thailand-ENDUROCORE_TH.png';
          WarrantyProjectTemplateComponent = WarrantyBSWMThailandEndurocoreTh;
          break;
        case warrantyTemplateNameList[4]:
          imageHeader = 'warranty-header-thailand-JINGJOE_LEK_TH.png';
          WarrantyProjectTemplateComponent = WarrantyBSWMThailandJingjoeLekTh;
          break;
        case warrantyTemplateNameList[5]:
          imageHeader = 'warranty-header-thailand-PRIMA_TH.png';
          WarrantyProjectTemplateComponent = WarrantyBSWMThailandPrimaTh;
          break;
        case warrantyTemplateNameList[17]:
          imageHeader = 'warranty-header-thailand-PRIMA_TH.png';
          WarrantyProjectTemplateComponent = WarrantyBSWMThailandPrimaP1pmaTh;
          break;
        case warrantyTemplateNameList[6]:
          imageHeader = 'warranty-header-thailand-SUPERDYMA_TH.png';
          WarrantyProjectTemplateComponent = WarrantyBSWMThailandSuperdymaTh;
          break;
        case warrantyTemplateNameList[14]:
          imageHeader = 'warranty-header-thailand-SUPERDYMA_TH.png';
          WarrantyProjectTemplateComponent = WarrantyBSWMThailandSuperdymaTfTh;
          break;
        case warrantyTemplateNameList[15]:
          imageHeader = 'warranty-header-thailand-SUPERDYMA_TH.png';
          WarrantyProjectTemplateComponent = WarrantyBSWMThailandSuperdymaPlTh;
          break;
        case warrantyTemplateNameList[18]:
          imageHeader = 'warranty-header-thailand-SUPERDYMA_TH.png';
          WarrantyProjectTemplateComponent = WarrantyBSWMThailandSuperdymaSDuckTh;
          break;
        case warrantyTemplateNameList[19]:
          imageHeader = 'warranty-header-thailand-SUPERDYMA_TH.png';
          WarrantyProjectTemplateComponent = WarrantyBSWMThailandSuperdymaSDuckK1dsdTh;
          break;
        case warrantyTemplateNameList[20]:
          imageHeader = 'warranty-header-thailand-SUPERDYMA_TH.png';
          WarrantyProjectTemplateComponent = WarrantyBSWMThailandSuperdymaSDuckK2dsdTh;
          break;
        case warrantyTemplateNameList[7]:
          imageHeader = 'warranty-header-thailand-TRUECORE_TH.png';
          WarrantyProjectTemplateComponent = WarrantyBSWMThailandTruecoreTh;
          break;
        case warrantyTemplateNameList[8]:
          imageHeader = 'warranty-header-thailand-ZINCALUME_TH.png';
          WarrantyProjectTemplateComponent = WarrantyBSWMThailandZincalumeTh;
          break;
        case warrantyTemplateNameList[9]:
          imageHeader = 'warranty-header-thailand-BLUESCOPE_ZACS_TH.png';
          WarrantyProjectTemplateComponent = WarrantyBSWMThailandBluescopeZacsCoolTh;
          break;
        case warrantyTemplateNameList[10]:
          imageHeader = 'warranty-header-thailand-BLUESCOPE_ZACS_TH.png';
          WarrantyProjectTemplateComponent = WarrantyBSWMThailandBluescopeZacsDazzleTh;
          break;
        case warrantyTemplateNameList[11]:
          imageHeader = 'warranty-header-thailand-BLUESCOPE_ZACS_TH.png';
          WarrantyProjectTemplateComponent = WarrantyBSWMThailandBluescopeZacsNaturalTh;
          break;
        case warrantyTemplateNameList[13]:
          imageHeader = 'warranty-header-thailand-PP_PANEL_SD_TH.png';
          WarrantyProjectTemplateComponent = WarrantyBSWMThailandPpPanelSdTh;
          break;
        default:
          imageHeader = 'warranty-header-thailand-COLORBOND_TH.png';
          WarrantyProjectTemplateComponent = WarrantyBSWMThailandColorbondTh;
          break;
      }
      break;
    case ENUM.COUNTRY_TYPE.MALAYSIA:
      imageFooter = 'warranty-footer-malaysia.png';
      switch (dataDetail.templateForm) {
        case warrantyTemplateNameList[0]:
          imageHeader = 'warranty-header-malaysia-CAPERO_MY_P1CWR.png';
          WarrantyProjectTemplateComponent = WarrantyBSWMMalaysiaCaperoMyP1cwr;
          break;
        case warrantyTemplateNameList[1]:
          imageHeader = 'warranty-header-malaysia-CCB_MATT_MY_P15RM.png';
          WarrantyProjectTemplateComponent = WarrantyBSWMMalaysiaCcbMattMyP15rm;
          break;
        case warrantyTemplateNameList[2]:
          imageHeader = 'warranty-header-malaysia-CCB_SPECTRUM_MY_P1CPP.png';
          WarrantyProjectTemplateComponent = WarrantyBSWMMalaysiaCcbSpectrumMyP1cpp;
          break;
        case warrantyTemplateNameList[3]:
          imageHeader = 'warranty-header-malaysia-CCB_SPECTRUM_MY_P15PP.png';
          WarrantyProjectTemplateComponent = WarrantyBSWMMalaysiaCcbSpectrumMyP15pp;
          break;
        case warrantyTemplateNameList[4]:
          imageHeader = 'warranty-header-malaysia-CCB_ULTRA_MATT_MY_P15UM.png';
          WarrantyProjectTemplateComponent = WarrantyBSWMMalaysiaCcbUltraMattMyP15um;
          break;
        case warrantyTemplateNameList[5]:
          imageHeader = 'warranty-header-malaysia-CCB_ULTRA_MY_P15UL.png';
          WarrantyProjectTemplateComponent = WarrantyBSWMMalaysiaCcbUltraMyP15ul;
          break;
        case warrantyTemplateNameList[6]:
          imageHeader = 'warranty-header-malaysia-CCB_XPD_MY_P15XP.png';
          WarrantyProjectTemplateComponent = WarrantyBSWMMalaysiaCcbXpdMyP15xp;
          break;
        case warrantyTemplateNameList[7]:
          imageHeader = 'warranty-header-malaysia-CCB_XPD_PSC_MY_P15XS.png';
          WarrantyProjectTemplateComponent = WarrantyBSWMMalaysiaCcbXpdPscMyP15xs;
          break;
        case warrantyTemplateNameList[8]:
          imageHeader = 'warranty-header-malaysia-CCB_XRW_MY_P15XR.png';
          WarrantyProjectTemplateComponent = WarrantyBSWMMalaysiaCcbXrwMyP15xr;
          break;
        case warrantyTemplateNameList[9]:
          imageHeader = 'warranty-header-malaysia-PM_R_MY_P2PRJ.png';
          WarrantyProjectTemplateComponent = WarrantyBSWMMalaysiaPmRMyP2prj;
          break;
        case warrantyTemplateNameList[10]:
          imageHeader = 'warranty-header-malaysia-TRUECORE_MY_Z1TRC.png';
          WarrantyProjectTemplateComponent = WarrantyBSWMMalaysiaTruecoreMyZ1trc;
          break;
        case warrantyTemplateNameList[11]:
          imageHeader = 'warranty-header-malaysia-VERMOE_MY_P1VRM.png';
          WarrantyProjectTemplateComponent = WarrantyBSWMMalaysiaVermoeMyP1vrm;
          break;
        case warrantyTemplateNameList[12]:
          imageHeader = 'warranty-header-malaysia-ZL_MY_Z1ZAL.png';
          WarrantyProjectTemplateComponent = WarrantyBSWMMalaysiaZlMyZ1zal;
          break;
        case warrantyTemplateNameList[13]:
          imageHeader = 'warranty-header-malaysia-ZL_MY_Z1ZAU.png';
          WarrantyProjectTemplateComponent = WarrantyBSWMMalaysiaZlMyZ1zau;
          break;
        case warrantyTemplateNameList[14]:
          imageHeader = 'warranty-header-malaysia-PM_MY_P2PRM.png';
          WarrantyProjectTemplateComponent = WarrantyBSWMMalaysiaPmMyP2prm;
          break;
        default:
          imageHeader = 'warranty-header-malaysia-CCB_XRW_MY_P15XR.png';
          WarrantyProjectTemplateComponent = WarrantyBSWMMalaysiaCcbXrwMyP15xr;
          break;
      }
      break;
    case ENUM.COUNTRY_TYPE.INDONESIA:
      imageFooter = 'warranty-footer-indonesia.png';
      switch (dataDetail.templateForm) {
        case warrantyTemplateNameList[0]:
          imageHeader = 'warranty-header-indonesia-P1CCP_CRP_ANTIBACTERIAL.png';
          WarrantyProjectTemplateComponent = WarrantyBSWMIndonesiaP1ccpCrpAntibacterial;
          break;
        case warrantyTemplateNameList[1]:
          imageHeader = 'warranty-header-indonesia-P2BPC_BLUESCOPE_ZACS_COOL.png';
          WarrantyProjectTemplateComponent = WarrantyBSWMIndonesiaP2bpcBluescopeZacsCool;
          break;
        case warrantyTemplateNameList[2]:
          imageHeader = 'warranty-header-indonesia-P2BPN_BLUESCOPE_ZACS_NATURAL.png';
          WarrantyProjectTemplateComponent = WarrantyBSWMIndonesiaP2bpnBluescopeZacsNatural;
          break;
        case warrantyTemplateNameList[3]:
          imageHeader = 'warranty-header-indonesia-P2CCT_CRP_FOODGRADE.png';
          WarrantyProjectTemplateComponent = WarrantyBSWMIndonesiaP2cctCrpFoodgrade;
          break;
        case warrantyTemplateNameList[4]:
          imageHeader = 'warranty-header-indonesia-P2HRP_HRP_ANTIBACTERIAL.png';
          WarrantyProjectTemplateComponent = WarrantyBSWMIndonesiaP2hrpHrpAntibacterial;
          break;
        case warrantyTemplateNameList[5]:
          imageHeader = 'warranty-header-indonesia-P2KIR_KIRANA.png';
          WarrantyProjectTemplateComponent = WarrantyBSWMIndonesiaP2kirKirana;
          break;
        case warrantyTemplateNameList[6]:
          imageHeader = 'warranty-header-indonesia-P13XL_COLORBOND_R_XAL.png';
          WarrantyProjectTemplateComponent = WarrantyBSWMIndonesiaP13xlColorbondRXal;
          break;
        case warrantyTemplateNameList[7]:
          imageHeader = 'warranty-header-indonesia-P13XP_COLORBOND_R_XPD.png';
          WarrantyProjectTemplateComponent = WarrantyBSWMIndonesiaP13xpColorbondRXpd;
          break;
        case warrantyTemplateNameList[8]:
          imageHeader = 'warranty-header-indonesia-P17UL_COLORBOND_R_ULTRA.png';
          WarrantyProjectTemplateComponent = WarrantyBSWMIndonesiaP17ulColorbondRUltra;
          break;
        case warrantyTemplateNameList[9]:
          imageHeader = 'warranty-header-indonesia-P17XR_COLORBOND_R_XRW.png';
          WarrantyProjectTemplateComponent = WarrantyBSWMIndonesiaP17xrColorbondRXrw;
          break;
        case warrantyTemplateNameList[10]:
          imageHeader = 'warranty-header-indonesia-Z1TRC_TRUECORE.png';
          WarrantyProjectTemplateComponent = WarrantyBSWMIndonesiaZ1trcTruecore;
          break;
        case warrantyTemplateNameList[11]:
          imageHeader = 'warranty-header-indonesia-Z1ZAL_ZINCALUME.png';
          WarrantyProjectTemplateComponent = WarrantyBSWMIndonesiaZ1zalZincalume;
          break;
        case warrantyTemplateNameList[12]:
          imageHeader = 'warranty-header-indonesia-Z1ZAU_ZINCALUME_ULTRA.png';
          WarrantyProjectTemplateComponent = WarrantyBSWMIndonesiaZ1zauZincalumeUltra;
          break;
        case warrantyTemplateNameList[13]:
          imageHeader = 'warranty-header-indonesia-Z2ABD_ABADI.png';
          WarrantyProjectTemplateComponent = WarrantyBSWMIndonesiaZ2abdAbadi;
          break;
        case warrantyTemplateNameList[14]:
          imageHeader = 'warranty-header-indonesia-Z2BZC_BLUESCOPE_ZACS_BARE.png';
          WarrantyProjectTemplateComponent = WarrantyBSWMIndonesiaZ2bzcBluescopeZacsBare;
          break;
        case warrantyTemplateNameList[15]:
          imageHeader = 'warranty-header-indonesia-Z2BZU_BLUESCOPE_ZACS_TRUSS.png';
          WarrantyProjectTemplateComponent = WarrantyBSWMIndonesiaZ2bzuBluescopeZacsTruss;
          break;
        case warrantyTemplateNameList[16]:
          imageHeader = 'warranty-header-indonesia-P1CCP_COLORBOND_R_COOLROOM.png';
          WarrantyProjectTemplateComponent = WarrantyBSWMIndonesiaP1ccpColorbondRCoolroom;
          break;
        default:
          imageHeader = 'warranty-header-indonesia-P1CCP_CRP_ANTIBACTERIAL.png';
          WarrantyProjectTemplateComponent = WarrantyBSWMIndonesiaP1ccpCrpAntibacterial;
          break;
      }
      break;
    case ENUM.COUNTRY_TYPE.VIETNAM:
      // ==============================
      // SubFooter Vietnam - Copyright line 1
      // CCB_CUSTOM_XPD_PEARLESCENT_VN_P17XR >>> DATA >>>> COLORBOND® Custom
      // CCB_CUSTOM_XPD_VN_P17XR >>> DATA >>>> COLORBOND® Custom
      // CCB_FOR_PANEL_VN_P17XR >>> DATA >>>> COLORBOND® For Panel
      // CCB_ULTRA_ACTIVATE_VN_P17XR >>> DATA >>>> COLORBOND® Ultra
      // SUMO_ANTIFADING_VN_P17XR >>> DATA >>>> SUMO™
      // ZL_VN_Z1ZAL >>> DATA >>>> ZINCALUME®
      // ZL_ULTRA_VN_Z1ZAL >>> DATA >>>> ZINCALUME® Ultra
      // CCB_FOR_PANEL_VN_P1CPP >>> DATA >>>> COLORBOND® For Panel
      // CCB_ULTRA_VN_P14UL >>> DATA >>>> COLORBOND® Ultra
      // CCB_ULTRA_ACTIVATE_VN_V13UL >>> DATA >>>> COLORBOND® Ultra
      // CCB_XPD_PSC_VN_P13XS >>> DATA >>>> COLORBOND® Custom
      // CCB_XPD_VN_X13XP >>> DATA >>>> COLORBOND®
      // CCB_XRW_VN_P13XR >>> DATA >>>> COLORBOND® XRW
      // HINATA_BARE_VN_Z1HB >>> DATA >>>> HINATA®
      // HINATA_PAINTED_VN_P1H1Z >>> DATA >>>> HINATA-PAINTED®
      // SUMO_ANTIFADING_VN_P2SUA >>> DATA >>>> SUMO™
      // SUMO_FOR_PANEL_VN_P3SUP >>> DATA >>>> SUMO™ For Panel
      // ZL_ULTRA_VN_B1MAX >>> DATA >>>> ZINCALUME® Ultra
      // ==============================
      imageFooter = 'warranty-footer-vietnam.png';
      switch (dataDetail.templateForm) {
        case warrantyTemplateNameList[0]:
          imageHeader = 'warranty-header-vietnam-CCB_CUSTOM_XPD_PEARLESCENT_VN_P17XR.png';
          dataFooter = 'COLORBOND® Custom';
          WarrantyProjectTemplateComponent = WarrantyBSWMVietnamCcbCustomXpdPearlescentVnP17xr;
          break;
        case warrantyTemplateNameList[1]:
          imageHeader = 'warranty-header-vietnam-CCB_CUSTOM_XPD_VN_P17XR.png';
          dataFooter = 'COLORBOND® Custom';
          WarrantyProjectTemplateComponent = WarrantyBSWMVietnamCcbCustomXpdVnP17xr;
          break;
        case warrantyTemplateNameList[2]:
          imageHeader = 'warranty-header-vietnam-CCB_FOR_PANEL_VN_P17XR.png';
          dataFooter = 'COLORBOND® For Panel';
          WarrantyProjectTemplateComponent = WarrantyBSWMVietnamCcbForPanelVnP17xr;
          break;
        case warrantyTemplateNameList[3]:
          imageHeader = 'warranty-header-vietnam-CCB_ULTRA_ACTIVATE_VN_P17XR.png';
          dataFooter = 'COLORBOND® Ultra';
          WarrantyProjectTemplateComponent = WarrantyBSWMVietnamCcbUltraActivateVnP17xr;
          break;
        case warrantyTemplateNameList[4]:
          imageHeader = 'warranty-header-vietnam-SUMO_ANTIFADING_VN_P17XR.png';
          dataFooter = 'SUMO™';
          WarrantyProjectTemplateComponent = WarrantyBSWMVietnamSumoAntifadingVnP17xr;
          break;
        case warrantyTemplateNameList[5]:
          imageHeader = 'warranty-header-vietnam-ZL_VN_Z1ZAL.png';
          dataFooter = 'ZINCALUME®';
          WarrantyProjectTemplateComponent = WarrantyBSWMVietnamZlVnZ1zal;
          break;
        case warrantyTemplateNameList[6]:
          imageHeader = 'warranty-header-vietnam-ZL_ULTRA_VN_Z1ZAL.png';
          dataFooter = 'ZINCALUME® Ultra';
          WarrantyProjectTemplateComponent = WarrantyBSWMVietnamZlUltraVnZ1zal;
          break;
        case warrantyTemplateNameList[7]:
          imageHeader = 'warranty-header-vietnam-CCB_FOR_PANEL_VN_P1CPP.png';
          dataFooter = 'COLORBOND® For Panel';
          WarrantyProjectTemplateComponent = WarrantyBSWMVietnamCcbForPanelVnP1cpp;
          break;
        case warrantyTemplateNameList[8]:
          imageHeader = 'warranty-header-vietnam-CCB_ULTRA_VN_P14UL.png';
          dataFooter = 'COLORBOND® Ultra';
          WarrantyProjectTemplateComponent = WarrantyBSWMVietnamCcbUltraVnP14ul;
          break;
        case warrantyTemplateNameList[9]:
          imageHeader = 'warranty-header-vietnam-CCB_ULTRA_ACTIVATE_VN_V13UL.png';
          dataFooter = 'COLORBOND® Ultra';
          WarrantyProjectTemplateComponent = WarrantyBSWMVietnamCcbUltraActivateVnV13ul;
          break;
        case warrantyTemplateNameList[10]:
          imageHeader = 'warranty-header-vietnam-CCB_XPD_PSC_VN_P13XS.png';
          dataFooter = 'COLORBOND® Custom';
          WarrantyProjectTemplateComponent = WarrantyBSWMVietnamCcbXpdPscVnP13xs;
          break;
        case warrantyTemplateNameList[11]:
          imageHeader = 'warranty-header-vietnam-CCB_XPD_VN_X13XP.png';
          dataFooter = 'COLORBOND®';
          WarrantyProjectTemplateComponent = WarrantyBSWMVietnamCcbXpdVnX13xp;
          break;
        case warrantyTemplateNameList[12]:
          imageHeader = 'warranty-header-vietnam-CCB_XRW_VN_P13XR.png';
          dataFooter = 'COLORBOND® XRW';
          WarrantyProjectTemplateComponent = WarrantyBSWMVietnamCcbXrwVnP13xr;
          break;
        case warrantyTemplateNameList[13]:
          imageHeader = 'warranty-header-vietnam-HINATA_BARE_VN_Z1HB.png';
          dataFooter = 'HINATA®';
          WarrantyProjectTemplateComponent = WarrantyBSWMVietnamHinataBareVnZ1hb;
          break;
        case warrantyTemplateNameList[14]:
          imageHeader = 'warranty-header-vietnam-HINATA_PAINTED_VN_P1H1Z.png';
          dataFooter = 'HINATA-PAINTED®';
          WarrantyProjectTemplateComponent = WarrantyBSWMVietnamHinataPaintedVnP1h1z;
          break;
        case warrantyTemplateNameList[15]:
          imageHeader = 'warranty-header-vietnam-SUMO_ANTIFADING_VN_P2SUA.png';
          dataFooter = 'SUMO™';
          WarrantyProjectTemplateComponent = WarrantyBSWMVietnamSumoAntifadingVnP2sua;
          break;
        case warrantyTemplateNameList[16]:
          imageHeader = 'warranty-header-vietnam-SUMO_FOR_PANEL_VN_P3SUP.png';
          dataFooter = 'SUMO™ For Panel';
          WarrantyProjectTemplateComponent = WarrantyBSWMVietnamSumoForPanelVnP3sup;
          break;
        case warrantyTemplateNameList[17]:
          imageHeader = 'warranty-header-vietnam-ZL_ULTRA_VN_B1MAX.png';
          dataFooter = 'ZINCALUME® Ultra';
          WarrantyProjectTemplateComponent = WarrantyBSWMVietnamZlUltraVnB1max;
          break;
        default:
          imageHeader = 'warranty-header-vietnam-ZL_VN_Z1ZAL.png';
          dataFooter = 'ZINCALUME®';
          WarrantyProjectTemplateComponent = WarrantyBSWMVietnamZlVnZ1zal;
          break;
      }
      break;
  }

  return {
    Component: WarrantyProjectTemplateComponent,
    imageHeader,
    imageFooter,
    dataFooter,
  }
}

export const getWarrantyTemplateResidential2Project = (data = {}, dataDetail = {}) => {
  const warrantyTemplateNameList = DATA.WARRANTY_PRODUCT_TEMPLATE_GROUP[data.projectCountry];
  let templateForm = '';
  switch (dataDetail.templateForm) {
    // blueScopeZacs
    case DATA.WARRANTY_TEMPLATE_LIST[0]:
      templateForm = warrantyTemplateNameList[0];
      break;
    // colorbond
    case DATA.WARRANTY_TEMPLATE_LIST[1]:
      templateForm = warrantyTemplateNameList[1];
      break;
    // crp
    case DATA.WARRANTY_TEMPLATE_LIST[2]:
      templateForm = warrantyTemplateNameList[2];
      break;
    // jingjoeLek
    case DATA.WARRANTY_TEMPLATE_LIST[3]:
      templateForm = warrantyTemplateNameList[4];
      break;
    // prima
    case DATA.WARRANTY_TEMPLATE_LIST[4]:
      templateForm = warrantyTemplateNameList[5];
      break;
    // superDymaDk
    case DATA.WARRANTY_TEMPLATE_LIST[5]:
      templateForm = warrantyTemplateNameList[6];
      break;
    // superDymaPl
    case DATA.WARRANTY_TEMPLATE_LIST[6]:
      templateForm = warrantyTemplateNameList[6];
      break;
    // trueCore
    case DATA.WARRANTY_TEMPLATE_LIST[7]:
      templateForm = warrantyTemplateNameList[7];
      break;
    // zincalume
    case DATA.WARRANTY_TEMPLATE_LIST[8]:
      templateForm = warrantyTemplateNameList[8];
      break;
    default:
      templateForm = warrantyTemplateNameList[1];
      break;
  }
  return templateForm;
}

export const getFirebaseImagePublicPath = async (imageFile) => {
  if (imageFile) {
    imageFile.path = await imageUrltoBase64(await firebaseGetDownloadPath(imageFile.pathRef))
  }
  return imageFile
}

export const getWarrantyTemplateImagePublicPath = async (list = [], option = {}) => {
  return new Promise((resolve) => {
    if (list.length) {
      let cnt = 0;
      list.forEach(async (e, i) => {
        const getFirebaseImage = async (path, key) => {
          if (path) {
            e[key].path = await imageUrltoBase64(await firebaseGetDownloadPath(path))
          }
        }
        await Promise.all([
          getFirebaseImage(e.wrtTempDtHeaderEnUrl, 'wrtTempDtHeaderEnImage'),
          getFirebaseImage(e.wrtTempDtHeaderThUrl, 'wrtTempDtHeaderThImage'),
          getFirebaseImage(e.wrtTempDtFooterEnUrl, 'wrtTempDtFooterEnImage'),
          getFirebaseImage(e.wrtTempDtFooterThUrl, 'wrtTempDtFooterThImage'),
        ])
        // if (e.wrtTempDtHeaderEnUrl) {
        //   e.wrtTempDtHeaderEnImage.path = await imageUrltoBase64(await firebaseGetDownloadPath(e.wrtTempDtHeaderEnUrl));
        // }
        // if (e.wrtTempDtHeaderThUrl) {
        //   e.wrtTempDtHeaderThImage.path = await imageUrltoBase64(await firebaseGetDownloadPath(e.wrtTempDtHeaderThUrl));
        // }
        // if (e.wrtTempDtFooterEnUrl) {
        //   e.wrtTempDtFooterEnImage.path = await imageUrltoBase64(await firebaseGetDownloadPath(e.wrtTempDtFooterEnUrl));
        // }
        // if (e.wrtTempDtFooterThUrl) {
        //   e.wrtTempDtFooterThImage.path = await imageUrltoBase64(await firebaseGetDownloadPath(e.wrtTempDtFooterThUrl));
        // }
        list[i] = e;
        cnt++;
        if (cnt === list.length) {
          resolve(list);
        }
      })
    }
    else {
      resolve(list);
    }
  })
}

export const checkIsCanSelectResidentialCategory = (list = []) => {
  let isCanSelectResidentialCategory = true;
  list.forEach(e => {
    e.detailList.forEach(ed => {
      //if coilOrderM more than 1000 can't generate residential
      if (ed.coilOrderMCal > 1000) {
        isCanSelectResidentialCategory = false;
      }
    });
  });
  return isCanSelectResidentialCategory
}

export const getDefaultChooseCategoryWarrantyModalData = () => {
  return {
    isOpenChooseProjectCategoryModal: false,
    wrtCat: undefined,
  };
}

export const getOpenChooseCategoryWarrantyModalData = (modalData) => {
  modalData.isOpenChooseProjectCategoryModal = true;
  modalData.wrtCat = undefined;
  return modalData;
}

export const getCloseChooseCategoryWarrantyModalData = (modalData) => {
  modalData.isOpenChooseProjectCategoryModal = false;
  return modalData;
}

export const onChangeProjectCategoryWarrantyModalData = (modalData, type) => {
  modalData.wrtCat = type;
  return modalData;
}

export const handleSubmitCategoryWarrantyModal = async (list = [], modalData, callbackSuccess = () => { }) => {
  let selectedDataList = _.cloneDeep(list);
  if (modalData.wrtCat === undefined) {
    toast.error(getLanguage('message.warrantyPleaseSelectCategory', ''));
  }
  else {
    const userAuth = getUserAuthRedux();
    let isAlertSelectCategoryProject = false;
    let isAlertSelectCategoryResidiential = false;

    selectedDataList.forEach(e => {
      if (e.isGenerateResidential) {
        if (modalData.wrtCat === DATA.WARRANTY_CATEGORY_TYPE.NON_RESIDENTIAL.TYPE) {
          isAlertSelectCategoryResidiential = true;
        }
      }
      else {
        if (modalData.wrtCat === DATA.WARRANTY_CATEGORY_TYPE.RESIDENTIAL.TYPE) {
          isAlertSelectCategoryProject = true;
        }
      }
    });

    if (isAlertSelectCategoryResidiential) {
      if (userAuth.addressType === ENUM.COUNTRY_TYPE.MALAYSIA) {
        toast.error(getLanguage('message.warrantyPleaseSelectResidentialMY', ''));
      }
      else {
        toast.error(getLanguage('message.warrantyPleaseSelectResidential', ''));
      }
    }
    else if (isAlertSelectCategoryProject && !(
      userAuth.addressType === ENUM.COUNTRY_TYPE.THAI ||
      userAuth.addressType === ENUM.COUNTRY_TYPE.INDONESIA
    )) {
      if (userAuth.addressType === ENUM.COUNTRY_TYPE.MALAYSIA) {
        toast.error(getLanguage('message.warrantyPleaseSelectNonResidentialMY', ''));
      }
      else {
        toast.error(getLanguage('message.warrantyPleaseSelectNonResidential', ''));
      }
    }
    else {
      if (isAlertSelectCategoryProject && (
        userAuth.addressType === ENUM.COUNTRY_TYPE.THAI ||
        userAuth.addressType === ENUM.COUNTRY_TYPE.INDONESIA
      )) {
        selectedDataList = selectedDataList.map(e => {
          e.isGenerateResidential = true;
          e.productGroupName = e.detailList[0].coilGrp;
          e.detailList = e.detailList.map(detail => {
            detail.productGroupName = detail.coilGrp;
            if (userAuth.addressType === ENUM.COUNTRY_TYPE.INDONESIA) {
              const dataTemplate = getWarrantyTemplateForm(detail.productGroupName);
              detail.templateLogo = dataTemplate.logo;
              detail.templateForm = dataTemplate.form;
            }
            return detail;
          });
          return e
        })
      }

      const data = await getManageWarrantyData(selectedDataList);
      if (data) {
        data.wrtCat = modalData.wrtCat;
        callbackSuccess(data);
      }
    }
  }
}

export const getCMSWarrantyTemplate = (selectedDataList) => {
  return new Promise(resolve => {
    isShowLoading(true);
    let cnt = 0;
    let isError = false;
    let newSelectedDataList = [];
    const userAuth = getUserAuthRedux();
    selectedDataList.forEach(async (e) => {
      if (isError) return;
      console.log('cms template', e)
      // const configService = getWarrantyTemplateDetailConfigService(e, e.isGenerateResidential);
      const params = {
        //CR: 1498
        production_date: e.templateMapping?.productionDate,
        m3_prod_grp_code: e.templateMapping?.m3ProdGrpCode,
        product_grp_name: e.templateMapping?.productGrpName,
        color: e.templateMapping?.color,
        segment_category__c: userAuth.segmentCategory__c,
        segmentation__c: userAuth.segmentation__c,
        account_type__c: userAuth.accountRoleType,
        customer_type__c: userAuth.customerRoleType,
        // production_date: e.productionDate,
      }
      const dataTemplateDetail = await getWarrantyTemplateDetail(params, {
        isShowLoading: false,
        isShowError: false,
      })
      if (dataTemplateDetail && isError === false) {
        cnt++;
        const wrtTempName = dataTemplateDetail.wrtTempName;
        e.detailList = e.detailList.map(detail => {
          detail.templateForm = wrtTempName;
          detail.templateFormList = [wrtTempName];
          detail.templateList = detail.templateList.map(edt => {
            edt.template = wrtTempName;
            return edt;
          })
          return detail;
        });

        newSelectedDataList.push(e);
        if (cnt === selectedDataList.length) {
          isShowLoading(false);
          resolve(newSelectedDataList);
        }
      }
      else {
        isError = true;
        isShowLoading(false);
        toast.error(getLanguage('message.warrantyNoTemplateForm', ''));
        resolve();
      }
    });
  })
}

// CR: 1498
export const isWarrantyExtendedYear = (detail) => {
  let isPass = false
  DATA.M3_PRODUCT_GROUP_1498.forEach(e => {
    e.m3ProductGroup.forEach(em => {
      if (em === detail.m3ProductGroup) {
        if (
          e.product === 'Zincalume AZ200' ||
          e.product === 'Zincalume'
        ) {
          isPass = true;
        }
        else if (new Date(detail.productionDate) >= new Date('2024-08-18')) {
          isPass = true;
        }
      }
    })
  })
  return isPass
}
// CR: 1498
export const handleWarrantyExtendedYearProductGroup = (data) => {
  const detail = _.cloneDeep(data)
  DATA.M3_PRODUCT_GROUP_1498.forEach(e => {
    e.m3ProductGroup.forEach(ep => {
      if (ep === detail.m3ProductGroup) {
        if (e.product === 'Zincalume AZ200') {
          detail.productGroupName = e.product
        }
        else if (e.product === 'Zincalume') {
          // not change
        }
        else {
          detail.productGroupName = `${detail.productGroupName} (New Formula)`
        }
      }
    })
  })
  return detail
}



export const getManageWarrantyData = async (selectedDataList = []) => {
  let detailList = [];
  let productGroupList = [];
  let productGroupNameList = [];
  let templateList = [];
  let templateNameList = [];
  let productGroup = {};
  let isWarrantyNoTemplateForm = false;
  let isCanRegenerate = true;

  if (selectedDataList[0].isCMSTemplate) {
    selectedDataList = await getCMSWarrantyTemplate(selectedDataList);
    if (!selectedDataList) {
      return undefined
    }
  }

  console.log('selectedDataList', selectedDataList)

  // CR: 1498
  selectedDataList = _.orderBy(selectedDataList.map(e => {
    e.detailList = e.detailList.map(ed => {
      if (e.projectCountry === ENUM.COUNTRY_TYPE.THAI) {
        if (isWarrantyExtendedYear(ed)) {
          ed = handleWarrantyExtendedYearProductGroup(ed);
          e.productGroupName = ed.productGroupName;
          e.oldProductGroupName = ed.oldProductGroupName;
        }
      }
      return ed;
    })
    return e;
  }), ['productGroupName'], ['asc'])

  selectedDataList.forEach(e => {

    if (isCanRegenerate) {
      isCanRegenerate = e.isCanRegenerate;
    }

    e.detailList.forEach(ed => {
      let detail = { ...ed };

      if (productGroupNameList.indexOf(detail.productGroupName) === -1) {
        productGroupNameList.push(detail.productGroupName);
        productGroupList.push(_.cloneDeep(detail));
      }

      //cal total coil order m in group
      if (productGroup[detail.productGroupName]) {
        productGroup[detail.productGroupName].coilOrderMGroupTotal += detail.coilOrderMCal;
      }
      else {
        productGroup[detail.productGroupName] = {
          coilOrderMGroupTotal: detail.coilOrderMCal,
        }
      }

      if (e.isGenerateResidential) {
        if (templateNameList.indexOf(detail.templateForm) === -1) {
          templateNameList.push(detail.templateForm);
          templateList.push(detail);
        }
      }
      else {
        detail.templateList.forEach(edt => {
          if (edt.template) {
            if (templateNameList.indexOf(edt.template) === -1) {
              templateNameList.push(edt.template);
              templateList.push(_.cloneDeep({
                ...detail,
                isAcceptTncTemplate: edt.isAcceptTncTemplate,
                templateForm: edt.template
              }));
            }
          }
          else {
            isWarrantyNoTemplateForm = true;
          }
        });
      }

      detailList.push(_.cloneDeep(detail));
    })
  })

  productGroupList = productGroupList.map(e => {
    e.coilOrderMGroupTotal = productGroup[e.productGroupName].coilOrderMGroupTotal;
    return e;
  })

  if (isWarrantyNoTemplateForm) {
    toast.error(getLanguage('message.warrantyNoTemplateForm', ''));
    return undefined;
  }
  else {
    const data = {
      ...selectedDataList[0],
      detailList,
      productGroupList,
      templateList,
      selectedDataList,
    };

    data.isCanRegenerate = isCanRegenerate;

    if (data.projectCountry === ENUM.COUNTRY_TYPE.THAI) {
      data.detailList.forEach(e => {
        //if coilOrderM more than 1000 can't generate residential
        if (e.coilOrderMCal > 1000) {
          data.isCanSelectResidentialCategory = false;
        }
      });
    }

    return data;
  }
};

export const handleProjectDocumentWarrantyData = (data, projectDocumentData, option = {}) => {
  data.projectDocumentId = projectDocumentData.data.id;
  data.handleGoBackPath = `${ROUTE_PATH.PROJECT_DOCUMENT_MANAGE.LINK}/${projectDocumentData.data.id}`;
  data.handleGoBackRouteData = {
    tabType: DATA.PROJECT_DOCUMENT_TAB_TYPE_LIST[2],
  }
  if (
    option.isEditDraft ||
    data.isCanRegenerate
  ) {
    // not replace information
  }
  else {
    data.projectName = projectDocumentData.data.preSalesTransProjectName;
    data.projectAddr = projectDocumentData.data.preSalesTransProjectAddr;
    data.selectedProvince = projectDocumentData.data.selectedProvince;
    data.selectedCity = projectDocumentData.data.selectedCity;
    data.projectSubDist = projectDocumentData.data.subDistrict;
    data.projectPostCode = projectDocumentData.data.postCode;
    data.segmentId = projectDocumentData.segmentSelected.segmentId;
    data.subSegmentId = projectDocumentData.segmentSelected.subSegmentId;
  }
  return data;
}

export const getDefaultEnvironmentDescription = (environment = '') => {
  switch (environment.toLowerCase()) {
    // 'Very Severe Marine'
    case DATA.WARRANTY_ENV_DETAIL_NAME_LIST[0].toLowerCase():
      return (
        <Section>
          <Text bold16 color={VARIABLES.COLORS.BLACK}>{getLanguage('field.environment.marineEnvironment.verySevereMarine', '')}</Text>
          <Section spacingBottom='2'>
            <Text regular12 color={VARIABLES.COLORS.BLACK}>
              {getLanguage('field.environment.marineEnvironment.verySevereMarineDescription1', '')}&nbsp;
              <Text bold12 underline color={VARIABLES.COLORS.PRIMARY_2}>{getLanguage('field.environment.marineEnvironment.verySevereMarineDescription2', '')}</Text>
              &nbsp;{getLanguage('field.environment.marineEnvironment.verySevereMarineDescription3', '')}
            </Text>
          </Section>
        </Section>
      )
    // 'Severe Marine'
    case DATA.WARRANTY_ENV_DETAIL_NAME_LIST[1].toLowerCase():
      return (
        <Section>
          <Text bold16 color={VARIABLES.COLORS.BLACK}>{getLanguage('field.environment.marineEnvironment.severeMarine', '')}</Text>
          <Section spacingBottom='2'>
            <Text regular12 color={VARIABLES.COLORS.BLACK}>
              {getLanguage('field.environment.marineEnvironment.severeMarineDescription1', '')}&nbsp;
              <Text bold12 underline color={VARIABLES.COLORS.PRIMARY_2}>{getLanguage('field.environment.marineEnvironment.severeMarineDescription2', '')}</Text>
              &nbsp;{getLanguage('field.environment.marineEnvironment.severeMarineDescription3', '')}&nbsp;
              <Text bold12 underline color={VARIABLES.COLORS.PRIMARY_2}>{getLanguage('field.environment.marineEnvironment.severeMarineDescription4', '')}</Text>&nbsp;
              &nbsp;{getLanguage('field.environment.marineEnvironment.severeMarineDescription5', '')}&nbsp;
              <Text bold12 italic color={VARIABLES.COLORS.PRIMARY_2}>{getLanguage('field.environment.marineEnvironment.severeMarineDescription6', '')}</Text>
            </Text>
          </Section>
        </Section>
      )
    // 'Marine'
    case DATA.WARRANTY_ENV_DETAIL_NAME_LIST[2].toLowerCase():
      return (
        <Section>
          <Text bold16 color={VARIABLES.COLORS.BLACK}>
            {getLanguage('field.environment.marineEnvironment.marine', '')}
          </Text>
          <Section spacingBottom='2'>
            <Text regular12 color={VARIABLES.COLORS.BLACK}>
              {getLanguage('field.environment.marineEnvironment.marineDescription1', '')}&nbsp;
              <Text bold12 underline color={VARIABLES.COLORS.PRIMARY_2}>{getLanguage('field.environment.marineEnvironment.marineDescription2', '')}</Text>
              &nbsp;{getLanguage('field.environment.marineEnvironment.marineDescription3', '')}&nbsp;
              <Text bold12 underline color={VARIABLES.COLORS.PRIMARY_2}>{getLanguage('field.environment.marineEnvironment.marineDescription4', '')}</Text>&nbsp;
              &nbsp;{getLanguage('field.environment.marineEnvironment.marineDescription5', '')}&nbsp;
              <Text bold12 italic color={VARIABLES.COLORS.PRIMARY_2}>{getLanguage('field.environment.marineEnvironment.marineDescription6', '')}</Text>
            </Text>
          </Section>
        </Section>
      )
    // 'Moderate Marine'
    case DATA.WARRANTY_ENV_DETAIL_NAME_LIST[3].toLowerCase():
      return (
        <Section>
          <Text bold16 color={VARIABLES.COLORS.BLACK}>
            {getLanguage('field.environment.marineEnvironment.moderateMarine', '')}
          </Text>
          <Section spacingBottom='2'>
            <Text regular12 color={VARIABLES.COLORS.BLACK}>
              {getLanguage('field.environment.marineEnvironment.moderateMarineDescription1', '')}&nbsp;
              <Text bold12 underline color={VARIABLES.COLORS.PRIMARY_2}>{getLanguage('field.environment.marineEnvironment.moderateMarineDescription2', '')}</Text>
              &nbsp;{getLanguage('field.environment.marineEnvironment.moderateMarineDescription3', '')}&nbsp;
              <Text bold12 underline color={VARIABLES.COLORS.PRIMARY_2}>{getLanguage('field.environment.marineEnvironment.moderateMarineDescription4', '')}</Text>
            </Text>
          </Section>
        </Section>
      )
    // 'Benign / Rural'
    case DATA.WARRANTY_ENV_DETAIL_NAME_LIST[4].toLowerCase():
      return (
        <Section>
          <Text bold16 color={VARIABLES.COLORS.BLACK}>
            {getLanguage('field.environment.standard.benignRural', '')}
          </Text>
          <Section spacingBottom='2'>
            <Text regular12 color={VARIABLES.COLORS.BLACK}>
              {getLanguage('field.environment.standard.benignRuralDescription1', '')}&nbsp;
              <Text bold12 underline color={VARIABLES.COLORS.PRIMARY_2}>{getLanguage('field.environment.standard.benignRuralDescription2', '')}</Text>
              &nbsp;{getLanguage('field.environment.standard.benignRuralDescription3', '')}&nbsp;
              &nbsp;{getLanguage('field.environment.standard.benignRuralDescription4', '')}&nbsp;
              &nbsp;{getLanguage('field.environment.standard.benignRuralDescription5', '')}&nbsp;
              <Text bold12 underline color={VARIABLES.COLORS.PRIMARY_2}>{getLanguage('field.environment.standard.benignRuralDescription6', '')}</Text>
              &nbsp;{getLanguage('field.environment.standard.benignRuralDescription7', '')}&nbsp;
              <Text bold12 underline color={VARIABLES.COLORS.PRIMARY_2}>{getLanguage('field.environment.standard.benignRuralDescription8', '')}</Text>
            </Text>
          </Section>
        </Section>
      )
    // 'Severe Industrial'
    case DATA.WARRANTY_ENV_DETAIL_NAME_LIST[5].toLowerCase():
      return (
        <Section>
          <Text bold16 color={VARIABLES.COLORS.BLACK}>
            {getLanguage('field.environment.industrialEnvironment.severeIndustrial', '')}
          </Text>
          <Section spacingBottom='2'>
            <Text regular12 color={VARIABLES.COLORS.BLACK}>
              {getLanguage('field.environment.industrialEnvironment.severeIndustrialDescription1', '')}&nbsp;
              <Text bold12 underline color={VARIABLES.COLORS.PRIMARY_2}>{getLanguage('field.environment.industrialEnvironment.severeIndustrialDescription2', '')}</Text>
              &nbsp;{getLanguage('field.environment.industrialEnvironment.severeIndustrialDescription3', '')}&nbsp;
              <Text bold12 underline color={VARIABLES.COLORS.PRIMARY_2}>{getLanguage('field.environment.industrialEnvironment.severeIndustrialDescription4', '')}</Text>&nbsp;
              &nbsp;{getLanguage('field.environment.industrialEnvironment.severeIndustrialDescription5', '')}
            </Text>
          </Section>
        </Section>
      )
    // 'Heavy Industrial'
    case DATA.WARRANTY_ENV_DETAIL_NAME_LIST[6].toLowerCase():
      return (
        <Section>
          <Text bold16 color={VARIABLES.COLORS.BLACK}>
            {getLanguage('field.environment.industrialEnvironment.heavyIndustrial', '')}
          </Text>
          <Section spacingBottom='2'>
            <Text regular12 color={VARIABLES.COLORS.BLACK}>
              {getLanguage('field.environment.industrialEnvironment.heavyIndustrialDescription1', '')}&nbsp;
              <Text bold12 underline color={VARIABLES.COLORS.PRIMARY_2}>{getLanguage('field.environment.industrialEnvironment.heavyIndustrialDescription2', '')}</Text>
              &nbsp;{getLanguage('field.environment.industrialEnvironment.heavyIndustrialDescription3', '')}&nbsp;
              <Text bold12 italic color={VARIABLES.COLORS.PRIMARY_2}>{getLanguage('field.environment.industrialEnvironment.heavyIndustrialDescription4', '')}</Text>
            </Text>
          </Section>
        </Section>
      )
    // 'Industrial'
    case DATA.WARRANTY_ENV_DETAIL_NAME_LIST[7].toLowerCase():
      return (
        <Section>
          <Text bold16 color={VARIABLES.COLORS.BLACK}>
            {getLanguage('field.environment.industrialEnvironment.industrial', '')}
          </Text>
          <Section spacingBottom='2'>
            <Text regular12 color={VARIABLES.COLORS.BLACK}>
              {getLanguage('field.environment.industrialEnvironment.industrialDescription1', '')}&nbsp;
              <Text bold12 underline color={VARIABLES.COLORS.PRIMARY_2}>{getLanguage('field.environment.industrialEnvironment.industrialDescription2', '')}</Text>
              &nbsp;{getLanguage('field.environment.industrialEnvironment.industrialDescription3', '')}
            </Text>
          </Section>
        </Section>
      )
    // 'Light Ind / Urban'
    case DATA.WARRANTY_ENV_DETAIL_NAME_LIST[8].toLowerCase():
      return (
        <Section>
          <Text bold16 color={VARIABLES.COLORS.BLACK}>
            {getLanguage('field.environment.industrialEnvironment.lightIndUrban', '')}
          </Text>
          <Section spacingBottom='2'>
            <Text regular12 color={VARIABLES.COLORS.BLACK}>
              {getLanguage('field.environment.industrialEnvironment.lightIndUrbanDescription1', '')}&nbsp;
              <Text bold12 underline color={VARIABLES.COLORS.PRIMARY_2}>{getLanguage('field.environment.industrialEnvironment.lightIndUrbanDescription2', '')}</Text>
              &nbsp;{getLanguage('field.environment.industrialEnvironment.lightIndUrbanDescription3', '')}
            </Text>
          </Section>
        </Section>
      )
    // 'TrueCore Application'
    case DATA.WARRANTY_ENV_DETAIL_NAME_LIST[9].toLowerCase():
      return (
        <Section>
          <Text bold16 color={VARIABLES.COLORS.BLACK}>
            {getLanguage('field.environment.trueCoreApplication', '')}
          </Text>
        </Section>
      )
    default: break;
  }
}

export const getWarrantyDocumentaryQRCodeValue = (data = {}) => {
  const language = getLocalStorage(LOCAL_STORAGE.LANGUAGE_RBPTRUEBLUE);
  // const prefixQRCode = 'https://trueblue.nsbluescope.com'
  const prefixQRCode = window.location.origin
  let qrValue = `${prefixQRCode}${ROUTE_PATH.PUBLIC.WARRANTY_AND_DOCUMENTARY.LINK}?type=${data.type}&id=${data.id}`;
  switch (data.type) {
    case DATA.PUBLIC_WARRANTY_DOCUMENTARY_TYPE.WARRANTY:
      return `${qrValue}&wrtSelGrp=${data.wrtSelGrp}&wrtSelTemplateGrp=${data.wrtSelTemplateGrp}&lang=${language}`;
    case DATA.PUBLIC_WARRANTY_DOCUMENTARY_TYPE.WARRANTY_DOCUMENTARY:
      return `${qrValue}&wrtDocNo=${encodeURIComponent(data.wrtDocNo)}&group=${data.group}&lang=${language}`;
    case DATA.PUBLIC_WARRANTY_DOCUMENTARY_TYPE.CERTIFICATE:
      return `${qrValue}&lang=${language}`;
    case DATA.PUBLIC_WARRANTY_DOCUMENTARY_TYPE.WARRANTY_TEMPLATE:
      return `${qrValue}&wrtCat=${data.wrtCat}`;
    default:
      return `${qrValue}&lang=${language}`;
  }
}

export const handleDocumentaryData = (dataDetail, dataGroupList = [], watermarkTemplateType, isGenerateQR) => {
  // merge documentaryList from product group with same template name
  const documentaryList = []
  let displayCoil = []
  // let productGroupList = []

  dataGroupList.forEach(e => {
    displayCoil = [...displayCoil, ...(e.displayCoil || [])]
    // let productGroupName = ''
    // if (watermarkTemplateType === DATA.WATERMARK_TEMPLATE_LIST[0].value) {
    //   productGroupName = e.productGroupName;
    // }
    // else {
    //   productGroupName = e.m3ProductGroupName;
    // }
    // productGroupList.push((productGroupName || '').replaceAll(' ', '_'))
  });

  // productGroupList = _.uniq(productGroupList).join(',')

  dataGroupList.forEach(e => {
    (e.documentaryList || []).forEach(ed => {
      if (ed.isSelected) {
        const document = _.cloneDeep(ed);
        document.m3ProductGroupName = e.m3ProductGroupName;
        document.productGroupName = e.productGroupName;
        // CR: 1498
        document.oldProductGroupName = e.oldProductGroupName;
        document.preSalesTransCertId = e.preSalesTransCertId;
        document.isSelectedSpecifyCompanyName = e.isSelectedSpecifyCompanyName;
        document.specifyCompanyName = e.specifyCompanyName || '';
        document.displayCoil = displayCoil;

        if (watermarkTemplateType === DATA.WATERMARK_TEMPLATE_LIST[0].value) {
          document.uniqId = `${document.id}_${document.productGroupName}`
          if (isGenerateQR) {
            document.qrCodeValue = getWarrantyDocumentaryQRCodeValue({
              type: DATA.PUBLIC_WARRANTY_DOCUMENTARY_TYPE.WARRANTY_DOCUMENTARY,
              id: e.wrtId,
              wrtDocNo: document.wrtDocNo || '-',
              group: e.productGroupName.replaceAll(' ', '_')
            })
            console.log('qrCodeValue', document.uniqId, document.qrCodeValue)
          }
        }
        else {
          document.uniqId = `${document.id}_${document.m3ProductGroupName}`
          if (isGenerateQR) {
            document.qrCodeValue = getWarrantyDocumentaryQRCodeValue({
              type: DATA.PUBLIC_WARRANTY_DOCUMENTARY_TYPE.CERTIFICATE,
              id: ed.preSalesTransCertDTId
            });
            console.log('qrCodeValue', document.uniqId, document.qrCodeValue)
          }
        }

        documentaryList.push(document)
      }
    })
  })

  const documentaryData = _.cloneDeep(dataDetail);
  documentaryData.documentaryList = documentaryList
  // console.log('handleDocumentaryData========')
  // console.log('dataDetail', dataDetail, dataDetail.productGroupName);
  // console.log('dataGroupList', dataGroupList);
  // console.log('documentaryData', documentaryData);
  // console.log('========')
  return documentaryData
}

export const getModelWarranty = (_this = {}) => {
  _this.detailList = getModelWarrantyDetial(_this, _this.detailList);
  return _this
}

export const getModelWarrantyDetial = (_this, detailList = []) => {
  return detailList.map(e => {
    e.projectCountry = _this.projectCountry;

    //clear data for status delete
    if (_this.isStatusDelete) {
      e.wrt_path = '';
    }
    const detail = new ModelWarrantyDetail(e)
    // if (detail.oldFileRef) {
    //   isShowWrtNo = true;
    // }
    detail.wrtId = _this.id;
    detail.wrtNo = _this.wrtNo;

    _this.isGenerateResidential = detail.templateList.length === 0;
    // || detail.templateList.filter(e => e.category === DATA.WARRANTY_CATEGORY_TYPE.RESIDENTIAL.TEXT).length > 0;

    if (
      _this.isCanRegenerate &&
      _this.wrtCat === DATA.WARRANTY_CATEGORY_TYPE.RESIDENTIAL.TYPE
    ) {
      _this.isGenerateResidential = true;
    }

    // const tempStatus = getWarrantyTypeText(props.wrt_status, _this.statusCode);
    // _this.wrtStatusText = tempStatus.typeText;
    // _this.wrtStatusColor = tempStatus.color;

    // _this.isSaveDraft =
    //   _this.wrtStatusText === DATA.WARRANTY_TYPE.SAVE_DRAFT.TEXT &&
    //   _this.statusCode === DATA.WARRANTY_TYPE.SAVE_DRAFT.TYPE;
    if (_this.isSaveDraft) {
      _this.isGenerateResidential = _this.wrtCat === DATA.WARRANTY_CATEGORY_TYPE.RESIDENTIAL.TYPE
    }

    // CR: 1490
    // if (_this.projectCountry === ENUM.COUNTRY_TYPE.THAI) {
    //   _this.isGenerateResidential = false;
    // }

    if (_this.isGenerateResidential) {
      let dataTemplate;
      detail.productGroupName = detail.coilGrp;
      switch (_this.projectCountry) {
        case ENUM.COUNTRY_TYPE.VIETNAM:
          dataTemplate = getWarrantyTemplateFormVi(detail.productGroupName);
          break;
        default:
          dataTemplate = getWarrantyTemplateForm(detail.productGroupName);
          break;
      }
      detail.templateLogo = dataTemplate.logo;
      detail.templateForm = dataTemplate.form;
    }
    else {
      if (_this.projectCountry === ENUM.COUNTRY_TYPE.THAI) {
        // CR: 1481
        detail.productGroupName = detail.coilGrp;
      }
      else {
        detail.productGroupName = detail.templateList[0]?.productGroupName || '';
      }
      detail.templateFormList = _.uniq(detail.templateList.map(et => et.template));
      detail.templateForm = '';
    }

    _this.coilGrp = detail.coilGrp;
    _this.productGroupName = detail.productGroupName;
    // CR: 1498
    detail.oldProductGroupName = detail.productGroupName;
    _this.oldProductGroupName = detail.productGroupName;

    _this.coilOrderMWithComma = detail.coilOrderMWithComma;
    _this.displayOrderDate = detail.displayOrderDate;
    _this.m3ProductGroup = detail.m3ProductGroup;
    _this.color = detail.color;
    //bswm v
    detail.createdDateTime = _this.createdDateTime;
    if (detail.applicationInfoList.length === 0) {
      detail.applicationInfoList = [{
        projectInsDateTime: _this.projectInsDateTime,
        building: '',
        customerRemark: '',
      }]
    }

    _this.productionDate = detail.productionDate;

    _this.templateMapping = {
      m3ProdGrpCode: _this.m3ProductGroup,
      productGrpName: _this.coilGrp,
      color: _this.color,
      // CR: 1498
      productionDate: _this.productionDate
    }
    detail.templateMapping = _.cloneDeep(_this.templateMapping)
    //bswm ^
    return detail;
  });
}

export const genWrtNoRegen = (oldWrtNo = '') => {
  let wrtNoRegen = oldWrtNo;
  const wrtNoRev = wrtNoRegen.match(/Rev(.*)/gi)
  if (wrtNoRev) {
    let revCnt = wrtNoRev[0].match(/\d+/g);
    if (revCnt) {
      revCnt = parseInt(revCnt[0])
    }
    else {
      revCnt = 0
    }
    wrtNoRegen = wrtNoRegen.replace(/Rev.*/gi, `Rev${revCnt + 1}`)
  }
  else {
    wrtNoRegen += '-Rev1'
  }
  return wrtNoRegen
}

// CR: 1497
export const isValidTemplateSegmentSpecial = (data = {}, templateData = {}) => {
  if (
    data.isSpecialSegment &&
    DATA.WARRANTY_YEAR.filter(e => e.m3ProductGroups.filter(em => em === templateData.m3ProductGroup).length).length === 0
  ) {
    return false
  }
  return true
}

// CR: 1498
export const isValidTemplateExtendYear = (data = {}, templateData = {}) => {
  let isPass = true;
  if (
    DATA.M3_PRODUCT_GROUP_1498.filter(e => e.m3ProductGroup.filter(em => em === templateData.m3ProductGroup).length).length > 0
  ) {
    console.log('isValidTemplateExtendYear', data, templateData)
    let envDetail = {};
    (templateData.templateList || []).forEach(e => {
      (data.detailList || []).forEach(ed => {
        if ((ed.templateList || []).length) {
          ed.templateList.forEach(edt => {
            if (
              edt.template === e.template &&
              ed[edt.applicationForSelect]
            ) {
              envDetail = e.envList[edt.detailEnvSelect];
              if (envDetail) {
                console.log('isValidTemplateExtendYear isWarrantyNoOffer', envDetail)
                if (envDetail.isWarrantyNoOffer) {
                  isPass = false
                }
              }
            }
          });
        }
      })
    })
  }
  return isPass
}