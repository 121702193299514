import { DATE_TIME_FORMAT } from "../helpers/constants/data";
import { getUserAuthRedux } from "../helpers/functions/auth";
import { displayDate } from "../helpers/functions/display";

export class ModelNoti {
  constructor(props = {}) {
    this.props = props;
  }

  firebase = () => {
    const userAuth = getUserAuthRedux();
    const data = {
      title: this.props.title || '',
      description: this.props.description || '',
      type: this.props.type || '',
      createdDate: new Date().getTime(),
      addressType: userAuth.addressType || '',
      data: {
        ...(this.props.data || {}),
        adRefId: userAuth.id,
      },
      isNew: true,
    };

    data.displayCreatedDate = data.createdDate ? displayDate(data.createdDate, '/', DATE_TIME_FORMAT) : '-';
    
    return data;
  }
}

export class ModelNotiCMS {
  constructor(props = {}) {
    this.props = props;
  }

  firebase = () => {
    const userAuth = getUserAuthRedux();
    const data = {
      title: this.props.title || '',
      description: this.props.description || '',
      type: this.props.type || '',
      createdDate: new Date().getTime(),
      addressType: userAuth.addressType || '',
      data: {
        ...(this.props.data || {}),
        adRefId: userAuth.id,
      },
      isNew: true,
    };

    data.displayCreatedDate = data.createdDate ? displayDate(data.createdDate, '/', DATE_TIME_FORMAT) : '-';
    
    return data;
  }
}