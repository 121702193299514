import _ from 'lodash';
import React from 'react';
import {
  Accordion,
  AccordionItem,
  AccordionItemBody,
  AccordionItemTitle
} from 'react-accessible-accordion';
import InfiniteScroll from 'react-infinite-scroller';
import {
  connect,
} from 'react-redux';
import { DATA_REDUX_STATE_KEY, dataCreators } from '../../actions/dataAction';
import { MAIN_REDUX_STATE_KEY } from '../../actions/mainAction';
import { DATA } from '../../helpers/constants/data';
import { ENUM } from '../../helpers/constants/enum';
import { PERMISSION } from '../../helpers/constants/permission';
import { ROUTE_PATH } from '../../helpers/constants/route';
import { firebaseGetNotificationLoadMore, firebaseUpdateOrderIsNew, firebaseUpdateReadNoti } from '../../helpers/firebases/firebaseNotification';
import { logout } from '../../helpers/functions/auth';
import { changeLanguage, getLanguage } from '../../helpers/functions/language';
import { isRolePermission } from '../../helpers/functions/permission';
import { redirect } from '../../helpers/functions/route';
import { getClearOrderInquiryParams, getNotiOrderInquiryList } from '../../helpers/services/orderInquiry';
import { storeDispatch } from '../../stores';
import {
  AccordionControl,
  ChooseLanguage,
  CmsLayout,
  Grid,
  Image,
  Section,
  ShapeContainer,
  Text
} from './../../components';
import {
  ICONS,
  VARIABLES
} from './../../themes';

class TopbarContainer extends React.Component {

  constructor(props) {
    super(props);
    let isMobileScreenSize = window.matchMedia('(max-width: 1023px)').matches
    this.state = {
      activePage: 1,
      dataPerPage: 10,
      isOpenNoti: false,
      notiList: [],
      isMobileScreenSize,
    }
    this.matchMedia = null
  }

  componentDidMount() {
    this.mobile.handleCheckSizeChange();
  }

  componentWillUnmount() {
    if (this.matchMedia) {
      this.matchMedia.removeEventListener("change", this.handleChange)
    }
  }

  handleChange = (ev) => {
    this.setState({
      isMobileScreenSize: ev.matches,
    });
  };

  mobile = {
    handleCheckSizeChange: () => {
      this.matchMedia = window.matchMedia('(max-width: 1023px)')
      this.matchMedia.addEventListener('change', this.handleChange);
    }
  }

  topbar = {
    onOpenNoti: async (ev) => {
      const {
        firebaseNoti,
        isNewNoti,
      } = this.props;
      const {
        isOpenNoti,
      } = this.state;
      // if ui current open will close
      if (isOpenNoti) {
        this.setState({
          isOpenNoti: false,
        })
      }
      else {
        this.setState({
          activePage: 1,
          notiList: [],
        }, async () => {
          if (isNewNoti) {
            firebaseUpdateOrderIsNew(false);
            firebaseUpdateReadNoti();
          }
          // clear noti for open first time
          firebaseNoti.dataList = [];
          storeDispatch(dataCreators.setDataRedux(DATA_REDUX_STATE_KEY.FIREBASE_NOTI, firebaseNoti));

          await this.topbar.getNotificationList();
          this.setState({
            isOpenNoti: true,
          })
        });
      }
    },
    getNotificationList: async () => {
      const {
        dataPerPage,
        activePage,
        notiList,
      } = this.state;
      // old noti order inquery
      let params = {
        ...getClearOrderInquiryParams(),
        page: activePage,
        limit: dataPerPage
      };
      let orderInqueryData = await getNotiOrderInquiryList(params);
      const dataList = orderInqueryData.dataList;

      // new noti list for check load more
      const firebaseNotiList = await firebaseGetNotificationLoadMore();

      // concat old noti and order inquery
      let newNotiList = [...notiList, ...dataList];
      this.setState({
        notiList: newNotiList
      }, () => {
        const orderInquiryParams = orderInqueryData.params;
        const totalData = orderInquiryParams.totalData;
        const isLoadMoreInquery = activePage < Math.ceil(totalData / dataPerPage);
        const isLoadMoreNoti = firebaseNotiList.length > 0;
        console.log('isLoadMoreInquery', isLoadMoreInquery)
        console.log('isLoadMoreNoti', isLoadMoreNoti)
        this.loadMore.handleEnableLoadMore(isLoadMoreInquery || isLoadMoreNoti)
      })
    },
    handleNotificationDataList: (dataList = []) => {
      const {
        isOpenNoti,
      } = this.state;
      if (isOpenNoti) {
        return _.orderBy(dataList, 'createdDate', 'desc').map(e => {
          switch (e.type) {
            case DATA.NOTIFICATION_TYPE.COIL_TRANSFER:
              e.displayIcon = ICONS['ic-notification-barcode.svg'];
              e.onClick = () => { redirect(`${ROUTE_PATH.COIL_APPROVAL.LINK}`) }
              break;
            default:
              // old order inquery;
              e.displayIcon = ICONS['ic-notification-order-inquiry.svg'];
              e.title = e.subject;
              e.description = `ID: ${e.idWithPrefix}, ${e.displaySubmitAt}`;
              e.onClick = () => { redirect(`${ROUTE_PATH.ORDER_INQUIRY.LINK}/${e.id}`) }
              break;
          }
          return e;
        });
      }
      else {
        return [];
      }
    }
  }

  loadMore = {
    handleLoadMore: () => {
      const {
        isEnableLoadMore,
        activePage,
        isOpenNoti
      } = this.state
      if (isEnableLoadMore && isOpenNoti) {
        this.setState({
          activePage: activePage + 1,
          isEnableLoadMore: false
        }, () => {
          this.topbar.getNotificationList();
        })
      }
    },
    handleEnableLoadMore: (isEnable) => {
      this.setState({
        isEnableLoadMore: isEnable,
        isMoreData: isEnable
      })
    },
    renderLoader() {
      return (
        <div className="loader" key={0}>Loading ...</div>
      )
    }
  }

  render() {
    const {
      data,
      languageRedux,
      isNewNoti,
      firebaseNotiList,
    } = this.props;
    const {
      isMoreData,
      isMobileScreenSize,
      notiList,
    } = this.state;
    return (
      <CmsLayout.Topbar
        topbarPaddingRight='22'
        topbarPaddingLeft='22'
        topbarBgColor={VARIABLES.COLORS.WHITE}
      >
        <Section
          // nonFluid
          flex='none'
          width='204'
          onClick={() => {
            redirect(ROUTE_PATH.HOME.LINK);
          }}
        >
          <Image ui='logoCms' />
        </Section>
        <Section
          justify='center'
          paddingHorizontal='30'
          className={'is-breakpoint-display-none '}
        >
          <ShapeContainer
            ui='companyName'
            companyName={`${data.company}`}
          />
        </Section>
        <Section
          nonFluid
          flex='none'
        >
          <Grid
            gutter='20'
            justify='flex-end'
          >
            <Grid.Column>
              <AccordionControl dropdown dropdownNotification={isMobileScreenSize} >
                <Accordion>
                  <AccordionItem>
                    <AccordionItemTitle>
                      {
                        isMobileScreenSize ?
                          <Section
                            height='44'
                            align='center'
                          >
                            <Image
                              widthRatio='24'
                              widthRatioUnit='px'
                              heightRatio='24'
                              heightRatioUnit='px'
                              src={ICONS['ic-hamberger-menu.svg']}
                            />
                          </Section>
                          :
                          <ShapeContainer
                            ui='user'
                            srcUserImage={data.imageUrl ? data.imageUrl : ICONS['ic-placeholder-avatar.svg']}
                          />
                      }
                    </AccordionItemTitle>
                    <AccordionItemBody>
                      <ShapeContainer
                        dropdown
                        width='200'
                        padding='20'
                        bgColor={VARIABLES.COLORS.WHITE}
                        borderRadius='5'
                      >
                        {
                          isMobileScreenSize ?
                            <React.Fragment>
                              <Section
                                justify='space-between'
                                align='center'
                                paddingBottom='8'
                                spacingBottom='8'
                                onClick={() => {
                                  redirect(ROUTE_PATH.HOME.LINK);
                                }}
                              >
                                <Text
                                  regular14
                                  singleLine
                                  color={VARIABLES.COLORS.BLACK}
                                  top='1'
                                >
                                  {getLanguage('sideMenu.home', '')}
                                </Text>
                                <Image ui='iconArrowGoto' />
                              </Section>
                              <Section
                                justify='space-between'
                                align='center'
                                paddingBottom='18'
                                spacingBottom='18'
                                borderBottomWidth='1'
                                borderBottomStyle='solid'
                                borderBottomColor={VARIABLES.COLORS.GRAY_8}
                                onClick={() => {
                                  redirect(ROUTE_PATH.COIL_TRANSFER_BARCODE_SCANNER.LINK);
                                }}
                              >
                                <Text
                                  regular14
                                  singleLine
                                  color={VARIABLES.COLORS.BLACK}
                                  top='1'
                                >
                                  {getLanguage('sideMenu.barcodeScan', '')}
                                </Text>
                                <Image ui='iconArrowGoto' />
                              </Section>
                            </React.Fragment>
                            :
                            <React.Fragment>
                              {isRolePermission(PERMISSION.SIDE_MENU.SETTINGS.ALL) &&
                                <Section
                                  justify='space-between'
                                  align='center'
                                  paddingBottom='18'
                                  spacingBottom='18'
                                  borderBottomWidth='1'
                                  borderBottomStyle='solid'
                                  borderBottomColor={VARIABLES.COLORS.GRAY_8}
                                  onClick={() => {
                                    redirect(ROUTE_PATH.AD_INFORMATION.LINK);
                                  }}
                                >
                                  <Text
                                    regular14
                                    singleLine
                                    color={VARIABLES.COLORS.BLACK}
                                    top='1'
                                  >
                                    {getLanguage('topMenu.myProfile', '')}
                                  </Text>
                                  <Image ui='iconArrowGoto' />
                                </Section>
                              }
                              {data.role && data.role.toLowerCase() === ENUM.USER_ROLE.SALES_PERSON.toLowerCase() &&
                                <Section
                                  justify='space-between'
                                  align='center'
                                  paddingBottom='18'
                                  spacingBottom='18'
                                  borderBottomWidth='1'
                                  borderBottomStyle='solid'
                                  borderBottomColor={VARIABLES.COLORS.GRAY_8}
                                  onClick={() => {
                                    redirect(ROUTE_PATH.CHANGE_PASSWORD.LINK);
                                  }}
                                >
                                  <Text
                                    regular14
                                    singleLine
                                    color={VARIABLES.COLORS.BLACK}
                                    top='1'
                                  >
                                    {getLanguage('field.buttonChangePassword', '')}
                                  </Text>
                                  <Image ui='iconArrowGoto' />
                                </Section>
                              }
                            </React.Fragment>
                        }

                        <Section spacingBottom='18'>
                          <Text
                            regular14
                            singleLine
                            color={VARIABLES.COLORS.BLACK}
                            top='1'
                          >
                            {getLanguage('topMenu.chooseLanguage.title', '')}
                          </Text>
                        </Section>
                        <Section spacingBottom='15'>
                          <ChooseLanguage
                            checked={languageRedux === DATA.LANGUAGE.THAI}
                            srcFlag={ICONS['ic-flag-language-th.png']}
                            label={getLanguage('topMenu.chooseLanguage.thai', '')}
                            name='language'
                            onChange={() => {
                              changeLanguage(DATA.LANGUAGE.THAI);
                              window.location.reload();
                            }}
                          />
                        </Section>
                        <Section spacingBottom='15'>
                          <ChooseLanguage
                            checked={languageRedux === DATA.LANGUAGE.ENGLISH}
                            srcFlag={ICONS['ic-flag-language-en.png']}
                            label={getLanguage('topMenu.chooseLanguage.english', '')}
                            name='language'
                            onChange={() => {
                              changeLanguage(DATA.LANGUAGE.ENGLISH);
                              window.location.reload();
                            }}
                          />
                        </Section>
                        <Section spacingBottom='15'>
                          <ChooseLanguage
                            checked={languageRedux === DATA.LANGUAGE.INDONESIA}
                            srcFlag={ICONS['ic-flag-language-in.png']}
                            label={getLanguage('topMenu.chooseLanguage.indonesia', '')}
                            name='language'
                            onChange={() => {
                              changeLanguage(DATA.LANGUAGE.INDONESIA);
                              window.location.reload();
                            }}
                          />
                        </Section>
                        <Section spacingBottom='15'>
                          <ChooseLanguage
                            checked={languageRedux === DATA.LANGUAGE.VIETNAM}
                            srcFlag={ICONS['ic-flag-language-vt.png']}
                            label={getLanguage('topMenu.chooseLanguage.vietnam', '')}
                            name='language'
                            onChange={() => {
                              changeLanguage(DATA.LANGUAGE.VIETNAM);
                              window.location.reload();
                            }}
                          />
                        </Section>
                        <Section spacingBottom='15'>
                          <ChooseLanguage
                            checked={languageRedux === DATA.LANGUAGE.MALAYSIA}
                            srcFlag={ICONS['ic-flag-language-my.png']}
                            label={getLanguage('topMenu.chooseLanguage.malaysia', '')}
                            name='language'
                            onChange={() => {
                              changeLanguage(DATA.LANGUAGE.MALAYSIA);
                              window.location.reload();
                            }}
                          />
                        </Section>
                        <Section>
                          <ChooseLanguage
                            checked={languageRedux === DATA.LANGUAGE.LAOS}
                            srcFlag={ICONS['ic-flag-language-la.png']}
                            label={getLanguage('topMenu.chooseLanguage.laos', '')}
                            name='language'
                            onChange={() => {
                              changeLanguage(DATA.LANGUAGE.LAOS);
                              window.location.reload();
                            }}
                          />
                        </Section>
                      </ShapeContainer>
                    </AccordionItemBody>
                  </AccordionItem>
                </Accordion>
              </AccordionControl>
            </Grid.Column>
            <Grid.Column>
              <Section
                height='44'
                align='center'
                className={'is-breakpoint-display-none '}
              >
                <Section
                  height='30'
                  align='center'
                  paddingHorizontal='20'
                  borderRightWidth='1'
                  borderRightStyle='solid'
                  borderRightColor={VARIABLES.COLORS.GRAY_7}
                  borderLeftWidth='1'
                  borderLeftStyle='solid'
                  borderLeftColor={VARIABLES.COLORS.GRAY_7}
                >
                  <AccordionControl dropdown dropdownNotification
                    onClick={this.topbar.onOpenNoti}
                  >
                    <Accordion>
                      <AccordionItem>
                        <AccordionItemTitle>
                          <Section
                            width='25'
                            justify='center'
                          >
                            {isNewNoti ?
                              <Image ui='iconNotificationActive' />
                              :
                              <Image ui='iconNotification' />
                            }
                          </Section>
                        </AccordionItemTitle>
                        <AccordionItemBody>
                          <ShapeContainer
                            dropdown
                            width='310'
                            paddingTop='5'
                            paddingBottom='4'
                            paddingHorizontal='5'
                            bgColor={VARIABLES.COLORS.WHITE}
                            borderRadius='5'
                          >
                            <Section
                              scroll
                              notification
                            >
                              <InfiniteScroll
                                pageStart={0}
                                hasMore={isMoreData}
                                loadMore={this.loadMore.handleLoadMore}
                                loader={this.loadMore.renderLoader()}
                                useWindow={false}
                              >
                                {this.topbar.handleNotificationDataList([...firebaseNotiList, ...notiList]).map((e, i) => {
                                  return (
                                    <ShapeContainer ui='notificationList'
                                      key={i}
                                      notificationActive={false} // After click change to 'false'
                                      notificationSrcIcon={e.displayIcon}
                                      notificationTitle={e.title}
                                      notificationDescription={e.description}
                                      onClick={(ev) => {
                                        ev.stopPropagation();
                                        ev.preventDefault();
                                        e.onClick();
                                      }}
                                    />
                                  )
                                })}
                              </InfiniteScroll>
                            </Section>
                          </ShapeContainer>
                        </AccordionItemBody>
                      </AccordionItem>
                    </Accordion>
                  </AccordionControl>
                </Section>
              </Section>
            </Grid.Column>
            <Grid.Column>
              <Section
                height='44'
                align='center'
              >
                <Image ui='iconLogout'
                  onClick={() => {
                    logout();
                  }}
                />
              </Section>
            </Grid.Column>
          </Grid>
        </Section>
      </CmsLayout.Topbar >
    )
  }
}

const mapStateToProps = (state) => {
  const mainRedux = state.mainRedux;
  const dataRedux = state.dataRedux;

  const firebaseNoti = dataRedux.firebaseNoti;
  const firebaseNotiList = firebaseNoti.dataList;
  const dataNoti = firebaseNoti.data;

  const isNewOrderInquery = dataNoti.orderInquiry.isNew;
  const isNewNoti = firebaseNotiList.filter(e => e.isNew).length > 0 || isNewOrderInquery;

  return {
    data: mainRedux[MAIN_REDUX_STATE_KEY.USER_AUTH],
    languageRedux: mainRedux.language,
    firebaseNoti,
    firebaseNotiList,
    isNewNoti,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
  }
}

export const TopbarContainerConnected = connect(
  mapStateToProps,
  mapDispatchToProps
)(TopbarContainer)