import React from 'react';
// import {
//   connect,
// } from 'react-redux';
import {
  Section,
  Grid,
  Modal,
  Text,
  Button,
  Table,
  SelectControl,
} from './../../../components'
import {
  VARIABLES,
} from './../../../themes'
import { getLanguage } from '../../../helpers/functions/language';
import _ from 'lodash';

export class ModalSegmentInformationContainer extends React.Component {

  constructor(props) {
    super(props);
    const filterList = _.uniqBy(props.dataList.map(e => {
      return {
        label: e.name,
        value: e.name,
      }
    }), (e) => e.value);
    this.state = {
      filterList: [{ label: 'All Segment', value: undefined }, ...filterList],
      filterValue: undefined,
      sortBy: 'name',
      sortType: 'desc',
      sortIcon: {
        name: true,
        description: true,
        subSegmentName: true,
        subSegmentDesc: true,
      }
    }
  }

  modalSegmentInformation = {
    getDataList: () => {
      const {
        dataList,
      } = this.props;
      const {
        filterValue,
        sortBy,
        sortType,
      } = this.state;
      return _.orderBy(dataList.filter(e => {
        if (filterValue) {
          return filterValue.value === undefined || e.name === filterValue.value;
        }
        return true;
      }), [sortBy], [sortType])
    }
  }

  input = {
    onChangeFilter: (selected) => {
      this.setState({
        filterValue: selected,
      })
    },
    onClickSort: (target) => {
      const {
        sortBy,
        sortType,
        sortIcon,
      } = this.state;
      let newSortBy = sortBy;
      let newSortType = sortType;
      Object.keys(sortIcon).forEach(key => {
        sortIcon[key] = true;
      });

      if (target === sortBy) {
        newSortType = newSortType === 'desc' ? 'asc' : 'desc';
        if (newSortType === 'asc') {
          sortIcon[target] = false;
        }
      }
      else {
        newSortBy = target;
        newSortType = 'desc';
      }
      this.setState({
        sortBy: newSortBy,
        sortType: newSortType,
        sortIcon,
      })
    }
  }

  render() {
    const {
      isOpenModal,
      onClickClose,
      primaryTitle,
      message,
      dataList
    } = this.props;
    const {
      filterList,
      filterValue,
      sortBy,
      sortType,
      sortIcon,
    } = this.state;

    return (
      <Modal
        open={isOpenModal}
        scroll
        // enableOverlayClose={false}
        width={VARIABLES.MODAL.WIDTHS.W_1145}
        overlayColor={VARIABLES.COLORS.OVERLAY_BLACK_1}
        spacing={VARIABLES.MODAL.CONTAINER.C_1.SPACING}
        transition={VARIABLES.TRANSITIONS.DEFAULT}
        transformStart={VARIABLES.MODAL.CONTAINER.C_1.TRANSFORM_START}
        transformEnd={VARIABLES.MODAL.CONTAINER.C_1.TRANSFORM_END}
        onClickClose={onClickClose}
      >
        <Modal.Section
          paddingVertical='25'
          paddingHorizontal='35'
          bgColor={VARIABLES.COLORS.WHITE}
          borderBottomWidth='1'
          borderBottomStyle='solid'
          borderBottomColor={VARIABLES.COLORS.GRAY_8}
          borderTopRightRadius={VARIABLES.MODAL.HEADER.H_1.BORDER_TOP_RIGHT_RADIUS}
          borderTopLeftRadius={VARIABLES.MODAL.HEADER.H_1.BORDER_TOP_LEFT_RADIUS}
        >
          <Text regular20 singleLine color={VARIABLES.COLORS.BLACK}>{primaryTitle}</Text>
        </Modal.Section>
        <Modal.Section
          paddingVertical='40'
          paddingHorizontal='35'
          bgColor={VARIABLES.COLORS.WHITE}
        >
          <Section spacingBottom='30'>
            <Grid gutter='15' justify='flex-end' align='center'>
              <Grid.Column>
                <Text regular14 color={VARIABLES.COLORS.BLACK}>{getLanguage('field.filter', '')}</Text>
              </Grid.Column>
              <Grid.Column>
                <SelectControl
                  name=''
                  isSearchable={false}
                  width='250'
                  placeholder={getLanguage('field.segmentSelectPlaceholder', '')}
                  value={filterValue}
                  options={filterList}
                  onChange={this.input.onChangeFilter}
                />
              </Grid.Column>
            </Grid>
          </Section>
          <Table striped>
            <Table.Section>
              <Table.Row>
                <Table.HeadColumn
                  maxWidth={VARIABLES.TABLE.SEGMENT_INFORMATION.COLUMN_WIDTHS.SEGMENT_NAME}
                  minWidth={VARIABLES.TABLE.SEGMENT_INFORMATION.COLUMN_WIDTHS.SEGMENT_NAME}
                  sortAscending={sortIcon.name}
                  onClickSort={() => { this.input.onClickSort('name') }}
                >
                  {getLanguage('warrantyManage.form.segment.table.header.segment')}
                </Table.HeadColumn>
                <Table.HeadColumn
                  minWidth={VARIABLES.TABLE.SEGMENT_INFORMATION.COLUMN_WIDTHS.SEGMENT_DESCRIPTION}
                  sortAscending={sortIcon.description}
                  onClickSort={() => { this.input.onClickSort('description') }}
                >
                  {getLanguage('warrantyManage.form.segment.table.header.description')}
                </Table.HeadColumn>
                <Table.HeadColumn
                  minWidth={VARIABLES.TABLE.SEGMENT_INFORMATION.COLUMN_WIDTHS.SUB_SEGMENT_NAME}
                  sortAscending={sortIcon.subSegmentName}
                  onClickSort={() => { this.input.onClickSort('subSegmentName') }}
                >
                  {getLanguage('warrantyManage.form.segment.table.header.subSegment')}
                </Table.HeadColumn>
                <Table.HeadColumn
                  minWidth={VARIABLES.TABLE.SEGMENT_INFORMATION.COLUMN_WIDTHS.SUB_SEGMENT_DESCRIPTION}
                  sortAscending={sortIcon.subSegmentDesc}
                  onClickSort={() => { this.input.onClickSort('subSegmentDesc') }}
                >
                  {getLanguage('warrantyManage.form.segment.table.header.subSegmentDescription')}
                </Table.HeadColumn>

              </Table.Row>
            </Table.Section>
          </Table>
          <Section scrollOverlay height='294'>
            <Table striped>
              <Table.Section>
                {this.modalSegmentInformation.getDataList().map((e, i) => {
                  return (
                    <Table.Row key={i}>
                      <Table.BodyColumn
                        info
                        maxWidth={VARIABLES.TABLE.SEGMENT_INFORMATION.COLUMN_WIDTHS.SEGMENT_NAME}
                        minWidth={VARIABLES.TABLE.SEGMENT_INFORMATION.COLUMN_WIDTHS.SEGMENT_NAME}
                        title={e.name}
                      >
                        {e.name || '-'}
                      </Table.BodyColumn>
                      <Table.BodyColumn
                        info
                        minWidth={VARIABLES.TABLE.SEGMENT_INFORMATION.COLUMN_WIDTHS.SEGMENT_DESCRIPTION}
                        title={e.description}
                      >
                        {e.description || '-'}
                      </Table.BodyColumn>
                      <Table.BodyColumn
                        info
                        minWidth={VARIABLES.TABLE.SEGMENT_INFORMATION.COLUMN_WIDTHS.SUB_SEGMENT_NAME}
                        title={e.subSegmentName}
                      >
                        {e.subSegmentName || '-'}
                      </Table.BodyColumn>
                      <Table.BodyColumn
                        info
                        minWidth={VARIABLES.TABLE.SEGMENT_INFORMATION.COLUMN_WIDTHS.SUB_SEGMENT_DESCRIPTION}
                        title={e.subSegmentDesc}
                      >
                        {e.subSegmentDesc || '-'}
                      </Table.BodyColumn>
                    </Table.Row>
                  )
                })}
              </Table.Section>
            </Table>
          </Section>
        </Modal.Section>
        <Modal.Section
          paddingTop='15'
          paddingBottom='20'
          paddingHorizontal='35'
          borderTopWidth={VARIABLES.MODAL.FOOTER.F_1.BORDER_TOP_WIDTH}
          borderTopStyle={VARIABLES.MODAL.FOOTER.F_1.BORDER_TOP_STYLE}
          borderTopColor={VARIABLES.COLORS.GRAY_8}
          borderBottomRightRadius={VARIABLES.MODAL.FOOTER.F_1.BORDER_BOTTOM_RIGHT_RADIUS}
          borderBottomLeftRadius={VARIABLES.MODAL.FOOTER.F_1.BORDER_BOTTOM_LEFT_RADIUS}
        >
          <Grid
            gutter='15'
            justify='flex-end'
          >
            <Grid.Column>
              <Button
                gray
                width={VARIABLES.BUTTON.WIDTHS.W_135}
                name='Close'
                onClick={onClickClose}
              />
            </Grid.Column>
          </Grid>
        </Modal.Section>
      </Modal>
    )
  }
}
