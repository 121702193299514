import { isValidResponse } from "../functions/validation";
import { storeDispatch, storeGetState } from "../../stores";
import { dataCreators, DATA_REDUX_STATE_KEY } from "../../actions/dataAction";
import { cmsUserManualController } from "../../apiService/apiController/cmsUserManualService";
import { ModelCMSUserManual } from "../../models/ModelCMSUserManual";
import { ENUM } from "../constants/enum";
import { handleSortParams, handleTrimParams } from "../functions/data";
import { getUserAuthRedux } from "../functions/auth";

export const getCMSUserManualList = async (params = {}) => {
  const userAuth = getUserAuthRedux();
  let currentParams = storeGetState().dataRedux.cmsUserManualParams;
  let newParams = handleTrimParams({
    ...currentParams,
    ...params,
    app: ENUM.CMS_TYPE.TRUE_BLUE,
    available_country: userAuth.addressType,
  });
  if (!newParams.sort) {
    newParams.sort = 'id desc';
  }
  else {
    newParams = handleSortParams(currentParams, newParams);
  }
  const cmsUserManualService = cmsUserManualController();
  const res = await cmsUserManualService.searchUserManual(newParams);
  if (isValidResponse(res)) {
    const dataList = res.data.map(e => new ModelCMSUserManual(e));
    newParams.activePage = newParams.page;
    newParams.totalData = res.total_items;
    console.log('dataList', res.data, dataList, newParams);
    storeDispatch(dataCreators.setDataRedux(DATA_REDUX_STATE_KEY.CMS_USER_MANUAL_LIST, dataList));
    storeDispatch(dataCreators.setDataRedux(DATA_REDUX_STATE_KEY.CMS_USER_MANUAL_PARAMS, newParams));
  }
}

export const getClearCMSUserManualParams = () => {
  return {
    name: '',
    sort: '',
  }
}