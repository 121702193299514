import apiService from '../apiService'

export const saleTransController = (configService = {}) => {
  const service = apiService(configService)
  return {
    searchSalesTransaction: (params) => {
      return service.post(`/salestrans/search`, params)
    },
    createSalesTransaction: (params) => {
      return service.post(`/salestrans`, params)
    },
    // updateSalePersonById: (params) => {
    //   return service.put(`/salestrans/${params.id}`, params)
    // },
    deleteSalesTransactionById: (params) => {
      return service.delete(`/salestrans/${params.id}`, params)
    },
    // saleTransPreSaleTransactionList: (params) => {
    //   return service.post(`/salestrans/pre-sale-transaction`, params);
    // },
    saleTransProjectDocumentLinkList: (params) => {
      return service.post(`/salestrans/pre-sale-transaction/link`, params);
    },
  }
}
