import _ from 'lodash';
import React from 'react';
import {
  Breadcrumb, Button, Field, Grid, Image, Section, SelectControl, ShapeContainer, Table, Text, TitlePage, TitleSection
} from '../../components';
import { ROUTE_PATH } from '../../helpers/constants/route';
import { getLanguage } from '../../helpers/functions/language';
import { downloadPathUrl, toast } from '../../helpers/functions/main';
import { getRouteData, getRouteParams, handleBeforeLeave, redirect, redirectData, setSideMenuActive } from '../../helpers/functions/route';
import { ICONS, VARIABLES } from '../../themes';
import variables from '../../themes/styles/bases/variables';

import { projectReferenceController } from '../../apiService/apiController/projectReferenceService';
import { FIREBASE_PUBLIC_STORAGE_PATH, FIREBASE_STORAGE } from '../../config/config';
import { ModalConfirmContainer, ModalPreviewImageContainer, ModalPreviewPdfContainer } from '../../containers/ModalContainer';
import { DATA } from '../../helpers/constants/data';
import { ENUM } from '../../helpers/constants/enum';
import { firebaseGetDownloadPath } from '../../helpers/firebases/firebaseStorage';
import { getBlobtoPath } from '../../helpers/functions/addon';
import { getUserAuthRedux } from '../../helpers/functions/auth';
import { imageUrltoBase64 } from '../../helpers/functions/data';
import { isShowLoading } from '../../helpers/functions/main';
import { isValidResponse } from '../../helpers/functions/validation';
import { getProjectReference } from '../../helpers/services/projectReference';
import { ModelProjectReferenceDetailFile } from '../../models/ModelProjectReference';
import {
  connect
} from 'react-redux';

class ButtonDelete extends React.Component {
  constructor(props) {
    super(props);
    this.state = {}
  }

  buttonDelete = {
    isShowDeleteButton: () => {
      const {
        userAuth
      } = this.props;
      let isShow = false;
      const positionUpperManagerList = [
        DATA.SALE_PERSON_POSITION.OWNER.ID,
        DATA.SALE_PERSON_POSITION.MANAGER.ID
      ]
      if (positionUpperManagerList.indexOf(userAuth.position) >= 0) {
        isShow = true;
      }
      return isShow;
    },
  }

  render() {
    const {
      children
    } = this.props;
    return (
      this.buttonDelete.isShowDeleteButton() ?
        children
        :
        null
    )
  }
}

const mapStateToProps = (state) => {
  const mainRedux = state.mainRedux;
  return {
    userAuth: mainRedux.userAuth,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
  }
}

const ButtonDeleteConnected = connect(mapStateToProps)(ButtonDelete)


export class ProjectDocumentReferenceManageScreen extends React.Component {
  constructor(props) {
    super(props);
    setSideMenuActive(props);
    const dataId = parseInt(getRouteParams(props, 'id'));
    let routeData = getRouteData(props, 'data');

    if (!routeData) {
      redirect(ROUTE_PATH.PROJECT_REFERENCE.LINK);
    }
    routeData = routeData || {};
    console.log('routeData', routeData);

    const viewTypeSelectOptionList = [
      { value: 'viewList', label: getLanguage('projectDocument.label.viewList', '') },
      { value: 'viewIcon', label: getLanguage('projectDocument.label.viewIcon', '') }
    ]
    const userAuth = getUserAuthRedux();
    const preTransData = routeData.data;
    const data = {
      preSalesTransId: preTransData.preSalesTransId,
      referenceDetailList: [],
      referenceName: '',
      // projectNameSelected: undefined,
      referenceProjectName: preTransData.projectName,
      referenceDesc: '',
    };

    this.state = {
      userAuth,
      data,
      dataOld: _.cloneDeep(data),
      customerData: routeData.customerData,
      segmentSelected: routeData.segmentSelected,
      preTransData,
      // referenceType: routeData.referenceType,
      // isWarranty: routeData.referenceType === DATA.PROJECT_REFERENCE_TYPE.WARRANTY,
      dataId,
      oldDataId: dataId,
      isEdit: !!dataId,

      // warrantySelectOptionList: [],
      // projectNameSelectOptionList: [],
      viewTypeSelectOptionList,
      viewTypesSelected: viewTypeSelectOptionList[0],

      currentSort: 'referenceDtFileName',
      sortIcon: {
        referenceDtFileName: true,
        displayCreatedDate: true,
      },

      previewData: undefined,
      isShowPreviewPdf: false,
      isShowPreviewImage: false,

      isShowConfirmLeaveModal: false,
      navigatePathName: '',

      isOpenConfirmDeleteReferenceDetailModal: false,
      dataDeleteList: [],
    };
  }

  componentDidMount() {
    const {
      isEdit,
    } = this.state;
    if (isEdit) {
      this.projectDocumentReferenceManage.getProjectReference(() => {
        const {
          data,
        } = this.state;
        this.setState({
          dataOld: _.cloneDeep(data)
        })
      });
    }
  }

  projectDocumentReferenceManage = {
    onClickBackProjectDocumentManage: () => {
      const {
        data,
      } = this.state;
      redirectData(`${ROUTE_PATH.PROJECT_DOCUMENT_MANAGE.LINK}/${data.preSalesTransId}`, {
        data: {
          tabType: DATA.PROJECT_DOCUMENT_TAB_TYPE_LIST[5]
        }
      });
    },
    getProjectReference: async (callback = () => { }) => {
      const {
        dataId,
        data,
      } = this.state;
      const params = {
        reference_id: dataId,
      }
      const result = await getProjectReference(params);
      if (result) {
        const newData = {
          ...data,
          ...result,
        }
        this.setState({
          data: newData,
          dataOld: _.cloneDeep(newData),
        }, () => {
          callback();
        })
      }
    },
    onClickSortReferenceDetail: (target) => {
      const {
        sortIcon,
        data,
        currentSort,
      } = this.state;

      Object.keys(sortIcon).forEach(key => {
        if (key === target) {
          sortIcon[key] = currentSort === target ? !sortIcon[key] : true;
        }
        else {
          sortIcon[key] = true;
        }
      });

      const sortType = sortIcon[target] ? 'desc' : 'asc';

      data.referenceDetailList = _.orderBy(data.referenceDetailList, [target], [sortType])
      this.setState({
        sortIcon,
        currentSort: target,
        data,
      })
    },
    // onClickDeleteReferenceDetail: (item) => {
    //   const {
    //     data,
    //   } = this.state;
    //   data.referenceDetailList = data.referenceDetailList.filter(e => {
    //     return e.id !== item.id;
    //   })
    //   this.setState({
    //     data,
    //   })
    // },
    handleDeleteReferenceDetail: (callbackSuccess = () => { }) => {
      const {
        data,
      } = this.state;
      data.referenceDetailList = data.referenceDetailList.filter(e => {
        return !e.isSelected;
      })
      this.setState({
        data,
      }, () => {
        toast.success(getLanguage('message.deleteReferenceFileSuccess', ''));
        callbackSuccess();
      })
    },
    onClickUploadReferenceDetail: () => {
      this.uploadFileRef.click();
    },
    isDisableSaveButton: () => {
      const {
        data,
      } = this.state;
      let isDisable = false;
      if (
        data.referenceName.length === 0
        // this.projectDocumentReferenceManage.getADReferenceDetailList().length === 0
      ) {
        isDisable = true;
      }
      return isDisable
    },
    onClickSave: async () => {
      const {
        data,
        // referenceType,
        userAuth,
        isEdit,
        dataId,
        preTransData,
        oldDataId,
        customerData,
        segmentSelected,
      } = this.state;
      isShowLoading(true);
      const configService = {
        isShowLoading: false,
      }
      const projectReferenceService = projectReferenceController(configService);
      let params = {
        reference_name: data.referenceName,
        reference_desc: data.referenceDesc,
      }
      if (isEdit) {
        const referenceDetailList = [];
        let cnt = 0;

        const handleUpdate = async () => {
          params.reference_id = dataId;
          params.reference_detail = referenceDetailList;
          params.updated_by = userAuth.name;
          const res = await projectReferenceService.updateProjectReference(params);
          if (isValidResponse(res)) {
            isShowLoading(false);
            this.setState({
              dataOld: _.cloneDeep(data),
            }, () => {
              this.projectDocumentReferenceManage.onClickBackProjectDocumentManage();
            });
            if (oldDataId) {
              toast.success(getLanguage('message.referenceSuccessfullyEdited', ''));
            }
            else {
              toast.success(getLanguage('message.referenceSuccessfullyCreated', ''));
            }
          } else {
            isShowLoading(false);
          }
        }

        if (data.referenceDetailList.length) {
          data.referenceDetailList.forEach((e) => {
            let filePath = e.filePath;
            if (e.file) {
              const filename = `${dataId}_${new Date().getTime()}_${e.referenceDtFileName}`;
              const pathUpload = `${ENUM.UPLOAD_PATH.PROJECT_DOCUMENT}/${preTransData.preSalesTransId}${ENUM.UPLOAD_PATH_FOLDER.PROJECT_DOCUMENT_REFERENCE}`;
              getBlobtoPath(e.file, filename, pathUpload);
              filePath = `${FIREBASE_STORAGE}${pathUpload}/${filename}`;
              e.isAdminCreate = false;
            }
            referenceDetailList.push({
              reference_dt_file_name: e.referenceDtFileName,
              reference_dt_path: filePath,
              is_admin: e.isAdminCreate,
            });
            cnt++;
            if (cnt === data.referenceDetailList.length) {
              handleUpdate();
            }
          })
        }
        else {
          handleUpdate();
        }
      }
      else {
        params.pre_sales_trans_project_name = preTransData.projectName;
        params.sub_segment_id = segmentSelected.subSegmentId;
        params.pre_sales_trans_id = data.preSalesTransId;
        params.reference_project_name = data.referenceProjectName || '';
        params.auth_deal_id = userAuth.id;
        params.created_by = userAuth.name;
        params.customer_id = customerData.id;
        params.reference_note = '';
        const res = await projectReferenceService.createProjectReference(params);
        if (isValidResponse(res)) {
          this.setState({
            data,
            isEdit: true,
            dataId: res.reference_id
          }, () => {
            this.projectDocumentReferenceManage.onClickSave();
          })
        }
        else {
          isShowLoading(false);
          toast.error(res.message);
        }
      }
    },
    getADReferenceDetailList: () => {
      const {
        data,
      } = this.state;
      return data.referenceDetailList.filter(e => !e.isAdminCreate)
    },
    isReferenceDetailSelectAll: () => {
      const dataList = this.projectDocumentReferenceManage.getADReferenceDetailList();
      return dataList.length &&
        dataList.filter(e => e.isSelected).length === dataList.length;
    },
    onClickDownloadAllSelectedFile: () => {
      const {
        data,
      } = this.state;
      const dataList = data.referenceDetailList.filter(e => e.isSelected);
      if (dataList.length) {
        dataList.forEach(e => {
          this.modal.onClickDownload(e);
        })
      }
    },
    onClickDeleteAllSelectedFile: () => {
      const {
        data,
      } = this.state;
      const dataList = data.referenceDetailList.filter(e => e.isSelected);
      if (dataList.length) {
        this.setState({
          isOpenConfirmDeleteReferenceDetailModal: true,
        })
      }
    },
  }

  input = {
    onChangeUploadFile: (ev) => {
      const {
        data,
      } = this.state;
      const files = ev.target.files;
      if (files.length) {
        for (let i = 0; i < files.length; i++) {
          const file = files[i];
          console.log('file', file)
          if (file.size > 20 * 1024 * 1024) {
            toast.error(getLanguage('message.maximumFileSize20MB', ''));
          }
          else {
            const referenceFile = new ModelProjectReferenceDetailFile({
              reference_dt_file_name: file.name,
              reference_dt_path: '',
              created_date: new Date(),
            }, `${new Date().getTime()}_${i}`);

            data.referenceDetailList.push({
              ...referenceFile,
              fileUrl: URL.createObjectURL(file),
              file,
            });
          }
        }

        this.setState({
          data,
        })
      }
      ev.target.value = '';
    },
    onChangeInput: (ev) => {
      const {
        data,
      } = this.state;
      const name = ev.target.name;
      const value = ev.target.value;
      data[name] = value;
      this.setState({
        data,
      })
    },
    onChangeSelectViewType: (item) => {
      this.setState({
        viewTypesSelected: item
      })
    },
    onChangeSelectReferenceDetailAll: () => {
      const {
        data,
      } = this.state;
      const isCheckAll = this.projectDocumentReferenceManage.isReferenceDetailSelectAll();
      data.referenceDetailList = data.referenceDetailList.map(e => {
        e.isSelected = !isCheckAll;
        return e
      })
      this.setState({
        data,
      })
    },
    onChangeSelectReferenceDetail: (item) => {
      const {
        data,
      } = this.state;
      data.referenceDetailList = data.referenceDetailList.map(e => {
        if (e.id === item.id) {
          e.isSelected = !e.isSelected;
        }
        return e
      })
      this.setState({
        data,
      })
    }
  }

  modal = {
    onClickPreviewModal: (item) => {
      if (item.isPdf) {
        this.setState({
          previewData: item,
          isShowPreviewPdf: true,
        })
      }
      else if (item.isImage) {
        this.setState({
          previewData: item,
          isShowPreviewImage: true,
        })
      }
      else {
        downloadPathUrl(item.fileUrl, item.referenceDtFileName);
      }
    },
    onClosePreviewModal: () => {
      this.setState({
        isShowPreviewPdf: false,
        isShowPreviewImage: false,
      })
    },
    onClickDownload: async (data) => {
      if (data.fileUrl.indexOf(FIREBASE_PUBLIC_STORAGE_PATH) === 0) {
        const fileUrl = await imageUrltoBase64(await firebaseGetDownloadPath(data.filePath));
        downloadPathUrl(fileUrl, data.referenceDtFileName);
      }
      else {
        downloadPathUrl(data.fileUrl, data.referenceDtFileName);
      }
    },
    onClickCancelDeleteReferenceDetail: () => {
      this.setState({
        isOpenConfirmDeleteReferenceDetailModal: false,
      })
    },
    onClickSubmitDeleteReferenceDetail: () => {
      this.projectDocumentReferenceManage.handleDeleteReferenceDetail(() => {
        this.modal.onClickCancelDeleteReferenceDetail();
      })
    },
    renderAlertBeforeLeave: (pathName) => {
      const {
        data,
        dataOld,
      } = this.state;
      console.log('renderAlertBeforeLeave', pathName, data, dataOld)
      this.setState({
        isShowConfirmLeaveModal: true,
        navigatePathName: pathName,
      })
    },
    onClickSubmitConfirmLeaveModal: () => {
      const {
        data,
        navigatePathName,
      } = this.state;
      this.setState({
        dataOld: _.cloneDeep(data),
      }, () => {
        if (navigatePathName === `${ROUTE_PATH.PROJECT_DOCUMENT_MANAGE.LINK}/${data.preSalesTransId}`) {
          this.projectDocumentReferenceManage.onClickBackProjectDocumentManage();
        }
        else {
          redirect(navigatePathName);
        }
      });
    },
    onCloseConfirmLeaveModal: () => {
      this.setState({
        isShowConfirmLeaveModal: false,
      })
    }
  }

  render() {
    const {
      // isWarranty,
      isEdit,
      data,
      customerData,
      // projectNameSelectOptionList,
      viewTypeSelectOptionList,
      viewTypesSelected,
      sortIcon,
      isShowPreviewPdf,
      isShowPreviewImage,
      previewData,
      preTransData,
      isShowConfirmLeaveModal,
      // warrantySelectOptionList,
      isOpenConfirmDeleteReferenceDetailModal,
      dataDeleteList,
    } = this.state;

    console.log('data', data);

    return (
      <React.Fragment>
        {handleBeforeLeave(this, this.modal.renderAlertBeforeLeave)}
        <Breadcrumb>
          <Breadcrumb.List
            link
            name={getLanguage("preSaleDocumentary.breadcrumb1", "")}
            onClick={() => {
              redirect(ROUTE_PATH.HOME.LINK);
            }}
          />
          <Breadcrumb.List name="/" />
          <Breadcrumb.List
            link
            name={getLanguage('projectDocument.breadcrumb2', '')}
            onClick={this.projectDocumentReferenceManage.onClickBackProjectDocumentManage}
          />
          <Breadcrumb.List name="/" />
          <Breadcrumb.List
            link
            name={customerData.fullname}
            onClick={this.projectDocumentReferenceManage.onClickBackProjectDocumentManage}
          />
          <Breadcrumb.List name="/" />
          <Breadcrumb.List
            link
            name={preTransData.projectName}
            onClick={this.projectDocumentReferenceManage.onClickBackProjectDocumentManage}
          />
          <Breadcrumb.List name="/" />
          <Breadcrumb.List
            name={isEdit ? getLanguage('projectDocument.breadcrumb8', '') : getLanguage('projectDocument.breadcrumb6', '')}
          />
        </Breadcrumb>
        <Section ui="content">
          <Section spacingBottom="35">
            <TitlePage
              src={ICONS['ic-reference-circle.svg']}
              name={isEdit ?
                getLanguage('projectReference.label.editReference', '')
                :
                getLanguage('projectReference.label.createReference', '')}
            />
          </Section>
          <ShapeContainer
            ui="content"
            calcMinHeight='100vh - 274px'
            justify='space-between'
            padding={40}
          >
            <React.Fragment>
              <Section spacingBottom="45">
                <Section spacingBottom='40'>
                  <TitleSection
                    name={getLanguage('projectReference.label.referenceInformation', '')}

                  />
                </Section>
                <Section spacingBottom='40'>
                  <Grid>
                    <Grid.Column>
                      <Grid
                        gutter='46'
                      >
                        <Grid.Column>
                          <Section width={549}>
                            <Field name='referenceName'
                              fluid
                              required
                              // error
                              ui='blockLabelVertical'
                              label={getLanguage('projectReference.label.referenceName', '')}
                              placeholder={getLanguage('projectReference.field.placeholderInputReferenceName', '')}
                              value={data.referenceName}
                              onChange={this.input.onChangeInput}
                            />
                          </Section>
                        </Grid.Column>
                      </Grid>
                    </Grid.Column>
                  </Grid>
                </Section>
                <Section spacingBottom='46' width={549}>
                  <Field name='referenceDesc'
                    fluid
                    ui='blockLabelVerticalTextarea'
                    label={getLanguage('projectReference.label.description', '')}
                    placeholder={getLanguage('projectReference.field.placeholderInputDescription', '')}
                    value={data.referenceDesc}
                    onChange={this.input.onChangeInput}
                  />
                </Section>
                <Section nonFluid >
                  <Section spacingBottom='46'>
                    <Grid justify='space-between' gutter='40'>
                      <Grid.Column flex='1'>

                        <TitleSection
                          name={getLanguage('projectReference.label.referenceFile', '')}
                          message={getLanguage('projectReference.label.maximumFileSize', '')}
                        />
                      </Grid.Column>
                      <Grid.Column flex='none'>
                        <Grid justify='flex-end' gutter='16' >
                          <Grid.Column>
                            <SelectControl
                              className='is-view-type'
                              verticalLabel
                              filterBorder
                              width='128'
                              isSearchable={false}
                              value={viewTypesSelected}
                              onChange={this.input.onChangeSelectViewType}
                              options={viewTypeSelectOptionList}
                            />
                          </Grid.Column>
                          <Grid.Column>
                            <Button
                              blue
                              ui='frontIcon'
                              name={getLanguage('projectReference.button.buttonUploadFile', '')}
                              iconWidth='16'
                              iconHeight='16'
                              iconSrc={ICONS['ic-create.svg']}
                              onClick={this.projectDocumentReferenceManage.onClickUploadReferenceDetail}
                            />
                            <input style={{ display: 'none' }}
                              ref={r => this.uploadFileRef = r}
                              type='file'
                              multiple
                              onChange={this.input.onChangeUploadFile}
                              accept={'.jpg, .jpeg, .png, .gif, .doc, .docx, .xls, .xlsx, .ptt, .pttx, .pdf'}
                            />
                          </Grid.Column>
                        </Grid>
                      </Grid.Column>
                    </Grid>
                  </Section>
                  <Section spacingBottom='30'>
                    <ShapeContainer
                      fluid
                      bgColor={VARIABLES.COLORS.GRAY_1}
                      borderRadius='5'
                      padding='10'
                    >
                      <Grid gutter='15' gutterVertical='15' justify='flex-end'>
                        <Grid.Column>
                          <Section
                            justify='center'
                            align='center'
                            textAlign='center'
                            spacingTop='5'
                          >
                            <Field
                              ui='checkboxTick'
                              name='checkAll'
                              checkboxLabel={getLanguage('projectDocument.label.allReferenceFile', '')}
                              checked={this.projectDocumentReferenceManage.isReferenceDetailSelectAll()}
                              onChange={this.input.onChangeSelectReferenceDetailAll}
                            />
                          </Section>
                        </Grid.Column>
                        <Grid.Column>
                          <Button
                            lightGreen
                            ui='badgeCount'
                            disabled={this.projectDocumentReferenceManage.getADReferenceDetailList().filter(e => e.isSelected).length < 1}
                            width={VARIABLES.BUTTON.WIDTHS.W_128}
                            name={getLanguage('field.buttonDownload', '')}
                            badgeCountColor={this.projectDocumentReferenceManage.getADReferenceDetailList().filter(e => e.isSelected).length < 1 ? VARIABLES.COLORS.GRAY_12 : VARIABLES.COLORS.GREEN_3}
                            badgeCount={this.projectDocumentReferenceManage.getADReferenceDetailList().filter(e => e.isSelected).length}
                            onClick={this.projectDocumentReferenceManage.onClickDownloadAllSelectedFile}
                          />
                        </Grid.Column>
                        <ButtonDeleteConnected>
                          <Grid.Column>
                            <Button
                              red
                              ui='badgeCount'
                              disabled={this.projectDocumentReferenceManage.getADReferenceDetailList().filter(e => e.isSelected).length < 1}
                              width={VARIABLES.BUTTON.WIDTHS.W_128}
                              name={getLanguage('field.buttonDelete', '')}
                              badgeCountColor={this.projectDocumentReferenceManage.getADReferenceDetailList().filter(e => e.isSelected).length < 1 ? VARIABLES.COLORS.GRAY_12 : VARIABLES.COLORS.RED_4}
                              badgeCount={this.projectDocumentReferenceManage.getADReferenceDetailList().filter(e => e.isSelected).length}
                              onClick={this.projectDocumentReferenceManage.onClickDeleteAllSelectedFile}
                            />
                          </Grid.Column>
                        </ButtonDeleteConnected>
                      </Grid>
                    </ShapeContainer>
                  </Section>
                  {
                    this.projectDocumentReferenceManage.getADReferenceDetailList().length ?
                      (
                        viewTypesSelected === viewTypeSelectOptionList[0] ?
                          <Section spacingBottom='45'>
                            <Table striped>
                              <Table.Section>
                                <Table.Row>
                                  <Table.HeadColumn
                                    noSort
                                    alignCenter
                                    maxWidth={'30'}
                                    minWidth={'30'}
                                  >
                                    <Image ui='iconDocument' />
                                  </Table.HeadColumn>
                                  <Table.HeadColumn
                                    sortAscending={sortIcon.referenceDtFileName}
                                    onClickSort={() => {
                                      this.projectDocumentReferenceManage.onClickSortReferenceDetail('referenceDtFileName');
                                    }}
                                    minWidth={'639'}
                                  >
                                    {getLanguage('projectReference.table.header.referenceNameLower', '')}
                                  </Table.HeadColumn>
                                  <Table.HeadColumn
                                    sortAscending={sortIcon.displayCreatedDate}
                                    onClickSort={() => {
                                      this.projectDocumentReferenceManage.onClickSortReferenceDetail('displayCreatedDate');
                                    }}
                                    maxWidth={'200'}
                                    minWidth={'200'}
                                  >
                                    {getLanguage('projectReference.table.header.updatedDate', '')}
                                  </Table.HeadColumn>
                                  <Table.HeadColumn
                                    noSort
                                    alignCenter
                                    maxWidth={'75'}
                                    minWidth={'75'}
                                  >
                                    {getLanguage('projectReference.table.header.action', '')}
                                  </Table.HeadColumn>
                                  <Table.HeadColumn
                                    noSort
                                    alignCenter
                                    maxWidth={'50'}
                                    minWidth={'50'}
                                  >
                                    {getLanguage('projectReference.table.header.select', '')}
                                  </Table.HeadColumn>
                                </Table.Row>
                              </Table.Section>
                              <Table.Section >
                                {
                                  this.projectDocumentReferenceManage.getADReferenceDetailList().map((e, i) => {
                                    return (
                                      <Table.Row key={i}>
                                        <Table.BodyColumn
                                          info
                                          alignCenter
                                          maxWidth={'30'}
                                          minWidth={'30'}
                                        >
                                          <Image
                                            src={ICONS[e.fileIcon]}
                                            widthRatio="20"
                                            widthRatioUnit="px"
                                            heightRatio="20"
                                            heightRatioUnit="px"
                                          />
                                        </Table.BodyColumn>
                                        <Table.BodyColumn
                                          info
                                          minWidth={'639'}
                                        >
                                          <Text
                                            regular12
                                            color={VARIABLES.COLORS.GRAY_19}
                                          >
                                            {e.referenceDtFileName}
                                          </Text>
                                        </Table.BodyColumn>
                                        <Table.BodyColumn
                                          info
                                          maxWidth={'200'}
                                          minWidth={'200'}
                                        >
                                          <Text
                                            regular12
                                            color={VARIABLES.COLORS.GRAY_19}
                                          >
                                            {e.displayCreatedDate}
                                          </Text>
                                        </Table.BodyColumn>
                                        <Table.BodyColumn
                                          action
                                          alignCenter
                                          maxWidth={'75'}
                                          minWidth={'75'}
                                        >
                                          <Grid justify='center' gutter='20'>
                                            <Grid.Column>
                                              {
                                                e.isImage ? (
                                                  <Image
                                                    ui='iconViewImage'
                                                    onClick={() => {
                                                      this.modal.onClickPreviewModal(e);
                                                    }}
                                                  />
                                                ) : (
                                                  <Image
                                                    ui='iconViewDocument'
                                                    onClick={() => {
                                                      this.modal.onClickPreviewModal(e);
                                                    }}
                                                  />
                                                )
                                              }
                                            </Grid.Column>
                                          </Grid>
                                        </Table.BodyColumn>
                                        <Table.BodyColumn
                                          action
                                          alignCenter
                                          maxWidth={50}
                                          minWidth={50}
                                        >
                                          <Field
                                            checked={e.isSelected}
                                            ui='checkboxTick'
                                            onChange={() => {
                                              this.input.onChangeSelectReferenceDetail(e);
                                            }}
                                          />
                                        </Table.BodyColumn>
                                      </Table.Row>
                                    )
                                  })
                                }
                              </Table.Section>
                            </Table>
                          </Section>
                          :
                          <Section spacingBottom='46'>
                            <Grid gutter='18' gutterVertical='30'>
                              {this.projectDocumentReferenceManage.getADReferenceDetailList().map((e, i) => {
                                return (
                                  <Grid.Column key={i}>
                                    <Section spacingBottom="5" >
                                      <Grid>
                                        <Grid.Column>
                                          <ShapeContainer
                                            bgColor={VARIABLES.COLORS.GRAY_1}
                                            width={119}
                                            height={119}
                                            justify='center'
                                            align='center'
                                            texAlign='center'
                                            borderRadius={6}
                                            borderWidth='1'
                                            borderStyle='solid'
                                            borderColor={VARIABLES.COLORS.GRAY_8}
                                          >
                                            <Image
                                              src={e.isImage ? e.fileUrl : ICONS['ic-document-fill-blue.svg']}
                                              widthRatio={e.isImage ? 120 : 24}
                                              widthRatioUnit="px"
                                              heightRatio={e.isImage ? 120 : 30}
                                              heightRatioUnit="px"
                                              borderRadius={e.isImage ? 6 : false}
                                              left={e.isImage ? -1 : false}
                                              onClick={() => {
                                                this.modal.onClickPreviewModal(e);
                                              }}
                                            />
                                            {
                                              !e.isImage && (
                                                <Section
                                                  nonFluid
                                                  align="center"
                                                  textAlign="center"
                                                  width='99'
                                                  spacingTop='10'
                                                  height='32'
                                                  overflowHidden
                                                >
                                                  <Text
                                                    regular12
                                                    color={VARIABLES.COLORS.BLUE_22}
                                                    ellipsisTwoLine
                                                  >
                                                    {e.referenceDtFileName}
                                                  </Text>
                                                </Section>
                                              )
                                            }
                                          </ShapeContainer>
                                        </Grid.Column>
                                        <Grid.Column>
                                          <Section relative top='5' left='-25' zIndexLv2 >
                                            <Field
                                              checked={e.isSelected}
                                              ui='checkboxTick'
                                              onChange={() => {
                                                this.input.onChangeSelectReferenceDetail(e);
                                              }}
                                            />
                                          </Section>
                                        </Grid.Column>
                                      </Grid>
                                    </Section>
                                  </Grid.Column>
                                )
                              })}
                            </Grid>

                          </Section >
                      )
                      :
                      <Section justify='center' paddingVertical='80'>
                        <Section justify='center' spacingBottom='8' >
                          <Image
                            src={ICONS[`${viewTypesSelected === viewTypeSelectOptionList[0] ? 'ic-lists-gray' : 'ic-grid'}.svg`]}
                            widthRatio="24"
                            widthRatioUnit="px"
                            heightRatio="24"
                            heightRatioUnit="px"
                          />
                        </Section>
                        <Text regular14 color={VARIABLES.COLORS.GRAY_12}>
                          {getLanguage('projectDocument.label.noReferenceFile', '')}
                        </Text>
                      </Section>
                  }
                </Section>
              </Section >
            </React.Fragment >
          </ShapeContainer >
          <Section spacingTop='40'>
            <Grid
              gutter='15'
              justify='flex-end'
            >
              <Grid.Column>
                <Button
                  gray
                  ui='back'
                  width={variables.BUTTON.WIDTHS.W_120}
                  name={getLanguage('field.buttonBack', '')}
                  onClick={this.projectDocumentReferenceManage.onClickBackProjectDocumentManage}
                />
              </Grid.Column>
              <Grid.Column>
                <Button
                  blue
                  disabled={this.projectDocumentReferenceManage.isDisableSaveButton()}
                  width={variables.BUTTON.WIDTHS.W_120}
                  name={getLanguage('field.buttonSave', '')}
                  onClick={this.projectDocumentReferenceManage.onClickSave}
                />
              </Grid.Column>
            </Grid>
          </Section>
        </Section >
        {isShowPreviewPdf &&
          <ModalPreviewPdfContainer
            isOpenModal
            ui='previewDownload'
            title={previewData.referenceDtFileName}
            pdfSrc={previewData.fileUrl}
            onClickCancel={this.modal.onClosePreviewModal}
            onClickConfirm={this.modal.onClosePreviewModal}
            onClickDownload={() => this.modal.onClickDownload(previewData)}
          />
        }
        {
          isShowPreviewImage &&
          <ModalPreviewImageContainer
            isOpenModal
            ui='previewImageSmall'
            title={previewData.referenceDtFileName}
            imageSrc={previewData.fileUrl}
            onClickCancel={this.modal.onClosePreviewModal}
            onClickConfirm={this.modal.onClosePreviewModal}
            onClickDownload={() => this.modal.onClickDownload(previewData)}
          />
        }

        {
          isOpenConfirmDeleteReferenceDetailModal && (
            <ModalConfirmContainer
              isOpenModal
              isHideIcon
              buttonSubmitRed
              footerButtonWidth={VARIABLES.BUTTON.WIDTHS.W_105}
              modalWidth={VARIABLES.MODAL.WIDTHS.W_480}
              title={getLanguage('projectDocument.modal.label.confirmToDelete', '')}
              messageAlign='center'
              message={getLanguage('projectDocument.modal.label.doYouWantToDeleteTheReferenceFile', '').replace('{count}', this.projectDocumentReferenceManage.getADReferenceDetailList().filter(e => e.isSelected).length)}
              // messageHighlight={getLanguage('projectDocument.modal.label.projectReference', '')}
              // messageSecondary={getLanguage('projectDocument.modal.label.reference', '')}
              messageColor={VARIABLES.COLORS.BLACK}
              buttonSubmitName={getLanguage('field.buttonConfirm', '')}
              headerPaddingVertical={'25.5'}
              headerPaddingHorizontal={'45'}
              descriptionPaddingVertical={'45'}
              descriptionMinHeight={'0px'}
              footerPaddingTop={30}
              footerPaddingBottom={30}
              footerPaddingHorizontal={45}
              onClickCancel={this.modal.onClickCancelDeleteReferenceDetail}
              onClickConfirm={this.modal.onClickSubmitDeleteReferenceDetail}
            />
          )
        }
        {
          isShowConfirmLeaveModal &&
          <ModalConfirmContainer
            isOpenModal
            isHideIcon
            modalWidth={VARIABLES.MODAL.WIDTHS.W_480}
            title={getLanguage('preTransaction.referancePreTransaction.modal.modalLeave.title', '')}
            messageAlign='center'
            message={getLanguage('preTransaction.referancePreTransaction.modal.modalLeave.message', '')}
            messageColor={VARIABLES.COLORS.BLACK}
            buttonSubmitName={getLanguage('field.buttonConfirm', '')}
            headerPaddingVertical={'25.5'}
            headerPaddingHorizontal={'45'}
            descriptionPaddingVertical={'45'}
            descriptionMinHeight={'0px'}
            footerPaddingTop={30}
            footerPaddingBottom={30}
            footerPaddingHorizontal={45}
            onClickCancel={this.modal.onCloseConfirmLeaveModal}
            onClickConfirm={this.modal.onClickSubmitConfirmLeaveModal}
          />
        }
      </React.Fragment >
    );
  }
}
