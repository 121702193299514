import apiService from '../apiService'

export const otpController = (configService = {}) => {
  const service = apiService(configService)
  return {
    otpSend: (params) => {
      return service.post(`/otp/send`, params)
    },
    otpVerify: (params) => {
      return service.post(`/otp/verify`, params)
    },
    otpCheck: (params) => {
      return service.post(`/otp/check`, params)
    },
  }
}
