import React from 'react'
import ClassNames from 'classnames'
import PropTypes from 'prop-types'
import {
  osName,
} from 'react-device-detect'
import {
  WarrantyNotCoveredListsWrapper
} from './styled'
import {
  Section,
  Text,
  Image,
  Grid,
  Table,
  ShapeContainer,
} from './../../../../components'
import {
  VARIABLES,
} from './../../../../themes'

/**
 * Warranty description:
 * - Warranty
 */

export class WarrantyBSWMThailandNotCoveredLists extends React.PureComponent {
  render() {
    const {
      thLanguage,
    } = this.props

    // props for css classes
    const classes = ClassNames(
      'warranty-bswm-note',
    )

    return (
      <WarrantyNotCoveredListsWrapper
        className={classes}
      >
        {thLanguage ?
          <React.Fragment>
            <Grid>
              <Grid.Column flex='none'><Section width='17'><p className='warranty-bswm-text is-xs'>ก.</p></Section></Grid.Column>
              <Grid.Column flex='1'><p className='warranty-bswm-text is-xs'>ความเสียหายที่มีสาเหตุจากการออกแบบ วิธีการผลิต หรือการติดตั้งที่ไม่ถูกต้อง</p></Grid.Column>
            </Grid>
            <Grid>
              <Grid.Column flex='none'><Section width='17'><p className='warranty-bswm-text is-xs'>ข.</p></Section></Grid.Column>
              <Grid.Column flex='1'><p className='warranty-bswm-text is-xs'>ความเสียหายทางกล เคมีหรืออื่นๆ ก่อน หรือในระหว่าง หรือภายหลังจากการติดตั้ง</p></Grid.Column>
            </Grid>
            <Grid>
              <Grid.Column flex='none'><Section width='17'><p className='warranty-bswm-text is-xs'>ค.</p></Section></Grid.Column>
              <Grid.Column flex='1'><p className='warranty-bswm-text is-xs'>การถูกทำลายโดยสารเคมี ไอ ของเหลวหรือของแข็งต่างๆ นอกจากน้ำฝนที่ตกมากระทบโดยตรง</p></Grid.Column>
            </Grid>
            <Grid>
              <Grid.Column flex='none'><Section width='17'><p className='warranty-bswm-text is-xs'>ง.</p></Section></Grid.Column>
              <Grid.Column flex='1'><p className='warranty-bswm-text is-xs'>บริเวณที่น้ำฝนธรรมชาติชำระล้างไม่ถึง และไม่ได้ทำความสะอาดประจำ</p></Grid.Column>
            </Grid>
            <Grid>
              <Grid.Column flex='none'><Section width='17'><p className='warranty-bswm-text is-xs'>จ.</p></Section></Grid.Column>
              <Grid.Column flex='1'><p className='warranty-bswm-text is-xs'>การสัมผัสกับดิน ฝุ่น ขี้เถ้า ปุ๋ย หรือสารที่เก็บความชื้นเป็นเวลานาน</p></Grid.Column>
            </Grid>
            <Grid>
              <Grid.Column flex='none'><Section width='17'><p className='warranty-bswm-text is-xs'>ฉ.</p></Section></Grid.Column>
              <Grid.Column flex='1'><p className='warranty-bswm-text is-xs'>บริเวณที่สัมผัสกับวัสดุที่ไม่เหมาะสมกัน (เช่น ตะกั่ว ทองแดง คาร์บอน/กราไฟต์ หรือเหล็กกล้าไร้สนิม) หรืออาจสัมผัสกับน้ำที่ไหลออกจากวัสดุที่ไม่เหมาะสมกันดังกล่าว</p></Grid.Column>
            </Grid>
            <Grid>
              <Grid.Column flex='none'><Section width='17'><p className='warranty-bswm-text is-xs'>ช.</p></Section></Grid.Column>
              <Grid.Column flex='1'><p className='warranty-bswm-text is-xs'>การไม่ทำความสะอาดเศษวัสดุและ/ หรือการไม่จัดให้มีการระบายน้ำอย่างสะดวก รวมทั้งการควบแน่นภายในที่เกิดบนผิวทั้งหมดของผลิตภัณฑ์</p></Grid.Column>
            </Grid>
            <Grid>
              <Grid.Column flex='none'><Section width='17'><p className='warranty-bswm-text is-xs'>ซ.</p></Section></Grid.Column>
              <Grid.Column flex='1'><p className='warranty-bswm-text is-xs'>ความเสียหายเนื่องจากวัสดุยึดเกาะ</p></Grid.Column>
            </Grid>
            <Grid>
              <Grid.Column flex='none'><Section width='17'><p className='warranty-bswm-text is-xs'>ฌ.</p></Section></Grid.Column>
              <Grid.Column flex='1'><p className='warranty-bswm-text is-xs'>ความเสียหายของแผ่นเหล็กที่เกิดจากการสัมผัสกับไม้เปียก ไม้สดหรือไม้อาบน้ำยา</p></Grid.Column>
            </Grid>
            <Grid>
              <Grid.Column flex='none'><Section width='17'><p className='warranty-bswm-text is-xs'>ญ.</p></Section></Grid.Column>
              <Grid.Column flex='1'><p className='warranty-bswm-text is-xs'>สภาพแวดล้อมทางทะเล หรืออุตสาหกรรมที่รุนแรง หรือสภาพแวดล้อมที่กัดกร่อนอย่างรุนแรง เว้นแต่มีการตกลงและหมายเหตุไว้เป็นการเฉพาะ</p></Grid.Column>
            </Grid>
            <Grid>
              <Grid.Column flex='none'><Section width='17'><p className='warranty-bswm-text is-xs'>ฎ.</p></Section></Grid.Column>
              <Grid.Column flex='1'><p className='warranty-bswm-text is-xs'>ภัยธรรมชาติและสิ่งของตกหล่นใส่ แรงกระทำจากภายนอก การระเบิด ไฟไหม้ การจลาจล ความไม่สงบในบ้านเมือง การทำสงคราม กัมมันตรังสี ก๊าซหรือควันที่เป็นอันตราย สารเคมี หรือสารแปลกปลอมในอากาศ หรือในบรรยากาศ การก่อการร้าย ภาวะโลกร้อน น้ำท่วม ซึ่งรวมถึงแต่ไม่จำกัดเพียงการจลาจลและการประท้วง</p></Grid.Column>
            </Grid>
          </React.Fragment>
          :
          <React.Fragment>
            <Grid>
              <Grid.Column flex='none'><Section width='17'><p className='warranty-bswm-text is-xs'>a.</p></Section></Grid.Column>
              <Grid.Column flex='1'><p className='warranty-bswm-text is-xs'>Defects attributed to faulty design, method of manufacture or installation of the product.</p></Grid.Column>
            </Grid>
            <Grid>
              <Grid.Column flex='none'><Section width='17'><p className='warranty-bswm-text is-xs'>b.</p></Section></Grid.Column>
              <Grid.Column flex='1'><p className='warranty-bswm-text is-xs'>Mechanical, chemical or other damage sustained during transport, handling, storage, erection or subsequent to erection.</p></Grid.Column>
            </Grid>
            <Grid>
              <Grid.Column flex='none'><Section width='17'><p className='warranty-bswm-text is-xs'>c.</p></Section></Grid.Column>
              <Grid.Column flex='1'><p className='warranty-bswm-text is-xs'>Attack from chemical agents, fumes, liquids or solids other than direct rainfall.</p></Grid.Column>
            </Grid>
            <Grid>
              <Grid.Column flex='none'><Section width='17'><p className='warranty-bswm-text is-xs'>d.</p></Section></Grid.Column>
              <Grid.Column flex='1'><p className='warranty-bswm-text is-xs'>Unwashed areas sheltered from normal rainfall and not regularly ed.</p></Grid.Column>
            </Grid>
            <Grid>
              <Grid.Column flex='none'><Section width='17'><p className='warranty-bswm-text is-xs'>e.</p></Section></Grid.Column>
              <Grid.Column flex='1'><p className='warranty-bswm-text is-xs'>Extended contact with soil, dust, ashes, fertilizers, or moisture retaining substances.</p></Grid.Column>
            </Grid>
            <Grid>
              <Grid.Column flex='none'><Section width='17'><p className='warranty-bswm-text is-xs'>f.</p></Section></Grid.Column>
              <Grid.Column flex='1'><p className='warranty-bswm-text is-xs'>Areas in metallic contact with incompatible materials (eg. lead, copper, carbon/graphite, or stainless steel) or subject to water run-off from such incompatible materials.</p></Grid.Column>
            </Grid>
            <Grid>
              <Grid.Column flex='none'><Section width='17'><p className='warranty-bswm-text is-xs'>g.</p></Section></Grid.Column>
              <Grid.Column flex='1'><p className='warranty-bswm-text is-xs'>Failure to remove debris and/or failure to provide free drainage of water including internal condensation all surfaces of the product.</p></Grid.Column>
            </Grid>
            <Grid>
              <Grid.Column flex='none'><Section width='17'><p className='warranty-bswm-text is-xs'>h.</p></Section></Grid.Column>
              <Grid.Column flex='1'><p className='warranty-bswm-text is-xs'>Failure to replace corroded fasteners.</p></Grid.Column>
            </Grid>
            <Grid>
              <Grid.Column flex='none'><Section width='17'><p className='warranty-bswm-text is-xs'>i.</p></Section></Grid.Column>
              <Grid.Column flex='1'><p className='warranty-bswm-text is-xs'>Deterioration of the panels caused by contact with wet, green or treated timber and wet cement.</p></Grid.Column>
            </Grid>
            <Grid>
              <Grid.Column flex='none'><Section width='17'><p className='warranty-bswm-text is-xs'>j.</p></Section></Grid.Column>
              <Grid.Column flex='1'><p className='warranty-bswm-text is-xs'>Severe marine, industrial or corrosive environments unless specifically agreed and noted.</p></Grid.Column>
            </Grid>
            <Grid>
              <Grid.Column flex='none'><Section width='17'><p className='warranty-bswm-text is-xs'>k.</p></Section></Grid.Column>
              <Grid.Column flex='1'><p className='warranty-bswm-text is-xs'>Acts of God and falling objects, external force, explosions, fire, riots, civil commotions, acts of war, radiation, harmful gases or fumes, chemicals or foreign substances in the air or atmosphere, acts of terrorism, global warming, flooding and political disturbances including but not limited to uprising and protests.</p></Grid.Column>
            </Grid>
          </React.Fragment>
        }

      </WarrantyNotCoveredListsWrapper>
    )
  }

  static defaultProps = {
    // If you set new default props, you should create propsType for correct styleguide document.
  }

  static propTypes = {
    // TYPE > node, string, number, bool, array, object, symbol, func
  }
}
