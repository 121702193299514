import React from "react";
import { NewsAndPromotionListContainerConnected } from "../../containers/NewsAndPromotionListContainer";
import { ROUTE_PATH } from "../../helpers/constants/route";
import { getLanguage } from "../../helpers/functions/language";
import { redirect, setSideMenuActive } from "../../helpers/functions/route";
import {
  Breadcrumb,
  Grid,
  Image,
  Section,
  ShapeContainer,
  Text,
  TitlePage,
} from "./../../components";
import { ICONS, VARIABLES } from "./../../themes";

export class HomeScreen extends React.Component {
  constructor(props) {
    super(props);
    setSideMenuActive(props);
    let isMobileScreenSize = window.matchMedia("(max-width: 1023px)").matches;
    this.state = {
      isMobileScreenSize,
    };
    this.matchMedia = null
  }

  componentDidMount() {
    this.mobile.handleCheckSizeChange();
  }

  componentWillUnmount() {
    if (this.matchMedia) {
      this.matchMedia.removeEventListener("change", this.handleChange)
    }
  }

  handleChange = (ev) => {
    this.setState({
      isMobileScreenSize: ev.matches,
    });
  };

  mobile = {
    handleCheckSizeChange: () => {
      this.matchMedia = window.matchMedia("(max-width: 1023px)")
      this.matchMedia.addEventListener("change", this.handleChange);
    },
  };

  render() {
    const { isMobileScreenSize } = this.state;
    return (
      <React.Fragment>
        <Breadcrumb>
          <Breadcrumb.List link name={getLanguage("home.breadcrumb1", "")} />
        </Breadcrumb>
        <Section ui="content">
          <Section spacingBottom="35">
            <TitlePage
              src={ICONS["ic-page-home.svg"]}
              name={getLanguage("home.title", "")}
            />
          </Section>
          <NewsAndPromotionListContainerConnected />
          {isMobileScreenSize && (
            <Section justify="center" align="center" spacingTop="40">
              <ShapeContainer
                barcodeScan
                fluid
                maxWidth="414"
                widthUnit="px"
                height="99"
                paddingHorizontal="20"
                paddingVertical="34"
                align="center"
                justify="center"
                textAlign="center"
                onClick={() => {
                  redirect(ROUTE_PATH.COIL_TRANSFER_BARCODE_SCANNER.LINK);
                }}
              >
                <Grid justify="center" gutter="18">
                  <Grid.Column>
                    <Image
                      src={ICONS["ic-barcode-reader.svg"]}
                      widthRatio="36"
                      widthRatioUnit="px"
                      heightRatio="36"
                      heightRatioUnit="px"
                    />
                  </Grid.Column>
                  <Grid.Column>
                    <Section
                      nonFluid
                      paddingVertical="4"
                      align="center"
                      textAlign="center"
                    >
                      <Text bold18 color={VARIABLES.COLORS.WHITE} noWrap>
                        {getLanguage("home.label.barcodeScan", "")}
                      </Text>
                    </Section>
                  </Grid.Column>
                </Grid>
              </ShapeContainer>
            </Section>
          )}
        </Section>
      </React.Fragment>
    );
  }
}
