import styled, {
  // css
} from 'styled-components'
import {
  default as VARIABLES
} from './../../../themes/styles/bases/variables'
// import {
//   default as TYPOGRAPHYS
// } from './../../../themes/styles/bases/typographys'
// import {
//   default as MIXINS
// } from './../../../themes/styles/helpers/mixins'

// Sample using image in style
// url(${require('./../../../themes/images/icons/icon-sample.svg')});

// Wrapper
// ============================================================
export const TitleSectionWrapper = styled.div`
  /* Parent
  ------------------------------- */
  position: relative;
  width: 100%;
  padding-bottom: 10px;
  border-bottom: 3px solid ${VARIABLES.COLORS.BLUE_15};

  &:after {
    content: ' ';
    position: absolute;
    z-index: ${VARIABLES.Z_INDEXS.LV_1};
    bottom: -3px;
    left: 0;
    display: block;
    width: 55px;
    height: 3px;
    background-color: ${VARIABLES.COLORS.PRIMARY_2};
  }

  /* Childrens
  ------------------------------- */
  /* Modifiers
  ------------------------------- */
  /* Media queries
  ------------------------------- */
`
