import React from 'react'
import ClassNames from 'classnames'
import PropTypes from 'prop-types'
import {
  osName,
} from 'react-device-detect'
import {
  WarrantyWrapper
} from './styled'
import {
  Section,
  Text,
  Image,
  Grid,
  Table,
  ShapeContainer,
} from './../../../../components'
import {
  VARIABLES,
} from './../../../../themes'

/**
 * Warranty description:
 * - Warranty
 */

export class WarrantyBSWMMalaysia extends React.PureComponent {
  render() {
    const {
      children,
    } = this.props

    // props for css classes
    const classes = ClassNames(
      'warranty-bswm',
    )

    return (
      <WarrantyWrapper
        className={classes}
      >
        {children}
      </WarrantyWrapper>
    )
  }

  static defaultProps = {
    // If you set new default props, you should create propsType for correct styleguide document.
  }

  static propTypes = {
    // TYPE > node, string, number, bool, array, object, symbol, func
  }
}
