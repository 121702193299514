import { ENUM } from "../helpers/constants/enum";
import { getUserAuthRedux } from "../helpers/functions/auth";
import { displayDate, displayUnit, displayWithComma } from "../helpers/functions/display";

export class ModelProduct {
  constructor(props = {}) {
    const userAuth = getUserAuthRedux();

    this.fAging = props.f_aging__c || '';
    this.steelGrade = props.steel_grade__c || '';
    this.m3CustomerId = props.m3_customer_id__c || '';
    this.fRemaininglength = props.migrated_remaining_length__c || '';
    this.bsProductGroup = props.bs_product_group__c || '';
    this.invoiceDate = props.invoice_date__c || '';
    this.displayInvoiceDate = displayDate(props.invoice_date__c, '/');
    this.orderDate = props.order_date__c || '';
    this.authorizedDealer = props.authorized_dealer__c || '';
    this.coNumber = props.co_number__c || '';
    this.invoiceNumber = props.invoice_number__c || '';
    this.deliveredQuantityT = props.delivered_quantity_t__c || '';
    this.customerOrderNumber = props.customer_order_number__c || '';
    this.topPaintColor = props.top_paint_color__c || '';
    this.name = props.name || '';
    this.lotNumber = props.lot_number__c || '';
    this.bmtMM = props.bmt_mm__c || '';
    this.m3ProductGroup = props.m3_product_group__c || '';
    this.fProductgroupcode = props.f_productgroupcode__c || '';
    this.itemNumber = props.item_number__c || '';
    this.fRemainingweight = props.f_remainingweight__c || '';
    this.fRemainingweightWithComma = displayWithComma(props.f_remainingweight__c);
    this.fBsproductgroupid = props.f_bsproductgroupid__c || '';
    this.deliveryNumber = props.delivery_number__c || '';
    this.isdeleted = props.isdeleted || '';
    this.fLengthsold = props.f_lengthsold__c || '';
    this.systemmodstamp = props.systemmodstamp || '';
    this.actualCoilLengthM = props.actual_coil_length_m__c || '';
    this.itemDescription = props.item_description__c || '';
    this.coatingMassMM = props.coating_mass_mm__c || '';
    this.createdDate = props.createddate || '';
    // this.displayCreatedDate = displayDate(props.createddate, '/');
    this.displayCreatedDate = displayDate(props.invoice_date__c, '/');
    this.thicknessMM = props.thickness_mm__c || '';
    this.deliveredDate = props.delivered_date__c || '';
    this.coilWidthMM = props.coil_width_mm__c || '';
    this.onHandBlance = props.on_hand_blance__c || '';
    this.kgPerM = props.kg_per_m__c || '';
    this.amountThb = props.amount_thb__c || '';
    this.fBsproductgroupname = props.f_bsproductgroupname__c || '';
    this.itemTypeName = props.item_type_name__c || '';
    this.sfid = props.sfid || '';
    this.id = props.sfid || '';
    this.hcLastop = props._hc_lastop || '';
    this.hcErr = props._hc_err || '';
    this.fTotalRemaininglength = props.f_total_remaininglength__c || '';
    this.fTotalRemaininglengthWithComma = displayWithComma(props.f_total_remaininglength__c);
    this.quantity = Math.round(this.fTotalRemaininglength * this.kgPerM);
    this.quantityWithComma = displayWithComma(this.quantity);
    this.points = 100;

    //indo
    this.fTotalRemainingprofile = props.f_total_remainingprofile__c || '';
    this.fTotalRemainingprofileWithComma = displayWithComma(this.fTotalRemainingprofile);
    this.profileLength = props.profile_length__c || 0;
    this.profileLengthWithComma = displayWithComma(this.profileLength);
    this.profileType = props.profile_type__c || '';
    this.profileTypeUnit = ENUM.PROFILE_TYPE_UNIT[this.profileType] || '';
    this.subSalesSfid = props.sub_sales_sfid || '';
    this.unit = displayUnit(this.profileType);
    if (
      userAuth.addressType === ENUM.COUNTRY_TYPE.INDONESIA && (
        userAuth.customerRoleType === ENUM.CUSTOMER_ROLE_TYPE.INDONESIA.TOKO ||
        userAuth.customerRoleType === ENUM.CUSTOMER_ROLE_TYPE.INDONESIA.DISTRIBUTOR
      )
    ) {
      this.id = `${this.sfid}:${this.profileType}:${this.profileLength}`
    }
  }
}

export class ModelProductQuantityList {
  constructor(props = []) {
    const productGroup = {};
    props.map(e => {
      let product = new ModelProduct(e);
      const groupName = product.fBsproductgroupname;
      if (!productGroup[groupName]) {
        productGroup[groupName] = {
          productList: [],
          totalCoil: 0,
          totalWeight: 0,
          totalLength: 0,
        }
      }
      productGroup[groupName].productList.push(product);
      productGroup[groupName].name = groupName;
      productGroup[groupName].totalCoil += 1;
      if (product.fRemainingweight > 0) {
        productGroup[groupName].totalWeight += product.fRemainingweight;
      }
      if (product.fTotalRemaininglength > 0) {
        productGroup[groupName].totalLength += product.fTotalRemaininglength;
      }
    })
    this.productGroupList = Object.values(productGroup).map((e, i) => {
      e.id = `${i + 1}_${e.name}`;
      return e;
    });
  }
}