import React from 'react'
import {
  ShapeContainer,
  Section,
  Breadcrumb,
  TitlePage,
  TableAction,
  Grid,
  Text,
  Button,
  PaginationControl,
  SelectControl,
  Field,
  DatePickerControl,
  Table,
  Image
} from './../../components'
import {
  ModalSelectDateTimeContainer
} from './../../containers/ModalContainer'
import {
  VARIABLES,
  ICONS
} from './../../themes'
import { setSideMenuActive, getRouteParams } from '../../helpers/functions/route';
import { ROUTE_PATH } from '../../helpers/constants/route';
import { redirect } from '../../helpers/functions/route';
import { getRedemptionList, getClearRedemptionParams } from '../../helpers/services/redemption';
import { RedemptionHistoryListContainerConnected } from '../../containers/RedemptionHistoryListContainer';
import { redemptionController } from '../../apiService/apiController/redemptionService';
import { isValidResponse } from '../../helpers/functions/validation';
import { toast } from '../../helpers/functions/main';
import _ from 'lodash';
import { getLanguage } from '../../helpers/functions/language';
import { getDateApiRequet, getMomentStartOf, getMomentEndOf } from '../../helpers/functions/data';
import { DATE_PICKER_FORMAT } from '../../helpers/constants/data';
import { ENUM } from '../../helpers/constants/enum';
import { getUserAuthRedux } from '../../helpers/functions/auth';

export class RewardRedemptionHistoryScreen extends React.Component {

  constructor(props) {
    super(props);
    setSideMenuActive(props);
    const userAuth = getUserAuthRedux();
    const customerId = getRouteParams(props, 'id') || '';
    const selectOptionList = [
      // {
      //   value: '',
      //   label: 'All'
      // }, {
      //   value: 'Most Exchange Reward',
      //   label: getLanguage('redemptionRewardHistory.selectOption.mostExchangeReward', '')
      // }, {
      //   value: 'Most Exchange Customer',
      //   label: getLanguage('redemptionRewardHistory.selectOption.mostExchangeCustomer', '')
      // },
      {
        value: 'Redemption Code',
        label: getLanguage('redemptionRewardHistory.selectOption.redemptionCode', '')
      }, {
        value: 'Customer Name',
        label: getLanguage('redemptionRewardHistory.selectOption.customerName', '')
      }, {
        value: 'Phone No.',
        label: getLanguage('redemptionRewardHistory.selectOption.phoneNo', '')
      }];
    this.state = {
      searchValue: '',
      selectOptionList,
      selectedOption: selectOptionList[0],
      startDate: new Date(),
      endDate: new Date(),
      customerId,
      modalTypeList: ['edit'],
      data: {},
      isMalay: userAuth.addressType === ENUM.COUNTRY_TYPE.MALAYSIA,
    }
    console.log('customerId', customerId)
  }

  redemptionHistory = {
    onClickEdit: (data) => {
      const {
        modalTypeList,
      } = this.state;
      this.setState({
        modalType: modalTypeList[0],
        data,
      })
    },
    onClickEditRedemption: async (newPickupAt) => {
      const {
        data,
      } = this.state;
      const params = {
        id: data.id,
        pickup_at: newPickupAt,
      };
      const redemptionService = redemptionController();
      const res = await redemptionService.updateRedemtion(params);
      if (isValidResponse(res)) {
        getRedemptionList({
          isNoSort: true,
        });
        this.modal.onCloseModal();
        toast.success(getLanguage('message.updateRedemptionCompleted', ''));
      }
      else {
        toast.error(res.message);
      }
    }
  }

  input = {
    onChangeInput: (ev) => {
      const name = ev.target.name;
      const value = ev.target.value;
      this.setState({
        [name]: value,
      })
    },
    onClickSearchText: () => {
      const {
        searchValue,
        selectedOption,
        selectOptionList,
        customerId,
      } = this.state;
      const searchType = selectedOption.value;
      let params = {
        ...getClearRedemptionParams(),
        page: 1,
        customer_id: customerId,
      };
      switch (searchType) {
        // case selectOptionList[0].value:
        //   params.all_field = searchValue;
        //   break;
        // case selectOptionList[1].value:
        //   params.reward_name = searchValue;
        //   break;
        // case selectOptionList[2].value:
        //   params.customer_name = searchValue;
        //   break;
        case selectOptionList[0].value:
          // params.reward_id = searchValue;
          params.id = searchValue;
          break;
        case selectOptionList[1].value:
          params.customer_name = searchValue;
          break;
        case selectOptionList[2].value:
          params.phone_no = searchValue;
          break;
      }
      getRedemptionList(params);
    },
    onClickSearchDate: () => {
      const {
        startDate,
        endDate,
        customerId
      } = this.state;
      let params = {
        ...getClearRedemptionParams(),
        page: 1,
        customer_id: customerId,
        start_date: getDateApiRequet(startDate),
        end_date: getDateApiRequet(endDate),
      };
      getRedemptionList(params);
    },
    onChangeSearchOption: (selectedOption) => {
      this.setState({
        selectedOption
      })
    },
    onChangeDate: (date, name) => {
      this.setState({
        [name]: date
      })
    }
  }

  modal = {
    onCloseModal: () => {
      this.setState({
        modalType: undefined,
      });
    }
  }

  render() {
    const {
      selectedOption,
      searchValue,
      selectOptionList,
      startDate,
      endDate,
      customerId,
      data,
      modalType,
      modalTypeList,
      isMalay
    } = this.state

    return (
      <React.Fragment>
        <Breadcrumb>
          <Breadcrumb.List
            link
            name={getLanguage('redemptionRewardHistory.breadcrumb1', '')}
            onClick={() => {
              redirect(ROUTE_PATH.HOME.LINK)
            }}
          />
          <Breadcrumb.List name='/' />
          <Breadcrumb.List
            link
            name={getLanguage('redemptionRewardHistory.breadcrumb2', '')}
          // onClick={() => {
          //   redirect(ROUTE_PATH.REWARDS.LINK)
          // }}
          />
          <Breadcrumb.List name='/' />
          <Breadcrumb.List name={getLanguage('redemptionRewardHistory.breadcrumb3', '')} />
        </Breadcrumb>
        <Section ui='content'>
          <Section spacingBottom='35'>
            <TitlePage
              src={ICONS['ic-page-rewards.svg']}
              name={getLanguage('redemptionRewardHistory.title', '')}
            />
          </Section>
          <Section spacingBottom='20'>
            <ShapeContainer
              fluid
              className='search-control'
              paddingTop='20'
              paddingBottom='18'
              paddingHorizontal='20'
              borderWidth='1'
              borderStyle='solid'
              borderColor={VARIABLES.COLORS.PRIMARY_1}
              borderRadius='10'
            >
              <Grid
                className='search-control-container'
                gutter='30'
                gutterVertical='20'
              >
                <Grid.Column
                  className='search-control-group-left'
                  flex='1'
                >
                  <Grid gutter='10'>
                    <Grid.Column flex='2'>
                      <Field name='searchValue'
                        fluid
                        ui='blockLabelVerticalSearch'
                        placeholder={getLanguage('redemptionRewardHistory.placeholder', '')}
                        value={searchValue}
                        onChange={this.input.onChangeInput}
                      />
                    </Grid.Column>
                    <Grid.Column flex='none'>
                      <Section zIndexLv3>
                        <SelectControl
                          verticalLabel
                          filterFluid
                          width='165'
                          isSearchable={false}
                          value={selectedOption}
                          onChange={this.input.onChangeSearchOption}
                          options={selectOptionList}
                        />
                      </Section>
                    </Grid.Column>
                    <Grid.Column flex='none'>
                      <Button
                        lightBlue
                        name={getLanguage('redemptionRewardHistory.buttonSearch', '')}
                        width={VARIABLES.BUTTON.WIDTHS.W_105}
                        onClick={this.input.onClickSearchText}
                      />
                    </Grid.Column>
                  </Grid>
                </Grid.Column>
                <Grid.Column
                  className='search-control-partition'
                  flex='none'
                >
                  <ShapeContainer
                    width='1'
                    height='32'
                    bgColor={VARIABLES.COLORS.PRIMARY_1}
                  />
                </Grid.Column>
                <Grid.Column
                  className='search-control-group-right'
                  flex='1'
                >
                  <Grid gutter='20'>
                    <Grid.Column flex='none'>
                      <Section paddingTop='6'>
                        <Text
                          regular12
                          color={VARIABLES.COLORS.BLACK}
                        >
                          {getLanguage('redemptionRewardHistory.date', '')}
                        </Text>
                      </Section>
                    </Grid.Column>
                    <Grid.Column flex='1'>
                      <Grid gutter='10'>
                        <Grid.Column flex='1'>
                          <Grid gutter='15'>
                            <Grid.Column flex='1'>
                              <DatePickerControl
                                filter
                                bottom
                                dateFormat={DATE_PICKER_FORMAT}
                                placeholderText={getLanguage('redemptionRewardHistory.selectStartDate', '')}
                                selected={startDate}
                                onChange={(date) => {
                                  this.input.onChangeDate(date, 'startDate');
                                }}
                              />
                            </Grid.Column>
                            <Grid.Column flex='none'>
                              <Section paddingTop='6'>
                                <Text
                                  regular12
                                  color={VARIABLES.COLORS.GRAY_19}
                                >
                                  {getLanguage('redemptionRewardHistory.dateTo', '')}
                                </Text>
                              </Section>
                            </Grid.Column>
                            <Grid.Column flex='1'>
                              <DatePickerControl
                                filter
                                bottom
                                dateFormat={DATE_PICKER_FORMAT}
                                placeholderText={getLanguage('redemptionRewardHistory.selectEndDate', '')}
                                selected={endDate}
                                onChange={(date) => {
                                  this.input.onChangeDate(date, 'endDate');
                                }}
                              />
                            </Grid.Column>
                          </Grid>
                        </Grid.Column>
                        <Grid.Column flex='none'>
                          <Button
                            lightBlue
                            name={getLanguage('redemptionRewardHistory.buttonSearch', '')}
                            width={VARIABLES.BUTTON.WIDTHS.W_105}
                            onClick={this.input.onClickSearchDate}
                          />
                        </Grid.Column>
                      </Grid>
                    </Grid.Column>
                  </Grid>
                </Grid.Column>
              </Grid>
            </ShapeContainer>
          </Section>
          <RedemptionHistoryListContainerConnected
            customerId={customerId}
            isMalay={isMalay}
            onClickEdit={this.redemptionHistory.onClickEdit}
          />
        </Section>
        {
          modalType === modalTypeList[0] &&
          <ModalSelectDateTimeContainer
            isOpenModal
            selectedDate={data.pickupAt}
            title={getLanguage('modal.selectDateTime.title', '')}
            placeholder={getLanguage('modal.selectDateTime.placeholder', '')}
            onClickClose={this.modal.onCloseModal}
            onClickConfirm={this.redemptionHistory.onClickEditRedemption}
          />
        }
      </React.Fragment>
    )
  }
}
