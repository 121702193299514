import { ENUM } from "../helpers/constants/enum";
import { convertString, getCityByLabel, getCountryPhone, getCustomerTypeText, getPhoneRemovePrefix, getProvinceByLabel } from "../helpers/functions/data";
import { displayUrlPath, displayWithComma, displayWithPrefix } from "../helpers/functions/display";
import { ModelAddress } from "./ModelAddress";

export class ModelUser {
  constructor(props = {}) {
    this.id = props.id;
    this.idWithPrefix = displayWithPrefix(props.id);
    this.firstname = props.first_name;
    this.lastname = props.last_name;
    this.email = props.email;
    this.phone = getPhoneRemovePrefix(props.phone);
    this.phoneCountry = getCountryPhone(props.phone);
    this.oldImageRef = props.image_url;
    this.imageUrl = displayUrlPath(this.oldImageRef);
    this.oldImageUrl = this.imageUrl;
    this.createdAt = props.created_at;
    this.type = props.type;
    this.typeText = getCustomerTypeText(props.type);
    this.countries = props.countries;
    this.accountType = props.account_type;
    this.uid = props.uid;
    this.mobileNo = getPhoneRemovePrefix(props.mobile_no);
    this.mobileNoCountry = getCountryPhone(props.mobile_no);
    this.address = props.address;
    this.oldIdAddress = props.address;
    let idAddressGroup = {};
    this.postCode = props.post_code === 'undefined' ? '0' : props.post_code || '';
    try {
      if (props.address) {
        idAddressGroup = new ModelAddress(props.address);
      }
      else {
        idAddressGroup = {
          address: props.house_number__c,
          subDistrict: props.district__c,
          selectedProvince: getProvinceByLabel(props.province__c),
          selectedCity: getCityByLabel(props.city__c),
          street: props.road__c,
          alley: props.alley__c,
          group: props.hamlet__c,
          ward: props.ward__c,
        };
        this.postCode = props.post_code__c ? props.post_code__c : '';
      }
    } catch { }
    finally {
      this.idAddress = idAddressGroup.address || '';
      this.idSelectedProvince = idAddressGroup.selectedProvince;
      this.idSelectedCity = idAddressGroup.selectedCity;
      this.idDistrict = idAddressGroup.district || '';
      this.idSubDistrict = idAddressGroup.subDistrict || '';
      this.idAlley = idAddressGroup.alley || '';
      this.idGroup = idAddressGroup.group || '';
      this.idStreet = idAddressGroup.street || '';
      this.idWard = idAddressGroup.ward || '';
      this.idAddressType = idAddressGroup.addressType || this.phoneCountry.countryType;
    }
    this.oldContactAddress = props.address;
    let contactAddressGroup = {};
    try {
      contactAddressGroup = new ModelAddress(props.address);
    } catch { }
    finally {
      this.contactAddress = contactAddressGroup.address || '';
      this.contactSelectedProvince = contactAddressGroup.selectedProvince;
      this.contactSelectedCity = contactAddressGroup.selectedCity;
      this.contactDistrict = contactAddressGroup.district || '';
      this.contactSubDistrict = contactAddressGroup.subDistrict || '';
      this.contactAlley = contactAddressGroup.alley || '';
      this.contactGroup = contactAddressGroup.group || '';
      this.contactStreet = contactAddressGroup.street || '';
      this.contactWard = contactAddressGroup.ward || '';
      this.contactAddressType = contactAddressGroup.addressType || this.phoneCountry.countryType;
    }
    this.contactPostCode = props.post_code === 'undefined' ? '0' : props.post_code || '';
    this.houseNumber = props.house_number;
    this.road = props.road;
    this.businessRegNo = props.business_reg_no;

    this.customerRefId = '';
    this.nickname = convertString(props.nick_name);
    this.points = props.points ? props.points : 0;
    this.pointsWithComma = displayWithComma(props.points);
    this.remainPoints = props.remain_point ? Math.floor(props.remain_point) : 0;
    this.remainPointsWithComma = displayWithComma(this.remainPoints);
    this.redemptionPoints = props.redemption_points ? props.redemption_points : 0;
    this.redemptionPointsWithComma = displayWithComma(props.redemption_points);
    this.pointsRevenue = props.points_revenue || '';

    this.displayFullname = `${this.firstname || ''} ${this.lastname || ''}`;
    this.fullname = `${this.firstname} ${this.lastname}`;

    //TODO: loas
    if (this.idAddressType === ENUM.COUNTRY_TYPE.THAI) {
      this.idPostCode = {
        label: this.idPostCode,
        value: this.idPostCode,
      }
      this.postCode = {
        label: this.postCode,
        value: this.postCode,
      }
    }
    //TODO: loas
    if (this.contactAddressType === ENUM.COUNTRY_TYPE.THAI) {
      this.contactPostCode = {
        label: this.contactPostCode,
        value: this.contactPostCode,
      }
    }
  }
}