import React from 'react'
import ClassNames from 'classnames'
// import PropTypes from 'prop-types'
import {
  PaginationControlBase
} from './../../base'
// import {
// } from './../../../components'
// import {
//   default as VARIABLES
// } from './../../../themes/styles/bases/variables'
// import {
//   default as TYPOGRAPHYS
// } from './../../../themes/styles/bases/typographys'
// import {
//   default as UTILITIES
// } from './../../../themes/styles/helpers/utilities'
import _ from 'lodash';
import { getLanguage } from '../../../helpers/functions/language';
/**
 * PaginationControl description:
 * - PaginationControl
 */

export class PaginationControl extends React.PureComponent {
  render() {
    const {
      className
    } = this.props

    // props for css classes
    const classes = ClassNames(
      className,
      'is-default'
      // { 'is-modifier-name': modifierName }
    )

    return (
      <PaginationControlBase
        {...this.props}
        className={classes}
      />
    )
  }

  static defaultProps = {
    // * If you set new default props, you should create propsType for correct styleguide document.
    hideFirstLastPages: true,
    prevPageText: getLanguage('pagination.previous', ''),
    nextPageText: getLanguage('pagination.next', '')
  }

  static propTypes = {
    // TYPE > node, string, number, bool, array, object, symbol, func
    /**
    * props_name_oneType
    */
    // props_name_onetype: PropTypes.string,

    /**
    * props_name_oneMoreType
    */
    // props_name_oneMoreType: PropTypes.oneOfType([
    //   PropTypes.node,
    //   PropTypes.string
    // ])
  }
}
