import apiService from '../apiService'
import { BASE_CMS_API, BASE_CMS_PORT_API, BASE_CMS_PATH_API } from '../../config/config'

const apiPath = '/terms'

export const cmsTncController = (configService = {}) => {
  configService.baseApiPath = BASE_CMS_API + BASE_CMS_PORT_API + BASE_CMS_PATH_API;
  const service = apiService(configService)
  return {
    searchTermsCondition: (params) => {
      return service.post(`${apiPath}/search`, params)
    },
  }
}
