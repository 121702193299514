
import _ from 'lodash';
import React from 'react';
import {
  Breadcrumb, Button, Field, Grid, Section,
  TitlePage
} from '../../components';
import { WarrantyTemplateListContainerConnected } from '../../containers/WarrantyTemplateListContainer/WarrantyTemplateListContainer';
import { DATA } from '../../helpers/constants/data';
import { ROUTE_PATH } from '../../helpers/constants/route';
import { getLanguage } from '../../helpers/functions/language';
import { redirect, redirectData, setSideMenuActive } from '../../helpers/functions/route';
import { getProjectDocumentInformationData } from '../../helpers/services/projectDocument';
import { getClearWarrantyTemplateParams, getWarrantyTemplateList } from '../../helpers/services/warrantyTemplate';
import {
  ICONS
} from '../../themes';

export class WarrantyTemplateScreen extends React.Component {

  constructor(props) {
    super(props);
    setSideMenuActive(props);

    this.state = {
      searchValue: '',
      // searchM3ProductGroupName: undefined,
      // searchM3ProductGroupCode: undefined,
      // searchM3ProductColor: undefined,
      // m3ProductGroupNameOptionList: [],
      // m3ProductGroupCodeOptionList: [],
      // m3ProductColorOptionList: [],
    }
  }

  componentDidMount() {
    this.projectDocument.handleCheckProjectDocumentDetail();
  }

  projectDocument = {
    handleCheckProjectDocumentDetail: async () => {
      const params = new URLSearchParams(this.props.location.search);
      const preSalesTransId = parseInt(params.get('preSalesTransId'));
      console.log('preSalesTransId', preSalesTransId)
      // this.warrantyTemplate.getData();
      if (preSalesTransId) {
        let newProps = _.cloneDeep(this.props);
        newProps.match.path = ROUTE_PATH.PROJECT_DOCUMENT_MANAGE.LINK;
        setSideMenuActive(newProps);
        getProjectDocumentInformationData(preSalesTransId).then(result => {
          if (result) {
            const preTransData = {
              customerData: result.customerData,
              data: result.data,
              segmentSelected: result.segmentSelected
            };
            console.log('preTransData', preTransData);
            this.setState({
              preTransData
            });
          }
        });
      }
    },
    onClickBackProjectDocumentManage: () => {
      const {
        preTransData,
      } = this.state;
      redirectData(`${ROUTE_PATH.PROJECT_DOCUMENT_MANAGE.LINK}/${preTransData.data.preSalesTransId}`, {
        data: {
          tabType: DATA.PROJECT_DOCUMENT_TAB_TYPE_LIST[1]
        }
      });
    }
  }

  warrantyTemplate = {
    // getData: async () => {
    //   const m3ProductGroupList = await getWarrantyTemplateM3ProductGroupList();
    //   const m3ProductGroupCodeOptionList = [];
    //   const m3ProductGroupNameOptionList = [];
    //   m3ProductGroupList.forEach(e => {
    //     m3ProductGroupCodeOptionList.push({
    //       label: e.m3ProductGroupCode,
    //       value: e.m3ProductGroupCode,
    //     });
    //     m3ProductGroupNameOptionList.push({
    //       label: e.m3ProductGroupName,
    //       value: e.m3ProductGroupName,
    //     });
    //   })
    //   const m3ProductColorOptionList = await getWarrantyTemplateMappingColor();
    //   this.setState({
    //     m3ProductGroupCodeOptionList: _.orderBy(_.uniqBy(m3ProductGroupCodeOptionList, 'value'), 'value'),
    //     m3ProductGroupNameOptionList: _.orderBy(_.uniqBy(m3ProductGroupNameOptionList, 'value'), 'value'),
    //     m3ProductColorOptionList: _.orderBy(_.uniqBy(m3ProductColorOptionList, 'value'), 'value'),
    //   })
    // },
    onClickSearch: () => {
      const {
        searchValue,
        // searchM3ProductColor,
        // searchM3ProductGroupName,
        // searchM3ProductGroupCode,
      } = this.state;
      const params = {
        ...getClearWarrantyTemplateParams(),
        // m3_product_group_code: (searchM3ProductGroupCode || {}).value,
        // m3_prod_grp_name: (searchM3ProductGroupName || {}).value,
        // product_group_name: (searchM3ProductGroupName || {}).value,
        template_name: searchValue,
        // color: (searchM3ProductColor || {}).value,
        page: 1,
      }
      getWarrantyTemplateList(params);
    }
  }

  input = {
    // onChangeSelectOption: (target, select) => {
    //   this.setState({
    //     [target]: select
    //   })
    // },
    onChangeInput: (ev) => {
      const name = ev.target.name;
      const value = ev.target.value;
      this.setState({
        [name]: value
      })
    }
  }

  render() {
    const {
      searchValue,
      // searchM3ProductGroupName,
      // searchM3ProductGroupCode,
      // searchM3ProductColor,
      // m3ProductGroupNameOptionList,
      // m3ProductGroupCodeOptionList,
      // m3ProductColorOptionList,
      preTransData,
    } = this.state;
    return (
      <React.Fragment>
        <Breadcrumb>
          <Breadcrumb.List
            link
            name={getLanguage('container.warrantyTemplate.breadcrumb1')}
            onClick={() => {
              redirect(ROUTE_PATH.HOME.LINK)
            }}
          />
          <Breadcrumb.List name='/' />
          {preTransData ?
            <React.Fragment>
              <Breadcrumb.List
                link
                name={getLanguage('projectDocument.breadcrumb2', '')}
                onClick={this.projectDocument.onClickBackProjectDocumentManage}
              />
              <Breadcrumb.List name="/" />
              <Breadcrumb.List
                link
                name={preTransData.customerData.fullname}
                onClick={this.projectDocument.onClickBackProjectDocumentManage}
              />
              <Breadcrumb.List name="/" />
              <Breadcrumb.List
                link
                name={preTransData.data.projectName}
                onClick={this.projectDocument.onClickBackProjectDocumentManage}
              />
              <Breadcrumb.List name="/" />
              <Breadcrumb.List
                name={getLanguage('projectDocument.breadcrumb4', '')}
              />
            </React.Fragment>
            :
            <Breadcrumb.List name={getLanguage('container.warrantyTemplate.breadcrumb2')} />
          }
        </Breadcrumb>
        <Section ui='content'>
          <Section spacingBottom='25'>
            <Grid
              gutter='30'
              gutterVertical='30'
              justify='space-between'
            >
              <Grid.Column>
                <TitlePage
                  src={ICONS['ic-page-warranty.svg']}
                  name={getLanguage('container.warrantyTemplate.title')}
                />
              </Grid.Column>
              <Grid.Column>
                <Section relative top='-7'>
                  <Grid gutter='15' align='center'>
                    {/* <Grid.Column>
                      <Section relative top='-12'>
                        <SelectControl
                          filter
                          verticalLabel
                          isSearchable={false}
                          label={getLanguage('container.warrantyTemplate.filter.label.m3ProductCode')}
                          placeholder={getLanguage('container.warrantyTemplate.placeholder.selectM3ProductCode')}
                          // defaultValue='all'
                          value={searchM3ProductGroupCode}
                          onChange={(ev) => this.input.onChangeSelectOption('searchM3ProductGroupCode', ev)}
                          options={m3ProductGroupCodeOptionList}
                        />
                      </Section>
                    </Grid.Column>
                    <Grid.Column>
                      <Section relative top='-12'>
                        <SelectControl
                          filter
                          verticalLabel
                          isSearchable={false}
                          label={getLanguage('container.warrantyTemplate.filter.label.m3ProductGroup')}
                          placeholder={getLanguage('container.warrantyTemplate.placeholder.selectM3ProductGroup')}
                          // defaultValue='all'
                          value={searchM3ProductGroupName}
                          onChange={(ev) => this.input.onChangeSelectOption('searchM3ProductGroupName', ev)}
                          options={m3ProductGroupNameOptionList}
                        />
                      </Section>
                    </Grid.Column>
                    <Grid.Column>
                      <Section relative top='-12'>
                        <SelectControl
                          filter
                          verticalLabel
                          isSearchable={false}
                          label={getLanguage('container.warrantyTemplate.filter.label.m3ProductColor')}
                          placeholder={getLanguage('container.warrantyTemplate.placeholder.selectM3ProductColor')}
                          // defaultValue='all'
                          value={searchM3ProductColor}
                          onChange={(ev) => this.input.onChangeSelectOption('searchM3ProductColor', ev)}
                          options={m3ProductColorOptionList}
                        />
                      </Section>
                    </Grid.Column> */}
                    <Grid.Column>
                      <Field name='searchValue'
                        ui='blockLabelVerticalSearch'
                        width='240'
                        placeholder={getLanguage('container.warrantyTemplate.placeholder.searchTemplateName')}
                        value={searchValue}
                        onChange={this.input.onChangeInput}
                      />
                    </Grid.Column>
                    <Grid.Column>
                      <Button
                        blue
                        width='100'
                        name={getLanguage('field.buttonSearch')}
                        onClick={this.warrantyTemplate.onClickSearch}
                      />
                    </Grid.Column>
                  </Grid>
                </Section>
              </Grid.Column>
            </Grid>
          </Section>
          <WarrantyTemplateListContainerConnected
            preTransData={preTransData}
            onClickBackProjectDocumentManage={this.projectDocument.onClickBackProjectDocumentManage}
          />
        </Section>
      </React.Fragment>
    )
  }
}