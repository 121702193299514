import styled, {
  // css
} from 'styled-components'
// import {
//   default as VARIABLES
// } from './../../../themes/styles/bases/variables'
// import {
//   default as TYPOGRAPHYS
// } from './../../../themes/styles/bases/typographys'
// import {
//   default as MIXINS
// } from './../../../themes/styles/helpers/mixins'

// Sample using image in style
// url(${require('./../../../themes/images/icons/icon-sample.svg')});

// Wrapper
// ============================================================
export const TableActionWrapper = styled.div`
  /* Parent
  ------------------------------- */
  width: 100%;

  /* Childrens
  ------------------------------- */
  .table-action-right {}

  .table-action-left {}

  /* Modifiers
  ------------------------------- */
  &.is-vertical-align-bottom {
    > .grid-base {
      > .grid-container-base {
        align-items: flex-end;
      }
    }
  }

  /* Media queries
  ------------------------------- */
`
