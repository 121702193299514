const IS_MAINTENANCE = false;

const MAINTENANCE_TIME = 'วันที่ 29 ตุลาคม 2566 เวลา 16.00 ถึง วันที่ 30 ตุลาคม 2566 เวลา 06.00'

const MAINTENANCE_USER_WHITE_LIST_STG = [
  'testken_stg@tbtest.com',
];

const MAINTENANCE_USER_WHITE_LIST_PRD = [
  'testm@bstest.com',
  'ploy@prd.com'
];

const MAINTENANCE_USER_WHITE_LIST = MAINTENANCE_USER_WHITE_LIST_PRD;

module.exports = {
  IS_MAINTENANCE,
  MAINTENANCE_TIME,
  MAINTENANCE_USER_WHITE_LIST,
}