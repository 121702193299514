import React from 'react'
import ClassNames from 'classnames'
import PropTypes from 'prop-types'
import {
  TableActionWrapper
} from './styled'
import {
  Grid,
  Button,
  Section,
  Text
} from './../../../components'
import {
  default as VARIABLES
} from './../../../themes/styles/bases/variables'
// import {
//   default as TYPOGRAPHYS
// } from './../../../themes/styles/bases/typographys'
// import {
//   default as UTILITIES
// } from './../../../themes/styles/helpers/utilities'
import {
  ICONS
} from './../../../themes'

/**
 * TableAction description:
 * - TableAction
 */

class TableActionRight extends React.PureComponent {
  render() {
    const {
      className,
      children
    } = this.props

    // props for css classes
    const classes = ClassNames(
      'table-action-right',
      // {'is-modifier-name': modifierName},
      className
    )

    return (
      <Grid.Column className={classes}>
        {children}
      </Grid.Column>
    )
  }

  static defaultProps = {
    // * If you set new default props, you should create propsType for correct styleguide document.
  }

  static propTypes = { // TYPE > node, string, number, bool, array, object, symbol, func
    /**
    * Additional classes
    */
    className: PropTypes.string
  }
}

class TableActionLeft extends React.PureComponent {
  render () {
    const {
      className,
      children
    } = this.props

    // props for css classes
    const classes = ClassNames(
      'table-action-left',
      // {'is-modifier-name': modifierName},
      className
    )

    return (
      <Grid.Column className={classes}>
        {children}
      </Grid.Column>
    )
  }

  static defaultProps = {
    // * If you set new default props, you should create propsType for correct styleguide document.
  }

  static propTypes = { // TYPE > node, string, number, bool, array, object, symbol, func
    /**
    * Additional classes
    */
    className: PropTypes.string
  }
}

export class TableAction extends React.PureComponent {
  render () {
    const {
      className,
      children
    } = this.props

    // props for css classes
    const classes = ClassNames(
      'table-action',
      // {'is-modifier-name': modifierName},
      className
    )

    return (
      <TableActionWrapper className={classes}>
        <Grid justify='space-between'>
          {children}
        </Grid>
      </TableActionWrapper>
    )
  }

  static Right = TableActionRight
  static Left = TableActionLeft

  static defaultProps = {
    // * If you set new default props, you should create propsType for correct styleguide document.
  }

  static propTypes = { // TYPE > node, string, number, bool, array, object, symbol, func
    /**
    * Additional classes
    */
    className: PropTypes.string
  }
}
