import _ from 'lodash';
import React from 'react';
import { DATA, DATE_FORMAT } from '../../helpers/constants/data';
import { ENUM } from '../../helpers/constants/enum';
import { LOCAL_STORAGE } from '../../helpers/constants/localStorage';
import { getUserAuthRedux } from '../../helpers/functions/auth';
import { displayDate } from '../../helpers/functions/display';
import { getLocalStorage } from '../../helpers/functions/localStorage';
import { isNumber } from '../../helpers/functions/validation';
import { getWarrantyProjectTemplateData } from '../../helpers/functions/warranty';
import {
  Warranty,
  WarrantyBSWMIndonesia,
  WarrantyBSWMMalaysia,
  WarrantyBSWMThailand,
  WarrantyBSWMVietnam,
  WarrantyVietnam
} from './../../components';
import {
  VARIABLES,
} from './../../themes';
import { WarrantyCMSTemplate } from './WarrantyCMSTemplate';
import { WarrantyTemplateTermsAndConditions } from './WarrantyTemplateTermsAndConditions';

export class WarrantyTemplate extends React.Component {

  constructor(props) {
    super(props);
    const userAuth = getUserAuthRedux();
    this.state = {
      userAuth,
    }
  }

  warrantyTemplate = {
    getFullCountry: (data) => {
      switch (data) {
        case ENUM.COUNTRY_TYPE.INDONESIA:
          return ENUM.COUNTRY.INDONESIA;
        case ENUM.COUNTRY_TYPE.MALAYSIA:
          return ENUM.COUNTRY.MALAYSIA;
        default:
          return data || '';
      }
    },
    getCustomerAddress: () => {
      const {
        data,
      } = this.props;

      let customerAddress = '';
      switch (data.projectCountry) {
        case ENUM.COUNTRY_TYPE.THAI:
          customerAddress = `${data.projectAddr}
          ${data.projectAlley || ''}
          ${data.projectGroup || ''}
          ${data.projectStreet || ''}
          ${data.projectWard || ''}
          ${(data.projectSubDist || {}).label || ''}
          ${data.projectDist || ''}
          ${data.selectedCity ? (data.selectedCity.value === '-' ? '' : data.selectedCity.label) : ''}
          ${data.selectedProvince ? `${data.selectedProvince.label},` : ''}
          ${data.projectPostCode ? `${(data.projectPostCode || {}).label},` : ''}
          ${this.warrantyTemplate.getFullCountry(data.projectCountry)}`;
          break;
        case ENUM.COUNTRY_TYPE.MALAYSIA:
        case ENUM.COUNTRY_TYPE.INDONESIA:
          customerAddress = `${data.projectAddr}
         ${data.projectAlley || ''}
         ${data.projectGroup || ''}
         ${data.projectStreet || ''}
         ${data.projectWard || ''}
         ${data.projectSubDist || ''}
         ${data.projectDist || ''}
         ${data.selectedCity ? (data.selectedCity.value === '-' ? '' : data.selectedCity.label) : ''}
         ${data.selectedProvince ? `${data.selectedProvince.label},` : ''}
         ${data.projectPostCode ? `${data.projectPostCode},` : ''}
         ${this.warrantyTemplate.getFullCountry(data.projectCountry)}`;
          break;
        case ENUM.COUNTRY_TYPE.VIETNAM:
          customerAddress = `${data.projectAddr}
         ${data.projectAlley || ''}
         ${data.projectGroup || ''}
         ${data.projectStreet || ''}
         ${data.projectWard || ''}
         ${data.projectSubDist || ''}
         ${data.projectDist || ''}
         ${data.selectedCity ? data.selectedCity.label : ''}
         ${data.selectedProvince ? `${data.selectedProvince.label}` : ''}`;
          break;
        //TODO: laos
        case ENUM.COUNTRY_TYPE.LAOS:
          customerAddress = `${data.projectAddr}
          ${data.projectAlley || ''}
          ${data.projectGroup || ''}
          ${data.projectStreet || ''}
          ${data.projectWard || ''}
          ${data.projectSubDist || ''}
          ${data.projectDist || ''}
          ${data.selectedCity ? data.selectedCity : ''}
          ${data.selectedProvince ? `${data.selectedProvince},` : ''}
          ${data.projectPostCode ? `${data.projectPostCode},` : ''}
          ${data.projectCountry}`;
          break;
      }

      return customerAddress;
    },
    renderWarrantyCountryTemplate: () => {
      const {
        data,
        dataGroupList,
        warrantyDetail,
        setComponentTemplateRef
      } = this.props;
      const {
        userAuth,
      } = this.state;

      const dataDetail = dataGroupList[0] || {};
      // console.log('dataDetail', dataDetail.templateForm, dataDetail.productGroupName, dataDetail.wrtNo, dataDetail.wrtId);
      const customerPhone = `+${data.projectPhoneCountry.value} ${data.projectPhone}`;
      const customerAddress = this.warrantyTemplate.getCustomerAddress();
      const projectInsDate = displayDate(data.projectInsDateTime, '/', DATE_FORMAT);
      const projectCreatedDate = displayDate(data.createdDateTime, '/', DATE_FORMAT);
      const projectPurchasedDate = displayDate(data.purchasedDateTime, '/', DATE_FORMAT);

      const isTncNewPage = (
        data.projectCountry === ENUM.COUNTRY_TYPE.THAI ||
        data.projectCountry === ENUM.COUNTRY_TYPE.LAOS
      )

      const displayTransactionId = dataGroupList.map(e => e.transactionId).join(', ');

      const handleEnvDetailData = (data = {}, newData = {}) => {
        let envDetail = data;
        if (dataDetail.isWarrantyPeriodSelected) {
          if (isNumber(envDetail.corrosion)) {
            envDetail.corrosion = newData.corrosion;
          }
          if (isNumber(envDetail.peelFlake)) {
            envDetail.peelFlake = newData.peelFlake;
          }
          if (isNumber(envDetail.colorFade)) {
            envDetail.colorFade = newData.colorFade;
          }
          if (isNumber(envDetail.dirtStain)) {
            envDetail.dirtStain = newData.dirtStain;
          }
          // console.log('envDetail shorter', envDetail);
        }
        return envDetail;
      }

      const handleDisplayEnvDetail = (item = {}) => {
        let envDetail = item;
        if (
          userAuth.addressType === ENUM.COUNTRY_TYPE.THAI &&
          data.isCMSTemplate
        ) {
          return envDetail;
        }

        //if value = 'n/a' or '0' display empty
        if (envDetail.corrosion === DATA.WARRANTY_ENV_TYPE.NA || envDetail.corrosion === '0' || envDetail.corrosion === 0) {
          envDetail.corrosion = '';
        }
        if (envDetail.peelFlake === DATA.WARRANTY_ENV_TYPE.NA || envDetail.peelFlake === '0' || envDetail.peelFlake === 0) {
          envDetail.peelFlake = '';
        }
        if (envDetail.colorFade === DATA.WARRANTY_ENV_TYPE.NA || envDetail.colorFade === '0' || envDetail.colorFade === 0) {
          envDetail.colorFade = '';
        }
        if (envDetail.dirtStain === DATA.WARRANTY_ENV_TYPE.NA || envDetail.dirtStain === '0' || envDetail.dirtStain === 0) {
          envDetail.dirtStain = '';
        }
        return envDetail;
      }

      let envDetail = {
        corrosion: 0,
        peelFlake: 0,
        colorFade: 0,
        dirtStain: 0,
        corrosionWall: 0,
        peelFlakeWall: 0,
        colorFadeWall: 0,
        dirtStainWall: 0,
      };

      // CR: remove merged roof and wall for thai
      const findTemplateSelectList = _.get(dataDetail, 'templateList', []).filter(e =>
        e.template === dataDetail.templateForm &&
        dataDetail[e.applicationForSelect]
      );

      const getEnvDetailByApplication = (applicationForSelect) => {
        let envDetail = {
          corrosion: 0,
          peelFlake: 0,
          colorFade: 0,
          dirtStain: 0,
        };

        const findTemplate = findTemplateSelectList.filter(e => e.applicationForSelect === applicationForSelect)[0];
        if (findTemplate) {
          let envDetailSelect = findTemplate.envList[findTemplate.detailEnvSelect];
          if (envDetailSelect) {
            envDetail = _.cloneDeep(envDetailSelect);
          }

          const envDetailWarrantyPeriod = dataDetail.templateList.filter(e =>
            e.isWarrantyPeriodAppSelect &&
            e.applicationForSelect === applicationForSelect
          )[0];
          if (envDetailWarrantyPeriod) {
            envDetail = handleEnvDetailData(envDetail, {
              corrosion: envDetailWarrantyPeriod.envDetail.corrosion.toString(),
              peelFlake: envDetailWarrantyPeriod.envDetail.peelFlake.toString(),
              colorFade: envDetailWarrantyPeriod.envDetail.colorFade.toString(),
              dirtStain: envDetailWarrantyPeriod.envDetail.dirtStain.toString(),
            });
          }
        }

        return handleDisplayEnvDetail(envDetail);
      }

      const envDetailRoof = getEnvDetailByApplication(DATA.WARRANTY_APP_DETAIL_NAME_SELECT_LIST[0]);
      const envDetailWall = getEnvDetailByApplication(DATA.WARRANTY_APP_DETAIL_NAME_SELECT_LIST[1]);

      // let isShowRoofAndWall = dataDetail.isRoofAndWall && data.projectCountry === ENUM.COUNTRY_TYPE.INDONESIA
      const isShowRoofAndWall = (
        dataDetail[DATA.WARRANTY_APP_DETAIL_NAME_SELECT_LIST[0]] &&
        dataDetail[DATA.WARRANTY_APP_DETAIL_NAME_SELECT_LIST[1]]
      );

      let isWallTemplateOnly = false;
      if (isShowRoofAndWall) {
        envDetail = {
          corrosion: envDetailRoof.corrosion,
          peelFlake: envDetailRoof.peelFlake,
          colorFade: envDetailRoof.colorFade,
          dirtStain: envDetailRoof.dirtStain,
          corrosionWall: envDetailWall.corrosion,
          peelFlakeWall: envDetailWall.peelFlake,
          colorFadeWall: envDetailWall.colorFade,
          dirtStainWall: envDetailWall.dirtStain,
        }
      }
      else {
        let findTemplate = findTemplateSelectList[0];

        if (findTemplate) {
          envDetail = getEnvDetailByApplication(findTemplate.applicationForSelect);
        }
        else {
          envDetail = handleDisplayEnvDetail(envDetail);
        }

        // CR: remove merged roof and wall for thai
        if (data.projectCountry === ENUM.COUNTRY_TYPE.THAI) {
          envDetail.corrosionWall = envDetailWall.corrosion;
          if (
            warrantyDetail.isWallTemplate &&
            findTemplateSelectList.length === 1 &&
            findTemplate.applicationForSelect === DATA.WARRANTY_APP_DETAIL_NAME_SELECT_LIST[1]
          ) {
            envDetail.corrosion = 0;
            isWallTemplateOnly = true;
          }
        }
      }

      const language = getLocalStorage(LOCAL_STORAGE.LANGUAGE_RBPTRUEBLUE);

      let WarrantyTemplateComponent;

      switch (userAuth.addressType) {
        case ENUM.COUNTRY_TYPE.INDONESIA:
          WarrantyTemplateComponent = this.warrantyTemplate.renderWarrantyTemplateIndo;
          break;
        case ENUM.COUNTRY_TYPE.VIETNAM:
          WarrantyTemplateComponent = this.warrantyTemplate.renderWarrantyTemplateVietnam;
          break;
        case ENUM.COUNTRY_TYPE.MALAYSIA:
          WarrantyTemplateComponent = this.warrantyTemplate.renderWarrantyTemplateMalay;
          break;
        case ENUM.COUNTRY_TYPE.THAI:
        default:
          WarrantyTemplateComponent = this.warrantyTemplate.renderWarrantyTemplateThai;
          break;
      }

      return (
        <React.Fragment>
          {dataDetail.isGenerateResidential ?
            dataDetail.wrtNo === '-' &&
            <React.Fragment>
              <div style={styles.warrantyMarkPreview} />
              {/* <div style={styles.warrantyMarkPreview2Page} /> */}
              {/* <div style={styles.warrantyMarkPreview3Page} /> */}
            </React.Fragment>
            :
            undefined
          }
          <WarrantyTemplateComponent
            setComponentTemplateRef={setComponentTemplateRef}
            data={data}
            warrantyDetail={warrantyDetail}
            dataGroupList={dataGroupList}
            dataDetail={dataDetail}
            customerPhone={customerPhone}
            customerAddress={customerAddress}
            projectInsDate={projectInsDate}
            projectCreatedDate={projectCreatedDate}
            projectPurchasedDate={projectPurchasedDate}
            currentDate={displayDate(new Date(), '/', DATE_FORMAT)}
            isTncNewPage={isTncNewPage}
            displayTransactionId={displayTransactionId}
            envDetail={envDetail}
            language={language}
            isShowRoofAndWall={isShowRoofAndWall}
            isWallTemplateOnly={isWallTemplateOnly}
          />
        </React.Fragment>
      )
    },
    renderWarrantyTemplateIndo: (props = {}) => {
      const {
        data,
        dataGroupList,
        customerAddress,
        customerPhone,
        envDetail,
        dataDetail,
        displayTransactionId,
        projectInsDate,
        currentDate,
        isShowRoofAndWall,
      } = props;

      const getWarrantyProjectTemplateComponent = () => {
        const WarrantyProjectTemplateComponent = getWarrantyProjectTemplateData(data, dataDetail).Component;
        return (
          <WarrantyProjectTemplateComponent
            isHideApplicationDetail={dataGroupList.filter(e => e.applicationInfoList.filter(ea => ea.building.length === 0).length).length > 0}
            dataGroupList={dataGroupList}
            installationDate={projectInsDate}
            customerName={data.customerName}
            projectName={data.projectName}
            additionalProjectInformation={data.customerRemark}
            projectAddress={customerAddress}
            perforateByCorrosionYear={envDetail.corrosion}
            peelOrFlakeExcessivelyYear={envDetail.peelFlake}
            colourFadeExcessivelyYear={envDetail.colorFade}
            discolourByDirtStainingYear={envDetail.dirtStain}
            isRoofAndWall={isShowRoofAndWall}
            perforateByCorrosionWallYear={envDetail.corrosionWall}
            peelOrFlakeExcessivelyWallYear={envDetail.peelFlakeWall}
            colourFadeExcessivelyWallYear={envDetail.colorFadeWall}
            discolourByDirtStainingWallYear={envDetail.dirtStainWall}
            roofArea={dataDetail.roof ? dataDetail.roof : '-'}
            thickness={_.get(dataDetail, 'thicknessList', []).join(', ')}
            width={''}
            grade={''}
            color={''}
            roofProfileNameInM2={''}
            wallProfileNameInM2={''}
            currentDate={currentDate}
            // registeredTrademarkName={dataDetail.productGroupName}
            roof={dataDetail[DATA.WARRANTY_APP_DETAIL_NAME_SELECT_LIST[0]] ? dataDetail.roof : ''}
            wall={dataDetail[DATA.WARRANTY_APP_DETAIL_NAME_SELECT_LIST[1]] ? dataDetail.wall : ''}
            purlinAndGirt={dataDetail[DATA.WARRANTY_APP_DETAIL_NAME_SELECT_LIST[2]] ? dataDetail.purlinGirt : ''}
            trussAndFrame={dataDetail[DATA.WARRANTY_APP_DETAIL_NAME_SELECT_LIST[3]] ? dataDetail.truFrame : ''}
            coolRoomPanel={dataDetail[DATA.WARRANTY_APP_DETAIL_NAME_SELECT_LIST[4]] ? dataDetail.coolRoom : ''}
            decking={dataDetail[DATA.WARRANTY_APP_DETAIL_NAME_SELECT_LIST[5]] ? dataDetail.decking : ''}
            // TODO: RL-724: check template warranty
            airDucting={dataDetail[DATA.WARRANTY_APP_DETAIL_NAME_SELECT_LIST[6]] ? dataDetail.airDucting : ''}
          />
        )
      }

      return (
        data.isGenerateResidential ?
          <Warranty
            ui='templateIndonesia'
            logo={dataDetail.templateLogo}
            // yearsPerforationCorrosion='2030'
            // yearColorFading='2030'
            warrantyNumber={dataDetail.wrtNo}
            transactionId={displayTransactionId}
            purchaseDate={data.displayCreatedDate}
            // installationFinishDate={projectInsDate}
            // rollFormerName='roll_former_name'
            projectName={data.projectName}
            projectOwnerName={data.customerName}
            projectOwnerPhoneNumber={customerPhone}
            projectAddress={customerAddress}
            tokoAdName={dataDetail.rollFormetName}
            tokoAdPhoneNumber={dataDetail.tokoPhone}
            contractorName={data.dealerName}
            contractorPhoneNumber={data.dealerPhone}
            // endUseApplication='2020/01/31'
            buildingAreaM2={data.areaBuilding}
            dataGroupList={dataGroupList}
          />
          :
          < WarrantyBSWMIndonesia >
            {getWarrantyProjectTemplateComponent()}
          </WarrantyBSWMIndonesia>
      )
    },
    renderWarrantyTemplateVietnam: (props = {}) => {
      const {
        data,
        dataGroupList,
        dataDetail,
        customerAddress,
        customerPhone,
        envDetail,
        projectCreatedDate,
        currentDate
      } = props;

      const getWarrantyProjectTemplateComponent = () => {
        const WarrantyProjectTemplateComponent = getWarrantyProjectTemplateData(data, dataDetail).Component;
        return (
          <WarrantyProjectTemplateComponent
            isHideApplicationDetail={dataGroupList.filter(e => e.applicationInfoList.filter(ea => ea.building.length === 0).length).length > 0}
            dataGroupList={dataGroupList}
            customerName={data.customerName}
            projectName={data.projectName}
            additionalProjectInformation={data.customerRemark}
            projectAddress={customerAddress}
            perforateByCorrosionYear={envDetail.corrosion}
            peelOrFlakeExcessivelyYear={envDetail.peelFlake}
            colourFadeExcessivelyYear={envDetail.colorFade}
            discolourByDirtStainingYear={envDetail.dirtStain}
            roofArea={dataDetail.roof ? dataDetail.roof : '-'}
            thickness={_.get(dataDetail, 'thicknessList', []).join(', ')}
            width={''}
            grade={''}
            color={''}
            roofProfileNameInM2={''}
            wallProfileNameInM2={''}
            currentDate={currentDate}
            registeredTrademarkName={dataDetail.productGroupName}
            roof={dataDetail[DATA.WARRANTY_APP_DETAIL_NAME_SELECT_LIST[0]] ? dataDetail.roof : ''}
            wall={dataDetail[DATA.WARRANTY_APP_DETAIL_NAME_SELECT_LIST[1]] ? dataDetail.wall : ''}
            purlinAndGirt={dataDetail[DATA.WARRANTY_APP_DETAIL_NAME_SELECT_LIST[2]] ? dataDetail.purlinGirt : ''}
            trussAndFrame={dataDetail[DATA.WARRANTY_APP_DETAIL_NAME_SELECT_LIST[3]] ? dataDetail.truFrame : ''}
            coolRoomPanel={dataDetail[DATA.WARRANTY_APP_DETAIL_NAME_SELECT_LIST[4]] ? dataDetail.coolRoom : ''}
            decking={dataDetail[DATA.WARRANTY_APP_DETAIL_NAME_SELECT_LIST[5]] ? dataDetail.decking : ''}
            // TODO: RL-724: check template warranty
            airDucting={dataDetail[DATA.WARRANTY_APP_DETAIL_NAME_SELECT_LIST[6]] ? dataDetail.airDucting : ''}
          />
        )
      }

      return (
        data.isGenerateResidential ?
          <WarrantyVietnam ui='template'
            isBzacsHcInok={dataDetail.templateForm === DATA.WARRANTY_TEMPLATE_VI_LIST[0]}
            isBzacsInok={dataDetail.templateForm === DATA.WARRANTY_TEMPLATE_VI_LIST[1]}
            isBzacsBenMau={dataDetail.templateForm === DATA.WARRANTY_TEMPLATE_VI_LIST[2]}
            isBzacsBenLanh={dataDetail.templateForm === DATA.WARRANTY_TEMPLATE_VI_LIST[3]}
            id={dataDetail.wrtNo}
            adName={data.dealerName}
            adAddress={data.dealerAddress}
            adPhone={data.dealerPhone}
            coilLot={dataGroupList.map(e => e.coilId).join(', ')}
            lengthSold={dataGroupList.map(e => e.coilOrderM).join(', ')}
            ownerName={data.customerName}
            ownerPhone={customerPhone}
            installationAddress={customerAddress}
            purchaseDate={projectCreatedDate.split('/')[0]}
            purchaseMonth={projectCreatedDate.split('/')[1]}
            purchaseYear={projectCreatedDate.split('/')[2]}
            // productBrand='BlueScope Zacs®+ Hoa Cương Công Nghệ INOK™'
            productBrand={dataDetail.productGroupName}
          />
          :
          <WarrantyBSWMVietnam>
            {getWarrantyProjectTemplateComponent()}
          </WarrantyBSWMVietnam>
      )
    },
    renderWarrantyTemplateMalay: (props = {}) => {
      const {
        data,
        dataGroupList,
        dataDetail,
        customerAddress,
        customerPhone,
        envDetail,
        projectInsDate,
        currentDate
      } = props;

      const getWarrantyProjectTemplateComponent = () => {
        const WarrantyProjectTemplateComponent = getWarrantyProjectTemplateData(data, dataDetail).Component;
        return (
          <WarrantyProjectTemplateComponent
            isHideApplicationDetail={dataGroupList.filter(e => e.applicationInfoList.filter(ea => ea.building.length === 0).length).length > 0}
            dataGroupList={dataGroupList}
            customerName={data.customerName}
            projectName={data.projectName}
            additionalProjectInformation={data.customerRemark}
            projectAddress={customerAddress}
            perforateByCorrosionYear={envDetail.corrosion}
            peelOrFlakeExcessivelyYear={envDetail.peelFlake}
            colourFadeExcessivelyYear={envDetail.colorFade}
            discolourByDirtStainingYear={envDetail.dirtStain}
            roofArea={dataDetail.roof ? dataDetail.roof : '-'}
            thickness={_.get(dataDetail, 'thicknessList', []).join(', ')}
            width={''}
            grade={''}
            color={''}
            roofProfileNameInM2={''}
            wallProfileNameInM2={''}
            currentDate={currentDate}
            // registeredTrademarkName={dataDetail.productGroupName}
            roof={dataDetail[DATA.WARRANTY_APP_DETAIL_NAME_SELECT_LIST[0]] ? dataDetail.roof : ''}
            wall={dataDetail[DATA.WARRANTY_APP_DETAIL_NAME_SELECT_LIST[1]] ? dataDetail.wall : ''}
            purlinAndGirt={dataDetail[DATA.WARRANTY_APP_DETAIL_NAME_SELECT_LIST[2]] ? dataDetail.purlinGirt : ''}
            trussAndFrame={dataDetail[DATA.WARRANTY_APP_DETAIL_NAME_SELECT_LIST[3]] ? dataDetail.truFrame : ''}
            coolRoomPanel={dataDetail[DATA.WARRANTY_APP_DETAIL_NAME_SELECT_LIST[4]] ? dataDetail.coolRoom : ''}
            decking={dataDetail[DATA.WARRANTY_APP_DETAIL_NAME_SELECT_LIST[5]] ? dataDetail.decking : ''}
            // TODO: RL-724: check template warranty
            airDucting={dataDetail[DATA.WARRANTY_APP_DETAIL_NAME_SELECT_LIST[6]] ? dataDetail.airDucting : ''}
          />
        )
      }

      return (
        data.isGenerateResidential ?
          <Warranty
            ui='templateMalaysia'
            // applicationList={data.applicationList}
            dataGroupList={dataGroupList}
            logo={dataDetail.templateLogo}
            warrantyNumber={dataDetail.wrtNo}
            setupDate={projectInsDate}
            projectName={data.projectName}
            phone={customerPhone}
            projectAddress={customerAddress}
            bsadName={data.dealerName}
            bsadMobile={data.dealerPhone}
            bsadAddress={data.dealerAddress}
            currentDate={currentDate}
          />
          :
          <WarrantyBSWMMalaysia>
            {getWarrantyProjectTemplateComponent()}
          </WarrantyBSWMMalaysia>
      )
    },
    renderWarrantyTemplateThai: (props = {}) => {
      const {
        data,
        dataGroupList,
        dataDetail,
        customerAddress,
        customerPhone,
        envDetail,
        projectInsDate,
        currentDate,
        language,
        warrantyDetail,
        setComponentTemplateRef,
        isWallTemplateOnly,
      } = props;
      const {
        userAuth,
      } = this.state;

      console.log('language', language, language === DATA.LANGUAGE.THAI);

      const getWarrantyProjectTemplateComponent = () => {
        const WarrantyProjectTemplateComponent = getWarrantyProjectTemplateData(data, dataDetail).Component;
        // let temp = [];
        // for (let i = 0; i < 10; i++) {
        //   if (dataGroupList[0]) {
        //     temp.push(dataGroupList[0].coilNameList[0] + i)
        //   }
        // }
        // if (dataGroupList[0]) {
        //   dataGroupList[0].displayCoil = temp
        //   dataGroupList[0].coilNameList = temp
        //   dataGroupList[0].coilIdList = temp
        // }

        // if (dataGroupList[0]) {
        //   dataGroupList[0].displayCoil = [
        //     ...dataGroupList[0].displayCoil,
        //     ...dataGroupList[0].displayCoil,
        //     ...dataGroupList[0].displayCoil,
        //     ...dataGroupList[0].displayCoil,
        //   ]
        //   dataGroupList[0].coilNameList = [
        //     ...dataGroupList[0].coilNameList,
        //     ...dataGroupList[0].coilNameList,
        //     ...dataGroupList[0].coilNameList,
        //     ...dataGroupList[0].coilNameList,
        //   ]
        //   dataGroupList[0].coilIdList = [
        //     ...dataGroupList[0].coilIdList,
        //     ...dataGroupList[0].coilIdList,
        //     ...dataGroupList[0].coilIdList,
        //     ...dataGroupList[0].coilIdList,
        //   ]
        // }
        return (
          <React.Fragment>
            <WarrantyProjectTemplateComponent
              isHideApplicationDetail={dataGroupList.filter(e => e.applicationInfoList.filter(ea => ea.building.length === 0).length).length > 0}
              dataGroupList={dataGroupList}
              subBrandName={''}
              // customerName={data.customerName}
              customerName={userAuth.company}
              projectName={data.projectName}
              additionalProjectInformation={data.customerRemark}
              projectAddress={customerAddress}
              perforateByCorrosionYear={envDetail.corrosion}
              peelOrFlakeExcessivelyYear={envDetail.peelFlake}
              colourFadeExcessivelyYear={envDetail.colorFade}
              discolourByDirtStainingYear={envDetail.dirtStain}
              roofArea={dataDetail.roof ? dataDetail.roof : '-'}
              thickness={_.get(dataDetail, 'thicknessList', []).join(', ')}
              width={''}
              grade={''}
              color={''}
              roofProfileNameInM2={''}
              wallProfileNameInM2={''}
              currentDate={currentDate}
              registeredTrademarkName={dataDetail.productGroupName}
              roof={dataDetail[DATA.WARRANTY_APP_DETAIL_NAME_SELECT_LIST[0]] ? dataDetail.roof : ''}
              wall={dataDetail[DATA.WARRANTY_APP_DETAIL_NAME_SELECT_LIST[1]] ? dataDetail.wall : ''}
              purlinAndGirt={dataDetail[DATA.WARRANTY_APP_DETAIL_NAME_SELECT_LIST[2]] ? dataDetail.purlinGirt : ''}
              trussAndFrame={dataDetail[DATA.WARRANTY_APP_DETAIL_NAME_SELECT_LIST[3]] ? dataDetail.truFrame : ''}
              coolRoomPanel={dataDetail[DATA.WARRANTY_APP_DETAIL_NAME_SELECT_LIST[4]] ? dataDetail.coolRoom : ''}
              decking={dataDetail[DATA.WARRANTY_APP_DETAIL_NAME_SELECT_LIST[5]] ? dataDetail.decking : ''}
              airDucting={dataDetail[DATA.WARRANTY_APP_DETAIL_NAME_SELECT_LIST[6]] ? dataDetail.airDucting : ''}
              thLanguage={language === DATA.LANGUAGE.THAI}
              isBluescopeZacsBare={(dataDetail.productGroupName || '').toLowerCase() === 'BlueScope Zacs Bare'.toLowerCase()}
              isBluescopeZacsPaint={(dataDetail.productGroupName || '').toLowerCase() === 'BlueScope Zacs Paint'.toLowerCase()}
            />
          </React.Fragment>
        )
      }

      return data.isCMSTemplate ?
        <WarrantyCMSTemplate
          setComponentTemplateRef={setComponentTemplateRef}
          warrantyDetail={warrantyDetail}
          data={data}
          envDetail={envDetail}
          customerAddress={customerAddress}
          dataDetail={dataDetail}
          dataGroupList={dataGroupList}
          customerPhone={customerPhone}
          projectInsDate={projectInsDate}
          isHideApplicationDetail={dataGroupList.filter(e => e.applicationInfoList.filter(ea => ea.building.length === 0).length).length > 0}
          isWallTemplateOnly={isWallTemplateOnly}
        // roof={dataDetail[DATA.WARRANTY_APP_DETAIL_NAME_SELECT_LIST[0]] ? dataDetail.roof : ''}
        // wall={dataDetail[DATA.WARRANTY_APP_DETAIL_NAME_SELECT_LIST[1]] ? dataDetail.wall : ''}
        // purlinAndGirt={dataDetail[DATA.WARRANTY_APP_DETAIL_NAME_SELECT_LIST[2]] ? dataDetail.purlinGirt : ''}
        // trussAndFrame={dataDetail[DATA.WARRANTY_APP_DETAIL_NAME_SELECT_LIST[3]] ? dataDetail.truFrame : ''}
        // coolRoomPanel={dataDetail[DATA.WARRANTY_APP_DETAIL_NAME_SELECT_LIST[4]] ? dataDetail.coolRoom : ''}
        // decking={dataDetail[DATA.WARRANTY_APP_DETAIL_NAME_SELECT_LIST[5]] ? dataDetail.decking : ''}
        // airDucting={dataDetail[DATA.WARRANTY_APP_DETAIL_NAME_SELECT_LIST[6]] ? dataDetail.airDucting : ''}
        />
        :
        (
          data.isGenerateResidential ?
            <React.Fragment>
              <Warranty
                ui='template'
                dataGroupList={dataGroupList}
                blueScopeZacs={dataDetail.templateForm === DATA.WARRANTY_TEMPLATE_LIST[0]}
                colorbond={dataDetail.templateForm === DATA.WARRANTY_TEMPLATE_LIST[1]}
                crp={dataDetail.templateForm === DATA.WARRANTY_TEMPLATE_LIST[2]}
                jingjoeLek={dataDetail.templateForm === DATA.WARRANTY_TEMPLATE_LIST[3]}
                prima={dataDetail.templateForm === DATA.WARRANTY_TEMPLATE_LIST[4]}
                superDymaDk={dataDetail.templateForm === DATA.WARRANTY_TEMPLATE_LIST[5]}
                superDymaPl={dataDetail.templateForm === DATA.WARRANTY_TEMPLATE_LIST[6]}
                trueCore={dataDetail.templateForm === DATA.WARRANTY_TEMPLATE_LIST[7]}
                zincalume={dataDetail.templateForm === DATA.WARRANTY_TEMPLATE_LIST[8]}
                logo={dataDetail.templateLogo}
                warrantyNumber={dataDetail.wrtNo}
                setupDate={projectInsDate}
                projectName={data.projectName}
                phone={customerPhone}
                projectAddress={customerAddress}
                bsadName={data.dealerName}
                bsadMobile={data.dealerPhone}
                bsadAddress={data.dealerAddress}
                roofAndWallLength={dataDetail.roof ? dataDetail.roof : '-'}
                purlinLength={dataDetail.purlinGirt ? dataDetail.purlinGirt : '-'}
                frameLength={dataDetail.truFrame ? dataDetail.truFrame : '-'}
                coldRoomWallLength={dataDetail.coolRoom ? dataDetail.coolRoom : '-'}
                slabsLength={dataDetail.decking ? dataDetail.decking : '-'}
                // TODO: RL-724: check template warranty
                airDuctingLength={dataDetail.airDucting ? dataDetail.airDucting : '-'}
                siteInspection='ไม่มีการตรวจสอบจากบริษัท เอ็นเอส บลูสโคป (ประเทศไทย) จำกัด'
                warrantyCreateDate={data.displayCreatedDate}
              />
              <WarrantyTemplateTermsAndConditions
                dataDetail={dataDetail}
              />
            </React.Fragment>
            :
            <WarrantyBSWMThailand>
              {getWarrantyProjectTemplateComponent()}
            </WarrantyBSWMThailand>
        )
    },
  }

  render() {
    const {
      innerRef,
    } = this.props;

    return (
      <div style={{
        position: 'absolute',
        left: '-9999px'
      }}>
        <div style={styles.warrantyPage}
          ref={innerRef}
        >
          {this.warrantyTemplate.renderWarrantyCountryTemplate()}
        </div>
      </div >
    )
  }
}

const styles = {
  warrantyPage: {
    backgroundColor: VARIABLES.COLORS.WHITE,
  },

  warrantyMarkPreview: {
    position: 'absolute',
    zIndex: VARIABLES.Z_INDEXS.LV_1,
    top: 0,
    left: 0,
    width: '100%',
    maxWidth: '1367px',
    height: '100%',
    backgroundImage: `url(${require('./../../themes/images/shareds/mark-preview.svg')})`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
  },

  warrantyMarkPreview2Page: {
    position: 'absolute',
    zIndex: VARIABLES.Z_INDEXS.LV_1,
    top: 0,
    left: 0,
    width: '100%',
    maxWidth: '1367px',
    height: '100%',
    backgroundImage: `url(${require('./../../themes/images/shareds/mark-preview-2p.svg')})`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
  },

  warrantyMarkPreview3Page: {
    position: 'absolute',
    zIndex: VARIABLES.Z_INDEXS.LV_1,
    top: 0,
    left: 0,
    width: '100%',
    maxWidth: '1367px',
    height: '100%',
    backgroundImage: `url(${require('./../../themes/images/shareds/mark-preview-3p.svg')})`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
  },
};
