import React from 'react';
import {
  connect,
} from 'react-redux';
import {
  ShapeContainer,
  Section,
  TableAction,
  Grid,
  Text,
  Button,
  PaginationControl,
  Table,
} from './../../components';
import {
  VARIABLES,
  ICONS
} from './../../themes';
import _ from 'lodash';
import { getLanguage } from '../../helpers/functions/language';
import { getUploadTransactionSearchFileList, getClearUploadTransactionHistoryParams } from '../../helpers/services/uploadTransactionHistory';
import { redirect } from '../../helpers/functions/route';
import { ROUTE_PATH } from '../../helpers/constants/route';

class UploadTransactionHistoryListContainer extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      activePage: 1,
      dataPerPage: 10,
    }
  }

  componentDidMount() {
    const {
      activePage,
      dataPerPage,
    } = this.state;
    const params = {
      ...getClearUploadTransactionHistoryParams(),
      page: activePage,
      limit: dataPerPage,
    };
    getUploadTransactionSearchFileList(params);
  }

  uploadTransactionHistory = {
    onClickSort: (target) => {
      getUploadTransactionSearchFileList({
        isNoSort: false,
        sort: target
      });
    },
  }

  pagination = {
    onPaginationChange: (pageNumber) => {
      getUploadTransactionSearchFileList({
        isNoSort: true,
        page: pageNumber,
      });
    }
  }

  render() {
    const {
      dataList,
      totalData,
      activePage,
      dataParams,
    } = this.props;
    const {
      dataPerPage,
    } = this.state;
    return (
      <ShapeContainer
        ui='content'
        justify='space-between'
      >
        <Section spacingBottom='45'>
          <Table
            striped
            action
          >
            <Table.Section>
              <Table.Row>
                <Table.HeadColumn
                  noSort
                  maxWidth={VARIABLES.TABLE.INFO_WIDTHS.ID}
                  minWidth={VARIABLES.TABLE.INFO_WIDTHS.ID}
                // sortAscending
                // onClickSort={() => {}}
                >
                  {getLanguage('uploadTransactionHistory.table.header.id', '')}
                </Table.HeadColumn>
                <Table.HeadColumn
                  noSort
                  minWidth={VARIABLES.TABLE.INFO_WIDTHS.NAME}
                // sortAscending
                // onClickSort={() => {}}
                >
                  {getLanguage('uploadTransactionHistory.table.header.fileName', '')}
                </Table.HeadColumn>
                <Table.HeadColumn
                  noSort
                  maxWidth={VARIABLES.TABLE.INFO_WIDTHS.DATE_UPLOADED_FILE}
                  minWidth={VARIABLES.TABLE.INFO_WIDTHS.DATE_UPLOADED_FILE}
                // sortAscending
                // onClickSort={() => {}}
                >
                  {getLanguage('uploadTransactionHistory.table.header.uploadedDate', '')}
                </Table.HeadColumn>

                <Table.HeadColumn
                  noSort
                  alignCenter
                  maxWidth={VARIABLES.TABLE.INFO_WIDTHS.ACTION_DETAIL}
                  minWidth={VARIABLES.TABLE.INFO_WIDTHS.ACTION_DETAIL}
                // sortAscending
                // onClickSort={() => {}}
                >
                  {getLanguage('uploadTransactionHistory.table.header.actions', '')}
                </Table.HeadColumn>
              </Table.Row>
            </Table.Section>
            <Table.Section>
              {dataList.map((e, i) => {
                return (
                  <Table.Row key={i}>
                    <Table.BodyColumn
                      info
                      maxWidth={VARIABLES.TABLE.INFO_WIDTHS.ID}
                      minWidth={VARIABLES.TABLE.INFO_WIDTHS.ID}
                    >
                      {e.idWithPrefix}
                    </Table.BodyColumn>
                    <Table.BodyColumn
                      info
                      minWidth={VARIABLES.TABLE.INFO_WIDTHS.NAME}
                    >
                      {e.fileName}.1
              </Table.BodyColumn>
                    <Table.BodyColumn
                      info
                      maxWidth={VARIABLES.TABLE.INFO_WIDTHS.DATE_UPLOADED_FILE}
                      minWidth={VARIABLES.TABLE.INFO_WIDTHS.DATE_UPLOADED_FILE}
                    >
                      {e.displayCreatedAt}
                    </Table.BodyColumn>
                    <Table.BodyColumn
                      action
                      alignCenter
                      maxWidth={VARIABLES.TABLE.INFO_WIDTHS.ACTION_DETAIL}
                      minWidth={VARIABLES.TABLE.INFO_WIDTHS.ACTION_DETAIL}
                    >
                      <Button
                        blue
                        ui='frontIconSmall'
                        name={getLanguage('uploadTransactionHistory.table.header.buttonDetail', '')}
                        iconWidth='10'
                        iconHeight='10'
                        iconSrc={ICONS['ic-detail.svg']}
                        onClick={() => {
                          redirect(`${ROUTE_PATH.UPLOAD_TRANSACTION_HISTORY_DETAIL.LINK}/${e.id}`);
                        }}
                      />
                    </Table.BodyColumn>
                  </Table.Row>
                )
              })}
            </Table.Section>
          </Table>
        </Section>
        <Section justify='flex-end'>
          <PaginationControl
            activePage={activePage}
            itemsCountPerPage={dataPerPage}
            totalItemsCount={totalData}
            pageRangeDisplayed={3}
            onChange={this.pagination.onPaginationChange}
          />
        </Section>
      </ShapeContainer>
    )
  }
}

const mapStateToProps = (state) => {
  const dataRedux = state.dataRedux;
  return {
    dataList: dataRedux.uploadTransactionHistoryList,
    dataParams: dataRedux.uploadTransactionHistoryParams,
    activePage: dataRedux.uploadTransactionHistoryParams.activePage,
    totalData: dataRedux.uploadTransactionHistoryParams.totalData || 0,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
  }
}

export const UploadTransactionHistoryListContainerConnected = connect(
  mapStateToProps,
  mapDispatchToProps
)(UploadTransactionHistoryListContainer)