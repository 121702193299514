import { displayWithComma, displayWithPrefix, displayUrlPath } from "../helpers/functions/display";
import { getCustomerTypeText, getPhoneRemovePrefix, getCountryPhone, getProvinceByLabel, getCityByLabel, convertString } from "../helpers/functions/data";
import { ModelAddress } from "./ModelAddress";
import { ENUM } from "../helpers/constants/enum";
import { FIREBASE_PUBLIC_STORAGE_PATH } from "../config/config";

export class ModelCustomer {
  constructor(props = {}) {
    this.oldIdAddress = props.address;
    this.phone = getPhoneRemovePrefix(props.phone);
    this.phoneCountry = getCountryPhone(props.phone);
    let idAddressGroup = {};
    this.idPostCode = props.post_code === 'undefined' ? '0' : props.post_code || '';
    try {
      if (props.address) {
        idAddressGroup = new ModelAddress(props.address);
      }
      else {
        idAddressGroup = {
          address: props.house_number__c,
          subDistrict: props.district__c,
          selectedProvince: getProvinceByLabel(props.province__c),
          selectedCity: getCityByLabel(props.city__c),
          street: props.road__c,
          alley: props.alley__c,
          group: props.hamlet__c,
          ward: props.ward__c,
        };
        this.idPostCode = props.post_code__c ? props.post_code__c : '';
      }
    } catch { }
    finally {
      this.idAddress = idAddressGroup.address || '';
      this.idSelectedProvince = idAddressGroup.selectedProvince;
      this.idSelectedCity = idAddressGroup.selectedCity;
      this.idDistrict = idAddressGroup.district || '';
      this.idSubDistrict = idAddressGroup.subDistrict || '';
      this.idAlley = idAddressGroup.alley || '';
      this.idGroup = idAddressGroup.group || '';
      this.idStreet = idAddressGroup.street || '';
      this.idWard = idAddressGroup.ward || '';
      this.idAddressType = idAddressGroup.addressType || this.phoneCountry.countryType;
    }
    this.authorizeDealerId = props.authorize_dealer_id;
    this.company = props.company;
    this.oldContactAddress = props.contact_address;
    let contactAddressGroup = {};
    try {
      contactAddressGroup = new ModelAddress(props.contact_address);
    } catch { }
    finally {
      this.contactAddress = contactAddressGroup.address || '';
      this.contactSelectedProvince = contactAddressGroup.selectedProvince;
      this.contactSelectedCity = contactAddressGroup.selectedCity;
      this.contactDistrict = contactAddressGroup.district || '';
      this.contactSubDistrict = contactAddressGroup.subDistrict || '';
      this.contactAlley = contactAddressGroup.alley || '';
      this.contactGroup = contactAddressGroup.group || '';
      this.contactStreet = contactAddressGroup.street || '';
      this.contactWard = contactAddressGroup.ward || '';
      this.contactAddressType = contactAddressGroup.addressType || this.phoneCountry.countryType;
    }
    this.contactPostCode = props.contact_post_code === 'undefined' ? '0' : props.contact_post_code || '';
    this.contactChannel = props.contact_channel || '';
    this.contactPhone = props.contact_phone || '';
    this.createdAt = props.created_at;
    this.customerRefId = props.customer_ref_id;
    this.dateOfBirth = props.date_of_birth;
    this.email = props.email;
    this.firstname = props.first_name;
    this.id = props.id;
    this.idWithPrefix = displayWithPrefix(props.id);
    this.idCardNo = props.id_card_no;
    this.oldImageRef = (props.image_url || '').replace(`${FIREBASE_PUBLIC_STORAGE_PATH}/`, '');
    this.imageUrl = displayUrlPath(this.oldImageRef);
    this.oldImageUrl = this.imageUrl;
    this.lastname = props.last_name;
    this.fullname = `${this.firstname} ${this.lastname}`;
    this.nickname = convertString(props.nick_name);
    this.isTrained = props.trained;
    // this.type = props.type;
    this.type = props.contractor_type__c;
    this.typeText = getCustomerTypeText(this.type);
    this.userRefId = props.user_ref_id;
    this.customerRefId = props.customer_ref_id;
    this.points = props.points ? props.points : 0;
    this.pointsWithComma = displayWithComma(props.points);
    this.remainPoints = props.remain_point ? Math.floor(props.remain_point) : 0;
    this.remainPointsWithComma = displayWithComma(this.remainPoints);
    this.redemptionPoints = props.redemption_points ? props.redemption_points : 0;
    this.redemptionPointsWithComma = displayWithComma(props.redemption_points);
    this.expirePoint = props.expire_point || 0;
    this.expirePointWithComma = displayWithComma(this.expirePoint);
    this.trainedTier__c = props.trained_tier__c;

    this.pointsRevenue = props.points_revenue || '';
    this.pointList = [];
    this.displayFullname = `${this.firstname || ''} ${this.lastname || ''}`;

    this.uid__c = props.uid__c;
    if ((this.trainedTier__c || '').toLowerCase().indexOf(ENUM.CUSTOMER_TRAINED_TIER.GOLD.TEXT.toLowerCase()) >= 0) {
      this.trainedTier__c = ENUM.CUSTOMER_TRAINED_TIER.GOLD.TEXT;
    }

    //TODO: loas
    if (this.idAddressType === ENUM.COUNTRY_TYPE.THAI) {
      this.idPostCode = {
        label: this.idPostCode,
        value: this.idPostCode,
      }
    }
    //TODO: loas
    if (this.contactAddressType === ENUM.COUNTRY_TYPE.THAI) {
      this.contactPostCode = {
        label: this.contactPostCode,
        value: this.contactPostCode,
      }
    }
  }
}