import _ from 'lodash'
import moment from 'moment'
import QrCode from 'qrcode.react'
import React from 'react'
import { Document, Page, pdfjs } from 'react-pdf'
import { DATA, DATE_FORMAT } from '../../helpers/constants/data'
import { LOCAL_STORAGE } from '../../helpers/constants/localStorage'
import { getUserAuthRedux } from '../../helpers/functions/auth'
import { zeroPad } from '../../helpers/functions/display'
import { getLanguage } from '../../helpers/functions/language'
import { getLocalStorage } from '../../helpers/functions/localStorage'
import { isShowLoading } from '../../helpers/functions/main'
import { getWatermarkTemplateDetailSearch, getWatermarkTemplateDetailSearchSubFolder } from '../../helpers/services/watermarkTemplate'
import { CONTENTS } from '../../themes'
import {
  Grid, Section, WarrantyGeneratePdfPage, Image
} from './../../components'

pdfjs.GlobalWorkerOptions.workerSrc =
  `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export class DocumentaryTemplate extends React.Component {

  constructor(props) {
    super(props);
    const language = getLocalStorage(LOCAL_STORAGE.LANGUAGE_RBPTRUEBLUE);
    const userAuth = getUserAuthRedux();
    this.state = {
      language,
      isLanguageEn: language === DATA.LANGUAGE.ENGLISH,
      isShowDocumentaryTemplate: true,
      documentaryNumPage: {},
      callbackDocumenrayLoadFinish: undefined,
      userAuth,
      isLoadWatermarkFinish: false,
      watermarkTemplateDataList: [],
    }
  }

  componentDidMount() {
    const {
      watermarkTemplateType,
      isModalPreviewDocument,
    } = this.props;
    if (
      isModalPreviewDocument && (
        watermarkTemplateType === DATA.WATERMARK_TEMPLATE_LIST[2].value ||
        watermarkTemplateType === DATA.WATERMARK_TEMPLATE_LIST[1].value
      )
    ) {
      isShowLoading(true);
      this.watermarkTemplate.getWatermarkTemplate().then(() => {
        isShowLoading(false);
      });
    }
  }

  watermarkTemplate = {
    handleReplaceData: (item, document) => {
      const {
        data,
        dataDetail,
        watermarkTemplateType,
        customerAddress,
      } = this.props;
      const {
        userAuth,
      } = this.state;
      // console.log('watermarkTemplate handleReplaceData', data, dataDetail)
      // Warranty Document
      item = item.replaceAll('[document_no]', document.wrtDocNo || '-');
      // if (watermarkTemplateType === DATA.WATERMARK_TEMPLATE_LIST[0].value) {
      item = item.replaceAll('[customer_name]', document.isSelectedSpecifyCompanyName ? (document.specifyCompanyName || '') : userAuth.company);
      // }
      // else {
      //   item = item.replaceAll('[customer_name]', document.isSelectedSpecifyCompanyName ? (document.specifyCompanyName || '') : _.get(data, 'customerData.displayFullname', ''));
      // }

      item = item.replaceAll('[date]', moment().format(DATE_FORMAT));

      let coilIdDescription = '';
      if ((document.displayCoil || []).length > 0) {
        coilIdDescription = document.displayCoil.join('<br/>');
      }
      item = item.replaceAll('[coil_id_description]', coilIdDescription);

      const customerProjectName = `${(data || {}).projectName} ${customerAddress || ''}`;
      item = item.replaceAll('[customer_project_address]', customerProjectName);
      item = item.replaceAll('[certificate_id]', `${document.preSalesTransCertDtCode ? document.preSalesTransCertDtCode : '-'}`);

      if (watermarkTemplateType === DATA.WATERMARK_TEMPLATE_LIST[0].value) {
        // CR: 1498
        item = item.replaceAll('[product_group]', _.get(document, 'oldProductGroupName', ''));
      } else {
        item = item.replaceAll('[product_group]', _.get(document, 'm3ProductGroupName', ''));
      }

      item = item.replaceAll('[customer_project_name]', customerProjectName);
      return item;
    },
    getWatermarkTemplateByProductGroup: async (watermarkTemplateDataList = []) => {
      const {
        watermarkTemplateType,
        productGroupName,
        dataDetail,
      } = this.props;
      const {
        isLanguageEn,
      } = this.state;
      const params = {
        product_group_name: productGroupName,
        template_category: watermarkTemplateType,
      }
      const configService = {
        isShowLoading: false,
      }
      let watermarkTemplateData = await getWatermarkTemplateDetailSearch(params, configService);
      if (watermarkTemplateData && watermarkTemplateData.id) {
        watermarkTemplateData.wtmTempDtBody = isLanguageEn ? watermarkTemplateData.wtmTempDtBodyEn : watermarkTemplateData.wtmTempDtBodyTh;
        const watermarkTemplateDocMsIdList = watermarkTemplateDataList.map(e => e.docMsId);
        (dataDetail.documentaryList || []).forEach(e => {
          if (watermarkTemplateDocMsIdList.indexOf(e.docMsId) === -1) {
            watermarkTemplateDocMsIdList.push(e.docMsId);
            watermarkTemplateDataList.push({
              docMsId: e.docMsId,
              ...watermarkTemplateData,
            });
          }
        })
      }
      this.setState({
        watermarkTemplateDataList,
        isLoadWatermarkFinish: true,
      }, () => {
        this.documentaryTemplate.handleCheckLoadDocumentaryFinish();
      })
    },
    getWatermarkTemplate: async () => {
      const {
        data,
        dataDetail,
        watermarkTemplateType,
        productGroupName,
        isHideWaterMark,
      } = this.props;
      const {
        isLanguageEn,
      } = this.state;
      // console.log('getWatermarkTemplate', data, dataDetail, watermarkTemplateType, productGroupName)
      if (!isHideWaterMark && productGroupName && watermarkTemplateType) {
        let watermarkTemplateDataList = [];
        const isSubFolderWatermark = (dataDetail.documentaryList || []).filter(e => e.wtmTempPostId || e.wtmTempPreId).length > 0;
        if (
          isSubFolderWatermark && (
            watermarkTemplateType === DATA.WATERMARK_TEMPLATE_LIST[1].value ||
            watermarkTemplateType === DATA.WATERMARK_TEMPLATE_LIST[2].value
          )
        ) {
          let subFolderWatermarkIdList = [];
          (dataDetail.documentaryList || []).forEach(e => {
            // 'Pre-Certificate'
            if (e.wtmTempPreId && watermarkTemplateType === DATA.WATERMARK_TEMPLATE_LIST[2].value) {
              subFolderWatermarkIdList.push({
                docMsId: e.docMsId,
                wtmTempId: e.wtmTempPreId,
              })
            }
            // 'Post-Certificate'
            if (e.wtmTempPostId && watermarkTemplateType === DATA.WATERMARK_TEMPLATE_LIST[1].value) {
              subFolderWatermarkIdList.push({
                docMsId: e.docMsId,
                wtmTempId: e.wtmTempPostId,
              })
            }
          });
          // console.log('find subfolder watermark', subFolderWatermarkIdList);
          if (subFolderWatermarkIdList.length) {
            const params = {
              wtm_temp_id: subFolderWatermarkIdList.map(e => e.wtmTempId),
            }
            const configService = {
              isShowLoading: false,
            }
            watermarkTemplateDataList = await getWatermarkTemplateDetailSearchSubFolder(params, configService);
            subFolderWatermarkIdList = subFolderWatermarkIdList.map(e => {
              watermarkTemplateDataList.forEach(ew => {
                const watermarkData = _.cloneDeep(ew);
                watermarkData.wtmTempDtBody = isLanguageEn ? watermarkData.wtmTempDtBodyEn : watermarkData.wtmTempDtBodyTh;
                if (e.wtmTempId === watermarkData.id) {
                  watermarkData.docMsId = e.docMsId;
                  e = watermarkData;
                }
              });
              return e;
            });
            // console.log('subFolderWatermarkIdList', subFolderWatermarkIdList)
            watermarkTemplateDataList = _.cloneDeep(subFolderWatermarkIdList)
          }
        }
        await this.watermarkTemplate.getWatermarkTemplateByProductGroup(watermarkTemplateDataList);
      }
      else {
        this.setState({
          isLoadWatermarkFinish: true,
        }, () => {
          this.documentaryTemplate.handleCheckLoadDocumentaryFinish();
        })
      }
    },
    getWatermarkComponent: (e) => {
      const {
        data,
        dataDetail,
        customerAddress,
        isWaterMarkCertificateTemplate,
        isHideWaterMark,
        isWaterMarkPreCertificate,
      } = this.props;
      const {
        isLanguageEn,
        userAuth,
        watermarkTemplateDataList,
      } = this.state;
      const watermarkTemplateData = watermarkTemplateDataList.filter(ew => ew.docMsId === e.docMsId)[0];

      return isHideWaterMark ?
        null
        :
        (watermarkTemplateData ?
          <div className='wgpp-mark'>
            <div className='wgpp-mark-container is-rotate'>
              <div className='wgpp-content is-watermark'>
                <div className='wgpp-content-item'>
                  <div dangerouslySetInnerHTML={{ __html: this.watermarkTemplate.handleReplaceData(watermarkTemplateData.wtmTempDtBody, e) }} />
                </div>
              </div>
            </div>
          </div>
          :
          <React.Fragment>
            <div className='wgpp-document-number'>
              <span className='wgpp-text is-bold is-documentary is-documentary-id'>
                {isWaterMarkCertificateTemplate ?
                  null
                  :
                  `${isLanguageEn ? 'Document no.' : 'เลขที่หนังสือรับรอง'} ${e.wrtDocNo || '-'}`
                }
              </span>
            </div>
            <div className='wgpp-mark'>
              <div className='wgpp-mark-container is-rotate'>
                <div className='wgpp-content is-documentary'>
                  {
                    isWaterMarkPreCertificate &&
                    <div className='wgpp-content-item'>
                      <span className={`wgpp-text is-bold is-documentary is-documentary-id-certificate ${isWaterMarkCertificateTemplate ? ' is-documentary-brand-certificate' : ''}`}>
                        เอกสารนี้สำหรับยื่นเสนอโครงการเท่านั้น เอกสารตัวจริงจะออกให้หลังจากมีการซื้อขาย
                      </span>
                    </div>
                  }
                  {isWaterMarkCertificateTemplate &&
                    <div className='wgpp-content-item'>
                      <span className='wgpp-text is-bold is-documentary is-documentary-id is-documentary-id-certificate'>
                        Pre-Transaction Document No. {e.preSalesTransCertDtCode ? e.preSalesTransCertDtCode : '-'}
                      </span>
                    </div>
                  }
                  <div className='wgpp-content-item'>
                    <span className={`wgpp-text is-bold is-documentary is-documentary-brand ${isWaterMarkCertificateTemplate ? ' is-documentary-brand-certificate' : ''}`}>
                      NS BlueScope (Thailand) Limited
                      {!isWaterMarkCertificateTemplate && e.isSelectedSpecifyCompanyName &&
                        <React.Fragment>
                          <br />
                          {isLanguageEn ? 'Document for' : 'หนังสือรับรองสำหรับ'}
                        </React.Fragment>
                      }
                    </span>
                  </div>
                  <div className='wgpp-content-item'>
                    <span className={`wgpp-text is-bold is-documentary is-documentary-company-name ${isWaterMarkCertificateTemplate ? ' is-documentary-company-name-certificate' : ''}`}>
                      {e.isSelectedSpecifyCompanyName ? (e.specifyCompanyName || '') :
                        // (
                        //   isWaterMarkCertificateTemplate ?
                        // _.get(data, 'customerData.displayFullname', '') :
                        userAuth.company
                        // )
                      }
                    </span>
                  </div>
                  {(isWaterMarkCertificateTemplate || (e.displayCoil || []).length > 0) &&
                    <div className='wgpp-content-item'>
                      <Section spacingBottom='5'>
                        <span className={`wgpp-text is-documentary is-documentary-product-label ${isWaterMarkCertificateTemplate ? ' is-documentary-product-label-certificate' : ''}`}>
                          {isLanguageEn ? 'Used for' : 'ใช้ผลิตภัณฑ์'}
                        </span>
                      </Section>
                      <Grid justify='center'>
                        <Grid.Column>
                          {isWaterMarkCertificateTemplate ?
                            (<span className={`wgpp-text is-documentary is-documentary-product ${isWaterMarkCertificateTemplate ? ' is-documentary-product-label-certificate' : ''}`}>
                              {e.m3ProductGroupName}
                            </span>)
                            :
                            e.displayCoil.map((edc, idc) => {
                              return (
                                <Grid gutter='15' key={idc}>
                                  {/* <Grid.Column>
                        <span className='wgpp-text is-documentary is-documentary-product'>
                          {e.coilIdList[idc]}
                        </span>
                      </Grid.Column> */}
                                  <Grid.Column>
                                    <span className='wgpp-text is-documentary is-documentary-product'>
                                      {edc}
                                    </span>
                                  </Grid.Column>
                                </Grid>
                              )
                            })}
                        </Grid.Column>
                      </Grid>
                    </div>
                  }
                  <div className='wgpp-content-item'>
                    <span className={`wgpp-text is-documentary is-documentary-project-address ${isWaterMarkCertificateTemplate ? ' is-documentary-project-address-certificate' : ''}`}>
                      {isWaterMarkCertificateTemplate ? 'โครงการ' : ''} {`${(data || {}).projectName} ${customerAddress || ''}`}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </React.Fragment>
        )
    }
  }

  documentaryTemplate = {
    setShowDocumentaryTemplate: (isShow, callback = () => { }) => {
      this.setState({
        isShowDocumentaryTemplate: isShow,
        documentaryNumPage: {},
        callbackDocumenrayLoadFinish: undefined
      }, () => {
        callback();
      })
    },
    handleCheckLoadDocumentaryFinish: () => {
      const {
        dataDetail,
      } = this.props;
      const {
        documentaryNumPage,
        callbackDocumenrayLoadFinish,
        isLoadWatermarkFinish,
      } = this.state;
      if (callbackDocumenrayLoadFinish) {
        const isLoadFinishList = [];
        Object.values(documentaryNumPage).forEach(e => {
          if (e.isLoadFinish) {
            isLoadFinishList.push(e)
          }
        });

        if (isLoadWatermarkFinish && isLoadFinishList.length === (dataDetail.documentaryList || []).length) {
          // console.log('callbackDocumenrayLoadFinish', (dataDetail || {}).productGroupName, documentaryNumPage)
          callbackDocumenrayLoadFinish();
        }
      }
    },
    waitingDocumentaryLoadFinish: async (callback = () => { }) => {
      await this.watermarkTemplate.getWatermarkTemplate();
      this.setState({
        callbackDocumenrayLoadFinish: callback
      }, () => {
        this.documentaryTemplate.handleCheckLoadDocumentaryFinish();
      })
    },
    onDocumentLoadSuccess: (data, item) => {
      const {
        documentaryNumPage,
      } = this.state;
      // console.log('onDocumentLoadSuccess', data, item)
      documentaryNumPage[data.uniqId] = {
        totalPage: item.numPages,
        pageLoaded: {}
      };
      this.setState({
        documentaryNumPage
      })
    },
    onLoadError: (error) => {
      // console.log('onLoadError', error)
      this.setState({
        isShowDocumentaryTemplate: false,
        documentaryNumPage: {},
      }, () => {
        this.setState({
          isShowDocumentaryTemplate: true,
        })
      })
    },
    onDocumentPageRenderSuccess: (data, item) => {
      const {
        dataDetail,
      } = this.props;
      const {
        documentaryNumPage,
      } = this.state;
      const target = `isLoaded_${item.pageNumber}`;
      documentaryNumPage[data.uniqId].pageLoaded[target] = true;

      const cntLoadFinish = Object.values(documentaryNumPage[data.uniqId].pageLoaded).length;

      if (cntLoadFinish === documentaryNumPage[data.uniqId].totalPage) {
        documentaryNumPage[data.uniqId].isLoadFinish = true;
      }

      // console.log('onDocumentPageRenderSuccess', data.docMsName, cntLoadFinish, documentaryNumPage[data.uniqId], (dataDetail || {}).productGroupName, data, documentaryNumPage)

      this.setState({
        documentaryNumPage,
      }, () => {
        this.documentaryTemplate.handleCheckLoadDocumentaryFinish();
      })
    },
  }

  render() {
    const {
      data,
      dataDetail,
      customerAddress,
      isShowPreviewMark,
      isWaterMarkCertificateTemplate,
      isHideWaterMark,
      isWaterMarkPreCertificate,
    } = this.props;
    const {
      documentaryNumPage,
      isLanguageEn,
      isShowDocumentaryTemplate,
      userAuth,
    } = this.state;

    const isDisplayPreview = dataDetail.wrtNo === '-' || isShowPreviewMark;
    const documentaryList = (dataDetail.documentaryList || []);

    return isShowDocumentaryTemplate ? documentaryList.map((e, i) => {
      return (
        <Document key={i}
          file={e.docMsPathFileObject.path}
          onLoadSuccess={(item) => this.documentaryTemplate.onDocumentLoadSuccess(e, item)}
          onLoadError={this.documentaryTemplate.onLoadError}
        >
          {Object.keys(documentaryNumPage).length === documentaryList.length &&
            Array.from(Array((documentaryNumPage[e.uniqId] || {}).totalPage || 0)).map((ed, id) => {
              return (
                <WarrantyGeneratePdfPage.List className='is-center' key={`${i}_${id}`}>
                  <div className='wgpp-container-document-file'>
                    <Page
                      pageNumber={id + 1}
                      width={700}
                      className='wgpp-page-documentary'
                      onRenderSuccess={(item) => this.documentaryTemplate.onDocumentPageRenderSuccess(e, item)}
                      onLoadError={this.documentaryTemplate.onLoadError}
                    />
                    {this.watermarkTemplate.getWatermarkComponent(e)}
                    {isDisplayPreview &&
                      <div className='wgpp-mark'>
                        <span className='wgpp-text is-bold is-preview'>PREVIEW</span>
                      </div>
                    }

                    <div className='wgpp-footer-file'>
                      <div className='wgpp-text-footer-section'>
                        {/* !!warranty-public-qr-code */}
                        <span className='wgpp-text-issue-date is-date is-white'> {moment().format('DD/MM/YYYY')}</span>
                        <Image
                          className='wgpp-image-created-verify'
                          src={isLanguageEn ? CONTENTS['footer-created-verify-qr-landscape-en.png'] : CONTENTS['footer-created-verify-qr-landscape-th.png']}
                          widthRatio={160}
                          widthRatioUnit="px"
                          heightRatio={75}
                          heightRatioUnit="px"
                        />
                      </div>
                      <img
                        src={isLanguageEn ? CONTENTS['warranty-footer-thailand.png'] : CONTENTS['warranty-footer-thailand-th.png']}
                        alt='Footer'
                      />
                      {e.qrCodeValue &&
                        <QrCode className='wgpp-qr' value={e.qrCodeValue} size={60} renderAs="canvas" />
                      }
                    </div>
                  </div>
                </WarrantyGeneratePdfPage.List>
              )
            })
          }
        </Document>
      )
    }) : null
  }
}
