import React from 'react'
import {
  ShapeContainer,
  Section,
  Breadcrumb,
  TitlePage,
  TitleSection,
  Grid,
  Button,
  Image,
  Text,
  Table,
} from './../../components'
import { ModalHardwareAndTokoShopProfileContainerConnected } from './../../containers/ModalContainer'
import {
  VARIABLES,
  ICONS,
  CONTENTS,
} from './../../themes'
import { ROUTE_PATH } from '../../helpers/constants/route';
import { redirect, redirectData } from '../../helpers/functions/route';
import { setSideMenuActive, getRouteData } from '../../helpers/functions/route';

export class ProfileMappingHardwareAndTokoShopInfoScreen extends React.Component {

  constructor(props) {
    super(props);
    setSideMenuActive(props);
    const profileSelect = getRouteData(props, 'profileSelect');

    this.state = {
      isOpenModalHardwareAndTokoShopProfile: false,
      isDisabledButtonSave: true,
      coilTransferItem: 0,
      profile: profileSelect
    }
  }

  modal = {
    onOpenModalHardwareAndTokoShopProfile: () => {
      this.setState({
        isOpenModalHardwareAndTokoShopProfile: true,
      });
    },

    onCloseModalHardwareAndTokoShopProfile: () => {
      this.setState({
        isOpenModalHardwareAndTokoShopProfile: false,
      });
    }
  }

  render() {
    const {
      isOpenModalHardwareAndTokoShopProfile,
      isDisabledButtonSave,
      profile,
    } = this.state

    return (
      <React.Fragment>
        <Breadcrumb>
          <Breadcrumb.List
            link
            name='Home'
            onClick={() => {
              redirect(ROUTE_PATH.HOME.LINK)
            }}
          />
          <Breadcrumb.List name='/' />
          <Breadcrumb.List
            link
            name='Inventory'
          // onClick={() => {
          //   redirect(ROUTE_PATH.INVENTORY_INDEXES.LINK)
          // }}
          />
          <Breadcrumb.List name='/' />
          <Breadcrumb.List
            link
            name='Profile Mapping'
            onClick={() => {
              redirect(ROUTE_PATH.PROFILE_MAPPING.LINK)
            }}
          />
          <Breadcrumb.List name='/' />
          <Breadcrumb.List name='Conxecute' />
        </Breadcrumb>
        <Section ui='content'>
          <Section spacingBottom='35'>
            <TitlePage
              src={ICONS['ic-page-inventory.svg']}
              name='Inventory > Profile Mapping > Conxecute'
            />
          </Section>
          <ShapeContainer
            ui='content'
            justify='space-between'
          >
            <Section spacingBottom='45'>
              <Section spacingBottom='35'>
                <TitleSection name='Hardware / Toko Shop Info' />
              </Section>
              <Section spacingBottom='35'>
                <Grid
                  gutter='35'
                  gutterVertical='35'
                  justify='space-between'
                >
                  <Grid.Column>
                    <Grid
                      gutter='55'
                      gutterVertical='35'
                    >
                      <Grid.Column>
                        <ShapeContainer
                          ui='userUpload'
                          srcUserUploadImage={false ? CONTENTS['customer-1.jpg'] : ICONS['ic-placeholder-avatar.svg']}
                        />
                      </Grid.Column>
                      <Grid.Column>
                        <Section
                          spacingTop='5'
                          spacingBottom='5'
                        >
                          <Text
                            regular14
                            color={VARIABLES.COLORS.GRAY_19}
                          >
                            Company Name
                          </Text>
                        </Section>
                        <Section spacingBottom='15'>
                          <Text
                            regular22
                            color={VARIABLES.COLORS.BLACK}
                          >
                            {profile.profileName}
                          </Text>
                        </Section>
                        <Section
                          spacingTop='5'
                          spacingBottom='5'
                        >
                          <Text
                            regular14
                            color={VARIABLES.COLORS.GRAY_19}
                          >
                            Phone No.
                          </Text>
                        </Section>
                        <Section spacingBottom='15'>
                          <Text
                            regular22
                            color={VARIABLES.COLORS.BLACK}
                          >
                            <Image
                              ui='flagWithPhoneIdAndNumber'
                              phoneId={profile.phoneCountry.value}
                              phoneNumber={profile.phoneNo}
                              src={profile.phoneCountry.flag}
                            />
                          </Text>
                        </Section>
                      </Grid.Column>
                      <Grid.Column>
                        <Section
                          spacingTop='5'
                          spacingBottom='10'
                        >
                          <Text
                            regular14
                            color={VARIABLES.COLORS.GRAY_19}
                          >
                            Email
                          </Text>
                        </Section>
                        <Section>
                          <Text
                            regular14
                            color={VARIABLES.COLORS.BLACK}
                          >
                            {profile.email}
                          </Text>
                        </Section>
                      </Grid.Column>
                    </Grid>
                  </Grid.Column>
                  <Grid.Column>
                    <Section spacingBottom='15'>
                      <Button
                        blue
                        ui='frontIcon'
                        name='Select Hardware / Toko Shop'
                        iconWidth='22'
                        iconHeight='18'
                        iconSrc={ICONS['ic-select-customer.svg']}
                        onClick={this.modal.onOpenModalHardwareAndTokoShopProfile}
                      />
                    </Section>
                  </Grid.Column>
                </Grid>
              </Section>
              <Section spacingBottom='50'>
                <TitleSection name='Profile Mapping Summary' />
              </Section>
              <Section
                spacingBottom='20'
                justify='center'
              >
                <Image ui='iconBlankProduct' />
              </Section>
              <Section
                spacingBottom='20'
                justify='center'
              >
                <Text
                  regular14
                  color={VARIABLES.COLORS.BLACK}
                >
                  Select the coil that you want to map profiles.
                </Text>
              </Section>
              <Section justify='center'>
                <Button
                  brown
                  ui='frontIcon'
                  name='Profile Mapping'
                  iconWidth='14'
                  iconHeight='14'
                  iconSrc={ICONS['ic-create.svg']}
                  width={VARIABLES.BUTTON.WIDTHS.W_200}
                  onClick={() => {
                    redirectData(ROUTE_PATH.PROFILE_MAPPING_PRODUCT_GROUP.LINK, {
                      profile: profile
                    })
                  }}
                />
              </Section>
            </Section>
            <Section justify='flex-end'>
              <Button
                gray
                ui='back'
                name='Back'
                onClick={() => {
                  redirect(ROUTE_PATH.PROFILE_MAPPING.LINK)
                }}
              />
            </Section>
          </ShapeContainer>
        </Section>
        {isOpenModalHardwareAndTokoShopProfile &&
          <ModalHardwareAndTokoShopProfileContainerConnected
            isOpenModal={isOpenModalHardwareAndTokoShopProfile}
            disabledButtonSave={isDisabledButtonSave}
            onClickClose={this.modal.onCloseModalHardwareAndTokoShopProfile}
          />
        }
      </React.Fragment>
    )
  }
}
