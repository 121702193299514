import React from 'react'
import ClassNames from 'classnames'
import PropTypes from 'prop-types'
import {
  StepWrapper
} from './styled'
// import {
// } from './../../../components'
// import {
//   default as VARIABLES
// } from './../../../themes/styles/bases/variables'
// import {
//   default as TYPOGRAPHYS
// } from './../../../themes/styles/bases/typographys'
// import {
//   default as UTILITIES
// } from './../../../themes/styles/helpers/utilities'

/**
 * Step description:
 * - Step
 */

class StepNumber extends React.PureComponent {
  render () {
    const {
      className,
      number,
      active,
      success,
      disabled,
      label,
      noWrap,
    } = this.props

    // props for css classes
    const classes = ClassNames(
      'step-number',
      {'is-active': active},
      {'is-success': success},
      {'is-disabled': disabled},
      {'is-nowrap': noWrap},
      className
    )

    return (
      <div className={classes}>
        <div className='step-number-container'>
          <span className='step-number-text'>
            {number}
          </span>
        </div>
        <div className='step-number-label'>
          {label}
        </div>
      </div>
    )
  }

  static defaultProps = {
    // * If you set new default props, you should create propsType for correct styleguide document.
  }

  static propTypes = { // TYPE > node, string, number, bool, array, object, symbol, func
    /**
    * Additional classes
    */
    className: PropTypes.string
  }
}

class StepTab extends React.PureComponent {
  render () {
    const {
      className,
      active,
      name,
      onClick
    } = this.props

    // props for css classes
    const classes = ClassNames(
      'step-tab',
      {'is-active': active},
      className
    )

    return (
      <div
        className={classes}
        onClick={onClick}
      >
        <span className='step-tab-text'>
          {name}
        </span>
      </div>
    )
  }

  static defaultProps = {
    // * If you set new default props, you should create propsType for correct styleguide document.
  }

  static propTypes = { // TYPE > node, string, number, bool, array, object, symbol, func
    /**
    * Additional classes
    */
    className: PropTypes.string
  }
}

export class Step extends React.PureComponent {
  render () {
    const {
      className,
      children,
      progressPercent,
      tabs,
      label,
    } = this.props

    // props for css classes
    const classes = ClassNames(
      'step',
      {'is-tabs': tabs},
      {'is-label': label},
      className
    )

    return (
      <StepWrapper className={classes}>
        <div className='step-container'>
          {children}
          {progressPercent &&
            <div className='step-progress'>
              <div
                className='step-progress-percent'
                style={{
                  width: progressPercent + `%`
                }}
              />
            </div>
          }
        </div>
      </StepWrapper>
    )
  }

  static Number = StepNumber
  static Tab = StepTab

  static defaultProps = {
    // * If you set new default props, you should create propsType for correct styleguide document.
  }

  static propTypes = { // TYPE > node, string, number, bool, array, object, symbol, func
    /**
    * Additional classes
    */
    className: PropTypes.string
  }
}
