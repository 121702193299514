import React from 'react';
import { WarrantyListContainerConnected } from '../../containers/WarrantyListContainer';
import { DATE_PICKER_FORMAT } from '../../helpers/constants/data';
import { ENUM } from '../../helpers/constants/enum';
import { ROUTE_PATH } from '../../helpers/constants/route';
import { getUserAuthRedux } from '../../helpers/functions/auth';
import { getDateApiRequet } from '../../helpers/functions/data';
import { getLanguage } from '../../helpers/functions/language';
import { redirect, setSideMenuActive } from '../../helpers/functions/route';
import { getClearWarrantyParams, getWarrantyAutoCompleteList, getWarrantyList } from '../../helpers/services/warranty';
import {
  Breadcrumb,
  Button,
  DatePickerControl,
  Grid,
  Section,
  SelectControl,
  ShapeContainer,
  Text,
  TitlePage
} from './../../components';
import {
  ICONS,
  VARIABLES
} from './../../themes';

export class WarrantyScreen extends React.Component {

  constructor(props) {
    super(props);
    setSideMenuActive(props);
    const userAuth = getUserAuthRedux();
    const selectOptionList = [
      // {
      //   value: '',
      //   label: 'All'
      // },
      {
        value: 'salesTransactionID',
        label: getLanguage('warranty.selectOption.salesTransactionID', '')
      }, {
        value: 'contactName',
        label: getLanguage('warranty.selectOption.contactName', '')
      }, {
        value: 'wrtNo',
        label: getLanguage('warranty.selectOption.warrantyNo', '')
      }, {
        value: 'projectName',
        label: getLanguage('warranty.selectOption.projectName', '')
      }, {
        value: 'phoneNo',
        label: getLanguage('warranty.selectOption.phoneNumber', '')
      }];
    this.state = {
      searchValue: '',
      selectOptionList,
      selectedOption: selectOptionList[0],
      startDate: new Date(),
      endDate: new Date(),
      isMalay: userAuth.addressType === ENUM.COUNTRY_TYPE.MALAYSIA,
      dataAutoCompleteList: [],
      isAutoCompleteLoading: false,
    }
  }

  warranty = {
    onClickViewWarrantyDetail: (isView) => {
      this.setState({
        isViewWarrantyDetail: isView,
      });
    }
  }

  input = {
    onChangeAutoCompleteInput: (name, value, option) => {
      let {
        setTimeoutIndex,
        selectedOption,
        selectOptionList,
      } = this.state;
      if (option.action === 'input-change') {
        clearTimeout(setTimeoutIndex);
        setTimeoutIndex = setTimeout(() => {
          this.setState({
            isAutoCompleteLoading: true,
            dataAutoCompleteList: [],
            searchAutoCompleteValue: undefined,
          }, async () => {
            const searchType = selectedOption.value;
            let params = {
              page: 1,
              limit: 10,
            };
            let target = '';
            switch (searchType) {
              case selectOptionList[0].value:
                params.order_no = value;
                target = 'orderNoText';
                break;
              case selectOptionList[1].value:
                params.customer_name = value;
                target = 'customerName';
                break;
              case selectOptionList[2].value:
                params.wrt_no = value;
                target = 'wrtNo';
                break;
              case selectOptionList[3].value:
                params.project_name = value;
                target = 'projectName';
                break;
              case selectOptionList[4].value:
                params.phone_no = value;
                target = 'projectPhone';
                break;
            }
            const dataAutoCompleteList = await getWarrantyAutoCompleteList(params);
            this.setState({
              dataAutoCompleteList: dataAutoCompleteList.map(e => {
                return {
                  value: e[target],
                  label: e[target]
                }
              }),
              isAutoCompleteLoading: false,
            });
          });
        }, 1000);

        this.setState({
          setTimeoutIndex,
          [name]: value,
        })
      }
    },
    onChangeAutoCompleteData: (data) => {
      this.setState({
        searchAutoCompleteValue: data,
        searchValue: data.value,
      }, () => {
        // this.input.onClickSearchText();
      })
    },
    onClickSearchText: () => {
      const {
        searchValue,
        selectedOption,
        selectOptionList,
      } = this.state;
      const searchType = selectedOption.value;
      let params = {
        ...getClearWarrantyParams(),
        page: 1,
      };
      switch (searchType) {
        case selectOptionList[0].value:
          params.order_no = searchValue;
          break;
        case selectOptionList[1].value:
          params.customer_name = searchValue;
          break;
        case selectOptionList[2].value:
          params.wrt_no = searchValue;
          break;
        case selectOptionList[3].value:
          params.project_name = searchValue;
          break;
        case selectOptionList[4].value:
          params.phone_no = searchValue;
          break;
      }
      getWarrantyList(params);
    },
    onClickSearchDate: () => {
      const {
        startDate,
        endDate,
      } = this.state;
      let params = {
        ...getClearWarrantyParams(),
        page: 1,
        str_date: getDateApiRequet(startDate),
        end_date: getDateApiRequet(endDate),
      };
      getWarrantyList(params);
    },
    onChangeSearchOption: (selectedOption) => {
      this.setState({
        selectedOption
      })
    },
    onChangeDate: (date, name) => {
      this.setState({
        [name]: date
      })
    }
  }

  render() {
    const {
      selectedOption,
      searchValue,
      selectOptionList,
      startDate,
      endDate,
      isViewWarrantyDetail,
      isMalay,
      dataAutoCompleteList,
      isAutoCompleteLoading,
      searchAutoCompleteValue,
    } = this.state

    return (
      <React.Fragment>
        <Breadcrumb>
          <Breadcrumb.List
            link
            name={getLanguage('warranty.breadcrumb1', '')}
            onClick={() => {
              redirect(ROUTE_PATH.HOME.LINK)
            }}
          />
          <Breadcrumb.List name='/' />
          <Breadcrumb.List
            name={getLanguage('warranty.breadcrumb2', '')}
          />
        </Breadcrumb>
        <Section ui='content'>
          {
            !isViewWarrantyDetail &&
            <React.Fragment>
              <Section spacingBottom='35'>
                <TitlePage
                  src={ICONS['ic-page-warranty.svg']}
                  name={getLanguage('warranty.title', '')}
                />
              </Section>
              <Section spacingBottom='20'>
                <ShapeContainer
                  fluid
                  className='search-control'
                  paddingTop='20'
                  paddingBottom='18'
                  paddingHorizontal='20'
                  borderWidth='1'
                  borderStyle='solid'
                  borderColor={VARIABLES.COLORS.PRIMARY_1}
                  borderRadius='10'
                >
                  <Grid
                    className='search-control-container'
                    gutter='30'
                    gutterVertical='20'
                  >
                    <Grid.Column
                      className='search-control-group-left'
                      flex='1'
                    >
                      <Grid gutter='10'>
                        <Grid.Column flex='2'>
                          <SelectControl
                            isFilterSearch
                            isSearchable={true}
                            placeholder={getLanguage('warranty.placeholder', '')}
                            value={searchAutoCompleteValue}
                            inputValue={searchAutoCompleteValue ? undefined : searchValue}
                            onInputChange={(value, option) => {
                              this.input.onChangeAutoCompleteInput('searchValue', value, option);
                            }}
                            onChange={this.input.onChangeAutoCompleteData}
                            options={dataAutoCompleteList}
                            isLoading={isAutoCompleteLoading}
                          />
                          {/* <Field name='searchValue'
                            fluid
                            ui='blockLabelVerticalSearch'
                            placeholder={getLanguage('warranty.placeholder', '')}
                            value={searchValue}
                            onChange={this.input.onChangeInput}
                          /> */}
                        </Grid.Column>
                        <Grid.Column flex='none'>
                          <Section zIndexLv3>
                            <SelectControl
                              verticalLabel
                              filterFluid
                              width='185'
                              isSearchable={false}
                              value={selectedOption}
                              onChange={this.input.onChangeSearchOption}
                              options={selectOptionList}
                            />
                          </Section>
                        </Grid.Column>
                        <Grid.Column flex='none'>
                          <Button
                            lightBlue
                            name={getLanguage('warranty.buttonSearch', '')}
                            width={VARIABLES.BUTTON.WIDTHS.W_105}
                            onClick={this.input.onClickSearchText}
                          />
                        </Grid.Column>
                      </Grid>
                    </Grid.Column>
                    <Grid.Column
                      className='search-control-partition'
                      flex='none'
                    >
                      <ShapeContainer
                        width='1'
                        height='32'
                        bgColor={VARIABLES.COLORS.PRIMARY_1}
                      />
                    </Grid.Column>
                    <Grid.Column
                      className='search-control-group-right'
                      flex='1'
                    >
                      <Grid gutter='20'>
                        <Grid.Column flex='none'>
                          <Section paddingTop='6'>
                            <Text
                              regular12
                              color={VARIABLES.COLORS.BLACK}
                            >
                              {getLanguage('warranty.date', '')}
                            </Text>
                          </Section>
                        </Grid.Column>
                        <Grid.Column flex='1'>
                          <Grid gutter='10'>
                            <Grid.Column flex='1'>
                              <Grid gutter='15'>
                                <Grid.Column flex='1'>
                                  <DatePickerControl
                                    filter
                                    bottom
                                    dateFormat={DATE_PICKER_FORMAT}
                                    placeholderText={getLanguage('warranty.selectStartDate', '')}
                                    selected={startDate}
                                    onChange={(date) => {
                                      this.input.onChangeDate(date, 'startDate');
                                    }}
                                  />
                                </Grid.Column>
                                <Grid.Column flex='none'>
                                  <Section paddingTop='6'>
                                    <Text
                                      regular12
                                      color={VARIABLES.COLORS.GRAY_19}
                                    >
                                      {getLanguage('warranty.dateTo', '')}
                                    </Text>
                                  </Section>
                                </Grid.Column>
                                <Grid.Column flex='1'>
                                  <DatePickerControl
                                    filter
                                    bottom
                                    dateFormat={DATE_PICKER_FORMAT}
                                    placeholderText={getLanguage('warranty.selectEndDate', '')}
                                    selected={endDate}
                                    onChange={(date) => {
                                      this.input.onChangeDate(date, 'endDate');
                                    }}
                                  />
                                </Grid.Column>
                              </Grid>
                            </Grid.Column>
                            <Grid.Column flex='none'>
                              <Button
                                lightBlue
                                name={getLanguage('warranty.buttonSearch', '')}
                                width={VARIABLES.BUTTON.WIDTHS.W_105}
                                onClick={this.input.onClickSearchDate}
                              />
                            </Grid.Column>
                          </Grid>
                        </Grid.Column>
                      </Grid>
                    </Grid.Column>
                  </Grid>
                </ShapeContainer>
              </Section>
            </React.Fragment>
          }
          <WarrantyListContainerConnected
            onClickViewWarrantyDetail={this.warranty.onClickViewWarrantyDetail}
            isMalay={isMalay}
          />
        </Section>
      </React.Fragment>
    )
  }
}
