import React from 'react'
import ClassNames from 'classnames'
import PropTypes from 'prop-types'
import Swiper from 'react-id-swiper'
import './css/swiper.min.css'
import {
  SwiperCarouselWrapper
} from './styled'
import {
  Image
} from './../../../components'
import {
  ICONS
} from './../../../themes'

class SwiperCarouselItem extends React.PureComponent {
  render () {
    const {
      className,
      children,
      href,
      onClick,
      title,
      labelNews,
      labelPromotions
    } = this.props

    // props for css classes
    const classes = ClassNames(
      'swiper-carousel-item',
      {'is-cursor': onClick},
      className
    )

    return (
      <React.Fragment>
        {href ?
          <a
            className={classes}
            href={href}
            onClick={onClick}
          >
            {children}
            {title &&
              <span className='swiper-carousel-title'>
                <span className='swiper-carousel-title-text'>
                  <span className='swiper-carousel-title-text-ellipsis'>
                    {title}
                  </span>
                </span>
              </span>
            }
            {(labelNews || labelPromotions) &&
              <span className={ClassNames(
                'swiper-carousel-label',
                {'is-news': labelNews},
                {'is-promotions': labelPromotions}
              )}>
                {labelNews &&
                  <Image
                    widthRatio='160'
                    widthRatioUnit='px'
                    heightRatio='97'
                    heightRatioUnit='px'
                    src={ICONS['ic-label-news.svg']}
                  />
                }

                {labelPromotions &&
                  <Image
                    widthRatio='287'
                    widthRatioUnit='px'
                    heightRatio='121'
                    heightRatioUnit='px'
                    src={ICONS['ic-label-promotions.svg']}
                  />
                }
              </span>
            }
          </a>
          :
          <div
            className={classes}
            onClick={onClick}
          >
            {children}
            {title &&
              <span className='swiper-carousel-title'>
                <span className='swiper-carousel-title-text'>
                  <span className='swiper-carousel-title-text-ellipsis'>
                    {title}
                  </span>
                </span>
              </span>
            }
            {(labelNews || labelPromotions) &&
              <span className={ClassNames(
                'swiper-carousel-label',
                {'is-news': labelNews},
                {'is-promotions': labelPromotions}
              )}>
                {labelNews &&
                  <Image
                    widthRatio='160'
                    widthRatioUnit='px'
                    heightRatio='97'
                    heightRatioUnit='px'
                    src={ICONS['ic-label-news.svg']}
                  />
                }

                {labelPromotions &&
                  <Image
                    widthRatio='287'
                    widthRatioUnit='px'
                    heightRatio='121'
                    heightRatioUnit='px'
                    src={ICONS['ic-label-promotions.svg']}
                  />
                }
              </span>
            }
          </div>
        }
      </React.Fragment>
    )
  }

  static propTypes = { // TYPE > node, string, number, bool, array, object, symbol, func
    /**
    * Additional classes
    */
    className: PropTypes.string,

    /**
    * Additional elements or text
    */
    children: PropTypes.oneOfType([
      PropTypes.node,
      PropTypes.string
    ]),

    /**
    * Modifier name for change default multiple UI (parent and children)
    */
    // ui: PropTypes.oneOf([]),

    /**
    * href for <a>
    */
    href: PropTypes.string,

    /**
    * On click event
    */
    onClick: PropTypes.func
  }
}

/**
 * SwiperCarousel description:
 * - Carousel for image or anything component
 * - https://github.com/kidjp85/react-id-swiper (this library wrapped Swiper - http://idangero.us/swiper/api/)
 */

export class SwiperCarousel extends React.PureComponent {
  render () {
    const {
      className,
      children,
      options,
      newsPromotions
    } = this.props

    // props for css classes
    const classes = ClassNames(
      'swiper-carousel',
      {'is-news-promotions': newsPromotions},
      className
    )

    return (
      <SwiperCarouselWrapper
        className={classes}
      >
        <Swiper {...options}>
          {children}
        </Swiper>
      </SwiperCarouselWrapper>
    )
  }

  static Item = SwiperCarouselItem

  static defaultProps = {
    options: {
      // loop: true,
      // autoplay: {
      //   delay: 5000,
      //   disableOnInteraction: false
      // },
      // pagination: {
      //   el: '.swiper-pagination'
      // },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev'
      }
    }
  }

  static propTypes = { // TYPE > node, string, number, bool, array, object, symbol, func
    /**
    * Modifier name for change default multiple UI (parent and children)
    */
    ui: PropTypes.oneOf([
      'card'
    ]),

    /**
    * Additional classes
    */
    className: PropTypes.string,

    /**
    * Additional elements or text
    */
    children: PropTypes.oneOfType([
      PropTypes.node,
      PropTypes.string
    ]),

    /**
    * Options for carousel
    * - http://kidjp85.github.io/react-id-swiper/
    */
    options: PropTypes.object
  }
}
