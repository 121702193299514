import styled, {
  // css
} from 'styled-components'
import {
  default as VARIABLES
} from './../../../themes/styles/bases/variables'
import {
  default as TYPOGRAPHYS
} from './../../../themes/styles/bases/typographys'
// import {
//   default as MIXINS
// } from './../../../themes/styles/helpers/mixins'

// Sample using image in style
// url(${require('./../../../themes/images/icons/icon-sample.svg')});

// Wrapper
// ============================================================
export const UploadFileWrapper = styled.div`
  /* Parent
  ------------------------------- */
  /* Childrens
  ------------------------------- */




  /* Modifiers
  ------------------------------- */
  &.is-document {
    .filepicker-file-icon {
      pointer-events: none;
      padding-left: 0;
      margin: 0 0 15px;

      &:before {
        display: inline-block;
        position: static;
        top: 0;
        left: 0;
        width: 30px;
        height: 36px;
        background-color: ${VARIABLES.COLORS.WHITE};
        border: solid 1px ${VARIABLES.COLORS.PRIMARY_2};
        border-radius: 3px;
      }

      &:after {
        ${TYPOGRAPHYS.FIRST.REGULAR.FS_12};
        position: absolute;
        top: 10px;
        left: -3px;
        width: 36px;
        padding: 0 0 1px;
        background-color: ${VARIABLES.COLORS.PRIMARY_2};
        color: ${VARIABLES.COLORS.WHITE};
        text-align: center;
      }
    }

    .dropzone {
      min-height: 200px;
      padding: 60px;
      background-color: ${VARIABLES.COLORS.BLUE_5};
      border-radius: 5px;
      border: 1px dashed ${VARIABLES.COLORS.GRAY_12};

      .dz-message {
        margin: 0;

        span {
          ${TYPOGRAPHYS.FIRST.REGULAR.FS_16};
          color: ${VARIABLES.COLORS.GRAY_23};
        }
      }

      .dz-error-message {
        transform: translateX(-50%);
        top: 100%;
        left: 50%;
        margin-top: 10px;
        background: ${VARIABLES.COLORS.RED_2};

        &:after {
          border-bottom-color: ${VARIABLES.COLORS.RED_2};
        }
      }

      .dz-preview {
        min-width: 250px;
        min-height: 1px;
        margin: 15px;

        .dz-image {
          display: none;
          margin-bottom: 10px;
        }

        .dz-details {
          position: static;
          padding: 15px 30px;
          margin-bottom: 10px;
          background-color: ${VARIABLES.COLORS.WHITE};
          border: 2px solid ${VARIABLES.COLORS.PRIMARY_2};
          border-radius: 26px;
          line-height: 1;

          .dz-filename,
          .dz-size {
            span {
              background-color: ${VARIABLES.COLORS.TRANSPARENT};
              padding: 0;
              border-radius: 5px;
            }
          }

          .dz-filename {
            overflow: hidden;
            text-overflow: ellipsis;

            &:hover {
              span {
                border-color: ${VARIABLES.COLORS.TRANSPARENT};
                background-color: ${VARIABLES.COLORS.TRANSPARENT};
              }
            }

            span {
              ${TYPOGRAPHYS.FIRST.REGULAR.FS_14};
            }
          }

          .dz-size {
            display: none;

            span {
              ${TYPOGRAPHYS.FIRST.BOLD.FS_14};
            }
          }
        }

        .dz-remove {
          ${TYPOGRAPHYS.FIRST.REGULAR.FS_12};
          color: ${VARIABLES.COLORS.PRIMARY_2};

          &:hover {
            text-decoration: none;
          }
        }

        .dz-progress {
          opacity: 0; /* Hide */
          z-index: ${VARIABLES.Z_INDEXS.LV_1};
          width: 200px;
          height: 8px;
          margin-top: -18px;
          margin-left: -100px;

          .dz-upload {
            background: ${VARIABLES.COLORS.PRIMARY_2};
          }
        }

        .dz-error-mark,
        .dz-success-mark {
          z-index: ${VARIABLES.Z_INDEXS.LV_1};
          width: 36px;
          height: 36px;
          margin-top: -31px;
          margin-left: -18px;

          &:after {
            content: ' ';
            display: block;
            width: 36px;
            height: 36px;
          }

          svg {
            display: none;
          }
        }

        .dz-error-mark {
          &:after {
            background-image: url(${require('./../../../themes/images/icons/ic-fail.svg')});
            background-size: cover;
          }
        }

        .dz-success-mark {
          &:after {
            background-image: url(${require('./../../../themes/images/icons/ic-success.svg')});
            background-size: cover;
          }
        }

        &.dz-file-preview {
          .dz-image {
            background: ${VARIABLES.COLORS.WHITE};
            border-radius: 5px;
          }
        }
      }

      &.dz-started {
        padding-top: 45px;
        padding-bottom: 45px;

        .filepicker-file-icon {
          display: none;
        }
      }
    }
  }

  /* Media queries
  ------------------------------- */
`
