import _ from 'lodash';
import React from 'react';
import {
  connect
} from 'react-redux';
import { bindActionCreators } from 'redux';
import { dataCreators, DATA_REDUX_STATE_KEY } from '../../actions/dataAction';
import { transferController } from '../../apiService/apiController/transferService';
import {
  Button, Field, Grid, Image, PaginationControl, Section, ShapeContainer, Table, TableAction, Text
} from '../../components';
import { getSortAscending } from '../../helpers/functions/data';
import { getLanguage } from '../../helpers/functions/language';
import { isShowLoading, toast } from '../../helpers/functions/main';
import { isValidResponse } from '../../helpers/functions/validation';
import { getClearTransferSearchApproveParams, getTransferSearchApproveList } from '../../helpers/services/transfer';
import { storeDispatch, storeGetState } from '../../stores';
import {
  ICONS,
  VARIABLES
} from '../../themes';
import { ModalCoilApprovalContainer, ModalCoilApprovalDetailContainer } from '../ModalContainer';

class CoilApproveListContainer extends React.Component {

  constructor(props) {
    super(props);
    const dataDefaultOptionParams = storeGetState().dataRedux.coilApproveDefaultOptionParams;
    storeDispatch(dataCreators.setDataRedux(DATA_REDUX_STATE_KEY.COIL_APPROVE_OPTION_PARAMS, _.cloneDeep(dataDefaultOptionParams)));

    this.state = {
      activePage: 1,
      dataPerPage: 10,

      isOpenCoilActionModal: false,
      isApproveCoil: false,
      detailData: undefined,
      isOpenCoilDetailModal: false,
    }
  }


  componentDidMount() {
    const {
      activePage,
      dataPerPage,
    } = this.state;
    const params = {
      ...getClearTransferSearchApproveParams(),
      page: activePage,
      limit: dataPerPage,
    };
    getTransferSearchApproveList(params);
  }

  coilApproveList = {
    updateData: (dataList) => {
      const {
        dataActions,
        dataOptionParams,
      } = this.props;
      //handle new or edit data
      dataList.forEach(e => {
        const findIndex = dataOptionParams.selectedDataList.map(es => es.id).indexOf(e.id);
        if (findIndex >= 0) {
          dataOptionParams.selectedDataList[findIndex] = e;
        }
        else {
          if (e.isSelected) {
            dataOptionParams.selectedDataList.push(e);
          }
        }
      })
      dataOptionParams.selectedDataList = dataOptionParams.selectedDataList.filter(e => e.isSelected);
      // console.log('dataOptionParams', dataList, dataOptionParams)
      dataActions.setDataRedux(DATA_REDUX_STATE_KEY.COIL_APPROVE_LIST, dataList);
      dataActions.setDataRedux(DATA_REDUX_STATE_KEY.COIL_APPROVE_OPTION_PARAMS, dataOptionParams);
    },
    onClickSort: (target) => {
      getTransferSearchApproveList({
        isNoSort: false,
        sort: target
      });
    },
    onClickView: async (item) => {
      this.setState({
        detailData: item,
        isOpenCoilDetailModal: true,
      })
    },
    onClickClear: () => {
      this.setState({
        isOpenCoilActionModal: true,
        isApproveCoil: false,
      })
    },
    onClickApprove: () => {
      this.setState({
        isOpenCoilActionModal: true,
        isApproveCoil: true,
      })
    }
  }

  input = {
    onChangeCheckboxAll: (ev) => {
      const {
        dataList,
      } = this.props;
      const isChecked = ev.target.checked;

      this.coilApproveList.updateData(dataList.map(e => {
        if (e.isPending) {
          e.isSelected = isChecked;
        }
        return e;
      }));
    },
    onChangeSelectCheckbox: (data) => {
      const {
        dataList,
      } = this.props;
      const tempDataList = dataList.map(e => {
        if (e.id === data.id) {
          e.isSelected = !e.isSelected;
        }
        // else {
        //   e.isSelected = false;
        // }
        return e
      })
      this.coilApproveList.updateData(tempDataList);
    },
  }

  modal = {
    onCloseCoilDetailModal: () => {
      this.setState({
        isOpenCoilDetailModal: false,
      })
    },
    onCloseActionCoilModal: () => {
      this.setState({
        isOpenCoilActionModal: false,
      })
    },
    onClickSubmitActionCoilModal: async () => {
      const {
        dataOptionParams,
      } = this.props;
      const {
        isApproveCoil,
      } = this.state;
      const dataList = dataOptionParams.selectedDataList;
      console.log('onClickSubmitActionCoilModal', isApproveCoil, dataList);
      isShowLoading(true);
      const dataErrorList = [];
      for (let i = 0; i < dataList.length; i++) {
        const e = dataList[i];
        const params = {
          uid__c: e.id,
        }
        const configService = {
          isShowLoading: false,
        }
        const transferService = transferController(configService);
        let res
        if (isApproveCoil) {
          res = await transferService.transferApprove(params);
        }
        else {
          res = await transferService.transferClear(params);
        }
        if (isValidResponse(res)) {
        }
        else {
          dataErrorList.push(e);
        }
      };

      const dataDefaultOptionParams = storeGetState().dataRedux.coilApproveDefaultOptionParams;
      storeDispatch(dataCreators.setDataRedux(DATA_REDUX_STATE_KEY.COIL_APPROVE_OPTION_PARAMS, _.cloneDeep({
        ...dataDefaultOptionParams,
        selectedDataList: dataErrorList,
      })));
      const params = {
        ...getClearTransferSearchApproveParams(),
        page: 1,
      };
      await getTransferSearchApproveList(params);
      isShowLoading(false);
      if (dataErrorList.length === 0) {
        if (isApproveCoil) {
          toast.success(getLanguage('message.youApprovedCoil'));
        }
        else {
          toast.success(getLanguage('message.youClearedCoil'));
        }
        this.modal.onCloseActionCoilModal();
      }
    }
  }

  pagination = {
    onPaginationChange: (pageNumber) => {
      getTransferSearchApproveList({
        isNoSort: true,
        page: pageNumber,
      });
    }
  }

  render() {
    const {
      dataList,
      dataParams,
      dataOptionParams,
      totalData,
      activePage,
    } = this.props;
    const {
      dataPerPage,
      isOpenCoilActionModal,
      isApproveCoil,
      isOpenCoilDetailModal,
      detailData
    } = this.state;
    return (
      <React.Fragment>
        <Section spacingBottom='40'>
          <Table strip>
            <Table.Section>
              <Table.Row>
                <Table.HeadColumn
                  action
                  noSort
                  alignCenter
                  maxWidth={50}
                  minWidth={50}
                >
                  <Section spacingTop="-2">
                    <Field
                      checked={
                        dataList.filter(e => e.isPending).length !== 0 &&
                        dataList.filter(e => e.isPending && e.isSelected).length === dataList.filter(e => e.isPending).length
                      }
                      ui='checkboxTick'
                      onChange={this.input.onChangeCheckboxAll}
                    />
                  </Section>
                </Table.HeadColumn>
                <Table.HeadColumn
                  noSort
                  maxWidth={100}
                  minWidth={100}
                >
                  {getLanguage('coilApproval.table.header.coilId', '')}
                </Table.HeadColumn>
                <Table.HeadColumn
                  noSort
                  maxWidth={193}
                  minWidth={193}
                >
                  {getLanguage('coilApproval.table.header.coilName', '')}
                </Table.HeadColumn>
                <Table.HeadColumn
                  sortAscending={getSortAscending(dataParams, 'transfer_to')}
                  onClickSort={() => {
                    this.coilApproveList.onClickSort('transfer_to desc');
                  }}
                  minWidth={260}
                >
                  {getLanguage('coilApproval.table.header.adRfName', '')}
                </Table.HeadColumn>
                <Table.HeadColumn
                  sortAscending={getSortAscending(dataParams, 'date')}
                  onClickSort={() => {
                    this.coilApproveList.onClickSort('date desc');
                  }}
                  maxWidth={75}
                  minWidth={75}
                >
                  {getLanguage('coilApproval.table.header.date', '')}
                </Table.HeadColumn>
                <Table.HeadColumn
                  sortAscending={getSortAscending(dataParams, 'length')}
                  onClickSort={() => {
                    this.coilApproveList.onClickSort('length desc');
                  }}
                  alignRight
                  maxWidth={160}
                  minWidth={160}
                >
                  {getLanguage('coilApproval.table.header.tranferAmount', '')}
                </Table.HeadColumn>
                <Table.HeadColumn
                  noSort
                  alignRight
                  maxWidth={108}
                  minWidth={108}
                >
                  {getLanguage('coilApproval.table.header.status', '')}
                </Table.HeadColumn>
                <Table.HeadColumn
                  noSort
                  alignCenter
                  maxWidth={94}
                  minWidth={94}
                >
                  {getLanguage('coilApproval.table.header.action', '')}
                </Table.HeadColumn>
              </Table.Row>
            </Table.Section>
          </Table>
          <Section scrollOverlay height='289'>
            <Table striped>
              {
                dataList.length ? (
                  <Table.Section>
                    {
                      dataList.map((e, i) => {
                        return (
                          <Table.Row key={i}>
                            <Table.BodyColumn
                              action
                              alignCenter
                              maxWidth={50}
                              minWidth={50}
                            >
                              {
                                e.isPending ?
                                  <Section spacingTop="-2">
                                    <Field
                                      checked={e.isSelected || false}
                                      ui='checkboxTick'
                                      onChange={() => {
                                        this.input.onChangeSelectCheckbox(e);
                                      }}
                                    />
                                  </Section> :
                                  <Text
                                    regular12
                                    color={VARIABLES.COLORS.GRAY_19}
                                  >
                                    -
                                  </Text>
                              }
                            </Table.BodyColumn>
                            <Table.BodyColumn
                              info
                              maxWidth={100}
                              minWidth={100}
                              title={e.coilId}
                            >
                              {e.coilId}
                            </Table.BodyColumn>
                            <Table.BodyColumn
                              info
                              maxWidth={193}
                              minWidth={193}
                              title={e.coilName}
                            >
                              {e.coilName}
                            </Table.BodyColumn>
                            <Table.BodyColumn
                              info
                              minWidth={260}
                              title={e.transferTo}
                            >
                              {e.transferTo}
                            </Table.BodyColumn>
                            <Table.BodyColumn
                              info
                              maxWidth={75}
                              minWidth={75}
                              title={e.displayDate}
                            >
                              {e.displayDate}
                            </Table.BodyColumn>
                            <Table.BodyColumn
                              info
                              alignRight
                              maxWidth={160}
                              minWidth={160}
                              title={e.length}
                            >
                              {e.length}
                            </Table.BodyColumn>
                            <Table.BodyColumn
                              info
                              alignRight
                              maxWidth={108}
                              minWidth={108}
                              title={e.transferStatus}
                            >
                              <Text
                                regular12
                                color={e.isApproved ? VARIABLES.COLORS.GREEN_8 : (e.isCleared ? VARIABLES.COLORS.RED_4 : VARIABLES.COLORS.GRAY_19)}
                              >
                                {e.transferStatus}
                              </Text>
                            </Table.BodyColumn>
                            <Table.BodyColumn
                              action
                              alignCenter
                              maxWidth={94}
                              minWidth={94}
                            >
                              <Image
                                src={ICONS['ic-document-blue-view.svg']}
                                widthRatio='11'
                                widthRatioUnit='px'
                                heightRatio='14'
                                heightRatioUnit='px'
                                onClick={() => {
                                  this.coilApproveList.onClickView(e)
                                }}
                              />
                            </Table.BodyColumn>
                          </Table.Row>
                        )
                      })
                    }
                  </Table.Section>
                ) : (
                  <Section justify='center' paddingVertical='80'>
                    <Section justify='center' spacingBottom='8' >
                      <Image
                        src={ICONS['ic-coil-lightblue.svg']}
                        widthRatio='40'
                        widthRatioUnit='px'
                        heightRatio='40'
                        heightRatioUnit='px'
                      />
                    </Section>
                    <Text regular14 color={VARIABLES.COLORS.BLACK}>
                      {getLanguage('coilApproval.label.noDataCoil', '')}
                    </Text>
                  </Section>
                )
              }
            </Table>
          </Section>
        </Section>
        <Section >
          <TableAction>
            <TableAction.Left>
              <Grid gutter='15'>
                <Grid.Column>
                  <ShapeContainer
                    ui='badgeCount'
                    badgeCountTitle={getLanguage('coilApproval.field.selectCoilApproval', '')}
                    badgeCountNumber={dataOptionParams.selectedDataList.length}
                  />
                </Grid.Column>
                <Grid.Column>
                  <Button
                    disabled={dataOptionParams.selectedDataList.length === 0}
                    red
                    name={getLanguage('coilApproval.button.buttonClear', '')}
                    width={VARIABLES.BUTTON.WIDTHS.W_120}
                    onClick={this.coilApproveList.onClickClear}
                  />
                </Grid.Column>
                <Grid.Column>
                  <Button
                    disabled={dataOptionParams.selectedDataList.length === 0}
                    blue
                    name={getLanguage('coilApproval.button.buttonApprove', '')}
                    width={VARIABLES.BUTTON.WIDTHS.W_120}
                    onClick={this.coilApproveList.onClickApprove}
                  />
                </Grid.Column>
              </Grid>
            </TableAction.Left>
            <TableAction.Right>
              <Grid gutter='20'>
                <Grid.Column>
                  <Section paddingTop='6'>
                    <Text
                      regular12
                      color={VARIABLES.COLORS.GRAY_16}
                    >
                      {getLanguage('pagination.page', '')} {activePage}/{Math.ceil(totalData / dataPerPage)}
                    </Text>
                  </Section>
                </Grid.Column>
                <Grid.Column>
                  <PaginationControl
                    activePage={activePage}
                    itemsCountPerPage={dataPerPage}
                    totalItemsCount={totalData || 0}
                    pageRangeDisplayed={3}
                    onChange={this.pagination.onPaginationChange}
                  />
                </Grid.Column>
              </Grid>
            </TableAction.Right>
          </TableAction>
        </Section>
        {
          isOpenCoilActionModal && (
            <ModalCoilApprovalContainer
              isOpenModal
              isApprove={isApproveCoil}
              title={isApproveCoil ?
                getLanguage('coilApproval.modal.modalCoilApproval.title_1', '') :
                getLanguage('coilApproval.modal.modalCoilApproval.title_2', '')
              }
              dataList={dataOptionParams.selectedDataList}
              onClickClose={this.modal.onCloseActionCoilModal}
              onClickConfirm={this.modal.onClickSubmitActionCoilModal}
            />
          )
        }
        {
          isOpenCoilDetailModal && (
            <ModalCoilApprovalDetailContainer
              isOpenModal
              title={getLanguage('coilApproval.modal.modalCoilApprovalDetail.title', '')}
              data={detailData}
              onClickClose={this.modal.onCloseCoilDetailModal}
              onClickConfirm={this.modal.onCloseCoilDetailModal}
            />
          )
        }
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => {
  const dataRedux = state.dataRedux;
  return {
    dataList: dataRedux.coilApproveList,
    dataParams: dataRedux.coilApproveParams,
    dataOptionParams: dataRedux.coilApproveOptionParams,
    activePage: dataRedux.coilApproveParams.activePage,
    totalData: dataRedux.coilApproveParams.totalData || 0,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    dataActions: bindActionCreators(dataCreators, dispatch),
  }
}

export const CoilApproveListContainerConnected = connect(
  mapStateToProps,
  mapDispatchToProps
)(CoilApproveListContainer)