import React from 'react';
// import {
//   connect,
// } from 'react-redux';
import {
  Section,
  Grid,
  Modal,
  Text,
  Button,
  Table,
  Image,
} from '../../../components'
import {
  VARIABLES,
  ICONS
} from '../../../themes'
import { getLanguage } from '../../../helpers/functions/language';
import { displayUrlPath } from '../../../helpers/functions/display';
// import { MODAL_TYPE } from '../../../helpers/constants/main'
// import { MAIN_REDUX_STATE_KEY } from '../../../actions/mainAction'

export class ModalCoilApprovalDetailContainer extends React.Component {

  constructor(props) {
    super(props);
    this.state = {}
  }

  render() {
    const {
      isOpenModal,
      isSecondModal,
      onClickClose,
      title,
      data,
    } = this.props
    return (
      <Modal
        open={isOpenModal}
        scroll
        second={isSecondModal}
        // enableOverlayClose={false}
        width={VARIABLES.MODAL.WIDTHS.W_1165}
        overlayColor={VARIABLES.COLORS.OVERLAY_BLACK_1}
        spacing={VARIABLES.MODAL.CONTAINER.C_1.SPACING}
        transition={VARIABLES.TRANSITIONS.DEFAULT}
        transformStart={VARIABLES.MODAL.CONTAINER.C_1.TRANSFORM_START}
        transformEnd={VARIABLES.MODAL.CONTAINER.C_1.TRANSFORM_END}
        onClickClose={onClickClose}
      >
        <Modal.Section
          paddingVertical='25'
          paddingHorizontal='35'
          bgColor={VARIABLES.COLORS.WHITE}
          borderBottomWidth='1'
          borderBottomStyle='solid'
          borderBottomColor={VARIABLES.COLORS.GRAY_8}
          borderTopRightRadius={VARIABLES.MODAL.HEADER.H_1.BORDER_TOP_RIGHT_RADIUS}
          borderTopLeftRadius={VARIABLES.MODAL.HEADER.H_1.BORDER_TOP_LEFT_RADIUS}
        >
          <Text
            regular16
            singleLine
            color={VARIABLES.COLORS.BLACK}
            top='2'
          >
            {title}
          </Text>
        </Modal.Section>
        <Modal.Section
          paddingVertical='45'
          paddingHorizontal='50'
          bgColor={VARIABLES.COLORS.WHITE}
        >
          <Section spacingBottom='54'>
            <Grid >
              <Grid.Column grid='4'>
                <Section spacingBottom='18'>
                  <Text
                    bold12
                    color={VARIABLES.COLORS.GRAY_19}
                  >
                    {getLanguage('coilApproval.modal.modalCoilApprovalDetail.label.coilName', '')}
                  </Text>
                </Section>
                <Section>
                  <Text
                    bold14
                    color={VARIABLES.COLORS.BLUE_22}
                  >
                    {data.coilName}
                  </Text>
                </Section>
              </Grid.Column>
              <Grid.Column grid='4'>
                <Section spacingBottom='18'>
                  <Text
                    bold12
                    color={VARIABLES.COLORS.GRAY_19}
                  >
                    {getLanguage('coilApproval.modal.modalCoilApprovalDetail.label.coilId', '')}
                  </Text>
                </Section>
                <Section>
                  <Text
                    bold14
                    color={VARIABLES.COLORS.BLUE_22}
                  >
                    {data.coilId}
                  </Text>
                </Section>
              </Grid.Column>
            </Grid>
          </Section>
          <Section spacingBottom='50'>
            <Grid >
              <Grid.Column grid='4'>
                <Section spacingBottom='18'>
                  <Text
                    bold12
                    color={VARIABLES.COLORS.GRAY_19}
                  >
                    {getLanguage('coilApproval.modal.modalCoilApprovalDetail.label.tranferAmount', '')}
                  </Text>
                </Section>
                <Section>
                  <Text
                    bold14
                    color={VARIABLES.COLORS.BLUE_22}
                  >
                    {data.length}
                  </Text>
                </Section>
              </Grid.Column>
              <Grid.Column grid='4'>
                <Section spacingBottom='18'>
                  <Text
                    bold12
                    color={VARIABLES.COLORS.GRAY_19}
                  >
                    {getLanguage('coilApproval.modal.modalCoilApprovalDetail.label.reWeight', '')}
                  </Text>
                </Section>
                <Section>
                  <Text
                    regular14
                    color={VARIABLES.COLORS.GRAY_19}
                  >
                    {data.weight}
                  </Text>
                </Section>
              </Grid.Column>
              <Grid.Column grid='4'>
                <Section spacingBottom='18'>
                  <Text
                    bold12
                    color={VARIABLES.COLORS.GRAY_19}
                  >
                    {getLanguage('coilApproval.modal.modalCoilApprovalDetail.label.lengthBalance', '')}
                  </Text>
                </Section>
                <Section>
                  <Text
                    regular14
                    color={VARIABLES.COLORS.GRAY_19}
                  >
                    {data.lengthBalance}
                  </Text>
                </Section>
              </Grid.Column>
            </Grid>
          </Section>
          <Section spacingBottom='50'>
            <Grid >
              <Grid.Column grid='4'>
                <Section spacingBottom='18'>
                  <Text
                    bold12
                    color={VARIABLES.COLORS.GRAY_19}
                  >
                    {getLanguage('coilApproval.modal.modalCoilApprovalDetail.label.thickness', '')}
                  </Text>
                </Section>
                <Section>
                  <Text
                    bold14
                    color={VARIABLES.COLORS.BLUE_22}
                  >
                    {data.thickness}
                  </Text>
                </Section>
              </Grid.Column>
              <Grid.Column grid='4'>
                <Section spacingBottom='18'>
                  <Text
                    bold12
                    color={VARIABLES.COLORS.GRAY_19}
                  >
                    {getLanguage('coilApproval.modal.modalCoilApprovalDetail.label.color', '')}
                  </Text>
                </Section>
                <Section>
                  <Text
                    regular14
                    color={VARIABLES.COLORS.GRAY_19}
                  >
                    {data.color}
                  </Text>
                </Section>
              </Grid.Column>
              <Grid.Column grid='4'>
                <Section spacingBottom='18'>
                  <Text
                    bold12
                    color={VARIABLES.COLORS.GRAY_19}
                  >
                    {getLanguage('coilApproval.modal.modalCoilApprovalDetail.label.aging', '')}
                  </Text>
                </Section>
                <Section>
                  <Text
                    regular14
                    color={VARIABLES.COLORS.GRAY_19}
                  >
                    {data.aging}
                  </Text>
                </Section>
              </Grid.Column>
            </Grid>
          </Section>
        </Modal.Section>
        <Modal.Section
          paddingHorizontal='45'
          paddingVertical='30'
          borderTopWidth={VARIABLES.MODAL.FOOTER.F_1.BORDER_TOP_WIDTH}
          borderTopStyle={VARIABLES.MODAL.FOOTER.F_1.BORDER_TOP_STYLE}
          borderTopColor={VARIABLES.COLORS.GRAY_8}
          borderBottomRightRadius={VARIABLES.MODAL.FOOTER.F_1.BORDER_BOTTOM_RIGHT_RADIUS}
          borderBottomLeftRadius={VARIABLES.MODAL.FOOTER.F_1.BORDER_BOTTOM_LEFT_RADIUS}
        >
          <Grid
            gutter='15'
            justify='flex-end'
          >
            <Grid.Column>
              <Button
                blue
                width={VARIABLES.BUTTON.WIDTHS.W_120}
                name={getLanguage('field.buttonClose')}
                onClick={onClickClose}
              />
            </Grid.Column>
          </Grid>
        </Modal.Section>
      </Modal>
    )
  }
}
