import React from 'react'
import {
  Breadcrumb,
  Section,
  TitlePage,
  ShapeContainer,
  TitleSection,
  Field,
  Grid,
  SelectControl,
  Button
} from './../../components'
import {
  VARIABLES,
  ICONS,
  CONTENTS
} from './../../themes'
import { FieldAddressContainerConnected } from './../../containers/FieldAddressContainer'
import { setSideMenuActive } from '../../helpers/functions/route';
import { ROUTE_PATH } from '../../helpers/constants/route';
import { redirect } from '../../helpers/functions/route';
import { storeGetState } from '../../stores';
import { isValidNumber, isValidEmail, isValidResponse, isValidPhoneNumber } from '../../helpers/functions/validation';
import { toast } from '../../helpers/functions/main';
import _ from 'lodash';
import { getLanguage } from '../../helpers/functions/language';
import { adController } from '../../apiService/apiController/adService';
import { setLocalStorage } from '../../helpers/functions/localStorage';
import { LOCAL_STORAGE } from '../../helpers/constants/localStorage';
import { setUserAuthRedux } from '../../helpers/functions/auth';
import { FieldUploadContainer } from '../../containers/FieldUploadContainer';
import { firebaseUpdateFile, firebaseDeleteFile } from '../../helpers/firebases/firebaseStorage';
import { ENUM } from '../../helpers/constants/enum';
import { AdInformationChangePassword } from './AdInformationChangePassword';
import { DATA } from '../../helpers/constants/data';
import { getCountryPhone } from '../../helpers/functions/data';
import { displayUrlPath } from '../../helpers/functions/display';
import { FIREBASE_PUBLIC_STORAGE_PATH } from '../../config/config';

export class AdInformationScreen extends React.Component {

  constructor(props) {
    super(props);
    setSideMenuActive(props);
    const defaultData = {
      name: '',
      email: '',
      businessRegNo: '',
      mobileNo: '',
      phoneNo: '',
      mobileNoCountry: getCountryPhone(),
      phoneNoCountry: getCountryPhone(),
    }
    const data = storeGetState().mainRedux.userAuth;
    const isSalePerson = (data.role && data.role.toLowerCase() === ENUM.USER_ROLE.SALES_PERSON.toLowerCase());
    this.state = {
      selectedOption: null,
      data: {
        ...defaultData,
        ...data,
      },
      isChangePassword: isSalePerson,
      isSalePerson,
    }
  }

  handleChange = selectedOption => {
    this.setState({
      selectedOption
    })
  }

  adInfo = {
    isValidatePass: () => {
      const {
        data,
      } = this.state;
      let isPass = true;
      if (
        !data.email.length
        // !data.mobileNo.length ||
        // !data.phoneNo.length
      ) {
        toast.error(getLanguage('message.pleaseInsertAllRequiredField', ''));
        isPass = false;
      }
      DATA.PHONE_PREFIX_LIST.forEach(e => {
        if (data.mobileNoCountry.value === e.value) {
          if (e.lengthList.indexOf(data.mobileNo.length) === -1) {
            toast.error(getLanguage('message.incorrectMobileNumberLength', ''));
            isPass = false;
          }
        }
        // if (data.phoneNo.length && data.phoneNoCountry.value === e.value) {
        //   if (e.lengthList.indexOf(data.phoneNo.length) === -1) {
        //     toast.error(getLanguage('message.incorrectPhoneNumberLength', ''));
        //     isPass = false;
        //   }
        // }
      })
      if (!isValidEmail(data.email)) {
        toast.error(getLanguage('message.wrongEmailFormat', ''));
        isPass = false;
      }
      return isPass;
    },
    isValidateChangePasswordPass: (password, newPassword) => {
      const {
        data,
      } = this.state;
      let isPass = true;
      // if (data.password !== password) {
      //   toast.error(getLanguage('message.passwordIncorrect', ''));
      //   isPass = false;
      // }
      if (
        password.length === 0 ||
        newPassword.length === 0
      ) {
        toast.error(getLanguage('message.pleaseInsertAllRequiredField', ''));
        isPass = false;
      }
      return isPass;
    },
    updateUserAuth: (data) => {
      setUserAuthRedux(data);
      setLocalStorage(LOCAL_STORAGE.USER_AUTH_RBPTRUEBLUE, data);
    },
    onClickSubmit: async () => {
      const {
        data,
        isSalePerson,
      } = this.state;
      if (this.adInfo.isValidatePass()) {
        let newImageRef = data.oldImageRef;
        console.log('data', data)
        if (data.oldImageUrl.length && data.imageUrl.length === 0) {
          firebaseDeleteFile(data.oldImageRef);
          newImageRef = '';
          data.imageUrl = '';
          data.oldImageUrl = '';
        }
        else if (data.oldImageUrl !== data.imageUrl) {
          const storagePath = `${ENUM.UPLOAD_PATH.IMAGE_PROFILE}/${data.id}`;
          newImageRef = await firebaseUpdateFile(storagePath, data.imageFile, `${new Date().getTime()}.png`, data.oldImageRef);
          newImageRef = newImageRef.ref.fullPath;
          data.imageUrl = displayUrlPath(newImageRef);
          data.oldImageUrl = data.imageUrl;
        }
        data.oldImageRef = newImageRef;
        console.log('newImageRef', newImageRef);

        const params = {
          id: data.id,
          name: data.name,
          business_reg_no: data.businessRegNo,
          email: data.email,
          mobile_no: data.mobileNoCountry.value + data.mobileNo,
          phone_no: data.phoneNoCountry.value + data.phoneNo,
          address: JSON.stringify({
            address_text: data.address,
            province: data.addressType === ENUM.COUNTRY_TYPE.LAOS ?
              data.selectedProvince
              :
              (data.selectedProvince || {}).value ? (data.selectedProvince || {}).value : '',
            city: data.addressType === ENUM.COUNTRY_TYPE.LAOS ?
              data.selectedCity
              :
              (data.selectedCity || {}).value ? (data.selectedCity || {}).value : '',
            province_text: data.addressType === ENUM.COUNTRY_TYPE.LAOS ?
              data.selectedProvince
              :
              (data.selectedProvince || {}).label ? (data.selectedProvince || {}).label : '',
            city_text: data.addressType === ENUM.COUNTRY_TYPE.LAOS ?
              data.selectedCity
              :
              (data.selectedCity || {}).label ? (data.selectedCity || {}).label : '',
            district: data.district,
            sub_district: data.addressType === ENUM.COUNTRY_TYPE.THAI ?
              (data.subDistrict || {}).label
              : data.subDistrict,
            alley: data.alley,
            group: data.group,
            street: data.street,
            ward: data.ward,
            address_type: data.addressType
          }),
          post_code: data.addressType === ENUM.COUNTRY_TYPE.THAI ?
            (data.postCode || {}).label
            : data.postCode,
          sfid: data.sfid,
        }

        if (newImageRef.indexOf('http') === 0) {
          params.image_url = newImageRef
        }
        else {
          params.image_url = newImageRef ? `${FIREBASE_PUBLIC_STORAGE_PATH}/${newImageRef}` : '';
        }

        const adService = adController();
        const res = await adService.updateADInfo(params);
        if (isValidResponse(res)) {
          console.log('data', data)
          this.adInfo.updateUserAuth(data);
          toast.success(getLanguage('message.updateAdCompleted', ''));
        }
        else {
          toast.error(res.message);
        }
      }
    },
    onClickChangePassword: () => {
      this.setState({
        isChangePassword: true
      })
    },
    onClickChangePasswordBack: () => {
      this.setState({
        isChangePassword: false
      })
    },
    onClickChangePasswordSubmit: async (password, newPassword) => {
      const {
        data,
        isSalePerson
      } = this.state;
      if (this.adInfo.isValidateChangePasswordPass(password, newPassword)) {
        const params = {
          id: data.id,
          old_password: password,
          password: newPassword,
          image_url: data.oldImageRef,
          sfid: data.sfid,
          phone_no: data.phoneNoCountry.value + data.phoneNo
        }
        const adService = adController();
        const res = await adService.updateADInfo(params);
        if (isValidResponse(res)) {
          // data.password = newPassword;
          console.log('data', data)
          // this.adInfo.updateUserAuth(data);
          if (isSalePerson) {
            toast.success(getLanguage('message.updateSalesPersonCompleted', ''));
          }
          else {
            this.adInfo.onClickChangePasswordBack();
            toast.success(getLanguage('message.updateAdCompleted', ''));
          }
        }
        else {
          toast.error(res.message);
        }
      }
    }
  }

  input = {
    onChangeInputUserAuth: (ev) => {
      const {
        data,
      } = this.state
      const name = ev.target.name;
      const value = ev.target.value;
      switch (name) {
        case 'phoneNo':
        case 'mobileNo':
          if (value.length && !isValidPhoneNumber(value)) {
            return;
          }
        case 'postCode':
          if (value.length && !isValidNumber(value)) {
            return;
          }
        default:
          data[name] = value;
          this.setState({
            data
          })
      }
    },
    onChangeUpload: (file, result) => {
      const {
        data,
      } = this.state;
      data.imageUrl = result;
      data.imageFile = file;
      this.setState({
        data,
      });
    },
    onClickDeleteImage: () => {
      const {
        data,
      } = this.state;
      data.imageUrl = '';
      data.imageFile = undefined;
      this.setState({
        data,
      });
    },
    onChangePhoneCountry: (selected, target) => {
      const {
        data,
      } = this.state;
      data[target] = selected;
      this.setState({
        data,
      })
    },
    onChangeAddress: (selected, target) => {
      const {
        data,
      } = this.state;
      data[target] = selected;
      switch (target) {
        case 'selectedProvince':
          data.selectedCity = null;
        case 'selectedCity':
          //TODO: loas
          if (data.addressType === ENUM.COUNTRY_TYPE.THAI) {
            data.subDistrict = null;
            data.postCode = null;
          }
          break;
        case 'subDistrict':
          //TODO: loas
          if (data.addressType === ENUM.COUNTRY_TYPE.THAI) {
            data.postCode = null;
          }
          break;
        default: break;
      }
      this.setState({
        data,
      })
    }
  }

  render() {
    const {
      data,
      isChangePassword,
      isSalePerson,
    } = this.state;
    return (
      <React.Fragment>
        <Breadcrumb>
          <Breadcrumb.List
            link
            name={getLanguage('adInfo.breadcrumb1', '')}
            onClick={() => {
              redirect(ROUTE_PATH.HOME.LINK)
            }}
          />
          <Breadcrumb.List name='/' />
          <Breadcrumb.List
            link
            name={getLanguage('adInfo.breadcrumb2', '')}
          // onClick={() => {
          //   redirect(ROUTE_PATH.SETTINGS.LINK)
          // }}
          />
          <Breadcrumb.List name='/' />
          <Breadcrumb.List name={isSalePerson ?
            getLanguage('adInfo.breadcrumb3_2', '')
            :
            getLanguage('adInfo.breadcrumb3', '')} />
        </Breadcrumb>
        {
          isChangePassword ?
            <AdInformationChangePassword
              isSalePerson={isSalePerson}
              onClickBack={this.adInfo.onClickChangePasswordBack}
              onClickSubmit={this.adInfo.onClickChangePasswordSubmit}
            />
            :
            <Section ui='content'>
              <Section spacingBottom='30'>
                <TitlePage
                  src={ICONS['ic-page-settings.svg']}
                  name={isSalePerson ?
                    getLanguage('adInfo.title_2', '')
                    :
                    getLanguage('adInfo.title', '')}
                />
              </Section>
              <ShapeContainer ui='content'>
                <Section spacingBottom='40'>
                  <TitleSection name={getLanguage('adInfo.form.info.title', '')} />
                </Section>
                <Section relative>
                  <Section uploadAvatar>
                    <FieldUploadContainer ui='uploadAvatar'
                      src={data.imageUrl}
                      onChange={this.input.onChangeUpload}
                      onClickDeleteImage={this.input.onClickDeleteImage}
                    />
                  </Section>
                  <Grid>
                    <Grid.ResponsiveColumn ui='fieldText'>
                      <Section spacingBottom='30'>
                        <Field name='name'
                          fluid
                          disabled
                          ui='blockLabelVertical'
                          label={getLanguage('field.adName', '')}
                          placeholder={getLanguage('field.adNamePlaceholder', '')}
                          value={data.name}
                          onChange={this.input.onChangeInputUserAuth}
                        />
                      </Section>
                      <Section spacingBottom='30'>
                        <Field name='businessRegNo'
                          fluid
                          disabled
                          ui='blockLabelVertical'
                          label={getLanguage('field.businessRegisNo', '')}
                          placeholder={getLanguage('field.businessRegisNoPlaceholder', '')}
                          value={data.businessRegNo}
                          onChange={this.input.onChangeInputUserAuth}
                        />
                      </Section>
                      <Section spacingBottom='30'>
                        <Field name='email'
                          fluid
                          required
                          ui='blockLabelVertical'
                          label={getLanguage('field.email', '')}
                          placeholder={getLanguage('field.emailPlaceholder', '')}
                          value={data.email}
                          onChange={this.input.onChangeInputUserAuth}
                        />
                      </Section>
                      <Section
                        zIndexLv2
                        spacingBottom='30'
                      >
                        <Field name='mobileNo'
                          fluid
                          required
                          // error
                          // disabled
                          ui='blockLabelVerticalPhone'
                          label={getLanguage('field.mobilePhoneNo', '')}
                          placeholder={getLanguage('field.mobilePhoneNoPlaceholder', '')}
                          message={
                            <Section
                              ui='hintBottomField'
                              hint={getLanguage('field.phoneNoHint', '')}
                            />
                          }
                          value={data.mobileNo}
                          onChange={this.input.onChangeInputUserAuth}
                          selectedPhoneCountry={data.mobileNoCountry}
                          onChangePhoneCountry={selected => {
                            this.input.onChangePhoneCountry(selected, 'mobileNoCountry')
                          }}
                        />
                      </Section>
                      {/* <Section spacingBottom='30'>
                        <Field name='phoneNo'
                          fluid
                          required
                          // error
                          disabled
                          ui='blockLabelVerticalPhone'
                          label={getLanguage('field.phoneNo', '')}
                          placeholder={getLanguage('field.phoneNoPlaceholder', '')}
                          message={
                            <Section
                              ui='hintBottomField'
                              hint={getLanguage('field.phoneNoHint', '')}
                            />
                          }
                          value={data.phoneNo}
                          onChange={this.input.onChangeInputUserAuth}
                          selectedPhoneCountry={data.phoneNoCountry}
                          onChangePhoneCountry={selected => {
                            this.input.onChangePhoneCountry(selected, 'phoneNoCountry')
                          }}
                        />
                      </Section>
                     */}
                    </Grid.ResponsiveColumn>
                  </Grid>
                  <Section spacingBottom='60'>
                    <FieldAddressContainerConnected
                      thai={data.addressType === ENUM.COUNTRY_TYPE.THAI}
                      malaysia={data.addressType === ENUM.COUNTRY_TYPE.MALAYSIA}
                      vietnam={data.addressType === ENUM.COUNTRY_TYPE.VIETNAM}
                      indonesia={data.addressType === ENUM.COUNTRY_TYPE.INDONESIA}
                      laos={data.addressType === ENUM.COUNTRY_TYPE.LAOS}
                      // Address
                      requiredAddress={false}
                      nameAddress='address'
                      valueAddress={data.address}
                      onChangeAddress={this.input.onChangeInputUserAuth}
                      // Province
                      requiredProvince={false}
                      nameProvince='selectedProvince'
                      valueProvince={data.selectedProvince}
                      onChangeProvince={(selected) => {
                        if (data.addressType === ENUM.COUNTRY_TYPE.LAOS) {
                          this.input.onChangeInputUserAuth(selected);
                        }
                        else {
                          this.input.onChangeAddress(selected, 'selectedProvince');
                        }
                      }}
                      // optionProvince={[
                      //   { value: 'option', label: 'Option' }
                      // ]}
                      // City
                      requiredCity={false}
                      nameCity='selectedCity'
                      valueCity={data.selectedCity}
                      onChangeCity={(selected) => {
                        if (data.addressType === ENUM.COUNTRY_TYPE.LAOS) {
                          this.input.onChangeInputUserAuth(selected);
                        }
                        else {
                          this.input.onChangeAddress(selected, 'selectedCity');
                        }
                      }}
                      // optionCity={[
                      //   { value: 'option', label: 'Option' }
                      // ]}
                      // Subdistrict
                      requiredSubdistrict={false}
                      nameSubdistrict='subDistrict'
                      valueSubdistrict={data.subDistrict}
                      onChangeSubdistrict={(selected) => {
                        if (data.addressType === ENUM.COUNTRY_TYPE.THAI) {
                          this.input.onChangeAddress(selected, 'subDistrict');
                        }
                        else {
                          this.input.onChangeInputUserAuth(selected);
                        }
                      }}
                      // District
                      requiredDistrict={false}
                      isSearchableDistrict={false}
                      nameDistrict='district'
                      valueDistrict={data.district}
                      onChangeDistrict={this.input.onChangeInputUserAuth}
                      // optionDistrict={[
                      //   { value: 'option', label: 'Option' }
                      // ]}
                      // Alley
                      requiredAlley={false}
                      nameAlley='alley'
                      valueAlley={data.alley}
                      onChangeAlley={this.input.onChangeInputUserAuth}
                      // Group
                      requiredGroup={false}
                      nameGroup='group'
                      valueGroup={data.group}
                      onChangeGroup={this.input.onChangeInputUserAuth}
                      // Street
                      requiredStreet={false}
                      nameStreet='street'
                      valueStreet={data.street}
                      onChangeStreet={this.input.onChangeInputUserAuth}
                      // Ward
                      requiredWard={false}
                      nameWard='ward'
                      valueWard={data.ward}
                      onChangeWard={this.input.onChangeInputUserAuth}
                      // PostalCode
                      requiredPostalCode={false}
                      namePostalCode='postCode'
                      valuePostalCode={data.postCode}
                      onChangePostalCode={(selected) => {
                        if (data.addressType === ENUM.COUNTRY_TYPE.THAI) {
                          this.input.onChangeAddress(selected, 'postCode');
                        }
                        else {
                          this.input.onChangeInputUserAuth(selected);
                        }
                      }}
                    />
                  </Section>
                  <Grid
                    gutter='15'
                    justify='flex-end'
                  >
                    <Grid.Column>
                      <Button
                        brown
                        ui='frontIcon'
                        name={getLanguage('field.buttonChangePassword', '')}
                        iconWidth='18'
                        iconHeight='20'
                        iconSrc={ICONS['ic-lock.svg']}
                        onClick={this.adInfo.onClickChangePassword}
                      />
                    </Grid.Column>
                    <Grid.Column>
                      <Button
                        blue
                        name={getLanguage('field.buttonSave', '')}
                        width={VARIABLES.BUTTON.WIDTHS.W_155}
                        onClick={this.adInfo.onClickSubmit}
                      />
                    </Grid.Column>
                  </Grid>
                </Section>
              </ShapeContainer>
            </Section>
        }
      </React.Fragment>
    )
  }
}
