import React from 'react'
import ClassNames from 'classnames'
import PropTypes from 'prop-types'
import {
  VideoWrapper
} from './styled'
// import {
// } from './../../../components'

// -------------------- Please remove this comment after reading message. --------------------
// Notice: Cause of import separation is styguidist require a root path for generate document.
// -------------------------------------------------------------------------------------------

// import {
//   default as VARIABLES
// } from './../../../themes/styles/bases/variables'
// import {
//   default as TYPOGRAPHYS
// } from './../../../themes/styles/bases/typographys'
// import {
//   default as UTILITIES
// } from './../../../themes/styles/helpers/utilities'

/**
 * Video description:
 * - Video
 */

export class Video extends React.PureComponent {
  render () {
    const {
      className,
      srcLink,
      srcFile,
      heightRatio,
      blueFrame,
      grayFrame
    } = this.props

    // props for css classes
    const classes = ClassNames(
      'video',
      className,
      {'is-blue-frame': blueFrame},
      {'is-gray-frame': grayFrame}
    )

    return (
      <VideoWrapper
        className={classes}
        theme={{
          heightRatio
        }}
      >
        {srcLink &&
          <iframe
            className='video-content'
            src={srcLink}
            frameBorder='0'
            allow='autoplay; encrypted-media'
            allowFullScreen
          />
        }

        {srcFile &&
          <video
            controls
            className='video-content'
          >
            <source src={srcFile} type='video/mp4' />
          </video>
        }
      </VideoWrapper>
    )
  }

  static defaultProps = {
    // * If you set new default props, you should create propsType for correct styleguide document.
    heightRatio: 56
  }

  static propTypes = { // TYPE > node, string, number, bool, array, object, symbol, func
    /**
    * Additional classes
    */
    className: PropTypes.string
  }
}
