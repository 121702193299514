import React from 'react'
import ClassNames from 'classnames'
import PropTypes from 'prop-types'
import {
  osName,
} from 'react-device-detect'
import {
  WarrantyNoteWrapper
} from './styled'
import {
  Section,
  Text,
  Image,
  Grid,
  Table,
  ShapeContainer,
} from './../../../../components'
import {
  VARIABLES,
} from './../../../../themes'

/**
 * Warranty description:
 * - Warranty
 */

export class WarrantyBSWMVietnamNote extends React.PureComponent {
  render() {
    const {
      customerName,
    } = this.props

    // props for css classes
    const classes = ClassNames(
      'warranty-bswm-note',
    )

    return (
      <WarrantyNoteWrapper
        className={classes}
      >
        <p className='warranty-bswm-text is-xs'><span className='warranty-bswm-text is-xs is-bold'>NOTE:</span> This warranty is provided to, and can only be actioned by, <span className='warranty-bswm-text is-xs is-bold'>{customerName}</span> subject to the express terms and conditions attached. Failure, despite best endeavours, to identify problems with environment and/or installation during project inspection shall not preclude or limit the operation of the terms and conditions attached.</p>
      </WarrantyNoteWrapper>
    )
  }

  static defaultProps = {
    // If you set new default props, you should create propsType for correct styleguide document.
  }

  static propTypes = {
    // TYPE > node, string, number, bool, array, object, symbol, func
  }
}
