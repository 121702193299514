import {
  store, storeGetState, storeDispatch,
} from '../../stores';
import { ROUTE_REDUX_STATE_KEY, routeCreators } from '../../actions/routeAction';
import { ROUTE_PATH, SIDE_MENU_LIST } from '../constants/route';
import { getLanguage, language } from './language';
import { lang_en } from '../constants/languageEN';
import { getUserAuthRedux } from './auth';
import { ENUM } from "../constants/enum";
import React from 'react';
import { Beforeunload } from 'react-beforeunload';
import { Prompt } from 'react-router-dom';
import _ from 'lodash';

export const redirect = (path, params) => {
  const routeRedux = storeGetState().routeRedux
  routeRedux.routeHistory.push(path, params)
}

export const redirectReplace = (path, params) => {
  const routeRedux = storeGetState().routeRedux
  routeRedux.routeHistory.replace(path, params)
}

export const redirectData = (path, params) => {
  const routeRedux = storeGetState().routeRedux
  routeRedux.routeHistory.replace(path, {
    pathname: path,
    ...params,
  })
}

export const setRouteHistory = (props) => {
  storeDispatch(routeCreators.setRouteRedux(ROUTE_REDUX_STATE_KEY.ROUTE_HISTORY, props.history))
}

export const setSideMenuActive = (props) => {
  const userAuth = getUserAuthRedux();
  const pathname = props.match.path.split('/:')[0];
  const menuActive = Object.values(ROUTE_PATH).filter(e => {
    return e.LINK === pathname && e.MENU_ACTIVE
  })[0]
  if (menuActive) {
    let mainActive = '';
    let childActive = '';
    let isNoExpanded = false;
    Object.keys(lang_en.sideMenu).forEach(key => {
      const sideMenu = lang_en.sideMenu[key];
      const childMenu = lang_en.sideMenu[key];
      if (sideMenu === menuActive.MENU_ACTIVE) {
        mainActive = language.sideMenu[key];
        isNoExpanded = menuActive.IS_NO_EXPANDED;
      }
      if (childMenu === menuActive.MENU_CHILD_ACTIVE) {
        childActive = language.sideMenu[key];
      }
    });

    if (userAuth.addressType === ENUM.COUNTRY_TYPE.THAI) {
      if (mainActive === SIDE_MENU_LIST[6]) {
        mainActive = getLanguage('sideMenu.warrantyManagement', '')
      }
    }

    storeDispatch(routeCreators.setRouteRedux(ROUTE_REDUX_STATE_KEY.SIDE_MENU_ACTIVE, {
      mainActive,
      childActive,
      isNoExpanded,
    }));
    // setTimeout(() => {
    // document.querySelector('.accordion__item [aria-selected="true"]').click();
    // }, 2000);


  }
}

export const getRouteParams = (props, target) => {
  return props.match.params[target];
}

export const getRouteData = (props, target) => {
  if (props.location.state) {
    return props.location.state[target];
  }
}


export const handleBeforeLeave = (self, callbackAlert = () => { }) => {
  const handleBeforeunload = () => {
    const isCanLeave = handleCheckConfirmLeave();
    if (!isCanLeave) {
      return 'Changes you made may not be saved.';
    }
  }

  const handleCheckConfirmLeave = (pathName) => {
    const {
      dataOld,
      data,
    } = self.state;
    if (JSON.stringify(data) !== JSON.stringify(dataOld)) {
      if (pathName) {
        callbackAlert(pathName);
      }
      return false;
    }
    return true;
  }

  return (
    <React.Fragment>
      <Beforeunload onBeforeunload={() => handleBeforeunload()} />
      <Prompt
        message={(location, action) => {
          return handleCheckConfirmLeave(location.pathname);
        }}
      />
    </React.Fragment>
  )
}