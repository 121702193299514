import _ from 'lodash';
import React from 'react';
import { PROFILE_MAPPING_RULE } from "../../helpers/constants/profileMapping";
import { ROUTE_PATH } from '../../helpers/constants/route';
import { getLanguage } from '../../helpers/functions/language';
import { toast } from '../../helpers/functions/main';
import { getRouteData, getRouteParams, redirect, redirectData, setSideMenuActive } from '../../helpers/functions/route';
import { crtProfileMapping } from '../../helpers/services/profile';
import {
  Breadcrumb,
  Button,
  Field,
  Grid,
  Section,
  SelectControl,
  ShapeContainer,
  Text,
  TitlePage,
  TitleSection
} from './../../components';
import {
  ICONS,
  VARIABLES
} from './../../themes';

export class ProfileMappingProductManageScreen extends React.Component {

  constructor(props) {
    super(props);
    setSideMenuActive(props);
    const productList = getRouteData(props, 'productList');
    const routeDataNoLengthList = getRouteData(props, 'dataNoLengthList');
    const routeData = getRouteData(props, 'data');
    const profile = getRouteData(props, 'profile');
    const isToko = getRouteData(props, 'isToko');
    const productId = parseInt(getRouteParams(props, 'id'));
    const dataDefault = {
      productTypeUnit: true,
      Ad: true,
    }
    const data = {
      ...dataDefault,
      ...routeData,
    };
    // console.log('profile', profile);
    this.state = {
      productList,
      dataNoLengthList: routeDataNoLengthList || [],
      productId,
      data,
      productTypeValue: '',
      length: 0,
      quantity: 0,
      totalLength: 0,
      profile,
      isToko,
      isDisable: true,
      disableAll: true,
      isProfile: false
    }
  }

  profileMappingProductManage = {
    goBack: () => {
      const {
        productList,
        dataNoLengthList,
        isToko,
        profile,
      } = this.state
      if (isToko) {
        redirectData(ROUTE_PATH.PRODUCT_DETAIL.LINK, {
          dataList: productList,
          dataNoLengthList,
          isToko,
        })
      }
      else {
        redirectData(ROUTE_PATH.PROFILE_MAPPING_PRODUCT_LIST.LINK, {
          dataList: productList,
          dataNoLengthList,
          profile: profile,
        });
      }
    }
  }

  input = {
    onChangeSelect: (name, data) => {
      const unit = ['trussc7565', 'trussc7575', 'trussc7510', 'trussc75080', 'rengr3045', 'rengr3245', 'rengr3545']
      let dataPage = this.state.data
      let chkUnit, chkLen
      let chkDisable, disableAll = false

      if (unit.includes(data.value)) {
        chkUnit = true
        chkLen = 6
        chkDisable = true
        disableAll = false
      } else {
        chkUnit = false
        chkLen = 0
      }

      dataPage.productTypeUnit = chkUnit

      this.setState({
        [name]: data.value,
        data: dataPage,
        isDisable: chkDisable,
        disableAll: disableAll,
        isProfile: disableAll,
        length: chkLen,
        quantity: 0,
        totalLength: 0,
      })
    },
    onChangeInput: (ev) => {
      const {
        data,
        quantity,
        length,
        productTypeValue
      } = this.state

      let nVal1, nVal2, totalLength
      nVal1 = parseInt(ev.target.value || 0)
      nVal1 = isNaN(nVal1) ? 0 : nVal1

      if (ev.target.name === 'length') {
        nVal2 = quantity
      } else {
        nVal2 = length
      }

      const widthPerStk = {
        trussc7565: 151.8,
        trussc7575: 151.8,
        rengr3045: 112,
        rengr3245: 102,
        rengr3545: 101
      }

      if (data.coilWidthMM < 1219 || !widthPerStk[productTypeValue]) {
        // toast.error(getLanguage('profileMapping.form.shopInfo.coilValue', ''));
        // return;
        totalLength = Math.round(nVal1 * nVal2)
      } else {
        totalLength = Math.ceil(nVal1 / Math.floor(data.coilWidthMM / widthPerStk[productTypeValue])) * nVal2
      }

      // if (widthPerStk[productTypeValue]) {
      //   // console.log('data.coilWidthMM =>', data.coilWidthMM);
      //   // console.log('widthPerStk[productTypeValue] =>', widthPerStk[productTypeValue]);
      //   totalLength = Math.ceil(nVal1 / Math.floor(data.coilWidthMM / widthPerStk[productTypeValue])) * nVal2
      // } else {
      //   totalLength = Math.round(nVal1 * nVal2)
      // }

      if (isNaN(totalLength) || totalLength < 0)
        totalLength = 0

      this.setState({
        [ev.target.name]: nVal1,
        totalLength: totalLength
      })
    },
    genProfileOpt(data) {
      console.log('genProfileOpt data', data);
      const optAll = [
        { value: 'trussc7565', label: 'Truss C75-65' }, // unit = stick
        { value: 'trussc7575', label: 'Truss C75-75' }, // unit = stick
        { value: 'trussc7510', label: 'Truss C75-10' }, // unit = stick
        { value: 'trussc7580', label: 'Truss C75-80' }, // unit = stick
        { value: 'rengr3045', label: 'RengR30-45' }, // unit = stick
        { value: 'rengr3245', label: 'RengR32-45' }, // unit = stick
        { value: 'rengr3545', label: 'RengR35-45' }, // unit = stick
        { value: 'longspan025tct', label: 'Long Span 0.25 TCT' }, // unit = sheet
        { value: 'longspan030tct', label: 'Long Span 0.30 TCT' }, // unit = sheet
        { value: 'longspan035tct', label: 'Long Span 0.35 TCT' }, // unit = sheet
        { value: 'longspan040tct', label: 'Long Span 0.40 TCT' }, // unit = sheet
        { value: 'longspan045tct', label: 'Long Span 0.45 TCT' }, // unit = sheet
        { value: 'metaltile025tct', label: 'Metal Tile 0.25 TCT' }, // unit = sheet
        { value: 'metaltile030tct', label: 'Metal Tile 0.30 TCT' }, // unit = sheet
        { value: 'metaltile035tct', label: 'Metal Tile 0.35 TCT' }, // unit = sheet
      ];
      // const profileCon = [
      //   {
      //     profileType: 'trussc7575',
      //     thicknessMM: 0.75,
      //     coilWidthMM: [151, 1219],
      //     bmtMM: 0.70,
      //     itemTypeName: ['Metallic Coated'],
      //     coatingMassMM: ['AZ100'],
      //     steelGrade: [550]
      //   },
      //   {
      //     profileType: 'trussc7565',
      //     thicknessMM: 0.65,
      //     coilWidthMM: [1219],
      //     bmtMM: 0.60,
      //     itemTypeName: ['Metallic Coated'],
      //     coatingMassMM: ['AZ100'],
      //     steelGrade: [550]
      //   },
      //   {
      //     profileType: 'trussc7510',
      //     thicknessMM: 1,
      //     coilWidthMM: [151, 1219],
      //     bmtMM: 0.95,
      //     itemTypeName: ['Metallic Coated'],
      //     coatingMassMM: ['AZ100'],
      //     steelGrade: [550]
      //   },
      //   {
      //     profileType: 'trussc75080',
      //     thicknessMM: 0.8,
      //     coilWidthMM: [163],
      //     bmtMM: 0.75,
      //     itemTypeName: ['Metallic Coated'],
      //     coatingMassMM: ['AZ100'],
      //     steelGrade: [550]
      //   },
      //   {
      //     profileType: 'rengr3545',
      //     thicknessMM: 0.45,
      //     coilWidthMM: [113, 1219],
      //     bmtMM: 0.40,
      //     itemTypeName: ['Metallic Coated'],
      //     coatingMassMM: ['AZ100'],
      //     steelGrade: [550]
      //   },
      //   {
      //     profileType: 'rengr3245',
      //     thicknessMM: 0.45,
      //     coilWidthMM: [1219],
      //     bmtMM: 0.40,
      //     itemTypeName: ['Metallic Coated'],
      //     coatingMassMM: ['AZ100'],
      //     steelGrade: [550]
      //   },
      //   {
      //     profileType: 'rengr3045',
      //     thicknessMM: 0.45,
      //     coilWidthMM: [1219],
      //     bmtMM: 0.40,
      //     itemTypeName: ['Metallic Coated'],
      //     coatingMassMM: ['AZ100'],
      //     steelGrade: [550]
      //   },
      //   {
      //     profileType: 'metaltile025tct',
      //     thicknessMM: 0.25,
      //     coilWidthMM: [914],
      //     bmtMM: 0.20,
      //     itemTypeName: ['Painted Coil'],
      //     coatingMassMM: ['AZ100'],
      //     steelGrade: [300]
      //   },
      //   {
      //     profileType: 'metaltile030tct',
      //     thicknessMM: 0.30,
      //     coilWidthMM: [914, 1219],
      //     bmtMM: 0.25,
      //     itemTypeName: ['Metallic Coated'],
      //     coatingMassMM: ['AZ100'],
      //     steelGrade: [300]
      //   },
      //   {
      //     profileType: 'metaltile035tct',
      //     thicknessMM: 0.35,
      //     coilWidthMM: [914, 1219],
      //     bmtMM: 0.30,
      //     itemTypeName: ['Metallic Coated'],
      //     coatingMassMM: ['AZ100'],
      //     steelGrade: [300]
      //   },
      //   {
      //     profileType: 'longspan045tct',
      //     thicknessMM: 0.45,
      //     coilWidthMM: [1219],
      //     bmtMM: 0.40,
      //     itemTypeName: ['Painted Coil'],
      //     coatingMassMM: ['AZ100'],
      //     steelGrade: [550]
      //   },
      //   {
      //     profileType: 'longspan040tct',
      //     thicknessMM: 0.40,
      //     coilWidthMM: [1219],
      //     bmtMM: 0.35,
      //     itemTypeName: ['Metallic Coated', 'Painted Coil'],
      //     coatingMassMM: ['AZ100'],
      //     steelGrade: [300, 550]
      //   },
      //   {
      //     profileType: 'longspan035tct',
      //     thicknessMM: 0.35,
      //     coilWidthMM: [1219],
      //     bmtMM: 0.30,
      //     itemTypeName: ['Metallic Coated', 'Painted Coil'],
      //     coatingMassMM: ['AZ100', 'AZ70'],
      //     steelGrade: [550]
      //   },
      //   {
      //     profileType: 'longspan030tct',
      //     thicknessMM: 0.30,
      //     coilWidthMM: [1219],
      //     bmtMM: 0.25,
      //     itemTypeName: ['Metallic Coated', 'Painted Coil'],
      //     coatingMassMM: ['AZ100', 'AZ70'],
      //     steelGrade: [300, 550]
      //   },
      //   {
      //     profileType: 'longspan025tct',
      //     thicknessMM: 0.25,
      //     coilWidthMM: [914],
      //     bmtMM: 0.20,
      //     itemTypeName: ['Painted Coil', 'Metallic Coated'],
      //     coatingMassMM: ['AZ100', 'AZ70'],
      //     steelGrade: [300, 550]
      //   },
      // ]

      const filterValue = {
        coilWidthMM: [data.coilWidthMM],
        bmtMM: data.bmtMM,
        itemTypeName: [data.itemTypeName],
        coatingMassMM: [data.coatingMassMM],
        steelGrade: [data.steelGrade]
      }

      const selProfile = _.filter(PROFILE_MAPPING_RULE, filterValue);
      // console.log('selProfile ::', selProfile)

      if (selProfile.length === 0) return selProfile

      const selOpt = selProfile.map(e => {
        // console.log('optAll ::', optAll)
        // console.log('e.profileType ::', e.profileType)
        // console.log('check ::', _.find(optAll, { value: e.profileType }))
        // const opt = _.find(optAll, { value: e.profileType })
        // if (opt) {
        //   return opt
        // }
        return _.find(optAll, { value: e.profileType })
      })
      // console.log('selOpt', selOpt)

      return selOpt
    }
  }

  async onSave() {
    const {
      data,
      profile,
      productTypeValue,
      length,
      quantity,
      isToko,
      totalLength
    } = this.state

    // console.log('data', profile)
    if (quantity === 0 || length === 0) {
      toast.error(getLanguage('profileMapping.form.shopInfo.message', ''));
      return
    }

    if (totalLength > data.fTotalRemaininglength) {
      toast.error(getLanguage('profileMapping.form.shopInfo.message', ''));
      return
    }

    const params = {
      to_dealer: profile.sfid,
      sfid: data.sfid,
      profile_type: productTypeValue,
      allocate_lenght: totalLength,
      length,
      quantity
    }

    if (isToko) {
      params.profile_type = undefined;
      params.length = undefined
    }
    // console.log('params', params)

    const res = await crtProfileMapping(params);

    if (res.status) {
      toast.success(getLanguage('message.saveCompleted', ''));
      redirect(ROUTE_PATH.PROFILE_MAPPING.LINK);
    } else {
      toast.error(res.message);
    }
  }

  render() {
    const {
      data,
      length,
      quantity,
      totalLength,
      productList,
      dataNoLengthList,
      isToko,
      isDisable,
      disableAll,
      profile,
      isProfile
    } = this.state
    const {
      Ad,
      productTypeUnit
    } = data
    // console.log('data', data)
    const profileOpt = this.input.genProfileOpt(data);

    return (
      <React.Fragment>
        <Breadcrumb>
          <Breadcrumb.List
            link
            name='Home'
            onClick={() => {
              redirect(ROUTE_PATH.HOME.LINK)
            }}
          />
          <Breadcrumb.List name='/' />
          <Breadcrumb.List
            link
            name='Inventory'
          // onClick={() => {
          //   redirect(ROUTE_PATH.INVENTORY_INDEXES.LINK)
          // }}
          />
          {Ad ?
            <React.Fragment>
              <Breadcrumb.List name='/' />
              <Breadcrumb.List
                link
                name='Profile Mapping'
                onClick={() => {
                  redirect(ROUTE_PATH.PROFILE_MAPPING.LINK)
                }}
              />
              <Breadcrumb.List name='/' />
              <Breadcrumb.List
                link
                name='Conxecute'
                onClick={() => {
                  redirect(ROUTE_PATH.PROFILE_MAPPING_HARDWARE_AND_TOKO_SHOP_INFO.LINK)
                }}
              />
              <Breadcrumb.List name='/' />
              <Breadcrumb.List
                link
                name='Products Quantity'
                onClick={() => {
                  redirect(ROUTE_PATH.PROFILE_MAPPING_PRODUCT_GROUP.LINK)
                }}
              />
              <Breadcrumb.List name='/' />
              <Breadcrumb.List
                link
                name='Products Detail'
                onClick={this.profileMappingProductManage.goBack}
              />
              <Breadcrumb.List name='/' />
              <Breadcrumb.List name={data.itemDescription} />
            </React.Fragment>
            :
            <React.Fragment>
              <Breadcrumb.List
                link
                name='Products Quantity'
                onClick={() => {
                  redirect(ROUTE_PATH.PROFILE_MAPPING_PRODUCT_GROUP.LINK)
                }}
              />
              <Breadcrumb.List name='/' />
              <Breadcrumb.List
                link
                name='Products Detail'
                onClick={this.profileMappingProductManage.goBack}
              />
              <Breadcrumb.List name='/' />
              <Breadcrumb.List name='Profile Mapping' />

            </React.Fragment>
          }
        </Breadcrumb>
        <Section ui='content'>
          <Section spacingBottom='35'>
            <TitlePage
              src={ICONS['ic-page-inventory.svg']}
              name={data.itemDescription}
            />
          </Section>
          <ShapeContainer
            ui='content'
            justify='space-between'
          >
            <Section spacingBottom='45'>
              <Grid gutter='60'>
                <Grid.Column grid='6'>
                  <Section spacingBottom='9'>
                    <Text
                      regular16
                      color={VARIABLES.COLORS.PRIMARY_2}
                    >
                      Profile Information
                    </Text>
                  </Section>
                  <Section
                    spacingBottom='30'
                    calcHeight='100% - 30px'
                  >
                    <ShapeContainer
                      fluid
                      height='100'
                      heightUnit='%'
                      paddingHorizontal='35'
                      paddingVertical='30'
                      bgColor={VARIABLES.COLORS.BLUE_3}
                      borderWidth='1'
                      borderStyle='solid'
                      borderColor={VARIABLES.COLORS.BLUE_15}
                      borderRadius='10'
                    >
                      <React.Fragment>
                        <Section spacingBottom='10'>
                          <Text
                            regular14
                            color={VARIABLES.COLORS.GRAY_19}
                          >
                            Coil ID
                          </Text>
                        </Section>
                        <Section spacingBottom='35'>
                          <Text
                            regular14
                            color={VARIABLES.COLORS.BLACK}
                          >
                            {data.lotNumber}
                          </Text>
                        </Section>
                        <Section spacingBottom='10'>
                          <Text
                            regular14
                            color={VARIABLES.COLORS.GRAY_19}
                          >
                            Quantity (kg)
                          </Text>
                        </Section>
                        <Section spacingBottom='35'>
                          <Text
                            regular14
                            color={VARIABLES.COLORS.BLACK}
                          >
                            {data.fRemainingweightWithComma}
                          </Text>
                        </Section>
                        <Section spacingBottom='10'>
                          <Text
                            regular14
                            color={VARIABLES.COLORS.GRAY_19}
                          >
                            Length (m.)
                          </Text>
                        </Section>
                        <Section spacingBottom='35'>
                          <Text
                            regular14
                            color={VARIABLES.COLORS.BLACK}
                          >
                            {data.fTotalRemaininglengthWithComma}
                          </Text>
                        </Section>
                        <Section spacingBottom='10'>
                          <Text
                            regular14
                            color={VARIABLES.COLORS.GRAY_19}
                          >
                            Mother coil width
                          </Text>
                        </Section>
                        <Section>
                          <Text
                            regular14
                            color={VARIABLES.COLORS.BLACK}
                          >
                            {data.coilWidthMM}
                          </Text>
                        </Section>
                      </React.Fragment>

                      {/* {Ad ?
                        <React.Fragment>
                          <Section spacingBottom='10'>
                            <Text
                              regular14
                              color={VARIABLES.COLORS.GRAY_19}
                            >
                              Coil ID
                            </Text>
                          </Section>
                          <Section spacingBottom='35'>
                            <Text
                              regular14
                              color={VARIABLES.COLORS.BLACK}
                            >
                              {data.lotNumber}
                            </Text>
                          </Section>
                          <Section spacingBottom='10'>
                            <Text
                              regular14
                              color={VARIABLES.COLORS.GRAY_19}
                            >
                              Quantity (kg)
                            </Text>
                          </Section>
                          <Section spacingBottom='35'>
                            <Text
                              regular14
                              color={VARIABLES.COLORS.BLACK}
                            >
                              358
                            </Text>
                          </Section>
                          <Section spacingBottom='10'>
                            <Text
                              regular14
                              color={VARIABLES.COLORS.GRAY_19}
                            >
                              Length (m.)
                            </Text>
                          </Section>
                          <Section>
                            <Text
                              regular14
                              color={VARIABLES.COLORS.BLACK}
                            >
                              100
                            </Text>
                          </Section>
                        </React.Fragment>
                        :
                        <React.Fragment>
                          <Section spacingBottom='10'>
                            <Text
                              regular14
                              color={VARIABLES.COLORS.GRAY_19}
                            >
                              Profile
                            </Text>
                          </Section>
                          <Section spacingBottom='35'>
                            <Text
                              regular14
                              color={VARIABLES.COLORS.BLACK}
                            >
                              Truss C75-75
                            </Text>
                          </Section>
                          <Section spacingBottom='10'>
                            <Text
                              regular14
                              color={VARIABLES.COLORS.GRAY_19}
                            >
                              Brand / Product Group
                            </Text>
                          </Section>
                          <Section spacingBottom='35'>
                            <Text
                              regular14
                              color={VARIABLES.COLORS.BLACK}
                            >
                              BlueScope Zacs 150
                            </Text>
                          </Section>
                          <Section spacingBottom='10'>
                            <Text
                              regular14
                              color={VARIABLES.COLORS.GRAY_19}
                            >
                              Quantity ({productType ? 'sticks' : 'sheets'})
                            </Text>
                          </Section>
                          <Section spacingBottom='35'>
                            <Text
                              regular14
                              color={VARIABLES.COLORS.BLACK}
                            >
                              358
                            </Text>
                          </Section>
                          <Section spacingBottom='10'>
                            <Text
                              regular14
                              color={VARIABLES.COLORS.GRAY_19}
                            >
                              Length (per {productType ? 'sticks' : 'sheets'})
                            </Text>
                          </Section>
                          <Section>
                            <Text
                              regular14
                              color={VARIABLES.COLORS.BLACK}
                            >
                              {data.fTotalRemaininglengthWithComma}
                            </Text>
                          </Section>
                        </React.Fragment>
                      } */}
                    </ShapeContainer>
                  </Section>
                </Grid.Column>
                <Grid.Column grid='6'>
                  <Section spacingBottom='30'>
                    <TitleSection name='Profile Mapping' />
                  </Section>
                  {!isToko &&
                    <Section spacingBottom='30'>
                      <Grid>
                        <Grid.Column grid='7'>
                          <SelectControl
                            verticalLabel
                            isSearchable={false}
                            label='Profile Type'
                            placeholder='Select Profile Type'
                            disabled={(profileOpt.length === 0)}
                            onChange={(data) => {
                              this.input.onChangeSelect('productTypeValue', data);
                            }}
                            options={profileOpt}
                          />
                        </Grid.Column>
                      </Grid>
                    </Section>
                  }
                  {/* {Ad &&
                    <Section spacingBottom='30'>
                      <Grid>
                        <Grid.Column grid='7'>
                          <SelectControl
                            verticalLabel
                            isSearchable={false}
                            label='Profile Type'
                            placeholder='Select Profile Type'
                            // value={}
                            // onChange={() => {}}
                            options={[
                              { value: 'Truss C75-65', label: 'Truss C75-65' }, // unit = stick
                              { value: 'rengr3045', label: 'RengR30-45' }, // unit = stick
                              { value: 'rengr3245', label: 'RengR32-45' }, // unit = stick
                              { value: 'rengr3545', label: 'RengR35-45' }, // unit = stick
                              { value: 'longspan025tct', label: 'Long Span 0.25 TCT' }, // unit = sheet
                              { value: 'longspan030tct', label: 'Long Span 0.30 TCT' }, // unit = sheet
                              { value: 'longspan035tct', label: 'Long Span 0.35 TCT' }, // unit = sheet
                              { value: 'metaltile025tct', label: 'Metal Tile 0.25 TCT' }, // unit = sheet
                              { value: 'metaltile030tct', label: 'Metal Tile 0.30 TCT' }, // unit = sheet
                              { value: 'metaltile035tct', label: 'Metal Tile 0.35 TCT' }, // unit = sheet
                            ]}
                          />
                        </Grid.Column>
                      </Grid>
                    </Section>
                  } */}
                  <Section spacingBottom='30'>
                    <Grid>
                      <Grid.Column grid='7'>
                        <Field
                          name='quantity'
                          fluid
                          required
                          value={quantity}
                          disabled={disableAll}
                          // error
                          ui='blockLabelVertical'
                          label='Quantity'
                          placeholder='Insert quantity'
                          fieldIcon={
                            <Text
                              regular14
                              color={VARIABLES.COLORS.BLACK}
                            >
                              {/* {productTypeUnit ? 'sticks' : 'sheets'} */}
                              {data.profileTypeUnit}
                            </Text>
                          }
                          fieldIconSpacingRight='25'
                          onChange={this.input.onChangeInput}
                        />
                      </Grid.Column>
                    </Grid>
                  </Section>
                  {!isToko &&
                    <React.Fragment>
                      <Section spacingBottom='30'>
                        <Grid>
                          <Grid.Column grid='7'>
                            <Field
                              name='length'
                              fluid
                              required
                              disabled={isDisable}
                              // error
                              value={length}
                              ui='blockLabelVertical'
                              label='Length * can be in 1 demical (m.)'
                              placeholder='Insert length'
                              fieldIcon={
                                <Text
                                  regular14
                                  color={VARIABLES.COLORS.BLACK}
                                >
                                  {productTypeUnit ? 'per sticks' : 'per sheets'}
                                </Text>
                              }
                              fieldIconSpacingRight='25'
                              onChange={this.input.onChangeInput}
                            />
                          </Grid.Column>
                        </Grid>
                      </Section>
                      <Section>
                        <Grid>
                          <Grid.Column grid='7'>
                            <Field
                              name='totalLength'
                              labelNoWrap
                              disabled
                              fluid
                              // required
                              // error
                              value={totalLength}
                              ui='blockLabelVertical'
                              label='Mother coil length to be deducted from the inventory'
                              onChange={() => { }}
                            />
                          </Grid.Column>
                        </Grid>
                      </Section>
                    </React.Fragment>
                  }
                  {/* {Ad &&
                    <Section>
                      <Grid>
                        <Grid.Column grid='7'>
                          <Field
                            fluid
                            required
                            // error
                            ui='blockLabelVertical'
                            label='Length * can be in 1 demical'
                            placeholder='Insert length'
                            fieldIcon={
                              <Text
                                regular14
                                color={VARIABLES.COLORS.BLACK}
                              >
                                {productType ? 'per sticks' : 'per sheets'}
                              </Text>
                            }
                            fieldIconSpacingRight='25'
                            onChange={() => {}}
                          />
                        </Grid.Column>
                      </Grid>
                    </Section>
                  } */}
                </Grid.Column>
              </Grid>
            </Section>
            <Section>
              <Grid
                gutter='15'
                justify='flex-end'
              >
                <Grid.Column>
                  <Button
                    gray
                    ui='back'
                    name='Back'
                    width={VARIABLES.BUTTON.WIDTHS.W_155}
                    onClick={this.profileMappingProductManage.goBack}
                  />
                </Grid.Column>
                <Grid.Column>
                  <Button
                    blue
                    name='SAVE'
                    disabled={disableAll}
                    width={VARIABLES.BUTTON.WIDTHS.W_155}
                    onClick={() => {
                      this.onSave()
                    }}
                  />
                </Grid.Column>
              </Grid>
            </Section>
          </ShapeContainer>
        </Section>
      </React.Fragment>
    )
  }
}
