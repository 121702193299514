import { displayWithPrefix, displayDate, displayUnit } from "../helpers/functions/display";
import { getPhoneRemovePrefix, getCountryPhone } from "../helpers/functions/data";
import { DATE_FORMAT } from "../helpers/constants/data";

export class ModelAdRf {
  constructor(props = {}) {
    this.id = props.id || '';
    this.sfid = props.sfid || '';
    this.idWithPrefix = displayWithPrefix(props.id, 6);
    this.adRfName = props.ad_rf_name || '';
    this.email = props.email || '';
    this.type = props.role || '';
    this.phoneNo = getPhoneRemovePrefix(props.phone_no);
    this.phoneCountry = getCountryPhone(props.phone);
    this.province = props.province || '';
    this.dealerCode = props.dealer_code || ''
  }
}

export class ModelDetailAdRf {
  constructor(props = {}) {
    console.log('props', props);
    this.adRfName = props.ad_rf_name || '';
    this.image = props.image;
    this.type = props.role;
    this.phoneNo = getPhoneRemovePrefix(props.phone_no);
    // this.lastTran = new ModelLastTransfer(props.last_transfer)
  }
}

export class ModelLastTransfer {
  constructor(props = {}) {
    this.orderDate = props.date;
    this.displayCreatedDate = displayDate(props.date, '/', DATE_FORMAT);
    this.orderNo = props.order_no;
    this.coilID = props.coil_id;
    this.coilName = props.coil_name;
    this.tranAmount = props.tran_amount;
    this.weight = props.weight;
    this.transferName = props.transfer_name;
    this.profileType = props.profile_type;
    this.productGrpName = props.product_group_name;
    this.quantity = props.quantity;
    this.length = props.length;
    this.unit = displayUnit(this.profileType)
  }
}

export class ModelTransferHistory {
  constructor(props = {}) {
    //history
    this.createdDate = props.created_date;
    this.displayCreatedDate = displayDate(props.created_date, '/', DATE_FORMAT);
    this.orderNo = props.order_no;
    this.coilID = props.coil_id;
    this.coilName = props.coil_name;
    this.companyName = props.company_name;
    this.phoneNo = getPhoneRemovePrefix(props.phone_no) || '';
    this.phoneCountry = getCountryPhone(props.phone) || '';
    this.tranAmount = props.trans_amount;
    this.weight = props.weigth;
    this.profileType = props.profile_type__c;
    this.profileQty = props.profile_quantity__c;
    this.profileLen = props.profile_length__c;
    this.profileUnit = displayUnit(this.profileType)
    this.profileTransID = props.profile_trans_id;

    //search history
    this.date = props.date;
    this.transferFrom = props.transfer_from;
    this.transferTo = props.transfer_to;
    this.length = props.length;
    this.status = props.status__c;
    this.displayDate = displayDate(this.date, '/', DATE_FORMAT);
  }
}

export class ModelInventory {
  constructor(props = {}) {
    this.id = props.id;
    this.coilID = props.coil_id;
    this.coilName = props.coil_name;
    this.productGrp = props.product_group;
    this.thickness = props.thickness;
    this.color = props.color;
    this.aging = props.aging;
    this.lenBalance = props.len_balance;
    this.reWeigth = props.re_weigth;
    this.salesTranID = props.sales_tran_id;
    this.orderNo = props.order_no;
  }
}

export class ModelTransferCheckCoil {
  constructor(props = {}) {
    this.id = props.coil_id;
    this.coilId = props.coil_id;
    this.coilName = props.coil_name;
    this.lenBalance = props.len_balance;
    this.salesTranId = props.sales_tran_id;
    this.fromDealer = props.from_dealer;
    this.dealerName = props.dealer_name;
    this.messageError = props.messageError;
  }
}


export class ModelCoilApprove {
  constructor(props = {}) {
    this.id = props.uid__c;
    this.uid = props.uid__c;
    this.date = props.date;
    this.coilName = props.coil_name;
    this.coilId = props.coil_id;
    this.transferFrom = props.transfer_from;
    this.transferTo = props.transfer_to;
    this.length = props.length;
    this.weight = props.weigth;
    this.transferStatus = props.transfer_status;
    this.thickness = props.thickness;
    this.color = props.color || '-';
    this.aging = props.aging || '-';
    this.lengthBalance = props.length_balance;
    
    this.displayDate = displayDate(this.date, '/', DATE_FORMAT);
    this.isPending = this.transferStatus === 'Pending';
    this.isApproved = this.transferStatus === 'Approved';
    this.isCleared = this.transferStatus === 'Cleared';
  }
}
