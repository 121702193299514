import { displayWithPrefix, displayWithComma, displayDate } from "../helpers/functions/display";

export class ModelPoint {
  constructor(props = {}) {
    this.id = props.id;
    this.idWithPrefix = displayWithPrefix(props.id);
    this.orderNo = props.order_no;
    this.name = props.name;
    this.coilName = props.coil_name;
    this.coilId = props.coil_id;
    this.orderM = parseInt(props.order_m);
    this.orderMWithComma = displayWithComma(parseInt(props.order_m));
    this.points = props.points;
    this.pointsWithComma = displayWithComma(props.points);
    this.salesPersonId = props.sales_person_id;
    this.salesPersonName = props.sales_person_name;
    this.customerId = props.customer_id;
    this.customerName = props.customer_name;
    this.createAt = props.create_at;
    this.displayCreateAt = displayDate(props.create_at);
    this.phoneNo = props.phone_no;
    this.pointsOriginal = props.points_original;
    this.pointsOriginalWithComma = displayWithComma(this.pointsOriginal);
  }
}