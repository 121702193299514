import { displayWithComma, displayWithPrefix, displayDate, displayDateTime } from "../helpers/functions/display";
import { getPhoneRemovePrefix, getCountryPhone } from "../helpers/functions/data";
import { DATE_FORMAT } from "../helpers/constants/data";

export class ModelProfile {
  constructor(props = {}) {
    this.id = props.id || '';
    this.sfid = props.sfid || '';
    this.idWithPrefix = displayWithPrefix(props.id, 6);
    this.profileName = props.name || '';
    this.email = props.email || '';
    this.phoneNo = getPhoneRemovePrefix(props.phone);
    this.phoneCountry = getCountryPhone(props.phone);
  }
}

// export class ModelDetailAdRf {
//   constructor(props = {}) {
//     console.log('props', props);
//     this.adRfName = props.ad_rf_name || '';
//     this.image = props.image;
//     this.type = props.role;
//     this.phoneNo = getPhoneRemovePrefix(props.phone_no);
//     // this.lastTran = new ModelLastTransfer(props.last_transfer)
//   }
// }

// export class ModelLastTransfer {
//   constructor(props = {}) {
//     this.orderDate = props.date;
//     this.displayCreatedDate = displayDate(props.date, '/', DATE_FORMAT);
//     this.orderNo = props.order_no;
//     this.coilID = props.coil_id;
//     this.coilName = props.coil_name;
//     this.tranAmount = props.tran_amount;
//     this.weight = props.weight;
//     this.transferName = props.transfer_name;
//   }
// }

// export class ModelTransferHistory {
//   constructor(props = {}) {
//     this.createdDate = props.created_date;
//     this.displayCreatedDate = displayDate(props.created_date, '/', DATE_FORMAT);
//     this.orderNo = props.order_no;
//     this.coilID = props.coil_id;
//     this.coilName = props.coil_name;
//     this.companyName = props.company_name;
//     this.phoneNo = getPhoneRemovePrefix(props.phone_no) || '';
//     this.phoneCountry = getCountryPhone(props.phone) || '';
//     this.tranAmount = props.trans_amount;
//     this.weigth = props.weigth;
//   }
// }

// export class ModelInventory {
//   constructor(props = {}) {
//     this.id = props.id;
//     this.coilID = props.coil_id;
//     this.coilName = props.coil_name;
//     this.productGrp = props.product_group;
//     this.thickness = props.thickness;
//     this.color = props.color;
//     this.aging = props.aging;
//     this.lenBalance = props.len_balance;
//     this.reWeigth = props.re_weigth;
//     this.salesTranID = props.sales_tran_id;
//     this.orderNo = props.order_no;
//   }
// }