import styled, {
  // css
} from 'styled-components'
import {
  default as VARIABLES
} from './../../../themes/styles/bases/variables'
import {
  default as TYPOGRAPHYS
} from './../../../themes/styles/bases/typographys'
// import {
//   default as MIXINS
// } from './../../../themes/styles/helpers/mixins'

// Sample using image in style
// url(${require('./../../../themes/images/icons/icon-sample.svg')});

// Wrapper
// ============================================================
export const ThumbnailIndexWrapper = styled.div`
  /* Parent
  ------------------------------- */
  transition: ${VARIABLES.TRANSITIONS.DEFAULT};
  cursor: pointer;
  width: 132px;
  height: 132px;
  border: 2px solid ${VARIABLES.COLORS.BLUE_22};
  border-radius: 6px;
  text-align: center;

  &:hover {
    background-color: ${VARIABLES.COLORS.BLUE_22};

    .thumbnail-index-text {
      color: ${VARIABLES.COLORS.WHITE};
    }

    .thumbnail-index-icon {
      &.is-arrow {
        display: none;
      }

      &.is-arrow-hover {
        display: inline;
      }
    }
  }

  /* Childrens
  ------------------------------- */
  .thumbnail-index-text {
    transition: ${VARIABLES.TRANSITIONS.DEFAULT};
    color: ${VARIABLES.COLORS.BLUE_22};

    &.is-name {
      ${TYPOGRAPHYS.FIRST.BOLD.FS_18};
      display: flex;
      justify-content: center;
      align-items: center;
      height: 95px;
      padding: 5px;
    }

    &.is-link {
      ${TYPOGRAPHYS.FIRST.REGULAR.FS_12};
    }
  }

  .thumbnail-index-icon {
    display: inline-block;
    margin-left: 7px;

    &.is-arrow {
      display: inline;
    }

    &.is-arrow-hover {
      display: none;
    }
  }

  /* Modifiers
  ------------------------------- */
  /* Media queries
  ------------------------------- */
`
