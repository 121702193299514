import apiService from '../apiService'

export const documentaryController = (configService = {}) => {
  const service = apiService(configService)
  return {
    getM3DocumentaryList: (params) => {
      return service.get(`/documentary/list`)
    },
    createDocumentary: (params) => {
      return service.post(`/documentary`, params)
    },
    searchDocumentary: (params) => {
      return service.post(`/documentary/search`, params)
    },
    documentaryDetail: (params) => {
      return service.post(`/documentary/detail`, params)
    },
    documentaryDraft: (params) => {
      return service.post(`/documentary/draft`, params)
    },
    documentaryUpdateDraft: (params) => {
      return service.put(`/documentary/draft`, params)
    },
    documentaryGenWrtDocNo: (params) => {
      return service.post(`/documentary/wrt-doc-no`, params)
    },
    // documentaryMSSearch: (params) => {
    //   return service.post(`/documentary/ms/search`, params)
    // },
  }
}
