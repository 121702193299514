import React from 'react';
// import {
//   connect,
// } from 'react-redux';
import { getLanguage } from '../../../helpers/functions/language';
import {
  Button,
  Field, Modal, Section, Table, Text
} from './../../../components';
import {
  ICONS, VARIABLES
} from './../../../themes';
// import { MODAL_TYPE } from '../../../helpers/constants/main'
// import { MAIN_REDUX_STATE_KEY } from '../../../actions/mainAction'
import _ from 'lodash';

export class ModalM3ProductTemplateInformationContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filterValue: '',
      type: '',
      sortGroup: {
        number: {
          iconASC: true,
          sortName: 'number',
        },
        code: {
          iconASC: true,
          sortName: 'code',
        },
        name: {
          iconASC: true,
          sortName: 'name',
        },
        color: {
          iconASC: true,
          sortName: 'color',
        },
      },
      currentSort: 'number',
      dataList: _.orderBy(props.dataList, ['value'], ['asc']).map((e, i) => {
        e.number = i + 1;
        return e;
      }),
    }
  }

  m3ProductGroup = {
    getDataList: () => {
      const {
        filterValue,
        dataList,
      } = this.state;
      return dataList.filter((e, i) => e.value.toLocaleLowerCase().indexOf(filterValue.toLocaleLowerCase()) >= 0)
    },
    onClickSort: (target) => {
      const {
        sortGroup,
        dataList,
      } = this.state;
      this.setState({
        currentSort: target,
        sortGroup,
        dataList: _.orderBy(dataList, [target], sortGroup[target].iconASC ? 'asc' : 'desc')
      })
    },
  }

  input = {
    onChangeInput: (ev) => {
      const name = ev.target.name;
      const value = ev.target.value;
      this.setState({
        [name]: value,
      })
    },
  }

  render() {
    const {
      isOpenModal,
      isSecondModal,
      onClickClose,
      templateName,
      isBreaklineTitle,
      isM3ProductColor,
      isM3ProductGroup,
      isM3ProductCode,
    } = this.props;
    const {
      filterValue,
      sortGroup,
      dataList,
    } = this.state;

    return (
      <Modal
        open={isOpenModal}
        scroll
        second={isSecondModal}
        // enableOverlayClose={false}
        width={VARIABLES.MODAL.WIDTHS.W_530}
        overlayColor={VARIABLES.COLORS.OVERLAY_BLACK_1}
        spacing={VARIABLES.MODAL.CONTAINER.C_1.SPACING}
        transition={VARIABLES.TRANSITIONS.DEFAULT}
        transformStart={VARIABLES.MODAL.CONTAINER.C_1.TRANSFORM_START}
        transformEnd={VARIABLES.MODAL.CONTAINER.C_1.TRANSFORM_END}
        onClickClose={onClickClose}
      >
        <Modal.Section
          paddingVertical='25'
          paddingHorizontal='35'
          bgColor={VARIABLES.COLORS.WHITE}
          borderBottomWidth='1'
          borderBottomStyle='solid'
          borderBottomColor={VARIABLES.COLORS.GRAY_8}
          borderTopRightRadius={VARIABLES.MODAL.HEADER.H_1.BORDER_TOP_RIGHT_RADIUS}
          borderTopLeftRadius={VARIABLES.MODAL.HEADER.H_1.BORDER_TOP_LEFT_RADIUS}
        >
          <Text bold20 color={VARIABLES.COLORS.BLACK}>
            {isM3ProductCode ?
              getLanguage('container.warrantyTemplate.modal.title.m3ProductCode') : isM3ProductGroup ?
                getLanguage('container.warrantyTemplate.modal.title.m3ProductGroup') :
                getLanguage('container.warrantyTemplate.modal.title.m3ProductColor')
            } :
          </Text>&nbsp;
          {isBreaklineTitle ?
            <Section paddingTop='5'>
              <Text regular20 color={VARIABLES.COLORS.PRIMARY_2}> {templateName}</Text>
            </Section>
            :
            <Text regular20 color={VARIABLES.COLORS.PRIMARY_2}> {templateName}</Text>
          }
        </Modal.Section>
        <Modal.Section
          paddingVertical='40'
          paddingHorizontal='35'
          bgColor={VARIABLES.COLORS.WHITE}
        >
          <Section spacingBottom='20'>
            <Field
              ui='blockLabelVerticalSearch'
              fluid
              border
              name='filterValue'
              placeholder={isM3ProductCode ?
                getLanguage('container.warrantyTemplate.placeholder.searchM3ProductCode') : isM3ProductGroup ?
                  getLanguage('container.warrantyTemplate.placeholder.searchM3ProductGroup') :
                  getLanguage('container.warrantyTemplate.placeholder.searchM3ProductColor')
              }
              value={filterValue}
              onChange={this.input.onChangeInput}
            />
          </Section>
          <Table striped>
            <Table.Section>
              <Table.Row>
                <Table.HeadColumn
                  noSort
                  maxWidth='50'
                  minWidth='50'
                >
                  {getLanguage('container.warrantyTemplate.table.header.no')}
                </Table.HeadColumn>
                <Table.HeadColumn noSort>
                  {isM3ProductCode ?
                    getLanguage('container.warrantyTemplate.table.header.m3ProductCode') : isM3ProductGroup ?
                      getLanguage('container.warrantyTemplate.table.header.m3ProductGroup') :
                      getLanguage('container.warrantyTemplate.table.header.m3ProductColor')
                  }
                </Table.HeadColumn>
              </Table.Row>
            </Table.Section>
          </Table>
          {this.m3ProductGroup.getDataList().length ?
            <Section scrollOverlay height='315'>
              <Table striped>
                <Table.Section>
                  {this.m3ProductGroup.getDataList().map((e, i) => {
                    return (
                      <Table.Row key={i}>
                        <Table.BodyColumn
                          info
                          maxWidth='50'
                          minWidth='50'
                        >
                          {e.number}
                        </Table.BodyColumn>
                        <Table.BodyColumn info>
                          {e.value}
                        </Table.BodyColumn>
                      </Table.Row>
                    )
                  })}
                </Table.Section>
              </Table>
            </Section>
            :
            <Section direction='column' align='center' height='315' paddingVertical='60'>
              <Section justify='center' spacingBottom='10'>
                <img src={ICONS['ic-coil-gray.svg']} alt='Icon' />
              </Section>
              <Text regular14 color={VARIABLES.COLORS.GRAY_12}>
                {isM3ProductCode ?
                  getLanguage('container.warrantyTemplate.noM3ProductCode') : isM3ProductGroup ?
                    getLanguage('container.warrantyTemplate.noM3ProductGroup') :
                    getLanguage('container.warrantyTemplate.noM3ProductColor')
                }
              </Text>
            </Section>
          }
        </Modal.Section>
        <Modal.Section
          paddingTop='15'
          paddingBottom='20'
          paddingHorizontal='35'
          borderTopWidth={VARIABLES.MODAL.FOOTER.F_1.BORDER_TOP_WIDTH}
          borderTopStyle={VARIABLES.MODAL.FOOTER.F_1.BORDER_TOP_STYLE}
          borderTopColor={VARIABLES.COLORS.GRAY_8}
          borderBottomRightRadius={VARIABLES.MODAL.FOOTER.F_1.BORDER_BOTTOM_RIGHT_RADIUS}
          borderBottomLeftRadius={VARIABLES.MODAL.FOOTER.F_1.BORDER_BOTTOM_LEFT_RADIUS}
        >
          <Section justify='flex-end'>
            <Button
              gray
              width={VARIABLES.BUTTON.WIDTHS.W_135}
              name='Close'
              onClick={onClickClose}
            />
          </Section>
        </Modal.Section>
      </Modal>
    )
  }
}