import { isValidResponse } from "../functions/validation";
import { storeDispatch, storeGetState } from "../../stores";
import { dataCreators, DATA_REDUX_STATE_KEY } from "../../actions/dataAction";
import { productController } from "../../apiService/apiController/productService";
import { ModelProduct, ModelProductQuantityList } from "../../models/ModelProduct";
import { ENUM } from "../constants/enum";
import { handleSortParams, handleTrimParams } from "../functions/data";
import { getUserAuthRedux } from "../functions/auth";

export const getProductList = async (params = {}) => {
  const userAuth = getUserAuthRedux();
  let currentParams = storeGetState().dataRedux.productParams;
  let newParams = handleTrimParams({
    ...currentParams,
    ...params,
  });
  if (!newParams.sort) {
    newParams.sort = 'id desc';
  }
  else {
    newParams = handleSortParams(currentParams, newParams);
  }
  const productService = productController();
  let res;
  if (
    userAuth.addressType === ENUM.COUNTRY_TYPE.INDONESIA && (
      userAuth.customerRoleType === ENUM.CUSTOMER_ROLE_TYPE.INDONESIA.TOKO ||
      userAuth.customerRoleType === ENUM.CUSTOMER_ROLE_TYPE.INDONESIA.DISTRIBUTOR
    )
  ) {
    res = await productService.getTokoProductT1(newParams);
  }
  else {
    res = await productService.getProductT1(newParams);
  }
  if (isValidResponse(res)) {
    let dataList = res.product_groups.map(e => new ModelProduct(e));
    if (params.productList.length) {
      dataList = dataList.map(e => {
        params.productList.forEach(ep => {
          if (ep.id === e.id) {
            e.orderValue = ep.orderValue;
            e.revenueValue = ep.revenueValue;
          }
        })
        return e
      })
    }
    newParams.activePage = newParams.page;
    newParams.totalData = res.total_items;
    console.log('dataList', res.product_groups, dataList, newParams);
    storeDispatch(dataCreators.setDataRedux(DATA_REDUX_STATE_KEY.PRODUCT_LIST, dataList));
    storeDispatch(dataCreators.setDataRedux(DATA_REDUX_STATE_KEY.PRODUCT_PARAMS, newParams));
    return dataList;
  }
  else {
    return [];
  }
}

export const getProductQuantityList = async (params = {}, configService = {}) => {
  let currentParams = storeGetState().dataRedux.productParams;
  const userAuth = getUserAuthRedux();
  let newParams = handleTrimParams({
    ...currentParams,
    ...params,
  });
  const productService = productController(configService);
  let res;
  if (
    userAuth.addressType === ENUM.COUNTRY_TYPE.INDONESIA && (
      userAuth.customerRoleType === ENUM.CUSTOMER_ROLE_TYPE.INDONESIA.TOKO ||
      userAuth.customerRoleType === ENUM.CUSTOMER_ROLE_TYPE.INDONESIA.DISTRIBUTOR
    )
  ) {
    res = await productService.getTokoProductT1(newParams);
  }
  else {
    res = await productService.getProductT1(newParams);
  }
  if (isValidResponse(res)) {
    let dataList = new ModelProductQuantityList(res.product_groups).productGroupList;
    let dataNoLengthList = (res.product_groups_no_length || []).map(e => new ModelProduct(e));
    newParams.activePage = newParams.page;
    newParams.totalData = res.total_items;
    console.log('dataList', res.product_groups, dataList, newParams);
    storeDispatch(dataCreators.setDataRedux(DATA_REDUX_STATE_KEY.PRODUCT_QUANTITY_LIST, dataList));
    storeDispatch(dataCreators.setDataRedux(DATA_REDUX_STATE_KEY.PRODUCT_QUANTITY_NO_LENGTH_LIST, dataNoLengthList));
    storeDispatch(dataCreators.setDataRedux(DATA_REDUX_STATE_KEY.PRODUCT_QUANTITY_LIST_PARAMS, newParams));

    let dataProductList = res.product_groups.map(e => new ModelProduct(e));
    if (params.productList && params.productList.length) {
      dataList = dataList.map(e => {
        params.productList.forEach(ep => {
          if (ep.id === e.id) {
            e.orderValue = ep.orderValue;
          }
        })
        return e
      })
    }
    storeDispatch(dataCreators.setDataRedux(DATA_REDUX_STATE_KEY.PRODUCT_LIST, dataProductList));
  }
}