import React from 'react';
import {
  ShapeContainer,
  Section,
  Grid,
  Button,
  Table,
  PaginationControl,
  TableAction,
  Text,
} from './../../components'
import { redirectData } from '../../helpers/functions/route';
import { ROUTE_PATH } from '../../helpers/constants/route';
import { VARIABLES, ICONS } from '../../themes';
import { getSortAscending } from '../../helpers/functions/data';
import { getLanguage } from '../../helpers/functions/language';
import _ from 'lodash';

export class CustomerPointsTableContainer extends React.Component {

  render() {
    const {
      dataList,
      dataParams,
      totalData,
      activePage,
      dataPerPage,
      onClickSort,
      onPaginationChange,
    } = this.props;
    return (
      <ShapeContainer
        ui='content'
        justify='space-between'
      >
        <Section spacingBottom='45'>
          <Table
            striped
            action
          >
            <Table.Section>
              <Table.Row>
                <Table.HeadColumn
                  maxWidth={VARIABLES.TABLE.COLUMN_WIDTHS.IDS.INDEX}
                  minWidth={VARIABLES.TABLE.COLUMN_WIDTHS.IDS.INDEX}
                  sortAscending={getSortAscending(dataParams, 'id')}
                  onClickSort={() => {
                    onClickSort('id desc');
                  }}
                >
                  {getLanguage('container.customerPointTable.table.header.id', '')}
                </Table.HeadColumn>
                <Table.HeadColumn
                  minWidth={VARIABLES.TABLE.COLUMN_WIDTHS.NAMES.FULL}
                  sortAscending={getSortAscending(dataParams, 'first_name')}
                  onClickSort={() => {
                    onClickSort('first_name desc');
                  }}
                >
                  {getLanguage('container.customerPointTable.table.header.customerName', '')}
                </Table.HeadColumn>
                <Table.HeadColumn
                  minWidth={VARIABLES.TABLE.COLUMN_WIDTHS.PHONE}
                  sortAscending={getSortAscending(dataParams, 'phone')}
                  onClickSort={() => {
                    onClickSort('phone desc');
                  }}
                >
                  {getLanguage('container.customerPointTable.table.header.phoneNo', '')}
                </Table.HeadColumn>
                <Table.HeadColumn
                  minWidth={VARIABLES.TABLE.COLUMN_WIDTHS.EMAIL}
                  sortAscending={getSortAscending(dataParams, 'email')}
                  onClickSort={() => {
                    onClickSort('email desc');
                  }}
                >
                  {getLanguage('container.customerPointTable.table.header.email', '')}
                </Table.HeadColumn>
                <Table.HeadColumn
                  alignRight
                  minWidth={VARIABLES.TABLE.COLUMN_WIDTHS.POINT}
                  sortAscending={getSortAscending(dataParams, 'remain_point')}
                  onClickSort={() => {
                    onClickSort('remain_point desc');
                  }}
                >
                  {getLanguage('container.customerPointTable.table.header.tbPoints', '')}
                </Table.HeadColumn>
                <Table.HeadColumn
                  noSort
                  alignCenter
                  maxWidth={VARIABLES.TABLE.COLUMN_WIDTHS.ACTIONS.ACT_10}
                  minWidth={VARIABLES.TABLE.COLUMN_WIDTHS.ACTIONS.ACT_10}
                >
                  {getLanguage('container.customerPointTable.table.header.action', '')}
                </Table.HeadColumn>
              </Table.Row>
            </Table.Section>
            <Table.Section>
              {
                dataList.map((e, i) => {
                  return (
                    <Table.Row
                      key={i}
                    >
                      <Table.BodyColumn
                        info
                        maxWidth={VARIABLES.TABLE.COLUMN_WIDTHS.IDS.INDEX}
                        minWidth={VARIABLES.TABLE.COLUMN_WIDTHS.IDS.INDEX}
                        title={e.idWithPrefix}
                      >
                        {e.idWithPrefix}
                      </Table.BodyColumn>
                      <Table.BodyColumn
                        info
                        minWidth={VARIABLES.TABLE.COLUMN_WIDTHS.NAMES.FULL}
                        title={e.fullname}
                      >
                        {e.fullname}
                      </Table.BodyColumn>
                      <Table.BodyColumn
                        phone
                        minWidth={VARIABLES.TABLE.COLUMN_WIDTHS.NAMES.FULL}
                        idPhone={e.phoneCountry.value}
                        srcFlag={e.phoneCountry.flag}
                      >
                        {e.phone}
                      </Table.BodyColumn>
                      <Table.BodyColumn
                        link
                        minWidth={VARIABLES.TABLE.COLUMN_WIDTHS.EMAIL}
                        title={e.email}
                      >
                        {e.email}
                      </Table.BodyColumn>
                      <Table.BodyColumn
                        info
                        alignRight
                        minWidth={VARIABLES.TABLE.COLUMN_WIDTHS.POINT}
                        title={e.remainPointsWithComma}
                      >
                        {e.remainPointsWithComma}
                      </Table.BodyColumn>
                      <Table.BodyColumn
                        action
                        alignCenter
                        maxWidth={VARIABLES.TABLE.COLUMN_WIDTHS.ACTIONS.ACT_10}
                        minWidth={VARIABLES.TABLE.COLUMN_WIDTHS.ACTIONS.ACT_10}
                      >
                        <Button
                          lightBlue
                          ui='frontIconSmall'
                          name={getLanguage('container.customerPointTable.table.action.buttonViewDetail', '')}
                          iconWidth='10'
                          iconHeight='10'
                          iconSrc={ICONS['ic-detail.svg']}
                          onClick={() => {
                            redirectData(`${ROUTE_PATH.POINTS_DETAIL_MANAGEMENT.LINK}/${e.id}`, {
                              data: e
                            })
                          }}
                        />
                      </Table.BodyColumn>
                    </Table.Row>
                  )
                })
              }
            </Table.Section>
          </Table>
        </Section>
        <TableAction>
          <TableAction.Left>
            {/* <Grid gutter='15'>
              <Grid.Column>
                <Button
                  blue
                  ui='frontIcon'
                  name='Print'
                  width={VARIABLES.BUTTON.WIDTHS.W_125}
                  iconWidth='18'
                  iconHeight='18'
                  iconSrc={ICONS['ic-printer.svg']}
                  onClick={() => { }}
                />
              </Grid.Column>
              <Grid.Column>
                <Button
                  green
                  ui='frontIcon'
                  name='Export'
                  width={VARIABLES.BUTTON.WIDTHS.W_125}
                  iconWidth='16'
                  iconHeight='16'
                  iconSrc={ICONS['ic-export.svg']}
                  onClick={() => { }}
                />
              </Grid.Column>
            </Grid> */}
          </TableAction.Left>
          <TableAction.Right>
            <Grid gutter='20'>
              <Grid.Column>
                <Section paddingTop='6'>
                  <Text
                    regular12
                    color={VARIABLES.COLORS.GRAY_16}
                  >
                    {getLanguage('pagination.page', '')} {activePage}/{Math.ceil(totalData / dataPerPage)}
                  </Text>
                </Section>
              </Grid.Column>
              <Grid.Column>
                <PaginationControl
                  activePage={activePage}
                  itemsCountPerPage={dataPerPage}
                  totalItemsCount={totalData}
                  pageRangeDisplayed={3}
                  onChange={onPaginationChange}
                />
              </Grid.Column>
            </Grid>
          </TableAction.Right>
        </TableAction>
      </ShapeContainer>
    )
  }
}