import React from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import {
  connect,
} from 'react-redux';
import {
  ShapeContainer,
  Section,
  Grid,
  Button,
  Table,
  TableAction,
  Image,
  Field,
  TitlePage,
  SelectControl,
  PaginationControl,
  Text
} from './../../components'
import { VARIABLES, ICONS } from '../../themes';
import { getProductList } from '../../helpers/services/product';
import { MAIN_REDUX_STATE_KEY } from '../../actions/mainAction';
import { bindActionCreators } from 'redux';
import { dataCreators, DATA_REDUX_STATE_KEY } from '../../actions/dataAction';
import { handleSortData } from '../../helpers/functions/data';
import { getLanguage } from '../../helpers/functions/language';
import _ from 'lodash';
import { toast } from '../../helpers/functions/main';
import { getUserAuthRedux } from '../../helpers/functions/auth';
import { ENUM } from '../../helpers/constants/enum';

class OrderProductVietnamListContainer extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
    }
  }

  componentDidMount() {
  }


  productList = this.props.productList;
  input = this.props.input;

  render() {
    const {
      sortIcon,
      dataList
    } = this.props;
    return (
      <Table
        striped
        fieldText
      >
        <Table.Section>
          <Table.Row>
            <Table.HeadColumn
              noSort
              maxWidth={VARIABLES.TABLE.INFO_WIDTHS.CHECKBOX_CIRCLE}
              minWidth={VARIABLES.TABLE.INFO_WIDTHS.CHECKBOX_CIRCLE}
            >
              &nbsp;
            </Table.HeadColumn>
            <Table.HeadColumn
              maxWidth={VARIABLES.TABLE.INFO_WIDTHS.COIL_ID}
              minWidth={VARIABLES.TABLE.INFO_WIDTHS.COIL_ID}
              sortAscending={sortIcon.lotNumber}
              onClickSort={() => {
                this.productList.onClickSort('lotNumber')
              }}
            >
              {getLanguage('container.orderProduct.table.header.coilID', '')}
            </Table.HeadColumn>
            <Table.HeadColumn
              minWidth={VARIABLES.TABLE.INFO_WIDTHS.COIL_NAME}
              sortAscending={sortIcon.itemDescription}
              onClickSort={() => {
                this.productList.onClickSort('itemDescription');
              }}
            >
              {getLanguage('container.orderProduct.table.header.coilName', '')}
            </Table.HeadColumn>
            <Table.HeadColumn
              alignRight
              maxWidth={VARIABLES.TABLE.INFO_WIDTHS.THICKNESS_APT_TCT}
              minWidth={VARIABLES.TABLE.INFO_WIDTHS.THICKNESS_APT_TCT}
              sortAscending={sortIcon.thicknessMM}
              onClickSort={() => {
                this.productList.onClickSort('thicknessMM');
              }}
            >
              {getLanguage('container.orderProduct.table.header.thicknessVt', '')}
            </Table.HeadColumn>
            <Table.HeadColumn
              alignCenter
              maxWidth={VARIABLES.TABLE.INFO_WIDTHS.COLOR}
              minWidth={VARIABLES.TABLE.INFO_WIDTHS.COLOR}
              sortAscending={sortIcon.topPaintColor}
              onClickSort={() => {
                this.productList.onClickSort('topPaintColor');
              }}
            >
              {getLanguage('container.orderProduct.table.header.color', '')}
            </Table.HeadColumn>
            <Table.HeadColumn
              alignRight
              maxWidth={VARIABLES.TABLE.INFO_WIDTHS.AGING}
              minWidth={VARIABLES.TABLE.INFO_WIDTHS.AGING}
              sortAscending={sortIcon.fAging}
              onClickSort={() => {
                this.productList.onClickSort('fAging');
              }}
            >
              {getLanguage('container.orderProduct.table.header.aging', '')}
            </Table.HeadColumn>
            <Table.HeadColumn
              alignRight
              maxWidth={VARIABLES.TABLE.INFO_WIDTHS.LENGTH_M}
              minWidth={VARIABLES.TABLE.INFO_WIDTHS.LENGTH_M}
              sortAscending={sortIcon.fTotalRemaininglength}
              onClickSort={() => {
                this.productList.onClickSort('fTotalRemaininglength');
              }}
            >
              {getLanguage('container.orderProduct.table.header.length', '')}
            </Table.HeadColumn>
            <Table.HeadColumn
              alignRight
              maxWidth={VARIABLES.TABLE.INFO_WIDTHS.WEIGHT_KG}
              minWidth={VARIABLES.TABLE.INFO_WIDTHS.WEIGHT_KG}
              sortAscending={sortIcon.fRemainingweight}
              onClickSort={() => {
                this.productList.onClickSort('fRemainingweight');
              }}
            >
              {getLanguage('container.orderProduct.table.header.weight', '')}
            </Table.HeadColumn>
            <Table.HeadColumn
              alignRight
              maxWidth={VARIABLES.TABLE.INFO_WIDTHS.INPUT_ORDER}
              minWidth={VARIABLES.TABLE.INFO_WIDTHS.INPUT_ORDER}
              noSort
            >
              {getLanguage('container.orderProduct.table.header.orders', '')}
            </Table.HeadColumn>
          </Table.Row>
        </Table.Section>
        <Section calcHeight="calc(100vh - 454px)">
          <Table.Section>
            {
              dataList.map((e, i) => {
                return (
                  <Table.Row
                    key={i}
                  >
                    <Table.BodyColumn
                      info
                      maxWidth={VARIABLES.TABLE.INFO_WIDTHS.CHECKBOX_CIRCLE}
                      minWidth={VARIABLES.TABLE.INFO_WIDTHS.CHECKBOX_CIRCLE}
                    >
                      <Image ui={e.orderValue > 0 ? 'iconSelectedActive' : 'iconSelectedInactive'} />
                    </Table.BodyColumn>
                    <Table.BodyColumn
                      info
                      maxWidth={VARIABLES.TABLE.INFO_WIDTHS.COIL_ID}
                      minWidth={VARIABLES.TABLE.INFO_WIDTHS.COIL_ID}
                      title={e.lotNumber}
                    >
                      {e.lotNumber}
                    </Table.BodyColumn>
                    <Table.BodyColumn
                      info
                      minWidth={VARIABLES.TABLE.INFO_WIDTHS.COIL_NAME}
                      title={e.itemDescription}
                    >
                      {e.itemDescription}
                    </Table.BodyColumn>
                    <Table.BodyColumn
                      info
                      alignRight
                      maxWidth={VARIABLES.TABLE.INFO_WIDTHS.THICKNESS_APT_TCT}
                      minWidth={VARIABLES.TABLE.INFO_WIDTHS.THICKNESS_APT_TCT}
                      title={e.thicknessMM}
                    >
                      {e.thicknessMM}
                    </Table.BodyColumn>
                    <Table.BodyColumn
                      info
                      alignCenter
                      maxWidth={VARIABLES.TABLE.INFO_WIDTHS.COLOR}
                      minWidth={VARIABLES.TABLE.INFO_WIDTHS.COLOR}
                      title={e.topPaintColor}
                    >
                      {e.topPaintColor}
                    </Table.BodyColumn>
                    <Table.BodyColumn
                      info
                      alignRight
                      maxWidth={VARIABLES.TABLE.INFO_WIDTHS.AGING}
                      minWidth={VARIABLES.TABLE.INFO_WIDTHS.AGING}
                      title={e.fAging ? e.fAging : '-'}
                    >
                      {e.fAging ? e.fAging : '-'}
                    </Table.BodyColumn>
                    <Table.BodyColumn
                      info
                      alignRight
                      maxWidth={VARIABLES.TABLE.INFO_WIDTHS.LENGTH_M}
                      minWidth={VARIABLES.TABLE.INFO_WIDTHS.LENGTH_M}
                      title={e.fTotalRemaininglengthWithComma}
                    >
                      {e.fTotalRemaininglengthWithComma}
                    </Table.BodyColumn>
                    <Table.BodyColumn
                      info
                      alignRight
                      maxWidth={VARIABLES.TABLE.INFO_WIDTHS.WEIGHT_KG}
                      minWidth={VARIABLES.TABLE.INFO_WIDTHS.WEIGHT_KG}
                      title={e.fRemainingweightWithComma}
                    >
                      {e.fRemainingweightWithComma}
                    </Table.BodyColumn>
                    <Table.BodyColumn
                      action
                      alignRight
                      maxWidth={VARIABLES.TABLE.INFO_WIDTHS.INPUT_ORDER}
                      minWidth={VARIABLES.TABLE.INFO_WIDTHS.INPUT_ORDER}
                    >
                      <Field name='orderValue'
                        ui='order'
                        value={e.orderValue ? e.orderValue : 0}
                        onChange={(ev) => {
                          this.input.onChangeDataInput(ev, e)
                        }}
                      />
                    </Table.BodyColumn>
                  </Table.Row>
                )
              })
            }
          </Table.Section>
        </Section>
      </Table>
    )
  }
}

const mapStateToProps = (state) => {
  const dataRedux = state.dataRedux;
  const mainRedux = state.mainRedux;
  return {
    // dataList: dataRedux.productList,
    // activePage: dataRedux.productParams.activePage,
    // totalData: dataRedux.productParams.totalData,
    // userAuth: mainRedux[MAIN_REDUX_STATE_KEY.USER_AUTH]
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    dataActions: bindActionCreators(dataCreators, dispatch),
  }
}

export const OrderProductVietnamListContainerConnected = connect(
  mapStateToProps,
  mapDispatchToProps
)(OrderProductVietnamListContainer)