import React from 'react'
import {
  ShapeContainer,
  Section,
  Breadcrumb,
  TitlePage,
  Grid,
  Button,
  Image,
  Text,
  Field,
  DatePickerControl,
  SelectControl,
  Table,
  TableAction,
  PaginationControl,
} from './../../components'

import {
  VARIABLES,
  ICONS,
} from './../../themes'
import { setSideMenuActive, getRouteData } from '../../helpers/functions/route';
import { ROUTE_PATH } from '../../helpers/constants/route';
import { redirect } from '../../helpers/functions/route';
import _ from 'lodash';
import { getLanguage } from '../../helpers/functions/language';
import { DATE_FORMAT, DATE_MOMENT_FORMAT, DATE_PICKER_FORMAT } from '../../helpers/constants/data';
import { ModalCoilApprovalContainer } from '../../containers/ModalContainer';
import { getClearTransferSearchApproveParams, getTransferSearchApproveList } from '../../helpers/services/transfer';
import { displayDate } from '../../helpers/functions/display';
import { CoilApproveListContainerConnected } from '../../containers/CoilApproveListContainer/CoilApproveListContainer';
import { ModalCoilApprovalDetailContainer } from '../../containers/ModalContainer';


export class CoilApprovalScreen extends React.Component {

  constructor(props) {
    super(props);
    setSideMenuActive(props);
    const categorySelectOptionList = [{
      value: '',
      label: getLanguage('coilApproval.selectOption.all', '')
    }, {
      value: 'coilID',
      label: getLanguage('coilApproval.selectOption.coilID', '')
    }, {
      value: 'coilName',
      label: getLanguage('coilApproval.selectOption.coilName', '')
    }, {
      value: 'adRFName',
      label: getLanguage('coilApproval.selectOption.adRFName', '')
    }];
    const statusSelectOptionList = [{
      value: '',
      label: getLanguage('coilApproval.selectOption.all', '')
    }, {
      value: 'pending',
      label: getLanguage('coilApproval.selectOption.pending', '')
    }, {
      value: 'approved',
      label: getLanguage('coilApproval.selectOption.approved', '')
    }, {
      value: 'cleared',
      label: getLanguage('coilApproval.selectOption.cleared', '')
    }];

    this.state = {
      searchValue: '',
      categorySelectOptionList,
      categorySelected: categorySelectOptionList[0],
      statusSelectOptionList,
      statusSelected: statusSelectOptionList[0],
      startDate: new Date(),
      endDate: new Date(),
    }
  }

  input = {
    onChangeInput: (ev) => {
      const name = ev.target.name;
      const value = ev.target.value;
      this.setState({
        [name]: value,
      })
    },
    onClickSearchText: () => {
      const {
        searchValue,
        categorySelected,
        categorySelectOptionList,
        statusSelected,
        statusSelectOptionList,
      } = this.state;
      let params = {
        ...getClearTransferSearchApproveParams(),
        page: 1,
      };
      params.text_search = searchValue;

      const categoryType = categorySelected.value;
      switch (categoryType) {
        case categorySelectOptionList[0].value:
          params.category = 'all';
          break;
        case categorySelectOptionList[1].value:
          params.category = 'coil_id';
          break;
        case categorySelectOptionList[2].value:
          params.category = 'coil_name';
          break;
        case categorySelectOptionList[3].value:
          params.category = 'ad';
          break;
        default: break;
      }

      const statusType = statusSelected.value;
      switch (statusType) {
        case statusSelectOptionList[0].value:
          params.status = 'all';
          break;
        case statusSelectOptionList[1].value:
          params.status = 'Pending';
          break;
        case statusSelectOptionList[2].value:
          params.status = 'Approved';
          break;
        case statusSelectOptionList[3].value:
          params.status = 'Cleared';
          break;
        default: break;
      }
      getTransferSearchApproveList(params);
    },
    onClickSearchDate: () => {
      const {
        startDate,
        endDate,
      } = this.state;
      let params = {
        ...getClearTransferSearchApproveParams(),
        page: 1,
        start_date: displayDate(startDate, '-', DATE_MOMENT_FORMAT),
        end_date: displayDate(endDate, '-', DATE_MOMENT_FORMAT),
      };
      getTransferSearchApproveList(params);
    },
    onChangeSearchOption: (target, selected) => {
      this.setState({
        [target]: selected
      })
    },
    onChangeDate: (date, name) => {
      this.setState({
        [name]: date
      })
    }
  }

  render() {
    const {
      searchValue,
      categorySelected,
      categorySelectOptionList,
      statusSelected,
      statusSelectOptionList,
      startDate,
      endDate,
    } = this.state

    return (
      <React.Fragment>
        <Breadcrumb>
          <Breadcrumb.List
            link
            name={getLanguage('coilApproval.breadcrumb1', '')}
            onClick={() => {
              redirect(ROUTE_PATH.HOME.LINK)
            }}
          />
          <Breadcrumb.List name='/' />
          <Breadcrumb.List
            link
            name={getLanguage('coilApproval.breadcrumb2', '')}
          // onClick={() => {
          //   redirect(ROUTE_PATH.INVENTORY_INDEXES.LINK)
          // }}
          />
          <Breadcrumb.List name='/' />
          <Breadcrumb.List name={getLanguage('coilApproval.breadcrumb3', '')} />
        </Breadcrumb>
        <Section ui='content'>
          <Section spacingBottom='35'>
            <TitlePage
              src={ICONS['ic-page-inventory.svg']}
              name={getLanguage('coilApproval.title', '')}
            />
          </Section>
          <Section spacingBottom='20'>
            <ShapeContainer
              fluid
              className='search-control'
              paddingTop='20'
              paddingBottom='24'
              paddingHorizontal='20'
              borderWidth='1'
              borderStyle='solid'
              borderColor={VARIABLES.COLORS.PRIMARY_1}
              borderRadius='10'
            >
              <Grid
                className='search-control-container'
                gutter='30'
                gutterVertical='20'
              >
                <Grid.Column
                  className='search-control-group-left'
                  flex='1'
                >
                  <Grid gutter='10'>
                    <Grid.Column flex='2'>
                      <Section paddingTop='24' >
                        <Field name='searchValue'
                          fluid
                          ui='blockLabelVerticalSearch'
                          placeholder={getLanguage('coilApproval.field.placeholderSearch', '')}
                          value={searchValue}
                          onChange={this.input.onChangeInput}
                        />
                      </Section>
                    </Grid.Column>
                    <Grid.Column flex='none'>
                      <Section zIndexLv3>
                        <SelectControl
                          verticalLabel
                          filterFluid
                          width='110'
                          label={getLanguage('coilApproval.label.category', '')}
                          isSearchable={false}
                          value={categorySelected}
                          onChange={(selected) => this.input.onChangeSearchOption('categorySelected', selected)}
                          options={categorySelectOptionList}
                        />
                      </Section>
                    </Grid.Column>
                    <Grid.Column flex='none'>
                      <Section zIndexLv3>
                        <SelectControl
                          verticalLabel
                          filterFluid
                          label={getLanguage('coilApproval.label.status', '')}
                          width='110'
                          isSearchable={false}
                          value={statusSelected}
                          onChange={(selected) => this.input.onChangeSearchOption('statusSelected', selected)}
                          options={statusSelectOptionList}
                        />
                      </Section>
                    </Grid.Column>
                    <Grid.Column flex='none'>
                      <Section paddingTop='24'>
                        <Button
                          lightBlue
                          name={getLanguage('coilApproval.button.buttonSearch', '')}
                          width={VARIABLES.BUTTON.WIDTHS.W_105}
                          onClick={this.input.onClickSearchText}
                        />
                      </Section>
                    </Grid.Column>
                  </Grid>
                </Grid.Column>
                <Grid.Column
                  className='search-control-partition'
                  flex='none'
                >
                  <Section paddingTop='24'>
                    <ShapeContainer
                      width='1'
                      height='32'
                      bgColor={VARIABLES.COLORS.PRIMARY_1}
                    />
                  </Section>
                </Grid.Column>
                <Grid.Column
                  className='search-control-group-right'
                  flex='1'
                >
                  <Section paddingTop='24'>
                    <Grid gutter='20'>
                      <Grid.Column flex='none'>
                        <Section paddingTop='6'>
                          <Text
                            regular12
                            color={VARIABLES.COLORS.BLACK}
                          >
                            {getLanguage('coilApproval.label.date', '')}
                          </Text>
                        </Section>
                      </Grid.Column>
                      <Grid.Column flex='1'>
                        <Grid gutter='10'>
                          <Grid.Column flex='1'>
                            <Grid gutter='15'>
                              <Grid.Column flex='1'>
                                <DatePickerControl
                                  filter
                                  bottom
                                  dateFormat={DATE_PICKER_FORMAT}
                                  placeholderText={getLanguage('coilApproval.field.selectStartDate', '')}
                                  selected={startDate}
                                  onChange={(date) => {
                                    this.input.onChangeDate(date, 'startDate');
                                  }}
                                />
                              </Grid.Column>
                              <Grid.Column flex='none'>
                                <Section paddingTop='6'>
                                  <Text
                                    regular12
                                    color={VARIABLES.COLORS.GRAY_19}
                                  >
                                    {getLanguage('coilApproval.label.dateTo', '')}
                                  </Text>
                                </Section>
                              </Grid.Column>
                              <Grid.Column flex='1'>
                                <DatePickerControl
                                  filter
                                  bottom
                                  dateFormat={DATE_PICKER_FORMAT}
                                  placeholderText={getLanguage('coilApproval.field.selectEndDate', '')}
                                  selected={endDate}
                                  onChange={(date) => {
                                    this.input.onChangeDate(date, 'endDate');
                                  }}
                                />
                              </Grid.Column>
                            </Grid>
                          </Grid.Column>
                          <Grid.Column flex='none'>
                            <Button
                              lightBlue
                              name={getLanguage('coilApproval.button.buttonSearch', '')}
                              width={VARIABLES.BUTTON.WIDTHS.W_105}
                              onClick={this.input.onClickSearchDate}
                            />
                          </Grid.Column>
                        </Grid>
                      </Grid.Column>
                    </Grid>
                  </Section>
                </Grid.Column>
              </Grid>
            </ShapeContainer>
          </Section>
          <Section>
            <ShapeContainer
              fluid
              calcMinHeight='100vh - 290px'
              paddingVertical='40'
              paddingHorizontal='40'
              bgColor={VARIABLES.COLORS.WHITE}
              borderRadius='10'
              justify='space-between'
            >
              <CoilApproveListContainerConnected />
            </ShapeContainer>
          </Section>
        </Section >
      </React.Fragment >
    )
  }
}
