export const PROFILE_MAPPING_RULE = [
  {
    profileType: 'trussc7575',
    thicknessMM: 0.75,
    coilWidthMM: [151, 1219],
    bmtMM: 0.70,
    itemTypeName: ['Metallic Coated'],
    coatingMassMM: ['AZ100'],
    steelGrade: [550]
  },
  {
    profileType: 'trussc7565',
    thicknessMM: 0.65,
    coilWidthMM: [1219],
    bmtMM: 0.60,
    itemTypeName: ['Metallic Coated'],
    coatingMassMM: ['AZ100'],
    steelGrade: [550]
  },
  {
    profileType: 'trussc7510',
    thicknessMM: 1,
    coilWidthMM: [151, 1219],
    bmtMM: 0.95,
    itemTypeName: ['Metallic Coated'],
    coatingMassMM: ['AZ100'],
    steelGrade: [550]
  },
  {
    profileType: 'trussc75080',
    thicknessMM: 0.8,
    coilWidthMM: [163],
    bmtMM: 0.75,
    itemTypeName: ['Metallic Coated'],
    coatingMassMM: ['AZ100'],
    steelGrade: [550]
  },
  {
    profileType: 'rengr3545',
    thicknessMM: 0.45,
    coilWidthMM: [113, 1219],
    bmtMM: 0.40,
    itemTypeName: ['Metallic Coated'],
    coatingMassMM: ['AZ100'],
    steelGrade: [550]
  },
  {
    profileType: 'rengr3245',
    thicknessMM: 0.45,
    coilWidthMM: [1219],
    bmtMM: 0.40,
    itemTypeName: ['Metallic Coated'],
    coatingMassMM: ['AZ100'],
    steelGrade: [550]
  },
  {
    profileType: 'rengr3045',
    thicknessMM: 0.45,
    coilWidthMM: [1219],
    bmtMM: 0.40,
    itemTypeName: ['Metallic Coated'],
    coatingMassMM: ['AZ100'],
    steelGrade: [550]
  },
  {
    profileType: 'metaltile025tct',
    thicknessMM: 0.25,
    coilWidthMM: [914],
    bmtMM: 0.20,
    itemTypeName: ['Painted Coil'],
    coatingMassMM: ['AZ100'],
    steelGrade: [300]
  },
  {
    profileType: 'metaltile030tct',
    thicknessMM: 0.30,
    coilWidthMM: [914, 1219],
    bmtMM: 0.25,
    itemTypeName: ['Metallic Coated'],
    coatingMassMM: ['AZ100'],
    steelGrade: [300]
  },
  {
    profileType: 'metaltile035tct',
    thicknessMM: 0.35,
    coilWidthMM: [914, 1219],
    bmtMM: 0.30,
    itemTypeName: ['Metallic Coated'],
    coatingMassMM: ['AZ100'],
    steelGrade: [300]
  },
  {
    profileType: 'longspan045tct',
    thicknessMM: 0.45,
    coilWidthMM: [914, 1219],
    bmtMM: 0.40,
    itemTypeName: ['Painted Coil', 'Metallic Coated'],
    coatingMassMM: ['AZ100', 'AZ150'],
    steelGrade: [300, 550]
  },
  {
    profileType: 'longspan040tct',
    thicknessMM: 0.40,
    coilWidthMM: [914, 1219],
    bmtMM: 0.35,
    itemTypeName: ['Metallic Coated', 'Painted Coil'],
    coatingMassMM: ['AZ100'],
    steelGrade: [550]
  },
  {
    profileType: 'longspan035tct',
    thicknessMM: 0.35,
    coilWidthMM: [914, 1219],
    bmtMM: 0.30,
    itemTypeName: ['Metallic Coated', 'Painted Coil'],
    coatingMassMM: ['AZ100', 'AZ70'],
    steelGrade: [300, 550]
  },
  {
    profileType: 'longspan030tct',
    thicknessMM: 0.30,
    coilWidthMM: [914, 1219],
    bmtMM: 0.25,
    itemTypeName: ['Metallic Coated', 'Painted Coil'],
    coatingMassMM: ['AZ100', 'AZ70'],
    steelGrade: [300, 550]
  },
  {
    profileType: 'longspan025tct',
    thicknessMM: 0.25,
    coilWidthMM: [914],
    bmtMM: 0.20,
    itemTypeName: ['Painted Coil', 'Metallic Coated'],
    coatingMassMM: ['AZ100', 'AZ70'],
    steelGrade: [300, 550]
  },
];