import React from "react";
// import {
//   connect,
// } from 'react-redux';
import {
  Section,
  Grid,
  Modal,
  Text,
  Button,
  Image,
  ShapeContainer,
} from "./../../../components";
import { VARIABLES, ICONS } from "./../../../themes";
import { ENUM } from "../../../helpers/constants/enum";
import { getLanguage } from "../../../helpers/functions/language";
// import { MODAL_TYPE } from '../../../helpers/constants/main'
// import { MAIN_REDUX_STATE_KEY } from '../../../actions/mainAction'
import _ from "lodash";

export class ModalChooseActionContainer extends React.Component {
  render() {
    const {
      isOpenModal,
      onClickClose,
      onClickAction = () => { },
      isDisableDocumentary
    } = this.props;
    return (
      <Modal
        open={isOpenModal}
        scroll
        // enableOverlayClose={false}
        width={VARIABLES.MODAL.WIDTHS.W_1165}
        overlayColor={VARIABLES.COLORS.OVERLAY_BLACK_1}
        spacing={VARIABLES.MODAL.CONTAINER.C_1.SPACING}
        transition={VARIABLES.TRANSITIONS.DEFAULT}
        transformStart={VARIABLES.MODAL.CONTAINER.C_1.TRANSFORM_START}
        transformEnd={VARIABLES.MODAL.CONTAINER.C_1.TRANSFORM_END}
        onClickClose={onClickClose}
      >
        <Modal.Section
          paddingVertical="25"
          paddingHorizontal="35"
          bgColor={VARIABLES.COLORS.WHITE}
          borderBottomWidth="1"
          borderBottomStyle="solid"
          borderBottomColor={VARIABLES.COLORS.GRAY_8}
          borderTopRightRadius={
            VARIABLES.MODAL.HEADER.H_1.BORDER_TOP_RIGHT_RADIUS
          }
          borderTopLeftRadius={
            VARIABLES.MODAL.HEADER.H_1.BORDER_TOP_LEFT_RADIUS
          }
        >
          <Grid gutter="10">
            <Grid.Column>
              <Text
                regular16
                singleLine
                color={VARIABLES.COLORS.BLACK}
                top="2"
              >
                {getLanguage(
                  "preTransaction.label.pleaseChooseAnAction",
                  ""
                )}
              </Text>
            </Grid.Column>
          </Grid>
        </Modal.Section>
        <Modal.Section
          paddingVertical="40"
          paddingHorizontal="35"
          bgColor={VARIABLES.COLORS.WHITE}
        >
          <Section align="center">
            <Grid gutter="15" justify="center">
              <Grid.Column>
                <ShapeContainer
                  onClick={() => { onClickAction(1) }}
                  width="180"
                  height="235"
                  borderRadius="5"
                  borderWidth="1"
                  borderStyle="solid"
                  borderColor={VARIABLES.COLORS.GRAY_8}
                  paddingVertical="40"
                  paddingHorizontal="20"
                  bgHoverColor={VARIABLES.COLORS.BLUE_22}
                  align="center"
                  textHoverColor={VARIABLES.COLORS.WHITE}
                  iconHover={
                    ICONS["ic-warranty-template-white.svg"]
                  }
                  disabled={false}
                  disabledColor={VARIABLES.COLORS.GRAY_8}
                >
                  <Section
                    nonFluid
                    spacingBottom="14"
                    align="center"
                  >
                    <Image
                      src={
                        ICONS[
                        "ic-warranty-template.svg"
                        ]
                      }
                      widthRatio="32"
                      widthRatioUnit="px"
                      heightRatio="38"
                      heightRatioUnit="px"
                    />
                  </Section>
                  <Section
                    nonFluid
                    spacingBottom="20"
                    align="center"
                    textAlign="center"
                  >
                    <Text
                      bold16
                      color={VARIABLES.COLORS.BLACK}
                      noWrap
                    >
                      {getLanguage(
                        "preTransaction.label.warrantyTempalte",
                        ""
                      )}
                    </Text>
                  </Section>
                  <Section
                    nonFluid
                    spacingBottom="10"
                    align="center"
                    textAlign="center"
                  >
                    <Text
                      regular12
                      color={VARIABLES.COLORS.GRAY_19}
                    >
                      {getLanguage(
                        "preTransaction.label.warrantyTempalteDesc",
                        ""
                      )}
                    </Text>
                  </Section>
                </ShapeContainer>
              </Grid.Column>
              <Grid.Column>
                <ShapeContainer
                  onClick={() => { onClickAction(2) }}
                  width="180"
                  height="235"
                  borderRadius="5"
                  borderWidth="1"
                  borderStyle="solid"
                  borderColor={VARIABLES.COLORS.GRAY_8}
                  paddingVertical="40"
                  paddingHorizontal="20"
                  bgHoverColor={VARIABLES.COLORS.BLUE_22}
                  align="center"
                  textHoverColor={VARIABLES.COLORS.WHITE}
                  iconHover={
                    ICONS["ic-document-template-white.svg"]
                  }
                  disabled={isDisableDocumentary}
                  disabledColor={VARIABLES.COLORS.GRAY_8}
                >
                  <Section
                    nonFluid
                    spacingBottom="15"
                    align="center"
                  >
                    <Image
                      src={ICONS[isDisableDocumentary ? 'ic-open-document-gray.svg' : "ic-documents-blue.svg"]}
                      widthRatio="32"
                      widthRatioUnit="px"
                      heightRatio="38"
                      heightRatioUnit="px"
                    />
                  </Section>
                  <Section
                    nonFluid
                    spacingBottom="20"
                    align="center"
                    textAlign="center"
                  >
                    <Text
                      bold16
                      color={VARIABLES.COLORS.BLACK}
                      noWrap
                    >
                      {getLanguage(
                        "preTransaction.label.documentTemplate",
                        ""
                      )}
                    </Text>
                  </Section>
                  <Section
                    nonFluid
                    spacingBottom="10"
                    align="center"
                    textAlign="center"
                  >
                    <Text
                      regular12
                      color={VARIABLES.COLORS.GRAY_19}
                    >
                      {getLanguage(
                        "preTransaction.label.documentTemplateDesc",
                        ""
                      )}
                    </Text>
                  </Section>
                </ShapeContainer>
              </Grid.Column>
              <Grid.Column>
                <ShapeContainer
                  onClick={() => { onClickAction(3) }}
                  width="180"
                  height="235"
                  borderRadius="5"
                  borderWidth="1"
                  borderStyle="solid"
                  borderColor={VARIABLES.COLORS.GRAY_8}
                  paddingVertical="40"
                  paddingHorizontal="20"
                  bgHoverColor={VARIABLES.COLORS.BLUE_22}
                  align="center"
                  textHoverColor={VARIABLES.COLORS.WHITE}
                  iconHover={
                    ICONS["ic-certificate-white.svg"]
                  }
                >
                  <Section
                    nonFluid
                    spacingBottom="15"
                    align="center"
                  >
                    <Image
                      src={ICONS["ic-certificate.svg"]}
                      widthRatio="32"
                      widthRatioUnit="px"
                      heightRatio="38"
                      heightRatioUnit="px"
                    />
                  </Section>
                  <Section
                    nonFluid
                    spacingBottom="20"
                    align="center"
                    textAlign="center"
                  >
                    <Text
                      bold16
                      color={VARIABLES.COLORS.BLACK}
                      noWrap
                    >
                      {getLanguage(
                        "preTransaction.label.certificate",
                        ""
                      )}
                    </Text>
                  </Section>
                  <Section
                    nonFluid
                    spacingBottom="10"
                    align="center"
                    textAlign="center"
                  >
                    <Text
                      regular12
                      color={VARIABLES.COLORS.GRAY_19}
                    >
                      {getLanguage(
                        "preTransaction.label.certificateDesc",
                        ""
                      )}
                    </Text>
                  </Section>
                </ShapeContainer>
              </Grid.Column>
              {/* <Grid.Column>
                <ShapeContainer
                  onClick={() => { onClickAction(4) }}
                  width="180"
                  height="235"
                  borderRadius="5"
                  borderWidth="1"
                  borderStyle="solid"
                  borderColor={VARIABLES.COLORS.GRAY_8}
                  paddingVertical="40"
                  paddingHorizontal="20"
                  bgHoverColor={VARIABLES.COLORS.BLUE_22}
                  align="center"
                  textHoverColor={VARIABLES.COLORS.WHITE}
                  iconHover={ICONS["ic-sale-order-white.svg"]}
                >
                  <Section
                    nonFluid
                    spacingBottom="15"
                    align="center"
                  >
                    <Image
                      src={ICONS["ic-sale-order.svg"]}
                      widthRatio="32"
                      widthRatioUnit="px"
                      heightRatio="38"
                      heightRatioUnit="px"
                    />
                  </Section>
                  <Section
                    nonFluid
                    spacingBottom="20"
                    align="center"
                    textAlign="center"
                  >
                    <Text
                      bold16
                      color={VARIABLES.COLORS.BLACK}
                      noWrap
                    >
                      {getLanguage(
                        "preTransaction.label.salesOrder",
                        ""
                      )}
                    </Text>
                  </Section>
                  <Section
                    nonFluid
                    spacingBottom="10"
                    align="center"
                    textAlign="center"
                  >
                    <Text
                      regular12
                      color={VARIABLES.COLORS.GRAY_19}
                    >
                      {getLanguage(
                        "preTransaction.label.salesOrderDesc",
                        ""
                      )}
                    </Text>
                  </Section>
                </ShapeContainer>
              </Grid.Column> */}
              <Grid.Column>
                <ShapeContainer
                  onClick={() => { onClickAction(5) }}
                  width="180"
                  height="235"
                  borderRadius="5"
                  borderWidth="1"
                  borderStyle="solid"
                  borderColor={VARIABLES.COLORS.GRAY_8}
                  paddingVertical="40"
                  paddingHorizontal="20"
                  bgHoverColor={VARIABLES.COLORS.BLUE_22}
                  align="center"
                  textHoverColor={VARIABLES.COLORS.WHITE}
                  iconHover={ICONS["ic-reference-white.svg"]}
                >
                  <Section
                    nonFluid
                    spacingBottom="15"
                    align="center"
                  >
                    <Image
                      src={ICONS["ic-reference.svg"]}
                      widthRatio="32"
                      widthRatioUnit="px"
                      heightRatio="38"
                      heightRatioUnit="px"
                    />
                  </Section>
                  <Section
                    nonFluid
                    spacingBottom="20"
                    align="center"
                    textAlign="center"
                  >
                    <Text
                      bold16
                      color={VARIABLES.COLORS.BLACK}
                      noWrap
                    >
                      {getLanguage(
                        "preTransaction.label.reference",
                        ""
                      )}
                    </Text>
                  </Section>
                  <Section
                    nonFluid
                    spacingBottom="10"
                    align="center"
                    textAlign="center"
                  >
                    <Text
                      regular12
                      color={VARIABLES.COLORS.GRAY_19}
                    >
                      {getLanguage(
                        "preTransaction.label.referenceDesc",
                        ""
                      )}
                    </Text>
                  </Section>
                </ShapeContainer>
              </Grid.Column>
              <Grid.Column>
                <ShapeContainer
                  onClick={() => { onClickAction(6) }}
                  width="180"
                  height="235"
                  borderRadius="5"
                  borderWidth="1"
                  borderStyle="solid"
                  borderColor={VARIABLES.COLORS.GRAY_8}
                  paddingVertical="40"
                  paddingHorizontal="20"
                  bgHoverColor={VARIABLES.COLORS.BLUE_22}
                  align="center"
                  textHoverColor={VARIABLES.COLORS.WHITE}
                  iconHover={ICONS["ic-warranty-guideline-white.svg"]}
                >
                  <Section
                    nonFluid
                    spacingBottom="15"
                    align="center"
                  >
                    <Image
                      src={ICONS["ic-warranty-guideline.svg"]}
                      widthRatio="32"
                      widthRatioUnit="px"
                      heightRatio="38"
                      heightRatioUnit="px"
                    />
                  </Section>
                  <Section
                    nonFluid
                    spacingBottom="20"
                    align="center"
                    textAlign="center"
                  >
                    <Text
                      bold16
                      color={VARIABLES.COLORS.BLACK}
                      noWrap
                    >
                      Warranty Guideline
                    </Text>
                  </Section>
                  <Section
                    nonFluid
                    spacingBottom="10"
                    align="center"
                    textAlign="center"
                  >
                    <Text
                      regular12
                      color={VARIABLES.COLORS.GRAY_19}
                    >
                      To view year warranty for favorite product group
                    </Text>
                  </Section>
                </ShapeContainer>
              </Grid.Column>
            </Grid>
          </Section>
        </Modal.Section>
        <Modal.Section
          paddingTop="15"
          paddingBottom="20"
          paddingHorizontal="35"
          borderTopWidth={VARIABLES.MODAL.FOOTER.F_1.BORDER_TOP_WIDTH}
          borderTopStyle={VARIABLES.MODAL.FOOTER.F_1.BORDER_TOP_STYLE}
          borderTopColor={VARIABLES.COLORS.GRAY_8}
          borderBottomRightRadius={
            VARIABLES.MODAL.FOOTER.F_1.BORDER_BOTTOM_RIGHT_RADIUS
          }
          borderBottomLeftRadius={
            VARIABLES.MODAL.FOOTER.F_1.BORDER_BOTTOM_LEFT_RADIUS
          }
        >
          <Grid gutter="15" justify="flex-end">
            <Grid.Column>
              <Button
                gray
                width={VARIABLES.BUTTON.WIDTHS.W_105}
                name={getLanguage("field.buttonClose", "")}
                onClick={onClickClose}
              />
            </Grid.Column>
          </Grid>
        </Modal.Section>
      </Modal>
    );
  }
}

// const mapStateToProps = (state) => {
//   const mainRedux = state.mainRedux;
//   const modalData = mainRedux[MAIN_REDUX_STATE_KEY.MODAL_DATA];
//   return {
//     isOpenModal: modalData.openModalType === MODAL_TYPE.CONFIRM_DELETE,
//     message: modalData.message,
//     onClickCancel: modalData.onClickCancel,
//     onClickConfirm: modalData.onClickConfirm,
//   }
// }

// const mapDispatchToProps = (dispatch) => {
//   return {
//   }
// }

// export const ModalInfoContainerConnected = connect(
//   mapStateToProps,
//   mapDispatchToProps
// )(ModalInfoContainer)
