import React from 'react';
import {
  connect,
} from 'react-redux';
import {
  ShapeContainer,
  Section,
  Grid,
  Field,
  Button,
  SelectControl,
  TitleSection,
} from './../../components'
import { FieldAddressContainerConnected } from './../../containers/FieldAddressContainer';
import {
  VARIABLES,
  ICONS
} from './../../themes'
import { ENUM } from '../../helpers/constants/enum';
import { MAIN_REDUX_STATE_KEY } from '../../actions/mainAction';
import { getLanguage } from '../../helpers/functions/language';
import _ from 'lodash';

class ManageContactAddressContainer extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
    }
  }


  render() {
    const {
      data,
      onChangeDataInput,
      onChangeContactChannel,
      goBack,
      nextStep,
      tabEdit,
      isEdit,
      onClickSave,
      userAuth,
      onChangeAddress,
      onChangeAddressContactSameId,
      isContactSameAddress
    } = this.props;
    return (
      <ShapeContainer
        ui='contentStep'
        justify='space-between'
      >
        {tabEdit}
        <Section>
          <Section spacingBottom='40'>
            <TitleSection name={getLanguage('customersManage.step.contactAddress', '')} />
          </Section>
          <Section spacingBottom='35'>
            <Field
              checked={isContactSameAddress || false}
              checkboxLabel={getLanguage('customersManage.form.contactAddress.sameAddress', '')}
              ui='checkboxTick'
              onChange={onChangeAddressContactSameId}
            />
          </Section>
          <Section spacingBottom='50'>
            <FieldAddressContainerConnected
              thai={data.contactAddressType === ENUM.COUNTRY_TYPE.THAI}
              malaysia={data.contactAddressType === ENUM.COUNTRY_TYPE.MALAYSIA}
              vietnam={data.contactAddressType === ENUM.COUNTRY_TYPE.VIETNAM}
              indonesia={data.contactAddressType === ENUM.COUNTRY_TYPE.INDONESIA}
              laos={data.contactAddressType === ENUM.COUNTRY_TYPE.LAOS}
              disabledAddress={isContactSameAddress}
              disabledProvince={isContactSameAddress}
              disabledCity={isContactSameAddress}
              disabledSubdistrict={isContactSameAddress}
              disabledPostalCode={isContactSameAddress}
              disabledDistrict={isContactSameAddress}
              disabledAlley={isContactSameAddress}
              disabledGroup={isContactSameAddress}
              disabledStreet={isContactSameAddress}
              disabledWard={isContactSameAddress}
              // Address
              requiredAddress={false}
              nameAddress='contactAddress'
              valueAddress={data.contactAddress}
              onChangeAddress={onChangeDataInput}
              // Province
              // requiredProvince={true}
              requiredProvince={false}
              nameProvince='contactSelectedProvince'
              valueProvince={data.contactSelectedProvince}
              onChangeProvince={(selected) => {
                if (data.contactAddressType === ENUM.COUNTRY_TYPE.LAOS) {
                  onChangeDataInput(selected);
                }
                else {
                  onChangeAddress(selected, 'contactSelectedProvince');
                }
              }}
              // City
              // requiredCity={true}
              requiredCity={false}
              nameCity='contactSelectedCity'
              valueCity={data.contactSelectedCity}
              onChangeCity={(selected) => {
                if (data.contactAddressType === ENUM.COUNTRY_TYPE.LAOS) {
                  onChangeDataInput(selected);
                }
                else {
                  onChangeAddress(selected, 'contactSelectedCity');
                }
              }}
              // Subdistrict
              requiredSubdistrict={false}
              nameSubdistrict='contactSubDistrict'
              valueSubdistrict={data.contactSubDistrict}
              onChangeSubdistrict={(selected) => {
                if (data.contactAddressType === ENUM.COUNTRY_TYPE.THAI) {
                  onChangeAddress(selected, 'contactSubDistrict');
                }
                else {
                  onChangeDataInput(selected);
                }
              }}
              // District
              requiredDistrict={false}
              isSearchableDistrict={false}
              nameDistrict='contactDistrict'
              valueDistrict={data.contactDistrict}
              onChangeDistrict={onChangeDataInput}
              // Alley
              requiredAlley={false}
              nameAlley='contactAlley'
              valueAlley={data.contactAlley}
              onChangeAlley={onChangeDataInput}
              // Group
              requiredGroup={false}
              nameGroup='contactGroup'
              valueGroup={data.contactGroup}
              onChangeGroup={onChangeDataInput}
              // Street
              requiredStreet={false}
              nameStreet='contactStreet'
              valueStreet={data.contactStreet}
              onChangeStreet={onChangeDataInput}
              // Ward
              requiredWard={false}
              nameWard='contactWard'
              valueWard={data.contactWard}
              onChangeWard={onChangeDataInput}
              // PostalCode
              requiredPostalCode={false}
              namePostalCode='contactPostCode'
              valuePostalCode={data.contactPostCode}
              onChangePostalCode={(selected) => {
                if (data.contactAddressType === ENUM.COUNTRY_TYPE.THAI) {
                  onChangeAddress(selected, 'contactPostCode');
                }
                else {
                  onChangeDataInput(selected);
                }
              }}
            />
          </Section>
          <Section spacingBottom='40'>
            <TitleSection name={getLanguage('customersManage.form.contactAddress.contactChannel', '')} />
          </Section>
          <Section spacingBottom='30'>
            <Grid
              gutter='35'
              gutterVertical='30'
            >
              <Grid.Column>
                <Field
                  checked={data.contactChannelList.indexOf(ENUM.CUSTOMER_CONTACT_CHANNEL.EMAIL) >= 0}
                  ui='checkboxTick'
                  checkboxLabel={getLanguage('field.email', '')}
                  onChange={() => {
                    onChangeContactChannel(ENUM.CUSTOMER_CONTACT_CHANNEL.EMAIL);
                  }}
                />
              </Grid.Column>
              <Grid.Column>
                <Field
                  checked={data.contactChannelList.indexOf(ENUM.CUSTOMER_CONTACT_CHANNEL.SMS) >= 0}
                  ui='checkboxTick'
                  checkboxLabel={getLanguage('field.sms', '')}
                  onChange={() => {
                    onChangeContactChannel(ENUM.CUSTOMER_CONTACT_CHANNEL.SMS);
                  }}
                />
              </Grid.Column>
            </Grid>
          </Section>
        </Section>
        <Grid
          gutter='15'
          justify='flex-end'
        >
          <Grid.Column>
            <Button
              gray
              ui='back'
              name={getLanguage('field.buttonBack', '')}
              onClick={goBack}
            />
          </Grid.Column>
          <Grid.Column>
            <Button
              blue
              name={isEdit ? getLanguage('field.buttonSave', '') : getLanguage('field.buttonSaveCapitalize', '')}
              width={VARIABLES.BUTTON.WIDTHS.W_200}
              onClick={isEdit ? onClickSave : nextStep}
            />
          </Grid.Column>
        </Grid>
      </ShapeContainer>
    )
  }
}

const mapStateToProps = (state) => {
  const mainRedux = state.mainRedux;
  return {
    userAuth: mainRedux[MAIN_REDUX_STATE_KEY.USER_AUTH]
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
  }
}

export const ManageContactAddressContainerConnected = connect(
  mapStateToProps,
  mapDispatchToProps
)(ManageContactAddressContainer)