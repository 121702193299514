import React from 'react';
import {
  connect,
} from 'react-redux';
import {
  ShapeContainer,
  Section,
  Grid,
  Field,
  Text,
  Button,
  TableAction,
  PaginationControl,
  Table,
  Image,
} from './../../components'
import {
  ICONS,
  VARIABLES,
} from './../../themes'
import { ROUTE_PATH } from '../../helpers/constants/route';
import { redirect, redirectData } from '../../helpers/functions/route';
import { getClearDocumentaryParams, getDocumentaryList } from '../../helpers/services/documentary';
import { DATA_REDUX_STATE_KEY, dataCreators } from '../../actions/dataAction';
import { bindActionCreators } from 'redux';
import { storeGetState, storeDispatch } from '../../stores';
import { ENUM } from '../../helpers/constants/enum';
import { getSortAscending, getTodayDateDiff } from '../../helpers/functions/data';
import { isShowLoading, toast } from '../../helpers/functions/main';
import { getLanguage } from '../../helpers/functions/language';
import _ from 'lodash';
import moment from 'moment';
import { DATE_FORMAT, DATE_MOMENT_FORMAT, DATA } from '../../helpers/constants/data';
import { getUserAuthRedux } from '../../helpers/functions/auth';
import { getWarrantyTemplateForm, getWarrantyTemplateImagePublicPath, handleWarrantyExtendedYearProductGroup, isWarrantyExtendedYear } from '../../helpers/functions/warranty';

class DocumentaryListContainer extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      activePage: 1,
      dataPerPage: 10,
    }
    const dataDefaultOptionParams = storeGetState().dataRedux.documentaryDefaultOptionParams;
    storeDispatch(dataCreators.setDataRedux(DATA_REDUX_STATE_KEY.DOCUMENTARY_OPTION_PARAMS, _.cloneDeep(dataDefaultOptionParams)));
  }

  componentDidMount() {
    const {
      activePage,
      dataPerPage,
    } = this.state;
    const params = {
      ...getClearDocumentaryParams(),
      page: activePage,
      limit: dataPerPage,
    };
    getDocumentaryList(params);
  }

  documentaryList = {
    onClickGenerate: (option = {}) => {
      const {
        dataOptionParams,
      } = this.props;

      let wrtNo = dataOptionParams.selectedDataList[0].wrtNo;
      let isSameWrt = true;
      dataOptionParams.selectedDataList.forEach(e => {
        if (e.wrtNo !== wrtNo) {
          isSameWrt = false;
        }
      });

      if (isSameWrt) {
        // const data = {
        //   ...dataOptionParams.selectedDataList[0],
        //   selectedDataList: dataOptionParams.selectedDataList,
        // };
        // CR: 1498
        const selectedDataList = _.orderBy(dataOptionParams.selectedDataList.map(e => {
          if (isWarrantyExtendedYear(e)) {
            e = handleWarrantyExtendedYearProductGroup(e);
          }
          return e;
        }), ['productGroupName'], ['asc'])

        const data = {
          ...selectedDataList[0],
          selectedDataList,
        };
        redirectData(ROUTE_PATH.DOCUMENTARY_MANAGE.LINK, {
          data
        })
      }
      else {
        toast.error(getLanguage('message.wrtNoPleaseSelectOnlyOne', ''));
      }
    },
    updateData: (dataList) => {
      const {
        dataActions,
        dataOptionParams,
      } = this.props;
      //handle new or edit data
      dataList.forEach(e => {
        const findIndex = dataOptionParams.selectedDataList.map(es => es.id).indexOf(e.id);
        if (findIndex >= 0) {
          dataOptionParams.selectedDataList[findIndex] = e;
        }
        else {
          if (e.isSelected) {
            dataOptionParams.selectedDataList.push(e);
          }
        }
      })
      dataOptionParams.selectedDataList = dataOptionParams.selectedDataList.filter(e => e.isSelected);
      dataActions.setDataRedux(DATA_REDUX_STATE_KEY.DOCUMENTARY_LIST, dataList);
      dataActions.setDataRedux(DATA_REDUX_STATE_KEY.DOCUMENTARY_OPTION_PARAMS, dataOptionParams);
    },
    onClickSort: (target) => {
      getDocumentaryList({
        sort: target
      });
    },
    onChangeSelect: (ev, item) => {
      const {
        dataList,
      } = this.props;
      const isChecked = ev.target.checked;
      const tempDataList = dataList.map(e => {
        if (e.id === item.id) {
          e.isSelected = isChecked;
        }
        return e
      })
      this.documentaryList.updateData(tempDataList);
    }
  }

  pagination = {
    onPaginationChange: (pageNumber) => {
      getDocumentaryList({
        page: pageNumber,
      });
    }
  }

  render() {
    const {
      dataList,
      totalData,
      activePage,
      dataParams,
      dataOptionParams
    } = this.props;
    const {
      dataPerPage,
    } = this.state;
    return (
      <ShapeContainer
        ui='contentFilter'
        justify='space-between'
      >
        <Section spacingBottom='45'>
          <Table
            striped
            actionCheckboxWarranty
          >
            <Table.Section>
              <Table.Row>
                <Table.HeadColumn
                  noSort
                  alignCenter
                  maxWidth={VARIABLES.TABLE.DOCUMENTARY.COLUMN_WIDTHS.SELECT}
                  minWidth={VARIABLES.TABLE.DOCUMENTARY.COLUMN_WIDTHS.SELECT}
                >
                  {getLanguage('documentary.table.header.select', '')}
                </Table.HeadColumn>
                <Table.HeadColumn
                  maxWidth={VARIABLES.TABLE.DOCUMENTARY.COLUMN_WIDTHS.CREATE_DATE}
                  minWidth={VARIABLES.TABLE.DOCUMENTARY.COLUMN_WIDTHS.CREATE_DATE}
                  sortAscending={getSortAscending(dataParams, 'created_date')}
                  onClickSort={() => {
                    this.documentaryList.onClickSort('created_date desc');
                  }}
                >
                  {getLanguage('documentary.table.header.createDate', '')}
                </Table.HeadColumn>
                <Table.HeadColumn
                  maxWidth={VARIABLES.TABLE.DOCUMENTARY.COLUMN_WIDTHS.WARRANTY_NO}
                  minWidth={VARIABLES.TABLE.DOCUMENTARY.COLUMN_WIDTHS.WARRANTY_NO}
                  sortAscending={getSortAscending(dataParams, 'wrt_no')}
                  onClickSort={() => {
                    this.documentaryList.onClickSort('wrt_no desc');
                  }}
                >
                  {getLanguage('documentary.table.header.warrantyNo', '')}
                </Table.HeadColumn>
                <Table.HeadColumn
                  maxWidth={VARIABLES.TABLE.DOCUMENTARY.COLUMN_WIDTHS.SALES_TRANSACTION_ID}
                  minWidth={VARIABLES.TABLE.DOCUMENTARY.COLUMN_WIDTHS.SALES_TRANSACTION_ID}
                  sortAscending={getSortAscending(dataParams, 'sales_tran')}
                  onClickSort={() => {
                    this.documentaryList.onClickSort('sales_tran desc');
                  }}
                >
                  {getLanguage('documentary.table.header.salesTId', '')}
                </Table.HeadColumn>
                <Table.HeadColumn
                  minWidth={VARIABLES.TABLE.DOCUMENTARY.COLUMN_WIDTHS.PROJECT_NAME}
                  sortAscending={getSortAscending(dataParams, 'project_name')}
                  onClickSort={() => {
                    this.documentaryList.onClickSort('project_name desc');
                  }}
                >
                  {getLanguage('documentary.table.header.projectName', '')}
                </Table.HeadColumn>
                <Table.HeadColumn
                  maxWidth={VARIABLES.TABLE.DOCUMENTARY.COLUMN_WIDTHS.CUSTOMER_NAME}
                  minWidth={VARIABLES.TABLE.DOCUMENTARY.COLUMN_WIDTHS.CUSTOMER_NAME}
                  sortAscending={getSortAscending(dataParams, 'customer_name')}
                  onClickSort={() => {
                    this.documentaryList.onClickSort('customer_name desc');
                  }}
                >
                  {getLanguage('documentary.table.header.customerName', '')}
                </Table.HeadColumn>
                <Table.HeadColumn
                  maxWidth={VARIABLES.TABLE.DOCUMENTARY.COLUMN_WIDTHS.PRODUCT_GROUP}
                  minWidth={VARIABLES.TABLE.DOCUMENTARY.COLUMN_WIDTHS.PRODUCT_GROUP}
                  sortAscending={getSortAscending(dataParams, 'product_group_name')}
                  onClickSort={() => {
                    this.documentaryList.onClickSort('product_group_name desc');
                  }}
                >
                  {getLanguage('documentary.table.header.productGroup', '')}
                </Table.HeadColumn>
                <Table.HeadColumn
                  maxWidth={VARIABLES.TABLE.DOCUMENTARY.COLUMN_WIDTHS.MOBILE_NO}
                  minWidth={VARIABLES.TABLE.DOCUMENTARY.COLUMN_WIDTHS.MOBILE_NO}
                  sortAscending={getSortAscending(dataParams, 'mobile_no')}
                  onClickSort={() => {
                    this.documentaryList.onClickSort('mobile_no desc');
                  }}
                >
                  {getLanguage('documentary.table.header.mobileNo', '')}
                </Table.HeadColumn>
                <Table.HeadColumn
                  maxWidth={VARIABLES.TABLE.DOCUMENTARY.COLUMN_WIDTHS.STATUS}
                  minWidth={VARIABLES.TABLE.DOCUMENTARY.COLUMN_WIDTHS.STATUS}
                  noSort
                >
                  {getLanguage('documentary.table.header.status', '')}
                </Table.HeadColumn>
                <Table.HeadColumn
                  noSort
                  alignCenter
                  maxWidth={VARIABLES.TABLE.DOCUMENTARY.COLUMN_WIDTHS.ACTION}
                  minWidth={VARIABLES.TABLE.DOCUMENTARY.COLUMN_WIDTHS.ACTION}
                >
                  {getLanguage('documentary.table.header.action', '')}
                </Table.HeadColumn>
              </Table.Row>
            </Table.Section>
            {dataList.length ?
              <Table.Section>
                {dataList.map((e, i) => {
                  return (
                    <Table.Row key={i}>
                      <Table.BodyColumn
                        action
                        alignCenter
                        maxWidth={VARIABLES.TABLE.DOCUMENTARY.COLUMN_WIDTHS.SELECT}
                        minWidth={VARIABLES.TABLE.DOCUMENTARY.COLUMN_WIDTHS.SELECT}
                      >
                        {e.isCanSelect ?
                          <Section spacingTop='-2'>
                            <Field
                              disabled={false}
                              checked={e.isSelected}
                              ui='checkboxTick'
                              onChange={(ev) => this.documentaryList.onChangeSelect(ev, e)}
                            />
                          </Section>
                          :
                          <Text
                            regular12
                            color={VARIABLES.COLORS.GRAY_19}
                          >
                            -
                          </Text>
                        }
                      </Table.BodyColumn>
                      <Table.BodyColumn
                        info
                        maxWidth={VARIABLES.TABLE.DOCUMENTARY.COLUMN_WIDTHS.CREATE_DATE}
                        minWidth={VARIABLES.TABLE.DOCUMENTARY.COLUMN_WIDTHS.CREATE_DATE}
                      >
                        {e.displayCreatedDate}
                      </Table.BodyColumn>
                      <Table.BodyColumn
                        info
                        maxWidth={VARIABLES.TABLE.DOCUMENTARY.COLUMN_WIDTHS.WARRANTY_NO}
                        minWidth={VARIABLES.TABLE.DOCUMENTARY.COLUMN_WIDTHS.WARRANTY_NO}
                        title={e.wrtNo}
                      >
                        {e.wrtNo}
                      </Table.BodyColumn>
                      <Table.BodyColumn
                        info
                        maxWidth={VARIABLES.TABLE.DOCUMENTARY.COLUMN_WIDTHS.SALES_TRANSACTION_ID}
                        minWidth={VARIABLES.TABLE.DOCUMENTARY.COLUMN_WIDTHS.SALES_TRANSACTION_ID}
                        title={e.salesTran}
                      >
                        {e.salesTran}
                      </Table.BodyColumn>
                      <Table.BodyColumn
                        info
                        minWidth={VARIABLES.TABLE.DOCUMENTARY.COLUMN_WIDTHS.PROJECT_NAME}
                        title={e.projectName}
                      >
                        {e.projectName}
                      </Table.BodyColumn>
                      <Table.BodyColumn
                        info
                        maxWidth={VARIABLES.TABLE.DOCUMENTARY.COLUMN_WIDTHS.CUSTOMER_NAME}
                        minWidth={VARIABLES.TABLE.DOCUMENTARY.COLUMN_WIDTHS.CUSTOMER_NAME}
                        title={e.customerName}
                      >
                        {e.customerName}
                      </Table.BodyColumn>
                      <Table.BodyColumn
                        info
                        maxWidth={VARIABLES.TABLE.DOCUMENTARY.COLUMN_WIDTHS.PRODUCT_GROUP}
                        minWidth={VARIABLES.TABLE.DOCUMENTARY.COLUMN_WIDTHS.PRODUCT_GROUP}
                        title={e.productGroupName}
                      >
                        {e.productGroupName}
                      </Table.BodyColumn>
                      <Table.BodyColumn
                        phone
                        maxWidth={VARIABLES.TABLE.DOCUMENTARY.COLUMN_WIDTHS.MOBILE_NO}
                        minWidth={VARIABLES.TABLE.DOCUMENTARY.COLUMN_WIDTHS.MOBILE_NO}
                        srcFlag={ICONS['ic-flag-th.jpg']}
                        idPhone={e.mobileNoPrefix}
                      >
                        {e.mobileNoSuffix}
                      </Table.BodyColumn>
                      <Table.BodyColumn
                        infoStatus
                        // Status color
                        // No status(-) = GRAY_19, Ready = GREEN_5
                        infoStatusColor={e.isReady ? VARIABLES.COLORS.GREEN_5 : VARIABLES.COLORS.GRAY_19}
                        maxWidth={VARIABLES.TABLE.DOCUMENTARY.COLUMN_WIDTHS.STATUS}
                        minWidth={VARIABLES.TABLE.DOCUMENTARY.COLUMN_WIDTHS.STATUS}
                      >
                        {e.isReady ? getLanguage('documentary.label.ready', '') : '-'}
                      </Table.BodyColumn>
                      <Table.BodyColumn
                        action
                        alignCenter
                        maxWidth={VARIABLES.TABLE.DOCUMENTARY.COLUMN_WIDTHS.ACTION}
                        minWidth={VARIABLES.TABLE.DOCUMENTARY.COLUMN_WIDTHS.ACTION}
                      >
                        {e.isReady ?
                          <Section>
                            <Image
                              title={getLanguage('field.buttonViewDocument', '')}
                              ui='iconDocuments'
                              onClick={() => {
                                redirect(`${ROUTE_PATH.DOCUMENTARY_VIEW_LIST.LINK}/${e.salesTranId}`, {
                                  data: e,
                                });
                              }}
                            />
                          </Section>
                          :
                          <Text
                            regular12
                            top='2'
                            color={VARIABLES.COLORS.GRAY_19}
                          >
                            -
                          </Text>
                        }
                      </Table.BodyColumn>
                    </Table.Row>
                  )
                })}
              </Table.Section>
              :
              <Section justify='center' paddingVertical='60'>
                <Section justify='center' spacingBottom='10'>
                  <img src={ICONS['ic-documents-gray.svg']} alt='Icon' />
                </Section>
                <Text regular14 color={VARIABLES.COLORS.GRAY_12}>
                  {getLanguage('documentary.message.noResult')}
                </Text>
              </Section>
            }
          </Table>
        </Section>
        <TableAction>
          <TableAction.Left>
            <Grid gutter='15'>
              <Grid.Column>
                <ShapeContainer
                  ui='badgeCount'
                  badgeCountTitle={getLanguage('documentary.label.selectedTransaction', '')}
                  badgeCountNumber={dataOptionParams.selectedDataList.length}
                />
              </Grid.Column>
              <Grid.Column>
                <Button
                  disabled={
                    dataOptionParams.selectedDataList.length === 0
                  }
                  blue
                  name={getLanguage('field.buttonGenerateDocumentary', '')}
                  width={VARIABLES.BUTTON.WIDTHS.W_200}
                  onClick={this.documentaryList.onClickGenerate}
                />
              </Grid.Column>
            </Grid>
          </TableAction.Left>
          <TableAction.Right>
            <Grid gutter='20'>
              <Grid.Column>
                <Section paddingTop='12'>
                  <Text
                    regular14
                    color={VARIABLES.COLORS.GRAY_16}
                  >
                    {getLanguage('pagination.page', '')} {activePage}/{Math.ceil(totalData / dataPerPage)}
                  </Text>
                </Section>
              </Grid.Column>
              <Grid.Column>
                <Section paddingTop='6'>
                  <PaginationControl
                    activePage={activePage}
                    itemsCountPerPage={dataPerPage}
                    totalItemsCount={totalData}
                    pageRangeDisplayed={3}
                    onChange={this.pagination.onPaginationChange}
                  />
                </Section>
              </Grid.Column>
            </Grid>
          </TableAction.Right>
        </TableAction>
      </ShapeContainer>
    )
  }
}

const mapStateToProps = (state) => {
  const dataRedux = state.dataRedux;
  return {
    dataList: dataRedux.documentaryList,
    dataParams: dataRedux.documentaryParams,
    dataOptionParams: dataRedux.documentaryOptionParams,
    activePage: dataRedux.documentaryParams.activePage,
    totalData: dataRedux.documentaryParams.totalData || 0,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    dataActions: bindActionCreators(dataCreators, dispatch),
  }
}

export const DocumentaryListContainerConnected = connect(
  mapStateToProps,
  mapDispatchToProps
)(DocumentaryListContainer)