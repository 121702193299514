import { storeGetState } from "../stores"
import { getProvince, getCity, getSubDistrictOption, getSubDistrictThaiByLabel, getProvinceThaiByLabel, getCityThaiByLabel } from "../helpers/functions/data";
import { ENUM } from "../helpers/constants/enum";

export class ModelAddress {
  constructor(props = {}) {
    props = props || {};
    // const countryList = storeGetState().dataRedux.countryList;
    // let selectedProvince;
    // let selectedCity;
    // if (props.province) {
    //   selectedProvince = countryList.filter(e => e.provinceCode.toString() === props.province.toString())[0];
    //   if (selectedProvince) {
    //     selectedCity = selectedProvince.cityList.filter(e => e.cityCode.toString() === props.city.toString())[0];
    //   }
    // }
    this.address = props.address_text;
    // this.selectedProvince = selectedProvince;
    // this.selectedCity = selectedCity;
    this.selectedProvince = props.address_type === ENUM.COUNTRY_TYPE.LAOS ? props.province || '' : getProvince(props.province);
    this.selectedCity = props.address_type === ENUM.COUNTRY_TYPE.LAOS ? props.city || '' : getCity(props.city);
    this.district = props.district;
    //TODO: laos
    // this.subDistrict = props.address_type === ENUM.COUNTRY_TYPE.THAI ? getSubDistrictOption(this.selectedCity, props.sub_district) : props.sub_district;
    this.subDistrict = props.sub_district || '';

    if (props.address_type === ENUM.COUNTRY_TYPE.THAI) {
      // this.selectedProvince = getProvinceThaiByLabel(props.province || '');
      // this.selectedCity = getCityThaiByLabel(props.city || '', this.selectedProvince);
      this.subDistrict = getSubDistrictThaiByLabel(this.subDistrict, this.selectedCity);
    }

    this.alley = props.alley;
    this.group = props.group;
    this.street = props.street;
    this.ward = props.ward;
    this.addressType = props.address_type;
  }
}