export const lang_en = {
  topMenu: {
    myProfile: "My Profile",
    chooseLanguage: {
      title: "Choose Language",
      thai: "Thai",
      english: "English",
      indonesia: "Bahasa indonesia",
      vietnam: "Tiếng việt",
      malaysia: "Malaysia",
      laos: "Laos",
    },
  },
  sideMenu: {
    home: "Home",
    yearWarranty: "Warranty Guideline",
    customers: "Customers",
    orderInquiry: "Order Inquiry",
    salesTransaction: "Sales Transaction",
    salesOrders: "Sales Orders",
    salesHistory: "Sales History",
    warrantyManagement: "Warranty Management",
    warrantyTemplate: "Template",
    warranty: "Warranty",
    documentary: "Documentary",
    documentTemplate: "Document Template",
    rewards: "Rewards",
    redemption: "Redemption",
    rewardRedemptionHistory: "Reward Redemption History",
    rewardStock: "Reward Stock",
    trueBluePointsDetail: "TrueBlue Points Detail",
    inventory: "Inventory & Coil Transfer",
    inventoryManagement: "Inventory",
    profileMapping: "Profile Mapping",
    profileMappingHistory: "Profile Mapping History",
    profileTransfer: "Profile Transfer",
    profileTransferHistory: "Profile Transfer History",
    coilTransfer: "Sell coil out",
    coilTransferHistory: "Coil Transfer History",
    report: "Report",
    settings: "Settings",
    adInformation: "Authorised Dealer's Information",
    termAndCondition: "TERMS AND CONDITIONS",
    contactUs: "Contact Us",
    help: "Help",
    salesPerson: "Sales Person",
    uploadTransaction: "Upload Transaction",
    uploadTransactionManagement: "Upload Transaction Management",
    uploadTransactionHistory: "Upload Transaction History",
    preTransaction: "Pre Transaction",
    projectDocument: "Project Document",
    preTransactionHistory: "Pre-Transaction History",
    projectReference: "Project Reference",
    barcodeScan: "Buy coil in",
    coilApproval: "Approve coil transfer",
    notificationManagement: "Notification Management",
  },
  login: {
    message_1: "Welcome to",
    message_2: "TrueBlue",
    message_3: "Forget User ID or Password",
    message_4: "Enter your email address to reset your password.",
    message_5: "Check",
    message_6: "Authentication",
    message_7: "Authentication link has been sent to your phone (SMS).",
    message_8: "Please approve the access from the link within 3 minutes.",
    message_9: "Reference Code is",
    message_10: "Success!",
    message_11: "Fail!",
    message_12: "expired",
    message_13: "Verify",
    message_14: "Methods",
    message_15: "Verify with",
    message_16: "Phone Number",
    message_17: "Questions",
    message_18: "OTP",
    message_19: "send to",
    message_20: "Re-send",
    message_21: "What province is your shop in? (Thai language)",
    message_22: "How many branches do you have?",
    message_23: "What year was your shop founded? (Buddhist calendar)",
    message_24: "8 - 24 Characters or Numbers",
    message_25: "Reset Password",
    message_26: "Please try your new password in the next login.",
    message_27: "Reset",
    message_28: "Password",
    message_29: "Setup",
    maintenance: "ระบบได้ปิดทำการปรับปรุงตั้งแต่",
    maintenance_2: "กรุณาเข้าระบบใหม่หลังจากระบบเปิดทำการตามเวลาที่แจ้ง",
    message_30: "For account security, please log in again.",
  },
  home: {
    breadcrumb1: "Home",
    title: "Home",
    label: {
      barcodeScan: "Scan Barcode to get coil",
    },
  },
  yearWarranty: {
    breadcrumb1: "Home",
    breadcrumb2: "Warranty Guideline",
    title: "Warranty Guideline",
    label: {
      product: "Product",
      environment: "Environment",
      wall: "Wall",
      roof: "Roof",
      purlinGirt: "Purlin",
      trussFrame: "Truss & Frame",
      coolRoomPanel: "Sandwich panel",
      decking: "Decking",
      airDucting: "Air Ducting",
      shutterEx: "Shutter ex",
      warrantiesAvailable: "Warranties Available",
      year: "Year(s)",
      std: "Std.",
      standard: "Standard",
      industrial: "Industrial",
      marine: "Marine",
      distance: "Distance",
      m: "m",
      metre: "metre",
      min: "Min",
      max: "Max",
      from: "From",
      pleaseRegister: "Please register",
    },
    placeholder: {
      selectProduct: "Select Product",
      selectFrom: "Select From",
      selectDistance: "Select Distance(m)",
    },
    hint: {
      short: {
        br: "B/R",
        lu: "L/U",
        ind: "Ind",
        hind: "HInd",
        sind: "SInd",
        mm: "MM",
        m: "M",
        sm: "SM",
        vsm: "VSM",
      },
      long: {
        br: "Benign / Rural",
        lu: "Light Ind / Urban",
        ind: "Industrial",
        hind: "Heavy Industrial",
        sind: "Severe Industrial",
        mm: "Moderate Marine",
        m: "Marine",
        sm: "Severe Marine",
        vsm: "Very Severe Marine",
      },
      message: {
        steelProductionDate: "It is only steel produced before 18 August 2024.",
      },
    },
    warranty: {
      perforateByCorrosion1: 'Shall not perforate by corrosion for',
      perforateByCorrosion2: 'Shall not structural failure by corrosion for', // isSteelBuildingFrames
      perforateByCorrosion3: 'Shall not structural failure by corrosion for', // SuperDyma / TrueCore
      peelOrFlakeExcessively1: 'Shall not perforate by color peeling for',
      colourFadeExcessively1: 'Shall not color fade excessively for',
      discolourByDirtStaining1: 'Shall not discolor by dirt staining for',
      noWarrantyInformation: 'No warranty information.',
      perforateDueToCorrosionRoof: 'Shall not perforate by corrosion for roof',
      perforateDueToCorrosionWall: 'Shall not perforate by corrosion for wall',
      year: 'years'
    },
  },
  customers: {
    breadcrumb1: "Home",
    breadcrumb2: "Customers",
    title: "Customer Management",
    placeholder: "Search",
    selectOption: {
      all: "All",
      name: "Name",
      nickname: "Nickname",
      phoneNo: "Phone No.",
    },
    buttonSearch: "Search",
    buttonCreate: "Create Customer",
    table: {
      header: {
        no: "No.",
        id: "ID",
        name: "Name",
        nickname: "Nickname",
        phoneNo: "Phone No.",
        points: "TrueBlue Points",
        expiringPoints: "TrueBlue Points Expiring in",
        trained: "Trained",
        level: "Level",
        action: "Action",
        memberType: "Member Type",
      },
      body: {
        platinum: "Platinum",
        gold: "Gold",
        silver: "Silver",
        bronze: "Bronze",
      },
    },
    checkboxs: {
      all: "All",
      onlyMemberPointsExpire: "Only member (points expire)",
      pdf: "PDF",
      excel: "Excel",
    },
  },
  customersManage: {
    breadcrumb1: "Home",
    breadcrumb2: "Customers",
    breadcrumb3_1: "Create Customer",
    breadcrumb3_2: "Edit Customer",
    step: {
      register: "Register",
      customerInfo: "Customer Info",
      idAddress: "ID Address",
      contactAddress: "Contact Address",
    },
    tab: {
      registration: "Registration",
      customerInfo: "Customer Info",
      idAddress: "ID Address",
      contactAddress: "Contact Address",
    },
    form: {
      regis: {
        customerType: "Customer Type",
        fixer: "Fixers / Contractors",
        hardware: "Hardware Store / Construction Shop",
        homeowner: "Homeowner / Project Owner",
        rollFormer: "Roll formers / RF Sales / RF Fixers",
        others: "Others (Military, Government)",
        authorisedDealer: "Authorised Dealer",
        architect: "Architect / Designer / Consult",
        engineer: "Engineer"
      },
      idAddress: {
        idCardNo: "ID Card No.",
      },
      contactAddress: {
        sameAddress: "Same as ID Address",
        contactChannel: "Contact Channel",
      },
    },
  },
  orderInquiry: {
    breadcrumb1: "Home",
    breadcrumb2: "Order Inquiry",
    title: "Order Inquiry List",
    placeholder: "Customer, Phone, Email, Status",
    buttonSearch: "Search",
    table: {
      header: {
        id: "ID",
        subject: "Subject",
        customer: "Customer",
        phoneNo: "Phone No.",
        email: "Email",
        pendingOn: "Pending On",
        responseDate: "Response Date",
        status: "Status",
        action: "Action",
      },
      action: {
        details: "Details",
      },
    },
  },
  orderInquiryManage: {
    breadcrumb1: "Home",
    breadcrumb2: "Order Inquiry",
    breadcrumb3: "Order Inquiry",
    title: "Order Inquiry Information",
    form: {
      information: {
        acknowledged: "Acknowledged",
        pending: "Pending",
        subject: "Subject",
        description: "Description",
        customer: "Customer",
        phoneNo: "Phone No.",
        email: "Email",
        submittedOn: "Submitted On",
        responseDate: "Response Date",
        status: "Status",
      },
    },
  },
  salesTransaction: {
    breadcrumb1: "Home",
    breadcrumb2: "Sales Transaction",
    title: "Sales Transaction",
    subtitle: "Sales Transaction Indexes",
    page: {
      goToPage: "Go to page",
      salesOrders: "Sales Orders",
      salesHistory: "Sales History",
    },
  },
  salesOrders: {
    breadcrumb1: "Home",
    breadcrumb2: "Sales Transaction",
    breadcrumb3: "Sales Orders",
    title: "Sales Transaction > Sales Orders",
    form: {
      customerInfo: "Customer Info",
      selectCustomer: "Select or create customer for create sales orders.",
      buttonCreate: "Create Customer",
      buttonSelectCustomer: "Select Customer",
    },
  },
  salesHistory: {
    breadcrumb1: "Home",
    breadcrumb2: "Sales Transaction",
    breadcrumb3: "Sales History",
    title: "Sales Transaction > Sales History",
    selectOption: {
      all: "All",
      salesTransactionID: "Sales Transaction ID",
      customerName: "Customer Name",
      phoneNumber: "Phone Number",
    },
    placeholder: "Search",
    date: "Date",
    dateTo: "to",
    selectStartDate: "Select Start Date",
    selectEndDate: "Select End Date",
    buttonSearch: "Search",
  },
  warranty: {
    breadcrumb1: "Home",
    breadcrumb2: "Warranty",
    breadcrumb3: "Warranty List",
    title: "Warranty",
    selectOption: {
      salesTransactionID: "Sales Transaction ID",
      contactName: "Contact Name",
      warrantyNo: "Warranty No.",
      projectName: "Project Name",
      phoneNumber: "Phone Number",
    },
    placeholder: "Search",
    date: "Date",
    dateTo: "to",
    selectStartDate: "Select Start Date",
    selectEndDate: "Select End Date",
    buttonSearch: "Search",
    selectedTransactions: "Selected Transactions",
    buttonGenerateWarranty: "Generate Warranty",
    footer: {
      createDate: 'Created Date',
      checkViaQr: 'Verify information via QR Code',
    }
  },
  warrantyManage: {
    breadcrumb1: "Home",
    breadcrumb2: "Warranty",
    breadcrumb3: "Warranty List",
    breadcrumb4: "Generate Warranty",
    title: "Warranty > Generate Warranty",
    alertGenerateWarranty: "This warranty is being generate.",
    step: {
      information: "Information",
      segment: "Segment",
      detail: "Detail",
      conditions: "Conditions",
      warranty: "Warranty",
      documentaryInformation: "Documentary Information",
      documentary: "Documentary",
    },
    form: {
      info: {
        title: "Project Information",
        title_env: "Environment Informaion",
      },
      segment: {
        title: "Segment Information",
        button: {
          search: "Search",
          segmentAndSubSegmentInformation: "Segment & Sub Segment Information",
        },
        table: {
          header: {
            segment: "Segment",
            description: "Segment Description",
            subSegment: "Sub Segment",
            subSegmentDescription: "Sub Segment Description",
          },
        },
        modal: {
          segmentAndSubSegmentInformation: "Segment & Sub Segment Information",
        },
        building: {
          factory: "Factory",
          office: "Office",
          carPark: "Car park",
          warehouse: "Warehouse",
        },
      },
      detail: {
        applicationDetail: "Application Detail",
        productInformation: "Product Information",
        roofAndWall: "Roof and wall - meter",
        roof: "Roof - meter",
        wall: "Wall - meter",
        purlin: "Purlin & Girt - meter",
        truss: "Truss & Frame - meter",
        coolRoom: "Cool room panel - meter",
        decking: "Decking - meter",
        airDucting: "Air Ducting - meter",
        quantityRemaining: "Quantity remaining",
        noInformation: "No Information",
        exceedQuantity:
          "Exceed quantity, please change quantity or change segment.",
        segmentInformation: "Segment Information",
        buildingInformation: "Building Information",
        table: {
          header: {
            selectedItem: "Selected Item",
            purchasingDate: "Purchasing Date",
            salesTransactionID: "Sales Transaction ID",
            coilDescription: "Coil Description",
            coilNo: "Coil No.",
            quantity: "Quantity",
          },
        },
      },
      condition: {
        title: "Terms and Conditions",
        conditionYes: "Yes",
        conditionNo: "No",
        condition1: "Roof pitch > 5 degree or provide free drainage of water",
        condition2: "Screw class 3 or 4 (not stainless)",
        condition3: "No stack",
        condition4: "Eave exposed",
        condition5: "Roof lined with",
        condition5_1: "Bilk Insulation",
        condition5_2: "Celling",
        condition5_3: "Vapour Barrier",
        condition5_4: "Nothing",
        condition6: "Rural or Urban",
        condition6_1: "Rural",
        condition6_2: "Urban",
        condition7: `Project's location must me located away from marine > 1 km.`,
        condition7cTitle: "Please choose additional options",
        condition7_1: "< 300 m.",
        condition7_2: "> 300, < 500 m.",
        condition7_3: "> 500, < 1,000 m.",
        condition8: `Project's location must me located away from industrial or corrosive environments > 1 km.`,
        condition8cTitle: "Please choose additional options",
        condition8_1: "< 500 m.",
        condition8_2: "> 500, < 1 km.",
        condition8_3: "> 1 , < 2 km.",
        condition9:
          "Product must not be used with incompatible materials such as stainless steel, copper, acid or base",
        condition10:
          "Specified indoor activities do not risk for the product life time. (Heat, Chemical, Vapor process)",
        conditionUnitPlaceholder: "Insert Number",
        conditionSelectPlaceholder: "Select Option",
        conditionDescriptionPlaceholder: "Insert Description",
        conditionDescriptionHint: "Maximum 500 characters.",
        application: {
          roofAndWall: "Roof and wall",
          walling: "Walling",
          roofing: "Roofing",
          purlinAndGirt: "Purlin & Girt",
          trussAndFrame: "Truss & Frame",
          coolRoomPanel: "Cool room panel",
          decking: "Decking",
          airDucting: "Air Ducting",
        },
        criteriaAndSurroundingEnvironment:
          "Criteria and Surrounding Environment",
        warrantyPeriods: "Warranty Periods",
        warrantyPeriodsCondition:
          "Request for shorter warranty periods, please select product:",
        warrantyPeriodsSelectLabel: "Select all products",
        warrantyPeriodsSelectPlaceholder: "Select product",
        warrantyPeriodsConditionAttribute: {
          PleaseSelectApplication: "Please select application",
          roof: "Roof",
          wall: "Wall",
          corrosion: "Corrosion",
          peelFlake: "Peel & Flake",
          colorFade: "Color Fade",
          dirtStain: "Dirt Stain",
        },
        roofConditions: {
          condition1:
            "Has materials & location been inspected by supplier of profiled sheet? (by supplier of profiles sheets or their representatives agents).",
          condition2:
            "Fasteners comply with AS3566: Class 3 (>400m from Marine/Industry) or Class 4 (≤400m from Marine/Industry) and fasteners are fastened properly to crest.",
          condition3: "Do not have stack on the building or nearby area.",
          condition4:
            "Panel is installed in accordance with manufacturer's installation manual / current published BlueScope guidelines.",
          condition5:
            "Panel is cleaned of rubbish / swarf and other foreign materials.",
          condition6:
            "Flashing, capping and associated components used are manufactured from compatible materials (Clean COLORBOND® steel, ZINCALUME® steel, zinc or aluminium) or be coated or plated in accordance with current published BlueScape guidelines.",
          condition7:
            "Lightning conductors material used is compatible (e.g. Aluminium) with Blue Scope's coated steel.",
          condition8: "Touch-up paint has not been used.",
          condition9:
            "Neutral cure silicone sealants is used in installation with current published BlueScope guidelines.",
          condition10:
            "Is the surrounding environment and installation condition as per normal condition stipulated in the warranty T&C?",
          condition11_1: "Project is located",
          condition11_2: "from Calm marine influence.",
          condition12_1: "Project is located",
          condition12_2: "from Surf marine influence.",
          condition13_1: "Project is located",
          condition13_2: "from severe industry influence.",
          condition14_1: "Project is located",
          condition14_2: "from light industry influence.",
          condition15:
            "Give full details of any other aggressive of unusual factors considered to influence warranty and state distance away in km:",
          condition16:
            "Observed performance of similar products and application in the area of describe activities within the building:",
        },
        wallConditions: {
          condition1:
            "Has materials & location been inspected by supplier of profiled sheet? (by supplier of profiles sheets or their representatives agents).",
          condition2:
            "Fasteners comply with AS3566: Class 3 (>400m from Marine/Industry) or Class 4 (≤400m from Marine/Industry) and fasteners are fastened properly to crest or valley.",
          condition3: "Do not have stack on the building or nearby area.",
          condition4:
            "Wall cladding is installed in accordance with manufacturer's installation manual / current published BlueScope guidelines.",
          condition5:
            "Wall cladding is cleaned of rubbish / swarf and other foreign materials.",
          condition6:
            "Flashing, capping and associated components used are manufactured from compatible materials (Clean COLORBOND® steel, ZINCALUME® steel, zinc or aluminium) or be coated or plated in accordance with current published BlueScape guidelines.",
          condition7:
            "Lightning conductors material used is compatible (e.g. Aluminium) with Blue Scope's coated steel.",
          condition8: "Touch-up paint has not been used.",
          condition9:
            "Neutral cure silicone sealants is used in installation with current published BlueScope guidelines.",
          condition10:
            "Is the surrounding environment and installation condition as per normal condition stipulated in the warranty T&C?",
          condition11_1: "Project is located",
          condition11_2: "from Calm marine influence.",
          condition12_1: "Project is located",
          condition12_2: "from Surf marine influence.",
          condition13_1: "Project is located",
          condition13_2: "from severe industry influence.",
          condition14_1: "Project is located",
          condition14_2: "from light industry influence.",
          condition15:
            "Give full details of any other aggressive of unusual factors considered to influence warranty and state distance away in km:",
          condition16:
            "Observed performance of similar products and application in the area of describe activities within the building:",
        },
        trussAndFrameConditions: {
          condition1:
            "Has materials & location been inspected by supplier of profied sheet? (by supplier of peoples sheets or their representatives agents).",
          condition2:
            'Steel roof truss/framing is "Enclosed within the building envelope".',
          condition3:
            "Host dwelling being located more than 300 meters from breaking surf or more than 100 meters from Still water.",
          condition4:
            "Steel roof truss / framing being installed in accordance with manufacturer's published recommendations.",
          condition5:
            "Installation of a damp proof course between bottom plates and concrete slabs were used.",
          condition6:
            "Installation of appropriate flashings and other water proofing treatments of any penetrations to eliminate wate ingress.",
          condition7:
            "Installation of fasteners which comply with relevant requirement prescribed in AS3588 and other applicable standards.",
          condition8:
            "No physical contact between incompatible materials (e.g. copper pipes or treated timer) in contact With steel roof truss / framing.",
          condition9:
            "Steel roof truss / framing is cleaned of swarf and other foreign materials (e.g. mortar) before and after site assembling.",
          condition10:
            "No mechanical or chemical damages on steel roof truss / framing coating before and or after site assembling.",
          condition11:
            "Is the surrounding environment and installation condition as per normal condition stipulated in the warranty T&C?",
          condition12_1: "Project is located",
          condition12_2: "from Calm marine influence.",
          condition13_1: "Project is located",
          condition13_2: "from Surf marine influence.",
          condition14_1: "Project is located",
          condition14_2: "from severe industry influence.",
          condition15_1: "Project is located",
          condition15_2: "from light industry influence.",
          condition16:
            "Give full details of any other aggressive of unusual factors considered to influence warranty and state distance away in km:",
          condition17:
            "Observed performance of similar products and application in the area of describe activities within the building:",
        },
        purlinAndGirtConditions: {
          condition1:
            "Has materials & location been inspected by supplier of profied sheet? (by supplier of peoples sheets or their representatives agents).",
          condition2:
            'Steel roof truss/framing is "Enclosed within the building envelope".',
          condition3:
            "Host dwelling being located more than 300 meters from breaking surf or more than 100 meters from Still water.",
          condition4:
            "Steel roof truss / framing being installed in accordance with manufacturer's published recommendations.",
          condition5:
            "Installation of a damp proof course between bottom plates and concrete slabs were used.",
          condition6:
            "Installation of appropriate flashings and other water proofing treatments of any penetrations to eliminate wate ingress.",
          condition7:
            "Installation of fasteners which comply with relevant requirement prescribed in AS3588 and other applicable standards.",
          condition8:
            "No physical contact between incompatible materials (e.g. copper pipes or treated timer) in contact With steel roof truss / framing.",
          condition9:
            "Steel roof truss / framing is cleaned of swarf and other foreign materials (e.g. mortar) before and after site assembling.",
          condition10:
            "No mechanical or chemical damages on steel roof truss / framing coating before and or after site assembling.",
          condition11:
            "Is the surrounding environment and installation condition as per normal condition stipulated in the warranty T&C?",
          condition12_1: "Project is located",
          condition12_2: "from Calm marine influence.",
          condition13_1: "Project is located",
          condition13_2: "from Surf marine influence.",
          condition14_1: "Project is located",
          condition14_2: "from severe industry influence.",
          condition15_1: "Project is located",
          condition15_2: "from light industry influence.",
          condition16:
            "Give full details of any other aggressive of unusual factors considered to influence warranty and state distance away in km:",
          condition17:
            "Observed performance of similar products and application in the area of describe activities within the building:",
        },
        coolRoomPanelConditions: {
          condition1:
            "Has materials & location been Inspected by supplier of profied sheet?",
          condition2:
            "The product was installed in accordance with the manufacturers published fixing Recommendations by BlueScope.",
          condition3: "Base metal thickness of 0.4mm (or greater).",
          condition4: "Sandwich panel of normal cool room conditions.",
          condition5:
            "Only neutral cure sealants are to be applied to panel joints.",
          condition6:
            "All fasteners used in conjunction are to te in accordance with BlueScope recommendations.",
          condition7:
            "All flashing and associated components used are manufactured from compatible materials (Clean COLORBOND© steel zinc, or aluminium) accordance with Bluescope recommendations.",
          condition8:
            "No physical contact between incompatible materials (e.g. stainless steel, copper pipes, treates timber).",
          condition9:
            "No mechanical or chemical damages on panel before and/or after site assembling.",
          condition10: "All areas were kept washed and clean.",
          condition11_1: "Project is located",
          condition11_2: "from Calm marine influence",
          condition12_1: "Project is located",
          condition12_2: "from Surf marine influence",
          condition13_1: "Project is located",
          condition13_2: "from severe industry influence",
          condition14_1: "Project is located",
          condition14_2: "from light industry influence",
          condition15:
            "Give full details of any other aggressive of unusual factors considered to influence warranty and state distance away in km:",
          condition16:
            "Observed performance of similar products and application in the area of describe activities within the building:",
          condition17_1: "Roof Lined With",
          condition17_2: "Insulation",
          condition17_3: "Please choose additional options",
          condition17_4: "PU",
          condition17_5: "PIR",
          condition17_6: "PE",
          condition17_7: "PS",
          condition17_8: "EPS",
          condition17_9: "Celling",
          condition17_10: "Vapour Barrier",
          condition17_11: "Nothing",
          condition17_12: "Other",
        },
        airDuctingConditions: {
          condition1:
            "Has materials & location been inspected by supplier of profied sheet? (by supplier of peoples sheets or their representatives agents).",
          condition2:
            'Steel roof truss/framing is "Enclosed within the building envelope".',
          condition3:
            "Host dwelling being located more than 300 meters from breaking surf or more than 100 meters from Still water.",
          condition4:
            "Steel roof truss / framing being installed in accordance with manufacturer's published recommendations.",
          condition5:
            "Installation of a damp proof course between bottom plates and concrete slabs were used.",
          condition6:
            "Installation of appropriate flashings and other water proofing treatments of any penetrations to eliminate wate ingress.",
          condition7:
            "Installation of fasteners which comply with relevant requirement prescribed in AS3588 and other applicable standards.",
          condition8:
            "No physical contact between incompatible materials (e.g. copper pipes or treated timer) in contact With steel roof truss / framing.",
          condition9:
            "Steel roof truss / framing is cleaned of swarf and other foreign materials (e.g. mortar) before and after site assembling.",
          condition10:
            "No mechanical or chemical damages on steel roof truss / framing coating before and or after site assembling.",
          condition11:
            "Is the surrounding environment and installation condition as per normal condition stipulated in the warranty T&C?",
          condition12_1: "Project is located",
          condition12_2: "from Calm marine influence.",
          condition13_1: "Project is located",
          condition13_2: "from Surf marine influence.",
          condition14_1: "Project is located",
          condition14_2: "from severe industry influence.",
          condition15_1: "Project is located",
          condition15_2: "from light industry influence.",
          condition16:
            "Give full details of any other aggressive of unusual factors considered to influence warranty and state distance away in km:",
          condition17:
            "Observed performance of similar products and application in the area of describe activities within the building:",
        },
      },
      manage: {
        title: "Product Warranty",
        or: "or",
      },
      environment: {
        title: "Environment Detail",
        hint: "Please select an environment for each product.",
      },
      documentary: {
        title: {
          selectDocumentary: "Select Documentary",
          specifyCompanyName: "Specify Company Name",
          confirmPeviousStep: "Confirm Proceed to The Previous Step",
          confirmCompanyName: "Confirm Company Name",
          productWarrantyAndDocumentary: "Product Warranty & Documentary",
          previewDocument: "Preview Document",
        },
        label: {
          specifyCompanyNameInDocument: "Specify company name in document",
        },
        placeholder: {
          typeCompanyName: "Type Company Name",
        },
        message: {
          confirmPeviousStep: "Do you proceed to the previous step ?",
          warningPeviousStep: "Your documentary information will be lost.",
          confirmCompanyName:
            "Please confirm specify company name to use in document.",
          warningCompanyName1: "After a complete save,",
          warningCompanyName2: "you won't be able to change or re-generate it.",
        },
      },
    },
  },
  warrantyStatus: {
    warrantyCertificateChecker: "WARRANTY CERTIFICATE CHECKER",
    warrantyNo: "Warranty No.",
    projectName: "Project Name",
    projectAddress: "Project Address (installation address)",
    warrantyStatusChecker: "WARRANTY STATUS CHECKER",
    certificateStatusChecker: "CERTIFICATE STATUS CHECKER",
    projectAddressShort: "Project Address",
    certificateNo: "Certificate no.",
    locationAccount: "Location account",
    phoneNo: "Phone no.",
    coilNo: " Coil no.",
    coilDescription: "Coil description",
    quantity: "Quantity",
    productGroup: "Product group",
    usability: 'Application',
    metre: 'meter',
    roof: 'Roof',
    wall: 'Wall',
    purlinGirt: 'Purlin and Girt',
    truFrame: 'Truss and Frame',
    coolRoom: 'Cool Room Panel',
    decking: 'Decking',
    airDucting: 'Air Ducting',
    length: 'Length',
  },
  documentary: {
    breadcrumb1: "Home",
    breadcrumb2: "Documentary",
    title: "Documentary",
    placeholder: {
      search: "Search",
      selectStartDate: "Select Start Date",
      selectEndDate: "Select End Date",
    },
    label: {
      date: "Date",
      to: "to",
      selectedTransaction: "Selected Transaction",
      ready: "Ready",
    },
    selectOption: {
      all: "All",
      salesTransactionID: "Sales Transaction ID",
      documentID: "Document ID",
      contractName: "Contract Name",
      warrantyNo: "Warranty No.",
      projectName: "Project Name",
      phoneNumber: "Phone Number",
    },
    table: {
      header: {
        select: "Select",
        createDate: "Create Date",
        warrantyNo: "Warranty No.",
        salesTId: "Sales T. ID",
        salesTransactionId: "Sales Transaction ID",
        projectName: "Project Name",
        customerName: "Customer Name",
        productGroup: "Product Group",
        mobileNo: "Mobile No.",
        status: "Status",
        action: "Action",
      },
    },
    message: {
      noResult: "No Result",
    },
  },
  documentTemplate: {
    breadcrumb1: "Home",
    breadcrumb2: "Warranty Management ",
    breadcrumb3: "Document Template ",
    title: "Document template",
    placeholderSearch: "Search document category code or name",
    button: {
      buttonSearch: "Search",
      buttonClose: "Close",
    },
    table: {
      header: {
        category: "Categoty",
        code: "Code",
        name: "Name",
        m3ProductGroup: "M3 Product Group",
        view: "View",
      },
    },
    modal: {
      header: {
        title: "M3 Product Group Mapping : ",
        productCertificate: "Certificate : [documentName]",
        productDocumentary: "Documentary : [documentName]",
        documentary: "Documentary",
      },
      placeholderSearchM3: "Search M3 Product Group Code or Name",
      table: {
        header: {
          no: "No.",
          m3ProductGroupCode: "M3 Product Group Code",
          m3ProductGroupName: "M3 Product Group Name",
        },
        label: {
          noM3ProductGroup: "No M3 Product Group.",
        },
      },
    },
  },
  documentaryManage: {
    breadcrumb1: "Home",
    breadcrumb2: "Documentary",
    breadcrumb3: "View Document",
    breadcrumb4: "Generate Documentary",
    title: "Documentary",
    titleGenerate: "Documentary > Generate Documentary",
    sectionTitle: {
      productWarrantyDocumentary: "Product Warranty & Documentary",
      documentary: "Documentary",
    },
    placeholder: {
      search: "Search document id or name",
    },
    label: {
      information: "Information",
      documentary: "Documentary",
    },
    table: {
      header: {
        document: "Document",
        specifyCompanyName: "Specify Company Name",
        createDate: "Create Date",
        action: "Action",
      },
    },
    message: {
      noDocument: "No Document",
    },
  },
  rewards: {
    breadcrumb1: "Home",
    breadcrumb2: "Rewards",
    title: "Rewards",
    subtitle: "Rewards Indexes",
    page: {
      goToPage: "Go to page",
      redemption: "Redemption",
      rewardRedemptionHistory: "Reward Redemption History",
      rewardStock: "Reward Stock",
      trueBluePointsDetail: "TrueBlue Points Detail",
    },
  },
  redemption: {
    breadcrumb1: "Home",
    breadcrumb2: "Rewards",
    breadcrumb3: "Redemption",
    title: "Rewards > Redemption",
    form: {
      customerInfo: {
        title: "Customer Info",
        customerBlank: "Select or create members to redemption.",
        buttonSelectCustomer: "Select Customer",
      },
    },
  },
  redemptionReward: {
    breadcrumb1: "Home",
    breadcrumb2: "Rewards",
    breadcrumb3: "Redemption",
    breadcrumb4: "List of Rewards",
    title: "Rewards > Redemption > List of Rewards",
    placeholder: "Search by ID, Name of Rewards",
    buttonSearch: "Search",
    table: {
      header: {
        id: "ID",
        rewards: "Rewards",
        inStock: "In-Stock",
        rewardPoint: "Trueblue Points",
        unit: "Unit",
        trueBluePoints: "Trueblue Points",
        usedPoints: "Used the TrueBlue Points",
      },
      footer: {
        usedPoints: "Used the TrueBlue Points",
        point: "Point",
        yourTotalPoints: "Your Total TrueBlue Points",
      },
    },
    informations: {
      yourTotalTrueBluePoints: "Your Total TrueBlue Points",
      usedtheTrueBluePoints: "Used the TrueBlue Points",
      points: "Point(s)",
      orderBy: "Order by",
      selectOrderBy: "Select order by",
    },
  },
  redemptionRewardHistory: {
    breadcrumb1: "Home",
    breadcrumb2: "Rewards",
    breadcrumb3: "Reward Redemption History",
    title: "Rewards > Reward Redemption History",
    selectOption: {
      mostExchangeReward: "Most Exchange Reward",
      mostExchangeCustomer: "Most Exchange Customer",
      redemptionCode: "Redemption Code",
      customerName: "Customer Name",
      phoneNo: "Phone No.",
    },
    placeholder: "Search",
    date: "Date",
    dateTo: "to",
    selectStartDate: "Select Start Date",
    selectEndDate: "Select End Date",
    buttonSearch: "Search",
  },
  rewardStock: {
    breadcrumb1: "Home",
    breadcrumb2: "Rewards",
    breadcrumb3: "Redemption",
    breadcrumb4: "Reward Stock",
    title: "Rewards > Reward Stock",
    placeholder: "Search by ID, Name of Rewards",
    buttonSearch: "Search",
  },
  pointDetail: {
    breadcrumb1: "Home",
    breadcrumb2: "Rewards",
    breadcrumb3: "TrueBlue Points Detail",
    title: "Rewards > TrueBlue Points Detail",
    placeholder: "Search by ID, Name, Phone No., Email",
    buttonSearch: "Search",
  },
  pointDetailManage: {
    breadcrumb1: "Home",
    breadcrumb2: "Rewards",
    breadcrumb3: "TrueBlue Points Detail",
    title: "Rewards > TrueBlue Points Detail",
    purchaseHistoryTitle: "Purchase History",
  },
  inventoryIndexes: {
    breadcrumb1: "Home",
    breadcrumb2: "Inventory & Coil Transfer",
    title: "Inventory",
    subtitle: "Inventory Indexes",
    page: {
      goToPage: "Go to page",
      inventoryManagement: "Inventory",
      coilTransfer: "Sell coil out",
      coilTransferHistory: "Coil Transfer History",
      profileTransfer: "Profile Transfer",
      profileTransferHistory: "Profile Transfer History",
      profileMapping: "Profile Mapping",
      profileMappingHistory: "Profile Mapping History",
    },
  },
  inventory: {
    breadcrumb1: "Home",
    breadcrumb2: "Inventory & Coil Transfer",
    breadcrumb3: "Products Quantity",
    title: "Inventory > Products Quantity",
    placeholder: "Search Product Group",
    buttonSearch: "Search",
  },
  productDetail: {
    breadcrumb1: "Home",
    breadcrumb2: "Inventory & Coil Transfer",
    breadcrumb3: "Products Quantity",
    breadcrumb4: "Products Detail",
    title: "Inventory > Products Quantity > Product Detail",
    placeholder: "Search Coil Name/ID, Color",
    placeholderForToko: "Search Profile Type/Product Group",
    buttonSearch: "Search",
    table: {
      header: {
        coilName: "Coil Name",
        coilID: "Coil ID",
        thickness: "Thickness",
        thicknessBMT: "Thickness (BMT)",
        createDate: "Create Date",
        lifetime: "Lifetime",
        color: "Color",
        weightBalance: "Weight Balance (Kg.)",
        weightBalanceAbbreviation: "Weight Bal. (Kg.)",
        lengthBalance: "Length Balance (m.)",
        lengthBalanceAbbreviation: "Length Bal. (m.)",
        action: "Action",
        profileType: "Profile Type",
        brandProductGroup: "Product Group",
        quantity: "Quantity",
        length: "Length (m. per unit)",
        description: "Description",
      },
      action: {
        buttonEditLength: "Edit Length",
      },
    },
  },
  productDetailManage: {
    breadcrumb1: "Home",
    breadcrumb2: "Inventory & Coil Transfer",
    breadcrumb3: "Products Quantity",
    breadcrumb4: "Products Detail",
    breadcrumb5: "Inventory & Stock Adjustment",
    title:
      "Inventory > Products Quantity > Product Detail > Inventory & Stock Adjustment",
    form: {
      coilInformation: {
        title: "Coil Information",
        coilName: "Coil Name",
        coilID: "Coil ID",
        lengthBalance: "Length Balance (m.)",
        weightBalance: "Weight Balance (Kg.)",
        titleProduct: `Product’s Length Setting`,
        reasonChange: {
          title: "Reason of change",
          cover: "Cover",
          cutOff: "Cut off",
          salesFlat: "Sales flat",
          other: "Other",
        },
        profileType: "Profile Type",
        productGroup: "Product Group",
        quantity: "Quantity",
      },
    },
  },
  profileMapping: {
    breadcrumb1: "Home",
    breadcrumb2: "Inventory & Coil Transfer",
    breadcrumb3: "Profile Mapping",
    breadcrumb4: "Profile Detail",
    title_1:
      "Inventory > Profile Mapping > Select Hardware / Toko Shop / Distributor",
    title_2: "Inventory > Profile Mapping > Product Detail",
    placeholder: "Search by Profile Type/Group",
    buttonSearch: "Search",
    form: {
      shopInfo: {
        title: "Hardware shop / Toko info.",
        shopInfoBlank:
          "Select Hardware / Toko Shop / Distributor for profile mapping.",
        buttonSelect: "Select Hardware / Toko Shop / Distributor",
        message:
          "Coil cannot be used in profile mapping, please select new coil.",
        coilValue: "Plese Check coil width values.",
        table: {
          header: {
            profileType: "Profile Type",
            group: "Brand / Product Group",
            quantity: "Quantity",
            length: "Length (m.)",
            action: "Action",
          },
        },
      },
    },
  },
  coilTransfer: {
    breadcrumb1: "Home",
    breadcrumb2: "Inventory & Coil Transfer",
    breadcrumb3: "Sell coil out",
    title: "Inventory & Coil Transfer > Sell coil out",
    form: {
      adAndToko: {
        title: "Hardware / Toko Shop Info",
        blank:
          "Select or create Hardware / Toko Shop for create coil transfer.",
        buttonCreate: "Request Create Hardware / Toko Shop",
        buttonSelect: "Select Hardware / Toko Shop",
      },
      customer: {
        title: "Customer Info",
        blank:
          "Select or create authorised dealer / roll former for create coil transfer.",
        buttonCreate: "Send request create a new roll former",
        buttonSelect: "Select Roll former who buy coil from you",
      },
    },
  },
  profileTransfer: {
    breadcrumb1: "Home",
    breadcrumb2: "Inventory & Coil Transfer",
    breadcrumb3: "Profile Transfer",
    title: "Inventory & Coil Transfer > Profile Transfer",
    form: {
      adAndToko: {
        title: "Hardware / Toko Shop Info",
        blank:
          "Select or create Hardware / Toko Shop / Distributor for create profile transfer.",
        buttonCreate: "Request Create Hardware / Toko Shop / Distributor",
        buttonSelect: "Select Hardware / Toko Shop / Distributor",
      },
      customer: {
        title: "Customer Info",
        blank:
          "Select or create authorised dealer / roll former for create profile transfer.",
        buttonCreate: "Request Create Authorised Dealer / Roll Former / Toko",
        buttonSelect: "Select Authorised Dealer / Roll Former / Toko",
      },
    },
  },
  coilTransaction: {
    breadcrumb1: "Home",
    breadcrumb2: "Inventory & Coil Transfer",
    breadcrumb3: "Sell coil out",
    breadcrumb4: "Coil Transaction",
    title: "Inventory & Coil Transfer > Sell coil out > Coil Transaction",
    title_2:
      "Inventory & Coil Transfer > Profile Transfer > Profile Transaction",
    form: {
      ad: {
        title: "Authorised Dealer Info",
        buttonSelect: "Select Customers (Roll formers)",
      },
      toko: {
        title: "Toko Info",
      },
      latestCoilTransfer: {
        title: "Latest Coil Transfer",
        table: {
          header: {
            date: "Date",
            orderNo: "Order No.",
            coilName: "Coil Name",
            coilID: "Coil ID",
            transferAmount: "Transfer Amount (m.)",
            weight: "Weight (ton)",
            salesperson: "Salesperson",
            profileType: "Profile Type",
            productGroup: "Product Group",
            quantity: "Quantity",
            length: "Length (m.)",
            lengthPerUnit: "Length (m. per unit)",
            tranferFrom: "Transfer from",
            tranferTo: "Tranfer to",
            status: "Status",
          },
        },
      },
      coilTransferSummary: {
        title: "Coil Transfer Summary",
        blank: "Select the product that you want to include to coil transfer.",
        buttonSelectProduct: "Select Products",
        table: {
          header: {
            coilID: "Coil ID",
            coilName: "Coil Name",
            length: "Length (m.)",
            weight: "Weight (kg.)",
          },
        },
      },
    },
  },
  profileTransaction: {
    breadcrumb1: "Home",
    breadcrumb2: "Inventory & Coil Transfer",
    breadcrumb3: "Profile Transfer",
    breadcrumb4: "Profile Transaction",
    title: "Inventory & Coil Transfer > Profile Transfer > Profile Transaction",
    form: {
      ad: {
        title: "Authorised Dealer Info",
        buttonSelect: "Select Customers (Roll formers)",
      },
      toko: {
        title: "Toko Info",
      },
      latestProfileTransfer: {
        title: "Latest Profile Transfer",
        table: {
          header: {
            date: "Date",
            orderNo: "Order No.",
            coilName: "Coil Name",
            coilID: "Coil ID",
            transferAmount: "Transfer Amount (m.)",
            weight: "Weight (ton)",
            salesperson: "Salesperson",
            profileType: "Profile Type",
            productGroup: "Product Group",
            quantity: "Quantity",
            length: "Length (m.)",
            lengthPerUnit: "Length (m. per unit)",
          },
        },
      },
      profileTransferSummary: {
        title: "Profile Transfer Summary",
        blank: "Select the product that you want to include to coil transfer.",
        buttonSelectProduct: "Select Products",
        table: {
          header: {
            coilID: "Coil ID",
            coilName: "Coil Name",
            quantity: "Quantity",
            length: "Length (m.)",
            weight: "Weight (kg.)",
          },
        },
      },
    },
  },
  coilTransactionManage: {
    breadcrumb1: "Home",
    breadcrumb2: "Inventory & Coil Transfer",
    breadcrumb3: "Sell coil out",
    breadcrumb4: "Coil Transaction",
    title: "Inventory & Coil Transfer > Sell coil out > Coil Transaction",
    placeholder: "Select product group",
    placeholderThickness: "Select thickness",
    placeholderCoilID: "Select Coil ID",
    placeholderColor: "Select Color",
    table: {
      header: {
        coilID: "Coil ID",
        coilName: "Coil Name",
        thickness: "Thickness",
        color: "Color",
        aging: "Aging",
        lengthBalance: "Length Balance (m.)",
        remainedWeight: "Remained Weight (kg)",
        remainedWeightAbbreviation: "Re. Weight (kg)",
        transferAmount: "Transfer Amount (m.)",
        allRemainingLength: "All Remaining Length",
      },
    },
  },
  profileTransactionManage: {
    breadcrumb1: "Home",
    breadcrumb2: "Inventory & Coil Transfer",
    breadcrumb3: "Profile Transfer",
    breadcrumb4: "Profile Transaction",
    title: "Inventory & Coil Transfer > Profile Transfer > Profile Transaction",
    placeholder: "Select product group",
    placeholderThickness: "Select thickness",
    placeholderCoilID: "Select Coil ID",
    placeholderColor: "Select Color",
    table: {
      header: {
        coilID: "Coil ID",
        coilName: "Coil Name",
        profileType: "Profile Type",
        thickness: "Thickness (BMT)",
        color: "Color",
        quantity: "Quantity",
        aging: "Aging",
        lengthBalance: "Length Balance (m.)",
        remainedWeight: "Remained Weight (kg)",
        remainedWeightAbbreviation: "Re. Weight (kg)",
        transferAmount: "Transfer Quantity",
        allRemainingLength: "All Remaining Quantity",
      },
    },
  },
  coilTransferHistory: {
    breadcrumb1: "Home",
    breadcrumb2: "Inventory & Coil Transfer",
    breadcrumb3_1: "Profile Transfer History",
    breadcrumb3_2: "Profile Mapping History",
    breadcrumb3_3: "Profile Sold History",
    breadcrumb3_4: "Coil Transfer History",
    title_1: "Inventory & Coil Transfer > Profile Transfer History",
    title_2: "Inventory & Coil Transfer > Profile Mapping History",
    title_3: "Inventory & Coil Transfer > Profile Sold History",
    title_4: "Inventory & Coil Transfer > Coil Transfer History",
    selectOption: {
      all: "All",
      orderNo: "Order No.",
      transferFrom: "Transfer from",
      transferTo: "Transfer to",
      coilID: "Coil ID",
      pending: "Pending",
      transferred: "Transferred",
      received: "Received",
    },
    placeholder: "Search",
    date: "Date",
    dateTo: "to",
    selectStartDate: "Select Start Date",
    selectEndDate: "Select End Date",
    buttonSearch: "Search",
    label: {
      category: "Category",
      status: "Status",
    },
  },
  profileTransferHistory: {
    breadcrumb1: "Home",
    breadcrumb2: "Inventory & Coil Transfer",
    breadcrumb3_1: "Profile Transfer History",
    breadcrumb3_2: "Profile Mapping History",
    breadcrumb3_3: "Profile Sold History",
    breadcrumb3_4: "Profile Transfer History",
    title_1: "Inventory & Coil Transfer > Profile Transfer History",
    title_2: "Inventory & Coil Transfer > Profile Mapping History",
    title_3: "Inventory & Coil Transfer > Profile Sold History",
    title_4: "Inventory & Coil Transfer > Profile Transfer History",
    selectOption: {
      all: "All",
      orderNo: "Order No.",
      companyName: "Company Name",
      phoneNumber: "Phone Number",
      coilID: "Coil ID",
    },
    placeholder: "Search",
    date: "Date",
    dateTo: "to",
    selectStartDate: "Select Start Date",
    selectEndDate: "Select End Date",
    buttonSearch: "Search",
  },
  profileMappingHistory: {
    breadcrumb1: "Home",
    breadcrumb2: "Inventory & Coil Transfer",
    breadcrumb3: "Profile Mapping History",
    title: "Inventory & Coil Transfer > Profile Mapping History",
    selectOption: {
      all: "All",
      orderNo: "Order No.",
      companyName: "Company Name",
      phoneNumber: "Phone Number",
      profileID: "Profile ID",
    },
    placeholder: "Search",
    date: "Date",
    dateTo: "to",
    selectStartDate: "Select Start Date",
    selectEndDate: "Select End Date",
    buttonSearch: "Search",
  },
  adAndRfManage: {
    breadcrumb1: "Home",
    breadcrumb2: "Inventory & Coil Transfer",
    breadcrumb3: "Profile Mapping",
    breadcrumb4_1: "Request Create Hardware/Toko Shop",
    breadcrumb4_2: "Request Create Authorised Dealer/Roll Former",
    title_1: "Request Create Hardware/Toko Shop",
    title_2: "Request Create Authorised Dealer/Roll Former",
    step: {
      regis: "Register",
    },
    form: {
      regis: {
        title: "Registration",
        customerType: "Customer Type",
        checkbox: {
          rollFormer: "Independent Roll Former",
          authorisedDealer: "Authorized Dealer",
        },
      },
    },
  },
  report: {
    breadcrumb1: "Home",
    breadcrumb2: "Report",
    breadcrumb3: "Sales Report",
    title: "Report > Sales Report",
    date: "Date",
    dateTo: "to",
    selectStartDate: "Select Start Date",
    selectEndDate: "Select End Date",
    buttonSearch: "Search",
    totalSalesReportInformation: "Total Sales Report : Information",
    totalSalesReportTotalLength: "Total Sales Report : Total Length",
    totalSalesReportCustomer: "Total Sales Report : Customer",
    buyIn: "Buy-In",
    sellOut: "Sell-Out",
    mappingIn: "Mapping in",
    mappingOut: "Mapping Out",
    transferIn: "Transfer in",
    transferOut: "Transfer Out",
    averageSales: "Average Sales",
    noOfCustomers: "No. of Customers",
    rewardPoint: "Rewards points",
    warranty: "Warranty",
    revenue: "Revenue",
    point: "Points",
    ready: "Ready",
    notReady: "Not Ready",
    volume: "Volume",
    length: "Length",
    quantity: "Quantity",
    monthly: "Monthly",
    daily: "Daily",
    total: "Total",
    active: "Active",
    form: {
      inOutStock: {
        title: "In-Out Stock Report :",
        sellIn: "Buy in",
        salesOut: "Sell out",
        stock: "Stock",
      },
      inOutTransfer: {
        title: "In-Out Transfer Report :",
        transferIn: "Transfer in",
        transferOut: "Transfer out",
      },
      product: {
        title: "Product",
        salesIn: "Buy in",
        salesOut: "Sell out",
        report: "Report",
      },
      topMember: {
        title: "Top 10 Customers by volume and length",
        subTitle: "Customers by volume and length",
        volume: "Volume",
        length: "Length",
      },
      topSalesPersons: {
        title: "Top 10 Sales Rep by volume and length",
        subTitle: "Sales Rep by volume and length",
        volume: "Volume",
        length: "Length",
      },
      rewardReport: {
        title: "Reward Report",
        subTitle_1: "Most exchange reward",
        subTitle_2: "Most exchange customer",
      },
    },
  },
  settings: {
    breadcrumb1: "Home",
    breadcrumb2: "Settings",
    title: "Settings",
    subtitle: "Settings Indexes",
    page: {
      goToPage: "Go to page",
      adInfo: "AD Information",
      tnc: "Terms and Conditions",
      contactUs: "Contact Us",
      help: "Help",
    },
  },
  adInfo: {
    breadcrumb1: "Home",
    breadcrumb2: "Settings",
    breadcrumb3: "AD Information",
    breadcrumb3_2: "Sales Person",
    title: "Settings > AD Information",
    title_2: "Settings > Sales Person",
    form: {
      info: {
        title: "AD Information",
      },
      changePassword: {
        title: "Change Password",
        changeYourPassword: "Change Your Password",
        passwordPolicies: "Password Policies",
        passwordMinimum_1: "Minimum 8 characters",
        passwordMinimum_2: "At least one character",
        passwordMinimum_3: "At least one number",
      },
    },
  },
  tnc: {
    breadcrumb1: "Home",
    breadcrumb2: "Settings",
    breadcrumb3: "Terms and Conditions",
    title: "Settings > Terms and Conditions",
    form: {
      tnc: {
        title: "TERMS AND CONDITIONS",
      },
    },
  },
  contactUs: {
    breadcrumb1: "Home",
    breadcrumb2: "Settings",
    breadcrumb3: "Contact Us",
    title: "Settings > Contact Us",
    form: {
      contactUs: {
        title: "Contact Us",
        hotline: "Hotline",
        phoneNo: "Phone No.",
        fax: "Fax",
        website: "Website",
      },
    },
  },
  help: {
    breadcrumb1: "Home",
    breadcrumb2: "Settings",
    breadcrumb3: "Help",
    title: "Settings > Help",
    form: {
      contactUs: {
        title: "Contact Us",
        hotline: "Hotline",
        phoneNo: "Phone No.",
        fax: "Fax",
        website: "Website",
      },
    },
    label: {
      coilTranfer: "Coil Transfer",
      stepGuildline: "Steps and Guideline",
    },
    button: {
      buttonSubmit: "Submit",
    },
    tab: {
      title: "Please enter information to report coil transfer issue",
      label: {
        coilId: "Coil ID",
        reportProblem: "Report Problem",
      },
      field: {
        placeholderInsertCoilId: "Insert Coil ID",
        placeholderInsertProblem: "Insert Problem",
      },
    },
  },
  salesPerson: {
    breadcrumb1: "Home",
    breadcrumb2: "Settings",
    breadcrumb3: "Sales Person",
    breadcrumb4: "Add New Sales",
    breadcrumb5: "Edit Sales",
    titles: {
      salesPerson: "Settings > Sales Person",
      addNewSales: "Settings > Sales Person > Add New Sales",
      editSales: "Settings > Sales Person > Edit Sales",
    },
    buttons: {
      addNewSales: "Add New Sales",
      export: "Export File",
    },
    tables: {
      salesPerson: {
        number: "No.",
        name: "Name",
        nickName: "Nick Name",
        jobPosition: "Job Position",
        mobile: "Mobile",
        points: "Points",
        actions: "Actions",
      },
    },
    tooltips: {
      viewInformation: "View Information",
      edit: "Edit",
    },
    options: {
      incentiveSales: "Incentive sales, successful sales",
      disputeClosing: "Dispute closing and closing professionals",
      professionalNegotiating: "Professional negotiating",
      basicKnowledge: "Basic knowledge of products",
      courseForExperts: "A course for experts in the use of products.",
    },
    headings: {
      salesInformation: "Sales Information",
      blueScopeTraining: "BlueScope Training (Maximum 5)",
      salesSkill: "Sales Skills Training Course",
      productKnowledge: "Product Knowledge Skills Training Course",
      specificCourse: "Specific Course",
      independentCourse: "Independent Course",
      maximum5: "(Maximum 5)",
    },
  },
  uploadTransactionIndexes: {
    breadcrumb1: "Home",
    breadcrumb2: "Upload Transaction",
    title: "Upload Transaction",
    subtitle: "Upload Transaction Indexes",
    page: {
      goToPage: "Go to page",
      uploadTransactionManagement: "Upload Transaction Management",
      uploadTransactionHistory: "Upload Transaction History",
    },
  },
  uploadTransaction: {
    breadcrumb1: "Home",
    breadcrumb2: "Upload Transaction",
    breadcrumb3: "Upload Transaction Management",
    title: "Upload Transaction Management",
    step: {
      validation: {
        title: "Validation",
      },
      information: {
        title: "Information",
      },
    },
    form: {
      validation: {
        title: "Validation",
        placeholderUploadTransaction: "Drag & Drop your files or Browse",
        buttonRemoveFile: "Remove file",
        buttonValidateTransaction: "Validate Transaction",
        buttonDownloadTemplate: "Download Template",
        checkingInProcess: "Checking in process",
        formatFileIncorrect:
          "Format file is incorrect. Please upload csv format.",
      },
      information: {
        title: "Information",
        table: {
          header: {
            status: "Status",
            saleperson: "Salesperson",
            phone: "Phone",
            email: "Email",
            coilId: "Coil ID",
            lengthSold: "Length Sold",
            projectName: "Project Name",
            homeowner: "Homeowner",
            purchaseDate: "Purchase Date",
            purchaseValue: "Purchase Value",
            invoiceNumber: "Invoice No.",
            invoiceLine: "Invoice Line",
            error: "Error",
          },
        },
        buttonUploadTransaction: "Upload Transaction",
        uploadingInProcess: "Uploading in process",
        modal: {
          title: "Error Detail",
        },
        uploadTransactionSuccess: "Upload transaction success.",
      },
    },
  },
  uploadTransactionHistory: {
    breadcrumb1: "Home",
    breadcrumb2: "Upload Transaction",
    breadcrumb3: "Upload Transaction History",
    title: "Upload Transaction History",
    form: {
      placeholderSearchFileName: "Search file name",
      placeholderSearchSalesTransaction: "Search Invoice No., Project Name",
      buttonSearch: "Search",
    },
    table: {
      header: {
        id: "ID",
        fileName: "File name",
        uploadedDate: "Uploaded date",
        actions: "Actions",
        buttonDetail: "Detail",
        status: "Status",
        saleperson: "Salesperson",
        phone: "Phone",
        email: "Email",
        coilId: "Coil ID",
        lengthSold: "Length Sold",
        projectName: "Project Name",
        homeowner: "Homeowner",
        purchaseDate: "Purchase Date",
        purchaseValue: "Purchase Value",
        invoiceNumber: "Invoice No.",
        invoiceLine: "Invoice Line",
        error: "Error",
      },
    },
  },
  container: {
    customerContentInfo: {
      title: "Customer Info",
      name: "Name",
      trueBluePoints: "TrueBlue Points",
      point: "Point",
      nickname: "Nickname",
      phoneNo: "Phone No.",
      customerType: "Customer Type",
      buttonSelectCustomer: "Select Customer",
    },
    customerContactInfo: {
      title: "Contact Information",
    },
    customerPointTable: {
      table: {
        header: {
          id: "ID",
          customerName: "Customer Name",
          phoneNo: "Phone No.",
          email: "Email",
          tbPoints: "TrueBlue Points",
          action: "Action",
        },
        action: {
          buttonViewDetail: "View Detail",
        },
      },
    },
    customerPointDetail: {
      title: "Customer Info",
      name: "Name",
      nickname: "Nickname",
      phoneNo: "Phone No.",
      customerType: "Customer Type",
      currentTrueBluePoints: "Current TrueBlue Points",
      level: "Level",
      point: "Point",
      expiringDate: "Expiring Date",
      tbPoints: "Expiring Points",
      trained: "Trained",
    },
    latestSaleTransaction: {
      titleSaleTransaction: "Latest Sale Transaction",
      titleOrder: "Order Summary",
      contentBlank:
        "Select the product that you want to include to sales orders.",
      buttonSelectProduct: "Select Products",
      hintDeleteTransaction:
        "Transaction can be deleted within 7 days after created. Any action will be done after the period, please contact Bluescope.",
      table: {
        header: {
          date: "Date",
          saleTransactionID: "Sale Transaction ID",
          coilName: "Coil Name",
          coilID: "Coil ID",
          order: "Order (m.)",
          trueBluePoints: "TrueBlue Points",
          salesperson: "Salesperson",
          profileType: "Profile Type",
          productGroup: "Product group",
          length: "Length",
          orderQuantity: "Order (Quantity)",
        },
      },
    },
    preTransactionList: {
      titlePreTransactionList:
        "Pre-Transaction List (To manage Pre-Transaction to Sales Order please select Product group item.)",
      table: {
        header: {
          date: "Date",
          preTransactionID: "Pre-Transaction ID",
          productGroup: "Product group",
          projectName: "Project Name",
          companyName: "Company Name",
          salesOrder: "Sales Orders",
          action: "Action",
        },
      },
    },
    orderSummary: {
      table: {
        header: {
          coilID: "Coil ID",
          coilName: "Coil Name",
          order: "Order (m.)",
          earnPoints: "Earn Points",
          profileType: "Profile Type",
          productGroup: "Product group",
          length: "Length",
          orderQuantity: "Order (Quantity)",
          revenue: "Revenue",
        },
        footer: {
          revenue: "Revenue",
          summaryGetPoints: "Summary Get the TrueBlue Points",
          point: "Point",
          yourTotalPoints: "Your Total TrueBlue Points",
        },
      },
    },
    orderProduct: {
      title: "Sales Transaction > Sales Orders",
      selectProductGroup: "Select Product Group",
      selectThickness: "Select Thickness",
      selectCoilID: "Select Coil ID",
      selectColor: "Select Color",
      selectOption: {
        all: "All",
      },
      selectProfileType: "Select Profile Type",
      selectLength: "Select Length",
      table: {
        header: {
          coilID: "Coil ID",
          coilName: "Coil Name",
          thickness: "Thickness (APT or TCT)",
          thicknessBMT: "Thickness (BMT)",
          thicknessVt: "Thickness",
          color: "Color",
          aging: "Aging",
          lengthBalance: "Length Balance (m.)",
          remainedWeight: "Remained Weight (kg)",
          length: "Length (m.)",
          weight: "Weight (kg.)",
          orders: "Orders (m.)",
          profileType: "Profile Type",
          productGroup: "Product group",
          quantity: "Quantity",
          orderQuantity: "Order (Quantity)",
          revenue: "Revenue",
        },
      },
    },
    saleTransactionHistory: {
      hintDeleteTransaction:
        "Transaction can be deleted within 7 days after created. Any action will be done after the period, please contact Bluescope.",
      table: {
        header: {
          date: "Date",
          stID: "Sales Transaction ID",
          stIDAbbreviation: "ST ID",
          coilName: "Coil Name",
          coilID: "Coil ID",
          customerName: "Customer Name",
          customerNameAbbreviation: "C. Name",
          phoneNo: "Phone No.",
          order: "Order (m.)",
          purchaseValue: "Purchase value",
          purchaseValueAbbreviation: "P. value",
          tbPoints: "TrueBlue Points",
          tbPointsAbbreviation: "TB Points",
          numberOfRewards: "Number of Rewards",
          numberOfRewardsAbbreviation: "N.O.R.",
          salesperson: "Salesperson",
          action: "Action",
          profileTypeAbbreviation: "PF Type",
          productGroupAbbreviation: "PD Group",
          profileType: "Profile Type",
          productGroup: "Product Group",
          length: "Length",
          orderQuantity: "Order (Quantity)",
          invoiceNumber: "Invoice No.",
          invoiceLineItem: "Invoice Line Item",
          revenue: "Revenue",
          remainingLength: "Remaining Length",
          pickQuantity: "Pick Quantity",
        },
        action: {
          buttonDelete: "Delete",
        },
      },
    },
    warrantyList: {
      tableWarranty: {
        header: {
          select: "Select",
          createDate: "Create Date",
          salesTransactionID: "Sales Transaction ID",
          salesTransactionIDAbbreviation: "Sales T. ID",
          contactName: "Contact Name",
          customerName: "Customer Name",
          warrantyNo: "Warranty No.",
          projectName: "Project Name",
          mobileNo: "Mobile No.",
          status: "Status",
          action: "Action",
          invoiceNumber: "Invoice No.",
          invoiceLineItem: "Invoice Line Item",
          revenue: "Revenue",
          purchaseDate: "Purchase Date",
        },
        action: {
          viewMore: "View more",
        },
      },
      tableWarrantyDetail: {
        header: {
          coilID: "Coil ID",
          orderDate: "Order Date",
          orderNo: "Order No.",
          coilName: "Coil Name",
          productGroup: "Product Group",
          order: "Order (m.)",
          action: "Action",
        },
        action: {
          viewProjectDetail: "View project detail",
          buttonViewWarranty: "View Warranty",
          buttonEditWarranty: "Edit Warranty",
        },
      },
    },
    warrantyTemplate: {
      breadcrumb1: "Home",
      breadcrumb2: "Template",
      title: "Template",
      noWarrantyTemplate: "No Warranty Template",
      noM3ProductCode: "No M3 Product Code",
      noM3ProductGroup: "No M3 Product Group",
      noM3ProductColor: "No M3 Product Color",
      filter: {
        label: {
          m3ProductCode: "M3 Product Code",
          m3ProductGroup: "M3 Product Group",
          m3ProductColor: "M3 Product Color",
          searchTemplateName: "Search Template Name",
        },
      },
      table: {
        header: {
          no: "No.",
          templateName: "Template Name",
          m3ProductCode: "M3 Product Code",
          m3ProductGroup: "M3 Product Group",
          m3ProductColor: "M3 Product Color",
          action: "Action",
        },
        body: {
          codes: "Code(s)",
          groups: "Group(s)",
          colors: "Color(s)",
        },
      },
      placeholder: {
        selectM3ProductCode: "Select Code",
        selectM3ProductGroup: "Select Group",
        selectM3ProductColor: "Select Color",
        searchM3ProductCode: "Search M3 Product Code",
        searchM3ProductGroup: "Search M3 Product Group",
        searchM3ProductColor: "Search M3 Product Color",
        searchTemplateName: "Search Template Name",
      },
      modal: {
        title: {
          m3ProductCode: "M3 Product Code",
          m3ProductGroup: "M3 Product Group",
          m3ProductColor: "M3 Product Color",
        },
        tabs: {
          project: "Project",
          residential: "Residential",
          templateEn: "Template EN",
          templateTh: "Template TH",
        },
      },
    },
    redemptionRewardHistory: {
      table: {
        header: {
          redID: "Red. ID",
          redemptionID: "Redemption ID",
          rewards: "Rewards",
          customerID: "Customer ID",
          cusID: "Cus. ID",
          customerName: "Customer Name",
          cusName: "Cus. Name",
          phoneNo: "Phone No.",
          salesperson: "Salesperson",
          createDate: "Create Date",
          giftDate: "Gift pick-up date",
          gpuDate: "Pick-up date",
          deliveryDate: "Delivery Date",
          tbPoints: "Used Points",
          tbPointsAbbreviation: "Used Points",
          numberOfRewards: "Number of Rewards",
          numberOfRewardsAbbreviation: "N.O.R.",
          stockType: "Stock Type",
          action: "Action",
        },
        action: {
          buttonEdit: "Edit",
        },
      },
    },
    rewardStock: {
      table: {
        header: {
          id: "ID",
          rewards: "Rewards",
          inStock: "In-Stock",
          rewardPoint: "Trueblue Points",
        },
      },
    },
    purchaseHistoryList: {
      table: {
        header: {
          salesDate: "Sales Date",
          salesTransaction: "T1 Sales Transaction",
          coilDetail: "Coil Detail",
          coilNumber: "Coil Number",
          purchaseLength: "Purchase Length(m)",
          tbPoints: "TrueBlue Points",
          salesRepresentative: "Sales Representative",
        },
      },
    },
    productQuantityList: {
      table: {
        header: {
          productGroup: "Product Group",
          noCoils: "No. of Coils",
          weight: "Weight (Kg.)",
          length: "Length (m.)",
        },
        footer: {
          total: "Total",
          buttonViewDetail: "View Detail",
        },
      },
    },
    coilTransferTable: {
      table: {
        header: {
          id: "ID",
          adRF: "AD/RF",
          phoneNo: "Phone No.",
          email: "Email",
          type: "Type",
          province: "Province",
          select: "Select",
        },
      },
    },
    profileTransferTable: {
      table: {
        header: {
          id: "ID",
          adRF: "AD/RF",
          phoneNo: "Phone No.",
          email: "Email",
          type: "Type",
          province: "Province",
          select: "Select",
        },
      },
    },
    coilTransferAdInfo: {
      companyName: "Company Name",
      customerType: "Customer Type",
      phoneNo: "Phone No.",
    },
    profileTransferAdInfo: {
      companyName: "Company Name",
      customerType: "Customer Type",
      phoneNo: "Phone No.",
    },
    coilTransferHistoryTable: {
      table: {
        header: {
          date: "Date",
          coilID: "Coil ID",
          coilName: "Coil Name",
          companyName: "Company Name",
          profile: "Profile",
          profileID: "Profile Transfer ID",
          quantity: "Quantity (no. of sheets or no. of sticks) – pcs",
          quantityAbbreviation: "Quantity ... pcs",
          length: "Length per sheet or per stick – m",
          lengthAbbreviation: "Length ... m",
          totalLength: "Total length (m)",
          orderNo: "Order No.",
          phoneNo: "Phone No.",
          transferAmount: "Transfer Amount (m.)",
          lengthFullText: "Length (m.)",
          weight: "Weight (kg.)",
          contactName: "Contact Name",
          quantityUnit: "Quantity / Unit",
          lengthUnit: "Length / Unit",
          tranferFrom: "Transfer from",
          tranferTo: "Tranfer to",
          status: "Status",
        },
      },
    },
    profileTransferHistoryTable: {
      table: {
        header: {
          date: "Date",
          coilID: "Coil ID",
          coilName: "Coil Name",
          companyName: "Company Name",
          profile: "Profile",
          quantity: "Quantity (no. of sheets or no. of sticks) – pcs",
          quantityAbbreviation: "Quantity ... pcs",
          length: "Length per sheet or per stick – m",
          lengthAbbreviation: "Length ... m",
          totalLength: "Total length (m)",
          orderNo: "Order No.",
          phoneNo: "Phone No.",
          transferAmount: "Transfer Amount (m.)",
          lengthFullText: "Length (m.)",
          weight: "Weight (kg.)",
          contactName: "Contact Name",
          quantityUnit: "Quantity / Unit",
          lengthUnit: "Length / Unit",
          quantityProfile: "Quantity",
          lengthProfile: "Length",
        },
      },
    },
    cmsUserManual: {
      title: "Steps and Guideline",
    },
  },
  component: {
    section: {
      coilDetail: {
        title: "Product Detail",
        roofAndWall: "Roof and Wall - Sq.m :",
        purlin: "Purlin & Girt - m. :",
        truss: "Truss & Frame - m. :",
        coolRoom: "Cool room panel - Sq.m :",
        decking: "Decking - Sq.m :",
        mmx: "MMx :",
        mmsdf: "MMsdf :",
        mmdf: "MMdf :",
      },
    },
  },
  modal: {
    newsAndPromotion: {
      buttonBack: "Back",
    },
    info: {
      notice: "Notice",
    },
    customerProfile: {
      title: "Customer Profile",
      placeholder: "Search by ID, Name, Phone no., Email",
      buttonSearch: "Search",
      buttonCancel: "Cancel",
      buttonSave: "SAVE",
      buttonSelect: "Select",
      table: {
        header: {
          id: "ID",
          name: "Name",
          phoneNo: "Phone No.",
          email: "Email",
          points: "TrueBlue Points",
          select: "Select",
        },
        action: {
          details: "Details",
        },
      },
    },
    confirm: {
      title: "Confirm",
    },
    confirmDelete: {
      title: "Confirm Delete",
    },
    warrantyChooseCategory: {
      title: "Choose category",
      residential: "Residential",
      nonResidential: "Project",
      residentialExample: "e.g. Detached House, Condo, etc.",
      nonResidentialExample1: "e.g. Factory, Warehouse, Malls, etc.",
      nonResidentialExample2: "including projects that are allocated villages.",
    },
    share: {
      title: "Social share",
    },
    selectDateTime: {
      title: "Edit gift pick-up date",
      placeholder: "Select gift pick-up date",
    },
    hardwareAndTokoShop: {
      title: "Hardware / Toko Shop List",
      placeholder: "Search by ID, Hardware/Toko Shop Name, Phone no., Email",
      buttonSearch: "Search",
      table: {
        header: {
          id: "ID",
          name: "Hardware / Toko Shop Name",
          phoneNo: "Phone No.",
          email: "Email",
          select: "Select",
        },
      },
    },
    adAndRf: {
      title: "AD/RF List",
      placeholder: "Search by ID, AD/RF, Phone no., Email",
      buttonSearch: "Search",
    },
    update: {
      title: "Application Notice",
      message: "Trueblue new version is available.",
      buttonCancel: "Cancel",
      buttonSubmit: "Update",
    },
    termsAndConditions: {
      title: "Terms and Conditions for creating warranty",
      subTitle: "Terms and Conditions",
      condition: "Accept Terms and Conditions",
    },
    createMultipleWarranty: {
      title: "Create Multiple Warranty",
      placeholder: "Search by Contractor Name",
      step1: {
        title: "Contractor",
        description: "Input coil length to contractor for generate warranty.",
      },
      step2: {
        title: "Confirm",
        description: "Confirm to generate warranty.",
        remainingCoilLength: "Remaining Coil Length",
      },
      label: {
        invoiceId: "Invoice no.",
        salesOrder: "Sales Order",
        coilId: "Coil ID",
        totalLength: "Total Length",
        enterLength: "Enter Length",
        errorLengthMessage: "Coli length can’t more than total length.",
        coilLength: "Coil Length",
        waitingForGenerating:
          "Your warranty are waiting for generating process.",
        success: "Success Generating Warranty",
      },
      table: {
        header: {
          id: "No.",
          contractorName: "Contractor Name",
          coliLength: "Coli Length",
        },
      },
      button: {
        search: "Search",
        close: "Close",
        back: "Back",
        continue: "Continue",
        generateWarranty: "Generate Warranty",
        goToWarranty: "Go to Warranty",
      },
    },
    saleOrder: {
      table: {
        header: {
          saleTransactionId: "Sales Transaction ID",
          coilName: "Coil Name",
          coilId: "Coil ID",
          order: "Order (m.)",
          linkPreTransactionId: "Link Pre-Transaction ID",
          action: "Action",
          linkProject: "Link Project",
        },
      },
      label: {
        noSaleTransactionIdForLinkToProject:
          " No Sales Transaction ID for Link to Project",
        selectProject: "Select Project",
      },
    },
  },
  pagination: {
    page: "Page",
    previous: "Previous",
    next: "Next",
  },
  field: {
    buttonAccept: "Accept",
    buttonPrintProductUpdateImage: "Print product update image",
    buttonRequestToEdit: "Request to edit",
    buttonProjectDocument: "Project document",
    buttonDelete: "Delete",
    buttonLinkPreTransactionId: "Link Pre-Transaction ID",
    buttonGenerateWarrantyFromCurrentSaleTransaction:
      "Generate Warranty from Current Sales Transaction",
    buttonNewScan: "New Scan",
    buttonExport: "Export",
    buttonPreview: "Preview",
    buttonBack: "Back",
    buttonNext: "Next",
    buttonNextStep: "Next",
    buttonEdit: "Edit",
    buttonEditProfile: "Edit Profile",
    buttonShare: "Share",
    buttonDownload: "Download",
    buttonCancel: "Cancel",
    buttonSave: "SAVE",
    buttonSaveCapitalize: "Save",
    buttonSelect: "Select",
    buttonSubmit: "SUBMIT",
    buttonConfirm: "Confirm",
    buttonContinue: "Continue",
    buttonSaveAndContinue: "SAVE and Continue",
    buttonSaveAndSendOrders: "SAVE and Send Orders",
    buttonSaveAndTransfer: "SAVE and Transfer",
    buttonConfirmAndSAVE: "Confirm and SAVE",
    buttonSaveAsDraft: "Save as Draft",
    buttonChangePassword: "Change Password",
    buttonUpdate: "Update",
    buttonClose: "Close",
    buttonInputQuantity: "Input Quantity",
    buttonSend: "Send",
    buttonLogin: "Log in",
    buttonAcceptTermsAndConditions: "Accept Terms and Conditions",
    buttonToLogin: "Back to Login",
    buttonApprove: "Approve",
    buttonReSendAuthenticationLink: "Re-Send Authentication Link",
    buttonRequireDocumentary: "Require Documentary",
    buttonSearch: "Search",
    buttonGenerateDocumentary: "Generate Documentary",
    buttonViewDocument: "View Document",
    imageUpload: "Image Upload",
    phoneNo: "Phone No.",
    phoneNoPlaceholder: "Insert Phone No.",
    mobileNo: "Phone No.",
    mobileNoPlaceholder: "Insert Phone No.",
    phoneNoHint: "Please fill phone number without prefix (0).",
    fullname: "Full Name",
    fullnamePlaceholder: "Insert Full Name",
    companyname: "Company or Project Name",
    companynamePlaceholder: "Insert Company or Project Name",
    typeProject: "Type Project",
    typeProjectPlaceholder: "Insert Type Project",
    firstname: "First Name",
    firstnamePlaceholder: "Insert First Name",
    lastname: "Last Name",
    lastnamePlaceholder: "Insert Last Name",
    nickname: "Nickname",
    nicknamePlaceholder: "Insert Nickname",
    dateOfBirth: "Date of Birth",
    dateOfBirthPlaceholder: "Select Date of Birth",
    email: "Email",
    emailPlaceholder: "Insert Email",
    sms: "SMS",
    company: "Company",
    companyPlaceholder: "Insert Company",
    idCardNo: "ID Card No.",
    idCardNoPlaceholder: "Insert ID Card No.",
    address: "Address",
    addressPlaceholder: "Insert Address",
    installationAddress: "Installation Address",
    installationAddressPlaceholder: "Insert Installation Address",
    installationAddressHint: "Maximum 500 characters.",
    industrialEstateName: "Industrial Estate Name",
    industrialEstateNamePlaceholder: "Insert Industrial Estate Name",
    warrantyEnvDistanceMarine: "Distance from Marine (m.)",
    warrantyEnvDistanceMarinePlaceholder: "Insert Distance from Marine (m.)",
    warrantyEnvDistanceIndustrial: "Distance from Industrial Estate (m.)",
    warrantyEnvDistanceIndustrialPlaceholder: "Insert Distance from Industrial Estate (m.)",
    province: "Province/State",
    provincePlaceholder: "Insert Province/State",
    provinceTH: "Province/State (จังหวัด)",
    provinceTHPlaceholder: "Insert Province/State (จังหวัด)",
    provinceLA: "Province/State (จังหวัด)",
    provinceLAPlaceholder: "Insert Province/State (จังหวัด)",
    city: "City",
    cityPlaceholder: "Insert City",
    cityTH: "City (อำเภอ)",
    cityTHPlaceholder: "Insert City (อำเภอ)",
    cityLA: "City (อำเภอ)",
    cityLAPlaceholder: "Insert City (อำเภอ)",
    subdistrict: "Subdistrict",
    subdistrictPlaceholder: "Insert Subdistrict",
    subdistrictTH: "Subdistrict (ตำบล)",
    subdistrictTHPlaceholder: "Insert Subdistrict (ตำบล)",
    subdistrictLA: "Subdistrict (ตำบล)",
    subdistrictLAPlaceholder: "Insert Subdistrict (ตำบล)",
    subdistrictIN: "Subdistrict (Kelurahan Bahasa)",
    subdistrictINPlaceholder: "Insert Subdistrict (Kelurahan Bahasa)",
    postalCode: "Postal Code",
    postalCodePlaceholder: "Insert Postal Code",
    district: "District",
    districtPlaceholder: "Insert District",
    districtIN: "District (Kecamatan)",
    districtINPlaceholder: "Insert District (Kecamatan)",
    alley: "Alley/Lane",
    alleyPlaceholder: "Insert Alley/Lane",
    group: "Group/Hamlet",
    groupPlaceholder: "Insert Group/Hamlet",
    street: "Street",
    streetPlaceholder: "Insert Street",
    ward: "Ward",
    wardPlaceholder: "Insert Ward",
    commentRemark: "Comments/Remarks",
    commentRemarkPlaceholder: "Insert Comments/Remarks",
    salesperson: "Salesperson",
    salespersonPlaceholder: "Select Salesperson",
    rmb: "RMB",
    rmbPlaceholder: "Insert Revenue",
    customerProjectName: "Customer / Project Name",
    customerProjectNamePlaceholder: "Insert Customer / Project Name",
    customerProjectNameHint: "Maximum 100 characters.",
    customerProjectNameHint500: "Maximum 500 characters.",
    additionalProjectInformation: "Additional Project Information (Optional)",
    additionalProjectInformationPlaceholder:
      "Insert Additional Project Information",
    additionalProjectInformationHint: "Maximum 500 characters.",
    customerRemark: "Customer Remark",
    customerRemarkExample: "(For example: “Roof in ABC profile - xyz sq.m”)",
    customerRemarkPlaceholder: "Insert Customer Remark",
    customerRemarkHint: "Maximum 500 characters.",
    mobilePhoneNo: "Mobile Phone No.",
    mobilePhoneNoPlaceholder: "Insert Mobile Phone No.",
    mobilePhoneNoHint: "Please fill mobile phone number without prefix (0).",
    purchaseDate: "Purchase Date",
    installationDate: "Installation Date",
    installationDatePlaceholder: "Select Installation Date",
    installationDateHint:
      "Installation date must be within 60 days from the purchase date.",
    inputNumberPlaceholder: "Input Number",
    editLength: "Edit Length (m.) reduce only",
    editLengthPlaceholder: "Insert Edit Length (m.) reduce only",
    editLengthPerUnit: "Edit Length (m. per unit) reduce only",
    editLengthPerUnitPlaceholder:
      "Insert edit length (m. per unit) reduce only",
    editQuantity: "Edit Quantity (Sheet / Stick)",
    editQuantityPlaceholder: "Insert edit quantity (Sheet / Stick)",
    otherReasonsChange: "Other Reasons of Change",
    otherReasonsChangePlaceholder: "Insert Other Reasons of Change",
    adName: "Authorizaed Dealer Name",
    adNamePlaceholder: "Insert Authorizaed Dealer Name",
    businessRegisNo: "Business Registration No.",
    businessRegisNoPlaceholder: "Insert Business Registration No.",
    oldPassword: "Old Password",
    oldPasswordPlaceholder: "Insert Old Password",
    newPassword: "New Password",
    newPasswordPlaceholder: "Insert New Password",
    confirmPassword: "Confirm Password",
    confirmPasswordPlaceholder: "Insert Confirm Password",
    homeOwnerName: "Home owner name ",
    homeOwnerNamePlaceholder: "Insert Home owner name ",
    homeOwnerAddress: "Home owner address",
    homeOwnerAddressPlaceholder: "Insert Home owner address",
    permitLetterNumber: "Permit letter number",
    permitLetterNumberPlaceholder: "Insert Permit letter number",
    buildingType: "Building Type",
    buildingTypePlaceholder: "Insert Building Type",
    AreaM2OfBuilding: "Area (m2) of building",
    AreaM2OfBuildingPlaceholder: "Insert Area (m2) of building",
    tokoName: "Toko Name",
    tokoNamePlaceholder: "Insert Toko Name",
    builderName: "Builder Name",
    builderNamePlaceholder: "Insert Builder Name",
    productWeight: "Product Weight",
    productWeightPlaceholder: "Insert Product Weight",
    tctThickness: "TCT Thickness",
    tctThicknessPlaceholder: "Insert TCT Thickness",
    colorName: "Color Name",
    colorNamePlaceholder: "Insert Color Name",
    productUpdate: "Product Update",
    application: {
      title: "Application",
      walling: "Walling",
      roofing: "Roofing",
      roofFrame: "Roof Frame",
      wallFrame: "Wall Frame",
      awning: "Awning",
    },
    brand: "Brand",
    brandPlaceholder: "Insert Brand",
    installationFinishDate: "Installation Finish Date",
    installationFinishDatePlaceholder: "Insert Installation Finish Date",
    authorizedDealerRubberStamp: "Authorized Dealer rubber stamp",
    authorizedDealerRubberStampPlaceholder:
      "Insert Authorized Dealer rubber stamp",
    originalReceiptInvoice: "Original Receipt / Invoice",
    originalReceiptInvoicePlaceholder: "Insert Original Receipt / Invoice",
    residentialCommercial: "Residential / Commercial",
    residentialCommercialPlaceholder: "Insert Residential / Commercial",
    name: "Name",
    namePlaceholder: "Insert Name",
    postcode: "Postcode",
    postcodePlaceholder: "Insert Postcode",
    typesOfProductPurchased: "Types of product purchased",
    typesOfProductPurchasedPlaceholder: "Insert Types of product purchased",
    purposeOfPurchasedZacs: "Purpose of purchased Zacs",
    purposeOfPurchasedZacsPlaceholder: "Insert Purpose of purchased Zacs",
    dateOfPurchase: "Date of purchase",
    dateOfPurchasePlaceholder: "Insert Date of purchase",
    inoivceNo: "Inoivce No.",
    inoivceNoPlaceholder: "Insert Inoivce No.",
    password: "Password",
    passwordPlaceholder: "Insert Password",
    rememberMe: "Remember Me",
    building: "Building",
    buildingPlaceholder: "Insert Building",
    environment: {
      verySevereMarme: "Very Severe Marme",
      verySevereMarmeDetail:
        "Please refer to BlueScope representatives - Includes off-shores areas and up to 100 m frame ah waterline of areas with breaking surf",
      setereMarine: "Setere Marine",
      setereMarineDetail:
        "Generally between 101 m from the beachfront to approximately 200 m inland. In high wind area, y extend further inland depending on marine prevailing winds and geography of the area (Or 100 m from calm harbour) Characterized by strong salt and haze, salt smearing and salt building up in washed areas of structures. Generally a very noticeable deterioration of most building materials is evident",
      marine: "Marine",
      marineDetail:
        "Generally between 201 m from the beachfront to approximately 400 m inland. In high wind and, may extend further inland depending on prevailing winds and geography of the area (Or 101 - 200 m from calm marine) Characterized by occasionally noticeable slight salt smell and salt building up in unwashed areas of structures",
      moderateMarine: "Moderate Marine",
      moderateMarineDetail:
        "Generally between 401 m and 1000 m from marine surf, although strong prevailing winds may extend this distance. (Or 201m - 1000m from calm marine)",
      beigaRural: "Benign/Rural",
      beigaRuralDetail:
        "Generally greater than 1000 m from marine influence (breaking surf or calm marine)",
      severeIndustrial: "Severe Industrial",
      severeIndustrialDetail:
        "Characterized by fall-out and emissions from stacks, sulfur and acid or alkali smells. Includes only plant building themselves and any building immediately under stacks. Also includes building with high internal humidity and or coRRosion from operations within Generally a very high rate of corrosion in most building structures is evident",
      heavyIndustrial: "Heavy Industrial",
      heavyIndustrialDetail:
        "Typically characterized by less than 500 m fall-out from adjoining severe industrial environments or where small industries lead to significant industrial fall-out Generally includes other service building located near heavy industrial plants, including out-building of the plant itself ",
      industrial: "Industrial",
      industrialDetail:
        "This environment is widespread in industrial urban areas, away from all environments listed above and typically more than 500 m up to 1000m from heavy industrial fall-out, or < 500 m where industries lead to a moderate level of fall-out from small stacks, etc",
      lightIndustrial: "Light Industrial",
      lightIndustrialDetail:
        "This environment is widespread in industrial urban areas, away from all environments listed above and typically more than 1000 m up to 2000 m from heavy industrial fall-out, or between 500 m to 1000 m where small industries lead to a moderate level of fall-out from small stacks, etc",
      trueCoreApplication: "TrueCore Application",
      trueCoreApplicationDetail:
        'Application of this warranty to construction is conditional upon the domestic dwelling and commercial buildings ("the host dwelling) incorporating TRUECORE steel Framing Components, and construction being carried out in accordance with good building practice. Host dwelling being located more than 300 metres from breaking surf or more than 100 metres from still water (All steel building frames shall be enclosed within the building envelope)',
      standard: {
        type: "Standard",
        benignRural: "Benign/Rural",
        benignRuralDescription1: "Generally greater than",
        benignRuralDescription2: "1000m from marine influence",
        benignRuralDescription3: "(breaking surf or calm marine)",
        benignRuralDescription4: "AND / OR",
        benignRuralDescription5: "At least",
        benignRuralDescription6: "2km away from heavy Industrial fall out",
        benignRuralDescription7: "OR",
        benignRuralDescription8: "1km away from small industrial fall out.",
      },
      industrialEnvironment: {
        type: "Industrial Environment",
        lightIndUrban: "Light Industrial/Urban",
        lightIndUrbanDescription1:
          "This environment is widespread in industrial / urban areas, typically more than",
        lightIndUrbanDescription2:
          "1000m up to 2000m from heavy industrial fall- out, or between 500m to 1000m where small industries",
        lightIndUrbanDescription3:
          "lead to a moderate level of fall- out from small stacks, etc.",
        industrial: "Industrial",
        industrialDescription1:
          "This environment is widespread in industrial/urban areas, typically more than",
        industrialDescription2:
          "500m up to 1000m from heavy industrial fall-out, or < 500m where small industries",
        industrialDescription3:
          "lead to a moderate level of fall-out from small stacks, etc.",
        heavyIndustrial: "Heavy Industrial ",
        heavyIndustrialDescription1: "Typically characterized by",
        heavyIndustrialDescription2: "less than 500m",
        heavyIndustrialDescription3:
          "fall-out from adjoining severe industrial environments or where small industries lead to significant industrial fall-out. Generally includes other service building located near heavy industrial plants, including out-building of the plant itself.",
        heavyIndustrialDescription4:
          "In this environment we strongly recommend Clean COLORBOND®ULTRA Steel.",
        severeIndustrial: "Severe Industrial ",
        severeIndustrialDescription1:
          "Characterized by fall-out and emissions from",
        severeIndustrialDescription2:
          "stacks, sulfur and acid or alkali smells.",
        severeIndustrialDescription3:
          "Includes only plant building themselves and any building immediately under stacks. Also includes building with",
        severeIndustrialDescription4:
          "high internal humidity and/ or corrosion from operations within.",
        severeIndustrialDescription5:
          "Generally a very high rate of corrosion in most building structures is evident (Please refer to BlueScope representatives).",
      },
      marineEnvironment: {
        type: "Marine Environment",
        moderateMarine: "Moderate Marine",
        moderateMarineDescription1: "Generally between",
        moderateMarineDescription2: "401m and 1000m from marine surf,",
        moderateMarineDescription3:
          "although strong prevailing winds may extend this distance.",
        moderateMarineDescription4: "(Or 201m – 1000m from calm marine).",
        marine: "Marine",
        marineDescription1: "Generally between",
        marineDescription2:
          "201m from the beachfront to approximately 400m inland.",
        marineDescription3:
          "In high wind areas, may extend further inland depending on prevailing winds and geography of the area. (Or",
        marineDescription4: "101 – 200m from calm marine)",
        marineDescription5:
          ". Characterized by occasionally noticeable slight salt smell and salt building-up in unwashed areas of structures.",
        marineDescription6:
          "If there is visible salt haze in this zone we would recommend you only use of Clean COLORBOND® ULTRA Steel and Class- 4 fasteners.",
        severeMarine: "Severe Marine",
        severeMarineDescription1: "Generally between",
        severeMarineDescription2:
          "101m from the beachfront to approximately 200m inland",
        severeMarineDescription3:
          ". In high wind areas, may extend further inland depending on prevailing winds and geography of the area. (Or",
        severeMarineDescription4: "< 100m from calm marine",
        severeMarineDescription5:
          "e.g.: harbour). Characterized by strong salt and haze, salt smearing and salt building-up in unwashed areas of structures. Generally a very noticeable deterioration of most building materials is evident.",
        severeMarineDescription6:
          "In this environment we strongly recommend Clean COLORBOND® ULTRA Steel and Class- 4 fasteners.",
        verySevereMarine: "Very Severe Marine",
        verySevereMarineDescription1: "Includes off-shores areas and",
        verySevereMarineDescription2:
          "up to 100m from high waterline of areas with breaking surf",
        verySevereMarineDescription3:
          ". (Please refer to BlueScope representatives)",
      },
      vn: {
        standard: {
          benignRural:
            "BENIGN / MILD URBAN / RURAL (MÔI TRƯỜNG THÀNH PHỐ / NÔNG THÔN)",
          benignRuralDescription1: "Generally greater than",
          benignRuralDescription2: "5 km from marine influence",
          benignRuralDescription3: "(breaking surf or calm marine) and away",
          benignRuralDescription4: "2km from all above industrial environments",
          benignRuralDescription5: "as well as corrosive fall-out.",
          benignRuralDescription6:
            "Cách môi trường biển hơn 5km và môi trường công nghiệp và khu vực khói bụi có tính ăn mòn từ 2km trở lên.",
        },
        industrialEnvironment: {
          lightIndUrban:
            "LIGHT INDUSTRIAL / URBAN (MÔI TRƯỜNG CÔNG NGHIỆP NHẸ)",
          lightIndUrbanDescription1:
            "This environment is widespread in industrial / urban areas, away from all environments listed above and typically more than",
          lightIndUrbanDescription2:
            "1001m to 2000m from heavy industrial fall-out.",
          lightIndUrbanDescription3:
            "Môi trường này phổ biến trong các khu công nghiệp/ khu đô thị, nằm cách xa các môi trường liệt kê ở trên, điển hình cách xa hơn 1001m – 2000m đối với khu vực có bụi công nghiệp nặng.",
          lightIndUrbanDescription4:
            "The Industrial warranty periods shown here are for indicative purpose only Warranties in industrial environments are  subject to case by case review of project for severity of pollutants and proximity to the marine & industrial influences.",
          industrial: "INDUSTRIAL (MÔI TRƯỜNG CÔNG NGHIỆP)",
          industrialDescription1:
            "This environment is widespread in industrial/urban areas, away from all environments listed above and typically more than",
          industrialDescription2:
            "501m up to 1000m from heavy industrial fall-out.",
          industrialDescription3:
            "Đặc điểm của môi trường này là khu vực có bụi do nằm gần kề khu vực mang tính chất công nghiệp nặng hay các khu vực công nghiệp nhỏ sản sinh ra khói bụi công nghiệp đáng kể, cách các khu vực này từ 501 tới 1000m.",
          industrialDescription4:
            "The Industrial warranty periods shown here are for indicative purpose only Warranties in industrial environments are  subject to case by case review of project for severity of pollutants and proximity to the marine & industrial influences.",
          heavyIndustrial: "HEAVY INDUSTRIAL (MÔI TRƯỜNG CÔNG NGHIỆP)",
          heavyIndustrialDescription1: "Typically characterized by less than",
          heavyIndustrialDescription2:
            "500m fall-out from adjoining severe industrial environments",
          heavyIndustrialDescription3:
            "or where small industries lead to significant industrial fall-out. Generally includes other service building located seperately and near severe industrial plants, including outbuilding of the plant itself.",
          heavyIndustrialDescription4:
            "Đặc điểm của môi trường này là khu vực có bụi do nằm gần kề trong vòng 500m khu vực mang tính chất công nghiệp nặng hay các khu vực công nghiệp nhỏ sản sinh ra khói bụi công nghiệp đáng kể.Thông thường bao gồm các tòa nhà dịch vụ nằm riêng biệt và gần những nhà máy công nghiệp nặng và bao gồm cả khu nhà phụ.",
          severeIndustrial: "SEVERE INDUSTRIAL (MÔI TRƯỜNG CÔNG NGHIỆP NẶNG)",
          severeIndustrialDescription1:
            "Characterized by fall-out and emissions from",
          severeIndustrialDescription2:
            "stacks, sulphur and acid or alkali smells.",
          severeIndustrialDescription3:
            "Includes only plant building themselves and any building immediately under stacks.  Also includes building with",
          severeIndustrialDescription4:
            "high internal humidity and/ or corrosion from operations within.",
          severeIndustrialDescription5:
            "Đặc điểm của môi trường này là khu vực có bụi và khói thải từ ống khói, có chứa lưu huỳnh và axit hay chất kiềm.Bao gồm các nhà xưởng có ống khói và bất kỳ tòa nhà nào ngay bên dưới ống khói.Và cũng bao gồm các tòa nhà có độ ẩm bên trong cao hay/ hoặc ăn mòn xảy ra do các hoạt động bên trong.",
          severeIndustrialDescription6:
            "Generally a very high rate of corrosion in most building structures is evident (Please refer to BlueScope representatives).",
        },
        marineEnvironment: {
          moderateMarine: "MODERATE MARINE (MÔI TRƯỜNG BIỂN)",
          moderateMarineDescription1: "Generally between",
          moderateMarineDescription2: "1001m and 5000m from marine surf",
          moderateMarineDescription3:
            ", although strong prevailing winds may extend this distance.",
          moderateMarineDescription4:
            "Thông thường nằm trong khu vực cách bờ biển từ 1001m đến 5000m, tuy nhiên gió chủ đạo mạnh có thể mở rộng khoảng cách này.",
          marine: "MARINE (MÔI TRƯỜNG BIỂN)",
          marineDescription1: "Generally between",
          marineDescription2: "401m and 1000m from marine surf",
          marineDescription3:
            ", although strong prevailing winds may extend this distance.",
          marineDescription4: "(Or 201m – 1000m from calm marine).",
          marineDescription5:
            "Thông thường nằm trong khu vực cách bờ biển từ 401m đến 1000m, tuy nhiên gió chủ đạo mạnh có thể mở rộng khoảng cách này(hoặc từ 201m đến 1000m từ khu vực sóng lặng).",
          severeMarine: "SEVERE MARINE (MÔI TRƯỜNG BIỂN KHẮC NGHIỆT)",
          severeMarineDescription1: "Generally between",
          severeMarineDescription2:
            "101m from the beachfront to approximately 400m inland",
          severeMarineDescription3:
            ". In high wind areas, may extend further inland depending on prevailing winds and geography of the area",
          severeMarineDescription4: "(or 101 – 200m from calm marine)",
          severeMarineDescription5:
            ". Characterized by occasionally noticeable slight salt smell and salt building-up in unwashed areas of structures.",
          severeMarineDescription6:
            "Thông thường nằm trong khu vực cách bờ biển từ 101m đến 400m, tuy nhiên gió chủ đạo mạnh có thể mở rộng khoảng cách này(hoặc từ 101m đến 200m từ khu vực sóng lặng).Đặc điểm của môi trường này là thỉnh thoảng xuất hiện mùi muối nhẹ, hơi muối đọng lại trên khu vực không được rửa trôi.",
          severeMarineDescription7:
            "In this environment we strongly recommend Clean COLORBOND® ULTRA Steel and Class- 4 fasteners.",
          verySevereMarine:
            "Very Severe Marine (MÔI TRƯỜNG BIỂN KHẮC NGHIỆT CAO)",
          verySevereMarineDescription1:
            "Applicable for roofing application subject to project specific evaluation (Reviewed on a case by case basis).",
          verySevereMarineDescription2:
            "Chỉ áp dụng cho mái, sẽ được đánh giá cho từng trường hợp.",
          verySevereMarineDescription3:
            "In this environment we strongly recommend COLORBOND® ULTRA Steel and Class- 4 fasteners",
          verySevereMarineDescription4: "Off-shores areas. Khu vực ngoài khơi.",
          verySevereMarineDescription5:
            "Up to 100m from the high waterline of areas with breaking surf. Khu vực cách mép sóng biển tan trong vòng 100m.",
        },
      },
      in: {
        standard: {
          benignRural: "Benign / Rural",
          benignRuralDescription1: "Generally",
          benignRuralDescription2: "greater than 1000m",
          benignRuralDescription3:
            "from marine influence (breaking surf or calm marine) ​AND/OR at​",
          benignRuralDescription4: "least 2km",
          benignRuralDescription5: "away from heavy Industrial fall out OR",
          benignRuralDescription6: "1km",
          benignRuralDescription7: "away from small industrial fall out.​",
        },
        industrialEnvironment: {
          lightIndUrban: "Light Industrial",
          lightIndUrbanDescription1:
            "This environment is widespread in industrial / urban areas, typically more than",
          lightIndUrbanDescription2:
            "1000m up to 2000m from heavy industrial fall-out, or between 500m to 1000m where small industries",
          lightIndUrbanDescription3:
            "lead to a moderate level of fall-out from small stacks, etc.",
          lightIndAndUrban: "Light Industrial / Urban",
          lightIndAndUrbanDescription1:
            "This environment is widespread in industrial / urban areas, typically more than",
          lightIndAndUrbanDescription2:
            "1000m up to 2000m from heavy industrial fall-out, or between 500m to 1000m where small industries",
          lightIndAndUrbanDescription3:
            "lead to a moderate level of fall-out from small stacks, etc.",
          industrial: "Industrial",
          industrialDescription1:
            "This environment is widespread in industrial/urban areas, typically more than",
          industrialDescription2:
            "500m up to 1000m from heavy industrial fall-out, or < 500m where small industries",
          industrialDescription3:
            "lead to a moderate level of fall-out from small stacks, etc. ",
          heavyIndustrial: "Heavy Industrial ",
          heavyIndustrialDescription1: "Typically characterized by",
          heavyIndustrialDescription2: "less than 500m",
          heavyIndustrialDescription3:
            "fall-out from adjoining severe industrial environments or where small industries lead to significant industrial fall-out. Generally includes other service building located near heavy industrial plants, including out-building of the plant itself.",
          heavyIndustrialDescription4:
            "In this environment we strongly recommend Clean COLORBOND®ULTRA Steel.",
          severeIndustrial: "Severe Industrial",
          severeIndustrialDescription1:
            "Characterized by fall-out and emissions from",
          severeIndustrialDescription2:
            "stacks, sulfur and acid or alkali smells.",
          severeIndustrialDescription3:
            "Includes only plant building themselves and any building immediately under stacks. Also includes building with",
          severeIndustrialDescription4:
            "high internal humidity and/ or corrosion from operations within.",
          severeIndustrialDescription5:
            "Generally a very high rate of corrosion in most building structures is evident.",
          severeIndustrialDescription6:
            "(Please refer to BlueScope representatives).",
        },
        marineEnvironment: {
          moderateMarine: "Moderate Marine",
          moderateMarineDescription1: "Generally between",
          moderateMarineDescription2: "401m and 1000m from marine surf,",
          moderateMarineDescription3:
            "although strong prevailing winds may extend this distance.",
          moderateMarineDescription4: "(Or 201m – 1000m from calm marine).",
          marine: "Marine",
          marineDescription1: "Generally between",
          marineDescription2:
            "201m from the beachfront to approximately 400m inland.",
          marineDescription3:
            "In high wind areas, may extend further inland depending on prevailing winds and geography of the area. (Or",
          marineDescription4: "101 - 200m from calm marine",
          marineDescription5:
            "). Characterized by occasionally noticeable slight salt smell and salt building-up in unwashed areas of structures.",
          marineDescription6:
            "If there is visible salt haze in this zone we would recommend you only use of Clean COLORBOND® ULTRA Steel and Class-4 fasteners.​",
          severeMarine: "Severe Marine",
          severeMarineDescription1: "Generally between",
          severeMarineDescription2:
            "101m from the beachfront to approximately 200m inland.",
          severeMarineDescription3:
            "In high wind areas, may extend further inland depending on prevailing winds and geography of the area. (Or",
          severeMarineDescription4: "< 100m from calm marine",
          severeMarineDescription5:
            "e.g.: harbour). Characterized by strong salt and haze, salt smearing and salt building-up in unwashed areas of structures.",
          severeMarineDescription6:
            "Generally a very noticeable deterioration of most building materials is evident.​ In this environment we strongly recommend Clean COLORBOND® ULTRA Steel and Class-4 fasteners.",
          verySevereMarine: "Very Severe Marine",
          verySevereMarineDescription1: "Includes off-shores areas and",
          verySevereMarineDescription2:
            "up to 100m from high waterline of areas with breaking surf.",
          verySevereMarineDescription3:
            "(Please refer to BlueScope representatives)",
        },
      },
    },
    startWorkingDate: "Start Working Date",
    startWorkingDatePlaceholder: "Insert Start Working Date",
    jobPosition: "Job Position",
    jobPositionPlaceholder: "Select Job Position",
    courseName: "Course Name",
    courseNamePlaceholder: "Insert Course Name",
    trainingDate: "Training Date",
    trainingDatePlaceholder: "Select Training Date",
    segmentPlaceholder: "Search Sub Segment or Description",
    applicationDetailPlaceholder: "Insert Number",
    buildingSelectPlaceholder: "Select Building",
    segmentSelectPlaceholder: "Select Segment",
    filter: "Filter",
    questionPlaceholder: "Insert Answer",
    birthDayPlaceholder: "Select Birthday",
    trainingYear: "Training Year",
    trainingYearPlaceholder: "Select Training Year",
    onceTheSaleOrderIsComplete:
      "Once the sales order is complete, this button will appear.",
    youCanGoTothe: "You can go to the",
    projectDocument: "project document",
    or: "or",
    warranty: "warranty",
    menu: "menu",
    toGenerateWarranty: "to generate a warranty",
    ifYouLeaveThisPage: "if you leave this page.",
    thisButtonWillDisappear: "this button will disappear.",
    linkPreTransactionIdAndToSaleTransactionId:
      "Link Pre-Transaction ID to Sales Transaction ID",
  },
  preTransaction: {
    breadcrumb1: "Home",
    breadcrumb2: "Project Document",
    breadcrumb3: "Pre-Transaction",
    breadcrumb4: "Project information",
    title: "Pre-Transaction",
    label: {
      customerSelection: "Customer Selection",
      selectCustomerCreatePreTransaction:
        "Select customer for create Pre-Transaction.",
      information: "Information",
      segment: "Segment",
      segmentAndSubSegment: "Segment and Sub segment",
      segmentDescription: "Segment description",
      subSegment: "Sub Segment",
      subSegmentDescription: "Segment description",
      pleaseChooseAnAction: "Please choose an action",
      warrantyTempalte: "Warranty template",
      warrantyTempalteDesc: "To view sample product group warranty template",
      documentTemplate: "Document template",
      documentTemplateDesc: "Support letter / Brochure /  Mill certificate",
      certificate: "Certificate",
      certificateDesc: "TIS / MiT / HACCP",
      salesOrder: "Sales order",
      salesOrderDesc:
        "To change product group from pre-transaction to sales order",
      reference: "Reference",
      referenceDesc: "To maintain your document (text,photos, files)",
      remarkOnlyProjectDocument: "สำหรับก่อนการขาย (Project Document) เท่านั้น",
    },
    field: {
      projectName: "Project Name",
      projectAddress: "Project address",
      maxProjectName: "Maximum 100 characters.",
      maxprojectAddress: "Maximum 100 characters.",
      placeholder: "Placeholder",
      placeholderProjectName: "Project Name",
      placeholderSearchSegmentSubSegmentAndDescription:
        "Search segment/sub segment name and description",
      placeholderSearchProjectOrCustomerName: "Search project or customer name",
    },
    button: {
      buttonSelectCustomer: "Select Customer",
      segmentAndSubSegmentSelection: "Segment & Sub Segment Selection",
      buttonCreatePreTransaction: "Create Pre-Transaction",
    },
    projectInformation: {
      title: "Project information",
    },
    preTransactionHistory: {
      breadcrumb1: "Pre-Transaction History",
      breadcrumb2: "Duplicate certificate",
      breadcrumb3: "View Certificate",
      title: "Project Document > Pre-Transaction History",
      field: {
        placeholderSearch: "Search",
        placeholderSelectStartDate: "Select Start Date",
        placeholderSelectEndDate: "Select End Date",
      },
      selectOption: {
        productName: "Product Name",
        customerName: "Customer Name",
        phoneNo: "Phone No.",
        status: "Status",
      },
      label: {
        date: "Date",
        to: "to",
        preTransactionCanBeDelete:
          "Pre-Transaction can be delete within a year after created. Any action will be done after the period, please contact Bluescope.",
        selectedPreTransaction: "Selected Pre-Transaction",
        noPreTransaction: "No Pre-Transaction",
        duplicatePreTransaction: "Duplicate Pre-Transaction",
        preTransactionCertificate: "Pre-Transaction certificate",
      },
      table: {
        header: {
          select: "Select",
          date: "Date",
          preTransactionID: "Pre-Transaction ID",
          projectName: "Project Name",
          productGroup: "Product group",
          customerName: "Customer Name",
          phoneNo: "Phone No.",
          references: "References",
          status: "Status",
          action: "Action",
        },
      },
      modal: {
        modalDelete: {
          title: "Confirm Delete",
          message:
            "Are you sure ? you want to delete this Pre-Transaction History",
        },
      },
    },
    duplicatePreTransaction: {
      breadcrumb1: "Duplicate Pre-Transaction",
      title: "Duplicate Pre-Transaction ",
      label: {
        productGroup: "Product Group",
        certificate: "Certificate",
        companyName: "Company Name",
        projectName: "Project Name",
        productAndDocumentary: "Product & Documentary",
      },
      field: {
        labelCustomerNameDocument: "Customer name in document",
      },
      button: {
        buttonSelectCustomer: "Select customer",
      },
    },
    referancePreTransaction: {
      breadcrumb1: "Reference",
      breadcrumb2: "Create Reference",
      breadcrumb3: "Edit Reference",
      title: "Reference",
      field: {
        placeholderReferenceName: "Reference name",
        placeholderInputReferenceName: "Input reference name",
        placeholderEnterDescription: "Enter description (optional)",
      },
      label: {
        noReference: "No Reference",
        noPreTransaction: "No Pre-Transaction",
        referenceInformation: "Reference information",
        referenceName: "Reference name",
        projectName: "Project name",
        description: "Description",
        referenceFile:
          "Reference file (Upload file type: .jpg, .jpeg, .png, .gif, .doc, .docx, .xls, .xlsx, .ptt, .pttx and .pdf)",
      },
      button: {
        buttonSearch: "Search",
        buttonCreateReference: "Create Reference",
        buttonUploadFile: "Upload File",
      },
      table: {
        header: {
          referenceName: "Reference Name",
          referenceNameLower: "Reference name",
          dateCreated: "Date created",
          dateModify: "Date Modified",
          action: "Action",
        },
      },
      modal: {
        modalDelete: {
          title: "Confirm Delete",
          message: "Are you sure ? you want to delete this Reference.",
        },
        modalLeave: {
          title: "Confirm leave",
          message:
            "Confirm Leave? Are you sure you want to leave this page with out save. The data will not save.",
        },
      },
    },
    modal: {
      modalHistoryLog: {
        title: "History log",
        label: {
          projectName: "Project name :",
          customerName: "Customer name :",
        },
        table: {
          header: {
            documentCategory: "Document category",
            productGroup: "Product group",
            logDescription: "Log description",
            dateTime: "Datetime",
          },
        },
      },
    },
    table: {
      header: {
        projectName: "Project name",
        customerName: "Customer name",
        createdDate: "Created date",
        action: "Action",
      },
    },
    document: {
      breadcrumb1: "Documentary",
      breadcrumb2: "View Documentary",
      title: "Documentary",
      table: {
        header: {
          productGroup: "Product group",
          certificate: "Certificate",
          createdDate: "Created date",
          action: "Action",
        },
      },
    },
  },
  message: {
    pleaseInsertAllRequiredField: "Please insert all required field.",
    wrongEmailFormat: "Wrong email format.",
    saveCompleted: "Save Completed.",
    incorrectEmailOrPassword: "Incorrect Email or Password.",
    pleaseSelectRewardCatalog: "Please select reward catalog.",
    customerPhoneIsAlreadyExist:
      "This phone number, email of customer by other authorize dealer.",
    newPasswordAndConfirmPasswordNotMatch:
      "New password and confirm password not match.",
    passwordIncorrect: "password incorrect.",
    passwordIncorrectLength: "password incorrect length.",
    usedPointsMoreThanTotalPoints:
      "Points cannot be redeemed. Please check your points.",
    incorrectPhoneNumberLength: "Incorrect length of phone number from country",
    incorrectMobileNumberLength:
      "Incorrect length of mobile number from country",
    contactNamePleaseSelectOnlyOne:
      "Contact Name is invalid. Please select only one contact name.",
    updateAdCompleted: "Authorize Dealer is successfully updated.",
    createCustomerCompleted: "Customer is successfully created.",
    updateCustomerCompleted: "Customer is successfully updated.",
    deleteCustomerCompleted: "Customer is successfully deleted.",
    createRedemptionCompleted: "Redemption has been successfully done.",
    updateRedemptionCompleted: "Redemption is successfully updated.",
    createSalesPersonCompleted: "Sales person is successfully created.",
    updateSalesPersonCompleted: "Sales person is successfully updated.",
    deleteSalesPersonCompleted: "Sales person is successfully deleted.",
    createSalesTransactionCompleted:
      "Sales transaction is successfully created. Please click warranty menu",
    updateWarrantyCompleted: "Warranty is successfully updated.",
    updateProductLengthCompleted: "Product’s Length is successfully updated.",
    confirmDeleteSalesPerson:
      "Are you sure you want to delete this sales person?",
    confirmDeleteCustomer: "Are you sure you want to delete this customer?",
    confirmDeleteHistorySaleTrans:
      "Are you sure you want to delete this sales transaction history?",
    warrantyWaitingApproval:
      "Your warranty are waiting for approval process due to some conditions have to be reviewed. if you have any question please contact Technical Marketing",
    nationalIdCard: "National id card reader",
    pleaseConfirmPointsAndUnits:
      "Please confirm your Trueblue Points and Units.",
    inventoryIsNotEnoughToPlace:
      "Your inventory is not enough to place this transaction. Please contact an administrator.",
    rewardStockItemIsNotEnoughToPlace:
      "Your reward stock item is not enough to place this transaction. Please contact an administrator.",
    sendPasswordToPhone: "Send temporary password to phone.",
    phoneOrContractorInvalid:
      "This phone number or this contractor is invalid.",
    formatFileIncorrect: "Your CSV file format is not correct.",
    incorrectProjectNameLength: "Maximun Project Name characters 100.",
    incorrectProjectName500Length: "Maximun Project Name characters 500.",
    incorrectProjectAddrLength: "Maximun Project Address characters 500.",
    incorrectAdditionalProjectLength:
      "Maximun Additional Project characters 500.",
    warrantyDetailExceedQuantity:
      "Exceed quantity, please change quantity or remove application.",
    warrantyPleaseSelectEnvDetail: "Please select environment detail.",
    warrantyPleaseApplicationDetail: "Please select application detail.",
    warrantyConditionOutOfLength:
      "Project is located out of environment detail.",
    warrantySelectEnvDetailSpecial:
      "Special product, please contact Bluescope Administration",
    numberEqualOrGreater: "A number must be equal or greater than",
    numberMustBe: "A number must be",
    numberMustBetween: "A number must be between",
    warrantyNoWarrantyOffered:
      "No warranty offer, please contact Bluescope Administration",
    warrantyOfferedNA:
      "Warranty will be submitted to Bluescpope Admin for further process",
    warrantyEnvSpecialProduct:
      "Special product will be submitted to Bluescope Admin for further process",
    warrantyPleaseSelectResidential:
      "Warranty cannot be generated. Please select category Residential.",
    warrantyPleaseSelectNonResidential:
      "Warranty cannot be generated. Please select category Non-residential.",
    warrantyPleaseSelectResidentialMY:
      "Your warranty application cannot be processed. Please contact BlueScope for further assistance",
    warrantyPleaseSelectNonResidentialMY:
      "Your warranty application cannot be processed. Please contact BlueScope for further assistance",
    warrantyNoTemplateForm: "Error-01 Warranty cannot be generated.",
    warrantySelectEnvDetailNA:
      "Warranty is not applicable, please contact Bluescope Administration",
    warrantyConditionNotPass:
      "Warranty will be sent to Bluescope for further approval.",
    warrantyMaximumYearExceed:
      "The maximum year cannot exceed corrosion warranty.",
    warrantyPleaseSelectCategory: "Please select category",
    pleaseSelectSegment: "Please select segment.",
    otpSentSuccess: "OTP has been sent, Please check your message.",
    otpResendSuccess: "OTP has been resend, Please check your message.",
    incorrectOTP: "Incorrect OTP.",
    wrongAnswer: "Wrong Answer",
    wrtNoPleaseSelectOnlyOne:
      "Warranty is invalid. Please select only one warranty.",
    updateDocumentaryCompleted: "Documentary is successfully created.",
    blueScopeContactBack: "Do you need BlueScope contact you back?",
    privacyPolicyRegisterYearWarranty1:
      'Privacy Policy: By clicking "agree", you agree to provide Bluescope your information which allows us to use it in regards to privacy policy and marketing purposes. To view privacy policy of NS Bluescope (Thailand) Pte., Ltd., please click ',
    privacyPolicyRegisterYearWarranty2: "this link",
    privacyPolicyRegisterYearWarranty3: "",
    errorSalesPersonMemberTypeIsContractor:
      "Sales person member_type__c is Contractor, Please contact Bluescope",
    preTransactionHistorySuccessDeleted:
      "Pre-Transaction History  is successfully deleted.",
    duplicatePreTransactionSuccess: "Duplicate Pre-Transaction success.",
    referenceSuccessfullyCreated: "Reference is successfully created.",
    referenceSuccessfullyEdited: "Reference is successfully edited.",
    referenceSuccessfullyDeleted: "Reference is successfully deleted.",
    deleteReferenceFileSuccess: "Delete reference file success.",
    coilTransactionSuccessfully: "You have been coil-transaction successfully.",
    coilTransferWaitingApproval:
      "You have completed coil transfer, waiting for approval.",
    youApprovedCoil: "You have been approved coil.",
    youClearedCoil: "You have been cleared coil.",
    receivedYourIssueCheckGetBackYouContact: `Bluescope has received your issue. We'll check and get back to you by contact.`,
    pleaseLoginWithSmartPhone: "Please login with smart phone",
    projectDocumentSuccessCreate: "Project Document is successfully created.",
    projectDocumentSuccessUpdate: "Project Document is successfully updated.",
    linkProjectSuccess: "Link project success.",
    certificateSuccessCreate: "Certificate is successfully created.",
    certificateSuccessUpdate: "Certificate is successfully updated.",
    cantFoundDocumentary: `Can't found documentary data.`,
    requestEditProjectDocumentSuccess: "Request edit project document success.",
    maximumFileSize1MB: `File size is too big, maximum file's size is 1 MB`,
    maximumFileSize20MB: `File size is too big, maximum file's size is 20 MB`,
    automaticEnvironmentNotFound: `Automatic environment not found`,
    environmentFromProjectAddressNotFound: `environment_from_project_address__c not found`,
    errorCoilNoLength: 'Coil [coilName] is sold out. Remaining length is 0 m.',
    warrantySelfDeclaredConditionNotFound: `Warranty self-declared conditions not found`,
    warrantySpecialRejectEnvironment: `The Terms and Conditions are not covered by the standard warranty. Please contact your dedicated sales representative for further assistance.`,
    warrantySpecialReject: `The Terms and Conditions are not covered by the standard warranty. Please contact your dedicated sales representative for further assistance.`,
    warrantySpecialWaitingToBeApprove: `The Terms and Conditions are not covered by the standard warranty. Please await approval from the BlueScope administrator.`,
    warrantySpecialWaitingToBeApproveAll: `Warranty is special, Waiting admin to be approve all.`, // TODO: re wording
  },
  certificate: {
    breadcrumb1: "Home",
    breadcrumb2: "Project Document",
    breadcrumb3: "Pre-Transaction",
    breadcrumb4: "Create Certificate",
    breadcrumb5: "Regenerate Certificate",
    title: "Pre-Transaction certificate : ",
    label: {
      preTransactionSuccessCreate:
        "Pre-Transaction is successfully created, It is visible in history and sales orders",
      for: "for",
      history: "history",
      or: "or",
      project: "project",
      referenceMenuMaintain:
        " reference menu, you can maintain references (text, images, and files).",
      inSalesOrder:
        "In sales orders, you can switch the product group from Pre-Transaction to Sales Transaction.",
      preTransactionAndCertificate: "Pre-Transaction & Certificate",
      selectProduct: "Select products",
      information: "Information",
      certificate: "Certificate",
      confirmPreTransaction: "Confirm Pre-transaction",
      projectName: "Project name",
    },
    placeholder: {
      placeholderSearchProductGroup: "Search product group",
      placeholderProjectName: "Project name",
    },
    table: {
      header: {
        select: "Select",
        productGroup: "Product Group",
        company: "Company",
        document: "Document",
      },
    },
    modal: {
      modalConfirmPreTransaction: {
        pleaseConfirmProjectNameAndSpecifyCompany:
          "Please confirm project name and specify company to use in document.",
        afterCompleteSave: `After a complete save, you won't be able to change or re-generate it.`,
      },
    },
  },
  preSaleDocumentary: {
    breadcrumb1: "Home",
    breadcrumb2: "Project Document",
    breadcrumb3: "Pre-Transaction",
    breadcrumb4: "Create Documentary",
    title: "Pre-Transaction documentary : ",
    label: {
      preTransactionSuccessCreate:
        "Pre-Transaction is successfully created, It is visible in history and sales orders",
      for: "for",
      history: "history",
      or: "or",
      project: "project",
      referenceMenuMaintain:
        " reference menu, you can maintain references (text, images, and files).",
      inSalesOrder:
        "In sales orders, you can switch the product group from Pre-Transaction to Sales Transaction.",
      preTransactionAndDocumentary: "Pre-Transaction & Documentary",
      selectProduct: "Select products",
      information: "Information",
      documentary: "Documentary",
      confirmPreTransaction: "Confirm Pre-transaction",
      projectName: "Project name",
    },
    placeholder: {
      placeholderSearchProductGroup: "Search product group",
      placeholderProjectName: "Project name",
    },
    table: {
      header: {
        select: "Select",
        productGroup: "Product Group",
        company: "Company",
        document: "Document",
      },
    },
    modal: {
      modalConfirmPreTransaction: {
        pleaseConfirmProjectNameAndSpecifyCompany:
          "Please confirm project name and specify company to use in document.",
        afterCompleteSave: `After a complete save, you won't be able to change or re-generate it.`,
      },
    },
  },
  projectReference: {
    breadcrumb1: "Home",
    breadcrumb2: "Project reference",
    breadcrumb3: "Reference",
    breadcrumb4: "Create reference",
    breadcrumb5: "Edit reference",
    title: "Project Reference",
    label: {
      customerSelection: "Customer Selection",
      selectCustomerCreatePreTransaction:
        "Select customer for create Pre-Transaction.",
      reference: "Reference",
      referenceList: "Reference List",
      noReference: "No Reference",
      createReference: "Create Reference",
      editReference: "Edit Reference",
      referenceInformation: "Reference information",
      referenceName: "Reference name",
      saleTransactionID: "Sales transaction ID",
      projectName: "Project name",
      description: "Description",
      referenceFile:
        "Reference file (Upload file type: .jpg, .jpeg, .png, .gif, .doc, .docx, .xls, .xlsx, .ptt, .pttx and .pdf)",
      warrantyNo: "Warranty No.",
      maximumFileSize: `*Maximum file's size is 20 MB.`,
    },
    button: {
      buttonSelectCustomer: "Select Customer",
      buttonSearch: "Search",
      buttonCreateReference: "Create Reference",
      buttonUploadFile: "Upload File",
    },
    field: {
      placeholderSearchNameReferenceProjectSaleTransaction:
        "Name of reference, project or sales transaction ID",
      placeholderInputReferenceName: "Input reference name",
      placeholderSelectSalesTransactionID: "Select sales transaction ID",
      placeholderProjectName: "Select project name",
      placeholderInputDescription: "Input description(optional)",
      placeholderSelectWarrantyNo: "Select warranty No.",
    },
    table: {
      header: {
        referenceName: "Reference Name",
        referenceNameLower: "Reference name",
        warrantyNo: "Warranty No.",
        projectName: "Project Name",
        dateCreated: "Date created",
        dateModify: "Date Modified",
        action: "Action",
        updatedDate: "Uploaded date",
        select: "Select",
      },
    },
    modal: {
      modalSelectReferenceType: {
        label: {
          pleaseSelectReferenceType: "Plese select reference type",
        },
        button: {
          buttonWarranty: "Warranty",
          buttonProjectDocument: "Project Document",
        },
      },
      modalConfirmDelete: {
        title: "Confirm Delete",
        message: "Are you sure ? you want to delete this Reference.",
      },
    },
  },
  barcode: {
    breadcrumb1: "Home",
    breadcrumb2: "Buy Coil in",
    title: "Scan Barcode to get coil",
    label: {
      coilId: "Search Coil ID",
      back: "Back",
    },
    field: {
      placeholderInsertCoilId: "Example MMxPxxxxx",
    },
    modal: {
      modalBarcodeDuplicate: {
        title: "Coil Duplicate",
        message: "You already have coil {coilName} in stock.",
      },
      modalCoilTranferInformation: {
        title: "Coil Transfer Information",
        label: {
          coilId: "Coil ID",
          coilName: "Coil Name",
          allAmount: "All amount (m.)",
          tranferAmount: "Transfer Amount (m.)",
        },
      },
    },
  },
  coilApproval: {
    breadcrumb1: "Home  ",
    breadcrumb2: "Inventory & Coil Transfer",
    breadcrumb3: "Approve coil transfer",
    title: "Inventory & Coil Transfer > Approve coil transfer",
    selectOption: {
      all: "All",
      coilID: "Coil ID",
      coilName: "Coil Name",
      adRFName: "AD/RF Name",
      pending: "Pending",
      approved: "Approved",
      cleared: "Cleared",
    },
    label: {
      category: "Category",
      status: "Status",
      date: "Date",
      dateTo: "to",
      noDataCoil: "No Data Coil",
    },
    field: {
      placeholderSearch: "Search",
      selectStartDate: "Select Start Date",
      selectEndDate: "Select End Date",
      selectCoilApproval: "Selected Coil Approval",
    },
    button: {
      buttonSearch: "Search",
      buttonClear: "Clear",
      buttonApprove: "Approve",
      buttonConformAndApprove: "Confirm and Approve",
      buttonConfirmAndClear: "Confirm and Clear",
    },
    table: {
      header: {
        coilId: "Coil ID",
        coilName: "Coil Name",
        adRfName: "AD/RF name",
        date: "Date",
        tranferAmount: "Transfer Amount (m.)",
        status: "Status",
        action: "Action",
      },
    },
    modal: {
      modalCoilApproval: {
        title_1: "Confirm Approve Coil",
        title_2: "Confirm Clear Coil",
        table: {
          header: {
            no: "No",
            coilId: "Coil ID",
            coilName: "Coil Name",
            thickness: "Thickness",
            color: "Color",
            aging: "Aging",
            reWeight: "Re. Weight (kg)",
            lengthBalance: "Length Balance (m.)",
            tranferAmount: "Transfer Amount (m.)",
          },
        },
      },
      modalCoilApprovalDetail: {
        title: "Detail Coil",
        label: {
          coilName: "Coil Name",
          coilId: " Coil ID",
          tranferAmount: "Transfer Amount (m.)",
          reWeight: "Re. Weight (kg)",
          lengthBalance: "Length Balance (m.)",
          thickness: "Thickness",
          color: "Color",
          aging: "Aging",
        },
      },
    },
  },
  projectDocument: {
    breadcrumb1: "Home",
    breadcrumb2: "Project Document",
    breadcrumb3: "View warranty",
    breadcrumb4: "Warranty Template",
    breadcrumb5: "Create documentary",
    breadcrumb6: "Create reference",
    breadcrumb7: "Create certificate",
    breadcrumb8: "Edit reference",
    breadcrumb9: "Regenerate Certificate",
    title: "Project Document",
    label: {
      createProjectDocument: "Create Project Document",
      date: "Date",
      dateTo: "to",
      selectStartDate: "Select Start Date",
      selectEndDate: "Select End Date",
      enabled: "Enabled",
      when: "when",
      aSalesOrderIsPresent: "a sales order is present",
      noWarrantyTemplate: "No Warranty Template",
      noDocumentary: "No Documentary",
      noCertificate: "No Certificate",
      noReference: "No Reference",
      warranty: "Warranty",
      warrantyTemplate: "Warranty Template",
      allReferenceFile: "All Reference Files",
      noReferenceFile: "No Reference File",
      documentary: "Documentary",
      certificate: "Certificate",
      selectProduct: "Select product",
      whenever: "whenever",
      aSaleOrderForTheSameProductGroupIsPresent: "a sales order for the same product group is present",
      preCertificate: "Pre-certificate: Before sales documents",
      postCertificate: "Post-certificate: After sales documents (Only available when a sales has been completed)",
      postCertificate2: `*TIS 2228-2559 for coil produced before 8/11/23`,
      postCertificate3: `*TIS 2228-2565 for coil produced since 8/11/23`,
      specifyCompanyNameInCertificate: "Specify the company name in certificate",
      viewList: "View list",
      viewIcon: "View icon",
      andUntilTheProductionDate: "and until the production date",
    },
    placeholder: {
      search: "Search",
      searchWarranty: "Search Sales Trans. ID",
      searchProductGroup: "Search product group",
    },
    button: {
      buttonProjectInformation: "Project Information",
      buttonSaleOrder: "Sales Order",
      buttonViewAllTemplate: "View All Template",
      buttonCreateDocumentary: "Create Documentary",
      buttonCreateCertificate: "Create Certificate",
      buttonCreateReference: "Create Reference",
      buttonSelectReferenceFile: "Select Reference File",
      buttonPreviewPostCertificate: "Preview Post Certificate",
      buttonPreviewPreCertificate: "Preview Pre Certificate",
    },
    table: {
      header: {
        preTransactionId: "Pre-Transaction ID",
        projectName: "Project name",
        customerName: "Customer name",
        saleTransactionId: "Sales Transaction ID",
        createdDate: "Created date",
        editedDate: "Edited date",
        action: "Action",
        name: "Name",
        nameDocument: "Name",
        downloadDate: "Downloaded date",
        ID: "ID",
        productGroup: "Product group",
        referenceName: "Reference Name",
        modifiedName: "Modified Date",
        uploadedDate: "Uploaded date",
        select: "Select",
        code: "Code",
        category: "Category",
        no: "No",
        view: "View",
        preview: "Preview",
        projectDocumentNo: "Project document no.",
      },
    },
    modal: {
      label: {
        confirmToDelete: "Confirm to Delete",
        doYouWantToDeleteTheReferenceFile:
          "Do you want to delete the {count} reference files ?  ",
        doYouWantToDelete: "Do you want to delete ",
        projectReference: "“Project reference”",
        reference: "reference ?",
        confirmCompanyName: "Confirm Company Name",
        pleaseConfirmSpecifyCompanyNameToUseInDocumenary:
          "Please confirm specify company name to use in documentary 1.BlueScope Zacs use",
        afterACompleteSave: "After a complete save,",
        youWontBeAbleToChangeOrReGenerateIt: `you won't be able to change or re-generate it.`,
        pleaseConfirmSpecifyCompanyNameToUseInCertificate:
          "Please confirm specify company name to use in certificate 1.BlueScope Zacs use",
      },
      modalSaleTransaction: {
        title: "Sales Transaction",
        label: {
          preTransactionID: "Pre-Transaction ID :",
          projectName: "Project name :",
          customerName: "Customer name :",
        },
        table: {
          header: {
            saleTransactionID: "Sales Transaction ID",
            createdDate: "Created date",
          },
        },
      },
      previewWarranty: {
        title: "Warranty No. [warrantyNo] : [productGroup]",
      },
      previewDocumentary: {
        title: "Documentary : [productGroup]",
      },
      previewCertificate: {
        title: "Certificate : [productGroup]",
      },
    },
    tab: {
      titleWarrantyGuideline: "Warranty Guideline",
      titleWarrantyTemplate: "Warranty Template",
      titleWarranty: "Warranty",
      titleDocumentary: "Documentary",
      titleCertificateAndLetter: "Certificate and Letter",
      titleProjectDatabase: "Project database",
      descriptionWarrantyGuideline:
        "To estimate warranty year in Marine environment",
      descriptionWarrantyTemplate:
        "To view sample warranty template for each product",
      descriptionWarranty: "To generate warranty ",
      descriptionDocumentary:
        "To generate document such as brochure, datasheet, etc.",
      descriptionCertificateAndLetter:
        "To generate certificate and letter such as TIS,MiT,HACCP, etc.",
      descriptionProjectDatabase: "To upload documents related to the project.",
    },
  },
  notificationManagement: {
    breadcrumb1: "Home",
    breadcrumb2: "Notification Management",
    title: "Notification Management",
    label: {
      from: "From",
      to: "to",
      selectStartDate: "Select Start Date",
      selectEndDate: "Select End Date",
      noData: "No data"
    },
    field: {
      placeholderSearch: "Search ID, Subject",
    },
    button: {
      buttonSearch: "Search",
    },
    table: {
      header: {
        id: 'ID',
        subject: 'Subject',
        publicationDate: 'Publication Date',
        acceptedDate: 'Accepted Date',
        action: 'Action'
      }
    }
  },
  warrantyConditions: {
    field: {
      placeholderInsertDistanceNumber: 'Insert distance number',
      placeholderSelectThePreferredOption: 'Select the preferred option',
      placeholderInsertDescription: 'Insert description',
    },
    label: {
      yes: 'Yes',
      no: 'No',

    },
  },
};
