import { lang_en } from "./languageEN";

export const SIDE_MENU_LIST = [
  lang_en.sideMenu.home,
  lang_en.sideMenu.yearWarranty,
  lang_en.sideMenu.customers,
  lang_en.sideMenu.orderInquiry,
  lang_en.sideMenu.salesTransaction,
  lang_en.sideMenu.uploadTransaction,
  lang_en.sideMenu.warranty,
  lang_en.sideMenu.rewards,
  lang_en.sideMenu.inventory,
  lang_en.sideMenu.report,
  lang_en.sideMenu.settings,
  lang_en.sideMenu.projectDocument,
];

export const ROUTE_PATH = {
  LOGIN: {
    TEXT: 'Login',
    LINK: '/'
  },
  AUTHENTICATION: {
    TEXT: 'Authentication',
    LINK: '/Auth'
  },
  HOME: {
    TEXT: 'Home',
    LINK: '/',
    MENU_ACTIVE: SIDE_MENU_LIST[0],
  },
  YEAR_WARRANTY: {
    TEXT: 'Year Warranty',
    LINK: '/YearWarranty',
    MENU_ACTIVE: SIDE_MENU_LIST[1],
  },
  CUSTOMERS: {
    TEXT: 'Customers',
    LINK: '/Customers',
    MENU_ACTIVE: SIDE_MENU_LIST[2],
  },
  CUSTOMERS_MANAGE: {
    TEXT: 'Customers Create',
    LINK: '/Customers/Manage',
    MENU_ACTIVE: SIDE_MENU_LIST[2],
  },
  ORDER_INQUIRY: {
    TEXT: 'Order Inquiry',
    LINK: '/OrderInquiry',
    MENU_ACTIVE: SIDE_MENU_LIST[3]
  },
  // SALES_TRANSACTION: {
  //   TEXT: 'Sales Transaction',
  //   LINK: '/SalesTransaction',
  //   MENU_ACTIVE: SIDE_MENU_LIST[4],
  //   IS_NO_EXPANDED: true,
  // },
  SALES_ORDERS: {
    TEXT: 'Sales Orders',
    LINK: '/SalesOrders',
    MENU_ACTIVE: SIDE_MENU_LIST[4],
    MENU_CHILD_ACTIVE: 'Sales Orders',
  },
  SALES_HISTORY: {
    TEXT: 'Sales History',
    LINK: '/SalesHistory',
    MENU_ACTIVE: SIDE_MENU_LIST[4],
    MENU_CHILD_ACTIVE: 'Sales History',
  },
  // UPLOAD_TRANSACTION_INDEXES: {
  //   TEXT: 'Upload Transaction Indexes',
  //   LINK: '/UploadTransactionIndexes',
  //   MENU_ACTIVE: SIDE_MENU_LIST[5],
  //   IS_NO_EXPANDED: true,
  // },
  UPLOAD_TRANSACTION: {
    TEXT: 'Upload Transaction',
    LINK: '/UploadTransaction',
    MENU_ACTIVE: SIDE_MENU_LIST[5],
    MENU_CHILD_ACTIVE: 'Upload Transaction Management',
  },
  UPLOAD_TRANSACTION_HISTORY: {
    TEXT: 'Upload Transaction History',
    LINK: '/UploadTransactionHistory',
    MENU_ACTIVE: SIDE_MENU_LIST[5],
    MENU_CHILD_ACTIVE: 'Upload Transaction History',
  },
  UPLOAD_TRANSACTION_HISTORY_DETAIL: {
    TEXT: 'Upload Transaction History Detail',
    LINK: '/UploadTransactionHistoryDetail',
    MENU_ACTIVE: SIDE_MENU_LIST[5],
    MENU_CHILD_ACTIVE: 'Upload Transaction History',
  },
  WARRANTY_MANAGEMENT: {
    TEXT: 'Warranty Management',
    LINK: '/WarrantyManagement',
    MENU_ACTIVE: lang_en.sideMenu.warranty,
    IS_NO_EXPANDED: true,
  },
  WARRANTY: {
    TEXT: 'Warranty',
    LINK: '/Warranty',
    MENU_ACTIVE: lang_en.sideMenu.warranty,
    MENU_CHILD_ACTIVE: 'Warranty',
  },
  WARRANTY_TEMPLATE: {
    TEXT: 'Warranty Template',
    LINK: '/WarrantyTemplate',
    MENU_ACTIVE: lang_en.sideMenu.warranty,
    MENU_CHILD_ACTIVE: 'Template',
  },
  DOCUMENTARY: {
    TEXT: 'Documentary',
    LINK: '/Documentary',
    MENU_ACTIVE: lang_en.sideMenu.warranty,
    MENU_CHILD_ACTIVE: 'Documentary',
  },
  DOCUMENTARY_MANAGE: {
    TEXT: 'DocumentaryManage',
    LINK: '/DocumentaryManage',
    MENU_ACTIVE: lang_en.sideMenu.warranty,
    MENU_CHILD_ACTIVE: 'Documentary',
  },
  DOCUMENTARY_VIEW_LIST: {
    TEXT: 'Documentary',
    LINK: '/DocumentaryViewList',
    MENU_ACTIVE: lang_en.sideMenu.warranty,
    MENU_CHILD_ACTIVE: 'Documentary',
  },
  SPECIAL_WARRANTY: {
    TEXT: 'Special Warranty',
    LINK: '/SpecialWarranty',
    MENU_ACTIVE: lang_en.sideMenu.warranty,
    MENU_CHILD_ACTIVE: 'Special Warranty',
  },
  // REWARDS: {
  //   TEXT: 'Rewards',
  //   LINK: '/Rewards',
  //   MENU_ACTIVE: SIDE_MENU_LIST[7],
  //   IS_NO_EXPANDED: true,
  // },
  REDEMPTION: {
    TEXT: 'Redemption',
    LINK: '/Redemption',
    MENU_ACTIVE: SIDE_MENU_LIST[7],
    MENU_CHILD_ACTIVE: 'Redemption',
  },
  REDEMPTION_LIST_OF_REWARDS: {
    TEXT: 'Redemption List of Rewards',
    LINK: '/RedemptionListOfRewards',
    MENU_ACTIVE: SIDE_MENU_LIST[7],
    MENU_CHILD_ACTIVE: 'Redemption',
  },
  REWARD_REDEMPTION_HISTORY: {
    TEXT: 'Reward Redemption History',
    LINK: '/RewardRedemptionHistory',
    MENU_ACTIVE: SIDE_MENU_LIST[7],
    MENU_CHILD_ACTIVE: 'Reward Redemption History',
  },
  REWARD_STOCK: {
    TEXT: 'Reward Stock',
    LINK: '/RewardStock',
    MENU_ACTIVE: SIDE_MENU_LIST[7],
    MENU_CHILD_ACTIVE: 'Reward Stock',
  },
  POINTS_DETAIL: {
    TEXT: 'TrueBlue Points Detail',
    LINK: '/PointsDetail',
    MENU_ACTIVE: SIDE_MENU_LIST[7],
    MENU_CHILD_ACTIVE: 'TrueBlue Points Detail',
  },
  POINTS_DETAIL_MANAGEMENT: {
    TEXT: 'TrueBlue Points Detail Management',
    LINK: '/PointsDetailManagement',
    MENU_ACTIVE: SIDE_MENU_LIST[7],
    MENU_CHILD_ACTIVE: 'TrueBlue Points Detail',
  },
  // INVENTORY_INDEXES: {
  //   TEXT: 'Inventory',
  //   LINK: '/InventoryIndexes',
  //   MENU_ACTIVE: SIDE_MENU_LIST[8],
  //   IS_NO_EXPANDED: true,
  // },
  INVENTORY: {
    TEXT: 'Inventory Management',
    LINK: '/Inventory',
    MENU_ACTIVE: SIDE_MENU_LIST[8],
    MENU_CHILD_ACTIVE: lang_en.sideMenu.inventoryManagement,
  },
  PRODUCT_DETAIL: {
    TEXT: 'Product Detail',
    LINK: '/Inventory/ProductDetail',
    MENU_ACTIVE: SIDE_MENU_LIST[8],
    MENU_CHILD_ACTIVE: lang_en.sideMenu.inventoryManagement,
  },
  COIL_TRANSFER: {
    TEXT: 'Coil Transfer',
    LINK: '/CoilTransfer',
    MENU_ACTIVE: SIDE_MENU_LIST[8],
    MENU_CHILD_ACTIVE: lang_en.sideMenu.coilTransfer,
  },
  COIL_TRANSFER_BARCODE_SCANNER: {
    TEXT: 'Coil Transfer Barcode',
    LINK: '/CoilTransfer/BarcodeScanner',
    MENU_ACTIVE: SIDE_MENU_LIST[8],
    MENU_CHILD_ACTIVE: lang_en.sideMenu.barcodeScan,
  },
  COIL_APPROVAL: {
    TEXT: 'Coil Approval',
    LINK: '/CoilApproval',
    MENU_ACTIVE: SIDE_MENU_LIST[8],
    MENU_CHILD_ACTIVE: lang_en.sideMenu.coilApproval,
  },
  PROFILE_TRANSFER: {
    TEXT: 'Profile Transfer',
    LINK: '/ProfileTransfer',
    MENU_ACTIVE: SIDE_MENU_LIST[8],
    MENU_CHILD_ACTIVE: 'Profile Transfer',
  },
  AUTHORISED_DEALER_AND_ROLL_FORMER_MANAGE: {
    TEXT: 'Authorised Dealer and Roll Former Create',
    LINK: '/AuthorisedDealerAndRollFormer/Manage',
    MENU_ACTIVE: SIDE_MENU_LIST[8],
    MENU_CHILD_ACTIVE: lang_en.sideMenu.coilTransfer,
  },
  COIL_TRANSACTION: {
    TEXT: 'Coil Transaction',
    LINK: '/CoilTransaction',
    MENU_ACTIVE: SIDE_MENU_LIST[8],
    MENU_CHILD_ACTIVE: lang_en.sideMenu.coilTransfer,
  },
  PROFILE_TRANSACTION: {
    TEXT: 'Profile Transaction',
    LINK: '/ProfileTransaction',
    MENU_ACTIVE: SIDE_MENU_LIST[8],
    MENU_CHILD_ACTIVE: 'Profile Transfer',
  },
  COIL_TRANSACTION_MANAGE: {
    TEXT: 'Coil Transaction Manage',
    LINK: '/CoilTransaction/Manage',
    MENU_ACTIVE: SIDE_MENU_LIST[8],
    MENU_CHILD_ACTIVE: lang_en.sideMenu.coilTransfer,
  },
  PROFILE_TRANSACTION_MANAGE: {
    TEXT: 'Profile Transaction Manage',
    LINK: '/ProfileTransaction/Manage',
    MENU_ACTIVE: SIDE_MENU_LIST[8],
    MENU_CHILD_ACTIVE: 'Profile Transfer',
  },
  COIL_TRANSFER_HISTORY: {
    TEXT: 'Coil Transfer History',
    LINK: '/CoilTransferHistory',
    MENU_ACTIVE: SIDE_MENU_LIST[8],
    MENU_CHILD_ACTIVE: 'Coil Transfer History',
  },
  PROFILE_TRANSFER_HISTORY: {
    TEXT: 'Profile Transfer History',
    LINK: '/ProfileTransferHistory',
    MENU_ACTIVE: SIDE_MENU_LIST[8],
    MENU_CHILD_ACTIVE: 'Profile Transfer History',
  },
  PROFILE_MAPPING: {
    TEXT: 'Profile Mapping',
    LINK: '/ProfileMapping',
    MENU_ACTIVE: SIDE_MENU_LIST[8],
    MENU_CHILD_ACTIVE: 'Profile Mapping', // AD/Toko = Mapping, Distributor = Transfer
  },
  PROFILE_MAPPING_HISTORY: {
    TEXT: 'Profile Mapping History',
    LINK: '/ProfileMappingHistory',
    MENU_ACTIVE: SIDE_MENU_LIST[8],
    MENU_CHILD_ACTIVE: 'Profile Mapping History',
  },
  PROFILE_MAPPING_HARDWARE_AND_TOKO_SHOP_INFO: {
    TEXT: 'Profile Mapping Hardware And Toko Shop Info',
    LINK: '/ProfileMappingHardwareAndTokoShopInfo',
    MENU_ACTIVE: SIDE_MENU_LIST[8],
    MENU_CHILD_ACTIVE: 'Profile Mapping',
  },
  PROFILE_MAPPING_PRODUCT_GROUP: {
    TEXT: 'Profile Mapping Product Group',
    LINK: '/ProfileMappingProductGroup',
    MENU_ACTIVE: SIDE_MENU_LIST[8],
    MENU_CHILD_ACTIVE: 'Profile Mapping',
  },
  PROFILE_MAPPING_PRODUCT_LIST: {
    TEXT: 'Profile Mapping Product List',
    LINK: '/ProfileMappingProductList',
    MENU_ACTIVE: SIDE_MENU_LIST[8],
    MENU_CHILD_ACTIVE: 'Profile Mapping',
  },
  PROFILE_MAPPING_PRODUCT_MANAGE: {
    TEXT: 'Profile Mapping Product Manage',
    LINK: '/ProfileMappingProductManage',
    MENU_ACTIVE: SIDE_MENU_LIST[8],
    MENU_CHILD_ACTIVE: 'Profile Mapping',
  },
  REPORT: {
    TEXT: 'Report',
    LINK: '/Report',
    MENU_ACTIVE: SIDE_MENU_LIST[9],
  },
  AD_INFORMATION: {
    TEXT: 'Ad Information',
    LINK: '/AdInformation',
    MENU_ACTIVE: SIDE_MENU_LIST[10],
    MENU_CHILD_ACTIVE: lang_en.sideMenu.adInformation,
  },
  CHANGE_PASSWORD: {
    TEXT: 'Change Password',
    LINK: '/ChangePassword',
    MENU_ACTIVE: SIDE_MENU_LIST[10],
    MENU_CHILD_ACTIVE: 'Change Password',
  },
  // SALE_PERSON_INFORMATION: {
  //   TEXT: 'Sale Person Information',
  //   LINK: '/SalePersonInformation',
  //   MENU_ACTIVE: SIDE_MENU_LIST[10],
  //   MENU_CHILD_ACTIVE: 'Sale Person Information',
  // },
  // SALE_PERSON_MANAGE: {
  //   TEXT: 'Sale Person Create',
  //   LINK: '/SalePersonInformation/Manage',
  //   MENU_ACTIVE: SIDE_MENU_LIST[10],
  //   MENU_CHILD_ACTIVE: 'Sale Person Information',
  // },
  // SETTINGS: {
  //   TEXT: 'Settings',
  //   LINK: '/Settings',
  //   MENU_ACTIVE: SIDE_MENU_LIST[10],
  //   IS_NO_EXPANDED: true,
  // },
  TERMS_AND_CONDITION: {
    TEXT: 'Term And Condition',
    LINK: '/TermAndCondition',
    MENU_ACTIVE: SIDE_MENU_LIST[10],
    MENU_CHILD_ACTIVE: lang_en.sideMenu.termAndCondition,
  },
  CONTACT_US: {
    TEXT: 'Contact Us',
    LINK: '/ContactUs',
    MENU_ACTIVE: SIDE_MENU_LIST[10],
    MENU_CHILD_ACTIVE: 'Contact Us',
  },
  HELP: {
    TEXT: 'Help',
    LINK: '/Help',
    MENU_ACTIVE: SIDE_MENU_LIST[10],
    MENU_CHILD_ACTIVE: 'Help',
  },
  SETTING_SALES_PERSON: {
    TEXT: 'Sales Person',
    LINK: '/SettingSalesPerson',
    MENU_ACTIVE: SIDE_MENU_LIST[10],
    MENU_CHILD_ACTIVE: 'Sales Person',
  },
  SETTING_SALES_PERSON_MANAGE: {
    TEXT: 'Sales Person Manage',
    LINK: '/SettingSalesPersonManage',
    MENU_ACTIVE: SIDE_MENU_LIST[10],
    MENU_CHILD_ACTIVE: 'Sales Person',
  },
  PRE_TRANSACTION: {
    TEXT: 'Pre Transaction',
    LINK: '/PreTransaction',
    MENU_ACTIVE: lang_en.sideMenu.projectDocument,
    MENU_CHILD_ACTIVE: 'Pre Transaction',
  },
  PRE_TRANSACTION_INFORMATION: {
    TEXT: 'Project Information',
    LINK: '/PreTransaction/Information',
    MENU_ACTIVE: lang_en.sideMenu.projectDocument,
    MENU_CHILD_ACTIVE: 'Pre Transaction',
  },
  CERTIFICATE_MANAGE: {
    TEXT: 'Certificate Manage',
    LINK: '/CertificateManage',
    MENU_ACTIVE: lang_en.sideMenu.projectDocument,
    MENU_CHILD_ACTIVE: 'Pre Transaction',
  },
  PROJECT_REFERENCE: {
    TEXT: 'Project Reference',
    LINK: '/ProjectReference',
    MENU_ACTIVE: lang_en.sideMenu.projectDocument,
    MENU_CHILD_ACTIVE: 'Project Reference',
  },
  PROJECT_DOCUMENT_REFERENCE_MANAGE: {
    TEXT: 'Project Reference',
    LINK: '/ProjectDocument/Reference/Manage',
    MENU_ACTIVE: lang_en.sideMenu.projectDocument,
    MENU_CHILD_ACTIVE: 'Project Reference',
  },
  PROJECT_DOCUMENT: {
    TEXT: 'Project Document',
    LINK: '/ProjectDocument',
    MENU_ACTIVE: lang_en.sideMenu.projectDocument,
  },
  PROJECT_DOCUMENT_INFORMATION: {
    TEXT: 'Project Document Information',
    LINK: '/ProjectDocument/Information',
    MENU_ACTIVE: lang_en.sideMenu.projectDocument,
  },
  PROJECT_DOCUMENT_MANAGE: {
    TEXT: 'Project Document',
    LINK: '/ProjectDocument/Manage',
    MENU_ACTIVE: lang_en.sideMenu.projectDocument,
  },
  PROJECT_DOCUMENT_DOCUMENTARY_MANAGE_SCREEN: {
    TEXT: 'Project Document Documentary Manage',
    LINK: '/ProjectDocument/DocumentaryManage',
    MENU_ACTIVE: lang_en.sideMenu.projectDocument,
  },
  NOTIFICATION_MANAGEMENT: {
    TEXT: 'Notification Management',
    LINK: '/NotificationManagement',
    MENU_ACTIVE: lang_en.sideMenu.notificationManagement,
  },
  PUBLIC: {
    NEWS_AND_PROMOTION: {
      LINK: '/Public/NewsAndPromotion',
      LINK_PARAMS: '/Public/NewsAndPromotion/:id',
    },
    WARRANTY_STATUS: {
      LINK: '/Public/WarrantyStatus',
      LINK_PARAMS: '/Public/WarrantyStatus/:country/:adId/:wrtId/:wrtNo',
    },
    YEAR_WARRANTY: {
      LINK: '/Public/YearWarranty',
      LINK_PARAMS: '/Public/YearWarranty',
    },
    WARRANTY_AND_DOCUMENTARY: {
      LINK: '/Public/WarrantyAndDocumentary',
    },
  },
}
