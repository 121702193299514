import React from 'react';
import {
  connect,
} from 'react-redux';
import {
  ShapeContainer,
  Section,
  Grid,
  Button,
  Table,
  TableAction,
  PaginationControl,
  Text
} from './../../components'
import {
  VARIABLES,
  ICONS
} from './../../themes'
import { getOrderInquiryList, getClearOrderInquiryParams } from '../../helpers/services/orderInquiry';
import { redirect, redirectData } from '../../helpers/functions/route';
import { ROUTE_PATH } from '../../helpers/constants/route';
import { ENUM } from '../../helpers/constants/enum';
import { getSortAscending } from '../../helpers/functions/data';
import { getLanguage } from '../../helpers/functions/language';
import _ from 'lodash';

class OrderInquiryListContainer extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      activePage: 1,
      dataPerPage: 10
    }
  }

  componentDidMount() {
    const {
      activePage,
      dataPerPage,
    } = this.state;
    const params = {
      ...getClearOrderInquiryParams(),
      page: activePage,
      limit: dataPerPage,
    };
    getOrderInquiryList(params);
  }

  orderInquiryList = {
    onClickSort: (target) => {
      getOrderInquiryList({
        sort: target
      });
    }
  }

  pagination = {
    onPaginationChange: (pageNumber) => {
      getOrderInquiryList({
        page: pageNumber,
      });
    }
  }


  render() {
    const {
      dataList,
      dataParams,
      totalData,
      activePage,
    } = this.props;
    const {
      dataPerPage,
    } = this.state;
    return (
      <ShapeContainer
        ui='content'
        justify='space-between'
      >
        <Section spacingBottom='45'>
          <Table
            striped
            action
          >
            <Table.Section>
              <Table.Row>
                <Table.HeadColumn
                  maxWidth={VARIABLES.TABLE.INFO_WIDTHS.ID}
                  minWidth={VARIABLES.TABLE.INFO_WIDTHS.ID}
                  sortAscending={getSortAscending(dataParams, 'id')}
                  onClickSort={() => {
                    this.orderInquiryList.onClickSort('id desc');
                  }}
                >
                  {getLanguage('orderInquiry.table.header.id', '')}
                </Table.HeadColumn>
                <Table.HeadColumn
                  minWidth={VARIABLES.TABLE.INFO_WIDTHS.SUBJECT}
                  sortAscending={getSortAscending(dataParams, 'subject')}
                  onClickSort={() => {
                    this.orderInquiryList.onClickSort('subject desc');
                  }}
                >
                  {getLanguage('orderInquiry.table.header.subject', '')}
                </Table.HeadColumn>
                <Table.HeadColumn
                  minWidth={VARIABLES.TABLE.INFO_WIDTHS.CUSTOMER_NAME}
                  sortAscending={getSortAscending(dataParams, 'customer_name')}
                  onClickSort={() => {
                    this.orderInquiryList.onClickSort('customer_name desc');
                  }}
                >
                  {getLanguage('orderInquiry.table.header.customer', '')}
                </Table.HeadColumn>
                <Table.HeadColumn
                  minWidth={VARIABLES.TABLE.INFO_WIDTHS.PHONE}
                  sortAscending={getSortAscending(dataParams, 'phone')}
                  onClickSort={() => {
                    this.orderInquiryList.onClickSort('phone desc');
                  }}
                >
                  {getLanguage('orderInquiry.table.header.phoneNo', '')}
                </Table.HeadColumn>
                <Table.HeadColumn
                  minWidth={VARIABLES.TABLE.INFO_WIDTHS.EMAIL}
                  sortAscending={getSortAscending(dataParams, 'email')}
                  onClickSort={() => {
                    this.orderInquiryList.onClickSort('email desc');
                  }}
                >
                  {getLanguage('orderInquiry.table.header.email', '')}
                </Table.HeadColumn>
                <Table.HeadColumn
                  minWidth={VARIABLES.TABLE.INFO_WIDTHS.DATE_TIME}
                  sortAscending={getSortAscending(dataParams, 'submit_at')}
                  onClickSort={() => {
                    this.orderInquiryList.onClickSort('submit_at desc');
                  }}
                >
                  {getLanguage('orderInquiry.table.header.pendingOn', '')}
                </Table.HeadColumn>
                <Table.HeadColumn
                  minWidth={VARIABLES.TABLE.INFO_WIDTHS.DATE_TIME}
                  sortAscending={getSortAscending(dataParams, 'response_at')}
                  onClickSort={() => {
                    this.orderInquiryList.onClickSort('response_at desc');
                  }}
                >
                  {getLanguage('orderInquiry.table.header.responseDate', '')}
                </Table.HeadColumn>
                <Table.HeadColumn
                  minWidth={VARIABLES.TABLE.INFO_WIDTHS.STATUS_ACKNOWLEDGED_PENDING}
                  sortAscending={getSortAscending(dataParams, 'status')}
                  onClickSort={() => {
                    this.orderInquiryList.onClickSort('status desc');
                  }}
                >
                  {getLanguage('orderInquiry.table.header.status', '')}
                </Table.HeadColumn>
                <Table.HeadColumn
                  noSort
                  alignCenter
                  maxWidth={VARIABLES.TABLE.INFO_WIDTHS.ACTION_DETAILS}
                  minWidth={VARIABLES.TABLE.INFO_WIDTHS.ACTION_DETAILS}
                >
                  {getLanguage('orderInquiry.table.header.action', '')}
                </Table.HeadColumn>
              </Table.Row>
            </Table.Section>
            <Table.Section>
              {
                dataList.map((e, i) => {
                  return (
                    <Table.Row
                      key={i}
                    >
                      <Table.BodyColumn
                        info
                        maxWidth={VARIABLES.TABLE.INFO_WIDTHS.ID}
                        minWidth={VARIABLES.TABLE.INFO_WIDTHS.ID}
                        title={e.idWithPrefix}
                      >
                        {e.idWithPrefix}
                      </Table.BodyColumn>
                      <Table.BodyColumn
                        info
                        minWidth={VARIABLES.TABLE.INFO_WIDTHS.SUBJECT}
                        title={e.subject}
                      >
                        {e.subject}
                      </Table.BodyColumn>
                      <Table.BodyColumn
                        info
                        minWidth={VARIABLES.TABLE.INFO_WIDTHS.CUSTOMER_NAME}
                        title={e.customerName}
                      >
                        {e.customerName}
                      </Table.BodyColumn>
                      <Table.BodyColumn
                        phone
                        minWidth={VARIABLES.TABLE.INFO_WIDTHS.PHONE}
                        idPhone={e.phoneCountry.value}
                        srcFlag={e.phoneCountry.flag}
                      >
                        {e.phone}
                      </Table.BodyColumn>
                      <Table.BodyColumn
                        info
                        minWidth={VARIABLES.TABLE.INFO_WIDTHS.EMAIL}
                        title={e.email}
                      >
                        {e.email}
                      </Table.BodyColumn>
                      <Table.BodyColumn
                        info
                        minWidth={VARIABLES.TABLE.INFO_WIDTHS.DATE_TIME}
                      >
                        {e.displaySubmitAt}
                      </Table.BodyColumn>
                      <Table.BodyColumn
                        info
                        minWidth={VARIABLES.TABLE.INFO_WIDTHS.DATE_TIME}
                      >
                        {e.displayResponseAt}
                      </Table.BodyColumn>
                      <Table.BodyColumn
                        infoStatus
                        infoStatusColor={e.status === ENUM.ORDER_INQUIRY_STATUS.ACKNOWLEDGED ? VARIABLES.COLORS.GREEN_5 : VARIABLES.COLORS.RED_4}
                        minWidth={VARIABLES.TABLE.INFO_WIDTHS.STATUS_ACKNOWLEDGED_PENDING}
                        title={e.status}
                      >
                        {e.status}
                      </Table.BodyColumn>
                      <Table.BodyColumn
                        action
                        alignCenter
                        maxWidth={VARIABLES.TABLE.INFO_WIDTHS.ACTION_DETAILS}
                        minWidth={VARIABLES.TABLE.INFO_WIDTHS.ACTION_DETAILS}
                      >
                        <Button
                          blue
                          ui='frontIconSmall'
                          name={getLanguage('orderInquiry.table.action.details', '')}
                          iconWidth='12'
                          iconHeight='11'
                          iconSrc={ICONS['ic-updated.svg']}
                          onClick={() => {
                            redirectData(`${ROUTE_PATH.ORDER_INQUIRY.LINK}/${e.id}`, {
                              data: e,
                            });
                          }}
                        />
                      </Table.BodyColumn>
                    </Table.Row>
                  )
                })
              }
            </Table.Section>
          </Table>
        </Section>
        <TableAction>
          <TableAction.Left>
            {/* <Grid gutter='15'>
              <Grid.Column>
                <Button
                  blue
                  ui='frontIcon'
                  name='Print'
                  width={VARIABLES.BUTTON.WIDTHS.W_125}
                  iconWidth='18'
                  iconHeight='18'
                  iconSrc={ICONS['ic-printer.svg']}
                  onClick={() => { }}
                />
              </Grid.Column>
              <Grid.Column>
                <Button
                  green
                  ui='frontIcon'
                  name='Export'
                  width={VARIABLES.BUTTON.WIDTHS.W_125}
                  iconWidth='16'
                  iconHeight='16'
                  iconSrc={ICONS['ic-export.svg']}
                  onClick={() => { }}
                />
              </Grid.Column>
            </Grid> */}
          </TableAction.Left>
          <TableAction.Right>
            <Grid gutter='20'>
              <Grid.Column>
                <Section paddingTop='6'>
                  <Text
                    regular12
                    color={VARIABLES.COLORS.GRAY_16}
                  >
                    {getLanguage('pagination.page', '')} {activePage}/{Math.ceil(totalData / dataPerPage)}
                  </Text>
                </Section>
              </Grid.Column>
              <Grid.Column>
                <PaginationControl
                  activePage={activePage}
                  itemsCountPerPage={dataPerPage}
                  totalItemsCount={totalData}
                  pageRangeDisplayed={3}
                  onChange={this.pagination.onPaginationChange}
                />
              </Grid.Column>
            </Grid>
          </TableAction.Right>
        </TableAction>
      </ShapeContainer>
    )
  }
}

const mapStateToProps = (state) => {
  const dataRedux = state.dataRedux;
  return {
    dataList: dataRedux.orderInquiryList,
    dataParams: dataRedux.orderInquiryParams,
    activePage: dataRedux.orderInquiryParams.activePage,
    totalData: dataRedux.orderInquiryParams.totalData || 0,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
  }
}

export const OrderInquiryListContainerConnected = connect(
  mapStateToProps,
  mapDispatchToProps
)(OrderInquiryListContainer)