import React from 'react'
import ClassNames from 'classnames'
import PropTypes from 'prop-types'
import {
  ApplicationCardWrapper
} from './styled'
import {
  Text,
  Section,
  Grid,
  ShapeContainer,
} from './../../../components'
import {
  VARIABLES,
  ICONS,
} from '../../../themes'
import { getLanguage } from '../../../helpers/functions/language'

/**
 * ApplicationCard description:
 * - ApplicationCard
 */

export class ApplicationCard extends React.PureComponent {
  render() {
    const {
      className,
      isFail,
      isErrorQuantity,
      isSuccess,
      isNotSelected,
      applicationName,
      applicationNumber,
      applicationTotalNumber,
      segmentName,
      roofNumber,
      wallNumber,
      purlinNumber,
      trussNumber,
      coolRoomNumber,
      deckingNumber,
      airDuctingNumber,
      onClick,
    } = this.props

    // props for css classes
    const classes = ClassNames(
      'application-card',
      { 'is-fail': isFail },
      { 'is-success': isSuccess },
      { 'is-not-selected': isNotSelected },
      className
    )

    return (
      <ApplicationCardWrapper
        className={classes}
        onClick={onClick}
      >
        <Grid>
          <Grid.Column flex='1'>
            <Section spacingBottom='2'>
              <Text medium16 color={VARIABLES.COLORS.WHITE}>{applicationName}</Text>
            </Section>
            <Section spacingBottom='10'>
              <Grid gutter='5' align='center'>
                <Grid.Column>
                  <Text regular12 color={VARIABLES.COLORS.WHITE}>{getLanguage('warrantyManage.form.detail.quantityRemaining', '')}</Text>
                </Grid.Column>
                <Grid.Column>
                  <ShapeContainer justify='center' height='18' paddingLeft='10' paddingRight='10' bgColor={VARIABLES.COLORS.WHITE} borderRadius='9'>
                    <Text bold12 singleLine top='-1'
                      color={isFail ?
                        VARIABLES.COLORS.RED_8 :
                        isSuccess ? VARIABLES.COLORS.GREEN_1 :
                          VARIABLES.COLORS.PRIMARY_2
                      }
                    >
                      {applicationNumber}
                    </Text>
                  </ShapeContainer>
                </Grid.Column>
                <Grid.Column>
                  <Text regular12 color={VARIABLES.COLORS.WHITE}>/ {applicationTotalNumber}</Text>
                </Grid.Column>
              </Grid>
            </Section>
          </Grid.Column>
          <Grid.Column flex='none'>
            {isFail && <img src={ICONS['ic-notification-error-white.svg']} alt='Icon' />}
            {isSuccess && <img src={ICONS['ic-notification-success-white.svg']} alt='Icon' />}
          </Grid.Column>
        </Grid>
        <ShapeContainer className='is-application-content' fluid padding='10' bgColor={VARIABLES.COLORS.WHITE} borderRadius='3'>
          <Section>
            <Text className='is-segment-name' bold14 color={VARIABLES.COLORS.PRIMARY_2}>{segmentName}</Text>
          </Section>
          {(!roofNumber && !wallNumber && !purlinNumber && !trussNumber && !coolRoomNumber && !deckingNumber) &&
            <Section className='is-no-application-content' spacingTop='10'>
              <Section justify='center' spacingBottom='5'>
                <img src={ICONS['ic-menu-segment-blue.svg']} alt='Icon' />
              </Section>
              <Section justify='center'>
                <Text regular12 color={VARIABLES.COLORS.BLUE_16}>{getLanguage('warrantyManage.form.detail.noInformation', '')}</Text>
              </Section>
            </Section>
          }
          {roofNumber &&
            <Section spacingTop='5'>
              <Grid gutter='15'>
                <Grid.Column flex='1'>
                  <Text regular14 color={VARIABLES.COLORS.BLACK}>{getLanguage('warrantyManage.form.detail.roof', '')}</Text>
                </Grid.Column>
                <Grid.Column flex='none'>
                  <Text regular14 color={isFail ? VARIABLES.COLORS.RED_8 : VARIABLES.COLORS.BLACK}>{roofNumber}</Text>
                </Grid.Column>
              </Grid>
            </Section>
          }
          {wallNumber &&
            <Section spacingTop='5'>
              <Grid gutter='15'>
                <Grid.Column flex='1'>
                  <Text regular14 color={VARIABLES.COLORS.BLACK}>{getLanguage('warrantyManage.form.detail.wall', '')}</Text>
                </Grid.Column>
                <Grid.Column flex='none'>
                  <Text regular14 color={isFail ? VARIABLES.COLORS.RED_8 : VARIABLES.COLORS.BLACK}>{wallNumber}</Text>
                </Grid.Column>
              </Grid>
            </Section>
          }
          {purlinNumber &&
            <Section spacingTop='5'>
              <Grid gutter='15'>
                <Grid.Column flex='1'>
                  <Text regular14 color={VARIABLES.COLORS.BLACK}>{getLanguage('warrantyManage.form.detail.purlin', '')}</Text>
                </Grid.Column>
                <Grid.Column flex='none'>
                  <Text regular14 color={isFail ? VARIABLES.COLORS.RED_8 : VARIABLES.COLORS.BLACK}>{purlinNumber}</Text>
                </Grid.Column>
              </Grid>
            </Section>
          }
          {trussNumber &&
            <Section spacingTop='5'>
              <Grid gutter='15'>
                <Grid.Column flex='1'>
                  <Text regular14 color={VARIABLES.COLORS.BLACK}>{getLanguage('warrantyManage.form.detail.truss', '')}</Text>
                </Grid.Column>
                <Grid.Column flex='none'>
                  <Text regular14 color={isFail ? VARIABLES.COLORS.RED_8 : VARIABLES.COLORS.BLACK}>{trussNumber}</Text>
                </Grid.Column>
              </Grid>
            </Section>
          }
          {coolRoomNumber &&
            <Section spacingTop='5'>
              <Grid gutter='15'>
                <Grid.Column flex='1'>
                  <Text regular14 color={VARIABLES.COLORS.BLACK}>{getLanguage('warrantyManage.form.detail.coolRoom', '')}</Text>
                </Grid.Column>
                <Grid.Column flex='none'>
                  <Text regular14 color={isFail ? VARIABLES.COLORS.RED_8 : VARIABLES.COLORS.BLACK}>{coolRoomNumber}</Text>
                </Grid.Column>
              </Grid>
            </Section>
          }
          {deckingNumber &&
            <Section spacingTop='5'>
              <Grid gutter='15'>
                <Grid.Column flex='1'>
                  <Text regular14 color={VARIABLES.COLORS.BLACK}>{getLanguage('warrantyManage.form.detail.decking', '')}</Text>
                </Grid.Column>
                <Grid.Column flex='none'>
                  <Text regular14 color={isFail ? VARIABLES.COLORS.RED_8 : VARIABLES.COLORS.BLACK}>{deckingNumber}</Text>
                </Grid.Column>
              </Grid>
            </Section>
          }
          {airDuctingNumber &&
            <Section spacingTop='5'>
              <Grid gutter='15'>
                <Grid.Column flex='1'>
                  <Text regular14 color={VARIABLES.COLORS.BLACK}>{getLanguage('warrantyManage.form.detail.airDucting', '')}</Text>
                </Grid.Column>
                <Grid.Column flex='none'>
                  <Text regular14 color={isFail ? VARIABLES.COLORS.RED_8 : VARIABLES.COLORS.BLACK}>{airDuctingNumber}</Text>
                </Grid.Column>
              </Grid>
            </Section>
          }
          {isErrorQuantity &&
            <Section spacingTop='5'>
              <Text regular12 color={VARIABLES.COLORS.RED_8}>{getLanguage('warrantyManage.form.detail.exceedQuantity', '')}</Text>
            </Section>
          }
        </ShapeContainer>
      </ApplicationCardWrapper>
    )
  }

  static defaultProps = {
    // * If you set new default props, you should create propsType for correct styleguide document.
    applicationName: '-',
    applicationNumber: '0',
    applicationTotalNumber: '0',
    segmentName: '-',
  }

  static propTypes = { // TYPE > node, string, number, bool, array, object, symbol, func
    /**
    * Additional classes
    */
    className: PropTypes.string
  }
}
