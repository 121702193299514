import React from 'react'
import ClassNames from 'classnames'
import PropTypes from 'prop-types'
import {
  osName,
} from 'react-device-detect'
import {
  WarrantyCladdingIssuesListsWrapper
} from './styled'
import {
  Section,
  Text,
  Image,
  Grid,
  Table,
  ShapeContainer,
} from './../../../../components'
import {
  VARIABLES,
} from './../../../../themes'

/**
 * Warranty description:
 * - Warranty
 */

export class WarrantyBSWMMalaysiaCladdingIssuesLists extends React.PureComponent {
  render() {
    const {} = this.props

    // props for css classes
    const classes = ClassNames(
      'warranty-bswm-note',
    )

    return (
      <WarrantyCladdingIssuesListsWrapper
        className={classes}
      >
        <Grid>
          <Grid.Column flex='none'><Section width='17'><p className='warranty-bswm-text is-xs'>a.</p></Section></Grid.Column>
          <Grid.Column flex='1'><p className='warranty-bswm-text is-xs'>Keep sheet dry at all times while stacked in bundles prior to installation. Wet sheet should be separated and dried to minimise risk of accelerated corrosion due to “wet stacking”.</p></Grid.Column>
        </Grid>
        <Grid>
          <Grid.Column flex='none'><Section width='17'><p className='warranty-bswm-text is-xs'>b.</p></Section></Grid.Column>
          <Grid.Column flex='1'><p className='warranty-bswm-text is-xs'>Do not cut sheet by circular saw or grinders as high temperature swarf will damage the coating.</p></Grid.Column>
        </Grid>
        <Grid>
          <Grid.Column flex='none'><Section width='17'><p className='warranty-bswm-text is-xs'>c.</p></Section></Grid.Column>
          <Grid.Column flex='1'><p className='warranty-bswm-text is-xs'>During construction, remove swarf and other debris daily to minimise risk of localised corrosion.</p></Grid.Column>
        </Grid>
      </WarrantyCladdingIssuesListsWrapper>
    )
  }

  static defaultProps = {
    // If you set new default props, you should create propsType for correct styleguide document.
  }

  static propTypes = {
    // TYPE > node, string, number, bool, array, object, symbol, func
  }
}
