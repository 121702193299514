import React from 'react';
import { ENUM } from '../../helpers/constants/enum';
import { getLanguage } from '../../helpers/functions/language';
import { generateAllWarranty, isValidTemplateExtendYear, isValidTemplateSegmentSpecial, setWarrantyData } from '../../helpers/functions/warranty';
import {
  Button,
  Field,
  Grid,
  Section,
  ShapeContainer,
  Text,
  TitleSection,
} from './../../components';
import { ModalSocialShareContainer, ModalWarrantyTncContainer } from './../../containers/ModalContainer';
import {
  ICONS,
  VARIABLES,
} from './../../themes';

export class WarrantyManageWarranty extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      modalTypeList: ['share', 'tnc'],
      dataDetail: props.dataDetailFromView ? props.dataDetailFromView : {},
      emailBody: '',
      emailSubject: '',

      //bswm
      modalWarrantyTncData: {
        isAcceptTncTemplate: false,
      },
      tncTemplateIndex: undefined
    }
  }

  componentDidMount() {
    const {
      isViewWarrantyDetail,
      data,
    } = this.props;
    if (!isViewWarrantyDetail) {
      setWarrantyData(data).then(() => {
        generateAllWarranty();
      });
    }
  }

  warrantyManageWarranty = {
    isAllTncAccept: () => {
      const {
        data,
      } = this.props;
      if (data.isGenerateResidential === false) {
        // CR: 1497, 1498
        const templateList = data.templateList.filter(e => isValidTemplateSegmentSpecial(data, e) && isValidTemplateExtendYear(data, e))
        return templateList.filter(e => e.isAcceptTncTemplate).length === templateList.length;
      }
      else {
        return true;
      }
    },
    onClickBack: async () => {
      const {
        onClickBack,
      } = this.props;
      // isShowLoading(true);
      // await deleteAllWarranty();
      // isShowLoading(false);
      onClickBack();
    },
    onClickEdit: async () => {
      const {
        onClickEdit,
      } = this.props;
      // isShowLoading(true);
      // await deleteAllWarranty();
      // isShowLoading(false);
      onClickEdit();
    },
    onClickView: (index) => {
      const {
        onClickView,
        data,
      } = this.props;
      const dataDetail = data.templateList[index];
      this.setState({
        dataDetail,
        tncTemplateIndex: index,
      }, () => {
        onClickView();
      })
    },
    onClickShare: () => {
      const {
        data,
      } = this.props;
      const {
        modalTypeList,
        dataDetail,
      } = this.state;
      let emailSubject = `${data.projectName} ${data.wrtNo}`;
      let emailBody = `Please check below link for warranty claim: ${dataDetail.fileUrl}\r\n\r\nPlease do not reply this email. If you have any question or enquiry please contact 02-333-3030`

      if (data.projectCountry === ENUM.COUNTRY_TYPE.MALAYSIA) {
        emailSubject = `Warranty Certificate: ${data.wrtNo} - ${data.productGroupName} - ${data.projectName ? data.projectName : data.customerName}`
        emailBody = `Dear Sir / Madam,

We hereby acknowledge that you have accepted the terms and condition for the attached warranty.


Please check below link for warranty claim: ${dataDetail.fileUrl}

Thank you.

From,
Warranty Administrator

If you wish to contact us, you may contact us at:

NS BlueScope Malaysia Sdn Bhd
A Lot 1551, Jalan Bukit Kapar, 42200 Kapar, Selangor Malaysia   T +60 3 3361 6888 | F +60 3 3361 6889   W www.bluescope.com.my
Person in charge :  Grace Leong    Email : Grace.Leong@bluescope.com     Direct Line : +603 5022 1192`
      }

      console.log('emailSubject', emailSubject)
      this.setState({
        modalType: modalTypeList[0],
        emailSubject,
        emailBody
        // sharePath: 'https://storage.googleapis.com/bluescope-955e7.appspot.com/mock/T1ST0100784.pdf'
      });
    },
    onCloseModal: () => {
      this.setState({
        modalType: undefined,
      });
    },
    onClickDownload: (index) => {
      const {
        data,
      } = this.props;
      const {
        dataDetail,
      } = this.state;
      let tempData = dataDetail;
      if (index !== undefined) {
        tempData = data.templateList[index]
      }
      window.open(tempData.fileUrl, '_blank')
    },
    onOpenWarrantyTnc: (templateData, index) => {
      const {
        modalTypeList,
      } = this.state;
      this.setState({
        modalType: modalTypeList[1],
        modalWarrantyTncData: {
          isAcceptTncTemplate: templateData.isAcceptTncTemplate,
          dataDetail: templateData,
        },
        tncTemplateIndex: index,
      })
    },
    onClickRequireDocumentary: () => {
      const {
        onClickRequireDocumentary,
      } = this.props;
      onClickRequireDocumentary();
    }
  }

  input = {
    onChangeWarrantyTncCheckbox: (ev, index) => {
      const {
        handleUpdateDataList,
        data,
      } = this.props;
      const {
        modalWarrantyTncData,
      } = this.state;
      const checked = ev.target.checked;
      let templateList = data.templateList;
      modalWarrantyTncData.isAcceptTncTemplate = checked;
      templateList[index].isAcceptTncTemplate = checked;
      this.setState({
        modalWarrantyTncData,
      });
      handleUpdateDataList(templateList);
    },
    onChangeAllWarrantyTncCheckbox: (ev) => {
      const {
        handleUpdateDataList,
        data,
      } = this.props;
      const checked = ev.target.checked;
      let templateList = data.templateList;
      templateList = templateList.map(e => {
        e.isAcceptTncTemplate = checked;
        return e;
      })

      handleUpdateDataList(templateList);
    }
  }

  render() {
    const {
      data,
      onClickSave,
      isPreview,
      isViewerPopup,
      isViewWarrantyDetail,
      onClickCloseView,
      onClickSaveDraft,
      getTemplateFormName,
    } = this.props;
    const {
      modalType,
      modalTypeList,
      dataDetail,
      emailBody,
      emailSubject,
      modalWarrantyTncData,
      tncTemplateIndex,
    } = this.state;
    const templateList = data.templateList;
    return (
      <React.Fragment>
        <ShapeContainer
          ui='contentStep'
          justify='space-between'
        >
          <Section spacingBottom='45'>
            <Section spacingBottom='45'>
              <TitleSection name={getLanguage('warrantyManage.form.manage.title', '')} />
            </Section>
            {isPreview ?
              <React.Fragment>
                <Section
                  ui='googleViewer'
                  spacingBottom='30'
                  isViewerPopup={isViewerPopup}
                  isViewerUploading={dataDetail.isViewerUploading}
                  isViewerLoading={dataDetail.isViewerLoading}
                  url={dataDetail.fileUrl}
                />
                {!isViewWarrantyDetail &&
                  <Grid gutter='10' justify='center' align='center'>
                    <Grid.Column>
                      <Field
                        ui='checkboxTick'
                        // name={}
                        // value={}
                        checked={!!dataDetail.isAcceptTncTemplate}
                        onChange={(ev) => {
                          this.input.onChangeWarrantyTncCheckbox(ev, tncTemplateIndex);
                        }}
                      />
                    </Grid.Column>
                    <Grid.Column>
                      <Text
                        regular12
                        underline
                        color={VARIABLES.COLORS.PRIMARY_2}
                        onClick={() => {
                          this.warrantyManageWarranty.onOpenWarrantyTnc(dataDetail, tncTemplateIndex);
                        }}
                      >
                        {getLanguage('field.buttonAcceptTermsAndConditions', '')}
                      </Text>
                    </Grid.Column>
                  </Grid>
                }
              </React.Fragment>
              :
              <React.Fragment>
                {templateList.map((e, i) => {
                  // CR: 1497, 1498
                  if (!isValidTemplateSegmentSpecial(data, e) || !isValidTemplateExtendYear(data, e)) {
                    return null
                  }
                  return (
                    <Section spacingBottom='15'
                      key={i}
                    >
                      <ShapeContainer
                        ui='warrantyList'
                        // warrantyName={e.productGroupName}
                        warrantyName={getTemplateFormName(e)}
                        onClickWarrantyPreview={() => {
                          this.warrantyManageWarranty.onClickView(i)
                        }}
                        // onClickWarrantyDownload={() => {
                        //   this.warrantyManageWarranty.onClickDownload(i)
                        // }}
                        warrantyTermsAndConditions={data.isGenerateResidential === false}
                        warrantyTermsAndConditionsName={''}
                        warrantyTermsAndConditionsValue={''}
                        checkedWarrantyTermsAndConditions={!!e.isAcceptTncTemplate}
                        onChangeWarrantyTermsAndConditions={(ev) => {
                          this.input.onChangeWarrantyTncCheckbox(ev, i)
                        }}
                        onClickWarrantyTermsAndConditions={() => {
                          this.warrantyManageWarranty.onOpenWarrantyTnc(e, i);
                        }}
                      />
                    </Section>
                  )
                })}
              </React.Fragment>
            }
          </Section>
          <Section>
            <Grid
              gutter='15'
              gutterVertical='15'
              justify='flex-end'
              align='center'
            >
              {isPreview ?
                <React.Fragment>
                  <Grid.Column>
                    <Button
                      gray
                      ui='back'
                      name={getLanguage('field.buttonBack', '')}
                      width={VARIABLES.BUTTON.WIDTHS.W_135}
                      onClick={onClickCloseView}
                    />
                  </Grid.Column>
                  {isViewWarrantyDetail ?
                    <React.Fragment>
                      <Grid.Column>
                        <Button
                          mediumBlue
                          ui='frontIcon'
                          name={getLanguage('field.buttonShare', '')}
                          width={VARIABLES.BUTTON.WIDTHS.W_135}
                          iconWidth='18'
                          iconHeight='18'
                          iconSrc={ICONS['ic-share.svg']}
                          onClick={this.warrantyManageWarranty.onClickShare}
                        />
                      </Grid.Column>
                      {/* <Grid.Column>
                        <Button
                          blue
                          ui='frontIcon'
                          name='Print'
                          width={VARIABLES.BUTTON.WIDTHS.W_135}
                          iconWidth='18'
                          iconHeight='18'
                          iconSrc={ICONS['ic-printer.svg']}
                          onClick={() => {
                          }}
                        />
                      </Grid.Column> */}
                      <Grid.Column>
                        <Button
                          green
                          ui='frontIcon'
                          name={getLanguage('field.buttonDownload', '')}
                          width={VARIABLES.BUTTON.WIDTHS.W_155}
                          iconWidth='18'
                          iconHeight='18'
                          iconSrc={ICONS['ic-download-circle.svg']}
                          onClick={() => {
                            this.warrantyManageWarranty.onClickDownload()
                          }}
                        />
                      </Grid.Column>
                    </React.Fragment>
                    :
                    <Grid.Column>
                      <Button
                        lightBlue
                        ui='frontIcon'
                        name={getLanguage('field.buttonEdit', '')}
                        width={VARIABLES.BUTTON.WIDTHS.W_135}
                        iconWidth='13'
                        iconHeight='13'
                        iconSrc={ICONS['ic-edit.svg']}
                        onClick={this.warrantyManageWarranty.onClickEdit}
                      />
                    </Grid.Column>
                  }
                </React.Fragment>
                :
                <React.Fragment>
                  {!isViewWarrantyDetail && data.isGenerateResidential === false &&
                    <Grid.Column>
                      <Field
                        ui='checkboxTick'
                        // name={}
                        // value={}
                        checked={this.warrantyManageWarranty.isAllTncAccept()}
                        checkboxLabel='Accept Terms and Conditions to All Warranty'
                        onChange={this.input.onChangeAllWarrantyTncCheckbox}
                      />
                    </Grid.Column>
                  }
                  <Grid.Column>
                    <Button
                      gray
                      ui='back'
                      name={getLanguage('field.buttonBack', '')}
                      onClick={this.warrantyManageWarranty.onClickBack}
                    />
                  </Grid.Column>
                  {data.isShowSaveDraft &&
                    <Grid.Column>
                      <Button
                        lightBlue
                        name={getLanguage('field.buttonSaveAsDraft', '')}
                        onClick={onClickSaveDraft}
                      />
                    </Grid.Column>
                  }
                  {/* RL-703 */}
                  {data.isShowDocumentary &&
                    <React.Fragment>
                      <Grid.Column>
                        <Button
                          lightGreen
                          disabled={!this.warrantyManageWarranty.isAllTncAccept()}
                          name={getLanguage('field.buttonRequireDocumentary', '')}
                          width={170}
                          onClick={this.warrantyManageWarranty.onClickRequireDocumentary}
                        />
                      </Grid.Column>
                      <Grid.Column>
                        <Text regular14 color={VARIABLES.COLORS.BLACK}>{getLanguage('warrantyManage.form.manage.or', '')}</Text>
                      </Grid.Column>
                    </React.Fragment>
                  }
                  <Grid.Column>
                    <Button
                      blue
                      disabled={this.warrantyManageWarranty.isAllTncAccept() === false}
                      name={getLanguage('field.buttonConfirmAndSAVE', '')}
                      width={170}
                      onClick={onClickSave}
                    />
                  </Grid.Column>
                </React.Fragment>
              }
            </Grid>
          </Section>
        </ShapeContainer>
        {
          modalType === modalTypeList[0] &&
          <ModalSocialShareContainer
            isOpenModal
            title={getLanguage('modal.share.title', '')}
            url={dataDetail.fileUrl}
            emailBody={emailBody}
            emailSubject={emailSubject}
            quote='Share warranty'
            onClickClose={this.warrantyManageWarranty.onCloseModal}
          />
        }
        {
          modalType === modalTypeList[1] &&
          <ModalWarrantyTncContainer
            isOpenModal
            dataDetail={modalWarrantyTncData.dataDetail}
            isSelected={modalWarrantyTncData.isAcceptTncTemplate}
            onChangeCheckbox={(ev) => {
              this.input.onChangeWarrantyTncCheckbox(ev, tncTemplateIndex);
            }}
            onClickClose={this.warrantyManageWarranty.onCloseModal}
          />
        }
      </React.Fragment>
    )
  }
}