import { DATA_REDUX_STATE_KEY, dataCreators } from "../../actions/dataAction";
import { userController } from "../../apiService/apiController/userService";
import { ModelUser } from "../../models/ModelUser";
import { storeDispatch, storeGetState } from "../../stores";
import { handleSortParams, handleTrimParams } from "../functions/data";
import { isValidResponse } from "../functions/validation";

export const getUserList = async (params = {}) => {
  let currentParams = storeGetState().dataRedux.userParams;
  let newParams = handleTrimParams({
    ...currentParams,
    ...params,
  });
  if (newParams.searchFullname) {
    const nameList = newParams.searchFullname.split(' ');
    newParams.first_name = nameList[0];
    newParams.last_name = nameList[nameList.length - 1];
  }
  if (!newParams.isNoSort) {
    if (!newParams.sort) {
      newParams.sort = 'id desc';
    }
    else {
      newParams = handleSortParams(currentParams, newParams);
    }
  }
  const userService = userController();
  const res = await userService.searchUsers(newParams);
  if (isValidResponse(res)) {
    const dataList = res.data.map(e => new ModelUser(e));
    newParams.activePage = newParams.page;
    newParams.totalData = res.total_items;
    if (params.callbackSuccess) {
      params.callbackSuccess(dataList, newParams);
    }
    console.log('getUserList', res.data, dataList, newParams);
    storeDispatch(dataCreators.setDataRedux(DATA_REDUX_STATE_KEY.USER_LIST, dataList));
    storeDispatch(dataCreators.setDataRedux(DATA_REDUX_STATE_KEY.USER_PARAMS, newParams));
  }
}

export const getClearUserParams = () => {
  return {
    email: '',
    first_name: '',
    last_name: '',
    phone: '',
    searchFullname: '',
    sort: '',
    isNoSort: false,
  }
}