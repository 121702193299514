import apiService from '../apiService'

const apiPath = '/saleperson'

export const salePersonController = (configService = {}) => {
  const service = apiService(configService)
  return {
    createSalePerson: (params) => {
      return service.post(`${apiPath}/create`, params)
    },
    updateSalePerson: (params) => {
      return service.put(`${apiPath}/update`, params)
    },
    updateSalePersonById: (params) => {
      return service.put(`${apiPath}/${params.id}`, params)
    },
    deleteSalePersonById: (params) => {
      return service.delete(`${apiPath}/${params.id}`, params)
    },
    searchSalePersonWithPagination: (params) => {
      return service.post(`${apiPath}/search`, params)
    },
  }
}
