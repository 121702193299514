import React from 'react';
import { connect, } from 'react-redux';
import {
  Section,
  Grid,
  Modal,
  Text,
  Button,
  Field,
  PaginationControl,
  Table,
} from './../../../components';
import {
  VARIABLES,
} from './../../../themes';

import { storeDispatch, storeGetState } from '../../../stores';
import { dataCreators, DATA_REDUX_STATE_KEY } from '../../../actions/dataAction';
import { ROUTE_PATH } from '../../../helpers/constants/route';
import { redirect } from '../../../helpers/functions/route';
import { CoilTransferContainerConnected } from '../../CoilTransferContainer/CoilTransferContainer';
import { bindActionCreators } from 'redux';
import { getLanguage } from '../../../helpers/functions/language';
import _ from 'lodash';

export class ModalSelectADContainer extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isDisableButtonSubmit: true,
      dataSelected: undefined,
    };
  }

  modalSelectAD = {
    onClickSelect: (data) => {
      this.setState({
        dataSelected: data,
        isDisableButtonSubmit: false
      });
    },
    onClickSubmit: () => {
      const {
        onClickSubmit = () => { },
      } = this.props;
      const {
        dataSelected,
      } = this.state;
      onClickSubmit(dataSelected);
    }
  }

  render() {
    const {
      dataList = [],
      onClickClose,
    } = this.props;
    const {
      isDisableButtonSubmit,
      dataSelected,
    } = this.state;
    return (
      <React.Fragment>
        <Modal
          open
          scroll
          // enableOverlayClose={false}
          width={VARIABLES.MODAL.WIDTHS.W_1145}
          overlayColor={VARIABLES.COLORS.OVERLAY_BLACK_1}
          spacing={VARIABLES.MODAL.CONTAINER.C_1.SPACING}
          transition={VARIABLES.TRANSITIONS.DEFAULT}
          transformStart={VARIABLES.MODAL.CONTAINER.C_1.TRANSFORM_START}
          transformEnd={VARIABLES.MODAL.CONTAINER.C_1.TRANSFORM_END}
          onClickClose={onClickClose}
        >
          <Modal.Section
            paddingVertical='30'
            paddingHorizontal='40'
            breakpointMobile='413px'
            paddingMobileVertical='15'
            paddingMobileHorizontal='20'
            bgColor={VARIABLES.COLORS.WHITE}
            borderBottomWidth='1'
            borderBottomStyle='solid'
            borderBottomColor={VARIABLES.COLORS.GRAY_8}
            borderTopRightRadius={VARIABLES.MODAL.HEADER.H_1.BORDER_TOP_RIGHT_RADIUS}
            borderTopLeftRadius={VARIABLES.MODAL.HEADER.H_1.BORDER_TOP_LEFT_RADIUS}
          >
            <Text
              regular16
              singleLine
              color={VARIABLES.COLORS.BLACK}
            >
              {getLanguage('modal.adAndRf.title', '')}
            </Text>
          </Modal.Section>
          <Modal.Section
            paddingTop='20'
            paddingBottom='30'
            paddingHorizontal='45'
            breakpointMobile='413px'
            paddingMobileVertical='15'
            paddingMobileHorizontal='25.5'
            bgColor={VARIABLES.COLORS.WHITE}
          >
            <Section spacingBottom='30' calcHeight='100vh - 480px' >
              <Table
                // striped
                stacked
                actionCheckbox
              >
                <Table.Section>
                  <Table.Row>
                    <Table.HeadColumn
                      maxWidth={VARIABLES.TABLE.COLUMN_WIDTHS.IDS.COIL}
                      minWidth={VARIABLES.TABLE.COLUMN_WIDTHS.IDS.COIL}
                      noSort
                    >
                      {getLanguage('container.coilTransferTable.table.header.id', '')}
                    </Table.HeadColumn>
                    <Table.HeadColumn
                      minWidth={VARIABLES.TABLE.COLUMN_WIDTHS.NAMES.FULL}
                      noSort
                    >
                      {getLanguage('container.coilTransferTable.table.header.adRF', '')}
                    </Table.HeadColumn>
                    <Table.HeadColumn
                      alignCenter
                      noSort
                      maxWidth={VARIABLES.TABLE.COLUMN_WIDTHS.TRAIN}
                      minWidth={VARIABLES.TABLE.COLUMN_WIDTHS.TRAIN}
                    >
                      {getLanguage('container.coilTransferTable.table.header.select', '')}
                    </Table.HeadColumn>
                  </Table.Row>
                </Table.Section>
                <Section scrollOverlay calcHeight='100vh - 480px' >
                  <Table.Section>
                    {
                      dataList.map((e, i) => {
                        return (
                          <Table.Row
                            pointer
                            key={i}
                            onClick={() => {
                              this.modalSelectAD.onClickSelect(e);
                            }}
                          >
                            <Table.BodyColumn
                              info
                              maxWidth={VARIABLES.TABLE.COLUMN_WIDTHS.IDS.COIL}
                              minWidth={VARIABLES.TABLE.COLUMN_WIDTHS.IDS.COIL}
                              title={e.fromDealer}
                            >
                              {e.fromDealer}
                            </Table.BodyColumn>
                            <Table.BodyColumn
                              info
                              minWidth={VARIABLES.TABLE.COLUMN_WIDTHS.NAMES.FULL}
                              title={e.dealerName}
                            >
                              {e.dealerName}
                            </Table.BodyColumn>
                            <Table.BodyColumn
                              selected={e.isSelected}
                              action
                              alignCenter
                              maxWidth={VARIABLES.TABLE.COLUMN_WIDTHS.TRAIN}
                              minWidth={VARIABLES.TABLE.COLUMN_WIDTHS.TRAIN}
                            >
                              <Field
                                checked={(dataSelected || {}).fromDealer === e.fromDealer}
                                ui='checkboxTickFillBlank'
                                onChange={() => { }}
                              />
                            </Table.BodyColumn>
                          </Table.Row>
                        )
                      })
                    }
                  </Table.Section>
                </Section>
              </Table>
            </Section>
          </Modal.Section>
          <Modal.Section
            paddingVertical='30'
            paddingHorizontal='45'
            breakpointMobile='413px'
            paddingMobileVertical='15'
            paddingMobileHorizontal='25.5'
            borderTopWidth={VARIABLES.MODAL.FOOTER.F_1.BORDER_TOP_WIDTH}
            borderTopStyle={VARIABLES.MODAL.FOOTER.F_1.BORDER_TOP_STYLE}
            borderTopColor={VARIABLES.COLORS.GRAY_8}
            borderBottomRightRadius={VARIABLES.MODAL.FOOTER.F_1.BORDER_BOTTOM_RIGHT_RADIUS}
            borderBottomLeftRadius={VARIABLES.MODAL.FOOTER.F_1.BORDER_BOTTOM_LEFT_RADIUS}
          >
            <Grid
              gutter='15'
              justify='flex-end'
              gutterVertical='20'
              gutterMobile='15'
            >
              <Grid.Column
                gridMobile='6'
                breakpointMobile='320px'
                breakpointThablet='531px'
              >
                <Button
                  gray
                  breakpointMobile
                  width={VARIABLES.BUTTON.WIDTHS.W_155}
                  name={getLanguage('field.buttonCancel', '')}
                  onClick={onClickClose}
                />
              </Grid.Column>
              <Grid.Column
                gridMobile='6'
                breakpointMobile='320px'
                breakpointThablet='531px'
              >
                <Button
                  blue
                  breakpointMobile
                  disabled={isDisableButtonSubmit}
                  width={VARIABLES.BUTTON.WIDTHS.W_155}
                  name={getLanguage('field.buttonSelect', '')}
                  onClick={this.modalSelectAD.onClickSubmit}
                />
              </Grid.Column>
            </Grid>
          </Modal.Section>
        </Modal>
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => {
  const dataRedux = state.dataRedux;
  return {
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    dataActions: bindActionCreators(dataCreators, dispatch),
  }
}

export const ModalSelectADContainerConnected = connect(
  mapStateToProps,
  mapDispatchToProps
)(ModalSelectADContainer)