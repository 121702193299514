import React from 'react';
import {
	Table,
	Field,
} from './../../components'
import { VARIABLES } from '../../themes';
import { storeDispatch } from '../../stores';
import { dataCreators, DATA_REDUX_STATE_KEY } from '../../actions/dataAction';
import { handleSortData } from '../../helpers/functions/data';
import _ from 'lodash';
import { getLanguage } from '../../helpers/functions/language';

export class ProfileTransferTableContainer extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			sortIcon: {
				id: false,
				adRfName: true,
				phone: true,
				email: true,
				type: true,
				province: true,
			}
		}
	}

	adRfList = {
		updateData: (dataList) => {
			storeDispatch(dataCreators.setDataRedux(DATA_REDUX_STATE_KEY.AD_RF_LIST, dataList));
		},
		handleSortIcon: (type) => {
			const {
				sortIcon,
			} = this.state;
			let newSortIcon = {};
			let oldType = type;
			Object.keys(sortIcon).forEach(e => {
				if (sortIcon[e] === false) {
					oldType = e;
				}
				newSortIcon[e] = true;
			});
			Object.keys(sortIcon).forEach(e => {
				if (e === type) {
					if (type === oldType) {
						newSortIcon[e] = !sortIcon[e];
					}
				}
			})
			this.setState({
				sortIcon: newSortIcon
			})
			return newSortIcon
		},
		onClickSort: (type) => {
			const {
				dataList,
			} = this.props;
			const newSortIcon = this.adRfList.handleSortIcon(type);
			const tempDataList = handleSortData(dataList, {
				sortTarget: type,
				orderType: newSortIcon[type] ? 'desc' : 'asc'
			});
			this.adRfList.updateData(tempDataList);
		},
		onClickSelect: (data) => {
			const {
				dataList,
				onClickCustomer,
			} = this.props;
			dataList.forEach(e => {
				if (e.id === data.id) {
					onClickCustomer(e);
				}
			})
		},
		getFilterDataPaginationList: () => {
			const {
				activePage,
				dataPerPage,
				dataList,
			} = this.props;
			const firstIndex = (activePage - 1) * dataPerPage;
			const lastIndex = activePage * dataPerPage;
			if (activePage && dataPerPage) {
				return this.adRfList.getFilterDataList().filter((e, i) => i >= firstIndex && i < lastIndex);
			}
			return dataList;
		},
		getFilterDataList: () => {
			const {
				dataList,
				filterValue = '',
			} = this.props;
			return dataList.filter((e, i) => {
				if (filterValue.length) {
					if (e.adRfName.toLowerCase().indexOf(filterValue.toLowerCase()) === -1 &&
						e.phoneNo.toLowerCase().indexOf(filterValue.toLowerCase()) === -1 &&
						e.email.toLowerCase().indexOf(filterValue.toLowerCase()) === -1
					) {
						return false;
					}
				}
				return true;
			})
		}
	}

	render() {
		const {
			adRfData
		} = this.props;
		const {
			sortIcon,
		} = this.state;
    const dataList = this.adRfList.getFilterDataPaginationList();
		return (
			<Table
				striped
				actionCheckbox
			>
				<Table.Section>
					<Table.Row>
						<Table.HeadColumn
							maxWidth={VARIABLES.TABLE.COLUMN_WIDTHS.IDS.INDEX}
							minWidth={VARIABLES.TABLE.COLUMN_WIDTHS.IDS.INDEX}
							sortAscending={sortIcon.id}
							onClickSort={() => {
								this.adRfList.onClickSort('id');
							}}
						>
							{getLanguage('container.profileTransferTable.table.header.id', '')}
						</Table.HeadColumn>
						<Table.HeadColumn
							minWidth={VARIABLES.TABLE.COLUMN_WIDTHS.NAMES.FULL}
							sortAscending={sortIcon.adRfName}
							onClickSort={() => {
								this.adRfList.onClickSort('adRfName');
							}}
						>
							{getLanguage('container.profileTransferTable.table.header.adRF', '')}
						</Table.HeadColumn>
						{/* <Table.HeadColumn
							maxWidth={VARIABLES.TABLE.COLUMN_WIDTHS.PHONE}
							minWidth={VARIABLES.TABLE.COLUMN_WIDTHS.PHONE}
							sortAscending={sortIcon.phone}
							onClickSort={() => {
								this.adRfList.onClickSort('phone');
							}}
						>
							{getLanguage('container.profileTransferTable.table.header.phoneNo', '')}
						</Table.HeadColumn>
						<Table.HeadColumn
							maxWidth={VARIABLES.TABLE.COLUMN_WIDTHS.EMAIL}
							minWidth={VARIABLES.TABLE.COLUMN_WIDTHS.EMAIL}
							sortAscending={sortIcon.email}
							onClickSort={() => {
								this.adRfList.onClickSort('email');
							}}
						>
							{getLanguage('container.profileTransferTable.table.header.email', '')}
						</Table.HeadColumn>
						<Table.HeadColumn
							maxWidth={VARIABLES.TABLE.INFO_WIDTHS.TYPE}
							minWidth={VARIABLES.TABLE.INFO_WIDTHS.TYPE}
							sortAscending={sortIcon.type}
							onClickSort={() => {
								this.adRfList.onClickSort('type');
							}}
						>
							{getLanguage('container.profileTransferTable.table.header.type', '')}
						</Table.HeadColumn> */}
						<Table.HeadColumn
							maxWidth={VARIABLES.TABLE.INFO_WIDTHS.PROVINCE}
							minWidth={VARIABLES.TABLE.INFO_WIDTHS.PROVINCE}
							sortAscending={sortIcon.province}
							onClickSort={() => {
								this.adRfList.onClickSort('province');
							}}
						>
							{getLanguage('container.profileTransferTable.table.header.province', '')}
						</Table.HeadColumn>
						<Table.HeadColumn
							alignCenter
							noSort
							maxWidth={VARIABLES.TABLE.COLUMN_WIDTHS.TRAIN}
							minWidth={VARIABLES.TABLE.COLUMN_WIDTHS.TRAIN}
						>
							{getLanguage('container.profileTransferTable.table.header.select', '')}
						</Table.HeadColumn>
					</Table.Row>
				</Table.Section>
				<Table.Section>
					{
						dataList.map((e, i) => {
							if (adRfData && adRfData.id === e.id) {
								e.isSelected = true;
							}
							else {
								e.isSelected = false;
							}
							return (
								<Table.Row
									pointer
									key={i}
									onClick={() => {
										this.adRfList.onClickSelect(e);
									}}
								>
									<Table.BodyColumn
										selected={e.isSelected} // Not use to row because when x scroll active > row bg color have not display
										info
										maxWidth={VARIABLES.TABLE.COLUMN_WIDTHS.IDS.INDEX}
										minWidth={VARIABLES.TABLE.COLUMN_WIDTHS.IDS.INDEX}
										title={e.idWithPrefix}
									>
										{e.idWithPrefix}
									</Table.BodyColumn>
									<Table.BodyColumn
										selected={e.isSelected}
										info
										minWidth={VARIABLES.TABLE.COLUMN_WIDTHS.NAMES.FULL}
										title={e.adRfName}
									>
										{e.adRfName}
									</Table.BodyColumn>
									{/* <Table.BodyColumn
										selected={e.isSelected}
										phone
										maxWidth={VARIABLES.TABLE.COLUMN_WIDTHS.PHONE}
										minWidth={VARIABLES.TABLE.COLUMN_WIDTHS.PHONE}
										idPhone={e.phoneCountry.value}
										srcFlag={e.phoneCountry.flag}
									>
										{e.phoneNo}
									</Table.BodyColumn>
									<Table.BodyColumn
										selected={e.isSelected}
										link
										maxWidth={VARIABLES.TABLE.COLUMN_WIDTHS.EMAIL}
										minWidth={VARIABLES.TABLE.COLUMN_WIDTHS.EMAIL}
										title={e.email}
									>
										{e.email}
									</Table.BodyColumn>
									<Table.BodyColumn
										selected={e.isSelected}
										info
										alignLeft
										maxWidth={VARIABLES.TABLE.INFO_WIDTHS.TYPE}
										minWidth={VARIABLES.TABLE.INFO_WIDTHS.TYPE}
										title={e.type}
									>
										{e.type}
									</Table.BodyColumn> */}
									<Table.BodyColumn
										selected={e.isSelected}
										info
										alignLeft
										maxWidth={VARIABLES.TABLE.INFO_WIDTHS.PROVINCE}
										minWidth={VARIABLES.TABLE.INFO_WIDTHS.PROVINCE}
										title={e.province}
									>
										{e.province}
									</Table.BodyColumn>
									<Table.BodyColumn
										selected={e.isSelected}
										action
										alignCenter
										maxWidth={VARIABLES.TABLE.COLUMN_WIDTHS.TRAIN}
										minWidth={VARIABLES.TABLE.COLUMN_WIDTHS.TRAIN}
									>
										<Field
											// checked={e.isTrained}
											checked={e.isSelected}
											ui='checkboxTickFillBlank'
											onChange={() => { }}
										/>
									</Table.BodyColumn>
								</Table.Row>
							)
						})
					}
				</Table.Section>
			</Table>
		);
	}
}