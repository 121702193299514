import React from 'react';
import {
  connect,
} from 'react-redux';
import { documentaryController } from '../../apiService/apiController/documentaryService';
import { FIREBASE_STORAGE } from '../../config/config';
import { DATA } from '../../helpers/constants/data';
import { ENUM } from '../../helpers/constants/enum';
import { LOCAL_STORAGE } from '../../helpers/constants/localStorage';
import { firebaseDeleteFile, firebaseGetAllFileList } from '../../helpers/firebases/firebaseStorage';
import { getBlobtoPath, getHtml2PdfFile } from '../../helpers/functions/addon';
import { getUserAuthRedux } from '../../helpers/functions/auth';
import { displayUrlPath } from '../../helpers/functions/display';
import { getLocalStorage } from '../../helpers/functions/localStorage';
import { pushLogList } from '../../helpers/functions/log';
import { isShowLoading } from '../../helpers/functions/main';
import { isValidResponse } from '../../helpers/functions/validation';
import { warrantyCloneDetail } from '../../helpers/functions/warranty';
import { ModelWarrantyTemplateDetail } from '../../models/ModelWarrantyTemplate';
import { WarrantyTemplate } from './WarrantyTemplate';

class WarrantyTemplateContainer extends React.Component {

  constructor(props) {
    super(props);
    const userAuth = getUserAuthRedux();
    const language = getLocalStorage(LOCAL_STORAGE.LANGUAGE_RBPTRUEBLUE);
    this.state = {
      userAuth,
      data: {},
      dataGroupList: [],
      isShowTemplate: false,
      viewerLoadingTime: 5000,
      warrantyDetail: new ModelWarrantyTemplateDetail(),
      language,
    }
  }

  warranty = {
    setWarrantyData: (data) => {
      return new Promise((resolve) => {
        this.setState({
          data,
        }, async () => {
          resolve();
        });
      })
    },
    handleDeletePdf: async (exceptDeletePathList = []) => {
      const {
        data,
      } = this.state;
      let pathDeleteList = [];
      for (let i = 0; i < data.selectedDataList.length; i++) {
        const dataId = data.selectedDataList[i].id;
        const pathUpload = `${ENUM.UPLOAD_PATH.WARRANTY}/${dataId}`;
        const pathList = await firebaseGetAllFileList(pathUpload);
        pathDeleteList = [...pathDeleteList, ...pathList];
        console.log('handleDeletePdf', pathList);
        console.log('exceptDeletePathList', exceptDeletePathList);
        for (let j = 0; j < pathList.length; j++) {
          const path = pathList[j];
          if (exceptDeletePathList.indexOf(path) === -1) {
            firebaseDeleteFile(path);
          }
        }
      }
      return pathDeleteList;
    },
    generateAllWarranty: () => {
      const {
        data,
      } = this.state;
      const time1 = new Date().getTime();
      return new Promise(async (resolve) => {
        this.setState({
          isShowTemplate: true,
        }, async () => {
          isShowLoading(true);
          let {
            warrantyDetail,
          } = this.state;
          // await this.warranty.handleDeletePdf();
          data.templateList = data.templateList.map(e => {
            e.isViewerUploading = true;
            e.isViewerLoading = true;
            return e;
          });
          for (let i = 0; i < data.templateList.length; i++) {
            const dataTemplate = data.templateList[i]
            const templateFormName = dataTemplate.templateForm;
            if (data.isCMSTemplate && dataTemplate.warrantyDetail) {
              warrantyDetail = dataTemplate.warrantyDetail;
            }
            await this.warranty.handleUploadPdf(templateFormName, warrantyDetail);
          }
          this.setState({
            isShowTemplate: false,
          }, () => {
            const {
              data,
            } = this.state;
            isShowLoading(false);
            const time2 = new Date().getTime();
            console.log((time2 - time1) / 1000)
            resolve(data);
          })
        });
      });
    },
    getOptSplit: (country) => {
      switch (country) {
        // case ENUM.COUNTRY_TYPE.MALAYSIA:
        //   return "<br/>";
        default:
          return ', '
      }
    },
    getSuffixWrtTempDtBodyRemoveCloseTag: (text) => {
      if (text.indexOf('</') === 0) {
        text = text.replace(text.match(/<\/.*?>/g)[0], '');
        text = this.warranty.getSuffixWrtTempDtBodyRemoveCloseTag(text);
      }
      return text;
    },
    getSuffixWrtTempDtBody: (e, fieldTarget, textTarget) => {
      let suffix;
      const prefixIndex = e[fieldTarget].indexOf(textTarget);
      const suffixIndex = prefixIndex + textTarget.length;
      if (prefixIndex >= 0) {
        suffix = e[fieldTarget].substr(suffixIndex, e[fieldTarget].length);
        suffix = this.warranty.getSuffixWrtTempDtBodyRemoveCloseTag(suffix);
      }
      return suffix;
    },
    handleUpdateCMSWarrantyTemplate: (warrantyDetail, dataGroupList, cntCoilId) => {
      const {
        language,
        data,
      } = this.state;
      const isLanguageTH = language === DATA.LANGUAGE.THAI;
      // dynamic attachment
      let suffix = '';
      console.log('handleUpdateCMSWarrantyTemplate', warrantyDetail, dataGroupList, cntCoilId)
      let totalPage = Math.ceil(cntCoilId / 10);
      const warrantyDetailList = [];
      const textTarget = '[attachment]';
      const fieldTarget = isLanguageTH ? 'wrtTempDtBodyTh' : 'wrtTempDtBodyEn';
      const warrantyDetailTarget = data.isGenerateResidential ? 'resDetailList' : 'nonResDetailList';

      warrantyDetail[warrantyDetailTarget].forEach(e => {
        //find textTarget
        suffix = this.warranty.getSuffixWrtTempDtBody(e, fieldTarget, textTarget);
        if (suffix === undefined) {
          warrantyDetailList.push(e);
        }
        else {
          //if find textTarget will return string and dynamic page
          for (let i = 0; i < totalPage; i++) {
            let newWrtTempDtBody = e[fieldTarget].replace('[attachment]', `[attachment${i + 1}]`);

            // for residential
            let wrtTempDtBodyLeftEn = e.wrtTempDtBodyLeftEn;
            let wrtTempDtBodyLeftTh = e.wrtTempDtBodyLeftTh;
            let wrtTempDtBodyRigthEn = e.wrtTempDtBodyRigthEn;
            let wrtTempDtBodyRigthTh = e.wrtTempDtBodyRigthTh;

            // if not last dynamic page remove suffix text
            if (i < totalPage - 1) {
              newWrtTempDtBody = newWrtTempDtBody.replace(suffix, '');
              wrtTempDtBodyLeftEn = '';
              wrtTempDtBodyLeftTh = '';
              wrtTempDtBodyRigthEn = '';
              wrtTempDtBodyRigthTh = '';
            }

            warrantyDetailList.push({
              ...e,
              wrtTempDtBodyLeftEn,
              wrtTempDtBodyLeftTh,
              wrtTempDtBodyRigthEn,
              wrtTempDtBodyRigthTh,
              [fieldTarget]: newWrtTempDtBody,
            });
          }
        }
      });

      warrantyDetail[warrantyDetailTarget] = warrantyDetailList;
      return warrantyDetail;
    },
    handleUploadPdf: (templateFormName, warrantyDetail) => {
      return new Promise(async (resolve) => {
        const {
          userAuth,
          data,
          viewerLoadingTime,
        } = this.state;
        let detailGroupList = [];
        if (data.isGenerateResidential) {
          detailGroupList = data.detailList.filter(e => {
            return e.templateForm === templateFormName
          });
        }
        else {
          detailGroupList = data.detailList.filter(e => {
            return e.templateFormList.indexOf(templateFormName) >= 0;
          }).map(e => {
            e.templateForm = templateFormName;
            return e;
          });
        }
        const dataGroup = {};
        let cntCoilId = 0;

        detailGroupList.forEach(e => {
          let dataDetail = dataGroup[e.productGroupName];
          if (!dataDetail) {
            dataDetail = {
              ...e,
              coilNameList: [],
              coilOrderM: [],
              coilOrderMList: [],
              coilId: [],
              coilIdList: [],
              transactionId: [],
              displayCoil: [],
              thicknessList: [],
              bmtList: [],
              wrtIdList: [],
              templateForm: templateFormName,
            };
            data.productGroupList.forEach(ep => {
              if (ep.productGroupName === dataDetail.productGroupName) {
                dataDetail = warrantyCloneDetail(ep, dataDetail);
              }
            })
          }
          dataDetail.coilNameList.push(e.coilName);
          dataDetail.coilOrderM.push(e.coilOrderMWithComma);
          dataDetail.coilOrderMList.push(e.coilOrderMWithComma);
          dataDetail.coilId.push(e.coilId);
          dataDetail.coilIdList.push(e.coilId);
          // sum coil for all product group because display one table attachment in cms template
          cntCoilId++;
          dataDetail.transactionId.push(e.name);
          dataDetail.displayCoil.push(`${e.coilId} ${e.coilName}`);
          dataDetail.thicknessList.push(e.thickness);
          dataDetail.bmtList.push(e.bmt);
          dataDetail.wrtIdList.push(e.wrtId);
          dataGroup[e.productGroupName] = dataDetail;
        });
        const optSplit = this.warranty.getOptSplit(data.projectCountry);
        const dataGroupList = Object.values(dataGroup).map(e => {
          e.coilId = e.coilId.join(optSplit);
          e.coilOrderM = e.coilOrderM.join(optSplit);
          e.transactionId = e.transactionId.join(optSplit);
          return e;
        });

        // check dynamic cms template page
        if (data.isCMSTemplate) {
          warrantyDetail = this.warranty.handleUpdateCMSWarrantyTemplate(warrantyDetail, dataGroupList, cntCoilId)
        }
        // CR: 1482
        if (data.isClickSaveDocumentary) {
          const configService = {
            isShowLoading: false,
          }
          const documentaryService = documentaryController(configService);
          for (let i = 0; i < dataGroupList.length; i++) {
            const e = dataGroupList[i];
            for (let j = 0; j < (e.documentaryList || []).length; j++) {
              const ed = e.documentaryList[j];
              if (ed.isSelected) {
                const params = {
                  wrt_ids: e.wrtIdList,
                  doc_ms_id: ed.docMsId
                }
                pushLogList('documentaryGenWrtDocNo params', params);
                const res = await documentaryService.documentaryGenWrtDocNo(params);
                pushLogList('documentaryGenWrtDocNo res', res);
                if (isValidResponse(res)) {
                  ed.wrtDocNo = res.data;
                  e.documentaryList[j] = ed;
                  dataGroupList[i] = e;
                }
              }
            }
          }
        }

        this.setState({
          dataGroupList,
          warrantyDetail,
        }, async () => {
          const uploadPDF = async (callback = () => { }) => {
            const dom = this.warrantyTemplateRef;
            let cnt = 0;
            const dataDetail = dataGroupList[0] || {}
            const pdfFile = await getHtml2PdfFile(dom, data, dataDetail, warrantyDetail);
            const filename = `${templateFormName}_${dataDetail.wrtNo}_${new Date().getTime()}.pdf`;
            const pathUpload = `${ENUM.UPLOAD_PATH.WARRANTY}/${data.id}`;
            getBlobtoPath(pdfFile, filename, pathUpload).then(path => {
              const {
                callbackPDFUploaded = () => { },
              } = this.props;
              detailGroupList.forEach((e) => {
                data.templateList = data.templateList.map(ep => {
                  if (data.isGenerateResidential) {
                    if (ep.templateForm === e.templateForm) {
                      ep.isViewerUploading = false;
                    }
                  }
                  else {
                    if (ep.templateFormList.indexOf(e.templateForm) >= 0) {
                      ep.isViewerUploading = false;
                    }
                  }
                  return ep;
                });
              });
              callbackPDFUploaded(data);
              setTimeout(() => {
                detailGroupList.forEach((e) => {
                  data.templateList = data.templateList.map(ep => {
                    if (data.isGenerateResidential) {
                      if (ep.templateForm === e.templateForm) {
                        ep.isViewerLoading = false;
                      }
                    }
                    else {
                      if (ep.templateFormList.indexOf(e.templateForm) >= 0) {
                        ep.isViewerLoading = false;
                      }
                    }
                    return ep;
                  });
                });
                callbackPDFUploaded(data);
              }, viewerLoadingTime);
            });
            const filePath = `${FIREBASE_STORAGE}${pathUpload}/${filename}`;
            const fileUrl = displayUrlPath(filePath);
            detailGroupList.forEach((e) => {
              console.log('filePath', filePath, e.wrtId);
              data.productGroupList = data.productGroupList.map(ep => {
                if (data.isGenerateResidential) {
                  if (ep.templateForm === e.templateForm) {
                    ep.oldFileRef = filePath;
                    ep.fileUrl = fileUrl;
                  }
                }
                else {
                  if (ep.templateFormList.indexOf(e.templateForm) >= 0) {
                    ep.oldFileRef = filePath;
                    ep.fileUrl = fileUrl;
                  }
                }
                return ep;
              });
              data.detailList = data.detailList.map(ed => {
                if (data.isGenerateResidential) {
                  if (ed.templateForm === e.templateForm) {
                    ed.oldFileRef = filePath;
                    ed.fileUrl = fileUrl;
                  }
                }
                else {
                  if (ed.templateFormList.indexOf(e.templateForm) >= 0) {
                    ed.oldFileRef = filePath;
                    ed.fileUrl = fileUrl;
                  }
                }
                return ed;
              });
              data.templateList = data.templateList.map(et => {
                if (data.isGenerateResidential) {
                  if (et.templateForm === e.templateForm) {
                    et.oldFileRef = filePath;
                    et.fileUrl = fileUrl;
                  }
                }
                else {
                  if (et.templateFormList.indexOf(e.templateForm) >= 0) {
                    et.oldFileRef = filePath;
                    et.fileUrl = fileUrl;
                  }
                }
                return et;
              });
              cnt++;
              if (detailGroupList.length === cnt) {
                this.setState({
                  data,
                }, () => {
                  callback(resolve);
                });
              }
            })
          }

          if (data.isDocumentary) {
            this.componentTemplateRef.documentaryTemplate.setShowDocumentaryTemplate(false, () => {
              this.componentTemplateRef.documentaryTemplate.setShowDocumentaryTemplate(true, () => {
                this.componentTemplateRef.documentaryTemplate.waitingDocumentaryLoadFinish(() => {
                  uploadPDF((callbackResolve) => {
                    callbackResolve();
                  });
                });
              });
            });
          }
          else {
            uploadPDF((callbackResolve) => {
              callbackResolve();
            });
          }

        })
      })
    },
  }

  render() {
    const {
      isShowTemplate,
      dataGroupList,
      data,
      warrantyDetail
    } = this.state;
    // console.log('data warranty template', data, dataGroupList)
    return isShowTemplate &&
      <WarrantyTemplate innerRef={r => this[`warrantyTemplateRef`] = r}
        setComponentTemplateRef={r => this.componentTemplateRef = r}
        data={data}
        dataGroupList={dataGroupList}
        warrantyDetail={warrantyDetail}
      />
  }
}

const mapStateToProps = (state) => {
  return {
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
  }
}

export const WarrantyTemplateContainerConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
  null,
  { forwardRef: true }
)(WarrantyTemplateContainer)