import React from 'react'
import ClassNames from 'classnames'
import PropTypes from 'prop-types'
import {
  WarrantyContentFormWrapper
} from './styled'
import {
  Grid,
  Image,
  Section,
  Text,
  Tab,
  ShapeContainer,
  Button,
  WarrantyUploadImage,
} from './../../../components'
import {
  default as VARIABLES
} from './../../../themes/styles/bases/variables'
// import {
//   default as TYPOGRAPHYS
// } from './../../../themes/styles/bases/typographys'
// import {
//   default as UTILITIES
// } from './../../../themes/styles/helpers/utilities'
import {
  ICONS
} from './../../../themes'
import { getLanguage } from './../../../helpers/functions/language';

/**
 * TemplateBase description:
 * - WarrantyContentForm
 */

export class WarrantyContentForm extends React.PureComponent {
  render () {
    const {
      className,
      children,
      isActiveTabFirst,
      isActiveTabSecond,
      isActions,
      tabFirstName,
      tabSecondName,
      buttonFirstName,
      buttonSecondName,
      buttonSecondWidth,
      buttonSecondIconSrc,
      buttonSecondIconWidth,
      buttonSecondIconHeight,
      buttonSecondIconTop,
      onClickTabFirst,
      onClickTabSecond,
      onClickButtonFirst,
      onClickButtonSecond,
    } = this.props

    // props for css classes
    const classes = ClassNames(
      'warranty-content-form',
      // {'is-modifier-name': modifierName},
      className
    )

    return (
      <WarrantyContentFormWrapper
        className={classes}
      >
        <Section>
          <Grid align='center'>
            <Grid.Column flex='1'>
              <Tab isThemeBlue>
                <Tab.List
                  isActive={isActiveTabFirst}
                  onClick={onClickTabFirst}
                >
                  <Text className='is-tab-name' regular18 color={VARIABLES.COLORS.BLACK}>
                    {tabFirstName}
                  </Text>
                </Tab.List>
                <Tab.List
                  isActive={isActiveTabSecond}
                  onClick={onClickTabSecond}
                >
                  <Text className='is-tab-name' regular18 color={VARIABLES.COLORS.BLACK}>
                    {tabSecondName}
                  </Text>
                </Tab.List>
              </Tab>
            </Grid.Column>
            {isActions &&
              <Grid.Column flex='none'>
                <Section paddingBottom='5'>
                  <Grid align='center' gutter='15'>
                    <Grid.Column>
                      <Section onClick={onClickButtonFirst}>
                        <Grid align='center' gutter='5'>
                          <Grid.Column>
                            <img src={ICONS['ic-notification-info-slim-blue.svg']} width='14' height='15' alt='Icon' />
                          </Grid.Column>
                          <Grid.Column>
                            <Text regular14 color={VARIABLES.COLORS.PRIMARY_2} top='1'>
                              {buttonFirstName}
                            </Text>
                          </Grid.Column>
                        </Grid>
                      </Section>
                    </Grid.Column>
                    <Grid.Column>
                      <Button
                        ui='frontIcon'
                        lightestBlue
                        textLightBlue
                        name={buttonSecondName}
                        width={buttonSecondWidth}
                        iconSrc={buttonSecondIconSrc}
                        iconWidth={buttonSecondIconWidth}
                        iconHeight={buttonSecondIconHeight}
                        iconTop={buttonSecondIconTop}
                        onClick={onClickButtonSecond}
                      />
                    </Grid.Column>
                  </Grid>
                </Section>
              </Grid.Column>
            }
          </Grid>
        </Section>
        <ShapeContainer
          className='is-tab-body'
          fluid
          padding='10'
          bgColor={VARIABLES.COLORS.GRAY_1}
          borderWidth='1'
          borderStyle='solid'
          borderColor={VARIABLES.COLORS.PRIMARY_2}
        >
          {children}
        </ShapeContainer>
      </WarrantyContentFormWrapper>
    )
  }

  static defaultProps = {
    // * If you set new default props, you should create propsType for correct styleguide document.
    isActions: true,
  }

  static propTypes = { // TYPE > node, string, number, bool, array, object, symbol, func
    /**
    * Additional classes
    */
    className: PropTypes.string
  }
}
