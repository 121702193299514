import styled, {
  // css
} from 'styled-components'
import {
  default as VARIABLES
} from './../../../themes/styles/bases/variables'
import {
  default as TYPOGRAPHYS
} from './../../../themes/styles/bases/typographys'
// import {
//   default as MIXINS
// } from './../../../themes/styles/helpers/mixins'

// Sample using image in style
// url(${require('./../../../themes/images/icons/icon-sample.svg')});

// Wrapper
// ============================================================
export const StepWrapper = styled.div`
  /* Parent
  ------------------------------- */
  display: flex;
  justify-content: center;
  padding-top: 15px;
  padding-bottom: 35px;

  /* Childrens
  ------------------------------- */
  .step-container {
    position: relative;
    display: flex;
  }

  .step-number {
    position: relative;
    z-index: ${VARIABLES.Z_INDEXS.LV_2};
    padding-right: 33px; // 58
    padding-left: 33px; // 58

    &.is-active,
    &.is-success {
      .step-number-text {
        ${TYPOGRAPHYS.FIRST.BOLD.FS_24};
        color: ${VARIABLES.COLORS.WHITE};
      }
    }

    &.is-active {
      .step-number-container {
        background-color: ${VARIABLES.COLORS.PRIMARY_2};
        border-color: ${VARIABLES.COLORS.PRIMARY_2};
      }

      .step-number-label {
        color: ${VARIABLES.COLORS.PRIMARY_2};
      }
    }

    &.is-success {
      .step-number-container {
        background-color: ${VARIABLES.COLORS.PRIMARY_1};
        border-color: ${VARIABLES.COLORS.PRIMARY_1};
      }
    }

    &.is-disabled {
      .step-number-container {
        background-color: ${VARIABLES.COLORS.GRAY_8};
        border-color: ${VARIABLES.COLORS.GRAY_8};
      }

      .step-number-text {
        color: ${VARIABLES.COLORS.WHITE};
      }

      .step-number-label {
        color: ${VARIABLES.COLORS.GRAY_8};
      }
    }
  }

  .step-number-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    background-color: ${VARIABLES.COLORS.BLUE_5};
    border: 2px solid ${VARIABLES.COLORS.PRIMARY_1};
    border-radius: 32px;
  }

  .step-number-text {
    ${TYPOGRAPHYS.FIRST.REGULAR.FS_24};
    line-height: 1;
    color: ${VARIABLES.COLORS.PRIMARY_1};
  }

  .step-number-label {
    ${TYPOGRAPHYS.FIRST.REGULAR.FS_16};
    transform: translateX(-50%);
    position: absolute;
    z-index: ${VARIABLES.Z_INDEXS.LV_1};
    top: 62px; // bottom: 0;
    left: 50%;
    line-height: 1;
    color: ${VARIABLES.COLORS.PRIMARY_1};
    /* white-space: nowrap; */
    text-align: center;
  }

  .step-progress {
    transform: translateY(-50%);
    position: absolute;
    z-index: ${VARIABLES.Z_INDEXS.LV_1};
    top: 50%;
    right: 66px; // 108
    left: 66px; // 108
    height: 4px;
    background-color: ${VARIABLES.COLORS.GRAY_8};
  }

  .step-progress-percent {
    position: absolute;
    z-index: ${VARIABLES.Z_INDEXS.LV_1};
    top: 0;
    left: 0;
    height: 100%;
    background-color: ${VARIABLES.COLORS.PRIMARY_1};
  }

  .step-tab {
    cursor: pointer;
    position: relative;
    padding-right: 30px;
    padding-bottom: 15px;
    padding-left: 30px;

    &:after {
      content: ' ';
      position: absolute;
      z-index: ${VARIABLES.Z_INDEXS.LV_1};
      bottom: -3px;
      left: 0;
      display: none;
      width: 100%;
      height: 3px;
      background-color: ${VARIABLES.COLORS.PRIMARY_2};
    }

    &.is-active {
      cursor: default;

      .step-tab-text {
        color: ${VARIABLES.COLORS.PRIMARY_2};
      }

      &:after {
        display: block;
      }
    }
  }

  .step-tab-text {
    ${TYPOGRAPHYS.FIRST.REGULAR.FS_16}
    color: ${VARIABLES.COLORS.GRAY_12};
    line-height: 1;
  }

  /* Modifiers
  ------------------------------- */
  &.is-tabs {
    justify-content: flex-start;
    width: 100%;
    padding-top: 0;
    padding-bottom: 0;
    border-bottom: 3px solid ${VARIABLES.COLORS.BLUE_15};
  }

  &.is-label {
    .step-number {
      padding-bottom: 28px;
    }

    .step-progress {
      top: calc(50% - 12px);
    }
  }

  .is-nowrap{
    white-space: nowrap;
  }
  /* Media queries
  ------------------------------- */
`
