import React from 'react'
import ClassNames from 'classnames'
import PropTypes from 'prop-types'
import {
  TitleSectionWrapper
} from './styled'
import {
  Section,
  Text,
  Grid
} from './../../../components'
import {
  default as VARIABLES
} from './../../../themes/styles/bases/variables'
// import {
//   default as TYPOGRAPHYS
// } from './../../../themes/styles/bases/typographys'
// import {
//   default as UTILITIES
// } from './../../../themes/styles/helpers/utilities'

/**
 * TitleSection description:
 * - TitleSection
 */

export class TitleSection extends React.PureComponent {
  render() {
    const {
      className,
      name,
      message,
      children,
    } = this.props

    // props for css classes
    const classes = ClassNames(
      'title-section',
      // {'is-modifier-name': modifierName},
      className
    )

    return (
      <TitleSectionWrapper
        className={classes}
      >
        {/* <Text
          regular16
          color={VARIABLES.COLORS.PRIMARY_2}
        >
          {name}
        </Text> */}
        <Grid>
          <Grid.Column flex='1'>
            <Text
              regular16
              color={VARIABLES.COLORS.PRIMARY_2}
            >
              {name}
            </Text>
            {message &&
              <Text
                regular16
                color={VARIABLES.COLORS.RED_4}
              >
                &nbsp;{message}
              </Text>
            }
          </Grid.Column>

          {children &&
            <Grid.Column flex='none'>
              {children}
            </Grid.Column>
          }
        </Grid>
      </TitleSectionWrapper>
    )
  }

  static defaultProps = {
    // * If you set new default props, you should create propsType for correct styleguide document.
  }

  static propTypes = { // TYPE > node, string, number, bool, array, object, symbol, func
    /**
    * Additional classes
    */
    className: PropTypes.string
  }
}
