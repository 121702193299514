import React from 'react'
import {
  Warranty
} from './../../components'
import {
  VARIABLES
} from './../../themes'
import { ENUM } from '../../helpers/constants/enum';
import { DATA } from '../../helpers/constants/data';

export class WarrantyTemplateTermsAndConditions extends React.Component {
  render() {
    const {
      dataDetail,
    } = this.props;
    return (
      <Warranty
        ui='termsAndConditions'
        blueScopeZacs={dataDetail.templateForm === DATA.WARRANTY_TEMPLATE_LIST[0]}
        colorbond={dataDetail.templateForm === DATA.WARRANTY_TEMPLATE_LIST[1]}
        crp={dataDetail.templateForm === DATA.WARRANTY_TEMPLATE_LIST[2]}
        jingjoeLek={dataDetail.templateForm === DATA.WARRANTY_TEMPLATE_LIST[3]}
        prima={dataDetail.templateForm === DATA.WARRANTY_TEMPLATE_LIST[4]}
        superDymaDk={dataDetail.templateForm === DATA.WARRANTY_TEMPLATE_LIST[5]}
        superDymaPl={dataDetail.templateForm === DATA.WARRANTY_TEMPLATE_LIST[6]}
        trueCore={dataDetail.templateForm === DATA.WARRANTY_TEMPLATE_LIST[7]}
        zincalume={dataDetail.templateForm === DATA.WARRANTY_TEMPLATE_LIST[8]}
      />
    )
  }
}
