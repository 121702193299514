import React from 'react'
import ClassNames from 'classnames'
import PropTypes from 'prop-types'
import { osName, } from 'react-device-detect'
import { WarrantyContentWrapper } from './styled'
import { WarrantyBSWMThailandSignature } from './WarrantyBSWMThailandSignature'
import { WarrantyBSWMThailandNote } from './WarrantyBSWMThailandNote'
import { WarrantyBSWMThailandCladdingIssuesLists } from './WarrantyBSWMThailandCladdingIssuesLists'
import { WarrantyBSWMThailandNotCoveredLists } from './WarrantyBSWMThailandNotCoveredLists'
import { WarrantyBSWMThailandProductInformation } from './WarrantyBSWMThailandProductInformation'
import { WarrantyBSWMThailandProductConditions } from './WarrantyBSWMThailandProductConditions'
import { WarrantyBSWMThailandTableCoilId } from './WarrantyBSWMThailandTableCoilId'
import { WarrantyBSWMThailandTableBuilding } from './WarrantyBSWMThailandTableBuilding'
import { WarrantyBSWMThailandAdditionalInformation } from './WarrantyBSWMThailandAdditionalInformation'
import { WarrantyBSWMThailandApplicationDetail } from './WarrantyBSWMThailandApplicationDetail'
import { WarrantyBSWMThailandTermsAndConditions } from './WarrantyBSWMThailandTermsAndConditions'
import {
  Section,
  Text,
  Image,
  Grid,
  Table,
  ShapeContainer,
} from './../../../../components'
import { VARIABLES, } from './../../../../themes'
import { displayDate } from '../../../../helpers/functions/display'

/**
 * Warranty description:
 * - Warranty
 */

export class WarrantyBSWMThailandPpPanelSdTh extends React.PureComponent {
  render() {
    const {
      registeredTrademarkName,
      subBrandName,
      customerName,
      projectName,
      projectAddress,
      perforateByCorrosionYear,
      peelOrFlakeExcessivelyYear,
      colourFadeExcessivelyYear,
      discolourByDirtStainingYear,
      roofArea,
      dataGroupList,
      currentDate,
      additionalProjectInformation,
      roof,
      wall,
      purlinAndGirt,
      trussAndFrame,
      coolRoomPanel,
      decking,
      airDucting,
      thLanguage,
      isHideApplicationDetail,
    } = this.props
    let cntDisplayCoil = 0;
    let cntDisplayCustomerRemark = 0;
    let cntPageProduct = 0;
    let cntPageApplication = 0;
    let cntPageLimit = 10;
    let totalRowProductRow = 0;
    let totalApplicationRow = 0;
    dataGroupList.forEach(e => {
      totalRowProductRow += e.displayCoil.length
      totalApplicationRow += e.applicationInfoList.length;
    });
    const totalRow = totalRowProductRow + totalApplicationRow;
    const totalPageProduct = Math.ceil(totalRowProductRow / 10);
    const totalPageApplication = Math.ceil(totalApplicationRow / 10);

    // props for css classes
    const classes = ClassNames(
      'warranty-bswm-content',
    )

    return (
      <React.Fragment>
        {/* Page 1 */}
        <WarrantyContentWrapper
          className={classes}
        >
          <div className='warranty-bswm-container is-spacebetween'>
            <div className='warranty-bswm-section'>
              <Section spacingBottom='15'><p className='warranty-bswm-text is-huge is-bold is-center'>{thLanguage ? 'การรับประกัน' : 'WARRANTY'}</p></Section>
              <Section spacingBottom='15'>
                <WarrantyBSWMThailandProductInformation
                  isSupplierRoof={false}
                  registeredTrademarkName={registeredTrademarkName}
                  subBrandName={subBrandName}
                  customerName={customerName}
                  projectName={projectName}
                  projectAddress={projectAddress}
                  // perforateByCorrosion={''}
                  perforateByCorrosionYear={perforateByCorrosionYear}
                  // peelOrFlakeExcessively={''}
                  peelOrFlakeExcessivelyYear={peelOrFlakeExcessivelyYear}
                  // colourFadeExcessively={''}
                  colourFadeExcessivelyYear={colourFadeExcessivelyYear}
                  // discolourByDirtStaining={''}
                  discolourByDirtStainingYear={discolourByDirtStainingYear}
                  thLanguage={thLanguage}
                  prefixRegisteredTrademarkName={'เคลือบสี'}
                />
              </Section>
              {!thLanguage &&
                <Section paddingHorizontal='15' spacingBottom='15'><p className='warranty-bswm-text is-xs'>* Terms and conditions apply. Please consult NS BlueScope (Thailand) Company Limited representative.</p></Section>
              }
              <Section paddingHorizontal='15'>
                <WarrantyBSWMThailandProductConditions
                  isLogoPositionBack={false}
                  registeredTrademarkName={registeredTrademarkName}
                  subBrandName={subBrandName}
                  customerName={customerName}
                  objectName={'cladding'}
                  isSteel
                  thLanguage={thLanguage}
                  prefixRegisteredTrademarkName={'เคลือบสี'}
                  objectThName={'แผ่นผนัง'}
                  isComments
                  materialLabel={'พื้นที่ผนัง'}
                />
                {additionalProjectInformation &&
                  <WarrantyBSWMThailandAdditionalInformation
                    additionalProjectInformation={additionalProjectInformation}
                    thLanguage={thLanguage}
                  />
                }
              </Section>
            </div>
            <div className='warranty-bswm-section'>
              {/* <Section spacingBottom='20'>
                <WarrantyBSWMThailandSignature
                  customerName={customerName}
                  date={currentDate}
                  thLanguage={thLanguage}
                />
              </Section> */}
              <WarrantyBSWMThailandNote
                customerName={customerName}
                thLanguage={thLanguage}
                isCustomer
                registeredTrademarkName={registeredTrademarkName}
              />
            </div>
          </div>
        </WarrantyContentWrapper>
        {/* Page 2 */}
        {Array.from({ length: totalRow > cntPageLimit ? totalPageProduct + totalPageApplication : 1 }).map((page, pageIndex) => {
          cntPageProduct = pageIndex * cntPageLimit;
          cntPageApplication = totalRow > cntPageLimit ? (pageIndex - totalPageProduct) * cntPageLimit : cntPageProduct;

          return (
            <WarrantyContentWrapper key={pageIndex}
              className={classes}
            >
              <div className='warranty-bswm-container is-spacebetween'>
                <div className='warranty-bswm-section'>
                  <Section spacingTop='1' spacingBottom='15'><p className='warranty-bswm-text is-huge is-bold is-center'>{thLanguage ? 'การรับประกัน' : 'WARRANTY'}</p></Section>
                  <Section spacingBottom='15'>
                    <WarrantyBSWMThailandProductInformation
                      registeredTrademarkName={registeredTrademarkName}
                      customerName={customerName}
                      projectName={projectName}
                      projectAddress={projectAddress}
                      // perforateByCorrosion={''}
                      perforateByCorrosionYear={''}
                      // peelOrFlakeExcessively={''}
                      peelOrFlakeExcessivelyYear={''}
                      // colourFadeExcessively={''}
                      colourFadeExcessivelyYear={''}
                      // discolourByDirtStaining={''}
                      discolourByDirtStainingYear={''}
                      thLanguage={thLanguage}
                      prefixRegisteredTrademarkName={'เคลือบสี'}
                    />
                  </Section>
                  <Section paddingHorizontal='15'>
                    {cntDisplayCoil < totalRowProductRow &&
                      <React.Fragment>
                        <Section spacingBottom='10'>
                          <p className='warranty-bswm-text'>{thLanguage ? 'เอกสารแนบ' : 'Attachment'} {pageIndex + 1}</p>
                        </Section>
                        <Section width='690' spacingBottom='15'>
                          <WarrantyBSWMThailandTableCoilId thLanguage={thLanguage}>
                            {dataGroupList.map((e, i) => {
                              return (
                                <Table.Section key={i}>
                                  {e.displayCoil.map((ec, ic) => {
                                    if (ic >= cntPageProduct && ic < cntPageProduct + cntPageLimit) {
                                      cntDisplayCoil += 1;
                                      return (
                                        <Table.Row key={ic}>
                                          <Table.Column className='is-body-column' alignCenter maxWidth='50' minWidth='50' paddingVertical='4' paddingHorizontal='4'>
                                            <p className='warranty-bswm-text is-sm is-bold'>{cntDisplayCoil}</p>
                                          </Table.Column>
                                          <Table.Column className='is-body-column' maxWidth='400' minWidth='400' paddingVertical='4' paddingHorizontal='4'>
                                            <p className='warranty-bswm-text is-sm is-bold'>{e.coilNameList[ic]}</p>
                                          </Table.Column>
                                          <Table.Column className='is-body-column' maxWidth='240' minWidth='240' paddingVertical='4' paddingHorizontal='4'>
                                            <p className='warranty-bswm-text is-sm is-bold'>{e.coilIdList[ic]}</p>
                                          </Table.Column>
                                        </Table.Row>
                                      )
                                    }
                                  })}
                                </Table.Section>
                              )
                            })}
                          </WarrantyBSWMThailandTableCoilId>
                        </Section>
                      </React.Fragment>
                    }
                    {isHideApplicationDetail || cntPageApplication < 0 ?
                      undefined
                      :
                      <React.Fragment>
                        <Section spacingBottom='15'>
                          <WarrantyBSWMThailandApplicationDetail
                            roof={roof}
                            wall={wall}
                            purlinAndGirt={purlinAndGirt}
                            trussAndFrame={trussAndFrame}
                            coolRoomPanel={coolRoomPanel}
                            decking={decking}
                            airDucting={airDucting}
                            thLanguage={thLanguage}
                          />
                        </Section>
                        <Section width='690' spacingBottom='15'>
                          <WarrantyBSWMThailandTableBuilding thLanguage={thLanguage}>
                            {dataGroupList.map((e, i) => {
                              return (
                                <Table.Section key={i}>
                                  {e.applicationInfoList.map((ec, ic) => {
                                    if (ic >= cntPageApplication && ic < cntPageApplication + cntPageLimit) {
                                      cntDisplayCustomerRemark += 1;
                                      return (
                                        <Table.Row key={ic}>
                                          <Table.Column className='is-body-column' alignCenter maxWidth='50' minWidth='50' paddingVertical='4' paddingHorizontal='4'>
                                            <p className='warranty-bswm-text is-sm is-bold'>{cntDisplayCustomerRemark}</p>
                                          </Table.Column>
                                          <Table.Column className='is-body-column' maxWidth='200' minWidth='200' paddingVertical='4' paddingHorizontal='4'>
                                            <p className='warranty-bswm-text is-sm is-bold'>{ec.building}</p>
                                          </Table.Column>
                                          <Table.Column className='is-body-column' alignCenter maxWidth='200' minWidth='200' paddingVertical='4' paddingHorizontal='4'>
                                            <p className='warranty-bswm-text is-sm is-bold'>{displayDate(ec.projectInsDateTime)}</p>
                                          </Table.Column>
                                          <Table.Column className='is-body-column' maxWidth='240' minWidth='240' paddingVertical='4' paddingHorizontal='4'>
                                            <Section>
                                              {/* <p className='warranty-bswm-text is-sm is-bold'>Roof: {roofProfileNameInM2}</p>
                                              <p className='warranty-bswm-text is-sm is-bold'>Wall: {wallProfileNameInM2}</p> */}
                                              <p className='warranty-bswm-text is-sm is-bold'>{ec.customerRemark}</p>
                                            </Section>
                                          </Table.Column>
                                        </Table.Row>
                                      )
                                    }
                                  })}
                                </Table.Section>
                              )
                            })}
                          </WarrantyBSWMThailandTableBuilding>
                        </Section>
                      </React.Fragment>
                    }
                  </Section>
                </div>
                <div className='warranty-bswm-section'>
                  <Section spacingBottom='20'>
                    <WarrantyBSWMThailandSignature
                      customerName={customerName}
                      date={currentDate}
                      thLanguage={thLanguage}
                    />
                  </Section>
                  <WarrantyBSWMThailandNote
                    customerName={customerName}
                    thLanguage={thLanguage}
                    isCustomer
                    registeredTrademarkName={registeredTrademarkName}
                  />
                </div>
              </div>
            </WarrantyContentWrapper>
          )
        })}

        {/* Page 3 */}
        <WarrantyContentWrapper
          className={classes}
        >
          {thLanguage ?
            <React.Fragment>
              <Section spacingBottom='10'><p className='warranty-bswm-text is-bold is-underline'>ข้อกำหนดและเงื่อนไข</p></Section>
              <Section spacingBottom='5'>
                <Grid>
                  <Grid.Column flex='none'><Section width='17'><p className='warranty-bswm-text is-xs'>1.</p></Section></Grid.Column>
                  <Grid.Column flex='1'><p className='warranty-bswm-text is-xs'>ใบรับประกันนี้เป็นการรับประกันแก่ท่านว่า เหล็กเคลือบสี <span className='warranty-bswm-text is-xs is-bold'>{registeredTrademarkName}</span> ที่ความหนาระบุ 0.3 มม. (หรือมากกว่า) และสำหรับการใช้งานที่เป็นผนัง และพาเนล</p></Grid.Column>
                </Grid>
              </Section>
              <Section>
                <Grid>
                  <Grid.Column flex='none'><Section width='17'><p className='warranty-bswm-text is-xs'>2.</p></Section></Grid.Column>
                  <Grid.Column flex='1'><p className='warranty-bswm-text is-xs'>แผ่นผนังที่ผลิตจากเหล็กเคลือบสี <span className='warranty-bswm-text is-xs is-bold'>{registeredTrademarkName}</span> ต้องได้รับการติดตั้งและบำรุงรักษาอย่างถูกต้องเหมาะสมจะมีระดับความสามารถในการใช้งานที่คาดหมาย อย่างน้อยเท่ากับระยะเวลา<br />ที่รับประกัน (เริ่มตั้งแต่วันที่ติดตั้ง) อย่างไรก็ตาม ปัญหาและสถานการณ์บางอย่าง (ตามที่ระบุด้านล่าง) ต้องระบุและแก้ไขโดยเร็ว เพื่อให้สามารถใช้งานอย่างเป็นไปได้ดีที่สุด<br /> และหากไม่ทำการแก้ไข อาจเป็นผลให้การรับประกันสิ้นผลลง</p></Grid.Column>
                </Grid>
              </Section>
              <Section spacingBottom='5'>
                <Grid>
                  <Grid.Column flex='none'><Section width='17'><p className='warranty-bswm-text is-xs'>3.</p></Section></Grid.Column>
                  <Grid.Column flex='1'><p className='warranty-bswm-text is-xs'>การใช้งานวัสดุยาแนวรอยต่อ ต้องมีสมบัติเป็นกลาง</p></Grid.Column>
                </Grid>
              </Section>
              <Section spacingBottom='5'>
                <Grid>
                  <Grid.Column flex='none'><Section width='17'><p className='warranty-bswm-text is-xs'>4.</p></Section></Grid.Column>
                  <Grid.Column flex='1'><p className='warranty-bswm-text is-xs'>ทุกการใช้งานภายนอกต้องมีระยะทางห่างจากทะเล และเขตนิคมอุตสาหกรรม 1 กิโลเมตร</p></Grid.Column>
                </Grid>
              </Section>
              <Section spacingBottom='5'>
                <Grid>
                  <Grid.Column flex='none'><Section width='17'><p className='warranty-bswm-text is-xs'>5.</p></Section></Grid.Column>
                  <Grid.Column flex='1'><p className='warranty-bswm-text is-xs'>ชั้นเคลือบของแผ่นผนังที่จัดหาจะต้องไม่มีรอยขีดข่วน ครูด เสียหายหรือเคลือบด้วยสิ่งแปลกปลอม หรือวัสดุที่ไม่เหมาะสมกัน (รวมทั้งสารตกค้างใดๆ จากปล่องระบายอากาศ)</p></Grid.Column>
                </Grid>
              </Section>
              <Section spacingBottom='5'>
                <Grid>
                  <Grid.Column flex='none'><Section width='17'><p className='warranty-bswm-text is-xs'>6.</p></Section></Grid.Column>
                  <Grid.Column flex='1'><p className='warranty-bswm-text is-xs'>วัสดุยึดจะต้องมีคุณสมบัติตาม AS3566 Class 3 หรือ Class 4 และต้องได้รับการรับรองคุณสมบัติดังกล่าวจากผู้จัดหาวัสดุยึด</p></Grid.Column>
                </Grid>
              </Section>
              <Section spacingBottom='5'>
                <Grid>
                  <Grid.Column flex='none'><Section width='17'><p className='warranty-bswm-text is-xs'>7.</p></Section></Grid.Column>
                  <Grid.Column flex='1'><p className='warranty-bswm-text is-xs'>การขนย้ายและติดตั้งแผ่นหลังคา/ผนัง ต้องเป็นไปตามคำแนะนำของทางบริษัทฯ ที่ได้มีการจัดพิมพ์และใช้งานอยู่เป็นฉบับปัจุบัน ณ ช่วงเวลาที่มีการติดตั้ง ทั้งนี้ โปรดให้ความระมัดระวังเป็นพิเศษในเรื่องดังต่อไปนี้</p></Grid.Column>
                </Grid>
              </Section>
              <Section paddingLeft='17' spacingBottom='5'>
                <WarrantyBSWMThailandCladdingIssuesLists thLanguage />
              </Section>
              <Section spacingBottom='5'>
                <Grid>
                  <Grid.Column flex='none'><Section width='17'><p className='warranty-bswm-text is-xs'>8.</p></Section></Grid.Column>
                  <Grid.Column flex='1'><p className='warranty-bswm-text is-xs'>อุปกรณ์ประกอบ ครอบมุมและชิ้นส่วนประกอบทั้งหมดจะต้องผลิตขึ้นจากโลหะที่มีความเหมาะสมกัน (<span className='warranty-bswm-text is-xs is-bold'>COLORBOND®</span>, <span className='warranty-bswm-text is-xs is-bold'>ZINCALUME®</span> เหล็ก สังกะสี <br />หรืออะลูมิเนียม) หรือได้รับการเคลือบตามคำแนะนำของทางบริษัทฯ ที่ได้มีการจัดพิมพ์และใช้งานอยู่เป็นปัจุบัน เพื่อให้ดูเป็นวัสดุแบบเดียวกัน ครอบมุมควรทำจาก<br />วัสดุเดียวกับแผ่นหลังคา/ผนังด้วย</p></Grid.Column>
                </Grid>
              </Section>
              <Section spacingBottom='5'>
                <Grid>
                  <Grid.Column flex='none'><Section width='17'><p className='warranty-bswm-text is-xs'>9.</p></Section></Grid.Column>
                  <Grid.Column flex='1'><p className='warranty-bswm-text is-xs'>ความเสียหายของผลิตภัณฑ์ไม่ว่าทั้งหมดหรือบางส่วนเนื่องจากสาเหตุดังต่อไปนี้ ไม่อยู่ภายใต้การรับประกัน</p></Grid.Column>
                </Grid>
              </Section>
              <Section paddingLeft='17' spacingBottom='5'>
                <WarrantyBSWMThailandNotCoveredLists thLanguage />
              </Section>
              <Section spacingBottom='5'>
                <Grid>
                  <Grid.Column flex='none'><Section width='17'><p className='warranty-bswm-text is-xs'>10.</p></Section></Grid.Column>
                  <Grid.Column flex='1'><p className='warranty-bswm-text is-xs'>ความบกพร่องของผลิตภัณฑ์เนื่องจากการกัดกร่อนหรือหมายถึงกรณีที่มีแผ่นเหล็กผุเป็นรู ในส่วนของสนิมที่พื้นผิวหรือการกัดกร่อนเพียงเล็กน้อยที่รอยโค้งหรือขอบตัด<br />เนื่องจากการใช้งานในสภาพอากาศตามธรรมชาติไม่ถือเป็นความบกพร่องของผลิตภัณฑ์</p></Grid.Column>
                </Grid>
              </Section>
              <Section spacingBottom='5'>
                <Grid>
                  <Grid.Column flex='none'><Section width='17'><p className='warranty-bswm-text is-xs'>11.</p></Section></Grid.Column>
                  <Grid.Column flex='1'><p className='warranty-bswm-text is-xs'>กรณีพบความเสีบหายหรือชำรุดใด ๆ จะต้องแจ้งให้บริษัท เอ็นเอส บลูสโคป ประเทศไทย ทราบเป็นลายลักษณ์อักษรภายใน 30 วัน นับจากวันที่บริษัท เอ็นเอส บลูสโคป ประเทศไทย เชื่อโดยดุลพินิจแต่เพียงผู้เดียวว่า ความชำรุดบกพร่องดังกล่าวพึงพบได้ตามสมควรในแต่ละสถานการณ์</p></Grid.Column>
                </Grid>
              </Section>
              <Section>
                <Grid>
                  <Grid.Column flex='none'><Section width='17'><p className='warranty-bswm-text is-xs'>12.</p></Section></Grid.Column>
                  <Grid.Column flex='1'><p className='warranty-bswm-text is-xs'>การรับประกันนี้จัดทำขึ้นทั้งฉบับภาษาอังกฤษและฉบับภาษาไทย หากมีการขัดแย้งกันระหว่างฉบับภาษาอังกฤษและฉบับภาษาไทย ให้นำฉบับภาษาอังกฤษมาใช้บังคับ</p></Grid.Column>
                </Grid>
              </Section>
            </React.Fragment>
            :
            <React.Fragment>
              <Section spacingBottom='10'><p className='warranty-bswm-text is-bold is-underline'>TERMS AND CONDITIONS</p></Section>
              <Section spacingBottom='5'><p className='warranty-bswm-text is-xs is-bold'>This warranty is subject to the following terms and conditions;</p></Section>
              <Section spacingBottom='5'>
                <Grid>
                  <Grid.Column flex='none'><Section width='17'><p className='warranty-bswm-text is-xs'>1.</p></Section></Grid.Column>
                  <Grid.Column flex='1'><p className='warranty-bswm-text is-xs'>This warranty applies only to <span className='warranty-bswm-text is-xs is-bold'>{registeredTrademarkName}</span> steel which has a base metal thickness of 0.3mm (or greater) and issued as the skins of a sandwich panel.</p></Grid.Column>
                </Grid>
              </Section>
              <Section>
                <Grid>
                  <Grid.Column flex='none'><Section width='17'><p className='warranty-bswm-text is-xs'>2.</p></Section></Grid.Column>
                  <Grid.Column flex='1'><p className='warranty-bswm-text is-xs'>The product must be installed in accordance with the manufacturer’s published fixing recommendations, current at the time of installation, including BlueScope Steel’s Technical Bulletins CTB-12, TB-8 and TB-31.</p></Grid.Column>
                </Grid>
              </Section>
              <Section spacingBottom='5'>
                <Grid>
                  <Grid.Column flex='none'><Section width='17'><p className='warranty-bswm-text is-xs'>3.</p></Section></Grid.Column>
                  <Grid.Column flex='1'><p className='warranty-bswm-text is-xs'>Only neutral cure sealants are to be applied to panel joints. Slip joints and channels must be inspected annually and sealant replaced where necessary,</p></Grid.Column>
                </Grid>
              </Section>
              <Section spacingBottom='5'>
                <Grid>
                  <Grid.Column flex='none'><Section width='17'><p className='warranty-bswm-text is-xs'>4.</p></Section></Grid.Column>
                  <Grid.Column flex='1'><p className='warranty-bswm-text is-xs'>All external installations must be greater than 1km from marine or industrial environments.</p></Grid.Column>
                </Grid>
              </Section>
              <Section spacingBottom='5'>
                <Grid>
                  <Grid.Column flex='none'><Section width='17'><p className='warranty-bswm-text is-xs'>5.</p></Section></Grid.Column>
                  <Grid.Column flex='1'><p className='warranty-bswm-text is-xs'>All flashings and components fixed to the panels must be either manufactured from (<span className='warranty-bswm-text is-xs is-bold'>COLOBOND®</span>steel or <span className='warranty-bswm-text is-xs is-bold'>ZINCZLUME®</span> steel, aluminium, zinc, or other BlueScope Steel approved material in accordance with BlueScope Steel recommendations (as per Technical Bulletin TB-8).</p></Grid.Column>
                </Grid>
              </Section>
              <Section spacingBottom='5'>
                <Grid>
                  <Grid.Column flex='none'><Section width='17'><p className='warranty-bswm-text is-xs'>6.</p></Section></Grid.Column>
                  <Grid.Column flex='1'><p className='warranty-bswm-text is-xs'>The BlueScope Steel applied paint finish, must not be scratched, abraded, over-painted, treated with chemicals, damaged in any way, or coated with an incompatible material.</p></Grid.Column>
                </Grid>
              </Section>
              <Section>
                <Grid>
                  <Grid.Column flex='none'><Section width='17'><p className='warranty-bswm-text is-xs'>7.</p></Section></Grid.Column>
                  <Grid.Column flex='1'><p className='warranty-bswm-text is-xs'>All fasteners used in conjunction <span className='warranty-bswm-text is-xs is-bold'>{registeredTrademarkName}</span> steel are to be in accordance with BlueScope Steel recommendations see corrosion Technical Bulletin, CTB-12 Fasteners must be inspected on a regular basis and immediately replaced if they exhibit sings of corrosion failure.</p></Grid.Column>
                </Grid>
              </Section>
              <Section spacingBottom='5'>
                <Grid>
                  <Grid.Column flex='none'><Section width='17'><p className='warranty-bswm-text is-xs'>8.</p></Section></Grid.Column>
                  <Grid.Column flex='1'><p className='warranty-bswm-text is-xs'>All areas must be kept washed and clean, as corrosion arising in unwashed areas is not covered by this warranty. Cleaning chemicals must only be used in concentrations and contact durations as recommend by the chemical supplier. In any event, the concentration of the cleaning solution should not exceed 5%. Furthermore, high pressure water should not exceed 400psi (2760kPa) water pressure.</p></Grid.Column>
                </Grid>
              </Section>
              <Section spacingBottom='5'>
                <Grid>
                  <Grid.Column flex='none'><Section width='17'><p className='warranty-bswm-text is-xs'>9.</p></Section></Grid.Column>
                  <Grid.Column flex='1'><p className='warranty-bswm-text is-xs'>This warranty is not applicable to heavy industrial environments including, without limitation, chemical processing or storage, aluminium processing, fertilizer processing and storage, pulp and paper mills, or any similar facility which uses or is otherwise exposed to corrosive chemicals or environment.</p></Grid.Column>
                </Grid>
              </Section>
              <Section>
                <Grid>
                  <Grid.Column flex='none'><Section width='17'><p className='warranty-bswm-text is-xs'>10.</p></Section></Grid.Column>
                  <Grid.Column flex='1'><p className='warranty-bswm-text is-xs'>Perforations partly or wholly due to the following causes are not covered by this warranty:</p></Grid.Column>
                </Grid>
              </Section>
              <Section spacingBottom='5' paddingLeft='17'>
                <Grid>
                  <Grid.Column flex='none'><Section width='17'><p className='warranty-bswm-text is-xs'>a.</p></Section></Grid.Column>
                  <Grid.Column flex='1'><p className='warranty-bswm-text is-xs'>Failure to seal all joints during installation or whilst in service, to prevent moisture ingress into the panel system</p></Grid.Column>
                </Grid>
                <Grid>
                  <Grid.Column flex='none'><Section width='17'><p className='warranty-bswm-text is-xs'>b.</p></Section></Grid.Column>
                  <Grid.Column flex='1'><p className='warranty-bswm-text is-xs'>Mechanical, chemical, corrosion or other damage sustained during transport,</p></Grid.Column>
                </Grid>
                <Grid>
                  <Grid.Column flex='none'><Section width='17'><p className='warranty-bswm-text is-xs'>c.</p></Section></Grid.Column>
                  <Grid.Column flex='1'><p className='warranty-bswm-text is-xs'>Attack from chemical agents, fumes, liquids or solids stored in the cool roof.</p></Grid.Column>
                </Grid>
                <Grid>
                  <Grid.Column flex='none'><Section width='17'><p className='warranty-bswm-text is-xs'>d.</p></Section></Grid.Column>
                  <Grid.Column flex='1'><p className='warranty-bswm-text is-xs'>Contact with, mortar, bitumen, soils, ashes, fertilizers or other moisture retaining substances.</p></Grid.Column>
                </Grid>
                <Grid>
                  <Grid.Column flex='none'><Section width='17'><p className='warranty-bswm-text is-xs'>e.</p></Section></Grid.Column>
                  <Grid.Column flex='1'><p className='warranty-bswm-text is-xs'>Immersion as a result of flood or substandard site drainage.</p></Grid.Column>
                </Grid>
                <Grid>
                  <Grid.Column flex='none'><Section width='17'><p className='warranty-bswm-text is-xs'>f.</p></Section></Grid.Column>
                  <Grid.Column flex='1'><p className='warranty-bswm-text is-xs'>Areas in metallic contact with lead or copper or subject to the run-off from lead or copper flashing and pipes.</p></Grid.Column>
                </Grid>
                <Grid>
                  <Grid.Column flex='none'><Section width='17'><p className='warranty-bswm-text is-xs'>g.</p></Section></Grid.Column>
                  <Grid.Column flex='1'><p className='warranty-bswm-text is-xs'>Failure to remove debris and/or failure to provide free drainage of water from all surfaces of the product.</p></Grid.Column>
                </Grid>
                <Grid>
                  <Grid.Column flex='none'><Section width='17'><p className='warranty-bswm-text is-xs'>h.</p></Section></Grid.Column>
                  <Grid.Column flex='1'><p className='warranty-bswm-text is-xs'>Deterioration of the sheet caused by contact with green, wet or treated timbers.</p></Grid.Column>
                </Grid>
                <Grid>
                  <Grid.Column flex='none'><Section width='17'><p className='warranty-bswm-text is-xs'>i.</p></Section></Grid.Column>
                  <Grid.Column flex='1'><p className='warranty-bswm-text is-xs'>Installations subject to unusually corrosive environments at any time in the future.</p></Grid.Column>
                </Grid>
                <Grid>
                  <Grid.Column flex='none'><Section width='17'><p className='warranty-bswm-text is-xs'>j.</p></Section></Grid.Column>
                  <Grid.Column flex='1'><p className='warranty-bswm-text is-xs'>Acts of God and falling objects, external force, explosions, fire, riots, civil commotions, acts of war, radiation, harmful gases or fumes, chemicals or foreign substances in ther air or atmosphere, acts of terrorism, global warming, flooding and political disturbances including but not limited to uprisings and protests.</p></Grid.Column>
                </Grid>
                <Grid>
                  <Grid.Column flex='none'><Section width='17'><p className='warranty-bswm-text is-xs'>k.</p></Section></Grid.Column>
                  <Grid.Column flex='1'><p className='warranty-bswm-text is-xs'>Any loss or damage resulting from non-compliance with the terms and conditions of this warranty.</p></Grid.Column>
                </Grid>
              </Section>
              <Section spacingBottom='10'>
                <Grid>
                  <Grid.Column flex='none'><Section width='17'><p className='warranty-bswm-text is-xs'>11.</p></Section></Grid.Column>
                  <Grid.Column flex='1'><p className='warranty-bswm-text is-xs'>Any defects must be informed to NSBST in writing within 30 days of the date NSBST in its sole discretion believes such defect should have reasonably have been found given each individual circumstance.</p></Grid.Column>
                </Grid>
              </Section>
              <Section spacingBottom='5'><p className='warranty-bswm-text is-xs'>Should perforation occur in less than 5 years, BlueScope Steel’s liability shall be limited exclusively to replacement or repair of the affected portion of the material supplied by BlueScope Steel to the manufacturer (“Warranted Product”), as follows:</p></Section>
              <Section><p className='warranty-bswm-text is-xs is-bold'>Limitation of liability</p></Section>
              <Section><p className='warranty-bswm-text is-xs'>To the extent permitted by law, BlueScope Steel’s liability is limited to, at BlueScope Steel’s sole discretion:</p></Section>
              <Section spacingBottom='5' paddingLeft='17'>
                <Grid>
                  <Grid.Column flex='none'><Section width='17'><p className='warranty-bswm-text is-xs'>a.</p></Section></Grid.Column>
                  <Grid.Column flex='1'><p className='warranty-bswm-text is-xs'>the replacement or repair of the Warranted Product:</p></Grid.Column>
                </Grid>
                <Grid>
                  <Grid.Column flex='none'><Section width='17'><p className='warranty-bswm-text is-xs'>b.</p></Section></Grid.Column>
                  <Grid.Column flex='1'><p className='warranty-bswm-text is-xs'>the cost of the replacement or repair of the Warranted Product.</p></Grid.Column>
                </Grid>
              </Section>
              <Section><p className='warranty-bswm-text is-xs is-bold'>To the extent permitted by law:</p></Section>
              <Section spacingBottom='5' paddingLeft='17'>
                <Grid>
                  <Grid.Column flex='none'><Section width='17'><p className='warranty-bswm-text is-xs'>a.</p></Section></Grid.Column>
                  <Grid.Column flex='1'><p className='warranty-bswm-text is-xs'>All implied warranties, representations, terms and conditions relating to the supply of and use of the Product are excluded.</p></Grid.Column>
                </Grid>
                <Grid>
                  <Grid.Column flex='none'><Section width='17'><p className='warranty-bswm-text is-xs'>b.</p></Section></Grid.Column>
                  <Grid.Column flex='1'><p className='warranty-bswm-text is-xs'>BlueScope Steel shall not be liable for any labour costs associated with the installation or removal of the Warranted Product or the fixing of replacement panels; or Injury to persons, or damage to property or any other direct or indirect (including consequential) loss arising from the use of the product.</p></Grid.Column>
                </Grid>
              </Section>
              <Section spacingBottom='5'><p className='warranty-bswm-text is-xs'>NS BlueScope (Thailand) Company Limited extends this warranty solely to the Manufacturer.</p></Section>
            </React.Fragment>
          }
        </WarrantyContentWrapper>
      </React.Fragment>
    )
  }

  static defaultProps = {
    // If you set new default props, you should create propsType for correct styleguide document.
    registeredTrademarkName: 'PP Panel SD',
    subBrandName: '< Antibacterial, Antistatic >',
    customerName: '< Customer Name >',
    projectName: '< Full Project Name >',
    projectAddress: '< Full Project Address >',
    perforateByCorrosionYear: 'XX',
    peelOrFlakeExcessivelyYear: 'XX',
    colourFadeExcessivelyYear: 'XX',
    discolourByDirtStainingYear: 'XX',
    roofArea: '< Roof Area >',
    additionalProjectInformation: '< Additional Project Information >',
    roof: 'XX',
    wall: 'XX',
    purlinAndGirt: 'XX',
    trussAndFrame: 'XX',
    coolRoomPanel: 'XX',
    decking: 'XX',
  }

  static propTypes = {
    // TYPE > node, string, number, bool, array, object, symbol, func
  }
}
