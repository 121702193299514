import React from 'react'
import ClassNames from 'classnames'
// import PropTypes from 'prop-types'
import {
  AccordionControlBase
} from './../../base'
import {
  Section,
  Grid,
  Image,
  Text
} from './../../../components'
// import {
//   default as VARIABLES
// } from './../../../themes/styles/bases/variables'
// import {
//   default as TYPOGRAPHYS
// } from './../../../themes/styles/bases/typographys'
// import {
//   default as UTILITIES
// } from './../../../themes/styles/helpers/utilities'
import {
  VARIABLES
} from './../../../themes'

/**
 * AccordionControl description:
 * - AccordionControl
 */

class AccordionControlButton extends React.PureComponent {
  render() {
    const {
      className,
      active,
      onClick,
      name,
      icon,
      collapsible
    } = this.props

    // props for css classes
    const classes = ClassNames(
      className,
      'accordion__button',
      { 'is-active': active },
      { 'is-collapsible': collapsible }
    )

    return (
      <div className={classes}>
        <Section onClick={onClick}>
          <Grid gutter='5'>
            <Grid.Column>
              <Section
                width='45' // 26
                justify='center'
              >
                <Image ui={icon} />
              </Section>
            </Grid.Column>
            <Grid.Column flex="1">
              {/* <Text
                regular12={!active}
                bold12={active}
                color={VARIABLES.COLORS.WHITE}
              >
                {name}
              </Text> */}
              <Text
                regular16={!active}
                bold16={active}
                color={VARIABLES.COLORS.WHITE}
              >
                {name}
              </Text>
            </Grid.Column>
          </Grid>
        </Section>
      </div>
    )
  }

  static defaultProps = {
    // * If you set new default props, you should create propsType for correct styleguide document.
  }

  static propTypes = {
    // TYPE > node, string, number, bool, array, object, symbol, func
    /**
    * props_name_oneType
    */
    // props_name_onetype: PropTypes.string,

    /**
    * props_name_oneMoreType
    */
    // props_name_oneMoreType: PropTypes.oneOfType([
    //   PropTypes.node,
    //   PropTypes.string
    // ])
  }
}

class AccordionControlButtonSubMenu extends React.PureComponent {
  render() {
    const {
      className,
      active,
      onClick,
      name
    } = this.props

    // props for css classes
    const classes = ClassNames(
      className,
      'accordion__buttonsubmenu',
      { 'is-active': active }
    )

    return (
      <div
        className={classes}
        onClick={onClick}
      >
        <Grid>
          <Grid.Column flex='1'>
            <Section
              paddingLeft='56' // 37
              paddingVertical='11'
            >
              {/* <Text
                regular12
                singleLine
                color={VARIABLES.COLORS.WHITE}
              >
                {name}
              </Text> */}
              <Text
                regular14
                singleLine
                color={VARIABLES.COLORS.WHITE}
              >
                {name}
              </Text>
            </Section>
          </Grid.Column>
          <Grid.Column flex='none'>
            <Section
              className='accordion__buttonsubmenu__arrow'
              width='34'
              paddingTop='10'
              paddingLeft='10'
              paddingRight='15'
            >
              <Image ui='iconArrowSideMenu' />
            </Section>
          </Grid.Column>
        </Grid>
      </div>
    )
  }

  static defaultProps = {
    // * If you set new default props, you should create propsType for correct styleguide document.
  }

  static propTypes = {
    // TYPE > node, string, number, bool, array, object, symbol, func
    /**
    * props_name_oneType
    */
    // props_name_onetype: PropTypes.string,

    /**
    * props_name_oneMoreType
    */
    // props_name_oneMoreType: PropTypes.oneOfType([
    //   PropTypes.node,
    //   PropTypes.string
    // ])
  }
}

class AccordionControlButtonHelp extends React.PureComponent {
  render() {
    const {
      className,
      name
    } = this.props

    // props for css classes
    const classes = ClassNames(
      className,
      'accordion__buttonhelp'
    )

    return (
      <div className={classes}>
        <Text
          bold14
          color={VARIABLES.COLORS.PRIMARY_2}
        >
          {name}
        </Text>
      </div>
    )
  }

  static defaultProps = {
    // * If you set new default props, you should create propsType for correct styleguide document.
  }

  static propTypes = {
    // TYPE > node, string, number, bool, array, object, symbol, func
    /**
    * props_name_oneType
    */
    // props_name_onetype: PropTypes.string,

    /**
    * props_name_oneMoreType
    */
    // props_name_oneMoreType: PropTypes.oneOfType([
    //   PropTypes.node,
    //   PropTypes.string
    // ])
  }
}

class AccordionControlButtonOutline extends React.PureComponent {
  render() {
    const {
      className,
      name
    } = this.props

    // props for css classes
    const classes = ClassNames(
      className,
      'accordion__buttonoutline'
    )

    return (
      <div className={classes}>
        <Text
          bold12
          color={VARIABLES.COLORS.BLACK}
        >
          {name}
        </Text>
      </div>
    )
  }

  static defaultProps = {
    // * If you set new default props, you should create propsType for correct styleguide document.
  }

  static propTypes = {
    // TYPE > node, string, number, bool, array, object, symbol, func
    /**
    * props_name_oneType
    */
    // props_name_onetype: PropTypes.string,

    /**
    * props_name_oneMoreType
    */
    // props_name_oneMoreType: PropTypes.oneOfType([
    //   PropTypes.node,
    //   PropTypes.string
    // ])
  }
}

export class AccordionControl extends React.PureComponent {
  render() {
    const {
      className,
      menuSidebar,
      help,
      dropdownNotification,
      outline,
      error,
    } = this.props

    // props for css classes
    const classes = ClassNames(
      className,
      { 'is-menu-sidebar': menuSidebar },
      { 'is-help': help },
      { 'is-dropdown-notification': dropdownNotification },
      { 'is-outline': outline },
    )

    return (
      <AccordionControlBase
        {...this.props}
        className={classes}
      />
    )
  }

  static Button = AccordionControlButton
  static ButtonSubMenu = AccordionControlButtonSubMenu
  static ButtonHelp = AccordionControlButtonHelp
  static ButtonOutline = AccordionControlButtonOutline

  static defaultProps = {
    // * If you set new default props, you should create propsType for correct styleguide document.
  }

  static propTypes = {
    // TYPE > node, string, number, bool, array, object, symbol, func
    /**
    * props_name_oneType
    */
    // props_name_onetype: PropTypes.string,

    /**
    * props_name_oneMoreType
    */
    // props_name_oneMoreType: PropTypes.oneOfType([
    //   PropTypes.node,
    //   PropTypes.string
    // ])
  }
}
