import React from 'react'
import {
  ShapeContainer,
  Section,
  Breadcrumb,
  TitlePage,
  TitleSection,
  Grid,
  Button,
  Image,
  Text,
  UploadFile,
  Step,
  Table,
  TableAction,
  PaginationControl,
  ProgressBar,
} from './../../components'
import {
  VARIABLES,
  ICONS,
} from './../../themes'
import { ModalInfoContainer } from './../../containers/ModalContainer'
import { setSideMenuActive } from '../../helpers/functions/route';
import { ROUTE_PATH } from '../../helpers/constants/route';
import { redirect } from '../../helpers/functions/route';
import { toast, isShowLoading } from '../../helpers/functions/main'
import _ from 'lodash';
import { getLanguage } from '../../helpers/functions/language';
import { getCsv2Json } from '../../helpers/functions/addon';
import { getUserAuthRedux } from '../../helpers/functions/auth';
import { isValidResponse } from '../../helpers/functions/validation';
import { integrationController } from '../../apiService/apiController/integrationService';
import { ModelUploadTransaction } from '../../models/ModelUploadTransaction';
import { ENUM } from '../../helpers/constants/enum';
import { DATA } from '../../helpers/constants/data';

export class UploadTransactionScreen extends React.Component {

  constructor(props) {
    super(props);
    setSideMenuActive(props);
    const userAuth = getUserAuthRedux();
    this.state = {
      isStepValidation: true,
      isShowProgressBar: false,
      activePage: 1,
      dataPerPage: 10,
      uploadFile: undefined,
      dataList: [],
      csvDataList: [],
      loadedPercentage: 0,
      salesPersonId: 0,
      salesPersonName: '',
      userAuth,
    }

    this.componentConfig = {
      iconFiletypes: ['.csv'],
      showFiletypeIcon: true,
      postUrl: '/'
    }
  }

  pagination = {
    onPaginationChange: (pageNumber) => {
      this.setState({
        activePage: pageNumber
      })
    }
  }

  uploadTransaction = {
    onUploadProgress: (loadedPercentage) => {
      console.log('onUploadProgress', loadedPercentage);
    },
    onDownloadProgress: (loadedPercentage) => {
      console.log('onDownloadProgress', loadedPercentage);
      this.setState({
        loadedPercentage,
      }, () => {
        if (loadedPercentage < 100) {
          this.setState({
            isShowProgressBar: true,
          })
        }
        else {
          setTimeout(() => {
            this.setState({
              isShowProgressBar: false
            }, () => {
              const {
                isStepValidation,
              } = this.state;
              if (isStepValidation) {
                this.setState({
                  isStepValidation: false,
                  loadedPercentage: 0,
                })
              }
              else {
                this.uploadTransaction.onClickGoBack()
              }
            })
          }, 2000);
        }
      })
    },
    getDataList: () => {
      const {
        dataList,
        activePage,
        dataPerPage
      } = this.state;
      const start = (activePage - 1) * dataPerPage;
      const end = start + dataPerPage;
      return dataList.slice(start, end);
    },
    onClickValidateFile: async () => {
      const {
        uploadFile,
        userAuth,
      } = this.state;
      if (uploadFile) {
        const fileNameList = uploadFile.name.split('.');
        if (fileNameList[fileNameList.length - 1] === 'csv') {
          const csvData = await getCsv2Json(uploadFile);
          const dataList = csvData.data;
          console.log('dataList', dataList);
          const t1List = [];
          const t1KeyList = [];
          let columnLength = (dataList[0] || []).length;
          dataList.forEach((list, i) => {
            if (list.length < columnLength) {
              return;
            }
            if (i === 0) {
              list.forEach(e => {
                t1KeyList.push(e);
              })
            }
            else {
              const t1 = {}
              list.forEach((e, il) => {
                switch (t1KeyList[il]) {
                  case 'length_sold':
                  case 'purchase_value':
                  case 'customer_id':
                    try {
                      t1[t1KeyList[il]] = parseInt(e);
                    }
                    catch (err) {
                      t1[t1KeyList[il]] = e;
                    }
                    break;
                  default:
                    t1[t1KeyList[il]] = e;
                }
              });
              t1List.push(t1);
            }
          })
          const params = {
            t1: t1List,
            ad_ref_id: userAuth.adRefId,
            authorize_dealer_id: userAuth.id,
            country: userAuth.country,
            sfid: userAuth.sfid
          };
          const configService = {
            onUploadProgress: this.uploadTransaction.onUploadProgress,
            onDownloadProgress: this.uploadTransaction.onDownloadProgress,
            isShowLoading: false,
            isShowError: false,
          }
          const integrationService = integrationController(configService);
          this.setState({
            isShowProgressBar: true,
          }, async () => {
            const res = await integrationService.checkT1(params);
            let dataList = [];
            if (isValidResponse(res)) {
              dataList = (res.valid_t1 || []).map(e => new ModelUploadTransaction(e))
            }
            else {
              if (res.invalid_t1) {
                dataList = (res.invalid_t1 || []).map(e => new ModelUploadTransaction(e))
              }
              else {
                this.setState({
                  isShowProgressBar: false,
                });
                toast.error(getLanguage('message.formatFileIncorrect', ''));
              }
            }
            this.setState({
              dataList,
              csvDataList: t1List,
              salesPersonId: res.sales_person_id,
              salesPersonName: res.sales_person_name,
            })
          });
        }
        else {
          toast.error(getLanguage('uploadTransaction.form.validation.formatFileIncorrect', ''));
        }
      }
      else {
        toast.error(getLanguage('uploadTransaction.form.validation.formatFileIncorrect', ''));
      }
    },
    onClickUploadTransaction: () => {
      const {
        dataList,
        csvDataList,
        salesPersonId,
        salesPersonName,
        userAuth,
        uploadFile,
      } = this.state;
      const loadedPercentageStep = 100 / dataList.length;
      let loadedPercentage = 0;
      this.setState({
        isShowProgressBar: true,
      }, async () => {
        const fileNameList = uploadFile.name.split('.');
        const fileName = fileNameList[0];
        const paramsTransactionLog = {
          file_name: fileName,
          ad_ref_id: userAuth.id,
        }
        const configService = {
          isShowLoading: false,
        }
        const integrationService = integrationController(configService);
        const resTransactionLog = await integrationService.createTransactionLog(paramsTransactionLog);
        if (isValidResponse(resTransactionLog)) {
          dataList.map(async (e) => {
            const paramsCreate = {
              "transaction_log_id": resTransactionLog.transaction_log_id,
              "sales_transaction": {
                "products": [{
                  "coil_name": e.coilName,
                  "coil_id": e.coilNumber,
                  "order_m": e.lengthSold,
                  "points": 0,
                  "points_revenue": 0
                }],
                "sales_person_id": salesPersonId,
                "sales_person_name": salesPersonName,
                "customer_id": e.customerId,
                "customer_name": e.firstName,
                "phone_no": e.phone,
                "ad_ref_id": userAuth.id,
                "invoice_no": e.invoiceNo,
                "invoice_line": e.invoiceLine,
                "create_at": e.purchaseDate,
                "email": e.email
              },
              "t1": {
                "products": [{
                  "member__c": e.customerId,
                  "dealer__c": userAuth.adRefId,
                  "coil_length__c": e.lengthSold,
                  "sales_transaction__c": e.salesTransaction,
                  "myr__c": e.purchaseValue,
                  "createddate": e.purchaseDate,
                }]
              },
              "wrt": {
                "home_owner": e.homeowner,
                "project_name": e.projectName,
                "project_addr": "",
                "project_phone": e.phone,
                "project_sub_dist": "",
                "project_dist": "",
                "project_prov": "",
                "project_city": "",
                "project_post_code": "",
                "project_alley": "",
                "project_group": "",
                "project_street": "",
                "project_ward": "",
                "project_county": userAuth.addressType,
                "wrt_cat": DATA.WARRANTY_CATEGORY_TYPE.RESIDENTIAL.TYPE,
                "wrt_status": "-",
                "auth_deal_id": userAuth.id,
                "product": [{
                  "wrt_path": ""
                }],
                "condition": {},
                "created_by": userAuth.firstname,
                "user_id": e.userRefId,
                "customer_id": e.customerId,
                "sales_admin": userAuth.sfid
              }
            }
            const resCreate = await integrationService.createT1(paramsCreate);
            if (isValidResponse(resCreate)) {
              loadedPercentage += loadedPercentageStep;
              this.setState({
                loadedPercentage
              })
            }
            else {
              this.uploadTransaction.onClickGoBack()
              toast.error(resCreate.message);
            }
            if (loadedPercentage >= 100) {
              setTimeout(() => {
                this.uploadTransaction.onClickGoBack()
                toast.success(getLanguage('uploadTransaction.form.information.uploadTransactionSuccess', ''))
              }, 2000);
            }
          });
        }
      });
    },
    onClickGoBack: () => {
      this.setState({
        isStepValidation: true,
        isShowProgressBar: false,
        activePage: 1,
        uploadFile: undefined,
        dataList: [],
        csvDataList: [],
        loadedPercentage: 0,
      })
    },
  }

  input = {
    onChangeUploadFile: (file) => {
      if (this.myDropzone.getAcceptedFiles().length === 0) {
        console.log('onChangeUploadFile', file);
        this.setState({
          uploadFile: file,
        })
      }
    },
    onClickRemoveFile: (file) => {
      this.setState({
        uploadFile: undefined,
      })
    }
  }

  step = {
    onClickOpenErrorDetail: () => {
      this.setState({
        isErrorDetail: true,
      })
    },
    onClickCloseErrorDetail: () => {
      this.setState({
        isErrorDetail: false,
      })
    },
  }

  render() {
    const {
      isStepValidation,
      isShowProgressBar,
      activePage,
      isErrorDetail,
      dataList,
      loadedPercentage,
      dataPerPage,
      userAuth
    } = this.state
    const config = this.componentConfig
    // For a list of all possible events (there are many), see README.md!
    console.log('dataList', dataList)
    return (
      <React.Fragment>
        <Breadcrumb>
          <Breadcrumb.List
            link
            name={getLanguage('uploadTransaction.breadcrumb1', '')}
            onClick={() => {
              redirect(ROUTE_PATH.HOME.LINK)
            }}
          />
          <Breadcrumb.List name='/' />
          <Breadcrumb.List
            link
            name={getLanguage('uploadTransaction.breadcrumb2', '')}
          // onClick={() => {
          //   redirect(ROUTE_PATH.UPLOAD_TRANSACTION_INDEXES.LINK)
          // }}
          />
          <Breadcrumb.List name='/' />
          <Breadcrumb.List name={getLanguage('uploadTransaction.breadcrumb3', '')} />
        </Breadcrumb>
        <Section ui='content'>
          <TitlePage
            src={ICONS['ic-page-upload-transaction.svg']}
            name={getLanguage('uploadTransaction.title', '')}
          />
          <Step
            label
            progressPercent={isStepValidation ? '0' : '100'}
          >
            <Step.Number
              active={isStepValidation ? true : false}
              success={!isStepValidation ? true : false}
              number='1'
              label={getLanguage('uploadTransaction.step.validation.title', '')}
            />
            <Step.Number
              active={!isStepValidation ? true : false}
              success={false}
              number='2'
              label={getLanguage('uploadTransaction.step.information.title', '')}
            />
          </Step>
          <ShapeContainer
            ui='contentStep'
            justify='space-between'
          >
            <Section spacingBottom='45'>
              <Section spacingBottom='40'>
                <TitleSection name={
                  isStepValidation ?
                    getLanguage('uploadTransaction.form.validation.title', '')
                    :
                    getLanguage('uploadTransaction.form.information.title', '')
                }
                />
              </Section>
              <Section spacingBottom='30'>
                {
                  isStepValidation || dataList.length === 0 ?
                    <React.Fragment>
                      <Section spacingBottom='30'>
                        <UploadFile
                          document
                          config={config}
                          eventHandlers={{
                            init: (r) => this.myDropzone = r,
                            addedfile: this.input.onChangeUploadFile,
                            removedfile: this.input.onClickRemoveFile,
                            maxfilesexceeded: (file) => {
                              this.myDropzone.removeAllFiles();
                              this.myDropzone.addFile(file);
                            }
                          }}
                          djsConfig={{
                            dictDefaultMessage: getLanguage('uploadTransaction.form.validation.placeholderUploadTransaction', ''),
                            buttonRemoveFile: getLanguage('uploadTransaction.form.validation.placeholderUploadTransaction', ''),
                            addRemoveLinks: true,
                            // acceptedFiles: '.csv',
                            autoProcessQueue: false,
                            createImageThumbnails: false,
                            maxFiles: 1,
                            isErrorDetail: false,
                          }}
                        />
                      </Section>
                      <Section justify='center'>
                        {/* [TODO] Error notification */}
                        {/* toast.error(getLanguage('uploadTransaction.form.validation.formatFileIncorrect', '')) */}
                        <Grid
                          justify='center'
                          gutter='30'
                        >
                          {
                            userAuth.addressType === ENUM.COUNTRY_TYPE.VIETNAM &&
                            <Grid.Column>
                              <a
                                className='link-wrapper'
                                href={ENUM.DOWNLOAD_PATH.UPLOAD_TRANSACTION_TEMPLATE}
                                target='_blank'
                                rel="noopener noreferrer"
                              >
                                <Button
                                  outlineBlue
                                  ui='frontIcon'
                                  name={getLanguage('uploadTransaction.form.validation.buttonDownloadTemplate', '')}
                                  iconWidth='17'
                                  iconHeight='14'
                                  iconSrc={ICONS['ic-download-template.svg']}
                                  width={VARIABLES.BUTTON.WIDTHS.W_200}
                                  onClick={() => { }}
                                />
                              </a>
                            </Grid.Column>
                          }
                          <Grid.Column>
                            <Button
                              blue
                              ui='frontIcon'
                              name={getLanguage('uploadTransaction.form.validation.buttonValidateTransaction', '')}
                              iconWidth='16'
                              iconHeight='16'
                              iconSrc={ICONS['ic-validate-file.svg']}
                              width={VARIABLES.BUTTON.WIDTHS.W_200}
                              onClick={this.uploadTransaction.onClickValidateFile}
                            />
                          </Grid.Column>
                        </Grid>

                      </Section>
                    </React.Fragment>
                    :
                    <React.Fragment>
                      <Table
                        striped
                        action
                      >
                        <Table.Section>
                          <Table.Row>
                            <Table.HeadColumn
                              noSort
                              alignCenter
                              maxWidth={VARIABLES.TABLE.INFO_WIDTHS.STATUS_TRANSACTION_FILE}
                              minWidth={VARIABLES.TABLE.INFO_WIDTHS.STATUS_TRANSACTION_FILE}
                            // sortAscending
                            // onClickSort={() => {}}
                            >
                              {getLanguage('uploadTransaction.form.information.table.header.status', '')}
                            </Table.HeadColumn>
                            <Table.HeadColumn
                              noSort
                              minWidth={VARIABLES.TABLE.INFO_WIDTHS.INVOICE_NUMBER}
                            // sortAscending
                            // onClickSort={() => {}}
                            >
                              {getLanguage('uploadTransaction.form.information.table.header.invoiceNumber', '')}
                            </Table.HeadColumn>
                            <Table.HeadColumn
                              noSort
                              minWidth={VARIABLES.TABLE.INFO_WIDTHS.INVOICE_LINE}
                            // sortAscending
                            // onClickSort={() => {}}
                            >
                              {getLanguage('uploadTransaction.form.information.table.header.invoiceLine', '')}
                            </Table.HeadColumn>
                            <Table.HeadColumn
                              noSort
                              maxWidth={VARIABLES.TABLE.INFO_WIDTHS.ACTION_ERROR}
                              minWidth={VARIABLES.TABLE.INFO_WIDTHS.ACTION_ERROR}
                            >
                              {getLanguage('uploadTransaction.form.information.table.header.error', '')}
                            </Table.HeadColumn>
                            <Table.HeadColumn
                              noSort
                              minWidth={VARIABLES.TABLE.INFO_WIDTHS.SALESPERSON_FULL_NAME}
                            // sortAscending
                            // onClickSort={() => {}}
                            >
                              {getLanguage('uploadTransaction.form.information.table.header.saleperson', '')}
                            </Table.HeadColumn>
                            <Table.HeadColumn
                              noSort
                              minWidth={VARIABLES.TABLE.INFO_WIDTHS.PHONE_NO_SORT}
                            // sortAscending
                            // onClickSort={() => {}}
                            >
                              {getLanguage('uploadTransaction.form.information.table.header.phone', '')}
                            </Table.HeadColumn>
                            <Table.HeadColumn
                              noSort
                              minWidth={VARIABLES.TABLE.INFO_WIDTHS.EMAIL_FULL}
                            // sortAscending
                            // onClickSort={() => {}}
                            >
                              {getLanguage('uploadTransaction.form.information.table.header.email', '')}
                            </Table.HeadColumn>
                            <Table.HeadColumn
                              noSort
                              minWidth={VARIABLES.TABLE.INFO_WIDTHS.COIL_ID_FULL}
                            // sortAscending
                            // onClickSort={() => {}}
                            >
                              {getLanguage('uploadTransaction.form.information.table.header.coilId', '')}
                            </Table.HeadColumn>
                            <Table.HeadColumn
                              noSort
                              alignRight
                              minWidth={VARIABLES.TABLE.INFO_WIDTHS.LENGTH_SOLD}
                            // sortAscending
                            // onClickSort={() => {}}
                            >
                              {getLanguage('uploadTransaction.form.information.table.header.lengthSold', '')}
                            </Table.HeadColumn>
                            <Table.HeadColumn
                              noSort
                              minWidth={VARIABLES.TABLE.INFO_WIDTHS.PROJECT_NAME_FULL}
                            // sortAscending
                            // onClickSort={() => {}}
                            >
                              {getLanguage('uploadTransaction.form.information.table.header.projectName', '')}
                            </Table.HeadColumn>
                            <Table.HeadColumn
                              noSort
                              minWidth={VARIABLES.TABLE.INFO_WIDTHS.HOMEOWNER_FULL}
                            // sortAscending
                            // onClickSort={() => {}}
                            >
                              {getLanguage('uploadTransaction.form.information.table.header.homeowner', '')}
                            </Table.HeadColumn>
                            <Table.HeadColumn
                              noSort
                              minWidth={VARIABLES.TABLE.INFO_WIDTHS.DATE_PURCHASING_WITH_TIME}
                            // sortAscending
                            // onClickSort={() => {}}
                            >
                              {getLanguage('uploadTransaction.form.information.table.header.purchaseDate', '')}
                            </Table.HeadColumn>
                            <Table.HeadColumn
                              noSort
                              alignRight
                              minWidth={VARIABLES.TABLE.INFO_WIDTHS.PURCHASE_VALUE}
                            // sortAscending
                            // onClickSort={() => {}}
                            >
                              {getLanguage('uploadTransaction.form.information.table.header.purchaseValue', '')}
                            </Table.HeadColumn>
                          </Table.Row>
                        </Table.Section>
                        <Table.Section>
                          {this.uploadTransaction.getDataList().map((e, i) => {
                            return (
                              <Table.Row key={i}>
                                <Table.BodyColumn
                                  info
                                  alignCenter
                                  maxWidth={VARIABLES.TABLE.INFO_WIDTHS.STATUS_TRANSACTION_FILE}
                                  minWidth={VARIABLES.TABLE.INFO_WIDTHS.STATUS_TRANSACTION_FILE}
                                >
                                  <Image ui={e.isError ? 'iconStatusFail' : 'iconStatusSuccess'} top='-1' />
                                </Table.BodyColumn>
                                <Table.BodyColumn
                                  info
                                  minWidth={VARIABLES.TABLE.INFO_WIDTHS.INVOICE_NUMBER}
                                >
                                  {e.invoiceNo}
                                </Table.BodyColumn>
                                <Table.BodyColumn
                                  info
                                  minWidth={VARIABLES.TABLE.INFO_WIDTHS.INVOICE_LINE}
                                >
                                  {e.invoiceLine}
                                </Table.BodyColumn>
                                <Table.BodyColumn
                                  action
                                  maxWidth={VARIABLES.TABLE.INFO_WIDTHS.ACTION_ERROR}
                                  minWidth={VARIABLES.TABLE.INFO_WIDTHS.ACTION_ERROR}
                                  title={e.isError ? e.message : '-'}
                                >
                                  {
                                    false ?
                                      <Section>
                                        <Image
                                          ui='iconErrorNote'
                                          top='-1'
                                          onClick={this.step.onClickOpenErrorDetail}
                                        />
                                      </Section>
                                      :
                                      <Text
                                        regular12
                                        color={VARIABLES.COLORS.GRAY_19}
                                        top='1'
                                      >
                                        {e.isError ? e.message : '-'}
                                      </Text>
                                  }
                                </Table.BodyColumn>
                                <Table.BodyColumn
                                  info
                                  minWidth={VARIABLES.TABLE.INFO_WIDTHS.SALESPERSON_FULL_NAME}
                                  title={e.fullName}
                                >
                                  {e.fullName}
                                </Table.BodyColumn>
                                <Table.BodyColumn
                                  info
                                  minWidth={VARIABLES.TABLE.INFO_WIDTHS.PHONE_NO_SORT}
                                >
                                  {e.phone}
                                </Table.BodyColumn>
                                <Table.BodyColumn
                                  info
                                  minWidth={VARIABLES.TABLE.INFO_WIDTHS.EMAIL_FULL}
                                  title={e.email}
                                >
                                  {e.email}
                                </Table.BodyColumn>
                                <Table.BodyColumn
                                  info
                                  minWidth={VARIABLES.TABLE.INFO_WIDTHS.COIL_ID_FULL}
                                >
                                  {e.coilNumber}
                                </Table.BodyColumn>
                                <Table.BodyColumn
                                  info
                                  alignRight
                                  minWidth={VARIABLES.TABLE.INFO_WIDTHS.LENGTH_SOLD}
                                >
                                  {e.lengthSold}
                                </Table.BodyColumn>
                                <Table.BodyColumn
                                  info
                                  minWidth={VARIABLES.TABLE.INFO_WIDTHS.PROJECT_NAME_FULL}
                                  title={e.projectName}
                                >
                                  {e.projectName}
                                </Table.BodyColumn>
                                <Table.BodyColumn
                                  info
                                  minWidth={VARIABLES.TABLE.INFO_WIDTHS.HOMEOWNER_FULL}
                                  title={e.homeowner}
                                >
                                  {e.homeowner}
                                </Table.BodyColumn>
                                <Table.BodyColumn
                                  info
                                  minWidth={VARIABLES.TABLE.INFO_WIDTHS.DATE_PURCHASING_WITH_TIME}
                                >
                                  {e.displayPurchaseDate}
                                </Table.BodyColumn>
                                <Table.BodyColumn
                                  info
                                  alignRight
                                  minWidth={VARIABLES.TABLE.INFO_WIDTHS.PURCHASE_VALUE}
                                >
                                  {e.purchaseValueWithComma}
                                </Table.BodyColumn>
                              </Table.Row>
                            )
                          })
                          }
                        </Table.Section>
                      </Table>
                    </React.Fragment>
                }
              </Section>
            </Section>
            {
              !isStepValidation &&
              <TableAction>
                <TableAction.Left>
                  <PaginationControl
                    activePage={activePage}
                    itemsCountPerPage={dataPerPage}
                    totalItemsCount={dataList.length}
                    pageRangeDisplayed={3}
                    onChange={this.pagination.onPaginationChange}
                  />
                </TableAction.Left>
                <TableAction.Right>
                  <Grid gutter='20'>
                    <Grid.Column>
                      <Button
                        gray
                        ui='back'
                        name={getLanguage('field.buttonBack', '')}
                        onClick={this.uploadTransaction.onClickGoBack}
                      />
                    </Grid.Column>
                    <Grid.Column>
                      <Button
                        disabled={dataList.length === 0 || dataList.filter(e => e.isError).length}
                        blue
                        ui='frontIcon'
                        name={getLanguage('uploadTransaction.form.information.buttonUploadTransaction', '')}
                        iconWidth='13'
                        iconHeight='16'
                        iconSrc={ICONS['ic-upload.svg']}
                        width={VARIABLES.BUTTON.WIDTHS.W_200}
                        onClick={this.uploadTransaction.onClickUploadTransaction}
                      />
                    </Grid.Column>
                  </Grid>
                </TableAction.Right>
              </TableAction>
            }
          </ShapeContainer>
        </Section>
        {
          isShowProgressBar &&
          <ProgressBar
            active
            percent={loadedPercentage}
            message={isStepValidation ?
              getLanguage('uploadTransaction.form.validation.checkingInProcess', '')
              :
              getLanguage('uploadTransaction.form.information.uploadingInProcess', '')}
          />
        }
        {
          isErrorDetail &&
          <ModalInfoContainer
            isOpenModal
            title={getLanguage('uploadTransaction.form.information.modal.title', '')}
            message='ERROR_DETAIL'
            buttonSubmitName={getLanguage('field.buttonClose', '')}
            onClickClose={this.step.onClickCloseErrorDetail}
            onClickConfirm={this.step.onClickCloseErrorDetail}
          />
        }
      </React.Fragment>
    )
  }
}
