import styled, {
  // css
} from 'styled-components'
import {
  default as VARIABLES
} from './../../../themes/styles/bases/variables'
import {
  default as TYPOGRAPHYS
} from './../../../themes/styles/bases/typographys'
import {
  default as MIXINS
} from './../../../themes/styles/helpers/mixins'

// Sample using image in style
// url(${require('./../../../themes/images/icons/icon-sample.svg')});

// Wrapper
// ============================================================
export const DatePickerControlWrapper = styled.div`
  /* Parent
  ------------------------------- */
  position: relative;
  z-index: ${VARIABLES.Z_INDEXS.LV_2};

  /* Childrens
  ------------------------------- */
  .react-datepicker {
    &:before {
      content: 'Select Date';
      ${TYPOGRAPHYS.FIRST.REGULAR.FS_14};
      display: block;
      color: ${VARIABLES.COLORS.GRAY_21};
      margin-bottom: 5px;
    }
  }

  /* Field */
  .react-datepicker-wrapper {}

  .react-datepicker__input-container {
    position: relative;

    &:after {
      content: ' ';
      transform: translateY(-50%);
      position: absolute;
      z-index: ${VARIABLES.Z_INDEXS.LV_1};
      top: 50%;
      right: 15px;
      display: block;
      width: 14px;
      height: 16px;
      margin-top: -1px;
      background-image: url(${require('./../../../themes/images/icons/ic-calendar.svg')});
      background-size: 14px 16px;
    }

    input {
      ${MIXINS.PLACEHOLDER({ color: VARIABLES.COLORS.GRAY_12 })};
      ${TYPOGRAPHYS.FIRST.REGULAR.FS_14};
      width: 100%;
      padding-top: 7px;
      padding-right: 57px;
      padding-bottom: 5px;
      padding-left: ${VARIABLES.FIELD.PADDINGS.HORIZONTAL + `px`};
      border: 1px solid ${VARIABLES.COLORS.GRAY_8};
      border-radius: 3px;
      color: ${VARIABLES.COLORS.BLACK};
    }
  }

  /* Calendar */
  .react-datepicker-popper {}

  .react-datepicker {
    padding: 20px;
    background-color: ${VARIABLES.COLORS.WHITE};
    border-color: ${VARIABLES.COLORS.WHITE};
    border-radius: 0;
    font-family: ${VARIABLES.FONT.FAMILIES.FIRST.WEIGHTS.REGULAR};
    color: ${VARIABLES.COLORS.GRAY_20};
    box-shadow: ${VARIABLES.COLORS.SHADOW_3};
  }

  .react-datepicker__triangle {
    display: none;
  }

  .react-datepicker__month-container {}

  .react-datepicker__header {
    padding-top: 0;
    background-color: ${VARIABLES.COLORS.WHITE};
    border-bottom-color: ${VARIABLES.COLORS.GRAY_5};
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  .react-datepicker__current-month {
    ${TYPOGRAPHYS.FIRST.BOLD.FS_12}
    display: none;
    color: ${VARIABLES.COLORS.GRAY_20};
  }

  .react-datepicker__day-names {}

  .react-datepicker__day-name {
    ${TYPOGRAPHYS.FIRST.BOLD.FS_9}
    width: 47px;
    padding-bottom: 10px;
    margin: 0;
    color: ${VARIABLES.COLORS.GRAY_14};
  }

  .react-datepicker__month {
    padding-top: 10px;
    margin: 0;
  }

  .react-datepicker__month-text {
    &:hover {
      border-radius: 50%;
    }
  }

  .react-datepicker__day {
    ${TYPOGRAPHYS.FIRST.REGULAR.FS_12}
    width: 47px;
    margin: 0;
    line-height: 47px;
    color: ${VARIABLES.COLORS.GRAY_20};

    &:hover {
      border-radius: 50%;
    }

    &.react-datepicker__day--disabled,
    &.react-datepicker__month-text--disabled,
    &.react-datepicker__quarter-text--disable {
      color: ${VARIABLES.COLORS.GRAY_8};
    }
  }

  /* Selected */
  .react-datepicker__day--selected,
  .react-datepicker__day--in-selecting-range,
  .react-datepicker__day--in-range,
  .react-datepicker__month-text--selected,
  .react-datepicker__month-text--in-selecting-range,
  .react-datepicker__month-text--in-range,
  .react-datepicker__day--keyboard-selected,
  .react-datepicker__month-text--keyboard-selected {
    background-color: ${VARIABLES.COLORS.OVERLAY_BLUE_2};
    border-radius: 50%;
    color: ${VARIABLES.COLORS.PRIMARY_1};

    &:hover {
      background-color: ${VARIABLES.COLORS.OVERLAY_BLUE_2};
    }
  }

  .react-datepicker__day--keyboard-selected,
  .react-datepicker__month-text--keyboard-selected {
    &:hover {
      background-color: ${VARIABLES.COLORS.OVERLAY_BLUE_2};
    }
  }

  .react-datepicker__navigation {
    display: none;
    top: 85px;
    width: 10px;
    height: 15px;
    padding: 0;
    border: 0 solid transparent;
    line-height: 1;
  }

  .react-datepicker__navigation--previous {
    left: 30px;
    background-image: url(${require('./../../../themes/images/icons/ic-arrow-calendar-prev.svg')});
    background-size: 10px 15px;
  }

  .react-datepicker__navigation--next {
    right: 30px;
    background-image: url(${require('./../../../themes/images/icons/ic-arrow-calendar-next.svg')});
    background-size: 10px 15px;
  }

  /* Month - Year Dropdown */
  .react-datepicker__header__dropdown {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 20px;
  }

  .react-datepicker__month-dropdown-container,
  .react-datepicker__year-dropdown-container {
    position: relative;
    display: block;
    margin: 0;

    &:after {
      content: ' ';
      position: absolute;
      z-index: ${VARIABLES.Z_INDEXS.LV_1};
      top: 1px;
      display: block;
      width: 30px;
      height: 30px;
      background-color: ${VARIABLES.COLORS.WHITE};
      background-image: url(${require('./../../../themes/images/icons/ic-arrow-select.svg')});
      background-size: 10px 6px;
      background-position: center;
      background-repeat: no-repeat;
      border-radius: 5px;
    }
  }

  .react-datepicker__month-select,
  .react-datepicker__year-select {
    ${TYPOGRAPHYS.FIRST.BOLD.FS_12}
    cursor: pointer;
    width: 100%;
    height: 32px;
    padding-right: 25px;
    padding-left: 30px;
    border: 1px solid ${VARIABLES.COLORS.GRAY_8};
    border-radius: 5px;
    color: ${VARIABLES.COLORS.GRAY_20};
  }

  .react-datepicker__month-dropdown-container {
    width: 60%;
    padding-right: 30px;

    &:after {
      right: 31px;
    }
  }

  .react-datepicker__year-dropdown-container {
    width: 40%;

    &:after {
      right: 1px;
    }
  }

  .date-picker-control-label {
    ${TYPOGRAPHYS.FIRST.REGULAR.FS_14};
    color: ${VARIABLES.COLORS.GRAY_19};
    margin-bottom: 5px;
  }

  /* Modifiers
  ------------------------------- */
  &.is-filter {
    .date-picker-control-label {
      display: none;
    }

    .react-datepicker-wrapper {
      width: 100%;
    }

    .react-datepicker__input-container {
      &:after {
        right: auto;
        left: 20px;
        display: block;
        width: 14px;
        height: 16px;
        margin-top: -1px;
        background-image: url(${require('./../../../themes/images/icons/ic-calendar-filter.svg')});
        background-size: 14px 16px;
      }

      input {
        ${MIXINS.PLACEHOLDER({ color: VARIABLES.COLORS.GRAY_12 })};
        ${TYPOGRAPHYS.FIRST.REGULAR.FS_12};
        padding-top: 8px;
        padding-right: 20px;
        padding-bottom: 8px;
        padding-left: 53px;
        border: none;
        border-radius: 5px;
        color: ${VARIABLES.COLORS.BLACK};
      }
    }
  }

  &.is-fluid {
    .react-datepicker-wrapper {
      width: 100%;
    }
  }

  &.is-required {
    .react-datepicker-wrapper {
      position: relative;

      &:after {
          content: ' ';
          position: absolute;
          z-index: ${VARIABLES.Z_INDEXS.LV_1};
          top: 0;
          right: -10px;
          display: block;
          width: 6px;
          height: 6px;
          background-color: ${VARIABLES.COLORS.ERROR};
          border-radius: 50%;
        }
    }
  }

  &.is-label-required {
    .date-picker-control-label {
      position: relative;
      display: inline-block;

      &:after {
        content: ' ';
        position: absolute;
        z-index: ${VARIABLES.Z_INDEXS.LV_1};
        top: 0;
        right: -10px;
        display: block;
        width: 4px;
        height: 4px;
        background-color: ${VARIABLES.COLORS.RED_4};
        border-radius: 50%;
      }
    }
  }

  &.is-bottom {
    .react-datepicker-popper {
      top: 100% !important;
      transform: translate3d(0, 0, 0) !important;
      margin-top: 10px;
      margin-bottom: 0;
    }
  }

  &.is-top {
    .react-datepicker-popper {
      top: auto !important;
      bottom: calc(100% - 13px) !important;
      transform: translate3d(0, 0, 0) !important;
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  &.is-small {
    .date-picker-control-label {
      ${TYPOGRAPHYS.FIRST.REGULAR.FS_12};
      color: ${VARIABLES.COLORS.BLACK};
    }

    .react-datepicker__input-container {
      input {
        ${TYPOGRAPHYS.FIRST.REGULAR.FS_12};
        padding-top: 5px;
        padding-bottom: 2px;
      }
    }
  }

  &.is-disabled {
    .react-datepicker__input-container {
      input {
        background-color: ${VARIABLES.COLORS.GRAY_1};
      }
    }
  }

  &.is-line {
    .react-datepicker__input-container {
      &:after {
        right: 0;
      }

      input {
        padding-top: 9px;
        padding-right: 0;
        padding-left: 0;
        border: none;
        border-bottom: 1px solid ${VARIABLES.COLORS.GRAY_2};
      }
    }
  }

  /* Media queries
  ------------------------------- */
`
