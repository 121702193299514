import React from 'react'
import ClassNames from 'classnames'
// import PropTypes from 'prop-types'
import {
  ShapeContainerBase
} from './../../base'
import {
  Text,
  Image,
  Grid,
  Section,
  Button,
  Field,
} from './../../../components'
import {
  default as VARIABLES
} from './../../../themes/styles/bases/variables'
// import {
//   default as TYPOGRAPHYS
// } from './../../../themes/styles/bases/typographys'
// import {
//   default as UTILITIES
// } from './../../../themes/styles/helpers/utilities'
import {
  ICONS
} from './../../../themes'
import { getLanguage } from '../../../helpers/functions/language'
import _ from 'lodash';
/**
 * ShapeContainer description:
 * - ShapeContainer
 */

export class ShapeContainer extends React.PureComponent {
  render() {
    const {
      className,
      ui,
      children,
      companyName,
      srcUserImage,
      srcUserUploadImage,
      iconWidth,
      iconHeight,
      iconSrc,
      dropdown,
      notificationActive,
      notificationSrcIcon,
      notificationTitle,
      notificationDescription,
      badgeCountTitle,
      badgeCountNumber,
      warrantyName,
      onClickWarrantyPreview,
      onClickWarrantyDownload,
      productInformation,
      statisticName,
      statisticDetail,
      statisticLeftLabel,
      statisticLeftInfo,
      statisticCenterLabel,
      statisticCenterInfo,
      statisticRightLabel,
      statisticRightInfo,
      statisticImageChartSrc,
      checked,
      warrantyTermsAndConditions,
      warrantyTermsAndConditionsName,
      warrantyTermsAndConditionsValue,
      checkedWarrantyTermsAndConditions,
      onChangeWarrantyTermsAndConditions,
      onClickWarrantyTermsAndConditions,
      hoverTextColor,
      barcodeScan,
      tooltips,
    } = this.props

    // props for css classes
    const classes = ClassNames(
      className,
      { 'is-dropdown': dropdown },
      { 'is-product-information': productInformation },
      { 'is-hover-text': hoverTextColor },
      { 'is-barcode-scan': barcodeScan },
      { 'is-tooltips': tooltips }
    )

    return (
      <React.Fragment>
        {(() => {
          switch (ui) {
            case 'login':
              return (
                <ShapeContainerBase
                  {...this.props}
                  className={classes}
                  width='615'
                  paddingTop='80'
                  paddingBottom='45'
                  paddingHorizontal='45'
                  spacingVertical='45'
                  bgColor={VARIABLES.COLORS.WHITE}
                  borderRadius='10'
                />
              )
            case 'companyName':
              return (
                <ShapeContainerBase
                  {...this.props}
                  className={classes}
                  paddingTop='9'
                  paddingBottom='10'
                  paddingHorizontal='30'
                  borderWidth='1'
                  borderStyle='solid'
                  borderColor={VARIABLES.COLORS.PRIMARY_2}
                  borderRadius='5'
                >
                  <Text
                    ellipsis
                    bold22
                    singleLine
                    color={VARIABLES.COLORS.PRIMARY_2}
                  >
                    {companyName}
                  </Text>
                </ShapeContainerBase>
              )
            case 'user':
              return (
                <ShapeContainerBase
                  {...this.props}
                  className={classes}
                  width='44'
                  height='44'
                  borderWidth='1'
                  borderStyle='solid'
                  borderColor={VARIABLES.COLORS.BLUE_20}
                  borderRadius='22'
                >
                  <Image
                    ui='user'
                    src={srcUserImage}
                  />
                </ShapeContainerBase>
              )
            case 'userUpload':
              return (
                <ShapeContainerBase
                  {...this.props}
                  className={classes}
                  width='132'
                  height='132'
                  borderWidth='1'
                  borderStyle='solid'
                  borderColor={VARIABLES.COLORS.GRAY_12}
                  borderRadius='66'
                >
                  <Image
                    ui='userUpload'
                    src={srcUserUploadImage}
                  />
                </ShapeContainerBase>
              )
            case 'content':
              return (
                <ShapeContainerBase
                  {...this.props}
                  className={classes}
                  fluid
                  calcMinHeight='100vh - 261px'
                  paddingVertical='40'
                  paddingHorizontal='40'
                  bgColor={VARIABLES.COLORS.WHITE}
                  borderRadius='10'
                />
              )
            case 'contentYearWarranty':
              return (
                <ShapeContainerBase
                  {...this.props}
                  className={classes}
                  fluid
                  calcMinHeight='100vh - 261px'
                  paddingTop='30'
                  paddingBottom='45'
                  paddingHorizontal='45'
                  bgColor={VARIABLES.COLORS.WHITE}
                  borderRadius='10'
                />
              )
            case 'contentFilter':
              return (
                <ShapeContainerBase
                  {...this.props}
                  className={ClassNames(
                    'is-content-filter',
                    classes,
                  )}
                  fluid
                  calcMinHeight='100vh - 365px'
                  paddingVertical='40'
                  paddingHorizontal='40'
                  bgColor={VARIABLES.COLORS.WHITE}
                  borderRadius='10'
                />
              )
            case 'contentStep':
              return (
                <ShapeContainerBase
                  {...this.props}
                  className={classes}
                  fluid
                  calcMinHeight='100vh - 354px' // 375
                  paddingVertical='40'
                  paddingHorizontal='40'
                  bgColor={VARIABLES.COLORS.WHITE}
                  borderRadius='10'
                />
              )
            case 'contentInner':
              return (
                <ShapeContainerBase
                  {...this.props}
                  className={classes}
                  fluid
                  padding='50'
                  bgColor={VARIABLES.COLORS.BLUE_3}
                  borderWidth='1'
                  borderStyle='solid'
                  borderColor={VARIABLES.COLORS.BLUE_15}
                  borderRadius='10'
                />
              )
            case 'required':
              return (
                <ShapeContainerBase
                  {...this.props}
                  className={classes}
                  width='6'
                  height='6'
                  borderRadius='3'
                  bgColor={VARIABLES.COLORS.ERROR}
                />
              )
            case 'numberHelp':
              return (
                <ShapeContainerBase
                  {...this.props}
                  className={classes}
                  justify='center'
                  align='center'
                  width='28'
                  height='28'
                  borderWidth='2'
                  borderStyle='solid'
                  borderColor={VARIABLES.COLORS.PRIMARY_2}
                  borderRadius='14'
                >
                  <Text
                    bold12
                    singleLine
                    color={VARIABLES.COLORS.PRIMARY_2}
                    left='-1'
                  >
                    {children}
                  </Text>
                </ShapeContainerBase>
              )
            case 'buttonIconCircle':
              return (
                <ShapeContainerBase
                  {...this.props}
                  className={classes}
                  justify='center'
                  align='center'
                  width='42'
                  height='42'
                  borderRadius='21'
                >
                  <Image
                    widthRatio={iconWidth}
                    widthRatioUnit='px'
                    heightRatio={iconHeight}
                    heightRatioUnit='px'
                    src={iconSrc}
                  />
                </ShapeContainerBase>
              )
            case 'notificationList':
              return (
                <ShapeContainerBase
                  {...this.props}
                  className={classes}
                  fluid
                  padding='15'
                  bgColor={notificationActive ? VARIABLES.COLORS.GRAY_1 : VARIABLES.COLORS.WHITE}
                  borderBottomWidth='1'
                  borderBottomStyle='solid'
                  borderBottomColor={VARIABLES.COLORS.WHITE}
                >
                  <Grid
                    gutter='30'
                    justify='space-between'
                  >
                    <Grid.Column>
                      <Grid gutter='15'>
                        <Grid.Column flex='none'>
                          <Image
                            widthRatio='42'
                            widthRatioUnit='px'
                            heightRatio='42'
                            heightRatioUnit='px'
                            src={notificationSrcIcon}
                          />
                        </Grid.Column>
                        <Grid.Column flex='1'>
                          <Section
                            width='155'
                            paddingTop='3'
                            spacingBottom='1'
                          >
                            <Text
                              regular12
                              singleLine
                              ellipsis
                              color={VARIABLES.COLORS.BLACK}
                              title={notificationTitle}
                            >
                              {notificationTitle}
                            </Text>
                          </Section>
                          <Section width='155'>
                            <Text
                              regular9
                              singleLine
                              color={VARIABLES.COLORS.BLACK}
                              title={notificationDescription}
                            >
                              {notificationDescription}
                            </Text>
                          </Section>
                        </Grid.Column>
                      </Grid>
                    </Grid.Column>
                    <Grid.Column>
                      <Section
                        height='100'
                        heightUnit='%'
                        align='center'
                      >
                        <Image ui='iconArrowNotification' />
                      </Section>
                    </Grid.Column>
                  </Grid>
                </ShapeContainerBase>
              )
            case 'badgeCount':
              return (
                <ShapeContainerBase
                  {...this.props}
                  className={classes}
                  height='32'
                  paddingHorizontal='15'
                  justify='center'
                  borderWidth='2'
                  borderStyle='solid'
                  borderColor={VARIABLES.COLORS.PRIMARY_2}
                  borderRadius='5'
                >
                  <Grid gutter='10'>
                    <Grid.Column>
                      <Text
                        medium12
                        color={VARIABLES.COLORS.BLACK}
                        top='1'
                      >
                        {badgeCountTitle}
                      </Text>
                    </Grid.Column>
                    <Grid.Column>
                      <ShapeContainerBase
                        justify='center'
                        align='center'
                        width='50'
                        height='20'
                        bgColor={VARIABLES.COLORS.PRIMARY_2}
                        borderRadius='10'
                      >
                        <Text
                          medium12
                          color={VARIABLES.COLORS.WHITE}
                          top='-1'
                        >
                          {badgeCountNumber}
                        </Text>
                      </ShapeContainerBase>
                    </Grid.Column>
                  </Grid>
                </ShapeContainerBase>
              )
            case 'warrantyList':
              return (
                <ShapeContainerBase
                  {...this.props}
                  className={classes}
                  fluid
                  height='40'
                  paddingHorizontal='15'
                  justify='center'
                  borderWidth='1'
                  borderStyle='solid'
                  borderColor={VARIABLES.COLORS.PRIMARY_2}
                  borderRadius='5'
                >
                  <Grid
                    gutter='30'
                    justify='space-between'
                  >
                    <Grid.Column
                      // grid='8'
                      flex='1'
                    >
                      <Text
                        bold12
                        singleLine
                        ellipsis
                        color={VARIABLES.COLORS.BLACK}
                        top='1'
                      >
                        {warrantyName}
                      </Text>
                    </Grid.Column>
                    <Grid.Column flex='none'>
                      <Grid gutter='25' align='center'>
                        {warrantyTermsAndConditions &&
                          <Grid.Column>
                            <Grid gutter='10' align='center'>
                              <Grid.Column>
                                <Field
                                  name={warrantyTermsAndConditionsName}
                                  value={warrantyTermsAndConditionsValue}
                                  checked={checkedWarrantyTermsAndConditions}
                                  ui='checkboxTick'
                                  onChange={onChangeWarrantyTermsAndConditions}
                                />
                              </Grid.Column>
                              <Grid.Column>
                                <Text
                                  regular12
                                  underline
                                  color={VARIABLES.COLORS.PRIMARY_2}
                                  onClick={onClickWarrantyTermsAndConditions}
                                >
                                  {getLanguage('field.buttonAcceptTermsAndConditions', '')}
                                </Text>
                              </Grid.Column>
                            </Grid>
                          </Grid.Column>
                        }
                        <Grid.Column>
                          <Grid gutter='15'>
                            <Grid.Column>
                              <Button
                                lightBlue
                                ui='frontIconSmall'
                                name={getLanguage('field.buttonPreview', '')}
                                iconWidth='7'
                                iconHeight='9'
                                iconSrc={ICONS['ic-document-fill.svg']}
                                onClick={onClickWarrantyPreview}
                              />
                            </Grid.Column>
                            {
                              onClickWarrantyDownload &&
                              <Grid.Column>
                                <Button
                                  blue
                                  ui='frontIconSmall'
                                  name={getLanguage('field.buttonDownload', '')}
                                  iconWidth='7'
                                  iconHeight='9'
                                  iconSrc={ICONS['ic-download.svg']}
                                  onClick={onClickWarrantyDownload}
                                />
                              </Grid.Column>
                            }
                          </Grid>
                        </Grid.Column>
                      </Grid>
                    </Grid.Column>
                  </Grid>
                </ShapeContainerBase>
              )
            case 'statisticPanel':
              return (
                <ShapeContainerBase
                  fluid
                  height='100'
                  heightUnit='%'
                  paddingTop='12'
                  paddingBottom='15'
                  paddingHorizontal='15'
                  bgColor={VARIABLES.COLORS.WHITE}
                  borderRadius='3'
                >
                  <Section
                    spacingBottom='10'
                  >
                    <Grid justify='space-between'>
                      <Grid.Column>
                        <Section paddingTop='3'>
                          <Text
                            regular16
                            color={VARIABLES.COLORS.BLACK}
                          >
                            {statisticName}
                          </Text>
                        </Section>
                      </Grid.Column>
                      <Grid.Column>
                        <Image
                          ui='iconBarChart'
                          src={statisticImageChartSrc}
                        />
                      </Grid.Column>
                    </Grid>
                  </Section>
                  <Section>
                    <ShapeContainerBase
                      fluid
                      height='100'
                      heightUnit='%'
                      paddingVertical='8'
                      paddingHorizontal='15'
                      bgColor={VARIABLES.COLORS.BLUE_5}
                      borderRadius='3'
                    >
                      {
                        (statisticLeftLabel || statisticLeftInfo || statisticCenterLabel || statisticCenterInfo || statisticRightLabel || statisticRightInfo) ?
                          <Grid gutter='30'>
                            {statisticLeftInfo &&
                              <Grid.Column>
                                <Section spacingBottom='2'>
                                  <Text
                                    regular14
                                    color={VARIABLES.COLORS.GRAY_25}
                                  >
                                    {statisticLeftLabel}
                                  </Text>
                                </Section>
                                <Section>
                                  <Text
                                    bold20
                                    color={VARIABLES.COLORS.BLACK}
                                  >
                                    {statisticLeftInfo}
                                  </Text>
                                </Section>
                              </Grid.Column>
                            }
                            {statisticCenterInfo &&
                              <Grid.Column>
                                <Section spacingBottom='2'>
                                  <Text
                                    regular14
                                    color={VARIABLES.COLORS.GRAY_25}
                                  >
                                    {statisticCenterLabel}
                                  </Text>
                                </Section>
                                <Section>
                                  <Text
                                    bold20
                                    color={VARIABLES.COLORS.BLACK}
                                  >
                                    {statisticCenterInfo}
                                  </Text>
                                </Section>
                              </Grid.Column>
                            }
                            {statisticRightInfo &&
                              <Grid.Column>
                                <Section spacingBottom='2'>
                                  <Text
                                    regular14
                                    color={VARIABLES.COLORS.GRAY_25}
                                  >
                                    {statisticRightLabel}
                                  </Text>
                                </Section>
                                <Section>
                                  <Text
                                    bold20
                                    color={VARIABLES.COLORS.BLACK}
                                  >
                                    {statisticRightInfo}
                                  </Text>
                                </Section>
                              </Grid.Column>
                            }
                          </Grid>
                          :
                          <Text
                            bold22
                            color={VARIABLES.COLORS.BLACK}
                          >
                            {statisticDetail}
                          </Text>
                      }
                    </ShapeContainerBase>
                  </Section>
                </ShapeContainerBase>
              )
            case 'warrantyDetailList':
              return (
                <ShapeContainerBase
                  {...this.props}
                  className='is-warranty-list'
                  paddingTop='20'
                  paddingBottom='30'
                  paddingHorizontal='45'
                  borderWidth='1'
                  borderStyle='solid'
                  borderColor={VARIABLES.COLORS.BLACK}
                  borderRadius='8'
                >
                  {children}
                </ShapeContainerBase>
              )
            case 'warrantyCheckbox':
              return (
                <ShapeContainerBase
                  {...this.props}
                  checked={checked}
                  width='14'
                  widthUnit='px'
                  height='14'
                  heightUnit='px'
                  borderWidth='1'
                  borderStyle='solid'
                  borderColor={VARIABLES.COLORS.BLACK}
                  justify='center'
                  align='center'
                >
                  {checked &&
                    <Image
                      widthRatio='8'
                      widthRatioUnit='px'
                      heightRatio='6'
                      heightRatioUnit='px'
                      src={ICONS['ic-check-language.svg']}
                    />
                  }
                </ShapeContainerBase>
              )
            case 'tableButton':
              return (
                <ShapeContainerBase
                  {...this.props}
                  className={classes}
                  height='20'
                  paddingHorizontal='10'
                  justify='center'
                  borderRadius='10'
                >
                  <Text
                    regular10
                    color={VARIABLES.COLORS.WHITE}
                  >
                    {children}
                  </Text>
                </ShapeContainerBase>
              )
            case 'lineVertical':
              return (
                <ShapeContainerBase
                  {...this.props}
                  className={classes}
                  borderLeftWidth={1}
                  borderLeftStyle='solid'
                  borderLeftColor={VARIABLES.COLORS.GRAY_12}
                  height={32}
                />
              )
            default:
              return (
                <ShapeContainerBase
                  {...this.props}
                  className={classes}
                />
              )
          }
        })()}
      </React.Fragment>
    )
  }

  static defaultProps = {
    // * If you set new default props, you should create propsType for correct styleguide document.
  }

  static propTypes = {
    // TYPE > node, string, number, bool, array, object, symbol, func
    /**
    * props_name_oneType
    */
    // props_name_onetype: PropTypes.string,

    /**
    * props_name_oneMoreType
    */
    // props_name_oneMoreType: PropTypes.oneOfType([
    //   PropTypes.node,
    //   PropTypes.string
    // ])
  }
}
