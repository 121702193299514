import React from 'react';
import {
  connect,
} from 'react-redux';
import {
  Section,
  Grid,
  Modal,
  Text,
  Button,
  Image,
  Table,
  ShapeContainer,
} from './../../../components';
import {
  VARIABLES,
} from './../../../themes';
import { MODAL_TYPE } from '../../../helpers/constants/main';
import { MAIN_REDUX_STATE_KEY } from '../../../actions/mainAction';
import { getLanguage } from '../../../helpers/functions/language';
import _ from 'lodash';
import { displayWithComma } from '../../../helpers/functions/display';
import { getUserAuthRedux } from '../../../helpers/functions/auth';

export class ModalConfirmContainer extends React.Component {

  render() {
    const {
      isOpenModal,
      onClickCancel,
      onClickConfirm,
      message,
      messageSecondary,
      messageWarning,
      messageWarningSecondary,
      messageAlign,
      messageColor,
      imageUi,
      title,
      buttonCancelName,
      buttonSubmitName,
      isDisableOverlayClick,
      rewardLists,
      orderList,
      data,
      modalWidth,
      isHideIcon,
      headerPaddingVertical,
      headerPaddingHorizontal,
      descriptionPaddingVertical,
      descriptionPaddingHorizontal,
      descriptionMinHeight,
      footerPaddingTop,
      footerPaddingBottom,
      footerPaddingHorizontal,
      buttonSubmitRed,
      footerButtonWidth,
      messageHighlight,
      isHideConfirmButton,
    } = this.props;
    const userAuth = getUserAuthRedux();
    console.log('rewardLists', rewardLists)
    return (
      <Modal
        open={isOpenModal}
        scroll
        enableOverlayClose={!isDisableOverlayClick}
        width={modalWidth ? modalWidth : VARIABLES.MODAL.WIDTHS.W_530}
        overlayColor={VARIABLES.COLORS.OVERLAY_BLACK_1}
        spacing={VARIABLES.MODAL.CONTAINER.C_1.SPACING}
        transition={VARIABLES.TRANSITIONS.DEFAULT}
        transformStart={VARIABLES.MODAL.CONTAINER.C_1.TRANSFORM_START}
        transformEnd={VARIABLES.MODAL.CONTAINER.C_1.TRANSFORM_END}
        onClickClose={onClickCancel}
      >
        <Modal.Section
          paddingVertical={headerPaddingVertical ? headerPaddingVertical : '25'}
          paddingHorizontal={headerPaddingHorizontal ? headerPaddingHorizontal : '35'}
          bgColor={VARIABLES.COLORS.WHITE}
          borderBottomWidth='1'
          borderBottomStyle='solid'
          borderBottomColor={VARIABLES.COLORS.GRAY_8}
          borderTopRightRadius={VARIABLES.MODAL.HEADER.H_1.BORDER_TOP_RIGHT_RADIUS}
          borderTopLeftRadius={VARIABLES.MODAL.HEADER.H_1.BORDER_TOP_LEFT_RADIUS}
        >
          <Grid gutter='10'>
            {isHideIcon ? (
              <React.Fragment></React.Fragment>
            ) : (
              <Grid.Column>
                <Image ui={imageUi} />
              </Grid.Column>
            )
            }
            <Grid.Column>
              <Text
                regular16
                singleLine
                color={VARIABLES.COLORS.BLACK}
                top='1'
              >
                {title ? title : getLanguage('modal.confirm.title')}
              </Text>
            </Grid.Column>
          </Grid>
        </Modal.Section>
        <Modal.Section
          paddingVertical={descriptionPaddingVertical ? descriptionPaddingVertical : '40'}
          paddingHorizontal={descriptionPaddingHorizontal ? descriptionPaddingHorizontal : '35'}
          bgColor={VARIABLES.COLORS.WHITE}
        >
          <Section calcMinHeight={descriptionMinHeight ? descriptionMinHeight : '70px'} textAlign={messageAlign}>
            <Section paddingLeft={rewardLists ? '5' : '0'}>
              {
                messageHighlight ? (
                  <Text
                    regular14
                    color={messageColor ? messageColor : VARIABLES.COLORS.GRAY_19}
                  >
                    {message}
                    <Text
                      bold14
                      color={messageColor ? messageColor : VARIABLES.COLORS.GRAY_19}
                      left='3'
                    >
                      {messageHighlight}
                    </Text>
                    <Text
                      regular14
                      left='4'
                      color={messageColor ? messageColor : VARIABLES.COLORS.GRAY_19}
                    >
                      {messageSecondary}
                    </Text>
                  </Text>
                ) : (
                  <Text
                    regular14
                    color={messageColor ? messageColor : VARIABLES.COLORS.GRAY_19}
                  >
                    {message}
                    {messageSecondary &&
                      <React.Fragment>
                        <br />
                        {messageSecondary}
                      </React.Fragment>
                    }
                  </Text>
                )
              }

              {orderList &&
                <Section paddingVertical='10'>
                  <Grid justify='center'>
                    <Grid.Column>
                      {orderList.map((e, i) => {
                        return (
                          <Grid gutter='3' key={i}>
                            <Grid.Column flex='none'>
                              <Section width='20'>
                                <Text regular14 color={VARIABLES.COLORS.GRAY_19}>{i + 1}</Text>
                              </Section>
                            </Grid.Column>
                            <Grid.Column flex='1'>
                              <Section textAlign='left'>
                                <Text regular14 color={VARIABLES.COLORS.GRAY_19}>
                                  {e.productGroupName} use <Text bold14 color={VARIABLES.COLORS.GRAY_19}>"{e.isSelectedSpecifyCompanyName ? e.specifyCompanyName : userAuth.company}"</Text>
                                </Text>
                              </Section>
                            </Grid.Column>
                          </Grid>
                        )
                      })}
                    </Grid.Column>
                  </Grid>
                </Section>
              }
              {messageWarning &&
                <Section>
                  <Text
                    regular14
                    color={VARIABLES.COLORS.RED_4}
                  >
                    {messageWarning}
                    {messageWarningSecondary &&
                      <React.Fragment>
                        <br />
                        {messageWarningSecondary}
                      </React.Fragment>
                    }
                  </Text>
                </Section>
              }
            </Section>
            {
              rewardLists &&
              <React.Fragment>
                <Section paddingTop='10'>
                  <Table striped>
                    <Table.Section>
                      <Table.Row>
                        <Table.HeadColumn noSort>
                          {getLanguage('redemptionReward.table.header.rewards', '')}
                        </Table.HeadColumn>
                        <Table.HeadColumn
                          noSort
                          alignRight
                          maxWidth={VARIABLES.TABLE.INFO_WIDTHS.UNIT}
                        >
                          {getLanguage('redemptionReward.table.header.unit', '')}
                        </Table.HeadColumn>
                        <Table.HeadColumn
                          noSort
                          alignRight
                          maxWidth={VARIABLES.TABLE.INFO_WIDTHS.TRUEBLUE_POINTS_NO_SORT}
                        >
                          {getLanguage('redemptionReward.table.header.trueBluePoints', '')}
                        </Table.HeadColumn>
                      </Table.Row>
                    </Table.Section>
                    <Table.Section>
                      {rewardLists.map((e, i) => {
                        return (
                          <Table.Row
                            key={i}
                          >
                            <Table.BodyColumn
                              info
                              title={e.name}
                            >
                              {e.name}
                            </Table.BodyColumn>
                            <Table.BodyColumn
                              info
                              alignRight
                              maxWidth={VARIABLES.TABLE.INFO_WIDTHS.UNIT}
                            >
                              {displayWithComma(e.unitValue)}
                            </Table.BodyColumn>
                            <Table.BodyColumn
                              info
                              alignRight
                              maxWidth={VARIABLES.TABLE.INFO_WIDTHS.TRUEBLUE_POINTS_NO_SORT}
                            >
                              {displayWithComma(e.totalPoints)}
                            </Table.BodyColumn>
                          </Table.Row>
                        )
                      })}
                    </Table.Section>
                  </Table>
                </Section>
                <ShapeContainer
                  fluid
                  paddingTop='5'
                  paddingBottom='6'
                  paddingHorizontal='5'
                  spacingTop='15'
                  spacingBottom='5'
                  borderWidth='1'
                  borderStyle='solid'
                  borderColor={VARIABLES.COLORS.BLUE_14}
                  borderRadius='3'
                >
                  <Grid justify='space-between'>
                    <Grid.Column>
                      <Grid gutter='15'>
                        <Grid.Column>
                          <Text
                            bold12
                            color={VARIABLES.COLORS.PRIMARY_2}
                          >
                            {getLanguage('redemptionReward.table.footer.yourTotalPoints', '')}
                          </Text>
                        </Grid.Column>
                        <Grid.Column>
                          <Text
                            bold12
                            color={VARIABLES.COLORS.PRIMARY_2}
                          >
                            {displayWithComma(data.remainPoints)}
                          </Text>
                        </Grid.Column>
                        <Grid.Column>
                          <Text
                            bold12
                            color={VARIABLES.COLORS.PRIMARY_2}
                          >
                            {getLanguage('redemptionReward.table.footer.point', '')}
                          </Text>
                        </Grid.Column>
                      </Grid>
                    </Grid.Column>
                    <Grid.Column>
                      <Grid gutter='15'>
                        <Grid.Column>
                          <Text
                            bold12
                            color={VARIABLES.COLORS.PRIMARY_2}
                          >
                            {getLanguage('redemptionReward.table.footer.usedPoints', '')}
                          </Text>
                        </Grid.Column>
                        <Grid.Column>
                          <Text
                            bold12
                            color={VARIABLES.COLORS.PRIMARY_2}
                          >
                            {displayWithComma((rewardLists || []).map(e => e.totalPoints).sum())}
                          </Text>
                        </Grid.Column>
                      </Grid>
                    </Grid.Column>
                  </Grid>
                </ShapeContainer>
              </React.Fragment>
            }
          </Section>
        </Modal.Section>
        <Modal.Section
          paddingTop={footerPaddingTop ? footerPaddingTop : '15'}
          paddingBottom={footerPaddingBottom ? footerPaddingBottom : '20'}
          paddingHorizontal={footerPaddingHorizontal ? footerPaddingHorizontal : '35'}
          borderTopWidth={VARIABLES.MODAL.FOOTER.F_1.BORDER_TOP_WIDTH}
          borderTopStyle={VARIABLES.MODAL.FOOTER.F_1.BORDER_TOP_STYLE}
          borderTopColor={VARIABLES.COLORS.GRAY_8}
          borderBottomRightRadius={VARIABLES.MODAL.FOOTER.F_1.BORDER_BOTTOM_RIGHT_RADIUS}
          borderBottomLeftRadius={VARIABLES.MODAL.FOOTER.F_1.BORDER_BOTTOM_LEFT_RADIUS}
        >
          <Grid
            gutter='15'
            justify='flex-end'
          >
            <Grid.Column>
              <Button
                gray
                width={footerButtonWidth ? footerButtonWidth : VARIABLES.BUTTON.WIDTHS.W_135}
                name={buttonCancelName ? buttonCancelName : getLanguage('field.buttonCancel')}
                onClick={onClickCancel}
              />
            </Grid.Column>
            <Grid.Column>
              {!isHideConfirmButton && (
                <Button
                  blue={!buttonSubmitRed}
                  red={buttonSubmitRed}
                  width={footerButtonWidth ? footerButtonWidth : VARIABLES.BUTTON.WIDTHS.W_135}
                  name={buttonSubmitName ? buttonSubmitName : getLanguage('field.buttonSubmit')}
                  onClick={onClickConfirm}
                />
              )}
            </Grid.Column>
          </Grid>
        </Modal.Section>
      </Modal >
    )
  }

  static defaultProps = {
    imageUi: 'iconInfo',
  }
}

const mapStateToProps = (state) => {
  const mainRedux = state.mainRedux;
  const modalData = mainRedux[MAIN_REDUX_STATE_KEY.MODAL_DATA];
  return {
    isOpenModal: modalData.openModalType === MODAL_TYPE.CONFIRM,
    data: modalData.data,
    title: modalData.title,
    message: modalData.message,
    buttonCancelName: modalData.buttonCancelName,
    buttonSubmitName: modalData.buttonSubmitName,
    isDisableOverlayClick: modalData.isDisableOverlayClick,
    onClickCancel: modalData.onClickCancel,
    onClickConfirm: modalData.onClickConfirm,
    rewardLists: modalData.rewardLists,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
  }
}

export const ModalConfirmContainerConnected = connect(
  mapStateToProps,
  mapDispatchToProps
)(ModalConfirmContainer)