import React from 'react'
import ClassNames from 'classnames'
import PropTypes from 'prop-types'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import {
  DatePickerControlWrapper
} from './styled'
// import {
//   default as VARIABLES
// } from './../../../themes/styles/bases/variables'

/**
 * DatePickerControl description:
 * - DatePickerControl
 */

export class DatePickerControl extends React.PureComponent {

  render() {
    const {
      className,
      label,
      filter,
      fluid,
      required,
      bottom,
      top,
      small,
      disabled,
      line,
      labelRequired,
      placeholderText,
    } = this.props

    // props for css classes
    const classes = ClassNames(
      'date-picker-control',
      { 'is-filter': filter },
      { 'is-fluid': fluid },
      { 'is-required': required },
      { 'is-bottom': bottom },
      { 'is-top': top },
      { 'is-small': small },
      { 'is-disabled': disabled },
      { 'is-line': line },
      { 'is-label-required': labelRequired },
      className
    )
    const CustomInput = React.forwardRef(({ value, onChange, onClick }, ref) => (
      <input readOnly
        ref={ref}
        value={value}
        onChange={e => onChange(e.target.value)}
        placeholder={placeholderText}
        onClick={onClick}
      />
    ));

    return (
      <DatePickerControlWrapper
        className={classes}
      >
        {label &&
          <div className='date-picker-control-label'>
            {label}
          </div>
        }
        <DatePicker
          {...this.props}
          customInput={<CustomInput />}
        />
      </DatePickerControlWrapper>
    )
  }

  static defaultProps = {
    // * If you set new default props, you should create propsType for correct styleguide document.
    showMonthDropdown: true,
    showYearDropdown: true,
    dropdownMode: 'select'
  }

  static propTypes = { // TYPE > node, string, number, bool, array, object, symbol, func
    /**
    * Additional classes
    */
    className: PropTypes.string
  }
}
