import React from 'react';
import {
  connect,
} from 'react-redux';
import {
  Accordion,
  AccordionItem,
  AccordionItemTitle,
  AccordionItemBody
} from 'react-accessible-accordion'
import {
  AccordionControl
} from './../../components'
import { SIDE_MENU_LIST, ROUTE_PATH } from '../../helpers/constants/route';
import { redirect } from '../../helpers/functions/route';
import { ROUTE_REDUX_STATE_KEY } from '../../actions/routeAction';
import { getLanguage } from '../../helpers/functions/language';
import { isRolePermission, isCountryPermission } from '../../helpers/functions/permission';
import { PERMISSION, PERMISSION_CUSTOMER_TYPE, PERMISSION_COUNTRY_TYPE } from '../../helpers/constants/permission';
import _ from 'lodash';
import { getUserAuthRedux } from '../../helpers/functions/auth';
import { ENUM } from '../../helpers/constants/enum';

const hideMenuEmail = [
  'test01@stg.com',
  'test02@stg.com',
  'test03@stg.com',
  'test04@stg.com',
  'test05@stg.com',
  'test06@stg.com',
  'test07@stg.com',
]

class SideMenuContainer extends React.Component {

  constructor(props) {
    super(props);
    const userAuth = getUserAuthRedux();
    const childSalesTransactionList = [];

    if (
      userAuth.addressType === ENUM.COUNTRY_TYPE.MALAYSIA &&
      userAuth.customerRoleType === ENUM.CUSTOMER_ROLE_TYPE.MALAYSIA.HARDWARE_SHOP
    ) {

    }
    else {
      childSalesTransactionList.push({
        name: getLanguage('sideMenu.salesOrders', ''),
        link: ROUTE_PATH.SALES_ORDERS.LINK,
      });
    }

    if (isRolePermission(PERMISSION.SIDE_MENU.SALES_TRANSACTION.SALE_HISTORY)) {
      childSalesTransactionList.push({
        name: getLanguage('sideMenu.salesHistory', ''),
        link: ROUTE_PATH.SALES_HISTORY.LINK,
      })
    }
    let sideMenuList = [];

    sideMenuList.push({
      icon: 'iconHome',
      name: getLanguage('sideMenu.home', ''),
      link: ROUTE_PATH.HOME.LINK,
      childList: []
    });

    if (userAuth.addressType === ENUM.COUNTRY_TYPE.THAI) {
      sideMenuList.push({
        icon: 'iconYearWarranty',
        name: getLanguage('sideMenu.yearWarranty', ''),
        link: ROUTE_PATH.YEAR_WARRANTY.LINK,
        childList: []
      });
    }

    sideMenuList.push({
      icon: 'iconCustomers',
      name: getLanguage('sideMenu.customers', ''),
      link: ROUTE_PATH.CUSTOMERS.LINK,
      childList: []
    });

    if (
      userAuth.addressType === ENUM.COUNTRY_TYPE.MALAYSIA ||
      userAuth.addressType === ENUM.COUNTRY_TYPE.THAI
    ) {

    }
    else {
      sideMenuList.push({
        icon: 'iconOrderInquiry',
        name: getLanguage('sideMenu.orderInquiry', ''),
        link: ROUTE_PATH.ORDER_INQUIRY.LINK,
        childList: []
      });
    }
    // if (userAuth.addressType === ENUM.COUNTRY_TYPE.INDONESIA
    //   && userAuth.customerRoleType === ENUM.CUSTOMER_ROLE_TYPE.INDONESIA.DISTRIBUTOR) {
    // }
    // else {
    sideMenuList.push({
      icon: 'iconSalesTransaction',
      name: getLanguage('sideMenu.salesTransaction', ''),
      childList: childSalesTransactionList
    });
    // }
    if (isCountryPermission(PERMISSION_COUNTRY_TYPE.SIDE_MENU.UPLOAD_TRANSACTION)) {
      if (
        userAuth.addressType === ENUM.COUNTRY_TYPE.MALAYSIA &&
        userAuth.isMalayUploadTransaction
      ) {
        sideMenuList.push({
          icon: 'iconUploadTransaction',
          name: getLanguage('sideMenu.uploadTransaction', ''),
          childList: [{
            name: getLanguage('sideMenu.uploadTransactionManagement', ''),
            link: ROUTE_PATH.UPLOAD_TRANSACTION.LINK,
          }, {
            name: getLanguage('sideMenu.uploadTransactionHistory', ''),
            link: ROUTE_PATH.UPLOAD_TRANSACTION_HISTORY.LINK,
          }]
        });
      }
      if (userAuth.addressType === ENUM.COUNTRY_TYPE.VIETNAM) {
        sideMenuList.push({
          icon: 'iconUploadTransaction',
          name: getLanguage('sideMenu.uploadTransaction', ''),
          childList: [{
            name: getLanguage('sideMenu.uploadTransactionManagement', ''),
            link: ROUTE_PATH.UPLOAD_TRANSACTION.LINK,
          }, {
            name: getLanguage('sideMenu.uploadTransactionHistory', ''),
            link: ROUTE_PATH.UPLOAD_TRANSACTION_HISTORY.LINK,
          }]
        });
      }
    }

    if (userAuth.addressType === ENUM.COUNTRY_TYPE.THAI) {
      sideMenuList.push({
        icon: 'iconWarranty',
        name: getLanguage('sideMenu.warrantyManagement', ''),
        childList: [{
          name: getLanguage('sideMenu.warrantyTemplate', ''),
          link: ROUTE_PATH.WARRANTY_TEMPLATE.LINK,
        }, {
          name: getLanguage('sideMenu.warranty', ''),
          link: ROUTE_PATH.WARRANTY.LINK,
        }, {
          name: getLanguage('sideMenu.documentary', ''),
          link: ROUTE_PATH.DOCUMENTARY.LINK,
        }]
      });
    }
    else {
      sideMenuList.push({
        icon: 'iconWarranty',
        name: getLanguage('sideMenu.warranty', ''),
        link: ROUTE_PATH.WARRANTY.LINK,
        childList: []
      });
    }

    if (userAuth.addressType === ENUM.COUNTRY_TYPE.THAI) {
      sideMenuList.push({
        icon: 'iconPreSalesTransaction',
        name: getLanguage('sideMenu.projectDocument', ''),
        link: ROUTE_PATH.PROJECT_DOCUMENT.LINK,
        childList: [],
      });
    }

    if (userAuth.addressType === ENUM.COUNTRY_TYPE.MALAYSIA) {

    }
    else {
      if (
        userAuth.addressType === ENUM.COUNTRY_TYPE.THAI &&
        userAuth.segmentation__c !== 'BSAD'
      ) {

      }
      else {
        sideMenuList.push({
          icon: 'iconRewards',
          name: getLanguage('sideMenu.rewards', ''),
          childList: [{
            name: getLanguage('sideMenu.redemption', ''),
            link: ROUTE_PATH.REDEMPTION.LINK,
          }, {
            name: getLanguage('sideMenu.rewardRedemptionHistory', ''),
            link: ROUTE_PATH.REWARD_REDEMPTION_HISTORY.LINK,
          }, {
            name: getLanguage('sideMenu.rewardStock', ''),
            link: ROUTE_PATH.REWARD_STOCK.LINK,
          }, {
            name: getLanguage('sideMenu.trueBluePointsDetail', ''),
            link: ROUTE_PATH.POINTS_DETAIL.LINK,
          }]
        });
      }
    }

    // if (userAuth.addressType === ENUM.COUNTRY_TYPE.THAI) {
    //   let projectReferenceChildList = [{
    //     name: getLanguage('sideMenu.projectReference', ''),
    //     link: ROUTE_PATH.PROJECT_REFERENCE.LINK,
    //   }]

    //   sideMenuList.push({
    //     icon: 'iconProjectReference',
    //     name: getLanguage('sideMenu.projectReference', ''),
    //     childList: projectReferenceChildList
    //   });
    // }

    let inventoryChildList = [{
      name: getLanguage('sideMenu.inventoryManagement', ''),
      link: ROUTE_PATH.INVENTORY.LINK,
    }];
    if (userAuth.addressType === ENUM.COUNTRY_TYPE.INDONESIA) {
      // if (isCustomerRoleTypeIndoPermission(PERMISSION_CUSTOMER_ROLE_TYPE.INDONESIA.SIDE_MENU.INVENTORY.PROFILE_MAPPING)) {
      if (
        userAuth.customerRoleType !== ENUM.CUSTOMER_ROLE_TYPE.INDONESIA.DISTRIBUTOR &&
        userAuth.customerRoleType !== ENUM.CUSTOMER_ROLE_TYPE.INDONESIA.TOKO && (
          userAuth.customerRoleType === ENUM.CUSTOMER_ROLE_TYPE.INDONESIA.AD &&
          userAuth.isIndoAdSpecial
        )
      ) {
        inventoryChildList.push({
          name: getLanguage('sideMenu.profileMapping', ''),
          link: ROUTE_PATH.PROFILE_MAPPING.LINK,
        });
      }
      // if (userAuth.customerRoleType && isCustomerRoleTypeIndoPermission(PERMISSION_CUSTOMER_ROLE_TYPE.INDONESIA.SIDE_MENU.INVENTORY.PROFILE_MAPPING_HISTORY)) {
      if (
        userAuth.customerRoleType === ENUM.CUSTOMER_ROLE_TYPE.INDONESIA.AD
      ) {
        inventoryChildList.push({
          name: getLanguage('sideMenu.profileMappingHistory', ''),
          link: ROUTE_PATH.PROFILE_MAPPING_HISTORY.LINK,
        });
        inventoryChildList.push({
          name: getLanguage('sideMenu.coilTransfer', ''),
          link: ROUTE_PATH.COIL_TRANSFER.LINK,
        }, {
          name: getLanguage('sideMenu.coilTransferHistory', ''),
          link: ROUTE_PATH.COIL_TRANSFER_HISTORY.LINK,
        });
      }
      if (
        userAuth.customerRoleType === ENUM.CUSTOMER_ROLE_TYPE.INDONESIA.DISTRIBUTOR
      ) {
        inventoryChildList.push({
          name: getLanguage('sideMenu.profileTransfer', ''),
          link: ROUTE_PATH.PROFILE_TRANSFER.LINK,
        });
        inventoryChildList.push({
          name: getLanguage('sideMenu.profileTransferHistory', ''),
          link: ROUTE_PATH.PROFILE_TRANSFER_HISTORY.LINK,
        });
      }
      // if (isCustomerRoleTypeIndoPermission(PERMISSION_CUSTOMER_ROLE_TYPE.INDONESIA.SIDE_MENU.INVENTORY.TRANSFER)) {
      // if (
      //   userAuth.customerRoleType !== ENUM.CUSTOMER_ROLE_TYPE.INDONESIA.AD &&
      //   userAuth.customerRoleType !== ENUM.CUSTOMER_ROLE_TYPE.INDONESIA.TOKO
      // ) {
      //   inventoryChildList.push({
      //     name: getLanguage('sideMenu.profileTransfer', ''),
      //     link: ROUTE_PATH.COIL_TRANSFER.LINK,
      //   });
      // }
      // if (
      //   userAuth.customerRoleType !== ENUM.CUSTOMER_ROLE_TYPE.INDONESIA.AD &&
      //   userAuth.customerRoleType !== ENUM.CUSTOMER_ROLE_TYPE.INDONESIA.TOKO
      // ) {
      //   inventoryChildList.push({
      //     name: getLanguage('sideMenu.profileTransferHistory', ''),
      //     link: ROUTE_PATH.COIL_TRANSFER_HISTORY.LINK,
      //   });
      // }
    }
    //RL-33
    // else if (
    //   userAuth.addressType === ENUM.COUNTRY_TYPE.THAI &&
    //   userAuth.customerRoleType === ENUM.CUSTOMER_ROLE_TYPE.THAI.DISTRIBUTOR
    // ) {
    // }
    else {
      if (userAuth.addressType === ENUM.COUNTRY_TYPE.MALAYSIA) {

      }
      else {
        inventoryChildList.push({
          name: getLanguage('sideMenu.coilTransfer', ''),
          link: ROUTE_PATH.COIL_TRANSFER.LINK,
        }, {
          name: getLanguage('sideMenu.barcodeScan', ''),
          link: ROUTE_PATH.COIL_TRANSFER_BARCODE_SCANNER.LINK,
        }, {
          name: getLanguage('sideMenu.coilApproval', ''),
          link: ROUTE_PATH.COIL_APPROVAL.LINK,
        }, {
          name: getLanguage('sideMenu.coilTransferHistory', ''),
          link: ROUTE_PATH.COIL_TRANSFER_HISTORY.LINK,
        });
      }
    }
    sideMenuList.push({
      icon: 'iconInventory',
      name: getLanguage('sideMenu.inventory', ''),
      childList: inventoryChildList
    });

    if (isRolePermission(PERMISSION.SIDE_MENU.REPORT.ALL)) {
      sideMenuList.push({
        icon: 'iconReport',
        name: getLanguage('sideMenu.report', ''),
        link: ROUTE_PATH.REPORT.LINK,
        childList: []
      })
    }

    if (userAuth.addressType === ENUM.COUNTRY_TYPE.THAI) {
      sideMenuList.push({
        icon: 'iconNotificationManagement',
        name: getLanguage('sideMenu.notificationManagement'),
        link: ROUTE_PATH.NOTIFICATION_MANAGEMENT.LINK,
        childList: []
      })
    }

    if (isRolePermission(PERMISSION.SIDE_MENU.SETTINGS.ALL)) {
      const menuSettingChild = [
        {
          name: getLanguage('sideMenu.adInformation', ''),
          link: ROUTE_PATH.AD_INFORMATION.LINK,
        },
        // {
        //   name: ROUTE_PATH.SALE_PERSON_INFORMATION.TEXT,
        //   link: ROUTE_PATH.SALE_PERSON_INFORMATION.LINK,
        // },
        {
          name: getLanguage('sideMenu.termAndCondition', ''),
          link: ROUTE_PATH.TERMS_AND_CONDITION.LINK,
        }, {
          name: getLanguage('sideMenu.contactUs', ''),
          link: ROUTE_PATH.CONTACT_US.LINK,
        },
        {
          name: getLanguage('sideMenu.help', ''),
          link: ROUTE_PATH.HELP.LINK,
        }
      ];

      if (
        userAuth.addressType === ENUM.COUNTRY_TYPE.THAI ||
        userAuth.addressType === ENUM.COUNTRY_TYPE.LAOS
      ) {
        menuSettingChild.push({
          name: getLanguage('sideMenu.salesPerson', ''),
          link: ROUTE_PATH.SETTING_SALES_PERSON.LINK,
        })
      }

      sideMenuList.push({
        icon: 'iconSettings',
        name: getLanguage('sideMenu.settings', ''),
        childList: menuSettingChild,
      });
    }


    if (hideMenuEmail.indexOf(userAuth.email) >= 0) {
      sideMenuList = [{
        icon: 'iconHome',
        name: getLanguage('sideMenu.home', ''),
        link: ROUTE_PATH.HOME.LINK,
        childList: []
      }];
    }

    this.state = {
      sideMenuList,
    }
  }

  render() {
    const {
      sideMenuActive,
    } = this.props;
    const {
      sideMenuList,
    } = this.state;
    return (
      <AccordionControl menuSidebar>
        <Accordion accordion>
          {
            sideMenuList.map((e, i) => {
              return (
                <AccordionItem
                  uuid={e.name}
                  key={i}
                  expanded={sideMenuActive.mainActive === e.name && !sideMenuActive.isNoExpanded}
                >
                  <AccordionItemTitle>
                    <AccordionControl.Button
                      active={sideMenuActive.mainActive === e.name}
                      collapsible={e.childList.length > 0}
                      icon={e.icon}
                      name={e.name}
                      onClick={() => {
                        if (e.link) {
                          redirect(e.link);
                        }
                      }}
                    />
                  </AccordionItemTitle>
                  {
                    e.childList.length > 0 &&
                    <AccordionItemBody>
                      {
                        e.childList.map((ec, ic) => {
                          return (
                            <AccordionControl.ButtonSubMenu
                              key={ic}
                              active={sideMenuActive.childActive === ec.name}
                              name={ec.name}
                              onClick={() => {
                                redirect(ec.link);
                              }}
                            />
                          )
                        })
                      }
                    </AccordionItemBody>
                  }
                </AccordionItem>
              )
            })
          }
        </Accordion>
      </AccordionControl>
    )
  }
}

const mapStateToProps = (state) => {
  const routeRedux = state.routeRedux;
  return {
    sideMenuActive: routeRedux[ROUTE_REDUX_STATE_KEY.SIDE_MENU_ACTIVE],
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
  }
}

export const SideMenuContainerConnected = connect(
  mapStateToProps,
  mapDispatchToProps
)(SideMenuContainer)