import React from 'react';
// import {
//   connect,
// } from 'react-redux';
import {
  Section,
  Grid,
  Modal,
  Text,
  Button,
  Table,
  Image,
} from '../../../components'
import {
  VARIABLES,
  ICONS
} from '../../../themes'
import { getLanguage } from '../../../helpers/functions/language';
import { displayUrlPath } from '../../../helpers/functions/display';
// import { MODAL_TYPE } from '../../../helpers/constants/main'
// import { MAIN_REDUX_STATE_KEY } from '../../../actions/mainAction'
import _ from 'lodash';

export class ModalSaleTransactionDetailContainer extends React.Component {

  constructor(props) {
    super(props);
    const defaultSortIcon = {
      salesTransId: true,
      displayCreatedDate: true,
    };
    const data = props.data;
    data.salesTransList = _.orderBy(data.salesTransList, ['salesTransId'], ['desc']);

    this.state = {
      data,

      currentSort: 'salesTransId',
      defaultSortIcon,
      sortIcon: _.cloneDeep(defaultSortIcon),
    }
  }

  modalSaleTransactionDetail = {
    onClickSort: (target) => {
      const {
        sortIcon,
        data,
        currentSort,
      } = this.state;

      Object.keys(sortIcon).forEach(key => {
        if (key === target) {
          sortIcon[key] = currentSort === target ? !sortIcon[key] : true;
        }
        else {
          sortIcon[key] = true;
        }
      })

      const sortType = sortIcon[target] ? 'desc' : 'asc';

      data.salesTransList = _.orderBy(data.salesTransList, [target], [sortType]);
      this.setState({
        sortIcon,
        currentSort: target,
        data,
      })
    },
  }

  render() {
    const {
      isOpenModal,
      isSecondModal,
      onClickClose,
      title,
    } = this.props;
    const {
      data,
      sortIcon,
    } = this.state;

    return (
      <Modal
        open={isOpenModal}
        scroll
        second={isSecondModal}
        // enableOverlayClose={false}
        width={VARIABLES.MODAL.WIDTHS.W_640}
        overlayColor={VARIABLES.COLORS.OVERLAY_BLACK_1}
        spacing={VARIABLES.MODAL.CONTAINER.C_1.SPACING}
        transition={VARIABLES.TRANSITIONS.DEFAULT}
        transformStart={VARIABLES.MODAL.CONTAINER.C_1.TRANSFORM_START}
        transformEnd={VARIABLES.MODAL.CONTAINER.C_1.TRANSFORM_END}
        onClickClose={onClickClose}
      >
        <Modal.Section
          paddingVertical='25.5'
          paddingHorizontal='45'
          bgColor={VARIABLES.COLORS.WHITE}
          borderBottomWidth='1'
          borderBottomStyle='solid'
          borderBottomColor={VARIABLES.COLORS.GRAY_8}
          borderTopRightRadius={VARIABLES.MODAL.HEADER.H_1.BORDER_TOP_RIGHT_RADIUS}
          borderTopLeftRadius={VARIABLES.MODAL.HEADER.H_1.BORDER_TOP_LEFT_RADIUS}
        >
          <Text
            regular16
            singleLine
            color={VARIABLES.COLORS.BLACK}
            top='2'
          >
            {title}
          </Text>
        </Modal.Section>
        <Modal.Section
          paddingVertical='45'
          paddingHorizontal='45'
          bgColor={VARIABLES.COLORS.WHITE}
        >
          <Section spacingBottom="45" >
            <Section spacingBottom="15">
              <Grid>
                <Grid.Column
                  gridLaptop='12'
                  gridDesktop='8'
                >
                  <Grid gutter="3" gutterVertical="15">
                    <Grid.Column
                      gridTablet='12'
                      gridLaptop='6'
                    >
                      <Text
                        bold14
                        color={VARIABLES.COLORS.BLACK}
                      >
                        {getLanguage('projectDocument.modal.modalSaleTransaction.label.preTransactionID', '')}
                      </Text>
                    </Grid.Column>
                    <Grid.Column
                      gridTablet='12'
                      gridLaptop='6'
                    >
                      <Text
                        regular14
                        color={VARIABLES.COLORS.BLACK}
                      >
                        {data.preSalesTransCode}
                      </Text>
                    </Grid.Column>
                  </Grid>
                </Grid.Column>
              </Grid>
            </Section>
            <Section spacingBottom="15">
              <Grid>
                <Grid.Column
                  gridLaptop='12'
                  gridDesktop='8'
                >
                  <Grid gutter="3" gutterVertical="15">
                    <Grid.Column
                      gridTablet='12'
                      gridLaptop='6'
                    >
                      <Text
                        bold14
                        color={VARIABLES.COLORS.BLACK}
                      >
                        {getLanguage('projectDocument.modal.modalSaleTransaction.label.projectName', '')}
                      </Text>
                    </Grid.Column>
                    <Grid.Column
                      gridTablet='12'
                      gridLaptop='6'
                    >
                      <Text
                        regular14
                        color={VARIABLES.COLORS.BLACK}
                      >
                        {data.preSalesTransProjectName}
                      </Text>
                    </Grid.Column>
                  </Grid>
                </Grid.Column>
              </Grid>
            </Section>
            <Section spacingBottom="15">
              <Grid>
                <Grid.Column
                  gridLaptop='12'
                  gridDesktop='8'
                >
                  <Grid gutter="3" gutterVertical="15">
                    <Grid.Column
                      gridTablet='12'
                      gridLaptop='6'
                    >
                      <Text
                        bold14
                        color={VARIABLES.COLORS.BLACK}
                      >
                        {getLanguage('projectDocument.modal.modalSaleTransaction.label.customerName', '')}
                      </Text>
                    </Grid.Column>
                    <Grid.Column
                      gridTablet='12'
                      gridLaptop='6'
                    >
                      <Text
                        regular14
                        color={VARIABLES.COLORS.BLACK}
                      >
                        {data.customerName}
                      </Text>
                    </Grid.Column>
                  </Grid>
                </Grid.Column>
              </Grid>
            </Section>
          </Section>
          <Section>
            <Table striped>
              <Table.Section>
                <Table.Row>
                  <Table.HeadColumn
                    minWidth={140}
                    sortAscending={sortIcon.salesTransId}
                    onClickSort={() => {
                      this.modalSaleTransactionDetail.onClickSort('salesTransId')
                    }}
                  >
                    {getLanguage('projectDocument.modal.modalSaleTransaction.table.header.saleTransactionID', '')}
                  </Table.HeadColumn>
                  <Table.HeadColumn
                    maxWidth={115}
                    minWidth={115}
                    sortAscending={sortIcon.displayCreatedDate}
                    onClickSort={() => {
                      this.modalSaleTransactionDetail.onClickSort('displayCreatedDate')
                    }}
                  >
                    {getLanguage('projectDocument.modal.modalSaleTransaction.table.header.createdDate', '')}
                  </Table.HeadColumn>
                </Table.Row>
              </Table.Section>
              <Section scrollOverlay height='240'>
                <Table.Section>
                  {
                    (data.salesTransList || []).map((e, i) => {
                      return (
                        <Table.Row
                          key={i}
                        >
                          <Table.BodyColumn
                            info
                            // maxWidth={140}
                            minWidth={140}
                            title={e.salesTransId}
                          >
                            {e.salesTransId}
                          </Table.BodyColumn>
                          <Table.BodyColumn
                            info
                            maxWidth={115}
                            minWidth={115}
                          >
                            {e.displayCreatedDate}
                          </Table.BodyColumn>
                        </Table.Row>
                      )
                    })
                  }
                </Table.Section>
              </Section>
            </Table>
          </Section>

        </Modal.Section>
        <Modal.Section
          paddingHorizontal='45'
          paddingVertical='30'
          borderTopWidth={VARIABLES.MODAL.FOOTER.F_1.BORDER_TOP_WIDTH}
          borderTopStyle={VARIABLES.MODAL.FOOTER.F_1.BORDER_TOP_STYLE}
          borderTopColor={VARIABLES.COLORS.GRAY_8}
          borderBottomRightRadius={VARIABLES.MODAL.FOOTER.F_1.BORDER_BOTTOM_RIGHT_RADIUS}
          borderBottomLeftRadius={VARIABLES.MODAL.FOOTER.F_1.BORDER_BOTTOM_LEFT_RADIUS}
        >
          <Grid
            gutter='15'
            justify='flex-end'
          >
            <Grid.Column>
              <Button
                gray
                width={VARIABLES.BUTTON.WIDTHS.W_105}
                name={getLanguage('field.buttonClose')}
                onClick={onClickClose}
              />
            </Grid.Column>
          </Grid>
        </Modal.Section>
      </Modal>
    )
  }
}
