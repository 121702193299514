import {
  combineReducers,
} from 'redux'
import { routeReducer } from './routeReducer'
import { mainReducer } from './mainReducer'
import { dataReducer } from './dataReducer'

export const rootReducer = () => {
  return combineReducers({
    routeRedux: routeReducer,
    mainRedux: mainReducer,
    dataRedux: dataReducer,
  })
}