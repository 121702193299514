import React from 'react';
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker
} from 'react-google-maps';
import {
  GOOGLE_MAP_URL_PATH,
} from '../../config/google'

class MyGoogleMap extends React.Component {
  static defaultProps = {
    latitude: 100.5348922,
    longitude: 13.7627997,
  }

  googleMap = {
    getCustomMarker: () => {
      const {
        iconMarker,
      } = this.props
      const customMarker = {};
      if (iconMarker) {
        customMarker.url = iconMarker;
      }
      console.log('customMarker', customMarker);
      return customMarker
    }
  }

  render() {
    const {
      latitude,
      longitude,
      isShowMarker,
      iconMarker,
    } = this.props
    const isCustomMarker = !!iconMarker;
    return (
      <GoogleMap
        defaultZoom={16}
        center={{
          lat: parseFloat(latitude),
          lng: parseFloat(longitude),
        }}
      >
        {
          isShowMarker &&
          <Marker
            icon={isCustomMarker ? this.googleMap.getCustomMarker() : undefined}
            position={{
              lat: parseFloat(latitude),
              lng: parseFloat(longitude),
            }}
          />
        }
      </GoogleMap>
    )
  }
}

export const GoogleMapContainer = withScriptjs(withGoogleMap(MyGoogleMap));

GoogleMapContainer.defaultProps = {
  isShowMarker: true,
  googleMapURL: GOOGLE_MAP_URL_PATH,
  loadingElement: < div style={{ height: '100%', width: '100%' }} />,
  containerElement: < div style={{ height: '100%', width: '100%' }} />,
  mapElement: < div style={{ height: '100%', width: '100%' }} />,
}