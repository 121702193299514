import React from 'react';
import {
  connect,
} from 'react-redux';
import {
  ShapeContainer,
  Section,
  TableAction,
  Grid,
  Text,
  Button,
  PaginationControl,
  Table,
  Image,
} from './../../components';
import {
  VARIABLES,
  ICONS
} from './../../themes';
import { getSaleTransList, getClearSaleTransParams } from '../../helpers/services/saleTrans';
import { onOpenModal, toast } from '../../helpers/functions/main';
import { saleTransController } from '../../apiService/apiController/saleTransService';
import { isValidResponse } from '../../helpers/functions/validation';
import { MODAL_TYPE } from '../../helpers/constants/main';
import { getSortAscending } from '../../helpers/functions/data';
import _ from 'lodash';
import { getLanguage } from '../../helpers/functions/language';
import { ModalCreateMultipleWarrantyContainer } from '../ModalContainer/ModalCreateMultipleWarrantyContainer/ModalCreateMultipleWarrantyContainer';
import { getUserAuthRedux } from '../../helpers/functions/auth';

class SaleTransactionHistoryMalayListContainer extends React.Component {

  constructor(props) {
    super(props);
    const userAuth = getUserAuthRedux();
    this.state = {
      userAuth,
      isOpenMultiWarrantyModal: false,
      data: {},
    }
  }

  historySaleTransList = this.props.historySaleTransList

  historySaleTransMalay = {
    onOpenMultiWarrantyModal: (data) => {
      this.setState({
        isOpenMultiWarrantyModal: true,
        data
      })
    },
    onCloseMultiWarrantyModal: () => {
      this.setState({
        isOpenMultiWarrantyModal: false,
      })
    }
  }

  render() {
    const {
      dataList,
      dataParams,
    } = this.props;
    const {
      isOpenMultiWarrantyModal,
      userAuth,
      data,
    } = this.state;
    return (
      <React.Fragment>
        <Table
          striped
          action
        >
          <Table.Section>
            <Table.Row>
              <Table.HeadColumn
                maxWidth={VARIABLES.TABLE.INFO_WIDTHS.DATE}
                minWidth={VARIABLES.TABLE.INFO_WIDTHS.DATE}
                sortAscending={getSortAscending(dataParams, 'create_at')}
                onClickSort={() => {
                  this.historySaleTransList.onClickSort('create_at desc');
                }}
              >
                {getLanguage('container.saleTransactionHistory.table.header.date', '')}
              </Table.HeadColumn>
              <Table.HeadColumn
                maxWidth={VARIABLES.TABLE.INFO_WIDTHS.SALES_TRANSACTION_ID}
                minWidth={VARIABLES.TABLE.INFO_WIDTHS.SALES_TRANSACTION_ID}
                sortAscending={getSortAscending(dataParams, 'order_no')}
                onClickSort={() => {
                  this.historySaleTransList.onClickSort('order_no desc');
                }}
              >
                {getLanguage('container.saleTransactionHistory.table.header.stID', '')}
              </Table.HeadColumn>
              <Table.HeadColumn
                minWidth={VARIABLES.TABLE.INFO_WIDTHS.COIL_NAME_FULL}
                sortAscending={getSortAscending(dataParams, 'coil_name')}
                onClickSort={() => {
                  this.historySaleTransList.onClickSort('coil_name desc');
                }}
              >
                {getLanguage('container.saleTransactionHistory.table.header.coilName', '')}
              </Table.HeadColumn>
              <Table.HeadColumn
                maxWidth={VARIABLES.TABLE.INFO_WIDTHS.COIL_ID_INFO}
                minWidth={VARIABLES.TABLE.INFO_WIDTHS.COIL_ID_INFO}
                sortAscending={getSortAscending(dataParams, 'coil_id')}
                onClickSort={() => {
                  this.historySaleTransList.onClickSort('coil_id desc');
                }}
              >
                {getLanguage('container.saleTransactionHistory.table.header.coilID', '')}
              </Table.HeadColumn>
              <Table.HeadColumn
                maxWidth={VARIABLES.TABLE.INFO_WIDTHS.CUSTOMER_NAME_FULL}
                minWidth={VARIABLES.TABLE.INFO_WIDTHS.CUSTOMER_NAME_FULL}
                sortAscending={getSortAscending(dataParams, 'customer_name')}
                onClickSort={() => {
                  this.historySaleTransList.onClickSort('customer_name desc');
                }}
                title={getLanguage('container.saleTransactionHistory.table.header.customerName', '')}
              >
                {getLanguage('container.saleTransactionHistory.table.header.customerName', '')}
              </Table.HeadColumn>
              <Table.HeadColumn
                maxWidth={VARIABLES.TABLE.INFO_WIDTHS.PHONE_GLOBAL_LONG}
                minWidth={VARIABLES.TABLE.INFO_WIDTHS.PHONE_GLOBAL_LONG}
                sortAscending={getSortAscending(dataParams, 'phone_no')}
                onClickSort={() => {
                  this.historySaleTransList.onClickSort('phone_no desc');
                }}
              >
                {getLanguage('container.saleTransactionHistory.table.header.phoneNo', '')}
              </Table.HeadColumn>
              <Table.HeadColumn
                noSort
                alignRight
                maxWidth={VARIABLES.TABLE.INFO_WIDTHS.INVOICE_LINE}
                minWidth={VARIABLES.TABLE.INFO_WIDTHS.INVOICE_LINE}
              // sortAscending={getSortAscending(dataParams, '')}
              // onClickSort={() => {
              //   this.historySaleTransList.onClickSort('');
              // }}
              >
                {getLanguage('container.saleTransactionHistory.table.header.invoiceNumber', '')}
              </Table.HeadColumn>
              <Table.HeadColumn
                noSort
                alignRight
                maxWidth={VARIABLES.TABLE.INFO_WIDTHS.INVOICE_LINE_ITEM}
                minWidth={VARIABLES.TABLE.INFO_WIDTHS.INVOICE_LINE_ITEM}
              // sortAscending={getSortAscending(dataParams, '')}
              // onClickSort={() => {
              //   this.historySaleTransList.onClickSort('');
              // }}
              >
                {getLanguage('container.saleTransactionHistory.table.header.invoiceLineItem', '')}
              </Table.HeadColumn>
              <Table.HeadColumn
                alignRight
                maxWidth={VARIABLES.TABLE.INFO_WIDTHS.ORDER_M}
                minWidth={VARIABLES.TABLE.INFO_WIDTHS.ORDER_M}
                sortAscending={getSortAscending(dataParams, 'order_m')}
                onClickSort={() => {
                  this.historySaleTransList.onClickSort('order_m desc');
                }}
              >
                {getLanguage('container.saleTransactionHistory.table.header.order', '')}
              </Table.HeadColumn>
              <Table.HeadColumn
                alignRight
                maxWidth={VARIABLES.TABLE.INFO_WIDTHS.REMAINING_LENGTH}
                minWidth={VARIABLES.TABLE.INFO_WIDTHS.REMAINING_LENGTH}
                sortAscending={() => { }}
                onClickSort={() => { }}
              >
                {getLanguage('container.saleTransactionHistory.table.header.remainingLength', '')}
              </Table.HeadColumn>
              <Table.HeadColumn
                alignRight
                maxWidth={VARIABLES.TABLE.INFO_WIDTHS.PURCHASE_VALUE_ABBREVIATION}
                minWidth={VARIABLES.TABLE.INFO_WIDTHS.PURCHASE_VALUE_ABBREVIATION}
                sortAscending={getSortAscending(dataParams, 'points_revenue')}
                onClickSort={() => {
                  this.historySaleTransList.onClickSort('points_revenue desc');
                }}
                title={getLanguage('container.saleTransactionHistory.table.header.revenue', '')}
              >
                {getLanguage('container.saleTransactionHistory.table.header.revenue', '')}
              </Table.HeadColumn>
              <Table.HeadColumn
                alignRight
                maxWidth={VARIABLES.TABLE.INFO_WIDTHS.TB_POINTS_ABBREVIATION}
                minWidth={VARIABLES.TABLE.INFO_WIDTHS.TB_POINTS_ABBREVIATION}
                sortAscending={getSortAscending(dataParams, 'points')}
                onClickSort={() => {
                  this.historySaleTransList.onClickSort('points desc');
                }}
                title={getLanguage('container.saleTransactionHistory.table.header.tbPoints', '')}
              >
                {getLanguage('container.saleTransactionHistory.table.header.tbPointsAbbreviation', '')}
              </Table.HeadColumn>
              <Table.HeadColumn
                maxWidth={VARIABLES.TABLE.INFO_WIDTHS.SALESPERSON_FULL_NAME}
                minWidth={VARIABLES.TABLE.INFO_WIDTHS.SALESPERSON_FULL_NAME}
                sortAscending={getSortAscending(dataParams, 'sales_person_name')}
                onClickSort={() => {
                  this.historySaleTransList.onClickSort('sales_person_name desc');
                }}
              >
                {getLanguage('container.saleTransactionHistory.table.header.salesperson', '')}
              </Table.HeadColumn>
              {dataList.filter(e => e.isShowMultiple).length > 0 && <Table.HeadColumn
                noSort
                alignCenter
                maxWidth={VARIABLES.TABLE.INFO_WIDTHS.ACTION_PICK_QUANTITY}
                minWidth={VARIABLES.TABLE.INFO_WIDTHS.ACTION_PICK_QUANTITY}
              >
                &nbsp;
              </Table.HeadColumn>
              }
              <Table.HeadColumn
                noSort
                alignCenter
                maxWidth={VARIABLES.TABLE.INFO_WIDTHS.ACTION_DELETE_ICON}
                minWidth={VARIABLES.TABLE.INFO_WIDTHS.ACTION_DELETE_ICON}
              >
                {getLanguage('container.saleTransactionHistory.table.header.action', '')}
              </Table.HeadColumn>
            </Table.Row>
          </Table.Section>
          <Table.Section>
            {dataList.map((e, i) => {
              return (
                <Table.Row
                  key={i}
                >
                  <Table.BodyColumn
                    info
                    maxWidth={VARIABLES.TABLE.INFO_WIDTHS.DATE}
                    minWidth={VARIABLES.TABLE.INFO_WIDTHS.DATE}
                  >
                    {e.displayCreateAt}
                  </Table.BodyColumn>
                  <Table.BodyColumn
                    info
                    maxWidth={VARIABLES.TABLE.INFO_WIDTHS.SALES_TRANSACTION_ID}
                    minWidth={VARIABLES.TABLE.INFO_WIDTHS.SALES_TRANSACTION_ID}
                    title={e.name}
                  >
                    {e.name}
                  </Table.BodyColumn>
                  <Table.BodyColumn
                    info
                    minWidth={VARIABLES.TABLE.INFO_WIDTHS.COIL_NAME_FULL}
                    title={e.coilName}
                  >
                    {e.coilName}
                  </Table.BodyColumn>
                  <Table.BodyColumn
                    info
                    maxWidth={VARIABLES.TABLE.INFO_WIDTHS.COIL_ID_INFO}
                    minWidth={VARIABLES.TABLE.INFO_WIDTHS.COIL_ID_INFO}
                    title={e.coilId}
                  >
                    {e.coilId}
                  </Table.BodyColumn>
                  <Table.BodyColumn
                    info
                    maxWidth={VARIABLES.TABLE.INFO_WIDTHS.CUSTOMER_NAME_FULL}
                    minWidth={VARIABLES.TABLE.INFO_WIDTHS.CUSTOMER_NAME_FULL}
                    title={e.customerName}
                  >
                    {e.customerName}
                  </Table.BodyColumn>
                  <Table.BodyColumn
                    phone
                    maxWidth={VARIABLES.TABLE.INFO_WIDTHS.PHONE_GLOBAL_LONG}
                    minWidth={VARIABLES.TABLE.INFO_WIDTHS.PHONE_GLOBAL_LONG}
                    idPhone={e.phoneNoCountry.value}
                    srcFlag={e.phoneNoCountry.flag}
                  >
                    {e.phoneNo}
                  </Table.BodyColumn>
                  <Table.BodyColumn
                    info
                    alignRight
                    maxWidth={VARIABLES.TABLE.INFO_WIDTHS.INVOICE_LINE}
                    minWidth={VARIABLES.TABLE.INFO_WIDTHS.INVOICE_LINE}
                    title={e.invoiceNo}
                  >
                    {e.invoiceNo}
                  </Table.BodyColumn>
                  <Table.BodyColumn
                    info
                    alignRight
                    maxWidth={VARIABLES.TABLE.INFO_WIDTHS.INVOICE_LINE_ITEM}
                    minWidth={VARIABLES.TABLE.INFO_WIDTHS.INVOICE_LINE_ITEM}
                    title={e.invoiceLineItem}
                  >
                    {e.invoiceLineItem}
                  </Table.BodyColumn>
                  <Table.BodyColumn
                    info
                    alignRight
                    maxWidth={VARIABLES.TABLE.INFO_WIDTHS.ORDER_M}
                    minWidth={VARIABLES.TABLE.INFO_WIDTHS.ORDER_M}
                  >
                    {e.orderMWithCommas}
                  </Table.BodyColumn>
                  <Table.BodyColumn
                    info
                    alignRight
                    maxWidth={VARIABLES.TABLE.INFO_WIDTHS.REMAINING_LENGTH}
                    minWidth={VARIABLES.TABLE.INFO_WIDTHS.REMAINING_LENGTH}
                  >

                    {e.isShowRemainingLength ? e.multipleWarrantyRemainingLengthWithComma : '-'}
                  </Table.BodyColumn>
                  <Table.BodyColumn
                    info
                    alignRight
                    maxWidth={VARIABLES.TABLE.INFO_WIDTHS.PURCHASE_VALUE_ABBREVIATION}
                    minWidth={VARIABLES.TABLE.INFO_WIDTHS.PURCHASE_VALUE_ABBREVIATION}
                  >
                    {e.myrWithComma}
                  </Table.BodyColumn>
                  <Table.BodyColumn
                    info
                    alignRight
                    maxWidth={VARIABLES.TABLE.INFO_WIDTHS.TB_POINTS_ABBREVIATION}
                    minWidth={VARIABLES.TABLE.INFO_WIDTHS.TB_POINTS_ABBREVIATION}
                    title={e.pointsWithComma}
                  >
                    {e.pointsWithComma}
                  </Table.BodyColumn>
                  <Table.BodyColumn
                    info
                    maxWidth={VARIABLES.TABLE.INFO_WIDTHS.SALESPERSON_FULL_NAME}
                    minWidth={VARIABLES.TABLE.INFO_WIDTHS.SALESPERSON_FULL_NAME}
                    title={e.salesPersonName}
                  >
                    {e.salesPersonName}
                  </Table.BodyColumn>
                  {e.isShowMultiple &&
                    <Table.BodyColumn
                      action button
                      alignCenter
                      maxWidth={VARIABLES.TABLE.INFO_WIDTHS.ACTION_PICK_QUANTITY}
                      minWidth={VARIABLES.TABLE.INFO_WIDTHS.ACTION_PICK_QUANTITY}
                    >
                      <ShapeContainer
                        ui='tableButton'
                        bgColor={VARIABLES.COLORS.PRIMARY_2}
                        onClick={() => {
                          this.historySaleTransMalay.onOpenMultiWarrantyModal(e)
                        }}
                      >
                        {getLanguage('container.saleTransactionHistory.table.header.pickQuantity', '')}
                      </ShapeContainer>
                    </Table.BodyColumn>
                  }
                  <Table.BodyColumn
                    action
                    alignCenter
                    maxWidth={VARIABLES.TABLE.INFO_WIDTHS.ACTION_DELETE_ICON}
                    minWidth={VARIABLES.TABLE.INFO_WIDTHS.ACTION_DELETE_ICON}
                  >
                    {
                      e.isShowDelete &&
                      <Button
                        red
                        ui='iconSmall'
                        iconWidth='8'
                        iconHeight='10'
                        iconSrc={ICONS['ic-delete.svg']}
                        onClick={() => {
                          this.historySaleTransList.onClickDelete(e);
                        }}
                      />
                    }
                  </Table.BodyColumn>
                </Table.Row>
              )
            })
            }
          </Table.Section>
        </Table>
        {
          isOpenMultiWarrantyModal &&
          <ModalCreateMultipleWarrantyContainer
            isOpenModal
            data={data}
            onClickClose={this.historySaleTransMalay.onCloseMultiWarrantyModal}
          />
        }
      </React.Fragment >)
  }
}

const mapStateToProps = (state) => {
  const dataRedux = state.dataRedux;
  return {
    dataList: dataRedux.saleTransList,
    dataParams: dataRedux.saleTransParams,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
  }
}

export const SaleTransactionHistoryMalayListContainerConnected = connect(
  mapStateToProps,
  mapDispatchToProps
)(SaleTransactionHistoryMalayListContainer)