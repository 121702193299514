import apiService from '../apiService'

const apiPath = '/integration'

export const integrationController = (configService = {}) => {
  const service = apiService(configService)
  return {
    checkT1: (params) => {
      return service.post(`${apiPath}/check/t1`, params)
    },
    createT1: (params) => {
      return service.post(`${apiPath}/create/t1`, params)
    },
    createTransactionLog: (params) => {
      return service.post(`${apiPath}/create/transaction_log`, params)
    },
  }
}
