import domtoimage from 'dom-to-image';
import html2pdf from 'html2pdf.js';
import jsPDF from 'jspdf';
import _ from 'lodash';
import moment from 'moment';
import Papa from 'papaparse';
import QrCode from 'qrcode.react';
import ReactDOM from "react-dom";
import * as XLSX from 'xlsx';
import { googleGeocode } from '../../config/google';
import { CONTENTS } from '../../themes';
import { DATA } from '../constants/data';
import { ENUM } from '../constants/enum';
import { LOCAL_STORAGE } from '../constants/localStorage';
import { ROUTE_PATH } from '../constants/route';
import { firebaseUploadFile } from '../firebases/firebaseStorage';
import { getUserAuthRedux } from './auth';
import { handleDisplayImage } from './display';
import { getLocalStorage } from './localStorage';
import { getWarrantyDocumentaryQRCodeValue, getWarrantyProjectTemplateData } from './warranty';

export const captureHtml = (dom, type = 'file', option = {}) => {
  return new Promise((resolve, reject) => {
    if (dom) {
      if (type === 'file') {
        domtoimage.toBlob(dom, option).then((blob) => {
          resolve(blob)
        });
      }
      else if (type === 'png') {
        domtoimage.toPng(dom, option).then((dataUrl) => {
          resolve(dataUrl)
        })
      }
      else {
        domtoimage.toJpeg(dom, option).then((dataUrl) => {
          resolve(dataUrl)
        })
      }
    }
    else {
      resolve();
    }
  });
}

export const downloadCaptureHtml = async (dom) => {
  const fileName = new Date().getTime() + '.png';
  const blob = await captureHtml(dom);
  const link = document.createElement('a');
  link.download = fileName;
  link.href = URL.createObjectURL(blob);
  link.click();
}

// export const getHtml2PdfFile = async (dom) => {
//   const docA4 = new jsPDF('p', 'mm', 'a4');
//   const pageWidth = docA4.internal.pageSize.getWidth();
//   const pageHeight = docA4.internal.pageSize.getHeight();
//   const doc = new jsPDF('p', 'mm');
//   const dataBase64 = await captureHtml(dom, 'jpeg', { quality: 0.75 });
//   let imgHeight = dom.offsetHeight * pageWidth / dom.offsetWidth;
//   let heightLeft = imgHeight;
//   let position = 0;
//   doc.addImage(dataBase64, 'JPEG', -4, position, pageWidth + 6, imgHeight);
//   heightLeft -= pageHeight;
//   while (heightLeft >= 0) {
//     position = heightLeft - imgHeight;
//     doc.addPage();
//     doc.addImage(dataBase64, 'JPEG', -4, position, pageWidth + 6, imgHeight);
//     heightLeft -= pageHeight;
//   }
//   return doc.output('blob');
// }
export const getHtml2PdfFile = async (dom, data = {}, dataDetail = {}, warrantyDetail, option = {}) => {
  const config = data.projectCountry === ENUM.COUNTRY_TYPE.VIETNAM ? { margin: [0, 0] } : { margin: [6, 0] };
  const language = option.language ? option.language : getLocalStorage(LOCAL_STORAGE.LANGUAGE_RBPTRUEBLUE);
  const userAuth = getUserAuthRedux();
  if (data.isCMSTemplate) {
    const generate = html2pdf().set({
      margin: [0, 0],
      html2canvas: { scale: 2.2, },
    }).from(dom)
    // if (data.isGenerateResidential) {
    //   return generate.outputPdf('blob');
    // }
    // else {
    const isLanguageEn = language === DATA.LANGUAGE.ENGLISH;
    return generate.toPdf().get('pdf').then(async (pdf) => {
      let dataList = _.cloneDeep(data.isGenerateResidential ? (warrantyDetail?.resDetailList || []) : (warrantyDetail?.nonResDetailList || []));

      // CR: 1491
      if ((warrantyDetail?.wrtSegmentDetailSelectedList || []).length) {
        dataList = [...dataList, ...warrantyDetail.wrtSegmentDetailSelectedList.map(e => {
          if (option.isPreviewTemplate) {
            e.qrCodeValue = (dataList[0] || {}).qrCodeValue || ''
          }
          return e;
        })]
      }

      let qrCodeBase64 = '';
      if (dataDetail.wrtNo && dataDetail.wrtNo !== '-') {
        qrCodeBase64 = await getWarrantyQRCode(dataDetail, {
          isCMSTemplate: true,
          wrtSelGrp: data.wrtSelGrp,
          wrtSelTemplateGrp: dataDetail.wrtSelTemplateGrp,
        });
      }

      for (let i = 1; i <= dataList.length; i++) {
        pdf.setPage(i);
        const item = dataList[i - 1];
        const itemData = isLanguageEn ? {
          wrtTempDtFooterImage: item.wrtTempDtFooterEnImage,
        } : {
          wrtTempDtFooterImage: item.wrtTempDtFooterThImage,
        }

        if (itemData.wrtTempDtFooterImage?.pathRef) {
          pdf.addImage(handleDisplayImage(itemData.wrtTempDtFooterImage), 'JPEG',
            0, pdf.internal.pageSize.getHeight() - 24, // Position X, Y
            210, 24, // Width, Height (Image size in PDF >>> 27% from original / 210 is full image width)
          );

          // !!warranty-public-qr-code
          const warrantyCheckQr = isLanguageEn ? CONTENTS['footer-created-verify-qr-landscape-en.png'] : CONTENTS['footer-created-verify-qr-landscape-th.png']
          pdf.addImage(await getDataUrl(warrantyCheckQr), 'JPEG', pdf.internal.pageSize.getWidth() - 80, pdf.internal.pageSize.getHeight() - 24, 48, 24)


          pdf.setFont('helvetica', 'normal');
          pdf.setFontSize(8);
          pdf.setTextColor('#FFFFFF');
          pdf.text(pdf.internal.pageSize.getWidth() - 47, pdf.internal.pageSize.getHeight() - 10.5, `${moment().format('DD/MM/YYYY')}`)


          if (option.isPreviewTemplate) {
            qrCodeBase64 = await getWarrantyQRCodeBase64(`${item.qrCodeValue}&lang=${language}`)
          }

          if (qrCodeBase64) {
            // ==============================
            // QR Code
            // ==============================
            pdf.addImage(
              qrCodeBase64, 'JPEG',
              pdf.internal.pageSize.getWidth() - 29.75, pdf.internal.pageSize.getHeight() - 21, // Position X, Y
              18, 18, // Width, Height (Image size in PDF >>> 27% from original / 210 is full image width)
            );
          }
        }
      }
      return pdf;
    }).outputPdf('blob')
    // }
  }
  else {
    if (data.isGenerateResidential) {
      //  html2pdf().set({
      //   margin: [6, 0],
      //   html2canvas:  { scale: 2 },
      // }).from(dom).outputImg('datauri').then(e => {
      //   const link = document.createElement('a');
      //   link.download = '2.jpg';
      //   link.href = e;
      //   link.click();
      // });
      return html2pdf().set({
        margin: config.margin, // For vietnam warranty [0, 0]
        // pagebreak:{
        //   before: ['#termsAndConditions']
        // }
        html2canvas: { scale: 2.2 },
        // image: { type: 'jpe', quality: 0.6 },
      }).from(dom).outputPdf('blob');
    }
    else {
      return getHtml2PdfFileBSWM(dom, data, dataDetail);
    }
  }
}

export const getWarrantyQRCode = async (dataDetail = {}, option = {}) => {
  let userAuth = getUserAuthRedux();
  let qrCodePath = '';
  if (option.isCMSTemplate) {
    qrCodePath = getWarrantyDocumentaryQRCodeValue({
      type: DATA.PUBLIC_WARRANTY_DOCUMENTARY_TYPE.WARRANTY,
      id: dataDetail.wrtId,
      wrtSelGrp: option.wrtSelGrp,
      wrtSelTemplateGrp: option.wrtSelTemplateGrp
    });
  }
  else {
    // const prefixQRCode = 'https://trueblue.nsbluescope.com'
    const prefixQRCode = window.location.origin
    qrCodePath = `${prefixQRCode}${ROUTE_PATH.PUBLIC.WARRANTY_STATUS.LINK}/${userAuth.country}/${userAuth.id}/${dataDetail.wrtId}/${dataDetail.wrtNo}`
  }
  console.log('qrCodePath', qrCodePath);
  return getWarrantyQRCodeBase64(qrCodePath)
}

export const getWarrantyQRCodeBase64 = async (qrCodePath) => {
  const qrCodeContainer = document.createElement('div');
  qrCodeContainer.style.width = "210px";
  const qrCodeComponent = QrCode({ value: qrCodePath, size: 210 });
  ReactDOM.render(qrCodeComponent, qrCodeContainer);
  document.body.appendChild(qrCodeContainer);
  const qrCodeBase64 = await captureHtml(qrCodeContainer, 'jpeg');
  qrCodeContainer.remove();
  return qrCodeBase64;
}


// BSWM
export const getHtml2PdfFileBSWM = async (dom, data = {}, dataDetail = {}) => {
  const warrantyTemplateNameList = DATA.WARRANTY_PRODUCT_TEMPLATE_GROUP[data.projectCountry];
  const config = {
    // Default [6, 0]
    // BSWM (Top/Bottom Fit) [33, 0, 24, 0]
    margin: [40, 0, 31, 0]
  }
  //  html2pdf().set({
  //   margin: [6, 0],
  //   html2canvas:  { scale: 2 },
  // }).from(dom).outputImg('datauri').then(e => {
  //   const link = document.createElement('a');
  //   link.download = '2.jpg';
  //   link.href = e;
  //   link.click();
  // });

  return html2pdf().set({
    margin: config.margin, // For vietnam warranty [0, 0]
    // pagebreak:{
    //   before: ['#termsAndConditions']
    // }
    html2canvas: { scale: 2.2 },
    // image: { type: 'jpe', quality: 0.6 },
  }).from(dom).toPdf().get('pdf').then(async (pdf) => {
    var totalPages = pdf.internal.getNumberOfPages();
    const warrantyTemplateData = getWarrantyProjectTemplateData(data, dataDetail);
    let imageHeader = warrantyTemplateData.imageHeader;
    let imageFooter = warrantyTemplateData.imageFooter;
    let dataFooter = warrantyTemplateData.dataFooter;

    const language = getLocalStorage(LOCAL_STORAGE.LANGUAGE_RBPTRUEBLUE);

    let qrCodeBase64 = '';
    if (dataDetail.wrtNo && dataDetail.wrtNo !== '-') {
      qrCodeBase64 = await getWarrantyQRCode(dataDetail);
    }

    for (let i = 1; i <= totalPages; i++) {
      pdf.setPage(i);

      // ==============================
      // Header
      // ==============================

      pdf.addImage(
        await getDataUrl(CONTENTS[imageHeader]), 'JPEG',
        0, 0, // Position X, Y
        210, 33, // Width, Height (Image size in PDF >>> 27% from original / 210 is full image width)
      );

      if (data.projectCountry === ENUM.COUNTRY_TYPE.THAI && language === DATA.LANGUAGE.THAI) {
        // For Thailand language only in Thai warranty (pdf.text can't render thai characters)
        pdf.addImage(
          await getDataUrl(CONTENTS['warranty-sub-header-th.png']), 'JPEG',
          0, 24, // Position X, Y
          210, 9, // Width, Height (Image size in PDF >>> 27% from original / 210 is full image width)
        );
      }

      if (data.projectCountry === ENUM.COUNTRY_TYPE.THAI && language === DATA.LANGUAGE.THAI &&
        (
          dataDetail.templateForm === warrantyTemplateNameList[2]
          || dataDetail.templateForm === warrantyTemplateNameList[14]
          || dataDetail.templateForm === warrantyTemplateNameList[15]
          || dataDetail.templateForm === warrantyTemplateNameList[18]
        )
      ) {
        // For Thailand language only in Thai warranty + SUPERDYMA_TF, SUPERDYMA_PL, SUPERDYMA_CRP
        pdf.addImage(
          await getDataUrl(CONTENTS['warranty-sub-header-en.png']), 'JPEG',
          0, 24, // Position X, Y
          210, 9, // Width, Height (Image size in PDF >>> 27% from original / 210 is full image width)
        );
      }

      // ==============================
      // Footer
      // ==============================

      pdf.addImage(
        // Thailand footer image > warranty-footer-thailand
        // Thailand footer image > warranty-footer-thailand-th >>> *** for thai language ***
        // Malaysia footer image > warranty-footer-malaysia
        // Vietnam footer image > warranty-footer-vietnam >>> *** SubFooter Vietnam line 1 (Change DATA bt product name) and line 2 ***
        // Indonesia footer image > warranty-footer-indonesia
        await getDataUrl(CONTENTS[imageFooter]), 'JPEG',
        0, pdf.internal.pageSize.getHeight() - 24, // Position X, Y
        210, 24, // Width, Height (Image size in PDF >>> 27% from original / 210 is full image width)
      );

      if (data.projectCountry === ENUM.COUNTRY_TYPE.VIETNAM) {
        pdf.setFont('helvetica', 'normal');
        pdf.setFontSize(7);
        pdf.setTextColor('#FFFFFF');
        pdf.text(`${dataFooter} and BLUESCOPE are registered trademark of BlueScope Steel Limited. `, 9, pdf.internal.pageSize.getHeight() - 8);

        // ==============================
        // SubFooter Vietnam - Copyright line 2
        // ==============================

        pdf.setFont('helvetica', 'normal');
        pdf.setFontSize(6);
        pdf.setTextColor('#FFFFFF');
        pdf.text("Copyright © 2019 by NS BlueScope Vietnam. All rights reserved.", 9, pdf.internal.pageSize.getHeight() - 5);
      }


      if (dataDetail.wrtNo && dataDetail.wrtNo !== '-') {
        // ==============================
        // QR Code
        // ==============================

        pdf.addImage(
          qrCodeBase64, 'JPEG',
          pdf.internal.pageSize.getWidth() - 28, pdf.internal.pageSize.getHeight() - 21, // Position X, Y
          18, 18, // Width, Height (Image size in PDF >>> 27% from original / 210 is full image width)
        );
      }

      // ==============================
      // Warranty no.
      // ==============================
      pdf.setFont('helvetica', 'bold');
      pdf.setFontSize(8);
      pdf.setTextColor('#FFFFFF');
      if (data.projectCountry === ENUM.COUNTRY_TYPE.THAI && language === DATA.LANGUAGE.THAI) {
        // For Thailand language only in Thai warranty (pdf.text can't render thai characters)
        pdf.text(`${_.get(data, 'detailList.0.wrtNo', '')}`, 35, 29.75);
      }
      else {
        pdf.text(`Warranty No: ${_.get(data, 'detailList.0.wrtNo', '')}`, 10, 29.75);
      }

      if (dataDetail.wrtNo === '-') {
        // ==============================
        // Preview label
        // ==============================

        pdf.setFont('helvetica', 'bold');
        pdf.setFontSize(48);
        pdf.setTextColor('#F18585');
        pdf.text("PREVIEW", 65, 150);
      }
    }
    return pdf
  })
    .outputPdf('blob');
}

const getDataUrl = (imgPath) => {
  return new Promise(resolve => {
    const img = document.createElement('img');
    img.src = imgPath;
    img.onload = () => {
      var canvas = document.createElement('canvas')
      var ctx = canvas.getContext('2d')

      canvas.width = img.width
      canvas.height = img.height
      ctx.drawImage(img, 0, 0)
      // If the image is not png, the format
      // must be specified here
      resolve(canvas.toDataURL('image/jpeg', 1));
    }
  })
}

export const getHtml2PdfA4File = async (domList) => {
  const doc = new jsPDF('p', 'mm', 'a4');
  const width = doc.internal.pageSize.getWidth();
  const height = doc.internal.pageSize.getHeight();
  for (let i = 0; i < domList.length; i++) {
    const dataBase64 = await captureHtml(domList[i], 'jpeg', { quality: 0.75 });
    doc.addImage(dataBase64, 'JPEG', -4, 0, width + 6, height + 10);
    if (i < domList.length - 1) {
      doc.addPage();
    }
  }
  return doc.output('blob');
}

export const getBlobtoPath = (blob, filename = '', pathUpload = '') => {
  return new Promise(async (resolve, reject) => {
    const file = await firebaseUploadFile(pathUpload, blob, filename);
    // const file = await firebaseUpdateFile(pathUpload, blob, filename, `${FIREBASE_STORAGE}${pathUpload}/${filename}`)
    resolve(file.ref.fullPath);
  });
}

export const downloadExcel = (dataList, filename) => {
  const wb = XLSX.utils.book_new();
  dataList.forEach(e => {
    const ws = XLSX.utils.json_to_sheet(e.data);
    XLSX.utils.book_append_sheet(wb, ws, e.name);
  })
  //client force download
  XLSX.writeFile(wb, `${filename}.xlsx`);
}

export const getCsv2Json = (file, config = {}) => {
  return new Promise(resolve => {
    Papa.parse(file, {
      ...config,
      complete: (data) => {
        resolve(data);
      }
    })
  });
}

const getCountryFromGooGleGeoCode = (addrComponents) => {
  for (var i = 0; i < addrComponents.length; i++) {
    if (addrComponents[i].types[0] == "country") {
      return addrComponents[i].short_name;
    }
    if (addrComponents[i].types.length == 2) {
      if (addrComponents[i].types[0] == "political") {
        return addrComponents[i].short_name;
      }
    }
  }
  return false;
}
export const getCurrentCountryLanguage = () => {
  return new Promise(resolve => {
    navigator.geolocation.getCurrentPosition(position => {
      const latitude = position.coords.latitude;
      const longitude = position.coords.longitude;
      googleGeocode.fromLatLng(latitude, longitude).then(
        res => {
          const countryShortName = getCountryFromGooGleGeoCode(res.results[0].address_components);
          console.log('getCurrentCountryLanguage', countryShortName)
          switch (countryShortName) {
            case 'TH':
              resolve(DATA.LANGUAGE.THAI);
              break;
            case 'VN':
              resolve(DATA.LANGUAGE.VIETNAM);
              break;
            case 'ID':
              resolve(DATA.LANGUAGE.INDONESIA);
              break;
            case 'MY':
              resolve(DATA.LANGUAGE.MALAYSIA);
              break;
            case 'LA':
              resolve(DATA.LANGUAGE.LAOS);
              break;
            default:
              resolve(DATA.LANGUAGE.ENGLISH);
              break;
          }
        },
        err => {
          console.log('googleGeocode error', err);
          resolve(DATA.LANGUAGE.ENGLISH);
        }
      );
    }, err => {
      console.log('getCurrentPosition error', err);
      resolve(DATA.LANGUAGE.ENGLISH);
    })
  });
}