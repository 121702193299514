import styled, {
  css
} from 'styled-components'
import {
  default as VARIABLES
} from './../../../themes/styles/bases/variables'
import {
  default as TYPOGRAPHYS
} from './../../../themes/styles/bases/typographys'
// import {
//   default as MIXINS
// } from './../../../themes/styles/helpers/mixins'
// import {
//   // Example using:
//   // background: url(${CONTENTS['image-RewardRedemptionCard.svg']});
//   CONTENTS,
//   ICONS,
//   LOGOS,
//   SHAREDS,
//   DOCUMENTS
// } from './../../../themes'

// Wrapper
// ============================================================
export const RewardRedemptionCardWrapper = styled.div`
  /* Parent
  ------------------------------- */
  overflow: hidden;
  border: 1px solid ${VARIABLES.COLORS.GRAY_12};
  border-radius: 5px;

  /* Childrens
  ------------------------------- */
  .rrc-image {
    cursor: pointer;
    overflow: hidden;
    position: relative;
    padding-bottom: 100%;

    img {
      position: absolute;
      z-index: ${VARIABLES.Z_INDEXS.LV_1};
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .rrc-point {
    position: absolute;
    z-index: ${VARIABLES.Z_INDEXS.LV_2};
    right: 0;
    bottom: 0;
    padding: 4px 15px 1px;
    background-color: ${VARIABLES.COLORS.PRIMARY_2};
    border-radius: 13px 0 0 0;
  }

  .rrc-container {
    padding: 15px;
    border-top: 1px solid ${VARIABLES.COLORS.GRAY_12};
  }

  .rrc-name {
    margin: 0 0 10px;

    .text-base {
      display: block;
      display: -webkit-box;
      height: 42px;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .rrc-control {
    border: 2px solid ${VARIABLES.COLORS.PRIMARY_2};
    border-radius: 5px;
  }

  .rrc-control-form {
    display: flex;
    align-items: center;
    padding: 2px;
  }

  .rrc-control-label {
    display: flex;
    flex-wrap: wrap;
    flex: 1;
    padding: 0 5px;

    .text-base {
      line-height: 1;

      &:first-child {
        margin: 0 3px 0 0;
      }
    }
  }

  .rrc-control-actions {
    flex: none;
  }

  .rrc-button {
    cursor: pointer;
    transition: ${VARIABLES.TRANSITIONS.DEFAULT};
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
    background-color: ${VARIABLES.COLORS.PRIMARY_1};

    &:active {
      opacity: 0.75;
    }

    &.is-minus {
      border-radius: 3px 0 0 3px;
    }

    &.is-plus {
      border-radius: 0 3px 3px 0;
    }
  }

  .rrc-input {
    ${TYPOGRAPHYS.FIRST.BOLD.FS_12};
    width: 36px;
    height: 24px;
    border: 1px solid ${VARIABLES.COLORS.GRAY_12};
    border-radius: 0;
    color: ${VARIABLES.COLORS.PRIMARY_2};
    text-align: center;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
    }

    /* Placeholder */
    &::-moz-placeholder {
      color: ${VARIABLES.COLORS.GRAY_29};
      opacity: 1;
    }

    &:-ms-input-placeholder {
      color: ${VARIABLES.COLORS.GRAY_29};
    }

    &::-webkit-input-placeholder {
      color: ${VARIABLES.COLORS.GRAY_29};
    }
  }

  .rrc-control-partition {
    height: 2px;
    background-color: ${VARIABLES.COLORS.PRIMARY_2};
  }

  /* Modifiers
  ------------------------------- */
  &.is-disabled-minus-quantity {
    #button-quantity-minus {
      pointer-events: none;
      background-color: ${VARIABLES.COLORS.GRAY_8};
    }
  }

  &.is-disabled-minus-ondemand {
    #button-ondemand-minus {
      pointer-events: none;
      background-color: ${VARIABLES.COLORS.GRAY_8};
    }
  }

  &.is-disabled-plus-quantity {
    #button-quantity-plus {
      pointer-events: none;
      background-color: ${VARIABLES.COLORS.GRAY_8};
    }
  }

  &.is-disabled-plus-ondemand {
    #button-ondemand-plus {
      pointer-events: none;
      background-color: ${VARIABLES.COLORS.GRAY_8};
    }
  }

  &.is-single-form {
    .rrc-name {
      margin: 0 0 40px;
    }
  }

  /* Media queries
  ------------------------------- */
`
