import React from 'react';
import {
  connect,
} from 'react-redux';
import { MAIN_REDUX_STATE_KEY } from '../../actions/mainAction';
import {
  Field
} from '../../components';
import { ENUM } from '../../helpers/constants/enum';
import { setCountryDataList } from '../../helpers/functions/data';

class FieldAddressContainer extends React.Component {

  componentDidMount() {
    let {
      thai,
      malaysia,
      vietnam,
      indonesia,
      laos,
      userAuth,
    } = this.props;
    let countryType = '';
    thai = userAuth.addressType === ENUM.COUNTRY_TYPE.THAI;
    malaysia = userAuth.addressType === ENUM.COUNTRY_TYPE.MALAYSIA;
    vietnam = userAuth.addressType === ENUM.COUNTRY_TYPE.VIETNAM;
    indonesia = userAuth.addressType === ENUM.COUNTRY_TYPE.INDONESIA;
    laos = userAuth.addressType === ENUM.COUNTRY_TYPE.LAOS;
    if (thai) {
      countryType = ENUM.COUNTRY_TYPE.THAI;
    }
    else if (malaysia) {
      countryType = ENUM.COUNTRY_TYPE.MALAYSIA;
    }
    else if (vietnam) {
      countryType = ENUM.COUNTRY_TYPE.VIETNAM;
    }
    else if (laos) {
      countryType = ENUM.COUNTRY_TYPE.LAOS;
    }
    else {
      countryType = ENUM.COUNTRY_TYPE.INDONESIA;
    }
    setCountryDataList(countryType);
  }

  render() {
    let {
      thai,
      malaysia,
      vietnam,
      indonesia,
      laos,
      dataList,
      valueProvince = {},
      valueCity = {},
      valueSubdistrict = {},
      userAuth,
    } = this.props;

    thai = userAuth.addressType === ENUM.COUNTRY_TYPE.THAI;
    malaysia = userAuth.addressType === ENUM.COUNTRY_TYPE.MALAYSIA;
    vietnam = userAuth.addressType === ENUM.COUNTRY_TYPE.VIETNAM;
    indonesia = userAuth.addressType === ENUM.COUNTRY_TYPE.INDONESIA;
    laos = userAuth.addressType === ENUM.COUNTRY_TYPE.LAOS;

    let cityList = [];
    let subDistrictList = [];
    let postCodeList = [];
    if (valueProvince) {
      let province = dataList.filter(e => e.provinceCode === valueProvince.value)[0];
      if (province) {
        cityList = province.cityList;
        // TODO: laos
        if (thai) {
          if (valueCity) {
            const city = cityList.filter(e => e.cityCode === valueCity.value)[0];
            if (city) {
              subDistrictList = city.subDistrict;
              if (valueSubdistrict) {
                const subdistrict = subDistrictList.filter(e => e.name === valueSubdistrict.value)[0];
                if (subdistrict) {
                  postCodeList = subdistrict.postCodeList;
                }
              }
            }
          }
        }
      }
    }

    console.log('subDistrictList', subDistrictList);
    console.log('postCodeList', postCodeList);

    return (
      <React.Fragment>
        {thai &&
          <Field
            optionProvince={dataList}
            optionCity={cityList}
            optionSubdistrict={subDistrictList}
            optionPostalCode={postCodeList}
            {...this.props}
            ui='addressThai'
          />
        }

        {malaysia &&
          <Field
            isSearchableProvince
            isSearchableCity
            optionProvince={dataList}
            optionCity={cityList}
            {...this.props}
            ui='addressMalaysia'
          />
        }

        {vietnam &&
          <Field
            optionProvince={dataList}
            optionCity={cityList}
            {...this.props}
            ui='addressVietnam'
          />
        }

        {indonesia &&
          <Field
            optionProvince={dataList}
            optionCity={cityList}
            {...this.props}
            ui='addressIndonesia'
          />
        }

        {laos &&
          <Field
            optionProvince={dataList}
            optionCity={cityList}
            {...this.props}
            ui='addressLao'
          />
        }
      </React.Fragment>
    )
  }

  static defaultProps = {
    thai: false,
    malaysia: false,
    vietnam: false
  }
}

const mapStateToProps = (state) => {
  const dataRedux = state.dataRedux;
  const mainRedux = state.mainRedux;
  return {
    dataList: dataRedux.countryList,
    userAuth: mainRedux[MAIN_REDUX_STATE_KEY.USER_AUTH]
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
  }
}

export const FieldAddressContainerConnected = connect(
  mapStateToProps,
  mapDispatchToProps
)(FieldAddressContainer)