import React from 'react';
import {
  connect,
} from 'react-redux';
import {
  ShapeContainer,
  Section,
  Grid,
  Table,
  Text,
  Field,
} from './../../components'
import { VARIABLES, } from '../../themes';
import { handleSortData } from '../../helpers/functions/data';
import { displayWithComma } from '../../helpers/functions/display';
import { ENUM } from '../../helpers/constants/enum';
import _ from 'lodash';
import { getLanguage } from '../../helpers/functions/language';

class OrderSummaryListContainer extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      summaryPointWithComma: displayWithComma(props.summaryPoint),
      totalPointWithComma: displayWithComma(props.totalPoint),
      dataList: props.dataList,
      sortIcon: {
        lotNumber: true,
        itemDescription: true,
        orderValue: true,
        points: true,
        //toko
        profileType: true,
        fBsproductgroupname: true,
        fTotalRemaininglength: true,
        profileLength: true,
        //malay
        revenueValue: true,
      },
    }
  }

  componentDidMount() {
  }

  orderSummaryList = {
    handleClearIcon: () => {
      const {
        sortIcon,
      } = this.state;
      let newSortIcon = {};
      Object.keys(sortIcon).forEach(e => {
        newSortIcon[e] = true;
      });
      this.setState({
        sortIcon: newSortIcon
      })
    },
    handleSortIcon: (type) => {
      const {
        sortIcon,
      } = this.state;
      let newSortIcon = {};
      let oldType = type;
      Object.keys(sortIcon).forEach(e => {
        if (sortIcon[e] === false) {
          oldType = e;
        }
        newSortIcon[e] = true;
      });
      Object.keys(sortIcon).forEach(e => {
        if (e === type) {
          if (type === oldType) {
            newSortIcon[e] = !sortIcon[e];
          }
        }
      })
      this.setState({
        sortIcon: newSortIcon
      })
      return newSortIcon
    },
    onClickSort: (type) => {
      const {
        dataList,
      } = this.props;
      const newSortIcon = this.orderSummaryList.handleSortIcon(type);
      const tempDataList = handleSortData(dataList, {
        sortTarget: type,
        orderType: newSortIcon[type] ? 'desc' : 'asc'
      });
      this.setState({
        dataList: tempDataList
      })
    },
    renderTableHeader: () => {
      const {
        customerData,
        isMalay
      } = this.props;
      const {
        sortIcon,
      } = this.state
      return (
        <Table.Row>
          <Table.HeadColumn
            maxWidth={VARIABLES.TABLE.COLUMN_WIDTHS.IDS.COIL}
            minWidth={VARIABLES.TABLE.COLUMN_WIDTHS.IDS.COIL}
            sortAscending={sortIcon.lotNumber}
            onClickSort={() => {
              this.orderSummaryList.onClickSort('lotNumber')
            }}
          >
            {getLanguage('container.orderSummary.table.header.coilID', '')}
          </Table.HeadColumn>
          <Table.HeadColumn
            minWidth={VARIABLES.TABLE.COLUMN_WIDTHS.NAMES.COIL}
            sortAscending={sortIcon.itemDescription}
            onClickSort={() => {
              this.orderSummaryList.onClickSort('itemDescription');
            }}
          >
            {getLanguage('container.orderSummary.table.header.coilName', '')}
          </Table.HeadColumn>
          <Table.HeadColumn
            alignRight
            minWidth={VARIABLES.TABLE.COLUMN_WIDTHS.MEASUREMENTS.ORDER}
            sortAscending={sortIcon.orderValue}
            onClickSort={() => {
              this.orderSummaryList.onClickSort('orderValue');
            }}
          >
            {getLanguage('container.orderSummary.table.header.order', '')}
          </Table.HeadColumn>
          {/* [TODO] RL-127 >>> Waiting for backend */}
          {
            customerData.phoneCountry.country === ENUM.COUNTRY.MALAYSIA &&
            <Table.HeadColumn
              alignRight
              maxWidth={VARIABLES.TABLE.COLUMN_WIDTHS.NUMBERS.EARN_POINT}
              minWidth={VARIABLES.TABLE.COLUMN_WIDTHS.NUMBERS.EARN_POINT}
              sortAscending={sortIcon[isMalay ? 'revenueValue' : 'points']}
              onClickSort={() => {
                this.orderSummaryList.onClickSort(isMalay ? 'revenueValue' : 'points');
              }}
            >
              {isMalay ? getLanguage('container.orderSummary.table.header.revenue', '') : getLanguage('container.orderSummary.table.header.earnPoints', '')}
            </Table.HeadColumn>
          }
        </Table.Row>
      )
    },
    renderTableHeaderIndoToko: () => {
      const {
        customerData,
      } = this.props;
      const {
        sortIcon,
      } = this.state
      return (
        <Table.Row>
          <Table.HeadColumn
            maxWidth={VARIABLES.TABLE.COLUMN_WIDTHS.NAMES.PROFILE_TYPE}
            minWidth={VARIABLES.TABLE.COLUMN_WIDTHS.NAMES.PROFILE_TYPE}
            sortAscending={sortIcon.profileType}
            onClickSort={() => {
              this.orderSummaryList.onClickSort('profileType');
            }}
          >
            {getLanguage('container.orderSummary.table.header.profileType', '')}
          </Table.HeadColumn>
          <Table.HeadColumn
            minWidth={VARIABLES.TABLE.COLUMN_WIDTHS.NAMES.PRODUCT_GROUP}
            sortAscending={sortIcon.fBsproductgroupname}
            onClickSort={() => {
              this.orderSummaryList.onClickSort('fBsproductgroupname');
            }}
          >
            {getLanguage('container.orderSummary.table.header.productGroup', '')}
          </Table.HeadColumn>
          <Table.HeadColumn
            alignRight
            minWidth={VARIABLES.TABLE.COLUMN_WIDTHS.MEASUREMENTS.LENGTH_NUMBER}
            sortAscending={sortIcon.profileLength}
            onClickSort={() => {
              this.orderSummaryList.onClickSort('profileLength');
            }}
          >
            {getLanguage('container.orderSummary.table.header.length', '')}
          </Table.HeadColumn>
          <Table.HeadColumn
            alignRight
            minWidth={VARIABLES.TABLE.COLUMN_WIDTHS.MEASUREMENTS.QUANTITY_UNIT}
            sortAscending={sortIcon.orderValue}
            onClickSort={() => {
              this.orderSummaryList.onClickSort('orderValue');
            }}
          >
            {getLanguage('container.orderSummary.table.header.orderQuantity', '')}
          </Table.HeadColumn>
          {/* [TODO] RL-127 >>> Waiting for backend */}
          {
            customerData.phoneCountry.country === ENUM.COUNTRY.MALAYSIA &&
            <Table.HeadColumn
              alignRight
              maxWidth={VARIABLES.TABLE.COLUMN_WIDTHS.NUMBERS.EARN_POINT}
              minWidth={VARIABLES.TABLE.COLUMN_WIDTHS.NUMBERS.EARN_POINT}
              sortAscending={sortIcon.points}
              onClickSort={() => {
                this.orderSummaryList.onClickSort('points');
              }}
            >
              {getLanguage('container.orderSummary.table.header.earnPoints', '')}
            </Table.HeadColumn>
          }
        </Table.Row>
      )
    },
    renderTableBody: () => {
      const {
        customerData,
        isMalay,
      } = this.props;
      const {
        dataList,
      } = this.state
      return dataList.map((e, i) => {
        return (
          <Table.Row
            key={i}
          >
            <Table.BodyColumn
              info
              maxWidth={VARIABLES.TABLE.COLUMN_WIDTHS.IDS.COIL}
              minWidth={VARIABLES.TABLE.COLUMN_WIDTHS.IDS.COIL}
              title={e.lotNumber}
            >
              {e.lotNumber}
            </Table.BodyColumn>
            <Table.BodyColumn
              info
              minWidth={VARIABLES.TABLE.COLUMN_WIDTHS.NAMES.COIL}
              title={e.itemDescription}
            >
              {e.itemDescription}
            </Table.BodyColumn>
            <Table.BodyColumn
              info
              alignRight
              minWidth={VARIABLES.TABLE.COLUMN_WIDTHS.MEASUREMENTS.ORDER}
            >
              {displayWithComma(e.orderValue)}
            </Table.BodyColumn>
            {
              customerData.phoneCountry.country === ENUM.COUNTRY.MALAYSIA &&
              <Table.BodyColumn
                info
                alignRight
                maxWidth={VARIABLES.TABLE.COLUMN_WIDTHS.NUMBERS.EARN_POINT}
                minWidth={VARIABLES.TABLE.COLUMN_WIDTHS.NUMBERS.EARN_POINT}
              >
                {displayWithComma(isMalay ? e.revenueValue : e.points)}
              </Table.BodyColumn>
            }
          </Table.Row>
        )
      })
    },
    renderTableBodyIndoToko: () => {
      const {
        customerData,
      } = this.props;
      const {
        dataList,
      } = this.state
      return dataList.map((e, i) => {
        return (
          <Table.Row
            key={i}
          >
            <Table.BodyColumn
              info
              maxWidth={VARIABLES.TABLE.COLUMN_WIDTHS.NAMES.PROFILE_TYPE}
              minWidth={VARIABLES.TABLE.COLUMN_WIDTHS.NAMES.PROFILE_TYPE}
              title={e.profileType}
            >
              {e.profileType}
            </Table.BodyColumn>
            <Table.BodyColumn
              info
              minWidth={VARIABLES.TABLE.COLUMN_WIDTHS.NAMES.PRODUCT_GROUP}
              title={e.fBsproductgroupname}
            >
              {e.fBsproductgroupname}
            </Table.BodyColumn>
            <Table.BodyColumn
              info
              alignRight
              minWidth={VARIABLES.TABLE.COLUMN_WIDTHS.MEASUREMENTS.LENGTH_NUMBER}
            >
              {/* {e.fTotalRemaininglengthWithComma} */}
              {e.profileLengthWithComma}
            </Table.BodyColumn>
            <Table.BodyColumn
              info
              alignRight
              minWidth={VARIABLES.TABLE.COLUMN_WIDTHS.MEASUREMENTS.QUANTITY_UNIT}
            >
              {`${displayWithComma(e.orderValue)} ${e.profileTypeUnit}`}
            </Table.BodyColumn>
            {/* [TODO] RL-127 >>> Waiting for backend */}
            {
              customerData.phoneCountry.country === ENUM.COUNTRY.MALAYSIA &&
              <Table.BodyColumn
                info
                alignRight
                maxWidth={VARIABLES.TABLE.COLUMN_WIDTHS.NUMBERS.EARN_POINT}
                minWidth={VARIABLES.TABLE.COLUMN_WIDTHS.NUMBERS.EARN_POINT}
              >
                {displayWithComma(e.points)}
              </Table.BodyColumn>
            }
          </Table.Row>
        )
      })
    }
  }

  render() {
    const {
      customerData,
      onChangeCustomerDataInput,
      isIndoToko,
      isMalay,
    } = this.props;
    const {
      summaryPointWithComma,
      totalPointWithComma,
    } = this.state
    return (
      <React.Fragment>
        <Section spacingBottom='30'>
          <Table striped>
            <Table.Section>
              {
                isIndoToko ?
                  this.orderSummaryList.renderTableHeaderIndoToko()
                  :
                  this.orderSummaryList.renderTableHeader()
              }
            </Table.Section>
            <Table.Section>
              {
                isIndoToko ?
                  this.orderSummaryList.renderTableBodyIndoToko()
                  :
                  this.orderSummaryList.renderTableBody()
              }
            </Table.Section>
          </Table>
        </Section>
        <Section>
          {
            customerData.phoneCountry.country === ENUM.COUNTRY.MALAYSIA ?
              <React.Fragment>
                {/* [Remove] RL-450 */}
                {false &&
                  <ShapeContainer
                    fluid
                    paddingTop='10'
                    paddingBottom='6'
                    paddingHorizontal='30'
                    bgColor={VARIABLES.COLORS.BLUE_14}
                    borderRadius='5'
                  >
                    <Grid
                      gutter='15'
                      justify='flex-end'
                    >
                      <Grid.Column>
                        <Section
                          height='100'
                          heightUnit='%'
                          align='center'
                        >
                          <Text
                            bold16
                            color={VARIABLES.COLORS.PRIMARY_2}
                          >
                            {getLanguage('container.orderSummary.table.footer.revenue', '')}
                          </Text>
                        </Section>
                      </Grid.Column>
                      <Grid.Column>
                        <Field
                          ui='blockLabelVertical'
                          placeholder={getLanguage('field.rmbPlaceholder', '')}
                          width='165'
                          fieldIcon={
                            <Text
                              regular12
                              color={VARIABLES.COLORS.BLACK}
                            >
                              {getLanguage('field.rmb', '')}
                            </Text>
                          }
                          fieldIconSpacingRight='15'
                          name='pointsRevenue'
                          value={customerData.pointsRevenue}
                          onChange={onChangeCustomerDataInput}
                        />
                      </Grid.Column>
                    </Grid>
                  </ShapeContainer>
                }
              </React.Fragment>
              :
              <React.Fragment>
                {/* [TODO] RL-127 >>> Waiting for backend */}
                {false &&
                  <Grid
                    gutter='45'
                    justify='center'
                  >
                    <Grid.Column>
                      <Section paddingTop='5'>
                        <Grid gutter='30'>
                          <Grid.Column>
                            <Text
                              bold14
                              color={VARIABLES.COLORS.PRIMARY_2}
                            >
                              {getLanguage('container.orderSummary.table.footer.summaryGetPoints', '')}
                            </Text>
                          </Grid.Column>
                          <Grid.Column>
                            <Text
                              bold14
                              color={VARIABLES.COLORS.PRIMARY_2}
                            >
                              {summaryPointWithComma}
                            </Text>
                          </Grid.Column>
                          <Grid.Column>
                            <Text
                              bold14
                              color={VARIABLES.COLORS.PRIMARY_2}
                            >
                              {getLanguage('container.orderSummary.table.footer.point', '')}
                            </Text>
                          </Grid.Column>
                        </Grid>
                      </Section>
                    </Grid.Column>
                    <Grid.Column>
                      <ShapeContainer
                        width='1'
                        height='30'
                        bgColor={VARIABLES.COLORS.PRIMARY_1}
                      />
                    </Grid.Column>
                    <Grid.Column>
                      <Section paddingTop='5'>
                        <Grid gutter='30'>
                          <Grid.Column>
                            <Text
                              bold14
                              color={VARIABLES.COLORS.PRIMARY_2}
                            >
                              {getLanguage('container.orderSummary.table.footer.yourTotalPoints', '')}
                            </Text>
                          </Grid.Column>
                          <Grid.Column>
                            <Text
                              bold14
                              color={VARIABLES.COLORS.PRIMARY_2}
                            >
                              {totalPointWithComma}
                            </Text>
                          </Grid.Column>
                          <Grid.Column>
                            <Text
                              bold14
                              color={VARIABLES.COLORS.PRIMARY_2}
                            >
                              {getLanguage('container.orderSummary.table.footer.point', '')}
                            </Text>
                          </Grid.Column>
                        </Grid>
                      </Section>
                    </Grid.Column>
                  </Grid>
                }
              </React.Fragment>
          }
        </Section>
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => {
  // const dataRedux = state.dataRedux;
  // const mainRedux = state.mainRedux;
  return {
    // dataList: dataRedux.orderSummaryList,
    // activePage: dataRedux.productParams.activePage,
    // totalData: dataRedux.productParams.totalData,
    // userAuth: mainRedux[MAIN_REDUX_STATE_KEY.USER_AUTH]
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    // dataActions: bindActionCreators(dataCreators, dispatch),
  }
}

export const OrderSummaryListContainerConnected = connect(
  mapStateToProps,
  mapDispatchToProps
)(OrderSummaryListContainer)