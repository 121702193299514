import React from 'react'
import ClassNames from 'classnames'
import PropTypes from 'prop-types'
import {
  Text,
  Grid,
} from './../../custom'
import {
  LightboxWrapper
} from './styled'
import {
  VARIABLES,
  ICONS,
} from './../../../themes'

/**
 * Lightbox description:
 * - Lightbox
 */

export class Lightbox extends React.PureComponent {
  render () {
    const {
      children,
      className,
      onClickClose,
      isImage,
    } = this.props

    // props for css classes
    const classes = ClassNames(
      'lightbox',
      {'is-image': isImage},
      className
    )

    return (
      <LightboxWrapper className={classes}>
        <div className='lightbox-scroll-container'>
          <div className='lightbox-container'>
            <div className='lightbox-content'>
              {children}
            </div>
            <div
              className='lightbox-button is-close'
              onClick={onClickClose}
            >
              <Grid gutter='10' align='center' justify='center'>
                <Grid.Column><img src={ICONS['ic-close-white.svg']} alt='Icon' /></Grid.Column>
                <Grid.Column><Text regular16 color={VARIABLES.COLORS.WHITE} top='2'>Close</Text></Grid.Column>
              </Grid>
            </div>
          </div>
          <div
            className='lightbox-overlay'
            onClick={onClickClose}
          />
        </div>
      </LightboxWrapper>
    )
  }

  static defaultProps = {
  }

  static propTypes = { // TYPE > node, string, number, bool, array, object, symbol, func
    /**
    * Additional classes
    */
    className: PropTypes.string
  }
}
