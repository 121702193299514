import { displayWithPrefix, displayDate, capitalizeFirstLetter, displayDateTime } from "../helpers/functions/display";
import { ModelImage } from "./ModelImage";
import { ENUM } from "../helpers/constants/enum";

export class ModelCMSNewsAndPromotion {
  constructor(props = {}) {
    this.id = props.id;
    this.idWithPrefix = displayWithPrefix(props.id);
    this.subject = props.subject;
    this.type = props.type;
    this.displayType = capitalizeFirstLetter(props.type);
    this.content = props.content;
    this.galleryList = (props.images || []).map(e => new ModelImage({ path: e }));
    this.publicDate = props.public_date;
    this.displayPublicDate = displayDateTime(props.public_date, '/');
    this.expireDate = props.expire_date;
    this.displayExpireDate = displayDateTime(props.expire_date, '/');
    this.createAt = props.create_at;
    this.displayCreateAt = displayDate(props.create_at);
    this.status = props.status;
    this.isActive = props.status === ENUM.STATUS_ACTIVE.ACTIVE;
    this.isBanner = props.is_banner;
    this.likeCount = props.like_count;
    this.app = props.app;
    this.cover = new ModelImage({ path: props.cover });
    this.segmentation = props.segmentation;
    this.segmentCategory = {};
    const segmentCategory = JSON.parse(props.segmentation);
    if (segmentCategory !== null) {
      segmentCategory.forEach(e => {
        this.segmentCategory[e.category] = {};
        e.segments.forEach(es => {
          this.segmentCategory[e.category][es.segment] = true;
        })
      });
    }
  }
}