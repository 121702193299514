import React from 'react';
import { ENUM } from '../../helpers/constants/enum';
import { ROUTE_PATH } from '../../helpers/constants/route';
import { getUserAuthRedux } from '../../helpers/functions/auth';
import { handleSortData } from '../../helpers/functions/data';
import { getLanguage } from '../../helpers/functions/language';
import { getRouteData, redirect, redirectData, setSideMenuActive } from '../../helpers/functions/route';
import {
  Breadcrumb,
  Button,
  Field,
  Grid,
  PaginationControl,
  Section,
  ShapeContainer,
  Table,
  TableAction,
  Text,
  TitlePage
} from './../../components';
import {
  ICONS,
  VARIABLES
} from './../../themes';
import { storeGetState } from '../../stores';
import { toast } from '../../helpers/functions/main';

export class ProductDetailScreen extends React.Component {

  constructor(props) {
    super(props);
    setSideMenuActive(props);
    const routeDataList = getRouteData(props, 'dataList');
    const routeDataNoLengthList = getRouteData(props, 'dataNoLengthList');
    const isIndoToko = getRouteData(props, 'isToko');
    const userAuth = getUserAuthRedux();
    const dataList = handleSortData(routeDataList, {
      sortTarget: 'itemDescription',
    })
    this.state = {
      activePage: 1,
      dataPerPage: 10,
      dataNoLengthList: routeDataNoLengthList || [],
      dataList,
      oldDataList: dataList,
      searchValue: '',
      filterValue: '',
      sortIcon: {
        itemDescription: true,
        lotNumber: true,
        thicknessMM: true,
        bmtMM: true,
        createdDate: true,
        fAging: true,
        topPaintColor: true,
        fRemainingweight: true,
        fTotalRemaininglength: true,
        //toko
        itemTypeName: true,
        fBsproductgroupname: true,
        fTotalRemainingprofile: true,
        profileLength: true,
      },
      userAuth,
      isThai: userAuth.addressType === ENUM.COUNTRY_TYPE.THAI,
      isVietnam: userAuth.addressType === ENUM.COUNTRY_TYPE.VIETNAM,
      isIndoToko: isIndoToko || (
        userAuth.addressType === ENUM.COUNTRY_TYPE.INDONESIA &&
        userAuth.customerRoleType === ENUM.CUSTOMER_ROLE_TYPE.INDONESIA.TOKO
      ),
      isIndoDistributor: (
        userAuth.addressType === ENUM.COUNTRY_TYPE.INDONESIA &&
        userAuth.customerRoleType === ENUM.CUSTOMER_ROLE_TYPE.INDONESIA.DISTRIBUTOR
      )
    }
    console.log('ProductDetailScreen dataList', dataList)
  }

  productDetail = {
    handleClearIcon: () => {
      const {
        sortIcon,
      } = this.state;
      let newSortIcon = {};
      Object.keys(sortIcon).forEach(e => {
        newSortIcon[e] = true;
      });
      this.setState({
        sortIcon: newSortIcon
      })
    },
    handleSortIcon: (type) => {
      const {
        sortIcon,
      } = this.state;
      let newSortIcon = {};
      let oldType = type;
      Object.keys(sortIcon).forEach(e => {
        if (sortIcon[e] === false) {
          oldType = e;
        }
        newSortIcon[e] = true;
      });
      Object.keys(sortIcon).forEach(e => {
        if (e === type) {
          if (type === oldType) {
            newSortIcon[e] = !sortIcon[e];
          }
        }
      })
      this.setState({
        sortIcon: newSortIcon
      })
      return newSortIcon
    },
    onClickSort: (type) => {
      const {
        dataList,
      } = this.state;
      const newSortIcon = this.productDetail.handleSortIcon(type);
      this.setState({
        dataList: handleSortData(dataList, {
          sortTarget: type,
          sortTargetType: type === 'createdDate' ? 'date' : undefined,
          orderType: newSortIcon[type] ? 'desc' : 'asc'
        })
      })
    },
    getFilterDataList: () => {
      const {
        dataList,
        activePage,
        dataPerPage,
        filterValue,
        isIndoToko,
        isIndoDistributor,
      } = this.state;
      const firstIndex = (activePage - 1) * dataPerPage;
      const lastIndex = activePage * dataPerPage;
      const filterList = dataList.filter((e, i) => {
        if (filterValue.length) {
          if (isIndoToko || isIndoDistributor) {
            if (
              e.profileType.toLowerCase().indexOf(filterValue.toLowerCase()) === -1 &&
              e.fBsproductgroupname.toLowerCase().indexOf(filterValue.toLowerCase()) === -1
            ) {
              return false;
            }
          }
          else {
            if (
              e.itemDescription.toLowerCase().indexOf(filterValue.toLowerCase()) === -1 &&
              e.lotNumber.toLowerCase().indexOf(filterValue.toLowerCase()) === -1 &&
              e.topPaintColor.toLowerCase().indexOf(filterValue.toLowerCase()) === -1
            ) {
              return false;
            }
          }
        }
        return true;
      });
      return filterList.filter((e, i) => i >= firstIndex && i < lastIndex);
    },
    renderTableToko: () => {
      const {
        isIndoToko,
        oldDataList,
        sortIcon,
        userAuth,
        isIndoDistributor,
      } = this.state
      return (
        <Table
          striped
          action
        >
          <Table.Section>
            <Table.Row>
              <Table.HeadColumn
                minWidth={VARIABLES.TABLE.COLUMN_WIDTHS.PROFILE_MAPPING_LIST.TOKO.PROFILE_TYPE}
                sortAscending={sortIcon.itemTypeName}
                onClickSort={() => {
                  this.productDetail.onClickSort('itemTypeName');
                }}
              >
                {getLanguage('productDetail.table.header.profileType', '')}
              </Table.HeadColumn>
              <Table.HeadColumn
                minWidth={VARIABLES.TABLE.COLUMN_WIDTHS.PROFILE_MAPPING_LIST.TOKO.PRODUCT_GROUP}
                sortAscending={sortIcon.fBsproductgroupname}
                onClickSort={() => {
                  this.productDetail.onClickSort('fBsproductgroupname');
                }}
              >
                {getLanguage('productDetail.table.header.brandProductGroup', '')}
              </Table.HeadColumn>
              <Table.HeadColumn
                minWidth={VARIABLES.TABLE.COLUMN_WIDTHS.PROFILE_MAPPING_LIST.TOKO.DESCRIPTION}
                // sortAscending={sortIcon.itemDescription}
                // onClickSort={() => {}}
                noSort
              >
                {getLanguage('productDetail.table.header.description', '')}
              </Table.HeadColumn>
              <Table.HeadColumn
                alignRight
                minWidth={VARIABLES.TABLE.COLUMN_WIDTHS.PROFILE_MAPPING_LIST.TOKO.QUANTITY}
                sortAscending={sortIcon.fTotalRemainingprofile}
                onClickSort={() => {
                  this.productDetail.onClickSort('fTotalRemainingprofile');
                }}
              >
                {getLanguage('productDetail.table.header.quantity', '')}
              </Table.HeadColumn>
              <Table.HeadColumn
                alignRight
                minWidth={VARIABLES.TABLE.COLUMN_WIDTHS.PROFILE_MAPPING_LIST.TOKO.LENGTH}
                sortAscending={sortIcon.profileLength}
                onClickSort={() => {
                  this.productDetail.onClickSort('profileLength');
                }}
              >
                {getLanguage('productDetail.table.header.length', '')}
              </Table.HeadColumn>
              {/* {
                isIndoDistributor &&
                <Table.HeadColumn
                  noSort
                  alignCenter
                  maxWidth={VARIABLES.TABLE.COLUMN_WIDTHS.PROFILE_MAPPING_LIST.TOKO.ACTION}
                  minWidth={VARIABLES.TABLE.COLUMN_WIDTHS.PROFILE_MAPPING_LIST.TOKO.ACTION}
                >
                  {getLanguage('productDetail.table.header.action', '')}
                </Table.HeadColumn>
              } */}
            </Table.Row>
          </Table.Section>
          <Table.Section>
            {
              this.productDetail.getFilterDataList().map((e, i) => {
                return (
                  <Table.Row key={i}>
                    <Table.BodyColumn
                      info
                      minWidth={VARIABLES.TABLE.COLUMN_WIDTHS.PROFILE_MAPPING_LIST.TOKO.PROFILE_TYPE}
                      title={e.profileType}
                    >
                      {e.profileType}
                    </Table.BodyColumn>
                    <Table.BodyColumn
                      info
                      minWidth={VARIABLES.TABLE.COLUMN_WIDTHS.PROFILE_MAPPING_LIST.TOKO.PRODUCT_GROUP}
                      title={e.fBsproductgroupname}
                    >
                      {e.fBsproductgroupname}
                    </Table.BodyColumn>
                    <Table.BodyColumn
                      info
                      minWidth={VARIABLES.TABLE.COLUMN_WIDTHS.PROFILE_MAPPING_LIST.TOKO.DESCRIPTION}
                      title={e.itemDescription}
                    >
                      {e.itemDescription}
                    </Table.BodyColumn>
                    <Table.BodyColumn
                      info
                      alignRight
                      minWidth={VARIABLES.TABLE.COLUMN_WIDTHS.PROFILE_MAPPING_LIST.TOKO.QUANTITY}
                      title={e.fTotalRemainingprofileWithComma}
                    >
                      {`${e.fTotalRemainingprofileWithComma} ${e.unit}`}
                    </Table.BodyColumn>
                    <Table.BodyColumn
                      info
                      alignRight
                      minWidth={VARIABLES.TABLE.COLUMN_WIDTHS.PROFILE_MAPPING_LIST.TOKO.LENGTH}
                    >
                      {e.profileLengthWithComma}
                    </Table.BodyColumn>

                    {/* {
                      isIndoDistributor &&
                      <Table.BodyColumn
                        action
                        alignCenter
                        maxWidth={VARIABLES.TABLE.COLUMN_WIDTHS.PROFILE_MAPPING_LIST.TOKO.ACTION}
                        minWidth={VARIABLES.TABLE.COLUMN_WIDTHS.PROFILE_MAPPING_LIST.TOKO.ACTION}
                      >
                        <Button
                          blue
                          ui='frontIconSmall'
                          name={getLanguage('field.buttonInputQuantity', '')}
                          iconWidth='10'
                          iconHeight='10'
                          iconSrc={ICONS['ic-edit.svg']}
                          onClick={() => {
                            // if (isIndoDistributor) {
                            redirectData(`${ROUTE_PATH.PRODUCT_DETAIL.LINK}/${e.id}`, {
                              data: e,
                              productList: oldDataList,
                              isIndo: isIndoDistributor || isIndoToko,
                            })
                            // }
                            // else {
                            //   redirectData(`${ROUTE_PATH.PROFILE_MAPPING_PRODUCT_MANAGE.LINK}/${e.id}`, {
                            //     data: e,
                            //     productList: oldDataList,
                            //     profile: userAuth,
                            //     isToko: isIndoToko,
                            //   });
                            // }
                          }}
                        />
                      </Table.BodyColumn>
                    } */}
                  </Table.Row>
                )
              })
            }</Table.Section>
        </Table>
      )
    },
    renderTable: () => {
      const {
        oldDataList,
        dataNoLengthList,
        sortIcon,
        isThai,
        isVietnam,
      } = this.state
      return (
        <Table
          striped
          action
        >
          <Table.Section>
            <Table.Row>
              <Table.HeadColumn
                minWidth={VARIABLES.TABLE.INFO_WIDTHS.COIL_NAME}
                sortAscending={sortIcon.itemDescription}
                onClickSort={() => {
                  this.productDetail.onClickSort('itemDescription');
                }}
              >
                {getLanguage('productDetail.table.header.coilName', '')}
              </Table.HeadColumn>
              <Table.HeadColumn
                maxWidth={VARIABLES.TABLE.INFO_WIDTHS.COIL_ID_INFO}
                minWidth={VARIABLES.TABLE.INFO_WIDTHS.COIL_ID_INFO}
                sortAscending={sortIcon.lotNumber}
                onClickSort={() => {
                  this.productDetail.onClickSort('lotNumber')
                }}
              >
                {getLanguage('productDetail.table.header.coilID', '')}
              </Table.HeadColumn>
              {
                (isThai || isVietnam) ?
                  <Table.HeadColumn
                    maxWidth={VARIABLES.TABLE.INFO_WIDTHS.THICKNESS}
                    minWidth={VARIABLES.TABLE.INFO_WIDTHS.THICKNESS}
                    sortAscending={sortIcon.thicknessMM}
                    onClickSort={() => {
                      this.productDetail.onClickSort('thicknessMM');
                    }}
                  >
                    {getLanguage('productDetail.table.header.thickness', '')}
                  </Table.HeadColumn>
                  :
                  <Table.HeadColumn
                    maxWidth={VARIABLES.TABLE.INFO_WIDTHS.THICKNESS}
                    minWidth={VARIABLES.TABLE.INFO_WIDTHS.THICKNESS}
                    sortAscending={sortIcon.bmtMM}
                    onClickSort={() => {
                      this.productDetail.onClickSort('bmtMM');
                    }}
                  >
                    {getLanguage('productDetail.table.header.thicknessBMT', '')}
                  </Table.HeadColumn>
              }
              <Table.HeadColumn
                maxWidth={VARIABLES.TABLE.INFO_WIDTHS.DATE_CREATE}
                minWidth={VARIABLES.TABLE.INFO_WIDTHS.DATE_CREATE}
                sortAscending={sortIcon.createdDate}
                onClickSort={() => {
                  this.productDetail.onClickSort('createdDate');
                }}
              >
                {getLanguage('productDetail.table.header.createDate', '')}
              </Table.HeadColumn>
              <Table.HeadColumn
                alignRight
                maxWidth={VARIABLES.TABLE.INFO_WIDTHS.LIFETIME}
                minWidth={VARIABLES.TABLE.INFO_WIDTHS.LIFETIME}
                sortAscending={sortIcon.fAging}
                onClickSort={() => {
                  this.productDetail.onClickSort('fAging');
                }}
              >
                {getLanguage('productDetail.table.header.lifetime', '')}
              </Table.HeadColumn>
              <Table.HeadColumn
                alignRight
                maxWidth={VARIABLES.TABLE.INFO_WIDTHS.COLOR}
                minWidth={VARIABLES.TABLE.INFO_WIDTHS.COLOR}
                sortAscending={sortIcon.topPaintColor}
                onClickSort={() => {
                  this.productDetail.onClickSort('topPaintColor');
                }}
              >
                {getLanguage('productDetail.table.header.color', '')}
              </Table.HeadColumn>
              <Table.HeadColumn
                alignRight
                maxWidth={VARIABLES.TABLE.INFO_WIDTHS.REMAINED_WEIGHT_KG_ABBREVIATION}
                minWidth={VARIABLES.TABLE.INFO_WIDTHS.REMAINED_WEIGHT_KG_ABBREVIATION}
                sortAscending={sortIcon.fRemainingweight}
                onClickSort={() => {
                  this.productDetail.onClickSort('fRemainingweight');
                }}
                title={getLanguage('productDetail.table.header.weightBalance', '')}
              >
                {getLanguage('productDetail.table.header.weightBalanceAbbreviation', '')}
              </Table.HeadColumn>
              <Table.HeadColumn
                alignRight
                maxWidth={VARIABLES.TABLE.INFO_WIDTHS.LENGTH_BALANCE_M_ABBREVIATION}
                minWidth={VARIABLES.TABLE.INFO_WIDTHS.LENGTH_BALANCE_M_ABBREVIATION}
                sortAscending={sortIcon.fTotalRemaininglength}
                onClickSort={() => {
                  this.productDetail.onClickSort('fTotalRemaininglength');
                }}
                title={getLanguage('productDetail.table.header.lengthBalance', '')}
              >
                {getLanguage('productDetail.table.header.lengthBalanceAbbreviation', '')}
              </Table.HeadColumn>
              <Table.HeadColumn
                noSort
                alignCenter
                maxWidth={VARIABLES.TABLE.INFO_WIDTHS.ACTION_EDIT_LENGTH}
                minWidth={VARIABLES.TABLE.INFO_WIDTHS.ACTION_EDIT_LENGTH}
              >
                {getLanguage('productDetail.table.header.action', '')}
              </Table.HeadColumn>
            </Table.Row>
          </Table.Section>
          <Table.Section>
            {
              this.productDetail.getFilterDataList().map((e, i) => {
                return (
                  <Table.Row
                    key={i}
                  >
                    <Table.BodyColumn
                      info
                      minWidth={VARIABLES.TABLE.INFO_WIDTHS.COIL_NAME}
                      title={e.itemDescription}
                    >
                      {e.itemDescription}
                    </Table.BodyColumn>
                    <Table.BodyColumn
                      info
                      maxWidth={VARIABLES.TABLE.INFO_WIDTHS.COIL_ID_INFO}
                      minWidth={VARIABLES.TABLE.INFO_WIDTHS.COIL_ID_INFO}
                      title={e.lotNumber}
                    >
                      {e.lotNumber}
                    </Table.BodyColumn>
                    {
                      (isThai || isVietnam) ?
                        <Table.BodyColumn
                          info
                          maxWidth={VARIABLES.TABLE.INFO_WIDTHS.THICKNESS}
                          minWidth={VARIABLES.TABLE.INFO_WIDTHS.THICKNESS}
                          title={e.thicknessMM}
                        >
                          {e.thicknessMM}
                        </Table.BodyColumn>
                        :
                        <Table.BodyColumn
                          info
                          maxWidth={VARIABLES.TABLE.INFO_WIDTHS.THICKNESS}
                          minWidth={VARIABLES.TABLE.INFO_WIDTHS.THICKNESS}
                          title={e.bmtMM}
                        >
                          {e.bmtMM}
                        </Table.BodyColumn>
                    }
                    <Table.BodyColumn
                      info
                      maxWidth={VARIABLES.TABLE.INFO_WIDTHS.DATE_CREATE}
                      minWidth={VARIABLES.TABLE.INFO_WIDTHS.DATE_CREATE}
                    >
                      {e.displayInvoiceDate}
                    </Table.BodyColumn>
                    <Table.BodyColumn
                      info
                      alignRight
                      maxWidth={VARIABLES.TABLE.INFO_WIDTHS.LIFETIME}
                      minWidth={VARIABLES.TABLE.INFO_WIDTHS.LIFETIME}
                      title={e.fAging}
                    >
                      {e.fAging}
                    </Table.BodyColumn>
                    <Table.BodyColumn
                      info
                      alignRight
                      maxWidth={VARIABLES.TABLE.INFO_WIDTHS.COLOR}
                      minWidth={VARIABLES.TABLE.INFO_WIDTHS.COLOR}
                      title={e.topPaintColor}
                    >
                      {e.topPaintColor}
                    </Table.BodyColumn>
                    <Table.BodyColumn
                      info
                      alignRight
                      maxWidth={VARIABLES.TABLE.INFO_WIDTHS.REMAINED_WEIGHT_KG_ABBREVIATION}
                      minWidth={VARIABLES.TABLE.INFO_WIDTHS.REMAINED_WEIGHT_KG_ABBREVIATION}
                      title={e.fRemainingweightWithComma}
                    >
                      {e.fRemainingweightWithComma}
                    </Table.BodyColumn>
                    <Table.BodyColumn
                      info
                      alignRight
                      maxWidth={VARIABLES.TABLE.INFO_WIDTHS.LENGTH_BALANCE_M_ABBREVIATION}
                      minWidth={VARIABLES.TABLE.INFO_WIDTHS.LENGTH_BALANCE_M_ABBREVIATION}
                      title={e.fTotalRemaininglengthWithComma}
                    >
                      {e.fTotalRemaininglengthWithComma}
                    </Table.BodyColumn>
                    <Table.BodyColumn
                      action
                      alignCenter
                      maxWidth={VARIABLES.TABLE.INFO_WIDTHS.ACTION_EDIT_LENGTH}
                      minWidth={VARIABLES.TABLE.INFO_WIDTHS.ACTION_EDIT_LENGTH}
                    >
                      <Button
                        lightBlue
                        ui='frontIconSmall'
                        name={getLanguage('productDetail.table.action.buttonEditLength', '')}
                        iconWidth='10'
                        iconHeight='10'
                        iconSrc={ICONS['ic-edit.svg']}
                        onClick={() => {
                          redirectData(`${ROUTE_PATH.PRODUCT_DETAIL.LINK}/${e.id}`, {
                            data: e,
                            productList: oldDataList,
                            dataNoLengthList
                          })
                        }}
                      />
                    </Table.BodyColumn>
                  </Table.Row>
                )
              })
            }</Table.Section>
        </Table>
      )
    },
  }

  input = {
    onChangeInput: (ev) => {
      const name = ev.target.name;
      const value = ev.target.value;
      this.setState({
        [name]: value,
      })
    },
    onClickSearch: () => {
      const {
        searchValue,
        oldDataList,
        dataNoLengthList,
      } = this.state;
      const filterValue = searchValue.trim();
      this.setState({
        filterValue,
        activePage: 1,
        dataList: oldDataList,
      }, () => {
        const dataList = this.productDetail.getFilterDataList();
        const findCoilNoLength = (dataNoLengthList || []).filter(e => (e.lotNumber).toLowerCase() === filterValue.toLowerCase())[0]
        if (
          dataList.length === 0 &&
          findCoilNoLength
        ) {
          toast.error(getLanguage('message.errorCoilNoLength').replace('[coilName]', findCoilNoLength.lotNumber))
        }
      })
    },
  }

  pagination = {
    onPaginationChange: (pageNumber) => {
      this.setState({
        activePage: pageNumber,
      })
    }
  }

  render() {
    const {
      activePage,
      dataPerPage,
      dataList,
      searchValue,
      isIndoToko,
      isIndoDistributor,
    } = this.state
    const totalData = dataList.length;
    return (
      <React.Fragment>
        <Breadcrumb>
          <Breadcrumb.List
            link
            name={getLanguage('productDetail.breadcrumb1', '')}
            onClick={() => {
              redirect(ROUTE_PATH.HOME.LINK)
            }}
          />
          <Breadcrumb.List name='/' />
          <Breadcrumb.List
            link
            name={getLanguage('productDetail.breadcrumb2', '')}
          // onClick={() => {
          //   redirect(ROUTE_PATH.INVENTORY_INDEXES.LINK)
          // }}
          />
          <Breadcrumb.List name='/' />
          <Breadcrumb.List
            link
            name={getLanguage('productDetail.breadcrumb3', '')}
            onClick={() => {
              redirect(ROUTE_PATH.INVENTORY.LINK)
            }}
          />
          <Breadcrumb.List name='/' />
          <Breadcrumb.List name={getLanguage('productDetail.breadcrumb4', '')} />
        </Breadcrumb>
        <Section ui='content'>
          <Section spacingBottom='35'>
            <Grid
              gutterVertical='15'
              justify='space-between'
            >
              <Grid.Column>
                <TitlePage
                  src={ICONS['ic-page-inventory.svg']}
                  name={getLanguage('productDetail.title', '')}
                />
              </Grid.Column>
              <Grid.Column>
                <Grid gutter='35'>
                  <Grid.Column>
                    <Grid gutter='10'>
                      <Grid.Column>
                        <Field name='searchValue'
                          ui='blockLabelVerticalSearch'
                          width='285'
                          placeholder={(isIndoToko || isIndoDistributor) ? getLanguage('productDetail.placeholderForToko', '') : getLanguage('productDetail.placeholder', '')}
                          value={searchValue}
                          onChange={this.input.onChangeInput}
                        />
                      </Grid.Column>
                      <Grid.Column>
                        <Button
                          lightBlue
                          name={getLanguage('productDetail.buttonSearch', '')}
                          width={VARIABLES.BUTTON.WIDTHS.W_105}
                          onClick={this.input.onClickSearch}
                        />
                      </Grid.Column>
                    </Grid>
                  </Grid.Column>
                </Grid>
              </Grid.Column>
            </Grid>
          </Section>
          <ShapeContainer
            ui='content'
            justify='space-between'
          >
            <Section spacingBottom='45'>
              {isIndoToko || isIndoDistributor ?
                this.productDetail.renderTableToko()
                :
                this.productDetail.renderTable()
              }
            </Section>
            <TableAction>
              <TableAction.Left>
                <Grid gutter='30'>
                  <Grid.Column>
                    <Grid gutter='20'>
                      <Grid.Column>
                        <Section paddingTop='6'>
                          <Text
                            regular12
                            color={VARIABLES.COLORS.GRAY_16}
                          >
                            {getLanguage('pagination.page', '')} {activePage}/{Math.ceil(totalData / dataPerPage)}
                          </Text>
                        </Section>
                      </Grid.Column>
                      <Grid.Column>
                        <PaginationControl
                          activePage={activePage}
                          itemsCountPerPage={dataPerPage}
                          totalItemsCount={totalData}
                          pageRangeDisplayed={3}
                          onChange={this.pagination.onPaginationChange}
                        />
                      </Grid.Column>
                    </Grid>
                  </Grid.Column>
                  {/* <Grid.Column>
                    <Grid gutter='15'>
                      <Grid.Column>
                        <Button
                          blue
                          ui='frontIcon'
                          name='Print'
                          width={VARIABLES.BUTTON.WIDTHS.W_125}
                          iconWidth='18'
                          iconHeight='18'
                          iconSrc={ICONS['ic-printer.svg']}
                          onClick={() => { }}
                        />
                      </Grid.Column>
                      <Grid.Column>
                        <Button
                          green
                          ui='frontIcon'
                          name='Export'
                          width={VARIABLES.BUTTON.WIDTHS.W_125}
                          iconWidth='16'
                          iconHeight='16'
                          iconSrc={ICONS['ic-export.svg']}
                          onClick={() => { }}
                        />
                      </Grid.Column>
                    </Grid>
                  </Grid.Column> */}
                </Grid>
              </TableAction.Left>
              <TableAction.Right>
                <Button
                  gray
                  ui='back'
                  name={getLanguage('field.buttonBack', '')}
                  onClick={() => {
                    redirect(ROUTE_PATH.INVENTORY.LINK)
                  }}
                />
              </TableAction.Right>
            </TableAction>
          </ShapeContainer>
        </Section>
      </React.Fragment>
    )
  }
}
