import _ from 'lodash';
import moment from 'moment';
import QrCode from 'qrcode.react';
import React from 'react';
import { renderToString } from 'react-dom/server';
import { DATA } from '../../helpers/constants/data';
import { LOCAL_STORAGE } from '../../helpers/constants/localStorage';
import { getUserAuthRedux } from '../../helpers/functions/auth';
import {
  displayDate,
  handleDisplayImage,
} from '../../helpers/functions/display';
import { getLocalStorage } from '../../helpers/functions/localStorage';
import { handleDocumentaryData } from '../../helpers/functions/warranty';
import { CONTENTS } from '../../themes';
import { DocumentaryTemplate } from '../GenerateDocumentaryContainer/DocumentaryTemplate';
import { Grid, Image, Section, WarrantyGeneratePdfPage } from './../../components';
import { ENUM } from '../../helpers/constants/enum';

export class WarrantyCMSTemplate extends React.Component {
  constructor(props) {
    super(props);
    const userAuth = getUserAuthRedux();
    const language =
      props.language === undefined
        ? getLocalStorage(LOCAL_STORAGE.LANGUAGE_RBPTRUEBLUE)
        : props.language;

    this.state = {
      userAuth,
      language,
      isLanguageEn: language !== DATA.LANGUAGE.THAI,
      attachmentPageLimit: 10,
    };
  }

  warrantyCMSTemplate = {
    // only preview warranty template
    getWatermarkComponent: () => {
      const {
        isWaterMarkPreviewWarrantyTemplate,
        watermarkTemplateData
      } = this.props;
      const { isLanguageEn } = this.state;
      if (isWaterMarkPreviewWarrantyTemplate) {
        return watermarkTemplateData ? (
          <div className='wgpp-mark'>
            <div className='wgpp-mark-container is-rotate'>
              <div className='wgpp-content is-watermark'>
                <div className='wgpp-content-item'>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: isLanguageEn
                        ? watermarkTemplateData.wtmTempDtBodyEn
                        : watermarkTemplateData.wtmTempDtBodyTh,
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className='wgpp-mark'>
            <div className='wgpp-mark-container is-rotate'>
              <div className='wgpp-content is-warranty'>
                <div className='wgpp-content-item'>
                  <span className='wgpp-text is-bold is-warranty is-brand'>
                    NS BlueScope (Thailand) Limited
                  </span>
                </div>
                <div className='wgpp-content-item'>
                  <span className='wgpp-text is-bold is-warranty is-heading'>
                    {isLanguageEn
                      ? 'This warranty is for project submissions only.'
                      : 'ใบรับประกันนี้สําหรับยื่นเสนอโครงการเท่านั้น'}
                  </span>
                </div>
                <div className='wgpp-content-item'>
                  <span className='wgpp-text is-warranty is-description'>
                    {isLanguageEn
                      ? 'After the transaction, the warranty will be provided out.'
                      : 'ใบรับประกันตัวจริงจะออกให้หลังจากมีการซื้อ'}
                  </span>
                </div>
              </div>
            </div>
          </div>
        );
      }
      return null;
    },
    renderAttachmentNonRes: (pageIndex) => {
      const { dataGroupList } = this.props;
      const { isLanguageEn, attachmentPageLimit } = this.state;
      let cnt = 0;
      const startAttachmentIndex = pageIndex * attachmentPageLimit + 1;
      const endAttachmentIndex = startAttachmentIndex + attachmentPageLimit - 1;

      return renderToString(
        <React.Fragment>
          <Section spacingBottom='3'>
            <span className='wgpp-text'>
              {isLanguageEn ? 'Attachment' : 'เอกสารแนบ'} {pageIndex + 1}
            </span>
          </Section>
          <Section spacingBottom='3'>
            <table className='wgpp-table'>
              <thead>
                <tr>
                  <th className='is-number is-text-align-center'>
                    <span className='wgpp-text'>
                      {isLanguageEn ? 'No.' : 'เลขที่'}
                    </span>
                  </th>
                  <th className='is-text-align-center'>
                    <span className='wgpp-text'>
                      {isLanguageEn
                        ? 'Description Coil ID'
                        : 'รายละเอียดคอยล์ไอดี'}
                    </span>
                  </th>
                  <th className='is-text-align-center'>
                    <span className='wgpp-text'>
                      {isLanguageEn ? 'Coil ID' : 'คอยล์ไอดี'}
                    </span>
                  </th>
                </tr>
              </thead>
              <tbody>
                {dataGroupList.map((e, i) => {
                  return e.displayCoil.map((ec, ic) => {
                    cnt++;
                    if (
                      cnt >= startAttachmentIndex &&
                      cnt <= endAttachmentIndex
                    ) {
                      return (
                        <tr key={i}>
                          <td className='is-number is-text-align-center'>
                            <span className='wgpp-text is-bold'>{cnt}</span>
                          </td>
                          <td>
                            <span className='wgpp-text is-bold'>
                              {e.coilNameList[ic]}
                            </span>
                          </td>
                          <td>
                            <span className='wgpp-text is-bold'>
                              {e.coilIdList[ic]}
                            </span>
                          </td>
                        </tr>
                      );
                    } else {
                      return null;
                    }
                  });
                })}
              </tbody>
            </table>
          </Section>
        </React.Fragment>
      );
    },
    renderAttachmentRes: (pageIndex) => {
      const { dataGroupList, dataDetail } = this.props;
      const { isLanguageEn, attachmentPageLimit } = this.state;
      let cnt = 0;
      const startAttachmentIndex = pageIndex * attachmentPageLimit + 1;
      const endAttachmentIndex = startAttachmentIndex + attachmentPageLimit - 1;

      const jingjoeLek =
        dataDetail.templateForm === DATA.WARRANTY_TEMPLATE_LIST[3];
      return renderToString(
        <React.Fragment>
          <Section spacingBottom='3'>
            <span className='wgpp-text is-sm is-bold'>
              {isLanguageEn ? 'Product Detail' : 'รายละเอียดสินค้า'}
            </span>
          </Section>
          <Section spacingBottom='3'>
            <table className='wgpp-table'>
              <thead>
                <tr>
                  <th className='is-text-align-center'>
                    <span className='wgpp-text is-sm'>
                      {isLanguageEn ? 'Coil Description' : 'รายละเอียดคอยล์'}
                    </span>
                  </th>
                  <th className='is-text-align-center'>
                    <span className='wgpp-text is-sm'>
                      {isLanguageEn ? 'Total Bold' : 'ความหนารวม'}
                      <br />
                      {isLanguageEn ? '(TCT or APT) mm.' : '(TCT หรือ APT) มม.'}
                    </span>
                  </th>
                  <th className='is-text-align-center'>
                    <span className='wgpp-text is-sm'>
                      {isLanguageEn ? 'Metal Bold' : 'ความหนาเหล็ก'}
                      <br />
                      {isLanguageEn ? '(BMT) mm.' : '(BMT) มม.'}
                    </span>
                  </th>
                  <th className='is-text-align-center'>
                    <span className='wgpp-text is-sm'>
                      {isLanguageEn ? 'Product Lengths' : 'ความยาวสินค้า'}
                      <br />
                      {isLanguageEn ? 'm.' : 'เมตร'}
                    </span>
                  </th>
                  <th className='is-text-align-center'>
                    <span className='wgpp-text is-sm'>
                      {isLanguageEn ? 'Coil ID' : 'คอยล์ไอดี'}
                    </span>
                  </th>
                </tr>
              </thead>
              <tbody>
                {dataGroupList.map((e, i) => {
                  return e.displayCoil.map((ec, ic) => {
                    cnt++;
                    if (
                      cnt >= startAttachmentIndex &&
                      cnt <= endAttachmentIndex
                    ) {
                      return (
                        <tr key={i}>
                          <td>
                            <span className='wgpp-text is-sm is-bold'>
                              {e.coilNameList[ic]}
                            </span>
                          </td>
                          <td className='is-text-align-center'>
                            <span className='wgpp-text is-sm is-bold'>
                              {e.thicknessList[ic]}
                            </span>
                          </td>
                          <td className='is-text-align-center'>
                            <span className='wgpp-text is-sm is-bold'>
                              {jingjoeLek ? '-' : e.bmtList[ic]}
                            </span>
                          </td>
                          <td className='is-text-align-center'>
                            <span className='wgpp-text is-sm is-bold'>
                              {e.coilOrderMList[ic]}
                            </span>
                          </td>
                          <td className='is-text-align-center'>
                            <span className='wgpp-text is-sm is-bold'>
                              {e.coilIdList[ic]}
                            </span>
                          </td>
                        </tr>
                      );
                    } else {
                      return null;
                    }
                  });
                })}
              </tbody>
            </table>
          </Section>
        </React.Fragment>
      );
    },
    renderApplicationDetailNonRes: () => {
      const {
        isHideApplicationDetail,
        dataGroupList,
      } = this.props;
      const { isLanguageEn } = this.state;
      let cnt = 0;
      let roof = 0;
      let wall = 0
      let purlinGirt = 0;
      let truFrame = 0;
      let coolRoom = 0;
      let decking = 0;
      let airDucting = 0;
      dataGroupList.forEach(e => {
        if (e[DATA.WARRANTY_APP_DETAIL_NAME_SELECT_LIST[0]]) {
          roof += parseInt(e.roof) || 0
        }
        if (e[DATA.WARRANTY_APP_DETAIL_NAME_SELECT_LIST[1]]) {
          wall += parseInt(e.wall) || 0
        }
        if (e[DATA.WARRANTY_APP_DETAIL_NAME_SELECT_LIST[2]]) {
          purlinGirt += parseInt(e.purlinGirt) || 0
        }
        if (e[DATA.WARRANTY_APP_DETAIL_NAME_SELECT_LIST[3]]) {
          truFrame += parseInt(e.truFrame) || 0
        }
        if (e[DATA.WARRANTY_APP_DETAIL_NAME_SELECT_LIST[4]]) {
          coolRoom += parseInt(e.coolRoom) || 0
        }
        if (e[DATA.WARRANTY_APP_DETAIL_NAME_SELECT_LIST[5]]) {
          decking += parseInt(e.decking) || 0
        }
        if (e[DATA.WARRANTY_APP_DETAIL_NAME_SELECT_LIST[6]]) {
          airDucting += parseInt(e.airDucting) || 0
        }
      });

      return isHideApplicationDetail
        ? ''
        : renderToString(
          <React.Fragment>
            <Section>
              <span className='wgpp-text'>
                {isLanguageEn
                  ? 'Application Detail'
                  : 'รายละเอียดแอปพลิเคชัน'}
              </span>
            </Section>
            <Section spacingBottom='3'>
              {roof > 0 && (
                <Grid>
                  <Grid.Column>
                    <Section width='100'>
                      <span className='wgpp-text is-bold'>
                        {isLanguageEn ? 'Roof' : 'หลังคา'}
                      </span>
                    </Section>
                  </Grid.Column>
                  <Grid.Column>
                    <span className='wgpp-text is-bold'>
                      {roof} {isLanguageEn ? 'm.' : 'เมตร'}
                    </span>
                  </Grid.Column>
                </Grid>
              )}
              {wall > 0 && (
                <Grid>
                  <Grid.Column>
                    <Section width='100'>
                      <span className='wgpp-text is-bold'>
                        {isLanguageEn ? 'Wall' : 'ผนัง'}
                      </span>
                    </Section>
                  </Grid.Column>
                  <Grid.Column>
                    <span className='wgpp-text is-bold'>
                      {wall} {isLanguageEn ? 'm.' : 'เมตร'}
                    </span>
                  </Grid.Column>
                </Grid>
              )}
              {purlinGirt > 0 && (
                <Grid>
                  <Grid.Column>
                    <Section width='100'>
                      <span className='wgpp-text is-bold'>
                        {isLanguageEn
                          ? 'Purlin and Girt'
                          : 'แปหลังคา และ แปผนัง'}
                      </span>
                    </Section>
                  </Grid.Column>
                  <Grid.Column>
                    <span className='wgpp-text is-bold'>
                      {purlinGirt} {isLanguageEn ? 'm.' : 'เมตร'}
                    </span>
                  </Grid.Column>
                </Grid>
              )}
              {truFrame > 0 && (
                <Grid>
                  <Grid.Column>
                    <Section width='100'>
                      <span className='wgpp-text is-bold'>
                        {isLanguageEn
                          ? 'Truss and Frame'
                          : 'โครงหลังคา หรือ โครงผนัง'}
                      </span>
                    </Section>
                  </Grid.Column>
                  <Grid.Column>
                    <span className='wgpp-text is-bold'>
                      {truFrame} {isLanguageEn ? 'm.' : 'เมตร'}
                    </span>
                  </Grid.Column>
                </Grid>
              )}
              {coolRoom > 0 && (
                <Grid>
                  <Grid.Column>
                    <Section width='100'>
                      <span className='wgpp-text is-bold'>
                        {isLanguageEn ? 'Cool Room Panel' : 'ผนังห้องเย็น'}
                      </span>
                    </Section>
                  </Grid.Column>
                  <Grid.Column>
                    <span className='wgpp-text is-bold'>
                      {coolRoom} {isLanguageEn ? 'm.' : 'เมตร'}
                    </span>
                  </Grid.Column>
                </Grid>
              )}
              {decking > 0 && (
                <Grid>
                  <Grid.Column>
                    <Section width='100'>
                      <span className='wgpp-text is-bold'>
                        {isLanguageEn ? 'Decking' : 'แผ่นพื้นกึ่งสำเร็จ'}
                      </span>
                    </Section>
                  </Grid.Column>
                  <Grid.Column>
                    <span className='wgpp-text is-bold'>
                      {decking} {isLanguageEn ? 'm.' : 'เมตร'}
                    </span>
                  </Grid.Column>
                </Grid>
              )}
              {airDucting > 0 && (
                <Grid>
                  <Grid.Column>
                    <Section width='100'>
                      <span className='wgpp-text is-bold'>
                        {isLanguageEn ? 'Air Ducting' : 'งานท่อ'}
                      </span>
                    </Section>
                  </Grid.Column>
                  <Grid.Column>
                    <span className='wgpp-text is-bold'>
                      {airDucting} {isLanguageEn ? 'm.' : 'เมตร'}
                    </span>
                  </Grid.Column>
                </Grid>
              )}
            </Section>
            <Section spacingBottom='3'>
              <table className='wgpp-table'>
                <thead>
                  <tr>
                    <th className='is-number is-text-align-center'>
                      <span className='wgpp-text'>
                        {isLanguageEn ? 'No.' : 'เลขที่'}
                      </span>
                    </th>
                    <th className='is-text-align-center'>
                      <span className='wgpp-text'>
                        {isLanguageEn ? 'Building' : 'งานก่อสร้าง'}
                      </span>
                    </th>
                    <th className='is-text-align-center'>
                      <span className='wgpp-text'>
                        {isLanguageEn
                          ? 'Installation Date (dd/mm/yy)'
                          : 'วันที่ติดตั้ง (วัน/เดือน/ปี)'}
                      </span>
                    </th>
                    <th className='is-text-align-center'>
                      <span className='wgpp-text'>
                        {isLanguageEn ? 'Remark' : 'ข้อสังเกต'}
                      </span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {dataGroupList.map((e, i) => {
                    return e.applicationInfoList.map((ec, ic) => {
                      cnt++;
                      return (
                        <tr key={ic}>
                          <td className='is-number is-text-align-center'>
                            <span className='wgpp-text is-bold'>{cnt}</span>
                          </td>
                          <td>
                            <span className='wgpp-text is-bold'>
                              {ec.building}
                            </span>
                          </td>
                          <td className='is-installation-date is-text-align-center'>
                            <span className='wgpp-text is-bold'>
                              {displayDate(ec.projectInsDateTime)}
                            </span>
                          </td>
                          <td>
                            <span className='wgpp-text is-bold'>
                              {ec.customerRemark}
                            </span>
                          </td>
                        </tr>
                      );
                    });
                  })}
                </tbody>
              </table>
            </Section>
          </React.Fragment>
        );
    },
    renderApplicationDetailRes: () => {
      const { isHideApplicationDetail, dataGroupList } = this.props;
      const { isLanguageEn } = this.state;
      let cnt = 0;
      return isHideApplicationDetail
        ? ''
        : renderToString(
          <React.Fragment>
            <Section spacingBottom='3'>
              <span className='wgpp-text is-sm is-bold'>
                {isLanguageEn ? 'Use for' : 'ใช้สำหรับในงาน'}
              </span>
            </Section>
            <Section spacingBottom='3'>
              <table className='wgpp-table'>
                <tbody>
                  {dataGroupList.map((e, i) => {
                    return (
                      <tr key={i}>
                        <td>
                          {e.coilNameList.map((ec, ic) => {
                            return (
                              <Section key={ic}>
                                <span className='wgpp-text is-sm is-bold'>
                                  {ec}
                                </span>
                              </Section>
                            )
                          })}
                          <Section>
                            <Grid gutter='10'>
                              <Grid.Column>
                                <span className='wgpp-text is-sm'>
                                  {isLanguageEn
                                    ? 'Roof and Wall'
                                    : 'หลังคาและผนัง'}
                                </span>
                              </Grid.Column>
                              <Grid.Column>
                                <span className='wgpp-text is-sm'>
                                  {e.roof ? e.roof : '-'}
                                </span>
                              </Grid.Column>
                              <Grid.Column>
                                <span className='wgpp-text is-sm'>
                                  {isLanguageEn ? 'm.' : 'เมตร'}
                                </span>
                              </Grid.Column>
                              <Grid.Column>
                                <span className='wgpp-text is-sm'>/</span>
                              </Grid.Column>
                              <Grid.Column>
                                <span className='wgpp-text is-sm'>
                                  {isLanguageEn ? 'Purlin' : 'แป'}
                                </span>
                              </Grid.Column>
                              <Grid.Column>
                                <span className='wgpp-text is-sm'>
                                  {e.purlinGirt ? e.purlinGirt : '-'}
                                </span>
                              </Grid.Column>
                              <Grid.Column>
                                <span className='wgpp-text is-sm'>
                                  {isLanguageEn ? 'm.' : 'เมตร'}
                                </span>
                              </Grid.Column>
                              <Grid.Column>
                                <span className='wgpp-text is-sm'>/</span>
                              </Grid.Column>
                              <Grid.Column>
                                <span className='wgpp-text is-sm'>
                                  {isLanguageEn ? 'Truss' : 'โครงเคร่า'}
                                </span>
                              </Grid.Column>
                              <Grid.Column>
                                <span className='wgpp-text is-sm'>
                                  {e.truFrame ? e.truFrame : '-'}
                                </span>
                              </Grid.Column>
                              <Grid.Column>
                                <span className='wgpp-text is-sm'>
                                  {isLanguageEn ? 'm.' : 'เมตร'}
                                </span>
                              </Grid.Column>
                              <Grid.Column>
                                <span className='wgpp-text is-sm'>/</span>
                              </Grid.Column>
                              <Grid.Column>
                                <span className='wgpp-text is-sm'>
                                  {isLanguageEn
                                    ? 'Cool Room'
                                    : 'ผนังห้องเย็น'}
                                </span>
                              </Grid.Column>
                              <Grid.Column>
                                <span className='wgpp-text is-sm'>
                                  {e.coolRoom ? e.coolRoom : '-'}
                                </span>
                              </Grid.Column>
                              <Grid.Column>
                                <span className='wgpp-text is-sm'>
                                  {isLanguageEn ? 'm.' : 'เมตร'}
                                </span>
                              </Grid.Column>
                              <Grid.Column>
                                <span className='wgpp-text is-sm'>/</span>
                              </Grid.Column>
                              <Grid.Column>
                                <span className='wgpp-text is-sm'>
                                  {isLanguageEn ? 'Decking' : 'แผ่นพื้น'}
                                </span>
                              </Grid.Column>
                              <Grid.Column>
                                <span className='wgpp-text is-sm'>
                                  {e.decking ? e.decking : '-'}
                                </span>
                              </Grid.Column>
                              <Grid.Column>
                                <span className='wgpp-text is-sm'>
                                  {isLanguageEn ? 'm.' : 'เมตร'}
                                </span>
                              </Grid.Column>
                            </Grid>
                          </Section>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </Section>
          </React.Fragment>
        );
    },
    handleDisplayEnvDetail: (item) => {
      let envDetail = _.cloneDeep(item);
      if (
        envDetail.corrosion === null ||
        envDetail.corrosion === 'null' ||
        envDetail.corrosion === undefined ||
        envDetail.corrosion === 'undefined' ||
        envDetail.corrosion === 0
      ) {
        envDetail.corrosion = '-';
      }
      if (
        envDetail.peelFlake === null ||
        envDetail.peelFlake === 'null' ||
        envDetail.peelFlake === undefined ||
        envDetail.peelFlake === 'undefined' ||
        envDetail.peelFlake === 0
      ) {
        envDetail.peelFlake = '-';
      }
      if (
        envDetail.colorFade === null ||
        envDetail.colorFade === 'null' ||
        envDetail.colorFade === undefined ||
        envDetail.colorFade === 'undefined' ||
        envDetail.colorFade === 0
      ) {
        envDetail.colorFade = '-';
      }
      if (
        envDetail.dirtStain === null ||
        envDetail.dirtStain === 'null' ||
        envDetail.dirtStain === undefined ||
        envDetail.dirtStain === 'undefined' ||
        envDetail.dirtStain === 0
      ) {
        envDetail.dirtStain = '-';
      }
      if (
        envDetail.corrosionWall === null ||
        envDetail.corrosionWall === 'null' ||
        envDetail.corrosionWall === undefined ||
        envDetail.corrosionWall === 'undefined' ||
        envDetail.corrosionWall === 0
      ) {
        envDetail.corrosionWall = '-';
      }
      // console.log('handleDisplayEnvDetail', item, envDetail);
      return envDetail;
    },
    handleReplaceData: (item) => {
      const {
        data,
        envDetail: propsEnvDetail,
        customerAddress,
        dataDetail,
        customerPhone,
        projectInsDate,
        dataGroupList,
        isWallTemplateOnly,
      } = this.props;
      const {
        userAuth,
        isLanguageEn,
        attachmentPageLimit,
      } = this.state;
      const segmentName = isLanguageEn
        ? _.get(data, 'segmentSelected.segmentNameEN', '')
        : _.get(data, 'segmentSelected.segmentNameTH', '');
      const subSegmentName = isLanguageEn
        ? _.get(data, 'segmentSelected.subSegmentNameEN', '')
        : _.get(data, 'segmentSelected.subSegmentNameTH', '');
      const envDetail = this.warrantyCMSTemplate.handleDisplayEnvDetail(propsEnvDetail);

      // const isEnglish = language === DATA.LANGUAGE.ENGLISH;
      // item = item.replaceAll('[company_name]', isEnglish? `NS BlueScope (Thailand) Company Limited`: `บริษัท เอ็นเอส บลูสโคป (ประเทศไทย) จำกัด`);
      // CR: 1498
      item = item.replaceAll('[product_group]', dataDetail.oldProductGroupName);
      // item = item.replaceAll('[product_group]', dataDetail.productGroupName);
      item = item.replaceAll('[customer_name]', userAuth.company);
      item = item.replaceAll('[customer_project_name]', data.projectName);
      item = item.replaceAll('[installation_address]', customerAddress);

      // CR: 1497
      // if subSegment == 'Farm & Cattle'
      if ((ENUM.SPECIAL_SEGMENT.some(e => e.EN === subSegmentName || e.TH === subSegmentName)) && data.projectCountry === ENUM.COUNTRY_TYPE.THAI) {
        if (DATA.WARRANTY_YEAR.find(e => e.m3ProductGroups.find(pg => pg === dataDetail.m3ProductGroup))) {
          const warrantyYear = DATA.WARRANTY_YEAR.find(e => e.m3ProductGroups.find(pg => pg === dataDetail.m3ProductGroup)) || {};
          item = this.warrantyCMSTemplate.handleReplaceWarrantySpecial(item, warrantyYear, isLanguageEn);
        }

      }

      // CR: 1498
      else if (DATA.M3_PRODUCT_GROUP_1498.some(e => e.m3ProductGroup.includes(dataDetail.m3ProductGroup))) {
        if (dataDetail.isWallSelected) {
          envDetail.corrosion = envDetail.corrosionWall;
        }
        item = this.warrantyCMSTemplate.handleReplaceExtendedYearWarranty(item, envDetail, isLanguageEn);
      }

      item = item.replaceAll('[perforate_by_corrosion_year]', envDetail.corrosion)

      // CR: remove merged roof and wall for thai
      item = item.replaceAll('[perforate_by_corrosion_year_wall]', envDetail.corrosionWall)

      item = item.replaceAll('[peel_or_flake_excessively_year]', envDetail.peelFlake);
      item = item.replaceAll('[colour_fade_excessively_year]', envDetail.colorFade);
      item = item.replaceAll('[discolour_by_dirtstaining_year]', envDetail.dirtStain);

      // TODO: change
      item = item.replaceAll(
        '[structural_failure_by_corrosion_year]',
        envDetail.corrosion
      );


      item = item.replaceAll(
        '[additional_project_information]',
        data.customerRemark
      );

      // for preview template
      item = item.replaceAll('[attachment]', '[attachment1]');

      let cntCoilId = 0;
      dataGroupList.forEach((e) => {
        cntCoilId += e.displayCoil.length;
      });

      let totalAttachmentPage = Math.ceil(cntCoilId / attachmentPageLimit);
      for (let i = 0; i < totalAttachmentPage; i++) {
        item = item.replaceAll(
          `[attachment${i + 1}]`,
          data.isGenerateResidential
            ? this.warrantyCMSTemplate.renderAttachmentRes(i)
            : this.warrantyCMSTemplate.renderAttachmentNonRes(i)
        );
      }
      item = item.replaceAll(
        '[application_detail]',
        data.isGenerateResidential
          ? this.warrantyCMSTemplate.renderApplicationDetailRes()
          : this.warrantyCMSTemplate.renderApplicationDetailNonRes()
      );
      item = item.replaceAll('[main_segment]', segmentName);
      item = item.replaceAll('[sub_segment]', subSegmentName);

      item = item.replaceAll('[warranty_number]', dataDetail.wrtNo);
      item = item.replaceAll('[customer_project_phone]', customerPhone);
      item = item.replaceAll('[installation_date]', projectInsDate);
      item = item.replaceAll('[dealer_name]', data.dealerName);
      item = item.replaceAll('[dealer_phone]', data.dealerPhone);
      item = item.replaceAll('[dealer_address]', data.dealerAddress);
      item = item.replaceAll(
        '[warranty_created_date]',
        data.displayCreatedDate
      );

      return item;
    },
    handleDataDetail: (item, option = {}) => {
      const { isLanguageEn } = this.state;
      const dataDetail = isLanguageEn
        ? {
          wrtTempDtHeaderImage: item.wrtTempDtHeaderEnImage,
          wrtTempDtBody: item.wrtTempDtBodyEn,
          wrtTempDtBodyLeft: item.wrtTempDtBodyLeftEn,
          wrtTempDtBodyRigth: item.wrtTempDtBodyRigthEn,
          wrtTempDtNote: item.wrtTempDtNoteEn,
          wrtTempDtFooterImage: item.wrtTempDtFooterEnImage,
        }
        : {
          wrtTempDtHeaderImage: item.wrtTempDtHeaderThImage,
          wrtTempDtBody: item.wrtTempDtBodyTh,
          wrtTempDtBodyLeft: item.wrtTempDtBodyLeftTh,
          wrtTempDtBodyRigth: item.wrtTempDtBodyRigthTh,
          wrtTempDtNote: item.wrtTempDtNoteTh,
          wrtTempDtFooterImage: item.wrtTempDtFooterThImage,
        };

      if (option.isSegment) {
        dataDetail.wrtTempDtNote = this.warrantyCMSTemplate.handleReplaceData(
          dataDetail.wrtTempDtNote
        );
      }
      else {
        dataDetail.wrtTempDtBody = this.warrantyCMSTemplate.handleReplaceData(
          dataDetail.wrtTempDtBody
        );
        dataDetail.wrtTempDtBodyLeft = this.warrantyCMSTemplate.handleReplaceData(
          dataDetail.wrtTempDtBodyLeft
        );
        dataDetail.wrtTempDtBodyRigth =
          this.warrantyCMSTemplate.handleReplaceData(
            dataDetail.wrtTempDtBodyRigth
          );
        dataDetail.wrtTempDtNote = this.warrantyCMSTemplate.handleReplaceData(
          dataDetail.wrtTempDtNote
        );
      }
      return dataDetail;
    },
    // CR-1498
    replaceUnusedCode: (item = '', isLanguageEn) => {
      const replaceItem = (matcher = '', index = 0, item = '') => {
        if (index === 0 && !item.includes('[warranty_special]<br>')) {
          item = item.replaceAll(matcher, '[warranty_special]<br>');
        } else {
          item = item.replaceAll(matcher, '');
        }

        return item;
      }
      // CR: 1497
      const patternEn = /-(?:&nbsp;|\s|)+Shall not .*? for/g;
      const isTemplateEn = !!item.match(patternEn);


      if (isLanguageEn) {
        const pattern1 = /-(?:&nbsp;|\s|)+Shall not .*? for:.*?(?:Years|Year|Year\(s\)|ปี)(<br>|\s<br>|\s)/g;
        const pattern2 = /- Shall not perforate by corrosion for:\s*(?:&nbsp;|\s)*Roof\s*(?:&nbsp;|\s)*<strong style="font-size:\s*12px;">\[perforate_by_corrosion_year\]\s*<\/strong>\s*Years<br>\s*(?:&nbsp;|\s)*Wall\s*(?:&nbsp;|\s)*<strong>\[perforate_by_corrosion_year_wall\]<\/strong>\s*Years<br>/g;
        const pattern3 = /-(&nbsp;|\s)+Shall not perforate by corrosion for.*?(?:Years|Year|Year\(s\)|ปี)/g;
        const pattern4 = /-(&nbsp;|\s)+Shall not perforate by color peeling for.*?(?:Years|Year|Year\(s\)|ปี)/g;
        const pattern5 = /-(&nbsp;|\s)+Shall not colour fade excessively for.*?(?:Years|Year|Year\(s\)|ปี)/g;
        const pattern6 = /-(&nbsp;|\s)+Shall not discolour by dirt staining for.*?(?:Years|Year|Year\(s\)|ปี)/g;

        const dirtStainPattern = /-(\s|&nbsp;)*Shall not discolour by dirt staining for:\s*<strong>\[([^\]]+)\]<\/strong>\s*Year\(s\)/g;
        const matches1 = item.match(pattern1);
        const matches2 = item.match(pattern2);
        const matches3 = item.match(pattern3);
        const matches4 = item.match(pattern4);
        const matches5 = item.match(pattern5);
        const matches6 = item.match(pattern6);
        const dirtStainMatcher = item.match(dirtStainPattern);

        if (dirtStainMatcher) {
          dirtStainMatcher.forEach((m, i) => {
            item = replaceItem(m, i, item);
          });
        }
        if (matches2) {
          matches2.forEach((m, i) => {
            item = replaceItem(m, i, item);
          });
        }
        if (matches1) {
          matches1.forEach((m, i) => {
            item = replaceItem(m, i, item);
          });
        }
        if (matches3) {
          matches3.forEach((m, i) => {
            item = replaceItem(m, i, item);
          });
        }
        if (matches4) {
          matches4.forEach((m, i) => {
            item = replaceItem(m, i, item);
          });
        }
        if (matches5) {
          matches5.forEach((m, i) => {
            item = replaceItem(m, i, item);
          });
        }
        if (matches6) {
          matches6.forEach((m, i) => {
            item = replaceItem(m, i, item);
          });
        }

      } else {
        const pattern1 = /- .*?:(?:&nbsp;\s*)*<strong>\s*\[.*?\]\s*<\/strong>\s*ปี\s*<br>/g;
        const pattern2 = /-(\s|&nbsp;).*?:(\s|&nbsp;)*.*\[.*?\].*(\s|&nbsp;)*ปี<br>/g;
        const pattern3 = /-(?:&nbsp;|\s|)+Shall not .*? for:.*?(?:Years|Year|Year\(s\)|ปี)(<br>|\s<br>|\s)/g;
        const pattern4 = /-(&nbsp;|\s)+สีไม่ซีดจางหรือไม่หลุดเป็นผงอย่างรุนแรง.*?ปี.*?<br>/g;
        const pattern5 = /-(&nbsp;|\s)+การไม่เกิดรอยด่างจากคราบสะสมของฝุ่น.*?ปี/g;
        const pattern6 = /-(&nbsp;|\s)+การไม่หลุดล่อนหรือหลุดเป็นเกล็ดของสี.*?ปี/g;
        const pattern7 = /-(&nbsp;|\s)+การไม่ผุเป็นรูอันเนื่องมาจากการกัดกร่อน.*?ปี/g;
        const pattern8 = /-(&nbsp;|\s)+Shall not discolour by dirt staining for.*?(?:Years|Year|Year\(s\)|ปี)/g;

        item = replaceItem(`- สีไม่ซีดจางหรือไม่หลุดเป็นผงอย่างรุนแรง:&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;<strong>[colour_fade_excessively_year]</strong><strong>&nbsp;</strong>ปี  <br>`, 0, item);
        item = replaceItem(`- Shall not perforate by corrosion for:&nbsp; &nbsp;หลังคา&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;<strong>[perforate_by_corrosion_year]</strong> Years&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;<br>`, 0, item);
        item = replaceItem(`- การไม่เกิดรอยด่างจากคราบสะสมของฝุ่น: <strong>[discolour_by_dirtstaining_year]</strong> ปี`, 0, item);

        const matches1 = item.match(pattern1);
        const matches2 = item.match(pattern2);
        const matches3 = item.match(pattern3);
        const matches4 = item.match(pattern4);
        const matches5 = item.match(pattern5);
        const matches6 = item.match(pattern6);
        const matches7 = item.match(pattern7);
        const matches8 = item.match(pattern8);

        if (matches1) {
          matches1.forEach((m, i) => {
            item = replaceItem(m, i, item);
          });
        }
        if (matches2) {
          matches2.forEach((m, i) => {
            item = replaceItem(m, i, item);
          });
        }
        if (matches3) {
          matches3.forEach((m, i) => {
            item = replaceItem(m, i, item);
          });
        }
        if (matches4) {
          matches4.forEach((m, i) => {
            item = replaceItem(m, i, item);
          });
        }
        if (matches5) {
          matches5.forEach((m, i) => {
            item = replaceItem(m, i, item);
          });
        }
        if (matches6) {
          matches6.forEach((m, i) => {
            item = replaceItem(m, i, item);
          });
        }
        if (matches7) {
          matches7.forEach((m, i) => {
            item = replaceItem(m, i, item);
          });
        }
        if (matches8) {
          matches8.forEach((m, i) => {
            item = replaceItem(m, i, item);
          });
        }
      }

      return {
        item,
        isTemplateEn,
      };
    },
    // CR-1498
    handleReplaceExtendedYearWarranty: (item = '', warrantyYear = {
      corrosion: null,
      peelFlake: null,
      colorFade: null,
      dirtStain: null,
    }, isLanguageEn) => {
      const {
        dataDetail
      } = this.props;
      const dataTemplate = DATA.M3_PRODUCT_GROUP_1498_DISPLAY_YEAR_LIST.filter(e => e.m3ProductGroup.indexOf(dataDetail.m3ProductGroup) >= 0)[0] || {
        isCorrosion: true,
        isPeelFlake: true,
        isColorFade: true,
        isDirtStain: true,
      }
      console.log('dataTemplate', dataTemplate)
      const replaceItem = this.warrantyCMSTemplate.replaceUnusedCode(item, isLanguageEn);
      item = replaceItem.item;
      const isTemplateEn = replaceItem.isTemplateEn;

      const array = [];
      const yearSuffix = isTemplateEn ? `Years` : 'ปี';
      if (
        dataTemplate.isCorrosion &&
        warrantyYear.corrosion &&
        warrantyYear.corrosion !== '-'
      ) {
        const text = isTemplateEn ? `- Shall not perforate by corrosion for:` : `- การไม่ผุเป็นรูอันเนื่องมาจากการกัดกร่อน: &nbsp;`;
        array.push(`
          ${text}
          &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;<strong style="font-size: 12px"
            >${warrantyYear.corrosion}</strong
          >&nbsp;${yearSuffix}
          `);
      }
      if (
        dataTemplate.isPeelFlake &&
        warrantyYear.peelFlake &&
        warrantyYear.peelFlake !== '-'
      ) {
        const text = isTemplateEn ? `- Shall not perforate by color peeling for:` : `- การไม่หลุดร่อนหรือหลุดเป็นเกล็ดของสี: &nbsp; &nbsp; &nbsp;`;
        array.push(`
          ${text}&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
          &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
          <strong style="font-size: 12px"
            >${warrantyYear.peelFlake}</strong
          >&nbsp;${yearSuffix}
          `);
      }
      if (
        dataTemplate.isColorFade &&
        warrantyYear.colorFade &&
        warrantyYear.colorFade !== '-'
      ) {
        const text = isTemplateEn ? `- Shall not colour fade excessively for:` : `- สีไม่ซีดจางหรือไม่หลุดเป็นผงอย่างรุนแรง:`;
        array.push(`
          ${text}&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
          &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
          <strong style="font-size: 12px"
            >${warrantyYear.colorFade}</strong
          >&nbsp;${yearSuffix}
          `);
      }
      if (
        dataTemplate.isDirtStain &&
        warrantyYear.dirtStain &&
        warrantyYear.dirtStain !== '-'
      ) {
        const text = isTemplateEn ? `- Shall not discolour by dirt staining for:` : `- การไม่เกิดรอยด่างจากคราบสะสมของฝุ่น: &nbsp;`;
        array.push(`
          ${text}&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
          &nbsp; &nbsp; &nbsp; &nbsp;
          &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
          <strong style="font-size: 12px"
            >${warrantyYear.dirtStain}</strong
          >&nbsp;${yearSuffix}
          `);
      }

      item = item.replaceAll('[warranty_special]', array.join('<br>'));

      return item;
    },
    // CR-1497
    handleReplaceWarrantySpecial: (item = '', warrantyYear = {
      roof: null,
      wall: null,
      ceiling: null,
      wallInterior: null,
      trussAndFrameAndPurlin: null,
    }, isLanguageEn) => {
      const replaceItem = this.warrantyCMSTemplate.replaceUnusedCode(item, isLanguageEn);
      item = replaceItem.item
      const isTemplateEn = replaceItem.isTemplateEn;
      const array = [];
      const yearSuffix = isTemplateEn ? `Years` : 'ปี';
      if (warrantyYear.roof) {
        const text = isTemplateEn ? `- Shall not perforate by corrosion for Roof(Exterior):` : `- การไม่ผุเป็นรูอันเนื่องมาจากการกัดกร่อนสำหรับหลังคา(ภายนอก):`;
        array.push(`
          ${text}
          &nbsp; &nbsp; &nbsp;<strong style="font-size: 12px"
            >${warrantyYear.roof}</strong
          >&nbsp;${yearSuffix}
          `);
      }
      if (warrantyYear.wall) {
        const text = isTemplateEn ? `- Shall not perforate by corrosion for Wall(Exterior):` : `- การไม่ผุเป็นรูอันเนื่องมาจากการกัดกร่อนสำหรับผนัง(ภายนอก): &nbsp; &nbsp;`;
        array.push(`
          ${text}
          &nbsp; &nbsp; &nbsp;<strong style="font-size: 12px"
            >${warrantyYear.wall}</strong
          >&nbsp;${yearSuffix}
          `);
      }
      if (warrantyYear.wallInterior) {
        const text = isTemplateEn ? `- Shall not perforate by corrosion for Wall(Interior):&nbsp;` : `- การไม่ผุเป็นรูอันเนื่องมาจากการกัดกร่อนสำหรับผนัง(ภายใน):&nbsp; &nbsp; &nbsp; &nbsp;`;
        array.push(`
          ${text}
          &nbsp; &nbsp;
          <strong style="font-size: 12px"
            >${warrantyYear.wallInterior}</strong
          >&nbsp;${yearSuffix}
          `);
      }
      if (warrantyYear.ceiling) {
        const text = isTemplateEn ? `- Shall not perforate by corrosion for Ceiling:&nbsp;` : `- การไม่ผุเป็นรูอันเนื่องมาจากการกัดกร่อนสำหรับฝ้า/เพดาน: `;
        array.push(`
          ${text}
          &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
          <strong style="font-size: 12px"
            >${warrantyYear.ceiling}</strong
          >&nbsp;${yearSuffix}
          `);
      }
      if (warrantyYear.trussAndFrameAndPurlin) {
        const text = isTemplateEn ? `- Shall not structural failure by corrosion for:` : `- โครงสร้างไม่พังอันเนื่องมาจากการกัดกร่อน: &nbsp; &nbsp;`
        array.push(`
          ${text}
          &nbsp; &nbsp; &nbsp; &nbsp;
          <strong style="font-size: 12px"
            >${warrantyYear.trussAndFrameAndPurlin}</strong
          >&nbsp;${yearSuffix}
          `);
      }

      item = item.replaceAll('[warranty_special]', array.join('<br>'));

      return item;
    },
    // handleRoofAndWallTemplate: (item, envDetail) => {
    //   const {
    //     dataDetail
    //   } = this.props;
    //   const {
    //     isLanguageEn,
    //   } = this.state;
    //   if (item.match(/\[perforate_by_corrosion_year\]/)) {
    //     const regExYearPattern = /\[perforate_by_corrosion_year\].*?(Year\(s\)|Years|Year|ปี)/i;
    //     let mathCorrosionYear = item.match(regExYearPattern);
    //     let labelYear = '';
    //     if (mathCorrosionYear) {
    //       labelYear = mathCorrosionYear[1];
    //       item = item.replaceAll(mathCorrosionYear[0], mathCorrosionYear[0].replaceAll(mathCorrosionYear[1], ''));
    //     }

    //     const template = `
    //         <span style="position: relative">
    //           <span style="font-weight: normal; padding-right: 5px;">${isLanguageEn ? 'Roof' : 'หลังคา'} 
    //             <span style="margin-left: 24px;">
    //               <b>${envDetail.corrosion}</b>
    //             </span> ${labelYear ? labelYear : 'Years'}
    //           </span>
    //           <span style="font-weight: normal; position: absolute; top: 16px; left: 0px">${isLanguageEn ? 'Wall' : 'ผนัง'} 
    //             <span style="margin-left:${isLanguageEn ? 26 : 38}px;">
    //               <b>${envDetail.corrosionWall}</b>
    //             </span> ${labelYear ? labelYear : 'Years'}
    //           </span>
    //         </span>
    //         <br>
    //       `;
    //     return item.replaceAll('[perforate_by_corrosion_year]', template);
    //   }
    //   else {
    //     return item.replaceAll('[perforate_by_corrosion_year]', envDetail.corrosion);
    //   }
    // },
  };

  render() {
    const {
      data,
      warrantyDetail,
      dataDetail,
      customerAddress,
      setComponentTemplateRef,
      isWaterMarkPreviewWarrantyTemplate,
      isPreviewTemplate,
      dataGroupList,
    } = this.props;
    const {
      isLanguageEn,
      language,
    } = this.state;
    const isShowWaterMarkPreview = dataDetail.wrtNo === '-' && !isWaterMarkPreviewWarrantyTemplate;
    let qrCodeValueSegment = '';
    return (
      <WarrantyGeneratePdfPage isHidden={false} isPreview={isPreviewTemplate}>
        {!data.isGenerateResidential ? (
          // Non Residential (Project)
          <React.Fragment>
            {/* Page : Element for Loop */}
            {warrantyDetail.nonResDetailList.map((e, i) => {
              qrCodeValueSegment = e.qrCodeValue;
              const item = this.warrantyCMSTemplate.handleDataDetail(e);
              return (
                <WarrantyGeneratePdfPage.List key={i}>
                  <div className='wgpp-container is-space-between'>
                    <div className='wgpp-section-top'>
                      <div className='wgpp-header is-full-page'>
                        {handleDisplayImage(item.wrtTempDtHeaderImage) &&
                          <img
                            src={handleDisplayImage(item.wrtTempDtHeaderImage)}
                            alt='Header'
                          />
                        }
                        <div className='wgpp-warranty-number'>
                          <span className='wgpp-text is-bold is-white'>
                            {isLanguageEn
                              ? 'Warranty No.'
                              : 'เลขที่ใบรับประกัน'}
                            : {dataDetail.wrtNo}
                          </span>
                        </div>
                      </div>
                      <div className='wgpp-body'>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: item.wrtTempDtBody,
                          }}
                        />
                        {
                          // CR: 1491
                          warrantyDetail.wrtWatermarkLogoImageFile && (
                            <div className='wgpp-mark is-watermark-bs-res'>
                              <img
                                src={handleDisplayImage(warrantyDetail.wrtWatermarkLogoImageFile)}
                                alt='watermark'
                                width={400}
                              />
                            </div>
                          )
                        }

                        {isShowWaterMarkPreview && (
                          <div className='wgpp-mark'>
                            <span className='wgpp-text is-bold is-preview'>
                              PREVIEW
                            </span>
                          </div>
                        )}
                      </div>
                      {this.warrantyCMSTemplate.getWatermarkComponent()}
                    </div>
                    <div className='wgpp-section-bottom'>
                      <div className='wgpp-note'>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: item.wrtTempDtNote,
                          }}
                        />
                      </div>
                      {isPreviewTemplate && (
                        <div className={`wgpp-image is-footer ${!handleDisplayImage(item.wrtTempDtFooterImage) && 'is-display-none'}`}>
                          {/* !!warranty-public-qr-code */}

                          <span className='wgpp-text-issue-date is-date is-white'> {moment().format('DD/MM/YYYY')}</span>
                          <Image
                            className='wgpp-image-created-verify'
                            src={isLanguageEn ? CONTENTS['footer-created-verify-qr-landscape-en.png'] : CONTENTS['footer-created-verify-qr-landscape-th.png']}
                            widthRatio={160}
                            widthRatioUnit="px"
                            heightRatio={94}
                            heightRatioUnit="px"
                          />
                          {handleDisplayImage(item.wrtTempDtFooterImage) &&
                            <img
                              src={handleDisplayImage(item.wrtTempDtFooterImage)}
                              alt='Footer'
                            />
                          }
                          {e.qrCodeValue &&
                            <QrCode className='wgpp-qr' value={`${e.qrCodeValue}&lang=${language}`} size={72} renderAs="canvas" />
                          }
                        </div>
                      )}
                    </div>
                  </div>
                </WarrantyGeneratePdfPage.List>
              );
            })}
          </React.Fragment>
        ) : (
          // Residential
          <React.Fragment>
            {warrantyDetail.resDetailList.map((e, i) => {
              qrCodeValueSegment = e.qrCodeValue;
              const item = this.warrantyCMSTemplate.handleDataDetail(e);
              return (
                <WarrantyGeneratePdfPage.List key={i} className='is-space-bottom'>
                  <div className='wgpp-container'>
                    <div className='wgpp-header is-full-page'>
                      {handleDisplayImage(item.wrtTempDtHeaderImage) &&
                        <img
                          src={handleDisplayImage(item.wrtTempDtHeaderImage)}
                          alt='Header'
                        />
                      }
                    </div>
                    <div className='wgpp-body-center'>
                      <div
                        dangerouslySetInnerHTML={{ __html: item.wrtTempDtBody }}
                      />
                    </div>
                    <div className='wgpp-container-left-right'>
                      <div className='wgpp-body-left'>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: item.wrtTempDtBodyLeft,
                          }}
                        />
                      </div>
                      <div className='wgpp-body-right'>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: item.wrtTempDtBodyRigth,
                          }}
                        />
                      </div>
                    </div>
                    {
                      // CR: 1491
                      warrantyDetail.wrtWatermarkLogoImageFile && (
                        <div className='wgpp-mark is-watermark-bs-res'>
                          <img
                            src={handleDisplayImage(warrantyDetail.wrtWatermarkLogoImageFile)}
                            alt='watermark'
                            width={400}
                          />
                        </div>
                      )
                    }

                    {isShowWaterMarkPreview && (
                      <div className='wgpp-mark'>
                        <span className='wgpp-text is-bold is-preview'>
                          PREVIEW
                        </span>
                      </div>
                    )}
                    {this.warrantyCMSTemplate.getWatermarkComponent()}
                    {
                      isPreviewTemplate && (
                        <div className={`wgpp-footer-section is-full-page ${!handleDisplayImage(item.wrtTempDtFooterImage) && 'is-display-none'}`} >
                          {/* !!warranty-public-qr-code */}
                          <span className='wgpp-text-issue-date is-date is-white'> {moment().format('DD/MM/YYYY')}</span>
                          <Image
                            className='wgpp-image-created-verify'
                            src={isLanguageEn ? CONTENTS['footer-created-verify-qr-landscape-en.png'] : CONTENTS['footer-created-verify-qr-landscape-th.png']}
                            widthRatio={160}
                            widthRatioUnit="px"
                            heightRatio={94}
                            heightRatioUnit="px"
                          />
                          {handleDisplayImage(item.wrtTempDtFooterImage) &&
                            <img
                              src={handleDisplayImage(item.wrtTempDtFooterImage)}
                              alt='Footer'
                            />
                          }
                          {e.qrCodeValue &&
                            <QrCode className='wgpp-qr' value={`${e.qrCodeValue}&lang=${language}`} size={72} renderAs="canvas" />
                          }
                        </div>
                      )
                    }
                  </div>
                </WarrantyGeneratePdfPage.List>
              );
            })}
          </React.Fragment>
        )}

        {/* CR: 1491 */}
        <React.Fragment>
          {/* Page : Element for Loop */}
          {warrantyDetail.wrtSegmentDetailSelectedList.map((e, i) => {
            const item = this.warrantyCMSTemplate.handleDataDetail(e, {
              isSegment: true,
            });
            return (
              <WarrantyGeneratePdfPage.List key={`segment_${i}`}>
                <div className='wgpp-container is-space-between'>
                  <div className='wgpp-section-top'>
                    <div className='wgpp-header is-full-page'>
                      {handleDisplayImage(item.wrtTempDtHeaderImage) &&
                        <img
                          src={handleDisplayImage(item.wrtTempDtHeaderImage)}
                          alt='Header'
                        />
                      }
                      <div className='wgpp-warranty-number'>
                        <span className='wgpp-text is-bold is-white'>
                          {isLanguageEn
                            ? 'Warranty No.'
                            : 'เลขที่ใบรับประกัน'}
                          : {dataDetail.wrtNo}
                        </span>
                      </div>
                    </div>
                    <div className='wgpp-body'>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: item.wrtTempDtBody,
                        }}
                      />
                      {
                        // CR: 1491
                        warrantyDetail.wrtWatermarkLogoImageFile && (
                          <div className='wgpp-mark is-watermark-bs-res'>
                            <img
                              src={handleDisplayImage(warrantyDetail.wrtWatermarkLogoImageFile)}
                              alt='watermark'
                              width={400}
                            />
                          </div>
                        )
                      }

                      {isShowWaterMarkPreview && (
                        <div className='wgpp-mark'>
                          <span className='wgpp-text is-bold is-preview'>
                            PREVIEW
                          </span>
                        </div>
                      )}
                    </div>
                    {this.warrantyCMSTemplate.getWatermarkComponent()}
                  </div>
                  <div className='wgpp-section-bottom'>
                    <div className='wgpp-note'>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: item.wrtTempDtNote,
                        }}
                      />
                    </div>
                    {isPreviewTemplate && (
                      <div className={`wgpp-image is-footer ${!handleDisplayImage(item.wrtTempDtFooterImage) && 'is-display-none'}`}>
                        {/* !!warranty-public-qr-code */}

                        <span className='wgpp-text-issue-date is-date is-white'> {moment().format('DD/MM/YYYY')}</span>
                        <Image
                          className='wgpp-image-created-verify'
                          src={isLanguageEn ? CONTENTS['footer-created-verify-qr-landscape-en.png'] : CONTENTS['footer-created-verify-qr-landscape-th.png']}
                          widthRatio={160}
                          widthRatioUnit="px"
                          heightRatio={94}
                          heightRatioUnit="px"
                        />
                        {handleDisplayImage(item.wrtTempDtFooterImage) &&
                          <img
                            src={handleDisplayImage(item.wrtTempDtFooterImage)}
                            alt='Footer'
                          />
                        }
                        {qrCodeValueSegment &&
                          <QrCode className='wgpp-qr' value={`${qrCodeValueSegment}&lang=${language}`} size={72} renderAs="canvas" />
                        }
                      </div>
                    )}
                  </div>
                </div>
              </WarrantyGeneratePdfPage.List>
            );
          })}
        </React.Fragment>

        <DocumentaryTemplate
          ref={(r) => setComponentTemplateRef(r)}
          data={data}
          dataDetail={handleDocumentaryData(
            dataDetail,
            dataGroupList,
            DATA.WATERMARK_TEMPLATE_LIST[0].value,
            dataDetail.wrtNo && dataDetail.wrtNo !== '-'
          )}
          customerAddress={customerAddress}
          watermarkTemplateType={DATA.WATERMARK_TEMPLATE_LIST[0].value}
          // productGroupName={(dataDetail || {}).productGroupName}
          // CR: 1498
          productGroupName={(dataDetail || {}).oldProductGroupName}
        />
      </WarrantyGeneratePdfPage>
    );
  }
}
