import React from 'react';
import {
  connect,
} from 'react-redux';
import {
  Section,
  Grid,
  Modal,
  Text,
  Button,
  Field,
  PaginationControl,
} from './../../../components'
import {
  VARIABLES,
} from './../../../themes'
import { CustomerListContainerConnected } from '../../CustomerListContainer/CustomerListContainer';
import { ModalCustomerProfileLinkContainer, ModalCustomerProfileLinkContainerConnected } from '../ModalCustomerProfileLinkContainer';
import { customerController } from '../../../apiService/apiController/customerService';
import { isValidResponse, isValidPhoneNumber, isValidEmail } from '../../../helpers/functions/validation';
import { toast } from '../../../helpers/functions/main';
import { getUserList, getClearUserParams } from '../../../helpers/services/user';
import { getClearCustomerParams, getCustomerList, getCustomerManageParams } from '../../../helpers/services/customer';
import { storeDispatch, storeGetState } from '../../../stores';
import { dataCreators, DATA_REDUX_STATE_KEY } from '../../../actions/dataAction';
import { FIREBASE_PUBLIC_STORAGE_PATH } from '../../../config/config';
import { displayUrlPath } from '../../../helpers/functions/display';
import { bindActionCreators } from 'redux';
// import { MODAL_TYPE } from '../../../helpers/constants/main'
// import { MAIN_REDUX_STATE_KEY } from '../../../actions/mainAction'
import _ from 'lodash';
import { getLanguage } from '../../../helpers/functions/language';
import { redirectData } from '../../../helpers/functions/route';
import { ROUTE_PATH } from '../../../helpers/constants/route';
import { getPhoneRemovePrefix } from '../../../helpers/functions/data';

export class ModalCustomerProfileContainer extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isDisableSaveButton: true,
      dataPerPage: 10,
      filter: '',
      filterValue: '',
    };
  }

  componentDidMount() {
    const {
      customerData,
    } = this.props;
    if (customerData) {
      this.setState({
        customerData,
      });
    }
  }

  modal = {
    onClickCustomer: (data) => {
      this.setState({
        customerData: data,
      });
    },
    onClickSaveSelectedCustomer: async () => {
      const {
        onClickSave,
      } = this.props;
      const {
        customerData,
        isCreateCustomer,
        isCreateUser,
      } = this.state;
      if (customerData.phone) {
        if (isCreateCustomer || isCreateUser) {
          customerData.userRefId = customerData.userRefId === 'undefined' ? '' : customerData.userRefId;
          customerData.customerRefId = customerData.customerRefId === 'undefined' ? '' : customerData.customerRefId;
          console.log('isCreateNewCustomer', customerData);
          let params = getCustomerManageParams(customerData);
          if (isCreateUser) {
            params.user_ref_id = customerData.id;
            customerData.userRefId = customerData.id;
          }
          params.image_url = customerData.oldImageRef;
          const customerService = customerController();
          let res = await customerService.createCustomer(params);
          console.log('params=', params);
          if (isValidResponse(res)) {
            customerData.id = res.id;
            customerData.authorizeDealerId = params.authorize_dealer_id;
            console.log('customerData', customerData);
            onClickSave(customerData);
          }
          else {
            toast.error(res.message);
          }
        }
        else {
          if (!customerData.userRefId) {
            const params = {
              ...getClearUserParams(),
              page: 1,
              limit: 1,
              phone: customerData.phone,
              callbackSuccess: (dataList) => {
                if (dataList.length) {
                  // this.setState({
                  //   isOpenModalSyncCustomer: true,
                  // })
                  this.modal.onClickSaveSyncCustomer(dataList[0]);
                }
                else {
                  onClickSave(customerData);
                }
              }
            };
            getUserList(params);
          }
          else {
            onClickSave(customerData);
          }
        }
      }
      else {
        redirectData(`${ROUTE_PATH.CUSTOMERS_MANAGE.LINK}/${customerData.id}`, {
          data: customerData
        })
      }
    },
    onClickSaveSyncCustomer: async (userData) => {
      const {
        onClickSave,
      } = this.props;
      const {
        customerData,
      } = this.state;
      const data = customerData;
      let params = getCustomerManageParams(data, data.id)
      params.image_url = userData.imageUrl;
      params.user_ref_id = userData.id;
      params.only_user_ref_id = true;
      params.address = userData.oldIdAddress;
      params.contact_address = userData.oldContactAddress;
      const customerService = customerController();
      const res = await customerService.updateCustomerInfo(params);
      if (isValidResponse(res)) {
        data.userRefId = userData.id;
        data.oldImageRef = userData.imageUrl || '';
        data.imageUrl = displayUrlPath(data.oldImageRef);
        // toast.success(getLanguage('message.saveCompleted',''));
        onClickSave(data);
      }
      else {
        toast.error(res.message);
      }
    },
    onClickCloseModalSyncCustomer: () => {
      this.setState({
        isOpenModalSyncCustomer: false
      })
    },
    getAllCustomerList: () => {
      let {
        filter,
        dataPerPage,
      } = this.state;
      const params = {
        ...getClearCustomerParams(),
        page: 1,
        limit: dataPerPage,
        isAll: true,
        callbackSuccess: (dataList) => {
          if (dataList.length) {
            this.setState({
              isCreateCustomer: true,
            })
          }
          // this.pagination.getFilterDataList();
        }
      };
      filter = filter.trim();
      if (isValidPhoneNumber(filter)) {
        console.log('customer phone', filter);
        params.phone = filter;
      }
      else if (isValidEmail(filter)) {
        console.log('customer email', filter);
        params.email = filter;
      }
      else {
        console.log('customer searchFullname', filter);
        params.searchFullname = filter;
      }
      getCustomerList(params);
    },
    getAllUserList: () => {
      let {
        filter,
        dataPerPage,
      } = this.state;
      const params = {
        ...getClearUserParams(),
        page: 1,
        limit: dataPerPage,
        callbackSuccess: (dataList, dataParams) => {
          if (dataList.length) {
            this.setState({
              isCreateUser: true,
            });
            storeDispatch(dataCreators.setDataRedux(DATA_REDUX_STATE_KEY.CUSTOMER_LIST, dataList));
            storeDispatch(dataCreators.setDataRedux(DATA_REDUX_STATE_KEY.CUSTOMER_PARAMS, dataParams));
          }
          else {
            this.modal.getAllCustomerList();
          }
          // this.pagination.getFilterDataList();
        }
      };
      filter = filter.trim();
      if (isValidPhoneNumber(filter)) {
        filter = getPhoneRemovePrefix(filter);
        console.log('user phone', filter);
        params.phone = filter;
      }
      else if (isValidEmail(filter)) {
        console.log('user email', filter);
        params.email = filter;
      }
      else {
        console.log('user searchFullname', filter);
        params.searchFullname = filter;
      }
      getUserList(params);
    },
    onClickSort: (target) => {
      const {
        isCreateUser,
      } = this.state;
      if (isCreateUser) {
        getUserList({
          isNoSort: false,
          sort: target,
          callbackSuccess: (dataList, dataParams) => {
            storeDispatch(dataCreators.setDataRedux(DATA_REDUX_STATE_KEY.CUSTOMER_LIST, dataList));
            storeDispatch(dataCreators.setDataRedux(DATA_REDUX_STATE_KEY.CUSTOMER_PARAMS, dataParams));
          }
        });
      }
      else {
        getCustomerList({
          isNoSort: false,
          sort: target
        });
      }
    }
  }

  input = {
    onChangeInput: (ev) => {
      const name = ev.target.name;
      const value = ev.target.value;
      this.setState({
        [name]: value,
      })
    },
    onClickFilter: () => {
      let {
        filter,
      } = this.state;
      this.setState({
        // filterValue: filter,
        customerData: undefined,
        isCreateCustomer: false,
        isCreateUser: false,
      });
      const params = {
        ...getClearCustomerParams(),
        page: 1,
        callbackSuccess: (dataList) => {
          if (dataList.length === 0 && filter.length > 0) {
            this.modal.getAllUserList();
          }
          // this.pagination.getFilterDataList();
        }
      };

      filter = filter.trim();
      if (filter.split(' ').length > 1) {
        console.log('customer searchFullname', filter);
        params.searchFullname = filter;
      }
      else {
        console.log('customer all_field', filter);
        params.all_field = filter;
      }

      getCustomerList(params);
      // this.refCustomerList.customerList.handleClearIcon();
    },
  }

  pagination = {
    onPaginationChange: (pageNumber) => {
      const {
        isCreateUser,
      } = this.state;
      // const {
      //   dataActions,
      //   customerParams
      // } = this.props;
      // customerParams.activePage = pageNumber;
      // dataActions.setDataRedux(DATA_REDUX_STATE_KEY.CUSTOMER_PARAMS, customerParams)
      if (isCreateUser) {
        getUserList({
          isNoSort: true,
          page: pageNumber,
          callbackSuccess: (dataList, dataParams) => {
            storeDispatch(dataCreators.setDataRedux(DATA_REDUX_STATE_KEY.CUSTOMER_LIST, dataList));
            storeDispatch(dataCreators.setDataRedux(DATA_REDUX_STATE_KEY.CUSTOMER_PARAMS, dataParams));
          }
        });
      }
      else {
        getCustomerList({
          isNoSort: true,
          page: pageNumber,
        });
      }
    },
    // getFilterDataList: () => {
    //   const {
    //     dataList,
    //   } = this.props;
    //   const {
    //     filter,
    //   } = this.state;
    //   const filterValue = filter;
    //   if (filterValue.length) {
    //     return this.refCustomerList.customerList.getFilterDataList();
    //   }
    //   else {
    //     return dataList;
    //   }
    // },
  }

  render() {
    const {
      isOpenModal,
      onClickClose,
      customerParams,
      isCheckboxTick,
    } = this.props;
    const {
      filterValue,
      // isOpenModalSyncCustomer,
      customerData,
      dataPerPage,
    } = this.state;
    return (
      <React.Fragment>
        <Modal
          open={isOpenModal}
          scroll
          // enableOverlayClose={false}
          width={VARIABLES.MODAL.WIDTHS.W_1145}
          overlayColor={VARIABLES.COLORS.OVERLAY_BLACK_1}
          spacing={VARIABLES.MODAL.CONTAINER.C_1.SPACING}
          transition={VARIABLES.TRANSITIONS.DEFAULT}
          transformStart={VARIABLES.MODAL.CONTAINER.C_1.TRANSFORM_START}
          transformEnd={VARIABLES.MODAL.CONTAINER.C_1.TRANSFORM_END}
          onClickClose={onClickClose}
        >
          <Modal.Section
            paddingVertical='30'
            paddingHorizontal='40'
            bgColor={VARIABLES.COLORS.WHITE}
            borderBottomWidth='1'
            borderBottomStyle='solid'
            borderBottomColor={VARIABLES.COLORS.GRAY_8}
            borderTopRightRadius={VARIABLES.MODAL.HEADER.H_1.BORDER_TOP_RIGHT_RADIUS}
            borderTopLeftRadius={VARIABLES.MODAL.HEADER.H_1.BORDER_TOP_LEFT_RADIUS}
          >
            <Text
              regular16
              singleLine
              color={VARIABLES.COLORS.BLACK}
            >
              {getLanguage('modal.customerProfile.title', '')}
            </Text>
          </Modal.Section>
          <Modal.Section
            paddingTop='20'
            paddingBottom='30'
            paddingHorizontal='45'
            bgColor={VARIABLES.COLORS.WHITE}
          >
            <Section spacingBottom='25'>
              <Grid
                gutter='10'
                justify='flex-end'
              >
                <Grid.Column>
                  <Field
                    border
                    name='filter'
                    ui='blockLabelVerticalSearch'
                    width='350'
                    placeholder={getLanguage('modal.customerProfile.placeholder', '')}
                    // value={}
                    onChange={this.input.onChangeInput}
                  />
                </Grid.Column>
                <Grid.Column>
                  <Button
                    lightBlue
                    name={getLanguage('modal.customerProfile.buttonSearch', '')}
                    width={VARIABLES.BUTTON.WIDTHS.W_105}
                    onClick={this.input.onClickFilter}
                  />
                </Grid.Column>
              </Grid>
            </Section>
            <Section spacingBottom='30'>
              <CustomerListContainerConnected
                type='selectCustomer'
                innerRef={r => this.refCustomerList = r}
                customerData={customerData}
                filterValue={filterValue}
                onClickCustomer={this.modal.onClickCustomer}
                onClickSort={this.modal.onClickSort}
                isCheckboxTick={isCheckboxTick}
              />
            </Section>
            <Section justify='flex-end'>
              <PaginationControl
                activePage={customerParams.activePage}
                itemsCountPerPage={dataPerPage}
                // totalItemsCount={this.pagination.getFilterDataList().length}
                totalItemsCount={customerParams.totalData}
                pageRangeDisplayed={3}
                onChange={this.pagination.onPaginationChange}
              />
            </Section>
          </Modal.Section>
          <Modal.Section
            paddingVertical='30'
            paddingHorizontal='45'
            borderTopWidth={VARIABLES.MODAL.FOOTER.F_1.BORDER_TOP_WIDTH}
            borderTopStyle={VARIABLES.MODAL.FOOTER.F_1.BORDER_TOP_STYLE}
            borderTopColor={VARIABLES.COLORS.GRAY_8}
            borderBottomRightRadius={VARIABLES.MODAL.FOOTER.F_1.BORDER_BOTTOM_RIGHT_RADIUS}
            borderBottomLeftRadius={VARIABLES.MODAL.FOOTER.F_1.BORDER_BOTTOM_LEFT_RADIUS}
          >
            <Grid
              gutter='15'
              justify='flex-end'
            >
              <Grid.Column>
                <Button
                  gray
                  width={VARIABLES.BUTTON.WIDTHS.W_155}
                  name={getLanguage('modal.customerProfile.buttonCancel', '')}
                  onClick={onClickClose}
                />
              </Grid.Column>
              <Grid.Column>
                <Button
                  blue
                  disabled={!customerData}
                  width={VARIABLES.BUTTON.WIDTHS.W_155}
                  name={customerData ?
                    customerData.phone ?
                      getLanguage('modal.customerProfile.buttonSelect', '')
                      :
                      getLanguage('field.buttonEditProfile', '')
                    :
                    getLanguage('modal.customerProfile.buttonSelect', '')
                  }
                  onClick={this.modal.onClickSaveSelectedCustomer}
                />
              </Grid.Column>
            </Grid>
          </Modal.Section>
        </Modal>
        {/* {
          isOpenModalSyncCustomer &&
          <ModalCustomerProfileLinkContainerConnected isOpenModal
            onClickClose={this.modal.onClickCloseModalSyncCustomer}
            onClickSave={this.modal.onClickSaveSyncCustomer}
          />
        } */}
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => {
  const dataRedux = state.dataRedux;
  return {
    dataList: dataRedux.customerList,
    customerParams: dataRedux.customerParams,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    dataActions: bindActionCreators(dataCreators, dispatch),
  }
}

export const ModalCustomerProfileContainerConnected = connect(
  mapStateToProps,
  mapDispatchToProps
)(ModalCustomerProfileContainer)