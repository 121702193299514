import ClassNames from 'classnames'
import _ from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'
import { DATA } from '../../../helpers/constants/data'
import { getLanguageSpecific } from '../../../helpers/functions/language'
import {
  Grid,
  Section,
  ShapeContainer,
  Text,
} from './../../../components'
import {
  ICONS,
  LOGOS,
  VARIABLES,
} from './../../../themes'
import { WarrantyBSWMStatusWrapper } from './styled'
/**
 * TemplateBase description:
 * - TemplateBase
 */


export class WarrantyBSWMStatus extends React.PureComponent {

  warrantyBSWMStatus = {
    getLanguage: (target) => {
      const {
        language
      } = this.props;
      return getLanguageSpecific(target, language)
    },
    getApplicationText: (name) => {
      switch (name) {
        case DATA.WARRANTY_APP_DETAIL_NAME_LIST[0]:
          return this.warrantyBSWMStatus.getLanguage('warrantyStatus.roof')
        case DATA.WARRANTY_APP_DETAIL_NAME_LIST[1]:
          return this.warrantyBSWMStatus.getLanguage('warrantyStatus.wall')
        case DATA.WARRANTY_APP_DETAIL_NAME_LIST[2]:
          return this.warrantyBSWMStatus.getLanguage('warrantyStatus.purlinGirt')
        case DATA.WARRANTY_APP_DETAIL_NAME_LIST[3]:
          return this.warrantyBSWMStatus.getLanguage('warrantyStatus.truFrame')
        case DATA.WARRANTY_APP_DETAIL_NAME_LIST[4]:
          return this.warrantyBSWMStatus.getLanguage('warrantyStatus.coolRoom')
        case DATA.WARRANTY_APP_DETAIL_NAME_LIST[5]:
          return this.warrantyBSWMStatus.getLanguage('warrantyStatus.decking')
        case DATA.WARRANTY_APP_DETAIL_NAME_LIST[6]:
          return this.warrantyBSWMStatus.getLanguage('warrantyStatus.airDucting')
        default:
          return ''
      }
    }
  }

  render() {
    const {
      className,
      warrantyNo,
      projectName,
      projectAddress,
      isWarranty,
      isWarrantyDocumentary,
      isCertificate,
      data,
      isWarrantyTemplate,
    } = this.props

    // props for css classes
    const classes = ClassNames(
      'warranty-bswms-status',
      // {'is-modifier-name': modifierName},
      className
    )

    const applicationList = _.get(data, 'applicationSelectList', []);

    return (
      <WarrantyBSWMStatusWrapper className={classes}>
        <div className='warranty-bswms-status-container'>
          <Section justify='center' spacingBottom='20'>
            <img src={LOGOS['logo-bluescope.png']} width='133' height='75' alt='Logo' />
          </Section>
          <Section justify='center' spacingBottom='30'>
            <Text bold16 color={VARIABLES.COLORS.PRIMARY_2} align='center'>
              {(
                isWarranty ||
                isWarrantyTemplate
              ) ? this.warrantyBSWMStatus.getLanguage('warrantyStatus.warrantyStatusChecker') : (
                (
                  isWarrantyDocumentary ||
                  isCertificate
                ) ?
                  this.warrantyBSWMStatus.getLanguage('warrantyStatus.certificateStatusChecker')
                  :
                  this.warrantyBSWMStatus.getLanguage('warrantyStatus.warrantyCertificateChecker')
              )}
            </Text>
          </Section>
          <Section justify='center' spacingBottom='30'>
            <img src={ICONS['ic-status-checked.png']} alt='Icon' />
          </Section>
          {
            (
              isWarranty ||
              isWarrantyTemplate
            ) ?
              <ShapeContainer fluid padding='30' bgColor={VARIABLES.COLORS.GRAY_28} borderWidth='1' borderStyle='solid' borderColor={VARIABLES.COLORS.GRAY_3} borderRadius='6'>
                <Section spacingBottom='5'>
                  <Text regular12 color={VARIABLES.COLORS.PRIMARY_2}>
                    {this.warrantyBSWMStatus.getLanguage('warrantyStatus.warrantyNo')}
                  </Text>
                </Section>
                <Section spacingBottom='20'>
                  <Text regular14 color={VARIABLES.COLORS.BLACK}>
                    {isWarrantyTemplate ? 'N/a' : data.wrtNo}
                  </Text>
                </Section>
                <Section spacingBottom='5'>
                  <Text regular12 color={VARIABLES.COLORS.PRIMARY_2}>
                    {this.warrantyBSWMStatus.getLanguage('warrantyStatus.projectName')}
                  </Text>
                </Section>
                <Section spacingBottom='20'>
                  <Text regular14 color={VARIABLES.COLORS.BLACK}>
                    {isWarrantyTemplate ? 'N/a' : data.projectName}
                  </Text>
                </Section>
                <Section spacingBottom='5'>
                  <Text regular12 color={VARIABLES.COLORS.PRIMARY_2}>
                    {this.warrantyBSWMStatus.getLanguage('warrantyStatus.projectAddressShort')}
                  </Text>
                </Section>
                <Section spacingBottom='20'>
                  <Text regular14 color={VARIABLES.COLORS.BLACK}>
                    {isWarrantyTemplate ? 'N/a' : data.displayAddress}
                  </Text>
                </Section>
                <Section spacingBottom='5'>
                  <Text regular12 color={VARIABLES.COLORS.PRIMARY_2}>
                    {this.warrantyBSWMStatus.getLanguage('warrantyStatus.locationAccount')}
                  </Text>
                </Section>
                <Section spacingBottom='20'>
                  <Text regular14 color={VARIABLES.COLORS.BLACK}>
                    {data.dealerName}
                  </Text>
                </Section>
                <Section spacingBottom='5'>
                  <Text regular12 color={VARIABLES.COLORS.PRIMARY_2}>
                    {this.warrantyBSWMStatus.getLanguage('warrantyStatus.phoneNo')}
                  </Text>
                </Section>
                <Section spacingBottom='20'>
                  <Text regular14 color={VARIABLES.COLORS.BLACK}>
                    {data.dealerPhone || '-'}
                  </Text>
                </Section>
                <Section spacingBottom='5'>
                  <Text regular12 color={VARIABLES.COLORS.PRIMARY_2}>
                    {this.warrantyBSWMStatus.getLanguage('warrantyStatus.productGroup')}
                  </Text>
                </Section>
                <Section spacingBottom='20'>
                  <Text regular14 color={VARIABLES.COLORS.BLACK}>
                    {isWarrantyTemplate ? 'N/a' : data.productGroup}
                  </Text>
                </Section>
                <Section spacingBottom='20' >
                  <ShapeContainer
                    fluid bgColor={VARIABLES.COLORS.WHITE} borderWidth='1' borderStyle='solid' borderColor={VARIABLES.COLORS.BLUE_16} borderRadius='6'
                  >
                    <Section
                      padding='15'
                      borderBottomWidth={0}
                      borderBottomStyle='solid'
                      borderBottomColor={VARIABLES.COLORS.BLUE_16}
                    >
                      {
                        data.wrtCat === DATA.WARRANTY_CATEGORY_TYPE.RESIDENTIAL.TYPE ? (
                          <Section>
                            <Grid justify='space-between' gutter='5'>
                              <Grid.Column>
                                <Text bold12 color={VARIABLES.COLORS.PRIMARY_2}>
                                  {this.warrantyBSWMStatus.getLanguage('warrantyStatus.length')}
                                </Text>
                              </Grid.Column>
                              <Grid.Column>
                                <Text regular12 color={VARIABLES.COLORS.BLACK}>
                                  {data.coilOrderMCal} {this.warrantyBSWMStatus.getLanguage('warrantyStatus.metre')}
                                </Text>
                              </Grid.Column>
                            </Grid>
                          </Section>
                        ) : (
                          <React.Fragment>
                            <Section spacingBottom='10'>
                              <Grid justify='space-between' gutter='5'>
                                <Grid.Column>
                                  <Section spacingBottom='5'>
                                    <Text bold12 color={VARIABLES.COLORS.PRIMARY_2}>
                                      {this.warrantyBSWMStatus.getLanguage('warrantyStatus.usability')}
                                    </Text>
                                  </Section>
                                </Grid.Column>
                                <Grid.Column>
                                  <Section spacingBottom='5'>
                                    <Text bold12 color={VARIABLES.COLORS.PRIMARY_2}>
                                      {this.warrantyBSWMStatus.getLanguage('warrantyStatus.metre')}
                                    </Text>
                                  </Section>
                                </Grid.Column>
                              </Grid>
                            </Section>
                            {applicationList.map((e, i) => {
                              return (
                                <Section key={i} spacingBottom={i < applicationList.length - 1 ? 10 : undefined}>
                                  <Grid justify='space-between' gutter='5'>
                                    <Grid.Column>
                                      <Text regular12 color={VARIABLES.COLORS.PRIMARY_2}>
                                        {this.warrantyBSWMStatus.getApplicationText(e.name)}
                                      </Text>
                                    </Grid.Column>
                                    <Grid.Column>
                                      <Text regular12 color={VARIABLES.COLORS.BLACK}>
                                        {e.value}
                                      </Text>
                                    </Grid.Column>
                                  </Grid>
                                </Section>
                              )
                            })}
                          </React.Fragment>
                        )
                      }
                    </Section>
                  </ShapeContainer>
                </Section>
              </ShapeContainer>
              : isWarrantyDocumentary ?
                <ShapeContainer fluid padding='30' bgColor={VARIABLES.COLORS.GRAY_28} borderWidth='1' borderStyle='solid' borderColor={VARIABLES.COLORS.GRAY_3} borderRadius='6'>
                  <Section spacingBottom='5'>
                    <Text regular12 color={VARIABLES.COLORS.PRIMARY_2}>
                      {this.warrantyBSWMStatus.getLanguage('warrantyStatus.certificateNo')}
                    </Text>
                  </Section>
                  <Section spacingBottom='20'>
                    <Text regular14 color={VARIABLES.COLORS.BLACK}>
                      {data.wrtDocNo}
                    </Text>
                  </Section>
                  <Section spacingBottom='5'>
                    <Text regular12 color={VARIABLES.COLORS.PRIMARY_2}>
                      {this.warrantyBSWMStatus.getLanguage('warrantyStatus.projectName')}
                    </Text>
                  </Section>
                  <Section spacingBottom='20'>
                    <Text regular14 color={VARIABLES.COLORS.BLACK}>
                      {data.projectName}
                    </Text>
                  </Section>
                  <Section spacingBottom='5'>
                    <Text regular12 color={VARIABLES.COLORS.PRIMARY_2}>
                      {this.warrantyBSWMStatus.getLanguage('warrantyStatus.projectAddressShort')}
                    </Text>
                  </Section>
                  <Section spacingBottom='20'>
                    <Text regular14 color={VARIABLES.COLORS.BLACK}>
                      {data.displayAddress}
                    </Text>
                  </Section>
                  <Section spacingBottom='5'>
                    <Text regular12 color={VARIABLES.COLORS.PRIMARY_2}>
                      {this.warrantyBSWMStatus.getLanguage('warrantyStatus.productGroup')}
                    </Text>
                  </Section>
                  <Section spacingBottom='20'>
                    <Text regular14 color={VARIABLES.COLORS.BLACK}>
                      {data.productGroup}
                    </Text>
                  </Section>
                  <Section spacingBottom='5'>
                    <Text regular12 color={VARIABLES.COLORS.PRIMARY_2}>
                      {this.warrantyBSWMStatus.getLanguage('warrantyStatus.locationAccount')}
                    </Text>
                  </Section>
                  <Section spacingBottom='20'>
                    <Text regular14 color={VARIABLES.COLORS.BLACK}>
                      {data.company}
                    </Text>
                  </Section>
                </ShapeContainer>
                : isCertificate ?
                  <ShapeContainer fluid padding='30' bgColor={VARIABLES.COLORS.GRAY_28} borderWidth='1' borderStyle='solid' borderColor={VARIABLES.COLORS.GRAY_3} borderRadius='6'>
                    <Section spacingBottom='5'>
                      <Text regular12 color={VARIABLES.COLORS.PRIMARY_2}>
                        {this.warrantyBSWMStatus.getLanguage('warrantyStatus.certificateNo')}
                      </Text>
                    </Section>
                    <Section spacingBottom='20'>
                      <Text regular14 color={VARIABLES.COLORS.BLACK}>
                        {data.displayCode}
                      </Text>
                    </Section>
                    <Section spacingBottom='5'>
                      <Text regular12 color={VARIABLES.COLORS.PRIMARY_2}>
                        {this.warrantyBSWMStatus.getLanguage('warrantyStatus.projectName')}
                      </Text>
                    </Section>
                    <Section spacingBottom='20'>
                      <Text regular14 color={VARIABLES.COLORS.BLACK}>
                        {data.projectName}
                      </Text>
                    </Section>
                    <Section spacingBottom='5'>
                      <Text regular12 color={VARIABLES.COLORS.PRIMARY_2}>
                        {this.warrantyBSWMStatus.getLanguage('warrantyStatus.projectAddressShort')}
                      </Text>
                    </Section>
                    <Section spacingBottom='20'>
                      <Text regular14 color={VARIABLES.COLORS.BLACK}>
                        {data.displayAddress}
                      </Text>
                    </Section>
                    <Section spacingBottom='5'>
                      <Text regular12 color={VARIABLES.COLORS.PRIMARY_2}>
                        {this.warrantyBSWMStatus.getLanguage('warrantyStatus.productGroup')}
                      </Text>
                    </Section>
                    <Section spacingBottom='20'>
                      <Text regular14 color={VARIABLES.COLORS.BLACK}>
                        {data.productGroup}
                      </Text>
                    </Section>
                    <Section spacingBottom='5'>
                      <Text regular12 color={VARIABLES.COLORS.PRIMARY_2}>
                        {this.warrantyBSWMStatus.getLanguage('warrantyStatus.locationAccount')}
                      </Text>
                    </Section>
                    <Section spacingBottom='20'>
                      <Text regular14 color={VARIABLES.COLORS.BLACK}>
                        {data.company}
                      </Text>
                    </Section>
                  </ShapeContainer>
                  :
                  <ShapeContainer fluid padding='30' bgColor={VARIABLES.COLORS.GRAY_28} borderWidth='1' borderStyle='solid' borderColor={VARIABLES.COLORS.GRAY_3} borderRadius='6'>
                    <Section spacingBottom='5'>
                      <Text regular12 color={VARIABLES.COLORS.PRIMARY_2}>
                        {this.warrantyBSWMStatus.getLanguage('warrantyStatus.warrantyNo')}
                      </Text>
                    </Section>
                    <Section spacingBottom='20'>
                      <Text regular14 color={VARIABLES.COLORS.BLACK}>
                        {warrantyNo}
                      </Text>
                    </Section>
                    <Section spacingBottom='5'>
                      <Text regular12 color={VARIABLES.COLORS.PRIMARY_2}>
                        {this.warrantyBSWMStatus.getLanguage('warrantyStatus.projectName')}
                      </Text>
                    </Section>
                    <Section spacingBottom='20'>
                      <Text regular14 color={VARIABLES.COLORS.BLACK}>
                        {projectName}
                      </Text>
                    </Section>
                    <Section spacingBottom='5'>
                      <Text regular12 color={VARIABLES.COLORS.PRIMARY_2}>
                        {this.warrantyBSWMStatus.getLanguage('warrantyStatus.projectAddress')}
                      </Text>
                    </Section>
                    <Section>
                      <Text regular14 color={VARIABLES.COLORS.BLACK}>
                        {projectAddress}
                      </Text>
                    </Section>
                  </ShapeContainer>
          }
        </div >
      </WarrantyBSWMStatusWrapper >
    )
  }

  static defaultProps = {
    // * If you set new default props, you should create propsType for correct styleguide document.
  }

  static propTypes = { // TYPE > node, string, number, bool, array, object, symbol, func
    /**
    * Additional classes
    */
    className: PropTypes.string
  }
}
