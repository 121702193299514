import styled, {
  // css
} from 'styled-components'
import {
  default as VARIABLES
} from './../../../themes/styles/bases/variables'
// import {
//   default as TYPOGRAPHYS
// } from './../../../themes/styles/bases/typographys'
// import {
//   default as MIXINS
// } from './../../../themes/styles/helpers/mixins'

// Sample using image in style
// url(${require('./../../../themes/images/icons/icon-sample.svg')});

// Wrapper
// ============================================================
export const FieldRadioImageWrapper = styled.div`
  /* Parent
  ------------------------------- */
  position: relative;
  display: inline-block;

  /* Childrens
  ------------------------------- */
  .field-radio-image-value {
    cursor: pointer;
    opacity: 0;
    position: absolute;
    z-index: ${VARIABLES.Z_INDEXS.LV_1};
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .field-radio-image-label {
    transition: ${VARIABLES.TRANSITIONS.DEFAULT};
    display: block;
  }

  .field-radio-image-icon {
    display: inline-block;
  }

  /* Modifiers
  ------------------------------- */
  &.is-category {
    .field-radio-image-value {
      &:checked {
        + .field-radio-image-label {
          background-color: ${VARIABLES.COLORS.BLUE_31};
          border: 4px solid ${VARIABLES.COLORS.PRIMARY_2};
        }
      }
    }

    .field-radio-image-label {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 275px;
      height: 275px;
      border: 4px solid transparent;
      border-radius: 10px;
      text-align: center;
    }

    .field-radio-image-icon {
      width: auto;
      height: 180px;
      margin-top: -7px;
      margin-bottom: 15px;
    }
  }

  /* Media queries
  ------------------------------- */
`
