import React from 'react'
import ClassNames from 'classnames'
import PropTypes from 'prop-types'
import {
  Text,
  Grid,
} from './../../custom'
import {
  RewardRedemptionCardWrapper
} from './styled'
import {
  VARIABLES,
  ICONS,
} from './../../../themes'

/**
 * RewardRedemptionCard description:
 * - RewardRedemptionCard
 */

export class RewardRedemptionCard extends React.PureComponent {
  render() {
    const {
      className,
      onClickPreviewImage,
      src,
      alt,
      point,
      itemName,
      isDisabledMinusQuantity,
      isDisabledPlusQuantity,
      isDisabledMinusOnDemand,
      isDisabledPlusOnDemand,
      isQuantity,
      isOnDemand,
      quantity,
      quantityId,
      quantityName,
      quantityValue,
      quantityOnChange,
      onDemandId,
      onDemandName,
      onDemandValue,
      onDemandOnChange,
      onClickMinusQuantity,
      onClickAddQuantity,
      onClickMinusOnDemand,
      onClickAddOnDemand,
    } = this.props

    // props for css classes
    const classes = ClassNames(
      'reward-redemption-card',
      { 'is-disabled-minus-quantity': isDisabledMinusQuantity },
      { 'is-disabled-plus-quantity': isDisabledPlusQuantity },
      { 'is-disabled-minus-ondemand': isDisabledMinusOnDemand },
      { 'is-disabled-plus-ondemand': isDisabledPlusOnDemand },
      { 'is-single-form': (isQuantity && !isOnDemand) || (!isQuantity && isOnDemand) },
      className
    )

    return (
      <RewardRedemptionCardWrapper className={classes}>
        <div
          className='rrc-image'
          onClick={onClickPreviewImage}
        >
          <img src={src} alt={alt} />
          <div className='rrc-point'>
            <Text medium16 color={VARIABLES.COLORS.WHITE}>{point}</Text> <Text regular16 color={VARIABLES.COLORS.WHITE}>Points</Text>
          </div>
        </div>
        <div className='rrc-container'>
          <div className='rrc-name' title={itemName}>
            <Text bold16 color={VARIABLES.COLORS.BLACK}>{itemName}</Text>
          </div>
          <div className='rrc-control'>
            {isQuantity &&
              <div className='rrc-control-form'>
                <div className='rrc-control-label'>
                  <Text bold10 color={VARIABLES.COLORS.BLACK}>In-Stock:</Text>
                  <Text bold10 color={VARIABLES.COLORS.PRIMARY_2}>{quantity}</Text>
                </div>
                <div className='rrc-control-actions'>
                  <Grid gutter='2'>
                    <Grid.Column>
                      <div id='button-quantity-minus' className='rrc-button is-minus' onClick={onClickMinusQuantity}>
                        <img src={ICONS['ic-minus-white.svg']} alt='Icon' />
                      </div>
                    </Grid.Column>
                    <Grid.Column>
                      <input
                        className='rrc-input'
                        type='number'
                        placeholder='0'
                        id={quantityId}
                        name={quantityName}
                        value={quantityValue}
                        onChange={quantityOnChange}
                      />
                    </Grid.Column>
                    <Grid.Column>
                      <div id='button-quantity-plus' className='rrc-button is-plus' onClick={onClickAddQuantity}>
                        <img src={ICONS['ic-plus-white.svg']} alt='Icon' />
                      </div>
                    </Grid.Column>
                  </Grid>
                </div>
              </div>
            }
            {(isQuantity && isOnDemand) &&
              <div className='rrc-control-partition' />
            }
            {isOnDemand &&
              <div className='rrc-control-form'>
                <div className='rrc-control-label'>
                  <Text bold10 color={VARIABLES.COLORS.BLACK}>In-Stock:</Text>
                  <Text bold10 color={VARIABLES.COLORS.PRIMARY_2}>On Demand</Text>
                </div>
                <div className='rrc-control-actions'>
                  <Grid gutter='2'>
                    <Grid.Column>
                      <div id='button-ondemand-minus' className='rrc-button is-minus' onClick={onClickMinusOnDemand}>
                        <img src={ICONS['ic-minus-white.svg']} alt='Icon' />
                      </div>
                    </Grid.Column>
                    <Grid.Column>
                      <input
                        className='rrc-input'
                        type='number'
                        placeholder='0'
                        id={onDemandId}
                        name={onDemandName}
                        value={onDemandValue}
                        onChange={onDemandOnChange}
                      />
                    </Grid.Column>
                    <Grid.Column>
                      <div id='button-ondemand-plus' className='rrc-button is-plus' onClick={onClickAddOnDemand}>
                        <img src={ICONS['ic-plus-white.svg']} alt='Icon' />
                      </div>
                    </Grid.Column>
                  </Grid>
                </div>
              </div>
            }
          </div>
        </div>
      </RewardRedemptionCardWrapper>
    )
  }

  static defaultProps = {
    isQuantity: true,
    isOnDemand: true,
    src: ICONS['ic-placeholder-image.svg'],
    alt: 'Reward Redemption Image',
    point: '0',
    itemName: '-',
    quantity: '0',
  }

  static propTypes = { // TYPE > node, string, number, bool, array, object, symbol, func
    /**
    * Additional classes
    */
    className: PropTypes.string
  }
}
