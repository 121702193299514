import apiService from '../apiService'

const apiPath = '/log'

export const logController = (configService = {}) => {
  const service = apiService(configService)
  return {
    uploadTransactionSearchFile: (params) => {
      return service.post(`${apiPath}/uploadtransaction/search/file`, params)
    },
    uploadTransactionSearchList: (params) => {
      return service.post(`${apiPath}/uploadtransaction/search/list`, params)
    },
  }
}
