import React from "react";
import {
  ShapeContainer,
  Section,
  Grid,
  Field,
  Button,
  TitleSection,
  DatePickerControl,
  Text,
  FieldRadioPanel,
} from "./../../components";
import { VARIABLES } from "./../../themes";
import { FieldAddressContainerConnected } from "./../../containers/FieldAddressContainer";
import { ENUM } from "../../helpers/constants/enum";
import { storeGetState } from "../../stores";
import { MAIN_REDUX_STATE_KEY } from "../../actions/mainAction";
import _ from "lodash";
import { getLanguage } from "../../helpers/functions/language";
import { getUserAuthRedux } from "../../helpers/functions/auth";
import { DATE_PICKER_FORMAT, DATA } from "../../helpers/constants/data";
import { WarrantyManageInfoIndo } from "./WarrantyManageInfoIndo";
import { WarrantyManageInfoMalay } from "./WarrantyManageInfoMalay";
import moment from "moment";
import { isShowLoading } from "../../helpers/functions/main";

export class WarrantyManageInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userAuth: getUserAuthRedux(),
    };
  }

  warrantyManageInfo = {
    getInstallationMinDate: () => {
      const { data } = this.props;
      if (data.projectCountry === ENUM.COUNTRY_TYPE.INDONESIA) {
        return moment(data.createdDateTime).add(-12, "months")._d;
      } else {
        return data.createdDateTime;
      }
    },
    getInstallationMaxDate: () => {
      const { data } = this.props;
      if (data.projectCountry === ENUM.COUNTRY_TYPE.INDONESIA) {
        // return moment(data.createdDateTime).add(12, 'months')._d;
        return moment(data.createdDateTime)._d;
      } else {
        return moment(data.createdDateTime).add(6, "months")._d;
      }
    },
  };

  render() {
    const {
      data,
      onClickSave,
      onClickBack,
      onChangeDataInput,
      onChangePhoneCountry,
      onChangeAddress,
      onChangeDataDate,
      onClickSaveDraft,
      automaticEnvironmentDescription,
      isAutomaticEnv,
      onChangeIndustrialEstate,
      isShowIndustrialEstate,
      industrialEstateSelectOptionList,
      onChangeWarrantyDistance,
      isShowWarrantyDistance,
      automaticEnvironmentData,
    } = this.props;
    const { userAuth } = this.state;

    return (
      <ShapeContainer ui="contentStep" justify="space-between">
        <Section spacingBottom="45">
          <Section spacingBottom="45">
            <TitleSection
              name={getLanguage("warrantyManage.form.info.title", "")}
            />
          </Section>
          {false && userAuth.addressType === ENUM.COUNTRY_TYPE.INDONESIA ? (
            <WarrantyManageInfoIndo {...this.props} />
          ) : false && userAuth.addressType === ENUM.COUNTRY_TYPE.MALAYSIA ? (
            <WarrantyManageInfoMalay {...this.props} />
          ) : (
            <React.Fragment>
              <Section spacingBottom="30">
                <Grid>
                  <Grid.Column gridTablet="12" gridLaptop="7">
                    <Field
                      name="projectName"
                      fluid
                      required
                      error={data.isProjectNameError}
                      ui="blockLabelVerticalTextarea"
                      type="textarea"
                      height="130"
                      label={getLanguage("field.customerProjectName", "")}
                      placeholder={getLanguage(
                        "field.customerProjectNamePlaceholder",
                        ""
                      )}
                      message={
                        <Section
                          ui="hintBottomTextarea"
                          className={
                            data.isProjectNameError && "is-message-error"
                          }
                          hint={
                            data.isGenerateResidential
                              ? getLanguage("field.customerProjectNameHint", "")
                              : getLanguage(
                                "field.customerProjectNameHint500",
                                ""
                              )
                          }
                        />
                      }
                      value={data.projectName}
                      onChange={onChangeDataInput}
                    />
                  </Grid.Column>
                </Grid>
              </Section>
              <Section spacingBottom="30">
                <Grid>
                  <Grid.ResponsiveColumn ui="fieldPhone">
                    <Field
                      name="projectPhone"
                      fluid
                      required
                      disabled={data.isCanRegenerate}
                      // error
                      ui="blockLabelVerticalPhone"
                      label={getLanguage("field.mobilePhoneNo", "")}
                      placeholder={getLanguage(
                        "field.mobilePhoneNoPlaceholder",
                        ""
                      )}
                      message={
                        <Section
                          ui="hintBottomField"
                          hint={getLanguage("field.mobilePhoneNoHint", "")}
                        />
                      }
                      value={data.projectPhone}
                      onChange={onChangeDataInput}
                      selectedPhoneCountry={data.projectPhoneCountry}
                      onChangePhoneCountry={(selected) => {
                        onChangePhoneCountry(selected, "projectPhoneCountry");
                      }}
                    />
                  </Grid.ResponsiveColumn>
                </Grid>
              </Section>
              <Section spacingBottom="30">
                <Grid gutter="35">
                  <Grid.ResponsiveColumn ui="fieldSelect">
                    <DatePickerControl
                      fluid
                      disabled
                      label={getLanguage("field.purchaseDate", "")}
                      dateFormat={DATE_PICKER_FORMAT}
                      selected={data.createdDateTime}
                      onChange={() => { }}
                    />
                  </Grid.ResponsiveColumn>
                  {data.wrtCat ===
                    DATA.WARRANTY_CATEGORY_TYPE.RESIDENTIAL.TYPE && (
                      <Grid.ResponsiveColumn ui="fieldSelect">
                        <DatePickerControl
                          fluid
                          required
                          disabled={data.isCanRegenerate}
                          label={getLanguage("field.installationDate", "")}
                          placeholderText={getLanguage(
                            "field.installationDatePlaceholder",
                            ""
                          )}
                          dateFormat={DATE_PICKER_FORMAT}
                          selected={data.projectInsDateTime}
                          minDate={this.warrantyManageInfo.getInstallationMinDate()}
                          maxDate={this.warrantyManageInfo.getInstallationMaxDate()}
                          onChange={(selected) => {
                            onChangeDataDate(selected, "projectInsDateTime");
                          }}
                        />
                        <Section>
                          <Text regular10 color={VARIABLES.COLORS.GRAY_19}>
                            {getLanguage("field.installationDateHint", "")}
                          </Text>
                        </Section>
                      </Grid.ResponsiveColumn>
                    )}
                </Grid>
              </Section>
              {/* <Section spacingBottom='30'>
                    <Grid>
                      <Grid.Column
                        gridTablet='12'
                        gridDesktop='10'
                        gridLargeDesktop='8'
                      >
                        <Field name='projectAddr'
                          fluid
                          required
                          // error
                          ui='blockLabelVerticalTextarea'
                          label='Installation Address'
                          placeholder='Insert Installation Address'
                          value={data.projectAddr}
                          onChange={onChangeDataInput}
                        />
                      </Grid.Column>
                    </Grid>
                  </Section> */}
              <Section spacingBottom="30">
                <FieldAddressContainerConnected
                  thai={data.projectCountry === ENUM.COUNTRY_TYPE.THAI}
                  malaysia={data.projectCountry === ENUM.COUNTRY_TYPE.MALAYSIA}
                  vietnam={data.projectCountry === ENUM.COUNTRY_TYPE.VIETNAM}
                  indonesia={
                    data.projectCountry === ENUM.COUNTRY_TYPE.INDONESIA
                  }
                  laos={data.projectCountry === ENUM.COUNTRY_TYPE.LAOS}
                  // Address
                  requiredAddress={true}
                  disabledAddress={false}
                  labelAddress={getLanguage("field.installationAddress", "")}
                  placeholderAddress={getLanguage(
                    "field.installationAddressPlaceholder",
                    ""
                  )}
                  nameAddress="projectAddr"
                  valueAddress={data.projectAddr}
                  onChangeAddress={onChangeDataInput}
                  errorInstallationAddress={data.isProjectAddrError}
                  // Province
                  requiredProvince={true}
                  disabledProvince={false}
                  nameProvince="selectedProvince"
                  valueProvince={data.selectedProvince}
                  onChangeProvince={(selected) => {
                    if (data.projectCountry === ENUM.COUNTRY_TYPE.LAOS) {
                      onChangeDataInput(selected);
                    } else {
                      onChangeAddress(selected, "selectedProvince");
                    }
                  }}
                  // City
                  requiredCity={true}
                  disabledCity={false}
                  nameCity="selectedCity"
                  valueCity={data.selectedCity}
                  onChangeCity={(selected) => {
                    if (data.projectCountry === ENUM.COUNTRY_TYPE.LAOS) {
                      onChangeDataInput(selected);
                    } else {
                      onChangeAddress(selected, "selectedCity");
                    }
                  }}
                  // Subdistrict
                  requiredSubdistrict={true}
                  disabledSubdistrict={false}
                  nameSubdistrict="projectSubDist"
                  valueSubdistrict={data.projectSubDist}
                  onChangeSubdistrict={(selected) => {
                    if (data.projectCountry === ENUM.COUNTRY_TYPE.THAI) {
                      onChangeAddress(selected, "projectSubDist");
                    } else {
                      onChangeDataInput(selected);
                    }
                  }}
                  // District
                  requiredDistrict={true}
                  disabledDistrict={false}
                  isSearchableDistrict={false}
                  nameDistrict="projectDist"
                  valueDistrict={data.projectDist}
                  onChangeDistrict={onChangeDataInput}
                  // Alley
                  requiredAlley={false}
                  disabledAlley={false}
                  nameAlley="projectAlley"
                  valueAlley={data.projectAlley}
                  onChangeAlley={onChangeDataInput}
                  // Group
                  requiredGroup={false}
                  disabledGroup={false}
                  nameGroup="projectGroup"
                  valueGroup={data.projectGroup}
                  onChangeGroup={onChangeDataInput}
                  // Street
                  requiredStreet={false}
                  disabledStreet={false}
                  nameStreet="projectStreet"
                  valueStreet={data.projectStreet}
                  onChangeStreet={onChangeDataInput}
                  // Ward
                  requiredWard={false}
                  disabledWard={false}
                  nameWard="projectWard"
                  valueWard={data.projectWard}
                  onChangeWard={onChangeDataInput}
                  // PostalCode
                  // requiredPostalCode={userAuth.addressType === ENUM.COUNTRY_TYPE.MALAYSIA}
                  requiredPostalCode={false}
                  disabledPostalCode={false}
                  namePostalCode="projectPostCode"
                  valuePostalCode={data.projectPostCode}
                  onChangePostalCode={(selected) => {
                    if (data.projectCountry === ENUM.COUNTRY_TYPE.THAI) {
                      onChangeAddress(selected, "projectPostCode");
                    } else {
                      onChangeDataInput(selected);
                    }
                  }}
                  // Industrial Estate Name
                  isShowIndrustialEstate={
                    isAutomaticEnv &&
                    isShowIndustrialEstate
                  }
                  requiredIndrustialEstateName={true}
                  nameIndrustialEstateName="projectIndrustialEstateName"
                  isSearchableIndrustialEstateName={false}
                  valueIndrustialEstateName={data.selectedIndustrialEstate}
                  onChangeIndrustialEstateName={async (selected) => {
                    isShowLoading(true);
                    await onChangeIndustrialEstate(selected);
                    isShowLoading(false);
                  }}
                  optionIndrustialEstateName={industrialEstateSelectOptionList}
                  disabledIndrustialEstateName={false}
                  isShowWarrantyDistance={isShowWarrantyDistance}
                  automaticEnvironmentData={automaticEnvironmentData}
                  requiredWarrantyDistance={true}
                  nameWarrantyDistance="projectWarrantyDistance"
                  isSearchableWarrantyDistance={false}
                  valueWarrantyDistance={data.selectedWarrantyDistance}
                  onChangeWarrantyDistance={async (selected) => {
                    isShowLoading(true);
                    await onChangeWarrantyDistance(selected);
                    isShowLoading(false);
                  }}
                  optionWarrantyDistance={automaticEnvironmentData ? automaticEnvironmentData.warrantyDistanceSelectOptionList : []}
                  disabledWarrantyDistance={false}
                />
              </Section>
              {data.isGenerateResidential === false && (
                <Section>
                  <Grid>
                    <Grid.Column gridTablet="12" gridLaptop="7">
                      <Field
                        name="customerRemark"
                        fluid
                        error={data.isCustomerRemarkError}
                        disabled={data.isCanRegenerate}
                        ui="blockLabelVerticalTextarea"
                        type="textarea"
                        height="130"
                        label={getLanguage(
                          "field.additionalProjectInformation",
                          ""
                        )}
                        placeholder={getLanguage(
                          "field.additionalProjectInformationPlaceholder",
                          ""
                        )}
                        message={
                          <Section
                            ui="hintBottomTextarea"
                            className={
                              data.isCustomerRemarkError && "is-message-error"
                            }
                            hint={getLanguage(
                              "field.additionalProjectInformationHint",
                              ""
                            )}
                          />
                        }
                        value={data.customerRemark}
                        onChange={onChangeDataInput}
                      />
                    </Grid.Column>
                  </Grid>
                </Section>
              )}
            </React.Fragment>
          )}
          {(isAutomaticEnv && automaticEnvironmentData && automaticEnvironmentData.isShowEnv) && (
            <React.Fragment>
              <Section spacingBottom="45" spacingTop="45">
                <TitleSection
                  name={getLanguage("warrantyManage.form.info.title_env", "")}
                />
              </Section>
              <Section spacingBottom="30">
                <ShapeContainer
                  fluid
                  calcHeight="100%"
                  paddingHorizontal="30"
                  paddingVertical="35"
                  bgColor={VARIABLES.COLORS.WHITE}
                  borderWidth="1"
                  borderStyle="solid"
                  borderColor={VARIABLES.COLORS.GRAY_8}
                  borderRadius="5"
                >
                  <Section spacingBottom="15">
                    <FieldRadioPanel
                      isChecked={true}
                      label={
                        automaticEnvironmentDescription
                          ? automaticEnvironmentDescription
                          : automaticEnvironmentData.warrantyEnvironment
                      }
                      onChange={() => { }}
                    />
                  </Section>
                </ShapeContainer>
              </Section>
            </React.Fragment>
          )}
        </Section>
        <Section>
          <Grid gutter="15" gutterVertical="15" justify="flex-end">
            <React.Fragment>
              <Grid.Column>
                <Button
                  gray
                  ui="back"
                  name={
                    data.projectDocumentId
                      ? getLanguage("field.buttonProjectDocument", "")
                      : getLanguage("field.buttonBack", "")
                  }
                  onClick={onClickBack}
                />
              </Grid.Column>
              {data.isShowSaveDraft && (
                <Grid.Column>
                  <Button
                    lightBlue
                    name={getLanguage("field.buttonSaveAsDraft", "")}
                    onClick={onClickSaveDraft}
                  />
                </Grid.Column>
              )}
              <Grid.Column>
                <Button
                  blue
                  name={getLanguage("field.buttonSaveAndContinue", "")}
                  width={VARIABLES.BUTTON.WIDTHS.W_255}
                  onClick={onClickSave}
                />
              </Grid.Column>
            </React.Fragment>
          </Grid>
        </Section>
      </ShapeContainer>
    );
  }
}
