import { displayWithPrefix, displayDate } from "../helpers/functions/display";
import { ENUM } from "../helpers/constants/enum";
import { getCountryPhone, getPhoneRemovePrefix } from "../helpers/functions/data";
import _ from 'lodash';

export class ModelCMSContactUs {
  constructor(props = {}) {
    this.id = props.id;
    this.idWithPrefix = displayWithPrefix(props.id);
    this.companyName = props.company_name || '';
    this.address = props.address || '';
    this.latitude = props.latitude || 0;
    this.longitude = props.longitude || 0;
    this.hotlineNo = getPhoneRemovePrefix(props.hotline_no);
    this.hotlineNoCountry = getCountryPhone(props.hotline_no);
    this.phoneNo = getPhoneRemovePrefix(props.phone_no);
    this.phoneNoCountry = getCountryPhone(props.phone_no);
    this.faxNo = getPhoneRemovePrefix(props.fax_no);
    this.faxNoCountry = getCountryPhone(props.fax_no);
    this.websiteUrlList = props.website_url || [];
    this.status = props.status || '';
    this.isActive = props.status === ENUM.STATUS_ACTIVE.ACTIVE;
    this.createdAt = props.created_at || 0;
    this.displayCreatedAt = displayDate(props.created_at);
    this.app = props.app || '';
  }
}