import React from 'react';
// import {
//   connect,
// } from 'react-redux';
import {
  Section,
  Grid,
  Modal,
  Text,
  Button,
  Image,
  Field
} from '../../../components'
import {
  VARIABLES,
  ICONS,
} from '../../../themes'
// import { MODAL_TYPE } from '../../../helpers/constants/main'
// import { MAIN_REDUX_STATE_KEY } from '../../../actions/mainAction'
import _ from 'lodash';
import { getLanguage } from '../../../helpers/functions/language';

export class ModalCoilTransferDuplicateContainer extends React.Component {
  render() {
    const {
      isOpenModal,
      onClickClose,
      onClickConfirm,
      title,
      message,
    } = this.props
    return (
      <Modal
        open={isOpenModal}
        scroll
        width={VARIABLES.MODAL.WIDTHS.W_327}
        overlayColor={VARIABLES.COLORS.OVERLAY_BLACK_1}
        transition={VARIABLES.TRANSITIONS.DEFAULT}
        transformStart={VARIABLES.MODAL.CONTAINER.C_1.TRANSFORM_START}
        transformEnd={VARIABLES.MODAL.CONTAINER.C_1.TRANSFORM_END}
        onClickClose={onClickClose}
      >
        <Modal.Section
          paddingVertical='21'
          paddingHorizontal='24'
          bgColor={VARIABLES.COLORS.WHITE}
          borderBottomWidth='1'
          borderBottomStyle='solid'
          borderBottomColor={VARIABLES.COLORS.GRAY_8}
          borderTopRightRadius={VARIABLES.MODAL.HEADER.H_1.BORDER_TOP_RIGHT_RADIUS}
          borderTopLeftRadius={VARIABLES.MODAL.HEADER.H_1.BORDER_TOP_LEFT_RADIUS}
        >
          <Grid gutter='10'>
            <Grid.Column>
              <Image ui='iconInfo' />
            </Grid.Column>
            <Grid.Column>
              <Text
                regular16
                singleLine
                color={VARIABLES.COLORS.BLACK}
                top='2'
              >
                {title}
              </Text>
            </Grid.Column>
          </Grid>
        </Modal.Section>
        <Modal.Section
          paddingVertical={31}
          paddingHorizontal={21}
          bgColor={VARIABLES.COLORS.WHITE}
        >
          <Section>
            <Text
              bold14
              color={VARIABLES.COLORS.PRIMARY_2}
            >
              {message}
            </Text>
          </Section>
        </Modal.Section>
        <Modal.Section
          paddingVertical='14'
          paddingHorizontal='24'
          borderTopWidth={VARIABLES.MODAL.FOOTER.F_1.BORDER_TOP_WIDTH}
          borderTopStyle={VARIABLES.MODAL.FOOTER.F_1.BORDER_TOP_STYLE}
          borderTopColor={VARIABLES.COLORS.GRAY_8}
          borderBottomRightRadius={VARIABLES.MODAL.FOOTER.F_1.BORDER_BOTTOM_RIGHT_RADIUS}
          borderBottomLeftRadius={VARIABLES.MODAL.FOOTER.F_1.BORDER_BOTTOM_LEFT_RADIUS}
        >
          <Grid
            gutter='15'
            justify='flex-end'
          >
            <Grid.Column>
              <Button
                gray
                width={VARIABLES.BUTTON.WIDTHS.W_132}
                name={getLanguage('field.buttonCancel', '')}
                onClick={onClickClose}
              />
            </Grid.Column>
            <Grid.Column>
              <Button
                blue
                ui='frontIcon'
                name={getLanguage('field.buttonNewScan', '')}
                iconWidth='16'
                iconHeight='16'
                iconSrc={ICONS['ic-barcode-reader.svg']}
                width={VARIABLES.BUTTON.WIDTHS.W_132}
                onClick={onClickConfirm}
              />
            </Grid.Column>
          </Grid>
        </Modal.Section>
      </Modal>
    )
  }
}
