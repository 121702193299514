import React from 'react'
import ClassNames from 'classnames'
import PropTypes from 'prop-types'
import {
  FieldRadioImageWrapper
} from './styled'
// import {
// } from './../../../components'
// import {
//   default as VARIABLES
// } from './../../../themes/styles/bases/variables'
// import {
//   default as TYPOGRAPHYS
// } from './../../../themes/styles/bases/typographys'
// import {
//   default as UTILITIES
// } from './../../../themes/styles/helpers/utilities'

/**
 * FieldRadioImage description:
 * - FieldRadioImage
 */

export class FieldRadioImage extends React.PureComponent {
  render () {
    const {
      className,
      id,
      name,
      value,
      isChecked,
      onChange,
      srcIcon,
      label,
      isCategory
    } = this.props

    // props for css classes
    const classes = ClassNames(
      'field-radio-image',
      {'is-category': isCategory},
      className
    )

    return (
      <FieldRadioImageWrapper
        className={classes}
      >
        <input className='field-radio-image-value'
          type='radio'
          id={id}
          name={name}
          value={value}
          checked={isChecked}
          onChange={onChange}
        />
        <label className='field-radio-image-label'
          htmlFor={id}
        >
          <img
            className='field-radio-image-icon'
            src={srcIcon}
            alt='Icon'
          />
          {label}
        </label>
      </FieldRadioImageWrapper>
    )
  }

  static defaultProps = {
    // * If you set new default props, you should create propsType for correct styleguide document.
  }

  static propTypes = { // TYPE > node, string, number, bool, array, object, symbol, func
    /**
    * Additional classes
    */
    className: PropTypes.string
  }
}
