
import styled, {
  css
} from 'styled-components'
import {
  default as VARIABLES
} from './../../../themes/styles/bases/variables'
// import {
//   default as TYPOGRAPHYS
// } from './../../../themes/styles/bases/typographys'
// import {
//   default as MIXINS
// } from './../../../themes/styles/helpers/mixins'

// Sample using image in style
// url(${require('./../../../themes/images/icons/icon-sample.svg')});

// Wrapper
// ============================================================
export const TabWrapper = styled.div`
  /* Parent
  ------------------------------- */
  display: flex;
  width: 100%;

  /* Childrens
  ------------------------------- */
  .tab-custom-list {
    cursor: pointer;
    transition: ${VARIABLES.TRANSITIONS.DEFAULT};
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    min-height: 72px;
    border-radius: 10px 10px 0 0;

    &.is-active {
      background-color: ${VARIABLES.COLORS.BLUE_27};
    }

    &.is-disabled-click {
      cursor: auto;
    }
  }

  /* Modifiers
  ------------------------------- */
  &.is-warranty-category {
    margin: 0 0 -10px;

    .tab-custom-list {
      min-height: 82px;

      > .grid-base {
        margin: 0 0 10px;
      }

      &.is-active {
        background-color: ${VARIABLES.COLORS.BLUE_31};
      }
    }
  }

  &.is-theme-blue {
    .tab-custom-list {
      flex: none;
      min-height: 47px;
      padding: 0 15px;
      background-color: ${VARIABLES.COLORS.GRAY_1};
      border-radius: 0;

      > .grid-base {
        margin: 0 0 5px;
      }

      .text-base {
        &.is-tab-name {
          color: ${VARIABLES.COLORS.GRAY_12};
        }
      }

      &:first-child {
        border-radius: 5px 0 0 0;
      }

      &:last-child {
        border-radius: 0 5px 0 0;
      }

      &.is-active {
        background-color: ${VARIABLES.COLORS.PRIMARY_2};

        .text-base {
          &.is-tab-name {
            color: ${VARIABLES.COLORS.WHITE};
          }
        }
      }
    }
  }

  &.is-help-tab {
    .tab-custom-list {
      flex: none;
      min-height: 47px;
      padding: 0 15px;
      background-color: ${VARIABLES.COLORS.GRAY_8};
      border-radius: 0;

      > .grid-base {
        margin: 0 0 5px;
      }

      .text-base {
        &.is-tab-name {
          color: ${VARIABLES.COLORS.GRAY_1};
        }
      }

      &:first-child {
        border-radius: 8px 0 0 0;
      }

      &:last-child {
        border-radius: 0 8px 0 0;
      }

      &.is-active {
        background-color: ${VARIABLES.COLORS.PRIMARY_2};

        .text-base {
          &.is-tab-name {
            color: ${VARIABLES.COLORS.WHITE};
          }
        }
      }

      &.is-help-active {
        background-color: ${VARIABLES.COLORS.BLUE_22};
        border-bottom: 3px solid ${VARIABLES.COLORS.BLUE_20};
        .text-base {
            &.is-tab-name {
              color: ${VARIABLES.COLORS.WHITE};
            }
          }
      }
    }
  }

  &.is-watermark-category {
    margin: 10px 0;
    border-bottom: 2px solid ${VARIABLES.COLORS.GRAY_11};
    .tab-custom-list {
      min-height: 20px;
      transition: ${VARIABLES.TRANSITIONS.TRANSITION_1_MS};
      flex: none;
      padding: 0 10px;
      margin-bottom: -2px;
      border-bottom: 2px solid transparent;
      > .grid-base {
        margin: 0 0 10px;
      }
      &.is-active {
        border-bottom: 2px solid ${VARIABLES.COLORS.PRIMARY_2};
        background-color: transparent;
      }
    }
  }

  /* Media queries
  ------------------------------- */
`
