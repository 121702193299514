import { ENUM } from './enum';

const PERMISSION_LIST_1 = [ENUM.USER_ROLE.OWNER];

export const PERMISSION = {
  SIDE_MENU: {
    SALES_TRANSACTION: {
      SALE_HISTORY: PERMISSION_LIST_1,
    },
    REPORT: {
      ALL: PERMISSION_LIST_1,
    },
    SETTINGS: {
      ALL: PERMISSION_LIST_1,
    },
  },
  CUSTOMER: {
    BUTTON: {
      SALE_HISTORY: PERMISSION_LIST_1,
    },
  },
}

const PERMISSION_CUSTOMER_TYPE_LIST_1 = [
  ENUM.CUSTOMER_ROLE_TYPE.INDONESIA.DIRECT_ROLL_FORMER,
  ENUM.CUSTOMER_ROLE_TYPE.INDONESIA.LYSAGHT_CENTER,
  ENUM.CUSTOMER_ROLE_TYPE.INDONESIA.AD,
];

const PERMISSION_CUSTOMER_TYPE_LIST_2 = [
  ENUM.CUSTOMER_ROLE_TYPE.INDONESIA.DIRECT_ROLL_FORMER,
  ENUM.CUSTOMER_ROLE_TYPE.INDONESIA.LYSAGHT_CENTER,
  ENUM.CUSTOMER_ROLE_TYPE.INDONESIA.DISTRIBUTOR,
];

export const PERMISSION_CUSTOMER_TYPE = {
  INDONESIA: {
    SIDE_MENU: {
      INVENTORY: {
        // INVENTORY_MANAGEMENT: PERMISSION_CUSTOMER_TYPE_LIST_1,
        PROFILE_MAPPING: PERMISSION_CUSTOMER_TYPE_LIST_1,
        PROFILE_MAPPING_HISTORY: PERMISSION_CUSTOMER_TYPE_LIST_1,
        TRANSFER: PERMISSION_CUSTOMER_TYPE_LIST_2,
        TRANSFER_HISTORY: PERMISSION_CUSTOMER_TYPE_LIST_2,
      },
    },
  }
}

const PERMISSION_COUNTRY_TYPE_LIST_1 = [
  ENUM.COUNTRY_TYPE.MALAYSIA,
  ENUM.COUNTRY_TYPE.VIETNAM,
];

export const PERMISSION_COUNTRY_TYPE = {
  SIDE_MENU: {
    UPLOAD_TRANSACTION: PERMISSION_COUNTRY_TYPE_LIST_1
  }
}