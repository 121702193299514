import React from 'react'
import ClassNames from 'classnames'
import PropTypes from 'prop-types'
import {
  osName,
} from 'react-device-detect'
import {
  WarrantyWrapper
} from './styled'
import {
  Section,
  Text,
  Image,
  Grid,
  Table,
  ShapeContainer,
} from './../../../components'
import {
  VARIABLES,
  CONTENTS,
} from './../../../themes'

/**
 * Warranty description:
 * - Warranty
 */

class WarrantyTermsAndConditionsPrimaryList extends React.Component {
  render() {
    return (
      <React.Fragment>
        <Section spacingBottom={(osName === 'Mac OS') ? '0' : '-3'} paddingLeft='15'>
          <Grid>
            <Grid.Column flex='none'>
              <Section width='15'>
                <Text regular8 color={VARIABLES.COLORS.BLACK}>a)</Text>
              </Section>
            </Grid.Column>
            <Grid.Column flex='1'>
              <Text regular8 color={VARIABLES.COLORS.BLACK}>Tấmlợpcần luôn luôn đượcgiữ khô khi còn xếpchồng trong kiệntrước khi lắpdựng.Nhữngtấmbịướtcầnphảiđể riêng và lau khô nhằmgiảmthiểu nguy cơbịăn mòn do bịẩm.</Text>
            </Grid.Column>
          </Grid>
        </Section>
        <Section spacingBottom={(osName === 'Mac OS') ? '0' : '-3'} paddingLeft='15'>
          <Grid>
            <Grid.Column flex='none'>
              <Section width='15'>
                <Text regular8 color={VARIABLES.COLORS.BLACK}>b)</Text>
              </Section>
            </Grid.Column>
            <Grid.Column flex='1'>
              <Text regular8 color={VARIABLES.COLORS.BLACK}>Không cắttấmlợpbằng gió đá,bởi vì mạtsắtnhiệtđộ cao sẽ phá hủylớpmạ.</Text>
            </Grid.Column>
          </Grid>
        </Section>
        <Section spacingBottom={(osName === 'Mac OS') ? '0' : '-3'} paddingLeft='15'>
          <Grid>
            <Grid.Column flex='none'>
              <Section width='15'>
                <Text regular8 color={VARIABLES.COLORS.BLACK}>c)</Text>
              </Section>
            </Grid.Column>
            <Grid.Column flex='1'>
              <Text regular8 color={VARIABLES.COLORS.BLACK}>Trong quá trình lắpdựng,cầnloạibỏmạtsắt và các rác thải khác trên tấmlợpnhằmgiảmthiểu nguy cơăn mòn cụcbộ.</Text>
            </Grid.Column>
          </Grid>
        </Section>
      </React.Fragment>
    )
  }
}

class WarrantyTermsAndConditionsSecondaryList extends React.Component {
  render() {
    return (
      <React.Fragment>
        <Section spacingBottom={(osName === 'Mac OS') ? '0' : '-3'} paddingLeft='15'>
          <Grid>
            <Grid.Column flex='none'>
              <Section width='15'>
                <Text regular8 color={VARIABLES.COLORS.BLACK}>a)</Text>
              </Section>
            </Grid.Column>
            <Grid.Column flex='1'>
              <Text regular8 color={VARIABLES.COLORS.BLACK}>Hưhạicơhọc, hóa họchoặchưhại khác xảy ra trước, trong hay sau khi thi công.</Text>
            </Grid.Column>
          </Grid>
        </Section>
        <Section spacingBottom={(osName === 'Mac OS') ? '0' : '-3'} paddingLeft='15'>
          <Grid>
            <Grid.Column flex='none'>
              <Section width='15'>
                <Text regular8 color={VARIABLES.COLORS.BLACK}>b)</Text>
              </Section>
            </Grid.Column>
            <Grid.Column flex='1'>
              <Text regular8 color={VARIABLES.COLORS.BLACK}>Tác nhân hóa chất, khói, chấtthảirắnhoặc các chấtlỏng ngoài nướcmưa.</Text>
            </Grid.Column>
          </Grid>
        </Section>
        <Section spacingBottom={(osName === 'Mac OS') ? '0' : '-3'} paddingLeft='15'>
          <Grid>
            <Grid.Column flex='none'>
              <Section width='15'>
                <Text regular8 color={VARIABLES.COLORS.BLACK}>c)</Text>
              </Section>
            </Grid.Column>
            <Grid.Column flex='1'>
              <Text regular8 color={VARIABLES.COLORS.BLACK}>Các khu vực không thường xuyên được làm vệ sinh, hoặc có chu kỳ, giai đoạnẩmướt lâu dài do bịbắnnước,đọngnước, hay dộinướctươngtựnhư môi trường nhà tắm, phòng giặt...</Text>
            </Grid.Column>
          </Grid>
        </Section>
        <Section spacingBottom={(osName === 'Mac OS') ? '0' : '-3'} paddingLeft='15'>
          <Grid>
            <Grid.Column flex='none'>
              <Section width='15'>
                <Text regular8 color={VARIABLES.COLORS.BLACK}>d)</Text>
              </Section>
            </Grid.Column>
            <Grid.Column flex='1'>
              <Text regular8 color={VARIABLES.COLORS.BLACK}>Tích tụ lâu dài đất cát, tro bụi, phân bón, hoặc các chấtgiữẩm khác.</Text>
            </Grid.Column>
          </Grid>
        </Section>
        <Section spacingBottom={(osName === 'Mac OS') ? '0' : '-3'} paddingLeft='15'>
          <Grid>
            <Grid.Column flex='none'>
              <Section width='15'>
                <Text regular8 color={VARIABLES.COLORS.BLACK}>e)</Text>
              </Section>
            </Grid.Column>
            <Grid.Column flex='1'>
              <Text regular8 color={VARIABLES.COLORS.BLACK}>Các khu vựctiếp xúc với các vậtliệu không tương thích (ví dụ chì, đồng, các-bon/ than chì, hoặc thép không gỉ)hoặchứngnướcchảytừ các loạivậtliệu không tương thích này.</Text>
            </Grid.Column>
          </Grid>
        </Section>
        <Section spacingBottom={(osName === 'Mac OS') ? '0' : '-3'} paddingLeft='15'>
          <Grid>
            <Grid.Column flex='none'>
              <Section width='15'>
                <Text regular8 color={VARIABLES.COLORS.BLACK}>f)</Text>
              </Section>
            </Grid.Column>
            <Grid.Column flex='1'>
              <Text regular8 color={VARIABLES.COLORS.BLACK}>Không loạibỏ rác rưởihoặc/ và không đảmbảo thoát nướckểcảnướcngưngđọng trên bềmặtsảnphẩm.</Text>
            </Grid.Column>
          </Grid>
        </Section>
        <Section spacingBottom={(osName === 'Mac OS') ? '0' : '-3'} paddingLeft='15'>
          <Grid>
            <Grid.Column flex='none'>
              <Section width='15'>
                <Text regular8 color={VARIABLES.COLORS.BLACK}>g)</Text>
              </Section>
            </Grid.Column>
            <Grid.Column flex='1'>
              <Text bold8 color={VARIABLES.COLORS.BLACK}>Không loạibỏmạtsắt sinh ra do bắn vít, cắt tôn khỏi mái lợp sau khi lắpdựngtấmlợp mái.</Text>
            </Grid.Column>
          </Grid>
        </Section>
        <Section spacingBottom={(osName === 'Mac OS') ? '0' : '-3'} paddingLeft='15'>
          <Grid>
            <Grid.Column flex='none'>
              <Section width='15'>
                <Text regular8 color={VARIABLES.COLORS.BLACK}>h)</Text>
              </Section>
            </Grid.Column>
            <Grid.Column flex='1'>
              <Text regular8 color={VARIABLES.COLORS.BLACK}>Sựhưhỏngcủatấmlợp gây ra do tiếp xúc vớigỗẩm,gỗtươihoặcgỗđã qua xử lý.</Text>
            </Grid.Column>
          </Grid>
        </Section>
        <Section spacingBottom={(osName === 'Mac OS') ? '0' : '-3'} paddingLeft='15'>
          <Grid>
            <Grid.Column flex='none'>
              <Section width='15'>
                <Text regular8 color={VARIABLES.COLORS.BLACK}>i)</Text>
              </Section>
            </Grid.Column>
            <Grid.Column flex='1'>
              <Text regular8 color={VARIABLES.COLORS.BLACK}>Môi trườngbiển  (cách biển  trong  vòng  02km)  và  môi trườngnằm  ngoài  khu  thành thị,  nông  thôn  ôn  hòa  (cách  khu  công nghiệp trong vòng 02km).</Text>
            </Grid.Column>
          </Grid>
        </Section>
        <Section spacingBottom={(osName === 'Mac OS') ? '0' : '-3'} paddingLeft='15'>
          <Grid>
            <Grid.Column flex='none'>
              <Section width='15'>
                <Text regular8 color={VARIABLES.COLORS.BLACK}>j)</Text>
              </Section>
            </Grid.Column>
            <Grid.Column flex='1'>
              <Text regular8 color={VARIABLES.COLORS.BLACK}>Giông bão hoặc các thiên tai khác.</Text>
            </Grid.Column>
          </Grid>
        </Section>
      </React.Fragment>
    )
  }
}

export class WarrantyVietnam extends React.PureComponent {
  render() {
    const {
      className,
      ui,
      children,
      isBzacsHcInok,
      isBzacsInok,
      isBzacsBenLanh,
      isBzacsBenMau,
      id,
      adName,
      adAddress,
      adPhone,
      coilLot,
      lengthSold,
      ownerName,
      ownerPhone,
      installationAddress,
      purchaseDate,
      purchaseMonth,
      purchaseYear,
      productBrand,
    } = this.props

    // props for css classes
    const classes = ClassNames(
      'warranty is-vietnam',
      {'is-bzacs-hc-inok': isBzacsHcInok},
      {'is-bzacs-inok': isBzacsInok},
      {'is-bzacs-ben-lanh': isBzacsBenLanh},
      {'is-bzacs-ben-mau': isBzacsBenMau},
      className
    )

    return (
      <React.Fragment>
        {(() => {
          switch (ui) {
            case 'template':
              return (
                <WarrantyWrapper className={classes}>
                  <div className="warranty-header-group">
                    <div className="warranty-header">
                      {isBzacsHcInok &&
                        <img className='warranty-header-image' src={CONTENTS['warranty-header-vietnam-bzacs-hc-inok.png']} alt="Header" />
                      }
                      {isBzacsInok &&
                        <img className='warranty-header-image' src={CONTENTS['warranty-header-vietnam-bzacs-inok.png']} alt="Header" />
                      }
                      {isBzacsBenLanh &&
                        <img className='warranty-header-image' src={CONTENTS['warranty-header-vietnam-bzacs-ben-lanh.png']} alt="Header" />
                      }
                      {isBzacsBenMau &&
                        <img className='warranty-header-image' src={CONTENTS['warranty-header-vietnam-bzacs-ben-mau.png']} alt="Header" />
                      }
                    </div>
                    <div className="warranty-subheader">
                      <Grid justify='space-between'>
                        <Grid.Column>
                          <Text bold16 color={VARIABLES.COLORS.WHITE}>CHỨNGNHẬNBẢO HÀNH</Text>
                        </Grid.Column>
                        <Grid.Column>
                          <Text bold16 color={VARIABLES.COLORS.WHITE}>No: {id}</Text>
                        </Grid.Column>
                      </Grid>
                    </div>
                  </div>
                  <div className="warranty-content">
                    <Section spacingBottom='3'>
                      <Text bold16 color={VARIABLES.COLORS.BLACK}>SẢNPHẨMBẢO HÀNH:&nbsp;</Text>
                      <Text regular16 color={VARIABLES.COLORS.BLACK}>
                        {isBzacsHcInok && 'BLUESCOPE ZACS®+ HOA CƯƠNG CÔNG NGHỆ INOK™' }
                        {isBzacsInok && 'BLUESCOPE ZACS®+ CÔNG NGHỆ INOK™' }
                        {isBzacsBenLanh && 'BLUESCOPE ZACS® BỀNLẠNH' }
                        {isBzacsBenMau && 'BLUESCOPE ZACS®BỀN MÀU' }
                      </Text>
                    </Section>
                    <Section>
                      <Text bold12 color={VARIABLES.COLORS.BLACK}>Tên đại lý uỷquyền:&nbsp;</Text>
                      <Text regular12 color={VARIABLES.COLORS.BLACK}>
                        {adName}
                      </Text>
                    </Section>
                    <Section spacingBottom='6'>
                      <Text bold12 color={VARIABLES.COLORS.BLACK}>Địachỉđại lý:&nbsp;</Text>
                      <Text regular12 color={VARIABLES.COLORS.BLACK}>
                        {adAddress}&nbsp;&nbsp;&nbsp;&nbsp;
                      </Text>
                      <Text bold12 color={VARIABLES.COLORS.BLACK}>Điệnthoại liên hệ:&nbsp;</Text>
                      <Text regular12 color={VARIABLES.COLORS.BLACK}>
                        {adPhone}
                      </Text>
                    </Section>
                    {isBzacsHcInok &&
                      <React.Fragment>
                        <Section spacingBottom='3'>
                          <Text bold12 color={VARIABLES.COLORS.BLACK}>Nội dung bảo hành: (theo điềukhoảnsố 9)</Text>
                        </Section>
                        <Section spacingBottom='3'>
                          <Table fluid warrantyVietnam>
                            <Table.Section>
                              <Table.Row>
                                <Table.Column className='is-body-column' alignCenter maxWidth='367' minWidth='367' padding='5'>
                                  <Text bold12 singleLine color={VARIABLES.COLORS.WHITE}>CHỐNGĂN MÒN THỦNG</Text>
                                </Table.Column>
                                <Table.Column className='is-body-column' alignCenter maxWidth='367' minWidth='367' padding='5'>
                                  <Text regular12 singleLine color={VARIABLES.COLORS.BLACK}>20 NĂM</Text>
                                </Table.Column>
                              </Table.Row>
                              <Table.Row>
                                <Table.Column className='is-body-column' alignCenter maxWidth='367' minWidth='367' padding='5'>
                                  <Text bold12 singleLine color={VARIABLES.COLORS.WHITE}>CHỐNG PHAI MÀU</Text>
                                </Table.Column>
                                <Table.Column className='is-body-column' alignCenter maxWidth='367' minWidth='367' padding='5'>
                                  <Text regular12 singleLine color={VARIABLES.COLORS.BLACK}>5 NĂM</Text>
                                </Table.Column>
                              </Table.Row>
                            </Table.Section>
                          </Table>
                        </Section>
                      </React.Fragment>
                    }

                    {isBzacsInok &&
                      <React.Fragment>
                        <Section>
                          <Text regular12 color={VARIABLES.COLORS.BLACK}>Nội dung bảo hành:  20 nămchốngăn mòn thủng</Text>
                        </Section>
                        <Section spacingBottom='6'>
                          <Text regular12 color={VARIABLES.COLORS.BLACK}>(theo điềukhoảnsố 7)</Text>
                        </Section>
                      </React.Fragment>
                    }

                    {isBzacsBenLanh &&
                      <React.Fragment>
                        <Section spacingBottom='6'>
                          <Text regular12 color={VARIABLES.COLORS.BLACK}>Nội dung bảo hành: (theo điềukhoảnsố 9)</Text>
                        </Section>
                      </React.Fragment>
                    }

                    {isBzacsBenMau &&
                      <React.Fragment>
                        <Section spacingBottom='3'>
                          <Text bold12 color={VARIABLES.COLORS.BLACK}>Nội dung bảo hành:</Text>
                        </Section>
                        <Section spacingBottom='3'>
                          <Table fluid warrantyVietnamOrangeTheme>
                            <Table.Section>
                              <Table.Row>
                                <Table.Column className='is-body-column' alignCenter maxWidth='367' minWidth='367' padding='5'>
                                  <Text bold12 singleLine color={VARIABLES.COLORS.WHITE}>CHỐNGĂN MÒN THỦNG</Text>
                                </Table.Column>
                                <Table.Column className='is-body-column' alignCenter maxWidth='367' minWidth='367' padding='5'>
                                  <Text regular12 singleLine color={VARIABLES.COLORS.BLACK}>10 NĂM</Text>
                                </Table.Column>
                              </Table.Row>
                              <Table.Row>
                                <Table.Column className='is-body-column' alignCenter maxWidth='367' minWidth='367' padding='5'>
                                  <Text bold12 singleLine color={VARIABLES.COLORS.WHITE}>CHỐNG PHAI MÀU</Text>
                                </Table.Column>
                                <Table.Column className='is-body-column' alignCenter maxWidth='367' minWidth='367' padding='5'>
                                  <Text regular12 singleLine color={VARIABLES.COLORS.BLACK}>5 NĂM</Text>
                                </Table.Column>
                              </Table.Row>
                            </Table.Section>
                          </Table>
                        </Section>
                      </React.Fragment>
                    }
                    <Section spacingBottom='3'>
                      <Text bold10 color={VARIABLES.COLORS.BLACK}>Mã sốcuộn(*):&nbsp;</Text>
                      <Text regular10 color={VARIABLES.COLORS.BLACK}>
                        {coilLot}
                      </Text>
                    </Section>
                    <Section>
                      <Text bold10 color={VARIABLES.COLORS.BLACK}>Sốlượng Tôn đượcbảo hành:&nbsp;</Text>
                      <Text regular10 color={VARIABLES.COLORS.BLACK}>
                        {lengthSold} mét
                      </Text>
                    </Section>
                    <Section spacingBottom='3'>
                      <Text bold10 color={VARIABLES.COLORS.BLACK}>Tên chủ nhà:&nbsp;</Text>
                      <Text regular10 color={VARIABLES.COLORS.BLACK}>
                        {ownerName}&nbsp;&nbsp;&nbsp;&nbsp;
                      </Text>
                      <Text bold10 color={VARIABLES.COLORS.BLACK}>Điệnthoại liên hệ:&nbsp;</Text>
                      <Text regular10 color={VARIABLES.COLORS.BLACK}>
                        {ownerPhone}
                      </Text>
                    </Section>
                    <Section>
                      <Text bold10 color={VARIABLES.COLORS.BLACK}>Địachỉlợp tôn:&nbsp;</Text>
                      <Text regular10 color={VARIABLES.COLORS.BLACK}>
                        {installationAddress}
                      </Text>
                    </Section>
                    <Section spacingBottom='6'>
                      <Text bold10 color={VARIABLES.COLORS.BLACK}>Ngày mua:&nbsp;</Text>
                      <Text bold10 color={VARIABLES.COLORS.BLACK}>Ngày:&nbsp;</Text>
                      <Text regular10 color={VARIABLES.COLORS.BLACK}>
                        {purchaseDate}&nbsp;
                      </Text>
                      <Text bold10 color={VARIABLES.COLORS.BLACK}>tháng:&nbsp;</Text>
                      <Text regular10 color={VARIABLES.COLORS.BLACK}>
                        {purchaseMonth}&nbsp;
                      </Text>
                      <Text bold10 color={VARIABLES.COLORS.BLACK}>Năm:&nbsp;</Text>
                      <Text regular10 color={VARIABLES.COLORS.BLACK}>
                        {purchaseYear}
                      </Text>
                    </Section>
                    <Section spacingBottom='6'>
                      <Text bold10 color={VARIABLES.COLORS.BLACK}>CÁC ĐIỀUKHOẢN & ĐIỀUKIỆNBẢO HÀNH</Text>
                    </Section>

                    {isBzacsHcInok &&
                      <React.Fragment>
                        <Section spacingBottom='3'>
                          <Text regular8 color={VARIABLES.COLORS.BLACK}>Bảo hành này đảmbảorằngtấmlợptừ<Text bold8 color={VARIABLES.COLORS.BLACK}>sảnphẩm {productBrand}</Text>nếuđượclắpdựng và bảo trì đúngcách, sẽđápứng yêu cầu mong muốn ít nhất là trong thời gian bảo hành (được tính từ ngày mua sảnphẩm,miễn là tổngthời gian bảohành từ ngày sảnxuấtđược in trên mặt trái củatấmlợpsẽ không vượt quá sốnămbảo hành cộng thêm 6 tháng).</Text>
                        </Section>
                        <Section spacingBottom={(osName === 'Mac OS') ? '0' : '-3'}>
                          <Text regular8 color={VARIABLES.COLORS.BLACK}>Bảo hành chỉ áp dụng cho công trình nhà ở và nhà kho có diện tích tấmlợptừ≥35m2 đến 5,000m2. Đốivớidiện tích lớnhơnhoặcgầnbiểnhoặcgần khu công nghiệp,một quy trình bảo hành vớiđiềukhoản và điềukiện quy địnhbởi Công ty TNHH NS BlueScope ViệtNam (BlueScope) sẽđược áp dụng.</Text>
                        </Section>
                        <Section spacingBottom='3'>
                          <Text regular8 color={VARIABLES.COLORS.BLACK}>Tuy nhiên, nhữngvấnđề và tình huốngcụthể rõ ràng (trong danh sách bên dưới)phảiđược chú ý và khắcphụcsớmđểđạtđượckếtquảtốtnhất,nếu không có thểsẽ không đượcbảo hành.</Text>
                        </Section>
                        <Section spacingBottom={(osName === 'Mac OS') ? '0' : '-3'}>
                          <Grid>
                            <Grid.Column flex='none'>
                              <Section width='15' align='flex-start'>
                                <Text regular8 color={VARIABLES.COLORS.BLACK}>1.</Text>
                              </Section>
                            </Grid.Column>
                            <Grid.Column flex='1'>
                              <Text regular8 color={VARIABLES.COLORS.BLACK}>Bảo hành chỉ áp dụng cho tấmlợp làm từ thép chính phẩm.Chủđầutư và Nhà thầucầnkiểm tra nhãn hiệu in trên mặt trái củatấmlợptrước khi lắpdựng. Không bao gồm máng xối,ốngxối,đaiốngxối,cửađi và phụkiện khác. </Text>
                            </Grid.Column>
                          </Grid>
                        </Section>
                        <Section spacingBottom={(osName === 'Mac OS') ? '0' : '-3'}>
                          <Grid>
                            <Grid.Column flex='none'>
                              <Section width='15'>
                                <Text regular8 color={VARIABLES.COLORS.BLACK}>2.</Text>
                              </Section>
                            </Grid.Column>
                            <Grid.Column flex='1'>
                              <Text regular8 color={VARIABLES.COLORS.BLACK}>Tấmlợpphảiđượcbốcdỡ và lắpdựng phù hợpvới tài liệuhướngdẫnlắpdựng phát hành bởi BlueScope tạithờiđiểmlắpđặt. <Text bold8 color={VARIABLES.COLORS.BLACK}>Sau đây là mộtsốlưu ý đặcbiệt:</Text></Text>
                            </Grid.Column>
                          </Grid>
                        </Section>
                        <Section spacingBottom={(osName === 'Mac OS') ? '0' : '-3'} paddingLeft='15'>
                          <Grid>
                            <Grid.Column flex='none'>
                              <Section width='15'>
                                <Text regular8 color={VARIABLES.COLORS.BLACK}>a)</Text>
                              </Section>
                            </Grid.Column>
                            <Grid.Column flex='1'>
                              <Text regular8 color={VARIABLES.COLORS.BLACK}>Tấmlợpcần luôn luôn đượcgiữ khô khi còn xếpchồng trong kiệntrước khi lắpdựng.Nhữngtấmbịướtcầnphảiđể riêng và lau khô nhằmgiảmthiểu nguy cơbịăn mòn do bịẩm.</Text>
                            </Grid.Column>
                          </Grid>
                        </Section>
                        <Section spacingBottom={(osName === 'Mac OS') ? '0' : '-3'} paddingLeft='15'>
                          <Grid>
                            <Grid.Column flex='none'>
                              <Section width='15'>
                                <Text regular8 color={VARIABLES.COLORS.BLACK}>b)</Text>
                              </Section>
                            </Grid.Column>
                            <Grid.Column flex='1'>
                              <Text regular8 color={VARIABLES.COLORS.BLACK}>Không cắttấmlợpbằng gió đá,bởi vì mạtsắtnhiệtđộ cao sẽ phá hủylớpmạ.</Text>
                            </Grid.Column>
                          </Grid>
                        </Section>
                        <Section spacingBottom={(osName === 'Mac OS') ? '0' : '-3'} paddingLeft='15'>
                          <Grid>
                            <Grid.Column flex='none'>
                              <Section width='15'>
                                <Text regular8 color={VARIABLES.COLORS.BLACK}>c)</Text>
                              </Section>
                            </Grid.Column>
                            <Grid.Column flex='1'>
                              <Text regular8 color={VARIABLES.COLORS.BLACK}>Trong quá trình lắpdựng,cầnloạibỏmạtsắt và các rác thải khác trên tấmlợpnhằmgiảmthiểu nguy cơăn mòn cụcbộ.</Text>
                            </Grid.Column>
                          </Grid>
                        </Section>
                        <Section spacingBottom='3'>
                          <Grid>
                            <Grid.Column flex='none'>
                              <Section width='15'>
                                <Text regular8 color={VARIABLES.COLORS.BLACK}>3.</Text>
                              </Section>
                            </Grid.Column>
                            <Grid.Column flex='1'>
                              <Text regular8 color={VARIABLES.COLORS.BLACK}>Vít phải tuân thủ tiêu chuẩn Úc AS 3566 Class 3 hoặc Class 4, và đượcchứngnhậnbởi nhà cung cấp vít.</Text>
                            </Grid.Column>
                          </Grid>
                        </Section>
                        <Section spacingBottom='3'>
                          <Grid>
                            <Grid.Column flex='none'>
                              <Section width='15' align='flex-start'>
                                <Text regular8 color={VARIABLES.COLORS.BLACK}>4.</Text>
                              </Section>
                            </Grid.Column>
                            <Grid.Column flex='1'>
                              <Text regular8 color={VARIABLES.COLORS.BLACK}>Tấtcả các vậtliệuđểlắpđặt và liên quan tới tôn diềmphảiđượcchếtạotừ các kim loạitương thích (là thép <Text bold8 color={VARIABLES.COLORS.BLACK}>{productBrand}</Text>tươngđương,kẽmhoặc nhôm) hoặccầnđượcmạ theo hướngdẫnhiệnthờicủa BlueScope. Đểbềngoài có sựđồngnhất lâu dài, tôn diềm và tấmlợp nên làm từ cùng mộtloạivậtliệu.</Text>
                            </Grid.Column>
                          </Grid>
                        </Section>
                        <Section spacingBottom={(osName === 'Mac OS') ? '0' : '-3'}>
                          <Grid>
                            <Grid.Column flex='none'>
                              <Section width='15' align='flex-start'>
                                <Text regular8 color={VARIABLES.COLORS.BLACK}>5.</Text>
                              </Section>
                            </Grid.Column>
                            <Grid.Column flex='1'>
                              <Text regular8 color={VARIABLES.COLORS.BLACK}>Lớpsơncủatấmlợp không bịhưhỏng do trầyxước,hoặcbị dính bẩntạpchấthoặcvậtliệu không tương thích (bao gồmsự tích tụkhói & từống khói). Bảo hành này sẽ không có hiệulựcnếu có bấtcứ hóa chấtxử lý sau khi sơn nào trên sảnphẩm<Text bold8 color={VARIABLES.COLORS.BLACK}>{productBrand}</Text>.</Text>
                            </Grid.Column>
                          </Grid>
                        </Section>
                        <Section spacingBottom={(osName === 'Mac OS') ? '0' : '-3'}>
                          <Grid>
                            <Grid.Column flex='none'>
                              <Section width='15'>
                                <Text regular8 color={VARIABLES.COLORS.BLACK}>6.</Text>
                              </Section>
                            </Grid.Column>
                            <Grid.Column flex='1'>
                              <Text regular8 color={VARIABLES.COLORS.BLACK}>Nếusảnphẩmbịhưhại do các nguyên nhân sau sẽ không đượcbảo hành mặc dù còn trong thời gian bảo hành:</Text>
                            </Grid.Column>
                          </Grid>
                        </Section>
                        <WarrantyTermsAndConditionsSecondaryList />
                        <Section spacingBottom='3'>
                          <Grid>
                            <Grid.Column flex='none'>
                              <Section width='15'>
                                <Text regular8 color={VARIABLES.COLORS.BLACK}>7.</Text>
                              </Section>
                            </Grid.Column>
                            <Grid.Column flex='1'>
                              <Text regular8 color={VARIABLES.COLORS.BLACK}>Sảnphẩmbịhưhại do ăn mòn đượcđịnhnghĩa là khi tấm tôn bịăn mòn thủng.</Text>
                            </Grid.Column>
                          </Grid>
                        </Section>
                        <Section spacingBottom='3'>
                          <Grid>
                            <Grid.Column flex='none'>
                              <Section width='15' align='flex-start'>
                                <Text regular8 color={VARIABLES.COLORS.BLACK}>8.</Text>
                              </Section>
                            </Grid.Column>
                            <Grid.Column flex='1'>
                              <Text regular8 color={VARIABLES.COLORS.BLACK}>Sảnphẩm phai màu đượcđịnhnghĩa là khi màu sắc thay đổi so với màu chuẩnvớichỉsố khác biệt màu dE lớnhơn 15 đơnvị theo tiêu chuẩn ASTM D-2244, và đượckiểm tra trên bềmặtđược làm sạchbụi và tạpchất khác theo tiêu chuẩn ASTM D3694.</Text>
                            </Grid.Column>
                          </Grid>
                        </Section>
                        <Section spacingBottom={(osName === 'Mac OS') ? '0' : '-3'}>
                          <Grid>
                            <Grid.Column flex='none'>
                              <Section width='15' align='flex-start'>
                                <Text regular8 color={VARIABLES.COLORS.BLACK}>9.</Text>
                              </Section>
                            </Grid.Column>
                            <Grid.Column flex='1'>
                              <Text regular8 color={VARIABLES.COLORS.BLACK}>Nếutấmlợp <Text bold8 color={VARIABLES.COLORS.BLACK}>từsảnphẩm {productBrand}</Text>(đãđượclắpdựng và bảoquảnđúng theo các điềukhoản & điềukiệnở trên) bịhưhỏng trong thời gian bảo hành do lỗivậtliệu,tấmlợpsẽđượcsửachữahoặc thay thếbằngsảnphẩmtươngđương, theo quyếtđịnhcủa BlueScope. BlueScope không có trách nhiệmvớibấtcứsựmất mát gián tiếphoặchưhỏnghoặc chi phí phát sinh từviệc tháo dỡ và loạibỏ các nguyên liệubịhưhỏnghoặc phát sinh từviệclắpdựng các nguyên liệuthay thế <Text bold8 color={VARIABLES.COLORS.BLACK}>sảnphẩm {productBrand}.</Text></Text>
                            </Grid.Column>
                          </Grid>
                        </Section>
                        <Section spacingBottom='3' paddingLeft='15'>
                          <Text regular8 color={VARIABLES.COLORS.BLACK}>Chi tiếtsốnămbảo hành chốngăn mòn thủng cho sảnphẩm <Text bold8 color={VARIABLES.COLORS.BLACK}>{productBrand}</Text> được xác định theo bảng sau:</Text>
                        </Section>
                        <Section paddingLeft='15'>
                          <Table fluid warrantyVietnamComparison>
                            <Table.Section>
                              <Table.Row>
                                <Table.Column className='is-head-column' alignCenter maxWidth='359' minWidth='359' padding='5'>
                                  <Text bold8 singleLine color={VARIABLES.COLORS.WHITE}>Khoảng cách tính từ mép sóng biển tan</Text>
                                </Table.Column>
                                <Table.Column className='is-head-column' alignCenter maxWidth='360' minWidth='360' padding='5'>
                                  <Text bold8 singleLine color={VARIABLES.COLORS.WHITE}>Sốnămbảo hành chốngăn mòn thủng</Text>
                                </Table.Column>
                              </Table.Row>
                            </Table.Section>
                            <Table.Section>
                              <Table.Row>
                                <Table.Column className='is-body-column' alignCenter maxWidth='359' minWidth='359' padding='5'>
                                  <Text bold8 singleLine color={VARIABLES.COLORS.BLACK}>2 – 5km</Text>
                                </Table.Column>
                                <Table.Column className='is-body-column' alignCenter maxWidth='360' minWidth='360' padding='5'>
                                  <Text regular8 singleLine color={VARIABLES.COLORS.BLACK}>10 năm</Text>
                                </Table.Column>
                              </Table.Row>
                              <Table.Row>
                                <Table.Column className='is-body-column' alignCenter maxWidth='359' minWidth='359' padding='5'>
                                  <Text bold8 singleLine color={VARIABLES.COLORS.BLACK}>> 5km</Text>
                                </Table.Column>
                                <Table.Column className='is-body-column' alignCenter maxWidth='360' minWidth='360' padding='5'>
                                  <Text regular8 singleLine color={VARIABLES.COLORS.BLACK}>20 năm</Text>
                                </Table.Column>
                              </Table.Row>
                            </Table.Section>
                          </Table>
                        </Section>
                        <Section spacingBottom={(osName === 'Mac OS') ? '0' : '-3'}>
                          <Grid>
                            <Grid.Column flex='none'>
                              <Section width='15'>
                                <Text regular8 color={VARIABLES.COLORS.BLACK}>10.</Text>
                              </Section>
                            </Grid.Column>
                            <Grid.Column flex='1'>
                              <Text regular8 color={VARIABLES.COLORS.BLACK}>ThẻBảo Hành không có giá trịsửdụngnếuThẻBảo Hành không còn nguyên vẹn,bịchắp vá, bịtẩy xóa.</Text>
                            </Grid.Column>
                          </Grid>
                        </Section>
                        <Section>
                          <Grid>
                            <Grid.Column flex='none'>
                              <Section width='15'>
                                <Text regular8 color={VARIABLES.COLORS.BLACK}>11.</Text>
                              </Section>
                            </Grid.Column>
                            <Grid.Column flex='1'>
                              <Text regular8 color={VARIABLES.COLORS.BLACK}>Khiếunạichỉđượctiếpnhận khi kèm theo ThẻBảo Hành.</Text>
                            </Grid.Column>
                          </Grid>
                        </Section>
                      </React.Fragment>
                    }

                    {isBzacsInok &&
                      <React.Fragment>
                        <Section spacingBottom='3'>
                          <Text regular8 color={VARIABLES.COLORS.BLACK}>Bảo hành này đảmbảorằngtấmlợptừ <Text bold8 color={VARIABLES.COLORS.BLACK}>sảnphẩm {productBrand}</Text> nếuđượclắpdựng và bảo trì đúng cách, sẽđápứng yêu cầu mong muốn ít nhất là trong thời gian bảo hành (được tính từ ngày mua sảnphẩm,miễn là tổngthời gian bảo hành từ ngày sảnxuấtđược in trên mặt trái củatấmlợpsẽ không vượt quá sốnămbảo hành cộng thêm 6 tháng).</Text>
                        </Section>
                        <Section spacingBottom={(osName === 'Mac OS') ? '0' : '-3'}>
                          <Text regular8 color={VARIABLES.COLORS.BLACK}>Bảo hành chỉ áp dụng cho công trình nhà ở và nhà kho có diện tích tấmlợptừ≥35m2 đến 5,000m2. Đốivớidiện tích lớnhơnhoặcgầnbiểnhoặcgần khu công nghiệp,một quy trình bảo hành vớiđiềukhoản và điềukiện quy địnhbởi Công ty TNHH NS BlueScope ViệtNam (BlueScope) sẽđược áp dụng.</Text>
                        </Section>
                        <Section spacingBottom='3'>
                          <Text regular8 color={VARIABLES.COLORS.BLACK}>Tuy nhiên, nhữngvấnđề và tình huốngcụthể rõ ràng (trong danh sách bên dưới)phảiđược chú ý và khắcphụcsớmđểđạtđượckếtquảtốtnhất,nếu không có thểsẽ không đượcbảo hành.</Text>
                        </Section>
                        <Section spacingBottom={(osName === 'Mac OS') ? '0' : '-3'}>
                          <Grid>
                            <Grid.Column flex='none'>
                              <Section width='15' align='flex-start'>
                                <Text regular8 color={VARIABLES.COLORS.BLACK}>1.</Text>
                              </Section>
                            </Grid.Column>
                            <Grid.Column flex='1'>
                              <Text regular8 color={VARIABLES.COLORS.BLACK}>Bảo hành chỉ áp dụng cho tấmlợp làm từ thép chính phẩm.Chủđầutư và Nhà thầucầnkiểm tra nhãn hiệu in trên mặt trái củatấmlợptrước khi lắpdựng. Không bao gồm máng xối,ốngxối,đaiốngxối,cửađi và phụkiện khác.</Text>
                            </Grid.Column>
                          </Grid>
                        </Section>
                        <Section spacingBottom={(osName === 'Mac OS') ? '0' : '-3'}>
                          <Grid>
                            <Grid.Column flex='none'>
                              <Section width='15'>
                                <Text regular8 color={VARIABLES.COLORS.BLACK}>2.</Text>
                              </Section>
                            </Grid.Column>
                            <Grid.Column flex='1'>
                              <Text regular8 color={VARIABLES.COLORS.BLACK}>Tấmlợpphảiđượcbốcdỡ và lắpdựng phù hợpvới tài liệuhướngdẫnlắpdựng phát hành bởi BlueScope tạithờiđiểmlắpđặt. <Text bold8 color={VARIABLES.COLORS.BLACK}>Sau đây là mộtsốlưu ý đặcbiệt:</Text></Text>
                            </Grid.Column>
                          </Grid>
                        </Section>
                        <WarrantyTermsAndConditionsPrimaryList />
                        <Section spacingBottom='3'>
                          <Grid>
                            <Grid.Column flex='none'>
                              <Section width='15'>
                                <Text regular8 color={VARIABLES.COLORS.BLACK}>3.</Text>
                              </Section>
                            </Grid.Column>
                            <Grid.Column flex='1'>
                              <Text regular8 color={VARIABLES.COLORS.BLACK}>Vít phải tuân thủ tiêu chuẩn Úc AS 3566 Class 3 hoặc Class 4, và đượcchứngnhậnbởi nhà cung cấp vít.</Text>
                            </Grid.Column>
                          </Grid>
                        </Section>
                        <Section spacingBottom={(osName === 'Mac OS') ? '0' : '-3'}>
                          <Grid>
                            <Grid.Column flex='none'>
                              <Section width='15' align='flex-start'>
                                <Text regular8 color={VARIABLES.COLORS.BLACK}>4.</Text>
                              </Section>
                            </Grid.Column>
                            <Grid.Column flex='1'>
                              <Text regular8 color={VARIABLES.COLORS.BLACK}>Tấtcả các vậtliệuđểlắpđặt và liên quan tới tôn diềmphảiđượcchếtạotừ các kim loạitương thích (là thép <Text bold8 color={VARIABLES.COLORS.BLACK}>{productBrand}</Text> tươngđương,kẽmhoặc  nhôm) hoặccầnđượcmạ  theo hướngdẫnhiệnthờicủa  BlueScope. Đểbề  ngoài  có sựđồngnhất lâu dài, tôn diềm và tấmlợp nên làm từ cùng mộtloạivậtliệu.</Text>
                            </Grid.Column>
                          </Grid>
                        </Section>
                        <Section spacingBottom={(osName === 'Mac OS') ? '0' : '-3'}>
                          <Grid>
                            <Grid.Column flex='none'>
                              <Section width='15'>
                                <Text regular8 color={VARIABLES.COLORS.BLACK}>5.</Text>
                              </Section>
                            </Grid.Column>
                            <Grid.Column flex='1'>
                              <Text regular8 color={VARIABLES.COLORS.BLACK}>Nếusảnphẩmbịhưhại do các nguyên nhân sau sẽ không đượcbảo hành mặc dù còn trong thời gian bảo hành:</Text>
                            </Grid.Column>
                          </Grid>
                        </Section>
                        <WarrantyTermsAndConditionsSecondaryList />
                        <Section spacingBottom='3'>
                          <Grid>
                            <Grid.Column flex='none'>
                              <Section width='15'>
                                <Text regular8 color={VARIABLES.COLORS.BLACK}>6.</Text>
                              </Section>
                            </Grid.Column>
                            <Grid.Column flex='1'>
                              <Text regular8 color={VARIABLES.COLORS.BLACK}>Sảnphẩmbịhưhại do ăn mòn đượcđịnhnghĩa là khi tấm tôn bịăn mòn thủng.</Text>
                            </Grid.Column>
                          </Grid>
                        </Section>
                        <Section spacingBottom={(osName === 'Mac OS') ? '0' : '-3'}>
                          <Grid>
                            <Grid.Column flex='none'>
                              <Section width='15' align='flex-start'>
                                <Text regular8 color={VARIABLES.COLORS.BLACK}>7.</Text>
                              </Section>
                            </Grid.Column>
                            <Grid.Column flex='1'>
                              <Text regular8 color={VARIABLES.COLORS.BLACK}>Nếutấmlợptừ <Text bold8 color={VARIABLES.COLORS.BLACK}>sảnphẩm {productBrand}</Text>  (đãđượclắpdựng và bảoquảnđúng theo các điềukhoản & điềukiệnở trên) bịhưhỏng trong thời gian bảo hành do lỗivậtliệu,tấmlợpsẽđượcsửachữahoặc thay thếbằngsảnphẩmtươngđương, theo quyếtđịnhcủa BlueScope. BlueScope không có trách nhiệmvớibấtcứsựmất mát gián tiếphoặchưhỏnghoặc chi phí phát sinh từviệc tháo dỡ và loạibỏ các nguyên liệubịhưhỏnghoặc phát sinh từviệclắpdựng các nguyên liệu thay thế <Text bold8 color={VARIABLES.COLORS.BLACK}>sảnphẩm {productBrand}</Text></Text>
                            </Grid.Column>
                          </Grid>
                        </Section>
                        <Section spacingBottom='3' paddingLeft='15'>
                          <Text regular8 color={VARIABLES.COLORS.BLACK}>Chi tiếtsốnămbảo hành chốngăn mòn thủng cho sảnphẩm <Text bold8 color={VARIABLES.COLORS.BLACK}>{productBrand}</Text> được xác định theo bảng sau:</Text>
                        </Section>
                        <Section paddingLeft='15'>
                          <Table fluid warrantyVietnamComparison>
                            <Table.Section>
                              <Table.Row>
                                <Table.Column className='is-head-column' alignCenter maxWidth='359' minWidth='359' padding='5'>
                                  <Text bold8 singleLine color={VARIABLES.COLORS.WHITE}>Khoảng cách tính từ mép sóng biển tan</Text>
                                </Table.Column>
                                <Table.Column className='is-head-column' alignCenter maxWidth='360' minWidth='360' padding='5'>
                                  <Text bold8 singleLine color={VARIABLES.COLORS.WHITE}>Sốnămbảo hành chốngăn mòn thủng</Text>
                                </Table.Column>
                              </Table.Row>
                            </Table.Section>
                            <Table.Section>
                              <Table.Row>
                                <Table.Column className='is-body-column' alignCenter maxWidth='359' minWidth='359' padding='5'>
                                  <Text bold8 singleLine color={VARIABLES.COLORS.BLACK}>2 – 5km</Text>
                                </Table.Column>
                                <Table.Column className='is-body-column' alignCenter maxWidth='360' minWidth='360' padding='5'>
                                  <Text regular8 singleLine color={VARIABLES.COLORS.BLACK}>10 năm</Text>
                                </Table.Column>
                              </Table.Row>
                              <Table.Row>
                                <Table.Column className='is-body-column' alignCenter maxWidth='359' minWidth='359' padding='5'>
                                  <Text bold8 singleLine color={VARIABLES.COLORS.BLACK}>> 5km</Text>
                                </Table.Column>
                                <Table.Column className='is-body-column' alignCenter maxWidth='360' minWidth='360' padding='5'>
                                  <Text regular8 singleLine color={VARIABLES.COLORS.BLACK}>20 năm</Text>
                                </Table.Column>
                              </Table.Row>
                            </Table.Section>
                          </Table>
                        </Section>
                        <Section spacingBottom={(osName === 'Mac OS') ? '0' : '-3'}>
                          <Grid>
                            <Grid.Column flex='none'>
                              <Section width='15'>
                                <Text regular8 color={VARIABLES.COLORS.BLACK}>8.</Text>
                              </Section>
                            </Grid.Column>
                            <Grid.Column flex='1'>
                              <Text regular8 color={VARIABLES.COLORS.BLACK}>ThẻBảo Hành không có giá trịsửdụngnếuThẻBảo Hành không còn nguyên vẹn,bịchắp vá, bịtẩy xóa.</Text>
                            </Grid.Column>
                          </Grid>
                        </Section>
                        <Section>
                          <Grid>
                            <Grid.Column flex='none'>
                              <Section width='15'>
                                <Text regular8 color={VARIABLES.COLORS.BLACK}>9.</Text>
                              </Section>
                            </Grid.Column>
                            <Grid.Column flex='1'>
                              <Text regular8 color={VARIABLES.COLORS.BLACK}>Khiếunạichỉđượctiếpnhận khi kèm theo ThẻBảo Hành.</Text>
                            </Grid.Column>
                          </Grid>
                        </Section>
                      </React.Fragment>
                    }

                    {isBzacsBenLanh &&
                      <React.Fragment>
                        <Section spacingBottom='3'>
                          <Text regular8 color={VARIABLES.COLORS.BLACK}>Bảo hành này đảmbảorằngtấmlợptừ <Text bold8 color={VARIABLES.COLORS.BLACK}>sảnphẩm {productBrand}</Text> nếuđượclắpdựng và bảo trì đúng cách, sẽđápứng yêu cầu mong muốn ít nhất là trong thời gian bảo hành (được tính từ ngày mua sảnphẩm,miễn là tổngthời gian bảo hành từ ngày sảnxuấtđược in trên mặt trái củatấmlợpsẽ không vượt quá sốnămbảo hành cộng thêm 6 tháng).</Text>
                        </Section>
                        <Section spacingBottom={(osName === 'Mac OS') ? '0' : '-3'}>
                          <Text regular8 color={VARIABLES.COLORS.BLACK}>Bảo hành chỉ áp dụng cho công trình nhà ở và nhà kho có diện tích tấmlợptừ≥35m2 đến 5,000m2. Đốivớidiện tích lớnhơnhoặcgầnbiểnhoặcgần khu công nghiệp,một quy trình bảo hành vớiđiềukhoản và điềukiện quy địnhbởi Công ty TNHH NS BlueScope ViệtNam (BlueScope) sẽđược áp dụng.</Text>
                        </Section>
                        <Section spacingBottom='3'>
                          <Text regular8 color={VARIABLES.COLORS.BLACK}>Tuy nhiên, nhữngvấnđề và tình huốngcụthể rõ ràng (trong danh sách bên dưới)phảiđược chú ý và khắcphụcsớmđểđạtđượckếtquảtốtnhất,nếu không có thểsẽ không đượcbảo hành.</Text>
                        </Section>
                        <Section spacingBottom={(osName === 'Mac OS') ? '0' : '-3'}>
                          <Grid>
                            <Grid.Column flex='none'>
                              <Section width='15' align='flex-start'>
                                <Text regular8 color={VARIABLES.COLORS.BLACK}>1.</Text>
                              </Section>
                            </Grid.Column>
                            <Grid.Column flex='1'>
                              <Text regular8 color={VARIABLES.COLORS.BLACK}>Bảo hành chỉ áp dụng cho tấmlợp làm từ thép chính phẩm.Chủđầutư và Nhà thầucầnkiểm tra nhãn hiệu in trên mặt trái củatấmlợptrước khi lắpdựng. Không bao gồm máng xối,ốngxối,đaiốngxối,cửađi và phụkiện khác.</Text>
                            </Grid.Column>
                          </Grid>
                        </Section>
                        <Section spacingBottom={(osName === 'Mac OS') ? '0' : '-3'}>
                          <Grid>
                            <Grid.Column flex='none'>
                              <Section width='15'>
                                <Text regular8 color={VARIABLES.COLORS.BLACK}>2.</Text>
                              </Section>
                            </Grid.Column>
                            <Grid.Column flex='1'>
                              <Text regular8 color={VARIABLES.COLORS.BLACK}>Tấmlợpphảiđượcbốcdỡ và lắpdựng phù hợpvới tài liệuhướngdẫnlắpdựng phát hành bởi BlueScope tạithờiđiểmlắpđặt. <Text bold8 color={VARIABLES.COLORS.BLACK}>Sau đây là mộtsốlưu ý đặcbiệt:</Text></Text>
                            </Grid.Column>
                          </Grid>
                        </Section>
                        <WarrantyTermsAndConditionsPrimaryList />
                        <Section spacingBottom='3'>
                          <Grid>
                            <Grid.Column flex='none'>
                              <Section width='15'>
                                <Text regular8 color={VARIABLES.COLORS.BLACK}>3.</Text>
                              </Section>
                            </Grid.Column>
                            <Grid.Column flex='1'>
                              <Text regular8 color={VARIABLES.COLORS.BLACK}>Vít phải tuân thủ tiêu chuẩn Úc AS 3566 Class 3 hoặc Class 4, và đượcchứngnhậnbởi nhà cung cấp vít.</Text>
                            </Grid.Column>
                          </Grid>
                        </Section>
                        <Section spacingBottom={(osName === 'Mac OS') ? '0' : '-3'}>
                          <Grid>
                            <Grid.Column flex='none'>
                              <Section width='15' align='flex-start'>
                                <Text regular8 color={VARIABLES.COLORS.BLACK}>4.</Text>
                              </Section>
                            </Grid.Column>
                            <Grid.Column flex='1'>
                              <Text regular8 color={VARIABLES.COLORS.BLACK}>Tấtcả các vậtliệuđểlắpđặt và liên quan tới tôn diềmphảiđượcchếtạotừ các kim loạitương thích (là thép <Text bold8 color={VARIABLES.COLORS.BLACK}>{productBrand}</Text> tươngđương,kẽmhoặc  nhôm) hoặccầnđượcmạ theo hướngdẫnhiệnthờicủa  BlueScope. Đểbề ngoài có sựđồngnhất lâu dài, tôn diềm và tấmlợp nên làm từ cùng mộtloạivậtliệu.</Text>
                            </Grid.Column>
                          </Grid>
                        </Section>
                        <Section spacingBottom={(osName === 'Mac OS') ? '0' : '-3'}>
                          <Grid>
                            <Grid.Column flex='none'>
                              <Section width='15'>
                                <Text regular8 color={VARIABLES.COLORS.BLACK}>5.</Text>
                              </Section>
                            </Grid.Column>
                            <Grid.Column flex='1'>
                              <Text regular8 color={VARIABLES.COLORS.BLACK}>Nếusảnphẩmbịhưhại do các nguyên nhân sau sẽ không đượcbảo hành mặc dù còn trong thời gian bảo hành:</Text>
                            </Grid.Column>
                          </Grid>
                        </Section>
                        <WarrantyTermsAndConditionsSecondaryList />
                        <Section spacingBottom='3'>
                          <Grid>
                            <Grid.Column flex='none'>
                              <Section width='15'>
                                <Text regular8 color={VARIABLES.COLORS.BLACK}>6.</Text>
                              </Section>
                            </Grid.Column>
                            <Grid.Column flex='1'>
                              <Text regular8 color={VARIABLES.COLORS.BLACK}>Sảnphẩmbịhưhại do ăn mòn đượcđịnhnghĩa là khi tấm tôn bịăn mòn thủng.</Text>
                            </Grid.Column>
                          </Grid>
                        </Section>
                        <Section spacingBottom={(osName === 'Mac OS') ? '0' : '-3'}>
                          <Grid>
                            <Grid.Column flex='none'>
                              <Section width='15' align='flex-start'>
                                <Text regular8 color={VARIABLES.COLORS.BLACK}>7.</Text>
                              </Section>
                            </Grid.Column>
                            <Grid.Column flex='1'>
                              <Text regular8 color={VARIABLES.COLORS.BLACK}>Nếutấmlợptừ <Text bold8 color={VARIABLES.COLORS.BLACK}>sảnphẩm {productBrand}</Text>  (đãđượclắpdựng và bảoquảnđúng theo các điềukhoản & điềukiệnở trên) bịhưhỏng trong thời gian bảo hành do lỗivậtliệu,tấmlợpsẽđượcsửachữahoặc thay thếbằngsảnphẩmtươngđương, theo quyếtđịnhcủa BlueScope. BlueScope không có trách nhiệmvớibấtcứsựmất mát gián tiếphoặchưhỏnghoặc chi phí phát sinh từviệc tháo dỡ và loạibỏ các nguyên liệubịhưhỏnghoặc phát sinh từviệclắpdựng các nguyên liệu thay thế <Text bold8 color={VARIABLES.COLORS.BLACK}>sảnphẩm {productBrand}</Text></Text>
                            </Grid.Column>
                          </Grid>
                        </Section>
                        <Section spacingBottom='3' paddingLeft='15'>
                          <Text regular8 color={VARIABLES.COLORS.BLACK}>Tốiđa trách nhiệmbảo hành của <Text bold8 color={VARIABLES.COLORS.BLACK}>sảnphẩm {productBrand}</Text> được quy địnhnhư sau:</Text>
                        </Section>
                        <Section paddingLeft='15'>
                          <Table fluid warrantyVietnamComparisonOrangeTheme>
                            <Table.Section>
                              <Table.Row>
                                <Table.Column className='is-head-column' alignCenter maxWidth='359' minWidth='359' padding='5'>
                                  <Text bold8 singleLine color={VARIABLES.COLORS.WHITE}>Thờihạnbảo hành<br />(từ ngày mua)</Text>
                                </Table.Column>
                                <Table.Column className='is-head-column' alignCenter maxWidth='360' minWidth='360' padding='5'>
                                  <Text bold8 singleLine color={VARIABLES.COLORS.WHITE}>Mứctốiđa trách nhiệmbảo hành của BlueScope<br />(trên cơsở giá bán của nguyên liệu làm nên sảnphẩm)</Text>
                                </Table.Column>
                              </Table.Row>
                            </Table.Section>
                            <Table.Section>
                              <Table.Row>
                                <Table.Column className='is-body-column' alignCenter maxWidth='359' minWidth='359' padding='5'>
                                  <Text bold8 singleLine color={VARIABLES.COLORS.BLACK}>Nămthứ 1 – Nămthứ 5</Text>
                                </Table.Column>
                                <Table.Column className='is-body-column' alignCenter maxWidth='360' minWidth='360' padding='5'>
                                  <Text regular8 singleLine color={VARIABLES.COLORS.BLACK}>100%</Text>
                                </Table.Column>
                              </Table.Row>
                              <Table.Row>
                                <Table.Column className='is-body-column' alignCenter maxWidth='359' minWidth='359' padding='5'>
                                  <Text bold8 singleLine color={VARIABLES.COLORS.BLACK}>Nămthứ 6 – Nămthứ 7</Text>
                                </Table.Column>
                                <Table.Column className='is-body-column' alignCenter maxWidth='360' minWidth='360' padding='5'>
                                  <Text regular8 singleLine color={VARIABLES.COLORS.BLACK}>70%</Text>
                                </Table.Column>
                              </Table.Row>
                              <Table.Row>
                                <Table.Column className='is-body-column' alignCenter maxWidth='359' minWidth='359' padding='5'>
                                  <Text bold8 singleLine color={VARIABLES.COLORS.BLACK}>Nămthứ 8 – Nămthứ 9</Text>
                                </Table.Column>
                                <Table.Column className='is-body-column' alignCenter maxWidth='360' minWidth='360' padding='5'>
                                  <Text regular8 singleLine color={VARIABLES.COLORS.BLACK}>40%</Text>
                                </Table.Column>
                              </Table.Row>
                              <Table.Row>
                                <Table.Column className='is-body-column' alignCenter maxWidth='359' minWidth='359' padding='5'>
                                  <Text bold8 singleLine color={VARIABLES.COLORS.BLACK}>Nămthứ 10 trởđi</Text>
                                </Table.Column>
                                <Table.Column className='is-body-column' alignCenter maxWidth='360' minWidth='360' padding='5'>
                                  <Text regular8 singleLine color={VARIABLES.COLORS.BLACK}>20%</Text>
                                </Table.Column>
                              </Table.Row>
                            </Table.Section>
                          </Table>
                        </Section>
                        <Section spacingBottom={(osName === 'Mac OS') ? '0' : '-3'}>
                          <Grid>
                            <Grid.Column flex='none'>
                              <Section width='15'>
                                <Text regular8 color={VARIABLES.COLORS.BLACK}>8.</Text>
                              </Section>
                            </Grid.Column>
                            <Grid.Column flex='1'>
                              <Text regular8 color={VARIABLES.COLORS.BLACK}>ThẻBảo Hành không có giá trịsửdụngnếuThẻBảo Hành không còn nguyên vẹn,bịchắp vá, bịtẩy xóa.</Text>
                            </Grid.Column>
                          </Grid>
                        </Section>
                        <Section>
                          <Grid>
                            <Grid.Column flex='none'>
                              <Section width='15'>
                                <Text regular8 color={VARIABLES.COLORS.BLACK}>9.</Text>
                              </Section>
                            </Grid.Column>
                            <Grid.Column flex='1'>
                              <Text regular8 color={VARIABLES.COLORS.BLACK}>Khiếunạichỉđượctiếpnhận khi kèm theo ThẻBảo Hành.</Text>
                            </Grid.Column>
                          </Grid>
                        </Section>
                      </React.Fragment>
                    }

                    {isBzacsBenMau &&
                      <React.Fragment>
                        <Section spacingBottom='3'>
                          <Text regular8 color={VARIABLES.COLORS.BLACK}>Bảo hành này đảmbảorằngtấmlợptừ <Text bold8 color={VARIABLES.COLORS.BLACK}>sảnphẩm {productBrand}</Text> nếuđượclắpdựng và bảo trì đúng cách, sẽđápứng yêu cầu mong muốn ít nhất là trong thời gian bảo hành (được tính từ ngày mua sảnphẩm,miễn là tổngthời gian bảo hành từ ngày sảnxuấtđược in trên mặt trái củatấmlợpsẽ không vượt quá sốnămbảo hành cộng thêm 6 tháng).</Text>
                        </Section>
                        <Section spacingBottom={(osName === 'Mac OS') ? '0' : '-3'}>
                          <Text regular8 color={VARIABLES.COLORS.BLACK}>Bảo hành chỉ áp dụng cho công trình nhà ở và nhà kho có diện tích tấmlợptừ≥35m2 đến 5,000m2. Đốivớidiện tích lớnhơnhoặcgầnbiểnhoặcgần khu công nghiệp,một quy trình bảo hành vớiđiềukhoản và điềukiện quy địnhbởi Công ty TNHH NS BlueScope ViệtNam (BlueScope) sẽđược áp dụng.</Text>
                        </Section>
                        <Section spacingBottom='3'>
                          <Text regular8 color={VARIABLES.COLORS.BLACK}>Tuy nhiên, nhữngvấnđề và tình huốngcụthể rõ ràng (trong danh sách bên dưới)phảiđược chú ý và khắcphụcsớmđểđạtđượckếtquảtốtnhất,nếu không có thểsẽ không đượcbảo hành.</Text>
                        </Section>
                        <Section spacingBottom={(osName === 'Mac OS') ? '0' : '-3'}>
                          <Grid>
                            <Grid.Column flex='none'>
                              <Section width='15' align='flex-start'>
                                <Text regular8 color={VARIABLES.COLORS.BLACK}>1.</Text>
                              </Section>
                            </Grid.Column>
                            <Grid.Column flex='1'>
                              <Text regular8 color={VARIABLES.COLORS.BLACK}>Bảo hành chỉ áp dụng cho tấmlợp làm từ thép chính phẩm.Chủđầutư và Nhà thầucầnkiểm tra nhãn hiệu in trên mặt trái củatấmlợptrước khi lắpdựng. Không bao gồm máng xối,ốngxối,đaiốngxối,cửađi và phụkiện khác.</Text>
                            </Grid.Column>
                          </Grid>
                        </Section>
                        <Section spacingBottom={(osName === 'Mac OS') ? '0' : '-3'}>
                          <Grid>
                            <Grid.Column flex='none'>
                              <Section width='15'>
                                <Text regular8 color={VARIABLES.COLORS.BLACK}>2.</Text>
                              </Section>
                            </Grid.Column>
                            <Grid.Column flex='1'>
                              <Text regular8 color={VARIABLES.COLORS.BLACK}>Tấmlợpphảiđượcbốcdỡ và lắpdựng phù hợpvới tài liệuhướngdẫnlắpdựng phát hành bởi BlueScope tạithờiđiểmlắpđặt. <Text bold8 color={VARIABLES.COLORS.BLACK}>Sau đây là mộtsốlưu ý đặcbiệt:</Text></Text>
                            </Grid.Column>
                          </Grid>
                        </Section>
                        <WarrantyTermsAndConditionsPrimaryList />
                        <Section spacingBottom='3'>
                          <Grid>
                            <Grid.Column flex='none'>
                              <Section width='15'>
                                <Text regular8 color={VARIABLES.COLORS.BLACK}>3.</Text>
                              </Section>
                            </Grid.Column>
                            <Grid.Column flex='1'>
                              <Text regular8 color={VARIABLES.COLORS.BLACK}>Vít phải tuân thủ tiêu chuẩn Úc AS 3566 Class 3 hoặc Class 4, và đượcchứngnhậnbởi nhà cung cấp vít.</Text>
                            </Grid.Column>
                          </Grid>
                        </Section>
                        <Section spacingBottom='3'>
                          <Grid>
                            <Grid.Column flex='none'>
                              <Section width='15' align='flex-start'>
                                <Text regular8 color={VARIABLES.COLORS.BLACK}>4.</Text>
                              </Section>
                            </Grid.Column>
                            <Grid.Column flex='1'>
                              <Text regular8 color={VARIABLES.COLORS.BLACK}>Tấtcả các vậtliệuđểlắpđặt và liên quan tới tôn diềmphảiđượcchếtạotừ các kim loạitương thích (là thép <Text bold8 color={VARIABLES.COLORS.BLACK}>{productBrand}</Text> tươngđương,kẽmhoặc  nhôm) hoặccầnđượcmạ theo hướngdẫnhiệnthờicủa  BlueScope. Đểbề ngoài có sựđồngnhất lâu dài, tôn diềm và tấmlợp nên làm từ cùng mộtloạivậtliệu.</Text>
                            </Grid.Column>
                          </Grid>
                        </Section>
                        <Section spacingBottom={(osName === 'Mac OS') ? '0' : '-3'}>
                          <Grid>
                            <Grid.Column flex='none'>
                              <Section width='15' align='flex-start'>
                                <Text regular8 color={VARIABLES.COLORS.BLACK}>5.</Text>
                              </Section>
                            </Grid.Column>
                            <Grid.Column flex='1'>
                              <Text regular8 color={VARIABLES.COLORS.BLACK}>ớpsơncủatấmlợp,tấmtrần không bịhưhỏng do trầyxước,hoặcbị dính bẩntạpchấthoặcvậtliệu không tương thích (bao gồmsự tích tụ khói & từống khói). Bảo hành này sẽ không có hiệulựcnếu có bấtcứ hóa chấtxử lý sau khi sơn nào trên sảnphẩm <Text bold8 color={VARIABLES.COLORS.BLACK}>{productBrand}</Text></Text>
                            </Grid.Column>
                          </Grid>
                        </Section>
                        <Section spacingBottom={(osName === 'Mac OS') ? '0' : '-3'}>
                          <Grid>
                            <Grid.Column flex='none'>
                              <Section width='15'>
                                <Text regular8 color={VARIABLES.COLORS.BLACK}>6.</Text>
                              </Section>
                            </Grid.Column>
                            <Grid.Column flex='1'>
                              <Text regular8 color={VARIABLES.COLORS.BLACK}>Nếusảnphẩmbịhưhại do các nguyên nhân sau sẽ không đượcbảo hành mặc dù còn trong thời gian bảo hành:</Text>
                            </Grid.Column>
                          </Grid>
                        </Section>
                        <WarrantyTermsAndConditionsSecondaryList />
                        <Section spacingBottom='3'>
                          <Grid>
                            <Grid.Column flex='none'>
                              <Section width='15'>
                                <Text regular8 color={VARIABLES.COLORS.BLACK}>7.</Text>
                              </Section>
                            </Grid.Column>
                            <Grid.Column flex='1'>
                              <Text regular8 color={VARIABLES.COLORS.BLACK}>Sảnphẩmbịhưhại do ăn mòn đượcđịnhnghĩa là khi tấm tôn bịăn mòn thủng.</Text>
                            </Grid.Column>
                          </Grid>
                        </Section>
                        <Section spacingBottom='3'>
                          <Grid>
                            <Grid.Column flex='none'>
                              <Section width='15' align='flex-start'>
                                <Text regular8 color={VARIABLES.COLORS.BLACK}>8.</Text>
                              </Section>
                            </Grid.Column>
                            <Grid.Column flex='1'>
                              <Text regular8 color={VARIABLES.COLORS.BLACK}>ảnphẩm phai màu đượcđịnhnghĩa là khi màu sắc thay đổi so với màu chuẩnvớichỉsố khác biệt màu dE lớnhơn 15 đơnvị theo tiêu chuẩn ASTM D-2244, và đượckiểm tra trên bềmặtđược làm sạchbụi và tạpchất khác theo tiêu chuẩn ASTM D3694.</Text>
                            </Grid.Column>
                          </Grid>
                        </Section>
                        <Section spacingBottom={(osName === 'Mac OS') ? '0' : '-3'}>
                          <Grid>
                            <Grid.Column flex='none'>
                              <Section width='15' align='flex-start'>
                                <Text regular8 color={VARIABLES.COLORS.BLACK}>9.</Text>
                              </Section>
                            </Grid.Column>
                            <Grid.Column flex='1'>
                              <Text regular8 color={VARIABLES.COLORS.BLACK}>Nếutấmlợptừ <Text bold8 color={VARIABLES.COLORS.BLACK}>sảnphẩm {productBrand}</Text>  (đãđượclắpdựng và bảoquảnđúng theo các điềukhoản & điềukiệnở trên) bịhưhỏng trong thời gian bảo hành do lỗivậtliệu,tấmlợpsẽđượcsửachữahoặc thay thếbằngsảnphẩmtươngđương, theo quyếtđịnhcủa BlueScope. BlueScope không có trách nhiệmvớibấtcứsựmất mát gián tiếphoặchưhỏnghoặc chi phí phát sinh từviệc tháo dỡ và loạibỏ các nguyên liệubịhưhỏnghoặc phát sinh từviệclắpdựng các nguyên liệu thay thế <Text bold8 color={VARIABLES.COLORS.BLACK}>sảnphẩm {productBrand}</Text></Text>
                            </Grid.Column>
                          </Grid>
                        </Section>
                        <Section spacingBottom='3' paddingLeft='15'>
                          <Text regular8 color={VARIABLES.COLORS.BLACK}>Tốiđa trách nhiệmbảo hành của <Text bold8 color={VARIABLES.COLORS.BLACK}>sảnphẩm {productBrand}</Text> được quy địnhnhư sau:</Text>
                        </Section>
                        <Section paddingLeft='15'>
                          <Table fluid warrantyVietnamComparisonOrangeTheme>
                            <Table.Section>
                              <Table.Row>
                                <Table.Column className='is-head-column' alignCenter maxWidth='359' minWidth='359' padding='5'>
                                  <Text bold8 singleLine color={VARIABLES.COLORS.WHITE}>Thờihạnbảo hành<br />(từ ngày mua)</Text>
                                </Table.Column>
                                <Table.Column className='is-head-column' alignCenter maxWidth='360' minWidth='360' padding='5'>
                                  <Text bold8 singleLine color={VARIABLES.COLORS.WHITE}>Mứctốiđa trách nhiệmbảo hành của BlueScope<br />(trên cơsở giá bán của nguyên liệu làm nên sảnphẩm)</Text>
                                </Table.Column>
                              </Table.Row>
                            </Table.Section>
                            <Table.Section>
                              <Table.Row>
                                <Table.Column className='is-body-column' alignCenter maxWidth='359' minWidth='359' padding='5'>
                                  <Text bold8 singleLine color={VARIABLES.COLORS.BLACK}>Nămthứ 1 – Nămthứ 5</Text>
                                </Table.Column>
                                <Table.Column className='is-body-column' alignCenter maxWidth='360' minWidth='360' padding='5'>
                                  <Text regular8 singleLine color={VARIABLES.COLORS.BLACK}>100%</Text>
                                </Table.Column>
                              </Table.Row>
                              <Table.Row>
                                <Table.Column className='is-body-column' alignCenter maxWidth='359' minWidth='359' padding='5'>
                                  <Text bold8 singleLine color={VARIABLES.COLORS.BLACK}>Nămthứ 6 – Nămthứ 7</Text>
                                </Table.Column>
                                <Table.Column className='is-body-column' alignCenter maxWidth='360' minWidth='360' padding='5'>
                                  <Text regular8 singleLine color={VARIABLES.COLORS.BLACK}>70%</Text>
                                </Table.Column>
                              </Table.Row>
                              <Table.Row>
                                <Table.Column className='is-body-column' alignCenter maxWidth='359' minWidth='359' padding='5'>
                                  <Text bold8 singleLine color={VARIABLES.COLORS.BLACK}>Nămthứ 8 – Nămthứ 9</Text>
                                </Table.Column>
                                <Table.Column className='is-body-column' alignCenter maxWidth='360' minWidth='360' padding='5'>
                                  <Text regular8 singleLine color={VARIABLES.COLORS.BLACK}>40%</Text>
                                </Table.Column>
                              </Table.Row>
                              <Table.Row>
                                <Table.Column className='is-body-column' alignCenter maxWidth='359' minWidth='359' padding='5'>
                                  <Text bold8 singleLine color={VARIABLES.COLORS.BLACK}>Nămthứ 10 trởđi</Text>
                                </Table.Column>
                                <Table.Column className='is-body-column' alignCenter maxWidth='360' minWidth='360' padding='5'>
                                  <Text regular8 singleLine color={VARIABLES.COLORS.BLACK}>20%</Text>
                                </Table.Column>
                              </Table.Row>
                            </Table.Section>
                          </Table>
                        </Section>
                        <Section spacingBottom={(osName === 'Mac OS') ? '0' : '-3'}>
                          <Grid>
                            <Grid.Column flex='none'>
                              <Section width='15'>
                                <Text regular8 color={VARIABLES.COLORS.BLACK}>10.</Text>
                              </Section>
                            </Grid.Column>
                            <Grid.Column flex='1'>
                              <Text regular8 color={VARIABLES.COLORS.BLACK}>ThẻBảo Hành không có giá trịsửdụngnếuThẻBảo Hành không còn nguyên vẹn,bịchắp vá, bịtẩy xóa.</Text>
                            </Grid.Column>
                          </Grid>
                        </Section>
                        <Section>
                          <Grid>
                            <Grid.Column flex='none'>
                              <Section width='15'>
                                <Text regular8 color={VARIABLES.COLORS.BLACK}>11.</Text>
                              </Section>
                            </Grid.Column>
                            <Grid.Column flex='1'>
                              <Text regular8 color={VARIABLES.COLORS.BLACK}>Khiếunạichỉđượctiếpnhận khi kèm theo ThẻBảo Hành.</Text>
                            </Grid.Column>
                          </Grid>
                        </Section>
                      </React.Fragment>
                    }
                  </div>
                  <div className="warranty-footer">
                    <div className="warranty-footer-contact">
                      <Section spacingBottom='-3'>
                        <Text bold10 color={VARIABLES.COLORS.WHITE}>CÔNG TY TNHH NS BLUESCOPE VIỆT NAM</Text>
                      </Section>
                      <Section spacingBottom='-3'>
                        <Text bold10 color={VARIABLES.COLORS.WHITE}>Địachỉ: Khu công nghiệp Phú Mỹ 1, phường Phú Mỹ,thị xã Phú Mỹ, Bà Rịa-Vũng Tàu. Điệnthoại: (+84) 25 4392 2666 -  Fax: (+84) 25 4392 2888</Text>
                      </Section>
                      <Text bold10 color={VARIABLES.COLORS.WHITE}>Hotline: 1800 6151</Text>
                    </div>
                    <div className="warranty-footer-copyright">
                      <Section spacingBottom='-3'>
                        <Text regular10 color={VARIABLES.COLORS.WHITE}>Zacs® and BLUESCOPE are registered trademark of  BlueScope Steel Limited.</Text>
                      </Section>
                      <Text regular10 color={VARIABLES.COLORS.WHITE}>Copyright © 2019 by NS BlueScope Vietnam. All rights reserved.</Text>
                    </div>
                  </div>
                </WarrantyWrapper>
              )
            default:
              return (
                <WarrantyWrapper
                  className={classes}
                >
                  {children}
                </WarrantyWrapper>
              )
          }
        })()}
      </React.Fragment>
    )
  }

  static defaultProps = {
    // * If you set new default props, you should create propsType for correct styleguide document.
  }

  static propTypes = { // TYPE > node, string, number, bool, array, object, symbol, func
    /**
    * Additional classes
    */
    className: PropTypes.string
  }
}
