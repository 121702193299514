import { FIREBASE_PUBLIC_STORAGE_PATH } from "../../config/config"

const STATUS_ACTIVE = {
  ACTIVE: 'active',
  INACTIVE: 'inactive',
}

const ORDER_INQUIRY_STATUS = {
  ACKNOWLEDGED: 'Acknowledged',
  PENDING: 'pending',
}

const DATA_TYPE = {
  NEWS: 'news',
  PROMOTION: 'promotion',
}

const CUSTOMER_TYPE = {
  FIXERS: {
    TYPE: 'Fixers / Contractors / Installer / Applicator',
  },
  HARDWARE: {
    TYPE: 'Hardware Store / Construction Shop',
  },
  HOMEOWNER: {
    TYPE: 'Homeowner / Project Owner',
  },
  ROLL_FORMER: {
    TYPE: 'Roll former',
  },
  OTHERS: {
    TYPE: 'Others',
  },
  AUTHORISED_DEALER: {
    TYPE: 'Authorised Dealer',
  },
  // CR: 1483
  ARCHITECT: {
    TYPE: 'Architect / Designer / Consult',
  },
  ENGINEER: {
    TYPE: 'Engineer',
  },
}

const UPLOAD_PATH = {
  IMAGE_PROFILE: '/users',
  IMAGE_CUSTOMER: '/customers',
  WARRANTY: '/warranty',
  DOCUMENTARY: '/documentary',
  PROJECT_DOCUMENT: '/project-document',
  LOG: '/log',
}

const UPLOAD_PATH_FOLDER = {
  PROJECT_DOCUMENT_WARRANTY_TEMPLATE: '/warranty-template',
  PROJECT_DOCUMENT_CERTIFICATE: '/certificate',
  PROJECT_DOCUMENT_DOCUMENTARY: '/documentary',
  PROJECT_DOCUMENT_REFERENCE: '/reference',
}

const DOWNLOAD_PATH = {
  UPLOAD_TRANSACTION_TEMPLATE: `${FIREBASE_PUBLIC_STORAGE_PATH}/rbptrueblue/misc/Upload_transaction_template.csv`,
}

const CMS_TYPE = {
  TRUE_BLUE: 'trueblue',
}

const CUSTOMER_CONTACT_CHANNEL = {
  EMAIL: 'email',
  SMS: 'sms'
}

const COUNTRY = {
  THAI: 'Thailand',
  MALAYSIA: 'Malaysia',
  VIETNAM: 'Vietnam',
  INDONESIA: 'Indonesia',
  LAOS: 'Laos',
}

const CUSTOMER_ROLE_TYPE = {
  THAI: {
    DISTRIBUTOR: 'Distributor',
  },
  MALAYSIA: {
    ROLL_FORMER: 'Roll Former',
    AD: 'Authorized Dealer',
    HARDWARE_SHOP: 'Toko',
    DISTRIBUTOR: 'Distributor',
  },
  INDONESIA: {
    DIRECT_ROLL_FORMER: 'Direct Roll Former',
    LYSAGHT_CENTER: 'Lysaght Center',
    AD: 'Authorized Dealer',
    TOKO: 'Toko',
    DISTRIBUTOR: 'Distributor',
  },
}

export const COUNTRY_TYPE = {
  THAI: 'th',
  MALAYSIA: 'ml',
  VIETNAM: 'vi',
  INDONESIA: 'in',
  LAOS: 'la',
}

const REWARD_STOCK_TYPE = {
  IN_STOCK: 'InStock',
  ON_DEMAND: 'On Demand',
}

const USER_ROLE = {
  OWNER: 'Owner',
  SALES_PERSON: 'Salesperson',
}

const ADMIN_ROLE = {
  TH: 'admin_th',
  ML: 'admin_ml',
  IN: 'admin_id',
  VI: 'admin_vn',
  // LA: 'admin_la',
  // SUPER_ADMIN: 'super_admin',
}

const PROFILE_UNIT = {
  STICK: 'stick (s)',
  SHEET: 'sheet (s)',
}

const PROFILE_TYPE_UNIT = {
  ['Truss C75-75']: PROFILE_UNIT.STICK,
  ['Truss C75-65']: PROFILE_UNIT.STICK,
  ['RengR30-45']: PROFILE_UNIT.STICK,
  ['RengR32-45']: PROFILE_UNIT.STICK,
  ['RengR35-45']: PROFILE_UNIT.STICK,
  ['RengR 30-45']: PROFILE_UNIT.STICK,
  ['RengR 32-45']: PROFILE_UNIT.STICK,
  ['RengR 35-45']: PROFILE_UNIT.STICK,
  ['Long Span 0.25 TCT']: PROFILE_UNIT.SHEET,
  ['Long Span 0.30 TCT']: PROFILE_UNIT.SHEET,
  ['Long Span 0.35 TCT']: PROFILE_UNIT.SHEET,
  ['Metal Tile 0.25 TCT']: PROFILE_UNIT.SHEET,
  ['Metal Tile 0.30 TCT']: PROFILE_UNIT.SHEET,
  ['Metal Tile 0.35 TCT']: PROFILE_UNIT.SHEET
}

const SEGMENT_BUILDING = {
  FACTORY: {
    VALUE: "Factory",
  },
  OFFICE: {
    VALUE: "Office",
  },
  CAR_PARK: {
    VALUE: "Car park",
  },
  WAREHOUSE: {
    VALUE: "Warehouse",
  }
}

const DOCUMENTARY = {
  STATUS: {
    READY: "Ready"
  },
  FOLDER_TYPE: {
    DOCUMENTARY: 1,
    CERTIFICATE: 2,
    WARRANRY_DOCUMENTARY: 3,
  }
}

const CUSTOMER_TRAINED_TIER = {
  GOLD: {
    TEXT: 'Gold',
  },
  SILVER: {
    TEXT: 'Silver',
  },
  BRONZE: {
    TEXT: 'Bronze',
  }
}

const COIL_TRANSFER_CONFIRM_TYPE = {
  NO_APPROVE: 'no_approve',
  APPROVE: 'approve',
}

const PROJECT_DOCUMENT = {
  CERTIFICATE_SETTING_TYPE: {
    PRE_POST: 1,
    PRE: 2,
    POST: 3,
  },
  LOG_CATEGORY: {
    WARRANTY_TEMPLATE: 'WarrantyTemplate',
  },
  INFORMATION_STATUS: {
    NONE: 'None',
    REQUESTING: 'Requesting',
    APPROVED: 'Approved',
  }
}

const WATERMARK_TEMPALTE = {
  'Warranty Documentary': 1,
  'Post-Certificate': 2,
  'Pre-Certificate': 3,
  'Warranty Template': 4,
}

const WARRANTY_QUESTION_TYPE = {
  RADIO: 1,
  INPUT: 2,
  TEXTAREA: 3,
  SELECT: 4
}

const SPECIAL_SEGMENT = [
  {
    EN: 'Farm & Cattle',
    TH: 'ฟาร์ม & ปศุสัตว์'
  }
]

export const ENUM = {
  SEGMENT_BUILDING,
  CUSTOMER_TRAINED_TIER,
  DOCUMENTARY,
  CUSTOMER_TYPE,
  UPLOAD_PATH,
  UPLOAD_PATH_FOLDER,
  CMS_TYPE,
  STATUS_ACTIVE,
  DATA_TYPE,
  ORDER_INQUIRY_STATUS,
  CUSTOMER_CONTACT_CHANNEL,
  COUNTRY,
  COUNTRY_TYPE,
  REWARD_STOCK_TYPE,
  USER_ROLE,
  CUSTOMER_ROLE_TYPE,
  ADMIN_ROLE,
  PROFILE_TYPE_UNIT,
  DOWNLOAD_PATH,
  COIL_TRANSFER_CONFIRM_TYPE,
  PROJECT_DOCUMENT,
  WATERMARK_TEMPALTE,
  WARRANTY_QUESTION_TYPE,
  SPECIAL_SEGMENT,
}