import { isValidResponse } from "../functions/validation";
import { storeDispatch, storeGetState } from "../../stores";
import { dataCreators, DATA_REDUX_STATE_KEY } from "../../actions/dataAction";
import { cmsRewardCatalogController } from "../../apiService/apiController/cmsRewardCatalogService";
import { ModelCMSRewardCatalog } from "../../models/ModelCMSRewardCatalog";
import { ENUM } from "../constants/enum";
import { handleSortParams, handleTrimParams } from "../functions/data";

export const getCMSRewardCatalogList = async (params = {}) => {
  let currentParams = storeGetState().dataRedux.cmsRewardCatalogParams;
  let userAuth = storeGetState().mainRedux.userAuth;
  let newParams = handleTrimParams({
    ...currentParams,
    ...params,
    app: ENUM.CMS_TYPE.TRUE_BLUE,
    search_from: 'trueblue',
    ad_ref_id: userAuth.adRefId,
  });
  if (!newParams.sort) {
    newParams.sort = 'id desc';
  }
  else {
    newParams = handleSortParams(currentParams, newParams);
  }
  const cmsRewardCatalogService = cmsRewardCatalogController();
  const res = await cmsRewardCatalogService.searchRewardCatalog(newParams);
  if (isValidResponse(res)) {
    let dataList = res.data.map(e => new ModelCMSRewardCatalog(e));
    dataList = dataList.filter(e => !e.isNotShowInTrueblue);
    newParams.activePage = newParams.page;
    newParams.totalData = res.total_items;
    dataList = dataList.map(e => {
      (newParams.rewardSelectedList || []).forEach(er => {
        if (er.uniqeId === e.uniqeId) {
          e.unitValue = er.unitValue;
        }
      })
      return e;
    });
    console.log('dataList', res.data, dataList, newParams);
    storeDispatch(dataCreators.setDataRedux(DATA_REDUX_STATE_KEY.CMS_REWARD_CATALOG_LIST, dataList));
    storeDispatch(dataCreators.setDataRedux(DATA_REDUX_STATE_KEY.CMS_REWARD_CATALOG_PARAMS, newParams));
  }
}

export const getClearCMSRewardParams = () => {
  return {
    name: '',
    sort: '',
    rewardSelectedList: [],
  }
}