import React from 'react'
import ClassNames from 'classnames'
// import PropTypes from 'prop-types'
import {
  ModalBase
} from './../../base'
// import {
// } from './../../../components'
// import {
//   default as VARIABLES
// } from './../../../themes/styles/bases/variables'
// import {
//   default as TYPOGRAPHYS
// } from './../../../themes/styles/bases/typographys'
// import {
//   default as UTILITIES
// } from './../../../themes/styles/helpers/utilities'

/**
 * Modal description:
 * - Modal
 */

export class Modal extends React.PureComponent {
  render() {
    const {
      className,
      scroll,
      second,
      fluid,
    } = this.props

    // props for css classes
    const classes = ClassNames(
      className,
      { 'is-scroll': scroll },
      { 'is-second-modal': second },
      { 'is-fluid': fluid }
    )

    return (
      <ModalBase
        {...this.props}
        className={classes}
      />
    )
  }

  static Section = ModalBase.Section

  static defaultProps = {
    // * If you set new default props, you should create propsType for correct styleguide document.
  }

  static propTypes = {
    // TYPE > node, string, number, bool, array, object, symbol, func
    /**
    * props_name_oneType
    */
    // props_name_onetype: PropTypes.string,

    /**
    * props_name_oneMoreType
    */
    // props_name_oneMoreType: PropTypes.oneOfType([
    //   PropTypes.node,
    //   PropTypes.string
    // ])
  }
}
