import apiService from '../apiService'

const apiPath = '/redemption'

export const redemptionController = (configService = {}) => {
  const service = apiService(configService)
  return {
    searchRedemtion: (params) => {
      return service.post(`${apiPath}/search`, params)
    },
    createRedemtion: (params) => {
      return service.post(`${apiPath}`, params)
    },
    updateRedemtion: (params) => {
      return service.put(`${apiPath}/${params.id}`, params)
    },
    deleteRedemtionById: (params) => {
      return service.delete(`${apiPath}/${params.id}`, params)
    },
  }
}
