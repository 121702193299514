import React from 'react'
import ClassNames from 'classnames'
import PropTypes from 'prop-types'
import {
  osName,
} from 'react-device-detect'
import {
  WarrantyProductInformationWrapper
} from './styled'
import {
  Section,
  Text,
  Image,
  Grid,
  Table,
  ShapeContainer,
} from './../../../../components'
import {
  VARIABLES,
} from './../../../../themes'

/**
 * Warranty description:
 * - Warranty
 */

export class WarrantyBSWMThailandProductInformation extends React.PureComponent {
  render() {
    const {
      registeredTrademarkName,
      prefixRegisteredTrademarkName,
      subBrandName,
      customerName,
      projectName,
      projectAddress,
      perforateByCorrosion,
      perforateByCorrosionYear,
      peelOrFlakeExcessively,
      peelOrFlakeExcessivelyYear,
      colourFadeExcessively,
      colourFadeExcessivelyYear,
      discolourByDirtStaining,
      discolourByDirtStainingYear,
      thLanguage,
      isSteelBuildingFrames,
      isColorbond,
      isSupplierRoof,
    } = this.props

    // props for css classes
    const classes = ClassNames(
      'warranty-bswm-note',
    )

    return (
      <WarrantyProductInformationWrapper
        className={classes}
      >
        {thLanguage ?
          <ShapeContainer width='100' widthUnit='%' paddingVertical='10' paddingHorizontal='15' borderWidth='1' borderStyle='solid' borderColor={VARIABLES.COLORS.BLACK} borderRadius='5'>
            <Section>
              <p className='warranty-bswm-text'>
                <span className='warranty-bswm-text is-bold'>บริษัท เอ็นเอส บลูสโคป (ประเทศไทย) จำกัด</span> (ผู้ผลิตเหล็ก{prefixRegisteredTrademarkName} <span className='warranty-bswm-text is-bold'>{registeredTrademarkName}{subBrandName && ' ' + subBrandName}</span>) ขอรับประกันต่อ <br /> <span className='warranty-bswm-text is-bold'>{customerName}</span> (ผู้จัดหาแผ่น{isSupplierRoof ? 'หลังคา' : 'ผนัง'}) ว่า<br />เหล็ก{prefixRegisteredTrademarkName} <span className='warranty-bswm-text is-bold'>{registeredTrademarkName}</span> หากได้รับการติดตั้งและดูแลรักษาอย่างถูกต้อง<br />ในโครงการ <span className='warranty-bswm-text is-bold'>{projectName}</span><br />ณ <span className='warranty-bswm-text is-bold'>{projectAddress}</span> :
              </p>
              {perforateByCorrosionYear &&
                <Section paddingTop='5'>
                  <Grid>
                    <Grid.Column>
                      <Section width='250'><p className='warranty-bswm-text'>- {isSteelBuildingFrames ? 'โครงสร้างไม่พังอันเนื่องมาจากการกัดกร่อน' : 'การไม่ผุเป็นรูอันเนื่องมาจากการกัดกร่อน'}</p></Section>
                    </Grid.Column>
                    <Grid.Column>
                      <p className='warranty-bswm-text is-year'><span className='warranty-bswm-text is-bold'>{perforateByCorrosionYear}</span> Years</p>
                    </Grid.Column>
                  </Grid>
                </Section>
              }
              {colourFadeExcessivelyYear &&
                <Grid>
                  <Grid.Column>
                    <Section width='250'><p className='warranty-bswm-text'>- {isColorbond ? 'การไม่หลุดร่อนหรือหลุดเป็นเกล็ดของสี' : 'สีไม่ซีดจาง'}</p></Section>
                  </Grid.Column>
                  <Grid.Column>
                    <p className='warranty-bswm-text is-year'><span className='warranty-bswm-text is-bold'>{isColorbond ? peelOrFlakeExcessivelyYear : colourFadeExcessivelyYear}</span> Years</p>
                  </Grid.Column>
                </Grid>
              }
              {peelOrFlakeExcessivelyYear &&
                <Grid>
                  <Grid.Column>
                    <Section width='250'><p className='warranty-bswm-text'>- {isColorbond ? 'สีไม่ซีดจางหรือไม่หลุดเป็นผงอย่างรุนแรง' : 'การไม่หลุดร่อนของสี'}</p></Section>
                  </Grid.Column>
                  <Grid.Column>
                    <p className='warranty-bswm-text is-year'><span className='warranty-bswm-text is-bold'>{isColorbond ? colourFadeExcessivelyYear : peelOrFlakeExcessivelyYear}</span> Years</p>
                  </Grid.Column>
                </Grid>
              }

              {discolourByDirtStainingYear &&
                <Grid>
                  <Grid.Column>
                    <Section width='250'><p className='warranty-bswm-text'>- การไม่เกิดรอยด่างจากคราบสะสมของฝุ่น</p></Section>
                  </Grid.Column>
                  <Grid.Column>
                    <p className='warranty-bswm-text is-year'><span className='warranty-bswm-text is-bold'>{discolourByDirtStainingYear}</span> Years</p>
                  </Grid.Column>
                </Grid>
              }
            </Section>
          </ShapeContainer>
          :
          <ShapeContainer width='100' widthUnit='%' paddingVertical='10' paddingHorizontal='15' borderWidth='1' borderStyle='solid' borderColor={VARIABLES.COLORS.BLACK} borderRadius='5'>
            <Section>
              <p className='warranty-bswm-text'>
                <span className='warranty-bswm-text is-bold'>NS BlueScope (Thailand) Company Limited</span> (manufacturer of <span className='warranty-bswm-text is-bold'>{registeredTrademarkName}{subBrandName && ' ' + subBrandName}</span> steel), warrants to <span className='warranty-bswm-text is-bold'>{customerName}</span> (supplier of profiled sheets) that the <span className='warranty-bswm-text is-bold'>{registeredTrademarkName}</span> steel if correctly installed and maintained on the <span className='warranty-bswm-text is-bold'>{projectName}</span><br />project at <span className='warranty-bswm-text is-bold'>{projectAddress}</span> :
              </p>
              {perforateByCorrosionYear &&
                <Section paddingTop='5'>
                  <Grid>
                    <Grid.Column>
                      <Section width='250'><p className='warranty-bswm-text'>- {isSteelBuildingFrames ? 'Shall not structural failure by corrosion for' : perforateByCorrosion}:</p></Section>
                    </Grid.Column>
                    <Grid.Column>
                      <p className='warranty-bswm-text is-year'><span className='warranty-bswm-text is-bold'>{perforateByCorrosionYear}</span> Years</p>
                    </Grid.Column>
                  </Grid>
                </Section>
              }
              {peelOrFlakeExcessivelyYear &&
                <Grid>
                  <Grid.Column>
                    <Section width='250'><p className='warranty-bswm-text'>- {isColorbond ? 'Shall not peel or flake excessively' : peelOrFlakeExcessively}:</p></Section>
                  </Grid.Column>
                  <Grid.Column>
                    <p className='warranty-bswm-text is-year'><span className='warranty-bswm-text is-bold'>{peelOrFlakeExcessivelyYear}</span> Years</p>
                  </Grid.Column>
                </Grid>
              }
              {colourFadeExcessivelyYear &&
                <Grid>
                  <Grid.Column>
                    <Section width='250'>
                      <p className='warranty-bswm-text'>- {isColorbond ? 'Shall not fade and chalk excessively' : colourFadeExcessively}:</p>
                    </Section>
                  </Grid.Column>
                  <Grid.Column>
                    <p className='warranty-bswm-text is-year'><span className='warranty-bswm-text is-bold'>{colourFadeExcessivelyYear}</span> Years</p>
                  </Grid.Column>
                </Grid>
              }
              {discolourByDirtStainingYear &&
                <Grid>
                  <Grid.Column>
                    <Section width='250'><p className='warranty-bswm-text'>- {discolourByDirtStaining}:</p></Section>
                  </Grid.Column>
                  <Grid.Column>
                    <p className='warranty-bswm-text is-year'><span className='warranty-bswm-text is-bold'>{discolourByDirtStainingYear}</span> Years</p>
                  </Grid.Column>
                </Grid>
              }
            </Section>
          </ShapeContainer>
        }
      </WarrantyProductInformationWrapper>
    )
  }

  static defaultProps = {
    // If you set new default props, you should create propsType for correct styleguide document.
    registeredTrademarkName: '< Registered Trademark Name >',
    prefixRegisteredTrademarkName: '< Prefix Registered Trademark Name >',
    customerName: '< Customer Name >',
    projectName: '< Full Project Name >',
    projectAddress: '< Full Project Address >',
    perforateByCorrosion: 'Shall not perforate by corrosion for',
    perforateByCorrosionYear: 'XX',
    peelOrFlakeExcessively: 'Shall not perforate by color peeling for', // Shall not peel or flake excessively for
    peelOrFlakeExcessivelyYear: 'XX',
    colourFadeExcessively: 'Shall not colour fade excessively for',
    colourFadeExcessivelyYear: 'XX',
    discolourByDirtStaining: 'Shall not discolour by dirt staining for',
    discolourByDirtStainingYear: 'XX',
    isSteelBuildingFrames: false,
    isColorbond: false,
    isSupplierRoof: true,
  }

  static propTypes = {
    // TYPE > node, string, number, bool, array, object, symbol, func
  }
}
