import React from 'react'
import {
  ShapeContainer,
  Section,
  Breadcrumb,
  TitlePage,
  Grid,
  Field,
  Button,
  Text,
  TitleSection,
  Image,
  SelectControl
} from './../../components'
import {
  VARIABLES,
  ICONS
} from './../../themes'
import { setSideMenuActive, getRouteParams, getRouteData } from '../../helpers/functions/route';
import { ROUTE_PATH } from '../../helpers/constants/route';
import { redirect } from '../../helpers/functions/route';
import { ENUM } from '../../helpers/constants/enum';
import { toast } from '../../helpers/functions/main';
import { isValidResponse } from '../../helpers/functions/validation';
import { orderInquiryController } from '../../apiService/apiController/orderInquiryService';
import _ from 'lodash';
import { getLanguage } from '../../helpers/functions/language';
import { getCountryPhone } from '../../helpers/functions/data';
import { getOrderInquiryList } from '../../helpers/services/orderInquiry';
import { firebaseSendOrderInquiry } from '../../helpers/firebases/firebaseNotification';

export class OrderInquiryManageScreen extends React.Component {

  constructor(props) {
    super(props);
    setSideMenuActive(props);
    const dataId = parseInt(getRouteParams(props, 'id'));
    const routeData = getRouteData(props, 'data');
    this.state = {
      dataId,
      routeData,
      ...this.orderInquiryManage.setData(routeData)
    };
    console.log('dataId', dataId)
  }

  componentDidMount() {
    const {
      routeData,
    } = this.state;
    if (!routeData) {
      this.orderInquiryManage.getOrderInquiryDetail();
    }
  }

  orderInquiryManage = {
    setData: (newData) => {
      const selectedOptionList = [{
        value: ENUM.ORDER_INQUIRY_STATUS.PENDING,
        label: getLanguage('orderInquiryManage.form.information.pending', '')
      },
      {
        value: ENUM.ORDER_INQUIRY_STATUS.ACKNOWLEDGED,
        label: getLanguage('orderInquiryManage.form.information.acknowledged', '')
      }];
      const dataDefault = {
        phoneCountry: getCountryPhone(),
        status: ENUM.ORDER_INQUIRY_STATUS.PENDING,
      }
      const data = {
        ...dataDefault,
        ...newData,
      }
      const selectedOption = data.status === ENUM.ORDER_INQUIRY_STATUS.PENDING ? selectedOptionList[0] : selectedOptionList[1];
      console.log('data', data);
      return {
        data,
        selectedOptionList,
        selectedOption,
      }
    },
    getOrderInquiryDetail: async () => {
      const {
        dataId,
      } = this.state;
      const params = {
        page: 1,
        id: dataId,
      };
      const dataList = await getOrderInquiryList(params);
      if (dataList[0]) {
        this.setState({
          ...this.orderInquiryManage.setData(dataList[0])
        });
      }
      else {
        this.orderInquiryManage.goBack();
      }
    },
    goBack: () => {
      redirect(ROUTE_PATH.ORDER_INQUIRY.LINK)
    },
    onClickSubmit: () => {
      this.orderInquiryManage.handleManageData()
    },
    handleManageData: async () => {
      const {
        data,
        dataId,
        selectedOption
      } = this.state;
      const params = {
        id: dataId,
        status: selectedOption.value,
        remark: data.remark
      }
      const orderInquiryService = orderInquiryController();
      let res = await orderInquiryService.updateOrderInquiryById(params);
      console.log('res', res)
      if (isValidResponse(res)) {
        firebaseSendOrderInquiry({
          userId: data.userId
        });
        this.orderInquiryManage.goBack();
        toast.success(getLanguage('message.updateAdCompleted', ''));
      }
      else {
        toast.error(res.message);
      }
    },
  }

  input = {
    onChangeSearchOption: (selectedOption) => {
      this.setState({
        selectedOption
      })
    },
    onChangeDataInput: (ev) => {
      const {
        data,
      } = this.state
      const name = ev.target.name;
      let value = ev.target.value;
      data[name] = value;
      this.setState({
        data,
      });
    },
  }

  render() {
    const {
      data,
      selectedOptionList,
      selectedOption
    } = this.state;
    return (
      <React.Fragment>
        <Breadcrumb>
          <Breadcrumb.List
            link
            name={getLanguage('orderInquiryManage.breadcrumb1', '')}
            onClick={() => {
              redirect(ROUTE_PATH.HOME.LINK)
            }}
          />
          <Breadcrumb.List name='/' />
          <Breadcrumb.List
            link
            name={getLanguage('orderInquiryManage.breadcrumb2', '')}
            onClick={this.orderInquiryManage.goBack}
          />
          <Breadcrumb.List name='/' />
          <Breadcrumb.List name={data.idWithPrefix} />
        </Breadcrumb>
        <Section ui='content'>
          <Section spacingBottom='35'>
            <TitlePage
              src={ICONS['ic-page-order-inquiry.svg']}
              name={`${getLanguage('orderInquiryManage.breadcrumb3', '')} : ${data.idWithPrefix}`}
            />
          </Section>
          <ShapeContainer
            ui='content'
            justify='space-between'
          >
            <Section spacingBottom='45'>
              <Section spacingBottom='35'>
                <TitleSection name={getLanguage('orderInquiryManage.title', '')} />
              </Section>
              <Section spacingBottom='10'>
                <Text
                  regular14
                  color={VARIABLES.COLORS.GRAY_19}
                >
                  {getLanguage('orderInquiryManage.form.information.subject', '')}
                </Text>
              </Section>
              <Section spacingBottom='25'>
                <Text
                  regular14
                  color={VARIABLES.COLORS.BLACK}
                >
                  {data.subject}
                </Text>
              </Section>
              <Section spacingBottom='10'>
                <Text
                  regular14
                  color={VARIABLES.COLORS.GRAY_19}
                >
                  {getLanguage('orderInquiryManage.form.information.description', '')}
                </Text>
              </Section>
              <Section spacingBottom='25'>
                <Grid>
                  <Grid.Column
                    gridTablet='12'
                    gridDesktop='10'
                  >
                    <Text
                      regular14
                      color={VARIABLES.COLORS.BLACK}
                    >
                      {data.description}
                    </Text>
                  </Grid.Column>
                </Grid>
              </Section>
              <Section spacingBottom='25'>
                <Grid
                  gutter='75'
                  gutterVertical='25'
                >
                  <Grid.Column
                    gridTablet='4'
                    gridLargeDesktop='2'
                  >
                    <Section spacingBottom='10'>
                      <Text
                        regular14
                        color={VARIABLES.COLORS.GRAY_19}
                      >
                        {getLanguage('orderInquiryManage.form.information.customer', '')}
                      </Text>
                    </Section>
                    <Section>
                      <Text
                        regular14
                        color={VARIABLES.COLORS.BLACK}
                      >
                        {data.customerName}
                      </Text>
                    </Section>
                  </Grid.Column>
                  <Grid.Column
                    gridTablet='4'
                    gridLargeDesktop='2'
                  >
                    <Section spacingBottom='10'>
                      <Text
                        regular14
                        color={VARIABLES.COLORS.GRAY_19}
                      >
                        {getLanguage('orderInquiryManage.form.information.phoneNo', '')}
                      </Text>
                    </Section>
                    <Section>
                      <Image
                        ui='flagWithPhoneIdAndNumber'
                        phoneId={data.phoneCountry.value}
                        phoneNumber={data.phone}
                        src={data.phoneCountry.flag}
                      />
                    </Section>
                  </Grid.Column>
                  <Grid.Column
                    gridTablet='4'
                    gridLargeDesktop='2'
                  >
                    <Section spacingBottom='10'>
                      <Text
                        regular14
                        color={VARIABLES.COLORS.GRAY_19}
                      >
                        {getLanguage('orderInquiryManage.form.information.email', '')}
                      </Text>
                    </Section>
                    <Section>
                      <Text
                        regular14
                        color={VARIABLES.COLORS.BLACK}
                      >
                        {data.email}
                      </Text>
                    </Section>
                  </Grid.Column>
                </Grid>
              </Section>
              <Section spacingBottom='25'>
                <Grid
                  gutter='75'
                  gutterVertical='25'
                >
                  <Grid.Column
                    gridTablet='4'
                    gridLargeDesktop='2'
                  >
                    <Section spacingBottom='10'>
                      <Text
                        regular14
                        color={VARIABLES.COLORS.GRAY_19}
                      >
                        {getLanguage('orderInquiryManage.form.information.submittedOn', '')}
                      </Text>
                    </Section>
                    <Section>
                      <Text
                        regular14
                        color={VARIABLES.COLORS.BLACK}
                      >
                        {data.displaySubmitAt}
                      </Text>
                    </Section>
                  </Grid.Column>
                  <Grid.Column
                    gridTablet='4'
                    gridLargeDesktop='2'
                  >
                    <Section spacingBottom='10'>
                      <Text
                        regular14
                        color={VARIABLES.COLORS.GRAY_19}
                      >
                        {getLanguage('orderInquiryManage.form.information.responseDate', '')}
                      </Text>
                    </Section>
                    <Section>
                      <Text
                        regular14
                        color={VARIABLES.COLORS.BLACK}
                      >
                        {data.displayResponseAt}
                      </Text>
                    </Section>
                  </Grid.Column>
                </Grid>
              </Section>
              <Section spacingBottom='10'>
                <Text
                  regular14
                  color={VARIABLES.COLORS.GRAY_19}
                >
                  {getLanguage('orderInquiryManage.form.information.status', '')}
                </Text>
              </Section>
              <Section spacingBottom='25'>
                <SelectControl
                  verticalLabel
                  isSearchable={false}
                  orderInquirySubmitted={selectedOption.value === ENUM.ORDER_INQUIRY_STATUS.PENDING}
                  orderInquiryAcknownledged={selectedOption.value === ENUM.ORDER_INQUIRY_STATUS.ACKNOWLEDGED}
                  width='250'
                  value={selectedOption}
                  onChange={this.input.onChangeSearchOption}
                  options={selectedOptionList}
                />
              </Section>
              <Section spacingBottom='10'>
                <Text
                  regular14
                  color={VARIABLES.COLORS.GRAY_19}
                >
                  {getLanguage('field.commentRemark', '')}
                </Text>
              </Section>
              <Section>
                <Grid>
                  <Grid.Column
                    gridTablet='12'
                    gridDesktop='10'
                  >
                    <Field name='remark'
                      fluid
                      ui='blockLabelVerticalTextarea'
                      placeholder={getLanguage('field.commentRemarkPlaceholder', '')}
                      value={data.remark}
                      onChange={this.input.onChangeDataInput}
                    />
                  </Grid.Column>
                </Grid>
              </Section>
            </Section>
            <Section>
              <Grid
                gutter='15'
                justify='flex-end'
              >
                <Grid.Column>
                  <Button
                    gray
                    ui='back'
                    name={getLanguage('field.buttonBack', '')}
                    width={VARIABLES.BUTTON.WIDTHS.W_155}
                    onClick={this.orderInquiryManage.goBack}
                  />
                </Grid.Column>
                <Grid.Column>
                  <Button
                    blue
                    name={getLanguage('field.buttonSave', '')}
                    width={VARIABLES.BUTTON.WIDTHS.W_155}
                    onClick={this.orderInquiryManage.onClickSubmit}
                  />
                </Grid.Column>
              </Grid>
            </Section>
          </ShapeContainer>
        </Section>
      </React.Fragment>
    )
  }
}
