import React from 'react';
import {
  connect,
} from 'react-redux';
import {
  ShapeContainer,
  Section,
  TableAction,
  Grid,
  Text,
  Button,
  PaginationControl,
  Table,
  Image,
} from './../../components';
import {
  VARIABLES,
  ICONS
} from './../../themes';
import _ from 'lodash';
import { getLanguage } from '../../helpers/functions/language';
import { getUploadTransactionSearchFileDetailList, getClearUploadTransactionHistoryDetailParams } from '../../helpers/services/uploadTransactionHistoryDetail';
import { redirect, getRouteParams } from '../../helpers/functions/route';
import { ROUTE_PATH } from '../../helpers/constants/route';

class UploadTransactionHistoryDetailListContainer extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      activePage: 1,
      dataPerPage: 10,
    }
  }

  componentDidMount() {
    const {
      dataId,
    } = this.props;
    const {
      activePage,
      dataPerPage,
    } = this.state;
    const params = {
      ...getClearUploadTransactionHistoryDetailParams(),
      log_id: dataId,
      page: activePage,
      limit: dataPerPage,
    };
    getUploadTransactionSearchFileDetailList(params);
  }

  uploadTransactionHistoryDetail = {
    onClickSort: (target) => {
      getUploadTransactionSearchFileDetailList({
        isNoSort: false,
        sort: target
      });
    },
  }

  pagination = {
    onPaginationChange: (pageNumber) => {
      getUploadTransactionSearchFileDetailList({
        isNoSort: true,
        page: pageNumber,
      });
    }
  }

  render() {
    const {
      dataList,
      totalData,
      activePage,
      dataParams,
    } = this.props;
    const {
      dataPerPage,
    } = this.state;
    return (
      <ShapeContainer
        ui='content'
        justify='space-between'
      >
        <Section spacingBottom='45'>
          <Table
            striped
            action
          >
            <Table.Section>
              <Table.Row>
                <Table.HeadColumn
                  noSort
                  alignCenter
                  maxWidth={VARIABLES.TABLE.INFO_WIDTHS.STATUS_TRANSACTION_FILE}
                  minWidth={VARIABLES.TABLE.INFO_WIDTHS.STATUS_TRANSACTION_FILE}
                // sortAscending
                // onClickSort={() => {}}
                >
                  {getLanguage('uploadTransactionHistory.table.header.status', '')}
                </Table.HeadColumn>
                <Table.HeadColumn
                  noSort
                  minWidth={VARIABLES.TABLE.INFO_WIDTHS.INVOICE_NUMBER}
                // sortAscending
                // onClickSort={() => {}}
                >
                  {getLanguage('uploadTransactionHistory.table.header.invoiceNumber', '')}
                </Table.HeadColumn>
                <Table.HeadColumn
                  noSort
                  minWidth={VARIABLES.TABLE.INFO_WIDTHS.INVOICE_LINE}
                // sortAscending
                // onClickSort={() => {}}
                >
                  {getLanguage('uploadTransactionHistory.table.header.invoiceLine', '')}
                </Table.HeadColumn>
                <Table.HeadColumn
                  noSort
                  maxWidth={VARIABLES.TABLE.INFO_WIDTHS.ACTION_ERROR}
                  minWidth={VARIABLES.TABLE.INFO_WIDTHS.ACTION_ERROR}
                >
                  {getLanguage('uploadTransactionHistory.table.header.error', '')}
                </Table.HeadColumn>
                <Table.HeadColumn
                  noSort
                  minWidth={VARIABLES.TABLE.INFO_WIDTHS.SALESPERSON_FULL_NAME}
                // sortAscending
                // onClickSort={() => {}}
                >
                  {getLanguage('uploadTransactionHistory.table.header.saleperson', '')}
                </Table.HeadColumn>
                <Table.HeadColumn
                  noSort
                  minWidth={VARIABLES.TABLE.INFO_WIDTHS.PHONE_NO_SORT}
                // sortAscending
                // onClickSort={() => {}}
                >
                  {getLanguage('uploadTransactionHistory.table.header.phone', '')}
                </Table.HeadColumn>
                <Table.HeadColumn
                  noSort
                  minWidth={VARIABLES.TABLE.INFO_WIDTHS.EMAIL_FULL}
                // sortAscending
                // onClickSort={() => {}}
                >
                  {getLanguage('uploadTransactionHistory.table.header.email', '')}
                </Table.HeadColumn>
                <Table.HeadColumn
                  noSort
                  minWidth={VARIABLES.TABLE.INFO_WIDTHS.COIL_ID_FULL}
                // sortAscending
                // onClickSort={() => {}}
                >
                  {getLanguage('uploadTransactionHistory.table.header.coilId', '')}
                </Table.HeadColumn>
                <Table.HeadColumn
                  noSort
                  alignRight
                  minWidth={VARIABLES.TABLE.INFO_WIDTHS.LENGTH_SOLD}
                // sortAscending
                // onClickSort={() => {}}
                >
                  {getLanguage('uploadTransactionHistory.table.header.lengthSold', '')}
                </Table.HeadColumn>
                <Table.HeadColumn
                  noSort
                  minWidth={VARIABLES.TABLE.INFO_WIDTHS.PROJECT_NAME_FULL}
                // sortAscending
                // onClickSort={() => {}}
                >
                  {getLanguage('uploadTransactionHistory.table.header.projectName', '')}
                </Table.HeadColumn>
                <Table.HeadColumn
                  noSort
                  minWidth={VARIABLES.TABLE.INFO_WIDTHS.HOMEOWNER_FULL}
                // sortAscending
                // onClickSort={() => {}}
                >
                  {getLanguage('uploadTransactionHistory.table.header.homeowner', '')}
                </Table.HeadColumn>
                <Table.HeadColumn
                  noSort
                  minWidth={VARIABLES.TABLE.INFO_WIDTHS.DATE_PURCHASING_WITH_TIME}
                // sortAscending
                // onClickSort={() => {}}
                >
                  {getLanguage('uploadTransactionHistory.table.header.purchaseDate', '')}
                </Table.HeadColumn>
                <Table.HeadColumn
                  noSort
                  alignRight
                  minWidth={VARIABLES.TABLE.INFO_WIDTHS.PURCHASE_VALUE}
                // sortAscending
                // onClickSort={() => {}}
                >
                  {getLanguage('uploadTransactionHistory.table.header.purchaseValue', '')}
                </Table.HeadColumn>
              </Table.Row>
            </Table.Section>
            <Table.Section>
              {dataList.map((e, i) => {
                return (
                  <Table.Row key={i}>
                    <Table.BodyColumn
                      info
                      alignCenter
                      maxWidth={VARIABLES.TABLE.INFO_WIDTHS.STATUS_TRANSACTION_FILE}
                      minWidth={VARIABLES.TABLE.INFO_WIDTHS.STATUS_TRANSACTION_FILE}
                    >
                      <Image ui={e.isError ? 'iconStatusFail' : 'iconStatusSuccess'} top='-1' />
                    </Table.BodyColumn>
                    <Table.BodyColumn
                      info
                      minWidth={VARIABLES.TABLE.INFO_WIDTHS.INVOICE_NUMBER}
                      title={e.invoiceNo}
                    >
                      {e.invoiceNo}
                    </Table.BodyColumn>
                    <Table.BodyColumn
                      info
                      minWidth={VARIABLES.TABLE.INFO_WIDTHS.INVOICE_LINE}
                    >
                      {e.invoiceLine}
                    </Table.BodyColumn>
                    <Table.BodyColumn
                      action
                      maxWidth={VARIABLES.TABLE.INFO_WIDTHS.ACTION_ERROR}
                      minWidth={VARIABLES.TABLE.INFO_WIDTHS.ACTION_ERROR}
                      title={e.isError ? 'e.message' : '-'}
                    >
                      {
                        false ?
                          <Section>
                            <Image
                              ui='iconErrorNote'
                              top='-1'
                              onClick={this.step.onClickOpenErrorDetail}
                            />
                          </Section>
                          :
                          <Text
                            regular12
                            color={VARIABLES.COLORS.GRAY_19}
                            top='1'
                          >
                            {e.isError ? e.message : '-'}
                          </Text>
                      }
                    </Table.BodyColumn>
                    <Table.BodyColumn
                      info
                      minWidth={VARIABLES.TABLE.INFO_WIDTHS.SALESPERSON_FULL_NAME}
                      title={e.salesPerson}
                    >
                      {e.salesPerson}
                    </Table.BodyColumn>
                    <Table.BodyColumn
                      info
                      minWidth={VARIABLES.TABLE.INFO_WIDTHS.PHONE_NO_SORT}
                    >
                      {e.phone}
                    </Table.BodyColumn>
                    <Table.BodyColumn
                      info
                      minWidth={VARIABLES.TABLE.INFO_WIDTHS.EMAIL_FULL}
                      title={e.email}
                    >
                      {e.email}
                    </Table.BodyColumn>
                    <Table.BodyColumn
                      info
                      minWidth={VARIABLES.TABLE.INFO_WIDTHS.COIL_ID_FULL}
                    >
                      {e.coilNumber}
                    </Table.BodyColumn>
                    <Table.BodyColumn
                      info
                      alignRight
                      minWidth={VARIABLES.TABLE.INFO_WIDTHS.LENGTH_SOLD}
                    >
                      {e.lengthSold}
                    </Table.BodyColumn>
                    <Table.BodyColumn
                      info
                      minWidth={VARIABLES.TABLE.INFO_WIDTHS.PROJECT_NAME_FULL}
                      title={e.projectName}
                    >
                      {e.projectName}
                    </Table.BodyColumn>
                    <Table.BodyColumn
                      info
                      minWidth={VARIABLES.TABLE.INFO_WIDTHS.HOMEOWNER_FULL}
                      title={e.homeOwner}
                    >
                      {e.homeOwner}
                    </Table.BodyColumn>
                    <Table.BodyColumn
                      info
                      minWidth={VARIABLES.TABLE.INFO_WIDTHS.DATE_PURCHASING_WITH_TIME}
                    >
                      {e.displayCreatedAt}
                    </Table.BodyColumn>
                    <Table.BodyColumn
                      info
                      alignRight
                      minWidth={VARIABLES.TABLE.INFO_WIDTHS.PURCHASE_VALUE}
                    >
                      {e.lengthSoldWithComma}
                    </Table.BodyColumn>
                  </Table.Row>
                )
              })}
            </Table.Section>
          </Table>
        </Section>
        <TableAction>
          <TableAction.Left>
            <PaginationControl
              activePage={activePage}
              itemsCountPerPage={dataPerPage}
              totalItemsCount={totalData}
              pageRangeDisplayed={3}
              onChange={this.pagination.onPaginationChange}
            />
          </TableAction.Left>
          <TableAction.Right>
            <Button
              gray
              ui='back'
              name={getLanguage('field.buttonBack', '')}
              onClick={() => {
                redirect(ROUTE_PATH.UPLOAD_TRANSACTION_HISTORY.LINK)
              }}
            />
          </TableAction.Right>
        </TableAction>
      </ShapeContainer>
    )
  }
}

const mapStateToProps = (state) => {
  const dataRedux = state.dataRedux;
  return {
    dataList: dataRedux.uploadTransactionHistoryDetailList,
    dataParams: dataRedux.uploadTransactionHistoryDetailParams,
    activePage: dataRedux.uploadTransactionHistoryDetailParams.activePage,
    totalData: dataRedux.uploadTransactionHistoryDetailParams.totalData || 0,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
  }
}

export const UploadTransactionHistoryDetailListContainerConnected = connect(
  mapStateToProps,
  mapDispatchToProps
)(UploadTransactionHistoryDetailListContainer)