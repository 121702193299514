import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  Section,
  Grid,
  Modal,
  Text,
  Button,
  Field,
  Table,
  PaginationControl,
} from './../../../components';
import {
  VARIABLES,
  ICONS
} from './../../../themes';
import _ from 'lodash';
// import { storeDispatch } from '../../../stores';
import { handleSortData } from '../../../helpers/functions/data';
import { getLanguage } from '../../../helpers/functions/language';
import { searchProfile } from '../../../helpers/services/profile';
import { dataCreators, DATA_REDUX_STATE_KEY } from '../../../actions/dataAction';
import { redirect } from '../../../helpers/functions/route';
import { ROUTE_PATH } from '../../../helpers/constants/route';

export class ModalHardwareAndTokoShopProfileContainer extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      activePage: 1,
      dataPerPage: 10,
      profileSelect: {},
      isDisabledButtonSave: true,
      filter: '',
      filterValue: '',
      sortIcon: {
        id: false,
        profileName: true,
        phoneNo: true,
        email: true,
      }
    };
  }

  componentDidMount() {
    searchProfile()
  }

  profile = {
    getFilterDataPaginationList: () => {
      const {
        dataList,
      } = this.props;

      const {
        activePage,
        dataPerPage
      } = this.state;

      const firstIndex = (activePage - 1) * dataPerPage;
      const lastIndex = activePage * dataPerPage;

      if (activePage && dataPerPage && dataList) {
        return this.profile.getFilterDataList().filter((e, i) => i >= firstIndex && i < lastIndex);
      }
      return dataList;
    },
    getFilterDataList: () => {
      const {
        dataList,
      } = this.props;
      const {
        filterValue = '',
      } = this.state;
      return dataList.filter((e, i) => {
        if (filterValue.length) {
          if (e.adRfName.toLowerCase().indexOf(filterValue.toLowerCase()) === -1 &&
            e.phoneNo.toLowerCase().indexOf(filterValue.toLowerCase()) === -1 &&
            e.email.toLowerCase().indexOf(filterValue.toLowerCase()) === -1
          ) {
            return false;
          }
        }
        return true;
      })
    },
    onClickSelect: (data) => {
      const {
        dataList,
      } = this.props;
      dataList.forEach(e => {
        if (e.id === data.id) {
          this.setState({
            profileSelect: e,
            isDisabledButtonSave: false
          })
        }
      })
    },
    onClickSort: (type) => {
      const {
        dataList,
      } = this.props;
      const newSortIcon = this.profile.handleSortIcon(type);
      const tempDataList = handleSortData(dataList, {
        sortTarget: type,
        orderType: newSortIcon[type] ? 'desc' : 'asc'
      });
      this.profile.updateData(tempDataList);
    },
    updateData: (dataList) => {
      const {
        dataActions
      } = this.props

      dataActions.setDataRedux(DATA_REDUX_STATE_KEY.PROFILE_LIST, dataList)
      // storeDispatch(dataCreators.setDataRedux(DATA_REDUX_STATE_KEY.AD_RF_LIST, dataList));
    },
    handleSortIcon: (type) => {
      const {
        sortIcon,
      } = this.state;
      let newSortIcon = {};
      let oldType = type;
      Object.keys(sortIcon).forEach(e => {
        if (sortIcon[e] === false) {
          oldType = e;
        }
        newSortIcon[e] = true;
      });
      Object.keys(sortIcon).forEach(e => {
        if (e === type) {
          if (type === oldType) {
            newSortIcon[e] = !sortIcon[e];
          }
        }
      })
      this.setState({
        sortIcon: newSortIcon
      })
      return newSortIcon
    },
  }

  input = {
    onChangeInput: (ev) => {
      this.setState({
        [ev.target.name]: ev.target.value
      })
    },
    onClickFilter: () => {
      const {
        dataActions,
        profileParams
      } = this.props;
      const {
        filter,
      } = this.state;

      this.setState({
        filterValue: filter,
      }, () => {
        profileParams.activePage = 1;
        dataActions.setDataRedux(DATA_REDUX_STATE_KEY.PROFILE_PARAMS, profileParams)
      });
    },
  }

  onClickSave() {
    const {
      profileSelect
    } = this.state;
    redirect(ROUTE_PATH.PROFILE_MAPPING_HARDWARE_AND_TOKO_SHOP_INFO.LINK, { profileSelect })
  }

  pagination = {
    onPaginationChange: (pageNumber) => {
      this.setState({
        activePage: pageNumber,
      })
    }
  }

  render() {
    const {
      isOpenModal,
      onClickClose,
    } = this.props;

    const {
      activePage,
      dataPerPage,
      profileSelect,
      isDisabledButtonSave,
      sortIcon,
    } = this.state;

    const dataList = this.profile.getFilterDataPaginationList()

    // console.log('dataList', dataList)

    return (
      <React.Fragment>
        <Modal
          open={isOpenModal}
          scroll
          // enableOverlayClose={false}
          width={VARIABLES.MODAL.WIDTHS.W_1145}
          overlayColor={VARIABLES.COLORS.OVERLAY_BLACK_1}
          spacing={VARIABLES.MODAL.CONTAINER.C_1.SPACING}
          transition={VARIABLES.TRANSITIONS.DEFAULT}
          transformStart={VARIABLES.MODAL.CONTAINER.C_1.TRANSFORM_START}
          transformEnd={VARIABLES.MODAL.CONTAINER.C_1.TRANSFORM_END}
          onClickClose={onClickClose}
        >
          <Modal.Section
            paddingVertical='30'
            paddingHorizontal='40'
            bgColor={VARIABLES.COLORS.WHITE}
            borderBottomWidth='1'
            borderBottomStyle='solid'
            borderBottomColor={VARIABLES.COLORS.GRAY_8}
            borderTopRightRadius={VARIABLES.MODAL.HEADER.H_1.BORDER_TOP_RIGHT_RADIUS}
            borderTopLeftRadius={VARIABLES.MODAL.HEADER.H_1.BORDER_TOP_LEFT_RADIUS}
          >
            <Text
              regular16
              singleLine
              color={VARIABLES.COLORS.BLACK}
            >
              {getLanguage('modal.hardwareAndTokoShop.title', '')}
            </Text>
          </Modal.Section>
          <Modal.Section
            paddingTop='20'
            paddingBottom='30'
            paddingHorizontal='45'
            bgColor={VARIABLES.COLORS.WHITE}
          >
            <Section spacingBottom='25'>
              <Grid
                gutter='10'
                justify='flex-end'
              >
                <Grid.Column>
                  <Field
                    border
                    name='filter'
                    ui='blockLabelVerticalSearch'
                    width='400'
                    placeholder={getLanguage('modal.hardwareAndTokoShop.placeholder', '')}
                    // value={}
                    onChange={this.input.onChangeInput}
                  />
                </Grid.Column>
                <Grid.Column>
                  <Button
                    lightBlue
                    name={getLanguage('modal.hardwareAndTokoShop.buttonSearch', '')}
                    width={VARIABLES.BUTTON.WIDTHS.W_105}
                    onClick={this.input.onClickFilter}
                  />
                </Grid.Column>
              </Grid>
            </Section>
            <Section spacingBottom='30'>
              <Table
                striped
                actionCheckbox
              >
                <Table.Section>
                  <Table.Row>
                    <Table.HeadColumn
                      maxWidth={VARIABLES.TABLE.COLUMN_WIDTHS.IDS.INDEX}
                      minWidth={VARIABLES.TABLE.COLUMN_WIDTHS.IDS.INDEX}
                      sortAscending={sortIcon.id}
                      onClickSort={() => {
                        this.profile.onClickSort('id');
                      }}
                    >
                      {getLanguage('modal.hardwareAndTokoShop.table.header.id', '')}
                    </Table.HeadColumn>
                    <Table.HeadColumn
                      minWidth={VARIABLES.TABLE.COLUMN_WIDTHS.NAMES.FULL}
                      sortAscending={sortIcon.profileName}
                      onClickSort={() => {
                        this.profile.onClickSort('profileName');
                      }}
                    >
                      {getLanguage('modal.hardwareAndTokoShop.table.header.name', '')}
                    </Table.HeadColumn>
                    {/* <Table.HeadColumn                    
                      minWidth={VARIABLES.TABLE.COLUMN_WIDTHS.PHONE}
                      sortAscending={sortIcon.phoneNo}
                      onClickSort={() => {
                        this.profile.onClickSort('phoneNo');
                      }}
                    >
                      {getLanguage('modal.hardwareAndTokoShop.table.header.phoneNo', '')}
                    </Table.HeadColumn> */}
                    {/* <Table.HeadColumn
                      minWidth={VARIABLES.TABLE.COLUMN_WIDTHS.EMAIL}
                      sortAscending={sortIcon.email}
                      onClickSort={() => {
                        this.profile.onClickSort('email');
                      }}
                    >
                      {getLanguage('modal.hardwareAndTokoShop.table.header.email', '')}
                    </Table.HeadColumn> */}
                    <Table.HeadColumn
                      alignCenter
                      noSort
                      maxWidth={VARIABLES.TABLE.COLUMN_WIDTHS.TRAIN}
                      minWidth={VARIABLES.TABLE.COLUMN_WIDTHS.TRAIN}
                    >
                      {getLanguage('modal.hardwareAndTokoShop.table.header.select', '')}
                    </Table.HeadColumn>
                  </Table.Row>
                </Table.Section>
                <Table.Section>
                  {
                    dataList.map((e, i) => {
                      if (profileSelect.id === e.id) {
                        e.isSelected = true;
                      }
                      else {
                        e.isSelected = false;
                      }
                      return (
                        <Table.Row
                          pointer
                          key={i}
                          onClick={() => { this.profile.onClickSelect(e); }}
                        >
                          <Table.BodyColumn
                            selected={e.isSelected} // Not use to row because when x scroll active > row bg color have not display
                            info
                            maxWidth={VARIABLES.TABLE.COLUMN_WIDTHS.IDS.INDEX}
                            minWidth={VARIABLES.TABLE.COLUMN_WIDTHS.IDS.INDEX}
                            title={e.idWithPrefix}
                          >
                            {e.idWithPrefix}
                          </Table.BodyColumn>
                          <Table.BodyColumn
                            selected={e.isSelected}
                            info
                            minWidth={VARIABLES.TABLE.COLUMN_WIDTHS.NAMES.FULL}
                            title={e.profileName}
                          >
                            {e.profileName}
                          </Table.BodyColumn>
                          {/* <Table.BodyColumn
                            selected={e.isSelected}
                            phone
                            minWidth={VARIABLES.TABLE.COLUMN_WIDTHS.PHONE}
                            idPhone={e.phoneCountry.value}
                            srcFlag={e.phoneCountry.flag}
                          >
                            {e.phoneNo}
                          </Table.BodyColumn>
                          <Table.BodyColumn
                            selected={e.isSelected}
                            link
                            minWidth={VARIABLES.TABLE.COLUMN_WIDTHS.EMAIL}
                            title={e.email}
                          >
                            {e.email}
                          </Table.BodyColumn> */}
                          <Table.BodyColumn
                            selected={e.isSelected}
                            action
                            alignCenter
                            maxWidth={VARIABLES.TABLE.COLUMN_WIDTHS.TRAIN}
                            minWidth={VARIABLES.TABLE.COLUMN_WIDTHS.TRAIN}
                          >
                            <Field
                              checked={e.isSelected}
                              ui='checkboxTickFillBlank'
                            />
                          </Table.BodyColumn>
                        </Table.Row>
                      )
                    })
                  }
                </Table.Section>
              </Table>
            </Section>
            <Section justify='flex-end'>
              <PaginationControl
                activePage={activePage}
                itemsCountPerPage={dataPerPage}
                totalItemsCount={this.profile.getFilterDataList().length}
                pageRangeDisplayed={3}
                onChange={this.pagination.onPaginationChange}
              />
            </Section>
          </Modal.Section>
          <Modal.Section
            paddingVertical='30'
            paddingHorizontal='45'
            borderTopWidth={VARIABLES.MODAL.FOOTER.F_1.BORDER_TOP_WIDTH}
            borderTopStyle={VARIABLES.MODAL.FOOTER.F_1.BORDER_TOP_STYLE}
            borderTopColor={VARIABLES.COLORS.GRAY_8}
            borderBottomRightRadius={VARIABLES.MODAL.FOOTER.F_1.BORDER_BOTTOM_RIGHT_RADIUS}
            borderBottomLeftRadius={VARIABLES.MODAL.FOOTER.F_1.BORDER_BOTTOM_LEFT_RADIUS}
          >
            <Grid
              gutter='15'
              justify='flex-end'
            >
              <Grid.Column>
                <Button
                  gray
                  width={VARIABLES.BUTTON.WIDTHS.W_155}
                  name={getLanguage('field.buttonCancel', '')}
                  onClick={onClickClose}
                />
              </Grid.Column>
              <Grid.Column>
                <Button
                  blue
                  disabled={isDisabledButtonSave}
                  width={VARIABLES.BUTTON.WIDTHS.W_155}
                  name={getLanguage('field.buttonSelect', '')}
                  onClick={() => { this.onClickSave() }}
                />
              </Grid.Column>
            </Grid>
          </Modal.Section>
        </Modal>
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => {
  const dataRedux = state.dataRedux;
  return {
    dataList: dataRedux.profileList,
    profileParams: dataRedux.profileParams
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    dataActions: bindActionCreators(dataCreators, dispatch),
  }
}

export const ModalHardwareAndTokoShopProfileContainerConnected = connect(
  mapStateToProps,
  mapDispatchToProps
)(ModalHardwareAndTokoShopProfileContainer)