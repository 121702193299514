import styled, {
  css
} from 'styled-components'
import {
  default as VARIABLES
} from './../../../themes/styles/bases/variables'
// import {
//   default as TYPOGRAPHYS
// } from './../../../themes/styles/bases/typographys'
// import {
//   default as MIXINS
// } from './../../../themes/styles/helpers/mixins'
// import {
//   // Example using:
//   // background: url(${CONTENTS['image-ProgressBar.svg']});
//   CONTENTS,
//   ICONS,
//   LOGOS,
//   SHAREDS,
//   DOCUMENTS
// } from './../../../themes'

// Wrapper
// ============================================================
export const ProgressBarWrapper = styled.div`
  @keyframes flash {
    0% {
      opacity: 1;
    }

    100% {
      opacity: 0.5;
    }
  }

  /* Parent
  ------------------------------- */
  pointer-events: none;
  transition: ${VARIABLES.TRANSITIONS.DEFAULT};
  opacity: 0;
  position: fixed;
  z-index: ${VARIABLES.Z_INDEXS.LV_6};
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${VARIABLES.COLORS.OVERLAY_BLACK_1};

  /* Childrens
  ------------------------------- */
  .progress-bar-container {
    ${props => {
      const theme = props.theme
      return css`
        ${theme.barWidth && css`
            width: ${theme.barWidth + `px`};
          `
        }
      `
    }}
  }

  .progress-bar-track {
    ${props => {
      const theme = props.theme
      return css`
        ${theme.barRadius && css`
            border-radius: ${theme.barRadius + `px`};
          `
        }
      `
    }}

    width: 100%;
    padding: 1px;
    background-color: ${VARIABLES.COLORS.WHITE};
  }

  .progress-bar-percent {
    ${props => {
      const theme = props.theme
      return css`
        ${theme.barHeight && css`
            height: ${theme.barHeight + `px`};
          `
        }

        ${theme.barRadius && css`
            border-radius: ${theme.barRadius + `px`};
          `
        }

        background-color: ${theme.barColor};
      `
    }}

    transition: ${VARIABLES.TRANSITIONS.DEFAULT};
  }

  .progress-bar-percent-number {
    transform: translateY(-50%);
    position: absolute;
    z-index: ${VARIABLES.Z_INDEXS.LV_1};
    top: 50%;
    left: 100%;
    margin-left: 15px;
  }

  .progress-bar-message {
    animation-name: flash;
    animation-duration: 0.5s;
    animation-iteration-count: infinite;
    animation-direction: alternate;
    animation-timing-function: ease;
  }

  /* Modifiers
  ------------------------------- */
  &.is-active {
    pointer-events: auto;
    opacity: 1;
  }

  /* Media queries
  ------------------------------- */
`
