import styled, {
  // css
} from 'styled-components'
import {
  default as VARIABLES
} from './../../../themes/styles/bases/variables'
// import {
//   default as TYPOGRAPHYS
// } from './../../../themes/styles/bases/typographys'
// import {
//   default as MIXINS
// } from './../../../themes/styles/helpers/mixins'

// Sample using image in style
// url(${require('./../../../themes/images/icons/icon-sample.svg')});

// Wrapper
// ============================================================
export const ApplicationCardWrapper = styled.div`
  /* Parent
  ------------------------------- */
  cursor: pointer;
  transition: ${VARIABLES.TRANSITIONS.DEFAULT};
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 15px;
  background-color: ${VARIABLES.COLORS.PRIMARY_2};
  border-radius: 5px;

  /* Childrens
  ------------------------------- */
  .text-base {
    &.is-segment-name {}
  }

  .shape-container-base {
    &.is-application-content {
      flex: 1;

      .shape-container-content-base {
        height: 100%;
      }
    }
  }

  .section-base {
    &.is-no-application-content {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }

  /* Modifiers
  ------------------------------- */
  &.is-fail {
    background-color: ${VARIABLES.COLORS.RED_8};

    .text-base {
      &.is-segment-name {
        color: ${VARIABLES.COLORS.RED_8};
      }
    }
  }

  &.is-success {
    background-color: ${VARIABLES.COLORS.GREEN_3};

    .text-base {
      &.is-segment-name {
        color: ${VARIABLES.COLORS.GREEN_3};
      }
    }
  }

  &.is-not-selected {
    opacity: 0.33;
  }

  /* Media queries
  ------------------------------- */
`
