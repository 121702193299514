import apiService from '../apiService'
import { BASE_CMS_API, BASE_CMS_PORT_API, BASE_CMS_PATH_API } from '../../config/config'

const apiPath = '/newspromotion'

export const cmsNewsAndPromotionController = (configService = {}) => {
  configService.baseApiPath = BASE_CMS_API + BASE_CMS_PORT_API + BASE_CMS_PATH_API;
  const service = apiService(configService)
  return {
    searchNewsPromotion: (params) => {
      return service.post(`${apiPath}/search`, params)
    },
    getById: (params) => {
      return service.get(`${apiPath}/${params.id}`, params)
    },
  }
}
