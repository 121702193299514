import { storeDispatch, storeGetState } from "../../stores";
import { dataCreators, DATA_REDUX_STATE_KEY } from "../../actions/dataAction";
import { ModelAdRf, ModelDetailAdRf, ModelTransferHistory, ModelLastTransfer, ModelInventory, ModelTransferCheckCoil, ModelCoilApprove } from "../../models/ModelTransfer";
import { ENUM } from "../constants/enum";
import { transferController } from "../../apiService/apiController/transferService";
import { isValidResponse } from "../functions/validation";
import { handleSortParams, handleTrimParams } from "../functions/data";

export const getAdRfManageParams = (data) => {
  const userAuth = storeGetState().mainRedux.userAuth;
  const txtCustomerType = data.customerType === 1 ? 'Authorized Roll former (AD)' : 'Independent Roll former'
  return {
    email: data.email,
    phone_no: data.phoneCountry.value + data.phone,
    company: data.company,
    address: data.address,
    member_type: txtCustomerType,
    country: userAuth.country
  }
}

export const getAdRFList = async (params = {}) => {
  let userAuth = storeGetState().mainRedux.userAuth;
  let currentParams = storeGetState().dataRedux.adRfParams;
  let newParams = handleTrimParams({
    ...currentParams,
    ...params,
    ad_ref_id: userAuth.adRefId,
    country: userAuth.country,
    customer_role_type: userAuth.customerRoleType
  });
  if (!newParams.sort) {
    newParams.sort = 'id asc';
  }
  // else {
  //   newParams = handleSortParams(currentParams, newParams);
  // }

  const res = await transferController().searchAdRf(newParams);
  if (isValidResponse(res)) {
    let dataList = res.data.map(e => new ModelAdRf(e));
    newParams.activePage = newParams.page;
    newParams.totalData = res.total_items;
    // if (dataOptionParams.selectedDataList.length) {
    //   dataList = dataList.map(e => {
    //     dataOptionParams.selectedDataList.forEach(es => {
    //       if (es.id === e.id) {
    //         e.isSelected = es.isSelected;
    //       }
    //     })
    //     return e
    //   })
    // }
    console.log('getAdRFList', res.data, dataList, newParams);
    storeDispatch(dataCreators.setDataRedux(DATA_REDUX_STATE_KEY.AD_RF_LIST, dataList));
    storeDispatch(dataCreators.setDataRedux(DATA_REDUX_STATE_KEY.AD_RF_PARAMS, newParams));
  }
}

export const getAdRFDetail = async (params = {}) => {
  // const dataOptionParams = storeGetState().dataRedux.warrantyOptionParams;
  // let currentParams = storeGetState().dataRedux.adRfParams;
  let newParams = handleTrimParams({
    // ...currentParams,
    ...params,
  });

  const res = await transferController().detailAdRf(newParams);
  if (isValidResponse(res)) {
    let dataList = new ModelAdRf(res.data);
    let dataLastList = res.last_transfer.map(e => new ModelLastTransfer(e));
    let adDetail = {
      detail: dataList,
      lastTransfer: dataLastList
    }
    // newParams.activePage = newParams.page;
    // newParams.totalData = res.total_items;
    // if (dataOptionParams.selectedDataList.length) {
    //   dataList = dataList.map(e => {
    //     dataOptionParams.selectedDataList.forEach(es => {
    //       if (es.id === e.id) {
    //         e.isSelected = es.isSelected;
    //       }
    //     })
    //     return e
    //   })
    // }
    // console.log('getWarrantyList', res.data, dataList, newParams, dataOptionParams);

    console.log('getAdRFDetail', res.data, adDetail, newParams);
    storeDispatch(dataCreators.setDataRedux(DATA_REDUX_STATE_KEY.AD_DETAIL, adDetail));
  }
}

export const getTransferHistoryList = async (params = {}) => {
  let userAuth = storeGetState().mainRedux.userAuth;
  let currentParams = storeGetState().dataRedux.transferHisParams;
  let newParams = handleTrimParams({
    ...currentParams,
    ...params,
    ad_ref_id: userAuth.adRefId,
    email: userAuth.email,
    customer_role_type: userAuth.customerRoleType,
  });
  if (!newParams.sort) {
    newParams.sort = 'rsst.createddate desc';
  }
  else {
    newParams = handleSortParams(currentParams, newParams);
  }
  // const saleTransService = saleTransController();
  // const res = await saleTransService.searchSalesTransaction(newParams);
  const res = await transferController().searchTransferHis(newParams);
  if (isValidResponse(res)) {
    const dataList = res.data.map(e => new ModelTransferHistory(e));
    newParams.activePage = newParams.page;
    newParams.totalData = res.total_items;
    console.log('res.data =', res.data);
    console.log('dataList =', dataList);
    console.log('newParams =', newParams);
    storeDispatch(dataCreators.setDataRedux(DATA_REDUX_STATE_KEY.TRNSFER_HISTORY_LIST, dataList));
    storeDispatch(dataCreators.setDataRedux(DATA_REDUX_STATE_KEY.TRNSFER_HISTORY_PARAMS, newParams));
  }
}

export const getClearTransferHistoryParams = () => {
  return {
    order_no: '',
    coil_name: '',
    coil_id: '',
    created_date: '',
    company_name: '',
    trans_amount: '',
    phone_no: '',
    start_date: '',
    end_date: '',
    customer_id: '',
    customer_name: '',
    ad_ref_id: '',
    all_data: '',
    sort: '',
  }
}

export const getTransferInvenrotyList = async (params = {}) => {
  let userAuth = storeGetState().mainRedux.userAuth;
  let currentParams = storeGetState().dataRedux.transferInvParams;
  let newParams = handleTrimParams({
    ...currentParams,
    ...params,
    ad_id: userAuth.adRefId,
  });
  if (!newParams.sort) {
    newParams.sort = 'coil_id desc';
  }
  else {
    newParams = handleSortParams(currentParams, newParams);
  }

  const res = await transferController().searchTransferInv(newParams);
  if (isValidResponse(res)) {
    const dataList = res.data.map(e => new ModelInventory(e));
    newParams.activePage = newParams.page;
    newParams.totalData = res.total_items;
    console.log('dataList', res.data, dataList, newParams);
    storeDispatch(dataCreators.setDataRedux(DATA_REDUX_STATE_KEY.TRNSFER_INVENROTY, dataList));
    storeDispatch(dataCreators.setDataRedux(DATA_REDUX_STATE_KEY.TRNSFER_INVENROTY_PARAMS, newParams));
  }
}

export const getTransferInvenrotyParams = () => {
  return {
    order_no: '',
    coil_id: '',
    thickness: '',
    color: '',
    prd_group: '',
    created_date: '',
    trans_amount: '',
    customer_id: '',
    ad_ref_id: '',
    all_field: '',
    sort: '',
  }
}

export const insTransfer = async (params = {}) => {
  let userAuth = storeGetState().mainRedux.userAuth;
  let res;
  try {
    let newParams = handleTrimParams({
      ...params,
      from_dealer: userAuth.adRefId,
    });
    res = await transferController().createTransfer(newParams);
  } catch (error) {
    console.log(error);
  }
  return res;
}

export const transferCheckCoil = async (configService = {}) => {
  const transferService = transferController(configService);
  const res = await transferService.transferCheckCoil();
  if (isValidResponse(res)) {
    let data;
    if (Array.isArray(res.data)) {
      data = res.data.map(e => new ModelTransferCheckCoil(e));
    }
    else {
      data = new ModelTransferCheckCoil(res.data);
    }
    console.log('transferCheckCoil', res.data, data);
    return data
  }
}


export const getTransferSearchHistoryList = async (params = {}) => {
  let userAuth = storeGetState().mainRedux.userAuth;
  let currentParams = storeGetState().dataRedux.transferHisParams;
  let newParams = handleTrimParams({
    ...currentParams,
    ...params,
  });
  if (!newParams.isNoSort) {
    if (!newParams.sort) {
      newParams.sort = 'date desc';
    }
    else {
      newParams = handleSortParams(currentParams, newParams);
    }
  }
  if (!newParams.dealer_code) {
    newParams.dealer_code = userAuth.dealerAccount
  }
  const transferService = transferController();
  const res = await transferService.transferSearchHistory(newParams);
  if (isValidResponse(res)) {
    const dataList = res.data.map(e => new ModelTransferHistory(e));
    newParams.activePage = newParams.page;
    newParams.totalData = res.total_items;
    console.log('getTransferSearchHistoryList', res.data, dataList, newParams);
    storeDispatch(dataCreators.setDataRedux(DATA_REDUX_STATE_KEY.TRNSFER_HISTORY_LIST, dataList));
    storeDispatch(dataCreators.setDataRedux(DATA_REDUX_STATE_KEY.TRNSFER_HISTORY_PARAMS, newParams));
  }
}

export const getClearTransferHistorySearchParams = () => {
  return {
    start_date: '',
    end_date: '',
    text_search: '',
    category: '',
    status: 'all',
    sort: '',
    dealer_code: '',
    isNoSort: false,
  }
}

export const getTransferSearchApproveList = async (params = {}) => {
  let userAuth = storeGetState().mainRedux.userAuth;
  const dataOptionParams = storeGetState().dataRedux.coilApproveOptionParams;
  let currentParams = storeGetState().dataRedux.coilApproveParams;
  let newParams = handleTrimParams({
    ...currentParams,
    ...params,
    dealer_code: userAuth.dealerAccount
  });
  if (!newParams.isNoSort) {
    if (!newParams.sort) {
      newParams.sort = 'date desc';
    }
    else {
      newParams = handleSortParams(currentParams, newParams);
    }
  }
  const transferService = transferController();
  const res = await transferService.transferSearchApprove(newParams);
  if (isValidResponse(res)) {
    let dataList = res.data.map(e => new ModelCoilApprove(e));
    newParams.activePage = newParams.page;
    newParams.totalData = res.total_items;
    if (dataOptionParams.selectedDataList.length) {
      dataList = dataList.map(e => {
        dataOptionParams.selectedDataList.forEach(es => {
          if (es.id === e.id) {
            e.isSelected = es.isSelected;
          }
        })
        return e
      })
    }
    console.log('getTransferSearchApproveList', res.data, dataList, newParams);
    storeDispatch(dataCreators.setDataRedux(DATA_REDUX_STATE_KEY.COIL_APPROVE_LIST, dataList));
    storeDispatch(dataCreators.setDataRedux(DATA_REDUX_STATE_KEY.COIL_APPROVE_PARAMS, newParams));
  }
}

export const getClearTransferSearchApproveParams = () => {
  return {
    start_date: '',
    end_date: '',
    text_search: '',
    category: '',
    status: 'all',
    sort: '',
    dealer_code: '',
    isNoSort: false,
  }
}