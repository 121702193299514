import { dataCreators, DATA_REDUX_STATE_KEY } from "../../actions/dataAction";
import { warrantyTemplateController } from "../../apiService/apiController/warrantyTemplateService";
import { ModelWarrantyTemplate, ModelWarrantyTemplateDetail, ModelWarrantyTemplateM3ProductGroup, ModelWarrantyTemplateMappingColor } from "../../models/ModelWarrantyTemplate";
import { storeDispatch, storeGetState } from "../../stores";
import { handleSortParams, handleTrimParams } from "../functions/data";
import { isValidResponse } from "../functions/validation";

export const getWarrantyTemplateList = async (params = {}) => {
  let currentParams = storeGetState().dataRedux.warrantyTemplateParams;
  let newParams = handleTrimParams({
    ...currentParams,
    ...params,
    is_mapping: true,
  });
  if (!newParams.isNoSort) {
    if (!newParams.sort) {
      newParams.sort = 'wrt_temp_name';
    }
    else {
      newParams = handleSortParams(currentParams, newParams);
    }
  }
  const warrantyTemplateService = warrantyTemplateController();
  const res = await warrantyTemplateService.warrantyTemplateSearch(newParams);
  if (isValidResponse(res)) {
    const dataList = res.data.map(e => new ModelWarrantyTemplate(e));
    newParams.activePage = newParams.page;
    newParams.totalData = res.total_items;
    console.log('dataList', res.data, dataList, newParams);
    storeDispatch(dataCreators.setDataRedux(DATA_REDUX_STATE_KEY.WARRANTY_TEMPLATE_LIST, dataList));
    storeDispatch(dataCreators.setDataRedux(DATA_REDUX_STATE_KEY.WARRANTY_TEMPLATE_PARAMS, newParams));
    return dataList;
  }
}

export const getWarrantyTemplateDetail = async (params = {}, configService = {}) => {
  const warrantyTemplateService = warrantyTemplateController(configService);
  const res = await warrantyTemplateService.warrantyTemplateDetail(params);
  if (isValidResponse(res)) {
    const data = new ModelWarrantyTemplateDetail(res.data);
    console.log('getWarrantyTemplateDetail', res.data, data)
    return data;
  }
}

// export const getWarrantyTemplateM3ProductGroupList = async (params = {}) => {
//   const configService = {
//     isShowLoading: false
//   }
//   const warrantyTemplateService = warrantyTemplateController(configService);
//   const res = await warrantyTemplateService.warrantyTemplateM3Product();
//   if (isValidResponse(res)) {
//     const dataList = res.data.map(e => new ModelWarrantyTemplateM3ProductGroup(e));
//     console.log('getWarrantyTemplateM3ProductGroupList', res.data, dataList);
//     return dataList;
//   }
// }

// export const getWarrantyTemplateMappingColor = async (params = {}) => {
//   const configService = {
//     isShowLoading: false
//   }
//   const warrantyTemplateService = warrantyTemplateController(configService);
//   const res = await warrantyTemplateService.warrantyTemplateMappingColor();
//   if (isValidResponse(res)) {
//     const dataList = res.data.map(e => new ModelWarrantyTemplateMappingColor(e));
//     return dataList;
//   }
// }

export const getClearWarrantyTemplateParams = () => {
  return {
    sort: '',
    m3_product_group_code: '',
    m3_prod_grp_name: '',
    product_group_name: '',
    template_name: '',
    color: '',
    isNoSort: false,
  }
}