import React from 'react'
import {
  BrowserRouter as Router,
  Route,
} from 'react-router-dom'
import {
  Provider,
} from 'react-redux'
import {
  getCreateStore,
} from './stores'
import {
  DeviceDetect
} from './components/core'
import { RouteLayout } from './layouts/RouteLayout/RouteLayout'

export default class App extends React.Component {
  render() {
    return (
      <Provider
        store={getCreateStore()}
      >
        <DeviceDetect>
          <Router>
            <Route path='/' component={RouteLayout} />
          </Router>
        </DeviceDetect>
      </Provider>
    )
  }
}
