import apiService from '../apiService'

const apiPath = '/orderinq'

export const orderInquiryController = (configService = {}) => {
  const service = apiService(configService)
  return {
    searchOrderInquiry: (params) => {
      return service.post(`${apiPath}/search`, params)
    },
    updateOrderInquiryById: (params) => {
      return service.put(`${apiPath}/${params.id}`, params)
    },
  }
}
