import { displayDateTime, displayWithPrefix } from "../helpers/functions/display";

export class ModelUploadTransactionHistory {
  constructor(props = {}) {
    this.id = props.id;
    this.idWithPrefix = displayWithPrefix(props.id, 5);
    this.fileName = props.file_name;
    this.createdAt = props.created_at;
    this.displayCreatedAt = displayDateTime(this.createdAt, '/');
    this.action = props.action;
    this.adRefId = props.ad_ref_id;
  }
}