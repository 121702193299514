import React from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import {
  connect,
} from 'react-redux';
import {
  ShapeContainer,
  Section,
  Grid,
  Button,
  Table,
  TableAction,
  Image,
  Field,
  TitlePage,
  SelectControl,
  PaginationControl,
  Text
} from './../../components'
import { VARIABLES, ICONS } from '../../themes';
import { getProductList } from '../../helpers/services/product';
import { MAIN_REDUX_STATE_KEY } from '../../actions/mainAction';
import { bindActionCreators } from 'redux';
import { dataCreators, DATA_REDUX_STATE_KEY } from '../../actions/dataAction';
import { handleSortData } from '../../helpers/functions/data';
import { getLanguage } from '../../helpers/functions/language';
import _ from 'lodash';
import { toast } from '../../helpers/functions/main';
import { getUserAuthRedux } from '../../helpers/functions/auth';
import { ENUM } from '../../helpers/constants/enum';

class OrderProductIndoListContainer extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
    }
  }

  componentDidMount() {
  }

  productList = this.props.productList;
  input = this.props.input;

  render() {
    const {
      sortIcon,
      dataList
    } = this.props;
    return (
      <Table
        striped
        fieldText
      >
        <Table.Section>
          <Table.Row>
            <Table.HeadColumn
              noSort
              maxWidth={VARIABLES.TABLE.INFO_WIDTHS.CHECKBOX_CIRCLE}
              minWidth={VARIABLES.TABLE.INFO_WIDTHS.CHECKBOX_CIRCLE}
            >
              &nbsp;
            </Table.HeadColumn>
            <Table.HeadColumn
              minWidth={VARIABLES.TABLE.COLUMN_WIDTHS.NAMES.PROFILE_TYPE}
              sortAscending={sortIcon.profileType}
              onClickSort={() => {
                this.productList.onClickSort('profileType');
              }}
            >
              {getLanguage('container.orderProduct.table.header.profileType', '')}
            </Table.HeadColumn>
            <Table.HeadColumn
              minWidth={VARIABLES.TABLE.COLUMN_WIDTHS.NAMES.PRODUCT_GROUP}
              sortAscending={sortIcon.fBsproductgroupname}
              onClickSort={() => {
                this.productList.onClickSort('fBsproductgroupname');
              }}
            >
              {getLanguage('container.orderProduct.table.header.productGroup', '')}
            </Table.HeadColumn>
            <Table.HeadColumn
              alignRight
              maxWidth={VARIABLES.TABLE.COLUMN_WIDTHS.MEASUREMENTS.QUANTITY_UNIT}
              minWidth={VARIABLES.TABLE.COLUMN_WIDTHS.MEASUREMENTS.QUANTITY_UNIT}
              sortAscending={sortIcon.fTotalRemainingprofile}
              onClickSort={() => {
                this.productList.onClickSort('fTotalRemainingprofile');
              }}
            >
              {getLanguage('container.orderProduct.table.header.quantity', '')}
            </Table.HeadColumn>
            <Table.HeadColumn
              alignRight
              maxWidth={VARIABLES.TABLE.INFO_WIDTHS.LENGTH_M}
              minWidth={VARIABLES.TABLE.INFO_WIDTHS.LENGTH_M}
              sortAscending={sortIcon.profileLength}
              onClickSort={() => {
                this.productList.onClickSort('profileLength');
              }}
            >
              {getLanguage('container.orderProduct.table.header.length', '')}
            </Table.HeadColumn>
            <Table.HeadColumn
              alignRight
              maxWidth={VARIABLES.TABLE.INFO_WIDTHS.WEIGHT_KG}
              minWidth={VARIABLES.TABLE.INFO_WIDTHS.WEIGHT_KG}
              sortAscending={sortIcon.fRemainingweight}
              onClickSort={() => {
                this.productList.onClickSort('fRemainingweight');
              }}
            >
              {getLanguage('container.orderProduct.table.header.weight', '')}
            </Table.HeadColumn>
            <Table.HeadColumn
              alignRight
              maxWidth={VARIABLES.TABLE.COLUMN_WIDTHS.FIELDS.ORDER_QUALITY}
              minWidth={VARIABLES.TABLE.COLUMN_WIDTHS.FIELDS.ORDER_QUALITY}
              noSort
            >
              {getLanguage('container.orderProduct.table.header.orderQuantity', '')}
            </Table.HeadColumn>
          </Table.Row>
        </Table.Section>
        <Section calcHeight="calc(100vh - 454px)">
          <Table.Section>
            {
              dataList.map((e, i) => {
                return (
                  <Table.Row
                    key={i}
                  >
                    <Table.BodyColumn
                      info
                      maxWidth={VARIABLES.TABLE.INFO_WIDTHS.CHECKBOX_CIRCLE}
                      minWidth={VARIABLES.TABLE.INFO_WIDTHS.CHECKBOX_CIRCLE}
                    >
                      <Image ui={e.orderValue > 0 ? 'iconSelectedActive' : 'iconSelectedInactive'} />
                    </Table.BodyColumn>
                    <Table.BodyColumn
                      info
                      minWidth={VARIABLES.TABLE.COLUMN_WIDTHS.NAMES.PROFILE_TYPE}
                      title={e.profileType}
                    >
                      {e.profileType}
                    </Table.BodyColumn>
                    <Table.BodyColumn
                      info
                      minWidth={VARIABLES.TABLE.COLUMN_WIDTHS.NAMES.PRODUCT_GROUP}
                      title={e.fBsproductgroupname}
                    >
                      {e.fBsproductgroupname}
                    </Table.BodyColumn>
                    <Table.BodyColumn
                      info
                      alignRight
                      maxWidth={VARIABLES.TABLE.COLUMN_WIDTHS.MEASUREMENTS.QUANTITY_UNIT}
                      minWidth={VARIABLES.TABLE.COLUMN_WIDTHS.MEASUREMENTS.QUANTITY_UNIT}
                    >
                      {`${e.fTotalRemainingprofileWithComma} ${e.profileTypeUnit}`}
                    </Table.BodyColumn>
                    <Table.BodyColumn
                      info
                      alignRight
                      maxWidth={VARIABLES.TABLE.INFO_WIDTHS.LENGTH_M}
                      minWidth={VARIABLES.TABLE.INFO_WIDTHS.LENGTH_M}
                      title={e.profileLengthWithComma}
                    >
                      {e.profileLengthWithComma}
                    </Table.BodyColumn>
                    <Table.BodyColumn
                      info
                      alignRight
                      maxWidth={VARIABLES.TABLE.INFO_WIDTHS.WEIGHT_KG}
                      minWidth={VARIABLES.TABLE.INFO_WIDTHS.WEIGHT_KG}
                      title={e.fRemainingweightWithComma}
                    >
                      {e.fRemainingweightWithComma}
                    </Table.BodyColumn>
                    <Table.BodyColumn
                      action
                      alignRight
                      maxWidth={VARIABLES.TABLE.COLUMN_WIDTHS.FIELDS.ORDER_QUALITY}
                      minWidth={VARIABLES.TABLE.COLUMN_WIDTHS.FIELDS.ORDER_QUALITY}
                    >
                      <Field name='orderValue'
                        ui='order'
                        value={e.orderValue ? e.orderValue : 0}
                        onChange={(ev) => {
                          this.input.onChangeDataInput(ev, e)
                        }}
                      />
                    </Table.BodyColumn>
                  </Table.Row>
                )
              })
            }
          </Table.Section>
        </Section>
      </Table>
    )
  }
}

const mapStateToProps = (state) => {
  const dataRedux = state.dataRedux;
  const mainRedux = state.mainRedux;
  return {
    // dataList: dataRedux.productList,
    // activePage: dataRedux.productParams.activePage,
    // totalData: dataRedux.productParams.totalData,
    // userAuth: mainRedux[MAIN_REDUX_STATE_KEY.USER_AUTH]
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    dataActions: bindActionCreators(dataCreators, dispatch),
  }
}

export const OrderProductIndoListContainerConnected = connect(
  mapStateToProps,
  mapDispatchToProps
)(OrderProductIndoListContainer)