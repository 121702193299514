import _ from 'lodash';
import React from 'react';
import {
  Breadcrumb,
  Button, DatePickerControl, Field,
  Grid, Image, Section, SelectControl, ShapeContainer, TableAction, Text, TitlePage
} from '../../components';
import {
  ModalPreviewPdfContainer
} from '../../containers/ModalContainer';
import { ProjectDocumentCertificateListContainerConnected } from "../../containers/ProjectDocumentCertificateListContainer";
import { ProjectDocumentDocumentaryListContainerConnected } from "../../containers/ProjectDocumentDocumentaryListContainer";
import { ProjectDocumentReferenceListContainerConnected } from "../../containers/ProjectDocumentReferenceListContainer/ProjectDocumentReferenceListContainer";
import { ProjectDocumentWarrantyTemplateListContainerConnected } from "../../containers/ProjectDocumentWarrantyTemplateListContainer";
import { WarrantyListContainerConnected } from '../../containers/WarrantyListContainer';
import { YearWarrantyContainerConnected } from '../../containers/YearWarrantyContainer';
import { DATA, DATE_PICKER_FORMAT } from '../../helpers/constants/data';
import { LOCAL_STORAGE } from '../../helpers/constants/localStorage';
import { ROUTE_PATH } from '../../helpers/constants/route';
import { getDateApiRequet } from '../../helpers/functions/data';
import { getLanguage } from '../../helpers/functions/language';
import { getLocalStorage } from '../../helpers/functions/localStorage';
import { isShowAppLoading } from "../../helpers/functions/main";
import { getRouteData, getRouteParams, redirect, redirectData, setSideMenuActive } from '../../helpers/functions/route';
import { getClearProjectDocumentCertificateSearchParams, getClearProjectDocumentDocumentarySearchParams, getClearProjectDocumentSearchLogParams, getProjectDocumentCertificateSearchList, getProjectDocumentDocumentarySearchList, getProjectDocumentInformationData, getProjectDocumentM3ProductList, getProjectDocumentSearchLogList } from '../../helpers/services/projectDocument';
import { getClearProjectReferenceParams, getProjectReferenceList } from '../../helpers/services/projectReference';
import { getClearWarrantyParams, getWarrantyList, getWarrantyYearDetail, getWarrantyYearList } from '../../helpers/services/warranty';
import { ICONS, VARIABLES } from '../../themes';

export class ProjectDocumentManageScreen extends React.Component {
  constructor(props) {
    super(props);
    setSideMenuActive(props);

    const dataId = parseInt(getRouteParams(props, 'id'));
    let routeData = getRouteData(props, 'data');
    console.log('routeData', routeData);

    const language = getLocalStorage(LOCAL_STORAGE.LANGUAGE_RBPTRUEBLUE);

    let tabList = [
      {
        tabType: DATA.PROJECT_DOCUMENT_TAB_TYPE_LIST[0],
        title: getLanguage('projectDocument.tab.titleWarrantyGuideline', ''),
        description: getLanguage('projectDocument.tab.descriptionWarrantyGuideline', ''),
        icon: 'ic-warranty-guideline',
        iconActive: 'ic-warranty-guideline-white',
        isActive: true,
        isDisabled: false,
        iconDisabled: '',
      },
      {
        tabType: DATA.PROJECT_DOCUMENT_TAB_TYPE_LIST[1],
        title: getLanguage('projectDocument.tab.titleWarrantyTemplate', ''),
        description: getLanguage('projectDocument.tab.descriptionWarrantyTemplate', ''),
        icon: 'ic-warranty-generate',
        iconActive: 'ic-warranty-generate-white',
        isActive: false,
        isDisabled: false,
        iconDisabled: 'ic-warranty-gray',
      },
      {
        tabType: DATA.PROJECT_DOCUMENT_TAB_TYPE_LIST[2],
        title: getLanguage('projectDocument.tab.titleWarranty', ''),
        description: getLanguage('projectDocument.tab.descriptionWarranty', ''),
        icon: 'ic-warranty-template',
        iconActive: 'ic-warranty-template-white',
        isActive: false,
        isDisabled: true,
        iconDisabled: 'ic-warranty-gray',
      },
      {
        tabType: DATA.PROJECT_DOCUMENT_TAB_TYPE_LIST[3],
        title: getLanguage('projectDocument.tab.titleDocumentary', ''),
        description: getLanguage('projectDocument.tab.descriptionDocumentary', ''),
        icon: 'ic-documents-blue',
        iconActive: 'ic-document-template-white',
        isActive: false,
        isDisabled: false,
        iconDisabled: '',
      },
      {
        tabType: DATA.PROJECT_DOCUMENT_TAB_TYPE_LIST[4],
        title: getLanguage('projectDocument.tab.titleCertificateAndLetter', ''),
        description: getLanguage('projectDocument.tab.descriptionCertificateAndLetter', ''),
        icon: 'ic-certificate',
        iconActive: 'ic-certificate-white',
        isActive: false,
        isDisabled: false,
        iconDisabled: '',

      },
      {
        tabType: DATA.PROJECT_DOCUMENT_TAB_TYPE_LIST[5],
        title: getLanguage('projectDocument.tab.titleProjectDatabase', ''),
        description: getLanguage('projectDocument.tab.descriptionProjectDatabase', ''),
        icon: 'ic-reference',
        iconActive: 'ic-reference-white',
        isActive: false,
        isDisabled: false,
        iconDisabled: '',
      },
    ]

    if (routeData) {
      if (routeData.tabType) {
        tabList = tabList.map(e => {
          e.isActive = e.tabType === routeData.tabType
          return e;
        });
      }
    }

    this.state = {
      language,

      dataId,
      tabList,
      customerData: {
        fullname: '',
      },
      data: {
        projectName: '',
      },
      searchValue: '',
      startDate: '',
      endDate: '',
      productGroupSelectOptionList: [],
      productGroupSelected: undefined,

      isShowPDFModal: false,
      dataPDF: undefined,
    };
  }

  componentDidMount() {
    this.projectDocumentManage.getData();
  }

  componentWillUnmount() {
    isShowAppLoading(false);
  }

  projectDocumentManage = {
    onClickTab: (tabType) => {
      const {
        tabList,
        productGroupSelectOptionList,
      } = this.state;
      this.setState({
        tabList: tabList.map(e => {
          e.isActive = e.tabType === tabType;
          return e;
        }),
        productGroupSelected: productGroupSelectOptionList[0],
        searchValue: '',
        startDate: '',
        endDate: '',
      })
    },
    getData: async () => {
      const {
        dataId,
        tabList,
      } = this.state;
      isShowAppLoading(true);
      // const params = {
      //   ...getClearProjectDocumentSearchWarrantyParams(),
      //   page: 1,
      //   limit: 1,
      //   pre_sales_trans_id: dataId,
      // };
      // const configService = {
      //   isShowLoading: false,
      // }
      // const warrantyList = await getProjectDocumentSearchWarrantyList(params, configService);
      // if (warrantyList.length) {
      //   this.setState({
      //     tabList: tabList.map(e => {
      //       if (e.tabType === DATA.PROJECT_DOCUMENT_TAB_TYPE_LIST[2]) {
      //         e.isDisabled = false;
      //       }
      //       return e;
      //     })
      //   })
      // }

      const result = await getProjectDocumentInformationData(dataId);
      if (result) {
        this.setState({
          customerData: result.customerData,
          data: result.data,
          segmentSelected: result.segmentSelected,

          tabList: tabList.map(e => {
            if (e.tabType === DATA.PROJECT_DOCUMENT_TAB_TYPE_LIST[2]) {
              e.isDisabled = result.data.isDisableWarrantyTab;
            }
            return e;
          })
        }, () => {
          isShowAppLoading(false);
        });
      }
      else {
        isShowAppLoading(false);
      }

      getProjectDocumentM3ProductList(undefined, {
        isShowLoading: false,
      }).then(dataList => {
        const productGroupSelected = {
          value: '',
          label: 'All Product Group',
        };
        this.setState({
          productGroupSelected,
          productGroupSelectOptionList: [productGroupSelected, ..._.orderBy(dataList.map((e, i) => {
            return {
              value: e.m3ProductGroupName,
              label: e.m3ProductGroupName,
            };
          }), ['label'], ['asc']),]
        });
      });
    },
    onClickBack: () => {
      redirect(ROUTE_PATH.PROJECT_DOCUMENT.LINK);
    },
    // Project Info
    onClickEditProjectInfo: () => {
      const {
        dataId,
      } = this.state;
      redirect(`${ROUTE_PATH.PROJECT_DOCUMENT_INFORMATION.LINK}/${dataId}`);
    },
    // Sale Order
    onClickSaleOrder: () => {
      const {
        dataId,
      } = this.state;
      redirect(`${ROUTE_PATH.SALES_ORDERS.LINK}?preSalesTransId=${dataId}`)
    },
    // Warranty Template
    onClickViewAllWarrantyTemplate: () => {
      const {
        dataId,
      } = this.state;
      redirect(`${ROUTE_PATH.WARRANTY_TEMPLATE.LINK}?preSalesTransId=${dataId}`)
    },
    // Warranty
    onClickViewWarrantyDetail: (item) => {
      console.log('item', item);
      this.setState({
        isShowPDFModal: true,
        dataPDF: {
          title: getLanguage('projectDocument.modal.previewWarranty.title', '').replace('[warrantyNo]', item.wrtNo).replace('[productGroup]', item.productGroupName),
          fileUrl: item.detailList[0].fileUrl,
        }
      })
    },
    // Documentary
    onClickCreateDocumentary: () => {
      const {
        data,
        segmentSelected,
        customerData,
      } = this.state;
      redirect(ROUTE_PATH.PROJECT_DOCUMENT_DOCUMENTARY_MANAGE_SCREEN.LINK, {
        data: {
          data,
          segmentSelected,
          customerData,
        }
      })
    },
    // Certificate
    onClickCreateCertificate: () => {
      const {
        data,
        segmentSelected,
        customerData,
      } = this.state;
      redirectData(ROUTE_PATH.CERTIFICATE_MANAGE.LINK, {
        data: {
          data,
          segmentSelected,
          customerData,
        }
      });
    },
    // Reference
    onClickCreateReference: () => {
      const {
        customerData,
        segmentSelected,
        data
      } = this.state;
      redirectData(`${ROUTE_PATH.PROJECT_DOCUMENT_REFERENCE_MANAGE.LINK}/0`, {
        data: {
          data,
          segmentSelected,
          customerData,
        }
      })
    },
    onClickSearch: () => {
      const {
        startDate,
        endDate,
        tabList,
        dataId,
        productGroupSelected,
        searchValue,
      } = this.state;
      // Warranty Template
      if (tabList[1].isActive) {
        const params = {
          ...getClearProjectDocumentSearchLogParams(),
          page: 1,
          pre_sales_trans_id: dataId,
        };
        if (startDate && endDate) {
          params.start_date = getDateApiRequet(startDate);
          params.end_date = getDateApiRequet(endDate);
        }
        getProjectDocumentSearchLogList(params);
      }
      // Warranty
      if (tabList[2].isActive) {
        const params = {
          ...getClearWarrantyParams(),
          page: 1,
          order_no: searchValue,
          pre_sales_trans_id: dataId,
          product_group_name: productGroupSelected.value,
        };
        if (startDate && endDate) {
          params.str_date = getDateApiRequet(startDate);
          params.end_date = getDateApiRequet(endDate);
        }
        getWarrantyList(params);
      }
      // Documentary
      if (tabList[3].isActive) {
        const params = {
          ...getClearProjectDocumentDocumentarySearchParams(),
          page: 1,
          pre_sales_trans_id: dataId,
          product_group_name: productGroupSelected.value,
        };
        if (startDate && endDate) {
          params.start_date = getDateApiRequet(startDate);
          params.end_date = getDateApiRequet(endDate);
        }
        getProjectDocumentDocumentarySearchList(params);
      }
      // Certificate
      if (tabList[4].isActive) {
        const params = {
          ...getClearProjectDocumentCertificateSearchParams(),
          page: 1,
          pre_sales_trans_id: dataId,
          product_group_name: productGroupSelected.value,
        };
        if (startDate && endDate) {
          params.start_date = getDateApiRequet(startDate);
          params.end_date = getDateApiRequet(endDate);
        }
        getProjectDocumentCertificateSearchList(params);
      }
      // Project Reference
      if (tabList[5].isActive) {
        const params = {
          ...getClearProjectReferenceParams(),
          page: 1,
          pre_sales_trans_id: dataId,
        };
        if (startDate && endDate) {
          params.start_date = getDateApiRequet(startDate);
          params.end_date = getDateApiRequet(endDate);
        }
        getProjectReferenceList(params);
      }
    },
    rendenSearchContent: () => {
      const {
        startDate,
        endDate,
        tabList,
        productGroupSelectOptionList,
        productGroupSelected,
        searchValue,
      } = this.state;
      return (
        <ShapeContainer
          fluid
          padding='15'
          bgColor={VARIABLES.COLORS.GRAY_1}
          borderRadius='5'
        >
          <Grid gutterVertical="30" gutter="30" justify="flex-end">
            <Grid.Column flex={tabList[5].isActive ? 'none' : '1'} >
              <Section >
                <Grid gutter='20'>
                  <Grid.Column flex='none'>
                    <Section paddingTop='6'>
                      <Text
                        regular12
                        color={VARIABLES.COLORS.BLACK}
                      >
                        {getLanguage('projectDocument.label.date', '')}
                      </Text>
                    </Section>
                  </Grid.Column>
                  <Grid.Column flex='1'>
                    <Grid gutter='10'>
                      <Grid.Column flex='1'>
                        <Grid gutter='15'>
                          <Grid.Column flex='1'>
                            <DatePickerControl
                              filter
                              bottom
                              dateFormat={DATE_PICKER_FORMAT}
                              placeholderText={getLanguage('projectDocument.label.selectStartDate', '')}
                              selected={startDate}
                              onChange={(date) => {
                                this.input.onChangeDate(date, 'startDate');
                              }}
                            />
                          </Grid.Column>
                          <Grid.Column flex='none'>
                            <Section paddingTop='6'>
                              <Text
                                regular12
                                color={VARIABLES.COLORS.GRAY_19}
                              >
                                {getLanguage('projectDocument.label.dateTo', '')}
                              </Text>
                            </Section>
                          </Grid.Column>
                          <Grid.Column flex='1'>
                            <DatePickerControl
                              filter
                              bottom
                              dateFormat={DATE_PICKER_FORMAT}
                              placeholderText={getLanguage('projectDocument.label.selectEndDate', '')}
                              minDate={startDate}
                              selected={endDate}
                              onChange={(date) => {
                                this.input.onChangeDate(date, 'endDate');
                              }}
                            />
                          </Grid.Column>
                        </Grid>
                      </Grid.Column>
                    </Grid>
                  </Grid.Column>
                </Grid>
              </Section>
            </Grid.Column>
            <Grid.Column flex="none">
              <Section >
                <Grid gutter='15'>
                  {
                    // Warranty
                    tabList[2].isActive && (
                      <Grid.Column flex='1'>
                        <Section >
                          <Field name='searchValue'
                            fluid
                            ui='blockLabelVerticalSearch'
                            placeholder={getLanguage('projectDocument.placeholder.searchWarranty', '')}
                            value={searchValue}
                            onChange={this.input.onChangeInput}
                          />
                        </Section>
                      </Grid.Column>
                    )
                  }
                  {
                    (
                      tabList[2].isActive ||
                      tabList[3].isActive ||
                      tabList[4].isActive
                    ) && (
                      <Grid.Column flex='1'>
                        <Section width="220">
                          <SelectControl
                            filterFluid
                            width='220'
                            isSearchable={false}
                            value={productGroupSelected}
                            onChange={this.input.onChangeSearchOption}
                            options={productGroupSelectOptionList}
                          />
                        </Section>
                      </Grid.Column>
                    )
                  }
                  <Grid.Column flex='none'>
                    <Button
                      lightBlue
                      name={getLanguage('field.buttonSearch', '')}
                      width={VARIABLES.BUTTON.WIDTHS.W_105}
                      onClick={this.projectDocumentManage.onClickSearch}
                    />
                  </Grid.Column>
                </Grid>
              </Section>
            </Grid.Column>
            {tabList[2].isActive ?
              null
              :
              <React.Fragment>
                <Grid.Column flex='none'>
                  <ShapeContainer ui='lineVertical' />
                </Grid.Column>
                <Grid.Column flex='none'>
                  {tabList[1].isActive ?
                    <Button
                      outlineBlue
                      ui='frontIcon'
                      name={getLanguage('projectDocument.button.buttonViewAllTemplate', '')}
                      width={VARIABLES.BUTTON.WIDTHS.W_165}
                      iconWidth='14'
                      iconHeight='16'
                      iconSrc={ICONS['ic-warranty-generate.svg']}
                      onClick={this.projectDocumentManage.onClickViewAllWarrantyTemplate}
                    />
                    :
                    tabList[3].isActive ?
                      <Button
                        outlineBlue
                        ui='frontIcon'
                        name={getLanguage('projectDocument.button.buttonCreateDocumentary', '')}
                        width={VARIABLES.BUTTON.WIDTHS.W_183}
                        iconWidth='16'
                        iconHeight='16'
                        iconSrc={ICONS['ic-create-blue.svg']}
                        onClick={this.projectDocumentManage.onClickCreateDocumentary}
                      />
                      :
                      tabList[4].isActive ?
                        <Button
                          outlineBlue
                          ui='frontIcon'
                          name={getLanguage('projectDocument.button.buttonCreateCertificate', '')}
                          width={VARIABLES.BUTTON.WIDTHS.W_183}
                          iconWidth='16'
                          iconHeight='16'
                          iconSrc={ICONS['ic-create-blue.svg']}
                          onClick={this.projectDocumentManage.onClickCreateCertificate}
                        />
                        :
                        <Button
                          outlineBlue
                          ui='frontIcon'
                          name={getLanguage('projectDocument.button.buttonCreateReference', '')}
                          width={VARIABLES.BUTTON.WIDTHS.W_183}
                          iconWidth='16'
                          iconHeight='16'
                          iconSrc={ICONS['ic-create-blue.svg']}
                          onClick={this.projectDocumentManage.onClickCreateReference}
                        />
                  }
                </Grid.Column>
              </React.Fragment>
            }
          </Grid>
        </ShapeContainer>
      )
    },
  }

  input = {
    onChangeSearchOption: (selectedOption) => {
      this.setState({
        productGroupSelected: selectedOption
      })
    },
    onChangeInput: (ev) => {
      const name = ev.target.name;
      const value = ev.target.value;
      this.setState({
        [name]: value,
      });
    },
    onChangeDate: (date, name) => {
      const {
        endDate,
        startDate,
      } = this.state;
      if (name === 'startDate') {
        if (endDate) {
          if (endDate.getTime() < date.getTime()) {
            this.setState({
              endDate: date,
            })
          }
        }
        else {
          this.setState({
            endDate: date,
          })
        }
      }
      if (name === 'endDate' && !startDate) {
        this.setState({
          startDate: date,
        })
      }
      this.setState({
        [name]: date
      })
    }
  }

  modal = {
    onClickClosePreviewPDFModal: () => {
      this.setState({
        isShowPDFModal: false,
      })
    },
    onClickDownload: () => {
      const {
        dataPDF,
      } = this.state;
      const link = document.createElement('a');
      link.download = `${new Date().getTime()}.pdf`;
      link.target = '_blank';
      link.href = dataPDF.fileUrl;
      link.click();
    }
  }

  render() {
    const {
      tabList,
      data,
      customerData,
      dataId,
      segmentSelected,
      isShowPDFModal,
      dataPDF,
    } = this.state;

    return (
      <React.Fragment>
        <Breadcrumb>
          <Breadcrumb.List
            link
            name={getLanguage('projectDocument.breadcrumb1', '')}
            onClick={() => {
              redirect(ROUTE_PATH.HOME.LINK);
            }}
          />
          <Breadcrumb.List name="/" />
          <Breadcrumb.List
            link
            name={getLanguage('projectDocument.breadcrumb2', '')}
            onClick={this.projectDocumentManage.onClickBack}
          />
          <Breadcrumb.List name="/" />
          <Breadcrumb.List
            link
            name={customerData.fullname}
          />
          <Breadcrumb.List name="/" />
          <Breadcrumb.List
            name={data.projectName}
          />
        </Breadcrumb>
        <Section ui="content">
          <Section spacingBottom="35">
            <Grid gutterVertical="15" justify="space-between">
              <Grid.Column flex='1' >
                <TitlePage
                  src={ICONS['ic-pre-transaction.svg']}
                  name={data.projectName}
                />
              </Grid.Column>
              <Grid.Column flex='none'>
                <Grid justify='flex-end' gutter='15'>
                  <Grid.Column>
                    <Button
                      ui='frontIcon'
                      blue
                      onClick={this.projectDocumentManage.onClickEditProjectInfo}
                      name={getLanguage('projectDocument.button.buttonProjectInformation', '')}
                      width={VARIABLES.BUTTON.WIDTHS.W_172}
                      iconWidth='11'
                      iconHeight='14'
                      iconSrc={ICONS['ic-document.svg']}
                    />
                  </Grid.Column>
                  <Grid.Column>
                    <Button
                      lightGreen
                      ui='frontIcon'
                      name={getLanguage('projectDocument.button.buttonSaleOrder', '')}
                      width={VARIABLES.BUTTON.WIDTHS.W_125}
                      iconSrc={ICONS['ic-sales-transaction.svg']}
                      iconWidth='14'
                      iconHeight='14'
                      onClick={this.projectDocumentManage.onClickSaleOrder}
                    />
                  </Grid.Column>
                </Grid>
              </Grid.Column>
            </Grid>
          </Section>
          <React.Fragment>
            <Section spacingBottom="15">
              <Grid gutter="5" gutterVertical="15" >
                {
                  tabList.map((e, i) => {
                    return (
                      <Grid.Column key={i} grid='2' >
                        <ShapeContainer
                          bgColor={e.isActive ? VARIABLES.COLORS.BLUE_22 : VARIABLES.COLORS.WHITE}
                          borderRadius={5}
                          padding={10}
                          borderWidth={1}
                          height={74}
                          width={100}
                          widthUnit='%'
                          borderStyle='solid'
                          borderColor={e.isDisabled ? VARIABLES.COLORS.GRAY_8 : VARIABLES.COLORS.BLUE_16}
                          disabledEventTooltips={e.isDisabled}
                          disabledColor={VARIABLES.COLORS.GRAY_1}
                          onClick={() => {
                            if (!e.isDisabled) {
                              this.projectDocumentManage.onClickTab(e.tabType);
                            }
                          }}
                          tooltips={e.isDisabled}
                        >
                          {/* Tooltip Warranty */}
                          {
                            (e.tabType === DATA.PROJECT_DOCUMENT_TAB_TYPE_LIST[2] && e.isDisabled) && (
                              <div className='tooltips-section'>
                                <span>
                                  <Text
                                    bold14
                                    noWrap
                                    color={VARIABLES.COLORS.GREEN_3}
                                  >
                                    {getLanguage('projectDocument.label.enabled', '')}
                                    <Text
                                      regular14
                                      left='5'
                                      color={VARIABLES.COLORS.BLACK}
                                    >
                                      {getLanguage('projectDocument.label.when', '')}
                                    </Text>
                                  </Text>
                                  <Text
                                    regular14
                                    color={VARIABLES.COLORS.BLACK}
                                  >
                                    {getLanguage('projectDocument.label.aSalesOrderIsPresent', '')}
                                  </Text>
                                </span>
                              </div>
                            )
                          }
                          <Grid gutter="15">
                            <Grid.Column flex="none">
                              <Image
                                src={ICONS[`${e.isDisabled ? e.iconDisabled :
                                  e.isActive ? e.iconActive : e.icon}.svg`]}
                                widthRatio={e.isDisabled ? "16" : "21"}
                                widthRatioUnit="px"
                                heightRatio={e.isDisabled ? "18" : "24"}
                                heightRatioUnit="px"
                              />
                            </Grid.Column>
                            <Grid.Column flex="1">
                              <Section relative height='18' spacingBottom="2" overflowHidden>
                                <Text
                                  className='text-title-tab'
                                  bold14
                                  color={e.isDisabled ? VARIABLES.COLORS.GRAY_12 : e.isActive ? VARIABLES.COLORS.WHITE : VARIABLES.COLORS.BLACK}
                                  ellipsisOneLine
                                  title={e.title}
                                >
                                  {e.title}
                                </Text>
                              </Section>
                              <Section overflowHidden>
                                <Text
                                  regular12
                                  ellipsisTwoLine
                                  color={e.isActive ? VARIABLES.COLORS.WHITE : VARIABLES.COLORS.GRAY_19}
                                  title={e.description}
                                  breakWord
                                >
                                  {e.description}
                                </Text>
                              </Section>
                            </Grid.Column>
                          </Grid>
                        </ShapeContainer>
                      </Grid.Column>
                    )
                  })
                }
              </Grid>
            </Section>
            <ShapeContainer
              // ui="content"
              justify="space-between"
              fluid
              calcMinHeight='100vh - 261px'
              paddingVertical={tabList[0].isActive ? '0' : '40'}
              paddingHorizontal={tabList[0].isActive ? '0' : '40'}
              bgColor={VARIABLES.COLORS.WHITE}
              borderRadius='10'
            >
              {
                tabList[0].isActive ?
                  <React.Fragment>
                    <YearWarrantyContainerConnected
                      isHideTitle
                      getWarrantyYearList={getWarrantyYearList}
                      getWarrantyYearDetail={getWarrantyYearDetail}
                    />
                    <Section spacingBottom='40' spacingLeft='40'>
                      <TableAction>
                        <TableAction.Left>
                          <Button
                            gray
                            ui="back"
                            name={getLanguage('field.buttonBack', '')}
                            onClick={this.projectDocumentManage.onClickBack}
                          />
                        </TableAction.Left>
                        <TableAction.Right />
                      </TableAction>
                    </Section>

                  </React.Fragment>
                  :
                  <Section spacingBottom="40">
                    {this.projectDocumentManage.rendenSearchContent()}
                    <Section>
                      {
                        tabList[1].isActive &&
                        <ProjectDocumentWarrantyTemplateListContainerConnected
                          projectDocumentId={dataId}
                          onClickBack={this.projectDocumentManage.onClickBack}
                        />
                      }
                      {
                        tabList[2].isActive &&
                        <WarrantyListContainerConnected
                          onClickViewWarrantyDetail={this.projectDocumentManage.onClickViewWarrantyDetail}
                          projectDocumentId={dataId}
                          projectDocumentData={{
                            customerData,
                            data,
                            segmentSelected,
                          }}
                          onClickBack={this.projectDocumentManage.onClickBack}
                        />
                      }
                      {
                        tabList[3].isActive &&
                        <ProjectDocumentDocumentaryListContainerConnected
                          projectDocumentId={dataId}
                          onClickBack={this.projectDocumentManage.onClickBack}
                        />
                      }
                      {
                        tabList[4].isActive &&
                        <ProjectDocumentCertificateListContainerConnected
                          projectDocumentId={dataId}
                          projectDocumentData={{
                            customerData,
                            data,
                            segmentSelected,
                          }}
                          onClickBack={this.projectDocumentManage.onClickBack}
                        />
                      }
                      {
                        tabList[5].isActive &&
                        <ProjectDocumentReferenceListContainerConnected
                          projectDocumentId={dataId}
                          onClickBack={this.projectDocumentManage.onClickBack}
                          projectDocumentData={{
                            customerData,
                            data,
                            segmentSelected,
                          }}
                        />
                      }
                    </Section>
                  </Section>
              }
            </ShapeContainer>
          </React.Fragment >
        </Section >
        {isShowPDFModal &&
          <ModalPreviewPdfContainer
            isOpenModal
            ui='previewDownload'
            title={dataPDF?.title}
            pdfSrc={dataPDF?.fileUrl}
            onClickCancel={this.modal.onClickClosePreviewPDFModal}
            onClickDownload={this.modal.onClickDownload}
          />
        }
      </React.Fragment >
    );
  }
}
