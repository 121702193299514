import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { dataCreators } from '../../actions/dataAction';
import { getSortAscending } from '../../helpers/functions/data';
import { getLanguage } from '../../helpers/functions/language';
import {
  getClearNotificationManagementAcceptSearchParams,
  getNotificationManagementAcceptSearchList,
} from '../../helpers/services/notificationManagement';
import { ModalProductUpdateContainer } from '../ModalContainer';
import {
  Grid,
  Image,
  PaginationControl,
  Section,
  ShapeContainer,
  Table,
  TableAction,
  Text,
} from './../../components';
import { ICONS, VARIABLES } from './../../themes';

class NotificationManagementListContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      activePage: 1,
      dataPerPage: 10,

      isOpenProductUpdateModal: false,
      dataModal: undefined,
    };
  }

  componentDidMount() {
    const { activePage, dataPerPage } = this.state;
    const params = {
      ...getClearNotificationManagementAcceptSearchParams(),
      page: activePage,
      limit: dataPerPage,
    };
    getNotificationManagementAcceptSearchList(params);
  }

  notificationManagementList = {
    onClickSort: (target) => {
      getNotificationManagementAcceptSearchList({
        isNoSort: false,
        sort: target,
      });
    },
  };

  modal = {
    onClickView: (item) => {
      this.setState({
        isOpenProductUpdateModal: true,
        dataModal: item,
      });
    },
    onClickCloseModal: () => {
      this.setState({
        isOpenProductUpdateModal: false,
      });
    },
  };

  pagination = {
    onPaginationChange: (pageNumber) => {
      getNotificationManagementAcceptSearchList({
        isNoSort: true,
        page: pageNumber,
      });
    },
  };

  render() {
    const { dataList, dataParams, totalData, activePage } = this.props;
    const { dataPerPage, isOpenProductUpdateModal, dataModal } = this.state;
    return (
      <React.Fragment>
        <ShapeContainer ui='content' justify='space-between'>
          <Section spacingBottom='40'>
            <Table striped action>
              <Table.Section>
                <Table.Row>
                  <Table.HeadColumn
                    minWidth={140}
                    maxWidth={140}
                    sortAscending={getSortAscending(
                      dataParams,
                      'notification_id'
                    )}
                    onClickSort={() => {
                      this.notificationManagementList.onClickSort(
                        'notification_id desc'
                      );
                    }}
                  >
                    {getLanguage('notificationManagement.table.header.id', '')}
                  </Table.HeadColumn>
                  <Table.HeadColumn
                    minWidth={250}
                    sortAscending={getSortAscending(dataParams, 'subject')}
                    onClickSort={() => {
                      this.notificationManagementList.onClickSort(
                        'subject desc'
                      );
                    }}
                  >
                    {getLanguage(
                      'notificationManagement.table.header.subject',
                      ''
                    )}
                  </Table.HeadColumn>
                  <Table.HeadColumn
                    minWidth={150}
                    maxWidth={150}
                    sortAscending={getSortAscending(dataParams, 'public_date')}
                    onClickSort={() => {
                      this.notificationManagementList.onClickSort(
                        'public_date desc'
                      );
                    }}
                  >
                    {getLanguage(
                      'notificationManagement.table.header.publicationDate',
                      ''
                    )}
                  </Table.HeadColumn>
                  <Table.HeadColumn
                    minWidth={150}
                    maxWidth={150}
                    sortAscending={getSortAscending(dataParams, 'created_date')}
                    onClickSort={() => {
                      this.notificationManagementList.onClickSort(
                        'created_date desc'
                      );
                    }}
                  >
                    {getLanguage(
                      'notificationManagement.table.header.acceptedDate',
                      ''
                    )}
                  </Table.HeadColumn>
                  <Table.HeadColumn
                    noSort
                    alignCenter
                    minWidth={VARIABLES.TABLE.INFO_WIDTHS.ACTION_DETAIL}
                    maxWidth={VARIABLES.TABLE.INFO_WIDTHS.ACTION_DETAIL}
                  >
                    {getLanguage(
                      'notificationManagement.table.header.action',
                      ''
                    )}
                  </Table.HeadColumn>
                </Table.Row>
              </Table.Section>
              {dataList.length > 0 ? (
                <Table.Section>
                  {dataList.map((e, i) => {
                    return (
                      <Table.Row key={i}>
                        <Table.BodyColumn info minWidth={140} maxWidth={140}>
                          {e.idWithPrefix}
                        </Table.BodyColumn>
                        <Table.BodyColumn info minWidth={250}>
                          {e.subject}
                        </Table.BodyColumn>
                        <Table.BodyColumn info minWidth={150} maxWidth={150}>
                          {e.displayPublicDate}
                        </Table.BodyColumn>
                        <Table.BodyColumn info minWidth={150} maxWidth={150}>
                          {e.displayAcceptedDate}
                        </Table.BodyColumn>
                        <Table.BodyColumn
                          action
                          alignCenter
                          minWidth={VARIABLES.TABLE.INFO_WIDTHS.ACTION_DETAIL}
                          maxWidth={VARIABLES.TABLE.INFO_WIDTHS.ACTION_DETAIL}
                        >
                          <Image
                            src={ICONS['ic-document-blue.svg']}
                            widthRatio='13'
                            widthRatioUnit='px'
                            heightRatio='16'
                            heightRatioUnit='px'
                            onClick={() => {
                              this.modal.onClickView(e);
                            }}
                          />
                        </Table.BodyColumn>
                      </Table.Row>
                    );
                  })}
                </Table.Section>
              ) : (
                <React.Fragment>
                  <Section justify='center' paddingVertical='80'>
                    <Section justify='center' spacingBottom='8'>
                      <Image
                        src={ICONS['ic-lists-gray.svg']}
                        widthRatio='24'
                        widthRatioUnit='px'
                        heightRatio='24'
                        heightRatioUnit='px'
                      />
                    </Section>
                    <Text regular14 color={VARIABLES.COLORS.GRAY_12}>
                      {getLanguage('notificationManagement.label.noData', '')}
                    </Text>
                  </Section>
                </React.Fragment>
              )}
            </Table>
          </Section>
          <Section>
            <TableAction>
              <TableAction.Left />
              <TableAction.Right>
                <Grid gutter='20'>
                  <Grid.Column>
                    <Section paddingTop='6'>
                      <Text regular12 color={VARIABLES.COLORS.GRAY_16}>
                        {getLanguage('pagination.page', '')} {activePage}/
                        {Math.ceil(totalData / dataPerPage)}
                      </Text>
                    </Section>
                  </Grid.Column>
                  <Grid.Column>
                    <PaginationControl
                      activePage={activePage}
                      itemsCountPerPage={dataPerPage}
                      totalItemsCount={totalData}
                      pageRangeDisplayed={3}
                      onChange={this.pagination.onPaginationChange}
                    />
                  </Grid.Column>
                </Grid>
              </TableAction.Right>
            </TableAction>
          </Section>
        </ShapeContainer>

        {isOpenProductUpdateModal && (
          <ModalProductUpdateContainer
            isOpenModal
            isView
            subject={dataModal.subject}
            description={dataModal.content}
            imageSrc={
              dataModal.notiImage.pathRef ? dataModal.notiImage.path : ''
            }
            onClickClose={this.modal.onClickCloseModal}
          />
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  const dataRedux = state.dataRedux;
  return {
    dataList: dataRedux.notificationManagementList,
    dataParams: dataRedux.notificationManagementParams,
    activePage: dataRedux.notificationManagementParams.activePage,
    totalData: dataRedux.notificationManagementParams.totalData || 0,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    dataActions: bindActionCreators(dataCreators, dispatch),
  };
};

export const ProjectDocumentDocumentaryListContainerConnected = connect(
  mapStateToProps,
  mapDispatchToProps
)(NotificationManagementListContainer);
