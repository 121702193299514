import React from 'react'
import DropzoneComponent from 'react-dropzone-component'
import 'react-dropzone-component/styles/filepicker.css'
import 'dropzone/dist/min/dropzone.min.css'
import ClassNames from 'classnames'
import PropTypes from 'prop-types'
import {
  UploadFileWrapper
} from './styled'
// import {
// } from './../../../components'
// import {
//   default as VARIABLES
// } from './../../../themes/styles/bases/variables'
// import {
//   default as TYPOGRAPHYS
// } from './../../../themes/styles/bases/typographys'
// import {
//   default as UTILITIES
// } from './../../../themes/styles/helpers/utilities'

/**
 * UploadFile description:
 * - Component wrapper for React-Dropzone-Component
 */

export class UploadFile extends React.PureComponent {
  render () {
    const {
      className,
      document,
    } = this.props

    // props for css classes
    const classes = ClassNames(
      'upload-file',
      {'is-document': document},
      className
    )

    return (
      <UploadFileWrapper
        className={classes}
      >
        <DropzoneComponent
          {...this.props}
        />
      </UploadFileWrapper>
    )
  }

  static defaultProps = {
    // * If you set new default props, you should create propsType for correct styleguide document.
  }

  static propTypes = { // TYPE > node, string, number, bool, array, object, symbol, func
    /**
    * Additional classes
    */
    className: PropTypes.string
  }
}
