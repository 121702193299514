import React from 'react'
import {
  ShapeContainer,
  Section,
  Breadcrumb,
  TitlePage,
  Grid,
  Field,
  Button,
  SelectControl,
  Table,
  PaginationControl,
  TableAction,
  Text
} from './../../components'
import {
  VARIABLES,
  ICONS
} from './../../themes'
import { setSideMenuActive, redirect } from '../../helpers/functions/route';
import { ROUTE_PATH } from '../../helpers/constants/route';
import { getCustomerList, getClearCustomerParams } from '../../helpers/services/customer';
import { CustomerListContainerConnected } from '../../containers/CustomerListContainer/CustomerListContainer';
import _ from 'lodash';
import { getLanguage } from '../../helpers/functions/language';
import { ENUM } from '../../helpers/constants/enum';
import { getUserAuthRedux } from '../../helpers/functions/auth';

export class CustomersScreen extends React.Component {

  constructor(props) {
    super(props);
    setSideMenuActive(props);
    const selectOptionList = [{
      value: 'all',
      label: getLanguage('customers.selectOption.all', ''),
    }, {
      value: 'firstname',
      label: getLanguage('customers.selectOption.name', ''),
    }, {
      value: 'nickname',
      label: getLanguage('customers.selectOption.nickname', '')
    }, {
      value: 'phoneNo',
      label: getLanguage('customers.selectOption.phoneNo', '')
    }];
    const userAuth = getUserAuthRedux();
    this.state = {
      searchValue: '',
      selectOptionList,
      selectedOption: selectOptionList[0],
      isHideCreateButton: false && (userAuth.addressType === ENUM.COUNTRY_TYPE.MALAYSIA &&
        (userAuth.customerRoleType === ENUM.CUSTOMER_ROLE_TYPE.MALAYSIA.AD ||
          userAuth.customerRoleType === ENUM.CUSTOMER_ROLE_TYPE.MALAYSIA.HARDWARE_SHOP)),
      isMalay: userAuth.addressType === ENUM.COUNTRY_TYPE.MALAYSIA,
    }
  }

  input = {
    onChangeInput: (ev) => {
      const name = ev.target.name;
      const value = ev.target.value;
      this.setState({
        [name]: value,
      })
    },
    onClickSearch: () => {
      const {
        searchValue,
        selectedOption,
        selectOptionList
      } = this.state;
      const searchType = selectedOption.value;
      let params = {
        ...getClearCustomerParams(),
        page: 1,
      };
      switch (searchType) {
        case selectOptionList[0].value:
          params.all_field = searchValue;
          break;
        case selectOptionList[1].value:
          params.first_name = searchValue;
          break;
        case selectOptionList[2].value:
          params.nick_name = searchValue;
          break;
        case selectOptionList[3].value:
          params.phone = searchValue;
          break;
        case selectOptionList[4].value:
          params.email = searchValue;
          break;
      }
      getCustomerList(params);
    },
    onChangeSearchOption: (selectedOption) => {
      this.setState({
        selectedOption,
      });
    }
  }

  render() {
    const {
      searchValue,
      selectOptionList,
      selectedOption,
      isHideCreateButton,
      isMalay
    } = this.state;
    return (
      <React.Fragment>
        <Breadcrumb>
          <Breadcrumb.List
            link
            name={getLanguage('customers.breadcrumb1', '')}
            onClick={() => {
              redirect(ROUTE_PATH.HOME.LINK)
            }}
          />
          <Breadcrumb.List name='/' />
          <Breadcrumb.List name={getLanguage('customers.breadcrumb2', '')} />
        </Breadcrumb>
        <Section ui='content'>
          <Section spacingBottom='35'>
            <Grid
              gutterVertical='15'
              justify='space-between'
            >
              <Grid.Column>
                <TitlePage
                  src={ICONS['ic-page-customer.svg']}
                  name={getLanguage('customers.title', '')}
                />
              </Grid.Column>
              <Grid.Column>
                <Grid gutter='20'>
                  <Grid.Column>
                    <Grid gutter='10'>
                      <Grid.Column>
                        <Field name='searchValue'
                          ui='blockLabelVerticalSearch'
                          width='285'
                          placeholder={getLanguage('customers.placeholder', '')}
                          value={searchValue}
                          onChange={this.input.onChangeInput}
                        />
                      </Grid.Column>
                      <Grid.Column>
                        <SelectControl
                          verticalLabel
                          filter
                          isSearchable={false}
                          value={selectedOption}
                          // defaultValue='all'
                          onChange={this.input.onChangeSearchOption}
                          options={selectOptionList}
                        />
                      </Grid.Column>
                      <Grid.Column>
                        <Button
                          lightBlue
                          name={getLanguage('customers.buttonSearch', '')}
                          width={VARIABLES.BUTTON.WIDTHS.W_105}
                          onClick={this.input.onClickSearch}
                        />
                      </Grid.Column>
                    </Grid>
                  </Grid.Column>
                  {
                    !isHideCreateButton &&
                    <Grid.Column>
                      <Button
                        blue
                        ui='frontIcon'
                        name={getLanguage('customers.buttonCreate', '')}
                        iconWidth='14'
                        iconHeight='14'
                        iconSrc={ICONS['ic-create.svg']}
                        onClick={() => {
                          redirect(`${ROUTE_PATH.CUSTOMERS_MANAGE.LINK}/0`);
                        }}
                      />
                    </Grid.Column>
                  }
                </Grid>
              </Grid.Column>
            </Grid>
          </Section>
          <CustomerListContainerConnected
            isMalay={isMalay}
          />
        </Section>
      </React.Fragment>
    )
  }
}
