import React from "react";
// import {
//   connect,
// } from 'react-redux';
import {
  Section,
  Grid,
  Modal,
  Text,
  Button,
  Table,
  SelectControl,
  Field,
} from "../../../components";
import { VARIABLES } from "../../../themes";
import { getLanguage } from "../../../helpers/functions/language";
import _ from "lodash";

export class ModalSegmentSelectionContainer extends React.Component {
  constructor(props) {
    super(props);
    const filterSegmentList = _.orderBy(_.uniqBy(
      props.dataList.map((e) => {
        return {
          label: e.segmentName,
          value: e.segmentName,
        };
      }),
      (e) => e.value
    ), ['label'], ['desc']);
    const filterSubSegmentList = _.orderBy(_.uniqBy(
      props.dataList.map((e) => {
        return {
          label: e.subSegmentName,
          value: e.subSegmentName,
        };
      }),
      (e) => e.value
    ), ['label'], ['asc']);
    this.state = {
      filterSegmentList: [
        { label: "All Segment", value: undefined },
        ...filterSegmentList,
      ],
      filterSubSegmentList: [
        { label: "All Sub Segment", value: undefined },
        ...filterSubSegmentList,
      ],
      filterSegmentSelected: undefined,
      filterSubSegmentSelected: undefined,
      filterValue: '',
      filterData: {
        filterValue: '',
        filterSegmentSelected: undefined,
        filterSubSegmentSelected: undefined
      },
      sortBy: "segmentName",
      sortType: "desc",
      sortIcon: {
        segmentName: true,
        segmentDescription: true,
        subSegmentName: true,
        subSegmentDescription: true,
      },
      segmentSelected: props.segmentSelected,
    };
  }

  modal = {
    getFilterDataList: () => {
      const {
        dataList,
      } = this.props;
      const {
        filterValue,
        sortBy,
        sortType,
        filterSegmentSelected,
        filterSubSegmentSelected
      } = this.state;
      const resultList = _.orderBy(
        dataList.filter((e) => {
          return (
            (e.segmentName.toLowerCase().indexOf(filterValue.toLowerCase()) >= 0 ||
              e.segmentDescription.toLowerCase().indexOf(filterValue.toLowerCase()) >= 0 ||
              e.subSegmentName.toLowerCase().indexOf(filterValue.toLowerCase()) >= 0 ||
              e.subSegmentDescription.toLowerCase().indexOf(filterValue.toLowerCase()) >= 0) &&
            (!filterSegmentSelected || filterSegmentSelected.value === undefined || filterSegmentSelected.value === e.segmentName) &&
            (!filterSubSegmentSelected || filterSubSegmentSelected.value === undefined || filterSubSegmentSelected.value === e.subSegmentName)
          );
        }),
        [sortBy],
        [sortType]
      );
      return resultList;
    },
    onClickSubmit: () => {
      const {
        onClickSubmit,
      } = this.props;
      const {
        segmentSelected,
      } = this.state;
      onClickSubmit(segmentSelected)
    },
    onClickSearch: () => {
      const {
        filterData,
      } = this.state;
      this.setState({
        sortBy: "segmentName",
        sortType: "desc",
        sortIcon: {
          segmentName: true,
          segmentDescription: true,
          subSegmentName: true,
          subSegmentDescription: true,
        },
        filterValue: filterData.filterValue,
        filterSegmentSelected: filterData.filterSegmentSelected,
        filterSubSegmentSelected: filterData.filterSubSegmentSelected,
      })
    },
    onClickSort: (target) => {
      const {
        sortBy,
        sortType,
        sortIcon
      } = this.state;
      let newSortBy = sortBy;
      let newSortType = sortType;
      Object.keys(sortIcon).forEach((key) => {
        sortIcon[key] = true;
      });

      if (target === sortBy) {
        newSortType = newSortType === "desc" ? "asc" : "desc";
        if (newSortType === "asc") {
          sortIcon[target] = false;
        }
      } else {
        newSortBy = target;
        newSortType = "desc";
      }
      this.setState({
        sortBy: newSortBy,
        sortType: newSortType,
        sortIcon,
      });
    },
  };

  input = {
    onChangeFilterInput: (ev) => {
      const {
        filterData,
      } = this.state;
      const name = ev.target.name;
      const value = ev.target.value;
      filterData[name] = value;
      this.setState({
        filterData,
      })
    },
    onChangeFilterSugmentSelected: (selected) => {
      const {
        filterData,
      } = this.state;
      filterData.filterSegmentSelected = selected
      this.setState({
        filterData,
      });
    },
    onChangeFilterSubSugmentSelected: (selected) => {
      const {
        filterData,
      } = this.state;
      filterData.filterSubSegmentSelected = selected
      this.setState({
        filterData,
      });
    },
    onChangeSelectData: (ev, item) => {
      const isChecked = ev.target.checked;
      this.setState({
        segmentSelected: isChecked ? item : undefined
      })
    }
  };

  render() {
    const {
      isOpenModal,
      onClickClose,
      primaryTitle,
    } = this.props;
    const {
      sortIcon,
      filterSegmentList,
      filterSubSegmentList,
      filterData,
      segmentSelected,
    } = this.state;

    return (
      <Modal
        open={isOpenModal}
        scroll
        // enableOverlayClose={false}
        width={VARIABLES.MODAL.WIDTHS.W_1145}
        overlayColor={VARIABLES.COLORS.OVERLAY_BLACK_1}
        spacing={VARIABLES.MODAL.CONTAINER.C_1.SPACING}
        transition={VARIABLES.TRANSITIONS.DEFAULT}
        transformStart={VARIABLES.MODAL.CONTAINER.C_1.TRANSFORM_START}
        transformEnd={VARIABLES.MODAL.CONTAINER.C_1.TRANSFORM_END}
        onClickClose={onClickClose}
      >
        <Modal.Section
          paddingVertical="25"
          paddingHorizontal="35"
          bgColor={VARIABLES.COLORS.WHITE}
          borderBottomWidth="1"
          borderBottomStyle="solid"
          borderBottomColor={VARIABLES.COLORS.GRAY_8}
          borderTopRightRadius={
            VARIABLES.MODAL.HEADER.H_1.BORDER_TOP_RIGHT_RADIUS
          }
          borderTopLeftRadius={
            VARIABLES.MODAL.HEADER.H_1.BORDER_TOP_LEFT_RADIUS
          }
        >
          <Text regular20 singleLine color={VARIABLES.COLORS.BLACK}>
            {primaryTitle}
          </Text>
        </Modal.Section>
        <Modal.Section
          paddingVertical="40"
          paddingHorizontal="35"
          bgColor={VARIABLES.COLORS.WHITE}
        >
          <Section spacingBottom="30">
            <Grid gutter="15" justify="flex-end" align="center">
              <Grid.Column>
                <Section spacingBottom="10">
                  <Text
                    regular14
                    color={VARIABLES.COLORS.GRAY_19}
                  ></Text>
                </Section>
                <Field
                  border
                  name="filterValue"
                  ui="blockLabelVerticalSearch"
                  width="375"
                  placeholder={getLanguage(
                    "preTransaction.field.placeholderSearchSegmentSubSegmentAndDescription",
                    ""
                  )}
                  value={filterData.filterValue}
                  onChange={this.input.onChangeFilterInput}
                />
              </Grid.Column>
              <Grid.Column>
                <Section spacingBottom="10">
                  <Text
                    regular14
                    color={VARIABLES.COLORS.GRAY_19}
                  >
                    {getLanguage(
                      "preTransaction.label.segment",
                      ""
                    )}
                  </Text>
                </Section>
                <SelectControl
                  name=""
                  verticalLabel
                  isSearchable={false}
                  width="200"
                  placeholder={getLanguage(
                    "preTransaction.label.segment",
                    ""
                  )}
                  value={filterData.filterSegmentSelected}
                  options={filterSegmentList}
                  onChange={this.input.onChangeFilterSugmentSelected}
                />
              </Grid.Column>
              <Grid.Column>
                <Section spacingBottom="10">
                  <Text
                    regular14
                    color={VARIABLES.COLORS.GRAY_19}
                  >
                    {getLanguage(
                      "preTransaction.label.subSegment",
                      ""
                    )}
                  </Text>
                </Section>
                <SelectControl
                  name=""
                  isSearchable={false}
                  verticalLabel
                  width="200"
                  placeholder={getLanguage(
                    "preTransaction.label.subSegment",
                    ""
                  )}
                  value={filterData.filterSubSegmentSelected}
                  options={filterSubSegmentList}
                  onChange={this.input.onChangeFilterSubSugmentSelected}
                />
              </Grid.Column>
              <Grid.Column>
                <Section spacingBottom="10">
                  <Text
                    regular14
                    color={VARIABLES.COLORS.GRAY_19}
                  ></Text>
                </Section>
                <Button
                  blue
                  name={getLanguage(
                    "customers.buttonSearch",
                    ""
                  )}
                  width={VARIABLES.BUTTON.WIDTHS.W_105}
                  onClick={this.modal.onClickSearch}
                />
              </Grid.Column>
            </Grid>
          </Section>
          <Table striped>
            <Table.Section>
              <Table.Row>
                <Table.HeadColumn
                  alignCenter
                  maxWidth={
                    VARIABLES.TABLE.INFO_WIDTHS
                      .CHECKBOX_SQUARE
                  }
                  minWidth={
                    VARIABLES.TABLE.INFO_WIDTHS
                      .CHECKBOX_SQUARE
                  }
                  noSort
                >
                  {getLanguage(
                    "container.warrantyList.tableWarranty.header.select",
                    ""
                  )}
                </Table.HeadColumn>
                <Table.HeadColumn
                  maxWidth={
                    VARIABLES.TABLE.SEGMENT_INFORMATION
                      .COLUMN_WIDTHS.SEGMENT_NAME
                  }
                  minWidth={
                    VARIABLES.TABLE.SEGMENT_INFORMATION
                      .COLUMN_WIDTHS.SEGMENT_NAME
                  }
                  sortAscending={sortIcon.segmentName}
                  onClickSort={() => {
                    this.modal.onClickSort("segmentName");
                  }}
                >
                  {getLanguage(
                    "warrantyManage.form.segment.table.header.segment"
                  )}
                </Table.HeadColumn>
                <Table.HeadColumn
                  minWidth={
                    VARIABLES.TABLE.SEGMENT_INFORMATION
                      .COLUMN_WIDTHS.SEGMENT_DESCRIPTION
                  }
                  sortAscending={sortIcon.segmentDescription}
                  onClickSort={() => {
                    this.modal.onClickSort("segmentDescription");
                  }}
                >
                  {getLanguage(
                    "warrantyManage.form.segment.table.header.description"
                  )}
                </Table.HeadColumn>
                <Table.HeadColumn
                  minWidth={
                    VARIABLES.TABLE.SEGMENT_INFORMATION
                      .COLUMN_WIDTHS.SUB_SEGMENT_NAME
                  }
                  sortAscending={sortIcon.subSegmentName}
                  onClickSort={() => {
                    this.modal.onClickSort(
                      "subSegmentName"
                    );
                  }}
                >
                  {getLanguage(
                    "warrantyManage.form.segment.table.header.subSegment"
                  )}
                </Table.HeadColumn>
                <Table.HeadColumn
                  minWidth={
                    VARIABLES.TABLE.SEGMENT_INFORMATION
                      .COLUMN_WIDTHS
                      .SUB_SEGMENT_DESCRIPTION
                  }
                  sortAscending={sortIcon.subSegmentDescription}
                  onClickSort={() => {
                    this.modal.onClickSort(
                      "subSegmentDescription"
                    );
                  }}
                >
                  {getLanguage(
                    "warrantyManage.form.segment.table.header.subSegmentDescription"
                  )}
                </Table.HeadColumn>
              </Table.Row>
            </Table.Section>
          </Table>
          <Section scrollOverlay height="294">
            <Table striped>
              <Table.Section>
                {this.modal
                  .getFilterDataList()
                  .map((e, i) => {
                    return (
                      <Table.Row key={i}>
                        <Table.BodyColumn
                          action
                          alignCenter
                          maxWidth={
                            VARIABLES.TABLE
                              .INFO_WIDTHS
                              .CHECKBOX_SQUARE
                          }
                          minWidth={
                            VARIABLES.TABLE
                              .INFO_WIDTHS
                              .CHECKBOX_SQUARE
                          }
                        >
                          <Section spacingTop="-2">
                            <Field
                              checked={(segmentSelected || {}).id === e.id}
                              ui="checkboxTick"
                              onChange={(ev) => this.input.onChangeSelectData(ev, e)}
                            />
                          </Section>
                        </Table.BodyColumn>
                        <Table.BodyColumn
                          info
                          maxWidth={
                            VARIABLES.TABLE
                              .SEGMENT_INFORMATION
                              .COLUMN_WIDTHS
                              .SEGMENT_NAME
                          }
                          minWidth={
                            VARIABLES.TABLE
                              .SEGMENT_INFORMATION
                              .COLUMN_WIDTHS
                              .SEGMENT_NAME
                          }
                          title={e.segmentName}
                        >
                          {e.segmentName || "-"}
                        </Table.BodyColumn>
                        <Table.BodyColumn
                          info
                          minWidth={
                            VARIABLES.TABLE
                              .SEGMENT_INFORMATION
                              .COLUMN_WIDTHS
                              .SEGMENT_DESCRIPTION
                          }
                          title={e.segmentDescription}
                        >
                          {e.segmentDescription || "-"}
                        </Table.BodyColumn>
                        <Table.BodyColumn
                          info
                          minWidth={
                            VARIABLES.TABLE
                              .SEGMENT_INFORMATION
                              .COLUMN_WIDTHS
                              .SUB_SEGMENT_NAME
                          }
                          title={e.subSegmentName}
                        >
                          {e.subSegmentName || "-"}
                        </Table.BodyColumn>
                        <Table.BodyColumn
                          info
                          minWidth={
                            VARIABLES.TABLE
                              .SEGMENT_INFORMATION
                              .COLUMN_WIDTHS
                              .SUB_SEGMENT_DESCRIPTION
                          }
                          title={e.subSegmentDescription}
                        >
                          {e.subSegmentDescription || "-"}
                        </Table.BodyColumn>
                      </Table.Row>
                    );
                  })}
              </Table.Section>
            </Table>
          </Section>
        </Modal.Section>
        <Modal.Section
          paddingTop="15"
          paddingBottom="20"
          paddingHorizontal="35"
          borderTopWidth={VARIABLES.MODAL.FOOTER.F_1.BORDER_TOP_WIDTH}
          borderTopStyle={VARIABLES.MODAL.FOOTER.F_1.BORDER_TOP_STYLE}
          borderTopColor={VARIABLES.COLORS.GRAY_8}
          borderBottomRightRadius={
            VARIABLES.MODAL.FOOTER.F_1.BORDER_BOTTOM_RIGHT_RADIUS
          }
          borderBottomLeftRadius={
            VARIABLES.MODAL.FOOTER.F_1.BORDER_BOTTOM_LEFT_RADIUS
          }
        >
          <Grid gutter="15" justify="flex-end">
            <Grid.Column>
              <Button
                gray
                width={VARIABLES.BUTTON.WIDTHS.W_105}
                name={getLanguage("field.buttonBack", "")}
                onClick={onClickClose}
              />
            </Grid.Column>
            <Grid.Column>
              <Button
                blue
                disabled={!segmentSelected}
                width={VARIABLES.BUTTON.WIDTHS.W_105}
                name={getLanguage("field.buttonConfirm", "")}
                onClick={this.modal.onClickSubmit}
              />
            </Grid.Column>
          </Grid>
        </Modal.Section>
      </Modal>
    );
  }
}
