import React from 'react';
import {
  connect,
} from 'react-redux';
import {
  ShapeContainer,
  Section,
  Breadcrumb,
  TitlePage,
  TitleSection,
  Grid,
  Button,
  Image,
  Text,
  Table,
} from './../../components'
import { VARIABLES, ICONS, CONTENTS } from '../../themes';
// import { storeDispatch } from '../../stores';
// import { dataCreators, DATA_REDUX_STATE_KEY } from '../../actions/dataAction';
// import { handleSortData } from '../../helpers/functions/data';
// import { getAdRFDetail } from '../../helpers/services/transfer';
import _ from 'lodash';
import { getLanguage } from '../../helpers/functions/language';

export class CoilTransferAdInfoContainer extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    // const { adDetail, } = this.props;

    // if (adDetail) {
    //   this.setState({
    //     adDetail,
    //   });
    // }

    // getAdRFList();
    // const {
    //   dataDetail
    // } = this.props;
    // console.log('store dataDetail = ', dataDetail);
    // if(dataDetail){
    //   const params = {
    //     id: dataDetail.dealerCode
    //   };

    //   getAdRFDetail(params);
    // } else {
    //   // redirect(ROUTE_PATH.COIL_TRANSFER.LINK)
    // }
  }
  // adRfList = {
  // 	onClickSort: (type) => {
  // 		const {
  // 			dataList,
  // 		} = this.props;
  // 		const newSortIcon = this.customerList.handleSortIcon(type);
  // 		const tempDataList = handleSortData(dataList, {
  // 			sortTarget: type,
  // 			orderType: newSortIcon[type] ? 'desc' : 'asc'
  // 		});
  // 		this.customerList.updateData(tempDataList);
  // 	},
  // 	onClickSelect: (data) => {
  // 		const {
  // 			dataList,
  // 			onClickCustomer,
  // 		} = this.props;
  // 		dataList.forEach(e => {
  // 			if (e.id === data.id) {
  // 				onClickCustomer(e);
  // 			}
  // 		})
  // 	}
  // }

  render() {
    const {
      stAdDetail
    } = this.props;
    return (
      <Grid.Column>
        <Grid
          gutter='55'
          gutterVertical='35'
        >
          <Grid.Column>
            <ShapeContainer
              ui='userUpload'
              srcUserUploadImage={false ? CONTENTS['customer-1.jpg'] : ICONS['ic-placeholder-avatar.svg']}
            />
          </Grid.Column>
          <Grid.Column>
            <Section
              spacingTop='5'
              spacingBottom='5'
            >
              <Text
                regular14
                color={VARIABLES.COLORS.GRAY_19}
              >
                {getLanguage('container.coilTransferAdInfo.companyName', '')}
              </Text>
            </Section>
            <Section
              className='is-info-name-long'
              spacingBottom='15'
            >
              <Text
                ellipsis
                regular22
                color={VARIABLES.COLORS.BLACK}
                title={stAdDetail.adRfName}
              >
                {stAdDetail.adRfName}
              </Text>
            </Section>
            <Section spacingBottom='5'>
              <Text
                regular14
                color={VARIABLES.COLORS.GRAY_19}
              >
                {getLanguage('container.coilTransferAdInfo.customerType', '')}
              </Text>
            </Section>
            <Section>
              <Text
                regular14
                color={VARIABLES.COLORS.BLACK}
              >
                {stAdDetail.type}
              </Text>
            </Section>
          </Grid.Column>
          <Grid.Column>
            <Section
              spacingTop='5'
              spacingBottom='5'
            >
              <Text
                regular14
                color={VARIABLES.COLORS.GRAY_19}
              >
                {getLanguage('container.coilTransferAdInfo.phoneNo', '')}
              </Text>
            </Section>
            <Section spacingBottom='15'>
              <Text
                regular22
                color={VARIABLES.COLORS.BLACK}
              >
                <Image
                  ui='flagWithPhoneIdAndNumber'
                  phoneId={stAdDetail.phoneCountry ? stAdDetail.phoneCountry.value : ''}
                  phoneNumber={stAdDetail.phoneNo ? stAdDetail.phoneNo : ''}
                  src={stAdDetail.phoneCountry ? stAdDetail.phoneCountry.flag : ''}
                />
              </Text>
            </Section>
          </Grid.Column>
        </Grid>
      </Grid.Column>
    );
  }
}