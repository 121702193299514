import React from 'react';
import { transferController } from '../../apiService/apiController/transferService';
import {
  Breadcrumb,
  Button,
  Field,
  Grid,
  Image,
  Section,
  TitlePage
} from '../../components';
import {
  ModalBarcodeScannerContainer,
  ModalCoilTransferBarcodeScanInformationContainer,
  ModalCoilTransferDuplicateContainer,
  ModalSelectADContainerConnected
} from '../../containers/ModalContainer';
import { ENUM } from '../../helpers/constants/enum';
import { ROUTE_PATH } from '../../helpers/constants/route';
import { getUserAuthRedux } from '../../helpers/functions/auth';
import { getLanguage } from '../../helpers/functions/language';
import { toast } from '../../helpers/functions/main';
import { addNotiCoilTransferScan } from '../../helpers/functions/notification';
import { redirect, setSideMenuActive } from '../../helpers/functions/route';
import { isValidResponse } from '../../helpers/functions/validation';
import { transferCheckCoil } from '../../helpers/services/transfer';
import {
  ICONS,
  VARIABLES,
} from '../../themes';

export class CoilTransferBarcodeScannerScreen extends React.Component {

  constructor(props) {
    super(props);
    setSideMenuActive(props);
    let isMobileScreenSize = window.matchMedia('(max-width: 1023px)').matches

    this.state = {
      isMobileScreenSize,
      searchValue: '',
      data: undefined,
      isOpenDuplicateModal: false,
      isOpenInformationModal: false,
      isOpenSelectADModal: false,
      selectADList: [],
    }

    this.matchMedia = null
  }

  componentDidMount() {
    this.mobile.handleCheckSizeChange();
  }

  componentWillUnmount() {
    if (this.matchMedia) {
      this.matchMedia.removeEventListener("change", this.handleChange)
    }
  }

  handleChange = (ev) => {
    this.setState({
      isMobileScreenSize: ev.matches,
    });
  };


  mobile = {
    handleCheckSizeChange: () => {
      this.matchMedia = window.matchMedia('(max-width: 1023px)')
      this.matchMedia.addEventListener('change', this.handleChange);
    }
  }

  coilTransferBarcodeScanner = {
    onClickSearch: async () => {
      const {
        searchValue,
      } = this.state;
      const userAuth = getUserAuthRedux();
      const configService = {
        urlParams: {
          coil_id: searchValue,
          to_dealer: userAuth.dealerAccount
        }
      }
      const data = await transferCheckCoil(configService);
      if (data) {
        if (data.messageError) {
          this.setState({
            data,
            isOpenDuplicateModal: true,
          })
        }
        else {
          const dataList = data.filter(e => e.fromDealer !== userAuth.dealerAccount);
          if (dataList.length > 1) {
            this.setState({
              selectADList: dataList,
              isOpenSelectADModal: true,
            })
          }
          else if (dataList.length === 0) {
            this.setState({
              data: {
                coilId: searchValue
              },
              isOpenDuplicateModal: true,
            })
          }
          else {
            this.setState({
              isOpenInformationModal: true,
              data: {
                ...dataList[0],
                length: 0,
              }
            });
          }
        }
      }
    },
    onClickSubmitCoilTransfer: async (data) => {
      const {
        isMobileScreenSize,
        searchValue,
      } = this.state;
      const userAuth = getUserAuthRedux();
      const params = {
        length: data.length,
        sales_tran_id: data.salesTranId,
        to_dealer: userAuth.dealerAccount,
        from_dealer: data.fromDealer,
        flows: ENUM.COIL_TRANSFER_CONFIRM_TYPE.APPROVE
      }
      const transferService = transferController();
      const res = await transferService.transferConfirm(params);
      if (isValidResponse(res)) {
        addNotiCoilTransferScan({
          coilId: searchValue,
          length: data.length,
          salesTranId: data.salesTranId,
          fromDealer: data.fromDealer,
          toDealer: userAuth.dealerAccount,
        });
        if (isMobileScreenSize) {
          redirect(ROUTE_PATH.HOME.LINK);
        }
        else {
          this.setState({
            searchValue: '',
            isOpenInformationModal: false,
          })
        }
        toast.success(getLanguage('message.coilTransactionSuccessfully', ''));
      }
    }
  }

  input = {
    onChangeInput: (ev) => {
      const name = ev.target.name;
      const value = ev.target.value;
      this.setState({
        [name]: value
      })
    },
  }


  modal = {
    handleUpdateScan: (value) => {
      console.log('handleUpdateScan', value)
      this.setState({
        searchValue: value,
        isOpenBarcodeScanner: false,
      }, () => {
        this.coilTransferBarcodeScanner.onClickSearch();
      })
    },
    onClickOpenBarcodeScannerModal: () => {
      const {
        isMobileScreenSize,
      } = this.state;
      if (isMobileScreenSize) {
        this.setState({
          searchValue: '',
          isOpenDuplicateModal: false,
          isOpenBarcodeScanner: true,
        });
      }
      else {
        toast.error(getLanguage('message.pleaseLoginWithSmartPhone'))
      }
    },
    onCloseBarcodeScannerModal: () => {
      this.setState({
        isOpenBarcodeScanner: false,
      })
    },
    onCloseInformationModal: () => {
      this.setState({
        isOpenInformationModal: false,
      })
    },
    onCloseDuplicateModal: () => {
      this.setState({
        searchValue: '',
        isOpenDuplicateModal: false,
      })
    },
    onCloseSelectADModal: () => {
      this.setState({
        isOpenSelectADModal: false,
      })
    },
    onClickSubmitSelectADModal: (item) => {
      this.setState({
        isOpenInformationModal: true,
        isOpenSelectADModal: false,
        data: {
          ...item,
          length: 0,
        }
      });
    }
  }

  render() {
    const {
      isOpenDuplicateModal,
      isOpenInformationModal,
      isOpenBarcodeScanner,
      data,
      searchValue,
      isOpenSelectADModal,
      selectADList,
    } = this.state
    return (
      <React.Fragment>
        <Breadcrumb>
          <Breadcrumb.List
            link
            name={getLanguage('barcode.breadcrumb1', '')}
            onClick={() => {
              redirect(ROUTE_PATH.HOME.LINK)
            }}
          />
          <Breadcrumb.List name='/' />
          <Breadcrumb.List name={getLanguage('barcode.breadcrumb2', '')} />
        </Breadcrumb>
        <Section ui='content' >
          <Section spacingBottom='35'>
            <TitlePage
              src={ICONS['ic-barcode-reader-circle.svg']}
              name={getLanguage('barcode.title')}
            />
          </Section>
          <Section
            spacingBottom='26'
            justify='center'
            align='center'
          >
            <Grid gutter='8' gutterVertical='8' justify='center'>
              <Grid.Column >
                <Section >
                  <Field
                    border
                    name="searchValue"
                    ui="blockLabelVertical"
                    label={getLanguage('barcode.label.coilId', '')}
                    width='286'
                    placeholder={getLanguage('barcode.field.placeholderInsertCoilId', '')}
                    value={searchValue}
                    onChange={this.input.onChangeInput}
                  />
                </Section>
              </Grid.Column>
              <Grid.Column >
                <Section
                  relative
                  top='20'
                  align='center'
                  spacingBottom='26'
                >
                  <Image
                    src={ICONS['ic-barcode-reader-blue.svg']}
                    widthRatio='36'
                    widthRatioUnit='px'
                    heightRatio='36'
                    heightRatioUnit='px'
                    onClick={this.modal.onClickOpenBarcodeScannerModal}
                  />
                </Section>
              </Grid.Column>
            </Grid>
          </Section>
          <Section
            justify='center'
            align='center'
            textAlign='center'
          >
            <Button
              blue
              name={getLanguage('field.buttonSearch', '')}
              width={VARIABLES.BUTTON.WIDTHS.W_120}
              onClick={this.coilTransferBarcodeScanner.onClickSearch}
            />
          </Section>
        </Section >
        {
          isOpenDuplicateModal && (
            <ModalCoilTransferDuplicateContainer
              isOpenModal
              title={getLanguage('barcode.modal.modalBarcodeDuplicate.title', '')}
              message={getLanguage('barcode.modal.modalBarcodeDuplicate.message', '').replace('{coilName}', data.coilId)}
              onClickClose={this.modal.onCloseDuplicateModal}
              onClickConfirm={this.modal.onClickOpenBarcodeScannerModal}
            />
          )
        }
        {
          isOpenInformationModal && (
            <ModalCoilTransferBarcodeScanInformationContainer
              isOpenModal
              title={getLanguage('barcode.modal.modalCoilTranferInformation.title', '')}
              data={data}
              onClickClose={this.modal.onCloseInformationModal}
              onClickConfirm={this.coilTransferBarcodeScanner.onClickSubmitCoilTransfer}
            />
          )
        }
        {
          isOpenBarcodeScanner && (
            <ModalBarcodeScannerContainer
              isOpenModal
              onUpdate={this.modal.handleUpdateScan}
              onClickClose={this.modal.onCloseBarcodeScannerModal}
            />
          )
        }
        {isOpenSelectADModal &&
          <ModalSelectADContainerConnected
            dataList={selectADList}
            onClickClose={this.modal.onCloseSelectADModal}
            onClickSubmit={this.modal.onClickSubmitSelectADModal}
          />
        }
      </React.Fragment >
    )
  }
}
