import styled from 'styled-components'
import {
  default as VARIABLES
} from './../../../themes/styles/bases/variables'
import {
  default as TYPOGRAPHYS
} from './../../../themes/styles/bases/typographys'
// import {
//   default as MIXINS
// } from './../../../themes/styles/helpers/mixins'

// Sample using image in style
// url(${require('./../../../themes/images/icons/icon-sample.svg')});

// Wrapper
// ============================================================
export const SwiperCarouselWrapper = styled.div`
  /* Parent
  ------------------------------- */
  position: relative;
  width: 100%;

  /* Childrens
  ------------------------------- */
  .swiper-carousel-item {
    display: block;

    &.is-cursor {
      cursor: pointer;
    }
  }

  /* Overwrite default swiper classes */
  .swiper-container {
    &.swiper-container-horizontal {
      /* Pagination */
      > .swiper-pagination-bullets {
        bottom: 36px;

        .swiper-pagination-bullet {
          transition: ${VARIABLES.TRANSITIONS.DEFAULT};
          opacity: 1;
          width: 10px;
          height: 10px;
          margin-right: 2px;
          margin-left: 2px;
          background-color: ${VARIABLES.COLORS.TRANSPARENT};
          border: 2px solid ${VARIABLES.COLORS.WHITE};
          border-radius: 50%;

          &.swiper-pagination-bullet-active {
            background-color: ${VARIABLES.COLORS.WHITE};
          }
        }
      }
    }
  }

  .swiper-button-next,
  .swiper-button-prev {
    transform: translateY(-50%);
    position: absolute;
    top: 50%;
    width: 26px;
    height: 26px;
    margin-top: 0;
    background-size: contain;
  }

  .swiper-button-next {
    right: 35px;
    background-image: url(${require('./../../../themes/images/icons/ic-arrow-carousel-next.svg')});
  }

  .swiper-button-prev {
    left: 35px;
    background-image: url(${require('./../../../themes/images/icons/ic-arrow-carousel-prev.svg')});
  }

  .swiper-carousel-title {
    position: absolute;
    z-index: ${VARIABLES.Z_INDEXS.LV_3};
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    width: 100%;
    height: 35%;
    padding-right: 35px;
    padding-bottom: 35px;
    background: rgb(0,0,0);
    background: -moz-linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.65) 100%);
    background: -webkit-linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.65) 100%);
    background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.65) 100%);
  }

  .swiper-carousel-title-text {
    ${TYPOGRAPHYS.FIRST.REGULAR.FS_16};
    overflow: hidden;
    display: block;
    width: 50%;
    color: ${VARIABLES.COLORS.WHITE};
    text-align: right;
  }

  .swiper-carousel-title-text-ellipsis {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  .swiper-carousel-label {
    position: absolute;
    z-index: ${VARIABLES.Z_INDEXS.LV_3};
    top: 15px;
    left: 20px;
    display: block;

    &.is-news {}

    &.is-promotions {}
  }

  /* Modifiers
  ------------------------------- */
  &.is-ui-card {
    .swiper-carousel-item {
      width: 162px;
      margin-left: 7px;
      margin-bottom: 5px;

      &:first-child {
        margin-left: 15px;
      }

      &:last-child {
        margin-right: 15px;
      }

      @media (max-width: ${VARIABLES.BREAKPOINTS.MOBILE_XS_MAX}) {
        width: 132px;
      }

      @media (min-width: ${VARIABLES.BREAKPOINTS.MOBILE_SM}) and (max-width: ${VARIABLES.BREAKPOINTS.MOBILE_SM_MAX}) {
        width: 152px;
      }
    }
  }

  &.is-news-promotions {
    .swiper-carousel-item {
      padding-right: 55px;
      padding-left: 55px;
    }

    .swiper-button-next,
    .swiper-button-prev {
      transform: translateY(0);
      top: 305px;
    }

    .swiper-button-next {
      right: 19px;
      background-image: url(${require('./../../../themes/images/icons/ic-arrow-carousel-next-blue.svg')});
    }

    .swiper-button-prev {
      left: 19px;
      background-image: url(${require('./../../../themes/images/icons/ic-arrow-carousel-prev-blue.svg')});
    }
  }

  /* Media queries
  ------------------------------- */
  .swiper-carousel-title {
    @media (max-width: ${VARIABLES.BREAKPOINTS.BP_1440}) {
      padding-right: 15px;
      padding-bottom: 15px;
    }
  }

  .swiper-carousel-label {
    &.is-news {
      @media (max-width: ${VARIABLES.BREAKPOINTS.BP_1440}) {
        .image-base {
          width: 120px;
          padding-bottom: 73px;
        }
      }
    }

    &.is-promotions {
      @media (max-width: ${VARIABLES.BREAKPOINTS.BP_1440}) {
        .image-base {
          width: 230px;
          padding-bottom: 97px;
        }
      }
    }
  }
`
