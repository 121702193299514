import styled, {
  // css
} from 'styled-components'
import {
  default as VARIABLES
} from './../../../themes/styles/bases/variables'
// import {
//   default as TYPOGRAPHYS
// } from './../../../themes/styles/bases/typographys'
// import {
//   default as MIXINS
// } from './../../../themes/styles/helpers/mixins'

// Sample using image in style
// url(${require('./images/icons/icon-sample.svg')});
// url(${require('./../../../themes/images/icons/icon-sample.svg')});

// Wrapper
// ============================================================
export const AccordionControlBaseWrapper = styled.div`
  /* Animation
  ------------------------------- */
  @keyframes fade-in {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
  }

  /* Parent
  ------------------------------- */
  /* Childrens
  ------------------------------- */
  /* Container */
  .accordion {
    border: 1px solid #A0A0A0;
  }

  /* List */
  .accordion__item {
    border-bottom: 1px solid #A0A0A0;

    &:last-child {
      border-bottom: none;
    }
  }

  /* Button */
  .accordion__title {
    cursor: pointer;
    position: relative;
    padding-right: 30px;

    &:focus {
      outline: none;
    }

    /* Arrow */
    &:before,
    &:after {
      content: ' ';
      position: absolute;
      z-index: ${VARIABLES.Z_INDEXS.LV_1};
      top: 50%;
      right: 11px;
      display: block;
      width: 8px;
      height: 2px;
      background-color: ${VARIABLES.COLORS.BLACK};
      border-radius: 2px;
    }

    &:before {
      transform: translate(-2px, -50%) rotate(45deg);
    }

    &:after {
      transform: translate(2px, -50%) rotate(-45deg);
    }

    /* Open content */
    &[aria-expanded='true'] {
      &:before {
        transform: translate(-2px, -50%) rotate(-45deg);
      }

      &:after {
        transform: translate(2px, -50%) rotate(45deg);
      }
    }
  }

  /* Content */
  .accordion__body {
    /* animation: fade-in 0.3s ease; */
    display: block;
    background-color: #E9E9F0;

    &.accordion__body--hidden {
      display: none;
    }
  }

  /* Modifiers
  ------------------------------- */
  /* Dropdown */
  &.is-dropdown {
    display: inline-block;
    vertical-align: middle;

    /* Container */
    .accordion {
      border: none;
    }

    /* List */
    .accordion__item {
      position: relative;
      border-bottom: none;
    }

    /* Button */
    .accordion__title {
      padding-right: 0;

      &:before,
      &:after {
        display: none;
      }
    }

    /* Content */
    .accordion__body {
      position: absolute;
      z-index: ${VARIABLES.Z_INDEXS.LV_1};
      top: 100%;
      left: 0;
      background-color: transparent;

      &.accordion__body--hidden {
        pointer-events: none;
        display: block;
        visibility: hidden;
      }
    }
  }

  /* Media queries
  ------------------------------- */
`
