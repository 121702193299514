import React from 'react'
import ClassNames from 'classnames'
// import PropTypes from 'prop-types'
import {
  ContainerBase
} from './../../base'
// import {
// } from './../../../components'
// import {
//   default as VARIABLES
// } from './../../../themes/styles/bases/variables'
// import {
//   default as TYPOGRAPHYS
// } from './../../../themes/styles/bases/typographys'
// import {
//   default as UTILITIES
// } from './../../../themes/styles/helpers/utilities'

/**
 * Container description:
 * - control site width
 */

export class Container extends React.PureComponent {
  render () {
    const {
      className,
      ui
    } = this.props

    // props for css classes
    const classes = ClassNames(
      className,
      // { 'is-modifier-name': modifierName }
    )

    return (
      <React.Fragment>
        {(() => {
          switch (ui) {
            case 'fullViewBgImageCover':
              return (
                <ContainerBase
                  {...this.props}
                  className={classes}
                  calcMinHeight='100vh'
                  bgSize='cover'
                  bgRepeat='no-repeat'
                  bgPosition='center'
                />
              )
            case 'fullView':
              return (
                <ContainerBase
                  {...this.props}
                  className={classes}
                  height='100'
                  heightUnit='vh'
                />
              )
            case 'fullViewCenter':
              return (
                <ContainerBase
                  {...this.props}
                  className={classes}
                  calcMinHeight='100vh'
                  justify='center'
                  align='center'
                />
              )
            case 'standAlone':
              return (
                <ContainerBase
                  {...this.props}
                  className={ClassNames(
                    className,
                    'is-standalone',
                  )}
                />
              )
            default:
              return (
                <ContainerBase
                  {...this.props}
                  className={classes}
                />
              )
          }
        })()}
      </React.Fragment>
    )
  }

  static defaultProps = {
    // * If you set new default props, you should create propsType for correct styleguide document.
  }

  static propTypes = {
    // TYPE > node, string, number, bool, array, object, symbol, func
    /**
    * props_name_oneType
    */
    // props_name_onetype: PropTypes.string,

    /**
    * props_name_oneMoreType
    */
    // props_name_oneMoreType: PropTypes.oneOfType([
    //   PropTypes.node,
    //   PropTypes.string
    // ])
  }
}

// export class ContainerFullViewBgCover extends React.PureComponent {
//   render () {
//     const {
//       className
//     } = this.props

//     // props for css classes
//     const classes = ClassNames(
//       className
//     )

//     return (
//       <ContainerBase
//         className={classes}
//         height='100'
//         heightUnit='vh'
//         bgSize='cover'
//         bgRepeat='no-repeat'
//         bgPosition='center'
//       />
//     )
//   }
// }
