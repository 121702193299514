import { dataCreators, DATA_REDUX_STATE_KEY } from "../../actions/dataAction";
import { projectReferenceController } from "../../apiService/apiController/projectReferenceService";
import { ModelProjectReference, ModelProjectReferenceDetail, ModelProjectReferenceProject } from "../../models/ModelProjectReference";
import { storeDispatch, storeGetState } from "../../stores";
import { getUserAuthRedux } from "../functions/auth";
import { handleSortParams, handleTrimParams } from "../functions/data";
import { isValidResponse } from "../functions/validation";

export const getProjectReferenceList = async (params = {}) => {
  let userAuth = getUserAuthRedux();
  let currentParams = storeGetState().dataRedux.projectDocumentReferenceParams;
  let newParams = handleTrimParams({
    ...currentParams,
    ...params,
    auth_deal_id: userAuth.id,
  });
  if (!newParams.isNoSort) {
    if (!newParams.sort) {
      newParams.sort = 'reference_id desc';
    }
    else {
      newParams = handleSortParams(currentParams, newParams);
    }
  }
  const projectReferenceService = projectReferenceController();
  const res = await projectReferenceService.projectReferenceSearch(newParams);
  if (isValidResponse(res)) {
    let dataList = (res.data || []).map(e => new ModelProjectReference(e));
    newParams.activePage = newParams.page;
    newParams.totalData = res.total_items;
    console.log('getProjectReferenceList', res.data, dataList, newParams);
    storeDispatch(dataCreators.setDataRedux(DATA_REDUX_STATE_KEY.PROJECT_DOCUMENT_REFERENCE_LIST, dataList));
    storeDispatch(dataCreators.setDataRedux(DATA_REDUX_STATE_KEY.PROJECT_DOCUMENT_REFERENCE_PARAMS, newParams));
  }
}

export const getProjectReference = async (params = {}) => {
  const configService = {
    urlParams: {
      ...params,
    }
  }
  const projectReferenceService = projectReferenceController(configService);
  const res = await projectReferenceService.projectReference();
  if (isValidResponse(res)) {
    let data = new ModelProjectReferenceDetail(res.data);
    return data;
  }
}

// export const getProjectReferenceProjectList = async (params = {}) => {
//   let userAuth = getUserAuthRedux();
//   const configService = {
//     urlParams: {
//       ...params,
//       auth_deal_id: userAuth.id,
//     }
//   }
//   const projectReferenceService = projectReferenceController(configService);
//   const res = await projectReferenceService.projectReferenceProject();
//   if (isValidResponse(res)) {
//     let dataList = (res.data || []).map(e => new ModelProjectReferenceProject(e));
//     return dataList;
//   }
//   return [];
// }

export const getClearProjectReferenceParams = () => {
  return {
    sort: '',
    customer_id: '',
    reference_project_name: '',
    reference_name: '',
    pre_sales_trans_id: '',
    start_date: '',
    end_date: '',
    isNoSort: false,
  }
}