import moment from 'moment';
import 'moment/locale/th';
import { DATE_FORMAT, DATE_TIME_FORMAT } from '../constants/data';
import { FIREBASE_PUBLIC_STORAGE_PATH } from '../../config/config';
import { ENUM } from '../constants/enum';

export const displayWithComma = (number = 0) => {
  if (number) {
    let parts = number.toString().split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return parts.join('.');
  }
  return '0';
}

export const displayWithPrefix = (number = 0, length = 3) => {
  if (number) {
    const text = (number + '');
    return text.length >= length ? text : ('0'.repeat(length) + text).slice(-length);
  }
  return '0'
}

export const displayDate = (date, opt = '/', format = DATE_FORMAT, locale = 'en') => {
  const tempFormat = format.replace(/\//g, opt);
  let result = moment(date)
  if (locale === 'th') {
    result = result.add(543, 'years')
  }
  result = result.locale(locale).format(tempFormat);
  return result === 'Invalid date' ? '-' : result
}

export const displayDateTime = (date, opt = '-', format = DATE_TIME_FORMAT, locale = 'en') => {
  const tempFormat = format.replace(/-/g, opt);
  let result = moment(date)
  if (locale === 'th') {
    result = result.add(543, 'years')
  }
  result = result.locale(locale).format(tempFormat);
  return result === 'Invalid date' ? '-' : result
}

export const displayPreviewImage = (input, success = () => { }) => {
  if (input.files && input.files[0]) {
    var reader = new FileReader()
    const file = input.files[0]
    reader.onload = (e) => {
      success(file, e.target.result)
    }
    reader.readAsDataURL(input.files[0]);
  }
}

export const capitalizeFirstLetter = (string = '') => {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export const displayUrlPath = (path) => {
  return path.indexOf('http') === 0 ? path : path ? `${FIREBASE_PUBLIC_STORAGE_PATH}/${path}` : '';
}

export const displayMoreText = (list = [], cntDisplay = 2, target) => {
  let result = list.filter((e, i) => i < cntDisplay).map(e => e[target]).join(', ');
  if (list.length > cntDisplay) {
    result = `${result} ... ${list.length - cntDisplay}`;
  }
  return result;
}

export const displayFloatDigit = (number, digit = 2) => {
  return Math.round(number * Math.pow(10, digit)) / Math.pow(10, digit)
}

export const displayUnit = (profileType) => {
  const conProfileType = ['Truss C75-65', 'Truss C75-75', 'RengR30-45', 'RengR32-45', 'RengR35-45', 'trussc7565', 'trussc7575', 'rengr3045', 'rengr3245', 'rengr3545']
  return conProfileType.includes(profileType) ? 'stick (s)' : 'sheet (s)';
}

export const displayAddress = (data) => {
  return `${data.address}
  ${data.alley || ''}
  ${data.group || ''}
  ${data.street || ''}
  ${data.ward || ''}
  ${(data.addressType === ENUM.COUNTRY_TYPE.THAI ? (data.subDistrict || {}).label : data.subDistrict) || ''}
  ${data.district || ''}
  ${data.selectedCity ? data.addressType === ENUM.COUNTRY_TYPE.LAOS ? data.selectedCity : (data.selectedCity.value === '-' ? '' : data.selectedCity.label) : ''}
  ${data.selectedProvince ? data.addressType === ENUM.COUNTRY_TYPE.LAOS ? `${data.selectedProvince},` : `${data.selectedProvince.label},` : ''}
  ${data.postCode ? (data.addressType === ENUM.COUNTRY_TYPE.THAI ? `${(data.postCode || {}).label},` : `${data.postCode},`) : ''}
  ${data.country}`;
}

export const handleDisplayImage = (image = {}, defaultImage) => {
  if (image.pathRef || image.file) {
    if (image.file) {
      return URL.createObjectURL(image.file);
    }
    return image.path;
  }
  return defaultImage;
}

export const zeroPad = (num, places = 4) => {
  return String(num).padStart(places, '0');
}