import _ from 'lodash';
import React from 'react';
import { DATA } from '../../helpers/constants/data';
import { ENUM } from '../../helpers/constants/enum';
import { displayAddress } from '../../helpers/functions/display';
import { getPublicWarrantySelGroupList, getWarrantyQRCertificateDetail, getWarrantyQRTemplateDetail, getWarrantyQRWarrantyDocumentaryDetail } from '../../helpers/services/warranty';
import {
  Container,
  WarrantyBSWMStatus,
} from './../../components';
import {
  CONTENTS
} from './../../themes';

export class PublicWarrantyAndDocumentaryScreen extends React.Component {

  constructor(props) {
    super(props);
    const params = new URLSearchParams(props.location.search);
    const type = params.get('type');
    const id = params.get('id');
    const groupList = (params.get('group') || '').replaceAll('_', ' ').split(',');
    const wrtSelGrp = params.get('wrtSelGrp');
    const wrtSelTemplateGrp = params.get('wrtSelTemplateGrp');
    const wrtCat = params.get('wrtCat');
    const wrtDocNo = params.get('wrtDocNo');
    const language = params.get('lang');
    this.state = {
      isWarranty: type === DATA.PUBLIC_WARRANTY_DOCUMENTARY_TYPE.WARRANTY,
      isWarrantyDocumentary: type === DATA.PUBLIC_WARRANTY_DOCUMENTARY_TYPE.WARRANTY_DOCUMENTARY,
      isCertificate: type === DATA.PUBLIC_WARRANTY_DOCUMENTARY_TYPE.CERTIFICATE,
      isWarrantyTemplate: type === DATA.PUBLIC_WARRANTY_DOCUMENTARY_TYPE.WARRANTY_TEMPLATE,
      id,
      groupList,
      wrtSelGrp,
      wrtSelTemplateGrp,
      wrtCat,
      wrtDocNo: wrtDocNo ? decodeURIComponent(wrtDocNo) : '-',
      language: language ? language : DATA.LANGUAGE.THAI,
      data: {}
    }
  }

  componentDidMount() {
    this.publicWarrantyAndDocumentary.getData();
  }

  publicWarrantyAndDocumentary = {
    getData: async () => {
      const {
        isWarranty,
        isWarrantyDocumentary,
        isCertificate,
        isWarrantyTemplate,
        id,
        groupList,
        wrtSelGrp,
        wrtSelTemplateGrp,
        wrtCat,
        wrtDocNo,
      } = this.state;
      if (isWarranty) {
        const warrantyList = await getPublicWarrantySelGroupList({
          wrt_sel_grp: wrtSelGrp,
          wrt_sel_template_grp: wrtSelTemplateGrp,
          country: ENUM.COUNTRY.THAI
        })
        const data = warrantyList.filter(e => e.id === parseInt(id))[0]
        if (data) {
          data.displayAddress = displayAddress({
            address: data.projectAddr,
            addressType: ENUM.COUNTRY_TYPE.THAI,
            subDistrict: data.projectSubDist,
            selectedCity: data.selectedCity,
            selectedProvince: data.selectedProvince,
            postCode: data.projectPostCode,
            country: ENUM.COUNTRY.THAI,
          })

          if (data.wrtCat === DATA.WARRANTY_CATEGORY_TYPE.RESIDENTIAL.TYPE) {
            let coilOrderMCal = 0;
            warrantyList.forEach(e => {
              coilOrderMCal += e.detailList[0].coilOrderMCal || 0
            })
            data.coilOrderMCal = coilOrderMCal;
          }
          else {
            let applicationSelectList = []
            let applicationSelectNameList = []
            let productGroupNameList = []
            warrantyList.forEach(e => {
              if (productGroupNameList.indexOf(e.productGroupName) === -1) {
                productGroupNameList.push(e.productGroupName)
                _.get(e, 'detailList.0.applicationSelectList', []).forEach(eda => {
                  if (applicationSelectNameList.indexOf(eda.name) === -1) {
                    applicationSelectList.push(eda);
                    applicationSelectNameList.push(eda.name);
                  }
                  else {
                    applicationSelectList = applicationSelectList.map(ed => {
                      if (eda.name === ed.name) {
                        if (!ed.value) {
                          ed.value = 0
                        }
                        if (!eda.value) {
                          eda.value = 0
                        }
                        ed.value += eda.value;
                      }
                      return ed
                    })
                  }
                });
              }
            })
            data.applicationSelectList = applicationSelectList
          }
          data.productGroup = _.uniq(warrantyList.map(e => e.productGroupName)).join(', ');

          console.log('data', data)
          this.setState({
            data
          })
        }
      }
      if (isCertificate) {
        const params = {
          id
        };
        const data = await getWarrantyQRCertificateDetail(params);
        this.setState({
          data
        })
      }
      if (isWarrantyDocumentary) {
        const params = {
          id,
        };
        const data = await getWarrantyQRWarrantyDocumentaryDetail(params);
        data.productGroup = groupList.join(', ');
        data.wrtDocNo = wrtDocNo;
        this.setState({
          data
        })
      }
      if (isWarrantyTemplate) {
        const params = {
          location_account__c: id,
        };
        const data = await getWarrantyQRTemplateDetail(params);
        data.wrtCat = wrtCat
        data.coilOrderMCal = 1;
        data.applicationSelectList = [{
          name: DATA.WARRANTY_APP_DETAIL_NAME_LIST[0],
          value: 1,
        }]
        this.setState({
          data
        })
      }
    }
  }

  render() {
    const {
      isWarranty,
      isWarrantyDocumentary,
      isCertificate,
      isWarrantyTemplate,
      data,
      language,
    } = this.state;
    return (
      <Container
        justify='center'
        align='center'
        calcMinHeight='100vh'
        bgSize='cover'
        bgRepeat='no-repeat'
        bgPosition='center'
        bgImage={CONTENTS['bg-warranty-bswm-status.png']}
      >
        <WarrantyBSWMStatus
          isWarranty={isWarranty}
          isWarrantyTemplate={isWarrantyTemplate}
          isWarrantyDocumentary={isWarrantyDocumentary}
          isCertificate={isCertificate}
          data={data}
          language={language}
        />
      </Container>
    )
  }
}
