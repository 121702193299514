import styled, {
  // css
} from 'styled-components'
import {
  default as VARIABLES
} from './../../../themes/styles/bases/variables'
import {
  default as TYPOGRAPHYS
} from './../../../themes/styles/bases/typographys'
// import {
//   default as MIXINS
// } from './../../../themes/styles/helpers/mixins'

// Sample using image in style
// url(${require('./../../../themes/images/icons/icon-sample.svg')});

// Wrapper
// ============================================================
export const SegmentCardWrapper = styled.div`
  /* Parent
  ------------------------------- */
  height: 100%;

  /* Childrens
  ------------------------------- */
  .segment-card-container {
    cursor: pointer;
    transition: ${VARIABLES.TRANSITIONS.DEFAULT};
    height: 100%;
    padding: 15px;
    background-color: ${VARIABLES.COLORS.WHITE};
    border: 1px solid ${VARIABLES.COLORS.GRAY_8};
    border-radius: 5px;
  }

  .segment-card-image-container {
    overflow: hidden;
    position: relative;
    display: inline-block;
    width: 100%;
    padding-bottom: 100%;
    background-color: ${VARIABLES.COLORS.WHITE};
    border-radius: 5px;
    vertical-align: middle;
  }

  .segment-card-image {
    position: absolute;
    z-index: ${VARIABLES.Z_INDEXS.LV_1};
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .text-base {
    &.is-segment-name,
    &.is-sub-segment-name {
      word-break: break-all;
    }
  }

  /* Modifiers
  ------------------------------- */
  &.is-not-selected {
    .segment-card-container {
      opacity: 0.33;
    }

    &:hover {
      .segment-card-container {
        opacity: 1;
      }
    }
  }

  &.is-active {
    background-color: ${VARIABLES.COLORS.GRAY_1};
  }

  &.is-selected {
    .segment-card-container {
      background-color: ${VARIABLES.COLORS.PRIMARY_2};
      border: 1px solid ${VARIABLES.COLORS.PRIMARY_2};

      .text-base {
        transition: ${VARIABLES.TRANSITIONS.DEFAULT};

        &.is-segment-name {
          ${TYPOGRAPHYS.FIRST.MEDIUM.FS_16};
          color: ${VARIABLES.COLORS.WHITE};
        }

        &.is-sub-segment-name {
          color: ${VARIABLES.COLORS.GRAY_3};
        }
      }
    }
  }

  &.is-open-subsegment-lists {
    position: relative;

    &:after {
      content: ' ';
      transform: translateX(-50%);
      position: absolute;
      z-index: ${VARIABLES.Z_INDEXS.LV_1};
      top: 100%;
      left: 50%;
      display: block;
      width: 22px;
      height: 12px;
      margin: 4px 0 0;
      background-image: url(${require('./../../../themes/images/icons/ic-arrow-tail-subsegment.svg')});
      background-size: 22px 12px;
    }
  }

  &.is-name-break-word {
    .text-base {
      &.is-segment-name {
        word-break: break-word;
      }
    }
  }

  /* Media queries
  ------------------------------- */
`
