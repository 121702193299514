import apiService from "../apiService";

export const warrantyController = (configService = {}) => {
  const service = apiService(configService);
  return {
    getWarrantyDetail: (params) => {
      return service.get(`/warranty/detail/${params.id}`, params);
    },
    searchWarranty: (params) => {
      return service.post(`/warranty/search`, params);
    },
    createWarranty: (params) => {
      return service.post(`/warranty`, params);
    },
    createWarrantyTran: (params) => {
      return service.post(`/warranty/draft`, params);
    },
    createWarrantyMulti: (params) => {
      return service.post(`/warranty/multiple`, params);
    },
    updateWarranty: (params) => {
      return service.put(`/warranty`, params);
    },
    deleteWarranty: (params) => {
      return service.delete(`/warranty/${params.id}`, params);
    },
    updateDocument: (params) => {
      return service.put(`/warranty/document`, params);
    },
    warrantyYear: () => {
      return service.get(`/warranty/year`);
    },
    warrantyYearDetail: (params) => {
      return service.get(`/warranty/year/${params.m3ProductGroupCode}`);
    },
    createTempCustomer: (params) => {
      return service.post(`/warranty/temp-customer`, params);
    },
    searchTempCustomer: (params) => {
      return service.post(`/warranty/temp-customer/search`, params);
    },
    warrantyEnvironmentProductDetail: (params) => {
      return service.post(`/warranty/environment/product/detail`, params);
    },
    warrantyEnvironmentAutomatic: () => {
      return service.get(`/warranty/environment/automatic`);
    },
    warrantyEnvironment: () => {
      return service.get(`/warranty/environment`);
    },
    warrantyApplication: () => {
      return service.get(`/warranty/application`);
    },
    warrantyConditionTemplateSearchDetail: (params) => {
      return service.post(`/warranty/condition-template/search/detail`, params);
    },
    warrantyDocumentaryDetail: (params) => {
      return service.post(`/warranty/documentary/detail`, params);
    },
    warrantyQRCertificateDetail: (params) => {
      return service.post(`/warranty/qr/certificate/detail`, params);
    },
    warrantyQRWarrantyDocumentaryDetail: (params) => {
      return service.post(`/warranty/qr/warranty-documentary/detail`, params);
    },
    warrantyQRDetail: (params) => {
      return service.post(`/warranty/qr/detail`, params);
    },
    warrantyQRTemplateDetail: (params) => {
      return service.post(`/warranty/qr/template/detail`, params);
    },
    sendMailToAdminApproved: (params) => {
      return service.post('/warranty/mail', params)
    },
  };
};
