import React from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import {
  connect,
} from 'react-redux';
import {
  ShapeContainer,
  Section,
  Grid,
  Button,
  Table,
  TableAction,
  Image,
  Field,
  TitlePage,
  SelectControl,
  PaginationControl,
  Text,
  TitleSection,
} from './../../components'
import { VARIABLES, ICONS } from '../../themes';
import { getProductList } from '../../helpers/services/product';
import { MAIN_REDUX_STATE_KEY } from '../../actions/mainAction';
import { bindActionCreators } from 'redux';
import { dataCreators, DATA_REDUX_STATE_KEY } from '../../actions/dataAction';
import { handleSortData } from '../../helpers/functions/data';
import { getLanguage } from '../../helpers/functions/language';
import _ from 'lodash';
import { toast } from '../../helpers/functions/main';
import { getUserAuthRedux } from '../../helpers/functions/auth';
import { ENUM } from '../../helpers/constants/enum';
import { OrderProductIndoListContainerConnected } from './OrderProductIndoListContainer';
import { OrderProductMalayListContainerConnected } from './OrderProductMalayListContainer';
import { OrderProductThaiListContainerConnected } from './OrderProductThaiListContainer';
import { OrderProductVietnamListContainerConnected } from './OrderProductVietnamListContainer';

class OrderProductListContainer extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      activePage: 1,
      dataPerPage: 10,
      optionProductGroupList: [],
      optionThicknessList: [],
      optionThicknessBMTList: [],
      optionCoilIdList: [],
      optionColorList: [],
      optionProfileTypeList: [],
      optionProfileLengthList: [],
      filterProductGroupValue: '',
      filterThicknessValue: '',
      filterThicknessBMTValue: '',
      filterCoilIdValue: '',
      filterColorValue: '',
      filterProfileTypeValue: '',
      filterProfileLengthValue: '',
      sortIcon: {
        lotNumber: true,
        itemDescription: true,
        thicknessMM: true,
        bmtMM: true,
        topPaintColor: true,
        fAging: true,
        fTotalRemaininglength: true,
        fRemainingweight: true,
        //indo
        profileType: true,
        fBsproductgroupname: true,
        fTotalRemainingprofile: true,
        profileLength: true,
      },
    }
  }

  componentDidMount() {
    this.productList.getProductList();
  }

  productList = {
    getProductList: async () => {
      const {
        userAuth,
        productList = [],
      } = this.props;
      const {
        activePage,
        dataPerPage,
      } = this.state;
      const params = {
        page: activePage,
        limit: dataPerPage,
        adRefId: userAuth.adRefId,
        productList
      };
      const dataList = await getProductList(params);
      this.productList.handleSelectOptionList(dataList);
    },
    handleSelectOptionList: (dataList = []) => {
      let newOptionProductGroupList = [];
      let newOptionThicknessList = [];
      let newOptionCoilIdList = [];
      let newOptionColorList = [];
      //indo
      let newOptionProfileTypeList = [];
      let newOptionProfileLengthList = [];
      //malay
      let newOptionThicknessBMTList = [];
      dataList.forEach(e => {
        newOptionProductGroupList.push(e.fBsproductgroupname);
        newOptionThicknessList.push(e.thicknessMM);
        newOptionThicknessBMTList.push(e.bmtMM);
        newOptionCoilIdList.push(e.lotNumber);
        newOptionColorList.push(e.topPaintColor);
        newOptionProfileTypeList.push(e.profileType);
        newOptionProfileLengthList.push(e.profileLength);
      })
      const sortFunc = (a, b) => a - b;
      newOptionProductGroupList = [...new Set(newOptionProductGroupList)].filter(e => e).sort(sortFunc);
      newOptionThicknessList = [...new Set(newOptionThicknessList)].filter(e => e).sort(sortFunc);
      newOptionThicknessBMTList = [...new Set(newOptionThicknessBMTList)].filter(e => e).sort(sortFunc);
      newOptionCoilIdList = [...new Set(newOptionCoilIdList)].filter(e => e).sort(sortFunc);
      newOptionColorList = [...new Set(newOptionColorList)].filter(e => e).sort(sortFunc);
      newOptionProfileTypeList = [...new Set(newOptionProfileTypeList)].filter(e => e).sort(sortFunc);
      newOptionProfileLengthList = [...new Set(newOptionProfileLengthList)].filter(e => e).sort(sortFunc);
      console.log('newOptionProfileLengthList', newOptionProfileLengthList)
      const selectOptionAll = {
        value: '',
        label: getLanguage('container.orderProduct.selectOption.all'),
      }
      this.setState({
        optionProductGroupList: [selectOptionAll, ...newOptionProductGroupList.map(e => ({ value: e.toString(), label: e }))],
        optionThicknessList: [selectOptionAll, ...newOptionThicknessList.map(e => ({ value: e.toString(), label: e }))],
        optionThicknessBMTList: [selectOptionAll, ...newOptionThicknessBMTList.map(e => ({ value: e.toString(), label: e }))],
        optionCoilIdList: [selectOptionAll, ...newOptionCoilIdList.map(e => ({ value: e.toString(), label: e }))],
        optionColorList: [selectOptionAll, ...newOptionColorList.map(e => ({ value: e.toString(), label: e }))],
        optionProfileTypeList: [selectOptionAll, ...newOptionProfileTypeList.map(e => ({ value: e.toString(), label: e }))],
        optionProfileLengthList: [selectOptionAll, ...newOptionProfileLengthList.map(e => ({ value: e.toString(), label: e }))],
      });
    },
    updateData: (dataList) => {
      const {
        dataActions,
      } = this.props;
      dataActions.setDataRedux(DATA_REDUX_STATE_KEY.PRODUCT_LIST, dataList);
    },
    onClickSave: () => {
      const {
        onClickSave,
        dataList,
        isIndoToko,
      } = this.props;
      let orderMoreLengthList = [];
      if (isIndoToko) {
        orderMoreLengthList = dataList.filter(e => e.orderValue > e.fTotalRemainingprofile);
      }
      else {
        orderMoreLengthList = dataList.filter(e => e.orderValue > e.fTotalRemaininglength);
      }
      if (orderMoreLengthList.length) {
        toast.error(getLanguage('message.inventoryIsNotEnoughToPlace'));
      }
      else {
        const orderList = dataList.filter(e => e.orderValue);
        onClickSave(orderList);
      }
    },
    handleClearIcon: () => {
      const {
        sortIcon,
      } = this.state;
      let newSortIcon = {};
      Object.keys(sortIcon).forEach(e => {
        newSortIcon[e] = true;
      });
      this.setState({
        sortIcon: newSortIcon
      })
    },
    handleSortIcon: (type) => {
      const {
        sortIcon,
      } = this.state;
      let newSortIcon = {};
      let oldType = type;
      Object.keys(sortIcon).forEach(e => {
        if (sortIcon[e] === false) {
          oldType = e;
        }
        newSortIcon[e] = true;
      });
      Object.keys(sortIcon).forEach(e => {
        if (e === type) {
          if (type === oldType) {
            newSortIcon[e] = !sortIcon[e];
          }
        }
      })
      this.setState({
        sortIcon: newSortIcon
      })
      return newSortIcon
    },
    onClickSort: (type) => {
      const {
        dataList,
      } = this.props;
      const newSortIcon = this.productList.handleSortIcon(type);
      const tempDataList = handleSortData(dataList, {
        sortTarget: type,
        orderType: newSortIcon[type] ? 'desc' : 'asc'
      });
      this.productList.updateData(tempDataList);
    },
    getFilterDataPaginationList: () => {
      const {
        activePage,
        dataPerPage,
      } = this.state;
      const firstIndex = (activePage - 1) * dataPerPage;
      const lastIndex = activePage * dataPerPage;
      const filterList = this.productList.getFilterDataList();
      return filterList.filter((e, i) => i >= firstIndex && i < lastIndex);
    },
    getFilterDataList: () => {
      const {
        dataList,
        isIndoToko,
        isMalay,
        isThai,
        isVietnam,
      } = this.props;
      const {
        filterProductGroupValue,
        filterThicknessValue,
        filterThicknessBMTValue,
        filterCoilIdValue,
        filterColorValue,
        filterProfileTypeValue,
        filterProfileLengthValue,
      } = this.state;
      return isIndoToko ?
        dataList.filter((e, i) => {
          if (
            filterProductGroupValue.length === 0 &&
            filterProfileTypeValue.length === 0 &&
            filterProfileLengthValue.length === 0
          ) {
            return true;
          }
          else {
            if (
              (filterProductGroupValue.length === 0 || e.fBsproductgroupname.toString().toLowerCase() === filterProductGroupValue.toString().toLowerCase()) &&
              (filterProfileTypeValue.length === 0 || e.profileType.toString() === filterProfileTypeValue.toString()) &&
              (filterProfileLengthValue.length === 0 || e.profileLength.toString() === filterProfileLengthValue.toString())
            ) {
              return true;
            }
          }
          return false;
        })
        :
        isMalay ?
          dataList.filter((e, i) => {
            if (filterProductGroupValue.length === 0 &&
              filterThicknessBMTValue.length === 0 &&
              filterCoilIdValue.length === 0 &&
              filterColorValue.length === 0
            ) {
              return true;
            }
            else {
              if (
                (filterProductGroupValue.length === 0 || e.fBsproductgroupname.toString().toLowerCase() === filterProductGroupValue.toString().toLowerCase()) &&
                (filterThicknessBMTValue.length === 0 || e.bmtMM.toString() === filterThicknessBMTValue.toString()) &&
                (filterCoilIdValue.length === 0 || e.lotNumber.toString() === filterCoilIdValue.toString()) &&
                (filterColorValue.length === 0 || e.topPaintColor.toString() === filterColorValue.toString())
              ) {
                return true;
              }
            }
            return false;
          })
          :
          (isThai || isVietnam) ?
            dataList.filter((e, i) => {
              if (filterProductGroupValue.length === 0 &&
                filterThicknessValue.length === 0 &&
                filterCoilIdValue.length === 0 &&
                filterColorValue.length === 0
              ) {
                return true;
              }
              else {
                if (
                  (filterProductGroupValue.length === 0 || e.fBsproductgroupname.toString().toLowerCase() === filterProductGroupValue.toString().toLowerCase()) &&
                  (filterThicknessValue.length === 0 || e.thicknessMM.toString() === filterThicknessValue.toString()) &&
                  (filterCoilIdValue.length === 0 || e.lotNumber.toString() === filterCoilIdValue.toString()) &&
                  (filterColorValue.length === 0 || e.topPaintColor.toString() === filterColorValue.toString())
                ) {
                  return true;
                }
              }
              return false;
            })
            :
            dataList.filter((e, i) => {
              if (filterProductGroupValue.length === 0 &&
                filterThicknessBMTValue.length === 0 &&
                filterCoilIdValue.length === 0 &&
                filterColorValue.length === 0
              ) {
                return true;
              }
              else {
                if (
                  (filterProductGroupValue.length === 0 || e.fBsproductgroupname.toString().toLowerCase() === filterProductGroupValue.toString().toLowerCase()) &&
                  (filterThicknessBMTValue.length === 0 || e.bmtMM.toString() === filterThicknessBMTValue.toString()) &&
                  (filterCoilIdValue.length === 0 || e.lotNumber.toString() === filterCoilIdValue.toString()) &&
                  (filterColorValue.length === 0 || e.topPaintColor.toString() === filterColorValue.toString())
                ) {
                  return true;
                }
              }
              return false;
            })
    },
    renderFilter: () => {
      const {
        optionProductGroupList,
        optionThicknessBMTList,
        optionCoilIdList,
        optionColorList,
      } = this.state;
      return (
        <Grid
          gutter='10'
          gutterVertical='10'
        >
          <Grid.Column>
            <SelectControl
              filterLarge
              filterLargePlaceholderLong
              verticalLabel
              placeholder={getLanguage('container.orderProduct.selectProductGroup', '')}
              onChange={(data) => {
                this.input.onChangeFilter('filterProductGroupValue', data);
              }}
              options={optionProductGroupList}
            />
          </Grid.Column>
          <Grid.Column>
            <SelectControl
              filterLarge
              verticalLabel
              placeholder={getLanguage('container.orderProduct.selectThickness', '')}
              onChange={(data) => {
                this.input.onChangeFilter('filterThicknessBMTValue', data)
              }}
              options={optionThicknessBMTList}
            />
          </Grid.Column>
          <Grid.Column>
            <SelectControl
              filterLarge
              verticalLabel
              placeholder={getLanguage('container.orderProduct.selectCoilID', '')}
              onChange={(data) => {
                this.input.onChangeFilter('filterCoilIdValue', data)
              }}
              options={optionCoilIdList}
            />
          </Grid.Column>
          <Grid.Column>
            <SelectControl
              filterLarge
              verticalLabel
              placeholder={getLanguage('container.orderProduct.selectColor', '')}
              onChange={(data) => {
                this.input.onChangeFilter('filterColorValue', data)
              }}
              options={optionColorList}
            />
          </Grid.Column>
        </Grid>
      )
    },
    renderFilterThai: () => {
      const {
        optionProductGroupList,
        optionThicknessList,
        optionCoilIdList,
        optionColorList,
      } = this.state;
      return (
        <Grid
          gutter='10'
          gutterVertical='10'
        >
            <Grid.Column>
              <SelectControl
                filterLarge
                filterLargePlaceholderLong
                verticalLabel
                placeholder={getLanguage('container.orderProduct.selectProductGroup', '')}
                onChange={(data) => {
                  this.input.onChangeFilter('filterProductGroupValue', data);
                }}
                options={optionProductGroupList}
              />
            </Grid.Column>
          <Grid.Column>
            <SelectControl
              filterLarge
              verticalLabel
              placeholder={getLanguage('container.orderProduct.selectThickness', '')}
              onChange={(data) => {
                this.input.onChangeFilter('filterThicknessValue', data)
              }}
              options={optionThicknessList}
            />
          </Grid.Column>
          <Grid.Column>
            <SelectControl
              filterLarge
              verticalLabel
              placeholder={getLanguage('container.orderProduct.selectCoilID', '')}
              onChange={(data) => {
                this.input.onChangeFilter('filterCoilIdValue', data)
              }}
              options={optionCoilIdList}
            />
          </Grid.Column>
          <Grid.Column>
            <SelectControl
              filterLarge
              verticalLabel
              placeholder={getLanguage('container.orderProduct.selectColor', '')}
              onChange={(data) => {
                this.input.onChangeFilter('filterColorValue', data)
              }}
              options={optionColorList}
            />
          </Grid.Column>
        </Grid>
      )
    },
    renderFilterMalay: () => {
      const {
        optionProductGroupList,
        optionThicknessBMTList,
        optionCoilIdList,
        optionColorList,
      } = this.state;
      return (
        <Grid
          gutter='10'
          gutterVertical='10'
        >
          <Grid.Column>
            <SelectControl
              filterLarge
              filterLargePlaceholderLong
              verticalLabel
              placeholder={getLanguage('container.orderProduct.selectProductGroup', '')}
              onChange={(data) => {
                this.input.onChangeFilter('filterProductGroupValue', data);
              }}
              options={optionProductGroupList}
            />
          </Grid.Column>
          <Grid.Column>
            <SelectControl
              filterLarge
              verticalLabel
              placeholder={getLanguage('container.orderProduct.selectThickness', '')}
              onChange={(data) => {
                this.input.onChangeFilter('filterThicknessBMTValue', data)
              }}
              options={optionThicknessBMTList}
            />
          </Grid.Column>
          <Grid.Column>
            <SelectControl
              filterLarge
              verticalLabel
              placeholder={getLanguage('container.orderProduct.selectCoilID', '')}
              onChange={(data) => {
                this.input.onChangeFilter('filterCoilIdValue', data)
              }}
              options={optionCoilIdList}
            />
          </Grid.Column>
          <Grid.Column>
            <SelectControl
              filterLarge
              verticalLabel
              placeholder={getLanguage('container.orderProduct.selectColor', '')}
              onChange={(data) => {
                this.input.onChangeFilter('filterColorValue', data)
              }}
              options={optionColorList}
            />
          </Grid.Column>
        </Grid>
      )
    },
    renderFilterIndoToko: () => {
      const {
        optionProductGroupList,
        optionProfileTypeList,
        optionProfileLengthList,
      } = this.state;
      return (
        <Grid
          gutter='10'
          gutterVertical='10'
        >
          <Grid.Column>
            <SelectControl
              filterLarge
              filterLargePlaceholderLong
              verticalLabel
              placeholder={getLanguage('container.orderProduct.selectProductGroup', '')}
              onChange={(data) => {
                this.input.onChangeFilter('filterProductGroupValue', data);
              }}
              options={optionProductGroupList}
            />
          </Grid.Column>
          <Grid.Column>
            <SelectControl
              filterLarge
              filterLargePlaceholderLong
              verticalLabel
              placeholder={getLanguage('container.orderProduct.selectProfileType', '')}
              onChange={(data) => {
                this.input.onChangeFilter('filterProfileTypeValue', data);
              }}
              options={optionProfileTypeList}
            />
          </Grid.Column>
          <Grid.Column>
            <SelectControl
              filterLarge
              verticalLabel
              placeholder={getLanguage('container.orderProduct.selectLength', '')}
              onChange={(data) => {
                this.input.onChangeFilter('filterProfileLengthValue', data);
              }}
              options={optionProfileLengthList}
            />
          </Grid.Column>
        </Grid>
      )
    },
    //content
    renderContent: () => {
      const {
        isIndoToko,
        isMalay,
        isThai,
        isVietnam,
      } = this.props;
      const {
        sortIcon,
      } = this.state;
      const dataList = this.productList.getFilterDataPaginationList();
      if (isIndoToko) {
        return (
          <OrderProductIndoListContainerConnected
            productList={this.productList}
            input={this.input}
            sortIcon={sortIcon}
            dataList={dataList}
          />
        )
      }
      if (isMalay) {
        return (
          <OrderProductMalayListContainerConnected
            productList={this.productList}
            input={this.input}
            sortIcon={sortIcon}
            dataList={dataList}
          />
        )
      }
      if (isThai) {
        return (
          <OrderProductThaiListContainerConnected
            productList={this.productList}
            input={this.input}
            sortIcon={sortIcon}
            dataList={dataList}
          />
        )
      }
      if (isVietnam) {
        return (
          <OrderProductVietnamListContainerConnected
            productList={this.productList}
            input={this.input}
            sortIcon={sortIcon}
            dataList={dataList}
          />
        )
      }
      return (
        <Table
          striped
          fieldText
        >
          <Table.Section>
            <Table.Row>
              <Table.HeadColumn
                noSort
                maxWidth={VARIABLES.TABLE.INFO_WIDTHS.CHECKBOX_CIRCLE}
                minWidth={VARIABLES.TABLE.INFO_WIDTHS.CHECKBOX_CIRCLE}
              >
                &nbsp;
              </Table.HeadColumn>
              <Table.HeadColumn
                maxWidth={VARIABLES.TABLE.INFO_WIDTHS.COIL_ID}
                minWidth={VARIABLES.TABLE.INFO_WIDTHS.COIL_ID}
                sortAscending={sortIcon.lotNumber}
                onClickSort={() => {
                  this.productList.onClickSort('lotNumber')
                }}
              >
                {getLanguage('container.orderProduct.table.header.coilID', '')}
              </Table.HeadColumn>
              <Table.HeadColumn
                minWidth={VARIABLES.TABLE.INFO_WIDTHS.COIL_NAME}
                sortAscending={sortIcon.itemDescription}
                onClickSort={() => {
                  this.productList.onClickSort('itemDescription');
                }}
              >
                {getLanguage('container.orderProduct.table.header.coilName', '')}
              </Table.HeadColumn>
              <Table.HeadColumn
                alignRight
                maxWidth={VARIABLES.TABLE.INFO_WIDTHS.THICKNESS_APT_TCT}
                minWidth={VARIABLES.TABLE.INFO_WIDTHS.THICKNESS_APT_TCT}
                sortAscending={sortIcon.bmtMM}
                onClickSort={() => {
                  this.productList.onClickSort('bmtMM');
                }}
              >
                {getLanguage('container.orderProduct.table.header.thicknessBMT', '')}
              </Table.HeadColumn>
              <Table.HeadColumn
                alignCenter
                maxWidth={VARIABLES.TABLE.INFO_WIDTHS.COLOR}
                minWidth={VARIABLES.TABLE.INFO_WIDTHS.COLOR}
                sortAscending={sortIcon.topPaintColor}
                onClickSort={() => {
                  this.productList.onClickSort('topPaintColor');
                }}
              >
                {getLanguage('container.orderProduct.table.header.color', '')}
              </Table.HeadColumn>
              <Table.HeadColumn
                alignRight
                maxWidth={VARIABLES.TABLE.INFO_WIDTHS.AGING}
                minWidth={VARIABLES.TABLE.INFO_WIDTHS.AGING}
                sortAscending={sortIcon.fAging}
                onClickSort={() => {
                  this.productList.onClickSort('fAging');
                }}
              >
                {getLanguage('container.orderProduct.table.header.aging', '')}
              </Table.HeadColumn>
              <Table.HeadColumn
                alignRight
                maxWidth={VARIABLES.TABLE.INFO_WIDTHS.LENGTH_M}
                minWidth={VARIABLES.TABLE.INFO_WIDTHS.LENGTH_M}
                sortAscending={sortIcon.fTotalRemaininglength}
                onClickSort={() => {
                  this.productList.onClickSort('fTotalRemaininglength');
                }}
              >
                {getLanguage('container.orderProduct.table.header.length', '')}
              </Table.HeadColumn>
              <Table.HeadColumn
                alignRight
                maxWidth={VARIABLES.TABLE.INFO_WIDTHS.WEIGHT_KG}
                minWidth={VARIABLES.TABLE.INFO_WIDTHS.WEIGHT_KG}
                sortAscending={sortIcon.fRemainingweight}
                onClickSort={() => {
                  this.productList.onClickSort('fRemainingweight');
                }}
              >
                {getLanguage('container.orderProduct.table.header.weight', '')}
              </Table.HeadColumn>
              <Table.HeadColumn
                alignRight
                maxWidth={VARIABLES.TABLE.INFO_WIDTHS.INPUT_ORDER}
                minWidth={VARIABLES.TABLE.INFO_WIDTHS.INPUT_ORDER}
                noSort
              >
                {getLanguage('container.orderProduct.table.header.orders', '')}
              </Table.HeadColumn>
            </Table.Row>
          </Table.Section>
          <Section calcHeight="calc(100vh - 454px)">
            <Table.Section>
              {
                dataList.map((e, i) => {
                  return (
                    <Table.Row
                      key={i}
                    >
                      <Table.BodyColumn
                        info
                        maxWidth={VARIABLES.TABLE.INFO_WIDTHS.CHECKBOX_CIRCLE}
                        minWidth={VARIABLES.TABLE.INFO_WIDTHS.CHECKBOX_CIRCLE}
                      >
                        <Image ui={e.orderValue > 0 ? 'iconSelectedActive' : 'iconSelectedInactive'} />
                      </Table.BodyColumn>
                      <Table.BodyColumn
                        info
                        maxWidth={VARIABLES.TABLE.INFO_WIDTHS.COIL_ID}
                        minWidth={VARIABLES.TABLE.INFO_WIDTHS.COIL_ID}
                        title={e.lotNumber}
                      >
                        {e.lotNumber}
                      </Table.BodyColumn>
                      <Table.BodyColumn
                        info
                        minWidth={VARIABLES.TABLE.INFO_WIDTHS.COIL_NAME}
                        title={e.itemDescription}
                      >
                        {e.itemDescription}
                      </Table.BodyColumn>
                      <Table.BodyColumn
                        info
                        alignRight
                        maxWidth={VARIABLES.TABLE.INFO_WIDTHS.THICKNESS_APT_TCT}
                        minWidth={VARIABLES.TABLE.INFO_WIDTHS.THICKNESS_APT_TCT}
                        // title={e.thicknessMM}
                        title={e.bmtMM}
                      >
                        {/* {e.thicknessMM} */}
                        {e.bmtMM}
                      </Table.BodyColumn>
                      <Table.BodyColumn
                        info
                        alignCenter
                        maxWidth={VARIABLES.TABLE.INFO_WIDTHS.COLOR}
                        minWidth={VARIABLES.TABLE.INFO_WIDTHS.COLOR}
                        title={e.topPaintColor}
                      >
                        {e.topPaintColor}
                      </Table.BodyColumn>
                      <Table.BodyColumn
                        info
                        alignRight
                        maxWidth={VARIABLES.TABLE.INFO_WIDTHS.AGING}
                        minWidth={VARIABLES.TABLE.INFO_WIDTHS.AGING}
                        title={e.fAging ? e.fAging : '-'}
                      >
                        {e.fAging ? e.fAging : '-'}
                      </Table.BodyColumn>
                      <Table.BodyColumn
                        info
                        alignRight
                        maxWidth={VARIABLES.TABLE.INFO_WIDTHS.LENGTH_M}
                        minWidth={VARIABLES.TABLE.INFO_WIDTHS.LENGTH_M}
                        title={e.fTotalRemaininglengthWithComma}
                      >
                        {e.fTotalRemaininglengthWithComma}
                      </Table.BodyColumn>
                      <Table.BodyColumn
                        info
                        alignRight
                        maxWidth={VARIABLES.TABLE.INFO_WIDTHS.WEIGHT_KG}
                        minWidth={VARIABLES.TABLE.INFO_WIDTHS.WEIGHT_KG}
                        title={e.fRemainingweightWithComma}
                      >
                        {e.fRemainingweightWithComma}
                      </Table.BodyColumn>
                      <Table.BodyColumn
                        action
                        alignRight
                        maxWidth={VARIABLES.TABLE.INFO_WIDTHS.INPUT_ORDER}
                        minWidth={VARIABLES.TABLE.INFO_WIDTHS.INPUT_ORDER}
                      >
                        <Field name='orderValue'
                          ui='order'
                          value={e.orderValue ? e.orderValue : 0}
                          onChange={(ev) => {
                            this.input.onChangeDataInput(ev, e)
                          }}
                        />
                      </Table.BodyColumn>
                    </Table.Row>
                  )
                })
              }
            </Table.Section>
          </Section>
        </Table>
      )
    },
  }

  input = {
    onChangeFilter: (name, data) => {
      this.setState({
        [name]: data.value,
      }, () => {
        const dataList = this.productList.getFilterDataList();
        this.productList.handleSelectOptionList(dataList);
      })
    },
    onChangeDataInput: (ev, data) => {
      const {
        dataList,
      } = this.props;
      const name = ev.target.name;
      const value = parseInt(ev.target.value);
      const tempDataList = dataList.map(e => {
        if (e.id === data.id) {
          e[name] = value;
        }
        return e;
      })
      this.productList.updateData(tempDataList);
    },
  }

  pagination = {
    onPaginationChange: (pageNumber) => {
      this.setState({
        activePage: pageNumber,
      })
    }
  }

  render() {
    const {
      onClickBack,
      isIndoToko,
      isMalay,
      isThai,
      isVietnam,
    } = this.props;
    const {
      activePage,
      dataPerPage,
      // sortIcon,
    } = this.state;
    const filterList = this.productList.getFilterDataList();
    // const dataList = this.productList.getFilterDataPaginationList();
    console.log('filterList', filterList)
    return (
      <Section ui='content'>
        <Section spacingBottom='35'>
          <Grid
            gutterVertical='15'
            justify='space-between'
          >
            <Grid.Column>
              <TitlePage
                src={ICONS['ic-page-sales-transaction.svg']}
                name={getLanguage('container.orderProduct.title', '')}
              />
            </Grid.Column>
            <Grid.Column>
              {isIndoToko ?
                this.productList.renderFilterIndoToko()
                :
                isMalay ?
                  this.productList.renderFilterMalay()
                  :
                  (isThai || isVietnam) ?
                    this.productList.renderFilterThai()
                    :
                    this.productList.renderFilter()
              }
            </Grid.Column>
          </Grid>
        </Section>
        <ShapeContainer
          ui='content'
          justify='space-between'
        >
          <Section spacingBottom='45'>
            {this.productList.renderContent()}
          </Section>
          <TableAction>
            <TableAction.Left>
              <Grid gutter='20'>
                <Grid.Column>
                  <Section paddingTop='6'>
                    <Text
                      regular12
                      color={VARIABLES.COLORS.GRAY_16}
                    >
                      {getLanguage('pagination.page', '')} {activePage}/{Math.ceil(filterList.length / dataPerPage)}
                    </Text>
                  </Section>
                </Grid.Column>
                <Grid.Column>
                  <PaginationControl
                    activePage={activePage}
                    itemsCountPerPage={dataPerPage}
                    totalItemsCount={filterList.length}
                    pageRangeDisplayed={3}
                    onChange={this.pagination.onPaginationChange}
                  />
                </Grid.Column>
              </Grid>
            </TableAction.Left>
            <TableAction.Right>
              <Grid gutter='15'>
                <Grid.Column>
                  <Button
                    gray
                    ui='back'
                    name={getLanguage('field.buttonBack', '')}
                    width={VARIABLES.BUTTON.WIDTHS.W_155}
                    onClick={onClickBack}
                  />
                </Grid.Column>
                <Grid.Column>
                  <Button
                    blue
                    name={getLanguage('field.buttonSave', '')}
                    width={VARIABLES.BUTTON.WIDTHS.W_155}
                    onClick={this.productList.onClickSave}
                  />
                </Grid.Column>
              </Grid>
            </TableAction.Right>
          </TableAction>
        </ShapeContainer>
      </Section>
    )
  }
}

const mapStateToProps = (state) => {
  const dataRedux = state.dataRedux;
  const mainRedux = state.mainRedux;
  return {
    dataList: dataRedux.productList,
    activePage: dataRedux.productParams.activePage,
    totalData: dataRedux.productParams.totalData || 0,
    userAuth: mainRedux[MAIN_REDUX_STATE_KEY.USER_AUTH]
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    dataActions: bindActionCreators(dataCreators, dispatch),
  }
}

export const OrderProductListContainerConnected = connect(
  mapStateToProps,
  mapDispatchToProps
)(OrderProductListContainer)