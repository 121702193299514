import React from 'react';
import { otpController } from '../../apiService/apiController/otpService';
import { TEXT_VERSION } from '../../config/config';
import { handleUpdateOTPAuth } from '../../helpers/firebases/firebaseOTPAuth';
import { getLanguage } from '../../helpers/functions/language';
import { isShowLoading, toast } from '../../helpers/functions/main';
import { isValidResponse } from '../../helpers/functions/validation';
import {
  Container, Image, Section, ShapeContainer, Text
} from './../../components';
import {
  CONTENTS,
  ICONS, VARIABLES
} from './../../themes';

export class AuthenticationScreen extends React.Component {

  constructor(props) {
    super(props);
    const statusAuthList = ['AuthSuccess', 'AuthFail'];
    this.state = {
      status: undefined,
      statusAuthList,
    }
  }

  componentDidMount() {
    this.login.checkAuthLoginPass();
  }

  login = {
    checkAuthLoginPass: async () => {
      const {
        location,
      } = this.props;
      const {
        statusAuthList,
      } = this.state;
      console.log('location.search', location.search);
      const params = new URLSearchParams(location.search);
      const data = params.get('data');
      console.log('params', params, data);
      if (data !== null) {
        const params = {
          data
        }
        const otpService = otpController();
        const res = await otpService.otpVerify(params);
        if (isValidResponse(res)) {
          isShowLoading(true);
          handleUpdateOTPAuth(params, (otpData) => {
            if (otpData) {
              this.setState({
                status: statusAuthList[0],
                otpData,
              });
            }
            else {
              this.setState({
                status: statusAuthList[1]
              });
              toast.error(`Can't find data in firebase data = '${params.data}'`);
            }
            isShowLoading(false);
          });
        }
        else {
          this.setState({
            status: statusAuthList[1]
          })
          toast.error(res.message);
        }
      }
    },
    getTitleText: () => {
      const {
        status,
        statusAuthList,
      } = this.state;
      let text1 = '';
      let text2 = '';
      switch (status) {
        case statusAuthList[0]:
          {/* RL-707 > Authentication Success */ }
          text1 = getLanguage('login.message_6');
          text2 = getLanguage('login.message_10');
          break;
        case statusAuthList[1]:
          {/* RL-707 > Authentication Fail */ }
          text1 = getLanguage('login.message_6');
          text2 = getLanguage('login.message_11');
          break;
        default: break;
      }
      return <React.Fragment>
        <Text regular18 color={VARIABLES.COLORS.BLACK}>
          {text1}
          &nbsp;
        </Text>
        <Text bold18 color={status === statusAuthList[1] ? VARIABLES.COLORS.RED_4 : VARIABLES.COLORS.PRIMARY_2}>
          {text2}
        </Text>
      </React.Fragment >
    },
  }

  render() {
    const {
      status,
      statusAuthList,
      otpData,
    } = this.state
    return (
      <Container
        className='is-login'
        ui='fullViewBgImageCover'
        bgImage={CONTENTS['bg-login.jpg']}
      >
        <Container
          ui='fullViewCenter'
          bgColor={VARIABLES.COLORS.OVERLAY_BLUE_1}
        >
          <ShapeContainer ui='login' className='is-login'>
            <Section
              className='is-login-logo'
              justify='center'
              spacingBottom='55'
            >
              <Image ui='logoLogin' className='is-login-logo' />
            </Section>
            <Section
              className='is-login-title'
              justify='center'
              spacingBottom='45'
            >
              {this.login.getTitleText()}
            </Section>
            {/* RL-707 > Authentication Success */}
            {status === statusAuthList[0] &&
              <React.Fragment>
                <ShapeContainer fluid padding='30' bgColor={VARIABLES.COLORS.BLUE_5} borderWidth='1' borderStyle='solid' borderColor={VARIABLES.COLORS.BLUE_16} borderRadius='10'>
                  <Section justify='center' spacingBottom='20'>
                    <img className='is-login-ref-code' src={ICONS['ic-ref-code-blue.svg']} alt='Icon' />
                  </Section>
                  <Section justify='center'>
                    <Text regular16 color={VARIABLES.COLORS.BLACK}>{getLanguage('login.message_9')}</Text>
                    &nbsp;
                    <Text bold16 color={VARIABLES.COLORS.PRIMARY_2}>{otpData.referenceCode}</Text>
                    <Text regular16 color={VARIABLES.COLORS.BLACK}>.</Text>
                  </Section>
                </ShapeContainer>
              </React.Fragment>
            }
            {/* RL-707 > Authentication Fail */}
            {status === statusAuthList[1] &&
              <React.Fragment>
                <ShapeContainer fluid padding='30' bgColor={VARIABLES.COLORS.BLUE_5} borderWidth='1' borderStyle='solid' borderColor={VARIABLES.COLORS.BLUE_16} borderRadius='10'>
                  <Section justify='center' spacingBottom='20'>
                    <img className='is-login-ref-code' src={ICONS['ic-ref-code-blue.svg']} alt='Icon' />
                  </Section>
                  <Section justify='center'>
                    <Text regular16 color={VARIABLES.COLORS.BLACK}>{getLanguage('login.message_9')}</Text>
                    &nbsp;
                    <Text bold16 color={VARIABLES.COLORS.RED_4}>{getLanguage('login.message_12')}</Text>
                  </Section>
                </ShapeContainer>
              </React.Fragment>
            }
          </ShapeContainer>
          <Section
            versionRight
            ui='versionNumber'
          >
            {TEXT_VERSION}
          </Section>
        </Container>
      </Container>
    )
  }
}
