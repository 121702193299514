import _ from 'lodash';
import moment from 'moment';
import { DATA, DATE_FORMAT } from "../helpers/constants/data";
import { ENUM } from "../helpers/constants/enum";
import { WARRANTY } from "../helpers/constants/warranty";
import { getUserAuthRedux } from "../helpers/functions/auth";
import { getAllNumberInString, getCityByLabel, getCityThaiByLabel, getCountryPhone, getPhoneRemovePrefix, getProvinceByLabel, getProvinceThaiByLabel, getSubDistrictThaiByLabel, getWarrantyTypeText } from "../helpers/functions/data";
import { displayAddress, displayDate, displayMoreText, displayUrlPath, displayWithComma, displayWithPrefix } from "../helpers/functions/display";
import { getLanguage } from "../helpers/functions/language";
import { getModelWarrantyDetial, setWarrantyMaxPeriod } from "../helpers/functions/warranty";

export class ModelWarranty {
  constructor(props = {}) {
    const userAuth = getUserAuthRedux() || {};

    this.id = props.wrt_id;
    this.idWithPrefix = displayWithPrefix(props.wrt_id);
    this.createBy = props.created_by;
    this.createdDate = props.created_date;
    this.createdDateTime = moment(props.created_date)._d;
    this.displayCreatedDate = displayDate(props.created_date, '/', DATE_FORMAT);
    this.wrtNo = props.wrt_no;
    // CR: 1493
    this.oldWrtNo = props.wrt_no;
    this.wrtNo = (this.wrtNo === 'null' || this.wrtNo === 'undefined') ? '-' : this.wrtNo;
    this.projectName = props.project_name || '';
    this.projectAddr = props.project_addr || '';
    this.projectPhone = getPhoneRemovePrefix(props.project_phone);
    this.projectPhoneCountry = getCountryPhone(props.project_phone);
    this.projectSubDist = props.project_sub_dist || '';
    this.projectDist = props.project_dist || '';
    this.projectProv = props.project_prov || '';
    this.projectPostCode = props.project_post_code === 'null' || props.project_post_code === '0' ? '' : (props.project_post_code || '');
    this.projectAlley = props.project_alley || '';
    this.projectGroup = props.project_group || '';
    this.projectStreet = props.project_street || '';
    this.projectWard = props.project_ward || '';
    this.projectInsDate = props.project_ins_date ? props.project_ins_date : props.created_date;
    this.projectInsDateTime = moment(this.projectInsDate)._d;
    this.projectCountry = props.project_county || '';

    // this.projectCountry = ENUM.COUNTRY_TYPE.INDONESIA;

    this.projectCountry = this.projectCountry ? this.projectCountry : userAuth.addressType;
    this.selectedProvince = this.projectCountry === ENUM.COUNTRY_TYPE.LAOS ?
      props.project_prov
      :
      getProvinceByLabel(props.project_prov);
    this.selectedCity = this.projectCountry === ENUM.COUNTRY_TYPE.LAOS ?
      props.project_city
      :
      getCityByLabel(props.project_city);

    this.segmentId = props.segment_id;
    this.subSegmentId = props.sub_segment_id;

    this.isCMSTemplate = userAuth.addressType === ENUM.COUNTRY_TYPE.THAI;

    if (this.projectCountry === ENUM.COUNTRY_TYPE.THAI) {
      this.selectedProvince = getProvinceThaiByLabel(props.project_prov);
      this.selectedCity = getCityThaiByLabel(props.project_city, this.selectedProvince);
      // this.projectSubDist = getSubDistrictOption(this.selectedCity, this.projectSubDist);
      this.projectSubDist = getSubDistrictThaiByLabel(this.projectSubDist, this.selectedCity);
      if (this.projectPostCode) {
        this.projectPostCode = {
          label: this.projectPostCode,
          value: this.projectPostCode,
        }
      }
      else {
        this.projectPostCode = undefined;
      }
    }

    this.wrtCat = props.wrt_cat ? props.wrt_cat : DATA.WARRANTY_CATEGORY_TYPE.NON_RESIDENTIAL.TYPE;
    // CR: 1490
    // if (this.projectCountry === ENUM.COUNTRY_TYPE.THAI) {
    //   this.wrtCat = DATA.WARRANTY_CATEGORY_TYPE.NON_RESIDENTIAL.TYPE;
    // }

    this.authDealId = props.auth_deal_id;
    this.isShowWrtNo = false;

    //bswm v
    this.customerRemark = props.project_add_info ? props.project_add_info : '';
    this.isDisableGenerate = false;
    this.isGenerateResidential = false;
    //bswm ^

    //clear data for status delete
    this.isStatusDelete = false;
    if (props.wrt_status.toLowerCase() === DATA.WARRANTY_TYPE.DELETE.TEXT.toLowerCase()) {
      this.wrtNo = '-';
      this.projectName = '-';
      props.status_code = DATA.WARRANTY_TYPE.DRAFT.TYPE;
      props.wrt_status = DATA.WARRANTY_TYPE.DRAFT.TEXT;
      this.isStatusDelete = true;
    }

    // for new generate 
    // prefixQRCode
    // props.status_code = DATA.WARRANTY_TYPE.DRAFT.TYPE;
    // props.wrt_status = DATA.WARRANTY_TYPE.DRAFT.TEXT;
    // props.regenerate__c = false;
    // props.wrt_step = 1
    // props.wrt_sel_grp = null;

    this.wrtStatus = props.wrt_status;
    this.statusCode = props.status_code;
    this.wrtStep = props.wrt_step ? props.wrt_step : 1;

    this.wrtSelGrp = props.wrt_sel_grp !== null ? parseInt(props.wrt_sel_grp) : props.wrt_sel_grp;
    this.wrtSelTemplateGrp = props.wrt_sel_template_grp !== null ? parseInt(props.wrt_sel_template_grp) : props.wrt_sel_template_grp;
    this.wrtComment = '';

    this.isCanRegenerate = !!props.regenerate__c;

    if (this.isCanRegenerate) {
      props.wrt_status = DATA.WARRANTY_TYPE.RE_GENERATE.TEXT;
      this.wrtStep = 1;
      this.wrtNo = '-';
    }

    const tempStatus = getWarrantyTypeText(props.wrt_status, this.statusCode);
    this.wrtStatusText = tempStatus.typeText;
    this.wrtStatusColor = tempStatus.color;

    this.isSaveDraft =
      this.wrtStatusText === DATA.WARRANTY_TYPE.SAVE_DRAFT.TEXT &&
      this.statusCode === DATA.WARRANTY_TYPE.SAVE_DRAFT.TYPE;

    // for public warranty
    this.detailList = getModelWarrantyDetial(this, (props.detail || []))

    // this.isShowWrtNo = isShowWrtNo;
    // for public warranty
    if (this.detailList.length) {
      this.customerName = this.detailList.map(e => e.customerName)[0];
      this.customerName = this.customerName ? this.customerName : '-';
      this.orderNoText = displayMoreText(this.detailList, 2, 'name');
    }
    else {
      this.coilGrp = props.coil_grp;
      this.productGroupName = this.coilGrp;
      this.customerName = props.customer_name || '-';
      this.orderNoText = props.s1_name || '';
      this.coilOrderMWithComma = displayWithComma(props.coil_order_m);
      this.displayOrderDate = displayDate(props.order_date, '/', DATE_FORMAT);
    }

    if (this.statusCode !== DATA.WARRANTY_TYPE.DRAFT.TYPE) {
      this.isShowWrtNo = true;
    }


    this.condition = {
      wrtCriteria1: props.wrt_criteria_1,
      wrtCriteria2: props.wrt_criteria_2,
      wrtCriteria3: props.wrt_criteria_3,
      wrtCriteria4: props.wrt_criteria_4,
      wrtCriteria5: props.wrt_criteria_5,
      wrtSurEnv1: props.wrt_sur_env_1,
      wrtSurEnv2: props.wrt_sur_env_2,
      wrtSurEnv22: props.wrt_sur_env_2_2,
      wrtSurEnv3: props.wrt_sur_env_3,
      wrtSurEnv32: props.wrt_sur_env_3_2,
      wrtSurEnv4: props.wrt_sur_env_4,
      wrtSurEnv5: props.wrt_sur_env_5,
    }
    this.userId = props.user_id;
    this.customerId = props.customer_id;
    this.dealerName = props.name;
    this.dealerAddress = props.address;
    this.dealerPhone = props.phone;

    this.isCanSelectResidentialCategory = true;
    this.isCanSelectNonResidentialCategory = true;

    //indo
    if (this.projectCountry === ENUM.COUNTRY_TYPE.INDONESIA) {
      this.projectName = (this.projectName === '-' || this.projectName === '') ? this.customerName || '' : this.projectName;
      this.permitNumber = props.permit_letter_number || '';
      this.buildingType = props.building_type || '';
      this.areaBuilding = props.area_of_building || '';
      this.tokoName = props.toko_name || '';
      this.builderName = props.builder_name || '';
      this.productWeight = props.product_weight || '';
      this.tctThickness = props.tct_thickness || '';
      this.colorName = props.color_name || '';
      this.brand = props.brand || '';
    }
    //malaysia
    if (this.projectCountry === ENUM.COUNTRY_TYPE.MALAYSIA) {
      this.adRubberStamp = props.ad_rubber_stamp || '';
      this.originalReceiptInvoice = props.original_receipt_invoice || '';
      this.residentialCommercial = props.residential_commercial || '';
      this.projectName = (this.projectName === '-' || this.projectName === '') ? this.customerName || '' : this.projectName;
      this.projectEmail = props.email || '';
      this.typesOfProductPurchased = props.type_of_product_purchased || '';
      this.purposeOfPurchasedZacs = props.purpose_of_purchased_zacs || '';
      this.purchasedDateTime = props.purchased_date ? moment(props.purchased_date)._d : moment(props.created_date)._d
      this.inoivceNo = props.invoice_no || '';
      this.invoiceLineItem = props.invoice_line_item || '';
      this.revenue = props.revenue || '';
      if (userAuth.customerRoleType === ENUM.CUSTOMER_ROLE_TYPE.MALAYSIA.HARDWARE_SHOP) {
        this.dealerName = userAuth.company;
        this.dealerAddress = displayAddress(userAuth);
        this.dealerPhone = `${userAuth.mobileNoCountry.value}${userAuth.mobileNo}`;
      }
    }

    if (userAuth.addressType === ENUM.COUNTRY_TYPE.MALAYSIA) {
      if (
        userAuth.customerRoleType === ENUM.CUSTOMER_ROLE_TYPE.MALAYSIA.ROLL_FORMER
      ) {
        this.isCanSelectResidentialCategory = false;
      }

      if (
        userAuth.customerRoleType === ENUM.CUSTOMER_ROLE_TYPE.MALAYSIA.HARDWARE_SHOP ||
        userAuth.customerRoleType === ENUM.CUSTOMER_ROLE_TYPE.MALAYSIA.AD ||
        userAuth.customerRoleType === ENUM.CUSTOMER_ROLE_TYPE.MALAYSIA.DISTRIBUTOR
      ) {
        this.isCanSelectNonResidentialCategory = false;
      }
    }

    if (WARRANTY.WARRANTY_PRODUCT_GROUP_CAN_GEN_ONLY_NON_RES.indexOf(this.m3ProductGroup) >= 0) {
      this.isCanSelectResidentialCategory = false;
      this.isCanSelectNonResidentialCategory = true;
    }

    // this.warrantyDetail = new ModelWarrantyTemplateDetail();
    this.warrantyDetail = undefined;

    this.isShowDocumentary = this.projectCountry === ENUM.COUNTRY_TYPE.THAI;

    this.industrialEstateName = props.industrial_estate_name;
    this.selectedIndustrialEstate = null;

    this.distance = props.distance;
    this.distanceEnv = props.distance_env;
    this.selectedWarrantyDistance = null;
  }
}

export class ModelDefaultWarranty {
  constructor() {
    this.projectName = '';
    this.projectAddr = '';
    this.projectPhone = '';
    this.projectSubDist = '';
    this.projectDist = '';
    this.projectProv = '';
    this.projectPostCode = '';
    this.projectCountry = '';
    this.projectAlley = '';
    this.projectGroup = '';
    this.projectStreet = '';
    this.projectWard = '';
    this.wrtCat = DATA.WARRANTY_CATEGORY_TYPE.RESIDENTIAL.TYPE;
    this.detailList = [];
    this.productGroupList = [];
    this.selectedDataList = [];
    this.condition = {
      wrtCriteria1: true,
      wrtCriteria2: true,
      wrtCriteria3: true,
      wrtCriteria4: true,
      wrtCriteria5: '1',
      wrtSurEnv1: DATA.WARRANTY_CONDITION.RURAL,
      wrtSurEnv2: true,
      wrtSurEnv22: undefined,
      wrtSurEnv3: true,
      wrtSurEnv32: undefined,
      wrtSurEnv4: true,
      wrtSurEnv5: true,
    };
    this.projectPhoneCountry = getCountryPhone();
    this.dealerName = '';
    this.dealerAddress = '';
    this.dealerPhone = '';

    this.segmentSelected = undefined;

    //indo
    this.permitNumber = '';
    this.buildingType = '';
    this.areaBuilding = '';
    this.tokoName = '';
    this.tokoPhone = '';
    this.builderName = '';
    this.productWeight = '';
    this.tctThickness = '';
    this.colorName = '';
    this.applicationList = [];
    this.brand = '';

    //malaysia
    this.adRubberStamp = '';
    this.originalReceiptInvoice = '';
    this.residentialCommercial = '';
    this.projectEmail = '';
    this.typesOfProductPurchased = '';
    this.purposeOfPurchasedZacs = '';
    this.inoivceNo = '';
  };
}

class ModelWarrantyDetailTemplate {
  constructor(props = {}, applicationSelect = {}, isRoofAndWall = false) {
    // this.wrtMapId = props.wrt_map_id;
    this.m3ProductGroup = props.m3_product_group;
    this.country = props.country;
    this.productGroupName = props.product_group_name;
    this.application = _.get(props, 'application', '');
    const applicationForSelect = DATA.WARRANTY_APP_DETAIL_NAME_ENUM_LIST.filter(e => e.NAME.trim().toLowerCase() === this.application.trim().toLowerCase())[0]
    this.applicationForSelect = applicationForSelect ? applicationForSelect.VALUE : undefined;

    // if (this.application === 'Wall') {
    //   this.application = DATA.WARRANTY_APP_DETAIL_NAME_LIST[6];
    //   this.applicationForSelect = DATA.WARRANTY_APP_DETAIL_NAME_SELECT_LIST[6];
    // }

    this.template = _.get(props, 'template', '');
    this.category = props.category;
    this.detailEnvSelect = undefined;
    this.imageEnvSelectFile = undefined;
    this.imageConditionSelectFile = undefined;
    this.imageSelectPath = '';
    this.envDetailCondition = {};

    this.envDetail = {
      corrosion: '',
      peelFlake: '',
      colorFade: '',
      dirtStain: '',
    };

    this.envDetailSelect = {};

    this.envDetailSelectMax = {
      corrosion: 0,
      peelFlake: 0,
      colorFade: 0,
      dirtStain: 0,
    };

    this.isWarrantyPeriodAppSelect = true;

    this.envList = _.get(props, 'env', []).map((e, i) => {
      if (Object.keys(applicationSelect).length && applicationSelect.environment === e.environment) {
        const envDetailGroup = DATA.WARRANTY_ENV_DETAIL[props.projectCountry][this.applicationForSelect];
        if (envDetailGroup) {
          this.envDetailCondition = envDetailGroup[e.environment];
        }
        this.detailEnvSelect = i;

        if ((
          applicationSelect.corPeriods +
          applicationSelect.peelFlakePeriods +
          applicationSelect.colFadePeriods +
          applicationSelect.dirStainPeriods) > 0
        ) {
          this.envDetail = {
            corrosion: applicationSelect.corPeriods === 0 ? '' : applicationSelect.corPeriods,
            peelFlake: applicationSelect.peelFlakePeriods === 0 ? '' : applicationSelect.peelFlakePeriods,
            colorFade: applicationSelect.colFadePeriods === 0 ? '' : applicationSelect.colFadePeriods,
            dirtStain: applicationSelect.dirStainPeriods === 0 ? '' : applicationSelect.dirStainPeriods,
          };
        }
        else {
          if (isRoofAndWall && props.projectCountry === ENUM.COUNTRY_TYPE.INDONESIA) {
            this.isWarrantyPeriodAppSelect = false;
          }
        }

        this.envDetailSelect = {
          corrosion: e.corrosion,
          peelFlake: e.peel_flake,
          colorFade: e.color_fade,
          dirtStain: e.dirt_stain,
        }

        const templatePeriodMax = setWarrantyMaxPeriod({
          corrosion: e.corrosion || e.corrosion_1,
          corrosion2: e.corrosion_2,
          peelFlake: e.peel_flake,
          colorFade: e.color_fade,
          dirtStain: e.dirt_stain,
        });
        this.envDetailSelectMax = {
          corrosion: templatePeriodMax.warrantyCorPeriodMax,
          peelFlake: templatePeriodMax.warrantyPeelFlakePeriodMax,
          colorFade: templatePeriodMax.warrantyColFadePeriodMax,
          dirtStain: templatePeriodMax.warrantyDirStainPeriodMax,
        }
      }

      let isWarrantyNA = false;
      let isWarrantyNoOffer = false;
      let isWarrantySpecial = false;
      // CR: 1498
      if ((
        e.corrosion === null &&
        e.peel_flake === null &&
        e.color_fade === null &&
        e.dirt_stain === null
      ) || (
          e.corrosion === '-' &&
          e.peel_flake === '-' &&
          e.color_fade === '-' &&
          e.dirt_stain === '-'
        )
      ) {
        isWarrantyNoOffer = true;
      }
      if (
        e.corrosion === DATA.WARRANTY_ENV_TYPE.NO_OFFER &&
        e.peel_flake === DATA.WARRANTY_ENV_TYPE.NO_OFFER &&
        e.color_fade === DATA.WARRANTY_ENV_TYPE.NO_OFFER &&
        e.dirt_stain === DATA.WARRANTY_ENV_TYPE.NO_OFFER
      ) {
        isWarrantyNoOffer = true;
      }
      if (
        e.corrosion === DATA.WARRANTY_ENV_TYPE.NA &&
        e.peel_flake === DATA.WARRANTY_ENV_TYPE.NA &&
        e.color_fade === DATA.WARRANTY_ENV_TYPE.NA &&
        e.dirt_stain === DATA.WARRANTY_ENV_TYPE.NA
      ) {
        isWarrantyNA = true;
      }
      if (
        props.projectCountry === ENUM.COUNTRY_TYPE.INDONESIA &&
        e.corrosion === DATA.WARRANTY_ENV_TYPE.NA &&
        e.peel_flake === DATA.WARRANTY_ENV_TYPE.NA &&
        e.color_fade === DATA.WARRANTY_ENV_TYPE.NA &&
        e.dirt_stain === null
      ) {
        isWarrantyNA = true;
      }
      // if (
      //   e.corrosion === DATA.WARRANTY_ENV_TYPE.SPECIAL &&
      //   e.peel_flake === DATA.WARRANTY_ENV_TYPE.SPECIAL &&
      //   e.color_fade === DATA.WARRANTY_ENV_TYPE.SPECIAL &&
      //   e.dirt_stain === DATA.WARRANTY_ENV_TYPE.SPECIAL
      // ) {
      //   isWarrantySpecial = true;
      // }
      if (
        e.special === DATA.WARRANTY_ENV_TYPE.SPECIAL
      ) {
        isWarrantySpecial = true;
      }

      // CR: remove merged roof and wall for thai
      // mock wall year warranty
      // if (this.application === 'Wall') {
      //   e.corrosion = '2';
      // }

      return {
        // wrtMapEnvId: e.wrt_map_env_id,
        // wrtMapId: e.wrt_map_id,
        environment: e.environment,
        corrosion: e.corrosion || e.corrosion_1,
        corrosion2: e.corrosion_2,
        peelFlake: e.peel_flake,
        colorFade: e.color_fade,
        dirtStain: e.dirt_stain,
        special: e.special,
        isWarrantyNA,
        isWarrantyNoOffer,
        isWarrantySpecial,
      }
    });

    // CR: warranty condition
    this.questionList = [];
    if (applicationSelect.isWarrantyConditionTemplate) {
      this.condition = applicationSelect.wrtQuestionTemplateAnswer;
      this.conditionError = {}
    }
    else {
      // set default condition list
      switch (this.applicationForSelect) {
        case DATA.WARRANTY_APP_DETAIL_NAME_SELECT_LIST[2]:
        case DATA.WARRANTY_APP_DETAIL_NAME_SELECT_LIST[3]:
        case DATA.WARRANTY_APP_DETAIL_NAME_SELECT_LIST[5]:
        case DATA.WARRANTY_APP_DETAIL_NAME_SELECT_LIST[6]:
          this.condition = {
            wrtTermCon1: _.get(applicationSelect, 'wrtQuestionTemplateAnswer.wrtTermCon1', 'true') === 'true',
            wrtTermCon2: _.get(applicationSelect, 'wrtQuestionTemplateAnswer.wrtTermCon2', 'true') === 'true',
            wrtTermCon3: _.get(applicationSelect, 'wrtQuestionTemplateAnswer.wrtTermCon3', 'true') === 'true',
            wrtTermCon4: _.get(applicationSelect, 'wrtQuestionTemplateAnswer.wrtTermCon4', 'true') === 'true',
            wrtTermCon5: _.get(applicationSelect, 'wrtQuestionTemplateAnswer.wrtTermCon5', 'true') === 'true',
            wrtTermCon6: _.get(applicationSelect, 'wrtQuestionTemplateAnswer.wrtTermCon6', 'true') === 'true',
            wrtTermCon7: _.get(applicationSelect, 'wrtQuestionTemplateAnswer.wrtTermCon7', 'true') === 'true',
            wrtTermCon8: _.get(applicationSelect, 'wrtQuestionTemplateAnswer.wrtTermCon8', 'true') === 'true',
            wrtTermCon9: _.get(applicationSelect, 'wrtQuestionTemplateAnswer.wrtTermCon9', 'true') === 'true',
            wrtTermCon10: _.get(applicationSelect, 'wrtQuestionTemplateAnswer.wrtTermCon10', 'true') === 'true',
            wrtTermCon11: _.get(applicationSelect, 'wrtQuestionTemplateAnswer.wrtTermCon11', 'true') === 'true',
            wrtTermCon12: _.get(applicationSelect, 'wrtQuestionTemplateAnswer.wrtTermCon12', '0'),
            wrtTermCon13: _.get(applicationSelect, 'wrtQuestionTemplateAnswer.wrtTermCon13', '0'),
            wrtTermCon14: _.get(applicationSelect, 'wrtQuestionTemplateAnswer.wrtTermCon14', '0'),
            wrtTermCon15: _.get(applicationSelect, 'wrtQuestionTemplateAnswer.wrtTermCon15', '0'),
            wrtTermCon16: _.get(applicationSelect, 'wrtQuestionTemplateAnswer.wrtTermCon16', ''),
            wrtTermCon17: _.get(applicationSelect, 'wrtQuestionTemplateAnswer.wrtTermCon17', ''),
          };
          break;
        case DATA.WARRANTY_APP_DETAIL_NAME_SELECT_LIST[4]:
          this.condition = {
            wrtTermCon1: _.get(applicationSelect, 'wrtQuestionTemplateAnswer.wrtTermCon1', 'true') === 'true',
            wrtTermCon2: _.get(applicationSelect, 'wrtQuestionTemplateAnswer.wrtTermCon2', 'true') === 'true',
            wrtTermCon3: _.get(applicationSelect, 'wrtQuestionTemplateAnswer.wrtTermCon3', 'true') === 'true',
            wrtTermCon4: _.get(applicationSelect, 'wrtQuestionTemplateAnswer.wrtTermCon4', 'true') === 'true',
            wrtTermCon5: _.get(applicationSelect, 'wrtQuestionTemplateAnswer.wrtTermCon5', 'true') === 'true',
            wrtTermCon6: _.get(applicationSelect, 'wrtQuestionTemplateAnswer.wrtTermCon6', 'true') === 'true',
            wrtTermCon7: _.get(applicationSelect, 'wrtQuestionTemplateAnswer.wrtTermCon7', 'true') === 'true',
            wrtTermCon8: _.get(applicationSelect, 'wrtQuestionTemplateAnswer.wrtTermCon8', 'true') === 'true',
            wrtTermCon9: _.get(applicationSelect, 'wrtQuestionTemplateAnswer.wrtTermCon9', 'true') === 'true',
            wrtTermCon10: _.get(applicationSelect, 'wrtQuestionTemplateAnswer.wrtTermCon10', 'true') === 'true',
            wrtTermCon11: _.get(applicationSelect, 'wrtQuestionTemplateAnswer.wrtTermCon11', '0'),
            wrtTermCon12: _.get(applicationSelect, 'wrtQuestionTemplateAnswer.wrtTermCon12', '0'),
            wrtTermCon13: _.get(applicationSelect, 'wrtQuestionTemplateAnswer.wrtTermCon13', '0'),
            wrtTermCon14: _.get(applicationSelect, 'wrtQuestionTemplateAnswer.wrtTermCon14', '0'),
            wrtTermCon15: _.get(applicationSelect, 'wrtQuestionTemplateAnswer.wrtTermCon15', ''),
            wrtTermCon16: _.get(applicationSelect, 'wrtQuestionTemplateAnswer.wrtTermCon16', ''),
            wrtTermCon17: _.get(applicationSelect, 'wrtQuestionTemplateAnswer.wrtTermCon17',
              getLanguage('warrantyManage.form.condition.coolRoomPanelConditions.condition17_2', '')
            ),
            wrtTermCon17_1: _.get(applicationSelect, 'wrtQuestionTemplateAnswer.wrtTermCon17_1',
              getLanguage('warrantyManage.form.condition.coolRoomPanelConditions.condition17_4', ''),
            )
          };
          break;
        default:
          this.condition = {
            wrtTermCon1: _.get(applicationSelect, 'wrtQuestionTemplateAnswer.wrtTermCon1', 'true') === 'true',
            wrtTermCon2: _.get(applicationSelect, 'wrtQuestionTemplateAnswer.wrtTermCon2', 'true') === 'true',
            wrtTermCon3: _.get(applicationSelect, 'wrtQuestionTemplateAnswer.wrtTermCon3', 'true') === 'true',
            wrtTermCon4: _.get(applicationSelect, 'wrtQuestionTemplateAnswer.wrtTermCon4', 'true') === 'true',
            wrtTermCon5: _.get(applicationSelect, 'wrtQuestionTemplateAnswer.wrtTermCon5', 'true') === 'true',
            wrtTermCon6: _.get(applicationSelect, 'wrtQuestionTemplateAnswer.wrtTermCon6', 'true') === 'true',
            wrtTermCon7: _.get(applicationSelect, 'wrtQuestionTemplateAnswer.wrtTermCon7', 'true') === 'true',
            wrtTermCon8: _.get(applicationSelect, 'wrtQuestionTemplateAnswer.wrtTermCon8', 'true') === 'true',
            wrtTermCon9: _.get(applicationSelect, 'wrtQuestionTemplateAnswer.wrtTermCon9', 'true') === 'true',
            wrtTermCon10: _.get(applicationSelect, 'wrtQuestionTemplateAnswer.wrtTermCon10', 'true') === 'true',
            wrtTermCon11: _.get(applicationSelect, 'wrtQuestionTemplateAnswer.wrtTermCon11', '0'),
            wrtTermCon12: _.get(applicationSelect, 'wrtQuestionTemplateAnswer.wrtTermCon12', '0'),
            wrtTermCon13: _.get(applicationSelect, 'wrtQuestionTemplateAnswer.wrtTermCon13', '0'),
            wrtTermCon14: _.get(applicationSelect, 'wrtQuestionTemplateAnswer.wrtTermCon14', '0'),
            wrtTermCon15: _.get(applicationSelect, 'wrtQuestionTemplateAnswer.wrtTermCon15', ''),
            wrtTermCon16: _.get(applicationSelect, 'wrtQuestionTemplateAnswer.wrtTermCon16', ''),
          };
          break;
      }
    }
  }
}

export class ModelWarrantyDetail {
  constructor(props = {}) {
    this.name = props.name;
    this.orderNo = props.order_no;
    this.orderDate = props.order_date;
    this.displayOrderDate = displayDate(props.order_date, '/', DATE_FORMAT);
    this.coilGrp = props.coil_grp;
    this.coilId = props.coil_id;
    this.coilName = props.coil_name;
    this.coilOrderM = props.coil_order_m;
    this.color = props.color;
    this.coilOrderMWithComma = displayWithComma(props.coil_order_m);
    //bswm v
    this.coilOrderMCal = parseInt(this.coilOrderM);
    this.coilOrderMSelectTotal = 0;

    this.isErrorQuantity = false;
    this.isShowErrorNoQuantity = false;
    this.isErrorSelectApplicationDetail = false;
    this.m3ProductGroup = props.m3_product_group;
    this.applicationInfoList = _.get(props, 'product_remark', []).map(e => {
      return {
        projectInsDateTime: moment(e.project_ins_date)._d,
        building: e.building,
        customerRemark: e.remark,
      }
    });

    let isAcceptTncTemplate = false;

    const applicationSelectList = _.get(props, 'application', []).map(e => {
      this.coilOrderMSelectTotal += e.value;
      const applicationSelect = {
        corPeriods: e.cor_periods === null ? 0 : e.cor_periods,
        peelFlakePeriods: e.peel_flake_periods === null ? 0 : e.peel_flake_periods,
        colFadePeriods: e.col_fade_periods === null ? 0 : e.col_fade_periods,
        dirStainPeriods: e.dir_stain_periods === null ? 0 : e.dir_stain_periods,
        termConAccet: e.term_con_accet === null ? false : e.term_con_accet,
        environment: e.environment,
        name: e.name,
        value: e.value === 0 ? '' : e.value,
        // CR: warranty condition
        isWarrantyConditionTemplate: !!e.wrt_question_template_answer,
        wrtQuestionTemplateAnswer: e.wrt_question_template_answer ? JSON.parse(e.wrt_question_template_answer) : {
          wrtTermCon1: e.wrt_term_con_1 === null ? undefined : e.wrt_term_con_1,
          wrtTermCon2: e.wrt_term_con_2 === null ? undefined : e.wrt_term_con_2,
          wrtTermCon3: e.wrt_term_con_3 === null ? undefined : e.wrt_term_con_3,
          wrtTermCon4: e.wrt_term_con_4 === null ? undefined : e.wrt_term_con_4,
          wrtTermCon5: e.wrt_term_con_5 === null ? undefined : e.wrt_term_con_5,
          wrtTermCon6: e.wrt_term_con_6 === null ? undefined : e.wrt_term_con_6,
          wrtTermCon7: e.wrt_term_con_7 === null ? undefined : e.wrt_term_con_7,
          wrtTermCon8: e.wrt_term_con_8 === null ? undefined : e.wrt_term_con_8,
          wrtTermCon9: e.wrt_term_con_9 === null ? undefined : e.wrt_term_con_9,
          wrtTermCon10: e.wrt_term_con_10 === null ? undefined : e.wrt_term_con_10,
          wrtTermCon11: e.wrt_term_con_11 === null ? undefined : e.wrt_term_con_11,
          wrtTermCon12: e.wrt_term_con_12 === null ? undefined : e.wrt_term_con_12,
          wrtTermCon13: e.wrt_term_con_13 === null ? undefined : e.wrt_term_con_13,
          wrtTermCon14: e.wrt_term_con_14 === null ? undefined : e.wrt_term_con_14,
          wrtTermCon15: e.wrt_term_con_15 === null ? undefined : e.wrt_term_con_15,
          wrtTermCon16: e.wrt_term_con_16 === null ? undefined : e.wrt_term_con_16,
          wrtTermCon17: e.wrt_term_con_17 === null ? undefined : e.wrt_term_con_17,
          wrtTermCon17_1: e.wrt_term_con_17_1 === null ? undefined : e.wrt_term_con_17_1,
          wrtTermCon17Other: e.wrt_term_con_17_other === null ? undefined : e.wrt_term_con_17_other,
        }
      }

      if (applicationSelect.termConAccet) {
        isAcceptTncTemplate = true;
      }

      return applicationSelect;
    });

    this.applicationSelectList = applicationSelectList;

    this.isWarrantyPeriodSelected = false;
    this.isRoofAndWall = applicationSelectList.filter(e => (
      e.name === DATA.WARRANTY_APP_DETAIL_NAME_LIST[0] ||
      e.name === DATA.WARRANTY_APP_DETAIL_NAME_LIST[1]
    )).length === 2;

    // CR: remove merged roof and wall for thai
    if (props.projectCountry === ENUM.COUNTRY_TYPE.THAI) {
      this.isRoofAndWall = false;
    }

    this.templateList = _.get(props, 'template', []).filter(e => {
      return (e.category || '').toLowerCase() === DATA.WARRANTY_CATEGORY_TYPE.PROJECT.TEXT.toLowerCase()
    }).map(e => {
      e.projectCountry = props.projectCountry;
      const applicationSelect = applicationSelectList.filter(ea => ea.name === e.application)[0];
      const template = new ModelWarrantyDetailTemplate(e, applicationSelect, this.isRoofAndWall);

      template.isAcceptTncTemplate = isAcceptTncTemplate;

      if (applicationSelect && this.isWarrantyPeriodSelected === false) {
        this.isWarrantyPeriodSelected = (
          applicationSelect.corPeriods +
          applicationSelect.peelFlakePeriods +
          applicationSelect.colFadePeriods +
          applicationSelect.dirStainPeriods
        ) > 0;
      }

      return template;
    });

    //bswm ^

    this.roof = props.roof_wall === '-' ? '' : (props.roof_wall || '');
    if (this.roof === '' && applicationSelectList.length) {
      applicationSelectList.forEach(e => {
        if (e.name === DATA.WARRANTY_APP_DETAIL_NAME_LIST[0]) {
          this.roof = e.value === null ? '' : e.value.toString();
        }
      });
    }
    this.isRoofSelected = this.roof.length > 0;
    // this.roof = props.roof === '-' ? '' : (props.roof || '');
    // this.isRoofSelected = this.roof.length > 0;

    this.wall = props.wall === '-' ? '' : (props.wall || '');
    if (this.wall === '' && applicationSelectList.length) {
      applicationSelectList.forEach(e => {
        if (e.name === DATA.WARRANTY_APP_DETAIL_NAME_LIST[1]) {
          this.wall = e.value === null ? '' : e.value.toString();
        }
      });
    }
    this.isWallSelected = this.wall.length > 0;

    this.purlinGirt = props.purlin_girt === '-' ? '' : (props.purlin_girt || '');
    if (this.purlinGirt === '' && applicationSelectList.length) {
      applicationSelectList.forEach(e => {
        if (e.name === DATA.WARRANTY_APP_DETAIL_NAME_LIST[2]) {
          this.purlinGirt = e.value === null ? '' : e.value.toString();
        }
      });
    }
    this.isPurlinGirtSelected = this.purlinGirt.length > 0;

    this.truFrame = props.tru_frame === '-' ? '' : (props.tru_frame || '');
    if (this.truFrame === '' && applicationSelectList.length) {
      applicationSelectList.forEach(e => {
        if (e.name === DATA.WARRANTY_APP_DETAIL_NAME_LIST[3]) {
          this.truFrame = e.value === null ? '' : e.value.toString();
        }
      });
    }
    this.isTruFrameSelected = this.truFrame.length > 0;

    this.coolRoom = props.cool_room === '-' ? '' : (props.cool_room || '');
    if (this.coolRoom === '' && applicationSelectList.length) {
      applicationSelectList.forEach(e => {
        if (e.name === DATA.WARRANTY_APP_DETAIL_NAME_LIST[4]) {
          this.coolRoom = e.value === null ? '' : e.value.toString();
        }
      });
    }
    this.isCoolRoomSelected = this.coolRoom.length > 0;

    this.decking = props.decking === '-' ? '' : (props.decking || '');
    if (this.decking === '' && applicationSelectList.length) {
      applicationSelectList.forEach(e => {
        if (e.name === DATA.WARRANTY_APP_DETAIL_NAME_LIST[5]) {
          this.decking = e.value === null ? '' : e.value.toString();
        }
      });
    }
    this.isDeckingSelected = this.decking.length > 0;

    // TODO: RL-724: check field api
    this.airDucting = props.air_ducting === '-' ? '' : (props.air_ducting || '');
    if (this.airDucting === '' && applicationSelectList.length) {
      applicationSelectList.forEach(e => {
        if (e.name === DATA.WARRANTY_APP_DETAIL_NAME_LIST[6]) {
          this.airDucting = e.value === null ? '' : e.value.toString();
        }
      });
    }
    this.isAirDuctingSelected = this.airDucting.length > 0;

    this.salesTranId = props.sales_tran_id;
    this.customerName = props.customer_name;
    this.wrtId = props.wrt_id;
    this.oldFileRef = props.wrt_path || '';
    this.fileUrl = displayUrlPath(this.oldFileRef);
    this.oldFileUrl = this.fileUrl;
    // CR: 1493
    this.oldWrtPath = props.wrt_path;
    this.wrtPathBeforeRegenList = props.wrt_path_before_regen;

    this.bmt = props.bmt;
    this.thickness = props.thickness;

    this.productionDateTime = props.production_date;
    this.productionDate = this.productionDateTime ? moment(this.productionDateTime).format('YYYY-MM-DD') : null
  }
}

// export class ModelWarrantyEnvironmentDetail {
//   constructor(props = {}) {
//     this.id = props.id;
//     this.productGroupName = props.product_group_name;
//     this.envName = props.env_name;
//     this.envDescription = props.env_description;
//     this.envDescriptionTH = props.env_description_th;
//     this.envNo = props.env_no;
//     this.editedDate = props.edited_date;
//     this.createdDate = props.created_date;
//   }
// }


export class ModelWarrantyEnvironmentDetail {
  constructor(props = {}) {
    this.id = props.id ?? 0;
    this.sfid = props.sfid;
    this.productGroupName = props.product_group_name;
    this.envName = props.environment || '';
    this.envDescription = props.env_description || '';
    this.envDescriptionTH = props.env_description_th || '';
  }
}

export class ModelWarrantyEnvironmentAutomatic {
  constructor(props = {}) {
    this.id = props.id;
    this.environment = props.environment;
    this.industrialEstateName = props.industrial_estate_name;
    this.code = props.environment_code__c || '';
    this.sfid = props.sfid;

    this.label = this.industrialEstateName;
    this.value = this.industrialEstateName;
    this.warrantyEnvironment = props.environment;
    this.envId = this.id;
    this.isShowEnv = true;
    this.isIndustrialNameNotInIndustrial = (this.industrialEstateName || '').indexOf(DATA.WARRANRY_AUTOMATIC_INDUSTIAL_NAME.NOT_IN_INDUSTIAL) >= 0;
    this.isNotIndustrial = !this.industrialEstateName || this.isIndustrialNameNotInIndustrial;

    this.priority = getAllNumberInString(this.code || '')[0] || 0;
    this.priorityEnv = undefined;
    this.isGroupMarine = this.code.split('-')[1] === DATA.WARRANTY_ENV_NAME_TYPE.MARINE;
    this.isGroupIndustrial = this.isIndustrialNameNotInIndustrial || this.code.split('-')[1] === DATA.WARRANTY_ENV_NAME_TYPE.INDUSTRIAL;
    this.isGroupBenign = this.code.split('-')[1] === DATA.WARRANTY_ENV_NAME_TYPE.BENIGN;
    this.isShowWarrantyDistance = this.isGroupMarine;
    this.warrantyDistanceSelectOptionList = [];
  }
}

export class ModelWarrantyConditionTemplateSearch {
  constructor(props = {}) {
    this.productGroup = props.product_group;
    this.environmentId = props.environment_id;
    this.applicationId = props.application_id;
    this.data = props.data;

    this.warrantyConditionTemplate = props.data ? new ModelWarrantyConditionTemplate(props.data) : undefined;
  }
}

export class ModelWarrantyConditionTemplate {
  constructor(props = {}) {
    this.id = props.id;
    this.name = props.name || '';
    this.questionList = _.orderBy((props.question || []).map(e => {
      return new ModelWarrantyConditionTemplateQuestion(e);
    }), 'orderNo', 'asc');
  }
}

export class ModelWarrantyConditionTemplateQuestion {
  constructor(props = {}) {
    this.id = props.id;
    this.wrtConTempId = props.wrt_con_temp_id;
    this.titleEN = props.title_en || '';
    this.titleTH = props.title_th || '';
    this.type = props.type;
    this.orderNo = props.order_no;
    this.isRequire = !!props.is_require;
    this.isAnswerNoShowAlert = !!props.is_answer_no_show_alert;

    this.isNew = !!props.is_new;
    this.isTypeRadio = this.type === ENUM.WARRANTY_QUESTION_TYPE.RADIO;
    this.isTypeInput = this.type === ENUM.WARRANTY_QUESTION_TYPE.INPUT;
    this.isTypeTextarea = this.type === ENUM.WARRANTY_QUESTION_TYPE.TEXTAREA;
    this.isTypeSelect = this.type === ENUM.WARRANTY_QUESTION_TYPE.SELECT;
  }
}

export class WarrantyEnvironment {
  constructor(props = {}) {
    this.id = props.id || '';
    this.sfid = props.sfid || '';
    this.code = props.environment_code__c || '';
    this.environmentName = props.environment || '';
  }
}

export class WarrantyApplication {
  constructor(props = {}) {
    this.id = props.id;
    this.applicationName = props.name;
  }
}

export class ModelWarrantyQRDetail {
  constructor(props = {}) {
    this.projectName = props.project_name || '';
    this.projectAddr = props.project_addr || '';
    this.subDist = props.sub_dist || '';
    this.dist = props.dist || '';
    this.prov = props.prov || '';
    this.postCode = props.post_code || '';
    this.productGroup = props.product_group || '';
    this.company = props.company || '';
    this.preSalesTransCertId = props.pre_sales_trans_cert_id;
    this.dealerName = props.dealer_name;
    this.dealerPhone = props.dealer_phone;
    this.code = props.code || ''

    // this.displayCode = zeroPad(this.preSalesTransCertId, 7);
    this.displayCode = this.code;
    this.displayAddress = displayAddress({
      address: this.projectAddr,
      addressType: ENUM.COUNTRY_TYPE.THAI,
      subDistrict: { label: this.subDist },
      selectedCity: { label: this.dist },
      selectedProvince: { label: this.prov },
      postCode: { label: this.postCode },
      country: ENUM.COUNTRY.THAI,
    })
  }
}