import React from 'react';
import {
  connect,
} from 'react-redux';
import {
  ShapeContainer,
  Section,
  Grid,
  Button,
  Image,
  Table,
  PaginationControl,
  TableAction,
  Text,
  Field,
  SelectControl,
  RewardRedemptionCard,
  Lightbox,
} from './../../components';
import { redirect } from '../../helpers/functions/route';
import { ROUTE_PATH } from '../../helpers/constants/route';
import {
  VARIABLES,
  CONTENTS,
} from '../../themes';
import { getCMSRewardCatalogList, getClearCMSRewardParams } from '../../helpers/services/cmsRewardCatalog';
import { bindActionCreators } from 'redux';
import { DATA_REDUX_STATE_KEY, dataCreators } from '../../actions/dataAction';
import { displayWithComma } from '../../helpers/functions/display';
import { toast, onOpenModal } from '../../helpers/functions/main';
import { redemptionController } from '../../apiService/apiController/redemptionService';
import { isValidResponse } from '../../helpers/functions/validation';
import { getSortAscending } from '../../helpers/functions/data';
import { getLanguage } from '../../helpers/functions/language';
import _ from 'lodash';
import { MODAL_TYPE } from '../../helpers/constants/main';
import { storeGetState } from '../../stores';
import { ENUM } from '../../helpers/constants/enum';
import moment from 'moment';

class RewardCatalogListContainer extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      activePage: 1,
      dataPerPage: 10,
      orderByOptionList: [{
        value: 'name',
        label: 'Name',
      }, {
        value: 'pointsCal',
        label: 'Points',
      }],
      orderByOptionSelected: undefined,
      isOpenViewImageModal: false,
      isErrorUsedPoint: false,
    }
  }

  componentDidMount() {
    const {
      activePage,
      dataPerPage,
    } = this.state;
    const params = {
      ...getClearCMSRewardParams(),
      page: activePage,
      limit: 0,
    };
    getCMSRewardCatalogList(params);
  }

  rewardCatalogList = {
    getTotalUsedPoint: () => {
      const {
        dataParams,
      } = this.props;
      const usedPoint = dataParams.rewardSelectedList.map(e => e.totalPoints).sum();
      return usedPoint;
    },
    onClickSave: async () => {
      const {
        dataParams,
        customerData,
        selectSalePerson,
        isMalay,
      } = this.props;
      let rewardSelectedList = dataParams.rewardSelectedList;
      const usedPoint = this.rewardCatalogList.getTotalUsedPoint();
      const currentPoint = customerData.remainPoints;
      if (usedPoint > currentPoint) {
        toast.error(getLanguage('message.usedPointsMoreThanTotalPoints', ''));
        this.setState({
          isErrorUsedPoint: true,
        })
      }
      else {
        if (rewardSelectedList.length) {
          const isRewardMoreUnit = rewardSelectedList.filter(e => {
            if (e.stockType === ENUM.REWARD_STOCK_TYPE.IN_STOCK && e.unitValue > e.inStock) {
              return true;
            }
            return false;
          })
          if (isRewardMoreUnit.length) {
            toast.error(getLanguage('message.rewardStockItemIsNotEnoughToPlace', ''));
          }
          else {
            onOpenModal({
              type: MODAL_TYPE.CONFIRM,
              data: customerData,
              message: getLanguage('message.pleaseConfirmPointsAndUnits', ''),
              rewardLists: rewardSelectedList,
              onClickConfirm: async () => {
                let userAuth = storeGetState().mainRedux.userAuth;
                const pickupDate = isMalay ? moment().add(60, 'day')._d : undefined;
                const params = {
                  redemptions: rewardSelectedList.map(e => {
                    return {
                      reward_name: e.name,
                      reward_id: e.id,
                      unit: e.unitValue,
                      points: e.points,
                      customer_id: customerData.id,
                      customer_name: customerData.fullname,
                      phone_no: customerData.phoneCountry.value + customerData.phone,
                      sales_person_id: selectSalePerson.data.id,
                      sales_person_name: selectSalePerson.data.fullname,
                      reward_sfid: e.rewardSfid,
                      ad_ref_id: userAuth.adRefId,
                      stock_location_ref_id: e.rewardLocationSfid,
                      pickup_at: pickupDate,
                    }
                  })
                }
                const redemptionService = redemptionController();
                const res = await redemptionService.createRedemtion(params);
                if (isValidResponse(res)) {
                  toast.success(getLanguage('message.createRedemptionCompleted', ''));
                  redirect(ROUTE_PATH.REDEMPTION.LINK);
                }
                else {
                  toast.error(res.message);
                }
              }
            })
          }
        }
        else {
          toast.error(getLanguage('message.pleaseSelectRewardCatalog', ''));
        }
      }
    },
    updateData: (dataList) => {
      const {
        dataActions,
      } = this.props;
      dataActions.setDataRedux(DATA_REDUX_STATE_KEY.CMS_REWARD_CATALOG_LIST, dataList);
    },
    onClickSort: (target) => {
      getCMSRewardCatalogList({
        sort: target
      });
    },
    updateRewardSelectedList: (data) => {
      const {
        dataParams,
        dataActions
      } = this.props;
      let rewardSelectedList = dataParams.rewardSelectedList;
      let rewardSelected = data;
      rewardSelected.totalPoints = parseInt(data.points) * data.unitValue;
      let isNew = true;
      let newRewardSelectedList = []
      rewardSelectedList = rewardSelectedList.map(e => {
        if (e.uniqeId === data.uniqeId) {
          e = rewardSelected;
          isNew = false;
        }
        if (e.unitValue) {
          newRewardSelectedList.push(e);
        }
      })
      if (isNew) {
        newRewardSelectedList.push(rewardSelected);
      }
      dataParams.rewardSelectedList = newRewardSelectedList;
      dataActions.setDataRedux(DATA_REDUX_STATE_KEY.CMS_REWARD_CATALOG_PARAMS, dataParams);
    },
    onChangeRewardUnit: (data, stockType, value) => {
      const {
        dataList,
      } = this.props;
      const tempDataList = dataList.map(item => {
        let e = item[stockType];
        if (e) {
          if (e.uniqeId === data.uniqeId) {
            e.unitValue += value;
            if (e.unitValue <= 0) {
              e.unitValue = 0;
            }
            if (e.stockType === ENUM.REWARD_STOCK_TYPE.IN_STOCK && e.unitValue > e.inStock) {
              e.unitValue = e.inStock;
            }
            this.rewardCatalogList.updateRewardSelectedList(e)
          }
          item[stockType] = _.cloneDeep(e);
        }
        return item;
      })
      this.rewardCatalogList.updateData(tempDataList);
      this.setState({
        isErrorUsedPoint: false,
      })
    },
    // onChangeRewardUnit: (data, value) => {
    //   const {
    //     dataList,
    //   } = this.props;
    //   const tempDataList = dataList.map(e => {
    //     if (e.uniqeId === data.uniqeId) {
    //       e.unitValue += value;
    //       if (e.unitValue <= 0) {
    //         e.unitValue = 0;
    //       }
    //       if (e.stockType === ENUM.REWARD_STOCK_TYPE.IN_STOCK && e.unitValue > e.inStock) {
    //         e.unitValue = e.inStock;
    //       }
    //       this.rewardCatalogList.updateRewardSelectedList(e)
    //     }
    //     return e;
    //   })
    //   this.rewardCatalogList.updateData(tempDataList);
    // }
    onClickViewImageModal: (data) => {
      this.setState({
        isOpenViewImageModal: true,
        imagePreview: _.get(data, 'galleryList.0.pathRef')
      });
    },
    onCloseViewImageModal: () => {
      this.setState({
        isOpenViewImageModal: false,
      })
    }
  }

  input = {
    onChangeOrderBy: (option) => {
      const {
        dataList,
      } = this.props;
      const tempDataList = _.orderBy(dataList, [option.value]);
      this.rewardCatalogList.updateData(tempDataList);
      this.setState({
        orderByOptionSelected: option
      });
    }
  }

  pagination = {
    onPaginationChange: (pageNumber) => {
      getCMSRewardCatalogList({
        page: pageNumber,
      });
    }
  }

  render() {
    const {
      dataList,
      dataParams,
      totalData,
      activePage,
      goBack,
      customerData,
    } = this.props;
    const {
      dataPerPage,
      orderByOptionSelected,
      orderByOptionList,
      isOpenViewImageModal,
      imagePreview,
      isErrorUsedPoint,
    } = this.state;
    console.log('dataList', dataList)
    return (
      <React.Fragment>
        <ShapeContainer
          ui='content'
          justify='space-between'
        >
          {/* Old Template */}
          {/* {false && <Section spacingBottom='60'>
            <Section spacingBottom='30'>
              <Table
                striped
                listOfRewards
              >
                <Table.Section>
                  <Table.Row>
                    <Table.HeadColumn
                      noSort
                      maxWidth={VARIABLES.TABLE.INFO_WIDTHS.CHECKBOX_CIRCLE}
                      minWidth={VARIABLES.TABLE.INFO_WIDTHS.CHECKBOX_CIRCLE}
                    >
                      &nbsp;
                    </Table.HeadColumn>
                    <Table.HeadColumn
                      maxWidth={VARIABLES.TABLE.INFO_WIDTHS.ID}
                      minWidth={VARIABLES.TABLE.INFO_WIDTHS.ID}
                      sortAscending={getSortAscending(dataParams, 'id')}
                      onClickSort={() => {
                        this.rewardCatalogList.onClickSort('id desc');
                      }}
                    >
                      {getLanguage('redemptionReward.table.header.id', '')}
                    </Table.HeadColumn>
                    <Table.HeadColumn
                      minWidth={VARIABLES.TABLE.INFO_WIDTHS.REWARDS}
                      sortAscending={getSortAscending(dataParams, 'name')}
                      onClickSort={() => {
                        this.rewardCatalogList.onClickSort('name desc');
                      }}
                    >
                      {getLanguage('redemptionReward.table.header.rewards', '')}
                    </Table.HeadColumn>
                    <Table.HeadColumn
                      alignRight
                      minWidth={VARIABLES.TABLE.INFO_WIDTHS.IN_STOCK}
                      sortAscending={getSortAscending(dataParams, 'id')}
                      onClickSort={() => {
                        this.rewardCatalogList.onClickSort('id desc');
                      }}
                    >
                      {getLanguage('redemptionReward.table.header.inStock', '')}
                    </Table.HeadColumn>
                    <Table.HeadColumn
                      alignRight
                      minWidth={VARIABLES.TABLE.INFO_WIDTHS.REWARD_POINT}
                      sortAscending={getSortAscending(dataParams, 'points')}
                      onClickSort={() => {
                        this.rewardCatalogList.onClickSort('points desc');
                      }}
                    >
                      {getLanguage('redemptionReward.table.header.rewardPoint', '')}
                    </Table.HeadColumn>
                    <Table.HeadColumn
                      noSort
                      alignCenter
                      minWidth={VARIABLES.TABLE.INFO_WIDTHS.INPUT_UNIT}
                    >
                      {getLanguage('redemptionReward.table.header.unit', '')}
                    </Table.HeadColumn>
                    <Table.HeadColumn
                      alignRight
                      maxWidth={VARIABLES.TABLE.INFO_WIDTHS.USED_THE_TRUEBLUE_POINTS}
                      minWidth={VARIABLES.TABLE.INFO_WIDTHS.USED_THE_TRUEBLUE_POINTS}
                      noSort
                    >
                      {getLanguage('redemptionReward.table.header.usedPoints', '')}
                    </Table.HeadColumn>
                  </Table.Row>
                </Table.Section>
                <Table.Section>
                  {
                    dataList.map((e, i) => {
                      return (
                        <Table.Row
                          key={i}
                        >
                          <Table.BodyColumn
                            info
                            maxWidth={VARIABLES.TABLE.INFO_WIDTHS.CHECKBOX_CIRCLE}
                            minWidth={VARIABLES.TABLE.INFO_WIDTHS.CHECKBOX_CIRCLE}
                          >
                            <Image ui={e.unitValue > 0 ? 'iconSelectedActive' : 'iconSelectedInactive'} />
                          </Table.BodyColumn>
                          <Table.BodyColumn
                            info
                            maxWidth={VARIABLES.TABLE.INFO_WIDTHS.ID}
                            minWidth={VARIABLES.TABLE.INFO_WIDTHS.ID}
                            title={e.idWithPrefix}
                          >
                            {e.idWithPrefix}
                          </Table.BodyColumn>
                          <Table.BodyColumn
                            info
                            minWidth={VARIABLES.TABLE.INFO_WIDTHS.REWARDS}
                            title={e.name}
                          >
                            {e.name}
                          </Table.BodyColumn>
                          <Table.BodyColumn
                            info
                            alignRight
                            minWidth={VARIABLES.TABLE.INFO_WIDTHS.IN_STOCK}
                            title={e.displayStock}
                          >
                            {e.displayStock}
                          </Table.BodyColumn>
                          <Table.BodyColumn
                            info
                            alignRight
                            minWidth={VARIABLES.TABLE.INFO_WIDTHS.REWARD_POINT}
                            title={e.pointsWithComma}
                          >
                            {e.pointsWithComma}
                          </Table.BodyColumn>
                          <Table.BodyColumn
                            action
                            alignCenter
                            minWidth={VARIABLES.TABLE.INFO_WIDTHS.INPUT_UNIT}
                          >
                            <Field
                              ui='unit'
                              value={e.unitValue}
                              onClickUnitMinus={() => {
                                this.rewardCatalogList.onChangeRewardUnit(e, -1);
                              }}
                              onClickUnitPlus={() => {
                                this.rewardCatalogList.onChangeRewardUnit(e, 1);
                              }}
                              onChange={() => { }}
                            />
                          </Table.BodyColumn>
                          <Table.BodyColumn
                            info
                            infoActive={true}
                            alignRight
                            maxWidth={VARIABLES.TABLE.INFO_WIDTHS.USED_THE_TRUEBLUE_POINTS}
                            minWidth={VARIABLES.TABLE.INFO_WIDTHS.USED_THE_TRUEBLUE_POINTS}
                            title={e.unitValue * e.points}
                          >
                            {e.unitValue * e.points}
                          </Table.BodyColumn>
                        </Table.Row>
                      )
                    })
                  }
                </Table.Section>
              </Table>
            </Section>
            <Section>
              <ShapeContainer
                fluid
                paddingTop='10'
                paddingBottom='6'
                paddingHorizontal='30'
                bgColor={VARIABLES.COLORS.BLUE_14}
                borderRadius='5'
              >
                <Grid
                  gutter='45'
                  justify='center'
                >
                  <Grid.Column>
                    <Section paddingTop='5'>
                      <Grid gutter='30'>
                        <Grid.Column>
                          <Text
                            bold14
                            color={VARIABLES.COLORS.PRIMARY_2}
                          >
                            {getLanguage('redemptionReward.table.footer.usedPoints', '')}
                          </Text>
                        </Grid.Column>
                        <Grid.Column>
                          <Text
                            bold14
                            color={VARIABLES.COLORS.PRIMARY_2}
                          >
                            {displayWithComma(dataParams.rewardSelectedList.map(e => e.totalPoints).sum())}
                          </Text>
                        </Grid.Column>
                        <Grid.Column>
                          <Text
                            bold14
                            color={VARIABLES.COLORS.PRIMARY_2}
                          >
                            {getLanguage('redemptionReward.table.footer.point', '')}
                          </Text>
                        </Grid.Column>
                      </Grid>
                    </Section>
                  </Grid.Column>
                  <Grid.Column>
                    <ShapeContainer
                      width='1'
                      height='30'
                      bgColor={VARIABLES.COLORS.PRIMARY_1}
                    />
                  </Grid.Column>
                  <Grid.Column>
                    <Section paddingTop='5'>
                      <Grid gutter='30'>
                        <Grid.Column>
                          <Text
                            bold14
                            color={VARIABLES.COLORS.PRIMARY_2}
                          >
                            {getLanguage('redemptionReward.table.footer.yourTotalPoints', '')}
                          </Text>
                        </Grid.Column>
                        <Grid.Column>
                          <Text
                            bold14
                            color={VARIABLES.COLORS.PRIMARY_2}
                          >
                            {displayWithComma(customerData.remainPoints)}
                          </Text>
                        </Grid.Column>
                        <Grid.Column>
                          <Text
                            bold14
                            color={VARIABLES.COLORS.PRIMARY_2}
                          >
                            {getLanguage('redemptionReward.table.footer.point', '')}
                          </Text>
                        </Grid.Column>
                      </Grid>
                    </Section>
                  </Grid.Column>
                </Grid>
              </ShapeContainer>
            </Section>
          </Section>} */}

          {/* New Template */}
          <Section spacingBottom='60'>
            <Section spacingBottom='30'>
              <Grid gutter='15' justify='space-between' align='center'>
                <Grid.Column>
                  <ShapeContainer paddingTop='11' paddingBottom='9' paddingHorizontal='15' bgColor={VARIABLES.COLORS.PRIMARY_1} borderRadius='5'>
                    <Grid gutter='15' justify='center' align='center'>
                      <Grid.Column><Text regular14 color={VARIABLES.COLORS.WHITE}>{getLanguage('redemptionReward.informations.yourTotalTrueBluePoints', '')}</Text></Grid.Column>
                      <Grid.Column>
                        <ShapeContainer align='center' minWidth='64' paddingTop='4' paddingBottom='2' paddingHorizontal='10' bgColor={VARIABLES.COLORS.PRIMARY_2} borderRadius='12'>
                          <Text medium14 color={VARIABLES.COLORS.WHITE}>{displayWithComma(customerData.remainPoints)}</Text>
                        </ShapeContainer>
                      </Grid.Column>
                      <Grid.Column><Text regular14 color={VARIABLES.COLORS.WHITE}>{getLanguage('redemptionReward.informations.points', '')}</Text></Grid.Column>
                      <Grid.Column><Text regular14 color={VARIABLES.COLORS.WHITE}>{getLanguage('redemptionReward.informations.usedtheTrueBluePoints', '')}</Text></Grid.Column>
                      <Grid.Column>
                        <ShapeContainer align='center' minWidth='64' paddingTop='4' paddingBottom='2' paddingHorizontal='10' bgColor={isErrorUsedPoint ? VARIABLES.COLORS.RED_4 : VARIABLES.COLORS.PRIMARY_2} borderRadius='12'>
                          <Text medium14 color={VARIABLES.COLORS.WHITE}>{displayWithComma(this.rewardCatalogList.getTotalUsedPoint())}</Text>
                        </ShapeContainer>
                      </Grid.Column>
                      <Grid.Column><Text regular14 color={VARIABLES.COLORS.WHITE}>{getLanguage('redemptionReward.informations.points', '')}</Text></Grid.Column>
                    </Grid>
                  </ShapeContainer>
                </Grid.Column>
                <Grid.Column>
                  <SelectControl
                    isSearchable={false}
                    label={getLanguage('redemptionReward.informations.orderBy', '')}
                    labelSpacingTop='7'
                    labelSpacingRight='10'
                    placeholder={getLanguage('redemptionReward.informations.selectOrderBy', '')}
                    width='200'
                    // name={}
                    value={orderByOptionSelected}
                    onChange={this.input.onChangeOrderBy}
                    options={orderByOptionList}
                  />
                </Grid.Column>
              </Grid>
            </Section>
            <Grid gutter='30' gutterVertical='30'>
              {dataList.map((e, i) => {
                return (
                  <Grid.Column grid='3'>
                    <RewardRedemptionCard
                      isQuantity={!!e.dataInStock}
                      isOnDemand={!!e.dataOnDemand}
                      src={_.get(e, 'galleryList.0.pathRef')}
                      point={e.pointsWithComma}
                      itemName={e.name}
                      quantity={_.get(e, 'dataInStock.inStockWithComma')}
                      isDisabledMinusQuantity={_.get(e, 'dataInStock.unitValue') === 0}
                      isDisabledPlusQuantity={_.get(e, 'dataInStock.unitValue') === _.get(e, 'dataInStock.inStock')}
                      isDisabledMinusOnDemand={_.get(e, 'dataOnDemand.unitValue') === 0}
                      // isDisabledPlusOnDemand={}
                      onClickPreviewImage={() => {
                        this.rewardCatalogList.onClickViewImageModal(e);
                      }}
                      // quantityId={''}
                      // quantityName={''}
                      onClickMinusQuantity={() => {
                        this.rewardCatalogList.onChangeRewardUnit(_.get(e, 'dataInStock'), 'dataInStock', -1);
                      }}
                      onClickAddQuantity={() => {
                        this.rewardCatalogList.onChangeRewardUnit(_.get(e, 'dataInStock'), 'dataInStock', 1);
                      }}
                      quantityValue={_.get(e, 'dataInStock.unitValue')}
                      // quantityOnChange={() => { }}
                      // onDemandId={''}
                      // onDemandName={''}
                      onDemandValue={_.get(e, 'dataOnDemand.unitValue')}
                      // onDemandOnChange={() => { }}
                      onClickMinusOnDemand={() => {
                        this.rewardCatalogList.onChangeRewardUnit(_.get(e, 'dataOnDemand'), 'dataOnDemand', -1);
                      }}
                      onClickAddOnDemand={() => {
                        this.rewardCatalogList.onChangeRewardUnit(_.get(e, 'dataOnDemand'), 'dataOnDemand', 1);
                      }}
                    />
                  </Grid.Column>
                )
              })}
            </Grid>
          </Section>


          <TableAction>
            <TableAction.Left>
              {/* <Grid gutter='20'>
                <Grid.Column>
                  <Section paddingTop='6'>
                    <Text
                      regular12
                      color={VARIABLES.COLORS.GRAY_16}
                    >
                      {getLanguage('pagination.page', '')} {activePage}/{Math.ceil(totalData / dataPerPage)}
                    </Text>
                  </Section>
                </Grid.Column>
                <Grid.Column>
                  <PaginationControl
                    activePage={activePage}
                    itemsCountPerPage={dataPerPage}
                    totalItemsCount={totalData}
                    pageRangeDisplayed={3}
                    onChange={this.pagination.onPaginationChange}
                  />
                </Grid.Column>
              </Grid>
            */}
            </TableAction.Left>
            <TableAction.Right>
              <Grid gutter='15'>
                <Grid.Column>
                  <Button
                    gray
                    ui='back'
                    name={getLanguage('field.buttonBack', '')}
                    width={VARIABLES.BUTTON.WIDTHS.W_155}
                    onClick={goBack}
                  />
                </Grid.Column>
                <Grid.Column>
                  <Button
                    blue
                    name={getLanguage('field.buttonSave', '')}
                    width={VARIABLES.BUTTON.WIDTHS.W_155}
                    onClick={this.rewardCatalogList.onClickSave}
                  />
                </Grid.Column>
              </Grid>
            </TableAction.Right>
          </TableAction>
        </ShapeContainer>

        {isOpenViewImageModal &&
          <Lightbox isImage onClickClose={this.rewardCatalogList.onCloseViewImageModal}>
            <img src={imagePreview} alt='Icon' />
          </Lightbox>
        }
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => {
  const dataRedux = state.dataRedux;
  let dataList = dataRedux.cmsRewardCatalogList;
  const dataGroup = {};
  dataList.forEach(e => {
    if (dataGroup[e.mapId__c] === undefined) {
      dataGroup[e.mapId__c] = { ...e };
    }
    if (e.stockType === ENUM.REWARD_STOCK_TYPE.IN_STOCK) {
      if (dataGroup[e.mapId__c].dataInStock === undefined) {
        dataGroup[e.mapId__c].dataInStock = e;
        dataGroup[e.mapId__c].name = e.name;
      }
    }
    else {
      if (dataGroup[e.mapId__c].dataOnDemand === undefined) {
        dataGroup[e.mapId__c].dataOnDemand = e;
      }
    }
  });
  dataList = Object.keys(dataGroup).map(key => dataGroup[key]);
  return {
    dataList,
    dataParams: dataRedux.cmsRewardCatalogParams,
    activePage: dataRedux.cmsRewardCatalogParams.activePage,
    totalData: dataRedux.cmsRewardCatalogParams.totalData || 0,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    dataActions: bindActionCreators(dataCreators, dispatch),
  }
}

export const RewardCatalogListContainerConnected = connect(
  mapStateToProps,
  mapDispatchToProps
)(RewardCatalogListContainer)