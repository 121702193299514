import _ from 'lodash';
import { DATA_REDUX_STATE_KEY, dataCreators } from "../../actions/dataAction";
import { warrantyController } from "../../apiService/apiController/warrantyService";
import { ModelWarrantyDocumentary } from '../../models/ModelDocumentary';
import { ModelWarranty, ModelWarrantyConditionTemplateSearch, ModelWarrantyEnvironmentAutomatic, ModelWarrantyEnvironmentDetail, ModelWarrantyQRDetail, WarrantyApplication, WarrantyEnvironment } from "../../models/ModelWarranty";
import { ModelWarrantyYear, ModelWarrantyYearDetail } from "../../models/ModelWarrantyYear";
import { storeDispatch, storeGetState } from "../../stores";
import { DATA, DATE_MOMENT_FORMAT } from "../constants/data";
import { ENUM } from "../constants/enum";
import { getUserAuthRedux } from "../functions/auth";
import { getCountryText, getCountryType, handleSortParams, handleTrimParams } from "../functions/data";
import { displayDate, displayUrlPath } from "../functions/display";
import { isValidResponse } from "../functions/validation";
import { getModelWarranty, isValidTemplateExtendYear, isValidTemplateSegmentSpecial } from '../functions/warranty';

export const getWarranty = async (params = {}) => {
  let newParams = handleTrimParams(params);
  const warrantyService = warrantyController();
  const res = await warrantyService.searchWarranty(newParams);
  if (isValidResponse(res)) {
    let data = res.data.map(e => new ModelWarranty(e))[0];
    console.log('getWarranty', res.data, data, newParams);
    return data;
  }
}

export const getWarrantyDetail = async (warrantyData = {}, configService = {}) => {
  let newParams = handleTrimParams({
    id: warrantyData.id
  });
  const warrantyService = warrantyController({
    params: {
      country: getCountryText(warrantyData.projectCountry)
    },
    ...configService,
  });
  const res = await warrantyService.getWarrantyDetail(newParams);
  if (isValidResponse(res)) {
    warrantyData.detailList = res.data || [];
    warrantyData = getModelWarranty(warrantyData);
    console.log('getWarrantyDetail', res.data, warrantyData, newParams);
  }
  return warrantyData
}

export const getWarrantyDetailList = async (warrantyList = []) => {
  let warrantyDetailList = []
  const configService = {
    isShowLoading: false
  }
  for (let i = 0; i < warrantyList.length; i++) {
    const warrantyData = await getWarrantyDetail(warrantyList[i], configService);
    warrantyDetailList.push(warrantyData)
  }
  return warrantyDetailList
}

// export const getWarrantyTemplateDetail = async (configService = {}) => {
//   const warrantyService = warrantyController(configService);
//   const res = await warrantyService.warrantyTemplateDetail();
//   if (isValidResponse(res)) {
//     let data = new ModelWarrantyTemplateDetail(res.data);
//     return data;
//   }
// }

// export const getWarrantyTemplateDetail = async (configService = {}) => {
//   const warrantyService = warrantyController(configService);
//   const res = await warrantyService.warrantyTemplateDetail();
//   if (isValidResponse(res)) {
//     let data = new ModelWarrantyTemplateDetail(res.data);
//     return data;
//   }
// }

export const getWarrantyAutoCompleteList = async (params = {}) => {
  let userAuth = getUserAuthRedux();
  let newParams = handleTrimParams({
    ...params,
    sort: 'wrt_id desc',
    auth_deal_id: userAuth.id,
    country: userAuth.country,
  });
  const configService = {
    isShowLoading: false,
  }
  const warrantyService = warrantyController(configService);
  const res = await warrantyService.searchWarranty(newParams);
  if (isValidResponse(res)) {
    let dataList = res.data.map(e => new ModelWarranty(e));
    console.log('getWarrantyAutoCompleteList', res.data, dataList, newParams);
    return _.cloneDeep(dataList);
  }
  else {
    return [];
  }
}

export const getWarrantyList = async (params = {}, configService = {}) => {
  let userAuth = getUserAuthRedux();
  const dataOptionParams = storeGetState().dataRedux.warrantyOptionParams;
  let currentParams = storeGetState().dataRedux.warrantyParams;
  let newParams = handleTrimParams({
    ...currentParams,
    ...params,
    auth_deal_id: userAuth.id,
    country: userAuth.country,
  });
  if (!newParams.sort) {
    newParams.sort = 'wrt_id desc';
  }
  else {
    newParams = handleSortParams(currentParams, newParams);
  }

  // newParams.page = 4;

  const warrantyService = warrantyController(configService);
  const res = await warrantyService.searchWarranty(newParams);
  if (isValidResponse(res)) {
    let dataList = res.data.map(e => new ModelWarranty(e));
    newParams.activePage = newParams.page;
    newParams.totalData = res.total_items;
    if (dataOptionParams.selectedDataList.length) {
      dataList = dataList.map(e => {
        dataOptionParams.selectedDataList.forEach(es => {
          if (es.id === e.id) {
            e.isSelected = es.isSelected;
          }
        })
        return e
      })
    }
    console.log('getWarrantyList', res.data, dataList, newParams, dataOptionParams);
    storeDispatch(dataCreators.setDataRedux(DATA_REDUX_STATE_KEY.WARRANTY_LIST, dataList));
    storeDispatch(dataCreators.setDataRedux(DATA_REDUX_STATE_KEY.WARRANTY_PARAMS, newParams));
    return dataList;
  }
  return [];
}

export const getWarrantySelGroupList = async (params = {}) => {
  let userAuth = getUserAuthRedux();
  let newParams = handleTrimParams({
    ...getClearWarrantyParams(),
    ...params,
    auth_deal_id: userAuth.id,
    country: userAuth.country,
    page: 1,
    limit: 0,
  });

  const warrantyService = warrantyController();
  const res = await warrantyService.searchWarranty(newParams);
  if (isValidResponse(res)) {
    let dataList = res.data.map(e => new ModelWarranty(e));
    console.log('getWarrantySelGroupList', res.data, dataList, newParams);
    return dataList;
  }
}

export const getPublicWarrantySelGroupList = async (params = {}) => {
  let newParams = handleTrimParams({
    ...params,
  });
  const warrantyService = warrantyController();
  const res = await warrantyService.warrantyQRDetail(newParams);
  if (isValidResponse(res)) {
    let dataList = res.data.map(e => new ModelWarranty(e));
    console.log('getWarrantySelGroupList', res.data, dataList, newParams);
    return dataList;
  }
  return [];
}

export const getClearWarrantyParams = () => {
  return {
    pre_sales_trans_id: '',
    order_no: '',
    customer_name: '',
    str_date: '',
    end_date: '',
    project_name: '',
    wrt_no: '',
    phone_no: '',
    sort: '',
    wrt_id: '',
    product_group_name: '',
  }
}

export const getWarrantyMultipleParams = (data) => {
  let userAuth = getUserAuthRedux();
  let params = {
    sales_t1_id: data.id,
    detail: [{
      customer_id: data.customerId,
      wrt_quantity: data.wrtQuantity
    },],
    sales_admin: userAuth.sfid,
    created_by: userAuth.name,

    project_phone: data.phoneNoCountry.value + data.phoneNo,
    project_county: getCountryType(userAuth.country)
  }
  return params;
}

export const getWarrantyTransManageParams = (data) => {
  let userAuth = storeGetState().mainRedux.userAuth;
  let contactSubDistrict = data.customerData.contactSubDistrict;
  let contactPostCode = data.customerData.contactPostCode;
  // TODO: laos
  if (data.customerData.contactAddressType === ENUM.COUNTRY_TYPE.THAI) {
    contactSubDistrict = (data.customerData.contactSubDistrict || {}).value || '';
    contactPostCode = (data.customerData.contactPostCode || {}).value || '';
  }


  let params = {
    // project_name: data.customerData.fullname,
    project_name: '',
    project_addr: data.customerData.contactAddress || '',
    project_phone: data.customerData.phoneCountry.value + data.customerData.phone,
    project_sub_dist: contactSubDistrict || '',
    project_dist: data.customerData.contactDistrict || '',
    project_prov: data.customerData.contactAddressType === ENUM.COUNTRY_TYPE.LAOS ?
      data.customerData.contactSelectedProvince
      :
      (data.customerData.contactSelectedProvince || {}).label || '',
    project_city: data.customerData.contactAddressType === ENUM.COUNTRY_TYPE.LAOS ?
      data.customerData.contactSelectedCity
      :
      (data.customerData.contactSelectedCity || {}).label || '',
    project_post_code: (contactPostCode || '').length ? parseInt(contactPostCode) : 0,
    project_alley: data.customerData.contactAlley || '',
    project_group: data.customerData.contactGroup || '',
    project_street: data.customerData.contactStreet || '',
    project_ward: data.customerData.contactWard || '',
    project_county: data.customerData.contactAddressType ? data.customerData.contactAddressType : getCountryType(userAuth.country),
    wrt_cat: DATA.WARRANTY_CATEGORY_TYPE.RESIDENTIAL.TYPE,
    wrt_length: data.wrtLength,
    wrt_status: DATA.WARRANTY_TYPE.DRAFT.TYPE,
    auth_deal_id: userAuth.id,
    product: data.productList,
    condition: {},
    created_by: userAuth.name,
    user_id: data.userRefId,
    customer_id: data.customerId,
    sales_admin: userAuth.sfid,
    wrt_sel_grp: null,
    wrt_sel_template_grp: null,
    industrial_estate_name: null,
    distance: null,
    distance_env: null,
  }

  if (data.preTransData) {
    params.project_name = data.preTransData.data.preSalesTransProjectName || '';
    params.project_addr = data.preTransData.data.preSalesTransProjectAddr || '';
    params.project_prov = data.preTransData.data.preSalesTransProv || '';
    params.project_city = data.preTransData.data.preSalesTransDist || '';
    params.project_sub_dist = data.preTransData.data.preSalesTransSubDist || '';
    params.project_post_code = data.preTransData.data.preSalesTransPostCode || '';
    params.segment_id = (data.preTransData.segmentSelected || {}).segmentId;
    params.product = params.product.map(e => {
      e.sub_segment_id = (data.preTransData.segmentSelected || {}).subSegmentId;
      return e;
    })
  }
  return params;
}

export const getWarrantyManageParams = (data, dataId) => {
  let userAuth = storeGetState().mainRedux.userAuth;
  let projectSubDist = data.projectSubDist;
  let projectPostCode = data.projectPostCode;
  // TODO: laos
  if (data.projectCountry === ENUM.COUNTRY_TYPE.THAI) {
    projectSubDist = (projectSubDist || {}).label || '';
    projectPostCode = (projectPostCode || {}).label || '';
  }
  // CR: 1497
  let wrtStatus = data.wrtStatus;
  let wrtNo = data.wrtNo;
  // CR: 1498
  let wrtComment = data.wrtComment;

  let params = {
    id: dataId,
    wrt_id: dataId,
    project_name: data.projectName,
    project_addr: data.projectAddr,
    project_phone: data.projectPhoneCountry.value + data.projectPhone,
    project_sub_dist: projectSubDist || '',
    project_dist: data.projectDist || '',
    project_prov: data.projectProv,
    project_city: data.projectCity,
    project_post_code: projectPostCode || 0,
    project_alley: data.projectAlley || '',
    project_group: data.projectGroup || '',
    project_street: data.projectStreet || '',
    project_ward: data.projectWard || '',
    project_ins_date: displayDate(data.projectInsDateTime, '-', DATE_MOMENT_FORMAT),
    project_county: data.projectCountry ? data.projectCountry : getCountryType(userAuth.country),
    wrt_cat: data.wrtCat,
    wrt_step: data.wrtStep,
    wrt_sel_grp: data.wrtSelGrp,
    wrt_sel_template_grp: data.wrtSelTemplateGrp,
    // wrt_path: "",
    user_id: data.userId,
    customer_id: data.customerId,
    auth_deal_id: userAuth.id,
    segment_id: (data.segmentSelected || {}).segmentId,
    product: data.detailList.map(e => {
      let envSelect = {
        environment: '',
        special: false,
      };
      const defaultEnvDetail = {
        corrosion: 0,
        peelFlake: 0,
        colorFade: 0,
        dirtStain: 0,
      };
      let envDetail = _.cloneDeep(defaultEnvDetail);
      let envDetailSelect = _.cloneDeep(defaultEnvDetail);
      let envDetailSelectMax = _.cloneDeep(defaultEnvDetail);
      let applicationList = [];

      let isSplitRoofAndWall = (
        e.isRoofAndWall &&
        data.projectCountry === ENUM.COUNTRY_TYPE.INDONESIA
      );

      let isFindEnvDetail = true;
      e.templateList.forEach(et => {
        if (isSplitRoofAndWall === false && isFindEnvDetail && e[et.applicationForSelect] && e.isWarrantyPeriodSelected) {
          envDetail = et.envDetail;
          isFindEnvDetail = false;
        }
      });


      e.templateList.forEach((et, it) => {
        if (e[et.applicationForSelect]) {
          if (isSplitRoofAndWall) {
            if (et.isWarrantyPeriodAppSelect) {
              envDetail = et.envDetail;
            }
            else {
              envDetail = _.cloneDeep(defaultEnvDetail);
            }
          }
          envDetailSelect = et.envDetailSelect;
          envDetailSelectMax = et.envDetailSelectMax;
          console.log('getWarrantyManageParams envDetail', envDetail, envDetailSelect, envDetailSelectMax, et.envList[et.detailEnvSelect]);
          envSelect = et.envList[et.detailEnvSelect ? et.detailEnvSelect : 0];
          // CR: warranty condition
          // const applicationCondition = et.condition;
          let applicationValue = 0;
          switch (et.applicationForSelect) {
            case DATA.WARRANTY_APP_DETAIL_NAME_SELECT_LIST[0]:
              applicationValue = e.roof ? parseFloat(e.roof) : 0;
              break;
            case DATA.WARRANTY_APP_DETAIL_NAME_SELECT_LIST[1]:
              applicationValue = e.wall ? parseFloat(e.wall) : 0;
              break;
            case DATA.WARRANTY_APP_DETAIL_NAME_SELECT_LIST[2]:
              applicationValue = e.purlinGirt ? parseFloat(e.purlinGirt) : 0;
              break;
            case DATA.WARRANTY_APP_DETAIL_NAME_SELECT_LIST[3]:
              applicationValue = e.truFrame ? parseFloat(e.truFrame) : 0;
              break;
            case DATA.WARRANTY_APP_DETAIL_NAME_SELECT_LIST[4]:
              applicationValue = e.coolRoom ? parseFloat(e.coolRoom) : 0;
              break;
            case DATA.WARRANTY_APP_DETAIL_NAME_SELECT_LIST[5]:
              applicationValue = e.decking ? parseFloat(e.decking) : 0;
              break;
            case DATA.WARRANTY_APP_DETAIL_NAME_SELECT_LIST[6]:
              applicationValue = e.airDucting ? parseFloat(e.airDucting) : 0;
              break;
          }

          const handleEnvDetailParams = (target = '') => {
            let result = 0;
            if (
              e.isWarrantyPeriodSelected &&
              envDetailSelectMax[target] > 0 &&
              envDetail[target] !== ''
            ) {
              result = envDetail[target];
            }
            return result;
          }

          applicationList.push({
            name: et.application,
            cor_periods: handleEnvDetailParams('corrosion'),
            peel_flake_periods: handleEnvDetailParams('peelFlake'),
            col_fade_periods: handleEnvDetailParams('colorFade'),
            dir_stain_periods: handleEnvDetailParams('dirtStain'),
            term_con_accet: !!et.isAcceptTncTemplate,
            environment: envSelect.environment,
            special: envSelect.special,
            value: applicationValue,
            sub_segment_id: (data.segmentSelected || {}).subSegmentId,
            link_path: et.imageSelectPath,
            // condition: {
            //   wrt_term_con_1: _.get(applicationCondition, 'wrtTermCon1', '').toString(),
            //   wrt_term_con_2: _.get(applicationCondition, 'wrtTermCon2', '').toString(),
            //   wrt_term_con_3: _.get(applicationCondition, 'wrtTermCon3', '').toString(),
            //   wrt_term_con_4: _.get(applicationCondition, 'wrtTermCon4', '').toString(),
            //   wrt_term_con_5: _.get(applicationCondition, 'wrtTermCon5', '').toString(),
            //   wrt_term_con_6: _.get(applicationCondition, 'wrtTermCon6', '').toString(),
            //   wrt_term_con_7: _.get(applicationCondition, 'wrtTermCon7', '').toString(),
            //   wrt_term_con_8: _.get(applicationCondition, 'wrtTermCon8', '').toString(),
            //   wrt_term_con_9: _.get(applicationCondition, 'wrtTermCon9', '').toString(),
            //   wrt_term_con_10: _.get(applicationCondition, 'wrtTermCon10', '').toString(),
            //   wrt_term_con_11: _.get(applicationCondition, 'wrtTermCon11', '').toString(),
            //   wrt_term_con_12: _.get(applicationCondition, 'wrtTermCon12', '').toString(),
            //   wrt_term_con_13: _.get(applicationCondition, 'wrtTermCon13', '').toString(),
            //   wrt_term_con_14: _.get(applicationCondition, 'wrtTermCon14', '').toString(),
            //   wrt_term_con_15: _.get(applicationCondition, 'wrtTermCon15', '').toString(),
            //   wrt_term_con_16: _.get(applicationCondition, 'wrtTermCon16', '').toString(),
            //   wrt_term_con_17: _.get(applicationCondition, 'wrtTermCon17', '').toString(),
            //   wrt_term_con_17_1: _.get(applicationCondition, 'wrtTermCon17_1', '').toString()
            // }
            condition: {},
            // CR: warranty condition
            wrt_question_template_answer: JSON.stringify(et.condition),
          })


          // CR: 1498
          if (wrtStatus !== DATA.WARRANTY_TYPE.SAVE_DRAFT.TYPE) {
            if (!isValidTemplateExtendYear(data, e)) {
              wrtStatus = DATA.WARRANTY_TYPE.SUBMITTED.TYPE;
              wrtComment = DATA.WARRANTY_ENV_TYPE.NO_OFFER_TEXT
              wrtNo = null
            }
          }
        }
      });

      // CR: 1497
      if (wrtStatus !== DATA.WARRANTY_TYPE.SAVE_DRAFT.TYPE) {
        if (data.isSpecialSegment) {
          if (!isValidTemplateSegmentSpecial(data, {
            m3ProductGroup: e.m3ProductGroup
          })) {
            wrtStatus = DATA.WARRANTY_TYPE.REJECTED.TYPE;
            wrtNo = null
          }
        }
      }

      return {
        sales_tran_id: e.salesTranId,
        wrt_path: e.oldFileRef,
        // CR: 1493
        wrt_path_before_regen: e.wrtPathBeforeRegenList,
        old_wrt_no: data.oldWrtNo,
        old_wrt_path: displayUrlPath(e.oldWrtPath),
        is_regenerate: data.isCanRegenerate,

        roof_wall: e.roof ? parseFloat(e.roof) : '',
        roof: e.roof ? parseFloat(e.roof) : '',
        wall: e.wall ? parseFloat(e.wall) : '',
        purlin_girt: e.purlinGirt ? parseFloat(e.purlinGirt) : '',
        tru_frame: e.truFrame ? parseFloat(e.truFrame) : '',
        cool_room: e.coolRoom ? parseFloat(e.coolRoom) : '',
        decking: e.decking ? parseFloat(e.decking) : '',
        // TODO: RL-724: check field api
        air_ducting: e.airDucting ? parseFloat(e.airDucting) : '',
        special: envSelect.special,
        m3_product_group: e.m3ProductGroup,
        // CR: 1498
        product_group_name: e.oldProductGroupName,
        sub_segment_id: applicationList.length === 0 ? (data.segmentSelected || {}).subSegmentId : undefined,
        product_remark: e.applicationInfoList.map(ea => {
          return {
            building: ea.building,
            project_ins_date: displayDate(ea.projectInsDateTime, '-', DATE_MOMENT_FORMAT),
            remark: ea.customerRemark
          }
        }),
        application: applicationList,
      }
    }),
    condition: {
      wrt_criteria_1: data.condition.wrtCriteria1,
      wrt_criteria_2: data.condition.wrtCriteria2,
      wrt_criteria_3: data.condition.wrtCriteria3,
      wrt_criteria_4: data.condition.wrtCriteria4,
      wrt_criteria_5: data.condition.wrtCriteria5,
      wrt_sur_env_1: data.condition.wrtSurEnv1,
      wrt_sur_env_2: data.condition.wrtSurEnv2,
      wrt_sur_env_2_2: data.condition.wrtSurEnv22,
      wrt_sur_env_3: data.condition.wrtSurEnv3,
      wrt_sur_env_3_2: data.condition.wrtSurEnv32,
      wrt_sur_env_4: data.condition.wrtSurEnv4,
      wrt_sur_env_5: data.condition.wrtSurEnv5,
    },
    created_by: userAuth.name,
    updated_by: userAuth.name,
    sales_admin: userAuth.sfid,
    //bswm
    project_add_info: data.customerRemark,
    industrial_estate_name: data.selectedIndustrialEstate ? data.selectedIndustrialEstate.value : null,
    distance: data.selectedWarrantyDistance ? data.selectedWarrantyDistance.label : null,
    distance_env: data.selectedWarrantyDistance ? data.selectedWarrantyDistance.value : null,
  }
  //indo
  if (data.projectCountry === ENUM.COUNTRY_TYPE.INDONESIA) {
    params.permit_letter_number = data.permitNumber;
    params.building_type = data.buildingType;
    params.area_of_building = data.areaBuilding;
    params.toko_name = data.tokoName;
    params.builder_name = data.builderName;
    params.product_weight = data.productWeight;
    params.tct_thickness = data.tctThickness;
    params.color_name = data.colorName;
    params.application = data.applicationList.join(',');
    params.brand = data.brand;
  }
  //malaysia
  if (data.projectCountry === ENUM.COUNTRY_TYPE.MALAYSIA) {
    params.ad_rubber_stamp = data.adRubberStamp;
    params.original_receipt_invoice = data.originalReceiptInvoice;
    params.residential_commercial = data.residentialCommercial;
    params.email = data.projectEmail;
    params.type_of_product_purchased = data.typesOfProductPurchased;
    params.purpose_of_purchased_zacs = data.purposeOfPurchasedZacs;
    params.application = data.applicationList.join(',');
    params.purchased_date = displayDate(data.purchasedDateTime, '-', DATE_MOMENT_FORMAT);
    params.invoice_no = data.inoivceNo;
  }

  // CR: 1497
  params.wrt_status = wrtStatus;
  params.wrt_no = wrtNo;
  // CR: 1498
  params.wrt_comment = wrtComment;

  return params;
}

export const getWarrantyYearList = async () => {
  let userAuth = getUserAuthRedux();
  const configService = {
    urlParams: {
      country: userAuth.country,
    }
  }
  const warrantyService = warrantyController(configService);
  const res = await warrantyService.warrantyYear();
  if (isValidResponse(res)) {
    let dataList = res.data.map(e => new ModelWarrantyYear(e));
    console.log('getWarrantyYearList', res.data, dataList);
    return _.cloneDeep(dataList);
  }
  else {
    return [];
  }
}

export const getWarrantyYearDetail = async (params = {}, configService = {}) => {
  let userAuth = getUserAuthRedux();
  let newParams = handleTrimParams({
    ...params,
  });
  const newConfigService = {
    urlParams: {
      country: userAuth.country,
    }
  }
  const warrantyService = warrantyController({ ...newConfigService, ...configService });
  const res = await warrantyService.warrantyYearDetail(newParams);
  if (isValidResponse(res)) {
    let dataList = res.data.map(e => new ModelWarrantyYearDetail(e));
    console.log('getWarrantyYearDetail', res.data, dataList);
    return dataList;
  }
  else {
    return []
  }
}

export const getPublicWarrantyYearList = async () => {
  const configService = {
    urlParams: {
      country: 'Thailand',
    }
  }
  const warrantyService = warrantyController(configService);
  const res = await warrantyService.warrantyYear();
  if (isValidResponse(res)) {
    let dataList = res.data.map(e => new ModelWarrantyYear(e));
    console.log('getWarrantyYearList', res.data, dataList);
    return _.cloneDeep(dataList);
  }
  else {
    return [];
  }
}

export const getPublicWarrantyYearDetail = async (params = {}) => {
  let userAuth = getUserAuthRedux();
  let newParams = handleTrimParams({
    ...params,
  });
  const configService = {
    urlParams: {
      country: 'Thailand',
    }
  }
  const warrantyService = warrantyController(configService);
  const res = await warrantyService.warrantyYearDetail(newParams);
  if (isValidResponse(res)) {
    let dataList = res.data.map(e => new ModelWarrantyYearDetail(e));
    console.log('getWarrantyYearDetail', res.data, dataList);
    return dataList;
  }
  else {
    return []
  }
}

// export const getWarrantyTemplateDetailConfigService = (data, isGenerateResidential) => {
//   const configService = {
//     isShowLoading: false,
//     isShowError: false,
//     urlParams: {
//       m3_prod_grp_code: data.m3ProductGroup,
//     }
//   }

//   if (isGenerateResidential) {
//     configService.urlParams.product_grp_name = data.productGroupName;
//   }
//   else {
//     configService.urlParams.m3_prod_grp_name = data.productGroupName;
//   }

//   if (data.color) {
//     configService.urlParams.color = data.color;
//   }
//   return configService;
// }

export const getWarrantyEnvironmentDetailList = async (params = {}) => {
  let newParams = handleTrimParams({
    ...params,
  });
  if (!newParams.sort) {
    newParams.sort = 'id_sf';
  }
  const warrantyService = warrantyController();
  const res = await warrantyService.warrantyEnvironmentProductDetail(newParams);
  if (isValidResponse(res)) {
    const dataList = res.data.map(e => new ModelWarrantyEnvironmentDetail(e));
    console.log('getWarrantyEnvironmentDetailList', res.data, dataList, newParams);
    return dataList;
  }
  return [];
}

export const getWarrantyEnvironmentAutomatic = async (configService = {}) => {
  const warrantyService = warrantyController(configService);
  const res = await warrantyService.warrantyEnvironmentAutomatic();
  if (isValidResponse(res)) {
    let dataList = res.data.map(e => new ModelWarrantyEnvironmentAutomatic(e))
    dataList = dataList.map(e => {
      if (e.isNotIndustrial) {
        e.priorityEnv = _.orderBy(dataList, 'priority', 'desc')[0];
        if (e.priorityEnv) {
          e.warrantyDistanceSelectOptionList = DATA.WARRANTY_AUTOMATIC_ENV_DISTANCE[e.priorityEnv.code] || [];
          e.isShowWarrantyDistance = e.warrantyDistanceSelectOptionList.length > 0;
        }
      }
      return e
    });

    console.log('getWarrantyEnvironmentAutomatic', res.data, dataList);
    return dataList;
  }
  return [];
}

export const getWarrantyConditionTemplateSearchDetail = async (params, configService = {}) => {
  let newParams = handleTrimParams({
    ...params,
  });
  const warrantyService = warrantyController(configService);
  const res = await warrantyService.warrantyConditionTemplateSearchDetail(newParams);
  if (isValidResponse(res)) {
    let dataList = res.data.map(e => new ModelWarrantyConditionTemplateSearch(e))
    console.log('getWarrantyConditionTemplateSearchDetail', res.data, dataList);
    return dataList;
  }
  return [];
}

export const getWarrantyEnvironmentList = async (configService = {}) => {
  const warrantyService = warrantyController(configService);
  const res = await warrantyService.warrantyEnvironment();
  if (isValidResponse(res)) {
    const dataList = _.orderBy(res.data.map(e => new WarrantyEnvironment(e)), 'id', 'asc');
    console.log('getWarrantyEnvironmentList', res.data, dataList);
    return dataList
  }
  return [];
}

export const getWarrantyApplicationList = async (configService = {}) => {
  const warrantyService = warrantyController(configService);
  const res = await warrantyService.warrantyApplication();
  if (isValidResponse(res)) {
    const dataList = _.orderBy(res.data.map(e => new WarrantyApplication(e)), 'id', 'asc');
    console.log('getWarrantyApplicationList', res.data, dataList);
    return dataList
  }
  return [];
}


export const getWarrantyDocumentaryDetailList = async (params = {}, configService = {}) => {
  const warrantyService = warrantyController(configService);
  const res = await warrantyService.warrantyDocumentaryDetail(params);
  if (isValidResponse(res)) {
    let dataList = res.data.map(e => new ModelWarrantyDocumentary(e));
    console.log('getWarrantyDocumentaryDetailList', res.data, dataList);
    return dataList
  }
  return [];
}

export const getWarrantyQRCertificateDetail = async (params = {}) => {
  let newParams = handleTrimParams(params);
  const warrantyService = warrantyController();
  const res = await warrantyService.warrantyQRCertificateDetail(newParams);
  if (isValidResponse(res)) {
    let data = new ModelWarrantyQRDetail(res.data ?? {});
    console.log('getWarrantyQRCertificateDetail', res.data, data, newParams);
    return data;
  }
}

export const getWarrantyQRWarrantyDocumentaryDetail = async (params = {}) => {
  let newParams = handleTrimParams(params);
  const warrantyService = warrantyController();
  const res = await warrantyService.warrantyQRWarrantyDocumentaryDetail(newParams);
  if (isValidResponse(res)) {
    let data = new ModelWarrantyQRDetail(res.data ?? {});
    console.log('getWarrantyQRWarrantyDocumentaryDetail', res.data, data, newParams);
    return data;
  }
}

export const getWarrantyQRTemplateDetail = async (params = {}) => {
  let newParams = handleTrimParams(params);
  const warrantyService = warrantyController();
  const res = await warrantyService.warrantyQRTemplateDetail(newParams);
  if (isValidResponse(res)) {
    let data = new ModelWarrantyQRDetail(res.data ?? {});
    console.log('getWarrantyQRTemplateDetail', res.data, data, newParams);
    return data;
  }
}

export const sendMailToAdminApproved = async (params) => {
  const warrantyService = warrantyController();
  const res = await warrantyService.sendMailToAdminApproved(params);
  if (isValidResponse(res)) {
    const dataList = res.data;
    console.log('sendMailToAdminApproved', res.data, dataList);
    return dataList;
  }
}