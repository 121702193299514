import moment from "moment";
import { firebaseUploadFile } from "../firebases/firebaseStorage";

let logList = [];
let isAddLog = false

export const setIsAddLog = (isAdd) => {
  isAddLog = isAdd;
}

export const clearLogList = () => {
  logList = [];
}

export const pushLogList = (...value) => {
  if (isAddLog) {
    logList.push(`\r\n${moment().format('DD-MM-YYYY HH:mm:ss.SSS')}\r\n${JSON.stringify(value, null, 2)}\r\n\r\n`)
  }
}

export const uploadLogFile = async (pathUpload = '') => {
  if (isAddLog) {
    const filename = `${moment().format('DD-MM-YYYY HH:mm:ss.SSS')}_${new Date().getTime()}.txt`;
    const BOM = new Uint8Array([0xEF, 0xBB, 0xBF]);
    const blob = new Blob([BOM, logList], { type: 'text/plain' });
    await firebaseUploadFile(pathUpload, blob, filename);
  }
}
