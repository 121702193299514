import { storeDispatch, storeGetState } from "../../stores";
import { dataCreators, DATA_REDUX_STATE_KEY } from "../../actions/dataAction";
import { ModelProfile } from "../../models/ModelProfile";
import { ENUM } from "../constants/enum";
import { profileController } from "../../apiService/apiController/profileService";
import { isValidResponse } from "../functions/validation";
import { handleSortParams, handleTrimParams } from "../functions/data";

// export const getAdRfManageParams = (data) => {
//   const txtCustomerType = data.customerType === 1 ? 'Authorized Roll former (AD)' : 'Independent Roll former'
//   return {
//     email: data.email,
//     phone_no: data.phone,
//     company: data.company,
//     address: data.address,
//     member_type: txtCustomerType,
//   }
// }

export const searchProfile = async (params = {}) => {
  const userAuth = storeGetState().mainRedux.userAuth;
  let newParams = handleTrimParams({
    ...params,
    ad_ref_id: userAuth.adRefId,
  });
  
  if (!newParams.sort) {
    newParams.sort = 'id asc';
  }
  const profileService = profileController();
  const res = await profileService.search(newParams)
  if (isValidResponse(res)) {
    let dataList = res.data.map(e => new ModelProfile(e));
    newParams.activePage = newParams.page;
    // newParams.totalData = res.total_items;
    
    console.log('searchProfile = ', res.data, dataList, newParams);
    storeDispatch(dataCreators.setDataRedux(DATA_REDUX_STATE_KEY.PROFILE_LIST, dataList));
    storeDispatch(dataCreators.setDataRedux(DATA_REDUX_STATE_KEY.PROFILE_PARAMS, newParams));
  }
}

export const crtProfileMapping = async (params = {}) => {
  let userAuth = storeGetState().mainRedux.userAuth;
  let res;
  try {
    let newParams = handleTrimParams({
      ...params,
      from_dealer: userAuth.adRefId,
    });
    const profileService = profileController();
    res = await profileService.create(newParams);
  } catch (error) {
    console.log(error);
  }
  return res;
}

export const calProfile = async (params = {}) => {
  let res;
  try {
    let newParams = handleTrimParams({
      ...params,
    });
    const profileService = profileController();
    res = await profileService.calculate(newParams);
  } catch (error) {
    console.log(error);
  }
  return res;
}


// export const getTransferHistoryList = async (params = {}) => {
//   let userAuth = storeGetState().mainRedux.userAuth;
//   let currentParams = storeGetState().dataRedux.transferHisParams;
//   let newParams = handleTrimParams({
//     ...currentParams,
//     ...params,
//     ad_ref_id: userAuth.adRefId,
//   });
//   if(!newParams.sort){
//     newParams.sort = 'rsst.createddate desc';
//   }
//   else {
//     newParams = handleSortParams(currentParams, newParams);
//   }
//   // const saleTransService = saleTransController();
//   // const res = await saleTransService.searchSalesTransaction(newParams);
//   const res = await transferController().searchTransferHis(newParams);
//   if (isValidResponse(res)) {
//     const dataList = res.data.map(e => new ModelTransferHistory(e));
//     newParams.activePage = newParams.page;
//     newParams.totalData = res.total_items;
//     console.log('res.data =', res.data);
//     console.log('dataList =', dataList);
//     console.log('newParams =', newParams);
//     storeDispatch(dataCreators.setDataRedux(DATA_REDUX_STATE_KEY.TRNSFER_HISTORY_LIST, dataList));
//     storeDispatch(dataCreators.setDataRedux(DATA_REDUX_STATE_KEY.TRNSFER_HISTORY_PARAMS, newParams));
//   }
// }

// export const getClearTransferHistoryParams = () => {
//   return {
//     order_no: '',
//     coil_name: '',
//     coil_id: '',
//     created_date: '',
//     company_name: '',
//     trans_amount: '',
//     phone_no: '',
//     start_date: '',
//     end_date: '',
//     customer_id: '',
//     customer_name: '',
//     ad_ref_id: '',
//     all_data: '',
//     sort: '',
//   }
// }

// export const getTransferInvenrotyList = async (params = {}) => {
//   let userAuth = storeGetState().mainRedux.userAuth;
//   let currentParams = storeGetState().dataRedux.transferInvParams;
//   let newParams = handleTrimParams({
//     ...currentParams,
//     ...params,
//     ad_id: userAuth.adRefId,
//   });
//   if(!newParams.sort){
//     newParams.sort = 'coil_id desc';
//   }
//   else {
//     newParams = handleSortParams(currentParams, newParams);
//   }

//   const res = await transferController().searchTransferInv(newParams);
//   if (isValidResponse(res)) {
//     const dataList = res.data.map(e => new ModelInventory(e));
//     newParams.activePage = newParams.page;
//     newParams.totalData = res.total_items;
//     console.log('dataList', res.data, dataList, newParams);
//     storeDispatch(dataCreators.setDataRedux(DATA_REDUX_STATE_KEY.TRNSFER_INVENROTY, dataList));
//     storeDispatch(dataCreators.setDataRedux(DATA_REDUX_STATE_KEY.TRNSFER_INVENROTY_PARAMS, newParams));
//   }
// }

// export const getTransferInvenrotyParams = () => {
//   return {
//     order_no: '',
//     coil_id: '',
//     thickness: '',
//     color: '',
//     prd_group: '',
//     created_date: '',
//     trans_amount: '',
//     customer_id: '',
//     ad_ref_id: '',
//     all_field: '',
//     sort: '',
//   }
// }