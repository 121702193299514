import React from 'react';
import {
  Field,
} from './../../components'
import { displayPreviewImage } from '../../helpers/functions/display';

export class FieldUploadContainer extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      // src: props.src,
    }
  }

  input = {
    onChangeUpload: (ev) => {
      const input = ev.target;
      displayPreviewImage(input, (file, result) => {
        const {
          onChange,
        } = this.props;
        onChange(file, result);
        input.value = '';
      })
    }
  }

  render() {
    const {
      ui,
      src,
      onClickDeleteImage,
    } = this.props;
    return (
      <Field
        ui={ui}
        acceptFileType='.jpg, .png' // All file types > 'image/*, video/*, audio/*'
        srcUpload={src}
        onClickBrowse={() => { }}
        onClickDelete={onClickDeleteImage}
        onChange={this.input.onChangeUpload}
      />
    )
  }
}