import ClassNames from 'classnames'
import _ from 'lodash'
import PropTypes from 'prop-types'
import Slider from 'rc-slider'
import 'rc-slider/assets/index.css'
import React from 'react'
import { DATA } from '../../../helpers/constants/data'
import { ENUM } from '../../../helpers/constants/enum'
import { LOCAL_STORAGE } from '../../../helpers/constants/localStorage'
import { displayWithComma } from '../../../helpers/functions/display'
import { getLocalStorage } from '../../../helpers/functions/localStorage'
import { toast } from '../../../helpers/functions/main'
import { isValidPhoneNumber } from '../../../helpers/functions/validation'
import {
  Button, Field, Grid, Section, SelectControl, ShapeContainer, Text
} from './../../../components'
import { getLanguage } from './../../../helpers/functions/language'
import {
  CONTENTS,
  ICONS,
  VARIABLES
} from './../../../themes'
import {
  YearWarrantyWrapper
} from './styled'
/**
 * YearWarranty description:
 * - YearWarranty
 */
export class YearWarranty extends React.PureComponent {
  constructor(props) {
    super(props);
    const buildingTypeList = Object.values(DATA.PUBLIC_YEAR_WARRANTY_BUILDING_TYPE_LIST).map(e => {
      return {
        label: e.NAME,
        value: e.VALUE,
      }
    })
    this.state = {
      buildingTypeList,
      registerFormData: {
        fullname: '',
        company: '',
        buildingType: undefined,
        phone: '',
        isContactBack: false,
        isAcceptPrivacyPolicy: false,
      }
    }
  }

  yearWarranty = {
    getDisplayYearWarranty: (data, item, index) => {
      const {
        envList,
        selectedProduct,
        filterProductGroupNameList,
      } = this.props;
      const language = getLocalStorage(LOCAL_STORAGE.LANGUAGE_RBPTRUEBLUE);
      const isLanguageEn = language === DATA.LANGUAGE.ENGLISH;
      console.log('isLanguageEn', isLanguageEn);

      const isSuperDyma = selectedProduct.label.toLowerCase() === filterProductGroupNameList[13].toLowerCase();
      const isTrueCore = selectedProduct.label.toLowerCase() === filterProductGroupNameList[12].toLowerCase();
      const isColorbond = selectedProduct.label.toLowerCase() === filterProductGroupNameList[0].toLowerCase();
      const isColorbondUltra = selectedProduct.label.toLowerCase() === filterProductGroupNameList[1].toLowerCase();

      let envSelected = {};
      let envWallSelected = {};
      const environmentName = envList[0];
      const isShowRoofAndWall = data.isShowRoofAndWall;

      const getEnvDetail = (envList = []) => {
        // CR: 1498
        const result = DATA.SPECIAL_ENV_WARRANTY_YEAR.find(e => e.product.toLowerCase() === selectedProduct?.label?.toLowerCase());
        if (result) {
          const envSelected = result.env.find(e => e.environment === environmentName);
          if (envSelected) {
            return envSelected;
          }
        }

        return envList.filter(e => e.environment === environmentName)[0] || {}
      }

      if (isShowRoofAndWall) {
        data.warrantyYearDetailList.forEach(e => {
          if (e.application === DATA.WARRANTY_APP_DETAIL_NAME_LIST[0]) {
            const envRoofSelected = getEnvDetail(e.envList);
            envSelected = envRoofSelected;
          }
          if (e.application === DATA.WARRANTY_APP_DETAIL_NAME_LIST[1]) {
            envWallSelected = getEnvDetail(e.envList);
          }
        })
      }
      else {
        envSelected = getEnvDetail(item.envList);
      }
      const isShowCorrosion = parseInt(envSelected.corrosion) > 0;
      const isShowCorrosionWall = parseInt(envWallSelected.corrosion) > 0;
      const isShowColorFade = parseInt(envSelected.colorFade) > 0;
      const isShowPeelFlake = parseInt(envSelected.peelFlake) > 0;
      const isShowDirtStain = parseInt(envSelected.dirtStain) > 0;

      const corrosionComponent = (
        <div className="year-warranty-list" key={`${index}_1`}>
          <Grid gutter='15'>
            <Grid.Column flex='1'>
              <span className='year-warranty-text is-label-warranty-available'>
                {(isSuperDyma || isTrueCore) ?
                  getLanguage('yearWarranty.warranty.perforateByCorrosion3', '')
                  : (
                    isShowRoofAndWall ?
                      getLanguage('yearWarranty.warranty.perforateDueToCorrosionRoof', '')
                      :
                      getLanguage('yearWarranty.warranty.perforateByCorrosion1', '')
                  )
                }
              </span>
            </Grid.Column>
            <Grid.Column flex='none'>
              <span className='year-warranty-text is-number-warranty-available'>
                {`${envSelected.corrosion || '-'} `}
              </span>
              <span className='year-warranty-text is-label-warranty-available'>
                {getLanguage('yearWarranty.warranty.year', '')}
              </span>
            </Grid.Column>
          </Grid>
        </div>
      )
      const corrosionWallComponent = (
        <div className="year-warranty-list" key={`${index}_1_wall`}>
          <Grid gutter='15'>
            <Grid.Column flex='1'>
              <span className='year-warranty-text is-label-warranty-available'>
                {getLanguage('yearWarranty.warranty.perforateDueToCorrosionWall', '')}
              </span>
            </Grid.Column>
            <Grid.Column flex='none'>
              <span className='year-warranty-text is-number-warranty-available'>
                {`${envWallSelected.corrosion || '-'} `}
              </span>
              <span className='year-warranty-text is-label-warranty-available'>
                {getLanguage('yearWarranty.warranty.year', '')}
              </span>
            </Grid.Column>
          </Grid>
        </div>
      )
      const peelFlakeComponent = (
        <div className="year-warranty-list" key={`${index}_2`}>
          <Grid gutter='15'>
            <Grid.Column flex='1'>
              <span className='year-warranty-text is-label-warranty-available'>
                {/* การไม่หลุดร่อนของสี */}
                {/* Shall not perforate by color peeling for */}
                {getLanguage('yearWarranty.warranty.peelOrFlakeExcessively1', '')}
              </span>
            </Grid.Column>
            <Grid.Column flex='none'>
              <span className='year-warranty-text is-number-warranty-available'>
                {`${envSelected.peelFlake || '-'} `}
              </span>
              <span className='year-warranty-text is-label-warranty-available'>
                {getLanguage('yearWarranty.warranty.year', '')}
              </span>
            </Grid.Column>
          </Grid>
        </div>
      )
      const colorFadeComponent = (
        <div className="year-warranty-list" key={`${index}_3`}>
          <Grid gutter='15'>
            <Grid.Column flex='1'>
              <span className='year-warranty-text is-label-warranty-available'>
                {/* สีไม่ซีดจาง */}
                {/* Shall not colour fade excessively for */}
                {getLanguage('yearWarranty.warranty.colourFadeExcessively1', '')}
              </span>
            </Grid.Column>
            <Grid.Column flex='none'>
              <span className='year-warranty-text is-number-warranty-available'>
                {`${envSelected.colorFade || '-'} `}
              </span>
              <span className='year-warranty-text is-label-warranty-available'>
                {getLanguage('yearWarranty.warranty.year', '')}
              </span>
            </Grid.Column>
          </Grid>
        </div>
      )
      const dirtStainComponent = (
        <div className="year-warranty-list" key={`${index}_4`}>
          <Grid gutter='15'>
            <Grid.Column flex='1'>
              <span className='year-warranty-text is-label-warranty-available'>
                {getLanguage('yearWarranty.warranty.discolourByDirtStaining1', '')}
              </span>
            </Grid.Column>
            <Grid.Column flex='none'>
              <span className='year-warranty-text is-number-warranty-available'>
                {`${envSelected.dirtStain || '-'} `}
              </span>
              <span className='year-warranty-text is-label-warranty-available'>
                {getLanguage('yearWarranty.warranty.year', '')}
              </span>
            </Grid.Column>
          </Grid>
        </div>
      )

      const dataList = [];

      if (isShowRoofAndWall) {
        if (isShowCorrosion || isShowCorrosionWall) {
          dataList.push(corrosionComponent)
          dataList.push(corrosionWallComponent)
        }
      } else {
        if (isShowCorrosion) {
          dataList.push(corrosionComponent)
        }
      }

      if (isShowPeelFlake) {
        dataList.push(peelFlakeComponent)
      }

      if (isShowColorFade) {
        dataList.push(colorFadeComponent)
      }

      if (isShowDirtStain) {
        dataList.push(dirtStainComponent)
      }

      if (dataList.length === 0) {
        dataList.push(
          <div className="year-warranty-list" key={`${index}_5`}>
            <div className="year-warranty-text is-message-error">
              {getLanguage('yearWarranty.warranty.noWarrantyInformation', '')}
            </div>
          </div>
        )
      }

      return dataList;
    },
    isValidateRegisterPass: () => {
      const {
        registerFormData,
      } = this.state;
      let isPass = true;
      const data = registerFormData;

      if (
        !data.fullname.length ||
        !data.company.length ||
        !data.phone.length ||
        !data.isAcceptPrivacyPolicy ||
        !data.buildingType
      ) {
        toast.error(getLanguage('message.pleaseInsertAllRequiredField', ''));
        isPass = false;
      }

      DATA.PHONE_PREFIX_LIST.forEach(e => {
        if (e.country === ENUM.COUNTRY.THAI) {
          if (e.lengthList.indexOf(data.phone.length) === -1) {
            toast.error(getLanguage('message.incorrectMobileNumberLength', ''));
            isPass = false;
          }
        }
      });

      return isPass;
    },
    onClickSubmitRegister: () => {
      const {
        handleSubmitRegisterForm,
      } = this.props;
      const {
        registerFormData,
      } = this.state;
      if (this.yearWarranty.isValidateRegisterPass()) {
        if (handleSubmitRegisterForm) {
          handleSubmitRegisterForm(registerFormData);
        }
      }
    },
  }

  input = {
    onChangeInput: (ev) => {
      const {
        registerFormData,
      } = this.state
      const name = ev.target.name;
      let value = ev.target.value;
      switch (name) {
        case 'phone':
          if (value.length && !isValidPhoneNumber(value)) {
            return;
          }
          break;
        case 'isContactBack':
        case 'isAcceptPrivacyPolicy':
          value = ev.target.checked;
          break;
        default: break;
      }

      registerFormData[name] = value;
      this.setState({
        registerFormData: _.cloneDeep(registerFormData)
      })
    },
    onChangeBuildingType: (item) => {
      const {
        registerFormData
      } = this.state;
      registerFormData.buildingType = item;
      this.setState({
        registerFormData,
      })
    }
  }

  render() {
    const {
      className,
      children,
      isProductSelected,
      isShowRegister,
      isShowTooltipRoof,
      isShowTooltipWall,
      isShowTooltipPurlinGirt,
      isShowTooltipTrussFrame,
      isShowTooltipCoolRoomPanel,
      isShowTooltipDecking,
      isShowTooltipAirDucting,
      isShowTooltipShutterEx,
      onChangeSelectProduct,
      onChangeSelectFrom,
      selectedProduct,
      selectedFrom,
      selectOptionProductList,
      selectOptionFromList,
      dataList,
      onChangeSlider,
      markData,
      markDataList,
      markValue,
      envList,
    } = this.props;
    const {
      registerFormData,
      buildingTypeList,
    } = this.state;
    // props for css classes
    const classes = ClassNames(
      'year-warranty',
      { 'is-product-selected': isProductSelected },
      { 'is-register-success': !isShowRegister },
      className
    )
    console.log('dataList', dataList);

    // CR: 1498
    const isInWarrantyHintProducts = DATA.WARRANTY_HINT_PRODUCTS.some(whp => whp.toLowerCase() === selectedProduct?.label?.toLowerCase());

    return (
      <YearWarrantyWrapper
        className={classes}
      >
        {/* Register */}
        <div className='year-warranty-register'>
          <div className='year-warranty-register-container'>
            <div className='year-warranty-lists is-register'>
              <div className='year-warranty-list'>
                <Text
                  bold18
                  color={VARIABLES.COLORS.BLACK}
                >
                  {getLanguage('yearWarranty.label.pleaseRegister', '')}
                </Text>
              </div>
              <div className='year-warranty-list'>
                <Field name='fullname'
                  fluid
                  required
                  // error
                  ui='blockLabelVertical'
                  label={getLanguage('field.fullname', '')}
                  placeholder={getLanguage('field.fullnamePlaceholder', '')}
                  value={registerFormData.fullname}
                  onChange={this.input.onChangeInput}
                />
              </div>
              <div className='year-warranty-list'>
                <Field name='company'
                  fluid
                  required
                  // error
                  ui='blockLabelVertical'
                  label={getLanguage('field.companyname', '')}
                  placeholder={getLanguage('field.companynamePlaceholder', '')}
                  value={registerFormData.company}
                  onChange={this.input.onChangeInput}
                />
              </div>
              <div className='year-warranty-list'>
                <SelectControl
                  className='is-filter-year-warranty'
                  verticalLabel
                  isSearchable={false}
                  required
                  label={getLanguage('field.typeProject', '')}
                  placeholder={getLanguage('field.typeProjectPlaceholder', '')}
                  value={registerFormData.buildingType}
                  onChange={this.input.onChangeBuildingType}
                  options={buildingTypeList}
                />
              </div>
              <div className='year-warranty-list'>
                <Field name='phone'
                  fluid
                  required
                  // error
                  ui='blockLabelVerticalPhone'
                  label={getLanguage('field.mobileNo', '')}
                  placeholder={getLanguage('field.mobileNoPlaceholder', '')}
                  message={
                    <Section
                      ui='hintBottomField'
                      hint={getLanguage('field.phoneNoHint', '')}
                    />
                  }
                  value={registerFormData.phone}
                  onChange={this.input.onChangeInput}
                // selectedPhoneCountry={}
                // onChangePhoneCountry={selected => {}}
                />
              </div>
              <div className='year-warranty-list'>
                <Grid gutter='8'>
                  <Grid.Column flex='none'>
                    <Field
                      ui='checkboxTick'
                      name='isContactBack'
                      checked={registerFormData.isContactBack}
                      onChange={this.input.onChangeInput}
                    />
                  </Grid.Column>
                  <Grid.Column flex='1'>
                    <Text regular12 color={VARIABLES.COLORS.BLACK}>
                      {getLanguage('message.blueScopeContactBack', '')}
                    </Text>
                  </Grid.Column>
                </Grid>
              </div>
              <div className='year-warranty-list'>
                <Grid gutter='8'>
                  <Grid.Column flex='none'>
                    <Field
                      ui='checkboxTick'
                      name='isAcceptPrivacyPolicy'
                      checked={registerFormData.isAcceptPrivacyPolicy}
                      onChange={this.input.onChangeInput}
                    />
                  </Grid.Column>
                  <Grid.Column flex='1'>
                    <Text regular12 color={VARIABLES.COLORS.BLACK} top='2'>
                      {getLanguage('message.privacyPolicyRegisterYearWarranty1', '')}
                      <a className='year-warranty-text is-link' href='https://bit.ly/3iEt8JB' target='_blank'>{getLanguage('message.privacyPolicyRegisterYearWarranty2', '')}</a>
                      {getLanguage('message.privacyPolicyRegisterYearWarranty3', '')}
                    </Text>
                  </Grid.Column>
                </Grid>
              </div>
              <div className='year-warranty-list'>
                <Button
                  blue
                  fluid
                  name={getLanguage('field.buttonNextStep', '')}
                  // width={VARIABLES.BUTTON.WIDTHS.W_125}
                  onClick={this.yearWarranty.onClickSubmitRegister}
                />
              </div>
            </div>
          </div>
        </div>
        {/* Select Product */}
        <div className='year-warranty-select-product'>
          <Grid className='is-select-product' gutter='15' gutterVertical='15'>
            <Grid.Column>
              <SelectControl
                className='is-filter-year-warranty'
                verticalLabel
                isSearchable={false}
                label={getLanguage('yearWarranty.label.product', '')}
                placeholder={getLanguage('yearWarranty.placeholder.selectProduct', '')}
                width='200'
                value={selectedProduct}
                // defaultValue={}
                onChange={onChangeSelectProduct}
                options={selectOptionProductList}
              />
            </Grid.Column>
            {selectedProduct &&
              <React.Fragment>
                <Grid.Column>
                  <SelectControl
                    className='is-filter-year-warranty'
                    verticalLabel
                    isSearchable={false}
                    label={getLanguage('yearWarranty.label.from', '')}
                    placeholder={getLanguage('yearWarranty.placeholder.selectFrom', '')}
                    width='200'
                    value={selectedFrom}
                    // defaultValue={}
                    onChange={onChangeSelectFrom}
                    options={selectOptionFromList}
                  />
                </Grid.Column>
                <Grid.Column className='is-select-distance'>
                  <SelectControl
                    className='is-filter-year-warranty'
                    verticalLabel
                    isSearchable={false}
                    label={getLanguage('yearWarranty.label.distance', '') + '(' + getLanguage('yearWarranty.label.m', '') + ')'}
                    placeholder={getLanguage('yearWarranty.placeholder.selectDistance', '')}
                    width='200'
                    value={{
                      label: markData[markValue],
                      value: markValue,
                    }}
                    // defaultValue={}
                    onChange={onChangeSlider}
                    options={markDataList}
                  />
                </Grid.Column>
              </React.Fragment>
            }
          </Grid>
        </div>
        {/* Product Information */}
        <div className="year-warranty-product-information">
          <div className="year-warranty-section is-heading-product-name">
            <span className="year-warranty-text is-name-product">
              {selectedProduct?.label ?? ''}
            </span>
          </div>
          <Grid className='is-disctance-info' gutter='15'>
            <Grid.Column>
              <span className="year-warranty-text is-label-environment">
                {getLanguage('yearWarranty.label.environment', '')}
              </span>
            </Grid.Column>
            <Grid.Column>
              <Grid gutter='10' align='center'>
                <Grid.Column>
                  <span className="year-warranty-text is-name-category">
                    {selectedFrom?.label ?? ''}
                  </span>
                </Grid.Column>
                <Grid.Column>
                  <img src={ICONS['ic-arrow-chevron-next-blue.svg']} alt='Icon' />
                </Grid.Column>
                <Grid.Column>
                  <span className="year-warranty-text is-distance">
                    {getLanguage('yearWarranty.label.distance', '')} {displayWithComma(markData[markValue])} {getLanguage('yearWarranty.label.m', '')}
                  </span>
                </Grid.Column>
              </Grid>
            </Grid.Column>
          </Grid>
        </div>
        {/* Home Model & Information Tooltips */}
        <div className='year-warranty-building'>
          <div className="year-warranty-image is-model">
            <img className='year-warranty-image is-home-model-disabled' src={CONTENTS['building-isometric-disabled.png']} alt='Home Model Disabled' />
            <img className='year-warranty-image is-home-model' src={CONTENTS['building-isometric.png']} alt='Home Model' />

            <div
              className={ClassNames(
                'year-warranty-building-tooltip is-roof is-arrow is-arrow-left',
                { 'is-show-tooltip is-show-tooltip-roof': isShowTooltipRoof },
              )}
            >
              <img className='year-warranty-image' src={ICONS['ic-roof.svg']} alt='Icon' />
              <div className="year-warranty-section is-tooltip-name">
                <span className="year-warranty-text is-tooltip">
                  {getLanguage('yearWarranty.label.roof', '')}
                </span>
              </div>
            </div>

            <div
              className={ClassNames(
                'year-warranty-building-tooltip is-wall is-arrow is-arrow-right',
                { 'is-show-tooltip is-show-tooltip-wall': isShowTooltipWall },
              )}
            >
              <img className='year-warranty-image' src={ICONS['ic-wall-vertical.svg']} alt='Icon' />
              <div className="year-warranty-section is-tooltip-name">
                <span className="year-warranty-text is-tooltip">
                  {getLanguage('yearWarranty.label.wall', '')}
                </span>
              </div>
            </div>

            <div
              className={ClassNames(
                'year-warranty-building-tooltip is-purlin-girt is-arrow is-arrow-left',
                { 'is-show-tooltip is-show-tooltip-purlin-girt': isShowTooltipPurlinGirt },
              )}
            >
              <img className='year-warranty-image' src={ICONS['ic-purlin-girt.svg']} alt='Icon' />
              <div className="year-warranty-section is-tooltip-name">
                <span className="year-warranty-text is-tooltip">
                  {getLanguage('yearWarranty.label.purlinGirt', '')}
                </span>
              </div>
            </div>

            <div
              className={ClassNames(
                'year-warranty-building-tooltip is-truss-frame is-arrow is-arrow-left',
                { 'is-show-tooltip is-show-tooltip-truss-frame': isShowTooltipTrussFrame },
              )}
            >
              <img className='year-warranty-image' src={ICONS['ic-truss-frame.svg']} alt='Icon' />
              <div className="year-warranty-section is-tooltip-name">
                <span className="year-warranty-text is-tooltip">
                  {getLanguage('yearWarranty.label.trussFrame', '')}
                </span>
              </div>
            </div>

            <div
              className={ClassNames(
                'year-warranty-building-tooltip is-coolroom-panel is-arrow is-arrow-bottom',
                { 'is-show-tooltip is-show-tooltip-coolroom-panel': isShowTooltipCoolRoomPanel },
              )}
            >
              <img className='year-warranty-image' src={ICONS['ic-cool-room-panel.svg']} alt='Icon' />
              <div className="year-warranty-section is-tooltip-name">
                <span className="year-warranty-text is-tooltip">
                  {getLanguage('yearWarranty.label.coolRoomPanel', '')}
                </span>
              </div>
            </div>

            <div
              className={ClassNames(
                'year-warranty-building-tooltip is-decking is-arrow is-arrow-left',
                { 'is-show-tooltip is-show-tooltip-decking': isShowTooltipDecking },
              )}
            >
              <img className='year-warranty-image' src={ICONS['ic-decking.svg']} alt='Icon' />
              <div className="year-warranty-section is-tooltip-name">
                <span className="year-warranty-text is-tooltip">
                  {getLanguage('yearWarranty.label.decking', '')}
                </span>
              </div>
            </div>

            <div
              className={ClassNames(
                'year-warranty-building-tooltip is-air-ducting is-arrow is-arrow-right',
                { 'is-show-tooltip is-show-tooltip-air-ducting': isShowTooltipAirDucting },
              )}
            >
              <img className='year-warranty-image' src={ICONS['ic-air-ducting.svg']} alt='Icon' />
              <div className="year-warranty-section is-tooltip-name">
                <span className="year-warranty-text is-tooltip">
                  {getLanguage('yearWarranty.label.airDucting', '')}
                </span>
              </div>
            </div>

            <div
              className={ClassNames(
                'year-warranty-building-tooltip is-shutter-ex is-arrow is-arrow-left',
                { 'is-show-tooltip is-show-tooltip-shutter-ex': isShowTooltipShutterEx },
              )}
            >
              <img className='year-warranty-image' src={ICONS['ic-shutter-ex.svg']} alt='Icon' />
              <div className="year-warranty-section is-tooltip-name">
                <span className="year-warranty-text is-tooltip">
                  {getLanguage('yearWarranty.label.shutterEx', '')}
                </span>
              </div>
            </div>
          </div>
        </div>
        {/* Environment Avaiable */}
        <div className='year-warranty-section is-warranty-available'>
          <div className="year-warranty-available">
            <div className="year-warranty-section is-heading-warranty-available">
              <span className='year-warranty-text is-heading-warranty-available'>
                {getLanguage('yearWarranty.label.environment', '')}
              </span>
            </div>
            <div className="year-warranty-section is-environment-warranty-available">
              <Grid gutter='5' gutterVertical='5'>
                <div className='year-warranty-env-wrapper'>
                  {envList.map((e, i) => {
                    return (
                      <Grid.Column key={i}>
                        <ShapeContainer
                          className='is-badge-environment'
                          height='20'
                          paddingHorizontal='10'
                          justify='center'
                          borderRadius='10'
                        >
                          <span className='year-warranty-text is-label-environment-selected'>{e}</span>
                        </ShapeContainer>
                      </Grid.Column>
                    )
                  })}
                  {/* // CR: 1498 */}
                  {isInWarrantyHintProducts && <div className='year-warranty-production-date-hint'>{getLanguage('yearWarranty.hint.message.steelProductionDate')}</div>}
                </div>
              </Grid>
            </div>
            <div className="year-warranty-section is-label-warranty-available">
              <Grid gutter='15'>
                <Grid.Column flex='1'>
                  <span className="year-warranty-text is-heading-warranty-available">
                    {getLanguage('yearWarranty.label.warrantiesAvailable', '')}
                  </span>
                </Grid.Column>
                <Grid.Column flex='none'>
                  <span className="year-warranty-text is-heading-warranty-available">
                    {getLanguage('yearWarranty.label.year', '')}
                  </span>
                </Grid.Column>
              </Grid>
            </div>
            <div className='year-warranty-lists is-product'>
              {/* Element for loop */}
              {dataList.map((e, i) => {
                return (
                  <div className='year-warranty-list' key={i}>
                    <div className="year-warranty-lists is-application-group">
                      <div className="year-warranty-list">
                        {/* <span className='year-warranty-text is-product'>{e.m3ProductGroupCode}</span> */}
                        <span className='year-warranty-text is-product'>{e.productGroupName}</span>
                      </div>
                      {/* Element for loop */}
                      {e.dataList.map((ed, id) => {
                        return (
                          <div className="year-warranty-list" key={id}>
                            <div className='year-warranty-lists is-application'>
                              <div className="year-warranty-list">
                                {/* <span className='year-warranty-text is-application'>{ed.application}</span> */}
                              </div>
                              <div className='year-warranty-list'>
                                <div className='year-warranty-lists'>
                                  {this.yearWarranty.getDisplayYearWarranty(e, ed, id)}
                                </div>
                              </div>
                            </div>
                          </div>
                        )
                      })}
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
        {
          selectedProduct ?
            <React.Fragment>
              {/* Environment Control */}
              <div className="year-warranty-environment-control">
                <div className="year-warranty-environment-control-container">
                  <Slider
                    min={0}
                    marks={markData}
                    step={null}
                    onChange={onChangeSlider}
                    value={markValue}
                  />
                  <div className="year-warranty-slider-label-position is-min">
                    <span className="year-warranty-text is-label-slider-range is-label-slider-range-min">{getLanguage('yearWarranty.label.min', '')}</span>
                  </div>
                  <div className="year-warranty-slider-label-position is-distance">
                    <span className="year-warranty-text is-label-slider-range is-label-slider-range-distance">{getLanguage('yearWarranty.label.distance', '')} ({getLanguage('yearWarranty.label.metre', '')})</span>
                  </div>
                  <div className="year-warranty-slider-label-position is-max">
                    <span className="year-warranty-text is-label-slider-range is-label-slider-range-max">{getLanguage('yearWarranty.label.max', '')}</span>
                  </div>
                </div>
              </div>
              {/* Hint */}
              {/* <div className="year-warranty-environment-hint">
                <Grid gutter='15' gutterVertical='5' justify='center'>
                  <Grid.Column>
                    <span className="year-warranty-text is-hint-short">{getLanguage('yearWarranty.hint.short.br', '')}</span>
                    <span className="year-warranty-text is-hint-long">= {getLanguage('yearWarranty.hint.long.br', '')}</span>
                  </Grid.Column>
                  <Grid.Column>
                    <span className="year-warranty-text is-hint-short">{getLanguage('yearWarranty.hint.short.lu', '')}</span>
                    <span className="year-warranty-text is-hint-long">= {getLanguage('yearWarranty.hint.long.lu', '')}</span>
                  </Grid.Column>
                  <Grid.Column>
                    <span className="year-warranty-text is-hint-short">{getLanguage('yearWarranty.hint.short.ind', '')}</span>
                    <span className="year-warranty-text is-hint-long">= {getLanguage('yearWarranty.hint.long.ind', '')}</span>
                  </Grid.Column>
                  <Grid.Column>
                    <span className="year-warranty-text is-hint-short">{getLanguage('yearWarranty.hint.short.hind', '')}</span>
                    <span className="year-warranty-text is-hint-long">= {getLanguage('yearWarranty.hint.long.hind', '')}</span>
                  </Grid.Column>
                  <Grid.Column>
                    <span className="year-warranty-text is-hint-short">{getLanguage('yearWarranty.hint.short.sind', '')}</span>
                    <span className="year-warranty-text is-hint-long">= {getLanguage('yearWarranty.hint.long.sind', '')}</span>
                  </Grid.Column>
                  <Grid.Column>
                    <span className="year-warranty-text is-hint-short">{getLanguage('yearWarranty.hint.short.mm', '')}</span>
                    <span className="year-warranty-text is-hint-long">= {getLanguage('yearWarranty.hint.long.mm', '')}</span>
                  </Grid.Column>
                  <Grid.Column>
                    <span className="year-warranty-text is-hint-short">{getLanguage('yearWarranty.hint.short.m', '')}</span>
                    <span className="year-warranty-text is-hint-long">= {getLanguage('yearWarranty.hint.long.m', '')}</span>
                  </Grid.Column>
                  <Grid.Column>
                    <span className="year-warranty-text is-hint-short">{getLanguage('yearWarranty.hint.short.sm', '')}</span>
                    <span className="year-warranty-text is-hint-long">= {getLanguage('yearWarranty.hint.long.sm', '')}</span>
                  </Grid.Column>
                  <Grid.Column>
                    <span className="year-warranty-text is-hint-short">{getLanguage('yearWarranty.hint.short.vsm', '')}</span>
                    <span className="year-warranty-text is-hint-long">= {getLanguage('yearWarranty.hint.long.vsm', '')}</span>
                  </Grid.Column>
                </Grid>
              </div> */}
            </React.Fragment>
            : undefined
        }
      </YearWarrantyWrapper >
    )
  }

  static defaultProps = {
    // * If you set new default props, you should create propsType for correct styleguide document.
  }

  static propTypes = { // TYPE > node, string, number, bool, array, object, symbol, func
    /**
    * Additional classes
    */
    className: PropTypes.string
  }
}
