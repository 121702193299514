import React from 'react'
import ClassNames from 'classnames'
import PropTypes from 'prop-types'
import {
  osName,
} from 'react-device-detect'
import {
  WarrantyWrapper
} from './styled'
import {
  Section,
  Text,
  Image,
  Grid,
  Table,
  ShapeContainer,
} from './../../../components'
import {
  default as VARIABLES
} from './../../../themes/styles/bases/variables'
import ReactHtmlParser from 'react-html-parser';
import _ from 'lodash';
// import {
//   default as TYPOGRAPHYS
// } from './../../../themes/styles/bases/typographys'
// import {
//   default as UTILITIES
// } from './../../../themes/styles/helpers/utilities'

/**
 * Warranty description:
 * - Warranty
 */

class WarrantySetupWarningList extends React.Component {
  render() {
    return (
      <React.Fragment>
        <Section paddingLeft='10' spacingBottom='-7'>
          <Grid>
            <Grid.Column flex='none'>
              <Section width='10'>
                <Text regular7 lineheightTh color={VARIABLES.COLORS.BLACK}>(a)</Text>
              </Section>
            </Grid.Column>
            <Grid.Column flex='1'>
              <Text regular7 lineheightTh color={VARIABLES.COLORS.BLACK}>ต้องจัดเก็บรักษาผลิตภัณฑ์ให้อยู่ในสภาพแห้งตลอดเวลาทั้งในขณะกองเก็บหรืออยู่ในมัดก่อนการติดตั้ง สำหรับแผ่นที่เปียกควรรีบจัดแยกและทำให้แห้งเพื่อลดความเสี่ยงจากการเร่งปฏิกิริยาการเกิดสนิม</Text>
            </Grid.Column>
          </Grid>
        </Section>
        <Section paddingLeft='10' spacingBottom='-7'>
          <Grid>
            <Grid.Column flex='none'>
              <Section width='10'>
                <Text regular7 lineheightTh color={VARIABLES.COLORS.BLACK}>(b)</Text>
              </Section>
            </Grid.Column>
            <Grid.Column flex='1'>
              <Text regular7 lineheightTh color={VARIABLES.COLORS.BLACK}>ไม่ตัดแผ่นโดยใช้เครื่องตัดหรือขัดแบบเจียระไน เนื่องจากความร้อนของเศษเหล็กหรือฝุ่นเหล็กจากการตัดอาจทำให้ผิวเคลือบของผลิตภัณฑ์เกิดความเสียหาย</Text>
            </Grid.Column>
          </Grid>
        </Section>
        <Section paddingLeft='10'>
          <Grid>
            <Grid.Column flex='none'>
              <Section width='10'>
                <Text regular7 lineheightTh color={VARIABLES.COLORS.BLACK}>(c)</Text>
              </Section>
            </Grid.Column>
            <Grid.Column flex='1'>
              <Text regular7 lineheightTh color={VARIABLES.COLORS.BLACK}>หลังการติดตั้งควรทำความสะอาด เศษโลหะ หรือฝุ่นละอองต่างๆที่ตกค้างอยู่บนผลิตภัณฑ์เป็นประจำทุกวัน เพื่อลดปัจจัยความเสี่ยงของการเกิดสนิมจากการสะสมของเศษโละหะ หรือฝุ่นละอองต่างๆ</Text>
            </Grid.Column>
          </Grid>
        </Section>
      </React.Fragment>
    )
  }
}

class WarrantyDecayList extends React.Component {
  render() {
    return (
      <React.Fragment>
        <Section paddingLeft='10' spacingBottom='-7'>
          <Grid>
            <Grid.Column flex='none'>
              <Section width='10'>
                <Text regular7 lineheightTh color={VARIABLES.COLORS.BLACK}>(a)</Text>
              </Section>
            </Grid.Column>
            <Grid.Column flex='1'>
              <Text regular7 lineheightTh color={VARIABLES.COLORS.BLACK}>ความเสียหายจากการใช้เครื่องมือ เครื่องจักรกล สารเคมี หรือความเสียหายอื่นๆ ที่ไม่ได้เกิดจากผลิตภัณฑ์ที่เกิดขึ้นก่อน ระหว่างหรือภายหลังการติดตั้ง</Text>
            </Grid.Column>
          </Grid>
        </Section>
        <Section paddingLeft='10' spacingBottom='-7'>
          <Grid>
            <Grid.Column flex='none'>
              <Section width='10'>
                <Text regular7 lineheightTh color={VARIABLES.COLORS.BLACK}>(b)</Text>
              </Section>
            </Grid.Column>
            <Grid.Column flex='1'>
              <Text regular7 lineheightTh color={VARIABLES.COLORS.BLACK}>ความเสียหายจากการสัมผัสกับตัวทำปฎิกริยาเคมี, ไอระเหย, ควัน, ของเหลวหรือของแข็งนอกเหนือจากฝนตกตามธรรมชาติ</Text>
            </Grid.Column>
          </Grid>
        </Section>
        <Section paddingLeft='10' spacingBottom='-7'>
          <Grid>
            <Grid.Column flex='none'>
              <Section width='10'>
                <Text regular7 lineheightTh color={VARIABLES.COLORS.BLACK}>(c)</Text>
              </Section>
            </Grid.Column>
            <Grid.Column flex='1'>
              <Text regular7 lineheightTh color={VARIABLES.COLORS.BLACK}>การขาดการทำความสะอาดในบริเวณที่ไม่ถูกฝนชะล้างตามธรรมชาติ และไม่มีการทำความสะอาดอย่างสม่ำเสมอ</Text>
            </Grid.Column>
          </Grid>
        </Section>
        <Section paddingLeft='10' spacingBottom='-7'>
          <Grid>
            <Grid.Column flex='none'>
              <Section width='10'>
                <Text regular7 lineheightTh color={VARIABLES.COLORS.BLACK}>(d)</Text>
              </Section>
            </Grid.Column>
            <Grid.Column flex='1'>
              <Text regular7 lineheightTh color={VARIABLES.COLORS.BLACK}>การสัมผัสกับดิน ฝุ่น เถ้า ปุ๋ย หรือวัสดุที่มีความชื้น</Text>
            </Grid.Column>
          </Grid>
        </Section>
        <Section paddingLeft='10' spacingBottom='-7'>
          <Grid>
            <Grid.Column flex='none'>
              <Section width='10'>
                <Text regular7 lineheightTh color={VARIABLES.COLORS.BLACK}>(e)</Text>
              </Section>
            </Grid.Column>
            <Grid.Column flex='1'>
              <Text regular7 lineheightTh color={VARIABLES.COLORS.BLACK}>พื้นที่ของผลิตภัณฑ์ที่สัมผัสกับโลหะที่ไม่มีคุณสมบัติเทียบเท่า (เช่น ตะกั่ว, ทองแดง, ถ่าน, กราไฟต์ หรือ เหล็กกล้าไร้สนิม) หรือพื้นที่ที่รองรับของเหลวที่ไหลมาจากบริเวณที่มีวัสดุดังกล่าว</Text>
            </Grid.Column>
          </Grid>
        </Section>
        <Section paddingLeft='10' spacingBottom='-7'>
          <Grid>
            <Grid.Column flex='none'>
              <Section width='10'>
                <Text regular7 lineheightTh color={VARIABLES.COLORS.BLACK}>(f)</Text>
              </Section>
            </Grid.Column>
            <Grid.Column flex='1'>
              <Text regular7 lineheightTh color={VARIABLES.COLORS.BLACK}>ขาดการกำจัดเศษต่างๆ การจัดการเพื่อให้เกิดการระบาย หรือการไหลของน้ำได้โดยสะดวก รวมทั้งการควบแน่นของไอน้ำบนทุกพื้นผิวของผลิตภัณฑ์</Text>
            </Grid.Column>
          </Grid>
        </Section>
        <Section paddingLeft='10' spacingBottom='-7'>
          <Grid>
            <Grid.Column flex='none'>
              <Section width='10'>
                <Text regular7 lineheightTh color={VARIABLES.COLORS.BLACK}>(g)</Text>
              </Section>
            </Grid.Column>
            <Grid.Column flex='1'>
              <Text regular7 lineheightTh color={VARIABLES.COLORS.BLACK}>การผุกร่อน ผุพังของผลิตภัณฑ์ที่เกิดจากการสัมผัสกับเศษไม้ กิ่งไม้สดเปียก หรือไม้ที่ผ่านกรรมวิธีทางเคมี</Text>
            </Grid.Column>
          </Grid>
        </Section>
        <Section paddingLeft='10' spacingBottom='-7'>
          <Grid>
            <Grid.Column flex='none'>
              <Section width='10'>
                <Text regular7 lineheightTh color={VARIABLES.COLORS.BLACK}>(h)</Text>
              </Section>
            </Grid.Column>
            <Grid.Column flex='1'>
              <Text regular7 lineheightTh color={VARIABLES.COLORS.BLACK}>การติดตั้งอยู่ในบริเวณที่มีสภาพแวดล้อมรุนแรง ได้แก่ อยู่ติดทะเลระยะทางน้อยกว่า 1 กิโลเมตร, อยู่ในเขตนิคมอุตสาหกรรม หรือสภาพที่มีการกัดกร่อนสูง</Text>
            </Grid.Column>
          </Grid>
        </Section>
        <Section paddingLeft='10' spacingBottom='-7'>
          <Grid>
            <Grid.Column flex='none'>
              <Section width='10'>
                <Text regular7 lineheightTh color={VARIABLES.COLORS.BLACK}>(i)</Text>
              </Section>
            </Grid.Column>
            <Grid.Column flex='1'>
              <Text regular7 lineheightTh color={VARIABLES.COLORS.BLACK}>พายุ หรือภัยพิบัติทางธรรมชาติ</Text>
            </Grid.Column>
          </Grid>
        </Section>
        <Section paddingLeft='10' spacingBottom='-7'>
          <Grid>
            <Grid.Column flex='none'>
              <Section width='10'>
                <Text regular7 lineheightTh color={VARIABLES.COLORS.BLACK}>(j)</Text>
              </Section>
            </Grid.Column>
            <Grid.Column flex='1'>
              <Text regular7 lineheightTh color={VARIABLES.COLORS.BLACK}>ความบกพร่องที่เกิดจากการรีดขึ้นรูป หรือการติดตั้งผลิตภัณฑ์</Text>
            </Grid.Column>
          </Grid>
        </Section>
        <Section paddingLeft='10'>
          <Grid>
            <Grid.Column flex='none'>
              <Section width='10'>
                <Text regular7 lineheightTh color={VARIABLES.COLORS.BLACK}>(k)</Text>
              </Section>
            </Grid.Column>
            <Grid.Column flex='1'>
              <Text regular7 lineheightTh color={VARIABLES.COLORS.BLACK}>น้ำขังอันเกิดจากความลาดเอียงของหลังคาต่ำ หรือการติดตั้งที่ไม่ได้มาตรฐาน</Text>
            </Grid.Column>
          </Grid>
        </Section>
      </React.Fragment>
    )
  }
}

class WarrantyNotice extends React.Component {
  render() {
    return (
      <React.Fragment>
        <Section spacingBottom='-4'>
          <Text regular8 lineheightTh color={VARIABLES.COLORS.BLACK}>
            บริษัท เอ็นเอส บลูสโคป (ประเทศไทย) จำกัด ให้การรับประกันผลิตภัณฑ์ นับตั้งแต่วันที่มีการติดตั้ง รวมถึงปฏิบัติตามข้อกำหนดและเงื่อนไขที่ระบุไว้ ตามรายละเอียดข้อกำหนดและเงื่อนไขการรับประกันดังนี้
          </Text>
        </Section>
        <Section spacingBottom='-7'>
          <Text regular8 lineheightTh color={VARIABLES.COLORS.BLACK}>การให้การรับประกันนี้ขึ้นอยู่กับข้อกำหนด และเงื่อนไข (ดูรายละเอียด), ตามที่ระบุไว้ในชุดเอกสารการรับประกัน และจะไม่มีการรับประกันจนกว่า :</Text>
        </Section>
        <Section spacingBottom='-7'>
          <Grid gutter='5'>
            <Grid.Column flex='none'>
              <Text regular8 lineheightTh color={VARIABLES.COLORS.BLACK}>-</Text>
            </Grid.Column>
            <Grid.Column flex='1'>
              <Text regular8 lineheightTh color={VARIABLES.COLORS.BLACK}>มีการปฏิบัติตามรายละเอียดที่กำหนดเอาไว้อย่างถูกต้องครบถ้วน</Text>
            </Grid.Column>
          </Grid>
        </Section>
        <Section spacingBottom='-7'>
          <Grid gutter='5'>
            <Grid.Column flex='none'>
              <Text regular8 lineheightTh color={VARIABLES.COLORS.BLACK}>-</Text>
            </Grid.Column>
            <Grid.Column flex='1'>
              <Text regular8 lineheightTh color={VARIABLES.COLORS.BLACK}>เจ้าของโครงการ/ผู้รับมอบอำนาจได้ลงลายมือชื่อและ รับชุดเอกสารเงื่อนไขการรับประกันอย่างถูกต้องครบถ้วนในวันที่มีการออกใบรับประกัน</Text>
            </Grid.Column>
          </Grid>
        </Section>
        <Section spacingBottom='4'>
          <Grid gutter='5'>
            <Grid.Column flex='none'>
              <Text regular8 lineheightTh color={VARIABLES.COLORS.BLACK}>-</Text>
            </Grid.Column>
            <Grid.Column flex='1'>
              <Text regular8 lineheightTh color={VARIABLES.COLORS.BLACK}>มีการกรอกข้อมูลข้างต้นครบถ้วนรวมถึงข้อมูลชื่อผู้ผลิต</Text>
            </Grid.Column>
          </Grid>
        </Section>
        <Section spacingBottom='4'>
          <Text regular8 lineheightTh color={VARIABLES.COLORS.BLACK}>
            ท่านยินยอมผูกพันตามที่การรับประกันนี้ขึ้นอยู่กับข้อกำหนดและเงื่อนไขที่ระบุในเอกสารชื่อ “ข้อกำหนดและเงื่อนไข” (ต่อไปนี้เรียกว่า “ข้อกำหนดและเงื่อนไข”) ตามที่ระบุไว้ในชุด เอกสารการรับประกัน กรุณาพิจารณา<br />อย่างละเอียดถี่ถ้วน และติดต่อผู้ผลิตทันทีหากว่าไม่ได้รับชุดเอกสารการรับประกันหรือข้อกำหนดและเงื่อนไข พร้อมกับผลิตภัณฑ์, เนื่องจาก การรับประกันนี้จะถือเป็นโมฆะหากไม่มีการปฏิบัติให้ถูกต้องตามข้อกำหนดและเงื่อนไข
          </Text>
        </Section>
        <Section spacingBottom='4'>
          <Text regular8 lineheightTh color={VARIABLES.COLORS.BLACK}>
            จากการให้ใบรับประกันนี้ ท่านตกลงว่าความรับผิดของ บริษัทฯ ภายใต้การรับประกันนี้จะถูกจำกัดอยู่ที่การซ่อมแซม หรือทดแทนผลิตภัณฑ์ใดๆ หรือเฉพาะส่วนของผลิตภัณฑ์ที่มีความบกพร่องหรือมีคุณสมบัติ<br />ไม่เป็นไป ตามที่ได้ให้การรับประกันนี้ไว้, หรือชำระค่าใช้จ่ายให้เป็นจำนวนที่เหมาะสมในการซ่อมแซมผลิตภัณฑ์ (ทางเลือก) ไม่ว่ากรณีใดๆท่านยอมรับและตกลงว่า บริษัทฯ ไม่สามารถรับผิดชอบหรือ<br />รับผิดต่อกรณีดังต่อไปนี้ (รวมถึง แต่ไม่จำกัดเพียง):
          </Text>
        </Section>
        <Section spacingBottom='4'>
          <Text regular8 lineheightTh italic color={VARIABLES.COLORS.BLACK}>
            (ก) การกระทำหรือละเว้นกระทำการใดๆของ, หรือผู้ผลิต หรือการรับประกันที่ออกโดยผู้ผลิตหรือบุคคลภายนอก (ข) ค่าจ้างแรงงานการทำงานรื้อถอน, ซ่อมแซมหรือทดแทนในส่วนของผลิตภัณฑ์ที่ชำรุดบกพร่อง<br /> (ค) กรณีบาดเจ็บของบุคคลใดๆ, ความเสียหายต่อทรัพย์สินหรือความเสียหายต่อเนื่อง, การสูญเสียกำไร, รายได้, การสูญเสียโอกาสทางธุรกิจหรือค่าใช้จ่ายทางอ้อมอื่นๆ, การสูญเสียหรือ ความเสียหายที่เกิดขึ้น<br />หรือเนื่องมาจากผลิตภัณฑ์
          </Text>
        </Section>
        <Section>
          <Text bold10 lineheightTh color={VARIABLES.COLORS.BLACK}>ข้อกำหนดและเงื่อนไขการรับประกัน</Text>
        </Section>
      </React.Fragment>
    )
  }
}

class WarrantyNoticeSmall extends React.Component {
  render() {
    return (
      <React.Fragment>
        <Section spacingBottom='-7'>
          <Text regular7 lineheightTh color={VARIABLES.COLORS.BLACK}>
            บริษัท เอ็นเอส บลูสโคป (ประเทศไทย) จำกัด ให้การรับประกันผลิตภัณฑ์ นับตั้งแต่วันที่มีการติดตั้ง รวมถึงปฏิบัติตามข้อกำหนดและเงื่อนไขที่ระบุไว้ ตามรายละเอียดข้อกำหนดและเงื่อนไขการรับประกันดังนี้
          </Text>
        </Section>
        <Section spacingBottom='-7'>
          <Text regular7 lineheightTh color={VARIABLES.COLORS.BLACK}>การให้การรับประกันนี้ขึ้นอยู่กับข้อกำหนด และเงื่อนไข (ดูรายละเอียด), ตามที่ระบุไว้ในชุดเอกสารการรับประกัน และจะไม่มีการรับประกันจนกว่า :</Text>
        </Section>
        <Section spacingBottom='-7'>
          <Grid gutter='5'>
            <Grid.Column flex='none'>
              <Text regular7 lineheightTh color={VARIABLES.COLORS.BLACK}>-</Text>
            </Grid.Column>
            <Grid.Column flex='1'>
              <Text regular7 lineheightTh color={VARIABLES.COLORS.BLACK}>มีการปฏิบัติตามรายละเอียดที่กำหนดเอาไว้อย่างถูกต้องครบถ้วน</Text>
            </Grid.Column>
          </Grid>
        </Section>
        <Section spacingBottom='-7'>
          <Grid gutter='5'>
            <Grid.Column flex='none'>
              <Text regular7 lineheightTh color={VARIABLES.COLORS.BLACK}>-</Text>
            </Grid.Column>
            <Grid.Column flex='1'>
              <Text regular7 lineheightTh color={VARIABLES.COLORS.BLACK}>เจ้าของโครงการ/ผู้รับมอบอำนาจได้ลงลายมือชื่อและ รับชุดเอกสารเงื่อนไขการรับประกันอย่างถูกต้องครบถ้วนในวันที่มีการออกใบรับประกัน</Text>
            </Grid.Column>
          </Grid>
        </Section>
        <Section>
          <Grid gutter='5'>
            <Grid.Column flex='none'>
              <Text regular7 lineheightTh color={VARIABLES.COLORS.BLACK}>-</Text>
            </Grid.Column>
            <Grid.Column flex='1'>
              <Text regular7 lineheightTh color={VARIABLES.COLORS.BLACK}>มีการกรอกข้อมูลข้างต้นครบถ้วนรวมถึงข้อมูลชื่อผู้ผลิต</Text>
            </Grid.Column>
          </Grid>
        </Section>
        <Section>
          <Text regular7 lineheightTh color={VARIABLES.COLORS.BLACK}>
            ท่านยินยอมผูกพันตามที่การรับประกันนี้ขึ้นอยู่กับข้อกำหนดและเงื่อนไขที่ระบุในเอกสารชื่อ “ข้อกำหนดและเงื่อนไข” (ต่อไปนี้เรียกว่า “ข้อกำหนดและเงื่อนไข”) ตามที่ระบุไว้ในชุด เอกสารการรับประกัน กรุณาพิจารณาอย่างละเอียดถี่ถ้วน และติดต่อผู้ผลิตทันที<br />หากว่าไม่ได้รับชุดเอกสารการรับประกันหรือข้อกำหนดและเงื่อนไข พร้อมกับผลิตภัณฑ์, เนื่องจาก การรับประกันนี้จะถือเป็นโมฆะหากไม่มีการปฏิบัติให้ถูกต้องตามข้อกำหนดและเงื่อนไข
          </Text>
        </Section>
        <Section>
          <Text regular7 lineheightTh color={VARIABLES.COLORS.BLACK}>
            จากการให้ใบรับประกันนี้ ท่านตกลงว่าความรับผิดของ บริษัทฯ ภายใต้การรับประกันนี้จะถูกจำกัดอยู่ที่การซ่อมแซม หรือทดแทนผลิตภัณฑ์ใดๆ หรือเฉพาะส่วนของผลิตภัณฑ์ที่มีความบกพร่องหรือมีคุณสมบัติไม่เป็นไป ตามที่ได้ให้การรับประกันนี้ไว้, หรือ<br />ชำระค่าใช้จ่ายให้เป็นจำนวนที่เหมาะสมในการซ่อมแซมผลิตภัณฑ์ (ทางเลือก) ไม่ว่ากรณีใดๆท่านยอมรับและตกลงว่า บริษัทฯ ไม่สามารถรับผิดชอบหรือรับผิดต่อกรณีดังต่อไปนี้ (รวมถึง แต่ไม่จำกัดเพียง):
          </Text>
        </Section>
        <Section>
          <Text regular7 lineheightTh italic color={VARIABLES.COLORS.BLACK}>
            (ก) การกระทำหรือละเว้นกระทำการใดๆของ, หรือผู้ผลิต หรือการรับประกันที่ออกโดยผู้ผลิตหรือบุคคลภายนอก (ข) ค่าจ้างแรงงานการทำงานรื้อถอน, ซ่อมแซมหรือทดแทนในส่วนของผลิตภัณฑ์ที่ชำรุดบกพร่อง (ค) กรณีบาดเจ็บของบุคคลใดๆ, ความเสียหาย<br />ต่อทรัพย์สินหรือความเสียหายต่อเนื่อง, การสูญเสียกำไร, รายได้, การสูญเสียโอกาสทางธุรกิจหรือค่าใช้จ่ายทางอ้อมอื่นๆ, การสูญเสียหรือ ความเสียหายที่เกิดขึ้นหรือเนื่องมาจากผลิตภัณฑ์
          </Text>
        </Section>
        <Section>
          <Text bold8 lineheightTh color={VARIABLES.COLORS.BLACK}>ข้อกำหนดและเงื่อนไขการรับประกัน</Text>
        </Section>
      </React.Fragment>
    )
  }
}

class WarrantySetupWarningListTwoColumn extends React.Component {
  render() {
    return (
      <React.Fragment>
        <Section paddingLeft='10' spacingBottom='4'>
          <Grid>
            <Grid.Column flex='none'>
              <Section width='15'>
                <div className="warranty-text is-xs">(a)</div>
              </Section>
            </Grid.Column>
            <Grid.Column flex='1'>
              <div className="warranty-text is-xs">ต้องจัดเก็บรักษาผลิตภัณฑ์ให้อยู่ในสภาพแห้งตลอดเวลาทั้งในขณะกองเก็บหรืออยู่ในมัดก่อนการติดตั้ง<br /> สำหรับแผ่นที่เปียกควรรีบจัดแยกและทำให้แห้งเพื่อลดความเสี่ยงจากการเร่งปฏิกิริยาการเกิดสนิม</div>
            </Grid.Column>
          </Grid>
        </Section>
        <Section paddingLeft='10' spacingBottom='4'>
          <Grid>
            <Grid.Column flex='none'>
              <Section width='15'>
                <div className="warranty-text is-xs">(b)</div>
              </Section>
            </Grid.Column>
            <Grid.Column flex='1'>
              <div className="warranty-text is-xs">ไม่ตัดแผ่นโดยใช้เครื่องตัดหรือขัดแบบเจียระไน เนื่องจากความร้อนของเศษเหล็กหรือฝุ่นเหล็กจากการตัด<br />อาจทำให้ผิวเคลือบของผลิตภัณฑ์เกิดความเสียหาย </div>
            </Grid.Column>
          </Grid>
        </Section>
        <Section paddingLeft='10'>
          <Grid>
            <Grid.Column flex='none'>
              <Section width='15'>
                <div className="warranty-text is-xs">(c)</div>
                <Text regular7 lineheightTh color={VARIABLES.COLORS.BLACK}></Text>
              </Section>
            </Grid.Column>
            <Grid.Column flex='1'>
              <div className="warranty-text is-xs">หลังการติดตั้งควรทำความสะอาด เศษโลหะ หรือฝุ่นละอองต่างๆที่ตกค้างอยู่บนผลิตภัณฑ์เป็น<br />ประจำทุกวัน เพื่อลดปัจจัยความเสี่ยงของการเกิดสนิมจากการสะสมของเศษโละหะ หรือฝุ่นละอองต่างๆ</div>
            </Grid.Column>
          </Grid>
        </Section>
      </React.Fragment>
    )
  }
}

class WarrantyDecayListTwoColumn extends React.Component {
  render() {
    return (
      <React.Fragment>
        <Section paddingLeft='10' spacingBottom='4'>
          <Grid>
            <Grid.Column flex='none'>
              <Section width='15'>
                <div className="warranty-text is-xs">(a)</div>
              </Section>
            </Grid.Column>
            <Grid.Column flex='1'>
              <div className="warranty-text is-xs">ความเสียหายจากการใช้เครื่องมือ เครื่องจักรกล สารเคมี หรือความเสียหายอื่นๆ ที่ไม่ได้เกิดจาก<br />ผลิตภัณฑ์ที่เกิดขึ้นก่อน ระหว่างหรือภายหลังการติดตั้ง </div>
            </Grid.Column>
          </Grid>
        </Section>
        <Section paddingLeft='10' spacingBottom='4'>
          <Grid>
            <Grid.Column flex='none'>
              <Section width='15'>
                <div className="warranty-text is-xs">(b)</div>
              </Section>
            </Grid.Column>
            <Grid.Column flex='1'>
              <div className="warranty-text is-xs">ความเสียหายจากการสัมผัสกับตัวทำปฎิกริยาเคมี, ไอระเหย, ควัน, ของเหลวหรือของแข็งนอกเหนือจาก<br />ฝนตกตามธรรมชาติ</div>
            </Grid.Column>
          </Grid>
        </Section>
        <Section paddingLeft='10' spacingBottom='4'>
          <Grid>
            <Grid.Column flex='none'>
              <Section width='15'>
                <div className="warranty-text is-xs">(c)</div>
              </Section>
            </Grid.Column>
            <Grid.Column flex='1'>
              <div className="warranty-text is-xs">การขาดการทำความสะอาดในบริเวณที่ไม่ถูกฝนชะล้างตามธรรมชาติ และไม่มีการทำความสะอาด<br />อย่างสม่ำเสมอ</div>
            </Grid.Column>
          </Grid>
        </Section>
        <Section paddingLeft='10' spacingBottom='4'>
          <Grid>
            <Grid.Column flex='none'>
              <Section width='15'>
                <div className="warranty-text is-xs">(d)</div>
              </Section>
            </Grid.Column>
            <Grid.Column flex='1'>
              <div className="warranty-text is-xs">การสัมผัสกับดิน ฝุ่น เถ้า ปุ๋ย หรือวัสดุที่มีความชื้น</div>
            </Grid.Column>
          </Grid>
        </Section>
        <Section paddingLeft='10' spacingBottom='4'>
          <Grid>
            <Grid.Column flex='none'>
              <Section width='15'>
                <div className="warranty-text is-xs">(e)</div>
              </Section>
            </Grid.Column>
            <Grid.Column flex='1'>
              <div className="warranty-text is-xs">พื้นที่ของผลิตภัณฑ์ที่สัมผัสกับโลหะที่ไม่มีคุณสมบัติเทียบเท่า (เช่น ตะกั่ว, ทองแดง, ถ่าน, กราไฟต์ หรือ<br /> เหล็กกล้าไร้สนิม) หรือพื้นที่ที่รองรับของเหลวที่ไหลมาจากบริเวณที่มีวัสดุดังกล่าว</div>
            </Grid.Column>
          </Grid>
        </Section>
        <Section paddingLeft='10' spacingBottom='4'>
          <Grid>
            <Grid.Column flex='none'>
              <Section width='15'>
                <div className="warranty-text is-xs">(f)</div>
              </Section>
            </Grid.Column>
            <Grid.Column flex='1'>
              <div className="warranty-text is-xs">ขาดการกำจัดเศษต่างๆ การจัดการเพื่อให้เกิดการระบาย หรือการไหลของน้ำได้โดยสะดวก <br />รวมทั้งการควบแน่นของไอน้ำบนทุกพื้นผิวของผลิตภัณฑ์</div>
            </Grid.Column>
          </Grid>
        </Section>
        <Section paddingLeft='10' spacingBottom='4'>
          <Grid>
            <Grid.Column flex='none'>
              <Section width='15'>
                <div className="warranty-text is-xs">(g)</div>
              </Section>
            </Grid.Column>
            <Grid.Column flex='1'>
              <div className="warranty-text is-xs">การผุกร่อน ผุพังของผลิตภัณฑ์ที่เกิดจากการสัมผัสกับเศษไม้ กิ่งไม้สดเปียก หรือไม้ที่ผ่านกรรมวิธีทางเคมี</div>
            </Grid.Column>
          </Grid>
        </Section>
        <Section paddingLeft='10' spacingBottom='4'>
          <Grid>
            <Grid.Column flex='none'>
              <Section width='15'>
                <div className="warranty-text is-xs">(h)</div>
              </Section>
            </Grid.Column>
            <Grid.Column flex='1'>
              <div className="warranty-text is-xs">การติดตั้งอยู่ในบริเวณที่มีสภาพแวดล้อมรุนแรง ได้แก่ อยู่ติดทะเลระยะทางน้อยกว่า 1 กิโลเมตร, <br />อยู่ในเขตนิคมอุตสาหกรรม หรือสภาพที่มีการกัดกร่อนสูง</div>
            </Grid.Column>
          </Grid>
        </Section>
        <Section paddingLeft='10' spacingBottom='4'>
          <Grid>
            <Grid.Column flex='none'>
              <Section width='15'>
                <div className="warranty-text is-xs">(i)</div>
              </Section>
            </Grid.Column>
            <Grid.Column flex='1'>
              <div className="warranty-text is-xs">พายุ หรือภัยพิบัติทางธรรมชาติ</div>
            </Grid.Column>
          </Grid>
        </Section>
        <Section paddingLeft='10' spacingBottom='4'>
          <Grid>
            <Grid.Column flex='none'>
              <Section width='15'>
                <div className="warranty-text is-xs">(j)</div>
              </Section>
            </Grid.Column>
            <Grid.Column flex='1'>
              <div className="warranty-text is-xs">ความบกพร่องที่เกิดจากการรีดขึ้นรูป หรือการติดตั้งผลิตภัณฑ์</div>
            </Grid.Column>
          </Grid>
        </Section>
        <Section paddingLeft='10' spacingBottom='4'>
          <Grid>
            <Grid.Column flex='none'>
              <Section width='15'>
                <div className="warranty-text is-xs">(k)</div>
              </Section>
            </Grid.Column>
            <Grid.Column flex='1'>
              <div className="warranty-text is-xs">น้ำขังอันเกิดจากความลาดเอียงของหลังคาต่ำ หรือการติดตั้งที่ไม่ได้มาตรฐาน</div>
            </Grid.Column>
          </Grid>
        </Section>
      </React.Fragment>
    )
  }
}

class WarrantyNoticeTwoColumn extends React.Component {
  render() {
    return (
      <React.Fragment>
        <Section spacingBottom='4'>
          <div className="warranty-text is-xs">บริษัท เอ็นเอส บลูสโคป (ประเทศไทย) จำกัด ให้การรับประกันผลิตภัณฑ์ นับตั้งแต่วันที่มีการติดตั้ง รวมถึง<br />ปฏิบัติตามข้อกำหนดและเงื่อนไขที่ระบุไว้ ตามรายละเอียดข้อกำหนดและเงื่อนไขการรับประกันดังนี้</div>
        </Section>
        <Section spacingBottom='2'>
          <div className="warranty-text is-xs">การให้การรับประกันนี้ขึ้นอยู่กับข้อกำหนด และเงื่อนไข (ดูรายละเอียด), ตามที่ระบุไว้ในชุดเอกสารการรับประกัน<br /> และจะไม่มีการรับประกันจนกว่า :</div>
        </Section>
        <Section spacingBottom='2'>
          <Grid gutter='5'>
            <Grid.Column flex='none'>
              <div className="warranty-text is-xs">-</div>
            </Grid.Column>
            <Grid.Column flex='1'>
              <div className="warranty-text is-xs">มีการปฏิบัติตามรายละเอียดที่กำหนดเอาไว้อย่างถูกต้องครบถ้วน</div>
            </Grid.Column>
          </Grid>
        </Section>
        <Section spacingBottom='2'>
          <Grid gutter='5'>
            <Grid.Column flex='none'>
              <div className="warranty-text is-xs">-</div>
            </Grid.Column>
            <Grid.Column flex='1'>
              <div className="warranty-text is-xs">เจ้าของโครงการ/ผู้รับมอบอำนาจได้ลงลายมือชื่อและ รับชุดเอกสารเงื่อนไขการรับประกันอย่างถูกต้อง<br />ครบถ้วนในวันที่มีการออกใบรับประกัน</div>
            </Grid.Column>
          </Grid>
        </Section>
        <Section spacingBottom='4'>
          <Grid gutter='5'>
            <Grid.Column flex='none'>
              <div className="warranty-text is-xs">-</div>
            </Grid.Column>
            <Grid.Column flex='1'>
              <div className="warranty-text is-xs">มีการกรอกข้อมูลข้างต้นครบถ้วนรวมถึงข้อมูลชื่อผู้ผลิต</div>
            </Grid.Column>
          </Grid>
        </Section>
        <Section spacingBottom='4'>
          <div className="warranty-text is-xs">ท่านยินยอมผูกพันตามที่การรับประกันนี้ขึ้นอยู่กับข้อกำหนดและเงื่อนไขที่ระบุในเอกสารชื่อ “ข้อกำหนดและ<br />เงื่อนไข” (ต่อไปนี้เรียกว่า “ข้อกำหนดและเงื่อนไข”) ตามที่ระบุไว้ในชุด เอกสารการรับประกัน กรุณาพิจารณาอย่าง<br />ละเอียดถี่ถ้วน และติดต่อผู้ผลิตทันทีหากว่าไม่ได้รับชุดเอกสารการรับประกันหรือข้อกำหนดและเงื่อนไข<br /> พร้อมกับผลิตภัณฑ์, เนื่องจากการรับประกันนี้จะถือเป็นโมฆะหากไม่มีการปฏิบัติให้ถูกต้อง<br /> ตามข้อกำหนดและเงื่อนไข</div>
        </Section>
        <Section spacingBottom='4'>
          <div className="warranty-text is-xs">จากการให้ใบรับประกันนี้ ท่านตกลงว่าความรับผิดของ บริษัทฯ ภายใต้การรับประกันนี้จะถูกจำกัดอยู่ที่<br /> การซ่อมแซม หรือทดแทนผลิตภัณฑ์ใดๆ หรือเฉพาะส่วนของผลิตภัณฑ์ที่มีความบกพร่องหรือมีคุณสมบัติ<br /> ไม่เป็นไป ตามที่ได้ให้การรับประกันนี้ไว้, หรือชำระค่าใช้จ่ายให้เป็นจำนวนที่เหมาะสมในการซ่อมแซม<br /> ผลิตภัณฑ์ (ทางเลือก) ไม่ว่ากรณีใดๆท่านยอมรับและตกลงว่า บริษัทฯ ไม่สามารถรับผิดชอบหรือ<br /> รับผิดต่อกรณีดังต่อไปนี้ (รวมถึง แต่ไม่จำกัดเพียง):</div>
        </Section>
        <Section spacingBottom='4'>
          <div className="warranty-text is-xs">(ก) การกระทำหรือละเว้นกระทำการใดๆของ, หรือผู้ผลิต หรือการรับประกันที่ออกโดยผู้ผลิตหรือบุคคลภายนอก (ข) ค่าจ้างแรงงานการทำงานรื้อถอน, ซ่อมแซมหรือทดแทนในส่วนของผลิตภัณฑ์ที่ชำรุดบกพร่อง<br /> (ค) กรณีบาดเจ็บของบุคคลใดๆ, ความเสียหายต่อทรัพย์สินหรือความเสียหายต่อเนื่อง, การสูญเสียกำไร, รายได้, การสูญเสียโอกาสทางธุรกิจหรือค่าใช้จ่ายทางอ้อมอื่นๆ, การสูญเสียหรือ ความเสียหายที่เกิดขึ้นหรือเนื่องมาจาก<br />ผลิตภัณฑ์</div>
        </Section>
        <Section spacingBottom='4'>
          <Text bold10 lineheightTh color={VARIABLES.COLORS.BLACK}>ข้อกำหนดและเงื่อนไขการรับประกัน</Text>
        </Section>
      </React.Fragment>
    )
  }
}

export class Warranty extends React.PureComponent {
  render() {
    const {
      className,
      ui,
      children,
      id,
      supplierNameNote,
      logo,
      warrantyNumber,
      setupDate,
      projectName,
      phone,
      projectAddress,
      bsadName,
      bsadMobile,
      bsadAddress,
      siteInspection,
      warrantyCreateDate,
      blueScopeZacs,
      colorbond,
      crp,
      jingjoeLek,
      prima,
      superDymaDk,
      superDymaPl,
      trueCore,
      zincalume,
      dataGroupList,
      transactionId,
      purchaseDate,
      projectOwnerName,
      projectOwnerPhoneNumber,
      tokoAdName,
      tokoAdPhoneNumber,
      contractorName,
      contractorPhoneNumber,
      buildingAreaM2,
      applicationList,
    } = this.props

    // props for css classes
    const classes = ClassNames(
      'warranty',
      // {'is-modifier-name': modifierName},
      className
    )
    let cntRow = 0;
    return (
      <React.Fragment>
        {(() => {
          switch (ui) {
            case 'bluescope':
              return (
                <React.Fragment>
                  <WarrantyWrapper
                    className={classes}
                  >
                    <Section ui='idWarranty'>
                      {id}
                    </Section>
                    <Section spacingBottom='45' justify='center'>
                      <Image ui='logoBlueScope' />
                    </Section>
                    <Section spacingBottom='45' justify='center'>
                      <Text bold36 color={VARIABLES.COLORS.BLACK}>WARRANTY</Text>
                    </Section>
                    {children}
                    <Section spacingBottom='45'>
                      <Text
                        regular14
                        lineheightTh
                        color={VARIABLES.COLORS.BLACK}
                      >
                        NOTE: This warranty is provided to, and can only be actioned by,&nbsp;
                        <Text bold16 lineheightTh italic color={VARIABLES.COLORS.BLACK}>
                          {supplierNameNote}
                        </Text>
                        &nbsp;subject to the express terms and conditions attached. Failure, despite best endeavours, to identify problems with environment and/or installation during project inspection shall not preclude or limit the operation of the terms and conditions attached.
                      </Text>
                    </Section>
                    <Section spacingBottom='30'>
                      <Section justify='center'>
                        <Text
                          bold14
                          lineheightTh

                          color={VARIABLES.COLORS.BLACK}
                        >
                          NS BlueScope (Thailand) Limited
                        </Text>
                      </Section>
                      <Section justify='center'>
                        <Text
                          regular14
                          lineheightTh

                          color={VARIABLES.COLORS.BLACK}
                          align='center'
                        >
                          7th Floor, Bangkok Thai Tower, 108 Rangnam road,
                        </Text>
                      </Section>
                      <Section justify='center'>
                        <Text
                          regular14
                          lineheightTh

                          color={VARIABLES.COLORS.BLACK}
                          align='center'
                        >
                          Rajthevi Bangkok 10400 Thailand.
                        </Text>
                      </Section>
                      <Section justify='center'>
                        <Text
                          regular14
                          lineheightTh

                          color={VARIABLES.COLORS.BLACK}
                        >
                          Tel: (66 2) 333 3000, Fax: (66 2) 333 3002
                        </Text>
                      </Section>
                    </Section>
                    <Section spacingBottom='30'>
                      <Section justify='center'>
                        <Text
                          regular14
                          lineheightTh

                          color={VARIABLES.COLORS.BLACK}
                          align='center'
                        >
                          COLORBOND®, Clean COLORBOND®, ZINCULUME®, P-ZACS® and ZACS® are registered trademarks of NS BlueScope (Thailand) Limited.
                        </Text>
                      </Section>
                      <Section justify='center'>
                        <Text
                          regular14
                          lineheightTh

                          color={VARIABLES.COLORS.BLACK}
                          align='center'
                        >
                          BlueScope is a trademarks of NS BlueScope (Thailand) Limited
                        </Text>
                      </Section>
                    </Section>
                  </WarrantyWrapper>
                </React.Fragment>
              )
            case 'template':
              return (
                <WarrantyWrapper className={classes}>
                  <Section spacingBottom='15'>
                    <Grid justify='space-between'>
                      <Grid.Column>
                        <Section width='200'>
                          <Image ui='logoBlueScope' />
                        </Section>
                      </Grid.Column>
                      <Grid.Column justify='center'>
                        <Section paddingTop='13'>
                          <Text bold16 color={VARIABLES.COLORS.PRIMARY_2}>Warranty</Text>
                        </Section>
                      </Grid.Column>
                      <Grid.Column>
                        <Section
                          width='200'
                          justify='flex-end'
                          paddingTop={(colorbond || crp || prima || superDymaDk || superDymaPl || trueCore || zincalume) && '5'}
                        >
                          <Image ui={logo} />
                        </Section>
                      </Grid.Column>
                    </Grid>
                  </Section>
                  <Section justify='space-between'>
                    <Text bold10 color={VARIABLES.COLORS.BLACK}>
                      ใบรับประกันเลขที่&nbsp;&nbsp;&nbsp;{warrantyNumber}
                    </Text>
                    <Text bold10 color={VARIABLES.COLORS.BLACK}>
                      วันที่ติดตั้งสินค้า&nbsp;&nbsp;&nbsp;{setupDate}
                    </Text>
                  </Section>
                  <Section>
                    <Text bold10 color={VARIABLES.COLORS.BLACK}>ชื่อโครงการหรือ เจ้าของ</Text>
                    &nbsp;
                    <Text regular10 color={VARIABLES.COLORS.BLACK}>{projectName}</Text>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <Text bold10 color={VARIABLES.COLORS.BLACK}>โทรศัพท์</Text>
                    &nbsp;
                    <Text regular10 color={VARIABLES.COLORS.BLACK}>{phone}</Text>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <Text bold10 color={VARIABLES.COLORS.BLACK}>ที่อยู่ ที่สินค้าติดตั้ง</Text>
                    &nbsp;
                    <Text regular10 color={VARIABLES.COLORS.BLACK}>{projectAddress}</Text>
                  </Section>
                  <Section spacingBottom='8'>
                    <Text bold10 color={VARIABLES.COLORS.BLACK}>ชื่อผู้ผลิตแผ่นหลังคาเหล็ก/ผู้จำหน่าย</Text>
                    &nbsp;
                    <Text regular10 color={VARIABLES.COLORS.BLACK}>{bsadName}</Text>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <Text bold10 color={VARIABLES.COLORS.BLACK}>โทรศัพท์</Text>
                    &nbsp;
                    <Text regular10 color={VARIABLES.COLORS.BLACK}>{bsadMobile}</Text>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <Text bold10 color={VARIABLES.COLORS.BLACK}>ที่อยู่</Text>
                    &nbsp;
                    <Text regular10 color={VARIABLES.COLORS.BLACK}>{bsadAddress}</Text>
                  </Section>
                  <Section spacingBottom={(osName === 'Mac OS') ? '3' : '0'}>
                    <Text bold10 lineheightTh color={VARIABLES.COLORS.BLACK}>รายละเอียดสินค้า</Text>
                  </Section>
                  <Section spacingBottom={(osName === 'Mac OS') ? '4' : '3'}>
                    {/* Note: content width = 334 */}
                    <Table fluid warranty>
                      <Table.Section>
                        <Table.Row>
                          <Table.Column className='is-head-column' alignCenter maxWidth='310' minWidth='310' paddingTop={(osName === 'Mac OS') ? '3' : '9'} paddingBottom='4' paddingHorizontal='2'>
                            <Text bold10 singleLine color={VARIABLES.COLORS.BLACK}>Coil Description</Text>
                          </Table.Column>
                          <Table.Column className='is-head-column' alignCenter maxWidth='110' minWidth='110' paddingTop={(osName === 'Mac OS') ? '0' : '4'} paddingBottom={(osName === 'Mac OS') ? '8' : '4'} paddingHorizontal='2'>
                            <Text bold10 singleLine color={VARIABLES.COLORS.BLACK}>ความหนารวม<br />(TCT หรือ APT) มม.</Text>
                          </Table.Column>
                          <Table.Column className='is-head-column' alignCenter maxWidth='90' minWidth='90' paddingTop={(osName === 'Mac OS') ? '0' : '4'} paddingBottom={(osName === 'Mac OS') ? '8' : '4'} paddingHorizontal='2'>
                            <Text bold10 singleLine color={VARIABLES.COLORS.BLACK}>ความหนาเหล็ก<br />(BMT) มม.</Text>
                          </Table.Column>
                          <Table.Column className='is-head-column' alignCenter maxWidth='90' minWidth='90' paddingTop={(osName === 'Mac OS') ? '0' : '4'} paddingBottom={(osName === 'Mac OS') ? '8' : '4'} paddingHorizontal='2'>
                            <Text bold10 singleLine color={VARIABLES.COLORS.BLACK}>ความยาวสินค้า<br />เมตร</Text>
                          </Table.Column>
                          <Table.Column className='is-head-column' alignCenter maxWidth='134' minWidth='134' paddingTop={(osName === 'Mac OS') ? '3' : '9'} paddingBottom='4' paddingHorizontal='2ß'>
                            <Text bold10 singleLine color={VARIABLES.COLORS.BLACK}>Coil ID</Text>
                          </Table.Column>
                        </Table.Row>
                      </Table.Section>
                      {dataGroupList.map((e, i) => {
                        return (
                          <Table.Section key={i}>
                            {e.displayCoil.map((ec, ic) => {
                              return (
                                <Table.Row key={ic}>
                                  <Table.Column className='is-body-column' maxWidth='310' minWidth='310' paddingTop={(osName === 'Mac OS') ? '0' : '2'} paddingBottom={(osName === 'Mac OS') ? '8' : '2'} paddingHorizontal='2'>
                                    <Text bold10 color={VARIABLES.COLORS.BLACK}>
                                      {e.coilNameList[ic]}
                                    </Text>
                                  </Table.Column>
                                  <Table.Column className='is-body-column' alignCenter maxWidth='110' minWidth='110' paddingTop={(osName === 'Mac OS') ? '0' : '2'} paddingBottom={(osName === 'Mac OS') ? '8' : '2'} paddingHorizontal='2'>
                                    <Text regular10 color={VARIABLES.COLORS.BLACK}>
                                      {e.thicknessList[ic]}
                                    </Text>
                                  </Table.Column>
                                  <Table.Column className='is-body-column' alignCenter maxWidth='90' minWidth='90' paddingTop={(osName === 'Mac OS') ? '0' : '2'} paddingBottom={(osName === 'Mac OS') ? '8' : '2'} paddingHorizontal='2'>
                                    <Text regular10 color={VARIABLES.COLORS.BLACK}>
                                      {jingjoeLek ? '-' : e.bmtList[ic]}
                                    </Text>
                                  </Table.Column>
                                  <Table.Column className='is-body-column' alignCenter maxWidth='90' minWidth='90' paddingTop={(osName === 'Mac OS') ? '0' : '2'} paddingBottom={(osName === 'Mac OS') ? '8' : '2'} paddingHorizontal='2'>
                                    <Text regular10 color={VARIABLES.COLORS.BLACK}>
                                      {e.coilOrderMList[ic]}
                                    </Text>
                                  </Table.Column>
                                  <Table.Column className='is-body-column' alignCenter maxWidth='134' minWidth='134' paddingTop={(osName === 'Mac OS') ? '0' : '2'} paddingBottom={(osName === 'Mac OS') ? '8' : '2'} paddingHorizontal='2'>
                                    <Text regular10 color={VARIABLES.COLORS.BLACK}>
                                      {e.coilIdList[ic]}
                                    </Text>
                                  </Table.Column>
                                </Table.Row>
                              )
                            })}
                          </Table.Section>
                        )
                      })}
                    </Table>
                  </Section>
                  <Section spacingBottom={(osName === 'Mac OS') ? '0' : '-3'}>
                    <Text bold10 lineheightTh color={VARIABLES.COLORS.BLACK}>ใช้สำหรับในงาน</Text>
                  </Section>
                  <Section spacingBottom='4'>
                    <ShapeContainer fluid paddingTop={(osName === 'Mac OS') ? '0' : '2'} paddingBottom={(osName === 'Mac OS') ? '4' : '2'} paddingHorizontal='2' borderWidth='1' borderStyle='solid' borderColor={VARIABLES.COLORS.BLACK}>
                      <Grid gutter='30'>
                        {dataGroupList.map((e, i) => {
                          return (
                            <Grid.Column key={i} grid='12'>
                              <Section spacingTop='-5' spacingBottom='-5'>
                                <Text bold10 color={VARIABLES.COLORS.BLACK}>
                                  {e.coilName}
                                </Text>
                              </Section>
                              <Section>
                                <Grid gutter='15'>
                                  <Grid.Column>
                                    <Grid gutter='20'>
                                      <Grid.Column>
                                        <Text regular10 color={VARIABLES.COLORS.BLACK}>หลังคาและผนัง</Text>
                                      </Grid.Column>
                                      <Grid.Column>
                                        <Text regular10 color={VARIABLES.COLORS.BLACK}>{e.roof ? e.roof : '-'} &nbsp;เมตร</Text>
                                      </Grid.Column>
                                    </Grid>
                                  </Grid.Column>
                                  <Grid.Column>
                                    <Text regular10 color={VARIABLES.COLORS.BLACK}>/</Text>
                                  </Grid.Column>
                                  <Grid.Column>
                                    <Grid gutter='20'>
                                      <Grid.Column>
                                        <Text regular10 color={VARIABLES.COLORS.BLACK}>แป</Text>
                                      </Grid.Column>
                                      <Grid.Column>
                                        <Text regular10 color={VARIABLES.COLORS.BLACK}>{e.purlinGirt ? e.purlinGirt : '-'} &nbsp;เมตร</Text>
                                      </Grid.Column>
                                    </Grid>
                                  </Grid.Column>
                                  <Grid.Column>
                                    <Text regular10 color={VARIABLES.COLORS.BLACK}>/</Text>
                                  </Grid.Column>
                                  <Grid.Column>
                                    <Grid gutter='20'>
                                      <Grid.Column>
                                        <Text regular10 color={VARIABLES.COLORS.BLACK}>โครงเคร่า</Text>
                                      </Grid.Column>
                                      <Grid.Column>
                                        <Text regular10 color={VARIABLES.COLORS.BLACK}>{e.truFrame ? e.truFrame : '-'} &nbsp;เมตร</Text>
                                      </Grid.Column>
                                    </Grid>
                                  </Grid.Column>
                                  <Grid.Column>
                                    <Text regular10 color={VARIABLES.COLORS.BLACK}>/</Text>
                                  </Grid.Column>
                                  <Grid.Column>
                                    <Grid gutter='20'>
                                      <Grid.Column>
                                        <Text regular10 color={VARIABLES.COLORS.BLACK}>ผนังห้องเย็น</Text>
                                      </Grid.Column>
                                      <Grid.Column>
                                        <Text regular10 color={VARIABLES.COLORS.BLACK}>{e.coolRoom ? e.coolRoom : '-'} &nbsp;เมตร</Text>
                                      </Grid.Column>
                                    </Grid>
                                  </Grid.Column>
                                  <Grid.Column>
                                    <Text regular10 color={VARIABLES.COLORS.BLACK}>/</Text>
                                  </Grid.Column>
                                  <Grid.Column>
                                    <Grid gutter='20'>
                                      <Grid.Column>
                                        <Text regular10 color={VARIABLES.COLORS.BLACK}>แผ่นพื้น</Text>
                                      </Grid.Column>
                                      <Grid.Column>
                                        <Text regular10 color={VARIABLES.COLORS.BLACK}>{e.decking ? e.decking : '-'} &nbsp;เมตร</Text>
                                      </Grid.Column>
                                    </Grid>
                                  </Grid.Column>
                                </Grid>
                              </Section>
                            </Grid.Column>
                          )
                        })}
                      </Grid>
                    </ShapeContainer>
                  </Section>
                  <Section spacingBottom={(osName === 'Mac OS') ? '0' : '-3'}>
                    <Text bold10 lineheightTh color={VARIABLES.COLORS.BLACK}>การรับประกัน</Text>
                  </Section>
                  <Section spacingBottom='-30'>
                    <ShapeContainer fluid borderWidth='1' borderStyle='solid' borderColor={VARIABLES.COLORS.BLACK}>
                      <Section paddingHorizontal='2' borderBottomWidth='1' borderBottomStyle='solid' borderBottomColor={VARIABLES.COLORS.BLACK}>
                        <Section spacingTop='-3' spacingBottom='-5'>
                          <Text bold10 lineheightTh color={VARIABLES.COLORS.BLACK}>
                            {(blueScopeZacs || colorbond || jingjoeLek || prima || zincalume) && 'หลังคาและผนัง'}
                            {crp && 'ผนังห้องเย็น'}
                            {(superDymaDk || superDymaPl) && 'แผ่นพื้น'}
                            {trueCore && 'โครงสร้างไม่พังอันเนื่องมาจากการกัดกร่อน เป็นระยะเวลาไม่น้อยกว่า'}
                          </Text>
                        </Section>

                        {/* blueScopeZacs */}
                        {blueScopeZacs &&
                          <React.Fragment>
                            <Section spacingBottom='-5'>
                              <Grid justify='space-between'>
                                <Grid.Column flex='1'>
                                  <Grid gutter='4'>
                                    <Grid.Column flex='none'>
                                      <Text regular10 lineheightTh color={VARIABLES.COLORS.BLACK}>-</Text>
                                    </Grid.Column>
                                    <Grid.Column flex='1'>
                                      <Text regular10 lineheightTh color={VARIABLES.COLORS.BLACK}>บลูสโคป แซคส์® - จะไม่เป็นรูพรุนเนื่องจากการกัดกร่อน เป็นระยะเวลา ไม่น้อยกว่า</Text>
                                    </Grid.Column>
                                  </Grid>
                                </Grid.Column>
                                <Grid.Column flex='none'>
                                  <Section width='100' justify='flex-end'>
                                    <Text regular10 lineheightTh color={VARIABLES.COLORS.BLACK}>10 ปี</Text>
                                  </Section>
                                </Grid.Column>
                              </Grid>
                            </Section>
                            <Section spacingBottom='-5'>
                              <Grid justify='space-between'>
                                <Grid.Column flex='1'>
                                  <Grid gutter='4'>
                                    <Grid.Column flex='none'>
                                      <Text regular10 lineheightTh color={VARIABLES.COLORS.BLACK}>-</Text>
                                    </Grid.Column>
                                    <Grid.Column flex='1'>
                                      <Text regular10 lineheightTh color={VARIABLES.COLORS.BLACK}>บลูสโคป แซคส์® คูล, เนเชอรัล และแดซเซิล - จะไม่เป็นรูพรุนเนื่องจากการกัดกร่อน เป็นระยะเวลาไม่น้อยกว่า</Text>
                                    </Grid.Column>
                                  </Grid>
                                </Grid.Column>
                                <Grid.Column flex='none'>
                                  <Section width='100' justify='flex-end'>
                                    <Text regular10 lineheightTh color={VARIABLES.COLORS.BLACK}>12 ปี</Text>
                                  </Section>
                                </Grid.Column>
                              </Grid>
                            </Section>
                            <Section spacingBottom='-5'>
                              <Grid justify='space-between'>
                                <Grid.Column flex='1'>
                                  <Grid gutter='4'>
                                    <Grid.Column flex='none'>
                                      <Text regular10 lineheightTh color={VARIABLES.COLORS.BLACK}>-</Text>
                                    </Grid.Column>
                                    <Grid.Column flex='1'>
                                      <Text regular10 lineheightTh color={VARIABLES.COLORS.BLACK}>บลูสโคป แซคส์® คูล, เนเชอรัล และแดซเซิล - สีจะไม่ซีดจางเกินกว่าค่าความแตกต่างของสีที่กำหนด เป็นระยะเวลาไม่น้อยกว่า</Text>
                                    </Grid.Column>
                                  </Grid>
                                </Grid.Column>
                                <Grid.Column flex='none'>
                                  <Section width='100' justify='flex-end'>
                                    <Text regular10 lineheightTh color={VARIABLES.COLORS.BLACK}>5 ปี</Text>
                                  </Section>
                                </Grid.Column>
                              </Grid>
                            </Section>
                          </React.Fragment>
                        }

                        {/* colorbond */}
                        {colorbond &&
                          <React.Fragment>
                            <Section spacingBottom='-5'>
                              <Grid justify='space-between'>
                                <Grid.Column flex='1'>
                                  <Grid gutter='4'>
                                    <Grid.Column flex='none'>
                                      <Text regular10 lineheightTh color={VARIABLES.COLORS.BLACK}>-</Text>
                                    </Grid.Column>
                                    <Grid.Column flex='1'>
                                      <Text regular10 lineheightTh color={VARIABLES.COLORS.BLACK}>จะไม่เป็นรูพรุนเนือ่งจากการกัดกร่อนเป็นระยะเวลา ไม่น้อยกว่า</Text>
                                    </Grid.Column>
                                  </Grid>
                                </Grid.Column>
                                <Grid.Column flex='none'>
                                  <Section width='100' justify='flex-end'>
                                    <Text regular10 lineheightTh color={VARIABLES.COLORS.BLACK}>30 ปี</Text>
                                  </Section>
                                </Grid.Column>
                              </Grid>
                            </Section>
                            <Section spacingBottom='-5'>
                              <Grid justify='space-between'>
                                <Grid.Column flex='1'>
                                  <Grid gutter='4'>
                                    <Grid.Column flex='none'>
                                      <Text regular10 lineheightTh color={VARIABLES.COLORS.BLACK}>-</Text>
                                    </Grid.Column>
                                    <Grid.Column flex='1'>
                                      <Text regular10 lineheightTh color={VARIABLES.COLORS.BLACK}>การไม่หลุดร่อนหรือหลุดเป็นเกล็ดของสี ไม่น้อยกว่า</Text>
                                    </Grid.Column>
                                  </Grid>
                                </Grid.Column>
                                <Grid.Column flex='none'>
                                  <Section width='100' justify='flex-end'>
                                    <Text regular10 lineheightTh color={VARIABLES.COLORS.BLACK}>15 ปี</Text>
                                  </Section>
                                </Grid.Column>
                              </Grid>
                            </Section>
                            <Section spacingBottom='-5'>
                              <Grid justify='space-between'>
                                <Grid.Column flex='1'>
                                  <Grid gutter='4'>
                                    <Grid.Column flex='none'>
                                      <Text regular10 lineheightTh color={VARIABLES.COLORS.BLACK}>-</Text>
                                    </Grid.Column>
                                    <Grid.Column flex='1'>
                                      <Text regular10 lineheightTh color={VARIABLES.COLORS.BLACK}>สีไม่ซีดจางหรือไม่หลุดเป็นผงอย่างรุนแรง ไม่น้อยกว่า</Text>
                                    </Grid.Column>
                                  </Grid>
                                </Grid.Column>
                                <Grid.Column flex='none'>
                                  <Section width='100' justify='flex-end'>
                                    <Text regular10 lineheightTh color={VARIABLES.COLORS.BLACK}>10 ปี</Text>
                                  </Section>
                                </Grid.Column>
                              </Grid>
                            </Section>
                            <Section spacingBottom='-5'>
                              <Grid justify='space-between'>
                                <Grid.Column flex='1'>
                                  <Grid gutter='4'>
                                    <Grid.Column flex='none'>
                                      <Text regular10 lineheightTh color={VARIABLES.COLORS.BLACK}>-</Text>
                                    </Grid.Column>
                                    <Grid.Column flex='1'>
                                      <Text regular10 lineheightTh color={VARIABLES.COLORS.BLACK}>การไม่เกิดรอยด่างจากคราบสะสมของฝุ่นไม่น้อยกว่า</Text>
                                    </Grid.Column>
                                  </Grid>
                                </Grid.Column>
                                <Grid.Column flex='none'>
                                  <Section width='100' justify='flex-end'>
                                    <Text regular10 lineheightTh color={VARIABLES.COLORS.BLACK}>5 ปี</Text>
                                  </Section>
                                </Grid.Column>
                              </Grid>
                            </Section>
                          </React.Fragment>
                        }

                        {/* crp */}
                        {crp &&
                          <React.Fragment>
                            <Section spacingBottom='-5'>
                              <Grid justify='space-between'>
                                <Grid.Column flex='1'>
                                  <Grid gutter='4'>
                                    <Grid.Column flex='none'>
                                      <Text regular10 lineheightTh color={VARIABLES.COLORS.BLACK}>-</Text>
                                    </Grid.Column>
                                    <Grid.Column flex='1'>
                                      <Text regular10 lineheightTh color={VARIABLES.COLORS.BLACK}>จะไม่เป็นรูพรุนเนือ่งจากการกัดกร่อนเป็นระยะเวลา ไม่น้อยกว่า</Text>
                                    </Grid.Column>
                                  </Grid>
                                </Grid.Column>
                                <Grid.Column flex='none'>
                                  <Section width='100' justify='flex-end'>
                                    <Text regular10 lineheightTh color={VARIABLES.COLORS.BLACK}>12 ปี</Text>
                                  </Section>
                                </Grid.Column>
                              </Grid>
                            </Section>
                          </React.Fragment>
                        }

                        {/* jingjoeLek */}
                        {jingjoeLek &&
                          <React.Fragment>
                            <Section spacingBottom='-5'>
                              <Grid justify='space-between'>
                                <Grid.Column flex='1'>
                                  <Grid gutter='4'>
                                    <Grid.Column flex='none'>
                                      <Text regular10 lineheightTh color={VARIABLES.COLORS.BLACK}>-</Text>
                                    </Grid.Column>
                                    <Grid.Column flex='1'>
                                      <Text regular10 lineheightTh color={VARIABLES.COLORS.BLACK}>จิงโจ้เหล็ก® - จะไม่เป็นรูพรุนเนื่องจากการกัดกร่อน เป็นระยะเวลา ไม่น้อยกว่า</Text>
                                    </Grid.Column>
                                  </Grid>
                                </Grid.Column>
                                <Grid.Column flex='none'>
                                  <Section width='100' justify='flex-end'>
                                    <Text regular10 lineheightTh color={VARIABLES.COLORS.BLACK}>1 ปี</Text>
                                  </Section>
                                </Grid.Column>
                              </Grid>
                            </Section>
                          </React.Fragment>
                        }

                        {/* prima */}
                        {prima &&
                          <React.Fragment>
                            <Section spacingBottom='-5'>
                              <Grid justify='space-between'>
                                <Grid.Column flex='1'>
                                  <Grid gutter='4'>
                                    <Grid.Column flex='none'>
                                      <Text regular10 lineheightTh color={VARIABLES.COLORS.BLACK}>-</Text>
                                    </Grid.Column>
                                    <Grid.Column flex='1'>
                                      <Text regular10 lineheightTh color={VARIABLES.COLORS.BLACK}>จะไม่เป็นรูพรุนเนือ่งจากการกัดกร่อนเป็นระยะเวลา ไม่น้อยกว่า</Text>
                                    </Grid.Column>
                                  </Grid>
                                </Grid.Column>
                                <Grid.Column flex='none'>
                                  <Section width='100' justify='flex-end'>
                                    <Text regular10 lineheightTh color={VARIABLES.COLORS.BLACK}>20 ปี</Text>
                                  </Section>
                                </Grid.Column>
                              </Grid>
                            </Section>
                          </React.Fragment>
                        }

                        {/* superDymaDk */}
                        {superDymaDk &&
                          <React.Fragment>
                            <Section spacingBottom='-5'>
                              <Grid justify='space-between'>
                                <Grid.Column flex='1'>
                                  <Grid gutter='4'>
                                    <Grid.Column flex='none'>
                                      <Text regular10 lineheightTh color={VARIABLES.COLORS.BLACK}>-</Text>
                                    </Grid.Column>
                                    <Grid.Column flex='1'>
                                      <Text regular10 lineheightTh color={VARIABLES.COLORS.BLACK}>โครงสร้างไม่พังอันเนื่องมาจากการกัดกร่อน ไม่น้อยกว่า</Text>
                                    </Grid.Column>
                                  </Grid>
                                </Grid.Column>
                                <Grid.Column flex='none'>
                                  <Section width='100' justify='flex-end'>
                                    <Text regular10 lineheightTh color={VARIABLES.COLORS.BLACK}>12 ปี</Text>
                                  </Section>
                                </Grid.Column>
                              </Grid>
                            </Section>
                          </React.Fragment>
                        }

                        {/* superDymaPl */}
                        {superDymaPl &&
                          <React.Fragment>
                            <Section spacingBottom='-5'>
                              <Grid justify='space-between'>
                                <Grid.Column flex='1'>
                                  <Grid gutter='4'>
                                    <Grid.Column flex='none'>
                                      <Text regular10 lineheightTh color={VARIABLES.COLORS.BLACK}>-</Text>
                                    </Grid.Column>
                                    <Grid.Column flex='1'>
                                      <Text regular10 lineheightTh color={VARIABLES.COLORS.BLACK}>โครงสร้างไม่พังอันเนื่องมาจากการกัดกร่อน ไม่น้อยกว่า</Text>
                                    </Grid.Column>
                                  </Grid>
                                </Grid.Column>
                                <Grid.Column flex='none'>
                                  <Section width='100' justify='flex-end'>
                                    <Text regular10 lineheightTh color={VARIABLES.COLORS.BLACK}>12 ปี</Text>
                                  </Section>
                                </Grid.Column>
                              </Grid>
                            </Section>
                          </React.Fragment>
                        }

                        {/* trueCore */}
                        {trueCore &&
                          <React.Fragment>
                            <Section spacingBottom='-5'>
                              <Grid justify='space-between'>
                                <Grid.Column flex='1'>
                                  <Grid gutter='4'>
                                    <Grid.Column flex='none'>
                                      <Text regular10 lineheightTh color={VARIABLES.COLORS.BLACK}>-</Text>
                                    </Grid.Column>
                                    <Grid.Column flex='1'>
                                      <Text regular10 lineheightTh color={VARIABLES.COLORS.BLACK}>สำหรับอาคารแบบปิดสนิท และมีระยะห่างจากทะเลมากกว่า 5 กม.</Text>
                                    </Grid.Column>
                                  </Grid>
                                </Grid.Column>
                                <Grid.Column flex='none'>
                                  <Section width='100' justify='flex-end'>
                                    <Text regular10 lineheightTh color={VARIABLES.COLORS.BLACK}>30 ปี</Text>
                                  </Section>
                                </Grid.Column>
                              </Grid>
                            </Section>
                            <Section spacingBottom='-5'>
                              <Grid justify='space-between'>
                                <Grid.Column flex='1'>
                                  <Grid gutter='4'>
                                    <Grid.Column flex='none'>
                                      <Text regular10 lineheightTh color={VARIABLES.COLORS.BLACK}>-</Text>
                                    </Grid.Column>
                                    <Grid.Column flex='1'>
                                      <Text regular10 lineheightTh color={VARIABLES.COLORS.BLACK}>สำหรับอาคารแบบปิดสนิท และมีระยะห่างจากทะเลมากกว่า 2-5 กม.</Text>
                                    </Grid.Column>
                                  </Grid>
                                </Grid.Column>
                                <Grid.Column flex='none'>
                                  <Section width='100' justify='flex-end'>
                                    <Text regular10 lineheightTh color={VARIABLES.COLORS.BLACK}>25 ปี</Text>
                                  </Section>
                                </Grid.Column>
                              </Grid>
                            </Section>
                            <Section spacingBottom='-5'>
                              <Grid justify='space-between'>
                                <Grid.Column flex='1'>
                                  <Grid gutter='4'>
                                    <Grid.Column flex='none'>
                                      <Text regular10 lineheightTh color={VARIABLES.COLORS.BLACK}>-</Text>
                                    </Grid.Column>
                                    <Grid.Column flex='1'>
                                      <Text regular10 lineheightTh color={VARIABLES.COLORS.BLACK}>สำหรับอาคารแบบเปิด และมีระยะห่างจากทะเลมากกว่า 5 กม.</Text>
                                    </Grid.Column>
                                  </Grid>
                                </Grid.Column>
                                <Grid.Column flex='none'>
                                  <Section width='100' justify='flex-end'>
                                    <Text regular10 lineheightTh color={VARIABLES.COLORS.BLACK}>20 ปี</Text>
                                  </Section>
                                </Grid.Column>
                              </Grid>
                            </Section>
                            <Section spacingBottom='-5'>
                              <Grid justify='space-between'>
                                <Grid.Column flex='1'>
                                  <Grid gutter='4'>
                                    <Grid.Column flex='none'>
                                      <Text regular10 lineheightTh color={VARIABLES.COLORS.BLACK}>-</Text>
                                    </Grid.Column>
                                    <Grid.Column flex='1'>
                                      <Text regular10 lineheightTh color={VARIABLES.COLORS.BLACK}>สำหรับอาคารแบบเปิด และมีระยะห่างจากทะเลมากกว่า 2-5 กม.</Text>
                                    </Grid.Column>
                                  </Grid>
                                </Grid.Column>
                                <Grid.Column flex='none'>
                                  <Section width='100' justify='flex-end'>
                                    <Text regular10 lineheightTh color={VARIABLES.COLORS.BLACK}>15 ปี</Text>
                                  </Section>
                                </Grid.Column>
                              </Grid>
                            </Section>
                          </React.Fragment>
                        }

                        {/* zincalume */}
                        {zincalume &&
                          <React.Fragment>
                            <Section spacingBottom='-5'>
                              <Grid justify='space-between'>
                                <Grid.Column flex='1'>
                                  <Grid gutter='4'>
                                    <Grid.Column flex='none'>
                                      <Text regular10 lineheightTh color={VARIABLES.COLORS.BLACK}>-</Text>
                                    </Grid.Column>
                                    <Grid.Column flex='1'>
                                      <Text regular10 lineheightTh color={VARIABLES.COLORS.BLACK}>จะไม่เป็นรูพรุนเนือ่งจากการกัดกร่อนเป็นระยะเวลา ไม่น้อยกว่า</Text>
                                    </Grid.Column>
                                  </Grid>
                                </Grid.Column>
                                <Grid.Column flex='none'>
                                  <Section width='100' justify='flex-end'>
                                    <Text regular10 lineheightTh color={VARIABLES.COLORS.BLACK}>20 ปี</Text>
                                  </Section>
                                </Grid.Column>
                              </Grid>
                            </Section>
                          </React.Fragment>
                        }
                        <Section paddingBottom={(osName === 'Mac OS') ? '3' : '0'}>
                          <Text regular10 lineheightTh color={VARIABLES.COLORS.BLACK}>
                            <Text bold10 lineheightTh color={VARIABLES.COLORS.BLACK}>Site Inspection:</Text>
                            &nbsp;&nbsp;&nbsp;{siteInspection}
                          </Text>
                        </Section>
                      </Section>
                      <Section>
                        <Grid>
                          <Grid.Column grid='6'>
                            <Section padding='2'>
                              <Section>
                                <Section height='24' borderBottomWidth='1' borderBottomStyle='solid' borderBottomColor={VARIABLES.COLORS.BLACK}></Section>
                              </Section>
                              <Section>
                                <Section spacingBottom='-5'><Text regular10 lineheightTh color={VARIABLES.COLORS.BLACK}>ลายมือชื่อ และประทับตราบริษัท ผู้ผลิตและจัดจำหน่าย</Text></Section>
                                <Section><Text regular10 lineheightTh color={VARIABLES.COLORS.BLACK}>วันที่ออกใบรับประกัน {warrantyCreateDate}</Text></Section>
                              </Section>
                            </Section>
                          </Grid.Column>
                          <Grid.Column grid='6'>
                            <Section paddingVertical='2' paddingHorizontal='2' borderLeftWidth='1' borderLeftStyle='solid' borderLeftColor={VARIABLES.COLORS.BLACK}>
                              <Grid gutter='30'>
                                <Grid.Column flex='1'>
                                  <Section>
                                    <Section height='24' borderBottomWidth='1' borderBottomStyle='solid' borderBottomColor={VARIABLES.COLORS.BLACK}></Section>
                                  </Section>
                                  <Section><Text regular10 lineheightTh color={VARIABLES.COLORS.BLACK}>ลายมือชื่อ เจ้าของโครงการ/ผู้รับมอบอำนาจ</Text></Section>
                                </Grid.Column>
                                <Grid.Column flex='none'>
                                  <Section spacingBottom='3'>
                                    <Grid gutter='5'>
                                      <Grid.Column><Section width='25' height='24' borderBottomWidth='1' borderBottomStyle='solid' borderBottomColor={VARIABLES.COLORS.BLACK}></Section></Grid.Column>
                                      <Grid.Column><Section height='100' heightUnit='%' align='flex-end'><Text regular10 lineheightTh top='5' left='2' color={VARIABLES.COLORS.BLACK}>/</Text></Section></Grid.Column>
                                      <Grid.Column><Section width='25' height='24' borderBottomWidth='1' borderBottomStyle='solid' borderBottomColor={VARIABLES.COLORS.BLACK}></Section></Grid.Column>
                                      <Grid.Column><Section height='100' heightUnit='%' align='flex-end'><Text regular10 lineheightTh top='5' left='2' color={VARIABLES.COLORS.BLACK}>/</Text></Section></Grid.Column>
                                      <Grid.Column><Section width='25' height='24' borderBottomWidth='1' borderBottomStyle='solid' borderBottomColor={VARIABLES.COLORS.BLACK}></Section></Grid.Column>
                                    </Grid>
                                  </Section>
                                  <Section justify='center'><Text regular10 lineheightTh color={VARIABLES.COLORS.BLACK}>วันที่</Text></Section>
                                </Grid.Column>
                              </Grid>
                              <Section spacingTop='-5'><Text regular10 lineheightTh color={VARIABLES.COLORS.BLACK}>รับทราบและยอมรับเงื่อนไขการรับประกัน</Text></Section>
                            </Section>
                          </Grid.Column>
                        </Grid>
                      </Section>
                    </ShapeContainer>
                  </Section>
                  {children}
                </WarrantyWrapper>
              )
            case 'termsAndConditions':
              return (
                <WarrantyWrapper id='termsAndConditions' className={classes}>
                  {/* blueScopeZacs */}
                  {blueScopeZacs &&
                    <React.Fragment>
                      {true &&
                        <React.Fragment>
                          <Section height='5' />
                          <Grid gutter='10'>
                            <Grid.Column grid='6'>
                              {/* Notice ============================================================= */}
                              <WarrantyNoticeTwoColumn />

                              {/* T & C ============================================================== */}
                              <Section>
                                <div className="warranty-text is-xs">สัญญารับประกันฉบับนี้เป็นการรับประกันว่าแผ่นเหล็ก BlueScope Zacs® หากได้รับการติดตั้งและบำรุงรักษา<br /> อย่างถูกต้องคาดว่าจะมีอายุการใช้งานอย่างน้อยตามระยะเวลาการรับประกันที่กำหนดของผลิตภัณฑ์<br /> (นับจากวันที่ซื้อสินค้า)</div>
                              </Section>
                              <Section spacingBottom='4'>
                                <div className="warranty-text is-xs">อย่างไรก็ตาม ข้อบกพร่องเฉพาะหรือสถานการณ์ใดๆตามรายละเอียดดังต่อไปนี้ จะต้องได้รับการระบุและ<br /> แก้ไขโดยเร็ว เพื่อที่จะให้เกิดผลดีที่สุดต่อคุณภาพของผลิตภัณฑ์ ทั้งนี้ข้อบกพร่องที่เกิดจากการไม่แก้ไข<br /> อาจมีผลกระทบต่อการยกเลิกของการรับประกัน ดังหัวข้อต่อไปนี้</div>
                              </Section>
                              <Section spacingBottom='4'>
                                <Grid>
                                  <Grid.Column flex='none'>
                                    <Section width='10' align='flex-start'>
                                      <div className="warranty-text is-xs">1.</div>
                                    </Section>
                                  </Grid.Column>
                                  <Grid.Column flex='1'>
                                    <div className="warranty-text is-xs">การรับประกันครอบคลุมเฉพาะหลังคาและผนัง ที่ทำจากแผ่นเหล็กคุณภาพชั้นหนึ่ง BlueScope Zacs® เท่านั้น<br /> ทั้งนี้เจ้าของบ้าน/ผู้รับเหมา ควรตรวจสอบว่าเป็นของแท้โดยดูเครื่องหมายการค้า BlueScope Zacs® ที่พิมพ์<br /> อยู่ใต้แผ่นทั้งนี้การนำไปใช้งานเป็นรางน้ำ, ท่อระบายน้ำฝน, แผ่นเหล็กรอยต่อโครงสร้าง, ประตูและวัสดุ<br />ประกอบอื่นๆ นั้น   จะไม่รวมอยู่ภายใต้การรับประกัน</div>
                                  </Grid.Column>
                                </Grid>
                              </Section>
                              <Section spacingBottom='4'>
                                <Grid>
                                  <Grid.Column flex='none'>
                                    <Section width='10'>
                                      <div className="warranty-text is-xs">2.</div>
                                    </Section>
                                  </Grid.Column>
                                  <Grid.Column flex='1'>
                                    <div className="warranty-text is-xs">การเคลื่อนย้ายและติดตั้ง ต้องปฏิบัติอย่างถูกวิธีตามคำแนะนำของ บลูสโคป โดยระมัดระวังสิ่งต่อไปนี้เป็นพิเศษ</div>
                                  </Grid.Column>
                                </Grid>
                              </Section>

                              {/* WarrantySetupWarningList =========================================== */}
                              <WarrantySetupWarningListTwoColumn />


                              {/* T & C ============================================================== */}
                              <Section spacingBottom='4'>
                                <Grid>
                                  <Grid.Column flex='none'>
                                    <Section width='10'>
                                      <div className="warranty-text is-xs">3.</div>
                                    </Section>
                                  </Grid.Column>
                                  <Grid.Column flex='1'>
                                    <div className="warranty-text is-xs">หมุดยึดต้องเป็นไปตามมาตรฐาน AS 3566 คลาส 3 หรือ 4 โดยต้องได้รับการรับรองมาตรฐานจากผู้ผลิต<br />วัสดุนั้นๆ และต้องไม่ใช่เหล็กกล้าไร้สนิม</div>
                                  </Grid.Column>
                                </Grid>
                              </Section>
                              <Section spacingBottom='4'>
                                <Grid>
                                  <Grid.Column flex='none'>
                                    <Section width='10' align='flex-start'>
                                      <div className="warranty-text is-xs">4.</div>
                                    </Section>
                                  </Grid.Column>
                                  <Grid.Column flex='1'>
                                    <div className="warranty-text is-xs">แผ่นปิดครอบ หมุดยึด และวัสดุประกอบอื่นๆต้องผลิตจากวัสดุที่มีคุณสมบัติเทียบเท่ากับหลังคาและผนัง<br /> ซึ่งเคลือบหรือชุบด้วยวัสดุตามคำแนะนำของบลูสโคป (แผ่นเหล็ก COLORBOND®, Zincalume®,<br /> BlueScope Zacs®, สังกะสี, อลูมิเนียม) เพื่อให้ผลิตภัณฑ์มีคุณภาพและระยะเวลาการใช้งานเหมือนกัน</div>
                                  </Grid.Column>
                                </Grid>
                              </Section>
                              <Section spacingBottom='4'>
                                <Grid>
                                  <Grid.Column flex='none'>
                                    <Section width='10'>
                                      <div className="warranty-text is-xs">5.</div>
                                    </Section>
                                  </Grid.Column>
                                  <Grid.Column flex='1'>
                                    <div className="warranty-text is-xs">สีของผลิตภัณฑ์ต้องไม่อยู่ในสภาพที่มีรอยขีดข่วน ถลอก ได้รับความเสียหาย หรือถูกเคลือบทับด้วยวัสดุแปลก<br />ปลอม หรือวัสดุที่ไม่มีคุณสมบัติเทียบเท่า (รวมทั้ง ปล่องไฟ หรือปล่องระบาย) </div>
                                  </Grid.Column>
                                </Grid>
                              </Section>
                            </Grid.Column>
                            <Grid.Column grid='6'>
                              <Section spacingBottom='4'>
                                <Grid>
                                  <Grid.Column flex='none'>
                                    <Section width='10'>
                                      <div className="warranty-text is-xs">6.</div>
                                    </Section>
                                  </Grid.Column>
                                  <Grid.Column flex='1'>
                                    <div className="warranty-text is-xs">การเกิดการผุกร่อนบางส่วนหรือทั้งหมดอันเนื่องมาจากสิ่งต่อไปนี้ ไม่ถือว่าอยู่ภายใต้การรับประกัน</div>
                                  </Grid.Column>
                                </Grid>
                              </Section>

                              {/* WarrantyDecayList ================================================== */}
                              <WarrantyDecayListTwoColumn />

                              {/* T & C ============================================================== */}
                              <Section spacingBottom='4'>
                                <Grid>
                                  <Grid.Column flex='none'>
                                    <Section width='15' align='flex-start'>
                                      <div className="warranty-text is-xs">7.</div>
                                    </Section>
                                  </Grid.Column>
                                  <Grid.Column flex='1'>
                                    <div className="warranty-text is-xs">ความบกพร่องของผลิตภัณฑ์จากการเกิดสนิมหมายถึงแผ่นมีการผุกร่อนเป็นรู สนิมที่เกิดบนผิวของ<br />แผ่นหรือการ เกิดสนิมเล็กน้อยเนื่องจากการหักงอของแผ่น หรือการตัดปลายแผ่น ในสภาพแวดล้อม<br />ตามธรรมชาติทั่วไป ไม่ถือว่าเป็นความบกพร่องของผลิตภัณฑ์ </div>
                                  </Grid.Column>
                                </Grid>
                              </Section>
                              <Section spacingBottom='4'>
                                <Grid>
                                  <Grid.Column flex='none'>
                                    <Section width='15'>
                                      <div className="warranty-text is-xs">8.</div>
                                    </Section>
                                  </Grid.Column>
                                  <Grid.Column flex='1'>
                                    <div className="warranty-text is-xs">การรับประกันสีไม่ซีดจาง, คราบฝุ่นสะสม และอัตราการหลุดเป็นผงอย่างรุนแรง  จะรับประกันตามค่าความ<br />แตกต่างของสีหรือค่า Delta (E) และค่าจาก Jacobson test โดยสีที่เคลือบบนผลิตภัณฑ์จะไม่เปลี่ยน<br />สภาพเกิน 30</div>
                                  </Grid.Column>
                                </Grid>
                              </Section>
                              <Section spacingBottom='4'>
                                <div className="warranty-text is-xs">หมายเหตุ: ค่าความแตกต่างของสีหรือค่า Delta(E) อ้างอิงตาม CIE Lab2000 units โดยจะทำการวัดจากชิ้นงาน<br />ที่พื้นผิวสะอาด ปราศจากฝุ่นและคราบสกปรกอื่นๆ(อ้างอิงตามมาตรฐาน การทดสอบ ASTM D 2244 และ ASTM D 3964)</div>
                              </Section>
                              <Section spacingBottom='4'>
                                <div className="warranty-text is-xs">ทั้งนี้การรับประกันนี้เป็นการรับประกันคุณภาพของผลิตภัณฑ์เท่านั้น หากมีข้อบกพร่องเกี่ยวกับการรีดขึ้นรูป หรือ<br />การติดตั้งผลิตภัณฑ์ กรุณาติดต่อผู้ผลิต</div>
                              </Section>
                            </Grid.Column>
                          </Grid>
                        </React.Fragment>
                      }

                      {/* One column */}
                      {false &&
                        <React.Fragment>
                          <WarrantyNotice />
                          <Section>
                            <Text regular8 lineheightTh color={VARIABLES.COLORS.BLACK}>สัญญารับประกันฉบับนี้เป็นการรับประกันว่าแผ่นเหล็ก BlueScope Zacs® หากได้รับการติดตั้งและบำรุงรักษาอย่างถูกต้องคาดว่าจะมีอายุการใช้งานอย่างน้อยตามระยะเวลาการรับประกันที่กำหนดของผลิตภัณฑ์ (นับจากวันที่ซื้อสินค้า)</Text>
                          </Section>
                          <Section spacingBottom='4'>
                            <Text regular8 lineheightTh color={VARIABLES.COLORS.BLACK}>อย่างไรก็ตาม ข้อบกพร่องเฉพาะหรือสถานการณ์ใดๆตามรายละเอียดดังต่อไปนี้ จะต้องได้รับการระบุและแก้ไขโดยเร็ว เพื่อที่จะให้เกิดผลดีที่สุดต่อคุณภาพของผลิตภัณฑ์ ทั้งนี้ข้อบกพร่องที่เกิดจากการไม่แก้ไข อาจมีผลกระทบต่อ<br />การยกเลิกของการรับประกัน ดังหัวข้อต่อไปนี้</Text>
                          </Section>
                          <Section spacingBottom='-3'>
                            <Grid>
                              <Grid.Column flex='none'>
                                <Section width='10' align='flex-start'>
                                  <Text regular8 lineheightTh color={VARIABLES.COLORS.BLACK}>1.</Text>
                                </Section>
                              </Grid.Column>
                              <Grid.Column flex='1'>
                                <Text regular8 lineheightTh color={VARIABLES.COLORS.BLACK}>การรับประกันครอบคลุมเฉพาะหลังคาและผนัง ที่ทำจากแผ่นเหล็กคุณภาพชั้นหนึ่ง BlueScope Zacs® เท่านั้น ทั้งนี้เจ้าของบ้าน/ผู้รับเหมา ควรตรวจสอบว่าเป็นของแท้โดยดูเครื่องหมายการค้า BlueScope Zacs® ที่พิมพ์อยู่ใต้แผ่น<br /> ทั้งนี้การนำไปใช้งานเป็นรางน้ำ, ท่อระบายน้ำฝน, แผ่นเหล็กรอยต่อโครงสร้าง, ประตู และวัสดุประกอบอื่นๆ นั้น จะไม่รวมอยู่ภายใต้การรับประกัน</Text>
                              </Grid.Column>
                            </Grid>
                          </Section>
                          <Section spacingBottom='-6'>
                            <Grid>
                              <Grid.Column flex='none'>
                                <Section width='10'>
                                  <Text regular8 lineheightTh color={VARIABLES.COLORS.BLACK}>2.</Text>
                                </Section>
                              </Grid.Column>
                              <Grid.Column flex='1'>
                                <Text regular8 lineheightTh color={VARIABLES.COLORS.BLACK}>การเคลื่อนย้ายและติดตั้ง ต้องปฏิบัติอย่างถูกวิธีตามคำแนะนำของ บลูสโคป โดยระมัดระวังสิ่งต่อไปนี้เป็นพิเศษ</Text>
                              </Grid.Column>
                            </Grid>
                          </Section>
                          <WarrantySetupWarningList />
                          <Section spacingBottom='3'>
                            <Grid>
                              <Grid.Column flex='none'>
                                <Section width='10'>
                                  <Text regular8 lineheightTh color={VARIABLES.COLORS.BLACK}>3.</Text>
                                </Section>
                              </Grid.Column>
                              <Grid.Column flex='1'>
                                <Text regular8 lineheightTh color={VARIABLES.COLORS.BLACK}>หมุดยึดต้องเป็นไปตามมาตรฐาน AS 3566 คลาส 3 หรือ 4 โดยต้องได้รับการรับรองมาตรฐานจากผู้ผลิตวัสดุนั้นๆ และต้องไม่ใช่เหล็กกล้าไร้สนิม</Text>
                              </Grid.Column>
                            </Grid>
                          </Section>
                          <Section spacingBottom='-3'>
                            <Grid>
                              <Grid.Column flex='none'>
                                <Section width='10' align='flex-start'>
                                  <Text regular8 lineheightTh color={VARIABLES.COLORS.BLACK}>4.</Text>
                                </Section>
                              </Grid.Column>
                              <Grid.Column flex='1'>
                                <Text regular8 lineheightTh color={VARIABLES.COLORS.BLACK}>แผ่นปิดครอบ หมุดยึด และวัสดุประกอบอื่นๆต้องผลิตจากวัสดุที่มีคุณสมบัติเทียบเท่ากับหลังคาและผนัง ซึ่งเคลือบหรือชุบด้วยวัสดุตามคำแนะนำของบลูสโคป (แผ่นเหล็ก COLORBOND®, Zincalume®, BlueScope Zacs®, สังกะสี,<br /> อลูมิเนียม) เพื่อให้ผลิตภัณฑ์มีคุณภาพและระยะเวลาการใช้งานเหมือนกัน</Text>
                              </Grid.Column>
                            </Grid>
                          </Section>
                          <Section spacingBottom='-3'>
                            <Grid>
                              <Grid.Column flex='none'>
                                <Section width='10'>
                                  <Text regular8 lineheightTh color={VARIABLES.COLORS.BLACK}>5.</Text>
                                </Section>
                              </Grid.Column>
                              <Grid.Column flex='1'>
                                <Text regular8 lineheightTh color={VARIABLES.COLORS.BLACK}>สีของผลิตภัณฑ์ต้องไม่อยู่ในสภาพที่มีรอยขีดข่วน ถลอก ได้รับความเสียหาย หรือถูกเคลือบทับด้วยวัสดุแปลกปลอม หรือวัสดุที่ไม่มีคุณสมบัติเทียบเท่า (รวมทั้ง ปล่องไฟ หรือปล่องระบาย)</Text>
                              </Grid.Column>
                            </Grid>
                          </Section>
                          <Section spacingBottom='-6'>
                            <Grid>
                              <Grid.Column flex='none'>
                                <Section width='10'>
                                  <Text regular8 lineheightTh color={VARIABLES.COLORS.BLACK}>6.</Text>
                                </Section>
                              </Grid.Column>
                              <Grid.Column flex='1'>
                                <Text regular8 lineheightTh color={VARIABLES.COLORS.BLACK}>การเกิดการผุกร่อนบางส่วนหรือทั้งหมดอันเนื่องมาจากสิ่งต่อไปนี้ ไม่ถือว่าอยู่ภายใต้การรับประกัน</Text>
                              </Grid.Column>
                            </Grid>
                          </Section>
                          <WarrantyDecayList />
                          <Section spacingBottom='-3' paddingTop='3'>
                            <Grid>
                              <Grid.Column flex='none'>
                                <Section width='10' align='flex-start'>
                                  <Text regular8 lineheightTh color={VARIABLES.COLORS.BLACK}>7.</Text>
                                </Section>
                              </Grid.Column>
                              <Grid.Column flex='1'>
                                <Text regular8 lineheightTh color={VARIABLES.COLORS.BLACK}>ความบกพร่องของผลิตภัณฑ์จากการเกิดสนิมหมายถึงแผ่นมีการผุกร่อนเป็นรู สนิมที่เกิดบนผิวของแผ่นหรือการเกิดสนิมเล็กน้อยเนื่องจากการหักงอของแผ่น หรือการตัดปลายแผ่น ในสภาพแวดล้อมตามธรรมชาติทั่วไป<br /> ไม่ถือว่าเป็นความบกพร่องของผลิตภัณฑ์</Text>
                              </Grid.Column>
                            </Grid>
                          </Section>
                          <Section spacingBottom='-3'>
                            <Grid>
                              <Grid.Column flex='none'>
                                <Section width='10'>
                                  <Text regular8 lineheightTh color={VARIABLES.COLORS.BLACK}>8.</Text>
                                </Section>
                              </Grid.Column>
                              <Grid.Column flex='1'>
                                <Text regular8 lineheightTh color={VARIABLES.COLORS.BLACK}>การรับประกันสีไม่ซีดจาง, คราบฝุ่นสะสม และอัตราการหลุดเป็นผงอย่างรุนแรง  จะรับประกันตามค่าความแตกต่างของสีหรือค่า Delta (E) และค่าจาก Jacobson test โดยสีที่เคลือบบนผลิตภัณฑ์จะไม่เปลี่ยนสภาพเกิน 30</Text>
                              </Grid.Column>
                            </Grid>
                          </Section>
                          <Section spacingBottom='-3'>
                            <Text regular8 lineheightTh color={VARIABLES.COLORS.BLACK}>หมายเหตุ: ค่าความแตกต่างของสีหรือค่า Delta(E) อ้างอิงตาม CIE Lab2000 units โดยจะทำการวัดจากชิ้นงานที่พื้นผิวสะอาด ปราศจากฝุ่นและคราบสกปรกอื่นๆ(อ้างอิงตามมาตรฐาน การทดสอบ ASTM D 2244 และ ASTM D 3964)</Text>
                          </Section>
                          <Section spacingBottom='-3'>
                            <Text bold8 lineheightTh color={VARIABLES.COLORS.BLACK}>ทั้งนี้การรับประกันนี้เป็นการรับประกันคุณภาพของผลิตภัณฑ์เท่านั้น หากมีข้อบกพร่องเกี่ยวกับการรีดขึ้นรูป หรือการติดตั้งผลิตภัณฑ์ กรุณาติดต่อผู้ผลิต</Text>
                          </Section>
                        </React.Fragment>
                      }
                    </React.Fragment>
                  }

                  {/* colorbond */}
                  {colorbond &&
                    <React.Fragment>
                      {true &&
                        <React.Fragment>
                          <Section height='5' />
                          <Grid gutter='10'>
                            <Grid.Column grid='6'>
                              {/* Notice ============================================================= */}
                              <WarrantyNoticeTwoColumn />

                              {/* T & C ============================================================== */}
                              <Section spacingBottom='4'>
                                <div className="warranty-text is-xs">สัญญารับประกันฉบับนี้เป็นการรับประกันว่าแผ่นเหล็ก Colorbond® หากได้รับการติดตั้งและบำรุงรักษา<br />อย่างถูกต้องคาดว่าจะมีอายุการใช้งานอย่างน้อยตามระยะเวลาการรับประกันที่กำหนดของผลิตภัณฑ์<br />(นับจากวันที่ซื้อสินค้า)</div>
                              </Section>
                              <Section spacingBottom='4'>
                                <div className="warranty-text is-xs">อย่างไรก็ตาม ข้อบกพร่องเฉพาะหรือสถานการณ์ใดๆตามรายละเอียดดังต่อไปนี้ จะต้องได้รับการระบุและแก้ไข<br /> โดยเร็ว เพื่อที่จะให้เกิดผลดีที่สุดต่อคุณภาพของผลิตภัณฑ์ ทั้งนี้ข้อบกพร่องที่เกิดจากการไม่แก้ไข อาจมีผล<br />กระทบต่อการยกเลิกของการรับประกัน ดังหัวข้อต่อไปนี้</div>
                              </Section>
                              <Section spacingBottom='4'>
                                <Grid>
                                  <Grid.Column flex='none'>
                                    <Section width='10' align='flex-start'>
                                      <div className="warranty-text is-xs">1.</div>
                                    </Section>
                                  </Grid.Column>
                                  <Grid.Column flex='1'>
                                    <div className="warranty-text is-xs">การรับประกันครอบคลุมเฉพาะหลังคาและผนัง ที่ทำจากแผ่นเหล็กคุณภาพชั้นหนึ่ง Colorbond® เท่านั้น ทั้งนี้<br /> เจ้าของบ้าน/ผู้รับเหมาควรตรวจสอบว่าเป็นของแท้โดยดูเครื่องหมายการค้า Colorbond® ที่พิมพ์อยู่ใต้แผ่น<br /> ทั้งนี้การนำไปใช้งานเป็นรางน้ำ, ท่อระบายน้ำฝน, แผ่นเหล็กรอยต่อโครงสร้าง, ประตู และวัสดุประกอบอื่นๆ นั้น<br /> จะไม่รวมอยู่ภายใต้การรับประกัน</div>
                                  </Grid.Column>
                                </Grid>
                              </Section>
                              <Section spacingBottom='4'>
                                <Grid>
                                  <Grid.Column flex='none'>
                                    <Section width='10'>
                                      <div className="warranty-text is-xs">2.</div>
                                    </Section>
                                  </Grid.Column>
                                  <Grid.Column flex='1'>
                                    <div className="warranty-text is-xs">การเคลื่อนย้ายและติดตั้ง ต้องปฏิบัติอย่างถูกวิธีตามคำแนะนำของ บลูสโคป โดยระมัดระวังสิ่งต่อไปนี้เป็นพิเศษ</div>
                                  </Grid.Column>
                                </Grid>
                              </Section>

                              {/* WarrantySetupWarningList =========================================== */}
                              <WarrantySetupWarningListTwoColumn />

                              {/* T & C ============================================================== */}
                              <Section spacingBottom='4'>
                                <Grid>
                                  <Grid.Column flex='none'>
                                    <Section width='10'>
                                      <div className="warranty-text is-xs">3.</div>
                                    </Section>
                                  </Grid.Column>
                                  <Grid.Column flex='1'>
                                    <div className="warranty-text is-xs">หมุดยึดต้องเป็นไปตามมาตรฐาน AS 3566 คลาส 3 หรือ 4 โดยต้องได้รับการรับรองมาตรฐานจากผู้ผลิตวัสดุนั้นๆ <br />และต้องไม่ใช่เหล็กกล้าไร้สนิม</div>
                                  </Grid.Column>
                                </Grid>
                              </Section>
                              <Section spacingBottom='4'>
                                <Grid>
                                  <Grid.Column flex='none'>
                                    <Section width='10' align='flex-start'>
                                      <div className="warranty-text is-xs">4.</div>
                                    </Section>
                                  </Grid.Column>
                                  <Grid.Column flex='1'>
                                    <div className="warranty-text is-xs">แผ่นปิดครอบ หมุดยึด และวัสดุประกอบอื่นๆต้องผลิตจากวัสดุที่มีคุณสมบัติเทียบเท่ากับหลังคาและผนัง ซึ่ง<br /> เคลือบหรือชุบด้วยวัสดุตามคำแนะนำของบลูสโคป (แผ่นเหล็ก COLORBOND®, Zincalume®,<br /> BlueScope Zacs®, สังกะสี, อลูมิเนียม) เพื่อให้ผลิตภัณฑ์มีคุณภาพและระยะเวลาการใช้งานเหมือนกัน</div>
                                  </Grid.Column>
                                </Grid>
                              </Section>
                              <Section spacingBottom='4'>
                                <Grid>
                                  <Grid.Column flex='none'>
                                    <Section width='10'>
                                      <div className="warranty-text is-xs">5.</div>
                                    </Section>
                                  </Grid.Column>
                                  <Grid.Column flex='1'>
                                    <div className="warranty-text is-xs">สีของผลิตภัณฑ์ต้องไม่อยู่ในสภาพที่มีรอยขีดข่วน ถลอก ได้รับความเสียหาย หรือถูกเคลือบทับด้วยวัสดุแปลก<br />ปลอม หรือวัสดุที่ไม่มีคุณสมบัติเทียบเท่า (รวมทั้ง ปล่องไฟ หรือปล่องระบาย)</div>
                                  </Grid.Column>
                                </Grid>
                              </Section>
                            </Grid.Column>
                            <Grid.Column grid='6'>
                              {/* T & C ============================================================== */}
                              <Section spacingBottom='4'>
                                <Grid>
                                  <Grid.Column flex='none'>
                                    <Section width='10'>
                                      <div className="warranty-text is-xs">6.</div>
                                    </Section>
                                  </Grid.Column>
                                  <Grid.Column flex='1'>
                                    <div className="warranty-text is-xs">การเกิดการผุกร่อนบางส่วนหรือทั้งหมดอันเนื่องมาจากสิ่งต่อไปนี้ ไม่ถือว่าอยู่ภายใต้การรับประกัน</div>
                                  </Grid.Column>
                                </Grid>
                              </Section>

                              {/* WarrantyDecayList ================================================== */}
                              <WarrantyDecayListTwoColumn />

                              {/* T & C ============================================================== */}
                              <Section spacingBottom='4'>
                                <Grid>
                                  <Grid.Column flex='none'>
                                    <Section width='10'>
                                      <div className="warranty-text is-xs">7.</div>
                                    </Section>
                                  </Grid.Column>
                                  <Grid.Column flex='1'>
                                    <div className="warranty-text is-xs">ความบกพร่องของผลิตภัณฑ์จากการเกิดสนิมหมายถึงแผ่นมีการผุกร่อนเป็นรู สนิมที่เกิดบนผิวของแผ่นหรือการ<br /> เกิดสนิมเล็กน้อยเนื่องจากการหักงอของแผ่น หรือการตัดปลายแผ่น ในสภาพแวดล้อมตามธรรมชาติทั่วไป<br /> ไม่ถือว่าเป็นความบกพร่องของผลิตภัณฑ์</div>
                                  </Grid.Column>
                                </Grid>
                              </Section>
                              <Section spacingBottom='4'>
                                <Grid>
                                  <Grid.Column flex='none'>
                                    <Section width='10'>
                                      <div className="warranty-text is-xs">8.</div>
                                    </Section>
                                  </Grid.Column>
                                  <Grid.Column flex='1'>
                                    <div className="warranty-text is-xs">การรับประกันสีไม่ซีดจาง, คราบฝุ่นสะสม และอัตราการหลุดเป็นผงอย่างรุนแรง  จะรับประกันตามค่าความแตก<br /> ต่างของสีหรือค่า Delta (E) และค่าจาก Jacobson test โดยสีที่เคลือบบนผลิตภัณฑ์จะไม่เปลี่ยนสภาพเกินค่า<br />ที่กำหนด ดังนี้</div>
                                  </Grid.Column>
                                </Grid>
                              </Section>
                              <Section paddingLeft='10' spacingBottom='4'>
                                <Table fluid warranty>
                                  <Table.Section>
                                    <Table.Row>
                                      <Table.Column className='is-head-column' alignCenter maxWidth='80' paddingTop='20' paddingBottom='2' paddingHorizontal='2'>
                                        <div className="warranty-text is-xs is-bold"><br />สี</div>
                                      </Table.Column>
                                      <Table.Column className='is-head-column' alignCenter maxWidth='92' minWidth='92' padding='2'>
                                        <div className="warranty-text is-xs is-bold">
                                          ค่ารับประกัน<br />สีซีดจางสูงสุด&nbsp;
                                          <div className="warranty-text is-xxs is-superscript">(1)</div><br />
                                          (ΔE units อ้างอิง <br />CIE lab2000)<br />
                                          ASTM D-2244
                                        </div>
                                      </Table.Column>
                                      <Table.Column className='is-head-column' alignCenter maxWidth='92' minWidth='92' padding='2'>
                                        <div className="warranty-text is-xs is-bold">
                                          ค่ารับประกัน<br />คราบฝุ่นสะสมสูงสุด&nbsp;
                                          <div className="warranty-text is-xxs is-superscript">(2)</div><br />
                                          (ΔE units อ้างอิง <br />CIE lab2000)<br />
                                          ASTM D-2244
                                        </div>
                                      </Table.Column>
                                      <Table.Column className='is-head-column' alignCenter maxWidth='92' minWidth='92' padding='2'>
                                        <div className="warranty-text is-xs is-bold">
                                          ค่ารับประกัน<br />อัตราสีหลุดเป็น<br />ผงสูงสุด&nbsp;
                                          <div className="warranty-text is-xxs is-superscript">(3)</div><br />
                                          (อ้างอิง<br /> Jacobson test)<br />
                                          AS1580-1991
                                        </div>
                                      </Table.Column>
                                    </Table.Row>
                                  </Table.Section>
                                  <Table.Section>
                                    <Table.Row>
                                      <Table.Column className='is-body-column' maxWidth='80' padding='2'>
                                        <div className="warranty-text is-xs">อ่อน เช่น Off white</div>
                                      </Table.Column>
                                      <Table.Column className='is-body-column' alignCenter maxWidth='92' minWidth='92' padding='2'>
                                        <div className="warranty-text is-xs">6</div>
                                      </Table.Column>
                                      <Table.Column className='is-body-column' alignCenter maxWidth='92' minWidth='92' padding='2'>
                                        <div className="warranty-text is-xs">4</div>
                                      </Table.Column>
                                      <Table.Column className='is-body-column' alignCenter maxWidth='92' minWidth='92' padding='2'>
                                        <div className="warranty-text is-xs">2</div>
                                      </Table.Column>
                                    </Table.Row>
                                    <Table.Row>
                                      <Table.Column className='is-body-column' maxWidth='80' padding='2'>
                                        <div className="warranty-text is-xs">ปานกลาง เช่น Burnt almond </div>
                                      </Table.Column>
                                      <Table.Column className='is-body-column' alignCenter maxWidth='92' minWidth='92' paddingTop='6' paddingBottom='2' paddingHorizontal='2'>
                                        <div className="warranty-text is-xs">9</div>
                                      </Table.Column>
                                      <Table.Column className='is-body-column' alignCenter maxWidth='92' minWidth='92' paddingTop='6' paddingBottom='2' paddingHorizontal='2'>
                                        <div className="warranty-text is-xs">3</div>
                                      </Table.Column>
                                      <Table.Column className='is-body-column' alignCenter maxWidth='92' minWidth='92' paddingTop='6' paddingBottom='2' paddingHorizontal='2'>
                                        <div className="warranty-text is-xs">2</div>
                                      </Table.Column>
                                    </Table.Row>
                                    <Table.Row>
                                      <Table.Column className='is-body-column' maxWidth='80' padding='2'>
                                        <div className="warranty-text is-xs">เข้ม เช่น Bangkok red </div>
                                      </Table.Column>
                                      <Table.Column className='is-body-column' alignCenter maxWidth='92' minWidth='92' paddingTop='6' paddingBottom='2' paddingHorizontal='2'>
                                        <div className="warranty-text is-xs">15</div>
                                      </Table.Column>
                                      <Table.Column className='is-body-column' alignCenter maxWidth='92' minWidth='92' paddingTop='6' paddingBottom='2' paddingHorizontal='2'>
                                        <div className="warranty-text is-xs">2</div>
                                      </Table.Column>
                                      <Table.Column className='is-body-column' alignCenter maxWidth='92' minWidth='92' paddingTop='6' paddingBottom='2' paddingHorizontal='2'>
                                        <div className="warranty-text is-xs">2</div>
                                      </Table.Column>
                                    </Table.Row>
                                  </Table.Section>
                                </Table>
                              </Section>
                              <Section paddingLeft='10' spacingBottom='4'>
                                <div className="warranty-text is-xs">หมายเหตุ:</div>
                              </Section>
                              <Section paddingLeft='10' spacingBottom='4'>
                                <div className="warranty-text is-xs">
                                  <div className="warranty-text is-xxs is-superscript">(1)</div>
                                  ค่าความแตกต่างของสีหรือค่า Delta(E) อ้างอิงตาม CIE lab2000&nbsp;
                                  <br />
                                  <div className="warranty-text is-xxs is-superscript">(2)</div>
                                  &nbsp;การรับประกันสีซีดจะวัดจากชิ้นงานที่ พื้นผิวสะอาด ปราศจากฝุ่นและคราบสกปรกอื่นๆ
                                  <br />
                                  <div className="warranty-text is-xxs is-superscript">(3)</div>
                                  &nbsp;การรับประกันคราบฝุ่นสะสมจะวัดจากชิ้นงานที่มีคราบฝุ่น และปล่อยให้น้ำไหลผ่านก่อนทำการทดสอบ&nbsp;
                                  <br />
                                  &nbsp;การรับประกันสีหลุดเป็นผงอ้างอิงตามการทดสอบ Jacobson
                                </div>
                              </Section>
                              <Section paddingLeft='10'>
                                <div className="warranty-text is-xs is-bold">ทั้งนี้การรับประกันนี้เป็นการรับประกันคุณภาพของผลิตภัณฑ์เท่านั้น หากมีข้อบกพร่องเกี่ยวกับ<br />การรีดขึ้นรูป หรือการติดตั้งผลิตภัณฑ์ กรุณาติดต่อผู้ผลิตแผ่นหลังคาเหล็ก</div>
                              </Section>
                            </Grid.Column>
                          </Grid>
                        </React.Fragment>
                      }

                      {/* One column */}
                      {false &&
                        <React.Fragment>
                          <WarrantyNoticeSmall />
                          <Section spacingBottom='2'>
                            <Text regular7 lineheightTh color={VARIABLES.COLORS.BLACK}>สัญญารับประกันฉบับนี้เป็นการรับประกันว่าแผ่นเหล็ก Colorbond® หากได้รับการติดตั้งและบำรุงรักษาอย่างถูกต้องคาดว่าจะมีอายุการใช้งานอย่างน้อยตามระยะเวลาการรับประกันที่กำหนดของผลิตภัณฑ์ (นับจากวันที่ซื้อสินค้า)</Text>
                          </Section>
                          <Section spacingBottom='2'>
                            <Text regular7 lineheightTh color={VARIABLES.COLORS.BLACK}>อย่างไรก็ตาม ข้อบกพร่องเฉพาะหรือสถานการณ์ใดๆตามรายละเอียดดังต่อไปนี้ จะต้องได้รับการระบุและแก้ไขโดยเร็ว เพื่อที่จะให้เกิดผลดีที่สุดต่อคุณภาพของผลิตภัณฑ์ ทั้งนี้ข้อบกพร่องที่เกิดจากการไม่แก้ไข อาจมีผลกระทบต่อการยกเลิกของการรับประกัน ดังหัวข้อต่อไปนี้</Text>
                          </Section>
                          <Section spacingBottom='-7'>
                            <Grid>
                              <Grid.Column flex='none'>
                                <Section width='10' align='flex-start'>
                                  <Text regular7 lineheightTh color={VARIABLES.COLORS.BLACK}>1.</Text>
                                </Section>
                              </Grid.Column>
                              <Grid.Column flex='1'>
                                <Text regular7 lineheightTh color={VARIABLES.COLORS.BLACK}>การรับประกันครอบคลุมเฉพาะหลังคาและผนัง ที่ทำจากแผ่นเหล็กคุณภาพชั้นหนึ่ง Colorbond® เท่านั้น ทั้งนี้เจ้าของบ้าน/ผู้รับเหมาควรตรวจสอบว่าเป็นของแท้โดยดูเครื่องหมายการค้า Colorbond® ที่พิมพ์อยู่ใต้แผ่น ทั้งนี้การนำไปใช้งานเป็นรางน้ำ,<br /> ท่อระบายน้ำฝน, แผ่นเหล็กรอยต่อโครงสร้าง, ประตู และวัสดุประกอบอื่นๆ นั้น จะไม่รวมอยู่ภายใต้การรับประกัน</Text>
                              </Grid.Column>
                            </Grid>
                          </Section>
                          <Section spacingBottom='-7'>
                            <Grid>
                              <Grid.Column flex='none'>
                                <Section width='10'>
                                  <Text regular7 lineheightTh color={VARIABLES.COLORS.BLACK}>2.</Text>
                                </Section>
                              </Grid.Column>
                              <Grid.Column flex='1'>
                                <Text regular7 lineheightTh color={VARIABLES.COLORS.BLACK}>การเคลื่อนย้ายและติดตั้ง ต้องปฏิบัติอย่างถูกวิธีตามคำแนะนำของ บลูสโคป โดยระมัดระวังสิ่งต่อไปนี้เป็นพิเศษ</Text>
                              </Grid.Column>
                            </Grid>
                          </Section>
                          <WarrantySetupWarningList />
                          <Section spacingTop='-7'>
                            <Grid>
                              <Grid.Column flex='none'>
                                <Section width='10'>
                                  <Text regular7 lineheightTh color={VARIABLES.COLORS.BLACK}>3.</Text>
                                </Section>
                              </Grid.Column>
                              <Grid.Column flex='1'>
                                <Text regular7 lineheightTh color={VARIABLES.COLORS.BLACK}>หมุดยึดต้องเป็นไปตามมาตรฐาน AS 3566 คลาส 3 หรือ 4 โดยต้องได้รับการรับรองมาตรฐานจากผู้ผลิตวัสดุนั้นๆ และต้องไม่ใช่เหล็กกล้าไร้สนิม</Text>
                              </Grid.Column>
                            </Grid>
                          </Section>
                          <Section spacingBottom='-7'>
                            <Grid>
                              <Grid.Column flex='none'>
                                <Section width='10' align='flex-start'>
                                  <Text regular7 lineheightTh color={VARIABLES.COLORS.BLACK}>4.</Text>
                                </Section>
                              </Grid.Column>
                              <Grid.Column flex='1'>
                                <Text regular7 lineheightTh color={VARIABLES.COLORS.BLACK}>แผ่นปิดครอบ หมุดยึด และวัสดุประกอบอื่นๆต้องผลิตจากวัสดุที่มีคุณสมบัติเทียบเท่ากับหลังคาและผนัง ซึ่งเคลือบหรือชุบด้วยวัสดุตามคำแนะนำของบลูสโคป (แผ่นเหล็ก COLORBOND®, Zincalume®, BlueScope Zacs®, สังกะสี, อลูมิเนียม) เพื่อให้ผลิตภัณฑ์<br />มีคุณภาพและระยะเวลาการใช้งานเหมือนกัน</Text>
                              </Grid.Column>
                            </Grid>
                          </Section>
                          <Section spacingBottom='-7'>
                            <Grid>
                              <Grid.Column flex='none'>
                                <Section width='10'>
                                  <Text regular7 lineheightTh color={VARIABLES.COLORS.BLACK}>5.</Text>
                                </Section>
                              </Grid.Column>
                              <Grid.Column flex='1'>
                                <Text regular7 lineheightTh color={VARIABLES.COLORS.BLACK}>สีของผลิตภัณฑ์ต้องไม่อยู่ในสภาพที่มีรอยขีดข่วน ถลอก ได้รับความเสียหาย หรือถูกเคลือบทับด้วยวัสดุแปลกปลอม หรือวัสดุที่ไม่มีคุณสมบัติเทียบเท่า (รวมทั้ง ปล่องไฟ หรือปล่องระบาย)</Text>
                              </Grid.Column>
                            </Grid>
                          </Section>
                          <Section spacingBottom='-7'>
                            <Grid>
                              <Grid.Column flex='none'>
                                <Section width='10'>
                                  <Text regular7 lineheightTh color={VARIABLES.COLORS.BLACK}>6.</Text>
                                </Section>
                              </Grid.Column>
                              <Grid.Column flex='1'>
                                <Text regular7 lineheightTh color={VARIABLES.COLORS.BLACK}>การเกิดการผุกร่อนบางส่วนหรือทั้งหมดอันเนื่องมาจากสิ่งต่อไปนี้ ไม่ถือว่าอยู่ภายใต้การรับประกัน</Text>
                              </Grid.Column>
                            </Grid>
                          </Section>
                          <WarrantyDecayList />
                          <Section spacingTop='-7' spacingBottom='-7'>
                            <Grid>
                              <Grid.Column flex='none'>
                                <Section width='10'>
                                  <Text regular7 lineheightTh color={VARIABLES.COLORS.BLACK}>7.</Text>
                                </Section>
                              </Grid.Column>
                              <Grid.Column flex='1'>
                                <Text regular7 lineheightTh color={VARIABLES.COLORS.BLACK}>ความบกพร่องของผลิตภัณฑ์จากการเกิดสนิมหมายถึงแผ่นมีการผุกร่อนเป็นรู สนิมที่เกิดบนผิวของแผ่นหรือการเกิดสนิมเล็กน้อยเนื่องจากการหักงอของแผ่น หรือการตัดปลายแผ่น ในสภาพแวดล้อมตามธรรมชาติทั่วไป ไม่ถือว่าเป็นความบกพร่องของผลิตภัณฑ์</Text>
                              </Grid.Column>
                            </Grid>
                          </Section>
                          <Section spacingBottom='2'>
                            <Grid>
                              <Grid.Column flex='none'>
                                <Section width='10'>
                                  <Text regular7 lineheightTh color={VARIABLES.COLORS.BLACK}>8.</Text>
                                </Section>
                              </Grid.Column>
                              <Grid.Column flex='1'>
                                <Text regular7 lineheightTh color={VARIABLES.COLORS.BLACK}>การรับประกันสีไม่ซีดจาง, คราบฝุ่นสะสม และอัตราการหลุดเป็นผงอย่างรุนแรง  จะรับประกันตามค่าความแตกต่างของสีหรือค่า Delta (E) และค่าจาก Jacobson test โดยสีที่เคลือบบนผลิตภัณฑ์จะไม่เปลี่ยนสภาพเกินค่าที่กำหนด ดังนี้</Text>
                              </Grid.Column>
                            </Grid>
                          </Section>
                          <Section paddingLeft='10'>
                            <Table fluid warranty>
                              <Table.Section>
                                <Table.Row>
                                  <Table.Column className='is-head-column' alignCenter maxWidth='159' paddingTop='8' paddingBottom='2' paddingHorizontal='2'>
                                    <Text bold7 color={VARIABLES.COLORS.BLACK}><br />สี</Text>
                                  </Table.Column>
                                  <Table.Column className='is-head-column' alignCenter maxWidth='165' minWidth='165' padding='2'>
                                    <Text bold7 color={VARIABLES.COLORS.BLACK}>
                                      ค่ารับประกัน<br />สีซีดจางสูงสุด&nbsp;
                                      <Text regular7 superScript color={VARIABLES.COLORS.BLACK}>(1)</Text><br />
                                      (ΔE units อ้างอิง CIE lab2000)<br />
                                      ASTM D-2244
                                    </Text>
                                  </Table.Column>
                                  <Table.Column className='is-head-column' alignCenter maxWidth='165' minWidth='165' padding='2'>
                                    <Text bold7 color={VARIABLES.COLORS.BLACK}>
                                      ค่ารับประกัน<br />คราบฝุ่นสะสมสูงสุด&nbsp;
                                      <Text regular7 superScript color={VARIABLES.COLORS.BLACK}>(2)</Text><br />
                                      (ΔE units อ้างอิง CIE lab2000)<br />
                                      ASTM D-2244
                                    </Text>
                                  </Table.Column>
                                  <Table.Column className='is-head-column' alignCenter maxWidth='165' minWidth='165' padding='2'>
                                    <Text bold7 color={VARIABLES.COLORS.BLACK}>
                                      ค่ารับประกัน<br />อัตราสีหลุดเป็นผงสูงสุด&nbsp;
                                      <Text regular7 superScript color={VARIABLES.COLORS.BLACK}>(3)</Text><br />
                                      (อ้างอิง Jacobson test)<br />
                                      AS1580-1991
                                    </Text>
                                  </Table.Column>
                                </Table.Row>
                              </Table.Section>
                              <Table.Section>
                                <Table.Row>
                                  <Table.Column className='is-body-column' maxWidth='159' padding='2'>
                                    <Text regular7 lineheightTh color={VARIABLES.COLORS.BLACK}>อ่อน เช่น Off white</Text>
                                  </Table.Column>
                                  <Table.Column className='is-body-column' alignCenter maxWidth='165' minWidth='165' padding='2'>
                                    <Text regular7 lineheightTh color={VARIABLES.COLORS.BLACK}>6</Text>
                                  </Table.Column>
                                  <Table.Column className='is-body-column' alignCenter maxWidth='165' minWidth='165' padding='2'>
                                    <Text regular7 lineheightTh color={VARIABLES.COLORS.BLACK}>4</Text>
                                  </Table.Column>
                                  <Table.Column className='is-body-column' alignCenter maxWidth='165' minWidth='165' padding='2'>
                                    <Text regular7 lineheightTh color={VARIABLES.COLORS.BLACK}>2</Text>
                                  </Table.Column>
                                </Table.Row>
                                <Table.Row>
                                  <Table.Column className='is-body-column' maxWidth='159' padding='2'>
                                    <Text regular7 lineheightTh color={VARIABLES.COLORS.BLACK}>ปานกลาง เช่น Burnt almond </Text>
                                  </Table.Column>
                                  <Table.Column className='is-body-column' alignCenter maxWidth='165' minWidth='165' padding='2'>
                                    <Text regular7 lineheightTh color={VARIABLES.COLORS.BLACK}>9</Text>
                                  </Table.Column>
                                  <Table.Column className='is-body-column' alignCenter maxWidth='165' minWidth='165' padding='2'>
                                    <Text regular7 lineheightTh color={VARIABLES.COLORS.BLACK}>3</Text>
                                  </Table.Column>
                                  <Table.Column className='is-body-column' alignCenter maxWidth='165' minWidth='165' padding='2'>
                                    <Text regular7 lineheightTh color={VARIABLES.COLORS.BLACK}>2</Text>
                                  </Table.Column>
                                </Table.Row>
                                <Table.Row>
                                  <Table.Column className='is-body-column' maxWidth='159' padding='2'>
                                    <Text regular7 lineheightTh color={VARIABLES.COLORS.BLACK}>เข้ม เช่น Bangkok red </Text>
                                  </Table.Column>
                                  <Table.Column className='is-body-column' alignCenter maxWidth='165' minWidth='165' padding='2'>
                                    <Text regular7 lineheightTh color={VARIABLES.COLORS.BLACK}>15</Text>
                                  </Table.Column>
                                  <Table.Column className='is-body-column' alignCenter maxWidth='165' minWidth='165' padding='2'>
                                    <Text regular7 lineheightTh color={VARIABLES.COLORS.BLACK}>2</Text>
                                  </Table.Column>
                                  <Table.Column className='is-body-column' alignCenter maxWidth='165' minWidth='165' padding='2'>
                                    <Text regular7 lineheightTh color={VARIABLES.COLORS.BLACK}>2</Text>
                                  </Table.Column>
                                </Table.Row>
                              </Table.Section>
                            </Table>
                          </Section>
                          <Section paddingLeft='10' spacingBottom='-7'>
                            <Text regular7 lineheightTh color={VARIABLES.COLORS.BLACK}>
                              หมายเหตุ: ค่าความแตกต่างของสีหรือค่า Delta(E) อ้างอิงตาม CIE lab2000&nbsp;
                              <Text regular7 lineheightTh superScript color={VARIABLES.COLORS.BLACK}>(1)</Text>
                              &nbsp;การรับประกันสีซีดจะวัดจากชิ้นงานที่ พื้นผิวสะอาด ปราศจากฝุ่นและคราบสกปรกอื่นๆ
                              <br />
                              <Text regular7 lineheightTh superScript color={VARIABLES.COLORS.BLACK}>(2)</Text>
                              &nbsp;การรับประกันคราบฝุ่นสะสมจะวัดจากชิ้นงานที่มีคราบฝุ่น และปล่อยให้น้ำไหลผ่านก่อนทำการทดสอบ&nbsp;
                              <Text regular7 lineheightTh superScript color={VARIABLES.COLORS.BLACK}>(3)</Text>
                              &nbsp;การรับประกันสีหลุดเป็นผงอ้างอิงตามการทดสอบ Jacobson
                            </Text>
                          </Section>
                          <Section paddingLeft='10'>
                            <Text bold7 lineheightTh color={VARIABLES.COLORS.BLACK}>ทั้งนี้การรับประกันนี้เป็นการรับประกันคุณภาพของผลิตภัณฑ์เท่านั้น หากมีข้อบกพร่องเกี่ยวกับการรีดขึ้นรูป หรือการติดตั้งผลิตภัณฑ์ กรุณาติดต่อผู้ผลิตแผ่นหลังคาเหล็ก</Text>
                          </Section>
                        </React.Fragment>
                      }
                    </React.Fragment>
                  }

                  {/* crp */}
                  {crp &&
                    <React.Fragment>
                      {true &&
                        <React.Fragment>
                          <Section height='5' />
                          <Grid gutter='10'>
                            <Grid.Column grid='6'>
                              {/* Notice ============================================================= */}
                              <WarrantyNoticeTwoColumn />

                              {/* T & C ============================================================== */}
                              <Section paddingTop='4' spacingBottom='4'>
                                <div className="warranty-text is-xs">สัญญารับประกันฉบับนี้เป็นการรับประกันว่าแผ่นเหล็ก CRP Antibacterial ® SD หากได้รับการติดตั้งและบำรุง<br /> รักษาอย่างถูกต้องคาดว่าจะมีอายุการใช้งานอย่างน้อย ตามระยะเวลาการรับประกันที่กำหนดของผลิตภัณฑ์<br /> (นับจากวันที่ซื้อสินค้า)</div>
                              </Section>
                              <Section spacingBottom='4'>
                                <div className="warranty-text is-xs">อย่างไรก็ตาม ข้อบกพร่องเฉพาะหรือสถานการณ์ใดๆตามรายละเอียดดังต่อไปนี้ จะต้องได้รับการระบุและแก้ไข<br /> โดยเร็ว เพื่อที่จะให้เกิดผลดีที่สุดต่อคุณภาพของผลิตภัณฑ์ ทั้งนี้ข้อบกพร่องที่เกิดจากการไม่แก้ไข อาจมีผล<br />กระทบต่อการยกเลิกของการรับประกัน ดังหัวข้อต่อไปนี้</div>
                              </Section>
                              <Section spacingBottom='4'>
                                <Grid>
                                  <Grid.Column flex='none'>
                                    <Section width='10' align='flex-start'>
                                      <div className="warranty-text is-xs">1.</div>
                                    </Section>
                                  </Grid.Column>
                                  <Grid.Column flex='1'>
                                    <div className="warranty-text is-xs">การรับประกันครอบคลุมเฉพาะแผ่นผนัง ที่ทำจากแผ่นเหล็กคุณภาพชั้นหนึ่ง CRP Antibacterial ® SD เท่านั้น<br /> ทั้งนี้เจ้าของบ้าน/ผู้รับเหมา ควรตรวจสอบว่าเป็นของแท้โดยดูเครื่องหมายการค้า CRP Antibacterial ® SD<br /> ที่พิมพ์อยู่บนแผ่น ทั้งนี้การนำไปใช้งานเป็นรางน้ำ, ท่อระบายน้ำฝน, แผ่นเหล็กรอยต่อโครงสร้าง, ประตู<br /> และวัสดุประกอบอื่นๆ นั้น จะไม่รวมอยู่ภายใต้การรับประกัน</div>
                                  </Grid.Column>
                                </Grid>
                              </Section>
                              <Section spacingBottom='4'>
                                <Grid>
                                  <Grid.Column flex='none'>
                                    <Section width='10'>
                                      <div className="warranty-text is-xs">2.</div>
                                    </Section>
                                  </Grid.Column>
                                  <Grid.Column flex='1'>
                                    <div className="warranty-text is-xs">การเคลื่อนย้ายและติดตั้ง ต้องปฏิบัติอย่างถูกวิธีตามคำแนะนำของ บลูสโคป โดยระมัดระวังสิ่งต่อไปนี้เป็นพิเศษ</div>
                                  </Grid.Column>
                                </Grid>
                              </Section>
                              {/* WarrantySetupWarningList =========================================== */}
                              <WarrantySetupWarningListTwoColumn />

                              {/* T & C ============================================================== */}
                              <Section spacingBottom='4'>
                                <Grid>
                                  <Grid.Column flex='none'>
                                    <Section width='10'>
                                      <div className="warranty-text is-xs">3.</div>
                                    </Section>
                                  </Grid.Column>
                                  <Grid.Column flex='1'>
                                    <div className="warranty-text is-xs">หมุดยึดต้องเป็นไปตามมาตรฐาน AS 3566 คลาส 3 หรือ 4 โดยต้องได้รับการรับรองมาตรฐานจาก<br />ผู้ผลิตวัสดุนั้นๆ และต้องไม่ใช่เหล็กกล้าไร้สนิม</div>
                                  </Grid.Column>
                                </Grid>
                              </Section>
                              <Section spacingBottom='4'>
                                <Grid>
                                  <Grid.Column flex='none'>
                                    <Section width='10' align='flex-start'>
                                      <div className="warranty-text is-xs">4.</div>
                                    </Section>
                                  </Grid.Column>
                                  <Grid.Column flex='1'>
                                    <div className="warranty-text is-xs">แผ่นปิดครอบ หมุดยึด และวัสดุประกอบอื่นๆต้องผลิตจากวัสดุที่มีคุณสมบัติเทียบเท่ากับหลังคาและผนัง ซึ่ง<br /> เคลือบหรือชุบด้วยวัสดุตามคำแนะนำของบลูสโคป (แผ่นเหล็ก COLORBOND®, Zincalume®,<br /> BlueScope Zacs®, CRP SD® steel Antibacterial, สังกะสี, อลูมิเนียม) เพื่อให้ผลิตภัณฑ์มีคุณภาพ<br /> และระยะเวลาการใช้งานเหมือนกัน</div>
                                  </Grid.Column>
                                </Grid>
                              </Section>
                              <Section spacingBottom='4'>
                                <Grid>
                                  <Grid.Column flex='none'>
                                    <Section width='10'>
                                      <div className="warranty-text is-xs">5.</div>
                                    </Section>
                                  </Grid.Column>
                                  <Grid.Column flex='1'>
                                    <div className="warranty-text is-xs">สีของผลิตภัณฑ์ต้องไม่อยู่ในสภาพที่มีรอยขีดข่วน ถลอก ได้รับความเสียหาย หรือถูกเคลือบทับด้วยวัสดุแปลก<br />ปลอม หรือวัสดุที่ไม่มีคุณสมบัติเทียบเท่า (รวมทั้ง ปล่องไฟ หรือปล่องระบาย)</div>
                                  </Grid.Column>
                                </Grid>
                              </Section>
                            </Grid.Column>
                            <Grid.Column grid='6'>
                              {/* T & C ============================================================== */}
                              <Section spacingBottom='4'>
                                <Grid>
                                  <Grid.Column flex='none'>
                                    <Section width='10'>
                                      <div className="warranty-text is-xs">6.</div>
                                    </Section>
                                  </Grid.Column>
                                  <Grid.Column flex='1'>
                                    <div className="warranty-text is-xs">การเกิดการผุกร่อนบางส่วนหรือทั้งหมดอันเนื่องมาจากสิ่งต่อไปนี้ ไม่ถือว่าอยู่ภายใต้การรับประกัน</div>
                                  </Grid.Column>
                                </Grid>
                              </Section>

                              {/* WarrantyDecayList ================================================== */}
                              <WarrantyDecayListTwoColumn />

                              {/* T & C ============================================================== */}
                              <Section paddingTop='4' spacingBottom='4'>
                                <Grid>
                                  <Grid.Column flex='none'>
                                    <Section width='10' align='flex-start'>
                                      <div className="warranty-text is-xs">7.</div>
                                    </Section>
                                  </Grid.Column>
                                  <Grid.Column flex='1'>
                                    <div className="warranty-text is-xs">ความบกพร่องของผลิตภัณฑ์จากการเกิดสนิมหมายถึงแผ่นมีการผุกร่อนเป็นรู สนิมที่เกิดบนผิวของแผ่นหรือ<br /> การเกิดสนิมเล็กน้อยเนื่องจากการหักงอของแผ่น หรือการตัดปลายแผ่น ในสภาพแวดล้อมตามธรรมชาติทั่วไป<br /> ไม่ถือว่าเป็นความบกพร่องของผลิตภัณฑ์</div>
                                  </Grid.Column>
                                </Grid>
                              </Section>
                              <Section>
                                <div className="warranty-text is-xs is-bold">ทั้งนี้การรับประกันนี้เป็นการรับประกันคุณภาพของผลิตภัณฑ์เท่านั้น หากมีข้อบกพร่องเกี่ยวกับ<br />การรีดขึ้นรูป หรือการติดตั้งผลิตภัณฑ์ กรุณาติดต่อผู้ผลิตแผ่นผนัง</div>
                              </Section>
                            </Grid.Column>
                          </Grid>
                        </React.Fragment>
                      }

                      {/* One column */}
                      {false &&
                        <React.Fragment>
                          <WarrantyNotice />
                          <Section paddingTop='4' spacingBottom='4'>
                            <Text regular8 lineheightTh color={VARIABLES.COLORS.BLACK}>สัญญารับประกันฉบับนี้เป็นการรับประกันว่าแผ่นเหล็ก CRP Antibacterial ® SD หากได้รับการติดตั้งและบำรุงรักษาอย่างถูกต้องคาดว่าจะมีอายุการใช้งานอย่างน้อย ตามระยะเวลาการรับประกันที่กำหนดของผลิตภัณฑ์ (นับจากวันที่ซื้อสินค้า)</Text>
                          </Section>
                          <Section spacingBottom='4'>
                            <Text regular8 lineheightTh color={VARIABLES.COLORS.BLACK}>อย่างไรก็ตาม ข้อบกพร่องเฉพาะหรือสถานการณ์ใดๆตามรายละเอียดดังต่อไปนี้ จะต้องได้รับการระบุและแก้ไขโดยเร็ว เพื่อที่จะให้เกิดผลดีที่สุดต่อคุณภาพของผลิตภัณฑ์ ทั้งนี้ข้อบกพร่องที่เกิดจากการไม่แก้ไข อาจมีผลกระทบต่อการยกเลิกของ<br />การรับประกัน ดังหัวข้อต่อไปนี้</Text>
                          </Section>
                          <Section spacingBottom='-3'>
                            <Grid>
                              <Grid.Column flex='none'>
                                <Section width='10' align='flex-start'>
                                  <Text regular8 lineheightTh color={VARIABLES.COLORS.BLACK}>1.</Text>
                                </Section>
                              </Grid.Column>
                              <Grid.Column flex='1'>
                                <Text regular8 lineheightTh color={VARIABLES.COLORS.BLACK}>การรับประกันครอบคลุมเฉพาะแผ่นผนัง ที่ทำจากแผ่นเหล็กคุณภาพชั้นหนึ่ง CRP Antibacterial ® SD เท่านั้น ทั้งนี้เจ้าของบ้าน/ผู้รับเหมา ควรตรวจสอบว่าเป็นของแท้โดยดูเครื่องหมายการค้า<br /> CRP Antibacterial ® SD ที่พิมพ์อยู่บนแผ่น ทั้งนี้การนำไปใช้งานเป็นรางน้ำ, ท่อระบายน้ำฝน, แผ่นเหล็กรอยต่อโครงสร้าง, ประตู และวัสดุประกอบอื่นๆ นั้น จะไม่รวมอยู่ภายใต้การรับประกัน</Text>
                              </Grid.Column>
                            </Grid>
                          </Section>
                          <Section spacingBottom='-6'>
                            <Grid>
                              <Grid.Column flex='none'>
                                <Section width='10'>
                                  <Text regular8 lineheightTh color={VARIABLES.COLORS.BLACK}>2.</Text>
                                </Section>
                              </Grid.Column>
                              <Grid.Column flex='1'>
                                <Text regular8 lineheightTh color={VARIABLES.COLORS.BLACK}>การเคลื่อนย้ายและติดตั้ง ต้องปฏิบัติอย่างถูกวิธีตามคำแนะนำของ บลูสโคป โดยระมัดระวังสิ่งต่อไปนี้เป็นพิเศษ</Text>
                              </Grid.Column>
                            </Grid>
                          </Section>
                          <WarrantySetupWarningList />
                          <Section spacingBottom='4'>
                            <Grid>
                              <Grid.Column flex='none'>
                                <Section width='10'>
                                  <Text regular8 lineheightTh color={VARIABLES.COLORS.BLACK}>3.</Text>
                                </Section>
                              </Grid.Column>
                              <Grid.Column flex='1'>
                                <Text regular8 lineheightTh color={VARIABLES.COLORS.BLACK}>หมุดยึดต้องเป็นไปตามมาตรฐาน AS 3566 คลาส 3 หรือ 4 โดยต้องได้รับการรับรองมาตรฐานจากผู้ผลิตวัสดุนั้นๆ และต้องไม่ใช่เหล็กกล้าไร้สนิม</Text>
                              </Grid.Column>
                            </Grid>
                          </Section>
                          <Section spacingBottom='-3'>
                            <Grid>
                              <Grid.Column flex='none'>
                                <Section width='10' align='flex-start'>
                                  <Text regular8 lineheightTh color={VARIABLES.COLORS.BLACK}>4.</Text>
                                </Section>
                              </Grid.Column>
                              <Grid.Column flex='1'>
                                <Text regular8 lineheightTh color={VARIABLES.COLORS.BLACK}>แผ่นปิดครอบ หมุดยึด และวัสดุประกอบอื่นๆต้องผลิตจากวัสดุที่มีคุณสมบัติเทียบเท่ากับหลังคาและผนัง ซึ่งเคลือบหรือชุบด้วยวัสดุตามคำแนะนำของบลูสโคป (แผ่นเหล็ก COLORBOND®, Zincalume®, BlueScope Zacs®,<br /> CRP SD® steel Antibacterial, สังกะสี, อลูมิเนียม) เพื่อให้ผลิตภัณฑ์มีคุณภาพและระยะเวลาการใช้งานเหมือนกัน</Text>
                              </Grid.Column>
                            </Grid>
                          </Section>
                          <Section spacingBottom='-3'>
                            <Grid>
                              <Grid.Column flex='none'>
                                <Section width='10'>
                                  <Text regular8 lineheightTh color={VARIABLES.COLORS.BLACK}>5.</Text>
                                </Section>
                              </Grid.Column>
                              <Grid.Column flex='1'>
                                <Text regular8 lineheightTh color={VARIABLES.COLORS.BLACK}>สีของผลิตภัณฑ์ต้องไม่อยู่ในสภาพที่มีรอยขีดข่วน ถลอก ได้รับความเสียหาย หรือถูกเคลือบทับด้วยวัสดุแปลกปลอม หรือวัสดุที่ไม่มีคุณสมบัติเทียบเท่า (รวมทั้ง ปล่องไฟ หรือปล่องระบาย)</Text>
                              </Grid.Column>
                            </Grid>
                          </Section>
                          <Section spacingBottom='-6'>
                            <Grid>
                              <Grid.Column flex='none'>
                                <Section width='10'>
                                  <Text regular8 lineheightTh color={VARIABLES.COLORS.BLACK}>6.</Text>
                                </Section>
                              </Grid.Column>
                              <Grid.Column flex='1'>
                                <Text regular8 lineheightTh color={VARIABLES.COLORS.BLACK}>การเกิดการผุกร่อนบางส่วนหรือทั้งหมดอันเนื่องมาจากสิ่งต่อไปนี้ ไม่ถือว่าอยู่ภายใต้การรับประกัน</Text>
                              </Grid.Column>
                            </Grid>
                          </Section>
                          <WarrantyDecayList />
                          <Section paddingTop='4' spacingBottom='-3'>
                            <Grid>
                              <Grid.Column flex='none'>
                                <Section width='10' align='flex-start'>
                                  <Text regular8 lineheightTh color={VARIABLES.COLORS.BLACK}>7.</Text>
                                </Section>
                              </Grid.Column>
                              <Grid.Column flex='1'>
                                <Text regular8 lineheightTh color={VARIABLES.COLORS.BLACK}>ความบกพร่องของผลิตภัณฑ์จากการเกิดสนิมหมายถึงแผ่นมีการผุกร่อนเป็นรู สนิมที่เกิดบนผิวของแผ่นหรือการเกิดสนิมเล็กน้อยเนื่องจากการหักงอของแผ่น หรือการตัดปลายแผ่น ในสภาพแวดล้อมตามธรรมชาติทั่วไป ไม่ถือว่า<br />เป็นความบกพร่องของผลิตภัณฑ์</Text>
                              </Grid.Column>
                            </Grid>
                          </Section>
                          <Section>
                            <Text bold8 lineheightTh color={VARIABLES.COLORS.BLACK}>ทั้งนี้การรับประกันนี้เป็นการรับประกันคุณภาพของผลิตภัณฑ์เท่านั้น หากมีข้อบกพร่องเกี่ยวกับการรีดขึ้นรูป หรือการติดตั้งผลิตภัณฑ์ กรุณาติดต่อผู้ผลิตแผ่นผนัง</Text>
                          </Section>
                        </React.Fragment>
                      }
                    </React.Fragment>
                  }

                  {/* jingjoeLek */}
                  {jingjoeLek &&
                    <React.Fragment>
                      {true &&
                        <React.Fragment>
                          <Section height='5' />
                          <Grid gutter='10'>
                            <Grid.Column grid='6'>
                              {/* Notice ============================================================= */}
                              <WarrantyNoticeTwoColumn />

                              {/* T & C ============================================================== */}
                              <Section spacingBottom='4'>
                                <div className="warranty-text is-xs">สัญญารับประกันฉบับนี้เป็นการรับประกันว่าแผ่นเหล็ก จิงโจ้เหล็ก® หากได้รับการติดตั้งและบำรุงรักษาอย่าง<br />ถูกต้องคาดว่าจะมีอายุการใช้งานอย่างน้อยตามระยะเวลาการรับประกันที่กำหนดของผลิตภัณฑ์<br /> (นับจากวันที่ซื้อสินค้า)</div>
                              </Section>
                              <Section spacingBottom='4'>
                                <div className="warranty-text is-xs">อย่างไรก็ตาม ข้อบกพร่องเฉพาะหรือสถานการณ์ใดๆตามรายละเอียดดังต่อไปนี้ จะต้องได้รับการระบุและแก้ไข<br /> โดยเร็ว เพื่อที่จะให้เกิดผลดีที่สุดต่อคุณภาพของผลิตภัณฑ์ ทั้งนี้ข้อบกพร่องที่เกิดจากการไม่แก้ไข อาจมี<br /> ผลกระทบต่อการยกเลิกของการรับประกัน ดังหัวข้อต่อไปนี้</div>
                              </Section>
                              <Section spacingBottom='4'>
                                <Grid>
                                  <Grid.Column flex='none'>
                                    <Section width='10' align='flex-start'>
                                      <div className="warranty-text is-xs">1.</div>
                                    </Section>
                                  </Grid.Column>
                                  <Grid.Column flex='1'>
                                    <div className="warranty-text is-xs">การรับประกันครอบคลุมเฉพาะหลังคาและผนัง ที่ทำจากแผ่นเหล็กคุณภาพชั้นหนึ่ง จิงโจ้เหล็ก® เท่านั้น ทั้งนี้<br /> เจ้าของบ้าน/ผู้รับเหมาควรตรวจสอบว่าเป็นของแท้โดยดูเครื่องหมายการค้า จิงโจ้เหล็ก® ที่พิมพ์อยู่ใต้แผ่น ทั้งนี้<br /> การนำไปใช้งานเป็นรางน้ำ, ท่อระบายน้ำฝน, แผ่นเหล็กรอยต่อโครงสร้าง, ประตู และวัสดุประกอบอื่นๆ นั้น<br /> จะไม่รวมอยู่ภายใต้การรับประกัน</div>
                                  </Grid.Column>
                                </Grid>
                              </Section>
                              <Section spacingBottom='4'>
                                <Grid>
                                  <Grid.Column flex='none'>
                                    <Section width='10'>
                                      <div className="warranty-text is-xs">2.</div>
                                    </Section>
                                  </Grid.Column>
                                  <Grid.Column flex='1'>
                                    <div className="warranty-text is-xs">การเคลื่อนย้ายและติดตั้ง ต้องปฏิบัติอย่างถูกวิธีตามคำแนะนำของ บลูสโคป โดยระมัดระวังสิ่งต่อไปนี้เป็นพิเศษ </div>
                                  </Grid.Column>
                                </Grid>
                              </Section>

                              {/* WarrantySetupWarningList =========================================== */}
                              <WarrantySetupWarningListTwoColumn />

                              {/* T & C ============================================================== */}
                              <Section spacingBottom='4'>
                                <Grid>
                                  <Grid.Column flex='none'>
                                    <Section width='10'>
                                      <div className="warranty-text is-xs">3.</div>
                                    </Section>
                                  </Grid.Column>
                                  <Grid.Column flex='1'>
                                    <div className="warranty-text is-xs">หมุดยึดต้องเป็นไปตามมาตรฐาน AS 3566 คลาส 3 หรือ 4 โดยต้องได้รับการรับรองมาตรฐานจาก<br />ผู้ผลิตวัสดุนั้นๆ และต้องไม่ใช่เหล็กกล้าไร้สนิม</div>
                                  </Grid.Column>
                                </Grid>
                              </Section>
                              <Section spacingBottom='4'>
                                <Grid>
                                  <Grid.Column flex='none'>
                                    <Section width='10' align='flex-start'>
                                      <div className="warranty-text is-xs">4.</div>
                                    </Section>
                                  </Grid.Column>
                                  <Grid.Column flex='1'>
                                    <div className="warranty-text is-xs">แผ่นปิดครอบ หมุดยึด และวัสดุประกอบอื่นๆต้องผลิตจากวัสดุที่มีคุณสมบัติเทียบเท่ากับหลังคาและผนัง<br /> ซึ่งเคลือบหรือชุบด้วยวัสดุตามคำแนะนำของบลูสโคป (แผ่นเหล็ก COLORBOND®, Zincalume®,<br /> BlueScope Zacs®, สังกะสี, อลูมิเนียม) เพื่อให้ผลิตภัณฑ์มีคุณภาพและระยะเวลาการใช้งานเหมือนกัน</div>
                                  </Grid.Column>
                                </Grid>
                              </Section>
                              <Section spacingBottom='4'>
                                <Grid>
                                  <Grid.Column flex='none'>
                                    <Section width='10'>
                                      <div className="warranty-text is-xs">5.</div>
                                    </Section>
                                  </Grid.Column>
                                  <Grid.Column flex='1'>
                                    <div className="warranty-text is-xs">สีของผลิตภัณฑ์ต้องไม่อยู่ในสภาพที่มีรอยขีดข่วน ถลอก ได้รับความเสียหาย หรือถูกเคลือบทับด้วยวัสดุแปลก<br />ปลอม หรือวัสดุที่ไม่มีคุณสมบัติเทียบเท่า (รวมทั้ง ปล่องไฟ หรือปล่องระบาย)</div>
                                  </Grid.Column>
                                </Grid>
                              </Section>
                            </Grid.Column>
                            <Grid.Column grid='6'>
                              {/* T & C ============================================================== */}
                              <Section spacingBottom='4'>
                                <Grid>
                                  <Grid.Column flex='none'>
                                    <Section width='10'>
                                      <div className="warranty-text is-xs">6.</div>
                                    </Section>
                                  </Grid.Column>
                                  <Grid.Column flex='1'>
                                    <div className="warranty-text is-xs">การเกิดการผุกร่อนบางส่วนหรือทั้งหมดอันเนื่องมาจากสิ่งต่อไปนี้ ไม่ถือว่าอยู่ภายใต้การรับประกัน</div>
                                  </Grid.Column>
                                </Grid>
                              </Section>

                              {/* WarrantyDecayList ================================================== */}
                              <WarrantyDecayListTwoColumn />

                              {/* T & C ============================================================== */}
                              <Section paddingTop='4' spacingBottom='4'>
                                <Grid>
                                  <Grid.Column flex='none'>
                                    <Section width='10' align='flex-end'>
                                      <div className="warranty-text is-xs">7.</div>
                                    </Section>
                                  </Grid.Column>
                                  <Grid.Column flex='1'>
                                    <div className="warranty-text is-xs">ความบกพร่องของผลิตภัณฑ์จากการเกิดสนิมหมายถึงแผ่นมีการผุกร่อนเป็นรู สนิมที่เกิดบนผิวของแผ่นหรือ<br />การเกิดสนิมเล็กน้อยเนื่องจากการหักงอของแผ่น หรือการตัดปลายแผ่น ในสภาพแวดล้อมตามธรรมชาติทั่วไป<br /> ไม่ถือว่าเป็นความบกพร่องของผลิตภัณฑ์</div>
                                  </Grid.Column>
                                </Grid>
                              </Section>
                              <Section>
                                <div className="warranty-text is-xs is-bold">ทั้งนี้การรับประกันนี้เป็นการรับประกันคุณภาพของผลิตภัณฑ์เท่านั้น หากมีข้อบกพร่องเกี่ยวกับ<br />การรีดขึ้นรูป หรือการติดตั้งผลิตภัณฑ์ กรุณาติดต่อผู้ผลิต </div>
                              </Section>
                            </Grid.Column>
                          </Grid>
                        </React.Fragment>
                      }

                      {/* One column */}
                      {false &&
                        <React.Fragment>
                          <WarrantyNotice />
                          <Section>
                            <Text regular8 lineheightTh color={VARIABLES.COLORS.BLACK}>สัญญารับประกันฉบับนี้เป็นการรับประกันว่าแผ่นเหล็ก จิงโจ้เหล็ก® หากได้รับการติดตั้งและบำรุงรักษาอย่างถูกต้องคาดว่าจะมีอายุการใช้งานอย่างน้อยตามระยะเวลาการรับประกันที่กำหนดของผลิตภัณฑ์ (นับจากวันที่ซื้อสินค้า)</Text>
                          </Section>
                          <Section spacingBottom='4'>
                            <Text regular8 lineheightTh color={VARIABLES.COLORS.BLACK}>อย่างไรก็ตาม ข้อบกพร่องเฉพาะหรือสถานการณ์ใดๆตามรายละเอียดดังต่อไปนี้ จะต้องได้รับการระบุและแก้ไขโดยเร็ว เพื่อที่จะให้เกิดผลดีที่สุดต่อคุณภาพของผลิตภัณฑ์ ทั้งนี้ข้อบกพร่องที่เกิดจากการไม่แก้ไข อาจมีผลกระทบ<br />ต่อการยกเลิกของการรับประกัน ดังหัวข้อต่อไปนี้</Text>
                          </Section>
                          <Section spacingBottom='-3'>
                            <Grid>
                              <Grid.Column flex='none'>
                                <Section width='10' align='flex-start'>
                                  <Text regular8 lineheightTh color={VARIABLES.COLORS.BLACK}>1.</Text>
                                </Section>
                              </Grid.Column>
                              <Grid.Column flex='1'>
                                <Text regular8 lineheightTh color={VARIABLES.COLORS.BLACK}>การรับประกันครอบคลุมเฉพาะหลังคาและผนัง ที่ทำจากแผ่นเหล็กคุณภาพชั้นหนึ่ง จิงโจ้เหล็ก® เท่านั้น ทั้งนี้เจ้าของบ้าน/ผู้รับเหมาควรตรวจสอบว่าเป็นของแท้โดยดูเครื่องหมายการค้า จิงโจ้เหล็ก® ที่พิมพ์อยู่ใต้แผ่น ทั้งนี้<br />การนำไปใช้งานเป็นรางน้ำ, ท่อระบายน้ำฝน, แผ่นเหล็กรอยต่อโครงสร้าง, ประตู และวัสดุประกอบอื่นๆ นั้น จะไม่รวมอยู่ภายใต้การรับประกัน</Text>
                              </Grid.Column>
                            </Grid>
                          </Section>
                          <Section spacingBottom='-6'>
                            <Grid>
                              <Grid.Column flex='none'>
                                <Section width='10'>
                                  <Text regular8 lineheightTh color={VARIABLES.COLORS.BLACK}>2.</Text>
                                </Section>
                              </Grid.Column>
                              <Grid.Column flex='1'>
                                <Text regular8 lineheightTh color={VARIABLES.COLORS.BLACK}>การเคลื่อนย้ายและติดตั้ง ต้องปฏิบัติอย่างถูกวิธีตามคำแนะนำของ บลูสโคป โดยระมัดระวังสิ่งต่อไปนี้เป็นพิเศษ </Text>
                              </Grid.Column>
                            </Grid>
                          </Section>
                          <WarrantySetupWarningList />
                          <Section spacingBottom='4'>
                            <Grid>
                              <Grid.Column flex='none'>
                                <Section width='10'>
                                  <Text regular8 lineheightTh color={VARIABLES.COLORS.BLACK}>3.</Text>
                                </Section>
                              </Grid.Column>
                              <Grid.Column flex='1'>
                                <Text regular8 lineheightTh color={VARIABLES.COLORS.BLACK}>หมุดยึดต้องเป็นไปตามมาตรฐาน AS 3566 คลาส 3 หรือ 4 โดยต้องได้รับการรับรองมาตรฐานจากผู้ผลิตวัสดุนั้นๆ และต้องไม่ใช่เหล็กกล้าไร้สนิม</Text>
                              </Grid.Column>
                            </Grid>
                          </Section>
                          <Section spacingBottom='-3'>
                            <Grid>
                              <Grid.Column flex='none'>
                                <Section width='10' align='flex-start'>
                                  <Text regular8 lineheightTh color={VARIABLES.COLORS.BLACK}>4.</Text>
                                </Section>
                              </Grid.Column>
                              <Grid.Column flex='1'>
                                <Text regular8 lineheightTh color={VARIABLES.COLORS.BLACK}>แผ่นปิดครอบ หมุดยึด และวัสดุประกอบอื่นๆต้องผลิตจากวัสดุที่มีคุณสมบัติเทียบเท่ากับหลังคาและผนัง ซึ่งเคลือบหรือชุบด้วยวัสดุตามคำแนะนำของบลูสโคป (แผ่นเหล็ก COLORBOND®, Zincalume®,<br /> BlueScope Zacs®, สังกะสี, อลูมิเนียม) เพื่อให้ผลิตภัณฑ์มีคุณภาพและระยะเวลาการใช้งานเหมือนกัน</Text>
                              </Grid.Column>
                            </Grid>
                          </Section>
                          <Section spacingBottom='-3'>
                            <Grid>
                              <Grid.Column flex='none'>
                                <Section width='10'>
                                  <Text regular8 lineheightTh color={VARIABLES.COLORS.BLACK}>5.</Text>
                                </Section>
                              </Grid.Column>
                              <Grid.Column flex='1'>
                                <Text regular8 lineheightTh color={VARIABLES.COLORS.BLACK}>สีของผลิตภัณฑ์ต้องไม่อยู่ในสภาพที่มีรอยขีดข่วน ถลอก ได้รับความเสียหาย หรือถูกเคลือบทับด้วยวัสดุแปลกปลอม หรือวัสดุที่ไม่มีคุณสมบัติเทียบเท่า (รวมทั้ง ปล่องไฟ หรือปล่องระบาย)</Text>
                              </Grid.Column>
                            </Grid>
                          </Section>
                          <Section spacingBottom='-6'>
                            <Grid>
                              <Grid.Column flex='none'>
                                <Section width='10'>
                                  <Text regular8 lineheightTh color={VARIABLES.COLORS.BLACK}>6.</Text>
                                </Section>
                              </Grid.Column>
                              <Grid.Column flex='1'>
                                <Text regular8 lineheightTh color={VARIABLES.COLORS.BLACK}>การเกิดการผุกร่อนบางส่วนหรือทั้งหมดอันเนื่องมาจากสิ่งต่อไปนี้ ไม่ถือว่าอยู่ภายใต้การรับประกัน</Text>
                              </Grid.Column>
                            </Grid>
                          </Section>
                          <WarrantyDecayList />
                          <Section paddingTop='4' spacingBottom='-3'>
                            <Grid>
                              <Grid.Column flex='none'>
                                <Section width='10' align='flex-end'>
                                  <Text regular8 lineheightTh color={VARIABLES.COLORS.BLACK}>7.</Text>
                                </Section>
                              </Grid.Column>
                              <Grid.Column flex='1'>
                                <Text regular8 lineheightTh color={VARIABLES.COLORS.BLACK}>ความบกพร่องของผลิตภัณฑ์จากการเกิดสนิมหมายถึงแผ่นมีการผุกร่อนเป็นรู สนิมที่เกิดบนผิวของแผ่นหรือการเกิดสนิมเล็กน้อยเนื่องจากการหักงอของแผ่น หรือการตัดปลายแผ่น ในสภาพแวดล้อมตามธรรมชาติทั่วไป ไม่ถือว่า<br />เป็นความบกพร่องของผลิตภัณฑ์</Text>
                              </Grid.Column>
                            </Grid>
                          </Section>
                          <Section>
                            <Text bold8 lineheightTh color={VARIABLES.COLORS.BLACK}>ทั้งนี้การรับประกันนี้เป็นการรับประกันคุณภาพของผลิตภัณฑ์เท่านั้น หากมีข้อบกพร่องเกี่ยวกับการรีดขึ้นรูป หรือการติดตั้งผลิตภัณฑ์ กรุณาติดต่อผู้ผลิต</Text>
                          </Section>
                        </React.Fragment>
                      }
                    </React.Fragment>
                  }

                  {/* prima */}
                  {prima &&
                    <React.Fragment>
                      {true &&
                        <React.Fragment>
                          <Section height='5' />
                          <Grid gutter='10'>
                            <Grid.Column grid='6'>
                              {/* Notice ============================================================= */}
                              <WarrantyNoticeTwoColumn />

                              {/* T & C ============================================================== */}
                              <Section spacingBottom='4'>
                                <div className="warranty-text is-xs">สัญญารับประกันฉบับนี้เป็นการรับประกันว่าแผ่นเหล็ก Prima® หากได้รับการติดตั้งและบำรุงรักษาอย่างถูกต้อง<br />คาดว่าจะมีอายุการใช้งานอย่างน้อยตามระยะเวลาการรับประกันที่กำหนดของผลิตภัณฑ์ (นับจากวันที่ซื้อสินค้า)</div>
                              </Section>
                              <Section spacingBottom='4'>
                                <div className="warranty-text is-xs">อย่างไรก็ตาม ข้อบกพร่องเฉพาะหรือสถานการณ์ใดๆตามรายละเอียดดังต่อไปนี้ จะต้องได้รับการระบุและแก้ไข<br /> โดยเร็ว เพื่อที่จะให้เกิดผลดีที่สุดต่อคุณภาพของผลิตภัณฑ์ ทั้งนี้ข้อบกพร่องที่เกิดจากการไม่แก้ไข<br /> อาจมีผลกระทบต่อการยกเลิกของการรับประกัน ดังหัวข้อต่อไปนี้</div>
                              </Section>
                              <Section spacingBottom='4'>
                                <Grid>
                                  <Grid.Column flex='none'>
                                    <Section width='10' align='flex-start'>
                                      <div className="warranty-text is-xs">1.</div>
                                    </Section>
                                  </Grid.Column>
                                  <Grid.Column flex='1'>
                                    <div className="warranty-text is-xs">การรับประกันครอบคลุมเฉพาะหลังคาและผนัง ที่ทำจากแผ่นเหล็กคุณภาพชั้นหนึ่ง Prima® เท่านั้น ทั้งนี้<br /> เจ้าของบ้าน/ผู้รับเหมา ควรตรวจสอบว่าเป็นของแท้โดยดูเครื่องหมายการค้า Prima® ที่พิมพ์อยู่ใต้แผ่น<br /> ทั้งนี้การนำไปใช้งานเป็นรางน้ำ, ท่อระบายน้ำฝน, แผ่นเหล็กรอยต่อโครงสร้าง, ประตู และวัสดุประกอบอื่นๆ <br /> นั้น จะไม่รวมอยู่ภายใต้การรับประกัน</div>
                                  </Grid.Column>
                                </Grid>
                              </Section>
                              <Section spacingBottom='4'>
                                <Grid>
                                  <Grid.Column flex='none'>
                                    <Section width='10'>
                                      <div className="warranty-text is-xs">2.</div>
                                    </Section>
                                  </Grid.Column>
                                  <Grid.Column flex='1'>
                                    <div className="warranty-text is-xs">การเคลื่อนย้ายและติดตั้ง ต้องปฏิบัติอย่างถูกวิธีตามคำแนะนำของ บลูสโคป โดยระมัดระวังสิ่งต่อไปนี้เป็นพิเศษ</div>
                                  </Grid.Column>
                                </Grid>
                              </Section>

                              {/* WarrantySetupWarningList =========================================== */}
                              <WarrantySetupWarningListTwoColumn />
                              <Section spacingBottom='4'>
                                <Grid>
                                  <Grid.Column flex='none'>
                                    <Section width='10'>
                                      <div className="warranty-text is-xs">3.</div>
                                    </Section>
                                  </Grid.Column>
                                  <Grid.Column flex='1'>
                                    <div className="warranty-text is-xs">หมุดยึดต้องเป็นไปตามมาตรฐาน AS 3566 คลาส 3 หรือ 4 โดยต้องได้รับการรับรองมาตรฐานจาก<br />ผู้ผลิตวัสดุนั้นๆ และต้องไม่ใช่เหล็กกล้าไร้สนิม</div>
                                  </Grid.Column>
                                </Grid>
                              </Section>
                              <Section spacingBottom='4'>
                                <Grid>
                                  <Grid.Column flex='none'>
                                    <Section width='10' align='flex-start'>
                                      <div className="warranty-text is-xs">4.</div>
                                    </Section>
                                  </Grid.Column>
                                  <Grid.Column flex='1'>
                                    <div className="warranty-text is-xs">แผ่นปิดครอบ หมุดยึด และวัสดุประกอบอื่นๆต้องผลิตจากวัสดุที่มีคุณสมบัติเทียบเท่ากับหลังคาและผนัง ซึ่งเคลือบหรือชุบด้วยวัสดุตามคำแนะนำของบลูสโคป (แผ่นเหล็ก COLORBOND®, Zincalume®, BlueScope Zacs®, สังกะสี, อลูมิเนียม) เพื่อให้ผลิตภัณฑ์มีคุณภาพและระยะเวลาการใช้งานเหมือนกัน</div>
                                  </Grid.Column>
                                </Grid>
                              </Section>
                              <Section spacingBottom='4'>
                                <Grid>
                                  <Grid.Column flex='none'>
                                    <Section width='10'>
                                      <div className="warranty-text is-xs">5.</div>
                                    </Section>
                                  </Grid.Column>
                                  <Grid.Column flex='1'>
                                    <div className="warranty-text is-xs">สีของผลิตภัณฑ์ต้องไม่อยู่ในสภาพที่มีรอยขีดข่วน ถลอก ได้รับความเสียหาย หรือถูกเคลือบทับด้วยวัสดุแปลก<br />ปลอม หรือวัสดุที่ไม่มีคุณสมบัติเทียบเท่า (รวมทั้ง ปล่องไฟ หรือปล่องระบาย) </div>
                                  </Grid.Column>
                                </Grid>
                              </Section>
                            </Grid.Column>
                            <Grid.Column grid='6'>
                              {/* T & C ============================================================== */}
                              <Section spacingBottom='4'>
                                <Grid>
                                  <Grid.Column flex='none'>
                                    <Section width='10'>
                                      <div className="warranty-text is-xs">6.</div>
                                    </Section>
                                  </Grid.Column>
                                  <Grid.Column flex='1'>
                                    <div className="warranty-text is-xs">การเกิดการผุกร่อนบางส่วนหรือทั้งหมดอันเนื่องมาจากสิ่งต่อไปนี้ ไม่ถือว่าอยู่ภายใต้การรับประกัน</div>
                                  </Grid.Column>
                                </Grid>
                              </Section>

                              {/* WarrantyDecayList ================================================== */}
                              <WarrantyDecayListTwoColumn />

                              {/* T & C ============================================================== */}
                              <Section paddingTop='4' spacingBottom='4'>
                                <Grid>
                                  <Grid.Column flex='none'>
                                    <Section width='10' align='flex-start'>
                                      <div className="warranty-text is-xs">7.</div>
                                    </Section>
                                  </Grid.Column>
                                  <Grid.Column flex='1'>
                                    <div className="warranty-text is-xs">ความบกพร่องของผลิตภัณฑ์จากการเกิดสนิมหมายถึงแผ่นมีการผุกร่อนเป็นรู สนิมที่เกิดบนผิวของแผ่นหรือ<br /> การเกิดสนิมเล็กน้อยเนื่องจากการหักงอของแผ่นหรือการตัดปลายแผ่น ในสภาพแวดล้อมตามธรรมชาติทั่วไป<br /> ไม่ถือว่าเป็นความบกพร่องของผลิตภัณฑ์</div>
                                  </Grid.Column>
                                </Grid>
                              </Section>
                              <Section>
                                <div className="warranty-text is-xs is-bold">ทั้งนี้การรับประกันนี้เป็นการรับประกันคุณภาพของผลิตภัณฑ์เท่านั้น หากมีข้อบกพร่องเกี่ยวกับ<br />การรีดขึ้นรูป หรือการติดตั้งผลิตภัณฑ์ กรุณาติดต่อผู้ผลิต</div>
                              </Section>
                            </Grid.Column>
                          </Grid>
                        </React.Fragment>
                      }

                      {/* One column */}
                      {false &&
                        <React.Fragment>
                          <WarrantyNotice />
                          <Section>
                            <Text regular8 lineheightTh color={VARIABLES.COLORS.BLACK}>สัญญารับประกันฉบับนี้เป็นการรับประกันว่าแผ่นเหล็ก Prima® หากได้รับการติดตั้งและบำรุงรักษาอย่างถูกต้องคาดว่าจะมีอายุการใช้งานอย่างน้อยตามระยะเวลาการรับประกันที่กำหนดของผลิตภัณฑ์ (นับจากวันที่ซื้อสินค้า)</Text>
                          </Section>
                          <Section spacingBottom='4'>
                            <Text regular8 lineheightTh color={VARIABLES.COLORS.BLACK}>อย่างไรก็ตาม ข้อบกพร่องเฉพาะหรือสถานการณ์ใดๆตามรายละเอียดดังต่อไปนี้ จะต้องได้รับการระบุและแก้ไขโดยเร็ว เพื่อที่จะให้เกิดผลดีที่สุดต่อคุณภาพของผลิตภัณฑ์ ทั้งนี้ข้อบกพร่องที่เกิดจากการไม่แก้ไข อาจมีผลกระทบ<br />ต่อการยกเลิกของการรับประกัน ดังหัวข้อต่อไปนี้</Text>
                          </Section>
                          <Section spacingBottom='-3'>
                            <Grid>
                              <Grid.Column flex='none'>
                                <Section width='10' align='flex-start'>
                                  <Text regular8 lineheightTh color={VARIABLES.COLORS.BLACK}>1.</Text>
                                </Section>
                              </Grid.Column>
                              <Grid.Column flex='1'>
                                <Text regular8 lineheightTh color={VARIABLES.COLORS.BLACK}>การรับประกันครอบคลุมเฉพาะหลังคาและผนัง ที่ทำจากแผ่นเหล็กคุณภาพชั้นหนึ่ง Prima® เท่านั้น ทั้งนี้เจ้าของบ้าน/ผู้รับเหมา ควรตรวจสอบว่าเป็นของแท้โดยดูเครื่องหมายการค้า Prima® ที่พิมพ์อยู่ใต้แผ่น ทั้งนี้การนำไปใช้งาน<br />เป็นรางน้ำ, ท่อระบายน้ำฝน, แผ่นเหล็กรอยต่อโครงสร้าง, ประตู และวัสดุประกอบอื่นๆ นั้น จะไม่รวมอยู่ภายใต้การรับประกัน</Text>
                              </Grid.Column>
                            </Grid>
                          </Section>
                          <Section spacingBottom='-6'>
                            <Grid>
                              <Grid.Column flex='none'>
                                <Section width='10'>
                                  <Text regular8 lineheightTh color={VARIABLES.COLORS.BLACK}>2.</Text>
                                </Section>
                              </Grid.Column>
                              <Grid.Column flex='1'>
                                <Text regular8 lineheightTh color={VARIABLES.COLORS.BLACK}>การเคลื่อนย้ายและติดตั้ง ต้องปฏิบัติอย่างถูกวิธีตามคำแนะนำของ บลูสโคป โดยระมัดระวังสิ่งต่อไปนี้เป็นพิเศษ</Text>
                              </Grid.Column>
                            </Grid>
                          </Section>
                          <WarrantySetupWarningList />
                          <Section spacingBottom='4'>
                            <Grid>
                              <Grid.Column flex='none'>
                                <Section width='10'>
                                  <Text regular8 lineheightTh color={VARIABLES.COLORS.BLACK}>3.</Text>
                                </Section>
                              </Grid.Column>
                              <Grid.Column flex='1'>
                                <Text regular8 lineheightTh color={VARIABLES.COLORS.BLACK}>หมุดยึดต้องเป็นไปตามมาตรฐาน AS 3566 คลาส 3 หรือ 4 โดยต้องได้รับการรับรองมาตรฐานจากผู้ผลิตวัสดุนั้นๆ และต้องไม่ใช่เหล็กกล้าไร้สนิม</Text>
                              </Grid.Column>
                            </Grid>
                          </Section>
                          <Section spacingBottom='-3'>
                            <Grid>
                              <Grid.Column flex='none'>
                                <Section width='10' align='flex-start'>
                                  <Text regular8 lineheightTh color={VARIABLES.COLORS.BLACK}>4.</Text>
                                </Section>
                              </Grid.Column>
                              <Grid.Column flex='1'>
                                <Text regular8 lineheightTh color={VARIABLES.COLORS.BLACK}>แผ่นปิดครอบ หมุดยึด และวัสดุประกอบอื่นๆต้องผลิตจากวัสดุที่มีคุณสมบัติเทียบเท่ากับหลังคาและผนัง ซึ่งเคลือบหรือชุบด้วยวัสดุตามคำแนะนำของบลูสโคป (แผ่นเหล็ก COLORBOND®, Zincalume®, BlueScope Zacs®, สังกะสี, อลูมิเนียม) เพื่อให้ผลิตภัณฑ์มีคุณภาพและระยะเวลาการใช้งานเหมือนกัน</Text>
                              </Grid.Column>
                            </Grid>
                          </Section>
                          <Section spacingBottom='-3'>
                            <Grid>
                              <Grid.Column flex='none'>
                                <Section width='10'>
                                  <Text regular8 lineheightTh color={VARIABLES.COLORS.BLACK}>5.</Text>
                                </Section>
                              </Grid.Column>
                              <Grid.Column flex='1'>
                                <Text regular8 lineheightTh color={VARIABLES.COLORS.BLACK}>สีของผลิตภัณฑ์ต้องไม่อยู่ในสภาพที่มีรอยขีดข่วน ถลอก ได้รับความเสียหาย หรือถูกเคลือบทับด้วยวัสดุแปลกปลอม หรือวัสดุที่ไม่มีคุณสมบัติเทียบเท่า (รวมทั้ง ปล่องไฟ หรือปล่องระบาย)</Text>
                              </Grid.Column>
                            </Grid>
                          </Section>
                          <Section spacingBottom='-6'>
                            <Grid>
                              <Grid.Column flex='none'>
                                <Section width='10'>
                                  <Text regular8 lineheightTh color={VARIABLES.COLORS.BLACK}>6.</Text>
                                </Section>
                              </Grid.Column>
                              <Grid.Column flex='1'>
                                <Text regular8 lineheightTh color={VARIABLES.COLORS.BLACK}>การเกิดการผุกร่อนบางส่วนหรือทั้งหมดอันเนื่องมาจากสิ่งต่อไปนี้ ไม่ถือว่าอยู่ภายใต้การรับประกัน</Text>
                              </Grid.Column>
                            </Grid>
                          </Section>
                          <WarrantyDecayList />
                          <Section paddingTop='4' spacingBottom='-3'>
                            <Grid>
                              <Grid.Column flex='none'>
                                <Section width='10' align='flex-start'>
                                  <Text regular8 lineheightTh color={VARIABLES.COLORS.BLACK}>7.</Text>
                                </Section>
                              </Grid.Column>
                              <Grid.Column flex='1'>
                                <Text regular8 lineheightTh color={VARIABLES.COLORS.BLACK}>ความบกพร่องของผลิตภัณฑ์จากการเกิดสนิมหมายถึงแผ่นมีการผุกร่อนเป็นรู สนิมที่เกิดบนผิวของแผ่นหรือการเกิดสนิมเล็กน้อยเนื่องจากการหักงอของแผ่นหรือการตัดปลายแผ่น ในสภาพแวดล้อมตามธรรมชาติทั่วไป ไม่ถือว่า<br />เป็นความบกพร่องของผลิตภัณฑ์</Text>
                              </Grid.Column>
                            </Grid>
                          </Section>
                          <Section>
                            <Text bold8 lineheightTh color={VARIABLES.COLORS.BLACK}>ทั้งนี้การรับประกันนี้เป็นการรับประกันคุณภาพของผลิตภัณฑ์เท่านั้น หากมีข้อบกพร่องเกี่ยวกับการรีดขึ้นรูป หรือการติดตั้งผลิตภัณฑ์ กรุณาติดต่อผู้ผลิตแผ่นหลังคาเหล็ก</Text>
                          </Section>
                        </React.Fragment>
                      }
                    </React.Fragment>
                  }

                  {/* superDymaDk */}
                  {superDymaDk &&
                    <React.Fragment>
                      {true &&
                        <React.Fragment>
                          <Section height='5' />
                          <Grid gutter='10'>
                            <Grid.Column grid='6'>
                              {/* Notice ============================================================= */}
                              <WarrantyNoticeTwoColumn />

                              {/* T & C ============================================================== */}
                              <Section spacingBottom='4'>
                                <div className="warranty-text is-xs">สัญญารับประกันฉบับนี้เป็นการรับประกันว่าแผ่นเหล็ก SuperDyma ® TF หากได้รับการติดตั้งและบำรุงรักษา<br /> อย่างถูกต้องคาดว่าจะมีอายุการใช้งานอย่างน้อยตามระยะเวลาการรับประกันที่กำหนดของผลิตภัณฑ์<br /> (นับจากวันที่ซื้อสินค้า)</div>
                              </Section>
                              <Section spacingBottom='4'>
                                <div className="warranty-text is-xs">อย่างไรก็ตาม ข้อบกพร่องเฉพาะหรือสถานการณ์ใดๆตามรายละเอียดดังต่อไปนี้ จะต้องได้รับการระบุและแก้ไข<br /> โดยเร็ว เพื่อที่จะให้เกิดผลดีที่สุดต่อคุณภาพของผลิตภัณฑ์ ทั้งนี้ข้อบกพร่องที่เกิดจากการไม่แก้ไข<br /> อาจมีผลกระทบต่อการยกเลิกของการรับประกัน ดังหัวข้อต่อไปนี้</div>
                              </Section>
                              <Section spacingBottom='4'>
                                <Grid>
                                  <Grid.Column flex='none'>
                                    <Section width='10' align='flex-start'>
                                      <div className="warranty-text is-xs">1.</div>
                                    </Section>
                                  </Grid.Column>
                                  <Grid.Column flex='1'>
                                    <div className="warranty-text is-xs">การรับประกันครอบคลุมเฉพาะหลังคาและผนัง ที่ทำจากแผ่นเหล็กคุณภาพชั้นหนึ่ง SuperDyma ® TF K14,<br /> ZM140  เท่านั้น ทั้งนี้เจ้าของบ้าน/ผู้รับเหมา ควรตรวจสอบว่าเป็นของแท้โดยดูเครื่องหมายการค้า<br /> SuperDyma ® TF ที่พิมพ์อยู่ใต้แผ่น ทั้งนี้การนำไปใช้งานเป็นรางน้ำ, ท่อระบายน้ำฝน, แผ่นเหล็กรอยต่อ<br /> โครงสร้าง, ประตู และวัสดุประกอบอื่นๆ นั้น จะไม่รวมอยู่ภายใต้การรับประกัน</div>
                                  </Grid.Column>
                                </Grid>
                              </Section>
                              <Section spacingBottom='4'>
                                <Grid>
                                  <Grid.Column flex='none'>
                                    <Section width='10'>
                                      <div className="warranty-text is-xs">2.</div>
                                    </Section>
                                  </Grid.Column>
                                  <Grid.Column flex='1'>
                                    <div className="warranty-text is-xs">การเคลื่อนย้ายและติดตั้ง ต้องปฏิบัติอย่างถูกวิธีตามคำแนะนำของ บลูสโคป โดยระมัดระวังสิ่งต่อไปนี้เป็นพิเศษ</div>
                                  </Grid.Column>
                                </Grid>
                              </Section>

                              {/* WarrantySetupWarningList =========================================== */}
                              <WarrantySetupWarningListTwoColumn />

                              {/* T & C ============================================================== */}
                              <Section spacingBottom='4'>
                                <Grid>
                                  <Grid.Column flex='none'>
                                    <Section width='10'>
                                      <div className="warranty-text is-xs">3.</div>
                                    </Section>
                                  </Grid.Column>
                                  <Grid.Column flex='1'>
                                    <div className="warranty-text is-xs">หมุดยึดต้องเป็นไปตามมาตรฐาน AS 3566 คลาส 3 หรือ 4 โดยต้องได้รับการรับรองมาตรฐานจาก<br />ผู้ผลิตวัสดุนั้นๆ และต้องไม่ใช่เหล็กกล้าไร้สนิม</div>
                                  </Grid.Column>
                                </Grid>
                              </Section>
                              <Section spacingBottom='4'>
                                <Grid>
                                  <Grid.Column flex='none'>
                                    <Section width='10' align='flex-start'>
                                      <div className="warranty-text is-xs">4.</div>
                                    </Section>
                                  </Grid.Column>
                                  <Grid.Column flex='1'>
                                    <div className="warranty-text is-xs">แผ่นปิดครอบ หมุดยึด และวัสดุประกอบอื่นๆต้องผลิตจากวัสดุที่มีคุณสมบัติเทียบเท่ากับหลังคาและผนัง ซึ่งเคลือบหรือชุบด้วยวัสดุตามคำแนะนำของบลูสโคป (แผ่นเหล็ก COLORBOND®, Zincalume®,<br /> BlueScope Zacs®, SuperDyma ®, สังกะสี, อลูมิเนียม) เพื่อให้ผลิตภัณฑ์มีคุณภาพ<br />และระยะเวลาการใช้งานเหมือนกัน</div>
                                  </Grid.Column>
                                </Grid>
                              </Section>
                              <Section spacingBottom='4'>
                                <Grid>
                                  <Grid.Column flex='none'>
                                    <Section width='10'>
                                      <div className="warranty-text is-xs">5.</div>
                                    </Section>
                                  </Grid.Column>
                                  <Grid.Column flex='1'>
                                    <div className="warranty-text is-xs">สีของผลิตภัณฑ์ต้องไม่อยู่ในสภาพที่มีรอยขีดข่วน ถลอก ได้รับความเสียหาย หรือถูกเคลือบทับด้วยวัสดุแปลก<br />ปลอม หรือวัสดุที่ไม่มีคุณสมบัติเทียบเท่า (รวมทั้ง ปล่องไฟ หรือปล่องระบาย)</div>
                                  </Grid.Column>
                                </Grid>
                              </Section>
                            </Grid.Column>
                            <Grid.Column grid='6'>
                              {/* T & C ============================================================== */}
                              <Section spacingBottom='4'>
                                <Grid>
                                  <Grid.Column flex='none'>
                                    <Section width='10'>
                                      <div className="warranty-text is-xs">6.</div>
                                    </Section>
                                  </Grid.Column>
                                  <Grid.Column flex='1'>
                                    <div className="warranty-text is-xs">การเกิดการผุกร่อนบางส่วนหรือทั้งหมดอันเนื่องมาจากสิ่งต่อไปนี้ ไม่ถือว่าอยู่ภายใต้การรับประกัน</div>
                                  </Grid.Column>
                                </Grid>
                              </Section>

                              {/* WarrantyDecayList ================================================== */}
                              <WarrantyDecayListTwoColumn />

                              {/* T & C ============================================================== */}
                              <Section paddingTop='4' spacingBottom='4'>
                                <Grid>
                                  <Grid.Column flex='none'>
                                    <Section width='10' align='flex-start'>
                                      <div className="warranty-text is-xs">7.</div>
                                    </Section>
                                  </Grid.Column>
                                  <Grid.Column flex='1'>
                                    <div className="warranty-text is-xs">ความบกพร่องของผลิตภัณฑ์จากการเกิดสนิมหมายถึงแผ่นมีการผุกร่อนเป็นรู สนิมที่เกิดบนผิวของแผ่นหรือ<br /> การเกิดสนิมเล็กน้อยเนื่องจากการหักงอของแผ่น หรือการตัดปลายแผ่น ในสภาพแวดล้อมตามธรรมชาติทั่วไป<br /> ไม่ถือว่าเป็นความบกพร่องของผลิตภัณฑ์ </div>
                                  </Grid.Column>
                                </Grid>
                              </Section>
                              <Section>
                                <div className="warranty-text is-xs is-bold">ทั้งนี้การรับประกันนี้เป็นการรับประกันคุณภาพของผลิตภัณฑ์เท่านั้น หากมีข้อบกพร่องเกี่ยวกับ<br />การรีดขึ้นรูป หรือการติดตั้งผลิตภัณฑ์ กรุณาติดต่อผู้ผลิต</div>
                              </Section>
                            </Grid.Column>
                          </Grid>
                        </React.Fragment>
                      }

                      {/* One column */}
                      {false &&
                        <React.Fragment>
                          <WarrantyNotice />
                          <Section>
                            <Text regular8 lineheightTh color={VARIABLES.COLORS.BLACK}>สัญญารับประกันฉบับนี้เป็นการรับประกันว่าแผ่นเหล็ก SuperDyma ® TF หากได้รับการติดตั้งและบำรุงรักษาอย่างถูกต้องคาดว่าจะมีอายุการใช้งานอย่างน้อยตามระยะเวลาการรับประกันที่กำหนดของผลิตภัณฑ์ (นับจากวันที่ซื้อสินค้า)</Text>
                          </Section>
                          <Section spacingBottom='4'>
                            <Text regular8 lineheightTh color={VARIABLES.COLORS.BLACK}>อย่างไรก็ตาม ข้อบกพร่องเฉพาะหรือสถานการณ์ใดๆตามรายละเอียดดังต่อไปนี้ จะต้องได้รับการระบุและแก้ไขโดยเร็ว เพื่อที่จะให้เกิดผลดีที่สุดต่อคุณภาพของผลิตภัณฑ์ ทั้งนี้ข้อบกพร่องที่เกิดจากการไม่แก้ไข อาจมีผลกระทบ<br />ต่อการยกเลิกของการรับประกัน ดังหัวข้อต่อไปนี้</Text>
                          </Section>
                          <Section spacingBottom='-3'>
                            <Grid>
                              <Grid.Column flex='none'>
                                <Section width='10' align='flex-start'>
                                  <Text regular8 lineheightTh color={VARIABLES.COLORS.BLACK}>1.</Text>
                                </Section>
                              </Grid.Column>
                              <Grid.Column flex='1'>
                                <Text regular8 lineheightTh color={VARIABLES.COLORS.BLACK}>การรับประกันครอบคลุมเฉพาะหลังคาและผนัง ที่ทำจากแผ่นเหล็กคุณภาพชั้นหนึ่ง SuperDyma ® TF K14, ZM140  เท่านั้น ทั้งนี้เจ้าของบ้าน/ผู้รับเหมา ควรตรวจสอบว่าเป็นของแท้โดยดูเครื่องหมายการค้า SuperDyma ® TF <br /> ที่พิมพ์อยู่ใต้แผ่น ทั้งนี้การนำไปใช้งานเป็นรางน้ำ, ท่อระบายน้ำฝน, แผ่นเหล็กรอยต่อโครงสร้าง, ประตู และวัสดุประกอบอื่นๆ นั้น จะไม่รวมอยู่ภายใต้การรับประกัน</Text>
                              </Grid.Column>
                            </Grid>
                          </Section>
                          <Section spacingBottom='-6'>
                            <Grid>
                              <Grid.Column flex='none'>
                                <Section width='10'>
                                  <Text regular8 lineheightTh color={VARIABLES.COLORS.BLACK}>2.</Text>
                                </Section>
                              </Grid.Column>
                              <Grid.Column flex='1'>
                                <Text regular8 lineheightTh color={VARIABLES.COLORS.BLACK}>การเคลื่อนย้ายและติดตั้ง ต้องปฏิบัติอย่างถูกวิธีตามคำแนะนำของ บลูสโคป โดยระมัดระวังสิ่งต่อไปนี้เป็นพิเศษ</Text>
                              </Grid.Column>
                            </Grid>
                          </Section>
                          <WarrantySetupWarningList />
                          <Section spacingBottom='4'>
                            <Grid>
                              <Grid.Column flex='none'>
                                <Section width='10'>
                                  <Text regular8 lineheightTh color={VARIABLES.COLORS.BLACK}>3.</Text>
                                </Section>
                              </Grid.Column>
                              <Grid.Column flex='1'>
                                <Text regular8 lineheightTh color={VARIABLES.COLORS.BLACK}>หมุดยึดต้องเป็นไปตามมาตรฐาน AS 3566 คลาส 3 หรือ 4 โดยต้องได้รับการรับรองมาตรฐานจากผู้ผลิตวัสดุนั้นๆ และต้องไม่ใช่เหล็กกล้าไร้สนิม</Text>
                              </Grid.Column>
                            </Grid>
                          </Section>
                          <Section spacingBottom='-3'>
                            <Grid>
                              <Grid.Column flex='none'>
                                <Section width='10' align='flex-start'>
                                  <Text regular8 lineheightTh color={VARIABLES.COLORS.BLACK}>4.</Text>
                                </Section>
                              </Grid.Column>
                              <Grid.Column flex='1'>
                                <Text regular8 lineheightTh color={VARIABLES.COLORS.BLACK}>แผ่นปิดครอบ หมุดยึด และวัสดุประกอบอื่นๆต้องผลิตจากวัสดุที่มีคุณสมบัติเทียบเท่ากับหลังคาและผนัง ซึ่งเคลือบหรือชุบด้วยวัสดุตามคำแนะนำของบลูสโคป (แผ่นเหล็ก COLORBOND®, Zincalume®,<br /> BlueScope Zacs®, SuperDyma ®, สังกะสี, อลูมิเนียม) เพื่อให้ผลิตภัณฑ์มีคุณภาพและระยะเวลาการใช้งานเหมือนกัน</Text>
                              </Grid.Column>
                            </Grid>
                          </Section>
                          <Section spacingBottom='-3'>
                            <Grid>
                              <Grid.Column flex='none'>
                                <Section width='10'>
                                  <Text regular8 lineheightTh color={VARIABLES.COLORS.BLACK}>5.</Text>
                                </Section>
                              </Grid.Column>
                              <Grid.Column flex='1'>
                                <Text regular8 lineheightTh color={VARIABLES.COLORS.BLACK}>สีของผลิตภัณฑ์ต้องไม่อยู่ในสภาพที่มีรอยขีดข่วน ถลอก ได้รับความเสียหาย หรือถูกเคลือบทับด้วยวัสดุแปลกปลอม หรือวัสดุที่ไม่มีคุณสมบัติเทียบเท่า (รวมทั้ง ปล่องไฟ หรือปล่องระบาย)</Text>
                              </Grid.Column>
                            </Grid>
                          </Section>
                          <Section spacingBottom='-6'>
                            <Grid>
                              <Grid.Column flex='none'>
                                <Section width='10'>
                                  <Text regular8 lineheightTh color={VARIABLES.COLORS.BLACK}>6.</Text>
                                </Section>
                              </Grid.Column>
                              <Grid.Column flex='1'>
                                <Text regular8 lineheightTh color={VARIABLES.COLORS.BLACK}>การเกิดการผุกร่อนบางส่วนหรือทั้งหมดอันเนื่องมาจากสิ่งต่อไปนี้ ไม่ถือว่าอยู่ภายใต้การรับประกัน</Text>
                              </Grid.Column>
                            </Grid>
                          </Section>
                          <WarrantyDecayList />
                          <Section paddingTop='4' spacingBottom='-3'>
                            <Grid>
                              <Grid.Column flex='none'>
                                <Section width='10' align='flex-start'>
                                  <Text regular8 lineheightTh color={VARIABLES.COLORS.BLACK}>7.</Text>
                                </Section>
                              </Grid.Column>
                              <Grid.Column flex='1'>
                                <Text regular8 lineheightTh color={VARIABLES.COLORS.BLACK}>ความบกพร่องของผลิตภัณฑ์จากการเกิดสนิมหมายถึงแผ่นมีการผุกร่อนเป็นรู สนิมที่เกิดบนผิวของแผ่นหรือการเกิดสนิมเล็กน้อยเนื่องจากการหักงอของแผ่น หรือการตัดปลายแผ่น ในสภาพแวดล้อมตามธรรมชาติทั่วไป ไม่ถือว่า<br />เป็นความบกพร่องของผลิตภัณฑ์</Text>
                              </Grid.Column>
                            </Grid>
                          </Section>
                          <Section>
                            <Text bold8 lineheightTh color={VARIABLES.COLORS.BLACK}>ทั้งนี้การรับประกันนี้เป็นการรับประกันคุณภาพของผลิตภัณฑ์เท่านั้น หากมีข้อบกพร่องเกี่ยวกับการรีดขึ้นรูป หรือการติดตั้งผลิตภัณฑ์ กรุณาติดต่อผู้ผลิตแผ่นหลังคาเหล็ก</Text>
                          </Section>
                        </React.Fragment>
                      }
                    </React.Fragment>
                  }

                  {/* superDymaPl */}
                  {superDymaPl &&
                    <React.Fragment>
                      {true &&
                        <React.Fragment>
                          <Section height='5' />
                          <Grid gutter='10'>
                            <Grid.Column grid='6'>
                              {/* Notice ============================================================= */}
                              <WarrantyNoticeTwoColumn />

                              {/* T & C ============================================================== */}
                              <Section spacingBottom='4'>
                                <div className="warranty-text is-xs">สัญญารับประกันฉบับนี้เป็นการรับประกันว่าแผ่นเหล็ก SuperDyma PL หากได้รับการติดตั้งและบำรุงรักษาอย่าง<br /> ถูกต้องคาดว่าจะมีอายุการใช้งานอย่างน้อยตามระยะเวลาการรับประกันที่กำหนดของผลิตภัณฑ์ (นับจากวันที่ซื้อสินค้า)</div>
                              </Section>
                              <Section spacingBottom='4'>
                                <div className="warranty-text is-xs">อย่างไรก็ตาม ข้อบกพร่องเฉพาะหรือสถานการณ์ใดๆตามรายละเอียดดังต่อไปนี้ จะต้องได้รับการระบุและแก้ไข<br /> โดยเร็ว เพื่อที่จะให้เกิดผลดีที่สุดต่อคุณภาพของผลิตภัณฑ์ ทั้งนี้ข้อบกพร่องที่เกิดจากการไม่แก้ไข อาจมี<br /> ผลกระทบต่อการยกเลิกของการรับประกัน ดังหัวข้อต่อไปนี้</div>
                              </Section>
                              <Section spacingBottom='4'>
                                <Grid>
                                  <Grid.Column flex='none'>
                                    <Section width='10' align='flex-start'>
                                      <div className="warranty-text is-xs">1.</div>
                                    </Section>
                                  </Grid.Column>
                                  <Grid.Column flex='1'>
                                    <div className="warranty-text is-xs">การรับประกันครอบคลุมเฉพาะหลังคาและผนัง ที่ทำจากแผ่นเหล็กคุณภาพชั้นหนึ่ง SuperDyma PL K18, K22,<br /> K27 ZM180, ZM220, ZM275  เท่านั้น ทั้งนี้เจ้าของบ้าน/ผู้รับเหมา ควรตรวจสอบว่าเป็นของแท้โดยดู<br /> เครื่องหมายการค้า SuperDyma ® ที่พิมพ์อยู่ใต้แผ่น ทั้งนี้การนำไปใช้งานเป็นรางน้ำ, ท่อระบายน้ำฝน,<br /> แผ่นเหล็กรอยต่อโครงสร้าง, ประตู และวัสดุประกอบอื่นๆ นั้น จะไม่รวมอยู่ภายใต้การรับประกัน </div>
                                  </Grid.Column>
                                </Grid>
                              </Section>
                              <Section spacingBottom='4'>
                                <Grid>
                                  <Grid.Column flex='none'>
                                    <Section width='10'>
                                      <div className="warranty-text is-xs">2.</div>
                                    </Section>
                                  </Grid.Column>
                                  <Grid.Column flex='1'>
                                    <div className="warranty-text is-xs">การเคลื่อนย้ายและติดตั้ง ต้องปฏิบัติอย่างถูกวิธีตามคำแนะนำของ บลูสโคป โดยระมัดระวังสิ่งต่อไปนี้เป็นพิเศษ</div>
                                  </Grid.Column>
                                </Grid>
                              </Section>

                              {/* WarrantySetupWarningList =========================================== */}
                              <WarrantySetupWarningListTwoColumn />

                              {/* T & C ============================================================== */}
                              <Section spacingBottom='4'>
                                <Grid>
                                  <Grid.Column flex='none'>
                                    <Section width='10'>
                                      <div className="warranty-text is-xs">3.</div>
                                    </Section>
                                  </Grid.Column>
                                  <Grid.Column flex='1'>
                                    <div className="warranty-text is-xs">หมุดยึดต้องเป็นไปตามมาตรฐาน AS 3566 คลาส 3 หรือ 4 โดยต้องได้รับการรับรองมาตรฐานจาก<br />ผู้ผลิตวัสดุนั้นๆ และต้องไม่ใช่เหล็กกล้าไร้สนิม</div>
                                  </Grid.Column>
                                </Grid>
                              </Section>
                              <Section spacingBottom='4'>
                                <Grid>
                                  <Grid.Column flex='none'>
                                    <Section width='10' align='flex-start'>
                                      <div className="warranty-text is-xs">4.</div>
                                    </Section>
                                  </Grid.Column>
                                  <Grid.Column flex='1'>
                                    <div className="warranty-text is-xs">แผ่นปิดครอบ หมุดยึด และวัสดุประกอบอื่นๆต้องผลิตจากวัสดุที่มีคุณสมบัติเทียบเท่ากับหลังคาและผนัง ซึ่งเคลือบหรือชุบด้วยวัสดุตามคำแนะนำของบลูสโคป (แผ่นเหล็ก COLORBOND®, Zincalume®,<br /> BlueScope Zacs®, SuperDyma ®, สังกะสี, อลูมิเนียม) เพื่อให้ผลิตภัณฑ์มีคุณภาพและ<br />ระยะเวลาการใช้งานเหมือนกัน</div>
                                  </Grid.Column>
                                </Grid>
                              </Section>
                              <Section spacingBottom='4'>
                                <Grid>
                                  <Grid.Column flex='none'>
                                    <Section width='10'>
                                      <div className="warranty-text is-xs">5.</div>
                                    </Section>
                                  </Grid.Column>
                                  <Grid.Column flex='1'>
                                    <div className="warranty-text is-xs">สีของผลิตภัณฑ์ต้องไม่อยู่ในสภาพที่มีรอยขีดข่วน ถลอก ได้รับความเสียหาย หรือถูกเคลือบทับด้วยวัสดุแปลก<br />ปลอม หรือวัสดุที่ไม่มีคุณสมบัติเทียบเท่า (รวมทั้ง ปล่องไฟ หรือปล่องระบาย)</div>
                                  </Grid.Column>
                                </Grid>
                              </Section>
                            </Grid.Column>
                            <Grid.Column grid='6'>
                              {/* T & C ============================================================== */}
                              <Section spacingBottom='4'>
                                <Grid>
                                  <Grid.Column flex='none'>
                                    <Section width='10'>
                                      <div className="warranty-text is-xs">6.</div>
                                    </Section>
                                  </Grid.Column>
                                  <Grid.Column flex='1'>
                                    <div className="warranty-text is-xs">การเกิดการผุกร่อนบางส่วนหรือทั้งหมดอันเนื่องมาจากสิ่งต่อไปนี้ ไม่ถือว่าอยู่ภายใต้การรับประกัน</div>
                                  </Grid.Column>
                                </Grid>
                              </Section>

                              {/* WarrantyDecayList ================================================== */}
                              <WarrantyDecayListTwoColumn />

                              {/* T & C ============================================================== */}
                              <Section paddingTop='4' spacingBottom='4'>
                                <Grid>
                                  <Grid.Column flex='none'>
                                    <Section width='10' align='flex-start'>
                                      <div className="warranty-text is-xs">7.</div>
                                    </Section>
                                  </Grid.Column>
                                  <Grid.Column flex='1'>
                                    <div className="warranty-text is-xs">ความบกพร่องของผลิตภัณฑ์จากการเกิดสนิมหมายถึงแผ่นมีการผุกร่อนเป็นรู สนิมที่เกิดบนผิวของแผ่นหรือ<br /> การเกิดสนิมเล็กน้อยเนื่องจากการหักงอของแผ่น หรือการตัดปลายแผ่น ในสภาพแวดล้อมตามธรรมชาติทั่วไป<br /> ไม่ถือว่าเป็นความบกพร่องของผลิตภัณฑ์</div>
                                  </Grid.Column>
                                </Grid>
                              </Section>
                              <Section>
                                <div className="warranty-text is-xs is-bold">ทั้งนี้การรับประกันนี้เป็นการรับประกันคุณภาพของผลิตภัณฑ์เท่านั้น หากมีข้อบกพร่องเกี่ยวกับ<br />การรีดขึ้นรูป หรือการติดตั้งผลิตภัณฑ์ กรุณาติดต่อผู้ผลิต </div>
                              </Section>
                            </Grid.Column>
                          </Grid>
                        </React.Fragment>
                      }

                      {/* One column */}
                      {false &&
                        <React.Fragment>
                          <WarrantyNotice />
                          <Section>
                            <Text regular8 lineheightTh color={VARIABLES.COLORS.BLACK}>สัญญารับประกันฉบับนี้เป็นการรับประกันว่าแผ่นเหล็ก SuperDyma PL หากได้รับการติดตั้งและบำรุงรักษาอย่างถูกต้องคาดว่าจะมีอายุการใช้งานอย่างน้อยตามระยะเวลาการรับประกันที่กำหนดของผลิตภัณฑ์ (นับจากวันที่ซื้อสินค้า)</Text>
                          </Section>
                          <Section spacingBottom='4'>
                            <Text regular8 lineheightTh color={VARIABLES.COLORS.BLACK}>อย่างไรก็ตาม ข้อบกพร่องเฉพาะหรือสถานการณ์ใดๆตามรายละเอียดดังต่อไปนี้ จะต้องได้รับการระบุและแก้ไขโดยเร็ว เพื่อที่จะให้เกิดผลดีที่สุดต่อคุณภาพของผลิตภัณฑ์ ทั้งนี้ข้อบกพร่องที่เกิดจากการไม่แก้ไข อาจมีผลกระทบต่อ<br />การยกเลิกของการรับประกัน ดังหัวข้อต่อไปนี้</Text>
                          </Section>
                          <Section spacingBottom='-3'>
                            <Grid>
                              <Grid.Column flex='none'>
                                <Section width='10' align='flex-start'>
                                  <Text regular8 lineheightTh color={VARIABLES.COLORS.BLACK}>1.</Text>
                                </Section>
                              </Grid.Column>
                              <Grid.Column flex='1'>
                                <Text regular8 lineheightTh color={VARIABLES.COLORS.BLACK}>การรับประกันครอบคลุมเฉพาะหลังคาและผนัง ที่ทำจากแผ่นเหล็กคุณภาพชั้นหนึ่ง SuperDyma PL K18, K22, K27 ZM180, ZM220, ZM275  เท่านั้น ทั้งนี้เจ้าของบ้าน/ผู้รับเหมา ควรตรวจสอบว่าเป็นของแท้โดยดูเครื่องหมายการค้า SuperDyma ® ที่พิมพ์อยู่ใต้แผ่น<br /> ทั้งนี้การนำไปใช้งานเป็นรางน้ำ, ท่อระบายน้ำฝน, แผ่นเหล็กรอยต่อโครงสร้าง, ประตู และวัสดุประกอบอื่นๆ นั้น จะไม่รวมอยู่ภายใต้การรับประกัน</Text>
                              </Grid.Column>
                            </Grid>
                          </Section>
                          <Section spacingBottom='-6'>
                            <Grid>
                              <Grid.Column flex='none'>
                                <Section width='10'>
                                  <Text regular8 lineheightTh color={VARIABLES.COLORS.BLACK}>2.</Text>
                                </Section>
                              </Grid.Column>
                              <Grid.Column flex='1'>
                                <Text regular8 lineheightTh color={VARIABLES.COLORS.BLACK}>การเคลื่อนย้ายและติดตั้ง ต้องปฏิบัติอย่างถูกวิธีตามคำแนะนำของ บลูสโคป โดยระมัดระวังสิ่งต่อไปนี้เป็นพิเศษ</Text>
                              </Grid.Column>
                            </Grid>
                          </Section>
                          <WarrantySetupWarningList />
                          <Section spacingBottom='4'>
                            <Grid>
                              <Grid.Column flex='none'>
                                <Section width='10'>
                                  <Text regular8 lineheightTh color={VARIABLES.COLORS.BLACK}>3.</Text>
                                </Section>
                              </Grid.Column>
                              <Grid.Column flex='1'>
                                <Text regular8 lineheightTh color={VARIABLES.COLORS.BLACK}>หมุดยึดต้องเป็นไปตามมาตรฐาน AS 3566 คลาส 3 หรือ 4 โดยต้องได้รับการรับรองมาตรฐานจากผู้ผลิตวัสดุนั้นๆ และต้องไม่ใช่เหล็กกล้าไร้สนิม</Text>
                              </Grid.Column>
                            </Grid>
                          </Section>
                          <Section spacingBottom='-3'>
                            <Grid>
                              <Grid.Column flex='none'>
                                <Section width='10' align='flex-start'>
                                  <Text regular8 lineheightTh color={VARIABLES.COLORS.BLACK}>4.</Text>
                                </Section>
                              </Grid.Column>
                              <Grid.Column flex='1'>
                                <Text regular8 lineheightTh color={VARIABLES.COLORS.BLACK}>แผ่นปิดครอบ หมุดยึด และวัสดุประกอบอื่นๆต้องผลิตจากวัสดุที่มีคุณสมบัติเทียบเท่ากับหลังคาและผนัง ซึ่งเคลือบหรือชุบด้วยวัสดุตามคำแนะนำของบลูสโคป (แผ่นเหล็ก COLORBOND®, Zincalume®,<br /> BlueScope Zacs®, SuperDyma ®, สังกะสี, อลูมิเนียม) เพื่อให้ผลิตภัณฑ์มีคุณภาพและระยะเวลาการใช้งานเหมือนกัน</Text>
                              </Grid.Column>
                            </Grid>
                          </Section>
                          <Section spacingBottom='-3'>
                            <Grid>
                              <Grid.Column flex='none'>
                                <Section width='10'>
                                  <Text regular8 lineheightTh color={VARIABLES.COLORS.BLACK}>5.</Text>
                                </Section>
                              </Grid.Column>
                              <Grid.Column flex='1'>
                                <Text regular8 lineheightTh color={VARIABLES.COLORS.BLACK}>สีของผลิตภัณฑ์ต้องไม่อยู่ในสภาพที่มีรอยขีดข่วน ถลอก ได้รับความเสียหาย หรือถูกเคลือบทับด้วยวัสดุแปลกปลอม หรือวัสดุที่ไม่มีคุณสมบัติเทียบเท่า (รวมทั้ง ปล่องไฟ หรือปล่องระบาย)</Text>
                              </Grid.Column>
                            </Grid>
                          </Section>
                          <Section spacingBottom='-6'>
                            <Grid>
                              <Grid.Column flex='none'>
                                <Section width='10'>
                                  <Text regular8 lineheightTh color={VARIABLES.COLORS.BLACK}>6.</Text>
                                </Section>
                              </Grid.Column>
                              <Grid.Column flex='1'>
                                <Text regular8 lineheightTh color={VARIABLES.COLORS.BLACK}>การเกิดการผุกร่อนบางส่วนหรือทั้งหมดอันเนื่องมาจากสิ่งต่อไปนี้ ไม่ถือว่าอยู่ภายใต้การรับประกัน</Text>
                              </Grid.Column>
                            </Grid>
                          </Section>
                          <WarrantyDecayList />
                          <Section paddingTop='4' spacingBottom='-3'>
                            <Grid>
                              <Grid.Column flex='none'>
                                <Section width='10' align='flex-start'>
                                  <Text regular8 lineheightTh color={VARIABLES.COLORS.BLACK}>7.</Text>
                                </Section>
                              </Grid.Column>
                              <Grid.Column flex='1'>
                                <Text regular8 lineheightTh color={VARIABLES.COLORS.BLACK}>ความบกพร่องของผลิตภัณฑ์จากการเกิดสนิมหมายถึงแผ่นมีการผุกร่อนเป็นรู สนิมที่เกิดบนผิวของแผ่นหรือการเกิดสนิมเล็กน้อยเนื่องจากการหักงอของแผ่น หรือการตัดปลายแผ่น ในสภาพแวดล้อมตามธรรมชาติทั่วไป ไม่ถือว่า<br />เป็นความบกพร่องของผลิตภัณฑ์</Text>
                              </Grid.Column>
                            </Grid>
                          </Section>
                          <Section>
                            <Text bold7 lineheightTh color={VARIABLES.COLORS.BLACK}>ทั้งนี้การรับประกันนี้เป็นการรับประกันคุณภาพของผลิตภัณฑ์เท่านั้น หากมีข้อบกพร่องเกี่ยวกับการรีดขึ้นรูป หรือการติดตั้งผลิตภัณฑ์ กรุณาติดต่อผู้ผลิตแผ่นหลังคาเหล็ก</Text>
                          </Section>
                        </React.Fragment>
                      }
                    </React.Fragment>
                  }

                  {/* trueCore */}
                  {trueCore &&
                    <React.Fragment>
                      {true &&
                        <React.Fragment>
                          <Section height='5' />
                          <Grid gutter='10'>
                            <Grid.Column grid='6'>
                              {/* Notice ============================================================= */}
                              <WarrantyNoticeTwoColumn />

                              {/* T & C ============================================================== */}
                              <Section spacingBottom='4'>
                                <div className="warranty-text is-xs">เอ็นเอส บลูสโคป ให้การรับประกันผลิตภัณฑ์เคลือบ สำหรับการรับประกันต่อความเสียหายทางโครงสร้างของ<br />เหล็ก จากการกัดกร่อน ตามตารางด้านล่างนี้ </div>
                              </Section>
                              <Section spacingBottom='4'>
                                <Table fluid warranty>
                                  <Table.Section>
                                    <Table.Row>
                                      <Table.Column className='is-head-column is-border-bottom-none' alignCenter minWidth='92' padding='2'>
                                        <div className="warranty-text is-xs is-bold">ระยะห่างจากน้ำทะเล</div>
                                      </Table.Column>
                                      <Table.Column className='is-head-column is-border-bottom-none' alignCenter maxWidth='132' minWidth='132' padding='2'>
                                        <div className="warranty-text is-xs is-bold">อาคารแบบปิดสนิท</div>
                                      </Table.Column>
                                      <Table.Column className='is-head-column is-border-bottom-none' alignCenter maxWidth='132' minWidth='132' padding='2'>
                                        <div className="warranty-text is-xs is-bold">อาคารแบบเปิด</div>
                                      </Table.Column>
                                    </Table.Row>
                                    <Table.Row>
                                      <Table.Column className='is-head-column' minWidth='92' padding='2'>
                                        <div className="warranty-text is-xs is-bold">&nbsp;</div>
                                      </Table.Column>
                                      <Table.Column className='is-head-column' alignCenter maxWidth='264' minWidth='264' padding='2'>
                                        <div className="warranty-text is-xs is-bold">โครงสร้างไม่พังอันเนื่องมาจากการกัดกร่อน เป็นระยะเวลาไม่น้อยกว่า</div>
                                      </Table.Column>
                                    </Table.Row>
                                  </Table.Section>
                                  <Table.Section>
                                    <Table.Row>
                                      <Table.Column className='is-body-column' alignCenter minWidth='92' padding='2'>
                                        <div className="warranty-text is-xs">มากกว่า 5 กม.</div>
                                      </Table.Column>
                                      <Table.Column className='is-body-column' alignCenter maxWidth='132' minWidth='132' padding='2'>
                                        <div className="warranty-text is-xs">30 ปี</div>
                                      </Table.Column>
                                      <Table.Column className='is-body-column' alignCenter maxWidth='132' minWidth='132' padding='2'>
                                        <div className="warranty-text is-xs">25 ปี</div>
                                      </Table.Column>
                                    </Table.Row>
                                    <Table.Row>
                                      <Table.Column className='is-body-column' alignCenter minWidth='92' padding='2'>
                                        <div className="warranty-text is-xs">2 กม. – 5 กม.</div>
                                      </Table.Column>
                                      <Table.Column className='is-body-column' alignCenter maxWidth='132' minWidth='132' padding='2'>
                                        <div className="warranty-text is-xs">20 ปี</div>
                                      </Table.Column>
                                      <Table.Column className='is-body-column' alignCenter maxWidth='132' minWidth='132' padding='2'>
                                        <div className="warranty-text is-xs">15 ปี</div>
                                      </Table.Column>
                                    </Table.Row>
                                    <Table.Row>
                                      <Table.Column className='is-body-column' alignCenter minWidth='92' paddingTop='6' paddingHorizontal='2' paddingBottom='2'>
                                        <div className="warranty-text is-xs">น้อยกว่า 2 กม.</div>
                                      </Table.Column>
                                      <Table.Column className='is-body-column' alignCenter maxWidth='264' minWidth='264' padding='2'>
                                        <div className="warranty-text is-xs">กรุณาติดต่อ แผนกเทคนิค ของทาง<br />บริษัทเอ็นเอส บลูสโคป (ประเทศไทย) จำกัด</div>
                                      </Table.Column>
                                    </Table.Row>
                                  </Table.Section>
                                </Table>
                              </Section>
                              <Section spacingBottom='4'>
                                <Grid>
                                  <Grid.Column flex='none'>
                                    <Section width='10'>
                                      <div className="warranty-text is-xs">1.</div>
                                    </Section>
                                  </Grid.Column>
                                  <Grid.Column flex='1'>
                                    <div className="warranty-text is-xs">ผลิตภัณฑ์เหล็กนี้ถูกสร้างขึ้นเพื่อใช้เฉพาะในงานโครงสร้างหลังคาและ โครงผนังที่พักอาศัยเท่านั้น.</div>
                                  </Grid.Column>
                                </Grid>
                              </Section>
                              <Section spacingBottom='4'>
                                <Grid>
                                  <Grid.Column flex='none'>
                                    <Section width='10'>
                                      <div className="warranty-text is-xs">2.</div>
                                    </Section>
                                  </Grid.Column>
                                  <Grid.Column flex='1'>
                                    <div className="warranty-text is-xs">คารแบบปิดสบิทนั้นหมายถึง</div>
                                  </Grid.Column>
                                </Grid>
                              </Section>
                              <Section paddingLeft='10' spacingBottom='4'>
                                <Grid>
                                  <Grid.Column flex='none'>
                                    <Section width='10'>
                                      <div className="warranty-text is-xs">-</div>
                                    </Section>
                                  </Grid.Column>
                                  <Grid.Column flex='1'>
                                    <div className="warranty-text is-xs">มีการติดตั้งฝ้าเพดานหรือ แผ่นผนังปิดอยู่เต็มพื้นที่ และ</div>
                                  </Grid.Column>
                                </Grid>
                              </Section>
                              <Section paddingLeft='10' spacingBottom='4'>
                                <Grid>
                                  <Grid.Column flex='none'>
                                    <Section width='10'>
                                      <div className="warranty-text is-xs">-</div>
                                    </Section>
                                  </Grid.Column>
                                  <Grid.Column flex='1'>
                                    <div className="warranty-text is-xs">ชายคาถุกปิดและ ไม่มีการเจาะรู และ</div>
                                  </Grid.Column>
                                </Grid>
                              </Section>
                              <Section paddingLeft='10' spacingBottom='4'>
                                <Grid>
                                  <Grid.Column flex='none'>
                                    <Section width='10'>
                                      <div className="warranty-text is-xs">-</div>
                                    </Section>
                                  </Grid.Column>
                                  <Grid.Column flex='1'>
                                    <div className="warranty-text is-xs">ไม่มีช่องว่าง (กว่ากว่า 20 มม.) ระหว่างผนังและ หลังคา หรือระหว่างผนังและ ผนัง และ</div>
                                  </Grid.Column>
                                </Grid>
                              </Section>
                              <Section paddingLeft='10' spacingBottom='4'>
                                <Grid>
                                  <Grid.Column flex='none'>
                                    <Section width='10'>
                                      <div className="warranty-text is-xs">-</div>
                                    </Section>
                                  </Grid.Column>
                                  <Grid.Column flex='1'>
                                    <div className="warranty-text is-xs">ช่องระบายอากาศของหลังคาและ ส่วนประกอบที่ถูกระบุว่า เป็นส่วนหนึ่งข องช่องระบายอากาศ<br /> เช่นขอบค้างปิดชาย, ปีกนก และอื่นๆ ต้องไม่กว้างจนเกินไป</div>
                                  </Grid.Column>
                                </Grid>
                              </Section>
                              <Section spacingBottom='4'>
                                <Grid>
                                  <Grid.Column flex='none'>
                                    <Section width='15'>
                                      <div className="warranty-text is-xs">3.</div>
                                    </Section>
                                  </Grid.Column>
                                  <Grid.Column flex='1'>
                                    <div className="warranty-text is-xs">อาคารแบบเปิดหมายถึง</div>
                                  </Grid.Column>
                                </Grid>
                              </Section>
                              <Section paddingLeft='15' spacingBottom='4'>
                                <Grid>
                                  <Grid.Column flex='none'>
                                    <Section width='10'>
                                      <div className="warranty-text is-xs">-</div>
                                    </Section>
                                  </Grid.Column>
                                  <Grid.Column flex='1'>
                                    <div className="warranty-text is-xs">ฝ้าเพดานหรือ แผ่นผนังไม่ได้ถูกปิด หรือ</div>
                                  </Grid.Column>
                                </Grid>
                              </Section>
                              <Section paddingLeft='15' spacingBottom='4'>
                                <Grid>
                                  <Grid.Column flex='none'>
                                    <Section width='10'>
                                      <div className="warranty-text is-xs">-</div>
                                    </Section>
                                  </Grid.Column>
                                  <Grid.Column flex='1'>
                                    <div className="warranty-text is-xs">ชายคาไม่ได้ถูกเปิด หรือมีการใช้วัสดุแบบเจาะรูหรือ</div>
                                  </Grid.Column>
                                </Grid>
                              </Section>
                              <Section paddingLeft='15' spacingBottom='4'>
                                <Grid>
                                  <Grid.Column flex='none'>
                                    <Section width='10'>
                                      <div className="warranty-text is-xs">-</div>
                                    </Section>
                                  </Grid.Column>
                                  <Grid.Column flex='1'>
                                    <div className="warranty-text is-xs">มีช่องว่าง (กว้างกว่า 20 มม.) ระหว่างผนังและ หลังคา/ชายคา หรือระหว่างผนังและ ผนัง หรือ</div>
                                  </Grid.Column>
                                </Grid>
                              </Section>
                              <Section paddingLeft='15' spacingBottom='4'>
                                <Grid>
                                  <Grid.Column flex='none'>
                                    <Section width='10'>
                                      <div className="warranty-text is-xs">-</div>
                                    </Section>
                                  </Grid.Column>
                                  <Grid.Column flex='1'>
                                    <div className="warranty-text is-xs">ช่องระบายอากาศของหลังคาไม่ถูกกำหนดความกว้างและมีการใช้วัสดุต่างๆ เช่น <br />ขอบโค้งปิดชาย และวัสดุอื่นๆ</div>
                                  </Grid.Column>
                                </Grid>
                              </Section>
                              <Section paddingLeft='15' spacingBottom='4'>
                                <Grid>
                                  <Grid.Column flex='none'>
                                    <Section width='10'>
                                      <div className="warranty-text is-xs">-</div>
                                    </Section>
                                  </Grid.Column>
                                  <Grid.Column flex='1'>
                                    <div className="warranty-text is-xs">อาคารประกอบด้วยหลังคาที่ไม่มีกำแพง</div>
                                  </Grid.Column>
                                </Grid>
                              </Section>
                            </Grid.Column>
                            <Grid.Column grid='6'>
                              {/* T & C ============================================================== */}
                              <Section spacingBottom='4'>
                                <Grid>
                                  <Grid.Column flex='none'>
                                    <Section width='15'>
                                      <div className="warranty-text is-xs">4.</div>
                                    </Section>
                                  </Grid.Column>
                                  <Grid.Column flex='1'>
                                    <div className="warranty-text is-xs">ผลิตภัณฑ์เหล็กจะต้องถูกดำเนินการและ ติดตั้งให้สอดคล้องตามเอกสาร แนวทางปฏิบัติของ<br /> เอ็นเอส บลูสโคป ซึ่งตีพิมพ์ขึ้น ณ เวลานั้นๆ</div>
                                  </Grid.Column>
                                </Grid>
                              </Section>
                              <Section spacingBottom='4'>
                                <Grid>
                                  <Grid.Column flex='none'>
                                    <Section width='15'>
                                      <div className="warranty-text is-xs">5.</div>
                                    </Section>
                                  </Grid.Column>
                                  <Grid.Column flex='1'>
                                    <div className="warranty-text is-xs">ผลิตภัณฑ์เหล็กจะต้องไม่ถู กจัดว่างให้สัมผัสกับวัสดุต่างๆ ที่เข้ากันไม่ได้ (เช่นทองแดง, แสตนเลสสตีล, ไม้, ปูน และคอนกรีตที่ยังไม่แข็งตัว)</div>
                                  </Grid.Column>
                                </Grid>
                              </Section>
                              <Section spacingBottom='4'>
                                <Grid>
                                  <Grid.Column flex='none'>
                                    <Section width='15'>
                                      <div className="warranty-text is-xs">6.</div>
                                    </Section>
                                  </Grid.Column>
                                  <Grid.Column flex='1'>
                                    <div className="warranty-text is-xs">การเลือกอุปกรณ์ช่วยยึดโครง สร้างเหล็กนั้นควรที่จะเลือกอุปกรณ์ที่มีความแข็งแรงทนท านเหมือนกับวัสดู<br />โครงสร้างหลังคา สกรูจะต้องสอดคล้องตามมาตรฐาน AS 3566 ระดับ 3</div>
                                  </Grid.Column>
                                </Grid>
                              </Section>
                              <Section spacingBottom='4'>
                                <Grid>
                                  <Grid.Column flex='none'>
                                    <Section width='15'>
                                      <div className="warranty-text is-xs">7.</div>
                                    </Section>
                                  </Grid.Column>
                                  <Grid.Column flex='1'>
                                    <div className="warranty-text is-xs">บริเวณพื้นที่ที่เปียกนั้นควรที่จะถูกออกแบบให้สามารถป้องกันความชื้นและ ป้องกันการกัดกร่อน<br />ต่อวัสดุโครงสร้าง</div>
                                  </Grid.Column>
                                </Grid>
                              </Section>
                              <Section spacingBottom='4'>
                                <Grid>
                                  <Grid.Column flex='none'>
                                    <Section width='15'>
                                      <div className="warranty-text is-xs">8.</div>
                                    </Section>
                                  </Grid.Column>
                                  <Grid.Column flex='1'>
                                    <div className="warranty-text is-xs">การกัดกร่อนใดๆ ต่อโครงสร้างที่ทำขึ้นจาก อันสืบเนื่องมาจากการสึกกร่อนของวัสดุหรือส่วนประกอบ<br />อื่นๆ นั้นจะไม่อยู่ในความคุ้มครองของการรับประกันนี้</div>
                                  </Grid.Column>
                                </Grid>
                              </Section>
                              <Section spacingBottom='4'>
                                <Grid>
                                  <Grid.Column flex='none'>
                                    <Section width='15'>
                                      <div className="warranty-text is-xs">9.</div>
                                    </Section>
                                  </Grid.Column>
                                  <Grid.Column flex='1'>
                                    <div className="warranty-text is-xs">การสึกกร่อนของโครงสร้างอันเป็นผลมาจากกา รรับสิ่งกระตุ้นเป็นเวลาที่ยาวนานอันเนื่องมาจาก<br />การจัดเก็บหรือ การก่อสร้างนั้น จะไม่อยู่ในความคุ้มครองของการรับประกันนี้</div>
                                  </Grid.Column>
                                </Grid>
                              </Section>
                              <Section spacingBottom='4'>
                                <Grid>
                                  <Grid.Column flex='none'>
                                    <Section width='15'>
                                      <div className="warranty-text is-xs">10.</div>
                                    </Section>
                                  </Grid.Column>
                                  <Grid.Column flex='1'>
                                    <div className="warranty-text is-xs">ผลิตภัณฑ์จะต้องมีการเคลื่อนย้ายและ ติดตั้งอย่างถูกวิธีตามคำแนะนำของ เอ็นเอส บลูสโคป ณ เวลาทำการ<br />ติดตั้ง โดยระมัดระวังสิ่งต่อไปนี้เป็นพิเศษ</div>
                                  </Grid.Column>
                                </Grid>
                              </Section>
                              <Section paddingLeft='15' spacingBottom='4'>
                                <Grid>
                                  <Grid.Column flex='none'>
                                    <Section width='15' align='flex-start'>
                                      <div className="warranty-text is-xs">(ก)</div>
                                    </Section>
                                  </Grid.Column>
                                  <Grid.Column flex='1'>
                                    <div className="warranty-text is-xs">ต้องจัดเก็บรักษาผลิต ภัณฑ์ให้อยู่ในสถาพแห้งตลอดเวลา ขณะกองหรือ ติดตั้งผลิตภัณฑ์ทั้งที่<br />อยุ่ใ มัดก่อนการติดตั้ง สำหรับผลิตภัณฑ์ที่เปียกควรรับจัดแยก และทำให้แห้งเพื่อลดความเสี่ยงจาก<br />การเร่งปฏิกริยา การเกิดสนิมเนื่องจาก "การกองผลิตภัณฑ์ในสถาพเปียกทั้งที่อยู่ในมัด"</div>
                                  </Grid.Column>
                                </Grid>
                              </Section>
                              <Section paddingLeft='15' spacingBottom='4'>
                                <Grid>
                                  <Grid.Column flex='none'>
                                    <Section width='15'>
                                      <div className="warranty-text is-xs">(ข)</div>
                                    </Section>
                                  </Grid.Column>
                                  <Grid.Column flex='1'>
                                    <div className="warranty-text is-xs">ไม่ต้ดแผ่นโดยใช้เครื่องตัดหรือ ขัดแบบเจียระไน เนื่องจากความร้อนของ เศษเหล็กหรือ<br />ฝุ่นเหล็กจากการตัดอาจทำให้ผิวเคลือบของผลิตภัณฑ์เกิดเสียหาย</div>
                                  </Grid.Column>
                                </Grid>
                              </Section>
                              <Section spacingBottom='4'>
                                <div className="warranty-text is-xs">ระหว่างการติดตั้ง ควรกวาด เศษเหล็ก เศษโลหะ หรือฝุ่นละอองต่างๆ ที่ตกค้างอยู่บนผลิต ภัณฑ์เป็นประจำทุกวัน เพื่อลดปัจจัยความเสี่ยงของการเกิดสนิมจากการสะสมของเศษเหล็ก เศษโลหะ หรือฝุ่นละอองต่างๆ</div>
                              </Section>
                              <Section>
                                <div className="warranty-text is-xs is-bold">ทั้งนี้การรับประกันนี้เป็นการรับประกันคุณภาพของผลิตภัณฑ์เท่านั้น หากมีข้อบกพร่องเกี่ยวกับ<br />การรีดขึ้นรูป หรือการติดตั้งผลิตภัณฑ์ กรุณาติดต่อผู้ผลิต</div>
                              </Section>
                            </Grid.Column>
                          </Grid>
                        </React.Fragment>
                      }

                      {/* One column */}
                      {false &&
                        <React.Fragment>
                          <WarrantyNoticeSmall />
                          <Section spacingBottom='4'>
                            <Text regular8 lineheightTh color={VARIABLES.COLORS.BLACK}>เอ็นเอส บลูสโคป ให้การรับประกันผลิตภัณฑ์เคลือบ สำหรับการรับประกันต่อความเสียหายทางโครงสร้างของเหล็ก จากการกัดกร่อน ตามตารางด้านล่างนี้</Text>
                          </Section>
                          <Section spacingBottom='4'>
                            <Table fluid warranty>
                              <Table.Section>
                                <Table.Row>
                                  <Table.Column className='is-head-column is-border-bottom-none' alignCenter minWidth='174' padding='2'>
                                    <Text bold7 lineheightTh color={VARIABLES.COLORS.BLACK}>ระยะห่างจากน้ำทะเล</Text>
                                  </Table.Column>
                                  <Table.Column className='is-head-column is-border-bottom-none' alignCenter maxWidth='250' minWidth='250' padding='2'>
                                    <Text bold7 lineheightTh color={VARIABLES.COLORS.BLACK}>อาคารแบบปิดสนิท</Text>
                                  </Table.Column>
                                  <Table.Column className='is-head-column is-border-bottom-none' alignCenter maxWidth='250' minWidth='250' padding='2'>
                                    <Text bold7 lineheightTh color={VARIABLES.COLORS.BLACK}>อาคารแบบเปิด</Text>
                                  </Table.Column>
                                </Table.Row>
                                <Table.Row>
                                  <Table.Column className='is-head-column' minWidth='174' padding='2'>
                                    <Text bold7 lineheightTh color={VARIABLES.COLORS.BLACK}>&nbsp;</Text>
                                  </Table.Column>
                                  <Table.Column className='is-head-column' alignCenter maxWidth='500' minWidth='500' padding='2'>
                                    <Text bold7 lineheightTh color={VARIABLES.COLORS.BLACK}>โครงสร้างไม่พังอันเนื่องมาจากการกัดกร่อน เป็นระยะเวลาไม่น้อยกว่า</Text>
                                  </Table.Column>
                                </Table.Row>
                              </Table.Section>
                              <Table.Section>
                                <Table.Row>
                                  <Table.Column className='is-body-column' alignCenter minWidth='174' padding='2'>
                                    <Text regular7 lineheightTh color={VARIABLES.COLORS.BLACK}>มากกว่า 5 กม.</Text>
                                  </Table.Column>
                                  <Table.Column className='is-body-column' alignCenter maxWidth='250' minWidth='250' padding='2'>
                                    <Text regular7 lineheightTh color={VARIABLES.COLORS.BLACK}>30 ปี</Text>
                                  </Table.Column>
                                  <Table.Column className='is-body-column' alignCenter maxWidth='250' minWidth='250' padding='2'>
                                    <Text regular7 lineheightTh color={VARIABLES.COLORS.BLACK}>25 ปี</Text>
                                  </Table.Column>
                                </Table.Row>
                                <Table.Row>
                                  <Table.Column className='is-body-column' alignCenter minWidth='174' padding='2'>
                                    <Text regular7 lineheightTh color={VARIABLES.COLORS.BLACK}>2 กม. – 5 กม.</Text>
                                  </Table.Column>
                                  <Table.Column className='is-body-column' alignCenter maxWidth='250' minWidth='250' padding='2'>
                                    <Text regular7 lineheightTh color={VARIABLES.COLORS.BLACK}>20 ปี</Text>
                                  </Table.Column>
                                  <Table.Column className='is-body-column' alignCenter maxWidth='250' minWidth='250' padding='2'>
                                    <Text regular7 lineheightTh color={VARIABLES.COLORS.BLACK}>15 ปี</Text>
                                  </Table.Column>
                                </Table.Row>
                                <Table.Row>
                                  <Table.Column className='is-body-column' alignCenter minWidth='174' padding='2'>
                                    <Text regular7 lineheightTh color={VARIABLES.COLORS.BLACK}>น้อยกว่า 2 กม.</Text>
                                  </Table.Column>
                                  <Table.Column className='is-body-column' alignCenter maxWidth='500' minWidth='500' padding='2'>
                                    <Text regular7 lineheightTh color={VARIABLES.COLORS.BLACK}>กรุณาติดต่อ แผนกเทคนิค ของทางบริษัทเอ็นเอส บลูสโคป (ประเทศไทย) จำกัด</Text>
                                  </Table.Column>
                                </Table.Row>
                              </Table.Section>
                            </Table>
                          </Section>
                          <Section spacingBottom='-5'>
                            <Grid>
                              <Grid.Column flex='none'>
                                <Section width='15'>
                                  <Text regular8 lineheightTh color={VARIABLES.COLORS.BLACK}>1.</Text>
                                </Section>
                              </Grid.Column>
                              <Grid.Column flex='1'>
                                <Text regular8 lineheightTh color={VARIABLES.COLORS.BLACK}>ผลิตภัณฑ์เหล็กนี้ถูกสร้างขึ้นเพื่อใช้เฉพาะในงานโครงสร้างหลังคาและ โครงผนังที่พักอาศัยเท่านั้น.</Text>
                              </Grid.Column>
                            </Grid>
                          </Section>
                          <Section spacingBottom='-5'>
                            <Grid>
                              <Grid.Column flex='none'>
                                <Section width='15'>
                                  <Text regular8 lineheightTh color={VARIABLES.COLORS.BLACK}>2.</Text>
                                </Section>
                              </Grid.Column>
                              <Grid.Column flex='1'>
                                <Text regular8 lineheightTh color={VARIABLES.COLORS.BLACK}>คารแบบปิดสบิทนั้นหมายถึง</Text>
                              </Grid.Column>
                            </Grid>
                          </Section>
                          <Section paddingLeft='15' spacingBottom='-5'>
                            <Grid>
                              <Grid.Column flex='none'>
                                <Section width='5'>
                                  <Text regular8 lineheightTh color={VARIABLES.COLORS.BLACK}>-</Text>
                                </Section>
                              </Grid.Column>
                              <Grid.Column flex='1'>
                                <Text regular8 lineheightTh color={VARIABLES.COLORS.BLACK}>มีการติดตั้งฝ้าเพดานหรือ แผ่นผนังปิดอยู่เต็มพื้นที่ และ</Text>
                              </Grid.Column>
                            </Grid>
                          </Section>
                          <Section paddingLeft='15' spacingBottom='-5'>
                            <Grid>
                              <Grid.Column flex='none'>
                                <Section width='5'>
                                  <Text regular8 lineheightTh color={VARIABLES.COLORS.BLACK}>-</Text>
                                </Section>
                              </Grid.Column>
                              <Grid.Column flex='1'>
                                <Text regular8 lineheightTh color={VARIABLES.COLORS.BLACK}>ชายคาถุกปิดและ ไม่มีการเจาะรู และ</Text>
                              </Grid.Column>
                            </Grid>
                          </Section>
                          <Section paddingLeft='15' spacingBottom='-5'>
                            <Grid>
                              <Grid.Column flex='none'>
                                <Section width='5'>
                                  <Text regular8 lineheightTh color={VARIABLES.COLORS.BLACK}>-</Text>
                                </Section>
                              </Grid.Column>
                              <Grid.Column flex='1'>
                                <Text regular8 lineheightTh color={VARIABLES.COLORS.BLACK}>ไม่มีช่องว่าง (กว่ากว่า 20 มม.) ระหว่างผนังและ หลังคา หรือระหว่างผนังและ ผนัง และ</Text>
                              </Grid.Column>
                            </Grid>
                          </Section>
                          <Section paddingLeft='15' spacingBottom='-5'>
                            <Grid>
                              <Grid.Column flex='none'>
                                <Section width='5'>
                                  <Text regular8 lineheightTh color={VARIABLES.COLORS.BLACK}>-</Text>
                                </Section>
                              </Grid.Column>
                              <Grid.Column flex='1'>
                                <Text regular8 lineheightTh color={VARIABLES.COLORS.BLACK}>ช่องระบายอากาศของหลังคาและ ส่วนประกอบที่ถูกระบุว่า เป็นส่วนหนึ่งข องช่องระบายอากาศ เช่นขอบค้างปิดชาย, ปีกนก และอื่นๆ ต้องไม่กว้างจนเกินไป</Text>
                              </Grid.Column>
                            </Grid>
                          </Section>
                          <Section spacingBottom='-5'>
                            <Grid>
                              <Grid.Column flex='none'>
                                <Section width='15'>
                                  <Text regular8 lineheightTh color={VARIABLES.COLORS.BLACK}>3.</Text>
                                </Section>
                              </Grid.Column>
                              <Grid.Column flex='1'>
                                <Text regular8 lineheightTh color={VARIABLES.COLORS.BLACK}>อาคารแบบเปิดหมายถึง</Text>
                              </Grid.Column>
                            </Grid>
                          </Section>
                          <Section paddingLeft='15' spacingBottom='-5'>
                            <Grid>
                              <Grid.Column flex='none'>
                                <Section width='5'>
                                  <Text regular8 lineheightTh color={VARIABLES.COLORS.BLACK}>-</Text>
                                </Section>
                              </Grid.Column>
                              <Grid.Column flex='1'>
                                <Text regular8 lineheightTh color={VARIABLES.COLORS.BLACK}>ฝ้าเพดานหรือ แผ่นผนังไม่ได้ถูกปิด หรือ</Text>
                              </Grid.Column>
                            </Grid>
                          </Section>
                          <Section paddingLeft='15' spacingBottom='-5'>
                            <Grid>
                              <Grid.Column flex='none'>
                                <Section width='5'>
                                  <Text regular8 lineheightTh color={VARIABLES.COLORS.BLACK}>-</Text>
                                </Section>
                              </Grid.Column>
                              <Grid.Column flex='1'>
                                <Text regular8 lineheightTh color={VARIABLES.COLORS.BLACK}>ชายคาไม่ได้ถูกเปิด หรือมีการใช้วัสดุแบบเจาะรูหรือ</Text>
                              </Grid.Column>
                            </Grid>
                          </Section>
                          <Section paddingLeft='15' spacingBottom='-5'>
                            <Grid>
                              <Grid.Column flex='none'>
                                <Section width='5'>
                                  <Text regular8 lineheightTh color={VARIABLES.COLORS.BLACK}>-</Text>
                                </Section>
                              </Grid.Column>
                              <Grid.Column flex='1'>
                                <Text regular8 lineheightTh color={VARIABLES.COLORS.BLACK}>มีช่องว่าง (กว้างกว่า 20 มม.) ระหว่างผนังและ หลังคา/ชายคา หรือระหว่างผนังและ ผนัง หรือ</Text>
                              </Grid.Column>
                            </Grid>
                          </Section>
                          <Section paddingLeft='15' spacingBottom='-5'>
                            <Grid>
                              <Grid.Column flex='none'>
                                <Section width='5'>
                                  <Text regular8 lineheightTh color={VARIABLES.COLORS.BLACK}>-</Text>
                                </Section>
                              </Grid.Column>
                              <Grid.Column flex='1'>
                                <Text regular8 lineheightTh color={VARIABLES.COLORS.BLACK}>ช่องระบายอากาศของหลังคาไม่ถู กกำหนอดความ กว้างและมีการใช้วัสดุต่างๆ เช่นขอบโค้งปิดชาย และวัสดุอื่นๆ</Text>
                              </Grid.Column>
                            </Grid>
                          </Section>
                          <Section paddingLeft='15' spacingBottom='-5'>
                            <Grid>
                              <Grid.Column flex='none'>
                                <Section width='5'>
                                  <Text regular8 lineheightTh color={VARIABLES.COLORS.BLACK}>-</Text>
                                </Section>
                              </Grid.Column>
                              <Grid.Column flex='1'>
                                <Text regular8 lineheightTh color={VARIABLES.COLORS.BLACK}>อาคารประกอบด้วยหลังคาที่ไม่มีกำแพง</Text>
                              </Grid.Column>
                            </Grid>
                          </Section>
                          <Section spacingBottom='-5'>
                            <Grid>
                              <Grid.Column flex='none'>
                                <Section width='15'>
                                  <Text regular8 lineheightTh color={VARIABLES.COLORS.BLACK}>4.</Text>
                                </Section>
                              </Grid.Column>
                              <Grid.Column flex='1'>
                                <Text regular8 lineheightTh color={VARIABLES.COLORS.BLACK}>ผลิตภัณฑ์เหล็กจะต้องถูกดำเนินการและ ติดตั้งให้สอดคล้องตามเอกสาร แนวทางปฏิบัติของ เอ็นเอส บลูสโคป ซึ่งตีพิมพ์ขึ้น ณ เวลานั้นๆ</Text>
                              </Grid.Column>
                            </Grid>
                          </Section>
                          <Section spacingBottom='-5'>
                            <Grid>
                              <Grid.Column flex='none'>
                                <Section width='15'>
                                  <Text regular8 lineheightTh color={VARIABLES.COLORS.BLACK}>5.</Text>
                                </Section>
                              </Grid.Column>
                              <Grid.Column flex='1'>
                                <Text regular8 lineheightTh color={VARIABLES.COLORS.BLACK}>ผลิตภัณฑ์เหล็กจะต้องไม่ถู กจัดว่างให้สัมผัสกับวัสดุต่างๆ ที่เข้ากันไม่ได้ (เช่นทองแดง, แสตนเลสสตีล, ไม้, ปูน และคอนกรีตที่ยังไม่แข็งตัว)</Text>
                              </Grid.Column>
                            </Grid>
                          </Section>
                          <Section spacingBottom='-5'>
                            <Grid>
                              <Grid.Column flex='none'>
                                <Section width='15'>
                                  <Text regular8 lineheightTh color={VARIABLES.COLORS.BLACK}>6.</Text>
                                </Section>
                              </Grid.Column>
                              <Grid.Column flex='1'>
                                <Text regular8 lineheightTh color={VARIABLES.COLORS.BLACK}>การเลือกอุปกรณ์ช่วยยึดโครง สร้างเหล็กนั้นควรที่จะเลือกอุปกรณ์ที่มีความแข็งแรงทนท านเหมือนกับวัสดูโครงสร้างหลังคา สกรูจะต้องสอดคล้องตามมาตรฐาน AS 3566 ระดับ 3</Text>
                              </Grid.Column>
                            </Grid>
                          </Section>
                          <Section spacingBottom='-5'>
                            <Grid>
                              <Grid.Column flex='none'>
                                <Section width='15'>
                                  <Text regular8 lineheightTh color={VARIABLES.COLORS.BLACK}>7.</Text>
                                </Section>
                              </Grid.Column>
                              <Grid.Column flex='1'>
                                <Text regular8 lineheightTh color={VARIABLES.COLORS.BLACK}>บริเวณพื้นที่ที่เปียกนั้นควรที่จะถูกออกแบบให้สามารถป้องกันความชื้นและ ป้องกันการกัดกร่อนต่อวัสดุโครงสร้าง</Text>
                              </Grid.Column>
                            </Grid>
                          </Section>
                          <Section spacingBottom='-5'>
                            <Grid>
                              <Grid.Column flex='none'>
                                <Section width='15'>
                                  <Text regular8 lineheightTh color={VARIABLES.COLORS.BLACK}>8.</Text>
                                </Section>
                              </Grid.Column>
                              <Grid.Column flex='1'>
                                <Text regular8 lineheightTh color={VARIABLES.COLORS.BLACK}>การกัดกร่อนใดๆ ต่อโครงสร้างที่ทำขึ้นจาก อันสืบเนื่องมาจากการสึกกร่อนของวัสดุหรือส่วนประกอบอื่นๆ นั้นจะไม่อยู่ในความคุ้มครองของการรับประกันนี้</Text>
                              </Grid.Column>
                            </Grid>
                          </Section>
                          <Section spacingBottom='-5'>
                            <Grid>
                              <Grid.Column flex='none'>
                                <Section width='15'>
                                  <Text regular8 lineheightTh color={VARIABLES.COLORS.BLACK}>9.</Text>
                                </Section>
                              </Grid.Column>
                              <Grid.Column flex='1'>
                                <Text regular8 lineheightTh color={VARIABLES.COLORS.BLACK}>การสึกกร่อนของโครงสร้างอันเป็นผลมาจากกา รรับสิ่งกระตุ้นเป็นเวลาที่ยาวนานอันเนื่ องมาจากการจัดเก็บหรือ การก่อสร้างนั้น จะไม่อยู่ในความคุ้มครองของการรับประกันนี้</Text>
                              </Grid.Column>
                            </Grid>
                          </Section>
                          <Section>
                            <Grid>
                              <Grid.Column flex='none'>
                                <Section width='15'>
                                  <Text regular8 lineheightTh color={VARIABLES.COLORS.BLACK}>10.</Text>
                                </Section>
                              </Grid.Column>
                              <Grid.Column flex='1'>
                                <Text regular8 lineheightTh color={VARIABLES.COLORS.BLACK}>ผลิตภัณฑ์จะต้องมีการเคลื่อนย้ายและ ติดตั้งอย่างถูกวิธีตามคำแนะนำของ เอ็นเอส บลูสโคป ณ เวลาทำการติดตั้ง โดยระมัดระวังสิ่งต่อไปนี้เป็นพิเศษ</Text>
                              </Grid.Column>
                            </Grid>
                          </Section>
                          <Section paddingLeft='15' spacingBottom='-5'>
                            <Grid>
                              <Grid.Column flex='none'>
                                <Section width='15' align='flex-start'>
                                  <Text regular8 lineheightTh color={VARIABLES.COLORS.BLACK}>(ก)</Text>
                                </Section>
                              </Grid.Column>
                              <Grid.Column flex='1'>
                                <Text regular8 lineheightTh color={VARIABLES.COLORS.BLACK}>ต้องจัดเก็บรักษาผลิต ภัณฑ์ให้อยู่ในสถาพแห้งตลอดเวลา ขณะกองหรือ ติดตั้งผลิตภัณฑ์ทั้งที่อยุ่ใ มัดก่อนการติดตั้ง สำหรับผลิตภัณฑ์ที่เปียกควรรับจัดแยก และทำให้แห้งเพื่อลดความเสี่ยงจากการเร่งปฏิกริยาการเกิดสนิมเนื่องจาก<br /> "การกองผลิตภัณฑ์ในสถาพเปียกทั้งที่อยู่ในมัด"</Text>
                              </Grid.Column>
                            </Grid>
                          </Section>
                          <Section paddingLeft='15'>
                            <Grid>
                              <Grid.Column flex='none'>
                                <Section width='15'>
                                  <Text regular8 lineheightTh color={VARIABLES.COLORS.BLACK}>(ข)</Text>
                                </Section>
                              </Grid.Column>
                              <Grid.Column flex='1'>
                                <Text regular8 lineheightTh color={VARIABLES.COLORS.BLACK}>ไม่ต้ดแผ่นโดยใช้เครื่องตัดหรือ ขัดแบบเจียระไน เนื่องจากความร้อนของ เศษเหล็กหรือ ฝุ่นเหล็กจากการตัดอาจทำให้ผิวเคลือบของผลิตภัณฑ์เกิดเสียหาย</Text>
                              </Grid.Column>
                            </Grid>
                          </Section>
                          <Section spacingBottom='-5'>
                            <Text regular8 lineheightTh color={VARIABLES.COLORS.BLACK}>ระหว่างการติดตั้ง ควรกวาด เศษเหล็ก เศษโลหะ หรือฝุ่นละอองต่างๆ ที่ตกค้างอยู่บนผลิต ภัณฑ์เป็นประจำทุกวัน เพื่อลดปัจจัยความเสี่ยงของการเกิดสนิมจากการสะสมของเศษเหล็ก เศษโลหะ หรือฝุ่นละอองต่างๆ</Text>
                          </Section>
                          <Section>
                            <Text bold8 lineheightTh color={VARIABLES.COLORS.BLACK}>ทั้งนี้การรับประกันนี้เป็นการรับประกันคุณภาพของผลิตภัณฑ์เท่านั้น หากมีข้อบกพร่องเกี่ยวกับการรีดขึ้นรูป หรือการติดตั้งผลิตภัณฑ์ กรุณาติดต่อผู้ผลิต</Text>
                          </Section>
                        </React.Fragment>
                      }
                    </React.Fragment>
                  }

                  {/* zincalume */}
                  {zincalume &&
                    <React.Fragment>
                      {true &&
                        <React.Fragment>
                          <Section height='5' />
                          <Grid gutter='10'>
                            <Grid.Column grid='6'>
                              {/* Notice ============================================================= */}
                              <WarrantyNoticeTwoColumn />

                              {/* T & C ============================================================== */}
                              <Section spacingBottom='4'>
                                <div className="warranty-text is-xs">สัญญารับประกันฉบับนี้เป็นการรับประกันว่าแผ่นเหล็ก Zincalume® หากได้รับการติดตั้งและบำรุงรักษา<br /> อย่างถูกต้องคาดว่าจะมีอายุการใช้งานอย่างน้อยตามระยะเวลาการรับประกันที่กำหนดของผลิตภัณฑ์<br />(นับจากวันที่ซื้อสินค้า)</div>
                              </Section>
                              <Section spacingBottom='4'>
                                <div className="warranty-text is-xs">อย่างไรก็ตาม ข้อบกพร่องเฉพาะหรือสถานการณ์ใดๆตามรายละเอียดดังต่อไปนี้ จะต้องได้รับการระบุและแก้ไข<br /> โดยเร็ว เพื่อที่จะให้เกิดผลดีที่สุดต่อคุณภาพของผลิตภัณฑ์ ทั้งนี้ข้อบกพร่องที่เกิดจากการไม่แก้ไขอาจมี<br /> ผลกระทบต่อการยกเลิกของการรับประกัน ดังหัวข้อต่อไปนี้</div>
                              </Section>
                              <Section spacingBottom='4'>
                                <Grid>
                                  <Grid.Column flex='none'>
                                    <Section width='10' align='flex-start'>
                                      <div className="warranty-text is-xs">1.</div>
                                    </Section>
                                  </Grid.Column>
                                  <Grid.Column flex='1'>
                                    <div className="warranty-text is-xs">การรับประกันครอบคลุมเฉพาะหลังคาและผนัง ที่ทำจากแผ่นเหล็กคุณภาพชั้นหนึ่ง Zincalume® เท่านั้น<br /> ทั้งนี้เจ้าของบ้าน/ผู้รับเหมา ควรตรวจสอบว่าเป็นของแท้โดยดูเครื่องหมายการค้า Zincalume®<br /> ที่พิมพ์อยู่ใต้แผ่น ทั้งนี้การนำไปใช้งานเป็นรางน้ำ, ท่อระบายน้ำฝน, แผ่นเหล็กรอยต่อโครงสร้าง, ประตู<br /> และวัสดุประกอบอื่นๆ นั้น จะไม่รวมอยู่ภายใต้การรับประกัน</div>
                                  </Grid.Column>
                                </Grid>
                              </Section>
                              <Section spacingBottom='4'>
                                <Grid>
                                  <Grid.Column flex='none'>
                                    <Section width='10'>
                                      <div className="warranty-text is-xs">2.</div>
                                    </Section>
                                  </Grid.Column>
                                  <Grid.Column flex='1'>
                                    <div className="warranty-text is-xs">การเคลื่อนย้ายและติดตั้ง ต้องปฏิบัติอย่างถูกวิธีตามคำแนะนำของ บลูสโคป โดยระมัดระวังสิ่งต่อไปนี้เป็นพิเศษ </div>
                                  </Grid.Column>
                                </Grid>
                              </Section>

                              {/* WarrantySetupWarningList =========================================== */}
                              <WarrantySetupWarningListTwoColumn />

                              {/* T & C ============================================================== */}
                              <Section spacingBottom='4'>
                                <Grid>
                                  <Grid.Column flex='none'>
                                    <Section width='10'>
                                      <div className="warranty-text is-xs">3.</div>
                                    </Section>
                                  </Grid.Column>
                                  <Grid.Column flex='1'>
                                    <div className="warranty-text is-xs">หมุดยึดต้องเป็นไปตามมาตรฐาน AS 3566 คลาส 3 หรือ 4 โดยต้องได้รับการรับรองมาตรฐานจาก<br />ผู้ผลิตวัสดุนั้นๆ และต้องไม่ใช่เหล็กกล้าไร้สนิม</div>
                                  </Grid.Column>
                                </Grid>
                              </Section>
                              <Section spacingBottom='4'>
                                <Grid>
                                  <Grid.Column flex='none'>
                                    <Section width='10' align='flex-start'>
                                      <div className="warranty-text is-xs">4.</div>
                                    </Section>
                                  </Grid.Column>
                                  <Grid.Column flex='1'>
                                    <div className="warranty-text is-xs">แผ่นปิดครอบ หมุดยึด และวัสดุประกอบอื่นๆต้องผลิตจากวัสดุที่มีคุณสมบัติเทียบเท่ากับหลังคาและผนัง<br /> ซึ่งเคลือบหรือชุบด้วยวัสดุตามคำแนะนำของบลูสโคป (แผ่นเหล็ก COLORBOND®, Zincalume®,<br /> BlueScope Zacs®, สังกะสี, อลูมิเนียม) เพื่อให้ผลิตภัณฑ์มีคุณภาพและระยะเวลาการใช้งานเหมือนกัน</div>
                                  </Grid.Column>
                                </Grid>
                              </Section>
                              <Section spacingBottom='4'>
                                <Grid>
                                  <Grid.Column flex='none'>
                                    <Section width='10'>
                                      <div className="warranty-text is-xs">5.</div>
                                    </Section>
                                  </Grid.Column>
                                  <Grid.Column flex='1'>
                                    <div className="warranty-text is-xs">สีของผลิตภัณฑ์ต้องไม่อยู่ในสภาพที่มีรอยขีดข่วน ถลอก ได้รับความเสียหาย หรือถูกเคลือบทับด้วยวัสดุแปลก<br />ปลอม หรือวัสดุที่ไม่มีคุณสมบัติเทียบเท่า (รวมทั้ง ปล่องไฟ หรือปล่องระบาย)</div>
                                  </Grid.Column>
                                </Grid>
                              </Section>
                            </Grid.Column>
                            <Grid.Column grid='6'>
                              {/* T & C ============================================================== */}
                              <Section spacingBottom='4'>
                                <Grid>
                                  <Grid.Column flex='none'>
                                    <Section width='10'>
                                      <div className="warranty-text is-xs">6.</div>
                                    </Section>
                                  </Grid.Column>
                                  <Grid.Column flex='1'>
                                    <div className="warranty-text is-xs">การเกิดการผุกร่อนบางส่วนหรือทั้งหมดอันเนื่องมาจากสิ่งต่อไปนี้ ไม่ถือว่าอยู่ภายใต้การรับประกัน</div>
                                  </Grid.Column>
                                </Grid>
                              </Section>

                              {/* WarrantyDecayList ================================================== */}
                              <WarrantyDecayListTwoColumn />

                              {/* T & C ============================================================== */}
                              <Section paddingTop='4' spacingBottom='4'>
                                <Grid>
                                  <Grid.Column flex='none'>
                                    <Section width='10' align='flex-start'>
                                      <div className="warranty-text is-xs">7.</div>
                                    </Section>
                                  </Grid.Column>
                                  <Grid.Column flex='1'>
                                    <div className="warranty-text is-xs">ความบกพร่องของผลิตภัณฑ์จากการเกิดสนิมหมายถึงแผ่นมีการผุกร่อนเป็นรู สนิมที่เกิดบนผิวของแผ่นหรือ<br /> การเกิดสนิมเล็กน้อยเนื่องจากการหักงอของแผ่น หรือการตัดปลายแผ่น ในสภาพแวดล้อมตามธรรมชาติทั่วไป<br /> ไม่ถือว่าเป็นความบกพร่องของผลิตภัณฑ์</div>
                                  </Grid.Column>
                                </Grid>
                              </Section>
                              <Section>
                                <div className="warranty-text is-xs is-bold">ทั้งนี้การรับประกันนี้เป็นการรับประกันคุณภาพของผลิตภัณฑ์เท่านั้น หากมีข้อบกพร่องเกี่ยวกับ<br />การรีดขึ้นรูป หรือการติดตั้งผลิตภัณฑ์ กรุณาติดต่อผู้ผลิต</div>
                              </Section>
                            </Grid.Column>
                          </Grid>
                        </React.Fragment>
                      }

                      {/* One column */}
                      {false &&
                        <React.Fragment>
                          <WarrantyNotice />
                          <Section>
                            <Text regular8 lineheightTh color={VARIABLES.COLORS.BLACK}>สัญญารับประกันฉบับนี้เป็นการรับประกันว่าแผ่นเหล็ก Zincalume® หากได้รับการติดตั้งและบำรุงรักษาอย่างถูกต้องคาดว่าจะมีอายุการใช้งานอย่างน้อยตามระยะเวลาการรับประกันที่กำหนดของผลิตภัณฑ์ (นับจากวันที่ซื้อสินค้า)</Text>
                          </Section>
                          <Section spacingBottom='4'>
                            <Text regular8 lineheightTh color={VARIABLES.COLORS.BLACK}>อย่างไรก็ตาม ข้อบกพร่องเฉพาะหรือสถานการณ์ใดๆตามรายละเอียดดังต่อไปนี้ จะต้องได้รับการระบุและแก้ไขโดยเร็ว เพื่อที่จะให้เกิดผลดีที่สุดต่อคุณภาพของผลิตภัณฑ์ ทั้งนี้ข้อบกพร่องที่เกิดจากการไม่แก้ไข อาจมีผลกระทบต่อ<br />การยกเลิกของการรับประกัน ดังหัวข้อต่อไปนี้</Text>
                          </Section>
                          <Section spacingBottom='-3'>
                            <Grid>
                              <Grid.Column flex='none'>
                                <Section width='10' align='flex-start'>
                                  <Text regular8 lineheightTh color={VARIABLES.COLORS.BLACK}>1.</Text>
                                </Section>
                              </Grid.Column>
                              <Grid.Column flex='1'>
                                <Text regular8 lineheightTh color={VARIABLES.COLORS.BLACK}>การรับประกันครอบคลุมเฉพาะหลังคาและผนัง ที่ทำจากแผ่นเหล็กคุณภาพชั้นหนึ่ง Zincalume® เท่านั้น ทั้งนี้เจ้าของบ้าน/ผู้รับเหมา ควรตรวจสอบว่าเป็นของแท้โดยดูเครื่องหมายการค้า Zincalume® ที่พิมพ์อยู่ใต้แผ่น ทั้งนี้<br />การนำไปใช้งานเป็นรางน้ำ, ท่อระบายน้ำฝน, แผ่นเหล็กรอยต่อโครงสร้าง, ประตู และวัสดุประกอบอื่นๆ นั้น จะไม่รวมอยู่ภายใต้การรับประกัน</Text>
                              </Grid.Column>
                            </Grid>
                          </Section>
                          <Section spacingBottom='-6'>
                            <Grid>
                              <Grid.Column flex='none'>
                                <Section width='10'>
                                  <Text regular8 lineheightTh color={VARIABLES.COLORS.BLACK}>2.</Text>
                                </Section>
                              </Grid.Column>
                              <Grid.Column flex='1'>
                                <Text regular8 lineheightTh color={VARIABLES.COLORS.BLACK}>การเคลื่อนย้ายและติดตั้ง ต้องปฏิบัติอย่างถูกวิธีตามคำแนะนำของ บลูสโคป โดยระมัดระวังสิ่งต่อไปนี้เป็นพิเศษ </Text>
                              </Grid.Column>
                            </Grid>
                          </Section>
                          <WarrantySetupWarningList />
                          <Section spacingBottom='4'>
                            <Grid>
                              <Grid.Column flex='none'>
                                <Section width='10'>
                                  <Text regular8 lineheightTh color={VARIABLES.COLORS.BLACK}>3.</Text>
                                </Section>
                              </Grid.Column>
                              <Grid.Column flex='1'>
                                <Text regular8 lineheightTh color={VARIABLES.COLORS.BLACK}>หมุดยึดต้องเป็นไปตามมาตรฐาน AS 3566 คลาส 3 หรือ 4 โดยต้องได้รับการรับรองมาตรฐานจากผู้ผลิตวัสดุนั้นๆ และต้องไม่ใช่เหล็กกล้าไร้สนิม</Text>
                              </Grid.Column>
                            </Grid>
                          </Section>
                          <Section spacingBottom='-3'>
                            <Grid>
                              <Grid.Column flex='none'>
                                <Section width='10' align='flex-start'>
                                  <Text regular8 lineheightTh color={VARIABLES.COLORS.BLACK}>4.</Text>
                                </Section>
                              </Grid.Column>
                              <Grid.Column flex='1'>
                                <Text regular8 lineheightTh color={VARIABLES.COLORS.BLACK}>แผ่นปิดครอบ หมุดยึด และวัสดุประกอบอื่นๆต้องผลิตจากวัสดุที่มีคุณสมบัติเทียบเท่ากับหลังคาและผนัง ซึ่งเคลือบหรือชุบด้วยวัสดุตามคำแนะนำของบลูสโคป (แผ่นเหล็ก COLORBOND®, Zincalume®, BlueScope Zacs®, สังกะสี, อลูมิเนียม) เพื่อให้ผลิตภัณฑ์มีคุณภาพและระยะเวลาการใช้งานเหมือนกัน</Text>
                              </Grid.Column>
                            </Grid>
                          </Section>
                          <Section spacingBottom='-3'>
                            <Grid>
                              <Grid.Column flex='none'>
                                <Section width='10'>
                                  <Text regular8 lineheightTh color={VARIABLES.COLORS.BLACK}>5.</Text>
                                </Section>
                              </Grid.Column>
                              <Grid.Column flex='1'>
                                <Text regular8 lineheightTh color={VARIABLES.COLORS.BLACK}>สีของผลิตภัณฑ์ต้องไม่อยู่ในสภาพที่มีรอยขีดข่วน ถลอก ได้รับความเสียหาย หรือถูกเคลือบทับด้วยวัสดุแปลกปลอม หรือวัสดุที่ไม่มีคุณสมบัติเทียบเท่า (รวมทั้ง ปล่องไฟ หรือปล่องระบาย)</Text>
                              </Grid.Column>
                            </Grid>
                          </Section>
                          <Section spacingBottom='-6'>
                            <Grid>
                              <Grid.Column flex='none'>
                                <Section width='10'>
                                  <Text regular8 lineheightTh color={VARIABLES.COLORS.BLACK}>6.</Text>
                                </Section>
                              </Grid.Column>
                              <Grid.Column flex='1'>
                                <Text regular8 lineheightTh color={VARIABLES.COLORS.BLACK}>การเกิดการผุกร่อนบางส่วนหรือทั้งหมดอันเนื่องมาจากสิ่งต่อไปนี้ ไม่ถือว่าอยู่ภายใต้การรับประกัน</Text>
                              </Grid.Column>
                            </Grid>
                          </Section>
                          <WarrantyDecayList />
                          <Section paddingTop='4' spacingBottom='-3'>
                            <Grid>
                              <Grid.Column flex='none'>
                                <Section width='10' align='flex-start'>
                                  <Text regular8 lineheightTh color={VARIABLES.COLORS.BLACK}>7.</Text>
                                </Section>
                              </Grid.Column>
                              <Grid.Column flex='1'>
                                <Text regular8 lineheightTh color={VARIABLES.COLORS.BLACK}>ความบกพร่องของผลิตภัณฑ์จากการเกิดสนิมหมายถึงแผ่นมีการผุกร่อนเป็นรู สนิมที่เกิดบนผิวของแผ่นหรือการเกิดสนิมเล็กน้อยเนื่องจากการหักงอของแผ่น หรือการตัดปลายแผ่น ในสภาพแวดล้อมตามธรรมชาติทั่วไป ไม่ถือว่า<br />เป็นความบกพร่องของผลิตภัณฑ์</Text>
                              </Grid.Column>
                            </Grid>
                          </Section>
                          <Section>
                            <Text bold8 lineheightTh color={VARIABLES.COLORS.BLACK}>ทั้งนี้การรับประกันนี้เป็นการรับประกันคุณภาพของผลิตภัณฑ์เท่านั้น หากมีข้อบกพร่องเกี่ยวกับการรีดขึ้นรูป หรือการติดตั้งผลิตภัณฑ์ กรุณาติดต่อผู้ผลิตแผ่นหลังคาเหล็ก</Text>
                          </Section>
                        </React.Fragment>
                      }
                    </React.Fragment>
                  }
                </WarrantyWrapper>
              )
            case 'templateIndonesia':
              return (
                <WarrantyWrapper className={classes}>
                  <Section spacingBottom='20'>
                    <Grid justify='space-between'>
                      <Grid.Column>
                        <Section
                          height='100'
                          heightUnit='%'
                          align='center'
                        >
                          <Image ui='logoBlueScope' />
                        </Section>
                      </Grid.Column>
                      <Grid.Column justify='center'>
                        <Section
                          height='100'
                          heightUnit='%'
                          align='center'
                        >
                          <Text bold24 color={VARIABLES.COLORS.PRIMARY_2} align='center'>
                            SERTIFIKAT GARANSI<br />
                            PT NS BLUESCOPE INDONESIA
                          </Text>
                        </Section>
                      </Grid.Column>
                      <Grid.Column>
                        <Section
                          height='100'
                          heightUnit='%'
                          align='center'
                        >
                          <Image ui={logo} />
                        </Section>
                      </Grid.Column>
                    </Grid>
                  </Section>
                  <Section>
                    <Section>
                      <Text bold12 color={VARIABLES.COLORS.BLACK}>
                        10 TAHUN GARANSI* PERFORASI KOROSI&nbsp;
                      </Text>
                      <Text regular10 color={VARIABLES.COLORS.BLACK}>(*see warranty terms & conditions below)</Text>
                    </Section>
                    <Section>
                      <Text bold12 color={VARIABLES.COLORS.BLACK}>
                        5 TAHUN** GARANSI* PERLINDUNGAN WARNA&nbsp;
                      </Text>
                      <Text regular10 color={VARIABLES.COLORS.BLACK}>(**Only for BlueScope Zacs™ Cool only)</Text>
                    </Section>
                  </Section>
                  <Section height='1' heightUnit='px' spacingTop='10' spacingBottom='5' bgColor={VARIABLES.COLORS.BLACK} />
                  <Section>
                    <Grid gutter='15'>
                      <Grid.Column>
                        <Text regular10 color={VARIABLES.COLORS.BLACK}>
                          <Text bold10 color={VARIABLES.COLORS.BLACK}>No Garansi :</Text>
                          &nbsp;&nbsp;&nbsp;{warrantyNumber}
                        </Text>
                      </Grid.Column>
                      <Grid.Column>
                        <Text regular10 color={VARIABLES.COLORS.BLACK}>
                          <Text bold10 color={VARIABLES.COLORS.BLACK}>Tanggal Pembelian :</Text>
                          &nbsp;&nbsp;&nbsp;{purchaseDate}
                        </Text>
                      </Grid.Column>
                    </Grid>
                  </Section>
                  <Section>
                    <Text regular10 color={VARIABLES.COLORS.BLACK}>
                      <Text bold10 color={VARIABLES.COLORS.BLACK}>No. Transaksi :</Text>
                      &nbsp;&nbsp;&nbsp;{transactionId}
                    </Text>
                  </Section>
                  <Section height='1' heightUnit='px' spacingVertical='10' bgColor={VARIABLES.COLORS.BLACK} />
                  <Section>
                    <Text regular10 color={VARIABLES.COLORS.BLACK}>
                      <Text bold10 color={VARIABLES.COLORS.BLACK}>Nama Proyek / Pemilik Bangunan :</Text>
                      &nbsp;&nbsp;&nbsp;{projectName}
                    </Text>
                  </Section>
                  <Section>
                    <Text regular10 color={VARIABLES.COLORS.BLACK}>
                      <Text bold10 color={VARIABLES.COLORS.BLACK}>No Telp Pemilik Proyek / Bangunan :</Text>
                      &nbsp;&nbsp;&nbsp;{projectOwnerPhoneNumber}
                    </Text>
                  </Section>
                  <Section>
                    <Text regular10 color={VARIABLES.COLORS.BLACK}>
                      <Text bold10 color={VARIABLES.COLORS.BLACK}>Alamat Proyek / Bangunan :</Text>
                      &nbsp;&nbsp;&nbsp;{projectAddress}
                    </Text>
                  </Section>
                  <Section>
                    <Text regular10 color={VARIABLES.COLORS.BLACK}>
                      <Text bold10 color={VARIABLES.COLORS.BLACK}>Nama Toko :</Text>
                      &nbsp;&nbsp;&nbsp;{tokoAdName}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      <Text bold10 color={VARIABLES.COLORS.BLACK}>No. Telp Toko :</Text>
                      &nbsp;&nbsp;&nbsp;{tokoAdPhoneNumber}
                    </Text>
                  </Section>
                  <Section spacingBottom='10'>
                    <Text regular10 color={VARIABLES.COLORS.BLACK}>
                      <Text bold10 color={VARIABLES.COLORS.BLACK}>Nama Aplikator :</Text>
                      &nbsp;&nbsp;&nbsp;{contractorName}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      <Text bold10 color={VARIABLES.COLORS.BLACK}>No. Telp Aplikator :</Text>
                      &nbsp;&nbsp;&nbsp;{contractorPhoneNumber}
                    </Text>
                  </Section>
                  <Section spacingBottom={(osName === 'Mac OS') ? '20' : '10'}>
                    {/* Note: content width = 674 */}
                    <Table fluid warranty>
                      <Table.Section>
                        <Table.Row>
                          <Table.Column className='is-head-column' alignCenter maxWidth='104' minWidth='104' padding='4'>
                            <Text bold10 color={VARIABLES.COLORS.BLACK}>No</Text>
                          </Table.Column>
                          <Table.Column className='is-head-column' alignCenter maxWidth='250' minWidth='250' padding='4'>
                            <Text bold10 color={VARIABLES.COLORS.BLACK}>Deskripsi Produk (BMT)</Text>
                          </Table.Column>
                          <Table.Column className='is-head-column' alignCenter maxWidth='160' minWidth='160' padding='4'>
                            <Text bold10 color={VARIABLES.COLORS.BLACK}>Jumlah</Text>
                          </Table.Column>
                          <Table.Column className='is-head-column' alignCenter maxWidth='160' minWidth='160' padding='4'>
                            <Text bold10 color={VARIABLES.COLORS.BLACK}>No. Coil (Jika Ada)</Text>
                          </Table.Column>
                        </Table.Row>
                      </Table.Section>
                      {dataGroupList.map((e, i) => {
                        return (
                          <Table.Section key={i}>
                            {e.displayCoil.map((ec, ic) => {
                              cntRow += 1;
                              return (
                                <Table.Row key={ic}>
                                  <Table.Column className='is-body-column' alignCenter maxWidth='104' minWidth='104' padding='4'>
                                    <Text regular10 color={VARIABLES.COLORS.BLACK}>
                                      {cntRow}
                                    </Text>
                                  </Table.Column>
                                  <Table.Column className='is-body-column' maxWidth='250' minWidth='250' padding='4'>
                                    <Text regular10 color={VARIABLES.COLORS.BLACK}>
                                      {e.coilNameList[ic]}
                                    </Text>
                                  </Table.Column>
                                  <Table.Column className='is-body-column' alignCenter maxWidth='160' minWidth='160' padding='4'>
                                    <Text regular10 color={VARIABLES.COLORS.BLACK}>
                                      {e.coilOrderMList[ic]}
                                    </Text>
                                  </Table.Column>
                                  <Table.Column className='is-body-column' alignCenter maxWidth='160' minWidth='160' padding='4'>
                                    <Text regular10 color={VARIABLES.COLORS.BLACK}>
                                      {e.coilIdList[ic]}
                                    </Text>
                                  </Table.Column>
                                </Table.Row>
                              )
                            })}
                          </Table.Section>
                        )
                      })}
                    </Table>
                  </Section>
                  {/* <Section spacingTop='-15'>
                    <Text regular10 color={VARIABLES.COLORS.BLACK}>
                       <Text bold10 color={VARIABLES.COLORS.BLACK}>Aplikasi  (untuk atap / dinding / lainnya) :</Text>
                      &nbsp;&nbsp;&nbsp;{endUseApplication}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      <Text bold10 color={VARIABLES.COLORS.BLACK}>
                        Luas Bangunan (m
                        <Text regular8 superScript color={VARIABLES.COLORS.BLACK}>2</Text>
                        ) :
                      </Text>
                      &nbsp;&nbsp;&nbsp;{buildingAreaM2}
                    </Text>
                  </Section>*/}
                  {/* <Section height='1' heightUnit='px' spacingTop={(osName === 'Mac OS') ? '10' : '0'} spacingBottom='10' bgColor={VARIABLES.COLORS.BLACK} /> */}
                  {/* Terms & Conditions */}
                  <Section spacingBottom='5'>
                    <Text bold12 color={VARIABLES.COLORS.BLACK}>SYARAT & KETENTUAN</Text>
                  </Section>
                  <Section spacingBottom='4'>
                    <Grid>
                      <Grid.Column flex='none'>
                        <Section width='20' align='flex-start'>
                          <Text regular10 color={VARIABLES.COLORS.BLACK}>1.</Text>
                        </Section>
                      </Grid.Column>
                      <Grid.Column flex='1'>
                        <Section align='flex-start'>
                          <Text regular10 color={VARIABLES.COLORS.BLACK}>Produk aksesoris dari logam yang kontak langsung dengan produk BlueScope harus merupakan material berbahan aluminium, seng, atau material lain yang direkomendasi BlueScope.</Text>
                        </Section>
                      </Grid.Column>
                    </Grid>
                  </Section>
                  <Section spacingBottom='4'>
                    <Grid>
                      <Grid.Column flex='none'>
                        <Section width='20' align='flex-start'>
                          <Text regular10 color={VARIABLES.COLORS.BLACK}>2.</Text>
                        </Section>
                      </Grid.Column>
                      <Grid.Column flex='1'>
                        <Section align='flex-start'>
                          <Text regular10 color={VARIABLES.COLORS.BLACK}>Lapisan Al-Zn yang merupakan lapisan pelindung karat, harus dalam keadaan tanpa kerusakan, atau tidak dalam keadaan dilapis dengan material yang tidak sesuai.</Text>
                        </Section>
                      </Grid.Column>
                    </Grid>
                  </Section>
                  <Section spacingBottom='4'>
                    <Grid>
                      <Grid.Column flex='none'>
                        <Section width='20' align='flex-start'>
                          <Text regular10 color={VARIABLES.COLORS.BLACK}>3.</Text>
                        </Section>
                      </Grid.Column>
                      <Grid.Column flex='1'>
                        <Section align='flex-start'>
                          <Text regular10 color={VARIABLES.COLORS.BLACK}>Baut yang digunakan harus sesuai dengan standar AS 3566 class 3, atau merupakan baut yang dilapis material aluminium dan atau seng.</Text>
                        </Section>
                      </Grid.Column>
                    </Grid>
                  </Section>
                  <Section spacingBottom='4'>
                    <Grid>
                      <Grid.Column flex='none'>
                        <Section width='20' align='flex-start'>
                          <Text regular10 color={VARIABLES.COLORS.BLACK}>4.</Text>
                        </Section>
                      </Grid.Column>
                      <Grid.Column flex='1'>
                        <Section align='flex-start'>
                          <Text regular10 color={VARIABLES.COLORS.BLACK}>Bagian yang tidak terkena air hujan secara langsung (misalnya bagian dinding) harus di bersihkan minimal 6 bulan sekali, sesuai rekomendasi BlueScope.</Text>
                        </Section>
                      </Grid.Column>
                    </Grid>
                  </Section>
                  <Section spacingBottom='4'>
                    <Grid>
                      <Grid.Column flex='none'>
                        <Section width='20' align='flex-start'>
                          <Text regular10 color={VARIABLES.COLORS.BLACK}>5.</Text>
                        </Section>
                      </Grid.Column>
                      <Grid.Column flex='1'>
                        <Section align='flex-start'>
                          <Text regular10 color={VARIABLES.COLORS.BLACK}>Garansi tidak berlaku jika perforasi korosi / karat atau pemudaran warna yang terjadi karena hal-hal berikut,</Text>
                        </Section>
                      </Grid.Column>
                    </Grid>
                  </Section>
                  <Section paddingLeft='20'>
                    <Section spacingBottom='4'>
                      <Grid>
                        <Grid.Column flex='none'>
                          <Section width='20' align='flex-start'>
                            <Text regular10 color={VARIABLES.COLORS.BLACK}>a)</Text>
                          </Section>
                        </Grid.Column>
                        <Grid.Column flex='1'>
                          <Section align='flex-start'>
                            <Text regular10 color={VARIABLES.COLORS.BLACK}>Kerusakan yang terjadi saat proses perpindahan material, pemasangan, atau aktivitas lainnya yang dilakukan diluar pabrik BlueScope.</Text>
                          </Section>
                        </Grid.Column>
                      </Grid>
                    </Section>
                    <Section spacingBottom='4'>
                      <Grid>
                        <Grid.Column flex='none'>
                          <Section width='20' align='flex-start'>
                            <Text regular10 color={VARIABLES.COLORS.BLACK}>b)</Text>
                          </Section>
                        </Grid.Column>
                        <Grid.Column flex='1'>
                          <Section align='flex-start'>
                            <Text regular10 color={VARIABLES.COLORS.BLACK}>Material terserang atau kontak dengan zat kimia kuat (baik padatan, cairan, atau gas) yang dapat menyebabkan karat logam</Text>
                          </Section>
                        </Grid.Column>
                      </Grid>
                    </Section>
                    <Section spacingBottom='4'>
                      <Grid>
                        <Grid.Column flex='none'>
                          <Section width='20' align='flex-start'>
                            <Text regular10 color={VARIABLES.COLORS.BLACK}>c)</Text>
                          </Section>
                        </Grid.Column>
                        <Grid.Column flex='1'>
                          <Section align='flex-start'>
                            <Text regular10 color={VARIABLES.COLORS.BLACK}>Bersentuhan atau kontak dengan tanah, abu, pupuk, atau zat yang mengandung kelembaban</Text>
                          </Section>
                        </Grid.Column>
                      </Grid>
                    </Section>
                    <Section spacingBottom='4'>
                      <Grid>
                        <Grid.Column flex='none'>
                          <Section width='20' align='flex-start'>
                            <Text regular10 color={VARIABLES.COLORS.BLACK}>d)</Text>
                          </Section>
                        </Grid.Column>
                        <Grid.Column flex='1'>
                          <Section align='flex-start'>
                            <Text regular10 color={VARIABLES.COLORS.BLACK}>Bersentuhan dengan logam lain, seperti tembaga, timah, atau logam lainnya.</Text>
                          </Section>
                        </Grid.Column>
                      </Grid>
                    </Section>
                    <Section spacingBottom='4'>
                      <Grid>
                        <Grid.Column flex='none'>
                          <Section width='20' align='flex-start'>
                            <Text regular10 color={VARIABLES.COLORS.BLACK}>e)</Text>
                          </Section>
                        </Grid.Column>
                        <Grid.Column flex='1'>
                          <Section align='flex-start'>
                            <Text regular10 color={VARIABLES.COLORS.BLACK}>Kontak dengan sepihan material lain yang dapat menyebabkan karat, serta jika drainase air tidak dapat mengalir dengan lancar di permukaan material BlueScope (adanya genangan air).</Text>
                          </Section>
                        </Grid.Column>
                      </Grid>
                    </Section>
                    <Section spacingBottom='4'>
                      <Grid>
                        <Grid.Column flex='none'>
                          <Section width='20' align='flex-start'>
                            <Text regular10 color={VARIABLES.COLORS.BLACK}>f)</Text>
                          </Section>
                        </Grid.Column>
                        <Grid.Column flex='1'>
                          <Section align='flex-start'>
                            <Text regular10 color={VARIABLES.COLORS.BLACK}>Kerusakan karena lumut, atau micro organisme lainnya.</Text>
                          </Section>
                        </Grid.Column>
                      </Grid>
                    </Section>
                    <Section spacingBottom='4'>
                      <Grid>
                        <Grid.Column flex='none'>
                          <Section width='20' align='flex-start'>
                            <Text regular10 color={VARIABLES.COLORS.BLACK}>g)</Text>
                          </Section>
                        </Grid.Column>
                        <Grid.Column flex='1'>
                          <Section align='flex-start'>
                            <Text regular10 color={VARIABLES.COLORS.BLACK}>Lingkungan yang berubah menjadi lebih korosif (misalnya, ada pabrik baru yang dibangun, yang mengeluarkan polutan korosif) pada waktu setelah material terpasang.</Text>
                          </Section>
                        </Grid.Column>
                      </Grid>
                    </Section>
                    <Section spacingBottom='4'>
                      <Grid>
                        <Grid.Column flex='none'>
                          <Section width='20' align='flex-start'>
                            <Text regular10 color={VARIABLES.COLORS.BLACK}>h)</Text>
                          </Section>
                        </Grid.Column>
                        <Grid.Column flex='1'>
                          <Section align='flex-start'>
                            <Text regular10 color={VARIABLES.COLORS.BLACK}>Badai atau bencana alam.</Text>
                          </Section>
                        </Grid.Column>
                      </Grid>
                    </Section>
                  </Section>
                  <Section spacingBottom='4'>
                    <Grid>
                      <Grid.Column flex='none'>
                        <Section width='20' align='flex-start'>
                          <Text regular10 color={VARIABLES.COLORS.BLACK}>6.</Text>
                        </Section>
                      </Grid.Column>
                      <Grid.Column flex='1'>
                        <Section align='flex-start'>
                          <Text regular10 color={VARIABLES.COLORS.BLACK}>Jika klaim garansi valid, dimana material mengalami perforasi korosi dikarenakan kondisi cuaca normal, BlueScope akan mengganti bagian yang karat/korosi saja, dengan penggantian produk sejumlah bagian (batangan / lembaran) yang karat.</Text>
                        </Section>
                      </Grid.Column>
                    </Grid>
                  </Section>
                  <Section spacingBottom='4'>
                    <Grid>
                      <Grid.Column flex='none'>
                        <Section width='20' align='flex-start'>
                          <Text regular10 color={VARIABLES.COLORS.BLACK}>7.</Text>
                        </Section>
                      </Grid.Column>
                      <Grid.Column flex='1'>
                        <Section align='flex-start'>
                          <Text regular10 color={VARIABLES.COLORS.BLACK}>BlueScope tidak bertanggung jawab atas biaya tenaga kerja yang terkait dengan instalasi, biaya perbaikan pada produk yang rusak, cedera orang, dan kerusakan pada properti lainnya.</Text>
                        </Section>
                      </Grid.Column>
                    </Grid>
                  </Section>
                  <Section spacingBottom='4'>
                    <Grid>
                      <Grid.Column flex='none'>
                        <Section width='20' align='flex-start'>
                          <Text regular10 color={VARIABLES.COLORS.BLACK}>8.</Text>
                        </Section>
                      </Grid.Column>
                      <Grid.Column flex='1'>
                        <Section align='flex-start'>
                          <Text regular10 color={VARIABLES.COLORS.BLACK}>Garansi 10 tahun perforasi korosi pada produk BlueScope Zacs™ berlaku untuk jarak bangunan lebih dari 1km dari garis pantai dan lebih dari 2km dari area industri. Periode garansi tertera adalah untuk aplikasi atap dan rangka atap, aplikasi non atap (seperti dinding) mempunyai periode garansi yang berbeda. Perforasi korosi adalah kondisi dimana material berkarat hingga berlubang.</Text>
                        </Section>
                      </Grid.Column>
                    </Grid>
                  </Section>
                  <Section spacingBottom='4'>
                    <Grid>
                      <Grid.Column flex='none'>
                        <Section width='20' align='flex-start'>
                          <Text regular10 color={VARIABLES.COLORS.BLACK}>9.</Text>
                        </Section>
                      </Grid.Column>
                      <Grid.Column flex='1'>
                        <Section align='flex-start'>
                          <Text regular10 color={VARIABLES.COLORS.BLACK}>5 Tahun garansi perlindungan warna hanya untuk produk BlueScope Zacs™ Cool, dan berlaku untuk jarak bangunan lebih dari 1km dari garis pantai dan lebih dari 2km dari area industri. Periode garansi tertera adalah untuk aplikasi atap, aplikasi non atap (seperti dinding) mempunyai periode garansi yang berbeda.</Text>
                        </Section>
                      </Grid.Column>
                    </Grid>
                  </Section>
                  <Section>
                    <Grid>
                      <Grid.Column flex='none'>
                        <Section width='20' align='flex-start'>
                          <Text regular10 color={VARIABLES.COLORS.BLACK}>10.</Text>
                        </Section>
                      </Grid.Column>
                      <Grid.Column flex='1'>
                        <Section align='flex-start'>
                          <Text regular10 color={VARIABLES.COLORS.BLACK}>Untuk produk atap warna (yang mendapatkan garansi perlindungan warna), batas satuan teknis pemudaran warna yang diganti (dibandingkan dengan produk warna tersebut yang baru) adalah jika sudah mencapai ∆E = 15 (berdasarkan standar ASTM D2244).</Text>
                        </Section>
                      </Grid.Column>
                    </Grid>
                  </Section>
                  <Section height='1' heightUnit='px' spacingTop='15' spacingBottom='10' bgColor={VARIABLES.COLORS.BLACK} />
                  <Section spacingBottom='10'>
                    <Grid>
                      <Grid.Column grid='6'>
                        <Section spacingBottom='4'>
                          <Text bold10 color={VARIABLES.COLORS.BLACK}>Head office</Text>
                        </Section>
                        <Section>
                          <Text regular10 color={VARIABLES.COLORS.BLACK}>PT NS BlueScope Indonesia</Text>
                        </Section>
                        <Section>
                          <Text regular10 color={VARIABLES.COLORS.BLACK}>South Quarter, Tower A, 10th floor,  Unit E. F, G, H,</Text>
                        </Section>
                        <Section>
                          <Text regular10 color={VARIABLES.COLORS.BLACK}>Jl. RA. Kartini Kav. 8, CIlandak Barat, Jakarta Selatan - 12430, Indonesia</Text>
                        </Section>
                        <Section>
                          <Text regular10 color={VARIABLES.COLORS.BLACK}>Tel: +62 21 5098 2030; Fax: +62 21 50982040</Text>
                        </Section>
                      </Grid.Column>
                      <Grid.Column grid='6'>
                        <Section>
                          <Text bold10 color={VARIABLES.COLORS.BLACK}>Marketing Office</Text>
                        </Section>
                        <Section>
                          <Text regular10 color={VARIABLES.COLORS.BLACK}>
                            Alamanda Tower, 19<Text regular8 lineheightTh superScript color={VARIABLES.COLORS.BLACK}>th</Text> Floor
                          </Text>
                        </Section>
                        <Section>
                          <Text regular10 color={VARIABLES.COLORS.BLACK}>Jl. TB Simatupang Kav 23-24</Text>
                        </Section>
                        <Section>
                          <Text regular10 color={VARIABLES.COLORS.BLACK}>Jakarta 12430</Text>
                        </Section>
                        <Section>
                          <Text regular10 color={VARIABLES.COLORS.BLACK}>Ph (62-21) 2966-0280  Fax (62-21) 2966 0281</Text>
                        </Section>
                      </Grid.Column>
                    </Grid>
                  </Section>
                  <Section>
                    <Text regular10 color={VARIABLES.COLORS.BLACK}>COLORBOND© and ZINCALUME© are registered trademarks of NS BlueScope Limited.</Text>
                  </Section>
                  <Section>
                    <Text regular10 color={VARIABLES.COLORS.BLACK}>BlueScope is a trademark of NS BlueScope Limited.</Text>
                  </Section>
                </WarrantyWrapper>
              )
            case 'templateMalaysia':
              return (
                <WarrantyWrapper className={classes}>
                  <Section spacingBottom='20'>
                    <Grid justify='space-between'>
                      <Grid.Column>
                        <Image ui='logoBlueScope' />
                      </Grid.Column>
                      <Grid.Column justify='center'>
                        <Section paddingTop='10'>
                          <Text bold24 color={VARIABLES.COLORS.PRIMARY_2}>Warranti</Text>
                        </Section>
                      </Grid.Column>
                      <Grid.Column>
                        <Image ui={logo} />
                      </Grid.Column>
                    </Grid>
                  </Section>
                  <Section
                    justify='space-between'
                    spacingBottom='5'
                  >
                    <Text bold12 color={VARIABLES.COLORS.BLACK}>
                      Waranti No./保证卡编号&nbsp;&nbsp;&nbsp;{warrantyNumber}
                    </Text>
                    <Text bold12 color={VARIABLES.COLORS.BLACK}>
                      Tarikh Pemasangan / 安装日期&nbsp;&nbsp;&nbsp;{setupDate}
                    </Text>
                  </Section>
                  <Section>
                    <Text regular10 color={VARIABLES.COLORS.BLACK}>
                      <Text bold10 color={VARIABLES.COLORS.BLACK}>Nama Pelanggan / 顾客姓名</Text>
                      &nbsp;&nbsp;&nbsp;{projectName}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      <Text bold10 color={VARIABLES.COLORS.BLACK}>Nombor Telefon / 电话号码</Text>
                      &nbsp;&nbsp;&nbsp;{phone}
                    </Text>
                  </Section>
                  <Section>
                    <Text regular10 color={VARIABLES.COLORS.BLACK}>
                      <Text bold10 color={VARIABLES.COLORS.BLACK}>Alamat Pemasangan / 安装地址</Text>
                      &nbsp;&nbsp;&nbsp;{projectAddress}
                    </Text>
                  </Section>
                  <Section>
                    <Text regular10 color={VARIABLES.COLORS.BLACK}>
                      <Text regular10 color={VARIABLES.COLORS.BLACK}>
                        <Text bold10 color={VARIABLES.COLORS.BLACK}>Pengedar Sah / 授权经销商</Text>
                        &nbsp;&nbsp;&nbsp;{bsadName}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <Text bold10 color={VARIABLES.COLORS.BLACK}>Nombor Telefon / 电话号码</Text>
                        &nbsp;&nbsp;&nbsp;{bsadMobile}
                      </Text>
                    </Text>
                  </Section>
                  <Section spacingBottom='10'>
                    <Text regular10 color={VARIABLES.COLORS.BLACK}>
                      <Text bold10 color={VARIABLES.COLORS.BLACK}>Alamat / 地址</Text>
                      &nbsp;&nbsp;&nbsp;{bsadAddress}
                    </Text>
                  </Section>
                  <Section spacingBottom='5'>
                    <Text bold12 color={VARIABLES.COLORS.BLACK}>Jenis keluli BlueScope ZACS® yang dibeli / 购买的BlueScope ZACS ®钢铁种类: </Text>
                  </Section>
                  <Section spacingBottom='10'>
                    {/* Note: content width = 674 */}
                    <Table fluid warrantyMalaysia>
                      <Table.Section>
                        <Table.Row>
                          <Table.Column className='is-head-column' alignCenter maxWidth='360' minWidth='360' padding='4'>
                            <Text bold10 color={VARIABLES.COLORS.BLACK}>{'<Keluli BlueScope ZACS® / BlueScope ZACS® 钢铁>'}</Text>
                          </Table.Column>
                          <Table.Column className='is-head-column' alignCenter maxWidth='187' minWidth='187' padding='4'>
                            <Text bold10 color={VARIABLES.COLORS.BLACK}>{'<Ketebalan / 厚度> Thickness (BMT)'}</Text>
                          </Table.Column>
                          <Table.Column className='is-head-column' alignCenter maxWidth='187' minWidth='187' padding='4'>
                            <Text bold10 color={VARIABLES.COLORS.BLACK}>{'<Panjang / 长度> Length meter '}</Text>
                          </Table.Column>
                        </Table.Row>
                      </Table.Section>
                      {dataGroupList.map((e, i) => {
                        return (
                          <Table.Section key={i}>
                            {e.displayCoil.map((ec, ic) => {
                              return (
                                <Table.Row key={ic}>
                                  <Table.Column className='is-body-column' maxWidth='360' minWidth='360' padding='4'>
                                    <Text regular10 color={VARIABLES.COLORS.BLACK}>
                                      {e.displayCoil[ic]}
                                    </Text>
                                  </Table.Column>
                                  <Table.Column className='is-body-column' alignCenter maxWidth='187' minWidth='187' padding='4'>
                                    <Text regular10 color={VARIABLES.COLORS.BLACK}>
                                      {e.bmtList[ic]}
                                    </Text>
                                  </Table.Column>
                                  <Table.Column className='is-body-column' alignCenter maxWidth='187' minWidth='187' padding='4'>
                                    <Text regular10 color={VARIABLES.COLORS.BLACK}>
                                      {e.coilOrderMList[ic]}
                                    </Text>
                                  </Table.Column>
                                </Table.Row>
                              )
                            })}
                          </Table.Section>
                        )
                      })}
                    </Table>
                  </Section>
                  <Section>
                    <Text regular10 color={VARIABLES.COLORS.BLACK}>
                      Waranti BlueScope ZACS® hanya merangkumi bahagian-bahagian yang berikut:
                    </Text>
                  </Section>
                  <Section spacingBottom='10'>
                    <Text regular10 color={VARIABLES.COLORS.BLACK}>
                      BlueScope ZACS® 的产品 保证仅限以下部分:
                    </Text>
                  </Section>
                  <Section spacingBottom='10'>

                    <Grid gutter='30'>
                      <Grid.Column>
                        <Text regular10 color={VARIABLES.COLORS.BLACK} top='-1'>1. Pengatapan / 屋顶</Text>
                      </Grid.Column>
                      <Grid.Column>
                        <Text regular10 color={VARIABLES.COLORS.BLACK} top='-1'>2. Awning / 雨篷</Text>
                      </Grid.Column>
                      <Grid.Column>
                        <Text regular10 color={VARIABLES.COLORS.BLACK} top='-1'>3. Pelapisan Dinding / 墙面</Text>
                      </Grid.Column>
                    </Grid>
                  </Section>
                  <Section spacingBottom='5'>
                    <Text bold12 color={VARIABLES.COLORS.BLACK}>Tempoh Waranti / 保证期</Text>
                  </Section>
                  <Section spacingBottom='20'>
                    <ShapeContainer fluid paddingTop='2' paddingBottom='4' paddingHorizontal='4' borderWidth='1' borderStyle='solid' borderColor={VARIABLES.COLORS.BLACK}>
                      <Section>
                        <Grid
                          gutter='30'
                          justify='space-between'
                        >
                          <Grid.Column>
                            <Text regular10 color={VARIABLES.COLORS.BLACK}>- BlueScope ZACS® - Perforasi akibat pengakisan / 防腐蚀穿孔</Text>
                          </Grid.Column>
                          <Grid.Column>
                            <Text regular10 color={VARIABLES.COLORS.BLACK}>10 Tahun</Text>
                          </Grid.Column>
                        </Grid>
                      </Section>
                      <Section>
                        <Grid
                          gutter='30'
                          justify='space-between'
                        >
                          <Grid.Column>
                            <Text regular10 color={VARIABLES.COLORS.BLACK}>- BlueScope ZACS® – Kepudaran warna / 防退色保证</Text>
                          </Grid.Column>
                          <Grid.Column>
                            <Text regular10 color={VARIABLES.COLORS.BLACK}>5 Tahun</Text>
                          </Grid.Column>
                        </Grid>
                      </Section>
                      <Section paddingTop='5'>
                        <Text regular10 color={VARIABLES.COLORS.BLACK}>*Tertakluk kepada terma & syarat / 须符合保修条规.</Text>
                      </Section>
                    </ShapeContainer>
                  </Section>
                  <Section spacingBottom='14'>
                    <Text regular10 color={VARIABLES.COLORS.BLACK}>Nota: Waranti BlueScope ZACS® ini akan menjelaskan secara terperinci sokongan BlueScope terhadap produk keluarannya melalui jaminan perlindungan yang disediakan. Adalah mustahak untuk anda membaca dan memahami terma dan syarat jaminan yang tertulis di dalam kad waranti ini. Nikmati ketenangan minda dengan waranti yang ditawarkan oleh BlueScope, berasaskan teknologi serta penyelidikan dan pembangunan Australia yang terkini.</Text>
                  </Section>
                  <Section spacingBottom='5'>
                    <Text bold12 color={VARIABLES.COLORS.BLACK}>Tertakluk pada terma dan syarat</Text>
                  </Section>
                  <Section spacingBottom='4'>
                    <Grid>
                      <Grid.Column flex='none'>
                        <Section width='20' align='flex-start'>
                          <Text regular10 color={VARIABLES.COLORS.BLACK}>*</Text>
                        </Section>
                      </Grid.Column>
                      <Grid.Column flex='1'>
                        <Section align='flex-start'>
                          <Text regular10 color={VARIABLES.COLORS.BLACK}>Jaminan 10 tahun terhadap perforasi akibat daripada pengakisan.</Text>
                        </Section>
                      </Grid.Column>
                    </Grid>
                  </Section>
                  <Section spacingBottom='4'>
                    <Grid>
                      <Grid.Column flex='none'>
                        <Section width='20' align='flex-start'>
                          <Text regular10 color={VARIABLES.COLORS.BLACK}>*</Text>
                        </Section>
                      </Grid.Column>
                      <Grid.Column flex='1'>
                        <Section align='flex-start'>
                          <Text regular10 color={VARIABLES.COLORS.BLACK}>Jaminan 5 tahun untuk kepudaran warna.</Text>
                        </Section>
                      </Grid.Column>
                    </Grid>
                  </Section>
                  <Section spacingBottom='4'>
                    <Grid>
                      <Grid.Column flex='none'>
                        <Section width='20' align='flex-start'>
                          <Text regular10 color={VARIABLES.COLORS.BLACK}>1.</Text>
                        </Section>
                      </Grid.Column>
                      <Grid.Column flex='1'>
                        <Section align='flex-start'>
                          <Text regular10 color={VARIABLES.COLORS.BLACK}>BlueScope memberi jaminan kepada pemilik asal  bahawa BlueScope ZACS® - jika dipasang dan diselenggara dengan betul, akan mencapai tahap minimum jangkaan prestasi sekurang-kurangnya selama tempoh waranti (jaminan bermula daripada tarikh pemasangan). Waranti terhad ini tidak boleh dipindah milik.</Text>
                        </Section>
                      </Grid.Column>
                    </Grid>
                  </Section>
                  <Section spacingBottom='4'>
                    <Grid>
                      <Grid.Column flex='none'>
                        <Section width='20' align='flex-start'>
                          <Text regular10 color={VARIABLES.COLORS.BLACK}>2.</Text>
                        </Section>
                      </Grid.Column>
                      <Grid.Column flex='1'>
                        <Section align='flex-start'>
                          <Text regular10 color={VARIABLES.COLORS.BLACK}>Waranti ini hanya merangkumi bahagian pelapisan (cladding) dan labong (flashing) yang diperbuat daripada produk BlueScope ZACS® yang berkualiti tinggi. Waranti ini tidak meliputi saluran air hujan, paip meliputi pengakran, sangga, pintu dan aksesori-aksesori lain.</Text>
                        </Section>
                      </Grid.Column>
                    </Grid>
                  </Section>
                  <Section spacingBottom='4'>
                    <Grid>
                      <Grid.Column flex='none'>
                        <Section width='20' align='flex-start'>
                          <Text regular10 color={VARIABLES.COLORS.BLACK}>3.</Text>
                        </Section>
                      </Grid.Column>
                      <Grid.Column flex='1'>
                        <Section align='flex-start'>
                          <Text regular10 color={VARIABLES.COLORS.BLACK}>Waranti ini sah untuk produk-produk berikut:</Text>
                        </Section>
                      </Grid.Column>
                    </Grid>
                  </Section>
                  <Section paddingLeft='20'>
                    <Section spacingBottom='4'>
                      <Grid>
                        <Grid.Column flex='none'>
                          <Section width='15' align='flex-start'>
                            <Text regular10 color={VARIABLES.COLORS.BLACK}>a.</Text>
                          </Section>
                        </Grid.Column>
                        <Grid.Column flex='1'>
                          <Section align='flex-start'>
                            <Text regular10 color={VARIABLES.COLORS.BLACK}>BlueScope ZACS® (Pre-painted)	Ketebalan 0.20mm – 0.40mm (BMT)</Text>
                          </Section>
                        </Grid.Column>
                      </Grid>
                    </Section>
                    <Section spacingBottom='4'>
                      <Grid>
                        <Grid.Column flex='none'>
                          <Section width='15' align='flex-start'>
                            <Text regular10 color={VARIABLES.COLORS.BLACK}>b.</Text>
                          </Section>
                        </Grid.Column>
                        <Grid.Column flex='1'>
                          <Section align='flex-start'>
                            <Text regular10 color={VARIABLES.COLORS.BLACK}>BlueScope ZACS® (Bare)Ketebalan 0.20mm – 0.40mm (BMT)</Text>
                          </Section>
                        </Grid.Column>
                      </Grid>
                    </Section>
                    <Section spacingBottom='4'>
                      <Grid>
                        <Grid.Column flex='none'>
                          <Section width='15' align='flex-start'>
                            <Text regular10 color={VARIABLES.COLORS.BLACK}>c.</Text>
                          </Section>
                        </Grid.Column>
                        <Grid.Column flex='1'>
                          <Section align='flex-start'>
                            <Text regular10 color={VARIABLES.COLORS.BLACK}>BlueScope ZACS NATURAL®Ketebalan 0.25mm – 0.35mm (BMT)</Text>
                          </Section>
                        </Grid.Column>
                      </Grid>
                    </Section>
                  </Section>
                  <Section spacingBottom='4'>
                    <Grid>
                      <Grid.Column flex='none'>
                        <Section width='20' align='flex-start'>
                          <Text regular10 color={VARIABLES.COLORS.BLACK}>4.</Text>
                        </Section>
                      </Grid.Column>
                      <Grid.Column flex='1'>
                        <Section align='flex-start'>
                          <Text regular10 color={VARIABLES.COLORS.BLACK}>Tuntutan mesti dibuat secara bertulis dalam masa tiga puluh (30) hari selepas perkara tuntutan diketahui, dan perlu disertakan gambar kepincangan produk untuk membolehkan BlueScope melakukan siasatan awal tuntutan sebelum memulakan tinjauan di lokasi terbabit. Mana-mana tuntutan kepincangan yang mana notisnya tidak diterima oleh BlueScope dalam tempoh jaminan yang telah ditetapkan tidak akan dilindungi oleh waranti ini.</Text>
                        </Section>
                      </Grid.Column>
                    </Grid>
                  </Section>
                  <Section spacingBottom='4'>
                    <Grid>
                      <Grid.Column flex='none'>
                        <Section width='20' align='flex-start'>
                          <Text regular10 color={VARIABLES.COLORS.BLACK}>5.</Text>
                        </Section>
                      </Grid.Column>
                      <Grid.Column flex='1'>
                        <Section align='flex-start'>
                          <Text regular10 color={VARIABLES.COLORS.BLACK}>Di bawah waranti terhad 10 tahun BlueScope, bagi kes penembusan akibat kakisan, sekiranya keluli BlueScope ZACS® (yang telah dipasang dan diselenggara mengikut garis panduan yang spesifik, serta terma dan syarat yang ditetapkan) gagal berfungsi sepertimana yang terjamin disebabkan oleh kepincangan bahan pembuatannya, BlueScope ZACS® akan membaiki atau menukar semula, atau memberi pampasan dalam bentuk gantian 1 kepada 1 (bahan pembuatan sahaja).</Text>
                        </Section>
                      </Grid.Column>
                    </Grid>
                  </Section>
                  <Section spacingBottom='4'>
                    <Grid>
                      <Grid.Column flex='none'>
                        <Section width='20' align='flex-start'>
                          <Text regular10 color={VARIABLES.COLORS.BLACK}>6.</Text>
                        </Section>
                      </Grid.Column>
                      <Grid.Column flex='1'>
                        <Section align='flex-start'>
                          <Text regular10 color={VARIABLES.COLORS.BLACK}>Waranti terhad 10 tahun terhadap penembusan akibat kakisan adalah tidak termasuk kos buruh bagi pembuangan atau pelupusan material yang rosak atau untuk penggantian material BlueScope ZACS®. Tiada tanggungan akan diterima untuk mana-mana kerugian atau kerosakan yang berpunca daripada kegagalan ini.</Text>
                        </Section>
                      </Grid.Column>
                    </Grid>
                  </Section>
                  <Section>
                    <Grid>
                      <Grid.Column flex='none'>
                        <Section width='20' align='flex-start'>
                          <Text regular10 color={VARIABLES.COLORS.BLACK}>7.</Text>
                        </Section>
                      </Grid.Column>
                      <Grid.Column flex='1'>
                        <Section align='flex-start'>
                          <Text regular10 color={VARIABLES.COLORS.BLACK}>Untuk pengenalpastian dan menentukan keaslian produk, pemilik rumah/kontraktor dinasihatkan agar memeriksa dan memastikan TEKS PENJENAMAAN BlueScope dicetak di bahagian belakang kepingan sebelum pemasangan dilakukan, seperti cetakan jenama berikut (sebagai contoh):</Text>
                        </Section>
                      </Grid.Column>
                    </Grid>
                  </Section>
                  <Section paddingLeft='20' spacingBottom='4'>
                    <Image ui='warrantyMalaysiaTermsAndConditionsLabel' />
                  </Section>
                  <Section spacingBottom='4'>
                    <Grid>
                      <Grid.Column flex='none'>
                        <Section width='20' align='flex-start'>
                          <Text regular10 color={VARIABLES.COLORS.BLACK}>8.</Text>
                        </Section>
                      </Grid.Column>
                      <Grid.Column flex='1'>
                        <Section align='flex-start'>
                          <Text regular10 color={VARIABLES.COLORS.BLACK}>8 Kegagalan produk akibat kakisan bererti keadaan di mana kepingan telah berlubang. Pengaratan permukaan atau kakisan yang sedikit di lengkungan tegang atau hujung keratan akibat cuaca semulajadi tidak akan dikira sebagai kerosakan/kegagalan produk.</Text>
                        </Section>
                      </Grid.Column>
                    </Grid>
                  </Section>
                  <Section spacingBottom='4'>
                    <Grid>
                      <Grid.Column flex='none'>
                        <Section width='20' align='flex-start'>
                          <Text regular10 color={VARIABLES.COLORS.BLACK}>9.</Text>
                        </Section>
                      </Grid.Column>
                      <Grid.Column flex='1'>
                        <Section align='flex-start'>
                          <Text regular10 color={VARIABLES.COLORS.BLACK}>Skrew(fastener) mestilah mematuhi Kelas 3 AS 3566 atau Kelas 4, dan diperakui oleh pembekal pengancing.</Text>
                        </Section>
                      </Grid.Column>
                    </Grid>
                  </Section>
                  <Section spacingBottom='4'>
                    <Grid>
                      <Grid.Column flex='none'>
                        <Section width='20' align='flex-start'>
                          <Text regular10 color={VARIABLES.COLORS.BLACK}>10.</Text>
                        </Section>
                      </Grid.Column>
                      <Grid.Column flex='1'>
                        <Section align='flex-start'>
                          <Text regular10 color={VARIABLES.COLORS.BLACK}>Pelapisan dinding (cladding) mestilah dikendalikan dan dipasang mengikut garis panduan BlueScope terkini yang dikeluarkan ketika pemasangan, dengan memberi perhatian kepada isu-isu di bawah:</Text>
                        </Section>
                      </Grid.Column>
                    </Grid>
                  </Section>
                  <Section paddingLeft='20'>
                    <Section spacingBottom='4'>
                      <Grid>
                        <Grid.Column flex='none'>
                          <Section width='15' align='flex-start'>
                            <Text regular10 color={VARIABLES.COLORS.BLACK}>a.</Text>
                          </Section>
                        </Grid.Column>
                        <Grid.Column flex='1'>
                          <Section align='flex-start'>
                            <Text regular10 color={VARIABLES.COLORS.BLACK}>Pastikan kepingan sentiasa kering semasa ditimbun untuk pemasangan.</Text>
                          </Section>
                        </Grid.Column>
                      </Grid>
                    </Section>
                    <Section spacingBottom='4'>
                      <Grid>
                        <Grid.Column flex='none'>
                          <Section width='15' align='flex-start'>
                            <Text regular10 color={VARIABLES.COLORS.BLACK}>b.</Text>
                          </Section>
                        </Grid.Column>
                        <Grid.Column flex='1'>
                          <Section align='flex-start'>
                            <Text regular10 color={VARIABLES.COLORS.BLACK}>Kepingan yang basah perlu diasingkan dan dikeringkan bagi mengurangkan risiko yang mempercepatkan kakisan akibat ‘timbunan yang basah’.</Text>
                          </Section>
                        </Grid.Column>
                      </Grid>
                    </Section>
                    <Section spacingBottom='4'>
                      <Grid>
                        <Grid.Column flex='none'>
                          <Section width='15' align='flex-start'>
                            <Text regular10 color={VARIABLES.COLORS.BLACK}>c.</Text>
                          </Section>
                        </Grid.Column>
                        <Grid.Column flex='1'>
                          <Section align='flex-start'>
                            <Text regular10 color={VARIABLES.COLORS.BLACK}>Jangan memotong kepingan dengan mesin gergaji bulat atau pengisar, kerana suhu sisa logam yang tinggi akan merosakkan salutan produk. Ketika pembinaan, buangkan semua sisa besi dan serpihan lain setiap hari bagi mengurangkan risiko kakisan setempat.</Text>
                          </Section>
                        </Grid.Column>
                      </Grid>
                    </Section>
                    <Section spacingBottom='4'>
                      <Grid>
                        <Grid.Column flex='none'>
                          <Section width='15' align='flex-start'>
                            <Text regular10 color={VARIABLES.COLORS.BLACK}>d.</Text>
                          </Section>
                        </Grid.Column>
                        <Grid.Column flex='1'>
                          <Section align='flex-start'>
                            <Text regular10 color={VARIABLES.COLORS.BLACK}>11 Pastikan kepingan sentiasa kering semasa ditimbun untuk pemasangan.</Text>
                          </Section>
                        </Grid.Column>
                      </Grid>
                    </Section>
                    <Section spacingBottom='4'>
                      <Grid>
                        <Grid.Column flex='none'>
                          <Section width='15' align='flex-start'>
                            <Text regular10 color={VARIABLES.COLORS.BLACK}>e.</Text>
                          </Section>
                        </Grid.Column>
                        <Grid.Column flex='1'>
                          <Section align='flex-start'>
                            <Text regular10 color={VARIABLES.COLORS.BLACK}>Kepingan yang basah perlu diasingkan dan dikeringkan bagi mengurangkan risiko yang mempercepatkan kakisan akibat ‘timbunan yang basah’.</Text>
                          </Section>
                        </Grid.Column>
                      </Grid>
                    </Section>
                    <Section spacingBottom={(osName === 'Mac OS') ? '26' : '20'}>
                      <Grid>
                        <Grid.Column flex='none'>
                          <Section width='15' align='flex-start'>
                            <Text regular10 color={VARIABLES.COLORS.BLACK}>f.</Text>
                          </Section>
                        </Grid.Column>
                        <Grid.Column flex='1'>
                          <Section align='flex-start'>
                            <Text regular10 color={VARIABLES.COLORS.BLACK}>Jangan memotong kepingan dengan mesin gergaji bulat atau pengisar, kerana suhu sisa logam yang tinggi akan merosakkan salutan produk. Ketika pembinaan, buangkan semua sisa besi dan serpihan lain setiap hari bagi mengurangkan risiko kakisan setempat.</Text>
                          </Section>
                        </Grid.Column>
                      </Grid>
                    </Section>
                  </Section>
                  <Section spacingBottom='4'>
                    <Grid>
                      <Grid.Column flex='none'>
                        <Section width='20' align='flex-start'>
                          <Text regular10 color={VARIABLES.COLORS.BLACK}>11.</Text>
                        </Section>
                      </Grid.Column>
                      <Grid.Column flex='1'>
                        <Section align='flex-start'>
                          <Text regular10 color={VARIABLES.COLORS.BLACK}>Kesemua pemasangan, pelapisan (cladding) atap serta kompunen yang berkaitan mestilah dibuat dengan logam yang sepadan (keluli BlueScope ZACS ®, keluli ZINCALUME ®, zink atau aluminium) atau disalut atau disadur mengikut garis panduan terkini yang dikeluarkan oleh BlueScope Steel. Untuk mengekalkan rupa permukaan supaya tahan lebih lama, pelapis atap perlu dibuat daripada bahan yang sama seperti pelapisan dinding (cladding).</Text>
                        </Section>
                      </Grid.Column>
                    </Grid>
                  </Section>
                  <Section spacingBottom='4'>
                    <Grid>
                      <Grid.Column flex='none'>
                        <Section width='20' align='flex-start'>
                          <Text regular10 color={VARIABLES.COLORS.BLACK}>12.</Text>
                        </Section>
                      </Grid.Column>
                      <Grid.Column flex='1'>
                        <Section align='flex-start'>
                          <Text regular10 color={VARIABLES.COLORS.BLACK}>Cat luar yang disediakan tidak boleh tercalar, terhakis, rosak, atau disalut dengan bahan asing atau bahan tidak sepadan (termasuk timbunan dan deposit serombong). Waranti ini akan terbatal jika terdapat aktiviti pengecatan semula ke atas keluli BlueScope ZACS®.</Text>
                        </Section>
                      </Grid.Column>
                    </Grid>
                  </Section>
                  <Section spacingBottom='4'>
                    <Grid>
                      <Grid.Column flex='none'>
                        <Section width='20' align='flex-start'>
                          <Text regular10 color={VARIABLES.COLORS.BLACK}>13.</Text>
                        </Section>
                      </Grid.Column>
                      <Grid.Column flex='1'>
                        <Section align='flex-start'>
                          <Text regular10 color={VARIABLES.COLORS.BLACK}>Sekiranya BlueScope tidak dapat membaiki atau mengganti, masalah kepincangan produk seperti yang tertera di bawah lindungan jaminan ini, BlueScope akan, dalam tempoh yang munasabah setelah dimaklumkan mengenai kepincangan tersebut, menggembalikan semula wang pembelian bahan pembuatan BlueScope ZACS® sahaja. BlueScope tidak akan bertanggungjawab di atas penukaran bahan yang berlainan warna berbanding produk asal akibat kesan daripada cuaca.</Text>
                        </Section>
                      </Grid.Column>
                    </Grid>
                  </Section>
                  <Section spacingBottom='4'>
                    <Grid>
                      <Grid.Column flex='none'>
                        <Section width='20' align='flex-start'>
                          <Text regular10 color={VARIABLES.COLORS.BLACK}>14.</Text>
                        </Section>
                      </Grid.Column>
                      <Grid.Column flex='1'>
                        <Section align='flex-start'>
                          <Text regular10 color={VARIABLES.COLORS.BLACK}>Kegagalan keseluruhan atau sebahagian produk akibat daripada sebab di bawah tidak akan dilindungi oleh waranti ini:</Text>
                        </Section>
                      </Grid.Column>
                    </Grid>
                  </Section>
                  <Section paddingLeft='20'>
                    <Section spacingBottom='4'>
                      <Grid>
                        <Grid.Column flex='none'>
                          <Section width='15' align='flex-start'>
                            <Text regular10 color={VARIABLES.COLORS.BLACK}>a.</Text>
                          </Section>
                        </Grid.Column>
                        <Grid.Column flex='1'>
                          <Section align='flex-start'>
                            <Text regular10 color={VARIABLES.COLORS.BLACK}>Mekanikal, kimia atau kerosakan lain, ketika atau selepas pemasangan.</Text>
                          </Section>
                        </Grid.Column>
                      </Grid>
                    </Section>
                    <Section spacingBottom='4'>
                      <Grid>
                        <Grid.Column flex='none'>
                          <Section width='15' align='flex-start'>
                            <Text regular10 color={VARIABLES.COLORS.BLACK}>b.</Text>
                          </Section>
                        </Grid.Column>
                        <Grid.Column flex='1'>
                          <Section align='flex-start'>
                            <Text regular10 color={VARIABLES.COLORS.BLACK}>Serangan daripada agen kimia, asap, cecair atau pepejal selain daripada jatuhan terus hujan.</Text>
                          </Section>
                        </Grid.Column>
                      </Grid>
                    </Section>
                    <Section spacingBottom='4'>
                      <Grid>
                        <Grid.Column flex='none'>
                          <Section width='15' align='flex-start'>
                            <Text regular10 color={VARIABLES.COLORS.BLACK}>c.</Text>
                          </Section>
                        </Grid.Column>
                        <Grid.Column flex='1'>
                          <Section align='flex-start'>
                            <Text regular10 color={VARIABLES.COLORS.BLACK}>Kawasan yang tidak terkena air hujan dan tidak dicuci secara kerap.</Text>
                          </Section>
                        </Grid.Column>
                      </Grid>
                    </Section>
                    <Section spacingBottom='4'>
                      <Grid>
                        <Grid.Column flex='none'>
                          <Section width='15' align='flex-start'>
                            <Text regular10 color={VARIABLES.COLORS.BLACK}>d.</Text>
                          </Section>
                        </Grid.Column>
                        <Grid.Column flex='1'>
                          <Section align='flex-start'>
                            <Text regular10 color={VARIABLES.COLORS.BLACK}>Bahagian yang terkena tanah, debu, baja, atau bahan-bahan yang memerangkap kelembapan.</Text>
                          </Section>
                        </Grid.Column>
                      </Grid>
                    </Section>
                    <Section spacingBottom='4'>
                      <Grid>
                        <Grid.Column flex='none'>
                          <Section width='15' align='flex-start'>
                            <Text regular10 color={VARIABLES.COLORS.BLACK}>e.</Text>
                          </Section>
                        </Grid.Column>
                        <Grid.Column flex='1'>
                          <Section align='flex-start'>
                            <Text regular10 color={VARIABLES.COLORS.BLACK}>Bahagian yang terkena dengan material metalik yang tidak sepadan (seperti plumbum, tembaga, karbon/grafit, atau besi tahan karat) atau aliran air daripada material yang tidak sepadan ini.</Text>
                          </Section>
                        </Grid.Column>
                      </Grid>
                    </Section>
                    <Section spacingBottom='4'>
                      <Grid>
                        <Grid.Column flex='none'>
                          <Section width='15' align='flex-start'>
                            <Text regular10 color={VARIABLES.COLORS.BLACK}>f.</Text>
                          </Section>
                        </Grid.Column>
                        <Grid.Column flex='1'>
                          <Section align='flex-start'>
                            <Text regular10 color={VARIABLES.COLORS.BLACK}>Kegagalan untuk membuang sampah dan/atau kegagalan untuk mencuci perparitan air termasuk pemeluwapan dalaman semua permukaan produk.</Text>
                          </Section>
                        </Grid.Column>
                      </Grid>
                    </Section>
                    <Section spacingBottom='4'>
                      <Grid>
                        <Grid.Column flex='none'>
                          <Section width='15' align='flex-start'>
                            <Text regular10 color={VARIABLES.COLORS.BLACK}>g.</Text>
                          </Section>
                        </Grid.Column>
                        <Grid.Column flex='1'>
                          <Section align='flex-start'>
                            <Text regular10 color={VARIABLES.COLORS.BLACK}>Kemerosotan panel yang disebabkan terkesan dengan keadaan yang basah, daunan atau kayu yang dirawat.</Text>
                          </Section>
                        </Grid.Column>
                      </Grid>
                    </Section>
                    <Section spacingBottom='4'>
                      <Grid>
                        <Grid.Column flex='none'>
                          <Section width='15' align='flex-start'>
                            <Text regular10 color={VARIABLES.COLORS.BLACK}>h.</Text>
                          </Section>
                        </Grid.Column>
                        <Grid.Column flex='1'>
                          <Section align='flex-start'>
                            <Text regular10 color={VARIABLES.COLORS.BLACK}>Persekitaran launtan dan industri atau keadaan yang menyebabkan hakisan, kecuali ianya telah dipersetujui terlebih dahulu.</Text>
                          </Section>
                        </Grid.Column>
                      </Grid>
                    </Section>
                    <Section spacingBottom='4'>
                      <Grid>
                        <Grid.Column flex='none'>
                          <Section width='15' align='flex-start'>
                            <Text regular10 color={VARIABLES.COLORS.BLACK}>i.</Text>
                          </Section>
                        </Grid.Column>
                        <Grid.Column flex='1'>
                          <Section align='flex-start'>
                            <Text regular10 color={VARIABLES.COLORS.BLACK}>Bencana semula jadi.</Text>
                          </Section>
                        </Grid.Column>
                      </Grid>
                    </Section>
                    <Section spacingBottom='4'>
                      <Grid>
                        <Grid.Column flex='none'>
                          <Section width='15' align='flex-start'>
                            <Text regular10 color={VARIABLES.COLORS.BLACK}>j.</Text>
                          </Section>
                        </Grid.Column>
                        <Grid.Column flex='1'>
                          <Section align='flex-start'>
                            <Text regular10 color={VARIABLES.COLORS.BLACK}>Persiapan & penyelenggaraan yang tidak sempurna di lokasi.</Text>
                          </Section>
                        </Grid.Column>
                      </Grid>
                    </Section>
                    <Section spacingBottom='4'>
                      <Grid>
                        <Grid.Column flex='none'>
                          <Section width='15' align='flex-start'>
                            <Text regular10 color={VARIABLES.COLORS.BLACK}>k.</Text>
                          </Section>
                        </Grid.Column>
                        <Grid.Column flex='1'>
                          <Section align='flex-start'>
                            <Text regular10 color={VARIABLES.COLORS.BLACK}>Kegagalan untuk mengikut prosedur pembinaan dan pemasangan mengikut garis panduan BlueScope.</Text>
                          </Section>
                        </Grid.Column>
                      </Grid>
                    </Section>
                  </Section>
                  <Section spacingBottom='4'>
                    <Grid>
                      <Grid.Column flex='none'>
                        <Section width='20' align='flex-start'>
                          <Text regular10 color={VARIABLES.COLORS.BLACK}>15.</Text>
                        </Section>
                      </Grid.Column>
                      <Grid.Column flex='1'>
                        <Section align='flex-start'>
                          <Text regular10 color={VARIABLES.COLORS.BLACK}>Kegagalan produk dari segi warna pudar ditakrifkan seperti jadual berikut:</Text>
                        </Section>
                      </Grid.Column>
                    </Grid>
                  </Section>
                  <Section paddingLeft='20' spacingBottom='4'>
                    {/* Note: content width = 654 */}
                    <Table fluid warranty>
                      <Table.Section>
                        <Table.Row>
                          <Table.Column className='is-head-column' alignCenter maxWidth='277' minWidth='277' padding='4'>
                            <Text bold10 color={VARIABLES.COLORS.BLACK}>Warna</Text>
                          </Table.Column>
                          <Table.Column className='is-head-column' alignCenter maxWidth='277' minWidth='277' padding='4'>
                            <Text bold10 color={VARIABLES.COLORS.BLACK}>
                              Kepudaran Maximum (△ E units CIELAB)<br />
                              ASTM D-2244 (cleaned)
                            </Text>
                          </Table.Column>
                        </Table.Row>
                      </Table.Section>
                      <Table.Section>
                        <Table.Row>
                          <Table.Column className='is-body-column' maxWidth='277' minWidth='277' padding='4'>
                            <Text regular10 color={VARIABLES.COLORS.BLACK}>
                              Semua warna piawai (KECUALI Senja dan Langit)
                            </Text>
                          </Table.Column>
                          <Table.Column className='is-body-column' alignCenter maxWidth='277' minWidth='277' padding='4'>
                            <Text regular10 color={VARIABLES.COLORS.BLACK}>
                              15
                            </Text>
                          </Table.Column>
                        </Table.Row>
                        <Table.Row>
                          <Table.Column className='is-body-column' maxWidth='277' minWidth='277' padding='4'>
                            <Text regular10 color={VARIABLES.COLORS.BLACK}>
                              Senja dan Langit
                            </Text>
                          </Table.Column>
                          <Table.Column className='is-body-column' alignCenter maxWidth='277' minWidth='277' padding='4'>
                            <Text regular10 color={VARIABLES.COLORS.BLACK}>
                              20
                            </Text>
                          </Table.Column>
                        </Table.Row>
                      </Table.Section>
                    </Table>
                  </Section>
                  <Section spacingBottom='10'>
                    <Grid>
                      <Grid.Column flex='none'>
                        <Section width='20' align='flex-start'>
                          <Text regular10 color={VARIABLES.COLORS.BLACK}>16.</Text>
                        </Section>
                      </Grid.Column>
                      <Grid.Column flex='1'>
                        <Section align='flex-start'>
                          <Text regular10 color={VARIABLES.COLORS.BLACK}>Waranti keluli BlueScope ZACS® merupakan jaminan kepastian bahawa produk kami menawarkan kualiti yang tahan lama. Untuk maklumat lanjut, sila hubungi pihak BlueScope seperti berikut:</Text>
                        </Section>
                      </Grid.Column>
                    </Grid>
                  </Section>
                  <Section paddingLeft='20' spacingBottom='10'>
                    <Text regular10 color={VARIABLES.COLORS.BLACK}>
                      NS BlueScope Malaysia Sdn Bhd<br />
                      Level 11, Tower 8, UOA Business Park,<br />
                      No. 1, Jalan Pengaturcara U1/51A,<br />
                      Seksyen U1, 40150 Shah Alam,<br />
                      Selangor Darul Ehsan.
                    </Text>
                  </Section>
                  <Section paddingLeft='20' spacingBottom='5'>
                    <Text regular10 color={VARIABLES.COLORS.BLACK}>
                      Talian Perkhidmatan Pelanggan: 1300 88 6288<br />
                      E-mel: enquiries.malaysia@bluescope.com
                    </Text>
                  </Section>
                  <Section spacingBottom='4'>
                    <Text regular10 color={VARIABLES.COLORS.BLACK}>注:</Text>
                  </Section>
                  <Section spacingBottom='10'>
                    <Text regular10 color={VARIABLES.COLORS.BLACK}>您的BlueScope ZACS®保修卡充分說明BlueScope对其产品品质的重视及提供的保修服务。请详细阅读并了解保修卡内的保修条款。BlueScope所提供的保修服务拥有澳大利亚的技术、研究及开发作为后盾，让您无后顾之忧。</Text>
                  </Section>
                  <Section spacingBottom='5'>
                    <Text bold12 color={VARIABLES.COLORS.BLACK}>须符合保修条规</Text>
                  </Section>
                  <Section spacingBottom='4'>
                    <Grid>
                      <Grid.Column flex='none'>
                        <Section width='20' align='flex-start'>
                          <Text regular10 color={VARIABLES.COLORS.BLACK}>*</Text>
                        </Section>
                      </Grid.Column>
                      <Grid.Column flex='1'>
                        <Section align='flex-start'>
                          <Text regular10 color={VARIABLES.COLORS.BLACK}>10年防腐蚀穿孔保证。</Text>
                        </Section>
                      </Grid.Column>
                    </Grid>
                  </Section>
                  <Section spacingBottom='10'>
                    <Grid>
                      <Grid.Column flex='none'>
                        <Section width='20' align='flex-start'>
                          <Text regular10 color={VARIABLES.COLORS.BLACK}>*</Text>
                        </Section>
                      </Grid.Column>
                      <Grid.Column flex='1'>
                        <Section align='flex-start'>
                          <Text regular10 color={VARIABLES.COLORS.BLACK}>5年防褪色保证。</Text>
                        </Section>
                      </Grid.Column>
                    </Grid>
                  </Section>
                  <Section spacingBottom='4'>
                    <Grid>
                      <Grid.Column flex='none'>
                        <Section width='20' align='flex-start'>
                          <Text regular10 color={VARIABLES.COLORS.BLACK}>1.</Text>
                        </Section>
                      </Grid.Column>
                      <Grid.Column flex='1'>
                        <Section align='flex-start'>
                          <Text regular10 color={VARIABLES.COLORS.BLACK}>BlueScope 保修仅限产品原主。在以正确的方式安装及进行维修的情况下，BlueScope ZACS®确保其性能至少在保修期内 (自安裝日期起生效）满足最低期望水平。此有限保修卡不可转让。</Text>
                        </Section>
                      </Grid.Column>
                    </Grid>
                  </Section>
                  <Section spacingBottom='4'>
                    <Grid>
                      <Grid.Column flex='none'>
                        <Section width='20' align='flex-start'>
                          <Text regular10 color={VARIABLES.COLORS.BLACK}>2.</Text>
                        </Section>
                      </Grid.Column>
                      <Grid.Column flex='1'>
                        <Section align='flex-start'>
                          <Text regular10 color={VARIABLES.COLORS.BLACK}>保修仅限 BlueScope ZACS® 所制成的覆层和防水板。排水沟、落水管、支架、大门及其他配件则不属于保修范围内。</Text>
                        </Section>
                      </Grid.Column>
                    </Grid>
                  </Section>
                  <Section spacingBottom='4'>
                    <Grid>
                      <Grid.Column flex='none'>
                        <Section width='20' align='flex-start'>
                          <Text regular10 color={VARIABLES.COLORS.BLACK}>3.</Text>
                        </Section>
                      </Grid.Column>
                      <Grid.Column flex='1'>
                        <Section align='flex-start'>
                          <Text regular10 color={VARIABLES.COLORS.BLACK}>此保修卡适用于以下产品:</Text>
                        </Section>
                      </Grid.Column>
                    </Grid>
                  </Section>
                  <Section paddingLeft='20'>
                    <Section spacingBottom='4'>
                      <Grid>
                        <Grid.Column flex='none'>
                          <Section width='15' align='flex-start'>
                            <Text regular10 color={VARIABLES.COLORS.BLACK}>a.</Text>
                          </Section>
                        </Grid.Column>
                        <Grid.Column flex='1'>
                          <Section align='flex-start'>
                            <Text regular10 color={VARIABLES.COLORS.BLACK}>BlueScope ZACS COOL® 厚度 0.20mm – 0.40mm (BMT)</Text>
                          </Section>
                        </Grid.Column>
                      </Grid>
                    </Section>
                    <Section spacingBottom='4'>
                      <Grid>
                        <Grid.Column flex='none'>
                          <Section width='15' align='flex-start'>
                            <Text regular10 color={VARIABLES.COLORS.BLACK}>b.</Text>
                          </Section>
                        </Grid.Column>
                        <Grid.Column flex='1'>
                          <Section align='flex-start'>
                            <Text regular10 color={VARIABLES.COLORS.BLACK}>BlueScope ZACS® (Bare) 厚度 0.20mm – 0.40mm (BMT)</Text>
                          </Section>
                        </Grid.Column>
                      </Grid>
                    </Section>
                    <Section spacingBottom='4'>
                      <Grid>
                        <Grid.Column flex='none'>
                          <Section width='15' align='flex-start'>
                            <Text regular10 color={VARIABLES.COLORS.BLACK}>c.</Text>
                          </Section>
                        </Grid.Column>
                        <Grid.Column flex='1'>
                          <Section align='flex-start'>
                            <Text regular10 color={VARIABLES.COLORS.BLACK}>BlueScope ZACS NATURAL® 厚度 0.25mm – 0.35mm (BMT)</Text>
                          </Section>
                        </Grid.Column>
                      </Grid>
                    </Section>
                  </Section>
                  <Section spacingBottom='4'>
                    <Grid>
                      <Grid.Column flex='none'>
                        <Section width='20' align='flex-start'>
                          <Text regular10 color={VARIABLES.COLORS.BLACK}>4.</Text>
                        </Section>
                      </Grid.Column>
                      <Grid.Column flex='1'>
                        <Section align='flex-start'>
                          <Text regular10 color={VARIABLES.COLORS.BLACK}>索偿必须在得知索偿事件发生（30）三十天内以书面形式提出，并附上产品受损的图片，以让BlueScope在前往现场调查前进行初步的索赔调查。若BlueScope没有收到索偿通知，任何在保修期间的索偿都无法被生效。</Text>
                        </Section>
                      </Grid.Column>
                    </Grid>
                  </Section>
                  <Section spacingBottom='4'>
                    <Grid>
                      <Grid.Column flex='none'>
                        <Section width='20' align='flex-start'>
                          <Text regular10 color={VARIABLES.COLORS.BLACK}>5.</Text>
                        </Section>
                      </Grid.Column>
                      <Grid.Column flex='1'>
                        <Section align='flex-start'>
                          <Text regular10 color={VARIABLES.COLORS.BLACK}>根据BlueScope针对腐蚀穿孔提供的10年有限保修，若 BlueScope ZACS® 钢板（根据指南进行安装及维护，并遵守指定条款与条件）因材料本身受损而无法发挥其功能，BlueScope ZACS®钢板将会提供修复和补给，或提供1对1更换补偿 (仅限材料。</Text>
                        </Section>
                      </Grid.Column>
                    </Grid>
                  </Section>
                  <Section spacingBottom='4'>
                    <Grid>
                      <Grid.Column flex='none'>
                        <Section width='20' align='flex-start'>
                          <Text regular10 color={VARIABLES.COLORS.BLACK}>6.</Text>
                        </Section>
                      </Grid.Column>
                      <Grid.Column flex='1'>
                        <Section align='flex-start'>
                          <Text regular10 color={VARIABLES.COLORS.BLACK}>防腐蚀穿孔10年有限保修将不包括因处理损坏材料或更换BlueScope ZACS®材料所产生的人工费。本公司不承担任何间接或直接造成的任何损失或损害。</Text>
                        </Section>
                      </Grid.Column>
                    </Grid>
                  </Section>
                  <Section spacingBottom='4'>
                    <Grid>
                      <Grid.Column flex='none'>
                        <Section width='20' align='flex-start'>
                          <Text regular10 color={VARIABLES.COLORS.BLACK}>7.</Text>
                        </Section>
                      </Grid.Column>
                      <Grid.Column flex='1'>
                        <Section align='flex-start'>
                          <Text regular10 color={VARIABLES.COLORS.BLACK}>欲识别产品原装性，屋主/承包商应在安装前检查并确认材料背面是否印上BlueScope ZACS® 商标文字声明 （如以下例子):</Text>
                        </Section>
                      </Grid.Column>
                    </Grid>
                  </Section>
                  <Section paddingLeft='20' spacingBottom='4'>
                    <Image ui='warrantyMalaysiaTermsAndConditionsLabel' />
                  </Section>
                  <Section>
                    <Grid>
                      <Grid.Column flex='none'>
                        <Section width='20' align='flex-start'>
                          <Text regular10 color={VARIABLES.COLORS.BLACK}>8.</Text>
                        </Section>
                      </Grid.Column>
                      <Grid.Column flex='1'>
                        <Section align='flex-start'>
                          <Text regular10 color={VARIABLES.COLORS.BLACK}>产品穿孔意味着产品因腐蚀而失效，无法使用。因自然气候导致产品表面生锈或边缘处轻微腐蚀将不会构成产品失效。</Text>
                        </Section>
                      </Grid.Column>
                    </Grid>
                  </Section>
                  <Section spacingBottom='4'>
                    <Grid>
                      <Grid.Column flex='none'>
                        <Section width='20' align='flex-start'>
                          <Text regular10 color={VARIABLES.COLORS.BLACK}>9.</Text>
                        </Section>
                      </Grid.Column>
                      <Grid.Column flex='1'>
                        <Section align='flex-start'>
                          <Text regular10 color={VARIABLES.COLORS.BLACK}>紧固件必须符合AS 3566的第3级別或第4级别，同时获得紧固件供应商的承认。</Text>
                        </Section>
                      </Grid.Column>
                    </Grid>
                  </Section>
                  <Section spacingBottom='4'>
                    <Grid>
                      <Grid.Column flex='none'>
                        <Section width='20' align='flex-start'>
                          <Text regular10 color={VARIABLES.COLORS.BLACK}>10.</Text>
                        </Section>
                      </Grid.Column>
                      <Grid.Column flex='1'>
                        <Section align='flex-start'>
                          <Text regular10 color={VARIABLES.COLORS.BLACK}>在安装覆层时必须根据BlueScope最新发布的指南来进行。以下是要特别注意的事项： </Text>
                        </Section>
                      </Grid.Column>
                    </Grid>
                  </Section>
                  <Section paddingLeft='20'>
                    <Section spacingBottom='4'>
                      <Grid>
                        <Grid.Column flex='none'>
                          <Section width='15' align='flex-start'>
                            <Text regular10 color={VARIABLES.COLORS.BLACK}>a.</Text>
                          </Section>
                        </Grid.Column>
                        <Grid.Column flex='1'>
                          <Section align='flex-start'>
                            <Text regular10 color={VARIABLES.COLORS.BLACK}>安裝前，確保堆叠片材时刻处于干燥的状态。</Text>
                          </Section>
                        </Grid.Column>
                      </Grid>
                    </Section>
                    <Section spacingBottom='4'>
                      <Grid>
                        <Grid.Column flex='none'>
                          <Section width='15' align='flex-start'>
                            <Text regular10 color={VARIABLES.COLORS.BLACK}>b.</Text>
                          </Section>
                        </Grid.Column>
                        <Grid.Column flex='1'>
                          <Section align='flex-start'>
                            <Text regular10 color={VARIABLES.COLORS.BLACK}>浸湿的片材应分开放置风干，以降低因“潮湿堆叠”所引发的腐蚀风险。</Text>
                          </Section>
                        </Grid.Column>
                      </Grid>
                    </Section>
                    <Section spacingBottom='4'>
                      <Grid>
                        <Grid.Column flex='none'>
                          <Section width='15' align='flex-start'>
                            <Text regular10 color={VARIABLES.COLORS.BLACK}>c.</Text>
                          </Section>
                        </Grid.Column>
                        <Grid.Column flex='1'>
                          <Section align='flex-start'>
                            <Text regular10 color={VARIABLES.COLORS.BLACK}>不要使用圆锯或磨床切割片材，高温切屑将会损坏涂料层。施工期間，每日必须丢弃金属屑以及其他杂物，以降低局部腐蚀的风险。</Text>
                          </Section>
                        </Grid.Column>
                      </Grid>
                    </Section>
                  </Section>
                  <Section spacingBottom={(osName === 'Mac OS') ? '24' : '4'}>
                    <Grid>
                      <Grid.Column flex='none'>
                        <Section width='20' align='flex-start'>
                          <Text regular10 color={VARIABLES.COLORS.BLACK}>11.</Text>
                        </Section>
                      </Grid.Column>
                      <Grid.Column flex='1'>
                        <Section align='flex-start'>
                          <Text regular10 color={VARIABLES.COLORS.BLACK}>所有固定裝置、屋頂覆层及相关组件必须使用相容金属（BlueScope ZACS®钢，ZINCALUME®钢，鋅或鋁）制造或根据BlueScope钢板最新发布的指南进行涂层或镀层。為了确保外观长久维持不变，屋頂层应使用相同的材料制成， 如屋頂覆层。</Text>
                        </Section>
                      </Grid.Column>
                    </Grid>
                  </Section>
                  <Section spacingBottom='4'>
                    <Grid>
                      <Grid.Column flex='none'>
                        <Section width='20' align='flex-start'>
                          <Text regular10 color={VARIABLES.COLORS.BLACK}>12.</Text>
                        </Section>
                      </Grid.Column>
                      <Grid.Column flex='1'>
                        <Section align='flex-start'>
                          <Text regular10 color={VARIABLES.COLORS.BLACK}>产品表面上的漆不可刮伤、磨损、损坏、或镀上任何不可兼容的材料物质（包括堆棧及烟道沉积物）。如果被发现BlueScope ZACS®钢铁上有任何重新喷漆物，此保修卡將立即失效。</Text>
                        </Section>
                      </Grid.Column>
                    </Grid>
                  </Section>
                  <Section spacingBottom='4'>
                    <Grid>
                      <Grid.Column flex='none'>
                        <Section width='20' align='flex-start'>
                          <Text regular10 color={VARIABLES.COLORS.BLACK}>13.</Text>
                        </Section>
                      </Grid.Column>
                      <Grid.Column flex='1'>
                        <Section align='flex-start'>
                          <Text regular10 color={VARIABLES.COLORS.BLACK}>在保修期內，若BlueScope无法对如上述所說的受损产品进行修复或更换，BlueScope将会在收到相关的产品受损通知后合理的时间内，退还购买BlueScope ZACS®材料的費用。若因正常的风化现象导致替代的材料与原來的材料颜色上不相符，BlueScope一概不负责。</Text>
                        </Section>
                      </Grid.Column>
                    </Grid>
                  </Section>
                  <Section spacingBottom='4'>
                    <Grid>
                      <Grid.Column flex='none'>
                        <Section width='20' align='flex-start'>
                          <Text regular10 color={VARIABLES.COLORS.BLACK}>14.</Text>
                        </Section>
                      </Grid.Column>
                      <Grid.Column flex='1'>
                        <Section align='flex-start'>
                          <Text regular10 color={VARIABLES.COLORS.BLACK}>以下情况所引发的部分或全部产品损坏将不获得保修:</Text>
                        </Section>
                      </Grid.Column>
                    </Grid>
                  </Section>
                  <Section paddingLeft='20'>
                    <Section spacingBottom='4'>
                      <Grid>
                        <Grid.Column flex='none'>
                          <Section width='15' align='flex-start'>
                            <Text regular10 color={VARIABLES.COLORS.BLACK}>a.</Text>
                          </Section>
                        </Grid.Column>
                        <Grid.Column flex='1'>
                          <Section align='flex-start'>
                            <Text regular10 color={VARIABLES.COLORS.BLACK}>一切在安裝中、后因机械、化学或其他因素导致的损坏。</Text>
                          </Section>
                        </Grid.Column>
                      </Grid>
                    </Section>
                    <Section spacingBottom='4'>
                      <Grid>
                        <Grid.Column flex='none'>
                          <Section width='15' align='flex-start'>
                            <Text regular10 color={VARIABLES.COLORS.BLACK}>b.</Text>
                          </Section>
                        </Grid.Column>
                        <Grid.Column flex='1'>
                          <Section align='flex-start'>
                            <Text regular10 color={VARIABLES.COLORS.BLACK}>遭受雨水以外的其它化学物质、气体、液体或硬物破坏。</Text>
                          </Section>
                        </Grid.Column>
                      </Grid>
                    </Section>
                    <Section spacingBottom='4'>
                      <Grid>
                        <Grid.Column flex='none'>
                          <Section width='15' align='flex-start'>
                            <Text regular10 color={VARIABLES.COLORS.BLACK}>c.</Text>
                          </Section>
                        </Grid.Column>
                        <Grid.Column flex='1'>
                          <Section align='flex-start'>
                            <Text regular10 color={VARIABLES.COLORS.BLACK}>未遭受雨水及不经常被清洗的部分。</Text>
                          </Section>
                        </Grid.Column>
                      </Grid>
                    </Section>
                    <Section spacingBottom='4'>
                      <Grid>
                        <Grid.Column flex='none'>
                          <Section width='15' align='flex-start'>
                            <Text regular10 color={VARIABLES.COLORS.BLACK}>d.</Text>
                          </Section>
                        </Grid.Column>
                        <Grid.Column flex='1'>
                          <Section align='flex-start'>
                            <Text regular10 color={VARIABLES.COLORS.BLACK}>长时间接触土壤、灰尘、肥枓或潮湿物质的部分。</Text>
                          </Section>
                        </Grid.Column>
                      </Grid>
                    </Section>
                    <Section spacingBottom='4'>
                      <Grid>
                        <Grid.Column flex='none'>
                          <Section width='15' align='flex-start'>
                            <Text regular10 color={VARIABLES.COLORS.BLACK}>e.</Text>
                          </Section>
                        </Grid.Column>
                        <Grid.Column flex='1'>
                          <Section align='flex-start'>
                            <Text regular10 color={VARIABLES.COLORS.BLACK}>与不相容金屬（如铅、铜、碳/石墨或不绣钢）接触的钢材表面或因水流接触不相容物质后再流过的钢材表面。</Text>
                          </Section>
                        </Grid.Column>
                      </Grid>
                    </Section>
                    <Section spacingBottom='4'>
                      <Grid>
                        <Grid.Column flex='none'>
                          <Section width='15' align='flex-start'>
                            <Text regular10 color={VARIABLES.COLORS.BLACK}>f.</Text>
                          </Section>
                        </Grid.Column>
                        <Grid.Column flex='1'>
                          <Section align='flex-start'>
                            <Text regular10 color={VARIABLES.COLORS.BLACK}>沒有清除垃圾，以及/或未清洗排水装置包括所有产品表面內部的水蒸氣</Text>
                          </Section>
                        </Grid.Column>
                      </Grid>
                    </Section>
                    <Section spacingBottom='4'>
                      <Grid>
                        <Grid.Column flex='none'>
                          <Section width='15' align='flex-start'>
                            <Text regular10 color={VARIABLES.COLORS.BLACK}>g.</Text>
                          </Section>
                        </Grid.Column>
                        <Grid.Column flex='1'>
                          <Section align='flex-start'>
                            <Text regular10 color={VARIABLES.COLORS.BLACK}>因接触潮湿、植物或處理过的木材而造成钢材片腐蚀现象。</Text>
                          </Section>
                        </Grid.Column>
                      </Grid>
                    </Section>
                    <Section spacingBottom='4'>
                      <Grid>
                        <Grid.Column flex='none'>
                          <Section width='15' align='flex-start'>
                            <Text regular10 color={VARIABLES.COLORS.BLACK}>h.</Text>
                          </Section>
                        </Grid.Column>
                        <Grid.Column flex='1'>
                          <Section align='flex-start'>
                            <Text regular10 color={VARIABLES.COLORS.BLACK}>海水、工业或腐蚀性的环境，除非之前有特別协议或声明。</Text>
                          </Section>
                        </Grid.Column>
                      </Grid>
                    </Section>
                    <Section spacingBottom='4'>
                      <Grid>
                        <Grid.Column flex='none'>
                          <Section width='15' align='flex-start'>
                            <Text regular10 color={VARIABLES.COLORS.BLACK}>i.</Text>
                          </Section>
                        </Grid.Column>
                        <Grid.Column flex='1'>
                          <Section align='flex-start'>
                            <Text regular10 color={VARIABLES.COLORS.BLACK}>天灾。</Text>
                          </Section>
                        </Grid.Column>
                      </Grid>
                    </Section>
                    <Section spacingBottom='4'>
                      <Grid>
                        <Grid.Column flex='none'>
                          <Section width='15' align='flex-start'>
                            <Text regular10 color={VARIABLES.COLORS.BLACK}>j.</Text>
                          </Section>
                        </Grid.Column>
                        <Grid.Column flex='1'>
                          <Section align='flex-start'>
                            <Text regular10 color={VARIABLES.COLORS.BLACK}>工地位置准备及管理不當。</Text>
                          </Section>
                        </Grid.Column>
                      </Grid>
                    </Section>
                    <Section spacingBottom='4'>
                      <Grid>
                        <Grid.Column flex='none'>
                          <Section width='15' align='flex-start'>
                            <Text regular10 color={VARIABLES.COLORS.BLACK}>k.</Text>
                          </Section>
                        </Grid.Column>
                        <Grid.Column flex='1'>
                          <Section align='flex-start'>
                            <Text regular10 color={VARIABLES.COLORS.BLACK}>未能遵循BlueScope指南進行建筑及安裝程序。</Text>
                          </Section>
                        </Grid.Column>
                      </Grid>
                    </Section>
                  </Section>
                  <Section spacingBottom='4'>
                    <Grid>
                      <Grid.Column flex='none'>
                        <Section width='20' align='flex-start'>
                          <Text regular10 color={VARIABLES.COLORS.BLACK}>15.</Text>
                        </Section>
                      </Grid.Column>
                      <Grid.Column flex='1'>
                        <Section align='flex-start'>
                          <Text regular10 color={VARIABLES.COLORS.BLACK}>产品在褪色损坏的定义如下表:</Text>
                        </Section>
                      </Grid.Column>
                    </Grid>
                  </Section>
                  <Section paddingLeft='20' spacingBottom='4'>
                    {/* Note: content width = 654 */}
                    <Table fluid warranty>
                      <Table.Section>
                        <Table.Row>
                          <Table.Column className='is-head-column' alignCenter maxWidth='277' minWidth='277' padding='4'>
                            <Text bold10 color={VARIABLES.COLORS.BLACK}>色彩</Text>
                          </Table.Column>
                          <Table.Column className='is-head-column' alignCenter maxWidth='277' minWidth='277' padding='4'>
                            <Text bold10 color={VARIABLES.COLORS.BLACK}>
                              最高的褪色程度 (△ E units CIELAB)<br />
                              ASTM D-2244 (cleaned)
                            </Text>
                          </Table.Column>
                        </Table.Row>
                      </Table.Section>
                      <Table.Section>
                        <Table.Row>
                          <Table.Column className='is-body-column' maxWidth='277' minWidth='277' padding='4'>
                            <Text regular10 color={VARIABLES.COLORS.BLACK}>
                              所有标准色彩（Senja 和 Langit除外）
                            </Text>
                          </Table.Column>
                          <Table.Column className='is-body-column' alignCenter maxWidth='277' minWidth='277' padding='4'>
                            <Text regular10 color={VARIABLES.COLORS.BLACK}>
                              15
                            </Text>
                          </Table.Column>
                        </Table.Row>
                        <Table.Row>
                          <Table.Column className='is-body-column' maxWidth='277' minWidth='277' padding='4'>
                            <Text regular10 color={VARIABLES.COLORS.BLACK}>
                              Senja和Langit
                            </Text>
                          </Table.Column>
                          <Table.Column className='is-body-column' alignCenter maxWidth='277' minWidth='277' padding='4'>
                            <Text regular10 color={VARIABLES.COLORS.BLACK}>
                              20
                            </Text>
                          </Table.Column>
                        </Table.Row>
                      </Table.Section>
                    </Table>
                  </Section>
                  <Section spacingBottom='10'>
                    <Grid>
                      <Grid.Column flex='none'>
                        <Section width='20' align='flex-start'>
                          <Text regular10 color={VARIABLES.COLORS.BLACK}>16.</Text>
                        </Section>
                      </Grid.Column>
                      <Grid.Column flex='1'>
                        <Section align='flex-start'>
                          <Text regular10 color={VARIABLES.COLORS.BLACK}>BlueScope ZACS®保修給您最持久耐用的品质保证。如有任何疑问．请联络BIueScope查询:</Text>
                        </Section>
                      </Grid.Column>
                    </Grid>
                  </Section>
                  <Section paddingLeft='20' spacingBottom='10'>
                    <Text regular10 color={VARIABLES.COLORS.BLACK}>
                      NS BlueScope Malaysia Sdn Bhd<br />
                      Level 11, Tower 8, UOA Business Park,<br />
                      No. 1, Jalan Pengaturcara U1/51A<br />
                      Seksyen U1, 40150 Shah Alam,<br />
                      Selangor Darul Ehsan.
                    </Text>
                  </Section>
                  <Section paddingLeft='20' spacingBottom='20'>
                    <Text regular10 color={VARIABLES.COLORS.BLACK}>
                      客服专线: 1300 88 6288<br />
                      电邮: enquiries.malaysia@bluescope.com
                    </Text>
                  </Section>
                </WarrantyWrapper>
              )
            default:
              return (
                <WarrantyWrapper
                  className={classes}
                >
                  {children}
                </WarrantyWrapper>
              )
          }
        })()}
      </React.Fragment>
    )
  }

  static defaultProps = {
    // * If you set new default props, you should create propsType for correct styleguide document.
  }

  static propTypes = { // TYPE > node, string, number, bool, array, object, symbol, func
    /**
    * Additional classes
    */
    className: PropTypes.string
  }
}
