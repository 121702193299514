import apiService from '../apiService'


export const authController = (configService = {}) => {
  const service = apiService(configService)
  return {
    loginByEmail: (params) => {
      return service.post(`/login`, params)
    },
    // loginByPhone: (params) => {
    //   return service.get(`${apiPath}/auth-dealer`, params)
    // },
    loginSearch: (params) => {
      return service.post(`/login/search`, params)
    }
  }
}
