import React from 'react';
import {
  ShapeContainer,
  Section,
  TitleSection,
  Grid,
  Button,
  Image,
  Text,
} from './../../components'
import {
  VARIABLES,
  ICONS,
} from './../../themes'
import { SelectSalePersonContainerConnected } from '../SelectSalePersonContainer';
import { getLanguage } from '../../helpers/functions/language';
import _ from 'lodash';

export class CustomerContentInfoContainer extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
    }
  }

  render() {
    const {
      data,
      onClickSelectCustomer,
      onChangeSelectSalePerson,
      selectSalePerson,
      isRedemption,
      handleSetSelectSalePerson,
      displayNoneButtonSelectSalePerson,
      disableSelectSalePerson,
      disableSelectSalePersonColor,
      isHideSalePerson
    } = this.props;
    return (
      <React.Fragment>
        <Section spacingBottom='35'>
          <TitleSection name={getLanguage('container.customerContentInfo.title', '')} />
        </Section>
        <Section spacingBottom={isRedemption ? '70' : '35'} >
          <Grid
            gutterTablet='35'
            gutterLargeDesktop='55'
            gutterVertical='35'
            justify='space-between'
          >
            <Grid.Column flex='1'>
              <Grid
                gutterTablet='35'
                gutterLargeDesktop='55'
              >
                <Grid.Column>
                  <ShapeContainer
                    ui='userUpload'
                    srcUserUploadImage={data.oldImageRef ? data.imageUrl : ICONS['ic-placeholder-avatar.svg']}
                  />
                </Grid.Column>
                <Grid.Column>
                  <Section
                    spacingTop='5'
                    spacingBottom='8'
                  >
                    <Text
                      regular14
                      color={VARIABLES.COLORS.GRAY_19}
                    >
                      {getLanguage('container.customerContentInfo.name', '')}
                    </Text>
                  </Section>
                  <Section
                    className='is-info-name'
                    spacingBottom='15'
                  >
                    <Text
                      ellipsis
                      regular22
                      color={VARIABLES.COLORS.BLACK}
                      title={data.fullname}
                    >
                      {data.fullname}
                    </Text>
                  </Section>
                  <Section spacingBottom='5'>
                    <Text
                      regular14
                      color={VARIABLES.COLORS.GRAY_19}
                    >
                      {getLanguage('container.customerContentInfo.trueBluePoints', '')}
                    </Text>
                  </Section>
                  <Section>
                    <Text
                      bold22
                      color={VARIABLES.COLORS.PRIMARY_1}
                    >
                      {data.remainPointsWithComma}
                    </Text>
                  </Section>
                </Grid.Column>
              </Grid>
            </Grid.Column>
            <Grid.Column flex='none'>
              <Grid
                gutterTablet='35'
                gutterLargeDesktop='80'
                gutterVertical='35'
              >
                <Grid.Column>
                  <Grid
                    gutterTablet='35'
                    gutterLargeDesktop='80'
                    gutterVertical='35'
                  >
                    <Grid.Column>
                      <Section
                        spacingTop='5'
                        spacingBottom='15'
                      >
                        <Text
                          regular14
                          color={VARIABLES.COLORS.GRAY_19}
                        >
                          {getLanguage('container.customerContentInfo.nickname', '')}
                        </Text>
                      </Section>
                      <Section spacingBottom='15'>
                        <Text
                          regular14
                          color={VARIABLES.COLORS.BLACK}
                        >
                          {data.nickname ? data.nickname : <span>&nbsp;</span>}
                        </Text>
                      </Section>
                      <Section spacingBottom='18'>
                        <Text
                          regular14
                          color={VARIABLES.COLORS.GRAY_19}
                        >
                          {getLanguage('container.customerContentInfo.phoneNo', '')}
                        </Text>
                      </Section>
                      <Section>
                        <Image
                          ui='flagWithPhoneIdAndNumber'
                          phoneId={data.phoneCountry.value}
                          phoneNumber={data.phone}
                          src={data.phoneCountry.flag}
                        />
                      </Section>
                    </Grid.Column>
                    <Grid.Column>
                      <Section
                        spacingTop='5'
                        spacingBottom='15'
                      >
                        <Text
                          regular14
                          color={VARIABLES.COLORS.GRAY_19}
                        >
                          {getLanguage('container.customerContentInfo.customerType', '')}
                        </Text>
                      </Section>
                      <Section spacingBottom='15'>
                        <Text
                          regular14
                          color={VARIABLES.COLORS.BLACK}
                        >
                          {data.typeText}
                        </Text>
                      </Section>
                      {!isHideSalePerson &&
                        <React.Fragment>
                          <Section spacingBottom='10'>
                            <Text
                              regular14
                              color={VARIABLES.COLORS.GRAY_19}
                            >
                              {getLanguage('field.salesperson', '')}
                            </Text>
                          </Section>
                          <Section width='250'>
                            <SelectSalePersonContainerConnected isSetDefault
                              selectedOption={selectSalePerson}
                              onChange={onChangeSelectSalePerson}
                              handleSetSelectSalePerson={handleSetSelectSalePerson}
                              disableSelectSalePerson={disableSelectSalePerson}
                              disableSelectSalePersonColor={disableSelectSalePersonColor}
                            />
                          </Section>
                        </React.Fragment>
                      }
                    </Grid.Column>
                  </Grid>
                </Grid.Column>
                <Grid.Column>
                  <Section spacingBottom='15' display={displayNoneButtonSelectSalePerson ? 'none' : ''}>
                    <Button
                      blue
                      ui='frontIcon'
                      name={getLanguage('container.customerContentInfo.buttonSelectCustomer', '')}
                      iconWidth='22'
                      iconHeight='18'
                      iconSrc={ICONS['ic-select-customer.svg']}
                      width={VARIABLES.BUTTON.WIDTHS.W_200}
                      onClick={onClickSelectCustomer}
                    />
                  </Section>
                  {/* {
                    data.isTrained &&
                    <Section justify='center'>
                      <Image ui='iconSymbolTrained' />
                    </Section>
                  } */}
                </Grid.Column>
              </Grid>
            </Grid.Column>
          </Grid>
        </Section>
      </React.Fragment >
    )
  }
}