import React from 'react'
import {
  ShapeContainer,
  Section,
  Breadcrumb,
  TitlePage,
  Text,
  TitleSection,
  Grid,
  Image
} from './../../components'
import {
  VARIABLES,
  ICONS,
  CONTENTS
} from './../../themes'
import { setSideMenuActive } from '../../helpers/functions/route';
import { ROUTE_PATH } from '../../helpers/constants/route';
import { redirect } from '../../helpers/functions/route';
import { getContactUsByAppType } from '../../helpers/services/cmsContactUs';
import { getCountryPhone } from '../../helpers/functions/data';
import { GoogleMapContainer } from '../../containers/GoogleMapContainer';
import _ from 'lodash';
import { getLanguage } from '../../helpers/functions/language';

export class ContactUsScreen extends React.Component {

  constructor(props) {
    super(props);
    setSideMenuActive(props);
    this.state = {
      data: {
        hotlineNoCountry: getCountryPhone(),
        phoneNoCountry: getCountryPhone(),
        faxNoCountry: getCountryPhone(),
        websiteUrlList: []
      }
    };
  }

  componentDidMount() {
    this.cmsContactUsManage.getContactUsByAppType();
  }

  cmsContactUsManage = {
    getContactUsByAppType: () => {
      getContactUsByAppType({
        callbackSuccess: (data) => {
          this.setState({
            data
          })
        }
      })
    },
  }

  render() {
    const {
      data,
    } = this.state;
    console.log('data', data)
    return (
      <React.Fragment>
        <Breadcrumb>
          <Breadcrumb.List
            link
            name={getLanguage('contactUs.breadcrumb1', '')}
            onClick={() => {
              redirect(ROUTE_PATH.HOME.LINK)
            }}
          />
          <Breadcrumb.List name='/' />
          <Breadcrumb.List
            link
            name={getLanguage('contactUs.breadcrumb2', '')}
          // onClick={() => {
          //   redirect(ROUTE_PATH.SETTINGS.LINK)
          // }}
          />
          <Breadcrumb.List name='/' />
          <Breadcrumb.List name={getLanguage('contactUs.breadcrumb3', '')} />
        </Breadcrumb>
        <Section ui='content'>
          <Section spacingBottom='35'>
            <TitlePage
              src={ICONS['ic-page-settings.svg']}
              name={getLanguage('contactUs.title', '')}
            />
          </Section>
          <ShapeContainer ui='content'>
            <Section spacingBottom='25'>
              <TitleSection name={getLanguage('contactUs.form.contactUs.title', '')} />
            </Section>
            <Grid
              gutter='65'
              gutterVertical='65'
            >
              <Grid.Column
                gridTablet='12'
                gridLargeDesktop='5'
              >
                <Section paddingTop='25'>
                  <Section
                    paddingBottom='45'
                    spacingBottom='45'
                    borderBottomWidth='1'
                    borderBottomStyle='solid'
                    borderBottomColor={VARIABLES.COLORS.GRAY_12}
                  >
                    <Section spacingBottom='5'>
                      <Text
                        bold14
                        color={VARIABLES.COLORS.BLACK}
                      >
                        {data.companyName}
                      </Text>
                    </Section>
                    <Section spacingBottom='5'>
                      <Text
                        regular14
                        color={VARIABLES.COLORS.BLACK}
                      >
                        {data.address}
                      </Text>
                    </Section>
                    {/* <Section>
                      <Text
                        regular14
                        color={VARIABLES.COLORS.BLACK}
                      >
                        ถนนรางน้ำ พญาไท ราชเทวี กรุงเทพฯ 10400
                      </Text>
                    </Section> */}
                  </Section>
                  <Section
                    paddingBottom='45'
                    spacingBottom='45'
                    borderBottomWidth='1'
                    borderBottomStyle='solid'
                    borderBottomColor={VARIABLES.COLORS.GRAY_12}
                  >
                    <Grid
                      gutter='20'
                      gutterVertical='20'
                    >
                      <Grid.Column flex='none'>
                        <Image ui='iconContact' />
                      </Grid.Column>
                      <Grid.Column flex='1'>
                        <Grid
                          gutter='35'
                          gutterVertical='25'
                        >
                          <Grid.Column>
                            <Section spacingBottom='10'>
                              <Text
                                regular14
                                color={VARIABLES.COLORS.GRAY_19}
                              >
                                {getLanguage('contactUs.form.contactUs.hotline', '')}
                              </Text>
                            </Section>
                            <Image
                              ui='flagWithPhoneIdAndNumber'
                              phoneId={data.hotlineNoCountry.value}
                              phoneNumber={data.hotlineNo}
                              src={data.hotlineNoCountry.flag}
                            />
                          </Grid.Column>
                          <Grid.Column>
                            <Section spacingBottom='10'>
                              <Text
                                regular14
                                color={VARIABLES.COLORS.GRAY_19}
                              >
                                {getLanguage('contactUs.form.contactUs.phoneNo', '')}
                              </Text>
                            </Section>
                            <Image
                              ui='flagWithPhoneIdAndNumber'
                              phoneId={data.phoneNoCountry.value}
                              phoneNumber={`${data.phoneNo} (Auto)`}
                              src={data.phoneNoCountry.flag}
                            />
                          </Grid.Column>
                          <Grid.Column>
                            <Section spacingBottom='10'>
                              <Text
                                regular14
                                color={VARIABLES.COLORS.GRAY_19}
                              >
                                {getLanguage('contactUs.form.contactUs.fax', '')}
                              </Text>
                            </Section>
                            <Image
                              ui='flagWithPhoneIdAndNumber'
                              phoneId={data.faxNoCountry.value}
                              phoneNumber={data.faxNo}
                              src={data.faxNoCountry.flag}
                            />
                          </Grid.Column>
                        </Grid>
                      </Grid.Column>
                    </Grid>
                  </Section>
                  <Section>
                    <Grid
                      gutter='20'
                      gutterVertical='20'
                    >
                      <Grid.Column flex='none'>
                        <Image ui='iconWebsite' />
                      </Grid.Column>
                      <Grid.Column flex='1'>
                        <Section spacingBottom='10'>
                          <Text
                            regular14
                            color={VARIABLES.COLORS.GRAY_19}
                          >
                            {getLanguage('contactUs.form.contactUs.website', '')}
                          </Text>
                        </Section>
                        {data.websiteUrlList.map((e, i) => {
                          return (
                            <Section
                              key={i}
                              spacingBottom={i < (data.websiteUrlList.length - 1) ? '10' : undefined}
                            >
                              <a href={e} target='_blank'>
                                <Text
                                  regular14
                                  underline
                                  color={VARIABLES.COLORS.PRIMARY_1}
                                >
                                  {e}
                                </Text>
                              </a>
                            </Section>
                          )
                        })}
                      </Grid.Column>
                    </Grid>
                  </Section>
                </Section>
              </Grid.Column>
              <Grid.Column
                gridTablet='12'
                gridLargeDesktop='7'
              >
                <ShapeContainer
                  fluid
                  borderWidth='1'
                  borderStyle='solid'
                  borderColor={VARIABLES.COLORS.GRAY_12}
                >
                  <div
                    style={{
                      width: '100%',
                      height: '400px',
                    }}
                  >
                    <GoogleMapContainer
                      latitude={data.latitude}
                      longitude={data.longitude}
                    />
                  </div>
                </ShapeContainer>
              </Grid.Column>
            </Grid>
          </ShapeContainer>
        </Section>
      </React.Fragment >
    )
  }
}
