import styled, {
  css
} from 'styled-components'
import {
  default as VARIABLES
} from './../../../themes/styles/bases/variables'
// import {
//   default as TYPOGRAPHYS
// } from './../../../themes/styles/bases/typographys'
import {
  default as MIXINS
} from './../../../themes/styles/helpers/mixins'

// Sample using image in style
// url(${require('./images/icons/icon-sample.svg')});
// url(${require('./../../../themes/images/icons/icon-sample.svg')});

// Wrapper
// ============================================================
export const TableBaseWrapper = styled.div`
  /* Parent
  ------------------------------- */
  /* Childrens
  ------------------------------- */
  .table-scroll-base {
    /* ${MIXINS.SCROLLBAR_CUSTOM_STYLE({})}; */
    overflow: auto;
  }

  .table-overflow-visible {
    overflow: visible;
  }
  /* Modifiers
  ------------------------------- */
  /* Media queries
  ------------------------------- */
`

// Section
// ============================================================
export const TableSectionBaseWrapper = styled.div`
  /* Parent
  ------------------------------- */
  /* Childrens
  ------------------------------- */
  /* Modifiers
  ------------------------------- */
  /* Media queries
  ------------------------------- */
`

// Row
// ============================================================
export const TableRowBaseWrapper = styled.div`
  /* Parent
  ------------------------------- */
  display: flex;

  /* Childrens
  ------------------------------- */
  /* Modifiers
  ------------------------------- */
  &.is-cursor-pointer {
    cursor: pointer;
  }

  /* Media queries
  ------------------------------- */
`

// Column
// ============================================================
export const TableColumnBaseWrapper = styled.div`
  /* Parent
  ------------------------------- */
  position: relative;
  display: flex;
  flex: 1;
  width: 100%;

  ${props => {
    const theme = props.theme
    return css`
      ${theme.maxWidth && css`
          max-width: ${theme.maxWidth + `px`};
        `
      }

      ${theme.minWidth && css`
          min-width: ${theme.minWidth + `px`};
        `
      }

      ${theme.minHeight && css`
          min-height: ${theme.minHeight + `px`};
        `
      }

      ${theme.padding && css`
          padding: ${theme.padding + `px`};
        `
      }

      ${theme.paddingTop && css`
          padding-top: ${theme.paddingTop + `px`};
        `
      }

      ${theme.paddingRight && css`
          padding-right: ${theme.paddingRight + `px`};
        `
      }

      ${theme.paddingBottom && css`
          padding-bottom: ${theme.paddingBottom + `px`};
        `
      }

      ${theme.paddingLeft && css`
          padding-left: ${theme.paddingLeft + `px`};
        `
      }

      ${theme.paddingHorizontal && css`
          padding-right: ${theme.paddingHorizontal + `px`};
          padding-left: ${theme.paddingHorizontal + `px`};
        `
      }

      ${theme.paddingVertical && css`
          padding-top: ${theme.paddingVertical + `px`};
          padding-bottom: ${theme.paddingVertical + `px`};
        `
      }

      background-color: ${theme.bgColor};

      ${theme.borderWidth && css`
          border-top: ${theme.borderWidth && `${theme.borderWidth + `px`}`} ${theme.borderStyle} ${theme.borderColor};
        `
      }

      ${theme.borderBottomWidth && css`
          border-bottom: ${theme.borderBottomWidth && `${theme.borderBottomWidth + `px`}`} ${theme.borderBottomStyle} ${theme.borderBottomColor};
        `
      }

      ${theme.borderTopRightRadius && css`
          border-top-right-radius: ${theme.borderTopRightRadius + `px`};
        `
      }

      ${theme.borderTopLeftRadius && css`
          border-top-left-radius: ${theme.borderTopLeftRadius + `px`};
        `
      }

      ${theme.borderBottomRightRadius && css`
          border-bottom-right-radius: ${theme.borderBottomRightRadius + `px`};
        `
      }

      ${theme.borderBottomLeftRadius && css`
          border-bottom-left-radius: ${theme.borderBottomLeftRadius + `px`};
        `
      }
    `
  }}

  /* Childrens
  ------------------------------- */
  .table-sort-button-base {
    cursor: pointer;
    /* transform: translateY(-50%); */
    position: absolute;
    z-index: ${VARIABLES.Z_INDEXS.LV_1};
    /* top: 50%; */
    bottom: -2px;

    ${props => {
    const theme = props.theme
    return css`
        ${theme.sortButtonSpacingRight && css`
            right: ${theme.sortButtonSpacingRight + `px`};
          `
      }
      `
  }}
  }

  /* Modifiers
  ------------------------------- */
  &.is-text-align-center {
    justify-content: center;
    text-align: center;
  }

  &.is-text-align-right {
    justify-content: flex-end;
    text-align: right;
  }

  /* Media queries
  ------------------------------- */
`
