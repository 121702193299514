import styled, {
  // css
} from 'styled-components'
import {
  default as VARIABLES
} from './../../../themes/styles/bases/variables'
// import {
//   default as TYPOGRAPHYS
// } from './../../../themes/styles/bases/typographys'
// import {
//   default as MIXINS
// } from './../../../themes/styles/helpers/mixins'
// import {
//   // Example using:
//   // background: url(${CONTENTS['image-Loading.svg']});
//   CONTENTS,
//   ICONS,
//   LOGOS,
//   SHAREDS,
//   DOCUMENTS
// } from './../../../themes'

// Wrapper
// ============================================================
export const LoadingWrapper = styled.div`
  /* Parent
  ------------------------------- */
  pointer-events: none;
  transition: ${VARIABLES.TRANSITIONS.DEFAULT};
  opacity: 0;
  position: fixed;
  z-index: ${VARIABLES.Z_INDEXS.LV_8};
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${VARIABLES.COLORS.OVERLAY_BLACK_1};

  /* Childrens
  ------------------------------- */
  /* Modifiers
  ------------------------------- */
  &.is-active {
    pointer-events: auto;
    opacity: 1;
  }

  /* Media queries
  ------------------------------- */
`
