import { DATA } from "../constants/data"
import { storeDispatch } from "../../stores";
import { mainCreators, MAIN_REDUX_STATE_KEY } from "../../actions/mainAction";
import { setLocalStorage } from "./localStorage";
import { LOCAL_STORAGE } from "../constants/localStorage";
import { lang_th } from "../constants/languageTH";
import { lang_en } from "../constants/languageEN";
import { lang_vi } from "../constants/languageVI";
import { lang_in } from "../constants/languageIN";
import { lang_ml } from "../constants/languageML";
import { lang_la } from "../constants/languageLA";
import _ from 'lodash';

export const changeLanguage = (type = DATA.LANGUAGE.ENGLISH) => {
  let newType = type ? type : DATA.LANGUAGE.ENGLISH;
  language = languageCountry[newType];
  setLocalStorage(LOCAL_STORAGE.LANGUAGE_RBPTRUEBLUE, newType);
  storeDispatch(mainCreators.setMainRedux(MAIN_REDUX_STATE_KEY.LANGUAGE, newType));
}

export const getLanguage = (target, valueDefault = '') => {
  return _.get(language, target, valueDefault);
}

export const getLanguageSpecific = (target, type = DATA.LANGUAGE.ENGLISH) => {
  const languageSpecific = languageCountry[type];
  return _.get(languageSpecific, target, '');
}

const languageCountry = {
  [DATA.LANGUAGE.THAI]: lang_th,
  [DATA.LANGUAGE.ENGLISH]: lang_en,
  [DATA.LANGUAGE.VIETNAM]: lang_vi,
  [DATA.LANGUAGE.INDONESIA]: lang_in,
  [DATA.LANGUAGE.MALAYSIA]: lang_ml,
  [DATA.LANGUAGE.LAOS]: lang_la,
}

export let language = languageCountry[DATA.LANGUAGE.ENGLISH];

