import { displayWithPrefix, displayDateTime, displayWithComma } from "../helpers/functions/display";
import { getPhoneRemovePrefix, getCountryPhone } from "../helpers/functions/data";

export class ModelUploadTransaction {
  constructor(props = {}) {
    this.fullName = `${props.first_name} ${props.last_name}`;
    this.phone = props.phone;
    this.email = props.email;
    this.coilNumber = props.coil_number;
    this.lengthSold = props.length_sold;
    this.projectName = props.project_name;
    this.homeowner = props.homeowner;
    this.purchaseDate = props.purchase_date;
    this.displayPurchaseDate = displayDateTime(this.purchaseDate, '/');
    this.purchaseValue = props.purchase_value || 0;
    this.purchaseValueWithComma = displayWithComma(props.purchase_value);
    this.invoiceNo = props.invoice_no;
    this.invoiceLine = props.invoice_line;
    this.coilName = props.coil_name || '';
    this.salesTransaction = props.sales_transaction__c || '';
    this.customerId = props.customer_id || '';
    this.firstName = props.first_name || '';
    this.lastName = props.last_name || '';
    this.userRefId = props.user_ref_id || '';
    this.checkingStatus = props.checking_status;
    this.message = props.message;
    this.isError = this.checkingStatus === 'invalid'
  }
}