import React from 'react';
import {
  connect,
} from 'react-redux';
import { bindActionCreators } from 'redux';
import { DATA_REDUX_STATE_KEY, dataCreators } from '../../actions/dataAction';
import { MAIN_REDUX_STATE_KEY } from '../../actions/mainAction';
import { ROUTE_PATH } from '../../helpers/constants/route';
import { handleSortData } from '../../helpers/functions/data';
import { displayWithComma } from '../../helpers/functions/display';
import { getLanguage } from '../../helpers/functions/language';
import { redirectData } from '../../helpers/functions/route';
import { getProductQuantityList } from '../../helpers/services/product';
import { ICONS, VARIABLES } from '../../themes';
import {
  Button,
  Field,
  Section,
  ShapeContainer,
  Table,
  TableAction,
} from './../../components';

class ProductQuantityListContainer extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      activePage: 1,
      dataPerPage: 10,
      sortIcon: {
        name: true,
        totalCoil: true,
        totalWeight: true,
        totalLength: true,
      }
    }
  }

  componentDidMount() {
    const {
      userAuth,
    } = this.props;
    const {
      activePage,
      dataPerPage,
    } = this.state;
    const params = {
      page: activePage,
      limit: dataPerPage,
      adRefId: userAuth.adRefId,
    };
    const configService = {
      urlParams: {
        type: 'ready_for_sale'
      }
    }
    getProductQuantityList(params, configService);
  }

  productQuantityList = {
    updateData: (dataList) => {
      const {
        dataActions,
      } = this.props;
      dataActions.setDataRedux(DATA_REDUX_STATE_KEY.PRODUCT_QUANTITY_LIST, dataList);
    },
    handleClearIcon: () => {
      const {
        sortIcon,
      } = this.state;
      let newSortIcon = {};
      Object.keys(sortIcon).forEach(e => {
        newSortIcon[e] = true;
      });
      this.setState({
        sortIcon: newSortIcon
      })
    },
    handleSortIcon: (type) => {
      const {
        sortIcon,
      } = this.state;
      let newSortIcon = {};
      let oldType = type;
      Object.keys(sortIcon).forEach(e => {
        if (sortIcon[e] === false) {
          oldType = e;
        }
        newSortIcon[e] = true;
      });
      Object.keys(sortIcon).forEach(e => {
        if (e === type) {
          if (type === oldType) {
            newSortIcon[e] = !sortIcon[e];
          }
        }
      })
      this.setState({
        sortIcon: newSortIcon
      })
      return newSortIcon
    },
    onClickSort: (type) => {
      const {
        dataList,
      } = this.props;
      const newSortIcon = this.productQuantityList.handleSortIcon(type);
      const tempDataList = handleSortData(dataList, {
        sortTarget: type,
        orderType: newSortIcon[type] ? 'desc' : 'asc'
      });
      this.productQuantityList.updateData(tempDataList);
    },
    getFilterDataList: () => {
      const {
        filterValue,
        dataList,
      } = this.props
      const filter = filterValue.trim();
      return dataList.filter((e, i) => {
        if (filter.length) {
          if (e.name.toLowerCase().indexOf(filter.toLowerCase()) === -1) {
            return false;
          }
        }
        return true;
      })
    }
  }

  input = {
    onChangeCheckbox: (data) => {
      const {
        dataList,
      } = this.props;
      const tempDataList = dataList.map(e => {
        if (e.id === data.id) {
          e.isSelected = !e.isSelected;
        }
        return e
      })
      this.productQuantityList.updateData(tempDataList);
    },
    onClickSelectAll: (ev) => {
      const {
        dataList,
      } = this.props;
      const isSelectedAll = ev.target.checked;
      const tempDataList = this.productQuantityList.getFilterDataList().map(e => {
        e.isSelected = isSelectedAll;
        return e;
      })
      const tempDataIdList = tempDataList.map(e => e.id);
      const resultList = dataList.map(e => {
        if (tempDataIdList.indexOf(e.id) >= 0) {
          e.isSelected = isSelectedAll
        }
        return e;
      })
      this.productQuantityList.updateData(resultList);
    }
  }

  // pagination = {
  //   onPaginationChange: (pageNumber) => {
  //     getProductQuantityList({
  //       page: pageNumber,
  //     });
  //   }
  // }

  render() {
    const {
      dataList,
      dataNoLengthList,
      isProfile,
      profile,
    } = this.props;
    const {
      sortIcon,
    } = this.state;
    const filterList = this.productQuantityList.getFilterDataList();
    const selectedList = filterList.filter(e => e.isSelected);
    return (
      <ShapeContainer
        ui='content'
        justify='space-between'
      >
        <Section spacingBottom='45'>
          <Table productQuantity>
            <Table.Section>
              <Table.Row>
                <Table.HeadColumn
                  maxWidth={VARIABLES.TABLE.COLUMN_WIDTHS.CHECKBOX}
                  minWidth={VARIABLES.TABLE.COLUMN_WIDTHS.CHECKBOX}
                  noSort
                >
                  <Field
                    checked={selectedList.length === filterList.length && filterList.length > 0}
                    ui='checkboxTickCircle'
                    onChange={(ev) => {
                      this.input.onClickSelectAll(ev);
                    }}
                  />
                </Table.HeadColumn>
                <Table.HeadColumn
                  minWidth={VARIABLES.TABLE.COLUMN_WIDTHS.NAMES.PRODUCT}
                  sortAscending={sortIcon.name}
                  onClickSort={() => {
                    this.productQuantityList.onClickSort('name');
                  }}
                >
                  {getLanguage('container.productQuantityList.table.header.productGroup', '')}
                </Table.HeadColumn>
                <Table.HeadColumn
                  maxWidth={VARIABLES.TABLE.COLUMN_WIDTHS.NUMBERS.COIL}
                  minWidth={VARIABLES.TABLE.COLUMN_WIDTHS.NUMBERS.COIL}
                  sortAscending={sortIcon.totalCoil}
                  onClickSort={() => {
                    this.productQuantityList.onClickSort('totalCoil');
                  }}
                >
                  {getLanguage('container.productQuantityList.table.header.noCoils', '')}
                </Table.HeadColumn>
                <Table.HeadColumn
                  alignRight
                  maxWidth={VARIABLES.TABLE.COLUMN_WIDTHS.MEASUREMENTS.WEIGHT}
                  minWidth={VARIABLES.TABLE.COLUMN_WIDTHS.MEASUREMENTS.WEIGHT}
                  sortAscending={sortIcon.totalWeight}
                  onClickSort={() => {
                    this.productQuantityList.onClickSort('totalWeight');
                  }}
                >
                  {getLanguage('container.productQuantityList.table.header.weight', '')}
                </Table.HeadColumn>
                <Table.HeadColumn
                  alignRight
                  maxWidth={VARIABLES.TABLE.COLUMN_WIDTHS.MEASUREMENTS.LENGTH}
                  minWidth={VARIABLES.TABLE.COLUMN_WIDTHS.MEASUREMENTS.LENGTH}
                  sortAscending={sortIcon.totalLength}
                  onClickSort={() => {
                    this.productQuantityList.onClickSort('totalLength');
                  }}
                >
                  {getLanguage('container.productQuantityList.table.header.length', '')}
                </Table.HeadColumn>
              </Table.Row>
            </Table.Section>
            <Table.SectionControl gradientBlue>
              {
                filterList.map((e, i) => {
                  return (
                    <Table.Row
                      key={i}
                    >
                      <Table.BodyColumn
                        action
                        maxWidth={VARIABLES.TABLE.COLUMN_WIDTHS.CHECKBOX}
                        minWidth={VARIABLES.TABLE.COLUMN_WIDTHS.CHECKBOX}
                      >
                        <Field
                          checked={e.isSelected || false}
                          ui='checkboxTickCircle'
                          onChange={() => {
                            this.input.onChangeCheckbox(e);
                          }}
                        />
                      </Table.BodyColumn>
                      <Table.BodyColumn
                        info
                        minWidth={VARIABLES.TABLE.COLUMN_WIDTHS.NAMES.PRODUCT}
                        title={e.name}
                      >
                        {e.name}
                      </Table.BodyColumn>
                      <Table.BodyColumn
                        info
                        maxWidth={VARIABLES.TABLE.COLUMN_WIDTHS.NUMBERS.COIL}
                        minWidth={VARIABLES.TABLE.COLUMN_WIDTHS.NUMBERS.COIL}
                        title={displayWithComma(e.totalCoil)}
                      >
                        {displayWithComma(e.totalCoil)}
                      </Table.BodyColumn>
                      <Table.BodyColumn
                        info
                        alignRight
                        maxWidth={VARIABLES.TABLE.COLUMN_WIDTHS.MEASUREMENTS.WEIGHT}
                        minWidth={VARIABLES.TABLE.COLUMN_WIDTHS.MEASUREMENTS.WEIGHT}
                        title={displayWithComma(e.totalWeight)}
                      >
                        {displayWithComma(e.totalWeight)}
                      </Table.BodyColumn>
                      <Table.BodyColumn
                        info
                        alignRight
                        maxWidth={VARIABLES.TABLE.COLUMN_WIDTHS.MEASUREMENTS.LENGTH}
                        minWidth={VARIABLES.TABLE.COLUMN_WIDTHS.MEASUREMENTS.LENGTH}
                        title={displayWithComma(e.totalLength)}
                      >
                        {displayWithComma(e.totalLength)}
                      </Table.BodyColumn>
                    </Table.Row>
                  )
                })
              }
            </Table.SectionControl>
            <Table.SectionControl blue>
              <Table.Row>
                <Table.BodyColumn
                  result
                  maxWidth={VARIABLES.TABLE.COLUMN_WIDTHS.CHECKBOX}
                  minWidth={VARIABLES.TABLE.COLUMN_WIDTHS.CHECKBOX}
                >
                  &nbsp;
                </Table.BodyColumn>
                <Table.BodyColumn
                  result
                  minWidth={VARIABLES.TABLE.COLUMN_WIDTHS.NAMES.PRODUCT}
                  title={getLanguage('container.productQuantityList.table.footer.total', '')}
                >
                  {getLanguage('container.productQuantityList.table.footer.total', '')}
                </Table.BodyColumn>
                <Table.BodyColumn
                  result
                  maxWidth={VARIABLES.TABLE.COLUMN_WIDTHS.NUMBERS.COIL}
                  minWidth={VARIABLES.TABLE.COLUMN_WIDTHS.NUMBERS.COIL}
                  title={displayWithComma(filterList.map(e => parseInt(e.totalCoil)).sum())}
                >
                  {displayWithComma(filterList.map(e => parseInt(e.totalCoil)).sum())}
                </Table.BodyColumn>
                <Table.BodyColumn
                  result
                  alignRight
                  maxWidth={VARIABLES.TABLE.COLUMN_WIDTHS.MEASUREMENTS.WEIGHT}
                  minWidth={VARIABLES.TABLE.COLUMN_WIDTHS.MEASUREMENTS.WEIGHT}
                  title={displayWithComma(filterList.map(e => parseInt(e.totalWeight)).sum())}
                >
                  {displayWithComma(filterList.map(e => parseInt(e.totalWeight)).sum())}
                </Table.BodyColumn>
                <Table.BodyColumn
                  result
                  alignRight
                  maxWidth={VARIABLES.TABLE.COLUMN_WIDTHS.MEASUREMENTS.LENGTH}
                  minWidth={VARIABLES.TABLE.COLUMN_WIDTHS.MEASUREMENTS.LENGTH}
                  title={displayWithComma(filterList.map(e => parseInt(e.totalLength)).sum())}
                >
                  {displayWithComma(filterList.map(e => parseInt(e.totalLength)).sum())}
                </Table.BodyColumn>
              </Table.Row>
            </Table.SectionControl>
          </Table>
        </Section>
        <TableAction>
          <TableAction.Left>
            {/* <Grid gutter='15'>
              <Grid.Column>
                <Button
                  blue
                  ui='frontIcon'
                  name='Print'
                  width={VARIABLES.BUTTON.WIDTHS.W_125}
                  iconWidth='18'
                  iconHeight='18'
                  iconSrc={ICONS['ic-printer.svg']}
                  onClick={() => { }}
                />
              </Grid.Column>
              <Grid.Column>
                <Button
                  green
                  ui='frontIcon'
                  name='Export'
                  width={VARIABLES.BUTTON.WIDTHS.W_125}
                  iconWidth='16'
                  iconHeight='16'
                  iconSrc={ICONS['ic-export.svg']}
                  onClick={() => { }}
                />
              </Grid.Column>
            </Grid> */}
          </TableAction.Left>
          <TableAction.Right>
            <Button
              lightBlue
              disabled={selectedList.length === 0}
              ui='frontIcon'
              name={getLanguage('container.productQuantityList.table.footer.buttonViewDetail', '')}
              width={VARIABLES.BUTTON.WIDTHS.W_165}
              iconWidth='14'
              iconHeight='14'
              iconSrc={ICONS['ic-detail.svg']}
              onClick={() => {
                const dataList = []
                selectedList.forEach(e => {
                  e.productList.forEach(ep => {
                    dataList.push(ep)
                  });
                });

                if (isProfile) {
                  redirectData(ROUTE_PATH.PROFILE_MAPPING_PRODUCT_LIST.LINK, {
                    dataList,
                    dataNoLengthList,
                    profile,
                  })
                } else {
                  redirectData(ROUTE_PATH.PRODUCT_DETAIL.LINK, {
                    dataList,
                    dataNoLengthList,
                  })
                }
              }}
            />
          </TableAction.Right>
        </TableAction>
      </ShapeContainer>
    )
  }
}

const mapStateToProps = (state) => {
  const dataRedux = state.dataRedux;
  const mainRedux = state.mainRedux;
  const dataList = dataRedux.productQuantityList;
  const dataNoLengthList = dataRedux.productQuantityNoLengthList;
  return {
    dataList,
    dataNoLengthList,
    activePage: dataRedux.productParams.activePage,
    totalData: dataRedux.productParams.totalData || 0,
    userAuth: mainRedux[MAIN_REDUX_STATE_KEY.USER_AUTH],
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    dataActions: bindActionCreators(dataCreators, dispatch),
  }
}

export const ProductQuantityListContainerConnected = connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductQuantityListContainer)