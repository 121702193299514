import { displayWithPrefix, displayWithComma, displayDate } from "../helpers/functions/display";
import { DATE_MONTH_NAME_FORMAT } from "../helpers/constants/data";

export class ModelExpirePoint {
  constructor(props = {}) {
    this.expireDate = props.expire_date;
    const expireDate = props.expire_date.split('T')[0];
    this.displayExpireDate = displayDate(expireDate, '/', DATE_MONTH_NAME_FORMAT);
    this.points = props.points;
    this.pointsWithComma = displayWithComma(props.points);
  }
}