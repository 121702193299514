import { displayWithPrefix, displayDateTime } from "../helpers/functions/display";
import { getPhoneRemovePrefix, getCountryPhone } from "../helpers/functions/data";

export class ModelOrderInquiry {
  constructor(props = {}) {
    this.id = props.id;
    this.idWithPrefix = displayWithPrefix(props.id);
    this.subject = props.subject;
    this.customerName = props.customer_name;
    this.phone = getPhoneRemovePrefix(props.phone);
    this.phoneCountry = getCountryPhone(props.phone);
    this.email = props.email;
    this.description = props.description;
    this.submitAt = props.submit_at;
    this.displaySubmitAt = displayDateTime(props.submit_at, '/');
    this.responseAt = props.response_at;
    this.displayResponseAt = displayDateTime(props.response_at, '/');
    this.status = props.status;
    this.adId = props.ad_id;
    this.userId = props.user_id;
    this.remark = props.remark || '';

    //for order noti list
    this.createdDate = new Date(this.submitAt).getTime();
  }
}