import { dataCreators, DATA_REDUX_STATE_KEY } from "../../actions/dataAction";
import { documentaryController } from "../../apiService/apiController/documentaryService";
import { ModelDocumentary, ModelDocumentaryDetail, ModelDocumentaryMS, ModelWarrantyDocumentary } from "../../models/ModelDocumentary";
import { storeDispatch, storeGetState } from "../../stores";
import { getUserAuthRedux } from "../functions/auth";
import { handleSortParams, handleTrimParams } from "../functions/data";
import { isValidResponse } from "../functions/validation";

export const getM3DocumentaryList = async (params = {}) => {
  const configService = {
    isShowLoading: false,
    params: {
      m3_prod_grp_code: params.m3ProdGrpCode,
      folder_type: params.folderType,
    }
  }
  const documentaryService = documentaryController(configService);
  const res = await documentaryService.getM3DocumentaryList();
  if (isValidResponse(res)) {
    let dataList = res.data.map(e => new ModelWarrantyDocumentary(e));
    console.log('getM3DocumentaryList', res.data, dataList);
    return dataList
  }
  return [];
}

export const getDocumentaryList = async (params = {}) => {
  let userAuth = getUserAuthRedux();
  const dataOptionParams = storeGetState().dataRedux.documentaryOptionParams;
  let currentParams = storeGetState().dataRedux.documentaryParams;
  let newParams = handleTrimParams({
    ...currentParams,
    ...params,
    auth_deal_id: userAuth.id,
  });
  if (!newParams.sort) {
    newParams.sort = 'wrt.created_date desc';
  }
  else {
    newParams = handleSortParams(currentParams, newParams);
  }
  const documentaryService = documentaryController();
  const res = await documentaryService.searchDocumentary(newParams);
  if (isValidResponse(res)) {
    let dataList = res.data.map(e => new ModelDocumentary(e));
    newParams.activePage = newParams.page;
    newParams.totalData = res.total_items;
    if (dataOptionParams.selectedDataList.length) {
      dataList = dataList.map(e => {
        dataOptionParams.selectedDataList.forEach(es => {
          if (es.id === e.id) {
            e.isSelected = es.isSelected;
          }
        })
        return e
      })
    }
    console.log('getDocumentaryList', res.data, dataList, newParams, dataOptionParams);
    storeDispatch(dataCreators.setDataRedux(DATA_REDUX_STATE_KEY.DOCUMENTARY_LIST, dataList));
    storeDispatch(dataCreators.setDataRedux(DATA_REDUX_STATE_KEY.DOCUMENTARY_PARAMS, newParams));
  }
  return [];
}

export const getDocumentaryDraftList = async (params = {}) => {
  let newParams = handleTrimParams({
    ...params,
  });
  newParams.sort = 'documentary.created_date desc, documentary_ms.doc_ms_cate';
  const configService = {
    isShowLoading: false,
  }
  const documentaryService = documentaryController(configService);
  const res = await documentaryService.documentaryDetail(newParams);
  if (isValidResponse(res)) {
    let dataList = res.data.map(e => new ModelDocumentaryDetail(e));
    console.log('getDocumentaryDraftList', res.data, dataList)
    return dataList
  }
  return [];
}

export const getDocumentaryDetailList = async (params = {}) => {
  let userAuth = getUserAuthRedux();
  let currentParams = storeGetState().dataRedux.documentaryDetailParams;
  let newParams = handleTrimParams({
    ...currentParams,
    ...params,
  });
  if (!newParams.sort) {
    newParams.sort = 'documentary.created_date desc, documentary_ms.doc_ms_cate';
  }
  else {
    newParams = handleSortParams(currentParams, newParams);
  }
  const documentaryService = documentaryController();
  const res = await documentaryService.documentaryDetail(newParams);
  if (isValidResponse(res)) {
    let dataList = res.data.map(e => new ModelDocumentaryDetail(e));
    newParams.activePage = newParams.page;
    newParams.totalData = res.total_items;
    console.log('getDocumentaryDetailList', res.data, dataList, newParams);
    storeDispatch(dataCreators.setDataRedux(DATA_REDUX_STATE_KEY.DOCUMENTARY_DETAIL_LIST, dataList));
    storeDispatch(dataCreators.setDataRedux(DATA_REDUX_STATE_KEY.DOCUMENTARY_DETAIL_PARAMS, newParams));
  }
  return [];
}

export const getClearDocumentaryParams = () => {
  return {
    str_date: '',
    end_date: '',
    wrt_no: '',
    project_name: '',
    phone_no: '',
    customer_name: '',
    sales_tran: '',
    sort: '',
  }
}

export const getClearDocumentaryDetailParams = () => {
  return {
    textSearch: '',
    sort: '',
  }
}