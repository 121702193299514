import React from 'react';
import {
  connect
} from 'react-redux';
import { bindActionCreators } from 'redux';
import { dataCreators } from '../../actions/dataAction';
import { projectReferenceController } from '../../apiService/apiController/projectReferenceService';
import { ROUTE_PATH } from '../../helpers/constants/route';
import { getSortAscending } from '../../helpers/functions/data';
import { getLanguage } from '../../helpers/functions/language';
import { toast } from '../../helpers/functions/main';
import { redirectData } from '../../helpers/functions/route';
import { isValidResponse } from '../../helpers/functions/validation';
import { getClearProjectReferenceParams, getProjectReferenceList } from '../../helpers/services/projectReference';
import { ModalConfirmContainer } from '../ModalContainer';
import {
  Button, Grid, Image, PaginationControl, Section, Table, TableAction, Text
} from './../../components';
import {
  ICONS,
  VARIABLES
} from './../../themes';

class ProjectDocumentReferenceListContainer extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      activePage: 1,
      dataPerPage: 10,

      isOpenConfirmDeleteModal: false,
      deleteData: undefined
    }
  }

  componentDidMount() {
    const {
      projectDocumentId,
    } = this.props;
    const {
      activePage,
      dataPerPage,
    } = this.state;
    const params = {
      ...getClearProjectReferenceParams(),
      page: activePage,
      limit: dataPerPage,
      pre_sales_trans_id: projectDocumentId,
    };
    getProjectReferenceList(params);
  }

  projectDocumentReferenceList = {
    onClickSort: (target) => {
      getProjectReferenceList({
        isNoSort: false,
        sort: target
      });
    },
    onClickEdit: (item) => {
      const {
        projectDocumentData
      } = this.props;
      redirectData(`${ROUTE_PATH.PROJECT_DOCUMENT_REFERENCE_MANAGE.LINK}/${item.id}`, {
        data: projectDocumentData
      })
    },
    onClickDelete: (item) => {
      this.setState({
        isOpenConfirmDeleteModal: true,
        deleteData: item,
      })
    },
    handleDelete: async (callbackSuccess = () => { }) => {
      const {
        deleteData,
      } = this.state;
      const configService = {
        data: {
          reference_id: deleteData.id,
        }
      }
      const projectReferenceService = projectReferenceController(configService);
      const res = await projectReferenceService.deleteProjectReference();
      if (isValidResponse(res)) {
        getProjectReferenceList({
          isNoSort: true,
        });
        toast.success(getLanguage('message.referenceSuccessfullyDeleted', ''));
        callbackSuccess();
      }
    }
  }

  modal = {
    onClickCancelDelete: () => {
      this.setState({
        isOpenConfirmDeleteModal: false,
      })
    },
    onClickConfirmDelete: () => {
      this.projectDocumentReferenceList.handleDelete(() => {
        this.modal.onClickCancelDelete();
      })
    }
  }

  pagination = {
    onPaginationChange: (pageNumber) => {
      getProjectReferenceList({
        isNoSort: true,
        page: pageNumber,
      });
    }
  }

  render() {
    const {
      dataList,
      dataParams,
      totalData,
      activePage,
      onClickBack,
    } = this.props;
    const {
      dataPerPage,
      isOpenConfirmDeleteModal,
      deleteData,
    } = this.state;
    return (
      <React.Fragment>
        <Section spacingBottom="40">
          <Table striped>
            <Table.Section>
              <Table.Row>
                <Table.HeadColumn
                  sortAscending={getSortAscending(dataParams, 'reference_name')}
                  onClickSort={() => {
                    this.projectDocumentReferenceList.onClickSort('reference_name desc');
                  }}
                  minWidth={175}
                >
                  {getLanguage('projectDocument.table.header.referenceName', '')}
                </Table.HeadColumn>
                <Table.HeadColumn
                  sortAscending={getSortAscending(dataParams, 'updated_date')}
                  onClickSort={() => {
                    this.projectDocumentReferenceList.onClickSort('updated_date desc');
                  }}
                  minWidth={225}
                  maxWidth={225}
                >
                  {getLanguage('projectDocument.table.header.modifiedName', '')}
                </Table.HeadColumn>
                <Table.HeadColumn
                  noSort
                  alignCenter
                  minWidth={75}
                  maxWidth={75}
                >
                  {getLanguage('projectDocument.table.header.action', '')}
                </Table.HeadColumn>
              </Table.Row>
            </Table.Section>
            {
              dataList.length > 0 ? (
                <Table.Section>
                  {
                    dataList.map((e, i) => {
                      return (
                        <Table.Row key={i}>
                          <Table.BodyColumn
                            info
                            minWidth={175}
                            title={e.referenceName}
                          >
                            {e.referenceName}
                          </Table.BodyColumn>
                          <Table.BodyColumn
                            info
                            minWidth={225}
                            maxWidth={225}
                          >
                            {e.displayUpdatedDate}
                          </Table.BodyColumn>
                          <Table.BodyColumn
                            info
                            alignCenter
                            minWidth={100}
                            maxWidth={100}
                          >
                            <Grid justify="center" gutter="15" align="center">
                              <Grid.Column>
                                <Image
                                  src={ICONS['ic-action-edit.svg']}
                                  widthRatio="12"
                                  widthRatioUnit="px"
                                  heightRatio="12"
                                  heightRatioUnit="px"
                                  onClick={() => {
                                    this.projectDocumentReferenceList.onClickEdit(e);
                                  }}
                                />
                              </Grid.Column>
                              <Grid.Column>
                                <Image
                                  src={ICONS['ic-trash-red.svg']}
                                  widthRatio="16"
                                  widthRatioUnit="px"
                                  heightRatio="16"
                                  heightRatioUnit="px"
                                  onClick={() => {
                                    this.projectDocumentReferenceList.onClickDelete(e);
                                  }}
                                />
                              </Grid.Column>
                            </Grid>
                          </Table.BodyColumn>
                        </Table.Row>
                      )
                    })
                  }
                </Table.Section>
              ) : (
                <Section justify='center' paddingVertical='80'>
                  <Section justify='center' spacingBottom='8' >
                    <Image
                      src={ICONS['ic-lists-gray.svg']}
                      widthRatio="24"
                      widthRatioUnit="px"
                      heightRatio="24"
                      heightRatioUnit="px"

                    />
                  </Section>
                  <Text regular14 color={VARIABLES.COLORS.GRAY_12}>
                    {getLanguage('projectDocument.label.noReference', '')}
                  </Text>
                </Section>
              )
            }
          </Table>
        </Section>
        <TableAction>
          <TableAction.Left>
            <Button
              gray
              ui="back"
              name={getLanguage('field.buttonBack', '')}
              onClick={onClickBack}
            />
          </TableAction.Left>
          <TableAction.Right>
            <Grid gutter='20'>
              <Grid.Column>
                <Section paddingTop='6'>
                  <Text
                    regular12
                    color={VARIABLES.COLORS.GRAY_16}
                  >
                    {getLanguage('pagination.page', '')} {activePage}/{Math.ceil(totalData / dataPerPage)}
                  </Text>
                </Section>
              </Grid.Column>
              <Grid.Column>
                <PaginationControl
                  activePage={activePage}
                  itemsCountPerPage={dataPerPage}
                  totalItemsCount={totalData}
                  pageRangeDisplayed={3}
                  onChange={this.pagination.onPaginationChange}
                />
              </Grid.Column>
            </Grid>
          </TableAction.Right>
        </TableAction>

        {
          isOpenConfirmDeleteModal && (
            <ModalConfirmContainer
              isOpenModal
              isHideIcon
              buttonSubmitRed
              footerButtonWidth={VARIABLES.BUTTON.WIDTHS.W_105}
              modalWidth={VARIABLES.MODAL.WIDTHS.W_480}
              title={getLanguage('projectDocument.modal.label.confirmToDelete', '')}
              messageAlign='center'
              message={getLanguage('projectDocument.modal.label.doYouWantToDelete', '')}
              messageHighlight={`"${deleteData.referenceName}" `}
              messageSecondary={getLanguage('projectDocument.modal.label.reference', '')}
              messageColor={VARIABLES.COLORS.BLACK}
              buttonSubmitName={getLanguage('field.buttonConfirm', '')}
              headerPaddingVertical={'25.5'}
              headerPaddingHorizontal={'45'}
              descriptionPaddingVertical={'45'}
              descriptionMinHeight={'0px'}
              footerPaddingTop={30}
              footerPaddingBottom={30}
              footerPaddingHorizontal={45}
              onClickCancel={this.modal.onClickCancelDelete}
              onClickConfirm={this.modal.onClickConfirmDelete}
            />
          )
        }
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => {
  const dataRedux = state.dataRedux;
  return {
    dataList: dataRedux.projectDocumentReferenceList,
    dataParams: dataRedux.projectDocumentReferenceParams,
    activePage: dataRedux.projectDocumentReferenceParams.activePage,
    totalData: dataRedux.projectDocumentReferenceParams.totalData || 0,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    dataActions: bindActionCreators(dataCreators, dispatch),
  }
}

export const ProjectDocumentReferenceListContainerConnected = connect(
  mapStateToProps,
  mapDispatchToProps
)(ProjectDocumentReferenceListContainer)