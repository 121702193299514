import React from 'react'
import ClassNames from 'classnames'
import PropTypes from 'prop-types'
import {
  osName,
} from 'react-device-detect'
import {
  WarrantyProductConditionsWrapper
} from './styled'
import {
  Section,
  Text,
  Image,
  Grid,
  Table,
  ShapeContainer,
} from './../../../../components'
import {
  VARIABLES,
} from './../../../../themes'

/**
 * Warranty description:
 * - Warranty
 */

export class WarrantyBSWMIndonesiaProductConditions extends React.PureComponent {
  render() {
    const {
      customerName,
      registeredTrademarkName,
      objectName,
      isSteel,
    } = this.props

    // props for css classes
    const classes = ClassNames(
      'warranty-bswm-note',
    )

    return (
      <WarrantyProductConditionsWrapper
        className={classes}
      >
        <Section paddingBottom='10' spacingBottom='10' borderBottomWidth='1' borderBottomStyle='solid' borderBottomColor={VARIABLES.COLORS.BLACK}>
          <p className='warranty-bswm-text is-sm'>
            If the <span className='warranty-bswm-text is-sm is-bold'>{registeredTrademarkName}</span> {isSteel && 'steel'} {objectName} (installed and maintained in accordance with the specific guidelines, terms and conditions attached) fails to perform as warranted due to a defect in the material, the {objectName} will be repaired or resupplied, or compensation provided with due regard to the pro-rata of actual performance versus warranted performance. No liability shall be accepted for any consequential loss or damages attributable to the failure.
          </p>
        </Section>
        <Section spacingBottom='10'>
          <p className='warranty-bswm-text is-sm'>
            This is to certify that representatives or agents of <span className='warranty-bswm-text is-sm is-bold'>{customerName}</span> have inspected the installed {objectName} , and ascertained (by best endeavours) that;
          </p>
        </Section>
        <Section spacingBottom='10'>
          <p className='warranty-bswm-text is-sm'>- The {objectName} is manufactured from prime quality <span className='warranty-bswm-text is-xs is-bold'>{registeredTrademarkName}</span> (branded on reverse);-</p>
          <p className='warranty-bswm-text is-sm'>- The {objectName} appears to have been properly installed (or any concerns are noted below) ;- and</p>
          <p className='warranty-bswm-text is-sm'>- The environment in or around the structure does not appear to be abnormally or severely corrosive.</p>
        </Section>
      </WarrantyProductConditionsWrapper>
    )
  }

  static defaultProps = {
    // If you set new default props, you should create propsType for correct styleguide document.
    registeredTrademarkName: '< Registered Trademark Name >',
    customerName: '< Customer Name >',
    objectName: '< Object Name >',
  }

  static propTypes = {
    // TYPE > node, string, number, bool, array, object, symbol, func
  }
}
