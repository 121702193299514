import styled, { css } from "styled-components";
import { default as VARIABLES } from "./../../../themes/styles/bases/variables";
// import {
//   default as TYPOGRAPHYS
// } from './../../../themes/styles/bases/typographys'
// import {
//   default as MIXINS
// } from './../../../themes/styles/helpers/mixins'

// Sample using image in style
// url(${require('./images/icons/icon-sample.svg')});
// url(${require('./../../../themes/images/icons/icon-sample.svg')});

// Wrapper
// ============================================================
export const ShapeContainerBaseWrapper = styled.div`
    /* Parent
  ------------------------------- */
    display: inline-block;

    ${(props) => {
        const theme = props.theme;
        return css`
            ${theme.flex &&
            css`
                flex: ${theme.flex};
            `}

            ${theme.width &&
            css`
                width: ${theme.width + theme.widthUnit};
            `}

      ${theme.minWidth &&
            css`
                min-width: ${theme.minWidth + theme.widthUnit};
            `}

    ${theme.maxWidth &&
            css`
                max-width: ${theme.maxWidth + theme.widthUnit};
            `}

      ${theme.height &&
            css`
                height: ${theme.height + theme.heightUnit};
            `}

      ${theme.calcHeight &&
            css`
                height: calc(${theme.calcHeight});
            `}

      ${theme.calcMinHeight &&
            css`
                min-height: calc(${theme.calcMinHeight});
            `}

      ${theme.minHeight &&
            css`
                min-height: ${theme.minHeight + theme.heightUnit};
            `}

      ${theme.spacing &&
            css`
                margin: ${theme.spacing + `px`};
            `}

      ${theme.spacingTop &&
            css`
                margin-top: ${theme.spacingTop + `px`};
            `}

      ${theme.spacingRight &&
            css`
                margin-right: ${theme.spacingRight + `px`};
            `}

      ${theme.spacingBottom &&
            css`
                margin-bottom: ${theme.spacingBottom + `px`};
            `}

      ${theme.spacingLeft &&
            css`
                margin-left: ${theme.spacingLeft + `px`};
            `}

      ${theme.spacingHorizontal &&
            css`
                margin-right: ${theme.spacingHorizontal + `px`};
                margin-left: ${theme.spacingHorizontal + `px`};
            `}

      ${theme.spacingVertical &&
            css`
                margin-top: ${theme.spacingVertical + `px`};
                margin-bottom: ${theme.spacingVertical + `px`};
            `}
        `;
    }}

    /* Childrens
  ------------------------------- */
  > .shape-container-content-base {
        transition: ${VARIABLES.TRANSITIONS.DEFAULT};
        display: flex;

        ${(props) => {
        const theme = props.theme;
        return css`
                flex-direction: ${theme.direction};
                flex-wrap: ${theme.wrap};
                justify-content: ${theme.justify};
                align-items: ${theme.align};

                ${theme.width &&
            css`
                    width: ${theme.width + theme.widthUnit};
                `}

                ${theme.minWidth &&
            css`
                    min-width: ${theme.minWidth + theme.widthUnit};
                `}

                ${theme.maxWidth &&
            css`
                    max-width: ${theme.maxWidth + theme.widthUnit};
                `}

        ${theme.height &&
            css`
                    height: ${theme.height + theme.heightUnit};
                `}

        ${theme.calcHeight &&
            css`
                    height: calc(${theme.calcHeight});
                `}

        ${theme.calcMinHeight &&
            css`
                    min-height: calc(${theme.calcMinHeight});
                `}

        ${theme.minHeight &&
            css`
                    min-height: ${theme.minHeight + theme.heightUnit};
                `}

        ${theme.padding &&
            css`
                    padding: ${theme.padding + `px`};
                `}

        ${theme.paddingTop &&
            css`
                    padding-top: ${theme.paddingTop + `px`};
                `}

        ${theme.paddingRight &&
            css`
                    padding-right: ${theme.paddingRight + `px`};
                `}

        ${theme.paddingBottom &&
            css`
                    padding-bottom: ${theme.paddingBottom + `px`};
                `}

        ${theme.paddingLeft &&
            css`
                    padding-left: ${theme.paddingLeft + `px`};
                `}

        ${theme.paddingHorizontal &&
            css`
                    padding-right: ${theme.paddingHorizontal + `px`};
                    padding-left: ${theme.paddingHorizontal + `px`};
                `}

        ${theme.paddingVertical &&
            css`
                    padding-top: ${theme.paddingVertical + `px`};
                    padding-bottom: ${theme.paddingVertical + `px`};
                `}

        background-color: ${theme.bgColor};

                ${theme.borderWidth &&
            css`
                    border: ${theme.borderWidth &&
                `${theme.borderWidth + `px`}`}
                        ${theme.borderStyle} ${theme.borderColor};
                `}

                ${theme.borderTopWidth &&
            css`
                    border-top: ${theme.borderTopWidth &&
                `${theme.borderTopWidth + `px`}`}
                        ${theme.borderTopStyle} ${theme.borderTopColor};
                `}

        ${theme.borderRightWidth &&
            css`
                    border-right: ${theme.borderRightWidth &&
                `${theme.borderRightWidth + `px`}`}
                        ${theme.borderRightStyle} ${theme.borderRightColor};
                `}

        ${theme.borderBottomWidth &&
            css`
                    border-bottom: ${theme.borderBottomWidth &&
                `${theme.borderBottomWidth + `px`}`}
                        ${theme.borderBottomStyle} ${theme.borderBottomColor};
                `}

        ${theme.borderLeftWidth &&
            css`
                    border-left: ${theme.borderLeftWidth &&
                `${theme.borderLeftWidth + `px`}`}
                        ${theme.borderLeftStyle} ${theme.borderLeftColor};
                `}

        ${theme.borderRadius &&
            css`
                    border-radius: ${theme.borderRadius + `px`};
                `}

        box-shadow: ${theme.boxShadow};
            `;
    }}
    }

    /* Modifiers
  ------------------------------- */
    &.is-fluid {
        width: 100%;
    }

    &.is-cursor-pointer {
        cursor: pointer;
    }

    &.is-disabled {
        ${(props) => {
        const theme = props.theme;
        return css`
                            background-color: ${theme.disabledColor};
                            pointer-events: none;
                            opacity: 0.25;
                            border: 1px solid ${theme.disabledColor};
                            border-radius: 5px;
                        `;
    }}
    }

    &.is-disabled-event-tooltips {
        ${(props) => {
        const theme = props.theme;
        return css`
                            background-color: ${theme.disabledColor};
                            border: 1px solid ${VARIABLES.COLORS.GRAY_8};
                            cursor: default;
                            border-radius: 5px;
                            
                        `;
    }}
    }

    &:hover {
        > .shape-container-content-base {
            ${(props) => {
        const theme = props.theme;
        return css`
                    background-color: ${theme.bgHoverColor};
                    border-color: ${theme.borderHoverColor};
                    border-top-color: ${theme.borderTopHoverColor};
                    border-right-color: ${theme.borderRightHoverColor};
                    border-bottom-color: ${theme.borderBottomHoverColor};
                    border-left-color: ${theme.borderLeftHoverColor};
                `;
    }}
        }
        > .shape-container-content-base > .section-base > .text-base {
            ${(props) => {
        const theme = props.theme;
        return css`
                    color: ${theme.textHoverColor};
                `;
    }}
        }
        > .shape-container-content-base > .section-base > .image-base {
            ${(props) => {
        const theme = props.theme;
        return css`
                    background: url(${theme.iconHover}) no-repeat;
                    background-size: 32px 38px;
                `;
    }}
        }
    }

    /* Media queries
  ------------------------------- */
`;
