import React from 'react';
import {
  connect,
} from 'react-redux';
import {
  ShapeContainer,
  Section,
  TableAction,
  Grid,
  Text,
  Button,
  PaginationControl,
  Table,
} from './../../components';
import {
  VARIABLES,
  ICONS
} from './../../themes';
import { getSaleTransList, getClearSaleTransParams } from '../../helpers/services/saleTrans';
import { onOpenModal, toast } from '../../helpers/functions/main';
import { saleTransController } from '../../apiService/apiController/saleTransService';
import { isValidResponse } from '../../helpers/functions/validation';
import { MODAL_TYPE } from '../../helpers/constants/main';
import { getSortAscending } from '../../helpers/functions/data';
import _ from 'lodash';
import { getLanguage } from '../../helpers/functions/language';
import { SaleTransactionHistoryIndoListContainerConnected } from './SaleTransactionHistoryIndoListContainer';
import { SaleTransactionHistoryMalayListContainerConnected } from './SaleTransactionHistoryMalayListContainer';

class SaleTransactionHistoryListContainer extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      activePage: 1,
      dataPerPage: 10,
      customerId: props.customerId,
    }
  }

  componentDidMount() {
    const {
      activePage,
      dataPerPage,
      customerId,
    } = this.state;
    const params = {
      ...getClearSaleTransParams(),
      page: activePage,
      limit: dataPerPage,
      customer_id: customerId,
    };
    getSaleTransList(params);
  }

  historySaleTransList = {
    onClickDelete: (data) => {
      onOpenModal({
        type: MODAL_TYPE.CONFIRM_DELETE,
        message: getLanguage('message.confirmDeleteHistorySaleTrans', ''),
        onClickConfirm: async () => {
          const {
            totalData,
            activePage,
          } = this.props
          const {
            dataPerPage,
          } = this.state;
          const params = {
            id: data.id,
          };
          const saleTransService = saleTransController();
          const res = await saleTransService.deleteSalesTransactionById(params);
          if (isValidResponse(res)) {
            toast.success(getLanguage('message.saveCompleted', ''));
            getSaleTransList({
              isNoSort: true,
              page: activePage > Math.ceil((totalData - 1) / dataPerPage) ? activePage - 1 : activePage,
            });
          }
          else {
            toast.error(res.message);
          }
        }
      })
    },
    onClickSort: (target) => {
      getSaleTransList({
        isNoSort: false,
        sort: target
      });
    },
  }

  pagination = {
    onPaginationChange: (pageNumber) => {
      getSaleTransList({
        isNoSort: true,
        page: pageNumber,
      });
    }
  }

  render() {
    const {
      dataList,
      totalData,
      activePage,
      isIndoToko,
      isMalay,
      dataParams,
    } = this.props;
    const {
      dataPerPage,
    } = this.state;
    return (
      <ShapeContainer
        ui='contentFilter'
        justify='space-between'
      >
        <Section spacingBottom='45'>
          <ShapeContainer fluid paddingVertical='10' paddingHorizontal='15' spacingBottom='20' bgColor={VARIABLES.COLORS.PRIMARY_1} borderRadius='5'>
            <Grid gutter='15' justify='center' align='center'>
              <Grid.Column><img src={ICONS['ic-notification-info-white.svg']} alt='Icon' /></Grid.Column>
              <Grid.Column><Text regular14 color={VARIABLES.COLORS.WHITE}>{getLanguage('container.saleTransactionHistory.hintDeleteTransaction', '')}</Text></Grid.Column>
            </Grid>
          </ShapeContainer>
          {
            isIndoToko ?
              <SaleTransactionHistoryIndoListContainerConnected
                historySaleTransList={this.historySaleTransList}
              />
              :
              isMalay ?
                <SaleTransactionHistoryMalayListContainerConnected
                  historySaleTransList={this.historySaleTransList}
                />
                :
                <Table
                  striped
                  action
                >
                  <Table.Section>
                    <Table.Row>
                      <Table.HeadColumn
                        maxWidth={VARIABLES.TABLE.INFO_WIDTHS.DATE}
                        minWidth={VARIABLES.TABLE.INFO_WIDTHS.DATE}
                        sortAscending={getSortAscending(dataParams, 'create_at')}
                        onClickSort={() => {
                          this.historySaleTransList.onClickSort('create_at desc');
                        }}
                      >
                        {getLanguage('container.saleTransactionHistory.table.header.date', '')}
                      </Table.HeadColumn>
                      <Table.HeadColumn
                        maxWidth={VARIABLES.TABLE.INFO_WIDTHS.ST_ID}
                        minWidth={VARIABLES.TABLE.INFO_WIDTHS.ST_ID}
                        sortAscending={getSortAscending(dataParams, 'name')}
                        onClickSort={() => {
                          this.historySaleTransList.onClickSort('name desc');
                        }}
                        title={getLanguage('container.saleTransactionHistory.table.header.stID', '')}
                      >
                        {getLanguage('container.saleTransactionHistory.table.header.stIDAbbreviation', '')}
                      </Table.HeadColumn>
                      <Table.HeadColumn
                        minWidth={VARIABLES.TABLE.INFO_WIDTHS.COIL_NAME}
                        sortAscending={getSortAscending(dataParams, 'coil_name')}
                        onClickSort={() => {
                          this.historySaleTransList.onClickSort('coil_name desc');
                        }}
                      >
                        {getLanguage('container.saleTransactionHistory.table.header.coilName', '')}
                      </Table.HeadColumn>
                      <Table.HeadColumn
                        maxWidth={VARIABLES.TABLE.INFO_WIDTHS.COIL_ID_INFO}
                        minWidth={VARIABLES.TABLE.INFO_WIDTHS.COIL_ID_INFO}
                        sortAscending={getSortAscending(dataParams, 'coil_id')}
                        onClickSort={() => {
                          this.historySaleTransList.onClickSort('coil_id desc');
                        }}
                      >
                        {getLanguage('container.saleTransactionHistory.table.header.coilID', '')}
                      </Table.HeadColumn>
                      <Table.HeadColumn
                        alignRight
                        maxWidth={VARIABLES.TABLE.INFO_WIDTHS.COLOR}
                        minWidth={VARIABLES.TABLE.INFO_WIDTHS.COLOR}
                        sortAscending={getSortAscending(dataParams, 'top_paint_color__c')}
                        onClickSort={() => {
                          this.historySaleTransList.onClickSort('top_paint_color__c desc');
                        }}
                      >
                        {getLanguage('productDetail.table.header.color', '')}
                      </Table.HeadColumn>
                      <Table.HeadColumn
                        maxWidth={VARIABLES.TABLE.INFO_WIDTHS.CUSTOMER_NAME}
                        minWidth={VARIABLES.TABLE.INFO_WIDTHS.CUSTOMER_NAME}
                        sortAscending={getSortAscending(dataParams, 'customer_name')}
                        onClickSort={() => {
                          this.historySaleTransList.onClickSort('customer_name desc');
                        }}
                        title={getLanguage('container.saleTransactionHistory.table.header.customerName', '')}
                      >
                        {
                          getLanguage('container.saleTransactionHistory.table.header.customerName', '')
                        }
                      </Table.HeadColumn>
                      <Table.HeadColumn
                        maxWidth={VARIABLES.TABLE.INFO_WIDTHS.PHONE}
                        minWidth={VARIABLES.TABLE.INFO_WIDTHS.PHONE}
                        sortAscending={getSortAscending(dataParams, 'phone_no')}
                        onClickSort={() => {
                          this.historySaleTransList.onClickSort('phone_no desc');
                        }}
                      >
                        {getLanguage('container.saleTransactionHistory.table.header.phoneNo', '')}
                      </Table.HeadColumn>
                      <Table.HeadColumn
                        alignRight
                        maxWidth={VARIABLES.TABLE.INFO_WIDTHS.ORDER_M}
                        minWidth={VARIABLES.TABLE.INFO_WIDTHS.ORDER_M}
                        sortAscending={getSortAscending(dataParams, 'order_m')}
                        onClickSort={() => {
                          this.historySaleTransList.onClickSort('order_m desc');
                        }}
                      >
                        {getLanguage('container.saleTransactionHistory.table.header.order', '')}
                      </Table.HeadColumn>
                      <Table.HeadColumn
                        alignRight
                        maxWidth={VARIABLES.TABLE.INFO_WIDTHS.TB_POINTS_ABBREVIATION}
                        minWidth={VARIABLES.TABLE.INFO_WIDTHS.TB_POINTS_ABBREVIATION}
                        sortAscending={getSortAscending(dataParams, 'points')}
                        onClickSort={() => {
                          this.historySaleTransList.onClickSort('points desc');
                        }}
                        title={getLanguage('container.saleTransactionHistory.table.header.tbPoints', '')}
                      >
                        {getLanguage('container.saleTransactionHistory.table.header.tbPointsAbbreviation', '')}
                      </Table.HeadColumn>
                      <Table.HeadColumn
                        maxWidth={VARIABLES.TABLE.INFO_WIDTHS.SALESPERSON}
                        minWidth={VARIABLES.TABLE.INFO_WIDTHS.SALESPERSON}
                        sortAscending={getSortAscending(dataParams, 'sales_person_name')}
                        onClickSort={() => {
                          this.historySaleTransList.onClickSort('sales_person_name desc');
                        }}
                      >
                        {getLanguage('container.saleTransactionHistory.table.header.salesperson', '')}
                      </Table.HeadColumn>
                      <Table.HeadColumn
                        noSort
                        alignCenter
                        maxWidth={VARIABLES.TABLE.INFO_WIDTHS.ACTION_DELETE}
                        minWidth={VARIABLES.TABLE.INFO_WIDTHS.ACTION_DELETE}
                      >
                        {getLanguage('container.saleTransactionHistory.table.header.action', '')}
                      </Table.HeadColumn>
                    </Table.Row>
                  </Table.Section>
                  <Table.Section>
                    {dataList.map((e, i) => {
                      return (
                        <Table.Row
                          key={i}
                        >
                          <Table.BodyColumn
                            info
                            maxWidth={VARIABLES.TABLE.INFO_WIDTHS.DATE}
                            minWidth={VARIABLES.TABLE.INFO_WIDTHS.DATE}
                          >
                            {e.displayCreateAt}
                          </Table.BodyColumn>
                          <Table.BodyColumn
                            info
                            maxWidth={VARIABLES.TABLE.INFO_WIDTHS.ST_ID}
                            minWidth={VARIABLES.TABLE.INFO_WIDTHS.ST_ID}
                            title={e.name}
                          >
                            {e.name}
                          </Table.BodyColumn>
                          <Table.BodyColumn
                            info
                            minWidth={VARIABLES.TABLE.INFO_WIDTHS.COIL_NAME}
                            title={e.coilName}
                          >
                            {e.coilName}
                          </Table.BodyColumn>
                          <Table.BodyColumn
                            info
                            maxWidth={VARIABLES.TABLE.INFO_WIDTHS.COIL_ID_INFO}
                            minWidth={VARIABLES.TABLE.INFO_WIDTHS.COIL_ID_INFO}
                            title={e.coilId}
                          >
                            {e.coilId}
                          </Table.BodyColumn>
                          <Table.BodyColumn
                            info
                            alignRight
                            maxWidth={VARIABLES.TABLE.INFO_WIDTHS.COLOR}
                            minWidth={VARIABLES.TABLE.INFO_WIDTHS.COLOR}
                            title={e.topPaintColor}
                          >
                            {e.topPaintColor}
                          </Table.BodyColumn>
                          <Table.BodyColumn
                            info
                            maxWidth={VARIABLES.TABLE.INFO_WIDTHS.CUSTOMER_NAME}
                            minWidth={VARIABLES.TABLE.INFO_WIDTHS.CUSTOMER_NAME}
                            title={e.customerName}
                          >
                            {e.customerName}
                          </Table.BodyColumn>
                          <Table.BodyColumn
                            phone
                            maxWidth={VARIABLES.TABLE.INFO_WIDTHS.PHONE}
                            minWidth={VARIABLES.TABLE.INFO_WIDTHS.PHONE}
                            idPhone={e.phoneNoCountry.value}
                            srcFlag={e.phoneNoCountry.flag}
                          >
                            {e.phoneNo}
                          </Table.BodyColumn>
                          <Table.BodyColumn
                            info
                            alignRight
                            maxWidth={VARIABLES.TABLE.INFO_WIDTHS.ORDER_M}
                            minWidth={VARIABLES.TABLE.INFO_WIDTHS.ORDER_M}
                            title={e.orderMWithCommas}
                          >
                            {e.orderMWithCommas}
                          </Table.BodyColumn>
                          <Table.BodyColumn
                            info
                            alignRight
                            maxWidth={VARIABLES.TABLE.INFO_WIDTHS.TB_POINTS_ABBREVIATION}
                            minWidth={VARIABLES.TABLE.INFO_WIDTHS.TB_POINTS_ABBREVIATION}
                            title={e.pointsWithComma}
                          >
                            {e.pointsWithComma}
                          </Table.BodyColumn>
                          <Table.BodyColumn
                            info
                            maxWidth={VARIABLES.TABLE.INFO_WIDTHS.SALESPERSON}
                            minWidth={VARIABLES.TABLE.INFO_WIDTHS.SALESPERSON}
                            title={e.salesPersonName}
                          >
                            {e.salesPersonName}
                          </Table.BodyColumn>
                          <Table.BodyColumn
                            action
                            alignCenter
                            maxWidth={VARIABLES.TABLE.INFO_WIDTHS.ACTION_DELETE}
                            minWidth={VARIABLES.TABLE.INFO_WIDTHS.ACTION_DELETE}
                          >
                            {
                              e.isShowDelete &&
                              <Button
                                red
                                ui='frontIconSmall'
                                name={getLanguage('container.saleTransactionHistory.table.action.buttonDelete', '')}
                                iconWidth='8'
                                iconHeight='10'
                                iconSrc={ICONS['ic-delete.svg']}
                                onClick={() => {
                                  this.historySaleTransList.onClickDelete(e);
                                }}
                              />
                            }
                          </Table.BodyColumn>
                        </Table.Row>
                      )
                    })
                    }
                  </Table.Section>
                </Table>
          }
        </Section>
        <TableAction>
          <TableAction.Left>
            {/* <Grid gutter='15'>
              <Grid.Column>
                <Button
                  blue
                  ui='frontIcon'
                  name='Print'
                  width={VARIABLES.BUTTON.WIDTHS.W_125}
                  iconWidth='18'
                  iconHeight='18'
                  iconSrc={ICONS['ic-printer.svg']}
                  onClick={() => { }}
                />
              </Grid.Column>
              <Grid.Column>
                <Button
                  green
                  ui='frontIcon'
                  name='Export'
                  width={VARIABLES.BUTTON.WIDTHS.W_125}
                  iconWidth='16'
                  iconHeight='16'
                  iconSrc={ICONS['ic-export.svg']}
                  onClick={() => { }}
                />
              </Grid.Column>
            </Grid>
           */}
          </TableAction.Left>
          <TableAction.Right>
            <Grid gutter='20'>
              <Grid.Column>
                <Section paddingTop='6'>
                  <Text
                    regular12
                    color={VARIABLES.COLORS.GRAY_16}
                  >
                    {getLanguage('pagination.page', '')} {activePage}/{Math.ceil(totalData / dataPerPage)}
                  </Text>
                </Section>
              </Grid.Column>
              <Grid.Column>
                <PaginationControl
                  activePage={activePage}
                  itemsCountPerPage={dataPerPage}
                  totalItemsCount={totalData}
                  pageRangeDisplayed={3}
                  onChange={this.pagination.onPaginationChange}
                />
              </Grid.Column>
            </Grid>
          </TableAction.Right>
        </TableAction>
      </ShapeContainer>
    )
  }
}

const mapStateToProps = (state) => {
  const dataRedux = state.dataRedux;
  return {
    dataList: dataRedux.saleTransList,
    dataParams: dataRedux.saleTransParams,
    activePage: dataRedux.saleTransParams.activePage,
    totalData: dataRedux.saleTransParams.totalData || 0,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
  }
}

export const SaleTransactionHistoryListContainerConnected = connect(
  mapStateToProps,
  mapDispatchToProps
)(SaleTransactionHistoryListContainer)