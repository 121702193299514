import React from 'react';
import {
  connect,
} from 'react-redux';
import {
  Section,
  Grid,
  Modal,
  Text,
  Button,
  Field,
  PaginationControl,
  Table,
  Step,
  ShapeContainer,
  Image,
} from './../../../components'
import {
  ICONS,
  SHAREDS,
  VARIABLES,
  TYPOGRAPHYS,
} from './../../../themes'
import { getLanguage } from '../../../helpers/functions/language';
import { GridBase } from '../../../components/base';
import { displayWithComma } from '../../../helpers/functions/display';
import { redirect } from '../../../helpers/functions/route';
import { ROUTE_PATH } from '../../../helpers/constants/route';
import { warrantyController } from '../../../apiService/apiController/warrantyService';
import { getUserAuthRedux } from '../../../helpers/functions/auth';
import { isValidResponse } from '../../../helpers/functions/validation';
import { toast } from '../../../helpers/functions/main';
import { getSaleTransList } from '../../../helpers/services/saleTrans';
import { getWarrantyMultipleParams } from '../../../helpers/services/warranty';

export class ModalCreateMultipleWarrantyContainer extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      dataPerPage: 10,
      isStep1: true,
      isStep1Success: false,
      isGenerateWarranty: false,
      isGenerateWarrantySuccess: false,
      remainingLength: 500,
      totalLength: 500,
      isRemainingLenghtError: false,
      multipleLength: 0,
      isDisableContinue: true,
      isDuringGenerateWarranty: false,
      data: props.data,
    };
    console.log('data', props.data)
  }

  componentDidMount() {
  }

  modalCreateMultipleWarranty = {
    onClickClose: () => {
      const {
        onClickClose,
      } = this.props;
      const {
        isGenerateWarrantySuccess,
        isDuringGenerateWarranty,
      } = this.state;
      if (isGenerateWarrantySuccess) {
        getSaleTransList({
          isNoSort: true
        });
      }
      if (isDuringGenerateWarranty === false) {
        onClickClose();
      }
    },
    onChangeStep: (step) => {
      switch (step) {
        case 1:
          this.setState({
            isStep1: true,
            isStep1Success: false,
          });
          break;
        case 2:
          this.setState({
            isStep1: false,
            isStep1Success: true,
          });
          break;
      }
    },
    onClickGenerate: () => {
      this.setState({
        isGenerateWarranty: true,
        isDuringGenerateWarranty: true,
      }, async () => {
        const {
          data,
          multipleLength,
        } = this.state;
        data.wrtQuantity = multipleLength;
        const params = getWarrantyMultipleParams(data);
        const configService = {
          isShowLoading: false,
        }
        const warrantyService = warrantyController(configService);
        const res = await warrantyService.createWarrantyMulti(params);
        if (isValidResponse(res)) {
          this.setState({
            isGenerateWarrantySuccess: true,
            isDuringGenerateWarranty: false,
          })
        }
        else {
          toast.error(res.message);
          this.setState({
            isGenerateWarranty: false,
            isDuringGenerateWarranty: false,
          })
        }
      })
    },
    goWarrantyPage: () => {
      redirect(`${ROUTE_PATH.WARRANTY.LINK}`);
    }
  }

  input = {
    onChangeInput: (ev) => {
      const name = ev.target.name;
      const value = parseInt(ev.target.value);
      this.setState({
        [name]: value ? value : 0,
      }, () => {
        const {
          multipleLength,
          data,
        } = this.state;
        const remainingLength = data.multipleWarrantyRemainingLength - multipleLength;
        let isRemainingLenghtError = false;
        let isDisableContinue = remainingLength === data.multipleWarrantyRemainingLength;
        if (remainingLength < 0) {
          isRemainingLenghtError = true;
          isDisableContinue = true;
        }
        this.setState({
          remainingLength,
          isRemainingLenghtError,
          isDisableContinue,
        })
      })
    }
  }

  render() {
    const {
      isOpenModal,
    } = this.props;
    const {
      isStep1,
      isStep1Success,
      isGenerateWarranty,
      isGenerateWarrantySuccess,
      isRemainingLenghtError,
      multipleLength,
      remainingLength,
      isDisableContinue,
      data,
    } = this.state;
    return (
      <React.Fragment>
        <Modal
          open={isOpenModal}
          scroll
          // enableOverlayClose={false}
          width={VARIABLES.MODAL.WIDTHS.W_660}
          overlayColor={VARIABLES.COLORS.OVERLAY_BLACK_1}
          spacing={VARIABLES.MODAL.CONTAINER.C_1.SPACING}
          transition={VARIABLES.TRANSITIONS.DEFAULT}
          transformStart={VARIABLES.MODAL.CONTAINER.C_1.TRANSFORM_START}
          transformEnd={VARIABLES.MODAL.CONTAINER.C_1.TRANSFORM_END}
          onClickClose={this.modalCreateMultipleWarranty.onClickClose}
        >
          <Modal.Section
            paddingVertical='30'
            paddingHorizontal='40'
            bgColor={VARIABLES.COLORS.WHITE}
            borderBottomWidth='1'
            borderBottomStyle='solid'
            borderBottomColor={VARIABLES.COLORS.GRAY_8}
            borderTopRightRadius={VARIABLES.MODAL.HEADER.H_1.BORDER_TOP_RIGHT_RADIUS}
            borderTopLeftRadius={VARIABLES.MODAL.HEADER.H_1.BORDER_TOP_LEFT_RADIUS}
          >
            <Text regular16 color={VARIABLES.COLORS.BLACK}>
              {getLanguage('modal.createMultipleWarranty.title', '')}
            </Text>
          </Modal.Section>
          <Modal.Section
            paddingTop='20'
            paddingBottom='30'
            paddingHorizontal='45'
            bgColor={VARIABLES.COLORS.WHITE}
          >
            {isGenerateWarranty ?
              <React.Fragment>
                {isGenerateWarrantySuccess ?
                  <React.Fragment>
                    <Section justify='center' paddingVertical='60'>
                      <Section spacingBottom='25' justify='center'>
                        <Image
                          src={ICONS['ic-success.svg']}
                          widthRatio='72' widthRatioUnit='px'
                          heightRatio='72' heightRatioUnit='px'
                        />
                      </Section>
                      <Section spacingBottom='25' justify='center'>
                        <Text bold18 color={VARIABLES.COLORS.SUCCESS}>
                          {getLanguage('modal.createMultipleWarranty.label.success', '')}
                        </Text>
                      </Section>
                      <ShapeContainer
                        paddingTop='1'
                        paddingBottom='2'
                        paddingHorizontal='10'
                        borderWidth='1' borderStyle='solid' borderColor={VARIABLES.COLORS.SUCCESS} borderRadius='3'
                        onClick={this.modalCreateMultipleWarranty.goWarrantyPage}
                      >
                        <Grid gutter='5' justify='center'>
                          <Grid.Column>
                            <Text regular12 color={VARIABLES.COLORS.SUCCESS}>
                              {getLanguage('modal.createMultipleWarranty.button.goToWarranty', '')}
                            </Text>
                          </Grid.Column>
                          <Grid.Column>
                            <Image
                              src={ICONS['ic-arrow-carousel-next-green.svg']}
                              widthRatio='8' widthRatioUnit='px'
                              heightRatio='8' heightRatioUnit='px'
                            />
                          </Grid.Column>
                        </Grid>
                      </ShapeContainer>
                    </Section>
                  </React.Fragment>
                  :
                  <React.Fragment>
                    <Section justify='center' paddingVertical='111'>
                      <Section spacingBottom='10' justify='center'>
                        <Image
                          src={SHAREDS['loading.svg']}
                          widthRatio='67' widthRatioUnit='px'
                          heightRatio='40' heightRatioUnit='px'
                        />
                      </Section>
                      <Text regular12 color={VARIABLES.COLORS.BLACK}>
                        {getLanguage('modal.createMultipleWarranty.label.waitingForGenerating', '')}
                      </Text>
                    </Section>
                  </React.Fragment>
                }
              </React.Fragment>
              :
              <React.Fragment>
                <Section>
                  <Step
                    label
                    progressPercent={isStep1Success ? '100' : '0'}
                  >
                    <Step.Number
                      active={isStep1 ? true : false}
                      success={isStep1Success ? true : false}
                      number='1'
                      label={getLanguage('modal.createMultipleWarranty.step1.title', '')}
                    />
                    <Step.Number
                      active={isStep1 ? false : true}
                      success={false}
                      number='2'
                      label={getLanguage('modal.createMultipleWarranty.step2.title', '')}
                    />
                  </Step>
                </Section>
                <Section spacingBottom='15'>
                  <ShapeContainer
                    fluid
                    bgColor={VARIABLES.COLORS.PRIMARY_2}
                    borderRadius='10'
                  >
                    <Grid gutter='10' justify='space-between' align='center'>
                      <Grid.Column flex='1'>
                        <Section padding='15'>
                          <Section spacingBottom='5'>
                            <Grid gutter='10'>
                              <Grid.Column>
                                <Text bold20 color={VARIABLES.COLORS.WHITE}>
                                  {getLanguage('modal.createMultipleWarranty.label.invoiceId', '')}
                                </Text>
                              </Grid.Column>
                              <Grid.Column>
                                <Text regular16 color={VARIABLES.COLORS.WHITE} top='4'>
                                  &nbsp;{data.invoiceNo}
                                </Text>
                              </Grid.Column>
                            </Grid>
                          </Section>
                          <Grid gutter='15'>
                            <Grid.Column>
                              <Grid gutter='5'>
                                <Grid.Column>
                                  <Text bold14 color={VARIABLES.COLORS.WHITE}>
                                    {getLanguage('modal.createMultipleWarranty.label.salesOrder', '')}:
                                  </Text>
                                </Grid.Column>
                                <Grid.Column>
                                  <Text regular14 color={VARIABLES.COLORS.WHITE}>
                                    &nbsp;{data.name}
                                  </Text>
                                </Grid.Column>
                              </Grid>
                            </Grid.Column>
                            <Grid.Column>
                              <Grid gutter='5'>
                                <Grid.Column>
                                  <Text bold14 color={VARIABLES.COLORS.WHITE}>
                                    {getLanguage('modal.createMultipleWarranty.label.coilId', '')}:
                                  </Text>
                                </Grid.Column>
                                <Grid.Column>
                                  <Text regular14 color={VARIABLES.COLORS.WHITE}>
                                    &nbsp;{data.coilId}
                                  </Text>
                                </Grid.Column>
                              </Grid>
                            </Grid.Column>
                          </Grid>
                        </Section>
                      </Grid.Column>
                      <Grid.Column flex='none'>
                        <Section height='100' heightUnit='%' padding='5'>
                          <ShapeContainer
                            minWidth='181'
                            height='100'
                            heightUnit='%'
                            paddingVertical='5'
                            paddingRight='8'
                            paddingLeft='5'
                            bgColor={VARIABLES.COLORS.WHITE}
                            borderRadius='7'
                            align='center'
                          >
                            <Section spacingBottom='5' justify='center'>
                              <Text regular12 color={VARIABLES.COLORS.BLACK}>
                                {isStep1 ?
                                  getLanguage('modal.createMultipleWarranty.label.enterLength', '')
                                  :
                                  getLanguage('modal.createMultipleWarranty.label.coilLength', '')
                                }
                              </Text>
                            </Section>
                            <Grid gutter='5' justify='center' align='flex-end'>
                              <Grid.Column>
                                {isStep1 ?
                                  <Field
                                    ui='blockLabelVerticalNumberLarge'
                                    width='135'
                                    textAlign='center'
                                    error={isRemainingLenghtError}
                                    name="multipleLength"
                                    value={multipleLength}
                                    onChange={(ev) => {
                                      this.input.onChangeInput(ev);
                                    }}
                                  />
                                  :
                                  <Section height='42' paddingTop='5'>
                                    <Text bold28 color={VARIABLES.COLORS.PRIMARY_2}>
                                      {displayWithComma(multipleLength)}
                                    </Text>
                                  </Section>
                                }
                              </Grid.Column>
                              {isStep1 &&
                                <React.Fragment>
                                  <Grid.Column>
                                    <Text bold14 color={VARIABLES.COLORS.BLACK} top='-6'>/</Text>
                                  </Grid.Column>
                                  <Grid.Column>
                                    <Text bold14 color={VARIABLES.COLORS.BLACK} top='-6'>{data.multipleWarrantyRemainingLengthWithComma}</Text>
                                  </Grid.Column>
                                </React.Fragment>
                              }
                            </Grid>
                          </ShapeContainer>
                        </Section>
                      </Grid.Column>
                    </Grid>
                  </ShapeContainer>
                </Section>
                <Section minHeight='42' justify='center' align='center'>
                  {isStep1 ?
                    <React.Fragment>
                      {isRemainingLenghtError &&
                        <Grid gutter='10' justify='center' align='center'>
                          <Grid.Column>
                            <Image
                              src={ICONS['ic-info-error.svg']}
                              widthRatio='16' widthRatioUnit='px'
                              heightRatio='16' heightRatioUnit='px'
                              top='-1'
                            />
                          </Grid.Column>
                          <Grid.Column>
                            <Text regular14 color={VARIABLES.COLORS.ERROR}>
                              {getLanguage('modal.createMultipleWarranty.label.errorLengthMessage', '')}
                            </Text>
                          </Grid.Column>
                        </Grid>
                      }
                    </React.Fragment>
                    :
                    <React.Fragment>
                      <Section spacingBottom='5' justify='center'>
                        <Text regular14 color={VARIABLES.COLORS.BLACK}>
                          {getLanguage('modal.createMultipleWarranty.step2.remainingCoilLength', '')} = &nbsp;
                          <Text bold14 color={VARIABLES.COLORS.PRIMARY_2}>{displayWithComma(remainingLength)}</Text> m.
                        </Text>
                      </Section>
                      <Grid gutter='10' justify='center' align='center'>
                        <Grid.Column>
                          <Image
                            src={ICONS['ic-notification-info.svg']}
                            widthRatio='16' widthRatioUnit='px'
                            heightRatio='16' heightRatioUnit='px'
                            top='-1'
                          />
                        </Grid.Column>
                        <Grid.Column>
                          <Text regular14 color={VARIABLES.COLORS.PRIMARY_2}>
                            {getLanguage('modal.createMultipleWarranty.step2.description', '')}
                          </Text>
                        </Grid.Column>
                      </Grid>
                    </React.Fragment>
                  }
                </Section>
                {/* {isStep1 &&
                  <Section spacingBottom='15'>
                    <Grid
                      gutter='10'
                      justify='center'
                    >
                      <Grid.Column flex='1'>
                        <Field
                          border
                          fluid
                          name='filter'
                          ui='blockLabelVerticalSearch'
                          placeholder={getLanguage('modal.createMultipleWarranty.placeholder', '')}
                          // value={}
                          onChange={() => { }}
                        />
                      </Grid.Column>
                      <Grid.Column flex='none'>
                        <Button
                          lightBlue
                          name={getLanguage('modal.createMultipleWarranty.button.search', '')}
                          width={VARIABLES.BUTTON.WIDTHS.W_105}
                          onClick={() => { }}
                        />
                      </Grid.Column>
                    </Grid>
                  </Section>
                }
                <Section spacingBottom='30'>
                  <Table striped action>
                    <Table.Section>
                      <Table.Row>
                        <Table.HeadColumn
                          maxWidth={VARIABLES.TABLE.INFO_WIDTHS.ID}
                          minWidth={VARIABLES.TABLE.INFO_WIDTHS.ID}
                          sortAscending={() => { }}
                          onClickSort={() => { }}
                        >
                          {getLanguage('modal.createMultipleWarranty.table.header.id', '')}
                        </Table.HeadColumn>
                        <Table.HeadColumn
                          minWidth={VARIABLES.TABLE.INFO_WIDTHS.NAME}
                          sortAscending={() => { }}
                          onClickSort={() => { }}
                        >
                          {getLanguage('modal.createMultipleWarranty.table.header.contractorName', '')}
                        </Table.HeadColumn>
                        <Table.HeadColumn
                          alignCenter
                          noSort
                          maxWidth={VARIABLES.TABLE.INFO_WIDTHS.ACTION_EDIT_LENGTH}
                          minWidth={VARIABLES.TABLE.INFO_WIDTHS.ACTION_EDIT_LENGTH}
                        >
                          {getLanguage('modal.createMultipleWarranty.table.header.coliLength', '')}
                        </Table.HeadColumn>
                      </Table.Row>
                    </Table.Section>
                    <Table.Section>
                      <Table.Row>
                        <Table.BodyColumn
                          info
                          maxWidth={VARIABLES.TABLE.INFO_WIDTHS.ID}
                          minWidth={VARIABLES.TABLE.INFO_WIDTHS.ID}
                        >
                          1
                        </Table.BodyColumn>
                        <Table.BodyColumn
                          info
                          minWidth={VARIABLES.TABLE.INFO_WIDTHS.NAME}
                          title={'Mr. BlueScope'}
                        >
                          Mr. BlueScope
                        </Table.BodyColumn>
                        <Table.BodyColumn
                          action
                          alignCenter
                          maxWidth={VARIABLES.TABLE.INFO_WIDTHS.ACTION_EDIT_LENGTH}
                          minWidth={VARIABLES.TABLE.INFO_WIDTHS.ACTION_EDIT_LENGTH}
                        >
                          {isStep1 ?
                            <Field
                              ui='order'
                              error={isRemainingLenghtError}
                              name='multipleLength'
                              value={multipleLength}
                              onChange={(ev) => {
                                this.input.onChangeInput(ev);
                              }}
                            />
                            :
                            <Text regular12 color={VARIABLES.COLORS.GRAY_19}>{displayWithComma(multipleLength)}</Text>
                          }
                        </Table.BodyColumn>
                      </Table.Row>
                      <Table.Row>
                        <Table.BodyColumn
                          info
                          maxWidth={VARIABLES.TABLE.INFO_WIDTHS.ID}
                          minWidth={VARIABLES.TABLE.INFO_WIDTHS.ID}
                        >
                          2
                        </Table.BodyColumn>
                        <Table.BodyColumn
                          info
                          minWidth={VARIABLES.TABLE.INFO_WIDTHS.NAME}
                          title={'Mr. TrueBlue'}
                        >
                          Mr. TrueBlue
                        </Table.BodyColumn>
                        <Table.BodyColumn
                          action
                          alignCenter
                          maxWidth={VARIABLES.TABLE.INFO_WIDTHS.ACTION_EDIT_LENGTH}
                          minWidth={VARIABLES.TABLE.INFO_WIDTHS.ACTION_EDIT_LENGTH}
                        >
                          {isStep1 ?
                            <Field
                              ui='order'
                              error={isRemainingLenghtError}
                              name="value2"
                              value={value2}
                              onChange={(ev) => {
                                this.input.onChangeInput(ev);
                              }}
                            />
                            :
                            <Text regular12 color={VARIABLES.COLORS.GRAY_19}>{displayWithComma(value2)}</Text>
                          }
                        </Table.BodyColumn>
                      </Table.Row>
                    </Table.Section>
                  </Table>
                </Section>
                {isStep1 &&
                  <Section spacingBottom='10' justify='flex-end'>
                    <PaginationControl
                      activePage={1}
                      itemsCountPerPage={10}
                      totalItemsCount={100}
                      pageRangeDisplayed={1} // 3
                      onChange={() => { }}
                    />
                  </Section>
                } */}
              </React.Fragment>
            }
          </Modal.Section>
          <Modal.Section
            paddingVertical='30'
            paddingHorizontal='45'
            borderTopWidth={VARIABLES.MODAL.FOOTER.F_1.BORDER_TOP_WIDTH}
            borderTopStyle={VARIABLES.MODAL.FOOTER.F_1.BORDER_TOP_STYLE}
            borderTopColor={VARIABLES.COLORS.GRAY_8}
            borderBottomRightRadius={VARIABLES.MODAL.FOOTER.F_1.BORDER_BOTTOM_RIGHT_RADIUS}
            borderBottomLeftRadius={VARIABLES.MODAL.FOOTER.F_1.BORDER_BOTTOM_LEFT_RADIUS}
          >
            <Grid gutter='15' justify='flex-end'>
              {isGenerateWarranty ?
                <React.Fragment>
                  <Grid.Column>
                    <Button
                      gray
                      disabled={!isGenerateWarrantySuccess}
                      width={VARIABLES.BUTTON.WIDTHS.W_155}
                      name={getLanguage('modal.createMultipleWarranty.button.close', '')}
                      onClick={this.modalCreateMultipleWarranty.onClickClose}
                    />
                  </Grid.Column>
                </React.Fragment>
                :
                <React.Fragment>
                  {isStep1 ?
                    <React.Fragment>
                      {/* Button for Step 1 */}
                      <Grid.Column>
                        <Button
                          gray
                          width={VARIABLES.BUTTON.WIDTHS.W_155}
                          name={getLanguage('modal.createMultipleWarranty.button.close', '')}
                          onClick={this.modalCreateMultipleWarranty.onClickClose}
                        />
                      </Grid.Column>
                      <Grid.Column>
                        <Button
                          blue
                          disabled={isDisableContinue}
                          width={VARIABLES.BUTTON.WIDTHS.W_155}
                          name={getLanguage('modal.createMultipleWarranty.button.continue', '')}
                          onClick={() => {
                            this.modalCreateMultipleWarranty.onChangeStep(2)
                          }}
                        />
                      </Grid.Column>
                    </React.Fragment>
                    :
                    <React.Fragment>
                      {/* Button for Step 2 */}
                      <Grid.Column>
                        <Button
                          ui='back'
                          gray
                          width={VARIABLES.BUTTON.WIDTHS.W_155}
                          name={getLanguage('modal.createMultipleWarranty.button.back', '')}
                          onClick={() => {
                            this.modalCreateMultipleWarranty.onChangeStep(1)
                          }}
                        />
                      </Grid.Column>
                      <Grid.Column>
                        <Button
                          blue
                          width={VARIABLES.BUTTON.WIDTHS.W_155}
                          name={getLanguage('modal.createMultipleWarranty.button.generateWarranty', '')}
                          onClick={this.modalCreateMultipleWarranty.onClickGenerate}
                        />
                      </Grid.Column>
                    </React.Fragment>
                  }
                </React.Fragment>
              }
            </Grid>
          </Modal.Section>
        </Modal>
      </React.Fragment>
    )
  }
}
