import React from 'react'
import ClassNames from 'classnames'
// import PropTypes from 'prop-types'
import {
  NotificationBase
} from './../../base'
import {
  Grid,
  Text,
  Image
} from './../../../components'
// import {
//   default as VARIABLES
// } from './../../../themes/styles/bases/variables'
// import {
//   default as TYPOGRAPHYS
// } from './../../../themes/styles/bases/typographys'
// import {
//   default as UTILITIES
// } from './../../../themes/styles/helpers/utilities'
import {
  VARIABLES,
  ICONS
} from './../../../themes'

/**
 * Notification description:
 * - Notification
 */

export class Notification extends React.PureComponent {
  render () {
    const {
      className,
      children,
      ui,
      message
    } = this.props

    // props for css classes
    const classes = ClassNames(
      className,
      // { 'is-modifier-name': modifierName }
    )

    return (
      <React.Fragment>
        {(() => {
          switch (ui) {
            case 'success':
              return (
                <NotificationBase
                  {...this.props}
                  className={ClassNames(
                    className,
                    'is-success',
                  )}
                  paddingVertical='13'
                  spacingBottom='13'
                  spacingHorizontal='30'
                  bgColor={VARIABLES.COLORS.OVERLAY_GREEN_1}
                  borderWidth='1'
                  borderStyle='solid'
                  borderColor={VARIABLES.COLORS.GREEN_3}
                  borderRadius='3'
                >
                  <Grid
                    gutter='15'
                    justify='center'
                  >
                    <Grid.Column flex='none'>
                      <Image
                        widthRatio='18'
                        widthRatioUnit='px'
                        heightRatio='18'
                        heightRatioUnit='px'
                        src={ICONS['ic-notification-success.svg']}
                      />
                    </Grid.Column>
                    <Grid.Column flex='1'>
                      <Text
                        bold12
                        color={VARIABLES.COLORS.GREEN_5}
                      >
                        {message}
                      </Text>
                    </Grid.Column>
                  </Grid>
                </NotificationBase>
              )
            case 'error':
              return (
                <NotificationBase
                  {...this.props}
                  className={ClassNames(
                    className,
                    'is-error',
                  )}
                  paddingVertical='13'
                  spacingBottom='13'
                  spacingHorizontal='30'
                  bgColor={VARIABLES.COLORS.OVERLAY_RED_1}
                  borderWidth='1'
                  borderStyle='solid'
                  borderColor={VARIABLES.COLORS.RED_4}
                  borderRadius='3'
                >
                  <Grid
                    gutter='15'
                    justify='center'
                  >
                    <Grid.Column flex='none'>
                      <Image
                        widthRatio='18'
                        widthRatioUnit='px'
                        heightRatio='18'
                        heightRatioUnit='px'
                        src={ICONS['ic-notification-error.svg']}
                      />
                    </Grid.Column>
                    <Grid.Column flex='1'>
                      <Text
                        bold12
                        color={VARIABLES.COLORS.RED_4}
                      >
                        {message}
                      </Text>
                    </Grid.Column>
                  </Grid>
                </NotificationBase>
              )
            case 'info':
              return (
                <NotificationBase
                  {...this.props}
                  className={ClassNames(
                    className,
                    'is-info',
                  )}
                  paddingVertical='13'
                  spacingBottom='13'
                  spacingHorizontal='30'
                  bgColor={VARIABLES.COLORS.OVERLAY_BLUE_3}
                  borderWidth='1'
                  borderStyle='solid'
                  borderColor={VARIABLES.COLORS.BLUE_21}
                  borderRadius='3'
                >
                  <Grid
                    gutter='15'
                    justify='center'
                  >
                    <Grid.Column flex='none'>
                      <Image
                        widthRatio='18'
                        widthRatioUnit='px'
                        heightRatio='18'
                        heightRatioUnit='px'
                        src={ICONS['ic-notification-info.svg']}
                      />
                    </Grid.Column>
                    <Grid.Column flex='1'>
                      <Text
                        bold12
                        color={VARIABLES.COLORS.BLUE_21}
                      >
                        {message}
                      </Text>
                    </Grid.Column>
                  </Grid>
                </NotificationBase>
              )
            default:
              return (
                <NotificationBase
                  {...this.props}
                  className={classes}
                >
                  {children}
                </NotificationBase>
              )
          }
        })()}
      </React.Fragment>
    )
  }

  static defaultProps = {
    // * If you set new default props, you should create propsType for correct styleguide document.
  }

  static propTypes = {
    // TYPE > node, string, number, bool, array, object, symbol, func
    /**
    * props_name_oneType
    */
    // props_name_onetype: PropTypes.string,

    /**
    * props_name_oneMoreType
    */
    // props_name_oneMoreType: PropTypes.oneOfType([
    //   PropTypes.node,
    //   PropTypes.string
    // ])
  }
}
