import {
  injectGlobal
} from 'styled-components'
import {
  default as VARIABLES
} from './../../../themes/styles/bases/variables'
// import {
//   default as TYPOGRAPHYS
// } from './../../../themes/styles/bases/typographys'
// import {
//   default as MIXINS
// } from './../../../themes/styles/helpers/mixins'

// Sample using image in style
// url(${require('./../../../themes/images/icons/icon-sample.svg')});

injectGlobal`
  .table-base {
    /* Parent
    ------------------------------- */
    /* Childrens
    ------------------------------- */
    .table-row-base {
      border-radius: 3px;
    }

    .table-column-base {
      .table-sort-button-base {
        position: relative;
        right: auto;
        margin-left: 10px;
      }

      &.table-head-column {
        /* align-items: center; */
        align-items: flex-end;
      }

      &.table-body-column {
        &:first-child {
          border-top-left-radius: 3px;
          border-bottom-left-radius: 3px;
        }

        &:last-child {
          border-top-right-radius: 3px;
          border-bottom-right-radius: 3px;
        }
      }

      &.is-no-sort {
        .table-sort-button-base {
          display: none;
        }
      }

      &.is-selected {
        background-color: ${VARIABLES.COLORS.BLUE_14};
      }
    }

    .table-section-base {
      &.is-gradient-blue {
        margin-top: 10px;
        margin-bottom: 15px;
        background: rgb(199,229,248);
        background: -moz-linear-gradient(180deg, rgba(199,229,248,1) 0%, rgba(245,250,254,1) 100%);
        background: -webkit-linear-gradient(180deg, rgba(199,229,248,1) 0%, rgba(245,250,254,1) 100%);
        background: linear-gradient(180deg, rgba(199,229,248,1) 0%, rgba(245,250,254,1) 100%);
        border-radius: 3px;

        .table-column-base {
          border-bottom: 1px solid ${VARIABLES.COLORS.WHITE};
          border-bottom-right-radius: 0;
          border-bottom-left-radius: 0;
        }
      }

      &.is-blue {
        background-color: ${VARIABLES.COLORS.BLUE_14};
        border-radius: 5px;

        .table-column-base {
          border-bottom-right-radius: 0;
          border-bottom-left-radius: 0;
        }
      }

      &.is-warranty-coil-list {
        flex: 1;
        padding-bottom: 5px; /* 30 */
        padding-left: ${VARIABLES.TABLE.INFO_WIDTHS.CHECKBOX_SQUARE + `px`};
        border-top: 1px solid ${VARIABLES.COLORS.GRAY_8};
        border-bottom: 1px solid ${VARIABLES.COLORS.PRIMARY_2};

        .table-head-column {
          &:last-child {
            justify-content: flex-end;
            padding-right: 20px;
          }
        }

        .table-body-column {
          &:last-child {
            padding-right: 8px;

            .grid-container-base {
              justify-content: flex-end;
            }
          }
        }
      }

      &.is-warranty-coil-detail {
        width: 100%;
        margin-top: -5px;
      }

      &.is-scroll {
        overflow: auto;
        max-height: 355px;
      }
    }

    .table-row-base {
      &.table-row-collapsible {
        flex-wrap: wrap;

        > .table-row-base {
          width: 100%;
        }
      }
    }

    .table-info {
      &.is-phone {
        position: relative;
        top: -2px;
      }
    }

    .table-action {
      &.is-button {
        position: relative;
        top: -2px;
      }
    }

    /* Modifiers
    ------------------------------- */
    &.is-fluid {
      width: 100%;
    }

    &.is-striped {
      .table-section-base {
        &:first-child {
          margin-bottom: 5px;
        }
      }

      .table-row-base {
        &:nth-child(even) {
          .table-body-column {
            background-color: ${VARIABLES.COLORS.GRAY_1};

            &.is-selected {
              background-color: ${VARIABLES.COLORS.BLUE_14};
            }
          }

          .table-section {
            &.is-warranty-coil-list {
              .table-row-base {
                &:nth-child(odd) {
                  .table-body-column {
                    background-color: ${VARIABLES.COLORS.WHITE};
                  }
                }

                &:nth-child(even) {
                  .table-body-column {
                    background-color: ${VARIABLES.COLORS.GRAY_1};
                  }
                }
              }
            }
          }
        }
      }

      .table-section {
        &.is-warranty-coil-list {
          .table-row-base {
            &:nth-child(even) {
              .table-section {
                &.is-warranty-coil-detail {
                  background-color: ${VARIABLES.COLORS.GRAY_1};
                }
              }
            }
          }
        }
      }
    }

    &.is-stacked {
      .table-section-base {
        &:first-child {
          margin-bottom: 5px;
        }
      }
      .table-row-base {
        @media (max-width: 530px) {
          display: block;
          border-bottom: 1px solid ${VARIABLES.COLORS.GRAY_19};
          .table-head-column{
            display: none;
            border: none;
          }
        
          &:last-child {
            border-bottom: none;
          }
          &:before {
            display: block;
          }
          .table-body-column{
            &:before {
            display: block;
          }
            &:nth-child(1) {
            width: 100%;
            max-width: none;
            &:before {
              content: 'ID :';
              font-family: ${VARIABLES.FONT.FAMILIES.FIRST.WEIGHTS.REGULAR};
              font-weight: normal;
              font-size: 12px;
              line-height: 1.1;
              color: ${VARIABLES.COLORS.BLACK};
              padding-right: 15px;
            }
          }

          &:nth-child(2) {
            width: 100%;
            max-width: none;
            &:before {
              content: 'AD/RF : ';
              font-family: ${VARIABLES.FONT.FAMILIES.FIRST.WEIGHTS.REGULAR};
              font-weight: normal;
              font-size: 12px;
              line-height: 1.1;
              color: ${VARIABLES.COLORS.BLACK};
              padding-right: 15px;
            }
          }

          &:nth-child(3) {
            width: 100%;
            max-width: none;
            justify-content: flex-start;
            &:before {
              content: 'Select: ';
              font-family: ${VARIABLES.FONT.FAMILIES.FIRST.WEIGHTS.REGULAR};
              font-weight: normal;
              font-size: 12px;
              line-height: 1.1;
              color: ${VARIABLES.COLORS.BLACK};
              padding-right: 15px;
            }
          }
          }
          
        }
        
        @media (min-width: 481px){
          &:nth-child(even) {
          .table-body-column {
            background-color: ${VARIABLES.COLORS.GRAY_1};

            &.is-selected {
              background-color: ${VARIABLES.COLORS.BLUE_14};
            }
          }

          .table-section {
            &.is-warranty-coil-list {
              .table-row-base {
                &:nth-child(odd) {
                  .table-body-column {
                    background-color: ${VARIABLES.COLORS.WHITE};
                  }
                }

                &:nth-child(even) {
                  .table-body-column {
                    background-color: ${VARIABLES.COLORS.GRAY_1};
                  }
                }
              }
            }
          }
          }
        }
      }
    }

    &.is-action {
      .table-body-column {
        > .text-base {
          top: -1px;
        }
      }
    }

    &.is-action-checkbox,
    &.is-action-checkbox-warranty {
      .table-body-column {
        > .text-base {
          top: -2px;
        }
      }
    }

    &.is-action,
    &.is-action-checkbox {
      .table-body-column {
        padding-top: 7px;
        padding-bottom: 5px;
      }
    }

    &.is-action-checkbox-warranty {
      .table-body-column {
        padding-top: 9px;
        padding-bottom: 3px;

        &:first-child {
          padding-top: 7px;
          padding-bottom: 5px;
        }
      }
    }

    &.is-action,
    &.is-action-checkbox-warranty {
      .table-body-column {
        &:last-child {
          padding-top: 5px;
          padding-bottom: 5px;
        }
      }
    }

    &.is-action-checkbox {
      .table-section-base {
        &:first-child {
          margin-bottom: 5px;
        }
      }

      .table-row-base {
        margin-bottom: 1px;
      }

      .table-body-column {
        &:last-child {
          padding-top: 5px;
          padding-bottom: 5px;
        }
      }
    }

    &.is-action {
      .table-column-base {
        &.is-button {
          padding-top: 5px;
        }
      }
    }

    &.is-field-text,
    &.is-list-of-rewards,
    &.is-coil-transaction {
      .table-section-base {
        &:first-child {
          margin-bottom: 5px;
        }
      }

      .table-body-column {
        padding-top: 7px;
        padding-bottom: 4px;
      }
    }

    &.is-field-text {
      .table-body-column {
        &:last-child {
          padding-top: 5px;
          padding-bottom: 5px;
        }
      }
    }

    &.is-coil-transaction {
      .table-body-column {
        &:nth-last-child(2),
        &:last-child {
          padding-top: 5px;
          padding-bottom: 5px;
        }
      }
    }

    &.is-list-of-rewards {
      .table-body-column {
        &:nth-last-child(2) {
          /* padding-top: 6px;
          padding-bottom: 6px; */
          padding-top: 4px;
          padding-bottom: 0;
        }
      }
    }

    &.is-product-quantity {
      .table-body-column {
        padding-top: 7px;
        padding-bottom: 6px;

        &:first-child {
          padding-top: 5px;
          padding-bottom: 4px;
        }
      }
    }

    &.is-warranty,
    &.is-warranty-malaysia,
    &.is-warranty-vietnam,
    &.is-warranty-vietnam-comparison {
      /* max-width: 1120px; */
      /* margin: auto; */
      /* border: 1px solid ${VARIABLES.COLORS.BLACK}; */
      border-bottom: none;

      .table-column-base {
        border-bottom: 1px solid ${VARIABLES.COLORS.BLACK};
        border-left: 1px solid ${VARIABLES.COLORS.BLACK};

        &:first-child {
          border-left: none;
        }

        &:last-child {
          border-right: 1px solid ${VARIABLES.COLORS.BLACK};
        }

        &.is-border-bottom-none {
          border-bottom: none;
        }

        &.is-head-column {
          border-top: 1px solid ${VARIABLES.COLORS.BLACK};
        }

        &.is-head-column,
        &.is-body-column {
          &:first-child {
            border-left: 1px solid ${VARIABLES.COLORS.BLACK};
          }
        }
      }
    }

    &.is-warranty-vietnam {
      border-bottom: none;

      .table-row-base {
        &:first-child {
          .table-column-base {
            border-top: 1px solid ${VARIABLES.COLORS.WHITE};
          }
        }
      }

      .table-column-base {
        border-bottom: 1px solid ${VARIABLES.COLORS.WHITE};
        border-left: 1px solid ${VARIABLES.COLORS.WHITE};

        &:first-child {
          background-color: ${VARIABLES.COLORS.BLUE_27};
        }

        &:not(:first-child) {
          background-color: ${VARIABLES.COLORS.BLUE_28};
        }

        &:last-child {
          border-right: 1px solid ${VARIABLES.COLORS.WHITE};
        }

        &.is-body-column {
          &:first-child {
            border-left: 1px solid ${VARIABLES.COLORS.WHITE};
          }
        }
      }
    }

    &.is-warranty-vietnam-orange-theme {
      border-bottom: none;

      .table-row-base {
        &:first-child {
          .table-column-base {
            border-top: 1px solid ${VARIABLES.COLORS.WHITE};
          }
        }
      }

      .table-column-base {
        border-bottom: 1px solid ${VARIABLES.COLORS.WHITE};
        border-left: 1px solid ${VARIABLES.COLORS.WHITE};

        &:first-child {
          background-color: ${VARIABLES.COLORS.ORANGE_6};
        }

        &:not(:first-child) {
          background-color: ${VARIABLES.COLORS.ORANGE_7};
        }

        &:last-child {
          border-right: 1px solid ${VARIABLES.COLORS.WHITE};
        }

        &.is-body-column {
          &:first-child {
            border-left: 1px solid ${VARIABLES.COLORS.WHITE};
          }
        }
      }
    }

    &.is-warranty-vietnam-comparison {
      border-bottom: none;

      .table-column-base {
        border-bottom: 1px solid ${VARIABLES.COLORS.BLUE_29};
        border-left: 1px solid ${VARIABLES.COLORS.BLUE_29};

        &:last-child {
          border-right: 1px solid ${VARIABLES.COLORS.BLUE_29};
        }

        &.is-head-column,
        &.is-body-column {
          &:first-child {
            border-left: 1px solid ${VARIABLES.COLORS.BLUE_29};
          }
        }

        &.is-head-column {
          background-color: ${VARIABLES.COLORS.BLUE_27};
          border-top: 1px solid ${VARIABLES.COLORS.BLUE_29};
        }
      }

      .table-row-base {
        &:nth-child(odd) {
          .table-column-base {
            &.is-body-column {
              background-color: ${VARIABLES.COLORS.BLUE_30};
            }
          }
        }
      }
    }

    &.is-warranty-vietnam-comparison-orange-theme {
      border-bottom: none;

      .table-column-base {
        border-bottom: 1px solid ${VARIABLES.COLORS.ORANGE_5};
        border-left: 1px solid ${VARIABLES.COLORS.ORANGE_5};

        &:last-child {
          border-right: 1px solid ${VARIABLES.COLORS.ORANGE_5};
        }

        &.is-head-column,
        &.is-body-column {
          &:first-child {
            border-left: 1px solid ${VARIABLES.COLORS.ORANGE_5};
          }
        }

        &.is-head-column {
          background-color: ${VARIABLES.COLORS.ORANGE_3};
          border-top: 1px solid ${VARIABLES.COLORS.ORANGE_5};
        }
      }

      .table-row-base {
        &:nth-child(odd) {
          .table-column-base {
            &.is-body-column {
              background-color: ${VARIABLES.COLORS.ORANGE_4};
            }
          }
        }
      }
    }

    &.is-warranty-malaysia {
      .table-section-base {
        border-bottom: 1px solid ${VARIABLES.COLORS.BLACK};

        &:first-child {
          border-bottom: none;
        }
      }

      .table-column-base {
        &.is-body-column {
          border-bottom: none;
        }
      }
    }

    &.is-link-pre-transaction {
      .table-row-base {
        .table-column-base {
          &.is-body-column {
            align-items: center;
          }
        }

        &:nth-last-child(1),
        &:nth-last-child(2),
        &:nth-last-child(3), 
        &:nth-last-child(4) {
          .table-column-base {
            &.is-body-column {
              .react-select__menu {
                top: auto;
                bottom: 100%;
              }
            }
          }
        }
      }
    }

    /* Media queries
    ------------------------------- */
  }
`
