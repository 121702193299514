import apiService from '../apiService'

const apiPath = '/customer'

export const customerController = (configService = {}) => {
  const service = apiService(configService)
  return {
    getListCustomer: (params) => {
      return service.get(`${apiPath}`, params)
    },
    createCustomer: (params) => {
      return service.post(`${apiPath}/create`, params)
    },
    updateCustomerInfo: (params) => {
      return service.put(`${apiPath}/${params.id}`, params)
    },
    deleteCustomerById: (params) => {
      return service.delete(`${apiPath}/${params.id}`, params)
    },
    searchCustomerWithPagination: (params) => {
      return service.post(`${apiPath}/search`, params)
    },
  }
}
