import React from 'react';
import {
  connect,
} from 'react-redux';
import {
  ShapeContainer,
  Section,
  Grid,
  Field,
  Text,
  Button,
  TableAction,
  PaginationControl,
  Table,
  Image,
} from './../../components'
import {
  ICONS,
  VARIABLES,
} from './../../themes'
import { ROUTE_PATH } from '../../helpers/constants/route';
import { getRouteParams, redirect, redirectData } from '../../helpers/functions/route';
import { getClearDocumentaryDetailParams, getDocumentaryDetailList } from '../../helpers/services/documentary';
import { DATA_REDUX_STATE_KEY, dataCreators } from '../../actions/dataAction';
import { bindActionCreators } from 'redux';
import { storeGetState, storeDispatch } from '../../stores';
import { ENUM } from '../../helpers/constants/enum';
import { getSortAscending, getTodayDateDiff } from '../../helpers/functions/data';
import { isShowLoading, toast } from '../../helpers/functions/main';
import { getLanguage } from '../../helpers/functions/language';
import _ from 'lodash';
import moment from 'moment';
import { DATE_FORMAT, DATE_MOMENT_FORMAT, DATA } from '../../helpers/constants/data';
import { getUserAuthRedux } from '../../helpers/functions/auth';
import { getWarrantyTemplateForm, getWarrantyTemplateImagePublicPath } from '../../helpers/functions/warranty';

class DocumentaryDetailListContainer extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      activePage: 1,
      dataPerPage: 10,
    }
  }

  componentDidMount() {
    const {
      dataId
    } = this.props;
    const {
      activePage,
      dataPerPage,
    } = this.state;
    const params = {
      ...getClearDocumentaryDetailParams(),
      page: activePage,
      limit: dataPerPage,
      sales_tran_id: dataId,
    };
    getDocumentaryDetailList(params);
  }

  documentaryDetailList = {
    onClickSort: (target) => {
      getDocumentaryDetailList({
        sort: target
      });
    },
  }

  pagination = {
    onPaginationChange: (pageNumber) => {
      getDocumentaryDetailList({
        page: pageNumber,
      });
    }
  }

  render() {
    const {
      dataList,
      // dataGroupList,
      totalData,
      activePage,
      dataParams,
      dataOptionParams,
      onClickView,
      goBack,
      viewGoBack,
    } = this.props;
    const {
      dataPerPage,
    } = this.state;
    // console.log('dataGroupList', dataGroupList)
    return (
      <ShapeContainer
        ui='content'
        justify='space-between'
      >
        <Section spacingBottom='45'>
          <Table
            striped
            action
          >
            <Table.Section>
              <Table.Row>
                <Table.HeadColumn
                  minWidth={VARIABLES.TABLE.DOCUMENTARY_MANAGE.COLUMN_WIDTHS.DOCUMENT}
                  sortAscending={getSortAscending(dataParams, 'doc_ms_code')}
                  onClickSort={() => {
                    this.documentaryDetailList.onClickSort('doc_ms_code desc');
                  }}
                >
                  {getLanguage('documentaryManage.table.header.document', '')}
                </Table.HeadColumn>
                <Table.HeadColumn
                  minWidth={VARIABLES.TABLE.DOCUMENTARY_MANAGE.COLUMN_WIDTHS.SPECIFY_COMPANY_NAME}
                  sortAscending={getSortAscending(dataParams, 'doc_sp_comp_name')}
                  onClickSort={() => {
                    this.documentaryDetailList.onClickSort('doc_sp_comp_name desc');
                  }}
                >
                  {getLanguage('documentaryManage.table.header.specifyCompanyName', '')}
                </Table.HeadColumn>
                <Table.HeadColumn
                  maxWidth={VARIABLES.TABLE.DOCUMENTARY_MANAGE.COLUMN_WIDTHS.CREATE_DATE}
                  minWidth={VARIABLES.TABLE.DOCUMENTARY_MANAGE.COLUMN_WIDTHS.CREATE_DATE}
                  sortAscending={getSortAscending(dataParams, 'created_date')}
                  onClickSort={() => {
                    this.documentaryDetailList.onClickSort('created_date desc');
                  }}
                >
                  {getLanguage('documentaryManage.table.header.createDate', '')}
                </Table.HeadColumn>
                <Table.HeadColumn
                  noSort
                  alignCenter
                  maxWidth={VARIABLES.TABLE.DOCUMENTARY_MANAGE.COLUMN_WIDTHS.ACTION}
                  minWidth={VARIABLES.TABLE.DOCUMENTARY_MANAGE.COLUMN_WIDTHS.ACTION}
                >
                  {getLanguage('documentaryManage.table.header.action', '')}
                </Table.HeadColumn>
              </Table.Row>
            </Table.Section>
            {dataList.length ?
              <Table.Section>
                {dataList.map((e, i) => {
                  return (
                    <Table.Row key={i}>
                      <Table.BodyColumn
                        action
                        minWidth={VARIABLES.TABLE.DOCUMENTARY_MANAGE.COLUMN_WIDTHS.DOCUMENT}
                      >
                        <Section relative top='-1'>
                          <Section>
                            <Grid gutter='5' align='center'>
                              <Grid.Column>
                                <Section relative top='-1'>
                                  <img src={ICONS['ic-document-outline-blue.svg']} alt='Icon' />
                                </Section>
                              </Grid.Column>
                              <Grid.Column>
                                <Section width='80'>
                                  <Text bold12 color={VARIABLES.COLORS.GRAY_19}>{e.docMsCode}</Text>
                                </Section>
                              </Grid.Column>
                              <Grid.Column>
                                <Text regular12 color={VARIABLES.COLORS.GRAY_19}>{e.docMsCate} : {e.docMsName}</Text>
                              </Grid.Column>
                            </Grid>
                          </Section>
                          {/* {e.documentaryList.map((ed, id) => {
                            return (
                              <Section key={id}>
                                <Grid gutter='5' align='center'>
                                  <Grid.Column>
                                    <Section relative top='-1'>
                                      <img src={ICONS['ic-document-outline-blue.svg']} alt='Icon' />
                                    </Section>
                                  </Grid.Column>
                                  <Grid.Column>
                                    <Section width='80'>
                                      <Text bold12 color={VARIABLES.COLORS.GRAY_19}>{ed.docMsCode}</Text>
                                    </Section>
                                  </Grid.Column>
                                  <Grid.Column>
                                    <Text regular12 color={VARIABLES.COLORS.GRAY_19}>{ed.docMsCate} : {ed.docMsName}</Text>
                                  </Grid.Column>
                                </Grid>
                              </Section>
                            )
                          })} */}
                        </Section>
                      </Table.BodyColumn>
                      <Table.BodyColumn
                        info
                        minWidth={VARIABLES.TABLE.DOCUMENTARY_MANAGE.COLUMN_WIDTHS.SPECIFY_COMPANY_NAME}
                        title={e.docSpCompName}
                      >
                        {e.docSpCompName}
                      </Table.BodyColumn>
                      <Table.BodyColumn
                        info
                        maxWidth={VARIABLES.TABLE.DOCUMENTARY_MANAGE.COLUMN_WIDTHS.CREATE_DATE}
                        minWidth={VARIABLES.TABLE.DOCUMENTARY_MANAGE.COLUMN_WIDTHS.CREATE_DATE}
                      >
                        {e.displayCreatedDate}
                      </Table.BodyColumn>
                      <Table.BodyColumn
                        action
                        alignCenter
                        maxWidth={VARIABLES.TABLE.DOCUMENTARY_MANAGE.COLUMN_WIDTHS.ACTION}
                        minWidth={VARIABLES.TABLE.DOCUMENTARY_MANAGE.COLUMN_WIDTHS.ACTION}
                      >
                        <Section>
                          <Image
                            title={getLanguage('field.buttonViewDocument', '')}
                            ui='iconViewWarranty'
                            onClick={() => { onClickView(e) }}
                          />
                        </Section>
                      </Table.BodyColumn>
                    </Table.Row>
                  )
                })}
              </Table.Section>
              :
              <Section justify='center' paddingVertical='60'>
                <Section justify='center' spacingBottom='10'>
                  <img src={ICONS['ic-documents-gray.svg']} alt='Icon' />
                </Section>
                <Text regular14 color={VARIABLES.COLORS.GRAY_12}>
                  {getLanguage('documentary.message.noResult')}
                </Text>
              </Section>
            }
          </Table>
        </Section>
        <TableAction>
          <TableAction.Left>
            <Button
              gray
              ui='back'
              name={getLanguage('field.buttonBack', '')}
              width={VARIABLES.BUTTON.WIDTHS.W_135}
              onClick={goBack}
            />
          </TableAction.Left>
          <TableAction.Right>
            <Grid gutter='20'>
              <Grid.Column>
                <Section paddingTop='12'>
                  <Text
                    regular14
                    color={VARIABLES.COLORS.GRAY_16}
                  >
                    {getLanguage('pagination.page', '')} {activePage}/{Math.ceil(totalData / dataPerPage)}
                  </Text>
                </Section>
              </Grid.Column>
              <Grid.Column>
                <Section paddingTop='6'>
                  <PaginationControl
                    activePage={activePage}
                    itemsCountPerPage={dataPerPage}
                    totalItemsCount={totalData}
                    pageRangeDisplayed={3}
                    onChange={this.pagination.onPaginationChange}
                  />
                </Section>
              </Grid.Column>
            </Grid>
          </TableAction.Right>
        </TableAction>
      </ShapeContainer>
    )
  }
}

const mapStateToProps = (state) => {
  const dataRedux = state.dataRedux;
  // const dataList = dataRedux.documentaryDetailList;
  // const dataGroup = {};
  // dataList.forEach(e => {
  //   if (dataGroup[e.id]) {
  //     dataGroup[e.id].documentaryList.push(e);
  //   }
  //   else {
  //     dataGroup[e.id] = {
  //       ...e,
  //       documentaryList: [e]
  //     };
  //   }
  // })
  return {
    dataList: dataRedux.documentaryDetailList,
    // dataGroupList: Object.values(dataGroup),
    dataParams: dataRedux.documentaryDetailParams,
    activePage: dataRedux.documentaryDetailParams.activePage,
    totalData: dataRedux.documentaryDetailParams.totalData || 0,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    dataActions: bindActionCreators(dataCreators, dispatch),
  }
}

export const DocumentaryDetailListContainerConnected = connect(
  mapStateToProps,
  mapDispatchToProps
)(DocumentaryDetailListContainer)