import React from 'react';
// import {
//   connect,
// } from 'react-redux';
import { WarrantyContentWrapper } from './../../../../components/custom/WarrantyBSWM/WarrantyBSWMMalaysia/styled'
import { WarrantyBSWMMalaysiaCladdingIssuesLists } from './../../../../components/custom/WarrantyBSWM/WarrantyBSWMMalaysia/WarrantyBSWMMalaysiaCladdingIssuesLists'
import { WarrantyBSWMMalaysiaNotCoveredLists } from './../../../../components/custom/WarrantyBSWM/WarrantyBSWMMalaysia/WarrantyBSWMMalaysiaNotCoveredLists'
import {
  Section,
  Grid,
  Table,
} from './../../../../components'
// import {
//   VARIABLES, ICONS,
// } from './../../../../themes'
// import { getLanguage } from '../../../../helpers/functions/language';
// import { MODAL_TYPE } from '../../../../helpers/constants/main'
// import { MAIN_REDUX_STATE_KEY } from '../../../../actions/mainAction'

export class ModalWarrantyTncMalaysiaContent extends React.Component {
  render() {
    const {
      isCAPERO_MY_P1CWR,
      isCCB_MATT_MY_P15RM,
      isCCB_SPECTRUM_MY_P1CPP,
      isCCB_SPECTRUM_MY_P15PP,
      isCCB_ULTRA_MATT_MY_P15UM,
      isCCB_ULTRA_MY_P15UL,
      isCCB_XPD_MY_P15XP,
      isCCB_XPD_PSC_MY_P15XS,
      isCCB_XRW_MY_P15XR,
      isPM_MY_P2PRM,
      isPM_R_MY_P2PRJ,
      isTRUECORE_MY_Z1TRC,
      isVERMOE_MY_P1VRM,
      isZL_MY_Z1ZAL,
      isZL_MY_Z1ZAU,
    } = this.props
    return (
      <WarrantyContentWrapper className='is-terms-and-conditions'>
        {isCAPERO_MY_P1CWR &&
          <React.Fragment>
            <Section spacingBottom='10'><p className='warranty-bswm-text is-bold is-underline'>TERMS AND CONDITIONS</p></Section>
            <Section spacingBottom='5'><p className='warranty-bswm-text is-xs'>This warranty is your guarantee that <span className='warranty-bswm-text is-xs is-bold'>CAPERO®</span> prepainted steel, if properly installed and maintained, will meet minimum expected performance levels for at least the warranted period (commencing from the date of installation).</p></Section>
            <Section spacingBottom='5'><p className='warranty-bswm-text is-xs'>However, certain specific problems and situations (listed below) must be addressed and rectified early in order to get the best possible performance and, if not rectified, may result in the warranty being void.</p></Section>
            <Section spacingBottom='5'>
              <Grid>
                <Grid.Column flex='none'><Section width='17'><p className='warranty-bswm-text is-xs'>1.</p></Section></Grid.Column>
                <Grid.Column flex='1'><p className='warranty-bswm-text is-xs'>The warranty applies only to cladding and flashing made from prime quality <span className='warranty-bswm-text is-xs is-bold'>CAPERO®</span> steel. The Owner/Contractor is advised to check for the <span className='warranty-bswm-text is-xs is-bold'>CAPERO®</span> steel brand name printed on the reverse of the sheets prior to installation. Guttering, downpipes, brackets, doors and other accessories are excluded.</p></Grid.Column>
              </Grid>
            </Section>
            <Section>
              <Grid>
                <Grid.Column flex='none'><Section width='17'><p className='warranty-bswm-text is-xs'>2.</p></Section></Grid.Column>
                <Grid.Column flex='1'><p className='warranty-bswm-text is-xs'>Cladding must be handled and installed in accordance with published BlueScope guidelines current at the time of installation, with particular attention to the issues listed below:-</p></Grid.Column>
              </Grid>
            </Section>
            <Section paddingLeft='17' spacingBottom='5'>
              <WarrantyBSWMMalaysiaCladdingIssuesLists />
            </Section>
            <Section spacingBottom='5'>
              <Grid>
                <Grid.Column flex='none'><Section width='17'><p className='warranty-bswm-text is-xs'>3.</p></Section></Grid.Column>
                <Grid.Column flex='1'><p className='warranty-bswm-text is-xs'>Fasteners must comply with AS3566 Class 3 or Class 4, and be certified as such by the supplier of fasteners.</p></Grid.Column>
              </Grid>
            </Section>
            <Section spacingBottom='5'>
              <Grid>
                <Grid.Column flex='none'><Section width='17'><p className='warranty-bswm-text is-xs'>4.</p></Section></Grid.Column>
                <Grid.Column flex='1'><p className='warranty-bswm-text is-xs'>All fixings, flashings and associated components must be manufactured from compatible metals (<span className='warranty-bswm-text is-xs is-bold'>CAPERO®</span>, <span className='warranty-bswm-text is-xs is-bold'>ZINCALUME®</span> steel, zinc, or aluminium) or be coated or plated in accordance with current published BlueScope guidelines. For long lasting consistency in appearance, flashings should be made from the same material as the cladding.</p></Grid.Column>
              </Grid>
            </Section>
            <Section spacingBottom='5'>
              <Grid>
                <Grid.Column flex='none'><Section width='17'><p className='warranty-bswm-text is-xs'>5.</p></Section></Grid.Column>
                <Grid.Column flex='1'><p className='warranty-bswm-text is-xs'>The paint finish as supplied must not be scratched, abraded damaged, or coated with any foreign matter or incompatible material (including stack and flue deposits).</p></Grid.Column>
              </Grid>
            </Section>
            <Section>
              <Grid>
                <Grid.Column flex='none'><Section width='17'><p className='warranty-bswm-text is-xs'>6.</p></Section></Grid.Column>
                <Grid.Column flex='1'><p className='warranty-bswm-text is-xs'>Product failures wholly or in part due to the following causes are not covered by this warranty.</p></Grid.Column>
              </Grid>
            </Section>
            <Section paddingLeft='17' spacingBottom='5'>
              <WarrantyBSWMMalaysiaNotCoveredLists />
            </Section>
            <Section spacingBottom='5'>
              <Grid>
                <Grid.Column flex='none'><Section width='17'><p className='warranty-bswm-text is-xs'>7.</p></Section></Grid.Column>
                <Grid.Column flex='1'><p className='warranty-bswm-text is-xs'>Failure of the product from corrosion is defined as when the sheet is perforated. Surface rust, or minor corrosion at tension bends or cuts edges due to natural weathering does not constitute failure of the product.</p></Grid.Column>
              </Grid>
            </Section>
            <Section spacingBottom='5'>
              <Grid>
                <Grid.Column flex='none'><Section width='17'><p className='warranty-bswm-text is-xs'>8.</p></Section></Grid.Column>
                <Grid.Column flex='1'><p className='warranty-bswm-text is-xs'>Failure of the product in terms of fading is deﬁned as per the following table.</p></Grid.Column>
              </Grid>
            </Section>
            <Section spacingBottom='5' paddingLeft='17'>
              <Table fluid warranty>
                <Table.Section>
                  <Table.Row>
                    <Table.Column className='is-head-column' alignCenter maxWidth='230' minWidth='230' paddingVertical='4' paddingHorizontal='4'>
                      <Section spacingTop='11'><p className='warranty-bswm-text is-xs is-bold'>Colour Shade</p></Section>
                    </Table.Column>
                    <Table.Column className='is-head-column' alignCenter maxWidth='230' minWidth='230' paddingVertical='4' paddingHorizontal='4'>
                      <p className='warranty-bswm-text is-xs is-bold'>Maximum Fading <br /> (ΔE units CIELAB) <br /> ASTM D-2244 (Cleaned)</p>
                    </Table.Column>
                  </Table.Row>
                </Table.Section>
                <Table.Section>
                  <Table.Row>
                    <Table.Column className='is-body-column' maxWidth='230' minWidth='230' paddingVertical='4' paddingHorizontal='4'>
                      <p className='warranty-bswm-text is-xs'>Intermediate (e.g., Ballistic Grey)</p>
                    </Table.Column>
                    <Table.Column className='is-body-column' maxWidth='230' minWidth='230' paddingVertical='4' paddingHorizontal='4'>
                      <Section><p className='warranty-bswm-text is-xs is-center'>10</p></Section>
                    </Table.Column>
                  </Table.Row>
                  <Table.Row>
                    <Table.Column className='is-body-column' maxWidth='230' minWidth='230' paddingVertical='4' paddingHorizontal='4'>
                      <p className='warranty-bswm-text is-xs'>Dark (e.g., Melanic)</p>
                    </Table.Column>
                    <Table.Column className='is-body-column' maxWidth='230' minWidth='230' paddingVertical='4' paddingHorizontal='4'>
                      <Section><p className='warranty-bswm-text is-xs is-center'>15</p></Section>
                    </Table.Column>
                  </Table.Row>
                  <Table.Row>
                    <Table.Column className='is-body-column' maxWidth='460' minWidth='460' paddingVertical='4' paddingHorizontal='4'>
                      <Section>
                        <p className='warranty-bswm-text is-xs is-center'>Colour Shade: L{'<'}40 is Dark, L between 40 and 70 is Intermediate and L{'>'}70 is Light</p>
                      </Section>
                    </Table.Column>
                  </Table.Row>
                </Table.Section>
              </Table>
            </Section>
            <Section paddingLeft='17'>
              <p className='warranty-bswm-text is-xs'>Ref : 04-0620-T6-T8</p>
            </Section>
          </React.Fragment>
        }
        {isCCB_MATT_MY_P15RM &&
          <React.Fragment>
            <Section spacingBottom='5'><p className='warranty-bswm-text is-xs'>This warranty is your guarantee that <span className='warranty-bswm-text is-xs is-bold'>COLORBOND® MATT</span> prepainted steel, if properly installed and maintained, will meet minimum expected performance levels for at least the warranted period (commencing from the date of installation).</p></Section>
            <Section spacingBottom='5'><p className='warranty-bswm-text is-xs'>However, certain specific problems and situations (listed below) must be addressed and rectified early in order to get the best possible performance and, if not rectified, may result in the warranty being void.</p></Section>
            <Section spacingBottom='5'>
              <Grid>
                <Grid.Column flex='none'><Section width='17'><p className='warranty-bswm-text is-xs'>1.</p></Section></Grid.Column>
                <Grid.Column flex='1'><p className='warranty-bswm-text is-xs'>The warranty applies only to cladding and flashing made from prime quality <span className='warranty-bswm-text is-xs is-bold'>COLORBOND® MATT</span> steel. The Owner/Contractor is advised to check for the <span className='warranty-bswm-text is-xs is-bold'>COLORBOND® MATT</span> steel brand name printed on the reverse of the sheets prior to installation. Guttering, downpipes, brackets, doors and other accessories are excluded.</p></Grid.Column>
              </Grid>
            </Section>
            <Section>
              <Grid>
                <Grid.Column flex='none'><Section width='17'><p className='warranty-bswm-text is-xs'>2.</p></Section></Grid.Column>
                <Grid.Column flex='1'><p className='warranty-bswm-text is-xs'>Cladding must be handled and installed in accordance with published BlueScope guidelines current at the time of installation, with particular attention to the issues listed below:-</p></Grid.Column>
              </Grid>
            </Section>
            <Section paddingLeft='17' spacingBottom='5'>
              <WarrantyBSWMMalaysiaCladdingIssuesLists />
            </Section>
            <Section spacingBottom='5'>
              <Grid>
                <Grid.Column flex='none'><Section width='17'><p className='warranty-bswm-text is-xs'>3.</p></Section></Grid.Column>
                <Grid.Column flex='1'><p className='warranty-bswm-text is-xs'>Fasteners must comply with AS3566 Class 3 or Class 4, and be certified as such by the supplier of fasteners.</p></Grid.Column>
              </Grid>
            </Section>
            <Section spacingBottom='5'>
              <Grid>
                <Grid.Column flex='none'><Section width='17'><p className='warranty-bswm-text is-xs'>4.</p></Section></Grid.Column>
                <Grid.Column flex='1'><p className='warranty-bswm-text is-xs'>All fixings, flashings and associated components must be manufactured from compatible metals (<span className='warranty-bswm-text is-xs is-bold'>COLORBOND®</span>, <span className='warranty-bswm-text is-xs is-bold'>ZINCALUME®</span> steel, zinc, or aluminium) or be coated or plated in accordance with current published BlueScope guidelines. For long lasting consistency in appearance, flashings should be made from the same material as the cladding.</p></Grid.Column>
              </Grid>
            </Section>
            <Section spacingBottom='5'>
              <Grid>
                <Grid.Column flex='none'><Section width='17'><p className='warranty-bswm-text is-xs'>5.</p></Section></Grid.Column>
                <Grid.Column flex='1'><p className='warranty-bswm-text is-xs'>The paint finish as supplied must not be scratched, abraded damaged, or coated with any foreign matter or incompatible material (including stack and flue deposits).</p></Grid.Column>
              </Grid>
            </Section>
            <Section>
              <Grid>
                <Grid.Column flex='none'><Section width='17'><p className='warranty-bswm-text is-xs'>6.</p></Section></Grid.Column>
                <Grid.Column flex='1'><p className='warranty-bswm-text is-xs'>Product failures wholly or in part due to the following causes are not covered by this warranty.</p></Grid.Column>
              </Grid>
            </Section>
            <Section paddingLeft='17' spacingBottom='5'>
              <WarrantyBSWMMalaysiaNotCoveredLists />
            </Section>
            <Section spacingBottom='5'>
              <Grid>
                <Grid.Column flex='none'><Section width='17'><p className='warranty-bswm-text is-xs'>7.</p></Section></Grid.Column>
                <Grid.Column flex='1'><p className='warranty-bswm-text is-xs'>Failure of the product from corrosion is defined as when the sheet is perforated. Surface rust, or minor corrosion at tension bends or cuts edges due to natural weathering does not constitute failure of the product.</p></Grid.Column>
              </Grid>
            </Section>
            <Section spacingBottom='5'>
              <Grid>
                <Grid.Column flex='none'><Section width='17'><p className='warranty-bswm-text is-xs'>8.</p></Section></Grid.Column>
                <Grid.Column flex='1'><p className='warranty-bswm-text is-xs'>Failure of the product in terms of fading and dirt staining is deﬁned as per the following table.</p></Grid.Column>
              </Grid>
            </Section>
            <Section spacingBottom='5' paddingLeft='17'>
              <Table fluid warranty>
                <Table.Section>
                  <Table.Row>
                    <Table.Column className='is-head-column' alignCenter maxWidth='200' minWidth='200' paddingVertical='4' paddingHorizontal='4'>
                      <Section spacingTop='11'><p className='warranty-bswm-text is-xs is-bold'>Colour Shade</p></Section>
                    </Table.Column>
                    <Table.Column className='is-head-column' alignCenter maxWidth='200' minWidth='200' paddingVertical='4' paddingHorizontal='4'>
                      <p className='warranty-bswm-text is-xs is-bold'>Maximum Fading <br /> (ΔE units CIELAB) <br /> ASTM D-2244 (Cleaned)</p>
                    </Table.Column>
                    <Table.Column className='is-head-column' alignCenter maxWidth='200' minWidth='200' paddingVertical='4' paddingHorizontal='4'>
                      <p className='warranty-bswm-text is-xs is-bold'>Maximum Discolouration by <br /> Dirt Staining (ΔL units CIELAB) <br /> ASTM D-2244 (Cleaned)</p>
                    </Table.Column>
                  </Table.Row>
                </Table.Section>
                <Table.Section>
                  <Table.Row>
                    <Table.Column className='is-body-column' maxWidth='200' minWidth='200' paddingVertical='4' paddingHorizontal='4'>
                      <p className='warranty-bswm-text is-xs'>Light (e.g., Scallop Matt)</p>
                    </Table.Column>
                    <Table.Column className='is-body-column' maxWidth='200' minWidth='200' paddingVertical='4' paddingHorizontal='4'>
                      <Section><p className='warranty-bswm-text is-xs is-center'>6</p></Section>
                    </Table.Column>
                    <Table.Column className='is-body-column' maxWidth='200' minWidth='200' paddingVertical='4' paddingHorizontal='4'>
                      <Section><p className='warranty-bswm-text is-xs is-center'>≥ -4</p></Section>
                    </Table.Column>
                  </Table.Row>
                  <Table.Row>
                    <Table.Column className='is-body-column' maxWidth='200' minWidth='200' paddingVertical='4' paddingHorizontal='4'>
                      <p className='warranty-bswm-text is-xs'>Intermediate (e.g., Hidden Matt)</p>
                    </Table.Column>
                    <Table.Column className='is-body-column' maxWidth='200' minWidth='200' paddingVertical='4' paddingHorizontal='4'>
                      <Section><p className='warranty-bswm-text is-xs is-center'>9</p></Section>
                    </Table.Column>
                    <Table.Column className='is-body-column' maxWidth='200' minWidth='200' paddingVertical='4' paddingHorizontal='4'>
                      <Section><p className='warranty-bswm-text is-xs is-center'>≥ -3</p></Section>
                    </Table.Column>
                  </Table.Row>
                  <Table.Row>
                    <Table.Column className='is-body-column' maxWidth='200' minWidth='200' paddingVertical='4' paddingHorizontal='4'>
                      <p className='warranty-bswm-text is-xs'>Dark (e.g., Eclipse Matt)</p>
                    </Table.Column>
                    <Table.Column className='is-body-column' maxWidth='200' minWidth='200' paddingVertical='4' paddingHorizontal='4'>
                      <Section><p className='warranty-bswm-text is-xs is-center'>15</p></Section>
                    </Table.Column>
                    <Table.Column className='is-body-column' maxWidth='200' minWidth='200' paddingVertical='4' paddingHorizontal='4'>
                      <Section><p className='warranty-bswm-text is-xs is-center'>≥ -2</p></Section>
                    </Table.Column>
                  </Table.Row>
                  <Table.Row>
                    <Table.Column className='is-body-column' maxWidth='600' minWidth='600' paddingVertical='4' paddingHorizontal='4'>
                      <Section>
                        <p className='warranty-bswm-text is-xs is-center'>Colour Shade: L{'<'}40 is Dark, L between 40 and 70 is Intermediate and L{'>'}70 is Ligh</p>
                      </Section>
                    </Table.Column>
                  </Table.Row>
                </Table.Section>
              </Table>
            </Section>
            <Section paddingLeft='17'>
              <p className='warranty-bswm-text is-xs'>Ref : 01-0620-T6-T8</p>
            </Section>
          </React.Fragment>
        }
        {isCCB_SPECTRUM_MY_P1CPP &&
          <React.Fragment>
            <Section spacingBottom='5'><p className='warranty-bswm-text is-xs'>This warranty is your guarantee that <span className='warranty-bswm-text is-xs is-bold'>COLORBOND®</span> - <span className='warranty-bswm-text is-xs is-bold'>SPECTRUM Series</span> prepainted steel, if properly installed and maintained, will meet minimum expected performance levels for at least the warranted period (commencing from the date of installation).</p></Section>
            <Section spacingBottom='5'><p className='warranty-bswm-text is-xs'>However, certain specific problems and situations (listed below) must be addressed and rectified early in order to get the best possible performance and, if not rectified, may result in the warranty being void.</p></Section>
            <Section spacingBottom='5'>
              <Grid>
                <Grid.Column flex='none'><Section width='17'><p className='warranty-bswm-text is-xs'>1.</p></Section></Grid.Column>
                <Grid.Column flex='1'><p className='warranty-bswm-text is-xs'>The warranty applies only to cladding and flashing made from prime quality <span className='warranty-bswm-text is-xs is-bold'>COLORBOND®</span> - <span className='warranty-bswm-text is-xs is-bold'>SPECTRUM Series</span>. The Owner/Contractor is advised to check for the <span className='warranty-bswm-text is-xs is-bold'>COLORBOND®</span> - <span className='warranty-bswm-text is-xs is-bold'>SPECTRUM Series</span> brand name printed on the reverse of the sheets prior to installation. Guttering, downpipes, brackets, doors and other accessories are excluded.</p></Grid.Column>
              </Grid>
            </Section>
            <Section>
              <Grid>
                <Grid.Column flex='none'><Section width='17'><p className='warranty-bswm-text is-xs'>2.</p></Section></Grid.Column>
                <Grid.Column flex='1'><p className='warranty-bswm-text is-xs'>Cladding must be handled and installed in accordance with published BlueScope guidelines current at the time of installation, with particular attention to the issues listed below:-</p></Grid.Column>
              </Grid>
            </Section>
            <Section paddingLeft='17' spacingBottom='5'>
              <WarrantyBSWMMalaysiaCladdingIssuesLists />
            </Section>
            <Section spacingBottom='5'>
              <Grid>
                <Grid.Column flex='none'><Section width='17'><p className='warranty-bswm-text is-xs'>3.</p></Section></Grid.Column>
                <Grid.Column flex='1'><p className='warranty-bswm-text is-xs'>Fasteners must comply with AS3566 Class 3 or Class 4, and be certified as such by the supplier of fasteners.</p></Grid.Column>
              </Grid>
            </Section>
            <Section spacingBottom='5'>
              <Grid>
                <Grid.Column flex='none'><Section width='17'><p className='warranty-bswm-text is-xs'>4.</p></Section></Grid.Column>
                <Grid.Column flex='1'><p className='warranty-bswm-text is-xs'>All fixings, flashings and associated components must be manufactured from compatible metals (<span className='warranty-bswm-text is-xs is-bold'>COLORBOND®</span>, <span className='warranty-bswm-text is-xs is-bold'>ZINCALUME®</span> steel, zinc, or aluminium) or be coated or plated in accordance with current published BlueScope guidelines. For long lasting consistency in appearance, flashings should be made from the same material as the cladding.</p></Grid.Column>
              </Grid>
            </Section>
            <Section spacingBottom='5'>
              <Grid>
                <Grid.Column flex='none'><Section width='17'><p className='warranty-bswm-text is-xs'>5.</p></Section></Grid.Column>
                <Grid.Column flex='1'><p className='warranty-bswm-text is-xs'>The paint finish as supplied must not be scratched, abraded damaged, or coated with any foreign matter or incompatible material (including stack and flue deposits).</p></Grid.Column>
              </Grid>
            </Section>
            <Section>
              <Grid>
                <Grid.Column flex='none'><Section width='17'><p className='warranty-bswm-text is-xs'>6.</p></Section></Grid.Column>
                <Grid.Column flex='1'><p className='warranty-bswm-text is-xs'>Product failures wholly or in part due to the following causes are not covered by this warranty.</p></Grid.Column>
              </Grid>
            </Section>
            <Section paddingLeft='17' spacingBottom='5'>
              <WarrantyBSWMMalaysiaNotCoveredLists />
            </Section>
            <Section spacingBottom='5'>
              <Grid>
                <Grid.Column flex='none'><Section width='17'><p className='warranty-bswm-text is-xs'>7.</p></Section></Grid.Column>
                <Grid.Column flex='1'><p className='warranty-bswm-text is-xs'>Failure of the product from corrosion is defined as when the sheet is perforated. Surface rust, or minor corrosion at tension bends or cuts edges due to natural weathering does not constitute failure of the product.</p></Grid.Column>
              </Grid>
            </Section>
            <Section spacingBottom='5'>
              <Grid>
                <Grid.Column flex='none'><Section width='17'><p className='warranty-bswm-text is-xs'>8.</p></Section></Grid.Column>
                <Grid.Column flex='1'><p className='warranty-bswm-text is-xs'>Failure of the product in terms of fading and dirt staining is deﬁned as per the following table.</p></Grid.Column>
              </Grid>
            </Section>
            <Section spacingBottom='5' paddingLeft='17'>
              <Table fluid warranty>
                <Table.Section>
                  <Table.Row>
                    <Table.Column className='is-head-column' alignCenter maxWidth='200' minWidth='200' paddingVertical='4' paddingHorizontal='4'>
                      <Section spacingTop='11'><p className='warranty-bswm-text is-xs is-bold'>Colour Shade</p></Section>
                    </Table.Column>
                    <Table.Column className='is-head-column' alignCenter maxWidth='200' minWidth='200' paddingVertical='4' paddingHorizontal='4'>
                      <p className='warranty-bswm-text is-xs is-bold'>Maximum Fading <br /> (ΔE units CIELAB) <br /> ASTM D-2244 (Cleaned)</p>
                    </Table.Column>
                    <Table.Column className='is-head-column' alignCenter maxWidth='200' minWidth='200' paddingVertical='4' paddingHorizontal='4'>
                      <p className='warranty-bswm-text is-xs is-bold'>Maximum Discolouration by <br /> Dirt Staining (ΔL units CIELAB) <br /> ASTM D-2244 (Cleaned)</p>
                    </Table.Column>
                  </Table.Row>
                </Table.Section>
                <Table.Section>
                  <Table.Row>
                    <Table.Column className='is-body-column' maxWidth='200' minWidth='200' paddingVertical='4' paddingHorizontal='4'>
                      <p className='warranty-bswm-text is-xs'>Intermediate (e.g., Pebble Grey)</p>
                    </Table.Column>
                    <Table.Column className='is-body-column' maxWidth='200' minWidth='200' paddingVertical='4' paddingHorizontal='4'>
                      <Section><p className='warranty-bswm-text is-xs is-center'>9</p></Section>
                    </Table.Column>
                    <Table.Column className='is-body-column' maxWidth='200' minWidth='200' paddingVertical='4' paddingHorizontal='4'>
                      <Section><p className='warranty-bswm-text is-xs is-center'>≥ -3</p></Section>
                    </Table.Column>
                  </Table.Row>
                  <Table.Row>
                    <Table.Column className='is-body-column' maxWidth='600' minWidth='600' paddingVertical='4' paddingHorizontal='4'>
                      <Section>
                        <p className='warranty-bswm-text is-xs is-center'>Colour Shade: L{'<'}40 is Dark, L between 40 and 70 is Intermediate and L{'>'}70 is Ligh</p>
                      </Section>
                    </Table.Column>
                  </Table.Row>
                </Table.Section>
              </Table>
            </Section>
            <Section paddingLeft='17'>
              <p className='warranty-bswm-text is-xs'>Ref : 04-0620-T6-T8</p>
            </Section>
          </React.Fragment>
        }
        {isCCB_SPECTRUM_MY_P15PP &&
          <React.Fragment>
            <Section spacingBottom='5'><p className='warranty-bswm-text is-xs'>This warranty is your guarantee that <span className='warranty-bswm-text is-xs is-bold'>COLORBOND®</span> - <span className='warranty-bswm-text is-xs is-bold'>SPECTRUM Series</span> prepainted steel, if properly installed and maintained, will meet minimum expected performance levels for at least the warranted period (commencing from the date of installation).</p></Section>
            <Section spacingBottom='5'><p className='warranty-bswm-text is-xs'>However, certain specific problems and situations (listed below) must be addressed and rectified early in order to get the best possible performance and, if not rectified, may result in the warranty being void.</p></Section>
            <Section spacingBottom='5'>
              <Grid>
                <Grid.Column flex='none'><Section width='17'><p className='warranty-bswm-text is-xs'>1.</p></Section></Grid.Column>
                <Grid.Column flex='1'><p className='warranty-bswm-text is-xs'>The warranty applies only to cladding and flashing made from prime quality <span className='warranty-bswm-text is-xs is-bold'>COLORBOND®</span> - <span className='warranty-bswm-text is-xs is-bold'>SPECTRUM Series</span>. The Owner/Contractor is advised to check for the <span className='warranty-bswm-text is-xs is-bold'>COLORBOND®</span> - <span className='warranty-bswm-text is-xs is-bold'>SPECTRUM Series</span> brand name printed on the reverse of the sheets prior to installation. Guttering, downpipes, brackets, doors and other accessories are excluded.</p></Grid.Column>
              </Grid>
            </Section>
            <Section>
              <Grid>
                <Grid.Column flex='none'><Section width='17'><p className='warranty-bswm-text is-xs'>2.</p></Section></Grid.Column>
                <Grid.Column flex='1'><p className='warranty-bswm-text is-xs'>Cladding must be handled and installed in accordance with published BlueScope guidelines current at the time of installation, with particular attention to the issues listed below:-</p></Grid.Column>
              </Grid>
            </Section>
            <Section paddingLeft='17' spacingBottom='5'>
              <WarrantyBSWMMalaysiaCladdingIssuesLists />
            </Section>
            <Section spacingBottom='5'>
              <Grid>
                <Grid.Column flex='none'><Section width='17'><p className='warranty-bswm-text is-xs'>3.</p></Section></Grid.Column>
                <Grid.Column flex='1'><p className='warranty-bswm-text is-xs'>Fasteners must comply with AS3566 Class 3 or Class 4, and be certified as such by the supplier of fasteners.</p></Grid.Column>
              </Grid>
            </Section>
            <Section spacingBottom='5'>
              <Grid>
                <Grid.Column flex='none'><Section width='17'><p className='warranty-bswm-text is-xs'>4.</p></Section></Grid.Column>
                <Grid.Column flex='1'><p className='warranty-bswm-text is-xs'>All fixings, flashings and associated components must be manufactured from compatible metals (<span className='warranty-bswm-text is-xs is-bold'>COLORBOND®</span>, <span className='warranty-bswm-text is-xs is-bold'>ZINCALUME®</span> steel, zinc, or aluminium) or be coated or plated in accordance with current published BlueScope guidelines. For long lasting consistency in appearance, flashings should be made from the same material as the cladding.</p></Grid.Column>
              </Grid>
            </Section>
            <Section spacingBottom='5'>
              <Grid>
                <Grid.Column flex='none'><Section width='17'><p className='warranty-bswm-text is-xs'>5.</p></Section></Grid.Column>
                <Grid.Column flex='1'><p className='warranty-bswm-text is-xs'>The paint finish as supplied must not be scratched, abraded damaged, or coated with any foreign matter or incompatible material (including stack and flue deposits).</p></Grid.Column>
              </Grid>
            </Section>
            <Section>
              <Grid>
                <Grid.Column flex='none'><Section width='17'><p className='warranty-bswm-text is-xs'>6.</p></Section></Grid.Column>
                <Grid.Column flex='1'><p className='warranty-bswm-text is-xs'>Product failures wholly or in part due to the following causes are not covered by this warranty.</p></Grid.Column>
              </Grid>
            </Section>
            <Section paddingLeft='17' spacingBottom='5'>
              <WarrantyBSWMMalaysiaNotCoveredLists />
            </Section>
            <Section spacingBottom='5'>
              <Grid>
                <Grid.Column flex='none'><Section width='17'><p className='warranty-bswm-text is-xs'>7.</p></Section></Grid.Column>
                <Grid.Column flex='1'><p className='warranty-bswm-text is-xs'>Failure of the product from corrosion is defined as when the sheet is perforated. Surface rust, or minor corrosion at tension bends or cuts edges due to natural weathering does not constitute failure of the product.</p></Grid.Column>
              </Grid>
            </Section>
            <Section spacingBottom='5'>
              <Grid>
                <Grid.Column flex='none'><Section width='17'><p className='warranty-bswm-text is-xs'>8.</p></Section></Grid.Column>
                <Grid.Column flex='1'><p className='warranty-bswm-text is-xs'>Failure of the product in terms of fading and dirt staining is deﬁned as per the following table.</p></Grid.Column>
              </Grid>
            </Section>
            <Section spacingBottom='5' paddingLeft='17'>
              <Table fluid warranty>
                <Table.Section>
                  <Table.Row>
                    <Table.Column className='is-head-column' alignCenter maxWidth='200' minWidth='200' paddingVertical='4' paddingHorizontal='4'>
                      <Section spacingTop='11'><p className='warranty-bswm-text is-xs is-bold'>Colour Shade</p></Section>
                    </Table.Column>
                    <Table.Column className='is-head-column' alignCenter maxWidth='200' minWidth='200' paddingVertical='4' paddingHorizontal='4'>
                      <p className='warranty-bswm-text is-xs is-bold'>Maximum Fading <br /> (ΔE units CIELAB) <br /> ASTM D-2244 (Cleaned)</p>
                    </Table.Column>
                    <Table.Column className='is-head-column' alignCenter maxWidth='200' minWidth='200' paddingVertical='4' paddingHorizontal='4'>
                      <p className='warranty-bswm-text is-xs is-bold'>Maximum Discolouration by <br /> Dirt Staining (ΔL units CIELAB) <br /> ASTM D-2244 (Cleaned)</p>
                    </Table.Column>
                  </Table.Row>
                </Table.Section>
                <Table.Section>
                  <Table.Row>
                    <Table.Column className='is-body-column' maxWidth='200' minWidth='200' paddingVertical='4' paddingHorizontal='4'>
                      <p className='warranty-bswm-text is-xs'>Intermediate (e.g., Pebble Grey)</p>
                    </Table.Column>
                    <Table.Column className='is-body-column' maxWidth='200' minWidth='200' paddingVertical='4' paddingHorizontal='4'>
                      <Section><p className='warranty-bswm-text is-xs is-center'>9</p></Section>
                    </Table.Column>
                    <Table.Column className='is-body-column' maxWidth='200' minWidth='200' paddingVertical='4' paddingHorizontal='4'>
                      <Section><p className='warranty-bswm-text is-xs is-center'>≥ -3</p></Section>
                    </Table.Column>
                  </Table.Row>
                  <Table.Row>
                    <Table.Column className='is-body-column' maxWidth='600' minWidth='600' paddingVertical='4' paddingHorizontal='4'>
                      <Section>
                        <p className='warranty-bswm-text is-xs is-center'>Colour Shade: L{'<'}40 is Dark, L between 40 and 70 is Intermediate and L{'>'}70 is Ligh</p>
                      </Section>
                    </Table.Column>
                  </Table.Row>
                </Table.Section>
              </Table>
            </Section>
            <Section paddingLeft='17'>
              <p className='warranty-bswm-text is-xs'>Ref : 04-0620-T6-T8</p>
            </Section>
          </React.Fragment>
        }
        {isCCB_ULTRA_MATT_MY_P15UM &&
          <React.Fragment>
            <Section spacingBottom='5'><p className='warranty-bswm-text is-xs'>This warranty is your guarantee that <span className='warranty-bswm-text is-xs is-bold'>COLORBOND® ULTRA MATT</span> prepainted steel, if properly installed and maintained, will meet minimum expected performance levels for at least the warranted period (commencing from the date of installation).</p></Section>
            <Section spacingBottom='5'><p className='warranty-bswm-text is-xs'>However, certain specific problems and situations (listed below) must be addressed and rectified early in order to get the best possible performance and, if not rectified, may result in the warranty being void.</p></Section>
            <Section spacingBottom='5'>
              <Grid>
                <Grid.Column flex='none'><Section width='17'><p className='warranty-bswm-text is-xs'>1.</p></Section></Grid.Column>
                <Grid.Column flex='1'><p className='warranty-bswm-text is-xs'>The warranty applies only to cladding and flashing made from prime quality <span className='warranty-bswm-text is-xs is-bold'>COLORBOND® ULTRA MATT</span> steel. The Owner/Contractor is advised to check for the <span className='warranty-bswm-text is-xs is-bold'>COLORBOND® ULTRA MATT</span> steel brand name printed on the reverse of the sheets prior to installation. Guttering, downpipes, brackets, doors and other accessories are excluded.</p></Grid.Column>
              </Grid>
            </Section>
            <Section>
              <Grid>
                <Grid.Column flex='none'><Section width='17'><p className='warranty-bswm-text is-xs'>2.</p></Section></Grid.Column>
                <Grid.Column flex='1'><p className='warranty-bswm-text is-xs'>Cladding must be handled and installed in accordance with published BlueScope guidelines current at the time of installation, with particular attention to the issues listed below:-</p></Grid.Column>
              </Grid>
            </Section>
            <Section paddingLeft='17' spacingBottom='5'>
              <WarrantyBSWMMalaysiaCladdingIssuesLists />
            </Section>
            <Section spacingBottom='5'>
              <Grid>
                <Grid.Column flex='none'><Section width='17'><p className='warranty-bswm-text is-xs'>3.</p></Section></Grid.Column>
                <Grid.Column flex='1'><p className='warranty-bswm-text is-xs'>Fasteners must comply with AS3566 Class 3 or Class 4, and be certified as such by the supplier of fasteners.</p></Grid.Column>
              </Grid>
            </Section>
            <Section spacingBottom='5'>
              <Grid>
                <Grid.Column flex='none'><Section width='17'><p className='warranty-bswm-text is-xs'>4.</p></Section></Grid.Column>
                <Grid.Column flex='1'><p className='warranty-bswm-text is-xs'>All fixings, flashings and associated components must be manufactured from compatible metals (<span className='warranty-bswm-text is-xs is-bold'>COLORBOND®</span>, <span className='warranty-bswm-text is-xs is-bold'>ZINCALUME®</span> steel, zinc, or aluminium) or be coated or plated in accordance with current published BlueScope guidelines. For long lasting consistency in appearance, flashings should be made from the same material as the cladding.</p></Grid.Column>
              </Grid>
            </Section>
            <Section spacingBottom='5'>
              <Grid>
                <Grid.Column flex='none'><Section width='17'><p className='warranty-bswm-text is-xs'>5.</p></Section></Grid.Column>
                <Grid.Column flex='1'><p className='warranty-bswm-text is-xs'>The paint finish as supplied must not be scratched, abraded damaged, or coated with any foreign matter or incompatible material (including stack and flue deposits).</p></Grid.Column>
              </Grid>
            </Section>
            <Section>
              <Grid>
                <Grid.Column flex='none'><Section width='17'><p className='warranty-bswm-text is-xs'>6.</p></Section></Grid.Column>
                <Grid.Column flex='1'><p className='warranty-bswm-text is-xs'>Product failures wholly or in part due to the following causes are not covered by this warranty.</p></Grid.Column>
              </Grid>
            </Section>
            <Section paddingLeft='17' spacingBottom='5'>
              <WarrantyBSWMMalaysiaNotCoveredLists />
            </Section>
            <Section spacingBottom='5'>
              <Grid>
                <Grid.Column flex='none'><Section width='17'><p className='warranty-bswm-text is-xs'>7.</p></Section></Grid.Column>
                <Grid.Column flex='1'><p className='warranty-bswm-text is-xs'>Failure of the product from corrosion is defined as when the sheet is perforated. Surface rust, or minor corrosion at tension bends or cuts edges due to natural weathering does not constitute failure of the product.</p></Grid.Column>
              </Grid>
            </Section>
            <Section spacingBottom='5'>
              <Grid>
                <Grid.Column flex='none'><Section width='17'><p className='warranty-bswm-text is-xs'>8.</p></Section></Grid.Column>
                <Grid.Column flex='1'><p className='warranty-bswm-text is-xs'>Failure of the product in terms of fading and dirt staining is deﬁned as per the following table.</p></Grid.Column>
              </Grid>
            </Section>
            <Section spacingBottom='5' paddingLeft='17'>
              <Table fluid warranty>
                <Table.Section>
                  <Table.Row>
                    <Table.Column className='is-head-column' alignCenter maxWidth='200' minWidth='200' paddingVertical='4' paddingHorizontal='4'>
                      <Section spacingTop='11'><p className='warranty-bswm-text is-xs is-bold'>Colour Shade</p></Section>
                    </Table.Column>
                    <Table.Column className='is-head-column' alignCenter maxWidth='200' minWidth='200' paddingVertical='4' paddingHorizontal='4'>
                      <p className='warranty-bswm-text is-xs is-bold'>Maximum Fading <br /> (ΔE units CIELAB) <br /> ASTM D-2244 (Cleaned)</p>
                    </Table.Column>
                    <Table.Column className='is-head-column' alignCenter maxWidth='200' minWidth='200' paddingVertical='4' paddingHorizontal='4'>
                      <p className='warranty-bswm-text is-xs is-bold'>Maximum Discolouration by <br /> Dirt Staining (ΔL units CIELAB) <br /> ASTM D-2244 (Cleaned)</p>
                    </Table.Column>
                  </Table.Row>
                </Table.Section>
                <Table.Section>
                  <Table.Row>
                    <Table.Column className='is-body-column' maxWidth='200' minWidth='200' paddingVertical='4' paddingHorizontal='4'>
                      <p className='warranty-bswm-text is-xs'>Light (e.g., Oyster Matt)</p>
                    </Table.Column>
                    <Table.Column className='is-body-column' maxWidth='200' minWidth='200' paddingVertical='4' paddingHorizontal='4'>
                      <Section><p className='warranty-bswm-text is-xs is-center'>6</p></Section>
                    </Table.Column>
                    <Table.Column className='is-body-column' maxWidth='200' minWidth='200' paddingVertical='4' paddingHorizontal='4'>
                      <Section><p className='warranty-bswm-text is-xs is-center'>≥ -4</p></Section>
                    </Table.Column>
                  </Table.Row>
                  <Table.Row>
                    <Table.Column className='is-body-column' maxWidth='200' minWidth='200' paddingVertical='4' paddingHorizontal='4'>
                      <p className='warranty-bswm-text is-xs'>Intermediate (e.g., Alley Matt)</p>
                    </Table.Column>
                    <Table.Column className='is-body-column' maxWidth='200' minWidth='200' paddingVertical='4' paddingHorizontal='4'>
                      <Section><p className='warranty-bswm-text is-xs is-center'>9</p></Section>
                    </Table.Column>
                    <Table.Column className='is-body-column' maxWidth='200' minWidth='200' paddingVertical='4' paddingHorizontal='4'>
                      <Section><p className='warranty-bswm-text is-xs is-center'>≥ -3</p></Section>
                    </Table.Column>
                  </Table.Row>
                  <Table.Row>
                    <Table.Column className='is-body-column' maxWidth='200' minWidth='200' paddingVertical='4' paddingHorizontal='4'>
                      <p className='warranty-bswm-text is-xs'>Dark (e.g., Granite Matt)</p>
                    </Table.Column>
                    <Table.Column className='is-body-column' maxWidth='200' minWidth='200' paddingVertical='4' paddingHorizontal='4'>
                      <Section><p className='warranty-bswm-text is-xs is-center'>15</p></Section>
                    </Table.Column>
                    <Table.Column className='is-body-column' maxWidth='200' minWidth='200' paddingVertical='4' paddingHorizontal='4'>
                      <Section><p className='warranty-bswm-text is-xs is-center'>≥ -2</p></Section>
                    </Table.Column>
                  </Table.Row>
                  <Table.Row>
                    <Table.Column className='is-body-column' maxWidth='600' minWidth='600' paddingVertical='4' paddingHorizontal='4'>
                      <Section>
                        <p className='warranty-bswm-text is-xs is-center'>Colour Shade: L{'<'}40 is Dark, L between 40 and 70 is Intermediate and L{'>'}70 is Light</p>
                      </Section>
                    </Table.Column>
                  </Table.Row>
                </Table.Section>
              </Table>
            </Section>
            <Section paddingLeft='17'>
              <p className='warranty-bswm-text is-xs'>Ref : 04-0620-T6-T8</p>
            </Section>
          </React.Fragment>
        }
        {isCCB_ULTRA_MY_P15UL &&
          <React.Fragment>
            <Section spacingBottom='5'><p className='warranty-bswm-text is-xs'>This warranty is your guarantee that <span className='warranty-bswm-text is-xs is-bold'>COLORBOND® ULTRA</span> prepainted steel, if properly installed and maintained, will meet minimum expected performance levels for at least the warranted period (commencing from the date of installation).</p></Section>
            <Section spacingBottom='5'><p className='warranty-bswm-text is-xs'>However, certain specific problems and situations (listed below) must be addressed and rectified early in order to get the best possible performance and, if not rectified, may result in the warranty being void.</p></Section>
            <Section spacingBottom='5'>
              <Grid>
                <Grid.Column flex='none'><Section width='17'><p className='warranty-bswm-text is-xs'>1.</p></Section></Grid.Column>
                <Grid.Column flex='1'><p className='warranty-bswm-text is-xs'>The warranty applies only to cladding and flashing made from prime quality <span className='warranty-bswm-text is-xs is-bold'>COLORBOND® ULTRA</span> steel. The Owner/Contractor is advised to check for the <span className='warranty-bswm-text is-xs is-bold'>COLORBOND® ULTRA</span> steel brand name printed on the reverse of the sheets prior to installation. Guttering, downpipes, brackets, doors and other accessories are excluded.</p></Grid.Column>
              </Grid>
            </Section>
            <Section>
              <Grid>
                <Grid.Column flex='none'><Section width='17'><p className='warranty-bswm-text is-xs'>2.</p></Section></Grid.Column>
                <Grid.Column flex='1'><p className='warranty-bswm-text is-xs'>Cladding must be handled and installed in accordance with published BlueScope guidelines current at the time of installation, with particular attention to the issues listed below:-</p></Grid.Column>
              </Grid>
            </Section>
            <Section paddingLeft='17' spacingBottom='5'>
              <WarrantyBSWMMalaysiaCladdingIssuesLists />
            </Section>
            <Section spacingBottom='5'>
              <Grid>
                <Grid.Column flex='none'><Section width='17'><p className='warranty-bswm-text is-xs'>3.</p></Section></Grid.Column>
                <Grid.Column flex='1'><p className='warranty-bswm-text is-xs'>Fasteners must comply with AS3566 Class 3 or Class 4, and be certified as such by the supplier of fasteners.</p></Grid.Column>
              </Grid>
            </Section>
            <Section spacingBottom='5'>
              <Grid>
                <Grid.Column flex='none'><Section width='17'><p className='warranty-bswm-text is-xs'>4.</p></Section></Grid.Column>
                <Grid.Column flex='1'><p className='warranty-bswm-text is-xs'>All fixings, flashings and associated components must be manufactured from compatible metals (<span className='warranty-bswm-text is-xs is-bold'>COLORBOND®</span>, <span className='warranty-bswm-text is-xs is-bold'>ZINCALUME®</span> steel, zinc, or aluminium) or be coated or plated in accordance with current published BlueScope guidelines. For long lasting consistency in appearance, flashings should be made from the same material as the cladding.</p></Grid.Column>
              </Grid>
            </Section>
            <Section spacingBottom='5'>
              <Grid>
                <Grid.Column flex='none'><Section width='17'><p className='warranty-bswm-text is-xs'>5.</p></Section></Grid.Column>
                <Grid.Column flex='1'><p className='warranty-bswm-text is-xs'>The paint finish as supplied must not be scratched, abraded damaged, or coated with any foreign matter or incompatible material (including stack and flue deposits).</p></Grid.Column>
              </Grid>
            </Section>
            <Section>
              <Grid>
                <Grid.Column flex='none'><Section width='17'><p className='warranty-bswm-text is-xs'>6.</p></Section></Grid.Column>
                <Grid.Column flex='1'><p className='warranty-bswm-text is-xs'>Product failures wholly or in part due to the following causes are not covered by this warranty.</p></Grid.Column>
              </Grid>
            </Section>
            <Section paddingLeft='17' spacingBottom='5'>
              <WarrantyBSWMMalaysiaNotCoveredLists />
            </Section>
            <Section spacingBottom='5'>
              <Grid>
                <Grid.Column flex='none'><Section width='17'><p className='warranty-bswm-text is-xs'>7.</p></Section></Grid.Column>
                <Grid.Column flex='1'><p className='warranty-bswm-text is-xs'>Failure of the product from corrosion is defined as when the sheet is perforated. Surface rust, or minor corrosion at tension bends or cuts edges due to natural weathering does not constitute failure of the product.</p></Grid.Column>
              </Grid>
            </Section>
            <Section spacingBottom='5'>
              <Grid>
                <Grid.Column flex='none'><Section width='17'><p className='warranty-bswm-text is-xs'>8.</p></Section></Grid.Column>
                <Grid.Column flex='1'><p className='warranty-bswm-text is-xs'>Failure of the product in terms of fading and dirt staining is deﬁned as per the following table.</p></Grid.Column>
              </Grid>
            </Section>
            <Section spacingBottom='5' paddingLeft='17'>
              <Table fluid warranty>
                <Table.Section>
                  <Table.Row>
                    <Table.Column className='is-head-column' alignCenter maxWidth='200' minWidth='200' paddingVertical='4' paddingHorizontal='4'>
                      <Section spacingTop='11'><p className='warranty-bswm-text is-xs is-bold'>Colour Shade</p></Section>
                    </Table.Column>
                    <Table.Column className='is-head-column' alignCenter maxWidth='200' minWidth='200' paddingVertical='4' paddingHorizontal='4'>
                      <p className='warranty-bswm-text is-xs is-bold'>Maximum Fading <br /> (ΔE units CIELAB) <br /> ASTM D-2244 (Cleaned)</p>
                    </Table.Column>
                    <Table.Column className='is-head-column' alignCenter maxWidth='200' minWidth='200' paddingVertical='4' paddingHorizontal='4'>
                      <p className='warranty-bswm-text is-xs is-bold'>Maximum Discolouration by <br /> Dirt Staining (ΔL units CIELAB) <br /> ASTM D-2244 (Cleaned)</p>
                    </Table.Column>
                  </Table.Row>
                </Table.Section>
                <Table.Section>
                  <Table.Row>
                    <Table.Column className='is-body-column' maxWidth='200' minWidth='200' paddingVertical='4' paddingHorizontal='4'>
                      <p className='warranty-bswm-text is-xs'>Light (e.g., Enduring White)</p>
                    </Table.Column>
                    <Table.Column className='is-body-column' maxWidth='200' minWidth='200' paddingVertical='4' paddingHorizontal='4'>
                      <Section><p className='warranty-bswm-text is-xs is-center'>6</p></Section>
                    </Table.Column>
                    <Table.Column className='is-body-column' maxWidth='200' minWidth='200' paddingVertical='4' paddingHorizontal='4'>
                      <Section><p className='warranty-bswm-text is-xs is-center'>≥ -4</p></Section>
                    </Table.Column>
                  </Table.Row>
                  <Table.Row>
                    <Table.Column className='is-body-column' maxWidth='200' minWidth='200' paddingVertical='4' paddingHorizontal='4'>
                      <p className='warranty-bswm-text is-xs'>Intermediate (e.g., Forever Beige)</p>
                    </Table.Column>
                    <Table.Column className='is-body-column' maxWidth='200' minWidth='200' paddingVertical='4' paddingHorizontal='4'>
                      <Section><p className='warranty-bswm-text is-xs is-center'>9</p></Section>
                    </Table.Column>
                    <Table.Column className='is-body-column' maxWidth='200' minWidth='200' paddingVertical='4' paddingHorizontal='4'>
                      <Section><p className='warranty-bswm-text is-xs is-center'>≥ -3</p></Section>
                    </Table.Column>
                  </Table.Row>
                  <Table.Row>
                    <Table.Column className='is-body-column' maxWidth='200' minWidth='200' paddingVertical='4' paddingHorizontal='4'>
                      <p className='warranty-bswm-text is-xs'>Dark (e.g., Ever Green)</p>
                    </Table.Column>
                    <Table.Column className='is-body-column' maxWidth='200' minWidth='200' paddingVertical='4' paddingHorizontal='4'>
                      <Section><p className='warranty-bswm-text is-xs is-center'>15</p></Section>
                    </Table.Column>
                    <Table.Column className='is-body-column' maxWidth='200' minWidth='200' paddingVertical='4' paddingHorizontal='4'>
                      <Section><p className='warranty-bswm-text is-xs is-center'>≥ -2</p></Section>
                    </Table.Column>
                  </Table.Row>
                  <Table.Row>
                    <Table.Column className='is-body-column' maxWidth='600' minWidth='600' paddingVertical='4' paddingHorizontal='4'>
                      <Section>
                        <p className='warranty-bswm-text is-xs is-center'>Colour Shade: L{'<'}40 is Dark, L between 40 and 70 is Intermediate and L{'>'}70 is Ligh</p>
                      </Section>
                    </Table.Column>
                  </Table.Row>
                </Table.Section>
              </Table>
            </Section>
            <Section paddingLeft='17'>
              <p className='warranty-bswm-text is-xs'>Ref : 04-0620-T6-T8</p>
            </Section>
          </React.Fragment>
        }
        {isCCB_XPD_MY_P15XP &&
          <React.Fragment>
            <Section spacingBottom='5'><p className='warranty-bswm-text is-xs'>This warranty is your guarantee that <span className='warranty-bswm-text is-xs is-bold'>COLORBOND® XPD</span> prepainted steel, if properly installed and maintained, will meet minimum expected performance levels for at least the warranted period (commencing from the date of installation).</p></Section>
            <Section spacingBottom='5'><p className='warranty-bswm-text is-xs'>However, certain specific problems and situations (listed below) must be addressed and rectified early in order to get the best possible performance and, if not rectified, may result in the warranty being void.</p></Section>
            <Section spacingBottom='5'>
              <Grid>
                <Grid.Column flex='none'><Section width='17'><p className='warranty-bswm-text is-xs'>1.</p></Section></Grid.Column>
                <Grid.Column flex='1'><p className='warranty-bswm-text is-xs'>The warranty applies only to cladding and flashing made from prime quality <span className='warranty-bswm-text is-xs is-bold'>COLORBOND® XPD</span> steel. The Owner/Contractor is advised to check for the <span className='warranty-bswm-text is-xs is-bold'>COLORBOND® XPD</span> steel brand name printed on the reverse of the sheets prior to installation. Guttering, downpipes, brackets, doors and other accessories are excluded.</p></Grid.Column>
              </Grid>
            </Section>
            <Section>
              <Grid>
                <Grid.Column flex='none'><Section width='17'><p className='warranty-bswm-text is-xs'>2.</p></Section></Grid.Column>
                <Grid.Column flex='1'><p className='warranty-bswm-text is-xs'>Cladding must be handled and installed in accordance with published BlueScope guidelines current at the time of installation, with particular attention to the issues listed below:-</p></Grid.Column>
              </Grid>
            </Section>
            <Section paddingLeft='17' spacingBottom='5'>
              <WarrantyBSWMMalaysiaCladdingIssuesLists />
            </Section>
            <Section spacingBottom='5'>
              <Grid>
                <Grid.Column flex='none'><Section width='17'><p className='warranty-bswm-text is-xs'>3.</p></Section></Grid.Column>
                <Grid.Column flex='1'><p className='warranty-bswm-text is-xs'>Fasteners must comply with AS3566 Class 3 or Class 4, and be certified as such by the supplier of fasteners.</p></Grid.Column>
              </Grid>
            </Section>
            <Section spacingBottom='5'>
              <Grid>
                <Grid.Column flex='none'><Section width='17'><p className='warranty-bswm-text is-xs'>4.</p></Section></Grid.Column>
                <Grid.Column flex='1'><p className='warranty-bswm-text is-xs'>All fixings, flashings and associated components must be manufactured from compatible metals (<span className='warranty-bswm-text is-xs is-bold'>COLORBOND®</span>, <span className='warranty-bswm-text is-xs is-bold'>ZINCALUME®</span> steel, zinc, or aluminium) or be coated or plated in accordance with current published BlueScope guidelines. For long lasting consistency in appearance, flashings should be made from the same material as the cladding.</p></Grid.Column>
              </Grid>
            </Section>
            <Section spacingBottom='5'>
              <Grid>
                <Grid.Column flex='none'><Section width='17'><p className='warranty-bswm-text is-xs'>5.</p></Section></Grid.Column>
                <Grid.Column flex='1'><p className='warranty-bswm-text is-xs'>The paint finish as supplied must not be scratched, abraded damaged, or coated with any foreign matter or incompatible material (including stack and flue deposits).</p></Grid.Column>
              </Grid>
            </Section>
            <Section>
              <Grid>
                <Grid.Column flex='none'><Section width='17'><p className='warranty-bswm-text is-xs'>6.</p></Section></Grid.Column>
                <Grid.Column flex='1'><p className='warranty-bswm-text is-xs'>Product failures wholly or in part due to the following causes are not covered by this warranty.</p></Grid.Column>
              </Grid>
            </Section>
            <Section paddingLeft='17' spacingBottom='5'>
              <WarrantyBSWMMalaysiaNotCoveredLists />
            </Section>
            <Section spacingBottom='5'>
              <Grid>
                <Grid.Column flex='none'><Section width='17'><p className='warranty-bswm-text is-xs'>7.</p></Section></Grid.Column>
                <Grid.Column flex='1'><p className='warranty-bswm-text is-xs'>Failure of the product from corrosion is defined as when the sheet is perforated. Surface rust, or minor corrosion at tension bends or cuts edges due to natural weathering does not constitute failure of the product.</p></Grid.Column>
              </Grid>
            </Section>
            <Section spacingBottom='5'>
              <Grid>
                <Grid.Column flex='none'><Section width='17'><p className='warranty-bswm-text is-xs'>8.</p></Section></Grid.Column>
                <Grid.Column flex='1'><p className='warranty-bswm-text is-xs'>Failure of the product in terms of fading and dirt staining is deﬁned as per the following table</p></Grid.Column>
              </Grid>
            </Section>
            <Section spacingBottom='5' paddingLeft='17'>
              <Table fluid warranty>
                <Table.Section>
                  <Table.Row>
                    <Table.Column className='is-head-column' alignCenter maxWidth='200' minWidth='200' paddingVertical='4' paddingHorizontal='4'>
                      <Section spacingTop='11'><p className='warranty-bswm-text is-xs is-bold'>Colour Shade</p></Section>
                    </Table.Column>
                    <Table.Column className='is-head-column' alignCenter maxWidth='200' minWidth='200' paddingVertical='4' paddingHorizontal='4'>
                      <p className='warranty-bswm-text is-xs is-bold'>Maximum Fading <br /> (ΔE units CIELAB) <br /> ASTM D-2244 (Cleaned)</p>
                    </Table.Column>
                    <Table.Column className='is-head-column' alignCenter maxWidth='200' minWidth='200' paddingVertical='4' paddingHorizontal='4'>
                      <p className='warranty-bswm-text is-xs is-bold'>Maximum Discolouration by <br /> Dirt Staining (ΔL units CIELAB) <br /> ASTM D-2244 (Cleaned)</p>
                    </Table.Column>
                  </Table.Row>
                </Table.Section>
                <Table.Section>
                  <Table.Row>
                    <Table.Column className='is-body-column' maxWidth='200' minWidth='200' paddingVertical='4' paddingHorizontal='4'>
                      <p className='warranty-bswm-text is-xs'>Light (e.g., Coral White)</p>
                    </Table.Column>
                    <Table.Column className='is-body-column' maxWidth='200' minWidth='200' paddingVertical='4' paddingHorizontal='4'>
                      <Section><p className='warranty-bswm-text is-xs is-center'>6</p></Section>
                    </Table.Column>
                    <Table.Column className='is-body-column' maxWidth='200' minWidth='200' paddingVertical='4' paddingHorizontal='4'>
                      <Section><p className='warranty-bswm-text is-xs is-center'>≥ -4</p></Section>
                    </Table.Column>
                  </Table.Row>
                  <Table.Row>
                    <Table.Column className='is-body-column' maxWidth='200' minWidth='200' paddingVertical='4' paddingHorizontal='4'>
                      <p className='warranty-bswm-text is-xs'>Intermediate (e.g., Palm Green)</p>
                    </Table.Column>
                    <Table.Column className='is-body-column' maxWidth='200' minWidth='200' paddingVertical='4' paddingHorizontal='4'>
                      <Section><p className='warranty-bswm-text is-xs is-center'>7</p></Section>
                    </Table.Column>
                    <Table.Column className='is-body-column' maxWidth='200' minWidth='200' paddingVertical='4' paddingHorizontal='4'>
                      <Section><p className='warranty-bswm-text is-xs is-center'>≥ -3</p></Section>
                    </Table.Column>
                  </Table.Row>
                  <Table.Row>
                    <Table.Column className='is-body-column' maxWidth='600' minWidth='600' paddingVertical='4' paddingHorizontal='4'>
                      <Section>
                        <p className='warranty-bswm-text is-xs is-center'>Colour Shade: L{'<'}40 is Dark, L between 40 and 70 is Intermediate and L{'>'}70 is Ligh</p>
                      </Section>
                    </Table.Column>
                  </Table.Row>
                </Table.Section>
              </Table>
            </Section>
            <Section paddingLeft='17'>
              <p className='warranty-bswm-text is-xs'>Ref : 04-0620-T6-T8</p>
            </Section>
          </React.Fragment>
        }
        {isCCB_XPD_PSC_MY_P15XS &&
          <React.Fragment>
            <Section spacingBottom='5'><p className='warranty-bswm-text is-xs'>This warranty is your guarantee that <span className='warranty-bswm-text is-xs is-bold'>COLORBOND® XPD Pearlescent</span> prepainted steel, if properly installed and maintained, will meet minimum expected performance levels for at least the warranted period (commencing from the date of installation).</p></Section>
            <Section spacingBottom='5'><p className='warranty-bswm-text is-xs'>However, certain specific problems and situations (listed below) must be addressed and rectified early in order to get the best possible performance and, if not rectified, may result in the warranty being void.</p></Section>
            <Section spacingBottom='5'>
              <Grid>
                <Grid.Column flex='none'><Section width='17'><p className='warranty-bswm-text is-xs'>1.</p></Section></Grid.Column>
                <Grid.Column flex='1'><p className='warranty-bswm-text is-xs'>The warranty applies only to cladding and flashing made from prime quality <span className='warranty-bswm-text is-xs is-bold'>COLORBOND® XPD Pearlescent</span> steel. The Owner/Contractor is advised to check for the <span className='warranty-bswm-text is-xs is-bold'>COLORBOND® XPD Pearlescent</span> steel brand name printed on the reverse of the sheets prior to installation. Guttering, downpipes, brackets, doors and other accessories are excluded.</p></Grid.Column>
              </Grid>
            </Section>
            <Section>
              <Grid>
                <Grid.Column flex='none'><Section width='17'><p className='warranty-bswm-text is-xs'>2.</p></Section></Grid.Column>
                <Grid.Column flex='1'><p className='warranty-bswm-text is-xs'>Cladding must be handled and installed in accordance with published BlueScope guidelines current at the time of installation, with particular attention to the issues listed below:-</p></Grid.Column>
              </Grid>
            </Section>
            <Section paddingLeft='17' spacingBottom='5'>
              <WarrantyBSWMMalaysiaCladdingIssuesLists />
            </Section>
            <Section spacingBottom='5'>
              <Grid>
                <Grid.Column flex='none'><Section width='17'><p className='warranty-bswm-text is-xs'>3.</p></Section></Grid.Column>
                <Grid.Column flex='1'><p className='warranty-bswm-text is-xs'>Fasteners must comply with AS3566 Class 3 or Class 4, and be certified as such by the supplier of fasteners.</p></Grid.Column>
              </Grid>
            </Section>
            <Section spacingBottom='5'>
              <Grid>
                <Grid.Column flex='none'><Section width='17'><p className='warranty-bswm-text is-xs'>4.</p></Section></Grid.Column>
                <Grid.Column flex='1'><p className='warranty-bswm-text is-xs'>All fixings, flashings and associated components must be manufactured from compatible metals (<span className='warranty-bswm-text is-xs is-bold'>COLORBOND®</span>, <span className='warranty-bswm-text is-xs is-bold'>ZINCALUME®</span> steel, zinc, or aluminium) or be coated or plated in accordance with current published BlueScope guidelines. For long lasting consistency in appearance, flashings should be made from the same material as the cladding.</p></Grid.Column>
              </Grid>
            </Section>
            <Section spacingBottom='5'>
              <Grid>
                <Grid.Column flex='none'><Section width='17'><p className='warranty-bswm-text is-xs'>5.</p></Section></Grid.Column>
                <Grid.Column flex='1'><p className='warranty-bswm-text is-xs'>The paint finish as supplied must not be scratched, abraded damaged, or coated with any foreign matter or incompatible material (including stack and flue deposits).</p></Grid.Column>
              </Grid>
            </Section>
            <Section>
              <Grid>
                <Grid.Column flex='none'><Section width='17'><p className='warranty-bswm-text is-xs'>6.</p></Section></Grid.Column>
                <Grid.Column flex='1'><p className='warranty-bswm-text is-xs'>Product failures wholly or in part due to the following causes are not covered by this warranty.</p></Grid.Column>
              </Grid>
            </Section>
            <Section paddingLeft='17' spacingBottom='5'>
              <WarrantyBSWMMalaysiaNotCoveredLists />
            </Section>
            <Section spacingBottom='5'>
              <Grid>
                <Grid.Column flex='none'><Section width='17'><p className='warranty-bswm-text is-xs'>7.</p></Section></Grid.Column>
                <Grid.Column flex='1'><p className='warranty-bswm-text is-xs'>Failure of the product from corrosion is defined as when the sheet is perforated. Surface rust, or minor corrosion at tension bends or cuts edges due to natural weathering does not constitute failure of the product.</p></Grid.Column>
              </Grid>
            </Section>
            <Section spacingBottom='5'>
              <Grid>
                <Grid.Column flex='none'><Section width='17'><p className='warranty-bswm-text is-xs'>8.</p></Section></Grid.Column>
                <Grid.Column flex='1'><p className='warranty-bswm-text is-xs'>Failure of the product in terms of fading and dirt staining is deﬁned as per the following table.</p></Grid.Column>
              </Grid>
            </Section>
            <Section spacingBottom='5' paddingLeft='17'>
              <Table fluid warranty>
                <Table.Section>
                  <Table.Row>
                    <Table.Column className='is-head-column' alignCenter maxWidth='200' minWidth='200' paddingVertical='4' paddingHorizontal='4'>
                      <Section spacingTop='11'><p className='warranty-bswm-text is-xs is-bold'>Colour Shade</p></Section>
                    </Table.Column>
                    <Table.Column className='is-head-column' alignCenter maxWidth='200' minWidth='200' paddingVertical='4' paddingHorizontal='4'>
                      <p className='warranty-bswm-text is-xs is-bold'>Maximum Fading <br /> (ΔE units CIELAB) <br /> ASTM D-2244 (Cleaned)</p>
                    </Table.Column>
                    <Table.Column className='is-head-column' alignCenter maxWidth='200' minWidth='200' paddingVertical='4' paddingHorizontal='4'>
                      <p className='warranty-bswm-text is-xs is-bold'>Maximum Discolouration by <br /> Dirt Staining (ΔL units CIELAB) <br /> ASTM D-2244 (Cleaned)</p>
                    </Table.Column>
                  </Table.Row>
                </Table.Section>
                <Table.Section>
                  <Table.Row>
                    <Table.Column className='is-body-column' maxWidth='200' minWidth='200' paddingVertical='4' paddingHorizontal='4'>
                      <p className='warranty-bswm-text is-xs'>Light (e.g., Silver Coin)</p>
                    </Table.Column>
                    <Table.Column className='is-body-column' maxWidth='200' minWidth='200' paddingVertical='4' paddingHorizontal='4'>
                      <Section><p className='warranty-bswm-text is-xs is-center'>6</p></Section>
                    </Table.Column>
                    <Table.Column className='is-body-column' maxWidth='200' minWidth='200' paddingVertical='4' paddingHorizontal='4'>
                      <Section><p className='warranty-bswm-text is-xs is-center'>≥ -4</p></Section>
                    </Table.Column>
                  </Table.Row>
                  <Table.Row>
                    <Table.Column className='is-body-column' maxWidth='200' minWidth='200' paddingVertical='4' paddingHorizontal='4'>
                      <p className='warranty-bswm-text is-xs'>Intermediate (e.g., Copper Penny)</p>
                    </Table.Column>
                    <Table.Column className='is-body-column' maxWidth='200' minWidth='200' paddingVertical='4' paddingHorizontal='4'>
                      <Section><p className='warranty-bswm-text is-xs is-center'>7</p></Section>
                    </Table.Column>
                    <Table.Column className='is-body-column' maxWidth='200' minWidth='200' paddingVertical='4' paddingHorizontal='4'>
                      <Section><p className='warranty-bswm-text is-xs is-center'>≥ -3</p></Section>
                    </Table.Column>
                  </Table.Row>
                  <Table.Row>
                    <Table.Column className='is-body-column' maxWidth='600' minWidth='600' paddingVertical='4' paddingHorizontal='4'>
                      <Section>
                        <p className='warranty-bswm-text is-xs is-center'>Colour Shade: L{'<'}40 is Dark, L between 40 and 70 is Intermediate and L{'>'}70 is Light</p>
                      </Section>
                    </Table.Column>
                  </Table.Row>
                </Table.Section>
              </Table>
            </Section>
            <Section paddingLeft='17'>
              <p className='warranty-bswm-text is-xs'>Ref : 04-0620-T6-T8</p>
            </Section>
          </React.Fragment>
        }
        {isCCB_XRW_MY_P15XR &&
          <React.Fragment>
            <Section spacingBottom='5'><p className='warranty-bswm-text is-xs'>This warranty is your guarantee that <span className='warranty-bswm-text is-xs is-bold'>COLORBOND®</span> prepainted steel, if properly installed and maintained, will meet minimum expected performance levels for at least the warranted period (commencing from the date of installation).</p></Section>
            <Section spacingBottom='5'><p className='warranty-bswm-text is-xs'>However, certain specific problems and situations (listed below) must be addressed and rectified early in order to get the best possible performance and, if not rectified, may result in the warranty being void.</p></Section>
            <Section spacingBottom='5'>
              <Grid>
                <Grid.Column flex='none'><Section width='17'><p className='warranty-bswm-text is-xs'>1.</p></Section></Grid.Column>
                <Grid.Column flex='1'><p className='warranty-bswm-text is-xs'>The warranty applies only to cladding and flashing made from prime quality <span className='warranty-bswm-text is-xs is-bold'>COLORBOND®</span> steel. The Owner/Contractor is advised to check for the <span className='warranty-bswm-text is-xs is-bold'>COLORBOND®</span> steel brand name printed on the reverse of the sheets prior to installation. Guttering, downpipes, brackets, doors and other accessories are excluded.</p></Grid.Column>
              </Grid>
            </Section>
            <Section>
              <Grid>
                <Grid.Column flex='none'><Section width='17'><p className='warranty-bswm-text is-xs'>2.</p></Section></Grid.Column>
                <Grid.Column flex='1'><p className='warranty-bswm-text is-xs'>Cladding must be handled and installed in accordance with published BlueScope guidelines current at the time of installation, with particular attention to the issues listed below:-</p></Grid.Column>
              </Grid>
            </Section>
            <Section paddingLeft='17' spacingBottom='5'>
              <WarrantyBSWMMalaysiaCladdingIssuesLists />
            </Section>
            <Section spacingBottom='5'>
              <Grid>
                <Grid.Column flex='none'><Section width='17'><p className='warranty-bswm-text is-xs'>3.</p></Section></Grid.Column>
                <Grid.Column flex='1'><p className='warranty-bswm-text is-xs'>Fasteners must comply with AS3566 Class 3 or Class 4, and be certified as such by the supplier of fasteners.</p></Grid.Column>
              </Grid>
            </Section>
            <Section spacingBottom='5'>
              <Grid>
                <Grid.Column flex='none'><Section width='17'><p className='warranty-bswm-text is-xs'>4.</p></Section></Grid.Column>
                <Grid.Column flex='1'><p className='warranty-bswm-text is-xs'>All fixings, flashings and associated components must be manufactured from compatible metals (COLORBOND®, ZINCALUME® steel, zinc, or aluminium) or be coated or plated in accordance with current published BlueScope guidelines. For long lasting consistency in appearance, flashings should be made from the same material as the cladding.</p></Grid.Column>
              </Grid>
            </Section>
            <Section spacingBottom='5'>
              <Grid>
                <Grid.Column flex='none'><Section width='17'><p className='warranty-bswm-text is-xs'>5.</p></Section></Grid.Column>
                <Grid.Column flex='1'><p className='warranty-bswm-text is-xs'>The paint finish as supplied must not be scratched, abraded damaged, or coated with any foreign matter or incompatible material (including stack and flue deposits).</p></Grid.Column>
              </Grid>
            </Section>
            <Section>
              <Grid>
                <Grid.Column flex='none'><Section width='17'><p className='warranty-bswm-text is-xs'>6.</p></Section></Grid.Column>
                <Grid.Column flex='1'><p className='warranty-bswm-text is-xs'>Product failures wholly or in part due to the following causes are not covered by this warranty.</p></Grid.Column>
              </Grid>
            </Section>
            <Section paddingLeft='17' spacingBottom='5'>
              <WarrantyBSWMMalaysiaNotCoveredLists />
            </Section>
            <Section spacingBottom='5'>
              <Grid>
                <Grid.Column flex='none'><Section width='17'><p className='warranty-bswm-text is-xs'>7.</p></Section></Grid.Column>
                <Grid.Column flex='1'><p className='warranty-bswm-text is-xs'>Failure of the product from corrosion is defined as when the sheet is perforated. Surface rust, or minor corrosion at tension bends or cuts edges due to natural weathering does not constitute failure of the product.</p></Grid.Column>
              </Grid>
            </Section>
            <Section spacingBottom='5'>
              <Grid>
                <Grid.Column flex='none'><Section width='17'><p className='warranty-bswm-text is-xs'>8.</p></Section></Grid.Column>
                <Grid.Column flex='1'><p className='warranty-bswm-text is-xs'>Failure of the product in terms of fading and dirt staining is deﬁned as per the following table.</p></Grid.Column>
              </Grid>
            </Section>
            <Section spacingBottom='5' paddingLeft='17'>
              <Table fluid warranty>
                <Table.Section>
                  <Table.Row>
                    <Table.Column className='is-head-column' alignCenter maxWidth='200' minWidth='200' paddingVertical='4' paddingHorizontal='4'>
                      <Section spacingTop='11'><p className='warranty-bswm-text is-xs is-bold'>Colour Shade</p></Section>
                    </Table.Column>
                    <Table.Column className='is-head-column' alignCenter maxWidth='200' minWidth='200' paddingVertical='4' paddingHorizontal='4'>
                      <p className='warranty-bswm-text is-xs is-bold'>Maximum Fading <br /> (ΔE units CIELAB) <br /> ASTM D-2244 (Cleaned)</p>
                    </Table.Column>
                    <Table.Column className='is-head-column' alignCenter maxWidth='200' minWidth='200' paddingVertical='4' paddingHorizontal='4'>
                      <p className='warranty-bswm-text is-xs is-bold'>Maximum Discolouration by <br /> Dirt Staining (ΔL units CIELAB) <br /> ASTM D-2244 (Cleaned)</p>
                    </Table.Column>
                  </Table.Row>
                </Table.Section>
                <Table.Section>
                  <Table.Row>
                    <Table.Column className='is-body-column' maxWidth='200' minWidth='200' paddingVertical='4' paddingHorizontal='4'>
                      <p className='warranty-bswm-text is-xs'>Light (e.g., Off White)</p>
                    </Table.Column>
                    <Table.Column className='is-body-column' maxWidth='200' minWidth='200' paddingVertical='4' paddingHorizontal='4'>
                      <Section><p className='warranty-bswm-text is-xs is-center'>6</p></Section>
                    </Table.Column>
                    <Table.Column className='is-body-column' maxWidth='200' minWidth='200' paddingVertical='4' paddingHorizontal='4'>
                      <Section><p className='warranty-bswm-text is-xs is-center'>≥ -4</p></Section>
                    </Table.Column>
                  </Table.Row>
                  <Table.Row>
                    <Table.Column className='is-body-column' maxWidth='200' minWidth='200' paddingVertical='4' paddingHorizontal='4'>
                      <p className='warranty-bswm-text is-xs'>Intermediate (e.g., Armour Grey)</p>
                    </Table.Column>
                    <Table.Column className='is-body-column' maxWidth='200' minWidth='200' paddingVertical='4' paddingHorizontal='4'>
                      <Section><p className='warranty-bswm-text is-xs is-center'>9</p></Section>
                    </Table.Column>
                    <Table.Column className='is-body-column' maxWidth='200' minWidth='200' paddingVertical='4' paddingHorizontal='4'>
                      <Section><p className='warranty-bswm-text is-xs is-center'>≥ -3</p></Section>
                    </Table.Column>
                  </Table.Row>
                  <Table.Row>
                    <Table.Column className='is-body-column' maxWidth='200' minWidth='200' paddingVertical='4' paddingHorizontal='4'>
                      <p className='warranty-bswm-text is-xs'>Dark (e.g., Cape Charcoal)</p>
                    </Table.Column>
                    <Table.Column className='is-body-column' maxWidth='200' minWidth='200' paddingVertical='4' paddingHorizontal='4'>
                      <Section><p className='warranty-bswm-text is-xs is-center'>15</p></Section>
                    </Table.Column>
                    <Table.Column className='is-body-column' maxWidth='200' minWidth='200' paddingVertical='4' paddingHorizontal='4'>
                      <Section><p className='warranty-bswm-text is-xs is-center'>≥ -2</p></Section>
                    </Table.Column>
                  </Table.Row>
                  <Table.Row>
                    <Table.Column className='is-body-column' maxWidth='600' minWidth='600' paddingVertical='4' paddingHorizontal='4'>
                      <Section>
                        <p className='warranty-bswm-text is-xs is-center'>Colour Shade: L{'<'}40 is Dark, L between 40 and 70 is Intermediate and L{'>'}70 is Light</p>
                      </Section>
                    </Table.Column>
                  </Table.Row>
                </Table.Section>
              </Table>
            </Section>
            <Section paddingLeft='17'>
              <p className='warranty-bswm-text is-xs'>Ref : 04-0620-T6-T8</p>
            </Section>
          </React.Fragment>
        }
        {isPM_MY_P2PRM &&
          <React.Fragment>
            <Section spacingBottom='5'><p className='warranty-bswm-text is-xs'>This warranty is your guarantee that <span className='warranty-bswm-text is-xs is-bold'>PRIMAMAJU®</span> prepainted steel, if properly installed and maintained, will meet minimum expected performance levels for at least the warranted period (commencing from the date of installation).</p></Section>
            <Section spacingBottom='5'><p className='warranty-bswm-text is-xs'>However, certain specific problems and situations (listed below) must be addressed and rectified early in order to get the best possible performance and, if not rectified, may result in the warranty being void.</p></Section>
            <Section spacingBottom='5'>
              <Grid>
                <Grid.Column flex='none'><Section width='17'><p className='warranty-bswm-text is-xs'>1.</p></Section></Grid.Column>
                <Grid.Column flex='1'><p className='warranty-bswm-text is-xs'>The warranty applies only to cladding and flashing made from prime quality <span className='warranty-bswm-text is-xs is-bold'>PRIMAMAJU®</span> steel. The Owner/Contractor is advised to check for the <span className='warranty-bswm-text is-xs is-bold'>PRIMAMAJU®</span> steel brand name printed on the reverse of the sheets prior to installation. Guttering, downpipes, brackets, doors and other accessories are excluded.</p></Grid.Column>
              </Grid>
            </Section>
            <Section>
              <Grid>
                <Grid.Column flex='none'><Section width='17'><p className='warranty-bswm-text is-xs'>2.</p></Section></Grid.Column>
                <Grid.Column flex='1'><p className='warranty-bswm-text is-xs'>Cladding must be handled and installed in accordance with published BlueScope guidelines current at the time of installation, with particular attention to the issues listed below:-</p></Grid.Column>
              </Grid>
            </Section>
            <Section paddingLeft='17' spacingBottom='5'>
              <WarrantyBSWMMalaysiaCladdingIssuesLists />
            </Section>
            <Section spacingBottom='5'>
              <Grid>
                <Grid.Column flex='none'><Section width='17'><p className='warranty-bswm-text is-xs'>3.</p></Section></Grid.Column>
                <Grid.Column flex='1'><p className='warranty-bswm-text is-xs'>Fasteners must comply with AS3566 Class 3 or Class 4, and be certified as such by the supplier of fasteners.</p></Grid.Column>
              </Grid>
            </Section>
            <Section spacingBottom='5'>
              <Grid>
                <Grid.Column flex='none'><Section width='17'><p className='warranty-bswm-text is-xs'>4.</p></Section></Grid.Column>
                <Grid.Column flex='1'><p className='warranty-bswm-text is-xs'>All fixings, flashings and associated components must be manufactured from compatible metals (<span className='warranty-bswm-text is-xs is-bold'>PRIMAMAJU®</span>, <span className='warranty-bswm-text is-xs is-bold'>ZINCALUME®</span> steel, zinc, or aluminium) or be coated or plated in accordance with current published BlueScope guidelines. For long lasting consistency in appearance, flashings should be made from the same material as the cladding.</p></Grid.Column>
              </Grid>
            </Section>
            <Section spacingBottom='5'>
              <Grid>
                <Grid.Column flex='none'><Section width='17'><p className='warranty-bswm-text is-xs'>5.</p></Section></Grid.Column>
                <Grid.Column flex='1'><p className='warranty-bswm-text is-xs'>The paint finish as supplied must not be scratched, abraded damaged, or coated with any foreign matter or incompatible material (including stack and flue deposits).</p></Grid.Column>
              </Grid>
            </Section>
            <Section>
              <Grid>
                <Grid.Column flex='none'><Section width='17'><p className='warranty-bswm-text is-xs'>6.</p></Section></Grid.Column>
                <Grid.Column flex='1'><p className='warranty-bswm-text is-xs'>Product failures wholly or in part due to the following causes are not covered by this warranty.</p></Grid.Column>
              </Grid>
            </Section>
            <Section paddingLeft='17' spacingBottom='5'>
              <WarrantyBSWMMalaysiaNotCoveredLists />
            </Section>
            <Section spacingBottom='5'>
              <Grid>
                <Grid.Column flex='none'><Section width='17'><p className='warranty-bswm-text is-xs'>7.</p></Section></Grid.Column>
                <Grid.Column flex='1'><p className='warranty-bswm-text is-xs'>Failure of the product from corrosion is defined as when the sheet is perforated. Surface rust, or minor corrosion at tension bends or cuts edges due to natural weathering does not constitute failure of the product.</p></Grid.Column>
              </Grid>
            </Section>
            <Section spacingBottom='5'>
              <Grid>
                <Grid.Column flex='none'><Section width='17'><p className='warranty-bswm-text is-xs'>8.</p></Section></Grid.Column>
                <Grid.Column flex='1'><p className='warranty-bswm-text is-xs'>Failure of the product in terms of fading is deﬁned as per the following table.</p></Grid.Column>
              </Grid>
            </Section>
            <Section spacingBottom='5' paddingLeft='17'>
              <Table fluid warranty>
                <Table.Section>
                  <Table.Row>
                    <Table.Column className='is-head-column' alignCenter maxWidth='230' minWidth='230' paddingVertical='4' paddingHorizontal='4'>
                      <Section spacingTop='11'><p className='warranty-bswm-text is-xs is-bold'>Colour Shade</p></Section>
                    </Table.Column>
                    <Table.Column className='is-head-column' alignCenter maxWidth='230' minWidth='230' paddingVertical='4' paddingHorizontal='4'>
                      <p className='warranty-bswm-text is-xs is-bold'>Maximum Fading <br /> (ΔE units CIELAB) <br /> ASTM D-2244 (Cleaned)</p>
                    </Table.Column>
                  </Table.Row>
                </Table.Section>
                <Table.Section>
                  <Table.Row>
                    <Table.Column className='is-body-column' maxWidth='230' minWidth='230' paddingVertical='4' paddingHorizontal='4'>
                      <p className='warranty-bswm-text is-xs'>Light (e.g., Prima Awana)</p>
                    </Table.Column>
                    <Table.Column className='is-body-column' maxWidth='230' minWidth='230' paddingVertical='4' paddingHorizontal='4'>
                      <Section><p className='warranty-bswm-text is-xs is-center'>5 (∆L units CIELAB)</p></Section>
                    </Table.Column>
                  </Table.Row>
                  <Table.Row>
                    <Table.Column className='is-body-column' maxWidth='230' minWidth='230' paddingVertical='4' paddingHorizontal='4'>
                      <p className='warranty-bswm-text is-xs'>Intermediate (e.g., Prima Cendana)</p>
                    </Table.Column>
                    <Table.Column className='is-body-column' maxWidth='230' minWidth='230' paddingVertical='4' paddingHorizontal='4'>
                      <Section><p className='warranty-bswm-text is-xs is-center'>9 (∆E units CIELAB)</p></Section>
                    </Table.Column>
                  </Table.Row>
                  <Table.Row>
                    <Table.Column className='is-body-column' maxWidth='230' minWidth='230' paddingVertical='4' paddingHorizontal='4'>
                      <p className='warranty-bswm-text is-xs'>Dark (e.g., Prima Muara)</p>
                    </Table.Column>
                    <Table.Column className='is-body-column' maxWidth='230' minWidth='230' paddingVertical='4' paddingHorizontal='4'>
                      <Section><p className='warranty-bswm-text is-xs is-center'>15 (∆E units CIELAB)</p></Section>
                    </Table.Column>
                  </Table.Row>
                  <Table.Row>
                    <Table.Column className='is-body-column' maxWidth='460' minWidth='460' paddingVertical='4' paddingHorizontal='4'>
                      <Section>
                        <p className='warranty-bswm-text is-xs is-center'>Colour Shade: L{'<'}40 is Dark, L between 40 and 70 is Intermediate and L{'>'}70 is Ligh</p>
                      </Section>
                    </Table.Column>
                  </Table.Row>
                </Table.Section>
              </Table>
            </Section>
            <Section paddingLeft='17'>
              <p className='warranty-bswm-text is-xs'>Ref : 00-012020</p>
            </Section>
          </React.Fragment>
        }
        {isPM_R_MY_P2PRJ &&
          <React.Fragment>
            <Section spacingBottom='5'><p className='warranty-bswm-text is-xs'>This warranty is your guarantee that <span className='warranty-bswm-text is-xs is-bold'>PRIMAMAJU-R®</span> prepainted steel, if properly installed and maintained, will meet minimum expected performance levels for at least the warranted period (commencing from the date of installation).</p></Section>
            <Section spacingBottom='5'><p className='warranty-bswm-text is-xs'>However, certain specific problems and situations (listed below) must be addressed and rectified early in order to get the best possible performance and, if not rectified, may result in the warranty being void.</p></Section>
            <Section spacingBottom='5'>
              <Grid>
                <Grid.Column flex='none'><Section width='17'><p className='warranty-bswm-text is-xs'>1.</p></Section></Grid.Column>
                <Grid.Column flex='1'><p className='warranty-bswm-text is-xs'>The warranty applies only to cladding and flashing made from prime quality <span className='warranty-bswm-text is-xs is-bold'>PRIMAMAJU-R®</span> steel. The Owner/Contractor is advised to check for the <span className='warranty-bswm-text is-xs is-bold'>PRIMAMAJU-R®</span> steel brand name printed on the reverse of the sheets prior to installation. Guttering, downpipes, brackets, doors and other accessories are excluded.</p></Grid.Column>
              </Grid>
            </Section>
            <Section>
              <Grid>
                <Grid.Column flex='none'><Section width='17'><p className='warranty-bswm-text is-xs'>2.</p></Section></Grid.Column>
                <Grid.Column flex='1'><p className='warranty-bswm-text is-xs'>Cladding must be handled and installed in accordance with published BlueScope guidelines current at the time of installation, with particular attention to the issues listed below:-</p></Grid.Column>
              </Grid>
            </Section>
            <Section paddingLeft='17' spacingBottom='5'>
              <WarrantyBSWMMalaysiaCladdingIssuesLists />
            </Section>
            <Section spacingBottom='5'>
              <Grid>
                <Grid.Column flex='none'><Section width='17'><p className='warranty-bswm-text is-xs'>3.</p></Section></Grid.Column>
                <Grid.Column flex='1'><p className='warranty-bswm-text is-xs'>Fasteners must comply with AS3566 Class 3 or Class 4, and be certified as such by the supplier of fasteners.</p></Grid.Column>
              </Grid>
            </Section>
            <Section spacingBottom='5'>
              <Grid>
                <Grid.Column flex='none'><Section width='17'><p className='warranty-bswm-text is-xs'>4.</p></Section></Grid.Column>
                <Grid.Column flex='1'><p className='warranty-bswm-text is-xs'>All fixings, flashings and associated components must be manufactured from compatible metals (<span className='warranty-bswm-text is-xs is-bold'>PRIMAMAJU-R®</span>, <span className='warranty-bswm-text is-xs is-bold'>ZINCALUME®</span> steel, zinc, or aluminium) or be coated or plated in accordance with current published BlueScope guidelines. For long lasting consistency in appearance, flashings should be made from the same material as the cladding.</p></Grid.Column>
              </Grid>
            </Section>
            <Section spacingBottom='5'>
              <Grid>
                <Grid.Column flex='none'><Section width='17'><p className='warranty-bswm-text is-xs'>5.</p></Section></Grid.Column>
                <Grid.Column flex='1'><p className='warranty-bswm-text is-xs'>The paint finish as supplied must not be scratched, abraded damaged, or coated with any foreign matter or incompatible material (including stack and flue deposits).</p></Grid.Column>
              </Grid>
            </Section>
            <Section>
              <Grid>
                <Grid.Column flex='none'><Section width='17'><p className='warranty-bswm-text is-xs'>6.</p></Section></Grid.Column>
                <Grid.Column flex='1'><p className='warranty-bswm-text is-xs'>Product failures wholly or in part due to the following causes are not covered by this warranty.</p></Grid.Column>
              </Grid>
            </Section>
            <Section paddingLeft='17' spacingBottom='5'>
              <WarrantyBSWMMalaysiaNotCoveredLists />
            </Section>
            <Section spacingBottom='5'>
              <Grid>
                <Grid.Column flex='none'><Section width='17'><p className='warranty-bswm-text is-xs'>7.</p></Section></Grid.Column>
                <Grid.Column flex='1'><p className='warranty-bswm-text is-xs'>Failure of the product from corrosion is defined as when the sheet is perforated. Surface rust, or minor corrosion at tension bends or cuts edges due to natural weathering does not constitute failure of the product.</p></Grid.Column>
              </Grid>
            </Section>
            <Section spacingBottom='5'>
              <Grid>
                <Grid.Column flex='none'><Section width='17'><p className='warranty-bswm-text is-xs'>8.</p></Section></Grid.Column>
                <Grid.Column flex='1'><p className='warranty-bswm-text is-xs'>Failure of the product in terms of fading is deﬁned as per the following table.</p></Grid.Column>
              </Grid>
            </Section>
            <Section spacingBottom='5' paddingLeft='17'>
              <Table fluid warranty>
                <Table.Section>
                  <Table.Row>
                    <Table.Column className='is-head-column' alignCenter maxWidth='230' minWidth='230' paddingVertical='4' paddingHorizontal='4'>
                      <Section spacingTop='11'><p className='warranty-bswm-text is-xs is-bold'>Colour Shade</p></Section>
                    </Table.Column>
                    <Table.Column className='is-head-column' alignCenter maxWidth='230' minWidth='230' paddingVertical='4' paddingHorizontal='4'>
                      <p className='warranty-bswm-text is-xs is-bold'>Maximum Fading <br /> (ΔE units CIELAB) <br /> ASTM D-2244 (Cleaned)</p>
                    </Table.Column>
                  </Table.Row>
                </Table.Section>
                <Table.Section>
                  <Table.Row>
                    <Table.Column className='is-body-column' maxWidth='230' minWidth='230' paddingVertical='4' paddingHorizontal='4'>
                      <p className='warranty-bswm-text is-xs'>Light (e.g., Awana)</p>
                    </Table.Column>
                    <Table.Column className='is-body-column' maxWidth='230' minWidth='230' paddingVertical='4' paddingHorizontal='4'>
                      <Section><p className='warranty-bswm-text is-xs is-center'>5 (∆L units CIELAB)</p></Section>
                    </Table.Column>
                  </Table.Row>
                  <Table.Row>
                    <Table.Column className='is-body-column' maxWidth='230' minWidth='230' paddingVertical='4' paddingHorizontal='4'>
                      <p className='warranty-bswm-text is-xs'>Intermediate (e.g., Cendana)</p>
                    </Table.Column>
                    <Table.Column className='is-body-column' maxWidth='230' minWidth='230' paddingVertical='4' paddingHorizontal='4'>
                      <Section><p className='warranty-bswm-text is-xs is-center'>9 (∆E units CIELAB)</p></Section>
                    </Table.Column>
                  </Table.Row>
                  <Table.Row>
                    <Table.Column className='is-body-column' maxWidth='230' minWidth='230' paddingVertical='4' paddingHorizontal='4'>
                      <p className='warranty-bswm-text is-xs'>Dark (e.g., Muara)</p>
                    </Table.Column>
                    <Table.Column className='is-body-column' maxWidth='230' minWidth='230' paddingVertical='4' paddingHorizontal='4'>
                      <Section><p className='warranty-bswm-text is-xs is-center'>15 (∆E units CIELAB)</p></Section>
                    </Table.Column>
                  </Table.Row>
                  <Table.Row>
                    <Table.Column className='is-body-column' maxWidth='460' minWidth='460' paddingVertical='4' paddingHorizontal='4'>
                      <Section>
                        <p className='warranty-bswm-text is-xs is-center'>Colour Shade: L{'<'}40 is Dark, L between 40 and 70 is Intermediate and L{'>'}70 is Ligh</p>
                      </Section>
                    </Table.Column>
                  </Table.Row>
                </Table.Section>
              </Table>
            </Section>
            <Section paddingLeft='17'>
              <p className='warranty-bswm-text is-xs'>Ref : 05-0620-T6-T8</p>
            </Section>
          </React.Fragment>
        }
        {isTRUECORE_MY_Z1TRC &&
          <React.Fragment>
            <Section spacingBottom='5'><p className='warranty-bswm-text is-xs'>Application of this warranty to construction is conditional upon the domestic dwellings and commercial buildings (“the host dwelling”) incorporating <span className='warranty-bswm-text is-xs is-bold'>TRUECORE®</span> steel Framing Componentry, and construction being carried out in accordance with good building practice. Good building practice includes, but <span className='warranty-bswm-text is-xs is-bold'>not limited</span> to:</p></Section>
            <Section spacingBottom='5'>
              <Grid>
                <Grid.Column flex='none'><Section width='17'><p className='warranty-bswm-text is-xs'>1.</p></Section></Grid.Column>
                <Grid.Column flex='1'><p className='warranty-bswm-text is-xs'>Framing Componentry being installed in accordance with manufacturer’s published recommendations current at the time of installation and compliant with the Building Code of Malaysia.</p></Grid.Column>
              </Grid>
            </Section>
            <Section spacingBottom='5'>
              <Grid>
                <Grid.Column flex='none'><Section width='17'><p className='warranty-bswm-text is-xs'>2.</p></Section></Grid.Column>
                <Grid.Column flex='1'><p className='warranty-bswm-text is-xs'>Installation of a damp proof course between bottom plates and concrete slabs were used.</p></Grid.Column>
              </Grid>
            </Section>
            <Section spacingBottom='5'>
              <Grid>
                <Grid.Column flex='none'><Section width='17'><p className='warranty-bswm-text is-xs'>3.</p></Section></Grid.Column>
                <Grid.Column flex='1'><p className='warranty-bswm-text is-xs'>Installation and ongoing maintenance (over the warranty period) of appropriate flashings and other water proofing treatments of any penetrations to eliminate water ingress.</p></Grid.Column>
              </Grid>
            </Section>
            <Section spacingBottom='5'>
              <Grid>
                <Grid.Column flex='none'><Section width='17'><p className='warranty-bswm-text is-xs'>4.</p></Section></Grid.Column>
                <Grid.Column flex='1'><p className='warranty-bswm-text is-xs'>Host dwelling being located more than 300 metres from breaking surf or more than 100 metres from still water.</p></Grid.Column>
              </Grid>
            </Section>
            <Section spacingBottom='5'>
              <Grid>
                <Grid.Column flex='none'><Section width='17'><p className='warranty-bswm-text is-xs'>5.</p></Section></Grid.Column>
                <Grid.Column flex='1'><p className='warranty-bswm-text is-xs'>Installation of fasteners which comply with relevant requirements prescribed in AS 3566 and other applicable standards, and;</p></Grid.Column>
              </Grid>
            </Section>
            <Section spacingBottom='10'>
              <Grid>
                <Grid.Column flex='none'><Section width='17'><p className='warranty-bswm-text is-xs'>6.</p></Section></Grid.Column>
                <Grid.Column flex='1'><p className='warranty-bswm-text is-xs'>No physical contact between “incompatible materials” such as copper pipes or treated timber in contact with Framing Componentry. (Details of all known incompatible materials can be obtained on request from NS BlueScope Malaysia.)</p></Grid.Column>
              </Grid>
            </Section>
            <Section spacingBottom='5'><p className='warranty-bswm-text is-xs'>Within the context of this warranty “enclosed within the building envelope“ is defined as all <span className='warranty-bswm-text is-xs is-bold'>TRUECORE®</span> steel Framing Componentry including, roof structure projections that extend beyond the walls of the domestic dwellings and commercial buildings creating uncontrolled voids or openings that expose Framing Componentry to the elements, being covered and entirely enclosed by building cladding materials such as bricks, aerated autoclaved cement or fibre cement sheet, appropriate eaves, porch, carport, porte-cochere linings and roofing cladding products such as COLORBOND® steel or concrete/terra-cotta tiles. Controlled voids are acceptable including wall and gable vents installed to manufacturers specifications. These should include durable flyscreen or similar mesh across the back face of the opening, secured around the perimeter of the vent or device.</p></Section>
            <Section spacingBottom='10'><p className='warranty-bswm-text is-xs is-italic'>Application of this warranty is dependent upon the manufacturer of the frame notifying NS BlueScope Malaysia by completing and submitting a warranty notification form prior to the despatch of the <span className='warranty-bswm-text is-xs is-bold is-italic'>TRUECORE®</span> steel frame to site. NS BlueScope Malaysia reserves the right to request access that allows inspection of the frame during the construction.</p></Section>
            <Section spacingBottom='5'><p className='warranty-bswm-text is-xs is-bold'>Building owner obligations include:</p></Section>
            <Section spacingBottom='5'>
              <Grid>
                <Grid.Column flex='none'><Section width='17'><p className='warranty-bswm-text is-xs'>1.</p></Section></Grid.Column>
                <Grid.Column flex='1'><p className='warranty-bswm-text is-xs'>Maintenance of internal linings and external cladding throughout the building and utility areas.</p></Grid.Column>
              </Grid>
            </Section>
            <Section spacingBottom='5'>
              <Grid>
                <Grid.Column flex='none'><Section width='17'><p className='warranty-bswm-text is-xs'>2.</p></Section></Grid.Column>
                <Grid.Column flex='1'><p className='warranty-bswm-text is-xs'>Repair of leaking pipes or overflowing PC items.</p></Grid.Column>
              </Grid>
            </Section>
            <Section spacingBottom='5'>
              <Grid>
                <Grid.Column flex='none'><Section width='17'><p className='warranty-bswm-text is-xs'>3.</p></Section></Grid.Column>
                <Grid.Column flex='1'><p className='warranty-bswm-text is-xs'>Immediate action to rectify conditions caused by wear and tear, weathering or other damage to building elements that can contribute to the degradation of the <span className='warranty-bswm-text is-xs is-bold'>TRUECORE®</span> steel frame.</p></Grid.Column>
              </Grid>
            </Section>
            <Section spacingBottom='10'>
              <Grid>
                <Grid.Column flex='none'><Section width='17'><p className='warranty-bswm-text is-xs'>4.</p></Section></Grid.Column>
                <Grid.Column flex='1'><p className='warranty-bswm-text is-xs'>Documentation of incidents that can contribute to degradation of the <span className='warranty-bswm-text is-xs is-bold'>TRUECORE®</span> steel frame which have occurred including a description and known duration of the condition.</p></Grid.Column>
              </Grid>
            </Section>
            <Section spacingBottom='5'><p className='warranty-bswm-text is-xs is-bold'>This warranty does not apply:</p></Section>
            <Section spacingBottom='5'>
              <Grid>
                <Grid.Column flex='none'><Section width='17'><p className='warranty-bswm-text is-xs'>1.</p></Section></Grid.Column>
                <Grid.Column flex='1'><p className='warranty-bswm-text is-xs'>To structural flooring systems or any composite wall or roof framing system made up of <span className='warranty-bswm-text is-xs is-bold'>TRUECORE®</span> steel and other materials such as concrete, timber or fibre cement sheeting back filled or sprayed with mortar or concrete aggregate.</p></Grid.Column>
              </Grid>
            </Section>
            <Section spacingBottom='10'>
              <Grid>
                <Grid.Column flex='none'><Section width='17'><p className='warranty-bswm-text is-xs'>2.</p></Section></Grid.Column>
                <Grid.Column flex='1'><p className='warranty-bswm-text is-xs'>Where leaking pipes, overflow of PC items or the inadequate maintenance of the internal lining or building envelope have resulted or contributed to degradation of the <span className='warranty-bswm-text is-xs is-bold'>TRUECORE®</span> steel framing members.</p></Grid.Column>
              </Grid>
            </Section>
            <Section spacingBottom='5'><p className='warranty-bswm-text is-xs is-bold'>Remedies for Breach of Warranty</p></Section>
            <Section spacingBottom='5'><p className='warranty-bswm-text is-xs'>Subject to the above, if any Framing Componentry suffers corrosion leading to structural failure during the warranty period, NS BlueScope Malaysia will, at its option:</p></Section>
            <Section spacingBottom='5'>
              <Grid>
                <Grid.Column flex='none'><Section width='17'><p className='warranty-bswm-text is-xs'>1.</p></Section></Grid.Column>
                <Grid.Column flex='1'><p className='warranty-bswm-text is-xs'>Supply sufficient <span className='warranty-bswm-text is-xs is-bold'>TRUECORE®</span> steel framing to replace the failed Framing Componentry; or equivalent material of suitable dimensions and structural adequacy to replace the original installation; or</p></Grid.Column>
              </Grid>
            </Section>
            <Section spacingBottom='10'>
              <Grid>
                <Grid.Column flex='none'><Section width='17'><p className='warranty-bswm-text is-xs'>2.</p></Section></Grid.Column>
                <Grid.Column flex='1'><p className='warranty-bswm-text is-xs'>Reimburse the cost of purchasing replacement <span className='warranty-bswm-text is-xs is-bold'>TRUECORE®</span> steel Framing Componentry.</p></Grid.Column>
              </Grid>
            </Section>
            <Section>
              <p className='warranty-bswm-text is-xs'>Ref : 00-0620</p>
            </Section>
          </React.Fragment>
        }
        {isVERMOE_MY_P1VRM &&
          <React.Fragment>
            <Section spacingBottom='5'><p className='warranty-bswm-text is-xs'>This warranty is your guarantee that <span className='warranty-bswm-text is-xs is-bold'>VERMOE™</span> prepainted steel, if properly installed and maintained, will meet minimum expected performance levels for at least the warranted period (commencing from the date of installation).</p></Section>
            <Section spacingBottom='5'><p className='warranty-bswm-text is-xs'>However, certain specific problems and situations (listed below) must be addressed and rectified early in order to get the best possible performance and, if not rectified, may result in the warranty being void.</p></Section>
            <Section spacingBottom='5'>
              <Grid>
                <Grid.Column flex='none'><Section width='17'><p className='warranty-bswm-text is-xs'>1.</p></Section></Grid.Column>
                <Grid.Column flex='1'><p className='warranty-bswm-text is-xs'>The warranty applies only to cladding and flashing made from prime quality <span className='warranty-bswm-text is-xs is-bold'>VERMOE™</span> steel. The Owner/Contractor is advised to check for the <span className='warranty-bswm-text is-xs is-bold'>VERMOE™</span> steel brand name printed on the reverse of the sheets prior to installation. Guttering, downpipes, brackets, doors and other accessories are excluded.</p></Grid.Column>
              </Grid>
            </Section>
            <Section>
              <Grid>
                <Grid.Column flex='none'><Section width='17'><p className='warranty-bswm-text is-xs'>2.</p></Section></Grid.Column>
                <Grid.Column flex='1'><p className='warranty-bswm-text is-xs'>Cladding must be handled and installed in accordance with published BlueScope guidelines current at the time of installation, with particular attention to the issues listed below:-</p></Grid.Column>
              </Grid>
            </Section>
            <Section paddingLeft='17' spacingBottom='5'>
              <WarrantyBSWMMalaysiaCladdingIssuesLists />
            </Section>
            <Section spacingBottom='5'>
              <Grid>
                <Grid.Column flex='none'><Section width='17'><p className='warranty-bswm-text is-xs'>3.</p></Section></Grid.Column>
                <Grid.Column flex='1'><p className='warranty-bswm-text is-xs'>Fasteners must comply with AS3566 Class 3 or Class 4, and be certified as such by the supplier of fasteners.</p></Grid.Column>
              </Grid>
            </Section>
            <Section spacingBottom='5'>
              <Grid>
                <Grid.Column flex='none'><Section width='17'><p className='warranty-bswm-text is-xs'>4.</p></Section></Grid.Column>
                <Grid.Column flex='1'><p className='warranty-bswm-text is-xs'>All fixings, flashings and associated components must be manufactured from compatible metals (<span className='warranty-bswm-text is-xs is-bold'>VERMOE™</span>, <span className='warranty-bswm-text is-xs is-bold'>ZINCALUME®</span> steel, zinc, or aluminium) or be coated or plated in accordance with current published BlueScope guidelines. For long lasting consistency in appearance, flashings should be made from the same material as the cladding.</p></Grid.Column>
              </Grid>
            </Section>
            <Section spacingBottom='5'>
              <Grid>
                <Grid.Column flex='none'><Section width='17'><p className='warranty-bswm-text is-xs'>5.</p></Section></Grid.Column>
                <Grid.Column flex='1'><p className='warranty-bswm-text is-xs'>The paint finish as supplied must not be scratched, abraded damaged, or coated with any foreign matter or incompatible material (including stack and flue deposits).</p></Grid.Column>
              </Grid>
            </Section>
            <Section>
              <Grid>
                <Grid.Column flex='none'><Section width='17'><p className='warranty-bswm-text is-xs'>6.</p></Section></Grid.Column>
                <Grid.Column flex='1'><p className='warranty-bswm-text is-xs'>Product failures wholly or in part due to the following causes are not covered by this warranty.</p></Grid.Column>
              </Grid>
            </Section>
            <Section paddingLeft='17' spacingBottom='5'>
              <WarrantyBSWMMalaysiaNotCoveredLists />
            </Section>
            <Section spacingBottom='5'>
              <Grid>
                <Grid.Column flex='none'><Section width='17'><p className='warranty-bswm-text is-xs'>7.</p></Section></Grid.Column>
                <Grid.Column flex='1'><p className='warranty-bswm-text is-xs'>Failure of the product from corrosion is defined as when the sheet is perforated. Surface rust, or minor corrosion at tension bends or cuts edges due to natural weathering does not constitute failure of the product.</p></Grid.Column>
              </Grid>
            </Section>
            <Section spacingBottom='5'>
              <Grid>
                <Grid.Column flex='none'><Section width='17'><p className='warranty-bswm-text is-xs'>8.</p></Section></Grid.Column>
                <Grid.Column flex='1'><p className='warranty-bswm-text is-xs'>Failure of the product in terms of fading is deﬁned as per the following table.</p></Grid.Column>
              </Grid>
            </Section>
            <Section spacingBottom='5' paddingLeft='17'>
              <Table fluid warranty>
                <Table.Section>
                  <Table.Row>
                    <Table.Column className='is-head-column' alignCenter maxWidth='230' minWidth='230' paddingVertical='4' paddingHorizontal='4'>
                      <Section spacingTop='11'><p className='warranty-bswm-text is-xs is-bold'>Colour Shade</p></Section>
                    </Table.Column>
                    <Table.Column className='is-head-column' alignCenter maxWidth='230' minWidth='230' paddingVertical='4' paddingHorizontal='4'>
                      <p className='warranty-bswm-text is-xs is-bold'>Maximum Fading <br /> (ΔE units CIELAB) <br /> ASTM D-2244 (Cleaned)</p>
                    </Table.Column>
                  </Table.Row>
                </Table.Section>
                <Table.Section>
                  <Table.Row>
                    <Table.Column className='is-body-column' maxWidth='230' minWidth='230' paddingVertical='4' paddingHorizontal='4'>
                      <p className='warranty-bswm-text is-xs'>Intermediate (e.g., Thunder Grey)</p>
                    </Table.Column>
                    <Table.Column className='is-body-column' maxWidth='230' minWidth='230' paddingVertical='4' paddingHorizontal='4'>
                      <Section><p className='warranty-bswm-text is-xs is-center'>10</p></Section>
                    </Table.Column>
                  </Table.Row>
                  <Table.Row>
                    <Table.Column className='is-body-column' maxWidth='230' minWidth='230' paddingVertical='4' paddingHorizontal='4'>
                      <p className='warranty-bswm-text is-xs'>Dark (e.g., Dazzling Black)</p>
                    </Table.Column>
                    <Table.Column className='is-body-column' maxWidth='230' minWidth='230' paddingVertical='4' paddingHorizontal='4'>
                      <Section><p className='warranty-bswm-text is-xs is-center'>15</p></Section>
                    </Table.Column>
                  </Table.Row>
                  <Table.Row>
                    <Table.Column className='is-body-column' maxWidth='460' minWidth='460' paddingVertical='4' paddingHorizontal='4'>
                      <Section>
                        <p className='warranty-bswm-text is-xs is-center'>Colour Shade: L{'<'}40 is Dark, L between 40 and 70 is Intermediate and L{'>'}70 is Ligh</p>
                      </Section>
                    </Table.Column>
                  </Table.Row>
                </Table.Section>
              </Table>
            </Section>
            <Section paddingLeft='17'>
              <p className='warranty-bswm-text is-xs'>Ref : 00-0620</p>
            </Section>
          </React.Fragment>
        }
        {isZL_MY_Z1ZAL &&
          <React.Fragment>
            <Section spacingBottom='5'><p className='warranty-bswm-text is-xs'>This warranty is your guarantee that <span className='warranty-bswm-text is-xs is-bold'>ZINCALUME®</span> steel, if properly installed and maintained, will meet minimum expected performance levels for at least the warranted period (commencing from the date of installation).</p></Section>
            <Section spacingBottom='5'><p className='warranty-bswm-text is-xs'>However, certain specific problems and situations (listed below) must be addressed and rectified early in order to get the best possible performance and, if not rectified, may result in the warranty being void.</p></Section>
            <Section spacingBottom='5'>
              <Grid>
                <Grid.Column flex='none'><Section width='17'><p className='warranty-bswm-text is-xs'>1.</p></Section></Grid.Column>
                <Grid.Column flex='1'><p className='warranty-bswm-text is-xs'>The warranty applies only to cladding and flashing made from prime quality <span className='warranty-bswm-text is-xs is-bold'>ZINCALUME®</span> steel. The Owner/Contractor is advised to check for the <span className='warranty-bswm-text is-xs is-bold'>ZINCALUME®</span> steel brand name printed on the reverse of the sheets prior to installation. Guttering, downpipes, brackets, doors and other accessories are excluded.</p></Grid.Column>
              </Grid>
            </Section>
            <Section>
              <Grid>
                <Grid.Column flex='none'><Section width='17'><p className='warranty-bswm-text is-xs'>2.</p></Section></Grid.Column>
                <Grid.Column flex='1'><p className='warranty-bswm-text is-xs'>Cladding must be handled and installed in accordance with published BlueScope guidelines current at the time of installation, with particular attention to the issues listed below:-</p></Grid.Column>
              </Grid>
            </Section>
            <Section paddingLeft='17' spacingBottom='5'>
              <WarrantyBSWMMalaysiaCladdingIssuesLists />
            </Section>
            <Section spacingBottom='5'>
              <Grid>
                <Grid.Column flex='none'><Section width='17'><p className='warranty-bswm-text is-xs'>3.</p></Section></Grid.Column>
                <Grid.Column flex='1'><p className='warranty-bswm-text is-xs'>Fasteners must comply with AS3566 Class 3 or Class 4, and be certified as such by the supplier of fasteners.</p></Grid.Column>
              </Grid>
            </Section>
            <Section spacingBottom='5'>
              <Grid>
                <Grid.Column flex='none'><Section width='17'><p className='warranty-bswm-text is-xs'>4.</p></Section></Grid.Column>
                <Grid.Column flex='1'><p className='warranty-bswm-text is-xs'>All fixings, flashings and associated components must be manufactured from compatible metals (<span className='warranty-bswm-text is-xs is-bold'>COLORBOND®</span>, <span className='warranty-bswm-text is-xs is-bold'>ZINCALUME®</span> steel, zinc, or aluminium) or be coated or plated in accordance with current published BlueScope guidelines. For long lasting consistency in appearance, flashings should be made from the same material as the cladding.</p></Grid.Column>
              </Grid>
            </Section>
            <Section spacingBottom='5'>
              <Grid>
                <Grid.Column flex='none'><Section width='17'><p className='warranty-bswm-text is-xs'>5.</p></Section></Grid.Column>
                <Grid.Column flex='1'><p className='warranty-bswm-text is-xs'>The coating finish as supplied must not be scratched, abraded damaged, or coated with any foreign matter or incompatible material (including stack and flue deposits).</p></Grid.Column>
              </Grid>
            </Section>
            <Section>
              <Grid>
                <Grid.Column flex='none'><Section width='17'><p className='warranty-bswm-text is-xs'>6.</p></Section></Grid.Column>
                <Grid.Column flex='1'><p className='warranty-bswm-text is-xs'>Product failures wholly or in part due to the following causes are not covered by this warranty.</p></Grid.Column>
              </Grid>
            </Section>
            <Section paddingLeft='17' spacingBottom='5'>
              <WarrantyBSWMMalaysiaNotCoveredLists />
            </Section>
            <Section spacingBottom='5'>
              <Grid>
                <Grid.Column flex='none'><Section width='17'><p className='warranty-bswm-text is-xs'>7.</p></Section></Grid.Column>
                <Grid.Column flex='1'><p className='warranty-bswm-text is-xs'>Failure of the product from corrosion is defined as when the sheet is perforated. Surface rust, or minor corrosion at tension bends or cuts edges due to natural weathering does not constitute failure of the product.</p></Grid.Column>
              </Grid>
            </Section>
            <Section>
              <p className='warranty-bswm-text is-xs'>Ref : 04-0620-T6</p>
            </Section>
          </React.Fragment>
        }
        {isZL_MY_Z1ZAU &&
          <React.Fragment>
            <Section spacingBottom='5'><p className='warranty-bswm-text is-xs'>This warranty is your guarantee that <span className='warranty-bswm-text is-xs is-bold'>ZINCALUME®</span> steel, if properly installed and maintained, will meet minimum expected performance levels for at least the warranted period (commencing from the date of installation).</p></Section>
            <Section spacingBottom='5'><p className='warranty-bswm-text is-xs'>However, certain specific problems and situations (listed below) must be addressed and rectified early in order to get the best possible performance and, if not rectified, may result in the warranty being void.</p></Section>
            <Section spacingBottom='5'>
              <Grid>
                <Grid.Column flex='none'><Section width='17'><p className='warranty-bswm-text is-xs'>1.</p></Section></Grid.Column>
                <Grid.Column flex='1'><p className='warranty-bswm-text is-xs'>The warranty applies only to cladding and flashing made from prime quality <span className='warranty-bswm-text is-xs is-bold'>ZINCALUME®</span> steel. The Owner/Contractor is advised to check for the <span className='warranty-bswm-text is-xs is-bold'>ZINCALUME®</span> steel brand name printed on the reverse of the sheets prior to installation. Guttering, downpipes, brackets, doors and other accessories are excluded.</p></Grid.Column>
              </Grid>
            </Section>
            <Section>
              <Grid>
                <Grid.Column flex='none'><Section width='17'><p className='warranty-bswm-text is-xs'>2.</p></Section></Grid.Column>
                <Grid.Column flex='1'><p className='warranty-bswm-text is-xs'>Cladding must be handled and installed in accordance with published BlueScope guidelines current at the time of installation, with particular attention to the issues listed below:-</p></Grid.Column>
              </Grid>
            </Section>
            <Section paddingLeft='17' spacingBottom='5'>
              <WarrantyBSWMMalaysiaCladdingIssuesLists />
            </Section>
            <Section spacingBottom='5'>
              <Grid>
                <Grid.Column flex='none'><Section width='17'><p className='warranty-bswm-text is-xs'>3.</p></Section></Grid.Column>
                <Grid.Column flex='1'><p className='warranty-bswm-text is-xs'>Fasteners must comply with AS3566 Class 3 or Class 4, and be certified as such by the supplier of fasteners.</p></Grid.Column>
              </Grid>
            </Section>
            <Section spacingBottom='5'>
              <Grid>
                <Grid.Column flex='none'><Section width='17'><p className='warranty-bswm-text is-xs'>4.</p></Section></Grid.Column>
                <Grid.Column flex='1'><p className='warranty-bswm-text is-xs'>All fixings, flashings and associated components must be manufactured from compatible metals (<span className='warranty-bswm-text is-xs is-bold'>COLORBOND®</span>, <span className='warranty-bswm-text is-xs is-bold'>ZINCALUME®</span> steel, zinc, or aluminium) or be coated or plated in accordance with current published BlueScope guidelines. For long lasting consistency in appearance, flashings should be made from the same material as the cladding.</p></Grid.Column>
              </Grid>
            </Section>
            <Section spacingBottom='5'>
              <Grid>
                <Grid.Column flex='none'><Section width='17'><p className='warranty-bswm-text is-xs'>5.</p></Section></Grid.Column>
                <Grid.Column flex='1'><p className='warranty-bswm-text is-xs'>The coating finish as supplied must not be scratched, abraded damaged, or coated with any foreign matter or incompatible material (including stack and flue deposits).</p></Grid.Column>
              </Grid>
            </Section>
            <Section>
              <Grid>
                <Grid.Column flex='none'><Section width='17'><p className='warranty-bswm-text is-xs'>6.</p></Section></Grid.Column>
                <Grid.Column flex='1'><p className='warranty-bswm-text is-xs'>Product failures wholly or in part due to the following causes are not covered by this warranty.</p></Grid.Column>
              </Grid>
            </Section>
            <Section paddingLeft='17' spacingBottom='5'>
              <WarrantyBSWMMalaysiaNotCoveredLists />
            </Section>
            <Section spacingBottom='5'>
              <Grid>
                <Grid.Column flex='none'><Section width='17'><p className='warranty-bswm-text is-xs'>7.</p></Section></Grid.Column>
                <Grid.Column flex='1'><p className='warranty-bswm-text is-xs'>Failure of the product from corrosion is defined as when the sheet is perforated. Surface rust, or minor corrosion at tension bends or cuts edges due to natural weathering does not constitute failure of the product.</p></Grid.Column>
              </Grid>
            </Section>
            <Section>
              <p className='warranty-bswm-text is-xs'>Ref : 04-0620-T6</p>
            </Section>
          </React.Fragment>
        }
      </WarrantyContentWrapper>
    )
  }
}
