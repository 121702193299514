import React from 'react'
import ClassNames from 'classnames'
import PropTypes from 'prop-types'
import {
  Text,
  Grid,
  Section,
} from './../../custom'
import {
  ProgressBarWrapper
} from './styled'
import {
  default as VARIABLES
} from './../../../themes/styles/bases/variables'

/**
 * ProgressBar description:
 * - ProgressBar
 */

export class ProgressBar extends React.PureComponent {
  render () {
    const {
      className,
      active,
      percent,
      message,
      barWidth,
      barHeight,
      barColor,
      barRadius,
      onClick,
    } = this.props

    // props for css classes
    const classes = ClassNames(
      'progress-bar',
      {'is-active': active},
      className
    )

    return (
      <ProgressBarWrapper
        className={classes}
        theme={{
          barWidth,
          barHeight,
          barColor,
          barRadius,
        }}
        onClick={onClick}
      >
        <div className='progress-bar-container'>
          <Section
            relative
            spacingBottom='10'
          >
            <div className='progress-bar-track'>
              <div
                className='progress-bar-percent'
                style={{
                  width: percent + '%',
                }}
              />
            </div>
            <div className='progress-bar-percent-number'>
              <Text
                medium14
                color={VARIABLES.COLORS.WHITE}
                top='-1'
              >
                {percent}%
              </Text>
            </div>
          </Section>
          <div className="progress-bar-message">
            <Section justify='center'>
              <Text
                regular12
                color={VARIABLES.COLORS.WHITE}
                align='center'
              >
                {message}
              </Text>
            </Section>
          </div>
        </div>
      </ProgressBarWrapper>
    )
  }

  static defaultProps = {
    barWidth: 250,
    barHeight: 5,
    barColor: VARIABLES.COLORS.PRIMARY_2,
    barRadius: 4,
  }

  static propTypes = { // TYPE > node, string, number, bool, array, object, symbol, func
    /**
    * Additional classes
    */
    className: PropTypes.string
  }
}
