import _ from 'lodash';
import moment from "moment";
import { MAIN_REDUX_STATE_KEY, mainCreators } from "../../actions/mainAction";
import { adController } from "../../apiService/apiController/adService";
import { storeDispatch, storeGetState } from "../../stores";
import { LOCAL_STORAGE } from "../constants/localStorage";
import { firebaseUnsubscribeNoti } from "../firebases/firebaseNotification";
import { getCMSTncList } from "../services/cmsTnc";
import { getLocalStorage, removeLocalStorage, setLocalStorage } from "./localStorage";
import { isValidResponse } from "./validation";

export const getUserAuth = () => {
  return getLocalStorage(LOCAL_STORAGE.USER_AUTH_RBPTRUEBLUE);
}

export const getAuthSuccess = () => {
  return getLocalStorage(LOCAL_STORAGE.IS_OTP_AUTH_SUCCESS);
}

export const setUserAuthRedux = (userAuth) => {
  storeDispatch(mainCreators.setMainRedux(MAIN_REDUX_STATE_KEY.USER_AUTH, userAuth));
  // if (userAuth) {
  //   setUserAuthImage(userAuth);
  // }
}

export const getUserAuthRedux = () => {
  return storeGetState().mainRedux.userAuth;
}

// const setUserAuthImage = async (userAuth) => {
//   // const imageUrl = await firebaseGetDownloadPath(userAuth.oldImageRef);
//   const imageUrl = `${FIREBASE_PUBLIC_STORAGE_PATH}/${userAuth.oldImageRef}`;
//   userAuth.oldImageUrl = imageUrl;
//   userAuth.imageUrl = imageUrl;
//   storeDispatch(mainCreators.setMainRedux(MAIN_REDUX_STATE_KEY.USER_AUTH, userAuth));
// }

export const logout = () => {
  firebaseUnsubscribeNoti();
  removeLocalStorage(LOCAL_STORAGE.USER_AUTH_RBPTRUEBLUE);
  removeLocalStorage(LOCAL_STORAGE.IS_OTP_AUTH_SUCCESS);
  removeLocalStorage(LOCAL_STORAGE.LAST_ACTIVE_AT)
  window.history.pushState({}, document.title, '/');
  window.location.reload(true);
}

export const acceptTnC = async () => {
  const userAuth = getUserAuthRedux();
  const params = {
    sfid: userAuth.sfid,
    accept: true
  }
  const adService = adController();
  const res = await adService.updateTermsAndCondition(params);
  if (isValidResponse(res)) {
    return true;
  }
  return false
}

export const getAcceptTnc = async () => {
  const userAuth = getUserAuthRedux();
  if (userAuth.acceptTermsConditions) {
    return true;
  }
  else {
    const params = {
      page: 1,
      limit: 1,
      status: 'active',
      sort: 'id desc',
    };
    await getCMSTncList(params);
    return false;
  }
}


export const getLastOTPSuccessDay = (username) => {
  let otpSuccessList = getLocalStorage(LOCAL_STORAGE.IS_OTP_AUTH_SUCCESS_LIST) || [];
  const otpSuccessData = otpSuccessList.filter(e => e.username === username)[0] || { otpSuccessDate: null };
  let otpSuccessDate = otpSuccessData.otpSuccessDate;
  // for developer
  if (window.location.origin.indexOf('http://localhost') === 0) {
    console.log('developer')
    otpSuccessDate = new Date();
  }
  const lastOTPSuccessDay = moment().startOf('day').diff(moment(otpSuccessDate).startOf('day'), 'days');
  console.log('================');
  console.log('getLastOTPSuccessDay', otpSuccessList, username, otpSuccessDate, lastOTPSuccessDay);
  console.log('================');
  return lastOTPSuccessDay
}

export const setLastOTPSuccessDay = (data) => {
  let otpSuccessList = getLocalStorage(LOCAL_STORAGE.IS_OTP_AUTH_SUCCESS_LIST) || [];
  const otpSuccessIDList = otpSuccessList.map(e => e.username);
  if (otpSuccessIDList.indexOf(data.username) >= 0) {
    otpSuccessList = otpSuccessList.map(e => {
      if (e.username === data.username) {
        e = _.cloneDeep(data);
      }
      return e;
    });
  }
  else {
    otpSuccessList.push(_.cloneDeep(data))
  }
  setLocalStorage(LOCAL_STORAGE.IS_OTP_AUTH_SUCCESS_LIST, otpSuccessList)
}

export const handleCheckMaintenance = () => {
  const maintenanceData = storeGetState().mainRedux.maintenance;
  if (maintenanceData.isMaintenance) {
    if (handleCheckMaintenanceWhiteList()) {
      return true
    }
    else {
      return false
    }
  }
  return true;
}

export const handleCheckMaintenanceWhiteList = () => {
  const maintenanceData = storeGetState().mainRedux.maintenance;
  const userAuth = getUserAuth();
  if (userAuth) {
    if (maintenanceData.userWhiteList.indexOf(userAuth.email) >= 0) {
      return true;
    } else {
      logout();
    }
  }
  return false;
}

export const handleValidateAuth = () => {
  const userAuth = getUserAuth();
  if (userAuth) {
    const dateNow = moment();
    const currentDate = dateNow.format('YYYY-MM-DD');
    const lastActiveDate = moment(getLocalStorage(LOCAL_STORAGE.LAST_ACTIVE_AT));
    const diffDate = moment(currentDate).diff(lastActiveDate, 'days');
    if (diffDate > 7) {
      setLocalStorage(LOCAL_STORAGE.IS_TOAST_RE_LOGIN, true);
      logout();
      return false
    } else {
      setLocalStorage(LOCAL_STORAGE.LAST_ACTIVE_AT, currentDate);
      return true
    }
  }
  return true;
}

export const isAccountSegmentPass = (data = '') => {
  const userAuth = getUserAuthRedux()
  const segmentCategory = JSON.parse(data || null)

  if (userAuth.segmentCategory__c === null && userAuth.segmentation__c === null) {
    return false
  }

  if (segmentCategory === null) {
    return true;
  }

  if (segmentCategory[userAuth.segmentCategory__c]) {
    if (userAuth.segmentation__c) {
      return segmentCategory[userAuth.segmentCategory__c][userAuth.segmentation__c];
    }
    return true
  }
  return false
}

export const isAccountAndCustomerPass = (accountData = '', customerData = '') => {
  const userAuth = getUserAuthRedux()
  const accountType = JSON.parse(accountData || null)
  const customerType = JSON.parse(customerData || null)

  if (userAuth.accountRoleType === '' || userAuth.customerRoleType === '') {
    return false
  }

  if (accountType === null && customerType === null) {
    return true;
  }

  let customerRoleType = userAuth.customerRoleType
  if (customerRoleType === 'Toko') {
    customerRoleType = 'Hardware Shop Toko'
  }

  if (accountType[userAuth.accountRoleType] && customerType[customerRoleType]) {
    return true
  }

  return false
}

