// export const BASE_API = 'http://localhost';
// export const BASE_PORT_API = ':5000';
export const BASE_API = 'https://rbptrueblue-prod.herokuapp.com';
export const BASE_PORT_API = '';
export const BASE_PATH_API = '/api';

// export const BASE_API = 'http://localhost';
// export const BASE_PORT_API = ':3000';
export const BASE_CMS_API = 'https://rbpcms-prod.herokuapp.com';
export const BASE_CMS_PORT_API = '';
export const BASE_CMS_PATH_API = '/api';

export const FIREBASE_STORAGE = 'rbptrueblue';

//staging, dev
// export const FIREBASE_PUBLIC_STORAGE_PATH = 'https://storage.googleapis.com/bluescope-stg.appspot.com';

//prod
export const FIREBASE_PUBLIC_STORAGE_PATH = 'https://storage.googleapis.com/bluescope-955e7.appspot.com';

export const TEXT_VERSION = '0.1.0';