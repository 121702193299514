import { VARIABLES } from "../../themes"
import { ENUM } from "./enum";

const WARRANTY_TYPE = {
  DRAFT: {
    TYPE: '-',
    TEXT: 'Draft',
    COLOR: VARIABLES.COLORS.GRAY_14,
  },
  SAVE_DRAFT: {
    TYPE: '3',
    TEXT: 'Draft',
    COLOR: VARIABLES.COLORS.GRAY_14,
  },
  READY: {
    TYPE: '2',
    TEXT: 'Ready',
    COLOR: VARIABLES.COLORS.GREEN_5,
  },
  WAITING_FOR_APPROVAL: {
    TYPE: '1',
    TEXT: 'InProgress',
    COLOR: VARIABLES.COLORS.ORANGE_2,
  },
  // CHANGE_NEEDED: {
  //   TYPE: '3',
  //   TEXT: 'ChangeNeeded',
  //   COLOR: VARIABLES.COLORS.RED_4,
  // },
  REJECTED: {
    TYPE: '7',
    TEXT: 'Rejected',
    COLOR: VARIABLES.COLORS.RED_4,
  },
  RE_GENERATE: {
    TYPE: '',
    TEXT: 'Re-generate',
    COLOR: VARIABLES.COLORS.PRIMARY_1,
  },
  SUBMITTED: {
    TYPE: '4',
    TEXT: 'Submitted',
    COLOR: VARIABLES.COLORS.ORANGE_2,
  },
  DELETE: {
    TYPE: '2',
    TEXT: 'Delete',
    COLOR: VARIABLES.COLORS.RED_4,
  },
  WAITING_TO_BE_APPROVE: {
    TYPE: '5',
    TEXT: 'Waiting to be Approve',
    COLOR: VARIABLES.COLORS.ORANGE_2,
  },
  APPROVE: {
    TYPE: '6',
    TEXT: 'Approve',
    COLOR: VARIABLES.COLORS.ORANGE_2,
  },
}

const WARRANTY_CATEGORY_TYPE = {
  RESIDENTIAL: {
    TYPE: '1',
    TEXT: 'Residential',
  },
  NON_RESIDENTIAL: {
    TYPE: '2',
    TEXT: 'Non-residential',
  },
  PROJECT: {
    TYPE: '3',
    TEXT: 'Project',
  }
}

const WARRANTY_TEMPLATE_LIST = [
  'BlueScope_Zacs',
  'Colorbond',
  'Crp',
  'Jingjoe_Lek',
  'Prima',
  'SuperDyma_Dk',
  'SuperDyma_Pl',
  'TrueCore',
  'Zincalume',
  'COLORBOND® - XRW (AZ150)',
  'COLORBOND® - MATT (AZ150)',
  // 'BlueScope Zacs Natural',
  // 'BlueScope Zacs Dazzle',
];

const WARRANTY_TEMPLATE_VI_LIST = [
  'BZACS - HC INOK',
  'BZACS - INOK',
  'BZACS - Bền Màu',
  'BZACS - Bền Lạnh',
];

const WARRANTY_APP_DETAIL_NAME_SELECT_LIST = [
  'isRoofSelected',
  'isWallSelected',
  'isPurlinGirtSelected',
  'isTruFrameSelected',
  'isCoolRoomSelected',
  'isDeckingSelected',
  'isAirDuctingSelected',
];

const WARRANTY_APP_DETAIL_NAME_LIST = [
  'Roof',
  'Wall',
  'Purlin and Girt',
  'Truss and Frame',
  'Cool room panel',
  'Decking',
  'Air ducting',
  'Shutter ex',
]

const WARRANTY_APP_DETAIL_NAME_ENUM_LIST = [{
  NAME: WARRANTY_APP_DETAIL_NAME_LIST[0],
  VALUE: WARRANTY_APP_DETAIL_NAME_SELECT_LIST[0],
}, {
  NAME: WARRANTY_APP_DETAIL_NAME_LIST[1],
  VALUE: WARRANTY_APP_DETAIL_NAME_SELECT_LIST[1],
}, {
  NAME: WARRANTY_APP_DETAIL_NAME_LIST[2],
  VALUE: WARRANTY_APP_DETAIL_NAME_SELECT_LIST[2],
}, {
  NAME: WARRANTY_APP_DETAIL_NAME_LIST[3],
  VALUE: WARRANTY_APP_DETAIL_NAME_SELECT_LIST[3],
}, {
  NAME: WARRANTY_APP_DETAIL_NAME_LIST[4],
  VALUE: WARRANTY_APP_DETAIL_NAME_SELECT_LIST[4],
}, {
  NAME: WARRANTY_APP_DETAIL_NAME_LIST[5],
  VALUE: WARRANTY_APP_DETAIL_NAME_SELECT_LIST[5],
}, {
  NAME: WARRANTY_APP_DETAIL_NAME_LIST[6],
  VALUE: WARRANTY_APP_DETAIL_NAME_SELECT_LIST[6],
}];

const WARRANTY_ENV_DETAIL_NAME_LIST = [
  'Very Severe Marine',
  'Severe Marine',
  'Marine',
  'Moderate Marine',
  'Benign / Rural',
  'Severe Industrial',
  'Heavy Industrial',
  'Industrial',
  'Light Ind / Urban',
  'TrueCore Application'
]

const WARRANTY_ENV_DETAIL_CODE_LIST = [
  // 'Very severe marine',
  'C5-MR',
  // 'Severe marine',
  'C4-MR',
  // 'Marine',
  'C3-MR',
  // 'Moderate Marine',
  'C2-MR',
  // 'Benign',
  'C1',
  // 'Severe Industrial',
  'C5-IN',
  // 'Heavy industrial',
  'C4-IN',
  // 'Industrial',
  'C3-IN',
  // 'Light Industrial',
  'C2-IN',
  'TrueCore Application',
]

const WARRANTY_ENV_DETAIL = {
  [ENUM.COUNTRY_TYPE.THAI]: {
    [WARRANTY_APP_DETAIL_NAME_SELECT_LIST[0]]: {
      //Very Severe Marme
      [WARRANTY_ENV_DETAIL_NAME_LIST[0]]: {
        IS_SPECIAL: true,
        CONDITION: {
          'wrtTermCon12': {
            MAX: 100,
          },
        },
      },
      //Setere Marine
      [WARRANTY_ENV_DETAIL_NAME_LIST[1]]: {
        CONDITION: {
          'wrtTermCon11': {
            MAX: 100,
          },
          'wrtTermCon12': {
            MIN: 101,
            MAX: 200,
          },
        },
      },
      //Marine
      [WARRANTY_ENV_DETAIL_NAME_LIST[2]]: {
        CONDITION: {
          'wrtTermCon11': {
            MIN: 101,
            MAX: 200,
          },
          'wrtTermCon12': {
            MIN: 201,
            MAX: 400,
          },
        }
      },
      //Moderate Marine
      [WARRANTY_ENV_DETAIL_NAME_LIST[3]]: {
        CONDITION: {
          'wrtTermCon11': {
            MIN: 201,
            MAX: 1000,
          },
          'wrtTermCon12': {
            MIN: 401,
            MAX: 1000,
          }
        }
      },
      //Benign / Rural
      [WARRANTY_ENV_DETAIL_NAME_LIST[4]]: {
        CONDITION: {
          'wrtTermCon11': {
            MIN: 1001,
          },
          'wrtTermCon12': {
            MIN: 1001,
          },
          'wrtTermCon13': {
            MIN: 2001,
          },
          'wrtTermCon14': {
            MIN: 1001,
          }
        },
      },
      //Severe Industrial
      [WARRANTY_ENV_DETAIL_NAME_LIST[5]]: {
        IS_SPECIAL: true,
      },
      //Heavy Industrial
      [WARRANTY_ENV_DETAIL_NAME_LIST[6]]: {
        CONDITION: {
          'wrtTermCon13': {
            MAX: 500
          }
        },
      },
      //Industrial
      [WARRANTY_ENV_DETAIL_NAME_LIST[7]]: {
        CONDITION: {
          'wrtTermCon13': {
            MIN: 501,
            MAX: 1000
          },
          'wrtTermCon14': {
            MIN: 500
          },
        },
      },
      //Light Industrial
      [WARRANTY_ENV_DETAIL_NAME_LIST[8]]: {
        CONDITION: {
          'wrtTermCon13': {
            MIN: 2000,
          },
          'wrtTermCon14': {
            MIN: 1000,
          }
        },
      },
      //TrueCore Application
      [WARRANTY_ENV_DETAIL_NAME_LIST[9]]: {
      }
    },
    [WARRANTY_APP_DETAIL_NAME_SELECT_LIST[1]]: {
      //Very Severe Marme
      [WARRANTY_ENV_DETAIL_NAME_LIST[0]]: {
        IS_SPECIAL: true,
        CONDITION: {
          'wrtTermCon12': {
            MAX: 100,
          },
        },
      },
      //Setere Marine
      [WARRANTY_ENV_DETAIL_NAME_LIST[1]]: {
        CONDITION: {
          'wrtTermCon11': {
            MAX: 100,
          },
          'wrtTermCon12': {
            MIN: 101,
            MAX: 200,
          },
        },
      },
      //Marine
      [WARRANTY_ENV_DETAIL_NAME_LIST[2]]: {
        CONDITION: {
          'wrtTermCon11': {
            MIN: 101,
            MAX: 200,
          },
          'wrtTermCon12': {
            MIN: 201,
            MAX: 400,
          },
        }
      },
      //Moderate Marine
      [WARRANTY_ENV_DETAIL_NAME_LIST[3]]: {
        CONDITION: {
          'wrtTermCon11': {
            MIN: 201,
            MAX: 1000,
          },
          'wrtTermCon12': {
            MIN: 401,
            MAX: 1000,
          }
        }
      },
      //Benign / Rural
      [WARRANTY_ENV_DETAIL_NAME_LIST[4]]: {
        CONDITION: {
          'wrtTermCon11': {
            MIN: 1001,
          },
          'wrtTermCon12': {
            MIN: 1001,
          },
          'wrtTermCon13': {
            MIN: 2001,
          },
          'wrtTermCon14': {
            MIN: 1001,
          }
        },
      },
      //Severe Industrial
      [WARRANTY_ENV_DETAIL_NAME_LIST[5]]: {
        IS_SPECIAL: true,
      },
      //Heavy Industrial
      [WARRANTY_ENV_DETAIL_NAME_LIST[6]]: {
        CONDITION: {
          'wrtTermCon13': {
            MAX: 500
          }
        },
      },
      //Industrial
      [WARRANTY_ENV_DETAIL_NAME_LIST[7]]: {
        CONDITION: {
          'wrtTermCon13': {
            MIN: 501,
            MAX: 1000
          },
          'wrtTermCon14': {
            MIN: 500
          },
        },
      },
      //Light Industrial
      [WARRANTY_ENV_DETAIL_NAME_LIST[8]]: {
        CONDITION: {
          'wrtTermCon13': {
            MIN: 2000,
          },
          'wrtTermCon14': {
            MIN: 1000,
          }
        },
      },
      //TrueCore Application
      [WARRANTY_ENV_DETAIL_NAME_LIST[9]]: {
      }
    },
    [WARRANTY_APP_DETAIL_NAME_SELECT_LIST[2]]: {
      //Very Severe Marme
      [WARRANTY_ENV_DETAIL_NAME_LIST[0]]: {
        IS_SPECIAL: true,
        CONDITION: {
          'wrtTermCon13': {
            MAX: 100,
          },
        },
      },
      //Setere Marine
      [WARRANTY_ENV_DETAIL_NAME_LIST[1]]: {
        CONDITION: {
          'wrtTermCon12': {
            MAX: 100,
          },
          'wrtTermCon13': {
            MIN: 101,
            MAX: 200,
          },
        },
      },
      //Marine
      [WARRANTY_ENV_DETAIL_NAME_LIST[2]]: {
        CONDITION: {
          'wrtTermCon12': {
            MIN: 101,
            MAX: 200,
          },
          'wrtTermCon13': {
            MIN: 201,
            MAX: 400,
          },
        }
      },
      //Moderate Marine
      [WARRANTY_ENV_DETAIL_NAME_LIST[3]]: {
        CONDITION: {
          'wrtTermCon12': {
            MIN: 201,
            MAX: 1000,
          },
          'wrtTermCon13': {
            MIN: 401,
            MAX: 1000,
          }
        }
      },
      //Benign / Rural
      [WARRANTY_ENV_DETAIL_NAME_LIST[4]]: {
        CONDITION: {
          'wrtTermCon12': {
            MIN: 1001,
          },
          'wrtTermCon13': {
            MIN: 1001,
          },
          'wrtTermCon14': {
            MIN: 2001,
          },
          'wrtTermCon15': {
            MIN: 1001,
          }
        },
      },
      //Severe Industrial
      [WARRANTY_ENV_DETAIL_NAME_LIST[5]]: {
        IS_SPECIAL: true,
      },
      //Heavy Industrial
      [WARRANTY_ENV_DETAIL_NAME_LIST[6]]: {
        CONDITION: {
          'wrtTermCon14': {
            MAX: 500
          }
        },
      },
      //Industrial
      [WARRANTY_ENV_DETAIL_NAME_LIST[7]]: {
        CONDITION: {
          'wrtTermCon14': {
            MIN: 501,
            MAX: 1000
          },
          'wrtTermCon15': {
            MIN: 500
          },
        },
      },
      //Light Industrial
      [WARRANTY_ENV_DETAIL_NAME_LIST[8]]: {
        CONDITION: {
          'wrtTermCon14': {
            MIN: 2000,
          },
          'wrtTermCon15': {
            MIN: 1000,
          }
        },
      },
      //TrueCore Application
      [WARRANTY_ENV_DETAIL_NAME_LIST[9]]: {
      }
    },
    [WARRANTY_APP_DETAIL_NAME_SELECT_LIST[3]]: {
      //Very Severe Marme
      [WARRANTY_ENV_DETAIL_NAME_LIST[0]]: {
        IS_SPECIAL: true,
        CONDITION: {
          'wrtTermCon13': {
            MAX: 100,
          },
        },
      },
      //Setere Marine
      [WARRANTY_ENV_DETAIL_NAME_LIST[1]]: {
        CONDITION: {
          'wrtTermCon12': {
            MAX: 100,
          },
          'wrtTermCon13': {
            MIN: 101,
            MAX: 200,
          },
        },
      },
      //Marine
      [WARRANTY_ENV_DETAIL_NAME_LIST[2]]: {
        CONDITION: {
          'wrtTermCon12': {
            MIN: 101,
            MAX: 200,
          },
          'wrtTermCon13': {
            MIN: 201,
            MAX: 400,
          },
        }
      },
      //Moderate Marine
      [WARRANTY_ENV_DETAIL_NAME_LIST[3]]: {
        CONDITION: {
          'wrtTermCon12': {
            MIN: 201,
            MAX: 1000,
          },
          'wrtTermCon13': {
            MIN: 401,
            MAX: 1000,
          }
        }
      },
      //Benign / Rural
      [WARRANTY_ENV_DETAIL_NAME_LIST[4]]: {
        CONDITION: {
          'wrtTermCon12': {
            MIN: 1001,
          },
          'wrtTermCon13': {
            MIN: 1001,
          },
          'wrtTermCon14': {
            MIN: 2001,
          },
          'wrtTermCon15': {
            MIN: 1001,
          }
        },
      },
      //Severe Industrial
      [WARRANTY_ENV_DETAIL_NAME_LIST[5]]: {
        IS_SPECIAL: true,
      },
      //Heavy Industrial
      [WARRANTY_ENV_DETAIL_NAME_LIST[6]]: {
        CONDITION: {
          'wrtTermCon14': {
            MAX: 500
          }
        },
      },
      //Industrial
      [WARRANTY_ENV_DETAIL_NAME_LIST[7]]: {
        CONDITION: {
          'wrtTermCon14': {
            MIN: 501,
            MAX: 1000
          },
          'wrtTermCon15': {
            MIN: 500
          },
        },
      },
      //Light Industrial
      [WARRANTY_ENV_DETAIL_NAME_LIST[8]]: {
        CONDITION: {
          'wrtTermCon14': {
            MIN: 2000,
          },
          'wrtTermCon15': {
            MIN: 1000,
          }
        },
      },
      //TrueCore Application
      [WARRANTY_ENV_DETAIL_NAME_LIST[9]]: {
      }
    },
    [WARRANTY_APP_DETAIL_NAME_SELECT_LIST[4]]: {
      //Very Severe Marme
      [WARRANTY_ENV_DETAIL_NAME_LIST[0]]: {
        IS_SPECIAL: true,
        CONDITION: {
          'wrtTermCon12': {
            MAX: 100,
          },
        },
      },
      //Setere Marine
      [WARRANTY_ENV_DETAIL_NAME_LIST[1]]: {
        CONDITION: {
          'wrtTermCon11': {
            MAX: 100,
          },
          'wrtTermCon12': {
            MIN: 101,
            MAX: 200,
          },
        },
      },
      //Marine
      [WARRANTY_ENV_DETAIL_NAME_LIST[2]]: {
        CONDITION: {
          'wrtTermCon11': {
            MIN: 101,
            MAX: 200,
          },
          'wrtTermCon12': {
            MIN: 201,
            MAX: 400,
          },
        }
      },
      //Moderate Marine
      [WARRANTY_ENV_DETAIL_NAME_LIST[3]]: {
        CONDITION: {
          'wrtTermCon11': {
            MIN: 201,
            MAX: 1000,
          },
          'wrtTermCon12': {
            MIN: 401,
            MAX: 1000,
          }
        }
      },
      //Benign / Rural
      [WARRANTY_ENV_DETAIL_NAME_LIST[4]]: {
        CONDITION: {
          'wrtTermCon11': {
            MIN: 1001,
          },
          'wrtTermCon12': {
            MIN: 1001,
          },
          'wrtTermCon13': {
            MIN: 2001,
          },
          'wrtTermCon14': {
            MIN: 1001,
          }
        },
      },
      //Severe Industrial
      [WARRANTY_ENV_DETAIL_NAME_LIST[5]]: {
        IS_SPECIAL: true,
      },
      //Heavy Industrial
      [WARRANTY_ENV_DETAIL_NAME_LIST[6]]: {
        CONDITION: {
          'wrtTermCon13': {
            MAX: 500
          }
        },
      },
      //Industrial
      [WARRANTY_ENV_DETAIL_NAME_LIST[7]]: {
        CONDITION: {
          'wrtTermCon13': {
            MIN: 501,
            MAX: 1000
          },
          'wrtTermCon14': {
            MIN: 500
          },
        },
      },
      //Light Industrial
      [WARRANTY_ENV_DETAIL_NAME_LIST[8]]: {
        CONDITION: {
          'wrtTermCon13': {
            MIN: 2000,
          },
          'wrtTermCon14': {
            MIN: 1000,
          }
        },
      },
      //TrueCore Application
      [WARRANTY_ENV_DETAIL_NAME_LIST[9]]: {
      }
    },
    [WARRANTY_APP_DETAIL_NAME_SELECT_LIST[5]]: {
      //Very Severe Marme
      [WARRANTY_ENV_DETAIL_NAME_LIST[0]]: {
        IS_SPECIAL: true,
        CONDITION: {
          'wrtTermCon13': {
            MAX: 100,
          },
        },
      },
      //Setere Marine
      [WARRANTY_ENV_DETAIL_NAME_LIST[1]]: {
        CONDITION: {
          'wrtTermCon12': {
            MAX: 100,
          },
          'wrtTermCon13': {
            MIN: 101,
            MAX: 200,
          },
        },
      },
      //Marine
      [WARRANTY_ENV_DETAIL_NAME_LIST[2]]: {
        CONDITION: {
          'wrtTermCon12': {
            MIN: 101,
            MAX: 200,
          },
          'wrtTermCon13': {
            MIN: 201,
            MAX: 400,
          },
        }
      },
      //Moderate Marine
      [WARRANTY_ENV_DETAIL_NAME_LIST[3]]: {
        CONDITION: {
          'wrtTermCon12': {
            MIN: 201,
            MAX: 1000,
          },
          'wrtTermCon13': {
            MIN: 401,
            MAX: 1000,
          }
        }
      },
      //Benign / Rural
      [WARRANTY_ENV_DETAIL_NAME_LIST[4]]: {
        CONDITION: {
          'wrtTermCon12': {
            MIN: 1001,
          },
          'wrtTermCon13': {
            MIN: 1001,
          },
          'wrtTermCon14': {
            MIN: 2001,
          },
          'wrtTermCon15': {
            MIN: 1001,
          }
        },
      },
      //Severe Industrial
      [WARRANTY_ENV_DETAIL_NAME_LIST[5]]: {
        IS_SPECIAL: true,
      },
      //Heavy Industrial
      [WARRANTY_ENV_DETAIL_NAME_LIST[6]]: {
        CONDITION: {
          'wrtTermCon14': {
            MAX: 500
          }
        },
      },
      //Industrial
      [WARRANTY_ENV_DETAIL_NAME_LIST[7]]: {
        CONDITION: {
          'wrtTermCon14': {
            MIN: 501,
            MAX: 1000
          },
          'wrtTermCon15': {
            MIN: 500
          },
        },
      },
      //Light Industrial
      [WARRANTY_ENV_DETAIL_NAME_LIST[8]]: {
        CONDITION: {
          'wrtTermCon14': {
            MIN: 2000,
          },
          'wrtTermCon15': {
            MIN: 1000,
          }
        },
      },
      //TrueCore Application
      [WARRANTY_ENV_DETAIL_NAME_LIST[9]]: {
      }
    },
    [WARRANTY_APP_DETAIL_NAME_SELECT_LIST[6]]: {
      //Very Severe Marme
      [WARRANTY_ENV_DETAIL_NAME_LIST[0]]: {
        IS_SPECIAL: true,
        CONDITION: {
          'wrtTermCon13': {
            MAX: 100,
          },
        },
      },
      //Setere Marine
      [WARRANTY_ENV_DETAIL_NAME_LIST[1]]: {
        CONDITION: {
          'wrtTermCon12': {
            MAX: 100,
          },
          'wrtTermCon13': {
            MIN: 101,
            MAX: 200,
          },
        },
      },
      //Marine
      [WARRANTY_ENV_DETAIL_NAME_LIST[2]]: {
        CONDITION: {
          'wrtTermCon12': {
            MIN: 101,
            MAX: 200,
          },
          'wrtTermCon13': {
            MIN: 201,
            MAX: 400,
          },
        }
      },
      //Moderate Marine
      [WARRANTY_ENV_DETAIL_NAME_LIST[3]]: {
        CONDITION: {
          'wrtTermCon12': {
            MIN: 201,
            MAX: 1000,
          },
          'wrtTermCon13': {
            MIN: 401,
            MAX: 1000,
          }
        }
      },
      //Benign / Rural
      [WARRANTY_ENV_DETAIL_NAME_LIST[4]]: {
        CONDITION: {
          'wrtTermCon12': {
            MIN: 1001,
          },
          'wrtTermCon13': {
            MIN: 1001,
          },
          'wrtTermCon14': {
            MIN: 2001,
          },
          'wrtTermCon15': {
            MIN: 1001,
          }
        },
      },
      //Severe Industrial
      [WARRANTY_ENV_DETAIL_NAME_LIST[5]]: {
        IS_SPECIAL: true,
      },
      //Heavy Industrial
      [WARRANTY_ENV_DETAIL_NAME_LIST[6]]: {
        CONDITION: {
          'wrtTermCon14': {
            MAX: 500
          }
        },
      },
      //Industrial
      [WARRANTY_ENV_DETAIL_NAME_LIST[7]]: {
        CONDITION: {
          'wrtTermCon14': {
            MIN: 501,
            MAX: 1000
          },
          'wrtTermCon15': {
            MIN: 500
          },
        },
      },
      //Light Industrial
      [WARRANTY_ENV_DETAIL_NAME_LIST[8]]: {
        CONDITION: {
          'wrtTermCon14': {
            MIN: 2000,
          },
          'wrtTermCon15': {
            MIN: 1000,
          }
        },
      },
      //TrueCore Application
      [WARRANTY_ENV_DETAIL_NAME_LIST[9]]: {
      }
    },
  },
  [ENUM.COUNTRY_TYPE.MALAYSIA]: {
    [WARRANTY_APP_DETAIL_NAME_SELECT_LIST[0]]: {
      //Very Severe Marme
      [WARRANTY_ENV_DETAIL_NAME_LIST[0]]: {
        IS_SPECIAL: true,
        CONDITION: {
          'wrtTermCon12': {
            MAX: 100,
          },
        }
      },
      //Setere Marine
      [WARRANTY_ENV_DETAIL_NAME_LIST[1]]: {
        CONDITION: {
          'wrtTermCon11': {
            MAX: 100,
          },
          'wrtTermCon12': {
            MIN: 101,
            MAX: 200,
          },
        },
      },
      //Marine
      [WARRANTY_ENV_DETAIL_NAME_LIST[2]]: {
        CONDITION: {
          'wrtTermCon11': {
            MIN: 101,
            MAX: 200,
          },
          'wrtTermCon12': {
            MIN: 201,
            MAX: 400,
          },
        }
      },
      //Moderate Marine
      [WARRANTY_ENV_DETAIL_NAME_LIST[3]]: {
        CONDITION: {
          'wrtTermCon11': {
            MIN: 201,
            MAX: 1000,
          },
          'wrtTermCon12': {
            MIN: 401,
            MAX: 1000,
          }
        }
      },
      //Benign / Rural
      [WARRANTY_ENV_DETAIL_NAME_LIST[4]]: {
        CONDITION: {
          'wrtTermCon11': {
            MIN: 1001,
          },
          'wrtTermCon12': {
            MIN: 1001,
          },
          'wrtTermCon13': {
            MIN: 2001,
          },
          'wrtTermCon14': {
            MIN: 1001,
          }
        },
      },
      //Severe Industrial
      [WARRANTY_ENV_DETAIL_NAME_LIST[5]]: {
        IS_SPECIAL: true,
      },
      //Heavy Industrial
      [WARRANTY_ENV_DETAIL_NAME_LIST[6]]: {
        CONDITION: {
          'wrtTermCon13': {
            MAX: 500
          }
        },
      },
      //Industrial
      [WARRANTY_ENV_DETAIL_NAME_LIST[7]]: {
        CONDITION: {
          'wrtTermCon13': {
            MIN: 501,
            MAX: 1000
          },
          'wrtTermCon14': {
            MIN: 500
          },
        },
      },
      //Light Industrial
      [WARRANTY_ENV_DETAIL_NAME_LIST[8]]: {
        CONDITION: {
          'wrtTermCon13': {
            MIN: 2000,
          },
          'wrtTermCon14': {
            MIN: 1000,
          }
        },
      },
      //TrueCore Application
      [WARRANTY_ENV_DETAIL_NAME_LIST[9]]: {
      }
    },
    [WARRANTY_APP_DETAIL_NAME_SELECT_LIST[1]]: {
      //Very Severe Marme
      [WARRANTY_ENV_DETAIL_NAME_LIST[0]]: {
        IS_SPECIAL: true,
        CONDITION: {
          'wrtTermCon12': {
            MAX: 100,
          },
        }
      },
      //Setere Marine
      [WARRANTY_ENV_DETAIL_NAME_LIST[1]]: {
        CONDITION: {
          'wrtTermCon11': {
            MAX: 100,
          },
          'wrtTermCon12': {
            MIN: 101,
            MAX: 200,
          },
        },
      },
      //Marine
      [WARRANTY_ENV_DETAIL_NAME_LIST[2]]: {
        CONDITION: {
          'wrtTermCon11': {
            MIN: 101,
            MAX: 200,
          },
          'wrtTermCon12': {
            MIN: 201,
            MAX: 400,
          },
        }
      },
      //Moderate Marine
      [WARRANTY_ENV_DETAIL_NAME_LIST[3]]: {
        CONDITION: {
          'wrtTermCon11': {
            MIN: 201,
            MAX: 1000,
          },
          'wrtTermCon12': {
            MIN: 401,
            MAX: 1000,
          }
        }
      },
      //Benign / Rural
      [WARRANTY_ENV_DETAIL_NAME_LIST[4]]: {
        CONDITION: {
          'wrtTermCon11': {
            MIN: 1001,
          },
          'wrtTermCon12': {
            MIN: 1001,
          },
          'wrtTermCon13': {
            MIN: 2001,
          },
          'wrtTermCon14': {
            MIN: 1001,
          }
        },
      },
      //Severe Industrial
      [WARRANTY_ENV_DETAIL_NAME_LIST[5]]: {
        IS_SPECIAL: true,
      },
      //Heavy Industrial
      [WARRANTY_ENV_DETAIL_NAME_LIST[6]]: {
        CONDITION: {
          'wrtTermCon13': {
            MAX: 500
          }
        },
      },
      //Industrial
      [WARRANTY_ENV_DETAIL_NAME_LIST[7]]: {
        CONDITION: {
          'wrtTermCon13': {
            MIN: 501,
            MAX: 1000
          },
          'wrtTermCon14': {
            MIN: 500
          },
        },
      },
      //Light Industrial
      [WARRANTY_ENV_DETAIL_NAME_LIST[8]]: {
        CONDITION: {
          'wrtTermCon13': {
            MIN: 2000,
          },
          'wrtTermCon14': {
            MIN: 1000,
          }
        },
      },
      //TrueCore Application
      [WARRANTY_ENV_DETAIL_NAME_LIST[9]]: {
      }
    },
    [WARRANTY_APP_DETAIL_NAME_SELECT_LIST[2]]: {
      //Very Severe Marme
      [WARRANTY_ENV_DETAIL_NAME_LIST[0]]: {
        IS_SPECIAL: true,
        CONDITION: {
          'wrtTermCon13': {
            MAX: 100,
          },
        }
      },
      //Setere Marine
      [WARRANTY_ENV_DETAIL_NAME_LIST[1]]: {
        CONDITION: {
          'wrtTermCon12': {
            MAX: 100,
          },
          'wrtTermCon13': {
            MIN: 101,
            MAX: 200,
          },
        },
      },
      //Marine
      [WARRANTY_ENV_DETAIL_NAME_LIST[2]]: {
        CONDITION: {
          'wrtTermCon12': {
            MIN: 101,
            MAX: 200,
          },
          'wrtTermCon13': {
            MIN: 201,
            MAX: 400,
          },
        }
      },
      //Moderate Marine
      [WARRANTY_ENV_DETAIL_NAME_LIST[3]]: {
        CONDITION: {
          'wrtTermCon12': {
            MIN: 201,
            MAX: 1000,
          },
          'wrtTermCon13': {
            MIN: 401,
            MAX: 1000,
          }
        }
      },
      //Benign / Rural
      [WARRANTY_ENV_DETAIL_NAME_LIST[4]]: {
        CONDITION: {
          'wrtTermCon12': {
            MIN: 1001,
          },
          'wrtTermCon13': {
            MIN: 1001,
          },
          'wrtTermCon14': {
            MIN: 2001,
          },
          'wrtTermCon15': {
            MIN: 1001,
          }
        },
      },
      //Severe Industrial
      [WARRANTY_ENV_DETAIL_NAME_LIST[5]]: {
        IS_SPECIAL: true,
      },
      //Heavy Industrial
      [WARRANTY_ENV_DETAIL_NAME_LIST[6]]: {
        CONDITION: {
          'wrtTermCon14': {
            MAX: 500
          }
        },
      },
      //Industrial
      [WARRANTY_ENV_DETAIL_NAME_LIST[7]]: {
        CONDITION: {
          'wrtTermCon14': {
            MIN: 501,
            MAX: 1000
          },
          'wrtTermCon15': {
            MIN: 500
          },
        },
      },
      //Light Industrial
      [WARRANTY_ENV_DETAIL_NAME_LIST[8]]: {
        CONDITION: {
          'wrtTermCon14': {
            MIN: 2000,
          },
          'wrtTermCon15': {
            MIN: 1000,
          }
        },
      },
      //TrueCore Application
      [WARRANTY_ENV_DETAIL_NAME_LIST[9]]: {
      }
    },
    [WARRANTY_APP_DETAIL_NAME_SELECT_LIST[3]]: {
      //Very Severe Marme
      [WARRANTY_ENV_DETAIL_NAME_LIST[0]]: {
        IS_SPECIAL: true,
        CONDITION: {
          'wrtTermCon13': {
            MAX: 100,
          },
        }
      },
      //Setere Marine
      [WARRANTY_ENV_DETAIL_NAME_LIST[1]]: {
        CONDITION: {
          'wrtTermCon12': {
            MAX: 100,
          },
          'wrtTermCon13': {
            MIN: 101,
            MAX: 200,
          },
        },
      },
      //Marine
      [WARRANTY_ENV_DETAIL_NAME_LIST[2]]: {
        CONDITION: {
          'wrtTermCon12': {
            MIN: 101,
            MAX: 200,
          },
          'wrtTermCon13': {
            MIN: 201,
            MAX: 400,
          },
        }
      },
      //Moderate Marine
      [WARRANTY_ENV_DETAIL_NAME_LIST[3]]: {
        CONDITION: {
          'wrtTermCon12': {
            MIN: 201,
            MAX: 1000,
          },
          'wrtTermCon13': {
            MIN: 401,
            MAX: 1000,
          }
        }
      },
      //Benign / Rural
      [WARRANTY_ENV_DETAIL_NAME_LIST[4]]: {
        CONDITION: {
          'wrtTermCon12': {
            MIN: 1001,
          },
          'wrtTermCon13': {
            MIN: 1001,
          },
          'wrtTermCon14': {
            MIN: 2001,
          },
          'wrtTermCon15': {
            MIN: 1001,
          }
        },
      },
      //Severe Industrial
      [WARRANTY_ENV_DETAIL_NAME_LIST[5]]: {
        IS_SPECIAL: true,
      },
      //Heavy Industrial
      [WARRANTY_ENV_DETAIL_NAME_LIST[6]]: {
        CONDITION: {
          'wrtTermCon14': {
            MAX: 500
          }
        },
      },
      //Industrial
      [WARRANTY_ENV_DETAIL_NAME_LIST[7]]: {
        CONDITION: {
          'wrtTermCon14': {
            MIN: 501,
            MAX: 1000
          },
          'wrtTermCon15': {
            MIN: 500
          },
        },
      },
      //Light Industrial
      [WARRANTY_ENV_DETAIL_NAME_LIST[8]]: {
        CONDITION: {
          'wrtTermCon14': {
            MIN: 2000,
          },
          'wrtTermCon15': {
            MIN: 1000,
          }
        },
      },
      //TrueCore Application
      [WARRANTY_ENV_DETAIL_NAME_LIST[9]]: {
      }
    },
    [WARRANTY_APP_DETAIL_NAME_SELECT_LIST[4]]: {
      //Very Severe Marme
      [WARRANTY_ENV_DETAIL_NAME_LIST[0]]: {
        IS_SPECIAL: true,
        CONDITION: {
          'wrtTermCon12': {
            MAX: 100,
          },
        }
      },
      //Setere Marine
      [WARRANTY_ENV_DETAIL_NAME_LIST[1]]: {
        CONDITION: {
          'wrtTermCon11': {
            MAX: 100,
          },
          'wrtTermCon12': {
            MIN: 101,
            MAX: 200,
          },
        },
      },
      //Marine
      [WARRANTY_ENV_DETAIL_NAME_LIST[2]]: {
        CONDITION: {
          'wrtTermCon11': {
            MIN: 101,
            MAX: 200,
          },
          'wrtTermCon12': {
            MIN: 201,
            MAX: 400,
          },
        }
      },
      //Moderate Marine
      [WARRANTY_ENV_DETAIL_NAME_LIST[3]]: {
        CONDITION: {
          'wrtTermCon11': {
            MIN: 201,
            MAX: 1000,
          },
          'wrtTermCon12': {
            MIN: 401,
            MAX: 1000,
          }
        }
      },
      //Benign / Rural
      [WARRANTY_ENV_DETAIL_NAME_LIST[4]]: {
        CONDITION: {
          'wrtTermCon11': {
            MIN: 1001,
          },
          'wrtTermCon12': {
            MIN: 1001,
          },
          'wrtTermCon13': {
            MIN: 2001,
          },
          'wrtTermCon14': {
            MIN: 1001,
          }
        },
      },
      //Severe Industrial
      [WARRANTY_ENV_DETAIL_NAME_LIST[5]]: {
        IS_SPECIAL: true,
      },
      //Heavy Industrial
      [WARRANTY_ENV_DETAIL_NAME_LIST[6]]: {
        CONDITION: {
          'wrtTermCon13': {
            MAX: 500
          }
        },
      },
      //Industrial
      [WARRANTY_ENV_DETAIL_NAME_LIST[7]]: {
        CONDITION: {
          'wrtTermCon13': {
            MIN: 501,
            MAX: 1000
          },
          'wrtTermCon14': {
            MIN: 500
          },
        },
      },
      //Light Industrial
      [WARRANTY_ENV_DETAIL_NAME_LIST[8]]: {
        CONDITION: {
          'wrtTermCon13': {
            MIN: 2000,
          },
          'wrtTermCon14': {
            MIN: 1000,
          }
        },
      },
      //TrueCore Application
      [WARRANTY_ENV_DETAIL_NAME_LIST[9]]: {
      }
    },
    [WARRANTY_APP_DETAIL_NAME_SELECT_LIST[5]]: {
      //Very Severe Marme
      [WARRANTY_ENV_DETAIL_NAME_LIST[0]]: {
        IS_SPECIAL: true,
        CONDITION: {
          'wrtTermCon13': {
            MAX: 100,
          },
        }
      },
      //Setere Marine
      [WARRANTY_ENV_DETAIL_NAME_LIST[1]]: {
        CONDITION: {
          'wrtTermCon12': {
            MAX: 100,
          },
          'wrtTermCon13': {
            MIN: 101,
            MAX: 200,
          },
        },
      },
      //Marine
      [WARRANTY_ENV_DETAIL_NAME_LIST[2]]: {
        CONDITION: {
          'wrtTermCon12': {
            MIN: 101,
            MAX: 200,
          },
          'wrtTermCon13': {
            MIN: 201,
            MAX: 400,
          },
        }
      },
      //Moderate Marine
      [WARRANTY_ENV_DETAIL_NAME_LIST[3]]: {
        CONDITION: {
          'wrtTermCon12': {
            MIN: 201,
            MAX: 1000,
          },
          'wrtTermCon13': {
            MIN: 401,
            MAX: 1000,
          }
        }
      },
      //Benign / Rural
      [WARRANTY_ENV_DETAIL_NAME_LIST[4]]: {
        CONDITION: {
          'wrtTermCon12': {
            MIN: 1001,
          },
          'wrtTermCon13': {
            MIN: 1001,
          },
          'wrtTermCon14': {
            MIN: 2001,
          },
          'wrtTermCon15': {
            MIN: 1001,
          }
        },
      },
      //Severe Industrial
      [WARRANTY_ENV_DETAIL_NAME_LIST[5]]: {
        IS_SPECIAL: true,
      },
      //Heavy Industrial
      [WARRANTY_ENV_DETAIL_NAME_LIST[6]]: {
        CONDITION: {
          'wrtTermCon14': {
            MAX: 500
          }
        },
      },
      //Industrial
      [WARRANTY_ENV_DETAIL_NAME_LIST[7]]: {
        CONDITION: {
          'wrtTermCon14': {
            MIN: 501,
            MAX: 1000
          },
          'wrtTermCon15': {
            MIN: 500
          },
        },
      },
      //Light Industrial
      [WARRANTY_ENV_DETAIL_NAME_LIST[8]]: {
        CONDITION: {
          'wrtTermCon14': {
            MIN: 2000,
          },
          'wrtTermCon15': {
            MIN: 1000,
          }
        },
      },
      //TrueCore Application
      [WARRANTY_ENV_DETAIL_NAME_LIST[9]]: {
      }
    },
    [WARRANTY_APP_DETAIL_NAME_SELECT_LIST[6]]: {
      //Very Severe Marme
      [WARRANTY_ENV_DETAIL_NAME_LIST[0]]: {
        IS_SPECIAL: true,
        CONDITION: {
          'wrtTermCon13': {
            MAX: 100,
          },
        }
      },
      //Setere Marine
      [WARRANTY_ENV_DETAIL_NAME_LIST[1]]: {
        CONDITION: {
          'wrtTermCon12': {
            MAX: 100,
          },
          'wrtTermCon13': {
            MIN: 101,
            MAX: 200,
          },
        },
      },
      //Marine
      [WARRANTY_ENV_DETAIL_NAME_LIST[2]]: {
        CONDITION: {
          'wrtTermCon12': {
            MIN: 101,
            MAX: 200,
          },
          'wrtTermCon13': {
            MIN: 201,
            MAX: 400,
          },
        }
      },
      //Moderate Marine
      [WARRANTY_ENV_DETAIL_NAME_LIST[3]]: {
        CONDITION: {
          'wrtTermCon12': {
            MIN: 201,
            MAX: 1000,
          },
          'wrtTermCon13': {
            MIN: 401,
            MAX: 1000,
          }
        }
      },
      //Benign / Rural
      [WARRANTY_ENV_DETAIL_NAME_LIST[4]]: {
        CONDITION: {
          'wrtTermCon12': {
            MIN: 1001,
          },
          'wrtTermCon13': {
            MIN: 1001,
          },
          'wrtTermCon14': {
            MIN: 2001,
          },
          'wrtTermCon15': {
            MIN: 1001,
          }
        },
      },
      //Severe Industrial
      [WARRANTY_ENV_DETAIL_NAME_LIST[5]]: {
        IS_SPECIAL: true,
      },
      //Heavy Industrial
      [WARRANTY_ENV_DETAIL_NAME_LIST[6]]: {
        CONDITION: {
          'wrtTermCon14': {
            MAX: 500
          }
        },
      },
      //Industrial
      [WARRANTY_ENV_DETAIL_NAME_LIST[7]]: {
        CONDITION: {
          'wrtTermCon14': {
            MIN: 501,
            MAX: 1000
          },
          'wrtTermCon15': {
            MIN: 500
          },
        },
      },
      //Light Industrial
      [WARRANTY_ENV_DETAIL_NAME_LIST[8]]: {
        CONDITION: {
          'wrtTermCon14': {
            MIN: 2000,
          },
          'wrtTermCon15': {
            MIN: 1000,
          }
        },
      },
      //TrueCore Application
      [WARRANTY_ENV_DETAIL_NAME_LIST[9]]: {
      }
    },
  },
  [ENUM.COUNTRY_TYPE.INDONESIA]: {
    [WARRANTY_APP_DETAIL_NAME_SELECT_LIST[0]]: {
      //Very Severe Marme
      [WARRANTY_ENV_DETAIL_NAME_LIST[0]]: {
        IS_SPECIAL: true,
        CONDITION: {
          'wrtTermCon12': {
            MAX: 100,
          },
        }
      },
      //Setere Marine
      [WARRANTY_ENV_DETAIL_NAME_LIST[1]]: {
        CONDITION: {
          'wrtTermCon11': {
            MAX: 100,
          },
          'wrtTermCon12': {
            MIN: 101,
            MAX: 200,
          },
        },
      },
      //Marine
      [WARRANTY_ENV_DETAIL_NAME_LIST[2]]: {
        CONDITION: {
          'wrtTermCon11': {
            MIN: 101,
            MAX: 200,
          },
          'wrtTermCon12': {
            MIN: 201,
            MAX: 400,
          },
        }
      },
      //Moderate Marine
      [WARRANTY_ENV_DETAIL_NAME_LIST[3]]: {
        CONDITION: {
          'wrtTermCon11': {
            MIN: 201,
            MAX: 1000,
          },
          'wrtTermCon12': {
            MIN: 401,
            MAX: 1000,
          }
        }
      },
      //Benign / Rural
      [WARRANTY_ENV_DETAIL_NAME_LIST[4]]: {
        CONDITION: {
          'wrtTermCon11': {
            MIN: 1001,
          },
          'wrtTermCon12': {
            MIN: 1001,
          },
          'wrtTermCon13': {
            MIN: 2001,
          },
          'wrtTermCon14': {
            MIN: 1001,
          }
        },
      },
      //Severe Industrial
      [WARRANTY_ENV_DETAIL_NAME_LIST[5]]: {
        IS_SPECIAL: true,
      },
      //Heavy Industrial
      [WARRANTY_ENV_DETAIL_NAME_LIST[6]]: {
        CONDITION: {
          'wrtTermCon13': {
            MAX: 500
          }
        },
      },
      //Industrial
      [WARRANTY_ENV_DETAIL_NAME_LIST[7]]: {
        CONDITION: {
          'wrtTermCon13': {
            MIN: 501,
            MAX: 1000
          },
          'wrtTermCon14': {
            MIN: 500
          },
        },
      },
      //Light Industrial
      [WARRANTY_ENV_DETAIL_NAME_LIST[8]]: {
        CONDITION: {
          'wrtTermCon13': {
            MIN: 2000,
          },
          'wrtTermCon14': {
            MIN: 1000,
          }
        },
      },
      //TrueCore Application
      [WARRANTY_ENV_DETAIL_NAME_LIST[9]]: {
      }
    },
    [WARRANTY_APP_DETAIL_NAME_SELECT_LIST[1]]: {
      //Very Severe Marme
      [WARRANTY_ENV_DETAIL_NAME_LIST[0]]: {
        IS_SPECIAL: true,
        CONDITION: {
          'wrtTermCon12': {
            MAX: 100,
          },
        }
      },
      //Setere Marine
      [WARRANTY_ENV_DETAIL_NAME_LIST[1]]: {
        CONDITION: {
          'wrtTermCon11': {
            MAX: 100,
          },
          'wrtTermCon12': {
            MIN: 101,
            MAX: 200,
          },
        },
      },
      //Marine
      [WARRANTY_ENV_DETAIL_NAME_LIST[2]]: {
        CONDITION: {
          'wrtTermCon11': {
            MIN: 101,
            MAX: 200,
          },
          'wrtTermCon12': {
            MIN: 201,
            MAX: 400,
          },
        }
      },
      //Moderate Marine
      [WARRANTY_ENV_DETAIL_NAME_LIST[3]]: {
        CONDITION: {
          'wrtTermCon11': {
            MIN: 201,
            MAX: 1000,
          },
          'wrtTermCon12': {
            MIN: 401,
            MAX: 1000,
          }
        }
      },
      //Benign / Rural
      [WARRANTY_ENV_DETAIL_NAME_LIST[4]]: {
        CONDITION: {
          'wrtTermCon11': {
            MIN: 1001,
          },
          'wrtTermCon12': {
            MIN: 1001,
          },
          'wrtTermCon13': {
            MIN: 2001,
          },
          'wrtTermCon14': {
            MIN: 1001,
          }
        },
      },
      //Severe Industrial
      [WARRANTY_ENV_DETAIL_NAME_LIST[5]]: {
        IS_SPECIAL: true,
      },
      //Heavy Industrial
      [WARRANTY_ENV_DETAIL_NAME_LIST[6]]: {
        CONDITION: {
          'wrtTermCon13': {
            MAX: 500
          }
        },
      },
      //Industrial
      [WARRANTY_ENV_DETAIL_NAME_LIST[7]]: {
        CONDITION: {
          'wrtTermCon13': {
            MIN: 501,
            MAX: 1000
          },
          'wrtTermCon14': {
            MIN: 500
          },
        },
      },
      //Light Industrial
      [WARRANTY_ENV_DETAIL_NAME_LIST[8]]: {
        CONDITION: {
          'wrtTermCon13': {
            MIN: 2000,
          },
          'wrtTermCon14': {
            MIN: 1000,
          }
        },
      },
      //TrueCore Application
      [WARRANTY_ENV_DETAIL_NAME_LIST[9]]: {
      }
    },
    [WARRANTY_APP_DETAIL_NAME_SELECT_LIST[2]]: {
      //Very Severe Marme
      [WARRANTY_ENV_DETAIL_NAME_LIST[0]]: {
        IS_SPECIAL: true,
        CONDITION: {
          'wrtTermCon13': {
            MAX: 100,
          },
        }
      },
      //Setere Marine
      [WARRANTY_ENV_DETAIL_NAME_LIST[1]]: {
        CONDITION: {
          'wrtTermCon12': {
            MAX: 100,
          },
          'wrtTermCon13': {
            MIN: 101,
            MAX: 200,
          },
        },
      },
      //Marine
      [WARRANTY_ENV_DETAIL_NAME_LIST[2]]: {
        CONDITION: {
          'wrtTermCon12': {
            MIN: 101,
            MAX: 200,
          },
          'wrtTermCon13': {
            MIN: 201,
            MAX: 400,
          },
        }
      },
      //Moderate Marine
      [WARRANTY_ENV_DETAIL_NAME_LIST[3]]: {
        CONDITION: {
          'wrtTermCon12': {
            MIN: 201,
            MAX: 1000,
          },
          'wrtTermCon13': {
            MIN: 401,
            MAX: 1000,
          }
        }
      },
      //Benign / Rural
      [WARRANTY_ENV_DETAIL_NAME_LIST[4]]: {
        CONDITION: {
          'wrtTermCon12': {
            MIN: 1001,
          },
          'wrtTermCon13': {
            MIN: 1001,
          },
          'wrtTermCon14': {
            MIN: 2001,
          },
          'wrtTermCon15': {
            MIN: 1001,
          }
        },
      },
      //Severe Industrial
      [WARRANTY_ENV_DETAIL_NAME_LIST[5]]: {
        IS_SPECIAL: true,
      },
      //Heavy Industrial
      [WARRANTY_ENV_DETAIL_NAME_LIST[6]]: {
        CONDITION: {
          'wrtTermCon14': {
            MAX: 500
          }
        },
      },
      //Industrial
      [WARRANTY_ENV_DETAIL_NAME_LIST[7]]: {
        CONDITION: {
          'wrtTermCon14': {
            MIN: 501,
            MAX: 1000
          },
          'wrtTermCon15': {
            MIN: 500
          },
        },
      },
      //Light Industrial
      [WARRANTY_ENV_DETAIL_NAME_LIST[8]]: {
        CONDITION: {
          'wrtTermCon14': {
            MIN: 2000,
          },
          'wrtTermCon15': {
            MIN: 1000,
          }
        },
      },
      //TrueCore Application
      [WARRANTY_ENV_DETAIL_NAME_LIST[9]]: {
      }
    },
    [WARRANTY_APP_DETAIL_NAME_SELECT_LIST[3]]: {
      //Very Severe Marme
      [WARRANTY_ENV_DETAIL_NAME_LIST[0]]: {
        IS_SPECIAL: true,
        CONDITION: {
          'wrtTermCon13': {
            MAX: 100,
          },
        }
      },
      //Setere Marine
      [WARRANTY_ENV_DETAIL_NAME_LIST[1]]: {
        CONDITION: {
          'wrtTermCon12': {
            MAX: 100,
          },
          'wrtTermCon13': {
            MIN: 101,
            MAX: 200,
          },
        },
      },
      //Marine
      [WARRANTY_ENV_DETAIL_NAME_LIST[2]]: {
        CONDITION: {
          'wrtTermCon12': {
            MIN: 101,
            MAX: 200,
          },
          'wrtTermCon13': {
            MIN: 201,
            MAX: 400,
          },
        }
      },
      //Moderate Marine
      [WARRANTY_ENV_DETAIL_NAME_LIST[3]]: {
        CONDITION: {
          'wrtTermCon12': {
            MIN: 201,
            MAX: 1000,
          },
          'wrtTermCon13': {
            MIN: 401,
            MAX: 1000,
          }
        }
      },
      //Benign / Rural
      [WARRANTY_ENV_DETAIL_NAME_LIST[4]]: {
        CONDITION: {
          'wrtTermCon12': {
            MIN: 1001,
          },
          'wrtTermCon13': {
            MIN: 1001,
          },
          'wrtTermCon14': {
            MIN: 2001,
          },
          'wrtTermCon15': {
            MIN: 1001,
          }
        },
      },
      //Severe Industrial
      [WARRANTY_ENV_DETAIL_NAME_LIST[5]]: {
        IS_SPECIAL: true,
      },
      //Heavy Industrial
      [WARRANTY_ENV_DETAIL_NAME_LIST[6]]: {
        CONDITION: {
          'wrtTermCon14': {
            MAX: 500
          }
        },
      },
      //Industrial
      [WARRANTY_ENV_DETAIL_NAME_LIST[7]]: {
        CONDITION: {
          'wrtTermCon14': {
            MIN: 501,
            MAX: 1000
          },
          'wrtTermCon15': {
            MIN: 500
          },
        },
      },
      //Light Industrial
      [WARRANTY_ENV_DETAIL_NAME_LIST[8]]: {
        CONDITION: {
          'wrtTermCon14': {
            MIN: 2000,
          },
          'wrtTermCon15': {
            MIN: 1000,
          }
        },
      },
      //TrueCore Application
      [WARRANTY_ENV_DETAIL_NAME_LIST[9]]: {
      }
    },
    [WARRANTY_APP_DETAIL_NAME_SELECT_LIST[4]]: {
      //Very Severe Marme
      [WARRANTY_ENV_DETAIL_NAME_LIST[0]]: {
        IS_SPECIAL: true,
        CONDITION: {
          'wrtTermCon12': {
            MAX: 100,
          },
        }
      },
      //Setere Marine
      [WARRANTY_ENV_DETAIL_NAME_LIST[1]]: {
        CONDITION: {
          'wrtTermCon11': {
            MAX: 100,
          },
          'wrtTermCon12': {
            MIN: 101,
            MAX: 200,
          },
        },
      },
      //Marine
      [WARRANTY_ENV_DETAIL_NAME_LIST[2]]: {
        CONDITION: {
          'wrtTermCon11': {
            MIN: 101,
            MAX: 200,
          },
          'wrtTermCon12': {
            MIN: 201,
            MAX: 400,
          },
        }
      },
      //Moderate Marine
      [WARRANTY_ENV_DETAIL_NAME_LIST[3]]: {
        CONDITION: {
          'wrtTermCon11': {
            MIN: 201,
            MAX: 1000,
          },
          'wrtTermCon12': {
            MIN: 401,
            MAX: 1000,
          }
        }
      },
      //Benign / Rural
      [WARRANTY_ENV_DETAIL_NAME_LIST[4]]: {
        CONDITION: {
          'wrtTermCon11': {
            MIN: 1001,
          },
          'wrtTermCon12': {
            MIN: 1001,
          },
          'wrtTermCon13': {
            MIN: 2001,
          },
          'wrtTermCon14': {
            MIN: 1001,
          }
        },
      },
      //Severe Industrial
      [WARRANTY_ENV_DETAIL_NAME_LIST[5]]: {
        IS_SPECIAL: true,
      },
      //Heavy Industrial
      [WARRANTY_ENV_DETAIL_NAME_LIST[6]]: {
        CONDITION: {
          'wrtTermCon13': {
            MAX: 500
          }
        },
      },
      //Industrial
      [WARRANTY_ENV_DETAIL_NAME_LIST[7]]: {
        CONDITION: {
          'wrtTermCon13': {
            MIN: 501,
            MAX: 1000
          },
          'wrtTermCon14': {
            MIN: 500
          },
        },
      },
      //Light Industrial
      [WARRANTY_ENV_DETAIL_NAME_LIST[8]]: {
        CONDITION: {
          'wrtTermCon13': {
            MIN: 2000,
          },
          'wrtTermCon14': {
            MIN: 1000,
          }
        },
      },
      //TrueCore Application
      [WARRANTY_ENV_DETAIL_NAME_LIST[9]]: {
      }
    },
    [WARRANTY_APP_DETAIL_NAME_SELECT_LIST[5]]: {
      //Very Severe Marme
      [WARRANTY_ENV_DETAIL_NAME_LIST[0]]: {
        IS_SPECIAL: true,
        CONDITION: {
          'wrtTermCon13': {
            MAX: 100,
          },
        }
      },
      //Setere Marine
      [WARRANTY_ENV_DETAIL_NAME_LIST[1]]: {
        CONDITION: {
          'wrtTermCon12': {
            MAX: 100,
          },
          'wrtTermCon13': {
            MIN: 101,
            MAX: 200,
          },
        },
      },
      //Marine
      [WARRANTY_ENV_DETAIL_NAME_LIST[2]]: {
        CONDITION: {
          'wrtTermCon12': {
            MIN: 101,
            MAX: 200,
          },
          'wrtTermCon13': {
            MIN: 201,
            MAX: 400,
          },
        }
      },
      //Moderate Marine
      [WARRANTY_ENV_DETAIL_NAME_LIST[3]]: {
        CONDITION: {
          'wrtTermCon12': {
            MIN: 201,
            MAX: 1000,
          },
          'wrtTermCon13': {
            MIN: 401,
            MAX: 1000,
          }
        }
      },
      //Benign / Rural
      [WARRANTY_ENV_DETAIL_NAME_LIST[4]]: {
        CONDITION: {
          'wrtTermCon12': {
            MIN: 1001,
          },
          'wrtTermCon13': {
            MIN: 1001,
          },
          'wrtTermCon14': {
            MIN: 2001,
          },
          'wrtTermCon15': {
            MIN: 1001,
          }
        },
      },
      //Severe Industrial
      [WARRANTY_ENV_DETAIL_NAME_LIST[5]]: {
        IS_SPECIAL: true,
      },
      //Heavy Industrial
      [WARRANTY_ENV_DETAIL_NAME_LIST[6]]: {
        CONDITION: {
          'wrtTermCon14': {
            MAX: 500
          }
        },
      },
      //Industrial
      [WARRANTY_ENV_DETAIL_NAME_LIST[7]]: {
        CONDITION: {
          'wrtTermCon14': {
            MIN: 501,
            MAX: 1000
          },
          'wrtTermCon15': {
            MIN: 500
          },
        },
      },
      //Light Industrial
      [WARRANTY_ENV_DETAIL_NAME_LIST[8]]: {
        CONDITION: {
          'wrtTermCon14': {
            MIN: 2000,
          },
          'wrtTermCon15': {
            MIN: 1000,
          }
        },
      },
      //TrueCore Application
      [WARRANTY_ENV_DETAIL_NAME_LIST[9]]: {
      }
    },
    [WARRANTY_APP_DETAIL_NAME_SELECT_LIST[6]]: {
      //Very Severe Marme
      [WARRANTY_ENV_DETAIL_NAME_LIST[0]]: {
        IS_SPECIAL: true,
        CONDITION: {
          'wrtTermCon13': {
            MAX: 100,
          },
        }
      },
      //Setere Marine
      [WARRANTY_ENV_DETAIL_NAME_LIST[1]]: {
        CONDITION: {
          'wrtTermCon12': {
            MAX: 100,
          },
          'wrtTermCon13': {
            MIN: 101,
            MAX: 200,
          },
        },
      },
      //Marine
      [WARRANTY_ENV_DETAIL_NAME_LIST[2]]: {
        CONDITION: {
          'wrtTermCon12': {
            MIN: 101,
            MAX: 200,
          },
          'wrtTermCon13': {
            MIN: 201,
            MAX: 400,
          },
        }
      },
      //Moderate Marine
      [WARRANTY_ENV_DETAIL_NAME_LIST[3]]: {
        CONDITION: {
          'wrtTermCon12': {
            MIN: 201,
            MAX: 1000,
          },
          'wrtTermCon13': {
            MIN: 401,
            MAX: 1000,
          }
        }
      },
      //Benign / Rural
      [WARRANTY_ENV_DETAIL_NAME_LIST[4]]: {
        CONDITION: {
          'wrtTermCon12': {
            MIN: 1001,
          },
          'wrtTermCon13': {
            MIN: 1001,
          },
          'wrtTermCon14': {
            MIN: 2001,
          },
          'wrtTermCon15': {
            MIN: 1001,
          }
        },
      },
      //Severe Industrial
      [WARRANTY_ENV_DETAIL_NAME_LIST[5]]: {
        IS_SPECIAL: true,
      },
      //Heavy Industrial
      [WARRANTY_ENV_DETAIL_NAME_LIST[6]]: {
        CONDITION: {
          'wrtTermCon14': {
            MAX: 500
          }
        },
      },
      //Industrial
      [WARRANTY_ENV_DETAIL_NAME_LIST[7]]: {
        CONDITION: {
          'wrtTermCon14': {
            MIN: 501,
            MAX: 1000
          },
          'wrtTermCon15': {
            MIN: 500
          },
        },
      },
      //Light Industrial
      [WARRANTY_ENV_DETAIL_NAME_LIST[8]]: {
        CONDITION: {
          'wrtTermCon14': {
            MIN: 2000,
          },
          'wrtTermCon15': {
            MIN: 1000,
          }
        },
      },
      //TrueCore Application
      [WARRANTY_ENV_DETAIL_NAME_LIST[9]]: {
      }
    },
  },
  [ENUM.COUNTRY_TYPE.VIETNAM]: {
    [WARRANTY_APP_DETAIL_NAME_SELECT_LIST[0]]: {
      //Very Severe Marme
      [WARRANTY_ENV_DETAIL_NAME_LIST[0]]: {
        IS_SPECIAL: true,
        CONDITION: {
          'wrtTermCon12': {
            MAX: 100,
          },
        }
      },
      //Setere Marine
      [WARRANTY_ENV_DETAIL_NAME_LIST[1]]: {
        CONDITION: {
          'wrtTermCon11': {
            MAX: 100,
          },
          'wrtTermCon12': {
            MIN: 101,
            MAX: 400,
          },
        },
      },
      //Marine
      [WARRANTY_ENV_DETAIL_NAME_LIST[2]]: {
        CONDITION: {
          'wrtTermCon11': {
            MIN: 101,
            MAX: 400,
          },
          'wrtTermCon12': {
            MIN: 401,
            MAX: 1000,
          },
        }
      },
      //Moderate Marine
      [WARRANTY_ENV_DETAIL_NAME_LIST[3]]: {
        CONDITION: {
          'wrtTermCon11': {
            MIN: 201,
            MAX: 1000,
          },
          'wrtTermCon12': {
            MIN: 1001,
            MAX: 5000,
          }
        }
      },
      //Benign / Rural
      [WARRANTY_ENV_DETAIL_NAME_LIST[4]]: {
        CONDITION: {
          'wrtTermCon11': {
            MIN: 5001,
          },
          'wrtTermCon12': {
            MIN: 5001,
          },
          'wrtTermCon13': {
            MIN: 2000,
          },
          'wrtTermCon14': {
            MIN: 1000,
          }
        },
      },
      //Severe Industrial
      [WARRANTY_ENV_DETAIL_NAME_LIST[5]]: {
        IS_SPECIAL: true,
      },
      //Heavy Industrial
      [WARRANTY_ENV_DETAIL_NAME_LIST[6]]: {
        CONDITION: {
          'wrtTermCon13': {
            MAX: 500
          }
        },
      },
      //Industrial
      [WARRANTY_ENV_DETAIL_NAME_LIST[7]]: {
        CONDITION: {
          'wrtTermCon13': {
            MIN: 501,
            MAX: 1000
          },
          'wrtTermCon14': {
            MIN: 500
          },
        },
      },
      //Light Industrial
      [WARRANTY_ENV_DETAIL_NAME_LIST[8]]: {
        CONDITION: {
          'wrtTermCon13': {
            MIN: 2000,
          },
          'wrtTermCon14': {
            MIN: 1000,
          }
        },
      },
      //TrueCore Application
      [WARRANTY_ENV_DETAIL_NAME_LIST[9]]: {
      }
    },
    [WARRANTY_APP_DETAIL_NAME_SELECT_LIST[1]]: {
      //Very Severe Marme
      [WARRANTY_ENV_DETAIL_NAME_LIST[0]]: {
        IS_SPECIAL: true,
        CONDITION: {
          'wrtTermCon12': {
            MAX: 100,
          },
        }
      },
      //Setere Marine
      [WARRANTY_ENV_DETAIL_NAME_LIST[1]]: {
        CONDITION: {
          'wrtTermCon11': {
            MAX: 100,
          },
          'wrtTermCon12': {
            MIN: 101,
            MAX: 400,
          },
        },
      },
      //Marine
      [WARRANTY_ENV_DETAIL_NAME_LIST[2]]: {
        CONDITION: {
          'wrtTermCon11': {
            MIN: 101,
            MAX: 400,
          },
          'wrtTermCon12': {
            MIN: 401,
            MAX: 1000,
          },
        }
      },
      //Moderate Marine
      [WARRANTY_ENV_DETAIL_NAME_LIST[3]]: {
        CONDITION: {
          'wrtTermCon11': {
            MIN: 201,
            MAX: 1000,
          },
          'wrtTermCon12': {
            MIN: 1001,
            MAX: 5000,
          }
        }
      },
      //Benign / Rural
      [WARRANTY_ENV_DETAIL_NAME_LIST[4]]: {
        CONDITION: {
          'wrtTermCon11': {
            MIN: 5001,
          },
          'wrtTermCon12': {
            MIN: 5001,
          },
          'wrtTermCon13': {
            MIN: 2000,
          },
          'wrtTermCon14': {
            MIN: 1000,
          }
        },
      },
      //Severe Industrial
      [WARRANTY_ENV_DETAIL_NAME_LIST[5]]: {
        IS_SPECIAL: true,
      },
      //Heavy Industrial
      [WARRANTY_ENV_DETAIL_NAME_LIST[6]]: {
        CONDITION: {
          'wrtTermCon13': {
            MAX: 500
          }
        },
      },
      //Industrial
      [WARRANTY_ENV_DETAIL_NAME_LIST[7]]: {
        CONDITION: {
          'wrtTermCon13': {
            MIN: 501,
            MAX: 1000
          },
          'wrtTermCon14': {
            MIN: 500
          },
        },
      },
      //Light Industrial
      [WARRANTY_ENV_DETAIL_NAME_LIST[8]]: {
        CONDITION: {
          'wrtTermCon13': {
            MIN: 2000,
          },
          'wrtTermCon14': {
            MIN: 1000,
          }
        },
      },
      //TrueCore Application
      [WARRANTY_ENV_DETAIL_NAME_LIST[9]]: {
      }
    },
    [WARRANTY_APP_DETAIL_NAME_SELECT_LIST[2]]: {
      //Very Severe Marme
      [WARRANTY_ENV_DETAIL_NAME_LIST[0]]: {
        IS_SPECIAL: true,
        CONDITION: {
          'wrtTermCon13': {
            MAX: 100,
          },
        }
      },
      //Setere Marine
      [WARRANTY_ENV_DETAIL_NAME_LIST[1]]: {
        CONDITION: {
          'wrtTermCon12': {
            MAX: 100,
          },
          'wrtTermCon13': {
            MIN: 101,
            MAX: 400,
          },
        },
      },
      //Marine
      [WARRANTY_ENV_DETAIL_NAME_LIST[2]]: {
        CONDITION: {
          'wrtTermCon12': {
            MIN: 101,
            MAX: 400,
          },
          'wrtTermCon13': {
            MIN: 401,
            MAX: 1000,
          },
        }
      },
      //Moderate Marine
      [WARRANTY_ENV_DETAIL_NAME_LIST[3]]: {
        CONDITION: {
          'wrtTermCon12': {
            MIN: 201,
            MAX: 1000,
          },
          'wrtTermCon13': {
            MIN: 1001,
            MAX: 5000,
          }
        }
      },
      //Benign / Rural
      [WARRANTY_ENV_DETAIL_NAME_LIST[4]]: {
        CONDITION: {
          'wrtTermCon12': {
            MIN: 5001,
          },
          'wrtTermCon13': {
            MIN: 5001,
          },
          'wrtTermCon14': {
            MIN: 2000,
          },
          'wrtTermCon15': {
            MIN: 1000,
          }
        },
      },
      //Severe Industrial
      [WARRANTY_ENV_DETAIL_NAME_LIST[5]]: {
        IS_SPECIAL: true,
      },
      //Heavy Industrial
      [WARRANTY_ENV_DETAIL_NAME_LIST[6]]: {
        CONDITION: {
          'wrtTermCon14': {
            MAX: 500
          }
        },
      },
      //Industrial
      [WARRANTY_ENV_DETAIL_NAME_LIST[7]]: {
        CONDITION: {
          'wrtTermCon14': {
            MIN: 501,
            MAX: 1000
          },
          'wrtTermCon15': {
            MIN: 500
          },
        },
      },
      //Light Industrial
      [WARRANTY_ENV_DETAIL_NAME_LIST[8]]: {
        CONDITION: {
          'wrtTermCon14': {
            MIN: 2000,
          },
          'wrtTermCon15': {
            MIN: 1000,
          }
        },
      },
      //TrueCore Application
      [WARRANTY_ENV_DETAIL_NAME_LIST[9]]: {
      }
    },
    [WARRANTY_APP_DETAIL_NAME_SELECT_LIST[3]]: {
      //Very Severe Marme
      [WARRANTY_ENV_DETAIL_NAME_LIST[0]]: {
        IS_SPECIAL: true,
        CONDITION: {
          'wrtTermCon13': {
            MAX: 100,
          },
        }
      },
      //Setere Marine
      [WARRANTY_ENV_DETAIL_NAME_LIST[1]]: {
        CONDITION: {
          'wrtTermCon12': {
            MAX: 100,
          },
          'wrtTermCon13': {
            MIN: 101,
            MAX: 400,
          },
        },
      },
      //Marine
      [WARRANTY_ENV_DETAIL_NAME_LIST[2]]: {
        CONDITION: {
          'wrtTermCon12': {
            MIN: 101,
            MAX: 400,
          },
          'wrtTermCon13': {
            MIN: 401,
            MAX: 1000,
          },
        }
      },
      //Moderate Marine
      [WARRANTY_ENV_DETAIL_NAME_LIST[3]]: {
        CONDITION: {
          'wrtTermCon12': {
            MIN: 201,
            MAX: 1000,
          },
          'wrtTermCon13': {
            MIN: 1001,
            MAX: 5000,
          }
        }
      },
      //Benign / Rural
      [WARRANTY_ENV_DETAIL_NAME_LIST[4]]: {
        CONDITION: {
          'wrtTermCon12': {
            MIN: 5001,
          },
          'wrtTermCon13': {
            MIN: 5001,
          },
          'wrtTermCon14': {
            MIN: 2000,
          },
          'wrtTermCon15': {
            MIN: 1000,
          }
        },
      },
      //Severe Industrial
      [WARRANTY_ENV_DETAIL_NAME_LIST[5]]: {
        IS_SPECIAL: true,
      },
      //Heavy Industrial
      [WARRANTY_ENV_DETAIL_NAME_LIST[6]]: {
        CONDITION: {
          'wrtTermCon14': {
            MAX: 500
          }
        },
      },
      //Industrial
      [WARRANTY_ENV_DETAIL_NAME_LIST[7]]: {
        CONDITION: {
          'wrtTermCon14': {
            MIN: 501,
            MAX: 1000
          },
          'wrtTermCon15': {
            MIN: 500
          },
        },
      },
      //Light Industrial
      [WARRANTY_ENV_DETAIL_NAME_LIST[8]]: {
        CONDITION: {
          'wrtTermCon14': {
            MIN: 2000,
          },
          'wrtTermCon15': {
            MIN: 1000,
          }
        },
      },
      //TrueCore Application
      [WARRANTY_ENV_DETAIL_NAME_LIST[9]]: {
      }
    },
    [WARRANTY_APP_DETAIL_NAME_SELECT_LIST[4]]: {
      //Very Severe Marme
      [WARRANTY_ENV_DETAIL_NAME_LIST[0]]: {
        IS_SPECIAL: true,
        CONDITION: {
          'wrtTermCon12': {
            MAX: 100,
          },
        }
      },
      //Setere Marine
      [WARRANTY_ENV_DETAIL_NAME_LIST[1]]: {
        CONDITION: {
          'wrtTermCon11': {
            MAX: 100,
          },
          'wrtTermCon12': {
            MIN: 101,
            MAX: 400,
          },
        },
      },
      //Marine
      [WARRANTY_ENV_DETAIL_NAME_LIST[2]]: {
        CONDITION: {
          'wrtTermCon11': {
            MIN: 101,
            MAX: 400,
          },
          'wrtTermCon12': {
            MIN: 401,
            MAX: 1000,
          },
        }
      },
      //Moderate Marine
      [WARRANTY_ENV_DETAIL_NAME_LIST[3]]: {
        CONDITION: {
          'wrtTermCon11': {
            MIN: 201,
            MAX: 1000,
          },
          'wrtTermCon12': {
            MIN: 1001,
            MAX: 5000,
          }
        }
      },
      //Benign / Rural
      [WARRANTY_ENV_DETAIL_NAME_LIST[4]]: {
        CONDITION: {
          'wrtTermCon11': {
            MIN: 5001,
          },
          'wrtTermCon12': {
            MIN: 5001,
          },
          'wrtTermCon13': {
            MIN: 2000,
          },
          'wrtTermCon14': {
            MIN: 1000,
          }
        },
      },
      //Severe Industrial
      [WARRANTY_ENV_DETAIL_NAME_LIST[5]]: {
        IS_SPECIAL: true,
      },
      //Heavy Industrial
      [WARRANTY_ENV_DETAIL_NAME_LIST[6]]: {
        CONDITION: {
          'wrtTermCon13': {
            MAX: 500
          }
        },
      },
      //Industrial
      [WARRANTY_ENV_DETAIL_NAME_LIST[7]]: {
        CONDITION: {
          'wrtTermCon13': {
            MIN: 501,
            MAX: 1000
          },
          'wrtTermCon14': {
            MIN: 500
          },
        },
      },
      //Light Industrial
      [WARRANTY_ENV_DETAIL_NAME_LIST[8]]: {
        CONDITION: {
          'wrtTermCon13': {
            MIN: 2000,
          },
          'wrtTermCon14': {
            MIN: 1000,
          }
        },
      },
      //TrueCore Application
      [WARRANTY_ENV_DETAIL_NAME_LIST[9]]: {
      }
    },
    [WARRANTY_APP_DETAIL_NAME_SELECT_LIST[5]]: {
      //Very Severe Marme
      [WARRANTY_ENV_DETAIL_NAME_LIST[0]]: {
        IS_SPECIAL: true,
        CONDITION: {
          'wrtTermCon13': {
            MAX: 100,
          },
        }
      },
      //Setere Marine
      [WARRANTY_ENV_DETAIL_NAME_LIST[1]]: {
        CONDITION: {
          'wrtTermCon12': {
            MAX: 100,
          },
          'wrtTermCon13': {
            MIN: 101,
            MAX: 400,
          },
        },
      },
      //Marine
      [WARRANTY_ENV_DETAIL_NAME_LIST[2]]: {
        CONDITION: {
          'wrtTermCon12': {
            MIN: 101,
            MAX: 400,
          },
          'wrtTermCon13': {
            MIN: 401,
            MAX: 1000,
          },
        }
      },
      //Moderate Marine
      [WARRANTY_ENV_DETAIL_NAME_LIST[3]]: {
        CONDITION: {
          'wrtTermCon12': {
            MIN: 201,
            MAX: 1000,
          },
          'wrtTermCon13': {
            MIN: 1001,
            MAX: 5000,
          }
        }
      },
      //Benign / Rural
      [WARRANTY_ENV_DETAIL_NAME_LIST[4]]: {
        CONDITION: {
          'wrtTermCon12': {
            MIN: 5001,
          },
          'wrtTermCon13': {
            MIN: 5001,
          },
          'wrtTermCon14': {
            MIN: 2000,
          },
          'wrtTermCon15': {
            MIN: 1000,
          }
        },
      },
      //Severe Industrial
      [WARRANTY_ENV_DETAIL_NAME_LIST[5]]: {
        IS_SPECIAL: true,
      },
      //Heavy Industrial
      [WARRANTY_ENV_DETAIL_NAME_LIST[6]]: {
        CONDITION: {
          'wrtTermCon14': {
            MAX: 500
          }
        },
      },
      //Industrial
      [WARRANTY_ENV_DETAIL_NAME_LIST[7]]: {
        CONDITION: {
          'wrtTermCon14': {
            MIN: 501,
            MAX: 1000
          },
          'wrtTermCon15': {
            MIN: 500
          },
        },
      },
      //Light Industrial
      [WARRANTY_ENV_DETAIL_NAME_LIST[8]]: {
        CONDITION: {
          'wrtTermCon14': {
            MIN: 2000,
          },
          'wrtTermCon15': {
            MIN: 1000,
          }
        },
      },
      //TrueCore Application
      [WARRANTY_ENV_DETAIL_NAME_LIST[9]]: {
      }
    },
    [WARRANTY_APP_DETAIL_NAME_SELECT_LIST[6]]: {
      //Very Severe Marme
      [WARRANTY_ENV_DETAIL_NAME_LIST[0]]: {
        IS_SPECIAL: true,
        CONDITION: {
          'wrtTermCon13': {
            MAX: 100,
          },
        }
      },
      //Setere Marine
      [WARRANTY_ENV_DETAIL_NAME_LIST[1]]: {
        CONDITION: {
          'wrtTermCon12': {
            MAX: 100,
          },
          'wrtTermCon13': {
            MIN: 101,
            MAX: 400,
          },
        },
      },
      //Marine
      [WARRANTY_ENV_DETAIL_NAME_LIST[2]]: {
        CONDITION: {
          'wrtTermCon12': {
            MIN: 101,
            MAX: 400,
          },
          'wrtTermCon13': {
            MIN: 401,
            MAX: 1000,
          },
        }
      },
      //Moderate Marine
      [WARRANTY_ENV_DETAIL_NAME_LIST[3]]: {
        CONDITION: {
          'wrtTermCon12': {
            MIN: 201,
            MAX: 1000,
          },
          'wrtTermCon13': {
            MIN: 1001,
            MAX: 5000,
          }
        }
      },
      //Benign / Rural
      [WARRANTY_ENV_DETAIL_NAME_LIST[4]]: {
        CONDITION: {
          'wrtTermCon12': {
            MIN: 5001,
          },
          'wrtTermCon13': {
            MIN: 5001,
          },
          'wrtTermCon14': {
            MIN: 2000,
          },
          'wrtTermCon15': {
            MIN: 1000,
          }
        },
      },
      //Severe Industrial
      [WARRANTY_ENV_DETAIL_NAME_LIST[5]]: {
        IS_SPECIAL: true,
      },
      //Heavy Industrial
      [WARRANTY_ENV_DETAIL_NAME_LIST[6]]: {
        CONDITION: {
          'wrtTermCon14': {
            MAX: 500
          }
        },
      },
      //Industrial
      [WARRANTY_ENV_DETAIL_NAME_LIST[7]]: {
        CONDITION: {
          'wrtTermCon14': {
            MIN: 501,
            MAX: 1000
          },
          'wrtTermCon15': {
            MIN: 500
          },
        },
      },
      //Light Industrial
      [WARRANTY_ENV_DETAIL_NAME_LIST[8]]: {
        CONDITION: {
          'wrtTermCon14': {
            MIN: 2000,
          },
          'wrtTermCon15': {
            MIN: 1000,
          }
        },
      },
      //TrueCore Application
      [WARRANTY_ENV_DETAIL_NAME_LIST[9]]: {
      }
    },
  },
}

const WARRANTY_ENV_TYPE = {
  NO_OFFER: 'null',
  NO_OFFER_TEXT: 'no offer',
  NA: 'n/a',
  SPECIAL: 'special',
}

const WARRANTY_DETAIL_GROUP_TYPE = {
  BLUE_SCOPE_ZACS: {
    TYPE: 'zacs',
    TEXT: '',
  },
  BLUESCOPE_ZACS_NATURAL: {
    TYPE: 'BlueScope Zacs Natural',
    TEXT: '',
  },
  BLUESCOPE_ZACS_DAZZLE: {
    TYPE: 'BlueScope Zacs Dazzle',
    TEXT: '',
  },
  COLOR_BOND: {
    TYPE: 'colorbond',
    TEXT: '',
  },
  CRP: {
    TYPE: 'crp',
    TEXT: '',
  },
  JINGJOE_LEK: {
    TYPE: 'jingjoe',
    TEXT: '',
  },
  PRIMA: {
    TYPE: 'Prima',
    TEXT: '',
  },
  SUPER_DYMA_DK: {
    TYPE: 'superdyma',
    TEXT: '',
  },
  SUPER_DYMA_PL: {
    TYPE: '',
    TEXT: '',
  },
  TRUE_CORE: {
    TYPE: 'truecore',
    TEXT: '',
  },
  ZINCALUME: {
    TYPE: 'zincalume',
    TEXT: '',
  },
}

const WARRANTY_CONDITION = {
  RURAL: 'Rural',
  URBAN: 'Urban',
}

// const WARRANTY_CAN_GENERATE = {
//   MALAY_GROUP_LIST: [
//     'BlueScope Zacs Cool',
//     'BlueScope Zacs Natural',
//     'BlueScope Zacs'
//   ]
// }

const WARRANTY_PRODUCT_TEMPLATE_GROUP = {
  [ENUM.COUNTRY_TYPE.THAI]: [
    'BLUESCOPE_ZACS_TH',
    'COLORBOND_TH',
    // 'CRP_ANTIBACTERIAL_SD_TH',
    'SUPERDYMA_CRP',
    'ENDUROCORE_TH',
    'JINGJOE_LEK_TH',
    'PRIMA_TH',
    'SUPERDYMA_TH',
    'TRUECORE_TH',
    'ZINCALUME_TH',
    'BLUESCOPE_ZACS_COOL_TH',
    'BLUESCOPE_ZACS_NATURAL_TH',
    'BLUESCOPE_ZACS_DAZZLE_TH',
    'COLORBOND_TH_2',
    'PP_PANEL_SD',
    'SUPERDYMA_TF',
    'SUPERDYMA_PL',
    'COLORBOND_XMA_TH',
    'PRIMA_P1PMA_TH',
    'SUPERDYMA_SDUCK_TH',
    'SUPERDYMA_SDUCK_K1DSD_TH',
    'SUPERDYMA_SDUCK_K2DSD_TH',
  ],
  [ENUM.COUNTRY_TYPE.INDONESIA]: [
    'P1CCP_CRP_ANTIBACTERIAL',
    'P2BPC_BLUESCOPE_ZACS_COOL',
    'P2BPN_BLUESCOPE_ZACS_NATURAL',
    'P2CCT_CRP_FOODGRADE',
    'P2HRP_HRP_ANTIBACTERIAL',
    'P2KIR_KIRANA',
    'P13XL_COLORBOND_R_XAL',
    'P13XP_COLORBOND_R_XPD',
    'P17UL_COLORBOND_R_ULTRA',
    'P17XR_COLORBOND_R_XRW',
    'Z1TRC_TRUECORE',
    'Z1ZAL_ZINCALUME',
    'Z1ZAU_ZINCALUME_ULTRA',
    'Z2ABD_ABADI',
    'Z2BZC_BLUESCOPE_ZACS_BARE',
    'Z2BZU_BLUESCOPE_ZACS_TRUSS',
    'P1CCP_COLORBOND_R_COOLROOM'
  ],
  [ENUM.COUNTRY_TYPE.MALAYSIA]: [
    'CAPERO_MY_P1CWR',
    'CCB_MATT_MY_P15RM',
    'CCB_SPECTRUM_MY_P1CPP',
    'CCB_SPECTRUM_MY_P15PP',
    'CCB_ULTRA_MATT_MY_P15UM',
    'CCB_ULTRA_MY_P15UL',
    'CCB_XPD_MY_P15XP',
    'CCB_XPD_PSC_MY_P15XS',
    'CCB_XRW_MY_P15XR',
    'PM_R_MY_P2PRJ',
    'TRUECORE_MY_Z1TRC',
    'VERMOE_MY_P1VRM',
    'ZL_MY_Z1ZAL',
    'ZL_MY_Z1ZAU',
    'PM_MY_P2PRM',
  ],
  [ENUM.COUNTRY_TYPE.VIETNAM]: [
    'CCB_CUSTOM_XPD_PEARLESCENT_VN_P17XR',
    'CCB_CUSTOM_XPD_VN_P17XR',
    'CCB_FOR_PANEL_VN_P17XR',
    'CCB_ULTRA_ACTIVATE_VN_P17XR',
    'SUMO_ANTIFADING_VN_P17XR',
    'ZL_VN_Z1ZAL',
    'ZL_ULTRA_VN_Z1ZAL',
    'CCB_FOR_PANEL_VN_P1CPP',
    'CCB_ULTRA_VN_P14UL',
    'CCB_ULTRA_ACTIVATE_VN_V13UL',
    'CCB_XPD_PSC_VN_P13XS',
    'CCB_XPD_VN_X13XP',
    'CCB_XRW_VN_P13XR',
    'HINATA_BARE_VN_Z1HB',
    'HINATA_PAINTED_VN_P1H1Z',
    'SUMO_ANTIFADING_VN_P2SUA',
    'SUMO_FOR_PANEL_VN_P3SUP',
    'ZL_ULTRA_VN_B1MAX',
  ],
}

// const WARRANTY_PRODUCT_GROUP = {
//   [ENUM.COUNTRY_TYPE.THAI]: {
//     'Z3BR7': {
//       PRODUCT_GROUP_NAME: 'BlueScope Zacs',
//       TEMPLATE: 'BlueScope Zacs_TH',
//     },
//     'Z3ZAC': {
//       PRODUCT_GROUP_NAME: 'BlueScope Zacs',
//       TEMPLATE: 'BlueScope Zacs_TH',
//     },
//     'P1SPN': {
//       PRODUCT_GROUP_NAME: 'BlueScope Zacs Cool',
//       TEMPLATE: 'BlueScope Zacs_TH',
//     },
//     'P3APX': {
//       PRODUCT_GROUP_NAME: 'BlueScope Zacs Cool',
//       TEMPLATE: 'BlueScope Zacs_TH',
//     },
//     'P3BPC': {
//       PRODUCT_GROUP_NAME: 'BlueScope Zacs Cool',
//       TEMPLATE: 'BlueScope Zacs_TH',
//     },
//     'P3BZP': {
//       PRODUCT_GROUP_NAME: 'BlueScope Zacs Cool',
//       TEMPLATE: 'BlueScope Zacs_TH',
//     },
//     'P3CSS': {
//       PRODUCT_GROUP_NAME: 'BlueScope Zacs Cool',
//       TEMPLATE: 'BlueScope Zacs_TH',
//     },
//     'P3CZL': {
//       PRODUCT_GROUP_NAME: 'BlueScope Zacs Cool',
//       TEMPLATE: 'BlueScope Zacs_TH',
//     },
//     'P3ELM': {
//       PRODUCT_GROUP_NAME: 'BlueScope Zacs Cool',
//       TEMPLATE: 'BlueScope Zacs_TH',
//     },
//     'P3OPA': {
//       PRODUCT_GROUP_NAME: 'BlueScope Zacs Cool',
//       TEMPLATE: 'BlueScope Zacs_TH',
//     },
//     'P3SAH': {
//       PRODUCT_GROUP_NAME: 'BlueScope Zacs Cool',
//       TEMPLATE: 'BlueScope Zacs_TH',
//     },
//     'P3ZAC': {
//       PRODUCT_GROUP_NAME: 'BlueScope Zacs Cool',
//       TEMPLATE: 'BlueScope Zacs_TH',
//     },
//     'P3BPD': {
//       PRODUCT_GROUP_NAME: 'BlueScope Zacs Dazzle',
//       TEMPLATE: 'BlueScope Zacs_TH',
//     },
//     'P3PZD': {
//       PRODUCT_GROUP_NAME: 'BlueScope Zacs Dazzle',
//       TEMPLATE: 'BlueScope Zacs_TH',
//     },
//     'P3BPN': {
//       PRODUCT_GROUP_NAME: 'BlueScope Zacs Natural',
//       TEMPLATE: 'BlueScope Zacs_TH',
//     },
//     'P3PZN': {
//       PRODUCT_GROUP_NAME: 'BlueScope Zacs Natural',
//       TEMPLATE: 'BlueScope Zacs_TH',
//     },
//     'P3PZC': {
//       PRODUCT_GROUP_NAME: 'BlueScope Zacs Paint',
//       TEMPLATE: 'BlueScope Zacs_TH',
//     },
//     'P3PZL': {
//       PRODUCT_GROUP_NAME: 'BlueScope Zacs Paint',
//       TEMPLATE: 'BlueScope Zacs_TH',
//     },
//     'P11UL': {
//       PRODUCT_GROUP_NAME: 'Clean Colorbond',
//       TEMPLATE: 'Colorbond_TH',
//     },
//     'P12UL': {
//       PRODUCT_GROUP_NAME: 'Clean Colorbond',
//       TEMPLATE: 'Colorbond_TH',
//     },
//     'P13UL': {
//       PRODUCT_GROUP_NAME: 'Clean Colorbond',
//       TEMPLATE: 'Colorbond_TH',
//     },
//     'P14UL': {
//       PRODUCT_GROUP_NAME: 'Clean Colorbond',
//       TEMPLATE: 'Colorbond_TH',
//     },
//     'P1CUL': {
//       PRODUCT_GROUP_NAME: 'Clean Colorbond',
//       TEMPLATE: 'Colorbond_TH',
//     },
//     'P11XP': {
//       PRODUCT_GROUP_NAME: 'Clean Colorbond',
//       TEMPLATE: 'Colorbond_TH',
//     },
//     'P12XP': {
//       PRODUCT_GROUP_NAME: 'Clean Colorbond',
//       TEMPLATE: 'Colorbond_TH',
//     },
//     'P13XP': {
//       PRODUCT_GROUP_NAME: 'Clean Colorbond',
//       TEMPLATE: 'Colorbond_TH',
//     },
//     'P14XP': {
//       PRODUCT_GROUP_NAME: 'Clean Colorbond',
//       TEMPLATE: 'Colorbond_TH',
//     },
//     'P1CXP': {
//       PRODUCT_GROUP_NAME: 'Clean Colorbond',
//       TEMPLATE: 'Colorbond_TH',
//     },
//     'C0ALM': {
//       PRODUCT_GROUP_NAME: 'Clean Colorbond',
//       TEMPLATE: 'Colorbond_TH',
//     },
//     'P11GM': {
//       PRODUCT_GROUP_NAME: 'Clean Colorbond',
//       TEMPLATE: 'Colorbond_TH',
//     },
//     'P11XF': {
//       PRODUCT_GROUP_NAME: 'Clean Colorbond',
//       TEMPLATE: 'Colorbond_TH',
//     },
//     'P11XM': {
//       PRODUCT_GROUP_NAME: 'Clean Colorbond',
//       TEMPLATE: 'Colorbond_TH',
//     },
//     'P11XR': {
//       PRODUCT_GROUP_NAME: 'Clean Colorbond',
//       TEMPLATE: 'Colorbond_TH',
//     },
//     'P12CB': {
//       PRODUCT_GROUP_NAME: 'Clean Colorbond',
//       TEMPLATE: 'Colorbond_TH',
//     },
//     'P12PS': {
//       PRODUCT_GROUP_NAME: 'Clean Colorbond',
//       TEMPLATE: 'Colorbond_TH',
//     },
//     'P12XM': {
//       PRODUCT_GROUP_NAME: 'Clean Colorbond',
//       TEMPLATE: 'Colorbond_TH',
//     },
//     'P12XR': {
//       PRODUCT_GROUP_NAME: 'Clean Colorbond',
//       TEMPLATE: 'Colorbond_TH',
//     },
//     'P13PP': {
//       PRODUCT_GROUP_NAME: 'Clean Colorbond',
//       TEMPLATE: 'Colorbond_TH',
//     },
//     'P13PS': {
//       PRODUCT_GROUP_NAME: 'Clean Colorbond',
//       TEMPLATE: 'Colorbond_TH',
//     },
//     'P13RM': {
//       PRODUCT_GROUP_NAME: 'Clean Colorbond',
//       TEMPLATE: 'Colorbond_TH',
//     },
//     'P13XM': {
//       PRODUCT_GROUP_NAME: 'Clean Colorbond',
//       TEMPLATE: 'Colorbond_TH',
//     },
//     'P13XR': {
//       PRODUCT_GROUP_NAME: 'Clean Colorbond',
//       TEMPLATE: 'Colorbond_TH',
//     },
//     'P13XS': {
//       PRODUCT_GROUP_NAME: 'Clean Colorbond',
//       TEMPLATE: 'Colorbond_TH',
//     },
//     'P14GM': {
//       PRODUCT_GROUP_NAME: 'Clean Colorbond',
//       TEMPLATE: 'Colorbond_TH',
//     },
//     'P14XL': {
//       PRODUCT_GROUP_NAME: 'Clean Colorbond',
//       TEMPLATE: 'Colorbond_TH',
//     },
//     'P14XM': {
//       PRODUCT_GROUP_NAME: 'Clean Colorbond',
//       TEMPLATE: 'Colorbond_TH',
//     },
//     'P14XR': {
//       PRODUCT_GROUP_NAME: 'Clean Colorbond',
//       TEMPLATE: 'Colorbond_TH',
//     },
//     'P15XR': {
//       PRODUCT_GROUP_NAME: 'Clean Colorbond',
//       TEMPLATE: 'Colorbond_TH',
//     },
//     'P1BXF': {
//       PRODUCT_GROUP_NAME: 'Clean Colorbond',
//       TEMPLATE: 'Colorbond_TH',
//     },
//     'P1CPO': {
//       PRODUCT_GROUP_NAME: 'Clean Colorbond',
//       TEMPLATE: 'Colorbond_TH',
//     },
//     'P1CPP': {
//       PRODUCT_GROUP_NAME: 'Clean Colorbond',
//       TEMPLATE: 'Colorbond_TH',
//     },
//     'P1CPS': {
//       PRODUCT_GROUP_NAME: 'Clean Colorbond',
//       TEMPLATE: 'Colorbond_TH',
//     },
//     'P1CXE': {
//       PRODUCT_GROUP_NAME: 'Clean Colorbond',
//       TEMPLATE: 'Colorbond_TH',
//     },
//     'P1CXM': {
//       PRODUCT_GROUP_NAME: 'Clean Colorbond',
//       TEMPLATE: 'Colorbond_TH',
//     },
//     'P1CXR': {
//       PRODUCT_GROUP_NAME: 'Clean Colorbond',
//       TEMPLATE: 'Colorbond_TH',
//     },
//     'P1PZC': {
//       PRODUCT_GROUP_NAME: 'Clean Colorbond',
//       TEMPLATE: 'Colorbond_TH',
//     },
//     'P1PZL': {
//       PRODUCT_GROUP_NAME: 'Clean Colorbond',
//       TEMPLATE: 'Colorbond_TH',
//     },
//     'P1SCR': {
//       PRODUCT_GROUP_NAME: 'Clean Colorbond',
//       TEMPLATE: 'Colorbond_TH',
//     },
//     'P1CCP': {
//       PRODUCT_GROUP_NAME: 'CRP',
//       TEMPLATE: 'CRP Antibacterial SD_TH',
//     },
//     'P1CSD': {
//       PRODUCT_GROUP_NAME: 'CRP',
//       TEMPLATE: 'CRP Antibacterial SD_TH',
//     },
//     'P1GCR': {
//       PRODUCT_GROUP_NAME: 'CRP',
//       TEMPLATE: 'CRP Antibacterial SD_TH',
//     },
//     'P1SMP': {
//       PRODUCT_GROUP_NAME: 'CRP',
//       TEMPLATE: 'CRP Antibacterial SD_TH',
//     },
//     'P2DPP': {
//       PRODUCT_GROUP_NAME: 'CRP',
//       TEMPLATE: 'CRP Antibacterial SD_TH',
//     },
//     'P2GCR': {
//       PRODUCT_GROUP_NAME: 'CRP',
//       TEMPLATE: 'CRP Antibacterial SD_TH',
//     },
//     'P3GCR': {
//       PRODUCT_GROUP_NAME: 'CRP',
//       TEMPLATE: 'CRP Antibacterial SD_TH',
//     },
//     'P3JJL': {
//       PRODUCT_GROUP_NAME: 'JingJoe Paint',
//       TEMPLATE: 'JingJoe Lek_TH',
//     },
//     'P3JJS': {
//       PRODUCT_GROUP_NAME: 'JingJoe Paint',
//       TEMPLATE: 'JingJoe Lek_TH',
//     },
//     'P4APX': {
//       PRODUCT_GROUP_NAME: 'JingJoe Paint',
//       TEMPLATE: 'JingJoe Lek_TH',
//     },
//     'P4IND': {
//       PRODUCT_GROUP_NAME: 'JingJoe Paint',
//       TEMPLATE: 'JingJoe Lek_TH',
//     },
//     'P4JJS': {
//       PRODUCT_GROUP_NAME: 'JingJoe Paint',
//       TEMPLATE: 'JingJoe Lek_TH',
//     },
//     'P4PZC': {
//       PRODUCT_GROUP_NAME: 'JingJoe Paint',
//       TEMPLATE: 'JingJoe Lek_TH',
//     },
//     'P4SPN': {
//       PRODUCT_GROUP_NAME: 'JingJoe Paint',
//       TEMPLATE: 'JingJoe Lek_TH',
//     },
//     'Z3JJL': {
//       PRODUCT_GROUP_NAME: 'JingJoe Paint',
//       TEMPLATE: 'JingJoe Lek_TH',
//     },
//     'Z4INT': {
//       PRODUCT_GROUP_NAME: 'JingJoe Paint',
//       TEMPLATE: 'JingJoe Lek_TH',
//     },
//     'Z4JJL': {
//       PRODUCT_GROUP_NAME: 'JingJoe Paint',
//       TEMPLATE: 'JingJoe Lek_TH',
//     },
//     'Z4ZAC': {
//       PRODUCT_GROUP_NAME: 'JingJoe Paint',
//       TEMPLATE: 'JingJoe Lek_TH',
//     },
//     'Z4ZAL': {
//       PRODUCT_GROUP_NAME: 'JingJoe Paint',
//       TEMPLATE: 'JingJoe Lek_TH',
//     },
//     'Z4ZAM': {
//       PRODUCT_GROUP_NAME: 'JingJoe Paint',
//       TEMPLATE: 'JingJoe Lek_TH',
//     },
//     'P1GEM': {
//       PRODUCT_GROUP_NAME: 'Prima',
//       TEMPLATE: 'Prima_TH',
//     },
//     'P1GSS': {
//       PRODUCT_GROUP_NAME: 'Prima',
//       TEMPLATE: 'Prima_TH',
//     },
//     'P21XR': {
//       PRODUCT_GROUP_NAME: 'Prima',
//       TEMPLATE: 'Prima_TH',
//     },
//     'P2APX': {
//       PRODUCT_GROUP_NAME: 'Prima',
//       TEMPLATE: 'Prima_TH',
//     },
//     'P2AUR': {
//       PRODUCT_GROUP_NAME: 'Prima',
//       TEMPLATE: 'Prima_TH',
//     },
//     'P2BZP': {
//       PRODUCT_GROUP_NAME: 'Prima',
//       TEMPLATE: 'Prima_TH',
//     },
//     'P2ELM': {
//       PRODUCT_GROUP_NAME: 'Prima',
//       TEMPLATE: 'Prima_TH',
//     },
//     'P2GEM': {
//       PRODUCT_GROUP_NAME: 'Prima',
//       TEMPLATE: 'Prima_TH',
//     },
//     'P2GSS': {
//       PRODUCT_GROUP_NAME: 'Prima',
//       TEMPLATE: 'Prima_TH',
//     },
//     'P2ITB': {
//       PRODUCT_GROUP_NAME: 'Prima',
//       TEMPLATE: 'Prima_TH',
//     },
//     'P2PAN': {
//       PRODUCT_GROUP_NAME: 'Prima',
//       TEMPLATE: 'Prima_TH',
//     },
//     'P2PMA': {
//       PRODUCT_GROUP_NAME: 'Prima',
//       TEMPLATE: 'Prima_TH',
//     },
//     'P2PRM': {
//       PRODUCT_GROUP_NAME: 'Prima',
//       TEMPLATE: 'Prima_TH',
//     },
//     'P2PZC': {
//       PRODUCT_GROUP_NAME: 'Prima',
//       TEMPLATE: 'Prima_TH',
//     },
//     'P2PZL': {
//       PRODUCT_GROUP_NAME: 'Prima',
//       TEMPLATE: 'Prima_TH',
//     },
//     'P2SPN': {
//       PRODUCT_GROUP_NAME: 'Prima',
//       TEMPLATE: 'Prima_TH',
//     },
//     'B1ZAL': {
//       PRODUCT_GROUP_NAME: 'Zincalume',
//       TEMPLATE: 'Zincalume_TH',
//     },
//     'Z1GAV': {
//       PRODUCT_GROUP_NAME: 'Zincalume',
//       TEMPLATE: 'Zincalume_TH',
//     },
//     'Z1ZAL': {
//       PRODUCT_GROUP_NAME: 'Zincalume',
//       TEMPLATE: 'Zincalume_TH',
//     },
//     'Z1ZAU': {
//       PRODUCT_GROUP_NAME: 'Zincalume',
//       TEMPLATE: 'Zincalume_TH',
//     },
//     'Z1ZSP': {
//       PRODUCT_GROUP_NAME: 'Zincalume',
//       TEMPLATE: 'Zincalume_TH',
//     },
//     'Z2GAV': {
//       PRODUCT_GROUP_NAME: 'Zincalume',
//       TEMPLATE: 'Zincalume_TH',
//     },
//     'Z1ERC': {
//       PRODUCT_GROUP_NAME: 'Endurocore',
//       TEMPLATE: 'Endurocore TH',
//     },
//     'K1DTF': {
//       PRODUCT_GROUP_NAME: 'SuperDyma',
//       TEMPLATE: 'SuperDyma_TH',
//     },
//     'K2DTF': {
//       PRODUCT_GROUP_NAME: 'SuperDyma',
//       TEMPLATE: 'SuperDyma_TH',
//     },
//     'Z1TRC': {
//       PRODUCT_GROUP_NAME: 'TrueCore',
//       TEMPLATE: 'TRUECORE_TH',
//     },
//     'Z1TRU': {
//       PRODUCT_GROUP_NAME: 'TrueCore',
//       TEMPLATE: 'TRUECORE_TH',
//     },
//     'K1DPL': {
//       PRODUCT_GROUP_NAME: 'SuperDyma',
//       TEMPLATE: 'SuperDyma_TH',
//     },
//   },
//   [ENUM.COUNTRY_TYPE.INDONESIA]: {
//     'P13XL': {
//       PRODUCT_GROUP_NAME: 'COLORBOND® XAL',
//       TEMPLATE: 'P13XL COLORBOND® XAL',
//     },
//     'P17UL': {
//       PRODUCT_GROUP_NAME: 'COLORBOND® Ultra',
//       TEMPLATE: 'P17UL COLORBOND® ULTRA',
//     },
//     'P13UL': {
//       PRODUCT_GROUP_NAME: 'COLORBOND® Ultra',
//       TEMPLATE: 'P17UL COLORBOND® ULTRA',
//     },
//     'P13XP': {
//       PRODUCT_GROUP_NAME: 'COLORBOND® XPD',
//       TEMPLATE: 'P13XP COLORBOND® XPD',
//     },
//     'P17XR': {
//       PRODUCT_GROUP_NAME: 'COLORBOND® XRW',
//       TEMPLATE: 'P17XR COLORBOND® XRW',
//     },
//     'P13XR': {
//       PRODUCT_GROUP_NAME: 'COLORBOND® XRW',
//       TEMPLATE: 'P17XR COLORBOND® XRW',
//     },
//     'P1CCP': {
//       PRODUCT_GROUP_NAME: 'CRP® Antibacterial',
//       TEMPLATE: 'P1CCP CRP Antibacterial',
//     },
//     'Z1ZAU': {
//       PRODUCT_GROUP_NAME: 'ZINCALUME® Ultra',
//       TEMPLATE: 'Z1ZAU ZINCALUME® ULTRA',
//     },
//     'Z1ZAL': {
//       PRODUCT_GROUP_NAME: 'ZINCALUME®',
//       TEMPLATE: 'Z1ZAL ZINCALUME®',
//     },
//     'Z1TRC': {
//       PRODUCT_GROUP_NAME: 'TRUECORE®',
//       TEMPLATE: 'Z1TRC TRUECORE®',
//     },
//     'P2KIR': {
//       PRODUCT_GROUP_NAME: 'KIRANA™',
//       TEMPLATE: 'P2KIR KIRANA®',
//     },
//     'P2HRP': {
//       PRODUCT_GROUP_NAME: 'HRP® Antibacterial',
//       TEMPLATE: 'P2HRP HRP Antibacterial',
//     },
//     'P2CCT': {
//       PRODUCT_GROUP_NAME: 'CRP® Foodgrade',
//       TEMPLATE: 'P2CCT CRP FOODGRADE',
//     },
//     'P2BPN': {
//       PRODUCT_GROUP_NAME: 'BLUESCOPE ZACS™ NATURAL',
//       TEMPLATE: 'P2BPN BLUESCOPE ZACS NATURAL',
//     },
//     'P2BPC': {
//       PRODUCT_GROUP_NAME: 'BLUESCOPE ZACS™ COOL',
//       TEMPLATE: 'P2BPC BLUESCOPE ZACS COOL',
//     },
//     'Z2ABD': {
//       PRODUCT_GROUP_NAME: 'ABADI® steel',
//       TEMPLATE: 'Z2ABD ABADI®',
//     },
//     'Z2BZC': {
//       PRODUCT_GROUP_NAME: 'BLUESCOPE ZACS™ BARE',
//       TEMPLATE: 'Z2BZC BLUESCOPE ZACS BARE',
//     },
//     'Z2BZU': {
//       PRODUCT_GROUP_NAME: 'BLUESCOPE ZACS™ TRUSS',
//       TEMPLATE: 'Z2BZU BLUESCOPE ZACS TRUSS',
//     },
//   },
//   [ENUM.COUNTRY_TYPE.MALAYSIA]: {
//     'P15XR': {
//       PRODUCT_GROUP_NAME: 'COLORBOND® - XRW (AZ150)',
//       TEMPLATE: 'CCB XRW_MY_P15XR_200617',
//     },
//     'P15RM': {
//       PRODUCT_GROUP_NAME: 'COLORBOND® - MATT (AZ150)',
//       TEMPLATE: 'CCB MATT_MY_P15RM_200617',
//     },
//     'P1CPP': {
//       PRODUCT_GROUP_NAME: 'COLORBOND® - SPECTRUM (AZ150) ',
//       TEMPLATE: 'CCB SPECTRUM_MY_P1CPP_200617',
//     },
//     'P15PP': {
//       PRODUCT_GROUP_NAME: 'COLORBOND® - SPECTRUM (AZ150)',
//       TEMPLATE: 'CCB SPECTRUM_MY_P15PP_200617',
//     },
//     'P15XP': {
//       PRODUCT_GROUP_NAME: 'COLORBOND® - XPD (AZ150)',
//       TEMPLATE: 'CCB XPD_MY_P15XP_200617',
//     },
//     'P15XS': {
//       PRODUCT_GROUP_NAME: 'COLORBOND® - XPD PSC (AZ150)',
//       TEMPLATE: 'CCB XPD PSC_MY_P15XS_200617',
//     },
//     'P15UL': {
//       PRODUCT_GROUP_NAME: 'COLORBOND® - Ultra  (AZ200)',
//       TEMPLATE: 'CCB ULTRA_MY_P15UL_200617',
//     },
//     'P15UM': {
//       PRODUCT_GROUP_NAME: 'COLORBOND® - Ultra Matt (AZ200)',
//       TEMPLATE: 'CCB ULTRA MATT_MY_P15UM_200617',
//     },
//     'P1CWR': {
//       PRODUCT_GROUP_NAME: 'CAPERO® (AZ150)',
//       TEMPLATE: 'CAPERO_MY_P1CWR_200617',
//     },
//     'P1VRM': {
//       PRODUCT_GROUP_NAME: 'VERMOE™ (AZ150) TBA',
//       TEMPLATE: 'VERMOE_MY_P1VRM_200617',
//     },
//     'P2PRJ': {
//       PRODUCT_GROUP_NAME: 'PRIMAMAJU-R® (AZ100)',
//       TEMPLATE: 'PM-R_MY_P2PRJ_200617',
//     },
//     'Z1ZAL': {
//       PRODUCT_GROUP_NAME: 'ZINCALUME® (AZ150)',
//       TEMPLATE: 'ZL MY_Z1ZAL_200617',
//     },
//     'Z1ZAU': {
//       PRODUCT_GROUP_NAME: 'ZINCALUME® (AZ150)',
//       TEMPLATE: 'ZL MY_Z1ZAL_200617',
//     },
//     'P3BPC': {
//       PRODUCT_GROUP_NAME: 'BlueScope ZACS® (AZ90) COOL',
//       TEMPLATE: 'BS ZACS',
//     },
//     'P3BPN': {
//       PRODUCT_GROUP_NAME: 'BlueScope ZACS® (AZ90) NATURAL',
//       TEMPLATE: 'BS ZACS',
//     },
//     'P3BPU': {
//       PRODUCT_GROUP_NAME: 'BlueScope ZACS® (AZ90) (PANEL)',
//       TEMPLATE: 'BS ZACS',
//     },
//     'Z2BZC': {
//       PRODUCT_GROUP_NAME: 'BlueScope ZACS® (AZ90) (BARE)',
//       TEMPLATE: 'BS ZACS',
//     },
//     'Z1TRC': {
//       PRODUCT_GROUP_NAME: 'TRUECORE® (AZ150)',
//       TEMPLATE: 'TRUECORE MY_Z1TRC_200617',
//     },
//   },
//   [ENUM.COUNTRY_TYPE.VIETNAM]: {
//     'V13UL': {
//       PRODUCT_GROUP_NAME: 'COLORBOND® ULTRA (AM150)',
//       TEMPLATE: 'CCB ULTRA ACTIVATE_VN_V13UL_200626',
//     },
//     'B1MAX': {
//       PRODUCT_GROUP_NAME: 'ZINCALUME® ULTRA (AM150)',
//       TEMPLATE: 'ZL ULTRA _VN_B1MAX_200626',
//     },
//     'P1CPP': {
//       PRODUCT_GROUP_NAME: 'COLORBOND® - FOR PANEL (AM100)',
//       TEMPLATE: 'CCB FOR PANEL_VN_P1CPP_200626',
//     },
//     'P3SUP': {
//       PRODUCT_GROUP_NAME: 'SUMOTM FOR PANEL (AZ70)',
//       TEMPLATE: 'SUMO FOR PANEL_VN_P3SUP_200626',
//     },
//     'P2SUA': {
//       PRODUCT_GROUP_NAME: 'SUMOTM ANTI FADING (AZ100)',
//       TEMPLATE: 'WARRANTY CARD',
//     },
//     'P13XP': {
//       PRODUCT_GROUP_NAME: 'COLORBOND® - XPD (AZ150)',
//       TEMPLATE: 'CCB XPD_VN_X13XP_200626',
//     },
//     'P13XS': {
//       PRODUCT_GROUP_NAME: 'COLORBOND® - XPD PSC (AZ150)',
//       TEMPLATE: 'CCB XPD PSC_VN_P13XS_200616',
//     },
//     'P14UL': {
//       PRODUCT_GROUP_NAME: 'COLORBOND® - Ultra  (AZ200)',
//       TEMPLATE: 'CCB ULTRA_VN_P14UL_200626',
//     },
//     'P13XR': {
//       PRODUCT_GROUP_NAME: 'COLORBOND® - XRW (AZ150)',
//       TEMPLATE: 'CCB XRW_P13XR_200626',
//     },
//     'Z1ZAL': {
//       PRODUCT_GROUP_NAME: 'ZINCALUME® (AZ150)',
//       TEMPLATE: 'ZL VN_Z1ZAL_200626',
//     },
//     'Z1HIB': {
//       PRODUCT_GROUP_NAME: 'HINATA- BARE (AZ150)',
//       TEMPLATE: 'WARRANTY CARD',
//     },
//     'P1HIP': {
//       PRODUCT_GROUP_NAME: 'HINATA- PAINTED (AZ150)',
//       TEMPLATE: 'WARRANTY CARD',
//     },
//   }
// }

const WARRANTY_PRODUCT_GROUP_CAN_GEN_ONLY_NON_RES = [
  'P1CCP',
  'P1CSD',
  'P1GCR',
  'P1SMP',
  'P2GCR',
  'P3GCR',
  'P2DPP',
  'P1DPS',
  'K1DTF',
  'K2DTF',
  'K1DPL',
  'Z1TRC',
  'Z1TRU',
  'Z1ERC',
  'P1PMA',
  'K1DSD',
  'K2DSD',
]

const WARRANTY_PREVIEW_TEMPLATE_NAME_SPECIFIC_CODE_LIST = [
  //prod
  {
    name: 'BLUESCOPE ZACS PAINT_TH',
    code: 'P2BPC'
  },
  {
    name: 'BLUESCOPE ZACS_TH',
    code: 'B2BZC'
  },
  {
    name: 'COLORBOND FOR PANEL_TH',
    code: 'P13IP'
  },
  {
    name: 'COLORBOND_TH',
    code: 'P13PP'
  },
  {
    name: 'COLORBOND XAL_TH',
    code: 'P13XL'
  },
  {
    name: 'COLORBOND_XMA_TH',
    code: 'P13XM'
  },
  {
    name: 'JINGJOE LEK_TH',
    code: 'P3JJL'
  },
  {
    name: 'PP PANEL SD_TH',
    code: 'P2DPP'
  },
  {
    name: 'PRIMA_TH',
    code: 'P1PMA'
  },
  {
    name: 'SUPERDYMA CRP_TH',
    code: 'P1CCP'
  },
  {
    name: 'SUPERDYMA SMARTDUCK K18_TH',
    code: 'K1DSD'
  },
  {
    name: 'SUPERDYMA SMARTDUCT K14_TH',
    code: 'K2DSD'
  },
  {
    name: 'SUPERDYMA SMARTFRAME',
    code: 'K2DTF'
  },
  {
    name: 'SUPERDYMA SMARTPURLIN',
    code: 'K1DPL'
  },
  {
    name: 'TRUECORE_TH',
    code: 'Z1TRC'
  },
  {
    name: 'ZINCALUME_TH',
    code: 'Z1ZAL'
  },
  //stg =====
  {
    name: 'BLUESCOPE_ZACS_COOL_TH',
    code: 'P2BPC',
  },
  {
    name: 'BLUESCOPE_ZACS_TH',
    code: 'B2BZC',
  },
  {
    name: 'COLORBOND_TH',
    code: 'P13PP',
  },
  {
    name: 'COLORBOND_XAL_TH',
    code: 'P13XL',
  },
  {
    name: 'COLORBOND_XMA_TH',
    code: 'P13XM',
  },
  {
    name: 'JINGJOE_LEK_TH',
    code: 'P3JJL',
  },
  {
    name: 'PP_PANEL_SD',
    code: 'P2DPP',
  },
  {
    name: 'PRIMA_P1PMA_TH',
    code: 'P1PMA',
  },
  {
    name: 'PRIMA_TH',
    code: 'P1PMA',
  },
  {
    name: 'SUPERDYMA_CRP',
    code: 'P1CCP',
  },
  {
    name: 'SUPERDYMA_PL',
    code: 'K1DPL',
  },
  {
    name: 'SUPERDYMA_SDUCK_K1DSD_TH',
    code: 'K1DSD',
  },
  {
    name: 'SUPERDYMA_SDUCK_K2DSD_TH',
    code: 'K2DSD',
  },
  {
    name: 'SUPERDYMA_TF',
    code: 'K2DTF',
  },
  {
    name: 'TRUECORE_TH',
    code: 'Z1TRC',
  },
  {
    name: 'ZINCALUME_TH',
    code: 'Z1ZAL',
  },
]

const WARRANRY_AUTOMATIC_INDUSTIAL_NAME = {
  NOT_IN_INDUSTIAL: 'ไม่อยู่ในนิคม',
}

const WARRANTY_ENV_NAME_TYPE = {
  MARINE: 'MR',
  INDUSTRIAL: 'IN',
  BENIGN: undefined,
}

const WARRANTY_AUTOMATIC_ENV_DISTANCE = {
  // 'Very Severe Marine'
  [WARRANTY_ENV_DETAIL_CODE_LIST[0]]: [{
    label: '<200',
    value: WARRANTY_ENV_DETAIL_CODE_LIST[0],
  }, {
    label: '201-400',
    value: WARRANTY_ENV_DETAIL_CODE_LIST[1],
  }, {
    label: '401-1000',
    value: WARRANTY_ENV_DETAIL_CODE_LIST[2],
  }, {
    label: '1001-5000',
    value: WARRANTY_ENV_DETAIL_CODE_LIST[3],
  }, {
    label: '>5000',
    value: WARRANTY_ENV_DETAIL_CODE_LIST[4],
  }],
  // 'Severe Marine'
  [WARRANTY_ENV_DETAIL_CODE_LIST[1]]: [{
    label: '<200',
    value: WARRANTY_ENV_DETAIL_CODE_LIST[0],
  }, {
    label: '201-400',
    value: WARRANTY_ENV_DETAIL_CODE_LIST[1],
  }, {
    label: '401-1000',
    value: WARRANTY_ENV_DETAIL_CODE_LIST[2],
  }, {
    label: '1001-5000',
    value: WARRANTY_ENV_DETAIL_CODE_LIST[3],
  }, {
    label: '>5000',
    value: WARRANTY_ENV_DETAIL_CODE_LIST[4],
  }],
  // 'Marine'
  [WARRANTY_ENV_DETAIL_CODE_LIST[2]]: [{
    label: '<200',
    value: WARRANTY_ENV_DETAIL_CODE_LIST[0],
  }, {
    label: '201-400',
    value: WARRANTY_ENV_DETAIL_CODE_LIST[1],
  }, {
    label: '401-1000',
    value: WARRANTY_ENV_DETAIL_CODE_LIST[2],
  }, {
    label: '1001-5000',
    value: WARRANTY_ENV_DETAIL_CODE_LIST[3],
  }, {
    label: '>5000',
    value: WARRANTY_ENV_DETAIL_CODE_LIST[4],
  }],
  // 'Moderate Marine'
  [WARRANTY_ENV_DETAIL_CODE_LIST[3]]: [{
    label: '<200',
    value: WARRANTY_ENV_DETAIL_CODE_LIST[0],
  }, {
    label: '201-400',
    value: WARRANTY_ENV_DETAIL_CODE_LIST[1],
  }, {
    label: '401-1000',
    value: WARRANTY_ENV_DETAIL_CODE_LIST[2],
  }, {
    label: '1001-5000',
    value: WARRANTY_ENV_DETAIL_CODE_LIST[3],
  }, {
    label: '>5000',
    value: WARRANTY_ENV_DETAIL_CODE_LIST[4],
  }],
  // 'Severe Industrial'
  [WARRANTY_ENV_DETAIL_CODE_LIST[5]]: [{
    label: '0-500',
    value: WARRANTY_ENV_DETAIL_CODE_LIST[5],
  }, {
    label: '501-1500',
    value: WARRANTY_ENV_DETAIL_CODE_LIST[6],
  }, {
    label: '1501-3000',
    value: WARRANTY_ENV_DETAIL_CODE_LIST[7],
  }, {
    label: '3001-5000',
    value: WARRANTY_ENV_DETAIL_CODE_LIST[8],
  }, {
    label: '>5000',
    value: WARRANTY_ENV_DETAIL_CODE_LIST[4],
  }],
  // 'Heavy Industrial'
  [WARRANTY_ENV_DETAIL_CODE_LIST[6]]: [{
    label: '0-1000',
    value: WARRANTY_ENV_DETAIL_CODE_LIST[6],
  }, {
    label: '1001-2000',
    value: WARRANTY_ENV_DETAIL_CODE_LIST[7],
  }, {
    label: '2001-5000',
    value: WARRANTY_ENV_DETAIL_CODE_LIST[8],
  }, {
    label: '>5000',
    value: WARRANTY_ENV_DETAIL_CODE_LIST[4],
  }],
  // 'Industrial'
  [WARRANTY_ENV_DETAIL_CODE_LIST[7]]: [{
    label: '0-1000',
    value: WARRANTY_ENV_DETAIL_CODE_LIST[7],
  }, {
    label: '1001-5000',
    value: WARRANTY_ENV_DETAIL_CODE_LIST[8],
  }, {
    label: '>5000',
    value: WARRANTY_ENV_DETAIL_CODE_LIST[4],
  }],
  // 'Light Ind / Urban'
  [WARRANTY_ENV_DETAIL_CODE_LIST[8]]: [{
    label: '0-5000',
    value: WARRANTY_ENV_DETAIL_CODE_LIST[8],
  }, {
    label: '>5000',
    value: WARRANTY_ENV_DETAIL_CODE_LIST[4],
  }],
}

// CR: 1498
const WARRANTY_HINT_PRODUCTS = [
  'Colorbond',
  'Colorbond Ultra',
];

// CR: 1497
const WARRANTY_YEAR = [
  {
    product: 'Colorbond',
    m3ProductGroups: [
      "P13UM",
      "P14PP",
      "P14UM",
      "P15UM",
      "P16UM",
      "P16XR",
      "P17SX",
      "P17XA",
      "P17XR",
      "P17XS",
      "C0ALM",
      "P11GM",
      "P11XF",
      "P11XR",
      "P12CB",
      "P12XR",
      "P13PP",
      "P13XR",
      "P13XS",
      "P14GM",
      "P14XR",
      "P15XR",
      "P1BXF",
      "P1CPO",
      "P1CPP",
      "P1CXE",
      "P1CXR",
      "P1PZC",
      "P1PZL",
      "P1SCR",
      "P11UL",
      "P12UL",
      "P13UL",
      "P15UL",
      "P16UL",
      "P17UL",
      "P14UL",
      "P1CUL",
      "P13IP",
      "P13XL",
      "P15XL",
      "P14XL",
      "P15XM",
      "P16XM",
      "P17XM",
      "P11XM",
      "P12XM",
      "P13XM",
      "P14XM",
      "P1CXM",
      "P13RM",
      "P15RM",
      "P12PS",
      "P13PS",
      "P1CPS"
    ],
    roof: 20,
    wall: 20,
    ceiling: 10,
    wallInterior: 10,
  },
  {
    product: 'Zincalume',
    m3ProductGroups: [
      "B1ZAL",
      "Z1GAV",
      "Z1ZAL",
      "Z1ZAU",
      "Z1ZSP",
      "Z2GAV"
    ],
    roof: 10,
    wall: 10,
    ceiling: 5,
    wallInterior: 5,
  },
  {
    product: 'SuperDyma CRP',
    m3ProductGroups: [
      "P1CCP",
      "P1CSD",
      "P1GCR",
      "P1SMP",
      "P2GCR",
      "P3GCR"
    ],
    wallInterior: 6,
  },
  {
    product: 'SuperDyma SmartPurlin',
    m3ProductGroups: [
      "K1DPL",
    ],
    trussAndFrameAndPurlin: 6,
  },
  {
    product: 'TrueCore',
    m3ProductGroups: [
      "Z1TRC",
      "Z1TRU"
    ],
    trussAndFrameAndPurlin: 20,
  },
  {
    product: 'BlueScope Zacs Cool',
    m3ProductGroups: [
      "P1SPN",
      "P2BPC",
      "P3APX",
      "P3BPC",
      "P3BPU",
      "P3BZP",
      "P3CSS",
      "P3CZL",
      "P3ELM",
      "P3OPA",
      "P3SAH",
      "P3ZAC",
      "P3BPD",
      "P3PZD",
      "P2BPN",
      "P3BPN",
      "P3PZN",
      "P3PZC",
      "P3PZL"
    ],
    roof: 8,
    wall: 8,
    ceiling: 5,
    wallInterior: 5,
  },
  {
    product: 'BlueScope Zacs',
    m3ProductGroups: [
      "Z3BR7",
      "Z1BZC",
      "B2BZC",
      "Z2BZA",
      "Z2BZC",
      "Z2BZT",
      "Z2ZAC",
      "Z3BZC",
      "Z3ZAC"
    ],
    roof: 5,
    wall: 5,
    ceiling: 3,
    wallInterior: 3,
  },
  {
    product: 'SuperDyma SmartFrame',
    m3ProductGroups: [
      "K1DTF",
      "K2DTF"
    ],
    trussAndFrameAndPurlin: 3,
  },
]

// CR: 1498
const SPECIAL_ENV_WARRANTY_YEAR = [
  {
    product: 'Zincalume',
    env: [
      {
        "environment": "Very severe marine",
        "corrosion": "5",
        "corrosion2": "n/a",
        "peelFlake": "n/a",
        "colorFade": "n/a",
        "dirtStain": "n/a"
      },
      {
        "environment": "Severe marine",
        "corrosion": "10",
        "corrosion2": "n/a",
        "peelFlake": "n/a",
        "colorFade": "n/a",
        "dirtStain": "n/a"
      },
      {
        "environment": "Marine",
        "corrosion": "20",
        "corrosion2": "n/a",
        "peelFlake": "n/a",
        "colorFade": "n/a",
        "dirtStain": "n/a"
      },
      {
        "environment": "Moderate Marine",
        "corrosion": "20",
        "corrosion2": "n/a",
        "peelFlake": "n/a",
        "colorFade": "n/a",
        "dirtStain": "n/a"
      },
      {
        "environment": "Benign",
        "corrosion": "25",
        "corrosion2": "n/a",
        "peelFlake": "n/a",
        "colorFade": "n/a",
        "dirtStain": "n/a"
      }
    ]
  }
]

// CR: 1498
const M3_PRODUCT_GROUP_1498_DISPLAY_YEAR_LIST = [{
  product: 'Colorbond XMA',
  isCorrosion: true,
  isPeelFlake: false,
  isColorFade: false,
  isDirtStain: false,
  m3ProductGroup: [
    'P11XM',
    'P12XM',
    'P13XM',
    'P14XM',
    'P15XM',
    'P16XM',
    'P17XM',
    'P1CXM',
  ],
}, {
  product: 'Colorbond XAL',
  isCorrosion: true,
  isPeelFlake: true,
  isColorFade: true,
  isDirtStain: false,
  m3ProductGroup: [
    'P13XL',
    'P14XL',
    'P15XL',
  ],
}]

// CR: 1498
const M3_PRODUCT_GROUP_1498 = [
  {
    product: 'Colorbond',
    m3ProductGroup: [
      "P13UM", "P14PP", "P14UM", "P15UM", "P16UM", "P16XR", "P17SX", "P17XA", "P17XR", "P17XS",
      "C0ALM", "P11GM", "P11XF", "P11XR", "P12CB", "P12XR", "P13PP", "P13XR", "P13XS", "P14GM",
      "P14XR", "P15XR", "P1BXF", "P1CPO", "P1CPP", "P1CXE", "P1CXR", "P1PZC", "P1PZL", "P1SCR",
      "P13IP", "P13XL", "P15XL", "P14XL", "P15XM", "P16XM", "P17XM", "P11XM", "P12XM", "P13XM",
      "P14XM", "P1CXM", "P13RM", "P15RM", "P12PS", "P13PS", "P1CPS", "P11XP", "P12XP", "P13XP",
      "P14XP", "P15XP", "P1CXP",
      "P15IP", "P17PS",
      "P17IP", "P17XP", "P17RM", "P18XL",
    ],
  },
  {
    product: 'Colorbond Ultra',
    m3ProductGroup: [
      "P11UL", "P12UL", "P13UL", "P15UL", "P16UL", "P17UL", "P14UL", "P1CUL"
    ],
  },
  {
    product: 'Zincalume',
    m3ProductGroup: [
      "B1ZAL", "Z1GAV", "Z1ZAL", "Z1ZSP", "Z2GAV"
    ],
  },
  {
    product: 'Zincalume AZ200',
    m3ProductGroup: [
      "Z1ZAU"
    ],
  },
];

export const WARRANTY = {
  WARRANTY_PRODUCT_GROUP_CAN_GEN_ONLY_NON_RES,
  WARRANTY_TYPE,
  WARRANTY_CATEGORY_TYPE,
  WARRANTY_DETAIL_GROUP_TYPE,
  WARRANTY_TEMPLATE_LIST,
  WARRANTY_CONDITION,
  WARRANTY_TEMPLATE_VI_LIST,
  // WARRANTY_CAN_GENERATE,
  WARRANTY_APP_DETAIL_NAME_LIST,
  WARRANTY_APP_DETAIL_NAME_ENUM_LIST,
  WARRANTY_APP_DETAIL_NAME_SELECT_LIST,
  WARRANTY_ENV_DETAIL,
  WARRANTY_ENV_DETAIL_NAME_LIST,
  // WARRANTY_PRODUCT_GROUP,
  WARRANTY_PRODUCT_TEMPLATE_GROUP,
  WARRANTY_ENV_TYPE,
  WARRANTY_PREVIEW_TEMPLATE_NAME_SPECIFIC_CODE_LIST,
  WARRANTY_AUTOMATIC_ENV_DISTANCE,
  WARRANRY_AUTOMATIC_INDUSTIAL_NAME,
  WARRANTY_ENV_NAME_TYPE,
  WARRANTY_ENV_DETAIL_CODE_LIST,
  WARRANTY_HINT_PRODUCTS,
  WARRANTY_YEAR,
  SPECIAL_ENV_WARRANTY_YEAR,
  M3_PRODUCT_GROUP_1498,
  M3_PRODUCT_GROUP_1498_DISPLAY_YEAR_LIST
}