import styled, {
  // css
} from 'styled-components'
import {
  default as VARIABLES
} from './../../../themes/styles/bases/variables'
// import {
//   default as TYPOGRAPHYS
// } from './../../../themes/styles/bases/typographys'
// import {
//   default as MIXINS
// } from './../../../themes/styles/helpers/mixins'

// Sample using image in style
// url(${require('./../../../themes/images/icons/icon-sample.svg')});

// Wrapper
// ============================================================
export const FieldRadioPanelWrapper = styled.div`
  /* Parent
  ------------------------------- */
  position: relative;

  /* Childrens
  ------------------------------- */
  .field-radio-panel-value {
    cursor: pointer;
    opacity: 0;
    position: absolute;
    z-index: ${VARIABLES.Z_INDEXS.LV_1};
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    &:checked {
      + .field-radio-panel-label {
        border: 1px solid ${VARIABLES.COLORS.PRIMARY_2};

        .field-radio-panel-box {
          background-color: ${VARIABLES.COLORS.PRIMARY_2};

          &:after {
            opacity: 1;
          }
        }
      }
    }
  }

  .field-radio-panel-label {
    transition: ${VARIABLES.TRANSITIONS.DEFAULT};
    overflow: hidden;
    display: flex;
    background-color: ${VARIABLES.COLORS.WHITE};
    border: 1px solid ${VARIABLES.COLORS.GRAY_12};
    border-radius: 12px;
  }

  .field-radio-panel-box {
    transition: ${VARIABLES.TRANSITIONS.DEFAULT};
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex: none;
    padding: 15px 20px;
    background-color: ${VARIABLES.COLORS.GRAY_12};

    &:before,
    &:after {
      content: ' ';
      display: block;
    }

    &:before {
      width: 20px;
      height: 20px;
      border: 1px solid ${VARIABLES.COLORS.WHITE};
      border-radius: 10px;
    }

    &:after {
      transition: ${VARIABLES.TRANSITIONS.DEFAULT};
      opacity: 0;
      transform: translate(-50%, -50%);
      position: absolute;
      z-index: ${VARIABLES.Z_INDEXS.LV_1};
      top: 50%;
      left: 50%;
      width: 10px;
      height: 10px;
      background-color: ${VARIABLES.COLORS.WHITE};
      border-radius: 5px;
    }
  }

  .field-radio-panel-label-text {
    flex: 1;
    padding: 15px 20px;

    .text-base {
      display: inline;
    }
  }

  /* Modifiers
  ------------------------------- */
  /* Media queries
  ------------------------------- */
`
