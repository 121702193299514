import React from 'react'
import ClassNames from 'classnames'
import PropTypes from 'prop-types'
import { osName, } from 'react-device-detect'
import { WarrantyContentWrapper } from './styled'
import { WarrantyBSWMVietnamSignature } from './WarrantyBSWMVietnamSignature'
import { WarrantyBSWMVietnamNote } from './WarrantyBSWMVietnamNote'
import { WarrantyBSWMVietnamCladdingIssuesLists } from './WarrantyBSWMVietnamCladdingIssuesLists'
import { WarrantyBSWMVietnamNotCoveredLists } from './WarrantyBSWMVietnamNotCoveredLists'
import { WarrantyBSWMVietnamProductInformation } from './WarrantyBSWMVietnamProductInformation'
import { WarrantyBSWMVietnamProductConditions } from './WarrantyBSWMVietnamProductConditions'
import { WarrantyBSWMVietnamTableCoilId } from './WarrantyBSWMVietnamTableCoilId'
import { WarrantyBSWMVietnamTableBuilding } from './WarrantyBSWMVietnamTableBuilding'
import { WarrantyBSWMVietnamAdditionalInformation } from './WarrantyBSWMVietnamAdditionalInformation'
import { WarrantyBSWMVietnamApplicationDetail } from './WarrantyBSWMVietnamApplicationDetail'
import {
  Section,
  Text,
  Image,
  Grid,
  Table,
  ShapeContainer,
} from './../../../../components'
import { VARIABLES, } from './../../../../themes'
import { displayDate } from '../../../../helpers/functions/display'

/**
 * Warranty description:
 * - Warranty
 */

export class WarrantyBSWMVietnamCcbCustomXpdVnP17xr extends React.PureComponent {
  render() {
    const {
      registeredTrademarkName,
      customerName,
      projectName,
      projectAddress,
      perforateByCorrosionYear,
      peelOrFlakeExcessivelyYear,
      colourFadeExcessivelyYear,
      discolourByDirtStainingYear,
      roofArea,
      thickness,
      width,
      grade,
      color,
      roofProfileNameInM2,
      wallProfileNameInM2,
      dataGroupList,
      currentDate,
      additionalProjectInformation,
      roof,
      wall,
      purlinAndGirt,
      trussAndFrame,
      coolRoomPanel,
      decking,
      isHideApplicationDetail,
    } = this.props
    let cntDisplayCoil = 0;
    let cntDisplayCustomerRemark = 0;
    let cntPageProduct = 0;
    let cntPageApplication = 0;
    let cntPageLimit = 10;
    let totalRowProductRow = 0;
    let totalApplicationRow = 0;
    dataGroupList.forEach(e => {
      totalRowProductRow += e.displayCoil.length
      totalApplicationRow += e.applicationInfoList.length;
    });
    const totalRow = totalRowProductRow + totalApplicationRow;
    const totalPageProduct = Math.ceil(totalRowProductRow / 10);
    const totalPageApplication = Math.ceil(totalApplicationRow / 10);

    // props for css classes
    const classes = ClassNames(
      'warranty-bswm-content',
    )

    return (
      <React.Fragment>
        {/* Page 1 */}
        <WarrantyContentWrapper
          className={classes}
        >
          <div className='warranty-bswm-container is-spacebetween'>
            <div className='warranty-bswm-section'>
              <Section spacingBottom='15'><p className='warranty-bswm-text is-huge is-bold is-center'>WARRANTY</p></Section>
              <Section spacingBottom='15'>
                <WarrantyBSWMVietnamProductInformation
                  registeredTrademarkName={registeredTrademarkName}
                  customerName={customerName}
                  projectName={projectName}
                  projectAddress={projectAddress}
                  // perforateByCorrosion={''}
                  perforateByCorrosionYear={perforateByCorrosionYear}
                  // peelOrFlakeExcessively={''}
                  peelOrFlakeExcessivelyYear={peelOrFlakeExcessivelyYear}
                  // colourFadeExcessively={''}
                  colourFadeExcessivelyYear={colourFadeExcessivelyYear}
                  // discolourByDirtStaining={''}
                  discolourByDirtStainingYear={discolourByDirtStainingYear}
                />
              </Section>
              <Section paddingHorizontal='15'>
                <WarrantyBSWMVietnamProductConditions
                  registeredTrademarkName={registeredTrademarkName}
                  customerName={customerName}
                  objectName={'cladding'}
                  isSteel
                />
                <Section spacingBottom='10'>
                  <Grid gutter='30'>
                    <Grid.Column>
                      <p className='warranty-bswm-text is-sm'>Materials Used:</p>
                    </Grid.Column>
                    <Grid.Column>
                      <Section spacingBottom='5'>
                        <p className='warranty-bswm-text is-sm is-bold'>
                          Area of Cladding:
                          {/* {roofArea} sq.m */}
                        </p>
                      </Section>
                      <p className='warranty-bswm-text is-sm'>Refer Attachment for details</p>
                    </Grid.Column>
                  </Grid>
                </Section>
                {additionalProjectInformation &&
                  <WarrantyBSWMVietnamAdditionalInformation additionalProjectInformation={additionalProjectInformation} />
                }
              </Section>
            </div>
            <div className='warranty-bswm-section'>
              <Section spacingBottom='20'>
                <WarrantyBSWMVietnamSignature customerName={customerName} date={currentDate} />
              </Section>
              <WarrantyBSWMVietnamNote customerName={customerName} />
            </div>
          </div>
        </WarrantyContentWrapper>
        {/* Page 2 */}
        {Array.from({ length: totalRow > cntPageLimit ? totalPageProduct + totalPageApplication : 1 }).map((page, pageIndex) => {
          cntPageProduct = pageIndex * cntPageLimit;
          cntPageApplication = totalRow > cntPageLimit ? (pageIndex - totalPageProduct) * cntPageLimit : cntPageProduct;

          return (
            <WarrantyContentWrapper key={pageIndex}
              className={classes}
            >
              <div className='warranty-bswm-container is-spacebetween'>
                <div className='warranty-bswm-section'>
                  <Section spacingTop='1' spacingBottom='15'><p className='warranty-bswm-text is-huge is-bold is-center'>WARRANTY</p></Section>
                  <Section spacingBottom='15'>
                    <WarrantyBSWMVietnamProductInformation
                      registeredTrademarkName={registeredTrademarkName}
                      customerName={customerName}
                      projectName={projectName}
                      projectAddress={projectAddress}
                      // perforateByCorrosion={''}
                      perforateByCorrosionYear={''}
                      // peelOrFlakeExcessively={''}
                      peelOrFlakeExcessivelyYear={''}
                      // colourFadeExcessively={''}
                      colourFadeExcessivelyYear={''}
                      // discolourByDirtStaining={''}
                      discolourByDirtStainingYear={''}
                    />
                  </Section>
                  <Section paddingHorizontal='15'>
                    {cntDisplayCoil < totalRowProductRow &&
                      <React.Fragment>
                        <Section spacingBottom='10'>
                          <p className='warranty-bswm-text'>Attachment {pageIndex + 1}</p>
                        </Section>
                        <Section width='690' spacingBottom='15'>
                          <WarrantyBSWMVietnamTableCoilId>
                            {dataGroupList.map((e, i) => {
                              return (
                                <Table.Section key={i}>
                                  {e.displayCoil.map((ec, ic) => {
                                    if (ic >= cntPageProduct && ic < cntPageProduct + cntPageLimit) {
                                      cntDisplayCoil += 1;
                                      return (
                                        <Table.Row key={ic}>
                                          <Table.Column className='is-body-column' alignCenter maxWidth='50' minWidth='50' paddingVertical='4' paddingHorizontal='4'>
                                            <p className='warranty-bswm-text is-sm is-bold'>{cntDisplayCoil}</p>
                                          </Table.Column>
                                          <Table.Column className='is-body-column' maxWidth='400' minWidth='400' paddingVertical='4' paddingHorizontal='4'>
                                            <p className='warranty-bswm-text is-sm is-bold'>{e.coilNameList[ic]}</p>
                                          </Table.Column>
                                          <Table.Column className='is-body-column' maxWidth='240' minWidth='240' paddingVertical='4' paddingHorizontal='4'>
                                            <p className='warranty-bswm-text is-sm is-bold'>{e.coilIdList[ic]}</p>
                                          </Table.Column>
                                        </Table.Row>
                                      )
                                    }
                                  })}
                                </Table.Section>
                              )
                            })}
                          </WarrantyBSWMVietnamTableCoilId>
                        </Section>
                      </React.Fragment>
                    }
                    {isHideApplicationDetail || cntPageApplication < 0 ?
                      undefined
                      :
                      <React.Fragment>
                        <Section spacingBottom='15'>
                          <WarrantyBSWMVietnamApplicationDetail
                            roof={roof}
                            wall={wall}
                            purlinAndGirt={purlinAndGirt}
                            trussAndFrame={trussAndFrame}
                            coolRoomPanel={coolRoomPanel}
                            decking={decking}
                          />
                        </Section>
                        <Section width='690' spacingBottom='15'>
                          <WarrantyBSWMVietnamTableBuilding>
                            {dataGroupList.map((e, i) => {
                              return (
                                <Table.Section key={i}>
                                  {e.applicationInfoList.map((ec, ic) => {
                                    if (ic >= cntPageApplication && ic < cntPageApplication + cntPageLimit) {
                                      cntDisplayCustomerRemark += 1;
                                      return (
                                        <Table.Row key={ic}>
                                          <Table.Column className='is-body-column' alignCenter maxWidth='50' minWidth='50' paddingVertical='4' paddingHorizontal='4'>
                                            <p className='warranty-bswm-text is-sm is-bold'>{cntDisplayCustomerRemark}</p>
                                          </Table.Column>
                                          <Table.Column className='is-body-column' maxWidth='200' minWidth='200' paddingVertical='4' paddingHorizontal='4'>
                                            <p className='warranty-bswm-text is-sm is-bold'>{ec.building}</p>
                                          </Table.Column>
                                          <Table.Column className='is-body-column' alignCenter maxWidth='200' minWidth='200' paddingVertical='4' paddingHorizontal='4'>
                                            <p className='warranty-bswm-text is-sm is-bold'>{displayDate(ec.projectInsDateTime)}</p>
                                          </Table.Column>
                                          <Table.Column className='is-body-column' maxWidth='240' minWidth='240' paddingVertical='4' paddingHorizontal='4'>
                                            <Section>
                                              {/* <p className='warranty-bswm-text is-sm is-bold'>Roof: {roofProfileNameInM2}</p>
                                            <p className='warranty-bswm-text is-sm is-bold'>Wall: {wallProfileNameInM2}</p> */}
                                              <p className='warranty-bswm-text is-sm is-bold'>{ec.customerRemark}</p>
                                            </Section>
                                          </Table.Column>
                                        </Table.Row>
                                      )
                                    }
                                  })}
                                </Table.Section>
                              )
                            })}
                          </WarrantyBSWMVietnamTableBuilding>
                        </Section>
                      </React.Fragment>
                    }
                  </Section>
                </div>
                <div className='warranty-bswm-section'>
                  <Section spacingBottom='20'>
                    <WarrantyBSWMVietnamSignature customerName={customerName} date={currentDate} />
                  </Section>
                  <WarrantyBSWMVietnamNote customerName={customerName} />
                </div>
              </div>
            </WarrantyContentWrapper>
          )
        })}
        {/* Page 3 */}
        <WarrantyContentWrapper
          className={classes}
        >
          <Section spacingBottom='10'><p className='warranty-bswm-text is-bold is-underline'>TERMS AND CONDITIONS</p></Section>
          <Section spacingBottom='5'><p className='warranty-bswm-text is-xs'>This warranty is your guarantee that <span className='warranty-bswm-text is-xs is-bold'>{registeredTrademarkName}</span> prepainted steel, if properly installed and maintained, will meet minimum expected performance levels for at least the warranted period (commencing from the date of installation).</p></Section>
          <Section spacingBottom='5'><p className='warranty-bswm-text is-xs'>However, certain specific problems and situations (listed below) must be addressed and rectified early in order to get the best possible performance and, if not rectified, may result in the warranty being void.</p></Section>
          <Section spacingBottom='5'>
            <Grid>
              <Grid.Column flex='none'><Section width='17'><p className='warranty-bswm-text is-xs'>1.</p></Section></Grid.Column>
              <Grid.Column flex='1'><p className='warranty-bswm-text is-xs'>The warranty applies only to cladding and flashing made from prime quality <span className='warranty-bswm-text is-xs is-bold'>{registeredTrademarkName}</span> steel. The Owner/Contractor is advised to check for the <span className='warranty-bswm-text is-xs is-bold'>{registeredTrademarkName}</span> steel brand name printed on the reverse of the sheets prior to installation. Guttering, downpipes, brackets, doors and other accessories are excluded.</p></Grid.Column>
            </Grid>
          </Section>
          <Section>
            <Grid>
              <Grid.Column flex='none'><Section width='17'><p className='warranty-bswm-text is-xs'>2.</p></Section></Grid.Column>
              <Grid.Column flex='1'><p className='warranty-bswm-text is-xs'>Cladding must be handled and installed in accordance with published BlueScope guidelines current at the time of installation, with particular attention to the issues listed below:</p></Grid.Column>
            </Grid>
          </Section>
          <Section paddingLeft='17' spacingBottom='5'>
            <WarrantyBSWMVietnamCladdingIssuesLists />
          </Section>
          <Section spacingBottom='5'>
            <Grid>
              <Grid.Column flex='none'><Section width='17'><p className='warranty-bswm-text is-xs'>3.</p></Section></Grid.Column>
              <Grid.Column flex='1'><p className='warranty-bswm-text is-xs'>Fasteners must comply with AS3566 Class 3 or Class 4,and be certified as such by the supplier of fasteners.</p></Grid.Column>
            </Grid>
          </Section>
          <Section spacingBottom='5'>
            <Grid>
              <Grid.Column flex='none'><Section width='17'><p className='warranty-bswm-text is-xs'>4.</p></Section></Grid.Column>
              <Grid.Column flex='1'><p className='warranty-bswm-text is-xs'>All fixings, flashings and associated components must be manufactured from compatible metals (<span className='warranty-bswm-text is-xs is-bold'>COLORBOND®</span>, <span className='warranty-bswm-text is-xs is-bold'>ZINCALUME®</span>, steel, zinc, or aluminium) or be coated or plated in accordance with current published BlueScope guidelines. For long lasting consistency in appearance, flashings should be made from the same material as the cladding.</p></Grid.Column>
            </Grid>
          </Section>
          <Section spacingBottom='5'>
            <Grid>
              <Grid.Column flex='none'><Section width='17'><p className='warranty-bswm-text is-xs'>5.</p></Section></Grid.Column>
              <Grid.Column flex='1'><p className='warranty-bswm-text is-xs'>The paint finish as supplied must not be scratched, abraded damaged, or coated with any foreign matter or incompatible material (including stack and flue deposits).</p></Grid.Column>
            </Grid>
          </Section>
          <Section>
            <Grid>
              <Grid.Column flex='none'><Section width='17'><p className='warranty-bswm-text is-xs'>6.</p></Section></Grid.Column>
              <Grid.Column flex='1'><p className='warranty-bswm-text is-xs'>Product failures wholly or in part due to the following causes are not covered by this warranty.</p></Grid.Column>
            </Grid>
          </Section>
          <Section paddingLeft='17' spacingBottom='5'>
            <WarrantyBSWMVietnamNotCoveredLists />
          </Section>
          <Section spacingBottom='5'>
            <Grid>
              <Grid.Column flex='none'><Section width='17'><p className='warranty-bswm-text is-xs'>7.</p></Section></Grid.Column>
              <Grid.Column flex='1'><p className='warranty-bswm-text is-xs'>Failure of the product from corrosion is defined as when the sheet is perforated. Surface rust, or minor corrosion at tension bends or cuts edges due to natural weathering does not constitute failure of the product.</p></Grid.Column>
            </Grid>
          </Section>
          <Section spacingBottom='5'>
            <Grid>
              <Grid.Column flex='none'><Section width='17'><p className='warranty-bswm-text is-xs'>8.</p></Section></Grid.Column>
              <Grid.Column flex='1'><p className='warranty-bswm-text is-xs'>Failure of the product in terms of fading and dirt staining is deﬁned as per the following table.</p></Grid.Column>
            </Grid>
          </Section>
          <Section spacingBottom='5' paddingLeft='17'>
            <Table fluid warranty>
              <Table.Section>
                <Table.Row>
                  <Table.Column className='is-head-column' alignCenter maxWidth='200' minWidth='200' paddingVertical='4' paddingHorizontal='4'>
                    <Section spacingTop='11'><p className='warranty-bswm-text is-xs is-bold'>Colour Shade</p></Section>
                  </Table.Column>
                  <Table.Column className='is-head-column' alignCenter maxWidth='200' minWidth='200' paddingVertical='4' paddingHorizontal='4'>
                    <p className='warranty-bswm-text is-xs is-bold'>Maximum Fading <br /> (ΔE units CIELAB) <br /> ASTM D-2244 (Cleaned)</p>
                  </Table.Column>
                  <Table.Column className='is-head-column' alignCenter maxWidth='200' minWidth='200' paddingVertical='4' paddingHorizontal='4'>
                    <p className='warranty-bswm-text is-xs is-bold'>Maximum Discolouration by <br /> Dirt Staining (ΔL units CIELAB) <br /> ASTM D-2244 (Cleaned)</p>
                  </Table.Column>
                </Table.Row>
              </Table.Section>
              <Table.Section>
                <Table.Row>
                  <Table.Column className='is-body-column' maxWidth='200' minWidth='200' paddingVertical='4' paddingHorizontal='4'>
                    <p className='warranty-bswm-text is-xs'>Light (e.g., Off white)</p>
                  </Table.Column>
                  <Table.Column className='is-body-column' maxWidth='200' minWidth='200' paddingVertical='4' paddingHorizontal='4'>
                    <Section><p className='warranty-bswm-text is-xs is-center'>6</p></Section>
                  </Table.Column>
                  <Table.Column className='is-body-column' maxWidth='200' minWidth='200' paddingVertical='4' paddingHorizontal='4'>
                    <Section><p className='warranty-bswm-text is-xs is-center'>≥ -5</p></Section>
                  </Table.Column>
                </Table.Row>
                <Table.Row>
                  <Table.Column className='is-body-column' maxWidth='200' minWidth='200' paddingVertical='4' paddingHorizontal='4'>
                    <p className='warranty-bswm-text is-xs'>Intermediate (e.g., Beige)</p>
                  </Table.Column>
                  <Table.Column className='is-body-column' maxWidth='200' minWidth='200' paddingVertical='4' paddingHorizontal='4'>
                    <Section><p className='warranty-bswm-text is-xs is-center'>9</p></Section>
                  </Table.Column>
                  <Table.Column className='is-body-column' maxWidth='200' minWidth='200' paddingVertical='4' paddingHorizontal='4'>
                    <Section><p className='warranty-bswm-text is-xs is-center'>≥ -4</p></Section>
                  </Table.Column>
                </Table.Row>
                <Table.Row>
                  <Table.Column className='is-body-column' maxWidth='200' minWidth='200' paddingVertical='4' paddingHorizontal='4'>
                    <p className='warranty-bswm-text is-xs'>Dark (e.g. Torres Blue) </p>
                  </Table.Column>
                  <Table.Column className='is-body-column' maxWidth='200' minWidth='200' paddingVertical='4' paddingHorizontal='4'>
                    <Section><p className='warranty-bswm-text is-xs is-center'>15</p></Section>
                  </Table.Column>
                  <Table.Column className='is-body-column' maxWidth='200' minWidth='200' paddingVertical='4' paddingHorizontal='4'>
                    <Section><p className='warranty-bswm-text is-xs is-center'>≥ -3</p></Section>
                  </Table.Column>
                </Table.Row>
                <Table.Row>
                  <Table.Column className='is-body-column' maxWidth='600' minWidth='600' paddingVertical='4' paddingHorizontal='4'>
                    <Section>
                      <p className='warranty-bswm-text is-xs is-center'>L{'<'}40 is DARK, L between 40 and 70 is INTERMEDIATE and L{'>'}70 is LIGHT</p>
                    </Section>
                  </Table.Column>
                </Table.Row>
              </Table.Section>
            </Table>
          </Section>
          <Section paddingLeft='17'>
            <p className='warranty-bswm-text is-xs'>Ref : 03-0120-Clean-F12-T8-Cladding</p>
          </Section>
        </WarrantyContentWrapper>
      </React.Fragment>
    )
  }

  static defaultProps = {
    // If you set new default props, you should create propsType for correct styleguide document.
    registeredTrademarkName: 'COLORBOND® Custom XPD',
    customerName: '< Customer Name >',
    projectName: '< Full Project Name >',
    projectAddress: '< Full Project Address >',
    perforateByCorrosionYear: 'XX',
    peelOrFlakeExcessivelyYear: 'XX',
    colourFadeExcessivelyYear: 'XX',
    discolourByDirtStainingYear: 'XX',
    roofArea: '< Roof Area >',
    thickness: '< Thickness >',
    width: '< Width >',
    grade: '< Grade >',
    color: '< Color >',
    roofProfileNameInM2: '< Profile Name in m2 >',
    wallProfileNameInM2: '< Profile Name in m2 >',
    additionalProjectInformation: '< Additional Project Information >',
    roof: 'XX',
    wall: 'XX',
    purlinAndGirt: 'XX',
    trussAndFrame: 'XX',
    coolRoomPanel: 'XX',
    decking: 'XX',
  }

  static propTypes = {
    // TYPE > node, string, number, bool, array, object, symbol, func
  }
}
