import React from 'react'
import {
  PieChart,
  Pie,
  Tooltip,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Cell
} from 'recharts'
import ClassNames from 'classnames'
import PropTypes from 'prop-types'
import {
  ChartBaseWrapper
} from './styled'
import {
  default as VARIABLES
} from './../../../themes/styles/bases/variables'
// import {
//   default as TYPOGRAPHYS
// } from './../../../themes/styles/bases/typographys'

/**
 * ChartBase description:
 * - Reference: https://github.com/recharts/recharts
 */

export class ChartBase extends React.PureComponent {
  render () {
    const {
      className,
      pie,
      label,
      labelLine,
      data,
      innerRadius,
      outerRadius,
      fill,
      width,
      height,
      cx,
      cy,
      progress,
      progressPercent,
      progressWidth,
      progressWidthUnit,
      progressHeight,
      progressTrackPadding,
      progressTrackColor,
      progressColor,
      percentNumberFontStyle,
      percentNumberSpacingLeft,
      nonFluid,
      chartWidth,
      chartWidthUnit,
      chartHeight,

      twoBar,
      twoBarVertical,
      threeBar,
      dataKeyXAxis,
      dataKeyBarFirst,
      dataKeyBarSecond,
      dataKeyBarThird,

      stackBar,

      pieMultipleColor,
      pieMultipleCellColors


    } = this.props

    // ### Sample svg element for custom svg style in chart ###
    // const BarLabel = (props) => {
    //   const { x, y, value } = props
    //
    //   return <text
    //       x={x}
    //       y={y}
    //       dy={-5}
    //       fontSize={VARIABLES.FONT.FAMILIES.FIRST.SIZES.FS_10}
    //       fontFamily={VARIABLES.FONT.FAMILIES.FIRST.WEIGHTS.REGULAR}
    //       fill={VARIABLES.COLORS.GRAY_26}
    //       textAnchor='middle'
    //     >
    //       {value}
    //     </text>
    // }

    const BarFirst = (props) => {
      const { x, y, width, height } = props

      return <svg>
        <defs>
          <linearGradient id='gradientFirst' x1='0%' y1='0%' x2='0%' y2='100%' >
            <stop offset='0%' style={{ stopColor: VARIABLES.COLORS.PURPLE_1, stopOpacity: 1 }} />
            <stop offset='100%' style={{ stopColor: VARIABLES.COLORS.PURPLE_2, stopOpacity: 1 }} />
          </linearGradient>
        </defs>
        <rect x={x} y={y} width={width} height={height} fill='url(#gradientFirst)' />
      </svg>
    }

    const BarSecond = (props) => {
      const { x, y, width, height } = props

      return <svg>
        <defs>
          <linearGradient id='gradientSecond' x1='0%' y1='0%' x2='0%' y2='100%' >
            <stop offset='0%' style={{ stopColor: VARIABLES.COLORS.BLUE_18, stopOpacity: 1 }} />
            <stop offset='100%' style={{ stopColor: VARIABLES.COLORS.BLUE_25, stopOpacity: 1 }} />
          </linearGradient>
        </defs>
        <rect x={x} y={y} width={width} height={height} fill='url(#gradientSecond)' />
      </svg>
    }

    const BarThird = (props) => {
      const { x, y, width, height } = props

      return <svg>
        <defs>
          <linearGradient id='gradientThird' x1='0%' y1='0%' x2='0%' y2='100%' >
            <stop offset='0%' style={{ stopColor: VARIABLES.COLORS.GREEN_1, stopOpacity: 1 }} />
            <stop offset='100%' style={{ stopColor: VARIABLES.COLORS.GREEN_7, stopOpacity: 1 }} />
          </linearGradient>
        </defs>
        <rect x={x} y={y} width={width} height={height} fill='url(#gradientThird)' />
      </svg>
    }

    const BarFirstHorizontal = (props) => {
      const { x, y, width, height } = props

      return <svg>
        <defs>
          <linearGradient id='gradientFirstHorizontal' x1='0%' y1='0%' x2='100%' y2='0%' >
            <stop offset='0%' style={{ stopColor: VARIABLES.COLORS.PURPLE_2, stopOpacity: 1 }} />
            <stop offset='100%' style={{ stopColor: VARIABLES.COLORS.PURPLE_1, stopOpacity: 1 }} />
          </linearGradient>
        </defs>
        <rect x={x} y={y} width={width} height={height} fill='url(#gradientFirstHorizontal)' />
      </svg>
    }

    const BarSecondHorizontal = (props) => {
      const { x, y, width, height } = props

      return <svg>
        <defs>
          <linearGradient id='gradientSecondHorizontal' x1='0%' y1='0%' x2='100%' y2='0%' >
            <stop offset='0%' style={{ stopColor: VARIABLES.COLORS.BLUE_25, stopOpacity: 1 }} />
            <stop offset='100%' style={{ stopColor: VARIABLES.COLORS.BLUE_18, stopOpacity: 1 }} />
          </linearGradient>
        </defs>
        <rect x={x} y={y} width={width} height={height} fill='url(#gradientSecondHorizontal)' />
      </svg>
    }

    const BAR_LABEL_STYLE = {
      position: 'top',
      fontSize: VARIABLES.FONT.FAMILIES.FIRST.SIZES.FS_10,
      fontFamily: VARIABLES.FONT.FAMILIES.FIRST.WEIGHTS.REGULAR,
      fill: VARIABLES.COLORS.GRAY_26
    }

    const BAR_LABEL_RIGHT_STYLE = {
      position: 'right',
      fontSize: VARIABLES.FONT.FAMILIES.FIRST.SIZES.FS_10,
      fontFamily: VARIABLES.FONT.FAMILIES.FIRST.WEIGHTS.REGULAR,
      fill: VARIABLES.COLORS.GRAY_26
    }

    const PIE_LABEL_STYLE = {
      fontSize: VARIABLES.FONT.FAMILIES.FIRST.SIZES.FS_12,
      fontFamily: VARIABLES.FONT.FAMILIES.FIRST.WEIGHTS.REGULAR,
      dy: 7
    }

    const XAXIS_LABEL_STYLE = {
      fontSize: VARIABLES.FONT.FAMILIES.FIRST.SIZES.FS_11,
      fontFamily: VARIABLES.FONT.FAMILIES.FIRST.WEIGHTS.REGULAR,
      fill: VARIABLES.COLORS.GRAY_26,
      dy: 8
    }

    const YAXIS_LABEL_STYLE = {
      fontSize: VARIABLES.FONT.FAMILIES.FIRST.SIZES.FS_11,
      fontFamily: VARIABLES.FONT.FAMILIES.FIRST.WEIGHTS.REGULAR,
      fill: VARIABLES.COLORS.GRAY_26,
      dx: -20
    }

    return (
      <ChartBaseWrapper
        className={ClassNames(
          'chart-base',
          {'is-non-fluid': nonFluid},
          className
        )}
        theme={{
          progressWidth,
          progressWidthUnit,
          progressHeight,
          progressTrackPadding,
          progressTrackColor,
          progressColor,
          percentNumberFontStyle,
          percentNumberSpacingLeft,
          chartWidth,
          chartWidthUnit,
          chartHeight
        }}
      >
        {/*  Pie chart examples: http://recharts.org/en-US/examples/TwoSimplePieChart  */}
        {/*  Responsive issue: https://github.com/recharts/recharts/issues/1136#issuecomment-386478593 */}
        {pie &&
          <PieChart
            width={label ? width : ((outerRadius * 2) + 12)}
            height={label ? height : ((outerRadius * 2) + 12)}
          >
            <Pie
              label={label}
              dataKey='value'
              data={data}
              cx={label ? cx : outerRadius}
              cy={label ? cy : outerRadius}
              innerRadius={innerRadius}
              outerRadius={outerRadius}
              fill={fill}
              paddingAngle={1}
            />
          </PieChart>
        }

        {progress &&
          <div className='chart-progress-base'>
            <div className='chart-progress-container-base'>
              <div className='chart-progress-track-base'>
                <div className='chart-progress-percent-base'
                  style={{
                    width: progressPercent + `%`
                  }}
                />
              </div>
            </div>
            <div className='chart-progress-percent-number-base'>
              {progressPercent}%
            </div>
          </div>
        }

        {twoBar &&
          <ResponsiveContainer>
            <BarChart
              margin={{ top: 15 }}
              barSize={16}
              barGap={5}
              data={data}
            >
              <CartesianGrid
                vertical={false}
                stroke={VARIABLES.COLORS.BLUE_2}
              />
              <XAxis
                dataKey={dataKeyXAxis}
                stroke={VARIABLES.COLORS.BLUE_2}
                strokeWidth={1}
                tickSize={0}
                tick={XAXIS_LABEL_STYLE}
              />
              <YAxis
                stroke={VARIABLES.COLORS.BLUE_2}
                strokeWidth={1}
                tickSize={0}
                tick={YAXIS_LABEL_STYLE}
              />
              <Bar
                dataKey={dataKeyBarFirst}
                label={BAR_LABEL_STYLE}
                fill={VARIABLES.COLORS.PURPLE_1}
                shape={<BarFirst />}
              />
              <Bar
                dataKey={dataKeyBarSecond}
                label={BAR_LABEL_STYLE}
                fill={VARIABLES.COLORS.BLUE_18}
                shape={<BarSecond />}
              />
            </BarChart>
          </ResponsiveContainer>
        }

        {twoBarVertical &&
          <ResponsiveContainer>
            <BarChart
              layout='vertical'
              margin={{ top: 15 }}
              barSize={16}
              barGap={5}
              data={data}
            >
              <CartesianGrid
                stroke={VARIABLES.COLORS.BLUE_2}
              />
              <XAxis
                type='number'
                stroke={VARIABLES.COLORS.BLUE_2}
                strokeWidth={1}
                tickSize={0}
                tick={XAXIS_LABEL_STYLE}
              />
              <YAxis
                type='category'
                dataKey={dataKeyXAxis}
                stroke={VARIABLES.COLORS.BLUE_2}
                strokeWidth={1}
                tickSize={0}
                tick={YAXIS_LABEL_STYLE}
              />
              <Bar
                dataKey={dataKeyBarFirst}
                label={BAR_LABEL_RIGHT_STYLE}
                fill={VARIABLES.COLORS.PURPLE_1}
                shape={<BarFirstHorizontal />}
              />
              <Bar
                dataKey={dataKeyBarSecond}
                label={BAR_LABEL_RIGHT_STYLE}
                fill={VARIABLES.COLORS.BLUE_18}
                shape={<BarSecondHorizontal />}
              />
            </BarChart>
          </ResponsiveContainer>
        }

        {threeBar &&
          <ResponsiveContainer>
            <BarChart
              margin={{ top: 15 }}
              barSize={16}
              barGap={5}
              data={data}
            >
              <CartesianGrid
                vertical={false}
                stroke={VARIABLES.COLORS.BLUE_2}
              />
              <XAxis
                dataKey={dataKeyXAxis}
                stroke={VARIABLES.COLORS.BLUE_2}
                strokeWidth={1}
                tickSize={0}
                tick={XAXIS_LABEL_STYLE}
              />
              <YAxis
                stroke={VARIABLES.COLORS.BLUE_2}
                strokeWidth={1}
                tickSize={0}
                tick={YAXIS_LABEL_STYLE}
              />
              <Bar
                dataKey={dataKeyBarFirst}
                label={BAR_LABEL_STYLE}
                fill={VARIABLES.COLORS.PURPLE_1}
                shape={<BarFirst />}
              />
              <Bar
                dataKey={dataKeyBarSecond}
                label={BAR_LABEL_STYLE}
                fill={VARIABLES.COLORS.BLUE_18}
                shape={<BarSecond />}
              />
              <Bar
                dataKey={dataKeyBarThird}
                fill={VARIABLES.COLORS.GREEN_1}
                label={BAR_LABEL_STYLE}
                shape={<BarThird />}
              />
            </BarChart>
          </ResponsiveContainer>
        }

        {stackBar &&
          <ResponsiveContainer>
            <BarChart
              layout='vertical'
              margin={{ top: 15 }}
              barSize={16}
              barGap={5}
              data={data}
            >
              <CartesianGrid
                stroke={VARIABLES.COLORS.BLUE_2}
              />
              <XAxis
                type='number'
                stroke={VARIABLES.COLORS.BLUE_2}
                strokeWidth={1}
                tickSize={0}
                tick={XAXIS_LABEL_STYLE}
              />
              <YAxis
                type='category'
                dataKey={dataKeyXAxis}
                stroke={VARIABLES.COLORS.BLUE_2}
                strokeWidth={1}
                tickSize={0}
                tick={YAXIS_LABEL_STYLE}
              />
              <Tooltip
                isAnimationActive={false}
                labelStyle={{
                  fontSize: VARIABLES.FONT.FAMILIES.FIRST.SIZES.FS_16,
                  fontFamily: VARIABLES.FONT.FAMILIES.FIRST.WEIGHTS.REGULAR,
                  marginBottom: 5
                }}
                itemStyle={{
                  fontSize: VARIABLES.FONT.FAMILIES.FIRST.SIZES.FS_14,
                  fontFamily: VARIABLES.FONT.FAMILIES.FIRST.WEIGHTS.REGULAR,
                }}
                contentStyle={{
                  borderColor: VARIABLES.COLORS.GRAY_8,
                  borderRadius: 3
                }}
                cursor={{fill: 'transparent'}}
              />
              <Bar
                stackId='barStack'
                dataKey={dataKeyBarFirst}
                fill={VARIABLES.COLORS.PURPLE_1}
                shape={<BarFirstHorizontal />}
              />
              <Bar
                stackId='barStack'
                dataKey={dataKeyBarSecond}
                fill={VARIABLES.COLORS.BLUE_18}
                shape={<BarSecondHorizontal />}
              />
            </BarChart>
          </ResponsiveContainer>
        }

        {pieMultipleColor &&
          <PieChart
            width={label ? width : ((outerRadius * 2) + 12)}
            height={label ? height : ((outerRadius * 2) + 12)}
          >
            <Pie
              label={PIE_LABEL_STYLE}
              labelLine={labelLine}
              dataKey='value'
              data={data}
              cx={label ? cx : outerRadius}
              cy={label ? cy : outerRadius}
              innerRadius={innerRadius}
              outerRadius={outerRadius}
              fill={fill}
              paddingAngle={1}
            >
              {
                data.map((entry, index) =>
                  <Cell
                    key={index}
                    fill={pieMultipleCellColors[index % pieMultipleCellColors.length]}
                  />
                )
              }
            </Pie>
          </PieChart>
        }
      </ChartBaseWrapper>
    )
  }

  static defaultProps = {
    chartWidth: '100',
    chartWidthUnit: '%'
  }

  static propTypes = { // TYPE > node, string, number, bool, array, object, symbol, func
    /**
    * Additional classes
    */
    className: PropTypes.string,

    /**
    * Additional Text, HTML, Component
    */
    children: PropTypes.oneOfType([
      PropTypes.node,
      PropTypes.string
    ])
  }
}
