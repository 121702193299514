/* eslint-disable default-case */
import React from 'react'
import {
  ShapeContainer,
  Section,
  Breadcrumb,
  TitlePage,
  Grid,
  Text,
  Button,
  SelectControl,
  Field,
  DatePickerControl,
} from './../../components'
import {
  VARIABLES,
  ICONS
} from './../../themes'
import { setSideMenuActive, getRouteParams } from '../../helpers/functions/route';
import { ROUTE_PATH } from '../../helpers/constants/route';
import { redirect } from '../../helpers/functions/route';
import { CoilTransferHistoryTableContainerConnected } from '../../containers/CoilTransferContainer';
import { getTransferSearchHistoryList, getClearTransferHistorySearchParams } from '../../helpers/services/transfer';
import { getMomentStartOf, getMomentEndOf } from '../../helpers/functions/data';
import { ENUM } from '../../helpers/constants/enum';
import _ from 'lodash';
import { getLanguage } from '../../helpers/functions/language';
import { DATE_MOMENT_FORMAT, DATE_PICKER_FORMAT } from '../../helpers/constants/data';
import { getUserAuthRedux } from '../../helpers/functions/auth';
import { displayDate } from '../../helpers/functions/display';

export class CoilTransferHistoryScreen extends React.Component {

  constructor(props) {
    super(props);
    setSideMenuActive(props);
    // const customerName = getRouteParams(props, 'name') || '';
    const categorySelectOptionList = [{
      value: '',
      label: getLanguage('coilTransferHistory.selectOption.all', '')
    }, {
      value: 'orderNo',
      label: getLanguage('coilTransferHistory.selectOption.orderNo', '')
    }, {
      value: 'transferFrom',
      label: getLanguage('coilTransferHistory.selectOption.transferFrom', '')
    }, {
      value: 'transferTo',
      label: getLanguage('coilTransferHistory.selectOption.transferTo', '')
    }, {
      value: 'coilID',
      label: getLanguage('coilTransferHistory.selectOption.coilID', '')
    }];
    const statusSelectOptionList = [{
      value: '',
      label: getLanguage('coilTransferHistory.selectOption.all', '')
    }, {
      value: 'pending',
      label: getLanguage('coilTransferHistory.selectOption.pending', '')
    }, {
      value: 'transferred',
      label: getLanguage('coilTransferHistory.selectOption.transferred', '')
    }, {
      value: 'received',
      label: getLanguage('coilTransferHistory.selectOption.received', '')
    }];
    this.state = {
      Ad: false,
      Toko: false,
      searchValue: '',
      // customerName,
      categorySelectOptionList,
      categorySelected: categorySelectOptionList[0],
      statusSelectOptionList,
      statusSelected: statusSelectOptionList[0],
      startDate: new Date(),
      endDate: new Date(),
      customerRoleType: undefined, // ENUM.CUSTOMER_ROLE_TYPE.INDONESIA.AD,
      userAuth: getUserAuthRedux(),
    }
  }

  input = {
    onChangeInput: (ev) => {
      const name = ev.target.name;
      const value = ev.target.value;
      this.setState({
        [name]: value,
      })
    },
    onClickSearchText: () => {
      const {
        searchValue,
        categorySelected,
        categorySelectOptionList,
        statusSelected,
        statusSelectOptionList,
        // customerName,
      } = this.state;
      let params = {
        ...getClearTransferHistorySearchParams(),
        page: 1,
        // customer_name: customerName,
      };
      params.text_search = searchValue;

      const categoryType = categorySelected.value;
      switch (categoryType) {
        case categorySelectOptionList[0].value:
          params.category = 'all';
          break;
        case categorySelectOptionList[1].value:
          params.category = 'order_no';
          break;
        case categorySelectOptionList[2].value:
          params.category = 'transfer_from';
          break;
        case categorySelectOptionList[3].value:
          params.category = 'transfer_to';
          break;
        case categorySelectOptionList[4].value:
          params.category = 'coil_id';
          break;
      }

      const statusType = statusSelected.value;
      switch (statusType) {
        case statusSelectOptionList[0].value:
          params.status = 'all';
          break;
        case statusSelectOptionList[1].value:
          params.status = 'Pending';
          break;
        case statusSelectOptionList[2].value:
          params.status = 'Transferred';
          break;
        case statusSelectOptionList[3].value:
          params.status = 'Received';
          break;
      }
      getTransferSearchHistoryList(params);
    },
    onClickSearchDate: () => {
      const {
        startDate,
        endDate,
        // customerName,
      } = this.state;
      let params = {
        ...getClearTransferHistorySearchParams(),
        page: 1,
        // customer_name: customerName,
        start_date: displayDate(startDate, '-', DATE_MOMENT_FORMAT),
        end_date: displayDate(endDate, '-', DATE_MOMENT_FORMAT),
      };
      getTransferSearchHistoryList(params);
    },
    onChangeSearchOption: (target, selected) => {
      this.setState({
        [target]: selected
      })
    },
    onChangeDate: (date, name) => {
      this.setState({
        [name]: date
      })
    }
  }

  render() {
    const {
      searchValue,
      categorySelected,
      categorySelectOptionList,
      statusSelected,
      statusSelectOptionList,
      startDate,
      endDate,
      // customerName,
      customerRoleType,
      userAuth,
    } = this.state
    return (
      <React.Fragment>
        <Breadcrumb>
          <Breadcrumb.List
            link
            name={getLanguage('coilTransferHistory.breadcrumb1', '')}
            onClick={() => {
              redirect(ROUTE_PATH.HOME.LINK)
            }}
          />
          <Breadcrumb.List name='/' />
          <Breadcrumb.List
            link
            name={getLanguage('coilTransferHistory.breadcrumb2', '')}
          // onClick={() => {
          //   redirect(ROUTE_PATH.INVENTORY_INDEXES.LINK)
          // }}
          />
          <Breadcrumb.List name='/' />
          {
            (customerRoleType === ENUM.CUSTOMER_ROLE_TYPE.INDONESIA.DISTRIBUTOR) ?
              <Breadcrumb.List name={getLanguage('coilTransferHistory.breadcrumb3_1', '')} />
              :
              (customerRoleType === ENUM.CUSTOMER_ROLE_TYPE.INDONESIA.AD) ?
                <Breadcrumb.List name={getLanguage('coilTransferHistory.breadcrumb3_2', '')} />
                :
                (customerRoleType === ENUM.CUSTOMER_ROLE_TYPE.INDONESIA.TOKO) ?
                  <Breadcrumb.List name={getLanguage('coilTransferHistory.breadcrumb3_3', '')} />
                  :
                  <Breadcrumb.List name={getLanguage('coilTransferHistory.breadcrumb3_4', '')} />
          }
        </Breadcrumb>
        <Section ui='content'>
          <Section spacingBottom='35'>
            <TitlePage
              src={ICONS['ic-page-inventory.svg']}
              name={
                (customerRoleType === ENUM.CUSTOMER_ROLE_TYPE.INDONESIA.DISTRIBUTOR) ?
                  getLanguage('coilTransferHistory.title_1', '')
                  :
                  (customerRoleType === ENUM.CUSTOMER_ROLE_TYPE.INDONESIA.AD) ?
                    getLanguage('coilTransferHistory.title_2', '')
                    :
                    (customerRoleType === ENUM.CUSTOMER_ROLE_TYPE.INDONESIA.TOKO) ?
                      getLanguage('coilTransferHistory.title_3', '')
                      :
                      getLanguage('coilTransferHistory.title_4', '')
              }
            />
          </Section>
          <Section spacingBottom='20'>
            <ShapeContainer
              fluid
              className='search-control'
              paddingTop='20'
              paddingBottom='24'
              paddingHorizontal='20'
              borderWidth='1'
              borderStyle='solid'
              borderColor={VARIABLES.COLORS.PRIMARY_1}
              borderRadius='10'
            >
              <Grid
                className='search-control-container'
                gutter='30'
                gutterVertical='20'
              >
                <Grid.Column
                  className='search-control-group-left'
                  flex='1'
                >
                  <Grid gutter='10'>
                    <Grid.Column flex='2'>
                      <Section paddingTop='24'>
                        <Field name='searchValue'
                          fluid
                          ui='blockLabelVerticalSearch'
                          placeholder={getLanguage('coilTransferHistory.placeholder', '')}
                          value={searchValue}
                          onChange={this.input.onChangeInput}
                        />
                      </Section>
                    </Grid.Column>
                    <Grid.Column flex='none'>
                      <Section zIndexLv3>
                        <SelectControl
                          verticalLabel
                          filterFluid
                          width='110'
                          label={getLanguage('coilTransferHistory.label.category', '')}
                          isSearchable={false}
                          value={categorySelected}
                          onChange={(selected) => this.input.onChangeSearchOption('categorySelected', selected)}
                          options={categorySelectOptionList}
                        />
                      </Section>
                    </Grid.Column>
                    <Grid.Column flex='none'>
                      <Section zIndexLv3>
                        <SelectControl
                          verticalLabel
                          filterFluid
                          width='110'
                          label={getLanguage('coilTransferHistory.label.status', '')}
                          isSearchable={false}
                          value={statusSelected}
                          onChange={(selected) => this.input.onChangeSearchOption('statusSelected', selected)}
                          options={statusSelectOptionList}
                        />
                      </Section>
                    </Grid.Column>
                    <Grid.Column flex='none'>
                      <Section paddingTop='24'>
                        <Button
                          lightBlue
                          name={getLanguage('coilTransferHistory.buttonSearch', '')}
                          width={VARIABLES.BUTTON.WIDTHS.W_105}
                          onClick={this.input.onClickSearchText}
                        />
                      </Section>
                    </Grid.Column>
                  </Grid>
                </Grid.Column>
                <Grid.Column
                  className='search-control-partition'
                  flex='none'
                >
                  <Section paddingTop='24'>
                    <ShapeContainer
                      width='1'
                      height='32'
                      bgColor={VARIABLES.COLORS.PRIMARY_1}
                    />
                  </Section>
                </Grid.Column>
                <Grid.Column
                  className='search-control-group-right'
                  flex='1'
                >
                  <Section paddingTop='24'>
                    <Grid gutter='20'>
                      <Grid.Column flex='none'>
                        <Section paddingTop='6'>
                          <Text
                            regular12
                            color={VARIABLES.COLORS.BLACK}
                          >
                            {getLanguage('coilTransferHistory.date', '')}
                          </Text>
                        </Section>
                      </Grid.Column>
                      <Grid.Column flex='1'>
                        <Grid gutter='10'>
                          <Grid.Column flex='1'>
                            <Grid gutter='15'>
                              <Grid.Column flex='1'>
                                <DatePickerControl
                                  filter
                                  bottom
                                  dateFormat={DATE_PICKER_FORMAT}
                                  placeholderText={getLanguage('coilTransferHistory.selectStartDate', '')}
                                  selected={startDate}
                                  onChange={(date) => {
                                    this.input.onChangeDate(date, 'startDate');
                                  }}
                                />
                              </Grid.Column>
                              <Grid.Column flex='none'>
                                <Section paddingTop='6'>
                                  <Text
                                    regular12
                                    color={VARIABLES.COLORS.GRAY_19}
                                  >
                                    {getLanguage('coilTransferHistory.dateTo', '')}
                                  </Text>
                                </Section>
                              </Grid.Column>
                              <Grid.Column flex='1'>
                                <DatePickerControl
                                  filter
                                  bottom
                                  dateFormat={DATE_PICKER_FORMAT}
                                  placeholderText={getLanguage('coilTransferHistory.selectEndDate', '')}
                                  selected={endDate}
                                  onChange={(date) => {
                                    this.input.onChangeDate(date, 'endDate');
                                  }}
                                />
                              </Grid.Column>
                            </Grid>
                          </Grid.Column>
                          <Grid.Column flex='none'>
                            <Button
                              lightBlue
                              name={getLanguage('coilTransferHistory.buttonSearch', '')}
                              width={VARIABLES.BUTTON.WIDTHS.W_105}
                              onClick={this.input.onClickSearchDate}
                            />
                          </Grid.Column>
                        </Grid>
                      </Grid.Column>
                    </Grid>
                  </Section>
                </Grid.Column>
              </Grid>
            </ShapeContainer>
          </Section>
          <ShapeContainer
            ui='contentFilter'
            justify='space-between'
          >
            <CoilTransferHistoryTableContainerConnected
            // customerName={customerName}
            />
          </ShapeContainer>
        </Section>
      </React.Fragment>
    )
  }
}
