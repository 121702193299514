import React from 'react'
import {
  ShapeContainer,
  Section,
  Breadcrumb,
  TitlePage,
  Text,
  Grid,
  Table,
  PaginationControl,
  TableAction,
  Button,
  Image,
  TitleSection,
} from './../../components'
import {
  VARIABLES,
  ICONS,
} from './../../themes'
import { setSideMenuActive } from '../../helpers/functions/route';
import { ROUTE_PATH } from '../../helpers/constants/route';
import { redirect } from '../../helpers/functions/route';
import _ from 'lodash';
import { getLanguage } from '../../helpers/functions/language';
import { SettingSalePersonListContainerConnected } from '../../containers/SettingSalePersonListContainer';
import { getSalePersonExportList } from '../../helpers/services/salePerson';
import { downloadExcel } from '../../helpers/functions/addon';
import { ENUM } from '../../helpers/constants/enum';
import { getUserAuthRedux } from '../../helpers/functions/auth';
import { displayDate } from '../../helpers/functions/display';
import { DATA, DATE_MMDDYYYY_FORMAT } from '../../helpers/constants/data';
import moment from 'moment';

export class SettingSalesPersonScreen extends React.Component {

  constructor(props) {
    super(props);
    setSideMenuActive(props);
    const userAuth = getUserAuthRedux();
    this.state = {
      userAuth,
    }
  }

  settingSalesPerson = {
    onClickExport: async () => {
      let dataList = await getSalePersonExportList();
      dataList = dataList.map(e => {
        e.salesSkillSpecificCourseList = e.salesSkillSpecificCourseList.filter(e => !!e.courseName__c || !!e.trainingDate__c);
        e.salesSkillIndependentCourseList = e.salesSkillIndependentCourseList.filter(e => !!e.courseName__c || !!e.trainingDate__c);
        e.productSkillSpecificCourseList = e.productSkillSpecificCourseList.filter(e => !!e.courseName__c || !!e.trainingDate__c);
        e.productSkillIndependentCourseList = e.productSkillIndependentCourseList.filter(e => !!e.courseName__c || !!e.trainingDate__c);
        return e;
      })
      const dataExportList = dataList.map((e, i) => {
        const data = {
          No: i + 1,
          Name: e.fullname__c,
          'Nick name': e.nickname__c,
          'Mobile': e.phone__c,
          'Points': e.points,
          'เลขบัตรประชาชน': e.idCardNo,
          'วันที่เริ่มงาน': displayDate(e.startDateStartWorkingDay, '-', DATE_MMDDYYYY_FORMAT),
          'ตำแหน่ง': e.position__c,
          'อีเมลล์': e.email__c,
        };

        [...e.salesSkillSpecificCourseList,
        ...e.salesSkillIndependentCourseList,
        ...e.productSkillSpecificCourseList,
        ...e.productSkillIndependentCourseList].forEach((course, i) => {
          data[`หลักสูตร ${i + 1}`] = course.courseName__c;
          data[`วันที่อบรม ${i + 1}`] = course.trainingDate__c ? moment(course.trainingDate__c).format('YYYY') : '';
        })

        return data;
      });

      downloadExcel([
        {
          name: 'sales person',
          data: dataExportList
        },
      ], 'sales person');
    },
  }

  render() {
    const {
      userAuth,
    } = this.state;
    return (
      <React.Fragment>
        <Breadcrumb>
          <Breadcrumb.List
            link
            name={getLanguage('salesPerson.breadcrumb1', '')}
            onClick={() => {
              redirect(ROUTE_PATH.HOME.LINK)
            }}
          />
          <Breadcrumb.List name='/' />
          <Breadcrumb.List
            link
            name={getLanguage('salesPerson.breadcrumb2', '')}
          // onClick={() => {
          //   redirect(ROUTE_PATH.SETTINGS.LINK)
          // }}
          />
          <Breadcrumb.List name='/' />
          <Breadcrumb.List name={getLanguage('salesPerson.breadcrumb3', '')} />
        </Breadcrumb>
        <Section ui='content'>
          <Section spacingBottom='35'>
            <Grid
              gutterVertical='15'
              justify='space-between'
            >
              <Grid.Column>
                <TitlePage
                  src={ICONS['ic-page-settings.svg']}
                  name={getLanguage('salesPerson.titles.salesPerson', '')}
                />
              </Grid.Column>
              <Grid.Column>
                <Grid gutter='15'>
                  <Grid.Column>
                    <Button
                      lightBlue
                      ui='frontIcon'
                      name={getLanguage('salesPerson.buttons.export', '')}
                      width={VARIABLES.BUTTON.WIDTHS.W_125}
                      iconWidth='16'
                      iconHeight='16'
                      iconSrc={ICONS['ic-export.svg']}
                      onClick={this.settingSalesPerson.onClickExport}
                    />
                  </Grid.Column>
                  {userAuth.role === ENUM.USER_ROLE.OWNER &&
                    <Grid.Column>
                      <Button
                        blue
                        ui='frontIcon'
                        name={getLanguage('salesPerson.buttons.addNewSales', '')}
                        iconWidth='14'
                        iconHeight='14'
                        iconSrc={ICONS['ic-create.svg']}
                        onClick={() => {
                          redirect(`${ROUTE_PATH.SETTING_SALES_PERSON_MANAGE.LINK}/0`);
                        }}
                      />
                    </Grid.Column>
                  }
                </Grid>
              </Grid.Column>
            </Grid>
          </Section>
          <SettingSalePersonListContainerConnected />
        </Section>
      </React.Fragment>
    )
  }
}
