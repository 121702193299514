import React from 'react';
import { RouteContainers } from '../../containers/RouteContainers/RouteContainers';
import { getLocalStorage } from '../../helpers/functions/localStorage';
import { LOCAL_STORAGE } from '../../helpers/constants/localStorage';
import { changeLanguage } from '../../helpers/functions/language';
import { handleWatchAllNoti } from '../../helpers/firebases/firebaseNotification';
import { handleWatchVersion } from '../../helpers/firebases/firebaseVersoin';
import { getCurrentCountryLanguage } from '../../helpers/functions/addon';
import { isShowLoading } from '../../helpers/functions/main';
import { DATA } from '../../helpers/constants/data';

export class RouteLayout extends React.Component {

  constructor(props) {
    super(props);
    const language = getLocalStorage(LOCAL_STORAGE.LANGUAGE_RBPTRUEBLUE);
    if (language) {
      changeLanguage(language);
    }
    handleWatchAllNoti();
    handleWatchVersion();
    this.state = {
      isRefresh: false
    };
  }

  componentDidMount() {
    const language = getLocalStorage(LOCAL_STORAGE.LANGUAGE_RBPTRUEBLUE);
    if (!language) {
      isShowLoading(true)
      let isGetCurrentLanguage = true;

      const handleLanguage = (lang) => {
        if (isGetCurrentLanguage) {
          isGetCurrentLanguage = false;
          changeLanguage(lang);
          isShowLoading(false)
          this.routeLayout.handleRefresh();
        }
      }

      const timeoutIndex = setTimeout(() => {
        console.log('get current lang too long time')
        handleLanguage(DATA.LANGUAGE.ENGLISH)
      }, 5000);

      getCurrentCountryLanguage().then(country => {
        clearTimeout(timeoutIndex)
        handleLanguage(country)
      });

    }
  }

  routeLayout = {
    handleRefresh: () => {
      this.setState({
        isRefresh: true,
      }, () => {
        this.setState({
          isRefresh: false
        })
      })
    }
  }

  render() {
    const {
      isRefresh,
    } = this.state;
    return isRefresh ?
      <div /> :
      <RouteContainers {...this.props} />
  }
}