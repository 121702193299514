import apiService from '../apiService'

export const pointController = (configService = {}) => {
  const service = apiService(configService)
  return {
    // getPoint: (params) => {
    //   return service.get(`${apiPath}/${params.id}`, params)
    // },
    getPoint: (params) => {
      return service.post(`/point/search`, params)
    },
  }
}
