import React from 'react'
import ClassNames from 'classnames'
import PropTypes from 'prop-types'
import {
  ChooseLanguageWrapper
} from './styled'
// import {
// } from './../../../components'
// import {
//   default as VARIABLES
// } from './../../../themes/styles/bases/variables'
// import {
//   default as TYPOGRAPHYS
// } from './../../../themes/styles/bases/typographys'
// import {
//   default as UTILITIES
// } from './../../../themes/styles/helpers/utilities'

/**
 * ChooseLanguage description:
 * - ChooseLanguage
 */

export class ChooseLanguage extends React.PureComponent {
  render () {
    const {
      className,
      type,
      id,
      name,
      value,
      checked,
      onChange,
      srcFlag,
      label
    } = this.props

    // props for css classes
    const classes = ClassNames(
      'choose-language',
      // {'is-modifier-name': modifierName},
      className
    )

    return (
      <ChooseLanguageWrapper
        className={classes}
      >
        <div className='choose-language-input-container'>
          <div className='choose-language-flag'>
            <img className='choose-language-flag-image'
              src={srcFlag}
              alt='Flag'
            />
          </div>
          <input
            className='choose-language-input'
            type={type}
            id={id}
            name={name}
            value={value}
            checked={checked}
            onChange={onChange}
          />
          <div className='choose-language-label'>
            {label}
          </div>
        </div>
      </ChooseLanguageWrapper>
    )
  }

  static defaultProps = {
    // * If you set new default props, you should create propsType for correct styleguide document.
    type: 'radio'
  }

  static propTypes = { // TYPE > node, string, number, bool, array, object, symbol, func
    /**
    * Additional classes
    */
    className: PropTypes.string
  }
}
