import apiService from '../apiService'

const apiPath = '/profile'

export const profileController = (configService = {}) => {
  const service = apiService(configService)
  return {
    search: (params) => {
      return service.post(`${apiPath}/search`, params)
    },
    create: (params) => {
      return service.post(`${apiPath}/`, params)
    },
    calculate: (params) => {
      return service.post(`${apiPath}/calculate`, params)
    },
    adjust: (params) => {
      return service.post(`${apiPath}/adjust`, params)
    },
    // detailAdRf: (params) => {
    //   return service.get(`${apiPath}/ad_rf/${params.dealerCode}/${params.id}`, params)
    // },    
    // createAD: (params) => {
    //   return service.post(`${apiPath}/ad_rf`, params)
    // },
    // searchTransferHis: (params) => {
    //   return service.post(`${apiPath}/history`, params)
    // },
    // searchTransferInv: (params) => {
    //   return service.post(`${apiPath}/inventory`, params)
    // },    
  }
}