import apiService from '../apiService'

export const userController = (configService = {}) => {
  const service = apiService(configService)
  return {
    searchUsers: (params) => {
      return service.post(`/users/search`, params)
    },
    verifyUser: (params) => {
      return service.post(`/users/verify`, params)
    },
    resetPassword: (params) => {
      return service.put(`/users/reset-password`, params)
    },
    securtyAnswer: (params) => {
      return service.post(`/users/securty-answer`, params)
    },
  }
}
