import React from 'react'
import ClassNames from 'classnames'
import PropTypes from 'prop-types'
import {
  TabWrapper
} from './styled'
import {
  Grid,
  Image,
  Section,
  Text,
} from './../../../components'
import {
  default as VARIABLES
} from './../../../themes/styles/bases/variables'
// import {
//   default as TYPOGRAPHYS
// } from './../../../themes/styles/bases/typographys'
// import {
//   default as UTILITIES
// } from './../../../themes/styles/helpers/utilities'

/**
 * TemplateBase description:
 * - Tab
 */

class TabList extends React.PureComponent {
  render() {
    const {
      className,
      children,
      isActive,
      isDisabledClick,
      onClick,
      isHelpActive,
    } = this.props

    // props for css classes
    const classes = ClassNames(
      'tab-custom-list',
      { 'is-active': isActive },
      { 'is-disabled-click': isDisabledClick },
      { 'is-help-active': isHelpActive },

      className
    )

    return (
      <div
        className={classes}
        onClick={onClick}
      >
        {children}
      </div>
    )
  }

  static defaultProps = {
    // * If you set new default props, you should create propsType for correct styleguide document.
  }

  static propTypes = { // TYPE > node, string, number, bool, array, object, symbol, func
    /**
    * Additional classes
    */
    className: PropTypes.string
  }
}

export class Tab extends React.PureComponent {
  render() {
    const {
      className,
      children,
      isThemeBlue,
      isHelpTab,
    } = this.props

    // props for css classes
    const classes = ClassNames(
      'tab-custom',
      { 'is-theme-blue': isThemeBlue },
      { 'is-help-tab': isHelpTab },
      className
    )

    return (
      <TabWrapper
        className={classes}
      >
        {children}
      </TabWrapper>
    )
  }

  static List = TabList

  static defaultProps = {
    // * If you set new default props, you should create propsType for correct styleguide document.
  }

  static propTypes = { // TYPE > node, string, number, bool, array, object, symbol, func
    /**
    * Additional classes
    */
    className: PropTypes.string
  }
}
