import ClassNames from "classnames";
import html2pdf from 'html2pdf.js';
import _ from "lodash";
import React from "react";
import {
  Accordion,
  AccordionItem, AccordionItemBody, AccordionItemTitle
} from "react-accessible-accordion";
import { projectDocumentController } from "../../apiService/apiController/projectDocumentService";
import {
  AccordionControl, Breadcrumb, Button,
  Field, Grid, Image, Section, ShapeContainer, Step, Table,
  Text, TitlePage, TitleSection, WarrantyGeneratePdfPage
} from "../../components";
import { FIREBASE_STORAGE } from "../../config/config";
import { DocumentaryTemplate } from "../../containers/GenerateDocumentaryContainer/DocumentaryTemplate";
import {
  ModalPreviewDocumentTemplateContainer
} from "../../containers/ModalContainer";
import { DATA } from "../../helpers/constants/data";
import { ENUM } from "../../helpers/constants/enum";
import { ROUTE_PATH } from "../../helpers/constants/route";
import { getBlobtoPath } from "../../helpers/functions/addon";
import { getUserAuthRedux } from "../../helpers/functions/auth";
import { displayUrlPath } from "../../helpers/functions/display";
import { getLanguage } from "../../helpers/functions/language";
import { isShowLoading, toast } from "../../helpers/functions/main";
import { getRouteData, redirect, redirectData, setSideMenuActive } from "../../helpers/functions/route";
import { isValidResponse } from "../../helpers/functions/validation";
import { handleDocumentaryData } from "../../helpers/functions/warranty";
import { getProjectDocumentCertificate, getProjectDocumentDocumentaryList, getProjectDocumentM3ProductList } from "../../helpers/services/projectDocument";
import { ICONS, VARIABLES } from "../../themes";

export class ProjectDocumentCertificateManageScreen extends React.Component {
  constructor(props) {
    super(props);
    setSideMenuActive(props);
    let routeData = getRouteData(props, 'data');
    console.log('routeData', routeData);
    if (!routeData) {
      redirect(ROUTE_PATH.PRE_TRANSACTION.LINK);
    }
    routeData = routeData || {};


    const stepContentList = [
      {
        stepPercent: "0",
        contentList: [
          {
            isActive: true,
            isSuccess: false,
            stepName: getLanguage("certificate.label.selectProduct", ""),
          },
          {
            isActive: false,
            isSuccess: false,
            stepName: getLanguage("certificate.label.information", ""),
          },
          {
            isActive: false,
            isSuccess: false,
            stepName: getLanguage("certificate.label.certificate", ""),
          },
        ],
      },
      {
        stepPercent: "50",
        contentList: [
          {
            isActive: false,
            isSuccess: true,
            stepName: getLanguage("certificate.label.selectProduct", ""),
          },
          {
            isActive: true,
            isSuccess: false,
            stepName: getLanguage("certificate.label.information", ""),
          },
          {
            isActive: false,
            isSuccess: false,
            stepName: getLanguage("certificate.label.certificate", ""),
          },
        ],
      },
      {
        stepPercent: "100",
        contentList: [
          {
            isActive: false,
            isSuccess: true,
            stepName: getLanguage("certificate.label.selectProduct", ""),
          },
          {
            isActive: false,
            isSuccess: true,
            stepName: getLanguage("certificate.label.information", ""),
          },
          {
            isActive: true,
            isSuccess: false,
            stepName: getLanguage("certificate.label.certificate", ""),
          },
        ],
      },
    ];

    const data = {
      ...routeData.data,
      customerData: routeData.customerData,
      segmentSelected: routeData.segmentSelected,
      selectSalePerson: routeData.selectSalePerson,
      certificateData: routeData.certificateData,
      isRegenerate: routeData.isRegenerate,
    }
    console.log('data', data);

    const userAuth = getUserAuthRedux();

    this.state = {
      userAuth,
      data,
      routeData,

      currentStepIndex: 0,
      currentStepContent: stepContentList[0],
      stepContentList,

      sortIcon: {
        m3ProductGroupName: true,
      },
      m3ProductList: [],
      searchM3ProductValue: '',
      searchM3ProductFilter: '',

      m3ProductSelectedList: [],
      defaultSortIcon: {
        docMsCate: true,
        docMsCode: true,
        docMsName: true,
      },
      certificateTypeList: ['pre', 'post'],

      isShowPreviewDocumentaryModal: false,
      previewDocumentaryDataModal: undefined,

      isShowCertificateTemplateModal: false,
      previewCertificateTemplateData: undefined,

      isCanRegenerate: false,
    };
  }

  componentDidMount() {
    this.projectDocumentCertificateManage.getProjectDocumentM3ProductList();
  }

  projectDocumentCertificateManage = {
    onClickBackProjectDocumentManage: () => {
      const {
        data,
      } = this.state;
      redirectData(`${ROUTE_PATH.PROJECT_DOCUMENT_MANAGE.LINK}/${data.preSalesTransId}`, {
        data: {
          tabType: DATA.PROJECT_DOCUMENT_TAB_TYPE_LIST[4]
        }
      });
    },
    onClickBack: () => {
      const {
        currentStepIndex,
        stepContentList,
        isCanRegenerate,
      } = this.state;
      switch (currentStepIndex) {
        case 0:
          this.projectDocumentCertificateManage.onClickBackProjectDocumentManage();
          break;
        case 1:
        case 2:
          if (isCanRegenerate) {
            this.projectDocumentCertificateManage.onClickBackProjectDocumentManage();
          }
          else {
            const prevStepIndex = currentStepIndex - 1;
            this.setState({
              currentStepIndex: prevStepIndex,
              currentStepContent: stepContentList[prevStepIndex],
            });
          }
          break;
        default: break;
      }
    },
    onClickEdit: () => {
      const {
        stepContentList,
      } = this.state;
      this.setState({
        currentStepIndex: 1,
        currentStepContent: stepContentList[1],
        isShowCertificateTemplateModal: false,
      });
    },
    getProjectDocumentM3ProductList: async () => {
      const {
        data,
      } = this.state;
      isShowLoading(true);
      const configService = {
        isShowLoading: false,
      }
      const dataList = await getProjectDocumentM3ProductList(configService);
      this.setState({
        m3ProductList: _.orderBy(dataList.filter(e => {
          if (data.isRegenerate) {
            return data.certificateData.preSalesTransM3ProdGrpName === e.m3ProductGroupName;
          }
          return true;
        }).map((e, i) => {
          e.id = i;
          if (data.isRegenerate) {
            e.isSelected = true;
            e.preSalesTransCertId = data.certificateData.preSalesTransCertId;
          }
          return e;
        }), ['m3ProductGroupName'], ['asc'])
      }, () => {
        if (data.isRegenerate) {
          this.projectDocumentCertificateManage.getM3DocumentaryList(async () => {
            await this.projectDocumentCertificateManage.handleRegenerateStep();
          });
        } else {
          isShowLoading(false);
        }
      })
    },
    handleRegenerateStep: () => {
      const {
        m3ProductSelectedList,
        stepContentList,
      } = this.state;
      return new Promise(resolve => {
        if (m3ProductSelectedList.filter(e => e.documentaryList.filter(ed => ed.isSelected).length).length > 0) {
          this.setState({
            isCanRegenerate: true,
            currentStepIndex: 2,
            currentStepContent: stepContentList[2],
          }, () => {
            resolve();
          })
        }
        else {
          toast.error(getLanguage('message.cantFoundDocumentary'));
          resolve();
        }
      })
    },
    onClickSearchM3Product: () => {
      const {
        searchM3ProductValue,
      } = this.state;
      this.setState({
        searchM3ProductFilter: searchM3ProductValue
      })
    },
    getM3ProductFilterDataList: () => {
      const {
        m3ProductList,
        searchM3ProductFilter
      } = this.state;
      const resultList = m3ProductList.filter(e => {
        return (e.m3ProductGroupName || '').toLowerCase().indexOf(searchM3ProductFilter.toLowerCase()) >= 0
      });
      return resultList;
    },
    getDocumentarySelectedList: async () => {
      const {
        data,
      } = this.state;
      const configService = {
        urlParams: {
          pre_sales_trans_id: data.preSalesTransId
        }
      }
      let dataSelectedList = await getProjectDocumentCertificate(configService);
      if (data.isRegenerate) {
        dataSelectedList = dataSelectedList.filter(e => e.preSalesTransM3ProdGrpName === data.certificateData.preSalesTransM3ProdGrpName);
      }
      console.log('dataSelectedList', dataSelectedList)
      return dataSelectedList;
    },
    getM3DocumentaryList: async (callback = () => { }) => {
      const {
        m3ProductList,
        defaultSortIcon,
        data,
      } = this.state;
      let m3ProductSelectedList = m3ProductList.filter(e => e.isSelected);
      let cnt = 0;
      let resultList = [];
      isShowLoading(true);
      const documentarySelectedList = await this.projectDocumentCertificateManage.getDocumentarySelectedList();
      m3ProductSelectedList.forEach(async (e) => {
        const configService = {
          isShowLoading: false,
          urlParams: {
            m3_prod_grp_name: e.m3ProductGroupName,
            folder_type: ENUM.DOCUMENTARY.FOLDER_TYPE.CERTIFICATE,
          }
        }
        let dataList = await getProjectDocumentDocumentaryList(configService);
        // dataList = dataList.filter(e => e.docMsCate.toLowerCase() !== DATA.PRE_SALES_TRANSACTION.DOCUMENTARY_TEMPLATE_CATEGORY.toLowerCase());

        let documentaryRegenSelected;
        if (data.isRegenerate) {
          documentaryRegenSelected = documentarySelectedList.filter(itemSelected => itemSelected.preSalesTransCertId === data.certificateData.preSalesTransCertId)[0];
        }

        dataList = dataList.filter(item => {
          let isPass = true;
          documentarySelectedList.forEach(itemSelected => {
            if (isPass) {
              if (e.m3ProductGroupName === itemSelected.preSalesTransM3ProdGrpName) {
                isPass = itemSelected.docMsSelectedIdList.indexOf(item.id) === -1;
              }
            }
          });
          if (
            documentaryRegenSelected &&
            documentaryRegenSelected.docMsSelectedIdList.indexOf(item.id) >= 0
          ) {
            isPass = true;
            documentaryRegenSelected.docMsCateList.forEach(erd => {
              if (erd.docMsId === item.id) {
                item.preSalesTransCertDTId = erd.preSalesTransCertDTId;
                item.preSalesTransCertDtCode = erd.preSalesTransCertDtCode;
              }
            })
          }
          return isPass;
        });

        e.documentaryList = dataList.map(ed => {
          (e.documentaryList || []).forEach(edc => {
            if (ed.id === edc.id) {
              ed.isSelected = edc.isSelected;
            }
          });
          if (documentaryRegenSelected) {
            ed.isSelected = documentaryRegenSelected.docMsSelectedIdList.indexOf(ed.id) >= 0;
          }
          return ed;
        });

        e.currentSort = 'docMsCate';
        e.sortIcon = _.cloneDeep(defaultSortIcon);

        resultList.push(e);
        cnt++;
        if (cnt === m3ProductSelectedList.length) {
          this.setState({
            m3ProductSelectedList: _.orderBy(resultList, ['m3ProductGroupName'], ['asc']),
          }, async () => {
            await callback();
            isShowLoading(false);
          })
        }
      })
    },
    handleManageCertificate: async () => {
      const {
        data,
        m3ProductSelectedList,
        isCanRegenerate,
      } = this.state;
      isShowLoading(true);
      const userAuth = getUserAuthRedux();
      let preSalesTransId = data.preSalesTransId;

      const configService = {
        isShowLoading: false,
      }

      const updateCertificate = async () => {
        await this.projectDocumentCertificateManage.handleUploadPDF();
        const {
          m3ProductSelectedList,
        } = this.state;
        const totalUpdate = this.projectDocumentCertificateManage.getTotalDocumentarySelected();


        let cntUpdate = 0;
        m3ProductSelectedList.forEach((e) => {
          e.documentaryList.filter(ed => ed.isSelected).forEach(async (ed) => {
            const paramsUpdate = {
              pre_sales_trans_cert_id: e.preSalesTransCertId,
              doc_ms_id: ed.id,
              pre_sales_trans_cert_path: ed.filePath,
              pre_sales_trans_cert_post_path: ed.filePostPath,
              updated_by: userAuth.name
            }
            const projectDocumentService = projectDocumentController(configService);
            const resUpdate = await projectDocumentService.updateProjectDocumentCertificateDetail(paramsUpdate);
            if (isValidResponse(resUpdate)) {
              cntUpdate++;
              if (cntUpdate === totalUpdate) {
                isShowLoading(false);
                if (isCanRegenerate) {
                  toast.success(getLanguage('message.certificateSuccessUpdate'));
                }
                else {
                  toast.success(getLanguage('message.certificateSuccessCreate'));
                }
                this.projectDocumentCertificateManage.onClickBackProjectDocumentManage();
              }
            }
            else {
              isShowLoading(false);
              toast.error(resUpdate.message);
            }
          });
        });
      }

      const createCertificate = async () => {
        const params = {
          pre_sales_trans_id: preSalesTransId,
          certificate: m3ProductSelectedList.map(e => {
            return {
              pre_sales_trans_corp_name: e.isSelectedSpecifyCompanyName ? e.specifyCompanyName || '' : '',
              pre_sales_trans_m3_prod_grp_name: e.m3ProductGroupName,
              certificate_detail: e.documentaryList.filter(ed => ed.isSelected).map(ed => {
                return {
                  doc_ms_id: ed.id,
                  pre_sales_trans_cert_path: '',
                  pre_sales_trans_cert_post_path: '',
                }
              })
            };
          }),
          created_by: userAuth.name
        }
        const projectDocumentService = projectDocumentController(configService);
        const res = await projectDocumentService.projectDocumentCertificate(params);
        if (isValidResponse(res)) {
          this.setState({
            m3ProductSelectedList: m3ProductSelectedList.map(e => {
              (res.certificate || []).forEach(ec => {
                if (e.m3ProductGroupName === ec.pre_sales_trans_m3_prod_grp_name) {
                  e.preSalesTransCertId = ec.pre_sales_trans_cert_id;
                  e.documentaryList = e.documentaryList.map(ed => {
                    if (ed.isSelected) {
                      (ec.certificate_detail || []).forEach(ecd => {
                        if (ed.id === ecd.doc_ms_id) {
                          ed.preSalesTransCertDTId = ecd.pre_sales_trans_cert_dt_id;
                          ed.preSalesTransCertDtCode = ecd.pre_sales_trans_cert_dt_code;
                        }
                      })
                    }
                    return ed;
                  })
                }
              });
              return e;
            })
          }, () => {
            updateCertificate();
          })
        }
        else {
          isShowLoading(false);
          toast.error(res.message);
        }
      }

      if (data.isRegenerate && isCanRegenerate) {
        updateCertificate();
      }
      else {
        createCertificate();
      }
    },
    nextStep: () => {
      const {
        currentStepIndex,
        stepContentList,
      } = this.state;
      const nextStepIndex = currentStepIndex + 1;
      this.setState({
        currentStepIndex: nextStepIndex,
        currentStepContent: stepContentList[nextStepIndex],
      });
    },
    getDocumentFullname: (item, pattern = ' ') => {
      return `${item.docMsCate}${pattern}${item.docMsCode}${pattern}${item.docMsName}`;
    },
    getTotalDocumentarySelected: () => {
      const {
        m3ProductSelectedList,
      } = this.state;
      let total = 0;
      m3ProductSelectedList.forEach(e => {
        e.documentaryList.forEach(ed => {
          if (ed.isSelected) {
            total++;
          }
        });
      });
      return total;
    },
    handleUploadPDF: () => {
      return new Promise(resolve => {
        const {
          m3ProductSelectedList,
          data,
          certificateTypeList,
        } = this.state;
        this.setState({
          isGeneratePdf: true,
          m3ProductSelectedList: m3ProductSelectedList.map(e => {
            e.documentaryList = e.documentaryList.map(ed => {
              if (ed.isSelected) {
                const handlePDFData = async (target, targetFilePath, targetFileUrl, targetFileName, targetPathUpload) => {
                  const fileName = `${data.projectName}_${e.preSalesTransCertId}_${ed.preSalesTransCertDTId}_${e.m3ProductGroupName}_${this.projectDocumentCertificateManage.getDocumentFullname(ed, '_')}_${target}_${new Date().getTime()}.pdf`;
                  const pathUpload = `${ENUM.UPLOAD_PATH.PROJECT_DOCUMENT}/${data.preSalesTransId}${ENUM.UPLOAD_PATH_FOLDER.PROJECT_DOCUMENT_CERTIFICATE}`;
                  ed[targetFileName] = fileName.replaceAll(' ', '_');
                  ed[targetPathUpload] = pathUpload.replaceAll(' ', '_');

                  const filePath = `${FIREBASE_STORAGE}${ed[targetPathUpload]}/${ed[targetFileName]}`;
                  const fileUrl = displayUrlPath(filePath);
                  ed[targetFilePath] = filePath;
                  ed[targetFileUrl] = fileUrl;
                }
                handlePDFData(certificateTypeList[0], 'filePath', 'fileUrl', 'fileName', 'pathUpload');
                handlePDFData(certificateTypeList[1], 'filePostPath', 'filePostUrl', 'filePostName', 'pathPostUpload');
              }
              return ed;
            });
            return e;
          })
        }, () => {
          const {
            m3ProductSelectedList,
            certificateTypeList,
          } = this.state;
          let totalDocumentUpload = this.projectDocumentCertificateManage.getTotalDocumentarySelected();
          let documentUploaded = 0;

          m3ProductSelectedList.forEach(e => {
            e.documentaryList.filter(ed => ed.isSelected).forEach(ed => {
              const uploadPDF = async (callback = () => { }) => {
                const uploadPDFTemplate = async (target, targetFileName, targetPathUpload) => {
                  const dom = this[`${target}CertificateTemplateRef_${e.id}_${ed.id}`];
                  const pdfFile = await html2pdf().set({
                    margin: [0, 0],
                    html2canvas: { scale: 2.2, },
                  }).from(dom).outputPdf('blob');
                  getBlobtoPath(pdfFile, ed[targetFileName], ed[targetPathUpload]);
                }
                await uploadPDFTemplate(certificateTypeList[0], 'fileName', 'pathUpload');
                await uploadPDFTemplate(certificateTypeList[1], 'filePostName', 'pathPostUpload');
                callback();
              }

              this[`${certificateTypeList[0]}DocumentaryTemplateRef_${e.id}_${ed.id}`].documentaryTemplate.waitingDocumentaryLoadFinish(() => {
                this[`${certificateTypeList[1]}DocumentaryTemplateRef_${e.id}_${ed.id}`].documentaryTemplate.waitingDocumentaryLoadFinish(() => {
                  uploadPDF(() => {
                    documentUploaded++
                    if (documentUploaded === totalDocumentUpload) {
                      this.setState({
                        isGeneratePdf: false,
                        m3ProductSelectedList: _.orderBy(m3ProductSelectedList, ['m3ProductGroupName'], ['asc']),
                      }, () => {
                        resolve();
                      })
                    }
                  });
                });
              });
            });
          });
        });
      });
    },
    onClickContinue: () => {
      const {
        currentStepIndex,
      } = this.state;
      switch (currentStepIndex) {
        case 0:
          this.projectDocumentCertificateManage.getM3DocumentaryList(() => {
            this.projectDocumentCertificateManage.nextStep();
          });
          break;
        case 1:
          this.projectDocumentCertificateManage.nextStep();
          break;
        case 2:
          this.projectDocumentCertificateManage.handleManageCertificate();
          break;
        default: break;
      }
    },
    onClickSortM3Product: () => {
      const {
        sortIcon,
        m3ProductList,
      } = this.state;
      sortIcon.m3ProductGroupName = !sortIcon.m3ProductGroupName;
      const sortType = sortIcon.m3ProductGroupName ? 'asc' : 'desc';
      this.setState({
        sortIcon,
        m3ProductList: _.orderBy(m3ProductList, ['m3ProductGroupName'], [sortType])
      })
    },
    onClickSortDocumentary: (target, index) => {
      const {
        m3ProductSelectedList,
      } = this.state;
      const sortIcon = m3ProductSelectedList[index].sortIcon;
      const currentSort = m3ProductSelectedList[index].currentSort;
      Object.keys(sortIcon).forEach(key => {
        if (key === target) {
          sortIcon[key] = currentSort === target ? !sortIcon[key] : true;
        }
        else {
          sortIcon[key] = true;
        }
      })

      const sortType = sortIcon[target] ? 'desc' : 'asc';
      m3ProductSelectedList[index].documentaryList = _.orderBy(m3ProductSelectedList[index].documentaryList, [target], [sortType]);
      m3ProductSelectedList[index].sortIcon = sortIcon;
      m3ProductSelectedList[index].currentSort = target;
      this.setState({
        m3ProductSelectedList
      })
    },
    onClickPreviewDocumentary: (item) => {
      this.setState({
        isShowPreviewDocumentaryModal: true,
        previewDocumentaryDataModal: item
      })
    },
    onClickClosePreviewDocumentaryModal: () => {
      this.setState({
        isShowPreviewDocumentaryModal: false,
      })
    },
    isDisableButtonContinueInformation: () => {
      const {
        m3ProductSelectedList,
        data,
      } = this.state;
      let isDisabled = false;
      m3ProductSelectedList.forEach(e => {
        if (e.documentaryList.filter(ed => ed.isSelected).length === 0) {
          isDisabled = true;
        }
      });
      return isDisabled;
    },
    onClickPreviewCertificateTemplate: (item, documentData, target) => {
      this.setState({
        isShowCertificateTemplateModal: true,
        previewCertificateTemplateData: {
          ...item,
          // documentaryList: item.documentaryList.filter(e => e.isSelected),
          documentaryList: [documentData],
          certificateType: target,
        },
      });
    },
    renderContent: () => {
      const {
        currentStepIndex,
        searchM3ProductValue,
        sortIcon,
        m3ProductList,
        m3ProductSelectedList,
        certificateTypeList,
      } = this.state;

      console.log('m3ProductSelectedList', m3ProductSelectedList);

      const renderSelectM3Product = () => {
        return (
          <ShapeContainer ui="contentStep">
            <Section relative>
              <Section spacingBottom="46">
                <Grid gutter="15" justify="flex-end">
                  <Grid.Column>
                    <Field
                      border
                      name="searchM3ProductValue"
                      ui="blockLabelVerticalSearch"
                      width="300"
                      placeholder={getLanguage("certificate.placeholder.placeholderSearchProductGroup", "")}
                      value={searchM3ProductValue}
                      onChange={this.input.onChangeInput}
                    />
                  </Grid.Column>
                  <Grid.Column>
                    <Button
                      blue
                      width="100"
                      name={getLanguage("documentTemplate.button.buttonSearch", "")}
                      onClick={this.projectDocumentCertificateManage.onClickSearchM3Product}
                    />
                  </Grid.Column>
                </Grid>
              </Section>
              <Section spacingBottom="88">
                <Table striped>
                  <Table.Section>
                    <Table.Row>
                      <Table.HeadColumn
                        noSort
                        maxWidth={50}
                        minWidth={50}
                      >
                        {getLanguage("certificate.table.header.select", "")}
                      </Table.HeadColumn>
                      <Table.HeadColumn
                        minWidth={90}
                        sortAscending={sortIcon.m3ProductGroupName}
                        onClickSort={() => {
                          this.projectDocumentCertificateManage.onClickSortM3Product('m3ProductGroupName');
                        }}
                      >
                        {getLanguage("certificate.table.header.productGroup", "")}
                      </Table.HeadColumn>
                    </Table.Row>
                  </Table.Section>
                  <Table.Section>
                    {this.projectDocumentCertificateManage.getM3ProductFilterDataList().map((e, i) => {
                      return (
                        <Table.Row key={i}>
                          <Table.BodyColumn
                            action
                            alignCenter
                            maxWidth={VARIABLES.TABLE.INFO_WIDTHS.CHECKBOX_SQUARE}
                            minWidth={VARIABLES.TABLE.INFO_WIDTHS.CHECKBOX_SQUARE}
                          >
                            <Section spacingTop="-2">
                              <Field
                                checked={e.isSelected || false}
                                ui="checkboxTick"
                                onChange={() => { this.input.onChangeSelectM3Product(e) }}
                              />
                            </Section>
                          </Table.BodyColumn>
                          <Table.BodyColumn
                            info
                            minWidth={90}
                            title={e.m3ProductGroupName}
                          >
                            {e.m3ProductGroupName}
                          </Table.BodyColumn>
                        </Table.Row>
                      );
                    })}
                  </Table.Section>
                </Table>
              </Section>
              <Grid gutter="15" justify="flex-end">
                <Grid.Column>
                  <Button
                    gray
                    ui="back"
                    name={getLanguage("field.buttonBack", "")}
                    onClick={this.projectDocumentCertificateManage.onClickBack}
                  />
                </Grid.Column>
                <Grid.Column>
                  <Button
                    blue
                    disabled={m3ProductList.filter(e => e.isSelected).length === 0}
                    name={getLanguage("field.buttonSaveAndContinue", "")}
                    width={VARIABLES.BUTTON.WIDTHS.W_147}
                    onClick={this.projectDocumentCertificateManage.onClickContinue}
                  />
                </Grid.Column>
              </Grid>
            </Section>
          </ShapeContainer>
        )
      }

      const renderSelectDocumentary = () => {
        return (
          <ShapeContainer ui="contentStep">
            <Section relative>
              {m3ProductSelectedList.map((e, i) => {
                return (
                  <Section spacingBottom="10" key={i}>
                    <AccordionControl outline>
                      <Accordion accordion>
                        <AccordionItem
                          expanded={false}
                          className={ClassNames(
                            'accordion__item',
                            { 'is-error': false },
                            { 'is-success': (e.documentaryList || []).filter(ed => ed.isSelected).length > 0 },
                          )}
                        >
                          <AccordionItemTitle>
                            <AccordionControl.ButtonOutline
                              name={e.m3ProductGroupName}
                            />
                          </AccordionItemTitle>
                          <AccordionItemBody>
                            <ShapeContainer
                              fluid
                              padding='15'
                              bgColor={VARIABLES.COLORS.BLUE_5}
                              borderRadius='5'
                            >
                              <Section spacingBottom='30'>
                                <TitleSection name={getLanguage('warrantyManage.form.documentary.title.selectDocumentary', '')} />
                              </Section>
                              <ShapeContainer
                                fluid
                                bgColor={VARIABLES.COLORS.WHITE}
                                borderRadius='5'
                                padding='15'
                              >
                                <Section >
                                  <Table striped>
                                    <Table.Section>
                                      <Table.Row>
                                        <Table.HeadColumn
                                          minWidth={85}
                                          maxWidth={85}
                                          sortAscending={e.sortIcon.docMsCate}
                                          onClickSort={() => {
                                            this.projectDocumentCertificateManage.onClickSortDocumentary('docMsCate', i);
                                          }}
                                        >
                                          {getLanguage('projectDocument.table.header.category', '')}
                                        </Table.HeadColumn>
                                        <Table.HeadColumn
                                          maxWidth={100}
                                          minWidth={100}
                                          sortAscending={e.sortIcon.docMsCode}
                                          onClickSort={() => {
                                            this.projectDocumentCertificateManage.onClickSortDocumentary('docMsCode', i);
                                          }}
                                        >
                                          {getLanguage('projectDocument.table.header.code', '')}
                                        </Table.HeadColumn>
                                        <Table.HeadColumn
                                          minWidth={635}
                                          sortAscending={e.sortIcon.docMsName}
                                          onClickSort={() => {
                                            this.projectDocumentCertificateManage.onClickSortDocumentary('docMsName', i);
                                          }}
                                        >
                                          {getLanguage('projectDocument.table.header.name', '')}
                                        </Table.HeadColumn>
                                        <Table.HeadColumn
                                          maxWidth={VARIABLES.TABLE.INFO_WIDTHS.ACTION_EDIT_LENGTH}
                                          minWidth={VARIABLES.TABLE.INFO_WIDTHS.ACTION_EDIT_LENGTH}
                                          noSort
                                        >
                                          {getLanguage('projectDocument.table.header.preview', '')}
                                        </Table.HeadColumn>
                                        <Table.HeadColumn
                                          maxWidth={VARIABLES.TABLE.INFO_WIDTHS.CHECKBOX_SQUARE}
                                          minWidth={VARIABLES.TABLE.INFO_WIDTHS.CHECKBOX_SQUARE}
                                          noSort
                                          alignCenter
                                        >
                                          {getLanguage('projectDocument.table.header.select', '')}
                                        </Table.HeadColumn>
                                      </Table.Row>
                                    </Table.Section>
                                    <Table.Section>
                                      {
                                        (e.documentaryList || []).map((ed, id) => {
                                          return (
                                            <Table.Row key={id}>
                                              <Table.BodyColumn
                                                info
                                                maxWidth={85}
                                                minWidth={85}
                                                title={ed.docMsCate}
                                              >
                                                {ed.docMsCate}
                                              </Table.BodyColumn>
                                              <Table.BodyColumn
                                                info
                                                maxWidth={100}
                                                minWidth={100}
                                                title={ed.docMsCode}
                                              >
                                                {ed.docMsCode}
                                              </Table.BodyColumn>
                                              <Table.BodyColumn
                                                info
                                                minWidth={635}
                                                title={ed.docMsName}
                                              >
                                                {ed.docMsName}
                                              </Table.BodyColumn>
                                              <Table.BodyColumn
                                                info
                                                maxWidth={VARIABLES.TABLE.INFO_WIDTHS.ACTION_EDIT_LENGTH}
                                                minWidth={VARIABLES.TABLE.INFO_WIDTHS.ACTION_EDIT_LENGTH}
                                              >
                                                <Section>
                                                  <Image
                                                    title={'Preview'}
                                                    ui='iconDocuments'
                                                    onClick={() => this.projectDocumentCertificateManage.onClickPreviewDocumentary(ed)}
                                                  />
                                                </Section>
                                              </Table.BodyColumn>
                                              <Table.BodyColumn
                                                action
                                                maxWidth={VARIABLES.TABLE.INFO_WIDTHS.CHECKBOX_SQUARE}
                                                minWidth={VARIABLES.TABLE.INFO_WIDTHS.CHECKBOX_SQUARE}
                                              >
                                                <Section spacingTop='-2'>
                                                  <Field
                                                    checked={ed.isSelected || false}
                                                    ui='checkboxTick'
                                                    onChange={() => this.input.onChangeSelectDocumentary(e, ed)}
                                                  />
                                                </Section>
                                              </Table.BodyColumn>
                                            </Table.Row>
                                          )
                                        })
                                      }
                                    </Table.Section>
                                  </Table>
                                </Section>
                              </ShapeContainer>
                              <Section spacingTop='30' >
                                <Grid gutter='15' align='center'>
                                  <Grid.Column>
                                    <Grid gutter='10' align='center'>
                                      <Grid.Column>
                                        <Field
                                          ui='checkboxTickLabelBlack'
                                          checked={e.isSelectedSpecifyCompanyName || false}
                                          onChange={() => this.input.onChangeSelectSpecifyCompanyName(e)}
                                        />
                                      </Grid.Column>
                                      <Grid.Column>
                                        <Text regular14 color={e.isSelectedSpecifyCompanyName ? VARIABLES.COLORS.BLACK : VARIABLES.COLORS.GRAY_19}>
                                          {getLanguage('projectDocument.label.specifyCompanyNameInCertificate', '')}
                                        </Text>
                                      </Grid.Column>
                                    </Grid>
                                  </Grid.Column>
                                  <Grid.Column>
                                    <Field
                                      ui='blockLabelVertical'
                                      error={false}
                                      disabled={!e.isSelectedSpecifyCompanyName}
                                      width='450'
                                      placeholder={getLanguage('warrantyManage.form.documentary.placeholder.typeCompanyName', '')}
                                      value={e.specifyCompanyName || ''}
                                      onChange={(ev) => this.input.onChangeSpecifyCompanyName(ev, e)}
                                    />
                                  </Grid.Column>
                                </Grid>
                              </Section>
                            </ShapeContainer>
                          </AccordionItemBody>
                        </AccordionItem>
                      </Accordion>
                    </AccordionControl>
                  </Section>
                )
              })}
              <Section top="315" spacingTop="315">
                <Grid gutter="15" justify="flex-end">
                  <Grid.Column>
                    <Button
                      gray
                      ui="back"
                      name={getLanguage("field.buttonBack", "")}
                      onClick={this.projectDocumentCertificateManage.onClickBack}
                    />
                  </Grid.Column>
                  <Grid.Column>
                    <Button
                      blue
                      disabled={this.projectDocumentCertificateManage.isDisableButtonContinueInformation()}
                      name={getLanguage("field.buttonSaveAndContinue", "")}
                      width={VARIABLES.BUTTON.WIDTHS.W_147}
                      onClick={this.projectDocumentCertificateManage.onClickContinue}
                    />
                  </Grid.Column>
                </Grid>
              </Section>
            </Section>
          </ShapeContainer>
        )
      }

      const renderCertificateTemplate = () => {
        return (
          <ShapeContainer ui="contentStep">
            <Section relative>
              {m3ProductSelectedList.map((e, i) => {
                return e.documentaryList.map((ed, id) => {
                  return ed.isSelected ?
                    (
                      <Section spacingBottom="10" key={`${i}_${id}`}>
                        <ShapeContainer
                          fluid
                          height="40"
                          borderRadius="5"
                          borderWidth="1"
                          borderStyle="solid"
                          borderColor={VARIABLES.COLORS.BLUE_22}
                          paddingTop="12"
                          paddingHorizontal="10"
                          paddingBottom="32"
                          align="center"
                        >
                          <Grid justify="space-between">
                            <Grid.Column>
                              <Section>
                                <Text
                                  bold12
                                  color={VARIABLES.COLORS.BLACK}
                                >
                                  {e.m3ProductGroupName} - {this.projectDocumentCertificateManage.getDocumentFullname(ed)}
                                </Text>
                              </Section>
                            </Grid.Column>
                            <Grid.Column>
                              <Grid gutter='10' gutterVertical='20'>
                                <Grid.Column>
                                  {ed.isShowPreCertificate &&
                                    <Button
                                      lightBlue
                                      ui="frontIconSmall"
                                      iconWidth="7.5"
                                      iconHeight="9"
                                      iconSrc={ICONS["ic-document-fill.svg"]}
                                      name={getLanguage('projectDocument.button.buttonPreviewPreCertificate', '')}
                                      width="150"
                                      onClick={() => this.projectDocumentCertificateManage.onClickPreviewCertificateTemplate(e, ed, certificateTypeList[0])}
                                    />
                                  }
                                </Grid.Column>
                                <Grid.Column>
                                  {ed.isShowPostCertificate &&
                                    <Button
                                      lightGreen
                                      ui="frontIconSmall"
                                      iconWidth="7.5"
                                      iconHeight="9"
                                      iconSrc={ICONS["ic-document-fill.svg"]}
                                      name={getLanguage('projectDocument.button.buttonPreviewPostCertificate', '')}
                                      width="150"
                                      onClick={() => this.projectDocumentCertificateManage.onClickPreviewCertificateTemplate(e, ed, certificateTypeList[1])}
                                    />
                                  }
                                </Grid.Column>
                              </Grid>
                            </Grid.Column>
                          </Grid>
                        </ShapeContainer>
                      </Section>
                    )
                    :
                    null;
                });
              })}
              <Section spacingTop="315">
                <Grid gutter="15" justify="flex-end">
                  <Grid.Column>
                    <Button
                      gray
                      ui="back"
                      name={getLanguage("field.buttonBack", "")}
                      onClick={this.projectDocumentCertificateManage.onClickBack}
                    />
                  </Grid.Column>
                  <Grid.Column>
                    <Button
                      blue
                      name={getLanguage("field.buttonSaveAndContinue", "")}
                      width={VARIABLES.BUTTON.WIDTHS.W_147}
                      onClick={this.projectDocumentCertificateManage.onClickContinue}
                    />
                  </Grid.Column>
                </Grid>
              </Section>
            </Section>
          </ShapeContainer>
        );
      }

      switch (currentStepIndex) {
        case 0:
          return renderSelectM3Product();
        case 1:
          return renderSelectDocumentary();
        case 2:
          return renderCertificateTemplate();
        default: return null;
      }
    },
    getCustomerAddress: () => {
      const {
        data,
      } = this.state;
      const customerAddress = `${data.installationAddress || ''}
      ${(data.subDistrict || {}).label || ''}
      ${(data.selectedCity || {}).label || ''}
      ${(data.selectedProvince || {}).label || ''}
      ${(data.postCode || {}).label || ''}
      ${ENUM.COUNTRY_TYPE.THAI}`;
      return customerAddress;
    },
  };

  input = {
    onChangeInput: (ev) => {
      const name = ev.target.name;
      const value = ev.target.value;
      this.setState({
        [name]: value,
      })
    },
    onChangeSelectM3Product: (item) => {
      const {
        m3ProductList,
      } = this.state;
      this.setState({
        m3ProductList: m3ProductList.map(e => {
          if (e.id === item.id) {
            e.isSelected = !e.isSelected;
          }
          return e;
        })
      })
    },
    onChangeSelectDocumentary: (item, documentaryItem) => {
      const {
        m3ProductSelectedList,
        data
      } = this.state;
      this.setState({
        m3ProductSelectedList: m3ProductSelectedList.map(e => {
          if (e.id === item.id) {
            e.documentaryList = e.documentaryList.map(ed => {
              if (ed.id === documentaryItem.id) {
                ed.isSelected = !ed.isSelected;
              }
              return ed;
            });
          }
          return e;
        })
      })
    },
    onChangeSelectSpecifyCompanyName: (item) => {
      const {
        m3ProductSelectedList,
      } = this.state;
      this.setState({
        m3ProductSelectedList: m3ProductSelectedList.map(e => {
          if (e.id === item.id) {
            e.isSelectedSpecifyCompanyName = !e.isSelectedSpecifyCompanyName;
          }
          return e;
        })
      })
    },
    onChangeSpecifyCompanyName: (ev, item) => {
      const {
        m3ProductSelectedList,
      } = this.state;
      const value = ev.target.value;
      this.setState({
        m3ProductSelectedList: m3ProductSelectedList.map(e => {
          if (e.id === item.id) {
            e.specifyCompanyName = value;
          }
          return e;
        })
      })
    }
  }

  modal = {
    onClickCloseCertificateTemplateModal: () => {
      this.setState({
        isShowCertificateTemplateModal: false,
      })
    },
  }

  render() {
    const {
      data,
      currentStepContent,
      isGeneratePdf,
      m3ProductSelectedList,

      isShowPreviewDocumentaryModal,
      previewDocumentaryDataModal,

      certificateTypeList,
      isShowCertificateTemplateModal,
      previewCertificateTemplateData,
      isCanRegenerate,
    } = this.state;
    return (
      <React.Fragment>
        <Breadcrumb>
          <Breadcrumb.List
            link
            name={getLanguage("certificate.breadcrumb1", "")}
            onClick={() => {
              redirect(ROUTE_PATH.HOME.LINK);
            }}
          />
          <Breadcrumb.List name="/" />
          <Breadcrumb.List
            link
            name={getLanguage('projectDocument.breadcrumb2', '')}
            onClick={this.projectDocumentCertificateManage.onClickBackProjectDocumentManage}
          />
          <Breadcrumb.List name="/" />
          <Breadcrumb.List
            link
            name={data.customerData.fullname}
            onClick={this.projectDocumentCertificateManage.onClickBackProjectDocumentManage}
          />
          <Breadcrumb.List name="/" />
          <Breadcrumb.List
            link
            name={data.projectName}
            onClick={this.projectDocumentCertificateManage.onClickBackProjectDocumentManage}
          />
          <Breadcrumb.List name="/" />
          <Breadcrumb.List
            name={data.isRegenerate ? getLanguage('projectDocument.breadcrumb9', '') : getLanguage('projectDocument.breadcrumb7', '')}
          />
        </Breadcrumb>
        <Section ui="content">
          <TitlePage
            src={ICONS["ic-pre-transaction.svg"]}
            name={data.isRegenerate ? getLanguage("certificate.breadcrumb5", "") : getLanguage("certificate.breadcrumb4", "")}
          />
          <Step
            label
            progressPercent={currentStepContent.stepPercent}
          >
            {currentStepContent.contentList.map((e, i) => {
              return (
                <Step.Number
                  noWrap
                  key={i}
                  active={e.isActive}
                  success={e.isSuccess}
                  number={i + 1}
                  label={e.stepName}
                  disabled={isCanRegenerate && i < 2}
                />
              );
            })}
          </Step>
          {this.projectDocumentCertificateManage.renderContent()}

        </Section>

        {isShowPreviewDocumentaryModal && (
          <ModalPreviewDocumentTemplateContainer
            isOpenModal
            title={getLanguage("documentTemplate.modal.header.productCertificate", "").replace('[documentName]', previewDocumentaryDataModal.displayDocMsName)}
            pdfSrc={previewDocumentaryDataModal.docMsPathFileObject.path}
            onClickCancel={this.projectDocumentCertificateManage.onClickClosePreviewDocumentaryModal}
          />
        )}
        {isShowCertificateTemplateModal && (
          <ModalPreviewDocumentTemplateContainer
            isOpenModal
            isShowEdit={!isCanRegenerate}
            isShowPreviewMark
            isButtontonDownloadColorLightGreen
            isWaterMarkPreCertificate={previewCertificateTemplateData.certificateType === certificateTypeList[0]}
            data={data}
            dataDetail={previewCertificateTemplateData}
            customerAddress={this.projectDocumentCertificateManage.getCustomerAddress()}
            title={`${getLanguage("projectDocument.modal.previewCertificate.title", "").replace('[productGroup]', previewCertificateTemplateData.m3ProductGroupName)}`}
            onClickEdit={this.projectDocumentCertificateManage.onClickEdit}
            onClickCancel={this.modal.onClickCloseCertificateTemplateModal}
            buttonWidth={VARIABLES.BUTTON.WIDTHS.W_120}
          />
        )}

        {isGeneratePdf &&
          <div style={{
            position: 'absolute',
            left: '-9999px'
          }}
          >
            {/* Pre template */}
            {m3ProductSelectedList.map((e, i) => {
              return e.documentaryList.filter(ed => ed.isSelected).map((ed, id) => {
                return (
                  <div key={`${i}_${id}`} ref={r => this[`${certificateTypeList[0]}CertificateTemplateRef_${e.id}_${ed.id}`] = r}>
                    <WarrantyGeneratePdfPage isHidden={false}>
                      <DocumentaryTemplate
                        isWaterMarkPreCertificate
                        isWaterMarkCertificateTemplate
                        data={data}
                        ref={r => this[`${certificateTypeList[0]}DocumentaryTemplateRef_${e.id}_${ed.id}`] = r}
                        dataDetail={handleDocumentaryData(
                          {
                            ...e,
                            documentaryList: [ed],
                          },
                          [{
                            ...e,
                            documentaryList: [ed],
                          }],
                          DATA.WATERMARK_TEMPLATE_LIST[2].value,
                          !!ed.preSalesTransCertDTId
                        )}
                        customerAddress={this.projectDocumentCertificateManage.getCustomerAddress()}
                        watermarkTemplateType={DATA.WATERMARK_TEMPLATE_LIST[2].value}
                        productGroupName={e.m3ProductGroupName}
                      />
                    </WarrantyGeneratePdfPage>
                  </div>
                )
              });
            })}
            {/* Post template */}
            {m3ProductSelectedList.map((e, i) => {
              return e.documentaryList.filter(ed => ed.isSelected).map((ed, id) => {
                return (
                  <div key={`${i}_${id}`} ref={r => this[`${certificateTypeList[1]}CertificateTemplateRef_${e.id}_${ed.id}`] = r}>
                    <WarrantyGeneratePdfPage isHidden={false}>
                      <DocumentaryTemplate
                        isWaterMarkCertificateTemplate
                        data={data}
                        ref={r => this[`${certificateTypeList[1]}DocumentaryTemplateRef_${e.id}_${ed.id}`] = r}
                        dataDetail={handleDocumentaryData(
                          {
                            ...e,
                            documentaryList: [ed],
                          },
                          [{
                            ...e,
                            documentaryList: [ed],
                          }],
                          DATA.WATERMARK_TEMPLATE_LIST[1].value,
                          !!ed.preSalesTransCertDTId
                        )}
                        customerAddress={this.projectDocumentCertificateManage.getCustomerAddress()}
                        watermarkTemplateType={DATA.WATERMARK_TEMPLATE_LIST[1].value}
                        productGroupName={e.m3ProductGroupName}
                      />
                    </WarrantyGeneratePdfPage>
                  </div>
                )
              });
            })}
          </div>
        }
      </React.Fragment>
    );
  }
}
