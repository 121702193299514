import { displayWithPrefix, displayDate } from "../helpers/functions/display";

export class ModelCMSTnc {
  constructor(props = {}) {
    this.id = props.id;
    this.idWithPrefix = displayWithPrefix(props.id);
    this.subject = props.subject;
    this.content = props.content;
    this.createAt = props.create_at;
    this.displayCreateAt = displayDate(props.create_at);
    this.updateDate = props.update_date;
    this.displayUpdateDate = displayDate(props.update_date);
    this.displayUpdateDate = this.displayUpdateDate === 'Invalid date' ? '-' : this.displayUpdateDate;
    this.status = props.status;
    this.app = props.app;
  }
}