import React from 'react'
import ClassNames from 'classnames'
import PropTypes from 'prop-types'
import {
  ThumbnailIndexWrapper
} from './styled'
// import {
// } from './../../../components'
// import {
//   default as VARIABLES
// } from './../../../themes/styles/bases/variables'
// import {
//   default as TYPOGRAPHYS
// } from './../../../themes/styles/bases/typographys'
// import {
//   default as UTILITIES
// } from './../../../themes/styles/helpers/utilities'
import {
  ICONS
} from './../../../themes'

/**
 * ThumbnailIndex description:
 * - ThumbnailIndex
 */

export class ThumbnailIndex extends React.PureComponent {
  render () {
    const {
      className,
      onClick,
      name,
      linkName,
    } = this.props

    // props for css classes
    const classes = ClassNames(
      'thumbnail-index',
      className
    )

    return (
      <ThumbnailIndexWrapper
        className={classes}
        onClick={onClick}
      >
        <div className='thumbnail-index-text is-name'>
          { name }
        </div>
        <div className='thumbnail-index-text is-link'>
          { linkName }
          <img className='thumbnail-index-icon is-arrow' src={ICONS['ic-arrow-goto-page.svg']} alt='Icon' />
          <img className='thumbnail-index-icon is-arrow-hover' src={ICONS['ic-arrow-goto-page-white.svg']} alt='Icon' />
        </div>
      </ThumbnailIndexWrapper>
    )
  }

  static defaultProps = {
    // * If you set new default props, you should create propsType for correct styleguide document.
  }

  static propTypes = { // TYPE > node, string, number, bool, array, object, symbol, func
    /**
    * Additional classes
    */
    className: PropTypes.string
  }
}
