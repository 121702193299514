import React from 'react'
import ClassNames from 'classnames'
import PropTypes from 'prop-types'
import {
  BreadcrumbWrapper
} from './styled'
import {
  Section,
  Grid,
  Text
} from './../../../components'
// import {
//   default as VARIABLES
// } from './../../../themes/styles/bases/variables'
// import {
//   default as TYPOGRAPHYS
// } from './../../../themes/styles/bases/typographys'
// import {
//   default as UTILITIES
// } from './../../../themes/styles/helpers/utilities'
import {
  VARIABLES
} from './../../../themes'

/**
 * Breadcrumb description:
 * - Breadcrumb
 */

class BreadcrumbList extends React.PureComponent {
  render() {
    const {
      className,
      name,
      link,
      onClick,
    } = this.props

    // props for css classes
    const classes = ClassNames(
      'breadcrumb-list',
      // {'is-modifier-name': modifierName},
      className
    )

    return (
      <React.Fragment>
        <Grid.Column className={classes}>
          <Text
            bold12={link}
            regular12={!link}
            singleLine
            color={link ? VARIABLES.COLORS.PRIMARY_2 : VARIABLES.COLORS.GRAY_19}
            onClick={onClick}
          >
            {name}
          </Text>
        </Grid.Column>
      </React.Fragment>

    )
  }

  static defaultProps = {
    // * If you set new default props, you should create propsType for correct styleguide document.
  }

  static propTypes = { // TYPE > node, string, number, bool, array, object, symbol, func
    /**
    * Additional classes
    */
    className: PropTypes.string
  }
}

export class Breadcrumb extends React.PureComponent {
  render() {
    const {
      className,
      children,
      remark,
      remarkName,
    } = this.props

    // props for css classes
    const classes = ClassNames(
      'breadcrumb',
      // {'is-modifier-name': modifierName},
      className
    )

    return (
      <BreadcrumbWrapper
        className={classes}
      >
        <Section
          paddingTop='13'
          paddingBottom='14'
          paddingHorizontal='25'
          bgColor={VARIABLES.COLORS.BLUE_16}
        >
          <Grid gutter='10' justify='space-between'>
            <Grid.Column>
              <Grid gutter='10'>
                {children}
              </Grid>
            </Grid.Column>
            {
              remark && (
                <Grid.Column >
                  <Grid justify='flex-end'>
                    <Grid.Column>
                      <Text
                        bold14
                        singleLine
                        color={VARIABLES.COLORS.BLACK}
                      >
                        {remarkName}
                      </Text>
                    </Grid.Column>
                  </Grid>
                </Grid.Column>
              )
            }
          </Grid>
        </Section>
      </BreadcrumbWrapper>
    )
  }

  static List = BreadcrumbList

  static defaultProps = {
    // * If you set new default props, you should create propsType for correct styleguide document.
  }

  static propTypes = { // TYPE > node, string, number, bool, array, object, symbol, func
    /**
    * Additional classes
    */
    className: PropTypes.string
  }
}
