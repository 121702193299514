import React from 'react'
import { connect } from 'react-redux';
import {
  ShapeContainer,
  Section,
  Breadcrumb,
  TitlePage,
  TitleSection,
  Grid,
  Button,
  Image,
  Text,
  Table,
} from './../../components'
import {
  ModalAuthorisedDealerAndRollFormerProfileContainerConnected,
} from './../../containers/ModalContainer'
import {
  VARIABLES,
  ICONS,
} from './../../themes'
import { setSideMenuActive, getRouteData } from '../../helpers/functions/route';
import { ROUTE_PATH } from '../../helpers/constants/route';
import { redirect } from '../../helpers/functions/route';
import { getAdRFDetail, insTransfer } from '../../helpers/services/transfer';
import { ProfileTransferAdInfoContainer } from './../../containers/ProfileTransferContainer'
import { toast } from '../../helpers/functions/main';
import _ from 'lodash';
import { getLanguage } from '../../helpers/functions/language';
import { dataCreators, DATA_REDUX_STATE_KEY } from '../../actions/dataAction';
import { bindActionCreators } from 'redux';
import { getUserAuthRedux } from '../../helpers/functions/auth';
import { ENUM } from '../../helpers/constants/enum';

class ProfileTransactionScreen extends React.Component {

  constructor(props) {
    super(props);
    setSideMenuActive(props);
    const userAuth = getUserAuthRedux();
    let adRfData = getRouteData(props, 'adRfData');
    const isIndoToko = userAuth.addressType === ENUM.COUNTRY_TYPE.INDONESIA && userAuth.customerRoleType === ENUM.CUSTOMER_ROLE_TYPE.INDONESIA.DISTRIBUTOR
    this.state = {
      isOpenModalAuthorisedDealerAndRollFormerProfile: false,
      isDisabledButtonSave: false,
      coilTransferItem: 0,
      Ad: false,
      Toko: false,
      AdAndToko: !isIndoToko,
      adRfData,
      // sortIcon: {
      // 	id: false,
      // 	adRfName: true,
      // 	phone: true,
      // 	email: true,
      //   type: true,
      //   province: true,
      // }
      userAuth,
      isIndoToko: isIndoToko
    }
  }

  getData() {
    const {
      dataDetail
    } = this.props;
    // console.log('getData dataDetail=', dataDetail);

    if (dataDetail) {
      const params = {
        id: dataDetail.sfid,
        dealerCode: dataDetail.dealerCode,
      };

      getAdRFDetail(params);
    } else {
      redirect(ROUTE_PATH.PROFILE_TRANSFER.LINK)
    }
  }

  async saveData() {
    const {
      dataActions,
      orderList,
      dataDetail,
    } = this.props;

    const params = {
      to_dealer: dataDetail.dealerCode,
      orderList,
    };
    const resp = await insTransfer(params);

    if (resp.message === 'success') {
      toast.success(getLanguage('message.saveCompleted', ''));
      dataActions.setDataRedux(DATA_REDUX_STATE_KEY.TRNSFER_ORDER_LIST, []);
      redirect(ROUTE_PATH.PROFILE_TRANSFER.LINK)
    } else {
      toast.error(resp.message);
    }
  }

  componentDidMount() {
    this.getData();
  }

  modal = {
    onOpenModalAuthorisedDealerAndRollFormerProfile: () => {
      this.setState({
        isOpenModalAuthorisedDealerAndRollFormerProfile: true,
      });
    },

    onCloseModalAuthorisedDealerAndRollFormerProfile: () => {
      this.setState({
        isOpenModalAuthorisedDealerAndRollFormerProfile: false,
      });
    }
  }

  render() {
    const {
      isOpenModalAuthorisedDealerAndRollFormerProfile,
      isDisabledButtonSave,
      // coilTransferItem,
      // Ad,
      // Toko,
      AdAndToko,
      // userAuth,
      isIndoToko,
    } = this.state
    const {
      stAdDetail,
      orderList,
    } = this.props;
    return (
      <React.Fragment>
        <Breadcrumb>
          <Breadcrumb.List
            link
            name={getLanguage('profileTransaction.breadcrumb1', '')}
            onClick={() => {
              redirect(ROUTE_PATH.HOME.LINK)
            }}
          />
          <Breadcrumb.List name='/' />
          <Breadcrumb.List
            link
            name={getLanguage('profileTransaction.breadcrumb2', '')}
          // onClick={() => {
          //   redirect(ROUTE_PATH.INVENTORY_INDEXES.LINK)
          // }}
          />
          <Breadcrumb.List name='/' />
          <Breadcrumb.List
            link
            name={getLanguage('profileTransaction.breadcrumb3', '')}
            onClick={() => {
              redirect(ROUTE_PATH.PROFILE_TRANSFER.LINK)
            }}
          />
          <Breadcrumb.List name='/' />
          <Breadcrumb.List name={getLanguage('profileTransaction.breadcrumb4', '')} />
        </Breadcrumb>
        <Section ui='content'>
          <Section spacingBottom='35'>
            <TitlePage
              src={ICONS['ic-page-inventory.svg']}
              name={getLanguage('profileTransaction.title', '')}
            />
          </Section>
          <ShapeContainer ui='content'>
            <Section spacingBottom='35'>
              <TitleSection
                name={isIndoToko ?
                  getLanguage('profileTransaction.form.toko.title', '')
                  :
                  getLanguage('profileTransaction.form.ad.title', '')
                }
              />
            </Section>
            <Section spacingBottom='35'>
              <Grid
                gutter='35'
                gutterVertical='35'
                justify='space-between'
              >
                {/* {AdAndToko ?
                  <Grid.Column>
                    <Grid
                      gutter='55'
                      gutterVertical='35'
                    >
                      <Grid.Column>
                        <ShapeContainer
                          ui='userUpload'
                          srcUserUploadImage={true ? CONTENTS['customer-1.jpg'] : ICONS['ic-placeholder-avatar.svg']}
                        />
                      </Grid.Column>
                      <Grid.Column>
                        <Section
                          spacingTop='5'
                          spacingBottom='5'
                        >
                          <Text
                            regular14
                            color={VARIABLES.COLORS.GRAY_19}
                          >
                            Company Name xxx
                          </Text>
                        </Section>
                        <Section spacingBottom='15'>
                          <Text
                            regular22
                            color={VARIABLES.COLORS.BLACK}
                          >
                          </Text>
                        </Section>
                        <Section
                          spacingTop='5'
                          spacingBottom='5'
                        >
                          <Text
                            regular14
                            color={VARIABLES.COLORS.GRAY_19}
                          >
                            Phone No.
                          </Text>
                        </Section>
                        <Section spacingBottom='15'>
                          <Text
                            regular22
                            color={VARIABLES.COLORS.BLACK}
                          >
                            <Image
                              ui='flagWithPhoneIdAndNumber'
                              phoneId='66'
                              phoneNumber='899978765'
                              src={ICONS['ic-flag-th.jpg']}
                            />
                          </Text>
                        </Section>
                      </Grid.Column>
                      <Grid.Column>
                        <Section
                          spacingTop='5'
                          spacingBottom='10'
                        >
                          <Text
                            regular14
                            color={VARIABLES.COLORS.GRAY_19}
                          >
                            Email
                          </Text>
                        </Section>
                        <Section>
                          <Text
                            regular14
                            color={VARIABLES.COLORS.BLACK}
                          >
                            aka@mail.com
                          </Text>
                        </Section>
                      </Grid.Column>
                    </Grid>
                  </Grid.Column>
                  :
                  <ProfileTransferAdInfoContainer
                    stAdDetail={stAdDetail.detail ? stAdDetail.detail : {}}
                  />
                } */}
                <ProfileTransferAdInfoContainer
                  stAdDetail={stAdDetail.detail ? stAdDetail.detail : {}}
                />
                <Grid.Column>
                  <Section spacingBottom='15'>
                    <Button
                      blue
                      ui='frontIcon'
                      name={getLanguage('profileTransaction.form.ad.buttonSelect', '')}
                      iconWidth='22'
                      iconHeight='18'
                      iconSrc={ICONS['ic-select-customer.svg']}
                      width={VARIABLES.BUTTON.WIDTHS.W_200}
                      onClick={this.modal.onOpenModalAuthorisedDealerAndRollFormerProfile}
                    />
                  </Section>
                  {AdAndToko ?
                    undefined
                    :
                    <React.Fragment>
                      {/* <Section justify='center'>
                        <Image ui='iconSymbolTrained' />
                      </Section> */}
                    </React.Fragment>
                  }
                </Grid.Column>
              </Grid>
            </Section>
            <Section spacingBottom='25'>
              <TitleSection name={getLanguage('profileTransaction.form.latestProfileTransfer.title', '')} />
            </Section>
            <Section spacingBottom='40'>
              {AdAndToko &&
                <Table striped>
                  <Table.Section>
                    <Table.Row>
                      <Table.HeadColumn
                        noSort
                        maxWidth={VARIABLES.TABLE.INFO_WIDTHS.DATE}
                        minWidth={VARIABLES.TABLE.INFO_WIDTHS.DATE}
                      >
                        {getLanguage('profileTransaction.form.latestProfileTransfer.table.header.date', '')}
                      </Table.HeadColumn>
                      <Table.HeadColumn
                        noSort
                        maxWidth={VARIABLES.TABLE.INFO_WIDTHS.ORDER_NO}
                        minWidth={VARIABLES.TABLE.INFO_WIDTHS.ORDER_NO}
                      >
                        {getLanguage('profileTransaction.form.latestProfileTransfer.table.header.orderNo', '')}
                      </Table.HeadColumn>
                      <Table.HeadColumn
                        noSort
                        minWidth={VARIABLES.TABLE.INFO_WIDTHS.COIL_NAME}
                      >
                        {getLanguage('profileTransaction.form.latestProfileTransfer.table.header.coilName', '')}
                      </Table.HeadColumn>
                      <Table.HeadColumn
                        noSort
                        minWidth={VARIABLES.TABLE.INFO_WIDTHS.COIL_ID_INFO}
                      >
                        {getLanguage('profileTransaction.form.latestProfileTransfer.table.header.coilID', '')}
                      </Table.HeadColumn>
                      <Table.HeadColumn
                        noSort
                        alignRight
                        maxWidth={VARIABLES.TABLE.INFO_WIDTHS.TRANSFER_AMOUNT_M}
                        minWidth={VARIABLES.TABLE.INFO_WIDTHS.TRANSFER_AMOUNT_M}
                      >
                        {getLanguage('profileTransaction.form.latestProfileTransfer.table.header.transferAmount', '')}
                      </Table.HeadColumn>
                      <Table.HeadColumn
                        noSort
                        alignRight
                        maxWidth={VARIABLES.TABLE.INFO_WIDTHS.WEIGHT_TON}
                        minWidth={VARIABLES.TABLE.INFO_WIDTHS.WEIGHT_TON}
                      >
                        {getLanguage('profileTransaction.form.latestProfileTransfer.table.header.weight', '')}
                      </Table.HeadColumn>
                      <Table.HeadColumn
                        noSort
                        maxWidth={VARIABLES.TABLE.INFO_WIDTHS.SALESPERSON}
                        minWidth={VARIABLES.TABLE.INFO_WIDTHS.SALESPERSON}
                      >
                        {getLanguage('profileTransaction.form.latestProfileTransfer.table.header.salesperson', '')}
                      </Table.HeadColumn>
                    </Table.Row>
                  </Table.Section>
                  <Table.Section>
                    {
                      (stAdDetail.lastTransfer || []).map((e, i) => {
                        return (
                          <Table.Row>
                            <Table.BodyColumn
                              info
                              maxWidth={VARIABLES.TABLE.INFO_WIDTHS.DATE}
                              minWidth={VARIABLES.TABLE.INFO_WIDTHS.DATE}
                            >
                              {e.orderDate}
                            </Table.BodyColumn>
                            <Table.BodyColumn
                              info
                              maxWidth={VARIABLES.TABLE.INFO_WIDTHS.ORDER_NO}
                              minWidth={VARIABLES.TABLE.INFO_WIDTHS.ORDER_NO}
                              title={e.orderNo}
                            >
                              {e.orderNo}
                            </Table.BodyColumn>
                            <Table.BodyColumn
                              info
                              minWidth={VARIABLES.TABLE.INFO_WIDTHS.COIL_NAME}
                              title={e.coilName}
                            >
                              {e.coilName}
                            </Table.BodyColumn>
                            <Table.BodyColumn
                              info
                              minWidth={VARIABLES.TABLE.INFO_WIDTHS.COIL_ID_INFO}
                              title={e.coilID}
                            >
                              {e.coilID}
                            </Table.BodyColumn>
                            <Table.BodyColumn
                              info
                              alignRight
                              maxWidth={VARIABLES.TABLE.INFO_WIDTHS.TRANSFER_AMOUNT_M}
                              minWidth={VARIABLES.TABLE.INFO_WIDTHS.TRANSFER_AMOUNT_M}
                              title={e.tranAmount}
                            >
                              {e.tranAmount}
                            </Table.BodyColumn>
                            <Table.BodyColumn
                              info
                              alignRight
                              maxWidth={VARIABLES.TABLE.INFO_WIDTHS.WEIGHT_TON}
                              minWidth={VARIABLES.TABLE.INFO_WIDTHS.WEIGHT_TON}
                              title={e.weight}
                            >
                              {e.weight}
                            </Table.BodyColumn>
                            <Table.BodyColumn
                              info
                              maxWidth={VARIABLES.TABLE.INFO_WIDTHS.SALESPERSON}
                              minWidth={VARIABLES.TABLE.INFO_WIDTHS.SALESPERSON}
                              title={e.transferName}
                            >
                              {e.transferName}
                            </Table.BodyColumn>
                          </Table.Row>
                        )
                      })
                    }
                  </Table.Section>
                </Table>
              }
              {/* {Ad &&
                <Table striped>
                  <Table.Section>
                    <Table.Row>
                      <Table.HeadColumn
                        noSort
                        maxWidth={VARIABLES.TABLE.INFO_WIDTHS.DATE}
                        minWidth={VARIABLES.TABLE.INFO_WIDTHS.DATE}
                      >
                        Date
                      </Table.HeadColumn>
                      <Table.HeadColumn
                        noSort
                        minWidth={VARIABLES.TABLE.INFO_WIDTHS.ORDER_NUMBER}
                      >
                        Order No.
                      </Table.HeadColumn>
                      <Table.HeadColumn
                        noSort
                        minWidth={VARIABLES.TABLE.INFO_WIDTHS.COIL_NAME}
                      >
                        Coil Name
                      </Table.HeadColumn>
                      <Table.HeadColumn
                        noSort
                        minWidth={VARIABLES.TABLE.INFO_WIDTHS.COIL_ID}
                      >
                        Coil ID
                      </Table.HeadColumn>
                      <Table.HeadColumn
                        noSort
                        minWidth={VARIABLES.TABLE.COLUMN_WIDTHS.NAMES.PROFILE_TYPE}
                      >
                        Profile Type
                      </Table.HeadColumn>
                      <Table.HeadColumn
                        noSort
                        minWidth={VARIABLES.TABLE.COLUMN_WIDTHS.MEASUREMENTS.QUANTITY_UNIT}
                      >
                        Quantity / Unit
                      </Table.HeadColumn>
                      <Table.HeadColumn
                        noSort
                        minWidth={VARIABLES.TABLE.COLUMN_WIDTHS.MEASUREMENTS.LENGTH_UNIT}
                      >
                        Length / Unit
                      </Table.HeadColumn>
                      <Table.HeadColumn
                        noSort
                        maxWidth={VARIABLES.TABLE.INFO_WIDTHS.SALESPERSON}
                        minWidth={VARIABLES.TABLE.INFO_WIDTHS.SALESPERSON}
                      >
                        Transfer To
                      </Table.HeadColumn>
                    </Table.Row>
                  </Table.Section>
                  <Table.Section>
                    <Table.Row>
                      <Table.BodyColumn
                        info
                        maxWidth={VARIABLES.TABLE.INFO_WIDTHS.DATE}
                        minWidth={VARIABLES.TABLE.INFO_WIDTHS.DATE}
                      >
                        27/08/2019
                      </Table.BodyColumn>
                      <Table.BodyColumn
                        info
                        minWidth={VARIABLES.TABLE.INFO_WIDTHS.ORDER_NUMBER}
                        title='600182890'
                      >
                        600182890
                      </Table.BodyColumn>
                      <Table.BodyColumn
                        info
                        minWidth={VARIABLES.TABLE.INFO_WIDTHS.COIL_NAME}
                        title='0.35TCT x 914mm BLUESCOPE ZAC0 G550 st HG RumRua...'
                      >
                        0.35TCT x 914mm BLUESCOPE ZAC0 G550 st HG RumRua...
                      </Table.BodyColumn>
                      <Table.BodyColumn
                        info
                        minWidth={VARIABLES.TABLE.INFO_WIDTHS.COIL_ID}
                        title='MM2Z255756'
                      >
                        MM2Z255756
                      </Table.BodyColumn>
                      <Table.BodyColumn
                        info
                        minWidth={VARIABLES.TABLE.COLUMN_WIDTHS.NAMES.PROFILE_TYPE}
                        title='Truss C75-75'
                      >
                        Truss C75-75
                      </Table.BodyColumn>
                      <Table.BodyColumn
                        info
                        minWidth={VARIABLES.TABLE.COLUMN_WIDTHS.MEASUREMENTS.QUANTITY_UNIT}
                        title='250'
                      >
                        250
                      </Table.BodyColumn>
                      <Table.BodyColumn
                        info
                        minWidth={VARIABLES.TABLE.COLUMN_WIDTHS.MEASUREMENTS.LENGTH_UNIT}
                        title='100'
                      >
                        100
                      </Table.BodyColumn>
                      <Table.BodyColumn
                        info
                        maxWidth={VARIABLES.TABLE.INFO_WIDTHS.SALESPERSON}
                        minWidth={VARIABLES.TABLE.INFO_WIDTHS.SALESPERSON}
                        title='Will Smith'
                      >
                        Will Smith
                      </Table.BodyColumn>
                    </Table.Row>
                  </Table.Section>
                </Table>
              } */}
              {isIndoToko &&
                <Table striped>
                  <Table.Section>
                    <Table.Row>
                      <Table.HeadColumn
                        noSort
                        maxWidth={VARIABLES.TABLE.INFO_WIDTHS.DATE}
                        minWidth={VARIABLES.TABLE.INFO_WIDTHS.DATE}
                      >
                        {getLanguage('profileTransaction.form.latestProfileTransfer.table.header.date', '')}
                      </Table.HeadColumn>
                      <Table.HeadColumn
                        noSort
                        minWidth={VARIABLES.TABLE.INFO_WIDTHS.ORDER_NUMBER}
                      >
                        {getLanguage('profileTransaction.form.latestProfileTransfer.table.header.orderNo', '')}
                      </Table.HeadColumn>
                      <Table.HeadColumn
                        noSort
                        minWidth={VARIABLES.TABLE.COLUMN_WIDTHS.NAMES.PROFILE_TYPE_SHORT}
                      >
                        {getLanguage('profileTransaction.form.latestProfileTransfer.table.header.profileType', '')}
                      </Table.HeadColumn>
                      <Table.HeadColumn
                        noSort
                        minWidth={VARIABLES.TABLE.COLUMN_WIDTHS.NAMES.PRODUCT_GROUP_SHORT}
                      >
                        {getLanguage('profileTransaction.form.latestProfileTransfer.table.header.productGroup', '')}
                      </Table.HeadColumn>
                      <Table.HeadColumn
                        noSort
                        minWidth={VARIABLES.TABLE.COLUMN_WIDTHS.NUMBERS.QUANTITY}
                      >
                        {getLanguage('profileTransaction.form.latestProfileTransfer.table.header.quantity', '')}
                      </Table.HeadColumn>
                      <Table.HeadColumn
                        noSort
                        minWidth={VARIABLES.TABLE.COLUMN_WIDTHS.MEASUREMENTS.LENGTH_NUMBER}
                      >
                        {getLanguage('profileTransaction.form.latestProfileTransfer.table.header.length', '')}
                      </Table.HeadColumn>
                      <Table.HeadColumn
                        noSort
                        minWidth={VARIABLES.TABLE.COLUMN_WIDTHS.MEASUREMENTS.LENGTH_UNIT}
                      >
                        {getLanguage('profileTransaction.form.latestProfileTransfer.table.header.lengthPerUnit', '')}
                      </Table.HeadColumn>
                      <Table.HeadColumn
                        noSort
                        alignRight
                        maxWidth={VARIABLES.TABLE.INFO_WIDTHS.WEIGHT_TON}
                        minWidth={VARIABLES.TABLE.INFO_WIDTHS.WEIGHT_TON}
                      >
                        {getLanguage('profileTransaction.form.latestProfileTransfer.table.header.weight', '')}
                      </Table.HeadColumn>
                      <Table.HeadColumn
                        noSort
                        maxWidth={VARIABLES.TABLE.INFO_WIDTHS.SALESPERSON}
                        minWidth={VARIABLES.TABLE.INFO_WIDTHS.SALESPERSON}
                      >
                        {getLanguage('profileTransaction.form.latestProfileTransfer.table.header.salesperson', '')}
                      </Table.HeadColumn>
                    </Table.Row>
                  </Table.Section>
                  <Table.Section>
                    {
                      (stAdDetail.lastTransfer || []).map((e, i) => {
                        return (
                          <Table.Row>
                            <Table.BodyColumn
                              info
                              maxWidth={VARIABLES.TABLE.INFO_WIDTHS.DATE}
                              minWidth={VARIABLES.TABLE.INFO_WIDTHS.DATE}
                            >
                              {e.orderDate}
                            </Table.BodyColumn>
                            <Table.BodyColumn
                              info
                              minWidth={VARIABLES.TABLE.INFO_WIDTHS.ORDER_NUMBER}
                              title='ORDER_NUMBER'
                            >
                              {e.orderNo}
                            </Table.BodyColumn>
                            <Table.BodyColumn
                              info
                              minWidth={VARIABLES.TABLE.COLUMN_WIDTHS.NAMES.PROFILE_TYPE_SHORT}
                              title='PROFILE_TYPE'
                            >
                              {e.profileType}
                            </Table.BodyColumn>
                            <Table.BodyColumn
                              info
                              minWidth={VARIABLES.TABLE.COLUMN_WIDTHS.NAMES.PRODUCT_GROUP_SHORT}
                              title='PRODUCT_GROUP'
                            >
                              {e.productGrpName}
                            </Table.BodyColumn>
                            <Table.BodyColumn
                              info
                              minWidth={VARIABLES.TABLE.COLUMN_WIDTHS.NUMBERS.QUANTITY}
                            >
                              {e.quantity}
                            </Table.BodyColumn>
                            <Table.BodyColumn
                              info
                              minWidth={VARIABLES.TABLE.COLUMN_WIDTHS.MEASUREMENTS.LENGTH_NUMBER}
                            >
                              {e.length}
                            </Table.BodyColumn>
                            <Table.BodyColumn
                              info
                              minWidth={VARIABLES.TABLE.COLUMN_WIDTHS.MEASUREMENTS.LENGTH_UNIT}
                            >
                              {e.unit}
                            </Table.BodyColumn>
                            <Table.BodyColumn
                              info
                              alignRight
                              maxWidth={VARIABLES.TABLE.INFO_WIDTHS.WEIGHT_TON}
                              minWidth={VARIABLES.TABLE.INFO_WIDTHS.WEIGHT_TON}
                            >
                              {e.weight}
                            </Table.BodyColumn>
                            <Table.BodyColumn
                              info
                              maxWidth={VARIABLES.TABLE.INFO_WIDTHS.SALESPERSON}
                              minWidth={VARIABLES.TABLE.INFO_WIDTHS.SALESPERSON}
                              title='Will Smith'
                            >
                              {e.transferName}
                            </Table.BodyColumn>
                          </Table.Row>
                        )
                      })
                    }
                  </Table.Section>
                </Table>
              }
            </Section>
            <Section spacingBottom={orderList.length === 0 ? '50' : '25'}>
              <TitleSection name={getLanguage('profileTransaction.form.profileTransferSummary.title', '')} />
            </Section>
            <Section spacingBottom={orderList.length === 0 ? '110' : '30'}>
              {
                orderList.length === 0 ?
                  <React.Fragment>
                    <Section
                      spacingBottom='20'
                      justify='center'
                    >
                      <Image ui='iconBlankProduct' />
                    </Section>
                    <Section
                      spacingBottom='20'
                      justify='center'
                    >
                      <Text
                        regular14
                        color={VARIABLES.COLORS.BLACK}
                      >
                        {getLanguage('profileTransaction.form.profileTransferSummary.blank', '')}
                      </Text>
                    </Section>
                    <Section justify='center'>
                      <Button
                        brown
                        ui='frontIcon'
                        name={getLanguage('profileTransaction.form.profileTransferSummary.buttonSelectProduct', '')}
                        iconWidth='14'
                        iconHeight='14'
                        iconSrc={ICONS['ic-create.svg']}
                        width={VARIABLES.BUTTON.WIDTHS.W_200}
                        onClick={() => {
                          redirect(ROUTE_PATH.PROFILE_TRANSACTION_MANAGE.LINK)
                        }}
                      />
                    </Section>
                  </React.Fragment>
                  :
                  <React.Fragment>
                    {/* {!AdAndToko && */}
                    <Table striped>
                      <Table.Section>
                        <Table.Row>
                          <Table.HeadColumn
                            maxWidth={VARIABLES.TABLE.INFO_WIDTHS.COIL_ID}
                            minWidth={VARIABLES.TABLE.INFO_WIDTHS.COIL_ID}
                            sortAscending={true}
                          // onClickSort={}
                          >
                            {getLanguage('profileTransaction.form.profileTransferSummary.table.header.coilID', '')}
                          </Table.HeadColumn>
                          <Table.HeadColumn
                            minWidth={VARIABLES.TABLE.COLUMN_WIDTHS.NAMES.COIL}
                            sortAscending={true}
                          // onClickSort={}
                          >
                            {getLanguage('profileTransaction.form.profileTransferSummary.table.header.coilName', '')}
                          </Table.HeadColumn>
                          <Table.HeadColumn
                            alignRight
                            maxWidth={VARIABLES.TABLE.INFO_WIDTHS.TRANSFER_AMOUNT_M}
                            minWidth={VARIABLES.TABLE.INFO_WIDTHS.TRANSFER_AMOUNT_M}
                            sortAscending={true}
                          // onClickSort={}
                          >
                            {getLanguage('profileTransaction.form.profileTransferSummary.table.header.quantity', '')}
                          </Table.HeadColumn>
                          <Table.HeadColumn
                            alignRight
                            maxWidth={VARIABLES.TABLE.INFO_WIDTHS.TRANSFER_AMOUNT_M}
                            minWidth={VARIABLES.TABLE.INFO_WIDTHS.TRANSFER_AMOUNT_M}
                            sortAscending={true}
                          // onClickSort={}
                          >
                            {getLanguage('profileTransaction.form.profileTransferSummary.table.header.length', '')}
                          </Table.HeadColumn>
                          <Table.HeadColumn
                            alignRight
                            maxWidth={VARIABLES.TABLE.INFO_WIDTHS.WEIGHT_TON}
                            minWidth={VARIABLES.TABLE.INFO_WIDTHS.WEIGHT_TON}
                            sortAscending={true}
                          // onClickSort={}
                          >
                            {getLanguage('profileTransaction.form.profileTransferSummary.table.header.weight', '')}
                          </Table.HeadColumn>
                        </Table.Row>
                      </Table.Section>
                      <Table.Section>
                        {
                          orderList.map((e, i) => {
                            const nWeigth = Math.round(e.orderValue * e.kgPerM)
                            return (
                              <Table.Row>
                                <Table.BodyColumn
                                  info
                                  maxWidth={VARIABLES.TABLE.INFO_WIDTHS.COIL_ID}
                                  minWidth={VARIABLES.TABLE.INFO_WIDTHS.COIL_ID}
                                  title={e.lotNumber}
                                >
                                  {e.lotNumber}
                                </Table.BodyColumn>
                                <Table.BodyColumn
                                  info
                                  minWidth={VARIABLES.TABLE.COLUMN_WIDTHS.NAMES.COIL}
                                  title={e.itemDescription}
                                >
                                  {e.itemDescription}
                                </Table.BodyColumn>
                                <Table.BodyColumn
                                  info
                                  alignRight
                                  maxWidth={VARIABLES.TABLE.INFO_WIDTHS.TRANSFER_AMOUNT_M}
                                  minWidth={VARIABLES.TABLE.INFO_WIDTHS.TRANSFER_AMOUNT_M}
                                  title={e.fTotalRemainingprofile + ' ' + e.unit}
                                >
                                  {`${e.quantity} ${e.unit}`}
                                </Table.BodyColumn>
                                <Table.BodyColumn
                                  info
                                  alignRight
                                  maxWidth={VARIABLES.TABLE.INFO_WIDTHS.TRANSFER_AMOUNT_M}
                                  minWidth={VARIABLES.TABLE.INFO_WIDTHS.TRANSFER_AMOUNT_M}
                                  title={e.orderValue}
                                >
                                  {e.orderValue}
                                </Table.BodyColumn>
                                <Table.BodyColumn
                                  info
                                  alignRight
                                  maxWidth={VARIABLES.TABLE.INFO_WIDTHS.WEIGHT_TON}
                                  minWidth={VARIABLES.TABLE.INFO_WIDTHS.WEIGHT_TON}
                                  title={nWeigth}
                                >
                                  {nWeigth}
                                </Table.BodyColumn>
                              </Table.Row>
                            )
                          })
                        }
                      </Table.Section>
                    </Table>
                    {/* } */}
                    {/* {Ad &&
                      <Table striped>
                        <Table.Section>
                          <Table.Row>
                            <Table.HeadColumn
                              maxWidth={VARIABLES.TABLE.INFO_WIDTHS.COIL_ID}
                              minWidth={VARIABLES.TABLE.INFO_WIDTHS.COIL_ID}
                              sortAscending={true}
                            // onClickSort={}
                            >
                              Coil ID
                            </Table.HeadColumn>
                            <Table.HeadColumn
                              minWidth={VARIABLES.TABLE.COLUMN_WIDTHS.NAMES.COIL}
                              sortAscending={true}
                            // onClickSort={}
                            >
                              Coil Name
                            </Table.HeadColumn>
                            <Table.HeadColumn
                              minWidth={VARIABLES.TABLE.COLUMN_WIDTHS.NAMES.PROFILE_TYPE}
                              sortAscending={true}
                            // onClickSort={}
                            >
                              Profile Type
                            </Table.HeadColumn>
                            <Table.HeadColumn
                              alignRight
                              maxWidth={VARIABLES.TABLE.COLUMN_WIDTHS.MEASUREMENTS.QUANTITY_UNIT}
                              minWidth={VARIABLES.TABLE.COLUMN_WIDTHS.MEASUREMENTS.QUANTITY_UNIT}
                              sortAscending={true}
                            // onClickSort={}
                            >
                              Quantity / Unit
                            </Table.HeadColumn>
                            <Table.HeadColumn
                              alignRight
                              maxWidth={VARIABLES.TABLE.COLUMN_WIDTHS.MEASUREMENTS.LENGTH_UNIT}
                              minWidth={VARIABLES.TABLE.COLUMN_WIDTHS.MEASUREMENTS.LENGTH_UNIT}
                              sortAscending={true}
                            // onClickSort={}
                            >
                              Length / Unit
                            </Table.HeadColumn>
                          </Table.Row>
                        </Table.Section>
                        <Table.Section>
                          <Table.Row>
                            <Table.BodyColumn
                              info
                              maxWidth={VARIABLES.TABLE.INFO_WIDTHS.COIL_ID}
                              minWidth={VARIABLES.TABLE.INFO_WIDTHS.COIL_ID}
                              title='MM2Z255756'
                            >
                              MM2Z255756
                            </Table.BodyColumn>
                            <Table.BodyColumn
                              info
                              minWidth={VARIABLES.TABLE.COLUMN_WIDTHS.NAMES.COIL}
                              title='0.35TCT x 914mm BLUESCOPE ZACS 150-A G550 Steel'
                            >
                              0.35TCT x 914mm BLUESCOPE ZACS 150-A G550 Steel
                            </Table.BodyColumn>
                            <Table.BodyColumn
                              info
                              minWidth={VARIABLES.TABLE.COLUMN_WIDTHS.NAMES.PROFILE_TYPE}
                              title='Truss C75-75'
                            >
                              Truss C75-75
                            </Table.BodyColumn>
                            <Table.BodyColumn
                              info
                              alignRight
                              maxWidth={VARIABLES.TABLE.COLUMN_WIDTHS.MEASUREMENTS.QUANTITY_UNIT}
                              minWidth={VARIABLES.TABLE.COLUMN_WIDTHS.MEASUREMENTS.QUANTITY_UNIT}
                              title='50'
                            >
                              50
                            </Table.BodyColumn>
                            <Table.BodyColumn
                              info
                              alignRight
                              maxWidth={VARIABLES.TABLE.COLUMN_WIDTHS.MEASUREMENTS.LENGTH_UNIT}
                              minWidth={VARIABLES.TABLE.COLUMN_WIDTHS.MEASUREMENTS.LENGTH_UNIT}
                              title='25'
                            >
                              25
                            </Table.BodyColumn>
                          </Table.Row>
                          <Table.Row>
                            <Table.BodyColumn
                              info
                              maxWidth={VARIABLES.TABLE.INFO_WIDTHS.COIL_ID}
                              minWidth={VARIABLES.TABLE.INFO_WIDTHS.COIL_ID}
                              title='MM1Z224312'
                            >
                              MM1Z224312
                            </Table.BodyColumn>
                            <Table.BodyColumn
                              info
                              minWidth={VARIABLES.TABLE.COLUMN_WIDTHS.NAMES.COIL}
                              title='0.35TCT x 914mm BLUESCOPE ZACS 150-A G550 Steel'
                            >
                              0.35TCT x 914mm BLUESCOPE ZACS 150-A G550 Steel
                            </Table.BodyColumn>
                            <Table.BodyColumn
                              info
                              minWidth={VARIABLES.TABLE.COLUMN_WIDTHS.NAMES.PROFILE_TYPE}
                              title='Long Span 0.25 TCT'
                            >
                              Long Span 0.25 TCT
                            </Table.BodyColumn>
                            <Table.BodyColumn
                              info
                              alignRight
                              maxWidth={VARIABLES.TABLE.COLUMN_WIDTHS.MEASUREMENTS.QUANTITY_UNIT}
                              minWidth={VARIABLES.TABLE.COLUMN_WIDTHS.MEASUREMENTS.QUANTITY_UNIT}
                              title='50'
                            >
                              50
                            </Table.BodyColumn>
                            <Table.BodyColumn
                              info
                              alignRight
                              maxWidth={VARIABLES.TABLE.COLUMN_WIDTHS.MEASUREMENTS.LENGTH_UNIT}
                              minWidth={VARIABLES.TABLE.COLUMN_WIDTHS.MEASUREMENTS.LENGTH_UNIT}
                              title='25'
                            >
                              25
                            </Table.BodyColumn>
                          </Table.Row>
                        </Table.Section>
                      </Table>
                    } */}

                    {isIndoToko && false &&
                      <Table striped>
                        <Table.Section>
                          <Table.Row>
                            <Table.HeadColumn
                              minWidth={VARIABLES.TABLE.COLUMN_WIDTHS.NAMES.PROFILE_TYPE}
                              sortAscending={true}
                            // onClickSort={}
                            >
                              Profile Type
                            </Table.HeadColumn>
                            <Table.HeadColumn
                              alignRight
                              maxWidth={VARIABLES.TABLE.COLUMN_WIDTHS.MEASUREMENTS.QUANTITY_UNIT}
                              minWidth={VARIABLES.TABLE.COLUMN_WIDTHS.MEASUREMENTS.QUANTITY_UNIT}
                              sortAscending={true}
                            // onClickSort={}
                            >
                              Quantity / Unit
                            </Table.HeadColumn>
                            <Table.HeadColumn
                              alignRight
                              maxWidth={VARIABLES.TABLE.COLUMN_WIDTHS.MEASUREMENTS.LENGTH_UNIT}
                              minWidth={VARIABLES.TABLE.COLUMN_WIDTHS.MEASUREMENTS.LENGTH_UNIT}
                              sortAscending={true}
                            // onClickSort={}
                            >
                              Length / Unit
                            </Table.HeadColumn>
                          </Table.Row>
                        </Table.Section>
                        <Table.Section>
                          <Table.Row>
                            <Table.BodyColumn
                              info
                              minWidth={VARIABLES.TABLE.COLUMN_WIDTHS.NAMES.PROFILE_TYPE}
                            >
                              PROFILE_TYPE
                            </Table.BodyColumn>
                            <Table.BodyColumn
                              info
                              alignRight
                              maxWidth={VARIABLES.TABLE.COLUMN_WIDTHS.MEASUREMENTS.QUANTITY_UNIT}
                              minWidth={VARIABLES.TABLE.COLUMN_WIDTHS.MEASUREMENTS.QUANTITY_UNIT}
                            >
                              QUANTITY
                            </Table.BodyColumn>
                            <Table.BodyColumn
                              info
                              alignRight
                              maxWidth={VARIABLES.TABLE.COLUMN_WIDTHS.MEASUREMENTS.LENGTH_UNIT}
                              minWidth={VARIABLES.TABLE.COLUMN_WIDTHS.MEASUREMENTS.LENGTH_UNIT}
                            >
                              LENGTH
                            </Table.BodyColumn>
                          </Table.Row>
                        </Table.Section>
                      </Table>
                    }
                  </React.Fragment>
              }
            </Section>
            <Section>
              <Grid
                gutter='15'
                justify='flex-end'
              >
                <Grid.Column>
                  <Button
                    gray
                    ui='back'
                    name={getLanguage('field.buttonBack', '')}
                    onClick={() => {
                      redirect(ROUTE_PATH.PROFILE_TRANSFER.LINK)
                    }}
                  />
                </Grid.Column>
                {
                  orderList.length > 0 &&
                  <Grid.Column>
                    <Button
                      blue
                      name={getLanguage('field.buttonSaveAndTransfer', '')}
                      onClick={() => {
                        this.saveData()
                      }}
                    />
                  </Grid.Column>
                }
              </Grid>
            </Section>
          </ShapeContainer>
        </Section>
        {
          isOpenModalAuthorisedDealerAndRollFormerProfile &&
          // <ModalAuthorisedDealerAndRollFormerProfileContainerConnected
          //   isOpenModal={isOpenModalAuthorisedDealerAndRollFormerProfile}
          //   disabledButtonSave={isDisabledButtonSave}
          //   onClickClose={this.modal.onCloseModalAuthorisedDealerAndRollFormerProfile}
          //   onClickSave={this.modal.onCloseModalAuthorisedDealerAndRollFormerProfile}
          // />
          <ModalAuthorisedDealerAndRollFormerProfileContainerConnected
            isProfile
            // adRfData={adRfData}
            isOpenModal={isOpenModalAuthorisedDealerAndRollFormerProfile}
            disabledButtonSave={isDisabledButtonSave}
            onClickClose={this.modal.onCloseModalAuthorisedDealerAndRollFormerProfile}
          />
        }
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => {
  const dataRedux = state.dataRedux;
  return {
    dataDetail: dataRedux.selectAD,
    stAdDetail: dataRedux.adDetail,
    orderList: dataRedux.transferOrderList,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    dataActions: bindActionCreators(dataCreators, dispatch),
  }
}

export const ProfileTransactionScreenConnected = connect(
  mapStateToProps,
  mapDispatchToProps
)(ProfileTransactionScreen)