import styled, {
  // css
} from 'styled-components'
import {
  default as VARIABLES
} from './../../../themes/styles/bases/variables'
// import {
//   default as TYPOGRAPHYS
// } from './../../../themes/styles/bases/typographys'
// import {
//   default as MIXINS
// } from './../../../themes/styles/helpers/mixins'

// Sample using image in style
// url(${require('./../../../themes/images/icons/icon-sample.svg')});

// Wrapper
// ============================================================
export const NewsPromotionsListWrapper = styled.div`
  /* Parent
  ------------------------------- */
  /* Childrens
  ------------------------------- */
  .news-promotions-list-label {
    position: absolute;
    z-index: ${VARIABLES.Z_INDEXS.LV_1};
    right: 10px;
    bottom: 10px;

    &.is-news {}

    &.is-promotions {}
  }

  .news-promotions-list-title {
    overflow: hidden;
  }

  .news-promotions-list-title-ellipsis {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  /* Modifiers
  ------------------------------- */
  &.is-cursor {
    cursor: pointer;
  }

  /* Media queries
  ------------------------------- */
  .news-promotions-list-label {
    &.is-news {
      @media (max-width: ${VARIABLES.BREAKPOINTS.BP_1440}) {
        .image-base {
          width: 60px;
          padding-bottom: 36px;
        }
      }
    }

    &.is-promotions {
      @media (max-width: ${VARIABLES.BREAKPOINTS.BP_1440}) {
        .image-base {
          width: 100px;
          padding-bottom: 43px;
        }
      }
    }
  }
`
