import React from 'react';
import {
  Table,
  Field,
} from './../../components'
import { VARIABLES } from '../../themes';
import { storeDispatch } from '../../stores';
import { dataCreators, DATA_REDUX_STATE_KEY } from '../../actions/dataAction';
import { handleSortData } from '../../helpers/functions/data';
import _ from 'lodash';
import { getLanguage } from '../../helpers/functions/language';
import { getSortAscending } from '../../helpers/functions/data';

export class CustomerSelectTableContainer extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      // sortIcon: {
      //   id: true,
      //   firstname: true,
      //   phone: true,
      //   email: true,
      //   remainPoints: true,
      // }
    }
  }

  customerList = {
    // updateData: (dataList) => {
    //   storeDispatch(dataCreators.setDataRedux(DATA_REDUX_STATE_KEY.CUSTOMER_LIST, dataList));
    // },
    // handleClearIcon: () => {
    //   const {
    //     sortIcon,
    //   } = this.state;
    //   let newSortIcon = {};
    //   Object.keys(sortIcon).forEach(e => {
    //     newSortIcon[e] = true;
    //   });
    //   this.setState({
    //     sortIcon: newSortIcon
    //   })
    // },
    // handleSortIcon: (type) => {
    //   const {
    //     sortIcon,
    //   } = this.state;
    //   let newSortIcon = {};
    //   let oldType = type;
    //   Object.keys(sortIcon).forEach(e => {
    //     if (sortIcon[e] === false) {
    //       oldType = e;
    //     }
    //     newSortIcon[e] = true;
    //   });
    //   Object.keys(sortIcon).forEach(e => {
    //     if (e === type) {
    //       if (type === oldType) {
    //         newSortIcon[e] = !sortIcon[e];
    //       }
    //     }
    //   })
    //   this.setState({
    //     sortIcon: newSortIcon
    //   })
    //   return newSortIcon
    // },
    onClickSort: (type) => {
      const {
        onClickSort,
      } = this.props;
      // const {
      //   dataList,
      // } = this.props;
      // const newSortIcon = this.customerList.handleSortIcon(type);
      // const tempDataList = handleSortData(dataList, {
      //   sortTarget: type,
      //   orderType: newSortIcon[type] ? 'desc' : 'asc'
      // });
      // this.customerList.updateData(tempDataList);
      onClickSort(type);
    },
    onClickSelect: (data) => {
      const {
        dataList,
        onClickCustomer,
      } = this.props;
      dataList.forEach(e => {
        if (e.id === data.id) {
          onClickCustomer(e);
        }
      })
    },
    // getFilterDataPaginationList: () => {
    //   const {
    //     activePage,
    //     dataPerPage,
    //     dataList,
    //   } = this.props;
    // const firstIndex = (activePage - 1) * dataPerPage;
    // const lastIndex = activePage * dataPerPage;
    // if (activePage && dataPerPage) {
    //   return this.customerList.getFilterDataList().filter((e, i) => i >= firstIndex && i < lastIndex);
    // }
    //   return dataList;
    // },
    // getFilterDataList: () => {
    //   const {
    //     dataList,
    //     filterValue,
    //   } = this.props;
    //   return dataList.filter((e, i) => {
    //     if (filterValue.length) {
    //       if (e.idWithPrefix.toLowerCase().indexOf(filterValue.toLowerCase()) === -1 &&
    //         e.fullname.toLowerCase().indexOf(filterValue.toLowerCase()) === -1 &&
    //         e.phone.toLowerCase().indexOf(filterValue.toLowerCase()) === -1 &&
    //         e.email.toLowerCase().indexOf(filterValue.toLowerCase()) === -1
    //       ) {
    //         return false;
    //       }
    //     }
    //     return true;
    //   })
    // }
  }

  render() {
    const {
      customerData,
      dataParams,
      dataList,
      isCheckboxTick,
    } = this.props;
    const {
      sortIcon,
    } = this.state;
    // const dataList = this.customerList.getFilterDataPaginationList();
    return (
      <Table
        striped
        actionCheckbox
      >
        <Table.Section>
          <Table.Row>
            <Table.HeadColumn
              maxWidth={VARIABLES.TABLE.COLUMN_WIDTHS.IDS.INDEX}
              minWidth={VARIABLES.TABLE.COLUMN_WIDTHS.IDS.INDEX}
              sortAscending={getSortAscending(dataParams, 'id')}
              // sortAscending={sortIcon.id}
              onClickSort={() => {
                this.customerList.onClickSort('id desc');
              }}
            >
              {getLanguage('modal.customerProfile.table.header.id', '')}
            </Table.HeadColumn>
            <Table.HeadColumn
              minWidth={VARIABLES.TABLE.COLUMN_WIDTHS.NAMES.FULL}
              sortAscending={getSortAscending(dataParams, 'first_name')}
              // sortAscending={sortIcon.firstname}
              onClickSort={() => {
                // this.customerList.onClickSort('firstname');
                this.customerList.onClickSort('first_name desc');
              }}
            >
              {getLanguage('modal.customerProfile.table.header.name', '')}
            </Table.HeadColumn>
            <Table.HeadColumn
              minWidth={VARIABLES.TABLE.COLUMN_WIDTHS.PHONE}
              sortAscending={getSortAscending(dataParams, 'phone')}
              // sortAscending={sortIcon.phone}
              onClickSort={() => {
                this.customerList.onClickSort('phone desc');
              }}
            >
              {getLanguage('modal.customerProfile.table.header.phoneNo', '')}
            </Table.HeadColumn>
            <Table.HeadColumn
              minWidth={VARIABLES.TABLE.COLUMN_WIDTHS.EMAIL}
              sortAscending={getSortAscending(dataParams, 'email')}
              // sortAscending={sortIcon.email}
              onClickSort={() => {
                this.customerList.onClickSort('email desc');
              }}
            >
              {getLanguage('modal.customerProfile.table.header.email', '')}
            </Table.HeadColumn>
            <Table.HeadColumn
              alignRight
              minWidth={VARIABLES.TABLE.COLUMN_WIDTHS.POINT}
              sortAscending={getSortAscending(dataParams, 'remain_point')}
              // sortAscending={sortIcon.remainPoints}
              onClickSort={() => {
                // this.customerList.onClickSort('remainPoints');
                this.customerList.onClickSort('remain_point desc');
              }}
            >
              {getLanguage('modal.customerProfile.table.header.points', '')}
            </Table.HeadColumn>
            <Table.HeadColumn
              alignCenter
              noSort
              maxWidth={VARIABLES.TABLE.COLUMN_WIDTHS.TRAIN}
              minWidth={VARIABLES.TABLE.COLUMN_WIDTHS.TRAIN}
            >
              {getLanguage('modal.customerProfile.table.header.select', '')}
            </Table.HeadColumn>
          </Table.Row>
        </Table.Section>
        <Table.SectionControl>
          {
            dataList.map((e, i) => {
              if (customerData && customerData.id === e.id) {
                e.isSelected = true;
              }
              else {
                e.isSelected = false;
              }
              return (
                <Table.Row
                  pointer
                  key={i}
                  onClick={() => {
                    this.customerList.onClickSelect(e);
                  }}
                >
                  <Table.BodyColumn
                    selected={e.isSelected} // Not use to row because when x scroll active > row bg color have not display
                    info
                    maxWidth={VARIABLES.TABLE.COLUMN_WIDTHS.IDS.INDEX}
                    minWidth={VARIABLES.TABLE.COLUMN_WIDTHS.IDS.INDEX}
                    title={e.idWithPrefix}
                  >
                    {e.idWithPrefix}
                  </Table.BodyColumn>
                  <Table.BodyColumn
                    selected={e.isSelected}
                    info
                    minWidth={VARIABLES.TABLE.COLUMN_WIDTHS.NAMES.FULL}
                    title={e.displayFullname}
                  >
                    {e.displayFullname}
                  </Table.BodyColumn>
                  <Table.BodyColumn
                    selected={e.isSelected}
                    phone
                    minWidth={VARIABLES.TABLE.COLUMN_WIDTHS.PHONE}
                    idPhone={e.phoneCountry.value}
                    srcFlag={e.phoneCountry.flag}
                  >
                    {e.phone}
                  </Table.BodyColumn>
                  <Table.BodyColumn
                    selected={e.isSelected}
                    link
                    minWidth={VARIABLES.TABLE.COLUMN_WIDTHS.EMAIL}
                    title={e.email}
                  >
                    {e.email}
                  </Table.BodyColumn>
                  <Table.BodyColumn
                    selected={e.isSelected}
                    info
                    alignRight
                    minWidth={VARIABLES.TABLE.COLUMN_WIDTHS.POINT}
                    title={e.remainPointsWithComma}
                  >
                    {e.remainPointsWithComma}
                  </Table.BodyColumn>
                  <Table.BodyColumn
                    selected={e.isSelected}
                    action
                    alignCenter
                    maxWidth={VARIABLES.TABLE.COLUMN_WIDTHS.TRAIN}
                    minWidth={VARIABLES.TABLE.COLUMN_WIDTHS.TRAIN}
                  >
                    <Field
                      // checked={e.isTrained}
                      checked={e.isSelected}
                      ui={isCheckboxTick ? 'checkboxTick' : 'checkboxTickFillBlank'}
                      onChange={() => { }}
                    />
                  </Table.BodyColumn>
                </Table.Row>
              )
            })
          }
        </Table.SectionControl>
      </Table>
    )
  }
}