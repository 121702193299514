import { isValidResponse } from "../functions/validation";
import { ModelCMSContactUs } from "../../models/ModelCMSContactUs";
import { ENUM } from "../constants/enum";
import { cmsContactUsController } from "../../apiService/apiController/cmsContactUsService";
import { handleTrimParams } from "../functions/data";
import { getUserAuthRedux } from "../functions/auth";

export const getContactUsByAppType = async (params = {}) => {
  const userAuth = getUserAuthRedux();
  let newParams = handleTrimParams({
    app: ENUM.CMS_TYPE.TRUE_BLUE,
    status: ENUM.STATUS_ACTIVE.ACTIVE,
    available_country: userAuth.addressType,
  });
  const contactUsService = cmsContactUsController();
  const res = await contactUsService.getContactUsByAppType(newParams);
  if (isValidResponse(res)) {
    const dataList = res.data.map(e => new ModelCMSContactUs(e));
    console.log('getContactUsByAppType', res.data, dataList, newParams);
    if (params.callbackSuccess) {
      params.callbackSuccess(dataList[0] ? dataList[0] : new ModelCMSContactUs())
    }
  }
}