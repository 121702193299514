import apiService from '../apiService'

export const transferController = (configService = {}) => {
  const service = apiService(configService)
  return {
    detailAdRf: (params) => {
      return service.get(`/transfer/ad_rf/${params.id}`, params)
    },
    searchAdRf: (params) => {
      return service.post(`/transfer/ad_rf/list`, params)
    },
    createAD: (params) => {
      return service.post(`/transfer/ad_rf`, params)
    },
    searchTransferHis: (params) => {
      return service.post(`/transfer/history`, params)
    },
    searchTransferInv: (params) => {
      return service.post(`/transfer/inventory`, params)
    },
    createTransfer: (params) => {
      return service.post(`/transfer/`, params)
    },
    transferCheckCoil: () => {
      return service.get(`/transfer/check-coil`)
    },
    transferConfirm: (params) => {
      return service.post(`/transfer/confirm`, params)
    },
    transferSearchHistory: (params) => {
      return service.post(`/transfer/search/history`, params)
    },
    transferSearchApprove: (params) => {
      return service.post(`/transfer/search/approve`, params)
    },
    transferApprove: (params) => {
      return service.put(`/transfer/approve`, params)
    },
    transferClear: (params) => {
      return service.put(`/transfer/clear`, params)
    },
  }
}