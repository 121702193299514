import ClassNames from 'classnames'
import React from 'react'
import {
  Accordion,
  AccordionItem,
  AccordionItemBody,
  AccordionItemTitle
} from 'react-accessible-accordion'
import { DATA } from '../../helpers/constants/data'
import { ENUM } from '../../helpers/constants/enum'
import { LOCAL_STORAGE } from '../../helpers/constants/localStorage'
import { getLanguage } from '../../helpers/functions/language'
import { getLocalStorage } from '../../helpers/functions/localStorage'
import { isShowLoading } from '../../helpers/functions/main'
import { getDefaultEnvironmentDescription } from '../../helpers/functions/warranty'
import { getWarrantyEnvironmentDetailList } from '../../helpers/services/warranty'
import {
  AccordionControl,
  FieldRadioPanel,
  Section,
  ShapeContainer,
  Text
} from './../../components'
import {
  VARIABLES
} from './../../themes'

export class WarrantyEnvDetailContainer extends React.Component {

  constructor(props) {
    super(props);
    const language = getLocalStorage(LOCAL_STORAGE.LANGUAGE_RBPTRUEBLUE);
    this.state = {
      envDescriptionList: [],
      language
    }
  }

  componentDidMount() {
    this.warrantyManageDetail.getEnvDescriptionList();
  }

  warrantyManageDetail = {
    getApplicationTitle: (e, et) => {
      const {
        data,
      } = this.props;
      switch (et.applicationForSelect) {
        case DATA.WARRANTY_APP_DETAIL_NAME_SELECT_LIST[0]:
          if (e.isRoofAndWall && data.projectCountry !== ENUM.COUNTRY_TYPE.INDONESIA) {
            return getLanguage('warrantyManage.form.condition.application.roofAndWall', '');
          }
          return getLanguage('warrantyManage.form.condition.application.roofing', '');
        case DATA.WARRANTY_APP_DETAIL_NAME_SELECT_LIST[1]:
          return getLanguage('warrantyManage.form.condition.application.walling', '');
        case DATA.WARRANTY_APP_DETAIL_NAME_SELECT_LIST[2]:
          return getLanguage('warrantyManage.form.condition.application.purlinAndGirt', '');
        case DATA.WARRANTY_APP_DETAIL_NAME_SELECT_LIST[3]:
          return getLanguage('warrantyManage.form.condition.application.trussAndFrame', '');
        case DATA.WARRANTY_APP_DETAIL_NAME_SELECT_LIST[4]:
          return getLanguage('warrantyManage.form.condition.application.coolRoomPanel', '');
        case DATA.WARRANTY_APP_DETAIL_NAME_SELECT_LIST[5]:
          return getLanguage('warrantyManage.form.condition.application.decking', '');
        case DATA.WARRANTY_APP_DETAIL_NAME_SELECT_LIST[6]:
          return getLanguage('warrantyManage.form.condition.application.airDucting', '');
      }
    },
    getEnvDescriptionList: () => {
      const {
        data,
      } = this.props;
      if (data.isManageEnv) {
        let cnt = 0;
        const envDescriptionList = [];
        isShowLoading(true);
        data.productGroupList.forEach(async (e) => {
          const params = {
            // productGroupName: e.productGroupName,
            // CR: 1498
            productGroupName: e.oldProductGroupName,
          }
          const configService = {
            isShowLoading: false,
          }
          const dataList = await getWarrantyEnvironmentDetailList(params, configService);
          envDescriptionList.push({
            productGroupName: e.productGroupName,
            dataList
          });
          cnt++;
          if (cnt === data.productGroupList.length) {
            console.log('envDescriptionList', envDescriptionList)
            this.setState({
              envDescriptionList
            }, () => {
              isShowLoading(false);
            });
          }
        });
      }
    },
    getEnvComponent: (e, i, et, it, etn, itn) => {
      const {
        onChangeEnvDataInput = () => { },
        data,
      } = this.props;
      const {
        envDescriptionList,
        language,
      } = this.state;
      let label = '';
      if (data.isManageEnv && envDescriptionList.length) {
        const envDescriptionData = envDescriptionList.filter(envDesc => (envDesc.productGroupName || '').toLowerCase() === (e.productGroupName || '').toLowerCase())[0];
        console.log('envDescriptionData', envDescriptionData)
        const envDesc = envDescriptionData.dataList.filter(envDesc => envDesc.envName === etn.environment)[0] || {};
        console.log('envDesc', envDesc, etn)
        const isLanguageTH = language === DATA.LANGUAGE.THAI;
        const envDescription = isLanguageTH ? envDesc.envDescriptionTH : envDesc.envDescription;
        label = <Section>
          <Text bold16 color={VARIABLES.COLORS.BLACK}>{etn.environment}</Text>
          <Section spacingBottom='2'>
            <span className='text-base is-regular-12' dangerouslySetInnerHTML={{ __html: envDescription }} />
          </Section>
        </Section>
      }
      else {
        switch (etn.environment) {
          case DATA.WARRANTY_ENV_DETAIL_NAME_LIST[0]:
            label = data.projectCountry === ENUM.COUNTRY_TYPE.VIETNAM ?
              <Section>
                <Text bold16 color={VARIABLES.COLORS.BLACK}>{getLanguage('field.environment.vn.marineEnvironment.verySevereMarine', '')}</Text>
                <Section spacingBottom='2'>
                  <Text regular12 color={VARIABLES.COLORS.BLACK}>
                    {getLanguage('field.environment.vn.marineEnvironment.verySevereMarineDescription1', '')}<br />
                    {getLanguage('field.environment.vn.marineEnvironment.verySevereMarineDescription2', '')}<br />
                    <Text bold12 italic color={VARIABLES.COLORS.PRIMARY_2}>{getLanguage('field.environment.vn.marineEnvironment.verySevereMarineDescription3', '')}</Text><br />
                    - {getLanguage('field.environment.vn.marineEnvironment.verySevereMarineDescription4', '')}<br />
                    - {getLanguage('field.environment.vn.marineEnvironment.verySevereMarineDescription5', '')}
                  </Text>
                </Section>
              </Section>
              :
              data.projectCountry === ENUM.COUNTRY_TYPE.INDONESIA ?
                <Section>
                  <Text bold16 color={VARIABLES.COLORS.BLACK}>{getLanguage('field.environment.in.marineEnvironment.verySevereMarine', '')}</Text>
                  <Section spacingBottom='2'>
                    <Text regular12 color={VARIABLES.COLORS.BLACK}>
                      {getLanguage('field.environment.in.marineEnvironment.verySevereMarineDescription1', '')}&nbsp;
                      <Text bold12 underline color={VARIABLES.COLORS.PRIMARY_2}>{getLanguage('field.environment.in.marineEnvironment.verySevereMarineDescription2', '')}</Text>&nbsp;
                      {getLanguage('field.environment.in.marineEnvironment.verySevereMarineDescription3', '')}
                    </Text>
                  </Section>
                </Section>
                :
                getDefaultEnvironmentDescription(etn.environment)
            break;
          case DATA.WARRANTY_ENV_DETAIL_NAME_LIST[1]:
            label = data.projectCountry === ENUM.COUNTRY_TYPE.VIETNAM ?
              <Section>
                <Text bold16 color={VARIABLES.COLORS.BLACK}>{getLanguage('field.environment.vn.marineEnvironment.severeMarine', '')}</Text>
                <Section spacingBottom='2'>
                  <Text regular12 color={VARIABLES.COLORS.BLACK}>
                    {getLanguage('field.environment.vn.marineEnvironment.severeMarineDescription1', '')}&nbsp;
                    <Text bold12 underline color={VARIABLES.COLORS.PRIMARY_2}>{getLanguage('field.environment.vn.marineEnvironment.severeMarineDescription2', '')}</Text>
                    &nbsp;{getLanguage('field.environment.vn.marineEnvironment.severeMarineDescription3', '')}&nbsp;
                    <Text bold12 underline color={VARIABLES.COLORS.PRIMARY_2}>{getLanguage('field.environment.vn.marineEnvironment.severeMarineDescription4', '')}</Text>&nbsp;
                    &nbsp;{getLanguage('field.environment.vn.marineEnvironment.severeMarineDescription5', '')}<br />
                    {getLanguage('field.environment.vn.marineEnvironment.severeMarineDescription6', '')}<br />
                    <Text bold12 italic color={VARIABLES.COLORS.PRIMARY_2}>{getLanguage('field.environment.vn.marineEnvironment.severeMarineDescription7', '')}</Text>
                  </Text>
                </Section>
              </Section>
              :
              data.projectCountry === ENUM.COUNTRY_TYPE.INDONESIA ?
                <Section>
                  <Text bold16 color={VARIABLES.COLORS.BLACK}>{getLanguage('field.environment.in.marineEnvironment.severeMarine', '')}</Text>
                  <Section spacingBottom='2'>
                    <Text regular12 color={VARIABLES.COLORS.BLACK}>
                      {getLanguage('field.environment.in.marineEnvironment.severeMarineDescription1', '')}&nbsp;
                      <Text bold12 underline color={VARIABLES.COLORS.PRIMARY_2}>{getLanguage('field.environment.in.marineEnvironment.severeMarineDescription2', '')}</Text>
                      &nbsp;{getLanguage('field.environment.in.marineEnvironment.severeMarineDescription3', '')}&nbsp;
                      <Text bold12 underline color={VARIABLES.COLORS.PRIMARY_2}>{getLanguage('field.environment.in.marineEnvironment.severeMarineDescription4', '')}</Text>
                      &nbsp;{getLanguage('field.environment.in.marineEnvironment.severeMarineDescription5', '')}
                      &nbsp;{getLanguage('field.environment.in.marineEnvironment.severeMarineDescription6', '')}
                    </Text>
                  </Section>
                </Section>
                :
                getDefaultEnvironmentDescription(etn.environment)
            break;
          case DATA.WARRANTY_ENV_DETAIL_NAME_LIST[2]:
            label = data.projectCountry === ENUM.COUNTRY_TYPE.VIETNAM ?
              <Section>
                <Text bold16 color={VARIABLES.COLORS.BLACK}>{getLanguage('field.environment.vn.marineEnvironment.marine', '')}</Text>
                <Section spacingBottom='2'>
                  <Text regular12 color={VARIABLES.COLORS.BLACK}>
                    {getLanguage('field.environment.vn.marineEnvironment.marineDescription1', '')}&nbsp;
                    <Text bold12 underline color={VARIABLES.COLORS.PRIMARY_2}>{getLanguage('field.environment.vn.marineEnvironment.marineDescription2', '')}</Text>
                    &nbsp;{getLanguage('field.environment.vn.marineEnvironment.marineDescription3', '')}&nbsp;
                    <Text bold12 underline color={VARIABLES.COLORS.PRIMARY_2}>{getLanguage('field.environment.vn.marineEnvironment.marineDescription4', '')}</Text><br />
                    {getLanguage('field.environment.vn.marineEnvironment.marineDescription5', '')}
                  </Text>
                </Section>
              </Section>
              :
              data.projectCountry === ENUM.COUNTRY_TYPE.INDONESIA ?
                <Section>
                  <Text bold16 color={VARIABLES.COLORS.BLACK}>
                    {getLanguage('field.environment.in.marineEnvironment.marine', '')}
                  </Text>
                  <Section spacingBottom='2'>
                    <Text regular12 color={VARIABLES.COLORS.BLACK}>
                      {getLanguage('field.environment.in.marineEnvironment.marineDescription1', '')}&nbsp;
                      <Text bold12 underline color={VARIABLES.COLORS.PRIMARY_2}>{getLanguage('field.environment.in.marineEnvironment.marineDescription2', '')}</Text>
                      &nbsp;{getLanguage('field.environment.in.marineEnvironment.marineDescription3', '')}&nbsp;
                      <Text bold12 underline color={VARIABLES.COLORS.PRIMARY_2}>{getLanguage('field.environment.in.marineEnvironment.marineDescription4', '')}</Text>
                      &nbsp;{getLanguage('field.environment.in.marineEnvironment.marineDescription5', '')}
                      &nbsp;{getLanguage('field.environment.in.marineEnvironment.marineDescription6', '')}
                    </Text>
                  </Section>
                </Section>
                :
                getDefaultEnvironmentDescription(etn.environment)
            break;
          case DATA.WARRANTY_ENV_DETAIL_NAME_LIST[3]:
            label = data.projectCountry === ENUM.COUNTRY_TYPE.VIETNAM ?
              <Section>
                <Text bold16 color={VARIABLES.COLORS.BLACK}>{getLanguage('field.environment.vn.marineEnvironment.moderateMarine', '')}</Text>
                <Section spacingBottom='2'>
                  <Text regular12 color={VARIABLES.COLORS.BLACK}>
                    {getLanguage('field.environment.vn.marineEnvironment.moderateMarineDescription1', '')}&nbsp;
                    <Text bold12 underline color={VARIABLES.COLORS.PRIMARY_2}>{getLanguage('field.environment.vn.marineEnvironment.moderateMarineDescription2', '')}</Text>
                    &nbsp;{getLanguage('field.environment.vn.marineEnvironment.moderateMarineDescription3', '')}<br />
                    {getLanguage('field.environment.vn.marineEnvironment.moderateMarineDescription4', '')}
                  </Text>
                </Section>
              </Section>
              :
              data.projectCountry === ENUM.COUNTRY_TYPE.INDONESIA ?
                <Section>
                  <Text bold16 color={VARIABLES.COLORS.BLACK}>
                    {getLanguage('field.environment.in.marineEnvironment.moderateMarine', '')}
                  </Text>
                  <Section spacingBottom='2'>
                    <Text regular12 color={VARIABLES.COLORS.BLACK}>
                      {getLanguage('field.environment.in.marineEnvironment.moderateMarineDescription1', '')}&nbsp;
                      <Text bold12 underline color={VARIABLES.COLORS.PRIMARY_2}>{getLanguage('field.environment.in.marineEnvironment.moderateMarineDescription2', '')}</Text>
                      &nbsp;{getLanguage('field.environment.in.marineEnvironment.moderateMarineDescription3', '')}
                      &nbsp;{getLanguage('field.environment.in.marineEnvironment.moderateMarineDescription4', '')}
                    </Text>
                  </Section>
                </Section>
                :
                getDefaultEnvironmentDescription(etn.environment)
            break;
          case DATA.WARRANTY_ENV_DETAIL_NAME_LIST[4]:
            label = data.projectCountry === ENUM.COUNTRY_TYPE.VIETNAM ?
              <Section>
                <Text bold16 color={VARIABLES.COLORS.BLACK}>{getLanguage('field.environment.vn.standard.benignRural', '')}</Text>
                <Section spacingBottom='2'>
                  <Text regular12 color={VARIABLES.COLORS.BLACK}>
                    {getLanguage('field.environment.vn.standard.benignRuralDescription1', '')}&nbsp;
                    <Text bold12 underline color={VARIABLES.COLORS.PRIMARY_2}>{getLanguage('field.environment.vn.standard.benignRuralDescription2', '')}</Text>
                    &nbsp;{getLanguage('field.environment.vn.standard.benignRuralDescription3', '')}&nbsp;
                    <Text bold12 underline color={VARIABLES.COLORS.PRIMARY_2}>{getLanguage('field.environment.vn.standard.benignRuralDescription4', '')}</Text>&nbsp;
                    &nbsp;{getLanguage('field.environment.vn.standard.benignRuralDescription5', '')}<br />
                    {getLanguage('field.environment.vn.standard.benignRuralDescription6', '')}
                  </Text>
                </Section>
              </Section>
              :
              data.projectCountry === ENUM.COUNTRY_TYPE.INDONESIA ?
                <Section>
                  <Text bold16 color={VARIABLES.COLORS.BLACK}>
                    {getLanguage('field.environment.in.standard.benignRural', '')}
                  </Text>
                  <Section spacingBottom='2'>
                    <Text regular12 color={VARIABLES.COLORS.BLACK}>
                      {getLanguage('field.environment.in.standard.benignRuralDescription1', '')}&nbsp;
                      <Text bold12 underline color={VARIABLES.COLORS.PRIMARY_2}>{getLanguage('field.environment.in.standard.benignRuralDescription2', '')}</Text>
                      &nbsp;{getLanguage('field.environment.in.standard.benignRuralDescription3', '')}&nbsp;
                      <Text bold12 underline color={VARIABLES.COLORS.PRIMARY_2}>{getLanguage('field.environment.in.standard.benignRuralDescription4', '')}</Text>
                      &nbsp;{getLanguage('field.environment.in.standard.benignRuralDescription5', '')}&nbsp;
                      <Text bold12 underline color={VARIABLES.COLORS.PRIMARY_2}>{getLanguage('field.environment.in.standard.benignRuralDescription6', '')}</Text>
                      &nbsp;{getLanguage('field.environment.in.standard.benignRuralDescription7', '')}
                    </Text>
                  </Section>
                </Section>
                :
                getDefaultEnvironmentDescription(etn.environment)
            break;
          case DATA.WARRANTY_ENV_DETAIL_NAME_LIST[5]:
            label = data.projectCountry === ENUM.COUNTRY_TYPE.VIETNAM ?
              <Section>
                <Text bold16 color={VARIABLES.COLORS.BLACK}>{getLanguage('field.environment.vn.industrialEnvironment.severeIndustrial', '')}</Text>
                <Section spacingBottom='2'>
                  <Text regular12 color={VARIABLES.COLORS.BLACK}>
                    {getLanguage('field.environment.vn.industrialEnvironment.severeIndustrialDescription1', '')}&nbsp;
                    <Text bold12 underline color={VARIABLES.COLORS.PRIMARY_2}>{getLanguage('field.environment.vn.industrialEnvironment.severeIndustrialDescription2', '')}</Text>
                    &nbsp;{getLanguage('field.environment.vn.industrialEnvironment.severeIndustrialDescription3', '')}&nbsp;
                    <Text bold12 underline color={VARIABLES.COLORS.PRIMARY_2}>{getLanguage('field.environment.vn.industrialEnvironment.severeIndustrialDescription4', '')}</Text><br />
                    {getLanguage('field.environment.vn.industrialEnvironment.severeIndustrialDescription5', '')}<br />
                    {getLanguage('field.environment.vn.industrialEnvironment.severeIndustrialDescription6', '')}
                  </Text>
                </Section>
              </Section>
              :
              data.projectCountry === ENUM.COUNTRY_TYPE.INDONESIA ?
                <Section>
                  <Text bold16 color={VARIABLES.COLORS.BLACK}>
                    {getLanguage('field.environment.in.industrialEnvironment.severeIndustrial', '')}
                  </Text>
                  <Section spacingBottom='2'>
                    <Text regular12 color={VARIABLES.COLORS.BLACK}>
                      {getLanguage('field.environment.in.industrialEnvironment.severeIndustrialDescription1', '')}&nbsp;
                      <Text bold12 underline color={VARIABLES.COLORS.PRIMARY_2}>{getLanguage('field.environment.in.industrialEnvironment.severeIndustrialDescription2', '')}</Text>
                      &nbsp;{getLanguage('field.environment.in.industrialEnvironment.severeIndustrialDescription3', '')}&nbsp;
                      <Text bold12 underline color={VARIABLES.COLORS.PRIMARY_2}>{getLanguage('field.environment.in.industrialEnvironment.severeIndustrialDescription4', '')}</Text>
                      &nbsp;{getLanguage('field.environment.in.industrialEnvironment.severeIndustrialDescription5', '')}
                      &nbsp;{getLanguage('field.environment.in.industrialEnvironment.severeIndustrialDescription6', '')}
                    </Text>
                  </Section>
                </Section>
                :
                getDefaultEnvironmentDescription(etn.environment)
            break;
          case DATA.WARRANTY_ENV_DETAIL_NAME_LIST[6]:
            label = data.projectCountry === ENUM.COUNTRY_TYPE.VIETNAM ?
              <Section>
                <Text bold16 color={VARIABLES.COLORS.BLACK}>{getLanguage('field.environment.vn.industrialEnvironment.heavyIndustrial', '')}</Text>
                <Section spacingBottom='2'>
                  <Text regular12 color={VARIABLES.COLORS.BLACK}>
                    {getLanguage('field.environment.vn.industrialEnvironment.heavyIndustrialDescription1', '')}&nbsp;
                    <Text bold12 underline color={VARIABLES.COLORS.PRIMARY_2}>{getLanguage('field.environment.vn.industrialEnvironment.heavyIndustrialDescription2', '')}</Text><br />
                    {getLanguage('field.environment.vn.industrialEnvironment.heavyIndustrialDescription3', '')}<br />
                    {getLanguage('field.environment.vn.industrialEnvironment.heavyIndustrialDescription4', '')}
                  </Text>
                </Section>
              </Section>
              :
              data.projectCountry === ENUM.COUNTRY_TYPE.INDONESIA ?
                <Section>
                  <Text bold16 color={VARIABLES.COLORS.BLACK}>
                    {getLanguage('field.environment.in.industrialEnvironment.heavyIndustrial', '')}
                  </Text>
                  <Section spacingBottom='2'>
                    <Text regular12 color={VARIABLES.COLORS.BLACK}>
                      {getLanguage('field.environment.in.industrialEnvironment.heavyIndustrialDescription1', '')}&nbsp;
                      <Text bold12 underline color={VARIABLES.COLORS.PRIMARY_2}>{getLanguage('field.environment.in.industrialEnvironment.heavyIndustrialDescription2', '')}</Text>
                      &nbsp;{getLanguage('field.environment.in.industrialEnvironment.heavyIndustrialDescription3', '')}
                      &nbsp;{getLanguage('field.environment.in.industrialEnvironment.heavyIndustrialDescription4', '')}
                    </Text>
                  </Section>
                </Section>
                :
                getDefaultEnvironmentDescription(etn.environment)
            break;
          case DATA.WARRANTY_ENV_DETAIL_NAME_LIST[7]:
            label = data.projectCountry === ENUM.COUNTRY_TYPE.VIETNAM ?
              <Section>
                <Text bold16 color={VARIABLES.COLORS.BLACK}>{getLanguage('field.environment.vn.industrialEnvironment.industrial', '')}</Text>
                <Section spacingBottom='2'>
                  <Text regular12 color={VARIABLES.COLORS.BLACK}>
                    {getLanguage('field.environment.vn.industrialEnvironment.industrialDescription1', '')}&nbsp;
                    <Text bold12 underline color={VARIABLES.COLORS.PRIMARY_2}>{getLanguage('field.environment.vn.industrialEnvironment.industrialDescription2', '')}</Text><br />
                    {getLanguage('field.environment.vn.industrialEnvironment.industrialDescription3', '')}<br />
                    <Text bold12 color={VARIABLES.COLORS.PRIMARY_2}>{getLanguage('field.environment.vn.industrialEnvironment.industrialDescription4', '')}</Text>
                  </Text>
                </Section>
              </Section>
              :
              data.projectCountry === ENUM.COUNTRY_TYPE.INDONESIA ?
                <Section>
                  <Text bold16 color={VARIABLES.COLORS.BLACK}>
                    {getLanguage('field.environment.in.industrialEnvironment.industrial', '')}
                  </Text>
                  <Section spacingBottom='2'>
                    <Text regular12 color={VARIABLES.COLORS.BLACK}>
                      {getLanguage('field.environment.in.industrialEnvironment.industrialDescription1', '')}&nbsp;
                      <Text bold12 underline color={VARIABLES.COLORS.PRIMARY_2}>{getLanguage('field.environment.in.industrialEnvironment.industrialDescription2', '')}</Text>
                      &nbsp;{getLanguage('field.environment.in.industrialEnvironment.industrialDescription3', '')}
                    </Text>
                  </Section>
                </Section>
                :
                getDefaultEnvironmentDescription(etn.environment)
            break;
          case DATA.WARRANTY_ENV_DETAIL_NAME_LIST[8]:
            label = data.projectCountry === ENUM.COUNTRY_TYPE.VIETNAM ?
              <Section>
                <Text bold16 color={VARIABLES.COLORS.BLACK}>{getLanguage('field.environment.vn.industrialEnvironment.lightIndUrban', '')}</Text>
                <Section spacingBottom='2'>
                  <Text regular12 color={VARIABLES.COLORS.BLACK}>
                    {getLanguage('field.environment.vn.industrialEnvironment.lightIndUrbanDescription1', '')}&nbsp;
                    <Text bold12 underline color={VARIABLES.COLORS.PRIMARY_2}>{getLanguage('field.environment.vn.industrialEnvironment.lightIndUrbanDescription2', '')}</Text><br />
                    {getLanguage('field.environment.vn.industrialEnvironment.lightIndUrbanDescription3', '')}<br />
                    <Text bold12 color={VARIABLES.COLORS.PRIMARY_2}>{getLanguage('field.environment.vn.industrialEnvironment.lightIndUrbanDescription4', '')}</Text>
                  </Text>
                </Section>
              </Section>
              :
              data.projectCountry === ENUM.COUNTRY_TYPE.INDONESIA ?
                <Section>
                  <Text bold16 color={VARIABLES.COLORS.BLACK}>
                    {getLanguage('field.environment.in.industrialEnvironment.lightIndAndUrban', '')}
                  </Text>
                  <Section spacingBottom='2'>
                    <Text regular12 color={VARIABLES.COLORS.BLACK}>
                      {getLanguage('field.environment.in.industrialEnvironment.lightIndAndUrbanDescription1', '')}&nbsp;
                      <Text bold12 underline color={VARIABLES.COLORS.PRIMARY_2}>{getLanguage('field.environment.in.industrialEnvironment.lightIndAndUrbanDescription2', '')}</Text>
                      &nbsp;{getLanguage('field.environment.in.industrialEnvironment.lightIndAndUrbanDescription3', '')}
                    </Text>
                  </Section>
                </Section>
                :
                getDefaultEnvironmentDescription(etn.environment)
            break;
          case DATA.WARRANTY_ENV_DETAIL_NAME_LIST[9]:
            label = data.projectCountry === ENUM.COUNTRY_TYPE.VIETNAM ?
              <Section>
                <Text bold16 color={VARIABLES.COLORS.BLACK}>
                  {getLanguage('field.environment.trueCoreApplication', '')}
                </Text>
              </Section>
              :
              getDefaultEnvironmentDescription(etn.environment)
            break;
          default: break;
        }
      }
      return (
        <Section spacingBottom='15' key={itn}>
          <FieldRadioPanel
            isChecked={et.detailEnvSelect === itn}
            label={label}
            onChange={() => {
              onChangeEnvDataInput({
                target: {
                  name: 'detailEnvSelect',
                  value: itn
                }
              }, i, it);
            }}
          />
        </Section>
      )
    }
  };

  render() {
    const {
      data,
      isTemplate,
    } = this.props;

    return (
      <React.Fragment>
        <AccordionControl outline>
          <Accordion accordion>
            {data.productGroupList.map((e, i) => {
              return (
                <AccordionItem
                  expanded={isTemplate}
                  key={i}
                  uuid={i}
                  className={ClassNames(
                    'accordion__item',
                    { 'is-error': e.isErrorSelectEnvDetail },
                  )}
                >
                  <AccordionItemTitle>
                    <AccordionControl.ButtonOutline
                      name={e.productGroupName}
                    />
                  </AccordionItemTitle>
                  <AccordionItemBody>
                    <ShapeContainer
                      fluid
                      paddingTop='30'
                      paddingHorizontal='30'
                      bgColor={VARIABLES.COLORS.GRAY_1}
                      borderRadius='5'
                    >
                      {e.templateList.map((et, it) => {
                        if (e[et.applicationForSelect]) {
                          if (
                            et.applicationForSelect === DATA.WARRANTY_APP_DETAIL_NAME_SELECT_LIST[1] &&
                            e.isRoofAndWall && data.projectCountry !== ENUM.COUNTRY_TYPE.INDONESIA
                          ) {
                            return undefined;
                          }
                          return (
                            <div ref={isTemplate ? `envDetailRef_${e.productGroupName}_${et.application}` : undefined} key={it}>
                              <Section spacingBottom='20'>
                                <Section spacingBottom='10'>
                                  <Text regular16 color={VARIABLES.COLORS.PRIMARY_2}>
                                    {this.warrantyManageDetail.getApplicationTitle(e, et)}
                                  </Text>
                                </Section>
                                <Section width='55' borderBottomWidth='3' borderBottomStyle='solid' borderBottomColor={VARIABLES.COLORS.PRIMARY_2} />
                              </Section>
                              <ShapeContainer
                                fluid
                                paddingTop='15'
                                paddingHorizontal='15'
                                spacingBottom='30'
                                borderWidth='1'
                                borderStyle='solid'
                                borderColor={VARIABLES.COLORS.BLUE_15}
                                borderRadius='5'
                              >
                                {et.envList.map((etn, itn) => {
                                  return this.warrantyManageDetail.getEnvComponent(e, i, et, it, etn, itn);
                                })}
                              </ShapeContainer>
                            </div>
                          )
                        }
                        return undefined;
                      })}
                    </ShapeContainer>
                  </AccordionItemBody>
                </AccordionItem>
              )
            })}
          </Accordion>
        </AccordionControl>
      </React.Fragment>
    )
  }
}
