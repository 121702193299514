import React from 'react';
import {
  connect,
} from 'react-redux';
import {
  Table,
} from './../../components'
import { VARIABLES } from '../../themes';
import { getPointList } from '../../helpers/services/point';
import { handleSortData } from '../../helpers/functions/data';
import { DATA_REDUX_STATE_KEY, dataCreators } from '../../actions/dataAction';
import { bindActionCreators } from 'redux';
import _ from 'lodash';
import { getLanguage } from '../../helpers/functions/language';

class PurchaseHistoryListContainer extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      sortIcon: {
        createAt: true,
        name: true,
        coilName: true,
        coilId: true,
        orderM: true,
        points: true,
        salesPersonName: true,
      }
    }
  }

  componentDidMount() {
    const {
      data,
    } = this.props
    const {
      activePage,
      dataPerPage,
      onLoadFinish
    } = this.props;
    const params = {
      page: activePage,
      limit: dataPerPage,
      // id: data.id,
      customer_id: data.id,
      callbackSuccess: onLoadFinish
    };
    getPointList(params);
  }

  purchaseHistoryList = {
    updateData: (dataList) => {
      const {
        dataActions,
      } = this.props;
      dataActions.setDataRedux(DATA_REDUX_STATE_KEY.POINT_LIST, dataList);
    },
    handleClearIcon: () => {
      const {
        sortIcon,
      } = this.state;
      let newSortIcon = {};
      Object.keys(sortIcon).forEach(e => {
        newSortIcon[e] = true;
      });
      this.setState({
        sortIcon: newSortIcon
      })
    },
    handleSortIcon: (type) => {
      const {
        sortIcon,
      } = this.state;
      let newSortIcon = {};
      let oldType = type;
      Object.keys(sortIcon).forEach(e => {
        if (sortIcon[e] === false) {
          oldType = e;
        }
        newSortIcon[e] = true;
      });
      Object.keys(sortIcon).forEach(e => {
        if (e === type) {
          if (type === oldType) {
            newSortIcon[e] = !sortIcon[e];
          }
        }
      })
      this.setState({
        sortIcon: newSortIcon
      })
      return newSortIcon
    },
    onClickSort: (type) => {
      const {
        dataList,
      } = this.props;
      const newSortIcon = this.purchaseHistoryList.handleSortIcon(type);
      const tempDataList = handleSortData(dataList, {
        sortTarget: type,
        sortTargetType: type === 'createAt' ? 'date' : undefined,
        orderType: newSortIcon[type] ? 'desc' : 'asc'
      });
      console.log('tempDataList', tempDataList)
      this.purchaseHistoryList.updateData(tempDataList);
    },
    getFilterDataList: () => {
      const {
        dataList,
        activePage,
        dataPerPage,
      } = this.props;
      const firstIndex = (activePage - 1) * dataPerPage;
      const lastIndex = activePage * dataPerPage;
      console.log('dataList', dataList, activePage, dataPerPage)
      return dataList.filter((e, i) => i >= firstIndex && i < lastIndex);
    }
  }

  render() {
    const {
      sortIcon,
    } = this.state;
    return (
      <Table striped>
        <Table.Section>
          <Table.Row>
            <Table.HeadColumn
              maxWidth={VARIABLES.TABLE.INFO_WIDTHS.DATE_CREATE}
              minWidth={VARIABLES.TABLE.INFO_WIDTHS.DATE_CREATE}
              sortAscending={sortIcon.createAt}
              onClickSort={() => {
                this.purchaseHistoryList.onClickSort('createAt');
              }}
            >
              {getLanguage('container.purchaseHistoryList.table.header.salesDate', '')}
            </Table.HeadColumn>
            <Table.HeadColumn
              maxWidth={VARIABLES.TABLE.INFO_WIDTHS.T1_SALES_TRANSACTION}
              minWidth={VARIABLES.TABLE.INFO_WIDTHS.T1_SALES_TRANSACTION}
              sortAscending={sortIcon.name}
              onClickSort={() => {
                this.purchaseHistoryList.onClickSort('name');
              }}
            >
              {getLanguage('container.purchaseHistoryList.table.header.salesTransaction', '')}
            </Table.HeadColumn>
            <Table.HeadColumn
              minWidth={VARIABLES.TABLE.INFO_WIDTHS.COIL_DETAIL}
              sortAscending={sortIcon.coilName}
              onClickSort={() => {
                this.purchaseHistoryList.onClickSort('coilName');
              }}
            >
              {getLanguage('container.purchaseHistoryList.table.header.coilDetail', '')}
            </Table.HeadColumn>
            <Table.HeadColumn
              maxWidth={VARIABLES.TABLE.INFO_WIDTHS.COIL_NUMBER}
              minWidth={VARIABLES.TABLE.INFO_WIDTHS.COIL_NUMBER}
              sortAscending={sortIcon.coilId}
              onClickSort={() => {
                this.purchaseHistoryList.onClickSort('coilId');
              }}
            >
              {getLanguage('container.purchaseHistoryList.table.header.coilNumber', '')}
            </Table.HeadColumn>
            <Table.HeadColumn
              alignRight
              maxWidth={VARIABLES.TABLE.INFO_WIDTHS.PURCHASE_LENGTH_M}
              minWidth={VARIABLES.TABLE.INFO_WIDTHS.PURCHASE_LENGTH_M}
              sortAscending={sortIcon.orderM}
              onClickSort={() => {
                this.purchaseHistoryList.onClickSort('orderM');
              }}
            >
              {getLanguage('container.purchaseHistoryList.table.header.purchaseLength', '')}
            </Table.HeadColumn>
            <Table.HeadColumn
              alignRight
              maxWidth={VARIABLES.TABLE.INFO_WIDTHS.TRUEBLUE_POINTS}
              minWidth={VARIABLES.TABLE.INFO_WIDTHS.TRUEBLUE_POINTS}
              sortAscending={sortIcon.points}
              onClickSort={() => {
                this.purchaseHistoryList.onClickSort('points');
              }}
            >
              {getLanguage('container.purchaseHistoryList.table.header.tbPoints', '')}
            </Table.HeadColumn>
            <Table.HeadColumn
              maxWidth={VARIABLES.TABLE.INFO_WIDTHS.SALES_REPRESENTATIVE}
              minWidth={VARIABLES.TABLE.INFO_WIDTHS.SALES_REPRESENTATIVE}
              sortAscending={sortIcon.salesPersonName}
              onClickSort={() => {
                this.purchaseHistoryList.onClickSort('salesPersonName');
              }}
            >
              {getLanguage('container.purchaseHistoryList.table.header.salesRepresentative', '')}
            </Table.HeadColumn>
          </Table.Row>
        </Table.Section>
        <Table.Section>
          {
            this.purchaseHistoryList.getFilterDataList().map((e, i) => {
              return (
                <Table.Row
                  key={i}
                >
                  <Table.BodyColumn
                    info
                    maxWidth={VARIABLES.TABLE.INFO_WIDTHS.DATE_CREATE}
                    minWidth={VARIABLES.TABLE.INFO_WIDTHS.DATE_CREATE}
                  >
                    {e.displayCreateAt}
                  </Table.BodyColumn>
                  <Table.BodyColumn
                    info
                    maxWidth={VARIABLES.TABLE.INFO_WIDTHS.T1_SALES_TRANSACTION}
                    minWidth={VARIABLES.TABLE.INFO_WIDTHS.T1_SALES_TRANSACTION}
                    title={e.name}
                  >
                    {e.name}
                  </Table.BodyColumn>
                  <Table.BodyColumn
                    info
                    minWidth={VARIABLES.TABLE.INFO_WIDTHS.COIL_DETAIL}
                    title={e.coilName}
                  >
                    {e.coilName}
                  </Table.BodyColumn>
                  <Table.BodyColumn
                    info
                    maxWidth={VARIABLES.TABLE.INFO_WIDTHS.COIL_NUMBER}
                    minWidth={VARIABLES.TABLE.INFO_WIDTHS.COIL_NUMBER}
                    title={e.coilId}
                  >
                    {e.coilId}
                  </Table.BodyColumn>
                  <Table.BodyColumn
                    info
                    alignRight
                    maxWidth={VARIABLES.TABLE.INFO_WIDTHS.PURCHASE_LENGTH_M}
                    minWidth={VARIABLES.TABLE.INFO_WIDTHS.PURCHASE_LENGTH_M}
                    title={e.orderMWithComma}
                  >
                    {e.orderMWithComma}
                  </Table.BodyColumn>
                  <Table.BodyColumn
                    info
                    alignRight
                    maxWidth={VARIABLES.TABLE.INFO_WIDTHS.TRUEBLUE_POINTS}
                    minWidth={VARIABLES.TABLE.INFO_WIDTHS.TRUEBLUE_POINTS}
                    title={e.pointsOriginalWithComma}
                  >
                    {e.pointsOriginalWithComma}
                  </Table.BodyColumn>
                  <Table.BodyColumn
                    info
                    maxWidth={VARIABLES.TABLE.INFO_WIDTHS.SALES_REPRESENTATIVE}
                    minWidth={VARIABLES.TABLE.INFO_WIDTHS.SALES_REPRESENTATIVE}
                    title={e.salesPersonName}
                  >
                    {e.salesPersonName}
                  </Table.BodyColumn>
                </Table.Row>
              )
            })
          } </Table.Section>
      </Table>
    )
  }
}

const mapStateToProps = (state) => {
  const dataRedux = state.dataRedux;
  return {
    dataList: dataRedux.pointList,
    // activePage: dataRedux.pointParams.activePage,
    // totalData: dataRedux.pointParams.totalData,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    dataActions: bindActionCreators(dataCreators, dispatch),
  }
}

export const PurchaseHistoryListContainerConnected = connect(
  mapStateToProps,
  mapDispatchToProps
)(PurchaseHistoryListContainer)