import React from "react";
// import {
//   connect,
// } from 'react-redux';
import {
  Section,
  Grid,
  Modal,
  Text,
  Button,
  Image,
  ShapeContainer,
} from "../../../components";
import { VARIABLES, ICONS } from "../../../themes";
import { ENUM } from "../../../helpers/constants/enum";
import { getLanguage } from "../../../helpers/functions/language";
// import { MODAL_TYPE } from '../../../helpers/constants/main'
// import { MAIN_REDUX_STATE_KEY } from '../../../actions/mainAction'
import _ from "lodash";
import { DATA } from "../../../helpers/constants/data";

export class ModalSelectReferenceTypeContainer extends React.Component {
  render() {
    const {
      isOpenModal,
      onClickClose,
      onClickSubmit = () => { }
    } = this.props;
    return (
      <Modal
        open={isOpenModal}
        scroll
        // enableOverlayClose={false}
        width={VARIABLES.MODAL.WIDTHS.W_526}
        overlayColor={VARIABLES.COLORS.OVERLAY_BLACK_1}
        spacing={VARIABLES.MODAL.CONTAINER.C_1.SPACING}
        transition={VARIABLES.TRANSITIONS.DEFAULT}
        transformStart={VARIABLES.MODAL.CONTAINER.C_1.TRANSFORM_START}
        transformEnd={VARIABLES.MODAL.CONTAINER.C_1.TRANSFORM_END}
        onClickClose={onClickClose}
      >
        <Modal.Section
          paddingVertical="64"
          paddingHorizontal="35"
          bgColor={VARIABLES.COLORS.WHITE}
          borderRadius={16}
        >
          <Section align="center" textAlign='center' justify='center' >
            <Section spacingBottom='40'>
              <Text
                regular16
                color={VARIABLES.COLORS.BLACK}
              >
                {getLanguage('projectReference.modal.modalSelectReferenceType.label.pleaseSelectReferenceType', '')}
              </Text>
            </Section>
            <Grid gutter="24" justify="center">
              <Grid.Column>
                <Button
                  blue
                  ui='frontIcon'
                  name={getLanguage('projectReference.modal.modalSelectReferenceType.button.buttonWarranty', '')}
                  iconWidth='15'
                  iconHeight='15'
                  iconSrc={ICONS['ic-sales-transaction.svg']}
                  width={VARIABLES.BUTTON.WIDTHS.W_187}
                  onClick={() => { onClickSubmit(DATA.PROJECT_REFERENCE_TYPE.WARRANTY) }}
                />
              </Grid.Column>
              <Grid.Column>
                <Button
                  blue
                  ui='frontIcon'
                  name={getLanguage('projectReference.modal.modalSelectReferenceType.button.buttonProjectDocument', '')}
                  iconWidth='15'
                  iconHeight='15'
                  iconSrc={ICONS['ic-menu-pre-sales-transaction-white.svg']}
                  width={VARIABLES.BUTTON.WIDTHS.W_187}
                  onClick={() => { onClickSubmit(DATA.PROJECT_REFERENCE_TYPE.PRE_SALES_TRANSACTION) }}
                />
              </Grid.Column>
            </Grid>
          </Section>
        </Modal.Section>
      </Modal>
    );
  }
}
