import html2pdf from 'html2pdf.js';
import _ from 'lodash';
import React from 'react';
import { documentaryController } from '../../apiService/apiController/documentaryService';
import { FIREBASE_STORAGE } from '../../config/config';
import { GenerateDocumentaryContainer } from '../../containers/GenerateDocumentaryContainer';
import { DocumentaryTemplate } from '../../containers/GenerateDocumentaryContainer/DocumentaryTemplate';
import { DATA } from '../../helpers/constants/data';
import { ENUM } from '../../helpers/constants/enum';
import { ROUTE_PATH } from '../../helpers/constants/route';
import { firebaseDeleteFile, firebaseGetAllFileList, firebaseGetDownloadPath } from '../../helpers/firebases/firebaseStorage';
import { getBlobtoPath } from '../../helpers/functions/addon';
import { getUserAuthRedux } from '../../helpers/functions/auth';
import { imageUrltoBase64 } from '../../helpers/functions/data';
import { displayUrlPath } from '../../helpers/functions/display';
import { getLanguage } from '../../helpers/functions/language';
import { isShowLoading, toast } from '../../helpers/functions/main';
import { getRouteData, redirect, setSideMenuActive } from '../../helpers/functions/route';
import { isValidResponse } from '../../helpers/functions/validation';
import { handleDocumentaryData } from '../../helpers/functions/warranty';
import {
  Breadcrumb,
  Section,
  Step,
  TitlePage,
  WarrantyGeneratePdfPage
} from './../../components';
import {
  ICONS
} from './../../themes';

export class DocumentaryManageScreen extends React.Component {

  constructor(props) {
    super(props);
    setSideMenuActive(props);
    const routeData = getRouteData(props, 'data') || {};
    const data = {
      ...routeData,
    }
    data.productGroupList = [];
    const productGroupNameList = [];
    data.selectedDataList.forEach(e => {
      let productGroupData = {
        id: e.id,
        wrtId: e.wrtId,
        projectName: e.projectName,
        m3ProductGroup: e.m3ProductGroup,
        documentaryList: [],
        isSelectedSpecifyCompanyName: false,
        specifyCompanyName: '',
        productGroupName: e.productGroupName,
        // CR: 1498
        oldProductGroupName: e.oldProductGroupName,
        coilId: e.coilId,
        coilName: e.coilName,
        productionDate: e.productionDate,
        //template
        templateForm: e.productGroupName
      }
      if (productGroupNameList.indexOf(productGroupData.productGroupName) === -1) {
        productGroupNameList.push(productGroupData.productGroupName)
        data.productGroupList.push(productGroupData);
      }
    })
    data.templateList = _.cloneDeep(data.productGroupList);

    console.log('data', data)

    const currentStepIndex = 0;
    const stepPercentList = ['1', '100'];
    this.state = {
      data,
      stepContentList: [
        getLanguage('documentaryManage.label.information', ''),
        getLanguage('documentaryManage.label.documentary', '')
      ],
      stepPercentList,
      currentStepIndex,
      currentStepPercent: stepPercentList[currentStepIndex],
      isSelectDocumentary: true,
      isShowPreviewMark: true,
      isClickSaveDocumentary: false,
      dataGroupList: [],
      viewerLoadingTime: 5000,
    }
  }

  documentaryManage = {
    goBack: () => {
      const {
        stepPercentList,
        currentStepIndex,
      } = this.state;
      let prevStepIndex = currentStepIndex - 1;
      if (prevStepIndex === -1) {
        redirect(ROUTE_PATH.DOCUMENTARY.LINK);
      }
      else {
        this.setState({
          isSelectDocumentary: prevStepIndex === 0 ? true : false,
          currentStepIndex: prevStepIndex,
          currentStepPercent: stepPercentList[prevStepIndex],
        })
      }
    },
    goBackIndex: (stepIndex) => {
      const {
        stepPercentList,
      } = this.state;
      this.setState({
        isSelectDocumentary: stepIndex === 0 ? true : false,
        currentStepIndex: stepIndex,
        currentStepPercent: stepPercentList[stepIndex],
      })
    },
    nextStep: async () => {
      const {
        currentStepIndex,
        stepPercentList,
        stepContentList,
      } = this.state;
      const nextStepIndex = currentStepIndex + 1;
      if (nextStepIndex === stepContentList.length) {
        const time1 = new Date().getTime();
        await this.documentaryManage.handleManageData();
        const time2 = new Date().getTime();
        console.log((time2 - time1) / 1000);
      }
      else {
        this.setState({
          currentStepIndex: nextStepIndex,
          currentStepPercent: stepPercentList[nextStepIndex],
        });
      }
    },
    handleUpdateProductGroupData: (list) => {
      const {
        data,
      } = this.state;
      data.productGroupList = _.cloneDeep(list);
      this.setState({
        data,
      })
    },
    onClickSave: () => {
      const {
        data,
        currentStepIndex
      } = this.state;
      const handleGenerateDocumentary = async () => {
        isShowLoading(true);
        for (let i = 0; i < data.productGroupList.length; i++) {
          let documentaryList = data.productGroupList[i].documentaryList;
          for (let j = 0; j < documentaryList.length; j++) {
            const documentary = documentaryList[j];
            if (documentary.isSelected) {
              documentary.docMsPathFileObject.path = await imageUrltoBase64(await firebaseGetDownloadPath(documentary.docMsPathFileObject.pathRef))
            }
            documentaryList[j] = documentary;
          }
          data.productGroupList[i].documentaryList = documentaryList;
        }

        data.templateList = data.templateList.map(e => {
          e.isViewerUploading = true;
          e.isViewerLoading = true;
          return e;
        });

        this.setState({
          data,
        }, async () => {
          await this.documentaryManage.generateDocumentary();
          isShowLoading(false);
          this.documentaryManage.nextStep();
        });
      }

      switch (currentStepIndex) {
        case 0:
          handleGenerateDocumentary();
          break;
        case 1:
          this.documentaryManage.nextStep();
          break;
        default: break;
      }
    },
    generateDocumentary: () => {
      const {
        data,
      } = this.state;
      return new Promise(resolve => {
        this.setState({
          isSelectDocumentary: false,
        }, async () => {
          for (let i = 0; i < data.templateList.length; i++) {
            const dataTemplate = data.templateList[i]
            const templateFormName = dataTemplate.templateForm;
            await this.documentaryManage.handleUploadPdf(templateFormName);
          }
          resolve();
        })
      })
    },
    handleUploadPdf: (templateFormName) => {
      return new Promise(async (resolve) => {
        const {
          data,
          viewerLoadingTime,
          isClickSaveDocumentary,
        } = this.state;
        let detailGroupList = _.cloneDeep(data.selectedDataList).filter(e => {
          return e.productGroupName === templateFormName
        })
        detailGroupList = detailGroupList.map(e => {
          const productGroupList = data.productGroupList.filter(ep => ep.templateForm === templateFormName);
          if (productGroupList.length) {
            e.documentaryList = productGroupList[0].documentaryList.filter(ed => ed.isSelected);
            e.isSelectedSpecifyCompanyName = productGroupList[0].isSelectedSpecifyCompanyName;
            e.specifyCompanyName = productGroupList[0].specifyCompanyName;
          }
          else {
            e.documentaryList = [];
          }
          e.templateForm = e.productGroupName;
          return e;
        });
        const dataGroup = {};
        detailGroupList.forEach(e => {
          let dataDetail = dataGroup[e.productGroupName];
          if (!dataDetail) {
            dataDetail = {
              ...e,
              displayCoil: [],
              wrtIdList: []
            };
          }

          dataDetail.displayCoil.push(`${e.coilId} ${e.coilName}`);
          dataDetail.wrtIdList.push(e.wrtId)
          dataGroup[e.productGroupName] = dataDetail;
        });
        const dataGroupList = Object.values(dataGroup);
        // CR: 1482
        if (isClickSaveDocumentary) {
          const configService = {
            isShowLoading: false,
          }
          const documentaryService = documentaryController(configService);
          for (let i = 0; i < dataGroupList.length; i++) {
            const e = dataGroupList[i];
            for (let j = 0; j < (e.documentaryList || []).length; j++) {
              const ed = e.documentaryList[j];
              if (ed.isSelected) {
                const params = {
                  wrt_ids: e.wrtIdList,
                  doc_ms_id: ed.docMsId
                }
                const res = await documentaryService.documentaryGenWrtDocNo(params);
                if (isValidResponse(res)) {
                  ed.wrtDocNo = res.data;
                  e.documentaryList[j] = ed;
                  dataGroupList[i] = e;
                }
              }
            }
          }
        }

        this.setState({
          dataGroupList,
        }, async () => {
          const uploadPDF = async (callback = () => { }) => {
            const dom = this.documentaryTemplateRef;
            let cnt = 0;
            const pdfFile = await html2pdf().set({
              margin: [0, 0],
              html2canvas: { scale: 2.2, },
            }).from(dom).outputPdf('blob');

            const filename = `${templateFormName}_${new Date().getTime()}.pdf`;
            const pathUpload = `${ENUM.UPLOAD_PATH.DOCUMENTARY}/${data.id}`;
            getBlobtoPath(pdfFile, filename, pathUpload).then(path => {
              detailGroupList.forEach((e) => {
                data.templateList = data.templateList.map(ep => {
                  if (ep.templateForm === e.templateForm) {
                    ep.isViewerUploading = false;
                  }
                  return ep;
                });
              });
              this.setState({
                data,
              }, () => {
                setTimeout(() => {
                  detailGroupList.forEach((e) => {
                    data.templateList = data.templateList.map(ep => {
                      if (ep.templateForm === e.templateForm) {
                        ep.isViewerLoading = false;
                      }
                      return ep;
                    });
                  });
                  this.setState({
                    data,
                  });
                }, viewerLoadingTime);
              });
            });
            const filePath = `${FIREBASE_STORAGE}${pathUpload}/${filename}`;
            const fileUrl = displayUrlPath(filePath);
            detailGroupList.forEach((e) => {
              console.log('filePath', filePath, e.id);
              data.templateList = data.templateList.map(et => {
                if (et.templateForm === e.templateForm) {
                  et.oldFileRef = filePath;
                  et.fileUrl = fileUrl;
                }
                return et;
              });
              cnt++;
              if (detailGroupList.length === cnt) {
                this.setState({
                  data,
                }, () => {
                  callback(resolve);
                });
              }
            })
          }

          this.componentTemplateRef.documentaryTemplate.setShowDocumentaryTemplate(false, () => {
            this.componentTemplateRef.documentaryTemplate.setShowDocumentaryTemplate(true, () => {
              this.componentTemplateRef.documentaryTemplate.waitingDocumentaryLoadFinish(() => {
                uploadPDF(callbackResolve => {
                  callbackResolve();
                });
              });
            });
          });
        });
      });
    },
    deleteAllDocumentary: async () => {
      const {
        data,
      } = this.state;
      const pathUpload = `${ENUM.UPLOAD_PATH.DOCUMENTARY}/${data.id}`;
      const pathList = await firebaseGetAllFileList(pathUpload);
      console.log('handleDeletePdf', pathList);
      for (let i = 0; i < pathList.length; i++) {
        const path = pathList[i];
        firebaseDeleteFile(path);
      }
    },
    handleManageData: () => {
      isShowLoading(true);
      this.setState({
        isShowPreviewMark: false,
        isClickSaveDocumentary: true,
      }, async () => {
        await this.documentaryManage.deleteAllDocumentary();

        const getManageParams = () => {
          const {
            data,
          } = this.state;
          const userAuth = getUserAuthRedux();
          const params = {
            documentary: data.selectedDataList.map((e, i) => {
              const productGroupData = data.productGroupList.filter(ep => ep.productGroupName === e.productGroupName)[0];
              const templateData = data.templateList.filter(et => et.productGroupName === e.productGroupName)[0];
              return {
                wrt_id: e.wrtId,
                sales_tran_id: e.salesTranId,
                doc_ms_id: productGroupData.documentaryList.filter(e => e.isSelected).map(e => e.id),
                doc_sp_comp_name: productGroupData.isSelectedSpecifyCompanyName ? productGroupData.specifyCompanyName : '',
                doc_path: templateData.oldFileRef
              }
            }),
            created_by: userAuth.name
          }
          return params
        }
        const configService = {
          isShowLoading: false
        }
        let params = getManageParams();
        const documentaryService = documentaryController(configService);
        let res = await documentaryService.documentaryUpdateDraft(params);
        if (isValidResponse(res)) {
          await this.documentaryManage.generateDocumentary();

          params = getManageParams();
          res = await documentaryService.createDocumentary(params);
          isShowLoading(false);
          if (isValidResponse(res)) {
            toast.success(getLanguage('message.updateDocumentaryCompleted', ''));
            redirect(ROUTE_PATH.DOCUMENTARY.LINK);
          }
        }
        else {
          isShowLoading(false);
        }
      });
    },
    getTemplateFormName: (e) => {
      return e.templateForm
    },
    getFullCountry: (data) => {
      switch (data) {
        case ENUM.COUNTRY_TYPE.INDONESIA:
          return ENUM.COUNTRY.INDONESIA;
        case ENUM.COUNTRY_TYPE.MALAYSIA:
          return ENUM.COUNTRY.MALAYSIA;
        default:
          return data || '';
      }
    },
    getCustomerAddress: () => {
      const {
        data,
      } = this.state;
      const customerAddress = `${data.projectAddr || ''}
      ${data.projectAlley || ''}
      ${data.projectGroup || ''}
      ${data.projectStreet || ''}
      ${data.projectWard || ''}
      ${(data.projectSubDist || {}).label || ''}
      ${data.projectDist || ''}
      ${data.selectedCity ? (data.selectedCity.value === '-' ? '' : data.selectedCity.label) : ''}
      ${data.selectedProvince ? `${data.selectedProvince.label},` : ''}
      ${data.projectPostCode ? `${(data.projectPostCode || {}).label},` : ''}
      ${this.documentaryManage.getFullCountry(data.projectCountry)}`;

      return customerAddress;
    }
  }

  render() {
    const {
      data,
      currentStepIndex,
      currentStepPercent,
      stepContentList,
      isSelectDocumentary,
      dataGroupList,
      isShowPreviewMark,
    } = this.state
    console.log('data', data)
    return (
      <React.Fragment>
        <Breadcrumb>
          <Breadcrumb.List
            link
            name={getLanguage('documentaryManage.breadcrumb1', '')}
            onClick={() => {
              redirect(ROUTE_PATH.HOME.LINK)
            }}
          />
          <Breadcrumb.List name='/' />
          <Breadcrumb.List
            link
            name={getLanguage('documentaryManage.breadcrumb2', '')}
            onClick={() => {
              redirect(ROUTE_PATH.DOCUMENTARY.LINK)
            }}
          />
          <Breadcrumb.List name='/' />
          <Breadcrumb.List
            name={getLanguage('documentaryManage.breadcrumb4', '')}
          />
        </Breadcrumb>
        <Section ui='content'>
          <TitlePage
            src={ICONS['ic-page-documents.svg']}
            name={getLanguage('documentaryManage.titleGenerate', '')}
          />
          <Step label
            progressPercent={currentStepPercent}
          >
            {stepContentList.map((e, i) => {
              return (
                <Step.Number
                  key={i}
                  disabled={false}
                  active={currentStepIndex === i}
                  success={currentStepIndex > i}
                  number={i + 1}
                  label={e}
                />
              )
            })}
          </Step>
          <GenerateDocumentaryContainer
            isSelectDocumentary={isSelectDocumentary}
            data={data}
            onClickEdit={() => { this.documentaryManage.goBackIndex(0); }}
            onClickBack={this.documentaryManage.goBack}
            handleUpdateProductGroupData={this.documentaryManage.handleUpdateProductGroupData}
            onClickSave={this.documentaryManage.onClickSave}
            getTemplateFormName={this.documentaryManage.getTemplateFormName}
          />
        </Section>

        <div
          style={{
            position: 'absolute',
            left: '-9999px'
          }}
        >
          <div ref={r => this.documentaryTemplateRef = r}>
            <WarrantyGeneratePdfPage isHidden={false}>
              <DocumentaryTemplate
                isShowPreviewMark={isShowPreviewMark}
                ref={r => this.componentTemplateRef = r}
                data={{
                  projectName: (dataGroupList[0] || {}).projectName || ''
                }}
                dataDetail={handleDocumentaryData(
                  dataGroupList[0] || {},
                  dataGroupList,
                  DATA.WATERMARK_TEMPLATE_LIST[0].value,
                  !isShowPreviewMark
                )}
                customerAddress={this.documentaryManage.getCustomerAddress()}
                watermarkTemplateType={DATA.WATERMARK_TEMPLATE_LIST[0].value}
                // CR: 1498
                productGroupName={(dataGroupList[0] || {}).oldProductGroupName}
              />
            </WarrantyGeneratePdfPage>
          </div>
        </div>
      </React.Fragment>
    )
  }
}
