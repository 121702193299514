import { displayWithComma, displayWithPrefix, displayDate, displayDateTime } from "../helpers/functions/display";
import { getPhoneRemovePrefix, getCountryPhone } from "../helpers/functions/data";
import { DATE_FORMAT } from "../helpers/constants/data";
import { ENUM } from "../helpers/constants/enum";

export class ModelRedemption {
  constructor(props = {}) {
    this.id = props.id;
    this.idWithPrefix = displayWithPrefix(props.id, 6);
    this.rewardId = props.reward_id;
    this.points = props.points;
    this.pointsWithComma = displayWithComma(props.points);
    this.totalPointsWithComma = displayWithComma(props.points * props.unit);
    this.salesPersonId = props.sales_person_id;
    this.salesPersonName = props.sales_person_name;
    this.customerId = props.customer_id;
    this.customerIdWithPrefix = displayWithPrefix(props.customer_id);
    this.customerName = props.customer_name;
    this.createAt = props.create_at;
    this.displayCreateAt = displayDateTime(props.create_at);
    this.pickupAt = props.pickup_at;
    this.displayPickupAt = displayDateTime(props.pickup_at, '/', DATE_FORMAT);
    this.rewardName = props.reward_name;
    this.phoneNo = getPhoneRemovePrefix(props.phone_no);
    this.phoneNoCountry = getCountryPhone(props.phone_no);
    this.stockType = props.stock_type__c;
    this.isCanEdit = this.stockType !== ENUM.REWARD_STOCK_TYPE.IN_STOCK;
    this.unit = props.unit;
    this.unitWithComma = displayWithComma(this.unit);
  }
}