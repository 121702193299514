import { dataCreators, DATA_REDUX_STATE_KEY } from "../../actions/dataAction";
import { projectDocumentController } from "../../apiService/apiController/projectDocumentService";
import { ModelWarrantyDocumentary } from "../../models/ModelDocumentary";
import { ModelProjectDocument, ModelProjectDocumentCertificate, ModelProjectDocumentCertificateSearch, ModelProjectDocumentDocumentary, ModelProjectDocumentDocumentaryDetail, ModelProjectDocumentGetAll, ModelProjectDocumentInformation, ModelProjectDocumentLog, ModelProjectDocumentM3Product, ModelProjectDocumentProjectName, ModelProjectDocumentSearchLog, ModelProjectDocumentSearchWarranty } from "../../models/ModelProjectDocument";
import { storeDispatch, storeGetState } from "../../stores";
import { ENUM } from "../constants/enum";
import { getUserAuthRedux } from "../functions/auth";
import { handleSortParams, handleTrimParams } from "../functions/data";
import { isValidResponse } from "../functions/validation";
import { getCustomer } from "./customer";

export const getProjectDocumentList = async (params = {}) => {
  let userAuth = getUserAuthRedux();
  let currentParams = storeGetState().dataRedux.projectDocumentParams;
  let newParams = handleTrimParams({
    ...currentParams,
    ...params,
    auth_deal_id: userAuth.id,
  });
  if (!newParams.isNoSort) {
    if (!newParams.sort) {
      newParams.sort = 'created_date desc';
    }
    else {
      newParams = handleSortParams(currentParams, newParams);
    }
  }
  const projectDocumentService = projectDocumentController();
  const res = await projectDocumentService.projectDocumentSearch(newParams);
  if (isValidResponse(res)) {
    let dataList = (res.data || []).map(e => new ModelProjectDocument(e));
    newParams.activePage = newParams.page;
    newParams.totalData = res.total_items;
    console.log('getProjectDocumentList', res.data, dataList, newParams);
    storeDispatch(dataCreators.setDataRedux(DATA_REDUX_STATE_KEY.PROJECT_DOCUMENT_LIST, dataList));
    storeDispatch(dataCreators.setDataRedux(DATA_REDUX_STATE_KEY.PROJECT_DOCUMENT_PARAMS, newParams));
  }
}

export const getClearProjectDocumentParams = () => {
  return {
    start_date: '',
    end_date: '',
    pre_sales_trans_code: '',
    pre_sales_trans_project_name: '',
    customer_name: '',
    product_group: '',
    sort: '',
    isNoSort: false,
  }
}

export const getProjectDocumentDetail = async (configService = {}) => {
  const projectDocumentService = projectDocumentController(configService);
  const res = await projectDocumentService.projectDocumentProjectInformation();
  if (isValidResponse(res)) {
    const data = new ModelProjectDocumentInformation(res.data);
    console.log('getProjectDocumentDetail', res.data, data);
    return data;
  }
}

export const getProjectDocumentInformationData = async (preSalesTransId) => {
  const configService = {
    urlParams: {
      pre_sales_trans_id: preSalesTransId
    },
    isShowLoading: false,
  }
  const data = await getProjectDocumentDetail(configService);
  if (data) {
    const paramsCustomer = {
      page: 1,
      limit: 1,
      id: data.customerId,
    }
    const configServiceCustomer = {
      isShowLoading: false,
    }
    const customerData = await getCustomer(paramsCustomer, configServiceCustomer);
    if (customerData) {
      return {
        customerData,
        data: {
          ...data,
          projectName: data.preSalesTransProjectName,
          installationAddress: data.preSalesTransProjectAddr,
        },
        segmentSelected: {
          segmentId: data.segmentId,
          subSegmentId: data.subSegmentId,
          segmentName: data.segmentName,
          segmentDescription: data.segmentDesc,
          subSegmentName: data.subSegmentName,
          subSegmentDescription: data.subSegmentDesc,
        }
      };
    }
  }
}

export const getProjectDocumentProjectNameList = async (configService = {}) => {
  const projectDocumentService = projectDocumentController(configService);
  const res = await projectDocumentService.projectDocumentProject();
  if (isValidResponse(res)) {
    const dataList = (res.data || []).map(e => new ModelProjectDocumentProjectName(e));
    console.log('getProjectDocumentProjectNameList', res.data, dataList);
    return dataList;
  }
  return []
}


export const getProjectDocumentM3ProductList = async (params = {}, configService = {}) => {
  const projectDocumentService = projectDocumentController(configService);
  const res = await projectDocumentService.projectDocumentM3Product();
  if (isValidResponse(res)) {
    const dataList = res.data.map(e => new ModelProjectDocumentM3Product(e));
    console.log('getProjectDocumentM3ProductList', res.data, dataList);
    return dataList;
  }
  return [];
}

export const getProjectDocumentDocumentaryList = async (configService = {}) => {
  const projectDocumentService = projectDocumentController(configService);
  const res = await projectDocumentService.projectDocumentDocumentary();
  if (isValidResponse(res)) {
    const dataList = res.data.map(e => new ModelWarrantyDocumentary(e));
    console.log('getProjectDocumentDocumentaryList', res.data, dataList);
    return dataList;
  }
  return []
}

export const getProjectDocumentDocumentarySearchList = async (params = {}) => {
  let userAuth = getUserAuthRedux();
  let currentParams = storeGetState().dataRedux.projectDocumentDocumentaryParams;
  let newParams = handleTrimParams({
    ...currentParams,
    ...params,
    auth_deal_id: userAuth.id,
  });
  if (!newParams.isNoSort) {
    if (!newParams.sort) {
      newParams.sort = 'pre_sales_trans_doc_id desc';
    }
    else {
      newParams = handleSortParams(currentParams, newParams);
    }
  }
  const projectDocumentService = projectDocumentController();
  const res = await projectDocumentService.projectDocumentDocumentarySearch(newParams);
  if (isValidResponse(res)) {
    const dataList = res.data.map(e => new ModelProjectDocumentDocumentary(e));
    newParams.activePage = newParams.page;
    newParams.totalData = res.total_items;
    console.log('getProjectDocumentDocumentarySearchList', res.data, dataList);
    storeDispatch(dataCreators.setDataRedux(DATA_REDUX_STATE_KEY.PROJECT_DOCUMENT_DOCUMENTARY_LIST, dataList));
    storeDispatch(dataCreators.setDataRedux(DATA_REDUX_STATE_KEY.PROJECT_DOCUMENT_DOCUMENTARY_PARAMS, newParams));
  }
}

export const getClearProjectDocumentDocumentarySearchParams = () => {
  return {
    sort: '',
    product_group_name: '',
    pre_sales_trans_id: undefined,
    isNoSort: false,
  }
}

export const getProjectDocumentCertificateSearchList = async (params = {}) => {
  let userAuth = getUserAuthRedux();
  let currentParams = storeGetState().dataRedux.projectDocumentCertificateParams;
  let newParams = handleTrimParams({
    ...currentParams,
    ...params,
    auth_deal_id: userAuth.id,
  });
  if (!newParams.isNoSort) {
    if (!newParams.sort) {
      newParams.sort = 'pre_sales_trans_cert_id desc';
    }
    else {
      newParams = handleSortParams(currentParams, newParams);
    }
  }
  const projectDocumentService = projectDocumentController();
  const res = await projectDocumentService.projectDocumentCertificateSearch(newParams);
  if (isValidResponse(res)) {
    const dataList = res.data.map(e => new ModelProjectDocumentCertificateSearch(e));
    newParams.activePage = newParams.page;
    newParams.totalData = res.total_items;
    console.log('getProjectDocumentCertificateSearchList', res.data, dataList);
    storeDispatch(dataCreators.setDataRedux(DATA_REDUX_STATE_KEY.PROJECT_DOCUMENT_CERTIFICATE_LIST, dataList));
    storeDispatch(dataCreators.setDataRedux(DATA_REDUX_STATE_KEY.PROJECT_DOCUMENT_CERTIFICATE_PARAMS, newParams));
  }
}

export const getClearProjectDocumentCertificateSearchParams = () => {
  return {
    sort: '',
    product_group_name: '',
    pre_sales_trans_id: undefined,
    isNoSort: false,
  }
}

export const getProjectDocumentCertificate = async (configService = {}) => {
  const projectDocumentService = projectDocumentController(configService);
  const res = await projectDocumentService.getProjectDocumentCertificate();
  if (isValidResponse(res)) {
    let dataList = (res.data || []).map(e => new ModelProjectDocumentCertificate(e));
    console.log('getProjectDocumentCertificate', res.data, dataList);
    return dataList
  }
  return [];
}

export const getProjectDocumentDocumentaryDetail = async (configService = {}) => {
  const projectDocumentService = projectDocumentController(configService);
  const res = await projectDocumentService.getProjectDocumentDocumentaryDetail();
  if (isValidResponse(res)) {
    let dataList = (res.data || []).map(e => new ModelProjectDocumentDocumentaryDetail(e));
    console.log('getProjectDocumentDocumentaryDetail', res.data, dataList);
    return dataList
  }
  return [];
}

export const getProjectDocumentLogList = async (configService = {}) => {
  const projectDocumentService = projectDocumentController(configService);
  const res = await projectDocumentService.projectDocumentLog();
  if (isValidResponse(res)) {
    const dataList = (res.data || []).map(e => new ModelProjectDocumentLog(e));
    console.log('addProjectDocumentLog', res.data, dataList);
    return dataList
  }
  return [];
}

export const addProjectDocumentLog = async (params = {}) => {
  const userAuth = getUserAuthRedux();
  const newParams = {
    pre_sales_trans_id: params.preSalesTransId,
    pre_sales_trans_log_cate: params.logCategory || '',
    pre_sales_trans_log_prod_grp: params.m3ProductGroupName || '-',
    pre_sales_trans_log_name: params.preSalesTransLogName || '',
    pre_sales_trans_log_desc: params.logDesc || '',
    pre_sales_trans_log_path: params.fileUrl || '',
    ref_id: params.refId,
    created_by: userAuth.name
  }
  const configService = {
    isShowLoading: false,
  }
  const projectDocumentService = projectDocumentController(configService);
  const res = await projectDocumentService.createProjectDocumentLog(newParams);
  if (isValidResponse(res)) {
    console.log('addProjectDocumentLog', res.data);
    return true
  }
}

export const getProjectDocumentSearchLogList = async (params = {}) => {
  let currentParams = storeGetState().dataRedux.projectDocumentSearchLogParams;
  let newParams = handleTrimParams({
    ...currentParams,
    ...params,
    pre_sales_trans_log_cate: ENUM.PROJECT_DOCUMENT.LOG_CATEGORY.WARRANTY_TEMPLATE,
  });
  if (!newParams.isNoSort) {
    if (!newParams.sort) {
      newParams.sort = 'created_date desc';
    }
    else {
      newParams = handleSortParams(currentParams, newParams);
    }
  }
  const projectDocumentService = projectDocumentController();
  const res = await projectDocumentService.projectDocumentSearchLog(newParams);
  if (isValidResponse(res)) {
    let dataList = (res.data || []).map(e => new ModelProjectDocumentSearchLog(e));
    newParams.activePage = newParams.page;
    newParams.totalData = res.total_items;
    console.log('getProjectDocumentSearchLogList', res.data, dataList, newParams);
    storeDispatch(dataCreators.setDataRedux(DATA_REDUX_STATE_KEY.PROJECT_DOCUMENT_SEARCH_LOG_LIST, dataList));
    storeDispatch(dataCreators.setDataRedux(DATA_REDUX_STATE_KEY.PROJECT_DOCUMENT_SEARCH_LOG_PARAMS, newParams));
  }
}

export const getClearProjectDocumentSearchLogParams = () => {
  return {
    product_group_name: '',
    start_date: '',
    end_date: '',
    pre_sales_trans_id: undefined,
    pre_sales_trans_log_cate: '',
    sort: '',
    isNoSort: false,
  }
}

// export const getProjectDocumentSearchWarrantyList = async (params = {}, configService = {}) => {
//   let newParams = handleTrimParams(params)
//   const projectDocumentService = projectDocumentController(configService);
//   const res = await projectDocumentService.projectDocumentSearchWarranty(newParams);
//   if (isValidResponse(res)) {
//     let dataList = (res.data || []).map(e => new ModelProjectDocumentSearchWarranty(e));
//     return dataList;
//   }
//   return [];
// }

// export const getClearProjectDocumentSearchWarrantyParams = () => {
//   return {
//     pre_sales_trans_id: undefined,
//     sort: '',
//     isNoSort: false,
//   }
// }

export const getProjectDocumentGetAllList = async (configService = {}) => {
  const projectDocumentService = projectDocumentController(configService);
  const res = await projectDocumentService.projectDocumentGetAll();
  if (isValidResponse(res)) {
    let dataList = (res.data || []).map(e => new ModelProjectDocumentGetAll(e));
    return dataList;
  }
  return [];
}