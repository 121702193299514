import styled, {
  // css
} from 'styled-components'
import {
  default as VARIABLES
} from './../../../themes/styles/bases/variables'
// import {
//   default as TYPOGRAPHYS
// } from './../../../themes/styles/bases/typographys'
// import {
//   default as MIXINS
// } from './../../../themes/styles/helpers/mixins'

// Sample using image in style
// url(${require('./../../../themes/images/icons/icon-sample.svg')});

// Wrapper
// ============================================================
export const WarrantyWrapper = styled.div`
  /* Parent
  ------------------------------- */
  position: relative;
  max-width: 1376px;
  height: 100%;
  padding-top: 5px;
  padding-right: 30px;
  padding-bottom: 30px;
  padding-left: 30px;
  margin-right: auto;
  margin-left: auto;
  background-color: ${VARIABLES.COLORS.WHITE};

  /* Childrens
  ------------------------------- */
  .warranty-header-group {
    /* position: absolute;
    z-index: ${VARIABLES.Z_INDEXS.LV_1};
    top: 0;
    right: 0;
    left: 0; */
  }

  .warranty-header {
    background-color: ${VARIABLES.COLORS.GRAY_27};
    text-align: center;
  }

  .warranty-header-image {
    display: inline-block;
    width: 543px;
    height: 75px;
  }

  .warranty-subheader {
    padding: 5px 30px;
    background-color: ${VARIABLES.COLORS.BLUE_26};
  }

  .warranty-footer {
    position: absolute;
    z-index: ${VARIABLES.Z_INDEXS.LV_1};
    bottom: 0;
    right: 0;
    left: 0;
    text-align: center;
  }

  .warranty-footer-contact,
  .warranty-footer-copyright {
    padding: 5px 30px;
  }

  .warranty-footer-contact {
    background-color: ${VARIABLES.COLORS.BLUE_26};
  }

  .warranty-footer-copyright {
    background-color: ${VARIABLES.COLORS.GRAY_27};
  }

  .warranty-content {
    padding: 20px 30px 30px;
  }

  .warranty-text {
    font-family: 'Tahoma';
    color: ${VARIABLES.COLORS.BLACK};
    line-height: 1.2;

    &.is-bold {
      font-family: 'Tahoma-Bold';
    }

    &.is-superscript {
      display: inline;
      vertical-align: super;
    }

    &.is-xxs {
      font-size: 7px;
    }

    &.is-xs {
      font-size: 8px;
    }

    &.is-sm {
      font-size: 10px;
    }

    &.is-md {
      font-size: 12px;
    }

    &.is-lg {
      font-size: 14px;
    }

    &.is-xl {
      font-size: 16px;
    }
  }

  /* Modifiers
  ------------------------------- */
  &.is-vietnam {
    padding-top: 0;
    padding-right: 0;
    padding-left: 0;

    &.is-bzacs-hc-inok {
      padding-bottom: 149px;
    }

    &.is-bzacs-inok {
      padding-bottom: 226px;
    }

    &.is-bzacs-ben-lanh {
      padding-bottom: 198px;
    }

    &.is-bzacs-ben-mau {
      padding-bottom: 103px;
    }

    &.is-bzacs-ben-lanh,
    &.is-bzacs-ben-mau {
      .warranty-subheader,
      .warranty-footer-contact {
        background-color: ${VARIABLES.COLORS.RED_6};
      }
    }
  }

  /* Media queries
  ------------------------------- */
`
