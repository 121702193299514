import React from 'react';
import {
  connect
} from 'react-redux';
import { bindActionCreators } from 'redux';
import { dataCreators } from '../../actions/dataAction';
import { getSortAscending } from '../../helpers/functions/data';
import { getLanguage } from '../../helpers/functions/language';
import { getClearProjectDocumentDocumentarySearchParams, getProjectDocumentDocumentarySearchList } from '../../helpers/services/projectDocument';
import { ModalPreviewPdfContainer } from '../ModalContainer';
import {
  Button, Grid, Image, PaginationControl, Section, Table, TableAction, Text
} from './../../components';
import {
  ICONS,
  VARIABLES
} from './../../themes';

class ProjectDocumentDocumentaryListContainer extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      activePage: 1,
      dataPerPage: 10,

      isShowPDFModal: false,
      dataPDF: undefined
    }
  }

  componentDidMount() {
    const {
      projectDocumentId,
    } = this.props;
    const {
      activePage,
      dataPerPage,
    } = this.state;
    const params = {
      ...getClearProjectDocumentDocumentarySearchParams(),
      page: activePage,
      limit: dataPerPage,
      pre_sales_trans_id: projectDocumentId,
      start_date: '',
      end_date: '',
    };
    getProjectDocumentDocumentarySearchList(params);
  }

  projectDocumentDocumentaryList = {
    onClickSort: (target) => {
      getProjectDocumentDocumentarySearchList({
        isNoSort: false,
        sort: target
      });
    },
    onClickView: (item) => {
      this.setState({
        isShowPDFModal: true,
        dataPDF: {
          title: getLanguage('projectDocument.modal.previewDocumentary.title', '').replace('[productGroup]', item.preSalesTransM3ProdGrpName),
          fileUrl: item.fileUrl,
        },
      })
    }
  }

  modal = {
    onClickClosePreviewPDFModal: () => {
      this.setState({
        isShowPDFModal: false,
      })
    },
    onClickDownload: () => {
      const {
        dataPDF,
      } = this.state;
      const link = document.createElement('a');
      link.download = `${new Date().getTime()}.pdf`;
      link.target = '_blank';
      link.href = dataPDF.fileUrl;
      link.click();
    }
  }

  pagination = {
    onPaginationChange: (pageNumber) => {
      getProjectDocumentDocumentarySearchList({
        isNoSort: true,
        page: pageNumber,
      });
    }
  }

  render() {
    const {
      dataList,
      dataParams,
      totalData,
      activePage,
      onClickBack,
    } = this.props;
    const {
      dataPerPage,
      isShowPDFModal,
      dataPDF,
    } = this.state;
    return (
      <React.Fragment>
        <Section spacingBottom="40">
          <Table striped>
            <Table.Section>
              <Table.Row>
                <Table.HeadColumn
                  minWidth={175}
                  maxWidth={175}
                  sortAscending={getSortAscending(dataParams, 'pre_sales_trans_doc_id')}
                  onClickSort={() => {
                    this.projectDocumentDocumentaryList.onClickSort('pre_sales_trans_doc_id desc');
                  }}
                >
                  {getLanguage('projectDocument.table.header.ID', '')}
                </Table.HeadColumn>
                <Table.HeadColumn
                  minWidth={175}
                  noSort
                >
                  {getLanguage('projectDocument.table.header.nameDocument', '')}
                </Table.HeadColumn>
                <Table.HeadColumn
                  minWidth={175}
                  maxWidth={175}
                  sortAscending={getSortAscending(dataParams, 'pre_sales_trans_m3_prod_grp_name')}
                  onClickSort={() => {
                    this.projectDocumentDocumentaryList.onClickSort('pre_sales_trans_m3_prod_grp_name desc');
                  }}
                >
                  {getLanguage('projectDocument.table.header.productGroup', '')}
                </Table.HeadColumn>
                <Table.HeadColumn
                  minWidth={225}
                  maxWidth={225}
                  sortAscending={getSortAscending(dataParams, 'created_date')}
                  onClickSort={() => {
                    this.projectDocumentDocumentaryList.onClickSort('created_date desc');
                  }}
                >
                  {getLanguage('projectDocument.table.header.createdDate', '')}
                </Table.HeadColumn>
                <Table.HeadColumn
                  noSort
                  alignCenter
                  minWidth={75}
                  maxWidth={75}
                >
                  {getLanguage('projectDocument.table.header.action', '')}
                </Table.HeadColumn>
              </Table.Row>
            </Table.Section>
            {
              dataList.length > 0 ? (
                <Table.Section>
                  {
                    dataList.map((e, i) => {
                      return (
                        <Table.Row key={i}>
                          <Table.BodyColumn
                            info
                            minWidth={175}
                            maxWidth={175}
                            title={e.displayCode}
                          >
                            {e.displayCode}
                          </Table.BodyColumn>
                          <Table.BodyColumn
                            info
                            minWidth={175}
                            title={e.displayDocMsName}
                          >
                            {e.displayDocMsName}
                          </Table.BodyColumn>
                          <Table.BodyColumn
                            info
                            minWidth={175}
                            maxWidth={175}
                            title={e.preSalesTransM3ProdGrpName}
                          >
                            {e.preSalesTransM3ProdGrpName}
                          </Table.BodyColumn>
                          <Table.BodyColumn
                            info
                            minWidth={225}
                            maxWidth={225}
                          >
                            {e.displayCreatedDate}
                          </Table.BodyColumn>
                          <Table.BodyColumn
                            info
                            alignCenter
                            minWidth={75}
                            maxWidth={75}
                          >
                            <Grid justify="center" gutter="15" align="center">
                              <Grid.Column>
                                <Image
                                  src={ICONS['ic-documents-blue.svg']}
                                  widthRatio="19"
                                  widthRatioUnit="px"
                                  heightRatio="18"
                                  heightRatioUnit="px"
                                  onClick={() => {
                                    this.projectDocumentDocumentaryList.onClickView(e);
                                  }}
                                />
                              </Grid.Column>
                            </Grid>
                          </Table.BodyColumn>
                        </Table.Row>
                      )
                    })
                  }
                </Table.Section>
              ) : (
                <React.Fragment>
                  <Section justify='center' paddingVertical='80'>
                    <Section justify='center' spacingBottom='8' >
                      <Image
                        src={ICONS['ic-lists-gray.svg']}
                        widthRatio="24"
                        widthRatioUnit="px"
                        heightRatio="24"
                        heightRatioUnit="px"
                      />
                    </Section>
                    <Text regular14 color={VARIABLES.COLORS.GRAY_12}>
                      {getLanguage('projectDocument.label.noDocumentary', '')}
                    </Text>
                  </Section>
                </React.Fragment>
              )
            }
          </Table>
        </Section>
        <TableAction>
          <TableAction.Left>
            <Button
              gray
              ui="back"
              name={getLanguage('field.buttonBack', '')}
              onClick={onClickBack}
            />
          </TableAction.Left>
          <TableAction.Right>
            <Grid gutter='20'>
              <Grid.Column>
                <Section paddingTop='6'>
                  <Text
                    regular12
                    color={VARIABLES.COLORS.GRAY_16}
                  >
                    {getLanguage('pagination.page', '')} {activePage}/{Math.ceil(totalData / dataPerPage)}
                  </Text>
                </Section>
              </Grid.Column>
              <Grid.Column>
                <PaginationControl
                  activePage={activePage}
                  itemsCountPerPage={dataPerPage}
                  totalItemsCount={totalData}
                  pageRangeDisplayed={3}
                  onChange={this.pagination.onPaginationChange}
                />
              </Grid.Column>
            </Grid>
          </TableAction.Right>
        </TableAction>

        {isShowPDFModal &&
          <ModalPreviewPdfContainer
            isOpenModal
            ui='previewDownload'
            title={dataPDF?.title}
            pdfSrc={dataPDF?.fileUrl}
            onClickCancel={this.modal.onClickClosePreviewPDFModal}
            onClickDownload={this.modal.onClickDownload}
          />
        }
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => {
  const dataRedux = state.dataRedux;
  return {
    dataList: dataRedux.projectDocumentDocumentaryList,
    dataParams: dataRedux.projectDocumentDocumentaryParams,
    activePage: dataRedux.projectDocumentDocumentaryParams.activePage,
    totalData: dataRedux.projectDocumentDocumentaryParams.totalData || 0,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    dataActions: bindActionCreators(dataCreators, dispatch),
  }
}

export const ProjectDocumentDocumentaryListContainerConnected = connect(
  mapStateToProps,
  mapDispatchToProps
)(ProjectDocumentDocumentaryListContainer)