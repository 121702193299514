import React from 'react'
import {
  Accordion,
  AccordionItem,
  AccordionItemTitle,
  AccordionItemBody
} from 'react-accessible-accordion'
import {
  ShapeContainer,
  Section,
  Breadcrumb,
  TitlePage,
  Text,
  TitleSection,
  Grid,
  AccordionControl,
  Video,
  Tab,
  TableAction,
  Button,
  Field,
} from './../../components'
import {
  VARIABLES,
  ICONS
} from './../../themes'
import { setSideMenuActive } from '../../helpers/functions/route';
import { ROUTE_PATH } from '../../helpers/constants/route';
import { redirect } from '../../helpers/functions/route';
import { CMSUserManualListContainerConnected } from '../../containers/CMSUserManualListContainer';
import _ from 'lodash';
import { getLanguage } from '../../helpers/functions/language';
import { getUserAuthRedux } from '../../helpers/functions/auth';
import { helpController } from '../../apiService/apiController/helpService';
import { isValidResponse } from '../../helpers/functions/validation';
import { toast } from '../../helpers/functions/main';

export class HelpScreen extends React.Component {

  constructor(props) {
    super(props);
    setSideMenuActive(props);
    const tabList = [
      getLanguage('help.label.coilTranfer', ''),
      getLanguage('help.label.stepGuildline', ''),
    ];

    this.state = {
      tabList,
      tabSelected: tabList[0],

      helpCoilIdValue: '',
      helpProblemValue: '',
    }
  }

  help = {
    onClickTab: (tabSelected) => {
      this.setState({
        tabSelected
      })
    },
    isDisableCoilTransferSubmit: () => {
      const {
        helpCoilIdValue,
        helpProblemValue,
      } = this.state;
      let isDisable = false;
      if (
        helpCoilIdValue.length === 0 ||
        helpProblemValue.length === 0
      ) {
        isDisable = true;
      }
      return isDisable;
    },
    onClickSubmitCoilTransfer: async () => {
      const {
        helpCoilIdValue,
        helpProblemValue,
      } = this.state;
      const userAuth = getUserAuthRedux();
      const params = {
        help_coil_id: helpCoilIdValue,
        help_problem: helpProblemValue,
        created_by: userAuth.name,
        company: userAuth.company,
        mobile: `${userAuth.mobileNoCountry.value}${userAuth.mobileNo}`
      }
      const helpService = helpController();
      const res = await helpService.createHelp(params);
      if (isValidResponse(res)) {
        toast.success(getLanguage('message.receivedYourIssueCheckGetBackYouContact'));
        this.setState({
          helpCoilIdValue: '',
          helpProblemValue: '',
        })
      }
    }
  }

  input = {
    onChangeInput: (ev) => {
      const name = ev.target.name;
      const value = ev.target.value;
      this.setState({
        [name]: value,
      })
    }
  }

  render() {
    const {
      tabList,
      tabSelected,
      helpCoilIdValue,
      helpProblemValue,
    } = this.state
    return (
      <React.Fragment>
        <Breadcrumb>
          <Breadcrumb.List
            link
            name={getLanguage('help.breadcrumb1', '')}
            onClick={() => {
              redirect(ROUTE_PATH.HOME.LINK)
            }}
          />
          <Breadcrumb.List name='/' />
          <Breadcrumb.List
            link
            name={getLanguage('help.breadcrumb2', '')}
          // onClick={() => {
          //   redirect(ROUTE_PATH.SETTINGS.LINK)
          // }}
          />
          <Breadcrumb.List name='/' />
          <Breadcrumb.List name={getLanguage('help.breadcrumb3', '')} />
        </Breadcrumb>
        <Section ui='content'>
          <Section spacingBottom='35'>
            <TitlePage
              src={ICONS['ic-page-settings.svg']}
              name={getLanguage('help.title', '')}
            />
          </Section>
          <Tab isHelpTab>
            <Tab.List
              isHelpActive={tabSelected === tabList[0]}
              onClick={() => {
                this.help.onClickTab(tabList[0])
              }}
            >
              <Section
                width={180}
                justify='center'
                align='center'
                textAlign='center'
              >
                <Text
                  className='is-tab-name'
                  bold14={tabSelected === tabList[0]}
                  regular14={tabSelected !== tabList[0]}
                  color={VARIABLES.COLORS.WHITE}>
                  {tabList[0]}
                </Text>
              </Section>
            </Tab.List>
            <Tab.List
              isHelpActive={tabSelected === tabList[1]}
              onClick={() => {
                this.help.onClickTab(tabList[1])
              }}
            >
              <Section
                width={180}
                justify='center'
                align='center'
                textAlign='center'
              >
                <Text
                  className='is-tab-name'
                  bold14={tabSelected === tabList[1]}
                  regular14={tabSelected !== tabList[1]}
                  color={VARIABLES.COLORS.WHITE}>
                  {tabList[1]}
                </Text>
              </Section>
            </Tab.List>
          </Tab>
          {
            tabSelected === tabList[0] ? (
              <React.Fragment>
                <ShapeContainer
                  ui='content'
                  justify='space-between'
                >
                  <Section>
                    <Section spacingBottom='40'>
                      <TitleSection name={getLanguage('help.tab.title', '')} />
                    </Section>
                    <Section spacingBottom='40'>
                      <Grid>
                        <Grid.Column
                          gridTablet='2'
                          gridDesktop='2'
                        >
                          <Field name='helpCoilIdValue'
                            fluid
                            required
                            // error
                            ui='blockLabelVertical'
                            label={getLanguage('help.tab.label.coilId', '')}
                            placeholder={getLanguage('help.tab.field.placeholderInsertCoilId', '')}
                            value={helpCoilIdValue}
                            onChange={this.input.onChangeInput}
                          />
                        </Grid.Column>
                      </Grid>
                    </Section>
                    <Grid>
                      <Grid.Column
                        gridTablet='5'
                        gridDesktop='5'
                      >
                        <Field name='helpProblemValue'
                          fluid
                          required
                          ui='blockLabelVerticalTextarea'
                          label={getLanguage('help.tab.label.reportProblem', '')}
                          placeholder={getLanguage('help.tab.field.placeholderInsertProblem', '')}
                          value={helpProblemValue}
                          onChange={this.input.onChangeInput}
                        />
                      </Grid.Column>
                    </Grid>
                  </Section>
                  <Section>
                    <TableAction>
                      <TableAction.Left></TableAction.Left>
                      <TableAction.Right>
                        <Button
                          blue
                          disabled={this.help.isDisableCoilTransferSubmit()}
                          width={VARIABLES.BUTTON.WIDTHS.W_140}
                          name={getLanguage('help.button.buttonSubmit', '')}
                          onClick={this.help.onClickSubmitCoilTransfer}
                        />
                      </TableAction.Right>
                    </TableAction>
                  </Section>
                </ShapeContainer>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <CMSUserManualListContainerConnected />
              </React.Fragment>
            )
          }
        </Section>
      </React.Fragment >
    )
  }
}
