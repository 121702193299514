import { DATA_REDUX_STATE_KEY, dataCreators } from "../../actions/dataAction";
import { notificationManagementController } from "../../apiService/apiController/notificationManagementService";
import { ModelNotificationManagement, ModelNotificationManagementSearch } from "../../models/ModelNotificationManagement";
import { storeDispatch, storeGetState } from "../../stores";
import { getUserAuthRedux } from "../functions/auth";
import { handleSortParams, handleTrimParams } from "../functions/data";
import { isValidResponse } from "../functions/validation";

export const getNotificationManagement = async (params = {}) => {
  const configService = {
    params,
  }
  const notificationManagementService = notificationManagementController(configService);
  const res = await notificationManagementService.getNotificationManagement();
  if (isValidResponse(res)) {
    let dataList = res.data.map(e => new ModelNotificationManagement(e));
    console.log('getNotificationManagement', res.data, dataList)
    return dataList;
  }
  return [];
}

export const getNotificationManagementAcceptSearchList = async (params = {}) => {
  const userAuth = getUserAuthRedux();
  let currentParams = storeGetState().dataRedux.notificationManagementParams;
  let newParams = handleTrimParams({
    ...currentParams,
    ...params,
    location_account: userAuth.adRefId,
    rbp_member_sfid: userAuth.sfid,
  });
  if (!newParams.isNoSort) {
    if (!newParams.sort) {
      newParams.sort = 'public_date desc';
    }
    else {
      newParams = handleSortParams(currentParams, newParams);
    }
  }
  const notificationManagementService = notificationManagementController();
  const res = await notificationManagementService.notificationManagementAcceptSearch(newParams);
  if (isValidResponse(res)) {
    const dataList = res.data.map(e => new ModelNotificationManagementSearch(e));
    newParams.activePage = newParams.page;
    newParams.totalData = res.total_items;
    console.log('getNotificationManagementAcceptSearchList', res.data, dataList);
    storeDispatch(dataCreators.setDataRedux(DATA_REDUX_STATE_KEY.NOTIFICATION_MANAGEMENT_LIST, dataList));
    storeDispatch(dataCreators.setDataRedux(DATA_REDUX_STATE_KEY.NOTIFICATION_MANAGEMENT_PARAMS, newParams));
  }
}

export const getClearNotificationManagementAcceptSearchParams = () => {
  return {
    location_account: '',
    rbp_member_sfid: '',
    text_search: '',
    start_date: '',
    end_date: '',
    sort: '',
    isNoSort: false,
  }
}