import { isValidResponse } from "../functions/validation";
import { storeDispatch, storeGetState } from "../../stores";
import { dataCreators, DATA_REDUX_STATE_KEY } from "../../actions/dataAction";
import { orderInquiryController } from "../../apiService/apiController/orderInquiryService";
import { ModelOrderInquiry } from "../../models/ModelOrderInquiry";
import { ENUM } from "../constants/enum";
import { handleSortParams, handleTrimParams } from "../functions/data";

export const getOrderInquiryList = async (params = {}) => {
  let userAuth = storeGetState().mainRedux.userAuth;
  let currentParams = storeGetState().dataRedux.orderInquiryParams;
  let newParams = handleTrimParams({
    ...currentParams,
    ...params,
    ad_ref_id: userAuth.adRefId,
  });
  if (!newParams.sort) {
    newParams.sort = 'id desc';
  }
  else {
    newParams = handleSortParams(currentParams, newParams);
  }
  const orderInquiryService = orderInquiryController();
  const res = await orderInquiryService.searchOrderInquiry(newParams);
  if (isValidResponse(res)) {
    const dataList = res.data.map(e => new ModelOrderInquiry(e));
    newParams.activePage = newParams.page;
    newParams.totalData = res.total_items;
    console.log('getOrderInquiryList dataList', res.data, dataList, newParams);
    storeDispatch(dataCreators.setDataRedux(DATA_REDUX_STATE_KEY.ORDER_INQUIRY_LIST, dataList));
    storeDispatch(dataCreators.setDataRedux(DATA_REDUX_STATE_KEY.ORDER_INQUIRY_PARAMS, newParams));
    return dataList;
  }
}

export const getNotiOrderInquiryList = async (params = {}) => {
  let currentParams = {};
  let newParams = handleTrimParams({
    ...currentParams,
    ...params,
  });
  if (!newParams.sort) {
    newParams.sort = 'id desc';
  }
  const orderInquiryService = orderInquiryController();
  const res = await orderInquiryService.searchOrderInquiry(newParams);
  if (isValidResponse(res)) {
    const dataList = res.data.map(e => new ModelOrderInquiry(e));
    newParams.activePage = newParams.page;
    newParams.totalData = res.total_items;
    console.log('getNotiOrderInquiryList dataList', res.data, dataList, newParams);
    return {
      dataList,
      params: newParams
    };
  }
}

export const getClearOrderInquiryParams = () => {
  return {
    id: '',
    subject: '',
    all_field: '',
    sort: '',
  }
}
