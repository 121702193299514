import moment from 'moment';
import { DATE_FORMAT, DATE_MOMENT_FORMAT } from "../helpers/constants/data";
import { ENUM } from "../helpers/constants/enum";
import { getUserAuthRedux } from "../helpers/functions/auth";
import { getCountryPhone, getPhoneRemovePrefix, getTodayDateDiff } from "../helpers/functions/data";
import { displayDate, displayWithComma, displayWithPrefix } from "../helpers/functions/display";

export class ModelSaleTrans {
  constructor(props = {}) {
    const userAuth = getUserAuthRedux();

    this.id = props.id;
    this.idWithPrefix = displayWithPrefix(props.id);
    this.name = props.name;
    this.orderNo = props.order_no;
    this.coilName = props.coil_name;
    this.coilId = props.coil_id;
    this.orderM = props.order_m;
    this.orderMWithCommas = displayWithComma(props.order_m);
    this.points = props.points;
    this.pointsWithComma = displayWithComma(props.points);
    this.salesPersonId = props.sales_person_id;
    this.salesPersonName = props.sales_person_name;
    this.customerId = props.customer_id;
    this.customerName = props.customer_name;
    this.createAt = props.project_ins_date ? props.project_ins_date : props.create_at;
    this.displayCreateAt = displayDate(this.createAt, '/', DATE_FORMAT);
    this.phoneNo = getPhoneRemovePrefix(props.phone_no);
    this.phoneNoCountry = getCountryPhone(props.phone_no);
    this.isShowDelete = getTodayDateDiff(moment(moment(this.createAt).format(DATE_MOMENT_FORMAT))) <= 7 &&
      props.rbp_warranty__c === null;
    this.profileQuantity = props.profile_quantity__c;
    this.profileQuantityWithComma = displayWithComma(this.profileQuantity);
    this.profileType = props.profile_type__c || '';
    this.profileLength = props.profile_length__c || 0;
    this.profileLengthWithComma = displayWithComma(this.profileLength);
    this.fBsproductgroupname = props.f_bsproductgroupname__c || '';
    this.pointsRevenue = props.points_revenue || 0;
    this.pointsRevenueWithComma = displayWithComma(this.pointsRevenue);
    this.profileTypeUnit = ENUM.PROFILE_TYPE_UNIT[this.profileType] || '';
    this.topPaintColor = props.top_paint_color__c;
    this.multipleWarrantyRemainingLength = props.multiple_warranty_remaining_length;
    this.isShowRemainingLength = this.multipleWarrantyRemainingLength < this.orderM;

    if (this.isShowRemainingLength === false) {
      this.multipleWarrantyRemainingLength = this.orderM;
    }

    this.multipleWarrantyRemainingLengthWithComma = displayWithComma(this.multipleWarrantyRemainingLength);
    this.isShowMultiple =
      userAuth.addressType === ENUM.COUNTRY_TYPE.MALAYSIA &&
      userAuth.customerRoleType !== ENUM.CUSTOMER_ROLE_TYPE.MALAYSIA.ROLL_FORMER &&
      this.multipleWarrantyRemainingLength > 0
    //malay
    this.myr = props.myr__c;
    this.myrWithComma = displayWithComma(this.myr);
    this.invoiceNo = props.invoice_no ? props.invoice_no : '-';
    this.invoiceLineItem = props.invoice_line_item;
  }
}

export class ModelSaleTransProjectDocumentLink {
  constructor(props = {}) {
    this.salesTranId = props.sales_tran_id;
    this.name = props.name;
    this.coilLength__c = props.coil_length__c;
    this.lotNumber__c = props.lot_number__c;
    this.itemDescription__c = props.item_description__c;

    this.id = this.salesTranId;
    this.displayCoilLengthWithComma = displayWithComma(this.coilLength__c);
  }
}