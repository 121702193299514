import styled, {
  // css
} from 'styled-components'
import {
  default as VARIABLES
} from './../../../themes/styles/bases/variables'
// import {
//   default as TYPOGRAPHYS
// } from './../../../themes/styles/bases/typographys'
// import {
//   default as MIXINS
// } from './../../../themes/styles/helpers/mixins'

// Sample using image in style
// url(${require('./../../../themes/images/icons/icon-sample.svg')});

// Wrapper
// ============================================================
export const WarrantyGeneratePdfPageWrapper = styled.div`
  /* Parent
  ------------------------------- */
  overflow: auto;
  // position: fixed;
  z-index: ${VARIABLES.Z_INDEXS.LV_10};
  // top: 0;
  // right: 0;
  // bottom: 0;
  // left: 0;
  padding: 0 30px;
  background-color: ${VARIABLES.COLORS.WHITE};

  /* Childrens
  ------------------------------- */
  /* Fix tag style */
  strong {
    font-weight: bold;
  }

  .wgpp-list {
    width: 800px; // 1376
    height: 1096px; // 1934
    margin: 0 auto 17px; // 0 auto 17px
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    page-break-inside: avoid;
    page-break-before: auto;

    &:last-child {
      margin: 0 auto;
    }

    &.is-document {}

    &.is-space-bottom{}

    /* React Jodit - Text editor style */
    .jodit-wysiwyg {
      height: 100%;
      font-family: Tahoma, Geneva, sans-serif;
      font-size: 12px;
      line-height: 16px;

      ol,
      ul {
        padding: 0 0 0 16px;
      }

      ol {
        list-style-type: decimal;

        li {
          list-style-type: inherit;
        }
      }

      ul {
        list-style-type: disc;

        li {
          list-style-type: inherit;
        }
      }

      span {
        line-height: 1.1;
      }

      hr {
        margin: 10px 0;
      }

      table {
        margin-top: 0;
        margin-bottom: 0;
        border: 1px solid ${VARIABLES.COLORS.BLACK};

        tr {
          &:first-child {
            td {
              padding: 1px 3px 2px;
              border-top: none;
            }
          }

          td,
          th {
            border: none;
            border-left: 1px solid ${VARIABLES.COLORS.BLACK};

            &:first-child {
              border-left: none;
            }
          }

          th {
            padding: 1px 3px 2px;
          }

          td {
            padding: 0 3px 1px;
            border-top: 1px solid ${VARIABLES.COLORS.BLACK};
          }
        }
      }
    }
  }

  .wgpp-container {
    position: relative;
    width: 735px;
    height: 100%;

    &.is-space-between {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }

  .wgpp-container-left-right {
    display: flex;
  }

  .wgpp-container-document-file {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 735px;
    height: 100%; 

    .wgpp-page-documentary {
      margin-bottom: 96px;
    }

    .wgpp-footer-file {
      position: absolute;
      bottom: 0;

      .wgpp-text-footer-section {
        position: absolute;
        bottom: 16px;
        right: 112px;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        
        .wgpp-text-issue-date {
          font-family: ${VARIABLES.FONT.FAMILIES.FIRST.WEIGHTS.REGULAR};
          font-weight: normal;
          font-size: 10px;
          line-height: 1.5;
          color: ${VARIABLES.COLORS.BLACK};

          &.is-issue-date-title {}

          &.is-date {
            position: relative;
            top: -20px;
            right: 2px;
            z-index: 9;
          }

          &.is-bold {
            font-family: ${VARIABLES.FONT.FAMILIES.FIRST.WEIGHTS.BOLD};
          }

          &.is-white {
            color: ${VARIABLES.COLORS.WHITE};
          }
          
        }
        .wgpp-image-created-verify {
          position: absolute;
          top: -49px;
          right: 0;
        }
      }
      
      .wgpp-qr {
          position: absolute;
          z-index: ${VARIABLES.Z_INDEXS.LV_1};
          top: 50%;
          transform: translateY(-50%);
          right: 37px;
          display: block;
        }
    }
  }

  .wgpp-section-top {}

  .wgpp-section-bottom {
    padding: 0 0 98px;
  }

  .wgpp-warranty-number {
    position: absolute;
    z-index: ${VARIABLES.Z_INDEXS.LV_1};
    top: 99px;
    left: 30px;
  }

  .wgpp-header {
    position: relative;

    &.is-full-page {
      margin: 0 -30px;
    }
  }

  .wgpp-body {
    position: relative;
    padding: 25px 0 0;
  }

  .wgpp-body-center {
    padding: 25px 0 10px;

    * {
      line-height: 11px;
    }
  }

  .wgpp-note {
    padding: 15px 0 0;
  }

  .wgpp-body-left,
  .wgpp-body-right {
    flex: none;
    width: 50%;

    * {
      line-height: 8px;
    }
  }

  .wgpp-body-left {
    padding: 0 8px 0 0;
  }

  .wgpp-body-right {
    padding: 0 0 0 8px;
  }

  .wgpp-footer {}

  .wgpp-text {
    /* font-family: Tahoma, Geneva, sans-serif; */
    font-family: ${VARIABLES.FONT.FAMILIES.FIRST.WEIGHTS.REGULAR};
    font-weight: normal;
    font-size: 12px;
    line-height: 1.1;
    color: ${VARIABLES.COLORS.BLACK};

    &.is-bold {
      font-family: ${VARIABLES.FONT.FAMILIES.FIRST.WEIGHTS.BOLD};
    }

    &.is-white {
      color: ${VARIABLES.COLORS.WHITE};
    }

    &.is-sm {
      font-size: 10px;
      line-height: 11px;
    }

    &.is-preview {
      font-size: 96px;
      letter-spacing: 5px;
      color: ${VARIABLES.COLORS.RED_4};
    }

    &.is-documentary,
    &.is-warranty {
      color: ${VARIABLES.COLORS.GRAY_19};
    }

    &.is-documentary-id {
      font-size: 14px;
    }
    
    &.is-documentary-id-certificate {
      font-size: 18px;
    }

    &.is-documentary-product-label,
    &.is-documentary-project-address,
    &.is-documentary-product,
    &.is-description {
      font-size: 24px;
    }

    &.is-documentary-brand,
    &.is-documentary-company-name,
    &.is-brand {
      font-size: 40px;
    }

    &.is-documentary-brand-certificate {
      font-size: 28px;
    }

    &.is-documentary-company-name-certificate {
      font-size: 24px;
    }

    &.is-documentary-product-label-certificate {
      font-size: 18px;
      font-weight: 700;
      color: #898989;
    }

    &.is-documentary-project-address-certificate {
      font-size: 18px;
    }

    &.is-heading {
      font-size: 32px;
    }
  }

  .wgpp-table {
    margin-top: 0;
    margin-bottom: 0;
    width: 100%;
    border: 1px solid ${VARIABLES.COLORS.BLACK};

    thead tr th,
    tbody tr td {
      border: none !important; // use !important for fix border collapse in pdf
      border-left: 1px solid ${VARIABLES.COLORS.BLACK} !important; // use !important for fix border collapse in pdf

      &:first-child {
        border-left: none;
      }

      &.is-text-align-center {
        text-align: center;
      }

      &.is-number {
        width: 50px;
      }

      &.is-installation-date {
        width: 185px;
      }
    }

    thead {
      tr {
        th {
          padding: 0 3px 3px !important; // use !important for fix pdf style
          border-bottom: 1px solid ${VARIABLES.COLORS.BLACK} !important; // use !important for fix border collapse in pdf
        }
      }
    }

    tbody {
      tr {
        td {
          padding: 0 3px 1px;
          border-top: 1px solid ${VARIABLES.COLORS.BLACK} !important; // use !important for fix border collapse in pdf
        }

        &:first-child {
          td {
            border-top: none !important; // use !important for fix border collapse in pdf
          }
        }
      }
    }
  }

  .wgpp-mark {
    position: absolute;
    z-index: ${VARIABLES.Z_INDEXS.LV_2};
    top: 0;
    right: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 800px;

    &.is-watermark-bs-res {
      opacity: .25;
      top: 10%;
    }
  }

  .wgpp-mark-container {
    &.is-rotate {
      transform: rotate(-15deg);
    }
  }

  .wgpp-content {
    &.is-documentary,
    &.is-warranty {
      text-align: center;

      .wgpp-content-item {
        margin: 0 0 30px;

        &:last-child {
          margin: 0;
        }
      }
    }

    &.is-watermark {
      text-align: center;
      max-width: 620px;

      .wgpp-content-item {
        margin: 0 0 30px;
        word-break: break-word;

        &:last-child {
          margin: 0;
        }
      }
    }
  }

  .wgpp-document-number {
    position: absolute;
    z-index: ${VARIABLES.Z_INDEXS.LV_2};
    bottom: 30px;
    left: 30px;
  }

  /* Modifiers
  ------------------------------- */
  &.is-hide {
    pointer-events: none;
    visibility: hidden;
    opacity: 0;
  }

  &.is-preview {
    width: 100%;
    height: 1132px;
    padding: 30px;

    .wgpp-list {
      height: auto;
      
      &.is-center {
        display: flex;
        justify-content: center;
      }

      &.is-space-bottom{
        margin-bottom: 48px;
      }
    }

    .wgpp-container {
      min-height: 1105px;
      margin: 0 auto;
    }

    .wgpp-page-documentary {
      margin-bottom: 120px;
    }
    .wgpp-footer-file {
      margin: 30px 0;
      position: absolute;
      bottom: 0;

      .wgpp-text-footer-section {
        position: absolute;
        bottom: 16px;
        right: 112px;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;

        .wgpp-text-issue-date {
        font-family: ${VARIABLES.FONT.FAMILIES.FIRST.WEIGHTS.REGULAR};
        font-weight: normal;
        font-size: 10px;
        line-height: 1.5;
        color: ${VARIABLES.COLORS.BLACK};

        &.is-issue-date-title {}

        &.is-date {
          position: relative;
          top: -20px;
          right: 2px;
          z-index: 9;
        }

        &.is-bold {
          font-family: ${VARIABLES.FONT.FAMILIES.FIRST.WEIGHTS.BOLD};
        }

        &.is-white {
          color: ${VARIABLES.COLORS.WHITE};
        }
        .wgpp-image-created-verify {
          position: absolute;
          top: -49px;
          right: 0;
        }
        
      }
      }

      
      
    }

    .wgpp-header {
      &.is-full-page {
        margin: 0 -32px 0 -33px;
      }
    }

    .wgpp-footer-section {
      position: relative;

      img {
          /*width: 735px;*/
          width: 800px;
          height: auto;
        }
        
      &.is-full-page {
        margin: 0 -32px -40px -33px;
      }

      &.is-display-none {
        display: none;
      }

      .wgpp-qr {
        position: absolute;
        z-index: ${VARIABLES.Z_INDEXS.LV_1};
        top: 50%;
        transform: translateY(-50%);
        right: 43px;
        display: block;
      }
      /*&:after {
          content: ' ';
          position: absolute;
          z-index: ${VARIABLES.Z_INDEXS.LV_1};
          top: 0;
          right: 0;
          display: block;
          width: 174px;
          height: 85px;
          background-image: url(${require('./../../../themes/images/contents/qr-code-preview-template.png')});
          background-image: contain;
        }*/

      .wgpp-text-issue-date {
        font-family: ${VARIABLES.FONT.FAMILIES.FIRST.WEIGHTS.REGULAR};
        font-weight: normal;
        font-size: 10px;
        line-height: 1.1;
        color: ${VARIABLES.COLORS.BLACK};
        position: relative;
        z-index: ${VARIABLES.Z_INDEXS.LV_2};

        &.is-issue-date-title {
          position: absolute;
          bottom: 54px;
          right: 148px;
        }

        &.is-date {
          position: absolute;
          bottom: 36px;
          right: 126px;
        }

        &.is-check-via-qr {
          position: absolute;
          bottom: 16px;
          right: 126px;
        }

        &.is-bold {
          font-family: ${VARIABLES.FONT.FAMILIES.FIRST.WEIGHTS.BOLD};
        }

        &.is-white {
          color: ${VARIABLES.COLORS.WHITE};
        }
      }

      .wgpp-image-created-verify {
        position: absolute;
        top: 12px;
        right: 124px;
      }
      
    }

    .wgpp-section-bottom {
      padding: 0;

      .wgpp-note {
        padding: 15px 0 20px;
      }
    }

    .wgpp-container-left-right {
      padding: 0 0 32px;
    }

    .wgpp-image {
      &.is-footer {
        position: relative;
        margin: 0 -32px 0 -33px;
        background-color: ${VARIABLES.COLORS.GRAY_31};

        .wgpp-qr {
          position: absolute;
          z-index: ${VARIABLES.Z_INDEXS.LV_1};
          top: 50%;
          transform: translateY(-50%);
          right: 43px;
          display: block;
        }
        /* &:after {
          content: ' ';
          position: absolute;
          z-index: ${VARIABLES.Z_INDEXS.LV_1};
          top: 0;
          right: 0;
          display: block;
          width: 174px;
          height: 86px;
           background-image: url(${require('./../../../themes/images/contents/qr-code-preview-template.png')});
          background-image: contain;
        } */
        
        img {
          /* width: 735px; */
          width: 800px;
          height: auto;
        }
        .wgpp-text-issue-date {
          font-family: ${VARIABLES.FONT.FAMILIES.FIRST.WEIGHTS.REGULAR};
          font-weight: normal;
          font-size: 10px;
          line-height: 1.1;
          color: ${VARIABLES.COLORS.BLACK};
          position: relative;
          z-index: ${VARIABLES.Z_INDEXS.LV_2};

          &.is-issue-date-title {
            position: absolute;
            bottom: 54px;
            right: 148px;
          }

          &.is-date {
            position: absolute;
            bottom: 36px;
            right: 126px;
          }

          &.is-check-via-qr {
            position: absolute;
            bottom: 16px;
            right: 126px;
          }

          &.is-bold {
            font-family: ${VARIABLES.FONT.FAMILIES.FIRST.WEIGHTS.BOLD};
          }

          &.is-white {
            color: ${VARIABLES.COLORS.WHITE};
          }
        }

        .wgpp-image-created-verify {
          position: absolute;
          top: 12px;
          right: 124px;
        }

      }

      &.is-display-none {
        display: none;
      }
    }
  }

  /* Media queries
  ------------------------------- */
`
