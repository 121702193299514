import apiService from "../apiService";

export const projectReferenceController = (configService = {}) => {
  const service = apiService(configService);
  return {
    projectReferenceSearch: (params) => {
      return service.post(`/project-reference/search`, params);
    },
    // projectReferenceProject: () => {
    //   return service.get(`/project-reference/project`);
    // },
    projectReference: () => {
      return service.get(`/project-reference`);
    },
    createProjectReference: (params) => {
      return service.post(`/project-reference`, params);
    },
    updateProjectReference: (params) => {
      return service.put(`/project-reference`, params);
    },
    deleteProjectReference: () => {
      return service.delete(`/project-reference`,);
    },
  };
};
