import React from 'react'
import {
  ShapeContainer,
  Section,
  Breadcrumb,
  TitlePage,
  Grid,
  Button,
  Table,
  PaginationControl,
  Field,
} from './../../components'
import {
  VARIABLES,
  ICONS,
} from './../../themes'
import { ModalInfoContainer } from './../../containers/ModalContainer'
import { setSideMenuActive } from '../../helpers/functions/route';
import { ROUTE_PATH } from '../../helpers/constants/route';
import { redirect } from '../../helpers/functions/route';
import { toast, isShowLoading } from '../../helpers/functions/main'
import _ from 'lodash';
import { getLanguage } from '../../helpers/functions/language';
import { getCsv2Json } from '../../helpers/functions/addon';
import { getUserAuthRedux } from '../../helpers/functions/auth';
import { isValidResponse } from '../../helpers/functions/validation';
import { integrationController } from '../../apiService/apiController/integrationService';
import { ModelUploadTransaction } from '../../models/ModelUploadTransaction';
import { ENUM } from '../../helpers/constants/enum';
import { getClearUploadTransactionHistoryParams, getUploadTransactionSearchFileList } from '../../helpers/services/uploadTransactionHistory';
import { UploadTransactionHistoryListContainerConnected } from '../../containers/UploadTransactionHistoryListContainer/UploadTransactionHistoryListContainer';

export class UploadTransactionHistoryScreen extends React.Component {

  constructor(props) {
    super(props);
    setSideMenuActive(props);
    this.state = {
      searchValue: ''
    }
  }


  input = {
    onChangeInput: (ev) => {
      const name = ev.target.name;
      const value = ev.target.value;
      this.setState({
        [name]: value,
      })
    },
    onClickSearchText: () => {
      const {
        searchValue,
      } = this.state;
      let params = {
        ...getClearUploadTransactionHistoryParams(),
        page: 1,
      };
      params.file_name = searchValue;
      getUploadTransactionSearchFileList(params);
    },
  }

  render() {
    const {
      searchValue,
    } = this.state
    return (
      <React.Fragment>
        <Breadcrumb>
          <Breadcrumb.List
            link
            name={getLanguage('uploadTransactionHistory.breadcrumb1', '')}
            onClick={() => {
              redirect(ROUTE_PATH.HOME.LINK)
            }}
          />
          <Breadcrumb.List name='/' />
          <Breadcrumb.List
            link
            name={getLanguage('uploadTransactionHistory.breadcrumb2', '')}
          // onClick={() => {
          //   redirect(ROUTE_PATH.UPLOAD_TRANSACTION_INDEXES.LINK)
          // }}
          />
          <Breadcrumb.List name='/' />
          <Breadcrumb.List name={getLanguage('uploadTransactionHistory.breadcrumb3', '')} />
        </Breadcrumb>
        <Section ui='content'>
          <Section spacingBottom='35'>
            <Grid
              gutterVertical='15'
              justify='space-between'
            >
              <Grid.Column>
                <TitlePage
                  src={ICONS['ic-page-upload-transaction.svg']}
                  name={getLanguage('uploadTransactionHistory.title', '')}
                />
              </Grid.Column>
              <Grid.Column>
                <Grid.Column>
                  <Grid gutter='10'>
                    <Grid.Column>
                      <Field
                        name="searchValue"
                        ui='blockLabelVerticalSearch'
                        width='285'
                        placeholder={getLanguage('uploadTransactionHistory.form.placeholderSearchFileName', '')}
                        value={searchValue}
                        onChange={this.input.onChangeInput}
                      />
                    </Grid.Column>
                    <Grid.Column>
                      <Button
                        lightBlue
                        name={getLanguage('uploadTransactionHistory.form.buttonSearch', '')}
                        width={VARIABLES.BUTTON.WIDTHS.W_105}
                        onClick={this.input.onClickSearchText}
                      />
                    </Grid.Column>
                  </Grid>
                </Grid.Column>
              </Grid.Column>
            </Grid>
          </Section>
          <UploadTransactionHistoryListContainerConnected />
        </Section>
      </React.Fragment>
    )
  }
}
