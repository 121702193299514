import React from 'react'
import { Helmet } from 'react-helmet'
import {
  FacebookShareButton,
  WhatsappShareButton,
  LineShareButton,
  EmailShareButton,
  FacebookIcon,
  WhatsappIcon,
  LineIcon,
  EmailIcon
} from 'react-share'
import {
  Section,
  Grid,
  Text,
  Container,
} from './../../components'
import {
  VARIABLES,
  CONTENTS,
} from './../../themes'
import { getRouteParams } from '../../helpers/functions/route';
import { getCMSNewsAndPromotion } from '../../helpers/services/cmsNewsAndPromotion'
import { isShowLoading } from '../../helpers/functions/main'
import ReactHtmlParser from 'react-html-parser';

export class PublicNewsAndPromotionScreen extends React.Component {

  constructor(props) {
    super(props);
    const id = getRouteParams(props, 'id');
    this.state = {
      dataId: id,
      data: {
        cover: {}
      }
    }
    console.log('id', id)
  }

  componentDidMount() {
    this.newsAndPromotion.getCMSNewsAndPromotion();
  }

  newsAndPromotion = {
    getCMSNewsAndPromotion: async () => {
      const {
        dataId,
      } = this.state;
      isShowLoading(true);
      const res = await getCMSNewsAndPromotion({
        id: dataId
      });
      this.setState({
        data: res,
      }, () => {
        isShowLoading(false);
      })
    }
  }

  render() {
    const {
      data,
    } = this.state
    console.log('data', data)
    return (
      <React.Fragment>
        <Helmet>
          <title>{data.subject}</title>
          <meta name='description' content={data.content} />
          <meta property='og:url' content={window.location.href} />
          <meta property='og:type' content='article' />
          <meta property='og:title' content={data.subject} />
          <meta property='og:description' content={data.content} />
          <meta property='og:image' content={(data.cover && data.cover.path) ? data.cover.path : ''} />
          <meta name='twitter:card' content='summary' />
        </Helmet>
        <Container
          ui='standAlone'
          align='center'
        >
          <Section
            maxWidth='1280'
            padding='30'
          >
            <Section
              paddingBottom='30'
              spacingBottom='15'
              borderBottomWidth='1'
              borderBottomStyle='solid'
              borderBottomColor={VARIABLES.COLORS.GRAY_8}
            >
              <Text
                bold24
                color={VARIABLES.COLORS.BLACK}
              >
                {data.subject}
              </Text>
            </Section>
            {/*  <Section
              paddingBottom='15'
              spacingBottom='45'
              borderBottomWidth='1'
              borderBottomStyle='solid'
              borderBottomColor={VARIABLES.COLORS.GRAY_8}
            >
              <Grid
                gutter='30'
                justify='center'
              >
                <Grid.Column>
                  <FacebookShareButton
                    url=''
                    quote=''
                  >
                    <FacebookIcon
                      size={32}
                      round
                    />
                  </FacebookShareButton>
                </Grid.Column>
                <Grid.Column>
                  <WhatsappShareButton
                    url=''
                    quote=''
                  >
                    <WhatsappIcon
                      size={32}
                      round
                    />
                  </WhatsappShareButton>
                </Grid.Column>
                <Grid.Column>
                  <LineShareButton
                    url=''
                    quote=''
                  >
                    <LineIcon
                      size={32}
                      round
                    />
                  </LineShareButton>
                </Grid.Column>
                <Grid.Column>
                  <EmailShareButton
                    url=''
                    quote=''
                  >
                    <EmailIcon
                      size={32}
                      round
                    />
                  </EmailShareButton>
                </Grid.Column>
              </Grid>
            </Section>*/}
            <Section spacingBottom='30'>
              {
                data.cover.path ?
                  <img alt='Banner'
                    src={data.cover.path}
                  />
                  :
                  undefined
              }
            </Section>
            <Section className='text-editor-content'>
              <div
                dangerouslySetInnerHTML={{
                  __html: data.content
                }}
              />
            </Section>
          </Section>
        </Container>
      </React.Fragment>
    )
  }
}