import { getAuthSuccess, getUserAuth } from '../functions/auth';
import { handleQueryFirebase, handleUpdateFirebase, handleAddFirebase, handleSetFirebase } from './firebaseManageData';
import { dataCreators, DATA_REDUX_STATE_KEY } from '../../actions/dataAction';
import { ModelUserNoti } from '../../models/ModelUserNoti';
import { ModelAdNoti } from '../../models/ModelAdNoti';
import { storeDispatch, storeGetState } from '../../stores';
import _ from 'lodash';
import { ModelNoti } from '../../models/ModelNoti';
import { displayDate } from '../functions/display';
import { DATE_TIME_FORMAT } from '../constants/data';

let qSnapOrderInqueryNotiUnsub = () => { };
let qSnapNotiUnsub = () => { };

export const firebaseUnsubscribeNoti = () => {
  console.log('firebaseUnsubscribeNoti')
  qSnapOrderInqueryNotiUnsub();
  qSnapNotiUnsub();
}

export const firebaseUpdateOrderIsNew = (isNew = false) => {
  return new Promise(async (resolve, reject) => {
    try {
      const firebaseNoti = storeGetState().dataRedux.firebaseNoti;
      await handleUpdateFirebase('notification', firebaseNoti.data.firebaseKey, {
        'order_inquiry.is_new': isNew
      })
      resolve();
    }
    catch (err) {
      reject(err);
      console.log('err', { err });
    }
  });
}

export const firebaseUpdateReadNoti = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const dataNoti = storeGetState().dataRedux.firebaseNoti;
      const notiList = dataNoti.dataList;
      const readNotiList = notiList.filter(e => e.isNew);
      if (readNotiList.length) {
        let cnt = 0;
        const updatedDate = new Date();
        readNotiList.forEach(async (e) => {
          await handleUpdateFirebase('notification', e.firebaseKey, {
            'isNew': false,
            'updatedDate': updatedDate.getTime(),
            'displayUpdatedDate': displayDate(updatedDate, '/', DATE_TIME_FORMAT)
          });
          cnt++;
          if (cnt === readNotiList.length) {
            resolve();
          }
        })
      }
    }
    catch (err) {
      reject(err);
      console.log('err', { err });
    }
  });
}


export const firebaseSendOrderInquiry = (params = {}) => {
  return new Promise(async (resolve, reject) => {
    try {
      const userId = params.userId;
      const where = ['user_id', '==', userId]
      const qSnapNoti = await handleQueryFirebase('notification', {
        where
      });
      if (qSnapNoti.size === 0) {
        await handleAddFirebase('notification', new ModelUserNoti({
          userId
        }).firebase())
      }
      else {
        const qSnap = qSnapNoti.docs[0];
        await handleUpdateFirebase('notification', qSnap.id, {
          'order_inquiry.is_new': true
        })
      }
      resolve();
    }
    catch (err) {
      reject(err);
      console.log('err', { err });
    }
  });
}

export const handleWatchAllNoti = async () => {
  await handleWatchOrderInquiryNoti();
  await handleWatchNoti();
}

const setFirebaseNotiDataList = (qSnapNoti) => {
  const dataList = [];
  qSnapNoti.docs.forEach(qSnap => {
    let data = qSnap.data();
    data.firebaseKey = qSnap.id;
    data.firebasePath = qSnap.ref.parent.path;
    dataList.push(data);
  })
  const firebaseNoti = storeGetState().dataRedux.firebaseNoti;
  firebaseNoti.dataList = _.uniqBy(_.orderBy([...firebaseNoti.dataList, ...dataList], 'createdDate', 'desc'), 'createdDate');
  console.log('setFirebaseNotiDataList', _.cloneDeep(dataList), _.cloneDeep(firebaseNoti.dataList))
  storeDispatch(dataCreators.setDataRedux(DATA_REDUX_STATE_KEY.FIREBASE_NOTI, _.cloneDeep(firebaseNoti)));
  return dataList;
}

export const firebaseGetNotificationLoadMore = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const userAuth = getUserAuth();
      const firebaseNoti = storeGetState().dataRedux.firebaseNoti;
      const lastData = firebaseNoti.dataList[firebaseNoti.dataList.length - 1];
      console.log('lastData', lastData)
      const where = ['dealerAccount', '==', userAuth.dealerAccount];
      const qSnapNoti = await handleQueryFirebase(`notification`, {
        where,
        orderBy: 'createdDate',
        maxLength: 10,
        lastRef: lastData?.firebasePath,
        lastDocRef: lastData?.firebaseKey
      });
      const dataList = setFirebaseNotiDataList(qSnapNoti);
      resolve(dataList);
    }
    catch (err) {
      reject(err);
      console.log('err', { err });
    }
  });
}

const handleWatchNoti = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const userAuth = getUserAuth();
      const isAuthSuccess = getAuthSuccess();
      if (userAuth && isAuthSuccess) {
        qSnapNotiUnsub();
        const where = ['dealerAccount', '==', userAuth.dealerAccount];
        qSnapNotiUnsub = await handleQueryFirebase(`notification`, {
          where,
          orderBy: 'createdDate',
          maxLength: 10,
          callType: 'onSnapshot',
          callbackSuccess: async (qSnapNoti) => {
            setFirebaseNotiDataList(qSnapNoti);
            resolve();
          }
        });
      }
      else {
        resolve()
      }
    }
    catch (err) {
      reject(err);
      console.log('err', { err });
    }
  });
}

const handleWatchOrderInquiryNoti = () => {
  return new Promise(async (resolve, reject) => {
    try {
      let userAuth = getUserAuth();
      const isAuthSuccess = getAuthSuccess();
      if (userAuth && isAuthSuccess) {
        qSnapOrderInqueryNotiUnsub();
        let where = ['ad_id', '==', userAuth.adRefId];
        qSnapOrderInqueryNotiUnsub = await handleQueryFirebase('notification', {
          where,
          callType: 'onSnapshot',
          callbackSuccess: async (qSnapNoti) => {
            if (qSnapNoti.size === 0) {
              await handleAddFirebase('notification', new ModelAdNoti({
                adId: userAuth.adRefId
              }).firebase());
              console.log('handleWatchOrderInquiryNoti add', userAuth.adRefId)
            }
            else {
              const qSnap = qSnapNoti.docs[0];
              let data = qSnap.data();
              data.firebaseKey = qSnap.id;
              data.firebasePath = qSnap.ref.parent.path;
              data = new ModelAdNoti(data).data();
              console.log('handleWatchOrderInquiryNoti data', data);
              const firebaseNoti = storeGetState().dataRedux.firebaseNoti;
              firebaseNoti.data = data;
              storeDispatch(dataCreators.setDataRedux(DATA_REDUX_STATE_KEY.FIREBASE_NOTI, _.cloneDeep(firebaseNoti)));
            }
            resolve();
          }
        });
      }
      else {
        resolve()
      }
    }
    catch (err) {
      reject(err);
      console.log('err', { err });
    }
  });
}

export const firebaseAddNoti = (params = {}) => {
  return new Promise(async (resolve, reject) => {
    try {
      await handleSetFirebase('notification', params.createdDate.toString(), params);
      resolve();
    }
    catch (err) {
      reject(err);
      console.log('err', { err }, params);
    }
  });
}

export const firebaseAddNotiCMS = (params = {}) => {
  return new Promise(async (resolve, reject) => {
    try {
      await handleSetFirebase('notification_cms', params.createdDate.toString(), params);
      resolve();
    }
    catch (err) {
      reject(err);
      console.log('err', { err }, params);
    }
  });
}