
export class ModelWatermarkTemplateDetail {
  constructor(props = {}) {
    this.wtmTempId = props.wtm_temp_id;
    this.wtmTempName = props.wtm_temp_name;
    this.wtmTempCategory = props.wtm_temp_cate;
    this.productGrpName = props.product_grp_name;
    this.wtmTempDtBodyEn = props.wtm_temp_dt_body_en;
    this.wtmTempDtBodyTh = props.wtm_temp_dt_body_th;

    this.id = this.wtmTempId;
  }
}