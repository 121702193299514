import React from 'react';
import ClassNames from 'classnames'
import {
  Accordion,
  AccordionItem,
  AccordionItemTitle,
  AccordionItemBody
} from 'react-accessible-accordion';
import {
  ShapeContainer,
  Section,
  Grid,
  Field,
  Text,
  Button,
  TitleSection,
  SelectControl,
  Modal,
  AccordionControl,
} from './../../components'
import {
  VARIABLES,
  ICONS,
} from './../../themes'
import { getLanguage } from '../../helpers/functions/language';
import { ENUM } from '../../helpers/constants/enum';
import { getTransferHistoryList } from '../../helpers/services/transfer';
import { toast } from '../../helpers/functions/main';
import { DATA } from '../../helpers/constants/data';
import { isValidNumber, isNumber } from '../../helpers/functions/validation';
import _ from 'lodash';
import { WarrantyEnvConditionContainer } from '../../containers/WarrantyTemplateContainer/WarrantyEnvConditionContainer';
import { getUserAuthRedux } from '../../helpers/functions/auth';

export class WarrantyManageCondition extends React.Component {

  constructor(props) {
    super(props);
    // props.handleUpdateEnvDetail();
    const userAuth = getUserAuthRedux();
    const defaultWarrantyPeriodDetail = {
      corrosion: '',
      peelFlake: '',
      colorFade: '',
      dirtStain: '',
    };
    const warrantyPeriodList = props.data.productGroupList.filter(e => e.isWarrantyPeriodSelected);

    this.state = {
      defaultWarrantyPeriodDetail,
      userAuth,
      warrantyPeriodList: warrantyPeriodList.length ? warrantyPeriodList : [_.cloneDeep(defaultWarrantyPeriodDetail)],
    }
  }

  warrantyManageCondition = {
    isValidatePass: () => {
      const {
        data,
        handleCheckProductGroupTncError,
        onClickOpenWarrantyConditionNoPassModal,
      } = this.props;
      let isPass = true;
      let isAlertWarrantyPeriod = true;

      data.productGroupList = data.productGroupList.map((e, i) => {
        const dataDetail = handleCheckProductGroupTncError(e);
        this.warrantyManageCondition.handleUpdateProductGroupData(dataDetail);
        return dataDetail;
      });

      data.productGroupList.forEach((e, i) => {
        e.templateList.forEach((et, it) => {
          //alert warranty shorter
          if (e.isWarrantyPeriodSelected && isAlertWarrantyPeriod) {
            if (
              et.envDetail.peelFlake > et.envDetail.corrosion ||
              et.envDetail.colorFade > et.envDetail.corrosion ||
              et.envDetail.dirtStain > et.envDetail.corrosion
            ) {
              console.log(et.isWarrantyPeriodAppSelect, e[et.applicationForSelect], e.isRoofAndWall)
              if (et.isWarrantyPeriodAppSelect && e[et.applicationForSelect] && e.isRoofAndWall && data.projectCountry === ENUM.COUNTRY_TYPE.INDONESIA) {
                toast.error(getLanguage('message.warrantyMaximumYearExceed', ''));
                isAlertWarrantyPeriod = false;
              }
              else {
                if (it === 0) {
                  toast.error(getLanguage('message.warrantyMaximumYearExceed', ''));
                  isAlertWarrantyPeriod = false;
                }
              }
            }
          }
        });
      });

      data.productGroupList.forEach((e, i) => {
        if (isPass) {
          e.templateList.forEach((et, it) => {
            if (isPass && e[et.applicationForSelect]) {
              // CR: warranty condition
              if (data.isWarrantyConditionTemplate) {
                if (!et.warrantyConditionTemplate) {
                  isPass = false;
                }

                Object.keys(et.conditionError).forEach(keyc => {
                  if (isPass) {
                    isPass = !et.conditionError[keyc];
                  }
                })

                //check waiting approval
                if (isPass) {
                  et.questionList.forEach(etq => {
                    if (etq.isAnswerNoShowAlert && et.condition[etq.id] === false) {
                      isPass = false;
                      onClickOpenWarrantyConditionNoPassModal();
                    }
                  });
                }
              }
              else {
                Object.keys(et.condition).forEach(keyc => {
                  if (isPass) {
                    // console.log('condition', et.applicationForSelect, `${keyc}Error`, et.condition[`${keyc}Error`], et.condition[keyc], et.envDetailCondition.CONDITION);
                    isPass = !et.condition[`${keyc}Error`];
                  }
                })

                //check waiting approval
                if (isPass) {
                  Object.keys(et.condition).forEach(keyc => {
                    if (isPass && et.condition[keyc.replace('Error', '')] === false) {
                      isPass = false;
                      onClickOpenWarrantyConditionNoPassModal();
                    }
                  })
                }
              }
            }

            if (isPass) {
              //check warranty shorter
              if (e.isWarrantyPeriodSelected) {
                isPass = !e.isErrorWarrantyPeriod;
              }
            }
          });
        }
      });

      return isPass;
    },
    onClickSave: () => {
      const {
        onClickSave,
      } = this.props;
      if (this.warrantyManageCondition.isValidatePass()) {
        onClickSave();
      }
    },
    onClickAddWarrantyPeriod: () => {
      const {
        data,
      } = this.props;
      const {
        warrantyPeriodList,
        defaultWarrantyPeriodDetail,
      } = this.state;
      warrantyPeriodList.push(_.cloneDeep(defaultWarrantyPeriodDetail));
      this.setState({
        warrantyPeriodList,
      });
    },
    onClickRemoveWarrantyPeriod: (index) => {
      const {
        warrantyPeriodList,
        defaultWarrantyPeriodDetail,
      } = this.state;
      const dataGroup = _.cloneDeep(warrantyPeriodList[index]);
      warrantyPeriodList.splice(index, 1);
      if (warrantyPeriodList.length === 0) {
        warrantyPeriodList.push(_.cloneDeep(defaultWarrantyPeriodDetail));
      }
      this.setState({
        warrantyPeriodList
      }, () => {
        dataGroup.isWarrantyPeriodSelected = false;
        this.warrantyManageCondition.handleUpdateProductGroupData(dataGroup);
      })
    },
    handleUpdateProductGroupData: (dataGroup) => {
      const {
        data,
        handleUpdateProductGroupData,
      } = this.props;
      data.productGroupList = data.productGroupList.map(e => {
        if (e.productGroupName === dataGroup.productGroupName) {
          e = dataGroup;
        }
        return e;
      });
      handleUpdateProductGroupData(data.productGroupList);
    },
    handleCheckProductGroupWarrantyPeriodError: () => {
      const {
        warrantyPeriodList,
      } = this.state;
      this.setState({
        warrantyPeriodList: warrantyPeriodList.map(e => {
          let isErrorWarrantyPeriod = false;
          e.templateList = e.templateList.map(et => {
            et.isWarrantyCorPeriodError = false;
            et.isWarrantyPeelFlakePeriodError = false;
            et.isWarrantyColFadePeriodError = false;
            et.isWarrantyDirStainPeriodError = false;
            if (et.isWarrantyPeriodAppSelect && e[et.applicationForSelect]) {
              if (et.envDetailSelectMax.corrosion) {
                et.isWarrantyCorPeriodError = et.envDetail.corrosion > et.envDetailSelectMax.corrosion;
              }
              if (et.envDetailSelectMax.peelFlake) {
                et.isWarrantyPeelFlakePeriodError = (
                  et.envDetail.peelFlake > et.envDetailSelectMax.peelFlake ||
                  et.envDetail.peelFlake > et.envDetail.corrosion
                );
              }
              if (et.envDetailSelectMax.colorFade) {
                et.isWarrantyColFadePeriodError = (
                  et.envDetail.colorFade > et.envDetailSelectMax.colorFade ||
                  et.envDetail.colorFade > et.envDetail.corrosion
                );
              }
              if (et.envDetailSelectMax.dirtStain) {
                et.isWarrantyDirStainPeriodError = (
                  et.envDetail.dirtStain > et.envDetailSelectMax.dirtStain ||
                  et.envDetail.dirtStain > et.envDetail.corrosion
                );
              }
            }

            if (isErrorWarrantyPeriod === false) {
              isErrorWarrantyPeriod = (
                et.isWarrantyCorPeriodError ||
                et.isWarrantyPeelFlakePeriodError ||
                et.isWarrantyColFadePeriodError ||
                et.isWarrantyDirStainPeriodError
              );
            }
            return et;
          });

          e.isErrorWarrantyPeriod = isErrorWarrantyPeriod;

          this.warrantyManageCondition.handleUpdateProductGroupData(e);
          return e;
        }),
      });

    },
  }

  input = {
    onChangeSelectAllWarrantyPeriod: (ev) => {
      const {
        data,
      } = this.props;
      let {
        warrantyPeriodList,
        defaultWarrantyPeriodDetail
      } = this.state;
      const checked = ev.target.checked;
      warrantyPeriodList = warrantyPeriodList.filter(e => e.productGroupName);
      if (checked) {
        const warrantyPeriodNameList = warrantyPeriodList.map(e => e.productGroupName);
        data.productGroupList.forEach(e => {
          if (warrantyPeriodNameList.indexOf(e.productGroupName) === -1) {
            e.isWarrantyPeriodSelected = true;
            warrantyPeriodList.push(e);
            this.warrantyManageCondition.handleUpdateProductGroupData(e);
          }
        });
      }
      else {
        warrantyPeriodList.forEach(e => {
          e.isWarrantyPeriodSelected = false;
          e.templateList = e.templateList.map(et => {
            et = {
              ...et,
              ...defaultWarrantyPeriodDetail,
            }
            return et;
          })
          this.warrantyManageCondition.handleUpdateProductGroupData(e);
        });
        warrantyPeriodList = [_.cloneDeep(defaultWarrantyPeriodDetail)];
      }
      this.setState({
        warrantyPeriodList,
      })
    },
    onChangeWarrantyPeriodSelect: (ev, index) => {
      const {
        data,
      } = this.props;
      const {
        warrantyPeriodList,
      } = this.state;
      if (warrantyPeriodList[index].productGroupName) {
        warrantyPeriodList[index].isWarrantyPeriodSelected = false;
        this.warrantyManageCondition.handleUpdateProductGroupData(warrantyPeriodList[index]);
      }
      const dataGroup = ev.value;
      dataGroup.isWarrantyPeriodSelected = true;
      warrantyPeriodList[index] = dataGroup;
      this.setState({
        warrantyPeriodList,
      }, () => {
        this.warrantyManageCondition.handleUpdateProductGroupData(dataGroup);
      })
    },
    onChangeWarrantyPeriodInput: (ev, productGroupIndex, templateIndex) => {
      const {
        data,
      } = this.props;
      const {
        warrantyPeriodList,
      } = this.state;
      const name = ev.target.name;
      const value = ev.target.value;
      if (value.length && !isValidNumber(value)) {
        return;
      }
      const dataGroup = warrantyPeriodList[productGroupIndex];
      const dataDetail = dataGroup.templateList[templateIndex];

      dataDetail.envDetail[name] = parseInt(value) ? parseInt(value) : 1;

      dataGroup.templateList[templateIndex] = dataDetail;

      // CR: remove merged roof and wall for thai
      const isRoofAndWall = dataGroup[DATA.WARRANTY_APP_DETAIL_NAME_SELECT_LIST[0]] &&
        dataGroup[DATA.WARRANTY_APP_DETAIL_NAME_SELECT_LIST[1]];
      if (isRoofAndWall) {
        // if change envDetail wall then update roof
        // if change envDetail roof then update wall from props
        if (dataDetail.applicationForSelect === DATA.WARRANTY_APP_DETAIL_NAME_SELECT_LIST[1]) {
          dataGroup.templateList = dataGroup.templateList.map(e => {
            if (e.applicationForSelect === DATA.WARRANTY_APP_DETAIL_NAME_SELECT_LIST[0]) {
              e.envDetail[name] = dataDetail.envDetail[name];
            }
            return e;
          })
        }
      }

      warrantyPeriodList[productGroupIndex] = dataGroup;

      this.setState({
        warrantyPeriodList,
      }, () => {
        this.warrantyManageCondition.handleCheckProductGroupWarrantyPeriodError();
      })
    },
    onChangeWarrantyPeriodAppSelect: (ev, productGroupIndex, applicationForSelect) => {
      const {
        data,
      } = this.props;
      const {
        warrantyPeriodList,
      } = this.state;
      const checked = ev.target.checked;

      const dataGroup = warrantyPeriodList[productGroupIndex];
      dataGroup.templateList = dataGroup.templateList.map(e => {
        if (e.applicationForSelect === applicationForSelect) {
          e.isWarrantyPeriodAppSelect = checked;
        }
        return e;
      });

      warrantyPeriodList[productGroupIndex] = dataGroup;
      this.setState({
        warrantyPeriodList,
      }, () => {
        this.warrantyManageCondition.handleCheckProductGroupWarrantyPeriodError();
      })
    },
  }

  render() {
    const {
      data,
      onClickBack,
      onClickSaveDraft,
      onChangeDataApplicationInput,
      handleUpdateProductGroupData
    } = this.props;
    const {
      warrantyPeriodList,
      userAuth
    } = this.state
    // const condition = data.condition;

    return (
      <React.Fragment>
        <ShapeContainer
          ui='contentStep'
          justify='space-between'
        >
          <Section spacingBottom='45'>
            <Section spacingBottom='45'>
              <TitleSection name={getLanguage('warrantyManage.form.condition.title', '')} />
            </Section>
            <AccordionControl outline>
              <Accordion accordion>
                <WarrantyEnvConditionContainer
                  onChangeDataApplicationInput={onChangeDataApplicationInput}
                  handleUpdateProductGroupData={handleUpdateProductGroupData}
                  data={data}
                />
                {
                  userAuth.addressType !== ENUM.COUNTRY_TYPE.INDONESIA &&
                  <AccordionItem
                    className={ClassNames(
                      'accordion__item',
                      { 'is-error': warrantyPeriodList.filter(e => e.isWarrantyPeriodSelected && e.isErrorWarrantyPeriod).length > 0 },
                    )}
                  >
                    <AccordionItemTitle>
                      <AccordionControl.ButtonOutline
                        name={getLanguage('warrantyManage.form.condition.warrantyPeriods', '')}
                      />
                    </AccordionItemTitle>
                    <AccordionItemBody>
                      <ShapeContainer
                        fluid
                        paddingTop='30'
                        paddingBottom='10'
                        paddingHorizontal='30'
                        bgColor={VARIABLES.COLORS.GRAY_1}
                        borderRadius='5'
                      >
                        <Section spacingBottom='15'>
                          <Grid gutter='30' justify='space-between'>
                            <Grid.Column>
                              <Text regular16 color={VARIABLES.COLORS.BLACK}>{getLanguage('warrantyManage.form.condition.warrantyPeriodsCondition', '')}</Text>
                            </Grid.Column>
                            <Grid.Column>
                              <Field
                                checked={data.productGroupList.filter(e => e.isWarrantyPeriodSelected).length === data.productGroupList.length}
                                ui='checkboxTickCondition'
                                checkboxLabel={getLanguage('warrantyManage.form.condition.warrantyPeriodsSelectLabel', '')}
                                onChange={this.input.onChangeSelectAllWarrantyPeriod}
                              />
                            </Grid.Column>
                          </Grid>
                        </Section>
                        {warrantyPeriodList.map((e, i) => {
                          return (
                            <Section spacingBottom='20' key={i}>
                              <ShapeContainer
                                fluid
                                padding='15'
                                bgColor={VARIABLES.COLORS.WHITE}
                                borderWidth='1'
                                borderStyle='solid'
                                borderColor={VARIABLES.COLORS.GRAY_29}
                                borderRadius='5'
                              >
                                <Grid gutter='15' justify='space-between'>
                                  <Grid.Column flex='1'>
                                    <Grid gutter='15'>
                                      <Grid.Column grid='12'>
                                        <Grid gutter='20' align='center'>
                                          <Grid.Column grid='5'>
                                            <SelectControl
                                              placeholder={getLanguage('warrantyManage.form.condition.warrantyPeriodsSelectPlaceholder', '')}
                                              // name={}
                                              value={e.productGroupName ? {
                                                label: e.productGroupName,
                                              } : null}
                                              onChange={(ev) => {
                                                this.input.onChangeWarrantyPeriodSelect(ev, i);
                                              }}
                                              options={data.productGroupList.filter(ep => ep.isWarrantyPeriodSelected === false).map((ep, ip) => {
                                                return {
                                                  value: ep,
                                                  label: ep.productGroupName,
                                                }
                                              })}
                                            />
                                          </Grid.Column>
                                          {e.isRoofAndWall && data.projectCountry === ENUM.COUNTRY_TYPE.INDONESIA &&
                                            <Grid.Column>
                                              <Grid gutter='15'>
                                                <Grid.Column><Text bold12 color={VARIABLES.COLORS.BLACK}>{getLanguage('warrantyManage.form.condition.warrantyPeriodsConditionAttribute.PleaseSelectApplication', '')}</Text></Grid.Column>
                                                <Grid.Column>
                                                  <Field name={'roof'}
                                                    checked={!!(e.templateList.filter(et => et.applicationForSelect === DATA.WARRANTY_APP_DETAIL_NAME_SELECT_LIST[0])[0] || {}).isWarrantyPeriodAppSelect}
                                                    ui='checkboxTickFillSmallest'
                                                    checkboxLabel={getLanguage('warrantyManage.form.condition.warrantyPeriodsConditionAttribute.roof', '')}
                                                    onChange={(ev) => {
                                                      this.input.onChangeWarrantyPeriodAppSelect(ev, i, DATA.WARRANTY_APP_DETAIL_NAME_SELECT_LIST[0])
                                                    }}
                                                  />
                                                </Grid.Column>
                                                <Grid.Column>
                                                  <Field name={'wall'}
                                                    checked={!!(e.templateList.filter(et => et.applicationForSelect === DATA.WARRANTY_APP_DETAIL_NAME_SELECT_LIST[1])[0] || {}).isWarrantyPeriodAppSelect}
                                                    ui='checkboxTickFillSmallest'
                                                    checkboxLabel={getLanguage('warrantyManage.form.condition.warrantyPeriodsConditionAttribute.wall', '')}
                                                    onChange={(ev) => {
                                                      this.input.onChangeWarrantyPeriodAppSelect(ev, i, DATA.WARRANTY_APP_DETAIL_NAME_SELECT_LIST[1])
                                                    }}
                                                  />
                                                </Grid.Column>
                                              </Grid>
                                            </Grid.Column>
                                          }
                                        </Grid>
                                      </Grid.Column>
                                      {e.isWarrantyPeriodSelected &&
                                        ((e.isRoofAndWall && data.projectCountry === ENUM.COUNTRY_TYPE.INDONESIA) ?
                                          e.templateList
                                          :
                                          [e.templateList.filter(et => e[et.applicationForSelect])[0]]
                                        ).map((et, it) => {
                                          let index = -1;
                                          if (e.isRoofAndWall && data.projectCountry === ENUM.COUNTRY_TYPE.INDONESIA) {
                                            index = it;
                                          }
                                          else {
                                            e.templateList.forEach((etf, itf) => {
                                              if (e[etf.applicationForSelect] && index === -1) {
                                                index = itf;
                                              }
                                            });
                                          }

                                          if ((et.isWarrantyPeriodAppSelect || e.isRoofAndWall === false) && e[et.applicationForSelect]) {
                                            return (
                                              <Grid.Column key={it}>
                                                <Section paddingTop='10'>
                                                  <ShapeContainer
                                                    fluid
                                                    bgColor={VARIABLES.COLORS.GRAY_30}
                                                    borderRadius='5'
                                                  >
                                                    <Grid>
                                                      {e.isRoofAndWall && data.projectCountry === ENUM.COUNTRY_TYPE.INDONESIA &&
                                                        <Grid.Column flex='none'>
                                                          <Section className='is-short-warranty-label'>
                                                            <Text top='1' bold14 color={VARIABLES.COLORS.BLACK}>
                                                              {et.applicationForSelect === DATA.WARRANTY_APP_DETAIL_NAME_SELECT_LIST[0] &&
                                                                getLanguage('warrantyManage.form.condition.warrantyPeriodsConditionAttribute.roof', '')}
                                                              {et.applicationForSelect === DATA.WARRANTY_APP_DETAIL_NAME_SELECT_LIST[1] &&
                                                                getLanguage('warrantyManage.form.condition.warrantyPeriodsConditionAttribute.wall', '')}
                                                            </Text>
                                                          </Section>
                                                        </Grid.Column>
                                                      }
                                                      <Grid.Column flex='1'>
                                                        <Section paddingVertical='8' paddingHorizontal='30'>
                                                          <Grid gutter='30' gutterVertical='5'>
                                                            {et.envDetailSelectMax.corrosion > 0 &&
                                                              <Grid.Column>
                                                                <Grid gutter='5' align='center'>
                                                                  <Grid.Column>
                                                                    <Section width='70'>
                                                                      <Text bold12 color={VARIABLES.COLORS.BLACK}>{getLanguage('warrantyManage.form.condition.warrantyPeriodsConditionAttribute.corrosion', '')}</Text>
                                                                    </Section>
                                                                  </Grid.Column>
                                                                  <Grid.Column>
                                                                    <Field name='corrosion'
                                                                      ui='blockLabelVerticalSmall'
                                                                      error={et.isWarrantyCorPeriodError}
                                                                      width='50'
                                                                      textAlign='center'
                                                                      value={et.envDetail.corrosion}
                                                                      onChange={(ev) => {
                                                                        this.input.onChangeWarrantyPeriodInput(ev, i, index)
                                                                      }}
                                                                    />
                                                                  </Grid.Column>
                                                                  <Grid.Column>
                                                                    <Section width='53' height='36' paddingLeft='2'>
                                                                      <Text regular11 top={et.envDetailSelectMax.corrosion > 0 ? '1' : '8'} color={et.isWarrantyCorPeriodError ? VARIABLES.COLORS.RED_4 : VARIABLES.COLORS.BLACK}>Year</Text>
                                                                      {et.envDetailSelectMax.corrosion > 0 &&
                                                                        <Section width='55'>
                                                                          <Text regular9 top='-6' color={et.isWarrantyCorPeriodError ? VARIABLES.COLORS.RED_4 : VARIABLES.COLORS.BLACK}>(1 - {et.envDetailSelectMax.corrosion} Year)</Text>
                                                                        </Section>
                                                                      }
                                                                    </Section>
                                                                  </Grid.Column>
                                                                </Grid>
                                                              </Grid.Column>
                                                            }
                                                            {et.envDetailSelectMax.peelFlake > 0 &&
                                                              <Grid.Column>
                                                                <Grid gutter='5' align='center'>
                                                                  <Grid.Column>
                                                                    <Section width='70'>
                                                                      <Text bold12 color={VARIABLES.COLORS.BLACK}>{getLanguage('warrantyManage.form.condition.warrantyPeriodsConditionAttribute.peelFlake', '')}</Text>
                                                                    </Section>
                                                                  </Grid.Column>
                                                                  <Grid.Column>
                                                                    <Field name='peelFlake'
                                                                      ui='blockLabelVerticalSmall'
                                                                      error={et.isWarrantyPeelFlakePeriodError}
                                                                      width='50'
                                                                      textAlign='center'
                                                                      value={et.envDetail.peelFlake}
                                                                      onChange={(ev) => {
                                                                        this.input.onChangeWarrantyPeriodInput(ev, i, index)
                                                                      }}
                                                                    />
                                                                  </Grid.Column>
                                                                  <Grid.Column>
                                                                    <Section width='53' height='36' paddingLeft='2'>
                                                                      <Text regular11 top={et.envDetailSelectMax.peelFlake > 0 ? '1' : '8'} color={et.isWarrantyPeelFlakePeriodError ? VARIABLES.COLORS.RED_4 : VARIABLES.COLORS.BLACK}>Year</Text>
                                                                      {et.envDetailSelectMax.peelFlake > 0 &&
                                                                        <Section width='55'>
                                                                          <Text regular9 top='-6' color={et.isWarrantyPeelFlakePeriodError ? VARIABLES.COLORS.RED_4 : VARIABLES.COLORS.BLACK}>(1 - {et.envDetailSelectMax.peelFlake} Year)</Text>
                                                                        </Section>
                                                                      }
                                                                    </Section>
                                                                  </Grid.Column>
                                                                </Grid>
                                                              </Grid.Column>
                                                            }
                                                            {et.envDetailSelectMax.colorFade > 0 &&
                                                              <Grid.Column>
                                                                <Grid gutter='5' align='center'>
                                                                  <Grid.Column>
                                                                    <Section width='70'>
                                                                      <Text bold12 color={VARIABLES.COLORS.BLACK}>{getLanguage('warrantyManage.form.condition.warrantyPeriodsConditionAttribute.colorFade', '')}</Text>
                                                                    </Section>
                                                                  </Grid.Column>
                                                                  <Grid.Column>
                                                                    <Field name='colorFade'
                                                                      ui='blockLabelVerticalSmall'
                                                                      error={et.isWarrantyColFadePeriodError}
                                                                      width='50'
                                                                      textAlign='center'
                                                                      value={et.envDetail.colorFade}
                                                                      onChange={(ev) => {
                                                                        this.input.onChangeWarrantyPeriodInput(ev, i, index)
                                                                      }}
                                                                    />
                                                                  </Grid.Column>
                                                                  <Grid.Column>
                                                                    <Section width='53' height='36' paddingLeft='2'>
                                                                      <Text regular11 top={et.envDetailSelectMax.colorFade > 0 ? '1' : '8'} color={et.isWarrantyColFadePeriodError ? VARIABLES.COLORS.RED_4 : VARIABLES.COLORS.BLACK}>Year</Text>
                                                                      {et.envDetailSelectMax.colorFade > 0 &&
                                                                        <Section width='55'>
                                                                          <Text regular9 top='-6' color={et.isWarrantyColFadePeriodError ? VARIABLES.COLORS.RED_4 : VARIABLES.COLORS.BLACK}>(1 - {et.envDetailSelectMax.colorFade} Year)</Text>
                                                                        </Section>
                                                                      }
                                                                    </Section>
                                                                  </Grid.Column>
                                                                </Grid>
                                                              </Grid.Column>
                                                            }
                                                            {et.envDetailSelectMax.dirtStain > 0 &&
                                                              <Grid.Column>
                                                                <Grid gutter='5' align='center'>
                                                                  <Grid.Column>
                                                                    <Section width='70'>
                                                                      <Text bold12 color={VARIABLES.COLORS.BLACK}>{getLanguage('warrantyManage.form.condition.warrantyPeriodsConditionAttribute.dirtStain', '')}</Text>
                                                                    </Section>
                                                                  </Grid.Column>
                                                                  <Grid.Column>
                                                                    <Field name='dirtStain'
                                                                      ui='blockLabelVerticalSmall'
                                                                      error={et.isWarrantyDirStainPeriodError}
                                                                      width='50'
                                                                      textAlign='center'
                                                                      value={et.envDetail.dirtStain}
                                                                      onChange={(ev) => {
                                                                        this.input.onChangeWarrantyPeriodInput(ev, i, index)
                                                                      }}
                                                                    />
                                                                  </Grid.Column>
                                                                  <Grid.Column>
                                                                    <Section width='53' height='36' paddingLeft='2'>
                                                                      <Text regular11 top={et.envDetailSelectMax.dirtStain > 0 ? '1' : '8'} color={et.isWarrantyDirStainPeriodError ? VARIABLES.COLORS.RED_4 : VARIABLES.COLORS.BLACK}>Year</Text>
                                                                      {et.envDetailSelectMax.dirtStain > 0 &&
                                                                        <Section width='55'>
                                                                          <Text regular9 top='-6' color={et.isWarrantyDirStainPeriodError ? VARIABLES.COLORS.RED_4 : VARIABLES.COLORS.BLACK}>(1 - {et.envDetailSelectMax.dirtStain} Year)</Text>
                                                                        </Section>
                                                                      }
                                                                    </Section>
                                                                  </Grid.Column>
                                                                </Grid>
                                                              </Grid.Column>
                                                            }
                                                          </Grid>
                                                        </Section>
                                                      </Grid.Column>
                                                    </Grid>
                                                  </ShapeContainer>
                                                </Section>
                                              </Grid.Column>
                                            )
                                          }
                                          return undefined;
                                        })
                                      }
                                    </Grid>
                                  </Grid.Column>
                                  <Grid.Column flex='none'>
                                    <Section width='16' paddingTop='5'>
                                      {e.isWarrantyPeriodSelected &&
                                        <React.Fragment>
                                          {i === warrantyPeriodList.length - 1 && data.productGroupList.filter(ep => ep.isWarrantyPeriodSelected === false).length ?
                                            <div onClick={this.warrantyManageCondition.onClickAddWarrantyPeriod} className='is-pointer'>
                                              <img src={ICONS['ic-add-outline.svg']} alt='Icon' />
                                            </div>
                                            :
                                            <div onClick={() => { this.warrantyManageCondition.onClickRemoveWarrantyPeriod(i); }} className='is-pointer'>
                                              <img src={ICONS['ic-delete-outline.svg']} alt='Icon' />
                                            </div>
                                          }
                                        </React.Fragment>
                                      }
                                    </Section>
                                  </Grid.Column>
                                </Grid>
                              </ShapeContainer>
                            </Section>
                          )
                        })}
                      </ShapeContainer>
                    </AccordionItemBody>
                  </AccordionItem>
                }
              </Accordion>
            </AccordionControl>
          </Section>

          <Section>
            <Grid
              gutter='15'
              gutterVertical='15'
              justify='flex-end'
            >
              <React.Fragment>
                <Grid.Column>
                  <Button
                    gray
                    ui='back'
                    name={getLanguage('field.buttonBack', '')}
                    onClick={onClickBack}
                  />
                </Grid.Column>
                {data.isShowSaveDraft &&
                  <Grid.Column>
                    <Button
                      lightBlue
                      name={getLanguage('field.buttonSaveAsDraft', '')}
                      onClick={onClickSaveDraft}
                    />
                  </Grid.Column>
                }
                <Grid.Column>
                  <Button
                    blue
                    name={getLanguage('field.buttonSaveAndContinue', '')}
                    width={VARIABLES.BUTTON.WIDTHS.W_255}
                    onClick={this.warrantyManageCondition.onClickSave}
                  />
                </Grid.Column>
              </React.Fragment>
            </Grid>
          </Section>
        </ShapeContainer>
      </React.Fragment>
    )
  }
}
