import React from 'react'
import {
  ShapeContainer,
  Section,
  Breadcrumb,
  TitlePage,
  TitleSection,
  Grid,
  Button,
  Text,
  Step,
  Field
} from './../../components'
import {
  VARIABLES,
  ICONS,
} from './../../themes'
import { ROUTE_PATH } from '../../helpers/constants/route';
import { ENUM } from '../../helpers/constants/enum';
import { storeGetState } from '../../stores';
import { MAIN_REDUX_STATE_KEY } from '../../actions/mainAction';
import { toast } from '../../helpers/functions/main';
import _ from 'lodash';
import { getLanguage } from '../../helpers/functions/language';
import { getCountryPhone } from '../../helpers/functions/data';
import { isValidNumber, isValidEmail, isValidResponse, isValidPhoneNumber } from '../../helpers/functions/validation';
import { setSideMenuActive, redirect, getRouteParams, getRouteData } from '../../helpers/functions/route';
import { getAdRfManageParams } from '../../helpers/services/transfer';
import { transferController } from '../../apiService/apiController/transferService';
import { DATA } from '../../helpers/constants/data';

export class AuthorisedDealerAndRollFormerManageScreen extends React.Component {

  constructor(props) {
    super(props);
    const dataId = parseInt(getRouteParams(props, 'id'));
    const defaultData = {
      company: '',
      address: '',
      email: '',
      phone: '',
      customerType: 2,
      phoneCountry: getCountryPhone(),
    };
    let data = {
      ...defaultData,
      ...props.data,
    };
    setSideMenuActive(props);
    this.state = {
      data,
      dataId,
      AdAndToko: false,
    }
  }

  customerInfo = {
    isValidatePass: () => {
      const {
        data,
      } = this.state;
      let isPass = true;
      if (
        !data.company.length ||
        !data.address.length ||
        !data.email.length ||
        !data.phone.length
      ) {
        toast.error(getLanguage('message.pleaseInsertAllRequiredField', ''));
        isPass = false;
      }

      if (!isValidEmail(data.email)) {
        toast.error(getLanguage('message.wrongEmailFormat', ''));
        isPass = false;
      }

      DATA.PHONE_PREFIX_LIST.forEach(e => {
        if (data.phoneCountry.value === e.value) {
          if (e.lengthList.indexOf(data.phone.length) === -1) {
            toast.error(getLanguage('message.incorrectPhoneNumberLength', ''));
            isPass = false;
          }
        }
      });
      return isPass;
    },
    handleSave: async () => {
      const {
        data,
      } = this.state;

      let params = getAdRfManageParams(data);
      console.log('params', params)
      // const res = await transferController().createAD(params);

      // if (isValidResponse(res)) {
      //   toast.success(getLanguage('message.saveCompleted', ''));
      //   redirect(ROUTE_PATH.COIL_TRANSFER.LINK);
      // } else {
      //   toast.error(res.message);
      // }
    },
    onClickSave: () => {
      if (this.customerInfo.isValidatePass()) {
        this.customerInfo.handleSave();
      }
    },
  }

  input = {
    onChangeDataInput: (ev) => {
      const {
        data,
      } = this.state;
      const name = ev.target.name;
      const value = ev.target.value;
      if (name === 'phone') {
        if (value.length && !isValidPhoneNumber(value)) {
          return;
        }
      }

      if (name === 'phone') {
        if (value.length && !isValidPhoneNumber(value)) {
          return;
        }
      }

      data[name] = value;
      // console.log('data = ', data);
      this.setState({
        data,
      })
    },
    onChangePhoneCountry: (selected, target) => {
      const {
        data,
      } = this.state;
      data[target] = selected;
      this.setState({
        data,
      })
    },
    onChangeCustomerType: (ev, value) => {
      const {
        data,
      } = this.state;
      data[ev.target.name] = value;
      this.setState({
        data,
      })
    }
  }

  render() {
    // const {
    //   onClickBack,
    //   userAuth,
    // } = this.props;
    const {
      data,
      AdAndToko,
    } = this.state;

    return (
      <React.Fragment>
        <Breadcrumb>
          <Breadcrumb.List
            link
            name={getLanguage('adAndRfManage.breadcrumb1', '')}
            onClick={() => {
              redirect(ROUTE_PATH.HOME.LINK)
            }}
          />
          <Breadcrumb.List name='/' />
          <Breadcrumb.List
            link
            name={getLanguage('adAndRfManage.breadcrumb2', '')}
          // onClick={() => {
          //   redirect(ROUTE_PATH.INVENTORY_INDEXES.LINK)
          // }}
          />
          <Breadcrumb.List name='/' />
          <Breadcrumb.List
            link
            name={getLanguage('adAndRfManage.breadcrumb3', '')}
            onClick={() => {
              redirect(ROUTE_PATH.COIL_TRANSFER.LINK)
            }}
          />
          <Breadcrumb.List name='/' />
          <Breadcrumb.List name={AdAndToko ? getLanguage('adAndRfManage.breadcrumb4_1', '') : getLanguage('adAndRfManage.breadcrumb4_2', '')} />
        </Breadcrumb>
        <Section ui='content'>
          <TitlePage
            src={ICONS['ic-page-inventory.svg']}
            name={AdAndToko ? getLanguage('adAndRfManage.title_1', '') : getLanguage('adAndRfManage.title_2', '')}
          />
          <Step label>
            <Step.Number
              active
              number='1'
              label={getLanguage('adAndRfManage.step.regis', '')}
            />
          </Step>
          <ShapeContainer ui='contentStep'>
            <Section spacingBottom='40'>
              <TitleSection name={getLanguage('adAndRfManage.form.regis.title', '')} />
            </Section>
            <Section spacingBottom='30'>
              <Grid>
                <Grid.ResponsiveColumn ui='fieldText'>
                  <Field
                    fluid
                    required
                    // error
                    ui='blockLabelVertical'
                    label={getLanguage('field.company', '')}
                    placeholder={getLanguage('field.companyPlaceholder', '')}
                    name='company'
                    value={data.company}
                    onChange={this.input.onChangeDataInput}
                  />
                </Grid.ResponsiveColumn>
              </Grid>
            </Section>
            <Section spacingBottom='30'>
              <Grid>
                <Grid.Column
                  gridTablet='12'
                  gridLaptop='8'
                  gridLargeDesktop='6'
                >
                  <Field
                    fluid
                    required
                    // error
                    ui='blockLabelVerticalTextarea'
                    label={getLanguage('field.address', '')}
                    placeholder={getLanguage('field.addressPlaceholder', '')}
                    height='130'
                    name='address'
                    value={data.address}
                    onChange={this.input.onChangeDataInput}
                  />
                </Grid.Column>
              </Grid>
            </Section>
            <Section spacingBottom='30'>
              <Grid>
                <Grid.ResponsiveColumn ui='fieldText'>
                  <Field
                    fluid
                    required
                    // error
                    ui='blockLabelVertical'
                    label={getLanguage('field.email', '')}
                    placeholder={getLanguage('field.emailPlaceholder', '')}
                    name='email'
                    value={data.email}
                    onChange={this.input.onChangeDataInput}
                  />
                </Grid.ResponsiveColumn>
              </Grid>
            </Section>
            <Section spacingBottom='30'>
              <Grid>
                <Grid.ResponsiveColumn ui='fieldText'>
                  <Field name='phone'
                    fluid
                    required
                    // error
                    ui='blockLabelVerticalPhone'
                    label={getLanguage('field.phoneNo', '')}
                    placeholder={getLanguage('field.phoneNoPlaceholder', '')}
                    message={
                      <Section
                        ui='hintBottomField'
                        hint={getLanguage('field.phoneNoHint', '')}
                      />
                    }
                    value={data.phone}
                    onChange={this.input.onChangeDataInput}
                    selectedPhoneCountry={data.phoneCountry}
                    onChangePhoneCountry={selected => {
                      this.input.onChangePhoneCountry(selected, 'phoneCountry')
                    }}
                  />
                </Grid.ResponsiveColumn>
              </Grid>
            </Section>
            <Section spacingBottom='45'>
              <Section spacingBottom='15'>
                <Grid gutter='5'>
                  <Grid.Column>
                    <Text
                      regular14
                      color={VARIABLES.COLORS.GRAY_19}
                    >
                      {getLanguage('adAndRfManage.form.regis.customerType', '')}
                    </Text>
                  </Grid.Column>
                  <Grid.Column>
                    <ShapeContainer ui='required' />
                  </Grid.Column>
                </Grid>
              </Section>
              <Section spacingBottom='15'>
                <Field
                  checked={data.customerType === 2}
                  ui='radio'
                  radioLabel={getLanguage('adAndRfManage.form.regis.checkbox.rollFormer', '')}
                  name='customerType'
                  onChange={(ev) => {
                    this.input.onChangeCustomerType(ev, 2)
                  }}
                />
              </Section>
              <Section>
                <Field
                  checked={data.customerType === 1}
                  ui='radio'
                  radioLabel={getLanguage('adAndRfManage.form.regis.checkbox.authorisedDealer', '')}
                  name='customerType'
                  onChange={(ev) => {
                    this.input.onChangeCustomerType(ev, 1)
                  }}
                />
              </Section>
            </Section>
            <Grid
              gutter='15'
              justify='flex-end'
            >
              <Grid.Column>
                <Button
                  gray
                  ui='back'
                  name={getLanguage('field.buttonBack', '')}
                  onClick={() => {
                    redirect(ROUTE_PATH.COIL_TRANSFER.LINK)
                  }}
                />
              </Grid.Column>
              <Grid.Column>
                <Button
                  blue
                  name={getLanguage('field.buttonSubmit', '')}
                  width={VARIABLES.BUTTON.WIDTHS.W_200}
                  onClick={() => {
                    this.customerInfo.onClickSave()
                  }}
                />
              </Grid.Column>
            </Grid>
          </ShapeContainer>
        </Section>
      </React.Fragment>
    )
  }
}
