import React from 'react'
import {
  ShapeContainer,
  Section,
  Breadcrumb,
  TitlePage,
  TitleSection,
  Grid,
  Image,
  Text,
  Button,
  SelectControl,
  Field
} from './../../components'
import {
  ModalCustomerProfileContainerConnected
} from './../../containers/ModalContainer'
import {
  VARIABLES,
  ICONS,
  CONTENTS
} from './../../themes'
import { setSideMenuActive, getRouteData } from '../../helpers/functions/route';
import { ROUTE_PATH } from '../../helpers/constants/route';
import { redirect } from '../../helpers/functions/route';
import { CustomerInfoContainerConnected } from '../../containers/CustomerInfoContainer';
import { CustomerContactInfoContainerConnected } from '../../containers/CustomerContactInfoContainer';
import _ from 'lodash';
import { getLanguage } from '../../helpers/functions/language';
import { getUserAuthRedux } from '../../helpers/functions/auth';

export class RedemptionScreen extends React.Component {

  constructor(props) {
    super(props);
    setSideMenuActive(props);
    let data = getRouteData(props, 'data');
    this.state = {
      isOpenModalSelectCustomer: false,
      customerData: data,
    }
  }

  redemption = {
    handleClearCustomer: () => {
      this.setState({
        customerData: undefined,
        selectSalePerson: undefined,
      })
    },
    onChangeSelectSalePerson: (selectSalePerson) => {
      this.setState({
        selectSalePerson
      })
    },
    handleSetSelectSalePerson: (dataList) => {
      if (dataList.length) {
        const userAuth = getUserAuthRedux();
        const saleUser = dataList.filter(e => {
          return e.data.sfid === userAuth.sfid
        })[0];
        if (saleUser) {
          this.setState({
            selectSalePerson: saleUser
          });
        }
        else {
          this.setState({
            selectSalePerson: dataList[0]
          });
        }
      }
    },
  }

  modal = {
    onOpenModalSelectCustomer: () => {
      this.setState({
        isOpenModalSelectCustomer: true
      })
    },
    onClickCloseModalSelectCustomer: () => {
      this.setState({
        isOpenModalSelectCustomer: false
      })
    },
    onClickSubmitCustomer: (data) => {
      this.setState({
        customerData: undefined,
      }, () => {
        this.setState({
          isOpenModalSelectCustomer: false,
          customerData: data,
        })
      })
    },
  }

  render() {
    const {
      isOpenModalSelectCustomer,
      customerData,
      selectSalePerson
    } = this.state

    return (
      <React.Fragment>
        <Breadcrumb>
          <Breadcrumb.List
            link
            name={getLanguage('redemption.breadcrumb1', '')}
            onClick={() => {
              redirect(ROUTE_PATH.HOME.LINK)
            }}
          />
          <Breadcrumb.List name='/' />
          <Breadcrumb.List
            link
            name={getLanguage('redemption.breadcrumb2', '')}
          // onClick={() => {
          //   redirect(ROUTE_PATH.REWARDS.LINK)
          // }}
          />
          <Breadcrumb.List name='/' />
          <Breadcrumb.List name={getLanguage('redemption.breadcrumb3', '')} />
        </Breadcrumb>
        <Section ui='content'>
          <Section spacingBottom='35'>
            <TitlePage
              src={ICONS['ic-page-rewards.svg']}
              name={getLanguage('redemption.title', '')}
            />
          </Section>
          <ShapeContainer ui='content'>
            {
              !!customerData ?
                <React.Fragment>
                  <CustomerInfoContainerConnected isRedemption
                    data={customerData}
                    selectSalePerson={selectSalePerson}
                    onChangeSelectSalePerson={this.redemption.onChangeSelectSalePerson}
                    onClickSelectCustomer={this.modal.onOpenModalSelectCustomer}
                    handleSetSelectSalePerson={this.redemption.handleSetSelectSalePerson}
                  />
                  <CustomerContactInfoContainerConnected
                    data={customerData}
                    selectSalePerson={selectSalePerson}
                    onClickBack={this.redemption.handleClearCustomer}
                  />
                </React.Fragment>
                :
                <React.Fragment>
                  <TitleSection name={getLanguage('redemption.form.customerInfo.title', '')} />
                  <Section spacingTop='70'>
                    <Section
                      spacingBottom='20'
                      justify='center'
                    >
                      <Image ui='iconBlankCustomer' />
                    </Section>
                    <Section
                      spacingBottom='20'
                      justify='center'
                    >
                      <Text
                        regular14
                        color={VARIABLES.COLORS.BLACK}
                      >
                        {getLanguage('redemption.form.customerInfo.customerBlank', '')}
                      </Text>
                    </Section>
                    <Section justify='center'>
                      <Button
                        blue
                        ui='frontIcon'
                        name={getLanguage('redemption.form.customerInfo.buttonSelectCustomer', '')}
                        iconWidth='22'
                        iconHeight='18'
                        iconSrc={ICONS['ic-select-customer.svg']}
                        width={VARIABLES.BUTTON.WIDTHS.W_200}
                        onClick={this.modal.onOpenModalSelectCustomer}
                      />
                    </Section>
                  </Section>
                </React.Fragment>
            }
          </ShapeContainer>
        </Section>
        {
          isOpenModalSelectCustomer &&
          <ModalCustomerProfileContainerConnected isOpenModal
            customerData={customerData}
            onClickClose={this.modal.onClickCloseModalSelectCustomer}
            onClickSave={this.modal.onClickSubmitCustomer}
          />
        }
      </React.Fragment >
    )
  }
}
