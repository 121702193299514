import React from 'react'
import ClassNames from 'classnames'
import PropTypes from 'prop-types'
import {
  FieldBaseWrapper
} from './styled'
import {
  default as VARIABLES
} from './../../../themes/styles/bases/variables'
// import {
//   default as TYPOGRAPHYS
// } from './../../../themes/styles/bases/typographys'

/**
 * FieldBase description:
 * - base component Field
 */

export class FieldBase extends React.PureComponent {
  render () {
    const {
      // Option
      children,
      className,
      // Default
      label,
      labelFontStyle,
      labelFontColor,
      labelAlign,
      labelWidth,
      labelSpacingTop,
      labelSpacingRight,
      fluid,
      error,
      success,
      disabled,
      width,
      height,
      paddingTop,
      paddingRight,
      paddingBottom,
      paddingLeft,
      paddingHorizontal,
      paddingVertical,
      bgColor,
      borderWidth,
      borderStyle,
      borderColor,
      borderBottomWidth,
      borderBottomStyle,
      borderBottomColor,
      borderRadius,
      fontStyle,
      fontColor,
      fontLineHeight,
      textAlign,
      placeholder,
      placeholderColor,
      errorColor,
      successColor,
      disabledColor,
      fieldIcon,
      fieldIconWidth,
      fieldIconSpacingLeft,
      fieldIconSpacingRight,
      fieldIconActive,
      onClickFieldIcon,
      type,
      inputTextType,
      checkboxSize,
      checkboxBgColor,
      checkboxCheckedBgColor,
      checkboxBorderWidth,
      checkboxBorderStyle,
      checkboxBorderColor,
      checkboxBorderCheckedColor,
      checkboxBorderRadius,
      checkboxCheckedIcon,
      checkboxLabel,
      checkboxLabelFontStyle,
      checkboxLabelSpacingLeft,
      checkboxLabelFontColor,
      checkboxLabelCheckedFontColor,
      radioSize,
      radioBgColor,
      radioCheckedBgColor,
      radioBorderWidth,
      radioBorderStyle,
      radioBorderColor,
      radioBorderCheckedColor,
      radioBorderRadius,
      radioCheckedIcon,
      radioLabel,
      radioLabelFontStyle,
      radioLabelSpacingLeft,
      radioLabelFontColor,
      radioLabelCheckedFontColor,
      fileName,
      fileImage,
      fileImageSpacingBottom,
      buttonBrowse,
      buttonBrowseSpacingLeft,
      buttonUpload,
      buttonUploadSpacingLeft,
      buttonDeleteUpload,
      buttonDeleteUploadSpacingLeft,
      id,
      name,
      value,
      checked,
      accept,
      onChange,
      onBlur,
      onFocus,
      message,
      messageFontStyle,
      messageFontColor,
      messageSpacingTop,
      messageSpacingLeft,
      messageAlign,
      hint,
      hintFontStyle,
      hintFontColor,
      hintSpacingTop,
      hintSpacingLeft,
      info,
      switchWidth,
      switchHeight,
      switchBgColor,
      switchActiveBgColor,
      switchBorderWidth,
      switchBorderStyle,
      switchBorderColor,
      switchBorderRadius,
      switchButtonSpacingLeft,
      switchButtonSpacingRight,
      switchButtonSize,
      switchButtonInactiveColor,
      switchButtonActiveColor,
      switchButtonShadow,
      switchNameFontStyle,
      switchNameOuterFontStyle,
      switchNameOuterSpacingLeft,
      switchName,
      switchOuterName,
      maxLength
    } = this.props

    return (
      <FieldBaseWrapper
        className={ClassNames(
          'field-base',
          {'is-fluid': fluid},
          {'is-error': error},
          {'is-success': success},
          {'is-disabled': disabled},
          {'is-info': info},
          // Option
          className
        )}
        theme={{
          // Default
          labelFontStyle,
          labelFontColor,
          labelAlign,
          labelWidth,
          labelSpacingTop,
          labelSpacingRight,
          width,
          height,
          paddingTop,
          paddingRight,
          paddingBottom,
          paddingLeft,
          paddingHorizontal,
          paddingVertical,
          bgColor,
          borderWidth,
          borderStyle,
          borderColor,
          borderBottomWidth,
          borderBottomStyle,
          borderBottomColor,
          borderRadius,
          fontStyle,
          fontColor,
          fontLineHeight,
          textAlign,
          placeholderColor,
          errorColor,
          successColor,
          disabledColor,
          fieldIcon,
          fieldIconWidth,
          fieldIconSpacingLeft,
          fieldIconSpacingRight,
          checkboxSize,
          checkboxBgColor,
          checkboxCheckedBgColor,
          checkboxBorderWidth,
          checkboxBorderStyle,
          checkboxBorderColor,
          checkboxBorderCheckedColor,
          checkboxBorderRadius,
          checkboxLabelFontStyle,
          checkboxLabelSpacingLeft,
          checkboxLabelFontColor,
          checkboxLabelCheckedFontColor,
          radioSize,
          radioBgColor,
          radioCheckedBgColor,
          radioBorderWidth,
          radioBorderStyle,
          radioBorderColor,
          radioBorderCheckedColor,
          radioBorderRadius,
          radioLabelFontStyle,
          radioLabelSpacingLeft,
          radioLabelFontColor,
          radioLabelCheckedFontColor,
          fileImageSpacingBottom,
          buttonBrowseSpacingLeft,
          buttonUploadSpacingLeft,
          buttonDeleteUploadSpacingLeft,
          messageFontStyle,
          messageFontColor,
          messageSpacingTop,
          messageSpacingLeft,
          messageAlign,
          hintFontStyle,
          hintFontColor,
          hintSpacingTop,
          hintSpacingLeft,
          switchWidth,
          switchHeight,
          switchBgColor,
          switchActiveBgColor,
          switchBorderWidth,
          switchBorderStyle,
          switchBorderColor,
          switchBorderRadius,
          switchButtonSpacingLeft,
          switchButtonSpacingRight,
          switchButtonSize,
          switchButtonInactiveColor,
          switchButtonActiveColor,
          switchButtonShadow,
          switchNameFontStyle,
          switchNameOuterFontStyle,
          switchNameOuterSpacingLeft
        }}
      >
        <div className='field-container-base'>
          { label &&
            <label className='field-label-base'>
              {label}
            </label>
          }
          <div className='field-group-base'>
            <div className='field-group-inner-base'>
              {/* Select field element */}
              {(() => {
                switch (type) {
                  case 'textarea':
                    return (
                      <textarea
                        className='field-value-base'
                        placeholder={placeholder}
                        id={id}
                        name={name}
                        value={value}
                        onChange={onChange}
                        maxLength={maxLength}
                      />
                    )
                  case 'select':
                    return (
                      <select
                        className='field-value-base'
                        id={id}
                        name={name}
                        value={value}
                        onChange={onChange}
                      >
                        {children}
                      </select>
                    )
                  case 'checkbox':
                    return (
                      <div className='field-checkbox-base'>
                        <input className='field-checkbox-value-base'
                          type='checkbox'
                          id={id}
                          name={name}
                          value={value}
                          checked={checked}
                          onChange={onChange}
                        />
                        <label className='field-checkbox-label-base'
                          htmlFor={id}
                        >
                          <span className='field-checkbox-icon-base'>
                            <span className='field-checkbox-icon-checked-base'>
                              {checkboxCheckedIcon}
                            </span>
                          </span>
                          {checkboxLabel &&
                            <span className='field-checkbox-icon-label-base'>
                              {checkboxLabel}
                            </span>
                          }
                        </label>
                      </div>
                    )
                  case 'radio':
                    return (
                      <div className='field-radio-base'>
                        <input className='field-radio-value-base'
                          type='radio'
                          id={id}
                          name={name}
                          value={value}
                          checked={checked}
                          onChange={onChange}
                        />
                        <label className='field-radio-label-base'
                          htmlFor={id}
                        >
                          <span className='field-radio-icon-base'>
                            <span className='field-radio-icon-checked-base'>
                              {radioCheckedIcon}
                            </span>
                          </span>
                          {radioLabel &&
                            <span className='field-radio-icon-label-base'>
                              {radioLabel}
                            </span>
                          }
                        </label>
                      </div>
                    )
                  case 'file':
                    return (
                      <div className='field-file-base'>
                        <div className='field-file-name-base'>
                          <span className='field-file-name-text-base'>
                            {fileName}
                          </span>
                        </div>
                        <div className='field-file-button-base'>
                          <input className='field-file-input-base'
                            type='file'
                            id={id}
                            name={name}
                            value={value}
                            onChange={onChange}
                            accept={accept}
                          />
                          <div className='field-file-button-browse-base'>
                            {buttonBrowse}
                          </div>
                        </div>
                        <div className='field-file-button-upload-base'>
                          {buttonUpload}
                        </div>
                      </div>
                    )
                  case 'fileAvatar':
                    return (
                      <div className='field-file-base is-avatar'>
                        <div className='field-file-image-base'>
                          {fileImage}
                        </div>
                        <div className='field-file-button-group-base'>
                          <div className='field-file-button-base'>
                            <input className='field-file-input-base'
                              type='file'
                              id={id}
                              name={name}
                              value={value}
                              onChange={onChange}
                              accept={accept}
                            />
                            <div className='field-file-button-browse-base'>
                              {buttonBrowse}
                            </div>
                          </div>
                          <div className='field-file-button-delete-upload-base'>
                            {buttonDeleteUpload}
                          </div>
                        </div>
                      </div>
                    )
                  case 'info':
                    return (
                      <div className='field-info-base'>
                        <span className='field-info-text-base'>
                          {info}
                        </span>
                      </div>
                    )
                  case 'switch':
                    return (
                      <div className='field-switch-base'>
                        <input className='field-switch-input-base'
                          type='checkbox'
                          id={id}
                          name={name}
                          value={value}
                          checked={checked}
                          onChange={onChange}
                        />
                        <label className='field-switch-body-base'
                          htmlFor={id}
                        >
                          <span className='field-switch-button-base' />
                          { switchName &&
                            <span className='field-switch-name-base'>
                              {switchName}
                            </span>
                          }
                        </label>
                        { switchOuterName &&
                          <span className='field-switch-name-outer-base'>
                            {switchOuterName}
                          </span>
                        }
                      </div>
                    )
                  default:
                    return (
                      <input
                        className='field-value-base'
                        type={inputTextType}
                        placeholder={placeholder}
                        id={id}
                        name={name}
                        value={value}
                        onChange={onChange}
                        onBlur={onBlur}
                        onFocus={onFocus}
                        maxLength={maxLength}
                      />
                    )
                }
              })()}
              { fieldIcon &&
                <div
                  className={ClassNames(
                    'field-icon-base',
                    {'is-cursor-pointer': onClickFieldIcon}
                  )}
                  onClick={onClickFieldIcon}
                >
                  { fieldIconActive ?
                    fieldIconActive
                    :
                    fieldIcon
                  }
                </div>
              }
            </div>
            { message &&
              <div className='field-message-base'>
                {message}
              </div>
            }
          </div>
          { hint &&
            <div className='field-hint-base'>
              {hint}
            </div>
          }
        </div>
      </FieldBaseWrapper>
    )
  }

  static defaultProps = {
    // Option
    // children: undefined,
    // className: undefined,
    // Default
    inputTextType: 'text',
    bgColor: VARIABLES.COLORS.WHITE,
    errorColor: VARIABLES.COLORS.ERROR,
    successColor: VARIABLES.COLORS.SUCCESS,
    disabledColor: VARIABLES.COLORS.DISABLED,
    switchButtonInactiveColor: VARIABLES.COLORS.WHITE,
    switchButtonActiveColor: VARIABLES.COLORS.WHITE
  }

  static propTypes = { // TYPE > node, string, number, bool, array, object, symbol, func
    /**
    * Additional classes
    */
    className: PropTypes.string,

    /**
    * Additional Text, HTML, Component
    */
    children: PropTypes.oneOfType([
      PropTypes.node,
      PropTypes.string
    ])
  }
}
