import {
  getUserAuthRedux,
} from './auth';
import { ENUM } from '../constants/enum';

export const isRolePermission = (target = []) => {
  const userAuth = getUserAuthRedux();
  if (!userAuth.role) {
    return target;
  }
  return target.map(e => e.toLowerCase()).indexOf(userAuth.role.toLowerCase()) >= 0;
}

export const isCustomerRoleTypeIndoPermission = (target = []) => {
  const userAuth = getUserAuthRedux();
  if (userAuth.addressType === ENUM.COUNTRY_TYPE.INDONESIA && userAuth.customerRoleType) {
    return target.map(e => e.toLowerCase()).indexOf(userAuth.customerRoleType.toLowerCase()) >= 0;
  }
  else {
    return true;
  }
}

export const isCountryPermission = (target = []) => {
  const userAuth = getUserAuthRedux();
  if (target.length) {
    return target.map(e => e.toLowerCase()).indexOf(userAuth.addressType.toLowerCase()) >= 0;
  }
  else {
    return true;
  }
}