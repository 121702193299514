import React from 'react';
// import {
//   connect,
// } from 'react-redux';
import {
  Button,
  Grid,
  Modal,
  ShapeContainer,
  Tab,
  Text,
  WarrantyContentForm
} from '../../../components';
import { displayAddress, displayDate, displayUrlPath } from '../../../helpers/functions/display';
import { getLanguage } from '../../../helpers/functions/language';
import {
  ICONS,
  VARIABLES,
} from '../../../themes';
import { WarrantyCMSTemplate } from '../../WarrantyTemplateContainer/WarrantyCMSTemplate';
// import { MODAL_TYPE } from '../../../helpers/constants/main'
// import { MAIN_REDUX_STATE_KEY } from '../../../actions/mainAction'
import _ from 'lodash';
import { FIREBASE_STORAGE } from '../../../config/config';
import { DATA, DATE_FORMAT } from '../../../helpers/constants/data';
import { ENUM } from '../../../helpers/constants/enum';
import { getBlobtoPath, getHtml2PdfFile } from '../../../helpers/functions/addon';
import { getUserAuthRedux } from '../../../helpers/functions/auth';
import { isShowLoading } from '../../../helpers/functions/main';
import { getFirebaseImagePublicPath, getWarrantyDocumentaryQRCodeValue, getWarrantyTemplateImagePublicPath } from '../../../helpers/functions/warranty';
import { addProjectDocumentLog } from '../../../helpers/services/projectDocument';
import { getWatermarkTemplateDetailSearch } from '../../../helpers/services/watermarkTemplate';

export class ModalPreviewWarrantyTemplateContainer extends React.Component {

  constructor(props) {
    super(props);
    const userAuth = getUserAuthRedux() || {};
    const tabList = ['Project', 'Residential'];
    const warrantyDetail = props.warrantyDetail;

    warrantyDetail.resDetailList = (warrantyDetail.resDetailList || []).map(e => {
      e.qrCodeValue = getWarrantyDocumentaryQRCodeValue({
        type: DATA.PUBLIC_WARRANTY_DOCUMENTARY_TYPE.WARRANTY_TEMPLATE,
        id: userAuth.adRefId,
        wrtCat: DATA.WARRANTY_CATEGORY_TYPE.RESIDENTIAL.TYPE
      })
      return e;
    })
    warrantyDetail.nonResDetailList = (warrantyDetail.nonResDetailList || []).map(e => {
      e.qrCodeValue = getWarrantyDocumentaryQRCodeValue({
        type: DATA.PUBLIC_WARRANTY_DOCUMENTARY_TYPE.WARRANTY_TEMPLATE,
        id: userAuth.adRefId,
        wrtCat: DATA.WARRANTY_CATEGORY_TYPE.NON_RESIDENTIAL.TYPE
      })
      return e;
    })
    // CR: 1491
    if (warrantyDetail.isShowPreviewWrtSegment) {
      warrantyDetail.wrtSegmentDetailSelectedList = [warrantyDetail.wrtSegmentDetailList[0]]
    }

    const envDetail = props.envDetail || {
      corrosion: 1,
      peelFlake: 2,
      colorFade: 3,
      dirtStain: 4,
      corrosionWall: 1,
    }
    // console.log('envDetail', envDetail);

    const preTransData = props.preTransData;
    console.log('preTransData', preTransData);
    const data = {
      isGenerateResidential: false,
      segmentSelected: {
        segmentNameEN: 'Segment Name',
        segmentNameTH: 'Segment Name',
        subSegmentNameEN: 'Sub Segment Name',
        subSegmentNameTH: 'Sub Segment Name',
      },
      projectName: 'Customer Project Name',
      customerRemark: 'Additional Project Information',
      dealerName: 'Dealer Name',
      dealerPhone: 'Dealer Phone',
      dealerAddress: 'Dealer Address',
      displayCreatedDate: displayDate(new Date(), '/', DATE_FORMAT),
      // customerAddress: displayAddress(userAuth),
    };
    let customerAddress = 'Customer Address';
    let customerPhone = 'Customer Phone';

    if (preTransData) {
      data.segmentSelected.segmentNameEN = preTransData.segmentSelected.segmentName
      data.segmentSelected.segmentNameTH = preTransData.segmentSelected.segmentName
      data.segmentSelected.subSegmentNameEN = preTransData.segmentSelected.subSegmentName
      data.segmentSelected.subSegmentNameTH = preTransData.segmentSelected.subSegmentName
      data.projectName = preTransData.data.projectName;
      customerPhone = `+${(preTransData.data.phoneCountry || {}).value} ${preTransData.data.phone}`;
      customerAddress = displayAddress({
        address: preTransData.data.installationAddress,
        addressType: ENUM.COUNTRY_TYPE.THAI,
        subDistrict: preTransData.data.subDistrict,
        selectedCity: preTransData.data.selectedCity,
        selectedProvince: preTransData.data.selectedProvince,
        postCode: preTransData.data.postCode,
        country: ENUM.COUNTRY.THAI,
      })
    }

    this.state = {
      preTransData,
      tabSelect: tabList[0],
      isSelectPDFNonResTH: false,
      isSelectPDFResTH: false,
      tabList,

      warrantyDetail,
      data,
      customerAddress,
      customerPhone,
      projectInsDate: displayDate(new Date(), '/', DATE_FORMAT),
      dataDetail: {
        wrtNo: '-',
        productGroupName: warrantyDetail.wrtTempName,
        // CR: 1498
        oldProductGroupName: warrantyDetail.wrtTempName,
        templateForm: '',
        [DATA.WARRANTY_APP_DETAIL_NAME_SELECT_LIST[0]]: true,
        roof: 1
      },
      envDetail,
      dataGroupList: [
        {
          coilName: 'Coil Name',
          [DATA.WARRANTY_APP_DETAIL_NAME_SELECT_LIST[0]]: true,
          roof: 1,
          applicationInfoList: [{
            building: 'Building',
            projectInsDateTime: new Date(),
            customerRemark: 'Customer Remark'
          }],
          displayCoil: ['Coil Name'],
          coilNameList: ['Coil Name'],
          coilIdList: ['Coil Id'],
          coilOrderMList: [1],
          thicknessList: [1],
          bmtList: [1],
        }
      ],
      isRefresh: false,
      watermarkTemplateData: undefined,
      userAuth,
      // CR: 1491
      wrtWatermarkLogoSelected: warrantyDetail.wrtWatermarkLogoList[0],
    }
  }

  componentDidMount() {
    this.watermarkTemplate.getWatermarkTemplate();
  }

  watermarkTemplate = {
    getWatermarkTemplate: async () => {
      const {
        warrantyDetail,
      } = this.state;
      const productGroupName = warrantyDetail.m3ProductMappingSelectList.filter(e => e.productGroupName)[0]?.productGroupName;
      if (productGroupName) {
        const params = {
          product_group_name: productGroupName,
          template_category: DATA.WATERMARK_TEMPLATE_LIST[3].value,
        }
        const configService = {
          isShowLoading: false,
        }
        const watermarkTemplateData = await getWatermarkTemplateDetailSearch(params, configService);
        if (watermarkTemplateData && watermarkTemplateData.id) {
          this.setState({
            watermarkTemplateData
          })
        }
      }
    },
  }

  modalPreview = {
    isDisableResidential: () => {
      const {
        warrantyDetail,
      } = this.state;
      let isDisable = true;
      warrantyDetail.resDetailList.forEach(e => {
        if (e.wrtTempDtBodyEn.length) {
          isDisable = false;
        }
      });
      return isDisable;
    },
    onClickTab: (tabSelect) => {
      const {
        data,
        tabList,
        warrantyDetail,
      } = this.state;
      isShowLoading(true);
      data.isGenerateResidential = tabSelect === tabList[1];
      this.setState({
        tabSelect,
        data,
        // CR: 1491
        wrtWatermarkLogoSelected: warrantyDetail.wrtWatermarkLogoList[0],
        isRefresh: true,
      }, () => {
        this.setState({
          isRefresh: false,
        }, () => {
          isShowLoading(false)
        })
      })
    },
    onClickPDFTabLanguage: (target, value) => {
      isShowLoading(true);
      this.setState({
        [target]: value,
        isRefresh: true,
      }, () => {
        this.setState({
          isRefresh: false,
        }, () => {
          isShowLoading(false)
        })
      })
    },
    // CR: 1491
    // onClickWarranyWatermarkLogoTab: (item) => {
    //   this.setState({
    //     wrtWatermarkLogoSelected: item
    //   })
    // },
    onClickDownload: async () => {
      const {
        tabSelect,
        tabList,
        warrantyDetail,
        preTransData,
        wrtWatermarkLogoSelected,
      } = this.state;
      isShowLoading(true);
      const isGenerateResidential = tabSelect === tabList[1];
      if (isGenerateResidential) {
        warrantyDetail.resDetailList = await getWarrantyTemplateImagePublicPath(warrantyDetail.resDetailList);
      } else {
        warrantyDetail.nonResDetailList = await getWarrantyTemplateImagePublicPath(warrantyDetail.nonResDetailList);
      }

      // CR: 1491
      if (wrtWatermarkLogoSelected) {
        wrtWatermarkLogoSelected.imageFile = await getFirebaseImagePublicPath(wrtWatermarkLogoSelected.imageFile);
      }
      if (warrantyDetail.isShowPreviewWrtSegment) {
        warrantyDetail.wrtSegmentDetailSelectedList = await getWarrantyTemplateImagePublicPath(warrantyDetail.wrtSegmentDetailSelectedList);
      }

      this.setState({
        warrantyDetail,
        wrtWatermarkLogoSelected,
      }, async () => {
        const dom = this.refs.warrantyTemplateRef;
        const data = {
          isCMSTemplate: true,
          isGenerateResidential
        }
        const language = this.modalPreview.getLanguage();
        const pdfFile = await getHtml2PdfFile(dom, data, undefined, warrantyDetail, {
          language,
          isPreviewTemplate: true
        });
        if (preTransData) {
          const fileLanguage = language.toLocaleUpperCase();
          // CR: 1491
          let warrantyWatermarkLogoName = '';
          if (wrtWatermarkLogoSelected) {
            warrantyWatermarkLogoName = wrtWatermarkLogoSelected.name || ''
            warrantyWatermarkLogoName += '_'
          }
          const pdfName = `${warrantyDetail.wrtTempName}_${warrantyWatermarkLogoName}${tabSelect}_${fileLanguage}`;
          const filename = `${pdfName}_${new Date().getTime()}.pdf`;
          const pathUpload = `${ENUM.UPLOAD_PATH.PROJECT_DOCUMENT}/${preTransData.data.preSalesTransId}${ENUM.UPLOAD_PATH_FOLDER.PROJECT_DOCUMENT_WARRANTY_TEMPLATE}`;
          getBlobtoPath(pdfFile, filename, pathUpload);
          const filePath = `${FIREBASE_STORAGE}${pathUpload}/${filename}`;
          const fileUrl = displayUrlPath(filePath);
          addProjectDocumentLog({
            preSalesTransId: preTransData.data.preSalesTransId,
            logCategory: ENUM.PROJECT_DOCUMENT.LOG_CATEGORY.WARRANTY_TEMPLATE,
            preSalesTransLogName: pdfName,
            logDesc: `${tabSelect}_${fileLanguage}`,
            refId: warrantyDetail.id,
            fileUrl
          });
        }
        const link = document.createElement('a');
        link.download = `${new Date().getTime()}.pdf`;
        link.href = URL.createObjectURL(pdfFile);
        link.click();
        isShowLoading(false);
      });
    },
    getLanguage: () => {
      const {
        tabSelect,
        tabList,
        isSelectPDFNonResTH,
        isSelectPDFResTH,
      } = this.state;
      let language;
      if (tabSelect === tabList[0]) {
        language = isSelectPDFNonResTH ? DATA.LANGUAGE.THAI : DATA.LANGUAGE.ENGLISH;
      }
      else {
        language = isSelectPDFResTH ? DATA.LANGUAGE.THAI : DATA.LANGUAGE.ENGLISH
      }
      return language;
    },
    warrantyTemplate: (option = {}) => {
      const {
        data,
        dataDetail,
        dataGroupList,
        envDetail,
        customerAddress,
        projectInsDate,
        customerPhone,
        isRefresh,
        warrantyDetail,
        watermarkTemplateData,
        wrtWatermarkLogoSelected,
      } = this.state;

      // CR: 1491
      const warrantyDetailData = _.cloneDeep(warrantyDetail)
      if (wrtWatermarkLogoSelected) {
        warrantyDetailData.wrtWatermarkLogoImageFile = wrtWatermarkLogoSelected.imageFile;
      }

      return isRefresh ?
        undefined :
        <WarrantyCMSTemplate
          isWaterMarkPreviewWarrantyTemplate
          isPreviewTemplate={option.isPreviewTemplate}
          language={this.modalPreview.getLanguage()}
          setComponentTemplateRef={() => { }}
          warrantyDetail={warrantyDetailData}
          data={data}
          envDetail={envDetail}
          customerAddress={customerAddress}
          dataDetail={dataDetail}
          dataGroupList={dataGroupList}
          customerPhone={customerPhone}
          projectInsDate={projectInsDate}
          isHideApplicationDetail={dataGroupList.filter(e => e.applicationInfoList.filter(ea => ea.building.length === 0).length).length > 0}
          // roof={dataDetail[DATA.WARRANTY_APP_DETAIL_NAME_SELECT_LIST[0]] ? dataDetail.roof : ''}
          // wall={dataDetail[DATA.WARRANTY_APP_DETAIL_NAME_SELECT_LIST[1]] ? dataDetail.wall : ''}
          // purlinAndGirt={dataDetail[DATA.WARRANTY_APP_DETAIL_NAME_SELECT_LIST[2]] ? dataDetail.purlinGirt : ''}
          // trussAndFrame={dataDetail[DATA.WARRANTY_APP_DETAIL_NAME_SELECT_LIST[3]] ? dataDetail.truFrame : ''}
          // coolRoomPanel={dataDetail[DATA.WARRANTY_APP_DETAIL_NAME_SELECT_LIST[4]] ? dataDetail.coolRoom : ''}
          // decking={dataDetail[DATA.WARRANTY_APP_DETAIL_NAME_SELECT_LIST[5]] ? dataDetail.decking : ''}
          // airDucting={dataDetail[DATA.WARRANTY_APP_DETAIL_NAME_SELECT_LIST[6]] ? dataDetail.airDucting : ''}
          watermarkTemplateData={watermarkTemplateData}
        />
    }
  }

  render() {
    const {
      isOpenModal,
      isSecondModal,
      onClickClose,
      onClickCancel,
      title,
    } = this.props
    const {
      tabSelect,
      tabList,
      isSelectPDFNonResTH,
      isSelectPDFResTH,
      data,
      warrantyDetail,
      wrtWatermarkLogoSelected,
    } = this.state;
    // console.log('warrantyDetail', warrantyDetail);
    // console.log('data', data);
    return (
      <React.Fragment>
        <Modal
          open={isOpenModal}
          scroll
          second={isSecondModal}
          // enableOverlayClose={false}
          width={VARIABLES.MODAL.WIDTHS.W_1145}
          overlayColor={VARIABLES.COLORS.OVERLAY_BLACK_1}
          spacing={VARIABLES.MODAL.CONTAINER.C_1.SPACING}
          transition={VARIABLES.TRANSITIONS.DEFAULT}
          transformStart={VARIABLES.MODAL.CONTAINER.C_1.TRANSFORM_START}
          transformEnd={VARIABLES.MODAL.CONTAINER.C_1.TRANSFORM_END}
          onClickClose={onClickClose}
        >
          <Modal.Section
            paddingVertical='25'
            paddingHorizontal='35'
            bgColor={VARIABLES.COLORS.WHITE}
            borderBottomWidth='1'
            borderBottomStyle='solid'
            borderBottomColor={VARIABLES.COLORS.GRAY_8}
            borderTopRightRadius={VARIABLES.MODAL.HEADER.H_1.BORDER_TOP_RIGHT_RADIUS}
            borderTopLeftRadius={VARIABLES.MODAL.HEADER.H_1.BORDER_TOP_LEFT_RADIUS}
          >
            <Text
              regular20
              singleLine
              color={VARIABLES.COLORS.BLACK}
              top='2'
            >
              {title}
            </Text>
          </Modal.Section>
          <Modal.Section
            paddingVertical='40'
            paddingHorizontal='35'
            bgColor={VARIABLES.COLORS.WHITE}
          >
            {/* CR: 1490 */}
            <Tab className='is-warranty-category'>
              <Tab.List
                isActive={tabSelect === tabList[0]}
                onClick={() => {
                  this.modalPreview.onClickTab(tabList[0])
                }}
              >
                <Grid justify='center' align='center' gutter='15'>
                  <Grid.Column>
                    <img src={ICONS['ic-warranty-category-project.png']} width='61' height='48' alt='Icon' />
                  </Grid.Column>
                  <Grid.Column>
                    <Text bold20 color={VARIABLES.COLORS.BLACK}>
                      {getLanguage('container.warrantyTemplate.modal.tabs.project')}
                    </Text>
                  </Grid.Column>
                </Grid>
              </Tab.List>
              <Tab.List
                isActive={tabSelect === tabList[1]}
                isDisabledClick={this.modalPreview.isDisableResidential()}
                onClick={this.modalPreview.isDisableResidential() ? undefined
                  :
                  () => {
                    this.modalPreview.onClickTab(tabList[1]);
                  }}
              >
                <Grid justify='center' align='center' gutter='15'>
                  <Grid.Column>
                    <img src={ICONS['ic-warranty-category-residential.png']} width='61' height='48' alt='Icon' />
                  </Grid.Column>
                  <Grid.Column>
                    <Text bold20 color={VARIABLES.COLORS.BLACK}>
                      {getLanguage('container.warrantyTemplate.modal.tabs.residential')}
                    </Text>
                  </Grid.Column>
                </Grid>
              </Tab.List>
            </Tab>
            {tabSelect === tabList[0] ?
              // Non Residential (Project)
              <ShapeContainer
                fluid
                padding='30'
                borderRadius='10'
                bgColor={VARIABLES.COLORS.BLUE_31}
              >
                {/* CR: 1491 */}
                {/*warrantyDetail.wrtWatermarkLogoList.length > 0 &&
                  <Tab className='is-watermark-category'>
                    {warrantyDetail.wrtWatermarkLogoList.map((e, i) => {
                      const isSelected = e.orderNo === wrtWatermarkLogoSelected.orderNo
                      return (
                        <Tab.List
                          key={i}
                          isActive={isSelected}
                          onClick={() => {
                            this.modalPreview.onClickWarranyWatermarkLogoTab(e)
                          }}
                        >
                          <Grid justify='center' align='center' gutter='15'>
                            <Grid.Column>
                              <Text
                                medium18
                                color={isSelected ? VARIABLES.COLORS.PRIMARY_2 : VARIABLES.COLORS.GRAY_11}>
                                {e.name}
                              </Text>
                            </Grid.Column>
                          </Grid>
                        </Tab.List>
                      )
                    })}
                  </Tab>
                */}
                <WarrantyContentForm
                  isActions={false}
                  isActiveTabFirst={!isSelectPDFNonResTH}
                  isActiveTabSecond={isSelectPDFNonResTH}
                  tabFirstName={getLanguage('container.warrantyTemplate.modal.tabs.templateEn')}
                  tabSecondName={getLanguage('container.warrantyTemplate.modal.tabs.templateTh')}
                  onClickTabFirst={() => {
                    this.modalPreview.onClickPDFTabLanguage('isSelectPDFNonResTH', false)
                  }}
                  onClickTabSecond={() => {
                    this.modalPreview.onClickPDFTabLanguage('isSelectPDFNonResTH', true)
                  }}
                >
                  {/* <iframe
                    title='Browser Viewer'
                    src={displayUrlPath(isSelectPDFNonResTH ? data.wrtTempPathNonResTh : data.wrtTempPathNonResEn) + `#toolbar=0`}
                    frameBorder='0'
                  /> */}
                  {this.modalPreview.warrantyTemplate({
                    isPreviewTemplate: true,
                  })}
                </WarrantyContentForm>
              </ShapeContainer>
              :
              // Residential
              <ShapeContainer
                fluid
                padding='30'
                borderRadius='10'
                bgColor={VARIABLES.COLORS.BLUE_31}
              >
                {/* CR: 1491 */}
                {/*warrantyDetail.wrtWatermarkLogoList.length > 0 &&
                  <Tab className='is-watermark-category'>
                    {warrantyDetail.wrtWatermarkLogoList.map((e, i) => {
                      const isSelected = e.orderNo === wrtWatermarkLogoSelected.orderNo
                      return (
                        <Tab.List
                          key={i}
                          isActive={isSelected}
                          onClick={() => {
                            this.modalPreview.onClickWarranyWatermarkLogoTab(e)
                          }}
                        >
                          <Grid justify='center' align='center' gutter='15'>
                            <Grid.Column>
                              <Text
                                medium18
                                color={isSelected ? VARIABLES.COLORS.PRIMARY_2 : VARIABLES.COLORS.GRAY_11}>
                                {e.name}
                              </Text>
                            </Grid.Column>
                          </Grid>
                        </Tab.List>
                      )
                    })}
                  </Tab>
                */}
                <WarrantyContentForm
                  isActions={false}
                  isActiveTabFirst={!isSelectPDFResTH}
                  isActiveTabSecond={isSelectPDFResTH}
                  tabFirstName={getLanguage('container.warrantyTemplate.modal.tabs.templateEn')}
                  tabSecondName={getLanguage('container.warrantyTemplate.modal.tabs.templateTh')}
                  onClickTabFirst={() => {
                    this.modalPreview.onClickPDFTabLanguage('isSelectPDFResTH', false)
                  }}
                  onClickTabSecond={() => {
                    this.modalPreview.onClickPDFTabLanguage('isSelectPDFResTH', true)
                  }}
                >
                  {/* <iframe
                  title='Browser Viewer'
                  src={displayUrlPath(isSelectPDFResTH ? data.wrtTempPathResTh : data.wrtTempPathResEn) + `#toolbar=0`}
                  frameBorder='0'
                /> */}
                  {this.modalPreview.warrantyTemplate({
                    isPreviewTemplate: true,
                  })}
                </WarrantyContentForm>
              </ShapeContainer>
            }
          </Modal.Section>
          <Modal.Section
            paddingTop='15'
            paddingBottom='20'
            paddingHorizontal='35'
            borderTopWidth={VARIABLES.MODAL.FOOTER.F_1.BORDER_TOP_WIDTH}
            borderTopStyle={VARIABLES.MODAL.FOOTER.F_1.BORDER_TOP_STYLE}
            borderTopColor={VARIABLES.COLORS.GRAY_8}
            borderBottomRightRadius={VARIABLES.MODAL.FOOTER.F_1.BORDER_BOTTOM_RIGHT_RADIUS}
            borderBottomLeftRadius={VARIABLES.MODAL.FOOTER.F_1.BORDER_BOTTOM_LEFT_RADIUS}
          >
            <Grid
              gutter='15'
              justify='flex-end'
            >
              <Grid.Column>
                <Button
                  gray
                  width={VARIABLES.BUTTON.WIDTHS.W_155}
                  name={getLanguage('field.buttonClose')}
                  onClick={onClickCancel}
                />
              </Grid.Column>
              <Grid.Column>
                <Button
                  green
                  ui='frontIcon'
                  name={getLanguage('field.buttonDownload', '')}
                  width={VARIABLES.BUTTON.WIDTHS.W_155}
                  iconWidth='18'
                  iconHeight='18'
                  iconSrc={ICONS['ic-download-circle.svg']}
                  onClick={() => {
                    this.modalPreview.onClickDownload()
                  }}
                />
              </Grid.Column>
            </Grid>
          </Modal.Section>
        </Modal>
        <div style={{
          position: 'absolute',
          left: '-9999px'
        }}>
          <div
            ref='warrantyTemplateRef'
          >
            {this.modalPreview.warrantyTemplate()}
          </div>
        </div>
      </React.Fragment>
    )
  }
}

// const mapStateToProps = (state) => {
//   const mainRedux = state.mainRedux;
//   const modalData = mainRedux[MAIN_REDUX_STATE_KEY.MODAL_DATA];
//   return {
//     isOpenModal: modalData.openModalType === MODAL_TYPE.CONFIRM_DELETE,
//     message: modalData.message,
//     onClickCancel: modalData.onClickCancel,
//     onClickConfirm: modalData.onClickConfirm,
//   }
// }

// const mapDispatchToProps = (dispatch) => {
//   return {
//   }
// }

// export const ModalPreviewPdfConnected = connect(
//   mapStateToProps,
//   mapDispatchToProps
// )(ModalPreviewPdf)