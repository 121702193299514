import React from "react";
import { warrantyController } from "../../apiService/apiController/warrantyService";
import { YearWarrantyContainerConnected } from "../../containers/YearWarrantyContainer";
import { DATA } from "../../helpers/constants/data";
import { changeLanguage } from "../../helpers/functions/language";
import { isValidResponse } from "../../helpers/functions/validation";
import {
  getPublicWarrantyYearDetail,
  getPublicWarrantyYearList,
} from "../../helpers/services/warranty";

export class PublicYearWarrantyScreen extends React.Component {
  constructor(props) {
    super(props);
    changeLanguage(DATA.LANGUAGE.THAI);
    this.state = {
      isShowRegister: true,
    };
  }

  publicYearWarranty = {
    handleSubmitRegisterForm: async (data) => {
      const params = {
        name: data.fullname,
        company: data.company,
        type_project: (data.buildingType || {}).value,
        mobile: `66${data.phone}`,
        contact_you_back: data.isContactBack,
      };
      const warrantyService = warrantyController();
      const res = await warrantyService.createTempCustomer(params);
      if (isValidResponse(res)) {
        this.setState({
          isShowRegister: false,
        });
      }
    },
  };

  render() {
    const { isShowRegister } = this.state;
    return (
      <YearWarrantyContainerConnected
        isShowRegister={isShowRegister}
        handleSubmitRegisterForm={
          this.publicYearWarranty.handleSubmitRegisterForm
        }
        getWarrantyYearList={getPublicWarrantyYearList}
        getWarrantyYearDetail={getPublicWarrantyYearDetail}
      />
    );
  }
}
