import React from 'react';
import {
  connect,
} from 'react-redux';
import {
  Section,
  TitleSection,
  Grid,
  Field,
  Button
} from './../../components'
import { FieldAddressContainerConnected } from './../../containers/FieldAddressContainer';
import { isValidNumber, isValidResponse } from '../../helpers/functions/validation';
import { redirectData } from '../../helpers/functions/route';
import { toast } from '../../helpers/functions/main';
import { customerController } from '../../apiService/apiController/customerService';
import { ROUTE_PATH } from '../../helpers/constants/route';
import {
  VARIABLES,
} from './../../themes'
import { MAIN_REDUX_STATE_KEY } from '../../actions/mainAction';
import { ENUM } from '../../helpers/constants/enum';
import { storeGetState } from '../../stores';
import { getProvince, getCity } from '../../helpers/functions/data';
import { getCustomerManageParams } from '../../helpers/services/customer';
import _ from 'lodash';
import { getLanguage } from '../../helpers/functions/language';

class CustomerContactInfoContainer extends React.Component {

  constructor(props) {
    super(props);
    const defaultData = {
      idCardNo: '',
      contactAddress: '',
      contactSelectedProvince: props.data.contactAddressType === ENUM.COUNTRY_TYPE.LAOS ? '' : getProvince(),
      contactSelectedCity: props.data.contactAddressType === ENUM.COUNTRY_TYPE.LAOS ? '' : getCity(),
      contactDistrict: '',
      //TODO: laos
      contactSubDistrict: props.data.contactAddressType === ENUM.COUNTRY_TYPE.THAI ? undefined : '',
      //TODO: laos
      contactPostCode: props.data.contactAddressType === ENUM.COUNTRY_TYPE.THAI ? undefined : '',
      contactAlley: '',
      contactGroup: '',
      contactStreet: '',
      contactWard: '',
    };
    let data = {
      ...defaultData,
      ...props.data,
    };
    console.log('data', data)
    this.state = {
      data,
    }
  }

  componentDidMount() {
  }

  customerContactInfo = {
    onClickSave: () => {
      const {
        selectSalePerson,
      } = this.props;
      if (!selectSalePerson) {
        toast.error(getLanguage('message.pleaseInsertAllRequiredField', ''));
      }
      else {
        if (this.customerContactInfo.isValidatePass()) {
          this.customerContactInfo.handleUpdateData();
        }
      }
    },
    isValidatePass: () => {
      const {
        userAuth,
      } = this.props;
      const {
        data,
      } = this.state;
      let isPass = true;
      console.log('data', data);
      return isPass;
      // if (userAuth.addressType === ENUM.COUNTRY_TYPE.THAI) {
      //   if (
      //     !data.idCardNo.length ||
      //     !data.contactAddress.length ||
      //     !data.contactSubDistrict.length ||
      //     !data.contactPostCode.length ||
      //     !data.contactSelectedProvince ||
      //     !data.contactSelectedCity
      //   ) {
      //     toast.error(getLanguage('message.pleaseInsertAllRequiredField', ''));
      //     isPass = false;
      //   }
      // }
      // else if (userAuth.addressType === ENUM.COUNTRY_TYPE.MALAYSIA) {
      //   if (
      //     !data.idCardNo.length ||
      //     !data.contactAddress.length ||
      //     !data.contactPostCode.length ||
      //     !data.contactSelectedProvince ||
      //     !data.contactSelectedCity
      //   ) {
      //     toast.error(getLanguage('message.pleaseInsertAllRequiredField', ''));
      //     isPass = false;
      //   }
      // }
      // else if (userAuth.addressType === ENUM.COUNTRY_TYPE.VIETNAM) {
      //   if (
      //     !data.idCardNo.length ||
      //     !data.contactAddress.length ||
      //     !data.contactDistrict.length ||
      //     !data.contactPostCode.length ||
      //     !data.contactSelectedProvince ||
      //     !data.contactSelectedCity
      //   ) {
      //     toast.error(getLanguage('message.pleaseInsertAllRequiredField', ''));
      //     isPass = false;
      //   }
      // }
      // else if (userAuth.addressType === ENUM.COUNTRY_TYPE.INDONESIA) {
      //   if (
      //     !data.idCardNo.length ||
      //     !data.contactAddress.length ||
      //     !data.contactDistrict.length ||
      //     !data.contactSubDistrict.length ||
      //     !data.contactPostCode.length ||
      //     !data.contactSelectedProvince ||
      //     !data.contactSelectedCity
      //   ) {
      //     toast.error(getLanguage('message.pleaseInsertAllRequiredField', ''));
      //     isPass = false;
      //   }
      // }
      // else {
      //   //th, la
      //   if (
      //     !data.idCardNo.length ||
      //     !data.contactAddress.length ||
      //     !data.contactSubDistrict.length ||
      //     !data.contactPostCode.length ||
      //     !data.contactSelectedProvince ||
      //     !data.contactSelectedCity
      //   ) {
      //     toast.error(getLanguage('message.pleaseInsertAllRequiredField', ''));
      //     isPass = false;
      //   }
      // }
      // return isPass;
    },
    handleUpdateData: async () => {
      const {
        selectSalePerson,
      } = this.props
      const {
        data,
      } = this.state;
      let params = getCustomerManageParams(data, data.id)
      const customerService = customerController();
      const res = await customerService.updateCustomerInfo(params);
      if (isValidResponse(res)) {
        // toast.success(getLanguage('message.saveCompleted', ''));
        redirectData(ROUTE_PATH.REDEMPTION_LIST_OF_REWARDS.LINK, {
          data,
          selectSalePerson
        });
      }
      else {
        toast.error(res.message);
      }
    },
  }

  input = {
    onChangeDataInput: (ev) => {
      const {
        data,
      } = this.state;
      const name = ev.target.name;
      const value = ev.target.value;
      switch (name) {
        case 'idCardNo':
        case 'contactPostCode':
          if (value.length && !isValidNumber(value)) {
            return;
          }
        default:
          data[name] = value;
          this.setState({
            data,
          });
      }
    },
    onChangeAddress: (selected, target) => {
      const {
        data,
      } = this.state;
      data[target] = selected;
      switch (target) {
        case 'contactSelectedProvince':
          data.contactSelectedCity = null;
        case 'contactSelectedCity':
          //TODO: loas
          if (data.contactAddressType === ENUM.COUNTRY_TYPE.THAI) {
            data.contactSubDistrict = null;
            data.contactPostCode = null;
          }
          break;
        case 'contactSubDistrict':
          //TODO: loas
          if (data.contactAddressType === ENUM.COUNTRY_TYPE.THAI) {
            data.contactPostCode = null;
          }
          break;
        default: break;
      }
      this.setState({
        data,
      })
    }
  }

  render() {
    const {
      onClickBack,
      userAuth,
    } = this.props;
    const {
      data,
    } = this.state;
    return (
      <React.Fragment>
        <Section spacingBottom='35'>
          <TitleSection name={getLanguage('container.customerContactInfo.title', '')} />
        </Section>
        <Section spacingBottom='30'>
          <Grid>
            <Grid.Column
              gridTablet='12'
              gridDesktop='5'
            >
              <Field name='idCardNo'
                fluid
                required={false}
                // error
                ui='blockLabelVertical'
                label={getLanguage('field.idCardNo', '')}
                placeholder={getLanguage('field.idCardNoPlaceholder', '')}
                value={data.idCardNo}
                onChange={this.input.onChangeDataInput}
              />
            </Grid.Column>
          </Grid>
        </Section>
        <Section spacingBottom='45'>
          <FieldAddressContainerConnected
            thai={data.contactAddressType === ENUM.COUNTRY_TYPE.THAI}
            malaysia={data.contactAddressType === ENUM.COUNTRY_TYPE.MALAYSIA}
            vietnam={data.contactAddressType === ENUM.COUNTRY_TYPE.VIETNAM}
            indonesia={data.contactAddressType === ENUM.COUNTRY_TYPE.INDONESIA}
            laos={data.contactAddressType === ENUM.COUNTRY_TYPE.LAOS}
            // Address
            requiredAddress={false}
            nameAddress='contactAddress'
            valueAddress={data.contactAddress}
            onChangeAddress={this.input.onChangeDataInput}
            // Province
            requiredProvince={false}
            nameProvince='contactSelectedProvince'
            valueProvince={data.contactSelectedProvince}
            onChangeProvince={(selected) => {
              if (data.contactAddressType === ENUM.COUNTRY_TYPE.LAOS) {
                this.input.onChangeDataInput(selected);
              }
              else {
                this.input.onChangeAddress(selected, 'contactSelectedProvince');
              }
            }}
            // City
            requiredCity={false}
            nameCity='contactSelectedCity'
            valueCity={data.contactSelectedCity}
            onChangeCity={(selected) => {
              if (data.contactAddressType === ENUM.COUNTRY_TYPE.LAOS) {
                this.input.onChangeDataInput(selected);
              }
              else {
                this.input.onChangeAddress(selected, 'contactSelectedCity');
              }
            }}
            // Subdistrict
            requiredSubdistrict={false}
            nameSubdistrict='contactSubDistrict'
            valueSubdistrict={data.contactSubDistrict}
            onChangeSubdistrict={(selected) => {
              if (data.contactAddressType === ENUM.COUNTRY_TYPE.THAI) {
                this.input.onChangeAddress(selected, 'contactSubDistrict');
              }
              else {
                this.input.onChangeDataInput(selected);
              }
            }}
            // District
            requiredDistrict={false}
            isSearchableDistrict={false}
            nameDistrict='contactDistrict'
            valueDistrict={data.contactDistrict}
            onChangeDistrict={this.input.onChangeDataInput}
            // optionDistrict={[
            //   { value: 'option', label: 'Option' }
            // ]}
            // Alley
            requiredAlley={false}
            nameAlley='contactAlley'
            valueAlley={data.contactAlley}
            onChangeAlley={this.input.onChangeDataInput}
            // Group
            requiredGroup={false}
            nameGroup='contactGroup'
            valueGroup={data.contactGroup}
            onChangeGroup={this.input.onChangeDataInput}
            // Street
            requiredStreet={false}
            nameStreet='contactStreet'
            valueStreet={data.contactStreet}
            onChangeStreet={this.input.onChangeDataInput}
            // Ward
            requiredWard={false}
            nameWard='contactWard'
            valueWard={data.contactWard}
            onChangeWard={this.input.onChangeDataInput}
            // PostalCode
            requiredPostalCode={false}
            namePostalCode='contactPostCode'
            valuePostalCode={data.contactPostCode}
            onChangePostalCode={(selected) => {
              if (data.contactAddressType === ENUM.COUNTRY_TYPE.THAI) {
                this.input.onChangeAddress(selected, 'contactPostCode');
              }
              else {
                this.input.onChangeDataInput(selected);
              }
            }}
          />
        </Section>
        <Section>
          <Grid
            gutter='15'
            justify='flex-end'
          >
            <Grid.Column>
              <Button
                gray
                ui='back'
                name={getLanguage('field.buttonBack', '')}
                onClick={onClickBack}
              />
            </Grid.Column>
            <Grid.Column>
              <Button
                blue
                name={getLanguage('field.buttonSaveAndContinue', '')}
                width={VARIABLES.BUTTON.WIDTHS.W_255}
                onClick={this.customerContactInfo.onClickSave}
              />
            </Grid.Column>
          </Grid>
        </Section>
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => {
  const dataRedux = state.dataRedux;
  const mainRedux = state.mainRedux;
  return {
    dataList: dataRedux.salePersonList,
    activePage: dataRedux.salePersonParams.activePage,
    totalData: dataRedux.salePersonParams.totalData || 0,
    userAuth: mainRedux[MAIN_REDUX_STATE_KEY.USER_AUTH]
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
  }
}

export const CustomerContactInfoContainerConnected = connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomerContactInfoContainer)