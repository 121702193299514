import _ from 'lodash';
import React from 'react';
import {
  Accordion,
} from 'react-accessible-accordion';
import { documentaryController } from '../../apiService/apiController/documentaryService';
import { warrantyController } from '../../apiService/apiController/warrantyService';
import { FIREBASE_PUBLIC_STORAGE_PATH, FIREBASE_STORAGE } from '../../config/config';
import { GenerateDocumentaryContainer } from '../../containers/GenerateDocumentaryContainer';
import { WarrantyTemplateContainerConnected } from '../../containers/WarrantyTemplateContainer';
import { WarrantyEnvConditionContainer } from '../../containers/WarrantyTemplateContainer/WarrantyEnvConditionContainer';
import { WarrantyEnvDetailContainer } from '../../containers/WarrantyTemplateContainer/WarrantyEnvDetailContainer';
import { COUNTRY } from '../../helpers/constants/country';
import { DATA } from '../../helpers/constants/data';
import { ENUM } from '../../helpers/constants/enum';
import { LOCAL_STORAGE } from '../../helpers/constants/localStorage';
import { ROUTE_PATH } from '../../helpers/constants/route';
import { firebaseGetDownloadPath } from '../../helpers/firebases/firebaseStorage';
import { captureHtml, getBlobtoPath } from '../../helpers/functions/addon';
import { getUserAuth, getUserAuthRedux } from '../../helpers/functions/auth';
import { canvasMergeImageFile, convertFloat, imageUrltoBase64 } from '../../helpers/functions/data';
import { getLanguage } from '../../helpers/functions/language';
import { getLocalStorage } from '../../helpers/functions/localStorage';
import { clearLogList, pushLogList, setIsAddLog, uploadLogFile } from '../../helpers/functions/log';
import { isShowAppLoading, isShowLoading, toast } from '../../helpers/functions/main';
import { getRouteData, getRouteParams, redirect, redirectData, setSideMenuActive } from '../../helpers/functions/route';
import { isValidEmail, isValidFloat, isValidNumber, isValidPhoneNumber, isValidResponse } from '../../helpers/functions/validation';
import { deleteAllWarranty, genWrtNoRegen, generateAllWarranty, getFirebaseImagePublicPath, getWarrantyTemplateImagePublicPath, isValidTemplateSegmentSpecial, setWarrantyData, setWarrantyMaxPeriod, setWarrantyTemplateRef, warrantyCloneDetail, warrantyCloneTemplate } from '../../helpers/functions/warranty';
import { getClearDocumentaryDetailParams, getDocumentaryDraftList } from '../../helpers/services/documentary';
import { getSegmentList } from '../../helpers/services/segment';
import { getWarrantyApplicationList, getWarrantyDocumentaryDetailList, getWarrantyEnvironmentAutomatic, getWarrantyEnvironmentDetailList, getWarrantyEnvironmentList, getWarrantyManageParams } from '../../helpers/services/warranty';
import { getWarrantyTemplateDetail } from '../../helpers/services/warrantyTemplate';
import { ModelDefaultWarranty, WarrantyApplication, WarrantyEnvironment } from '../../models/ModelWarranty';
import {
  AccordionControl,
  Breadcrumb,
  Section,
  Step,
  Text,
  TitlePage,
} from './../../components';
import {
  ModalConfirmContainer
} from './../../containers/ModalContainer';
import {
  ICONS, VARIABLES,
} from './../../themes';
import { WarrantyManageCondition } from './WarrantyManageCondition';
import { WarrantyManageDetail } from './WarrantyManageDetail';
import { WarrantyManageInfo } from './WarrantyManageInfo';
import { WarrantyManageSegment } from './WarrantyManageSegment';
import { WarrantyManageWarranty } from './WarrantyManageWarranty';

export class WarrantyManageScreen extends React.Component {
  isUploadLogFile = false;
  isClickRequireDocument = false;

  constructor(props) {
    super(props);
    setIsAddLog(true);
    clearLogList();
    const dataId = parseInt(getRouteParams(props, 'id'));
    const routeData = getRouteData(props, 'data');

    let newProps = _.cloneDeep(props);
    if (routeData.handleGoBackPath) {
      newProps.match.path = routeData.handleGoBackPath.split('?')[0];
    }
    setSideMenuActive(newProps);

    const defaultData = new ModelDefaultWarranty();
    const isEdit = dataId > 0;
    let data = {
      ...defaultData,
      ...routeData,
    };
    // data.projectCountry = ENUM.COUNTRY_TYPE.INDONESIA

    data.wrtStatus = DATA.WARRANTY_TYPE.READY.TYPE;

    if (data.condition.wrtCriteria1 === undefined) {
      data.condition = defaultData.condition;
    }

    if (
      data.isGenerateResidential === false &&
      data.statusCode === DATA.WARRANTY_TYPE.DRAFT.TYPE &&
      !data.projectDocumentId
    ) {
      data.projectAddr = '';
      data.projectDist = '';
      data.projectAlley = '';
      data.projectGroup = '';
      data.projectStreet = '';
      data.projectWard = '';
      data.selectedProvince = data.projectCountry === ENUM.COUNTRY_TYPE.LAOS ? '' : undefined;
      data.selectedCity = data.projectCountry === ENUM.COUNTRY_TYPE.LAOS ? '' : undefined;
      data.projectSubDist = data.projectCountry === ENUM.COUNTRY_TYPE.THAI ? undefined : '';
      data.projectPostCode = data.projectCountry === ENUM.COUNTRY_TYPE.THAI ? undefined : '';

      data.industrialEstateName = '';
      data.selectedIndustrialEstate = undefined;
      data.selectedWarrantyDistance = undefined;
    }

    //clear data for status delete
    if (data.isStatusDelete) {
      data.customerRemark = '';
      data.segmentSelected = undefined;
      data.detailList = data.detailList.map(e => {
        e.templateList = e.templateList.map(et => {
          et.detailEnvSelect = undefined;
          return et;
        });
        return e;
      });
      data.productGroupList = data.productGroupList.map(e => {
        e.applicationInfoList = [{
          projectInsDateTime: data.projectInsDateTime,
          building: '',
          customerRemark: '',
        }];
        e.applicationSelectList = [];
        e.coilOrderMSelectTotal = 0;
        e.roof = '';
        e.isRoofSelected = false;
        e.wall = '';
        e.isWallSelected = false;
        e.purlinGirt = '';
        e.isPurlinGirtSelected = false;
        e.truFrame = '';
        e.isTruFrameSelected = false;
        e.coolRoom = '';
        e.isCoolRoomSelected = false;
        e.decking = '';
        e.isDeckingSelected = false;
        e.airDucting = '';
        e.isAirDuctingSelected = false;
        e.isRoofAndWall = false;
        e.isWarrantyPeriodSelected = false;
        e.templateList = e.templateList.map(et => {
          et.detailEnvSelect = undefined;
          Object.keys(et.condition).forEach(key => {
            if (typeof et.condition[key] === 'boolean') {
              et.condition[key] = true;
            }
            if (typeof et.condition[key] === 'string') {
              et.condition[key] = '';
            }
          });
          et.envDetail = {
            corrosion: '',
            peelFlake: '',
            colorFade: '',
            dirtStain: '',
          }
          return et;
        });
        return e;
      });

      data.templateList = data.templateList.map(e => {
        e.isAcceptTncTemplate = false;
        return e;
      })
    }

    data.isShowSaveDraft = data.isGenerateResidential === false && data.isCanRegenerate === false;

    const isShowSegment = data.projectCountry === ENUM.COUNTRY_TYPE.THAI;

    const stepPercentResList = isShowSegment ? ['0', '50', '100'] : ['0', '100'];
    const stepPercentNonResList = isShowSegment ? ['0', '25', '50', '75', '100'] : ['0', '33.33', '66.66', '100'];
    const stepContentResList = isShowSegment ? [
      getLanguage('warrantyManage.step.information', ''),
      getLanguage('warrantyManage.step.segment', ''),
      getLanguage('warrantyManage.step.warranty', '')
    ] : [
      getLanguage('warrantyManage.step.information', ''),
      getLanguage('warrantyManage.step.warranty', '')
    ];
    const stepContentNonResList = isShowSegment ? [
      getLanguage('warrantyManage.step.information', ''),
      getLanguage('warrantyManage.step.segment', ''),
      getLanguage('warrantyManage.step.detail', ''),
      getLanguage('warrantyManage.step.conditions', ''),
      getLanguage('warrantyManage.step.warranty', ''),
    ] : [
      getLanguage('warrantyManage.step.information', ''),
      getLanguage('warrantyManage.step.detail', ''),
      getLanguage('warrantyManage.step.conditions', ''),
      getLanguage('warrantyManage.step.warranty', '')
    ];
    const modalTypeList = ['projectCategory', 'noticeConditions', 'envNotice', 'warrantyEnvDetailNA', 'warrantyEnvDetailNoWarrantyOffer', 'rejectEnvironment', 'reject', 'confirmWatingToBeApprove'];
    const currentStepIndex = 0;

    const language = getLocalStorage(LOCAL_STORAGE.LANGUAGE_RBPTRUEBLUE);
    // CR: warranty condition
    data.isWarrantyConditionTemplate = data.projectCountry === ENUM.COUNTRY_TYPE.THAI &&
      data.wrtCat !== DATA.WARRANTY_CATEGORY_TYPE.RESIDENTIAL.TYPE;
    // CR: manage env
    data.isManageEnv = data.projectCountry === ENUM.COUNTRY_TYPE.THAI &&
      data.wrtCat !== DATA.WARRANTY_CATEGORY_TYPE.RESIDENTIAL.TYPE;
    data.environmentNameList = DATA.WARRANTY_ENV_DETAIL_NAME_LIST.map(e => {
      return new WarrantyEnvironment({
        id: e,
        sfid: e,
        environment: e
      })
    })
    data.applicationNameList = DATA.WARRANTY_APP_DETAIL_NAME_LIST.map(e => {
      return new WarrantyApplication({
        id: e,
        name: e,
      })
    });

    // CR: 1497
    data.isSpecialSegment = false;
    // CR: 1498
    data.selectedDataList = data.selectedDataList.map(e => {
      e.detailList.forEach(ed => {
        e.productGroupName = ed.productGroupName;
        e.oldProductGroupName = ed.oldProductGroupName;
      })
      return e;
    })

    this.state = {
      language,
      dataId,
      isEdit,
      data,
      oldData: _.cloneDeep(data),
      currentStepIndex,
      currentStepPercent: stepPercentResList[currentStepIndex],
      stepPercentResList,
      stepPercentNonResList,
      stepContentResList,
      stepContentNonResList,
      stepContentList: stepContentResList,
      stepPercentList: stepPercentResList,
      modalTypeList,
      isPreviewWarranty: false,
      isUpdateWrtNo: false,
      projectNameMaxLength: data.isGenerateResidential ? 100 : 500,
      projectAddrMaxLength: 500,
      customerRemarkMaxLength: 500,
      defaultApplicationListData: {
        projectInsDateTime: undefined,
        building: '',
        customerRemark: '',
      },
      userAuth: getUserAuthRedux(),
      isShowSegment,
      routeData,

      isAutomaticEnv: data.projectCountry === ENUM.COUNTRY_TYPE.THAI &&
        data.wrtCat !== DATA.WARRANTY_CATEGORY_TYPE.RESIDENTIAL.TYPE,
      automaticEnvSelectOptionList: [],
      automaticEnvironmentData: undefined,
      automaticEnvironmentDescription: undefined,
      isShowIndustrialEstate: false,
      isShowWarrantyDistance: false,

      // CR: 1497
      selectedEnvironment: {},
    }
    console.log('WarrantyManageScreen', data);
  }

  componentDidMount() {
    isShowAppLoading(true);
    this.warrantyManage.handlePrepareData(async () => {
      await this.warrantyManage.handleUpdateStepContentList();

      // for check res documentary
      // this.warrantyManage.handleSetDocumentaryStep()
      isShowAppLoading(false);
    });
  }

  componentWillUnmount() {
    if (this.isUploadLogFile) {
      this.warrantyManage.uploadLogFile();
      setIsAddLog(false);
    }
    isShowAppLoading(false);
  }

  warrantyManage = {
    getDisableStepContent: (index) => {
      const {
        data,
        stepContentList,
      } = this.state;
      if (data.isCanRegenerate) {
        if (data.isDocumentary) {
          return index > 0 && index < (stepContentList.length - 3)
        }
        return index > 0 && index < (stepContentList.length - 1)
      }
      return false
    },
    handlePrepareData: async (callback = () => { }) => {
      const {
        data,
        isAutomaticEnv,
        userAuth,
      } = this.state;

      isShowLoading(true);
      if (data.isManageEnv) {
        const mappingList = await Promise.all([
          getWarrantyEnvironmentList({
            isShowLoading: false,
          }),
          getWarrantyApplicationList({
            isShowLoading: false,
          })
        ])
        data.environmentNameList = mappingList[0];
        data.applicationNameList = mappingList[1];
      }

      //check application detail input all require
      data.productGroupList = data.productGroupList.map(e => {
        e = this.input.handleCheckProductGroupSuccess(e);
        return e;
      });

      if (isAutomaticEnv) {
        await this.warrantyManage.getAutomaticEnvironmentList({
          isSaveDraft: data.isSaveDraft
        });
      }

      if (data.segmentId && data.subSegmentId) {
        //find segment data
        const configService = {
          isShowLoading: false,
        }
        const paramsSegment = {
          isSearchRes: data.wrtCat === DATA.WARRANTY_CATEGORY_TYPE.RESIDENTIAL.TYPE,
        }
        const segmentList = await getSegmentList(paramsSegment, configService);
        if (segmentList) {
          segmentList.forEach(e => {
            e.subSegmentList.forEach(es => {
              if (es.segmentId === data.segmentId && es.subSegmentId === data.subSegmentId) {
                data.segmentSelected = _.cloneDeep(es);
              }
            })
          })
        }
      }

      if (data.isCMSTemplate) {
        let cnt = 0;
        let newTemplateList = [];
        data.templateList.forEach(async (e) => {
          console.log('cms template', e)
          // const configService = getWarrantyTemplateDetailConfigService(e, data.isGenerateResidential);
          const params = {
            // CR: 1498
            production_date: e.templateMapping?.productionDate,
            m3_prod_grp_code: e.templateMapping?.m3ProdGrpCode,
            product_grp_name: e.templateMapping?.productGrpName,
            color: e.templateMapping?.color,
            segment_category__c: userAuth.segmentCategory__c,
            segmentation__c: userAuth.segmentation__c,
            account_type__c: userAuth.accountRoleType,
            customer_type__c: userAuth.customerRoleType
          }
          const dataTemplateDetail = await getWarrantyTemplateDetail(params, {
            isShowLoading: false,
          })
          e.warrantyDetail = dataTemplateDetail;
          if (data.isGenerateResidential) {
            e.warrantyDetail.resDetailList = await getWarrantyTemplateImagePublicPath(e.warrantyDetail.resDetailList);
          } else {
            e.warrantyDetail.nonResDetailList = await getWarrantyTemplateImagePublicPath(e.warrantyDetail.nonResDetailList);
          }
          // CR: 1491
          if (e.warrantyDetail.wrtWatermarkLogoImageFile) {
            e.warrantyDetail.wrtWatermarkLogoImageFile = await getFirebaseImagePublicPath(e.warrantyDetail.wrtWatermarkLogoImageFile);
          }

          e.wrtSelTemplateGrp = new Date().getTime();

          newTemplateList.push(e);
          cnt++;
          if (cnt === data.templateList.length) {
            // CR: 1498
            data.templateList = _.orderBy(newTemplateList, ['templateForm'], ['asc']);
            this.setState({
              data,
            }, () => {
              // CR: 1497
              this.warrantyManage.handleSegmentSpecialData()
              this.warrantyManage.handleWarrantySegmentTemplate(() => {
                isShowLoading(false);
                callback();
              })
            })
          }
        });
      }
      else {
        this.setState({
          data,
        }, () => {
          isShowLoading(false);
          callback();
        })
      }
    },
    isValidateInfoPass: () => {
      const {
        data,
        projectNameMaxLength,
        projectAddrMaxLength,
        customerRemarkMaxLength,
        userAuth,
        isAutomaticEnv,
        isShowIndustrialEstate,
        isShowWarrantyDistance,
      } = this.state;
      let isPass = true;
      console.log('data', data);
      // if (data.projectCountry === ENUM.COUNTRY_TYPE.THAI) {

      const isAutomaticEnvError = isAutomaticEnv && (
        (isShowIndustrialEstate && !data.selectedIndustrialEstate) ||
        (isShowWarrantyDistance && !data.selectedWarrantyDistance)
      );

      if (isAutomaticEnvError) {
        toast.error(getLanguage('message.automaticEnvironmentNotFound', ''));
        isPass = false;
      }

      if (userAuth.addressType === ENUM.COUNTRY_TYPE.THAI) {
        if (
          !data.projectName.length ||
          !data.projectAddr.length ||
          !data.projectPhone.length ||
          !data.projectSubDist ||
          !data.selectedProvince ||
          !data.selectedCity ||
          isAutomaticEnvError
        ) {
          toast.error(getLanguage('message.pleaseInsertAllRequiredField', ''));
          isPass = false;
        }
      }
      // else if (data.projectCountry === ENUM.COUNTRY_TYPE.MALAYSIA) {
      else if (userAuth.addressType === ENUM.COUNTRY_TYPE.MALAYSIA) {
        if (
          // !data.adRubberStamp.length ||
          // !data.originalReceiptInvoice.length ||
          // !data.residentialCommercial.length ||
          // !data.projectName.length ||
          // !data.projectAddr.length ||
          // !data.projectPostCode.length ||
          // !data.projectPhone.length ||
          // !data.projectEmail.length ||
          // !data.typesOfProductPurchased.length ||
          // !data.purposeOfPurchasedZacs.length ||
          // !data.applicationList.length ||
          // !data.inoivceNo.length ||
          // !data.selectedProvince ||
          // !data.selectedCity
          !data.projectName.length ||
          !data.projectAddr.length ||
          !data.projectPhone.length ||
          !data.selectedProvince ||
          !data.selectedCity //||
          // !data.projectPostCode
        ) {
          toast.error(getLanguage('message.pleaseInsertAllRequiredField', ''));
          isPass = false;
        }
        if (data.projectEmail.length && !isValidEmail(data.projectEmail)) {
          toast.error(getLanguage('message.wrongEmailFormat', ''));
          isPass = false;
        }
      }
      // else if (data.projectCountry === ENUM.COUNTRY_TYPE.VIETNAM) {
      else if (userAuth.addressType === ENUM.COUNTRY_TYPE.VIETNAM) {
        if (
          !data.projectName.length ||
          !data.projectAddr.length ||
          !data.projectPhone.length ||
          !data.projectDist.length ||
          !data.selectedProvince ||
          !data.selectedCity
        ) {
          toast.error(getLanguage('message.pleaseInsertAllRequiredField', ''));
          isPass = false;
        }
      }
      // else if (data.projectCountry === ENUM.COUNTRY_TYPE.INDONESIA) {
      else if (userAuth.addressType === ENUM.COUNTRY_TYPE.INDONESIA) {
        if (
          // !data.projectName.length ||
          // !data.projectAddr.length ||
          // !data.buildingType ||
          // !data.areaBuilding ||
          // !data.tokoName ||
          // !data.builderName ||
          // !data.tctThickness ||
          // !data.colorName ||
          // !data.applicationList.length ||
          // !data.brand
          !data.projectName.length ||
          !data.projectAddr.length ||
          !data.projectPhone.length ||
          !data.projectSubDist.length ||
          !data.projectDist.length ||
          !data.selectedProvince ||
          !data.selectedCity
        ) {
          toast.error(getLanguage('message.pleaseInsertAllRequiredField', ''));
          isPass = false;
        }
      }
      else {
        //th, la
        //TODO: laos
        if (
          !data.projectName.length ||
          !data.projectAddr.length ||
          !data.projectPhone.length ||
          !data.projectSubDist.length ||
          !data.selectedProvince ||
          !data.selectedCity
        ) {
          toast.error(getLanguage('message.pleaseInsertAllRequiredField', ''));
          isPass = false;
        }
      }
      DATA.PHONE_PREFIX_LIST.forEach(e => {
        if (data.projectPhoneCountry.value === e.value) {
          if (e.lengthList.indexOf(data.projectPhone.length) === -1) {
            toast.error(getLanguage('message.incorrectPhoneNumberLength', ''));
            isPass = false;
          }
        }
      })
      if (data.projectName.length > projectNameMaxLength) {
        if (data.isGenerateResidential) {
          toast.error(getLanguage('message.incorrectProjectNameLength', ''));
        }
        else {
          toast.error(getLanguage('message.incorrectProjectName500Length', ''));
        }
        isPass = false;
      }
      if (data.projectAddr.length > projectAddrMaxLength) {
        toast.error(getLanguage('message.incorrectProjectAddrLength', ''));
        isPass = false;
      }
      if (data.customerRemark.length > customerRemarkMaxLength) {
        toast.error(getLanguage('message.incorrectAdditionalProjectLength', ''));
        isPass = false;
      }
      return isPass;
    },
    // isValidatePass: () => {
    //   return this.warrantyManage.isValidateInfoPass()
    // },
    handleGoBack: () => {
      const {
        routeData,
      } = this.state;
      pushLogList('handleGoBack');
      if (routeData.handleGoBackPath) {
        if (routeData.handleGoBackRouteData) {
          redirectData(routeData.handleGoBackPath, {
            data: routeData.handleGoBackRouteData
          })
        }
        else {
          redirect(routeData.handleGoBackPath);
        }
      }
      else {
        redirect(ROUTE_PATH.WARRANTY.LINK);
      }
    },
    nextStep: () => {
      const {
        currentStepIndex,
        stepPercentList,
        stepContentList,
        data,
      } = this.state;
      const nextStepIndex = currentStepIndex + 1;
      data.wrtStep = (nextStepIndex + 1);
      this.setState({
        data,
      }, async () => {
        if (nextStepIndex === stepContentList.length) {
          const time1 = new Date().getTime();
          data.wrtStatus = DATA.WARRANTY_TYPE.READY.TYPE;
          this.setState({
            data,
            isUpdateWrtNo: false,
          }, async () => {
            this.warrantyManage.handleUploadEnvSelect([
              'envDetailRef',
              'envConditionRef'
            ], async () => {
              this.isUploadLogFile = true;
              pushLogList('next step end');
              await this.warrantyManage.handleManageData();
              const time2 = new Date().getTime();
              console.log((time2 - time1) / 1000);
            });
            // await this.warrantyManage.handleManageData();
            // const time2 = new Date().getTime();
            // console.log((time2 - time1) / 1000);
          })
        }
        else {
          this.setState({
            currentStepIndex: nextStepIndex,
            currentStepPercent: stepPercentList[nextStepIndex],
          });
          if (data.isDocumentary) {
            console.log('nextStepIndex', nextStepIndex)
            if (data.isGenerateResidential) {
              if (
                nextStepIndex === 3 ||
                nextStepIndex === 4
              ) {
                // this.warrantyManage.onClickSaveDraft({
                //   isAutoSaveDraft: true,
                // });
              }
            }
            else {
              if (
                nextStepIndex === 5 ||
                nextStepIndex === 6
              ) {
                // this.warrantyManage.onClickSaveDraft({
                //   isAutoSaveDraft: true,
                // });
              }
            }
          }
        }
      });
    },
    goBack: () => {
      const {
        stepPercentList,
        currentStepIndex,
        data,
      } = this.state;
      let prevStepIndex = currentStepIndex - 1;
      data.wrtStep = (prevStepIndex + 1);
      this.setState({
        data,
      }, () => {
        if (prevStepIndex === -1) {
          this.warrantyManage.handleGoBack();
        }
        else {
          this.setState({
            currentStepIndex: prevStepIndex,
            currentStepPercent: stepPercentList[prevStepIndex],
          })
        }
      });
    },
    goBackIndex: (stepIndex) => {
      const {
        stepPercentList,
        data,
      } = this.state;
      data.wrtStep = (stepIndex + 1);
      this.setState({
        data,
        currentStepIndex: stepIndex,
        currentStepPercent: stepPercentList[stepIndex],
      })
    },
    onClickEditWarranty: () => {
      this.warrantyManage.onClickCloseViewWarranty();
      this.warrantyManage.goBackIndex(0);
    },
    handleUpdateDetailList: (callback = () => { }) => {
      const {
        data,
      } = this.state;

      data.productGroupList = this.warrantyManage.handleUpdateRoofWall(data.productGroupList);

      data.productGroupList.forEach(e => {
        data.detailList = data.detailList.map(ed => {
          if (e.productGroupName === ed.productGroupName) {
            ed = warrantyCloneDetail(e, ed);
          }
          return ed
        })
      })

      data.templateList.forEach(e => {
        data.detailList = data.detailList.map(ed => {
          if (ed.templateList.length) {
            ed.templateList = ed.templateList.map(edt => {
              if (edt.template === e.templateForm) {
                edt = warrantyCloneTemplate(e, edt);
                ed.wrtSelTemplateGrp = e.wrtSelTemplateGrp
              }
              return edt;
            });
          }
          else {
            ed.wrtSelTemplateGrp = e.wrtSelTemplateGrp
          }
          return ed;
        });
      });

      this.setState({
        data,
      }, () => {
        callback()
      })
    },
    handleUpdateProductGroupData: (list = []) => {
      const {
        data,
      } = this.state;
      // CR: 1498
      return new Promise(resolve => {
        data.productGroupList = _.cloneDeep(list);
        this.setState({
          data,
        }, () => {
          resolve()
        })
      })
    },
    handleUpdateTemplateDataList: (list = []) => {
      const {
        data,
      } = this.state;
      data.templateList = list;
      this.setState({
        data,
      })
    },
    handleUploadImageSelect: async (productGroup, template) => {
      const {
        data,
      } = this.state;
      const filename = `${productGroup.productGroupName}_${template.application}_${new Date().getTime()}.png`;
      const pathUpload = `${ENUM.UPLOAD_PATH.WARRANTY}/${data.id}`;
      let imageFileList = [];
      if (template.imageEnvSelectFile) {
        imageFileList.push(template.imageEnvSelectFile);
      }
      if (template.imageConditionSelectFile) {
        imageFileList.push(template.imageConditionSelectFile);
      }
      // console.log('imageFileList', imageFileList)
      if (imageFileList.length) {
        canvasMergeImageFile(imageFileList).then(uploadFile => {
          getBlobtoPath(uploadFile, filename, pathUpload);
        });
      }

      let imageSelectPath = '';
      if (template.imageEnvSelectFile || template.imageConditionSelectFile) {
        imageSelectPath = `${FIREBASE_PUBLIC_STORAGE_PATH}/${FIREBASE_STORAGE}${pathUpload}/${filename}`;
      }

      return imageSelectPath;
    },
    handleManageDocumentary: async (option = {}) => {
      const {
        data,
      } = this.state;
      const userAuth = getUserAuthRedux();
      const params = {
        documentary: data.selectedDataList.map((e, i) => {
          let paramsData = _.cloneDeep(data);
          const wrtData = paramsData.selectedDataList[i];
          const dataId = wrtData.id;
          paramsData.wrtNo = wrtData.wrtNo;
          paramsData.detailList = paramsData.detailList.filter(e => e.wrtId === dataId);

          let productGroupData = {};
          if (data.isGenerateResidential) {
            productGroupData = data.productGroupList.filter(ep => ep.coilGrp === e.coilGrp)[0];
          }
          else {
            productGroupData = data.productGroupList.filter(ep => ep.productGroupName === e.productGroupName)[0];
          }
          return {
            wrt_id: dataId,
            sales_tran_id: paramsData.detailList[0].salesTranId,
            doc_ms_id: (productGroupData.documentaryList || []).filter(e => e.isSelected).map(e => e.id),
            doc_sp_comp_name: productGroupData.isSelectedSpecifyCompanyName ? productGroupData.specifyCompanyName : '',
            doc_path: paramsData.detailList[0].oldFileRef || ''
          }
        }),
        created_by: userAuth.name
      }

      const configService = {
        isShowLoading: false,
        isShowError: false,
      }
      const documentaryService = documentaryController(configService);
      // if (option.isCreateDocumentaryDraft) {
      //   pushLogList('documentaryDraft params', params);
      //   const res = await documentaryService.documentaryDraft(params);
      //   pushLogList('documentaryDraft res', res);
      // }
      // else 
      if (option.isUpdateDocumentaryDraft) {
        pushLogList('documentaryUpdateDraft params', params);
        const res = await documentaryService.documentaryUpdateDraft(params);
        pushLogList('documentaryUpdateDraft res', res);
      }
      else {
        pushLogList('createDocumentary params', params);
        const res = await documentaryService.createDocumentary(params);
        pushLogList('createDocumentary res', res);
      }
    },
    handleManageData: async (option = {}) => {
      const {
        data,
        isUpdateWrtNo,
        userAuth,
      } = this.state;
      console.log('=====================');
      console.log('handleManageData', option, data);
      console.log('=====================');
      let cntSuccess = 0;
      data.projectProv = data.projectCountry === ENUM.COUNTRY_TYPE.LAOS ? data.selectedProvince : _.get(data, 'selectedProvince.label', '');
      data.projectCity = data.projectCountry === ENUM.COUNTRY_TYPE.LAOS ? data.selectedCity : _.get(data, 'selectedCity.label', '');
      if (!option.isAutoSaveDraft) {
        isShowAppLoading(true);
      }
      // if (option.isClickSaveDraft && data.wrtSelGrp === null) {
      if (data.wrtSelGrp === null) {
        data.wrtSelGrp = new Date().getTime();
      }
      pushLogList('isUpdateWrtNo', isUpdateWrtNo, option);
      if (isUpdateWrtNo) {
        data.selectedDataList.forEach(async (e, i) => {
          let paramsData = _.cloneDeep(data);
          const wrtData = paramsData.selectedDataList[i];
          const dataId = wrtData.id;
          paramsData.wrtNo = wrtData.wrtNo;
          paramsData.detailList = paramsData.detailList.filter(e => e.wrtId === dataId);
          paramsData.wrtSelTemplateGrp = paramsData.detailList[0].wrtSelTemplateGrp
          const params = getWarrantyManageParams(paramsData, dataId);
          const configService = {
            isShowLoading: false,
          }
          const warrantyService = warrantyController(configService);
          let res;
          if (i === 0) {
            const newParams = {
              wrt_id: params.id,
              wrt_path: params.product[0].wrt_path,
              updated_by: params.updated_by,
            }
            pushLogList('second updateDocument newParams', newParams);
            res = await warrantyService.updateDocument(newParams);
            pushLogList('second updateDocument res', res);
          } else {
            pushLogList('second updateWarranty params', params);
            res = await warrantyService.updateWarranty(params);
            pushLogList('second updateWarranty res', res);
          }
          if (isValidResponse(res)) {
            console.log(getLanguage('message.updateWarrantyCompleted', ''));
            cntSuccess++;
          } else {
            if (!option.isAutoSaveDraft) {
              toast.error(res.message);
              isShowAppLoading(false);
            }
          }
          if (cntSuccess === data.selectedDataList.length) {
            if (
              data.isClickSaveDocumentary ||
              data.isCanRegenerate
            ) {
              // update document path and sync sf
              await this.warrantyManage.handleManageDocumentary();
            }

            // CR: 1497
            if (option.isSendMailToBeApprove) {
              let paramsData = _.cloneDeep(data);
              const address = {
                project_name: paramsData.projectName,
                project_addr: paramsData.projectAddr,
                project_prov: paramsData.projectProv,
                project_city: paramsData.projectCity,
                project_sub_dist: paramsData.projectSubDist.name,
                project_post_code: paramsData.projectPostCode.value || 0,
              };
              //  CR: 1497
              const wtr_ids = data.selectedDataList.filter(e => isValidTemplateSegmentSpecial(data, {
                m3ProductGroup: e.m3ProductGroup
              })).map(e => e.id);
              if (wtr_ids.length) {
                await this.warrantyManage.sendMailToAdminApprove(wtr_ids, address);
              }
            }

            if (!option.isAutoSaveDraft) {
              isShowAppLoading(false);
              toast.success(getLanguage('message.updateWarrantyCompleted', ''));
              this.warrantyManage.handleGoBack();
            }
          }
        });
      }
      else {
        // const oldWrtStatus = _.cloneDeep(data.wrtStatus);
        this.warrantyManage.handleUpdateDetailList(async () => {
          const {
            data
          } = this.state;
          // if (oldWrtStatus === DATA.WARRANTY_TYPE.READY.TYPE) {
          //   data.wrtStatus = DATA.WARRANTY_TYPE.DRAFT.TYPE;
          // }

          // CR: 1493
          if (data.isCanRegenerate) {
            data.detailList = data.detailList.map(e => {
              if (e.wrtPathBeforeRegenList) {
                e.wrtPathBeforeRegenList.push(e.oldWrtPath)
              }
              else {
                e.wrtPathBeforeRegenList = [e.oldWrtPath]
              }
              return e;
            })

            data.wrtNo = genWrtNoRegen(data.oldWrtNo);
          }
          else {
            data.wrtNo = null;
          }

          let paramsData = _.cloneDeep(data);
          const wrtData = paramsData.selectedDataList[0];
          const dataId = wrtData.id;

          paramsData.detailList = paramsData.detailList.filter(ed => ed.wrtId === dataId);
          paramsData.wrtSelTemplateGrp = paramsData.detailList[0].wrtSelTemplateGrp
          const params = getWarrantyManageParams(paramsData, dataId);
          // console.log('params', params);
          // isShowLoading(false);
          // return;

          const configService = {
            isShowLoading: false,
          }
          const warrantyService = warrantyController(configService);
          pushLogList('first updateWarranty params', params);
          this.warrantyManage.uploadLogFile();
          const res = await warrantyService.updateWarranty(params);
          pushLogList('first updateWarranty res', res);
          this.warrantyManage.uploadLogFile();
          if (isValidResponse(res)) {
            console.log(getLanguage('message.updateWarrantyCompleted', ''));
            data.selectedDataList = data.selectedDataList.map(e => {
              // e.wrtNo = option.isClickSaveDraft ? '-' : String(res.wrt_no);
              e.wrtNo = (option.isClickSaveDraft || !res.wrt_no) ? '-' : String(res.wrt_no);
              e.tokoPhone = res.tokoPhone;
              e.rollFormetName = res.rollFormetName;
              return e;
            })
            data.detailList = data.detailList.map(e => {
              e.wrtNo = (option.isClickSaveDraft || !res.wrt_no) ? '-' : String(res.wrt_no);
              e.tokoPhone = res.tokoPhone;
              e.rollFormetName = res.rollFormetName;
              return e;
            })
            // data.dealerName = _.get(res, 'dealer.name', '');
            // data.dealerPhone = _.get(res, 'dealer.phone', '');
            // data.dealerAddress = _.get(res, 'dealer.address', '');

            // data.wrtStatus = oldWrtStatus;

            this.setState({
              data,
              isUpdateWrtNo: true,
            }, async () => {
              setWarrantyData(data).then(async () => {
                if (!option.isAutoSaveDraft) {
                  const exceptDeletePathList = [];
                  data.detailList.forEach(e => {
                    (e.wrtPathBeforeRegenList || []).forEach(er => {
                      exceptDeletePathList.push(er);
                    })
                    e.templateList.forEach(et => {
                      if (e[et.applicationForSelect]) {
                        // env and condition image path
                        exceptDeletePathList.push(et.imageSelectPath.replace(`${FIREBASE_PUBLIC_STORAGE_PATH}/`, ''));
                      }
                    })
                  });
                  const pathDeleteList = await deleteAllWarranty(exceptDeletePathList);
                  pushLogList('pathDeleteList', pathDeleteList);
                  pushLogList('exceptDeletePathList', exceptDeletePathList);
                  this.warrantyManage.uploadLogFile();
                }
                if (option.isDisableGenerateWarranty) {
                  // isShowLoading(false);
                  this.warrantyManage.handleManageData(option);
                }
                else {
                  // CR: 1482
                  // create document before update wrt_doc_no
                  if (data.isClickSaveDocumentary) {
                    await this.warrantyManage.handleManageDocumentary({
                      isUpdateDocumentaryDraft: true,
                    });
                  }

                  const newData = await generateAllWarranty();
                  console.log('generateAllWarranty newData', newData)
                  pushLogList('generateAllWarranty newData');
                  this.setState({
                    data: newData,
                  }, () => {
                    // isShowLoading(false);
                    this.warrantyManage.uploadLogFile();
                    this.warrantyManage.handleManageData(option);
                  })
                }
              });
            })
          }
          else {
            if (!option.isAutoSaveDraft) {
              toast.error(res.message);
              isShowAppLoading(false);
            }
            // data.wrtStatus = oldWrtStatus;
            this.setState({
              data,
            });
          }
        });
      }
    },
    onClickViewWarranty: () => {
      this.setState({
        isPreviewWarranty: true,
      });
    },
    onClickCloseViewWarranty: () => {
      this.setState({
        isPreviewWarranty: false,
      })
    },
    isValidateSegmentPass: () => {
      const {
        data,
      } = this.state;
      let isPass = true;
      if (data.segmentSelected === undefined) {
        isPass = false;
        toast.error(getLanguage('message.pleaseSelectSegment'));
      }
      return isPass;
    },
    onClickSaveDocumentary: () => {
      const {
        data,
      } = this.state;
      data.isClickSaveDocumentary = true;
      this.setState({
        data
      }, () => {
        this.warrantyManage.onClickSave();
      })
    },
    onClickSaveWarranty: () => {
      const {
        data,
        stepContentResList,
        stepContentNonResList,
        stepPercentResList,
        stepPercentNonResList,
      } = this.state;
      data.isDocumentary = false;
      data.isClickSaveDocumentary = false;
      this.setState({
        data,
        stepContentList: data.isGenerateResidential ? stepContentResList : stepContentNonResList,
        stepPercentList: data.isGenerateResidential ? stepPercentResList : stepPercentNonResList,
      }, () => {
        this.warrantyManage.onClickSave();
      })
    },
    handleGenerateWarrantyDocumentary: async (callback = () => { }) => {
      const {
        data,
      } = this.state;
      isShowLoading(true);
      for (let i = 0; i < data.productGroupList.length; i++) {
        let documentaryList = (data.productGroupList[i].documentaryList || []);
        for (let j = 0; j < documentaryList.length; j++) {
          const documentary = documentaryList[j];
          if (documentary.isSelected) {
            documentary.docMsPathFileObject.path = await imageUrltoBase64(await firebaseGetDownloadPath(documentary.docMsPathFileObject.pathRef))
            // documentary.docMsPathFileObject.path = await firebaseGetDownloadPath(documentary.docMsPathFileObject.pathRef);
          }
          documentaryList[j] = documentary;
        }
        data.productGroupList[i].documentaryList = documentaryList;
      }
      this.setState({
        data,
      }, () => {
        isShowLoading(false);
        this.warrantyManage.handleUpdateDetailList(() => {
          setWarrantyData(data).then(() => {
            generateAllWarranty();
            callback();
          });
        });
      });
    },
    // CR: 1491
    handleWarrantySegmentTemplate: async (callback = () => { }) => {
      const {
        data,
      } = this.state;
      if (data.segmentSelected) {
        data.templateList = await Promise.all(data.templateList.map(async (e) => {
          const wrtSegmentDetailSelectedList = [];
          for (let i = 0; i < e.warrantyDetail.wrtSegmentDetailList.length; i++) {
            const wrtSegmentDetail = _.cloneDeep(e.warrantyDetail.wrtSegmentDetailList[i]);
            for (let j = 0; j < wrtSegmentDetail.segmentMappingList.length; j++) {
              const wrtSegmentDetailMapping = wrtSegmentDetail.segmentMappingList[j];
              if (
                wrtSegmentDetailMapping.segmentId === data.segmentSelected.segmentId &&
                wrtSegmentDetailMapping.subSegmentId === data.segmentSelected.subSegmentId
              ) {
                wrtSegmentDetail.wrtTempDtBodyTh = wrtSegmentDetailMapping.wrtTempDtBodyTh;
                wrtSegmentDetail.wrtTempDtBodyEn = wrtSegmentDetailMapping.wrtTempDtBodyEn;
                wrtSegmentDetailSelectedList.push(wrtSegmentDetail);
              }
            }
          }
          e.warrantyDetail.wrtSegmentDetailSelectedList = await getWarrantyTemplateImagePublicPath(wrtSegmentDetailSelectedList);
          console.log('handleWarrantySegmentTemplate', e.warrantyDetail.wrtSegmentDetailSelectedList)
          return e;
        }))
      }
      this.setState({
        data
      }, () => {
        callback()
      })
    },
    onClickSave: () => {
      const {
        data,
        currentStepIndex,
        isShowSegment,
        isAutomaticEnv,
      } = this.state;
      const validateSegment = () => {
        if (this.warrantyManage.isValidateSegmentPass()) {
          // CR: 1497
          if (currentStepIndex === 1) {
            const isPass = this.warrantyManage.validateEnviromentSegment();
            if (isPass) {
              this.warrantyManage.nextStep();
            }
          } else {
            this.warrantyManage.nextStep();
          }
        }
      }
      const validateCondition = () => {
        if (
          data.condition.wrtCriteria1 &&
          data.condition.wrtCriteria2 &&
          data.condition.wrtCriteria3 &&
          data.condition.wrtCriteria4 &&
          data.condition.wrtSurEnv2 &&
          data.condition.wrtSurEnv3 &&
          data.condition.wrtSurEnv4 &&
          data.condition.wrtSurEnv5
        ) {
          this.warrantyManage.handleUpdateDetailList(() => {
            this.warrantyManage.nextStep();
          });
        }
        else {
          // this.modal.onOpenModal(modalTypeList[1]);
        }
      }
      // const handleUploadEnvSelect = () => {
      //   this.warrantyManage.handleUploadEnvSelect([
      //     'envDetailRef',
      //     'envConditionRef'
      //   ], async () => {
      //     this.warrantyManage.nextStep();
      //   });
      // }
      switch (currentStepIndex) {
        case 0:
          if (this.warrantyManage.isValidateInfoPass()) {
            if (this.warrantyManage.handleCheckAlertWarrantyEnvDetail()) {
              this.modal.onClickSubmitCategory();
            }
          }
          break;
        case 1:
          if (isShowSegment) {
            validateSegment();
          }
          else {
            this.warrantyManage.nextStep();
          }
          break;
        case 2:
          if (isShowSegment) {
            if (isAutomaticEnv) {
              if (this.warrantyManage.isValidateInfoPass()) {
                validateSegment();
              }
            }
            else {
              validateSegment();
            }
          }
          else {
            validateCondition();
          }
          break;
        case 3:
          if (data.isDocumentary && data.isGenerateResidential) {
            this.warrantyManage.handleGenerateWarrantyDocumentary(() => {
              this.warrantyManage.nextStep();
            });
          }
          else {
            if (isShowSegment) {
              validateCondition();
            }
            else {
              // handleUploadEnvSelect();
              this.warrantyManage.nextStep();
            }
          }
          break;
        case 4:
          if (data.isDocumentary && data.isGenerateResidential) {
            validateSegment();
          }
          else {
            if (this.warrantyManage.isValidateSegmentPass()) {
              if (isAutomaticEnv) {
                if (this.warrantyManage.isValidateInfoPass()) {
                  // handleUploadEnvSelect();
                  if (this.isClickRequireDocument) {
                    this.isClickRequireDocument = false;
                    this.warrantyManage.nextStep();
                  } else {
                    const isPass = this.warrantyManage.validateSpecialSegment();
                    if (isPass) {
                      this.warrantyManage.nextStep();
                    }
                  }
                }
              }
              else {
                // handleUploadEnvSelect();
                this.warrantyManage.nextStep();
              }
            }
          }
          break;
        case 5:
          if (data.isDocumentary) {
            this.warrantyManage.handleGenerateWarrantyDocumentary(() => {
              this.warrantyManage.nextStep();
            })
          }
          break;
        case 6:
          if (data.isDocumentary) {
            const isPass = this.warrantyManage.validateSpecialSegment();
            if (isPass) {
              validateSegment();
            }
          }
          break;
        default: break;
      }
    },
    // CR: 1497
    handleSegmentSpecialData: () => {
      const {
        data,
        automaticEnvironmentData,
      } = this.state;
      if (data.segmentSelected) {
        const isSpecialSegment = (ENUM.SPECIAL_SEGMENT.some(e => e.EN === data.segmentSelected.subSegmentNameEN || e.TH === data.segmentSelected.subSegmentNameTH));
        console.log('isSpecialSegment', isSpecialSegment)
        data.isSpecialSegment = false;
        if (
          isSpecialSegment && (
            automaticEnvironmentData &&
            automaticEnvironmentData.warrantyEnvironment === 'Benign'
          )
        ) {
          data.isSpecialSegment = true;
        }
        this.setState({
          data
        })
      }
    },
    // CR: 1497
    validateEnviromentSegment: () => {
      const { data, selectedEnvironment, modalTypeList } = this.state;
      const { templateList = [] } = data;
      const isSpecialSegment = (ENUM.SPECIAL_SEGMENT.some(e => e.EN === data.segmentSelected.subSegmentNameEN || e.TH === data.segmentSelected.subSegmentNameTH));
      this.warrantyManage.handleSegmentSpecialData()
      if (isSpecialSegment) {
        if (selectedEnvironment.envName !== 'Benign') {
          // Show modal rejecting environment
          this.modal.onOpenModal(modalTypeList[5]);
          return false;
        }
        const foundSpecialWarrantyList = templateList
          .map(template => DATA.WARRANTY_YEAR.find(e => e.m3ProductGroups.includes(template.m3ProductGroup)))
          .filter(result => result !== undefined);
        // if (foundSpecialWarrantyList.length !== templateList.length) {
        if (foundSpecialWarrantyList.length === 0) {
          // Show modal rejecting special warranty
          this.modal.onOpenModal(modalTypeList[6]);
          return false;
        }
      }
      return true;
    },
    // CR: 1497
    validateSpecialSegment: () => {
      const { data, modalTypeList, currentStepIndex, stepPercentNonResList } = this.state;
      const isSpecialSegment = (ENUM.SPECIAL_SEGMENT.some(e => e.EN === data.segmentSelected.subSegmentNameEN || e.TH === data.segmentSelected.subSegmentNameTH));
      if (isSpecialSegment) {
        if (currentStepIndex === 4) {
          this.setState({ currentStepPercent: stepPercentNonResList[currentStepIndex] })
        }
        // Show modal 'waiting to be approved'
        this.modal.onOpenModal(modalTypeList[7]);
        return false;
      }
      return true;
    },
    handleUpdateRoofWall: (productGroupList = []) => {
      const {
        data,
      } = this.state;
      return productGroupList.map(e => {
        let templateRoof = e.templateList.filter(et => et.applicationForSelect === DATA.WARRANTY_APP_DETAIL_NAME_SELECT_LIST[0])[0];

        if (e.isRoofAndWall && data.projectCountry !== ENUM.COUNTRY_TYPE.INDONESIA) {
          e.templateList = e.templateList.map(et => {
            if (et.applicationForSelect === DATA.WARRANTY_APP_DETAIL_NAME_SELECT_LIST[1]) {
              et.detailEnvSelect = _.cloneDeep(templateRoof.detailEnvSelect);
              et.envDetailSelect = _.cloneDeep(templateRoof.envDetailSelect);
              et.envDetailSelectMax = _.cloneDeep(templateRoof.envDetailSelectMax);
              // et.imageEnvSelectFile = _.cloneDeep(templateRoof.imageEnvSelectFile);
              // et.imageConditionSelectFile = _.cloneDeep(templateRoof.imageConditionSelectFile);
              // et.imageSelectPath = _.cloneDeep(templateRoof.imageSelectPath);
            }
            return et;
          });
          console.log('clone wall', e.templateList);
        }

        // CR: remove merged roof and wall for thai
        const isRoofAndWall = e[DATA.WARRANTY_APP_DETAIL_NAME_SELECT_LIST[0]] &&
          e[DATA.WARRANTY_APP_DETAIL_NAME_SELECT_LIST[1]];
        if (
          isRoofAndWall &&
          templateRoof &&
          data.projectCountry === ENUM.COUNTRY_TYPE.THAI
        ) {
          e.templateList = e.templateList.map(et => {
            if (et.applicationForSelect === DATA.WARRANTY_APP_DETAIL_NAME_SELECT_LIST[1]) {
              //  for warranty period wall
              et.envDetail = _.cloneDeep(templateRoof.envDetail);
            }
            return et;
          });
          console.log('clone wall', e.templateList);
        }
        return e;
      });
    },
    handleUpdateEnvDetail: (callback = () => { }) => {
      const {
        data,
      } = this.state;

      isShowLoading(true);

      data.productGroupList = this.warrantyManage.handleUpdateRoofWall(data.productGroupList);

      let cntProductGroup = 0;
      const productGroupList = [];

      data.productGroupList.forEach((e, i) => {
        const templateList = [];
        let cntTemplate = 0;

        e.templateList.forEach(async (et) => {
          if (e[et.applicationForSelect]) {
            const envDetailGroup = DATA.WARRANTY_ENV_DETAIL[data.projectCountry][et.applicationForSelect];
            const envDetail = et.envList[et.detailEnvSelect];
            if (envDetail) {
              et.envDetailCondition = envDetailGroup[envDetail.environment];
              // console.log('vvvv', et.envDetailCondition);
            }
          }
          cntTemplate += 1;
          templateList.push(et);
          if (cntTemplate === e.templateList.length) {
            e.templateList = templateList;
            cntProductGroup += 1;
            productGroupList.push(e);
            if (cntProductGroup === data.productGroupList.length) {
              data.productGroupList = productGroupList;
              this.setState({
                data,
              }, () => {
                isShowLoading(false);
                callback();
              })
            }
          }
        });
      });

      // data.productGroupList = data.productGroupList.map((e, i) => {
      //   e.templateList = e.templateList.map(et => {
      //     if (e[et.applicationForSelect]) {
      //       const envDetailGroup = DATA.WARRANTY_ENV_DETAIL[data.projectCountry][et.applicationForSelect];
      //       et.envDetailCondition = envDetailGroup[et.envList[et.detailEnvSelect].environment];
      //       console.log('vvvv', et.envDetailCondition);
      //     }
      //     return et;
      //   });
      //   return e;
      // });

      // this.setState({
      //   data,
      // }, () => {
      //   callback();
      // })
    },
    handleUploadEnvSelect: async (targetList = [], callback = () => { }) => {
      const {
        data,
        isAutomaticEnv,
      } = this.state;

      if (data.wrtCat !== DATA.WARRANTY_CATEGORY_TYPE.RESIDENTIAL.TYPE) {
        isShowAppLoading(true);

        // CR: 1498
        if (data.isSaveDraft) {
          for (let i = 0; i < targetList.length; i++) {
            const targetRef = targetList[i];
            if (
              targetRef === 'envConditionRef' &&
              this.refs.envConditionRef &&
              this.refs.envConditionRef.warrantyManageCondition
            ) {
              await this.refs.envConditionRef.warrantyManageCondition.getData()
            }
          }
        }


        let cntProductGroup = 0;
        const productGroupList = [];
        data.productGroupList.forEach((e, i) => {
          const templateList = [];
          let cntTemplate = 0;

          if (e.templateList.length) {
            e.templateList.forEach(async (et) => {
              if (e[et.applicationForSelect]) {
                for (let i = 0; i < targetList.length; i++) {
                  const targetRef = targetList[i];
                  // if thai not upload env select
                  if (
                    targetRef === 'envDetailRef' &&
                    isAutomaticEnv
                  ) {
                    continue;
                  }
                  // const refEnvDetailTarget = `envDetailRef_${e.productGroupName}_${et.application}`;
                  // const refEnvConditionTarget = `envConditionRef_${e.productGroupName}_${et.application}`;
                  let application = et.application;
                  if (targetRef === 'envDetailRef' && e.isRoofAndWall && data.projectCountry !== ENUM.COUNTRY_TYPE.INDONESIA && et.applicationForSelect === DATA.WARRANTY_APP_DETAIL_NAME_SELECT_LIST[1]) {
                    application = DATA.WARRANTY_APP_DETAIL_NAME_LIST[0];
                  }
                  const refTarget = `${targetRef}_${e.productGroupName}_${application}`;
                  // const refEnvDetailSelect = this.refs.envDetailRef.refs[refEnvDetailTarget];
                  // const refEnvConditionSelect = this.refs.envConditionRef.refs[refEnvConditionTarget];
                  const refSelect = this.refs[targetRef].refs[refTarget];
                  if (refSelect) {
                    const imageCapture = await captureHtml(refSelect);
                    switch (targetRef) {
                      case 'envDetailRef':
                        et.imageEnvSelectFile = imageCapture;
                        break;
                      case 'envConditionRef':
                        et.imageConditionSelectFile = imageCapture;
                        break;
                      default: break;
                    }
                  }
                }
                et.imageSelectPath = await this.warrantyManage.handleUploadImageSelect(e, et);
              }
              cntTemplate += 1;
              templateList.push(et);
              if (cntTemplate === e.templateList.length) {
                e.templateList = templateList;
                cntProductGroup += 1;
                productGroupList.push(e);
                if (cntProductGroup === data.productGroupList.length) {
                  data.productGroupList = productGroupList;
                  this.setState({
                    data,
                  }, () => {
                    isShowAppLoading(false);
                    callback();
                  })
                }
              }
            });
          }
          else {
            cntProductGroup += 1;
            productGroupList.push(e);
            if (cntProductGroup === data.productGroupList.length) {
              data.productGroupList = productGroupList;
              this.setState({
                data,
              }, () => {
                isShowAppLoading(false);
                callback();
              })
            }
          }
        });
      }
      else {
        callback();
      }
    },
    onChangeSegment: (value) => {
      const {
        data,
      } = this.state;
      data.segmentSelected = value;
      this.setState({
        data,
      })
    },
    handleUpdateData: (data) => {
      this.setState({
        data,
      })
    },
    handleClearDocumentary: () => {
      const {
        data,
      } = this.state;
      data.detailList = data.detailList.map(e => {
        e.documentaryList = [];
        return e;
      })
      data.productGroupList = data.productGroupList.map(e => {
        e.documentaryList = [];
        return e;
      })
      return data;
    },
    onClickEditWarrantyDocumentary: () => {
      this.setState({
        data: this.warrantyManage.handleClearDocumentary()
      }, () => {
        this.warrantyManage.onClickEditWarranty();
      })
    },
    goBackGenerateWarranty: () => {
      this.setState({
        data: this.warrantyManage.handleClearDocumentary()
      }, () => {
        this.warrantyManage.goBack();
      });
    },
    handleCheckAlertWarrantyEnvDetail: () => {
      const {
        data,
        modalTypeList,
        isAutomaticEnv,
      } = this.state;
      let isPass = true;

      if (data.wrtCat === DATA.WARRANTY_CATEGORY_TYPE.NON_RESIDENTIAL.TYPE) {
        data.productGroupList.forEach(e => {
          if (isPass) {
            e.templateList.forEach((et, it) => {
              if (isPass && (e[et.applicationForSelect] || isAutomaticEnv)) {
                if (
                  et.applicationForSelect === DATA.WARRANTY_APP_DETAIL_NAME_SELECT_LIST[1] &&
                  e.isRoofAndWall && data.projectCountry !== ENUM.COUNTRY_TYPE.INDONESIA
                ) {
                  return;
                }
                let envDetail = et.envList[et.detailEnvSelect];
                if (envDetail) {
                  if (envDetail.isWarrantyNoOffer) {

                    isPass = false;
                    // CR: 1498
                    if (
                      DATA.M3_PRODUCT_GROUP_1498.filter(ep => ep.m3ProductGroup.indexOf(et.m3ProductGroup) >= 0).length > 0 &&
                      data.productGroupList.length > 1
                    ) {
                      isPass = true
                    }
                    if (!isPass) {
                      this.modal.onOpenModal(modalTypeList[4]);
                    }
                    console.log('isWarrantyNoOffer', envDetail);
                  }
                  else if (envDetail.isWarrantyNA) {
                    isPass = false;
                    this.modal.onOpenModal(modalTypeList[3]);
                    console.log('isWarrantyNA', envDetail);
                  }
                  else if (envDetail.isWarrantySpecial) {
                    isPass = false;
                    this.modal.onOpenModal(modalTypeList[2]);
                    console.log('isWarrantySpecial', envDetail);
                  }
                }
              }
            });
          }
        });
      }

      return isPass
    },
    // CR: 1497
    sendMailToAdminApprove: async (wrt_ids = [], address = {}) => {
      const { data } = this.state;

      const productGroupNames = [];
      const coilDescriptions = [];

      wrt_ids.forEach(wrt_id => {
        const detail = data.detailList.find(d => d.wrtId === wrt_id);
        if (detail) {
          productGroupNames.push(detail.coilGrp)
          coilDescriptions.push(detail.coilName)
        }
      });

      const configService = {
        isShowLoading: false,
      }
      const warrantyService = warrantyController(configService);

      const userAuth = getUserAuth();
      const params = {
        location: userAuth.company,
        address: address,
        wrt_ids: wrt_ids,
        productGroupNames: [...new Set(productGroupNames)],
        coilDescriptions: [...new Set(coilDescriptions)],
      }
      await warrantyService.sendMailToAdminApproved(params);
    },
    renderContent: () => {
      const {
        currentStepIndex,
        data,
        isPreviewWarranty,
        modalTypeList,
        isShowSegment,
        isAutomaticEnv,
        automaticEnvSelectOptionList,
        automaticEnvironmentDescription,
        automaticEnvironmentData,
        isShowWarrantyDistance,
        isShowIndustrialEstate,
      } = this.state;
      const renderWarrantyManageWarranty = () => {
        return (
          <WarrantyManageWarranty
            data={data}
            isPreview={isPreviewWarranty}
            onClickBack={data.isCanRegenerate ?
              () => {
                this.warrantyManage.goBackIndex(0)
              } : this.warrantyManage.goBack
            }
            onClickSave={this.warrantyManage.onClickSaveWarranty}
            onClickView={this.warrantyManage.onClickViewWarranty}
            onClickCloseView={this.warrantyManage.onClickCloseViewWarranty}
            onClickEdit={this.warrantyManage.onClickEditWarranty}
            handleUpdateDataList={this.warrantyManage.handleUpdateTemplateDataList}
            onClickSaveDraft={this.warrantyManage.onClickSaveDraft}
            onClickRequireDocumentary={this.warrantyManage.onClickRequireDocumentary}
            getTemplateFormName={this.warrantyManage.getTemplateFormName}
          />
        )
      }
      const renderWarrantyManageDetail = () => {
        return (
          <WarrantyManageDetail
            data={data}
            isAutomaticEnv={isAutomaticEnv}
            isShowSegment={isShowSegment}
            onChangeDataInput={this.input.onChangeProductGroupDataInput}
            onChangeAppInfoDataInput={this.input.onChangeProductGroupAppInfoDataInput}
            onChangeEnvDataInput={this.input.onChangeProductGroupEnvDataInput}
            onClickAddApplicationInfo={this.warrantyManage.onClickAddApplicationInfo}
            onClickDeleteApplicationInfo={this.warrantyManage.onClickDeleteApplicationInfo}
            onChangeCheckbox={this.input.onChangeProductGroupDataCheckbox}
            onClickBack={this.warrantyManage.goBack}
            onClickSave={this.warrantyManage.onClickSave}
            handleUpdateEnvDetail={this.warrantyManage.handleUpdateEnvDetail}
            handleUpdateProductGroupData={this.warrantyManage.handleUpdateProductGroupData}
            handleCheckAlertWarrantyEnvDetail={this.warrantyManage.handleCheckAlertWarrantyEnvDetail}
            onClickSaveDraft={this.warrantyManage.onClickSaveDraft}
            handleUpdateDetailList={this.warrantyManage.handleUpdateDetailList}
          />
        )
      }
      const renderWarrantyManageCondition = () => {
        return (
          <WarrantyManageCondition
            data={data}
            onChangeDataInput={this.input.onChangeDataConditionInput}
            onClickBack={this.warrantyManage.goBack}
            onClickSave={this.warrantyManage.onClickSave}
            onChangeDataApplicationInput={this.input.onChangeDataApplicationConditionInput}
            handleUpdateProductGroupData={this.warrantyManage.handleUpdateProductGroupData}
            handleCheckProductGroupTncError={this.input.handleCheckProductGroupTncError}
            onClickOpenWarrantyConditionNoPassModal={() => {
              this.modal.onOpenModal(modalTypeList[1]);
            }}
            onClickSaveDraft={this.warrantyManage.onClickSaveDraft}
          />
        )
      }

      const renderWarrantySelectDocumentary = () => {
        return (
          <GenerateDocumentaryContainer isSelectDocumentary
            isShowSaveDraft={!data.isCanRegenerate}
            data={data}
            onClickBack={this.warrantyManage.goBackGenerateWarranty}
            handleUpdateProductGroupData={this.warrantyManage.handleUpdateProductGroupData}
            onClickSave={this.warrantyManage.onClickSave}
            onClickSaveDraft={this.warrantyManage.onClickSaveDraft}
          />
        )
      }
      const renderWarrantyGenerateDocumentary = () => {
        return (
          <GenerateDocumentaryContainer
            isShowSaveDraft={!data.isCanRegenerate}
            getTemplateFormName={this.warrantyManage.getTemplateFormName}
            data={data}
            onClickBack={this.warrantyManage.goBack}
            onClickEdit={this.warrantyManage.onClickEditWarrantyDocumentary}
            onClickSave={this.warrantyManage.onClickSaveDocumentary}
            onClickSaveDraft={this.warrantyManage.onClickSaveDraft}
          />
        )
      }
      switch (currentStepIndex) {
        case 0:
          return (
            <WarrantyManageInfo
              isAutomaticEnv={isAutomaticEnv}
              automaticEnvironmentData={automaticEnvironmentData}
              isShowIndustrialEstate={isShowIndustrialEstate}
              isShowWarrantyDistance={isShowWarrantyDistance}
              onChangeIndustrialEstate={this.input.onChangeIndustrialEstate}
              industrialEstateSelectOptionList={automaticEnvSelectOptionList}
              onChangeWarrantyDistance={this.input.onChangeWarrantyDistance}
              automaticEnvironmentDescription={automaticEnvironmentDescription}
              data={data}
              onChangeDataInput={this.input.onChangeDataInput}
              onChangeAddress={this.input.onChangeAddress}
              onChangePhoneCountry={this.input.onChangePhoneCountry}
              onChangeDataDate={this.input.onChangeDataDate}
              onClickBack={this.warrantyManage.goBack}
              onClickSave={this.warrantyManage.onClickSave}
              onChangeApplication={this.input.onChangeApplicationInfoIndo}
              onClickSaveDraft={this.warrantyManage.onClickSaveDraft}
            />
          )
        case 1:
          if (isShowSegment) {
            return (
              <WarrantyManageSegment
                data={data}
                onChangeSegment={this.warrantyManage.onChangeSegment}
                onClickBack={this.warrantyManage.goBack}
                onClickSave={() => {
                  // CR: 1491
                  isShowLoading(true)
                  this.warrantyManage.handleWarrantySegmentTemplate(() => {
                    isShowLoading(false)
                    this.warrantyManage.onClickSave()
                  })
                }}
                onClickSaveDraft={this.warrantyManage.onClickSaveDraft}
              />
            )
          }
          else {
            return data.wrtCat === DATA.WARRANTY_CATEGORY_TYPE.RESIDENTIAL.TYPE ?
              renderWarrantyManageWarranty()
              :
              renderWarrantyManageDetail();
          }
        case 2:
          if (isShowSegment) {
            return data.wrtCat === DATA.WARRANTY_CATEGORY_TYPE.RESIDENTIAL.TYPE ?
              renderWarrantyManageWarranty()
              :
              renderWarrantyManageDetail();
          }
          else {
            return renderWarrantyManageCondition();
          }
        case 3:
          if (data.isDocumentary && data.isGenerateResidential) {
            return renderWarrantySelectDocumentary();
          }
          else {
            if (isShowSegment) {
              return renderWarrantyManageCondition();
            }
            else {
              return renderWarrantyManageWarranty();
            }
          }
        case 4:
          if (data.isDocumentary && data.isGenerateResidential) {
            return renderWarrantyGenerateDocumentary();
          }
          else {
            return renderWarrantyManageWarranty();
          }
        case 5:
          if (data.isDocumentary) {
            return renderWarrantySelectDocumentary();
          }
          break;
        case 6:
          if (data.isDocumentary) {
            return renderWarrantyGenerateDocumentary();
          }
          break;
        default: break;
      }
    },
    getTemplateFormName: (item) => {
      const {
        data,
      } = this.state;
      if (data.isCMSTemplate) {
        return item.warrantyDetail.wrtTempName;
      }
      else {
        switch (item.templateForm) {
          case DATA.WARRANTY_PRODUCT_TEMPLATE_GROUP[ENUM.COUNTRY_TYPE.INDONESIA][16]:
            return 'P1CCP COLORBOND ® COOLROOM';
          default:
            return item.templateForm;
        }
      }
    },
    callbackPDFUploaded: (data) => {
      this.setState({
        data,
      })
    },
    //bswm v
    handleUpdateStepRegenerate: () => {
      const {
        stepContentList,
      } = this.state;
      const currentStepIndex = (stepContentList.length - 2);
      this.setState({
        currentStepIndex,
      }, () => {
        this.warrantyManage.nextStep();
      });
    },
    handleUpdateStepContentList: () => {
      return new Promise(resolve => {
        const {
          data,
          stepContentNonResList,
          stepPercentNonResList,
        } = this.state;

        const isNonRes = data.wrtCat === DATA.WARRANTY_CATEGORY_TYPE.NON_RESIDENTIAL.TYPE;

        const handleCheckSaveDraft = () => {
          if (data.isSaveDraft) {
            isShowLoading(true);
            const {
              stepPercentList,
            } = this.state;
            const currentStepIndex = data.wrtStep - 1;
            const handleStepPercentList = () => {
              const {
                stepPercentList,
              } = this.state;
              this.setState({
                currentStepIndex,
                currentStepPercent: stepPercentList[currentStepIndex],
              });
              isShowLoading(false);
              resolve();
            }
            if (currentStepIndex >= stepPercentList.length) {
              this.warrantyManage.handleSetDocumentaryStep(async () => {
                // find documentary draft
                for (let i = 0; i < data.detailList.length; i++) {
                  const item = data.detailList[i];
                  const paramsDocumentaryDetail = {
                    ...getClearDocumentaryDetailParams(),
                    sales_tran_id: item.salesTranId,
                    page: 1,
                  };
                  const documentaryDetailList = await getDocumentaryDraftList(paramsDocumentaryDetail);
                  if (documentaryDetailList.length) {
                    const selectedIdList = documentaryDetailList.map(e => e.id);
                    const params = {
                      data: [{
                        m3_prod_grp_code: item.m3ProductGroup,
                        production_date: item.productionDate
                      }]
                    }
                    let m3DocumentaryList = await getWarrantyDocumentaryDetailList(params, {
                      isShowLoading: false
                    })

                    // let m3DocumentaryList = await getM3DocumentaryList({
                    //   m3ProdGrpCode: item.m3ProductGroup,
                    //   folderType: ENUM.DOCUMENTARY.FOLDER_TYPE.WARRANRY_DOCUMENTARY
                    // });


                    m3DocumentaryList = m3DocumentaryList.map(e => {
                      if (selectedIdList.indexOf(e.id) >= 0) {
                        e.isSelected = true;
                      }
                      return e;
                    })

                    data.productGroupList = data.productGroupList.map(e => {
                      if (item.productGroupName === e.productGroupName) {
                        e.specifyCompanyName = (documentaryDetailList[0] || {}).docSpCompName || '';
                        e.isSelectedSpecifyCompanyName = (e.specifyCompanyName || '').length > 0;
                        if (!e.documentaryList) {
                          e.documentaryList = m3DocumentaryList;
                        }
                        else {
                          e.documentaryList = _.uniqBy([...e.documentaryList, ...m3DocumentaryList], 'id');
                        }
                      }
                      return e;
                    })
                  }
                }
                this.setState({
                  data,
                }, () => {
                  const {
                    stepContentList,
                  } = this.state;
                  if (currentStepIndex === stepContentList.length - 1) {
                    this.warrantyManage.handleGenerateWarrantyDocumentary(() => {
                      handleStepPercentList();
                    });
                  }
                  else {
                    handleStepPercentList();
                  }
                })
              });
            }
            else {
              handleStepPercentList();
            }
          }
          else if (data.projectDocumentId) {
            const {
              stepPercentList,
            } = this.state;
            data.wrtStep = 3;
            let stepIndexForProjectDocument = 2;
            // CR: 1497
            if (data.isCanRegenerate || (
              data.segmentSelected &&
              ENUM.SPECIAL_SEGMENT.some(e => e.EN === data.segmentSelected.subSegmentNameEN || e.TH === data.segmentSelected.subSegmentNameTH)
            )) {
              data.wrtStep = 1;
              stepIndexForProjectDocument = 0;
            }
            this.setState({
              data,
              currentStepIndex: stepIndexForProjectDocument,
              currentStepPercent: stepPercentList[stepIndexForProjectDocument],
            }, () => {
              resolve();
            });
          }
          else {
            resolve();
          }
        }

        if (isNonRes) {
          this.setState({
            stepContentList: stepContentNonResList,
            stepPercentList: stepPercentNonResList,
          }, () => {
            handleCheckSaveDraft();
          })
        }
        else {
          handleCheckSaveDraft();
        }
      });
    },
    onClickAddApplicationInfo: (index) => {
      const {
        data,
        defaultApplicationListData,
      } = this.state;
      let dataDetail = data.productGroupList[index];

      dataDetail.applicationInfoList.push({
        ...defaultApplicationListData,
        projectInsDateTime: data.projectInsDateTime
      });

      dataDetail = this.input.handleCheckProductGroupSuccess(dataDetail);

      data.productGroupList[index] = dataDetail;
      this.setState({
        data,
      })
    },
    onClickDeleteApplicationInfo: (index, infoIndex) => {
      const {
        data,
      } = this.state;
      let dataDetail = data.productGroupList[index];
      dataDetail.applicationInfoList.splice(infoIndex, 1);
      dataDetail = this.input.handleCheckProductGroupSuccess(dataDetail);
      data.productGroupList[index] = dataDetail;
      this.setState({
        data,
      })
    },
    onClickSaveDraft: (option = {}) => {
      const {
        data,
      } = this.state;
      data.wrtStatus = DATA.WARRANTY_TYPE.SAVE_DRAFT.TYPE;
      console.log('onClickSaveDraft', data);
      this.setState({
        data,
        isUpdateWrtNo: false,
      }, () => {
        if (data.isDocumentary) {
          this.warrantyManage.handleManageDocumentary({
            isUpdateDocumentaryDraft: true,
          });
        }
        // this.warrantyManage.handleUploadEnvSelect(['envDetailRef', 'envConditionRef'], () => {
        if (!option.isAutoSaveDraft) {
          this.isUploadLogFile = true;
          pushLogList('onClickSaveDraft');
        }
        this.warrantyManage.handleManageData({
          isAutoSaveDraft: option.isAutoSaveDraft,
          isDisableGenerateWarranty: true,
          isClickSaveDraft: true,
        });
        // });
      });
    },
    //bswm ^
    handleSetDocumentaryStep: (callback = () => { }) => {
      const {
        data,
        stepContentList,
      } = this.state;
      const newStepContentList = [...stepContentList,
      getLanguage('warrantyManage.step.documentaryInformation', ''),
      getLanguage('warrantyManage.step.documentary', '')
      ];
      const stepPercentList = Array.from(Array(newStepContentList.length)).map((e, i) => (i / (newStepContentList.length - 1) * 100).toString());
      data.isDocumentary = true;
      this.setState({
        stepContentList: newStepContentList,
        stepPercentList,
        data,
        // for check res documentary
        // currentStepIndex: 3,
        // currentStepPercent: stepPercentList[3]
      }, () => {
        callback()
      });
    },
    onClickRequireDocumentary: async () => {
      const {
        data,
      } = this.state;
      this.isClickRequireDocument = true;

      if (data.isDocumentary) {
        this.warrantyManage.onClickSave();
      }
      else {
        if (this.warrantyManage.isValidateSegmentPass()) {
          // if (!data.isSaveDraft) {
          //   this.warrantyManage.handleManageDocumentary({
          //     isCreateDocumentaryDraft: true,
          //   });
          // }

          this.warrantyManage.handleSetDocumentaryStep(() => {
            this.warrantyManage.onClickSave();
          })
        }
      }
    },
    //
    // automatic environment
    //
    getAutomaticEnvironmentList: (option = {}) => {
      return new Promise(async (resolve) => {
        const {
          data,
        } = this.state;
        if (
          data.projectSubDist &&
          data.selectedProvince &&
          data.selectedCity
        ) {
          isShowLoading(true);
          const provinceList = COUNTRY.THAI.PROVINCE_LIST;
          const cityList = COUNTRY.THAI.CITY_LIST;
          let provinceText = '';
          let districtText = '';
          let subDistrictText = '';
          provinceList.forEach(e => {
            if (e.provinceCode === data.selectedProvince.provinceCode) {
              provinceText = e.province
            }
          })
          cityList.forEach(e => {
            if (e.cityCode === data.selectedCity.cityCode) {
              districtText = e.city
              e.subDistrict.forEach(es => {
                if (es.id === data.projectSubDist.id) {
                  subDistrictText = es.name;
                }
              })
            }
          })

          const configService = {
            params: {
              project_prov: provinceText,
              project_dist: districtText,
              project_sub_dist: subDistrictText
            },
            isShowLoading: false,
          }
          const automaticEnvSelectOptionList = await getWarrantyEnvironmentAutomatic(configService);

          let isShowIndustrialEstate = false;
          let isShowWarrantyDistance = false;
          let automaticEnvironmentData;

          automaticEnvSelectOptionList.forEach(e => {
            if (e.industrialEstateName) {
              isShowIndustrialEstate = true;
            }
            else {
              automaticEnvironmentData = _.cloneDeep(e);

              if (e.isGroupMarine) {
                // if marine show only distance
                isShowWarrantyDistance = true;
                automaticEnvironmentData.isShowEnv = false;
                if ((option.isSaveDraft) && data.distanceEnv) {
                  const envCode = data.distanceEnv;
                  const envSelected = data.environmentNameList.filter(ev => ev.code === envCode)[0] || {};
                  automaticEnvironmentData.warrantyEnvironment = envSelected.environmentName;
                  data.selectedWarrantyDistance = automaticEnvironmentData.warrantyDistanceSelectOptionList.filter(e => e.value === envSelected.code)[0];
                  if (data.selectedWarrantyDistance) {
                    automaticEnvironmentData.isShowEnv = true;
                  }
                }
              }
            }
          })

          this.setState({
            automaticEnvironmentData,
            isShowIndustrialEstate,
            isShowWarrantyDistance,
            automaticEnvSelectOptionList,
            data
          }, async () => {
            // marine or benign
            if (automaticEnvironmentData && automaticEnvironmentData.isShowEnv) {
              const automaticEnvironmentName = automaticEnvironmentData.warrantyEnvironment;
              await this.warrantyManage.handleSelectAutomaticEnv(automaticEnvironmentName, () => {
                resolve();
                isShowLoading(false);
              })
            }
            else {
              // industrial
              if (
                option.isSaveDraft &&
                isShowIndustrialEstate &&
                data.industrialEstateName
              ) {
                let selectedIndustrialEstate;
                automaticEnvSelectOptionList.forEach(e => {
                  if (e.industrialEstateName === data.industrialEstateName) {
                    selectedIndustrialEstate = e;
                  }
                })
                if (selectedIndustrialEstate) {
                  await this.input.onChangeIndustrialEstate(selectedIndustrialEstate, option);
                }
              }
              resolve();
              isShowLoading(false);
            }
          })

          // if (data.industrialEstateName || data.industrialEstateName === null) {
          //   let selectedIndustrialEstate;
          //   automaticEnvSelectOptionList.forEach(e => {
          //     if (e.industrialEstateName === data.industrialEstateName) {
          //       selectedIndustrialEstate = e;
          //     }
          //   })
          //   if (selectedIndustrialEstate) {
          //     await this.input.onChangeIndustrialEstate(selectedIndustrialEstate, option);
          //   }
          // }

          // this.setState({
          //   automaticEnvSelectOptionList,
          // }, () => {
          //   resolve();
          //   isShowLoading(false);
          // })
        }
        else {
          this.warrantyManage.handleClearAutomaticEnv(() => {
            resolve();
          });
        }
      })
    },
    handleClearAutomaticEnv: (callback = () => { }) => {
      const {
        data,
      } = this.state
      data.selectedIndustrialEstate = null;
      data.industrialEstateName = '';
      data.selectedWarrantyDistance = null;
      this.setState({
        data,
        isShowIndustrialEstate: false,
        isShowWarrantyDistance: false,
        automaticEnvironmentData: undefined,
        // automaticEnvSelectOptionList: [],
      }, () => {
        callback();
      })
    },
    getEnvDescriptionList: () => {
      return new Promise(async (resolve) => {
        const {
          data,
          language,
          automaticEnvironmentData,
        } = this.state;
        const automaticEnvironmentName = automaticEnvironmentData.warrantyEnvironment;
        // let automaticEnvironmentDescription = getDefaultEnvironmentDescription(automaticEnvironmentName);
        let automaticEnvironmentDescription = '';
        // const productGroupName = data.productGroupList[0]?.productGroupName;
        // CR: 1498
        const productGroupName = data.productGroupList[0]?.oldProductGroupName;
        const params = {
          productGroupName,
        }
        const configService = {
          isShowLoading: false,
        }
        const dataList = await getWarrantyEnvironmentDetailList(params, configService);
        const envDesc = dataList.filter(e => e.envName === automaticEnvironmentName)[0];
        console.log('envDesc', envDesc, automaticEnvironmentData)
        if (envDesc) {
          const isLanguageTH = language === DATA.LANGUAGE.THAI;
          const envDescription = isLanguageTH ? envDesc.envDescriptionTH : envDesc.envDescription;
          automaticEnvironmentDescription = (
            <Section>
              <Text bold16 color={VARIABLES.COLORS.BLACK}>{automaticEnvironmentName || ''}</Text>
              <Section spacingBottom='2'>
                <span className='text-base is-regular-12' dangerouslySetInnerHTML={{ __html: envDescription }} />
              </Section>
            </Section>
          );
        }


        this.setState({
          automaticEnvironmentDescription,
          selectedEnvironment: envDesc || {}
        }, () => {
          resolve();
        })
      })
    },
    handleSelectAutomaticEnv: async (automaticEnvironmentName, callback = () => { }) => {
      const {
        data,
      } = this.state;
      data.productGroupList.forEach((e, i) => {
        e.templateList.forEach((et, it) => {
          et.envList.forEach((etn, itn) => {
            if ((etn.environment || '').toLowerCase() === (automaticEnvironmentName || '').toLowerCase()) {
              this.input.onChangeProductGroupEnvDataInput({
                target: {
                  name: 'detailEnvSelect',
                  value: itn
                }
              }, i, it);
            }
          })
        })
      });
      this.setState({
        data,
      }, async () => {
        await this.warrantyManage.getEnvDescriptionList();
        callback()
      })
    },
    //
    // add log
    //
    uploadLogFile: async () => {
      const {
        data,
      } = this.state;
      data.selectedDataList.forEach(async (e, i) => {
        const pathUpload = `${ENUM.UPLOAD_PATH.LOG}${ENUM.UPLOAD_PATH.WARRANTY}/${e.id}`;
        await uploadLogFile(pathUpload);
      });
    },
  }

  modal = {
    onOpenModal: (type) => {
      this.setState({
        modalType: type,
      })
    },
    onCloseModal: (type) => {
      this.setState({
        modalType: undefined,
      })
    },
    onClickSubmitCategory: () => {
      const {
        data,
        oldData,
      } = this.state;
      const isRes = data.wrtCat === DATA.WARRANTY_CATEGORY_TYPE.RESIDENTIAL.TYPE;
      if (isRes) {
        data.condition = _.cloneDeep(oldData.condition);
        data.productGroupList = _.cloneDeep(oldData.productGroupList);
      }
      this.setState({
        data,
      }, () => {
        if (data.isCanRegenerate) {
          this.warrantyManage.handleUpdateStepRegenerate();
        }
        else {
          this.warrantyManage.nextStep();
        }
        //bswm v
        // this.modal.onCloseModal();
        //bswm ^
      })
    },
    onClickWaitingApprove: () => {
      const {
        data,
      } = this.state;
      data.wrtStatus = DATA.WARRANTY_TYPE.WAITING_FOR_APPROVAL.TYPE;
      this.setState({
        data,
      }, () => {
        this.warrantyManage.handleUploadEnvSelect([
          'envDetailRef',
          'envConditionRef'
        ], () => {
          this.isUploadLogFile = true;
          pushLogList('onClickWaitingApprove');
          this.warrantyManage.handleManageData();
        });
      })
    },
    onClickSubmitSelectEnvDetailSpecial: () => {
      const {
        data,
      } = this.state;
      data.wrtStatus = DATA.WARRANTY_TYPE.SUBMITTED.TYPE;
      data.wrtComment = DATA.WARRANTY_ENV_TYPE.SPECIAL;
      this.setState({
        data,
      }, () => {
        this.warrantyManage.handleUploadEnvSelect([
          'envDetailRef'
        ], () => {
          this.isUploadLogFile = true;
          pushLogList('onClickSubmitSelectEnvDetailSpecial');
          this.warrantyManage.handleManageData({
            isDisableGenerateWarranty: true,
          });
        });
      });
    },
    onClickSubmitSelectEnvDetailNA: () => {
      const {
        data,
      } = this.state;
      data.wrtStatus = DATA.WARRANTY_TYPE.SUBMITTED.TYPE;
      data.wrtComment = DATA.WARRANTY_ENV_TYPE.NA;
      this.setState({
        data,
      }, () => {
        this.warrantyManage.handleUploadEnvSelect([
          'envDetailRef'
        ], () => {
          this.isUploadLogFile = true;
          pushLogList('onClickSubmitSelectEnvDetailNA');
          this.warrantyManage.handleManageData({
            isDisableGenerateWarranty: true,
          });
        });
      });
    },
    onClickSubmitSelectEnvDetailNoWarrantyOffer: () => {
      const {
        data,
      } = this.state;
      data.wrtStatus = DATA.WARRANTY_TYPE.SUBMITTED.TYPE;
      data.wrtComment = DATA.WARRANTY_ENV_TYPE.NO_OFFER_TEXT;
      this.setState({
        data,
      }, () => {
        this.warrantyManage.handleUploadEnvSelect([
          'envDetailRef'
        ], () => {
          this.isUploadLogFile = true;
          pushLogList('onClickSubmitSelectEnvDetailNoWarrantyOffer');
          this.warrantyManage.handleManageData({
            isDisableGenerateWarranty: true
          });
        });
      });
    },
    // CR: 1497
    onClickConfirmReject: async (option = {}) => {
      const {
        data,
      } = this.state;
      data.wrtStatus = DATA.WARRANTY_TYPE.REJECTED.TYPE;
      console.log('onClickConfirmReject', data);
      this.setState({
        data,
        isUpdateWrtNo: false,
      }, async () => {
        if (data.isDocumentary) {
          await this.warrantyManage.handleManageDocumentary({
            isUpdateDocumentaryDraft: true,
          });
        }
        if (!option.isAutoSaveDraft) {
          this.isUploadLogFile = true;
          pushLogList('onClickConfirmReject');
        }
        await this.warrantyManage.handleManageData({
          isAutoSaveDraft: option.isAutoSaveDraft,
          isDisableGenerateWarranty: true,
          isClickSaveDraft: true,
        });

      });
    },
    // CR: 1497
    onClickConfirmToBeApprove: async () => {
      const { data, currentStepIndex, stepContentList } = this.state;
      const nextStepIndex = currentStepIndex + 1;
      data.wrtStep = (nextStepIndex + 1);
      this.setState({
        data,
      }, async () => {
        if (nextStepIndex === stepContentList.length) {
          data.wrtStatus = DATA.WARRANTY_TYPE.WAITING_TO_BE_APPROVE.TYPE;
          this.setState({
            data,
            isUpdateWrtNo: false,
          }, async () => {
            this.warrantyManage.handleUploadEnvSelect([
              'envDetailRef',
              'envConditionRef'
            ], async () => {
              this.isUploadLogFile = true;
              pushLogList('onClickConfirmToBeApprove');
              await this.warrantyManage.handleManageData({
                isSendMailToBeApprove: true,
              });
            });
          });
        }
      });
    }
  }

  input = {
    onChangeDataInput: (ev) => {
      const {
        data,
        projectNameMaxLength,
        projectAddrMaxLength,
        customerRemarkMaxLength,
      } = this.state;
      const name = ev.target.name;
      const value = ev.target.value;
      switch (name) {
        case 'projectPhone':
          if (value.length && !isValidPhoneNumber(value)) {
            return;
          }
        case 'projectPostCode':
          if (value.length && !isValidNumber(value)) {
            return;
          }
        default:
          data[name] = value;
          if (name === 'projectName') {
            data.isProjectNameError = data.projectName.length > projectNameMaxLength;
          }
          if (name === 'projectAddr') {
            data.isProjectAddrError = data.projectAddr.length > projectAddrMaxLength;
          }
          if (name === 'customerRemark') {
            data.isCustomerRemarkError = data.customerRemark.length > customerRemarkMaxLength;
          }
          if (data.isProjectNameError) {
            if (data.isGenerateResidential) {
              toast.error(getLanguage('message.incorrectProjectNameLength', ''));
            }
            else {
              toast.error(getLanguage('message.incorrectProjectName500Length', ''));
            }
          }
          if (data.isProjectAddrError) {
            toast.error(getLanguage('message.incorrectProjectAddrLength', ''));
          }
          if (data.isCustomerRemarkError) {
            toast.error(getLanguage('message.incorrectAdditionalProjectLength', ''));
          }
          this.setState({
            data,
          })
      }
    },
    //
    // automatic environment
    //
    onChangeIndustrialEstate: (selected, option = {}) => {
      return new Promise((resolve) => {
        const {
          data,
        } = this.state;
        console.log('onChangeIndustrialEstate', selected, option)
        let automaticEnvironmentName = selected.warrantyEnvironment;
        if (automaticEnvironmentName) {
          data.selectedIndustrialEstate = _.cloneDeep(selected);
          data.selectedWarrantyDistance = null;

          if (option.isSaveDraft && data.distanceEnv) {
            const envCode = data.distanceEnv;
            const envSelected = data.environmentNameList.filter(ev => ev.code === envCode)[0] || {};
            automaticEnvironmentName = envSelected.environmentName;
            data.selectedIndustrialEstate.warrantyEnvironment = automaticEnvironmentName;

            data.selectedWarrantyDistance = data.selectedIndustrialEstate.warrantyDistanceSelectOptionList.filter(e => e.value === envSelected.code)[0];
            if (data.selectedWarrantyDistance === undefined) {
              data.selectedWarrantyDistance = null;
            }
          }

          const automaticEnvironmentData = _.cloneDeep(data.selectedIndustrialEstate);
          if (
            automaticEnvironmentData.isNotIndustrial &&
            !data.selectedWarrantyDistance
          ) {
            automaticEnvironmentData.isShowEnv = false;
          }
          this.setState({
            data,
            automaticEnvironmentData,
            isShowWarrantyDistance: data.selectedIndustrialEstate.isShowWarrantyDistance,
          }, async () => {
            if (automaticEnvironmentData.isShowEnv) {
              this.warrantyManage.handleSelectAutomaticEnv(automaticEnvironmentName, () => {
                resolve();
              })
            }
            else {
              resolve();
            }
          });
        }
        else {
          this.warrantyManage.handleClearAutomaticEnv(() => {
            toast.error(getLanguage('message.environmentFromProjectAddressNotFound', ''));
            resolve()
          })
        }
      });
    },
    onChangeWarrantyDistance: (selected) => {
      return new Promise(resolve => {
        const {
          data,
          automaticEnvironmentData
        } = this.state;
        data.selectedWarrantyDistance = _.cloneDeep(selected);
        const envSelected = data.environmentNameList.filter(e => e.code === selected.value)[0] || {};
        const automaticEnvironmentName = envSelected.environmentName;
        automaticEnvironmentData.warrantyEnvironment = automaticEnvironmentName;
        automaticEnvironmentData.isShowEnv = true;

        console.log('onChangeWarrantyDistance', envSelected, data.selectedWarrantyDistance, automaticEnvironmentData)
        this.setState({
          data,
          automaticEnvironmentData,
        }, () => {
          this.warrantyManage.handleSelectAutomaticEnv(automaticEnvironmentName, () => {
            resolve();
          })
        });
      })
    },
    //
    //
    //
    onChangeAddress: (selected, target) => {
      const {
        data,
        isAutomaticEnv,
      } = this.state;
      data[target] = selected;
      switch (target) {
        case 'selectedProvince':
          data.selectedCity = null;
        case 'selectedCity':
          //TODO: loas
          if (data.projectCountry === ENUM.COUNTRY_TYPE.THAI) {
            data.projectSubDist = null;
            data.projectPostCode = null;
          }
          break;
        case 'projectSubDist':
          //TODO: loas
          if (data.projectCountry === ENUM.COUNTRY_TYPE.THAI) {
            if (selected.postCodeList && selected.postCodeList.length === 1) {
              data.projectPostCode = selected.postCodeList[0];
            }
            else {
              data.projectPostCode = null;
            }
            this.warrantyManage.handleClearAutomaticEnv();
          }
          break;
        case 'projectPostCode':
          if (data.projectCountry === ENUM.COUNTRY_TYPE.THAI) {
            this.warrantyManage.handleClearAutomaticEnv();
          }
          break;
        default: break;
      }
      this.setState({
        data,
      }, () => {
        if (isAutomaticEnv) {
          this.warrantyManage.getAutomaticEnvironmentList()
        }
      })
    },
    onChangePhoneCountry: (selected, target) => {
      const {
        data,
      } = this.state;
      data[target] = selected;
      this.setState({
        data,
      })
    },
    onChangeDataApplicationConditionInput: (ev, value, index, productGroupIndex) => {
      const {
        data,
      } = this.state;
      const name = ev.target.name;
      let dataDetail = data.productGroupList[productGroupIndex];
      const applicationName = dataDetail.templateList[index].applicationForSelect;
      switch (applicationName) {
        case DATA.WARRANTY_APP_DETAIL_NAME_SELECT_LIST[2]:
        case DATA.WARRANTY_APP_DETAIL_NAME_SELECT_LIST[3]:
        case DATA.WARRANTY_APP_DETAIL_NAME_SELECT_LIST[5]:
        case DATA.WARRANTY_APP_DETAIL_NAME_SELECT_LIST[6]:
          switch (name) {
            case 'wrtTermCon12':
            case 'wrtTermCon13':
            case 'wrtTermCon14':
            case 'wrtTermCon15':
              if (value.length && !isValidNumber(value)) {
                return;
              }
              dataDetail.templateList[index].condition[name] = parseInt(value) ? parseInt(value) : 0;
              dataDetail = this.input.handleCheckProductGroupTncError(dataDetail, {
                templateIndex: index,
                name
              });
              break;
            default:
              dataDetail.templateList[index].condition[name] = value;
              break;
          }
          break;
        default:
          switch (name) {
            case 'wrtTermCon11':
            case 'wrtTermCon12':
            case 'wrtTermCon13':
            case 'wrtTermCon14':
              if (value.length && !isValidNumber(value)) {
                return;
              }
              dataDetail.templateList[index].condition[name] = parseInt(value) ? parseInt(value) : 0;
              dataDetail = this.input.handleCheckProductGroupTncError(dataDetail, {
                templateIndex: index,
                name,
              });
              break;
            default:
              dataDetail.templateList[index].condition[name] = value;
              break;
          }
          break;
      };

      const isShowRoofAndWall = dataDetail.isRoofAndWall && data.projectCountry === ENUM.COUNTRY_TYPE.THAI;
      //duplicate wall data for thai
      if (isShowRoofAndWall) {
        dataDetail.templateList = dataDetail.templateList.map((e, i) => {
          if (
            applicationName === DATA.WARRANTY_APP_DETAIL_NAME_SELECT_LIST[0] &&
            e.applicationForSelect === DATA.WARRANTY_APP_DETAIL_NAME_SELECT_LIST[1]
          ) {
            e.condition[name] = _.cloneDeep(dataDetail.templateList[index].condition[name]);
          }
          return e;
        })
      }

      data.productGroupList[productGroupIndex] = dataDetail;
      this.setState({
        data,
      })
    },
    onChangeDataConditionInput: (ev, value) => {
      const {
        data,
      } = this.state;
      const name = ev.target.name;
      data.condition[name] = value;
      switch (name) {
        case 'wrtSurEnv2':
          data.condition.wrtSurEnv22 = value ? undefined : '1';
          break;
        case 'wrtSurEnv3':
          data.condition.wrtSurEnv32 = value ? undefined : '1';
          break;
      }
      this.setState({
        data
      })
    },
    handleCheckProductGroupError: (dataDetail) => {
      // TODO: fix/warranty-application
      // dataDetail.coilOrderMSelectTotal = convertInt(dataDetail.roof) +
      //   convertInt(dataDetail.wall) +
      //   convertInt(dataDetail.purlinGirt) +
      //   convertInt(dataDetail.truFrame) +
      //   convertInt(dataDetail.coolRoom) +
      //   convertInt(dataDetail.decking) +
      //   convertInt(dataDetail.airDucting);
      dataDetail.coilOrderMSelectTotal = convertFloat(dataDetail.roof) +
        convertFloat(dataDetail.wall) +
        convertFloat(dataDetail.purlinGirt) +
        convertFloat(dataDetail.truFrame) +
        convertFloat(dataDetail.coolRoom) +
        convertFloat(dataDetail.decking) +
        convertFloat(dataDetail.airDucting);

      dataDetail.isErrorQuantity = dataDetail.coilOrderMGroupTotal - dataDetail.coilOrderMSelectTotal < 0;

      // TODO: fix/warranty-application
      // const isErrorNoQuantity =
      //   (dataDetail.isRoofSelected && convertInt(dataDetail.roof) === 0) ||
      //   (dataDetail.isWallSelected && convertInt(dataDetail.wall) === 0) ||
      //   (dataDetail.isPurlinGirtSelected && convertInt(dataDetail.purlinGirt) === 0) ||
      //   (dataDetail.isTruFrameSelected && convertInt(dataDetail.truFrame) === 0) ||
      //   (dataDetail.isCoolRoomSelected && convertInt(dataDetail.coolRoom) === 0) ||
      //   (dataDetail.isDeckingSelected && convertInt(dataDetail.decking) === 0) ||
      //   (dataDetail.isAirDuctingSelected && convertInt(dataDetail.airDucting) === 0)
      const isErrorNoQuantity =
        (dataDetail.isRoofSelected && convertFloat(dataDetail.roof) === 0) ||
        (dataDetail.isWallSelected && convertFloat(dataDetail.wall) === 0) ||
        (dataDetail.isPurlinGirtSelected && convertFloat(dataDetail.purlinGirt) === 0) ||
        (dataDetail.isTruFrameSelected && convertFloat(dataDetail.truFrame) === 0) ||
        (dataDetail.isCoolRoomSelected && convertFloat(dataDetail.coolRoom) === 0) ||
        (dataDetail.isDeckingSelected && convertFloat(dataDetail.decking) === 0) ||
        (dataDetail.isAirDuctingSelected && convertFloat(dataDetail.airDucting) === 0)

      if (dataDetail.isErrorQuantity || isErrorNoQuantity) {
        dataDetail.isSuccessApplicationDetail = false;
      }
      else {
        dataDetail.isShowErrorNoQuantity = false;
      }
      return dataDetail;
    },
    handleCheckProductGroupSuccess: (dataDetail) => {
      let isSuccess = true;
      if (!(dataDetail.isRoofSelected ||
        dataDetail.isWallSelected ||
        dataDetail.isPurlinGirtSelected ||
        dataDetail.isTruFrameSelected ||
        dataDetail.isCoolRoomSelected ||
        dataDetail.isDeckingSelected ||
        dataDetail.isAirDuctingSelected
      )) {
        isSuccess = false;
      }
      dataDetail.applicationInfoList.forEach(e => {
        if (e.building.length === 0 ||
          e.customerRemark.length === 0) {
          isSuccess = false;
        }
      });
      dataDetail.isSuccessApplicationDetail = isSuccess;
      if (isSuccess) {
        dataDetail.isErrorSelectApplicationDetail = false;
        dataDetail.isErrorApplicationList = false;
      }
      dataDetail = this.input.handleCheckProductGroupError(dataDetail);
      return dataDetail;
    },
    handleCheckProductGroupTncError: (dataDetail, option = {}) => {
      const {
        data,
      } = this.state;
      const getTemplateConditionError = (template, fieldName) => {
        const applicationName = template.applicationForSelect;
        switch (applicationName) {
          case DATA.WARRANTY_APP_DETAIL_NAME_SELECT_LIST[2]:
          case DATA.WARRANTY_APP_DETAIL_NAME_SELECT_LIST[3]:
          case DATA.WARRANTY_APP_DETAIL_NAME_SELECT_LIST[5]:
          case DATA.WARRANTY_APP_DETAIL_NAME_SELECT_LIST[6]:
            switch (fieldName) {
              case 'wrtTermCon12':
              case 'wrtTermCon13':
              case 'wrtTermCon14':
              case 'wrtTermCon15':
                const condition = template.envDetailCondition.CONDITION;
                if (condition) {
                  const conditionData = condition[fieldName];
                  const value = template.condition[fieldName];
                  if (conditionData) {
                    if (conditionData.MIN && conditionData.MAX) {
                      template.condition[`${fieldName}Error`] = !(value >= conditionData.MIN && value <= conditionData.MAX);
                    }
                    else if (conditionData.MIN) {
                      template.condition[`${fieldName}Error`] = !(value >= conditionData.MIN);
                    }
                    else if (conditionData.MAX) {
                      template.condition[`${fieldName}Error`] = !(value <= conditionData.MAX);
                    }
                  }
                  else {
                    template.condition[`${fieldName}Error`] = false;
                  }
                }
                else {
                  template.condition[`${fieldName}Error`] = false;
                }
                break;
            }
            break;
          default:
            switch (fieldName) {
              case 'wrtTermCon11':
              case 'wrtTermCon12':
              case 'wrtTermCon13':
              case 'wrtTermCon14':
                const condition = template.envDetailCondition.CONDITION;
                if (condition) {
                  const conditionData = condition[fieldName];
                  const value = template.condition[fieldName];
                  if (conditionData) {
                    if (conditionData.MIN && conditionData.MAX) {
                      template.condition[`${fieldName}Error`] = !(value >= conditionData.MIN && value <= conditionData.MAX);
                    }
                    else if (conditionData.MIN) {
                      template.condition[`${fieldName}Error`] = !(value >= conditionData.MIN);
                    }
                    else if (conditionData.MAX) {
                      template.condition[`${fieldName}Error`] = !(value <= conditionData.MAX);
                    }
                  }
                  else {
                    template.condition[`${fieldName}Error`] = false;
                  }
                }
                else {
                  template.condition[`${fieldName}Error`] = false;
                }
                break;
            }
            break;
        }
        return template;
      }
      dataDetail.templateList = dataDetail.templateList.map((e, i) => {
        if (dataDetail[e.applicationForSelect]) {
          // CR: warranty condition
          if (data.isWarrantyConditionTemplate) {
            e.questionList.forEach(eq => {
              if (eq.isRequire && e.condition[eq.id] === '') {
                e.conditionError[eq.id] = true;
              }
            })
          }
          else {
            if (Object.keys(option).length > 0) {
              if (option.templateIndex === i) {
                e = getTemplateConditionError(e, option.name);
              }
            }
            else {
              Object.keys(e.condition).forEach(keyc => {
                e = getTemplateConditionError(e, keyc);
              });
            }
          }
        }
        return e;
      });

      return dataDetail;
    },
    onChangeProductGroupDataInput: (ev, index) => {
      const {
        data,
        modalTypeList,
      } = this.state;
      const name = ev.target.name;
      let value = ev.target.value;
      let dataDetail = data.productGroupList[index];

      switch (name) {
        case 'isErrorSelectApplicationDetail':
        case 'isErrorSelectEnvDetail':
        case 'isErrorApplicationList':
        case 'isShowErrorNoQuantity':
          dataDetail[name] = value;
          break;
        default:
          // TODO: fix/warranty-application
          // if (!isValidNumber(value)) {
          if (!isValidFloat(value)) {
            return;
          };
          // TODO: fix/warranty-application
          // dataDetail[name] = value ? parseInt(value) : '';
          dataDetail[name] = value;
          // if (value.indexOf('.') > -1) {
          //   dataDetail[name] = convertFloat(value, 2);
          // }
          break;
      }

      dataDetail = this.input.handleCheckProductGroupSuccess(dataDetail);

      data.productGroupList[index] = dataDetail;

      this.setState({
        data
      })
    },
    onChangeProductGroupAppInfoDataInput: (ev, index, infoIndex) => {
      const {
        data,
      } = this.state;
      const name = ev.target.name;
      let value = ev.target.value;
      let dataDetail = data.productGroupList[index];

      switch (name) {
        case 'building':
        case 'customerRemark':
        case 'projectInsDateTime':
          dataDetail.applicationInfoList[infoIndex][name] = value;
          break;
      }

      dataDetail = this.input.handleCheckProductGroupSuccess(dataDetail);

      data.productGroupList[index] = dataDetail;

      this.setState({
        data
      })
    },
    onChangeProductGroupEnvDataInput: async (ev, index, templateIndex) => {
      const {
        data,
        modalTypeList,
      } = this.state;
      const name = ev.target.name;
      let value = ev.target.value;
      let dataDetail = data.productGroupList[index];

      switch (name) {
        case 'detailEnvSelect':
          const envDetailSelect = dataDetail.templateList[templateIndex].envList[value];
          console.log('envDetailSelect', envDetailSelect);

          dataDetail.templateList[templateIndex].envDetailSelect = envDetailSelect;

          const templatePeriodMax = setWarrantyMaxPeriod(envDetailSelect);

          dataDetail.templateList[templateIndex].envDetailSelectMax = {
            corrosion: templatePeriodMax.warrantyCorPeriodMax,
            peelFlake: templatePeriodMax.warrantyPeelFlakePeriodMax,
            colorFade: templatePeriodMax.warrantyColFadePeriodMax,
            dirtStain: templatePeriodMax.warrantyDirStainPeriodMax,
          }

          // if (envData.isWarrantySpecial) {
          //   this.setState({
          //     detailEnvSelectIndex: index,
          //     detailEnvTemplateSelectIndex: templateIndex,
          //     detailEnvSelectValue: value,
          //   }, () => {
          //     this.modal.onOpenModal(modalTypeList[2]);
          //   });
          // }
          // else {
          dataDetail.templateList[templateIndex][name] = value;
          console.log('templatePeriodMax', dataDetail.templateList[templateIndex]);
          // }
          break;
        default: break;
      }

      data.productGroupList[index] = dataDetail;
      data.productGroupList = this.warrantyManage.handleUpdateRoofWall(data.productGroupList);
      this.setState({
        data
      })
    },
    onChangeProductGroupDataCheckbox: (ev, index, valueName) => {
      const {
        data,
      } = this.state;
      const name = ev.target.name;
      const checked = ev.target.checked;

      let dataDetail = data.productGroupList[index];
      dataDetail[name] = checked;
      dataDetail[valueName] = '';

      // CR: remove merged roof and wall for thai
      // dataDetail.isRoofAndWall = dataDetail[DATA.WARRANTY_APP_DETAIL_NAME_SELECT_LIST[0]] &&
      //   dataDetail[DATA.WARRANTY_APP_DETAIL_NAME_SELECT_LIST[1]];
      dataDetail.isRoofAndWall = data.projectCountry !== ENUM.COUNTRY_TYPE.THAI && (
        dataDetail[DATA.WARRANTY_APP_DETAIL_NAME_SELECT_LIST[0]] &&
        dataDetail[DATA.WARRANTY_APP_DETAIL_NAME_SELECT_LIST[1]]
      );

      dataDetail = this.input.handleCheckProductGroupSuccess(dataDetail);

      data.productGroupList[index] = dataDetail;

      // CR: remove merged roof and wall for thai
      data.productGroupList[index].isWarrantyPeriodSelected = false;

      this.setState({
        data
      })
    },
    onChangeCategory: (type) => {
      const {
        data,
      } = this.state;
      data.wrtCat = type;
      this.setState({
        data,
      })
    },
    onChangeDataDate: (date, name) => {
      const {
        data,
      } = this.state;
      data[name] = date;
      this.setState({
        data,
      })
    },
    onChangeApplicationInfoIndo: (value) => {
      const {
        data,
      } = this.state;
      if (data.applicationList.indexOf(value) >= 0) {
        data.applicationList = data.applicationList.filter(e => e !== value);
      }
      else {
        data.applicationList.push(value);
      }
      this.setState({
        data,
      })
    },
  }

  render() {
    const {
      data,
      modalTypeList,
      modalType,
      currentStepIndex,
      currentStepPercent,
      stepContentList,
      isPreviewWarranty,
      isAutomaticEnv,
    } = this.state;
    return (
      <React.Fragment>
        <Breadcrumb>
          <Breadcrumb.List
            link
            name={getLanguage('warrantyManage.breadcrumb1', '')}
            onClick={() => {
              redirect(ROUTE_PATH.HOME.LINK)
            }}
          />
          <Breadcrumb.List name='/' />
          <Breadcrumb.List
            link
            name={getLanguage('warrantyManage.breadcrumb2', '')}
            onClick={() => {
              this.warrantyManage.handleGoBack();
            }}
          />
          <Breadcrumb.List name='/' />
          <Breadcrumb.List name={getLanguage('warrantyManage.breadcrumb4', '')} />
        </Breadcrumb>
        <Section ui='content'>
          <Section spacingBottom={isPreviewWarranty ? '35' : '0'}>
            <TitlePage
              src={ICONS['ic-page-warranty.svg']}
              name={getLanguage('warrantyManage.title', '')}
            />
          </Section>
          <Step
            label
            progressPercent={currentStepPercent}
          >
            {stepContentList.map((e, i) => {
              return (
                <Step.Number
                  key={i}
                  disabled={this.warrantyManage.getDisableStepContent(i)}
                  active={currentStepIndex === i}
                  success={currentStepIndex > i}
                  number={i + 1}
                  label={e}
                />
              )
            })}
          </Step>
          {this.warrantyManage.renderContent()}
        </Section>
        {/* bswm v */}
        {/* {modalType === modalTypeList[0] &&
          <ModalChooseProjectCategoryContainer isOpenModal
            onClickClose={this.modal.onCloseModal}
            onClickConfirm={this.modal.onClickSubmitCategory}
            onChangeCategory={this.input.onChangeCategory}
            category={data.wrtCat}
            data={data}
            isShowNewUi
          />
        } */}
        {/* bswm ^ */}
        {modalType === modalTypeList[1] &&
          // <ModalInfoContainer isOpenModal
          //   title={getLanguage('modal.info.notice', '')}
          //   message={getLanguage('message.warrantyConditionNotPass', '')}
          //   onClickClose={this.modal.onCloseModal}
          //   onClickConfirm={this.modal.onClickWaitingApprove}
          // />
          <ModalConfirmContainer isOpenModal
            title={getLanguage('modal.info.notice', '')}
            message={getLanguage('message.warrantyConditionNotPass', '')}
            onClickCancel={this.modal.onCloseModal}
            onClickConfirm={this.modal.onClickWaitingApprove}
          />
        }
        {modalType === modalTypeList[2] &&
          <ModalConfirmContainer isOpenModal
            title={getLanguage('modal.info.notice', '')}
            message={getLanguage('message.warrantySelectEnvDetailSpecial', '')}
            onClickCancel={this.modal.onCloseModal}
            onClickConfirm={this.modal.onClickSubmitSelectEnvDetailSpecial}
          />
        }
        {modalType === modalTypeList[3] &&
          <ModalConfirmContainer isOpenModal
            title={getLanguage('modal.info.notice', '')}
            message={getLanguage('message.warrantySelectEnvDetailNA', '')}
            onClickCancel={this.modal.onCloseModal}
            onClickConfirm={this.modal.onClickSubmitSelectEnvDetailNA}
          />
        }
        {modalType === modalTypeList[4] &&
          <ModalConfirmContainer isOpenModal
            title={getLanguage('modal.info.notice', '')}
            message={getLanguage('message.warrantyNoWarrantyOffered', '')}
            onClickCancel={this.modal.onCloseModal}
            onClickConfirm={this.modal.onClickSubmitSelectEnvDetailNoWarrantyOffer}
          />
        }
        {modalType === modalTypeList[5] &&
          <ModalConfirmContainer isOpenModal
            title={getLanguage('modal.info.notice', '')}
            message={getLanguage('message.warrantySpecialRejectEnvironment', '')}
            onClickCancel={this.modal.onCloseModal}
            onClickConfirm={this.modal.onClickConfirmReject}
          />
        }
        {modalType === modalTypeList[6] &&
          <ModalConfirmContainer isOpenModal
            title={getLanguage('modal.info.notice', '')}
            message={getLanguage('message.warrantySpecialReject', '')}
            onClickCancel={this.modal.onCloseModal}
            onClickConfirm={this.modal.onClickConfirmReject}
          />
        }
        {modalType === modalTypeList[7] &&
          <ModalConfirmContainer isOpenModal
            title={getLanguage('modal.info.notice', '')}
            message={getLanguage('message.warrantySpecialWaitingToBeApprove', '')}
            onClickCancel={this.modal.onCloseModal}
            onClickConfirm={this.modal.onClickConfirmToBeApprove}
          />
        }
        <WarrantyTemplateContainerConnected
          ref={r => setWarrantyTemplateRef(r)}
          callbackPDFUploaded={this.warrantyManage.callbackPDFUploaded}
        />

        {data.wrtCat !== DATA.WARRANTY_CATEGORY_TYPE.RESIDENTIAL.TYPE &&
          <div style={{
            position: 'absolute',
            left: '-9999px',
            width: '1024px'
          }}>
            {!isAutomaticEnv &&
              <WarrantyEnvDetailContainer isTemplate
                ref='envDetailRef'
                data={data}
              />
            }
            <AccordionControl outline>
              <Accordion accordion>
                <WarrantyEnvConditionContainer isTemplate
                  ref='envConditionRef'
                  onChangeDataApplicationInput={() => { }}
                  data={data}
                  // CR: 1498
                  handleUpdateProductGroupData={this.warrantyManage.handleUpdateProductGroupData}
                />
              </Accordion>
            </AccordionControl>
          </div>
        }
      </React.Fragment>
    )
  }
}