import React from 'react'
import {
  Section,
  Breadcrumb,
  TitlePage,
  Grid,
  Field,
  Button,
} from './../../components'
import {
  VARIABLES,
  ICONS
} from './../../themes'
import { setSideMenuActive } from '../../helpers/functions/route';
import { ROUTE_PATH } from '../../helpers/constants/route';
import { redirect } from '../../helpers/functions/route';
import { getOrderInquiryList, getClearOrderInquiryParams } from '../../helpers/services/orderInquiry';
import { OrderInquiryListContainerConnected } from '../../containers/OrderInquiryListContainer';
import _ from 'lodash';
import { getLanguage } from '../../helpers/functions/language';


export class OrderInquiryScreen extends React.Component {

  constructor(props) {
    super(props);
    setSideMenuActive(props);
    this.state = {
      searchValue: '',
    }
  }

  input = {
    onChangeInput: (ev) => {
      const name = ev.target.name;
      const value = ev.target.value;
      this.setState({
        [name]: value,
      })
    },
    onClickSearch: () => {
      const {
        searchValue,
      } = this.state;
      let params = {
        ...getClearOrderInquiryParams(),
        page: 1,
        all_field: searchValue,
      };
      getOrderInquiryList(params);
    },
  }

  render() {
    const {
      searchValue,
    } = this.state

    return (
      <React.Fragment>
        <Breadcrumb>
          <Breadcrumb.List
            link
            name={getLanguage('orderInquiry.breadcrumb1', '')}
            onClick={() => {
              redirect(ROUTE_PATH.HOME.LINK)
            }}
          />
          <Breadcrumb.List name='/' />
          <Breadcrumb.List name={getLanguage('orderInquiry.breadcrumb2', '')} />
        </Breadcrumb>
        <Section ui='content'>
          <Section spacingBottom='35'>
            <Grid
              gutterVertical='15'
              justify='space-between'
            >
              <Grid.Column>
                <TitlePage
                  src={ICONS['ic-page-order-inquiry.svg']}
                  name={getLanguage('orderInquiry.title', '')}
                />
              </Grid.Column>
              <Grid.Column>
                <Grid gutter='35'>
                  <Grid.Column>
                    <Grid gutter='10'>
                      <Grid.Column>
                        <Field name='searchValue'
                          ui='blockLabelVerticalSearch'
                          width='285'
                          placeholder={getLanguage('orderInquiry.placeholder', '')}
                          value={searchValue}
                          onChange={this.input.onChangeInput}
                        />
                      </Grid.Column>
                      <Grid.Column>
                        <Button
                          lightBlue
                          name={getLanguage('orderInquiry.buttonSearch', '')}
                          width={VARIABLES.BUTTON.WIDTHS.W_105}
                          onClick={this.input.onClickSearch}
                        />
                      </Grid.Column>
                    </Grid>
                  </Grid.Column>
                </Grid>
              </Grid.Column>
            </Grid>
          </Section>
          <OrderInquiryListContainerConnected />
        </Section>
      </React.Fragment>
    )
  }
}
