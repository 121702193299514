import { props } from 'bluebird'
import {
  injectGlobal
} from 'styled-components'
import { ICONS } from '../../../themes'
import {
  default as VARIABLES
} from './../../../themes/styles/bases/variables'
// import {
//   default as TYPOGRAPHYS
// } from './../../../themes/styles/bases/typographys'
// import {
//   default as MIXINS
// } from './../../../themes/styles/helpers/mixins'

// Sample using image in style
// url(${require('./../../../themes/images/icons/icon-sample.svg')});

injectGlobal`
  .shape-container-base {
    /* Parent
    ------------------------------- */
    /* Childrens
    ------------------------------- */
    /* Modifiers
    ------------------------------- */
    &.is-dropdown {
      > .shape-container-content-base {
        box-shadow: ${VARIABLES.COLORS.SHADOW_2};
      }
    }

    &.is-product-information {
      width: 100%;
      /* max-width: 1050px; */
    }

    &.search-control {
      .search-control-container {}

      .earch-control-group-left {}

      .search-control-partition {}

      .earch-control-group-right {}
    }

    &.is-warranty-list {
      width: 100%;
      max-width: 1120px;
    }

    &.is-content-filter {}


    &.is-barcode-scan {
      position: relative;
      overflow: hidden;
      background: linear-gradient(92.1deg, ${VARIABLES.COLORS.GRADIENT_BLUE_3_START} -15.02%, ${VARIABLES.COLORS.GRADIENT_BLUE_3_END} 63.7%)  ;

    }
    &.is-barcode-scan::before {
      content: '';
      background: url(${ICONS['ic-coil-overlay.svg']}) no-repeat  ; 
      background-size: cover;
      position: absolute;
      width: 149px;
      height: 119px;
      right: 21px;
      bottom: 2px;
    }

    &.is-tooltips {
      position: relative;
      display: inline-block;

      .tooltips-section {
        visibility: hidden;
        min-width: 188px;
        background-color: ${VARIABLES.COLORS.WHITE};
        text-align: center;
        padding: 5px;
        border-radius: 6px;
        border: 1px solid ${VARIABLES.COLORS.GRAY_12};
        border-radius: 3px;
        position: absolute;
        z-index: ${VARIABLES.Z_INDEXS.LV_1};
        bottom: 100%;
        left: 50%;
        transform: translate(-50%, -5px);
        opacity: 0;
        transition: opacity 0.3s;
      }

      .tooltips-section::before {
        content: "";
        position: absolute;
        top: 100%;
        left: 50%;
        z-index:  ${VARIABLES.Z_INDEXS.LV_1};
        margin-left: -4px;
        border-width: 4px;
        border-style: solid;
        border-color: ${VARIABLES.COLORS.WHITE} transparent transparent transparent;
      }

      .tooltips-section::after {
        content: "";
        position: absolute;
        top: 100%;
        left: 50%;
        margin-left: -5px;
        border-width: 5px;
        border-style: solid;
        border-color: ${VARIABLES.COLORS.GRAY_12} transparent transparent transparent;
      }
    }

    &.is-tooltips:hover .tooltips-section {
      visibility: visible;
      opacity: 1;
    }



    /* Media queries
    ------------------------------- */
    &.search-control {
      .search-control-container {
        @media (max-width: ${VARIABLES.BREAKPOINTS.BP_1440}) {
          max-width: ${VARIABLES.BREAKPOINTS.BP_768};
          margin: auto;
        }

        > .grid-container-base {
          @media (max-width: ${VARIABLES.BREAKPOINTS.BP_1440}) {
            flex-direction: column;
          }
        }
      }

      .earch-control-group-left {}

      .search-control-partition {
        .shape-container-base,
        .shape-container-content-base {
          @media (max-width: ${VARIABLES.BREAKPOINTS.BP_1440}) {
            display: block;
            width: 100%;
            height: 1px;
          }
        }
      }

      .earch-control-group-right {}
    }

    &.is-content-filter {
      @media (max-width: ${VARIABLES.BREAKPOINTS.BP_1440}) {
        min-height: calc(100vh - 426px);
      }

      > .shape-container-content-base {
        @media (max-width: ${VARIABLES.BREAKPOINTS.BP_1440}) {
          min-height: calc(100vh - 426px);
        }
      }
    }

    &.is-login {
      @media (max-width: 428px) {
        width: auto;

        .shape-container-content-base {
          width: auto;
          padding: 30px;
        }

        .section-base {
          &.is-login-logo {
            margin-bottom: 25px;
          }

          &.is-login-title {
            margin-bottom: 30px;
          }
        }

        .image-base {
          &.is-login-logo {
            width: 220px;
            padding-bottom: 32px;
          }
        }

        img {
          &.is-login-ref-code {
            width: auto;
            height: 40px;
          }
        }
      }
    }

    &.is-year-warranty {
      > .shape-container-content-base {
        @media (max-width: 1023px) {
          padding-top: 0;
          padding-right: 0;
          padding-bottom: 0;
          padding-left: 0;
        }
      }
    }
  }
`
