import React from 'react';
import { getRouteParams } from '../../helpers/functions/route';
import { getWarranty } from '../../helpers/services/warranty';
import {
  Container,
  WarrantyBSWMStatus,
} from './../../components';
import {
  CONTENTS
} from './../../themes';

export class PublicWarrantyStatusScreen extends React.Component {

  constructor(props) {
    super(props);
    const country = getRouteParams(props, 'country');
    const adId = parseInt(getRouteParams(props, 'adId'));
    const wrtId = parseInt(getRouteParams(props, 'wrtId'));
    const wrtNo = getRouteParams(props, 'wrtNo');
    this.state = {
      country,
      adId,
      wrtId,
      wrtNo,
      data: {}
    }
  }

  componentDidMount() {
    this.warrantyStatus.getWarranty();
  }

  warrantyStatus = {
    getWarranty: async () => {
      const {
        country,
        adId,
        wrtNo,
      } = this.state;
      const params = {
        page: 1,
        limit: 1,
        auth_deal_id: adId,
        country: country,
        wrt_no: wrtNo,
      };
      const data = await getWarranty(params);
      this.setState({
        data: data || {},
      })
    }
  }

  render() {
    const {
      wrtNo,
      data,
    } = this.state;
    return (
      <Container
        justify='center'
        align='center'
        calcMinHeight='100vh'
        bgSize='cover'
        bgRepeat='no-repeat'
        bgPosition='center'
        bgImage={CONTENTS['bg-warranty-bswm-status.png']}
      >
        <WarrantyBSWMStatus
          warrantyNo={wrtNo}
          projectName={data.projectName}
          projectAddress={data.projectAddr}
        />
      </Container>
    )
  }
}
