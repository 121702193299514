import styled, {
  // css
} from 'styled-components'
import {
  default as VARIABLES
} from './../../../themes/styles/bases/variables'
// import {
//   default as TYPOGRAPHYS
// } from './../../../themes/styles/bases/typographys'
// import {
//   default as MIXINS
// } from './../../../themes/styles/helpers/mixins'

// Sample using image in style
// url(${require('./../../../themes/images/icons/icon-sample.svg')});

// Wrapper
// ============================================================
export const WarrantyBSWMStatusWrapper = styled.div`
  /* Parent
  ------------------------------- */
  width: 100%;
  max-width: 540px;
  padding: 15px;

  /* Childrens
  ------------------------------- */
  .warranty-bswms-status-container {
    padding: 50px 45px 45px;
    background-color: ${VARIABLES.COLORS.WHITE};
    border-radius: 6px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.25);

    @media (max-width: 414px) {
      padding: 30px 15px 15px;
    }
  }

  /* Modifiers
  ------------------------------- */
  /* Media queries
  ------------------------------- */
`
