import { customerController } from "../../apiService/apiController/customerService"
import { isValidResponse } from "../functions/validation";
import { storeDispatch, storeGetState } from "../../stores";
import { dataCreators, DATA_REDUX_STATE_KEY } from "../../actions/dataAction";
import { ModelCustomer } from "../../models/ModelCustomer";
import { displayDate } from "../functions/display";
import { handleSortParams, getCountryType, handleTrimParams } from "../functions/data";
import { DATE_MOMENT_FORMAT } from "../constants/data";
import { ENUM } from "../constants/enum";
import _ from 'lodash';

export const getCustomerList = async (params = {}) => {
  let userAuth = storeGetState().mainRedux.userAuth;
  let currentParams = storeGetState().dataRedux.customerParams;
  let newParams = handleTrimParams({
    ...currentParams,
    ...params,
    authorize_dealer_id: userAuth.id
  });
  if (newParams.isAll) {
    newParams.authorize_dealer_id = '';
  }
  if (newParams.searchFullname) {
    const nameList = newParams.searchFullname.split(' ');
    newParams.first_name = nameList[0];
    newParams.last_name = nameList[nameList.length - 1];
  }
  if (!newParams.isNoSort) {
    if (!newParams.sort) {
      newParams.sort = 'id desc';
    }
    else {
      newParams = handleSortParams(currentParams, newParams);
    }
  }
  const customerService = customerController();
  const res = await customerService.searchCustomerWithPagination(newParams);
  if (isValidResponse(res)) {
    const dataList = res.data.map(e => new ModelCustomer(e));
    newParams.activePage = newParams.page;
    newParams.totalData = res.total_items;
    if (params.callbackSuccess) {
      params.callbackSuccess(dataList);
    }
    console.log('dataList', res.data, dataList, newParams);
    storeDispatch(dataCreators.setDataRedux(DATA_REDUX_STATE_KEY.CUSTOMER_LIST, dataList));
    storeDispatch(dataCreators.setDataRedux(DATA_REDUX_STATE_KEY.CUSTOMER_PARAMS, newParams));
  }
}

export const getCustomer = async (params = {}, configService = {}) => {
  let userAuth = storeGetState().mainRedux.userAuth;
  let newParams = handleTrimParams({
    ...params,
    authorize_dealer_id: userAuth.id
  });
  const customerService = customerController(configService);
  const res = await customerService.searchCustomerWithPagination(newParams);
  if (isValidResponse(res)) {
    const dataList = res.data.map(e => new ModelCustomer(e));
    console.log('dataList', res.data, dataList, newParams);
    return dataList[0];
  }
}

export const getCustomerExportList = async () => {
  let userAuth = storeGetState().mainRedux.userAuth;
  let newParams = handleTrimParams({
    authorize_dealer_id: userAuth.id,
    sort: 'id desc',
  });
  const customerService = customerController();
  const res = await customerService.searchCustomerWithPagination(newParams);
  if (isValidResponse(res)) {
    const dataList = _.uniqBy(res.data.map(e => new ModelCustomer(e)), 'uid__c');
    console.log('dataList', res.data, dataList, newParams);
    return dataList;
  }
}

export const getClearCustomerParams = () => {
  return {
    id: '',
    first_name: '',
    last_name: '',
    nick_name: '',
    email: '',
    id_card_no: '',
    phone: '',
    remain_point: '',
    all_field: '',
    sort: '',
    searchFullname: '',
    trained_tier__c: '',
    isAll: false,
    isNoSort: false,
  }
}

export const getCustomerManageParams = (data, dataId) => {
  let userAuth = storeGetState().mainRedux.userAuth;
  //TODO: laos
  const idSubDistrict = data.idAddressType === ENUM.COUNTRY_TYPE.THAI ?
    (data.idSubDistrict || {}).label
    :
    data.idSubDistrict;
  //TODO: laos
  const idPostCode = data.idAddressType === ENUM.COUNTRY_TYPE.THAI ?
    (data.idPostCode || {}).label : data.idPostCode;
  //TODO: laos
  const contactSubDistrict = data.contactAddressType === ENUM.COUNTRY_TYPE.THAI ?
    (data.contactSubDistrict || {}).label
    :
    data.contactSubDistrict;
  //TODO: laos
  const contactPostCode = data.contactAddressType === ENUM.COUNTRY_TYPE.THAI ?
    (data.contactPostCode || {}).label : data.contactPostCode;

  return {
    id: dataId,
    first_name: data.firstname || '',
    last_name: data.lastname || '',
    nick_name: data.nickname || '',
    id_card_no: (data.idCardNo || '').length ? parseInt(data.idCardNo) : 0,
    email: data.email ? data.email : '',
    phone: data.phoneCountry.value + data.phone || '',
    address: JSON.stringify({
      address_text: data.idAddress,
      province: data.idAddressType === ENUM.COUNTRY_TYPE.LAOS ?
        data.idSelectedProvince
        :
        (data.idSelectedProvince || {}).value,
      city: data.idAddressType === ENUM.COUNTRY_TYPE.LAOS ?
        data.idSelectedCity
        :
        (data.idSelectedCity || {}).value,
      province_text: (data.idSelectedProvince || {}).label,
      city_text: (data.idSelectedCity || {}).label,
      sub_district: idSubDistrict,
      district: data.idDistrict,
      alley: data.idAlley,
      group: data.idGroup,
      street: data.idStreet,
      ward: data.idWard,
      address_type: data.phoneCountry.countryType,
    }),
    post_code: (idPostCode || '').length ? parseInt(idPostCode) : 0,
    image_url: data.oldImageRef || '',
    date_of_birth: displayDate(data.dateOfBirth, '-', DATE_MOMENT_FORMAT),
    company: data.company || '',
    type: data.type || '',
    contact_address: JSON.stringify({
      address_text: data.contactAddress,
      province: data.contactAddressType === ENUM.COUNTRY_TYPE.LAOS ?
        data.contactSelectedProvince
        :
        (data.contactSelectedProvince || {}).value,
      city: data.contactAddressType === ENUM.COUNTRY_TYPE.LAOS ?
        data.contactSelectedCity
        :
        (data.contactSelectedCity || {}).value,
      province_text: (data.contactSelectedProvince || {}).label,
      city_text: (data.contactSelectedCity || {}).label,
      sub_district: contactSubDistrict,
      district: data.contactDistrict,
      alley: data.contactAlley,
      group: data.contactGroup,
      street: data.contactStreet,
      ward: data.contactWard,
      address_type: data.phoneCountry.countryType,
    }),
    contact_post_code: (contactPostCode || '').length ? parseInt(contactPostCode) : 0,
    contact_channel: data.contactChannel || '',
    contact_phone: data.contactPhone || '',
    trained: data.isTrained ? data.isTrained : false,
    authorize_dealer_id: userAuth.id,
    customer_ref_id: data.customerRefId || '',
    user_ref_id: data.userRefId || '',
    no_sms: true,
  }
}