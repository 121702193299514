import React from 'react'
import {
  ShapeContainer,
  Section,
  Breadcrumb,
  TitlePage,
  Table,
  Button,
  Grid,
  TableAction,
  Field
} from './../../components'
import {
  VARIABLES,
  ICONS
} from './../../themes'
import { setSideMenuActive, getRouteData } from '../../helpers/functions/route';
import { ROUTE_PATH } from '../../helpers/constants/route';
import { redirect } from '../../helpers/functions/route';

import { ProductQuantityListContainerConnected } from '../../containers/ProductQuantityListContainer/ProductQuantityListContainer';
import { getLanguage } from '../../helpers/functions/language';

export class ProfileMappingProductGroupScreen extends React.Component {

  constructor(props) {
    super(props);
    const profile = getRouteData(props, 'profile');
    setSideMenuActive(props);
    this.state = {
      searchValue: '',
      filterValue: '',
      isProfile: true,
      profile
    }
  }

  input = {
    onChangeInput: (ev) => {
      const name = ev.target.name;
      const value = ev.target.value;
      this.setState({
        [name]: value,
      })
    },
    onClickSearch: () => {
      const {
        searchValue,
      } = this.state;
      this.setState({
        filterValue: searchValue.trim()
      })
    },
  }

  render() {
    const {
      searchValue,
      filterValue,
      isProfile,
      profile,
    } = this.state

    return (
      <React.Fragment>
        <Breadcrumb>
          <Breadcrumb.List
            link
            name='Home'
            onClick={() => {
              redirect(ROUTE_PATH.HOME.LINK)
            }}
          />
          <Breadcrumb.List name='/' />
          <Breadcrumb.List
            link
            name='Inventory'
          // onClick={() => {
          //   redirect(ROUTE_PATH.INVENTORY_INDEXES.LINK)
          // }}
          />
          <Breadcrumb.List name='/' />
          <Breadcrumb.List
            link
            name='Profile Mapping'
            onClick={() => {
              redirect(ROUTE_PATH.PROFILE_MAPPING.LINK)
            }}
          />
          <Breadcrumb.List name='/' />
          <Breadcrumb.List
            link
            name='Conxecute'
            onClick={() => {
              redirect(ROUTE_PATH.PROFILE_MAPPING_HARDWARE_AND_TOKO_SHOP_INFO.LINK)
            }}
          />
          <Breadcrumb.List name='/' />
          <Breadcrumb.List name='Products Quantity' />
        </Breadcrumb>
        <Section ui='content'>
          <Section spacingBottom='35'>
            <Grid
              gutterVertical='15'
              justify='space-between'
            >
              <Grid.Column>
                <TitlePage
                  src={ICONS['ic-page-inventory.svg']}
                  name='Conxecute > Products Quantity'
                />
              </Grid.Column>
              <Grid.Column>
                <Grid gutter='35'>
                  <Grid.Column>
                    <Grid gutter='10'>
                      <Grid.Column>
                        <Field name='searchValue'
                          ui='blockLabelVerticalSearch'
                          width='285'
                          placeholder={getLanguage('inventory.placeholder', '')}
                          value={searchValue}
                          onChange={this.input.onChangeInput}
                        />
                      </Grid.Column>
                      <Grid.Column>
                        <Button
                          lightBlue
                          name={getLanguage('inventory.buttonSearch', '')}
                          width={VARIABLES.BUTTON.WIDTHS.W_105}
                          onClick={this.input.onClickSearch}
                        />
                      </Grid.Column>
                    </Grid>
                  </Grid.Column>
                </Grid>
              </Grid.Column>
            </Grid>
          </Section>
          <ProductQuantityListContainerConnected
            filterValue={filterValue}
            isProfile={isProfile}
            profile={profile}
          />



          {/* <ShapeContainer
            ui='content'
            justify='space-between'
          >
            <Section spacingBottom='45'>
              <Table productQuantity>
                <Table.Section>
                  <Table.Row>
                    <Table.HeadColumn
                      maxWidth={VARIABLES.TABLE.COLUMN_WIDTHS.CHECKBOX}
                      minWidth={VARIABLES.TABLE.COLUMN_WIDTHS.CHECKBOX}
                      noSort
                    >
                      <Field
                        checked={false}
                        ui='checkboxTickCircle'
                        onChange={() => {}}
                      />
                    </Table.HeadColumn>
                    <Table.HeadColumn
                      minWidth={VARIABLES.TABLE.COLUMN_WIDTHS.NAMES.PRODUCT}
                      sortAscending={true}
                      // onClickSort={}
                    >
                      Product Group
                    </Table.HeadColumn>
                    <Table.HeadColumn
                      maxWidth={VARIABLES.TABLE.COLUMN_WIDTHS.NUMBERS.COIL}
                      minWidth={VARIABLES.TABLE.COLUMN_WIDTHS.NUMBERS.COIL}
                      sortAscending={true}
                      // onClickSort={}
                    >
                      No. of Coils
                    </Table.HeadColumn>
                    <Table.HeadColumn
                      alignRight
                      maxWidth={VARIABLES.TABLE.COLUMN_WIDTHS.MEASUREMENTS.WEIGHT}
                      minWidth={VARIABLES.TABLE.COLUMN_WIDTHS.MEASUREMENTS.WEIGHT}
                      sortAscending={true}
                      // onClickSort={}
                    >
                      Weight (Kg.)
                    </Table.HeadColumn>
                    <Table.HeadColumn
                      alignRight
                      maxWidth={VARIABLES.TABLE.COLUMN_WIDTHS.MEASUREMENTS.LENGTH}
                      minWidth={VARIABLES.TABLE.COLUMN_WIDTHS.MEASUREMENTS.LENGTH}
                      sortAscending={true}
                      // onClickSort={}
                    >
                      Length (m.)
                  </Table.HeadColumn>
                  </Table.Row>
                </Table.Section>
                <Table.SectionControl gradientBlue>
                  <Table.Row>
                    <Table.BodyColumn
                      action
                      maxWidth={VARIABLES.TABLE.COLUMN_WIDTHS.CHECKBOX}
                      minWidth={VARIABLES.TABLE.COLUMN_WIDTHS.CHECKBOX}
                    >
                      <Field
                        checked={true}
                        ui='checkboxTickCircle'
                        onChange={() => {}}
                      />
                    </Table.BodyColumn>
                    <Table.BodyColumn
                      info
                      minWidth={VARIABLES.TABLE.COLUMN_WIDTHS.NAMES.PRODUCT}
                      title='BlueScope Zacs Cool'
                    >
                      BlueScope Zacs Cool
                    </Table.BodyColumn>
                    <Table.BodyColumn
                      info
                      maxWidth={VARIABLES.TABLE.COLUMN_WIDTHS.NUMBERS.COIL}
                      minWidth={VARIABLES.TABLE.COLUMN_WIDTHS.NUMBERS.COIL}
                      title='1'
                    >
                      1
                    </Table.BodyColumn>
                    <Table.BodyColumn
                      info
                      alignRight
                      maxWidth={VARIABLES.TABLE.COLUMN_WIDTHS.MEASUREMENTS.WEIGHT}
                      minWidth={VARIABLES.TABLE.COLUMN_WIDTHS.MEASUREMENTS.WEIGHT}
                      title='4,382'
                    >
                      4,382
                    </Table.BodyColumn>
                    <Table.BodyColumn
                      info
                      alignRight
                      maxWidth={VARIABLES.TABLE.COLUMN_WIDTHS.MEASUREMENTS.LENGTH}
                      minWidth={VARIABLES.TABLE.COLUMN_WIDTHS.MEASUREMENTS.LENGTH}
                      title='26,138'
                    >
                      26,138
                    </Table.BodyColumn>
                  </Table.Row>
                  <Table.Row>
                    <Table.BodyColumn
                      action
                      maxWidth={VARIABLES.TABLE.COLUMN_WIDTHS.CHECKBOX}
                      minWidth={VARIABLES.TABLE.COLUMN_WIDTHS.CHECKBOX}
                    >
                      <Field
                        checked={false}
                        ui='checkboxTickCircle'
                        onChange={() => {}}
                      />
                    </Table.BodyColumn>
                    <Table.BodyColumn
                      info
                      minWidth={VARIABLES.TABLE.COLUMN_WIDTHS.NAMES.PRODUCT}
                      title='BlueScope Zacs Dazzle'
                    >
                      BlueScope Zacs Dazzle
                    </Table.BodyColumn>
                    <Table.BodyColumn
                      info
                      maxWidth={VARIABLES.TABLE.COLUMN_WIDTHS.NUMBERS.COIL}
                      minWidth={VARIABLES.TABLE.COLUMN_WIDTHS.NUMBERS.COIL}
                      title='1'
                    >
                      1
                    </Table.BodyColumn>
                    <Table.BodyColumn
                      info
                      alignRight
                      maxWidth={VARIABLES.TABLE.COLUMN_WIDTHS.MEASUREMENTS.WEIGHT}
                      minWidth={VARIABLES.TABLE.COLUMN_WIDTHS.MEASUREMENTS.WEIGHT}
                      title='4,925'
                    >
                      4,925
                    </Table.BodyColumn>
                    <Table.BodyColumn
                      info
                      alignRight
                      maxWidth={VARIABLES.TABLE.COLUMN_WIDTHS.MEASUREMENTS.LENGTH}
                      minWidth={VARIABLES.TABLE.COLUMN_WIDTHS.MEASUREMENTS.LENGTH}
                      title='39,519'
                    >
                      39,519
                    </Table.BodyColumn>
                  </Table.Row>
                </Table.SectionControl>
                <Table.SectionControl blue>
                  <Table.Row>
                    <Table.BodyColumn
                      result
                      maxWidth={VARIABLES.TABLE.COLUMN_WIDTHS.CHECKBOX}
                      minWidth={VARIABLES.TABLE.COLUMN_WIDTHS.CHECKBOX}
                    >
                      &nbsp;
                    </Table.BodyColumn>
                    <Table.BodyColumn
                      result
                      minWidth={VARIABLES.TABLE.COLUMN_WIDTHS.NAMES.PRODUCT}
                    >
                      Total
                    </Table.BodyColumn>
                    <Table.BodyColumn
                      result
                      maxWidth={VARIABLES.TABLE.COLUMN_WIDTHS.NUMBERS.COIL}
                      minWidth={VARIABLES.TABLE.COLUMN_WIDTHS.NUMBERS.COIL}
                      title='2'
                    >
                      2
                    </Table.BodyColumn>
                    <Table.BodyColumn
                      result
                      alignRight
                      maxWidth={VARIABLES.TABLE.COLUMN_WIDTHS.MEASUREMENTS.WEIGHT}
                      minWidth={VARIABLES.TABLE.COLUMN_WIDTHS.MEASUREMENTS.WEIGHT}
                      title='9,307'
                    >
                      9,307
                    </Table.BodyColumn>
                    <Table.BodyColumn
                      result
                      alignRight
                      maxWidth={VARIABLES.TABLE.COLUMN_WIDTHS.MEASUREMENTS.LENGTH}
                      minWidth={VARIABLES.TABLE.COLUMN_WIDTHS.MEASUREMENTS.LENGTH}
                      title='65,657'
                    >
                      65,657
                    </Table.BodyColumn>
                  </Table.Row>
                </Table.SectionControl>
              </Table>
            </Section>
            <TableAction>
              <TableAction.Left>
                <Grid gutter='15'>
                  <Grid.Column>
                    <Button
                      blue
                      ui='frontIcon'
                      name='Print'
                      width={VARIABLES.BUTTON.WIDTHS.W_125}
                      iconWidth='18'
                      iconHeight='18'
                      iconSrc={ICONS['ic-printer.svg']}
                      onClick={() => { }}
                    />
                  </Grid.Column>
                  <Grid.Column>
                    <Button
                      green
                      ui='frontIcon'
                      name='Export'
                      width={VARIABLES.BUTTON.WIDTHS.W_125}
                      iconWidth='16'
                      iconHeight='16'
                      iconSrc={ICONS['ic-export.svg']}
                      onClick={() => { }}
                    />
                  </Grid.Column>
                </Grid>
              </TableAction.Left>
              <TableAction.Right>
                <Grid gutter='15'>
                  <Grid.Column>
                    <Button
                      gray
                      ui='back'
                      name='Back'
                      onClick={() => {
                        redirect(ROUTE_PATH.PROFILE_MAPPING_HARDWARE_AND_TOKO_SHOP_INFO.LINK)
                      }}
                    />
                  </Grid.Column>
                  <Grid.Column>
                    <Button
                      lightBlue
                      disabled={false}
                      ui='frontIcon'
                      name='View Detail'
                      width={VARIABLES.BUTTON.WIDTHS.W_165}
                      iconWidth='14'
                      iconHeight='14'
                      iconSrc={ICONS['ic-detail.svg']}
                      onClick={() => {
                        redirect(ROUTE_PATH.PROFILE_MAPPING_PRODUCT_LIST.LINK)
                      }}
                    />
                  </Grid.Column>
                </Grid>
              </TableAction.Right>
            </TableAction>
          </ShapeContainer> */}
        </Section>
      </React.Fragment>
    )
  }
}
