import apiService from '../apiService'
import { BASE_CMS_API, BASE_CMS_PORT_API, BASE_CMS_PATH_API } from '../../config/config'

const apiPath = '/reward'

export const cmsRewardCatalogController = (configService = {}) => {
  configService.baseApiPath = BASE_CMS_API + BASE_CMS_PORT_API + BASE_CMS_PATH_API;
  const service = apiService(configService)
  return {
    searchRewardCatalog: (params) => {
      return service.post(`${apiPath}/search`, params)
    },
  }
}