import styled, {
  // css
} from 'styled-components'
// import {
//   default as VARIABLES
// } from './../../../themes/styles/bases/variables'
// import {
//   default as TYPOGRAPHYS
// } from './../../../themes/styles/bases/typographys'
// import {
//   default as MIXINS
// } from './../../../themes/styles/helpers/mixins'

// Sample using image in style
// url(${require('./../../../themes/images/icons/icon-sample.svg')});

// Wrapper
// ============================================================
export const TitlePageWrapper = styled.div`
  /* Parent
  ------------------------------- */
  /* Childrens
  ------------------------------- */
  /* Modifiers
  ------------------------------- */
  &.is-year-warranty {
    .grid-base {
      .grid-container-base {
        @media (max-width: 1279px) {
          justify-content: center;
        }
      }
    }
  }

  /* Media queries
  ------------------------------- */
`
