import { ModelNoti, ModelNotiCMS } from "../../models/ModelNoti"
import { DATA } from "../constants/data"
import { ENUM } from "../constants/enum";
import { firebaseAddNoti, firebaseAddNotiCMS } from "../firebases/firebaseNotification"
import { getUserAuthRedux } from "./auth";

export const addNotiCoilTransferScan = (params = {}) => {
  const userAuth = getUserAuthRedux();
  const data = new ModelNoti({
    title: `${params.coilId} has requested ${params.length} (m.)`,
    description: `From Scan Barcode, ${userAuth.company}`,
    type: DATA.NOTIFICATION_TYPE.COIL_TRANSFER,
    data: params,
  }).firebase();
  firebaseAddNoti({
    ...data,
    dealerAccount: params.fromDealer
  });
}

export const addNotiCreateLibPreSaleCMS = (params = {}) => {
  const userAuth = getUserAuthRedux();
  if (userAuth.addressType === ENUM.COUNTRY_TYPE.THAI) {
    firebaseAddNotiCMS(new ModelNotiCMS({
      title: `${userAuth.company} has created a library`,
      description: `From Project Document, ${params.projectName}`,
      type: DATA.NOTIFICATION_CMS_TYPE.LIBRARY,
      data: params,
    }).firebase());
  }
}