import React from 'react'
import {
  Section,
  Breadcrumb,
  TitlePage,
  Grid,
  Button,
  Field,
} from './../../components'
import {
  VARIABLES,
  ICONS
} from './../../themes'
import { setSideMenuActive, getRouteData, redirectData } from '../../helpers/functions/route';
import { ROUTE_PATH } from '../../helpers/constants/route';
import { redirect } from '../../helpers/functions/route';
import { RewardCatalogListContainerConnected } from '../../containers/RewardCatalogListContainer';
import { getCMSRewardCatalogList, getClearCMSRewardParams } from '../../helpers/services/cmsRewardCatalog';
import _ from 'lodash';
import { getLanguage } from '../../helpers/functions/language';
import { storeGetState } from '../../stores';
import { getUserAuthRedux } from '../../helpers/functions/auth';
import { ENUM } from '../../helpers/constants/enum';

export class RedemptionListOfRewardsScreen extends React.Component {

  constructor(props) {
    super(props);
    setSideMenuActive(props);
    let data = getRouteData(props, 'data');
    let selectSalePerson = getRouteData(props, 'selectSalePerson');
    const userAuth = getUserAuthRedux();
    if (!data || !selectSalePerson) {
      data = {}
      redirect(ROUTE_PATH.REDEMPTION.LINK)
    }
    this.state = {
      data,
      searchValue: '',
      selectSalePerson,
      isMalay: userAuth.addressType === ENUM.COUNTRY_TYPE.MALAYSIA,
    }
    console.log('data', data)
    console.log('selectSalePerson', selectSalePerson)
  }

  redemptionRewardList = {
    goBack: () => {
      const {
        data,
      } = this.state;
      redirectData(ROUTE_PATH.REDEMPTION.LINK, {
        data
      })
    }
  }

  input = {
    onChangeInput: (ev) => {
      const name = ev.target.name;
      const value = ev.target.value;
      this.setState({
        [name]: value,
      })
    },
    onClickSearch: () => {
      const {
        searchValue,
      } = this.state;
      const rewardSelectedList = storeGetState().dataRedux.cmsRewardCatalogParams.rewardSelectedList
      let params = {
        ...getClearCMSRewardParams(),
        page: 1,
        rewardSelectedList,
        name: searchValue,
      };
      getCMSRewardCatalogList(params);
    },
  }

  render() {
    const {
      searchValue,
      selectSalePerson,
      data,
      isMalay,
    } = this.state

    return (
      <React.Fragment>
        <Breadcrumb>
          <Breadcrumb.List
            link
            name={getLanguage('redemptionReward.breadcrumb1', '')}
            onClick={() => {
              redirect(ROUTE_PATH.HOME.LINK)
            }}
          />
          <Breadcrumb.List name='/' />
          <Breadcrumb.List
            link
            name={getLanguage('redemptionReward.breadcrumb2', '')}
          // onClick={() => {
          //   redirect(ROUTE_PATH.REWARDS.LINK)
          // }}
          />
          <Breadcrumb.List name='/' />
          <Breadcrumb.List
            link
            name={getLanguage('redemptionReward.breadcrumb3', '')}
            onClick={this.redemptionRewardList.goBack}
          />
          <Breadcrumb.List name='/' />
          <Breadcrumb.List name={getLanguage('redemptionReward.breadcrumb4', '')} />
        </Breadcrumb>
        <Section ui='content'>
          <Section spacingBottom='35'>
            <Grid
              gutterVertical='15'
              justify='space-between'
            >
              <Grid.Column>
                <TitlePage
                  src={ICONS['ic-page-rewards.svg']}
                  name={getLanguage('redemptionReward.title', '')}
                />
              </Grid.Column>
              <Grid.Column>
                <Grid gutter='10'>
                  <Grid.Column>
                    <Field name='searchValue'
                      ui='blockLabelVerticalSearch'
                      width='285'
                      placeholder={getLanguage('redemptionReward.placeholder', '')}
                      value={searchValue}
                      onChange={this.input.onChangeInput}
                    />
                  </Grid.Column>
                  <Grid.Column>
                    <Button
                      lightBlue
                      name={getLanguage('redemptionReward.buttonSearch', '')}
                      width={VARIABLES.BUTTON.WIDTHS.W_105}
                      onClick={this.input.onClickSearch}
                    />
                  </Grid.Column>
                </Grid>
              </Grid.Column>
            </Grid>
          </Section>
          <RewardCatalogListContainerConnected
            isMalay={isMalay}
            customerData={data}
            selectSalePerson={selectSalePerson}
            goBack={this.redemptionRewardList.goBack}
          />
        </Section>
      </React.Fragment>
    )
  }
}
