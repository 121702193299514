import apiService from '../apiService'

export const segmentController = (configService = {}) => {
  const service = apiService(configService)
  return {
    searchSegment: (params) => {
      return service.post(`/segment/search`, params)
    },
    searchSubSegment: (params) => {
      return service.post(`/sub-segment/search`, params)
    },
  }
}
