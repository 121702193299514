import { isValidResponse } from "../functions/validation";
import { storeDispatch, storeGetState } from "../../stores";
import { dataCreators, DATA_REDUX_STATE_KEY } from "../../actions/dataAction";
import { pointController } from "../../apiService/apiController/pointService";
import { ModelPoint } from "../../models/ModelPoint";
import { handleSortParams, handleTrimParams } from "../functions/data";
import { ModelExpirePoint } from "../../models/ModelExpirePoint";

export const getPointList = async (params = {}) => {
  let userAuth = storeGetState().mainRedux.userAuth;
  let currentParams = storeGetState().dataRedux.pointParams;
  let newParams = handleTrimParams({
    ...currentParams,
    ...params,
    ad_ref_id: userAuth.adRefId
  });
  if(!newParams.sort){
    newParams.sort = 'id desc';
  }
  else {
    newParams = handleSortParams(currentParams, newParams);
  }
  const pointService = pointController();
  const res = await pointService.getPoint(newParams);
  if (isValidResponse(res)) {
    const dataList = res.points.map(e => new ModelPoint(e));
    const expirePointList = (res.expire_points || []).map(e => new ModelExpirePoint(e));
    newParams.activePage = newParams.page;
    newParams.totalData = res.total_items;
    if (newParams.callbackSuccess) {
      newParams.callbackSuccess(dataList);
    }
    console.log('dataList', res.points, dataList, newParams);
    storeDispatch(dataCreators.setDataRedux(DATA_REDUX_STATE_KEY.POINT_LIST, dataList));
    storeDispatch(dataCreators.setDataRedux(DATA_REDUX_STATE_KEY.EXPIRE_POINT_LIST, expirePointList));
    storeDispatch(dataCreators.setDataRedux(DATA_REDUX_STATE_KEY.POINT_PARAMS, newParams));
  }
}