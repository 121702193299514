import apiService from '../apiService';

export const notificationManagementController = (configService = {}) => {
  const service = apiService(configService)
  return {
    getNotificationManagement: () => {
      return service.get(`/notification`)
    },
    acceptNotificationManagement: (params) => {
      return service.post(`/notification/accept`, params)
    },
    notificationManagementAcceptSearch: (params) => {
      return service.post(`/notification/accept/search`, params)
    },
  }
}
