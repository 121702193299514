import { DATE_FORMAT, DATE_TIME_FORMAT } from "../helpers/constants/data";
import { ENUM } from "../helpers/constants/enum";
import { getCityByLabel, getCityThaiByLabel, getCountryPhone, getPhoneRemovePrefix, getProvinceByLabel, getProvinceThaiByLabel, getSubDistrictOption, getSubDistrictThaiByLabel } from "../helpers/functions/data";
import { displayDate, displayUrlPath, zeroPad } from "../helpers/functions/display";
import { VARIABLES } from "../themes";

export class ModelProjectDocument {
  constructor(props = {}) {
    this.id = props.pre_sales_trans_id;
    this.preSalesTransId = props.pre_sales_trans_id;
    this.createdDate = props.created_date;
    this.preSalesTransCode = props.pre_sales_trans_code;
    this.preSalesTransProjectName = props.pre_sales_trans_project_name;
    this.customerName = props.customer_name;
    this.customerId = props.customer_id;
    this.phone = props.phone;
    this.isDocumentary = props.is_documentary;
    this.isAdmin = props.is_admin;
    this.updatedDate = props.updated_date;

    this.salesTransList = (props.sales_trans || []).map(e => {
      return {
        createdDate: e.created_date,
        salesTransId: e.sales_trans_id,

        displayCreatedDate: displayDate(e.created_date, '/', DATE_FORMAT),
      }
    });

    this.displayPhone = getPhoneRemovePrefix(this.phone);
    this.projectPhoneCountry = getCountryPhone(this.phone);
    this.displayCreatedDate = displayDate(this.createdDate, '/', DATE_FORMAT);
    this.displayUpdatedDate = displayDate(this.updatedDate, '/', DATE_FORMAT);
  }
}

export class ModelProjectDocumentInformation {
  constructor(props = {}) {
    this.id = props.pre_sales_trans_id;
    this.preSalesTransId = props.pre_sales_trans_id;
    this.preSalesTransCode = props.pre_sales_trans_code;
    this.preSalesTransProjectName = props.pre_sales_trans_project_name;
    this.preSalesTransProjectAddr = props.pre_sales_trans_project_addr;
    this.preSalesTransSubDist = props.pre_sales_trans_sub_dist;
    this.preSalesTransDist = props.pre_sales_trans_dist;
    this.preSalesTransProv = props.pre_sales_trans_prov;
    this.preSalesTransPostCode = props.pre_sales_trans_post_code;
    this.customerId = props.customer_id;
    this.segmentId = props.segment_id;
    this.subSegmentId = props.sub_segment_id;
    this.segmentName = props.segment_name;
    this.segmentDesc = props.segment_desc;
    this.subSegmentName = props.sub_segment_name;
    this.subSegmentDesc = props.sub_segment_desc;
    this.isDocumentary = props.is_documentary;
    this.isDisableWarrantyTab = props.is_disable_warranty_tab;
    this.status = props.status__c;

    this.displayPhone = getPhoneRemovePrefix(this.phone);
    this.projectPhoneCountry = getCountryPhone(this.phone);
    this.displayCreatedDate = displayDate(this.createdDate, '/', DATE_FORMAT);
    this.selectedProvince = getProvinceThaiByLabel(this.preSalesTransProv || '');
    this.selectedCity = getCityThaiByLabel(this.preSalesTransDist, this.selectedProvince);
    this.subDistrict = getSubDistrictThaiByLabel(this.preSalesTransSubDist, this.selectedCity);
    this.postCode = {
      label: this.preSalesTransPostCode,
      value: this.preSalesTransPostCode,
    }
  }
}

export class ModelProjectDocumentProjectName {
  constructor(props = {}) {
    this.id = props.pre_sales_trans_id;
    this.preSalesTransId = props.pre_sales_trans_id;
    this.preSalesTransProjectName = props.pre_sales_trans_project_name;
  }
}

export class ModelProjectDocumentDocumentary {
  constructor(props = {}) {
    this.preSalesTransDocId = props.pre_sales_trans_doc_id;
    this.preSalesTransM3ProdGrpName = props.pre_sales_trans_m3_prod_grp_name;
    this.createdDate = props.created_date;
    this.preSalesTransDocPath = props.pre_sales_trans_doc_path;
    this.preSalesTransId = props.pre_sales_trans_id;
    this.docMsId = props.doc_ms_id;
    this.docMsName = props.doc_ms_name || '';
    this.docMsCode = props.doc_ms_code || '';
    this.docMsCate = props.doc_ms_cate || '';

    this.id = this.preSalesTransDocId;
    this.displayCode = `PRETDOC${zeroPad(this.id, 7)}`;
    this.displayDocMsName = `${this.docMsCate} ${this.docMsCode} ${this.docMsName}`;
    this.displayCreatedDate = displayDate(this.createdDate, '/', DATE_TIME_FORMAT);
    this.fileUrl = displayUrlPath(this.preSalesTransDocPath || '');
  }
}

export class ModelProjectDocumentCertificateSearch {
  constructor(props = {}) {
    this.preSalesTransCertId = props.pre_sales_trans_cert_id;
    this.preSalesTransM3ProdGrpName = props.pre_sales_trans_m3_prod_grp_name;
    this.createdDate = props.created_date;
    this.preSalesTransCertPath = props.pre_sales_trans_cert_path;
    this.preSalesTransCertPostPath = props.pre_sales_trans_cert_post_path;
    this.preSalesTransId = props.pre_sales_trans_id;
    this.docMsId = props.doc_ms_id;
    this.docMsName = props.doc_ms_name || '';
    this.docMsCode = props.doc_ms_code || '';
    this.docMsCate = props.doc_ms_cate || '';
    this.isPostCertificate = props.is_post_certificate;
    this.certificateSettingType = props.certificate_setting_type;
    this.isCanRegenerate = props.cert_can_regenerate;
    this.productionStartDate = props.production_start_date;
    this.productionEndDate = props.production_end_date;
    this.preSalesTransCertDTId = props.pre_sales_trans_cert_dt_id;

    this.id = this.preSalesTransCertId;
    this.displayCode = `PRETCER${zeroPad(this.id, 7)}`;
    this.displayDocMsName = `${this.docMsCate} ${this.docMsCode} ${this.docMsName}`;
    this.displayCreatedDate = displayDate(this.createdDate, '/', DATE_TIME_FORMAT);
    this.fileUrl = displayUrlPath(this.preSalesTransCertPath || '');
    this.filePostUrl = displayUrlPath(this.preSalesTransCertPostPath || '');
    this.isShowPreCertificate = this.certificateSettingType === ENUM.PROJECT_DOCUMENT.CERTIFICATE_SETTING_TYPE.PRE ||
      this.certificateSettingType === ENUM.PROJECT_DOCUMENT.CERTIFICATE_SETTING_TYPE.PRE_POST
    this.isShowPostCertificate = this.certificateSettingType === ENUM.PROJECT_DOCUMENT.CERTIFICATE_SETTING_TYPE.POST ||
      this.certificateSettingType === ENUM.PROJECT_DOCUMENT.CERTIFICATE_SETTING_TYPE.PRE_POST

    this.displayProductionStartDate = displayDate(this.productionStartDate, '/', DATE_FORMAT, 'th');
    this.displayProductionEndDate = displayDate(this.productionEndDate, '/', DATE_FORMAT, 'th');
  }
}

export class ModelProjectDocumentCertificate {
  constructor(props = {}) {
    //pre_sales_trans_cert_id
    this.id = props.doc_ms_id;
    this.docMsId = props.doc_ms_id;
    this.preSalesTransCertPath = props.pre_sales_trans_cert_path;
    this.fileUrl = displayUrlPath(this.preSalesTransCertPath || '');
    //pre_sales_trans_id
    this.preSalesTransCertId = props.pre_sales_trans_cert_id;
    this.preSalesTransM3ProdGrpName = props.pre_sales_trans_m3_prod_grp_name;
    this.docMsCateList = (props.doc_ms_cate || []).map(e => {
      return {
        docMsCate: e.doc_ms_cate,
        docMsId: e.doc_ms_id,
        preSalesTransCertDtCode: e.pre_sales_trans_cert_dt_code || '',
        preSalesTransCertDTId: e.pre_sales_trans_cert_dt_id
      }
    });

    this.docMsSelectedIdList = this.docMsCateList.map(e => e.docMsId);
  }
}

export class ModelProjectDocumentDocumentaryDetail {
  constructor(props = {}) {
    this.id = props.pre_sales_trans_doc_id;
    this.preSalesTransDocId = props.pre_sales_trans_doc_id;
    this.preSalesTransM3ProdGrpName = props.pre_sales_trans_m3_prod_grp_name;
    this.docMsCateList = (props.doc_ms_cate || []).map(e => {
      return {
        docMsCate: e.doc_ms_cate,
        docMsId: e.doc_ms_id,
      }
    });

    this.docMsSelectedIdList = this.docMsCateList.map(e => e.docMsId);
  }
}

export class ModelProjectDocumentM3Product {
  constructor(props = {}) {
    this.m3ProductGroupName = props.m3_product_group_name;
  }
}

export class ModelProjectDocumentLog {
  constructor(props = {}) {
    this.preSalesTransLogId = props.pre_sales_trans_log_id;
    this.preSalesTransId = props.pre_sales_trans_id;
    this.preSalesTransLogCate = props.pre_sales_trans_log_cate;
    this.preSalesTransLogProdGrp = props.pre_sales_trans_log_prod_grp;
    this.preSalesTransLogDesc = props.pre_sales_trans_log_desc;
    this.createdBy = props.created_by;
    this.createdDate = props.created_date;

    this.displayCreatedDate = displayDate(this.createdDate, '/', DATE_TIME_FORMAT);
  }
}

export class ModelProjectDocumentSearchLog {
  constructor(props = {}) {
    this.preSalesTransLogId = props.pre_sales_trans_log_id;
    this.preSalesTransId = props.pre_sales_trans_id;
    this.preSalesTransLogCate = props.pre_sales_trans_log_cate;
    this.preSalesTransLogProdGrp = props.pre_sales_trans_log_prod_grp;
    this.preSalesTransLogDesc = props.pre_sales_trans_log_desc;
    this.createdBy = props.created_by;
    this.createdDate = props.created_date;
    this.preSalesTransLogPath = props.pre_sales_trans_log_path;
    this.preSalesTransLogName = props.pre_sales_trans_log_name;

    this.displayCreatedDate = displayDate(this.createdDate, '/', DATE_FORMAT);
    this.fileUrl = displayUrlPath(this.preSalesTransLogPath || '');
  }
}

// export class ModelProjectDocumentSearchWarranty {
//   constructor(props = {}) {
//     this.created_date = props.created_date;
//     this.sales_trans_id = props.sales_trans_id;
//     this.wrt_no = props.wrt_no;
//     this.product_group_name = props.product_group_name;
//     this.coil_order_m = props.coil_order_m;
//     this.phone_no = props.phone_no;

//     this.displayCreatedDate = displayDate(this.createdDate, '/', DATE_FORMAT);
//   }
// }

export class ModelProjectDocumentGetAll {
  constructor(props = {}) {
    this.preSalesTransId = props.pre_sales_trans_id;
    this.preSalesTransCode = props.pre_sales_trans_code;
    this.preSalesTransProjectName = props.pre_sales_trans_project_name;

    this.id = this.preSalesTransId;
  }
}
