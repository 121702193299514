import React from 'react';
// import {
//   connect,
// } from 'react-redux';
import { ModalWarrantyTncMalaysiaContent } from './ModalWarrantyTncMalaysiaContent'
import {
  Section,
  Grid,
  Modal,
  Text,
  Button,
  Field,
  ShapeContainer,
} from './../../../components'
import {
  VARIABLES, ICONS,
} from './../../../themes'
import { getLanguage } from '../../../helpers/functions/language';
import { DATA } from '../../../helpers/constants/data';
import { ENUM } from '../../../helpers/constants/enum';
// import { MODAL_TYPE } from '../../../helpers/constants/main'
// import { MAIN_REDUX_STATE_KEY } from '../../../actions/mainAction'

export class ModalWarrantyTncContainer extends React.Component {
  render() {
    const {
      isOpenModal,
      isSelected,
      onChangeCheckbox,
      onClickClose,
      dataDetail,
    } = this.props
    return (
      <Modal
        open={isOpenModal}
        scroll
        // enableOverlayClose={false}
        width={VARIABLES.MODAL.WIDTHS.W_960}
        overlayColor={VARIABLES.COLORS.OVERLAY_BLACK_1}
        spacing={VARIABLES.MODAL.CONTAINER.C_1.SPACING}
        transition={VARIABLES.TRANSITIONS.DEFAULT}
        transformStart={VARIABLES.MODAL.CONTAINER.C_1.TRANSFORM_START}
        transformEnd={VARIABLES.MODAL.CONTAINER.C_1.TRANSFORM_END}
      // onClickClose={onClickClose}
      >
        <Modal.Section
          paddingVertical='30'
          paddingHorizontal='40'
          bgColor={VARIABLES.COLORS.WHITE}
          borderBottomWidth='1'
          borderBottomStyle='solid'
          borderBottomColor={VARIABLES.COLORS.GRAY_8}
          borderTopRightRadius={VARIABLES.MODAL.HEADER.H_1.BORDER_TOP_RIGHT_RADIUS}
          borderTopLeftRadius={VARIABLES.MODAL.HEADER.H_1.BORDER_TOP_LEFT_RADIUS}
        >
          <Grid gutter='10' align='center'>
            <Grid.Column>
              <img src={ICONS['ic-notification-info.svg']} alt='Icon' />
            </Grid.Column>
            <Grid.Column>
              <Text regular16 singleLine color={VARIABLES.COLORS.BLACK}>
                {getLanguage('modal.termsAndConditions.title', '')}
              </Text>
            </Grid.Column>
          </Grid>
        </Modal.Section>
        <Modal.Section
          paddingVertical='30'
          paddingHorizontal='40'
          bgColor={VARIABLES.COLORS.WHITE}
        >
          <Section justify='center' spacingBottom='25'>
            <Text bold16 color={VARIABLES.COLORS.BLACK}>
              {getLanguage('modal.termsAndConditions.subTitle', '')}
            </Text>
          </Section>
          <Section spacingBottom='25'>
            <ShapeContainer
              fluid
              paddingVertical='30'
              paddingHorizontal='30'
              bgColor={VARIABLES.COLORS.GRAY_1}
              borderWidth='1'
              borderStyle='solid'
              borderColor={VARIABLES.COLORS.GRAY_7}
              borderRadius='10'
            >
              <Section scroll height='300'>
                <div className="text-editor-content"></div>
                <ModalWarrantyTncMalaysiaContent
                  isCAPERO_MY_P1CWR={dataDetail.templateForm === DATA.WARRANTY_PRODUCT_TEMPLATE_GROUP[ENUM.COUNTRY_TYPE.MALAYSIA][0]}
                  isCCB_MATT_MY_P15RM={dataDetail.templateForm === DATA.WARRANTY_PRODUCT_TEMPLATE_GROUP[ENUM.COUNTRY_TYPE.MALAYSIA][1]}
                  isCCB_SPECTRUM_MY_P1CPP={dataDetail.templateForm === DATA.WARRANTY_PRODUCT_TEMPLATE_GROUP[ENUM.COUNTRY_TYPE.MALAYSIA][2]}
                  isCCB_SPECTRUM_MY_P15PP={dataDetail.templateForm === DATA.WARRANTY_PRODUCT_TEMPLATE_GROUP[ENUM.COUNTRY_TYPE.MALAYSIA][3]}
                  isCCB_ULTRA_MATT_MY_P15UM={dataDetail.templateForm === DATA.WARRANTY_PRODUCT_TEMPLATE_GROUP[ENUM.COUNTRY_TYPE.MALAYSIA][4]}
                  isCCB_ULTRA_MY_P15UL={dataDetail.templateForm === DATA.WARRANTY_PRODUCT_TEMPLATE_GROUP[ENUM.COUNTRY_TYPE.MALAYSIA][5]}
                  isCCB_XPD_MY_P15XP={dataDetail.templateForm === DATA.WARRANTY_PRODUCT_TEMPLATE_GROUP[ENUM.COUNTRY_TYPE.MALAYSIA][6]}
                  isCCB_XPD_PSC_MY_P15XS={dataDetail.templateForm === DATA.WARRANTY_PRODUCT_TEMPLATE_GROUP[ENUM.COUNTRY_TYPE.MALAYSIA][7]}
                  isCCB_XRW_MY_P15XR={dataDetail.templateForm === DATA.WARRANTY_PRODUCT_TEMPLATE_GROUP[ENUM.COUNTRY_TYPE.MALAYSIA][8]}
                  isPM_R_MY_P2PRJ={dataDetail.templateForm === DATA.WARRANTY_PRODUCT_TEMPLATE_GROUP[ENUM.COUNTRY_TYPE.MALAYSIA][9]}
                  isTRUECORE_MY_Z1TRC={dataDetail.templateForm === DATA.WARRANTY_PRODUCT_TEMPLATE_GROUP[ENUM.COUNTRY_TYPE.MALAYSIA][10]}
                  isVERMOE_MY_P1VRM={dataDetail.templateForm === DATA.WARRANTY_PRODUCT_TEMPLATE_GROUP[ENUM.COUNTRY_TYPE.MALAYSIA][11]}
                  isZL_MY_Z1ZAL={dataDetail.templateForm === DATA.WARRANTY_PRODUCT_TEMPLATE_GROUP[ENUM.COUNTRY_TYPE.MALAYSIA][12]}
                  isZL_MY_Z1ZAU={dataDetail.templateForm === DATA.WARRANTY_PRODUCT_TEMPLATE_GROUP[ENUM.COUNTRY_TYPE.MALAYSIA][13]}
                  isPM_MY_P2PRM={dataDetail.templateForm === DATA.WARRANTY_PRODUCT_TEMPLATE_GROUP[ENUM.COUNTRY_TYPE.MALAYSIA][14]}
                />
              </Section>
            </ShapeContainer>
          </Section>
          <Section justify='center'>
            <Field name='CONDITION_NAME'
              checked={isSelected}
              ui='checkboxTick'
              checkboxLabel={getLanguage('modal.termsAndConditions.condition', '')}
              onChange={onChangeCheckbox}
            />
          </Section>
        </Modal.Section>
        <Modal.Section
          paddingVertical='30'
          paddingHorizontal='40'
          borderTopWidth={VARIABLES.MODAL.FOOTER.F_1.BORDER_TOP_WIDTH}
          borderTopStyle={VARIABLES.MODAL.FOOTER.F_1.BORDER_TOP_STYLE}
          borderTopColor={VARIABLES.COLORS.GRAY_8}
          borderBottomRightRadius={VARIABLES.MODAL.FOOTER.F_1.BORDER_BOTTOM_RIGHT_RADIUS}
          borderBottomLeftRadius={VARIABLES.MODAL.FOOTER.F_1.BORDER_BOTTOM_LEFT_RADIUS}
        >
          <Grid
            gutter='35'
            justify='flex-end'
          >
            <Grid.Column>
              <Button
                gray
                width={VARIABLES.BUTTON.WIDTHS.W_135}
                name={getLanguage('field.buttonClose', '')}
                onClick={onClickClose}
              />
            </Grid.Column>
          </Grid>
        </Modal.Section>
      </Modal>
    )
  }
}
