import React from 'react'
import ClassNames from 'classnames'
import PropTypes from 'prop-types'
import {
  Image
} from './../../custom'
import {
  LoadingWrapper
} from './styled'
// import {
//   default as VARIABLES
// } from './../../../themes/styles/bases/variables'

/**
 * Loading description:
 * - Loading
 */

export class Loading extends React.PureComponent {
  render () {
    const {
      className,
      active
    } = this.props

    // props for css classes
    const classes = ClassNames(
      'Loading',
      {'is-active': active},
      className
    )

    return (
      <LoadingWrapper
        className={classes}
      >
        <Image
          widthRatio='100'
          widthRatioUnit='px'
          heightRatio='100'
          heightRatioUnit='px'
          src={require('./../../../themes/images/shareds/loading.svg')}
        />
      </LoadingWrapper>
    )
  }

  static defaultProps = {
    // * If you set new default props, you should create propsType for correct styleguide document.
  }

  static propTypes = { // TYPE > node, string, number, bool, array, object, symbol, func
    /**
    * Additional classes
    */
    className: PropTypes.string
  }
}
