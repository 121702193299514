import React from 'react';
import {
  connect
} from 'react-redux';
import { DATA } from '../../helpers/constants/data';
import { getSortAscending } from '../../helpers/functions/data';
import { getLanguage } from '../../helpers/functions/language';
import { isShowLoading } from '../../helpers/functions/main';
import { getWarrantyYearDetail } from '../../helpers/services/warranty';
import { getClearWarrantyTemplateParams, getWarrantyTemplateDetail, getWarrantyTemplateList } from '../../helpers/services/warrantyTemplate';
import { ICONS, VARIABLES } from '../../themes';
import { ModalPreviewWarrantyTemplateContainer } from '../ModalContainer/ModalPreviewWarrantyTemplateContainer';
import {
  Button,
  Grid, Image, PaginationControl, Section, ShapeContainer, Table, TableAction,
  Text
} from './../../components';

class WarrantyTemplateListContainer extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      activePage: 1,
      dataPerPage: 10,

      // isShowM3Modal: false,
      // dataListModal: [],
      // m3TypeModal: '',

      isShowPreview: false,
      previewData: {},
      envDetail: undefined,
    }
  }

  componentDidMount() {
    const {
      activePage,
      dataPerPage,
    } = this.state;
    const params = {
      ...getClearWarrantyTemplateParams(),
      page: activePage,
      limit: dataPerPage,
    };
    getWarrantyTemplateList(params);
  }

  warrantyTemplateList = {
    onClickSort: (target) => {
      getWarrantyTemplateList({
        sort: target
      });
    },
    onClickView: async (data) => {
      console.log('m3ProdGrpCode', data.m3ProdGrpCode);

      isShowLoading(true);
      let envDetail;
      const configService = {
        isShowLoading: false,
      }
      if (data.m3ProdGrpCode) {
        const params = {
          m3ProductGroupCode: data.m3ProdGrpCode
        };
        let yearDetailList = await getWarrantyYearDetail(params, configService);
        if (yearDetailList.length) {
          yearDetailList = yearDetailList.map(e => {
            let envBenign
            e.envList = e.envList.filter(ev => {
              if ((ev.environment || '').toLowerCase() === 'Benign'.toLowerCase()) {
                envBenign = ev;
                return false
              }
              return true
            })

            if (envBenign) {
              e.envList.unshift(envBenign)
            }
            return e;
          })

          const isRoofAndWall = yearDetailList.filter(e => (
            e.application === DATA.WARRANTY_APP_DETAIL_NAME_LIST[0] ||
            e.application === DATA.WARRANTY_APP_DETAIL_NAME_LIST[1]
          )).length === 2;
          const envRoof = yearDetailList.filter(e => e.application === DATA.WARRANTY_APP_DETAIL_NAME_LIST[0])[0];
          const envWall = yearDetailList.filter(e => e.application === DATA.WARRANTY_APP_DETAIL_NAME_LIST[1])[0];
          if (isRoofAndWall) {
            const envDetailRoof = envRoof.envList[0];
            const envDetailWall = envWall.envList[0];
            envDetail = {
              corrosion: envDetailRoof.corrosion,
              peelFlake: envDetailRoof.peelFlake,
              colorFade: envDetailRoof.colorFade,
              dirtStain: envDetailRoof.dirtStain,
              corrosionWall: envDetailWall.corrosion,
            }
          }
          else {
            if (envWall) {
              envDetail = yearDetailList[0].envList[0];
              envDetail.corrosionWall = envDetail.corrosion;
              envDetail.corrosion = '-'
            }
            else {
              envDetail = yearDetailList[0].envList[0];
              envDetail.corrosionWall = '-';
            }
          }
        }
      }

      const previewData = await getWarrantyTemplateDetail({
        id: data.id,
      }, configService);

      this.setState({
        isShowPreview: true,
        previewData,
        envDetail,
      }, () => {
        isShowLoading(false);
      })
    },
    // onClickOpenM3Modal: (data, target) => {
    //   this.setState({
    //     isShowM3Modal: true,
    //     groupNameModal: data.wrtTempName,
    //     m3TypeModal: target,
    //     dataListModal: _.cloneDeep(data[target]),
    //   })
    // },
    // onClickCloseM3Modal: () => {
    //   this.setState({
    //     isShowM3Modal: false,
    //   })
    // },
    onClickClosePreviewModal: () => {
      this.setState({
        isShowPreview: false,
      })
    },
  }

  pagination = {
    onPaginationChange: (pageNumber) => {
      getWarrantyTemplateList({
        page: pageNumber,
      });
    }
  }

  render() {
    const {
      dataList,
      totalData,
      activePage,
      dataOptionParams,
      preTransData,
      onClickBackProjectDocumentManage,
    } = this.props;
    const {
      dataPerPage,
      isShowPreview,
      previewData,
      envDetail,
    } = this.state;
    return (
      <React.Fragment>
        <ShapeContainer
          ui='content'
          justify='space-between'
        >
          <Section spacingBottom='45'>
            <Table
              striped
              action
            >
              <Table.Section>
                <Table.Row>
                  <Table.HeadColumn
                    minWidth={VARIABLES.TABLE.WARRANTY_TEMPLATE.COLUMN_WIDTHS.TEMPLATE_NAME}
                    sortAscending={getSortAscending(dataOptionParams, 'wrt_temp_name')}
                    onClickSort={() => {
                      this.warrantyTemplateList.onClickSort('wrt_temp_name');
                    }}
                  >
                    {getLanguage('container.warrantyTemplate.table.header.templateName')}
                  </Table.HeadColumn>
                  {/* <Table.HeadColumn
                    noSort
                    minWidth={VARIABLES.TABLE.WARRANTY_TEMPLATE.COLUMN_WIDTHS.M3_PRODUCT_CODE}
                  >
                    {getLanguage('container.warrantyTemplate.table.header.m3ProductCode')}
                  </Table.HeadColumn>
                  <Table.HeadColumn
                    noSort
                    minWidth={VARIABLES.TABLE.WARRANTY_TEMPLATE.COLUMN_WIDTHS.M3_PRODUCT_GROUP}
                  >
                    {getLanguage('container.warrantyTemplate.table.header.m3ProductGroup')}
                  </Table.HeadColumn>
                  <Table.HeadColumn
                    noSort
                    minWidth={VARIABLES.TABLE.WARRANTY_TEMPLATE.COLUMN_WIDTHS.M3_PRODUCT_COLOR}
                  >
                    {getLanguage('container.warrantyTemplate.table.header.m3ProductColor')}
                  </Table.HeadColumn> */}
                  <Table.HeadColumn
                    noSort
                    alignCenter
                    maxWidth={VARIABLES.TABLE.WARRANTY_TEMPLATE.COLUMN_WIDTHS.ACTION}
                  >
                    {getLanguage('container.warrantyTemplate.table.header.action')}
                  </Table.HeadColumn>
                </Table.Row>
              </Table.Section>
              {dataList.length ?
                <Table.Section>
                  {dataList.map((e, i) => {
                    return (
                      <Table.Row key={i}>
                        <Table.BodyColumn
                          info
                          minWidth={VARIABLES.TABLE.WARRANTY_TEMPLATE.COLUMN_WIDTHS.TEMPLATE_NAME}
                          title={e.wrtTempName}
                        >
                          {e.wrtTempName}
                        </Table.BodyColumn>
                        {/* <Table.BodyColumn
                          action
                          minWidth={VARIABLES.TABLE.WARRANTY_TEMPLATE.COLUMN_WIDTHS.M3_PRODUCT_CODE}
                        >
                          {e.wrtTempM3ProdGrpCodeList.length <= 1 ?
                            <Text regular12 color={VARIABLES.COLORS.GRAY_19}>{e.wrtTempM3ProdGrpCodeList[0]?.m3ProdGrpCode ?? '-'}</Text>
                            :
                            <Text
                              regular12
                              underline
                              color={VARIABLES.COLORS.PRIMARY_1}
                              onClick={() => {
                                this.warrantyTemplateList.onClickOpenM3Modal(e, 'wrtTempM3ProdGrpCodeList')
                              }}
                            >
                              {e.wrtTempM3ProdGrpCodeList.length} {getLanguage('container.warrantyTemplate.table.body.codes')}
                            </Text>
                          }
                        </Table.BodyColumn>
                        <Table.BodyColumn
                          action
                          minWidth={VARIABLES.TABLE.WARRANTY_TEMPLATE.COLUMN_WIDTHS.M3_PRODUCT_GROUP}
                        >
                          {e.wrtTempM3ProdGrpNameList.length <= 1 ?
                            <Text regular12 color={VARIABLES.COLORS.GRAY_19}>{e.wrtTempM3ProdGrpNameList[0]?.m3ProdGrpName ?? '-'}</Text>
                            :
                            <Text
                              regular12
                              underline
                              color={VARIABLES.COLORS.PRIMARY_1}
                              onClick={() => {
                                this.warrantyTemplateList.onClickOpenM3Modal(e, 'wrtTempM3ProdGrpNameList')
                              }}
                            >
                              {e.wrtTempM3ProdGrpNameList.length} {getLanguage('container.warrantyTemplate.table.body.groups')}
                            </Text>
                          }
                        </Table.BodyColumn>
                        <Table.BodyColumn
                          action
                          minWidth={VARIABLES.TABLE.WARRANTY_TEMPLATE.COLUMN_WIDTHS.M3_PRODUCT_COLOR}
                        >
                          {e.wrtTempColorList.length <= 1 ?
                            <Text regular12 color={VARIABLES.COLORS.GRAY_19}>{e.wrtTempColorList[0]?.color ?? '-'}</Text>
                            :
                            <Text
                              regular12
                              underline
                              color={VARIABLES.COLORS.PRIMARY_1}
                              onClick={() => {
                                this.warrantyTemplateList.onClickOpenM3Modal(e, 'wrtTempColorList')
                              }}
                            >
                              {e.wrtTempColorList.length} {getLanguage('container.warrantyTemplate.table.body.colors')}
                            </Text>
                          }
                        </Table.BodyColumn> */}
                        <Table.BodyColumn
                          action
                          alignCenter
                          minWidth={VARIABLES.TABLE.WARRANTY_TEMPLATE.COLUMN_WIDTHS.ACTION}
                          maxWidth={VARIABLES.TABLE.WARRANTY_TEMPLATE.COLUMN_WIDTHS.ACTION}
                        >
                          <Section>
                            <Image
                              ui='iconDocument'
                              top='-1'
                              onClick={() => { this.warrantyTemplateList.onClickView(e) }}
                            />
                          </Section>
                        </Table.BodyColumn>
                      </Table.Row>
                    )
                  })}
                </Table.Section>
                :
                <Section justify='center' paddingVertical='60'>
                  <Section justify='center' spacingBottom='10'>
                    <img src={ICONS['ic-menu-warranty-gray.svg']} alt='Icon' />
                  </Section>
                  <Text regular14 color={VARIABLES.COLORS.GRAY_12}>
                    {getLanguage('container.warrantyTemplate.noWarrantyTemplate')}
                  </Text>
                </Section>
              }
            </Table>
          </Section>
          <TableAction>
            <TableAction.Left>
              {preTransData &&
                <Button
                  gray
                  ui='back'
                  name={getLanguage('field.buttonBack', '')}
                  onClick={onClickBackProjectDocumentManage}
                />
              }
            </TableAction.Left>
            <TableAction.Right>
              <Grid gutter='20'>
                <Grid.Column>
                  <Section paddingTop='6'>
                    <Text
                      regular12
                      color={VARIABLES.COLORS.GRAY_16}
                    >
                      {getLanguage('pagination.page')} {activePage}/{Math.ceil(totalData / dataPerPage)}
                    </Text>
                  </Section>
                </Grid.Column>
                <Grid.Column>
                  <PaginationControl
                    activePage={activePage}
                    itemsCountPerPage={dataPerPage}
                    totalItemsCount={totalData}
                    pageRangeDisplayed={3}
                    onChange={this.pagination.onPaginationChange}
                  />
                </Grid.Column>
              </Grid>
            </TableAction.Right>
          </TableAction>
        </ShapeContainer>

        {/* Modal : M3 Product Template Information */}
        {/* {isShowM3Modal &&
          <ModalM3ProductTemplateInformationContainer
            isOpenModal
            isM3ProductColor={m3TypeModal === 'wrtTempColorList'}
            isM3ProductCode={m3TypeModal === 'wrtTempM3ProdGrpCodeList'}
            isM3ProductGroup={m3TypeModal === 'wrtTempM3ProdGrpNameList'}
            dataList={dataListModal}
            templateName={groupNameModal}
            onClickClose={this.warrantyTemplateList.onClickCloseM3Modal}
          />
        } */}

        {isShowPreview &&
          <ModalPreviewWarrantyTemplateContainer
            isOpenModal
            isSecondModal
            preTransData={preTransData}
            warrantyDetail={previewData}
            envDetail={envDetail}
            title={previewData.wrtTempName}
            onClickClose={this.warrantyTemplateList.onClickClosePreviewModal}
            onClickCancel={this.warrantyTemplateList.onClickClosePreviewModal}
          />
        }
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => {
  const dataRedux = state.dataRedux;
  return {
    dataList: dataRedux.warrantyTemplateList,
    dataOptionParams: dataRedux.warrantyTemplateParams,
    activePage: dataRedux.warrantyTemplateParams.activePage,
    totalData: dataRedux.warrantyTemplateParams.totalData || 0,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
  }
}

export const WarrantyTemplateListContainerConnected = connect(
  mapStateToProps,
  mapDispatchToProps
)(WarrantyTemplateListContainer)