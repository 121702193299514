import {
  injectGlobal
} from 'styled-components'
import {
  default as VARIABLES
} from './../../../themes/styles/bases/variables'
// import {
//   default as TYPOGRAPHYS
// } from './../../../themes/styles/bases/typographys'
// import {
//   default as MIXINS
// } from './../../../themes/styles/helpers/mixins'

// Sample using image in style
// url(${require('./../../../themes/images/icons/icon-sample.svg')});

injectGlobal`
  .field-base {
    /* Parent
    ------------------------------- */
    /* Childrens
    ------------------------------- */
    .field-radio-base {
      .field-radio-icon-label-base {
        position: relative;
        top: 1px;
        line-height: 1;
      }
    }

    .field-checkbox-base {
      .field-checkbox-icon-label-base {
        position: relative;
        top: 1px;
        line-height: 1;
      }
    }

    /* Modifiers
    ------------------------------- */
    &.is-label-vertical,
    &.is-label-vertical-block {
      .field-container-base {
        display: block;
      }
    }

    &.is-label-vertical-block {
      .field-label-base {
        display: block;
        margin-bottom: 5px;
      }
    }

    &.is-label-nowrap {
      .field-label-base {
        white-space: nowrap;
      }
    }

    &.is-focus {
      .field-label-base {
        color: ${VARIABLES.COLORS.PRIMARY_2};
      }

      .field-value-base,
      .field-file-name-base,
      .field-info-text-base {
        border-bottom-color: ${VARIABLES.COLORS.PRIMARY_2};
      }
    }

    &.is-required {
      .field-group-base {
        position: relative;

        &:after {
          content: ' ';
          position: absolute;
          z-index: ${VARIABLES.Z_INDEXS.LV_1};
          top: 0;
          right: -10px;
          display: block;
          width: 6px;
          height: 6px;
          background-color: ${VARIABLES.COLORS.ERROR};
          border-radius: 50%;
        }
      }
    }

    &.is-label-required {
      .field-label-base {
        position: relative;

        &:after {
          content: ' ';
          position: absolute;
          z-index: ${VARIABLES.Z_INDEXS.LV_1};
          top: 0;
          right: -10px;
          display: block;
          width: 4px;
          height: 4px;
          background-color: ${VARIABLES.COLORS.ERROR};
          border-radius: 50%;
        }
      }
    }

    &.is-change-password {
      max-width: 475px;
    }

    &.is-enable-field-icon-action {
      .field-icon-base {
        pointer-events: auto;
      }
    }

    &.is-icon-right {
      .field-icon-base {
        position: absolute;
        z-index: ${VARIABLES.Z_INDEXS.LV_1};
        top: 1px;
        right: 1px;
        bottom: 1px;
        display: flex;
        align-items: center;
      }
    }

    /* Media queries
    ------------------------------- */
  }

  .field-unit {
    position: relative;

    .field-unit-button-minus,
    .field-unit-button-plus,
    .field-unit-name {
      transform: translateY(-50%);
      position: absolute;
      z-index: ${VARIABLES.Z_INDEXS.LV_1};
      top: 50%;
    }

    .field-unit-button-minus {
      left: 3px;
    }

    .field-unit-button-plus {
      right: 3px;
    }

    .field-unit-name {
      right: 15px;
    }
  }
`
