import { displayDate, displayWithComma } from "../helpers/functions/display";
import moment from 'moment';
import { handleSortData } from "../helpers/functions/data";
import _ from 'lodash';

export class ModelReport {
  constructor(props = {}) {
    this.totalBuyin = {
      volumn: displayWithComma(Math.floor(props.total_buyin.total_remaining_weight / 1000)),
      length: displayWithComma(props.total_buyin.total_remaining_length),
    };
    this.totalSalesOut = {
      volumn: displayWithComma(Math.floor(props.total_sales_out.total_remaining_weight / 1000)),
      length: displayWithComma(props.total_sales_out.total_remaining_length),
      quantity: displayWithComma(props.total_sales_out.total_profile_quantity),
      revenue: displayWithComma(props.total_sales_out.total_revenue),
    };
    this.averageSalesOut = {
      month: displayWithComma(props.average_sales_out.month_sales),
      daily: displayWithComma(props.average_sales_out.daily_sales),
    };
    this.noOfCustomer = {
      total: displayWithComma(props.no_of_customer.total_customer),
      active: displayWithComma(props.no_of_customer.active_customer),
    };
    this.rewardPoint = {
      volumn: displayWithComma(props.point.total_point),
    };
    this.warranty = {
      ready: displayWithComma(props.warranty.warranty_ready),
      notReady: displayWithComma(props.warranty.warranty_not_ready),
      total: displayWithComma(props.warranty.warranty_ready + props.warranty.warranty_not_ready),
    };

    //indo
    this.totalProfileMappingIn = {
      length: displayWithComma(props.total_profile_mapping_in.total_profile_length),
      quantity: displayWithComma(props.total_profile_mapping_in.total_profile_quantity),
      volumn: displayWithComma(props.total_profile_mapping_in.total_profile_weight),
    };
    this.totalProfileMappingOut = {
      length: displayWithComma(props.total_profile_mapping_out.total_profile_length),
      quantity: displayWithComma(props.total_profile_mapping_out.total_profile_quantity),
      volumn: displayWithComma(props.total_profile_mapping_out.total_profile_weight),
    };
    this.totalProfileTransferIn = {
      length: displayWithComma(props.total_profile_transfer_in.total_profile_length),
      quantity: displayWithComma(props.total_profile_transfer_in.total_profile_quantity),
      volumn: displayWithComma(props.total_profile_transfer_in.total_profile_weight),
    };
    this.totalProfileTransferOut = {
      length: displayWithComma(props.total_profile_transfer_out.total_profile_length),
      quantity: displayWithComma(props.total_profile_transfer_out.total_profile_quantity),
      volumn: displayWithComma(props.total_profile_transfer_out.total_profile_weight),
    };
    //indo dc
    let x = 0;
    this.profileTransferInList = _.get(props, 'total_profile_transfer_list.profile_transfer_in', []).map(e => {
      x += e.allocate_lenght_m__c;
      return {
        allocateLenghtM: e.allocate_lenght_m__c,
        createdDate: e.createddate,
        month: moment(e.createddate).format('MMM'),
        year: moment(e.createddate).format('YY'),
        type: 'transferIn',
        fBsproductgroupname: e.f_bsproductgroupname__c,
        itemTypeName: e.item_type_name__c,
        profileQuantity: e.profile_quantity__c,
        profileWeight: e.profile_weight
      }
    });
    let y = 0;
    this.profileTransferOutList = _.get(props, 'total_profile_transfer_list.profile_transfer_out', []).map(e => {
      y += e.allocate_lenght_m__c;
      return {
        allocateLenghtM: e.allocate_lenght_m__c,
        createdDate: e.createddate,
        month: moment(e.createddate).format('MMM'),
        year: moment(e.createddate).format('YY'),
        type: 'transferOut',
        fBsproductgroupname: e.f_bsproductgroupname__c,
        itemTypeName: e.item_type_name__c,
        profileQuantity: e.profile_quantity__c,
        profileWeight: e.profile_weight
      }
    });

    this.totalProfileTransferList = handleSortData([...this.profileTransferInList, ...this.profileTransferOutList], {
      sortTarget: 'createdDate',
      sortTargetType: 'date',
    })

    this.oldTotalProfileTransferList = props.total_profile_transfer_list;

    this.buyinList = props.inout_stock.buyin.map(e => {
      return {
        fBsproductgroupname: e.f_bsproductgroupname__c,
        fRemaininglength: e.migrated_remaining_length__c,
        itemTypeName: e.item_type_name__c,
        orderDate: e.order_date__c,
        month: moment(e.order_date__c).format('MMM'),
        year: moment(e.order_date__c).format('YY'),
        type: 'buyIn',
        productValue: e.migrated_remaining_length__c,
      }
    });
    this.selloutStockList = props.inout_stock.sellout_stock.map(e => {
      return {
        coilLength: e.coil_length__c,
        fRemaininglength: e.migrated_remaining_length__c,
        fBsproductgroupname: e.f_bsproductgroupname__c,
        itemTypeName: e.item_type_name__c,
        createAt: e.create_at,
        orderDate: e.create_at,
        month: moment(e.create_at).format('MMM'),
        year: moment(e.create_at).format('YY'),
        type: 'sellOut',
        productValue: e.coil_length__c,
      }
    });

    this.buyAndSelloutList = handleSortData([...this.buyinList, ...this.selloutStockList], {
      sortTarget: 'orderDate',
      sortTargetType: 'date',
    })
    this.top10CustomerList = props.top10_customer.map(e => {
      return {
        customerId: e.customer_id,
        customerName: e.customer_name,
        totalCoilLength: e.total_coil_length,
        totalPoint: e.total_point,
        totalPointsCoilLength: e.total_points_coil_length,
        name: e.customer_name,
        volumn: e.total_coil_weight,
        length: e.total_coil_length
      }
    });
    // this.top10SalesList = props.top10_sales.map(e => {
    //   return {
    //     salesPersonId: e.sales_person_id,
    //     salesPersonName: e.sales_person_name,
    //     totalCoilLength: e.total_coil_length,
    //     totalPoint: e.total_point,
    //     totalPointsCoilLength: e.total_points_coil_length,
    //     name: e.sales_person_name,
    //     points: e.total_point,
    //     length: e.total_coil_length
    //   }
    // });
    this.top10SalesList = props.top10_sales.map(e => {
      return {
        name: e.sales_person_name,
        volumn: e.total_coil_weight,
        length: e.total_coil_length
      }
    });
    this.top10SalesByVolumeList = props.top10_sales.map(e => {
      return {
        name: e.sales_person_name,
        value: e.total_coil_weight
      }
    });
    this.top10SalesByLengthList = props.top10_sales.map(e => {
      return {
        name: e.sales_person_name,
        value: e.total_coil_length
      }
    });
    this.mostRedemptionItemList = props.most_redemption_item.map(e => {
      return {
        rewardId: e.reward_id,
        rewardName: e.reward_name,
        count: e.count,
      }
    });
    this.mostRedemptionCustomerList = props.most_redemption_customer.map(e => {
      return {
        customerId: e.customer_id,
        customerName: e.customer_name,
        count: e.count,
      }
    });
    this.mostRedemptionList = [];
    if (this.mostRedemptionItemList.length >= this.mostRedemptionCustomerList) {
      this.mostRedemptionItemList.forEach((e, i) => {
        this.mostRedemptionList.push({
          'Most ex rewards': e.rewardName,
          'Most ex customer': this.mostRedemptionCustomerList[i] ? this.mostRedemptionCustomerList[i].customerName : '',
        })
      })
    }
    else {
      this.mostRedemptionCustomerList.forEach((e, i) => {
        this.mostRedemptionList.push({
          'Most ex rewards': this.mostRedemptionItemList[i] ? this.mostRedemptionItemList[i].rewardName : '',
          'Most ex customer': e.customerName,
        })
      })
    }
    this.oldInoutStock = props.inout_stock;
    this.oldTop10Customer = props.top10_customer;
    this.oldTop10Sales = props.top10_sales;
    this.oldMostRedemptionItem = props.most_redemption_item;
    this.oldMostRedemptionCustomer = props.most_redemption_customer;
  }
}