import React from 'react';
import {
  ShapeContainer,
  Section,
  Grid,
  Field,
  Button,
  TitleSection,
  DatePickerControl,
  SelectControl,
  Text,
} from './../../components'
import {
  VARIABLES,
} from './../../themes'
import { FieldAddressContainerConnected } from './../../containers/FieldAddressContainer';
import { ENUM } from '../../helpers/constants/enum';
import { storeGetState } from '../../stores';
import { MAIN_REDUX_STATE_KEY } from '../../actions/mainAction';
import _ from 'lodash';
import { getLanguage } from '../../helpers/functions/language';
import { DATE_PICKER_FORMAT } from '../../helpers/constants/data';

export class WarrantyManageInfoMalay extends React.Component {

  constructor(props) {
    super(props);
    const provinceList = storeGetState().dataRedux.countryList;
    this.state = {
      provinceList,
    }
  }

  render() {
    const {
      data,
      onClickSave,
      onClickBack,
      onChangeDataInput,
      onChangePhoneCountry,
      onChangeAddress,
      onChangeDataDate,
      onChangeApplication,
    } = this.props;
    const {
      provinceList,
    } = this.state;
    let cityList = [];
    if (data.selectedProvince) {
      let province = provinceList.filter(e => e.provinceCode === data.selectedProvince.value)[0];
      if (province) {
        cityList = province.cityList;
      }
    }
    return (
      <React.Fragment>
        <Section spacingBottom='30'>
          <Grid>
            <Grid.ResponsiveColumn ui='fieldText'>
              <Field name='adRubberStamp'
                fluid
                required
                // error
                ui='blockLabelVertical'
                label={getLanguage('field.authorizedDealerRubberStamp', '')}
                placeholder={getLanguage('field.authorizedDealerRubberStampPlaceholder', '')}
                value={data.adRubberStamp}
                onChange={onChangeDataInput}
              />
            </Grid.ResponsiveColumn>
          </Grid>
        </Section>
        <Section spacingBottom='30'>
          <Grid gutter='35'>
            <Grid.ResponsiveColumn ui='fieldSelect'>
              <Field name='originalReceiptInvoice'
                fluid
                required
                // error
                ui='blockLabelVertical'
                label={getLanguage('field.originalReceiptInvoice', '')}
                placeholder={getLanguage('field.originalReceiptInvoicePlaceholder', '')}
                value={data.originalReceiptInvoice}
                onChange={onChangeDataInput}
              />
            </Grid.ResponsiveColumn>
            <Grid.ResponsiveColumn ui='fieldSelect'>
              <Field name='residentialCommercial'
                fluid
                required
                // error
                ui='blockLabelVertical'
                label={getLanguage('field.residentialCommercial', '')}
                placeholder={getLanguage('field.residentialCommercialPlaceholder', '')}
                value={data.residentialCommercial}
                onChange={onChangeDataInput}
              />
            </Grid.ResponsiveColumn>
          </Grid>
        </Section>
        <Section spacingBottom='30'>
          <Grid>
            <Grid.ResponsiveColumn ui='fieldText'>
              <Field name='projectName'
                fluid
                required
                // error
                ui='blockLabelVertical'
                label={getLanguage('field.name', '')}
                placeholder={getLanguage('field.namePlaceholder', '')}
                message={
                  <Section
                    ui='hintBottomField'
                    hint={getLanguage('field.customerProjectNameHint', '')}
                  />
                }
                value={data.projectName}
                onChange={onChangeDataInput}
              />
            </Grid.ResponsiveColumn>
          </Grid>
        </Section>
        <Section spacingBottom='30'>
          <Grid>
            <Grid.Column
              gridTablet='12'
              gridLaptop='7'
            >
              <Field name='projectAddr'
                fluid
                required
                // error
                ui='blockLabelVerticalTextarea'
                label={getLanguage('field.address', '') + ' ' + '(' + getLanguage('field.street', '') + ')'}
                placeholder={getLanguage('field.addressPlaceholder', '')}
                value={data.projectAddr}
                onChange={onChangeDataInput}
              />
            </Grid.Column>
          </Grid>
        </Section>
        <Section spacingBottom='30'>
          <Grid gutter='35'>
            <Grid.ResponsiveColumn ui='fieldSelect'>
              <SelectControl
                verticalLabel
                required
                isSearchable
                label={getLanguage('field.province', '')}
                placeholder={getLanguage('field.provincePlaceholder', '')}
                name={'selectedProvince'}
                value={data.selectedProvince}
                options={provinceList}
                onChange={(selected) => {
                  onChangeAddress(selected, 'selectedProvince');
                }}
              />
            </Grid.ResponsiveColumn>
            <Grid.ResponsiveColumn ui='fieldSelect'>
              <SelectControl
                verticalLabel
                required
                isSearchable
                label={getLanguage('field.city', '')}
                placeholder={getLanguage('field.cityPlaceholder', '')}
                name={'selectedCity'}
                value={data.selectedCity}
                options={cityList}
                onChange={(selected) => {
                  onChangeAddress(selected, 'selectedCity');
                }}
              />
            </Grid.ResponsiveColumn>
            <Grid.ResponsiveColumn ui='fieldSelect'>
              <Field name='projectPostCode'
                fluid
                required
                // error
                ui='blockLabelVertical'
                label={getLanguage('field.postcode', '')}
                placeholder={getLanguage('field.postcodePlaceholder', '')}
                value={data.projectPostCode}
                onChange={onChangeDataInput}
              />
            </Grid.ResponsiveColumn>
          </Grid>
        </Section>
        <Section spacingBottom='30'>
          <Grid gutter='35'>
            <Grid.ResponsiveColumn ui='fieldSelect'>
              <Field name='projectPhone'
                fluid
                required
                // error
                ui='blockLabelVertical'
                label={getLanguage('field.phoneNo', '')}
                placeholder={getLanguage('field.phoneNoPlaceholder', '')}
                value={data.projectPhone}
                onChange={onChangeDataInput}
              />
            </Grid.ResponsiveColumn>
            <Grid.ResponsiveColumn ui='fieldSelect'>
              <Field name='projectEmail'
                fluid
                required
                // error
                ui='blockLabelVertical'
                label={getLanguage('field.email', '')}
                placeholder={getLanguage('field.emailPlaceholder', '')}
                value={data.projectEmail}
                onChange={onChangeDataInput}
              />
            </Grid.ResponsiveColumn>
          </Grid>
        </Section>
        <Section spacingBottom='30'>
          <Grid gutter='35'>
            <Grid.ResponsiveColumn ui='fieldSelect'>
              {/* <SelectControl
                verticalLabel
                required
                isSearchable
                label={getLanguage('field.typesOfProductPurchased', '')}
                placeholder={getLanguage('field.typesOfProductPurchasedPlaceholder', '')}
                // name={}
                // value={}
                // options={}
                // disabled={}
                onChange={onChangeDataInput}
              /> */}
              <Field name='typesOfProductPurchased'
                fluid
                required
                // error
                ui='blockLabelVertical'
                label={getLanguage('field.typesOfProductPurchased', '')}
                placeholder={getLanguage('field.typesOfProductPurchasedPlaceholder', '')}
                value={data.typesOfProductPurchased}
                onChange={onChangeDataInput}
              />
            </Grid.ResponsiveColumn>
          </Grid>
        </Section>
        <Section spacingBottom='20'>
          <Grid>
            <Grid.Column
              gridTablet='12'
              gridLaptop='7'
            >
              <Field name='purposeOfPurchasedZacs'
                fluid
                required
                // error
                ui='blockLabelVerticalTextarea'
                label={getLanguage('field.purposeOfPurchasedZacs', '')}
                placeholder={getLanguage('field.purposeOfPurchasedZacsPlaceholder', '')}
                value={data.purposeOfPurchasedZacs}
                onChange={onChangeDataInput}
              />
            </Grid.Column>
          </Grid>
        </Section>
        <Section spacingBottom='15'>
          <Grid gutter='5'>
            <Grid.Column>
              <Text
                regular14
                color={VARIABLES.COLORS.GRAY_19}
              >
                {getLanguage('field.application.title', '')}
              </Text>
            </Grid.Column>
            <Grid.Column>
              <Section spacingTop='-5'>
                <ShapeContainer ui='required' />
              </Section>
            </Grid.Column>
          </Grid>
        </Section>
        <Section spacingBottom='30'>
          <Grid gutter='30'>
            <Grid.Column>
              <Field
                checked={data.applicationList.indexOf('roofing') >= 0}
                checkboxLabel={getLanguage('field.application.roofing', '')}
                ui='checkboxTick'
                onChange={() => {
                  onChangeApplication('roofing');
                }}
              />
            </Grid.Column>
            <Grid.Column>
              <Field
                checked={data.applicationList.indexOf('awning') >= 0}
                checkboxLabel={getLanguage('field.application.awning', '')}
                ui='checkboxTick'
                onChange={() => {
                  onChangeApplication('awning');
                }}
              />
            </Grid.Column>
            <Grid.Column>
              <Field
                checked={data.applicationList.indexOf('walling') >= 0}
                checkboxLabel={getLanguage('field.application.walling', '')}
                ui='checkboxTick'
                onChange={() => {
                  onChangeApplication('walling');
                }}
              />
            </Grid.Column>
          </Grid>
        </Section>
        <Section spacingBottom='60'>
          <Grid gutter='35'>
            <Grid.ResponsiveColumn ui='fieldSelect'>
              <DatePickerControl
                fluid
                required
                label={getLanguage('field.purchaseDate', '')}
                dateFormat={DATE_PICKER_FORMAT}
                selected={data.purchasedDateTime}
                minDate={data.createdDateTime}
                onChange={(selected) => {
                  onChangeDataDate(selected, 'purchasedDateTime')
                }}
              />
            </Grid.ResponsiveColumn>
            <Grid.ResponsiveColumn ui='fieldText'>
              <Field name='inoivceNo'
                fluid
                required
                // error
                ui='blockLabelVertical'
                label={getLanguage('field.inoivceNo', '')}
                placeholder={getLanguage('field.inoivceNoPlaceholder', '')}
                value={data.inoivceNo}
                onChange={onChangeDataInput}
              />
            </Grid.ResponsiveColumn>
          </Grid>
        </Section>
      </React.Fragment >
    )
  }
}
