import apiService from '../apiService';

export const warrantyTemplateController = (configService = {}) => {
  const service = apiService(configService)
  return {
    warrantyTemplateSearch: (params) => {
      return service.post(`/warranty-template/search`, params)
    },
    warrantyTemplateDetail: (params) => {
      return service.post(`/warranty-template/detail`, params)
    },
  }
}
