import React from 'react';
import {
  ShapeContainer,
  Section,
  Grid,
  Field,
  Button,
  TitleSection,
  DatePickerControl,
  SelectControl,
  Text,
} from './../../components'
import {
  VARIABLES,
} from './../../themes'
import { FieldAddressContainerConnected } from './../../containers/FieldAddressContainer';
import { ENUM } from '../../helpers/constants/enum';
import { storeGetState } from '../../stores';
import { MAIN_REDUX_STATE_KEY } from '../../actions/mainAction';
import _ from 'lodash';
import { getLanguage } from '../../helpers/functions/language';
import { DATE_PICKER_FORMAT } from '../../helpers/constants/data';

export class WarrantyManageInfoIndo extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
    }
  }

  render() {
    const {
      data,
      onClickSave,
      onClickBack,
      onChangeDataInput,
      onChangePhoneCountry,
      onChangeAddress,
      onChangeDataDate,
      onChangeApplication,
    } = this.props;
    return (
      <React.Fragment>
        <Section spacingBottom='30'>
          <Grid>
            <Grid.ResponsiveColumn ui='fieldText'>
              <Field name='projectName'
                fluid
                required
                // error
                ui='blockLabelVertical'
                label={getLanguage('field.homeOwnerName', '')}
                placeholder={getLanguage('field.homeOwnerNamePlaceholder', '')}
                message={
                  <Section
                    ui='hintBottomField'
                    hint={getLanguage('field.customerProjectNameHint', '')}
                  />
                }
                value={data.projectName}
                onChange={onChangeDataInput}
              />
            </Grid.ResponsiveColumn>
          </Grid>
        </Section>
        <Section spacingBottom='30'>
          <Grid>
            <Grid.Column
              gridTablet='12'
              gridLaptop='7'
            >
              <Field name='projectAddr'
                fluid
                required
                // error
                ui='blockLabelVerticalTextarea'
                label={getLanguage('field.homeOwnerAddress', '')}
                placeholder={getLanguage('field.homeOwnerAddressPlaceholder', '')}
                value={data.projectAddr}
                onChange={onChangeDataInput}
              />
            </Grid.Column>
          </Grid>
        </Section>
        <Section spacingBottom='30'>
          <Grid>
            <Grid.ResponsiveColumn ui='fieldText'>
              <Field name='permitNumber'
                fluid
                // required
                // error
                ui='blockLabelVertical'
                label={getLanguage('field.permitLetterNumber', '')}
                placeholder={getLanguage('field.permitLetterNumberPlaceholder', '')}
                value={data.permitNumber}
                onChange={onChangeDataInput}
              />
            </Grid.ResponsiveColumn>
          </Grid>
        </Section>
        <Section spacingBottom='30'>
          <Grid gutter='35'>
            <Grid.ResponsiveColumn ui='fieldSelect'>
              {/* <SelectControl
                verticalLabel
                required
                isSearchable
                label={getLanguage('field.buildingType', '')}
                placeholder={getLanguage('field.buildingTypePlaceholder', '')}
                // name={}
                // value={}
                // options={}
                // disabled={}
                onChange={onChangeDataInput}
              /> */}
              <Field name='buildingType'
                fluid
                required
                // error
                ui='blockLabelVertical'
                label={getLanguage('field.buildingType', '')}
                placeholder={getLanguage('field.buildingTypePlaceholder', '')}
                value={data.buildingType}
                onChange={onChangeDataInput}
              />
            </Grid.ResponsiveColumn>
            <Grid.ResponsiveColumn ui='fieldSelect'>
              <Field name='areaBuilding'
                fluid
                required
                // error
                ui='blockLabelVertical'
                label={getLanguage('field.AreaM2OfBuilding', '')}
                placeholder={getLanguage('field.AreaM2OfBuildingPlaceholder', '')}
                value={data.areaBuilding}
                onChange={onChangeDataInput}
              />
            </Grid.ResponsiveColumn>
          </Grid>
        </Section>
        <Section spacingBottom='30'>
          <Grid gutter='35'>
            <Grid.ResponsiveColumn ui='fieldSelect'>
              <Field name='tokoName'
                fluid
                required
                // error
                ui='blockLabelVertical'
                label={getLanguage('field.tokoName', '')}
                placeholder={getLanguage('field.tokoNamePlaceholder', '')}
                value={data.tokoName}
                onChange={onChangeDataInput}
              />
            </Grid.ResponsiveColumn>
            <Grid.ResponsiveColumn ui='fieldSelect'>
              <Field name='builderName'
                fluid
                required
                // error
                ui='blockLabelVertical'
                label={getLanguage('field.builderName', '')}
                placeholder={getLanguage('field.builderNamePlaceholder', '')}
                value={data.builderName}
                onChange={onChangeDataInput}
              />
            </Grid.ResponsiveColumn>
          </Grid>
        </Section>
        <Section spacingBottom='30'>
          <Grid gutter='35'>
            <Grid.ResponsiveColumn ui='fieldSelect'>
              <Field name='productWeight'
                fluid
                // required
                // error
                ui='blockLabelVertical'
                label={getLanguage('field.productWeight', '')}
                placeholder={getLanguage('field.productWeightPlaceholder', '')}
                value={data.productWeight}
                onChange={onChangeDataInput}
              />
            </Grid.ResponsiveColumn>
            <Grid.ResponsiveColumn ui='fieldSelect'>
              <Field name='tctThickness'
                fluid
                required
                // error
                ui='blockLabelVertical'
                label={getLanguage('field.tctThickness', '')}
                placeholder={getLanguage('field.tctThicknessPlaceholder', '')}
                value={data.tctThickness}
                onChange={onChangeDataInput}
              />
            </Grid.ResponsiveColumn>
            <Grid.ResponsiveColumn ui='fieldSelect'>
              <Field name='colorName'
                fluid
                required
                // error
                ui='blockLabelVertical'
                label={getLanguage('field.colorName', '')}
                placeholder={getLanguage('field.colorNamePlaceholder', '')}
                value={data.colorName}
                onChange={onChangeDataInput}
              />
            </Grid.ResponsiveColumn>
          </Grid>
        </Section>
        <Section spacingBottom='15'>
          <Grid gutter='5'>
            <Grid.Column>
              <Text
                regular14
                color={VARIABLES.COLORS.GRAY_19}
              >
                {getLanguage('field.application.title', '')}
              </Text>
            </Grid.Column>
            <Grid.Column>
              <Section spacingTop='-5'>
                <ShapeContainer ui='required' />
              </Section>
            </Grid.Column>
          </Grid>
        </Section>
        <Section spacingBottom='30'>
          <Grid gutter='30'>
            <Grid.Column>
              <Field
                checked={data.applicationList.indexOf('walling') >= 0}
                checkboxLabel={getLanguage('field.application.walling', '')}
                ui='checkboxTick'
                onChange={() => {
                  onChangeApplication('walling');
                }}
              />
            </Grid.Column>
            <Grid.Column>
              <Field
                checked={data.applicationList.indexOf('roofing') >= 0}
                checkboxLabel={getLanguage('field.application.roofing', '')}
                ui='checkboxTick'
                onChange={() => {
                  onChangeApplication('roofing');
                }}
              />
            </Grid.Column>
            <Grid.Column>
              <Field
                checked={data.applicationList.indexOf('roofFrame') >= 0}
                checkboxLabel={getLanguage('field.application.roofFrame', '')}
                ui='checkboxTick'
                onChange={() => {
                  onChangeApplication('roofFrame');
                }}
              />
            </Grid.Column>
            <Grid.Column>
              <Field
                checked={data.applicationList.indexOf('wallFrame') >= 0}
                checkboxLabel={getLanguage('field.application.wallFrame', '')}
                ui='checkboxTick'
                onChange={() => {
                  onChangeApplication('wallFrame');
                }}
              />
            </Grid.Column>
          </Grid>
        </Section>
        <Section spacingBottom='30'>
          <Grid gutter='35'>
            <Grid.ResponsiveColumn ui='fieldSelect'>
              {/* <SelectControl
                verticalLabel
                required
                isSearchable
                label={getLanguage('field.brand', '')}
                placeholder={getLanguage('field.brandPlaceholder', '')}
                // name={}
                // value={}
                // options={}
                // disabled={}
                onChange={onChangeDataInput}
              /> */}
              <Field name='brand'
                fluid
                required
                // error
                ui='blockLabelVertical'
                label={getLanguage('field.brand', '')}
                placeholder={getLanguage('field.brandPlaceholder', '')}
                value={data.brand}
                onChange={onChangeDataInput}
              />
            </Grid.ResponsiveColumn>
          </Grid>
        </Section>
        <Section spacingBottom='60'>
          <Grid gutter='35'>
            <Grid.ResponsiveColumn ui='fieldSelect'>
              {/* <SelectControl
                verticalLabel
                required
                isSearchable
                label={getLanguage('field.installationFinishDate', '')}
                placeholder={getLanguage('field.installationFinishDatePlaceholder', '')}
                // name={}
                // value={}
                // options={}
                // disabled={}
                onChange={onChangeDataInput}
              /> */}
              <DatePickerControl
                fluid
                required
                label={getLanguage('field.installationFinishDate', '')}
                placeholderText={getLanguage('field.installationFinishDatePlaceholder', '')}
                dateFormat={DATE_PICKER_FORMAT}
                selected={data.projectInsDateTime}
                minDate={data.createdDateTime}
                onChange={(selected) => {
                  onChangeDataDate(selected, 'projectInsDateTime')
                }}
              />
            </Grid.ResponsiveColumn>
          </Grid>
        </Section>
      </React.Fragment>
    )
  }
}
