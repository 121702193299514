import { watermarkTemplateController } from "../../apiService/apiController/watermarkTemplateService";
import { ModelWatermarkTemplateDetail } from "../../models/ModelWatermarkTemplate";
import { isValidResponse } from "../functions/validation";

export const getWatermarkTemplateDetailSearch = async (params = {}, configService = {}) => {
  const watermarkTemplateService = watermarkTemplateController(configService);
  const res = await watermarkTemplateService.watermarkTemplateDetailSearch(params);
  if (isValidResponse(res)) {
    const data = new ModelWatermarkTemplateDetail(res.data);
    console.log('getWatermarkTemplateDetailSearch', data, res.data)
    return data;
  }
}

export const getWatermarkTemplateDetailSearchSubFolder = async (params = {}, configService = {}) => {
  const watermarkTemplateService = watermarkTemplateController(configService);
  const res = await watermarkTemplateService.watermarkTemplateDetailSearchSubfolder(params);
  if (isValidResponse(res)) {
    const dataList = res.data.map(e => new ModelWatermarkTemplateDetail(e));
    console.log('getWatermarkTemplateDetailSearchSubFolder', dataList, res.data)
    return dataList;
  }
  return [];
}