import React from 'react';
import { authController } from '../../apiService/apiController/authService';
import { notificationManagementController } from '../../apiService/apiController/notificationManagementService';
import { TEXT_VERSION } from '../../config/config';
import { ModalProductUpdateContainer, ModalTermsAndConditionsContainer } from '../../containers/ModalContainer';
import { SideMenuContainerConnected } from '../../containers/SideMenuContainers/SideMenuContainers';
import { TopbarContainerConnected } from '../../containers/TopbarContainer';
import { ENUM } from '../../helpers/constants/enum';
import { LOCAL_STORAGE } from '../../helpers/constants/localStorage';
import { acceptTnC, getAcceptTnc, getUserAuth, logout, setUserAuthRedux } from '../../helpers/functions/auth';
import { setLocalStorage } from '../../helpers/functions/localStorage';
import { isValidResponse } from '../../helpers/functions/validation';
import { getNotificationManagement } from '../../helpers/services/notificationManagement';
import { ModelUserAuth } from '../../models/ModelUserAuth';
import {
  CmsLayout,
  Section
} from './../../components';
import {
  VARIABLES
} from './../../themes';

export class MainLayout extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isRefresh: false,
      isOpenNotificationModal: false,
      notificationDataList: [],
    }
  }

  componentDidMount() {
    this.mainLayout.getTnc();
    this.mainLayout.handleUpdateUserAuth();
  }

  mainLayout = {
    getTnc: async () => {
      const isOpenTermsAndConditions = !(await getAcceptTnc());
      if (!isOpenTermsAndConditions) {
        await this.mainLayout.getNotificationManagement();
      }
      this.setState({
        isOpenTermsAndConditions,
      });
    },
    handleUpdateUserAuth: async () => {
      const userAuth = getUserAuth();
      if (userAuth) {
        const params = {
          email: userAuth.email,
        }
        const configService = {
          isShowLoading: false,
        }
        const authService = authController(configService);
        const res = await authService.loginSearch(params);
        if (isValidResponse(res)) {
          const newUserAuth = new ModelUserAuth(res.data);
          setLocalStorage(LOCAL_STORAGE.USER_AUTH_RBPTRUEBLUE, newUserAuth);
          setUserAuthRedux(newUserAuth);
          this.setState({
            isRefresh: true,
          }, () => {
            this.setState({
              isRefresh: false,
            })
          })
        }
      }
    },
    getNotificationManagement: () => {
      return new Promise(async (resolve) => {
        const userAuth = getUserAuth();
        if (userAuth.addressType === ENUM.COUNTRY_TYPE.THAI) {
          const params = {
            location_account: userAuth.adRefId,
            rbp_member_sfid: userAuth.sfid,
          }
          const dataList = await getNotificationManagement(params);
          if (dataList.length) {
            dataList[0].isShow = true;
            this.setState({
              isOpenNotificationModal: true,
              notificationDataList: dataList,
            }, () => {
              resolve();
            })
          }
          else {
            resolve();
          }
        }
        else {
          resolve();
        }
      });
    },
    onClickAcceptNotificationManagement: async (item) => {
      const {
        notificationDataList,
      } = this.state;
      const userAuth = getUserAuth();
      const params = {
        notification_id: item.id,
        rbp_member_sfid: userAuth.sfid,
        location_account__c: userAuth.adRefId,
        created_by: userAuth.name
      }
      const notificationManagementService = notificationManagementController();
      const res = await notificationManagementService.acceptNotificationManagement(params);
      if (isValidResponse(res)) {
        notificationDataList.splice(0, 1)
        if (notificationDataList[0]) {
          notificationDataList[0].isShow = true;
        }
        this.setState({
          notificationDataList
        })
      }
    }
  }

  modal = {
    onClickCloseTncModal: () => {
      logout();
    },
    onClickAcceptTncModal: async () => {
      const isAccept = await acceptTnC();
      if (isAccept) {
        const data = getUserAuth();
        data.acceptTermsConditions = true;
        setUserAuthRedux(data);
        setLocalStorage(LOCAL_STORAGE.USER_AUTH_RBPTRUEBLUE, data);
        this.setState({
          isOpenTermsAndConditions: false
        }, () => {
          this.mainLayout.getNotificationManagement();
        })
      }
    }
  }

  render() {
    const {
      children,
    } = this.props;
    const {
      isOpenTermsAndConditions,
      isRefresh,
      isOpenNotificationModal,
      notificationDataList,
    } = this.state;
    console.log('notificationDataList', notificationDataList)
    return (
      <React.Fragment>
        <CmsLayout bgColor={VARIABLES.COLORS.BLUE_5}>
          <TopbarContainerConnected />
          <CmsLayout.Sidebar
            sidebarPaddingTop='10'
            sidebarPaddingBottom='10'
            sidebarBoxShadow={VARIABLES.COLORS.SHADOW_1}
          >
            {isRefresh ?
              null
              :
              <SideMenuContainerConnected />
            }
            <Section ui='versionNumber'>
              {TEXT_VERSION}
            </Section>
          </CmsLayout.Sidebar>
          <CmsLayout.Content>
            {children}
          </CmsLayout.Content>
        </CmsLayout>
        {
          isOpenTermsAndConditions &&
          <ModalTermsAndConditionsContainer
            open
            disabledAcceptButton={false}
            onClickClose={this.modal.onClickCloseTncModal}
            onClickAccept={this.modal.onClickAcceptTncModal}
          />
        }
        {isOpenNotificationModal &&
          notificationDataList.map((e, i) => {
            return e.isShow ?
              (
                <ModalProductUpdateContainer isOpenModal
                  key={i}
                  subject={e.subject}
                  description={e.content}
                  imageSrc={e.notiImage.pathRef ? e.notiImage.path : ''}
                  onClickAccept={() => {
                    this.mainLayout.onClickAcceptNotificationManagement(e)
                  }}
                  onClickPrint={() => {
                    window.print();
                  }}
                />
              )
              :
              null
          })
        }
      </React.Fragment>
    )
  }
}
