import React from 'react';
import {
  connect,
} from 'react-redux';
import {
  ShapeContainer,
  Section,
  TableAction,
  Grid,
  Text,
  Button,
  PaginationControl,
  Table,
} from './../../components';
import {
  VARIABLES,
  ICONS
} from './../../themes';
import { getTransferHistoryList, getClearTransferHistoryParams, getClearTransferHistorySearchParams, getTransferSearchHistoryList } from '../../helpers/services/transfer';
import { ENUM } from '../../helpers/constants/enum';
import { getLanguage } from '../../helpers/functions/language';
import _ from 'lodash';
import { getSortAscending } from '../../helpers/functions/data';

class CoilTransferHistoryTableContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activePage: 1,
      dataPerPage: 10,
      customerName: props.customerName,
      // ProfileMappingHistoryScreen
      customerRoleType: props.customerRoleType, // ENUM.CUSTOMER_ROLE_TYPE.INDONESIA.AD, ENUM.CUSTOMER_ROLE_TYPE.INDONESIA.TOKO, ENUM.CUSTOMER_ROLE_TYPE.INDONESIA.DISTRIBUTOR
      // [TODO : RL-170]
      isIndonesia: {
        toko: false,
        distributor: false,
      },
    }
  }

  componentDidMount() {
    const {
      isLastCoilTransfer,
    } = this.props;
    const {
      activePage,
      dataPerPage,
      customerName,
      customerRoleType,
    } = this.state;
    if (customerRoleType) {
      const params = {
        ...getClearTransferHistoryParams(),
        page: activePage,
        limit: dataPerPage,
        customer_name: customerName,
        customer_role_type: customerRoleType
      };
      getTransferHistoryList(params);
    }
    else {
      if (!isLastCoilTransfer) {
        const params = {
          ...getClearTransferHistorySearchParams(),
          page: activePage,
          limit: dataPerPage,
        };
        getTransferSearchHistoryList(params);
      }
    }
  }

  historyTransList = {
    onClickSort: (target) => {
      const {
        customerRoleType,
      } = this.state;
      if (customerRoleType) {
        getTransferHistoryList({
          sort: target
        });
      }
      else {
        getTransferSearchHistoryList({
          sort: target
        });
      }
    }
  }

  pagination = {
    onPaginationChange: (pageNumber) => {
      const {
        customerRoleType,
      } = this.state;
      if (customerRoleType) {
        getTransferHistoryList({
          page: pageNumber,
        });
      }
      else {
        getTransferSearchHistoryList({
          isNoSort: true,
          page: pageNumber,
        });
      }
    }
  }

  render() {
    const {
      dataList,
      dataParams,
      totalData,
      activePage,
      // isProfileMapping,
      isLastCoilTransfer,
    } = this.props;
    const {
      dataPerPage,
      customerRoleType,
      isIndonesia,
    } = this.state;
    return (
      <React.Fragment>
        <Section spacingBottom='45'>
          {
            ((customerRoleType === ENUM.CUSTOMER_ROLE_TYPE.INDONESIA.AD) || (customerRoleType === ENUM.CUSTOMER_ROLE_TYPE.INDONESIA.TOKO) || (customerRoleType === ENUM.CUSTOMER_ROLE_TYPE.INDONESIA.DISTRIBUTOR)) ?
              <Table striped>
                <Table.Section>
                  <Table.Row>
                    <Table.HeadColumn
                      maxWidth={VARIABLES.TABLE.INFO_WIDTHS.DATE}
                      minWidth={VARIABLES.TABLE.INFO_WIDTHS.DATE}
                      sortAscending={true}
                    // onClickSort={}
                    >
                      {getLanguage('container.coilTransferHistoryTable.table.header.date', '')}
                    </Table.HeadColumn>
                    {/* <Table.HeadColumn
                      maxWidth={VARIABLES.TABLE.INFO_WIDTHS.ORDER_NO}
                      minWidth={VARIABLES.TABLE.INFO_WIDTHS.ORDER_NO}
                      sortAscending={true}
                        // onClickSort={}
                    >
                      Order No.
                    </Table.HeadColumn> */}
                    {
                      (customerRoleType === ENUM.CUSTOMER_ROLE_TYPE.INDONESIA.AD) &&
                      <React.Fragment>
                        <Table.HeadColumn
                          maxWidth={VARIABLES.TABLE.INFO_WIDTHS.COIL_ID}
                          minWidth={VARIABLES.TABLE.INFO_WIDTHS.COIL_ID}
                          sortAscending={true}
                        // onClickSort={}
                        >
                          {getLanguage('container.coilTransferHistoryTable.table.header.coilID', '')}
                        </Table.HeadColumn>
                        <Table.HeadColumn
                          minWidth={VARIABLES.TABLE.INFO_WIDTHS.COIL_NAME}
                          sortAscending={true}
                        // onClickSort={}
                        >
                          {getLanguage('container.coilTransferHistoryTable.table.header.coilName', '')}
                        </Table.HeadColumn>
                      </React.Fragment>
                    }
                    <Table.HeadColumn
                      minWidth={VARIABLES.TABLE.INFO_WIDTHS.COMPANY_NAME}
                      sortAscending={true}
                    // onClickSort={}
                    >
                      {getLanguage('container.coilTransferHistoryTable.table.header.companyName', '')}
                    </Table.HeadColumn>
                    <Table.HeadColumn
                      maxWidth={VARIABLES.TABLE.INFO_WIDTHS.COIL_ID}
                      minWidth={VARIABLES.TABLE.INFO_WIDTHS.COIL_ID}
                      sortAscending={true}
                    // onClickSort={}
                    >
                      {getLanguage('container.coilTransferHistoryTable.table.header.coilID', '')}
                    </Table.HeadColumn>
                    <Table.HeadColumn
                      maxWidth={VARIABLES.TABLE.INFO_WIDTHS.PROFILE}
                      minWidth={VARIABLES.TABLE.INFO_WIDTHS.PROFILE}
                      sortAscending={true}
                    // onClickSort={}
                    >
                      {getLanguage('container.coilTransferHistoryTable.table.header.profile', '')}
                    </Table.HeadColumn>
                    <Table.HeadColumn
                      maxWidth={VARIABLES.TABLE.COLUMN_WIDTHS.PHONE}
                      minWidth={VARIABLES.TABLE.COLUMN_WIDTHS.PHONE}
                      sortAscending={true}
                    // onClickSort={}
                    >
                      {getLanguage('container.coilTransferHistoryTable.table.header.profileID', '')}
                    </Table.HeadColumn>
                    <Table.HeadColumn
                      alignRight
                      maxWidth={VARIABLES.TABLE.INFO_WIDTHS.PROFILE_QUANTITY_UNIT}
                      minWidth={VARIABLES.TABLE.INFO_WIDTHS.PROFILE_QUANTITY_UNIT}
                      sortAscending={true}
                      // onClickSort={}
                      title={getLanguage('container.coilTransferHistoryTable.table.header.quantity', '')}
                    >
                      {getLanguage('container.coilTransferHistoryTable.table.header.quantityAbbreviation', '')}
                    </Table.HeadColumn>
                    <Table.HeadColumn
                      alignRight
                      maxWidth={VARIABLES.TABLE.INFO_WIDTHS.PROFILE_LENGTH_UNIT}
                      minWidth={VARIABLES.TABLE.INFO_WIDTHS.PROFILE_LENGTH_UNIT}
                      sortAscending={true}
                      // onClickSort={}
                      title={getLanguage('container.coilTransferHistoryTable.table.header.length', '')}
                    >
                      {getLanguage('container.coilTransferHistoryTable.table.header.lengthAbbreviation', '')}
                    </Table.HeadColumn>
                    <Table.HeadColumn
                      alignRight
                      maxWidth={VARIABLES.TABLE.INFO_WIDTHS.TOTAL_LENGTH_M}
                      minWidth={VARIABLES.TABLE.INFO_WIDTHS.TOTAL_LENGTH_M}
                      sortAscending={true}
                    // onClickSort={}
                    >
                      {getLanguage('container.coilTransferHistoryTable.table.header.totalLength', '')}
                    </Table.HeadColumn>
                  </Table.Row>
                </Table.Section>
                <Table.Section>
                  {
                    dataList.map((e, i) => {
                      // const profileTypeMapping = {
                      //   trussc7565: 'Truss C75-65',
                      //   trussc7575: 'Truss C75-75',
                      //   rengr3045: 'RengR30-45',
                      //   rengr3245: 'RengR32-45',
                      //   rengr3545: 'RengR35-45',
                      //   longspan025tct: 'Long Span 0.25 TCT',
                      //   longspan030tct: 'Long Span 0.30 TCT',
                      //   longspan035tct: 'Long Span 0.35 TCT',
                      //   metaltile025tct: 'Metal Tile 0.25 TCT',
                      //   metaltile030tct: 'Metal Tile 0.30 TCT',
                      //   metaltile035tct: 'Metal Tile 0.35 TCT'
                      // }
                      return (
                        <Table.Row
                          key={i}
                        >
                          <Table.BodyColumn
                            info
                            maxWidth={VARIABLES.TABLE.INFO_WIDTHS.DATE}
                            minWidth={VARIABLES.TABLE.INFO_WIDTHS.DATE}
                          >
                            {e.displayCreatedDate}
                          </Table.BodyColumn>
                          {/* <Table.BodyColumn
                            info
                            maxWidth={VARIABLES.TABLE.INFO_WIDTHS.ORDER_NO}
                            minWidth={VARIABLES.TABLE.INFO_WIDTHS.ORDER_NO}
                            title='600182890'
                          >
                            600182890
                          </Table.BodyColumn> */}
                          {
                            (customerRoleType === ENUM.CUSTOMER_ROLE_TYPE.INDONESIA.AD) &&
                            <React.Fragment>
                              <Table.BodyColumn
                                info
                                maxWidth={VARIABLES.TABLE.INFO_WIDTHS.COIL_ID}
                                minWidth={VARIABLES.TABLE.INFO_WIDTHS.COIL_ID}
                                title={e.coilID}
                              >
                                {e.coilID}
                              </Table.BodyColumn>
                              <Table.BodyColumn
                                info
                                minWidth={VARIABLES.TABLE.INFO_WIDTHS.COIL_NAME}
                                title={e.coilName}
                              >
                                {e.coilName}
                              </Table.BodyColumn>
                            </React.Fragment>
                          }
                          <Table.BodyColumn
                            info
                            minWidth={VARIABLES.TABLE.INFO_WIDTHS.COMPANY_NAME}
                            title={e.companyName}
                          >
                            {e.companyName}
                          </Table.BodyColumn>
                          <Table.BodyColumn
                            info
                            maxWidth={VARIABLES.TABLE.INFO_WIDTHS.COIL_ID}
                            minWidth={VARIABLES.TABLE.INFO_WIDTHS.COIL_ID}
                            title={e.coilID}
                          >
                            {e.coilID}
                          </Table.BodyColumn>
                          <Table.BodyColumn
                            info
                            maxWidth={VARIABLES.TABLE.INFO_WIDTHS.PROFILE}
                            minWidth={VARIABLES.TABLE.INFO_WIDTHS.PROFILE}
                            title={e.profileType}
                          >
                            {e.profileType}
                          </Table.BodyColumn>
                          <Table.BodyColumn
                            info
                            maxWidth={VARIABLES.TABLE.COLUMN_WIDTHS.PHONE}
                            minWidth={VARIABLES.TABLE.COLUMN_WIDTHS.PHONE}
                          >
                            {e.profileTransID}
                          </Table.BodyColumn>
                          <Table.BodyColumn
                            info
                            alignRight
                            maxWidth={VARIABLES.TABLE.INFO_WIDTHS.PROFILE_QUANTITY_UNIT}
                            minWidth={VARIABLES.TABLE.INFO_WIDTHS.PROFILE_QUANTITY_UNIT}
                          >
                            {e.profileQty}
                          </Table.BodyColumn>
                          <Table.BodyColumn
                            info
                            alignRight
                            maxWidth={VARIABLES.TABLE.INFO_WIDTHS.PROFILE_LENGTH_UNIT}
                            minWidth={VARIABLES.TABLE.INFO_WIDTHS.PROFILE_LENGTH_UNIT}
                          >
                            {e.profileLen}
                          </Table.BodyColumn>
                          <Table.BodyColumn
                            info
                            alignRight
                            maxWidth={VARIABLES.TABLE.INFO_WIDTHS.TOTAL_LENGTH_M}
                            minWidth={VARIABLES.TABLE.INFO_WIDTHS.TOTAL_LENGTH_M}
                          >
                            {e.tranAmount}
                          </Table.BodyColumn>
                        </Table.Row>
                      )
                    })
                  }
                </Table.Section>
              </Table>
              :
              <Table striped>
                <Table.Section>
                  <Table.Row>
                    <Table.HeadColumn
                      maxWidth={VARIABLES.TABLE.INFO_WIDTHS.DATE}
                      minWidth={VARIABLES.TABLE.INFO_WIDTHS.DATE}
                      sortAscending={getSortAscending(dataParams, 'date')}
                      onClickSort={() => {
                        this.historyTransList.onClickSort('date desc');
                      }}
                    >
                      {getLanguage('container.coilTransferHistoryTable.table.header.date', '')}
                    </Table.HeadColumn>
                    <Table.HeadColumn
                      maxWidth={VARIABLES.TABLE.INFO_WIDTHS.ORDER_NO}
                      minWidth={VARIABLES.TABLE.INFO_WIDTHS.ORDER_NO}
                      sortAscending={getSortAscending(dataParams, 'order_no')}
                      onClickSort={() => {
                        this.historyTransList.onClickSort('order_no desc');
                      }}
                    >
                      {getLanguage('container.coilTransferHistoryTable.table.header.orderNo', '')}
                    </Table.HeadColumn>
                    <Table.HeadColumn
                      minWidth={VARIABLES.TABLE.INFO_WIDTHS.COIL_NAME}
                      sortAscending={getSortAscending(dataParams, 'coil_name')}
                      onClickSort={() => {
                        this.historyTransList.onClickSort('coil_name desc');
                      }}
                    >
                      {getLanguage('container.coilTransferHistoryTable.table.header.coilName', '')}
                    </Table.HeadColumn>
                    <Table.HeadColumn
                      alignCenter
                      maxWidth={VARIABLES.TABLE.INFO_WIDTHS.COIL_ID_INFO}
                      minWidth={VARIABLES.TABLE.INFO_WIDTHS.COIL_ID_INFO}
                      sortAscending={getSortAscending(dataParams, 'coil_id')}
                      onClickSort={() => {
                        this.historyTransList.onClickSort('coil_id desc');
                      }}
                    >
                      {getLanguage('container.coilTransferHistoryTable.table.header.coilID', '')}
                    </Table.HeadColumn>
                    {/* <Table.HeadColumn
                          maxWidth={VARIABLES.TABLE.INFO_WIDTHS.COMPANY_NAME}
                          minWidth={VARIABLES.TABLE.INFO_WIDTHS.COMPANY_NAME}
                          sortAscending={getSortAscending(dataParams, 'company_name')}
                          onClickSort={() => {
                            this.historyTransList.onClickSort('company_name desc');
                          }}
                        >
                          {getLanguage('container.coilTransferHistoryTable.table.header.companyName', '')}
                        </Table.HeadColumn>
                        <Table.HeadColumn
                          maxWidth={VARIABLES.TABLE.COLUMN_WIDTHS.PHONE}
                          minWidth={VARIABLES.TABLE.COLUMN_WIDTHS.PHONE}
                          sortAscending={getSortAscending(dataParams, 'phone_no')}
                          onClickSort={() => {
                            this.historyTransList.onClickSort('phone_no desc');
                          }}
                        >
                          {getLanguage('container.coilTransferHistoryTable.table.header.phoneNo', '')}
                        </Table.HeadColumn> */}
                    {/* <Table.HeadColumn
                      alignRight
                      maxWidth={VARIABLES.TABLE.INFO_WIDTHS.TRANSFER_AMOUNT_M_INFO}
                      minWidth={VARIABLES.TABLE.INFO_WIDTHS.TRANSFER_AMOUNT_M_INFO}
                      sortAscending={true}
                      // onClickSort={}
                    >
                      {getLanguage('container.coilTransferHistoryTable.table.header.transferAmount', '')}
                    </Table.HeadColumn> */}
                    <Table.HeadColumn
                      alignRight
                      maxWidth={VARIABLES.TABLE.INFO_WIDTHS.COMPANY_NAME}
                      minWidth={VARIABLES.TABLE.INFO_WIDTHS.COMPANY_NAME}
                      sortAscending={getSortAscending(dataParams, 'transfer_from')}
                      onClickSort={() => {
                        this.historyTransList.onClickSort('transfer_from desc');
                      }}
                    >
                      {getLanguage('container.coilTransferHistoryTable.table.header.tranferFrom', '')}
                    </Table.HeadColumn>
                    <Table.HeadColumn
                      alignRight
                      maxWidth={VARIABLES.TABLE.INFO_WIDTHS.COMPANY_NAME}
                      minWidth={VARIABLES.TABLE.INFO_WIDTHS.COMPANY_NAME}
                      sortAscending={getSortAscending(dataParams, 'transfer_to')}
                      onClickSort={() => {
                        this.historyTransList.onClickSort('transfer_to desc');
                      }}
                    >
                      {getLanguage('container.coilTransferHistoryTable.table.header.tranferTo', '')}
                    </Table.HeadColumn>
                    <Table.HeadColumn
                      alignRight
                      maxWidth={VARIABLES.TABLE.INFO_WIDTHS.LENGTH_M}
                      minWidth={VARIABLES.TABLE.INFO_WIDTHS.LENGTH_M}
                      sortAscending={getSortAscending(dataParams, 'length')}
                      onClickSort={() => {
                        this.historyTransList.onClickSort('length desc');
                      }}
                    >
                      {getLanguage('container.coilTransferHistoryTable.table.header.lengthFullText', '')}
                    </Table.HeadColumn>
                    <Table.HeadColumn
                      alignRight
                      maxWidth={VARIABLES.TABLE.INFO_WIDTHS.WEIGHT_KG}
                      minWidth={VARIABLES.TABLE.INFO_WIDTHS.WEIGHT_KG}
                      noSort
                    >
                      {getLanguage('container.coilTransferHistoryTable.table.header.weight', '')}
                    </Table.HeadColumn>
                    <Table.HeadColumn
                      alignCenter
                      maxWidth={VARIABLES.TABLE.INFO_WIDTHS.COMPANY_NAME}
                      minWidth={VARIABLES.TABLE.INFO_WIDTHS.COMPANY_NAME}
                      sortAscending={getSortAscending(dataParams, 'status__c')}
                      onClickSort={() => {
                        this.historyTransList.onClickSort('status__c desc');
                      }}
                    >
                      {getLanguage('container.coilTransferHistoryTable.table.header.status', '')}
                    </Table.HeadColumn>
                  </Table.Row>
                </Table.Section>
                <Table.Section>
                  {
                    dataList.map((e, i) => {
                      return (
                        <Table.Row
                          key={i}
                        >
                          <Table.BodyColumn
                            info
                            maxWidth={VARIABLES.TABLE.INFO_WIDTHS.DATE}
                            minWidth={VARIABLES.TABLE.INFO_WIDTHS.DATE}
                          >
                            {e.displayDate}
                          </Table.BodyColumn>
                          <Table.BodyColumn
                            info
                            maxWidth={VARIABLES.TABLE.INFO_WIDTHS.ORDER_NO}
                            minWidth={VARIABLES.TABLE.INFO_WIDTHS.ORDER_NO}
                            title={e.orderNo}
                          >
                            {e.orderNo}
                          </Table.BodyColumn>
                          <Table.BodyColumn
                            info
                            minWidth={VARIABLES.TABLE.INFO_WIDTHS.COIL_NAME}
                            title={e.coilName}
                          >
                            {e.coilName}
                          </Table.BodyColumn>
                          <Table.BodyColumn
                            info
                            maxWidth={VARIABLES.TABLE.INFO_WIDTHS.COIL_ID_INFO}
                            minWidth={VARIABLES.TABLE.INFO_WIDTHS.COIL_ID_INFO}
                            title={e.coilID}
                          >
                            {e.coilID}
                          </Table.BodyColumn>

                          {/* <Table.BodyColumn
                                info
                                maxWidth={VARIABLES.TABLE.INFO_WIDTHS.COMPANY_NAME}
                                minWidth={VARIABLES.TABLE.INFO_WIDTHS.COMPANY_NAME}
                                title={e.companyName}
                              >
                                {e.companyName}
                              </Table.BodyColumn>
                              <Table.BodyColumn
                                phone
                                maxWidth={VARIABLES.TABLE.COLUMN_WIDTHS.PHONE}
                                minWidth={VARIABLES.TABLE.COLUMN_WIDTHS.PHONE}
                                idPhone={e.phoneCountry.value}
                                srcFlag={e.phoneCountry.flag}
                              >
                                {e.phoneNo}
                              </Table.BodyColumn> */}
                          {/* <Table.BodyColumn
                                phone
                                maxWidth={VARIABLES.TABLE.COLUMN_WIDTHS.PHONE}
                                minWidth={VARIABLES.TABLE.COLUMN_WIDTHS.PHONE}
                                idPhone={e.phoneCountry.value}
                                srcFlag={e.phoneCountry.flag}
                              >
                                {e.phoneNo}
                              </Table.BodyColumn> */}

                          <Table.BodyColumn
                            info
                            maxWidth={VARIABLES.TABLE.INFO_WIDTHS.COMPANY_NAME}
                            minWidth={VARIABLES.TABLE.INFO_WIDTHS.COMPANY_NAME}
                            title={e.transferFrom}
                          >
                            {e.transferFrom}
                          </Table.BodyColumn>
                          <Table.BodyColumn
                            info
                            maxWidth={VARIABLES.TABLE.INFO_WIDTHS.COMPANY_NAME}
                            minWidth={VARIABLES.TABLE.INFO_WIDTHS.COMPANY_NAME}
                            title={e.transferTo}
                          >
                            {e.transferTo}
                          </Table.BodyColumn>
                          <Table.BodyColumn
                            info
                            alignRight
                            maxWidth={VARIABLES.TABLE.INFO_WIDTHS.LENGTH_M}
                            minWidth={VARIABLES.TABLE.INFO_WIDTHS.LENGTH_M}
                            title={e.length}
                          >
                            {e.length}
                          </Table.BodyColumn>
                          <Table.BodyColumn
                            info
                            alignRight
                            maxWidth={VARIABLES.TABLE.INFO_WIDTHS.WEIGHT_KG}
                            minWidth={VARIABLES.TABLE.INFO_WIDTHS.WEIGHT_KG}
                            title={e.weight}
                          >
                            {e.weight}
                          </Table.BodyColumn>
                          <Table.BodyColumn
                            info
                            alignCenter
                            maxWidth={VARIABLES.TABLE.INFO_WIDTHS.WEIGHT_KG}
                            minWidth={VARIABLES.TABLE.INFO_WIDTHS.WEIGHT_KG}
                            title={e.status}
                          >
                            {e.status}
                          </Table.BodyColumn>
                        </Table.Row>
                      )
                    })
                  }
                </Table.Section>
              </Table>
          }
          {/* [TODO : RL-170] */}
          {(isIndonesia.toko || isIndonesia.distributor) &&
            <Table striped>
              <Table.Section>
                <Table.Row>
                  <Table.HeadColumn
                    maxWidth={VARIABLES.TABLE.INFO_WIDTHS.DATE}
                    minWidth={VARIABLES.TABLE.INFO_WIDTHS.DATE}
                    sortAscending={true}
                  // onClickSort={}
                  >
                    {getLanguage('container.coilTransferHistoryTable.table.header.date', '')}
                  </Table.HeadColumn>
                  <Table.HeadColumn
                    maxWidth={VARIABLES.TABLE.INFO_WIDTHS.ORDER_NO}
                    minWidth={VARIABLES.TABLE.INFO_WIDTHS.ORDER_NO}
                    sortAscending={true}
                  // onClickSort={}
                  >
                    {getLanguage('container.coilTransferHistoryTable.table.header.orderNo', '')}
                  </Table.HeadColumn>
                  <Table.HeadColumn
                    minWidth={VARIABLES.TABLE.INFO_WIDTHS.COMPANY_NAME}
                    sortAscending={true}
                  // onClickSort={}
                  >
                    {getLanguage('container.coilTransferHistoryTable.table.header.companyName', '')}
                  </Table.HeadColumn>
                  <Table.HeadColumn
                    minWidth={VARIABLES.TABLE.INFO_WIDTHS.PROFILE}
                    sortAscending={true}
                  // onClickSort={}
                  >
                    {getLanguage('container.coilTransferHistoryTable.table.header.profile', '')}
                  </Table.HeadColumn>
                  <Table.HeadColumn
                    minWidth={VARIABLES.TABLE.INFO_WIDTHS.CONTACT_NAME}
                    sortAscending={true}
                  // onClickSort={}
                  >
                    {getLanguage('container.coilTransferHistoryTable.table.header.contactName', '')}
                  </Table.HeadColumn>
                  <Table.HeadColumn
                    maxWidth={VARIABLES.TABLE.COLUMN_WIDTHS.PHONE}
                    minWidth={VARIABLES.TABLE.COLUMN_WIDTHS.PHONE}
                    sortAscending={true}
                  // onClickSort={}
                  >
                    {getLanguage('container.coilTransferHistoryTable.table.header.phoneNo', '')}
                  </Table.HeadColumn>
                  <Table.HeadColumn
                    alignRight
                    maxWidth={VARIABLES.TABLE.INFO_WIDTHS.QUANTITY_UNIT}
                    minWidth={VARIABLES.TABLE.INFO_WIDTHS.QUANTITY_UNIT}
                    sortAscending={true}
                  // onClickSort={}
                  >
                    {getLanguage('container.coilTransferHistoryTable.table.header.quantityUnit', '')}
                  </Table.HeadColumn>
                  <Table.HeadColumn
                    alignRight
                    maxWidth={VARIABLES.TABLE.INFO_WIDTHS.LENGTH_UNIT}
                    minWidth={VARIABLES.TABLE.INFO_WIDTHS.LENGTH_UNIT}
                    sortAscending={true}
                  // onClickSort={}
                  >
                    {getLanguage('container.coilTransferHistoryTable.table.header.lengthUnit', '')}
                  </Table.HeadColumn>
                </Table.Row>
              </Table.Section>
              <Table.Section>
                <Table.Row>
                  <Table.BodyColumn
                    info
                    maxWidth={VARIABLES.TABLE.INFO_WIDTHS.DATE}
                    minWidth={VARIABLES.TABLE.INFO_WIDTHS.DATE}
                  >
                    DATE
                  </Table.BodyColumn>
                  <Table.BodyColumn
                    info
                    maxWidth={VARIABLES.TABLE.INFO_WIDTHS.ORDER_NO}
                    minWidth={VARIABLES.TABLE.INFO_WIDTHS.ORDER_NO}
                    title={'ORDER_NO'}
                  >
                    ORDER_NO
                  </Table.BodyColumn>
                  <Table.BodyColumn
                    info
                    minWidth={VARIABLES.TABLE.INFO_WIDTHS.PROFILE}
                    title={'PROFILE'}
                  >
                    PROFILE
                  </Table.BodyColumn>
                  <Table.BodyColumn
                    info
                    minWidth={VARIABLES.TABLE.INFO_WIDTHS.COMPANY_NAME}
                    title={'COMPANY_NAME'}
                  >
                    COMPANY_NAME
                  </Table.BodyColumn>
                  <Table.BodyColumn
                    info
                    minWidth={VARIABLES.TABLE.INFO_WIDTHS.CONTACT_NAME}
                    title={'COMPANY_NAME'}
                  >
                    COMPANY_NAME
                  </Table.BodyColumn>
                  <Table.BodyColumn
                    phone
                    maxWidth={VARIABLES.TABLE.COLUMN_WIDTHS.PHONE}
                    minWidth={VARIABLES.TABLE.COLUMN_WIDTHS.PHONE}
                    idPhone={'62'}
                    srcFlag={ICONS['ic-flag-id.jpg']}
                  >
                    PHONE
                  </Table.BodyColumn>
                  <Table.BodyColumn
                    info
                    alignRight
                    maxWidth={VARIABLES.TABLE.INFO_WIDTHS.QUANTITY_UNIT}
                    minWidth={VARIABLES.TABLE.INFO_WIDTHS.QUANTITY_UNIT}
                  >
                    LENGTH
                  </Table.BodyColumn>
                  <Table.BodyColumn
                    info
                    alignRight
                    maxWidth={VARIABLES.TABLE.INFO_WIDTHS.LENGTH_UNIT}
                    minWidth={VARIABLES.TABLE.INFO_WIDTHS.LENGTH_UNIT}
                  >
                    WEIGHT
                  </Table.BodyColumn>
                </Table.Row>
              </Table.Section>
            </Table>
          }
        </Section>
        {!isLastCoilTransfer &&
          <TableAction>
            <TableAction.Left>
              {/* <Grid gutter='15'>
              <Grid.Column>
                <Button
                  blue
                  ui='frontIcon'
                  name='Print'
                  width={VARIABLES.BUTTON.WIDTHS.W_125}
                  iconWidth='18'
                  iconHeight='18'
                  iconSrc={ICONS['ic-printer.svg']}
                  onClick={() => { }}
                />
              </Grid.Column>
              <Grid.Column>
                <Button
                  green
                  ui='frontIcon'
                  name='Export'
                  width={VARIABLES.BUTTON.WIDTHS.W_125}
                  iconWidth='16'
                  iconHeight='16'
                  iconSrc={ICONS['ic-export.svg']}
                  onClick={() => { }}
                />
              </Grid.Column>
            </Grid> */}
            </TableAction.Left>
            <TableAction.Right>
              <Grid gutter='20'>
                <Grid.Column>
                  <Section paddingTop='6'>
                    <Text
                      regular12
                      color={VARIABLES.COLORS.GRAY_16}
                    >
                      {getLanguage('pagination.page', '')} {activePage}/{Math.ceil(totalData / dataPerPage)}
                    </Text>
                  </Section>
                </Grid.Column>
                <Grid.Column>
                  <PaginationControl
                    activePage={activePage}
                    itemsCountPerPage={dataPerPage}
                    totalItemsCount={totalData}
                    pageRangeDisplayed={3}
                    onChange={this.pagination.onPaginationChange}
                  />
                </Grid.Column>
              </Grid>
            </TableAction.Right>
          </TableAction>
        }
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => {
  const dataRedux = state.dataRedux;
  return {
    dataList: dataRedux.transferHisList,
    dataParams: dataRedux.transferHisParams,
    activePage: dataRedux.transferHisParams.activePage,
    totalData: dataRedux.transferHisParams.totalData || 0,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
  }
}

export const CoilTransferHistoryTableContainerConnected = connect(
  mapStateToProps,
  mapDispatchToProps
)(CoilTransferHistoryTableContainer)