import React from 'react';
import {
  connect,
} from 'react-redux';
import { getCustomerList, getClearCustomerParams } from '../../helpers/services/customer';
import { CustomerTableContainer } from './CustomerTableContainer';
import { CustomerSelectTableContainer } from './CustomerSelectTableContainer';
import { CustomerPointsTableContainer } from './CustomerPointsTableContainer';

class CustomerListContainer extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      activePage: 1,
      dataPerPage: 10
    }
  }

  componentDidMount() {
    const {
      type,
    } = this.props;
    const {
      activePage,
      dataPerPage,
    } = this.state;
    const params = {
      ...getClearCustomerParams(),
      page: activePage,
      // limit: type === 'selectCustomer' ? 0 : dataPerPage,
      limit: dataPerPage,
    };
    getCustomerList(params);
  }

  customerList = {
    onClickSort: (target) => {
      getCustomerList({
        isNoSort: false,
        sort: target
      });
    },
  }

  pagination = {
    onPaginationChange: (pageNumber) => {
      getCustomerList({
        isNoSort: true,
        page: pageNumber,
      });
    }
  }


  render() {
    const {
      dataList,
      dataParams,
      totalData,
      activePage,
      type,
      filterValue,
      onClickCustomer,
      customerData,
      innerRef,
      onClickSort,
      isMalay,
      isCheckboxTick
    } = this.props;
    const {
      dataPerPage
    } = this.state;
    switch (type) {
      case 'selectCustomer':
        return (
          <CustomerSelectTableContainer
            ref={innerRef}
            dataList={dataList}
            dataParams={dataParams}
            filterValue={filterValue}
            onClickCustomer={onClickCustomer}
            customerData={customerData}
            activePage={activePage}
            dataPerPage={dataPerPage}
            onClickSort={onClickSort}
            isCheckboxTick={isCheckboxTick}
          />
        )
      case 'pointDetail':
        return (
          <CustomerPointsTableContainer
            ref={innerRef}
            dataList={dataList}
            dataParams={dataParams}
            totalData={totalData}
            activePage={activePage}
            dataPerPage={dataPerPage}
            onClickSort={this.customerList.onClickSort}
            onPaginationChange={this.pagination.onPaginationChange}
          />
        )
      default:
        return (
          <CustomerTableContainer
            ref={innerRef}
            dataList={dataList}
            dataParams={dataParams}
            totalData={totalData}
            activePage={activePage}
            dataPerPage={dataPerPage}
            onClickSort={this.customerList.onClickSort}
            onPaginationChange={this.pagination.onPaginationChange}
            isMalay={isMalay}
          />
        )
    }
  }
}

const mapStateToProps = (state) => {
  const dataRedux = state.dataRedux;
  return {
    dataList: dataRedux.customerList,
    dataParams: dataRedux.customerParams,
    activePage: dataRedux.customerParams.activePage,
    totalData: dataRedux.customerParams.totalData || 0,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
  }
}

export const CustomerListContainerConnected = connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomerListContainer)