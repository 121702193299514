export const isValidResponse = (res) => {
  if (res.validation || res.message === 'success') {
    return res
  }
}

export const isValidEmail = (email = '') => {
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email.toLowerCase()) && isValidEmailEnglish(email);
}

export const isValidEmailEnglish = (string = '') => {
  const re = /^[A-Za-z0-9|\@|\.|\_]*$/;
  return re.test(string.toLowerCase());
}

export const isValidNumber = (number) => {
  // const re = /^-?\d*\.?\d*$/;
  const re = /^[0-9]*$/;
  return re.test(number);
}

export const isNumber = (number) => {
  return isNaN(parseInt(number)) === false;
}

export const isValidFloat = (number) => {
  // return isFinite(number);
  const re = /^[0-9]*\.?[0-9]*$/;
  return re.test(number);
}

export const isValidPhoneNumber = (number) => {
  // const re = /^-?\d*\.?\d*$/;
  const re = /^[1-9][0-9]*$/;
  return re.test(number);
}
