import styled, {
  // css
} from 'styled-components'
import {
  default as VARIABLES
} from './../../../themes/styles/bases/variables'
// import {
//   default as TYPOGRAPHYS
// } from './../../../themes/styles/bases/typographys'
// import {
//   default as MIXINS
// } from './../../../themes/styles/helpers/mixins'

// Sample using image in style
// url(${require('./../../../themes/images/icons/icon-sample.svg')});

// Wrapper
// ============================================================
export const DocumentCardWrapper = styled.div`
  /* Parent
  ------------------------------- */
  /* Childrens
  ------------------------------- */
  .document-card-container {
    /* transition: ${VARIABLES.TRANSITIONS.DEFAULT}; */
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 284px;
    padding: 15px;
    margin: 0 0 15px;
    background-color: ${VARIABLES.COLORS.WHITE};
    border: 1px solid ${VARIABLES.COLORS.GRAY_8};
    border-radius: 5px;
  }

  .document-card-image {
    &.is-logo {
      width: auto;
      height: 48px;
    }

    &.is-line {
      width: 100%;
      height: auto;
    }

    &.is-icon-button {
      margin: 0 10px 0 0;
    }

    &.is-icon-preview {
      position: relative;
      top: -1px;
    }
  }

  .document-card-checkbox {
    .document-card-checkbox-input {
      cursor: pointer;
      opacity: 0;
      position: absolute;
      z-index: ${VARIABLES.Z_INDEXS.LV_1};
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;

      &:checked {
        + .document-card-checkbox-content {
          .document-card-checkbox-icon {
            background-color: ${VARIABLES.COLORS.PRIMARY_2};
            border: 1px solid ${VARIABLES.COLORS.PRIMARY_2};
          }

          .document-card-checkbox-icon-checked {
            opacity: 1;
          }
        }
      }
    }

    .document-card-checkbox-content {
      display: block;
    }

    .document-card-checkbox-icon {
      transition: ${VARIABLES.TRANSITIONS.DEFAULT};
      position: relative;
      display: block;
      width: 24px;
      height: 24px;
      background-color: ${VARIABLES.COLORS.WHITE};
      border: 1px solid ${VARIABLES.COLORS.GRAY_12};
      border-radius: 12px;
    }

    .document-card-checkbox-icon-checked {
      transition: ${VARIABLES.TRANSITIONS.DEFAULT};
      transform: translate(-50%, -50%);
      opacity: 0;
      position: absolute;
      z-index: ${VARIABLES.Z_INDEXS.LV_1};
      top: 50%;
      left: 50%;
      width: 11px;
      height: 8px;
    }
  }

  .document-card-button {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;

    &.is-small {
      height: 20px;
      padding: 0 10px;
      border-radius: 3px;
    }

    &.is-border-blue {
      border: 1px solid ${VARIABLES.COLORS.PRIMARY_2};
    }

    &.is-white {
      background-color: ${VARIABLES.COLORS.WHITE};
    }
  }

  /* Modifiers
  ------------------------------- */
  &.is-selected {
    .document-card-container {
      outline: 3px solid ${VARIABLES.COLORS.PRIMARY_2};
    }
  }

  /* Media queries
  ------------------------------- */
`
