import React from 'react'
import ClassNames from 'classnames'
import PropTypes from 'prop-types'
import {
  osName,
} from 'react-device-detect'
import {
  WarrantyNotCoveredListsWrapper
} from './styled'
import {
  Section,
  Text,
  Image,
  Grid,
  Table,
  ShapeContainer,
} from './../../../../components'
import {
  VARIABLES,
} from './../../../../themes'

/**
 * Warranty description:
 * - Warranty
 */

export class WarrantyBSWMIndonesiaNotCoveredLists extends React.PureComponent {
  render() {
    const {} = this.props

    // props for css classes
    const classes = ClassNames(
      'warranty-bswm-note',
    )

    return (
      <WarrantyNotCoveredListsWrapper
        className={classes}
      >
        <Grid>
          <Grid.Column flex='none'><Section width='17'><p className='warranty-bswm-text is-xs'>a.</p></Section></Grid.Column>
          <Grid.Column flex='1'><p className='warranty-bswm-text is-xs'>Defects attributed to faulty design, method of manufacture, or installation of the product.</p></Grid.Column>
        </Grid>
        <Grid>
          <Grid.Column flex='none'><Section width='17'><p className='warranty-bswm-text is-xs'>b.</p></Section></Grid.Column>
          <Grid.Column flex='1'><p className='warranty-bswm-text is-xs'>Mechanical, chemical, or other damage sustained during transport, handling, storage, erection, or subsequent to erection.</p></Grid.Column>
        </Grid>
        <Grid>
          <Grid.Column flex='none'><Section width='17'><p className='warranty-bswm-text is-xs'>c.</p></Section></Grid.Column>
          <Grid.Column flex='1'><p className='warranty-bswm-text is-xs'>Attack from chemical agents, fumes, liquids, or solids other than direct rainfall.</p></Grid.Column>
        </Grid>
        <Grid>
          <Grid.Column flex='none'><Section width='17'><p className='warranty-bswm-text is-xs'>d.</p></Section></Grid.Column>
          <Grid.Column flex='1'><p className='warranty-bswm-text is-xs'>Unwashed areas sheltered from normal rainfall and not regularly cleaned.</p></Grid.Column>
        </Grid>
        <Grid>
          <Grid.Column flex='none'><Section width='17'><p className='warranty-bswm-text is-xs'>e.</p></Section></Grid.Column>
          <Grid.Column flex='1'><p className='warranty-bswm-text is-xs'>Extended contact with soil, dust, ashes, fertilizers, or moisture retaining substances.</p></Grid.Column>
        </Grid>
        <Grid>
          <Grid.Column flex='none'><Section width='17'><p className='warranty-bswm-text is-xs'>f.</p></Section></Grid.Column>
          <Grid.Column flex='1'><p className='warranty-bswm-text is-xs'>Areas in metallic contact with incompatible materials (eg. lead, copper, carbon/graphite, or stainless steel) or subject to water run-off from such incompatible materials.</p></Grid.Column>
        </Grid>
        <Grid>
          <Grid.Column flex='none'><Section width='17'><p className='warranty-bswm-text is-xs'>g.</p></Section></Grid.Column>
          <Grid.Column flex='1'><p className='warranty-bswm-text is-xs'>Failure to remove debris and/or failure to provide free drainage of water including internal condensation all surfaces of the product.</p></Grid.Column>
        </Grid>
        <Grid>
          <Grid.Column flex='none'><Section width='17'><p className='warranty-bswm-text is-xs'>h.</p></Section></Grid.Column>
          <Grid.Column flex='1'><p className='warranty-bswm-text is-xs'>Failure to replace corroded fasteners.</p></Grid.Column>
        </Grid>
        <Grid>
          <Grid.Column flex='none'><Section width='17'><p className='warranty-bswm-text is-xs'>i.</p></Section></Grid.Column>
          <Grid.Column flex='1'><p className='warranty-bswm-text is-xs'>Deterioration of the panels caused by contact with wet, green or treated timber and wet cement.</p></Grid.Column>
        </Grid>
        <Grid>
          <Grid.Column flex='none'><Section width='17'><p className='warranty-bswm-text is-xs'>j.</p></Section></Grid.Column>
          <Grid.Column flex='1'><p className='warranty-bswm-text is-xs'>Severe marine, industrial or corrosive environments unless specifically agreed and noted.</p></Grid.Column>
        </Grid>
        <Grid>
          <Grid.Column flex='none'><Section width='17'><p className='warranty-bswm-text is-xs'>k.</p></Section></Grid.Column>
          <Grid.Column flex='1'><p className='warranty-bswm-text is-xs'>Storm and tempest or other Acts of God.</p></Grid.Column>
        </Grid>
      </WarrantyNotCoveredListsWrapper>
    )
  }

  static defaultProps = {
    // If you set new default props, you should create propsType for correct styleguide document.
  }

  static propTypes = {
    // TYPE > node, string, number, bool, array, object, symbol, func
  }
}
