import styled, {
  css
} from 'styled-components'
import {
  default as VARIABLES
} from './../../../themes/styles/bases/variables'
// import {
//   default as TYPOGRAPHYS
// } from './../../../themes/styles/bases/typographys'
import {
  default as MIXINS
} from './../../../themes/styles/helpers/mixins'

// Sample using image in style
// url(${require('./images/icons/icon-sample.svg')});
// url(${require('./../../../themes/images/icons/icon-sample.svg')});

// Wrapper
// ============================================================
export const CmsLayoutBaseWrapper = styled.div`
  /* Parent
  ------------------------------- */
  display: flex;
  flex-wrap: wrap;
  height: 100vh;

  ${props => {
    const theme = props.theme
    return css`
      background-color: ${theme.bgColor};
    `
  }}

  /* Childrens
  ------------------------------- */
  /* Modifiers
  ------------------------------- */
  /* Media queries
  ------------------------------- */
`

// Topbar
// ============================================================
export const CmsLayoutTopbarBaseWrapper = styled.div`
  /* Parent
  ------------------------------- */
  position: relative;
  z-index: ${VARIABLES.Z_INDEXS.LV_2};
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  ${props => {
    const theme = props.theme
    return css`
      ${theme.topbarHeight && css`
          height: ${theme.topbarHeight + `px`};
        `
      }

      ${theme.topbarPadding && css`
          padding: ${theme.topbarPadding + `px`};
        `
      }

      ${theme.topbarPaddingTop && css`
          padding-top: ${theme.topbarPaddingTop + `px`};
        `
      }

      ${theme.topbarPaddingRight && css`
          padding-right: ${theme.topbarPaddingRight + `px`};
        `
      }

      ${theme.topbarPaddingBottom && css`
          padding-bottom: ${theme.topbarPaddingBottom + `px`};
        `
      }

      ${theme.topbarPaddingLeft && css`
          padding-left: ${theme.topbarPaddingLeft + `px`};
        `
      }

      ${theme.topbarPaddingHorizontal && css`
          padding-right: ${theme.topbarPaddingHorizontal + `px`};
          padding-left: ${theme.topbarPaddingHorizontal + `px`};
        `
      }

      ${theme.topbarPaddingVertical && css`
          padding-top: ${theme.topbarPaddingVertical + `px`};
          padding-bottom: ${theme.topbarPaddingVertical + `px`};
        `
      }

      background-color: ${theme.topbarBgColor};

      ${theme.topbarBorderBottomWidth && css`
          border-bottom: ${theme.topbarBorderBottomWidth && `${theme.topbarBorderBottomWidth + `px`}`} ${theme.topbarBorderBottomStyle} ${theme.topbarBorderBottomColor};
        `
      }

      box-shadow: ${theme.topbarBoxShadow};
    `
  }}

  /* Childrens
  ------------------------------- */
  /* Modifiers
  ------------------------------- */
  /* Media queries
  ------------------------------- */
`

// Sidebar
// ============================================================
export const CmsLayoutSidebarBaseWrapper = styled.div`
  /* Parent
  ------------------------------- */
  /* ${MIXINS.SCROLLBAR_CUSTOM_STYLE({})} */
  overflow: auto;
  flex: none;
  height: calc(100vh - ${VARIABLES.CMS_LAYOUT.TOPBAR.HEIGHT + `px`});


  ${props => {
    const theme = props.theme
    return css`
      ${theme.sidebarWidth && css`
          width: ${theme.sidebarWidth + `px`};
        `
      }

      ${theme.sidebarPadding && css`
          padding: ${theme.sidebarPadding + `px`};
        `
      }

      ${theme.sidebarPaddingTop && css`
          padding-top: ${theme.sidebarPaddingTop + `px`};
        `
      }

      ${theme.sidebarPaddingRight && css`
          padding-right: ${theme.sidebarPaddingRight + `px`};
        `
      }

      ${theme.sidebarPaddingBottom && css`
          padding-bottom: ${theme.sidebarPaddingBottom + `px`};
        `
      }

      ${theme.sidebarPaddingLeft && css`
          padding-left: ${theme.sidebarPaddingLeft + `px`};
        `
      }

      ${theme.sidebarPaddingHorizontal && css`
          padding-right: ${theme.sidebarPaddingHorizontal + `px`};
          padding-left: ${theme.sidebarPaddingHorizontal + `px`};
        `
      }

      ${theme.sidebarPaddingVertical && css`
          padding-top: ${theme.sidebarPaddingVertical + `px`};
          padding-bottom: ${theme.sidebarPaddingVertical + `px`};
        `
      }

      background-color: ${theme.sidebarBgColor};

      box-shadow: ${theme.sidebarBoxShadow};
    `
  }}

  /* Childrens
  ------------------------------- */
  /* Modifiers
  ------------------------------- */
  /* Media queries
  ------------------------------- */
`

// Content
// ============================================================
export const CmsLayoutContentBaseWrapper = styled.div`
  /* Parent
  ------------------------------- */
  /* ${MIXINS.SCROLLBAR_CUSTOM_STYLE({})} */
  overflow: auto;
  flex: 1;
  height: calc(100vh - ${VARIABLES.CMS_LAYOUT.TOPBAR.HEIGHT + `px`});

  ${props => {
    const theme = props.theme
    return css`
      ${theme.contentPadding && css`
          padding: ${theme.contentPadding + `px`};
        `
      }

      ${theme.contentPaddingTop && css`
          padding-top: ${theme.contentPaddingTop + `px`};
        `
      }

      ${theme.contentPaddingRight && css`
          padding-right: ${theme.contentPaddingRight + `px`};
        `
      }

      ${theme.contentPaddingBottom && css`
          padding-bottom: ${theme.contentPaddingBottom + `px`};
        `
      }

      ${theme.contentPaddingLeft && css`
          padding-left: ${theme.contentPaddingLeft + `px`};
        `
      }

      ${theme.contentPaddingHorizontal && css`
          padding-right: ${theme.contentPaddingHorizontal + `px`};
          padding-left: ${theme.contentPaddingHorizontal + `px`};
        `
      }

      ${theme.contentPaddingVertical && css`
          padding-top: ${theme.contentPaddingVertical + `px`};
          padding-bottom: ${theme.contentPaddingVertical + `px`};
        `
      }

      background-color: ${theme.contentBgColor};
    `
  }}

  /* Childrens
  ------------------------------- */
  /* Modifiers
  ------------------------------- */
  /* Media queries
  ------------------------------- */
`
