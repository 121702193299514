import React from 'react';
import {
  connect,
} from 'react-redux';
import {
  ShapeContainer,
  Section,
  TableAction,
  Grid,
  Text,
  Button,
  PaginationControl,
  Table,
} from './../../components';
import {
  VARIABLES,
  ICONS
} from './../../themes';
import { getTransferHistoryList, getClearTransferHistoryParams } from '../../helpers/services/transfer';
import { ENUM } from '../../helpers/constants/enum';
import { getLanguage } from '../../helpers/functions/language';
import _ from 'lodash';

class ProfileTransferHistoryTableContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activePage: 1,
      dataPerPage: 10,
      customerName: props.customerName,
      customerRoleType: props.customerRoleType, // ENUM.CUSTOMER_ROLE_TYPE.INDONESIA.AD, ENUM.CUSTOMER_ROLE_TYPE.INDONESIA.TOKO, ENUM.CUSTOMER_ROLE_TYPE.INDONESIA.DISTRIBUTOR
      // [TODO : RL-170]
      isIndonesia: {
        toko: false,
        distributor: false,
      },
    }
  }

  componentDidMount() {
    const {
      activePage,
      dataPerPage,
      customerName,
      // customerRoleType,
    } = this.state;

    const params = {
      ...getClearTransferHistoryParams(),
      page: activePage,
      limit: dataPerPage,
      customer_name: customerName,      
    };
    getTransferHistoryList(params);
  }

  historyTransList = {
    onClickSort: (target) => {
      getTransferHistoryList({
        sort: target
      });
    }
  }

  pagination = {
    onPaginationChange: (pageNumber) => {
      getTransferHistoryList({
        page: pageNumber,
      });
    }
  }

  render() {
    const {
      dataList,
      // dataParams,
      totalData,
      activePage,
      // isProfileMapping,
    } = this.props;
    const {
      dataPerPage,
      customerRoleType,
      isIndonesia,
    } = this.state;
    return (
      <ShapeContainer
        ui='contentFilter'
        justify='space-between'
      >
        <Section spacingBottom='45'>
          {
            ((customerRoleType === ENUM.CUSTOMER_ROLE_TYPE.INDONESIA.AD) || (customerRoleType === ENUM.CUSTOMER_ROLE_TYPE.INDONESIA.TOKO) || (customerRoleType === ENUM.CUSTOMER_ROLE_TYPE.INDONESIA.DISTRIBUTOR)) ?
              <Table striped>
                <Table.Section>
                  <Table.Row>
                    <Table.HeadColumn
                      maxWidth={VARIABLES.TABLE.INFO_WIDTHS.DATE}
                      minWidth={VARIABLES.TABLE.INFO_WIDTHS.DATE}
                      sortAscending={true}
                      // onClickSort={}
                    >
                      {getLanguage('container.profileTransferHistoryTable.table.header.date', '')}
                    </Table.HeadColumn>
                    {/* <Table.HeadColumn
                      maxWidth={VARIABLES.TABLE.INFO_WIDTHS.ORDER_NO}
                      minWidth={VARIABLES.TABLE.INFO_WIDTHS.ORDER_NO}
                      sortAscending={true}
                        // onClickSort={}
                    >
                      Order No.
                    </Table.HeadColumn> */}
                    {
                      (customerRoleType === ENUM.CUSTOMER_ROLE_TYPE.INDONESIA.AD) &&
                      <React.Fragment>
                        <Table.HeadColumn
                          maxWidth={VARIABLES.TABLE.INFO_WIDTHS.COIL_ID}
                          minWidth={VARIABLES.TABLE.INFO_WIDTHS.COIL_ID}
                          sortAscending={true}
                          // onClickSort={}
                        >
                          {getLanguage('container.profileTransferHistoryTable.table.header.coilID', '')}
                        </Table.HeadColumn>
                        <Table.HeadColumn
                          minWidth={VARIABLES.TABLE.INFO_WIDTHS.COIL_NAME}
                          sortAscending={true}
                          // onClickSort={}
                        >
                          {getLanguage('container.profileTransferHistoryTable.table.header.coilName', '')}
                        </Table.HeadColumn>
                      </React.Fragment>
                    }
                    <Table.HeadColumn
                      minWidth={VARIABLES.TABLE.INFO_WIDTHS.COMPANY_NAME}
                      sortAscending={true}
                    // onClickSort={}
                    >
                      {getLanguage('container.profileTransferHistoryTable.table.header.companyName', '')}
                    </Table.HeadColumn>
                    <Table.HeadColumn
                      maxWidth={VARIABLES.TABLE.INFO_WIDTHS.PROFILE}
                      minWidth={VARIABLES.TABLE.INFO_WIDTHS.PROFILE}
                      sortAscending={true}
                    // onClickSort={}
                    >
                      {getLanguage('container.profileTransferHistoryTable.table.header.profile', '')}
                    </Table.HeadColumn>
                    {/* <Table.HeadColumn
                      maxWidth={VARIABLES.TABLE.COLUMN_WIDTHS.PHONE}
                      minWidth={VARIABLES.TABLE.COLUMN_WIDTHS.PHONE}
                      sortAscending={true}
                        // onClickSort={}
                    >
                      Phone No.
                    </Table.HeadColumn> */}
                    <Table.HeadColumn
                      alignRight
                      maxWidth={VARIABLES.TABLE.INFO_WIDTHS.PROFILE_QUANTITY_UNIT}
                      minWidth={VARIABLES.TABLE.INFO_WIDTHS.PROFILE_QUANTITY_UNIT}
                      sortAscending={true}
                      // onClickSort={}
                      title={getLanguage('container.profileTransferHistoryTable.table.header.quantity', '')}
                    >
                      {getLanguage('container.profileTransferHistoryTable.table.header.quantityAbbreviation', '')}
                    </Table.HeadColumn>
                    <Table.HeadColumn
                      alignRight
                      maxWidth={VARIABLES.TABLE.INFO_WIDTHS.PROFILE_LENGTH_UNIT}
                      minWidth={VARIABLES.TABLE.INFO_WIDTHS.PROFILE_LENGTH_UNIT}
                      sortAscending={true}
                      // onClickSort={}
                      title={getLanguage('container.profileTransferHistoryTable.table.header.length', '')}
                    >
                      {getLanguage('container.profileTransferHistoryTable.table.header.lengthAbbreviation', '')}
                    </Table.HeadColumn>
                    <Table.HeadColumn
                      alignRight
                      maxWidth={VARIABLES.TABLE.INFO_WIDTHS.TOTAL_LENGTH_M}
                      minWidth={VARIABLES.TABLE.INFO_WIDTHS.TOTAL_LENGTH_M}
                      sortAscending={true}
                    // onClickSort={}
                    >
                      {getLanguage('container.profileTransferHistoryTable.table.header.totalLength', '')}
                    </Table.HeadColumn>
                  </Table.Row>
                </Table.Section>
                <Table.Section>
                  {
                    dataList.map((e, i) => {
                      const profileTypeMapping = {
                        trussc7565: 'Truss C75-65',
                        trussc7575: 'Truss C75-75',
                        rengr3045: 'RengR30-45',
                        rengr3245: 'RengR32-45',
                        rengr3545: 'RengR35-45',
                        longspan025tct: 'Long Span 0.25 TCT',
                        longspan030tct: 'Long Span 0.30 TCT',
                        longspan035tct: 'Long Span 0.35 TCT',
                        metaltile025tct: 'Metal Tile 0.25 TCT',
                        metaltile030tct: 'Metal Tile 0.30 TCT',
                        metaltile035tct: 'Metal Tile 0.35 TCT'
                      }
                      return (
                        <Table.Row
                          key={i}
                        >
                          <Table.BodyColumn
                            info
                            maxWidth={VARIABLES.TABLE.INFO_WIDTHS.DATE}
                            minWidth={VARIABLES.TABLE.INFO_WIDTHS.DATE}
                          >
                            {e.displayCreatedDate}
                          </Table.BodyColumn>
                          {/* <Table.BodyColumn
                            info
                            maxWidth={VARIABLES.TABLE.INFO_WIDTHS.ORDER_NO}
                            minWidth={VARIABLES.TABLE.INFO_WIDTHS.ORDER_NO}
                            title='600182890'
                          >
                            600182890
                          </Table.BodyColumn> */}
                          {
                            (customerRoleType === ENUM.CUSTOMER_ROLE_TYPE.INDONESIA.AD) &&
                            <React.Fragment>
                              <Table.BodyColumn
                                info
                                maxWidth={VARIABLES.TABLE.INFO_WIDTHS.COIL_ID}
                                minWidth={VARIABLES.TABLE.INFO_WIDTHS.COIL_ID}
                                title={e.coilID}
                              >
                                {e.coilID}
                              </Table.BodyColumn>
                              <Table.BodyColumn
                                info
                                minWidth={VARIABLES.TABLE.INFO_WIDTHS.COIL_NAME}
                                title={e.coilName}
                              >
                                {e.coilName}
                              </Table.BodyColumn>
                            </React.Fragment>
                          }
                          <Table.BodyColumn
                            info
                            minWidth={VARIABLES.TABLE.INFO_WIDTHS.COMPANY_NAME}
                            title={e.companyName}
                          >
                            {e.companyName}
                          </Table.BodyColumn>
                          <Table.BodyColumn
                            info
                            maxWidth={VARIABLES.TABLE.INFO_WIDTHS.PROFILE}
                            minWidth={VARIABLES.TABLE.INFO_WIDTHS.PROFILE}
                            title={profileTypeMapping[e.profileType]}
                          >
                            {profileTypeMapping[e.profileType]}
                          </Table.BodyColumn>
                          {/* <Table.BodyColumn
                            phone
                            maxWidth={VARIABLES.TABLE.COLUMN_WIDTHS.PHONE}
                            minWidth={VARIABLES.TABLE.COLUMN_WIDTHS.PHONE}
                            idPhone='62'
                            srcFlag={ICONS['ic-flag-id.jpg']}
                          >
                            899978765
                          </Table.BodyColumn> */}
                          <Table.BodyColumn
                            info
                            alignRight
                            maxWidth={VARIABLES.TABLE.INFO_WIDTHS.PROFILE_QUANTITY_UNIT}
                            minWidth={VARIABLES.TABLE.INFO_WIDTHS.PROFILE_QUANTITY_UNIT}
                            title='100'
                          >
                            {e.profileQty}
                          </Table.BodyColumn>
                          <Table.BodyColumn
                            info
                            alignRight
                            maxWidth={VARIABLES.TABLE.INFO_WIDTHS.PROFILE_LENGTH_UNIT}
                            minWidth={VARIABLES.TABLE.INFO_WIDTHS.PROFILE_LENGTH_UNIT}
                            title='100'
                          >
                            {e.profileLen}
                          </Table.BodyColumn>
                          <Table.BodyColumn
                            info
                            alignRight
                            maxWidth={VARIABLES.TABLE.INFO_WIDTHS.TOTAL_LENGTH_M}
                            minWidth={VARIABLES.TABLE.INFO_WIDTHS.TOTAL_LENGTH_M}
                            title='10,000'
                          >
                            {e.tranAmount}
                          </Table.BodyColumn>
                        </Table.Row>
                      )
                    })
                  }
                </Table.Section>
              </Table>
              :
              <Table striped>
                <Table.Section>
                  <Table.Row>
                    <Table.HeadColumn
                      maxWidth={VARIABLES.TABLE.INFO_WIDTHS.DATE}
                      minWidth={VARIABLES.TABLE.INFO_WIDTHS.DATE}
                      sortAscending={true}
                    // onClickSort={}
                    >
                      {getLanguage('container.profileTransferHistoryTable.table.header.date', '')}
                    </Table.HeadColumn>
                    <Table.HeadColumn
                      maxWidth={VARIABLES.TABLE.INFO_WIDTHS.ORDER_NO}
                      minWidth={VARIABLES.TABLE.INFO_WIDTHS.ORDER_NO}
                      sortAscending={true}
                    // onClickSort={}
                    >
                      {getLanguage('container.profileTransferHistoryTable.table.header.orderNo', '')}
                    </Table.HeadColumn>
                    <Table.HeadColumn
                      minWidth={VARIABLES.TABLE.INFO_WIDTHS.PROFILE}
                      sortAscending={true}
                    // onClickSort={}
                    >
                      {getLanguage('container.profileTransferHistoryTable.table.header.profile', '')}
                    </Table.HeadColumn>
                    <Table.HeadColumn
                      maxWidth={VARIABLES.TABLE.INFO_WIDTHS.COIL_ID_INFO}
                      minWidth={VARIABLES.TABLE.INFO_WIDTHS.COIL_ID_INFO}
                      sortAscending={true}
                    // onClickSort={}
                    >
                      {getLanguage('container.profileTransferHistoryTable.table.header.coilID', '')}
                    </Table.HeadColumn>
                    <Table.HeadColumn
                      maxWidth={VARIABLES.TABLE.INFO_WIDTHS.COMPANY_NAME}
                      minWidth={VARIABLES.TABLE.INFO_WIDTHS.COMPANY_NAME}
                      sortAscending={true}
                    // onClickSort={}
                    >
                      {getLanguage('container.profileTransferHistoryTable.table.header.companyName', '')}
                    </Table.HeadColumn>
                    <Table.HeadColumn
                      maxWidth={VARIABLES.TABLE.COLUMN_WIDTHS.PHONE}
                      minWidth={VARIABLES.TABLE.COLUMN_WIDTHS.PHONE}
                      sortAscending={true}
                    // onClickSort={}
                    >
                      {getLanguage('container.profileTransferHistoryTable.table.header.phoneNo', '')}
                    </Table.HeadColumn>
                    {/* <Table.HeadColumn
                      alignRight
                      maxWidth={VARIABLES.TABLE.INFO_WIDTHS.TRANSFER_AMOUNT_M_INFO}
                      minWidth={VARIABLES.TABLE.INFO_WIDTHS.TRANSFER_AMOUNT_M_INFO}
                      sortAscending={true}
                      // onClickSort={}
                    >
                      {getLanguage('container.profileTransferHistoryTable.table.header.transferAmount', '')}
                    </Table.HeadColumn> */}
                    <Table.HeadColumn
                      alignRight
                      maxWidth={VARIABLES.TABLE.INFO_WIDTHS.LENGTH_M}
                      minWidth={VARIABLES.TABLE.INFO_WIDTHS.LENGTH_M}
                      sortAscending={true}
                    // onClickSort={}
                    >
                      {getLanguage('container.profileTransferHistoryTable.table.header.quantityProfile', '')}
                    </Table.HeadColumn>
                    <Table.HeadColumn
                      alignRight
                      maxWidth={VARIABLES.TABLE.INFO_WIDTHS.LENGTH_M}
                      minWidth={VARIABLES.TABLE.INFO_WIDTHS.LENGTH_M}
                      sortAscending={true}
                    // onClickSort={}
                    >
                      {getLanguage('container.profileTransferHistoryTable.table.header.lengthProfile', '')}
                    </Table.HeadColumn>
                    <Table.HeadColumn
                      alignRight
                      maxWidth={VARIABLES.TABLE.INFO_WIDTHS.WEIGHT_KG}
                      minWidth={VARIABLES.TABLE.INFO_WIDTHS.WEIGHT_KG}
                      sortAscending={true}
                    // onClickSort={}
                    >
                      {getLanguage('container.profileTransferHistoryTable.table.header.totalLength', '')}
                    </Table.HeadColumn>
                  </Table.Row>
                </Table.Section>
                <Table.Section>
                  {
                    dataList.map((e, i) => {
                      return (
                        <Table.Row
                          key={i}
                        >
                          <Table.BodyColumn
                            info
                            maxWidth={VARIABLES.TABLE.INFO_WIDTHS.DATE}
                            minWidth={VARIABLES.TABLE.INFO_WIDTHS.DATE}
                          >
                            {e.displayCreatedDate}
                          </Table.BodyColumn>
                          <Table.BodyColumn
                            info
                            maxWidth={VARIABLES.TABLE.INFO_WIDTHS.ORDER_NO}
                            minWidth={VARIABLES.TABLE.INFO_WIDTHS.ORDER_NO}
                            title={e.orderNo}
                          >
                            {e.orderNo}
                          </Table.BodyColumn>
                          <Table.BodyColumn
                            info
                            minWidth={VARIABLES.TABLE.INFO_WIDTHS.PROFILE}
                            title={e.profileType}
                          >
                            {e.profileType === null ? '-' : e.profileType}
                          </Table.BodyColumn>
                          <Table.BodyColumn
                            info
                            maxWidth={VARIABLES.TABLE.INFO_WIDTHS.COIL_ID_INFO}
                            minWidth={VARIABLES.TABLE.INFO_WIDTHS.COIL_ID_INFO}
                            title={e.coilID}
                          >
                            {e.coilID}
                          </Table.BodyColumn>
                          <Table.BodyColumn
                            info
                            maxWidth={VARIABLES.TABLE.INFO_WIDTHS.COMPANY_NAME}
                            minWidth={VARIABLES.TABLE.INFO_WIDTHS.COMPANY_NAME}
                            title={e.companyName}
                          >
                            {e.companyName}
                          </Table.BodyColumn>
                          <Table.BodyColumn
                            phone
                            maxWidth={VARIABLES.TABLE.COLUMN_WIDTHS.PHONE}
                            minWidth={VARIABLES.TABLE.COLUMN_WIDTHS.PHONE}
                            idPhone={e.phoneCountry.value}
                            srcFlag={e.phoneCountry.flag}
                          >
                            {e.phoneNo}
                          </Table.BodyColumn>
                          <Table.BodyColumn
                            info
                            alignRight
                            maxWidth={VARIABLES.TABLE.INFO_WIDTHS.LENGTH_M}
                            minWidth={VARIABLES.TABLE.INFO_WIDTHS.LENGTH_M}
                            title={e.profileQty}
                          >
                            {e.profileQty == null ? '-' : `${e.profileQty} ${e.profileUnit}`}
                          </Table.BodyColumn>
                          <Table.BodyColumn
                            info
                            alignRight
                            maxWidth={VARIABLES.TABLE.INFO_WIDTHS.LENGTH_M}
                            minWidth={VARIABLES.TABLE.INFO_WIDTHS.LENGTH_M}
                            title={e.profileLen}
                          >
                            {e.profileLen === null ? '-' : e.profileLen}
                          </Table.BodyColumn>
                          <Table.BodyColumn
                            info
                            alignRight
                            maxWidth={VARIABLES.TABLE.INFO_WIDTHS.WEIGHT_KG}
                            minWidth={VARIABLES.TABLE.INFO_WIDTHS.WEIGHT_KG}
                            title={e.tranAmount}
                          >
                            {e.tranAmount}
                          </Table.BodyColumn>
                        </Table.Row>
                      )
                    })
                  }
                </Table.Section>
              </Table>
          }
          {/* [TODO : RL-170] */}
          {(isIndonesia.toko || isIndonesia.distributor) &&
            <Table striped>
              <Table.Section>
                <Table.Row>
                  <Table.HeadColumn
                    maxWidth={VARIABLES.TABLE.INFO_WIDTHS.DATE}
                    minWidth={VARIABLES.TABLE.INFO_WIDTHS.DATE}
                    sortAscending={true}
                    // onClickSort={}
                  >
                    {getLanguage('container.profileTransferHistoryTable.table.header.date', '')}
                  </Table.HeadColumn>
                  <Table.HeadColumn
                    maxWidth={VARIABLES.TABLE.INFO_WIDTHS.ORDER_NO}
                    minWidth={VARIABLES.TABLE.INFO_WIDTHS.ORDER_NO}
                    sortAscending={true}
                    // onClickSort={}
                  >
                    {getLanguage('container.profileTransferHistoryTable.table.header.orderNo', '')}
                  </Table.HeadColumn>
                  <Table.HeadColumn
                    minWidth={VARIABLES.TABLE.INFO_WIDTHS.COMPANY_NAME}
                    sortAscending={true}
                    // onClickSort={}
                  >
                    {getLanguage('container.profileTransferHistoryTable.table.header.companyName', '')}
                  </Table.HeadColumn>
                  <Table.HeadColumn
                    minWidth={VARIABLES.TABLE.INFO_WIDTHS.PROFILE}
                    sortAscending={true}
                    // onClickSort={}
                  >
                    {getLanguage('container.profileTransferHistoryTable.table.header.profile', '')}
                  </Table.HeadColumn>
                  <Table.HeadColumn
                    minWidth={VARIABLES.TABLE.INFO_WIDTHS.CONTACT_NAME}
                    sortAscending={true}
                    // onClickSort={}
                  >
                    {getLanguage('container.profileTransferHistoryTable.table.header.contactName', '')}
                  </Table.HeadColumn>
                  <Table.HeadColumn
                    maxWidth={VARIABLES.TABLE.COLUMN_WIDTHS.PHONE}
                    minWidth={VARIABLES.TABLE.COLUMN_WIDTHS.PHONE}
                    sortAscending={true}
                    // onClickSort={}
                  >
                    {getLanguage('container.profileTransferHistoryTable.table.header.phoneNo', '')}
                  </Table.HeadColumn>
                  <Table.HeadColumn
                    alignRight
                    maxWidth={VARIABLES.TABLE.INFO_WIDTHS.QUANTITY_UNIT}
                    minWidth={VARIABLES.TABLE.INFO_WIDTHS.QUANTITY_UNIT}
                    sortAscending={true}
                    // onClickSort={}
                  >
                    {getLanguage('container.profileTransferHistoryTable.table.header.quantityUnit', '')}
                  </Table.HeadColumn>
                  <Table.HeadColumn
                    alignRight
                    maxWidth={VARIABLES.TABLE.INFO_WIDTHS.LENGTH_UNIT}
                    minWidth={VARIABLES.TABLE.INFO_WIDTHS.LENGTH_UNIT}
                    sortAscending={true}
                    // onClickSort={}
                  >
                    {getLanguage('container.profileTransferHistoryTable.table.header.lengthUnit', '')}
                  </Table.HeadColumn>
                </Table.Row>
              </Table.Section>
              <Table.Section>
                <Table.Row>
                  <Table.BodyColumn
                    info
                    maxWidth={VARIABLES.TABLE.INFO_WIDTHS.DATE}
                    minWidth={VARIABLES.TABLE.INFO_WIDTHS.DATE}
                  >
                    DATE
                  </Table.BodyColumn>
                  <Table.BodyColumn
                    info
                    maxWidth={VARIABLES.TABLE.INFO_WIDTHS.ORDER_NO}
                    minWidth={VARIABLES.TABLE.INFO_WIDTHS.ORDER_NO}
                    title={'ORDER_NO'}
                  >
                    ORDER_NO
                  </Table.BodyColumn>
                  <Table.BodyColumn
                    info
                    minWidth={VARIABLES.TABLE.INFO_WIDTHS.PROFILE}
                    title={'PROFILE'}
                  >
                    PROFILE
                  </Table.BodyColumn>
                  <Table.BodyColumn
                    info
                    minWidth={VARIABLES.TABLE.INFO_WIDTHS.COMPANY_NAME}
                    title={'COMPANY_NAME'}
                  >
                    COMPANY_NAME
                  </Table.BodyColumn>
                  <Table.BodyColumn
                    info
                    minWidth={VARIABLES.TABLE.INFO_WIDTHS.CONTACT_NAME}
                    title={'COMPANY_NAME'}
                  >
                    COMPANY_NAME
                  </Table.BodyColumn>
                  <Table.BodyColumn
                    phone
                    maxWidth={VARIABLES.TABLE.COLUMN_WIDTHS.PHONE}
                    minWidth={VARIABLES.TABLE.COLUMN_WIDTHS.PHONE}
                    idPhone={'62'}
                    srcFlag={ICONS['ic-flag-id.jpg']}
                  >
                    PHONE
                  </Table.BodyColumn>
                  <Table.BodyColumn
                    info
                    alignRight
                    maxWidth={VARIABLES.TABLE.INFO_WIDTHS.QUANTITY_UNIT}
                    minWidth={VARIABLES.TABLE.INFO_WIDTHS.QUANTITY_UNIT}
                  >
                    LENGTH
                  </Table.BodyColumn>
                  <Table.BodyColumn
                    info
                    alignRight
                    maxWidth={VARIABLES.TABLE.INFO_WIDTHS.LENGTH_UNIT}
                  minWidth={VARIABLES.TABLE.INFO_WIDTHS.LENGTH_UNIT}
                  >
                    WEIGHT
                  </Table.BodyColumn>
                </Table.Row>
              </Table.Section>
            </Table>
          }
        </Section>
        <TableAction>
          <TableAction.Left>
            {/* <Grid gutter='15'>
              <Grid.Column>
                <Button
                  blue
                  ui='frontIcon'
                  name='Print'
                  width={VARIABLES.BUTTON.WIDTHS.W_125}
                  iconWidth='18'
                  iconHeight='18'
                  iconSrc={ICONS['ic-printer.svg']}
                  onClick={() => { }}
                />
              </Grid.Column>
              <Grid.Column>
                <Button
                  green
                  ui='frontIcon'
                  name='Export'
                  width={VARIABLES.BUTTON.WIDTHS.W_125}
                  iconWidth='16'
                  iconHeight='16'
                  iconSrc={ICONS['ic-export.svg']}
                  onClick={() => { }}
                />
              </Grid.Column>
            </Grid> */}
          </TableAction.Left>
          <TableAction.Right>
            <Grid gutter='20'>
              <Grid.Column>
                <Section paddingTop='6'>
                  <Text
                    regular12
                    color={VARIABLES.COLORS.GRAY_16}
                  >
                    {getLanguage('pagination.page', '')} {activePage}/{Math.ceil(totalData / dataPerPage)}
                  </Text>
                </Section>
              </Grid.Column>
              <Grid.Column>
                <PaginationControl
                  activePage={activePage}
                  itemsCountPerPage={dataPerPage}
                  totalItemsCount={totalData}
                  pageRangeDisplayed={3}
                  onChange={this.pagination.onPaginationChange}
                />
              </Grid.Column>
            </Grid>
          </TableAction.Right>
        </TableAction>
      </ShapeContainer>
    )
  }
}

const mapStateToProps = (state) => {
  const dataRedux = state.dataRedux;
  return {
    dataList: dataRedux.transferHisList,
    dataParams: dataRedux.transferHisParams,
    activePage: dataRedux.transferHisParams.activePage,
    totalData: dataRedux.transferHisParams.totalData || 0,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
  }
}

export const ProfileTransferHistoryTableContainerConnected = connect(
  mapStateToProps,
  mapDispatchToProps
)(ProfileTransferHistoryTableContainer)