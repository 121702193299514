import apiService from '../apiService'

const apiPath = '/sfdc/product'

export const productController = (configService = {}) => {
  const service = apiService(configService)
  return {
    getProductT1: (params) => {
      return service.get(`${apiPath}/${params.adRefId}`, params)
    },
    getTokoProductT1: (params) => {
      return service.get(`${apiPath}/toko/${params.adRefId}`, params)
    },
    addProductT1: (params) => {
      return service.post(`${apiPath}/create`, params)
    },
    editProductT1: (params) => {
      return service.post(`${apiPath}/edit`, params)
    },
  }
}
