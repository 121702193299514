import { 
  createReducer,
} from 'reduxsauce';
import _ from 'lodash';
import { ROUTE_REDUX_INITIAL_STATE, ROUTE_REDUX_TYPES } from '../actions/routeAction';

/* ------------- Reducers ------------- */

export const setRouteRedux = (state, { key, data }) => {
  state[key] = _.clone(data)
  return _.clone(state)
}

/* ------------- Hookup Reducers To Types ------------- */

export const routeReducer = createReducer(ROUTE_REDUX_INITIAL_STATE, {
  [ROUTE_REDUX_TYPES.SET_ROUTE_REDUX]: setRouteRedux,
})