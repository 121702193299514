import React from 'react'
import ClassNames from 'classnames'
import PropTypes from 'prop-types'
import { osName, } from 'react-device-detect'
import { WarrantyContentWrapper } from './styled'
import { WarrantyBSWMIndonesiaSignature } from './WarrantyBSWMIndonesiaSignature'
import { WarrantyBSWMIndonesiaNote } from './WarrantyBSWMIndonesiaNote'
import { WarrantyBSWMIndonesiaCladdingIssuesLists } from './WarrantyBSWMIndonesiaCladdingIssuesLists'
import { WarrantyBSWMIndonesiaNotCoveredLists } from './WarrantyBSWMIndonesiaNotCoveredLists'
import { WarrantyBSWMIndonesiaReplacement } from './WarrantyBSWMIndonesiaReplacement'
import { WarrantyBSWMIndonesiaProductInformation } from './WarrantyBSWMIndonesiaProductInformation'
import { WarrantyBSWMIndonesiaProductConditions } from './WarrantyBSWMIndonesiaProductConditions'
import { WarrantyBSWMIndonesiaTableCoilId } from './WarrantyBSWMIndonesiaTableCoilId'
import { WarrantyBSWMIndonesiaTableBuilding } from './WarrantyBSWMIndonesiaTableBuilding'
import { WarrantyBSWMIndonesiaAdditionalInformation } from './WarrantyBSWMIndonesiaAdditionalInformation'
import { WarrantyBSWMIndonesiaApplicationDetail } from './WarrantyBSWMIndonesiaApplicationDetail'
import {
  Section,
  Text,
  Image,
  Grid,
  Table,
  ShapeContainer,
} from './../../../../components'
import { VARIABLES, } from './../../../../themes'
import { displayDate } from '../../../../helpers/functions/display'

/**
 * Warranty description:
 * - Warranty
 */

export class WarrantyBSWMIndonesiaZ2bzuBluescopeZacsTruss extends React.PureComponent {
  render() {
    const {
      registeredTrademarkName,
      customerName,
      additionalProjectInformation,
      projectName,
      projectAddress,
      perforateByCorrosionYear,
      peelOrFlakeExcessivelyYear,
      colourFadeExcessivelyYear,
      discolourByDirtStainingYear,
      roofArea,
      thickness,
      width,
      grade,
      roofProfileNameInM2,
      wallProfileNameInM2,
      dataGroupList,
      currentDate,
      roof,
      wall,
      purlinAndGirt,
      trussAndFrame,
      coolRoomPanel,
      decking,
      isRoofAndWall,
      perforateByCorrosionWallYear,
      peelOrFlakeExcessivelyWallYear,
      colourFadeExcessivelyWallYear,
      discolourByDirtStainingWallYear,
      isHideApplicationDetail,
    } = this.props
    let cntDisplayCoil = 0;
    let cntDisplayCustomerRemark = 0;
    let cntPageProduct = 0;
    let cntPageApplication = 0;
    let cntPageLimit = 10;
    let totalRowProductRow = 0;
    let totalApplicationRow = 0;
    dataGroupList.forEach(e => {
      totalRowProductRow += e.displayCoil.length
      totalApplicationRow += e.applicationInfoList.length;
    });
    const totalRow = totalRowProductRow + totalApplicationRow;
    const totalPageProduct = Math.ceil(totalRowProductRow / 10);
    const totalPageApplication = Math.ceil(totalApplicationRow / 10);

    // props for css classes
    const classes = ClassNames(
      'warranty-bswm-content',
    )

    return (
      <React.Fragment>
        {/* Page 1 */}
        <WarrantyContentWrapper
          className={classes}
        >
          <div className='warranty-bswm-container is-spacebetween'>
            <div className='warranty-bswm-section'>
              <Section spacingBottom='15'><p className='warranty-bswm-text is-huge is-bold is-center'>WARRANTY</p></Section>
              <Section spacingBottom='5'>
                <WarrantyBSWMIndonesiaProductInformation
                  registeredTrademarkName={registeredTrademarkName}
                  customerName={customerName}
                  projectName={projectName}
                  projectAddress={projectAddress}
                  isRoofAndWall={isRoofAndWall}
                  // perforateByCorrosion={''}
                  perforateByCorrosionYear={perforateByCorrosionYear}
                  perforateByCorrosionWallYear={perforateByCorrosionWallYear}
                  // peelOrFlakeExcessively={''}
                  peelOrFlakeExcessivelyYear={peelOrFlakeExcessivelyYear}
                  peelOrFlakeExcessivelyWallYear={peelOrFlakeExcessivelyWallYear}
                  // colourFadeExcessively={''}
                  colourFadeExcessivelyYear={colourFadeExcessivelyYear}
                  colourFadeExcessivelyWallYear={colourFadeExcessivelyWallYear}
                  // discolourByDirtStaining={''}
                  discolourByDirtStainingYear={discolourByDirtStainingYear}
                  discolourByDirtStainingWallYear={discolourByDirtStainingWallYear}
                />
              </Section>
              <Section paddingHorizontal='15' spacingBottom='15'>
                <p className='warranty-bswm-text is-xs'>* Terms and conditions apply. Please consult PT NS BlueScope Indonesia representative.</p>
              </Section>
              <Section paddingHorizontal='15'>
                <WarrantyBSWMIndonesiaProductConditions
                  registeredTrademarkName={registeredTrademarkName}
                  customerName={customerName}
                  objectName={'structure and frame'}
                />
                <Section spacingBottom='10'>
                  <Grid gutter='30'>
                    <Grid.Column>
                      <Section width='75'><p className='warranty-bswm-text is-sm'>Comments:</p></Section>
                    </Grid.Column>
                    <Grid.Column>
                      <p className='warranty-bswm-text is-sm is-bold'>No Inspection by PT NS BlueScope Indonesia</p>
                    </Grid.Column>
                  </Grid>
                </Section>
                <Section spacingBottom='10'>
                  <Grid gutter='30'>
                    <Grid.Column>
                      <Section width='75'><p className='warranty-bswm-text is-sm'>Materials Used:</p></Section>
                    </Grid.Column>
                    <Grid.Column>
                      <Section spacingBottom='5'>
                        <p className='warranty-bswm-text is-sm is-bold'>
                          Area of Roof:
                          {/* {roofArea} sq.m */}
                        </p>
                      </Section>
                      <p className='warranty-bswm-text is-sm'>Refer attachment for details</p>
                    </Grid.Column>
                  </Grid>
                </Section>
                {additionalProjectInformation &&
                  <WarrantyBSWMIndonesiaAdditionalInformation additionalProjectInformation={additionalProjectInformation} />
                }
              </Section>
            </div>
            <div className='warranty-bswm-section'>
              <WarrantyBSWMIndonesiaSignature customerName={customerName} date={currentDate} />
            </div>
          </div>
        </WarrantyContentWrapper>
        {/* Page 2 */}
        {Array.from({ length: totalRow > cntPageLimit ? totalPageProduct + totalPageApplication : 1 }).map((page, pageIndex) => {
          cntPageProduct = pageIndex * cntPageLimit;
          cntPageApplication = totalRow > cntPageLimit ? (pageIndex - totalPageProduct) * cntPageLimit : cntPageProduct;

          return (
            <WarrantyContentWrapper key={pageIndex}
              className={classes}
            >
              <div className='warranty-bswm-container is-spacebetween'>
                <div className='warranty-bswm-section'>
                  <Section spacingTop='1' spacingBottom='15'><p className='warranty-bswm-text is-huge is-bold is-center'>WARRANTY</p></Section>
                  <Section spacingBottom='15'>
                    <WarrantyBSWMIndonesiaProductInformation
                      registeredTrademarkName={registeredTrademarkName}
                      customerName={customerName}
                      projectName={projectName}
                      projectAddress={projectAddress}
                      // perforateByCorrosion={''}
                      perforateByCorrosionYear={''}
                      perforateByCorrosionWallYear={''}
                      // peelOrFlakeExcessively={''}
                      peelOrFlakeExcessivelyYear={''}
                      peelOrFlakeExcessivelyWallYear={''}
                      // colourFadeExcessively={''}
                      colourFadeExcessivelyYear={''}
                      colourFadeExcessivelyWallYear={''}
                      // discolourByDirtStaining={''}
                      discolourByDirtStainingYear={''}
                      discolourByDirtStainingWallYear={''}
                    />
                  </Section>
                  <Section paddingHorizontal='15'>
                    {cntDisplayCoil < totalRowProductRow &&
                      <React.Fragment>
                        <Section spacingBottom='10'>
                          <p className='warranty-bswm-text'>Attachment {pageIndex + 1}</p>
                        </Section>
                        <Section width='690' spacingBottom='15'>
                          <WarrantyBSWMIndonesiaTableCoilId>
                            {dataGroupList.map((e, i) => {
                              return (
                                <Table.Section key={i}>
                                  {e.displayCoil.map((ec, ic) => {
                                    if (ic >= cntPageProduct && ic < cntPageProduct + cntPageLimit) {
                                      cntDisplayCoil += 1;
                                      return (
                                        <Table.Row key={ic}>
                                          <Table.Column className='is-body-column' alignCenter maxWidth='50' minWidth='50' paddingVertical='4' paddingHorizontal='4'>
                                            <p className='warranty-bswm-text is-sm is-bold'>{cntDisplayCoil}</p>
                                          </Table.Column>
                                          <Table.Column className='is-body-column' maxWidth='400' minWidth='400' paddingVertical='4' paddingHorizontal='4'>
                                            <p className='warranty-bswm-text is-sm is-bold'>{e.coilNameList[ic]}</p>
                                          </Table.Column>
                                          <Table.Column className='is-body-column' maxWidth='240' minWidth='240' paddingVertical='4' paddingHorizontal='4'>
                                            <p className='warranty-bswm-text is-sm is-bold'>{e.coilIdList[ic]}</p>
                                          </Table.Column>
                                        </Table.Row>
                                      )
                                    }
                                  })}
                                </Table.Section>
                              )
                            })}
                          </WarrantyBSWMIndonesiaTableCoilId>
                        </Section>
                      </React.Fragment>
                    }
                    {isHideApplicationDetail || cntPageApplication < 0 ?
                      undefined
                      :
                      <React.Fragment>
                        <Section spacingBottom='15'>
                          <WarrantyBSWMIndonesiaApplicationDetail
                            roof={roof}
                            wall={wall}
                            purlinAndGirt={purlinAndGirt}
                            trussAndFrame={trussAndFrame}
                            coolRoomPanel={coolRoomPanel}
                            decking={decking}
                          />
                        </Section>
                        <Section width='690' spacingBottom='5'>
                          <WarrantyBSWMIndonesiaTableBuilding>
                            {dataGroupList.map((e, i) => {
                              return (
                                <Table.Section key={i}>
                                  {e.applicationInfoList.map((ec, ic) => {
                                    if (ic >= cntPageApplication && ic < cntPageApplication + cntPageLimit) {
                                      cntDisplayCustomerRemark += 1;
                                      return (
                                        <Table.Row key={ic}>
                                          <Table.Column className='is-body-column' alignCenter maxWidth='50' minWidth='50' paddingVertical='4' paddingHorizontal='4'>
                                            <p className='warranty-bswm-text is-sm is-bold'>{cntDisplayCustomerRemark}</p>
                                          </Table.Column>
                                          <Table.Column className='is-body-column' maxWidth='200' minWidth='200' paddingVertical='4' paddingHorizontal='4'>
                                            <p className='warranty-bswm-text is-sm is-bold'>{ec.building}</p>
                                          </Table.Column>
                                          <Table.Column className='is-body-column' alignCenter maxWidth='200' minWidth='200' paddingVertical='4' paddingHorizontal='4'>
                                            <p className='warranty-bswm-text is-sm is-bold'>{displayDate(ec.projectInsDateTime)}</p>
                                          </Table.Column>
                                          <Table.Column className='is-body-column' maxWidth='240' minWidth='240' paddingVertical='4' paddingHorizontal='4'>
                                            <Section>
                                              {/* <p className='warranty-bswm-text is-sm is-bold'>Roof: {roofProfileNameInM2}</p>
                                            <p className='warranty-bswm-text is-sm is-bold'>Wall: {wallProfileNameInM2}</p> */}
                                              <p className='warranty-bswm-text is-sm is-bold'>{ec.customerRemark}</p>
                                            </Section>
                                          </Table.Column>
                                        </Table.Row>
                                      )
                                    }
                                  })}
                                </Table.Section>
                              )
                            })}
                          </WarrantyBSWMIndonesiaTableBuilding>
                        </Section>
                        <Section spacingBottom='15'>
                          <p className='warranty-bswm-text is-xs'>* BMT - Base Metal Thickness (mm)</p>
                        </Section>
                      </React.Fragment>
                    }
                  </Section>
                </div>
                <div className='warranty-bswm-section'>
                  <Section spacingBottom='20'>
                    <WarrantyBSWMIndonesiaSignature customerName={customerName} date={currentDate} />
                  </Section>
                  <WarrantyBSWMIndonesiaNote customerName={customerName} />
                </div>
              </div>
            </WarrantyContentWrapper>
          )
        })}
        {/* Page 3 */}
        <WarrantyContentWrapper
          className={classes}
        >
          <Section spacingBottom='10'><p className='warranty-bswm-text is-bold is-underline'>TERMS AND CONDITIONS</p></Section>
          <Section spacingBottom='5'><p className='warranty-bswm-text is-xs'>Application of this warranty to construction is conditional upon the domestic dwellings and commercial buildings (“the host dwelling”) incorporating <span className='warranty-bswm-text is-xs is-bold'>{registeredTrademarkName}</span> Framing Componentry, and construction being carried out in accordance with good building practice. Good building practice includes, but <span className='warranty-bswm-text is-xs is-bold'>not limited to</span> :</p></Section>
          <Section spacingBottom='5'>
            <Grid>
              <Grid.Column flex='none'><Section width='17'><p className='warranty-bswm-text is-xs'>1.</p></Section></Grid.Column>
              <Grid.Column flex='1'><p className='warranty-bswm-text is-xs'>Framing Componentry being installed in accordance with manufacturer’s published recommendations current at the time of installation and compliant with the Building Code of Indonesia.</p></Grid.Column>
            </Grid>
          </Section>
          <Section spacingBottom='5'>
            <Grid>
              <Grid.Column flex='none'><Section width='17'><p className='warranty-bswm-text is-xs'>2.</p></Section></Grid.Column>
              <Grid.Column flex='1'><p className='warranty-bswm-text is-xs'>Installation of a dampproof course between bottom plates and concrete slabs were used.</p></Grid.Column>
            </Grid>
          </Section>
          <Section spacingBottom='5'>
            <Grid>
              <Grid.Column flex='none'><Section width='17'><p className='warranty-bswm-text is-xs'>3.</p></Section></Grid.Column>
              <Grid.Column flex='1'><p className='warranty-bswm-text is-xs'>Installation and ongoing maintenance (over the warranty period) of appropriate flashings and other water proofing treatments of any penetrations to eliminate water ingress.</p></Grid.Column>
            </Grid>
          </Section>
          <Section spacingBottom='5'>
            <Grid>
              <Grid.Column flex='none'><Section width='17'><p className='warranty-bswm-text is-xs'>4.</p></Section></Grid.Column>
              <Grid.Column flex='1'><p className='warranty-bswm-text is-xs'>Host dwelling being located more than 300 metres from breaking surf or more than 100 metres from still water.</p></Grid.Column>
            </Grid>
          </Section>
          <Section spacingBottom='5'>
            <Grid>
              <Grid.Column flex='none'><Section width='17'><p className='warranty-bswm-text is-xs'>5.</p></Section></Grid.Column>
              <Grid.Column flex='1'><p className='warranty-bswm-text is-xs'>Installation of fasteners which comply with relevant requirements prescribed in AS 3566 and other applicable standards, and;</p></Grid.Column>
            </Grid>
          </Section>
          <Section spacingBottom='10'>
            <Grid>
              <Grid.Column flex='none'><Section width='17'><p className='warranty-bswm-text is-xs'>6.</p></Section></Grid.Column>
              <Grid.Column flex='1'><p className='warranty-bswm-text is-xs'>No physical contact between “incompatible materials” such as copper pipes or treated timber in contact with Framing Componentry. (Details of all known incompatible materials can be obtained on request from NS BlueScope Indonesia.)</p></Grid.Column>
            </Grid>
          </Section>
          <Section spacingBottom='10'><p className='warranty-bswm-text is-xs'>Within the context of this warranty “enclosed within the building envelope“ is defined as all <span className='warranty-bswm-text is-xs is-bold'>{registeredTrademarkName}</span> Framing Componentry including, roof structure projections that extend beyond the walls of the domestic dwellings and commercial buildings creating uncontrolled voids or openings that expose Framing Componentry to the elements, being covered and entirely enclosed by building cladding materials such as bricks, aerated autoclaved cement or fibre cement sheet, appropriate eaves, porch, carport, porte-cochere linings and roofing cladding products such as <span className='warranty-bswm-text is-xs is-bold'>COLORBOND®</span> or concrete/terra-cotta tiles. Controlled voids are acceptable including wall and gable vents installed to manufacturers specifications. These should include durable flyscreen or similar mesh across the back face of the opening, secured around the perimeter of the vent or device.</p></Section>
          <Section spacingBottom='10'><p className='warranty-bswm-text is-xs is-italic'>Application of this warranty is dependent upon the manufacturer of the frame notifying NS BlueScope Indonesia by completing and submitting a warranty notification form prior to the despatch of the <span className='warranty-bswm-text is-xs is-bold is-normal'>{registeredTrademarkName}</span> frame to site. NS BlueScope Indonesia reserves the right to request access that allows inspection of the frame during the construction.</p></Section>
          <Section spacingBottom='5'><p className='warranty-bswm-text is-xs is-bold'>Building owner obligations include:</p></Section>
          <Section spacingBottom='5'>
            <Grid>
              <Grid.Column flex='none'><Section width='17'><p className='warranty-bswm-text is-xs'>1.</p></Section></Grid.Column>
              <Grid.Column flex='1'><p className='warranty-bswm-text is-xs'>Maintenance of internal linings and external cladding throughout the building and utility areas.</p></Grid.Column>
            </Grid>
          </Section>
          <Section spacingBottom='5'>
            <Grid>
              <Grid.Column flex='none'><Section width='17'><p className='warranty-bswm-text is-xs'>2.</p></Section></Grid.Column>
              <Grid.Column flex='1'><p className='warranty-bswm-text is-xs'>Repair of leaking pipes or overflowing PC items.</p></Grid.Column>
            </Grid>
          </Section>
          <Section spacingBottom='5'>
            <Grid>
              <Grid.Column flex='none'><Section width='17'><p className='warranty-bswm-text is-xs'>3.</p></Section></Grid.Column>
              <Grid.Column flex='1'><p className='warranty-bswm-text is-xs'>Immediate action to rectify conditions caused by wear and tear, weathering or other damage to building elements that can contribute to the degradation of the <span className='warranty-bswm-text is-xs is-bold'>{registeredTrademarkName}</span> frame.</p></Grid.Column>
            </Grid>
          </Section>
          <Section spacingBottom='10'>
            <Grid>
              <Grid.Column flex='none'><Section width='17'><p className='warranty-bswm-text is-xs'>4.</p></Section></Grid.Column>
              <Grid.Column flex='1'><p className='warranty-bswm-text is-xs'>Documentation of incidents that can contribute to degradation of the <span className='warranty-bswm-text is-xs is-bold'>{registeredTrademarkName}</span> frame which have occurred including a description and known duration of the condition.</p></Grid.Column>
            </Grid>
          </Section>
          <Section spacingBottom='5'><p className='warranty-bswm-text is-xs is-bold'>This warranty does not apply:</p></Section>
          <Section spacingBottom='5'>
            <Grid>
              <Grid.Column flex='none'><Section width='17'><p className='warranty-bswm-text is-xs'>1.</p></Section></Grid.Column>
              <Grid.Column flex='1'><p className='warranty-bswm-text is-xs'>To structural flooring systems or any composite wall or roof framing system made up of <span className='warranty-bswm-text is-xs is-bold'>{registeredTrademarkName}</span> and other materials such as concrete, timber or fibre cement sheeting back filled or sprayed with mortar or concrete aggregate.</p></Grid.Column>
            </Grid>
          </Section>
          <Section spacingBottom='10'>
            <Grid>
              <Grid.Column flex='none'><Section width='17'><p className='warranty-bswm-text is-xs'>2.</p></Section></Grid.Column>
              <Grid.Column flex='1'><p className='warranty-bswm-text is-xs'>Where leaking pipes, overflow of PC items or the inadequate maintenance of the internal lining or building envelope have resulted or contributed to degradation of the <span className='warranty-bswm-text is-xs is-bold'>{registeredTrademarkName}</span> framing members</p></Grid.Column>
            </Grid>
          </Section>
          <Section spacingBottom='5'><p className='warranty-bswm-text is-xs is-bold'>Remedies for Breach of Warranty</p></Section>
          <Section spacingBottom='5'><p className='warranty-bswm-text is-xs'>Subject to the above, if any Framing Componentry suffers corrosion leading to structural failure during the warranty period, NS BlueScope Indonesia will, at its option:</p></Section>
          <Section spacingBottom='5'>
            <Grid>
              <Grid.Column flex='none'><Section width='17'><p className='warranty-bswm-text is-xs'>1.</p></Section></Grid.Column>
              <Grid.Column flex='1'><p className='warranty-bswm-text is-xs'>Supply sufficient <span className='warranty-bswm-text is-xs is-bold'>{registeredTrademarkName}</span> framing to replace the failed Framing Componentry; or equivalent material of suitable dimensions and structural adequacy to replace the original installation; or</p></Grid.Column>
            </Grid>
          </Section>
          <Section spacingBottom='10'>
            <Grid>
              <Grid.Column flex='none'><Section width='17'><p className='warranty-bswm-text is-xs'>2.</p></Section></Grid.Column>
              <Grid.Column flex='1'><p className='warranty-bswm-text is-xs'>Reimburse the cost of purchasing replacement <span className='warranty-bswm-text is-xs is-bold'>{registeredTrademarkName}</span> Framing Componentry.</p></Grid.Column>
            </Grid>
          </Section>
          <Section><p className='warranty-bswm-text is-xs'>Subject to any rights implied in favour of any person by any applicable laws of Indonesia or any of its States or Territories which rights by law cannot be excluded, NS BlueScope Indonesia’s liability shall be limited as set out immediately above and, without limitation, shall not include any consequential losses. This warranty does not apply to any other failure of the framing componentry other than that being structural failure due to corrosion.</p></Section>
        </WarrantyContentWrapper>
      </React.Fragment>
    )
  }

  static defaultProps = {
    // If you set new default props, you should create propsType for correct styleguide document.
    registeredTrademarkName: 'BLUESCOPE ZACS TRUSS',
    customerName: '< Customer Name >',
    additionalProjectInformation: '< Additional Project Information >',
    projectName: '< Full Project Name >',
    projectAddress: '< Full Project Address >',
    perforateByCorrosionYear: 'XX',
    roofArea: '< Roof Area >',
    thickness: '< Thickness >',
    width: '< Width >',
    grade: '< Grade >',
    roofProfileNameInM2: '< Profile Name in m2 >',
    wallProfileNameInM2: '< Profile Name in m2 >',
    roof: 'XX',
    wall: 'XX',
    purlinAndGirt: 'XX',
    trussAndFrame: 'XX',
    coolRoomPanel: 'XX',
    decking: 'XX',
  }

  static propTypes = {
    // TYPE > node, string, number, bool, array, object, symbol, func
  }
}
