import React from 'react'
import ClassNames from 'classnames'
// import PropTypes from 'prop-types'
import {
  TableBase
} from './../../base'
import {
  Text,
  Image,
  Grid,
} from './../../../components'
import {
  default as VARIABLES
} from './../../../themes/styles/bases/variables'
// import {
//   default as TYPOGRAPHYS
// } from './../../../themes/styles/bases/typographys'
// import {
//   default as UTILITIES
// } from './../../../themes/styles/helpers/utilities'

/**
 * Table description:
 * - Table
 */

class TableHeadColumn extends React.PureComponent {
  render() {
    const {
      className,
      children,
      maxWidth,
      minWidth,
      sortAscending,
      onClickSort,
      noSort,
      viewMore
    } = this.props

    // props for css classes
    const classes = ClassNames(
      className,
      'table-head-column',
      { 'is-no-sort': noSort },
      { 'is-view-more': viewMore }
    )

    return (
      <Table.Column
        {...this.props}
        className={classes}
        maxWidth={maxWidth}
        minWidth={minWidth}
        paddingHorizontal='5'
        paddingVertical='6'
        borderBottomWidth='1'
        borderBottomStyle='solid'
        borderBottomColor={VARIABLES.COLORS.GRAY_8}
        sortAscending={sortAscending}
        sortButtonAscending={<Image ui='iconArrowAscending' />}
        sortButtonDescending={<Image ui='iconArrowDescending' />}
        onClickSort={onClickSort}
      >
        <Text
          bold12
          singleLine
          color={viewMore ? VARIABLES.COLORS.PRIMARY_2 : VARIABLES.COLORS.GRAY_19}
        >
          {children}
        </Text>
      </Table.Column>
    )
  }

  static defaultProps = {
    // * If you set new default props, you should create propsType for correct styleguide document.
  }

  static propTypes = {
    // TYPE > node, string, number, bool, array, object, symbol, func
    /**
    * props_name_oneType
    */
    // props_name_onetype: PropTypes.string,

    /**
    * props_name_oneMoreType
    */
    // props_name_oneMoreType: PropTypes.oneOfType([
    //   PropTypes.node,
    //   PropTypes.string
    // ])
  }
}

class TableBodyColumn extends React.PureComponent {
  render() {
    const {
      className,
      children,
      maxWidth,
      minWidth,
      info,
      infoActive,
      link,
      phone,
      action,
      button,
      idPhone,
      srcFlag,
      selected,
      result,
      infoStatus,
      infoStatusColor,
    } = this.props

    // props for css classes
    const classes = ClassNames(
      className,
      'table-body-column',
      { 'is-selected': selected },
      { 'is-button': button },
    )

    return (
      <Table.Column
        {...this.props}
        className={classes}
        maxWidth={maxWidth}
        minWidth={minWidth}
        paddingTop='6' // 10
        paddingBottom='6' // 9
        paddingHorizontal='5'
      >
        {info &&
          <Text
            regular12
            ellipsis
            color={infoActive ? VARIABLES.COLORS.PRIMARY_1 : VARIABLES.COLORS.GRAY_19}
            lineheightTh
          >
            {children}
          </Text>
        }
        {infoStatus &&
          <Text
            regular12
            ellipsis
            color={infoStatusColor}
          >
            {children}
          </Text>
        }
        {result &&
          <Text
            bold14
            ellipsis
            color={VARIABLES.COLORS.PRIMARY_2}
          >
            {children}
          </Text>
        }
        {link &&
          <Text
            regular12
            ellipsis
            underline
            color={VARIABLES.COLORS.PRIMARY_1}
          >
            {children}
          </Text>
        }
        {phone &&
          <div className='table-info is-phone'>
            <Grid gutter='10'>
              <Grid.Column>
                <Grid gutter='5'>
                  <Grid.Column>
                    <Image
                      ui='iconFlag'
                      src={srcFlag}
                      top='-1'
                    />
                  </Grid.Column>
                  <Grid.Column>
                    <Text
                      regular12
                      color={VARIABLES.COLORS.GRAY_19}
                    >
                      +{idPhone}
                    </Text>
                  </Grid.Column>
                </Grid>
              </Grid.Column>
              <Grid.Column>
                <Text
                  regular12
                  color={VARIABLES.COLORS.GRAY_19}
                >
                  {children}
                </Text>
              </Grid.Column>
            </Grid>
          </div>
        }
        {action &&
          <React.Fragment>
            {children}
          </React.Fragment>
        }
      </Table.Column>
    )
  }

  static defaultProps = {
    // * If you set new default props, you should create propsType for correct styleguide document.
  }

  static propTypes = {
    // TYPE > node, string, number, bool, array, object, symbol, func
    /**
    * props_name_oneType
    */
    // props_name_onetype: PropTypes.string,

    /**
    * props_name_oneMoreType
    */
    // props_name_oneMoreType: PropTypes.oneOfType([
    //   PropTypes.node,
    //   PropTypes.string
    // ])
  }
}

class TableSectionControl extends React.PureComponent {
  render() {
    const {
      className,
      children,
      gradientBlue,
      blue,
      warrantyCoilList,
      warrantyCoilDetail,
      scroll,
    } = this.props

    // props for css classes
    const classes = ClassNames(
      className,
      'table-section',
      { 'is-gradient-blue': gradientBlue },
      { 'is-blue': blue },
      { 'is-warranty-coil-list': warrantyCoilList },
      { 'is-warranty-coil-detail': warrantyCoilDetail },
      { 'is-scroll': scroll },
    )

    return (
      <Table.Section
        {...this.props}
        className={classes}
      >
        {children}
      </Table.Section>
    )
  }

  static defaultProps = {
    // * If you set new default props, you should create propsType for correct styleguide document.
  }

  static propTypes = {
    // TYPE > node, string, number, bool, array, object, symbol, func
    /**
    * props_name_oneType
    */
    // props_name_onetype: PropTypes.string,

    /**
    * props_name_oneMoreType
    */
    // props_name_oneMoreType: PropTypes.oneOfType([
    //   PropTypes.node,
    //   PropTypes.string
    // ])
  }
}

class TableRowCollapsible extends React.PureComponent {
  render() {
    const {
      className,
      children
    } = this.props

    // props for css classes
    const classes = ClassNames(
      className,
      'table-row-collapsible'
    )

    return (
      <Table.Row
        {...this.props}
        className={classes}
      >
        {children}
      </Table.Row>
    )
  }

  static defaultProps = {
    // * If you set new default props, you should create propsType for correct styleguide document.
  }

  static propTypes = {
    // TYPE > node, string, number, bool, array, object, symbol, func
    /**
    * props_name_oneType
    */
    // props_name_onetype: PropTypes.string,

    /**
    * props_name_oneMoreType
    */
    // props_name_oneMoreType: PropTypes.oneOfType([
    //   PropTypes.node,
    //   PropTypes.string
    // ])
  }
}

export class Table extends React.PureComponent {
  render() {
    const {
      className,
      striped,
      action,
      actionCheckbox,
      actionCheckboxWarranty,
      fieldText,
      listOfRewards,
      productQuantity,
      fluid,
      warranty,
      warrantyMalaysia,
      warrantyVietnam,
      warrantyVietnamOrangeTheme,
      coilTransaction,
      warrantyVietnamComparison,
      warrantyVietnamComparisonOrangeTheme,
      stacked,
    } = this.props

    // props for css classes
    const classes = ClassNames(
      className,
      { 'is-striped': striped },
      { 'is-stacked': stacked },
      { 'is-action': action },
      { 'is-action-checkbox': actionCheckbox },
      { 'is-action-checkbox-warranty': actionCheckboxWarranty },
      { 'is-field-text': fieldText },
      { 'is-list-of-rewards': listOfRewards },
      { 'is-product-quantity': productQuantity },
      { 'is-fluid': fluid },
      { 'is-warranty': warranty },
      { 'is-warranty-malaysia': warrantyMalaysia },
      { 'is-warranty-vietnam': warrantyVietnam },
      { 'is-warranty-vietnam-orange-theme': warrantyVietnamOrangeTheme },
      { 'is-warranty-vietnam-comparison': warrantyVietnamComparison },
      { 'is-warranty-vietnam-comparison-orange-theme': warrantyVietnamComparisonOrangeTheme },
      { 'is-coil-transaction': coilTransaction },
    )

    return (
      <TableBase
        {...this.props}
        className={classes}
      />
    )
  }

  static Section = TableBase.Section
  static Row = TableBase.Row
  static Column = TableBase.Column

  static HeadColumn = TableHeadColumn
  static BodyColumn = TableBodyColumn
  static SectionControl = TableSectionControl
  static RowCollapsible = TableRowCollapsible

  static defaultProps = {
    // * If you set new default props, you should create propsType for correct styleguide document.
  }

  static propTypes = {
    // TYPE > node, string, number, bool, array, object, symbol, func
    /**
    * props_name_oneType
    */
    // props_name_onetype: PropTypes.string,

    /**
    * props_name_oneMoreType
    */
    // props_name_oneMoreType: PropTypes.oneOfType([
    //   PropTypes.node,
    //   PropTypes.string
    // ])
  }
}
