import React from 'react';
import {
  connect,
} from 'react-redux';
import {
  ShapeContainer,
  Section,
  Grid,
  Table,
  PaginationControl,
  TableAction,
  Text,
} from './../../components'
import { VARIABLES } from '../../themes';
import { getCMSRewardCatalogList, getClearCMSRewardParams } from '../../helpers/services/cmsRewardCatalog';
import { bindActionCreators } from 'redux';
import { dataCreators } from '../../actions/dataAction';
import { getSortAscending } from '../../helpers/functions/data';
import { getLanguage } from '../../helpers/functions/language';
import _ from 'lodash';

class RewardStockListContainer extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      dataPerPage: 10,
    }
  }

  componentDidMount() {
    const {
      dataPerPage,
    } = this.state;
    const params = {
      ...getClearCMSRewardParams(),
      page: 1,
      limit: dataPerPage,
    };
    getCMSRewardCatalogList(params);
  }

  rewardStockList = {
    onClickSort: (target) => {
      getCMSRewardCatalogList({
        sort: target
      });
    },
  }

  pagination = {
    onPaginationChange: (pageNumber) => {
      getCMSRewardCatalogList({
        page: pageNumber,
      });
    }
  }

  render() {
    const {
      dataList,
      dataParams,
      totalData,
      activePage,
    } = this.props;
    const {
      dataPerPage,
    } = this.state;
    return (
      <ShapeContainer
        ui='content'
        justify='space-between'
      >
        <Section spacingBottom='60'>
          <Section spacingBottom='30'>
            <Table
              striped
              listOfRewards
            >
              <Table.Section>
                <Table.Row>
                  <Table.HeadColumn
                    maxWidth={VARIABLES.TABLE.INFO_WIDTHS.ID}
                    minWidth={VARIABLES.TABLE.INFO_WIDTHS.ID}
                    sortAscending={getSortAscending(dataParams, 'id')}
                    onClickSort={() => {
                      this.rewardStockList.onClickSort('id desc');
                    }}
                  >
                    {getLanguage('container.rewardStock.table.header.id', '')}
                  </Table.HeadColumn>
                  <Table.HeadColumn
                    minWidth={VARIABLES.TABLE.INFO_WIDTHS.REWARDS}
                    sortAscending={getSortAscending(dataParams, 'name')}
                    onClickSort={() => {
                      this.rewardStockList.onClickSort('name desc');
                    }}
                  >
                    {getLanguage('container.rewardStock.table.header.rewards', '')}
                  </Table.HeadColumn>
                  <Table.HeadColumn
                    alignRight
                    minWidth={VARIABLES.TABLE.INFO_WIDTHS.IN_STOCK}
                    sortAscending={getSortAscending(dataParams, 'in_stock')}
                    onClickSort={() => {
                      // this.rewardStockList.onClickSort('in_stock desc');
                    }}
                  >
                    {getLanguage('container.rewardStock.table.header.inStock', '')}
                  </Table.HeadColumn>
                  <Table.HeadColumn
                    alignRight
                    maxWidth={VARIABLES.TABLE.INFO_WIDTHS.REWARD_POINT}
                    minWidth={VARIABLES.TABLE.INFO_WIDTHS.REWARD_POINT}
                    sortAscending={getSortAscending(dataParams, 'points')}
                    onClickSort={() => {
                      this.rewardStockList.onClickSort('points desc');
                    }}
                  >
                    {getLanguage('container.rewardStock.table.header.rewardPoint', '')}
                  </Table.HeadColumn>
                </Table.Row>
              </Table.Section>
              <Table.Section>
                {
                  dataList.map((e, i) => {
                    return (
                      <Table.Row
                        key={i}
                      >
                        <Table.BodyColumn
                          info
                          maxWidth={VARIABLES.TABLE.INFO_WIDTHS.ID}
                          minWidth={VARIABLES.TABLE.INFO_WIDTHS.ID}
                          title={e.idWithPrefix}
                        >
                          {e.idWithPrefix}
                        </Table.BodyColumn>
                        <Table.BodyColumn
                          info
                          minWidth={VARIABLES.TABLE.INFO_WIDTHS.REWARDS}
                          title={e.name}
                        >
                          {e.name}
                        </Table.BodyColumn>
                        <Table.BodyColumn
                          info
                          alignRight
                          minWidth={VARIABLES.TABLE.INFO_WIDTHS.IN_STOCK}
                          title={e.displayStock}
                        >
                          {e.displayStock}
                        </Table.BodyColumn>
                        <Table.BodyColumn
                          info
                          alignRight
                          maxWidth={VARIABLES.TABLE.INFO_WIDTHS.REWARD_POINT}
                          minWidth={VARIABLES.TABLE.INFO_WIDTHS.REWARD_POINT}
                          title={e.pointsWithComma}
                        >
                          {e.pointsWithComma}
                        </Table.BodyColumn>
                      </Table.Row>
                    )
                  })
                }
              </Table.Section>
            </Table>
          </Section>
        </Section>
        <TableAction>
          <TableAction.Left />
          <TableAction.Right>
            <Grid gutter='20'>
              <Grid.Column>
                <Section paddingTop='6'>
                  <Text
                    regular12
                    color={VARIABLES.COLORS.GRAY_16}
                  >
                    {getLanguage('pagination.page', '')} {activePage}/{Math.ceil(totalData / dataPerPage)}
                  </Text>
                </Section>
              </Grid.Column>
              <Grid.Column>
                <PaginationControl
                  activePage={activePage}
                  itemsCountPerPage={dataPerPage}
                  totalItemsCount={totalData}
                  pageRangeDisplayed={3}
                  onChange={this.pagination.onPaginationChange}
                />
              </Grid.Column>
            </Grid>
          </TableAction.Right>
        </TableAction>
      </ShapeContainer>
    )
  }
}

const mapStateToProps = (state) => {
  const dataRedux = state.dataRedux;
  return {
    dataList: dataRedux.cmsRewardCatalogList,
    dataParams: dataRedux.cmsRewardCatalogParams,
    activePage: dataRedux.cmsRewardCatalogParams.activePage,
    totalData: dataRedux.cmsRewardCatalogParams.totalData || 0,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    dataActions: bindActionCreators(dataCreators, dispatch),
  }
}

export const RewardStockListContainerConnected = connect(
  mapStateToProps,
  mapDispatchToProps
)(RewardStockListContainer)