import styled, {
  css
} from 'styled-components'
// import {
//   default as VARIABLES
// } from './../../../themes/styles/bases/variables'
// import {
//   default as TYPOGRAPHYS
// } from './../../../themes/styles/bases/typographys'
// import {
//   default as MIXINS
// } from './../../../themes/styles/helpers/mixins'

// Sample using image in style
// url(${require('./images/icons/icon-sample.svg')});
// url(${require('./../../../themes/images/icons/icon-sample.svg')});

// Wrapper (Fix width 100% in flex container)
// ============================================================
export const GridBaseWrapper = styled.div`
  /* Parent
  ------------------------------- */
  width: 100%;

  /* Childrens
  ------------------------------- */
  /* Modifiers
  ------------------------------- */
  /* Media queries
  ------------------------------- */
`

// Container
// ============================================================
export const GridContainerBaseWrapper = styled.div`
  /* Parent
  ------------------------------- */
  display: flex;
  flex-wrap: wrap;

  /* Childrens
  ------------------------------- */
  > .grid-column-base {}

  /* Gutter horizontal */
  /* Gutter vertical */
  /* Column align */
  ${props => {
    const theme = props.theme
    return css`
      ${theme.gutter && css`
          margin-left: ${(theme.gutter === 0) ? 0 : (`-` + theme.gutter + theme.gutterUnit)};
        `
      }
      ${theme.gutterVertical && css`
          margin-bottom: ${(theme.gutterVertical === 0) ? 0 : (`-` + theme.gutterVertical + theme.gutterUnit)};
        `
      }
      ${theme.justify && css`
          justify-content: ${theme.justify};
        `
      }
      ${theme.align && css`
          align-items: ${theme.align};
        `
      }

      /* Mobile */
      ${theme.gutterMobile && css`
          @media (max-width: ${theme.breakpointMobile}) {
            margin-left: ${(theme.gutterMobile === 0) ? 0 : (`-` + theme.gutterMobile + theme.gutterUnit)};
          }
        `
      }
      ${theme.gutterVerticalMobile && css`
          @media (max-width: ${theme.breakpointMobile}) {
            margin-bottom: ${(theme.gutterVerticalMobile === 0) ? 0 : (`-` + theme.gutterVerticalMobile + theme.gutterUnit)};
          }
        `
      }
      ${theme.justifyMobile && css`
          @media (max-width: ${theme.breakpointMobile}) {
            justify-content: ${theme.justifyMobile};
          }
        `
      }

      /* Phablet */
      ${theme.gutterPhablet && css`
          @media (min-width: ${theme.breakpointPhablet}) {
            margin-left: ${(theme.gutterPhablet === 0) ? 0 : (`-` + theme.gutterPhablet + theme.gutterUnit)};
          }
        `
      }
      ${theme.gutterVerticalPhablet && css`
          @media (min-width: ${theme.breakpointPhablet}) {
            margin-bottom: ${(theme.gutterVerticalPhablet === 0) ? 0 : (`-` + theme.gutterVerticalPhablet + theme.gutterUnit)};
          }
        `
      }
      ${theme.justifyPhablet && css`
          @media (min-width: ${theme.breakpointPhablet}) {
            justify-content: ${theme.justifyPhablet};
          }
        `
      }

      /* Tablet */
      ${theme.gutterTablet && css`
          @media (min-width: ${theme.breakpointTablet}) {
            margin-left: ${(theme.gutterTablet === 0) ? 0 : (`-` + theme.gutterTablet + theme.gutterUnit)};
          }
        `
      }
      ${theme.gutterVerticalTablet && css`
          @media (min-width: ${theme.breakpointTablet}) {
            margin-bottom: ${(theme.gutterVerticalTablet === 0) ? 0 : (`-` + theme.gutterVerticalTablet + theme.gutterUnit)};
          }
        `
      }
      ${theme.justifyTablet && css`
          @media (min-width: ${theme.breakpointTablet}) {
            justify-content: ${theme.justifyTablet};
          }
        `
      }

      /* Laptop */
      ${theme.gutterLaptop && css`
          @media (min-width: ${theme.breakpointLaptop}) {
            margin-left: ${(theme.gutterLaptop === 0) ? 0 : (`-` + theme.gutterLaptop + theme.gutterUnit)};
          }
        `
      }
      ${theme.gutterVerticalLaptop && css`
          @media (min-width: ${theme.breakpointLaptop}) {
            margin-bottom: ${(theme.gutterVerticalLaptop === 0) ? 0 : (`-` + theme.gutterVerticalLaptop + theme.gutterUnit)};
          }
        `
      }
      ${theme.justifyLaptop && css`
          @media (min-width: ${theme.breakpointLaptop}) {
            justify-content: ${theme.justifyLaptop};
          }
        `
      }

      /* Desktop */
      ${theme.gutterDesktop && css`
          @media (min-width: ${theme.breakpointDesktop}) {
            margin-left: ${(theme.gutterDesktop === 0) ? 0 : (`-` + theme.gutterDesktop + theme.gutterUnit)};
          }
        `
      }
      ${theme.gutterVerticalDesktop && css`
          @media (min-width: ${theme.breakpointDesktop}) {
            margin-bottom: ${(theme.gutterVerticalDesktop === 0) ? 0 : (`-` + theme.gutterVerticalDesktop + theme.gutterUnit)};
          }
        `
      }
      ${theme.justifyDesktop && css`
          @media (min-width: ${theme.breakpointDesktop}) {
            justify-content: ${theme.justifyDesktop};
          }
        `
      }

      /* Large Desktop */
      ${theme.gutterLargeDesktop && css`
          @media (min-width: ${theme.breakpointLargeDesktop}) {
            margin-left: ${(theme.gutterLargeDesktop === 0) ? 0 : (`-` + theme.gutterLargeDesktop + theme.gutterUnit)};
          }
        `
      }
      ${theme.gutterVerticalLargeDesktop && css`
          @media (min-width: ${theme.breakpointLargeDesktop}) {
            margin-bottom: ${(theme.gutterVerticalLargeDesktop === 0) ? 0 : (`-` + theme.gutterVerticalLargeDesktop + theme.gutterUnit)};
          }
        `
      }
      ${theme.justifyLargeDesktop && css`
          @media (min-width: ${theme.breakpointLargeDesktop}) {
            justify-content: ${theme.justifyLargeDesktop};
          }
        `
      }

      > .grid-column-base {
        ${theme.gutter && css`
            padding-left: ${theme.gutter + theme.gutterUnit};
          `
        }
        ${theme.gutterVertical && css`
            padding-bottom: ${theme.gutterVertical + theme.gutterUnit};
          `
        }

        /* Mobile */
        ${theme.gutterMobile && css`
            @media (max-width: ${theme.breakpointMobile}) {
              padding-left: ${theme.gutterMobile  + theme.gutterUnit};
            }
          `
        }
        ${theme.gutterVerticalMobile && css`
            @media (max-width: ${theme.breakpointMobile}) {
              padding-bottom: ${theme.gutterVerticalMobile  + theme.gutterUnit};
            }
          `
        }

        /* Phablet */
        ${theme.gutterPhablet && css`
            @media (min-width: ${theme.breakpointPhablet}) {
              padding-left: ${theme.gutterPhablet  + theme.gutterUnit};
            }
          `
        }
        ${theme.gutterVerticalPhablet && css`
            @media (min-width: ${theme.breakpointPhablet}) {
              padding-bottom: ${theme.gutterVerticalPhablet  + theme.gutterUnit};
            }
          `
        }

        /* Tablet */
        ${theme.gutterTablet && css`
            @media (min-width: ${theme.breakpointTablet}) {
              padding-left: ${theme.gutterTablet  + theme.gutterUnit};
            }
          `
        }
        ${theme.gutterVerticalTablet && css`
            @media (min-width: ${theme.breakpointTablet}) {
              padding-bottom: ${theme.gutterVerticalTablet  + theme.gutterUnit};
            }
          `
        }

        /* Laptop */
        ${theme.gutterLaptop && css`
            @media (min-width: ${theme.breakpointLaptop}) {
              padding-left: ${theme.gutterLaptop  + theme.gutterUnit};
            }
          `
        }
        ${theme.gutterVerticalLaptop && css`
            @media (min-width: ${theme.breakpointLaptop}) {
              padding-bottom: ${theme.gutterVerticalLaptop  + theme.gutterUnit};
            }
          `
        }

        /* Desktop */
        ${theme.gutterDesktop && css`
            @media (min-width: ${theme.breakpointDesktop}) {
              padding-left: ${theme.gutterDesktop  + theme.gutterUnit};
            }
          `
        }
        ${theme.gutterVerticalDesktop && css`
            @media (min-width: ${theme.breakpointDesktop}) {
              padding-bottom: ${theme.gutterVerticalDesktop  + theme.gutterUnit};
            }
          `
        }

        /* Large Desktop */
        ${theme.gutterLargeDesktop && css`
            @media (min-width: ${theme.breakpointLargeDesktop}) {
              padding-left: ${theme.gutterLargeDesktop  + theme.gutterUnit};
            }
          `
        }
        ${theme.gutterVerticalLargeDesktop && css`
            @media (min-width: ${theme.breakpointLargeDesktop}) {
              padding-bottom: ${theme.gutterVerticalLargeDesktop  + theme.gutterUnit};
            }
          `
        }
      }
    `
  }}

  /* Modifiers
  ------------------------------- */
  /* Media queries
  ------------------------------- */
`

// Column
// ============================================================
export const GridColumnBaseWrapper = styled.div`
  transition: all 0.3s ease-in-out;

  /* Flex number (row only because flex-wrap can't effect to flex number) */
  &.is-flex {
    ${props => {
      const theme = props.theme
      return css`
        flex: ${theme.flex};

        @media (max-width: ${theme.breakpointMobile}) {
          /* default props > VARIABLES.BREAKPOINTS.BP_320 */
          flex: ${theme.flexMobile};
        }

        @media (min-width: ${theme.breakpointPhablet}) {
          /* default props > VARIABLES.BREAKPOINTS.BP_414 */
          flex: ${theme.flexPhablet};
        }

        @media (min-width: ${theme.breakpointTablet}) {
          /* default props > VARIABLES.BREAKPOINTS.BP_768 */
          flex: ${theme.flexTablet};
        }

        @media (min-width: ${theme.breakpointLaptop}) {
          /* default props > VARIABLES.BREAKPOINTS.BP_1024 */
          flex: ${theme.flexLaptop};
        }

        @media (min-width: ${theme.breakpointDesktop}) {
          /* default props > VARIABLES.BREAKPOINTS.BP_1366 */
          flex: ${theme.flexDesktop};
        }

        @media (min-width: ${theme.breakpointLargeDesktop}) {
          /* default props > VARIABLES.BREAKPOINTS.BP_1600 */
          flex: ${theme.flexLargeDesktop};
        }
      `
    }}
  }

  /* Grid (percent) */
  ${props => {
    const theme = props.theme
    // let gridSystem = 12
    let gridSystem = theme.gridSystem
    let gridClasses = ''
    let gridMobileClasses = ''
    let gridPhabletClasses = ''
    let gridTabletClasses = ''
    let gridLaptopClasses = ''
    let gridDesktopClasses = ''
    let gridLargeDesktopClasses = ''
    for (let i = 1; i <= gridSystem; i++) {
      let width = (100*i)/gridSystem
      gridClasses += `
        &.is-grid-${i} {
          width: ${width}%;
        }
      `

      gridMobileClasses += `
        &.is-grid-${i}-mobile {
          width: ${width}%;
        }
      `

      gridPhabletClasses += `
        &.is-grid-${i}-phablet {
          width: ${width}%;
        }
      `

      gridTabletClasses += `
        &.is-grid-${i}-tablet {
          width: ${width}%;
        }
      `

      gridLaptopClasses += `
        &.is-grid-${i}-laptop {
          width: ${width}%;
        }
      `

      gridDesktopClasses += `
        &.is-grid-${i}-desktop {
          width: ${width}%;
        }
      `

      gridLargeDesktopClasses += `
        &.is-grid-${i}-large-desktop {
          width: ${width}%;
        }
      `
    }
    return (
      gridClasses +
      `@media (max-width: ${theme.breakpointMobile}) {` + gridMobileClasses + `}` +
      `@media (min-width: ${theme.breakpointPhablet}) {` + gridPhabletClasses + `}` +
      `@media (min-width: ${theme.breakpointTablet}) {` + gridTabletClasses + `}` +
      `@media (min-width: ${theme.breakpointLaptop}) {` + gridLaptopClasses + `}` +
      `@media (min-width: ${theme.breakpointDesktop}) {` + gridDesktopClasses + `}` +
      `@media (min-width: ${theme.breakpointLargeDesktop}) {` + gridLargeDesktopClasses + `}`
    )
  }}

  /* Flex none for stack column */
  &.is-flex,
  &[class*='is-grid'] {
    &.is-stack-mobile {
      ${props => {
        const theme = props.theme
        return css`
          @media (max-width: ${theme.breakpointColFlexMobileStack}) {
            flex: none;
            width: 100%;
          }
        `
      }}
    }

    &.is-stack-phablet {
      ${props => {
        const theme = props.theme
        return css`
          @media (min-width: ${theme.breakpointColFlexPhabletStackMin}) and (max-width: ${theme.breakpointColFlexPhabletStackMax}) {
            flex: none;
            width: 100%;
          }
        `
      }}
    }

    &.is-stack-tablet {
      ${props => {
        const theme = props.theme
        return css`
          @media (min-width: ${theme.breakpointColFlexTabletStackMin}) and (max-width: ${theme.breakpointColFlexTabletStackMax}) {
            flex: none;
            width: 100%;
          }
        `
      }}
    }

    &.is-stack-laptop {
      ${props => {
        const theme = props.theme
        return css`
          @media (min-width: ${theme.breakpointColFlexLaptopStackMin}) and (max-width: ${theme.breakpointColFlexLaptopStackMax}) {
            flex: none;
            width: 100%;
          }
        `
      }}
    }

    &.is-stack-desktop {
      ${props => {
        const theme = props.theme
        return css`
          @media (min-width: ${theme.breakpointColFlexDesktopStackMin}) and (max-width: ${theme.breakpointColFlexDesktopStackMax}) {
            flex: none;
            width: 100%;
          }
        `
      }}
    }
  }
`