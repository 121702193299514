import { TEXT_VERSION } from "../../config/config"
import { getLanguage } from "./language"
import { getLocalStorage, setLocalStorage } from "./localStorage"
import { LOCAL_STORAGE } from "../constants/localStorage"
import { getCurrentTimeStamp } from "./data"
import { MODAL_TYPE } from "../constants/main"
import { onOpenModal } from "./main"

const alertTimeStamp = 1000 * 60 * 5;

export const handleAppVersion = (version = '') => {
  console.log('version check', version);
  console.log('version current', TEXT_VERSION);
  let isUpdateVersion = false;
  let currentVersionList = TEXT_VERSION.split('.');
  let checkVersionList = version.split('.');
  for (let i = 0; i < currentVersionList.length; i++) {
    const currentVersion = parseInt(currentVersionList[i]);
    const checkVersion = parseInt(checkVersionList[i]);
    if (currentVersion < checkVersion) {
      isUpdateVersion = true;
      break;
    }
    else if (currentVersion > checkVersion){
      break;
    }
  }
  if (isUpdateVersion && TEXT_VERSION !== version) {
    handleOpenAlert();
  }
}

export const onOpenAlertVersion = () => {
  onOpenModal({
    type: MODAL_TYPE.CONFIRM,
    title: getLanguage('modal.update.title'),
    message: getLanguage('modal.update.message'),
    buttonCancelName: getLanguage('modal.update.buttonCancel'),
    buttonSubmitName: getLanguage('modal.update.buttonSubmit'),
    isDisableOverlayClick: true,
    onClickConfirm: () => {
      handleAlertTimeout();
      window.location.reload(true);
    },
    onClickCancel: () => {
      handleAlertTimeout();
    }
  })
}

export const handleOpenAlert = () => {
  let prevTimeStamp = getLocalStorage(LOCAL_STORAGE.ALERT_VERSION_TIMEOUT_RBPTRUEBLUE);
  const currentTimeStamp = getCurrentTimeStamp();
  if (!prevTimeStamp) {
    prevTimeStamp = currentTimeStamp - alertTimeStamp;
  }
  const nextTimeStamp = currentTimeStamp - prevTimeStamp;
  if (nextTimeStamp >= alertTimeStamp) {
    onOpenAlertVersion();
  }
  else {
    setTimeout(() => {
      onOpenAlertVersion();
    }, alertTimeStamp - nextTimeStamp);
  }
}

export const handleAlertTimeout = () => {
  const currentTimeStamp = getCurrentTimeStamp();
  setLocalStorage(LOCAL_STORAGE.ALERT_VERSION_TIMEOUT_RBPTRUEBLUE, currentTimeStamp);
  setTimeout(() => {
    onOpenAlertVersion();
  }, alertTimeStamp);
}