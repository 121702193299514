import React from 'react'
import ClassNames from 'classnames'
import PropTypes from 'prop-types'
import {
  SegmentCardWrapper
} from './styled'
import {
  Text,
  Section,
} from './../../../components'
import {
  VARIABLES,
  ICONS,
} from './../../../themes'

/**
 * TemplateBase description:
 * - TemplateBase
 */

export class SegmentCard extends React.PureComponent {
  render () {
    const {
      className,
      isNotSelected,
      isActive,
      isSelected,
      isOpenSubSegmentLists,
      imageSrc,
      name,
      description,
      onClick,
    } = this.props

    // props for css classes
    const classes = ClassNames(
      'segment-card',
      {'is-not-selected': isNotSelected},
      {'is-active': isActive},
      {'is-selected': isSelected},
      {'is-open-subsegment-lists': isOpenSubSegmentLists},
      {'is-name-break-word': name.indexOf(' ') > 0},
      className
    )

    return (
      <SegmentCardWrapper
        className={classes}
        onClick={onClick}
      >
        <div className="segment-card-container">
          <div className='segment-card-image-container'>
            <img
              className='segment-card-image' alt='Cover'
              src={imageSrc}
            />
          </div>
          <Section overflowHidden justify='center' spacingTop='10' spacingBottom={description ? '5' : '0'}>
            <Text className='is-segment-name' bold16 color={VARIABLES.COLORS.BLACK} align='center' title={name}>{name}</Text>
          </Section>
          {description &&
            <Section justify='center'>
              <Text className='is-sub-segment-name' regular12 color={VARIABLES.COLORS.GRAY_19} align='center'>{description}</Text>
            </Section>
          }
        </div>
      </SegmentCardWrapper>
    )
  }

  static defaultProps = {
    // * If you set new default props, you should create propsType for correct styleguide document.
    imageSrc: ICONS['ic-placeholder-segment.svg'],
    name: '-',
  }

  static propTypes = { // TYPE > node, string, number, bool, array, object, symbol, func
    /**
    * Additional classes
    */
    className: PropTypes.string
  }
}
