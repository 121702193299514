import React from 'react'
import ClassNames from 'classnames'
import PropTypes from 'prop-types'
import {
  TableBaseWrapper,
  TableSectionBaseWrapper,
  TableRowBaseWrapper,
  TableColumnBaseWrapper
} from './styled'
// import {
//   default as VARIABLES
// } from './../../../themes/styles/bases/variables'
// import {
//   default as TYPOGRAPHYS
// } from './../../../themes/styles/bases/typographys'

/**
 * TableBase description:
 * - base component Table
 */

class TableSectionBase extends React.PureComponent {
  render() {
    const {
      // Option
      children,
      className,
      // Default
    } = this.props

    return (
      <TableSectionBaseWrapper
        className={ClassNames(
          'table-section-base',
          // Option
          className
        )}
        theme={{
          // Default
        }}
      >
        {children}
      </TableSectionBaseWrapper>
    )
  }

  static defaultProps = {
    // Option
    // children: undefined,
    // className: undefined,
    // Default
  }

  static propTypes = { // TYPE > node, string, number, bool, array, object, symbol, func
    /**
    * Additional classes
    */
    className: PropTypes.string,

    /**
    * Additional Text, HTML, Component
    */
    children: PropTypes.oneOfType([
      PropTypes.node,
      PropTypes.string
    ])
  }
}

class TableRowBase extends React.PureComponent {
  render() {
    const {
      // Option
      children,
      className,
      // Default,
      onClick = () => { },
      pointer,
    } = this.props

    return (
      <TableRowBaseWrapper
        onClick={onClick}
        className={ClassNames(
          'table-row-base',
          // Option
          className,
          { 'is-cursor-pointer': pointer }
        )}
        theme={{
          // Default
        }}
      >
        {children}
      </TableRowBaseWrapper>
    )
  }

  static defaultProps = {
    // Option
    // children: undefined,
    // className: undefined,
    // Default
  }

  static propTypes = { // TYPE > node, string, number, bool, array, object, symbol, func
    /**
    * Additional classes
    */
    className: PropTypes.string,

    /**
    * Additional Text, HTML, Component
    */
    children: PropTypes.oneOfType([
      PropTypes.node,
      PropTypes.string
    ])
  }
}

class TableColumnBase extends React.PureComponent {
  render() {
    const {
      // Option
      children,
      className,
      // Default
      maxWidth,
      minWidth,
      minHeight,
      padding,
      paddingTop,
      paddingRight,
      paddingBottom,
      paddingLeft,
      paddingHorizontal,
      paddingVertical,
      bgColor,
      borderWidth,
      borderStyle,
      borderColor,
      borderBottomWidth,
      borderBottomStyle,
      borderBottomColor,
      borderTopRightRadius,
      borderTopLeftRadius,
      borderBottomRightRadius,
      borderBottomLeftRadius,
      alignCenter,
      alignRight,
      sortButtonDescending,
      sortButtonAscending,
      onClickSort,
      sortAscending,
      sortButtonSpacingRight,
      title,
    } = this.props

    return (
      <TableColumnBaseWrapper
        className={ClassNames(
          'table-column-base',
          { 'is-text-align-center': alignCenter },
          { 'is-text-align-right': alignRight },
          // Option
          className
        )}
        theme={{
          // Default
          maxWidth,
          minWidth,
          minHeight,
          padding,
          paddingTop,
          paddingRight,
          paddingBottom,
          paddingLeft,
          paddingHorizontal,
          paddingVertical,
          bgColor,
          borderWidth,
          borderStyle,
          borderColor,
          borderBottomWidth,
          borderBottomStyle,
          borderBottomColor,
          borderTopRightRadius,
          borderTopLeftRadius,
          borderBottomRightRadius,
          borderBottomLeftRadius,
          sortButtonSpacingRight
        }}
        title={title}
      >
        {children}
        {(sortButtonAscending || sortButtonDescending) &&
          <div className='table-sort-button-base'
            onClick={onClickSort}
          >
            {sortAscending ?
              sortButtonAscending
              :
              sortButtonDescending
            }
          </div>
        }
      </TableColumnBaseWrapper>
    )
  }

  static defaultProps = {
    // Option
    // children: undefined,
    // className: undefined,
    // Default
    sortButtonSpacingRight: '0' // if zero value, you should use string instead number
  }

  static propTypes = { // TYPE > node, string, number, bool, array, object, symbol, func
    /**
    * Additional classes
    */
    className: PropTypes.string,

    /**
    * Additional Text, HTML, Component
    */
    children: PropTypes.oneOfType([
      PropTypes.node,
      PropTypes.string
    ])
  }
}

export class TableBase extends React.PureComponent {
  render() {
    const {
      // Option
      children,
      className,
      overflowVisible,
      // Default
    } = this.props

    return (
      <TableBaseWrapper
        className={ClassNames(
          'table-base',
          // Option
          className
        )}
        theme={{
          // Default
        }}
      >
        {
          overflowVisible ? (
            <div className='table-overflow-visible'>
              {children}
            </div>
          ) : (
            <div className='table-scroll-base'>
              {children}
            </div>
          )
        }

      </TableBaseWrapper>
    )
  }

  static Section = TableSectionBase
  static Row = TableRowBase
  static Column = TableColumnBase

  static defaultProps = {
    // Option
    // children: undefined,
    // className: undefined,
    // Default
  }

  static propTypes = { // TYPE > node, string, number, bool, array, object, symbol, func
    /**
    * Additional classes
    */
    className: PropTypes.string,

    /**
    * Additional Text, HTML, Component
    */
    children: PropTypes.oneOfType([
      PropTypes.node,
      PropTypes.string
    ])
  }
}
