import React from 'react'
import ClassNames from 'classnames'
// import PropTypes from 'prop-types'
import {
  TextBase
} from './../../base'
// import {
// } from './../../../components'
// import {
//   default as VARIABLES
// } from './../../../themes/styles/bases/variables'
// import {
//   default as TYPOGRAPHYS
// } from './../../../themes/styles/bases/typographys'
// import {
//   default as UTILITIES
// } from './../../../themes/styles/helpers/utilities'

/**
 * Text description:
 * - Text
 */

export class Text extends React.PureComponent {
  render() {
    const {
      className,
      regular36,
      regular28,
      regular24,
      regular22,
      regular20,
      regular18,
      regular16,
      regular14,
      regular12,
      regular11,
      regular10,
      regular9,
      regular8,
      regular7,
      medium36,
      medium28,
      medium24,
      medium22,
      medium20,
      medium18,
      medium16,
      medium14,
      medium12,
      medium11,
      medium10,
      medium9,
      medium8,
      medium7,
      bold36,
      bold28,
      bold24,
      bold22,
      bold20,
      bold18,
      bold16,
      bold14,
      bold12,
      bold11,
      bold10,
      bold9,
      bold8,
      bold7,
      underline,
      italic,
      ellipsisOneLine,
      ellipsisTwoLine,
      lineheightTh,
      noWrap,
      superScript,
      required,
      breakWord,
      breakAll,
    } = this.props

    // props for css classes
    const classes = ClassNames(
      className,
      { 'is-regular-36': regular36 },
      { 'is-regular-28': regular28 },
      { 'is-regular-24': regular24 },
      { 'is-regular-22': regular22 },
      { 'is-regular-20': regular20 },
      { 'is-regular-18': regular18 },
      { 'is-regular-16': regular16 },
      { 'is-regular-14': regular14 },
      { 'is-regular-12': regular12 },
      { 'is-regular-11': regular11 },
      { 'is-regular-10': regular10 },
      { 'is-regular-9': regular9 },
      { 'is-regular-8': regular8 },
      { 'is-regular-7': regular7 },
      { 'is-medium-36': medium36 },
      { 'is-medium-28': medium28 },
      { 'is-medium-24': medium24 },
      { 'is-medium-22': medium22 },
      { 'is-medium-20': medium20 },
      { 'is-medium-18': medium18 },
      { 'is-medium-16': medium16 },
      { 'is-medium-14': medium14 },
      { 'is-medium-12': medium12 },
      { 'is-medium-11': medium11 },
      { 'is-medium-10': medium10 },
      { 'is-medium-9': medium9 },
      { 'is-medium-8': medium8 },
      { 'is-medium-7': medium7 },
      { 'is-bold-36': bold36 },
      { 'is-bold-28': bold28 },
      { 'is-bold-24': bold24 },
      { 'is-bold-22': bold22 },
      { 'is-bold-20': bold20 },
      { 'is-bold-18': bold18 },
      { 'is-bold-16': bold16 },
      { 'is-bold-14': bold14 },
      { 'is-bold-12': bold12 },
      { 'is-bold-11': bold11 },
      { 'is-bold-10': bold10 },
      { 'is-bold-9': bold9 },
      { 'is-bold-8': bold8 },
      { 'is-bold-7': bold7 },
      { 'is-underline': underline },
      { 'is-italic': italic },
      { 'is-ellipsis-one-line': ellipsisOneLine },
      { 'is-ellipsis-two-line': ellipsisTwoLine },
      { 'is-lineheight-th': lineheightTh },
      { 'is-nowrap': noWrap },
      { 'is-superscript': superScript },
      { 'is-required': required },
      { 'is-break-word': breakWord },
      { 'is-break-all': breakAll },
    )

    return (
      <TextBase
        {...this.props}
        className={classes}
      />
    )
  }

  static defaultProps = {
    // * If you set new default props, you should create propsType for correct styleguide document.
  }

  static propTypes = {
    // TYPE > node, string, number, bool, array, object, symbol, func
    /**
    * props_name_oneType
    */
    // props_name_onetype: PropTypes.string,

    /**
    * props_name_oneMoreType
    */
    // props_name_oneMoreType: PropTypes.oneOfType([
    //   PropTypes.node,
    //   PropTypes.string
    // ])
  }
}
