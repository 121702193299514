import React from 'react'
import {
  ShapeContainer,
  Section,
  Breadcrumb,
  TitlePage,
  TitleSection,
  Grid,
  Button,
  Image,
  Text,
  Field,
  TableAction,
  Table,
  PaginationControl,
} from './../../components'
import {
  ModalHardwareAndTokoShopProfileContainerConnected,
} from './../../containers/ModalContainer'
import {
  VARIABLES,
  ICONS,
} from './../../themes'
import { setSideMenuActive } from '../../helpers/functions/route';
import { ROUTE_PATH } from '../../helpers/constants/route';
import { redirect } from '../../helpers/functions/route';
import _ from 'lodash';
import { getLanguage } from '../../helpers/functions/language';

export class ProfileMappingScreen extends React.Component {

  constructor(props) {
    super(props);
    setSideMenuActive(props);
    this.state = {
      isOpenModalHardwareAndTokoShopProfile: false,
      Ad: true,
    }
  }

  modal = {
    onOpenModalHardwareAndTokoShopProfile: () => {
      this.setState({
        isOpenModalHardwareAndTokoShopProfile: true,
      });
    },

    onCloseModalHardwareAndTokoShopProfile: () => {
      this.setState({
        isOpenModalHardwareAndTokoShopProfile: false,
      });
    },
  }

  pagination = {
    handlePageChange: (pageNumber) => {
      this.setState({ activePage: pageNumber })
    }
  }

  render() {
    const {
      isOpenModalHardwareAndTokoShopProfile,
      isDisabledButtonSave,
      Ad,
    } = this.state

    return (
      <React.Fragment>
        <Breadcrumb>
          <Breadcrumb.List
            link
            name={getLanguage('profileMapping.breadcrumb1', '')}
            onClick={() => {
              redirect(ROUTE_PATH.HOME.LINK)
            }}
          />
          <Breadcrumb.List name='/' />
          <Breadcrumb.List
            link
            name={getLanguage('profileMapping.breadcrumb2', '')}
          // onClick={() => {
          //   redirect(ROUTE_PATH.INVENTORY_INDEXES.LINK)
          // }}
          />
          <Breadcrumb.List name='/' />
          {Ad ?
            <React.Fragment>
              <Breadcrumb.List name={getLanguage('profileMapping.breadcrumb3', '')} />
            </React.Fragment>
            :
            <React.Fragment>
              <Breadcrumb.List
                link
                name={getLanguage('profileMapping.breadcrumb3', '')}
              />
              <Breadcrumb.List name='/' />
              <Breadcrumb.List name={getLanguage('profileMapping.breadcrumb4', '')} />
            </React.Fragment>
          }

        </Breadcrumb>
        <Section ui='content'>
          <Section spacingBottom='35'>
            <Grid
              gutterVertical='15'
              justify='space-between'
            >
              <Grid.Column>
                <TitlePage
                  src={ICONS['ic-page-inventory.svg']}
                  name={Ad ? getLanguage('profileMapping.title_1', '') : getLanguage('profileMapping.title_2', '')}
                />
              </Grid.Column>
              {/* {!Ad &&
                <Grid.Column>
                  <Grid gutter='35'>
                    <Grid.Column>
                      <Grid gutter='10'>
                        <Grid.Column>
                          <Field
                            ui='blockLabelVerticalSearch'
                            width='285'
                            placeholder={getLanguage('profileMapping.placeholder', '')}
                            // name={}
                            // value={}
                            onChange={() => { }}
                          />
                        </Grid.Column>
                        <Grid.Column>
                          <Button
                            lightBlue
                            name={getLanguage('profileMapping.buttonSearch', '')}
                            width={VARIABLES.BUTTON.WIDTHS.W_105}
                          // onClick={}
                          />
                        </Grid.Column>
                      </Grid>
                    </Grid.Column>
                  </Grid>
                </Grid.Column>
              } */}
            </Grid>
          </Section>
          <ShapeContainer
            ui='content'
            justify={Ad ? 'flex-start' : 'space-between'}
          >
            <Section spacingBottom='45'>
              <TitleSection name={getLanguage('profileMapping.form.shopInfo.title', '')} />
              <Section spacingTop='70'>
                <Section
                  spacingBottom='20'
                  justify='center'
                >
                  <Image ui='iconBlankCustomer' />
                </Section>
                <Section
                  spacingBottom='20'
                  justify='center'
                >
                  <Text
                    regular14
                    color={VARIABLES.COLORS.BLACK}
                  >
                    {getLanguage('profileMapping.form.shopInfo.shopInfoBlank', '')}
                  </Text>
                </Section>
                <Section justify='center'>
                  <Button
                    blue
                    ui='frontIcon'
                    name={getLanguage('profileMapping.form.shopInfo.buttonSelect', '')}
                    iconWidth='20'
                    iconHeight='16'
                    iconSrc={ICONS['ic-select-customer.svg']}
                    onClick={this.modal.onOpenModalHardwareAndTokoShopProfile}
                  />
                </Section>
              </Section>
            </Section>
            {/* {Ad ?
              <Section spacingBottom='45'>
                <TitleSection name={getLanguage('profileMapping.form.shopInfo.title', '')} />
                <Section spacingTop='70'>
                  <Section
                    spacingBottom='20'
                    justify='center'
                  >
                    <Image ui='iconBlankCustomer' />
                  </Section>
                  <Section
                    spacingBottom='20'
                    justify='center'
                  >
                    <Text
                      regular14
                      color={VARIABLES.COLORS.BLACK}
                    >
                      {getLanguage('profileMapping.form.shopInfo.shopInfoBlank', '')}
                    </Text>
                  </Section>
                  <Section justify='center'>
                    <Button
                      blue
                      ui='frontIcon'
                      name={getLanguage('profileMapping.form.shopInfo.buttonSelect', '')}
                      iconWidth='20'
                      iconHeight='16'
                      iconSrc={ICONS['ic-select-customer.svg']}
                      onClick={this.modal.onOpenModalHardwareAndTokoShopProfile}
                    />
                  </Section>
                </Section>
              </Section>
              :
              <React.Fragment>
                <Section spacingBottom='45'>
                  <Table
                    striped
                    action
                  >
                    <Table.Section>
                      <Table.Row>
                        <Table.HeadColumn
                          minWidth={VARIABLES.TABLE.COLUMN_WIDTHS.PROFILE_MAPPING_LIST.TOKO.PROFILE_TYPE}
                          sortAscending={true}
                        // onClickSort={}
                        >
                          {getLanguage('profileMapping.form.shopInfo.table.header.profileType', '')}
                        </Table.HeadColumn>
                        <Table.HeadColumn
                          minWidth={VARIABLES.TABLE.COLUMN_WIDTHS.PROFILE_MAPPING_LIST.TOKO.PRODUCT_GROUP}
                          sortAscending={true}
                        // onClickSort={}
                        >
                          {getLanguage('profileMapping.form.shopInfo.table.header.group', '')}
                        </Table.HeadColumn>
                        <Table.HeadColumn
                          minWidth={VARIABLES.TABLE.COLUMN_WIDTHS.PROFILE_MAPPING_LIST.TOKO.QUANTITY}
                          sortAscending={true}
                        // onClickSort={}
                        >
                          {getLanguage('profileMapping.form.shopInfo.table.header.quantity', '')}
                        </Table.HeadColumn>
                        <Table.HeadColumn
                          minWidth={VARIABLES.TABLE.COLUMN_WIDTHS.PROFILE_MAPPING_LIST.TOKO.LENGTH}
                          sortAscending={true}
                        // onClickSort={}
                        >
                          {getLanguage('profileMapping.form.shopInfo.table.header.length', '')}
                        </Table.HeadColumn>
                        <Table.HeadColumn
                          noSort
                          alignCenter
                          maxWidth={VARIABLES.TABLE.COLUMN_WIDTHS.PROFILE_MAPPING_LIST.TOKO.ACTION}
                          minWidth={VARIABLES.TABLE.COLUMN_WIDTHS.PROFILE_MAPPING_LIST.TOKO.ACTION}
                        >
                          {getLanguage('profileMapping.form.shopInfo.table.header.action', '')}
                        </Table.HeadColumn>
                      </Table.Row>
                    </Table.Section>
                    <Table.Section>
                      <Table.Row>
                        <Table.BodyColumn
                          info
                          minWidth={VARIABLES.TABLE.COLUMN_WIDTHS.PROFILE_MAPPING_LIST.TOKO.PROFILE_TYPE}
                          title='Truss C75-75'
                        >
                          Truss C75-75
                        </Table.BodyColumn>
                        <Table.BodyColumn
                          info
                          minWidth={VARIABLES.TABLE.COLUMN_WIDTHS.PROFILE_MAPPING_LIST.TOKO.PRODUCT_GROUP}
                          title='BlueScope Zacs Truss'
                        >
                          BlueScope Zacs Truss
                        </Table.BodyColumn>
                        <Table.BodyColumn
                          info
                          minWidth={VARIABLES.TABLE.COLUMN_WIDTHS.PROFILE_MAPPING_LIST.TOKO.QUANTITY}
                          title='10 stick'
                        >
                          10 stick
                        </Table.BodyColumn>
                        <Table.BodyColumn
                          info
                          minWidth={VARIABLES.TABLE.COLUMN_WIDTHS.PROFILE_MAPPING_LIST.TOKO.LENGTH}
                          title='6'
                        >
                          6
                        </Table.BodyColumn>
                        <Table.BodyColumn
                          action
                          alignCenter
                          maxWidth={VARIABLES.TABLE.COLUMN_WIDTHS.PROFILE_MAPPING_LIST.TOKO.ACTION}
                          minWidth={VARIABLES.TABLE.COLUMN_WIDTHS.PROFILE_MAPPING_LIST.TOKO.ACTION}
                        >
                          <Button
                            blue
                            ui='frontIconSmall'
                            name='Input Quantity'
                            iconWidth='10'
                            iconHeight='10'
                            iconSrc={ICONS['ic-edit.svg']}
                          // onClick={}
                          />
                        </Table.BodyColumn>
                      </Table.Row>
                      <Table.Row>
                        <Table.BodyColumn
                          info
                          minWidth={VARIABLES.TABLE.COLUMN_WIDTHS.PROFILE_MAPPING_LIST.TOKO.PROFILE_TYPE}
                          title='Long Span 0.25 TCT'
                        >
                          Long Span 0.25 TCT
                        </Table.BodyColumn>
                        <Table.BodyColumn
                          info
                          minWidth={VARIABLES.TABLE.COLUMN_WIDTHS.PROFILE_MAPPING_LIST.TOKO.PRODUCT_GROUP}
                          title='BlueScope Zacs Natural'
                        >
                          BlueScope Zacs Natural
                        </Table.BodyColumn>
                        <Table.BodyColumn
                          info
                          minWidth={VARIABLES.TABLE.COLUMN_WIDTHS.PROFILE_MAPPING_LIST.TOKO.QUANTITY}
                          title='10 sheet'
                        >
                          10 sheet
                        </Table.BodyColumn>
                        <Table.BodyColumn
                          info
                          minWidth={VARIABLES.TABLE.COLUMN_WIDTHS.PROFILE_MAPPING_LIST.TOKO.LENGTH}
                          title='6'
                        >
                          6
                        </Table.BodyColumn>
                        <Table.BodyColumn
                          action
                          alignCenter
                          maxWidth={VARIABLES.TABLE.COLUMN_WIDTHS.PROFILE_MAPPING_LIST.TOKO.ACTION}
                          minWidth={VARIABLES.TABLE.COLUMN_WIDTHS.PROFILE_MAPPING_LIST.TOKO.ACTION}
                        >
                          <Button
                            blue
                            ui='frontIconSmall'
                            name='Input Quantity'
                            iconWidth='10'
                            iconHeight='10'
                            iconSrc={ICONS['ic-edit.svg']}
                          // onClick={}
                          />
                        </Table.BodyColumn>
                      </Table.Row>
                    </Table.Section>
                  </Table>
                </Section>
                <TableAction>
                  <TableAction.Left>
                  </TableAction.Left>
                  <TableAction.Right>
                    <Grid gutter='20'>
                      <Grid.Column>
                        <Section paddingTop='6'>
                          <Text
                            regular12
                            color={VARIABLES.COLORS.GRAY_16}
                          >
                            {getLanguage('pagination.page', '')} 1/3
                          </Text>
                        </Section>
                      </Grid.Column>
                      <Grid.Column>
                        <PaginationControl
                          activePage={activePage}
                          itemsCountPerPage={10}
                          totalItemsCount={100}
                          pageRangeDisplayed={3}
                          onChange={this.pagination.handlePageChange}
                        />
                      </Grid.Column>
                    </Grid>
                  </TableAction.Right>
                </TableAction>
              </React.Fragment>
            } */}
          </ShapeContainer>
        </Section>
        {isOpenModalHardwareAndTokoShopProfile &&
          <ModalHardwareAndTokoShopProfileContainerConnected
            isOpenModal={isOpenModalHardwareAndTokoShopProfile}
            disabledButtonSave={isDisabledButtonSave}
            onClickClose={this.modal.onCloseModalHardwareAndTokoShopProfile}
          />
        }
      </React.Fragment>
    )
  }
}
