import React from 'react';
// import {
//   connect,
// } from 'react-redux';
import {
  Section,
  Grid,
  Modal,
  Text,
  Button,
  Image,
  Field,
  FieldRadioImage,
} from './../../../components'  
import {
  VARIABLES,
  ICONS,
} from './../../../themes'
import { ENUM } from '../../../helpers/constants/enum';
import { getLanguage } from '../../../helpers/functions/language';
// import { MODAL_TYPE } from '../../../helpers/constants/main'
// import { MAIN_REDUX_STATE_KEY } from '../../../actions/mainAction'
import _ from 'lodash';
import { DATA } from '../../../helpers/constants/data';

export class ModalChooseProjectCategoryContainer extends React.Component {
  render() {
    const {
      isOpenModal,
      onClickClose,
      onClickConfirm,
      onChangeCategory,
      category,
      data,
      // isDisableResidential
    } = this.props
    return (
      <Modal
        open={isOpenModal}
        scroll
        // enableOverlayClose={false}
        width={VARIABLES.MODAL.WIDTHS.W_660}
        overlayColor={VARIABLES.COLORS.OVERLAY_BLACK_1}
        spacing={VARIABLES.MODAL.CONTAINER.C_1.SPACING}
        transition={VARIABLES.TRANSITIONS.DEFAULT}
        transformStart={VARIABLES.MODAL.CONTAINER.C_1.TRANSFORM_START}
        transformEnd={VARIABLES.MODAL.CONTAINER.C_1.TRANSFORM_END}
        onClickClose={onClickClose}
      >
        <Modal.Section
          paddingVertical='25'
          paddingHorizontal='35'
          bgColor={VARIABLES.COLORS.WHITE}
          borderBottomWidth='1'
          borderBottomStyle='solid'
          borderBottomColor={VARIABLES.COLORS.GRAY_8}
          borderTopRightRadius={VARIABLES.MODAL.HEADER.H_1.BORDER_TOP_RIGHT_RADIUS}
          borderTopLeftRadius={VARIABLES.MODAL.HEADER.H_1.BORDER_TOP_LEFT_RADIUS}
        >
          <Grid gutter='10'>
            <Grid.Column>
              <Image ui='iconInfo' />
            </Grid.Column>
            <Grid.Column>
              <Text
                regular16
                singleLine
                color={VARIABLES.COLORS.BLACK}
                top='2'
              >
                {getLanguage('modal.warrantyChooseCategory.title', '')}
              </Text>
            </Grid.Column>
          </Grid>
        </Modal.Section>
        <Modal.Section
          paddingVertical='40'
          paddingHorizontal='35'
          bgColor={VARIABLES.COLORS.WHITE}
        >
          <Section
            align='center'
          >
            <Grid
              gutter='25'
              justify='center'
            >
              {data.isCanSelectResidentialCategory &&
                <Grid.Column>
                  <FieldRadioImage name='category'
                    isCategory
                    isChecked={category === DATA.WARRANTY_CATEGORY_TYPE.RESIDENTIAL.TYPE}
                    srcIcon={ICONS['ic-residential.png']}
                    label={
                      <Section>
                        <Text bold18 color={VARIABLES.COLORS.BLACK}>
                          {getLanguage('modal.warrantyChooseCategory.residential', '')}
                        </Text>
                        <Section spacingBottom='2'>
                          <Text regular14 color={VARIABLES.COLORS.BLACK}>
                            {getLanguage('modal.warrantyChooseCategory.residentialExample', '')}
                            <br />
                            &nbsp;
                          </Text>
                        </Section>
                      </Section>
                    }
                    onChange={() => {
                      onChangeCategory(DATA.WARRANTY_CATEGORY_TYPE.RESIDENTIAL.TYPE);
                    }}
                  />
                </Grid.Column>
              }
              {data.isCanSelectNonResidentialCategory &&
                <Grid.Column>
                  <FieldRadioImage name='category'
                    isCategory
                    isChecked={category === DATA.WARRANTY_CATEGORY_TYPE.NON_RESIDENTIAL.TYPE}
                    srcIcon={ICONS['ic-non-residential.png']}
                    label={
                      <Section>
                        <Text bold18 color={VARIABLES.COLORS.BLACK}>
                          {getLanguage('modal.warrantyChooseCategory.nonResidential', '')}
                        </Text>
                        <Section spacingBottom='2'>
                          <Text regular14 color={VARIABLES.COLORS.BLACK}>
                            {getLanguage('modal.warrantyChooseCategory.nonResidentialExample1', '')}
                            <br />
                            {getLanguage('modal.warrantyChooseCategory.nonResidentialExample2', '')}
                          </Text>
                        </Section>
                      </Section>
                    }
                    onChange={() => {
                      onChangeCategory(DATA.WARRANTY_CATEGORY_TYPE.NON_RESIDENTIAL.TYPE);
                    }}
                  />
                </Grid.Column>
              }
            </Grid>
          </Section>
        </Modal.Section>
        <Modal.Section
          paddingTop='15'
          paddingBottom='20'
          paddingHorizontal='35'
          borderTopWidth={VARIABLES.MODAL.FOOTER.F_1.BORDER_TOP_WIDTH}
          borderTopStyle={VARIABLES.MODAL.FOOTER.F_1.BORDER_TOP_STYLE}
          borderTopColor={VARIABLES.COLORS.GRAY_8}
          borderBottomRightRadius={VARIABLES.MODAL.FOOTER.F_1.BORDER_BOTTOM_RIGHT_RADIUS}
          borderBottomLeftRadius={VARIABLES.MODAL.FOOTER.F_1.BORDER_BOTTOM_LEFT_RADIUS}
        >
          <Grid
            gutter='15'
            justify='flex-end'
          >
            <Grid.Column>
              <Button
                gray
                width={VARIABLES.BUTTON.WIDTHS.W_135}
                name={getLanguage('field.buttonCancel', '')}
                onClick={onClickClose}
              />
            </Grid.Column>
            <Grid.Column>
              <Button
                blue
                width={VARIABLES.BUTTON.WIDTHS.W_135}
                name={getLanguage('field.buttonConfirm', '')}
                onClick={onClickConfirm}
              />
            </Grid.Column>
          </Grid>
        </Modal.Section>
      </Modal>
    )
  }
}

// const mapStateToProps = (state) => {
//   const mainRedux = state.mainRedux;
//   const modalData = mainRedux[MAIN_REDUX_STATE_KEY.MODAL_DATA];
//   return {
//     isOpenModal: modalData.openModalType === MODAL_TYPE.CONFIRM_DELETE,
//     message: modalData.message,
//     onClickCancel: modalData.onClickCancel,
//     onClickConfirm: modalData.onClickConfirm,
//   }
// }

// const mapDispatchToProps = (dispatch) => {
//   return {
//   }
// }

// export const ModalInfoContainerConnected = connect(
//   mapStateToProps,
//   mapDispatchToProps
// )(ModalInfoContainer)