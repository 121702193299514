import React from 'react';
import { YearWarrantyContainerConnected } from '../../containers/YearWarrantyContainer';
import { ROUTE_PATH } from '../../helpers/constants/route';
import { getLanguage } from '../../helpers/functions/language';
import { redirect, setSideMenuActive } from '../../helpers/functions/route';
import { getWarrantyYearDetail, getWarrantyYearList } from '../../helpers/services/warranty';
import {
  Breadcrumb
} from './../../components';

export class YearWarrantyScreen extends React.Component {

  constructor(props) {
    super(props);
    setSideMenuActive(props);
    this.state = {};
  }

  render() {
    return (
      <React.Fragment>
        <Breadcrumb>
          <Breadcrumb.List
            link
            name={getLanguage('yearWarranty.breadcrumb1', '')}
            onClick={() => {
              redirect(ROUTE_PATH.HOME.LINK)
            }}
          />
          <Breadcrumb.List name='/' />
          <Breadcrumb.List
            name={getLanguage('yearWarranty.breadcrumb2', '')}
          />
        </Breadcrumb>
        <YearWarrantyContainerConnected
          getWarrantyYearList={getWarrantyYearList}
          getWarrantyYearDetail={getWarrantyYearDetail}
        />
      </React.Fragment>
    )
  }
}