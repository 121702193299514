import React from 'react'
import ClassNames from 'classnames'
import PropTypes from 'prop-types'
import {
  osName,
} from 'react-device-detect'
import {
  WarrantyProductConditionsWrapper
} from './styled'
import {
  Section,
  Text,
  Image,
  Grid,
  Table,
  ShapeContainer,
} from './../../../../components'
import {
  VARIABLES,
} from './../../../../themes'

/**
 * Warranty description:
 * - Warranty
 */

export class WarrantyBSWMThailandProductConditions extends React.PureComponent {
  render() {
    const {
      customerName,
      registeredTrademarkName,
      prefixRegisteredTrademarkName,
      subBrandName,
      objectName,
      objectThName,
      isSheets,
      isSteel,
      isSteelCertify,
      isCladding,
      thLanguage,
      isComments,
      isMaterialLabel,
      materialLabel,
      roofArea,
      isLogoPositionBack,
    } = this.props

    // props for css classes
    const classes = ClassNames(
      'warranty-bswm-note',
    )

    return (
      <WarrantyProductConditionsWrapper
        className={classes}
      >
        {thLanguage ?
          <React.Fragment>
            <Section paddingBottom='10' spacingBottom='10' borderBottomWidth='1' borderBottomStyle='solid' borderBottomColor={VARIABLES.COLORS.BLACK}>
              <p className='warranty-bswm-text is-sm is-word-break-word'>
                หาก{objectThName}ที่ทำจากเหล็ก{prefixRegisteredTrademarkName} <span className='warranty-bswm-text is-sm is-bold'>{registeredTrademarkName}</span> (ซึ่งได้รับการติดตั้งและดูแลรักษาตามคำแนะนำ ข้อกำหนดและเงื่อนไขเฉพาะ<br />ที่แนบท้ายนี้) ไม่เป็นไปตามที่รับประกันเนื่องจากความบกพร่องของวัสดุ ด้วยดุลพินิจแต่เพียงผู้เดียวของบริษัท เอ็นเอส บูลสโคป (ประเทศไทย) จำกัด (“NSBST”) เหล็กเคลือบสีจะได้รับการซ่อมแซม จัดหาให้ใหม่ (แต่ไม่ใช่การติดตั้ง) หรือจะทำการชดเชยโดยพิจารณาตามสัดส่วนของคุณสมบัติ และระยะเวลา<br />รับประกันคงเหลือ โดยบริษัทฯ ไม่มีความรับผิดชอบต่อความสูญเสียหรือเสียหายที่เป็นผลอันเนื่องมาจากความบกพร่องดังกล่าว ทั้งนี้ จะไม่ยอมรับความรับผิด<br />จากการสูญเสีย หรือความเสียหายที่เป็นผลสืบเนื่องใด ๆ ที่มีสาเหตุจากการที่ไม่สามารถใช้งานได้
              </p>
            </Section>
            <Section spacingBottom='10'>
              <p className='warranty-bswm-text is-sm'>
                ขอรับรองว่าผู้แทน หรือตัวแทนของ <span className='warranty-bswm-text is-sm is-bold'>{customerName}</span> ได้ตรวจสอบการติดตั้ง <br /> {objectThName} , และได้รับความเชื่อมั่น(โดยความพยายามอย่างดีที่สุด) แล้วว่า
              </p>
            </Section>
            <Section spacingBottom='10'>
              <p className='warranty-bswm-text is-sm'>- {objectThName} ผลิตขึ้นจากเหล็ก {prefixRegisteredTrademarkName} <span className='warranty-bswm-text is-xs is-bold'>{registeredTrademarkName}{subBrandName && ' ' + subBrandName}</span> คุณภาพสูง (ชื่อตราสินค้าอยู่{isLogoPositionBack ? 'ด้านหลังแผ่น' : 'บนแผ่น'})</p>
              <p className='warranty-bswm-text is-sm'>- {objectThName} ได้รับการติดตั้งอย่างถูกต้องเหมาะสม (หรือมีข้อสังเกตใดๆ ตามที่ระบุไว้ด้านล่าง) และ</p>
              <p className='warranty-bswm-text is-sm'>- สภาพแวดล้อมภายใน หรือโดยรอบ โครงสร้างไม่ปรากฏว่ามีสภาพการกัดกร่อนที่ผิดปกติ หรือรุนแรง</p>
            </Section>
            {isComments &&
              <Section spacingBottom='10'>
                <Grid gutter='30'>
                  <Grid.Column>
                    <Section width='75'><p className='warranty-bswm-text is-sm'>ความเห็น:</p></Section>
                  </Grid.Column>
                  <Grid.Column>
                    <p className='warranty-bswm-text is-sm is-bold'>ไม่มีการตรวจสอบโดย บริษัท เอ็นเอส บลูสโคป (ประเทศไทย) จำกัด</p>
                  </Grid.Column>
                </Grid>
              </Section>
            }
            <Section spacingBottom='10'>
              <Grid gutter='30'>
                <Grid.Column>
                  <Section width='75'><p className='warranty-bswm-text is-sm'>วัสดุที่ใช้:</p></Section>
                </Grid.Column>
                <Grid.Column>
                  {isMaterialLabel &&
                    <p className='warranty-bswm-text is-sm is-bold'>
                      {materialLabel}:
                      {/* {roofArea} ตารางเมตร */}
                    </p>
                  }
                </Grid.Column>
              </Grid>
            </Section>
          </React.Fragment>
          :
          <React.Fragment>
            <Section paddingBottom='10' spacingBottom='10' borderBottomWidth='1' borderBottomStyle='solid' borderBottomColor={VARIABLES.COLORS.BLACK}>
              <p className='warranty-bswm-text is-sm'>
                If the <span className='warranty-bswm-text is-sm is-bold'>{registeredTrademarkName}</span> {isSteel && 'steel'} {isSheets ? 'sheets' : objectName} (installed and maintained in accordance with the specific guidelines, terms and conditions attached) fails to perform as warranted due to a defect in the material, then at the sole discretion of NS BlueScope (Thailand) Limited (“NSBST”) the {isCladding ? 'cladding' : 'air ducting product'} will either be repaired ,resupplied (but not installation),or compensation provided pro-rata in terms of actual performance versus warranted performance, and amount of time remaining in warranty No liability shall be accepted for any consequential loss or damages attributable to the failure.
              </p>
            </Section>
            <Section spacingBottom='10'>
              <p className='warranty-bswm-text is-sm'>
                This is to certify that representatives or agents of <span className='warranty-bswm-text is-sm is-bold'>{customerName}</span> have inspected the installed {objectName} , and ascertained (by best endeavours) that;
              </p>
            </Section>
            <Section spacingBottom='10'>
              <p className='warranty-bswm-text is-sm'>- The {objectName} is manufactured from prime quality <span className='warranty-bswm-text is-xs is-bold'>{registeredTrademarkName}{subBrandName && ' ' + subBrandName}</span> {isSteelCertify && 'steel'} ({isLogoPositionBack ? 'branded on reverse' : 'Invisible brand on top'});-</p>
              <p className='warranty-bswm-text is-sm'>- The {objectName} appears to have been properly installed (or any concerns are noted below) ;- and</p>
              <p className='warranty-bswm-text is-sm'>- The environment in or around the structure does not appear to be abnormally or severely corrosive.</p>
            </Section>
            <Section spacingBottom='10'>
              <Grid gutter='30'>
                <Grid.Column>
                  <Section width='75'><p className='warranty-bswm-text is-sm'>Comments:</p></Section>
                </Grid.Column>
                <Grid.Column>
                  <p className='warranty-bswm-text is-sm is-bold'>No Inspection by NS BlueScope (Thailand) Company Limited</p>
                </Grid.Column>
              </Grid>
            </Section>
            <Section spacingBottom='10'>
              <Grid gutter='30'>
                <Grid.Column>
                  <Section width='75'><p className='warranty-bswm-text is-sm'>Materials Used:</p></Section>
                </Grid.Column>
                <Grid.Column>
                  <p className='warranty-bswm-text is-sm is-bold'>
                    Area of Roof:
                    {/* {roofArea} sq.m */}
                  </p>
                </Grid.Column>
              </Grid>
            </Section>
          </React.Fragment>
        }
      </WarrantyProductConditionsWrapper>
    )
  }

  static defaultProps = {
    // If you set new default props, you should create propsType for correct styleguide document.
    registeredTrademarkName: '< Registered Trademark Name >',
    prefixRegisteredTrademarkName: '< Prefix Registered Trademark Name >',
    customerName: '< Customer Name >',
    objectName: '< Object Name >',
    isComments: true,
    isMaterialLabel: true,
    materialLabel: '< Material Label >',
    roofArea: 'XX',
    isLogoPositionBack: true,
    isCladding: true,
  }

  static propTypes = {
    // TYPE > node, string, number, bool, array, object, symbol, func
  }
}