import _ from 'lodash';
import { DATA, DATE_TIME_FORMAT } from "../helpers/constants/data";
import { LOCAL_STORAGE } from "../helpers/constants/localStorage";
import { WARRANTY } from "../helpers/constants/warranty";
import { isAccountAndCustomerPass, isAccountSegmentPass } from '../helpers/functions/auth';
import { displayDate, displayWithPrefix } from "../helpers/functions/display";
import { getLocalStorage } from "../helpers/functions/localStorage";
import { ModelImage } from "./ModelImage";

export class ModelWarrantyTemplate {
  constructor(props = {}) {
    this.id = props.wrt_temp_id;
    this.idWithPrefix = displayWithPrefix(props.id);
    this.wrtTempName = props.wrt_temp_name;
    this.wrtTempPathResTh = props.wrt_temp_path_res_th;
    this.wrtTempPathResEn = props.wrt_temp_path_res_en;
    this.wrtTempPathNonResTh = props.wrt_temp_path_non_res_th;
    this.wrtTempPathNonResEn = props.wrt_temp_path_non_res_en;
    this.createdDate = props.created_date;
    this.updatedDate = props.updated_date;

    this.m3ProdGrpCode = '';
    this.warrantyPreviewTemplateSpecificCode = undefined;

    WARRANTY.WARRANTY_PREVIEW_TEMPLATE_NAME_SPECIFIC_CODE_LIST.forEach(e => {
      if (e.name === this.wrtTempName) {
        this.warrantyPreviewTemplateSpecificCode = e;
        this.m3ProdGrpCode = e.code;
      }
    });

    this.wrtTempM3ProdGrpCodeList = _.uniqBy((props.wrt_temp_m3_prod_grp || []).map(e => {
      if (this.m3ProdGrpCode.length === 0 && (e.m3_prod_grp_code || '').length) {
        this.m3ProdGrpCode = e.m3_prod_grp_code;
      }
      return {
        m3ProdGrpCode: e.m3_prod_grp_code || '',
        value: e.m3_prod_grp_code || ''
      }
    }), 'value')
    this.wrtTempM3ProdGrpNameList = _.uniqBy((props.wrt_temp_m3_prod_grp || []).map(e => {
      return {
        m3ProdGrpName: e.product_grp_name || '',
        value: e.product_grp_name || '',
      }
    }), 'value')
    this.wrtTempColorList = _.sortBy(_.uniqBy((props.wrt_temp_m3_prod_grp || []).map(e => {
      return {
        color: e.color || '',
        value: e.color || '',
      }
    }).filter(e => e.color), 'color'), ['color']);

    this.displayCreatedDate = this.createdDate ? displayDate(this.createdDate, '/', DATE_TIME_FORMAT) : '-';
    this.displayUpdatedDate = this.updatedDate ? displayDate(this.updatedDate, '/', DATE_TIME_FORMAT) : '-';
  }
}

export class ModelWarrantyTemplateDetail {
  constructor(props = {}) {
    this.id = props.wrt_temp_id;
    this.wrtTempName = props.wrt_temp_name;
    this.wrtTempPathResTh = props.wrt_temp_path_res_th;
    this.wrtTempPathResEn = props.wrt_temp_path_res_en;
    this.wrtTempPathNonResTh = props.wrt_temp_path_non_res_th;
    this.wrtTempPathNonResEn = props.wrt_temp_path_non_res_en;
    this.createdDate = props.created_date;
    this.updatedDate = props.updated_date;
    this.wrtTempM3ProdGrpList = (props.wrt_temp_m3_prod_grp || []).map(e => {
      return {
        m3ProdGrpCode: e.m3_prod_grp_code,
        // m3ProdGrpName: e.m3_prod_grp_name,
        m3ProdGrpName: e.product_grp_name,
        coilDescription: e.coil_description,
      }
    })
    this.wrtTempProdGrpList = (props.wrt_temp_prod_grp || []).map(e => {
      return {
        productGrpName: e.product_grp_name,
      }
    });

    // CR: 1491
    this.wrtWatermarkLogoList = _.orderBy((props.wrt_watermark_logo || []).map(e => {
      const wrtWatermarkLogo = {
        name: e.name || '',
        orderNo: e.order_no,
        imageUrl: e.image_url || '',
        segmentation: e.segmentation,
        accountType: e.account_type,
        customerType: e.customer_type
      }

      wrtWatermarkLogo.imageFile = new ModelImage({ path: wrtWatermarkLogo.imageUrl });
      return wrtWatermarkLogo
    }), ['orderNo'], ['asc'])

    // CR:1491
    // this.wrtWatermarkLogoSelected = this.wrtWatermarkLogoList.filter(e => isAccountSegmentPass(e.segmentation))[0]
    this.wrtWatermarkLogoList = this.wrtWatermarkLogoList.filter(e => isAccountAndCustomerPass(e.accountType, e.customerType))
    this.wrtWatermarkLogoSelected = this.wrtWatermarkLogoList[0]
    this.wrtWatermarkLogoImageFile = (this.wrtWatermarkLogoSelected || {}).imageFile;

    this.nonResDetailList = [];
    this.resDetailList = [];
    this.wrtTempDetail = (props.wrt_temp_detail || []).map(e => {
      const dataDetail = new ModelWarrantyTemplateDetailPage(e);
      if (dataDetail.wrtTempDtCate === parseInt(WARRANTY.WARRANTY_CATEGORY_TYPE.RESIDENTIAL.TYPE)) {
        if (this.resDetailList.length === 0) {
          dataDetail.isSelected = true;
        }
        this.resDetailList.push(dataDetail);
      }
      else {
        if (this.nonResDetailList.length === 0) {
          dataDetail.isSelected = true;
        }
        this.nonResDetailList.push(dataDetail);
      }
      return dataDetail;
    });

    this.m3ProductMappingSelectList = (props.wrt_temp_m3_prod_grp || []).map(e => {
      return new ModelWarrantyTemplateMapping({
        m3_product_group_code: e.m3_prod_grp_code,
        // product_group_name: e.m3_prod_grp_name,
        product_group_name: e.product_grp_name,
        coil_description: e.coil_description,
        top_paint_color: e.color
      })
    });

    const language = getLocalStorage(LOCAL_STORAGE.LANGUAGE_RBPTRUEBLUE);
    const isLanguageEn = language === DATA.LANGUAGE.ENGLISH;
    this.isWallTemplate = false;
    this.nonResDetailList.forEach(e => {
      const wrtTempDtBody = isLanguageEn ? e.wrtTempDtBodyEn : e.wrtTempDtBodyTh;
      if (wrtTempDtBody.match(/\[perforate_by_corrosion_year_wall\]/)) {
        this.isWallTemplate = true;
      }
    })

    // CR: 1491
    this.wrtSegmentDetailList = (props.wrt_segment_detail || []).map(e => {
      const wrtSegmentDetail = {
        wrtTempSegmentDtId: e.wrt_temp_segment_dt_id,
        wrtTempSegmentDtNoteEN: e.wrt_temp_segment_dt_note_en,
        wrtTempSegmentDtNoteTH: e.wrt_temp_segment_dt_note_th,
        wrtTempSegmentDtFooterENUrl: e.wrt_temp_segment_dt_footer_en_url,
        wrtTempSegmentDtFooterTHUrl: e.wrt_temp_segment_dt_footer_th_url,
        wrtTempSegmentDtHeaderENUrl: e.wrt_temp_segment_dt_header_en_url,
        wrtTempSegmentDtHeaderTHUrl: e.wrt_temp_segment_dt_header_th_url,
        segmentMappingList: _.orderBy((e.segment_mapping || []).map(em => {
          const segmentMapping = {
            wrtTempSegmentDtMappingId: em.wrt_temp_segment_dt_mapping_id,
            wrtTempSegmentDtId: em.wrt_temp_segment_dt_id,
            orderNo: em.order_no,
            segmentId: em.segment_id,
            subSegmentId: em.sub_segment_id,
            wrtTempSegmentDtBodyEN: em.wrt_temp_segment_dt_body_en,
            wrtTempSegmentDtBodyTH: em.wrt_temp_segment_dt_body_th,
          }

          segmentMapping.wrtTempDtBodyTh = segmentMapping.wrtTempSegmentDtBodyTH || '';
          segmentMapping.wrtTempDtBodyEn = segmentMapping.wrtTempSegmentDtBodyEN || '';
          return segmentMapping
        }), ['orderNo'], ['asc'])
      }

      wrtSegmentDetail.wrtTempDtNoteTh = wrtSegmentDetail.wrtTempSegmentDtNoteTH || '';
      wrtSegmentDetail.wrtTempDtNoteEn = wrtSegmentDetail.wrtTempSegmentDtNoteEN || '';
      wrtSegmentDetail.wrtTempDtHeaderThUrl = wrtSegmentDetail.wrtTempSegmentDtHeaderTHUrl || '';
      wrtSegmentDetail.wrtTempDtHeaderEnUrl = wrtSegmentDetail.wrtTempSegmentDtHeaderENUrl || '';
      wrtSegmentDetail.wrtTempDtFooterThUrl = wrtSegmentDetail.wrtTempSegmentDtFooterTHUrl || '';
      wrtSegmentDetail.wrtTempDtFooterEnUrl = wrtSegmentDetail.wrtTempSegmentDtFooterENUrl || '';
      wrtSegmentDetail.wrtTempDtHeaderThImage = new ModelImage({ path: wrtSegmentDetail.wrtTempDtHeaderThUrl });
      wrtSegmentDetail.wrtTempDtHeaderEnImage = new ModelImage({ path: wrtSegmentDetail.wrtTempDtHeaderEnUrl });
      wrtSegmentDetail.wrtTempDtFooterThImage = new ModelImage({ path: wrtSegmentDetail.wrtTempDtFooterThUrl });
      wrtSegmentDetail.wrtTempDtFooterEnImage = new ModelImage({ path: wrtSegmentDetail.wrtTempDtFooterEnUrl });

      return wrtSegmentDetail;
    })
    this.wrtSegmentDetailSelectedList = [];

    this.isShowPreviewWrtSegment = false;
    if (
      this.wrtSegmentDetailList.length &&
      this.wrtSegmentDetailList[0].segmentMappingList.length &&
      this.wrtSegmentDetailList[0].segmentMappingList[0].segmentId &&
      this.wrtSegmentDetailList[0].segmentMappingList[0].subSegmentId
    ) {
      this.isShowPreviewWrtSegment = true;
      this.wrtSegmentDetailList[0].wrtTempDtBodyTh = this.wrtSegmentDetailList[0].segmentMappingList[0].wrtTempDtBodyTh;
      this.wrtSegmentDetailList[0].wrtTempDtBodyEn = this.wrtSegmentDetailList[0].segmentMappingList[0].wrtTempDtBodyEn;
    }
  }
}

export class ModelWarrantyTemplateDetailPage {
  constructor(props = {}) {
    this.wrtTempDtId = props.wrt_temp_dt_id;
    this.wrtTempDtPage = props.wrt_temp_dt_page;
    this.wrtTempDtInfo = props.wrt_temp_dt_info || '';
    this.wrtTempDtHeaderThUrl = props.wrt_temp_dt_header_th_url || '';
    this.wrtTempDtHeaderEnUrl = props.wrt_temp_dt_header_en_url || '';
    this.wrtTempDtBodyTh = props.wrt_temp_dt_body_th || '';
    this.wrtTempDtBodyEn = props.wrt_temp_dt_body_en || '';
    this.wrtTempDtBodyLeftTh = props.wrt_temp_dt_body_left_th || '';
    this.wrtTempDtBodyLeftEn = props.wrt_temp_dt_body_left_en || '';
    this.wrtTempDtBodyRigthTh = props.wrt_temp_dt_body_rigth_th || '';
    this.wrtTempDtBodyRigthEn = props.wrt_temp_dt_body_rigth_en || '';
    this.wrtTempDtFooterThUrl = props.wrt_temp_dt_footer_th_url || '';
    this.wrtTempDtFooterEnUrl = props.wrt_temp_dt_footer_en_url || '';
    this.createdBy = props.created_by;
    this.createdDate = props.created_date;
    this.updatedBy = props.updated_by;
    this.updatedDate = props.updated_date;
    this.wrtTempDtCate = props.wrt_temp_dt_cate;
    this.wrtTempDtNoteTh = props.wrt_temp_dt_note_th || '';
    this.wrtTempDtNoteEn = props.wrt_temp_dt_note_en || '';

    this.wrtTempDtHeaderThImage = new ModelImage({ path: this.wrtTempDtHeaderThUrl || '' });
    this.wrtTempDtHeaderEnImage = new ModelImage({ path: this.wrtTempDtHeaderEnUrl || '' });
    this.wrtTempDtFooterThImage = new ModelImage({ path: this.wrtTempDtFooterThUrl || '' });
    this.wrtTempDtFooterEnImage = new ModelImage({ path: this.wrtTempDtFooterEnUrl || '' });
    this.wrtTempDtHeaderThUrlOld = props.wrt_temp_dt_header_th_url || '';
    this.wrtTempDtHeaderEnUrlOld = props.wrt_temp_dt_header_en_url || '';
    this.wrtTempDtFooterThUrlOld = props.wrt_temp_dt_footer_th_url || '';
    this.wrtTempDtFooterEnUrlOld = props.wrt_temp_dt_footer_en_url || '';
  }
}

export class ModelWarrantyTemplateProductGroup {
  constructor(props = {}) {
    this.productGroupCode = props.product_group_code;
    this.productGroupName = props.product_group_name;
    this.value = this.productGroupCode;
    this.label = this.productGroupName;
  }
}

// export class ModelWarrantyTemplateM3ProductGroup {
//   constructor(props = {}) {
//     this.m3ProductGroupCode = props.m3_product_group_code;
//     this.m3ProductGroupName = props.m3_product_group_name;
//   }
// }

export class ModelWarrantyTemplateM3ProductCodeGroup {
  constructor(props = {}) {
    this.m3ProductGroupCode = props.m3_product_group_code;
    this.m3ProductGroupName = props.m3_product_group_name;
    this.value = this.m3ProductGroupCode;
    this.label = this.m3ProductGroupCode;
  }
}

export class ModelWarrantyTemplateMapping {
  constructor(props = {}) {
    this.id = `${props.m3_product_group_code}_${props.product_group_name}_${props.top_paint_color}`;
    this.m3ProductGroupCode = props.m3_product_group_code;
    this.productGroupName = props.product_group_name;
    // this.coilDescription = props.coil_description;
    this.topPaintColor = props.top_paint_color;
    this.isChecked = false;
  }
}

// export class ModelWarrantyTemplateMappingProductGroup {
//   constructor(props = {}) {
//     this.productGroupName = props.product_group_name;
//     this.value = this.productGroupName;
//     this.label = this.productGroupName;
//   }
// }

// export class ModelWarrantyTemplateMappingM3ProductGroup {
//   constructor(props = {}) {
//     this.m3ProductGroupCode = props.m3_product_group_code;
//     this.value = this.m3ProductGroupCode;
//     this.label = this.m3ProductGroupCode;
//   }
// }

// export class ModelWarrantyTemplateMappingColor {
//   constructor(props = {}) {
//     this.color = props.color;
//     this.value = this.color;
//     this.label = this.color;
//   }
// }