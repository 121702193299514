import React from 'react'
import {
  ShapeContainer,
  Section,
  Breadcrumb,
  TitlePage,
  TableAction,
  Grid,
  Text,
  Button,
  PaginationControl,
  SelectControl,
  Field,
  DatePickerControl,
  Table,
  Image
} from './../../components'
import {
  VARIABLES,
  ICONS
} from './../../themes'
import { setSideMenuActive } from '../../helpers/functions/route';
import { ROUTE_PATH } from '../../helpers/constants/route';
import { redirect } from '../../helpers/functions/route';
import { CustomerListContainerConnected } from '../../containers/CustomerListContainer/CustomerListContainer';
import { getCustomerList, getClearCustomerParams } from '../../helpers/services/customer';
import _ from 'lodash';
import { getLanguage } from '../../helpers/functions/language';

export class PointsDetailScreen extends React.Component {

  constructor(props) {
    super(props);
    setSideMenuActive(props);
    this.state = {
      searchValue: '',
    }
    console.log('PointsDetailScreen')
  }

  input = {
    onChangeInput: (ev) => {
      const name = ev.target.name;
      const value = ev.target.value;
      this.setState({
        [name]: value,
      })
    },
    onClickSearch: () => {
      const {
        searchValue,
      } = this.state;
      let params = {
        ...getClearCustomerParams(),
        page: 1,
        all_field: searchValue,
      };
      getCustomerList(params);
    },
  }

  render() {
    const {
      searchValue,
    } = this.state

    return (
      <React.Fragment>
        <Breadcrumb>
          <Breadcrumb.List
            link
            name={getLanguage('pointDetail.breadcrumb1', '')}
            onClick={() => {
              redirect(ROUTE_PATH.HOME.LINK)
            }}
          />
          <Breadcrumb.List name='/' />
          <Breadcrumb.List
            link
            name={getLanguage('pointDetail.breadcrumb2', '')}
          // onClick={() => {
          //   redirect(ROUTE_PATH.REWARDS.LINK)
          // }}
          />
          <Breadcrumb.List name='/' />
          <Breadcrumb.List name={getLanguage('pointDetail.breadcrumb3', '')} />
        </Breadcrumb>
        <Section ui='content'>
          <Section spacingBottom='35'>
            <Grid
              gutterVertical='15'
              justify='space-between'
            >
              <Grid.Column>
                <TitlePage
                  src={ICONS['ic-page-rewards.svg']}
                  name={getLanguage('pointDetail.title', '')}
                />
              </Grid.Column>
              <Grid.Column>
                <Grid gutter='10'>
                  <Grid.Column>
                    <Field name='searchValue'
                      ui='blockLabelVerticalSearch'
                      width='350'
                      placeholder={getLanguage('pointDetail.placeholder', '')}
                      value={searchValue}
                      onChange={this.input.onChangeInput}
                    />
                  </Grid.Column>
                  <Grid.Column>
                    <Button
                      lightBlue
                      name={getLanguage('pointDetail.buttonSearch', '')}
                      width={VARIABLES.BUTTON.WIDTHS.W_105}
                      onClick={this.input.onClickSearch}
                    />
                  </Grid.Column>
                </Grid>
              </Grid.Column>
            </Grid>
          </Section>
          <CustomerListContainerConnected
            type='pointDetail'
          />
        </Section>
      </React.Fragment>
    )
  }
}
