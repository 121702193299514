import React from 'react';
import {
  connect,
} from 'react-redux';
import {
  Section,
  Text,
} from './../../components'
import {
  VARIABLES,
} from './../../themes'
import ReactHtmlParser from 'react-html-parser';
import { getCMSTncList } from '../../helpers/services/cmsTnc';

class TncContainer extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      activePage: 1,
      dataPerPage: 1
    }
  }

  componentDidMount() {
    const {
      activePage,
      dataPerPage,
    } = this.state;
    const params = {
      page: activePage,
      limit: dataPerPage,
      status: 'active',
      sort: 'id desc',
    };
    getCMSTncList(params);
  }

  render() {
    const {
      dataList,
    } = this.props;
    return (
      <Section
        scroll
        calcHeight='100vh - 524px'
      >
        {
          dataList.map((e, i) => {
            return (
              <Section
                // spacingBottom='50'
                justify='center'
                key={i}
              >
                <div className='text-editor-content'>
                  {ReactHtmlParser(e.content)}
                </div>
              </Section>
            )
          })
        }
      </Section>
    )
  }
}

const mapStateToProps = (state) => {
  const dataRedux = state.dataRedux;
  return {
    dataList: dataRedux.cmsTncList,
    activePage: dataRedux.cmsTncParams.activePage,
    totalData: dataRedux.cmsTncParams.totalData || 0,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
  }
}

export const TncContainerConnected = connect(
  mapStateToProps,
  mapDispatchToProps
)(TncContainer)