import React from 'react';
// import {
//   connect,
// } from 'react-redux';
import {
  Section,
  Grid,
  Modal,
  Text,
  Button,
  Image,
  TitleSection,
  ShapeContainer
} from './../../../components'
import {
  VARIABLES, ICONS
} from './../../../themes'
import { getLanguage } from './../../../helpers/functions/language';

export class ModalPreviewImageContainer extends React.Component {

  constructor(props) {
    super(props);
    this.state = {}
  }

  render() {
    const {
      isOpenModal,
      isSecondModal,
      onClickClose,
      onClickCancel,
      onClickDownload,
      title,
      imageSrc,
      ui,
    } = this.props
    const { } = this.state;
    return (
      <React.Fragment>
        {(() => {
          switch (ui) {
            case 'previewImageSmall':
              return (
                <Modal
                  open={isOpenModal}
                  scroll
                  second={isSecondModal}
                  // enableOverlayClose={false}
                  width={VARIABLES.MODAL.WIDTHS.W_960}
                  overlayColor={VARIABLES.COLORS.OVERLAY_BLACK_1}
                  spacing={VARIABLES.MODAL.CONTAINER.C_1.SPACING}
                  transition={VARIABLES.TRANSITIONS.DEFAULT}
                  transformStart={VARIABLES.MODAL.CONTAINER.C_1.TRANSFORM_START}
                  transformEnd={VARIABLES.MODAL.CONTAINER.C_1.TRANSFORM_END}
                  onClickClose={onClickClose}
                >
                  <Modal.Section
                    paddingVertical='40'
                    paddingHorizontal='40'
                    bgColor={VARIABLES.COLORS.WHITE}
                    borderTopRightRadius={VARIABLES.MODAL.HEADER.H_1.BORDER_TOP_RIGHT_RADIUS}
                    borderTopLeftRadius={VARIABLES.MODAL.HEADER.H_1.BORDER_TOP_LEFT_RADIUS}
                  >
                    <Section spacingBottom="35">
                      <TitleSection
                        name={title}
                      />
                    </Section>
                    <Grid justify='center'>
                      <Grid.Column>
                        <ShapeContainer
                          bgColor={VARIABLES.COLORS.WHITE}
                          justify='center'
                          align='center'
                          width='700'
                          boxShadow='0px 0px 10px rgba(0, 0, 0, 0.15);'
                          borderRadius='5'
                          paddingHorizontal='30'
                          paddingVertical='30'
                        >
                          <Image
                            src={imageSrc}
                            widthRatio="640"
                            widthRatioUnit="px"
                            heightRatio="427"
                            heightRatioUnit="px"
                          />
                        </ShapeContainer>
                      </Grid.Column>
                    </Grid>
                  </Modal.Section>
                  <Modal.Section
                    paddingTop='15'
                    paddingBottom='20'
                    paddingHorizontal='35'
                    borderBottomRightRadius={VARIABLES.MODAL.FOOTER.F_1.BORDER_BOTTOM_RIGHT_RADIUS}
                    borderBottomLeftRadius={VARIABLES.MODAL.FOOTER.F_1.BORDER_BOTTOM_LEFT_RADIUS}
                  >
                    <Grid
                      gutter='15'
                      justify='flex-end'
                    >
                      <Grid.Column>
                        <Button
                          gray
                          width={VARIABLES.BUTTON.WIDTHS.W_120}
                          name={getLanguage('field.buttonClose')}
                          onClick={onClickCancel}
                        />
                      </Grid.Column>
                      <Grid.Column>
                        <Button
                          lightGreen
                          ui="frontIcon"
                          name={getLanguage(
                            "field.buttonDownload",
                            ""
                          )}
                          width={VARIABLES.BUTTON.WIDTHS.W_120}
                          iconWidth="18"
                          iconHeight="18"
                          iconSrc={ICONS["ic-download-circle.svg"]}
                          onClick={onClickDownload}
                        />
                      </Grid.Column>
                    </Grid>
                  </Modal.Section>
                </Modal>
              )
            default:
              return (
                <Modal
                  open={isOpenModal}
                  scroll
                  second={isSecondModal}
                  // enableOverlayClose={false}
                  width={VARIABLES.MODAL.WIDTHS.W_1032}
                  overlayColor={VARIABLES.COLORS.OVERLAY_BLACK_1}
                  spacing={VARIABLES.MODAL.CONTAINER.C_1.SPACING}
                  transition={VARIABLES.TRANSITIONS.DEFAULT}
                  transformStart={VARIABLES.MODAL.CONTAINER.C_1.TRANSFORM_START}
                  transformEnd={VARIABLES.MODAL.CONTAINER.C_1.TRANSFORM_END}
                  onClickClose={onClickClose}
                >
                  <Modal.Section
                    paddingVertical='25'
                    paddingHorizontal='35'
                    bgColor={VARIABLES.COLORS.WHITE}
                    borderBottomWidth='1'
                    borderBottomStyle='solid'
                    borderBottomColor={VARIABLES.COLORS.GRAY_8}
                    borderTopRightRadius={VARIABLES.MODAL.HEADER.H_1.BORDER_TOP_RIGHT_RADIUS}
                    borderTopLeftRadius={VARIABLES.MODAL.HEADER.H_1.BORDER_TOP_LEFT_RADIUS}
                  >
                    <Text
                      regular20
                      singleLine
                      color={VARIABLES.COLORS.BLACK}
                      top='2'
                    >
                      {title}
                    </Text>
                  </Modal.Section>
                  <Modal.Section
                    paddingVertical='40'
                    bgColor={VARIABLES.COLORS.WHITE}
                  >
                    <Section height='1132'>
                      <Image
                        src={imageSrc}
                        widthRatio="1032"
                        widthRatioUnit="px"
                        heightRatio="1152"
                        heightRatioUnit="px"
                      />
                    </Section>
                  </Modal.Section>
                  <Modal.Section
                    paddingTop='15'
                    paddingBottom='20'
                    paddingHorizontal='35'
                    borderTopWidth={VARIABLES.MODAL.FOOTER.F_1.BORDER_TOP_WIDTH}
                    borderTopStyle={VARIABLES.MODAL.FOOTER.F_1.BORDER_TOP_STYLE}
                    borderTopColor={VARIABLES.COLORS.GRAY_8}
                    borderBottomRightRadius={VARIABLES.MODAL.FOOTER.F_1.BORDER_BOTTOM_RIGHT_RADIUS}
                    borderBottomLeftRadius={VARIABLES.MODAL.FOOTER.F_1.BORDER_BOTTOM_LEFT_RADIUS}
                  >
                    <Grid
                      gutter='15'
                      justify='flex-end'
                    >
                      <Grid.Column>
                        <Button
                          gray
                          width={VARIABLES.BUTTON.WIDTHS.W_120}
                          name={getLanguage('field.buttonClose')}
                          onClick={onClickCancel}
                        />
                      </Grid.Column>
                    </Grid>
                  </Modal.Section>
                </Modal>
              )
          }
        })()}
      </React.Fragment >

    )
  }
}
