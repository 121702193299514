import styled, {
  css
} from 'styled-components'
import {
  default as VARIABLES
} from './../../../themes/styles/bases/variables'
// import {
//   default as TYPOGRAPHYS
// } from './../../../themes/styles/bases/typographys'
// import {
//   default as MIXINS
// } from './../../../themes/styles/helpers/mixins'

// Sample using image in style
// url(${require('./../../../themes/images/icons/icon-sample.svg')});

// Wrapper
// ============================================================
export const VideoWrapper = styled.div`
  /* Parent
  ------------------------------- */
  overflow: hidden;
  position: relative;
  width: 100%;

  ${props => {
    const theme = props.theme
    return css`
      ${theme.heightRatio && css`
          padding-bottom: ${theme.heightRatio + `%`};
        `
      }
    `
  }}

  /* Childrens
  ------------------------------- */
  .video-content {
    position: absolute;
    z-index: ${VARIABLES.Z_INDEXS.LV_1};
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  /* Modifiers
  ------------------------------- */
  &.is-blue-frame {
    border: 1px solid ${VARIABLES.COLORS.BLUE_14};
  }

  &.is-gray-frame {
    background-color: ${VARIABLES.COLORS.GRAY_24};
    border: 1px solid ${VARIABLES.COLORS.GRAY_24};
    border-bottom-width: 2px;
  }

  /* Media queries
  ------------------------------- */
`
