import React from 'react'
import {
  ShapeContainer,
  Section,
  Breadcrumb,
  TitlePage,
  Grid,
  Button,
  Text,
  DatePickerControl,
  Field,
  Chart
} from './../../components'
import {
  VARIABLES,
  ICONS
} from './../../themes'
import { setSideMenuActive } from '../../helpers/functions/route';
import { ROUTE_PATH } from '../../helpers/constants/route';
import { redirect } from '../../helpers/functions/route';
import { stat } from 'fs';
import { getReport, getClearReportParams } from '../../helpers/services/report';
import { displayWithComma, displayDate } from '../../helpers/functions/display';
import moment from 'moment';
import { DATE_FORMAT, DATE_PICKER_FORMAT } from '../../helpers/constants/data';
import { getDateApiRequet, getMomentStartOf, getMomentEndOf } from '../../helpers/functions/data';
import _ from 'lodash';
import { getLanguage } from '../../helpers/functions/language';
import { downloadData } from '../../helpers/functions/main';
import { downloadExcel } from '../../helpers/functions/addon';
import { getUserAuthRedux } from '../../helpers/functions/auth';
import { ENUM } from '../../helpers/constants/enum';

export class ReportScreen extends React.Component {

  constructor(props) {
    super(props);
    setSideMenuActive(props);
    const userAuth = getUserAuthRedux();
    const startDate = moment().add(-12, 'month').startOf('month')._d;
    // const endDate = moment().startOf('month')._d;
    const endDate = moment().endOf('day')._d;
    const defaultProduct = {
      buyIn: [],
      sellOut: []
    };
    this.state = {
      data: {
        totalBuyin: {
          volumn: 0,
          length: 0
        },
        totalSalesOut: {
          volumn: 0,
          length: 0,
          revenue: 0,
          quantity: 0,
        },
        averageSalesOut: {
          month: 0,
          daily: 0,
        },
        noOfCustomer: {
          total: 0,
          active: 0
        },
        rewardPoint: {
          volumn: 0
        },
        warranty: {
          ready: 0,
          notReady: 0,
          total: 0,
        },
        totalProfileMappingIn: {
          volumn: 0,
          length: 0,
          quantity: 0
        },
        totalProfileMappingOut: {
          volumn: 0,
          length: 0,
          quantity: 0
        },
        totalProfileTransferIn: {
          volumn: 0,
          length: 0,
          quantity: 0
        },
        totalProfileTransferOut: {
          volumn: 0,
          length: 0,
          quantity: 0
        },
        buyinList: [],
        selloutStockList: [],
        top10CustomerList: [],
        mostRedemptionItemList: [],
        mostRedemptionCustomerList: [],
        oldInoutStock: {},
        oldTop10Customer: {},
        oldTop10Sales: {},
        oldMostRedemptionItem: {},
        oldMostRedemptionCustomer: {},
      },
      topSalesPersonsType: 'volume',
      top10SalesList: [],
      startDate,
      endDate,
      displayStartDate: displayDate(startDate),
      displayEndDate: displayDate(endDate),
      inOutStockReportList: [
        { month: 'Jan', buyIn: 0, sellOut: 0, stock: 0, },
        { month: 'Feb', buyIn: 0, sellOut: 0, stock: 0, },
        { month: 'Mar', buyIn: 0, sellOut: 0, stock: 0, },
        { month: 'Apr', buyIn: 0, sellOut: 0, stock: 0, },
        { month: 'May', buyIn: 0, sellOut: 0, stock: 0, },
        { month: 'Jun', buyIn: 0, sellOut: 0, stock: 0, },
        { month: 'Jul', buyIn: 0, sellOut: 0, stock: 0, },
        { month: 'Aug', buyIn: 0, sellOut: 0, stock: 0, },
        { month: 'Sep', buyIn: 0, sellOut: 0, stock: 0, },
        { month: 'Oct', buyIn: 0, sellOut: 0, stock: 0, },
        { month: 'Nov', buyIn: 0, sellOut: 0, stock: 0, },
        { month: 'Dec', buyIn: 0, sellOut: 0, stock: 0, }
      ],
      inOutTransferReportList: [
        { month: 'Jan', transferIn: 0, transferOut: 0, },
        { month: 'Feb', transferIn: 0, transferOut: 0, },
        { month: 'Mar', transferIn: 0, transferOut: 0, },
        { month: 'Apr', transferIn: 0, transferOut: 0, },
        { month: 'May', transferIn: 0, transferOut: 0, },
        { month: 'Jun', transferIn: 0, transferOut: 0, },
        { month: 'Jul', transferIn: 0, transferOut: 0, },
        { month: 'Aug', transferIn: 0, transferOut: 0, },
        { month: 'Sep', transferIn: 0, transferOut: 0, },
        { month: 'Oct', transferIn: 0, transferOut: 0, },
        { month: 'Nov', transferIn: 0, transferOut: 0, },
        { month: 'Dec', transferIn: 0, transferOut: 0, }
      ],
      defaultProduct,
      product: _.cloneDeep(defaultProduct),
      productType: 'buyIn',
      dataProductList: [],
      colorPie: [
        VARIABLES.COLORS.PURPLE_1,
        VARIABLES.COLORS.BLUE_18,
        VARIABLES.COLORS.GREEN_1,
        VARIABLES.COLORS.ORANGE_1,
        VARIABLES.COLORS.BROWN_1,
        VARIABLES.COLORS.PURPLE_2,
        VARIABLES.COLORS.BLUE_19,
        VARIABLES.COLORS.GREEN_2,
        VARIABLES.COLORS.ORANGE_2,
        VARIABLES.COLORS.BROWN_2,
      ],
      exportTypeList: ['all', 'product', 'topCustomer', 'topSales', 'mostRedemption'],
      isIndoAD: userAuth.addressType === ENUM.COUNTRY_TYPE.INDONESIA &&
        userAuth.customerRoleType === ENUM.CUSTOMER_ROLE_TYPE.INDONESIA.AD,
      isIndoDistributor: userAuth.addressType === ENUM.COUNTRY_TYPE.INDONESIA &&
        userAuth.customerRoleType === ENUM.CUSTOMER_ROLE_TYPE.INDONESIA.DISTRIBUTOR,
      isIndoToko: userAuth.addressType === ENUM.COUNTRY_TYPE.INDONESIA &&
        userAuth.customerRoleType === ENUM.CUSTOMER_ROLE_TYPE.INDONESIA.TOKO,
      isMalay: userAuth.addressType === ENUM.COUNTRY_TYPE.MALAYSIA
    }
  }

  componentDidMount() {
    this.report.getReport();
  }

  report = {
    onClickExport: async (type) => {
      const {
        data,
        product,
        exportTypeList,
        isIndoAD,
        isIndoDistributor,
        isIndoToko,
        isMalay
      } = this.state;
      switch (type) {
        case exportTypeList[0]:
          if (isIndoAD) {
            downloadExcel([
              //   {
              //   name: 'mapping in',
              //   data: [{
              //     [getLanguage('report.volume', '')]: `${data.totalProfileMappingIn.volumn} T`,
              //     [getLanguage('report.length', '')]: `${data.totalProfileMappingIn.length} m`,
              //     [getLanguage('report.quantity', '')]: `${data.totalProfileMappingIn.quantity} Stick/Sheet`,
              //   }]
              // },
              {
                name: 'buyin',
                data: [{
                  [getLanguage('report.volume', '')]: `${data.totalBuyin.volumn} T`,
                  [getLanguage('report.length', '')]: `${data.totalBuyin.length} m`,
                }]
              },
              {
                name: 'mapping out',
                data: [{
                  [getLanguage('report.volume', '')]: `${data.totalProfileMappingOut.volumn} T`,
                  [getLanguage('report.length', '')]: `${data.totalProfileMappingOut.length} m`,
                  [getLanguage('report.quantity', '')]: `${data.totalProfileMappingOut.quantity} Stick/Sheet`,
                }]
              },
              {
                name: 'in out stock (buyin)',
                data: data.oldInoutStock.buyin,
              },
              {
                name: 'in out stock (sales out)',
                data: data.oldInoutStock.sellout_stock,
              },
            ], 'report_all');
          }
          else if (isIndoDistributor) {
            downloadExcel([{
              name: 'total transfer in',
              data: [{
                [getLanguage('report.volume', '')]: `${data.totalProfileTransferIn.volumn} T`,
                [getLanguage('report.length', '')]: `${data.totalProfileTransferIn.length} m`,
                [getLanguage('report.quantity', '')]: `${data.totalProfileTransferIn.quantity} Stick/Sheet`,
              }]
            },
            {
              name: 'total transfer out',
              data: [{
                [getLanguage('report.volume', '')]: `${data.totalProfileTransferOut.volumn} T`,
                [getLanguage('report.length', '')]: `${data.totalProfileTransferOut.length} m`,
                [getLanguage('report.quantity', '')]: `${data.totalProfileTransferOut.quantity} Stick/Sheet`,
              }]
            },
            {
              name: 'transfer in',
              data: data.oldTotalProfileTransferList.profile_transfer_in,
            },
            {
              name: 'transfer out',
              data: data.oldTotalProfileTransferList.profile_transfer_out,
            },
            ], 'report_all');
          }
          else if (isIndoToko) {
            downloadExcel([{
              name: 'mapping in',
              data: [{
                [getLanguage('report.volume', '')]: `${data.totalProfileMappingIn.volumn} T`,
                [getLanguage('report.length', '')]: `${data.totalProfileMappingIn.length} m`,
                [getLanguage('report.quantity', '')]: `${data.totalProfileMappingIn.quantity} Stick/Sheet`,
              }]
            }, {
              name: 'sell out',
              // data: [{
              //   [getLanguage('report.volume', '')]: `${data.totalProfileTransferIn.volumn} T`,
              //   [getLanguage('report.length', '')]: `${data.totalProfileTransferIn.length} m`,
              //   [getLanguage('report.quantity', '')]: `${data.totalProfileTransferIn.quantity} Stick/Sheet`,
              // }]
              data: [{
                [getLanguage('report.volume', '')]: `${data.totalSalesOut.volumn} T`,
                [getLanguage('report.length', '')]: `${data.totalSalesOut.length} m`,
                [getLanguage('report.quantity', '')]: `${data.totalSalesOut.quantity} Stick/Sheet`,
              }]
            },
            {
              name: 'in out stock (buyin)',
              data: data.oldInoutStock.buyin,
            },
            {
              name: 'in out stock (sales out)',
              data: data.oldInoutStock.sellout_stock,
            },
            ], 'report_all');
          }
          else if (isMalay) {
            downloadExcel([{
              name: 'buyin',
              data: [{
                [getLanguage('report.volume', '')]: `${data.totalBuyin.volumn} T`,
                [getLanguage('report.length', '')]: `${data.totalBuyin.length} m`,
              }]
            },
            {
              name: 'sellout',
              data: [{
                [getLanguage('report.volume', '')]: `${data.totalSalesOut.volumn} T`,
                [getLanguage('report.length', '')]: `${data.totalSalesOut.length} m`,
                [getLanguage('report.revenue', '')]: `${data.totalSalesOut.revenue} myr`,
              }]
            },
            {
              name: 'average sales',
              data: [{
                [getLanguage('report.monthly', '')]: `${data.averageSalesOut.month} m`,
                [getLanguage('report.daily', '')]: `${data.averageSalesOut.daily} m`,
              }]
            },
            {
              name: 'no of customer',
              data: [{
                [getLanguage('report.total', '')]: `${data.noOfCustomer.total}`,
                [getLanguage('report.active', '')]: `${data.noOfCustomer.active}`,
              }]
            },
            {
              name: 'rewards points',
              data: [{
                [getLanguage('report.point', '')]: `${data.rewardPoint.volumn}`,
              }]
            },
            {
              name: 'warranty',
              data: [{
                [getLanguage('report.ready', '')]: `${data.warranty.ready}`,
                [getLanguage('report.notReady', '')]: `${data.warranty.notReady}`,
                [getLanguage('report.total', '')]: `${data.warranty.total}`,
              }]
            },
            {
              name: 'in out stock (buyin)',
              data: data.oldInoutStock.buyin,
            },
            {
              name: 'in out stock (sales out)',
              data: data.oldInoutStock.sellout_stock,
            },
            ], 'report_all');
          }
          else {
            downloadExcel([{
              name: 'buyin',
              data: [{
                [getLanguage('report.volume', '')]: `${data.totalBuyin.volumn} T`,
                [getLanguage('report.length', '')]: `${data.totalBuyin.length} m`,
              }]
            },
            {
              name: 'sellout',
              data: [{
                [getLanguage('report.volume', '')]: `${data.totalSalesOut.volumn} T`,
                [getLanguage('report.length', '')]: `${data.totalSalesOut.length} m`,
              }]
            },
            {
              name: 'average sales',
              data: [{
                [getLanguage('report.monthly', '')]: `${data.averageSalesOut.month} m`,
                [getLanguage('report.daily', '')]: `${data.averageSalesOut.daily} m`,
              }]
            },
            {
              name: 'no of customer',
              data: [{
                [getLanguage('report.total', '')]: `${data.noOfCustomer.total}`,
                [getLanguage('report.active', '')]: `${data.noOfCustomer.active}`,
              }]
            },
            {
              name: 'in out stock (buyin)',
              data: data.oldInoutStock.buyin,
            },
            {
              name: 'in out stock (sales out)',
              data: data.oldInoutStock.sellout_stock,
            },
            ], 'report_all');
          }
          break;
        case exportTypeList[1]:
          downloadExcel([{
            name: 'product buyin',
            data: product.buyIn,
          },
          {
            name: 'product sellout',
            data: product.sellOut,
          },
          ], 'report_product');
          break;
        case exportTypeList[2]:
          downloadExcel([{
            name: 'customer',
            data: data.top10CustomerList.map(e => {
              return {
                name: e.name,
                volumn: e.volumn,
                length: e.length
              }
            }),
          },
          ], 'report_top10_customer');
          break;
        case exportTypeList[3]:
          downloadExcel([{
            name: 'sales person',
            data: data.top10SalesList
          },
          ], 'report_top10_sales');
          break;
        case exportTypeList[4]:
          downloadExcel([{
            name: 'Most ex',
            data: data.mostRedemptionList
          },
          ], 'report_most_redemption');
          break;
      }
    },
    getInOutStockReport: (data) => {
      const {
        defaultProduct
      } = this.state;
      const inOutStockReport = {};
      let product = _.cloneDeep(defaultProduct);
      data.buyAndSelloutList.forEach(e => {
        const key = `${e.month} ${e.year}`;
        if (!inOutStockReport[key]) {
          inOutStockReport[key] = {
            buyIn: 0,
            sellOut: 0,
            stock: 0,
          }
        }
        if (e.type === 'buyIn') {
          inOutStockReport[key].buyIn += e.fRemaininglength;
        }
        if (e.type === 'sellOut') {
          inOutStockReport[key].sellOut += e.coilLength;
          inOutStockReport[key].stock += e.fRemaininglength;
        }
        const groupName = e.fBsproductgroupname;
        if (!product[e.type][groupName]) {
          product[e.type][groupName] = 0;
        }
        product[e.type][groupName] += e.productValue;
      })
      const inOutStockReportList = Object.keys(inOutStockReport).map(key => {
        return {
          month: key,
          buyIn: inOutStockReport[key].buyIn,
          sellOut: inOutStockReport[key].sellOut,
          stock: inOutStockReport[key].stock,
        }
      });
      product = {
        buyIn: Object.keys(product.buyIn).map(key => {
          return {
            name: key,
            value: product.buyIn[key],
          }
        }),
        sellOut: Object.keys(product.sellOut).map(key => {
          return {
            name: key,
            value: product.sellOut[key],
          }
        })
      };
      console.log('inOutStockReportList', inOutStockReportList)
      console.log('product', product)
      return {
        inOutStockReportList,
        product,
      }
    },
    getInOutTransferReportList: (data) => {
      const inOutTransferReport = {};
      data.totalProfileTransferList.forEach(e => {
        const key = `${e.month}`;
        if (!inOutTransferReport[key]) {
          inOutTransferReport[key] = {
            transferIn: 0,
            transferOut: 0,
          }
        }
        inOutTransferReport[key][e.type] += e.allocateLenghtM;
      })
      const inOutTransferReportList = Object.keys(inOutTransferReport).map(key => {
        return {
          month: key,
          transferIn: inOutTransferReport[key].transferIn,
          transferOut: inOutTransferReport[key].transferOut,
        }
      });
      console.log('inOutTransferReportList', inOutTransferReportList)
      return inOutTransferReportList;
    },
    getReport: (callbackSuccess = () => { }) => {
      const {
        startDate,
        endDate,
        isIndoDistributor,
      } = this.state;
      const params = {
        ...getClearReportParams(),
        start_date: getDateApiRequet(startDate),
        end_date: getDateApiRequet(endDate),
        callbackSuccess: (data) => {
          let {
            inOutTransferReportList
          } = this.state;
          //indo dc
          if (isIndoDistributor) {
            inOutTransferReportList = this.report.getInOutTransferReportList(data);
          }
          const inOutStockReport = this.report.getInOutStockReport(data);
          const inOutStockReportList = inOutStockReport.inOutStockReportList;
          const product = inOutStockReport.product;
          this.setState({
            data,
            inOutStockReportList,
            product,
            dataProductList: product.buyIn,
            top10SalesList: data.top10SalesByVolumeList,
            inOutTransferReportList,
          }, () => {
            if (callbackSuccess) {
              callbackSuccess();
            }
            this.report.onClickProductType('buyIn');
            this.report.onClickTopSalesPersonsType('volume');
          })
        }
      }
      getReport(params)
    },
    getColorPie: (list = []) => {
      const {
        colorPie,
      } = this.state;
      return list.map((e, i) => {
        return colorPie[i % colorPie.length]
      })
    },
    onClickProductType: (type) => {
      const {
        product,
      } = this.state;
      this.setState({
        productType: type,
        dataProductList: product[type],
      })
    },
    onClickTopSalesPersonsType: (type) => {
      const {
        data,
      } = this.state;
      this.setState({
        topSalesPersonsType: type,
        top10SalesList: type === 'volume' ? data.top10SalesByVolumeList : data.top10SalesByLengthList,
      })
    },
  }

  input = {
    onClickSearchDate: () => {
      const {
        startDate,
        endDate,
      } = this.state;
      this.setState({
        displayStartDate: displayDate(startDate),
        displayEndDate: displayDate(endDate),
      })
      this.report.getReport();
    },
    onChangeDate: (date, name) => {
      this.setState({
        [name]: date
      })
    }
  }

  render() {
    const {
      startDate,
      endDate,
      displayStartDate,
      displayEndDate,
      data,
      inOutStockReportList,
      inOutTransferReportList,
      dataProductList,
      productType,
      topSalesPersonsType,
      top10SalesList,
      exportTypeList,
      isIndoAD,
      isIndoDistributor,
      isIndoToko,
      isMalay,
    } = this.state

    return (
      <React.Fragment>
        <Breadcrumb>
          <Breadcrumb.List
            link
            name={getLanguage('report.breadcrumb1', '')}
            onClick={() => {
              redirect(ROUTE_PATH.HOME.LINK)
            }}
          />
          <Breadcrumb.List name='/' />
          <Breadcrumb.List
            link
            name={getLanguage('report.breadcrumb2', '')}
          />
          <Breadcrumb.List name='/' />
          <Breadcrumb.List name={getLanguage('report.breadcrumb3', '')} />
        </Breadcrumb>
        <Section ui='content'>
          <Section spacingBottom='35'>
            <Grid
              gutterVertical='15'
              justify='space-between'
            >
              <Grid.Column>
                <TitlePage
                  src={ICONS['ic-page-report.svg']}
                  name={getLanguage('report.title', '')}
                />
              </Grid.Column>
              <Grid.Column>
                <Grid gutter='10'>
                  <Grid.Column>
                    <Grid gutter='20'>
                      <Grid.Column>
                        <Section paddingTop='6'>
                          <Text
                            regular12
                            color={VARIABLES.COLORS.BLACK}
                          >
                            {getLanguage('report.date', '')}
                          </Text>
                        </Section>
                      </Grid.Column>
                      <Grid.Column>
                        <Grid gutter='15'>
                          <Grid.Column>
                            <DatePickerControl
                              filter
                              dateFormat={DATE_PICKER_FORMAT}
                              placeholderText={getLanguage('report.selectStartDate', '')}
                              selected={startDate}
                              onChange={(date) => {
                                this.input.onChangeDate(date, 'startDate');
                              }}
                              minDate={moment().add(-10, 'years')._d}
                              maxDate={moment().add(5, 'years')._d}
                            />
                          </Grid.Column>
                          <Grid.Column>
                            <Section paddingTop='6'>
                              <Text
                                regular12
                                color={VARIABLES.COLORS.GRAY_19}
                              >
                                {getLanguage('report.dateTo', '')}
                              </Text>
                            </Section>
                          </Grid.Column>
                          <Grid.Column>
                            <DatePickerControl
                              filter
                              dateFormat={DATE_PICKER_FORMAT}
                              placeholderText={getLanguage('report.selectEndDate', '')}
                              selected={endDate}
                              onChange={(date) => {
                                this.input.onChangeDate(date, 'endDate');
                              }}
                              minDate={moment().add(-10, 'years')._d}
                              maxDate={moment().add(5, 'years')._d}
                            />
                          </Grid.Column>
                        </Grid>
                      </Grid.Column>
                    </Grid>
                  </Grid.Column>
                  <Grid.Column>
                    <Button
                      blue
                      name={getLanguage('report.buttonSearch', '')}
                      width={VARIABLES.BUTTON.WIDTHS.W_105}
                      onClick={this.input.onClickSearchDate}
                    />
                  </Grid.Column>
                  <Grid.Column>
                    <Button
                      green
                      ui='frontIcon'
                      name={getLanguage('field.buttonExport', '')}
                      width={VARIABLES.BUTTON.WIDTHS.W_105}
                      iconWidth='16'
                      iconHeight='16'
                      iconSrc={ICONS['ic-export.svg']}
                      onClick={() => {
                        this.report.onClickExport(exportTypeList[0]);
                      }}
                    />
                  </Grid.Column>
                </Grid>
              </Grid.Column>
            </Grid>
          </Section>
          <Section spacingBottom='45'>
            {
              isIndoAD ?
                <Grid gutter='30'>
                  <Grid.Column grid='6'>
                    {/* <ShapeContainer
                      statisticResultUp
                      ui='statisticPanel'
                      statisticName={getLanguage('report.mappingIn', '')}
                      statisticLeftLabel={getLanguage('report.volume', '')}
                      statisticLeftInfo={`${data.totalProfileMappingIn.volumn} T`}
                      statisticCenterLabel={getLanguage('report.length', '')}
                      statisticCenterInfo={`${data.totalProfileMappingIn.length} m`}
                      statisticRightLabel={getLanguage('report.quantity', '')}
                      statisticRightInfo={`${data.totalProfileMappingIn.quantity} Stick/Sheet`}
                      statisticImageChartSrc={ICONS['ic-bar-chart-purple.svg']}
                    /> */}
                    <ShapeContainer
                      statisticResultUp
                      ui='statisticPanel'
                      statisticName={getLanguage('report.buyIn', '')}
                      statisticLeftLabel={getLanguage('report.volume', '')}
                      statisticLeftInfo={`${data.totalBuyin.volumn} T`}
                      statisticRightLabel={getLanguage('report.length', '')}
                      statisticRightInfo={`${data.totalBuyin.length} m`}
                      statisticImageChartSrc={ICONS['ic-bar-chart-purple.svg']}
                    />
                  </Grid.Column>
                  <Grid.Column grid='6'>
                    <ShapeContainer
                      statisticResultUp
                      ui='statisticPanel'
                      statisticName={getLanguage('report.mappingOut', '')}
                      statisticLeftLabel={getLanguage('report.volume', '')}
                      statisticLeftInfo={`${data.totalProfileMappingOut.volumn} T`}
                      statisticCenterLabel={getLanguage('report.length', '')}
                      statisticCenterInfo={`${data.totalProfileMappingOut.length} m`}
                      statisticRightLabel={getLanguage('report.quantity', '')}
                      statisticRightInfo={`${data.totalProfileMappingOut.quantity} Stick/Sheet`}
                      statisticImageChartSrc={ICONS['ic-bar-chart-blue.svg']}
                    />
                  </Grid.Column>
                </Grid>
                :
                isIndoDistributor ?
                  <Grid gutter='30'>
                    <Grid.Column grid='6'>
                      <ShapeContainer
                        statisticResultUp
                        ui='statisticPanel'
                        statisticName={getLanguage('report.transferIn', '')}
                        statisticLeftLabel={getLanguage('report.volume', '')}
                        statisticLeftInfo={`${data.totalProfileTransferIn.volumn} T`}
                        statisticCenterLabel={getLanguage('report.length', '')}
                        statisticCenterInfo={`${data.totalProfileTransferIn.length} m`}
                        statisticRightLabel={getLanguage('report.quantity', '')}
                        statisticRightInfo={`${data.totalProfileTransferIn.quantity} Stick/Sheet`}
                        statisticImageChartSrc={ICONS['ic-bar-chart-purple.svg']}
                      />
                    </Grid.Column>
                    <Grid.Column grid='6'>
                      <ShapeContainer
                        statisticResultUp
                        ui='statisticPanel'
                        statisticName={getLanguage('report.transferOut', '')}
                        statisticLeftLabel={getLanguage('report.volume', '')}
                        statisticLeftInfo={`${data.totalProfileTransferOut.volumn} T`}
                        statisticCenterLabel={getLanguage('report.length', '')}
                        statisticCenterInfo={`${data.totalProfileTransferOut.length} m`}
                        statisticRightLabel={getLanguage('report.quantity', '')}
                        statisticRightInfo={`${data.totalProfileTransferOut.quantity} Stick/Sheet`}
                        statisticImageChartSrc={ICONS['ic-bar-chart-blue.svg']}
                      />
                    </Grid.Column>
                  </Grid>
                  :
                  isIndoToko ?
                    <Grid gutter='30'>
                      <Grid.Column grid='6'>
                        <ShapeContainer
                          statisticResultUp
                          ui='statisticPanel'
                          statisticName={getLanguage('report.mappingIn', '')}
                          statisticLeftLabel={getLanguage('report.volume', '')}
                          statisticLeftInfo={`${data.totalProfileMappingIn.volumn} T`}
                          statisticCenterLabel={getLanguage('report.length', '')}
                          statisticCenterInfo={`${data.totalProfileMappingIn.length} m`}
                          statisticRightLabel={getLanguage('report.quantity', '')}
                          statisticRightInfo={`${data.totalProfileMappingIn.quantity} Stick/Sheet`}
                          statisticImageChartSrc={ICONS['ic-bar-chart-purple.svg']}
                        />
                      </Grid.Column>
                      <Grid.Column grid='6'>
                        <ShapeContainer
                          statisticResultUp
                          ui='statisticPanel'
                          statisticName={getLanguage('report.sellOut', '')}
                          statisticLeftLabel={getLanguage('report.volume', '')}
                          // statisticLeftInfo={`${data.totalProfileTransferIn.volumn} T`}
                          // statisticCenterLabel={getLanguage('report.length', '')}
                          // statisticCenterInfo={`${data.totalProfileTransferIn.length} m`}
                          // statisticRightLabel={getLanguage('report.quantity', '')}
                          // statisticRightInfo={`${data.totalProfileTransferIn.quantity} Stick/Sheet`}
                          statisticLeftInfo={`${data.totalSalesOut.volumn} T`}
                          statisticCenterLabel={getLanguage('report.length', '')}
                          statisticCenterInfo={`${data.totalSalesOut.length} m`}
                          statisticRightLabel={getLanguage('report.quantity', '')}
                          statisticRightInfo={`${data.totalSalesOut.quantity} Stick/Sheet`}
                          statisticImageChartSrc={ICONS['ic-bar-chart-blue.svg']}
                        />
                      </Grid.Column>
                    </Grid>
                    :
                    isMalay ?
                      < Grid gutter='30' gutterVertical='30'>
                        <Grid.Column grid='4'>
                          <ShapeContainer
                            statisticResultUp
                            ui='statisticPanel'
                            statisticName={getLanguage('report.buyIn', '')}
                            statisticLeftLabel={getLanguage('report.volume', '')}
                            statisticLeftInfo={`${data.totalBuyin.volumn} T`}
                            statisticRightLabel={getLanguage('report.length', '')}
                            statisticRightInfo={`${data.totalBuyin.length} m`}
                            statisticImageChartSrc={ICONS['ic-bar-chart-purple.svg']}
                          />
                        </Grid.Column>
                        <Grid.Column grid='4'>
                          <ShapeContainer
                            statisticResultUp
                            ui='statisticPanel'
                            statisticName={getLanguage('report.sellOut', '')}
                            statisticLeftLabel={getLanguage('report.volume', '')}
                            statisticLeftInfo={`${data.totalSalesOut.volumn} T`}
                            statisticCenterLabel={getLanguage('report.length', '')}
                            statisticCenterInfo={`${data.totalSalesOut.length} m`}
                            statisticRightLabel={getLanguage('report.revenue', '')}
                            statisticRightInfo={`${data.totalSalesOut.revenue} myr`}
                            statisticImageChartSrc={ICONS['ic-bar-chart-blue.svg']}
                          />
                        </Grid.Column>
                        <Grid.Column grid='4'>
                          <ShapeContainer
                            statisticResultUp
                            ui='statisticPanel'
                            statisticName={getLanguage('report.averageSales', '')}
                            statisticLeftLabel={getLanguage('report.monthly', '')}
                            statisticLeftInfo={`${data.averageSalesOut.month} m`}
                            statisticRightLabel={getLanguage('report.daily', '')}
                            statisticRightInfo={`${data.averageSalesOut.daily} m`}
                            statisticImageChartSrc={ICONS['ic-bar-chart-green.svg']}
                          />
                        </Grid.Column>
                        <Grid.Column grid='4'>
                          <ShapeContainer
                            statisticResultUp
                            ui='statisticPanel'
                            statisticName={getLanguage('report.noOfCustomers', '')}
                            statisticLeftLabel={getLanguage('report.total', '')}
                            statisticLeftInfo={data.noOfCustomer.total}
                            statisticRightLabel={getLanguage('report.active', '')}
                            statisticRightInfo={data.noOfCustomer.active}
                            statisticImageChartSrc={ICONS['ic-bar-chart-orange.svg']}
                          />
                        </Grid.Column>
                        <Grid.Column grid='4'>
                          <ShapeContainer
                            statisticResultUp
                            ui='statisticPanel'
                            statisticName={getLanguage('report.rewardPoint', '')}
                            statisticLeftLabel={getLanguage('report.point', '')}
                            statisticLeftInfo={data.rewardPoint.volumn}
                            statisticImageChartSrc={ICONS['ic-bar-chart-yellow.svg']}
                          />
                        </Grid.Column>
                        <Grid.Column grid='4'>
                          <ShapeContainer
                            statisticResultUp
                            ui='statisticPanel'
                            statisticName={getLanguage('report.warranty', '')}
                            statisticLeftLabel={getLanguage('report.ready', '')}
                            statisticLeftInfo={data.warranty.ready}
                            statisticCenterLabel={getLanguage('report.notReady', '')}
                            statisticCenterInfo={data.warranty.notReady}
                            statisticRightLabel={getLanguage('report.total', '')}
                            statisticRightInfo={data.warranty.total}
                            statisticImageChartSrc={ICONS['ic-bar-chart-brown.svg']}
                          />
                        </Grid.Column>
                      </Grid>
                      :
                      <Grid gutter='30'>
                        <Grid.Column grid='3'>
                          <ShapeContainer
                            statisticResultUp
                            ui='statisticPanel'
                            statisticName={getLanguage('report.buyIn', '')}
                            statisticLeftLabel={getLanguage('report.volume', '')}
                            statisticLeftInfo={`${data.totalBuyin.volumn} T`}
                            statisticRightLabel={getLanguage('report.length', '')}
                            statisticRightInfo={`${data.totalBuyin.length} m`}
                            statisticImageChartSrc={ICONS['ic-bar-chart-purple.svg']}
                          />
                        </Grid.Column>
                        <Grid.Column grid='3'>
                          <ShapeContainer
                            statisticResultUp
                            ui='statisticPanel'
                            statisticName={getLanguage('report.sellOut', '')}
                            statisticLeftLabel={getLanguage('report.volume', '')}
                            statisticLeftInfo={`${data.totalSalesOut.volumn} T`}
                            statisticRightLabel={getLanguage('report.length', '')}
                            statisticRightInfo={`${data.totalSalesOut.length} m`}
                            statisticImageChartSrc={ICONS['ic-bar-chart-blue.svg']}
                          />
                        </Grid.Column>
                        <Grid.Column grid='3'>
                          <ShapeContainer
                            statisticResultUp
                            ui='statisticPanel'
                            statisticName={getLanguage('report.averageSales', '')}
                            statisticLeftLabel={getLanguage('report.monthly', '')}
                            statisticLeftInfo={`${data.averageSalesOut.month} m`}
                            statisticRightLabel={getLanguage('report.daily', '')}
                            statisticRightInfo={`${data.averageSalesOut.daily} m`}
                            statisticImageChartSrc={ICONS['ic-bar-chart-green.svg']}
                          />
                        </Grid.Column>
                        <Grid.Column grid='3'>
                          <ShapeContainer
                            statisticResultUp
                            ui='statisticPanel'
                            statisticName={getLanguage('report.noOfCustomers', '')}
                            statisticLeftLabel={getLanguage('report.total', '')}
                            statisticLeftInfo={data.noOfCustomer.total}
                            statisticRightLabel={getLanguage('report.active', '')}
                            statisticRightInfo={data.noOfCustomer.active}
                            statisticImageChartSrc={ICONS['ic-bar-chart-yellow.svg']}
                          />
                        </Grid.Column>
                      </Grid>
            }
          </Section>
          <Section spacingBottom='45'>
            <ShapeContainer
              fluid
              paddingTop='15'
              paddingBottom='30'
              paddingHorizontal='15'
              bgColor={VARIABLES.COLORS.WHITE}
              borderRadius='3'
            >
              <Section spacingBottom='25'>
                <Grid gutter='5'>
                  <Grid.Column>
                    <Text
                      regular14
                      color={VARIABLES.COLORS.GRAY_21}
                    >
                      {isIndoDistributor ? getLanguage('report.form.inOutTransfer.title', '') : getLanguage('report.form.inOutStock.title', '')}
                    </Text>
                  </Grid.Column>
                  <Grid.Column>
                    <Text
                      regular14
                      color={VARIABLES.COLORS.GRAY_19}
                    >
                      {displayStartDate} - {displayEndDate}
                    </Text>
                  </Grid.Column>
                </Grid>
              </Section>
              <Section spacingBottom='20'>
                {isIndoDistributor ?
                  <Chart
                    twoBar
                    chartHeight='265'
                    data={inOutTransferReportList}
                    dataKeyXAxis='month'
                    dataKeyBarFirst='transferIn'
                    dataKeyBarSecond='transferOut'
                  />
                  :
                  <Chart
                    threeBar
                    chartHeight='265'
                    data={inOutStockReportList}
                    dataKeyXAxis='month'
                    dataKeyBarFirst='buyIn'
                    dataKeyBarSecond='sellOut'
                    dataKeyBarThird='stock'
                  />
                }
              </Section>
              <Section paddingLeft='15'>
                <Grid
                  gutter='20'
                  gutterVertical='5'
                  justify='center'
                >
                  {isIndoDistributor ?
                    <React.Fragment>
                      <Grid.Column>
                        <Section
                          ui='legend'
                          legendBadgeColor={VARIABLES.COLORS.PURPLE_1}
                          legendName={getLanguage('report.form.inOutTransfer.transferIn', '')}
                        />
                      </Grid.Column>
                      <Grid.Column>
                        <Section
                          ui='legend'
                          legendBadgeColor={VARIABLES.COLORS.BLUE_18}
                          legendName={getLanguage('report.form.inOutTransfer.transferOut', '')}
                        />
                      </Grid.Column>
                    </React.Fragment>
                    :
                    <React.Fragment>
                      <Grid.Column>
                        <Section
                          ui='legend'
                          legendBadgeColor={VARIABLES.COLORS.PURPLE_1}
                          legendName={getLanguage('report.form.inOutStock.sellIn', '')}
                        />
                      </Grid.Column>
                      <Grid.Column>
                        <Section
                          ui='legend'
                          legendBadgeColor={VARIABLES.COLORS.BLUE_18}
                          legendName={getLanguage('report.form.inOutStock.salesOut', '')}
                        />
                      </Grid.Column>
                      <Grid.Column>
                        <Section
                          ui='legend'
                          legendBadgeColor={VARIABLES.COLORS.GREEN_1}
                          legendName={getLanguage('report.form.inOutStock.stock', '')}
                        />
                      </Grid.Column>
                    </React.Fragment>
                  }
                </Grid>
              </Section>
            </ShapeContainer>
          </Section>
          <Section spacingBottom='45'>
            <Grid
              gutter='45'
              gutterVertical='45'
            >
              <Grid.Column grid='12'>
                <Section spacingBottom='30'>
                  <Grid
                    gutter='25'
                    gutterVertical='25'
                    justify='space-between'
                  >
                    <Grid.Column
                      flexStackLaptop
                      flex='1'
                    >
                      <TitlePage
                        src={ICONS['ic-page-report.svg']}
                        name={getLanguage('report.form.product.title', '')}
                      />
                    </Grid.Column>
                    <Grid.Column flex='none'>
                      <Grid gutter='30'>
                        <Grid.Column>
                          <Section paddingTop='8'>
                            <Grid gutter='20'>
                              <Grid.Column>
                                <Field
                                  checked={productType === 'buyIn'}
                                  ui='radio'
                                  radioLabel={getLanguage('report.form.product.salesIn', '')}
                                  onChange={() => {
                                    this.report.onClickProductType('buyIn')
                                  }}
                                />
                              </Grid.Column>
                              <Grid.Column>
                                <Field
                                  checked={productType === 'sellOut'}
                                  ui='radio'
                                  radioLabel={getLanguage('report.form.product.salesOut', '')}
                                  onChange={() => {
                                    this.report.onClickProductType('sellOut')
                                  }}
                                />
                              </Grid.Column>
                            </Grid>
                          </Section>
                        </Grid.Column>
                        <Grid.Column>
                          <Section paddingTop='2'>
                            <Button
                              green
                              ui='frontIcon'
                              name={getLanguage('field.buttonExport', '')}
                              width={VARIABLES.BUTTON.WIDTHS.W_105}
                              iconWidth='16'
                              iconHeight='16'
                              iconSrc={ICONS['ic-export.svg']}
                              onClick={() => {
                                this.report.onClickExport(exportTypeList[1]);
                              }}
                            />
                          </Section>
                        </Grid.Column>
                      </Grid>
                    </Grid.Column>
                  </Grid>
                </Section>
                <ShapeContainer
                  fluid
                  padding='15'
                  bgColor={VARIABLES.COLORS.WHITE}
                  borderRadius='3'
                >
                  <Section>
                    <Text
                      regular14
                      color={VARIABLES.COLORS.GRAY_21}
                    >
                      {getLanguage('report.form.product.report', '')} {productType === 'buyIn' ? getLanguage('report.form.product.salesIn', '') : getLanguage('report.form.product.salesOut', '')}
                    </Text>
                  </Section>
                  <Section>
                    <Text
                      regular11
                      color={VARIABLES.COLORS.GRAY_19}
                    >
                      {displayStartDate} - {displayEndDate}
                    </Text>
                  </Section>
                  <Section
                    spacingBottom='20'
                    justify='center'
                  >
                    <Chart
                      pieMultipleColor
                      nonFluid
                      label
                      labelLine={false}
                      width={370}
                      height={370}
                      chartHeight='370'
                      cx={185}
                      cy={185}
                      innerRadius='110'
                      outerRadius='140'
                      pieMultipleCellColors={this.report.getColorPie(dataProductList)}
                      data={dataProductList}
                    />
                  </Section>
                  <Section spacingBottom='10'>
                    <Grid
                      gutter='30'
                      gutterVertical='5'
                      justify='center'
                    >
                      {dataProductList.map((e, i) => {
                        return (
                          <Grid.Column
                            key={i}
                          >
                            <Section
                              ui='legend'
                              legendBadgeColor={this.report.getColorPie(dataProductList)[i]}
                              legendName={e.name}
                            />
                          </Grid.Column>
                        )
                      })}
                    </Grid>
                  </Section>
                </ShapeContainer>
              </Grid.Column>
            </Grid>
          </Section>
          <Section spacingBottom='45'>
            <Grid
              gutter='45'
              gutterVertical='45'
            >
              <Grid.Column grid='6'>
                <Section spacingBottom='30'>
                  <Grid
                    gutter='25'
                    gutterVertical='25'
                    justify='space-between'
                  >
                    <Grid.Column
                      flexStackLaptop
                      flex='1'
                    >
                      <TitlePage
                        src={ICONS['ic-page-report.svg']}
                        name={getLanguage('report.form.topMember.title', '')}
                      />
                    </Grid.Column>
                    <Grid.Column flex='none'>
                      <Section paddingTop='2'>
                        <Button
                          green
                          ui='frontIcon'
                          name={getLanguage('field.buttonExport', '')}
                          width={VARIABLES.BUTTON.WIDTHS.W_105}
                          iconWidth='16'
                          iconHeight='16'
                          iconSrc={ICONS['ic-export.svg']}
                          onClick={() => {
                            this.report.onClickExport(exportTypeList[2]);
                          }}
                        />
                      </Section>
                    </Grid.Column>
                  </Grid>
                </Section>
                <ShapeContainer
                  fluid
                  padding='15'
                  bgColor={VARIABLES.COLORS.WHITE}
                  borderRadius='3'
                >
                  <Section spacingBottom='10'>
                    <Section>
                      <Text
                        regular14
                        color={VARIABLES.COLORS.GRAY_21}
                      >
                        {getLanguage('report.form.topMember.subTitle', '')}
                      </Text>
                    </Section>
                    <Section>
                      <Text
                        regular11
                        color={VARIABLES.COLORS.GRAY_19}
                      >
                        {displayStartDate} - {displayEndDate}
                      </Text>
                    </Section>
                  </Section>
                  <Section spacingBottom='15'>
                    <Chart
                      twoBarVertical
                      chartHeight='625'
                      data={data.top10CustomerList}
                      dataKeyXAxis='name'
                      dataKeyBarFirst='volumn'
                      dataKeyBarSecond='length'
                    />

                    {/* <Chart
                      twoBar
                      chartHeight='265'
                      data={inOutTransferReportList}
                      dataKeyXAxis='month'
                      dataKeyBarFirst='transferIn'
                      dataKeyBarSecond='transferOut'
                    /> */}


                  </Section>
                  <Section spacingBottom='10'>
                    <Grid
                      gutter='30'
                      gutterVertical='5'
                      justify='center'
                    >
                      <Grid.Column>
                        <Section
                          ui='legend'
                          legendBadgeColor={VARIABLES.COLORS.PURPLE_1}
                          legendName={getLanguage('report.form.topMember.volume', '')}
                        />
                      </Grid.Column>
                      <Grid.Column>
                        <Section
                          ui='legend'
                          legendBadgeColor={VARIABLES.COLORS.BLUE_18}
                          legendName={getLanguage('report.form.topMember.length', '')}
                        />
                      </Grid.Column>
                    </Grid>
                  </Section>
                </ShapeContainer>
              </Grid.Column>
              <Grid.Column
                className='is-flex-column'
                grid='6'
              >
                <Section spacingBottom='30'>
                  <Grid
                    gutter='25'
                    gutterVertical='25'
                    justify='space-between'
                  >
                    <Grid.Column
                      flexStackLaptop
                      flex='1'
                    >
                      <TitlePage
                        src={ICONS['ic-page-report.svg']}
                        name={getLanguage('report.form.topSalesPersons.title', '')}
                      />
                    </Grid.Column>
                    <Grid.Column flex='none'>
                      <Grid gutter='30'>
                        <Grid.Column>
                          <Section paddingTop='8'>
                            <Grid gutter='20'>
                              <Grid.Column>
                                <Field
                                  checked={topSalesPersonsType === 'volume'}
                                  ui='radio'
                                  radioLabel={getLanguage('report.form.topSalesPersons.volume', '')}
                                  onChange={() => {
                                    this.report.onClickTopSalesPersonsType('volume')
                                  }}
                                />
                              </Grid.Column>
                              <Grid.Column>
                                <Field
                                  checked={topSalesPersonsType === 'length'}
                                  ui='radio'
                                  radioLabel={getLanguage('report.form.topSalesPersons.length', '')}
                                  onChange={() => {
                                    this.report.onClickTopSalesPersonsType('length')
                                  }}
                                />
                              </Grid.Column>
                            </Grid>
                          </Section>
                        </Grid.Column>
                        <Grid.Column>
                          <Section paddingTop='2'>
                            <Button
                              green
                              ui='frontIcon'
                              name={getLanguage('field.buttonExport', '')}
                              width={VARIABLES.BUTTON.WIDTHS.W_105}
                              iconWidth='16'
                              iconHeight='16'
                              iconSrc={ICONS['ic-export.svg']}
                              onClick={() => {
                                this.report.onClickExport(exportTypeList[3]);
                              }}
                            />
                          </Section>
                        </Grid.Column>
                      </Grid>
                    </Grid.Column>
                  </Grid>
                </Section>
                <ShapeContainer
                  fluid
                  flex='1'
                  height='100'
                  heightUnit='%'
                  padding='15'
                  bgColor={VARIABLES.COLORS.WHITE}
                  borderRadius='3'
                >
                  <Section spacingBottom='10'>
                    <Section>
                      <Text
                        regular14
                        color={VARIABLES.COLORS.GRAY_21}
                      >
                        {getLanguage('report.form.topSalesPersons.subTitle', '')}
                      </Text>
                    </Section>
                    <Section>
                      <Text
                        regular11
                        color={VARIABLES.COLORS.GRAY_19}
                      >
                        {displayStartDate} - {displayEndDate}
                      </Text>
                    </Section>
                  </Section>
                  <Section
                    flex='1'
                    align='center'
                  >
                    {/* <Chart
                      stackBar
                      chartHeight='625'
                      data={top10SalesList}
                      dataKeyXAxis='name'
                      dataKeyBarFirst='points'
                      dataKeyBarSecond='length'
                    /> */}
                    <Section>
                      <Section
                        justify='center'
                        spacingBottom='20'
                      >
                        <Chart
                          pieMultipleColor
                          nonFluid
                          label
                          labelLine={false}
                          width={370}
                          height={370}
                          chartHeight='370'
                          cx={185}
                          cy={185}
                          innerRadius='110'
                          outerRadius='140'
                          pieMultipleCellColors={this.report.getColorPie(top10SalesList)}
                          data={top10SalesList}
                        />
                      </Section>
                      <Section spacingBottom='60'>
                        <Grid
                          gutter='30'
                          gutterVertical='5'
                          justify='center'
                        >
                          {top10SalesList.map((e, i) => {
                            return (
                              <Grid.Column
                                key={i}
                              >
                                <Section
                                  ui='legend'
                                  legendBadgeColor={this.report.getColorPie(top10SalesList)[i]}
                                  legendName={e.name}
                                  legendNameWidth='65'
                                />
                              </Grid.Column>
                            )
                          })}
                        </Grid>
                      </Section>
                    </Section>
                  </Section>
                </ShapeContainer>
              </Grid.Column>
            </Grid>
          </Section>
          <Section spacingBottom='30'>
            <Grid gutter='30'>
              <Grid.Column grid='8'>
                <Grid justify='space-between'>
                  <Grid.Column>
                    <TitlePage
                      src={ICONS['ic-page-report.svg']}
                      name={getLanguage('report.form.rewardReport.title', '')}
                    />
                  </Grid.Column>
                  <Grid.Column>
                    <Section paddingTop='2'>
                      <Button
                        green
                        ui='frontIcon'
                        name={getLanguage('field.buttonExport', '')}
                        width={VARIABLES.BUTTON.WIDTHS.W_105}
                        iconWidth='16'
                        iconHeight='16'
                        iconSrc={ICONS['ic-export.svg']}
                        onClick={() => {
                          this.report.onClickExport(exportTypeList[4]);
                        }}
                      />
                    </Section>
                  </Grid.Column>
                </Grid>
              </Grid.Column>
            </Grid>
          </Section>
          <Section spacingBottom='10'>
            <Grid gutter='30'>
              {data.mostRedemptionItemList.map((e, i) => {
                return (
                  <Grid.Column grid='4'
                    key={i}
                  >
                    <ShapeContainer
                      ui='statisticPanel'
                      statisticName={getLanguage('report.form.rewardReport.subTitle_1', '')}
                      statisticDetail={e.rewardName}
                      statisticImageChartSrc={ICONS['ic-bar-chart-purple.svg']}
                    />
                  </Grid.Column>
                )
              })}
              {data.mostRedemptionCustomerList.map((e, i) => {
                return (
                  <Grid.Column grid='4'
                    key={i}
                  >
                    <ShapeContainer
                      ui='statisticPanel'
                      statisticName={getLanguage('report.form.rewardReport.subTitle_2', '')}
                      statisticDetail={e.customerName}
                      statisticImageChartSrc={ICONS['ic-bar-chart-blue.svg']}
                    />
                  </Grid.Column>
                )
              })}
            </Grid>
          </Section>
        </Section>
      </React.Fragment >
    )
  }
}
