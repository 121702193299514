import React from 'react'
import ClassNames from 'classnames'
import PropTypes from 'prop-types'
import {
  osName,
} from 'react-device-detect'
import {
  WarrantySignatureWrapper
} from './styled'
import {
  Section,
  Text,
  Image,
  Grid,
  Table,
  ShapeContainer,
} from './../../../../components'
import {
  VARIABLES,
} from './../../../../themes'

/**
 * Warranty description:
 * - Warranty
 */

export class WarrantyBSWMVietnamSignature extends React.PureComponent {
  render() {
    const {
      customerName,
      date,
    } = this.props

    // props for css classes
    const classes = ClassNames(
      'warranty-bswm-signature',
    )

    return (
      <WarrantySignatureWrapper
        className={classes}
      >
        <Grid justify='space-between'>
          <Grid.Column>
            <Section width='300'>
              <p className='warranty-bswm-text'>Authorized by:</p>
              <Section height='50' paddingBottom='10' spacingBottom='10' borderBottomWidth='1' borderBottomStyle='solid' borderBottomColor={VARIABLES.COLORS.BLACK} />
              <p className='warranty-bswm-text is-bold'>NS BLUESCOPE VIETNAM</p>
              <Section spacingBottom='10'>
                <p className='warranty-bswm-text is-sm'>(Technical Marketing Manager)</p>
              </Section>
              <p className='warranty-bswm-text'>Date: <span className='warranty-bswm-text is-bold'>{date}</span></p>
            </Section>
          </Grid.Column>
          <Grid.Column>
            <Section width='300'>
              <p className='warranty-bswm-text'>Acknowledged by:</p>
              <Section height='50' paddingBottom='10' spacingBottom='10' borderBottomWidth='1' borderBottomStyle='solid' borderBottomColor={VARIABLES.COLORS.BLACK} />
              <p className='warranty-bswm-text is-bold'>{customerName}</p>
              <Section spacingBottom='10'>
                <p className='warranty-bswm-text is-sm'>&nbsp;</p>
              </Section>
              <p className='warranty-bswm-text'>Date: <span className='warranty-bswm-text is-bold'>{date}</span></p>
            </Section>
          </Grid.Column>
        </Grid>
      </WarrantySignatureWrapper>
    )
  }

  static defaultProps = {
    // If you set new default props, you should create propsType for correct styleguide document.
  }

  static propTypes = {
    // TYPE > node, string, number, bool, array, object, symbol, func
  }
}
