import React from 'react'
import ClassNames from 'classnames'
// import PropTypes from 'prop-types'
import {
  ButtonBase
} from './../../base'
import {
  Text,
  Image,
  ShapeContainer,
  Grid,
} from './../../../components'
import {
  default as VARIABLES
} from './../../../themes/styles/bases/variables'
// import {
//   default as TYPOGRAPHYS
// } from './../../../themes/styles/bases/typographys'
// import {
//   default as UTILITIES
// } from './../../../themes/styles/helpers/utilities'
import {
  ICONS
} from './../../../themes'

/**
 * Button description:
 * - button
 */

export class Button extends React.PureComponent {
  render() {
    const {
      className,
      ui,
      name,
      lightestBlue,
      lightBlue,
      mediumBlue,
      blue,
      red,
      lightGreen,
      green,
      brown,
      gray,
      outlineBlue,
      outlineLightestBlue,
      iconWidth,
      iconHeight,
      iconSrc,
      textSmall,
      breakpointMobile,
      badgeCountColor,
      badgeCount,
      outlineLightBlue,
    } = this.props

    // props for css classes
    const classes = ClassNames(
      className,
      { 'is-lightest-blue': lightestBlue },
      { 'is-light-blue': lightBlue },
      { 'is-medium-blue': mediumBlue },
      { 'is-blue': blue },
      { 'is-red': red },
      { 'is-light-green': lightGreen },
      { 'is-green': green },
      { 'is-brown': brown },
      { 'is-gray': gray },
      { 'is-outline-blue': outlineBlue },
      { 'is-outline-lightblue': outlineLightBlue },
      { 'is-outline-lightestblue': outlineLightestBlue },
      { 'is-text-small': textSmall },
      { 'is-breakpoint-mobile': breakpointMobile },
    )

    return (
      <React.Fragment>
        {(() => {
          switch (ui) {
            case 'frontIcon':
              return (
                <ButtonBase
                  {...this.props}
                  className={classes}
                  height={VARIABLES.BUTTON.HEIGHTS.H_32}
                  paddingHorizontal={VARIABLES.BUTTON.PADDINGS.P_20}
                  borderRadius={VARIABLES.BUTTON.BORDER_RADIUSES.BR_3}
                >
                  <ButtonBase.Icon spacingRight='10'>
                    <Image
                      widthRatio={iconWidth}
                      widthRatioUnit='px'
                      heightRatio={iconHeight}
                      heightRatioUnit='px'
                      src={iconSrc}
                    />
                  </ButtonBase.Icon>
                  <Text
                    medium14
                    singleLine
                    color={VARIABLES.COLORS.WHITE}
                    top='-1'
                  >
                    {name}
                  </Text>
                </ButtonBase>
              )
            case 'frontIconSmall':
              return (
                <ButtonBase
                  {...this.props}
                  className={classes}
                  height={VARIABLES.BUTTON.HEIGHTS.H_20}
                  paddingHorizontal={VARIABLES.BUTTON.PADDINGS.P_10}
                  borderRadius={VARIABLES.BUTTON.BORDER_RADIUSES.BR_3}
                >
                  <ButtonBase.Icon spacingRight='10'>
                    <Image
                      widthRatio={iconWidth}
                      widthRatioUnit='px'
                      heightRatio={iconHeight}
                      heightRatioUnit='px'
                      top='-1'
                      src={iconSrc}
                    />
                  </ButtonBase.Icon>
                  <Text
                    medium9
                    singleLine
                    color={VARIABLES.COLORS.WHITE}
                  >
                    {name}
                  </Text>
                </ButtonBase>
              )
            case 'iconSmall':
              return (
                <ButtonBase
                  {...this.props}
                  className={classes}
                  height={VARIABLES.BUTTON.HEIGHTS.H_20}
                  paddingHorizontal={VARIABLES.BUTTON.PADDINGS.P_10}
                  borderRadius={VARIABLES.BUTTON.BORDER_RADIUSES.BR_3}
                >
                  <ButtonBase.Icon>
                    <Image
                      widthRatio={iconWidth}
                      widthRatioUnit='px'
                      heightRatio={iconHeight}
                      heightRatioUnit='px'
                      top='-1'
                      src={iconSrc}
                    />
                  </ButtonBase.Icon>
                </ButtonBase>
              )
            case 'small':
              return (
                <ButtonBase
                  {...this.props}
                  className={classes}
                  height={VARIABLES.BUTTON.HEIGHTS.H_20}
                  paddingHorizontal={VARIABLES.BUTTON.PADDINGS.P_10}
                  borderRadius={VARIABLES.BUTTON.BORDER_RADIUSES.BR_3}
                >
                  <Text
                    medium9
                    singleLine
                    color={VARIABLES.COLORS.WHITE}
                  >
                    {name}
                  </Text>
                </ButtonBase>
              )
            case 'frontIconSmallTextTail':
              return (
                <ButtonBase
                  {...this.props}
                  className={classes}
                  height={VARIABLES.BUTTON.HEIGHTS.H_20}
                  paddingHorizontal={VARIABLES.BUTTON.PADDINGS.P_10}
                  borderRadius={VARIABLES.BUTTON.BORDER_RADIUSES.BR_3}
                >
                  <ButtonBase.Icon spacingRight='10'>
                    <Image
                      widthRatio={iconWidth}
                      widthRatioUnit='px'
                      heightRatio={iconHeight}
                      heightRatioUnit='px'
                      top='-1'
                      src={iconSrc}
                    />
                  </ButtonBase.Icon>
                  <Text
                    medium9
                    singleLine
                    color={VARIABLES.COLORS.WHITE}
                    top='-1'
                  >
                    {name}
                  </Text>
                </ButtonBase>
              )
            case 'icon':
              return (
                <ButtonBase
                  {...this.props}
                  className={classes}
                  height={VARIABLES.BUTTON.HEIGHTS.H_32}
                  paddingHorizontal={VARIABLES.BUTTON.PADDINGS.P_15}
                  borderRadius={VARIABLES.BUTTON.BORDER_RADIUSES.BR_3}
                >
                  <ButtonBase.Icon>
                    <Image
                      widthRatio={iconWidth}
                      widthRatioUnit='px'
                      heightRatio={iconHeight}
                      heightRatioUnit='px'
                      src={iconSrc}
                    />
                  </ButtonBase.Icon>
                </ButtonBase>
              )
            case 'back':
              return (
                <ButtonBase
                  {...this.props}
                  className={classes}
                  height={VARIABLES.BUTTON.HEIGHTS.H_32}
                  paddingRight={VARIABLES.BUTTON.PADDINGS.P_35}
                  paddingLeft={VARIABLES.BUTTON.PADDINGS.P_25}
                  borderRadius={VARIABLES.BUTTON.BORDER_RADIUSES.BR_3}
                >
                  <ButtonBase.Icon spacingRight='20'>
                    <Image
                      widthRatio='10'
                      widthRatioUnit='px'
                      heightRatio='10'
                      heightRatioUnit='px'
                      src={ICONS['ic-arrow-back.svg']}
                      top='-1'
                    />
                  </ButtonBase.Icon>
                  <Text
                    medium14
                    singleLine
                    color={VARIABLES.COLORS.WHITE}
                    top='-1'
                  >
                    {name}
                  </Text>
                </ButtonBase>
              )
            case 'topIcon':
              return (
                <ButtonBase
                  {...this.props}
                  className={classes}
                  height={VARIABLES.BUTTON.HEIGHTS.H_32}
                  paddingHorizontal={VARIABLES.BUTTON.PADDINGS.P_20}
                  borderRadius={VARIABLES.BUTTON.BORDER_RADIUSES.BR_3}
                >
                  <ButtonBase.Icon spacingRight='10'>
                    <Image
                      widthRatio={iconWidth}
                      widthRatioUnit='px'
                      heightRatio={iconHeight}
                      heightRatioUnit='px'
                      src={iconSrc}
                    />
                  </ButtonBase.Icon>
                  <Text
                    medium14
                    singleLine
                    color={VARIABLES.COLORS.WHITE}
                    top='-1'
                  >
                    {name}
                  </Text>
                </ButtonBase>
              )
            case 'badgeCount':
              return (
                <ButtonBase
                  {...this.props}
                  className={classes}
                  height={VARIABLES.BUTTON.HEIGHTS.H_32}
                  paddingHorizontal={VARIABLES.BUTTON.PADDINGS.P_20}
                  borderRadius={VARIABLES.BUTTON.BORDER_RADIUSES.BR_3}
                >
                  <Grid gutter='15' justify='center'>
                    <Grid.Column>
                      <Text
                        medium14
                        singleLine
                        color={VARIABLES.COLORS.WHITE}
                        top='3'
                      >
                        {name}
                      </Text>
                    </Grid.Column>
                    <Grid.Column>
                      <ShapeContainer
                        justify='center'
                        align='center'
                        width='22'
                        height='22'
                        bgColor={VARIABLES.COLORS.WHITE}
                        borderRadius='10'
                      >
                        <Text
                          bold14
                          singleLine
                          color={badgeCountColor ? badgeCountColor : VARIABLES.COLORS.PRIMARY_1}
                        >
                          {badgeCount}
                        </Text>
                      </ShapeContainer>
                    </Grid.Column>
                  </Grid>
                </ButtonBase>
              )
            case 'edit':
              return (
                <ButtonBase
                  {...this.props}
                  className={classes}
                  height={VARIABLES.BUTTON.HEIGHTS.H_32}
                  paddingRight={VARIABLES.BUTTON.PADDINGS.P_35}
                  paddingLeft={VARIABLES.BUTTON.PADDINGS.P_25}
                  borderRadius={VARIABLES.BUTTON.BORDER_RADIUSES.BR_3}
                >
                  <ButtonBase.Icon spacingRight='20'>
                    <Image
                      widthRatio='13'
                      widthRatioUnit='px'
                      heightRatio='13'
                      heightRatioUnit='px'
                      src={ICONS['ic-edit-white.svg']}
                      top='-1'
                    />
                  </ButtonBase.Icon>
                  <Text
                    medium14
                    singleLine
                    color={VARIABLES.COLORS.WHITE}
                    top='-1'
                  >
                    {name}
                  </Text>
                </ButtonBase>
              )
            default:
              return (
                <ButtonBase
                  {...this.props}
                  className={classes}
                  height={VARIABLES.BUTTON.HEIGHTS.H_32}
                  paddingHorizontal={VARIABLES.BUTTON.PADDINGS.P_20}
                  borderRadius={VARIABLES.BUTTON.BORDER_RADIUSES.BR_3}
                >
                  <Text
                    medium14
                    singleLine
                    color={VARIABLES.COLORS.WHITE}
                    top='-1'
                  >
                    {name}
                  </Text>
                </ButtonBase>
              )
          }
        })()}
      </React.Fragment>
    )
  }

  static Icon = ButtonBase.Icon

  static defaultProps = {
    // * If you set new default props, you should create propsType for correct styleguide document.
    bgColorDisabled: VARIABLES.COLORS.DISABLED
  }

  static propTypes = {
    // TYPE > node, string, number, bool, array, object, symbol, func
    /**
    * props_name_oneType
    */
    // props_name_onetype: PropTypes.string,

    /**
    * props_name_oneMoreType
    */
    // props_name_oneMoreType: PropTypes.oneOfType([
    //   PropTypes.node,
    //   PropTypes.string
    // ])
  }
}
