import React from 'react';
// import {
//   connect,
// } from 'react-redux';
import {
  Section,
  Grid,
  Modal,
  Text,
  Button,
  Image,
  ShapeContainer,
} from '../../../components'
import {
  VARIABLES,
  ICONS
} from '../../../themes'
// import { MODAL_TYPE } from '../../../helpers/constants/main'
// import { MAIN_REDUX_STATE_KEY } from '../../../actions/mainAction'
import _ from 'lodash';
import { getLanguage } from '../../../helpers/functions/language';
import BarcodeScannerComponent from "react-qr-barcode-scanner";


export class ModalBarcodeScannerContainer extends React.Component {
  render() {
    const {
      isOpenModal,
      onClickClose,
      onUpdate = () => { }
    } = this.props
    return (
      <Modal
        open={isOpenModal}
        fluid
        // enableOverlayClose={false}
        overlayColor={VARIABLES.COLORS.OVERLAY_BLACK_1}
        transition={VARIABLES.TRANSITIONS.DEFAULT}
        transformStart={VARIABLES.MODAL.CONTAINER.C_1.TRANSFORM_START}
        transformEnd={VARIABLES.MODAL.CONTAINER.C_1.TRANSFORM_END}
        onClickClose={onClickClose}
      >
        <Modal.Section>
          <React.Fragment>
            <Section paddingVertical='15' bgColor={VARIABLES.COLORS.WHITE}
              onClick={onClickClose}
            >
              <Grid gutter='12'>
                <Grid.Column>
                  <Image
                    src={ICONS['ic-left-blue.svg']}
                    widthRatio='24'
                    widthRatioUnit='px'
                    heightRatio='24'
                    heightRatioUnit='px'
                  />
                </Grid.Column>
                <Grid.Column>
                  <Text
                    regular16
                    color={VARIABLES.COLORS.PRIMARY_2}
                    top='2'
                  >
                    {getLanguage('barcode.label.back', '')}
                  </Text>
                </Grid.Column>
              </Grid>
            </Section>
            <Section
              align='center'
              textAlign='center'
              justify='center'
              bgColor={VARIABLES.COLORS.BLACK}
              calcMinHeight='100vh - 54px'
              paddingHorizontal='24'
            >
              <Section
                relative
                bgColor={VARIABLES.COLORS.GRAY_32}
                calcMinHeight={'100vh - 248px'}
                align='center'
                textAlign='center'
                justify='center'
              >
                <BarcodeScannerComponent
                  onUpdate={(err, result) => {
                    if (result) {
                      onUpdate(result.text);
                    }
                  }}
                />
                <Section
                  absolute
                  width='20'
                  height='20'
                  zIndexLv1
                  top='0'
                  left='0'
                  borderLeftWidth='3'
                  borderLeftStyle='solid'
                  borderLeftColor={VARIABLES.COLORS.BLUE_22}
                  borderTopWidth='3'
                  borderTopStyle='solid'
                  borderTopColor={VARIABLES.COLORS.BLUE_22}
                />
                <Section
                  absolute
                  width='20'
                  height='20'
                  zIndexLv1
                  top='0'
                  right='0'
                  borderRightWidth='3'
                  borderRightStyle='solid'
                  borderRightColor={VARIABLES.COLORS.BLUE_22}
                  borderTopWidth='3'
                  borderTopStyle='solid'
                  borderTopColor={VARIABLES.COLORS.BLUE_22}
                />
                <Section
                  absolute
                  width='20'
                  height='20'
                  zIndexLv1
                  bottom='0'
                  left='0'
                  borderLeftWidth='3'
                  borderLeftStyle='solid'
                  borderLeftColor={VARIABLES.COLORS.BLUE_22}
                  borderBottomWidth='3'
                  borderBottomStyle='solid'
                  borderBottomColor={VARIABLES.COLORS.BLUE_22}
                />
                <Section
                  absolute
                  width='20'
                  height='20'
                  zIndexLv1
                  bottom='0'
                  right='0'
                  borderRightWidth='3'
                  borderRightStyle='solid'
                  borderRightColor={VARIABLES.COLORS.BLUE_22}
                  borderBottomWidth='3'
                  borderBottomStyle='solid'
                  borderBottomColor={VARIABLES.COLORS.BLUE_22}
                />
                <Section
                  absolute
                  zIndexLv1
                  positionTop='50'
                  positionLeft='50'
                  transform={'translate(-50%, -50%)'}
                  calcMinWidth={'calc(100% - 24px)'}
                  borderTopWidth='2'
                  borderTopStyle='solid'
                  borderTopColor={VARIABLES.COLORS.RED_3}
                />
              </Section>
            </Section>
          </React.Fragment>
        </Modal.Section>
      </Modal>
    )
  }

  static defaultProps = {
    buttonSubmitName: getLanguage('field.buttonConfirm', ''),
  }
}
