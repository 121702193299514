import React from "react";
// import {
//   connect,
// } from 'react-redux';
import {
  Section,
  Grid,
  Modal,
  Text,
  Button,
  Image,
  TitleSection,
  ShapeContainer,
} from "../../../components";
import { VARIABLES, ICONS, CONTENTS } from "../../../themes";
import { getLanguage } from "../../../helpers/functions/language";

export class ModalProductUpdateContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      isOpenModal,
      isSecondModal,
      onClickPrint,
      onClickAccept,
      onClickClose,
      title,
      imageSrc,
      subject,
      description,
      isView,
    } = this.props;

    return (
      <React.Fragment>
        <Modal
          open={isOpenModal}
          scroll
          className="product-update"
          second={isSecondModal}
          width={VARIABLES.MODAL.WIDTHS.W_768}
          overlayColor={VARIABLES.COLORS.OVERLAY_BLACK_1}
          spacing={VARIABLES.MODAL.CONTAINER.C_1.SPACING}
          transition={VARIABLES.TRANSITIONS.DEFAULT}
          transformStart={VARIABLES.MODAL.CONTAINER.C_1.TRANSFORM_START}
          transformEnd={VARIABLES.MODAL.CONTAINER.C_1.TRANSFORM_END}
        >
          <Modal.Section
            paddingVertical="25.5"
            paddingHorizontal="35"
            bgColor={VARIABLES.COLORS.WHITE}
            borderBottomWidth="1"
            borderBottomStyle="solid"
            borderBottomColor={VARIABLES.COLORS.GRAY_8}
            borderTopRightRadius={5}
            borderTopLeftRadius={5}
            className="print-section"
          >
            <Grid gutter={10}>
              <Grid.Column>
                <Image
                  src={ICONS["ic-broadcast.svg"]}
                  widthRatio="20"
                  widthRatioUnit="px"
                  heightRatio="20"
                  heightRatioUnit="px"
                />
              </Grid.Column>
              <Grid.Column>
                <Text
                  regular16
                  singleLine
                  color={VARIABLES.COLORS.BLACK}
                  top="2"
                >
                  {getLanguage("field.productUpdate", "")}
                </Text>
              </Grid.Column>
            </Grid>
          </Modal.Section>
          <Modal.Section bgColor={VARIABLES.COLORS.WHITE}>
            <Section padding="45" height="480" scrollOverlay>
              <Section spacingBottom="30" className="print-section">
                <Text bold24>{subject}</Text>
              </Section>
              <Section spacingBottom="30" className="print-section">
                <div
                  className="text-editor-content"
                  dangerouslySetInnerHTML={{
                    __html: description,
                  }}
                />
              </Section>
              {imageSrc && (
                <Section>
                  <Image
                    className="product-image-print"
                    src={imageSrc}
                    widthRatio="678"
                    widthRatioUnit="px"
                    heightRatio="959"
                    heightRatioUnit="px"
                  />
                </Section>
              )}
            </Section>
          </Modal.Section>
          <Modal.Section
            className="print-section"
            paddingTop="15"
            paddingBottom="20"
            paddingHorizontal="35"
            borderTopWidth={VARIABLES.MODAL.FOOTER.F_1.BORDER_TOP_WIDTH}
            borderTopStyle={VARIABLES.MODAL.FOOTER.F_1.BORDER_TOP_STYLE}
            borderTopColor={VARIABLES.COLORS.GRAY_8}
            borderBottomRightRadius={5}
            borderBottomLeftRadius={5}
          >
            <Grid gutter="15" justify="flex-end">
              {isView ? (
                <Grid.Column>
                  <Button
                    gray
                    width={VARIABLES.BUTTON.WIDTHS.W_155}
                    name={getLanguage("field.buttonClose")}
                    onClick={onClickClose}
                  />
                </Grid.Column>
              ) : (
                <React.Fragment>
                  <Grid.Column>
                    <Button
                      lightGreen
                      width={VARIABLES.BUTTON.WIDTHS.W_155}
                      name={getLanguage("field.buttonAccept", "")}
                      onClick={onClickAccept}
                    />
                  </Grid.Column>
                </React.Fragment>
              )}
            </Grid>
          </Modal.Section>
        </Modal>
      </React.Fragment>
    );
  }
}
