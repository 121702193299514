import { isValidResponse } from "../functions/validation";
import { storeDispatch, storeGetState } from "../../stores";
import { dataCreators, DATA_REDUX_STATE_KEY } from "../../actions/dataAction";
import { cmsNewsAndPromotionController } from "../../apiService/apiController/cmsNewsAndPromotionService";
import { ModelCMSNewsAndPromotion } from "../../models/ModelCMSNewsAndPromotion";
import { ENUM } from "../constants/enum";
import { handleSortParams, handleTrimParams } from "../functions/data";
import { getUserAuthRedux } from "../functions/auth";

export const getCMSNewsAndPromotionList = async (params = {}) => {
  const userAuth = getUserAuthRedux();
  let currentParams = storeGetState().dataRedux.cmsNewsAndPromotionParams;
  let newParams = handleTrimParams({
    ...currentParams,
    ...params,
    app: ENUM.CMS_TYPE.TRUE_BLUE,
  });
  if (!newParams.isNoSort) {
    if (!newParams.sort) {
      newParams.sort = 'id desc';
    }
    else {
      newParams = handleSortParams(currentParams, newParams);
    }
  }
  const apiParams = {
    ...newParams,
    sort: `display_index asc, ${newParams.sort}`,
  }
  const cmsNewsAndPromotionService = cmsNewsAndPromotionController();
  const res = await cmsNewsAndPromotionService.searchNewsPromotion(apiParams);
  if (isValidResponse(res)) {
    let dataList = res.data.filter(e => e.available_country.indexOf(userAuth.addressType) >= 0).map(e => new ModelCMSNewsAndPromotion(e));
    dataList = dataList.filter(e => {
      if (userAuth.segmentCategory__c === null && userAuth.segmentation__c === null) {
        return false;
      } else {
        if (e.segmentation === null) {
          return true;
        }
        if (e.segmentCategory[userAuth.segmentCategory__c]) {
          return e.segmentCategory[userAuth.segmentCategory__c][userAuth.segmentation__c];
        }
        return false;
      }
    })
    newParams.activePage = newParams.page;
    newParams.totalData = res.total_items;
    console.log('dataList', res.data, dataList, newParams);
    storeDispatch(dataCreators.setDataRedux(DATA_REDUX_STATE_KEY.CMS_NEWS_AND_PROMOTION_LIST, dataList));
    storeDispatch(dataCreators.setDataRedux(DATA_REDUX_STATE_KEY.CMS_NEWS_AND_PROMOTION_PARAMS, newParams));
  }
}

export const getCMSNewsAndPromotion = async (params = {}) => {
  const userAuth = getUserAuthRedux();
  let currentParams = storeGetState().dataRedux.cmsNewsAndPromotionParams;
  let newParams = handleTrimParams({
    ...currentParams,
    ...params,
    app: ENUM.CMS_TYPE.TRUE_BLUE,
  });
  const cmsNewsAndPromotionService = cmsNewsAndPromotionController();
  const res = await cmsNewsAndPromotionService.getById(newParams);
  const dataList = res.data.filter(e => e.available_country.indexOf(userAuth.addressType) >= 0).map(e => new ModelCMSNewsAndPromotion(e));
  console.log('dataList', res.data, dataList, newParams);
  storeDispatch(dataCreators.setDataRedux(DATA_REDUX_STATE_KEY.CMS_NEWS_AND_PROMOTION_LIST, dataList));
  storeDispatch(dataCreators.setDataRedux(DATA_REDUX_STATE_KEY.CMS_NEWS_AND_PROMOTION_PARAMS, newParams));
  return dataList[0]
}