import React from 'react';
import {
  connect,
} from 'react-redux';
import { MAIN_REDUX_STATE_KEY } from '../../actions/mainAction';
import { CustomerPointsDetailContainer } from './CustomerPointsDetailContainer';
import { CustomerContentInfoContainer } from './CustomerContentInfoContainer';

class CustomerInfoContainer extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
    }
  }

  render() {
    const {
      data,
      onClickSelectCustomer,
      onChangeSelectSalePerson,
      selectSalePerson,
      isRedemption,
      type,
      expirePointList,
      handleSetSelectSalePerson,
      displayNoneButtonSelectSalePerson,
      disableSelectSalePerson,
      disableSelectSalePersonColor,
      isHideSalePerson
    } = this.props;
    switch (type) {
      case 'pointsDetail':
        return (
          <CustomerPointsDetailContainer
            data={data}
            expirePointList={expirePointList}
          />
        )
      default:
        return (
          <CustomerContentInfoContainer
            data={data}
            isRedemption={isRedemption}
            onClickSelectCustomer={onClickSelectCustomer}
            onChangeSelectSalePerson={onChangeSelectSalePerson}
            selectSalePerson={selectSalePerson}
            handleSetSelectSalePerson={handleSetSelectSalePerson}
            displayNoneButtonSelectSalePerson={displayNoneButtonSelectSalePerson}
            disableSelectSalePerson={disableSelectSalePerson}
            disableSelectSalePersonColor={disableSelectSalePersonColor}
            isHideSalePerson={isHideSalePerson}
          />
        )
    }
  }
}

const mapStateToProps = (state) => {
  const dataRedux = state.dataRedux;
  const mainRedux = state.mainRedux;
  return {
    dataList: dataRedux.customerList,
    expirePointList: dataRedux.expirePointList,
    activePage: dataRedux.customerParams.activePage,
    totalData: dataRedux.customerParams.totalData || 0,
    userAuth: mainRedux[MAIN_REDUX_STATE_KEY.USER_AUTH],
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
  }
}

export const CustomerInfoContainerConnected = connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomerInfoContainer)