import React from 'react'
import ClassNames from 'classnames'
import PropTypes from 'prop-types'
import {
  osName,
} from 'react-device-detect'
import {
  WarrantyProductInformationWrapper
} from './styled'
import {
  Section,
  Text,
  Image,
  Grid,
  Table,
  ShapeContainer,
} from './../../../../components'
import {
  VARIABLES,
} from './../../../../themes'

/**
 * Warranty description:
 * - Warranty
 */

export class WarrantyBSWMIndonesiaProductInformation extends React.PureComponent {
  render() {
    const {
      registeredTrademarkName,
      customerName,
      projectName,
      projectAddress,
      perforateByCorrosion,
      perforateByCorrosionYear,
      perforateByCorrosionWallYear,
      peelOrFlakeExcessively,
      peelOrFlakeExcessivelyYear,
      peelOrFlakeExcessivelyWallYear,
      colourFadeExcessively,
      colourFadeExcessivelyYear,
      colourFadeExcessivelyWallYear,
      discolourByDirtStaining,
      discolourByDirtStainingYear,
      discolourByDirtStainingWallYear,
      isRoofAndWall,
    } = this.props

    // props for css classes
    const classes = ClassNames(
      'warranty-bswm-note',
    )

    return (
      <WarrantyProductInformationWrapper
        className={classes}
      >
        <ShapeContainer width='100' widthUnit='%' paddingVertical='10' paddingHorizontal='15' borderWidth='1' borderStyle='solid' borderColor={VARIABLES.COLORS.BLACK} borderRadius='5'>
          <Section>
            <Section spacingBottom='5'>
              <p className='warranty-bswm-text'>
                <span className='warranty-bswm-text is-bold'>
                  PT NS BlueScope Indonesia</span> (manufacturer of <span className='warranty-bswm-text is-bold'>{registeredTrademarkName}</span>), warrants to <span className='warranty-bswm-text is-bold'>{customerName}</span> (supplier of profiled sheets) that the <span className='warranty-bswm-text is-bold'>{registeredTrademarkName}</span> if correctly installed and maintained on the <span className='warranty-bswm-text is-bold'>{projectName}</span> project at <span className='warranty-bswm-text is-bold'>{projectAddress}
                </span> :
              </p>
            </Section>
            <Grid gutter='30'>
              <Grid.Column>
                {isRoofAndWall && <p className='warranty-bswm-text is-bold'>Roof</p> }
                {perforateByCorrosionYear &&
                  <Grid>
                    <Grid.Column>
                      <Section width='225'><p className='warranty-bswm-text'>- {perforateByCorrosion}:</p></Section>
                    </Grid.Column>
                    <Grid.Column>
                      <p className='warranty-bswm-text is-year'><span className='warranty-bswm-text is-bold'>{perforateByCorrosionYear}</span> Years</p>
                    </Grid.Column>
                  </Grid>
                }
                {peelOrFlakeExcessivelyYear &&
                  <Grid>
                    <Grid.Column>
                      <Section width='225'><p className='warranty-bswm-text'>- {peelOrFlakeExcessively}:</p></Section>
                    </Grid.Column>
                    <Grid.Column>
                      <p className='warranty-bswm-text is-year'><span className='warranty-bswm-text is-bold'>{peelOrFlakeExcessivelyYear}</span> Years</p>
                    </Grid.Column>
                  </Grid>
                }
                {colourFadeExcessivelyYear &&
                  <Grid>
                    <Grid.Column>
                      <Section width='225'><p className='warranty-bswm-text'>- {colourFadeExcessively}:</p></Section>
                    </Grid.Column>
                    <Grid.Column>
                      <p className='warranty-bswm-text is-year'><span className='warranty-bswm-text is-bold'>{colourFadeExcessivelyYear}</span> Years</p>
                    </Grid.Column>
                  </Grid>
                }
                {discolourByDirtStainingYear &&
                  <Grid>
                    <Grid.Column>
                      <Section width='225'><p className='warranty-bswm-text'>- {discolourByDirtStaining}:</p></Section>
                    </Grid.Column>
                    <Grid.Column>
                      <p className='warranty-bswm-text is-year'><span className='warranty-bswm-text is-bold'>{discolourByDirtStainingYear}</span> Years</p>
                    </Grid.Column>
                  </Grid>
                }
              </Grid.Column>
              {isRoofAndWall &&
                <Grid.Column>
                  <p className='warranty-bswm-text is-bold'>Wall</p>
                  {perforateByCorrosionWallYear &&
                    <Grid>
                      <Grid.Column>
                        <Section width='225'><p className='warranty-bswm-text'>- {perforateByCorrosion}:</p></Section>
                      </Grid.Column>
                      <Grid.Column>
                        <p className='warranty-bswm-text is-year'><span className='warranty-bswm-text is-bold'>{perforateByCorrosionWallYear}</span> Years</p>
                      </Grid.Column>
                    </Grid>
                  }
                  {peelOrFlakeExcessivelyWallYear &&
                    <Grid>
                      <Grid.Column>
                        <Section width='225'><p className='warranty-bswm-text'>- {peelOrFlakeExcessively}:</p></Section>
                      </Grid.Column>
                      <Grid.Column>
                        <p className='warranty-bswm-text is-year'><span className='warranty-bswm-text is-bold'>{peelOrFlakeExcessivelyWallYear}</span> Years</p>
                      </Grid.Column>
                    </Grid>
                  }
                  {colourFadeExcessivelyWallYear &&
                    <Grid>
                      <Grid.Column>
                        <Section width='225'><p className='warranty-bswm-text'>- {colourFadeExcessively}:</p></Section>
                      </Grid.Column>
                      <Grid.Column>
                        <p className='warranty-bswm-text is-year'><span className='warranty-bswm-text is-bold'>{colourFadeExcessivelyWallYear}</span> Years</p>
                      </Grid.Column>
                    </Grid>
                  }
                  {discolourByDirtStainingWallYear &&
                    <Grid>
                      <Grid.Column>
                        <Section width='225'><p className='warranty-bswm-text'>- {discolourByDirtStaining}:</p></Section>
                      </Grid.Column>
                      <Grid.Column>
                        <p className='warranty-bswm-text is-year'><span className='warranty-bswm-text is-bold'>{discolourByDirtStainingWallYear}</span> Years</p>
                      </Grid.Column>
                    </Grid>
                  }
                </Grid.Column>
              }
            </Grid>
          </Section>
        </ShapeContainer>
      </WarrantyProductInformationWrapper>
    )
  }

  static defaultProps = {
    // If you set new default props, you should create propsType for correct styleguide document.
    registeredTrademarkName: '< Registered Trademark Name >',
    customerName: '< Customer Name >',
    projectName: '< Full Project Name >',
    projectAddress: '< Full Project Address >',
    perforateByCorrosion: 'Shall not perforate by corrosion for',
    perforateByCorrosionYear: 'XX',
    perforateByCorrosionWallYear: 'XX',
    peelOrFlakeExcessively: 'Shall not peel or flake excessively for',
    peelOrFlakeExcessivelyYear: 'XX',
    peelOrFlakeExcessivelyWallYear: 'XX',
    colourFadeExcessively: 'Shall not colour fade excessively for',
    colourFadeExcessivelyYear: 'XX',
    colourFadeExcessivelyWallYear: 'XX',
    discolourByDirtStaining: 'Shall not discolour by dirt staining for',
    discolourByDirtStainingYear: 'XX',
    discolourByDirtStainingWallYear: 'XX',
  }

  static propTypes = {
    // TYPE > node, string, number, bool, array, object, symbol, func
  }
}
