import React from 'react';
import {
  connect,
} from 'react-redux';
import {
  ShapeContainer,
  Section,
  Grid,
  Button,
  Table,
  PaginationControl,
  TableAction,
  Text,
  Image,
} from './../../components'
import { redirect, redirectData } from '../../helpers/functions/route';
import { ROUTE_PATH } from '../../helpers/constants/route';
import { VARIABLES, ICONS } from '../../themes';
import { getSalePersonList, getClearSalePersonParams } from '../../helpers/services/salePerson';
import { onOpenModal, toast } from '../../helpers/functions/main';
import { MODAL_TYPE } from '../../helpers/constants/main';
import _ from 'lodash';
import { getLanguage } from '../../helpers/functions/language';
import { salePersonController } from '../../apiService/apiController/salePersonService';
import { isValidResponse } from '../../helpers/functions/validation';
import { getSortAscending } from '../../helpers/functions/data';
import { MAIN_REDUX_STATE_KEY } from '../../actions/mainAction';
import { ENUM } from '../../helpers/constants/enum';

class SettingSalePersonListContainer extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      activePage: 1,
      dataPerPage: 10
    }
  }

  componentDidMount() {
    const {
      userAuth,
    } = this.props;
    const {
      activePage,
      dataPerPage,
    } = this.state;
    const params = {
      ...getClearSalePersonParams(),
      page: activePage,
      limit: dataPerPage,
      ad_ref_id: userAuth.adRefId,
    };
    getSalePersonList(params);
  }

  salePersonList = {
    onClickSort: (target) => {
      getSalePersonList({
        isNoSort: false,
        sort: target
      });
    }
  }

  pagination = {
    onPaginationChange: (pageNumber) => {
      getSalePersonList({
        isNoSort: true,
        page: pageNumber,
      });
    }
  }

  render() {
    const {
      dataList,
      dataParams,
      totalData,
      activePage,
      userAuth,
    } = this.props;
    const {
      dataPerPage,
    } = this.state;
    return (
      <ShapeContainer
        ui='content'
        justify='space-between'
      >
        <Section spacingBottom='45'>
          <Table
            striped
            action
          >
            <Table.Section>
              <Table.Row>
                <Table.HeadColumn
                  noSort
                  maxWidth={VARIABLES.TABLE.SALES_PERSON.NUMBER}
                  minWidth={VARIABLES.TABLE.SALES_PERSON.NUMBER}
                >
                  {getLanguage('salesPerson.tables.salesPerson.number', '')}
                </Table.HeadColumn>
                <Table.HeadColumn
                  minWidth={VARIABLES.TABLE.SALES_PERSON.NAME}
                  sortAscending={getSortAscending(dataParams, 'first_name__c')}
                  onClickSort={() => {
                    this.salePersonList.onClickSort('first_name__c desc');
                  }}
                >
                  {getLanguage('salesPerson.tables.salesPerson.name', '')}
                </Table.HeadColumn>
                <Table.HeadColumn
                  minWidth={VARIABLES.TABLE.SALES_PERSON.NICK_NAME}
                  sortAscending={getSortAscending(dataParams, 'nick_name__c')}
                  onClickSort={() => {
                    this.salePersonList.onClickSort('nick_name__c desc');
                  }}
                >
                  {getLanguage('salesPerson.tables.salesPerson.nickName', '')}
                </Table.HeadColumn>
                <Table.HeadColumn
                  minWidth={VARIABLES.TABLE.SALES_PERSON.JOB_POSITION}
                  sortAscending={getSortAscending(dataParams, 'position__c')}
                  onClickSort={() => {
                    this.salePersonList.onClickSort('position__c desc');
                  }}
                >
                  {getLanguage('salesPerson.tables.salesPerson.jobPosition', '')}
                </Table.HeadColumn>
                <Table.HeadColumn
                  minWidth={VARIABLES.TABLE.SALES_PERSON.MOBILE}
                  sortAscending={getSortAscending(dataParams, 'phone__c')}
                  onClickSort={() => {
                    this.salePersonList.onClickSort('phone__c desc');
                  }}
                >
                  {getLanguage('salesPerson.tables.salesPerson.mobile', '')}
                </Table.HeadColumn>
                <Table.HeadColumn
                  noSort
                  minWidth={VARIABLES.TABLE.SALES_PERSON.POINTS}
                >
                  {getLanguage('salesPerson.tables.salesPerson.points', '')}
                </Table.HeadColumn>
                <Table.HeadColumn
                  alignCenter
                  noSort
                  maxWidth={VARIABLES.TABLE.SALES_PERSON.ACTIONS}
                  minWidth={VARIABLES.TABLE.SALES_PERSON.ACTIONS}
                >
                  {getLanguage('salesPerson.tables.salesPerson.actions', '')}
                </Table.HeadColumn>
              </Table.Row>
            </Table.Section>
            <Table.Section>
              {dataList.map((e, i) => {
                return (
                  <Table.Row key={i}>
                    <Table.BodyColumn
                      info
                      maxWidth={VARIABLES.TABLE.SALES_PERSON.NUMBER}
                      minWidth={VARIABLES.TABLE.SALES_PERSON.NUMBER}
                      title={''}
                    >
                      {(((activePage || 1) - 1) * dataPerPage) + (i + 1)}
                    </Table.BodyColumn>
                    <Table.BodyColumn
                      info
                      minWidth={VARIABLES.TABLE.SALES_PERSON.NAME}
                      title={''}
                    >
                      {e.fullname__c}
                    </Table.BodyColumn>
                    <Table.BodyColumn
                      info
                      minWidth={VARIABLES.TABLE.SALES_PERSON.NICK_NAME}
                      title={''}
                    >
                      {e.nickname__c}
                    </Table.BodyColumn>
                    <Table.BodyColumn
                      info
                      minWidth={VARIABLES.TABLE.SALES_PERSON.JOB_POSITION}
                      title={e.position__c || '-'}
                    >
                      {e.position__c || '-'}
                    </Table.BodyColumn>
                    <Table.BodyColumn
                      phone
                      minWidth={VARIABLES.TABLE.SALES_PERSON.MOBILE}
                      idPhone={e.phoneNoCountry.value}
                      srcFlag={e.phoneNoCountry.flag}
                    >
                      {e.phoneNoPrefix__c}
                    </Table.BodyColumn>
                    <Table.BodyColumn
                      info
                      minWidth={VARIABLES.TABLE.SALES_PERSON.POINTS}
                      title={''}
                    >
                      {e.pointsWithComma}
                    </Table.BodyColumn>
                    <Table.BodyColumn
                      action
                      alignCenter
                      maxWidth={VARIABLES.TABLE.SALES_PERSON.ACTIONS}
                      minWidth={VARIABLES.TABLE.SALES_PERSON.ACTIONS}
                    >
                      {userAuth.role === ENUM.USER_ROLE.OWNER &&
                        <Grid
                          gutter='15'
                          justify='center'
                        >
                          <Grid.Column>
                            <Image
                              title={getLanguage('salesPerson.tooltips.edit', '')}
                              ui='iconActionEdit'
                              onClick={() => {
                                redirectData(`${ROUTE_PATH.SETTING_SALES_PERSON_MANAGE.LINK}/${e.id}`, {
                                  data: e
                                });
                              }}
                            />
                          </Grid.Column>
                        </Grid>
                      }
                    </Table.BodyColumn>
                  </Table.Row>
                )
              })}
            </Table.Section>
          </Table>
        </Section>
        <TableAction>
          <TableAction.Left />
          <TableAction.Right>
            <Grid gutter='20'>
              <Grid.Column>
                <Section paddingTop='6'>
                  <Text
                    regular12
                    color={VARIABLES.COLORS.GRAY_16}
                  >
                    {getLanguage('pagination.page', '')} {activePage}/{Math.ceil(totalData / dataPerPage)}
                  </Text>
                </Section>
              </Grid.Column>
              <Grid.Column>
                <PaginationControl
                  activePage={activePage}
                  itemsCountPerPage={dataPerPage}
                  totalItemsCount={totalData}
                  pageRangeDisplayed={3}
                  onChange={this.pagination.onPaginationChange}
                />
              </Grid.Column>
            </Grid>
          </TableAction.Right>
        </TableAction>
      </ShapeContainer>
    )
  }
}

const mapStateToProps = (state) => {
  const dataRedux = state.dataRedux;
  const mainRedux = state.mainRedux;
  return {
    dataList: dataRedux.salePersonList,
    dataParams: dataRedux.salePersonParams,
    activePage: dataRedux.salePersonParams.activePage,
    totalData: dataRedux.salePersonParams.totalData || 0,
    userAuth: mainRedux[MAIN_REDUX_STATE_KEY.USER_AUTH],
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
  }
}

export const SettingSalePersonListContainerConnected = connect(
  mapStateToProps,
  mapDispatchToProps
)(SettingSalePersonListContainer)