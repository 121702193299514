import apiService from '../apiService'

const apiPath = '/report'

export const reportController = (configService = {}) => {
  const service = apiService(configService)
  return {
    getReport: (params) => {
      return service.post(`${apiPath}/search`, params)
    },
  }
}
