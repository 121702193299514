import React from 'react'
import {
  Section,
  Grid,
  Modal,
  Text,
  Button,
  Field
} from './../../../components'
import {
  VARIABLES,
} from './../../../themes'
import { TncContainerConnected } from '../../TncContainer'

export class ModalTermsAndConditionsContainer extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isAcceptedTnc: false,
    }
  }

  input = {
    onChangeCheckbox: (ev) => {
      const checked = ev.target.checked;
      this.setState({
        isAcceptedTnc: checked,
      });
    },
  }

  render() {
    const {
      open,
      onClickClose,
      onClickAccept,
    } = this.props
    const {
      isAcceptedTnc,
    } = this.state;
    return (
      <Modal
        open={open}
        scroll
        // enableOverlayClose={false}
        width={VARIABLES.MODAL.WIDTHS.W_1145}
        overlayColor={VARIABLES.COLORS.OVERLAY_BLACK_1}
        spacing={VARIABLES.MODAL.CONTAINER.C_1.SPACING}
        transition={VARIABLES.TRANSITIONS.DEFAULT}
        transformStart={VARIABLES.MODAL.CONTAINER.C_1.TRANSFORM_START}
        transformEnd={VARIABLES.MODAL.CONTAINER.C_1.TRANSFORM_END}
      // onClickClose={onClickClose}
      >
        <Modal.Section
          paddingTop='55'
          paddingBottom='35'
          paddingHorizontal={VARIABLES.MODAL.BODY.B_1.PADDING_HORIZONTAL}
          bgColor={VARIABLES.COLORS.WHITE}
          borderTopRightRadius={VARIABLES.MODAL.HEADER.H_1.BORDER_TOP_RIGHT_RADIUS}
          borderTopLeftRadius={VARIABLES.MODAL.HEADER.H_1.BORDER_TOP_LEFT_RADIUS}
        >
          <Section
            spacingBottom='50'
            justify='center'
          >
            <Text
              bold24
              color={VARIABLES.COLORS.GRAY_17}
              align='center'
            >
              Terms and conditions
            </Text>
          </Section>
          <TncContainerConnected />
          <Section
            paddingTop='45'
            justify='center'
          >
            <Field
              checked={isAcceptedTnc}
              ui='checkboxTick'
              checkboxLabel='I have read and accept Terms and conditions'
              onChange={this.input.onChangeCheckbox}
            />
          </Section>
        </Modal.Section>
        <Modal.Section
          paddingTop={VARIABLES.MODAL.FOOTER.F_1.PADDING_TOP}
          paddingBottom={VARIABLES.MODAL.FOOTER.F_1.PADDING_BOTTOM}
          paddingHorizontal={VARIABLES.MODAL.FOOTER.F_1.PADDING_HORIZONTAL}
          borderTopWidth={VARIABLES.MODAL.FOOTER.F_1.BORDER_TOP_WIDTH}
          borderTopStyle={VARIABLES.MODAL.FOOTER.F_1.BORDER_TOP_STYLE}
          borderTopColor={VARIABLES.COLORS.GRAY_8}
          borderBottomRightRadius={VARIABLES.MODAL.FOOTER.F_1.BORDER_BOTTOM_RIGHT_RADIUS}
          borderBottomLeftRadius={VARIABLES.MODAL.FOOTER.F_1.BORDER_BOTTOM_LEFT_RADIUS}
        >
          <Grid
            gutter='35'
            justify='center'
          >
            <Grid.Column>
              <Button
                gray
                width={VARIABLES.BUTTON.WIDTHS.W_135}
                name='Back'
                onClick={onClickClose}
              />
            </Grid.Column>
            <Grid.Column>
              <Button
                disabled={!isAcceptedTnc}
                lightBlue
                width={VARIABLES.BUTTON.WIDTHS.W_135}
                name='Accept'
                onClick={onClickAccept}
              />
            </Grid.Column>
          </Grid>
        </Modal.Section>
      </Modal>
    )
  }
}
