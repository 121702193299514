import React from 'react'
import {
  ShapeContainer,
  Section,
  Breadcrumb,
  TitlePage,
  TableAction,
  Grid,
  Text,
  Button,
  PaginationControl,
  SelectControl,
  Field,
  DatePickerControl,
  Table,
  Image
} from './../../components'
import {
  VARIABLES,
  ICONS
} from './../../themes'
import { setSideMenuActive, getRouteParams, getRouteData } from '../../helpers/functions/route';
import { ROUTE_PATH } from '../../helpers/constants/route';
import { redirect } from '../../helpers/functions/route';
import { SaleTransactionHistoryListContainerConnected } from '../../containers/SaleTransactionHistoryListContainer';
import { getSaleTransList, getClearSaleTransParams } from '../../helpers/services/saleTrans';
import { getDateApiRequet, getMomentStartOf, getMomentEndOf } from '../../helpers/functions/data';
import _ from 'lodash';
import { getLanguage } from '../../helpers/functions/language';
import { DATE_PICKER_FORMAT } from '../../helpers/constants/data';
import { getUserAuthRedux } from '../../helpers/functions/auth';
import { ENUM } from '../../helpers/constants/enum';

export class SalesHistoryScreen extends React.Component {

  constructor(props) {
    super(props);
    setSideMenuActive(props);
    const userAuth = getUserAuthRedux();
    let customerId = getRouteParams(props, 'id');
    customerId = customerId ? parseInt(customerId) : '';
    const selectOptionList = [{
      value: '',
      label: getLanguage('salesHistory.selectOption.all', '')
    }, {
      value: 'salesTransactionID',
      label: getLanguage('salesHistory.selectOption.salesTransactionID', '')
    }, {
      value: 'customerName',
      label: getLanguage('salesHistory.selectOption.customerName', '')
    }, {
      value: 'phoneNo',
      label: getLanguage('salesHistory.selectOption.phoneNumber', '')
    }];
    this.state = {
      searchValue: '',
      customerId,
      selectOptionList,
      selectedOption: selectOptionList[0],
      startDate: new Date(),
      endDate: new Date(),
      isIndoToko: userAuth.addressType === ENUM.COUNTRY_TYPE.INDONESIA && (
        userAuth.customerRoleType === ENUM.CUSTOMER_ROLE_TYPE.INDONESIA.TOKO ||
        userAuth.customerRoleType === ENUM.CUSTOMER_ROLE_TYPE.INDONESIA.DISTRIBUTOR
      ),
      isMalay: userAuth.addressType === ENUM.COUNTRY_TYPE.MALAYSIA,
    }
  }

  input = {
    onChangeInput: (ev) => {
      const name = ev.target.name;
      const value = ev.target.value;
      this.setState({
        [name]: value,
      })
    },
    onClickSearchText: () => {
      const {
        searchValue,
        selectedOption,
        selectOptionList,
        customerId
      } = this.state;
      const searchType = selectedOption.value;
      let params = {
        ...getClearSaleTransParams(),
        page: 1,
        customer_id: customerId,
      };
      switch (searchType) {
        case selectOptionList[0].value:
          params.all_field = searchValue;
          break;
        case selectOptionList[1].value:
          params.order_no = searchValue;
          break;
        case selectOptionList[2].value:
          params.customer_name = searchValue;
          break;
        case selectOptionList[3].value:
          params.phone_no = searchValue;
          break;
      }
      getSaleTransList(params);
    },
    onClickSearchDate: () => {
      const {
        startDate,
        endDate,
        customerId
      } = this.state;
      let params = {
        ...getClearSaleTransParams(),
        page: 1,
        customer_id: customerId,
        start_date: getDateApiRequet(startDate),
        end_date: getDateApiRequet(endDate),
      };
      getSaleTransList(params);
    },
    onChangeSearchOption: (selectedOption) => {
      this.setState({
        selectedOption
      })
    },
    onChangeDate: (date, name) => {
      this.setState({
        [name]: date
      })
    }
  }

  render() {
    const {
      selectedOption,
      searchValue,
      selectOptionList,
      startDate,
      endDate,
      customerId,
      isIndoToko,
      isMalay
    } = this.state

    return (
      <React.Fragment>
        <Breadcrumb>
          <Breadcrumb.List
            link
            name={getLanguage('salesHistory.breadcrumb1', '')}
            onClick={() => {
              redirect(ROUTE_PATH.HOME.LINK)
            }}
          />
          <Breadcrumb.List name='/' />
          <Breadcrumb.List
            link
            name={getLanguage('salesHistory.breadcrumb2', '')}
          // onClick={() => {
          //   redirect(ROUTE_PATH.SALES_TRANSACTION.LINK)
          // }}
          />
          <Breadcrumb.List name='/' />
          <Breadcrumb.List name={getLanguage('salesHistory.breadcrumb3', '')} />
        </Breadcrumb>
        <Section ui='content'>
          <Section spacingBottom='35'>
            <TitlePage
              src={ICONS['ic-page-sales-transaction.svg']}
              name={getLanguage('salesHistory.title', '')}
            />
          </Section>
          <Section spacingBottom='20'>
            <ShapeContainer
              fluid
              className='search-control'
              paddingTop='20'
              paddingBottom='18'
              paddingHorizontal='20'
              borderWidth='1'
              borderStyle='solid'
              borderColor={VARIABLES.COLORS.PRIMARY_1}
              borderRadius='10'
            >
              <Grid
                className='search-control-container'
                gutter='30'
                gutterVertical='20'
              >
                <Grid.Column
                  className='search-control-group-left'
                  flex='1'
                >
                  <Grid gutter='10'>
                    <Grid.Column flex='2'>
                      <Field name='searchValue'
                        fluid
                        ui='blockLabelVerticalSearch'
                        placeholder={getLanguage('salesHistory.placeholder', '')}
                        value={searchValue}
                        onChange={this.input.onChangeInput}
                      />
                    </Grid.Column>
                    <Grid.Column flex='none'>
                      <Section zIndexLv3>
                        <SelectControl
                          verticalLabel
                          filterFluid
                          width='150'
                          isSearchable={false}
                          value={selectedOption}
                          onChange={this.input.onChangeSearchOption}
                          options={selectOptionList}
                        />
                      </Section>
                    </Grid.Column>
                    <Grid.Column flex='none'>
                      <Button
                        lightBlue
                        name={getLanguage('salesHistory.buttonSearch', '')}
                        width={VARIABLES.BUTTON.WIDTHS.W_105}
                        onClick={this.input.onClickSearchText}
                      />
                    </Grid.Column>
                  </Grid>
                </Grid.Column>
                <Grid.Column
                  className='search-control-partition'
                  flex='none'
                >
                  <ShapeContainer
                    width='1'
                    height='32'
                    bgColor={VARIABLES.COLORS.PRIMARY_1}
                  />
                </Grid.Column>
                <Grid.Column
                  className='search-control-group-right'
                  flex='1'
                >
                  <Grid gutter='20'>
                    <Grid.Column flex='none'>
                      <Section paddingTop='6'>
                        <Text
                          regular12
                          color={VARIABLES.COLORS.BLACK}
                        >
                          {getLanguage('salesHistory.date', '')}
                        </Text>
                      </Section>
                    </Grid.Column>
                    <Grid.Column flex='1'>
                      <Grid gutter='10'>
                        <Grid.Column flex='1'>
                          <Grid gutter='15'>
                            <Grid.Column flex='1'>
                              <DatePickerControl
                                filter
                                bottom
                                dateFormat={DATE_PICKER_FORMAT}
                                placeholderText={getLanguage('salesHistory.selectStartDate', '')}
                                selected={startDate}
                                onChange={(date) => {
                                  this.input.onChangeDate(date, 'startDate');
                                }}
                              />
                            </Grid.Column>
                            <Grid.Column flex='none'>
                              <Section paddingTop='6'>
                                <Text
                                  regular12
                                  color={VARIABLES.COLORS.GRAY_19}
                                >
                                  {getLanguage('salesHistory.dateTo', '')}
                                </Text>
                              </Section>
                            </Grid.Column>
                            <Grid.Column flex='1'>
                              <DatePickerControl
                                filter
                                bottom
                                dateFormat={DATE_PICKER_FORMAT}
                                placeholderText={getLanguage('salesHistory.selectEndDate', '')}
                                selected={endDate}
                                onChange={(date) => {
                                  this.input.onChangeDate(date, 'endDate');
                                }}
                              />
                            </Grid.Column>
                          </Grid>
                        </Grid.Column>
                        <Grid.Column flex='none'>
                          <Button
                            lightBlue
                            name={getLanguage('salesHistory.buttonSearch', '')}
                            width={VARIABLES.BUTTON.WIDTHS.W_105}
                            onClick={this.input.onClickSearchDate}
                          />
                        </Grid.Column>
                      </Grid>
                    </Grid.Column>
                  </Grid>
                </Grid.Column>
              </Grid>
            </ShapeContainer>
          </Section>
          <SaleTransactionHistoryListContainerConnected
            customerId={customerId}
            isIndoToko={isIndoToko}
            isMalay={isMalay}
          />
        </Section>
      </React.Fragment>
    )
  }
}
