import React from 'react';
import {
  ShapeContainer,
  Section,
  Grid,
  Table,
  PaginationControl,
  TableAction,
  Text,
  Image,
  Button,
  Field,
} from './../../components';
import {
  VARIABLES,
  ICONS,
} from './../../themes';
import { redirect, redirectData } from '../../helpers/functions/route';
import { ROUTE_PATH } from '../../helpers/constants/route';
import { customerController } from '../../apiService/apiController/customerService';
import { isValidResponse } from '../../helpers/functions/validation';
import { getCustomerExportList, getCustomerList, getCustomerManageParams } from '../../helpers/services/customer';
import { toast, onOpenModal } from '../../helpers/functions/main';
import { MODAL_TYPE } from '../../helpers/constants/main';
import { firebaseDeleteFile } from '../../helpers/firebases/firebaseStorage';
import { getCustomerTrainedTier, getSortAscending } from '../../helpers/functions/data';
import _ from 'lodash';
import { getLanguage } from '../../helpers/functions/language';
import { isRolePermission } from '../../helpers/functions/permission';
import { PERMISSION } from '../../helpers/constants/permission';
import { downloadExcel } from '../../helpers/functions/addon';
import html2pdf from 'html2pdf.js';
import moment from 'moment';
import { getLocalStorage } from '../../helpers/functions/localStorage';
import { LOCAL_STORAGE } from '../../helpers/constants/localStorage';
import { DATA } from '../../helpers/constants/data';
import 'moment/locale/th';
import 'moment/locale/en-gb';

export class CustomerTableContainer extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isExportPdf: true,
      isExportExcel: true,
      dataExportPdfList: [],
    }
  }

  customerList = {
    onClickDelete: (data) => {
      onOpenModal({
        type: MODAL_TYPE.CONFIRM_DELETE,
        message: getLanguage('message.confirmDeleteCustomer', ''),
        onClickConfirm: async () => {
          const {
            totalData,
            activePage,
            dataPerPage,
          } = this.props;
          const params = {
            id: data.id,
          };
          const customerService = customerController();
          const res = await customerService.deleteCustomerById(params);
          if (isValidResponse(res)) {
            firebaseDeleteFile(data.oldImageRef)
            toast.success(getLanguage('message.deleteCustomerCompleted', ''));
            getCustomerList({
              isNoSort: true,
              page: activePage > Math.ceil((totalData - 1) / dataPerPage) ? activePage - 1 : activePage,
            });
          }
          else {
            toast.error(res.message);
          }
        }
      })
    },
    onClickTrained: async (data) => {
      let params = getCustomerManageParams(data, data.id)
      params.trained = !data.isTrained;
      const customerService = customerController();
      const res = await customerService.updateCustomerInfo(params);
      if (isValidResponse(res)) {
        getCustomerList({
          isNoSort: true,
        });
        toast.success(getLanguage('message.saveCompleted', ''));
      }
      else {
        toast.error(res.message);
      }
    },
    onClickExport: async () => {
      const {
        isExportPdf,
        isExportExcel,
      } = this.state;
      const dataList = await getCustomerExportList();
      const dataExportList = dataList.map((e, i) => {
        return {
          'No': i + 1,
          'Name': e.fullname,
          'Nick name': e.nickname,
          'Mobile': `+${e.phoneCountry.value} ${e.phone}`,
          'Member type': e.typeText,
          'Points will expire': e.expirePointWithComma,
          'Total points': e.remainPointsWithComma,
        }
      })
      if (isExportPdf) {
        const dataExportPdfList = [];
        let row = 0;
        let cntData = 0;
        dataExportList.forEach(e => {
          if (dataExportPdfList[row]) {
            dataExportPdfList[row].push(e);
          }
          else {
            dataExportPdfList[row] = [e];
          }
          cntData++;
          if (cntData === 15) {
            cntData = 0;
            row++;
          }
        })
        this.setState({
          dataExportPdfList,
        }, () => {
          const dom = this.refs.refTemplatePdf;
          html2pdf().set({
            margin: [6, 6],
            pagebreak: { after: ['.table-break'] },
            jsPDF: {
              orientation: 'l',
            }
          }).from(dom).save(`customer.pdf`);
        })
      }
      if (isExportExcel) {
        downloadExcel([
          {
            name: 'customer',
            data: dataExportList
          },
        ], 'customer');
      }
    },
    getExpiringPointDate: () => {
      const currentMonth = moment().month() + 1;
      const currentYear = moment().year();
      let language = getLocalStorage(LOCAL_STORAGE.LANGUAGE_RBPTRUEBLUE);
      if (language !== DATA.LANGUAGE.THAI) {
        language = 'en-gb';
      }
      let date = `31/12/${currentYear}`;
      if (currentMonth <= 3) {
        date = `31/3/${currentYear}`;
      } else if (currentMonth <= 6) {
        date = `30/6/${currentYear}`;
      } else if (currentMonth <= 9) {
        date = `30/9/${currentYear}`;
      }
      const expiringDate = moment(date, 'DD/MM/YYYY').locale(language).format('DD MMM');
      moment().locale('en-gb');
      return expiringDate;
    },
  }

  input = {
    onChangeExport: (ev) => {
      const name = ev.target.name;
      const value = ev.target.checked;
      this.setState({
        [name]: value
      })
    }
  }

  render() {
    const {
      dataList,
      dataParams,
      totalData,
      activePage,
      dataPerPage,
      onClickSort,
      onPaginationChange,
      isMalay,
    } = this.props;
    const {
      isExportPdf,
      isExportExcel,
      dataExportPdfList
    } = this.state;
    return (
      <React.Fragment>
        <ShapeContainer
          ui='content'
          justify='space-between'
        >
          <Section spacingBottom='45'>
            <Table
              striped
              action
            >
              <Table.Section>
                <Table.Row>
                  <Table.HeadColumn
                    maxWidth={VARIABLES.TABLE.INFO_WIDTHS.ID}
                    minWidth={VARIABLES.TABLE.INFO_WIDTHS.ID}
                    sortAscending={getSortAscending(dataParams, 'id')}
                    onClickSort={() => {
                      onClickSort('id desc');
                    }}
                  >
                    {getLanguage('customers.table.header.id', '')}
                  </Table.HeadColumn>
                  <Table.HeadColumn
                    minWidth={VARIABLES.TABLE.INFO_WIDTHS.NAME}
                    sortAscending={getSortAscending(dataParams, 'first_name')}
                    onClickSort={() => {
                      onClickSort('first_name desc');
                    }}
                  >
                    {getLanguage('customers.table.header.name', '')}
                  </Table.HeadColumn>
                  <Table.HeadColumn
                    minWidth={VARIABLES.TABLE.INFO_WIDTHS.NICKNAME}
                    sortAscending={getSortAscending(dataParams, 'nick_name')}
                    onClickSort={() => {
                      onClickSort('nick_name desc');
                    }}
                  >
                    {getLanguage('customers.table.header.nickname', '')}
                  </Table.HeadColumn>
                  <Table.HeadColumn
                    minWidth={VARIABLES.TABLE.INFO_WIDTHS.PHONE}
                    sortAscending={getSortAscending(dataParams, 'phone')}
                    onClickSort={() => {
                      onClickSort('phone desc');
                    }}
                  >
                    {getLanguage('customers.table.header.phoneNo', '')}
                  </Table.HeadColumn>
                  {isMalay ?
                    undefined :
                    <React.Fragment>
                      <Table.HeadColumn
                        alignRight
                        minWidth={VARIABLES.TABLE.INFO_WIDTHS.TRUEBLUE_POINTS}
                        // sortAscending={getSortAscending(dataParams, 'remain_point')}
                        // onClickSort={() => {
                        //   onClickSort('remain_point desc');
                        // }}
                        noSort
                      >
                        {getLanguage('customers.table.header.points', '')}
                      </Table.HeadColumn>
                      <Table.HeadColumn
                        alignRight
                        minWidth={VARIABLES.TABLE.INFO_WIDTHS.EXPIRING_TRUEBLUE_POINTS_IN_THIS_QUARTER}
                        noSort
                      // sortAscending={getSortAscending(dataParams, 'expire_point')}
                      // onClickSort={() => {
                      //   onClickSort('expire_point desc');
                      // }}
                      >
                        {getLanguage('customers.table.header.expiringPoints', '')}
                      </Table.HeadColumn>
                    </React.Fragment>
                  }
                  <Table.HeadColumn
                    alignCenter
                    minWidth={VARIABLES.TABLE.INFO_WIDTHS.TRAINED}
                    sortAscending={getSortAscending(dataParams, 'trained')}
                    onClickSort={() => {
                      onClickSort('trained desc');
                    }}
                  >
                    {getLanguage('customers.table.header.trained', '')}
                  </Table.HeadColumn>
                  <Table.HeadColumn
                    minWidth={VARIABLES.TABLE.INFO_WIDTHS.LEVEL}
                    sortAscending={getSortAscending(dataParams, 'trained_tier__c')}
                    onClickSort={() => {
                      onClickSort('trained_tier__c desc');
                    }}
                  >
                    {getLanguage('customers.table.header.level', '')}
                  </Table.HeadColumn>
                  <Table.HeadColumn
                    alignCenter
                    noSort
                    maxWidth={VARIABLES.TABLE.INFO_WIDTHS.ACTION_CUSTOMER}
                    minWidth={VARIABLES.TABLE.INFO_WIDTHS.ACTION_CUSTOMER}
                  >
                    {getLanguage('customers.table.header.action', '')}
                  </Table.HeadColumn>
                </Table.Row>
              </Table.Section>
              <Table.Section>
                {
                  dataList.map((e, i) => {
                    const customerTrainedTier = getCustomerTrainedTier(e.trainedTier__c);
                    return (
                      <Table.Row
                        key={i}
                      >
                        <Table.BodyColumn
                          info
                          maxWidth={VARIABLES.TABLE.INFO_WIDTHS.ID}
                          minWidth={VARIABLES.TABLE.INFO_WIDTHS.ID}
                          title={e.idWithPrefix}
                        >
                          {e.idWithPrefix}
                        </Table.BodyColumn>
                        <Table.BodyColumn
                          info
                          minWidth={VARIABLES.TABLE.INFO_WIDTHS.NAME}
                          title={e.fullname}
                        >
                          {e.fullname}
                        </Table.BodyColumn>
                        <Table.BodyColumn
                          info
                          minWidth={VARIABLES.TABLE.INFO_WIDTHS.NICKNAME}
                          title={e.nickname}
                        >
                          {e.nickname}
                        </Table.BodyColumn>
                        <Table.BodyColumn
                          phone
                          minWidth={VARIABLES.TABLE.INFO_WIDTHS.PHONE}
                          idPhone={e.phoneCountry.value}
                          srcFlag={e.phoneCountry.flag}
                        >
                          {e.phone}
                        </Table.BodyColumn>
                        {isMalay ?
                          undefined
                          :
                          <React.Fragment>
                            <Table.BodyColumn
                              info
                              alignRight
                              minWidth={VARIABLES.TABLE.INFO_WIDTHS.TRUEBLUE_POINTS}
                              title={e.remainPointsWithComma}
                            >
                              {e.remainPointsWithComma}
                            </Table.BodyColumn>
                            <Table.BodyColumn
                              info
                              alignRight
                              minWidth={VARIABLES.TABLE.INFO_WIDTHS.EXPIRING_TRUEBLUE_POINTS_IN_THIS_QUARTER}
                              title={e.expirePointWithComma}
                            >
                              {e.expirePointWithComma}
                            </Table.BodyColumn>
                          </React.Fragment>
                        }
                        <Table.BodyColumn
                          info
                          alignCenter
                          minWidth={VARIABLES.TABLE.INFO_WIDTHS.TRAINED}
                        >
                          {e.isTrained ? <Image ui='iconChecked' /> : '-'}
                        </Table.BodyColumn>
                        <Table.BodyColumn
                          action
                          minWidth={VARIABLES.TABLE.INFO_WIDTHS.LEVEL}
                        >
                          {customerTrainedTier &&
                            <React.Fragment>
                              <div className='icon-medal'>
                                <Image ui={customerTrainedTier.ui} widthRatio='24' heightRatio='24' top='-1' />
                              </div>
                              <Text regular12 top='1' left='5' color={customerTrainedTier.textColor}>
                                {customerTrainedTier.text}
                              </Text>
                            </React.Fragment>
                          }
                        </Table.BodyColumn>
                        <Table.BodyColumn
                          action
                          alignCenter
                          maxWidth={VARIABLES.TABLE.INFO_WIDTHS.ACTION_CUSTOMER}
                          minWidth={VARIABLES.TABLE.INFO_WIDTHS.ACTION_CUSTOMER}
                        >
                          <Grid
                            gutter='15'
                            justify='center'
                          >
                            {isRolePermission(PERMISSION.CUSTOMER.BUTTON.SALE_HISTORY) &&
                              <Grid.Column>
                                <Image
                                  title='Sales History'
                                  ui='iconActionSalesHistory'
                                  onClick={() => {
                                    redirect(`${ROUTE_PATH.SALES_HISTORY.LINK}/${e.id}`);
                                  }}
                                />
                              </Grid.Column>
                            }
                            <Grid.Column>
                              <Image
                                title='Points Detail'
                                ui='iconActionPointsDetail'
                                onClick={() => {
                                  redirectData(`${ROUTE_PATH.POINTS_DETAIL_MANAGEMENT.LINK}/${e.id}`, {
                                    data: e
                                  });
                                }}
                              />
                            </Grid.Column>
                            <Grid.Column>
                              <Image
                                title='Reward Redemption History'
                                ui='iconActionRewardRedemptionHistory'
                                onClick={() => {
                                  redirect(`${ROUTE_PATH.REWARD_REDEMPTION_HISTORY.LINK}/${e.id}`);
                                }}
                              />
                            </Grid.Column>
                            <Grid.Column>
                              <Image
                                title='Edit'
                                ui='iconActionEdit'
                                onClick={() => {
                                  redirectData(`${ROUTE_PATH.CUSTOMERS_MANAGE.LINK}/${e.id}`, {
                                    data: e
                                  })
                                }}
                              />
                            </Grid.Column>
                          </Grid>
                        </Table.BodyColumn>
                      </Table.Row>
                    )
                  })
                }
              </Table.Section>
            </Table>
          </Section>
          <TableAction className='is-vertical-align-bottom'>
            <TableAction.Left>
              <ShapeContainer
                padding='15'
                // bgColor={notificationActive ? VARIABLES.COLORS.GRAY_1 : VARIABLES.COLORS.WHITE}
                borderWidth='1'
                borderStyle='solid'
                borderColor={VARIABLES.COLORS.GREEN_4}
                borderRadius='3'
              >
                <Grid gutter='30' align='center'>
                  <Grid.Column>
                    {false &&
                      <Section spacingBottom='8'>
                        <Grid gutter='15'>
                          <Grid.Column>
                            <Section width='70'>
                              <Field
                                ui='checkboxTick'
                                name={''}
                                checked={true}
                                checkboxLabel={getLanguage('customers.checkboxs.all', '')}
                                onChange={() => { }}
                              />
                            </Section>
                          </Grid.Column>
                          <Grid.Column>
                            <Field
                              ui='checkboxTick'
                              name={''}
                              checked={true}
                              checkboxLabel={getLanguage('customers.checkboxs.onlyMemberPointsExpire', '')}
                              onChange={() => { }}
                            />
                          </Grid.Column>
                        </Grid>
                      </Section>
                    }
                    <Grid gutter='15'>
                      <Grid.Column>
                        <Section width='70'>
                          <Field
                            ui='checkboxTick'
                            name='isExportPdf'
                            checked={isExportPdf}
                            checkboxLabel={getLanguage('customers.checkboxs.pdf', '')}
                            onChange={this.input.onChangeExport}
                          />
                        </Section>
                      </Grid.Column>
                      <Grid.Column>
                        <Field
                          ui='checkboxTick'
                          name='isExportExcel'
                          checked={isExportExcel}
                          checkboxLabel={getLanguage('customers.checkboxs.excel', '')}
                          onChange={this.input.onChangeExport}
                        />
                      </Grid.Column>
                    </Grid>
                  </Grid.Column>
                  <Grid.Column>
                    <Button
                      green
                      ui='frontIcon'
                      name='Export'
                      width={VARIABLES.BUTTON.WIDTHS.W_125}
                      iconWidth='16'
                      iconHeight='16'
                      iconSrc={ICONS['ic-export.svg']}
                      onClick={this.customerList.onClickExport}
                    />
                  </Grid.Column>
                </Grid>
              </ShapeContainer>
            </TableAction.Left>
            <TableAction.Right>
              <Grid gutter='20'>
                <Grid.Column>
                  <Section paddingTop='6'>
                    <Text
                      regular12
                      color={VARIABLES.COLORS.GRAY_16}
                    >
                      {getLanguage('pagination.page', '')} {activePage}/{Math.ceil(totalData / dataPerPage)}
                    </Text>
                  </Section>
                </Grid.Column>
                <Grid.Column>
                  <PaginationControl
                    activePage={activePage}
                    itemsCountPerPage={dataPerPage}
                    totalItemsCount={totalData}
                    pageRangeDisplayed={3}
                    onChange={onPaginationChange}
                  />
                </Grid.Column>
              </Grid>
            </TableAction.Right>
          </TableAction>
        </ShapeContainer>

        {/* Remove 'is-hidden-for-generate-pdf' for display template */}
        <Section className='is-generate-pdf-container is-hidden-for-generate-pdf' padding='30'>
          <div ref="refTemplatePdf">
            {dataExportPdfList.map((list, iList) => {
              return (
                <div style={{ height: '748px', paddingTop: '15px' }}>
                  <table key={iList} className='is-border is-customer table-break' style={{ width: '1024px' }}>
                    <thead>
                      <tr>
                        <th className='is-number'>{getLanguage('customers.table.header.no', '')}</th>
                        <th className='is-name'>{getLanguage('customers.table.header.name', '')}</th>
                        <th className='is-nickname'>{getLanguage('customers.table.header.nickname', '')}</th>
                        <th className='is-phone'>{getLanguage('customers.table.header.phoneNo', '')}</th>
                        <th className='is-member-type'>{getLanguage('customers.table.header.memberType', '')}</th>
                        <th className='is-point'>{getLanguage('customers.table.header.points', '')}</th>
                        <th className=''>{`${getLanguage('customers.table.header.expiringPoints', '')} ${this.customerList.getExpiringPointDate()}`}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {list.map((item, iItem) => {
                        return (
                          <tr key={iItem}>
                            <td className='is-number'>{item['No']}</td>
                            <td className='is-name'>{item['Name']}</td>
                            <td className='is-nickname'>{item['Nick name']}</td>
                            <td className='is-phone'>{item['Mobile']}</td>
                            <td className='is-member-type'>{item['Member type']}</td>
                            <td className='is-point'>{item['Total points']}</td>
                            <td className='is-exp'>{item['Points will expire']}</td>
                          </tr>
                        )
                      })
                      }
                    </tbody>
                  </table>
                </div>
              )
            })}
          </div>
        </Section>
      </React.Fragment>
    )
  }
}