import React from 'react'
import {
  ShapeContainer,
  Section,
  Breadcrumb,
  TitlePage,
  Grid,
  Field,
  Text,
  Button,
  Table,
  TableAction,
  Image,
  PaginationControl,
  TitleSection,
} from './../../components'
import {
  VARIABLES,
  ICONS
} from './../../themes'
import { ROUTE_PATH } from '../../helpers/constants/route';
import { getRouteData, getRouteParams, redirect } from '../../helpers/functions/route';
import _ from 'lodash';
import { getLanguage } from '../../helpers/functions/language';
import { DocumentaryDetailListContainerConnected } from '../../containers/DocumentaryDetailListContainer';
import { ENUM } from '../../helpers/constants/enum';
import { ModalSocialShareContainer } from '../../containers/ModalContainer';
import { getClearDocumentaryDetailParams, getDocumentaryDetailList } from '../../helpers/services/documentary';

export class DocumentaryViewListScreen extends React.Component {

  constructor(props) {
    super(props);
    const dataId = parseInt(getRouteParams(props, 'id'));
    const routeData = getRouteData(props, 'data') || {};
    this.state = {
      dataId,
      isViewDocument: false,
      isOpenShareModal: false,
      data: routeData,
      searchValue: '',
    }
  }

  documentaryViewList = {
    goBack: () => {
      redirect(ROUTE_PATH.DOCUMENTARY.LINK);
    },
    viewGoBack: () => {
      this.setState({
        isViewDocument: false,
      })
    },
    onClickView: (item) => {
      this.setState({
        isViewDocument: true,
        viewData: item,
      })
    },
    onClickShare: () => {
      const {
        viewData,
      } = this.state;
      let emailSubject = viewData.docSpCompName;
      let emailBody = `Please check below link for warranty claim: ${viewData.fileUrl}\r\n\r\nPlease do not reply this email. If you have any question or enquiry please contact 02-333-3030`
      console.log('emailSubject', emailSubject)
      this.setState({
        emailSubject,
        emailBody,
        isOpenShareModal: true,
      });
    },
    onCloseShareModal: () => {
      this.setState({
        isOpenShareModal: false,
      });
    },
    onClickDownload: () => {
      const {
        viewData,
      } = this.state;
      window.open(viewData.fileUrl, '_blank')
    },
    onClickSearch: () => {
      const {
        searchValue,
      } = this.state;
      let params = {
        ...getClearDocumentaryDetailParams(),
        page: 1,
        textSearch: searchValue
      };
      getDocumentaryDetailList(params);
    }
  }

  input = {
    onChangeInput: (ev) => {
      const name = ev.target.name;
      const value = ev.target.value;
      this.setState({
        [name]: value
      })
    }
  }

  render() {
    const {
      dataId,
      isViewDocument,
      isOpenShareModal,
      viewData,
      emailBody,
      emailSubject,
      data,
      searchValue
    } = this.state

    return (
      <React.Fragment>
        <Breadcrumb>
          <Breadcrumb.List
            link
            name={getLanguage('documentaryManage.breadcrumb1', '')}
            onClick={() => {
              redirect(ROUTE_PATH.HOME.LINK)
            }}
          />
          <Breadcrumb.List name='/' />
          <Breadcrumb.List
            link
            name={getLanguage('documentaryManage.breadcrumb2', '')}
            onClick={this.documentaryViewList.goBack}
          />
          <Breadcrumb.List name='/' />
          <Breadcrumb.List
            link={isViewDocument}
            name={`Sales Transaction ID : ${data.salesTran}`}
            onClick={isViewDocument ? this.documentaryViewList.viewGoBack : undefined}
          />
          {isViewDocument &&
            <React.Fragment>
              <Breadcrumb.List name='/' />
              <Breadcrumb.List
                name={getLanguage('documentaryManage.breadcrumb3', '')}
              />
            </React.Fragment>
          }
        </Breadcrumb>
        <Section ui='content'>
          {isViewDocument ?
            <ShapeContainer
              ui='content'
              justify='space-between'
            >
              <Section spacingBottom='30'>
                <Section spacingBottom='45'>
                  <TitleSection name={getLanguage('documentaryManage.sectionTitle.productWarrantyDocumentary', '')} />
                </Section>
                <Section
                  ui='googleViewer'
                  spacingBottom='30'
                  url={viewData.fileUrl}
                />
              </Section>
              <Section>
                <Grid
                  gutter='15'
                  gutterVertical='15'
                  justify='flex-end'
                  align='center'
                >
                  <Grid.Column>
                    <Button
                      gray
                      ui='back'
                      name={getLanguage('field.buttonBack', '')}
                      width={VARIABLES.BUTTON.WIDTHS.W_135}
                      onClick={this.documentaryViewList.viewGoBack}
                    />
                  </Grid.Column>
                  <Grid.Column>
                    <Button
                      mediumBlue
                      ui='frontIcon'
                      name={getLanguage('field.buttonShare', '')}
                      width={VARIABLES.BUTTON.WIDTHS.W_135}
                      iconWidth='18'
                      iconHeight='18'
                      iconSrc={ICONS['ic-share.svg']}
                      onClick={this.documentaryViewList.onClickShare}
                    />
                  </Grid.Column>
                  <Grid.Column>
                    <Button
                      green
                      ui='frontIcon'
                      name={getLanguage('field.buttonDownload', '')}
                      width={VARIABLES.BUTTON.WIDTHS.W_155}
                      iconWidth='18'
                      iconHeight='18'
                      iconSrc={ICONS['ic-download-circle.svg']}
                      onClick={this.documentaryViewList.onClickDownload}
                    />
                  </Grid.Column>
                </Grid>
              </Section>
            </ShapeContainer>
            :
            <React.Fragment>
              <Section spacingBottom='35'>
                <Grid>
                  <Grid.Column flex='1'>
                    <TitlePage
                      src={ICONS['ic-page-documents.svg']}
                      name={`${getLanguage('documentaryManage.title', '')} > Sales Transaction ID : ${data.salesTran}`}
                    />
                  </Grid.Column>
                  <Grid.Column flex='none'>
                    <Grid gutter='15'>
                      <Grid.Column>
                        <Field
                          ui='blockLabelVerticalSearch'
                          width='300'
                          placeholder={getLanguage('documentaryManage.placeholder.search', '')}
                          name='searchValue'
                          value={searchValue}
                          onChange={this.input.onChangeInput}
                        />
                      </Grid.Column>
                      <Grid.Column>
                        <Button
                          lightBlue
                          name={getLanguage('field.buttonSearch', '')}
                          width={VARIABLES.BUTTON.WIDTHS.W_105}
                          onClick={this.documentaryViewList.onClickSearch}
                        />
                      </Grid.Column>
                    </Grid>
                  </Grid.Column>
                </Grid>
              </Section>
              <DocumentaryDetailListContainerConnected
                dataId={dataId}
                goBack={this.documentaryViewList.goBack}
                onClickView={this.documentaryViewList.onClickView}
              />
            </React.Fragment>
          }
        </Section>
        {isOpenShareModal &&
          <ModalSocialShareContainer
            isOpenModal
            title={getLanguage('modal.share.title', '')}
            url={viewData.fileUrl}
            emailBody={emailBody}
            emailSubject={emailSubject}
            quote='Share warranty'
            onClickClose={this.documentaryViewList.onCloseShareModal}
          />
        }
      </React.Fragment>
    )
  }
}
