import styled, {
  // css
} from 'styled-components'
import {
  default as VARIABLES
} from './../../../../themes/styles/bases/variables'
// import {
//   default as TYPOGRAPHYS
// } from './../../../../themes/styles/bases/typographys'
// import {
//   default as MIXINS
// } from './../../../../themes/styles/helpers/mixins'

// Sample using image in style
// url(${require('./../../../../themes/images/icons/icon-sample.svg')});

// Wrapper
// ============================================================
export const WarrantyWrapper = styled.div`
  /* Parent
  ------------------------------- */
  position: relative;
  max-width: 1376px;
  height: 100%;
  padding: 0;
  margin-right: auto;
  margin-left: auto;
  background-color: ${VARIABLES.COLORS.WHITE};

  /* Childrens
  ------------------------------- */
  /* Modifiers
  ------------------------------- */
  /* Media queries
  ------------------------------- */
`

// Content
// ============================================================
export const WarrantyContentWrapper = styled.div`
  /* Parent
  ------------------------------- */
  min-height: 854px;
  padding-right: 37px;
  padding-left: 37px;

  /* Childrens
  ------------------------------- */
  .warranty-bswm-text {
    font-family: 'Tahoma';
    color: ${VARIABLES.COLORS.BLACK};
    font-size: 12px;
    line-height: 1.2;
    word-break: break-word;

    &.is-bold {
      font-family: 'Tahoma-Bold';
    }

    &.is-italic {
      font-style: italic;
    }

    &.is-normal {
      font-style: normal;
    }

    &.is-superscript {
      display: inline;
      vertical-align: super;
    }

    &.is-center {
      text-align: center;
    }

    &.is-right {
      text-align: right;
    }

    &.is-underline {
      text-decoration: underline;
    }

    &.is-year {
      width: 75px;
      text-align: right;
    }

    &.is-xs {
      font-size: 10px;
    }

    &.is-sm {
      font-size: 11px;
    }

    &.is-md {
      font-size: 12px;
    }

    &.is-lg {
      font-size: 14px;
    }

    &.is-xl {
      font-size: 16px;
    }

    &.is-xxl {
      font-size: 18px;
    }

    &.is-big {
      font-size: 20px;
    }

    &.is-jumbo {
      font-size: 22px;
    }

    &.is-huge {
      font-size: 24px;
    }
  }

  .warranty-bswm-container {
    &.is-spacebetween {
      display: flex;
      min-height: 854px;
      flex-direction: column;
      justify-content: space-between;
    }
  }

  .warranty-bswm-section {}

  /* Modifiers
  ------------------------------- */

  /* Media queries
  ------------------------------- */
`

// Note
// ============================================================
export const WarrantyNoteWrapper = styled.div`
  /* Parent
  ------------------------------- */
  /* Childrens
  ------------------------------- */
  /* Modifiers
  ------------------------------- */
  /* Media queries
  ------------------------------- */
`

// Signature
// ============================================================
export const WarrantySignatureWrapper = styled.div`
  /* Parent
  ------------------------------- */
  /* Childrens
  ------------------------------- */
  /* Modifiers
  ------------------------------- */
  /* Media queries
  ------------------------------- */
`

// Not Covered Lists
// ============================================================
export const WarrantyNotCoveredListsWrapper = styled.div`
  /* Parent
  ------------------------------- */
  /* Childrens
  ------------------------------- */
  /* Modifiers
  ------------------------------- */
  /* Media queries
  ------------------------------- */
`

// Cladding Issues Lists
// ============================================================
export const WarrantyCladdingIssuesListsWrapper = styled.div`
  /* Parent
  ------------------------------- */
  /* Childrens
  ------------------------------- */
  /* Modifiers
  ------------------------------- */
  /* Media queries
  ------------------------------- */
`

// Product Information
// ============================================================
export const WarrantyProductInformationWrapper = styled.div`
  /* Parent
  ------------------------------- */
  /* Childrens
  ------------------------------- */
  /* Modifiers
  ------------------------------- */
  /* Media queries
  ------------------------------- */
`

// Product Conditions
// ============================================================
export const WarrantyProductConditionsWrapper = styled.div`
  /* Parent
  ------------------------------- */
  /* Childrens
  ------------------------------- */
  /* Modifiers
  ------------------------------- */
  /* Media queries
  ------------------------------- */
`

// Table Coil ID
// ============================================================
export const WarrantyTableCoilIdWrapper = styled.div`
  /* Parent
  ------------------------------- */
  /* Childrens
  ------------------------------- */
  /* Modifiers
  ------------------------------- */
  /* Media queries
  ------------------------------- */
`

// Table Building
// ============================================================
export const WarrantyTableBuildingWrapper = styled.div`
  /* Parent
  ------------------------------- */
  /* Childrens
  ------------------------------- */
  /* Modifiers
  ------------------------------- */
  /* Media queries
  ------------------------------- */
`

// Additional Information
// ============================================================
export const WarrantyAdditionalInformationWrapper = styled.div`
  /* Parent
  ------------------------------- */
  /* Childrens
  ------------------------------- */
  /* Modifiers
  ------------------------------- */
  /* Media queries
  ------------------------------- */
`

// Application detail
// ============================================================
export const WarrantyApplicationDetailWrapper = styled.div`
  /* Parent
  ------------------------------- */
  /* Childrens
  ------------------------------- */
  /* Modifiers
  ------------------------------- */
  /* Media queries
  ------------------------------- */
`

// Terms and Conditions
// ============================================================
export const WarrantyTermsAndConditionsWrapper = styled.div`
  /* Parent
  ------------------------------- */
  /* Childrens
  ------------------------------- */
  /* Modifiers
  ------------------------------- */
  /* Media queries
  ------------------------------- */
`
