import { isValidResponse } from "../functions/validation";
import { storeDispatch, storeGetState } from "../../stores";
import { dataCreators, DATA_REDUX_STATE_KEY } from "../../actions/dataAction";
import { handleTrimParams } from "../functions/data";
import { logController } from "../../apiService/apiController/logService";
import { ModelUploadTransactionHistory } from "../../models/ModelUploadTransactionHistory";

export const getUploadTransactionSearchFileList = async (params = {}) => {
  let userAuth = storeGetState().mainRedux.userAuth;
  let currentParams = storeGetState().dataRedux.uploadTransactionHistoryParams;
  let newParams = handleTrimParams({
    ...currentParams,
    ...params,
    ad_ref_id: userAuth.id,
  });
  // if (!newParams.isNoSort) {
  //   if (!newParams.sort) {
  //     newParams.sort = 'create_at desc';
  //   }
  //   else {
  //     newParams = handleSortParams(currentParams, newParams);
  //   }
  // }
  const apiParams = {
    ...newParams,
    // sort: `${newParams.sort}, id desc`,
  }
  const logService = logController();
  const res = await logService.uploadTransactionSearchFile(apiParams);
  if (isValidResponse(res)) {
    const dataList = res.data.map(e => new ModelUploadTransactionHistory(e));
    newParams.activePage = newParams.page;
    newParams.totalData = res.total_items;
    console.log('dataList', res.data, dataList, newParams);
    storeDispatch(dataCreators.setDataRedux(DATA_REDUX_STATE_KEY.UPLOAD_TRANSACTION_HISTORY_LIST, dataList));
    storeDispatch(dataCreators.setDataRedux(DATA_REDUX_STATE_KEY.UPLOAD_TRANSACTION_HISTORY_PARAMS, newParams));
  }
}

export const getClearUploadTransactionHistoryParams = () => {
  return {
    file_name: '',
    sort: 'id desc',
    isNoSort: false,
  }
}