import {
  firebase,
} from '../../config/firebase';
import { FIREBASE_STORAGE, FIREBASE_PUBLIC_STORAGE_PATH } from '../../config/config';
import { pushLogList } from '../functions/log';

const storage = firebase.storage();

const getListAll = (listRef, pathList) => {
  return new Promise((resolve, reject) => {
    listRef.listAll().then(async (res) => {
      res.items.forEach((itemRef) => {
        pathList.push(itemRef.fullPath);
      });
      for (let i = 0; i < res.prefixes.length; i++) {
        await getListAll(res.prefixes[i], pathList);
      }
      resolve(pathList)
    }).catch((error) => {
      reject(error);
    });
  });
}

export const firebaseGetAllFileList = async (path) => {
  const listRef = storage.ref().child(`${FIREBASE_STORAGE}${path}`);
  let pathList = [];
  return await getListAll(listRef, pathList);
}


export const firebaseUploadFile = (path, file, fileName) => {
  return storage.ref().child(`${FIREBASE_STORAGE}${path}/${fileName}`).put(file).then((res) => {
    console.log('==================');
    console.log('firebaseUploadFile', `${FIREBASE_PUBLIC_STORAGE_PATH}/${FIREBASE_STORAGE}${path}/${fileName}`.replace(/ /g, '%20'));
    console.log(`${fileName} Image successfully added!`, res)
    console.log('==================');
    pushLogList(
      '==================',
      'firebaseUploadFile', `${FIREBASE_PUBLIC_STORAGE_PATH}/${FIREBASE_STORAGE}${path}/${fileName}`.replace(/ /g, '%20'),
      `${fileName} Image successfully added!`,
      '=================='
    );
    return res
  }).catch((err) => {
    console.log('==================');
    console.error(`${fileName} Error adding image: ${err}`)
    console.log('==================');
    pushLogList(
      '==================',
      `${fileName} Error adding image: ${err.message}`,
      '=================='
    );
  });
}

export const firebaseDeleteFile = (filePath) => {
  try {
    return storage.ref().child(filePath).delete().then((res) => {
      console.log(`${filePath} Image successfully deleted!`, res)
      pushLogList(`${filePath} Image successfully deleted!`)
      return res
    }).catch((err) => {
      console.error(`${filePath} Error removing image: ${err}`)
      pushLogList(`${filePath} Error removing image: ${err.message}`)
    });
  }
  catch {
    return ''
  }
}

export const firebaseUpdateFile = async (path, file, fileName, oldFilePath) => {
  await firebaseDeleteFile(oldFilePath);
  return firebaseUploadFile(path, file, fileName);
}

export const firebaseGetDownloadPath = (refPath) => {
  try {
    return storage.ref(refPath).getDownloadURL().then((res) => {
      console.log(`getDownloadURL successfully!`, res);
      return res
    }).catch((err) => {
      console.error(`getDownloadURL Error: `);
      console.error({ err });
      pushLogList(`getDownloadURL Error: ${err.message}`);
      return ''
    });
  } catch {
    return ''
  }
}
