export const lang_vi = {
  topMenu: {
    myProfile: 'thông tin của tôi',
    chooseLanguage: {
      title: 'chọn ngôn ngữ',
      thai: 'Thai',
      english: 'English',
      indonesia: 'Bahasa indonesia',
      vietnam: 'Tiếng việt',
      malaysia: 'Malaysia',
      laos: 'Laos',
    }
  },
  sideMenu: {
    home: 'Trang chủ',
    yearWarranty: 'Warranty Guideline',
    customers: 'Khách hàng',
    orderInquiry: 'Yêu cầu đặt hàng',
    salesTransaction: 'Giao dịch',
    salesOrders: 'Đơn đặt hàng',
    salesHistory: 'Lịch sử bán hàng',
    warrantyManagement: 'Bảo hành Management',
    warrantyTemplate: 'Template',
    warranty: 'Bảo hành',
    documentary: 'Documentary',
    documentTemplate: 'Document Template',
    rewards: 'Điểm thưởng',
    redemption: 'Đổi thưởng',
    rewardRedemptionHistory: 'Lịch sử đổi thưởng',
    rewardStock: 'Phần thưởng tồn kho',
    trueBluePointsDetail: 'Chi tiết điểm TrueBlue',
    inventory: 'Tồn Kho',
    inventoryManagement: 'Quản Lý Tồn Kho',
    profileMapping: 'Profile Mapping',
    profileMappingHistory: 'Profile Mapping History',
    profileTransfer: 'Profile Transfer',
    profileTransferHistory: 'Profile Transfer History',
    coilTransfer: 'Chuyển Cuộn',
    coilTransferHistory: 'Lịch Sử Chuyển Cuộn',
    report: 'Báo cáo',
    settings: 'Cài đặt',
    adInformation: 'Thông tin thêm',
    termAndCondition: 'Điều khoản và điều kiện',
    contactUs: 'Liên hệ với chúng tôi',
    help: 'Giúp đỡ',
    salesPerson: 'Sales Person',
    uploadTransaction: 'giao dịch tải lên',
    uploadTransactionManagement: 'quản lý giao dịch tải lên',
    uploadTransactionHistory: 'lịch sử giao dịch tải lên',
    preTransaction: 'Pre Transaction',
    projectDocument: 'Project Document',
    preTransactionHistory: 'Pre-Transaction History',
    projectReference: 'Project Reference',
    barcodeScan: 'Buy Coil in',
    coilApproval: 'Approve coil transfer',
    notificationManagement: 'Notification Management',
  },
  login: {
    message_1: 'xin chào',
    message_2: 'TrueBlue',
    message_3: 'quên tên tài khoản hoặc mật khẩu',
    message_4: 'nhập địa chỉ email để đặt lại mật khẩu',
    message_5: 'Check',
    message_6: 'Authentication',
    message_7: 'Authentication link has been sent to your phone (SMS).',
    message_8: 'Please approve the access from the link within 3 minutes.',
    message_9: 'Reference Code is',
    message_10: 'Success!',
    message_11: 'Fail!',
    message_12: 'expired',
    message_13: 'Verify',
    message_14: 'Methods',
    message_15: 'Verify with',
    message_16: 'Phone Number',
    message_17: 'Questions',
    message_18: 'OTP',
    message_19: 'send to',
    message_20: 'Re-send',
    message_21: 'What province is your shop in? (Thai language)',
    message_22: "How many branches do you have?",
    message_23: 'What year was your shop founded? (Buddhist calendar)',
    message_24: '8 - 24 Characters or Numbers',
    message_25: 'Reset Password',
    message_26: 'Please try your new password in the next login.',
    message_27: 'Reset',
    message_28: 'Password',
    message_29: 'Setup',
    maintenance: "ระบบได้ปิดทำการปรับปรุงตั้งแต่",
    maintenance_2: "กรุณาเข้าระบบใหม่หลังจากระบบเปิดทำการตามเวลาที่แจ้ง",
    message_30: 'For account security, please log in again.',
  },
  home: {
    breadcrumb1: 'Trang chủ',
    title: 'Trang chủ',
    label: {
      barcodeScan: 'Barcode Scan',
    }
  },
  yearWarranty: {
    breadcrumb1: 'Home',
    breadcrumb2: 'Warranty Guideline',
    title: 'Warranty Guideline',
    label: {
      product: 'Product',
      environment: 'Environment',
      wall: 'Wall',
      roof: 'Roof',
      purlinGirt: 'Purlin',
      trussFrame: 'Truss & Frame',
      coolRoomPanel: 'Sandwich panel',
      decking: 'Decking',
      airDucting: 'Air Ducting',
      shutterEx: 'Shutter ex',
      warrantiesAvailable: 'Warranties Available',
      year: 'Year(s)',
      std: 'Std.',
      standard: 'Standard',
      industrial: 'Industrial',
      marine: 'Marine',
      distance: 'Distance',
      m: 'm',
      metre: 'metre',
      min: 'Min',
      max: 'Max',
      from: 'From',
      pleaseRegister: 'Please register',
    },
    placeholder: {
      selectProduct: 'Select Product',
      selectFrom: 'Select From',
      selectDistance: 'Select Distance(m)',
    },
    hint: {
      short: {
        br: 'B/R',
        lu: 'L/U',
        ind: 'Ind',
        hind: 'HInd',
        sind: 'SInd',
        mm: 'MM',
        m: 'M',
        sm: 'SM',
        vsm: 'VSM',
      },
      long: {
        br: 'Benign / Rural',
        lu: 'Light Ind / Urban',
        ind: 'Industrial',
        hind: 'Heavy Industrial',
        sind: 'Severe Industrial',
        mm: 'Moderate Marine',
        m: 'Marine',
        sm: 'Severe Marine',
        vsm: 'Very Severe Marine',
      },
      message: {
        steelProductionDate: "It is only steel produced before 18 August 2024.",
      },
    },
    warranty: {
      perforateByCorrosion1: 'Shall not perforate by corrosion for',
      perforateByCorrosion2: 'Shall not structural failure by corrosion for', // isSteelBuildingFrames
      perforateByCorrosion3: 'Shall not structural failure by corrosion for', // SuperDyma / TrueCore
      peelOrFlakeExcessively1: 'Shall not perforate by color peeling for',
      colourFadeExcessively1: 'Shall not color fade excessively for',
      discolourByDirtStaining1: 'Shall not discolor by dirt staining for',
      noWarrantyInformation: 'No warranty information.',
      perforateDueToCorrosionRoof: "Shall not perforate by corrosion for roof",
      perforateDueToCorrosionWall: "Shall not perforate by corrosion for wall",
      year: 'years'
    },
  },
  customers: {
    breadcrumb1: 'Trang chủ',
    breadcrumb2: 'Khách hàng',
    title: 'Quản lý khách hàng',
    placeholder: 'Tìm kiếm',
    selectOption: {
      all: 'Tất cả',
      name: 'Tên',
      nickname: 'Biệt danh',
      phoneNo: 'Số điện thoại',
    },
    buttonSearch: 'Tìm kiếm',
    buttonCreate: 'Tạo khách hàng',
    table: {
      header: {
        no: 'Số',
        id: 'Số CMND',
        name: 'Tên',
        nickname: 'Biệt danh',
        phoneNo: 'Số điện thoại',
        points: 'Điểm TrueBlue',
        expiringPoints: 'TrueBlue Points Expiring in',
        trained: 'Được đào tạo',
        level: 'Level',
        action: 'hành động',
        memberType: 'Member Type',
      },
      body: {
        platinum: 'Platinum',
        gold: 'Gold',
        silver: 'Silver',
        bronze: 'Bronze',
      },
    },
    checkboxs: {
      all: 'All',
      onlyMemberPointsExpire: 'Only member (points expire)',
      pdf: 'PDF',
      excel: 'Excel',
    },
  },
  customersManage: {
    breadcrumb1: 'Trang chủ',
    breadcrumb2: 'Khách hàng',
    breadcrumb3_1: 'Tạo khách hàng',
    breadcrumb3_2: 'sửa lại khách hàng',
    step: {
      register: 'Đăng ký',
      customerInfo: 'Thông tin khách hàng',
      idAddress: 'Địa chỉ thường trú',
      contactAddress: 'Địa chỉ liên hệ',
    },
    tab: {
      registration: 'Đăng ký',
      customerInfo: 'Thông tin khách hàng',
      idAddress: 'Địa chỉ thường trú',
      contactAddress: 'Địa chỉ liên hệ',
    },
    form: {
      regis: {
        customerType: 'Loại khách hàng',
        fixer: 'Thợ/Nhà thầu/Thợ lắp dựng',
        hardware: 'Cửa hàng/Cửa hàng VLXD',
        homeowner: 'Chủ nhà/Chủ dự án',
        rollFormer: 'Xưởng cán',
        others: 'Khác',
        authorisedDealer: 'Đại lý ủy quyền',
        architect: "Architect / Designer / Consult",
        engineer: "Engineer"
      },
      idAddress: {
        idCardNo: 'Số CMND',
      },
      contactAddress: {
        sameAddress: 'Địa chỉ theo CMND',
        contactChannel: 'Kênh liên hệ',
      }
    }
  },
  orderInquiry: {
    breadcrumb1: 'Trang chủ',
    breadcrumb2: 'Yêu cầu đặt hàng',
    title: 'Danh sách yêu cầu đặt hàng',
    placeholder: 'Khách hàng, Số điện thoại, Email, Tình trạng',
    buttonSearch: 'Tìm kiếm',
    table: {
      header: {
        id: 'Số CMND',
        subject: 'Tiêu đề',
        customer: 'Khách hàng',
        phoneNo: 'Số điện thoại',
        email: 'Email',
        pendingOn: 'Tình trạng hiện tại',
        responseDate: 'Ngày phản hồi',
        status: 'Tình trạng',
        action: 'Hành động',
      },
      action: {
        details: 'Chi tiết'
      }
    }
  },
  orderInquiryManage: {
    breadcrumb1: 'Trang chủ',
    breadcrumb2: 'Yêu cầu đặt hàng',
    breadcrumb3: 'Yêu cầu đặt hàng',
    title: 'Thông tin yêu cầu đặt hàng',
    form: {
      information: {
        acknowledged: 'Đã xác nhận',
        pending: 'Đang chờ xử lý',
        subject: 'Tiêu đề',
        description: 'Mô tả',
        customer: 'Khách hàng',
        phoneNo: 'Số điện thoại',
        email: 'Email',
        submittedOn: 'Ngày đặt hàng',
        responseDate: 'Ngày phản hồi',
        status: 'Tình trạng',
      },
    }
  },
  salesTransaction: {
    breadcrumb1: 'Trang chủ',
    breadcrumb2: 'Giao dịch',
    title: 'Giao dịch',
    subtitle: 'Giao dịch Indexes',
    page: {
      goToPage: 'Đến trang tiếp theo',
      salesOrders: 'Đơn đặt hàng',
      salesHistory: 'Lịch sử bán hàng',
    },
  },
  salesOrders: {
    breadcrumb1: 'Trang chủ',
    breadcrumb2: 'Giao dịch',
    breadcrumb3: 'Đơn đặt hàng',
    title: 'Giao dịch bán hàng > Đơn hàng',
    form: {
      customerInfo: 'Thông tin khách hàng',
      selectCustomer: 'Chọn khách hàng ',
      buttonCreate: 'Tạo khách hàng',
      buttonSelectCustomer: 'Chọn khách hàng',
    }
  },
  salesHistory: {
    breadcrumb1: 'Trang chủ',
    breadcrumb2: 'Giao dịch',
    breadcrumb3: 'Lịch sử bán hàng',
    title: 'Giao dịch > Lịch sử bán hàng',
    selectOption: {
      all: 'Tất cả',
      salesTransactionID: 'Mã giao dịch',
      customerName: 'Tên khách hàng',
      phoneNumber: 'Phone Number',
    },
    placeholder: 'Giữ chỗ',
    date: 'Ngày',
    dateTo: 'đến',
    selectStartDate: 'ngày bắt đầu',
    selectEndDate: 'ngày kết thúc',
    buttonSearch: 'Tìm kiếm',
  },
  warranty: {
    breadcrumb1: 'Trang chủ',
    breadcrumb2: 'Bảo hành',
    breadcrumb3: 'Danh sách bảo hành',
    title: 'Bảo hành',
    selectOption: {
      salesTransactionID: 'Mã giao dịch',
      contactName: 'Tên liên hệ',
      warrantyNo: 'Số phiếu bảo hành',
      projectName: 'Tên dự án',
      phoneNumber: 'Phone Number',
    },
    placeholder: 'Tìm kiếm',
    date: 'Ngày',
    dateTo: 'Đến',
    selectStartDate: 'Ngày bắt đầu',
    selectEndDate: 'Ngày kết thúc',
    buttonSearch: 'Tìm kiếm',
    selectedTransactions: 'Giao dịch',
    buttonGenerateWarranty: 'Tạo bảo hành',
    footer: {
      createDate: 'Created Date',
      checkViaQr: 'Verify information via QR Code',
    }
  },
  warrantyManage: {
    breadcrumb1: 'Trang chủ',
    breadcrumb2: 'Bảo hành',
    breadcrumb3: 'Danh sách bảo hành',
    breadcrumb4: 'Generate Warranty',
    title: 'Bảo hành > Generate Warranty',
    alertGenerateWarranty: 'This warranty is being generate.',
    step: {
      information: 'Thông tin',
      segment: 'Segment',
      detail: 'Chi tiết',
      conditions: 'Điều kiện',
      warranty: 'Bảo hành',
      documentaryInformation: 'Documentary Information',
      documentary: 'Documentary',
    },
    form: {
      info: {
        title: 'Thông tin dự án',
        title_env: 'Environment Informaion',
      },
      segment: {
        title: 'Segment Information',
        button: {
          search: 'Search',
          segmentAndSubSegmentInformation: 'Segment & Sub Segment Information',
        },
        table: {
          header: {
            segment: 'Segment',
            description: 'Segment Description',
            subSegment: 'Sub Segment',
            subSegmentDescription: 'Sub Segment Description',
          },
        },
        modal: {
          segmentAndSubSegmentInformation: 'Segment & Sub Segment Information',
        },
        building: {
          factory: 'Factory',
          office: 'Office',
          carPark: 'Car park',
          warehouse: 'Warehouse'
        }
      },
      detail: {
        applicationDetail: 'Chi tiết ứng dụng',
        productInformation: 'Thông tin sản phẩm',
        roofAndWall: 'Mái và vách - met',
        roof: 'Mái - met',
        wall: 'Vách - met',
        purlin: 'Xà gồ mái và vách - met',
        truss: 'Khung và kèo - met',
        coolRoom: 'phòng mát',
        decking: 'Tấm sàn - met',
        airDucting: 'Air Ducting - meter',
        quantityRemaining: 'Quantity remaining',
        noInformation: 'No Information',
        exceedQuantity: 'Exceed quantity, please change quantity or change segment.',
        segmentInformation: 'Segment Information',
        buildingInformation: 'Building Information',
        table: {
          header: {
            selectedItem: 'Sản phẩm được chọn',
            purchasingDate: 'Ngày mua hàng',
            salesTransactionID: 'Mã giao dịch',
            coilDescription: 'Mô tả cuộn',
            coilNo: 'Mã số cuộn',
            quantity: 'Số lượng',
          }
        }
      },
      condition: {
        title: 'Điều khoản và điều kiện',
        conditionYes: 'Đồng ý',
        conditionNo: 'không',
        condition1: 'Độ dốc mái nhà > 5 độ hoặc dễ thoát nước',
        condition2: 'Vít cấp 3 hoặc 4 (không rỉ)',
        condition3: 'Không chồng lên',
        condition4: 'Rìa mái mở',
        condition5: 'Mái kín',
        condition5_1: 'Cách nhiệt toàn bộ',
        condition5_2: 'Trần nhà',
        condition5_3: 'Lớp chống ẩm',
        condition5_4: 'Không che chắn',
        condition6: 'Nông thôn hoặc thành thị',
        condition6_1: 'Nông thôn',
        condition6_2: 'Thành thị',
        condition7: 'Vị trí dự án phải cách xa biển > 1km',
        condition7cTitle: 'Vui lòng chọn những tùy chọn bổ sung',
        condition7_1: '< 300 m.',
        condition7_2: '> 300, < 500 m.',
        condition7_3: '> 500, < 1,000 m.',
        condition8: 'Vị trí dự án phải cách xa khu công nghiệp hoặc môi trường ăn mòn > 1km',
        condition8cTitle: 'Vui lòng chọn những tùy chọn bổ sung',
        condition8_1: '< 500 m.',
        condition8_2: '> 500, < 1 km.',
        condition8_3: '> 1, < 2 km.',
        condition9: 'Không được sử dụng sản phẩm với các vật liệu không tương thích như thép không gỉ, đồng, axit hoặc bazơ',
        condition10: 'Các hoạt động trong nhà không được ảnh hưởng đến tuổi thọ của sản phẩm (nhiệt, hóa chất, khói từ quá trình sản xuất).',
        conditionUnitPlaceholder: 'Insert Number',
        conditionSelectPlaceholder: 'Select Option',
        conditionDescriptionPlaceholder: 'Mô tả',
        conditionDescriptionHint: 'Tối đa 500 kí tự',
        application: {
          roofAndWall: 'mái và vách',
          walling: 'mái',
          roofing: 'vách',
          purlinAndGirt: 'xà gồ',
          trussAndFrame: 'giàn và khung',
          coolRoomPanel: 'tấm vách panel phòng lạnh',
          decking: 'tấm lát ván',
          airDucting: 'Air Ducting',
        },
        criteriaAndSurroundingEnvironment: 'môi trường bên trog nhà máy và xung quanh',
        warrantyPeriods: 'số năm bảo hành',
        warrantyPeriodsCondition: 'Yêu cầu cho số năm bảo hành ngắn hơn, vui long chọn sản phẩm',
        warrantyPeriodsSelectLabel: 'chọn tất cả sản phẩm',
        warrantyPeriodsSelectPlaceholder: 'chọn sản phẩm',
        warrantyPeriodsConditionAttribute: {
          PleaseSelectApplication: 'Please select application',
          roof: 'Roof',
          wall: 'Wall',
          corrosion: 'Corrosion',
          peelFlake: 'Peel & Flake',
          colorFade: 'Color Fade',
          dirtStain: 'Dirt Stain',
        },
        roofConditions: {
          condition1: 'nhà cung cấp tấm lợp đã kieemt tra nguyên liệu và vị trí chưa ? (bởi nhà cung cấp tấm lợp hoặc các công ty đại diện',
          condition2: 'Vít phải tuân thủ tiêu chuẩn Úc AS 3566 Class 3(> 400m từ vị trí biển hoặc khu công nghiệp) hoặc Class 4(≤400m từ vị trí biển hoặc khu công nghiệp) và vít được gắn chặt vào đỉnh mái',
          condition3: 'Không xếp chồng lên tòa nhà hoặc khu vực lân cận.',
          condition4: "tấm lợp được lắp đặt tuân thủ hướng dẫn lắp đặt / chính sách ban hành của nhà sản xuất BlueScope",
          condition5: 'Tấm lợp được làm sạch rác / tạp chất và các vật liệu lạ khác',
          condition6: 'Tất cả các vật liệu để lắp đặt và liên quan tới tôn diềm phải được chế tạo từ các kim loại tương thích(là thép clean COLORBOND®, ZINCALUME®, Zinc hoặc nhôm) hoặc cần được bao phủ theo hướng dẫn hiện thời của BlueScope',
          condition7: "Vật liệu dẫn sét được sử dụng tương thích(ví dụ: Nhôm) với thép phủ của Blue Scope",
          condition8: 'Sơn dặm không được sử dụng',
          condition9: 'Sử dụng Silicon trung tính trong việc lắp đặt theo hướng dẫn hiện thời của BlueScope.',
          condition10: 'Môi trường xung quanh và điều kiện lắp đặt có như điều kiện bình thường được quy định trong điều khoản bảo hành không ?',
          condition11_1: 'Dự án nằm vị trí',
          condition11_2: 'Ảnh hưởng từ khu vự biển sóng lặng',
          condition12_1: 'Dự án nằm vị trí',
          condition12_2: 'Ảnh hưởng từ khu vự biển sóng tan',
          condition13_1: 'Dự án nằm vị trí',
          condition13_2: 'Ảnh hưởng từ khu vự công nghiệp nặng',
          condition14_1: 'Dự án nằm vị trí',
          condition14_2: 'Ảnh hưởng từ khu công nghiệp nhẹ',
          condition15: 'Cung cấp đầy đủ chi tiết về bất kỳ yếu tố bất thường nào khác gây ảnh hưởng đến bảo hành và cho biết khoảng cách tính bằng km:',
          condition16: 'Tuổi thọ được quan sát của các sản phẩm tương tự và ứng dụng trong lĩnh vực mô tả các hoạt động trong tòa nhà',
        },
        wallConditions: {
          condition1: 'nhà cung cấp tấm lợp đã kiểm tra nguyên liệu và vị trí chưa?(bởi nhà cung cấp tấm lợp hoặc các công ty đại diện',
          condition2: 'Vít phải tuân thủ tiêu chuẩn Úc AS 3566 Class 3(> 400m từ vị trí biển hoặc khu công nghiệp) hoặc Class 4(≤400m từ vị trí biển hoặc khu công nghiệp) và vít được gắn chặt vào đỉnh hoặc khe vách',
          condition3: 'Không xếp chồng lên tòa nhà hoặc khu vực lân cận.',
          condition4: "Tấm lợp vách được lắp đặt tuân thủ hướng dẫn lắp đặt / chính sách ban hành của nhà sản xuất BlueScope",
          condition5: 'Tấm lợp vách được làm sạch rác / tạp chất và các vật liệu lạ khác',
          condition6: 'Tất cả các vật liệu để lắp đặt và liên quan tới tôn diềm phải được chế tạo từ các kim loại tương thích(là thép clean COLORBOND®, ZINCALUME®, Zinc hoặc nhôm) hoặc cần được bao phủ theo hướng dẫn hiện thời của BlueScope',
          condition7: "Vật liệu dẫn sét được sử dụng tương thích(ví dụ: Nhôm) với thép phủ của BlueScope",
          condition8: 'Sơn dặm không được sử dụng',
          condition9: 'Sử dụng Silicon trung tính trong việc lắp đặt theo hướng dẫn hiện thời của BlueScope',
          condition10: 'Môi trường xung quanh và điều kiện lắp đặt có như điều kiện bình thường được quy định trong điều khoản bảo hành không?',
          condition11_1: 'Dự án nằm vị trí',
          condition11_2: 'Ảnh hưởng từ khu vự biển sóng lặng',
          condition12_1: 'Dự án nằm vị trí',
          condition12_2: 'Ảnh hưởng từ khu vự biển sóng tan',
          condition13_1: 'Dự án nằm vị trí',
          condition13_2: 'Ảnh hưởng từ khu vự công nghiệp nặng',
          condition14_1: 'Dự án nằm vị trí',
          condition14_2: 'Ảnh hưởng từ khu công nghiệp nhẹ',
          condition15: 'Cung cấp đầy đủ chi tiết về bất kỳ yếu tố bất thường nào khác gây ảnh hưởng đến bảo hành và cho biết khoảng cách tính bằng km:',
          condition16: 'Tuổi thọ được quan sát của các sản phẩm tương tự và ứng dụng trong lĩnh vực mô tả các hoạt động trong tòa nhà',
        },
        trussAndFrameConditions: {
          condition1: 'nhà cung cấp tấm lợp đã kiểm tra nguyên liệu và vị trí chưa?(bởi nhà cung cấp tấm lợp hoặc các công ty đại diện)',
          condition2: 'Tòa nhà với hệ giàn thép kín',
          condition3: 'Hệ giàn thép nằm ở vị trí cách sóng tan hơn 300m hoặc cách sóng lặng hơn 100m',
          condition4: "Hệ giàn thép được lắp đặt tuân thủ hướng dẫn lắp đặt / chính sách ban hành của nhà sản xuất BlueScope",
          condition5: 'Việc lắp đặt lớp chống ẩm giữa các tấm đáy và tấm bê tông đã được sử dụng',
          condition6: 'Lắp đặt các diềm thích hợp và các phương pháp xử lý chống thấm nước khác đối với bất kỳ sự xâm nhập nào để loại bỏ sự xâm nhập của nước.',
          condition7: "Lắp đặt các vít tuân theo yêu cầu liên quan được quy định trong AS3588 và các tiêu chuẩn áp dụng khác",
          condition8: 'Không để kèo thép tiếp xúc trực tiếp với “các vật liệu không tương thích” như đồng, chì, thép không rỉ hoặc gỗ đã qua xử lý hóa chất',
          condition9: 'Giàn kèo được làm sạch rác / tạp chất và các vật liệu lạ khác trước và sau khi lắp dựng',
          condition10: 'Không có hư hỏng cơ học hoặc hóa học trên khung thép / lớp phủ khung trước và sau khi lắp ráp tại công trường',
          condition11: 'Môi trường xung quanh và điều kiện lắp đặt có như điều kiện bình thường được quy định trong điều khoản bảo hành không?',
          condition12_1: 'Dự án nằm vị trí',
          condition12_2: 'Ảnh hưởng từ khu vự biển sóng lặng',
          condition13_1: 'Dự án nằm vị trí',
          condition13_2: 'Ảnh hưởng từ khu vự biển sóng tan',
          condition14_1: 'Dự án nằm vị trí',
          condition14_2: 'Ảnh hưởng từ khu vự công nghiệp nặng',
          condition15_1: 'Dự án nằm vị trí',
          condition15_2: 'Ảnh hưởng từ khu vự công nghiệp nhẹ',
          condition16: 'Cung cấp đầy đủ chi tiết về bất kỳ yếu tố bất thường nào khác gây ảnh hưởng đến bảo hành và cho biết khoảng cách tính bằng km:',
          condition17: 'Tuổi thọ được quan sát của các sản phẩm tương tự và ứng dụng trong lĩnh vực mô tả các hoạt động trong tòa nhà',
        },
        purlinAndGirtConditions: {
          condition1: 'nhà cung cấp tấm lợp đã kiểm tra nguyên liệu và vị trí chưa?',
          condition2: 'Tòa nhà với hệ giàn thép kín',
          condition3: 'Hệ giàn thép nằm ở vị trí cách sóng tan hơn 300m hoặc cách sóng lặng hơn 100m',
          condition4: "Hệ giàn thép được lắp đặt tuân thủ hướng dẫn lắp đặt / chính sách ban hành của nhà sản xuất BlueScope",
          condition5: 'Việc lắp đặt lớp chống ẩm giữa các tấm đáy và tấm bê tông đã được sử dụng',
          condition6: 'Lắp đặt các diềm thích hợp và các phương pháp xử lý chống thấm nước khác đối với bất kỳ sự xâm nhập nào để loại bỏ sự xâm nhập của nước.',
          condition7: "Lắp đặt các vít tuân theo yêu cầu liên quan được quy định trong AS3588 và các tiêu chuẩn áp dụng khác",
          condition8: 'Không để kèo thép tiếp xúc trực tiếp với “các vật liệu không tương thích” như đồng, chì, thép không rỉ hoặc gỗ đã qua xử lý hóa chất',
          condition9: 'Steel roof truss / framing is cleaned of swarf and other foreign materials (e.g. mortar) before and after site assembling.',
          condition10: 'Không có hư hỏng cơ học hoặc hóa học trên khung thép / lớp phủ khung trước và sau khi lắp ráp tại công trường',
          condition11: 'Môi trường xung quanh và điều kiện lắp đặt có như điều kiện bình thường được quy định trong điều khoản bảo hành không?',
          condition12_1: 'Dự án nằm vị trí',
          condition12_2: 'Ảnh hưởng từ khu vự biển sóng lặng',
          condition13_1: 'Dự án nằm vị trí',
          condition13_2: 'Ảnh hưởng từ khu vự biển sóng tan',
          condition14_1: 'Dự án nằm vị trí',
          condition14_2: 'Ảnh hưởng từ khu vự công nghiệp nặng',
          condition15_1: 'Dự án nằm vị trí',
          condition15_2: 'Ảnh hưởng từ khu vự công nghiệp nhẹ',
          condition16: 'Cung cấp đầy đủ chi tiết về bất kỳ yếu tố bất thường nào khác gây ảnh hưởng đến bảo hành và cho biết khoảng cách tính bằng km:',
          condition17: 'Tuổi thọ được quan sát của các sản phẩm tương tự và ứng dụng trong lĩnh vực mô tả các hoạt động trong tòa nhà',
        },
        coolRoomPanelConditions: {
          condition1: 'Nhà cung cấp tấm lợp đã kiểm tra nguyên liệu và vị trí chưa?',
          condition2: 'Sản phẩm được lắp đặt tuân thủ hướng dẫn lắp đặt / chính sách ban hành của nhà sản xuất BlueScope',
          condition3: 'Độ dày thép nền là 0.4m(hoặc hơn)',
          condition4: 'Điều kiện phòng lạnh bình thường của tấm sandwich',
          condition5: 'Chỉ các chất dán trung tính mới được áp dụng cho các mối nối tấm panel',
          condition6: 'Tất cả các vít được sử dụng cùng với nhau phải tuân theo các khuyến nghị của BlueScope.',
          condition7: 'Tất cả các vật liệu để lắp đặt và liên quan tới tôn diềm phải được chế tạo từ các kim loại tương thích(là thép clean COLORBOND®, ZINCALUME®, Zinc hoặc nhôm) hoặc cần được bao phủ theo hướng dẫn hiện thời của BlueScope',
          condition8: 'Không để kèo thép tiếp xúc trực tiếp với “các vật liệu không tương thích” như đồng, chì, thép không rỉ hoặc gỗ đã qua xử lý hóa chất',
          condition9: 'Không có hư hỏng cơ học hoặc hóa học trên tấm panel trước và sau khi lắp ráp tại công trường',
          condition10: 'Tất cả các khu vực phải được giữ sạch và vệ sinh',
          condition11_1: 'Dự án nằm vị trí',
          condition11_2: 'Ảnh hưởng từ khu vự biển sóng lặng',
          condition12_1: 'Dự án nằm vị trí',
          condition12_2: 'Ảnh hưởng từ khu vự biển sóng tan',
          condition13_1: 'Dự án nằm vị trí',
          condition13_2: 'Ảnh hưởng từ khu vự công nghiệp nặng',
          condition14_1: 'Dự án nằm vị trí',
          condition14_2: 'Ảnh hưởng từ khu vự công nghiệp nhẹ',
          condition15: 'Cung cấp đầy đủ chi tiết về bất kỳ yếu tố bất thường nào khác gây ảnh hưởng đến bảo hành và cho biết khoảng cách tính bằng km:',
          condition16: 'Tuổi thọ được quan sát của các sản phẩm tương tự và ứng dụng trong lĩnh vực mô tả các hoạt động trong tòa nhà',
          condition17_1: 'Mái được lót với:',
          condition17_2: 'vật liệu cách nhiệt',
          condition17_3: 'Làm ơn chọn thêm:',
          condition17_4: 'PU',
          condition17_5: 'PIR',
          condition17_6: 'PE',
          condition17_7: 'PS',
          condition17_8: 'EPS',
          condition17_9: 'Trần nhà',
          condition17_10: 'ngăn hơi',
          condition17_11: 'không có gì',
          condition17_12: 'khác',
        },
        airDuctingConditions: {
          condition1: 'nhà cung cấp tấm lợp đã kiểm tra nguyên liệu và vị trí chưa?(bởi nhà cung cấp tấm lợp hoặc các công ty đại diện)',
          condition2: 'Tòa nhà với hệ giàn thép kín',
          condition3: 'Hệ giàn thép nằm ở vị trí cách sóng tan hơn 300m hoặc cách sóng lặng hơn 100m',
          condition4: "Hệ giàn thép được lắp đặt tuân thủ hướng dẫn lắp đặt / chính sách ban hành của nhà sản xuất BlueScope",
          condition5: 'Việc lắp đặt lớp chống ẩm giữa các tấm đáy và tấm bê tông đã được sử dụng',
          condition6: 'Lắp đặt các diềm thích hợp và các phương pháp xử lý chống thấm nước khác đối với bất kỳ sự xâm nhập nào để loại bỏ sự xâm nhập của nước.',
          condition7: "Lắp đặt các vít tuân theo yêu cầu liên quan được quy định trong AS3588 và các tiêu chuẩn áp dụng khác",
          condition8: 'Không để kèo thép tiếp xúc trực tiếp với “các vật liệu không tương thích” như đồng, chì, thép không rỉ hoặc gỗ đã qua xử lý hóa chất',
          condition9: 'Giàn kèo được làm sạch rác / tạp chất và các vật liệu lạ khác trước và sau khi lắp dựng',
          condition10: 'Không có hư hỏng cơ học hoặc hóa học trên khung thép / lớp phủ khung trước và sau khi lắp ráp tại công trường',
          condition11: 'Môi trường xung quanh và điều kiện lắp đặt có như điều kiện bình thường được quy định trong điều khoản bảo hành không?',
          condition12_1: 'Dự án nằm vị trí',
          condition12_2: 'Ảnh hưởng từ khu vự biển sóng lặng',
          condition13_1: 'Dự án nằm vị trí',
          condition13_2: 'Ảnh hưởng từ khu vự biển sóng tan',
          condition14_1: 'Dự án nằm vị trí',
          condition14_2: 'Ảnh hưởng từ khu vự công nghiệp nặng',
          condition15_1: 'Dự án nằm vị trí',
          condition15_2: 'Ảnh hưởng từ khu vự công nghiệp nhẹ',
          condition16: 'Cung cấp đầy đủ chi tiết về bất kỳ yếu tố bất thường nào khác gây ảnh hưởng đến bảo hành và cho biết khoảng cách tính bằng km:',
          condition17: 'Tuổi thọ được quan sát của các sản phẩm tương tự và ứng dụng trong lĩnh vực mô tả các hoạt động trong tòa nhà',
        },
      },
      manage: {
        title: 'Bảo hành sản phẩm',
        or: 'or',
      },
      environment: {
        title: 'Environment Detail',
        hint: 'Vui long chọn môi trường cho mỗi sản phẩm',
      },
      documentary: {
        title: {
          selectDocumentary: 'Select Documentary',
          specifyCompanyName: 'Specify Company Name',
          confirmPeviousStep: 'Confirm Proceed to The Previous Step',
          confirmCompanyName: 'Confirm Company Name',
          productWarrantyAndDocumentary: 'Product Warranty & Documentary',
          previewDocument: 'Preview Document',
        },
        label: {
          specifyCompanyNameInDocument: 'Specify company name in document',
        },
        placeholder: {
          typeCompanyName: 'Type Company Name',
        },
        message: {
          confirmPeviousStep: 'Do you proceed to the previous step ?',
          warningPeviousStep: 'Your documentary information will be lost.',
          confirmCompanyName: 'Please confirm specify company name to use in document.',
          warningCompanyName1: 'After a complete save,',
          warningCompanyName2: "you won't be able to change or re-generate it.",
        },
      },
    }
  },
  warrantyStatus: {
    warrantyCertificateChecker: 'NGƯỜI KIỂM TRA GIẤY CHỨNG NHẬN BẢO HÀNH',
    warrantyNo: 'Số bảo hành',
    projectName: 'Tên dự án',
    projectAddress: 'Địa chỉ dự án(địa chỉ lắp dựng)',
    warrantyStatusChecker: "WARRANTY STATUS CHECKER",
    certificateStatusChecker: "CERTIFICATE STATUS CHECKER",
    projectAddressShort: "Project Address",
    certificateNo: "Certificate no.",
    locationAccount: "Location account",
    phoneNo: "Phone no.",
    coilNo: " Coil no.",
    coilDescription: "Coil description",
    quantity: "Quantity",
    productGroup: "Product group",
    usability: 'Application',
    metre: 'meter',
    roof: 'Roof',
    wall: 'Wall',
    purlinGirt: 'Purlin and Girt',
    truFrame: 'Truss and Frame',
    coolRoom: 'Cool Room Panel',
    decking: 'Decking',
    airDucting: 'Air Ducting',
    length: 'Length',
  },
  documentary: {
    breadcrumb1: 'Home',
    breadcrumb2: 'Documentary',
    title: 'Documentary',
    placeholder: {
      search: 'Search',
      selectStartDate: 'Select Start Date',
      selectEndDate: 'Select End Date',
    },
    label: {
      date: 'Date',
      to: 'to',
      selectedTransaction: 'Selected Transaction',
      ready: 'Ready',
    },
    selectOption: {
      all: 'All',
      salesTransactionID: 'Sales Transaction ID',
      documentID: 'Document ID',
      contractName: 'Contract Name',
      warrantyNo: 'Warranty No.',
      projectName: 'Project Name',
      phoneNumber: 'Phone Number',
    },
    table: {
      header: {
        select: 'Select',
        createDate: 'Create Date',
        warrantyNo: 'Warranty No.',
        salesTId: 'Sales T. ID',
        salesTransactionId: 'Sales Transaction ID',
        projectName: 'Project Name',
        customerName: 'Customer Name',
        productGroup: 'Product Group',
        mobileNo: 'Mobile No.',
        status: 'Status',
        action: 'Action',
      },
    },
    message: {
      noResult: 'No Result',
    },
  },
  documentTemplate: {
    breadcrumb1: 'Home',
    breadcrumb2: 'Warranty Management ',
    breadcrumb3: 'Document Template ',
    title: 'Document template',
    placeholderSearch: 'Search document category code or name',
    button: {
      buttonSearch: 'Search',
      buttonClose: 'Close'
    },
    table: {
      header: {
        category: 'Categoty',
        code: 'Code',
        name: 'Name',
        m3ProductGroup: 'M3 Product Group',
        view: 'View'
      }
    },
    modal: {
      header: {
        title: 'M3 Product Group Mapping : ',
        productCertificate: 'Certificate : [documentName]',
        productDocumentary: 'Documentary : [documentName]',
        documentary: 'Documentary',
      },
      placeholderSearchM3: 'Search M3 Product Group Code or Name',
      table: {
        header: {
          no: 'No.',
          m3ProductGroupCode: 'M3 Product Group Code',
          m3ProductGroupName: 'M3 Product Group Name'

        },
        label: {
          noM3ProductGroup: 'No M3 Product Group.'
        }
      }
    }
  },
  documentaryManage: {
    breadcrumb1: 'Home',
    breadcrumb2: 'Documentary',
    breadcrumb3: 'View Document',
    breadcrumb4: 'Generate Documentary',
    title: 'Documentary',
    titleGenerate: 'Documentary > Generate Documentary',
    sectionTitle: {
      productWarrantyDocumentary: 'Product Warranty & Documentary',
      documentary: 'Documentary',
    },
    placeholder: {
      search: 'Search document id or name',
    },
    label: {
      information: 'Information',
      documentary: 'Documentary',
    },
    table: {
      header: {
        document: 'Document',
        specifyCompanyName: 'Specify Company Name',
        createDate: 'Create Date',
        action: 'Action',
      },
    },
    message: {
      noDocument: 'No Document',
    },
  },
  rewards: {
    breadcrumb1: 'Trang chủ',
    breadcrumb2: 'Điểm thưởng',
    title: 'Điểm thưởng',
    subtitle: 'Điểm thưởng Indexes',
    page: {
      goToPage: 'Go to page',
      redemption: 'Đổi thưởng',
      rewardRedemptionHistory: 'Lịch sử đổi thưởng',
      rewardStock: 'Phần thưởng tồn kho',
      trueBluePointsDetail: 'Chi tiết điểm TrueBlue',
    },
  },
  redemption: {
    breadcrumb1: 'Trang chủ',
    breadcrumb2: 'Điểm thưởng',
    breadcrumb3: 'Đổi thưởng',
    title: 'Phần thưởng > Đổi thưởng',
    form: {
      customerInfo: {
        title: 'Thông tin khách hàng',
        customerBlank: 'Select or create members to redemption.',
        buttonSelectCustomer: 'Chọn khách hàng',
      }
    }
  },
  redemptionReward: {
    breadcrumb1: 'Trang chủ',
    breadcrumb2: 'Điểm thưởng',
    breadcrumb3: 'Đổi thưởng',
    breadcrumb4: 'Danh sách phần thưởng',
    title: 'Phần thưởng > Đổi thưởng > Danh sách phần thưởng',
    placeholder: 'Search by ID, Name of Rewards',
    buttonSearch: 'Tìm kiếm',
    table: {
      header: {
        id: 'Số CMND',
        rewards: 'Điểm thưởng',
        inStock: 'Hàng có sẵn trong kho',
        rewardPoint: 'Điểm thưởng',
        unit: 'Đơn vị',
        trueBluePoints: 'Điểm TrueBlue',
        usedPoints: 'Số điểm đã sử dụng',
      },
      footer: {
        usedPoints: 'Số điểm đã sử dụng',
        point: 'Điểm',
        yourTotalPoints: 'Tổng số điểm',
      }
    },
    informations: {
      yourTotalTrueBluePoints: 'Your Total TrueBlue Points',
      usedtheTrueBluePoints: 'Used the TrueBlue Points',
      points: 'Point(s)',
      orderBy: 'Order by',
      selectOrderBy: 'Select order by',
    },
  },
  redemptionRewardHistory: {
    breadcrumb1: 'Trang chủ',
    breadcrumb2: 'Điểm thưởng',
    breadcrumb3: 'Lịch sử đổi thưởng',
    title: 'Phần thưởng > Lịch sử đổi thưởng',
    selectOption: {
      mostExchangeReward: 'Phần thưởng trao đổi nhiều nhất',
      mostExchangeCustomer: 'Khách hàng trao đổi nhiều nhất',
      redemptionCode: 'Mã đổi thưởng',
      customerName: 'Tên khách hàng',
      phoneNo: 'Số điện thoại',
    },
    placeholder: 'Tìm kiếm',
    date: 'Ngày',
    dateTo: 'to',
    selectStartDate: 'Ngàu bắt đầu',
    selectEndDate: 'Ngày kết thúc',
    buttonSearch: 'Tìm kiếm',
  },
  rewardStock: {
    breadcrumb1: 'Trang chủ',
    breadcrumb2: 'Điểm thưởng',
    breadcrumb3: 'Đổi thưởng',
    breadcrumb4: 'Phần thưởng tồn kho',
    title: 'Phần thưởng > Phần thưởng tồn kho',
    placeholder: 'Tìm kiếm ID, tên giải thưởng',
    buttonSearch: 'Tìm kiếm',
  },
  pointDetail: {
    breadcrumb1: 'Trang chủ',
    breadcrumb2: 'Điểm thưởng',
    breadcrumb3: 'Chi tiết điểm TrueBlue',
    title: 'Phần thưởng > Chi tiết điểm TrueBlue',
    placeholder: 'Tìm kiếm bằng ID, số điện thoại, email',
    buttonSearch: 'Tìm kiếm',
  },
  pointDetailManage: {
    breadcrumb1: 'Trang chủ',
    breadcrumb2: 'Điểm thưởng',
    breadcrumb3: 'Chi tiết điểm TrueBlue',
    title: 'Phần thưởng > Chi tiết điểm TrueBlue',
    purchaseHistoryTitle: 'Lịch sử mua hàng',
  },
  inventoryIndexes: {
    breadcrumb1: 'Trang chủ',
    breadcrumb2: 'Tồn Kho',
    title: 'Tồn Kho',
    subtitle: 'Tồn Kho Indexes',
    page: {
      goToPage: 'Đến trang kế tiếp',
      inventoryManagement: 'Tồn Kho Management',
      coilTransfer: 'Chuyển tiếp cuộn',
      coilTransferHistory: 'Lịch Sử Chuyển Cuộn',
      profileTransfer: 'Chuyển hồ sơ',
      profileTransferHistory: 'Lịch sử chuyển hồ sơ',
      profileMapping: 'Bản vẽ định hình',
      profileMappingHistory: 'Lịch sử bản vẽ định hình',
    },
  },
  inventory: {
    breadcrumb1: 'Trang chủ',
    breadcrumb2: 'Tồn Kho',
    breadcrumb3: 'Số lượng sản phẩm',
    title: 'Tồn kho > Số lượng sản phẩm',
    placeholder: 'Tìm theo nhóm sản phẩm',
    buttonSearch: 'Tìm kiếm',
  },
  productDetail: {
    breadcrumb1: 'Trang chủ',
    breadcrumb2: 'Tồn Kho',
    breadcrumb3: 'Số lượng sản phẩm',
    breadcrumb4: 'Chi tiết sản phẩm',
    title: 'Tồn kho > Số lượng sản phẩm > Chi tiết sản phẩm',
    placeholder: 'Tìm theo tên cuộn, mã cuộn hoặc màu',
    placeholderForToko: 'Tiềm kiếm loại sóng / loại sản phẩm',
    buttonSearch: 'Tìm kiếm',
    table: {
      header: {
        coilName: 'Tên cuộn',
        coilID: 'Mã cuộn',
        thickness: 'Độ dày',
        thicknessBMT: 'Độ dày (BMT)',
        createDate: 'Ngày khởi tạo',
        lifetime: 'Thời gian sử dụng',
        color: 'Màu',
        weightBalance: 'Trọng lượng còn lại (Kg)',
        weightBalanceAbbreviation: 'Weight Bal. (Kg.)',
        lengthBalance: 'Chiều dài còn lại (m)',
        lengthBalanceAbbreviation: 'Length Bal. (m.)',
        action: 'Tùy chọn',
        profileType: 'Loại sóng',
        brandProductGroup: 'Nhóm sản phẩm',
        quantity: 'Số lượng',
        length: 'Chiều đai(m)',
        description: 'Mô tả',
      },
      action: {
        buttonEditLength: 'Sửa chiều dài',
      }
    }
  },
  productDetailManage: {
    breadcrumb1: 'Trang chủ',
    breadcrumb2: 'Tồn Kho',
    breadcrumb3: 'Số lượng sản phẩm',
    breadcrumb4: 'Chi tiết sản phẩm',
    breadcrumb5: 'Tồn kho và điều chỉnh',
    title: 'Tồn kho > Số lượng sản phẩm > Chi tiết sản phẩm > Tồn kho và điều chỉnh',
    form: {
      coilInformation: {
        title: 'Thông tin cuộn',
        coilName: 'Tên cuộn',
        coilID: 'Mã cuộn',
        lengthBalance: 'Chiều dài còn lại (m)',
        weightBalance: 'Trọng lượng còn lại (Kg)',
        titleProduct: 'chiều dài sản phẩm',
        reasonChange: {
          title: 'Lý do thay đổi',
          cover: 'Diềm mái',
          cutOff: 'Phần thừa',
          salesFlat: 'Tấm phẳng',
          other: 'Khác',
        },
        profileType: 'Loại sóng',
        productGroup: 'Nhóm sản phẩm',
        quantity: 'Số lượng'
      }
    }
  },
  profileMapping: {
    breadcrumb1: 'Trang chủ',
    breadcrumb2: 'Tồn Kho',
    breadcrumb3: 'Bản vẽ sóng',
    breadcrumb4: 'Chi tiết sóng',
    title_1: 'Tồn Kho > Profile Mapping > Select Hardware shop or Toko',
    title_2: 'Tồn Kho > Profile Mapping > Product Detail',
    placeholder: 'Tìm kiếm bằng loại hoặc nhóm sóng',
    buttonSearch: 'Tìm kiếm',
    form: {
      shopInfo: {
        title: 'Hardware shop / Toko info.',
        shopInfoBlank: 'Chọn đại lý ủy quyền / xưởng cán / Toko cho bản vẽ cuộn',
        buttonSelect: 'Select Hardware / Toko Shop',
        message: 'Coil không được sử dụng trong bản vẽ sóng, làm ơn chọn coil mới',
        coilValue: 'Plese Check coil width values.',
        table: {
          header: {
            profileType: 'Loại sóng',
            group: 'Nhóm sản phẩm',
            quantity: 'Số lượng',
            length: 'Chiều dài (m.)',
            action: 'Tùy chọn',
          }
        }
      }
    }
  },
  coilTransfer: {
    breadcrumb1: 'Trang chủ',
    breadcrumb2: 'Tồn Kho',
    breadcrumb3: 'Chuyển tiếp cuộn',
    title: 'Tồn kho > Chuyển tiếp cuộn',
    form: {
      adAndToko: {
        title: 'Cửa hàng / Thông tin của cửa hàng',
        blank: 'Chọn hoặc tạo mới đại lý ủy quyền / xưởng cán hoặc chuyển tiếp cuộn',
        buttonCreate: 'Yêu cầu tạo mới đại lý ủy quyền / xưởng cán / Toko',
        buttonSelect: 'Chọn đại lý ủy quyền / xưởng cán / Toko'
      },
      customer: {
        title: 'Thông tin khách hàng',
        blank: 'Chọn hoặc tạo mới đại lý ủy quyền / xưởng cán hoặc chuyển tiếp cuộn',
        buttonCreate: 'Yêu cầu tạo mới đại lý ủy quyền / xưởng cán / Toko',
        buttonSelect: 'Chọn đại lý ủy quyền / xưởng cán / Toko',
      },
    }
  },
  profileTransfer: {
    breadcrumb1: 'Trang chủ',
    breadcrumb2: 'Tồn Kho',
    breadcrumb3: 'Profile Transfer',
    title: 'Tồn Kho > Profile Transfer',
    form: {
      adAndToko: {
        title: 'Thông tin khách hàng',
        blank: 'Chọn hoặc tạo mới đại lý ủy quyền / xưởng cán hoặc chuyển tiếp cuộn',
        buttonCreate: 'Yêu cầu tạo mới đại lý ủy quyền / xưởng cán',
        buttonSelect: 'Chọn đại lý ủy quyền / xưởng cán',
      },
      customer: {
        title: 'Thông tin khách hàng',
        blank: 'Chọn hoặc tạo mới đại lý ủy quyền / xưởng cán hoặc chuyển tiếp cuộn',
        buttonCreate: 'Yêu cầu tạo mới đại lý ủy quyền / xưởng cán',
        buttonSelect: 'Chọn đại lý ủy quyền / xưởng cán',
      },
    }
  },
  coilTransaction: {
    breadcrumb1: 'Trang chủ',
    breadcrumb2: 'Tồn Kho',
    breadcrumb3: 'Chuyển tiếp cuộn',
    breadcrumb4: 'Giao dịch cuộn',
    title: 'Tồn kho > Chuyển tiếp cuộn > Giao dịch cuộn',
    title_2: 'Inventory & Coil Transfer  > Profile Transfer > Profile Transaction',
    form: {
      ad: {
        title: 'Thông tin đại lý ủy quyền',
        buttonSelect: 'Chọn khách hàng(xưởng cán)',
      },
      toko: {
        title: 'Toko Info',
      },
      latestCoilTransfer: {
        title: 'Giao dịch chuyển tiếp cuộn gần nhất',
        table: {
          header: {
            date: 'Ngày',
            orderNo: 'Mã đơn hàng',
            coilName: 'Tên cuộn',
            coilID: 'Mã cuộn',
            transferAmount: 'Số lượng chuyển tiếp (m)',
            weight: 'Trọng lượng (tấn)',
            salesperson: 'Nhân viên giao dịch',
            profileType: 'Loại sóng',
            productGroup: 'Nhóm sản phẩm',
            quantity: 'Số lượng',
            length: 'Chiều dài',
            lengthPerUnit: 'Chiều dài(m)',
            tranferFrom: 'Transfer from',
            tranferTo: 'Tranfer to',
            status: 'Status',
          }
        },
      },
      coilTransferSummary: {
        title: 'Cuộn chuyển tiếp',
        blank: 'Chọn sản phẩm bạn muốn bao gồm trong chuyển tiếp cuộn',
        buttonSelectProduct: 'Chọn sản phẩm',
        table: {
          header: {
            coilID: 'Mã cuộn',
            coilName: 'Tên cuộn',
            length: 'Chiều dài (m.)',
            weight: 'Trọng lượng (Kg.)',
          }
        },
      },
    }
  },
  profileTransaction: {
    breadcrumb1: 'Trang chủ',
    breadcrumb2: 'Tồn Kho',
    breadcrumb3: 'Chuyển tiếp cuộn',
    breadcrumb4: 'Giao dịch cuộn',
    title: 'Tồn Kho > Profile Transfer > Profile Transaction',
    form: {
      ad: {
        title: 'Thông tin đại lý ủy quyền',
        buttonSelect: 'chọn khách hàng(xưởng cán)',
      },
      toko: {
        title: 'Thông tin Toko',
      },
      latestProfileTransfer: {
        title: 'Giao dịch chuyển tiếp cuộn gần nhất',
        table: {
          header: {
            date: 'Ngày',
            orderNo: 'Mã đơn hàng',
            coilName: 'Tên cuộn',
            coilID: 'Mã cuộn',
            transferAmount: 'Số lượng chuyển tiếp (m)',
            weight: 'Trọng lượng (tấn)',
            salesperson: 'Nhân viên giao dịch',
            profileType: 'Loại sóng',
            productGroup: 'Nhóm sản phẩm',
            quantity: 'Số lượng',
            length: 'Chiều dài',
            lengthPerUnit: 'Chiều dài(m)'
          }
        },
      },
      profileTransferSummary: {
        title: 'Chuyển tiếp cuộn',
        blank: 'Chọn sản phẩm bạn muốn bao gồm trong chuyển tiếp cuộn',
        buttonSelectProduct: 'Chọn sản phẩm',
        table: {
          header: {
            coilID: 'Mã cuộn',
            coilName: 'Tên cuộn',
            quantity: 'Số lượng',
            length: 'Chiều dài (m.)',
            weight: 'Trọng lượng (Kg.)',
          }
        },
      },
    }
  },
  coilTransactionManage: {
    breadcrumb1: 'Trang chủ',
    breadcrumb2: 'Tồn Kho',
    breadcrumb3: 'Chuyển tiếp cuộn',
    breadcrumb4: 'Giao dịch cuộn',
    title: 'Tồn kho > Chuyển tiếp cuộn > Giao dịch cuộn',
    placeholder: 'Chọn nhóm sản phẩm',
    placeholderThickness: 'Chọn độ dày',
    placeholderCoilID: 'Chọn mã cuộn',
    placeholderColor: 'Chọn màu',
    table: {
      header: {
        coilID: 'Mã cuộn',
        coilName: 'Tên cuộn',
        thickness: 'Độ dày',
        color: 'Màu',
        aging: 'Thời gian lưu kho',
        lengthBalance: 'Chiều dài còn lại (m)',
        remainedWeight: 'Trọng lượng còn lại (Kg)',
        remainedWeightAbbreviation: 'Re. Weight (kg)',
        transferAmount: 'Số lượng chuyển tiếp (m)',
        allRemainingLength: 'Tất cả chiều dài còn lại',
      }
    }
  },
  profileTransactionManage: {
    breadcrumb1: 'Trang chủ',
    breadcrumb2: 'Tồn Kho',
    breadcrumb3: 'Chuyển tiếp cuộn',
    breadcrumb4: 'Giao dịch cuộn',
    title: 'Tồn Kho > Profile Transfer > Profile Transaction',
    placeholder: 'Chọn nhóm sản phẩm',
    placeholderThickness: 'Chọn độ dày',
    placeholderCoilID: 'Chọn mã cuộn',
    placeholderColor: 'Chọn màu',
    table: {
      header: {
        coilID: 'Mã cuộn',
        coilName: 'Tên cuộn',
        profileType: 'Loại sóng',
        thickness: 'Độ dày (BMT)',
        color: 'Màu',
        quantity: 'Số lượng',
        aging: 'Thời gian lưu kho',
        lengthBalance: 'Chiều dài còn lại (m)',
        remainedWeight: 'Trọng lượng còn lại (Kg)',
        remainedWeightAbbreviation: 'Re. Weight (kg)',
        transferAmount: 'Số lượng chuyển tiếp (m)',
        allRemainingLength: 'Tất cả chiều dài còn lại',
      }
    }
  },
  coilTransferHistory: {
    breadcrumb1: 'Trang chủ',
    breadcrumb2: 'Tồn Kho',
    breadcrumb3_1: 'Lịch sử chuyển tiếp cuộn',
    breadcrumb3_2: 'Lịch sử bản vẽ cuộn',
    breadcrumb3_3: 'Lịch sử cuộn bán',
    breadcrumb3_4: 'Lịch Sử Chuyển Cuộn',
    title_1: 'Lịch sử chuyển tiếp cuộn',
    title_2: 'Tồn Kho > Lịch sử bản vẽ cuộn',
    title_3: 'Tồn Kho > Lịch sử cuộn bán',
    title_4: 'Tồn Kho > Lịch Sử Chuyển Cuộn',
    selectOption: {
      all: 'All',
      orderNo: 'Order No.',
      transferFrom: 'Transfer from',
      transferTo: 'Transfer to',
      coilID: 'Coil ID',
      pending: 'Pending',
      transferred: 'Transferred',
      received: 'Received',
    },
    placeholder: 'Tìm kiếm',
    date: 'Ngày',
    dateTo: 'đến',
    selectStartDate: 'Ngày bắt đầu',
    selectEndDate: 'Ngày kết thúc',
    buttonSearch: 'Tìm kiếm',
    label: {
      category: 'Category',
      status: 'Status'
    },
  },
  profileTransferHistory: {
    breadcrumb1: 'Trang chủ',
    breadcrumb2: 'Tồn Kho',
    breadcrumb3_1: 'Lịch sử chuyển tiếp cuộn',
    breadcrumb3_2: 'Lịch sử bản vẽ cuộn',
    breadcrumb3_3: 'Lịch sử cuộn bán',
    breadcrumb3_4: 'Lịch sử chuyển cuộn',
    title_1: 'Tồn Kho > Lịch sử chuyển tiếp cuộn',
    title_2: 'Tồn Kho > Lịch sử bản vẽ cuộn',
    title_3: 'Tồn Kho > Lịch sử cuộn bán',
    title_4: 'Tồn Kho > Lịch sử chuyển tiếp cuộn',
    selectOption: {
      all: 'Tất cả',
      orderNo: 'Mã đơn hàng',
      companyName: 'Tên công ty',
      phoneNumber: 'Số điện thoại',
      coilID: 'Mã cuộn',
    },
    placeholder: 'Tìm kiếm',
    date: 'Ngày',
    dateTo: 'đến',
    selectStartDate: 'Ngày bắt đầu',
    selectEndDate: 'Ngày kết thúc',
    buttonSearch: 'Tìm kiếm',
  },
  profileMappingHistory: {
    breadcrumb1: 'Trang chủ',
    breadcrumb2: 'Tồn Kho',
    breadcrumb3: 'Lịch sử bản vẽ cuộn',
    title: 'Tồn Kho > Lịch sử bản vẽ cuộn',
    selectOption: {
      all: 'All',
      orderNo: 'Số đơn hàng',
      companyName: 'Tên công ty',
      phoneNumber: 'Số điện thoại',
      profileID: 'Mã sóng',
    },
    placeholder: 'Search',
    date: 'Ngày',
    dateTo: 'đến',
    selectStartDate: 'Ngày bắt đầu',
    selectEndDate: 'Ngày kết thúc',
    buttonSearch: 'Search',
  },
  adAndRfManage: {
    breadcrumb1: 'Trang chủ',
    breadcrumb2: 'Tồn Kho',
    breadcrumb3: 'Chuyển tiếp cuộn',
    breadcrumb4_1: 'Yêu cầu tạo mới đại lý ủy quyền / xưởng cán / Toko',
    breadcrumb4_2: 'Yêu cầu tạo  đại lý ủy quyền / xưởng cán',
    title_1: 'Yêu cầu tạo mới đại lý ủy quyền / Toko',
    title_2: 'Yêu cầu tạo  đại lý ủy quyền / xưởng cán',
    step: {
      regis: 'Đăng ký',
    },
    form: {
      regis: {
        title: 'Đăng ký',
        customerType: 'Loại khách hàng',
        checkbox: {
          rollFormer: 'Xưởng cán',
          authorisedDealer: 'Đại lý ủy quyền',
        }
      }
    }
  },
  report: {
    breadcrumb1: 'Trang chủ',
    breadcrumb2: 'Báo cáo',
    breadcrumb3: 'Báo cáo bán hàng',
    title: 'Report > Báo cáo bán hàng',
    date: 'Ngày',
    dateTo: 'đến',
    selectStartDate: 'Ngày bắt đầu',
    selectEndDate: 'Ngày kết thúc',
    buttonSearch: 'Tìm kiếm',
    totalSalesReportInformation: 'Tổng số báo cáo bán hàng: thông tin',
    totalSalesReportTotalLength: 'Tổng số báo cáo bán hàng: Tổng chiều dài',
    totalSalesReportCustomer: 'Tổng số báo cáo bán hàng: Khách hàng',
    buyIn: 'Mua vào',
    sellOut: 'Bán ra',
    mappingIn: 'Mapping in',
    mappingOut: 'Mapping Out',
    transferIn: 'chuyển giao',
    transferOut: 'Chuyển ra ngoài',
    averageSales: 'Doanh số trung bình',
    noOfCustomers: 'Số khách hàng',
    rewardPoint: 'Rewards points',
    warranty: 'Warranty',
    revenue: 'Revenue',
    point: 'Points',
    ready: 'Ready',
    notReady: 'Not Ready',
    volume: 'khối lượng',
    length: 'Chiều dài',
    quantity: 'Số lượng',
    monthly: 'Hàng tháng',
    daily: 'Hàng ngày',
    total: 'Tổng cộng',
    active: 'Kích hoạt',
    form: {
      inOutStock: {
        title: 'Báo cáo hàng trong kho',
        sellIn: 'Mua vào',
        salesOut: 'Bán ra',
        stock: 'Lưu trử',
      },
      inOutTransfer: {
        title: 'In-Out Transfer Report :',
        transferIn: 'Transfer in',
        transferOut: 'Transfer out',
      },
      product: {
        title: 'Sản phẩm',
        salesIn: 'Mua vào',
        salesOut: 'Bán ra',
        report: 'Báo cáo',
      },
      topMember: {
        title: '10 khách hàng có khối lượng và chiều dài lớn nhất',
        subTitle: 'Khối lượng và chiều dài',
        volume: 'Khối lượng',
        length: 'Chiều dài',
      },
      topSalesPersons: {
        title: '10 khách hàng có khối lượng và chiều dài lớn nhất',
        subTitle: 'Doanh số đại diện bởi khối lượng và chiều dài',
        volume: 'Khối lượng',
        length: 'Chiều dài',
      },
      rewardReport: {
        title: 'Báo cáo thưởng',
        subTitle_1: 'Most exchange reward',
        subTitle_2: 'Most exchange customer',
      },
    }
  },
  settings: {
    breadcrumb1: 'Trang chủ',
    breadcrumb2: 'Cài đặt',
    title: 'Cài đặt',
    subtitle: 'Cài đặt Indexes',
    page: {
      goToPage: 'Trang kế tiếp',
      adInfo: 'Thông tin đại lý',
      tnc: 'Điều khoản và điều kiện',
      contactUs: 'Liên hệ với chúng tôi',
      help: 'Help',
    },
  },
  adInfo: {
    breadcrumb1: 'Trang chủ',
    breadcrumb2: 'Cài đặt',
    breadcrumb3: 'Thông tin đại lý',
    breadcrumb3_2: 'Sales Person',
    title: 'Cài đặt > Thông tin đại lý ủy quyền',
    title_2: 'Cài đặt > Sales Person',
    form: {
      info: {
        title: 'Thông tin đại lý',
      },
      changePassword: {
        title: 'Đổi mật khẩu',
        changeYourPassword: 'Thay đổi mật khẩu của bạn',
        passwordPolicies: 'Chính sách thay đổi mật khẩu',
        passwordMinimum_1: 'ít nhất 8 kí tự',
        passwordMinimum_2: 'ít nhất 1 kí tự chữ',
        passwordMinimum_3: 'ít nhất 1 kí tự số',
      }
    }
  },
  tnc: {
    breadcrumb1: 'Trang chủ',
    breadcrumb2: 'Cài đặt',
    breadcrumb3: 'Điều khoản và điều kiện',
    title: 'Cài đặt > Điều khoản và điều kiện',
    form: {
      tnc: {
        title: 'Điều khoản và điều kiện',
      },
    }
  },
  contactUs: {
    breadcrumb1: 'Trang chủ',
    breadcrumb2: 'Cài đặt',
    breadcrumb3: 'Liên hệ với chúng tôi',
    title: 'Cài đặt  > Liên hệ với chúng tôi',
    form: {
      contactUs: {
        title: 'Liên hệ với chúng tôi',
        hotline: 'Hotline',
        phoneNo: 'Số điện thoại',
        fax: 'Fax',
        website: 'Website',
      },
    }
  },
  help: {
    breadcrumb1: 'Trang chủ',
    breadcrumb2: 'Cài đặt',
    breadcrumb3: 'Giúp đỡ',
    title: 'Settings > Help',
    form: {
      contactUs: {
        title: 'Liên hệ với chúng tôi',
        hotline: 'Số điện thoại nóng',
        phoneNo: 'Số điện thoại',
        fax: 'Fax',
        website: 'Website',
      },
    },
    label: {
      coilTranfer: 'Coil Transfer',
      stepGuildline: 'Steps and Guideline'
    },
    button: {
      buttonSubmit: 'Submit',
    },
    tab: {
      title: 'Please enter information to report coil transfer issue',
      label: {
        coilId: 'Coil ID',
        reportProblem: 'Report Problem',
      },
      field: {
        placeholderInsertCoilId: 'Insert Coil ID',
        placeholderInsertProblem: 'Insert Problem',
      }
    }
  },
  salesPerson: {
    breadcrumb1: 'Home',
    breadcrumb2: 'Settings',
    breadcrumb3: 'Sales Person',
    breadcrumb4: 'Add New Sales',
    breadcrumb5: 'Edit Sales',
    titles: {
      salesPerson: 'Settings > Sales Person',
      addNewSales: 'Settings > Sales Person > Add New Sales',
      editSales: 'Settings > Sales Person > Edit Sales',
    },
    buttons: {
      addNewSales: 'Add New Sales',
      export: 'Export File',
    },
    tables: {
      salesPerson: {
        number: 'No.',
        name: 'Name',
        nickName: 'Nick Name',
        jobPosition: 'Job Position',
        mobile: 'Mobile',
        points: 'Points',
        actions: 'Actions',
      }
    },
    tooltips: {
      viewInformation: 'View Information',
      edit: 'Edit',
    },
    options: {
      incentiveSales: 'Incentive sales, successful sales',
      disputeClosing: 'Dispute closing and closing professionals',
      professionalNegotiating: 'Professional negotiating',
      basicKnowledge: 'Basic knowledge of products',
      courseForExperts: 'A course for experts in the use of products.',
    },
    headings: {
      salesInformation: 'Sales Information',
      blueScopeTraining: 'BlueScope Training (Maximum 5)',
      salesSkill: 'Sales skills training course',
      productKnowledge: 'Product knowledge skills training course',
      specificCourse: 'Specific Course',
      independentCourse: 'Independent Course',
      maximum5: '(Maximum 5)',
    },
  },
  uploadTransactionIndexes: {
    breadcrumb1: 'Trang chủ',
    breadcrumb2: 'Giao dịch đăng lên',
    title: 'Giao dịch đăng lên',
    subtitle: 'Upload Transaction Indexes',
    page: {
      goToPage: 'Trang kế tiếp',
      uploadTransactionManagement: 'Quản lý giao dịch đăng lên',
      uploadTransactionHistory: 'Lịch sử giao dịch đăng lên',
    },
  },
  uploadTransaction: {
    breadcrumb1: 'Trang chủ',
    breadcrumb2: 'Giao dịch đăng lên',
    breadcrumb3: 'Quản lý giao dịch đăng lên',
    title: 'Upload Transaction',
    step: {
      validation: {
        title: 'Thẫm định',
      },
      information: {
        title: 'Information',
      },
    },
    form: {
      validation: {
        title: 'Validation',
        placeholderUploadTransaction: 'Kéo và thả tệp hoặc trình duyệt của bạn',
        buttonRemoveFile: 'Xóa tệp',
        buttonValidateTransaction: 'Giao dịch phê duyệt',
        buttonDownloadTemplate: 'Tải mẫu',
        checkingInProcess: 'Kiểm tra quy trình',
        formatFileIncorrect: 'Tệp định dạng không chính xác.Vui lòng tải lên định dạng csv',
      },
      information: {
        title: 'Information',
        table: {
          header: {
            status: 'Tình trạng',
            saleperson: 'Nhân viên bán hàng',
            phone: 'Số điện thoại',
            email: 'Email',
            coilId: 'Mã cuộn',
            lengthSold: 'chiều dài bán được',
            projectName: 'Tên dự án',
            homeowner: 'Chủ nhà',
            purchaseDate: 'Ngày đặt hàng',
            purchaseValue: 'Gía trị hàng mua',
            invoiceNumber: 'Số hóa đơn',
            invoiceLine: 'Dòng hóa đơn',
            error: 'Lỗi',
          },
        },
        buttonUploadTransaction: 'Giao dịch đăng lên',
        uploadingInProcess: 'Xử lí để tải lên',
        modal: {
          title: 'Chi tiết lỗi',
        },
        uploadTransactionSuccess: 'Giao dịch đăng lên thành công',
      },
    },
  },
  uploadTransactionHistory: {
    breadcrumb1: 'Home',
    breadcrumb2: 'Giao dịch đăng lên',
    breadcrumb3: 'Lịch sử giao dịch đăng lên',
    title: 'Lịch sử giao dịch đăng lên',
    form: {
      placeholderSearchFileName: 'Tìm kiếm tên tệp',
      placeholderSearchSalesTransaction: 'Tìm kiếm số hóa đơn, tên dự án',
      buttonSearch: 'Search',
    },
    table: {
      header: {
        id: 'ID',
        fileName: 'Tên tệp',
        uploadedDate: 'Ngày tải lên',
        actions: 'Hành động',
        buttonDetail: 'Chi tiết',
        status: 'Tình trạng',
        saleperson: 'Người bán hàng',
        phone: 'Điện thoại',
        email: 'Email',
        coilId: 'Mã cuộn',
        lengthSold: 'Chiều dài bán được',
        projectName: 'Tên dự án',
        homeowner: 'Chủ nhà',
        purchaseDate: 'Ngày đặt hàng',
        purchaseValue: 'Gía trị mua hàng',
        invoiceNumber: 'Số hóa đơn',
        invoiceLine: 'Dòng hóa đơn',
        error: 'Error',
      },
    },
  },
  container: {
    customerContentInfo: {
      title: 'Thông tin khách hàng',
      name: 'Tên',
      trueBluePoints: 'Điểm TrueBlue',
      point: 'Điểm',
      nickname: 'Biệt danh',
      phoneNo: 'Số điện thoại',
      customerType: 'Loại khách hàng',
      buttonSelectCustomer: 'Chọn khách hàng',
    },
    customerContactInfo: {
      title: 'Thông tin liên lạc',
    },
    customerPointTable: {
      table: {
        header: {
          id: 'Số CMND',
          customerName: 'Tên khách hàng',
          phoneNo: 'Số điện thoại',
          email: 'Email',
          tbPoints: 'Điểm TrueBlue',
          action: 'Tùy chọn',
        },
        action: {
          buttonViewDetail: 'Xem chi tiết',
        }
      }
    },
    customerPointDetail: {
      title: 'Thông tin khách hàng',
      name: 'Tên',
      nickname: 'Biệt danh',
      phoneNo: 'Số điện thoại',
      customerType: 'Loại khách hàng',
      currentTrueBluePoints: 'Current TrueBlue Points',
      level: 'Level',
      point: 'Điểm',
      expiringDate: 'Ngày hết hạn',
      tbPoints: 'Điểm hết hạn',
      trained: 'Trained',
    },
    latestSaleTransaction: {
      titleSaleTransaction: 'Giao dịch gần nhất',
      titleOrder: 'Tóm tắt đơn hàng',
      contentBlank: 'Chọn sản phẩm cho đơn hàng',
      buttonSelectProduct: 'Chọn sản phẩm',
      hintDeleteTransaction: 'Transaction can be deleted within 7 days after created. Any action will be done after the period, please contact Bluescope.',
      table: {
        header: {
          date: 'Ngày',
          saleTransactionID: 'Mã giao dịch',
          coilName: 'Tên cuộn',
          coilID: 'Mã cuộn',
          order: 'Tóm tắt đơn hàng',
          trueBluePoints: 'Điểm TrueBlue',
          salesperson: 'Nhân viên giao dịch',
          profileType: 'Loại sóng',
          productGroup: 'Nhóm sản phẩm',
          length: 'Chiều dài',
          orderQuantity: 'Đơn đặt hàng(số lượng)',
        },
      }
    },
    preTransactionList: {
      titlePreTransactionList: 'Pre-Transaction List (To manage Pre-Transaction to Sales Order please select Product group item.)',
      table: {
        header: {
          date: 'Date',
          preTransactionID: 'Pre-Transaction ID',
          productGroup: 'Product group',
          projectName: 'Project Name',
          companyName: 'Company Name',
          salesOrder: 'Sales Orders',
          action: 'Action'
        }
      },
    },
    orderSummary: {
      table: {
        header: {
          coilID: 'Mã cuộn',
          coilName: 'Tên cuộn',
          order: 'Tóm tắt đơn hàng',
          earnPoints: 'Điểm có được',
          profileType: 'Loại sóng',
          productGroup: 'Nhóm sản phẩm',
          length: 'Chiều dài',
          orderQuantity: 'Đơn đặt hàng(Số lượng)',
          revenue: 'Thu nhập',
        },
        footer: {
          revenue: 'Revenue',
          summaryGetPoints: 'Nhận điểm TrueBlue',
          point: 'Điểm',
          yourTotalPoints: 'Tổng số điểm',
        }
      }
    },
    orderProduct: {
      title: 'Giao dịch bán hàng > Đơn hàng',
      selectProductGroup: 'Chọn nhóm sản phẩm',
      selectThickness: 'Chọn độ dày',
      selectCoilID: 'Chọn mã cuộn',
      selectColor: 'Chọn màu',
      selectOption: {
        all: 'Tất cả',
      },
      selectProfileType: 'Chọn loại sóng',
      selectLength: 'Chọn chiều dài',
      table: {
        header: {
          coilID: 'Mã cuộn',
          coilName: 'Tên cuộn',
          thickness: 'Độ dày',
          thicknessBMT: 'Độ dày(BMT)',
          thicknessVt: 'Độ dày',
          color: 'Màu',
          aging: 'Aging',
          lengthBalance: 'Chiều dài còn lại (m)',
          remainedWeight: 'Trọng lượng còn lại (kg)',
          length: 'Chiều dài (m.)',
          weight: 'Trọng lượng (Kg.)',
          orders: 'Đơn đặt hàng',
          profileType: 'Loại sóng',
          productGroup: 'Nhóm sản phẩm',
          quantity: 'Số lượng',
          orderQuantity: 'Đơn đặt hàng(Số lượng)',
          revenue: 'Thu nhập',
        }
      }
    },
    saleTransactionHistory: {
      hintDeleteTransaction: 'Transaction can be deleted within 7 days after created. Any action will be done after the period, please contact Bluescope.',
      table: {
        header: {
          date: 'Ngày',
          stID: 'Mã đơn hàng',
          stIDAbbreviation: 'Mã đơn hàng',
          coilName: 'Tên cuộn',
          coilID: 'Mã cuộn',
          customerName: 'Tên khách hàng',
          customerNameAbbreviation: 'Tên',
          phoneNo: 'Số điện thoại',
          order: 'Tóm tắt đơn hàng',
          purchaseValue: 'Gía trị mua hàng',
          purchaseValueAbbreviation: 'P. value',
          tbPoints: 'Điểm TrueBlue',
          tbPointsAbbreviation: 'Điểm TrueBlue',
          numberOfRewards: 'Số phần thưởng',
          numberOfRewardsAbbreviation: 'N.O.R.',
          salesperson: 'Nhân viên giao dịch',
          action: 'Tùy chọn',
          profileTypeAbbreviation: 'PF Type',
          productGroupAbbreviation: 'PD Group',
          profileType: 'Loại sóng',
          productGroup: 'Nhóm sản phẩm',
          length: 'Chiều dài',
          orderQuantity: 'Đơn đặt hàng',
          invoiceNumber: 'Số hóa đơn',
          invoiceLineItem: 'Dòng hóa đơn',
          revenue: 'Thu nhập',
          remainingLength: 'Remaining Length',
          pickQuantity: 'Pick Quantity',
        },
        action: {
          buttonDelete: 'Xóa',
        }
      }
    },
    warrantyList: {
      tableWarranty: {
        header: {
          select: 'Select',
          createDate: 'Ngày khởi tạo',
          salesTransactionID: 'Mã giao dịch',
          salesTransactionIDAbbreviation: 'Sales T. ID',
          contactName: 'Tên liên hệ',
          customerName: 'Tên khách hàng',
          warrantyNo: 'Số thẻ bảo hành',
          projectName: 'Tên dự án',
          mobileNo: 'Mobile No.',
          status: 'Tình trạng',
          action: 'Tùy chọn',
          invoiceNumber: 'Số hóa đơn',
          invoiceLineItem: 'Dòng hóa đơn',
          revenue: 'Revenue',
          purchaseDate: 'Ngày đặt hàng',
        },
        action: {
          viewMore: 'Xem nhiều hơn',
        }
      },
      tableWarrantyDetail: {
        header: {
          coilID: 'Mã cuộn',
          orderDate: 'Ngày đặt hàng',
          orderNo: 'Mã đơn hàng',
          coilName: 'Tên cuộn',
          productGroup: 'Nhóm sản phẩm',
          order: 'Tóm tắt đơn hàng',
          action: 'Tùy chọn',
        },
        action: {
          viewProjectDetail: 'Xem chi tiết dự án',
          buttonViewWarranty: 'Xem chi tiết bảo hành',
          buttonEditWarranty: 'Sửa lại bảo hành',
        }
      },
    },
    warrantyTemplate: {
      breadcrumb1: 'Home',
      breadcrumb2: 'Template',
      title: 'Template',
      noWarrantyTemplate: 'No Warranty Template',
      noM3ProductCode: 'No M3 Product Code',
      noM3ProductGroup: 'No M3 Product Group',
      noM3ProductColor: 'No M3 Product Color',
      filter: {
        label: {
          m3ProductCode: 'M3 Product Code',
          m3ProductGroup: 'M3 Product Group',
          m3ProductColor: 'M3 Product Color',
          searchTemplateName: 'Search Template Name',
        },
      },
      table: {
        header: {
          no: 'No.',
          templateName: 'Template Name',
          m3ProductCode: 'M3 Product Code',
          m3ProductGroup: 'M3 Product Group',
          m3ProductColor: 'M3 Product Color',
          action: 'Action',
        },
        body: {
          codes: 'Code(s)',
          groups: 'Group(s)',
          colors: 'Color(s)',
        },
      },
      placeholder: {
        selectM3ProductCode: 'Select Code',
        selectM3ProductGroup: 'Select Group',
        selectM3ProductColor: 'Select Color',
        searchM3ProductCode: 'Search M3 Product Code',
        searchM3ProductGroup: 'Search M3 Product Group',
        searchM3ProductColor: 'Search M3 Product Color',
        searchTemplateName: 'Search Template Name',
      },
      modal: {
        title: {
          m3ProductCode: 'M3 Product Code',
          m3ProductGroup: 'M3 Product Group',
          m3ProductColor: 'M3 Product Color',
        },
        tabs: {
          project: 'Project',
          residential: 'Residential',
          templateEn: 'Template EN',
          templateTh: 'Template TH',
        },
      },
    },
    redemptionRewardHistory: {
      table: {
        header: {
          redID: 'Mã phần thưởng',
          redemptionID: 'Mã phần thưởng',
          rewards: 'Điểm thưởng',
          customerID: 'Customer ID',
          cusID: 'Mã khách hàng',
          customerName: 'Tên khách hàng',
          cusName: 'Tên khách hàng',
          phoneNo: 'Số điện thoại',
          salesperson: 'Nhân viên giao dịch',
          createDate: 'Ngày khởi tạo',
          giftDate: 'Ngày nhận phần thưởng',
          gpuDate: 'G. Pu. date',
          deliveryDate: 'Ngày giao hàng',
          tbPoints: 'Điểm sử dụng',
          tbPointsAbbreviation: 'U. Points',
          numberOfRewards: 'Số điểm thưởng',
          numberOfRewardsAbbreviation: 'N.O.R.',
          stockType: 'Loại lưu trữ',
          action: 'Tùy chọn',
        },
        action: {
          buttonEdit: 'Sửa'
        }
      }
    },
    rewardStock: {
      table: {
        header: {
          id: 'Số CMND',
          rewards: 'Điểm thưởng',
          inStock: 'Hàng có sẵn trong kho',
          rewardPoint: 'Điểm thưởng',
        },
      }
    },
    purchaseHistoryList: {
      table: {
        header: {
          salesDate: 'Ngày bán hàng',
          salesTransaction: 'Giao dịch bán hàng T1',
          coilDetail: 'Coil Detail',
          coilNumber: 'Số cuộn',
          purchaseLength: 'Chiều dài đặt hàng',
          tbPoints: 'Điểm TrueBlue',
          salesRepresentative: 'Đại diện bán hàng',
        },
      }
    },
    productQuantityList: {
      table: {
        header: {
          productGroup: 'Nhóm sản phẩm',
          noCoils: 'Số lượng cuộn',
          weight: 'Trọng lượng (Kg.)',
          length: 'Chiều dài (m.)',
        },
        footer: {
          total: 'Tổng cộng',
          buttonViewDetail: 'Xem chi tiết',
        }
      }
    },
    coilTransferTable: {
      table: {
        header: {
          id: 'Số CMND',
          adRF: 'AD/RF',
          phoneNo: 'Số điện thoại',
          email: 'Email',
          type: 'Type',
          province: 'Tỉnh',
          select: 'Chọn',
        },
      }
    },
    profileTransferTable: {
      table: {
        header: {
          id: 'Số CMND',
          adRF: 'AD/RF',
          phoneNo: 'Số điện thoại',
          email: 'Email',
          type: 'Type',
          province: 'Tỉnh',
          select: 'Chọn',
        },
      }
    },
    coilTransferAdInfo: {
      companyName: 'Tên công ty',
      customerType: 'Loại khách hàng',
      phoneNo: 'Số điện thoại',
    },
    profileTransferAdInfo: {
      companyName: 'Tên công ty',
      customerType: 'Loại khách hàng',
      phoneNo: 'Số điện thoại',
    },
    coilTransferHistoryTable: {
      table: {
        header: {
          date: 'Ngày',
          coilID: 'Mã cuộn',
          coilName: 'Tên cuộn',
          companyName: 'Tên công ty',
          profile: 'Sóng',
          profileID: 'Mã cuộn chuyển tiếp',
          quantity: 'Số tấm hoặc số cây - cái',
          quantityAbbreviation: 'Số lượng ….cái',
          length: 'Chiều dài trên một tấm hoặc một cây - m',
          lengthAbbreviation: 'Chiều dài',
          totalLength: 'Tổng chiều dài',
          orderNo: 'Mã đơn hàng',
          phoneNo: 'Số điện thoại',
          transferAmount: 'Số lượng chuyển tiếp (m)',
          lengthFullText: 'Chiều dài (m.)',
          weight: 'Trọng lượng (Kg.)',
          contactName: 'Tên liên lạc',
          quantityUnit: 'Số lượng / đơn vị',
          lengthUnit: 'Chiều dài / Đơn vị',
          tranferFrom: 'Transfer from',
          tranferTo: 'Tranfer to',
          status: 'Status',
        }
      }
    },
    profileTransferHistoryTable: {
      table: {
        header: {
          date: 'Ngày',
          coilID: 'Mã cuộn',
          coilName: 'Tên cuộn',
          companyName: 'Tên công ty',
          profile: 'Sóng',
          quantity: 'Số tấm hoặc số cây - cái',
          quantityAbbreviation: 'Số lượng ….Cái',
          length: 'Length per sheet or per stick – m',
          lengthAbbreviation: 'Length ... m',
          totalLength: 'Total length (m)',
          orderNo: 'Mã đơn hàng',
          phoneNo: 'Số điện thoại',
          transferAmount: 'Số lượng chuyển tiếp (m)',
          lengthFullText: 'Chiều dài (m.)',
          weight: 'Trọng lượng (Kg.)',
          contactName: 'Tên liên hệ',
          quantityUnit: 'Số lượng / đơn vị',
          lengthUnit: 'Chiều dài / đơn vị',
          quantityProfile: 'Số lượng',
          lengthProfile: 'Chiều dài',
        }
      }
    },
    cmsUserManual: {
      title: 'Các bước và hướng dẫn',
    }
  },
  component: {
    section: {
      coilDetail: {
        title: 'Chi tiết sản phẩm',
        roofAndWall: 'Mái và vách - Sq.m :',
        purlin: 'Xà gồ mái và vách - Sq.m. :',
        truss: 'Khung và kèo  - Sq.m. :',
        coolRoom: 'Vách panel phòng mát - Sq.m :',
        decking: 'Tấm sàn - Sq.m :',
        mmx: 'MMx :',
        mmsdf: 'MMsdf :',
        mmdf: 'MMdf :',
      }
    },
  },
  modal: {
    newsAndPromotion: {
      buttonBack: 'Quay trở lại',
    },
    info: {
      notice: 'Chú ý',
    },
    customerProfile: {
      title: 'Hồ sơ khách hàng',
      placeholder: 'Tìm kiếm bằng mã, tên, số điện thoại',
      buttonSearch: 'Tìm kiếm',
      buttonCancel: 'Hủy bỏ',
      buttonSave: 'Lưu',
      buttonSelect: 'Chọn',
      table: {
        header: {
          id: 'Số CMND',
          name: 'Tên',
          phoneNo: 'Số điện thoại',
          email: 'Email',
          points: 'Điểm TrueBlue',
          select: 'Chọn',
        },
        action: {
          details: 'Chi tiết'
        }
      }
    },
    confirm: {
      title: 'Xác nhận',
    },
    confirmDelete: {
      title: 'Xác nhận xóa',
    },
    warrantyChooseCategory: {
      title: 'Chọn loại',
      residential: 'Dân dụng',
      nonResidential: 'Dự án',
      residentialExample: 'Nhà riêng, cửa hàng..vv',
      nonResidentialExample1: 'Nhà máy, kho, trung tâm thương mại…vv',
      nonResidentialExample2: 'including projects that are allocated villages.',
    },
    share: {
      title: 'Social share',
    },
    selectDateTime: {
      title: 'Chỉnh sửa ngày nhận quà',
      placeholder: 'Chọn ngày nhận quà',
    },
    hardwareAndTokoShop: {
      title: 'Đại lý ủy quyền / xưởng cán / Toko',
      placeholder: 'Tìm kiếm bằng mã, Đại lý ủy quyền / tên xưởng cán, số điện thoại',
      buttonSearch: 'Tìm kiếm',
      table: {
        header: {
          id: 'Số CMND',
          name: 'Tên đại lý ủy quyền / xưởng cán / Toko',
          phoneNo: 'Số điện thoại',
          email: 'Email',
          select: 'Chọn',
        },
      }
    },
    adAndRf: {
      title: 'Danh sách AD / RF',
      placeholder: 'Tìm kiếm bằng mã, AD / RF, số điện thoại',
      buttonSearch: 'Tìm kiếm',
    },
    update: {
      title: 'Chú ý ứng dụng',
      message: 'Phiên bản Trueblue mới sẵng sàng',
      buttonCancel: 'Hủy bỏ',
      buttonSubmit: 'Cập nhật',
    },
    termsAndConditions: {
      title: 'Điều kiện và điều khoản để tạo bảo hành',
      subTitle: 'Điều kiện và điều khoản',
      condition: 'Chấp nhận điều kiện và điều khoản',
    },
    createMultipleWarranty: {
      title: 'Create Multiple Warranty:',
      placeholder: 'Search by Contractor Name',
      step1: {
        title: 'Contractor',
        description: 'Input coil length to contractor for generate warranty.',
      },
      step2: {
        title: 'Confirm',
        description: 'Confirm to generate warranty.',
        remainingCoilLength: 'Remaining Coil Length',
      },
      label: {
        invoiceId: 'Invoice no.',
        salesOrder: 'Sales Order',
        coilId: 'Coil ID',
        totalLength: 'Total Length',
        enterLength: 'Enter Length',
        errorLengthMessage: 'Coli length can’t more than total length.',
        coilLength: 'Coil Length',
        waitingForGenerating: 'Your warranty are waiting for generating process.',
        success: 'Success Generating Warranty',
      },
      table: {
        header: {
          id: 'No.',
          contractorName: 'Contractor Name',
          coliLength: 'Coli Length',
        },
      },
      button: {
        search: 'Search',
        close: 'Close',
        back: 'Back',
        continue: 'Continue',
        generateWarranty: 'Generate Warranty',
        goToWarranty: 'Go to Warranty',
      },
    },
    saleOrder: {
      table: {
        header: {
          saleTransactionId: 'Sales Transaction ID',
          coilName: 'Coil Name',
          coilId: 'Coil ID',
          order: 'Order (m.)',
          linkPreTransactionId: 'Link Pre-Transaction ID',
          action: 'Action',
          linkProject: 'Link Project',
        }
      },
      label: {
        noSaleTransactionIdForLinkToProject: ' No Sales Transaction ID for Link to Project',
        selectProject: 'Select Project',
      },
    },
  },
  pagination: {
    page: 'Trang',
    previous: 'Quay lại',
    next: 'Tiếp theo',
  },
  field: {
    buttonAccept: 'Accept',
    buttonPrintProductUpdateImage: 'Print product update image',
    buttonRequestToEdit: 'Request to edit',
    buttonProjectDocument: 'Project document',
    buttonDelete: 'Delete',
    buttonLinkPreTransactionId: 'Link Pre-Transaction ID',
    buttonGenerateWarrantyFromCurrentSaleTransaction: 'Generate Warranty from Current Sales Transaction',
    buttonNewScan: 'New Scan',
    buttonExport: 'Xuất khẩu',
    buttonPreview: 'Xem trước',
    buttonBack: 'Quay lại',
    buttonNext: 'Next',
    buttonNextStep: 'Next',
    buttonEdit: 'Sửa',
    buttonEditProfile: 'Sửa hồ sơ',
    buttonShare: 'Chia sẽ',
    buttonDownload: 'Tải về',
    buttonCancel: 'Hủy',
    buttonSave: 'LƯU',
    buttonSaveCapitalize: 'Lưu',
    buttonSelect: 'Chọn',
    buttonSubmit: 'Nộp',
    buttonConfirm: 'Xác nhận',
    buttonContinue: 'Tiếp tục',
    buttonSaveAndContinue: 'LƯU và tiếp tục',
    buttonSaveAndSendOrders: 'Lưu và gửi đơn hàng',
    buttonSaveAndTransfer: 'Lưu và chuyển tiếp',
    buttonConfirmAndSAVE: 'Xác nhận và lưu',
    buttonSaveAsDraft: 'Lưu bản nháp',
    buttonChangePassword: 'Chọn mật khẩu',
    buttonUpdate: 'Cập nhật',
    buttonClose: 'Đóng',
    buttonInputQuantity: 'Nhập Số lượng',
    buttonSend: 'Gửi',
    buttonLogin: 'Đăng nhập',
    buttonAcceptTermsAndConditions: 'Chấp nhận điều kiện và điều khoản',
    buttonToLogin: 'Back to Login',
    buttonApprove: 'Approve',
    buttonReSendAuthenticationLink: 'Re-Send Authentication Link',
    buttonRequireDocumentary: 'Require Documentary',
    buttonSearch: 'Search',
    buttonGenerateDocumentary: 'Generate Documentary',
    buttonViewDocument: 'View Document',
    imageUpload: 'Tải ảnh lên',
    phoneNo: 'Số điện thoại',
    phoneNoPlaceholder: 'Nhập số điện thoại',
    mobileNo: 'Phone No.',
    mobileNoPlaceholder: 'Insert Phone No.',
    phoneNoHint: 'Vui lòng điền số điện thoại không có số(0) trước',
    fullname: 'Full Name',
    fullnamePlaceholder: 'Insert Full Name',
    companyname: 'Company or Project Name',
    companynamePlaceholder: 'Insert Company or Project Name',
    typeProject: "Type Project",
    typeProjectPlaceholder: "Insert Type Project",
    firstname: 'Tên đầu',
    firstnamePlaceholder: 'Nhập tên đầu',
    lastname: 'Last Name',
    lastnamePlaceholder: 'Nhập tên cuối',
    nickname: 'Biệt danh',
    nicknamePlaceholder: 'Nhập biệt danh',
    dateOfBirth: 'Ngày sinh',
    dateOfBirthPlaceholder: 'Chọn ngày sinh',
    email: 'Email',
    emailPlaceholder: 'Nhập email',
    sms: 'SMS',
    company: 'Công ty',
    companyPlaceholder: 'Nhập công ty',
    idCardNo: 'Số CMND',
    idCardNoPlaceholder: 'Nhập số CMND',
    address: 'Address',
    addressPlaceholder: 'Nhập địa chỉ',
    installationAddress: 'Địa chỉ lắp dựng',
    installationAddressPlaceholder: 'Nhập địa chỉ lắp dựng',
    installationAddressHint: 'Tối đa 500 kí tự',
    industrialEstateName: 'Industrial Estate Name',
    industrialEstateNamePlaceholder: 'Insert Industrial Estate Name',
    warrantyEnvDistanceMarine: "Distance from Marine (m.)",
    warrantyEnvDistanceMarinePlaceholder: "Insert Distance from Marine (m.)",
    warrantyEnvDistanceIndustrial: "Distance from Industrial Estate (m.)",
    warrantyEnvDistanceIndustrialPlaceholder: "Insert Distance from Industrial Estate (m.)",
    province: 'Tỉnh',
    provincePlaceholder: 'Nhập tỉnh',
    provinceTH: 'Tỉnh',
    provinceTHPlaceholder: 'Nhập tỉnh',
    provinceLA: 'Tỉnh',
    provinceLAPlaceholder: 'Nhập tỉnh',
    city: 'Thành phố',
    cityPlaceholder: 'Nhập thành phố',
    cityTH: 'Thành phố',
    cityTHPlaceholder: 'Nhập thành phố',
    cityLA: 'Thành phố',
    cityLAPlaceholder: 'Nhập thành phố',
    subdistrict: 'Quận',
    subdistrictPlaceholder: 'Nhập Quận',
    subdistrictTH: 'Quận',
    subdistrictTHPlaceholder: 'Nhập Quận',
    subdistrictLA: 'Subdistrict ',
    subdistrictLAPlaceholder: 'Nhập quận',
    subdistrictIN: 'Quận,',
    subdistrictINPlaceholder: 'Nhập quận',
    postalCode: 'Postal Code',
    postalCodePlaceholder: 'Insert Postal Code',
    district: 'District',
    districtPlaceholder: 'Nhập quận',
    districtIN: 'District (Kecamatan)',
    districtINPlaceholder: 'Nhập quận',
    alley: 'Alley/Lane',
    alleyPlaceholder: 'Insert Alley/Lane',
    group: 'Group/Hamlet',
    groupPlaceholder: 'Insert Group/Hamlet',
    street: 'Street',
    streetPlaceholder: 'Nhập đường',
    ward: 'Ward',
    wardPlaceholder: 'Nhập phường',
    commentRemark: 'Comments/Remarks',
    commentRemarkPlaceholder: 'Insert Comments/Remarks',
    salesperson: 'Nhân viên giao dịch',
    salespersonPlaceholder: 'Chọn nhân viên giao dịch',
    rmb: 'RMB',
    rmbPlaceholder: 'Nhập thu nhập',
    customerProjectName: 'Khách hàng / Tên dự án',
    customerProjectNamePlaceholder: 'Nhập khách hàng / Tên dự án',
    customerProjectNameHint: 'Tối đa 100 kí tự',
    customerProjectNameHint500: 'Tối đa 500 kí tự',
    additionalProjectInformation: 'Thêm thông tin dự án',
    additionalProjectInformationPlaceholder: 'Nhập thêm thông tin dự án',
    additionalProjectInformationHint: 'Tối đa 500 kí tự',
    customerRemark: 'Khách hàng nhận xét',
    customerRemarkExample: '(Ví dụ: Mái có sóng - xyz m2) (ví dụ: Mái có sóng ABC - xyz)',
    customerRemarkPlaceholder: 'Nhập nhận xét khách hàng',
    customerRemarkHint: 'Tối đa 500 kí tự',
    mobilePhoneNo: 'Số đi động',
    mobilePhoneNoPlaceholder: 'Nhập số di động',
    mobilePhoneNoHint: 'Làm ơn ghi số điện thoại di động không có tiền tố(0)',
    purchaseDate: 'Ngày đặt hàng',
    installationDate: 'Ngày lắp dựng',
    installationDatePlaceholder: 'Chọn ngày lắp dựng',
    installationDateHint: 'Ngày lắp dựng phải trong vòng 6 tháng từ ngày mua hàng',
    inputNumberPlaceholder: 'Nhập số',
    editLength: 'Chỉ chỉnh sửa độ dày thấp',
    editLengthPlaceholder: 'Nhập chỉnh sửa độ dài(m) thấp xuống',
    editLengthPerUnit: 'Chỉ sửa chiều dài(m) thấp xuống',
    editLengthPerUnitPlaceholder: 'Nhập chỉnh sửa độ dài(m) thấp xuống',
    editQuantity: 'Sửa số lượng(tấm / cây)',
    editQuantityPlaceholder: 'Nhập sửa số lượng(tấm / cây)',
    otherReasonsChange: 'Lý do thay đổi khác',
    otherReasonsChangePlaceholder: 'Nhập lý do thay đổi khác',
    adName: 'Tên đại lý được ủy quyền',
    adNamePlaceholder: 'Nhập tên đại lý được ủy quyền',
    businessRegisNo: 'Số đăng kí kinh doanh',
    businessRegisNoPlaceholder: 'Nhập số đăng kí kinh doanh',
    oldPassword: 'Mật khẩu củ',
    oldPasswordPlaceholder: 'Nhập mật khẩu cũ',
    newPassword: 'Mật khẩu mới',
    newPasswordPlaceholder: 'Nhập mật khẩu mới',
    confirmPassword: 'Xác nhận mật khẩu',
    confirmPasswordPlaceholder: 'Nhập xác nhận mật khẩu',
    homeOwnerName: 'Tên chủ nhà',
    homeOwnerNamePlaceholder: 'Nhập tên chủ nhà',
    homeOwnerAddress: 'Địa chỉ chủ nhà',
    homeOwnerAddressPlaceholder: 'Nhập địa chỉ chủ nhà',
    permitLetterNumber: 'Số kí tự cho phép',
    permitLetterNumberPlaceholder: 'Nhập số kí tự cho phép',
    buildingType: 'Loại tòa nhà',
    buildingTypePlaceholder: 'Nhập Loại tòa nhà',
    AreaM2OfBuilding: 'Diện tích xây dựng',
    AreaM2OfBuildingPlaceholder: 'Nhập diện tích xây dựng',
    tokoName: 'Tên Toko',
    tokoNamePlaceholder: 'Nhập tên Toko',
    builderName: 'Tên nhà thầu',
    builderNamePlaceholder: 'Nhập tên nhà thầu',
    productWeight: 'Trọng lượng sản phẩm',
    productWeightPlaceholder: 'Nhập trọng lượng sản phẩm',
    tctThickness: 'Độ dày TCT',
    tctThicknessPlaceholder: 'Nhập độ dày TCT',
    colorName: 'Tên màu',
    colorNamePlaceholder: 'Nhập tên màu',
    productUpdate: 'Product Update',
    application: {
      title: 'Ứng dụng',
      walling: 'Vách',
      roofing: 'Mái',
      roofFrame: 'Khung mái',
      wallFrame: 'Khung vách',
      awning: 'Awning',
    },
    brand: 'Nhãn hiệu',
    brandPlaceholder: 'Nhập nhãn hiệu',
    installationFinishDate: 'Ngày lắp dựng hoàn thành',
    installationFinishDatePlaceholder: 'Nhập ngày lắp dựng hoàn thành',
    authorizedDealerRubberStamp: 'Con dấu của đại lý ủy quyền',
    authorizedDealerRubberStampPlaceholder: 'Nhập con dấu của đại lý ủy quyền',
    originalReceiptInvoice: 'Hóa đơn / biên lai gốc',
    originalReceiptInvoicePlaceholder: 'Nhập hóa đơn / biên lai gốc',
    residentialCommercial: 'Nhà ở / thương mại',
    residentialCommercialPlaceholder: 'Nhập nhà ở / thương mại',
    name: 'tên',
    namePlaceholder: 'Nhập tên',
    postcode: 'Mã bưu điện',
    postcodePlaceholder: 'Nhập Mã bưu điện',
    typesOfProductPurchased: 'Loại sản phẩm mua',
    typesOfProductPurchasedPlaceholder: 'Nhập loại sản phẩm mua',
    purposeOfPurchasedZacs: 'Mục đích của Zacs mua hàng',
    purposeOfPurchasedZacsPlaceholder: 'Nhập mục đích của Zacs mua hàng',
    dateOfPurchase: 'Ngày mua hàng',
    dateOfPurchasePlaceholder: 'Nhập ngày mua hàng',
    inoivceNo: 'Số hóa đơn',
    inoivceNoPlaceholder: 'Nhập số hóa đơn',
    password: 'Mật khẩu',
    passwordPlaceholder: 'Nhập mật khẩu',
    rememberMe: 'Nhớ',
    building: 'Tòa nhà',
    buildingPlaceholder: 'Nhập tòa nhà',
    environment: {
      verySevereMarme: 'Biển rất khắc nghiệp',
      verySevereMarmeDetail: 'Vui lòng tham khảo đại diện bán hàng - Bao gồm các khu vực ngoài khơi và Khu vực cách mép sóng biển tan trong vòng 100m',
      setereMarine: 'Biển khắc nghiệt',
      setereMarineDetail: 'Thông thường là khu vực đất liền cách bờ biển từ 101m cho tới khoảng 200m.Đối với khu vực gió lớn, có thể mở rộng sâu vô đất liền tùy theo địa lý và gió chủ đạo của khu vực đó(hoặc nhỏ hơn 100m từ khu vực sóng lặng, ví dụ: cảng biển).Đặc điểm của môi trường này là lượng muối cao và sương mù, vết muối và muối tích tụ trên những phần công trình không được rửa trôi.Thông thường dễ thấy là sự giảm giá trị rõ rệt của hầu hết các vật liệu của tòa nhà.',
      marine: 'Biển',
      marineDetail: 'Thông thường nằm trong khu vực cách bờ biển từ 201m đến 400m, tuy nhiên gió chủ đạo mạnh có thể mở rộng khoảng cách này(hoặc từ 101m đến 200m từ khu vực sóng lặng).Đặc điểm của môi trường này là thỉnh thoảng xuất hiện mùi muối nhẹ, hơi muối đọng lại trên khu vực không được rửa trôi.',
      moderateMarine: 'Môi trường biển',
      moderateMarineDetail: 'Thông thường nằm trong khu vực cách bờ biển từ 401m đến 1000m, tuy nhiên gió chủ đạo mạnh có thể mở rộng khoảng cách này(hoặc từ 201m đến 1000m từ khu vực sóng lặng).',
      beigaRural: 'Môi trường thành phố / nông thôn ôn hòa',
      beigaRuralDetail: 'Cách môi trường biển hơn 1000m',
      severeIndustrial: 'Môi trường công nghiệp rất nặng',
      severeIndustrialDetail: 'Đặc điểm của môi trường này là khu vực có bụi và khói thải từ ống khói, có chứa lưu huỳnh và axit hay chất kiềm.Bao gồm các nhà xưởng có ống khói và bất kỳ tòa nhà nào ngay bên dưới ống khói.Và cũng bao gồm các tòa nhà có độ ẩm bên trong cao hay / hoặc ăn mòn xảy ra do các hoạt động bên trong.',
      heavyIndustrial: 'Môi trường công nghiệp nặng',
      heavyIndustrialDetail: 'Đặc điểm của môi trường này là khu vực có bụi do nằm gần kề trong vòng 500m khu vực mang tính chất công nghiệp nặng hay các khu vực công nghiệp nhỏ sản sinh ra khói bụi công nghiệp đáng kể.Thông thường bao gồm các tòa nhà dịch vụ nằm riêng biệt và gần những nhà máy công nghiệp nặng và bao gồm cả khu nhà phụ.',
      industrial: 'Môi trường công nghiệp',
      industrialDetail: 'Đặc điểm của môi trường này là khu vực có bụi do nằm gần kề khu vực mang tính chất công nghiệp nặng hay các khu vực công nghiệp nhỏ sản sinh ra khói bụi công nghiệp đáng kể, cách các khu vực này từ 501 tới 1000m.hoặc < 500m nơi mà các khu công nghiệp sản sinh ra khói thải nhẹ từ ống khói nhỏ…vv',
      lightIndustrial: 'Môi trường công nghiệp nhẹ',
      lightIndustrialDetail: 'Môi trường này phổ biến trong các khu công nghiệp / khu đô thị, nằm cách xa các môi trường liệt kê ở trên, điển hình cách xa hơn 1001m – 2000m đối với khu vực có bụi công nghiệp nặng, hoặc từ 500m đến 1000m nơi mà các khu công nghiệp sản sinh ra khói thải nhẹ từ ống khói nhỏ…vv',
      trueCoreApplication: 'ứng dụng Truecore',
      trueCoreApplicationDetail: 'Việc áp dụng bảo hành này cho công trình xây dựng có điều kiện dựa trên các tòa nhà thương mại và các công trình nhà ở trong nước("nơi ở của chủ nhà) kết hợp các Thành phần khung thép TRUECORE và việc xây dựng được thực hiện theo phương pháp xây dựng tốt. Công trình nằm cách xa hơn 300 m từ vị trí sóng vỗ hoặc hơn 100 mét từ mặt nước lặng (Tất cả các khung xây dựng bằng thép phải là một hệ giàn thép kín)',
      standard: {
        type: 'Standard',
        benignRural: 'Môi trường thành phố/ nông thôn ôn hòa',
        benignRuralDescription1: 'Thông thường hơn',
        benignRuralDescription2: '1000m từ khu vự biển',
        benignRuralDescription3: '(Sóng tan hoặc biển lặng)',
        benignRuralDescription4: 'và / hoặc',
        benignRuralDescription5: 'ít nhất',
        benignRuralDescription6: 'Cách 2km từ môi trường công nghiệp có khói bụi nhiều',
        benignRuralDescription7: 'hoặc',
        benignRuralDescription8: 'Cách 1km từ môi trường công nghiệp có khói bụi ít',
      },
      industrialEnvironment: {
        type: 'Môi trường công nghiệp',
        lightIndUrban: 'Công nghiệp nhẹ / nông thôn',
        lightIndUrbanDescription1: 'Đặc điểm của môi trường này là khu vực có bụi do nằm gần kề khu vực mang tính chất công nghiệp sản sinh ra khói bụi đáng kể, Điển hình nhiều hơn',
        lightIndUrbanDescription2: '1000m đến 2000m từ khu công nghiệp nặng nặng, hoặc từ 500m đến 1000m nơi công nghiệp nhỏ',
        lightIndUrbanDescription3: 'Lượng khói bụi mức độ nhẹ từ ống khói',
        industrial: 'Môi trường công nghiệp',
        industrialDescription1: 'Đặc điểm của môi trường này là khu vực có bụi do nằm gần kề khu vực mang tính chất công nghiệp sản sinh ra khói bụi đáng kể, Điển hình nhiều hơn',
        industrialDescription2: '500m đến 1000m từ khu công nghiệp nặng nặng, hoặc từ nhỏ hơn 500m nơi công nghiệp nhỏ',
        industrialDescription3: 'Lượng khói bụi mức độ nhẹ từ ống khói',
        heavyIndustrial: 'Môi trường công nghiệp nặng',
        heavyIndustrialDescription1: 'Đặc trưng bởi',
        heavyIndustrialDescription2: 'ít hơn 500m',
        heavyIndustrialDescription3: 'Khu vực mang tính chất công nghiệp nặng hay các khu vực công nghiệp nhỏ sản sinh ra khói bụi công nghiệp đáng kể.Thông thường bao gồm các tòa nhà dịch vụ nằm riêng biệt và gần những nhà máy công nghiệp nặng và bao gồm cả khu nhà phụ.',
        heavyIndustrialDescription4: 'Môi trường này chúng ta đề nghị sử dụng thép Clean COLORBOND®ULTRA',
        severeIndustrial: 'Môi trường công nghiệp rất nặng',
        severeIndustrialDescription1: 'Đặc trưng bởi khói bụi và khí thải',
        severeIndustrialDescription2: 'Ống khói, có chứa lưu huỳnh và axit hay chất kiềm.',
        severeIndustrialDescription3: 'Bao gồm các nhà xưởng có ống khói và bất kỳ tòa nhà nào ngay bên dưới ống khói.Và cũng bao gồm với các tòa nhà có',
        severeIndustrialDescription4: 'Độ ẩm bên trong cao hay / hoặc ăn mòn xảy ra do các hoạt động bên trong.',
        severeIndustrialDescription5: 'Thường tốc độ ăn mòn cao trong cấu trúc tòa nhà là hiển nhiên(vui lòng liên hệ đại diện bán hàng Bluescope)',
      },
      marineEnvironment: {
        type: 'Marine Environment',
        moderateMarine: 'Môi trường biển nhẹ',
        moderateMarineDescription1: 'Thường nằm giữa',
        moderateMarineDescription2: '400m và 1000m từ vị trí sóng tan',
        moderateMarineDescription3: 'tuy nhiên gió chủ đạo mạnh có thể mở rộng khoảng cách này',
        moderateMarineDescription4: 'khu vực cách bờ biển từ 201m đến 1000m',
        marine: 'Môi trường biển',
        marineDescription1: 'Thường giữa',
        marineDescription2: 'nằm trong khu vực cách bờ biển từ 201m đến 400m',
        marineDescription3: 'tuy nhiên gió chủ đạo mạnh có thể mở rộng khoảng cách này',
        marineDescription4: 'hoặc từ 101m đến 200m từ khu vực sóng lặng)',
        marineDescription5: 'Đặc điểm của môi trường này là thỉnh thoảng xuất hiện mùi muối nhẹ, hơi muối đọng lại trên khu vực không được rửa trôi.',
        marineDescription6: 'Nếu có thể nhìn thấy sương muối trong khu vực này, chúng tôi khuyên bạn chỉ nên sử dụng thép Clean COLORBOND® ULTRA và vít Class - 4',
        severeMarine: 'Môi trường biển khắc nghiệt',
        severeMarineDescription1: 'Thông thường nằm giữa',
        severeMarineDescription2: 'nằm trong khu vực cách bờ biển từ 101m đến 200m',
        severeMarineDescription3: 'tuy nhiên gió chủ đạo mạnh có thể mở rộng khoảng cách này',
        severeMarineDescription4: 'nhỏ hơn 100m từ biển lặng',
        severeMarineDescription5: 'Đặc điểm của môi trường này là thỉnh thoảng xuất hiện mùi muối nhẹ, hơi muối đọng lại trên khu vực không được rửa trôi.Thông thường dễ thấy là sự giảm giá trị rõ rệt của hầu hết các vật liệu của tòa nhà.',
        severeMarineDescription6: 'Môi trường này chúng ta đề nghị sử dụng thép Clean COLORBOND®ULTRA và vít Class - 4',
        verySevereMarine: 'Môi trường biển rất khắc nghiệt',
        verySevereMarineDescription1: 'Bao gồm khu vực ngoài khơi.',
        verySevereMarineDescription2: 'Khu vực cách mép sóng biển tan trong vòng 100m.',
        verySevereMarineDescription3: 'Vui lòng gọi đại diện bán hàng BlueScope',
      },
      vn: {
        standard: {
          benignRural: 'BENIGN / MILD URBAN / RURAL (MÔI TRƯỜNG THÀNH PHỐ / NÔNG THÔN)',
          benignRuralDescription1: 'Thông thường hơn',
          benignRuralDescription2: '5km từ biển',
          benignRuralDescription3: 'sóng vỡ hoặc lặng',
          benignRuralDescription4: '2km từ các môi trường bên trên',
          benignRuralDescription5: 'khu vực khói bụi có tính ăn mòn',
          benignRuralDescription6: 'Cách môi trường biển hơn 5km và môi trường công nghiệp và khu vực khói bụi có tính ăn mòn từ 2km trở lên.',
        },
        industrialEnvironment: {
          lightIndUrban: 'LIGHT INDUSTRIAL / URBAN (MÔI TRƯỜNG CÔNG NGHIỆP NHẸ)',
          lightIndUrbanDescription1: 'Môi trường này phổ biến trong các khu công nghiệp / khu đô thị, nằm cách xa các môi trường liệt kê ở trên, điển hình cách xa đối với khu vực có bụi công nghiệp nặng',
          lightIndUrbanDescription2: '1001m – 2000m từ môi trường công nghiệp khói bụi nặng',
          lightIndUrbanDescription3: 'Môi trường này phổ biến trong các khu công nghiệp/ khu đô thị, nằm cách xa các môi trường liệt kê ở trên, điển hình cách xa hơn 1001m – 2000m đối với khu vực có bụi công nghiệp nặng.',
          lightIndUrbanDescription4: 'Thời hạn bảo hành môi trường công nghiệp được hiển thị ở đây chỉ nhằm mục đích cho thấy Các bảo hành trong môi trường công nghiệp có thể tùy thuộc vào từng trường hợp xem xét lại dự án về mức độ nghiêm trọng của các chất ô nhiễm và mức độ ảnh hưởng từ biển & công nghiệp',
          industrial: 'INDUSTRIAL (MÔI TRƯỜNG CÔNG NGHIỆP)',
          industrialDescription1: 'Đặc điểm của môi trường này là khu vực có bụi do nằm gần kề khu vực mang tính chất công nghiệp nặng hay các khu vực công nghiệp nhỏ sản sinh ra khói bụi công nghiệp đáng kể, cách các khu vực này.',
          industrialDescription2: '501 tới 1000m từ môi trường công nghiệp có khói bụi nặng',
          industrialDescription3: 'Đặc điểm của môi trường này là khu vực có bụi do nằm gần kề khu vực mang tính chất công nghiệp nặng hay các khu vực công nghiệp nhỏ sản sinh ra khói bụi công nghiệp đáng kể, cách các khu vực này từ 501 tới 1000m.',
          industrialDescription4: 'Thời hạn bảo hành môi trường công nghiệp được hiển thị ở đây chỉ nhằm mục đích cho thấy Các bảo hành trong môi trường công nghiệp có thể tùy thuộc vào từng trường hợp xem xét lại dự án về mức độ nghiêm trọng của các chất ô nhiễm và mức độ ảnh hưởng từ biển & công nghiệp',
          heavyIndustrial: 'HEAVY INDUSTRIAL (MÔI TRƯỜNG CÔNG NGHIỆP)',
          heavyIndustrialDescription1: 'Đặc điểm của môi trường này là khu vực có bụi do nằm gần kề',
          heavyIndustrialDescription2: 'trong vòng 500m khu vực mang tính chất công nghiệp nặng',
          heavyIndustrialDescription3: 'hay các khu vực công nghiệp nhỏ sản sinh ra khói bụi công nghiệp đáng kể.Thông thường bao gồm các tòa nhà dịch vụ nằm riêng biệt và gần những nhà máy công nghiệp nặng và bao gồm cả khu nhà phụ',
          heavyIndustrialDescription4: 'Đặc điểm của môi trường này là khu vực có bụi do nằm gần kề trong vòng 500m khu vực mang tính chất công nghiệp nặng hay các khu vực công nghiệp nhỏ sản sinh ra khói bụi công nghiệp đáng kể.Thông thường bao gồm các tòa nhà dịch vụ nằm riêng biệt và gần những nhà máy công nghiệp nặng và bao gồm cả khu nhà phụ.',
          severeIndustrial: 'SEVERE INDUSTRIAL (MÔI TRƯỜNG CÔNG NGHIỆP NẶNG)',
          severeIndustrialDescription1: 'Đặc điểm của môi trường này là khu vực có bụi và khói thải',
          severeIndustrialDescription2: 'từ ống khói, có chứa lưu huỳnh và axit hay chất kiềm.',
          severeIndustrialDescription3: 'Bao gồm các nhà xưởng có ống khói và bất kỳ tòa nhà nào ngay bên dưới ống khói.Và cũng bao gồm các tòa nhà',
          severeIndustrialDescription4: 'có độ ẩm bên trong cao hay / hoặc ăn mòn xảy ra do các hoạt động bên trong.',
          severeIndustrialDescription5: 'Đặc điểm của môi trường này là khu vực có bụi và khói thải từ ống khói, có chứa lưu huỳnh và axit hay chất kiềm.Bao gồm các nhà xưởng có ống khói và bất kỳ tòa nhà nào ngay bên dưới ống khói.Và cũng bao gồm các tòa nhà có độ ẩm bên trong cao hay/ hoặc ăn mòn xảy ra do các hoạt động bên trong.',
          severeIndustrialDescription6: 'Nói chung, tốc độ ăn mòn rất cao trong hầu hết các cấu trúc xây dựng(Vui lòng tham khảo đại diện bán hàng của BlueScope).',
        },
        marineEnvironment: {
          moderateMarine: 'MODERATE MARINE (MÔI TRƯỜNG BIỂN)',
          moderateMarineDescription1: 'Thông thường nằm giữa',
          moderateMarineDescription2: '1001m và 5000m từ biển có sóng',
          moderateMarineDescription3: 'tuy nhiên gió chủ đạo mạnh có thể mở rộng khoảng cách này',
          moderateMarineDescription4: 'Thông thường nằm trong khu vực cách bờ biển từ 1001m đến 5000m, tuy nhiên gió chủ đạo mạnh có thể mở rộng khoảng cách này.',
          marine: 'MARINE (MÔI TRƯỜNG BIỂN)',
          marineDescription1: 'Thông thường nằm giữa',
          marineDescription2: 'Thông thường nằm trong khu vực cách bờ biển từ 401m đến 1000m',
          marineDescription3: 'tuy nhiên gió chủ đạo mạnh có thể mở rộng khoảng cách này.',
          marineDescription4: '(hoặc từ 201m đến 1000m từ khu vực sóng lặng)',
          marineDescription5: 'Thông thường nằm trong khu vực cách bờ biển từ 401m đến 1000m, tuy nhiên gió chủ đạo mạnh có thể mở rộng khoảng cách này(hoặc từ 201m đến 1000m từ khu vực sóng lặng).',
          severeMarine: 'SEVERE MARINE (MÔI TRƯỜNG BIỂN KHẮC NGHIỆT)',
          severeMarineDescription1: 'Thông thường nằm giữa',
          severeMarineDescription2: 'nằm trong khu vực cách bờ biển từ 101m đến 400m',
          severeMarineDescription3: 'tuy nhiên gió chủ đạo mạnh có thể mở rộng khoảng cách này',
          severeMarineDescription4: '(hoặc từ 101m đến 200m từ khu vực sóng lặng)',
          severeMarineDescription5: 'Đặc điểm của môi trường này là thỉnh thoảng xuất hiện mùi muối nhẹ, hơi muối đọng lại trên khu vực không được rửa trôi.',
          severeMarineDescription6: 'Thông thường nằm trong khu vực cách bờ biển từ 101m đến 400m, tuy nhiên gió chủ đạo mạnh có thể mở rộng khoảng cách này(hoặc từ 101m đến 200m từ khu vực sóng lặng).Đặc điểm của môi trường này là thỉnh thoảng xuất hiện mùi muối nhẹ, hơi muối đọng lại trên khu vực không được rửa trôi.',
          severeMarineDescription7: 'Môi trường này chúng ta đề nghị sử dụng thép Clean COLORBOND®ULTRA và vít Class - 4',
          verySevereMarine: 'Very Severe Marine (MÔI TRƯỜNG BIỂN KHẮC NGHIỆT CAO)',
          verySevereMarineDescription1: 'chỉ áp dụng cho máitùy thuộc vào đánh giá cụ thể của dự án(Xem xét trên từng trường hợp).',
          verySevereMarineDescription2: 'Chỉ áp dụng cho mái, sẽ được đánh giá cho từng trường hợp.',
          verySevereMarineDescription3: 'Môi trường này chúng ta đề nghị sử dụng thép Clean COLORBOND®ULTRA và vít Class - 4',
          verySevereMarineDescription4: 'Off-shores areas. Khu vực ngoài khơi.',
          verySevereMarineDescription5: 'Up to 100m from the high waterline of areas with breaking surf. Khu vực cách mép sóng biển tan trong vòng 100m.',
        },
      },
      in: {
        standard: {
          benignRural: 'Benign / Rural',
          benignRuralDescription1: 'Generally',
          benignRuralDescription2: 'greater than 1000m',
          benignRuralDescription3: 'from marine influence (breaking surf or calm marine) ​AND/OR at​',
          benignRuralDescription4: 'least 2km',
          benignRuralDescription5: 'away from heavy Industrial fall out OR',
          benignRuralDescription6: '1km',
          benignRuralDescription7: 'away from small industrial fall out.​',
        },
        industrialEnvironment: {
          lightIndUrban: 'Light Industrial',
          lightIndUrbanDescription1: 'This environment is widespread in industrial / urban areas, typically more than',
          lightIndUrbanDescription2: '1000m up to 2000m from heavy industrial fall-out, or between 500m to 1000m where small industries',
          lightIndUrbanDescription3: 'lead to a moderate level of fall-out from small stacks, etc.',
          lightIndAndUrban: 'Light Industrial / Urban',
          lightIndAndUrbanDescription1: 'This environment is widespread in industrial / urban areas, typically more than',
          lightIndAndUrbanDescription2: '1000m up to 2000m from heavy industrial fall-out, or between 500m to 1000m where small industries',
          lightIndAndUrbanDescription3: 'lead to a moderate level of fall-out from small stacks, etc.',
          industrial: 'Industrial',
          industrialDescription1: 'This environment is widespread in industrial/urban areas, typically more than',
          industrialDescription2: '500m up to 1000m from heavy industrial fall-out, or < 500m where small industries',
          industrialDescription3: 'lead to a moderate level of fall-out from small stacks, etc. ',
          heavyIndustrial: 'Heavy Industrial ',
          heavyIndustrialDescription1: 'Typically characterized by',
          heavyIndustrialDescription2: 'less than 500m',
          heavyIndustrialDescription3: 'fall-out from adjoining severe industrial environments or where small industries lead to significant industrial fall-out. Generally includes other service building located near heavy industrial plants, including out-building of the plant itself.',
          heavyIndustrialDescription4: 'In this environment we strongly recommend Clean COLORBOND®ULTRA Steel.',
          severeIndustrial: 'Severe Industrial',
          severeIndustrialDescription1: 'Characterized by fall-out and emissions from',
          severeIndustrialDescription2: 'stacks, sulfur and acid or alkali smells.',
          severeIndustrialDescription3: 'Includes only plant building themselves and any building immediately under stacks. Also includes building with',
          severeIndustrialDescription4: 'high internal humidity and/ or corrosion from operations within.',
          severeIndustrialDescription5: 'Generally a very high rate of corrosion in most building structures is evident.',
          severeIndustrialDescription6: '(Please refer to BlueScope representatives).',
        },
        marineEnvironment: {
          moderateMarine: 'Moderate Marine',
          moderateMarineDescription1: 'Generally between',
          moderateMarineDescription2: '401m and 1000m from marine surf,',
          moderateMarineDescription3: 'although strong prevailing winds may extend this distance.',
          moderateMarineDescription4: '(Or 201m – 1000m from calm marine).',
          marine: 'Marine',
          marineDescription1: 'Generally between',
          marineDescription2: '201m from the beachfront to approximately 400m inland.',
          marineDescription3: 'In high wind areas, may extend further inland depending on prevailing winds and geography of the area. (Or',
          marineDescription4: '101 - 200m from calm marine',
          marineDescription5: '). Characterized by occasionally noticeable slight salt smell and salt building-up in unwashed areas of structures.',
          marineDescription6: 'If there is visible salt haze in this zone we would recommend you only use of Clean COLORBOND® ULTRA Steel and Class-4 fasteners​.',
          severeMarine: 'Severe Marine',
          severeMarineDescription1: 'Generally between',
          severeMarineDescription2: '101m from the beachfront to approximately 200m inland.',
          severeMarineDescription3: 'In high wind areas, may extend further inland depending on prevailing winds and geography of the area. (Or',
          severeMarineDescription4: '< 100m from calm marine',
          severeMarineDescription5: 'e.g.: harbour). Characterized by strong salt and haze, salt smearing and salt building-up in unwashed areas of structures.',
          severeMarineDescription6: 'Generally a very noticeable deterioration of most building materials is evident.​ In this environment we strongly recommend Clean COLORBOND® ULTRA Steel and Class-4 fasteners.',
          verySevereMarine: 'Very Severe Marine',
          verySevereMarineDescription1: 'Includes off-shores areas and',
          verySevereMarineDescription2: 'up to 100m from high waterline of areas with breaking surf. ',
          verySevereMarineDescription3: '(Please refer to BlueScope representatives)',
        },
      },
    },
    startWorkingDate: 'Start Working Date',
    startWorkingDatePlaceholder: 'Insert Start Working Date',
    jobPosition: 'Job Position',
    jobPositionPlaceholder: 'Select Job Position',
    courseName: 'Course Name',
    courseNamePlaceholder: 'Insert Course Name',
    trainingDate: 'Training Date',
    trainingDatePlaceholder: 'Select Training Date',
    segmentPlaceholder: 'Search Sub Segment or Description',
    applicationDetailPlaceholder: 'Insert Number',
    buildingSelectPlaceholder: 'Select Building',
    segmentSelectPlaceholder: 'Select Segment',
    filter: 'Filter',
    questionPlaceholder: 'Insert Answer',
    birthDayPlaceholder: 'Select Birthday',
    trainingYear: 'Training Year',
    trainingYearPlaceholder: 'Select Training Year',
    onceTheSaleOrderIsComplete: 'Once the sales order is complete, this button will appear.',
    youCanGoTothe: 'You can go to the',
    projectDocument: 'project document',
    or: 'or',
    warranty: 'warranty',
    menu: 'menu',
    toGenerateWarranty: 'to generate a warranty',
    ifYouLeaveThisPage: 'if you leave this page.',
    thisButtonWillDisappear: 'this button will disappear.',
    linkPreTransactionIdAndToSaleTransactionId: 'Link Pre-Transaction ID to Sales Transaction ID',
  },
  preTransaction: {
    breadcrumb1: 'Home',
    breadcrumb2: 'Project Document',
    breadcrumb3: 'Pre-Transaction',
    breadcrumb4: 'Project information',
    title: 'Pre-Transaction',
    label: {
      customerSelection: 'Customer Selection',
      selectCustomerCreatePreTransaction: 'Select customer for create Pre-Transaction.',
      information: 'Information',
      segment: 'Segment',
      segmentAndSubSegment: 'Segment and Sub segment',
      segmentDescription: 'Segment description',
      subSegment: 'Sub Segment',
      subSegmentDescription: 'Segment description',
      pleaseChooseAnAction: 'Please choose an action',
      warrantyTempalte: 'Warranty template',
      warrantyTempalteDesc: 'To view sample product group warranty template',
      documentTemplate: 'Document template',
      documentTemplateDesc: 'To view sample product group certificate template',
      certificate: 'Certificate',
      certificateDesc: 'To generate a certificate from product group',
      salesOrder: 'Sales order',
      salesOrderDesc: 'To change product group from pre-transaction to sales order',
      reference: 'Reference',
      referenceDesc: 'To maintain your document (text,photos, files)',
      remarkOnlyProjectDocument: 'สำหรับก่อนการขาย (Project Document) เท่านั้น',

    },
    field: {
      projectName: 'Project Name',
      projectAddress: 'Project address',
      maxProjectName: 'Maximum 100 characters.',
      maxprojectAddress: 'Maximum 100 characters.',
      placeholder: 'Placeholder',
      placeholderProjectName: 'Project Name',
      placeholderSearchSegmentSubSegmentAndDescription: 'Search segment/sub segment name and description',
      placeholderSearchProjectOrCustomerName: 'Search project or customer name'
    },
    button: {
      buttonSelectCustomer: 'Select Customer',
      segmentAndSubSegmentSelection: 'Segment & Sub Segment Selection',
      buttonCreatePreTransaction: 'Create Pre-Transaction'
    },
    projectInformation: {
      title: 'Project information',
    },
    preTransactionHistory: {
      breadcrumb1: 'Pre-Transaction History',
      breadcrumb2: 'Duplicate certificate',
      breadcrumb3: 'View Certificate',
      title: 'Project Document > Pre-Transaction History',
      field: {
        placeholderSearch: 'Search',
        placeholderSelectStartDate: 'Select Start Date',
        placeholderSelectEndDate: 'Select End Date',
      },
      selectOption: {
        productName: 'Product Name',
        customerName: 'Customer Name',
        phoneNo: 'Phone No.',
        status: 'Status',
      },
      label: {
        date: 'Date',
        to: 'to',
        preTransactionCanBeDelete: 'Pre-Transaction can be delete within a year after created. Any action will be done after the period, please contact Bluescope.',
        selectedPreTransaction: 'Selected Pre-Transaction',
        noPreTransaction: 'No Pre-Transaction',
        duplicatePreTransaction: 'Duplicate Pre-Transaction',
        preTransactionCertificate: 'Pre-Transaction certificate'

      },
      table: {
        header: {
          select: 'Select',
          date: 'Date',
          preTransactionID: 'Pre-Transaction ID',
          projectName: 'Project Name',
          productGroup: 'Product group',
          customerName: 'Customer Name',
          phoneNo: 'Phone No.',
          references: 'References',
          status: 'Status',
          action: 'Action',
        }
      },
      modal: {
        modalDelete: {
          title: 'Confirm Delete',
          message: 'Are you sure ? you want to delete this Pre-Transaction History',
        }
      }
    },
    duplicatePreTransaction: {
      breadcrumb1: 'Duplicate Pre-Transaction',
      title: 'Duplicate Pre-Transaction ',
      label: {
        productGroup: 'Product Group',
        certificate: 'Certificate',
        companyName: 'Company Name',
        projectName: 'Project Name',
        productAndDocumentary: 'Product & Documentary'
      },
      field: {
        labelCustomerNameDocument: 'Customer name in document',
      },
      button: {
        buttonSelectCustomer: 'Select customer'
      },
    },
    referancePreTransaction: {
      breadcrumb1: 'Reference',
      breadcrumb2: 'Create Reference',
      breadcrumb3: 'Edit Reference',
      title: 'Reference',
      field: {
        placeholderReferenceName: 'Reference name',
        placeholderInputReferenceName: 'Input reference name',
        placeholderEnterDescription: 'Enter description (optional)',

      },
      label: {
        noReference: 'No Reference',
        noPreTransaction: 'No Pre-Transaction',
        referenceInformation: 'Reference information',
        referenceName: 'Reference name',
        projectName: 'Project name',
        description: 'Description',
        referenceFile: 'Reference file (Upload file type: .jpg, .jpeg, .png, .gif, .doc, .docx, .xls, .xlsx, .ptt, .pttx and .pdf)',

      },
      button: {
        buttonSearch: 'Search',
        buttonCreateReference: 'Create Reference',
        buttonUploadFile: 'Upload File',
      },
      table: {
        header: {
          referenceName: 'Reference Name',
          referenceNameLower: 'Reference name',
          dateCreated: 'Date created',
          dateModify: 'Date Modified',
          action: 'Action'
        }
      },
      modal: {
        modalDelete: {
          title: 'Confirm Delete',
          message: 'Are you sure ? you want to delete this Reference.'
        },
        modalLeave: {
          title: 'Confirm leave',
          message: 'Confirm Leave? Are you sure you want to leave this page with out save. The data will not save.'
        },
      }
    },
    modal: {
      modalHistoryLog: {
        title: 'History log',
        label: {
          projectName: 'Project name :',
          customerName: 'Customer name :',
        },
        table: {
          header: {
            documentCategory: 'Document category',
            productGroup: 'Product group',
            logDescription: 'Log description',
            dateTime: 'Datetime'
          }
        }
      }
    },
    table: {
      header: {
        projectName: 'Project name',
        customerName: 'Customer name',
        createdDate: 'Created date',
        action: 'Action'
      }
    },
    document: {
      breadcrumb1: 'Documentary',
      breadcrumb2: 'View Documentary',
      title: 'Documentary',
      table: {
        header: {
          productGroup: 'Product group',
          certificate: 'Certificate',
          createdDate: 'Created date',
          action: 'Action',
        }
      },
    }
  },
  message: {
    pleaseInsertAllRequiredField: 'Vui lòng nhập tất cả các vị trí bắt buộc',
    wrongEmailFormat: 'email định dạng sai',
    saveCompleted: 'Lưu đã hoàn thành',
    incorrectEmailOrPassword: 'Email hoặc mật khẩu không chính xác',
    pleaseSelectRewardCatalog: 'Vui lòng chọn danh mục phần thưởng.',
    customerPhoneIsAlreadyExist: 'Số điện thoại, email này của khách hàng là đại lý ủy quyền khác.',
    newPasswordAndConfirmPasswordNotMatch: 'Mật khẩu mới và mật khẩu xác nhận không khớp.',
    passwordIncorrect: 'mật khẩu không đúng',
    passwordIncorrectLength: 'password incorrect length.',
    usedPointsMoreThanTotalPoints: 'Không thể đổi điểm.Vui lòng kiểm tra điểm của bạn. ',
    incorrectPhoneNumberLength: 'Độ dài số điện thoại từ quốc gia không chính xác',
    incorrectMobileNumberLength: 'Độ dài số điện thoại di động không chính xác từ quốc gia',
    contactNamePleaseSelectOnlyOne: 'Contact Name is invalid. Please select only one contact name.',
    updateAdCompleted: 'Đại lý Ủy quyền được cập nhật thành công',
    createCustomerCompleted: 'Khách hàng được tạo thành công.',
    updateCustomerCompleted: 'Khách hàng được tạo thành công.',
    deleteCustomerCompleted: 'Khách hàng được tạo thành công.',
    createRedemptionCompleted: 'Đổi thưởng đã được thực hiện thành công.',
    updateRedemptionCompleted: 'Đổi thưởng được cập nhật thành công.',
    createSalesPersonCompleted: 'Người bán hàng được tạo thành công.',
    updateSalesPersonCompleted: 'Người bán hàng đã được cập nhật thành công.',
    deleteSalesPersonCompleted: 'Đã xóa thành công người bán hàng.',
    createSalesTransactionCompleted: 'Giao dịch bán hàng được tạo thành công.Vui lòng nhấp vào menu bảo hành',
    updateWarrantyCompleted: 'Bảo hành được cập nhật thành công',
    updateProductLengthCompleted: 'Chiều dài sản phẩm được cập nhật thành công.',
    confirmDeleteSalesPerson: 'Bạn có chắc chắn muốn xóa người bán hàng này không?',
    confirmDeleteCustomer: 'Bạn có chắc chắn muốn xóa khách hàng này không?',
    confirmDeleteHistorySaleTrans: 'Bạn có chắc chắn muốn xóa lịch sử giao dịch bán hàng này không?',
    warrantyWaitingApproval: 'Bảo hành của bạn đang chờ quá trình phê duyệt do một số điều kiện phải được xem xét. Nếu bạn có bất kỳ câu hỏi nào, vui lòng liên hệ với Bọ phận Kỹ thuật',
    nationalIdCard: 'Đầu đọc thẻ id quốc gia',
    pleaseConfirmPointsAndUnits: 'Vui lòng xác nhận Điểm và Đơn vị Trueblue của bạn.',
    inventoryIsNotEnoughToPlace: 'Tồn kho của bạn không đủ để thực hiện giao dịch này.Vui lòng liên hệ với quản trị viên',
    rewardStockItemIsNotEnoughToPlace: 'mặt hàng trong kho phần thưởng của bạn không đủ để thực hiện giao dịch này.Vui lòng liên hệ với quản trị viên.',
    sendPasswordToPhone: 'Gửi mật khẩu tạm thời đến điện thoại.',
    phoneOrContractorInvalid: 'Số điện thoại này hoặc nhà thầu này không hợp lệ.',
    formatFileIncorrect: 'Định dạng tệp CSV của bạn không đúng',
    incorrectProjectNameLength: 'Tên dự án tối đa 100 ký tự.',
    incorrectProjectName500Length: 'Tên dự án tối đa 500 ký tự.',
    incorrectProjectAddrLength: 'Tên dự án tối đa 100 ký tự.',
    incorrectAdditionalProjectLength: 'Tên dự án bổ sung tối đa 500  ký tự.',
    warrantyDetailExceedQuantity: 'Vượt quá số lượng, vui lòng thay đổi số lượng hoặc xóa đăng ký.',
    warrantyPleaseSelectEnvDetail: 'Vui lòng chọn chi tiết môi trường.',
    warrantyPleaseApplicationDetail: 'Vui lòng chọn chi tiết môi trường',
    warrantyConditionOutOfLength: 'Dự án nằm ngoài môi trường cơ bản.',
    warrantySelectEnvDetailSpecial: 'Sản phẩm đặc biệt, vui lòng liên hệ với Ban quản trị Bluescope',
    numberEqualOrGreater: 'A number must be equal or greater than',
    numberMustBe: 'Một số phải là',
    numberMustBetween: 'Một số phải ở giữa',
    warrantyNoWarrantyOffered: 'Không có đề nghị bảo hành, vui lòng liên hệ với Ban quản trị Bluescope',
    warrantyOfferedNA: 'Bảo hành sẽ được gửi cho Quản trị viên Bluescpope để xử lý thêm',
    warrantyEnvSpecialProduct: 'Sản phẩm đặc biệt sẽ được gửi cho Ban quản trị Bluescope để xử lý thêm',
    warrantyPleaseSelectResidential: 'Không thể tạo bảo hành. Vui lòng chọn danh mục dân dụng. ',
    warrantyPleaseSelectNonResidential: 'Không thể tạo bảo hành. Vui lòng chọn danh mục dự án. ',
    warrantyPleaseSelectResidentialMY: 'Your warranty application cannot be processed. Please contact BlueScope for further assistance',
    warrantyPleaseSelectNonResidentialMY: 'Your warranty application cannot be processed. Please contact BlueScope for further assistance',
    warrantyNoTemplateForm: 'Lỗi-01 Bảo hành không thể được tạo',
    warrantySelectEnvDetailNA: 'Không áp dụng bảo hành, vui lòng liên hệ với Ban quản trị Bluescope',
    warrantyConditionNotPass: 'Bảo hành sẽ được gửi đến Bluescope để được phê duyệt thêm.',
    warrantyMaximumYearExceed: 'The maximum year cannot exceed corrosion warranty.',
    warrantyPleaseSelectCategory: 'Please select category',
    pleaseSelectSegment: 'Please select segment.',
    otpSentSuccess: 'OTP has been sent, Please check your message.',
    otpResendSuccess: 'OTP has been resend, Please check your message.',
    incorrectOTP: 'Incorrect OTP.',
    wrongAnswer: 'Wrong Answer',
    wrtNoPleaseSelectOnlyOne: 'Warranty is invalid. Please select only one warranty.',
    updateDocumentaryCompleted: 'Documentary is successfully created.',
    blueScopeContactBack: 'Do you need BlueScope contact you back?',
    privacyPolicyRegisterYearWarranty1: 'Privacy Policy: By clicking "agree", you agree to provide Bluescope your information which allows us to use it in regards to privacy policy and marketing purposes. To view privacy policy of NS Bluescope (Thailand) Pte., Ltd., please click ',
    privacyPolicyRegisterYearWarranty2: 'this link',
    privacyPolicyRegisterYearWarranty3: '',
    errorSalesPersonMemberTypeIsContractor: 'Sales person member_type__c is Contractor, Please contact Bluescope',
    preTransactionHistorySuccessDeleted: 'Pre-Transaction History  is successfully deleted.',
    duplicatePreTransactionSuccess: 'Duplicate Pre-Transaction success.',
    referenceSuccessfullyCreated: 'Reference is successfully created',
    referenceSuccessfullyEdited: 'Reference is successfully edited.',
    referenceSuccessfullyDeleted: 'Reference is successfully deleted.',
    deleteReferenceFileSuccess: 'Delete reference file success.',
    coilTransactionSuccessfully: 'You have been coil-transaction successfully.',
    coilTransferWaitingApproval: 'You have completed coil transfer, waiting for approval.',
    youApprovedCoil: 'You have been approved coil.',
    youClearedCoil: 'You have been cleared coil.',
    receivedYourIssueCheckGetBackYouContact: `Bluescope has received your issue. We'll check and get back to you by contact.`,
    pleaseLoginWithSmartPhone: 'Please login with smart phone',
    projectDocumentSuccessCreate: 'Project Document is successfully created.',
    projectDocumentSuccessUpdate: 'Project Document is successfully updated.',
    linkProjectSuccess: 'Link project success.',
    certificateSuccessCreate: 'Certificate is successfully created.',
    certificateSuccessUpdate: 'Certificate is successfully updated.',
    cantFoundDocumentary: `Can't found documentary data.`,
    requestEditProjectDocumentSuccess: 'Request edit project document success.',
    maximumFileSize1MB: `File size is too big, maximum file's size is 1 MB`,
    maximumFileSize20MB: `File size is too big, maximum file's size is 20 MB`,
    automaticEnvironmentNotFound: `Automatic environment not found`,
    environmentFromProjectAddressNotFound: `environment_from_project_address__c not found`,
    errorCoilNoLength: 'Coil [coilName] is sold out. Remaining length is 0 m.',
    warrantySelfDeclaredConditionNotFound: `Warranty self-declared conditions not found`,
    warrantySpecialRejectEnvironment: `The Terms and Conditions are not covered by the standard warranty. Please contact your dedicated sales representative for further assistance.`,
    warrantySpecialReject: `The Terms and Conditions are not covered by the standard warranty. Please contact your dedicated sales representative for further assistance.`,
    warrantySpecialWaitingToBeApprove: `The Terms and Conditions are not covered by the standard warranty. Please await approval from the BlueScope administrator.`,
    warrantySpecialWaitingToBeApproveAll: `Warranty is special, Waiting admin to be approve all.`, // TODO: re wording
  },
  certificate: {
    breadcrumb1: 'Home',
    breadcrumb2: 'Project Document',
    breadcrumb3: 'Pre-Transaction',
    breadcrumb4: 'Create Certificate',
    breadcrumb5: 'Regenerate Certificate',
    title: 'Pre-Transaction certificate : ',
    label: {
      preTransactionSuccessCreate: 'Pre-Transaction is successfully created, It is visible in history and sales orders',
      for: 'for',
      history: 'history',
      or: 'or',
      project: 'project',
      referenceMenuMaintain: ' reference menu, you can maintain references (text, images, and files).',
      inSalesOrder: 'In sales orders, you can switch the product group from Pre-Transaction to Sales Transaction.',
      preTransactionAndCertificate: 'Pre-Transaction & Certificate',
      selectProduct: 'Select products',
      information: 'Information',
      certificate: 'Certificate',
      confirmPreTransaction: 'Confirm Pre-transaction',
      projectName: 'Project name',
    },
    placeholder: {
      placeholderSearchProductGroup: 'Search product group',
      placeholderProjectName: 'Project name'
    },
    table: {
      header: {
        select: 'Select',
        productGroup: 'Product Group',
        company: 'Company',
        document: 'Document',
      }
    },
    modal: {
      modalConfirmPreTransaction: {
        pleaseConfirmProjectNameAndSpecifyCompany: 'Please confirm project name and specify company to use in document.',
        afterCompleteSave: `After a complete save, you won't be able to change or re-generate it.`
      }
    }
  },
  preSaleDocumentary: {
    breadcrumb1: 'Home',
    breadcrumb2: 'Project Document',
    breadcrumb3: 'Pre-Transaction',
    breadcrumb4: 'Create Documentary',
    title: 'Pre-Transaction documentary : ',
    label: {
      preTransactionSuccessCreate: 'Pre-Transaction is successfully created, It is visible in history and sales orders',
      for: 'for',
      history: 'history',
      or: 'or',
      project: 'project',
      referenceMenuMaintain: ' reference menu, you can maintain references (text, images, and files).',
      inSalesOrder: 'In sales orders, you can switch the product group from Pre-Transaction to Sales Transaction.',
      preTransactionAndDocumentary: 'Pre-Transaction & Documentary',
      selectProduct: 'Select products',
      information: 'Information',
      documentary: 'Documentary',
      confirmPreTransaction: 'Confirm Pre-transaction',
      projectName: 'Project name',
    },
    placeholder: {
      placeholderSearchProductGroup: 'Search product group',
      placeholderProjectName: 'Project name'
    },
    table: {
      header: {
        select: 'Select',
        productGroup: 'Product Group',
        company: 'Company',
        document: 'Document',
      }
    },
    modal: {
      modalConfirmPreTransaction: {
        pleaseConfirmProjectNameAndSpecifyCompany: 'Please confirm project name and specify company to use in document.',
        afterCompleteSave: `After a complete save, you won't be able to change or re-generate it.`
      }
    }
  },
  projectReference: {
    breadcrumb1: 'Home',
    breadcrumb2: 'Project reference',
    breadcrumb3: 'Reference',
    breadcrumb4: 'Create reference',
    breadcrumb5: 'Edit reference',
    title: 'Project Reference',
    label: {
      customerSelection: 'Customer Selection',
      selectCustomerCreatePreTransaction: 'Select customer for create Pre-Transaction.',
      reference: 'Reference',
      referenceList: 'Reference List',
      noReference: 'No Reference',
      createReference: 'Create Reference',
      editReference: 'Edit Reference',
      referenceInformation: 'Reference information',
      referenceName: 'Reference name',
      saleTransactionID: 'Sales transaction ID',
      projectName: 'Project name',
      description: 'Description',
      referenceFile: 'Reference file (Upload file type: .jpg, .jpeg, .png, .gif, .doc, .docx, .xls, .xlsx, .ptt, .pttx and .pdf)',
      warrantyNo: 'Warranty No.',
      maximumFileSize: `*Maximum file's size is 20 MB.`,
    },
    button: {
      buttonSelectCustomer: 'Select Customer',
      buttonSearch: 'Search',
      buttonCreateReference: 'Create Reference',
      buttonUploadFile: 'Upload File',
    },
    field: {
      placeholderSearchNameReferenceProjectSaleTransaction: 'Name of reference, project or sales transaction ID',
      placeholderInputReferenceName: 'Input reference name',
      placeholderSelectSalesTransactionID: 'Select sales transaction ID',
      placeholderProjectName: 'Select project name',
      placeholderInputDescription: 'Input description(optional)',
      placeholderSelectWarrantyNo: 'Select warranty No.',
    },
    table: {
      header: {
        referenceName: 'Reference Name',
        referenceNameLower: 'Reference name',
        warrantyNo: 'Warranty No.',
        projectName: 'Project Name',
        dateCreated: 'Date created',
        dateModify: 'Date Modified',
        action: 'Action',
        updatedDate: 'Uploaded date',
        select: 'Select',
      }
    },
    modal: {
      modalSelectReferenceType: {
        label: {
          pleaseSelectReferenceType: 'Plese select reference type',
        },
        button: {
          buttonWarranty: 'Warranty',
          buttonProjectDocument: 'Project Document',
        }
      },
      modalConfirmDelete: {
        title: 'Confirm Delete',
        message: 'Are you sure ? you want to delete this Reference.'
      }
    }
  },
  barcode: {
    breadcrumb1: 'Home',
    breadcrumb2: 'Buy Coil in',
    title: 'Scan Barcode to get coil',
    label: {
      coilId: 'Search Coil ID',
      back: 'Back',
    },
    field: {
      placeholderInsertCoilId: 'Example MMxPxxxxx',
    },
    modal: {
      modalBarcodeDuplicate: {
        title: 'Coil Duplicate',
        message: 'You already have coil {coilName} in stock.'
      },
      modalCoilTranferInformation: {
        title: 'Coil Transfer Information',
        label: {
          coilId: 'Coil ID',
          coilName: 'Coil Name',
          allAmount: 'All amount (m.)',
          tranferAmount: 'Transfer Amount (m.)',
        }
      }
    }
  },
  coilApproval: {
    breadcrumb1: 'Home  ',
    breadcrumb2: 'Inventory & Coil Transfer ',
    breadcrumb3: 'Approve coil transfer',
    title: 'Inventory & Coil Transfer  > Approve coil transfer',
    selectOption: {
      all: 'All',
      coilID: 'Coil ID',
      coilName: 'Coil Name',
      adRFName: 'AD/RF Name',
      pending: 'Pending',
      approved: 'Approved',
      cleared: 'Cleared',
    },
    label: {
      category: 'Category',
      status: 'Status',
      date: 'Date',
      dateTo: 'to',
      noDataCoil: 'No Data Coil',
    },
    field: {
      placeholderSearch: 'Search',
      selectStartDate: 'Select Start Date',
      selectEndDate: 'Select End Date',
      selectCoilApproval: 'Selected Coil Approval'
    },
    button: {
      buttonSearch: 'Search',
      buttonClear: 'Clear',
      buttonApprove: 'Approve',
      buttonConformAndApprove: 'Confirm and Approve',
      buttonConfirmAndClear: 'Confirm and Clear',
    },
    table: {
      header: {
        coilId: 'Coil ID',
        coilName: 'Coil Name',
        adRfName: 'AD/RF name',
        date: 'Date',
        tranferAmount: 'Transfer Amount (m.)',
        status: 'Status',
        action: 'Action',
      }
    },
    modal: {
      modalCoilApproval: {
        title_1: 'Confirm Approve Coil',
        title_2: 'Confirm Clear Coil',
        table: {
          header: {
            no: 'No',
            coilId: 'Coil ID',
            coilName: 'Coil Name',
            thickness: 'Thickness',
            color: 'Color',
            aging: 'Aging',
            reWeight: 'Re. Weight (kg)',
            lengthBalance: 'Length Balance (m.)',
            tranferAmount: 'Transfer Amount (m.)'
          }
        }
      },
      modalCoilApprovalDetail: {
        title: 'Detail Coil',
        label: {
          coilName: 'Coil Name',
          coilId: ' Coil ID',
          tranferAmount: 'Transfer Amount (m.)',
          reWeight: 'Re. Weight (kg)',
          lengthBalance: 'Length Balance (m.)',
          thickness: 'Thickness',
          color: 'Color',
          aging: 'Aging',
        }
      }
    }
  },
  projectDocument: {
    breadcrumb1: 'Home',
    breadcrumb2: 'Project Document',
    breadcrumb3: 'View warranty',
    breadcrumb4: 'Warranty Template',
    breadcrumb5: 'Create documentary',
    breadcrumb6: 'Create reference',
    breadcrumb7: 'Create certificate',
    breadcrumb8: 'Edit reference',
    breadcrumb9: 'Regenerate Certificate',
    title: 'Project Document',
    label: {
      createProjectDocument: 'Create Project Document',
      date: 'Date',
      dateTo: 'to',
      selectStartDate: 'Select Start Date',
      selectEndDate: 'Select End Date',
      enabled: 'Enabled',
      when: 'when',
      aSalesOrderIsPresent: 'a sales order is present',
      noWarrantyTemplate: 'No Warranty Template',
      noDocumentary: 'No Documentary',
      noCertificate: 'No Certificate',
      noReference: 'No Reference',
      warranty: 'Warranty',
      warrantyTemplate: 'Warranty Template',
      allReferenceFile: 'All Reference Files',
      noReferenceFile: 'No Reference File',
      documentary: 'Documentary',
      certificate: 'Certificate',
      selectProduct: 'Select product',
      whenever: 'whenever',
      aSaleOrderForTheSameProductGroupIsPresent: 'a sales order for the same product group is present',
      preCertificate: 'Pre-certificate: Before sales documents',
      postCertificate: 'Post-certificate: After sales documents (Only available when a sales has been completed)',
      postCertificate2: `*TIS 2228-2559 for coil produced before 8/11/23`,
      postCertificate3: `*TIS 2228-2565 for coil produced since 8/11/23`,
      specifyCompanyNameInCertificate: 'Specify the company name in certificate',
      viewList: 'View list',
      viewIcon: 'View icon',
      andUntilTheProductionDate: "and until the production date",
    },
    placeholder: {
      search: 'Search',
      searchWarranty: 'Search Sales Trans. ID',
      searchProductGroup: 'Search product group'
    },
    button: {
      buttonProjectInformation: 'Project Information',
      buttonSaleOrder: 'Sales Order',
      buttonViewAllTemplate: 'View All Template',
      buttonCreateDocumentary: 'Create Documentary',
      buttonCreateCertificate: 'Create Certificate',
      buttonCreateReference: 'Create Reference',
      buttonSelectReferenceFile: 'Select Reference File',
      buttonPreviewPostCertificate: 'Preview Post Certificate',
      buttonPreviewPreCertificate: 'Preview Pre Certificate',

    },
    table: {
      header: {
        preTransactionId: 'Pre-Transaction ID',
        projectName: 'Project name',
        customerName: 'Customer name',
        saleTransactionId: 'Sales Transaction ID',
        createdDate: 'Created date',
        editedDate: 'Edited date',
        action: 'Action',
        name: 'Name',
        nameDocument: 'Name',
        downloadDate: 'Downloaded date',
        ID: 'ID',
        productGroup: 'Product group',
        referenceName: 'Reference Name',
        modifiedName: 'Modified Date',
        uploadedDate: 'Uploaded date',
        select: 'Select',
        code: 'Code',
        category: 'Category',
        no: 'No',
        view: 'View',
        preview: 'Preview',
        projectDocumentNo: 'Project document no.',
      }
    },
    modal: {
      label: {
        confirmToDelete: 'Confirm to Delete',
        doYouWantToDeleteTheReferenceFile: 'Do you want to delete the {count} reference files ?  ',
        doYouWantToDelete: 'Do you want to delete ',
        projectReference: '“Project reference”',
        reference: 'reference ?',
        confirmCompanyName: 'Confirm Company Name',
        pleaseConfirmSpecifyCompanyNameToUseInDocumenary: 'Please confirm specify company name to use in documentary 1.BlueScope Zacs use',
        afterACompleteSave: 'After a complete save,',
        youWontBeAbleToChangeOrReGenerateIt: `you won't be able to change or re-generate it.`,
        pleaseConfirmSpecifyCompanyNameToUseInCertificate: 'Please confirm specify company name to use in certificate 1.BlueScope Zacs use',
      },
      modalSaleTransaction: {
        title: 'Sales Transaction',
        label: {
          preTransactionID: 'Pre-Transaction ID :',
          projectName: 'Project name :',
          customerName: 'Customer name :',
        },
        table: {
          header: {
            saleTransactionID: 'Sales Transaction ID',
            createdDate: 'Created date',
          }
        },
      },
      previewWarranty: {
        title: 'Warranty No. [warrantyNo] : [productGroup]'
      },
      previewDocumentary: {
        title: 'Documentary : [productGroup]'
      },
      previewCertificate: {
        title: 'Certificate : [productGroup]'
      },
    },
    tab: {
      titleWarrantyGuideline: 'Warranty Guideline',
      titleWarrantyTemplate: 'Warranty Template',
      titleWarranty: 'Warranty',
      titleDocumentary: 'Documentary',
      titleCertificateAndLetter: 'Certificate and Letter',
      titleProjectDatabase: 'Project database',
      descriptionWarrantyGuideline: 'To estimate warranty year in Marine environment',
      descriptionWarrantyTemplate: 'To view sample warranty template for each product',
      descriptionWarranty: 'To generate warranty ',
      descriptionDocumentary: 'To generate document such as brochure, datasheet, etc.',
      descriptionCertificateAndLetter: 'To generate certificate and letter such as TIS,MiT,HACCP, etc.',
      descriptionProjectDatabase: 'To upload documents related to the project.',
    }
  },
  notificationManagement: {
    breadcrumb1: "Home",
    breadcrumb2: "Notification Management",
    title: "Notification Management",
    label: {
      from: "From",
      to: "to",
      selectStartDate: "Select Start Date",
      selectEndDate: "Select End Date",
      noData: "No data"
    },
    field: {
      placeholderSearch: "Search ID, Subject",
    },
    button: {
      buttonSearch: "Search",
    },
    table: {
      header: {
        id: 'ID',
        subject: 'Subject',
        publicationDate: 'Publication Date',
        acceptedDate: 'Accepted Date',
        action: 'Action'
      }
    }
  },
  warrantyConditions: {
    field: {
      placeholderInsertDistanceNumber: 'Insert distance number',
      placeholderSelectThePreferredOption: 'Select the preferred option',
      placeholderInsertDescription: 'Insert description',
    },
    label: {
      yes: 'Yes',
      no: 'No',

    },
  },
}