import apiService from '../apiService'

export const watermarkTemplateController = (configService = {}) => {
  const service = apiService(configService)
  return {
    watermarkTemplateDetailSearch: (params) => {
      return service.post(`/watermark-template/detail/search`, params)
    },
    watermarkTemplateDetailSearchSubfolder: (params) => {
      return service.post(`/watermark-template/detail/search/subfolder`, params)
    },
  }
}
