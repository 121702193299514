import _ from 'lodash';
import React from 'react';
import { projectDocumentController } from '../../apiService/apiController/projectDocumentService';
import {
  Breadcrumb, Button, Field, Grid, Image, Section, SegmentCard, SelectControl, ShapeContainer, Text, TitlePage, TitleSection
} from '../../components';
import { CustomerInfoContainerConnected } from '../../containers/CustomerInfoContainer';
import { FieldAddressContainerConnected } from '../../containers/FieldAddressContainer';
import { DATA } from '../../helpers/constants/data';
import { ENUM } from '../../helpers/constants/enum';
import { LOCAL_STORAGE } from '../../helpers/constants/localStorage';
import { ROUTE_PATH } from '../../helpers/constants/route';
import { getUserAuthRedux } from '../../helpers/functions/auth';
import { getStyleSelectControl } from '../../helpers/functions/data';
import { getLanguage } from '../../helpers/functions/language';
import { getLocalStorage } from '../../helpers/functions/localStorage';
import { isShowLoading, toast } from '../../helpers/functions/main';
import { addNotiCreateLibPreSaleCMS } from '../../helpers/functions/notification';
import { getRouteData, getRouteParams, redirect, setSideMenuActive } from '../../helpers/functions/route';
import { isValidResponse } from '../../helpers/functions/validation';
import { getProjectDocumentInformationData, getProjectDocumentProjectNameList } from '../../helpers/services/projectDocument';
import {
  getSegmentList,
  getSubSegmentList
} from '../../helpers/services/segment';
import {
  ModalSegmentInformationContainer
} from './../../containers/ModalContainer';
import { CONTENTS, ICONS, VARIABLES } from './../../themes';

export class ProjectDocumentInformationScreen extends React.Component {
  constructor(props) {
    super(props);
    setSideMenuActive(props);
    const language = getLocalStorage(LOCAL_STORAGE.LANGUAGE_RBPTRUEBLUE);
    const dataId = parseInt(getRouteParams(props, 'id'));
    let routeData = getRouteData(props, 'data');
    console.log('routeData', routeData);
    let customerData = undefined;

    let data = {
      projectName: '',
      installationAddress: '',
    };
    if (routeData) {
      customerData = routeData.customerData;
    }

    this.state = {
      dataId,
      customerData,
      selectSalePerson: undefined,
      segmentSelected: undefined,
      data,
      language,

      isEdit: dataId > 0,
      isDisableEdit: false,
      projectNameSelected: null,
      projectNameSelectOptionList: [],

      segmentGroupList: [],
      subSegmentList: [],
      isOpenSubSegmentList: [],
      timeoutSearchSegmentIndex: undefined,
      segmentAutoCompleteList: [],
      searchSegmentValue: '',

      isOpenSegmentInformationModal: false,
      segmentInformationList: [],
    };
  }

  componentDidMount() {
    const {
      isEdit
    } = this.state;
    if (isEdit) {
      this.projectDocumentInformation.getData();
    } else {
      this.projectDocumentInformation.getAutoCompleteProjectName();
      this.projectDocumentInformation.getSegmentDataList();
    }
  }

  projectDocumentInformation = {
    onClickRequestEdit: async () => {
      const {
        dataId,
        data,
        customerData,
      } = this.state;
      isShowLoading(true);
      const userAuth = getUserAuthRedux();
      const params = {
        pre_sales_trans_id: dataId,
        auth_deal_id: userAuth.id,
        // for email
        customer_name: _.get(customerData, 'displayFullname', ''),
        pre_sales_trans_project_phone: _.get(customerData, 'phoneCountry.value', '') + _.get(customerData, 'phone', ''),
        pre_sales_trans_project_name: data.projectName,
        company: userAuth.company,
      }
      const configService = {
        isShowLoading: false
      }
      const projectDocumentService = projectDocumentController(configService);
      const res = await projectDocumentService.projectDocumentRequestEditProjectInformation(params);
      if (isValidResponse(res)) {
        const result = await getProjectDocumentInformationData(dataId);
        toast.success(getLanguage('message.requestEditProjectDocumentSuccess'));
        if (result) {
          this.setState({
            data: result.data,
            isDisableEdit: result.data.status !== ENUM.PROJECT_DOCUMENT.INFORMATION_STATUS.APPROVED
          }, () => {
            isShowLoading(false);
          });
        }
        else {
          isShowLoading(false);
        }
      }
      else {
        toast.error(res.message);
        isShowLoading(false);
      }
    },
    getData: async () => {
      const {
        dataId,
      } = this.state;
      isShowLoading(true);
      const result = await getProjectDocumentInformationData(dataId);
      if (result) {
        this.setState({
          customerData: result.customerData,
          data: result.data,
          segmentSelected: result.segmentSelected,
          isDisableEdit: result.data.status !== ENUM.PROJECT_DOCUMENT.INFORMATION_STATUS.APPROVED
        }, async () => {
          await this.projectDocumentInformation.getAutoCompleteProjectName();
          await this.projectDocumentInformation.getSegmentDataList();
          isShowLoading(false);
        });
      }
      else {
        isShowLoading(false);
      }
    },
    getAutoCompleteProjectName: async () => {
      const {
        customerData,
      } = this.state;
      const userAuth = getUserAuthRedux();
      const configService = {
        urlParams: {
          auth_deal_id: userAuth.id,
          customer_id: customerData.id
        },
        isShowLoading: false,
      }
      const dataList = await getProjectDocumentProjectNameList(configService);
      this.setState({
        projectNameSelectOptionList: _.orderBy(_.uniqBy(dataList.map(e => {
          return {
            label: e.preSalesTransProjectName,
            value: e.preSalesTransProjectName,
            ...e,
          }
        }), 'preSalesTransProjectName'), ['preSalesTransProjectName'], ['asc'])
      })
    },
    handleGoBack: () => {
      const {
        dataId,
      } = this.state
      if (dataId) {
        redirect(`${ROUTE_PATH.PROJECT_DOCUMENT_MANAGE.LINK}/${dataId}`);
      }
      else {
        redirect(ROUTE_PATH.PROJECT_DOCUMENT.LINK);
      }
    },
    onClickContinue: async () => {
      const {
        data,
        customerData,
        segmentSelected,
        isEdit,
        dataId,
      } = this.state;
      const userAuth = getUserAuthRedux();
      const params = {
        pre_sales_trans_id: dataId,
        pre_sales_trans_project_name: data.projectName,
        pre_sales_trans_project_addr: data.installationAddress,
        pre_sales_trans_project_phone: _.get(customerData, 'phoneCountry.value', '') + _.get(customerData, 'phone', ''),
        pre_sales_trans_sub_dist: (data.subDistrict || {}).label || '',
        pre_sales_trans_dist: (data.selectedCity || {}).label || '',
        pre_sales_trans_prov: (data.selectedProvince || {}).label || '',
        pre_sales_trans_post_code: (data.postCode || {}).label || '',
        customer_id: customerData.id,
        segment_id: segmentSelected.segmentId,
        sub_segment_id: segmentSelected.subSegmentId,
        auth_deal_id: userAuth.id,
        trueblue_points__c: _.get(customerData, 'remainPoints', ''),
        customer_type__c: _.get(customerData, 'type', ''),
        segment__c: segmentSelected.segmentNameEN,
        sub_segment__c: segmentSelected.subSegmentNameEN,
        customer_name: _.get(customerData, 'displayFullname', ''),
        customer_nickname: _.get(customerData, 'nickname', ''),
      }
      const projectDocumentService = projectDocumentController();
      let res;
      if (isEdit) {
        params.updated_by = userAuth.name;
        res = await projectDocumentService.updateProjectDocument(params);
      }
      else {
        params.created_by = userAuth.name;
        res = await projectDocumentService.createProjectDocument(params);
      }
      if (isValidResponse(res)) {
        if (isEdit) {
          toast.success(getLanguage('message.projectDocumentSuccessUpdate'));
          this.projectDocumentInformation.handleGoBack();
        }
        else {
          addNotiCreateLibPreSaleCMS({
            preSalesTransId: res.pre_sales_trans_id,
            projectName: params.pre_sales_trans_project_name || '',
            subSegmentId: params.sub_segment_id || '',
            customerId: params.customer_id || '',
          });
          toast.success(getLanguage('message.projectDocumentSuccessCreate'));
          this.setState({
            dataId: res.pre_sales_trans_id
          }, () => {
            this.projectDocumentInformation.handleGoBack();
          })
        }

      }
      else {
        toast.error(res.message)
      }
    },
    handleSegmentGroup: (dataList) => {
      const rowMax = 5;
      const dataGroupList = [];
      let cnt = 0;
      let row = 0;

      dataList.forEach(e => {
        if (cnt < rowMax) {
          if (dataGroupList[row]) {
            dataGroupList[row].push(e);
          } else {
            dataGroupList[row] = [e];
          }
          cnt++;
        }
        if (cnt === rowMax) {
          cnt = 0;
          row++;
        }
      });
      return dataGroupList;
    },
    getSegmentDataList: async () => {
      const {
        language,
        isEdit,
        segmentSelected,
      } = this.state;
      const isLanguageTh = language === DATA.LANGUAGE.THAI;
      const params = {
        sort: 'segment_id asc',
      };
      let dataList = await getSegmentList(params);
      if (dataList) {
        // old
        // dataList.forEach((e) => {
        //   e.subSegmentList.forEach((es) => {
        //     resultList.push({
        //       id: `${e.segmentId}_${es.subSegmentId}`,
        //       segmentId: e.segmentId,
        //       subSegmentId: es.subSegmentId,
        //       segmentName: isLanguageTh ? e.segmentNameTH : e.segmentNameEN,
        //       segmentDescription: isLanguageTh ? e.segmentDescTH : e.segmentDescEN,
        //       subSegmentName: isLanguageTh ? es.subSegmentNameTH : es.subSegmentNameEN,
        //       subSegmentDescription: isLanguageTh
        //         ? es.subSegmentDescTH
        //         : es.subSegmentDescEN,
        //     })
        //   });
        // });

        let newSegmentSelected = segmentSelected;

        dataList = dataList.map(e => {
          e.name = isLanguageTh ? e.segmentNameTH : e.segmentNameEN;
          e.description = isLanguageTh ? e.segmentDescTH : e.segmentDescEN;
          e.image = e.segmentImg;
          e.subSegmentList = e.subSegmentList.map(es => {
            es.name = isLanguageTh ? es.subSegmentNameTH : es.subSegmentNameEN;
            es.description = isLanguageTh ? es.subSegmentDescTH : es.subSegmentDescEN;
            es.image = es.subSegmentImg;
            es.segmentImage = e.segmentImg;
            // for prefill edit info
            if (isEdit && segmentSelected) {
              if (segmentSelected.subSegmentId === es.subSegmentId) {
                newSegmentSelected = {
                  ...es,
                  segmentName: e.name,
                  segmentDescription: e.description,
                }
              }
            }

            return es;
          });
          return e;
        })

        this.setState({
          segmentList: dataList,
          segmentGroupList: this.projectDocumentInformation.handleSegmentGroup(dataList),
          segmentSelected: newSegmentSelected,
        })
      }
    },
    onClickSearchSegment: () => {
      const {
        searchSegmentValue,
        segmentList,
      } = this.state;
      const dataList = _.cloneDeep(segmentList);
      const newDataList = dataList.filter(e => {
        e.subSegmentList = e.subSegmentList.filter(es => {
          return (es.subSegmentNameEN || '').toLowerCase().indexOf(searchSegmentValue.toLowerCase()) >= 0 ||
            (es.subSegmentNameTH || '').toLowerCase().indexOf(searchSegmentValue.toLowerCase()) >= 0 ||
            (es.subSegmentDescEN || '').toLowerCase().indexOf(searchSegmentValue.toLowerCase()) >= 0 ||
            (es.subSegmentDescTH || '').toLowerCase().indexOf(searchSegmentValue.toLowerCase()) >= 0;
        });
        return e.subSegmentList.length;
      }).map(e => {
        e.isOpenSubSegment = false;
        return e;
      });;
      console.log('searchSegmentValue', searchSegmentValue);
      console.log('newDataList', newDataList);
      this.setState({
        isOpenSubSegmentList: [],
        subSegmentList: [],
        segmentGroupList: this.projectDocumentInformation.handleSegmentGroup(newDataList),
      });
    },
    getIsSegmentSelected: (value, type) => {
      const {
        segmentSelected,
      } = this.state;
      switch (type) {
        case 'segment':
          return segmentSelected &&
            segmentSelected.segmentId === value.segmentId;
        case 'subSegment':
          return segmentSelected &&
            segmentSelected.segmentId === value.segmentId &&
            segmentSelected.subSegmentId === value.subSegmentId;
        default: return false;
      }
    },
    onClickSegment: (value, callback = () => { }, option = {}) => {
      const {
        segmentGroupList,
      } = this.state;

      let subSegmentList = [];
      const isOpenSubSegmentList = [];
      const newDataList = segmentGroupList.map((e, i) => {
        let isOpenSubSegment = false;
        e = e.map(item => {
          if (item.segmentId === value.segmentId) {
            item.isActive = option.isActive ? option.isActive : !item.isActive;
            item.isOpenSubSegment = option.isOpenSubSegment ? option.isOpenSubSegment : !item.isOpenSubSegment;
          }
          else {
            item.isActive = false;
            item.isOpenSubSegment = false;
          }
          if (item.isOpenSubSegment) {
            subSegmentList = _.cloneDeep(item.subSegmentList);
            isOpenSubSegment = true;
          }
          return item;
        });
        isOpenSubSegmentList.push(isOpenSubSegment);
        return e;
      });

      this.setState({
        segmentGroupList: newDataList,
        isOpenSubSegmentList,
        subSegmentList,
      }, () => {
        callback();
      });
    },
    onClickSubSegment: (value) => {
      const {
        subSegmentList,
      } = this.state;
      subSegmentList.forEach(e => {
        if (e.segmentId === value.segmentId && e.subSegmentId === value.subSegmentId) {
          this.setState({
            segmentSelected: e,
          })
          console.log('segmentSelected', e)
        }
      });
    },
    getSearchSegmentAutoCompleteList: (value) => {
      const {
        timeoutSearchSegmentIndex,
      } = this.state;
      clearTimeout(timeoutSearchSegmentIndex);
      this.setState({
        timeoutSearchSegmentIndex: setTimeout(async () => {
          const params = {
            text: value
          }
          let dataList = await getSubSegmentList(params);
          const segmentAutoCompleteList = [];
          dataList.forEach(e => {
            segmentAutoCompleteList.push({
              label: e.subSegmentNameEN,
              value: e.subSegmentNameEN,
              data: e
            });
            segmentAutoCompleteList.push({
              label: e.subSegmentNameTH,
              value: e.subSegmentNameTH,
              data: e
            });
            segmentAutoCompleteList.push({
              label: e.subSegmentDescEN,
              value: e.subSegmentDescEN,
              data: e
            });
            segmentAutoCompleteList.push({
              label: e.subSegmentDescTH,
              value: e.subSegmentDescTH,
              data: e
            });
          });

          this.setState({
            segmentAutoCompleteList,
          });
        }, 500)
      })
    },
    onClickOpenSegmentInformationModal: () => {
      this.projectDocumentInformation.getSegmentInformationList(() => {
        this.setState({
          isOpenSegmentInformationModal: true,
        });
      })
    },
    getSegmentInformationList: async (callback = () => { }) => {
      const {
        segmentList,
      } = this.state;
      const segmentInformationList = [];
      segmentList.forEach(e => {
        e.subSegmentList.forEach(es => {
          segmentInformationList.push({
            ...e,
            subSegmentName: es.name,
            subSegmentDesc: es.description,
          });
        });
      })
      this.setState({
        segmentInformationList,
      }, () => {
        callback();
      });
    },
    isDisableButtonContinue: () => {
      const {
        segmentSelected,
        selectSalePerson,
        data,
      } = this.state;
      let isDisabled = false;
      if (
        // !selectSalePerson ||
        !segmentSelected ||
        data.projectName.length === 0 ||
        data.installationAddress.length === 0 ||
        !data.selectedProvince ||
        !data.selectedCity ||
        !data.subDistrict ||
        !data.postCode
      ) {
        isDisabled = true;
      }
      return isDisabled;
    },
    onChangeSelectSalePerson: (selectSalePerson) => {
      this.setState({
        selectSalePerson
      })
    },
    handleSetSelectSalePerson: (dataList) => {
      if (dataList.length) {
        const userAuth = getUserAuthRedux();
        const saleUser = dataList.filter(e => {
          return e.data.sfid === userAuth.sfid
        })[0];
        if (saleUser) {
          this.setState({
            selectSalePerson: saleUser
          });
        }
        else {
          this.setState({
            selectSalePerson: dataList[0]
          });
        }
      }
    },
  };

  modal = {
    onCloseSegmentInformationModal: () => {
      this.setState({
        isOpenSegmentInformationModal: false,
      });
    },
  };

  input = {
    onChangeInput: (ev) => {
      const name = ev.target.name;
      const value = ev.target.value;
      this.setState({
        [name]: value,
      });
    },
    onChangeDataInput: (ev) => {
      const {
        data,
      } = this.state;
      const name = ev.target.name;
      const value = ev.target.value;
      data[name] = value;
      this.setState({
        data,
      });
    },
    onChangeAddress: (selected, target) => {
      const {
        data,
      } = this.state;
      data[target] = selected;
      switch (target) {
        case 'selectedProvince':
          data.selectedCity = null;
          data.subDistrict = null;
          data.postCode = null;
          break;
        case 'selectedCity':
          data.subDistrict = null;
          data.postCode = null;
          break;
        case 'subDistrict':
          data.postCode = null;
          break;
        default: break;
      }
      this.setState({
        data,
      })
    },
    onChangeAutoCompleteInput: (name, value, option, maxLength) => {
      const {
        data,
      } = this.state;
      if (option.action === 'input-change') {
        if (value.length <= maxLength) {
          data[name] = value;
          this.setState({
            data,
            projectNameSelected: null,
          })
        }
      }
    },
    onChangeAutoCompleteData: (name, selected) => {
      const {
        data,
      } = this.state;
      data[name] = selected.value;
      this.setState({
        projectNameSelected: selected,
        data,
      })
    },
    onChangeSearchAutoCompleteSegment: (value, option) => {
      if (option.action === 'input-change') {
        this.setState({
          searchSegmentValue: value
        });
        this.projectDocumentInformation.getSearchSegmentAutoCompleteList(value);
      }
    },
    onChangeSearchSelectedSegment: (selected) => {
      const {
        segmentList,
      } = this.state;
      this.setState({
        segmentGroupList: this.projectDocumentInformation.handleSegmentGroup(segmentList),
        searchSegmentValue: '',
      }, () => {
        this.projectDocumentInformation.onClickSegment(selected.data, () => {
          this.projectDocumentInformation.onClickSubSegment(selected.data);
        }, { isActive: true, isOpenSubSegment: true });
      });
    }
  }

  render() {
    const {
      customerData,
      segmentSelected,
      data,
      selectSalePerson,
      isEdit,
      projectNameSelected,
      projectNameSelectOptionList,

      segmentGroupList,
      subSegmentList,
      isOpenSubSegmentList,
      searchSegmentValue,
      segmentAutoCompleteList,

      isOpenSegmentInformationModal,
      segmentInformationList,

      isDisableEdit,
    } = this.state;

    return (
      <React.Fragment>
        <Breadcrumb>
          <Breadcrumb.List
            link
            name={getLanguage('preTransaction.breadcrumb1', '')}
            onClick={() => {
              redirect(ROUTE_PATH.HOME.LINK);
            }}
          />
          <Breadcrumb.List name="/" />
          <Breadcrumb.List
            link
            name={getLanguage('preTransaction.breadcrumb2', '')}
          />
          <Breadcrumb.List name="/" />
          <Breadcrumb.List
            link
            name={customerData?.fullname}
          />
          <Breadcrumb.List name="/" />
          <Breadcrumb.List
            name={getLanguage('preTransaction.breadcrumb4', '')}
          />
        </Breadcrumb>
        <Section ui="content">
          <Section spacingBottom="35">
            <Grid gutterVertical="15" justify="space-between">
              <Grid.Column flex='1' >
                <TitlePage
                  src={ICONS['ic-pre-transaction.svg']}
                  name={getLanguage('preTransaction.projectInformation.title', '')}
                />
              </Grid.Column>
            </Grid>

          </Section>
          <ShapeContainer ui="content">
            {customerData &&
              <CustomerInfoContainerConnected
                isHideSalePerson
                displayNoneButtonSelectSalePerson
                data={customerData}
              // selectSalePerson={selectSalePerson}
              // onChangeSelectSalePerson={this.projectDocumentInformation.onChangeSelectSalePerson}
              // handleSetSelectSalePerson={this.projectDocumentInformation.handleSetSelectSalePerson}
              // disableSelectSalePerson={true}
              // disableSelectSalePersonColor={VARIABLES.COLORS.GRAY_8}
              />
            }
            <Section spacingBottom="35">
              <TitleSection
                name={getLanguage('preTransaction.label.information', '')}
              />
            </Section>
            <Section spacingBottom="30">
              <Grid>
                <Grid.Column gridTablet="12" gridDesktop="5">
                  <Section spacingBottom="5">
                    {isDisableEdit ?
                      <Field
                        name="projectName"
                        fluid
                        required
                        disabled
                        disabledColor={VARIABLES.COLORS.GRAY_8}
                        // error
                        ui="blockLabelVerticalTextarea"
                        label={getLanguage('preTransaction.field.projectName', '')}
                        placeholder={getLanguage('preTransaction.field.placeholder', '')}
                        value={data.projectName}
                        onChange={this.input.onChangeDataInput}
                        maxLength={100}
                      />
                      :
                      <SelectControl
                        verticalLabel
                        isSearchable
                        label={getLanguage('preTransaction.field.projectName', '')}
                        placeholder={getLanguage('preTransaction.field.placeholder', '')}
                        value={projectNameSelected}
                        inputValue={data.projectName}
                        styles={getStyleSelectControl()}
                        onInputChange={(value, option) => {
                          this.input.onChangeAutoCompleteInput('projectName', value, option, 100);
                        }}
                        onChange={(selected) => this.input.onChangeAutoCompleteData('projectName', selected)}
                        options={projectNameSelectOptionList}
                      />
                    }
                  </Section>
                  <Text regular10 color={VARIABLES.COLORS.GRAY_19}>
                    {getLanguage('preTransaction.field.maxProjectName', '')}
                  </Text>
                </Grid.Column>
              </Grid>
            </Section>
            <Section spacingBottom="30">
              <Grid>
                <Grid.Column gridTablet="12" gridDesktop="5">
                  <Section spacingBottom="5">
                    <Field
                      name="installationAddress"
                      fluid
                      required
                      disabled={isDisableEdit}
                      disabledColor={VARIABLES.COLORS.GRAY_8}
                      // error
                      ui="blockLabelVerticalTextarea"
                      label={getLanguage(
                        'preTransaction.field.projectAddress',
                        ''
                      )}
                      placeholder={getLanguage(
                        'preTransaction.field.placeholder',
                        ''
                      )}
                      value={data.installationAddress}
                      onChange={this.input.onChangeDataInput}
                      maxLength={100}
                    />
                  </Section>
                  <Text regular10 color={VARIABLES.COLORS.GRAY_19}>
                    {getLanguage(
                      'preTransaction.field.maxprojectAddress',
                      ''
                    )}
                  </Text>
                </Grid.Column>
              </Grid>
            </Section>
            <Section spacingBottom="30">
              <FieldAddressContainerConnected
                thai
                disabledAddress={isDisableEdit}
                disabledCity={isDisableEdit}
                disabledPostalCode={isDisableEdit}
                disabledProvince={isDisableEdit}
                disabledSubdistrict={isDisableEdit}
                disabledDistrict={isDisableEdit}
                disabledColor={VARIABLES.COLORS.GRAY_8}
                // Address
                isRemoveAddress
                // Province
                requiredProvince
                nameProvince='selectedProvince'
                valueProvince={data.selectedProvince}
                onChangeProvince={(selected) => {
                  this.input.onChangeAddress(selected, 'selectedProvince');
                }}
                // City
                requiredCity
                nameCity='selectedCity'
                valueCity={data.selectedCity}
                onChangeCity={(selected) => {
                  this.input.onChangeAddress(selected, 'selectedCity');
                }}
                // Subdistrict
                requiredSubdistrict
                nameSubdistrict='subDistrict'
                valueSubdistrict={data.subDistrict}
                onChangeSubdistrict={(selected) => {
                  this.input.onChangeAddress(selected, 'subDistrict');
                }}
                // PostalCode
                requiredPostalCode
                namePostalCode='postCode'
                valuePostalCode={data.postCode}
                onChangePostalCode={(selected) => {
                  this.input.onChangeAddress(selected, 'postCode');
                }}
              />
            </Section>
            <Section spacingBottom="35">
              <TitleSection
                name={getLanguage('preTransaction.label.segment', '')}
              />
            </Section>
            {!isDisableEdit &&
              <Section spacingBottom="30">
                <Grid justify='space-between'>
                  <Grid.Column>
                    <Grid gutter="15" gutterVertical="15" >
                      <Grid.Column flex="1" >
                        <Section width={450}>
                          <SelectControl
                            filterBorder
                            isFilterSearch
                            isSearchable
                            placeholder={getLanguage('field.segmentPlaceholder', '')}
                            value={null}
                            inputValue={searchSegmentValue}
                            onInputChange={this.input.onChangeSearchAutoCompleteSegment}
                            onChange={this.input.onChangeSearchSelectedSegment}
                            options={segmentAutoCompleteList}
                          />
                        </Section>
                      </Grid.Column>
                      <Grid.Column flex="none" >
                        <Button
                          lightBlue
                          name={getLanguage('coilTransferHistory.buttonSearch', '')}
                          width={VARIABLES.BUTTON.WIDTHS.W_100}
                          onClick={this.projectDocumentInformation.onClickSearchSegment}
                        />
                      </Grid.Column>
                    </Grid>
                  </Grid.Column>
                  <Grid.Column>
                    <Section justify="end" spacingBottom="10">
                      <Grid gutter="5">
                        <Grid.Column>
                          <Button
                            ui="frontIcon"
                            lightestBlue
                            outlineLightestBlue
                            textSmall
                            width="100"
                            iconWidth="22"
                            iconHeight="16"
                            iconSrc={ICONS['ic-table-blue.svg']}
                            name={getLanguage(
                              'preTransaction.button.segmentAndSubSegmentSelection',
                              ''
                            )}
                            onClick={this.projectDocumentInformation.onClickOpenSegmentInformationModal}
                          />
                        </Grid.Column>
                      </Grid>
                    </Section>
                  </Grid.Column>
                </Grid>
              </Section>
            }
            {isDisableEdit ?
              <Grid justify="space-between" gutter="40" gutterVertical="40">
                <Grid.Column flex="1">
                  <Section spacingBottom="10">
                    <Text
                      regular14
                      color={VARIABLES.COLORS.GRAY_19}
                    >
                      Segment
                    </Text>
                  </Section>
                  <ShapeContainer
                    fluid
                    bgColor={VARIABLES.COLORS.GRAY_8}
                    borderRadius={5}
                    padding={15}
                    align="center"
                  >
                    <Grid gutter="15" >
                      <Grid.Column>
                        <ShapeContainer
                          bgColor={VARIABLES.COLORS.WHITE}
                          borderRadius={5}
                          align="center"
                        >
                          <Image
                            src={_.get(segmentSelected, 'segmentImage.path', '')}
                            widthRatio='72'
                            widthRatioUnit='px'
                            heightRatio='72'
                            heightRatioUnit='px'
                          />
                        </ShapeContainer>
                      </Grid.Column>
                      <Grid.Column flex='1'>
                        <Section align="center" spacingTop="15" >
                          <Section spacingBottom="5" >
                            <Text
                              bold16
                              color={VARIABLES.COLORS.BLACK}
                            >
                              {segmentSelected?.segmentName}
                            </Text>
                          </Section>
                          <Text
                            regular12
                            color={VARIABLES.COLORS.GRAY_19}
                          >
                            {segmentSelected?.segmentDescription}
                          </Text>
                        </Section>
                      </Grid.Column>
                    </Grid>
                  </ShapeContainer>
                </Grid.Column>
                <Grid.Column flex="1">
                  <Section spacingBottom="10">
                    <Text
                      regular14
                      color={VARIABLES.COLORS.GRAY_19}
                    >
                      Sub segment
                    </Text>
                  </Section>
                  <ShapeContainer
                    fluid
                    bgColor={VARIABLES.COLORS.GRAY_8}
                    borderRadius={5}
                    padding={15}
                    align="center"
                  >
                    <Grid gutter="15" >
                      <Grid.Column>
                        <ShapeContainer
                          bgColor={VARIABLES.COLORS.WHITE}
                          borderRadius={5}
                          align="center"
                        >
                          <Image
                            src={_.get(segmentSelected, 'image.path', '')}
                            widthRatio='72'
                            widthRatioUnit='px'
                            heightRatio='72'
                            heightRatioUnit='px'
                          />
                        </ShapeContainer>

                      </Grid.Column>
                      <Grid.Column flex='1'>
                        <Section align="center" spacingTop="15" >
                          <Section spacingBottom="5" >
                            <Text
                              bold16
                              color={VARIABLES.COLORS.BLACK}
                            >
                              {segmentSelected?.name}
                            </Text>
                          </Section>
                          <Text
                            regular12
                            color={VARIABLES.COLORS.GRAY_19}
                          >
                            {segmentSelected?.description}
                          </Text>
                        </Section>
                      </Grid.Column>
                    </Grid>
                  </ShapeContainer>
                </Grid.Column>
              </Grid>
              :
              <Section>
                <ShapeContainer fluid padding='30' bgColor={VARIABLES.COLORS.WHITE} borderRadius='5'>
                  {segmentGroupList.map((e, i) => {
                    return (
                      <React.Fragment key={i}>
                        <Section paddingTop='15'>
                          <Grid gutter='15'>
                            {e.map((item, iItem) => {
                              return (
                                <Grid.Column key={`${i}_${iItem}`} gridSystem='10' grid='2'>
                                  <SegmentCard
                                    isNotSelected={!this.projectDocumentInformation.getIsSegmentSelected(item, 'segment')}
                                    isActive={item.isActive}
                                    isSelected={this.projectDocumentInformation.getIsSegmentSelected(item, 'segment')}
                                    isOpenSubSegmentLists={item.isOpenSubSegment}
                                    name={item.name}
                                    description={item.description}
                                    imageSrc={_.get(item, 'image.path', '')}
                                    onClick={() => { this.projectDocumentInformation.onClickSegment(item) }}
                                  />
                                </Grid.Column>
                              )
                            })}
                          </Grid>
                        </Section>
                        {isOpenSubSegmentList[i] &&
                          <Section paddingTop='15'>
                            <ShapeContainer fluid padding='15' bgColor={VARIABLES.COLORS.GRAY_1} borderStyle='solid' borderWidth='1' borderColor={VARIABLES.COLORS.GRAY_8} borderRadius='5'>
                              <Grid gutter='15' gutterVertical='15'>
                                {subSegmentList.map((item, iItem) => {
                                  return (
                                    <Grid.Column gridSystem='10' grid='2' key={`${i}_${iItem}`}>
                                      <SegmentCard
                                        isSelected={this.projectDocumentInformation.getIsSegmentSelected(item, 'subSegment')}
                                        name={item.name}
                                        description={item.description}
                                        imageSrc={_.get(item, 'image.path', '')}
                                        onClick={() => { this.projectDocumentInformation.onClickSubSegment(item) }}
                                      />
                                    </Grid.Column>
                                  )
                                })}
                              </Grid>
                            </ShapeContainer>
                          </Section>
                        }
                      </React.Fragment>
                    )
                  })}
                </ShapeContainer>
              </Section>
            }

            {isDisableEdit ?
              <Section spacingTop="45">
                <Grid gutter="15" justify="flex-end">
                  <Grid.Column>
                    <Button
                      gray
                      ui="back"
                      name={getLanguage('field.buttonBack', '')}
                      onClick={this.projectDocumentInformation.handleGoBack}
                    />
                  </Grid.Column>
                  <Grid.Column>
                    <Button
                      blue
                      disabled={data.status === ENUM.PROJECT_DOCUMENT.INFORMATION_STATUS.REQUESTING}
                      name={getLanguage('field.buttonRequestToEdit', '')}
                      width={VARIABLES.BUTTON.WIDTHS.W_125}
                      onClick={this.projectDocumentInformation.onClickRequestEdit}
                    />
                  </Grid.Column>
                </Grid>
              </Section>
              :
              <Section spacingTop="45">
                <Grid gutter="15" justify="flex-end">
                  <Grid.Column>
                    <Button
                      gray
                      ui="back"
                      name={getLanguage('field.buttonCancel', '')}
                      onClick={this.projectDocumentInformation.handleGoBack}
                    />
                  </Grid.Column>
                  <Grid.Column>
                    <Button
                      blue
                      disabled={this.projectDocumentInformation.isDisableButtonContinue()}
                      name={getLanguage('field.buttonSaveAndContinue', '')}
                      width={VARIABLES.BUTTON.WIDTHS.W_125}
                      onClick={this.projectDocumentInformation.onClickContinue}
                    />
                  </Grid.Column>
                </Grid>
              </Section>
            }
          </ShapeContainer>
        </Section>

        {isOpenSegmentInformationModal &&
          <ModalSegmentInformationContainer
            isOpenModal
            dataList={segmentInformationList}
            primaryTitle={getLanguage('warrantyManage.form.segment.modal.segmentAndSubSegmentInformation', '')}
            secondaryTitle={''}
            onClickClose={this.modal.onCloseSegmentInformationModal}
          />
        }
      </React.Fragment>
    );
  }
}
