import React from 'react'
import {
  ShapeContainer,
  Section,
  Breadcrumb,
  TitlePage,
  Text
} from './../../components'
import {
  VARIABLES,
  ICONS
} from './../../themes'
import { setSideMenuActive } from '../../helpers/functions/route';
import { ROUTE_PATH } from '../../helpers/constants/route';
import { redirect } from '../../helpers/functions/route';
import { TncContainerConnected } from '../../containers/TncContainer';
import _ from 'lodash';
import { getLanguage } from '../../helpers/functions/language';

export class TermAndConditionScreen extends React.Component {

  constructor(props) {
    super(props);
    setSideMenuActive(props);
  }

  render() {
    return (
      <React.Fragment>
        <Breadcrumb>
          <Breadcrumb.List
            link
            name={getLanguage('tnc.breadcrumb1', '')}
            onClick={() => {
              redirect(ROUTE_PATH.HOME.LINK)
            }}
          />
          <Breadcrumb.List name='/' />
          <Breadcrumb.List
            link
            name={getLanguage('tnc.breadcrumb2', '')}
          // onClick={() => {
          //   redirect(ROUTE_PATH.SETTINGS.LINK)
          // }}
          />
          <Breadcrumb.List name='/' />
          <Breadcrumb.List name={getLanguage('tnc.breadcrumb3', '')} />
        </Breadcrumb>
        <Section ui='content'>
          <Section spacingBottom='35'>
            <TitlePage
              src={ICONS['ic-page-settings.svg']}
              name={getLanguage('tnc.title', '')}
            />
          </Section>
          <ShapeContainer ui='content'>
            <ShapeContainer
              fluid
              paddingVertical='40'
              paddingHorizontal='60'
              borderWidth='1'
              borderStyle='solid'
              borderColor={VARIABLES.COLORS.GRAY_16}
              borderRadius='5'
            >
              <Section
                spacingBottom='50'
                justify='center'
              >
                <Text
                  bold24
                  color={VARIABLES.COLORS.GRAY_17}
                  align='center'
                >
                  {getLanguage('tnc.form.tnc.title', '')}
                </Text>
              </Section>
              <TncContainerConnected />
            </ShapeContainer>
          </ShapeContainer>
        </Section>
      </React.Fragment>
    )
  }
}
