import { displayWithPrefix, displayWithComma } from "../helpers/functions/display";
import { ModelImage } from "./ModelImage";
import { ENUM } from "../helpers/constants/enum";

export class ModelCMSRewardCatalog {
  constructor(props = {}) {
    this.uniqeId = props.reward_location_sfid;
    this.id = props.id;
    this.idWithPrefix = displayWithPrefix(props.id);
    this.name = props.name;
    this.categoryId = props.category_id;
    this.description = props.description;
    this.pointsWithComma = displayWithComma(props.points);
    this.points = props.points.toString();
    this.pointsCal = parseInt(props.points || 0);
    this.unitValue = 0;
    this.images = props.images;
    this.galleryList = (props.images || []).map(e => new ModelImage({ path: e }));
    this.createAt = props.create_at;
    this.status = props.status;
    this.isActive = props.status === ENUM.STATUS_ACTIVE.ACTIVE;
    this.categoryName = props.category_name;
    this.stock = props.stock;
    this.itemsAvailableC = props.items_available__c;
    this.quantity = props.quantity;
    this.stockType = props.stock_type;
    // this.inStock = props.in_stock + props.quantity;
    this.inStock = props.in_stock;
    this.inStockWithComma = displayWithComma(this.inStock);
    this.displayStock = this.stockType === ENUM.REWARD_STOCK_TYPE.IN_STOCK ? this.inStockWithComma : ENUM.REWARD_STOCK_TYPE.ON_DEMAND;
    this.rewardSfid = props.reward_sfid;
    this.rewardLocationSfid = props.reward_location_sfid;
    this.mapId__c = props.map_id__c;
    this.isNotShowInTrueblue = props.not_show_in_trueblue__c;
  }
}