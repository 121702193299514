import { ICONS } from "../../themes";
import { ENUM } from "./enum";
import { WARRANTY } from "./warranty";

export const DATE_MOMENT_FORMAT = 'YYYY-MM-DD';
export const DATE_FORMAT = 'DD/MM/YYYY';
export const DATE_MMDDYYYY_FORMAT = 'MM/DD/YYYY';
export const DATE_MONTH_NAME_FORMAT = 'DD MMM YYYY';
export const DATE_TIME_FORMAT = 'DD/MM/YYYY HH:mm';
export const DATE_PICKER_FORMAT = 'dd/MM/yyyy';

const PHONE_PREFIX_LIST = [
  { value: '66', label: '+66', flag: ICONS['ic-flag-th.jpg'], subStrLength: 2, lengthList: [9], country: ENUM.COUNTRY.THAI, countryType: ENUM.COUNTRY_TYPE.THAI, }, // th
  { value: '60', label: '+60', flag: ICONS['ic-flag-my.jpg'], subStrLength: 2, lengthList: [9, 10], country: ENUM.COUNTRY.MALAYSIA, countryType: ENUM.COUNTRY_TYPE.MALAYSIA, }, // my
  { value: '84', label: '+84', flag: ICONS['ic-flag-vn.jpg'], subStrLength: 2, lengthList: [9], country: ENUM.COUNTRY.VIETNAM, countryType: ENUM.COUNTRY_TYPE.VIETNAM, }, // vn
  { value: '62', label: '+62', flag: ICONS['ic-flag-id.jpg'], subStrLength: 2, lengthList: [8, 9, 10, 11, 12, 13], country: ENUM.COUNTRY.INDONESIA, countryType: ENUM.COUNTRY_TYPE.INDONESIA, }, // in
  { value: '856', label: '+856', flag: ICONS['ic-flag-la.jpg'], subStrLength: 3, lengthList: [10], country: ENUM.COUNTRY.LAOS, countryType: ENUM.COUNTRY_TYPE.LAOS, }, // la
]

const LANGUAGE = {
  THAI: 'th',
  ENGLISH: 'en',
  VIETNAM: 'vi',
  INDONESIA: 'in',
  MALAYSIA: 'ml',
  LAOS: 'la',
}

const SALE_PERSON_POSITION = {
  OWNER: {
    ID: 'เจ้าของ',
    NAME: 'เจ้าของ',
  },
  MANAGER: {
    ID: 'ผู้จัดการ',
    NAME: 'ผู้จัดการ',
  },
  SALE: {
    ID: 'พนักงานขาย',
    NAME: 'พนักงานขาย',
  },
  ADMINISTRATIVE: {
    ID: 'พนักงานธุรการ',
    NAME: 'พนักงานธุรการ',
  },
  OTHER: {
    ID: 'อื่นๆ',
    NAME: 'อื่นๆ',
  },
}

const PUBLIC_YEAR_WARRANTY_BUILDING_TYPE_LIST = [{
  NAME: 'RESIDENTIAL',
  VALUE: 1,
}, {
  NAME: 'INSTITUTIONAL',
  VALUE: 2,
}, {
  NAME: 'INFRASTRUCTURE',
  VALUE: 3,
}, {
  NAME: 'COMMERCIAL',
  VALUE: 4,
}, {
  NAME: 'INDUSTRIAL',
  VALUE: 5,
}]

const PROJECT_REFERENCE_TYPE = {
  WARRANTY: 1,
  PRE_SALES_TRANSACTION: 2,
}

const NOTIFICATION_TYPE = {
  COIL_TRANSFER: 1,
}

const NOTIFICATION_CMS_TYPE = {
  LIBRARY: 1,
}

const PRE_SALES_TRANSACTION = {
  DOCUMENTARY_TEMPLATE_CATEGORY: 'Test Certificate',
}

const PROJECT_DOCUMENT_TAB_TYPE_LIST = ['Warranty guideline', 'Warranty template', 'Warranty', 'Documentary', 'Certificate', 'Reference'];

const WATERMARK_TEMPLATE_LIST = [{
  label: 'Warranty Documentary',
  value: ENUM.WATERMARK_TEMPALTE['Warranty Documentary'],
}, {
  label: 'Post-Certificate',
  value: ENUM.WATERMARK_TEMPALTE['Post-Certificate'],
}, {
  label: 'Pre-Certificate',
  value: ENUM.WATERMARK_TEMPALTE['Pre-Certificate'],
}, {
  label: 'Warranty Template',
  value: ENUM.WATERMARK_TEMPALTE['Warranty Template'],
}]

const WARRANTY_QUESTION_SELECT_TYPE_LIST = [
  {
    label: '0 m.',
    value: '0 m.',
  },
  {
    label: '500 m.',
    value: '500 m.',
  },
  {
    label: '1000 m.',
    value: '1000 m.',
  },
  {
    label: '1500 m.',
    value: '1500 m.',
  },
  {
    label: '2000 m.',
    value: '2000 m.',
  },
  {
    label: '> 2000 m.',
    value: '> 2000 m.',
  },
]

const PUBLIC_WARRANTY_DOCUMENTARY_TYPE = {
  WARRANTY: '1',
  WARRANTY_DOCUMENTARY: '2',
  CERTIFICATE: '3',
  WARRANTY_TEMPLATE: '4',
}

export const DATA = {
  PHONE_PREFIX_LIST,
  LANGUAGE,
  ...WARRANTY,
  SALE_PERSON_POSITION,
  PUBLIC_YEAR_WARRANTY_BUILDING_TYPE_LIST,
  PROJECT_REFERENCE_TYPE,
  NOTIFICATION_CMS_TYPE,
  PRE_SALES_TRANSACTION,
  NOTIFICATION_TYPE,
  PROJECT_DOCUMENT_TAB_TYPE_LIST,
  WATERMARK_TEMPLATE_LIST,
  WARRANTY_QUESTION_SELECT_TYPE_LIST,
  PUBLIC_WARRANTY_DOCUMENTARY_TYPE
}