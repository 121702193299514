import { redemptionController } from "../../apiService/apiController/redemptionService"
import { isValidResponse } from "../functions/validation";
import { storeDispatch, storeGetState } from "../../stores";
import { dataCreators, DATA_REDUX_STATE_KEY } from "../../actions/dataAction";
import { ModelRedemption } from "../../models/ModelRedemption";
import { handleSortParams, handleTrimParams } from "../functions/data";

export const getRedemptionList = async (params = {}) => {
  let currentParams = storeGetState().dataRedux.redemptionParams;
  let userAuth = storeGetState().mainRedux.userAuth;
  let newParams = handleTrimParams({
    ...currentParams,
    ...params,
    ad_ref_id: userAuth.adRefId,
  });
  if (!newParams.isNoSort) {
    if (!newParams.sort) {
      newParams.sort = 'id desc';
    }
    else {
      newParams = handleSortParams(currentParams, newParams);
    }
  }
  const redemptionService = redemptionController();
  const res = await redemptionService.searchRedemtion(newParams);
  if (isValidResponse(res)) {
    const dataList = res.data.map(e => new ModelRedemption(e));
    newParams.activePage = newParams.page;
    newParams.totalData = res.total_items;
    console.log('dataList', res.data, dataList, newParams);
    storeDispatch(dataCreators.setDataRedux(DATA_REDUX_STATE_KEY.REDEMPTION_LIST, dataList));
    storeDispatch(dataCreators.setDataRedux(DATA_REDUX_STATE_KEY.REDEMPTION_PARAMS, newParams));
  }
}

export const getClearRedemptionParams = () => {
  return {
    id: '',
    reward_name: '',
    reward_id: '',
    customer_id: '',
    customer_name: '',
    sales_person_name: '',
    phone_no: '',
    start_date: '',
    end_date: '',
    all_field: '',
    sort: '',
    isNoSort: false,
  }
}