import React from 'react'
import {
  Section,
  Breadcrumb,
  TitlePage,
  Grid,
  Button,
  Field,
} from './../../components'
import {
  VARIABLES,
  ICONS,
} from './../../themes'
import { setSideMenuActive, getRouteParams } from '../../helpers/functions/route';
import { ROUTE_PATH } from '../../helpers/constants/route';
import { redirect } from '../../helpers/functions/route';
import _ from 'lodash';
import { getLanguage } from '../../helpers/functions/language';
import { UploadTransactionHistoryDetailListContainerConnected } from '../../containers/UploadTransactionHistoryDetailListContainer/UploadTransactionHistoryDetailListContainer';
import { getClearUploadTransactionHistoryDetailParams, getUploadTransactionSearchFileDetailList } from '../../helpers/services/uploadTransactionHistoryDetail';

export class UploadTransactionHistoryDetailScreen extends React.Component {

  constructor(props) {
    super(props);
    setSideMenuActive(props);
    const dataId = parseInt(getRouteParams(props, 'id'));
    console.log('dataId', dataId);
    this.state = {
      searchValue: '',
      dataId
    }
  }

  input = {
    onChangeInput: (ev) => {
      const name = ev.target.name;
      const value = ev.target.value;
      this.setState({
        [name]: value,
      })
    },
    onClickSearchText: () => {
      const {
        searchValue,
      } = this.state;
      let params = {
        ...getClearUploadTransactionHistoryDetailParams(),
        page: 1,
      };
      params.invoice_no = searchValue;
      params.project_name = searchValue;
      getUploadTransactionSearchFileDetailList(params);
    },
  }

  render() {
    const {
      dataId,
      searchValue,
    } = this.state
    return (
      <React.Fragment>
        <Breadcrumb>
          <Breadcrumb.List
            link
            name={getLanguage('uploadTransactionHistory.breadcrumb1', '')}
            onClick={() => {
              redirect(ROUTE_PATH.HOME.LINK)
            }}
          />
          <Breadcrumb.List name='/' />
          <Breadcrumb.List
            link
            name={getLanguage('uploadTransactionHistory.breadcrumb2', '')}
          // onClick={() => {
          //   redirect(ROUTE_PATH.UPLOAD_TRANSACTION_INDEXES.LINK)
          // }}
          />
          <Breadcrumb.List name='/' />
          <Breadcrumb.List name={getLanguage('uploadTransactionHistory.breadcrumb3', '')} />
        </Breadcrumb>
        <Section ui='content'>
          <Section spacingBottom='35'>
            <Grid
              gutterVertical='15'
              justify='space-between'
            >
              <Grid.Column>
                <TitlePage
                  src={ICONS['ic-page-upload-transaction.svg']}
                  name={getLanguage('uploadTransactionHistory.title', '')}
                />
              </Grid.Column>
              <Grid.Column>
                <Grid.Column>
                  <Grid gutter='10'>
                    <Grid.Column>
                      <Field name="searchValue"
                        ui='blockLabelVerticalSearch'
                        width='285'
                        placeholder={getLanguage('uploadTransactionHistory.form.placeholderSearchSalesTransaction', '')}
                        value={searchValue}
                        onChange={this.input.onChangeInput}
                      />
                    </Grid.Column>
                    <Grid.Column>
                      <Button
                        lightBlue
                        name={getLanguage('uploadTransactionHistory.form.buttonSearch', '')}
                        width={VARIABLES.BUTTON.WIDTHS.W_105}
                        onClick={this.input.onClickSearchText}
                      />
                    </Grid.Column>
                  </Grid>
                </Grid.Column>
              </Grid.Column>
            </Grid>
          </Section>
          <UploadTransactionHistoryDetailListContainerConnected
            dataId={dataId} />
        </Section>
      </React.Fragment>
    )
  }
}
