import React from 'react'
import {
  ShapeContainer,
  Section,
  Breadcrumb,
  TitlePage,
  Button,
  Grid,
  Field,
  Text,
  TitleSection,
  Image
} from './../../components'
import {
  VARIABLES,
  ICONS
} from './../../themes'
import { setSideMenuActive, getRouteData, getRouteParams, redirectData } from '../../helpers/functions/route';
import { ROUTE_PATH } from '../../helpers/constants/route';
import { redirect } from '../../helpers/functions/route';
import _ from 'lodash';
import { getLanguage } from '../../helpers/functions/language';
import { toast } from '../../helpers/functions/main';
import { getUserAuthRedux } from '../../helpers/functions/auth';
import { isValidPhoneNumber, isValidResponse } from '../../helpers/functions/validation';
import { productController } from '../../apiService/apiController/productService';
import { displayWithComma } from '../../helpers/functions/display';
import { profileController } from '../../apiService/apiController/profileService';

export class ProductDetailManageScreen extends React.Component {

  constructor(props) {
    super(props);
    setSideMenuActive(props);
    const productList = getRouteData(props, 'productList');
    const routeDataNoLengthList = getRouteData(props, 'dataNoLengthList');
    const productId = parseInt(getRouteParams(props, 'id'));
    const routeData = getRouteData(props, 'data');
    const isIndo = getRouteData(props, 'isIndo');
    const reasonChangeList = ['cover', 'cut_off', 'sales_flat', 'other'];
    const dataDefault = {
      reasonChange: reasonChangeList[0],
      editLength: '',
      editQuantity: '',
      otherReason: '',
    }
    const data = {
      ...dataDefault,
      ...routeData,
    };
    this.state = {
      productList,
      dataNoLengthList: routeDataNoLengthList || [],
      productId,
      data,
      reasonChangeList,
      isIndo,
    }
    console.log('ProductDetailManageScreen data', data)
  }

  productDetailManage = {
    goBack: () => {
      const {
        productList,
        dataNoLengthList,
      } = this.state;
      redirectData(ROUTE_PATH.PRODUCT_DETAIL.LINK, {
        dataList: productList,
        dataNoLengthList
      })
    },
    isValidatePass: () => {
      const {
        data,
      } = this.state;
      let isPass = true;
      if (
        !data.editLength.length
      ) {
        toast.error(getLanguage('message.pleaseInsertAllRequiredField', ''));
        isPass = false;
      }
      return isPass;
    },
    isValidateIndoPass: () => {
      const {
        data,
      } = this.state;
      let isPass = true;
      if (
        !data.editQuantity.length
      ) {
        toast.error(getLanguage('message.pleaseInsertAllRequiredField', ''));
        isPass = false;
      }
      return isPass;
    },
    onClickSubmit: async () => {
      const {
        isIndo,
        data,
        productList,
      } = this.state;
      if (isIndo) {
        if (this.productDetailManage.isValidateIndoPass()) {
          const editQuantity = parseInt(data.editQuantity);
          const remainingLength = data.fTotalRemainingprofile - editQuantity;
          const params = {
            sfid: data.subSalesSfid,
            quantity: remainingLength
          }
          const profileService = profileController();
          const res = await profileService.adjust(params);
          if (isValidResponse(res)) {
            toast.success(getLanguage('message.updateProductLengthCompleted', ''));
            this.setState({
              productList: productList.map(e => {
                if (e.lotNumber === data.lotNumber) {
                  e.fTotalRemainingprofile = remainingLength;
                  e.fTotalRemainingprofileWithComma = displayWithComma(e.fTotalRemainingprofile);
                }
                if (e.fTotalRemainingprofile >= 0) {
                  return e;
                }
                return undefined;
              }).filter(e => e)
            }, () => {
              this.productDetailManage.goBack();
            })
          } else {
            toast.error(res.message);
          }
        }
      }
      else {
        if (this.productDetailManage.isValidatePass()) {
          const userAuth = getUserAuthRedux();
          const editLength = parseInt(data.editLength);
          const remainingLength = data.fTotalRemaininglength - editLength;
          const params = {
            products: [{
              dealer__c: userAuth.adRefId,
              coil_length__c: editLength,
              sales_transaction__c: data.sfid,
              type__c: 'Adjust',
              sales_sfid: userAuth.sfid,
              reason_of_change: data.reasonChange,
              reason_of_change_description: data.otherReason,
            }]
          }
          const productService = productController();
          const res = await productService.editProductT1(params);
          if (isValidResponse(res)) {
            toast.success(getLanguage('message.updateProductLengthCompleted', ''));
            this.setState({
              productList: productList.map(e => {
                if (e.lotNumber === data.lotNumber) {
                  e.fTotalRemaininglength = remainingLength;
                  e.fTotalRemaininglengthWithComma = displayWithComma(e.fTotalRemaininglength);
                }
                if (e.fTotalRemaininglength >= 0) {
                  return e;
                }
                return undefined;
              }).filter(e => e)
            }, () => {
              this.productDetailManage.goBack();
            })
          } else {
            toast.error(res.message);
          }
        }
      }
    }
  }

  input = {
    onChangeDataInput: (ev) => {
      const {
        data,
      } = this.state;
      const name = ev.target.name;
      const value = ev.target.value;
      switch (name) {
        case 'editLength':
          if (value.length && !isValidPhoneNumber(value)) {
            return;
          }
        default:
          data[name] = value;
          this.setState({
            data,
          });
      }
    },
    onChangeCheckbox: (ev, value) => {
      const {
        data,
      } = this.state;
      data.reasonChange = value;
      this.setState({
        data,
      });
    },
  }

  render() {
    const {
      data,
      reasonChangeList,
      isIndo,
    } = this.state;
    return (
      <React.Fragment>
        <Breadcrumb>
          <Breadcrumb.List
            link
            name={getLanguage('productDetailManage.breadcrumb1', '')}
            onClick={() => {
              redirect(ROUTE_PATH.HOME.LINK)
            }}
          />
          <Breadcrumb.List name='/' />
          <Breadcrumb.List
            link
            name={getLanguage('productDetailManage.breadcrumb2', '')}
          // onClick={() => {
          //   redirect(ROUTE_PATH.INVENTORY_INDEXES.LINK)
          // }}
          />
          <Breadcrumb.List name='/' />
          <Breadcrumb.List
            link
            name={getLanguage('productDetailManage.breadcrumb3', '')}
            onClick={() => {
              redirect(ROUTE_PATH.INVENTORY.LINK)
            }}
          />
          <Breadcrumb.List name='/' />
          <Breadcrumb.List
            link
            name={getLanguage('productDetailManage.breadcrumb4', '')}
            onClick={this.productDetailManage.goBack}
          />
          <Breadcrumb.List name='/' />
          <Breadcrumb.List name={getLanguage('productDetailManage.breadcrumb5', '')} />
        </Breadcrumb>
        <Section ui='content'>
          <Section spacingBottom='35'>
            <TitlePage
              src={ICONS['ic-page-inventory.svg']}
              name={getLanguage('productDetailManage.title', '')}
            />
          </Section>
          <ShapeContainer
            ui='content'
            justify='space-between'
          >
            <Section spacingBottom='45'>
              <Grid gutter='60'>
                <Grid.Column grid='6'>
                  <Section spacingBottom='9'>
                    <Text
                      regular16
                      color={VARIABLES.COLORS.PRIMARY_2}
                    >
                      {getLanguage('productDetailManage.form.coilInformation.title', '')}
                    </Text>
                  </Section>
                  <Section
                    spacingBottom='30'
                    calcHeight='100% - 39px'
                  >
                    <ShapeContainer
                      fluid
                      height='100'
                      heightUnit='%'
                      paddingHorizontal='35'
                      paddingVertical='30'
                      bgColor={VARIABLES.COLORS.BLUE_3}
                      borderWidth='1'
                      borderStyle='solid'
                      borderColor={VARIABLES.COLORS.BLUE_15}
                      borderRadius='10'
                    >
                      <Section spacingBottom='10'>
                        <Text
                          regular14
                          color={VARIABLES.COLORS.GRAY_19}
                        >
                          {isIndo ?
                            getLanguage('productDetailManage.form.coilInformation.profileType', '')
                            :
                            getLanguage('productDetailManage.form.coilInformation.coilName', '')
                          }
                        </Text>
                      </Section>
                      <Section spacingBottom='35'>
                        <Text
                          regular14
                          color={VARIABLES.COLORS.BLACK}
                        >
                          {isIndo ? data.profileType : data.itemDescription}
                        </Text>
                      </Section>
                      <Section spacingBottom='10'>
                        <Text
                          regular14
                          color={VARIABLES.COLORS.GRAY_19}
                        >
                          {isIndo ?
                            getLanguage('productDetailManage.form.coilInformation.productGroup', '')
                            :
                            getLanguage('productDetailManage.form.coilInformation.coilID', '')
                          }
                        </Text>
                      </Section>
                      <Section spacingBottom='35'>
                        <Text
                          regular14
                          color={VARIABLES.COLORS.BLACK}
                        >
                          {isIndo ? data.fBsproductgroupname : data.lotNumber}
                        </Text>
                      </Section>
                      <Section spacingBottom='10'>
                        <Text
                          regular14
                          color={VARIABLES.COLORS.GRAY_19}
                        >
                          {getLanguage('productDetailManage.form.coilInformation.lengthBalance', '')}
                        </Text>
                      </Section>
                      <Section spacingBottom='35'>
                        <Text
                          regular14
                          color={VARIABLES.COLORS.BLACK}
                        >
                          {isIndo ? data.profileLengthWithComma : data.fTotalRemaininglengthWithComma}
                        </Text>
                      </Section>
                      {isIndo &&
                        <React.Fragment>
                          <Section spacingBottom='10'>
                            <Text
                              regular14
                              color={VARIABLES.COLORS.GRAY_19}
                            >
                              {getLanguage('productDetailManage.form.coilInformation.quantity', '')}
                            </Text>
                          </Section>
                          <Section spacingBottom='35'>
                            <Text
                              regular14
                              color={VARIABLES.COLORS.BLACK}
                            >
                              {data.fTotalRemainingprofileWithComma}
                            </Text>
                          </Section>
                        </React.Fragment>
                      }
                      <Section spacingBottom='10'>
                        <Text
                          regular14
                          color={VARIABLES.COLORS.GRAY_19}
                        >
                          {getLanguage('productDetailManage.form.coilInformation.weightBalance', '')}
                        </Text>
                      </Section>
                      <Section>
                        <Text
                          regular14
                          color={VARIABLES.COLORS.BLACK}
                        >
                          {data.fRemainingweightWithComma}
                        </Text>
                      </Section>
                    </ShapeContainer>
                  </Section>
                </Grid.Column>
                <Grid.Column grid='6'>
                  <Section spacingBottom='30'>
                    <TitleSection name={getLanguage('productDetailManage.form.coilInformation.titleProduct', '')} />
                  </Section>
                  <Section spacingBottom='5'>
                    <Grid gutter='10'>
                      <Grid.Column>
                        <Text
                          regular14
                          color={VARIABLES.COLORS.GRAY_19}
                        >
                          {isIndo ?
                            getLanguage('field.editQuantity', '')
                            // getLanguage('field.editLengthPerUnit', '')
                            :
                            getLanguage('field.editLength', '')
                          }
                        </Text>
                      </Grid.Column>
                      <Grid.Column>
                        <Image ui='iconInfoLabel' />
                      </Grid.Column>
                    </Grid>
                  </Section>
                  <Section spacingBottom='30'>
                    {isIndo ?
                      <Field name='editQuantity'
                        fluid
                        required
                        // error
                        ui='blockLabelVertical'
                        placeholder={getLanguage('field.editQuantityPlaceholder', '')}
                        value={data.editQuantity}
                        onChange={this.input.onChangeDataInput}
                      />
                      :
                      <Field name='editLength'
                        fluid
                        required
                        // error
                        ui='blockLabelVertical'
                        placeholder={isIndo ? getLanguage('field.editLengthPerUnitPlaceholder', '') : getLanguage('field.editLengthPlaceholder', '')}
                        value={data.editLength}
                        onChange={this.input.onChangeDataInput}
                      />
                    }
                  </Section>
                  {!isIndo &&
                    <React.Fragment>
                      <Section spacingBottom='10'>
                        <Grid gutter='5'>
                          <Grid.Column>
                            <Text
                              regular14
                              color={VARIABLES.COLORS.GRAY_19}
                            >
                              {getLanguage('productDetailManage.form.coilInformation.reasonChange.title', '')}
                            </Text>
                          </Grid.Column>
                          <Grid.Column>
                            <Section spacingTop='-5'>
                              <ShapeContainer ui='required' />
                            </Section>
                          </Grid.Column>
                        </Grid>
                      </Section>
                      <Section spacingBottom='15'>
                        <Field
                          name='reason'
                          checked={data.reasonChange === reasonChangeList[0]}
                          ui='radio'
                          radioLabel={getLanguage('productDetailManage.form.coilInformation.reasonChange.cover', '')}
                          onChange={(ev) => {
                            this.input.onChangeCheckbox(ev, reasonChangeList[0]);
                          }}
                        />
                      </Section>
                      <Section spacingBottom='15'>
                        <Field
                          name='reason'
                          checked={data.reasonChange === reasonChangeList[1]}
                          ui='radio'
                          radioLabel={getLanguage('productDetailManage.form.coilInformation.reasonChange.cutOff', '')}
                          onChange={(ev) => {
                            this.input.onChangeCheckbox(ev, reasonChangeList[1]);
                          }}
                        />
                      </Section>
                      <Section spacingBottom='15'>
                        <Field
                          name='reason'
                          checked={data.reasonChange === reasonChangeList[2]}
                          ui='radio'
                          radioLabel={getLanguage('productDetailManage.form.coilInformation.reasonChange.salesFlat', '')}
                          onChange={(ev) => {
                            this.input.onChangeCheckbox(ev, reasonChangeList[2]);
                          }}
                        />
                      </Section>
                      <Section spacingBottom='15'>
                        <Field
                          name='reason'
                          checked={data.reasonChange === reasonChangeList[3]}
                          ui='radio'
                          radioLabel={getLanguage('productDetailManage.form.coilInformation.reasonChange.other', '')}
                          onChange={(ev) => {
                            this.input.onChangeCheckbox(ev, reasonChangeList[3]);
                          }}
                        />
                      </Section>
                      {
                        data.reasonChange === reasonChangeList[3] &&
                        <Section>
                          <Field name='otherReason'
                            fluid
                            label={getLanguage('field.otherReasonsChange', '')}
                            ui='blockLabelVerticalTextarea'
                            placeholder={getLanguage('field.otherReasonsChangePlaceholder', '')}
                            value={data.otherReason}
                            onChange={this.input.onChangeDataInput}
                          />
                        </Section>
                      }
                    </React.Fragment>
                  }
                </Grid.Column>
              </Grid>
            </Section>
            <Section>
              <Grid
                gutter='15'
                justify='flex-end'
              >
                <Grid.Column>
                  <Button
                    gray
                    ui='back'
                    name={getLanguage('field.buttonBack', '')}
                    width={VARIABLES.BUTTON.WIDTHS.W_155}
                    onClick={this.productDetailManage.goBack}
                  />
                </Grid.Column>
                <Grid.Column>
                  <Button
                    blue
                    name={getLanguage('field.buttonSave', '')}
                    width={VARIABLES.BUTTON.WIDTHS.W_155}
                    onClick={this.productDetailManage.onClickSubmit}
                  />
                </Grid.Column>
              </Grid>
            </Section>
          </ShapeContainer>
        </Section>
      </React.Fragment>
    )
  }
}
