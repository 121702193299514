import React from 'react';
import {
  connect,
} from 'react-redux';
import { redirect, redirectData } from '../../helpers/functions/route';
import { ROUTE_PATH } from '../../helpers/constants/route';
import { VARIABLES, ICONS } from '../../themes';
import { onOpenModal, toast } from '../../helpers/functions/main';
import { MODAL_TYPE } from '../../helpers/constants/main';
import _ from 'lodash';
import { getLanguage } from '../../helpers/functions/language';
import { salePersonController } from '../../apiService/apiController/salePersonService';
import { isValidResponse } from '../../helpers/functions/validation';
import { getCustomerList, getClearCustomerParams } from '../../helpers/services/customer';
import { displayWithComma } from '../../helpers/functions/display';
import { CoilTransferTableContainer } from '../CoilTransferContainer/CoilTransferTableContainer';
import { getAdRFList } from '../../helpers/services/transfer';

class CoilTransferContainer extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      activePage: 1,
      dataPerPage: 10
    }
  }

  componentDidMount() {
    const { adRfData, } = this.props;

    getAdRFList({
      page: 1,
    });

    if (adRfData) {
      this.setState({
        adRfData,
      });
    }
  }

  // customerList = {
  //   onClickSort: (target) => {
  //     getCustomerList({
  //       sort: target
  //     });
  //   },
  // }

  // pagination = {
  //   onPaginationChange: (pageNumber) => {
  //     getCustomerList({
  //       page: pageNumber,
  //     });
  //   }
  // }


  render() {
    const {
      dataList,
      dataParams,
      filterValue,
      onClickCustomer,
      adRfData,
      innerRef,
      activePage,
    } = this.props;
    const {
      dataPerPage
    } = this.state;

    return (
      <CoilTransferTableContainer
          ref={innerRef}
          dataList={dataList}
          dataParams={dataParams}
          filterValue={filterValue}
          onClickCustomer={onClickCustomer}
          adRfData={adRfData}
          activePage={activePage}
          dataPerPage={dataPerPage}
      />
    )
  }
}

const mapStateToProps = (state) => {
  const dataRedux = state.dataRedux;
  return {
    dataList: dataRedux.adRfList,
    activePage: dataRedux.adRfParams.activePage,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
  }
}

export const CoilTransferContainerConnected = connect(
  mapStateToProps,
  mapDispatchToProps
)(CoilTransferContainer)