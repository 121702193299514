import {
  createActions
} from 'reduxsauce'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  setRouteRedux: ['key', 'data'],
})

export const ROUTE_REDUX_TYPES = Types
export const routeCreators = Creators

/* ------------- Initial State ------------- */

export const ROUTE_REDUX_STATE_KEY = {
  ROUTE_HISTORY: 'routeHistory',
  SIDE_MENU_ACTIVE: 'sideMenuActive',
  SIDE_MENU_CHILD_ACTIVE: 'sideMenuChildActive',
}

export const ROUTE_REDUX_INITIAL_STATE = {
  routeHistory: [],
  sideMenuActive: {
    mainActive: '',
    childActive: '',
  },
}