import {
  createActions
} from 'reduxsauce'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  setDataRedux: ['key', 'data'],
})

export const DATA_REDUX_TYPES = Types
export const dataCreators = Creators

/* ------------- Initial State ------------- */
export const DATA_REDUX_STATE_KEY = {
  COUNTRY_LIST: 'countryList',
  SALE_PERSON_LIST: 'salePersonList',
  SALE_PERSON_PARAMS: 'salePersonParams',
  CUSTOMER_LIST: 'customerList',
  CUSTOMER_PARAMS: 'customerParams',
  CMS_NEWS_AND_PROMOTION_LIST: 'cmsNewsAndPromotionList',
  CMS_NEWS_AND_PROMOTION_PARAMS: 'cmsNewsAndPromotionParams',
  CMS_TNC_LIST: 'cmsTncList',
  CMS_TNC_PARAMS: 'cmsTncParams',
  CMS_REWARD_CATALOG_LIST: 'cmsRewardCatalogList',
  CMS_REWARD_CATALOG_PARAMS: 'cmsRewardCatalogParams',
  CMS_USER_MANUAL_LIST: 'cmsUserManualList',
  CMS_USER_MANUAL_PARAMS: 'cmsUserManualParams',
  CMS_CONTACT_US: 'cmsContactUs',
  USER_LIST: 'userList',
  USER_PARAMS: 'userParams',
  SALE_TRANS_LIST: 'saleTransList',
  SALE_TRANS_PARAMS: 'saleTransParams',
  PRODUCT_LIST: 'productList',
  PRODUCT_PARAMS: 'productParams',
  PRODUCT_QUANTITY_LIST: 'productQuantityList',
  PRODUCT_QUANTITY_NO_LENGTH_LIST: 'productQuantityNoLengthList',
  PRODUCT_QUANTITY_LIST_PARAMS: 'productQuantityParams',
  REDEMPTION_LIST: 'redemptionList',
  REDEMPTION_PARAMS: 'redemptionParams',
  POINT_LIST: 'pointList',
  POINT_PARAMS: 'pointParams',
  ORDER_INQUIRY_LIST: 'orderInquiryList',
  ORDER_INQUIRY_PARAMS: 'orderInquiryParams',
  WARRANTY_LIST: 'warrantyList',
  WARRANTY_PARAMS: 'warrantyParams',
  WARRANTY_DEFAULT_OPTION_PARAMS: 'warrantyDefaultOptionParams',
  WARRANTY_OPTION_PARAMS: 'warrantyOptionParams',
  WARRANTY_TEMPLATE_LIST: 'warrantyTemplateList',
  WARRANTY_TEMPLATE_PARAMS: 'warrantyTemplateParams',
  DOCUMENTARY_LIST: 'documentaryList',
  DOCUMENTARY_PARAMS: 'documentaryParams',
  DOCUMENTARY_DEFAULT_OPTION_PARAMS: 'documentaryDefaultOptionParams',
  DOCUMENTARY_OPTION_PARAMS: 'documentaryOptionParams',
  DOCUMENTARY_DETAIL_LIST: 'documentaryDetailList',
  DOCUMENTARY_DETAIL_PARAMS: 'documentaryDetailParams',
  AD_RF_LIST: 'adRfList',
  AD_RF_PARAMS: 'adRfParams',
  AD_DETAIL: 'adDetail',
  TRNSFER_HISTORY_LIST: 'transferHisList',
  TRNSFER_HISTORY_PARAMS: 'transferHisParams',
  COIL_APPROVE_LIST: 'coilApproveList',
  COIL_APPROVE_PARAMS: 'coilApproveParams',
  COIL_APPROVE_DEFAULT_OPTION_PARAMS: 'coilApproveDefaultOptionParams',
  COIL_APPROVE_OPTION_PARAMS: 'coilApproveOptionParams',
  TRNSFER_INVENROTY: 'transferInvList',
  TRNSFER_INVENROTY_PARAMS: 'transferInvParams',
  TRNSFER_ORDER_LIST: 'transferOrderList',
  EXPIRE_POINT_LIST: 'expirePointList',
  FIREBASE_NOTI: 'firebaseNoti',
  PROFILE_LIST: 'profileList',
  PROFILE_PARAMS: 'profileParams',
  UPLOAD_TRANSACTION_HISTORY_LIST: 'uploadTransactionHistoryList',
  UPLOAD_TRANSACTION_HISTORY_PARAMS: 'uploadTransactionHistoryParams',
  UPLOAD_TRANSACTION_HISTORY_DETAIL_LIST: 'uploadTransactionHistoryDetailList',
  UPLOAD_TRANSACTION_HISTORY_DETAIL_PARAMS: 'uploadTransactionHistoryDetailParams',
  PROJECT_DOCUMENT_LIST: 'projectDocumentList',
  PROJECT_DOCUMENT_PARAMS: 'projectDocumentParams',
  PROJECT_DOCUMENT_SEARCH_LOG_LIST: 'projectDocumentSearchLogList',
  PROJECT_DOCUMENT_SEARCH_LOG_PARAMS: 'projectDocumentSearchLogParams',
  PROJECT_DOCUMENT_DOCUMENTARY_LIST: 'projectDocumentDocumentaryList',
  PROJECT_DOCUMENT_DOCUMENTARY_PARAMS: 'projectDocumentDocumentaryParams',
  PROJECT_DOCUMENT_CERTIFICATE_LIST: 'projectDocumentCertificateList',
  PROJECT_DOCUMENT_CERTIFICATE_PARAMS: 'projectDocumentCertificateParams',
  PROJECT_DOCUMENT_REFERENCE_LIST: 'projectDocumentReferenceList',
  PROJECT_DOCUMENT_REFERENCE_PARAMS: 'projectDocumentReferenceParams',
  NOTIFICATION_MANAGEMENT_LIST: 'notificationManagementList',
  NOTIFICATION_MANAGEMENT_PARAMS: 'notificationManagementParams',
}

export const DATA_REDUX_INITIAL_STATE = {
  countryList: [],
  salePersonList: [],
  salePersonParams: {
    sort: '',
  },
  customerList: [],
  customerParams: {
    sort: '',
  },
  cmsNewsAndPromotionList: [],
  cmsNewsAndPromotionParams: {
    sort: '',
  },
  cmsTncList: [],
  cmsTncParams: {
    sort: '',
  },
  cmsUserManualList: [],
  cmsUserManualParams: {
    sort: '',
  },
  cmsContactUs: {},
  cmsRewardCatalogList: [],
  cmsRewardCatalogParams: {
    sort: '',
    rewardSelectedList: [],
  },
  userList: [],
  userParams: {
    sort: '',
  },
  saleTransList: [],
  saleTransParams: {
    sort: '',
  },
  productList: [],
  productParams: {
    sort: '',
  },
  productQuantityList: [],
  productQuantityNoLengthList: [],
  productQuantityListParams: {
    sort: '',
  },
  redemptionList: [],
  redemptionParams: {
    sort: '',
  },
  pointList: [],
  pointParams: {
    sort: '',
  },
  orderInquiryList: [],
  orderInquiryParams: {
    sort: '',
  },
  warrantyList: [],
  warrantyParams: {
    sort: '',
  },
  warrantyDefaultOptionParams: {
    sort: '',
    selectedDataList: [],
  },
  warrantyOptionParams: {
    sort: '',
    selectedDataList: [],
  },
  warrantyTemplateList: [],
  warrantyTemplateParams: {
    sort: '',
    dataSelectList: []
  },
  documentaryList: [],
  documentaryParams: {
    sort: '',
  },
  documentaryDetailList: [],
  documentaryDetailParams: {
    sort: '',
  },
  documentaryDefaultOptionParams: {
    sort: '',
    selectedDataList: [],
  },
  documentaryOptionParams: {
    sort: '',
    selectedDataList: [],
  },
  adRfList: [],
  adRfParams: {
    sort: '',
  },
  adDetail: {},
  transferHisList: [],
  transferHisParams: {
    sort: '',
  },
  coilApproveList: [],
  coilApproveParams: {
    sort: '',
  },
  coilApproveDefaultOptionParams: {
    sort: '',
    selectedDataList: [],
  },
  coilApproveOptionParams: {
    sort: '',
    selectedDataList: [],
  },
  transferInvList: [],
  transferInvParams: {
    sort: '',
  },
  transferOrderList: [],
  expirePointList: [],
  firebaseNoti: {
    dataList: [],
    data: {
      orderInquiry: {},
    }
  },
  profileList: [],
  profileParams: {
    sort: '',
  },
  uploadTransactionHistoryList: [],
  uploadTransactionHistoryParams: {
    sort: '',
  },
  uploadTransactionHistoryDetailList: [],
  uploadTransactionHistoryDetailParams: {
    sort: '',
  },
  projectDocumentList: [],
  projectDocumentParams: {
    sort: '',
  },
  projectDocumentSearchLogList: [],
  projectDocumentSearchLogParams: {
    sort: '',
  },
  projectDocumentDocumentaryList: [],
  projectDocumentDocumentaryParams: {
    sort: '',
  },
  projectDocumentCertificateList: [],
  projectDocumentCertificateParams: {
    sort: '',
  },
  projectDocumentReferenceList: [],
  projectDocumentReferenceParams: {
    sort: '',
  },
  notificationManagementList: [],
  notificationManagementParams: {
    sort: '',
  },
}