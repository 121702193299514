import React from "react";
import { connect } from "react-redux";
import { ENUM } from "../../helpers/constants/enum";
import { getCMSNewsAndPromotionList } from "../../helpers/services/cmsNewsAndPromotion";
import {
  ModalNewsAndPromotionsContainer
} from "../ModalContainer";
import {
  Grid,
  Image,
  NewsPromotionsList,
  Section,
  SwiperCarousel,
} from "./../../components";
import { VARIABLES } from "./../../themes";

class NewsAndPromotionListContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activePage: 1,
      dataPerPage: 10,
      initSlideIndex: 0,
    };
  }

  componentDidMount() {
    const { activePage, dataPerPage } = this.state;
    const params = {
      // page: activePage,
      // limit: dataPerPage,
      status: ENUM.STATUS_ACTIVE.ACTIVE,
      isNoSort: true,
      sort: "id desc",
      // type: ENUM.DATA_TYPE.NEWS,
    };
    getCMSNewsAndPromotionList(params);
  }

  modal = {
    onOpenNewsAndPromotionsModal: (initSlideIndex) => {
      this.setState({
        isOpenNewsAndPromotionsModal: true,
        initSlideIndex,
      });
    },
    onCloseNewsAndPromotionsModal: () => {
      this.setState({
        isOpenNewsAndPromotionsModal: false,
      });
    },
  };

  render() {
    const { dataList } = this.props;
    const { isOpenNewsAndPromotionsModal, initSlideIndex } = this.state;
    const bannerList = dataList.filter((e, i) => e.isBanner);
    const newsPromotionList = dataList.filter((e, i) => !e.isBanner);
    return (
      <React.Fragment>
        <Section spacingBottom="35">
          {bannerList.length > 0 && (
            <SwiperCarousel>
              {bannerList.map((e, i) => {
                return (
                  <SwiperCarousel.Item
                    key={i}
                    labelNews={e.type === ENUM.DATA_TYPE.NEWS}
                    labelPromotions={e.type === ENUM.DATA_TYPE.PROMOTION}
                    title={e.subject}
                    onClick={() => {
                      this.modal.onOpenNewsAndPromotionsModal(i);
                    }}
                  >
                    <Image
                      heightRatio={
                        VARIABLES.BANNER.NEWS_AND_PROMOTIONS_HEIGHT_RATIO
                      }
                      src={e.cover.path}
                    />
                  </SwiperCarousel.Item>
                );
              })}
            </SwiperCarousel>
          )}
        </Section>
        <Grid gutter="35" gutterVertical="35">
          {newsPromotionList.map((e, i) => {
            return (
              <Grid.Column grid="3" key={i}>
                <NewsPromotionsList
                  key={i}
                  labelNews={e.type === ENUM.DATA_TYPE.NEWS}
                  labelPromotions={e.type === ENUM.DATA_TYPE.PROMOTION}
                  src={e.cover.path}
                  title={e.subject}
                  onClick={() => {
                    this.modal.onOpenNewsAndPromotionsModal(
                      bannerList.length + i
                    );
                  }}
                />
              </Grid.Column>
            );
          })}
        </Grid>
        {isOpenNewsAndPromotionsModal && (
          <ModalNewsAndPromotionsContainer
            open
            initSlideIndex={initSlideIndex}
            dataList={[...bannerList, ...newsPromotionList]}
            onClickClose={this.modal.onCloseNewsAndPromotionsModal}
          />
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  const dataRedux = state.dataRedux;
  return {
    dataList: dataRedux.cmsNewsAndPromotionList,
    activePage: dataRedux.cmsNewsAndPromotionParams.activePage,
    totalData: dataRedux.cmsNewsAndPromotionParams.totalData || 0,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export const NewsAndPromotionListContainerConnected = connect(
  mapStateToProps,
  mapDispatchToProps
)(NewsAndPromotionListContainer);
