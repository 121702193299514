import React from 'react'
import {
  ShapeContainer,
  Section,
  Breadcrumb,
  TitlePage,
  Text,
  Grid,
  Table,
  PaginationControl,
  TableAction,
  Button,
  Image,
  TitleSection,
  Field,
  DatePickerControl,
  SelectControl,
} from './../../components'
import {
  VARIABLES,
  ICONS,
} from './../../themes'
import { getRouteData, getRouteParams, setSideMenuActive } from '../../helpers/functions/route';
import { ROUTE_PATH } from '../../helpers/constants/route';
import { redirect } from '../../helpers/functions/route';
import _ from 'lodash';
import { getLanguage, getLanguageSpecific } from '../../helpers/functions/language';
import { DATA, DATE_PICKER_FORMAT } from '../../helpers/constants/data';
import { getUserAuthRedux } from '../../helpers/functions/auth';
import { getCountryPhone } from '../../helpers/functions/data';
import { isValidPhoneNumber, isValidResponse } from '../../helpers/functions/validation';
import { toast } from '../../helpers/functions/main';
import { displayDate } from '../../helpers/functions/display';
import { DATE_MMDDYYYY_FORMAT } from '../../helpers/constants/data';
import { salePersonController } from '../../apiService/apiController/salePersonService';
import { ModelSalePerson } from '../../models/ModelSalePerson';
import moment from 'moment';

export class SettingSalesPersonManageScreen extends React.Component {

  constructor(props) {
    super(props);
    setSideMenuActive(props);
    const dataId = parseInt(getRouteParams(props, 'id'));
    const routeData = getRouteData(props, 'data');
    const userAuth = getUserAuthRedux();
    const isEdit = dataId > 0;
    const defaultData = {
      ...new ModelSalePerson(),
      phoneCountry: getCountryPhone(),
      phone: '',
      firstname: '',
      lastname: '',
      nickname: '',
      dateOfBirth: new Date(),
      startDateStartWorkingDay: undefined,
      email: '',
      positionSelected: undefined,
    };
    const positionList = Object.keys(DATA.SALE_PERSON_POSITION).map(key => {
      return {
        value: DATA.SALE_PERSON_POSITION[key].ID,
        label: DATA.SALE_PERSON_POSITION[key].NAME,
      }
    })
    let data = {
      ...defaultData,
      ...routeData,
    };

    const saleSkillJobPositionOptionList = [
      {
        value: '1',
        label: getLanguage('salesPerson.options.incentiveSales'),
        name: getLanguageSpecific('salesPerson.options.incentiveSales')
      },
      {
        value: '2',
        label: getLanguage('salesPerson.options.disputeClosing'),
        name: getLanguageSpecific('salesPerson.options.disputeClosing')
      },
      {
        value: '3',
        label: getLanguage('salesPerson.options.professionalNegotiating'),
        name: getLanguageSpecific('salesPerson.options.professionalNegotiating')
      },
    ];
    const productSkillJobPositionOptionList = [
      {
        value: '1',
        label: getLanguage('salesPerson.options.basicKnowledge'),
        name: getLanguageSpecific('salesPerson.options.basicKnowledge')
      },
      {
        value: '2',
        label: getLanguage('salesPerson.options.courseForExperts'),
        name: getLanguageSpecific('salesPerson.options.courseForExperts')
      },
    ];

    if (data.salesSkillSpecificCourseList.length === 0) {
      data.salesSkillSpecificCourseList = [{
        name: '',
        date: undefined,
        selected: undefined,
      }]
    }
    if (data.salesSkillIndependentCourseList.length === 0) {
      data.salesSkillIndependentCourseList = [{
        name: '',
        date: undefined,
        selected: undefined,
      }]
    }
    if (data.productSkillSpecificCourseList.length === 0) {
      data.productSkillSpecificCourseList = [{
        name: '',
        date: undefined,
        selected: undefined,
      }]
    }
    if (data.productSkillIndependentCourseList.length === 0) {
      data.productSkillIndependentCourseList = [{
        name: '',
        date: undefined,
        selected: undefined,
      }]
    }
    if (isEdit) {
      data.phone = routeData.phoneNoPrefix__c;
      data.firstname = routeData.firstname__c;
      data.lastname = routeData.lastname__c;
      data.nickname = routeData.nickname__c;
      data.dateOfBirth = new Date(routeData.birthDate);
      data.startDateStartWorkingDay = routeData.startDateStartWorkingDay__c ? new Date(routeData.startDateStartWorkingDay__c) : undefined
      data.email = routeData.email__c;
      if (routeData.position__c !== null) {
        data.positionSelected = positionList.filter(e => e.value === routeData.position__c)[0];
      }
      data.salesSkillSpecificCourseList = data.salesSkillSpecificCourseList.map(e => {
        // e.date = e.trainingDate__c ? new Date(e.trainingDate__c) : undefined;
        if (e.trainingDate__c) {
          const date = moment(e.trainingDate__c).format('YYYY');
          e.date = {
            label: date,
            value: date,
          }
        }
        e.name = e.courseName__c;
        if (e.courseName__c) {
          e.selected = saleSkillJobPositionOptionList.filter(es => es.name === e.courseName__c)[0];
        }
        return e;
      });
      data.salesSkillIndependentCourseList = data.salesSkillIndependentCourseList.map(e => {
        if (e.trainingDate__c) {
          const date = moment(e.trainingDate__c).format('YYYY');
          e.date = {
            label: date,
            value: date,
          }
        }
        e.name = e.courseName__c;
        return e;
      });
      data.productSkillSpecificCourseList = data.productSkillSpecificCourseList.map(e => {
        if (e.trainingDate__c) {
          const date = moment(e.trainingDate__c).format('YYYY');
          e.date = {
            label: date,
            value: date,
          }
        }
        e.name = e.courseName__c;
        if (e.courseName__c) {
          e.selected = productSkillJobPositionOptionList.filter(es => es.name === e.courseName__c)[0];
        }
        return e;
      });
      data.productSkillIndependentCourseList = data.productSkillIndependentCourseList.map(e => {
        if (e.trainingDate__c) {
          const date = moment(e.trainingDate__c).format('YYYY');
          e.date = {
            label: date,
            value: date,
          }
        }
        e.name = e.courseName__c;
        return e;
      });
    }

    console.log('routeData', data, routeData);

    const yearSelectOptionList = []
    for (let i = 2015; i <= 2030; i++) {
      yearSelectOptionList.push({
        label: i.toString(),
        value: i.toString()
      })
    }
    this.state = {
      userAuth,
      data,
      dataId,
      isEdit,
      positionList,
      maximumCourse: 5,
      saleSkillJobPositionOptionList,
      productSkillJobPositionOptionList,
      yearSelectOptionList,
    }
  }

  settingSalesPersonManage = {
    goBack: () => {
      redirect(ROUTE_PATH.SETTING_SALES_PERSON.LINK);
    },
    onClickAddCourse: (type) => {
      const {
        data,
        maximumCourse,
      } = this.state;
      if (data[type].length < maximumCourse) {
        data[type].push({
          name: '',
          date: undefined,
        })
        this.setState({
          data,
        });
      }
    },
    onClickDeleteCourse: (type, index) => {
      const {
        data,
      } = this.state;
      data[type].splice(index, 1);
      this.setState({
        data,
      })
    },
    isValidatePass: () => {
      const {
        data,
      } = this.state;
      let isPass = true;
      console.log('data', data);
      if (
        !data.phone.length ||
        !data.idCardNo.length ||
        !data.firstname.length ||
        !data.lastname.length ||
        !data.positionSelected
      ) {
        toast.error(getLanguage('message.pleaseInsertAllRequiredField', ''));
        isPass = false;
      }
      DATA.PHONE_PREFIX_LIST.forEach(e => {
        if (data.phoneCountry.value === e.value) {
          if (e.lengthList.indexOf(data.phone.length) === -1) {
            toast.error(getLanguage('message.incorrectPhoneNumberLength', ''));
            isPass = false;
          }
        }
      })
      return isPass;
    },
    handleDeleteCourseList: (target, list = []) => {
      const {
        data,
      } = this.state;
      data[target].forEach(e => {
        let isDelete = list.filter(et => e.uid__c === et.uid__c).length === 0;
        if (isDelete) {
          list.push({
            uid__c: e.uid__c,
            course_type__c: e.courseType__c,
            training_course__c: e.trainingCourse__c,
            course_name__c: e.courseName__c,
            training_date__c: e.trainingDate__c,
            status__c: 'Inactive'
          });
        }
      });
      return list;
    },
    onClickSubmit: async () => {
      const {
        data,
      } = this.state;
      if (this.settingSalesPersonManage.isValidatePass()) {
        const {
          data,
          isEdit,
          dataId,
          userAuth,
        } = this.state;
        const params = {
          first_name: data.firstname || '',
          last_name: data.lastname || '',
          nick_name: data.nickname || '',
          email: data.email || '',
          phone: data.phoneCountry.value + data.phone || '',
          country: data.phoneCountry.country,
          date_of_birth: displayDate(data.dateOfBirth, '-', DATE_MMDDYYYY_FORMAT),
          ad_ref_id: isEdit ? undefined : userAuth.adRefId,
          ad_dealer_account: isEdit ? undefined : userAuth.dealerAccount,
          start_date_start_working_day__c: data.startDateStartWorkingDay ? displayDate(data.startDateStartWorkingDay, '-', DATE_MMDDYYYY_FORMAT) : undefined,
          position__c: data.positionSelected.value,
          id_card: data.idCardNo
        };
        let trainingCourseList = [];
        data.salesSkillSpecificCourseList.forEach(e => {
          trainingCourseList.push({
            uid__c: e.uid__c ? e.uid__c : undefined,
            rbp_member__c: isEdit && e.uid__c === undefined ? data.sfid : undefined,
            course_type__c: 'Specific Course',
            training_course__c: 'Sales Skills Training Course',
            course_name__c: _.get(e, 'selected.name', ''),
            // course_name__c: e.name || '',
            training_date__c: e.date ? displayDate(new Date(e.date.value), '-', DATE_MMDDYYYY_FORMAT) : undefined,
            status__c: 'Active'
          })
        });
        data.salesSkillIndependentCourseList.forEach(e => {
          trainingCourseList.push({
            uid__c: e.uid__c ? e.uid__c : undefined,
            rbp_member__c: isEdit && e.uid__c === undefined ? data.sfid : undefined,
            course_type__c: 'Independent Course',
            training_course__c: 'Sales Skills Training Course',
            course_name__c: e.name || '',
            training_date__c: e.date ? displayDate(new Date(e.date.value), '-', DATE_MMDDYYYY_FORMAT) : undefined,
            status__c: 'Active'
          })
        });
        data.productSkillSpecificCourseList.forEach(e => {
          trainingCourseList.push({
            uid__c: e.uid__c ? e.uid__c : undefined,
            rbp_member__c: isEdit && e.uid__c === undefined ? data.sfid : undefined,
            course_type__c: 'Specific Course',
            training_course__c: 'Product Knowledge Skills Training Course',
            course_name__c: _.get(e, 'selected.name', ''),
            // course_name__c: e.name || '',
            training_date__c: e.date ? displayDate(new Date(e.date.value), '-', DATE_MMDDYYYY_FORMAT) : undefined,
            status__c: 'Active'
          })
        });
        data.productSkillIndependentCourseList.forEach(e => {
          trainingCourseList.push({
            uid__c: e.uid__c ? e.uid__c : undefined,
            rbp_member__c: isEdit && e.uid__c === undefined ? data.sfid : undefined,
            course_type__c: 'Independent Course',
            training_course__c: 'Product Knowledge Skills Training Course',
            course_name__c: e.name || '',
            training_date__c: e.date ? displayDate(new Date(e.date.value), '-', DATE_MMDDYYYY_FORMAT) : undefined,
            status__c: 'Active'
          })
        });

        if (isEdit) {
          trainingCourseList = this.settingSalesPersonManage.handleDeleteCourseList('oldSalesSkillSpecificCourseList', trainingCourseList);
          trainingCourseList = this.settingSalesPersonManage.handleDeleteCourseList('oldSalesSkillIndependentCourseList', trainingCourseList);
          trainingCourseList = this.settingSalesPersonManage.handleDeleteCourseList('oldProductSkillSpecificCourseList', trainingCourseList);
          trainingCourseList = this.settingSalesPersonManage.handleDeleteCourseList('oldProductSkillIndependentCourseList', trainingCourseList);
        }

        params.training_courses = trainingCourseList;

        const salePersonService = salePersonController();
        let res = {};
        if (isEdit) {
          params.id = dataId;
          res = await salePersonService.updateSalePerson(params);
        }
        else {
          res = await salePersonService.createSalePerson(params);
        }
        if (isValidResponse(res)) {
          this.settingSalesPersonManage.goBack();
          if (isEdit) {
            toast.success(getLanguage('message.updateSalesPersonCompleted', ''));
          }
          else {
            toast.success(getLanguage('message.createSalesPersonCompleted', ''));
          }
        }
        else {
          toast.error(res.message);
        }
      }
    }
  }

  input = {
    onChangeDataInput: (ev) => {
      let {
        data,
      } = this.state;
      const name = ev.target.name;
      const value = ev.target.value;
      switch (name) {
        case 'phone':
        case 'idCardNo':
          if (value.length && !isValidPhoneNumber(value)) {
            return;
          }
          break;
        default: break;
      }
      data[name] = value;
      this.setState({
        data,
      });
    },
    onChangeCourseDataInput: (ev, type, target, index) => {
      let {
        data,
      } = this.state;
      let value;
      switch (target) {
        case 'name':
          value = ev.target.value;
          break;
        case 'date':
        case 'selected':
          value = ev;
          break;
        default: break;
      }
      data[type][index][target] = value;
      this.setState({
        data,
      });
    },
    onChangeSelect: (value) => {
      const {
        data,
      } = this.state;
      data.positionSelected = value;
      this.setState({
        data,
      });
    },
    onChangeDatePicker: (target, value) => {
      const {
        data,
      } = this.state;
      data[target] = value;
      this.setState({
        data,
      });
    },
  }

  render() {
    const {
      data,
      isEdit,
      positionList,
      saleSkillJobPositionOptionList,
      productSkillJobPositionOptionList,
      maximumCourse,
      yearSelectOptionList,
    } = this.state;
    return (
      <React.Fragment>
        <Breadcrumb>
          <Breadcrumb.List
            link
            name={getLanguage('salesPerson.breadcrumb1', '')}
            onClick={() => {
              redirect(ROUTE_PATH.HOME.LINK)
            }}
          />
          <Breadcrumb.List name='/' />
          <Breadcrumb.List
            link
            name={getLanguage('salesPerson.breadcrumb2', '')}
          // onClick={() => {
          //   redirect(ROUTE_PATH.SETTINGS.LINK)
          // }}
          />
          <Breadcrumb.List name='/' />
          <Breadcrumb.List
            link
            name={getLanguage('salesPerson.breadcrumb3', '')}
            onClick={() => {
              redirect(ROUTE_PATH.SETTING_SALES_PERSON.LINK)
            }}
          />
          <Breadcrumb.List name='/' />
          <Breadcrumb.List name={isEdit ? getLanguage('salesPerson.breadcrumb5', '') : getLanguage('salesPerson.breadcrumb4', '')} />
        </Breadcrumb>
        <Section ui='content'>
          <Section spacingBottom='35'>
            <TitlePage
              src={ICONS['ic-page-settings.svg']}
              name={isEdit ? getLanguage('salesPerson.titles.editSales', '') : getLanguage('salesPerson.titles.addNewSales', '')}
            />
          </Section>
          <ShapeContainer
            ui='content'
            justify='space-between'
          >
            <Section spacingBottom='45'>
              <Section spacingBottom='40'>
                <TitleSection name={getLanguage('salesPerson.headings.salesInformation')} />
              </Section>
              <Section spacingBottom='30'>
                <Grid>
                  <Grid.ResponsiveColumn ui='fieldText'>
                    <Field name='phone'
                      fluid
                      required
                      disabled={isEdit}
                      // error
                      ui='blockLabelVerticalPhone'
                      label={getLanguage('field.phoneNo', '')}
                      placeholder={getLanguage('field.phoneNoPlaceholder', '')}
                      message={
                        <Section
                          ui='hintBottomField'
                          hint={getLanguage('field.phoneNoHint', '')}
                        />
                      }
                      value={data.phone}
                      onChange={this.input.onChangeDataInput}
                      selectedPhoneCountry={data.phoneCountry}
                    />
                  </Grid.ResponsiveColumn>
                </Grid>
              </Section>
              <Section spacingBottom='30'>
                <Grid
                  gutter='35'
                  gutterVertical='30'
                >
                  <Grid.Column
                    gridTablet='12'
                    gridLaptop='6'
                    gridDesktop='3'
                  >
                    <Field name='firstname'
                      fluid
                      required
                      // error
                      ui='blockLabelVertical'
                      label={getLanguage('field.firstname', '')}
                      placeholder={getLanguage('field.firstnamePlaceholder', '')}
                      value={data.firstname}
                      onChange={this.input.onChangeDataInput}
                    />
                  </Grid.Column>
                  <Grid.Column
                    gridTablet='12'
                    gridLaptop='6'
                    gridDesktop='3'
                  >
                    <Field name='lastname'
                      fluid
                      required
                      // error
                      ui='blockLabelVertical'
                      label={getLanguage('field.lastname', '')}
                      placeholder={getLanguage('field.lastnamePlaceholder', '')}
                      value={data.lastname}
                      onChange={this.input.onChangeDataInput}
                    />
                  </Grid.Column>
                  <Grid.Column
                    gridTablet='12'
                    gridLaptop='6'
                    gridDesktop='3'
                  >
                    <Field name='nickname'
                      fluid
                      ui='blockLabelVertical'
                      label={getLanguage('field.nickname', '')}
                      placeholder={getLanguage('field.nicknamePlaceholder', '')}
                      value={data.nickname}
                      onChange={this.input.onChangeDataInput}
                    />
                  </Grid.Column>
                  <Grid.Column
                    gridTablet='12'
                    gridLaptop='6'
                    gridDesktop='3'
                  >
                    <Section zIndexLv2>
                      <DatePickerControl
                        fluid
                        top
                        dateFormat={DATE_PICKER_FORMAT}
                        maxDate={new Date()}
                        label={getLanguage('field.dateOfBirth', '')}
                        placeholderText={getLanguage('field.dateOfBirthPlaceholder', '')}
                        selected={data.dateOfBirth}
                        onChange={(ev) => this.input.onChangeDatePicker('dateOfBirth', ev)}
                      />
                    </Section>
                  </Grid.Column>
                </Grid>
              </Section>
              <Section spacingBottom='30'>
                <Grid
                  gutter='35'
                  gutterVertical='30'
                >
                  <Grid.Column
                    gridTablet='12'
                    gridLaptop='6'
                    gridDesktop='3'
                  >
                    <Field name='idCardNo'
                      fluid
                      required
                      // error
                      ui='blockLabelVertical'
                      label={getLanguage('field.idCardNo', '')}
                      placeholder={getLanguage('field.idCardNoPlaceholder', '')}
                      value={data.idCardNo}
                      onChange={this.input.onChangeDataInput}
                    />
                  </Grid.Column>
                  <Grid.Column
                    gridTablet='12'
                    gridLaptop='6'
                    gridDesktop='3'
                  >
                    <Section zIndexLv1>
                      <DatePickerControl
                        fluid
                        top
                        dateFormat={DATE_PICKER_FORMAT}
                        // maxDate={}
                        label={getLanguage('field.startWorkingDate', '')}
                        placeholderText={getLanguage('field.startWorkingDatePlaceholder', '')}
                        selected={data.startDateStartWorkingDay}
                        onChange={(ev) => this.input.onChangeDatePicker('startDateStartWorkingDay', ev)}
                      />
                    </Section>
                  </Grid.Column>
                  <Grid.Column
                    gridTablet='12'
                    gridLaptop='6'
                    gridDesktop='3'
                  >
                    <SelectControl
                      verticalLabel
                      required
                      label={getLanguage('field.jobPosition', '')}
                      placeholder={getLanguage('field.jobPositionPlaceholder', '')}
                      // name={}
                      value={data.positionSelected}
                      onChange={this.input.onChangeSelect}
                      options={positionList}
                    />
                  </Grid.Column>
                </Grid>
              </Section>
              <Section spacingBottom='30'>
                <Grid>
                  <Grid.ResponsiveColumn ui='fieldText'>
                    <Field name='email'
                      fluid
                      required
                      ui='blockLabelVertical'
                      label={getLanguage('field.email', '')}
                      placeholder={getLanguage('field.emailPlaceholder', '')}
                      value={data.email}
                      onChange={this.input.onChangeDataInput}
                    />
                  </Grid.ResponsiveColumn>
                </Grid>
              </Section>
              <React.Fragment>
                <Section spacingBottom='40'>
                  <TitleSection name={getLanguage('salesPerson.headings.salesSkill')} />
                </Section>
                <Section spacingBottom='15'>
                  <Grid gutter='10' align='center'>
                    <Grid.Column><ShapeContainer width='6' height='6' bgColor={VARIABLES.COLORS.PRIMARY_2} borderRadius='3' /></Grid.Column>
                    <Grid.Column><Text regular16 top='2' color={VARIABLES.COLORS.PRIMARY_2}>{getLanguage('salesPerson.headings.specificCourse')} {getLanguage('salesPerson.headings.maximum5')}</Text></Grid.Column>
                  </Grid>
                </Section>
                {data.salesSkillSpecificCourseList.map((e, i) => {
                  return (
                    <Section spacingBottom='15' key={i}>
                      <Grid>
                        <Grid.Column
                          gridTablet='12'
                          gridDesktop='10'
                        >
                          <Grid
                            gutter='35'
                            gutterVertical='30'
                            align='center'
                          >
                            <Grid.Column flex='1'>
                              <ShapeContainer fluid padding='15' bgColor={VARIABLES.COLORS.BLUE_31} borderStyle='solid' borderWidth='1' borderColor={VARIABLES.COLORS.BLUE_16} borderRadius='5'>
                                <Grid
                                  gutter='35'
                                  gutterVertical='30'
                                >
                                  <Grid.Column flex='2'>
                                    <SelectControl
                                      verticalLabel
                                      label={getLanguage('field.courseName', '')}
                                      placeholder={getLanguage('field.courseNamePlaceholder', '')}
                                      value={e.selected}
                                      onChange={(ev) => this.input.onChangeCourseDataInput(ev, 'salesSkillSpecificCourseList', 'selected', i)}
                                      options={saleSkillJobPositionOptionList}
                                    />

                                    {/* <Field
                                      fluid
                                      ui='blockLabelVertical'
                                      label={getLanguage('field.courseName', '')}
                                      placeholder={getLanguage('field.courseNamePlaceholder', '')}
                                      value={e.name}
                                      onChange={(ev) => this.input.onChangeCourseDataInput(ev, 'salesSkillSpecificCourseList', 'name', i)}
                                    /> */}
                                  </Grid.Column>
                                  <Grid.Column flex='1'>
                                    {/* <DatePickerControl
                                      fluid
                                      top
                                      dateFormat={DATE_PICKER_FORMAT}
                                      // maxDate={}
                                      label={getLanguage('field.trainingDate', '')}
                                      placeholderText={getLanguage('field.trainingDatePlaceholder', '')}
                                      selected={e.date}
                                      onChange={(ev) => this.input.onChangeCourseDataInput(ev, 'salesSkillSpecificCourseList', 'date', i)}
                                    /> */}

                                    <SelectControl
                                      verticalLabel
                                      label={getLanguage('field.trainingYear', '')}
                                      placeholder={getLanguage('field.trainingYearPlaceholder', '')}
                                      // name={}
                                      value={e.date}
                                      onChange={(ev) => this.input.onChangeCourseDataInput(ev, 'salesSkillSpecificCourseList', 'date', i)}
                                      options={yearSelectOptionList}
                                    />
                                  </Grid.Column>
                                </Grid>
                              </ShapeContainer>
                            </Grid.Column>
                            <Grid.Column flex='none'>
                              <Section width='55'>
                                <Grid gutter='15' justify='flex-end'>
                                  {data.salesSkillSpecificCourseList.length > 1 &&
                                    <Grid.Column>
                                      <div onClick={() => { this.settingSalesPersonManage.onClickDeleteCourse('salesSkillSpecificCourseList', i) }} className='is-pointer'>
                                        <img src={ICONS['ic-delete-outline.svg']} alt='Icon' />
                                      </div>
                                    </Grid.Column>
                                  }
                                  {i === data.salesSkillSpecificCourseList.length - 1 && data.salesSkillSpecificCourseList.length < maximumCourse &&
                                    <Grid.Column>
                                      <div onClick={() => this.settingSalesPersonManage.onClickAddCourse('salesSkillSpecificCourseList')} className='is-pointer'>
                                        <img src={ICONS['ic-add-outline.svg']} alt='Icon' />
                                      </div>
                                    </Grid.Column>
                                  }
                                </Grid>
                              </Section>
                            </Grid.Column>
                          </Grid>
                        </Grid.Column>
                      </Grid>
                    </Section>
                  )
                })}
                <Section paddingTop='15' spacingBottom='15'>
                  <Grid gutter='10' align='center'>
                    <Grid.Column><ShapeContainer width='6' height='6' bgColor={VARIABLES.COLORS.PRIMARY_2} borderRadius='3' /></Grid.Column>
                    <Grid.Column><Text regular16 top='2' color={VARIABLES.COLORS.PRIMARY_2}>{getLanguage('salesPerson.headings.independentCourse')} {getLanguage('salesPerson.headings.maximum5')}</Text></Grid.Column>
                  </Grid>
                </Section>
                {data.salesSkillIndependentCourseList.map((e, i) => {
                  return (
                    <Section spacingBottom='15' key={i}>
                      <Grid>
                        <Grid.Column
                          gridTablet='12'
                          gridDesktop='10'
                        >
                          <Grid
                            gutter='35'
                            gutterVertical='30'
                            align='center'
                          >
                            <Grid.Column flex='1'>
                              <ShapeContainer fluid padding='15' bgColor={VARIABLES.COLORS.BLUE_5} borderStyle='solid' borderWidth='1' borderColor={VARIABLES.COLORS.BLUE_15} borderRadius='5'>
                                <Grid
                                  gutter='35'
                                  gutterVertical='30'
                                >
                                  <Grid.Column flex='2'>
                                    <Field
                                      fluid
                                      ui='blockLabelVertical'
                                      label={getLanguage('field.courseName', '')}
                                      placeholder={getLanguage('field.courseNamePlaceholder', '')}
                                      value={e.name}
                                      onChange={(ev) => this.input.onChangeCourseDataInput(ev, 'salesSkillIndependentCourseList', 'name', i)}
                                    />
                                  </Grid.Column>
                                  <Grid.Column flex='1'>
                                    {/* <DatePickerControl
                                      fluid
                                      top
                                      dateFormat={DATE_PICKER_FORMAT}
                                      // maxDate={}
                                      label={getLanguage('field.trainingDate', '')}
                                      placeholderText={getLanguage('field.trainingDatePlaceholder', '')}
                                      selected={e.date}
                                      onChange={(ev) => this.input.onChangeCourseDataInput(ev, 'salesSkillIndependentCourseList', 'date', i)}
                                    /> */}

                                    <SelectControl
                                      verticalLabel
                                      label={getLanguage('field.trainingYear', '')}
                                      placeholder={getLanguage('field.trainingYearPlaceholder', '')}
                                      // name={}
                                      value={e.date}
                                      onChange={(ev) => this.input.onChangeCourseDataInput(ev, 'salesSkillIndependentCourseList', 'date', i)}
                                      options={yearSelectOptionList}
                                    />
                                  </Grid.Column>
                                </Grid>
                              </ShapeContainer>
                            </Grid.Column>
                            <Grid.Column flex='none'>
                              <Section width='55'>
                                <Grid gutter='15' justify='flex-end'>
                                  {data.salesSkillIndependentCourseList.length > 1 &&
                                    <Grid.Column>
                                      <div onClick={() => { this.settingSalesPersonManage.onClickDeleteCourse('salesSkillIndependentCourseList', i) }} className='is-pointer'>
                                        <img src={ICONS['ic-delete-outline.svg']} alt='Icon' />
                                      </div>
                                    </Grid.Column>
                                  }
                                  {i === data.salesSkillIndependentCourseList.length - 1 && data.salesSkillIndependentCourseList.length < maximumCourse &&
                                    <Grid.Column>
                                      <div onClick={() => this.settingSalesPersonManage.onClickAddCourse('salesSkillIndependentCourseList')} className='is-pointer'>
                                        <img src={ICONS['ic-add-outline.svg']} alt='Icon' />
                                      </div>
                                    </Grid.Column>
                                  }
                                </Grid>
                              </Section>
                            </Grid.Column>
                          </Grid>
                        </Grid.Column>
                      </Grid>
                    </Section>
                  )
                })}
                <Section paddingTop='25' spacingBottom='40'>
                  <TitleSection name={getLanguage('salesPerson.headings.productKnowledge')} />
                </Section>
                <Section spacingBottom='15'>
                  <Grid gutter='10' align='center'>
                    <Grid.Column><ShapeContainer width='6' height='6' bgColor={VARIABLES.COLORS.PRIMARY_2} borderRadius='3' /></Grid.Column>
                    <Grid.Column><Text regular16 top='2' color={VARIABLES.COLORS.PRIMARY_2}>{getLanguage('salesPerson.headings.specificCourse')} {getLanguage('salesPerson.headings.maximum5')}</Text></Grid.Column>
                  </Grid>
                </Section>
                {data.productSkillSpecificCourseList.map((e, i) => {
                  return (
                    <Section spacingBottom='15' key={i}>
                      <Grid>
                        <Grid.Column
                          gridTablet='12'
                          gridDesktop='10'
                        >
                          <Grid
                            gutter='35'
                            gutterVertical='30'
                            align='center'
                          >
                            <Grid.Column flex='1'>
                              <ShapeContainer fluid padding='15' bgColor={VARIABLES.COLORS.BLUE_31} borderStyle='solid' borderWidth='1' borderColor={VARIABLES.COLORS.BLUE_16} borderRadius='5'>
                                <Grid
                                  gutter='35'
                                  gutterVertical='30'
                                >
                                  <Grid.Column flex='2'>
                                    <SelectControl
                                      verticalLabel
                                      label={getLanguage('field.courseName', '')}
                                      placeholder={getLanguage('field.courseNamePlaceholder', '')}
                                      // name={}
                                      value={e.selected}
                                      onChange={(ev) => this.input.onChangeCourseDataInput(ev, 'productSkillSpecificCourseList', 'selected', i)}
                                      options={productSkillJobPositionOptionList}
                                    />

                                    {/* <Field
                                      fluid
                                      ui='blockLabelVertical'
                                      label={getLanguage('field.courseName', '')}
                                      placeholder={getLanguage('field.courseNamePlaceholder', '')}
                                      value={e.name}
                                      onChange={(ev) => this.input.onChangeCourseDataInput(ev, 'productSkillSpecificCourseList', 'name', i)}
                                    /> */}
                                  </Grid.Column>
                                  <Grid.Column flex='1'>
                                    {/* <DatePickerControl
                                      fluid
                                      top
                                      dateFormat={DATE_PICKER_FORMAT}
                                      // maxDate={}
                                      label={getLanguage('field.trainingDate', '')}
                                      placeholderText={getLanguage('field.trainingDatePlaceholder', '')}
                                      selected={e.date}
                                      onChange={(ev) => this.input.onChangeCourseDataInput(ev, 'productSkillSpecificCourseList', 'date', i)}
                                    /> */}

                                    <SelectControl
                                      verticalLabel
                                      label={getLanguage('field.trainingYear', '')}
                                      placeholder={getLanguage('field.trainingYearPlaceholder', '')}
                                      // name={}
                                      value={e.date}
                                      onChange={(ev) => this.input.onChangeCourseDataInput(ev, 'productSkillSpecificCourseList', 'date', i)}
                                      options={yearSelectOptionList}
                                    />
                                  </Grid.Column>
                                </Grid>
                              </ShapeContainer>
                            </Grid.Column>
                            <Grid.Column flex='none'>
                              <Section width='55'>
                                <Grid gutter='15' justify='flex-end'>
                                  {data.productSkillSpecificCourseList.length > 1 &&
                                    <Grid.Column>
                                      <div onClick={() => { this.settingSalesPersonManage.onClickDeleteCourse('productSkillSpecificCourseList', i) }} className='is-pointer'>
                                        <img src={ICONS['ic-delete-outline.svg']} alt='Icon' />
                                      </div>
                                    </Grid.Column>
                                  }
                                  {i === data.productSkillSpecificCourseList.length - 1 && data.productSkillSpecificCourseList.length < maximumCourse &&
                                    <Grid.Column>
                                      <div onClick={() => this.settingSalesPersonManage.onClickAddCourse('productSkillSpecificCourseList')} className='is-pointer'>
                                        <img src={ICONS['ic-add-outline.svg']} alt='Icon' />
                                      </div>
                                    </Grid.Column>
                                  }
                                </Grid>
                              </Section>
                            </Grid.Column>
                          </Grid>
                        </Grid.Column>
                      </Grid>
                    </Section>
                  )
                })}
                <Section paddingTop='15' spacingBottom='15'>
                  <Grid gutter='10' align='center'>
                    <Grid.Column><ShapeContainer width='6' height='6' bgColor={VARIABLES.COLORS.PRIMARY_2} borderRadius='3' /></Grid.Column>
                    <Grid.Column><Text regular16 top='2' color={VARIABLES.COLORS.PRIMARY_2}>{getLanguage('salesPerson.headings.independentCourse')} {getLanguage('salesPerson.headings.maximum5')}</Text></Grid.Column>
                  </Grid>
                </Section>
                {data.productSkillIndependentCourseList.map((e, i) => {
                  return (
                    <Section spacingBottom='15' key={i}>
                      <Grid>
                        <Grid.Column
                          gridTablet='12'
                          gridDesktop='10'
                        >
                          <Grid
                            gutter='35'
                            gutterVertical='30'
                            align='center'
                          >
                            <Grid.Column flex='1'>
                              <ShapeContainer fluid padding='15' bgColor={VARIABLES.COLORS.BLUE_5} borderStyle='solid' borderWidth='1' borderColor={VARIABLES.COLORS.BLUE_15} borderRadius='5'>
                                <Grid
                                  gutter='35'
                                  gutterVertical='30'
                                >
                                  <Grid.Column flex='2'>
                                    <Field
                                      fluid
                                      ui='blockLabelVertical'
                                      label={getLanguage('field.courseName', '')}
                                      placeholder={getLanguage('field.courseNamePlaceholder', '')}
                                      value={e.name}
                                      onChange={(ev) => this.input.onChangeCourseDataInput(ev, 'productSkillIndependentCourseList', 'name', i)}
                                    />
                                  </Grid.Column>
                                  <Grid.Column flex='1'>
                                    {/* <DatePickerControl
                                      fluid
                                      top
                                      dateFormat={DATE_PICKER_FORMAT}
                                      // maxDate={}
                                      label={getLanguage('field.trainingDate', '')}
                                      placeholderText={getLanguage('field.trainingDatePlaceholder', '')}
                                      selected={e.date}
                                      onChange={(ev) => this.input.onChangeCourseDataInput(ev, 'productSkillIndependentCourseList', 'date', i)}
                                    /> */}

                                    <SelectControl
                                      verticalLabel
                                      label={getLanguage('field.trainingYear', '')}
                                      placeholder={getLanguage('field.trainingYearPlaceholder', '')}
                                      // name={}
                                      value={e.date}
                                      onChange={(ev) => this.input.onChangeCourseDataInput(ev, 'productSkillIndependentCourseList', 'date', i)}
                                      options={yearSelectOptionList}
                                    />
                                  </Grid.Column>
                                </Grid>
                              </ShapeContainer>
                            </Grid.Column>
                            <Grid.Column flex='none'>
                              <Section width='55'>
                                <Grid gutter='15' justify='flex-end'>
                                  {data.productSkillIndependentCourseList.length > 1 &&
                                    <Grid.Column>
                                      <div onClick={() => { this.settingSalesPersonManage.onClickDeleteCourse('productSkillIndependentCourseList', i) }} className='is-pointer'>
                                        <img src={ICONS['ic-delete-outline.svg']} alt='Icon' />
                                      </div>
                                    </Grid.Column>
                                  }
                                  {i === data.productSkillIndependentCourseList.length - 1 && data.productSkillIndependentCourseList.length < maximumCourse &&
                                    <Grid.Column>
                                      <div onClick={() => this.settingSalesPersonManage.onClickAddCourse('productSkillIndependentCourseList')} className='is-pointer'>
                                        <img src={ICONS['ic-add-outline.svg']} alt='Icon' />
                                      </div>
                                    </Grid.Column>
                                  }
                                </Grid>
                              </Section>
                            </Grid.Column>
                          </Grid>
                        </Grid.Column>
                      </Grid>
                    </Section>
                  )
                })}
              </React.Fragment>
            </Section>
            <Section>
              <Grid
                justify='flex-end'
                gutter='15'
              >
                <Grid.Column>
                  <Button
                    gray
                    ui='back'
                    name={getLanguage('field.buttonBack', '')}
                    onClick={this.settingSalesPersonManage.goBack}
                  />
                </Grid.Column>
                <Grid.Column>
                  <Button
                    blue
                    name={getLanguage('field.buttonSave', '')}
                    width={VARIABLES.BUTTON.WIDTHS.W_200}
                    onClick={this.settingSalesPersonManage.onClickSubmit}
                  />
                </Grid.Column>
              </Grid>
            </Section>
          </ShapeContainer>
        </Section>
      </React.Fragment>
    )
  }
}
